export const columnValues = [
    { name: 'Pipeline - Total', value: 'allPipeline', color: '#ed7d31', title: 'Total Pipeline' },
    { name: 'Scheduled Orders Not Yet Wholesaled - Total', value: 'pipelineNoWholesaled', color: '#fce4d6', title: 'Total Pipeline' },
    { name: 'Wholesaled Not Retailed - Total', value: 'pipelineNoRetailed', color: '#fce4d6', title: 'Total Pipeline' },
    { name: 'Pipeline - Customer', value: 'customerPipeline', color: '#ed7d31', title: 'Customer Pipeline' },
    { name: 'Scheduled Orders Not Yet Wholesaled - Customer', value: 'customerNoWholesaled', color: '#fce4d6', title: 'Customer Pipeline' },
    { name: 'Wholesaled Not Retailed - Customer', value: 'customerNoRetailed', color: '#fce4d6', title: 'Customer Pipeline' },
    { name: 'Pipeline - Stock', value: 'stockPipeline', color: '#ed7d31', title: 'Stock Pipeline' },
    { name: 'Scheduled Orders Not Yet Wholesaled - Stock', value: 'stockNoWholesaled', color: '#fce4d6', title: 'Stock Pipeline' },
    { name: 'Wholesaled Not Retailed - Stock', value: 'stockNoRetailed', color: '#fce4d6', title: 'Stock Pipeline' },
];

export const stockAge = [
    { name: '0-3 Months', value: '0-3', min: 0, max: 90, color: '#DCDCDC' },
    { name: '3-6 Months', value: '3-6', min: 91, max: 180, color: '#DCDCDC' },
    { name: '6-9 Months', value: '6-9', min: 181, max: 270, color: '#DCDCDC' },
    { name: '9 Months - 1 Year', value: '9-1+', min: 271, max: 365, color: '#ed7d31' },
    { name: '+1 Year', value: '1+', min: 365, max: null, color: '#fc4630' }
]

export const stockOverview = [
    { name: 'Pipeline Deposit', value: 'sold' },
    { name: 'Wholesaled not retailed', value: 'notRetailed' },
    { name: 'Scheduled order not yet wholesaled', value: 'notWholesaled' }
]

export const reportModels = [
    'GT',
    'GTS',
    'Artura',
    'Artura Spider',
    '720S Coupe',
    '720S Spider',
    '750S Coupe',
    '750S Spider',
    'Elva'
]