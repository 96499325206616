/** LIBRARIES */
import React, { Component } from "react";
import { PopUpModal } from '../../components';
import Signature from '../../components/signature'
import { Modal } from "react-bootstrap";
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { default as _images } from '../../images';
import { storageBucket } from "../../services/helper";

const objAgreement = {
    verbalConsent: false,
    signature: '',
    termsAndConditions: ''
};

const _termsandCondition = '...';

export default class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: Object.assign({}, objAgreement),
            errors: {},
            showSignature: false,
            termConsent: false,
            insuranceConsent: false
        }

        //this.handleSignatureClose = this.handleSignatureClose.bind(this);
    }


    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        const { dealersettings, termsandCondition, addedBy, imagePath } = this.props;
        let clientID = this.props.clientID ? this.props.clientID : (dealersettings ? dealersettings.client.id : '');

        //console.log('uid', uid);
        var storageRef = storageBucket(dealersettings).ref(`${clientID}/${imagePath}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/${imagePath}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        state['termsAndConditions'] = termsandCondition ? termsandCondition : _termsandCondition;

                        if (imagePath !== 'serviceJobs') {
                            state['addedBy'] = localStorage?.uid ? localStorage.uid : addedBy ? addedBy : null;
                            state['addedDate'] = window.firebase.firestore.Timestamp.now();
                            state['addedFrom'] = 'web';
                        }

                        this.setState({ fields: state }, () => { this.errorChange(id) });
                    })
            })
    };

    //#endregion

    //#region CHANGE EVENTS
    handleCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        const { termsandCondition, addedBy, imagePath } = this.props;
        if (checked) {
            state.termsAndConditions = termsandCondition ? termsandCondition : _termsandCondition;
            if (imagePath !== 'serviceJobs') {
                state['addedBy'] = localStorage?.uid ? localStorage.uid : addedBy ? addedBy : null;
                state['addedDate'] = window.firebase.firestore.Timestamp.now();
                state['addedFrom'] = 'web';
            }
        }
        else {
            state.termsAndConditions = '';
            if (imagePath !== 'serviceJobs') {
                state['addedBy'] = '';
                state['addedDate'] = null;
            }
        }


        this.setState({ fields: state });
    }

    handleInsuranceCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        this.setState({ [name]: checked });
    }

    handleSignatureClose = (imgURL) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state.signature = imgURL;
            this.setState({ fields: state, showSignature: false }, () => { this.errorChange('signature') });

            // @CHECK for what to return a base64 image or uploaded image url
            if (this.props.shouldReturnBase64 === true) {
                let state = Object.assign({
                    signature: imgURL,
                    termsAndConditions: this.props.termsandCondition
                        ? this.props.termsandCondition
                        : _termsandCondition
                });
                // if (this.props.imagePath !== 'serviceJobs') {
                //     state['addedBy'] = localStorage.uid;
                //     state['addedDate'] = window.firebase.firestore.Timestamp.now();
                // }
                this.setState({
                    fields: state
                });
            } else {
                this.saveImageToStorage(imgURL, 'signature', 'png');
            }
        }
        else {
            this.setState({ showSignature: false })
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }
    //#endregion

    //#region RENDER & SUBMIT

    componentDidMount() {
        const fields = Object.assign({}, objAgreement);
        const objData = Object.assign({}, this.props.terms);

        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }

        this.setState({ fields: fields })
    }

    componentWillUnmount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log('handleSubmit refId=>', this.newDocID);
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        const { isVerbalTerms, includeInsWaiverInTDTerms, isMandatoryInsurance } = this.props;
        const { insuranceConsent } = this.state;
        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key] && key !== 'verbalConsent' && key !== 'addedBy') {

                if (key === 'signature' && fields.verbalConsent) {

                }
                else {
                    formIsValid = false;
                    errors[key] = errorClass;
                }

            }
        }


        if (isVerbalTerms && !fields['verbalConsent']) {
            formIsValid = false;
            errors['verbalConsent'] = errorClass;
        }

        if (includeInsWaiverInTDTerms && isMandatoryInsurance && !insuranceConsent) {
            formIsValid = false;
            errors['insuranceConsent'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            //console.log('formIsValid')
            if (!fields.verbalConsent)
                delete fields['verbalConsent'];

            this.props.handleClose(fields, insuranceConsent)
        }
        else if (!isVerbalTerms && !errors['insuranceConsent']) {
            this.setState({ showSignature: true })
            return formIsValid;
        }
        else {
            return formIsValid;
        }

    };
    //#endregion

    render() {
        const { termsAndConditions, signature, verbalConsent } = this.state.fields;
        const { insuranceConsent, errors, termConsent } = this.state;
        const { show, isDisable, termsandCondition, title, classContainer, isVerbalTerms,
            includeInsWaiverInTDTerms, insurance, insuranceTC
        } = this.props;
        return (
            <>

                <Modal
                    id="add-terms"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog-scrollable modal-dialog-centered modal-add-terms${includeInsWaiverInTDTerms ? '-fullwidth' : ''}`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={title ? title : 'termsAndConditions'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <div className={`container-fluid ${classContainer ? classContainer : ''}`}>
                            <div className={`row ${title && (['termsAndConditions', 'insuranceWaiver', 'customerConsent', 'financeCreditScoreConsent'].includes(title)) ? '' : 'w-100 d-inline-block'}`}>

                                <div className="testdrive-terms-wrap">
                                    {
                                        includeInsWaiverInTDTerms ?
                                            <>
                                                <div className="testdrive-terms-wrap-flex">
                                                    <div className="testdrive-terms-wrap-flexinner mr-2">
                                                        <h3><Translate text={'termsAndConditions'} /></h3>
                                                        <div
                                                            className={`terms-list`}
                                                            dangerouslySetInnerHTML={{ __html: (termsAndConditions ? termsAndConditions : (termsandCondition ? termsandCondition : _termsandCondition)) }} />

                                                        <div className={`testdrive-checkbox error-hidden-border ${(isDisable) ? 'form-disable' : ''}`}>
                                                            <div className="checkbox icheck-success">
                                                                <input
                                                                    type="checkbox"
                                                                    id="termConsent"
                                                                    name="termConsent"
                                                                    className="uncheck-activity"
                                                                    checked={(termConsent || !_.isEmpty(signature)) ? true : false}
                                                                    onChange={(e) => {
                                                                        this.handleInsuranceCheckChange(e, 'termConsent')
                                                                    }} />
                                                                <label htmlFor="termConsent"> {'I agree with terms & conditions'}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="testdrive-terms-wrap-flexinner ml-2">
                                                        <h3><Translate text={'insuranceWaiver'} /></h3>
                                                        <div
                                                            className={`terms-list`}
                                                            dangerouslySetInnerHTML={{ __html: _.trim(insurance?.termsAndConditions ? insurance.termsAndConditions : (insuranceTC ? insuranceTC : '')) }} />

                                                        <div className={`testdrive-checkbox error-hidden-border ${insuranceConsent ? '' : (errors["insuranceConsent"] || '')} ${(isDisable) ? 'form-disable' : ''}`}>
                                                            <div className="checkbox icheck-success">

                                                                <input
                                                                    type="checkbox"
                                                                    id="insuranceConsent"
                                                                    name="insuranceConsent"
                                                                    className="uncheck-activity"
                                                                    checked={(insuranceConsent || !_.isEmpty(insurance)) ? true : false}
                                                                    onChange={(e) => {
                                                                        this.handleInsuranceCheckChange(e, 'insuranceConsent')
                                                                    }} />
                                                                <label htmlFor="insuranceConsent">{'I agree to insurance waiver terms & conditions'}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div
                                                    className={`terms-list ${includeInsWaiverInTDTerms ? 'terms-list-min' : ''}`}
                                                    dangerouslySetInnerHTML={{ __html: (termsAndConditions ? termsAndConditions : (termsandCondition ? termsandCondition : _termsandCondition)) }} />
                                            </>
                                    }

                                    {
                                        isVerbalTerms
                                            ?
                                            <div className={`testdrive-checkbox error-hidden-border ${errors["verbalConsent"]}`}>
                                                <div className="checkbox icheck-success">

                                                    <input
                                                        type="checkbox"
                                                        id="verbalConsent"
                                                        name="verbalConsent"
                                                        className="uncheck-activity"
                                                        checked={verbalConsent ? true : false}
                                                        onChange={(e) => {
                                                            this.handleCheckChange(e, 'verbalConsent')
                                                        }} />
                                                    <label htmlFor="verbalConsent"><strong>{'Verbal Consent'}</strong>{': Has the customer verbally provided you with their consent to capture and record their details/information.'} </label>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <h2><Translate text={'signature'} /></h2>

                                                <div className={`signature-box ${errors["signature"]} ${(isDisable) ? 'form-disable' : ''}`} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showSignature: true })
                                                }}>
                                                    {
                                                        (signature && signature.indexOf('base64') > 0 && this.props.shouldReturnBase64 !== true)
                                                            ?
                                                            <div className="img-loader">
                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                    <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        (signature && !isDisable && signature.indexOf('base64') <= 0)
                                                            ?
                                                            <div className="editsign-btn">
                                                                <a href="#" className="editsign mini-button" onClick={(e) => { e.preventDefault(); }}>
                                                                    <i className="ico icon-edit" htmlFor="licBack"></i>
                                                                </a>
                                                            </div>
                                                            :
                                                            <></>
                                                    }

                                                    <img src={signature ? signature : _images.nosign} alt="" width="75" />
                                                </div>
                                            </>
                                    }


                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        isDisable
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer className="modal-footer">
                                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose([]) }}>
                                        <Translate text={isVerbalTerms ? 'cancel' : 'iDisagree'} />
                                    </button>
                                    <button type="button"
                                        className={`btn btn-primary float-right ${(signature && signature.indexOf('base64') > 0 && this.props.shouldReturnBase64 !== true) ? 'form-disable' : ''}`}
                                        onClick={(e) => this.handleSubmit(e)}>
                                        <Translate text={isVerbalTerms ? 'save' : 'iAgree'} />
                                    </button>
                                </Modal.Footer>
                            </>
                    }

                </Modal>

                <PopUpModal show={this.state.showSignature}>
                    <Signature
                        show={this.state.showSignature}
                        clsActive='overlay-modal active'
                        handleClose={this.handleSignatureClose}
                    ></Signature>
                </PopUpModal>


            </>


        );
    }
}

