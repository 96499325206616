import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { docData, collection } from 'rxfire/firestore';

import Translate from '../../constants/translate';
import { ContentOverlay, TableView, PopUpModal } from '../../components'
import CommonHelper from '../../services/common';
import { allTemplatesFields, mentions, templateVM, specialLinks, levelOptions } from './viewModel'
import images from '../../images'
import { firestoreDB } from '../../services/helper';
import AddTemplateHTML from './addTemplateHTML';
import htmlToText from 'html-to-text';
import TemplateEditor from './templateEditor';
import TemplatePreview from '../settings/tradeinProworkflow/triggers/previewTemplate';
import { userLevel } from '../../services/enum';

const ListReducer = (state, action) => {
    function updateData() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateData()
        }
        default:
            return state;
    }
};



const EventTemplates = (props) => {
    const [templates, dispatch] = useReducer(ListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const pageLimit = 20
    const [hasMore, setHasMoreData] = useState({})
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.eventsSearchText ? localStorage.eventsSearchText : '')
    const [loader, setLoader] = useState(true)
    const [addHtml, setAddHtml] = useState({
        show: false,
        title: '',
        template: null
    })
    const [showEdiorModel, setShowEdiorModel] = useState({ show: false })
    const [formMentions, setMentions] = useState(mentions);
    const [showPreviewModel, setShowPreviewModel] = useState({ show: false })


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length > 2) {
                setDataload(true)
            }

            if (searchText === '') {
                setDataload(true)
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubEventTemplatelist && window.unsubEventTemplatelist.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        let allEventProDealers = CommonHelper.getAllEventsProDealers(props.dealersettings, true);
        var collectionPromise = [];

        for (let k = 0; k < Math.ceil(allEventProDealers.length / 10); k++) {
            let refTemplatesData = window.firebase.firestore().collection('eventsProTemplates')
                .where('isDeleted', '==', false)

            if (searchText?.trim()) {
                refTemplatesData = refTemplatesData.where('keywords', 'array-contains', searchText.toLowerCase().trim())
            }
            else {
                refTemplatesData = refTemplatesData.where('clientIDs', 'array-contains-any', CommonHelper.arrPaginate(allEventProDealers, 10, k + 1))
            }

            refTemplatesData = refTemplatesData.orderBy('addedDate', 'desc')

            if (hasMore[k]) {
                refTemplatesData = refTemplatesData
                    .startAfter(hasMore[k])
                    .limit(pageLimit)
            }
            else {
                if (isPaging) {
                    refTemplatesData = '';
                } else {
                    refTemplatesData = refTemplatesData
                        .limit(pageLimit)
                }

            }

            collectionPromise.push(refTemplatesData ? collection(refTemplatesData) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubEventTemplatelist = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    let i = 0;
                    let _hasMore = {};
                    allDocs.forEach(doc => {
                        if (doc.length > 0 && doc.length === pageLimit) {
                            _hasMore = {
                                ..._hasMore,
                                [i]: doc[doc.length - 1]
                            };
                        }
                        else {
                            _hasMore = {
                                ..._hasMore,
                                [i]: null
                            };
                        }
                        querySnapshot.push(...doc);
                        i++;
                    });
                    setHasMoreData(_hasMore);
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setLoader(false)
            setDataload(false)
            setPaging(false)
        }

        // let refTemplatesData = window.firebase.firestore().collection('eventsProTemplates')
        //     .where('isDeleted', '==', false)

        // if (searchText?.trim()) {
        //     refTemplatesData = refTemplatesData.where('keywords', 'array-contains', searchText.toLowerCase().trim())
        // }
        // else {
        //     refTemplatesData = refTemplatesData.where('clientIDs', 'array-contains-any', CommonHelper.getEventsProDealers(props.dealersettings, true))
        // }

        // refTemplatesData = refTemplatesData.orderBy('addedDate', 'desc')

        // if (hasMore.length > 0) {
        //     refTemplatesData = refTemplatesData
        //         .startAfter(hasMore[0])
        //         .limit(pageLimit)
        // }
        // else {
        //     refTemplatesData = refTemplatesData
        //         .limit(pageLimit)
        // }

        // window.unsubEventTemplatelist = refTemplatesData
        //     .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         if (change.doc.data() && CommonHelper.getEventsProDealers(props.dealersettings, true).some(a => change.doc.data().clientIDs.includes(a))) {
        //             snapshotDoc = convertVM({
        //                 ...change.doc.data(),
        //                 documentID: change.doc.id
        //             }, props);
        //             if (change.type === 'added') {
        //                 actionType = "ADD_LIST";
        //             }
        //             else if (change.type === 'modified') {
        //                 actionType = "UPDATE_LIST"
        //             }
        //             else if (change.type === 'removed') {
        //                 actionType = "REMOVE_LIST"
        //             }
        //         }
        //     }
        // })
        const _templates = [];
        if (!actionType) {
            // if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            //     setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            // }
            // else {
            //     setHasMoreData([]);
            // }
            querySnapshot.forEach(function (doc) {
                var logVM = convertVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _templates.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _.uniqBy(_templates, 'documentID').filter(a => CommonHelper.getAllEventsProDealers(props.dealersettings, true).some(b => a?.clientIDs?.includes(b))),
        });
        setLoader(false)
        setDataload(false)
        setPaging(false)
    }
    const editTemplate = (data) => {
        if (data.templateJSON) {
            setShowEdiorModel({ show: true, title: data.title, template: data })
        } else {
            setAddHtml({ show: true, title: data.title, template: data })
        }
    }

    const handleActionClick = (id, data) => {
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= _.find(levelOptions, { value: props.dealersettings.level })?.index);
        return (<div className="">
            <button
                type='button'
                className='btn btn-green float-left mr-2'
                style={{ padding: `3px 8px`, fontSize: `11px` }}
                onClick={e =>
                    setShowPreviewModel({
                        show: true,
                        template: data,
                        title: data.title
                    })
                }
            >
                {' '}
                <i
                    className='fa fa-eye custom-icon-preview'
                    aria-hidden='true'
                    style={{ paddingTop: '0px', marginRight: '4px' }}
                ></i>{' '}
                <span><Translate text={'Preview'} /></span>{' '}
            </button>
            <div title="Clone template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                hadleCloneTemplate(data)
            }}>
                <i className="ico icon-rearrange"></i>
            </div>
            {
                _.isEmpty(data.level) || _.find(_userLevel, { value: data.level }) ? (<>
                    <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {

                        editTemplate(data)

                    }}>
                        <i className={`ico icon-edit`}></i>
                    </div>
                    <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {

                        hadleDeletetemplate(data)

                    }}>
                        <i className={`ico icon-delete`}></i>
                    </div>
                </>) : (<>
                    <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2">
                        <i className="ico icon-lock"></i>
                    </div>
                    <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button">
                        <i className="ico icon-lock"></i>
                    </div>
                </>)
            }

        </div>)
    }

    const hadleCloneTemplate = (template) => {
        let templateToBePassed = {
            ...template,
            'documentID': window.firebase.firestore().collection(`eventsProTemplates`).doc().id,
            'projectId': props?.dealersettings?.client?.projectId ? props.dealersettings.client.projectId : null,
            'clientID': props.dealersettings.client.id,
            'clientIDs': [props.dealersettings.client.id],
            'addedBy': localStorage.uid,
            'modifiedBy': localStorage.uid,
            'addedDate': window.firebase.firestore.Timestamp.now(),
            'modifiedDate': window.firebase.firestore.Timestamp.now(),
            'visibility': 'private',
            'title': template.title + ' - Copy'
        }
        if (template.templateJSON) {
            setShowEdiorModel({ show: true, title: template.title + ' - Copy', template: templateToBePassed })
        } else {
            setAddHtml({ show: true, title: template.title + ' - Copy', template: templateToBePassed })
        }
    }

    const hadleDeletetemplate = (template) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`eventsProTemplates/${template.documentID}`)
                    .set({
                        'isDeleted': true,
                        'modifiedBy': localStorage.uid,
                        'modifiedDate': window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then(() => {
                        toast.notify(`Template deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleDetailclick = (e, id, data) => {
        editTemplate(data)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const convertVM = (doc) => {
        const objData = Object.assign({}, doc);
        const listVM = Object.assign({}, templateVM);
        for (let [key, value] of Object.entries(objData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }
        var textlimit = 150
        if (window.innerWidth < 1500) textlimit = 85;
        // dynamicOptions.forEach(rec => {
        //     if (doc.bodyHTML && doc.bodyHTML.includes(`@${rec.value}`)) {
        //         doc.bodyHTML = doc.bodyHTML.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
        //     }
        //     if (doc.templateJSON && doc.templateJSON.includes(`@${rec.value}`)) {
        //         doc.templateJSON = doc.templateJSON.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
        //     }
        // })
        listVM.templateJSON = doc.templateJSON ? doc.templateJSON : null;
        listVM.bodyHTML = doc.bodyHTML;
        var text = doc.bodyHTML ? htmlToText.fromString(doc.bodyHTML.replace(/<img[^>]*>/g, "")) : '';
        listVM.emailContent = text.length > textlimit ? text.substring(0, textlimit) + '...' : text;
        listVM.createdBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.addedBy);
        listVM.updatedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.modifiedBy);
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        if (props?.dealersettings?.client?.eventsPro?.categories?.some(a => a.value === doc.category)) {
            listVM.strCategoty = props?.dealersettings?.client?.eventsPro?.categories?.find(a => a.value === doc.category).name;
        }
        listVM.visibilityDOM = doc.visibility === 'private' ?
            (<div className="badge badge-pill badge-private"  >Private </div>)
            : (<div className="badge badge-pill badge-shared" >Shared  </div>)

        if (doc.level && !_.isEmpty(doc.levelID)) {
            listVM.dealersDOM = <div className="Vehicle-availability">
                {
                    CommonHelper.getDataByLevel(props.dealersettings, doc.level, doc.levelID, 'name').map((rec, index) => {
                        return <div key={index} title={doc.level}><span>{rec}</span></div>
                    })
                }
            </div>
        }
        else {
            listVM.dealersDOM = <div className="Vehicle-availability">
                {
                    CommonHelper.getDataByLevel(props.dealersettings, userLevel.INDIVIDUAL, doc.clientIDs, 'name').map((rec, index) => {
                        return <div key={index} title={'client'}><span>{rec}</span></div>
                    })
                }
            </div>
        }
        listVM.levelName = doc.level ? CommonHelper.autoCaps(doc.level) : 'Individual';
        return listVM
    }

    return (<>
        <div className={`middle-wrapper`}>
            <div className="settings-head mt-3">
                <div className="float-left">
                    <div className="filter-search search-icon">
                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => {
                            setSearchText(e.target.value)
                            if (e.target.value.length > 2) {
                                setLoader(true)
                            }
                        }} />
                    </div>
                </div>
                <div className="float-right">
                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                        setShowEdiorModel({ show: true, title: 'Add Email Template' })
                    }} ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Email Template'} /></button>
                </div>
                <div className="float-right">
                    <button type="button" className="btn btn-primary float-right mr-2" onClick={() => {
                        setAddHtml({ show: true, title: 'Add Email HTML', template: null })
                    }} ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Email HTML'} /></button>
                </div>
            </div>
            <div className={`mt-2`}>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        templates.length > 0 || !_.isEmpty(searchText) ? (<div className="common-table">
                            <TableView
                                datalist={_.orderBy(templates, 'addedDate.seconds', 'desc')}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allTemplatesFields}
                                dynamicFields={allTemplatesFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailclick}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                            />
                        </div>
                        ) : (
                            <div className="gdpr-btn-box gdpr-flex" style={{ height: `${windowSize.windowHeight}px` }}>
                                <div className="gdpr-btn-info"><Translate text={'Design the content for your email.'} /></div>
                                <div className="gdpr-btn-wrapper">
                                    <button type="button" className="btn btn-primary float-left" onClick={(e) => {
                                        e.preventDefault();
                                        setAddHtml({
                                            show: true,
                                            title: 'Add Template',
                                            template: null
                                        })
                                    }}> <i className="ico icon-add mr-2"></i> <Translate text={'Add Email HTML Template'} /> </button>
                                    <span><Translate text={'or'} upperCase={true} /></span>
                                    <button type="button" className="btn btn-secondary float-left ml-2" onClick={(e) => {
                                        e.preventDefault();
                                        setShowEdiorModel({
                                            show: true,
                                            title: 'Add Template',
                                            template: null
                                        })
                                    }}> <i className="ico icon-add mr-2"></i> <Translate text={'Custom Template Builder'} /> </button>
                                </div>
                            </div>
                        )
                }
            </div>
        </div>
        <PopUpModal show={showEdiorModel.show}>
            <TemplateEditor
                {...props}
                {...showEdiorModel}
                handleClose={() => { setShowEdiorModel({ show: false, title: '', template: null }) }}
                formMentions={formMentions}
            />
        </PopUpModal>
        <PopUpModal show={addHtml.show}>
            <AddTemplateHTML
                {...props}
                {...addHtml}
                handleClose={() => { setAddHtml({ show: false, title: '', template: null }) }}
                dealersettings={props.dealersettings}
                formMentions={[...formMentions, ...specialLinks.map(rec => { return { name: rec.label, value: rec.link } })]}
            />
        </PopUpModal>
        <PopUpModal show={showPreviewModel.show}>
            <TemplatePreview
                {...props}
                {...showPreviewModel}
                handleClose={() => {
                    setShowPreviewModel({ show: false })
                }}
            />
        </PopUpModal>
    </>
    )
}

export default EventTemplates;