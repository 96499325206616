/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import toast from 'toasted-notes'
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2'
import _ from 'lodash';
import { alerts } from './viewmodel';
import AlertTabs from './alertTab';
import { ContentOverlay } from '../../../../components';
import { mentions } from './viewmodel';


const CustomAlerts = (props) => {
    const [state, setState] = useState({});
    const [tabLoader, setTabLoader] = useState(true);
    const [loader, setLoader] = useState();
    const [key, setKey] = useState(alerts[0].value)
    const [email, setEmail] = useState('')
    const [allMentions, setAllMentions] = useState([])
    const fleetOptionsDF = props.dealersettings?.client?.fleetPro?.fleetOptionsDF || []

    useEffect(() => {
        const _options = [];
        fleetOptionsDF.forEach(rec => {
            _options.push({
                name: `${rec.name}`,
                value: `@${rec.name.replace(/ /g, '_')}`,
                id: `@${rec.value}`,
                dynamic: true
            })
        })
        setAllMentions([
            ...mentions,
            ..._options
        ]);
    }, [])

    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/fleetPro/${props.dealersettings.oemID}/alerts/${key}`)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.exists) {
                    const docData = querySnapshot.data();
                    fleetOptionsDF.forEach(rec => {
                        if (docData.emailTemplate && docData.emailTemplate.includes(`@${rec.value}`)) {
                            docData.emailTemplate = docData.emailTemplate.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
                        }
                        if (docData.smsTemplate && docData.smsTemplate.includes(`@${rec.value}`)) {
                            docData.smsTemplate = docData.smsTemplate.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
                        }
                    })
                    setState(docData);
                    setEmail(docData.emailTemplate);
                }
                else {
                    setState({})
                }
                setTabLoader(false)
            });
    }, [key])

    useEffect(() => {
        if (!_.isEmpty(email) && !_.isEmpty(state)) {
            setState({
                ...state,
                emailTemplate: email
            })
        }
    }, [email])

    const handleChange = obj => {
        setEmail(obj)
    };

    const onMentionChange = notesText => {
        setState({
            ...state,
            templateJSON: '',
            smsTemplate: notesText ? notesText : '',
        });
    };

    const onMentionSelect = (option, ownerID) => {
        console.log('select', option);
    };

    const saveTemplate = () => {
        setLoader(true);
        if (state.emailTemplate || state.smsTemplate) {
            let _dynamicFields = [];
            var _contentEmail = state.emailTemplate || null;
            var _contentSMS = state.smsTemplate || null;
            if (fleetOptionsDF) {
                fleetOptionsDF.forEach(rec => {
                    if (_contentEmail && _contentEmail.includes(`@${rec.name.replace(/ /g, '_')}`)) {
                        _dynamicFields.push(`${rec.value.replace(/@/g, '')}`);
                        _contentEmail = _contentEmail.replace(new RegExp(`@${rec.name.replace(/ /g, '_')}`, 'g'), `@${rec.value}`);
                    }
                    if (_contentSMS && _contentSMS.includes(`@${rec.name.replace(/ /g, '_')}`)) {
                        _dynamicFields.push(`${rec.value.replace(/@/g, '')}`);
                        _contentSMS = _contentSMS.replace(new RegExp(`@${rec.name.replace(/ /g, '_')}`, 'g'), `@${rec.value}`);
                    }
                })
            }
            state.emailTemplate = _contentEmail;
            state.smsTemplate = _contentSMS;
            state.dynamicFields = _.uniq(_dynamicFields);
        }

        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/fleetPro/${props.dealersettings.oemID}/alerts/${key}`).set({
            ...state,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                const docData = state;
                fleetOptionsDF.forEach(rec => {
                    if (docData.emailTemplate && docData.emailTemplate.includes(`@${rec.value}`)) {
                        docData.emailTemplate = docData.emailTemplate.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
                    }
                    if (docData.smsTemplate && docData.smsTemplate.includes(`@${rec.value}`)) {
                        docData.smsTemplate = docData.smsTemplate.replace(new RegExp(`@${rec.value}`, 'g'), `@${rec.name.replace(/ /g, '_')}`);
                    }
                })
                setState(docData);
                setLoader(false)
                toast.notify('Template updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }
    const handleOnChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setState({
            ...state,
            [name]: str,
        });
    };

    const handleOnChangeMultipleText = (val, name) => {
        setState({
            ...state,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
    }

    const removeEmail = (index, name) => {
        let arr = state[name] ? _.isArray(state[name]) ? state[name] : state[name].split(',') : []
        arr.splice(index, 1)
        setState({
            ...state,
            [name]: arr.join(',')
        })
    }

    return (
        <>
            <h2>Custom Alerts</h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey={key} transition={false} className="nav-fill" onSelect={(val) => {
                            setTabLoader(true)
                            setEmail('');
                            setState({});
                            setKey(val);
                        }} unmountOnExit={true} mountOnEnter={true}>
                            {
                                alerts.map((rec, index) => {
                                    return <Tab key={index} eventKey={rec.value} title={rec.name}>
                                        {
                                            tabLoader ? (<div className="mt-5" style={{ height: '200px' }}><ContentOverlay active={true} /></div>) :
                                                <AlertTabs
                                                    {...props}
                                                    activeTab={rec.value}
                                                    data={state}
                                                    email={email}
                                                    handleChange={handleChange}
                                                    handleOnChange={handleOnChange}
                                                    onMentionChange={onMentionChange}
                                                    onMentionSelect={onMentionSelect}
                                                    handleOnChangeMultipleText={handleOnChangeMultipleText}
                                                    removeEmail={removeEmail}
                                                    allMentions={allMentions}
                                                />
                                        }
                                    </Tab>
                                })
                            }

                        </Tabs>
                    </nav>
                    <div className="settings-footer mt-3">
                        <button type="button" className="btn btn-primary float-left" onClick={() => saveTemplate()}>
                            {
                                loader ?
                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                    : (<></>)
                            }
                            <Translate text={'save'} />
                        </button>
                    </div>

                </div>
            </div>

        </>
    );
}

export default CustomAlerts;