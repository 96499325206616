/** LIBRARIES */
import React, { PureComponent, Component } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    AntMonthPicker,
    ReactSelect,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    InputCheckBox,
    DynamicInput
} from '../../../components';
import _ from 'lodash'
import moment from 'moment';
import { tipStatus } from '../../../services/enum';
import CommonHelper from '../../../services/common';

class EnquiryInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        let { status, enquiryType, serviceType,
            label, purchaseIntention, contractNumber,
            depositAmount, depositReciptNumber, grossAmount, origin, campaign,
            lostReason, lostSubReason, deliveryDate, leadSource,
            chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
            isFinanceRequired, financeStatus, dynamicFields
        } = this.props.fields;

        const { dealersettings, canModifyTIP, errors, currencySymbol, type, origins,
            enquiryOptionsDF, lostReasons, lostSubReasons, handleSelectSave, handleLostReasonSave,
            // enquiryTypes, leadSources, origins, campaigns, labels, financeEnabled,
            // __financestatus, financeStatuses, serviceTypes, enquiryAllStatus,
            // handlePIDateSave, isPIValid, handleDeliveryDateChange, handleOnCapChange,
            // saveEnquiryField, cancelEnquiryField, handleDateChange,
            // handleCheckChange, onCurrencyChange, handleOnChange,
            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange
        } = this.props;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const modifyLostReason = true;//((!_.isEmpty(_permissions) && _permissions.modifyLostReason) ? true : false);


        const isStatusOpen = (_.isEmpty(status) || status === tipStatus.NEW) ? true : false;
        const isStatusLost = (status === tipStatus.LOST) ? true : false;

        return (
            <>

                {(() => {

                    if (type === 'origin') {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={'origin'} /></label>
                                    <ReactSelect
                                        options={origins}
                                        name={"origin"}
                                        placeholder={'select origin'}
                                        onChange={handleSelectSave}
                                        value={origin}
                                        classNamePrefix={`cursor-pointer ${errors["origin"]} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={canModifyTIP  ? false : true}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'lostReason' && isStatusLost) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={'lostReason'} /></label>
                                    <ReactSelect
                                        options={lostReasons}
                                        name={'lostReason'}
                                        placeholder={'select lost reason'}
                                        onChange={handleLostReasonSave}
                                        value={lostReason}
                                        classNamePrefix={`cursor-pointer ${errors['lostReason']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={(canModifyTIP && (modifyLostReason || !lostReason || isStatusOpen)) ? false : true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }
                    else if (type === 'lostSubReason' && isStatusLost && !_.isEmpty(lostSubReasons)) {
                        return (
                            <>
                                <div className="form-group col-md-6">
                                    <label><Translate text={'lostSubReason'} /></label>
                                    <ReactSelect
                                        options={lostSubReasons}
                                        name={'lostSubReason'}
                                        placeholder={'select lost sub reason'}
                                        onChange={handleSelectSave}
                                        value={lostSubReason}
                                        classNamePrefix={`cursor-pointer ${errors['lostSubReason']} basic-select`}
                                        //removeClearable={true}
                                        isDisabled={(canModifyTIP && (modifyLostReason || !lostSubReason || isStatusOpen)) ? false : true}
                                    ></ReactSelect>
                                </div>
                            </>
                        )
                    }

                    else {
                        return (
                            <>
                                {!_.isEmpty(enquiryOptionsDF) && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => {
                                    if (type === info.value) {
                                        return (<div key={info.value} className="form-group col-md-6">
                                            <label>{info.name}</label>
                                            <DynamicInput
                                                index={'0' + index}
                                                data={info}
                                                errors={errors ? errors : {}}
                                                id={info.value}
                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                handleCheckChange={handleDynamicCheckChange}
                                                handleOnChange={handleDynamicOnChange}
                                                saveEnquiryField={saveDynamicField}
                                                cancelEnquiryField={cancelDynamicField}
                                                onCurrencyChange={onDynamicNumberChange}
                                                handleDateChange={handleDynamicDateChange}
                                                handleSelectSave={handleDynamicSelectSave}
                                                handleMultiSelectChange={handleDynamicMultiSelectChange}
                                                currencySymbol={currencySymbol}
                                                inputAutoFormat={true}
                                            >
                                            </DynamicInput>
                                        </div>)
                                    }
                                })}
                            </>
                        )
                    }

                })()}

            </>

        );
    }
}

export default EnquiryInputs;