import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import CommonHelper from '../../services/common';
import { PopUpModal } from '../../components';

import Translate from '../../constants/translate';
import { TableView } from '../../components';
import { alldisplayrecipientFields } from '../bulksms/viewModel';
import RecipientReply from './recipientReply'

const DisplayRecipients = ({ campaignID,title, show, handleClose, data }) => {
  const [displayRecipients, setDisplayRecipients] = useState({
    show: false,
    data: null
  });
  const [loader, setLoader] = useState(true)
  const [recipients, setRecipients] = useState([])
	const [hasMore, setHasMoreData] = useState([])

  const [windowSize, setWindowSize] = useState({
    windowWidth: 750,
    windowHeight: window.innerHeight - 293,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // console.log('fetching data')
			let recipientsToAdd = []
      try {

        let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients')
        if (title.includes('Delivered')) {
          ref = ref.where('status', '==', 'delivered')
        } else if (title.includes('Failed')) {
          ref = ref.where('status', '==', 'failed')
        }
        const snapshots = await ref.limit(100).get()
          if (!snapshots.empty) {
            snapshots.forEach((doc) => {
              recipientsToAdd.push(doc.data())
            });
            setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
          }	
        setRecipients(recipientsToAdd)
        setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    } 

    fetchData()
  }, [])

  const fetchMoreData = async () => {
		if (recipients.length === data.recipientsCount) {
			return
		}
		let recipientsToAdd = []
		let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients').startAfter(hasMore[0]).limit(100)
		const snapshots = await ref.get()
		if (!snapshots.empty) {
			snapshots.forEach((doc) => {
				recipientsToAdd.push(doc.data())
			});
			setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
			// recipientsToAdd = recipientsToAdd.filter(a => data.recipientIDs.some(id => id === a.documentID))
			setRecipients(_.uniqBy([...recipients, ...recipientsToAdd], 'phone'))
		}	
	}

  const handleActionClick = id => {
  };
  const handleRowSingleClick = () => {
  }
  const filterByType = (recs) => {
    let filteredRecs = [...recs]
    if (title.includes('Delivered')) {
      filteredRecs = recs.filter(a => a.status === 'delivered')
    } else if (title.includes('Failed')) {
      filteredRecs = recs.filter(a => a.status === 'failed')
    }

    filteredRecs = filteredRecs.map(a => {
      return {
        ...a,
        _phone: CommonHelper.getFullPhone(a.phoneCode, a.phone),
        _replyCount: !_.isEmpty(a.replies) ? <>
          <a href="#" onClick={(e) => { e.preventDefault(); handleReplyOpen(a) }}>
            <div className="badge-sms-reply"><i className="ico icon-sms"> </i> {a.replies.length} Reply</div>
          </a>
        </> :
          <>
            <a href="#" onClick={(e) => { e.preventDefault(); }}>
              <div className="badge-sms-reply disable"><i className="ico icon-sms"> </i> 0 Reply</div>
            </a>
          </>
      }
    })

    return filteredRecs
  }

  const handleReplyOpen = (_info) => {
    setDisplayRecipients({ show: true, data: _info })
  }


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName='modal-dialog modal-dialog-centered modal-delivered-sms'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={title} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="settings-head">
              </div>
              <div className="common-table">
                <TableView
                  isReorderable={false}
                  datalist={filterByType(recipients)}
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={alldisplayrecipientFields}
                  dynamicFields={alldisplayrecipientFields
                    .filter(e => e.default === true)
                    .map(v => _.pick(v, ['value']).value)}
                  settingsLoader={false}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleRowSingleClick}
                  isSettings={false}
                  hasMore={hasMore}
                  isPaging={true}
                  handlePagination={fetchMoreData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-default float-right ml-2 '
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'Close'} />
          </button>
        </Modal.Footer>
      </Modal>
      <PopUpModal show={displayRecipients.show}>
        <RecipientReply
          show={displayRecipients.show}
          handleClose={() => { setDisplayRecipients({ show: false, data: null }) }}
          title={displayRecipients.title}
          data={displayRecipients.data}
        />
      </PopUpModal>
    </>
  );
};

export default DisplayRecipients;
