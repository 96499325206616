import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { InputText } from '../../../components'

const AddGuest = (props) => {
    const [guest, setGuest] = useState(props.guest ? props.guest : { documentID: window.firebase.firestore().collection('eventsProAttendees').doc().id })
    const [errorFields, setErrorFields] = useState({});
    const newGuest = !_.isEmpty(props.guest) ? false : true

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setGuest({
                ...guest,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setGuest({
                    ...guest,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setGuest({
                    ...guest,
                    [name]: str,
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9 \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            setGuest({
                ...guest,
                [name]: telephone
            });
        }
    };


    return _.isEmpty(guest) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >First Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'first name'}
                                        className={`form-control ${errorFields["firstName"]}`}
                                        name="firstName"
                                        onChange={handleOnChange}
                                        value={guest.firstName}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                        <label >Middle Name</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'middle name'}
                                            className={`form-control ${errorFields["middleName"]}`}
                                            name="middleName"
                                            onChange={handleOnChange}
                                            value={guest.middleName}
                                        />
                                    </div>
                                <div className="form-group col-md-12">
                                    <label >Last Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'last name'}
                                        className={`form-control ${errorFields["lastName"]}`}
                                        name="lastName"
                                        onChange={handleOnChange}
                                        value={guest.lastName}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Phone</label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={'phone'}
                                        pattern="[0-9]*"
                                        className={`form-control `}
                                        name="phone"
                                        onChange={onHandleTelephoneChange}
                                        value={guest.phone}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Email</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'email'}
                                        className={`form-control ${errorFields["email"]}`}
                                        name="email"
                                        onChange={handleOnChange}
                                        value={guest.email}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => {
                    let errors = {};
                    let formIsValid = true;
                    let errorClass = 'input_error';
                    if (guest['email']) {
                        if (typeof guest['email'] !== "undefined") {
                            let lastAtPos = guest['email'].lastIndexOf('@');
                            let lastDotPos = guest['email'].lastIndexOf('.');

                            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && guest['email'].indexOf('@@') === -1 && lastDotPos > 2 && (guest['email'].length - lastDotPos) > 2)) {
                                formIsValid = false;
                                errors['email'] = errorClass;
                            }
                        }
                    }
                    if (_.isEmpty(guest.firstName)) {
                        formIsValid = false;
                        errors['firstName'] = errorClass;
                    }
                    setErrorFields(errors)
                    if (formIsValid === true) {
                        props.handleClose(guest, newGuest)
                    }
                }}>
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer >
        </Modal >
    );
}

export default AddGuest;