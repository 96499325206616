import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Tabs, Tab } from 'react-bootstrap';

import { SidePanel } from '../../components';

import { MainContext } from '../layout/provider';
import ChatUserListItem from './chatUserListItem'

import './chat.scss';

const ChatSidePanel = ({
	sidepanelClose,
	clsActive,
	clsName,
	title,
	sendAs,
	contact,
	enquiry,
	sharePanel,
	service,
	tradeinPro,
	unreadCounts,
	setUnreadCounts,
	shouldCalculateCounts,
}) => {
	const [searchText, setSearchText] = useState('');
	const [sentToList, setSentToList] = useState([]);
	const [userChats, setUserChats] = useState([]);
	const [contactChats, setContactChats] = useState([]);
	const [activeTab, setActiveTab] = useState('teammates');
	const { dealersettings, clientUsers } = useContext(MainContext);

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--chatItemsContainerHeight',
				window.innerHeight - 100 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--chatItemsContainerHeight',
			window.innerHeight - 100 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const processUsers = async () => {
			window.firebase
				.firestore()
				.collection('messages')
				.where('oemID', '==', dealersettings.client.settingsID)
				.onSnapshot(querySnapshot => {
					let docs = [];
					let contactDocs = [];
					querySnapshot.forEach(rec => {
						let data = rec.data();
						let messageID = rec.id.split('-').sort().join('-')
						if (messageID === rec.id) {
							data.dbAddress = rec.id;
							docs.push(data);
						}
						if (data.conversationType === 'group' && data.clientID === dealersettings.client.id) {
							// console.log('group chat', data)
							data.dbAddress = rec.id;
							docs.push(data);
						}
						if (
							data.clientID === dealersettings.client.id
							&& !_.isEmpty(data.participantInfo)
							&& data.participantInfo.filter(participant => participant.isAMSME === true).length
							&& data.participantInfo.filter(participant => participant.id === localStorage.uid).length
						) {
							data.dbAddress = rec.id;
							contactDocs.push(data);
						}
					});
					// console.log('111', docs)
					// console.log('contactDocs', contactDocs)
					let updatedUsers = clientUsers.filter(a => a.isDisabled !== true).map(clientUser => {
						if (clientUser.id === localStorage.uid) {
							return clientUser;
						}

						let userToReturn = clientUser;
						userToReturn.isRead = true;

						docs.forEach(doc => {
							// if (doc.dbAddress === '4gvaiyt1sFdco59yMNObgaQDZYQ2-8apfvnXrxadOglHQ0nrSxrEZWrj2') {
							// 	console.log('first1', doc)
							// 	console.log('first2', clientUser)
							// }
							// check if it belongs to currentUSer
							if (
								doc?.lastMessage?.idTo === localStorage.uid ||
								doc?.lastMessage?.idFrom === localStorage.uid
							) {
								// check if it belongs to the current user in loop
								if (
									doc?.lastMessage?.idTo === clientUser.id ||
									doc?.lastMessage?.idFrom === clientUser.id
								) {
									userToReturn = {
										...userToReturn,
										...doc,
									};
									if (doc?.lastMessage?.idTo !== localStorage.uid) {
										userToReturn.isRead = true;
									}
								}
							}
						});
						return userToReturn;
					});

					let updatedGroupChats = []
					docs.forEach(doc => {
						if (doc.conversationType === 'group' && doc.clientID === dealersettings.client.id) {
							if (doc.participants.includes(localStorage.uid)) {
								updatedGroupChats.push({ ...doc })
							}
						}
					})


					updatedGroupChats = updatedGroupChats.filter((thing, index, self) =>
						index === self.findIndex((t) => (
							t.dbAddress === thing.dbAddress
						))
					)

					updatedUsers = [...updatedUsers, ...updatedGroupChats]

					updatedUsers = _.orderBy(updatedUsers, ['timestamp'], ['desc']);
					let updatedUsersWithTimeStamp = updatedUsers.filter(
						user => user.timestamp,
					);
					let updatedUsersWithoutTimeStamp = updatedUsers.filter(
						user => !user.timestamp,
					);
					updatedUsers = [
						...updatedUsersWithTimeStamp,
						...updatedUsersWithoutTimeStamp,
					];

					if (shouldCalculateCounts) {
						let counts = {};
						//console.log('updatedUsers', updatedUsers);
						updatedUsers.forEach(async user => {
							if (!user.isRead && user.dbAddress) {
								try {
									const ref = window.firebase
										.firestore()
										.collection('messages')
										.doc(user.dbAddress)
										.collection(user.dbAddress)
										.where('isRead', '==', false)
										.where('idTo', '==', localStorage.uid);

									const snaps = await ref.get()
									// console.log('snaps', snaps);
									if (snaps.size > 0) {
										counts[user.id] = snaps.size;
										if (!counts.total) {
											counts.total = snaps.size;
										} else {
											counts.total = counts.total + snaps.size;
										}
									}
									setUnreadCounts(counts);
								} catch (error) {
									console.error(error);
								}
							}

							if (user.conversationType === 'group' && user.groupPendingReads && user.groupPendingReads.includes(localStorage.uid)) {

								const ref = window.firebase
									.firestore()
									.collection('messages')
									.doc(user.dbAddress)
									.collection(user.dbAddress)
									.where('groupPendingReads', 'array-contains', localStorage.uid)

								const snaps = await ref.get()

								if (snaps.size > 0) {
									counts[user.dbAddress] = snaps.size;
									if (!counts.total) {
										counts.total = snaps.size;
									} else {
										counts.total = counts.total + snaps.size;
									}
								}
								setUnreadCounts(counts);
							}
						});
						contactDocs.forEach(async user => {
							if (!user.isRead && user.dbAddress) {
								try {
									const ref = window.firebase
										.firestore()
										.collection('messages')
										.doc(user.dbAddress)
										.collection(user.dbAddress)
										.where('isRead', '==', false)
										.where('idTo', '==', localStorage.uid);

									const snaps = await ref.get()
									//console.log('snaps11111', snaps);
									if (snaps.size > 0) {
										counts[user.dbAddress] = snaps.size;
										if (!counts.total) {
											counts.total = snaps.size;
										} else {
											counts.total = counts.total + snaps.size;
										}
									}
									setUnreadCounts(counts);
								} catch (error) {
									console.error(error);
								}
							}
						})
						// console.log('counts After calculation', counts)
					}

					setUserChats(updatedUsers);
					setContactChats(contactDocs)
					// setLoader(false)
				});
		};
		processUsers();
	}, [dealersettings.client.id, dealersettings.client.settingsID, shouldCalculateCounts, clientUsers]);

	const handleAddSentToList = id => {
		setSentToList([...sentToList, id]);
	};

	const filterClientUsers = clientUser => {
		if (clientUser.id === localStorage.uid) {
			return false;
		}

		if (searchText === '') {
			return true;
		}

		if (clientUser?.name?.toLowerCase().includes(searchText?.toLowerCase())) {
			return true;
		}

		if (
			!_.isEmpty(clientUser.participantInfo)
			&& clientUser.participantInfo.filter(participant => participant.isAMSME === true).length
			&& clientUser.participantInfo.filter(participant => participant.isAMSME === true)[0]?.name?.toLowerCase().includes(searchText?.toLowerCase())
		) {
			return true;
		}
	};

	return (
		<SidePanel
			clsActive={clsActive}
			clsName={clsName}
			sidepanelClose={sidepanelClose}
			title={title}
			activeTab={activeTab}
		>
			<div className='chat-list-wraper position-relative'>
				{contactChats && contactChats.length > 0 ? (
					<>
						<div className='settings-tab-wrap tabs chat-tabs'>
							<div className='tabs-wraper' id='chat-tabs'>
								<nav>
									<Tabs
										defaultActiveKey={activeTab}
										transition={false}
										className='nav-fill'
										onSelect={(key) => {
											setActiveTab(key)
										}}
									>
										<Tab eventKey='teammates' title={'Teammates'} tabClassName='teammates-tab'>
											<div className='chat-search'>
												<input
													placeholder='Search'
													aria-label='Search'
													value={searchText}
													onChange={e => setSearchText(e.target.value)}
												/>
											</div>
											<div className='chat-items-container'>
												{userChats &&
													userChats.length > 0 &&
													userChats
														.filter(clientUser => filterClientUsers(clientUser))
														.map((clientUser) => (
															<ChatUserListItem
																clientUser={clientUser}
																dealersettings={dealersettings}
																key={clientUser.id ? clientUser.id : clientUser.dbAddress}
																id={clientUser.id ? clientUser.id : clientUser.dbAddress}
																sidepanelClose={sidepanelClose}
																sharePanel={sharePanel}
																sendAs={sendAs}
																contact={contact}
																enquiry={enquiry}
																service={service}
																tradeinPro={tradeinPro}
																sentToList={sentToList}
																handleAddSentToList={handleAddSentToList}
																clientUsers={clientUsers}
																unreadCounts={unreadCounts}
																setUnreadCounts={setUnreadCounts}
																contactChat={false}
															/>
														))}
											</div>
										</Tab>
										<Tab eventKey='contacts' title={`Contacts`} tabClassName='contacts-tab'>
											<div className='chat-search'>
												<input
													placeholder='Search'
													aria-label='Search'
													value={searchText}
													onChange={e => setSearchText(e.target.value)}
												/>
											</div>
											<div className='chat-items-container'>
												{contactChats &&
													contactChats.length > 0 &&
													contactChats
														.filter(clientUser => filterClientUsers(clientUser))
														.map((clientUser) => (
															<ChatUserListItem
																clientUser={clientUser}
																dealersettings={dealersettings}
																key={clientUser.id ? clientUser.id : clientUser.dbAddress}
																id={clientUser.id ? clientUser.id : clientUser.dbAddress}
																sidepanelClose={sidepanelClose}
																sharePanel={sharePanel}
																sendAs={sendAs}
																contact={contact}
																enquiry={enquiry}
																service={service}
																tradeinPro={tradeinPro}
																sentToList={sentToList}
																handleAddSentToList={handleAddSentToList}
																clientUsers={clientUsers}
																unreadCounts={unreadCounts}
																setUnreadCounts={setUnreadCounts}
																contactChat={true}
															/>
														))}
											</div>
										</Tab>
									</Tabs>
								</nav>
							</div>
						</div>
					</>
				) : (
					<>
						<div className='chat-search'>
							<input
								placeholder='Search'
								aria-label='Search'
								value={searchText}
								onChange={e => setSearchText(e.target.value)}
							/>
						</div>
						<div className='chat-items-container'>
							{userChats &&
								userChats.length > 0 &&
								userChats
									.filter(clientUser => filterClientUsers(clientUser))
									.map((clientUser, index) => (
										<ChatUserListItem
											clientUser={clientUser}
											dealersettings={dealersettings}
											key={clientUser.id ? clientUser.id : clientUser.dbAddress}
											id={clientUser.id ? clientUser.id : clientUser.dbAddress}
											sidepanelClose={sidepanelClose}
											sharePanel={sharePanel}
											sendAs={sendAs}
											contact={contact}
											enquiry={enquiry}
											service={service}
											tradeinPro={tradeinPro}
											sentToList={sentToList}
											handleAddSentToList={handleAddSentToList}
											clientUsers={clientUsers}
											unreadCounts={unreadCounts}
											setUnreadCounts={setUnreadCounts}
											contactChat={false}
										/>
									))}
						</div>
					</>
				)}

			</div>
		</SidePanel>
	);
};

export default ChatSidePanel;
