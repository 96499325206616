/** LIBRARIES */
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { InputText } from '../../components';
import { firestoreDB } from "../../services/helper";
import { default as _images } from '../../images';
import { contactBasicDetailsVM } from "../contacts/viewModel";
import { objTradeIn } from "../tradeIn/viewModel";
import { enquiryBasicTDVM } from "../pipeline/viewModel";
import { objServiceBasicVM } from "../service/viewModel";

export default class EvalScan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                regNum: '',
                vinNum: '',
                evalId: ''
            },
            errors: {},
            isLoading: false,
            pageLoading: false,
            evalList: []
        }
    }


    clearList = () => {
        this.setState({ evalList: [] });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '')).toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
        if (name === 'vinNum' && _.isEmpty(fields[name])) this.clearList();
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!fields.regNum && fields.vinNum && fields.evalId) {
            formIsValid = false;
            errors['regNum'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleSearch();
        }
        else
            return formIsValid;
    };

    handleSearch = () => {
        this.setState({ isLoading: true, evalList: [] })
        const { dealersettings, clientID } = this.props;
        let state = Object.assign({}, this.state.fields);
        var objData = Object.assign({}, {
            "clientID": clientID ? clientID : dealersettings?.client?.id,
        });

        if (state.regNum) objData.registration = state.regNum;
        else if (state.vinNum) objData.vin = state.vinNum;
        else if (state.evalId) objData.evalid = state.evalId;

        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //console.log('handlePpsrSearch', objData);
        const tradeinplussendEmail = window.firebase.functions().httpsCallable('evalexpert-searchEval');
        tradeinplussendEmail(objData).then((response) => {
            //console.log('evalexpert-searchEval', response, response.data?.success);
            this.setState({ isLoading: false })
            if (response.data.success) {
                this.setState({ evalList: Object.assign([], response.data?.data) });
            }
            else if (response?.data?.message) {
                Swal.fire(response.data.message, '', 'info')
            }
            else {
                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }

        });
    };

    handleSelect = async (evalData) => {
        const { dealersettings, enquiryID, enquiry, contact, tradeinProID, serviceID, service } = this.props;
        // const result = await Swal.fire({
        //     title: CommonHelper.showLocale(this.props, 'Are you sure?'),
        //     text: CommonHelper.showLocale(this.props, 'Do you want to create trade-in with the selected vehicle.'),
        //     icon: 'info',
        //     showCancelButton: true,
        //     confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
        //     cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
        //     allowOutsideClick: false,
        //     allowEscapeKey: false
        // })

        // if (!result.value) {
        //     return
        // }

        this.setState({ pageLoading: true })
        let objData = Object.assign({}, objTradeIn);

        objData.sent = true;
        objData.evalScan = true;
        objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.documentID = firestoreDB(dealersettings).firestore().collection('tradeins').doc().id;
        objData = CommonHelper.setClientOrGroupOrRegionOrOemID(objData, dealersettings, null, objData.clientID, true)

        if (!_.isEmpty(contact)) {
            let _contact = Object.assign({}, contactBasicDetailsVM);
            for (let [key, value] of Object.entries(contact)) {
                if (_contact.hasOwnProperty(key))
                    _contact[key] = value;
            }
            objData.contact = _contact;
            objData.contactID = _contact.documentID;
        }

        if (!_.isEmpty(enquiry)) {
            let _enquiry = Object.assign({}, enquiryBasicTDVM);
            for (let [key, value] of Object.entries(enquiry)) {
                if (_enquiry.hasOwnProperty(key))
                    _enquiry[key] = value;
            }
            objData.enquiry = _enquiry;
            objData.enquiryID = _enquiry.documentID;
        }
        else if (enquiryID)
            objData.enquiryID = enquiryID;

        if (!_.isEmpty(service)) {
            let _service = Object.assign({}, objServiceBasicVM);
            for (let [key, value] of Object.entries(service)) {
                if (_service.hasOwnProperty(key))
                _service[key] = value;
            }
            objData.service = _service;
            objData.serviceID = _service.documentID;
        }
        else if (serviceID)
            objData.serviceID = serviceID;

        if (tradeinProID)
            objData.tradeinProID = tradeinProID;

        objData = CommonHelper.convertEvalMappingVM({ ...evalData, EvalID: evalData.ID }, objData);

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                if (propName !== 'images') delete objData[propName];
            }
        }

        const updateRef = firestoreDB(dealersettings).firestore().collection('tradeins').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.setState({ pageLoading: false })
            toast.notify('Trade-In saved successfully', {
                duration: 2000
            })

            let _makeModel = (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');
            let _logNote = 'Trade-In added'

            let _ID = (objData.enquiryID ? objData.enquiryID : objData.serviceID ? objData.serviceID : objData.tradeinProID)
            let _subType = (objData.serviceID ? 'service' : objData.tradeinProID ? 'tradeinPro' : 'enquiry')

            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, (_ID), _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', _subType);

            this.props.handleClose(objData.documentID, objData);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    }


    render() {
        const { regNum, vinNum, evalId } = this.state.fields;
        const { show } = this.props;
        const { isLoading, evalList, pageLoading } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName=" modal-dialog-scrollable modal-dialog-centered modal-ppsr-manager">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Search Eval'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLoading || pageLoading
                                ?
                                <>
                                    <div className="dashboard-widget-loader h-100">
                                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"><Translate text={'Loading...'} /></span>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                :
                                <>
                                </>
                        }

                        <div className="container-fluid">
                            <div className="row">
                                <div className="form-style w-100">
                                    <div className={`form-row ${isLoading || pageLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'vehicleRegNo'} /></label>
                                            <InputText
                                                name="regNum"
                                                value={regNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'registration number'}
                                                className={`form-control ${this.state.errors["regNum"]}`}
                                            />
                                        </div>

                                    </div>

                                    <div className="ppsr-text-divider">OR</div>

                                    <div className={`form-row ${isLoading || pageLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'chassisNo'} /></label>
                                            <InputText
                                                type="text"
                                                name="vinNum"
                                                value={vinNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["vinNum"]}`}
                                            />
                                        </div>

                                    </div>

                                    {/* <div className="ppsr-text-divider">OR</div>

                                    <div className={`form-row ${isLoading || pageLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'evalId'} /></label>
                                            <InputText
                                                type="text"
                                                name="evalId"
                                                value={evalId}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'eval id'}
                                                className={`form-control ${this.state.errors["evalId"]}`}
                                            />
                                        </div>

                                    </div> */}

                                    {
                                        !_.isEmpty(evalList)
                                            ?
                                            <>
                                                <div className="ppsr-table-wrap">
                                                    <h3><Translate text={'vehicles'} toUpperCase={true} /></h3>
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'date'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="60%"><Translate text={'vehicleModel'} toUpperCase={true} /></th>
                                                                    <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'colour'} /></th>
                                                                    <th scope="col" className="head-light border-left-0" width="10%"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    _.orderBy(evalList, ["EntryDate"], ["desc"]).map((_data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{_data.EntryDate ? moment(_data.EntryDate).format('DD MMM YYYY h:mm A') : '--'}</td>
                                                                            <td>
                                                                                {CommonHelper.bindEvalModelDescription(_data)}
                                                                            </td>

                                                                            <td className="border-right-0">{_data.Ext_Colour ? _data.Ext_Colour : '--'}</td>
                                                                            <td className="border-left-0">
                                                                                <button className="btn btn-sm btn-secondary" style={{ cursor: 'pointer', float: 'right' }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        if (!pageLoading) this.handleSelect(_data);
                                                                                    }}>
                                                                                    <Translate text={'select'} />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <></>
                                    }



                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}> <Translate text={'cancel'} /></button>

                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'search'} />
                        </button>
                    </Modal.Footer>


                </Modal>


            </>


        );
    }
}

