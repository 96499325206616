import React, { useEffect, useState } from 'react';
import moment from "moment";
import _ from 'lodash'
import Translate from '../../constants/translate';
const CustomToolbar = (toolbar) => {
    const [searchText, setSearchText] = useState(toolbar.searchText)

    // useEffect(() => {
    //     setSearchText(toolbar.searchText);
    // }, [toolbar.searchText])

    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)

        toolbar.handlechangeyear(newDate.getFullYear())
        toolbar.onNavigate("prev", newDate);
    };
    const goToNext = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)
        toolbar.handlechangeyear(newDate.getFullYear())
        toolbar.onNavigate("next", newDate);
    };

    const goToToday = () => {
        const now = moment()._d;
        toolbar.date.setDate(now.getDate());
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        if (toolbar.monthData !== now.getMonth()) {
            toolbar.handlechangemonth(now.getMonth())
        }
        setDateRange(now, toolbar.viewstate)
        toolbar.handlechangeyear(moment().year())
        toolbar.onNavigate("current");
    };

    const setDateRange = (date, view) => {
        if (view === 'month')
            toolbar.setDateRange({
                startDate: moment(date).startOf(view).startOf('day').add(-7, 'day'),
                endDate: moment(date).endOf(view).endOf('day').add(7, 'day')
            })
        else if (view === 'agenda')
            toolbar.setDateRange({
                startDate: moment(date).startOf('month').startOf('day').add(-1, 'day'),
                endDate: moment(date).endOf('month').endOf('day').add(1, 'day')
            })
        else
            toolbar.setDateRange({
                startDate: moment(date).startOf(view).startOf('day'),
                endDate: moment(date).endOf(view).endOf('day')
            })
    }

    const _permissions = ((!_.isEmpty(toolbar.dealersettings) &&
        !_.isEmpty(toolbar.dealersettings.rolePermissions) &&
        !_.isEmpty(toolbar.dealersettings.rolePermissions.servicePermissions)) ? toolbar.dealersettings.rolePermissions.servicePermissions : null);
    const scheduleAccess = ((!_.isEmpty(_permissions) && _permissions.scheduleAccess) ? true : false);

    return (
        <div className="filter-sub-panel">
            <div className="d-flex justify-content-between ">
                <div className="filter-search">
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="quick search..."
                            value={searchText ? searchText : ''}
                            onChange={(e) => {
                                let _val = e.target.value
                                setSearchText(_val);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    toolbar.handlesearchText(searchText)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchText && searchText.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchText('');
                                            toolbar.handlesearchText('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }
                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toolbar.handlesearchText(searchText)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="calendar-year">{toolbar.label}</div>
                <div className="">
                    <div className="calendar-switch-buttons mr-0">
                        {/* {
                            scheduleAccess
                                ?
                                <> */}
                        <div className="service-reject">
                            <button type="button"
                                className="btn btn-sm btn-rejected float-left mr-2"
                                onClick={() => {
                                    if (toolbar.cancelledCount.length > 0)
                                        toolbar.handleRejectShow();
                                }}
                            >
                                <i className="ico icon-rejected"></i>
                                <Translate text={'cancelledAppointments'} />
                                <span className="badge badge-pill badge-rejected-count ml-1">{toolbar.cancelledCount.length}</span>
                            </button>
                        </div>
                        {/* </>
                                :
                                <></>
                        } */}
                        <div className="btn-group">
                            <button className="btn btn-default" onClick={goToToday}><Translate text={'today'} /></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToBack}> <i className="fa fa-chevron-left"></i></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToNext}> <i className="fa fa-chevron-right"></i></button>
                        </div>

                    </div>
                    {
                        scheduleAccess
                            ?
                            <>
                                <div className="filter-add-button">
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        toolbar.handleAddActivity()
                                    }}> <i className="ico icon-add"></i> <Translate text={'appointment'} /></button>
                                </div>
                            </>
                            :
                            <></>
                    }
                </div>

            </div>
        </div>
    );
};

export default CustomToolbar;