export const objFilter = {
    documentID: null,
    name: '',
    value: null,
    type: 'custom',
    isFav: [],
    module: '',
    visibility: 'private',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false
};