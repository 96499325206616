
export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByMake', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 4, i: 'ActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },

]

export const pipelineCountVMNEW = {
    appointmentsMonth: 0,
    appointmentsToday: 0,
    cancelledJobsMonth: 0,
    cancelledJobsToday: 0,
    checkedInMonth: 0,
    checkedInToday: 0,
    completedJobsMonth: 0,
    completedJobsToday: 0,
    ownerApprovedMonth: 0,
    ownerApprovedToday: 0,
    ownerDeclinedMonth: 0,
    ownerDeclinedToday: 0,
    pendingJobsMonth: 0,
    pendingJobsToday: 0,
    workInProgressMonth: 0,
    workInProgressToday: 0,
}

export const pipelinestatus = [
    {
        name: "New",
        value: "New",
        color: "#4967cc",
        sortOrder: 1
    },
    {
        name: "In Progress",
        value: "InProgress",
        color: "#29abe2",
        sortOrder: 2
    },
    {
        name: "Pending",
        value: "Pending",
        color: "#f7931e",
        sortOrder: 3
    },
    {
        name: "Completed",
        value: "Completed",
        color: "#049b8c",
        sortOrder: 4
    },
    {
        name: "Won",
        value: "Won",
        color: "#39b54a",
        sortOrder: 5
    },
    {
        name: "Lost",
        value: "Lost",
        color: "#ef4136",
        sortOrder: 6
    }

]