import React from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';

const PreviewTemplate = props => (
	<Modal
		show={props.show}
		onHide={props.handleClose}
		backdrop='static'
		backdropClassName={props.show ? 'overlay-modal active' : ''}
		dialogClassName='modal-dialog-centered modal-preview-custom'
	>
		<Modal.Header closeButton>
			<Modal.Title>
				<h5 className='modal-title'>
					{' '}
					<Translate text={props.title ? props.title : 'Preview'} />
				</h5>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div className='container-fluid'>
				<div
					dangerouslySetInnerHTML={{
						__html: props.bodyHTML,
					}}
				></div>
			</div>
		</Modal.Body>
		<Modal.Footer className='modal-footer'>
			<button
				type='button'
				className='btn btn-default float-right'
				onClick={() => {
					props.handleClose();
				}}
			>
				<Translate text={'close'} />
			</button>
		</Modal.Footer>
	</Modal>
);

export default PreviewTemplate;
