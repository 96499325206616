import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import _images from '../../../../images'

const OEMSummaryReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [clients, setClients] = useState({});
    const [oemSettings, setOEMSettings] = useState({});
    const [reportData, setReportData] = useState([]);
    const [timezone, setTimezone] = useState();
    const [brands, setBrands] = useState();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    let _saleType = params.get('saletype');
    _date = _date ? _date : moment().format('YYYY-MM-DD');
    _saleType = _saleType && _saleType !== 'undefined' ? _saleType : '';

    const [dateRange, setDateRange] = useState({})

    const arrheadOffice = ['bmw aus lead', 'mini aus lead']
    const arrAutogate = ['autogate', 'carsales']
    const arrInternet = ['web', 'website', 'internet']
    const dateTypes = ['TODAY', 'WTD', 'MTD']
    const saleTypes = _saleType === 'used' ? ['Used'] : ['New', 'Used']
    const arrMakes = ['BMW', 'MINI']

    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${props.oemid}`)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let _timezone = doc.data().timezone ? doc.data().timezone : 'Australia/Sydney';
                    setTimezone(_timezone);
                    moment.updateLocale('en', {
                        week: {
                            dow: 1, // Monday is the first day of the week.
                        }
                    });
                    setDateRange({
                        monthStart: moment(_date).tz(_timezone).startOf('month').unix(),
                        monthEnd: moment(_date).tz(_timezone).endOf('day').unix(),
                        todayStart: moment(_date).tz(_timezone).startOf('day').unix(),
                        todayEnd: moment(_date).tz(_timezone).endOf('day').unix(),
                        weekStart: moment(_date).tz(_timezone).startOf('week').unix(),
                        weekEnd: moment(_date).tz(_timezone).endOf('week').unix()
                    })
                    setOEMSettings({
                        ...doc.data(),
                        id: doc.id
                    });

                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(oemSettings))
            return;
        window.firebase.firestore().collection(`clients`)
            .where('settingsID', '==', oemSettings.id)
            .where('isActive', '==', true)
            .get()
            .then(async (snap) => {
                let _clients = [];
                let _brandIDs = [];
                snap.docs.forEach((doc, index) => {
                    _clients.push({
                        ...doc.data(),
                        id: doc.id,
                        sortOrder: doc.data().sortOrder && doc.data().sortOrder > 0 ? doc.data().sortOrder : (index + 1)
                    })
                    if (doc.data().clientBrandIDs) {
                        _brandIDs.push(...doc.data().clientBrandIDs);
                    }
                })
                let _brands = []
                const brandpromises = []
                _.uniq(_brandIDs).forEach(bid => {
                    brandpromises.push(window.firebase.firestore().doc(`brands/${bid}`).get())
                })
                const brandsnapshots = await Promise.all(brandpromises);
                brandsnapshots.forEach(snap => {
                    _brands.push({
                        ...snap.data(),
                        id: snap.id
                    })
                })
                //.filter(a => a.id === '9OaKXHd6f1pesGBdeIUS')
                setClients(_.orderBy(_clients.filter(a => !Boolean(a.excludeInOEMReport)), ['sortOrder'], ['asc']).map(rec => {
                    return {
                        ...rec,
                        brands: _.filter(_brands, (v) => _.indexOf(rec.clientBrandIDs, v.id) >= 0)
                    }
                }))
            })
    }, [oemSettings])

    useEffect(() => {
        if (_.isEmpty(clients))
            return

        let makeSaleType = [];
        let makes = '';
        let sectionHeader = null;
        let summarySalesTypes = [];
        // arrMakes.forEach(mk => {
        //     _.filter(clients, (v) => _.indexOf(v.brands.map(r => { return r.name.toLowerCase() }), mk.toLowerCase()) >= 0).forEach(client => {
        //         makes += `${client.id}:New:${mk};`;
        //     })
        // })
        const excludeClients = clients.filter(a => Boolean(a.sectionInOEMReport)).map(r => { return r.id });
        saleTypes.forEach(st => {
            _.filter(clients, (v) => _.indexOf(excludeClients, v.id) < 0).forEach(client => {
                if (st === 'New' && (_.isEmpty(client.reportSaleType) || client.reportSaleType.includes('New')) && _.filter(arrMakes, (v) => _.indexOf(client.brands.map(r => { return r.name.toLowerCase() }), v.toLowerCase()) >= 0).length > 0) {
                    arrMakes.forEach(mk => {
                        if (_.indexOf(client.brands.map(r => { return r.name.toLowerCase() }), mk.toLowerCase()) >= 0) {
                            makes += `${client.id}:New:${mk};`;
                            summarySalesTypes.push(st);
                        }
                    })
                }
                //else if (st !== 'Empty') {
                else if (_.isEmpty(client.reportSaleType) || client.reportSaleType.includes(st)) {
                    makes += `${client.id}:${st};`;
                    summarySalesTypes.push(st);
                }
                //}
                //else
                //   makes += `${client.id}:${st};`;
            })
        })
        summarySalesTypes = _.uniq(summarySalesTypes);
        if (_.isEmpty(summarySalesTypes))
            summarySalesTypes = saleTypes;
        makes.split(';').forEach(rec => {
            if (!_.isEmpty(rec)) {
                let makeSplit = rec.split(':');
                makeSaleType.push({
                    groupBy: `${makeSplit[0]}-${makeSplit[1]}${makeSplit[2] ? '-' + makeSplit[2].toLowerCase() : ''}`,
                    title: getTitle(makeSplit),
                    logoURL: makeSplit[2] === 'MINI' ? _images.minilogo : _.find(clients, { id: makeSplit[0] }).logoURL,
                    saleType: makeSplit[1].toLowerCase(),
                    make: makeSplit[2] ? makeSplit[2].toLowerCase() : null,
                    clientID: makeSplit[0]
                })
            }
        });
        excludeClients.forEach(rec => {
            if (_.find(clients, { id: rec })) {
                if (_.find(clients, { id: rec }).sectionHeader) {
                    sectionHeader = _.find(clients, { id: rec }).sectionHeader;
                }
                makeSaleType.push({
                    groupBy: `${rec}`,
                    title: _.find(clients, { id: rec }).sectionHeader ? _.find(clients, { id: rec }).sectionHeader.toUpperCase() : _.find(clients, { id: rec }).name.toUpperCase(),
                    logoURL: null,
                    saleType: null,
                    make: null,
                    clientID: rec,
                    isClient: true,
                    dynTitle: 'TOTAL SUMMARY',
                    dynSaleType: _.find(clients, { id: rec }).reportSaleType
                });
                saleTypes.forEach(st => {
                    if (_.isEmpty(_.find(clients, { id: rec }).reportSaleType) || _.find(clients, { id: rec }).reportSaleType.includes(st)) {
                        makeSaleType.push({
                            groupBy: `${rec}-${st}`,
                            title: _.find(clients, { id: rec }).name.toUpperCase(),
                            logoURL: null,
                            saleType: st.toLowerCase(),
                            make: null,
                            clientID: rec,
                            isClient: true,
                            dynTitle: st === 'Empty' ? 'LEADS WITHOUT VEHICLE MODEL REQUIREMENT' : `${st.toUpperCase()} VEHICLE`
                        });
                    }
                })
            }
        })
        const getEnquiryData = window.firebase.functions().httpsCallable('generic-getData');
        getEnquiryData({
            type: 'getSummaryReportbyUsers',
            params: JSON.stringify({
                clientID: clients.map(r => { return r.id }).join(','),
                timezone: timezone,
                inclInboundLeadsinRpt: 'true',
                startDate: moment.unix(dateRange.monthStart).add(-7, 'days').format('YYYY-MM-DD'),
                endDate: moment.unix(dateRange.monthEnd).add(7, 'days').format('YYYY-MM-DD')
            })
        }).then((response) => {
            if (response.data.success) {
                //activity collection and origin col is removed
                let _data = response.data.data;
                let _reportData = [];
                makeSaleType.forEach((rec) => {
                    let tdNewLead = _data.filter(e => e.col === 'enq' && e.cid === rec.clientID && e.dt >= dateRange.todayStart && e.dt <= dateRange.todayEnd).map(r => { return r.eid });
                    let wkNewLead = _data.filter(e => e.col === 'enq' && e.cid === rec.clientID && e.dt >= dateRange.weekStart && e.dt <= dateRange.weekEnd).map(r => { return r.eid });
                    let mnNewLead = _data.filter(e => e.col === 'enq' && e.cid === rec.clientID && e.dt >= dateRange.monthStart && e.dt <= dateRange.monthEnd).map(r => { return r.eid });

                    //Today data
                    _reportData.push({
                        groupBy: rec.groupBy,
                        saleType: `${rec.isClient === true ? `client-${rec.title}` : rec.saleType}`,
                        logoURL: rec.logoURL,
                        title: rec.dynTitle ? rec.dynTitle : rec.title,
                        dateType: 'TODAY',
                        name: `TODAY`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => (a.col === 'enq' || a.col === 'td' || a.col === 'wk') && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID && tdNewLead.includes(a.eid)), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.todayStart, dateRange.todayEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act' && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act') && a.cid === rec.clientID), rec, dateRange.todayStart, dateRange.todayEnd, null, null, null),
                    })


                    //week data
                    _reportData.push({
                        groupBy: rec.groupBy,
                        saleType: `${rec.isClient === true ? `client-${rec.title}` : rec.saleType}`,
                        logoURL: rec.logoURL,
                        title: rec.dynTitle ? rec.dynTitle : rec.title,
                        dateType: 'WTD',
                        name: `WTD ${moment(_date).startOf('week').format('DD MMM')}-${moment(_date).endOf('week').format('DD MMM YYYY')}`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => (a.col === 'enq' || a.col === 'td' || a.col === 'wk') && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID && wkNewLead.includes(a.eid)), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act' && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act') && a.cid === rec.clientID), rec, dateRange.weekStart, dateRange.weekEnd, null, null, null),
                    })

                    //month data
                    _reportData.push({
                        groupBy: rec.groupBy,
                        saleType: `${rec.isClient === true ? `client-${rec.title}` : rec.saleType}`,
                        logoURL: rec.logoURL,
                        title: rec.dynTitle ? rec.dynTitle : rec.title,
                        dateType: 'MTD',
                        name: `MTD ${moment(_date).format('MMM')}`,
                        walkIn: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, 'walk', null, null),
                        phone: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, 'phone', null, null),
                        headOffice: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, 'headOffice', null),
                        autogate: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, 'autogate', null),
                        web: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, 'web', null, null),
                        outbound: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, 'outbound', null, null),
                        newLead: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        existingLead: checkExistingLedas(_data.filter(a => (a.col === 'enq' || a.col === 'td' || a.col === 'wk') && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd),
                        facetoface: checkDataFilter(_data.filter(a => a.col === 'wk' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        newtestdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID && mnNewLead.includes(a.eid)), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        testdrives: checkDataFilter(_data.filter(a => a.col === 'td' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        testdriveSold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID && _data.some(e => e.col === 'td' && a.eid === e.eid)), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd'),
                        newsold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd', 'new'),
                        sold: checkDataFilter(_data.filter(a => a.col === 'enq' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, 'sold', 'wd'),
                        followup: checkDataFilter(_data.filter(a => a.col === 'act' && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                        totalFollowup: checkDataFilter(_data.filter(a => (a.col === 'enq' || a.col === 'act') && a.cid === rec.clientID), rec, dateRange.monthStart, dateRange.monthEnd, null, null, null),
                    })
                })

                if (_reportData.length > 0) {
                    _.reverse(dateTypes).map(rec => {
                        return _.reverse(sectionHeader ? [...summarySalesTypes, `client-${sectionHeader}`] : summarySalesTypes).map(st => {
                            return _reportData.splice(0, 0, {
                                groupBy: `Total-${st}`,
                                saleType: st.toLowerCase(),
                                title: 'Total Summary',
                                dateType: rec,
                                name: `Total ${rec}`,
                                walkIn: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'walkIn'),
                                phone: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'phone'),
                                headOffice: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'headOffice'),
                                autogate: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'autogate'),
                                web: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'web'),
                                outbound: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'outbound'),
                                newLead: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'newLead'),
                                existingLead: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'existingLead'),
                                facetoface: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'facetoface'),
                                testdrives: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'testdrives'),
                                newtestdrives: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'newtestdrives'),
                                testdriveSold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'testdriveSold'),
                                newsold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'newsold'),
                                sold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'sold'),
                                followup: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'followup'),
                                totalFollowup: _.sumBy(_reportData.filter(a => a.dateType === rec && a.saleType.toLowerCase() === st.toLowerCase()), 'totalFollowup'),
                            })
                        })
                    })

                    if (_saleType === 'used') {
                        dateTypes.map(rec => {
                            return _reportData.splice(0, 0, {
                                groupBy: `Total-Used-Vehicle-Summary`,
                                saleType: 'total-used',
                                title: '',
                                dateType: rec,
                                name: `Total ${rec}`,
                                walkIn: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'walkIn'),
                                phone: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'phone'),
                                headOffice: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'headOffice'),
                                autogate: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'autogate'),
                                web: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'web'),
                                outbound: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'outbound'),
                                newLead: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'newLead'),
                                existingLead: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'existingLead'),
                                facetoface: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'facetoface'),
                                testdrives: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'testdrives'),
                                newtestdrives: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'newtestdrives'),
                                testdriveSold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'testdriveSold'),
                                newsold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'newsold'),
                                sold: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'sold'),
                                followup: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'followup'),
                                totalFollowup: _.sumBy(_reportData.filter(a => a.dateType === rec && a.title.toLowerCase() === 'total summary' && a.groupBy.includes('Total-')), 'totalFollowup'),
                            })
                        })
                    }

                    _reportData = _reportData.map(rec => {
                        return {
                            ...rec,
                            other: sumOtherLeads(rec),
                            totalLead: rec.newLead + rec.existingLead,
                            followupRate: (rec.totalFollowup > 0 ? parseFloat(((100 / rec.totalFollowup) * rec.followup)).toFixed(2) : '0') + '%',
                            leadtestdriveRatio: ((rec.newLead + rec.existingLead) > 0 ? parseFloat(((100 / (rec.newLead + rec.existingLead)) * rec.testdrives)).toFixed(2) : '0') + '%',
                            leadsaleRatio: ((rec.newLead + rec.existingLead) > 0 ? parseFloat(((100 / (rec.newLead + rec.existingLead)) * rec.sold)).toFixed(2) : '0') + '%',
                            testdrivesaleRatio: (rec.testdrives > 0 ? parseFloat(((100 / rec.testdrives) * rec.testdriveSold)).toFixed(2) : '0') + '%',
                            newtestdriveRatio: (rec.newLead > 0 ? parseFloat(((100 / rec.newLead) * rec.newtestdrives)).toFixed(2) : '0') + '%',
                            newsaleRatio: (rec.newLead > 0 ? parseFloat(((100 / rec.newLead) * rec.newsold)).toFixed(2) : '0') + '%',
                        }
                    })
                }
                if (sectionHeader) {
                    _reportData = _reportData.filter(a => a.saleType !== `client-${sectionHeader.toUpperCase()}`);
                }
                let _report = _.chain(_reportData)
                    .groupBy("saleType")
                    .map((value, key) => ({ key: key, data: _.chain(value).groupBy("groupBy").map((val) => val).value() }))
                    .value();

                setReportData(_report);
                setLoader(false)
            }
            else {
                setLoader(false)
            }
        });
    }, [clients])

    const sumOtherLeads = (rec) => {
        if ((rec.newLead - (rec.walkIn + rec.phone + rec.web + rec.outbound)) > 0)
            return (rec.newLead - (rec.walkIn + rec.phone + rec.web + rec.outbound))

        return 0;
    }

    const checkDataFilter = (_data, rec, startDt, endDt, enqType, origin, status, dateType, newlead) => {

        return status === 'sold' ? _.sumBy(_.uniqBy(_.map(_data.filter(a =>
            (((enqType === 'walk' || enqType === 'outbound')) ? (a.et && a.et.toLowerCase().includes(enqType.toLowerCase())) : true)
            && ((enqType === 'phone') ? (a.et && (a.et.toLowerCase().includes(enqType.toLowerCase()) || a.et.toLowerCase().includes('inbound'))) : true)
            && ((origin === 'headOffice') ? (a.org && arrheadOffice.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((origin === 'autogate') ? (a.org && arrAutogate.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((enqType === 'web') ? (a.et && arrInternet.some(b => b.toLowerCase() === a.et.toLowerCase())) : true)
            && ((status === 'sold') ? (a.est && (a.est.toLowerCase() === 'won' || a.est.toLowerCase() === 'delivered')) : true)
            && ((rec.make && rec.make !== 'all' && rec.make !== 'others' && rec.make !== 'nomake') ? (a.mk && a.mk.toLowerCase() === rec.make) : true)
            && (rec.make === 'others' ? (a.mk && !brands.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((!Boolean(rec.isClient) && _.isEmpty(rec.make) && arrMakes.length > 0 && rec.saleType === 'new') ? (a.mk && !arrMakes.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((rec.make === 'nomake' || rec.saleType === 'empty') ? _.isEmpty(a.mk) : true)
            && ((rec.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((rec.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((!_.isEmpty(rec.dynSaleType)) ? ((rec.dynSaleType.includes('Empty') ? _.isEmpty(a.mk) : false) || (rec.dynSaleType.includes('Used') ? (a.st && a.st.toLowerCase() === 'preowned') : false) || (rec.dynSaleType.includes('New') ? (a.st && a.st.toLowerCase() !== 'preowned') : false)) : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )), 'eid'), 'wc') : _.uniq(_.map(_data.filter(a =>
            (((enqType === 'walk' || enqType === 'outbound')) ? (a.et && a.et.toLowerCase().includes(enqType.toLowerCase())) : true)
            && ((enqType === 'phone') ? (a.et && (a.et.toLowerCase().includes(enqType.toLowerCase()) || a.et.toLowerCase().includes('inbound'))) : true)
            && ((origin === 'headOffice') ? (a.org && arrheadOffice.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((origin === 'autogate') ? (a.org && arrAutogate.some(b => b.toLowerCase() === a.org.toLowerCase())) : true)
            && ((enqType === 'web') ? (a.et && arrInternet.some(b => b.toLowerCase() === a.et.toLowerCase())) : true)
            && ((status === 'sold') ? (a.est && (a.est.toLowerCase() === 'won' || a.est.toLowerCase() === 'delivered')) : true)
            && ((rec.make && rec.make !== 'all' && rec.make !== 'others' && rec.make !== 'nomake') ? (a.mk && a.mk.toLowerCase() === rec.make) : true)
            && (rec.make === 'others' ? (a.mk && !brands.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((!Boolean(rec.isClient) && _.isEmpty(rec.make) && arrMakes.length > 0 && rec.saleType === 'new') ? (a.mk && !arrMakes.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((rec.make === 'nomake' || rec.saleType === 'empty') ? _.isEmpty(a.mk) : true)
            && ((rec.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((rec.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((!_.isEmpty(rec.dynSaleType)) ? ((rec.dynSaleType.includes('Empty') ? _.isEmpty(a.mk) : false) || (rec.dynSaleType.includes('Used') ? (a.st && a.st.toLowerCase() === 'preowned') : false) || (rec.dynSaleType.includes('New') ? (a.st && a.st.toLowerCase() !== 'preowned') : false)) : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ), function (e) {
            return e.eid
        })).length;
    }

    const checkExistingLedas = (_data, rec, startDt, endDt) => {

        return _.uniq(_.map(_data.filter(a =>
            ((rec.make && rec.make !== 'all' && rec.make !== 'others' && rec.make !== 'nomake') ? (a.mk && a.mk.toLowerCase() === rec.make) : true)
            && (rec.make === 'others' ? (a.mk && !brands.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((rec.make === 'nomake' || rec.saleType === 'empty') ? _.isEmpty(a.mk) : true)
            && ((!Boolean(rec.isClient) && _.isEmpty(rec.make) && arrMakes.length > 0 && rec.saleType === 'new') ? (a.mk && !arrMakes.some(b => b.toLowerCase() === a.mk.toLowerCase())) : true)
            && ((rec.saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((rec.saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((!_.isEmpty(rec.dynSaleType)) ? ((rec.dynSaleType.includes('Empty') ? _.isEmpty(a.mk) : false) || (rec.dynSaleType.includes('Used') ? (a.st && a.st.toLowerCase() === 'preowned') : false) || (rec.dynSaleType.includes('New') ? (a.st && a.st.toLowerCase() !== 'preowned') : false)) : true)
            && (a.col === 'enq' && a.dt < startDt)
            && (
                (a.dt >= startDt && a.dt <= endDt)
                || (a.wd >= startDt && a.wd <= endDt)
                || (a.est && (a.est.toLowerCase() === 'lost' || a.est.toLowerCase() === 'prospectlost' || a.est.toLowerCase() === 'pendinglost') && a.ld >= startDt && a.ld <= endDt)
                || (_data.some(b => (b.col === 'wk' || b.col === 'td') && a.eid === b.eid && (b.dt >= startDt && b.dt <= endDt)))
            )
        ), function (e) {
            return e.eid
        })).length;
    }

    const getTitle = (makeSplit) => {
        let clientName = _.find(clients, { id: makeSplit[0] }).name.toUpperCase();
        if (makeSplit[2]) {
            clientName = `${clientName.replace('BMW', '').trim()} ${makeSplit[2].toUpperCase()}`
        }
        return clientName;
    }

    return loader || _.isEmpty(oemSettings) || _.isEmpty(clients) ? (
        <div className='loader-center-screen'>
            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
        </div>) : (
        <div className="A4 landscape report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        oemSettings && oemSettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={oemSettings.logoURL ? oemSettings.logoURL : ''} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(oemSettings) ? oemSettings.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {`SUMMARY REPORT`} <br></br>
                                    <span> {moment(_date).format('DD MMMM YYYY')}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="summary-table-wraper">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                            <tr>
                                <td className="outside-td">
                                    {
                                        reportData.map((saleType, i) => {
                                            return <div key={i}>
                                                {
                                                    saleType.key.toLowerCase() === 'empty' ? (
                                                        <div className="report-main-head-panel">{`LEADS WITHOUT VEHICLE MODEL REQUIREMENT`}</div>
                                                    ) : saleType.key.startsWith('client-') ? (
                                                        <div className="report-main-head-panel">{`${saleType.key.replace('client-', '')}`}</div>
                                                    ) : saleType.key.toLowerCase() === 'used' && _saleType === 'used' ? (
                                                        <div className="report-main-head-panel">{`RETAIL USED TOTAL`}</div>
                                                    ) : saleType.key.toLowerCase() === 'total-used' ? (
                                                        <div className="report-main-head-panel">{`TOTAL USED VEHICLE SUMMARY`}</div>
                                                    ) : (
                                                        <div className="report-main-head-panel">{`${saleType.key.toUpperCase()} VEHICLE`}</div>
                                                    )
                                                }
                                                <table className="summary-table-loop">
                                                    <tbody>
                                                        {
                                                            saleType.data.map((rec, index) => {
                                                                return <>
                                                                    {
                                                                        rec[0].title ? (
                                                                            <tr>
                                                                                <td colSpan="12" style={{ padding: "0px", border: "none", height: "30px", textAlign: "left" }}>
                                                                                    <div className="report-dealer-logo-panel">
                                                                                        <div className="report-dealer-info">
                                                                                            {
                                                                                                rec[0].logoURL ? (
                                                                                                    <div className="report-dealer-infoimage"> <img src={rec[0].logoURL} alt={rec[0].title} height="40" /> </div>
                                                                                                ) : (<></>)
                                                                                            }
                                                                                            <div className="report-dealer-info-name"> {rec[0].title}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ) : (<></>)
                                                                    }
                                                                    <tr key={index} >
                                                                        <th className="cell-head td-head" width="12%">DATE</th>
                                                                        <td className="td-subhead" width="5%">NEW LEADS</td>
                                                                        <td className="td-subhead" width="5%">EXISITNG LEADS</td>
                                                                        <td className="td-subhead" width="5%">TOTAL LEADS</td>
                                                                        <td className="td-subhead" width="8%">NEW LEADS TEST DRIVE</td>
                                                                        <td className="td-subhead" width="8%">NEW LEADS TEST DRIVE RATIO</td>
                                                                        <td className="td-subhead" width="8%">TOTAL LEADS TEST DRIVE</td>
                                                                        <td className="td-subhead" width="8%">TOTAL LEADS TEST DRIVE RATIO</td>
                                                                        <td className="td-subhead" width="8%">NEW LEADS SOLD </td>
                                                                        <td className="td-subhead" width="8%">NEW LEADS SOLD RATIO</td>
                                                                        <td className="td-subhead" width="8%">OVERALL LEADS SOLD </td>
                                                                        <td className="td-subhead" width="8%">OVERALL LEADS SOLD RATIO</td>
                                                                    </tr>
                                                                    {
                                                                        rec.map((data, index) => {
                                                                            return <tr key={index}>
                                                                                <td className="td-head">{data.name}</td>
                                                                                <td className="count-large">{data.newLead}</td>
                                                                                <td className="count-large">{data.existingLead}</td>
                                                                                <td className="count-large">{data.totalLead}</td>
                                                                                <td className="count-large">{data.newtestdrives}</td>
                                                                                <td className="count-large">{data.newtestdriveRatio}</td>
                                                                                <td className="count-large">{data.testdrives}</td>
                                                                                <td className="count-large">{data.leadtestdriveRatio}</td>
                                                                                <td className="count-large">{data.newsold}</td>
                                                                                <td className="count-large">{data.newsaleRatio}</td>
                                                                                <td className="count-large">{data.sold}</td>
                                                                                <td className="count-large">{data.leadsaleRatio}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    <tr>
                                                                        <td colSpan="12" style={{ padding: "0px", border: "none", height: "30px" }} >&nbsp;</td>
                                                                    </tr>
                                                                    <div style={{ pageBreakAfter: 'always' }}></div>
                                                                </>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        })
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <div className="footer-bottom">
                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {oemSettings.address ? <>{oemSettings.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {oemSettings.phone ? <>Tel: <span style={{ paddingLeft: 0 }} >{oemSettings.phone}</span></> : <></>}
                                {oemSettings.website ? <>Web: <span style={{ paddingLeft: 0 }} >{oemSettings.website}</span></> : <></>}
                            </span>
                        </p>
                    </div>
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>
                        <div className="footer-right"></div>
                    </div>
                    <div className="clear"></div>
                </div>
            </section>
        </div>
    )
}

export default OEMSummaryReport;