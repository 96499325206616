import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import _ from 'lodash'
import { ContentOverlay, PopUpModal } from '../../components'
import CommonHelper from '../../services/common'
import { default as _images } from '../../images';
import AcitivitylogReducer from '../pipeline/quickview/activitylogReducer'
import FleetView from './fleetViewListItem'
import AddFleet from '../fleet/add';
import AddTestDrive from '../testdrive/add'
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';
import { fleetStatus, testDriveStatus } from '../../services/enum';

const FleetCounts = (props) => {
    const [loadingFleet, setLoadingFleet] = useState(true)
    const [allFleetData, dispatch] = useReducer(AcitivitylogReducer, { Overdue: [], Today: [], Upcoming: [], PendingTD: [] })
    const fleetTags = ["Overdue", "Upcoming", "Today", "PendingTD"]
    const [activeFleetTag, setActiveFleet] = useState('Upcoming')
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Overdue": true, "Today": true, "Upcoming": true, "PendingTD": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 10
    const [fleetCounts, setFleetCounts] = useState({})
    const [showFleet, setShowFleet] = useState({
        show: false,
        clsActive: '',
        documentID: null,
    });

    const [showTestDrive, setShowTestDrive] = useState({
        show: false,
        documentID: null,
    });
    const [activityCountsLoader, setActivityCountsLoader] = useState(false)
    const [reload, setReload] = useState(false)
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-fleetCounts`);

    useEffect(() => {
        return () => {
            window.unsubdashboardfleetCount && window.unsubdashboardfleetCount();
        }
    }, [])

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchCounts(true);
            }
        }, props.refreshTimer);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchCounts();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        window.unsubdashboardfleetCount = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setInitialLoad(false)
                    setFleetCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        setTimeout(() => {
                            fetchCounts(true);
                        }, props.delayTimer);
                    }
                }
                else {
                    fetchCounts();
                }
            })
    }, [initialLoad])

    useEffect(() => {
        let dataload = { "Overdue": true, "Today": true, "Upcoming": true, "PendingTD": true }
        setHasMoreData([])
        dispatch({
            type: "CLEAR_ALL"
        });
        setDataload({
            ...dataload,
            [activeFleetTag]: true
        })
    }, [props.selectedUser])

    // useEffect(() => {
    const fetchCounts = async () => {
        //console.log('fetchCounts', reload)

        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        // if (props.selectedPipeline) {
        //     _filter.pipeline = ''
        // }
        if (props.selectedUser) {
            _filter.owner = props.selectedUser.value
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props.dateRange) {
            _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
        }

        let _searchObject = {
            type: "getFleetCount",
            params: JSON.stringify(_filter),
        }
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        //console.log('resp getFleetCount, ', resp)
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            // console.log(`data of the counts`, data)
            setFleetCounts(data)
            setActivityCountsLoader(false)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setFirstTimeLoaded(true)
        } else {
            setActivityCountsLoader(false)
            setFirstTimeLoaded(true)
        }
    }
    //  fetchCounts()
    //}, [props.selectedUser, props.refreshData, reload])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchCounts();
    }, [props.refreshData])

    useEffect(() => {
        if (!checkDataLoad[activeFleetTag]) {
            return;
        }
        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

        setPagingloader(true)
        let _cType = activeFleetTag === 'PendingTD' ? 'testdrives' : 'fleet'
        let refFleetData = firestoreDB(props.dealersettings).firestore().collection(_cType)
            .where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', 'pending')


        // .where('type', '==', 'activity')
        // .where('isDeleted', '==', false)
        // refFleetData = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(refFleetData, props.dealersettings, null, true);

        if (ownerDashboard)
            refFleetData = refFleetData.where('addedBy', '==', localStorage.uid)
        else if (props.selectedUser)
            refFleetData = refFleetData.where('addedBy', '==', props.selectedUser.value)

        // @info: Overdue
        if (activeFleetTag === 'Overdue') {
            refFleetData = refFleetData
                .where('expectedReturn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .orderBy('expectedReturn', 'asc')
        }
        // @info: On Loan
        else if (activeFleetTag === 'Today') {
            refFleetData = refFleetData
                // .where('expectedReturn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .orderBy('expectedReturn', 'asc')
        }
        // @info: Today Expected Return
        else if (activeFleetTag === 'Upcoming') {
            refFleetData = refFleetData
                .where('expectedReturn', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .where('expectedReturn', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
                .orderBy('expectedReturn', 'asc')
        }
        else {
            refFleetData = refFleetData
                .where('isDeleted', '==', false)
                .orderBy('startDate', 'desc')
        }

        let lastRecord = hasMore.filter(e => e.type === activeFleetTag);
        if (lastRecord && lastRecord.length > 0) {
            refFleetData = refFleetData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refFleetData = refFleetData
                .limit(pageLimit)
        }

        window.unsubdashboardfleetlist = refFleetData
            .get()
            .then(onCollectionUpdate);
    }, [checkDataLoad, activeFleetTag])

    const onCollectionUpdate = async (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = {
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         };
        //         if (change.type === 'added') {
        //             actionType = "ADDNEWLOG";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_ACTIVITY"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVELOG"
        //         }
        //     }
        // })
        const _fleetData = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeFleetTag),
                {
                    type: activeFleetTag,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeFleetTag)]);
            }
            querySnapshot.forEach(function (doc) {
                const objlogData = Object.assign({}, doc.data());
                objlogData.documentID = doc.id;
                _fleetData.push(objlogData)
            });
        }
        //console.log(activeFleetTag, _fleetData)
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _fleetData,
            activeTab: activeFleetTag
        });
        setDataload({
            ...checkDataLoad,
            [activeFleetTag]: false
        })
        setLoadingFleet(false)
        setPagingloader(false)
    }

    const handleFleetOpen = (e, logVM) => {
        if (activeFleetTag === 'PendingTD') {
            setShowTestDrive({
                show: true,
                documentID: logVM.documentID,
            });
        }
        else {
            setShowFleet({
                show: true,
                clsActive: 'overlay-modal active',
                documentID: logVM.documentID,
            });
        }

    }

    const handleFleetClose = (id, objData) => {
        if (objData?.status === fleetStatus.COMPLETE) {
            dispatch({
                type: "REMOVELOG",
                data: objData,
                activeTab: activeFleetTag
            });
        }
        else if (!_.isEmpty(objData)) {
            dispatch({
                type: "UPDATE_ACTIVITY",
                data: objData,
                activeTab: activeFleetTag
            });
        }
        setShowFleet({
            show: false,
            clsActive: '',
            documentID: null,
        });
        setTimeout(() => {
            setReload(!reload)
        }, 2000);
    }

    const handleTestDriveClose = (id, objData) => {
        if (objData?.status === testDriveStatus.COMPLETE) {
            dispatch({
                type: "REMOVELOG",
                data: objData,
                activeTab: activeFleetTag
            });
        }
        else if (!_.isEmpty(objData)) {
            dispatch({
                type: "UPDATE_ACTIVITY",
                data: objData,
                activeTab: activeFleetTag
            });
        }
        setShowTestDrive({
            show: false,
            documentID: null,
        });
        setTimeout(() => {
            setReload(!reload)
        }, 2000);
    }

    return (
        <>
            <div className="dashboard-box">
                {
                    activityCountsLoader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button mr-1" onClick={(e) => { e.preventDefault(); setActivityCountsLoader(true); fetchCounts(); }}> <i className="ico icon-refresh"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }

                        {/* <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a> */}
                    </div>
                    <h3><Translate text={'LoanTestDriveVehicle'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loadingFleet ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    fleetTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeFleetTag === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveFleet(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >{rec === 'Upcoming' ? <Translate text={`Today's Expected Return`} /> : rec === 'Today' ? <Translate text={'onLoan'} /> : rec === 'PendingTD' ? <Translate text={'pendingTestDrives'} /> : <Translate text={'Overdue'} />}
                                                                {!_.isEmpty(fleetCounts) ?
                                                                    rec === 'Overdue' ? (<span className="badge badge-pill badge-widget-counter ml-1">{fleetCounts.overdue ? fleetCounts.overdue : '0'}</span>)
                                                                        : rec === 'Upcoming' ? (<span className="badge badge-pill badge-widget-counter ml-1">{fleetCounts.today ? fleetCounts.today : '0'}</span>)
                                                                            : rec === 'Today' ? (<span className="badge badge-pill badge-widget-counter ml-1">{fleetCounts.total ? fleetCounts.total : '0'}</span>)
                                                                                : rec === 'PendingTD' ? (<span className="badge badge-pill badge-widget-counter ml-1">{fleetCounts.pendingTD ? fleetCounts.pendingTD : '0'}</span>)
                                                                                    : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            !_.isEmpty(allFleetData[activeFleetTag]) && allFleetData[activeFleetTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            allFleetData[activeFleetTag].map((log, index) => {
                                                                return <FleetView
                                                                    key={index}
                                                                    title={log.title}
                                                                    id={log.documentID}
                                                                    addedBy={CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.addedBy)}
                                                                    fleetTag={activeFleetTag}
                                                                    dealersettings={props.dealersettings}
                                                                    fleetLog={log}
                                                                    handleFleetOpen={handleFleetOpen}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && _.isEmpty(allFleetData[activeFleetTag])) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={activeFleetTag === 'PendingTD' ? 'No Test Drive Vehicles' : 'No Loan Vehicles'} />  </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                !_.isEmpty(hasMore) && hasMore.filter(e => e.type === activeFleetTag).length > 0 ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeFleetTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showFleet.show}>
                <AddFleet
                    docID={showFleet.documentID}
                    show={showFleet.show}
                    clsActive='overlay-modal active'
                    // stock={modelStock}
                    handleClose={handleFleetClose}
                    title={'fleet'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddFleet>
            </PopUpModal>

            <PopUpModal show={showTestDrive.show}>
                <AddTestDrive
                    docID={showTestDrive.documentID}
                    show={showTestDrive.show}
                    clsActive='overlay-modal active'
                    handleClose={handleTestDriveClose}
                    title={'testDrive'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isDeivered={false}
                    contact={null}
                >
                </AddTestDrive>
            </PopUpModal>
        </>
    );
}

export default FleetCounts;