import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { TableView, ReactMultiSelect } from '../../components'
import { taskFields, allstatus, alltypes } from './viewModel'
import Translate from '../../constants/translate'
import { default as _images } from '../../images';
import moment from 'moment'

const MeilisearchTasks = (props) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth - 30,
    windowHeight: window.innerHeight - 185,
  });
  const [data, setData] = useState([])
  const [searchFilter, setSearchFilter] = useState({})
  const [loader, setLoader] = useState(true)
  const [refreshData, setRefreshData] = useState(true);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth - 30,
        windowHeight: window.innerHeight - 185,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      //window.unSubscribeCompanies && window.unSubscribeCompanies();
    };
  }, []);

  useEffect(() => {
    async function getAllTasks() {
      const allTasks = await props.client.getTasks({
        ...Object.fromEntries(Object.entries(searchFilter).filter(([_, v]) => v.length > 0)),
        limit: 100
      });
      let _task = [];
      allTasks && allTasks.results.forEach(rec => {
        _task.push({
          ...rec,
          strenqueuedAt: rec.enqueuedAt ? moment(rec.enqueuedAt).format('DD/MM/YYYY h:mm A') : '',
          strstartedAt: rec.startedAt ? moment(rec.startedAt).format('DD/MM/YYYY h:mm A') : '',
          strfinishedAt: rec.finishedAt ? moment(rec.finishedAt).format('DD/MM/YYYY h:mm A') : '',
          strerror: rec.error ? JSON.stringify(rec.error) : ''
        })
      });
      setData(_task);
      setLoader(false)
    }

    getAllTasks();
  }, [refreshData])

  const handleActionClick = (id, data) => {
    return (
      <></>
    )
  }

  const handledetailsClick = (e, id, data) => {
    e.preventDefault();
  }

  const handleReactMultiSelectChange = (selectedOptions, name) => {
    const value = [];
    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
      value.push(data.value)
    })

    setSearchFilter({
      ...searchFilter,
      [name]: value
    });

  }

  return (
    <>
      <div className="filter-panel">
        <div className="d-flex justify-content-between">
          <div className='form-row col-md-12'>
            <div className="form-group col-md-3">
              <ReactMultiSelect
                options={props.collection}
                name={"indexUid"}
                placeholder={'select index'}
                onChange={(option) => handleReactMultiSelectChange(option, 'indexUid')}
                value={searchFilter.indexUid ? searchFilter.indexUid : []}
                classNamePrefix={`basic-select`}
                isMulti={true}
                isSearchable={true}
              >
              </ReactMultiSelect>
            </div>
            <div className="form-group col-md-3">
              <ReactMultiSelect
                options={allstatus}
                name={"status"}
                placeholder={'select status'}
                onChange={(option) => handleReactMultiSelectChange(option, 'status')}
                value={searchFilter.status ? searchFilter.status : []}
                classNamePrefix={`basic-select`}
                isMulti={true}
                isSearchable={true}
              >
              </ReactMultiSelect>
            </div>
            <div className="form-group col-md-3">
              <ReactMultiSelect
                options={alltypes}
                name={"type"}
                placeholder={'select type'}
                onChange={(option) => handleReactMultiSelectChange(option, 'type')}
                value={searchFilter.type ? searchFilter.type : []}
                classNamePrefix={`basic-select`}
                isMulti={true}
                isSearchable={true}
              >
              </ReactMultiSelect>
            </div>
            <div className="float-right">
              <div className="filter-add-button">
                <button type="button" className="btn btn-primary" onClick={() => {
                  setLoader(true)
                  setRefreshData(!refreshData)
                }}> <i className="ico icon-search"></i> Search</button>
              </div>
            </div>
            <div className="float-right ml-2">
              <div className="filter-add-button">
                <button type="button" className="btn btn-default" onClick={() => {
                  setLoader(true)
                  setSearchFilter({})
                  setRefreshData(!refreshData)
                }}>Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loader
          ?
          <>
            <div className="dashboard-widget-loader h-100">
              <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                <div className="spinner-border" role="status">
                  <span className="sr-only"><Translate text={'Loading...'} /></span>
                </div>
              </div>

            </div>
          </>
          :
          <>
          </>
      }
      <div className='contact-panel ml-3'>
        <TableView
          datalist={data}
          height={windowSize.windowHeight}
          width={windowSize.windowWidth}
          columns={taskFields}
          dynamicFields={taskFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
          handleActionClick={handleActionClick}
          handleRowSingleClick={handledetailsClick}
          isSettings={false}
          isReorderable={false}
        />

      </div>
    </>
  )
}

export default MeilisearchTasks