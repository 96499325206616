/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allCallLogsFieldsVM, objCallLogs } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import Users from '../users/list/index';
import FilterPanel from './filter'
import AddCallLog from './add'
import RejectLogList from './rejectLogs'
//import EngageRequest from './engageLog'
//import { default as _images } from '../../images';

import StockListReducer from '../stock/stockListReducer'
import { callLogStatus, callTypes, logTypes } from '../../services/enum';

//let arrcallstatus = [];
const CallLogList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    //const [sortName, setSortName] = useState(localStorage.stocksortName ? localStorage.stocksortName : 'modifiedDate')
    const [sortOrder, setSortOrder] = useState(localStorage.stocksortOrder ? localStorage.stocksortOrder : 'desc')
    const [hasMore, setHasMoreData] = useState([])
    const [callLogsFields, setCallLogsFields] = useState(props.dealersettings.callLogsFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [modelUserShow, setModelUserShow] = useState(false)
    const [OEMShow, setOEMShow] = useState(false)
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)

    const [csvHeader, setHeader] = useState([])

    const [searchText, setSearchText] = useState(localStorage.logSearchText ? localStorage.logSearchText : '')
    const [stockCount, setStockCount] = useState(0)

    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [rejectCount, setRejectCount] = useState([])
    const [modelRejectShow, setModelRejectShow] = useState(false)
    const [filterLog, setFilterLog] = useState()

    const allDealers = localStorage.defaultModule === 'frontdesk' && !_.isEmpty(props.dealersettings?.clients) ? props.dealersettings.clients.map(r => { return r.documentID }) : [props.dealersettings.client.id];
    /*const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);*/

    useEffect(() => {
        let headerFields = callLogsFields;
        let allHeaderFields = allCallLogsFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key === rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [callLogsFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubLogs && window.unSubLogs();
            window.unSubLogs && window.unSubLogs.unsubscribe();
        }
    }, [])

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings) && !isFilterApplied) {
    //         //setDataload(false)

    //         try {
    //             const addContact = window.firebase.functions().httpsCallable('stock-getCount');
    //             addContact({
    //                 "clientID": props.dealersettings.client.id
    //             }).then((_data) => {
    //                 if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
    //                     console.log('stock-getCount', _data.data[0])
    //                     setStockCount(_data.data[0].stock_count);
    //                 }

    //             });
    //         }
    //         catch (error) {
    //             console.error(error)
    //         }
    //         setDataload(true)
    //     }
    // }, [props.dealersettings, isFilterApplied])

    // useEffect(() => {
    //     let settingsid = props.dealersettings.client.settings.id;
    //     let userID = props.dealersettings.id;
    //     const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
    //         .where('module', '==', 'stocks')
    //         .onSnapshot((querySnapshot) => {
    //             let _customFilters = [];
    //             querySnapshot.forEach(doc => {
    //                 const _data = {
    //                     ...doc.data(),
    //                     documentID: doc.id
    //                 }
    //                 _customFilters.push(_data);
    //             });
    //             _customFilters = _customFilters.filter(m => m.addedBy === userID || m.visibility === 'shared')
    //             setCustomFilters(_customFilters)

    //         });
    //     return () => {
    //         userRefDoc && userRefDoc();
    //     }
    // }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.logsFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.logsFilter])

    useEffect(() => {
        if (!props.dealersettings)
            return;
        //let clientID = props.dealersettings.client.id        
        const clientUserSnapshot = firestoreDB(props.dealersettings).firestore().collection('callLogs')
            .where('clientID', 'in', CommonHelper.arrLimit(allDealers, 10))
            .where('status', '==', callLogStatus.REJECTED)
            .where('isDeleted', '==', false)

        window.unSubRejectLogsList = clientUserSnapshot
            .onSnapshot(onRejectedCollectionUpdate);

        return () => {
            window.unSubRejectLogsList();
        }
    }, [])

    const onRejectedCollectionUpdate = (querySnapshot) => {
        let rejectCount = [];
        querySnapshot.forEach((docSnapshot) => {
            rejectCount.push(docSnapshot.id);
        });
        setRejectCount(rejectCount)
    }

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        var collectionPromise = [];
        for (let k = 0; k < Math.ceil(allDealers.length / 10); k++) {
            let collectionDataRef = firestoreDB(dealersettings).firestore().collection('callLogs')
                .where('clientID', 'in', CommonHelper.arrPaginate(allDealers, 10, k + 1))
                .where('isDeleted', '==', false)
                .where('modifiedDate', '>=', dateRange.startDate)
                .where('modifiedDate', '<=', dateRange.endDate)
                .orderBy('modifiedDate', 'desc')


            if (!_.isEmpty(filterLog)) {
                collectionDataRef = collectionDataRef.where('logType', '==', filterLog)
            }
            if (searchText && searchText.trim()) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
                if (formattedSearchText.trim())
                    collectionDataRef = collectionDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            }

            if (hasMore[k]) {
                collectionDataRef = collectionDataRef
                    .startAfter(hasMore[k])
                    .limit(pageLimit)
            }
            else {
                if (isPaging) {
                    collectionDataRef = '';
                } else {
                    collectionDataRef = collectionDataRef
                        .limit(pageLimit)
                }
            }
            collectionPromise.push(collectionDataRef ? collection(collectionDataRef) : of([]));
        }

        if (collectionPromise.length > 0) {
            window.unSubLogs = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    let i = 0;
                    let _hasMore = {};
                    allDocs.forEach(doc => {
                        if (doc.length > 0 && doc.length === pageLimit) {
                            _hasMore = {
                                ..._hasMore,
                                [i]: doc[doc.length - 1]
                            };
                        }
                        else {
                            _hasMore = {
                                ..._hasMore,
                                [i]: null
                            };
                        }
                        querySnapshot.push(...doc);
                        i++;
                    });
                    setHasMoreData(_hasMore);
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setDataload(false)
            setStockLoader(false)
            setPaging(false)
        }
        // let collectionDataRef = firestoreDB(props.dealersettings).firestore().collection('callLogs')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        //     .where('modifiedDate', '>=', dateRange.startDate)
        //     .where('modifiedDate', '<=', dateRange.endDate)
        //     .orderBy('modifiedDate', 'desc')

        // if (searchText && searchText.trim()) {
        //     collectionDataRef = collectionDataRef
        //         .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        // }

        // if (hasMore.length > 0) {
        //     collectionDataRef = collectionDataRef
        //         .startAfter(hasMore[0])
        //         .limit(pageLimit)
        // }
        // else {
        //     collectionDataRef = collectionDataRef
        //         .limit(pageLimit)
        // }

        // window.unSubLogs = collectionDataRef
        //     .onSnapshot(onCollectionUpdate);

        // // return () => {
        // //     window.unSubLogs();
        // // }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = dataMappingVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         });
        //         if (change.type === 'added') {
        //             actionType = "_ADD";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "_UPDATE"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "_REMOVE"
        //         }
        //     }
        // })
        const stocks = [];
        if (!actionType) {
            // if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            //     setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            // }
            // else {
            //     setHasMoreData([]);
            // }
            querySnapshot.forEach((doc) => {
                stocks.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _.uniqBy(stocks, 'documentID'),
            sortName: 'modifiedDate',
            sortOrder: 'desc'
        });
        // if (isPaging) {
        //     dispatch({
        //         type: "_APPEND",
        //         data: stocks,
        //         sortName: 'modifiedDate',
        //         sortOrder: 'desc'
        //     });
        // }
        // else {
        //     dispatch({
        //         type: actionType ? actionType : "_SUCCESS",
        //         data: actionType ? snapshotDoc : stocks,
        //         sortName: 'modifiedDate',
        //         sortOrder: 'desc'
        //     });
        // }

        setDataload(false)
        setStockLoader(false)
        setPaging(false)
    }

    const onCollectionStocks = (querySnapshot) => {

        const _stocks = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _stocks.push(dataVM)
        });

        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _stocks,
            sortName: 'modifiedDate',
            sortOrder: 'desc'
        });

        setStockLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const dataMappingVM = (doc) => {

        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objCallLogsData = Object.assign({}, doc);
        const stock = Object.assign({}, objCallLogs);
        for (let [key, value] of Object.entries(objCallLogsData)) {
            stock[key] = value;
        }
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(stock.contact)) {
            // stock.contactName = <div onClick={(e) => {
            //     e.preventDefault();
            //     const url = `/contacts/details/${stock.contact.documentID}`;
            //     window.open(url, '_blank');
            // }}>{CommonHelper.displayContact([], queueVM.contact, '--')}</div>;

            let _contact = Object.assign({}, stock.contact);
            stock.contactName = CommonHelper.getFullName([], _contact);
            stock.displayPhone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
            stock.csvPhone = stock.displayPhone ? `${stock.displayPhone}` : '';
            stock.phone = CommonHelper.getFullPhone(_contact.phoneCode, _contact.phone);
            stock.email = _contact.email ? (_contact.email) : '';

        }

        if (stock.clientID && props.dealersettings.group && ((stock.clientID !== props.dealersettings.client.id && localStorage.defaultModule === 'frontdesk') || localStorage.defaultModule === 'oem')) {
            stock.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stock.clientID);
        } else {
            stock.dealerName = ''
        }
        const setttings = props.dealersettings.client.settings;
        stock.callType = stock.callType ? CommonHelper.getNameByValue(callTypes, stock.callType) : ''
        stock.logTypeName = stock.logType ? CommonHelper.getLabelByValue(logTypes, stock.logType) : ''
        stock.callStatus = stock.callStatus ? CommonHelper.getNameByValue(setttings.callStatus, stock.callStatus, '', '--') : ''
        stock.callDuration = stock.callDuration > 0 ? CommonHelper.convertsecstostr(stock.callDuration) : ''
        stock.enquiryType = stock.enquiryType ? CommonHelper.getNameByValue(setttings.enquiryTypes, stock.enquiryType, '', '--') : ''
        stock.origin = stock.origin ? CommonHelper.getNameByValue(setttings.origins, stock.origin, '', '--') : ''
        stock.campaign = stock.campaign ? CommonHelper.getNameByValue(setttings.campaigns, stock.campaign, '', '--') : ''
        stock.userName = CommonHelper.getUserNamebyId(props.groupUsers, stock.user);
        stock.statusName = stock.status ? stock.status : '';
        stock.status = (stock.status
            ?
            <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
                {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
            </div>
            :
            <></>);

        stock.addedBy = CommonHelper.getUserNamebyId(props.groupUsers, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(props.groupUsers, stock.modifiedBy);
        return stock
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('logsFilter', JSON.stringify(_filter));
            localStorage.logSearchText = '';
            setFilterApplied(true);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.logsFilter)) {
            localStorage.removeItem('logsFilter');
            localStorage.logSearchText = '';
            setFilterApplied(false);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ callLogsFields: fields }, { merge: true })
            .then(() => {
                setCallLogsFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('callLogsFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {

        return (
            <div style={{ width: '40px', float: 'left' }}>

                {
                    (data.statusName === callLogStatus.PENDING || data.statusName === callLogStatus.REJECTED)
                        ?
                        (<a href="#"
                            title="Reassign"
                            style={{ cursor: 'pointer' }}
                            className="mini-button mr-2"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditDocument(id);
                                setModelUserShow(true);
                            }}>
                            <i className="ico icon-reassign"></i>
                        </a>)
                        :
                        (<a href="#"
                            title={(data.statusName === callLogStatus.LOGGED && _.isEmpty(data.enquiryID)) ? `Edit` : `View`}
                            style={{ cursor: 'pointer' }}
                            className="mini-button mr-2"
                            onClick={(e) => {
                                setEditDocument(id);
                                setModelShow(true);
                            }}>
                            {
                                (data.statusName === callLogStatus.LOGGED && _.isEmpty(data.enquiryID))
                                    ?
                                    <i className={`ico icon-edit`} aria-hidden="true"></i>
                                    :
                                    <i className={`ico icon-zoom`} aria-hidden="true"></i>
                            }
                        </a >)

                }


                {/* <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button mr-2 ml-2"
                >
                    <i className="ico icon-delete"></i>
                </div> */}

            </div >
        )
    }

    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        setEditDocument(id);
        setModelShow(true);
        //props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleClose = () => {
        setModelShow(false);
        setEditDocument(null);
    }
    const handleHistoryClose = () => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const handleOEMClose = () => {
        setOEMShow(false);
    }
    const handleOEMOpen = () => {
        setOEMShow(true);
    }


    const updateStock = (objCallLogs) => {
        if (!_.isEmpty(objCallLogs)) {
            let _data = dataMappingVM(objCallLogs);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "_UPDATE",
                    data: _data,
                    sortName: 'modifiedDate',
                    sortOrder: 'desc'
                });
            }
        }

    }

    const handleRejectClose = (id) => {
        setModelRejectShow(false);
    }

    const handleRejectShow = (id) => {
        setModelRejectShow(true);
    }


    const handleUserClose = (userid) => {
        //console.log('handleUserClose', userid, editDocumentID)
        if (userid && editDocumentID) {
            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();
            const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(editDocumentID);
            updateRef.update({
                isDeleted: true,
                status: callLogStatus.REJECTED,
                rejectReason: 'User Reassign',
                modifiedBy: modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: modifiedDate
            }).then((docRef) => {
                if (userid && editDocumentID) {
                    createEngageRequest(userid, editDocumentID);
                    setEditDocument(null);
                }
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }


        setModelUserShow(false);

    }

    const createEngageRequest = async (userid, docID) => {
        const fields = Object.assign({}, objCallLogs);
        if (docID && userid) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                let _userID = props.dealersettings ? props.dealersettings.id : '';
                let currentDate = window.firebase.firestore.Timestamp.now();

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }
                //fields.clientID = props.dealersettings ? props.dealersettings.client.id : '';
                fields.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                fields.documentID = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc().id;
                fields.status = objData.status === callLogStatus.REJECTED ? callLogStatus.PENDING : objData.status;
                fields.user = userid;
                fields.addedBy = _userID;
                fields.addedDate = currentDate;
                fields.modifiedBy = _userID;
                fields.modifiedDate = currentDate;
                fields.isDeleted = false;
                fields.rejectReason = null;
                fields.noNotify = false;

                const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(fields.documentID);
                updateRef.set(fields, { merge: true }).then((docRef) => {
                    //  console.log('callLogs', fields.documentID)
                    toast.notify('Engage reassign send successfully.', {
                        duration: 2000
                    });

                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });

            } else {
                console.error("No such document2!");
            }

        }
    }


    return (
        <>
            <FilterPanel
                handleOEMOpen={handleOEMOpen}
                sortOrder={sortOrder}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                handleAddStock={() => {
                    setModelShow(true);
                    setEditDocument(null);
                }}
                csvHeader={csvHeader}
                datalist={stocks}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setStockLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([])
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.logsFilter))
                        localStorage.removeItem('logsFilter');

                }}
                stockCount={0}
                dateRange={dateRange}
                handleDateRangeChange={(val) => {
                    if (val) {
                        setDateRange({
                            startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                            endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                        })

                        setStockLoader(true)
                        setDataload(true);
                        setPaging(false);
                        setHasMoreData([])
                        setPageNum(0);
                    }
                    else {
                        setDateRange({
                            startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                            endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                        })
                        setStockLoader(true)
                        setDataload(true);
                        setPaging(false);
                        setHasMoreData([])
                        setPageNum(0);
                    }
                }}
                filterLog={filterLog}
                handleFilterLog={(val) => {
                    setFilterLog(val)
                    setStockLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.logsFilter))
                        localStorage.removeItem('logsFilter');

                }}
                handleRejectShow={handleRejectShow}
                rejectCount={rejectCount}
                isFromSales={props.isFromSales}
            />
            {
                stockLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            <TableView
                                isReorderable={true}
                                datalist={stocks}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allCallLogsFieldsVM}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(callLogsFields && callLogsFields.length > 0) ? callLogsFields : allCallLogsFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailsClick}
                                isSettings={true}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                                displayByValue={true}
                            />
                        </div>
                    )
            }
            <PopUpModal show={modelShow}>
                <AddCallLog
                    show={modelShow}
                    handleClose={handleClose}
                    title={editDocumentID ? 'Log' : 'Add Log'}
                    docID={editDocumentID}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                    isFromSales={props.isFromSales}
                ></AddCallLog>
            </PopUpModal>
            <PopUpModal show={modelRejectShow}>
                <RejectLogList
                    show={modelRejectShow}
                    userID={0}
                    handleClose={handleRejectClose}
                    dealersettings={props.dealersettings}
                    groupUsers={props.groupUsers}
                    clientUsers={props.clientUsers}
                    activeUsers={props.clientUsers && props.clientUsers.filter(e => !_.isEmpty(e.tokkens) && !e.isDeleted)}
                    rejectCount={rejectCount.length}
                    isFromSales={props.isFromSales}
                >
                </RejectLogList>
            </PopUpModal>

            <PopUpModal show={modelUserShow}>
                <Users
                    show={modelUserShow}
                    groupUsers={props.groupUsers}
                    clientUsers={props.clientUsers && props.clientUsers.filter(e => !_.isEmpty(e.tokkens) && !e.isDeleted)}
                    dealersettings={props.dealersettings}
                    handleUserClose={handleUserClose}
                    title='selectUserToReassign'
                    showAdd={false}
                    showQueue={true}
                />
            </PopUpModal>

        </>
    )
}

export default CallLogList