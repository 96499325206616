import React from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import CallLogs from '../callLogs';

const CallLogsContainer = props => {
	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={'Logs'} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen pt-0'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid '>
                <CallLogs history={props.history} isFromSales={true}></CallLogs>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default CallLogsContainer;
