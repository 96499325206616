/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */   
import { LoaderOverlay } from '../../components'
import Dashboard from '../dashboard';
import Settings from '../settings';
import Dealers from '../dealers'
import Meilisearch from '../meilisearch'
import Billing from '../billing'
import Integrations from '../integration';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/admin/dashboard" exact component={Dashboard} />

                        <Route path="/admin/settings" component={Settings} />

                        <Route path="/admin/dealers" exact render={() => (
                            <>
                                <Dealers setUser={this.props.setUser} user={this.props.user} history={this.props.history} />
                            </>
                        )}/>
                        <Route path="/admin/meilisearch" component={Meilisearch} />

                        <Route path="/admin/billing" component={Billing} />

                        <Route path="/admin/integration" component={Integrations} />

                        <Redirect from="/" exact to="/admin/dashboard" />

                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;