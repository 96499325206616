/** LIBRARIES */
import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** COMPONENTS */
import QuoteForm from '../form';
import QuotePreview from '../preview';

const MainContainer = props => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let _type = params.get('type');
	let _previewID = params.get('previewID');
	let _showPrice = params.get('showPrice');

	let _make = params.get('mk');
	let _model = params.get('ml');
	let _vehicletype = params.get('ty');
	let _group = params.get('gp');

	const tab = (_type === 'new' || _type === 'edit') ? 'details' : 'preview';
	const [activeTab, setActiveTab] = useState(tab);
	const [newlySavedData, setNewlySavedData] = useState({});
	const [client, setClient] = useState({});
	const [clientSettings, setClientSettings] = useState({});
	const [currencySymbol, setCurrencySymbol] = useState('$ ');

	let _clientID = props.history.location.pathname.replace(
		'/stock/quote/',
		'',
	);

	useEffect(() => {
		const fetchData = async () => {
			if ((_type === 'print' || _type === 'preview') && _previewID) {
				let quoteData = {}
				const quoteRef = window.firebase.firestore().doc(`stockQuotations/${_previewID}`);
				const quoteSnap = await quoteRef.get();
				quoteData = quoteSnap.data();
				if (quoteData.clientID) {
					const clientSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}`).get()
					const clientData = clientSnap.data();
					const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}/currentSettings/${quoteData.clientID}`).get()
					const clientSettingsData = clientSettingsSnap.data();
					setClient(clientData);
					setClientSettings(clientSettingsData);
					setCurrencySymbol(((!_.isEmpty(clientData) &&
						!_.isEmpty(clientData.currency)) ? clientData.currency.symbol + ' ' : '$ '))
				}
				setNewlySavedData(quoteData)
			}
			else if (_type === 'new') {
				const response = await window.firebase.firestore().collection('stockQuotations')
					.where('clientID', '==', _clientID)
					.where('isDeleted', '==', false)
					.where('vehicle.make', '==', _make)
					.where('vehicle.model', '==', _model)
					.limit(1)
					.get();
				if (response.docs.length > 0) {
					response.docs.forEach((doc) => {
						const quoteData = doc.data();
						console.log('stockQuotations', quoteData)
						setNewlySavedData(quoteData)
						return
					});


				}
			}
		}

		console.log(activeTab)
		fetchData()
	}, [])

	//return <></>
	return activeTab === 'details' ? (
		<QuoteForm
			setActiveTab={setActiveTab}
			history={props.history}
			newlySavedData={newlySavedData}
			setNewlySavedData={setNewlySavedData}
			client={client}
			setClient={setClient}
			clientSettings={clientSettings}
			setClientSettings={setClientSettings}
			currencySymbol={currencySymbol}
			setCurrencySymbol={setCurrencySymbol}
			_type={_type}
			_previewID={_previewID}
		/>
	) : (
		<QuotePreview
			setActiveTab={setActiveTab}
			history={props.history}
			newlySavedData={newlySavedData}
			setNewlySavedData={setNewlySavedData}
			client={client}
			setClient={setClient}
			clientSettings={clientSettings}
			setClientSettings={setClientSettings}
			currencySymbol={currencySymbol}
			setCurrencySymbol={setCurrencySymbol}
			_type={_type}
			_previewID={_previewID}
			_showPrice={_showPrice}
		/>
	);
};

export default MainContainer;
