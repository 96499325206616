import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { PopUpModal, PopoverPanel } from '../../../../components';
import { levelOptions } from "./viewModel";
import StagesModal from './stagesmodal'
import StagePopover from './stagePopover';
import CommonHelper from '../../../../services/common';

const PipelineStages = (props) => {
    const [showStage, setShowStage] = useState(false)
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })
    const [activeTarget, setActiveTarget] = useState(null)

    let userlevelindex = _.find(levelOptions, { value: props.dealersettings.level }) ? _.find(levelOptions, { value: props.dealersettings.level }).index : 1;
    let pipelinelevelindex = _.find(levelOptions, { value: props.pipeline.level }) ? _.find(levelOptions, { value: props.pipeline.level }).index : 1;

    const enableProspectLost = ((!_.isEmpty(props.dealersettings) &&
			!_.isEmpty(props.dealersettings.client) &&
			!_.isEmpty(props.dealersettings.client.clientSettings) && props.dealersettings.client.clientSettings.enableProspectLost) ? true : false);

    const handleOpenStagesView = () => {
        setShowStage(true)
    }

    const handleCloseStagesView = () => {
        setShowStage(false)
    }

    const convertStatusSettings = statusSettings => {
        if (!statusSettings) {
          return []
        }
        const _statusSettings = statusSettings.map(obj => {
          const stage = obj.stage
          const pipeline = obj.pipeline
          const status = obj.status
          return {
            ...obj,
            stage,
            pipeline,
            status,
          }
        })
        return _statusSettings
      }

    const handleShowTargetDetails = (e, pipeline, stage) => {
        setActiveTarget({stage, pipeline})
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }

    return _.isEmpty(props.pipeline) ? (<></>) : (<>
        <div className={`settings-pipeline-loop`} id={props.pipeline.value + '-' + props.pipeline.levelID}>
            <div className="settings-pipeline-loop-title">
                <div className="float-left">
                    <div className="settings-pipeline-name">{props.pipeline.name} {props.pipeline.active === true ? (<></>) : (<span className="pipeline-inactive-span">(<Translate text={'inactive'} />)</span>)}
                        {
                            props.pipeline.level ?
                                (<span className="user-role-span ml-0">{_.find(levelOptions, { value: props.pipeline.level }) ? _.find(levelOptions, { value: props.pipeline.level }).label : props.pipeline.level}</span>)
                                : (<></>)
                        }
                    </div>
                </div>
                {
                    pipelinelevelindex < userlevelindex ? (
                        <div className="float-right">
                            <div className="button-lock float-right ml-3 "> <i className="ico icon-lock"></i> </div>
                        </div>
                    ) : (
                        <div className="float-right">
                            {
                                (props.pipeline.value === 'LeadsBucket' || _.isEmpty(props.dealersettings.level) || pipelinelevelindex < userlevelindex) ?
                                    (<div className="button-lock float-right ml-3 "> <i className="ico icon-lock"></i> </div>) :
                                    (<button type="button" className="mini-button float-right ml-3" title={CommonHelper.showLocale(props, "Delete Pipeline")} onClick={() => { props.hanleDeletePipeline(props.pipeline) }}> <i className="ico icon-delete"></i> </button>)
                            }
                            {props.pipeline.value === 'LeadsBucket' ? enableProspectLost ? (
                                <button type="button" className="mini-button float-right ml-3" title={CommonHelper.showLocale(props, "Status Settings")} onClick={() => { handleOpenStagesView(props.pipeline) }}> <i className="ico icon-settings"></i> </button>
                            ) : (<></>) : (
                                <button type="button" className="mini-button float-right ml-3" title={CommonHelper.showLocale(props, "Status Settings")} onClick={() => { handleOpenStagesView(props.pipeline) }}> <i className="ico icon-settings"></i> </button>
                            )}
                            <button type="button" className="mini-button float-right ml-3" title={CommonHelper.showLocale(props, "Edit Pipeline")} onClick={() => { props.hanleEditPipeline(props.pipeline) }}> <i className="ico icon-edit"></i> </button>
                            <button type="button" className="mini-button float-right ml-3" title={CommonHelper.showLocale(props, "Rearrange Stage")} onClick={() => { props.hanleRearrangeStage(props.pipeline) }}> <i className="ico icon-rearrange"></i> </button>
                            <button type="button" className="btn btn-common float-right" onClick={() => { props.handleAddStage(props.pipeline) }}>  <Translate text={'Add New Stage'} /> </button>
                        </div>
                    )
                }

            </div>
            <div className="settings-pipeline-stage-list">
                <div className="stage-loop-flex ">
                    {
                        props.pipeline.stages.map((rec, index) => {
                            return <div
                                key={index}
                                className="stage-loop">
                                {props.allPipelines.filter(item => (!_.isEmpty(item.statusSettings) && item.statusSettings.filter(statusSetting => statusSetting.stage === rec.value).length)).length ? (<div className="ribbon-stage-green"><div className="ribbon-stage"></div></div>) : (<></>)}
                                <div className="stage-content">
                                    <div className="stage-title text-ellipsis">{rec.name}</div>
                                    {
                                        rec.rottenDays > 0 ? (<div className="stage-text "><Translate text={'unattended for'} />&nbsp; {rec.rottenDays} &nbsp;<Translate text={'days'} /></div>)
                                            : (<div className="stage-text "></div>)
                                    }
                                        <div className="stage-action">
                                    {
                                        pipelinelevelindex < userlevelindex ? (
                                                <button type="button" className="mini-button float-right ml-2 mt-4"> <i className="ico icon-lock"></i> </button>
                                        ) : (
                                            <>
                                                <button type="button" className="mini-button float-right ml-2  mt-4" onClick={() => { props.hanleEditStage(props.pipeline, rec) }}> <i className="ico icon-edit"></i> </button>
                                                {
                                                    (props.pipeline.value === 'LeadsBucket') ? (<></>) : (_.isEmpty(props.dealersettings.level) || pipelinelevelindex < userlevelindex) ?
                                                        (<button type="button" className="mini-button float-right ml-2 mt-4"> <i className="ico icon-lock"></i> </button>) :
                                                        (<button type="button" className="mini-button float-right ml-2 mt-4" onClick={() => { props.hanleDeleteStage(props.pipeline, rec) }}> <i className="ico icon-delete"></i> </button>)
                                                }
                                            </>
                                        )
                                    }
                                        {props.allPipelines.filter(item => (!_.isEmpty(item.statusSettings) && item.statusSettings.filter(statusSetting => statusSetting.stage === rec.value).length)).length ? (
                                            <button type="button" title='Info' className="mini-button float-right ml-2  mt-4" onClick={(e) => { handleShowTargetDetails(e, props.pipeline, rec) }}> <i className="ico icon-info-fill"></i> </button>
                                        ) : (<></>)}
                                        </div>  
                                    {/* {!_.isEmpty(props.pipeline.statusSettings) 
                                        && props.pipeline.statusSettings.filter(statusSetting => statusSetting.stage === rec.value).length > 0
                                        && statusOptions.filter(enqStatus => enqStatus.value === props.pipeline.statusSettings.filter(statusSetting => statusSetting.stage === rec.value)[0].status).length > 0  ? 
                                            statusOptions.filter(enqStatus => enqStatus.value === props.pipeline.statusSettings.filter(statusSetting => statusSetting.stage === rec.value)[0].status)[0].label
                                         : (<></>)} */}

                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
        <PopUpModal show={showStage}>
            <StagesModal
                show={showStage}
                handleClose={() => { handleCloseStagesView() }}
                pipeline={props.pipeline}
                eventStatus={props.eventStatus}
                allPipelines={props.allPipelines}
                convertStatusSettings={convertStatusSettings}
                dealersettings={props.dealersettings}
                selectedClient={props.selectedClient}
            />
        </PopUpModal>
        <PopoverPanel
            showpopover={targetPopOver.show}
            targetpopover={targetPopOver.target}
            title={'Stage Info'}
            position="left"
            className="popover-required-fields"
            closepopover={() => {
                setTargetPopOver({ show: false, target: '', field: '' });
            }}>
            <StagePopover
                {...props}
                stage={activeTarget ? activeTarget.stage: null}
                pipeline={activeTarget ? activeTarget.pipeline: null}
                allPipelines={props.allPipelines}
            />
        </PopoverPanel>
    </>);
}

export default PipelineStages;