import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import { Modal, Accordion, Card } from "react-bootstrap";
import moment from 'moment'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import _ from 'lodash'

import { collection } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'

import { ContentOverlay, PopUpModal } from '../../components'
import Translate from '../../constants/translate';
import FileInfo from './fileInfo'
import AddFile from './add'

import FleetHistoryReducer from '../fleet/history/historyReducer'
import CommonHelper from '../../services/common';
import { objFiles } from './viewModel'
import { firestoreDB } from '../../services/helper';
import { defaultFileCategories } from '../../services/enum';
import ComposeEmail from '../mail/composeEmail';
import enquiryHelper from '../../services/enquiryHelper';


const Files = (props) => {
    let _contract = localStorage.defaultModule === 'service' ? 'serviceContract' :
        localStorage.defaultModule === 'fleetPro' ? 'fleetContract' :
            (localStorage.defaultModule === 'tradeinPro' ? 'tradeinContract' :
                'salesContract')
    let _fileCategories = _.map(defaultFileCategories, function (e) {
        return {
            ...e,
            label: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.label),
            name: CommonHelper.showLocale(props, (e.value === 'contract' ? _contract : e.value), e.name),
        }
    })
    if (!_.isEmpty(props.dynamicCategories)) _fileCategories = Object.assign([], props.dynamicCategories)
    let _dataObject = _.fromPairs(_.map(_fileCategories, i => [i.value, Object.assign([])]))
    let _dataBool = _.fromPairs(_.map(_fileCategories, i => [i.value, true]))
    const [files, dispatch] = useReducer(FleetHistoryReducer, _dataObject)//{ quotation: [], invoice: [], contract: [], others: [] })
    const [checkDataLoad, setDataload] = useState(_dataBool);//{ "quotation": true, "invoice": true, "contract": true, "others": true })    
    const [modelAddShow, setModelAddShow] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [filesLoader, setLoader] = useState(_dataBool)
    const [checkFile, setCheckFile] = useState(_dataObject)
    const [activeTab, setActiveTab] = useState(props.customTab ? props.customTab : _fileCategories[0]?.value)

    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
        files: null,
    });
    const [sendLoader, setSendLoader] = useState(false);

    //const [searchText, setSearchText] = useState('')
    //const [hasMore, setHasMoreData] = useState([])
    //const pageLimit = 20

    const [showMove, setShowMove] = useState(false)
    const node = useRef();
    const { dealersettings } = props;
    const deleteFiles = ((props.dealersettings?.rolePermissions?.permissions?.deleteFiles) ? true : false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        //setShowMove(false);
    };

    //  useEffect(() => {
    //      if (!_.isEmpty(props.dynamicCategories)) {
    //          //setLoader(_dataBool)
    //          //setCheckFile(_dataObject)
    //          //setDataload(_dataBool)
    //          setActiveTab(props.customTab ? props.customTab : _fileCategories[0]?.value)
    //      }

    //  }, [props.dynamicCategories]);

    useEffect(() => {
        if (!props.showAddOpen) return;
        handleFileOpen();
    }, [props.showAddOpen]);

    useEffect(() => {
        // function handleResize() {
        //     setWindowSize({
        //         windowWidth: (window.innerWidth - 30),
        //         windowHeight: (window.innerHeight - 170)
        //     })
        // }
        // window.addEventListener('resize', handleResize);

        return () => {
            //window.removeEventListener('resize', handleResize);
            window[`unSubscribeFileList${props.module ? props.module : ''}`] && window[`unSubscribeFileList${props.module ? props.module : ''}`]();
            window.refCompaniesFiles && window.refCompaniesFiles.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.contacts)) {
            return;
        }
        if (props.companyID)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })

    }, [props.contacts])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }
        let refCollectionData;

        if (props.enquiryID || props.contactID || props.companyID || props.serviceID || props.stockID || props.tradeinProID || props.fleetProID) {

            if (props.enquiryID) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${props.enquiryID}/files`)
                    .where('category', '==', activeTab)
                    .where('isDeleted', '==', false)

                // if (props.module)
                //     refCollectionData = refCollectionData.where('module', '==', props.module)

            }
            else if (props.serviceID) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collection(`serviceJobs/${props.serviceID}/files`)
                    .where('category', '==', activeTab)
                    .where('isDeleted', '==', false)
            }
            else if (props.stockID) {
                refCollectionData = window.firebase.firestore().collection(`stock/${props.stockID}/files`)
                    //.where('category', '==', activeTab)
                    .where('isDeleted', '==', false)
            }
            else if (props.tradeinProID) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collection(`tradeinPro/${props.tradeinProID}/files`)
                    .where('category', '==', activeTab)
                    .where('isDeleted', '==', false)
            }
            else if (props.fleetProID) {
                refCollectionData = window.firebase.firestore().collection(`fleetPro/${props.fleetProID}/files`)
                    //.where('category', '==', activeTab)
                    .where('isDeleted', '==', false)
            }
            else if (props.contactID) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collectionGroup(`files`)
                    .where('contactID', '==', props.contactID)
                    .where('category', '==', activeTab)
                    .where('isDeleted', '==', false)
            }
            else if (props.companyID) {
                let _contactIDs = !_.isEmpty(props.contacts) ? CommonHelper.arrLimit(props.contacts.map(r => { return r.documentID }), 25) : [];
                //console.log(activeTab, _contactIDs)
                window.refCompaniesFiles = combineLatest(
                    collection(firestoreDB(props.dealersettings).firestore().collection(`companies/${props.companyID}/files`)
                        .where('category', '==', activeTab)
                        .where('isDeleted', '==', false)),
                    !_.isEmpty(_contactIDs) ? collection(firestoreDB(props.dealersettings).firestore().collectionGroup(`files`)
                        .where('contactID', 'in', _contactIDs)
                        .where('category', '==', activeTab)
                        .where('isDeleted', '==', false)) : of([])
                ).pipe(
                    map(([enqASnapshot, enqBSnapshot]) => {
                        return [
                            _.map(enqASnapshot, function (obj) { return { ...obj.data(), documentID: obj.id, refPath: (obj?.ref?.path || null) } }),
                            _.map(enqBSnapshot, function (obj) { return { ...obj.data(), documentID: obj.id, refPath: (obj?.ref?.path || null) } }),
                        ]
                    }))
                    .subscribe(async ([enqASnapshot, enqBSnapshot]) => {
                        const files = [];
                        enqASnapshot && enqASnapshot.forEach((_enquiry) => {
                            files.push(dataMappingVM(_enquiry));
                        });
                        enqBSnapshot && enqBSnapshot.forEach((_enquiry) => {
                            if (!files.some(i => i.documentID === _enquiry.documentID))
                                files.push(dataMappingVM(_enquiry));
                        });

                        Promise.all(files).then(_files => {

                            dispatch({
                                type: "SUCCESS_LIST",
                                data: _.orderBy(_files, ["modifiedDate"], ["desc"]),
                                activetab: activeTab
                            });
                            setDataload({
                                ...checkDataLoad,
                                [activeTab]: false
                            })
                            setLoader({
                                ...filesLoader,
                                [activeTab]: false
                            })
                            setPaging(false)
                        })

                    });
            }

            if (!props.companyID)
                window[`unSubscribeFileList${props.module ? props.module : ''}`] = refCollectionData.onSnapshot(onCollectionUpdate);
        }



    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    refPath: (change.doc?.ref?.path || null)
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        let _files = [];
        if (!actionType) {
            // if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            //     setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            //     {
            //         type: activeTab,
            //         lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1].data()
            //     }])
            // }
            // else {
            //     setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            // }
            querySnapshot.forEach(function (doc) {
                //console.log(doc.ref.path, doc.ref, doc)

                var logVM = dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id,
                    refPath: (doc?.ref?.path || null)
                });
                _files.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: actionType ? snapshotDoc : _.orderBy(_files, ["modifiedDate"], ["desc"]),
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...filesLoader,
            [activeTab]: false
        })
        setPaging(false)
    }


    const dataMappingVM = (doc) => {
        const objlogData = Object.assign({}, doc);
        const fileVM = Object.assign({}, objFiles);
        for (let [key, value] of Object.entries(objlogData)) {
            fileVM[key] = value;
        }

        if (props.companyID && fileVM.contactID && !_.isEmpty(props.contacts) && _.find(props.contacts, { documentID: fileVM.contactID })) {
            //fileVM.contact = _.find(props.contacts, { documentID: fileVM.contactID })
            fileVM.displayName = CommonHelper.displayContactName(null, _.find(props.contacts, { documentID: fileVM.contactID }))
        }
        else
            fileVM.displayName = ''

        fileVM.createdOn = moment.unix(fileVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.updatedOn = moment.unix(fileVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.addedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fileVM.addedBy);
        fileVM.modifiedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), fileVM.modifiedBy);

        return fileVM
    }


    const handleAllChecked = (event) => {

        let selectedIds = [];
        if (event.target.checked) {
            files[activeTab] && files[activeTab].map(file => {
                return selectedIds.push(file.documentID)
            })
        }

        setCheckFile({
            ...checkFile,
            [activeTab]: selectedIds
        })
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        //console.log('handleCheckChange', name, checked)

        let selectedIds = checkFile[activeTab]

        if (checked) {
            selectedIds.push(name);
        }
        else {
            let stindex = selectedIds.indexOf(name)
            selectedIds.splice(stindex, 1);
        }

        setCheckFile({
            ...checkFile,
            [activeTab]: selectedIds
        })
    }


    const handleFileMoveTo = (category) => {
        setShowMove(false)
        let selectedIds = Object.assign([], checkFile[activeTab]);
        let fileIds = Object.assign([], checkFile[activeTab]);
        let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        const { serviceID, tradeinProID, fleetProID, enquiryID, stockID, dealerID, companyID, contactID } = props;
        fileIds && fileIds.forEach((fileID) => {

            // if (!props.enquiryID) {
            let _files = files[activeTab].filter(item => item.documentID === fileID)[0]
            //     enquiryID = (_files && _files.length > 0) ? _files[0].enquiryID : '*';
            // }

            // let filePath = props.filePath ? props.filePath : `enquiries/${enquiryID}/files`;

            let filePath = _files.refPath ? _files.refPath : ((serviceID || tradeinProID || fleetProID || enquiryID || stockID || dealerID) && props.filePath) ? `${props.filePath}/${fileID}` :
                ((_files.serviceID || serviceID) ? `serviceJobs/${_files.serviceID || serviceID}/files/${fileID}` :
                    ((_files.tradeinProID || tradeinProID) ? `tradeinPro/${_files.tradeinProID || tradeinProID}/files/${fileID}` :
                        ((_files?.fleetProID || fleetProID) ? `fleetPro/${_files?.fleetProID || fleetProID}/files/${fileID}` :
                            ((_files.enquiryID || enquiryID) ? `enquiries/${_files.enquiryID || enquiryID}/files/${fileID}` :
                                ((_files.stockID || stockID) ? `stock/${_files.stockID || stockID}/files/${fileID}` :
                                    ((_files.companyID || companyID) ? `companies/${_files.companyID || companyID}/files/${fileID}` :
                                        (dealerID ? `clients/${dealerID}/files/${fileID}` :
                                            ((_files.contactID || contactID) ? `contactDetails/${_files.contactID || contactID}/files/${fileID}` :
                                                null))))))));

            if (filePath) {
                let stindex = selectedIds.indexOf(fileID)
                selectedIds.splice(stindex, 1);

                const updateRef = firestoreDB((_files?.stockID || _files?.fleetProID || stockID) ? null : props.dealersettings).firestore().doc(filePath)
                updateRef.
                    set({
                        category,
                        modifiedBy,
                        modifiedDate
                    }, { merge: true })
                    .then((docRef) => {

                    }).catch((error) => {
                        console.error("Error updating contact_details: ", error);
                    });
            }


        });
        //console.log('selectedIds', selectedIds)
        setCheckFile({
            ...checkFile,
            [activeTab]: selectedIds
        })

    }

    const handleFileDelete = () => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the selected file.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let selectedIds = Object.assign([], checkFile[activeTab]);
                let fileIds = Object.assign([], checkFile[activeTab]);
                let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();
                const { serviceID, tradeinProID, fleetProID, enquiryID, stockID, dealerID, companyID, contactID } = props;

                fileIds && fileIds.forEach((fileID) => {
                    let logNotes = 'File deleted';

                    let _files = files[activeTab].filter(item => item.documentID === fileID)[0]

                    let filePath = _files.refPath ? _files.refPath : ((serviceID || tradeinProID || fleetProID || enquiryID || stockID || dealerID) && props.filePath) ? `${props.filePath}/${fileID}` :
                        ((_files.serviceID || serviceID) ? `serviceJobs/${_files.serviceID || serviceID}/files/${fileID}` :
                            ((_files.tradeinProID || tradeinProID) ? `tradeinPro/${_files.tradeinProID || tradeinProID}/files/${fileID}` :
                                ((_files?.fleetProID || fleetProID) ? `fleetPro/${_files?.fleetProID || fleetProID}/files/${fileID}` :
                                    ((_files.enquiryID || enquiryID) ? `enquiries/${_files.enquiryID || enquiryID}/files/${fileID}` :
                                        ((_files.stockID || stockID) ? `stock/${_files.stockID || stockID}/files/${fileID}` :
                                            ((_files.companyID || companyID) ? `companies/${_files.companyID || companyID}/files/${fileID}` :
                                                (dealerID ? `clients/${dealerID}/files/${fileID}` :
                                                    ((_files.contactID || contactID) ? `contactDetails/${_files.contactID || contactID}/files/${fileID}` :
                                                        null))))))));
                    if (filePath) {
                        if (_files?.name)
                            logNotes = 'File: ' + _files.name + ' deleted';

                        let stindex = selectedIds.indexOf(fileID)
                        selectedIds.splice(stindex, 1);

                        const updateRef = firestoreDB((_files?.stockID || _files?.fleetProID || stockID) ? null : props.dealersettings).firestore().doc(filePath)
                        updateRef.
                            set({
                                isDeleted: true,
                                modifiedBy,
                                modifiedDate
                            }, { merge: true })
                            .then((docRef) => {

                                let _objLogData = {
                                    notes: logNotes,
                                    type: 'files',
                                    subType: 'delete',
                                    recordId: fileID,
                                    path: filePath,
                                    enquiryID: (_files?.enquiryID || enquiryID),
                                    serviceID: (_files?.serviceID || serviceID),
                                    tradeinProID: (_files?.tradeinProID || tradeinProID),
                                    fleetProID: (_files?.fleetProID || fleetProID),
                                    contactID: (_files?.contactID || contactID),
                                    companyID: (_files?.companyID || companyID),
                                    stockID: (_files?.stockID || stockID),
                                    dealerID: (_files?.dealerID || dealerID),
                                }
                                Object.keys(_objLogData).forEach(key => (_objLogData[key] === undefined) ? delete _objLogData[key] : {});
                                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                                CommonHelper.saveAuditLog(_objLogData);
                                CommonHelper.saveFileChangeLog(_objLogData, 'updated');

                            }).catch((error) => {
                                console.error("Error updating contact_details: ", error);
                            });
                    }

                });

                setCheckFile({
                    ...checkFile,
                    [activeTab]: selectedIds
                })

            }
        })

    }

    //#region FILE CHANGES

    const handleFileClose = (ID) => {
        setModelAddShow(false);
        if (props.handleFileClose) props.handleFileClose(ID);
    }

    const handleFileOpen = (_type) => {
        //if (_type) activeTab(_type)
        setModelAddShow(true);
    }

    //#endregion
    //#region EMAIL CHANGES   

    const handleSendAsEmail = () => {

        let fileIds = Object.assign([], checkFile[activeTab]);
        let fileList = [];
        fileIds && fileIds.forEach((fileID) => {
            let _files = files[activeTab].filter(item => item.documentID === fileID)[0];
            fileList.push({
                uid: moment()._d.getTime().toString(),
                name: _files.name,
                size: _files.size,
                isUploaded: true,
                url: _files.url,
                path: _files.url ? _files.url.split('/o/')[1].split('?alt')[0].replaceAll('%2F', `/`) : '',
            });
        });
        setShowComposeModal({
            ...showComposeModal,
            show: true,
            type: 'compose',
            title: 'New Message',
            emailData: !_.isEmpty(props.contact) ? {
                mailTo: [{
                    name: props.contact.firstName,
                    email: props.contact.email
                }]
            } : null,
            files: fileList,
        });
    }

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            ...showComposeModal,
            show: false,
            type: '',
            title: '',
            emailData: null,
            files: null,
        });
    };

    const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
        const { mailTo, mailCc, mailBcc, subject, email } = trigger;
        setSendLoader(true);

        if (mailTo === '' && mailCc === '' && mailBcc === '') {
            toast.notify('Please specify at least one recipient.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (subject === '') {
            toast.notify('Please write subject.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (email === '' || email === '<p></p>\n') {
            toast.notify('Please write in email body.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        try {
            let emailObj = {
                token: dealersettings.nylasAccessToken,
                draft: {
                    subject: trigger.subject,
                    body: trigger.email,
                },
            };

            if (trigger.mailTo !== '') {
                emailObj.draft.to = trigger.mailTo.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailCc !== '') {
                emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailBcc !== '') {
                emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.files.length > 0) {
                emailObj.files = trigger.files;
                //console.log('emailObj.files', emailObj.files)
            }

            setTimeout(() => {
                toast.notify('Email has been sent.', {
                    duration: 2000,
                });
                setShowComposeModal({
                    ...showComposeModal,
                    show: false,
                    type: '',
                    title: '',
                    emailData: null,
                    file: null,
                });
            }, 300);

            // console.log('emailObj', emailObj);
            const nylasSendEmail = window.firebase
                .functions()
                .httpsCallable('nylas-sendEmail');
            const resp = await nylasSendEmail(emailObj);
            console.log('nylasSendEmail', resp);
            if (resp.data.success) {
                enquiryHelper.linkEnqruiy(resp.data, props)
                setSendLoader(false);
                setCheckFile({
                    ...checkFile,
                    [activeTab]: []
                })
            } else {
                setSendLoader(false);
                if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
                    Swal.fire({
                        title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
                        cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
                                nylas: null,
                            }, { merge: true });
                            props.history.push('/settings/profile?key=emailsettings');
                        }
                    });
                    return
                }
                else {
                    Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');
                }
            }
        } catch (error) {
            Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');

            setSendLoader(false);
            console.log(error);
        }
    };

    const handleActiveChange = (value) => {
        setActiveTab(value);
        setCheckFile(_dataObject)
    }
    //#endregion
    //let _categoryName = props.customTab ? _.find(_fileCategories, { value: activeTab })?.name : ''
    return (
        <>
            {
                props.showAdd
                    ?
                    <>
                        <div className="add-files-button">
                            <a href="#" className="mini-button" onClick={(e) => {
                                e.preventDefault();
                                handleFileOpen();
                            }}>
                                <i className="ico icon-add"></i>
                            </a>
                        </div>
                        <div className="file-manager-nav pl-0 file-manager-tooltipfix"> <h5>{'Files'}</h5> </div>
                    </>
                    :
                    <></>

            }

            {
                props.stockID || props.fleetProID || props.customTab
                    ?
                    <>
                        {
                            (props.stockID || props.fleetProID) && (<div className={`${props.isListView ? 'filter-panel' : 'linked-activities-title'} pb-2`}>
                                {props.isListView ? <>&nbsp;</> : <Translate text={'Specifications'} />}

                                {
                                    props.isDeivered
                                        ?
                                        <></>
                                        :
                                        <>
                                            <div className={`float-right ${props.isListView ? 'filter-add-button' : 'linked-activities-add'}`}>
                                                <button type="button"
                                                    className="btn btn-primary float-right"
                                                    onClick={(e) => { e.preventDefault(); handleFileOpen(); }}
                                                >
                                                    <i className="ico icon-add"></i> <Translate text={'Add Specification'} />
                                                </button>
                                            </div>
                                        </>
                                }

                            </div>)
                        }
                        {
                            filesLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                files[activeTab] && files[activeTab].length > 0 ?
                                    (
                                        <>
                                            {
                                                !_.isEmpty(files[activeTab]) && _.chain(_.orderBy(files[activeTab], ['displayName'], ['asc']))
                                                    .groupBy("displayName")
                                                    .map((value, key) => ({ displayName: key, lists: value }))
                                                    .value().map((info, index) => {
                                                        return <div className={info.displayName ? 'file-manager-username-wrap' : ''} key={index}>
                                                            {info.displayName ? <div className="file-manager-username">
                                                                <i className='ico icon-Contacts mr-1'></i>
                                                                {info.displayName}</div> : <></>}
                                                            {
                                                                info.lists && _.orderBy(info.lists, ['modifiedDate'], ['desc']).map((file, iindex) => {
                                                                    return <FileInfo
                                                                        key={iindex}
                                                                        file={file}
                                                                        dealersettings={props.dealersettings}
                                                                        selectedfiles={checkFile[activeTab]}
                                                                        handleCheckChange={handleCheckChange}
                                                                        isDeivered={props.isDeivered}
                                                                        enquiryID={props.enquiryID}
                                                                        contactID={props.contactID}
                                                                        companyID={props.companyID}
                                                                        serviceID={props.serviceID}
                                                                        stockID={props.stockID}
                                                                        tradeinProID={props.tradeinProID}
                                                                        fleetProID={props.fleetProID}
                                                                        pathToDelete={props.filePath}
                                                                        contact={props.contact}
                                                                        history={props.history}
                                                                        hideSelect={true}
                                                                    >
                                                                    </FileInfo>
                                                                })
                                                            }
                                                        </div>
                                                    })
                                            }
                                        </>
                                    )
                                    : (
                                        <div className="common-table">
                                            <div className="text-center p-5">
                                                <p><Translate text={'No record found'} /></p>

                                            </div>
                                        </div>
                                    )
                        }
                    </>
                    :
                    <>

                        <div className="file-manager-accordion" >
                            <Accordion className="quickview-related" defaultActiveKey={activeTab}>
                                {
                                    _fileCategories && _fileCategories.map((_info, index) => {
                                        return <Card key={index} >
                                            <>
                                                {(() => {
                                                    return <>
                                                        <Accordion.Toggle as={Card.Header} eventKey={_info.value} id={`file-item-${_info.value}`} onClick={(e) => {
                                                            e.preventDefault();

                                                            if (activeTab === _info.value)
                                                                handleActiveChange(null);
                                                            else
                                                                handleActiveChange(_info.value);
                                                        }}>
                                                            <div className="mb-0">
                                                                <div className="cardlink">
                                                                    <a onClick={(e) => { e.preventDefault(); }}
                                                                        className={`btn btn-link ${activeTab === _info.value ? '' : 'collapsed'}`}                                                                                            >


                                                                        <div className="file-manager-accordion-icon"> <i className="ico icon-folder"></i> </div>
                                                                        <div className="file-manager-accordion-head">
                                                                            {/* {CommonHelper.showLimitedChar(CommonHelper.showLocale(props, _info.name), (window.innerWidth < 1450 ? 30 : 45))} */}
                                                                            <span className="text-file-limit" title={_info.name}><Translate text={_info.name} /></span>   
                                                                        </div>

                                                                        <i className="arrow-collapse"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </Accordion.Toggle>
                                                        {/* <div className="edit-button">
                                                            <a
                                                                className="mini-button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleFileOpen();
                                                                }}
                                                            >
                                                                <i className={`ico icon-add`}></i>
                                                            </a>
                                                        </div> */}

                                                        <Accordion.Collapse eventKey={_info.value}>
                                                            <Card.Body>
                                                                <div className="form-style file-manager-accordion-inner">
                                                                    {
                                                                        filesLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                                                            files[activeTab] && files[activeTab].length > 0 ?
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            props.isDeivered
                                                                                                ?
                                                                                                <>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        ((!_.isEmpty(files[activeTab]) && files[activeTab].length > 1) || (_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]))) && (<div className="file-manager-control">

                                                                                                            {!_.isEmpty(files[activeTab]) && files[activeTab].length > 1 && (<div className="file-manager-check">
                                                                                                                <div className="checkbox icheck-success">
                                                                                                                    <input
                                                                                                                        id={activeTab}
                                                                                                                        type="checkbox"
                                                                                                                        onChange={handleAllChecked}
                                                                                                                        value={activeTab}
                                                                                                                        checked={(_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]) && !_.isEmpty(files[activeTab]) && checkFile[activeTab].length === files[activeTab].length) ? true : false}
                                                                                                                    />
                                                                                                                    <label htmlFor={activeTab}><Translate text={'Select All'} /></label>
                                                                                                                </div>
                                                                                                            </div>)}

                                                                                                            {
                                                                                                                (_.isObject(checkFile[activeTab]) && !_.isEmpty(checkFile[activeTab]))
                                                                                                                    ?
                                                                                                                    <div className={`file-manager-action float-right`}>

                                                                                                                        {
                                                                                                                            deleteFiles
                                                                                                                                ?
                                                                                                                                <div className="file-manager-btn float-right">
                                                                                                                                    <button type="button" className="btn btn-default"
                                                                                                                                        onClick={(e) => { e.preventDefault(); handleFileDelete(); }}>
                                                                                                                                        <i className="ico icon-delete"></i>
                                                                                                                                        <Translate text={'delete'} />
                                                                                                                                    </button>
                                                                                                                                </div>
                                                                                                                                :
                                                                                                                                <></>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            Object.assign([], checkFile[activeTab]).length > 1 && !_.isEmpty(props.dealersettings.nylas) && (<div className="file-manager-btn float-right mr-2">
                                                                                                                                <button type="button" className="btn btn-default"
                                                                                                                                    onClick={(e) => { e.preventDefault(); handleSendAsEmail(); }}>
                                                                                                                                    <i className="ico icon-Mail"></i>
                                                                                                                                    <Translate text={'Send as Email'} />
                                                                                                                                </button>
                                                                                                                            </div>)
                                                                                                                        }

                                                                                                                        <div className="file-manager-btn float-right mr-2">
                                                                                                                            <button type="button" className="btn btn-default"
                                                                                                                                onClick={(e) => {
                                                                                                                                    e.preventDefault();
                                                                                                                                    setShowMove(!showMove)
                                                                                                                                    if (showMove)
                                                                                                                                        document.addEventListener('click', setShowMove(false));
                                                                                                                                }}>
                                                                                                                                <i className="ico icon-folder"></i>
                                                                                                                                <Translate text={'Move To'} />
                                                                                                                            </button>
                                                                                                                            <div ref={node} className={`custom-drop file-manager-drop-align ${showMove ? '' : 'hide'}`}>

                                                                                                                                <ul className="more-dropdown">
                                                                                                                                    {
                                                                                                                                        _fileCategories.map((info, index) => {
                                                                                                                                            return <li key={index} style={activeTab === info.value ? { display: 'none' } : {}} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleFileMoveTo(info.value) }}>
                                                                                                                                                <span>
                                                                                                                                                    <i className="ico icon-folder"></i><Translate text={info.name} />
                                                                                                                                                </span>
                                                                                                                                            </li>

                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </ul>


                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <></>
                                                                                                            }
                                                                                                        </div>)
                                                                                                    }

                                                                                                </>

                                                                                        }
                                                                                        {
                                                                                            !_.isEmpty(files[activeTab]) && _.chain(_.orderBy(files[activeTab], ['displayName'], ['asc']))
                                                                                                .groupBy("displayName")
                                                                                                .map((value, key) => ({ displayName: key, lists: value }))
                                                                                                .value().map((info, index) => {
                                                                                                    return <div className={info.displayName ? 'file-manager-username-wrap' : ''} key={index}>
                                                                                                        {info.displayName ? <div className="file-manager-username">
                                                                                                            <i className='ico icon-Contacts mr-1'></i>
                                                                                                            {info.displayName}</div> : <></>}
                                                                                                        {
                                                                                                            info.lists && _.orderBy(info.lists, ['modifiedDate'], ['desc']).map((file, iindex) => {
                                                                                                                return <FileInfo
                                                                                                                    key={iindex}
                                                                                                                    file={file}
                                                                                                                    dealersettings={props.dealersettings}
                                                                                                                    selectedfiles={checkFile[activeTab]}
                                                                                                                    handleCheckChange={handleCheckChange}
                                                                                                                    isDeivered={props.isDeivered}
                                                                                                                    enquiryID={props.enquiryID}
                                                                                                                    contactID={props.contactID}
                                                                                                                    companyID={props.companyID}
                                                                                                                    serviceID={props.serviceID}
                                                                                                                    stockID={props.stockID}
                                                                                                                    tradeinProID={props.tradeinProID}
                                                                                                                    fleetProID={props.fleetProID}
                                                                                                                    pathToDelete={props.filePath}
                                                                                                                    contact={props.contact}
                                                                                                                    history={props.history}
                                                                                                                //hideSelect={info.lists.length === 1 ? true : false}
                                                                                                                >
                                                                                                                </FileInfo>
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                })
                                                                                        }

                                                                                        <div className="file-manager-accordion-morelink">
                                                                                            <a href="#" onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                handleFileOpen();
                                                                                            }}><i className="ico icon-add mr-1"></i>  {'Add More'}</a></div>
                                                                                    </>
                                                                                )
                                                                                : (
                                                                                    <div className="common-table">
                                                                                        <div className="text-center p-2">
                                                                                            <p><Translate text={'nodata'} /></p>
                                                                                            {
                                                                                                !props.isDeivered && (<button className="btn btn-sm btn-default" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    handleFileOpen();
                                                                                                }}>
                                                                                                    <i className="ico icon-add mr-1"></i> Add </button>)
                                                                                            }

                                                                                        </div>

                                                                                    </div>
                                                                                )
                                                                    }
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </>
                                                })()}
                                            </>


                                        </Card>
                                    })
                                }
                            </Accordion>
                        </div>

                        {/* <div className="file-manager-nav pl-0">
                            <ul>
                                {
                                    _fileCategories.map((info, index) => {
                                        return <li key={index}><a href="#" className={`nav-activity-tabs  ${activeTab === info.value ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab(info.value);
                                            }}><Translate text={info.label} /> </a></li>

                                    })
                                }
                            </ul>
                        </div> */}

                    </>
            }




            <PopUpModal show={modelAddShow}>
                <AddFile
                    show={modelAddShow}
                    enquiryID={props.enquiryID}
                    contactID={props.contactID}
                    companyID={props.companyID}
                    serviceID={props.serviceID}
                    stockID={props.stockID}
                    tradeinProID={props.tradeinProID}
                    fleetProID={props.fleetProID}
                    handleClose={handleFileClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    filePath={props.filePath}
                    dynamicCategories={_fileCategories}
                    module={props.module}
                >
                </AddFile>
            </PopUpModal>

            <PopUpModal show={showComposeModal.show}>
                {showComposeModal.show && (<ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    title={showComposeModal.title}
                    type={showComposeModal.type}
                    emailData={showComposeModal.emailData}
                    userData={{ emailSignature: dealersettings.emailSignature ? dealersettings.emailSignature : '' }}
                    sendLoader={sendLoader}
                    handleSend={handleSend}
                    handleReply={() => { }}
                    dealersettings={dealersettings}
                    file={showComposeModal.files}
                    contact={props.contact}
                ></ComposeEmail>)}
            </PopUpModal>

        </>

    )
}

export default Files;