import React, { useState, useEffect } from 'react'
import "../../../styles/testdrive.scss";
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';

const CustomerConsent = (props) => {
    const [contact, setContact] = useState({})
    const [firestoreDB, setFirestoreDB] = useState()
    const [event, setEvent] = useState()
    const [client, setClient] = useState()
    const [loader, setLoader] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _eid = params.get('eid');

    useEffect(() => {
        if (props.contactid) {
            window.firebase.firestore().doc(`eventsProAttendees/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`).doc(props.contactid).get());

        Promise.all(_promise)
            .then(async docs => {
                if (docs[0]?.exists) {
                    setEvent({
                        ...docs[0].data(),
                        timezone: docs[0].data()?.timezone || moment.tz.guess()
                    })
                    if (docs[0].data().clientID) {
                        const clientDoc = await window.firebase.firestore().collection(`clients`).doc(docs[0].data().clientID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                    }
                    else if (docs[0].data().oemID) {
                        const clientDoc = await window.firebase.firestore().collection(`clientSettings`).doc(docs[0].data().oemID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                    }
                }
                if (docs[1]?.exists) {
                    setContact({
                        ...docs[1].data()
                    })
                }
                setLoader(false)
            })

    }, [firestoreDB]);

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div className="quotation-wrap report-data-load">
                <div className="print-ico">
                    <a href="#" onClick={() => {
                        window.print();
                    }}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                </div>
                <div className="wrapper">
                    <div className="print-wrap">
                        <div className="header">

                            {
                                client && client.logoURL
                                    ?
                                    <div className="logo-center"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                    :
                                    <>
                                        <div className="dealer-center">{!_.isEmpty(client) ? client.name : '--'}</div>
                                    </>
                            }
                        </div>
                        <div className="clear"></div>
                        <div className="clear"></div>
                        <div className="middle">
                            <div className="title-head"><Translate text={'Customer Consent'} upperCase={true} /></div>
                            <div className="clear"></div>
                            <div className="table-wraper brd-none">
                                <div className="table-head"><Translate text={'contactDetails'} /> </div>
                                <div className="table-details">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'Contact Name'} /></span>{CommonHelper.getFullName(null, contact, '--')}</div></td>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'phone'} /></span>{contact.phone ? (contact.phoneCode ? contact.phoneCode : '') + contact.phone : '--'}</div></td>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'email'} /></span>{contact.email ? contact.email : '--'}</div></td>
                                            </tr>
                                            <tr>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'licenseNo'} /></span>{contact.licenseNo ? contact.licenseNo : '--'} </div></td>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'licenseType'} /></span>{contact.licenseType ? contact.licenseType : '--'}</div></td>
                                                <td width="33%"><div className="grid-one"> <span><Translate text={'licenseExpiry'} /></span>{contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD MMM YYYY') : '--'}</div></td>
                                            </tr>
                                        </tbody>

                                    </table>
                                    <div className="tbl-lic-fix">
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td width="25%">
                                                        <div className="license-wrap">
                                                            <span><Translate text={'licenseFrontURL'} /></span>
                                                            {
                                                                (contact.licenseFrontURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseFrontURL`} href={contact.licenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img id="licenseFrontURL" src={contact.licenseFrontURL} alt="" className="" width="150" />

                                                                    </a>
                                                                    :
                                                                    <img id="licenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td width="25%">
                                                        <div className="license-wrap">
                                                            <span><Translate text={'licenseBackURL'} /></span>
                                                            {
                                                                (contact.licenseBackURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseBackURL`} href={contact.licenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img id="licenseBackURL" src={contact.licenseBackURL} alt="" className="" width="150" />
                                                                    </a>
                                                                    :
                                                                    <img id="licenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                            }
                                                        </div>
                                                    </td>

                                                    <td width="25%">  </td>

                                                    <td width="25%"> </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>

                            {
                                !_.isEmpty(contact.terms)
                                    ?
                                    <>

                                        <div className="table-wraper brd-none">
                                            <div className="table-head"><Translate text={'termsAndConditions'} upperCase={true} /></div>
                                            <div className="terms"
                                                dangerouslySetInnerHTML={{ __html: (contact.terms.termsAndConditions ? contact.terms.termsAndConditions : '--') }} />

                                        </div>

                                        {
                                            contact.terms.verbalConsent
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none">
                                                        <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                        <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{'Verbal Consent'}</strong>{': Has the customer verbally provided you with their consent to capture and record their details/information.'}</span>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {
                                                        contact.terms.consentType
                                                            ?
                                                            <>
                                                                <div className="table-wraper brd-none">
                                                                    <img src={_images.checkboxTick} alt="" width="15" style={{ float: 'left', marginTop: '2px' }} />
                                                                    <span className={'ml-2'} style={{ fontSize: '14px' }}><strong>{`${contact.terms.consentType} Consent`}</strong>{`: Has the customer ${contact.terms.consentType}ly provided you with their consent to capture and record their details/information.`}</span>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                        }



                                        <div className="table-wraper brd-none">
                                            <div className="table-head"><Translate text={'signature'} /> </div>
                                            <div className="vehicle-map">
                                                {
                                                    contact.terms.signature
                                                        ?
                                                        <img id={`terms-signature`} src={contact.terms.signature} width="120" />
                                                        :
                                                        <img id={`terms-signature`} src={_images.sign} width="120" />
                                                }
                                            </div>
                                        </div>

                                        {
                                            contact.terms.addedBy
                                                ?
                                                <>
                                                    <div className="table-wraper cst-consent-wrapper">
                                                        <div className="footer-left">
                                                            {contact.terms.addedDate ?
                                                                <>
                                                                    &nbsp;on &nbsp;<strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(contact.terms.addedDate.seconds)).tz(client.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment.unix(contact.terms.addedDate.seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`))}</strong>
                                                                </>
                                                                : '--'}
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }


                                    </>
                                    :
                                    <>
                                    </>
                            }

                        </div>
                    </div>
                    <div className="push"></div>
                </div>
                <div className="clear"></div>
                <div className="footer">
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment().format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`)}</div>

                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </>
    )
}
export default CustomerConsent;