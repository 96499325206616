import React, { useEffect, useRef, useState, useContext } from 'react';
import {  Dropdown, Modal, Popover } from 'react-bootstrap';
import { InputMultipleText, TextEditor } from '../../components';
import _, { round, union } from 'lodash';
import toast from 'toasted-notes';
import moment from 'moment';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from 'react-html-parser';
import { Progress } from 'antd';
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';
import { MainContext } from '../layout/provider';
import SummerEditor from './summereditor/summereditor';
import ReactSummernote from 'react-summernote';
import { storageBucket } from '../../services/helper';

function insert(str, index, value) {
	return str.substr(0, index) + value + str.substr(index);
}

const DraftComposeModal = ({
	show,
	handleClose,
	token,
	title,
	type,
	emailData,
	userData,
	sendLoader,
	setSendLoader,
	handleSend,
	handleReply,
	thread,
	// emailtemplates,
	dealersettings,
}) => {
	const [attachments, setAttachments] = useState([]);
	const [progress, setProgress] = useState(null);
	const [currentUid, setCurrentUid] = useState(null);
	const [showPop, setShowPop] = useState(false);
	const { emailTemplates } = useContext(MainContext)
	const [templates, setTemplates] = useState(emailTemplates ? emailTemplates : []);
	const [templateSearchText, setTemplateSearchText] = useState('');
	const node = useRef();

	const handleInitializeEmailBody = () => {
		let emailHtml;
		// // console.log('emailContent', emailData);
		if (type === 'reply') {
			emailHtml = emailData.replyToEmailContent
				? emailData.replyToEmailContent
				: '<p></p>';

			emailHtml = `<p>On ${moment
				.unix(emailData.replyEmailDate)
				.format('MMM DD, YYYY hh:mm A')}, ${
				emailData.replyEmailName
			} &#60;${emailData.replyEmailAddress}&#62; wrote: </p>${emailHtml}`
			return emailHtml;
		} else if (type === 'forward') {
			return `${emailData.replyToEmailContent}`;
		}

		// if (userData && userData.emailSignature) {
		// 	emailHtml = '<p></p>';
		// 	emailHtml = insert(
		// 		emailHtml,
		// 		emailHtml.length - 4,
		// 		userData.emailSignature,
		// 	);

		// 	return emailHtml;
		// }
		return '<p></p>';
	};

	const replyToAll = !_.isEmpty(emailData)
		? emailData.replyToAll && true
		: false;

	const [loading, setLoading] = useState(true);
	const [drafts, setDrafts] = useState(null);
	const [errorFields, setErrorFields] = useState({});
	const [trigger, setTrigger] = useState({
		mailTo:
			type === 'reply'
				? emailData.replyTo.map(replyObj => replyObj.email).join(',')
				: '',
		mailCc: replyToAll
			? emailData.replyToCC.map(replyObj => replyObj.email).join(',')
			: '',
		mailBcc: '',
		replyTo: '',
		subject: '',
		// email: handleInitializeEmailBody(),
	});
	const [email, setEmail] = useState(handleInitializeEmailBody())
	const [state, setState] = useState({
		showCc: replyToAll ? true : false,
		showBcc: false,
	});

	useEffect(() => {
		const loadMessages = async () => {
			if (!thread) {
				return;
			}

			setLoading(true);
			// console.log(thread);
			const messageIdList = thread.draft_ids;
			const messageIdListLength = thread.draft_ids.length;
			let arrayCompleted = false;
			// // console.log('THIS IS SELECTED MESSAGE IDS messageIdList', messageIdList);
			const nylasMessage = window.firebase
				.functions()
				.httpsCallable('nylas-getMessage');
			setDrafts([]);
			const check = [];
			messageIdList.forEach((messageId, index) => {
				const dataObj = {
					token: token,
					id: messageId,
				};

				nylasMessage(dataObj).then(message => {
					check.push(message.data);
					if (messageIdListLength === check.length) {
						arrayCompleted = true;
						setDrafts(_.orderBy(check, ['date'], ['asc']));
						setLoading(false);
						// console.log(check[0])
						setTrigger({
							...trigger,
							mailTo: _.isEmpty(check[0].to)
								? ''
								: check[0].to.map(replyObj => replyObj.email).join(','),
							mailCc: _.isEmpty(check[0].cc)
								? ''
								: check[0].cc.map(replyObj => replyObj.email).join(','),
							mailBcc: _.isEmpty(check[0].bcc)
								? ''
								: check[0].bcc.map(replyObj => replyObj.email).join(','),
							replyTo: _.isEmpty(check[0].reply_to)
								? ''
								: check[0].reply_to.map(replyObj => replyObj.email).join(','),
							subject: _.isEmpty(check[0].subject) ? '' : check[0].subject,
							// email: _.isEmpty(check[0].body) ? '' : check[0].body,
						});
						setEmail(_.isEmpty(check[0].body) ? '' : check[0].body)
						if (!_.isEmpty(check[0].body)) {
							ReactSummernote.reset()
							var node = document.createElement('div');
							node.innerHTML = `${check[0].body}`
							ReactSummernote.insertNode(node)
						}
					}
				}).catch(error => console.error(error))
			});
		};

		loadMessages();
	}, [token, thread]);

	// useEffect(() => {
	// 	document
	// 		.querySelector('.editor-item-custom')
	// 		.classList.add('custom-editor-height');

	// 	return () => {
	// 		document
	// 			.querySelector('.editor-item-custom')
	// 			.classList.remove('custom-editor-height');
	// 	};
	// }, []);

	const handleInsertSignature = () => {
		if (userData && userData.emailSignature) {
			ReactSummernote.reset()
			var node = document.createElement('div');
			node.innerHTML = `${email}<br>${userData.emailSignature}`
			ReactSummernote.insertNode(node)
		}
	};

	const handleInsertTemplate = emailTemplate => {
		ReactSummernote.reset()

		var node = document.createElement('div');
			node.innerHTML = `${emailTemplate.bodyHTML}`
			ReactSummernote.insertNode(node)
		return;
	};

	const onEditorStateChange = obj => {
		// console.log(obj);
		setTrigger({
			...trigger,
			[obj.name]: obj.value,
		});
	};

	const handleChange = obj => {
		setEmail(obj)
	};

	// for email
	const handleOnChangeMultipleText = (val, name) => {
		setTrigger({
			...trigger,
			[name]: !_.isEmpty(val) ? val.join(',') : '',
		});
	};

	const removeEmail = (index, name) => {
		let arr = trigger[name] ? trigger[name].split(',') : [];
		arr.splice(index, 1);
		setTrigger({
			...trigger,
			[name]: arr.join(','),
		});
	};

	const onSelectFile = e => {
		if (progress) {
			return;
		}

		if (e.target.files && e.target.files.length > 0) {
			var _size = e.target.files[0].size;
			var filesize = _size / 1024 / 1024;

			if (filesize > 25) {
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Maximum file size exceeded.'), '', 'info');
				return;
			}

			// check if file size of all attachments is not more than 25 mb
			let totalSize = attachments.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.size;
			}, 0);

			totalSize = totalSize + _size;
			totalSize = totalSize / 1024 / 1024;

			if (totalSize > 25) {
				Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Maximum files size exceeded.'), '', 'info');
				return;
			}

			let reader = new FileReader();
			let file = e.target.files[0];
			let uid = moment()._d.getTime().toString();
			let arrayToReturn;
		//	console.log('uid at onSelectFile', uid);
			reader.onloadend = () => {
				arrayToReturn = [
					...attachments,
					{
						uid,
						name: file.name,
						size: file.size,
						isUploaded: false,
						url: null,
						path: null,
					},
				];
				setAttachments(_.orderBy(arrayToReturn, ['uid'], ['desc']));
				saveFileToStorage(
					file,
					uid,
					_.orderBy(arrayToReturn, ['uid'], ['desc']),
				);
			};
			reader.readAsDataURL(file);
		}
	};

	const saveFileToStorage = (file, uid, attachments) => {
		let clientID = dealersettings ? dealersettings.client.id : '';
	//	console.log('uid at saveFileToStorage', uid);

		var storageRef = storageBucket(dealersettings).ref(
				`${clientID}/emailattachments/${localStorage.uid}/${uid}/${file.name}`,
			)
			.put(file);
		storageRef.on(
			'state_changed',
			snapshot => {
				var percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				if (percentage > 0 && percentage < 99) {
					setProgress(round(percentage));
				//	console.log('percentage running', percentage);
				} else {
					// setImageLoader(false)
					// console.log('percentage done');
					// console.log('storage red get url');
					storageRef.snapshot.ref
						.getDownloadURL()
						.then(downloadURL => console.log(downloadURL));
				}
			},
			error => {
				console.error(error);
			},
			() => {
				storageBucket(dealersettings).ref(`${clientID}/emailattachments/${localStorage.uid}/${uid}`)
					.child(file.name)
					.getDownloadURL()
					.then(dataURL => {
						// console.log(dataURL);
						// console.log('uid at finding', uid);

						console.log(
							' is ir running or nott',
							attachments.forEach(att => console.log(att)),
						);
						let attachmentObj = attachments.filter(att => att.uid === uid)[0];
						let allAttachments = attachments.filter(att => att.uid !== uid);

						//console.log('attachmentObj at finding', attachmentObj);
						attachmentObj.isUploaded = true;
						attachmentObj.url = dataURL;
						attachmentObj.path = `${clientID}/emailattachments/${localStorage.uid}/${uid}/${file.name}`;
						setProgress(null);
						setAttachments(
							_.orderBy(
								union([attachmentObj], allAttachments),
								['uid'],
								['desc'],
							),
						);
						return dataURL;
					});
			},
		);
	};

	const handleRemoveAttachment = uid => {
		//console.log('handleRemove', uid);
		let allAttachments = attachments.filter(att => att.uid !== uid);
		setAttachments(_.orderBy(allAttachments, ['uid'], ['desc']));
	};

	const handleSubmit = () => {
		if (progress !== null) {
			return;
		}

		let state = trigger;
		state.email = email;
		if (attachments.length > 0) {
			state.files = attachments.map(attachment => attachment.path);
		} else {
			state.files = [];
		}

		if (type === 'reply') {
			handleReply(state, emailData, handleClose, replyToAll);
		} else {
			handleSend(state, emailData, handleClose, replyToAll);
		}
	};

	const filterTemplates = (template) => {
		if (!templateSearchText) {
			return true
		} else if (template.title.toLowerCase().includes(templateSearchText.toLowerCase())) {
			return true
		}

		return false
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
				document.removeEventListener("mousedown", handleClick);
		};
}, []);

const handleClick = e => {       
		if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner')) {
				return;
		}
		setShowPop(false);
};

	return (
		<Modal
			id='compose-mail'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-compose-mail'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title' id=''>
						{title}
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='compose-mail-popup form-style'>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12'>
									<div className='input-group'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id=''
												style={{ width: '50px' }}
											>
												To:
											</span>
										</div>
										<InputMultipleText
											placeholder={`enter email`}
											disbaled={true}
											className={`form-control react-multi-tag ${errorFields['mailTo']}`}
											name='mailTo'
											onChange={emails => {
												handleOnChangeMultipleText(emails, 'mailTo');
											}}
											value={
												!_.isEmpty(trigger.mailTo)
													? trigger.mailTo.split(',')
													: []
											}
											validateEmail={true}
											getLabel={(email, index) => {
												return (
													<div data-tag key={index}>
														{email}
														<span
															data-tag-handle
															onClick={() => removeEmail(index, 'mailTo')}
														>
															×
														</span>
													</div>
												);
											}}
										/>
										<div className='input-group-append' id='button-addon4'>
											{/* Dont show append button when reply is true */}
											<>
												{!state.showCc && (
													<button
														className='btn btn-outline-secondary'
														type='button'
														onClick={() => setState({ ...state, showCc: true })}
													>
														Cc
													</button>
												)}
											</>
											<>
												{!state.showBcc && (
													<button
														className='btn btn-outline-secondary'
														type='button'
														onClick={() =>
															setState({ ...state, showBcc: true })
														}
													>
														Bcc
													</button>
												)}
											</>
										</div>
									</div>
									{state.showCc && (
										<div className='input-group'>
											<div className='input-group-prepend'>
												<span
													className='input-group-text'
													id=''
													style={{ width: '50px' }}
												>
													Cc:
												</span>
											</div>
											<InputMultipleText
												placeholder={`enter email`}
												className={`form-control react-multi-tag ${errorFields['mailCc']}`}
												name='mailCc'
												onChange={emails => {
													handleOnChangeMultipleText(emails, 'mailCc');
												}}
												value={
													_.isEmpty(trigger.mailCc)
														? []
														: replyToAll === true
														? trigger.mailCc.split(',')
														: trigger.mailCc.split(',')
												}
												validateEmail={true}
												getLabel={(email, index) => {
													return (
														<div data-tag key={index}>
															{email}
															<span
																data-tag-handle
																onClick={() => removeEmail(index, 'mailCc')}
															>
																×
															</span>
														</div>
													);
												}}
											/>
										</div>
									)}
									{state.showBcc && (
										<div className='input-group'>
											<div className='input-group-prepend'>
												<span
													className='input-group-text'
													id=''
													style={{ width: '50px' }}
												>
													Bcc:
												</span>
											</div>
											<InputMultipleText
												placeholder={`enter email`}
												className={`form-control react-multi-tag ${errorFields['mailBcc']}`}
												name='mailBcc'
												onChange={emails => {
													handleOnChangeMultipleText(emails, 'mailBcc');
												}}
												value={
													!_.isEmpty(trigger.mailBcc)
														? trigger.mailBcc.split(',')
														: []
												}
												validateEmail={true}
												getLabel={(email, index) => {
													return (
														<div data-tag key={index}>
															{email}
															<span
																data-tag-handle
																onClick={() => removeEmail(index, 'mailBcc')}
															>
																×
															</span>
														</div>
													);
												}}
											/>
										</div>
									)}
								</div>
							</div>
							{type !== 'reply' && (
								<div className='form-row mb-2'>
									<div className='form-group col-md-12'>
										<div className='input-group'>
											<div className='input-group-prepend'>
												<span className='input-group-text' id=''>
													Subject:
												</span>
											</div>
											<input
												type='text'
												className='form-control'
												name='subject'
												placeholder='Subject'
												value={trigger.subject}
												onChange={e => onEditorStateChange(e.target)}
											/>
										</div>
									</div>
								</div>
							)}

							<div className='form-row mb-2'>
								<div className='form-group col-md-12'>
									{/* <TextEditor
										name={'email'}
										placeholder={'Write Email'}
										value={trigger.email}
										onChange={onEditorStateChange}
										toolbarOptions={[
											'fontFamily',
											'fontSize',
											'inline',
											'textAlign',
											'colorPicker',
											'list',
											'history',
										]}
										height={650}
									/> */}
									<SummerEditor 
										value={email}
										height={window.innerHeight - 350 + 'px'}
										handleChange={handleChange}
										emailType={'forward'}
										customFonts={dealersettings?.customFonts || []}
									/>
								</div>
							</div>
							<div className='form-row mb-2'>
								<div className='form-group col-md-12 compose-mail-attach'>
									{attachments.length > 0 &&
										attachments.map(attachment => (
											<div className='mail-attach-list'>
												{attachment.isUploaded && (
													<div
														className='mail-attach-remove'
														onClick={() =>
															handleRemoveAttachment(attachment.uid)
														}
													>
														{' '}
														<i className='ico icon-remove'> </i>
													</div>
												)}
												<a id='' href='#' target='_blank'>
													<div className='mail-attach-text'>{attachment.name}</div>
													<div className='mail-attach-size'>
														{' (' +
															CommonHelper.showFileSize(attachment.size) +
															')'}
													</div>
													{progress !== null && !attachment.isUploaded ? (
														<Progress percent={progress} />
													) : (
														<></>
													)}
													{attachment.isUploaded ? (
														<Progress percent={100} />
													) : (
														<></>
													)}
												</a>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<div
					className='settings-profile-upload'
					style={{ display: 'block', marginBottom: '0px' }}
				>
					<label
						htmlFor='logoURL'
						className={`btn btn-default float-left ml-2 font-600 email-attachments-btn`}
					>
						<i className='ico icon-attachment'></i>
						Attach
						<input
							className='fileInput'
							type='file'
							name='testee'
							id='logoURL'
							accept='*'
							style={{ display: 'none' }}
							onChange={e => onSelectFile(e)}
							disabled={progress ? true : false}
						/>
					</label>
				</div>
				<button
					type='button'
					className='btn btn-default float-left ml-2'
					onClick={handleInsertSignature}
				>
					<i className='ico icon-sign'></i> Signature
				</button>
				<Dropdown
					className='filter-pipeline-btn ml-2 float-left dropdown-toggle'
					alignRight
					ref={node} 
					show={showPop}
				>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' onClick={() => {
                    setShowPop(!showPop)
                }}>
						<i className='ico icon-template'></i> <span>Templates</span>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right template-add-dropdown'
						ChildClass='pipeline-dropdown'
						xplacement='top-end'
					>
						<Popover
					id='popover-basic-mail'
					className='popover popover-mail-templates popover-x in kv-popover-active show left left-bottom'
					style={{
						top: '-317px',
						left: '42px',
						display: 'block',
						zIndex: '1029',
						padding: '0px',
					}}
					
				>
					<Popover.Title>
							<h3 className='popover-header popover-title'>
							<span className='close' data-dismiss='popover-x' onClick={() => setShowPop(false)}>
								×
							</span>
							Templates{' '}
						</h3>
						<div className='email-list-search'>
							<div className='input-group template-search'>
								<input type='text' className='form-control' placeholder='Search' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} style={{width: '100%'}} />
								<div className='input-group-append input-search-clear'>
									{templateSearchText.length > 0 && (
										<a href='#' className='input-search-clear-icon' onClick={() => setTemplateSearchText('')}> 
											<i className='ico icon-remove'></i>
										</a>
									)}
								</div>
							</div>
						</div>
					</Popover.Title>
					<Popover.Content>
						<div className='popover-body popover-content p-0'>
							<div className='mailtemplate-column-nav'>
								<ul>
									{templates && templates.length > 0 && templates.filter(template => filterTemplates(template)).map((template, index) => (
										<li onClick={() => {handleInsertTemplate(template); setShowPop(false);}}>
											<a href='#' key={index}>{template.title}</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</Popover.Content>
				</Popover>
						
					</Dropdown.Menu>
				</Dropdown>
				<button
					type='button'
					className='btn btn-default float-right'
					onClick={handleClose}
				>
					{
						// loader ?
						//   (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
						//   : (<></>)
					}
					Discard
				</button>
				<button
					type='button'
					className='btn btn-primary float-right mr-2 '
					aria-label='Close'
					// disabled={type === 'add'}
					onClick={handleSubmit}
				>
					Send{' '}
					{sendLoader ? (
						<span
							className='spinner-border spinner-button mr-1'
							role='status'
							aria-hidden='true'
						></span>
					) : (
						<></>
					)}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DraftComposeModal;
