import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import moment from 'moment';

import LeadsBucket from './leadsBucket'
import ActivitiesWidget from './activitiesWidget'
import CampaignCounts from './campaignCounts'
import ActivitiesCount from './activitiesCount'
import InboundActivitiesCount from './inboundactivitiesCount'
import OriginCounts from './originCounts'
import EnquiryTypeCounts from './enquiryTypeCounts'
import SalesFunnel from './salesFunnel'
import StatusCounts from './statusCounts';
import SalesTarget from './salesTarget';
import DealerTarget from './dealerTarget';
import LabelCounts from './labelsCounts';
import AllUsersTarget from './allUsersTarget'
import FleetCounts from './fleetCounts'
import LoanBookingsWidget from './loanBookingsWidget'
import InboundActivitiesWidget from './inboundactivitiesWidget'
import PerformanceReview from "./performanceReview";
import TestDriveBookings from "./testdriveBookings";


import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import DynamicFieldsWidget from "./dynamicFieldsWidget";
import TestDriveInbound from "./testdriveInbound";
import FinanceCreditScoreWidget from "./FinCreditScoreWidget";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialLayout !== this.state.layouts) {
      this.setState({ layouts: { lg: nextProps.initialLayout } });
    }
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onLayoutChange(layout, layouts) {
    //console.log('layout, layouts', layout, layouts)
    this.props.onLayoutChange(layout, this.props.sortedWidgets, this.props.hiddenWidgets);
  }

  calculateHeight(sectionID, layoutArr) {
    let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
    if (selectedSection.length === 0)
      return 30
    let columnHeight = Number(selectedSection[0].h)
    if (columnHeight === 1) {
      return 30
    }
    columnHeight = columnHeight - 1
    columnHeight = columnHeight * 45
    columnHeight = columnHeight + 30

    return columnHeight
  }

  filterLayouts = (layouts) => {

    if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
      if (layouts.lg[0].i === '0') {
        layouts.lg.shift()
      }
    }
    //console.log('layout passed to the grid', layouts)

    // settings height and width to 0 of hidden widgets
    // let layoutWithDashboardSettingsApplied = {...layouts}
    let layoutWithDashboardSettingsApplied = layouts.lg.filter(a => a.i !== 'null').map((rec, index) => {
      return this.props.hiddenWidgets.includes(rec.i) ? {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        w: 0,
        h: 0
      } : {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        i: rec.i === 'null' ? `${index}` : rec.i,
        maxH: 16,
        maxW: 12,
        minH: (rec.i === 'LeadsBucket' || rec.i === 'TestDriveInbound' || rec.i === 'ActivitiesWidget' || rec.i === 'FinanceActivitiesWidget' || rec.i === 'AfterMarketActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
        minW: 4,
        h: (this.props.isMclaren === true && rec.i === 'DealerTarget' && rec.h < 22) ? 22 : (this.props.isMclaren === false && rec.i === 'DealerTarget' && rec.h >= 22) ? 11 : (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'TestDriveInbound' || rec.i === 'ActivitiesWidget' || rec.i === 'FinanceActivitiesWidget' || rec.i === 'AfterMarketActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
        w: (this.props.isMclaren === true && rec.i === 'DealerTarget' && rec.w < 8) ? 8 : (this.props.isMclaren === false && rec.i === 'DealerTarget' && rec.w >= 8) ? 4 : (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
        isResizable: (rec.i === 'LeadsBucket' || rec.i === 'TestDriveInbound' || rec.i === 'ActivitiesWidget' || rec.i === 'FinanceActivitiesWidget' || rec.i === 'AfterMarketActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
        isBounded: false,
        isDraggable: true,
        resizeHandles: false
      }
    })
    return { lg: layoutWithDashboardSettingsApplied }
  }


  render() {
    const enableLoanBooking = ((!_.isEmpty(this.props.dealersettings) &&
      !_.isEmpty(this.props.dealersettings.client) &&
      !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
      this.props.dealersettings.client.clientSettings.enableLoanBooking) ? true : false);
    const showInboundActivities = ((!_.isEmpty(this.props.dealersettings) &&
      !_.isEmpty(this.props.dealersettings.client) &&
      !_.isEmpty(this.props.dealersettings.client.clientSettings) &&
      this.props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);

    const accessToFinanceActivity = this.props?.dealersettings?.rolePermissions?.permissions?.accessToFinanceActivity ? true : false;
    const accessToAfterMarketActivity = this.props?.dealersettings?.rolePermissions?.permissions?.accessToAfterMarketActivity ? true : false;
    const accessToViewFinanceCreditScore = this.props?.dealersettings?.rolePermissions?.permissions?.accessToViewFinanceCreditScore ? true : false;

    const d365testdriveEnabled = ((!_.isEmpty(this.props.dealersettings) &&
      !_.isEmpty(this.props.dealersettings.client) && !_.isEmpty(this.props.dealersettings.client.integrations) &&
      this.props.dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0] &&
      this.props.dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0].active === true &&
      this.props.dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0].enabled === true
    ) ? true : false);

    const performanceReviewEnabled = ((this.props.dealersettings && !_.isEmpty(this.props.dealersettings.client) && !_.isEmpty(this.props.dealersettings.client.moduleSettings) &&
      !_.isEmpty(this.props.dealersettings.client.moduleSettings.performanceReview) &&
      this.props.dealersettings.client.moduleSettings.performanceReview.enabled) ? true : false);

    const {
      handleShowEnquiryList,
      handleShowActivityList,
      pipelineEnquiries,
      selectedPipeline,
      selectedUser,
      clientLostApproval,
      managerLostApproval,
      dateRange,
      applydateRange,
      loader,
      allActivities,
      testdrives,
      tradeins,
      contacts,
      dealersettings,
      pipeline,
      ownerDashboard,
      hiddenWidgets,
      sortedWidgets,
      removeUnAllowedWidgets,
      staticDashboard
    } = this.props;

    return (pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval)) ? (
      <ResponsiveReactGridLayout
        {...this.props}
        layouts={staticDashboard ? this.state.layouts : this.filterLayouts(this.state.layouts)}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={this.state.mounted}
        compactType={this.state.compactType}
        preventCollision={!this.state.compactType}
        margin={[15, 15]}
        isDraggable={!staticDashboard}
        isResizable={!staticDashboard}
        draggableHandle='.draggable-section'
        draggableCancel='.dashboard-box-body'
      >
        {(pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval)) &&
          sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsBucket') ? (
          <div key={'LeadsBucket'} id={'LeadsBucket'}>
            <LeadsBucket {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsBucket', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsBucket')}
              refreshData={this.props.refreshData}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsBucket'}></Fragment>)}

        {d365testdriveEnabled && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('TestDriveInbound') ? (
          <div key={'TestDriveInbound'} id={'TestDriveInbound'}>
            <TestDriveInbound {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('TestDriveInbound', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('TestDriveInbound')}
              refreshData={this.props.refreshData}
              hideRemoveBtn={staticDashboard}
              d365testdriveEnabled={d365testdriveEnabled}
            />
          </div>
        ) : (<Fragment key={'TestDriveInbound'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByLabels') ? (
          <div key={'LeadsByLabels'} id={'LeadsByLabels'}>
            <LabelCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByLabels', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByLabels')}
              hideRemoveBtn={staticDashboard}
              minHeight={staticDashboard ? '350px' : ''}
            />
          </div>
        ) : (<Fragment key={'LeadsByLabels'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
          <div key={'Activities'} id={'Activities'}>
            {
              (showInboundActivities && this.props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true))
                ?
                <InboundActivitiesCount {...this.props}
                  selectedPipeline={selectedPipeline}
                  selectedUser={selectedUser}
                  height={this.calculateHeight('Activities', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  refreshData={this.props.refreshData}
                  removeWidget={() => this.props.removeWidget('Activities')}
                  roleIDs={this.props.dealersettings.client.roles
                    .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === false)
                    .map(r => { return r.documentID })
                    .join(',')}
                  title={'Activities'}
                  hideRemoveBtn={staticDashboard}
                  refreshTimer={420000}
                  delayTimer={1000}
                />
                :
                <ActivitiesCount {...this.props}
                  selectedPipeline={selectedPipeline}
                  selectedUser={selectedUser}
                  height={this.calculateHeight('Activities', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  refreshData={this.props.refreshData}
                  removeWidget={() => this.props.removeWidget('Activities')}
                  hideRemoveBtn={staticDashboard}
                  titlePrefix={'Sales'}
                  docID={'salesActivities'}
                  refreshTimer={420000}
                  delayTimer={1000}
                />
            }

          </div>
        ) : (<Fragment key={'Activities'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('TestDriveBooking') ? (
          <div key={'TestDriveBooking'} id={'TestDriveBooking'}>
            <TestDriveBookings {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('TestDriveBooking', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('TestDriveBooking')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'TestDriveBooking'}></Fragment>)}

        {accessToFinanceActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FinanceActivities') ? (
          <div key={'FinanceActivities'} id={'FinanceActivities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FinanceActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FinanceActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
              activityType={'financeactivity'}
              originType={'finance'}
              titlePrefix={'Finance'}
              docID={'financeActivities'}
              refreshTimer={480000}
              delayTimer={3000}

            />
          </div>
        ) : (<Fragment key={'FinanceActivities'}></Fragment>)}

        {accessToFinanceActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FinanceActivitiesWidget') ? (
          <div key={'FinanceActivitiesWidget'} id={'FinanceActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('FinanceActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FinanceActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              activityType={'financeactivity'}
              titlePrefix={'Finance'}
              docID={'financeActivitiesWidget'}
              title={'Finance Leads without scheduled activity'}
              refreshTimer={510000}
              delayTimer={4000}
            />
          </div>
        ) : (<Fragment key={'FinanceActivitiesWidget'}></Fragment>)}

        {accessToAfterMarketActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('AfterMarketActivities') ? (
          <div key={'AfterMarketActivities'} id={'AfterMarketActivities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('AfterMarketActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('AfterMarketActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
              activityType={'afterMarketactivity'}
              originType={'afterMarket'}
              titlePrefix={'After Market'}
              docID={'afterMarketActivities'}
              refreshTimer={540000}
              delayTimer={5000}


            />
          </div>
        ) : (<Fragment key={'AfterMarketActivities'}></Fragment>)}

        {accessToAfterMarketActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('AfterMarketActivitiesWidget') ? (
          <div key={'AfterMarketActivitiesWidget'} id={'AfterMarketActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('AfterMarketActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('AfterMarketActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              activityType={'afterMarketactivity'}
              titlePrefix={'After Market'}
              docID={'afterMarketActivitiesWidget'}
              title={'After Market Leads without scheduled activity'}
              refreshTimer={600000}
              delayTimer={6000}

            />
          </div>
        ) : (<Fragment key={'AfterMarketActivitiesWidget'}></Fragment>)}

        {showInboundActivities && this.props.dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true) && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('InboundActivities') ? (
          <div key={'InboundActivities'} id={'InboundActivities'}>
            <InboundActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('InboundActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('InboundActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
              refreshTimer={420000}
              delayTimer={1500}
            />
          </div>
        ) : (<Fragment key={'InboundActivities'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByOrigin') ? (
          <div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
            <OriginCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByOrigin')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsByOrigin'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByEnquiryType') ? (
          <div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
            <EnquiryTypeCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByEnquiryType')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsByEnquiryType'}></Fragment>)}

        {this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length && !ownerDashboard ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map(a => sortedWidgets
              .filter(a => removeUnAllowedWidgets(a))
              .includes(`SalesFunnel-${a.name}`) ? (
              <div key={`SalesFunnel-${a.name}`} id={`SalesFunnel-${a.name}`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}
                  pipelineEnquiries={pipelineEnquiries}
                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  removeWidget={() => this.props.removeWidget(`SalesFunnel-${a.name}`)}
                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  id={`SalesFunnel-${a.value}`}
                  hideRemoveBtn={staticDashboard}
                />
              </div>
            ) : (
              <Fragment key={`SalesFunnel-${a.name}`}></Fragment>
            ))
        ) : sortedWidgets
          .filter(a => removeUnAllowedWidgets(a))
          .includes('SalesFunnel') ?
          (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel
                {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight(
                  'SalesFunnel',
                  this.props.initialLayout,
                )}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
                enquiryStatus={this.props.enquiryStatus}
                isMclaren={false}
                id={`SalesFunnel`}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (
            <Fragment key={'SalesFunnel'}></Fragment>
          )}

        {this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length && !ownerDashboard ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map(a => sortedWidgets
              .filter(a => removeUnAllowedWidgets(a))
              .includes(`SalesFunnel-${a.name}-OVERALL`) ? (
              <div key={`SalesFunnel-${a.name}-OVERALL`} id={`SalesFunnel-${a.name}-OVERALL`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}
                  pipelineEnquiries={pipelineEnquiries}
                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={{
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment('2017-01-01').startOf('year')._d),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d)
                  }}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  removeWidget={() => this.props.removeWidget(`SalesFunnel-${a.name}-OVERALL`)}
                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  isYTD={true}
                  id={`SalesFunnel-${a.value}-OVERALL`}
                  hideRemoveBtn={staticDashboard}
                />
              </div>
            ) : (
              <Fragment key={`SalesFunnel-${a.name}-OVERALL`}></Fragment>
            ))
        ) : (
          <Fragment key={`SalesFunnel-${'pipeline'}-OVERALL`}></Fragment>
        )}
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByCampaign') ? (
          <div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
            <CampaignCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByCampaign')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsByCampaign'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
          <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
            <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByStatus')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesTarget') ? (
          <div key={'SalesTarget'} id={'SalesTarget'}>
            {
              ownerDashboard ? (
                <SalesTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                  hideRemoveBtn={staticDashboard}
                />
              ) : (
                <AllUsersTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                  reloadData={this.props.reloadData}
                  hideRemoveBtn={staticDashboard}
                />
              )
            }
          </div>
        ) : (<Fragment key={'SalesTarget'}></Fragment>)}

        {
          (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.accessDealertarget && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DealerTarget')) ? (
            <div key={'DealerTarget'} id={'DealerTarget'}>
              <DealerTarget {...this.props}
                pipelineEnquiries={pipelineEnquiries}
                allActivities={allActivities}
                testdrives={testdrives}
                tradeins={tradeins}
                contacts={contacts}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                removeWidget={() => this.props.removeWidget('DealerTarget')}
                height={this.calculateHeight('DealerTarget', this.props.initialLayout)}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (<Fragment key={'DealerTarget'}></Fragment>)
        }

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('ActivitiesWidget') ? (
          <div key={'ActivitiesWidget'} id={'ActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('ActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('ActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              titlePrefix={'Sales'}
              title={'Sales Leads without scheduled activity'}
              docID={'salesActivitiesWidget'}
              refreshTimer={450000}
              delayTimer={2000}
            />
          </div>
        ) : (<Fragment key={'ActivitiesWidget'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FleetCounts') ? (
          <div key={'FleetCounts'} id={'FleetCounts'}>
            <FleetCounts {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FleetCounts', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FleetCounts')}
              hideRemoveBtn={staticDashboard}
              refreshTimer={500000}
              delayTimer={2000}
            />
          </div>
        ) : (<Fragment key={'FleetCounts'}></Fragment>)}

        {enableLoanBooking && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LoanBookingsWidget') ? (
          <div key={'LoanBookingsWidget'} id={'LoanBookingsWidget'}>
            <LoanBookingsWidget {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('LoanBookingsWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('LoanBookingsWidget')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'LoanBookingsWidget'}></Fragment>)}

        {showInboundActivities && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('InboundActivitiesWidget') ? (
          <div key={'InboundActivitiesWidget'} id={'InboundActivitiesWidget'}>
            <InboundActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('InboundActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('InboundActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'InboundActivitiesWidget'}></Fragment>)}
        {
          performanceReviewEnabled && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('PerformanceReviewWidget') ? (
            <div key={'PerformanceReviewWidget'} id={'PerformanceReviewWidget'}>
              <PerformanceReview {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                handleShowActivityList={handleShowActivityList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                clientLostApproval={clientLostApproval}
                managerLostApproval={managerLostApproval}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('PerformanceReviewWidget', this.props.initialLayout)}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                refreshData={this.props.refreshData}
                removeWidget={() => this.props.removeWidget('PerformanceReviewWidget')}
                hideRemoveBtn={staticDashboard}
              />
            </div>
          ) : (<Fragment key={'PerformanceReviewWidget'}></Fragment>)
        }
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DynamicFieldsWidget') ? (
          <div key={'DynamicFieldsWidget'} id={'DynamicFieldsWidget'}>
            <DynamicFieldsWidget {...this.props}
              removeWidget={() => this.props.removeWidget('DynamicFieldsWidget')}
              height={this.calculateHeight('DynamicFieldsWidget', this.props.initialLayout)}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'DynamicFieldsWidget'}></Fragment>)}

        {accessToViewFinanceCreditScore && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FinanceCreditScore') ? (
          <div key={'FinanceCreditScore'} id={'FinanceCreditScore'}>
            <FinanceCreditScoreWidget {...this.props}
              removeWidget={() => this.props.removeWidget('FinanceCreditScore')}
              height={this.calculateHeight('FinanceCreditScore', this.props.initialLayout)}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'FinanceCreditScore'}></Fragment>)}

      </ResponsiveReactGridLayout>
    ) : (
      <ResponsiveReactGridLayout
        {...this.props}
        layouts={this.filterLayouts(this.state.layouts)}
        onBreakpointChange={this.onBreakpointChange}
        onLayoutChange={this.onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={this.state.mounted}
        compactType={this.state.compactType}
        preventCollision={!this.state.compactType}
        margin={[15, 15]}
        isResizable={true}
        draggableHandle='.draggable-section'
        draggableCancel='.dashboard-box-body'
      >
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByLabels') ? (
          <div key={'LeadsByLabels'} id={'LeadsByLabels'}>
            <LabelCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByLabels', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByLabels')}
            />
          </div>
        ) : (<Fragment key={'LeadsByLabels'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
          <div key={'Activities'} id={'Activities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('Activities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('Activities')}
              titlePrefix={'Sales'}
              docID={'salesActivities'}
              refreshTimer={420000}
              delayTimer={1000}
            />
          </div>
        ) : (<Fragment key={'Activities'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByOrigin') ? (
          <div key={'LeadsByOrigin'} id={'LeadsByOrigin'}>
            <OriginCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByOrigin', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByOrigin')}
            />
          </div>
        ) : (<Fragment key={'LeadsByOrigin'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByEnquiryType') ? (
          <div key={'LeadsByEnquiryType'} id={'LeadsByEnquiryType'}>
            <EnquiryTypeCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByEnquiryType', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByEnquiryType')}
            />
          </div>
        ) : (<Fragment key={'LeadsByEnquiryType'}></Fragment>)}

        {this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length && !ownerDashboard ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map(a => sortedWidgets
              .filter(a => removeUnAllowedWidgets(a))
              .includes(`SalesFunnel-${a.name}`) ? (
              <div key={`SalesFunnel-${a.name}`} id={`SalesFunnel-${a.name}`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}
                  pipelineEnquiries={pipelineEnquiries}
                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  removeWidget={() => this.props.removeWidget(`SalesFunnel-${a.name}`)}
                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  id={`SalesFunnel-${a.value}`}
                />
              </div>
            ) : (
              <Fragment key={`SalesFunnel-${a.name}`}></Fragment>
            ))
        ) : sortedWidgets
          .filter(a => removeUnAllowedWidgets(a))
          .includes('SalesFunnel') ?
          (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel
                {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight(
                  'SalesFunnel',
                  this.props.initialLayout,
                )}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
                enquiryStatus={this.props.enquiryStatus}
                isMclaren={false}
                id={`SalesFunnel`}
              />
            </div>
          ) : (
            <Fragment key={'SalesFunnel'}></Fragment>
          )}

        {this.props.isMclaren &&
          this.props.pipeline.filter(a => a.value !== 'LeadsBucket').length && !ownerDashboard ? (
          this.props.pipeline
            .filter(a => a.value !== 'LeadsBucket')
            .map(a => sortedWidgets
              .filter(a => removeUnAllowedWidgets(a))
              .includes(`SalesFunnel-${a.name}-OVERALL`) ? (
              <div key={`SalesFunnel-${a.name}-OVERALL`} id={`SalesFunnel-${a.name}-OVERALL`}>
                <SalesFunnel
                  {...this.props}
                  handleShowEnquiryList={handleShowEnquiryList}
                  pipelineEnquiries={pipelineEnquiries}
                  selectedPipeline={{
                    value: a.value,
                    label: a.name,
                  }}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={{
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment('2017-01-01').startOf('year')._d),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d)
                  }}
                  height={this.calculateHeight(
                    'SalesFunnel',
                    this.props.initialLayout,
                  )}
                  reloadData={this.props.reloadData}
                  ownerDashboard={ownerDashboard}
                  removeWidget={() => this.props.removeWidget(`SalesFunnel-${a.name}-OVERALL`)}
                  enquiryStatus={this.props.enquiryStatus}
                  isMclaren={this.props.isMclaren}
                  isYTD={true}
                  id={`SalesFunnel-${a.value}-OVERALL`}
                />
              </div>
            ) : (
              <Fragment key={`SalesFunnel-${a.name}-OVERALL`}></Fragment>
            ))
        ) : (
          <Fragment key={`SalesFunnel-${'pipeline'}-OVERALL`}></Fragment>
        )}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByCampaign') ? (
          <div key={'LeadsByCampaign'} id={'LeadsByCampaign'}>
            <CampaignCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              height={this.calculateHeight('LeadsByCampaign', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByCampaign')}
            />
          </div>
        ) : (<Fragment key={'LeadsByCampaign'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
          <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
            <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              loader={loader}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              removeWidget={() => this.props.removeWidget('LeadsByStatus')}
            />
          </div>
        ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesTarget') ? (
          <div key={'SalesTarget'} id={'SalesTarget'}>
            {
              ownerDashboard ? (
                <SalesTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                />
              ) : (
                <AllUsersTarget {...this.props}
                  pipelineEnquiries={pipelineEnquiries}
                  allActivities={allActivities}
                  testdrives={testdrives}
                  tradeins={tradeins}
                  contacts={contacts}
                  selectedUser={selectedUser}
                  loader={loader}
                  dateRange={dateRange}
                  applydateRange={applydateRange}
                  height={this.calculateHeight('SalesTarget', this.props.initialLayout)}
                  reloadData={this.props.reloadData}
                  removeWidget={() => this.props.removeWidget('SalesTarget')}
                />
              )
            }
          </div>
        ) : (<Fragment key={'SalesTarget'}></Fragment>)}

        {
          (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.accessDealertarget && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DealerTarget')) ? (
            <div key={'DealerTarget'} id={'DealerTarget'}>
              <DealerTarget {...this.props}
                pipelineEnquiries={pipelineEnquiries}
                allActivities={allActivities}
                testdrives={testdrives}
                tradeins={tradeins}
                contacts={contacts}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('DealerTarget', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('DealerTarget')}
              />
            </div>
          ) : (<Fragment key={'DealerTarget'}></Fragment>)
        }

        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('ActivitiesWidget') ? (
          <div key={'ActivitiesWidget'} id={'ActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('ActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('ActivitiesWidget')}
              titlePrefix={'Sales'}
              title={'Sales Leads without scheduled activity'}
              docID={'salesActivitiesWidget'}
              refreshTimer={450000}
              delayTimer={2000}
            />
          </div>
        ) : (<Fragment key={'ActivitiesWidget'}></Fragment>)}


        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FleetCounts') ? (
          <div key={'FleetCounts'} id={'FleetCounts'}>
            <FleetCounts {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FleetCounts', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FleetCounts')}
            />
          </div>
        ) : (<Fragment key={'FleetCounts'}></Fragment>)}

        {enableLoanBooking && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LoanBookingsWidget') ? (
          <div key={'LoanBookingsWidget'} id={'LoanBookingsWidget'}>
            <LoanBookingsWidget {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('LoanBookingsWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('LoanBookingsWidget')}
            />
          </div>
        ) : (<Fragment key={'LoanBookingsWidget'}></Fragment>)}
        {
          performanceReviewEnabled && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('PerformanceReviewWidget') ? (
            <div key={'PerformanceReviewWidget'} id={'PerformanceReviewWidget'}>
              <PerformanceReview {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                handleShowActivityList={handleShowActivityList}
                pipelineEnquiries={pipelineEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                clientLostApproval={clientLostApproval}
                managerLostApproval={managerLostApproval}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('PerformanceReviewWidget', this.props.initialLayout)}
                reloadData={this.props.reloadData}
                ownerDashboard={ownerDashboard}
                refreshData={this.props.refreshData}
                removeWidget={() => this.props.removeWidget('PerformanceReviewWidget')}
              />
            </div>
          ) : (<Fragment key={'PerformanceReviewWidget'}></Fragment>)
        }

        {accessToFinanceActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FinanceActivities') ? (
          <div key={'FinanceActivities'} id={'FinanceActivities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('FinanceActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FinanceActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
              activityType={'financeactivity'}
              originType={'finance'}
              titlePrefix={'Finance'}
              docID={'financeActivities'}
              refreshTimer={480000}
              delayTimer={3000}


            />
          </div>
        ) : (<Fragment key={'FinanceActivities'}></Fragment>)}

        {accessToFinanceActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('FinanceActivitiesWidget') ? (
          <div key={'FinanceActivitiesWidget'} id={'FinanceActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('FinanceActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('FinanceActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              activityType={'financeactivity'}
              titlePrefix={'Finance'}
              docID={'financeActivitiesWidget'}
              title={'Finance Leads without scheduled activity'}
              refreshTimer={510000}
              delayTimer={4000}
            />
          </div>
        ) : (<Fragment key={'FinanceActivitiesWidget'}></Fragment>)}

        {accessToAfterMarketActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('AfterMarketActivities') ? (
          <div key={'AfterMarketActivities'} id={'AfterMarketActivities'}>
            <ActivitiesCount {...this.props}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              height={this.calculateHeight('AfterMarketActivities', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('AfterMarketActivities')}
              roleIDs={this.props.dealersettings.client.roles
                .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                .map(r => { return r.documentID })
                .join(',')}
              hideRemoveBtn={staticDashboard}
              activityType={'afterMarketactivity'}
              originType={'afterMarket'}
              titlePrefix={'After Market'}
              docID={'afterMarketActivities'}
              refreshTimer={540000}
              delayTimer={5000}

            />
          </div>
        ) : (<Fragment key={'AfterMarketActivities'}></Fragment>)}

        {accessToAfterMarketActivity && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('AfterMarketActivitiesWidget') ? (
          <div key={'AfterMarketActivitiesWidget'} id={'AfterMarketActivitiesWidget'}>
            <ActivitiesWidget {...this.props}
              handleShowEnquiryList={handleShowEnquiryList}
              handleShowActivityList={handleShowActivityList}
              pipelineEnquiries={pipelineEnquiries}
              selectedPipeline={selectedPipeline}
              selectedUser={selectedUser}
              clientLostApproval={clientLostApproval}
              managerLostApproval={managerLostApproval}
              dateRange={dateRange}
              applydateRange={applydateRange}
              height={this.calculateHeight('AfterMarketActivitiesWidget', this.props.initialLayout)}
              reloadData={this.props.reloadData}
              ownerDashboard={ownerDashboard}
              refreshData={this.props.refreshData}
              removeWidget={() => this.props.removeWidget('AfterMarketActivitiesWidget')}
              hideRemoveBtn={staticDashboard}
              activityType={'afterMarketactivity'}
              titlePrefix={'After Market'}
              docID={'afterMarketActivitiesWidget'}
              title={'After Market Leads without scheduled activity'}
              refreshTimer={600000}
              delayTimer={6000}
            />
          </div>
        ) : (<Fragment key={'AfterMarketActivitiesWidget'}></Fragment>)}
        {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('DynamicFieldsWidget') ? (
          <div key={'DynamicFieldsWidget'} id={'DynamicFieldsWidget'}>
            <DynamicFieldsWidget {...this.props}
              removeWidget={() => this.props.removeWidget('DynamicFieldsWidget')}
              height={this.calculateHeight('DynamicFieldsWidget', this.props.initialLayout)}
              hideRemoveBtn={staticDashboard}
            />
          </div>
        ) : (<Fragment key={'DynamicFieldsWidget'}></Fragment>)}

      </ResponsiveReactGridLayout>
    );
  }
}

DragDropLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

