import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { lookupDFVM, clientLookups, levelOptions, fieldTypes, dmsKeys } from './viewModel'
import CommonHelper from '../../../../services/common';

const AddDynamicOptions = (props) => {

    const [lookup, setLookup] = useState(props.lookup)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newLookup = !_.isEmpty(props.lookup) ? false : true
    const [addSubList, setAddSubList] = useState()
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.subList) ? props.lookup.subList : []);
    const [textEditor, setTexteditor] = useState({})
    const disableEdit = (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
        (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false
    const [oldPipeline, setoldPipeline] = useState(props?.lookup?.showinPipeline || []);


    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupDFVM);
            if (!_.isEmpty(props.selectedLevel)) {
                newlookupVM.level = props.selectedLevel.level;
                newlookupVM.levelID = props.selectedLevel.levelID;
            }
            setLookup(newlookupVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handlecolorSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e ? e.value : ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.selectedOEM.id] : '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs,
                ['subList']: (data.name === 'type' && (e.value === 'select' || e.value === 'multiselect')) ? subList : []
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name, removeOption) => {
        let value = [];
        let _oldPipeline = oldPipeline;
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        if (name === 'showinPipeline' && removeOption.action === 'remove-value') {
            _oldPipeline = oldPipeline.filter(a => a !== removeOption?.removedValue?.value);
        }
        if (name === 'showinPipeline' && removeOption.action === 'clear') {
            const allPipeline = props?.pipelines?.map(r => { return r.value }) || [];
            _oldPipeline = oldPipeline.filter(a => !allPipeline.includes(a));
        }
        if (name === 'showinPipeline') {
            value = _.uniq([...value, ..._oldPipeline])
        }
        setoldPipeline(_oldPipeline)
        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }

    const saveLookup = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let activeLookupName = (_.find(clientLookups, { 'value': props.activeLookup }) ? _.find(clientLookups, { 'value': props.activeLookup }).name : 'Lookups')
        if (_.isEmpty(lookup['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(lookup['value'])) {
            formIsValid = false;
            errors['value'] = errorClass;
        }
        if (_.isEmpty(lookup['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (_.isEmpty(lookup['type'])) {
            formIsValid = false;
            errors['type'] = errorClass;
        }
        if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.name.toLowerCase() === lookup.name.toLowerCase() && lookup.levelID.some(a => item.levelID === a))) {
            Swal.fire(`${activeLookupName} ${CommonHelper.showLocale(props, 'with same name already exists')}`, '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)

        const batch = window.firebase.firestore().batch();
        if (lookup.level === 'oem') {
            const lookupObj = {
                [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level), function (obj) {
                        return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                    })]
            }
            if (Boolean(lookup.static)) {
                delete lookupObj[props.activeLookup];
                lookupObj['enquiryOptionsStatic'] = [..._.map(props.allLookups['enquiryOptionsStatic'].filter(item => item.level === lookup.level && item.value !== lookup.value), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                lookupObj, { merge: true });
        }
        else if (_.isArray(lookup.levelID)) {
            lookup.levelID.forEach(rec => {
                const lookupObj = {
                    [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))] :
                        [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && rec === item.levelID), function (obj) {
                            return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                        })]
                }
                if (Boolean(lookup.static)) {
                    delete lookupObj[props.activeLookup];
                    lookupObj['enquiryOptionsStatic'] = [..._.map(props.allLookups['enquiryOptionsStatic'].filter(item => item.level === lookup.level && item.levelID === rec && item.value !== lookup.value), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                    lookupObj, { merge: true });
            })
        }
        else {
            const lookupObj = {
                [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookup.level && item.levelID === lookup.levelID), function (obj) {
                        return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupDFVM)) } : { ..._.pick(obj, _.keys(lookupDFVM)) };
                    })]
            }
            if (Boolean(lookup.static)) {
                delete lookupObj[props.activeLookup];
                lookupObj['enquiryOptionsStatic'] = [..._.map(props.allLookups['enquiryOptionsStatic'].filter(item => item.level === lookup.level && item.levelID === lookup.levelID && item.value !== lookup.value), function (obj) { return { ..._.pick(obj, _.keys(lookupDFVM)) }; }), _.pick(lookup, _.keys(lookupDFVM))]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${lookup.levelID}`),
                lookupObj, { merge: true });
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Dynamic Field Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Dynamic Field Settings has been updated',
                        }, { merge: true })
                }
                //end 
                toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookup, newLookup)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setAddSubList(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setAddSubList(str);
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }

            setEditSubList(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditSubList(str);
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleSublistSave = (rec, newlist, index) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec)) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        setErrorFields(errors)
        if (newlist && subList.some(item => item === rec)) {
            Swal.fire(CommonHelper.showLocale(props, 'Options with same name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj, i) {
                return i === index ? rec : obj;
            })];
        setSubList(addList)
        setLookup({
            ...lookup,
            ['subList']: addList
        });
        setAddSubList()
        setEditSubList(null)
        setTexteditor({})
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec, index) => {
        setEditSubList(rec)
        setTexteditor({
            [index]: true
        })
    }
    const handleDeleteSubList = (rec, index) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [];
                _.map(subList, (e, i) => {
                    return index !== i ? deleteList.push(e) : false
                })
                setSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['subList']: deleteList
                });
            }
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        setSubList(subList)
        setLookup({
            ...lookup,
            ['subList']: subList
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        maxLength={150}
                                        disabled={(newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={(!_.isEmpty(props.selectedLevel) && newLookup) ? props.selectedLevel.level : lookup.level}
                                        classNamePrefix={`${!newLookup ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={(!_.isEmpty(props.selectedLevel) && newLookup) ? props.selectedLevel.level : !newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={handleSelectChange}
                                            //onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            //value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            value={(!_.isEmpty(props.selectedLevel) && newLookup) ? props.selectedLevel.levelID : lookup.levelID}
                                            classNamePrefix={`${!newLookup ? 'select-readonly' : ''}  ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            isDisabled={(!_.isEmpty(props.selectedLevel) && newLookup) ? true : !newLookup}
                                        //isMulti={true}
                                        >
                                        </ReactSelect>
                                    </div>) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Field Type'} /></label>
                                    <ReactSelect
                                        options={fieldTypes}
                                        name={"type"}
                                        placeholder={'select field type'}
                                        onChange={handleSelectChange}
                                        value={lookup.type}
                                        removeClearable={true}
                                        classNamePrefix={`${errorFields["type"]} basic-select`}
                                        isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    props.dealersettings.superAdmin === true && props.activeLookup === 'enquiryOptionsDF' && !Boolean(lookup?.static) ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'DMS Key'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'key'}
                                                className={`form-control ${errorFields["key"]}`}
                                                name="key"
                                                onChange={(e) => {
                                                    setLookup({
                                                        ...lookup,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                value={lookup.key}
                                                maxLength={150}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    !Boolean(lookup?.static) && (lookup.type === 'select' || lookup.type === 'multiselect') ? (
                                        <>
                                            <DragDropContext onDragEnd={onDragEnd}>

                                                <div className="common-table col-md-12">
                                                    <label > <Translate text={'Options'} /></label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="head-light" width="45%"> <Translate text={'name'} /></th>
                                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                            </tr>
                                                            {
                                                                disableEdit ? (<></>) : (
                                                                    <tr>
                                                                        <td><div className="table-input">
                                                                            <InputText
                                                                                id="sublistname"
                                                                                autoComplete="off"
                                                                                placeholder={'name'}
                                                                                className={`form-control ${errorFields["sublistname"]}`}
                                                                                name="name"
                                                                                onChange={handleOnSubChange}
                                                                                value={addSubList}
                                                                                disabled={disableEdit}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        handleSublistSave(addSubList, true)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        </td>
                                                                        <td className="border-right-0">
                                                                            <div className="button-height-fix">
                                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </thead>
                                                        <Droppable droppableId="droppableOptions">
                                                            {(provided, snapshot) => (
                                                                <tbody
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >


                                                                    {
                                                                        subList.length > 0 ?
                                                                            subList.map((rec, index) => {
                                                                                return <Draggable key={`enqOptions-${index}`} draggableId={`enqOptions-${index}`} index={index}>
                                                                                    {(provided1, snapshot1) => (
                                                                                        <tr
                                                                                            ref={provided1.innerRef}
                                                                                            {...provided1.draggableProps}
                                                                                            {...provided1.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot1.isDragging,
                                                                                                provided1.draggableProps.style
                                                                                            )}
                                                                                            onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}>
                                                                                            <td style={{ width: '335px' }}>
                                                                                                {
                                                                                                    textEditor[_.findIndex(subList, (e) => {
                                                                                                        return e == rec;
                                                                                                    }, 0)] ? (<><InputText
                                                                                                        id="editsublistname"
                                                                                                        autoComplete="off"
                                                                                                        placeholder={'name'}
                                                                                                        className={`form-control ${errorFields["editsublistname"]}`}
                                                                                                        name="name"
                                                                                                        onChange={handleOnEditSubChange}
                                                                                                        value={editSubList}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === 'Enter') {
                                                                                                                handleSublistSave(editSubList, false, index)
                                                                                                            }
                                                                                                        }}
                                                                                                    /></>) : (<>{rec}</>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border-right-0">
                                                                                                <div className="action-fix">
                                                                                                    {
                                                                                                        textEditor[_.findIndex(subList, (e) => {
                                                                                                            return e == rec;
                                                                                                        }, 0)] ?
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false, index) }}> <i className="ico icon-check"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec, index) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                            </>) :
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec, index) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                            </>)
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                                            </td>
                                                                                            {provided1.placeholder}
                                                                                        </tr>
                                                                                    )}
                                                                                </Draggable>
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                    {provided.placeholder}
                                                                </tbody>
                                                            )}
                                                        </Droppable>
                                                    </table>
                                                </div>
                                            </DragDropContext>
                                        </>

                                    ) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Show in Pipeline'} /></label>
                                    <ReactMultiSelect
                                        options={props?.pipelines?.map(r => {
                                            return {
                                                label: r.name,
                                                value: r.value
                                            }
                                        }) || []}
                                        name={"showinPipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={(option, remove) => handleReactMultiSelectChange(option, 'showinPipeline', remove)}
                                        value={lookup?.showinPipeline || []}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left ${(newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? '' :
                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddDynamicOptions;