import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import { InputText } from '../../components';
import Translate from '../../constants/translate';
import { CustomToggle, CustomSearchMenu } from '../../components/customdropdown';
import _ from 'lodash'
import { firestoreDB } from '../../services/helper';

const AddRecipient = (props) => {
	const [errorFields, setErrorFields] = useState({})
	const [data, setData] = useState({
		firstName: props.title === 'Edit Recipient' ? props.data.firstName ? props.data.firstName : '' : '',
		middleName: props.title === 'Edit Recipient' ? props.data.middleName ? props.data.middleName : '' : '',
		lastName: props.title === 'Edit Recipient' ? props.data.lastName ? props.data.lastName : '' : '',
		phone: props.title === 'Edit Recipient' ? props.data.phone ? props.data.phone : '' : '',
		phoneCode: props.title === 'Edit Recipient' ? props.data.phoneCode ? props.data.phoneCode : '' : '',
		documentID: props.title === 'Edit Recipient' ? props.data.documentID ? props.data.documentID : '' : '',
		contactID: props.title === 'Edit Recipient' ? props.data.contactID ? props.data.contactID : '' : ''
	})
	const [phoneCodes, setphoneCodes] = useState([])
	const [btnLoader, setBtnLoader] = useState(false)

	useEffect(() => {
		const { dealersettings, data } = props;
		let _phoneCodes = []
		props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
			if (doc.phone_code) {
				_phoneCodes.push({
					country_code: doc.code,
					value: doc.phone_code,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
				});
				setphoneCodes(_phoneCodes)
			}
		});

		if (_.isEmpty(data) && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
			let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
			let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
			if (!_.isEmpty(phone_code)) {
				setData({
					...data,
					['phoneCode']: phone_code.phone_code ? phone_code.phone_code : ''
				});
			}
		}
	}, [])

	const handleOnChange = e => {
		const { name, value } = e.target;

		setData({
			...data,
			[name]: value,
		});
		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const onSave = async e => {
		e.preventDefault()
		let isValid = true;
		let errors = { ...errorFields }
		if (!data.firstName || (data.firstName && data.firstName.trim() === '')) {
			isValid = false;
			errors.firstName = 'input_error'
		}
		if (!data.lastName || (data.lastName && data.lastName.trim() === '')) {
			isValid = false;
			errors.lastName = 'input_error'
		}
		if (!data.phone || (data.phone && data.phone.trim() === '')) {
			isValid = false;
			errors.phone = 'input_error'
		}
		if (!(props.title === 'Edit Recipient' && props.data.phoneCode === '')) {
			if (data.phoneCode === '') {
				isValid = false;
				errors.phoneCode = 'input_error'
			}
		}
		setErrorFields(errors);

		if (isValid) {
			if (_.isEmpty(data.contactID)) {
				setBtnLoader(true);
				data.contactID = await getContact(data);
				console.log('return', data);
				setBtnLoader(false);
			}
			if (props.title === 'Edit Recipient') {
				props.handleClose(data, 'update');
			} else {
				props.handleClose(data, 'add');
			}
		}
	}

	const getContact = async (_data) => {
		let contactDetailsRef = firestoreDB(props.dealersettings).firestore().collection('contactDetails');
		const { dealersettings } = props;
		if (dealersettings && dealersettings.client && dealersettings.client.group && dealersettings.group && dealersettings.group.enableSharedContact)
			contactDetailsRef = contactDetailsRef.where('groupID', '==', dealersettings.client.group)
		else
			contactDetailsRef = contactDetailsRef.where('clientID', '==', (dealersettings.client.id))

		let _contactID = null;
		const phone8digit = data.phone ? data.phone.replace(/\D/g, '').slice(-8) : '';
		const phone = data.phone ? data.phone.replace(/\D/g, '') : '';

		const checkPhone8digit = await contactDetailsRef.where('keywords', 'array-contains', phone8digit).where('isDeleted', '==', false).limit(1).get()
		if (checkPhone8digit.size === 1) {
			checkPhone8digit.forEach(doc => {
				console.log('checkPhone8digit', doc.id)
				_contactID = doc.id;
			});
			return _contactID;
		}
		const checkPhone = await contactDetailsRef.where('keywords', 'array-contains', phone).where('isDeleted', '==', false).limit(1).get()
		if (checkPhone.size === 1) {
			checkPhone.forEach(doc => {
				console.log('checkPhone', doc.id)
				_contactID = doc.id;
			});
			return _contactID;
		}
		return _contactID;
	}

	const onHandleTelephoneChange = e => {
		const { name, value } = e.target;
		var regexp = /^[0-9+ \b]+$/;
		let telephone = value;

		// if value is not blank, then test the regex
		if (telephone === '' || regexp.test(telephone)) {
			let val = _.trim(value);
			setData({ ...data, [name]: val })
			setErrorFields({ ...errorFields, phone: '' })
		}
	};

	const handleAddPhoneCode = (name, value) => {
		let val = value ? value : '';
		setData({ ...data, [name]: val });
		setErrorFields({ ...errorFields, phoneCode: '' })
	};

	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			backdropClassName={props.show ? 'overlay-modal active' : ''}
			dialogClassName='modal-dialog-centered modal-save-filter'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						{' '}
						<Translate text={props.title} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='form-style w-100'>
							<div className='form-row'>
								<div className='form-group col-md-12'>
									<label>First Name</label>
									<InputText
										autoComplete='off'
										className={`form-control ${errorFields['firstName']}`}
										name='firstName'
										onChange={handleOnChange}
										value={data.firstName}
										placeholder='first name'
									/>
								</div>
								<div className="form-group col-md-12">
									<label >Middle Name</label>
									<InputText
										autoComplete="off"
										className={`form-control ${errorFields["middleName"]}`}
										name="middleName"
										onChange={handleOnChange}
										value={data.middleName}
										placeholder='middle name'
									/>
								</div>
								<div className='form-group col-md-12'>
									<label>Last Name</label>
									<InputText
										autoComplete='off'
										className={`form-control ${errorFields['lastName']}`}
										name='lastName'
										onChange={handleOnChange}
										value={data.lastName}
										placeholder='last name'
									/>
								</div>
								<div className='form-group col-md-12'>
									<label>Phone</label>
									<div
										className={`input-group country-code ${(errorFields['phone'] || errorFields['phoneCode']) ? 'phone_error' : ''
											}`}
									>
										<div className='input-group-prepend'>
											<Dropdown className={`btn btn-outline-secondary`}>
												<Dropdown.Toggle as={CustomToggle}>
													<span id='spn-code-name' data-name={data.phoneCode}>
														{data.phoneCode &&
															phoneCodes.find(item => item.value === data.phoneCode)
															? phoneCodes.find(
																item => item.value === data.phoneCode,
															).value + ' '
															: 'Code'}
													</span>
												</Dropdown.Toggle>

												<Dropdown.Menu
													as={CustomSearchMenu}
													className='rm-pointers dropdown-menu-lg dropdown-menu-right'
													ChildClass='pipeline-dropdown'
													xplacement='bottom-end'
												>
													{!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
														phoneCodes.map((rec, index) => {
															return (
																<Dropdown.Item
																	key={index}
																	className={`current-pipeline ${data.phoneCode && rec.value === data.phoneCode
																		? 'active'
																		: ''
																		}`}
																	onClick={e => {
																		e.preventDefault();
																		handleAddPhoneCode(
																			'phoneCode',
																			rec.value,
																		);
																	}}
																>
																	{rec.label}
																</Dropdown.Item>
															);
														})
													) : (
														<></>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<input
											type='text'
											autoComplete='off'
											placeholder={'phone'}
											pattern='[0-9]*'
											className={`form-control `}
											name='phone'
											onChange={onHandleTelephoneChange}
											value={data.phone}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className={`btn btn-primary float-right`}
					onClick={onSave}
				>
					{btnLoader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
					<Translate
						text={props.title === 'Edit Recipient' ? 'Save' : 'Add'}
					/>
				</button>
				<button
					type='button'
					className='btn btn-default float-left'
					onClick={() => {
						props.handleClose();
					}}
				>
					<Translate text={'cancel'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default AddRecipient;