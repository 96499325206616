import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import BeePluginEditor from './beepluginEditor'
import { mentions, templateVM } from './viewModel'
import BASE_TEMPLATE from './blankTemplate.json';
import Addtemplate from './addTemplate'
import { PopUpModal } from '../../components';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import SendTestEmail from '../settings/workflow/templates/sendTestEmail';
import { specialLinks } from './viewModel'
import { userLevel } from '../../services/enum'

const beeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}

const TemplateEditor = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [showEditModel, setShowEditModel] = useState({ show: false })
    const [popHeader, setPopHeader] = useState('')
    const newTemplate = !_.isEmpty(props.template) ? false : true
    const [sendEmailModel, setSendEmailModel] = useState(false)


    useEffect(() => {
        if (_.isEmpty(props.template)) {
            let newtemplateVM = Object.assign({}, templateVM);
            newtemplateVM.documentID = window.firebase.firestore().collection(`eventsProTemplates`).doc().id;
            //newtemplateVM.clientID = props.dealersettings.client.id;
            newtemplateVM.addedBy = localStorage.uid;
            newtemplateVM.modifiedBy = localStorage.uid;
            newtemplateVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.templateJSON = BASE_TEMPLATE
            newtemplateVM.visibility = 'private'
            if (props.dealersettings.level === userLevel.INDIVIDUAL) {
				newtemplateVM.clientID = props.dealersettings.client.id;
				newtemplateVM.clientIDs = [props.dealersettings.client.id];
				newtemplateVM.level = userLevel.INDIVIDUAL;
			}
            newtemplateVM.projectId = props?.dealersettings?.client?.projectId ? props.dealersettings.client.projectId : null;
            setTemplate(newtemplateVM)
        }
    }, [])

    const handleModelClose = (template) => {
        setShowEditModel({ show: false })
        setSendEmailModel(false)
        if (!_.isEmpty(template)) {
            if (props.returnAfterCreation) {
                props.handleClose(template)
            } else {
                props.handleClose()
            }
        }
    }

    const save = (filename, content) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: 'text/plain;charset=utf-8' });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    };

    return _.isEmpty(template) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen">
                            <main role="main" className="container">
                                <BeePluginEditor
                                    {...props}
                                    className="containerClassName"
                                    template={newTemplate ? BASE_TEMPLATE : JSON.parse(template.templateJSON)}
                                    style={beeStyles}
                                    onSave={(jsonFile, htmlFile) => {
                                        var _contentEmail = htmlFile;
                                        var _contentJson = jsonFile;
                                        let _dynamicFields = [];
                                        // if (props.dynamicOptions) {
                                        //     props.dynamicOptions.forEach(rec => {
                                        //         if (_contentEmail && _contentEmail.includes(`@${rec.name.replace(/ /g, '_')}`)) {
                                        //             _dynamicFields.push(`${rec.value.replace(/@/g, '')}`);
                                        //             _contentEmail = _contentEmail.replace(new RegExp(`@${rec.name.replace(/ /g, '_')}`, 'g'), `@${rec.value}`);
                                        //         }
                                        //         if (_contentJson && _contentJson.includes(`@${rec.name.replace(/ /g, '_')}`)) {
                                        //             _contentJson = _contentJson.replace(new RegExp(`@${rec.name.replace(/ /g, '_')}`, 'g'), `@${rec.value}`);
                                        //         }
                                        //     })
                                        // } 
                                        setShowEditModel({
                                            show: true,
                                            title: newTemplate ? 'Add Template' : 'Edit template',
                                            template: {
                                                ...template,
                                                ['dynamicFields']: _dynamicFields,
                                                ['bodyHTML']: _contentEmail,
                                                ['templateJSON']: _contentJson
                                            }
                                        })
                                    }}
                                    onSend={htmlFile => {
                                        setTemplate({
                                            ...template,
                                            ['bodyHTML']: htmlFile
                                        })
                                        setPopHeader('Send Test Email');
                                        setSendEmailModel(true)
                                    }}
                                    onSaveAsTemplate={jsonFile => { save('email-template.json', jsonFile) }}
                                    onError={errorMessage => console.log(errorMessage)}
                                    specialLinks={specialLinks}
                                />
                            </main>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <PopUpModal show={showEditModel.show}>
                <Addtemplate
                    {...props}
                    {...showEditModel}
                    handleClose={handleModelClose}
                    newTemplate={newTemplate}
                />
            </PopUpModal>
            <PopUpModal show={sendEmailModel}>
                <SendTestEmail
                    {...props}
                    show={sendEmailModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    template={template}
                    selectedClient={template?.clientIDs?.[0] || props.dealersettings.client.id}
                    mentions={mentions}
                />
            </PopUpModal>
        </>
    )
}

export default TemplateEditor