/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash'
//import toast from 'toasted-notes';
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc, collection } from 'rxfire/firestore';
/** COMPONENTS */
import { InputRadio, SidePanel } from '../../../components';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
import { default as _images } from '../../../images';
import { StageProvider, StageConsumer } from '../provider'
import QuickView from "../quickview";
/** VIEW-MODELS */
import { objEnquiry, enquiryBasicTDVM } from '../viewModel'
import { enquiryStatus, stockStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';

//#region  ASSIGN VARIABLES

// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]

const __financestatus = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]

/*const enquiryStatuses = [
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'closeWon', label: <div className={`badge badge-pill badge-status-won`}>CLOSE WON</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> },
    { active: true, value: 'pendingLost', label: <div className={`badge badge-pill badge-status-pendinglost`}>PENDING APPROVAL</div> },
    { active: true, value: 'prospectLost', label: <div className={`badge badge-pill badge-status-lost`}>PROSPECT LOST</div> }
]*/

let enquiryMainFields = ["displayID", "status", "owner", "ownerInbound", "isFav"];
let enquirySortOptions = [
    "displayID", "status", "owner", "ownerInbound", "isFav",
    "enquiryType", "origin", "campaign", "label", "purchaseIntention", "deliveryDate",
    "chassisNo", "regNo", "stockNo", "warrantyStartDate", "warrantyExpiry", "lostReason",
    "lostSubReason", "isFinanceRequired", "financeStatus", "depositAmount", "depositReciptNumber", "grossAmount",
    "contractNumber", "leadSource"
];
let financeStatuses = [];
//#endregion

const MergeEnquiry = (props) => {
    const [selected, setSelected] = useState({})
    const [selectedDynamic, setSelectedDynamic] = useState({})
    const [viewableKeys, setviewableKeys] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [enquiryOptionsDF, setEnquiryOptionsDF] = useState([])

    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [pipelines, setPipelines] = useState([])
    const [stages, setStages] = useState([])
    const [enquiries, setMergeEnquires] = useState([])
    const [enqRequirements, setMergeEnqRequirements] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [isPageLoading, setPageLoading] = useState(true)

    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview-pop' })



    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const viewOtherEnquiry = (props.dealersettings?.rolePermissions?.permissions?.viewOtherEnquiry ? true : false);

    useEffect(() => {
        const { dealersettings } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;

            const _lostReasons = [];
            const _lostSubReasons = [];
            const _pipelines = [];
            const _stages = [];

            const _moduleSettings = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

            const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
                !_.isEmpty(dealersettings.client.moduleSettings.finance) &&
                dealersettings.client.moduleSettings.finance.enabled) ? true : false);

            financeStatuses = [];
            !financeEnabled && setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: docsub.name
                    });
                });
            });

            dealersettings.allPipelines && dealersettings.allPipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });

                !_.isEmpty(doc.stages) && doc.stages.forEach(docsub => {
                    _stages.push({
                        value: docsub.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: docsub.name
                    });
                });
            });

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            setAllStatus(_enquiryAllStatus);
            setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            setPipelines(_pipelines);
            setStages(_stages);
        }

    }, [])

    useEffect(() => {
        const { enqIDA, enqIDB, dealersettings } = props;

        if (_.isEmpty(enqIDA) || _.isEmpty(enqIDB))
            return;

        let _viewableKeys = []
        let _selected = {}
        let _selectedDymanic = {}


        var enqOptions = combineLatest(
            doc(firestoreDB(dealersettings).firestore().doc(`enquiries/${enqIDA}`)),
            doc(firestoreDB(dealersettings).firestore().doc(`enquiries/${enqIDB}`)),
            collection(firestoreDB(dealersettings).firestore().collection(`enquiries/${enqIDA}/requirements`)),
            collection(firestoreDB(dealersettings).firestore().collection(`enquiries/${enqIDB}/requirements`)),
        )
            .pipe(
                map((options) => {
                    // Destructure the values to combine a single array.
                    var [enqA, enqB, enqAreq, enqBreq] = options;
                    return [
                        //spread the arrays out to combine as one array 
                        enqA.exists ? { ...enqA.data(), documentID: enqA.id } : {},
                        enqB.exists ? { ...enqB.data(), documentID: enqB.id } : {},
                        _.map(enqAreq, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                        _.map(enqBreq, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    ];
                }))
            .subscribe(allDocs => {
                //console.log('enqOptions', allDocs)
                //console.log('enqReqOptions', [_.map(allDocs[2]), _.map(allDocs[3])])
                setPageLoading(false);
                let enquiryA = allDocs[0];
                let enquiryB = allDocs[1];

                //console.log('enquiryA', enquiryA)
                //console.log('enquiryB', enquiryB)

                const _enquiryOptionsDF = dealersettings.client.settings.enquiryOptionsDF;
                let dynamicEnquiryDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.enquiryOptions))
                    ? _.union(enquiryMainFields, dealersettings.client.enquiryOptions) :
                    Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(_enquiryOptionsDF) && !_.isEmpty(_enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(_enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));
                const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])

                dynamicEnquiryDetailList && Object.values(dynamicEnquiryDetailList).forEach(key => {

                    if (objEnquiry.hasOwnProperty(key)) {
                        let hasValue1 = enquiryA[key] && (enquiryA[key] || enquiryA[key] === true) ? true : false;
                        let hasValue2 = enquiryB[key] && (enquiryB[key] || enquiryB[key] === true) ? true : false;

                        if (hasValue1 || hasValue2) {
                            _viewableKeys.push(key);
                        }

                        if (key === 'status') {
                            const isPrimaryStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, enquiryA[key])
                            const isSecondaryStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, enquiryB[key])
                            const isSecondaryStatusDeliverd = enquiryB[key] === enquiryStatus.DELIEVERD ? true : false;
                            if (isPrimaryStatusOpen && (isSecondaryStatusSold || isSecondaryStatusDeliverd))
                                _selected[key] = 1
                            else
                                _selected[key] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
                        }
                        else if (key === 'owner')
                            _selected[key] = hasValue2 ? 1 : 0;
                        else
                            _selected[key] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
                    }
                    else if (!_.isEmpty(_enquiryOptionsDF) && !_.isEmpty(_enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) {

                        let hasValue1 = enquiryA.dynamicFields && enquiryA.dynamicFields[key] && (enquiryA.dynamicFields[key] || enquiryA.dynamicFields[key] === true) ? true : false;
                        let hasValue2 = enquiryB.dynamicFields && enquiryB.dynamicFields[key] && (enquiryB.dynamicFields[key] || enquiryB.dynamicFields[key] === true) ? true : false;

                        if (hasValue1 || hasValue2) {
                            _viewableKeys.push(key);
                        }
                        _selectedDymanic[key] = hasValue1 ? 0 : hasValue2 ? 1 : 0;
                    }

                });

                //console.log('dynamicEnquiryDetailList', dynamicEnquiryDetailList)
                //console.log('_enquiryOptionsDF', _enquiryOptionsDF)
                //console.log('_viewableKeys', _viewableKeys)

                setEnquiryOptionsDF(_enquiryOptionsDF)
                setSelected(_selected);
                setSelectedDynamic(_selectedDymanic);
                setviewableKeys(_viewableKeys);
                setMergeEnquires(allDocs);
                setMergeEnqRequirements([_.map(allDocs[2]), _.map(allDocs[3])]);

                enqOptions && enqOptions.unsubscribe();
            });

    }, [])

    const convertContactVM = (doc) => {
        const enquiry = Object.assign({}, doc);

        let dealersettings = props.dealersettings;
        let _setttings = enquiry.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[enquiry.clientID] && dealersettings.group.clients[enquiry.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        if (_setttings) {
            enquiry.campaign = CommonHelper.getNameByValue(_setttings.campaigns, enquiry.campaign, '');
            enquiry.origin = CommonHelper.getNameByValue(_setttings.origins, enquiry.origin, '');
            enquiry.enquiryType = CommonHelper.getNameByValue(_setttings.enquiryTypes, enquiry.enquiryType, '');
            enquiry.serviceType = CommonHelper.getNameByValue(_setttings.serviceTypes, enquiry.serviceType, '');
            enquiry.leadSource = CommonHelper.getNameByValue(_setttings.leadSource, enquiry.leadSource, '');
            enquiry.label = CommonHelper.bindEnquiryLabel(_setttings.enquiryLabels, enquiry.label);
            enquiry.lostReason = CommonHelper.getNameByValue(_setttings.lostReasons, enquiry.lostReason, '');
            //enquiry.lostSubReason = CommonHelper.getNameByValue(lostSubReasons, enquiry.lostSubReason, '');
        }

        return enquiry
    }

    const handleCheckChange = e => {
        const { name, value } = e.target;
        //console.log('handleCheckChange', name, value)
        let state = Object.assign({}, selected);
        state[name] = parseInt(value);
        if (name === "displayID") state["status"] = parseInt(value);
        setSelected(state);
    };

    const showDataView = (key, value, id) => {

        if (value || value === false) {
            if (key === 'displayID') {
                return <><div style={{ cursor: 'pointer' }} onClick={(e) => { sidepanelOpen(id) }}>
                    <i className="ico icon-enquiry mr-1"></i>{value ? value : '--'}
                </div></>
            }
            else if (key === 'status') {
                return <>{CommonHelper.getLabelByValue(enquiryAllStatus, value, '--')}</>
            }
            else if (key === 'pipeline') {
                return <>{CommonHelper.getNameByValue(props.dealersettings.allPipelines, value, '--')}</>
            }
            else if (key === 'lostSubReason') {
                return <>{CommonHelper.getLabelByValue(lostSubReasons, value, '--')}</>
            }
            else if (key === 'financeStatus') {
                return <>{CommonHelper.getLabelByValue(!_.isEmpty(financeStatuses) ? financeStatuses : __financestatus, value, '--')}</>
            }
            else if (key === 'purchaseIntention') {
                return <>{value ? moment(value).format('MMM YYYY') : '--'}</>
            }
            else if (key === 'warrantyStartDate' || key === 'warrantyExpiry') {
                return <>{value ? moment(value).format('DD MMM YYYY') : '--'}</>
            }
            else if (key === 'deliveryDate' || key === 'stageDate' || key === 'wonDate' || key === 'lostDate' ||
                key === 'deliveredDate' || key === 'confirmedDate' || key === 'verifiedDate' || key === 'receivedDate' ||
                key === 'convertedDate' || key === 'convertedDateGroup' || key === 'convertedDateOEM' || key === 'convertedDateRegion' || key === 'oemConvertedDate') {
                return <>{value ? moment.unix(value.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
            }
            else if (key === 'owner' || key === 'confirmedBy' || key === 'verifiedBy' || key === 'ownerInbound' ||
                key === 'convertedBy' || key === 'convertedByGroup' || key === 'convertedByOEM' || key === 'convertedByRegion' || key === 'oemConvertedBy') {
                return <>{CommonHelper.getUserNamebyId(allUsers, value, '--')}</>
            }
            else if (key === 'depositAmount' || key === 'grossAmount') {
                return <>{value ? <NumberFormat value={value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</>
            }
            else if (key === 'isFav' || key === 'isFinanceRequired' || key === 'isFinanceRequestSent') {
                return <>{value ? 'Yes' : 'No'}</>
            }
            else {
                return <>{value ? value : '--'}</>
            }
        }
        else {
            return <>{'--'}</>
        }
    }

    const handleDynamicCheckChange = e => {
        const { name, value } = e.target;
        //console.log('handleCheckChange', name, value)
        let state = Object.assign({}, selectedDynamic);
        state[name] = parseInt(value);
        setSelectedDynamic(state);
    };

    const showDynamicDataView = (key, value, data) => {

        if (value || value === false) {
            if (data.type === 'text' || data.type === 'select') {
                return <>{value ? value : '--'}</>
            }
            else if (data.type === 'number') {
                return <>{_.isNumber(value) ? value : '--'}</>
            }
            else if (data.type === 'multiselect') {
                return <>{!_.isEmpty(value) ? value.join(', ') : '--'}</>
            }
            else if (data.type === 'date') {
                return <>{!_.isEmpty(value) ? moment.unix(value.seconds).format('DD MMM YYYY') : '--'}</>
            }
            else if (data.type === 'price') {
                return <>{value ? <NumberFormat value={value} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</>
            }
            else if (data.type === 'switch' || data.type === 'toggle') {
                return <>{value ? 'Yes' : 'No'}</>
            }
            else {
                return <>{value ? value : '--'}</>
            }
        }
        else {
            return <>{'--'}</>
        }
    }




    const handleMerge = async (e) => {
        e.preventDefault();
        setLoading(true);
        //const { enquiries, enquiryB, enquiryA } = props;

        let merged = Object.assign({
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web'
        })

        let _primaryKey = selected['displayID'];
        let _primaryEnq = Object.assign({}, enquiries[_primaryKey]);
        let _secondaryEnq = Object.assign({}, enquiries[_primaryKey === 0 ? 1 : 0]);

        for (let [key, value] of Object.entries(selected)) {
            if (key === 'status') {
                merged['pipeline'] = enquiries[value].pipeline;
                merged['stage'] = enquiries[value].stage;
                merged['stageDate'] = enquiries[value].stageDate;
                merged['stageHistory'] = enquiries[value].stageHistory;
                merged['statusHistory'] = enquiries[value].statusHistory;
                merged['wonDate'] = enquiries[value].wonDate;
                merged['deliveredDate'] = enquiries[value].deliveredDate;
                merged['lostDate'] = enquiries[value].lostDate;
                merged['lastStatus'] = enquiries[value].lastStatus;
            }
            else if (key === 'lostReason') {
                merged['lostSubReason'] = enquiries[value].lostSubReason;
                merged['lostNotes'] = enquiries[value].lostNotes;
                merged['lostRefID'] = enquiries[value].lostRefID;
            }
            merged[key] = enquiries[value][key];
        }

        let mergedDynamic = Object.assign({}, _primaryEnq.dynamicFields)
        for (let [key, value] of Object.entries(selectedDynamic)) {
            if (!_.isEmpty(enquiries[value].dynamicFields))
                mergedDynamic[key] = enquiries[value].dynamicFields[key] ? enquiries[value].dynamicFields[key] : null;
        }

        merged['dynamicFields'] = mergedDynamic;
        merged['documentID'] = _primaryEnq.documentID;

        if (!_primaryEnq.isVehicle && _secondaryEnq.isVehicle && !_.isEmpty(_secondaryEnq.requirement)) {
            merged['requirement'] = Object.assign({}, _secondaryEnq.requirement);
            merged['isVehicle'] = true;
        }

        if (!_.isEmpty(_secondaryEnq.tags)) {
            if (_.isEmpty(_primaryEnq.tags)) {
                merged['tags'] = Object.assign([], _secondaryEnq.tags)
            }
            else {
                let _primaryTags = Object.assign([], _primaryEnq.tags)
                let _secondaryTags = Object.assign([], _secondaryEnq.tags)

                _secondaryTags && _secondaryTags.forEach(_tag => {
                    if (!_primaryTags.some(item => item.type === _tag.type))
                        _primaryTags.push(_tag);
                });

                merged['tags'] = Object.assign([], _primaryTags)
            }
        }

        if (_secondaryEnq.linkID && !_primaryEnq.linkID) {
            merged['linkID'] = _secondaryEnq.linkID;
            if (_secondaryEnq.linkEnquiryID) merged['linkEnquiryID'] = _secondaryEnq.linkEnquiryID;
        }

        if (_secondaryEnq.isConverted && !_primaryEnq.isConverted) {
            merged['isConverted'] = _secondaryEnq.isConverted;
            if (_secondaryEnq.convertedBy) merged['convertedBy'] = _secondaryEnq.convertedBy;
            if (_secondaryEnq.convertedBy) merged['convertedDate'] = _secondaryEnq.convertedDate;
            if (_secondaryEnq.unactionedNotify) merged['unactionedNotify'] = _secondaryEnq.unactionedNotify;
        }

        let _enqBasicData = {}// FOR DEMO
        let _enqBasicTDInfo = Object.assign({}, enquiryBasicTDVM);
        for (let [key, value] of Object.entries(_primaryEnq)) {

            _enqBasicData[key] = merged.hasOwnProperty(key) ? merged[key] : value;
            if (_enqBasicTDInfo.hasOwnProperty(key) || key === 'keywords') _enqBasicTDInfo[key] = _enqBasicData[key];

            //Remove empty value from object
            if (_enqBasicTDInfo[key] === null || _enqBasicTDInfo[key] === undefined || _enqBasicTDInfo[key] === '' || _enqBasicTDInfo[key] === [] || (_.isObject(_enqBasicTDInfo[key]) && _.isEmpty(_enqBasicTDInfo[key]))) {
                delete _enqBasicTDInfo[key];
            }

        }

        //console.log('handleMerge', merged)
        saveMerge(merged, _secondaryEnq, _enqBasicTDInfo);

    }

    const saveMerge = async (_objEnq, _secondaryEnq, _primaryBasicEnq) => {

        let mergeID = _secondaryEnq.documentID ? _secondaryEnq.documentID : null;
        let mergeDisplayID = _secondaryEnq.displayID ? _secondaryEnq.displayID : null;

        setLoading(true);
        const mergeEnquiry = window.firebase.functions().httpsCallable('enquiries-saveMerge');
        mergeEnquiry({
            "model": _objEnq,
            "param": {
                "enquiryID": _objEnq.documentID,
                "mergeID": mergeID,
                "mergeDisplayID": mergeDisplayID,
                "clientID": _primaryBasicEnq.clientID,
                "projectId": _primaryBasicEnq.projectId ? _primaryBasicEnq.projectId : null,
                "modifiedBy": localStorage.uid,
                "modifiedFrom": "web"
            }
        }).then((res) => {
            let response = res.data;
            setLoading(false);
            if (response.success === true) {
                props.handleClose(_primaryBasicEnq, mergeID, _objEnq.documentID);
            }
            else {
                Swal.fire(response.message, '', 'error')
            }
        }).catch((err) => {
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        })
    }

    // const saveMerge = async (_objEnq, mergeID, _primaryBasicEnq) => {
    //     const batch = window.firebase.firestore().batch();

    //     let _primaryKey = selected['displayID'];
    //     let _primaryEnqRequirements = Object.assign([], enqRequirements[_primaryKey]);
    //     let _secondaryEnqRequirements = Object.assign([], enqRequirements[_primaryKey === 0 ? 1 : 0]);

    //     let _allRequirements = [];
    //     if (!_.isEmpty(_secondaryEnqRequirements) || !_.isEmpty(_primaryEnqRequirements)) {

    //         _primaryEnqRequirements && _primaryEnqRequirements.forEach(_requirement => {
    //             if (_requirement.isSold && (_objEnq.status === enquiryStatus.OPEN || _objEnq.status === enquiryStatus.LOST || _objEnq.status === enquiryStatus.PROSPECTLOST)) {

    //                 _requirement.isSold = false;

    //                 if (!_.isEmpty(_requirement.stock) && _requirement.stock.documentID) {
    //                     batch.set(window.firebase.firestore().doc(`stock/${_requirement.stock.documentID}`),
    //                         {
    //                             status: stockStatus.AVAILABLE,
    //                             enquiryID: null,
    //                             statusModifiedBy: localStorage.uid,
    //                             statusModifiedFrom: 'web',
    //                             modifiedBy: localStorage.uid,
    //                             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //                             modifiedFrom: 'web'
    //                         }, { merge: true });
    //                 }

    //             }
    //             _allRequirements.push(_requirement);
    //         });

    //         _secondaryEnqRequirements && _secondaryEnqRequirements.forEach(_requirement => {
    //             if (_requirement.isSold && (_objEnq.status === enquiryStatus.OPEN || _objEnq.status === enquiryStatus.LOST || _objEnq.status === enquiryStatus.PROSPECTLOST)) {

    //                 _requirement.isSold = false;

    //                 if (!_.isEmpty(_requirement.stock) && _requirement.stock.documentID) {
    //                     batch.set(window.firebase.firestore().doc(`stock/${_requirement.stock.documentID}`),
    //                         {
    //                             status: stockStatus.AVAILABLE,
    //                             enquiryID: null,
    //                             statusModifiedBy: localStorage.uid,
    //                             statusModifiedFrom: 'web',
    //                             modifiedBy: localStorage.uid,
    //                             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //                             modifiedFrom: 'web'
    //                         }, { merge: true });
    //                 }

    //             }

    //             let fields = !_.isEmpty(_requirement.stock) ? _requirement.stock : _requirement;
    //             if (!_.isEmpty(_allRequirements) && _allRequirements.filter(e => e.regNo === fields.regNo || e.stockNo === fields.stockNo || e.vinNo === fields.vinNo)[0]) {

    //             }
    //             else {
    //                 _allRequirements.push(_requirement);
    //             }

    //         });

    //     }

    //     if (!_.isEmpty(_allRequirements)) {
    //         _objEnq.requirementCount = _allRequirements.length;
    //         _objEnq.soldCount = _allRequirements.filter(m => m.isSold).length;

    //         //MERGE ALL REQUIREMENTS AND UPDATE 
    //         _allRequirements.forEach(_requirement => {
    //             _objEnq.isVehicle = true;
    //             _requirement.enquiryID = _objEnq.documentID;
    //             _requirement.modifiedBy = localStorage.uid;
    //             _requirement.modifiedDate = window.firebase.firestore.Timestamp.now();
    //             _requirement.modifiedFrom = 'web';
    //             batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/requirements/${_requirement.documentID}`), _requirement, { merge: true });
    //         });

    //     }
    //     else {
    //         _objEnq.requirementCount = 0;
    //         _objEnq.soldCount = 0;
    //     }


    //     if (mergeID && _objEnq.documentID) {

    //         let _objReqData = {
    //             enquiryID: _objEnq.documentID,
    //             modifiedBy: localStorage.uid,
    //             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //             modifiedFrom: 'web'
    //         }

    //         // UPDATE MERGED ENQUIRY WITH MERGE-ENQ-ID & IS-DELETED WITH TRUE
    //         batch.set(window.firebase.firestore().doc(`enquiries/${mergeID}`),
    //             {
    //                 isDeleted: true,
    //                 mergeID: _objEnq.documentID,
    //                 modifiedBy: localStorage.uid,
    //                 modifiedDate: window.firebase.firestore.Timestamp.now(),
    //                 modifiedFrom: 'web'
    //             }, { merge: true });

    //         // UPDATE WITH NEW ENQUIRY ID
    //         await firestoreDB(props.dealersettings).firestore().collection('activities')
    //             .where('enquiryID', '==', mergeID)
    //             .where('isDeleted', '==', false)
    //             .where('isDone', '==', true)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     let _objDoc = doc.data();
    //                     batch.update(doc.ref, {
    //                         enquiryID: _objEnq.documentID,
    //                         owner: _objEnq.owner ? _objEnq.owner : _objDoc.owner,
    //                         modifiedBy: localStorage.uid,
    //                         modifiedDate: window.firebase.firestore.Timestamp.now(),
    //                         modifiedFrom: 'web'
    //                     });
    //                 });
    //             });

    //         // CREATE NEW REQUIREMENTS FROM MERGED ENQUIRY 
    //         /*await window.firebase.firestore().collection(`enquiries/${mergeID}/requirements`)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     _objEnq.isVehicle = true;
    //                     let _objDoc = doc.data();
    //                     _objDoc.enquiryID = _objEnq.documentID;
    //                     _objDoc.modifiedBy = localStorage.uid;
    //                     _objDoc.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objDoc.modifiedFrom = 'web';
    //                     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/requirements/${doc.id}`), _objDoc, { merge: true });
    //                 });
    //             });*/

    //         // CREATE NEW WALKINS FROM MERGED ENQUIRY 
    //         await window.firebase.firestore().collection(`enquiries/${mergeID}/walkins`)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     //_objEnq.isVideo = true;
    //                     let _objDoc = doc.data();
    //                     _objDoc.enquiryID = _objEnq.documentID;
    //                     _objDoc.modifiedBy = localStorage.uid;
    //                     _objDoc.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objDoc.modifiedFrom = 'web';
    //                     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/walkins/${doc.id}`), _objDoc, { merge: true });
    //                 });
    //             });

    //         // CREATE NEW QUOTATIONS FROM MERGED ENQUIRY 
    //         await window.firebase.firestore().collection(`enquiries/${mergeID}/quotations`)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     _objEnq.isQuotation = true;
    //                     let _objDoc = doc.data();
    //                     _objDoc.enquiryID = _objEnq.documentID;
    //                     _objDoc.modifiedBy = localStorage.uid;
    //                     _objDoc.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objDoc.modifiedFrom = 'web';
    //                     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/quotations/${doc.id}`), _objDoc, { merge: true });
    //                 });
    //             });

    //         // CREATE NEW FILES FROM MERGED ENQUIRY 
    //         await window.firebase.firestore().collection(`enquiries/${mergeID}/files`)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     _objEnq.isFiles = true;
    //                     let _objDoc = doc.data();
    //                     _objDoc.modifiedBy = localStorage.uid;
    //                     _objDoc.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objDoc.modifiedFrom = 'web';
    //                     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/files/${doc.id}`), _objDoc, { merge: true });
    //                 });
    //             });

    //         // CREATE NEW VIDEOS FROM MERGED ENQUIRY 
    //         await window.firebase.firestore().collection(`enquiries/${mergeID}/videos`)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => {
    //                     _objEnq.isVideo = true;
    //                     let _objDoc = doc.data();
    //                     _objDoc.modifiedBy = localStorage.uid;
    //                     _objDoc.modifiedDate = window.firebase.firestore.Timestamp.now();
    //                     _objDoc.modifiedFrom = 'web';
    //                     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}/videos/${doc.id}`), _objDoc, { merge: true });
    //                 });
    //             });


    //         // UPDATE WITH NEW ENQUIRY ID
    //         await window.firebase.firestore().collection('tradeins')
    //             .where('enquiryID', '==', mergeID)
    //             .where('isDeleted', '==', false)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => { _objEnq.isTradeIn = true; batch.update(doc.ref, { ..._objReqData, enquiry: _primaryBasicEnq }); });
    //             });

    //         // UPDATE WITH NEW ENQUIRY ID
    //         await window.firebase.firestore().collection('testdrives')
    //             .where('enquiryID', '==', mergeID)
    //             .where('isDeleted', '==', false)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => { _objEnq.isTestDrive = true; batch.update(doc.ref, { ..._objReqData, enquiry: _primaryBasicEnq }); });
    //             });

    //         // UPDATE WITH NEW ENQUIRY ID
    //         await window.firebase.firestore().collection('cafeOrders')
    //             .where('enquiryID', '==', mergeID)
    //             .where('isDeleted', '==', false)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => { _objEnq.isCafe = true; batch.update(doc.ref, _objReqData); });
    //             });

    //         // UPDATE WITH NEW ENQUIRY ID
    //         await window.firebase.firestore().collection('finance')
    //             .where('enquiryID', '==', mergeID)
    //             .where('isDeleted', '==', false)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => { _objEnq.isFinance = true; batch.update(doc.ref, { ..._objReqData, enquiry: _primaryBasicEnq }); });
    //             });

    //         // UPDATE WITH NEW ENQUIRY ID
    //         await window.firebase.firestore().collection('usersRating')
    //             .where('enquiryID', '==', mergeID)
    //             .get().then(response => {
    //                 response.docs.forEach((doc) => { batch.update(doc.ref, _objReqData); });
    //             });

    //     }


    //     //Remove empty value from object
    //     for (var propName in _objEnq) {
    //         if (_objEnq[propName] === null || _objEnq[propName] === undefined || _objEnq[propName] === '' || _objEnq[propName] === [] || (_.isObject(_objEnq[propName]) && _.isEmpty(_objEnq[propName]))) {
    //             delete _objEnq[propName];
    //         }
    //     }

    //     // UPDATE ENQUIRY WITH ALL MERGE DATA
    //     batch.set(window.firebase.firestore().doc(`enquiries/${_objEnq.documentID}`), _objEnq, { merge: true });

    //     batch.commit()
    //         .then(() => {
    //             setLoading(false);
    //             props.handleClose(_primaryBasicEnq, mergeID);

    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             toast.notify('Something went wrong', {
    //                 duration: 2000
    //             })
    //         });
    // }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const disableCheckBox = (key, enquiryA, enquiryB) => {
        let _response = (((!_.isEmpty(enquiryA[key]) || _.isBoolean(enquiryA[key]) || _.isNumber(enquiryA[key])) ||
            (!_.isEmpty(enquiryB[key]) || _.isBoolean(enquiryB[key]) || _.isNumber(enquiryB[key]))) &&
            enquiryA[key] != enquiryB[key]) ? false : true;

        if (key === 'owner' && _response === false && !viewOtherEnquiry && (enquiryA[key] !== enquiryB[key])) {
            _response = true;
        }

        if (key === 'status') {
            _response = false;
        }

        return _response
    }


    const divStyle = {
        height: '120px',
        overflow: 'auto'
    };

    let { show, title, } = props;

    let enquiryB = convertContactVM(enquiries[1]);
    let enquiryA = convertContactVM(enquiries[0]);
    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-merge-contact">
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        {
                            isPageLoading
                                ?
                                <>
                                    <div className='loader-center-screen h-100'>
                                        <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row">
                                        <div className="service-loan-yellow-alert">
                                            <div className="alert alert-warning" role="alert" style={divStyle}>
                                                <div className="service-alert-flex">
                                                    {/* <div className="service-alert-flex-icon"> <img src={_images.checkboxTick} alt="" height="20" /> </div> */}
                                                    <div className="service-alert-flex-data pl-1">
                                                        <h4><Translate text={'note'} />: </h4>
                                                        <p>- <Translate text={"The selected 'Enquiry ID' will be a primary enquiry."} /></p>
                                                        <p>- <Translate text={"Pipeline stages and their corresponding dates are derived from the status you have selected."} /></p>
                                                        <p>- <Translate text={'The merge process cannot be reversed.'} /> </p>
                                                        <p>- <Translate text={'The requirement vehicle, test-drive, trade-in, files and walk-in history from both enquiries, will be added to the selected primary enquiry.'} /></p>
                                                        <p>- <Translate text={'The upcoming scheduled activities of the selected primary enquiry will only show after the merge.'} /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="merge-contact-layout form-style">
                                            <div className="common-table">
                                                <table className="table table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="head-light" width="20%"><Translate text={'Property'} /></th>
                                                            <th scope="col" className="head-light" width="30%"><Translate text={'Current Enquiry'} /> </th>
                                                            <th scope="col" className="head-light" width="30%"><Translate text={'Existing Enquiry'} /> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !_.isEmpty(enquiryA['tags']) || !_.isEmpty(enquiryB['tags'])
                                                                ?
                                                                <tr>
                                                                    <th scope="row" className="th-active-bg"><Translate text={'References'} /></th>
                                                                    <td>
                                                                        {
                                                                            !_.isEmpty(enquiryA['tags']) && enquiryA['tags'].length > 0 ? <>
                                                                                {
                                                                                    enquiryA['tags'].map((r, i) => {
                                                                                        return r.subType ? (
                                                                                            <span key={i}>
                                                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(props.dealersettings.integrations, r.type, r.type)}</div>
                                                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
                                                                                            </span>
                                                                                        ) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(props.dealersettings.integrations, r.type, r.type)}</div>)
                                                                                    })
                                                                                }
                                                                            </> : <>{'--'}</>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            !_.isEmpty(enquiryB['tags']) && enquiryB['tags'].length > 0 ? <>
                                                                                {
                                                                                    enquiryB['tags'].map((r, i) => {
                                                                                        return r.subType ? (
                                                                                            <span key={i}>
                                                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(props.dealersettings.integrations, r.type, r.type)}</div>
                                                                                                <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
                                                                                            </span>
                                                                                        ) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{CommonHelper.getNameByValue(props.dealersettings.integrations, r.type, r.type)}</div>)
                                                                                    })
                                                                                }
                                                                            </> : <>{'--'}</>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                        {
                                                            !_.isEmpty(viewableKeys) && viewableKeys.map((key, idx) => {
                                                                if (objEnquiry.hasOwnProperty(key)) {
                                                                    return <tr key={idx}>
                                                                        <th scope="row" className="th-active-bg">
                                                                            {
                                                                                key === 'wonDate'
                                                                                    ?
                                                                                    <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date'} />
                                                                                    :
                                                                                    (key === 'deliveredDate'
                                                                                        ?
                                                                                        <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date'} />
                                                                                        :
                                                                                        (key === 'lostDate'
                                                                                            ?
                                                                                            <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'lost', 'Lost') + ' Date'} />
                                                                                            :
                                                                                            <Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic, key.replace('displayID', 'enquiryID'))} />)
                                                                                    )
                                                                            }
                                                                        </th>
                                                                        <td>
                                                                            <div className="merge-contact-list">
                                                                                <div className="radio icheck-success">
                                                                                    <InputRadio
                                                                                        id={key + '_0'}
                                                                                        type="radio"
                                                                                        value={0}
                                                                                        name={key}
                                                                                        checked={selected[key] === 0 ? true : false}
                                                                                        onChange={handleCheckChange}
                                                                                        disabled={disableCheckBox(key, enquiryA, enquiryB)}
                                                                                    // disabled={(!_.isEmpty(enquiryA[key]) || _.isBoolean(enquiryA[key]) || _.isNumber(enquiryA[key])) &&
                                                                                    //     (!_.isEmpty(enquiryB[key]) || _.isBoolean(enquiryB[key]) || _.isNumber(enquiryB[key])) &&
                                                                                    //     enquiryA[key] != enquiryB[key] ? false : true}
                                                                                    />
                                                                                    <label htmlFor={key + '_0'}></label>
                                                                                </div>
                                                                                {showDataView(key, enquiryA[key], enquiryA.documentID)}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="merge-contact-list">
                                                                                <div className="radio icheck-success">
                                                                                    <InputRadio
                                                                                        id={key + '_1'}
                                                                                        type="radio"
                                                                                        value={1}
                                                                                        name={key}
                                                                                        checked={selected[key] === 1 ? true : false}
                                                                                        onChange={handleCheckChange}
                                                                                        disabled={disableCheckBox(key, enquiryA, enquiryB)}
                                                                                    // disabled={(!_.isEmpty(enquiryA[key]) || _.isBoolean(enquiryA[key]) || _.isNumber(enquiryA[key])) &&
                                                                                    //     (!_.isEmpty(enquiryB[key]) || _.isBoolean(enquiryB[key]) || _.isNumber(enquiryB[key])) &&
                                                                                    //     enquiryA[key] != enquiryB[key] ? false : true}
                                                                                    />
                                                                                    <label htmlFor={key + '_1'}></label>
                                                                                </div>
                                                                                {showDataView(key, enquiryB[key], enquiryB.documentID)}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                else if (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) {
                                                                    return <tr key={idx}>
                                                                        {(() => {
                                                                            let _dataDynamic = enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0]
                                                                            return <>
                                                                                <th scope="row" className="th-active-bg">{_dataDynamic.name}</th>
                                                                                <td>
                                                                                    <div className="merge-contact-list">
                                                                                        <div className="radio icheck-success">
                                                                                            <InputRadio
                                                                                                id={key + '_0'}
                                                                                                type="radio"
                                                                                                value={0}
                                                                                                name={key}
                                                                                                checked={selectedDynamic[key] === 0 ? true : false}
                                                                                                onChange={handleDynamicCheckChange}
                                                                                                disabled={!_.isEmpty(enquiryA.dynamicFields) && !_.isEmpty(enquiryB.dynamicFields) && (!_.isEmpty(enquiryA.dynamicFields[key]) || _.isBoolean(enquiryA.dynamicFields[key]) || _.isNumber(enquiryA.dynamicFields[key])) && enquiryA.dynamicFields[key] != enquiryB.dynamicFields[key] ? false : true}
                                                                                            />
                                                                                            <label htmlFor={key + '_0'}></label>
                                                                                        </div>
                                                                                        {showDynamicDataView(key, (enquiryA.dynamicFields && enquiryA.dynamicFields[key]), _dataDynamic)}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="merge-contact-list">
                                                                                        <div className="radio icheck-success">
                                                                                            <InputRadio
                                                                                                id={key + '_1'}
                                                                                                type="radio"
                                                                                                value={1}
                                                                                                name={key}
                                                                                                checked={selectedDynamic[key] === 1 ? true : false}
                                                                                                onChange={handleDynamicCheckChange}
                                                                                                disabled={!_.isEmpty(enquiryA.dynamicFields) && !_.isEmpty(enquiryB.dynamicFields) && (!_.isEmpty(enquiryB.dynamicFields[key]) || _.isBoolean(enquiryB.dynamicFields[key]) || _.isNumber(enquiryB.dynamicFields[key])) && enquiryA.dynamicFields[key] != enquiryB.dynamicFields[key] ? false : true}
                                                                                            />
                                                                                            <label htmlFor={key + '_1'}></label>
                                                                                        </div>
                                                                                        {showDynamicDataView(key, (enquiryB.dynamicFields && enquiryB.dynamicFields[key]), _dataDynamic)}
                                                                                    </div>
                                                                                </td>
                                                                            </>
                                                                        })()}
                                                                    </tr>
                                                                }

                                                            })
                                                        }

                                                    </tbody>
                                                </table>



                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                </Modal.Body>
                {
                    props.isReadOnly
                        ?
                        <></>
                        :
                        <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                            <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose(); }}>
                                <Translate text={'cancel'} />
                            </button>
                            <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleMerge(e)} >
                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'merge'} />
                            </button>
                        </Modal.Footer>
                }

            </Modal>

            <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers}>
                <StageConsumer>
                    {() => (

                        <SidePanel
                            clsActive={showpanel.clsActive}
                            clsName={showpanel.clsName}
                            sidepanelClose={sidepanelClose}
                            title=''
                        >
                            <QuickView {...props}
                                enquiryid={showpanel.enquiryid}
                                isHideExpandView={true}
                                sidepanelClose={sidepanelClose}
                            />
                        </SidePanel>
                    )}
                </StageConsumer>
            </StageProvider>
        </>
    );

}
export default MergeEnquiry;