/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, InputText, PopUpModal, InputCheckBox,
    RegScan, VinScan
} from '../../../components';
//import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import Translate from '../../../constants/translate';
/** VIEW-MODELS */
import { objOwnedVehicle, mandatoryOwnedEnqFields, objOwnedEnquiryVehicle } from '../viewModel'

export default class AddOwnedEnquiryVehicle extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.refStock = window.firebase.firestore().collection('stock');
        this.state = {
            stock: Object.assign({}, objOwnedEnquiryVehicle),
            showRegScan: false,
            showVinScan: false,
            errors: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: []
        }

    }

    //#region  LOAD DATA
    async loadStock(props) {
        const { show, dealersettings, vehicle } = props;
        //console.log('loadStock', vehicle);
        let owned = Object.assign({}, objOwnedEnquiryVehicle);
        if (show === true && vehicle) {
            owned = Object.assign({}, vehicle);
            this.setState({ stock: owned });
        }

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            //const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;

            const years = [];
            const makes = [];

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true
                });
            });

            if (owned.make) {
                const types = [];
                const models = [];
                const groups = [];
                let _models = !_.isEmpty(makes.filter(m => m.value === owned.make)[0]) ? makes.filter(m => m.value === owned.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });

            }

            this.setState({
                makes: makes,
                years: years
            });

        }



    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadStock(this.props);

    }

    //#endregion

    //#region CHANGE EVENT STOCK
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.stock);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && mandatoryOwnedEnqFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        let state = Object.assign({}, this.state.stock);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ stock: state }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ stock: state }, () => { this.errorChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.stock);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ stock: fields }, () => { this.errorChange(name); });

    }


    handleSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.stock);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ stock: state }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = !checked;
        this.setState({ stock: state });
    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ stock: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ stock: state, models: models, types: _.orderBy(types, ['label'], ['asc'])  }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            stock: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }


    handleSaveInfo = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.stock);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            if (mandatoryOwnedEnqFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.saveStockInfo(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            document.getElementById(id).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
            return formIsValid;
        }

    };

    saveStockInfo = (e) => {
        this.isLoading = true;

        const { enquiryID, regNo, chassisNo, currentlyNotOwned } = this.state.stock;
        if (enquiryID) {
            const objData = Object.assign({}, {
                modifiedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web',
                regNo: regNo,
                chassisNo: chassisNo,
                currentlyNotOwned: currentlyNotOwned
            });

            const refStockData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries`).doc(enquiryID);
            refStockData.set(objData, { merge: true }).then((docRef) => {
                //console.log(docRef);
                this.isLoading = false;
                this.props.handleClose();

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

    };

    //#endregion

    //#region  SCAN
    handleRegScanClose = (data) => {
        if (!_.isEmpty(data)) {
            this.bindData(data);
        }
        this.setState({ showRegScan: false })
    }

    handleVinScanClose = (data) => {
        if (!_.isEmpty(data)) {

            this.bindData(data);
        }
        this.setState({ showVinScan: false })
    }

    bindData = (data) => {
        let state = Object.assign({}, this.state.stock);

        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.yearOfManufacturer) state.year = data.yearOfManufacturer.toString();
        if (data.bodyStyle) state.type = data.bodyStyle;
        if (data.rego) state.regNo = data.rego;
        if (data.vin) state.chassisNo = data.vin;
        if (data.primaryColour) state.extColor = data.primaryColour;

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }
                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ stock: state })
    }
    //#endregion

    render() {

        const {
            enquiryID, group,
            make, model, type, currentlyNotOwned,
            year, regNo, chassisNo, extColor
        } = this.state.stock;

        const { isDeivered, show } = this.props;

        return (
            enquiryID
                ?
                <>
                    <Modal show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-centered modal-add-requirement"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title> <Translate text={isDeivered ? 'vehicle' : this.props.title} /></Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="container-fluid">
                                <div className={`row ${isDeivered ? 'form-disable' : ''}`}>
                                    <div className=" pop-layout form-style requirement-pop">
                                        <>
                                            <div className="requirement-pop-middle">
                                                <div className="requirement-section-head"><Translate text={'vehicleInformation'} /> </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label> <Translate text={'make'} /></label>
                                                        <ReactSelect
                                                            options={this.state.makes}
                                                            name={"make"}
                                                            placeholder={'select make'}
                                                            onChange={this.handleMakeChange}
                                                            value={make}
                                                            classNamePrefix={`${this.state.errors["make"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label><Translate text={'group'} /></label>
                                                        <ReactSelect
                                                            options={this.state.groups}
                                                            name={"group"}
                                                            placeholder={'select group'}
                                                            onChange={this.handleGroupChange}
                                                            value={group}
                                                            classNamePrefix={`${this.state.errors["group"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label><Translate text={'type'} /></label>
                                                        <ReactSelect
                                                            options={this.state.types}
                                                            name={"type"}
                                                            placeholder={'select type'}
                                                            onChange={this.handleTypeChange}
                                                            value={type}
                                                            classNamePrefix={`${this.state.errors["type"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label> <Translate text={'model'} /></label>
                                                        <ReactSelect
                                                            options={this.state.models}
                                                            name={"model"}
                                                            placeholder={'select model'}
                                                            onChange={this.handleSelectChange}
                                                            value={model}
                                                            classNamePrefix={`${this.state.errors["model"]} basic-select`}
                                                            AddUnAvailable={true}
                                                            isDisabled={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'year'} /></label>
                                                        <ReactSelect
                                                            options={this.state.years}
                                                            name={"year"}
                                                            placeholder={'select year'}
                                                            onChange={this.handleSelectChange}
                                                            value={year}
                                                            classNamePrefix={`${this.state.errors["year"]} basic-select`}
                                                            isDisabled={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>



                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'vinNo'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {
                                                                isDeivered
                                                                    ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ showVinScan: true })
                                                                        }}>
                                                                            <i className="ico icon-vin-no"></i>
                                                                        </div>
                                                                    </>
                                                            }


                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'vin no'}
                                                                className={`form-control ${this.state.errors["chassisNo"]}`}
                                                                name="chassisNo"
                                                                onChange={this.handleOnCapChange}
                                                                value={chassisNo}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'registrationNumber'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {
                                                                isDeivered
                                                                    ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ showRegScan: true })
                                                                        }}>
                                                                            <i className="ico icon-reg-no "></i>
                                                                        </div>
                                                                    </>
                                                            }

                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'reg no'}
                                                                className={`form-control ${this.state.errors["regNo"]}`}
                                                                name="regNo"
                                                                onChange={this.handleOnCapChange}
                                                                value={regNo}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-row">

                                                    <div className="form-group col-md-6">
                                                        <label>&nbsp;</label>
                                                        <div className="text-left" >
                                                            <Translate text={'currentlyOwned'} />
                                                            <span className="switch switch-sm float-right">
                                                                <InputCheckBox
                                                                    className="switch"
                                                                    id="currentlyNotOwned"
                                                                    name="currentlyNotOwned"
                                                                    checked={currentlyNotOwned ? false : true}
                                                                    onChange={(e) => { this.handleCheckChange(e) }} />
                                                                <label htmlFor="currentlyNotOwned"></label>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            isDeivered
                                ?
                                <>
                                </>
                                :
                                <>
                                    <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                                        <button
                                            type="button"
                                            className="btn btn-default float-left"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.handleClose();
                                            }}
                                        ><Translate text={'cancel'} />
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-primary float-right"
                                            onClick={(e) => this.handleSaveInfo(e)}
                                        >
                                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'save'} />
                                        </button>
                                    </Modal.Footer>
                                </>
                        }

                    </Modal>

                    <PopUpModal show={this.state.showRegScan}>
                        <RegScan
                            show={this.state.showRegScan}
                            clsActive='overlay-modal active'
                            regNo={regNo}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleRegScanClose}
                        >
                        </RegScan>
                    </PopUpModal>

                    <PopUpModal show={this.state.showVinScan}>
                        <VinScan
                            show={this.state.showVinScan}
                            clsActive='overlay-modal active'
                            chassisNo={chassisNo}
                            handleClose={this.handleVinScanClose}
                        >
                        </VinScan>
                    </PopUpModal>


                </>
                :
                <></>

        );
    }
}