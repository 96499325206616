import React, { useEffect, useState, useContext } from 'react'
import { Modal, Tab, Nav } from 'react-bootstrap'
import _ from 'lodash'
import { triggerVM, triggerCategory } from './viewModel'
import TriggerType from './triggerType'
import Translate from '../../../../constants/translate';
import { TemplateContext } from '../templateContext'
import CommonHelper from '../../../../services/common';

const AddTrigger = (props) => {

    const [trigger, setTrigger] = useState(props.trigger);
    const newTrigger = !_.isEmpty(props.trigger) ? false : true
    const [frequencyValue, setFrequencyValue] = useState('after')
    const { clients, groups, regions } = useContext(TemplateContext);
    const [selectedClient, setSelectedClient] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);

    useEffect(() => {
        if (_.isEmpty(props.trigger)) {
            const newtriggerVM = Object.assign({}, triggerVM);
            newtriggerVM.documentID = window.firebase.firestore().collection(`triggers`).doc().id;
            newtriggerVM.clientID = props.dealersettings.client.id;
            newtriggerVM.addedBy = localStorage.uid;
            newtriggerVM.modifiedBy = localStorage.uid;
            newtriggerVM.module = 'sales';
            newtriggerVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtriggerVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtriggerVM.category = 'activity';
            newtriggerVM.status = 'appointmentScheduled';
            newtriggerVM.triggerType = 'email';
            newtriggerVM.period = 'afterMins';
            newtriggerVM.iterationType = 'daily';
            newtriggerVM.level = 'individual';
            newtriggerVM.subLevelIDs = [props.dealersettings.client.id];
            newtriggerVM.settingsID =  !_.isEmpty(props.dealersettings.oemID) ? props.dealersettings.oemID : props.dealersettings.client.settingsID;
            setTrigger(newtriggerVM)
        }
        else {
            if (trigger.periodValue < 0) {
                setTrigger({
                    ...trigger,
                    'periodValue': -(trigger.periodValue)
                })
                setFrequencyValue('before')
            }
        }
    }, [])


    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return
        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    data: rec
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    return _.isEmpty(trigger) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen h-100">
                            <div className="container-fluid pl-0 ">
                                <div className="add-trigger-wrapper">
                                    <Tab.Container defaultActiveKey={trigger.category} onSelect={(key) => {

                                        setTrigger({
                                            ...trigger,
                                            'category': key,
                                            'stageTo': null,
                                            'stageList': null,
                                        })
                                    }} unmountOnExit={true} mountOnEnter={true}>
                                        <div className="add-trigger-leftmenu">
                                            <h3> <Translate text={'Category'} /></h3>
                                            <Nav as="ul" bsPrefix="ul-nav">
                                                {
                                                    triggerCategory.map((rec, index) => {
                                                        return <Nav.Item as="li" bsPrefix="li-nav" key={index} className={`nav-${rec.value}`}>
                                                            <Nav.Link eventKey={rec.value} bsPrefix="li-a-nav"><i className={rec.class}></i>{CommonHelper.showLocale(props, rec.value, rec.name)}</Nav.Link>
                                                        </Nav.Item>
                                                    })
                                                }
                                            </Nav>
                                        </div>
                                        <div className='add-trigger-column-wraper'>

                                            {
                                                triggerCategory.map((rec, index) => {
                                                    return <Tab.Pane key={index} eventKey={rec.value} bsPrefix={'h-100'}>
                                                        <TriggerType
                                                            {...props}
                                                            value={rec.value}
                                                            newTrigger={newTrigger}
                                                            trigger={trigger}
                                                            handleClose={props.handleClose}
                                                            frequencyValue={frequencyValue}
                                                            setFrequencyValue={(val) => {
                                                                setFrequencyValue(val)
                                                            }}
                                                            groupOptions={groupOptions}
                                                            clientOptions={clientOptions}
                                                            regionOptions={regionOptions}
                                                        />
                                                    </Tab.Pane>
                                                })
                                            }

                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}

export default AddTrigger