import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { TableView, ReactSelect } from '../../../../components';
import { pipelineSettingsModalFields } from './viewModel'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import { pipelineVM } from './viewModel'
import CommonHelper from '../../../../services/common';
const StatusSettings = ({ title, show, handleClose, pipeline, allPipelines, serviceStatus, convertStatusSettings, dealersettings, selectedClient }) => {
  const [statusSettings, setStatusSettings] = useState(pipeline.statusSettings ? pipeline.statusSettings : [])
  const [addSubList, setAddSubList] = useState({ pipeline: '', stage: '', status: '', id: uuid() })
  const [editSubList, setEditSubList] = useState()
  const [errorFields, setErrorFields] = useState({});
  const [textEditor, setTexteditor] = useState({})
  const [loader, setLoader] = useState(false)

  const serviceInvoiceEnabled = dealersettings?.client?.moduleSettings?.serviceInvoice?.enabled === true;

  const [windowSize, setWindowSize] = useState({
    windowWidth: 750,
    windowHeight: window.innerHeight - 693,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 693,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  let pipelinesOfSameLevel = []
  if (pipeline.value === 'LeadsBucket') {
    pipelinesOfSameLevel = allPipelines.filter(_pipeline => _pipeline.level === pipeline.level && _pipeline.value === 'LeadsBucket')
  } else {
    if (pipeline.level === 'oem') {
      pipelinesOfSameLevel = allPipelines.filter(_pipeline => _pipeline.level === pipeline.level && _pipeline.value !== 'LeadsBucket')
    } else if (pipeline.level === 'region') {
      pipelinesOfSameLevel = allPipelines.filter(_pipeline => (_pipeline.level === pipeline.level || _pipeline.level === 'oem') && _pipeline.value !== 'LeadsBucket')
    } else if (pipeline.level === 'group') {
      pipelinesOfSameLevel = allPipelines.filter(_pipeline => (_pipeline.level === pipeline.level || _pipeline.level === 'region' || _pipeline.level === 'oem') && _pipeline.value !== 'LeadsBucket')
    } else if (pipeline.level === 'individual') {
      pipelinesOfSameLevel = allPipelines.filter(_pipeline => (_pipeline.level === pipeline.level || _pipeline.level === 'group' || _pipeline.level === 'region' || _pipeline.level === 'oem') && _pipeline.value !== 'LeadsBucket')
    }
  }
  const pipelineOptions = pipelinesOfSameLevel.map(rec => {
    return {
      active: true,
      label: rec.name,
      value: rec.value
    }
  })

  var statuses = [
    'scheduled',
    'checkIn',
    'technicianRequested',
    'partsRequested',
    'workshopRequested',
    'advisorRequested',
    'ownerRequested',
    'ownerApproved',
    'ownerDeclined',
    'workInProgress',
    'completed'
  ]

  var statusOptions = serviceStatus;

  // statuses.forEach(a => {
  if (serviceInvoiceEnabled && !statusOptions.some(a => a.isModule === true)) {
    statusOptions.push({ active: true, isModule: true, value: 'isInvoice', label: <div className={`badge badge-pill badge-status-open`}>INVOICE DONE</div> })
  }
  // })

  const generateStageOptions = _pipeline => {
    const selectedPipeline = allPipelines.filter(rec => rec.value === _pipeline)[0]
    let stagesToReturn = !_.isEmpty(selectedPipeline) ? selectedPipeline.stages.map(stage => {
      return {
        active: true,
        label: stage.name ? stage.name : '',
        value: stage.value
      }
    }) : []

    if (!_.isEmpty(selectedPipeline) && selectedPipeline.value === 'LeadsBucket') {
      stagesToReturn = stagesToReturn.filter(stage => stage.value !== 'Converted')
    }

    return stagesToReturn
  }

  const handleonSubSelectChange = (e, data) => {
    if (data.name === 'pipeline') {
      setAddSubList({
        ...addSubList,
        [data.name]: e ? e.value : '',
        stage: '',
      })
    } else if (data.name === 'status') {
      setAddSubList({
        ...addSubList,
        [data.name]: e ? e.value : '',
        isModule: e?.isModule ? e.isModule : null,
      })
    } else {
      setAddSubList({
        ...addSubList,
        [data.name]: e ? e.value : ''
      })
    }
    setErrorFields({
      ...errorFields,
      ['sublist' + data.name]: ''
    });
  }
  const handleonEditSubSelectChange = (e, data) => {
    if (data.name === 'pipeline') {
      setEditSubList({
        ...editSubList,
        [data.name]: e ? e.value : '',
        stage: '',
      })
    } else if (data.name === 'status') {
      setEditSubList({
        ...editSubList,
        [data.name]: e ? e.value : '',
        isModule: e?.isModule ? e.isModule : null,
      })
    } else {
      setEditSubList({
        ...editSubList,
        [data.name]: e ? e.value : ''
      })
    }
    setErrorFields({
      ...errorFields,
      ['editsublist' + data.name]: ''
    });
  }

  const handleEditSubList = (rec) => {
    setEditSubList(rec)
    setTexteditor({
      [rec.id]: true
    })
  }

  const handleCancelSubList = () => {
    setEditSubList(null)
    setTexteditor({})
  }

  const handleDeleteSubList = (rec) => {
    Swal.fire({
      title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
      text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
      cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
    }).then((result) => {
      if (result.value) {
        let deleteList = [...statusSettings.filter(item => item.id !== rec.id)];
        setStatusSettings(deleteList)
      }
    })
  }

  const handleSublistSave = (rec, newlist) => {
    let formIsValid = true;
    let errorClass = 'input_error';
    let errors = {};
    if (_.isEmpty(rec['stage']) || _.isEmpty(rec['stage'].trim())) {
      formIsValid = false;
      if (newlist)
        errors['subliststage'] = errorClass;
      else
        errors['editsubliststage'] = errorClass;
    }
    if (_.isEmpty(rec['pipeline']) || _.isEmpty(rec['pipeline'].trim())) {
      formIsValid = false;
      if (newlist)
        errors['sublistpipeline'] = errorClass;
      else
        errors['editsublistpipeline'] = errorClass;
    }
    if (_.isEmpty(rec['status']) || _.isEmpty(rec['status'].trim())) {
      formIsValid = false;
      if (newlist)
        errors['subliststatus'] = errorClass;
      else
        errors['editsubliststatus'] = errorClass;
    }
    setErrorFields(errors)

    if (newlist && statusSettings.some(item => item.status === rec.status)) {
      Swal.fire(CommonHelper.showLocale({ dealersettings }, 'List item with same status already exists'), '', 'error')
      formIsValid = false;
    }
    if (!formIsValid) {
      return;
    }
    let addList = newlist ? [...statusSettings, rec] : [
      ..._.map(statusSettings, function (obj) {
        return obj.id === rec.id ? { ...rec } : { ...obj };
      })];

    if (!newlist) {
      let _list = addList.filter(item => item.id !== rec.id)
      if (_list.some(item => item.status === rec.status)) {
        Swal.fire(CommonHelper.showLocale({ dealersettings }, 'List item with same status already exists'), '', 'error')
        return
      }
    }
    setStatusSettings(addList)
    setAddSubList({ pipeline: '', stage: '', status: '', id: uuid() })
    setEditSubList(null)
    setTexteditor({})
  }

  const handleSave = () => {

    if (_.isEmpty(statusSettings) && _.isEmpty(pipeline.statusSettings)) {
      Swal.fire(CommonHelper.showLocale({ dealersettings }, 'Please add some data to save'), '', 'info')
      return
    }

    setLoader(true)
    const batch = window.firebase.firestore().batch();
    if (pipeline.level === 'oem') {
      const pipelineObj = {
        'pipelines': [..._.map(allPipelines.filter(item => item.level === pipeline.level), function (obj) {
          return obj.value === pipeline.value ? {
            ..._.pick(pipeline, _.keys(pipelineVM)),
            stages: pipeline.stages,
            statusSettings: statusSettings
          } : { ..._.pick(obj, _.keys(pipelineVM)) };
        })]
      }
      batch.set(window.firebase.firestore().doc(`clientSettings/${dealersettings.client.settingsID}/services/${dealersettings.client.settingsID}`),
        pipelineObj, { merge: true });
    }
    else if (_.isArray(pipeline.levelID)) {
      pipeline.levelID.forEach(rec => {
        const pipelineObj = {
          'pipelines': [..._.map(allPipelines.filter(item => item.level === pipeline.level && item.levelID === rec), function (obj) {
            return obj.value === pipeline.value ? {
              ..._.pick(pipeline, _.keys(pipelineVM)),
              stages: pipeline.stages,
              statusSettings: statusSettings
            } : { ..._.pick(obj, _.keys(pipelineVM)) };
          })]
        }
        batch.set(window.firebase.firestore().doc(`clientSettings/${dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}/services/${rec}`),
          pipelineObj, { merge: true });
      })
    }
    else {
      const pipelineObj = {
        'pipelines': [..._.map(allPipelines.filter(item => item.level === pipeline.level && item.levelID === pipeline.levelID), function (obj) {
          return obj.value === pipeline.value ? {
            ..._.pick(pipeline, _.keys(pipelineVM)),
            stages: pipeline.stages,
            statusSettings: statusSettings
          } : { ..._.pick(obj, _.keys(pipelineVM)) };
        })]
      }
      batch.set(window.firebase.firestore().doc(`clientSettings/${dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}/services/${pipeline.levelID}`),
        pipelineObj, { merge: true });
    }


    batch.commit()
      .then(snapshot => {
        setLoader(false)
        //update settings date and priority
        if (!_.isEmpty(selectedClient)) {
          window.firebase.firestore().doc(`clients/${selectedClient.documentID}`)
            .set({
              settingsUpdate: window.firebase.firestore.Timestamp.now(),
              settingsUpdatePriority: 'normal',
              settingsUpdateBy: dealersettings?.name || null,
              settingsUpdateText: 'Service Pipeline Settings has been updated',
            }, { merge: true })
        }
        //end
        toast.notify('Settings updated successfully', {
          duration: 2000
        })
        handleClose(pipeline)
      })
      .catch(error => {
        setLoader(false)
        console.log(error);
        Swal.fire('Something went wrong', '', 'error')
      });

  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName='modal-dialog modal-dialog-centered modal-delivered-sms'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={'Status Settings'} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="settings-head">
              </div>
              <div role="alert" className="alert alert-primary w-100"><Translate text={'From Pipeline'} />: <b>{pipeline.name}</b></div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="head-light" width="33%"> <Translate text={'status'} /></th>
                    <th scope="col" className="head-light" width="33%"> <Translate text={'To Pipeline'} /></th>
                    <th scope="col" className="head-light" width="33%"> <Translate text={'To Stage'} /></th>
                    <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><div className="">
                      <ReactSelect
                        options={statusOptions}
                        name={"status"}
                        placeholder={'select status'}
                        onChange={handleonSubSelectChange}
                        value={addSubList.status}
                        classNamePrefix={`${errorFields["subliststatus"]} cursor-pointer basic-select`}
                        id="subliststatus"
                      >
                      </ReactSelect>
                    </div></td>
                    <td><div className="">
                      <ReactSelect
                        options={pipelineOptions}
                        name={"pipeline"}
                        placeholder={'select pipeline'}
                        onChange={handleonSubSelectChange}
                        value={addSubList.pipeline ? addSubList.pipeline : ''}
                        classNamePrefix={`${errorFields["sublistpipeline"]} cursor-pointer basic-select`}
                        id="sublistpipeline"
                      >
                      </ReactSelect>
                    </div></td>
                    <td><div className="">
                      <ReactSelect
                        options={generateStageOptions(addSubList.pipeline ? addSubList.pipeline : pipeline)}
                        name={"stage"}
                        placeholder={'select stage'}
                        onChange={handleonSubSelectChange}
                        value={addSubList.stage}
                        classNamePrefix={`${errorFields["subliststage"]} cursor-pointer basic-select`}
                        id="subliststage"
                      >
                      </ReactSelect>
                    </div></td>
                    <td className="border-right-0">
                      <div className="button-height-fix">
                        <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                      </div>
                    </td>
                  </tr>
                  {
                    statusSettings.length > 0 ?
                      statusSettings.map((rec, index) => {
                        return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                          <td>
                            {
                              textEditor[rec.id] ? (<>
                                <ReactSelect
                                  options={statusOptions}
                                  name={"status"}
                                  placeholder={'select status'}
                                  onChange={handleonEditSubSelectChange}
                                  value={editSubList.status}
                                  classNamePrefix={`${errorFields["editsubliststatus"]} cursor-pointer basic-select`}
                                  id="editsubliststatus"
                                />
                              </>) : (<>{statusOptions.filter(enqStatus => enqStatus.value === rec.status).length > 0 ? statusOptions.filter(enqStatus => enqStatus.value === rec.status)[0].label : ''}</>)
                            }
                          </td>
                          <td>
                            {
                              textEditor[rec.id] ? (<> <ReactSelect
                                options={pipelineOptions}
                                name={"pipeline"}
                                placeholder={'select pipeline'}
                                onChange={handleonEditSubSelectChange}
                                value={editSubList.pipeline ? editSubList.pipeline : ''}
                                classNamePrefix={`${errorFields["editsublistpipeline"]} cursor-pointer basic-select`}
                                id="editsublistpipeline"
                              >
                              </ReactSelect></>) : (<>{allPipelines.filter(item => item.value === rec.pipeline).length ? allPipelines.filter(item => item.value === rec.pipeline)[0].name : ''}</>)
                            }
                          </td>
                          <td>
                            {
                              textEditor[rec.id] ? (<> <ReactSelect
                                options={generateStageOptions(editSubList.pipeline ? editSubList.pipeline : pipeline)}
                                name={"stage"}
                                placeholder={'select stage'}
                                onChange={handleonEditSubSelectChange}
                                value={editSubList.stage}
                                classNamePrefix={`${errorFields["editsubliststage"]} cursor-pointer basic-select`}
                                id="editsubliststage"
                              >
                              </ReactSelect></>) : (<>{allPipelines.filter(item => item.value === rec.pipeline)[0].stages.filter(stage => stage.value === rec.stage).length ? allPipelines.filter(item => item.value === rec.pipeline)[0].stages.filter(stage => stage.value === rec.stage)[0].name : ''}</>)
                            }
                          </td>
                          <td className="border-right-0">
                            <div className="action-fix">
                              {
                                textEditor[rec.id] ?
                                  (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                    <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                  </>) :
                                  (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                    <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                  </>)
                              }

                            </div>
                          </td>
                        </tr>
                      })
                      : (<></>)
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-primary float-right ml-2'
            aria-label='Save'
            disabled={(!_.isEmpty(editSubList) || !_.isEmpty(addSubList.pipeline) || !_.isEmpty(addSubList.status) || !_.isEmpty(addSubList.stage)) ? true : false}
            onClick={handleSave}
          >
            {' '}
            {
              loader ?
                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                : (<></>)
            }
            <Translate text={'save'} />
          </button>
          <button
            type='button'
            className='btn btn-default float-right'
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'close'} />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusSettings;
