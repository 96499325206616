import React, { useState, useEffect, useReducer, useCallback } from 'react';
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ContentOverlay, PopUpModal } from '../../components'
import CommonHelper from '../../services/common'
import { default as _images } from '../../images';
import AcitivitylogReducer from '../calendar/activityReducer'
import AddActivity from '../activity'
import ActivityView from './activityView'
import { objActivitylog, activitylogTypes, objWalkIn } from '../pipeline/viewModel'
import ActivityStatus from '../activity/actvityStatusModal';
import { enqUnActionStatus, enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const InboundActivitiesCount = (props) => {
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [activitylogs, dispatch] = useReducer(AcitivitylogReducer, { Overdue: [], Today: [], Upcoming: [], Completed: [] })
    const acitivtyTags = ["Overdue", "Today", "Upcoming"]
    const [activeAcitivtyTag, setActiveactivity] = useState('Overdue')
    const [selectedActivitylog, setSelectedActivitylog] = useState(null);
    const [activeTab, setActivetab] = useState('appointment')
    const [editable, setEditable] = useState(false);
    const [filtertype, setFiltertype] = useState(null)
    const [sectionLoading, setSectionLoading] = useState(false);
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Overdue": true, "Today": true, "Upcoming": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 10
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [isNewActivity, setNewActivity] = useState(false)
    const [roleIDs, setRoleIDs] = useState(props.roleIDs);
    const [pageNum, setPageNum] = useState({ Overdue: 0, Today: 0, Upcoming: 0 });
    const [selectedUser, setUser] = useState([])
    const [searchText, setSearchText] = useState('')
    const [filterPopOver, setFilterPopOver] = useState({ show: false, target: null })
    //const [viewOtherActivity, setOtherActivity] = useState(false);
    const [userOptions, setUserOptions] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [activityCountsLoader, setActivityCountsLoader] = useState()
    const [activityCounts, setActivityCounts] = useState({})
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const showInboundActivities = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);

    // const accessToFinanceActivity = (!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.accessToFinanceActivity) ? true : false
    // useEffect(() => {
    //     setOtherActivity((!_.isEmpty(props.dealersettings) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //         props.dealersettings.rolePermissions.permissions.modifyOtherActivity) ? true : false)
    // }, [props.dealersettings])

    // useEffect(() => {
    //     if (_.isEmpty(props.clientUsers))
    //         return
    //     let _users = [];
    //     props.clientUsers.forEach(rec => {
    //         _users.push({
    //             value: rec.id,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             searchlabel: rec.name,
    //             label: <div className="drop-image">
    //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" />
    //                 {rec.name}
    //             </div>,
    //             data: rec
    //         })
    //     })
    //     setUserOptions(_users)
    // }, [props.clientUsers])

    useEffect(() => {
        let _actName = activitylogTypes.filter(e => e.type === activeTab)[0] ? activitylogTypes.filter(e => e.type === activeTab)[0].name : activeTab
        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'activity';
        newlogVM.subType = activeTab;
        newlogVM.title = _actName;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        //newlogVM.clientID = props.dealersettings.client.id;
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
        newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(['call'].includes(activeTab) ? moment().add(15, 'minutes')._d : moment().add(1, 'hours')._d);
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), localStorage.uid);
        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
        setSelectedActivitylog(newlogVM)
    }, [activeTab])

    useEffect(() => {
        return () => {
            window.unsubdashboardactivitylist && window.unsubdashboardactivitylist();
        }
    }, [])

    useEffect(() => { //NEW
        let dataload = { "Overdue": true, "Today": true, "Upcoming": true }
        setPageNum({ Overdue: 0, Today: 0, Upcoming: 0 });
        setHasMoreData({});
        setDataload({
            ...dataload,
            [activeAcitivtyTag]: true
        })
        dispatch({
            type: "CLEAR_ALL"
        });
    }, [props?.dashboardFilter])

    useEffect(() => {
        const fetchCounts = async () => {

            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props?.dashboardFilter?.pipelines?.length > 0) {
                _filter.pipeline = props.dashboardFilter.pipelines.join(',')
            }
            if (props?.dashboardFilter?.user?.length > 0) {
                _filter.owner = props.dashboardFilter.user.join(',')
            }
            if (ownerDashboard) {
                _filter.owner = localStorage.uid
            }
            if (props?.dashboardFilter?.makes?.length > 0) {
                _filter.make = props.dashboardFilter.makes.join(',')
            }
            if (!_.isEmpty(roleIDs)) _filter.roleIDs = roleIDs
            //if (accessToFinanceActivity)
            _filter.type = "activity";

            if (props?.dashboardFilter?.salesType?.length > 0) {
                _filter.saleType = props.dashboardFilter.salesType.join(',')
            } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
                _filter.saleType = props.dealersettings.accessSalesType.join(',');
            }

            let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
            if (!_.isEmpty(excludeOrigins))
                _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

            let _searchObject = {
                type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
                params: JSON.stringify(_filter),
            }
            setActivityCountsLoader(true)
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await getData(_searchObject);
            if (resp.data.success) {
                const data = resp.data.data[0]
                // console.log(`data of the counts`, data)
                setActivityCounts(data)
                setActivityCountsLoader(false)
            } else {
                setActivityCountsLoader(false)
            }
        }
        fetchCounts()
    }, [props?.dashboardFilter, props.refreshData])

    useEffect(() => { //new
        if (!checkDataLoad[activeAcitivtyTag]) {
            return;
        }
        const fetchActivities = async () => {
            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props?.dashboardFilter?.pipelines?.length > 0) {
                _filter.pipeline = props.dashboardFilter.pipelines.join(',')
            }
            if (props?.dashboardFilter?.user?.length > 0) {
                _filter.owner = props.dashboardFilter.user.join(',')
            }
            if (ownerDashboard) {
                _filter.owner = localStorage.uid
            }
            if (props?.dashboardFilter?.makes?.length > 0) {
                _filter.enqMake = props.dashboardFilter.makes.join(',')
            }
            _filter.dateType = 'startDate'
            _filter.isDone = 'false'
            _filter.searchType = activeAcitivtyTag.toLowerCase();
            _filter.type = "activity";

            if (props?.dashboardFilter?.salesType?.length > 0) {
                _filter.enqSaletype = props.dashboardFilter.salesType.join(',')
            } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
                _filter.enqSaletype = props.dealersettings.accessSalesType.join(',');
            }

            let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
            if (!_.isEmpty(excludeOrigins))
                _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

            if (!_.isEmpty(roleIDs)) _filter.roleIDs = roleIDs

            let _searchObject = {
                "type": "searchActivities",
                "filters": _filter,
                "sortOrder": `startDate asc`,
                "pageNum": pageNum[activeAcitivtyTag],
                pageLimit,
            }
            _searchObject.clientID = props.dealersettings.client ? props.dealersettings.client.id : '';;

            setPagingloader(true)
            const getData = window.firebase.functions().httpsCallable('generic-searchData');
            const resp = await getData(_searchObject);
            setDataload({
                ...checkDataLoad,
                [activeAcitivtyTag]: false
            })
            setLoadingActivity(false)
            setPagingloader(false)
            if (resp.data.success) {

                let activities_ = resp.data.data
                activities_.forEach(rec => {
                    rec.startDate = rec.startDate && rec.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.startDate._seconds)._d) : (rec.startDate ? rec.startDate : null);
                    rec.endDate = rec.endDate && rec.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.endDate._seconds)._d) : (rec.endDate ? rec.endDate : null);
                    rec.addedDate = rec.addedDate && rec.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.addedDate._seconds)._d) : (rec.addedDate ? rec.addedDate : null);
                    rec.modifiedDate = rec.modifiedDate && rec.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.modifiedDate._seconds)._d) : (rec.modifiedDate ? rec.modifiedDate : null);
                })

                //let activities_ = resp.data.data.map(a => CommonHelper.convertActivityLogVM(a, { ...props }))
                setHasMoreData({
                    ...hasMore,
                    [activeAcitivtyTag]: activities_.length >= pageLimit
                })
                if (activities_.length >= pageLimit)
                    setPageNum({
                        ...pageNum,
                        [activeAcitivtyTag]: pageNum[activeAcitivtyTag] + 1
                    })

                dispatch({
                    type: "APPEND_LIST",
                    data: activities_,
                    activetab: activeAcitivtyTag
                });
            }
        }
        fetchActivities();
    }, [checkDataLoad, activeAcitivtyTag])

    const handleAddActivity = () => {
        setSelectedActivitylog(null)
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    const handleEditactivity = useCallback(async (e, logVM) => {
        e && e.preventDefault();
        if (logVM.stockID) {
            if (!_.isEmpty(logVM.stock)) {
                setStockObj(logVM.stock)
            }
            else {
                const stockDoc = await window.firebase.firestore().doc('stock/' + logVM.stockID).get();
                if (stockDoc.exists) {
                    const stockData = stockDoc.data()
                    stockData.documentID = stockDoc.id;
                    setStockObj(stockData)
                }
            }
        }
        let _contactID = logVM.contactID ? logVM.contactID : logVM?.contact?.documentID;
        if (_contactID) {
            //if (!_.isEmpty(logVM.contact)) {
            //    setContactObj(logVM.contact);
            //} else {
            const contactDoc = await firestoreDB(props.dealersettings)
                .firestore()
                .doc('contactDetails/' + _contactID)
                .get();
            if (contactDoc.exists) {
                const contactData = contactDoc.data();
                contactData.documentID = contactDoc.id;
                setContactObj(contactData);
            }
            //}
        }
        if (logVM.enquiryID) {
            // if (!_.isEmpty(logVM.enquiry)) {
            //     setEnquiryObj(logVM.enquiry);
            // } else {
            const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('enquiries/' + logVM.enquiryID)
                .get();
            if (enquiryDoc.exists) {
                const enquiryData = enquiryDoc.data();
                enquiryData.documentID = enquiryDoc.id;
                setEnquiryObj(enquiryData);
            }
            //}
        }
        setSelectedActivitylog(logVM)
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }, [])

    const handleCompleteContinue = (isDone, objLog, activityOutcome) => {
        // check mandActivityNotes
        if (CommonHelper.isValidActivityNotesCheck(objLog, isDone, props.dealersettings) === false) {
            handleEditactivity(null, { ...objLog, 'isDone': isDone, 'isFromComplete': true });
            return;
        }

        delete objLog.searchUsers;
        objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        objLog.modifiedBy = localStorage.uid;
        objLog.isDone = isDone
        if (activityOutcome) {
            objLog.activityOutcome = activityOutcome
        }
        setSectionLoading(true)
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
            .set(objLog, { merge: true })
            .then(snapshot => {
                onDocUpdate(objLog)
                toast.notify('Log updated successfully', {
                    duration: 2000
                })
                //setActivetab('appointment')
                //setActiveactivity('Completed')
                setEditable(false)

                setSectionLoading(false)
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                //update enquiry modified date if activity is mark as done
                if (!_.isEmpty(objLog.enquiryID) && objLog.isDone === true) {
                    let objUpdate = {
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        unactionedNotify: enqUnActionStatus.COMPLETED,
                    }
                    // if (objLog.inbound === true) {
                    //     objUpdate = {
                    //         ...objUpdate,
                    //         bdcStatus: 'show'
                    //     }
                    // }
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set(objUpdate, { merge: true })
                }
                if (objLog.isDone === true) {
                    props.reloadData && props.reloadData();
                    if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                        Swal.fire({
                            title: CommonHelper.showLocale(props, 'Please select'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                            cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                .set({
                                    bdcStatus: result.value ? 'show' : 'noshow'
                                }, { merge: true })
                            shedulenextactivity(objLog);
                            if (result.value)
                                logWalkin(objLog.enquiryID)

                            CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                        });
                    }
                    else {
                        shedulenextactivity(objLog);
                    }
                }
            })
            .catch(error => {
                console.error(error);
                setSectionLoading(false)
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const handleComplete = useCallback((e, objLog, aTab) => {
        const isDone = e.target.checked
        const { dealersettings } = props
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });

            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { isDone, objLog }
            });
        } else {
            handleCompleteContinue(isDone, objLog);
        }
    }, [])

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        //console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                handleCompleteContinue(data.isDone, data.objLog,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }


    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }
    }

    const onDocUpdate = (activity) => {
        var tabtype = '';
        if (activity.isDone === true) {
            tabtype = "Completed"
        }
        else if (activity.startDate < window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)) {
            tabtype = "Overdue"
        }
        else if (activity.startDate > window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)) {
            tabtype = "Upcoming"
        }
        else {
            tabtype = "Today"
        }
        //console.log('onDocUpdate', tabtype, activeAcitivtyTag)
        if (!_.isEmpty(activity)) {
            const oldLog = activitylogs[activeAcitivtyTag].find(item => item.documentID === activity.documentID);
            if (!_.isEmpty(oldLog)) {
                if (activeAcitivtyTag !== tabtype) {
                    dispatch({
                        type: "REMOVE_LIST",
                        data: activity,
                        activetab: activeAcitivtyTag
                    });
                    dispatch({
                        type: "ADDNEW_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }
                else {
                    dispatch({
                        type: "UPDATE_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }
            }
            else {

                if (activeAcitivtyTag !== tabtype) {
                    dispatch({
                        type: "REMOVE_LIST",
                        data: activity,
                        activetab: activeAcitivtyTag
                    });
                    dispatch({
                        type: "ADDNEW_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }
                else {
                    dispatch({
                        type: "ADDNEW_LIST",
                        data: activity,
                        activetab: tabtype
                    });
                }

            }
        }
        setTimeout(() => {
            props.reloadData && props.reloadData();
            //props.refreshData && props.refreshData();
        }, 2000);
    }

    const handleScheduledActivityModelClose = (objLog) => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null,
        })
        if (objLog) onDocUpdate(objLog)
    }

    const handleDeleteactivity = useCallback((e, objLog) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                delete objLog.searchUsers;
                objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
                objLog.modifiedBy = localStorage.uid;
                objLog.isDeleted = true;
                //setSectionLoading(true)
                firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    isDeleted: true
                }, { merge: true })
                    .then(snapshot => {
                        if (!_.isEmpty(objLog)) {
                            dispatch({
                                type: "REMOVE_LIST",
                                data: objLog,
                                activetab: activeAcitivtyTag
                            });
                        }
                        setTimeout(() => {
                            props.reloadData && props.reloadData();
                            //props.refreshData && props.refreshData();
                        }, 2000);

                        toast.notify('Log deleted successfully', {
                            duration: 2000
                        })
                        // setTimeout(function () {
                        //     setSectionLoading(false)
                        // }, 1000);

                        let logNotes = 'Log deleted';
                        if (!_.isEmpty(objLog) && objLog.type)
                            logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'activities',
                            subType: 'delete',
                            recordId: objLog.documentID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);

                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }, [])

    const handleActivityModelDelete = (objLog) => {
        setStockObj(null)
        setSelectedActivitylog(null)
        setShowActivityModel(false)

        if (!_.isEmpty(objLog)) {
            dispatch({
                type: "REMOVE_LIST",
                data: objLog,
                activetab: activeAcitivtyTag
            });

            setTimeout(() => {
                props.reloadData && props.reloadData();
                //props.refreshData && props.refreshData();
            }, 2000);
        }
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setShowActivityModel(false)
        setStockObj(null)
        setEnquiryObj(null)
        setContactObj(null)
        setSelectedActivitylog(null)

        if (objLog) onDocUpdate(objLog)
        // if (objLog) {
        //     dispatch({
        //         type: "CHECKLOG",
        //         data: objLog,
        //         activeTab: objLog.isDone ? 'Completed' :
        //             objLog.startDate.seconds < parseInt(moment().startOf('day').format('X')) ? 'Overdue' :
        //                 objLog.startDate.seconds > parseInt(moment().endOf('day').format('X')) ? 'Upcoming' :
        //                     'Today'
        //     });
        // }
        if (objLog) {
            if (objLog.isDone === true && !isActivtyAlreadyDone) {
                if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, 'Please select'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set({
                                bdcStatus: result.value ? 'show' : 'noshow'
                            }, { merge: true })
                        shedulenextactivity(objLog);
                        if (result.value)
                            logWalkin(objLog.enquiryID)

                        CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                    });
                }
                else {
                    shedulenextactivity(objLog);
                }
            }
        }
        setTimeout(() => {
            props.reloadData && props.reloadData();
            //props.refreshData && props.refreshData();
        }, 2000);
    }

    return (
        <>
            <div className="dashboard-box">
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => {
                            e.preventDefault();
                            handleAddActivity();
                        }}> <i className="ico icon-plus"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                        {/* <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-more"></i></a>*/} </div>
                    <h3>{props.title ? props.title : <Translate text={'Inbound Activities'} />}</h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    acitivtyTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeAcitivtyTag === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveactivity(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >{<Translate text={rec.toLocaleLowerCase()} />}
                                                                {!_.isEmpty(activityCounts) ?
                                                                    rec === 'Overdue' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.overdue ? activityCounts.overdue : '0'}</span>)
                                                                        : rec === 'Today' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.today ? activityCounts.today : '0'}</span>)
                                                                            : rec === 'Upcoming' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.upcoming ? activityCounts.upcoming > 99 ? '99+' : activityCounts.upcoming : '0'}</span>)
                                                                                : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            activitylogs[activeAcitivtyTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            activitylogs[activeAcitivtyTag].map((log, index) => {
                                                                return <ActivityView
                                                                    key={index}
                                                                    title={log.title}
                                                                    activityDate={log.startDate}
                                                                    id={log.documentID}
                                                                    type={log.subType}
                                                                    addedBy={CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner, log.addedByName)}
                                                                    handleEditactivity={handleEditactivity}
                                                                    handleComplete={handleComplete}
                                                                    completed={log.isDone}
                                                                    activitytag={activeAcitivtyTag}
                                                                    dealersettings={props.dealersettings}
                                                                    handleDeleteactivity={handleDeleteactivity}
                                                                    activitylog={log}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && activitylogs[activeAcitivtyTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No activities'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                hasMore[activeAcitivtyTag] ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeAcitivtyTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={selectedActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                    roleIDs={roleIDs}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : null}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    isScheduleActivity={true}
                    roleIDs={roleIDs}
                // activeTab={activeTab}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherActivity}
                    searchText={searchText}
                    showSearchText={false}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                        }
                        else {
                            setUser(null)
                        }
                    }}
                    setSearchText={(e) => {
                        setSearchText(e.target.value)
                    }}
                    applyFilter={() => {
                        let dataload = { "Overdue": true, "Today": true, "Upcoming": true }
                        setFilterPopOver({ show: false, target: '' })
                        setHasMoreData([])
                        dispatch({
                            type: "CLEAR_ALL"
                        });
                        setDataload({
                            ...dataload,
                            [activeAcitivtyTag]: true
                        })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default InboundActivitiesCount;