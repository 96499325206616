/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
/** VIEW-MODELS */
import { objStock } from '../stock/viewModel'


const TestDriveInfo = ({ testdrive, stockData, handleEditTestDrive, isDeivered, dealersettings }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        documentID, startDate, endDate, enquiryID,
        expectedReturn, status, dealerName
    } = testdrive;

    useEffect(() => {
        if (testdrive.stockID && (!_.isEmpty(stockData))) {
            const refTDStockData = window.firebase.firestore().collection('stock').doc(testdrive.stockID);
            refTDStockData.get().then((_testStock) => {
                if (_testStock.exists) {
                    const _stock = Object.assign({}, objStock);
                    const objTDStockData = Object.assign({}, _testStock.data());
                    for (let [key, value] of Object.entries(objTDStockData)) {
                        _stock[key] = value;
                    }
                    _stock.documentID = testdrive.stockID;
                    testdrive.stock = _stock;
                }
                else {
                    console.error("No such document!");
                }
            });
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleViewReport = () => {
        if (documentID) {
            const url = `/fleet/details/${documentID}`;
            window.open(url, '_blank');
        }
        else {
            Swal.fire(CommonHelper.showLocale({ dealersettings }, 'No report available.'), '', 'info')
        }

        setEditshow(false);
    }

    return (


        <div className="vehicle-item" id={'testD_' + documentID}>

            <div className="quickview-activity-more">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setEditshow(!editshow)
                    if (editshow)
                        document.addEventListener('click', setEditshow(false));
                }}><i className="ico icon-more"></i></a>
                <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                    <ul className="more-dropdown">
                        <li> <a href="#" onClick={(e) => { e.preventDefault(); handleEditTestDrive(documentID, status) }}>
                            {
                                (status === 'complete' || isDeivered)
                                    ?
                                    <><i className="ico icon-zoom"></i>  <Translate text={'view'} /></>
                                    :
                                    <><i className="ico icon-edit"></i>  <Translate text={'edit'} /></>
                            }
                        </a>
                        </li>
                        {
                            status === 'complete'
                                ?
                                <li> <a href="#" onClick={(e) => { e.preventDefault(); handleViewReport(); }}> <i className="ico icon-waiver"></i>  <Translate text={'report'} /></a> </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </div>

            <div className="testdrive-listing-tab flex-vehicle">
                <div className="vehicle-item-image">
                    {
                        (!_.isEmpty(testdrive.stock) && !_.isEmpty(testdrive.stock.images))
                            ?
                            <>
                                {
                                    testdrive.stock.images.map((data, index) => (
                                        <a key={index} data-fancybox={`testgl_info_img_slider${documentID}`} className={index === 0 ? '' : 'hide'} href={data} onClick={(e) => { e.preventDefault(); }}>
                                            {index === 0 ?
                                                <>
                                                    <div className="zoom-ico"><i className="ico icon-zoom"></i></div>
                                                    <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                </>
                                                : <></>}
                                        </a>
                                    ))
                                }
                            </>
                            :
                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                <img src={CommonHelper.showBrandLogo(dealersettings, testdrive?.stock?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                            </a>
                    }
                </div>
                <div className="vehicle-data" onClick={(e) => { e.preventDefault(); handleEditTestDrive(documentID, status) }}>
                    <div className="vehicle-item-title">{testdrive.stock.make} {testdrive.stock.model}</div>
                    {dealerName ? <div className="dealer-group-title blue-color">{dealerName}</div> : <></>}
                    <div className="vehicle-item-info">{testdrive.stock.exteriorColor ? `${testdrive.stock.exteriorColor},` : ''} {testdrive.stock.year ? testdrive.stock.year : ''}</div>
                    <div className="testdrive-item-status">
                        {testdrive.stock.saleType ? <div className="badge badge-pill badge-white">
                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                testdrive.stock.saleType, '')}
                        </div> : <></>}
                        <div className={`badge badge-pill badge-testdrive-${status ? status : 'pending'}  ml-1`}>{status ? status.toUpperCase() : 'pending'}</div>
                    </div>

                </div>
            </div>
            <div className="testdrive-duration" onClick={(e) => { e.preventDefault(); handleEditTestDrive(documentID, status) }}>
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <td width="50%"><Translate text={'startTime'} /></td>
                            <td width="50%"><Translate text={status === 'pending' ? 'expectedReturnTime' : 'endTime'} /></td>
                        </tr>
                        <tr>
                            <td className="label-duration">{(!_.isEmpty(startDate)) ? moment.unix(startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                            {status === 'pending'
                                ?
                                <td className="label-duration">{(!_.isEmpty(expectedReturn)) ? moment.unix(expectedReturn.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                                :
                                <td className="label-duration">{(!_.isEmpty(endDate)) ? moment.unix(endDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</td>
                            }

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    )
};

export default TestDriveInfo;
