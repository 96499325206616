/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
import UnionDailyActivityReport from '../union/dailyReports';
import DailyActivityReport from '../generic/dailyReports';
import DynamicReport from '../../dynamicReport';
import SalesProgressBMW from '../../bmw/salesProgressBMW/index.js';
import SalesFunnelBMW from '../../bmw/salesFunnelBMW/index.js';
import SummaryReportBMW from '../../bmw/summaryReportBMW/index';
import SummaryReportByUsers from '../../bmw/summaryReportByUsers/monthlySummaryByUser'
import AdamasDailyActivityReport from '../adamas/dailyReports';
import GulsonDailyActivityReport from '../gulson/dailyReports'
import BmwDailyActivityReport from '../bmw/dailyReports';
import PorscheDailyActivityReport from '../porsche/dailyReports';
import LeadSalesAnalysis from '../../bmw/leadsalesAnalysis';
import SummaryReport from '../../bmw/summaryReport'
import SummaryReportUsers from '../../bmw/summaryReportUsers'
import LeadSource from '../../bmw/leadSource';
import OEMDynamicReport from '../../dynamicReport/oemReport'
import OEMSummaryReport from '../../oem/summaryReport'
import EmailViewBrowser from '../../../email'
import DealerTargets from '../../dealerTarget'
import ScheculePerformanceReport from '../../../dailyCheckout/scheduleReport'
import TonyLeaheyDailyActivityReport from '../tonyLeahey/dailyReports'
import TriggerActivityReport from '../../triggerActivity'
import SummaryDailyActivityReport from '../summary/dailyReports';
import StatusUpdate from '../../terryShield/statusUpdate';

import AcquisitionReport from '../../../acquisition/acqReport'
import RosterReport from '../../../roster/rosterReport'
import UserMgmtReport from '../../oem/usersReport'
import WeeklyActivityReportPDF from '../../dynamicReport/mclarenweeklyreport';
import ServiceDynamicReport from '../../dynamicReport/serviceReport';
import MarketingReport from '../../dynamicReport/marketingROI.js';
import YearlyPerformance from '../../dynamicReport/yearlyPerformance.js';
import YearlySalesPerformance from '../../dynamicReport/salesPerformance.js';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/dailyactivity/union/:id" exact render={(match) => <UnionDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/report/:id" exact render={(match) => <DailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/adamas/:id" exact render={(match) => <AdamasDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/bmw/:id" exact render={(match) => <BmwDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/porsche/:id" exact render={(match) => <PorscheDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/gulson/:id" exact render={(match) => <GulsonDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/tonyleahey/:id" exact render={(match) => <TonyLeaheyDailyActivityReport clientid={match.match.params.id} />} />
                        <Route path="/triggeractivity/report/:id" exact render={(match) => <TriggerActivityReport clientid={match.match.params.id} />} />
                        <Route path="/dailyactivity/summary/:id" exact render={(match) => <SummaryDailyActivityReport clientid={match.match.params.id} />} />


                        <Route path="/dynamic/report/:id" exact render={(match) => <DynamicReport reportid={match.match.params.id} />} />
                        <Route path="/dynamic/report/service/:id" exact render={(match) => <ServiceDynamicReport reportid={match.match.params.id} />} />
                        <Route path="/summary/report/byseries/:id" exact render={(match) => <SummaryReportBMW clientid={match.match.params.id} />} />
                        <Route path="/summary/report/byusers/:id" exact render={(match) => <SummaryReportByUsers clientid={match.match.params.id} />} />
                        <Route path="/summary/report/salesprogress/:id" exact render={(match) => <SalesProgressBMW clientid={match.match.params.id} />} />
                        <Route path="/summary/report/salesfunnel/:id" exact render={(match) => <SalesFunnelBMW clientid={match.match.params.id} />} />
                        <Route path="/summary/report/leadsalesanalysis/:id" exact render={(match) => <LeadSalesAnalysis clientid={match.match.params.id} />} />
                        <Route path="/summary/report/leadSource/:id" exact render={(match) => <LeadSource clientid={match.match.params.id} />} />

                        <Route path="/summary/report/summaryreport/:id" exact render={(match) => <SummaryReport clientid={match.match.params.id} />} />
                        <Route path="/summary/report/summaryreportusers/:id" exact render={(match) => <SummaryReportUsers clientid={match.match.params.id} />} />

                        <Route path="/dynamic/report/oem/:id" exact render={(match) => <OEMDynamicReport reportid={match.match.params.id} />} />
                        <Route path="/summary/report/oem/:id" exact render={(match) => <OEMSummaryReport oemid={match.match.params.id} />} />

                        <Route path="/dealertargets/report/:id" exact render={(match) => <DealerTargets clientid={match.match.params.id} />} />
                        <Route path="/email/preview/:id" exact render={(match) => <EmailViewBrowser id={match.match.params.id} />} />

                        <Route path="/dynamic/report/performance/:id" exact render={(match) => <ScheculePerformanceReport clientid={match.match.params.id} />} />
                        <Route path="/dynamic/report/acquisition/:id" exact render={(match) => <AcquisitionReport clientid={match.match.params.id} />} />

                        <Route path="/dynamic/report/roster/:id" exact render={(match) => <RosterReport clientid={match.match.params.id} />} />
                        <Route path="/dynamic/report/oem/users/:id" exact render={(match) => <UserMgmtReport oemid={match.match.params.id} />} />

                        <Route path="/dynamic/report/statusupdate/:id" exact render={(match) => <StatusUpdate clientid={match.match.params.id} />} />

                        <Route path="/dynamic/report/weeklyactivity/:id" exact render={(match) => <WeeklyActivityReportPDF reportid={match.match.params.id} />} />

                        <Route path="/dynamic/report/marketing/:id" exact render={(match) => <MarketingReport reportid={match.match.params.id} />} />
                        <Route path="/dynamic/report/yearlyperformance/:id" exact render={(match) => <YearlyPerformance clientid={match.match.params.id} />} />
                        <Route path="/dynamic/report/salesperformance/:id" exact render={(match) => <YearlySalesPerformance clientid={match.match.params.id} />} />

                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;