/** LIBRARIES */
import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
//import NumberFormat from 'react-number-format';
//import { collectionData, docData } from 'rxfire/firestore';
//import { combineLatest } from 'rxjs';
import "react-big-calendar/lib/css/react-big-calendar.css";

import Translate from '../../constants/translate';
import CommonHelper from '../../services/common'
import CustomToolbar from './toolbar'
import CustomEvent from './eventView'
import CustomAgendaView from './agendaView'

import { objActivitylog, objWalkIn } from '../pipeline/viewModel';
import { PopUpModal, UpgradeModal } from '../../components'
import AddActivity from '../activity'
import FilterPanel from './filterPanel'
import AcitivityReducer from './activityReducer'
import { activityListVM } from './viewModel'
import { enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

const localizer = momentLocalizer(moment);



const CalendarUI = (props) => {
    const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 123)
    const [activitylogs, dispatch] = useReducer(AcitivityReducer, [])
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [tradeinProObj, setTradeinProObj] = useState(null);
    const [serviceObj, setServiceObj] = useState(null);
    const [stockObj, setStockObj] = useState(null);
    const [viewstate, setViewState] = useState('day')
    const [activeTab, setActivetab] = useState(CommonHelper.isJSON(localStorage.eventFilter) && _.isArray(JSON.parse(localStorage.eventFilter)) ? JSON.parse(localStorage.eventFilter) : ['all']);
    //const [showpopover, setShowPopover] = useState(false)
    //const [targetpopover, setTargetPopover] = useState()
    const [slotDate, setSlotdate] = useState(null)
    const [monthData, setMonthData] = useState(moment()._d.getMonth())
    const [yearData, setYearData] = useState(moment().year())
    const [isNewActivity, setNewActivity] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.eventSearchText ? localStorage.eventSearchText : '')

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [checkDataLoad, setDataload] = useState(true)
    //const [pageNum, setPageNum] = useState(0)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)

    const [titles, setTitles] = useState([])
    //const [languages, setLanguages] = useState([])
    //const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [localFilter, setLocalFilter] = useState()
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day')
    })
    const [loader, setLoader] = useState(true);
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const isOutlookCalendarExpired =
        !_.isEmpty(props.dealersettings)
            && !_.isEmpty(props.dealersettings.outlookCalendar)
            && props.dealersettings.outlookCalendar.refreshTokenExpired === true ? true : false

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);
    const workingHours = (props.dealersettings?.client?.clientSettings?.scheduleActWithInWHrs && props.dealersettings?.client?.workingHours) ? props.dealersettings.client.workingHours : null;
    const workingStartHour = workingHours && workingHours.split(';')[0] ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[0]}`).hours() : 6
    const workingEndHour = workingHours && workingHours.split(';')[1] ? moment(`${moment().format('YYYY-MM-DD')} ${workingHours.split(';')[1]}`).hours() : 22

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload(false)
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);

            setTitles(_titles);
            //setLanguages(_languages);
            //setNationalities(_nationalities)
            //setDataload(true)
        }
    }, [props.dealersettings])

    useEffect(() => {
        function handleResize() {
            setCaleandarHeight(window.innerHeight - 123)
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unrefActivitiesData && window.unrefActivitiesData();
        }
    }, []);


    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'activities')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)
            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    // useEffect(() => {
    //     // if (!checkDataLoad) {
    //     //     return;
    //     // }

    //     if (!props.dealersettings) {
    //         //console.log('!props.dealersettings')
    //         return;
    //     }
    //     const { dealersettings } = props;
    //     const viewOtherActivity = ((!_.isEmpty(dealersettings) &&
    //         !_.isEmpty(dealersettings.rolePermissions) &&
    //         !_.isEmpty(dealersettings.rolePermissions.permissions) &&
    //         dealersettings.rolePermissions.permissions.viewOtherActivity) ? true : false);

    //     // if (localStorage.activityFilter && isValidFilter()) {
    //     //     let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
    //     //     let _activityFilter = JSON.parse(localStorage.activityFilter);
    //     //     let _filter = Object.assign({}, _activityFilter.value);

    //     //     // if (!_filter.owner)
    //     //     //     _filter.owner = localStorage.uid;

    //     //     _filter.type = 'activity';

    //     //     /* DATE RANGE FILTER */
    //     //     if (!_.isEmpty(_filter.date)) {
    //     //         _filter = CommonHelper.handleDateRangeFilter(_filter);
    //     //         delete _filter['date'];
    //     //     }

    //     //     if (!viewOtherActivity)
    //     //         _filter.owner = localStorage.uid;

    //     //     if (!_.isEmpty(_filter.subType)) {
    //     //         setActivetab(_filter.subType.split(','))
    //     //         localStorage.eventFilter = JSON.stringify(_filter.subType.split(','));
    //     //     }
    //     //     else if (_.isEmpty(_filter.subType) && activeTab && !_.isEmpty(activeTab.some(e => e !== 'all'))) {
    //     //         _filter.subType = activeTab.join(',');
    //     //     }

    //     //     console.log('isFilterApplied', isFilterApplied, _filter)
    //     //     const addContact = window.firebase.functions().httpsCallable('generic-searchData');
    //     //     addContact({
    //     //         "type": "searchActivities",
    //     //         "clientID": clientID,
    //     //         "filters": JSON.stringify(_filter),
    //     //         //"sortOrder": "stageDate desc",
    //     //         "pageNum": 0,
    //     //         "pageLimit": 1000
    //     //     }).then((response) => {
    //     //         //console.log('generic-searchData', _filter, response);
    //     //         if (response.data.success && localStorage.activityFilter && isValidFilter()) {
    //     //             onCollectionEnquiry(response.data);
    //     //         }
    //     //         else
    //     //             setDataload(false)
    //     //     });

    //     //     window.unrefActivitiesData && window.unrefActivitiesData();
    //     // }
    //     // else {

    //     let searchKeywords = searchText;
    //     let searchOwners = [];
    //     let searchTypes = '';

    //     if (localStorage.activityFilter) {
    //         let _activityFilter = JSON.parse(localStorage.activityFilter);
    //         let _filter = Object.assign({}, _activityFilter.value);

    //         searchTypes = _filter['subType'] ? _filter['subType'] : '';
    //         searchOwners = _filter['owner'] ? _filter['owner'] : '';

    //         setSearchText(searchKeywords)
    //         localStorage.eventSearchText = searchKeywords;
    //         //console.log('search---types', searchTypes, '--', searchOwners, '--', searchKeywords)
    //     }

    //     //console.log('isFilterApplied-not', isFilterApplied)
    //     let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
    //         .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').month(monthData).year(yearData).add(-7, 'day').startOf('day')._d))
    //         .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').month(monthData).year(yearData).add(7, 'day').endOf('day')._d))
    //         .where('type', '==', 'activity')
    //         .orderBy('startDate', 'desc')


    //     if (localStorage.defaultModule === 'oem') {
    //         // refActivitiesData = refActivitiesData
    //         //     .where('clientID', '==', props.dealersettings.client.id)
    //         //     .where('isDeleted', '==', false)
    //         refActivitiesData = refActivitiesData
    //             .where('levelID', '==', (props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
    //                 props.dealersettings.level === 'region' ? props.dealersettings.regionID :
    //                     props.dealersettings.level === 'group' ? props.dealersettings.groupID :
    //                         props.dealersettings.client.id))
    //         //.where('level', '==', props.dealersettings.level)
    //     }
    //     else {
    //         refActivitiesData = refActivitiesData
    //             .where('clientID', '==', props.dealersettings.client.id)
    //             .where('isDeleted', '==', false)
    //     }
    //     // if (searchKeywords) {
    //     //     refActivitiesData = refActivitiesData
    //     //         .where('keywords', 'array-contains', searchKeywords.toLowerCase())
    //     // }

    //     if (!viewOtherActivity) {
    //         refActivitiesData = refActivitiesData
    //             .where('searchUsers', 'array-contains', localStorage.uid)
    //     }
    //     // else if (!_.isEmpty(searchOwners))
    //     //     refActivitiesData = refActivitiesData.where('searchUsers', 'array-contains', searchOwners.split(','))

    //     window.unrefActivitiesData = refActivitiesData
    //         .onSnapshot(onCollectionUpdate);

    //     if (!_.isEmpty(searchTypes)) {
    //         setActivetab(searchTypes.split(','))
    //         localStorage.eventFilter = JSON.stringify(searchTypes.split(','));
    //     }

    //     //}

    // }, [monthData])


    //by postgres fucntion
    useEffect(() => {
        if (!props.dealersettings || !checkDataLoad) {
            return;
        }
        const { dealersettings } = props;
        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);
        const viewOtherActivity = ((!_.isEmpty(_permissions) && _permissions.viewOtherActivity) ? true : false);
        const accessToFinanceActivity = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceActivity) ? true : false);
        const accessToAfterMarketActivity = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketActivity) ? true : false);

        let searchKeywords = searchText;
        let searchOwners = [];
        let searchTypes = '';

        if (localStorage.activityFilter) {
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filter = Object.assign({}, _activityFilter.value);

            searchTypes = _filter['subType'] ? _filter['subType'] : '';
            searchOwners = _filter['owner'] ? _filter['owner'] : '';

            setSearchText(searchKeywords)
            localStorage.eventSearchText = searchKeywords;
            //console.log('search---types', searchTypes, '--', searchOwners, '--', searchKeywords)
        }

        let _filter = {
            startDate: dateRange.startDate.format('YYYY-MM-DD'),
            endDate: dateRange.endDate.format('YYYY-MM-DD'),
        }

        if (localStorage.defaultModule === 'oem') {
            _filter.level = props.dealersettings.level;
            _filter.levelID = (props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                    props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                        props.dealersettings.client.id)
        }
        else if (localStorage.defaultModule === 'tradeinPro') {
            _filter.clientID = CommonHelper.getTradeinProDealers(props.dealersettings, false, true);
        }
        else {
            _filter.clientID = props.dealersettings.client.id;
        }

        if (!viewOtherActivity) {
            _filter.owner = localStorage.uid
        }
        if (activeTab && !activeTab.some(a => a === 'all')) {
            _filter.subType = activeTab.join(',');
        }

        if (!_.isEmpty(localStorage.activityFilter)) {
            _filter = {
                ..._filter,
                ...JSON.parse(localStorage.activityFilter).value
            }
        }

        let _modules = ['tradeinPro', 'service']
        if (_modules.includes(localStorage.defaultModule))
            _filter.module = localStorage.defaultModule
        else if (accessToFinanceActivity || accessToAfterMarketActivity) {
            let _type = ['activity']
            if (accessToFinanceActivity) _type.push('financeactivity')
            if (accessToAfterMarketActivity) _type.push('afterMarketactivity')
            _filter.type = _type.join(',');
        }


        if (viewstate === 'month' || viewstate === 'agenda') {
            _filter.monthView = 'true'
        }

        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            type: "getCalendarEvents",
            params: JSON.stringify(_filter),
        }
        setLoader(true);
        const refActivitiesData = window.firebase.functions().httpsCallable('generic-getData');
        refActivitiesData(_searchObject).then(resp => {
            setLoader(false);
            if (resp.data.success) {
                onCollectionUpdate(resp.data.data)
            }
            else {
                setDataload(false)
                Swal.fire(resp.data.message, '', 'error')
            }
        })




        if (!_.isEmpty(searchTypes)) {
            setActivetab(searchTypes.split(','))
            localStorage.eventFilter = JSON.stringify(searchTypes.split(','));
        }

        //}

    }, [checkDataLoad])


    const isValidFilter = () => {
        if (localStorage.activityFilter) {
            let _activityFilter = JSON.parse(localStorage.activityFilter);
            let _filter = Object.assign({}, _activityFilter.value);

            // if (Object.keys(_filter).length === 3 && (_filter.hasOwnProperty('subType') && _filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
            //     return false;

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('subType') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
            //     return false;

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('subType') && _filter.hasOwnProperty('keywords')))
            //     return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('subType') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length === 1)))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = convertLogVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         });
        //         // snapshotDoc.start = moment.unix(snapshotDoc.startDate.seconds).toDate();
        //         // snapshotDoc.end = moment.unix(snapshotDoc.endDate.seconds).toDate();

        //         if (change.type === 'added') {
        //             actionType = "ADDNEWLOG";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATELOG"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVELOG"
        //         }
        //     }
        // })
        const _activities = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = convertLogVM(doc.data)
                _activities.push(logVM)
            });
        }
        // dispatch({
        //     type: "SUCCESS",
        //     data: _activities
        // });

        dispatch({
            type: "SUCCESS",
            data: _activities
        });

        setDataload(false)
    }

    /*const onCollectionEnquiry = (querySnapshot) => {

        const _activities = [];
        querySnapshot.data.forEach(function (doc) {
            var logVM = convertLogVM(doc);
            _activities.push(logVM)
        });

        dispatch({
            type: "SUCCESS",
            data: _activities,
        });

        setDataload(false)

    }*/

    const convertLogVM = (doc) => {

        doc.startDate = !_.isObject(doc.startDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate)._d) : doc.startDate;
        doc.endDate = !_.isObject(doc.endDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate)._d) : doc.endDate;

        const objlogData = Object.assign({}, doc);
        const logVM = Object.assign({}, activityListVM);
        for (let [key, value] of Object.entries(objlogData)) {
            if (logVM.hasOwnProperty(key))
                logVM[key] = value;
        }
        logVM.start = moment.unix(doc.startDate.seconds).toDate()
        logVM.end = moment.unix(doc.endDate.seconds).toDate()
        logVM.ownerName = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        logVM.createdByName = CommonHelper.getUserNamebyId(allUsers, doc.addedBy);
        logVM.isDone = doc.isDone === 'true' || doc.isDone === true
        if (doc.owner) {
            logVM.resourceId = doc.owner;
            logVM.resourceTitle = CommonHelper.getUserNamebyId(allUsers, doc.owner);
        }

        if (!_.isEmpty(doc.contact)) {
            logVM.contact = doc.contact
            logVM.contactName = CommonHelper.displayFullContact(titles, doc.contact);
        }

        logVM.searchUsers = doc.searchUsers;
        if (!_.isEmpty(logVM.searchUsers) && _.isString(logVM.searchUsers)) {
            logVM.allUsers = JSON.parse(logVM.searchUsers)
        }
        else if (_.isArray(logVM.teammates)) {
            logVM.allUsers = logVM.teammates
        }

        return logVM
    }

    useEffect(() => {
        if (!showActivityModel) {
            return
        }
        let newlogVM = Object.assign({}, objActivitylog);

        newlogVM.type = CommonHelper.getActivityType('activity');
        newlogVM.subType = 'appointment';
        if (slotDate) {
            newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment(slotDate)._d);
            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment(slotDate).add(1, 'hours')._d);
        }
        else {
            newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment().add(1, 'hours')._d);
        }
        newlogVM.title = 'Appointment';
        newlogVM.addedByName = CommonHelper.getUserNamebyId(allUsers, localStorage.uid);
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        //newlogVM.clientID = props.dealersettings.client.id;
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
        setNewActivitylog(newlogVM)
    }, [showActivityModel])

    const onSelectEvent = useCallback(async (eventData) => {
        const eventDoc = await firestoreDB(props.dealersettings).firestore().doc('activities/' + eventData.documentID).get();
        let event = eventDoc.exists ? { ...eventDoc.data(), documentID: eventDoc.id } : eventData;
        if (event.enquiryID) {
            if (!_.isEmpty(event.enquiry)) {
                setEnquiryObj(event.enquiry)
            }
            else {
                const enqDoc = await firestoreDB(props.dealersettings).firestore().doc('enquiries/' + event.enquiryID).get();
                if (enqDoc.exists) {
                    const enqData = enqDoc.data()
                    enqData.documentID = enqDoc.id;
                    setEnquiryObj(enqData)
                }
            }

        }

        if (event.tradeinProID) {
            if (!_.isEmpty(event.tradeinPro)) {
                setTradeinProObj(event.tradeinPro);
            } else {
                const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('tradeinPro/' + event.tradeinProID)
                    .get();
                if (enquiryDoc.exists) {
                    const enquiryData = enquiryDoc.data();
                    enquiryData.documentID = enquiryDoc.id;
                    setTradeinProObj(enquiryData);
                }
            }
        }

        if (event.serviceID) {
            if (!_.isEmpty(event.service)) {
                setServiceObj(event.service);
            } else {
                const _doc = await firestoreDB(props.dealersettings).firestore().doc('serviceJobs/' + event.serviceID)
                    .get();
                if (_doc.exists) {
                    const _objData = _doc.data();
                    _objData.documentID = _doc.id;
                    setServiceObj(_objData);
                }
            }
        }
        if (event.contactID) {
            if (!_.isEmpty(event.contact)) {
                setContactObj(event.contact)
            }
            else {
                const contactDoc = await firestoreDB(props.dealersettings).firestore().doc('contactDetails/' + event.contactID).get();
                if (contactDoc.exists) {
                    const contactData = contactDoc.data()
                    contactData.documentID = contactDoc.id;
                    setContactObj(contactData)
                }
            }
        }
        if (event.stockID) {
            if (!_.isEmpty(event.stock)) {
                setStockObj(event.stock)
            }
            else {
                const stockDoc = await window.firebase.firestore().doc('stock/' + event.stockID).get();
                if (stockDoc.exists) {
                    const stockData = stockDoc.data()
                    stockData.documentID = stockDoc.id;
                    setStockObj(stockData)
                }
            }
        }
        const logVM = Object.assign({}, objActivitylog);
        for (let [key, value] of Object.entries(event)) {
            if (logVM.hasOwnProperty(key))
                logVM[key] = value;
        }
        setSlotdate(null);
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setNewActivitylog(logVM)
        setShowActivityModel(true)
    }, [])

    const handleDeleteactivity = useCallback((e, objLog) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                delete objLog.searchUsers;
                objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
                objLog.modifiedBy = localStorage.uid;
                objLog.isDeleted = true;
                //setSectionLoading(true)
                firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    isDeleted: true
                }, { merge: true })
                    .then(snapshot => {
                        toast.notify('Log deleted successfully', {
                            duration: 2000
                        })

                        let logNotes = 'Log deleted';
                        if (!_.isEmpty(objLog) && objLog.type)
                            logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'activities',
                            subType: 'delete',
                            recordId: objLog.documentID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);

                        if (objLog) {
                            dispatch({
                                type: "REMOVELOG",
                                data: objLog
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }, [])

    const handleActivityModelDelete = (objLog) => {
        setSlotdate(null);
        setNewActivitylog(null);
        setEnquiryObj(null)
        setTradeinProObj(null)
        setServiceObj(null);
        setContactObj(null)
        setStockObj(null)
        setShowActivityModel(false)
        if (objLog) {
            dispatch({
                type: "REMOVELOG",
                data: objLog
            });
        }
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setSlotdate(null);
        setNewActivitylog(null);
        setEnquiryObj(null)
        setTradeinProObj(null)
        setServiceObj(null);
        setContactObj(null)
        setStockObj(null)
        setShowActivityModel(false)
        if (objLog) {
            dispatch({
                type: activitylogs.some(a => a.documentID === objLog.documentID) ? "UPDATELOG" : "ADDNEWLOG",
                data: convertLogVM(objLog)
            });
        }
        if (objLog && objLog.isDone === true && !isActivtyAlreadyDone) {
            if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                Swal.fire({
                    title: CommonHelper.showLocale(props, 'Please select'),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                    cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set({
                            bdcStatus: result.value ? 'show' : 'noshow'
                        }, { merge: true })
                    shedulenextactivity(objLog);
                    if (result.value)
                        logWalkin(objLog.enquiryID)

                    CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                });
            }
            else {
                shedulenextactivity(objLog);
            }
        }
    }

    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {

            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                    setEnquiryObj(objLog.enquiry ? objLog.enquiry : null)
                    setContactObj(objLog.contact ? objLog.contact : null)
                }
            })
        }
    }

    const handleScheduledActivityModelClose = (objLog) => {
        setSlotdate(null);
        setNewActivitylog(null);
        setEnquiryObj(null)
        setTradeinProObj(null)
        setServiceObj(null);
        setContactObj(null)
        setStockObj(null)
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null,
        })
        if (objLog) {
            dispatch({
                type: activitylogs.some(a => a.documentID === objLog.documentID) ? "UPDATELOG" : "ADDNEWLOG",
                data: convertLogVM(objLog)
            });
        }
    }

    const handleChangeviewstate = (view) => {
        localStorage.setItem('calendarView', view)
        setViewState(view);
    }

    const handleSelectSlot = ({ start, end }) => {
        if (viewstate === 'month') {
            start = moment(moment(start).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d;
        }
        setSlotdate(start);
        setEnquiryObj(null)
        setTradeinProObj(null)
        setServiceObj(null);
        setContactObj(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    const handleAddActivity = () => {

        // const clientSettings = (!_.isEmpty(props.dealersettings) &&
        //     !_.isEmpty(props.dealersettings.client) &&
        //     !_.isEmpty(props.dealersettings.client.clientSettings)) ? props.dealersettings.client.clientSettings : null;
        // const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion) ? true : false;
        // if (liteVersion && localStorage.defaultModule !== 'oem') {
        //     setShowUpgradeModal(true);
        //     return;
        // }

        setSlotdate(null);
        setEnquiryObj(null)
        setTradeinProObj(null)
        setServiceObj(null);
        setContactObj(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
        //console.log('Dealer Settings', props.dealersettings)
        //console.log('client Users', props.clientUsers)
        //console.log('group Users', props.groupUsers)
    }

    const handleActivityFilterApply = () => {
        if (!_.isEmpty(localStorage.eventFilter) && !_.isEmpty(localStorage.activityFilter)) {
            let response = JSON.parse(localStorage.activityFilter);
            let activeList = JSON.parse(localStorage.eventFilter);
            if (response.value && activeList) {
                if (activeList.some(e => e !== 'all'))
                    response.value['subType'] = JSON.parse(localStorage.eventFilter).join(',')
                else
                    response.value['subType'] = "";
                handleApplyFilter(response);
            }
        }
    }

    const handleApplyFilter = (_filter) => {

        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('activityFilter', JSON.stringify(_filter));
            localStorage.eventSearchText = _filter.value['keywords'];
            setSearchText(_filter.value['keywords']);
            setFilterApplied(true);
            setLocalFilter(window.firebase.firestore.Timestamp.now().seconds.toString())
            setDataload(true)
            if (_filter.value['subType']) {
                setActivetab(_filter.value['subType'].split(','))
                localStorage.eventFilter = JSON.stringify(_filter.value['subType'].split(','));
            }
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {

        if (!_.isEmpty(localStorage.activityFilter)) {
            localStorage.removeItem('activityFilter');
            localStorage.eventSearchText = '';
            setSearchText('');
            setFilterApplied(false);
            setDataload(true);

            setActivetab(['all']);
            localStorage.eventFilter = JSON.stringify(['all']);
        }
    }

    // const activityList = useMemo(() => activitylogs.filter(e => e.isDeleted === false)
    //     .filter(
    //         ({ subType, title, notes, isDone, owner, contactName, phone, contactEmail, searchUsers }) => ((!activeTab.some(e => e === 'all') ? activeTab.some(e => e === subType) : true)
    //             && (localStorage.activityFilter && JSON.parse(localStorage.activityFilter).value && JSON.parse(localStorage.activityFilter).value.keywords ? ((title ? title.toLowerCase().includes(JSON.parse(localStorage.activityFilter).value.keywords.toLowerCase()) : false)
    //                 || (notes ? notes.toLowerCase().includes(JSON.parse(localStorage.activityFilter).value.keywords.toLowerCase()) : false)
    //                 || (contactName ? contactName.toLowerCase().includes(JSON.parse(localStorage.activityFilter).value.keywords.toLowerCase()) : false)
    //                 || (phone ? phone.toLowerCase().includes(JSON.parse(localStorage.activityFilter).value.keywords.toLowerCase()) : false)
    //                 || (contactEmail ? contactEmail.toLowerCase().includes(JSON.parse(localStorage.activityFilter).value.keywords.toLowerCase()) : false)
    //             ) : true)
    //             && (localStorage.activityFilter && JSON.parse(localStorage.activityFilter).value && JSON.parse(localStorage.activityFilter).value.isDone ? isDone === Boolean(JSON.parse(localStorage.activityFilter).value.isDone) : true)
    //             && (localStorage.activityFilter && JSON.parse(localStorage.activityFilter).value && JSON.parse(localStorage.activityFilter).value.owner ? _.filter(JSON.parse(localStorage.activityFilter).value.owner.split(','), (v) => _.indexOf(searchUsers, v) >= 0).length > 0 : true)

    //         )
    //     )
    //     .sort(function (x, y) {
    //         return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
    //     }), [activeTab, activitylogs, localStorage.activityFilter, localFilter])

    const getResource = () => {
        return viewstate === 'day' && activitylogs.filter(a => !_.isEmpty(a.ownerName)).length > 0 ? {
            resources: _.uniqBy(allUsers, 'id').filter(a => _.uniqBy(activitylogs, 'owner').map(r => { return r.owner }).includes(a.id)).map(rec => {
                return {
                    resourceId: rec.id,
                    resourceTitle: <div className="user-calendar-day-cell">
                        <div className="user-calendar-avatar">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" />
                        </div>
                        {rec.name}
                    </div>,
                }
            }),
            resourceIdAccessor: "resourceId",
            resourceTitleAccessor: "resourceTitle"
        } : {}
    }

    return (
        <div className="middle-wrapper">
            <FilterPanel
                handleAddActivity={handleAddActivity}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                history={props.history}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                searchText={searchText}
                isOutlookCalendarExpired={isOutlookCalendarExpired}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setDataload(true);
                    if (!_.isEmpty(localStorage.activityFilter))
                        localStorage.removeItem('activityFilter');

                }}
            />
            <div className="calendar-panel calendar-border-none">
                {
                    loader
                        ?
                        <>
                            <div className="dashboard-widget-loader h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"><Translate text={'Loading...'} /></span>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                        </>
                }
                <Calendar
                    popup={false}
                    localizer={localizer}
                    events={activitylogs}
                    style={{ height: calendarHeight }}
                    views={['day', 'week', 'month', 'agenda']}
                    defaultView={viewstate}
                    formats={{
                        agendaHeaderFormat: ({ start, end }) => {
                            return start.getMonth() !== end.getMonth() ? (moment(start).format('MMMM DD') + ' - ' + moment(end).format('MMMM DD')) : (moment(start).format('MMMM DD') + ' - ' + moment(end).format('DD'));
                        }
                    }}
                    min={moment().set('hour', workingStartHour).set('minute', 0).toDate()}
                    max={moment().set('hour', workingEndHour).set('minute', 0).toDate()}
                    components={{
                        //toolbar: CustomToolbar,
                        toolbar: propstb => (<CustomToolbar {...propstb}
                            dealersettings={props.dealersettings}
                            viewstate={viewstate}
                            activetab={activeTab}
                            handleChangeviewstate={handleChangeviewstate}
                            handlechangefilter={(type) => {
                                setActivetab(type === 'all' ? ['all'] : [...activeTab.filter(e => e !== 'all'), type]);
                                localStorage.eventFilter = JSON.stringify(type === 'all' ? ['all'] : [...activeTab.filter(e => e !== 'all'), type]);

                                if (localStorage.activityFilter && isValidFilter)
                                    handleActivityFilterApply();
                                else
                                    setDataload(true)
                            }}
                            handlechangemonth={(month) => setMonthData(month)}
                            handlechangeyear={(year) => setYearData(year)}
                            monthData={monthData}
                            handleuncheckfilter={(type) => {
                                if (activeTab.filter(e => e !== type).length > 0)
                                    setActivetab(activeTab.filter(e => e !== type));
                                else
                                    setActivetab(['all'])
                                localStorage.eventFilter = JSON.stringify(activeTab.filter(e => e !== type).length > 0 ? activeTab.filter(e => e !== type) : ['all'])

                                if (localStorage.activityFilter && isValidFilter)
                                    handleActivityFilterApply();
                                else
                                    setDataload(true)
                            }}
                            setDateRange={(dateRange) => {
                                setDateRange(dateRange)
                                setDataload(true)
                            }}
                        />),
                        eventWrapper: propsevt => (<CustomEvent {...propsevt}
                            dealersettings={props.dealersettings}
                            onSelectEvent={onSelectEvent}
                            viewstate={viewstate} />),
                        agenda: {
                            event: agendaview => (<CustomAgendaView {...agendaview}
                                {...props}
                                onSelectEvent={onSelectEvent}
                                dealersettings={props.dealersettings}
                                handleDeleteactivity={handleDeleteactivity}
                                clientUsers={props.clientUsers}
                                groupUsers={props.groupUsers}
                            />),
                        }
                    }}
                    onShowMore={(events, date) => {
                        setDateRange({
                            startDate: moment(date).startOf('day'),
                            endDate: moment(date).endOf('day')
                        })
                        setDataload(true)
                        setViewState('day')
                    }}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    dayPropGetter={(date) => {
                        return {
                            className: 'special-day',
                        }
                    }
                    }
                    messages={{
                        showMore: function showMore(total) {
                            return `view more`;
                        }
                    }}
                    {...getResource()}
                // onSelectEvent={onSelectEvent}
                // startAccessor="start"
                // endAccessor="end"
                // messages={{
                //     showMore: total => (
                //         <div
                //             style={{ cursor: 'pointer' }}
                //             onClick={(e) => {
                //                 setShowPopover(true);
                //                 e.stopPropagation();
                //               }}
                //         >{`+${total} more`}
                //         </div>
                //     ),
                // }}
                />
            </div>

            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={newActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    tradeinPro={tradeinProObj}
                    service={serviceObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    slotDate={slotDate}
                    isNewActivity={isNewActivity}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'addNewActivity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    tradeinPro={tradeinProObj ? tradeinProObj : showScheduleActivityModel.tradeinPro ? showScheduleActivityModel.tradeinPro : null}
                    service={serviceObj ? serviceObj : showScheduleActivityModel.service ? showScheduleActivityModel.service : null}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : null}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    slotDate={slotDate}
                    isNewActivity={true}
                    isScheduleActivity={true}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showUpgradeModal}>
                <UpgradeModal
                    show={showUpgradeModal}
                    handleClose={() => {
                        setShowUpgradeModal(false);
                    }}
                />
            </PopUpModal>
        </div>
    )
}

export default CalendarUI;