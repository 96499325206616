
export const objVideos = {
    documentID: null,
    clientID: null,
    projectId: null,
    enquiryID: null,
    contactID: null,
    name: '',
    url: '',
    type: '',
    size: 0,
    isSent: false,
    sentDate: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};


export const mandatoryFields = ['url']