/** LIBRARIES */
import React, { Component } from "react";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ReactDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    /*shouldComponentUpdate(nextProps, nextState) {
        //console.log('shouldComponentUpdate Date Picker', nextProps.value, this.props.value)
        if (nextProps.value !== this.props.value)
            return true;
        else if (nextProps.className !== this.props.className)
            return true;
        else
            return false;

    }*/

    onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }

    render() {
        const { name, onChange, format, placeholder, value, onValueChange, className, minDate, maxDate, readOnly, isClearable, showMonthDropdown, showYearDropdown } = this.props;
        //console.log('RENDER - React Date Picker', name, value)
        return (
            (readOnly === true)
                ?
                <DatePicker
                    ref={el => this.onDatepickerRef(el)}
                    id={name}
                    showPopperArrow={false}
                    placeholderText={placeholder}
                    selected={value && moment(value).isValid() ? moment(value)._d : null}
                    onChange={val => onChange(val, name)}
                    dateFormat={"dd/MM/yyyy"}
                    showMonthDropdown={showMonthDropdown === false ? false : true}
                    showYearDropdown={showYearDropdown === false ? false : true}
                    minDate={minDate}
                    maxDate={maxDate}
                    className={className}
                    //dateFormatCalendar="  "
                    popperClassName="custom-cal-pop"
                    isClearable={isClearable}
                />
                :
                <DatePicker
                    id={name}
                    showPopperArrow={false}
                    placeholderText={placeholder}
                    selected={value && moment(value).isValid() ? moment(value)._d : null}
                    onChange={val => onChange(val, name)}
                    customInput={<NumberFormat
                        format={format}
                        placeholder={placeholder}
                        value={value ? moment(value, "YYYY-MM-DD").format('DD/MM/YYYY') : ''}
                        onValueChange={onValueChange}
                        className={className} />}
                    dateFormat={"dd/MM/yyyy"}
                    //dateFormatCalendar="  "
                    showMonthDropdown={showMonthDropdown === false ? false : true}
                    showYearDropdown={showYearDropdown === false ? false : true}
                    minDate={minDate}
                    maxDate={maxDate}
                    popperClassName="custom-cal-pop"
                    isClearable={isClearable}
                />
        );
    }
}