/** LIBRARIES */
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
/** COMPONENTS */
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
/** VIEW-MODELS */


const ReAuthenticate = (props) => {
    const [currentpassword, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [errorFields, setErrorFields] = useState({})

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setPassword(value);

    }

    const handleSubmit = async () => {

        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(currentpassword)) {
            formIsValid = false;
            errors['password'] = errorClass;
        }
        setErrorFields(errors)

        if (!formIsValid) {
            return;
        }

        setLoading(true);

        const cred = window.firebase.auth.EmailAuthProvider.credential(
            window.firebase.auth().currentUser.email,
            currentpassword,
        );

        window.firebase
            .auth()
            .currentUser.reauthenticateWithCredential(cred)
            .then((currentuser) => {
                setLoading(false);
                //console.log('localStorage.privacyUpdatedOn-b4', localStorage.privacyUpdatedOn);
                if (props.privacy && props.privacy === 'file')
                    localStorage.setItem('privacyFileUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
                else
                    localStorage.setItem('privacyUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);

                props.handleClose('privacyFileUpdatedOn', currentuser.user);
                return;
            })
            .catch(error => {
                setLoading(false);
                if (error.code === 'auth/multi-factor-auth-required') {
                    //console.log('localStorage.privacyUpdatedOn-auth/multi-factor-auth-required-b4', localStorage.privacyUpdatedOn);
                    if (props.privacy && props.privacy === 'file')
                        localStorage.setItem('privacyFileUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);
                    else
                        localStorage.setItem('privacyUpdatedOn', window.firebase.firestore.Timestamp.now().seconds);

                    props.handleClose('privacyFileUpdatedOn');
                    return;
                }
                else {
                    let _errors = {};
                    if (error && error.message)
                        _errors["message"] = error.message;
                    else
                        _errors["message"] = "password is not valid";

                    setErrorFields(_errors)
                    console.log(error);
                }


            });

    }
    const { show, title } = props;
    //console.log('errorFields', errorFields)
    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-authentication-pop">
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Authentication Required'} /> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="user-auth-custom-notify-border">
                        <div className="text-center mt-2">

                            <div className="user-auth-custom-notify-icon"> <i className="ico icon-reserve"></i> </div>

                            <div></div></div>

                        <div className="user-auth-custom-notify-subhead"><Translate text={'User Verification Required for Access'} /> </div>
                        <div className="user-auth-custom-notify-label"><Translate text={'Please enter your ams pro password to unlock'} /></div>
                        <div className="user-auth-custom-notify-content">
                            <input
                                type="password"
                                autoComplete="new-password"
                                className={`form-control ${errorFields['password']}`}
                                name="currentpassword"
                                onChange={handleOnChange}
                                value={currentpassword ? currentpassword : ''}
                                placeholder="Password"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit(e);
                                    }
                                }}
                            />
                            {
                                errorFields["message"]
                                    ?
                                    <div className="authentication-error">{errorFields["message"]}</div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); props.handleClose(null) }}>
                        <Translate text={'cancel'} />
                    </button>
                    <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleSubmit(e)} >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'Unlock'} />
                    </button>
                </Modal.Footer>
            </Modal>






        </>
    );

}
export default ReAuthenticate;