/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash'
/** PROVIDERS */
//import { StageProvider } from '../../pipeline/provider'
/** COMPONENTS */
import { InputText, PopUpModal } from '../../../components';
import CommonHelper from '../../../services/common'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
//import AddFleet from '../../fleet/add'
//import AddTestDrive from '../../testdrive/add'
import AddStock from '../add'
//import QuickView from "../../pipeline/quickview";
/** VIEW-MODELS */
//import { objTestDrive } from '../../testdrive/viewModel'
//import { objFleet } from '../../fleet/viewModel'
//import { objEnquiry, objActivitylog } from '../../pipeline/viewModel';
import { objStock } from '../../stock/viewModel';
import List from './list'

export default class StockHistory extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.refStock = window.firebase.firestore().collection('stock');
        this.unsubscribeStock = null;
        this.newDocID = null;
        this.state = {
            stock: Object.assign({}, objStock),
            stockModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: ''
            }
        }

    }

    //#region PAGE LOAD

    componentWillUnmount() {
        //console.log('componentWillUnmount - stock history')
        this.unsubscribeStock && this.unsubscribeStock();
    }

    componentDidMount() {
        const { docID } = this.props;
        if (docID) {
            this.unsubscribeStock = this.refStock
                .doc(docID)
                .onSnapshot(this.onStockCollectionUpdate);
        }
    }



    onStockCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }
        const stock = Object.assign({}, objStock);
        const _objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objData)) {
            stock[key] = value;
        }
        stock.documentID = docSnapshot.id;
        this.setState({ stock: stock });
    }

    handleStockClose = () => {
        this.setState({
            stockModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: ''
            }
        })
    }

    handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    //#endregion
    handleClose = () => {
        const { stock } = this.state;
        this.props.handleClose(stock);
    }


    render() {

        const { show, dealersettings, clientUsers, groupUsers, updateDealerSettings, isFromLayout } = this.props;
        const stockLocations = dealersettings?.client?.clientSettings?.stockLocations;
        const { stockModal } = this.state

        const {
            stockNo, documentID, images,
            make, model, price,
            saleType, status, stockInDate,
            year, regNo, vinNo, extColor, clientID
        } = this.state.stock;

        // const divStyle = {
        //     height: '345px',
        //     overflow: 'auto'
        // };
        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
        const modelPrice = ((!_.isEmpty(_permissions) && _permissions.modelPrice) ? true : false);

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        return (
            //this.newDocID ?
            <>
                <Modal
                    id="vehicle-history"
                    show={show}
                    onHide={this.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    enforceFocus={false}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-vehicle-history">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" > <Translate text={'stockHistory'} /></h5>
                        </Modal.Title>
                        {
                            clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                (<div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{CommonHelper.getOtherDealerName(dealersettings, clientID)}</div></div>)
                                : (<></>)
                        }
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                {/**LEFT SIDE */}
                                <div className="col-md-4-custom mb-2">
                                    <div className="vehicle-history-left form-style mt-3">

                                        <a href="#" className="mini-button edit-history" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                stockModal:
                                                {
                                                    show: true,
                                                    ID: documentID,
                                                    title: 'stock',
                                                    clsActive: 'overlay-modal active'
                                                }
                                            });
                                        }}>
                                            {
                                                readOnlyEnquiryView
                                                    ?
                                                    <i className="ico icon-zoom"></i>
                                                    :
                                                    <i className="ico icon-edit"></i>
                                            }

                                        </a>
                                        <h3> <Translate text={'vehicleDetails'} /></h3>
                                        <div className="vehicle-history-list mt-1 mb-3">
                                            <div className="vehicle-history-image">
                                                {
                                                    (!_.isEmpty(images))
                                                        ?
                                                        <a data-fancybox={`testdrive_img${documentID}`} href={images[0]} onClick={(e) => { e.preventDefault(); }}>
                                                            <img src={images[0]} width="70" height="70" alt="" className="img-object-fit" />
                                                        </a>
                                                        :
                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                            <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                        </a>
                                                }
                                            </div>
                                            <div className="vehicle-history-data">
                                                <div className="vehicle-history-title">{make} {model}</div>
                                                <div className="vehicle-history-info">{extColor ? `${extColor}, ` : ''} {year ? year : ''}</div>
                                                {
                                                    clientID && (!_.isEmpty(stockLocations) || clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (
                                                        <div className="tbl-sub-dealer"><strong>{CommonHelper.getOtherDealerName(dealersettings, clientID)}</strong></div>
                                                    ) : (<></>)
                                                }
                                                <div className="vehicle-history-status">
                                                    {saleType ? <div className="badge badge-pill badge-white">
                                                        {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                            saleType, '')}
                                                    </div> : <></>}
                                                    {status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, status, '', 'ml-1') : <></>}
                                                </div>
                                                <div className="vehicle-history-status">
                                                    {
                                                        !_.isEmpty(stockInDate)
                                                            ?
                                                            <div className="badge badge-pill status-stock-age">
                                                                <Translate text={'stockAge'} />{' : '}
                                                                {this.handleDiffDays(stockInDate)}
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group form-lock">
                                            <label><Translate text={'stockNo'} /></label>
                                            <InputText
                                                name="stockNo"
                                                value={stockNo}
                                                readOnly={true}
                                                autoComplete="off"
                                                placeholder={'--'}
                                                className={`form-control btn-disable`}
                                            />
                                        </div>
                                        <div className="form-group form-lock">
                                            <label><Translate text={'regNo'} /></label>
                                            <InputText
                                                name="regNo"
                                                value={regNo}
                                                readOnly={true}
                                                autoComplete="off"
                                                placeholder={'--'}
                                                className={`form-control btn-disable`}
                                            />
                                        </div>
                                        <div className="form-group form-lock">
                                            <label> <Translate text={'vinNo'} /></label>
                                            <InputText
                                                name="vinNo"
                                                value={vinNo}
                                                readOnly={true}
                                                autoComplete="off"
                                                placeholder={'--'}
                                                className={`form-control btn-disable`}
                                            />
                                        </div>
                                        {
                                            modelPrice
                                                ?
                                                <>
                                                    <div className="form-group form-lock">
                                                        <label> <Translate text={'price'} /></label>
                                                        <NumberFormat
                                                            id={'price'}
                                                            decimalScale={2}
                                                            allowNegative={true}
                                                            thousandSeparator={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control`}
                                                            onValueChange={console.log('')}
                                                            value={price ? price : null} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </div>
                                </div>
                                {/**RIGHT SIDE */}

                                <div className="col-md-8-custom pl-0 form-style">
                                    {
                                        documentID
                                            ?
                                            <List
                                                stockID={documentID}
                                                stock={this.state.stock}
                                                clientUsers={clientUsers}
                                                groupUsers={groupUsers}
                                                history={this.props.history}
                                                dealersettings={dealersettings}
                                                updateDealerSettings={updateDealerSettings}
                                                isFromLayout={isFromLayout}
                                                handleClose={this.props.handleClose}
                                            />
                                            :
                                            <></>
                                    }
                                </div>

                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

                <PopUpModal show={stockModal.show}>
                    <AddStock
                        docID={stockModal.ID}
                        show={stockModal.show}
                        clsActive={stockModal.clsActive}
                        handleClose={this.handleStockClose}
                        title={stockModal.title}
                        enableSale={false}
                        enableTestDrive={false}
                        enableLoan={false}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddStock>
                </PopUpModal>
            </>
            // :
            // <></>
        );
    }
}
