import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import { clientsVM, clientlistFields } from './viewModel'
import { ContentOverlay, TableView, PopUpModal } from '../../../../components'
import AddClient from './addClient'
import AddClientModal from '../../../oem/dealers/addClientModal'
import { ClientContext } from '../clientContext'
import Translate from '../../../../constants/translate';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { doc } from 'rxfire/firestore';
import { MainContext } from '../../../layout/provider';

const ClientList = (props) => {
  const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
  const [clientLst, setClientList] = useState([])
  const [checkDataLoad, setDataload] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(true)
  const [isPaging, setPaging] = useState(false)
  const [hasMore, setHasMoreData] = useState([])
  const [showclientModel, setShowClientModel] = useState(false)
  const [popHeader, setPopHeader] = useState('')
  const [selectedClient, setSelectedclient] = useState(null)
  const [brands, setBrands] = useState([])
  const { updateDealerSettings } = useContext(MainContext)
  const pageLimit = 50

  const { clients, regions } = useContext(ClientContext);
  const addDealer = ((!_.isEmpty(props.dealersettings) &&
    !_.isEmpty(props.dealersettings.rolePermissions) &&
    !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    props.dealersettings.rolePermissions.permissions.addDealer) ? true : false);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
        windowHeight: (window.innerHeight - 259)
      })
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubAdminClientList && window.unSubAdminClientList();
    }
  }, [])


  useEffect(() => {
    const brandCollection = window.firebase.firestore().collection(`brands`)
      .where('category', '==', 'Automotive')
      .where('isDeleted', '==', false)
      //.orderBy('addedDate', 'desc')
      .onSnapshot((docSnapshot) => {
        let _brand = [];
        docSnapshot.docs.forEach(doc => {
          const branddoc = {
            ...doc.data(),
            documentID: doc.id
          };
          _brand.push({ label: branddoc.name, value: doc.id, data: branddoc, active: _.isBoolean(doc.active) ? doc.active : true, })
        })
        setBrands(_.orderBy(_brand, ["label"], ["asc"]))
      });
    return () => {
      brandCollection && brandCollection();
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(clients) && localStorage.defaultModule !== 'oem') {
      let _client = [];
      _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(rec => {
        _client.push(convertVM(rec))
      })
      setClientList(_client);
      setLoader(false);
    }
  }, [clients, searchText])

  useEffect(() => {
    if (localStorage.defaultModule !== 'oem')
      return;

    let clientPromise = []
    clientPromise.push(doc(window.firebase.firestore().collection(`clients`).where('settingsID', '==', props.dealersettings.oemID)));
    clientPromise.push(doc(window.firebase.firestore().collection(`clients`).where('settingsIDs', 'array-contains', props.dealersettings.oemID)));
    if (clientPromise.length === 0) {
      setClientList([]);
      setLoader(false);
    }
    var oemclientSnapshot = combineLatest(
      clientPromise
    ).pipe(
      map(([dealers, linkDealers]) => {
        return [
          _.map(dealers.docs, function (obj) { return obj.exists ? { ...obj.data(), id: obj.id } : {} }),
          _.map(linkDealers.docs, function (obj) { return obj.exists ? { ...obj.data(), id: obj.id } : {} })
        ]
      }))
      .subscribe((allDocs) => {
        let allconverted = []
        allDocs.forEach(dealer => {
          dealer.forEach(rec => {
            if (rec && !allconverted.some(a => a.id === rec.id) && props.dealersettings.allClients.some(a => a.documentID === rec.id))
              allconverted.push(convertVM(rec));
          })
        });
        setClientList(allconverted);
        setLoader(false);
      });
    return () => {
      oemclientSnapshot && oemclientSnapshot.unsubscribe();
    };
  }, [])

  const convertVM = (doc) => {
    const objclientData = Object.assign({}, doc);
    const listVM = Object.assign({}, clientsVM);
    for (let [key, value] of Object.entries(objclientData)) {
      if (listVM.hasOwnProperty(key))
        listVM[key] = value;
    }
    if (doc.settingsID !== props.dealersettings.oemID)
      listVM.settingsName = doc.settingsName
    listVM.countryName = _.find(props.dealersettings.countries, { 'code': listVM.country }) ? _.find(props.dealersettings.countries, { 'code': listVM.country }).name : ''
    listVM.regionName = _.find(regions, { 'id': listVM.region }) ? _.find(regions, { 'id': listVM.region }).name : ''
    return listVM
  }

  const hadleEditClient = (id) => {
    const editClient = clientLst.find(item => item.documentID === id);
    const clientVM = Object.assign({}, clientsVM);
    for (let [key, value] of Object.entries(editClient)) {
      if (clientVM[key] !== undefined)
        clientVM[key] = value;
    }
    setSelectedclient(clientVM)
    setPopHeader('Edit Client')
    setShowClientModel(true)
  }

  const handleActionClick = (id) => {
    return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
      hadleEditClient(id)
    }}>
      <i className="ico icon-edit"></i>
    </div>)
  }

  const handleClientdetailsClick = (e, id) => {
    e.preventDefault();
    hadleEditClient(id)
  }

  const handlePagination = () => {
    setPaging(true)
    setDataload(true)
  }


  const handleModelClose = (client, isNewClient) => {
    setSelectedclient(null);
    setShowClientModel(false);

    if (client && clientLst.some(e => e.documentID === client.documentID))
      setClientList(clientLst.map(rec => {
        return rec.documentID === client.documentID ? {
          ...convertVM(client)
        } : { ...rec }
      }));
    else if (client && isNewClient) {
      setClientList([...clientLst, convertVM(client)]);
      updateDealerSettings('clients', { ...client, id: client.documentID })
    }

  };

  const handleAddClient = () => {
    setSelectedclient(null)
    setPopHeader('Add Client')
    setShowClientModel(true)
  }



  return (
    <>
      <div className="mt-2">
        <div className="settings-head">
          <div className="float-left">
            <div className="filter-search search-icon">
              <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
            </div>
          </div>
          {
            addDealer ? (<div className="float-right">
              <div className="filter-add-button">
                <button type="button" className="btn btn-primary" onClick={() => {
                  handleAddClient()
                }}> <i className="ico icon-add"></i> <Translate text={'Add Client'} /></button>
              </div>
            </div>) : (<></>)
          }
        </div>
        {
          loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
            clientLst.length > 0 ? (
              <div className="common-table">
                <TableView
                  datalist={_.orderBy(clientLst.filter(({ name, address, phone, email }) =>
                  (!_.isEmpty(searchText) ? (
                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(address) ? address.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                    (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                  ) : true)
                  ), ['addedDate.seconds'], ['desc'])}
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={clientlistFields}
                  dynamicFields={clientlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleClientdetailsClick}
                  isSettings={false}
                  hasMore={hasMore}
                  handlePagination={handlePagination}
                  isPaging={true}
                  isReorderable={false}
                  isSorting={true}
                  displayByValue={true}
                />
              </div>
            ) : (
              <div className="common-table">
                <div className="text-center p-5">
                  <p><Translate text={'No clients found'} /></p>
                  <button className="btn btn-sm btn-default" onClick={() => { handleAddClient(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                    <Translate text={'Add Client'} />
                  </button>
                </div>
              </div>
            )
        }
      </div>
      {
        localStorage.defaultModule === 'oem' ? (<PopUpModal show={showclientModel}>
          <AddClientModal
            show={showclientModel}
            title={popHeader}
            dealersettings={props.dealersettings}
            handleClose={handleModelClose}
            client={selectedClient}
            brands={brands}
          />
        </PopUpModal>) : (
          <PopUpModal show={showclientModel}>
            <AddClient
              {...props}
              show={showclientModel}
              title={popHeader}
              handleClose={handleModelClose}
              client={selectedClient}
              brands={brands}
            />
          </PopUpModal>
        )
      }
    </>
  )
}

export default ClientList