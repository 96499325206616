export const allUnallocatedFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        default: true,
    },
    {
        name: 'Ref Number',
        value: 'displayID',
        default: true,
        flex: 1,
        subText: 'domleadSource',
        subTextTitle: 'Lead Source'        
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 1,
        default: true, 
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',      
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Vehicle',
        value: 'tradeinModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type',
        elementsubName: 'vehicleSaletype'

    },
    {
        name: 'Reg. No.',
        value: 'regNo',
        default: true,
        flex: 1,
    },
    {
        name: 'State',
        value: 'state',
        default: true,
        flex: 1,
    },
    {
        name: 'Origin',
        value: 'origin',
        default: true,
        flex: 1,
    },
    // {
    //     name: 'Lead Source',
    //     value: 'domleadSource',
    //     flex: 1,
    //     elementName: 'strleadSource',
    //     default: true,
    // },
    {
        name: 'Notes',
        value: 'notes',
        default: true,
        flex: 1,
        limit : 190
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 100,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allAllocatedFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Favourite',
        value: 'documentID',
        subText: 'isFav',
        width: 80,
        type: 'favorite',
        flex: 0,
        align: 'center'
    },
    {
        name: 'Ref Number',
        value: 'displayID',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 2,
        default: true,
        subText: 'contactNumber',
        subTextTitle: 'Contact ID',
        //nestedText: 'contactdealerName',
        //nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type',
        elementsubName: 'vehicleSaletype'

    },
    {
        name: 'Vehicle Body Type',
        value: 'vehicleBodytype',
        flex: 1,
    },
    {
        name: 'Vehicle Year',
        value: 'vehicleYear',
        flex: 1,
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName'
    },
    {
        name: 'Lead Source',
        value: 'domleadSource',
        flex: 1,
        elementName: 'strleadSource',
        default: true,
    },
    {
        name: 'Received On',
        value: 'receivedDate',
        flex: 1,
        default: true
    },
    {
        name: 'Actioned On',
        value: 'actionedOn',
        flex: 1,
        default: true
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1
    },
    {
        name: 'Stage Date',
        value: 'stageDate',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By'
    },
    {
        name: 'Unattended Leads',
        value: 'rottenDays',
        flex: 1,
    },
    {
        name: 'Requirement Count',
        value: 'requirementCount',
        flex: 1,
    },
    {
        name: 'Won Count',
        value: 'soldCount',
        flex: 1,
    },
    {
        name: 'Enquiry Age',
        value: 'enquiryAge',
        flex: 1,
    },
    {
        name: 'Won Date',
        value: 'wonOn',
        flex: 1
    },
    {
        name: 'Lost Date',
        value: 'lostOn',
        flex: 1
    },
    {
        name: 'Delivered Date',
        value: 'deliveredOn',
        flex: 1
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1,
    },
    {
        name: 'Campaign',
        value: 'campaign',
        flex: 1,
    },
    {
        name: 'Label',
        value: 'label',
        flex: 1,
        elementName: 'labelName'
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
    },
    {
        name: 'Lost Reason',
        value: 'lostReason',
        flex: 1,
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason',
        flex: 1,
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention',
        flex: 1,
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount',
        flex: 1,
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount',
        flex: 1,
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber',
        flex: 1,
    },
    {
        name: 'Contract Number',
        value: 'contractNumber',
        flex: 1,
    },
    {
        name: 'Verified',
        value: 'verifiedDate',
        flex: 1,
        subText: 'verifiedBy',
        subTextTitle: 'Verified By'
    },


    {
        name: 'Unactioned',
        value: 'unActioned',
        flex: 1,
        default: true,
        elementName: 'strunActioned'

    },
    {
        name: 'Quote Amount',
        value: 'quoteAmount',
        flex: 1,
        elementName: 'csvQuoteAmount'
    },
    {
        name: 'Confirmed',
        value: 'confirmedDate',
        flex: 1,
        subText: 'confirmedBy',
        subTextTitle: 'Confirmed By'
    },
    {
        name: 'VIN #',
        value: 'chassisNo',
        flex: 1,
    },
    {
        name: 'Reg #',
        value: 'regNo',
        flex: 1,
    },
    {
        name: 'Stock #',
        value: 'stockNo',
        flex: 1,
    },
    {
        name: 'Warranty Start Date',
        value: 'warrantyStartDate',
        flex: 1
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry',
        flex: 1
    },
    {
        name: 'Converted Date',
        value: 'convertedOn',
        flex: 1,
        subText: 'convertedOwner',
        subTextTitle: 'Converted By'
    },
    {
        name: 'OEM Converted Date',
        value: 'convertedOnOEM',
        flex: 1,
        subText: 'convertedOwnerOEM',
        subTextTitle: 'OEM Converted By'
    },
    {
        name: 'Region Converted Date',
        value: 'convertedOnRegion',
        flex: 1,
        subText: 'convertedOwnerRegion',
        subTextTitle: 'Region Converted By'
    },
    {
        name: 'Group Converted Date',
        value: 'convertedOnGroup',
        flex: 1,
        subText: 'convertedOwnerGroup',
        subTextTitle: 'Group Converted By'
    },
    {
        name: 'BDC Status',
        value: 'bdcStatus',
        flex: 1,
        elementName: 'bdcStatusName'
    },
    {
        name: 'Appointment Date',
        value: 'appointmentDateOn',
        flex: 1
    },
    {
        name: 'Test Drive Model',
        value: 'testdriveModel',
        flex: 1,
        subText: 'testdriveSaletype',
        subTextTitle: 'Test Drive Sale Type'
    },
    {
        name: 'Response Time(hh:mm:ss)',
        value: 'strResponseTime',
        flex: 1,
        default: true,
    },
    {
        name: 'Finance Status',
        value: 'financeStatus',
        flex: 1
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2
    },
    {
        name: 'Company',
        value: 'companyName',
        flex: 1
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1
    },
    {
        name: 'Hobbies',
        value: 'interests',
        flex: 1
    },
    {
        name: 'Dependents',
        value: 'dependents',
        flex: 1
    },
    {
        name: 'Driver Name',
        value: 'driverName',
        flex: 1
    },
    {
        name: 'Contact Campaign',
        value: 'contactcampaign',
        flex: 1
    },
    {
        name: 'Contact Origin',
        value: 'contactorigin',
        flex: 1
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
    },
    {
        name: 'Secondary Name',
        value: 'secondaryName',
        flex: 1
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        flex: 1
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail',
        flex: 1
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]