import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText } from '../../../../components'
import { regionVM } from './viewModel'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';

const AddSubcollectionData = (props) => {
    const [state, setState] = useState(props.stateData);
    const [errorFields, setErrorFields] = useState({});
    const newData = !_.isEmpty(props.stateData) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.stateData)) {
            const newDataVM = Object.assign({}, regionVM);
            newDataVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/${props.collection}`).doc().id;
            newDataVM.addedBy = localStorage.uid;
            newDataVM.modifiedBy = localStorage.uid;
            newDataVM.addedDate = window.firebase.firestore.Timestamp.now();
            newDataVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            setState(newDataVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setState({
                ...state,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setState({
                ...state,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
       
    }

    const saveData = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(state['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (props.allData && props.allData.filter(item => item.documentID !== state.documentID).some(item => item.name === state.name)) {
            Swal.fire(CommonHelper.showLocale(props, `${props.collectionName} with same name already exists`), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete state.active;
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.collection}/${state.documentID}`).set(state, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newData === true ? `${props.collectionName} added successfully` : `${props.collectionName} updated successfully`), {
                    duration: 2000
                })
                props.handleClose(state,props.fieldName);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(state) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={`${props.collectionName} Name`} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={state.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveData(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                        <Translate text={'save'} />
                        </button>
                <button type="button" className="btn btn-default float-right" onClick={()=>props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSubcollectionData;