import React from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { AllModules } from "../../constants/routes";
import images from '../../images'
import Translate from '../../constants/translate';

const SelectModule = (props) => {
    return (<Modal
        show={props.show}
        backdrop="static"
        backdropClassName={'overlay-modal active'}
        dialogClassName="modal-dialog fullscreenmodal-lg">
        <div className="modal-content modal-content-fullscreen">
            <Modal.Header className="modal-header-fullscreen">
                <h5 className="modal-title" id="">
                    <div className="switch-logo"><img src={images.darklogo} width="55" alt="" /> <span>  <Translate text={'modules'} /></span></div>
                </h5>
            </Modal.Header>
            <Modal.Body className="modal-body-fullscreen">
                <div className="popup-wrap-fullscreen">
                    <main role="main" className="container">
                        <div className="row switch-popup-wraper d-flex justify-content-start">
                            {/* {
                        props.moduleAlert ? (<div className="alert alert-danger w-100 d-inline-block ml-3 mr-3" role="alert">
                            A simple warning alert—check it out!
                        </div>) : (<></>)
                    } */}
                            {
                                _.filter(AllModules, (v) => _.indexOf(props.modules.map(p => _.pick(p, ['name']).name), v.module) >= 0 && v.module !== 'oem').map((rec, index) => {
                                    return <div key={index} className="col-lg-3"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.handleSelectModule(rec.module, rec.route)
                                        }}>
                                        <div className="switch-popup-module">
                                            <div className="switch-popup-icowrap"> <i className={rec.class}> </i> </div>
                                            <div className="switch-popup-textwrap">
                                                <h2 >{rec.name} </h2>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </main>
                </div>
            </Modal.Body>
        </div>
    </Modal>
    )
}

export default SelectModule;