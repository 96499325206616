/** LIBRARIES */
import React, { PureComponent } from "react";
import _ from 'lodash';
import Select, { components } from "react-select";

class ReactSelect extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    DropdownIndicator = props => {
        return props.hasValue ? <></> : <components.DropdownIndicator {...props} />;
    };

    multiValueContainer = ({ selectProps, data }) => {
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex(selected => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        const labelSuffix = isLastSelected ? "" : ", ";
        const val = `${label}${labelSuffix}`;
        return val;
    };


    render() {
        //console.log('props.value', this.props.name, this.props.value, this.props.isMulti)
        const { minHeight } = this.props;

        const singleStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: (minHeight ? minHeight : '33px')
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            clearIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
        };

        const multiStyles = {
            control: base => ({
                ...base,
                minHeight: (minHeight ? minHeight : '33px')
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            clearIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                textOverflow: "ellipsis",
                maxWidth: "90%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "initial"
            })
        };

        const singleComponent = {
            IndicatorSeparator: () => null,
            DropdownIndicator: this.DropdownIndicator
        };

        const multiComponent = {
            IndicatorSeparator: () => null,
            DropdownIndicator: this.DropdownIndicator,
            MultiValueContainer: this.multiValueContainer
        };

        const simpleComponent = {
            IndicatorSeparator: () => null
        };

        const value = [];
        if (this.props.isMulti) {
            !_.isEmpty(this.props.value) && !_.isEmpty(this.props.options) && this.props.value.forEach((data) => {
                var indexVal = this.props.options.findIndex(x => x.value === data);
                value.push(this.props.options[indexVal])
            })
        }
        else {
            if ((this.props.value || this.props.value === false || this.props.value === 0) && !_.isEmpty(this.props.options)) {
                var index = this.props.options.findIndex(x => x.value === this.props.value);
                if (index >= 0) {
                    value.push(this.props.options[index])
                }
                else if (this.props.AddUnAvailable) {
                    value.push({ value: this.props.value, label: this.props.value })
                }
            }
            else if ((this.props.value || this.props.value === false || this.props.value === 0) && this.props.AddUnAvailable && _.isEmpty(this.props.options)) {
                value.push({ value: this.props.value, label: this.props.value })
            }
        }

        const customFilter = (option, searchText) => {
            if (
                (typeof (option.data.value) === 'string' && option.data.value.toLowerCase().includes(searchText.toLowerCase())) ||
                (typeof (option.data.label) === 'string' && option.data.label.toLowerCase().includes(searchText.toLowerCase())) ||
                (typeof (option.data.searchlabel) === 'string' && option.data.searchlabel.toLowerCase().includes(searchText.toLowerCase()))
            ) {
                return true;
            } else {
                return false;
            }
        }

        let options = [];
        if (!_.isEmpty(this.props.options)) {
            if (_.isBoolean(this.props.options.map(el => el.active)[0])) {
                options = this.props.options.filter(e => e.active === true);
            }
            else {
                options = this.props.options;
            }
        }
        //console.log('RENDER REACT SELECT', this.props.name, this.props.value)
        return (
            <div id={this.props.name}>

                {
                    this.props.removeClearable
                        ?
                        <Select
                            id={this.props.name}
                            options={options}
                            placeholder={this.props.placeholder}
                            className={`basic-single`}
                            classNamePrefix={this.props.classNamePrefix}
                            isClearable={this.props.removeClearable ? false : true}
                            components={simpleComponent}
                            styles={this.props.isMulti ? multiStyles : singleStyles}
                            name={this.props.name}
                            onChange={this.props.onChange}
                            defaultValue={value}
                            value={value}
                            isMulti={this.props.isMulti}
                            isSearchable={this.props.isSearchable === false ? false : true}
                            isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                            closeMenuOnSelect={this.props.isMulti ? false : true}
                            hideSelectedOptions={false}
                            menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : 'auto'}
                            filterOption={customFilter}
                            maxMenuHeight={this.props.maxMenuHeight}
                            defaultMenuIsOpen={this.props.defaultMenuIsOpen || false}
                        // menuPortalTarget={document.body}
                        />
                        :
                        <Select
                            id={this.props.name}
                            options={options}
                            placeholder={this.props.placeholder}
                            className={`basic-single`}
                            classNamePrefix={this.props.classNamePrefix}
                            isClearable={this.props.removeClearable ? false : true}
                            components={this.props.isMulti ? multiComponent : singleComponent}
                            styles={this.props.isMulti ? multiStyles : singleStyles}
                            name={this.props.name}
                            onChange={this.props.onChange}
                            defaultValue={value}
                            value={value}
                            isMulti={this.props.isMulti}
                            isSearchable={this.props.isSearchable === false ? false : true}
                            isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                            closeMenuOnSelect={this.props.isMulti ? false : true}
                            hideSelectedOptions={false}
                            menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : 'auto'}
                            filterOption={customFilter}
                            maxMenuHeight={this.props.maxMenuHeight}
                            defaultMenuIsOpen={this.props.defaultMenuIsOpen || false}
                        //menuPortalTarget={document.body}
                        />
                }
            </div>

        );
    }
}


export default ReactSelect;