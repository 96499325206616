/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { ReactSelect, InputText } from '../../components';




export default class EvalSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                vin: '',
                desc: ''
            },
            loader: {},
            descriptions: [],
            isLoading: false
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = async () => {


        this.setState({ loader: { 'desc': true } });
        const { data, vinNum } = this.props;
        const objData = Object.assign({}, this.state.fields);
        if (data?.VIN) objData.vin = data?.VIN;
        if (data?.options?.fullDescription) {
            let _descriptions = [];
            data.options.fullDescription.forEach((doc) => {
                _descriptions.push({
                    value: doc,
                    active: true,
                    label: doc
                });
            });

            this.setState({ fields: objData, descriptions: _descriptions, loader: {} })
        }
        else if (data?.VechielDescriptions) {

            //Swal.fire(_response.data.message, '', 'info')
        }
        else {

            Swal.fire('No record found!', '', 'info')
            this.forceUpdate()
        }
    }

    handleVariantChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields, loader: {} });

    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });
        const { dealersettings, vinNum } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';
        const objData = Object.assign({}, this.state.fields);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (vinNum)
            objData.vin = vinNum;

        const evalXpertSearch = window.firebase.functions().httpsCallable(`evalexpert-search`);
        evalXpertSearch({
            ...objData,
            clientID: clientID,
            manual: true
        }).then((_response) => {

            //console.log('evalexpert-search-handleSubmit', _response);
            //check for success response
            if (_response && _response.data && _response.data.success) {
                //console.log('evalexpert-search-handleSubmit-', _response.data.data[0]);
                this.props.handleClose(_response.data.data[0]);

            }
            else if (_response?.data?.message) {
                Swal.fire(_response.data.message, '', 'info')
            }
            else {
                Swal.fire('No record found!', '', 'info')
                this.forceUpdate()
            }
        });

    };

    render() {
        const { desc, vin } = this.state.fields;
        const { show, isGenericLayout, vinNum } = this.props;
        const { descriptions, loader, isLoading, fields } = this.state;

        const divStyle = {
            minHeight: '225px'
        };
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Select Vehicle'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''} ${desc ? '' : 'mb-5'}`}>
                            <div className={`row ${isGenericLayout ? 'w-100 d-block border-0' : ''}`}>
                                <div className="search-pop form-style mt-0" style={divStyle}>
                                    <div className="form-row">

                                        <div className="form-group col-md-12">
                                            <div className="vehicle-status-alert alert-custom" >
                                                {"Couldn't find vehicle. Please select from below to process."}

                                            </div>
                                        </div>

                                        {
                                            vin || vinNum
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'chassisNo'} /></label>
                                                        <InputText
                                                            type="text"
                                                            name="__vinNum"
                                                            value={vin ? vin : vinNum}
                                                            onChange={(e) => { e.preventDefault(); }}
                                                            autoComplete="off"
                                                            placeholder={'chassis number'}
                                                            className={`form-control`}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                        {
                                            !_.isEmpty(descriptions)
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label > <Translate text={'vehicle'} /></label>
                                                        <ReactSelect
                                                            options={descriptions}
                                                            name={"desc"}
                                                            placeholder={'Please select a vehicle'}
                                                            onChange={this.handleVariantChange}
                                                            value={desc}
                                                            classNamePrefix={`basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            !_.isEmpty(loader)
                                                ?
                                                <><div className="form-group col-md-12 mt-4"><div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="border" variant="primary" />
                                                </div></div></>
                                                :
                                                <></>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {
                        desc
                            ?
                            <>
                                <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                                    <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                                        {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'done'} />
                                    </button>
                                </Modal.Footer>
                            </>
                            :
                            <></>
                    }

                </Modal>
            </>


        );
    }
}

