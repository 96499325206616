
/** LIBRARIES */
import React, { Component } from "react";
import SignatureCanvas from 'react-signature-canvas'
import {Modal} from "react-bootstrap";
import Swal from 'sweetalert2'
import Translate from '../constants/translate';
import CommonHelper from '../services/common';
const none_sign_img = require("../images/sign-ico.png");


export default class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trimmedDataURL: null
        }
        this.signPad = {}
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.signPad.getSignaturePad()._isEmpty) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Please provide a signature.'), '', 'info')
            return false;
        }
        else {
            this.props.handleClose(this.signPad.getTrimmedCanvas().toDataURL('image/png'))
        }
    };

    render() {
        //let { trimmedDataURL } = this.state;
        const { show } = this.props;

        return (
            <>

                <Modal
                    id="add-terms"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'signature'}/> </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className="testdrive-terms-wrap">
                                    <div className="signature-box">
                                        <SignatureCanvas
                                            penColor='black'
                                            ref={(ref) => { this.signPad = ref }}
                                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas', src: { none_sign_img } }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-default float-left" onClick={() => {
                            this.signPad.clear()
                            this.setState({ trimmedDataURL: null })
                        }}><Translate text={'clear'}/></button>
                        <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                            this.handleSubmit(e)
                        }
                        }><Translate text={'done'}/></button>
                    </Modal.Footer>
                </Modal>


            </>


        );
    }
}

