/** LIBRARIES */
import React, { PureComponent } from "react";
import moment from 'moment'
import _ from 'lodash';
//import { Statistic, Row, Col } from 'antd';

//const { Countdown } = Statistic;


export default class Timer extends PureComponent {
    constructor(props) {
        super(props);
        this.interval = null;
        this.state = {
            loading: false,
            qTime: null,
            qTimeout: null,
            timer: undefined,
            minutes: undefined,
            seconds: undefined
        };
    }

    loadTimer = () => {
        const { data } = this.props;

        if (data && data.qTimer === true && !_.isEmpty(data.qTime)) {

            var eventTime = moment.unix(data.qTime.seconds).add((data.qTimeout ? data.qTimeout : 0), 'minutes');
            var currentTime = moment();
            var duration = moment.duration(eventTime.diff(currentTime));
            var interval = 1000;

            this.interval = setInterval(() => {

                // const then = moment.unix(data.qTime.seconds)
                // const now = moment().add((data.qTimeout ? data.qTimeout : 0), 'minutes');
                // const countdown = moment(now - then);
                // //console.log('countdown', data.qTime.seconds, countdown.format('DD MM YYYY hh mm ss'), countdown.format('mm ss'))
                // const minutes = countdown.format('mm');
                // const seconds = countdown.format('ss');

                duration = moment.duration(duration - interval, 'milliseconds');

                if (duration.asSeconds() <= 0) {
                    clearInterval(this.interval);
                } else {
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();
                    var timer = (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds
                    this.setState({ timer });
                }

            }, interval);

        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (data && data.qTimer === true && !_.isEmpty(data.qTime))
            this.setState({ qTime: data.qTime, qTimeout: data.qTimeout });
        this.loadTimer();

    }

    componentDidUpdate() {
        if (this.state.loading) {
            //console.log('componentDidUpdate -', this.props.data.qTimeout, this.state.qTimeout)            
            clearInterval(this.interval);
            this.loadTimer();
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (JSON.stringify(props.data.qTime) !== JSON.stringify(state.qTime) && state.qTime) {
            //console.log('getDerivedStateFromProps - qTime', props.data.qTime, state.qTime)            
            return {
                loading: true,
                qTime: props.data.qTime
            }
        }
        else if (JSON.stringify(props.data.qTimeout) !== JSON.stringify(state.qTimeout) && state.qTimeout) {
            //console.log('getDerivedStateFromProps - qTimeout', props.data.qTimeout, state.qTimeout)
            return {
                loading: true,
                qTimeout: props.data.qTimeout
            }
        }
        else {
            return {
                loading: false
            }
        }

    }

    onFinish = () => {
        console.log('countdown finished!');
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { timer } = this.state;
        //const { data } = this.props;
        // let deadline;
        // if (data && data.qTimer === true && data.qTime && Object.keys(data.qTime).length > 0) {
        //     deadline = new Date(moment.unix(data.qTime.seconds).add((data.qTimeout ? data.qTimeout : 0), 'minutes'));
        // }

        return (
            <>
                {
                    (timer)
                        ?
                        <div className="frontdesk-queue-timer">
                            <i className="ico icon-time"></i>
                            <span>{timer}</span>
                            {/* <Countdown value={deadline} onFinish={this.onFinish} format="HH:mm:ss" /> */}
                        </div>
                        :
                        <></>

                }

            </>

        );
    }
}

