import React, { useState, useEffect,useContext } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, TextEditor } from '../../../../components';
import MentionEditor from '../../../../components/mentionEditor/mentionEditor';
import { visibilities, templateVM, mentions } from './viewModel'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import { TemplateContext } from '../templateContext'

const AddSMStemplate = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [counter, setCounter] = useState({ sms: 0, text: 0 })
    // const [allMentions, setMentions] = useState(mentions);
    const [activityLogNotes, setActivityLogNotes] = useState('')
	const [teamMentions, setTeamMentions] = useState(null);
	const [errorNotes, setErrorNotes] = useState(null);
    const [allMentions, setMentions] = useState(mentions.map(mention => {
		return {
			name: `${mention.value}`,
			id: mention.value,
			title: `${mention.name}`,
		};
	}));
    const { formTemplates } = useContext(TemplateContext);

    useEffect(() => {
        if (_.isEmpty(props.template)) {
            let newtemplateVM = Object.assign({}, templateVM);
            newtemplateVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/servicetemplates/`).doc().id;
            //newtemplateVM.clientID = props.dealersettings.client.id;
            newtemplateVM.addedBy = localStorage.uid;
            newtemplateVM.modifiedBy = localStorage.uid;
            newtemplateVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.visibility = 'private'
            newtemplateVM.type = props.type ? props.type : 'sms'
            newtemplateVM = CommonHelper.setClientOrGroupOrRegionOrOemID(newtemplateVM, props.dealersettings, null, null, true);

            setTemplate(newtemplateVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(formTemplates))
            return

        let _formMentions = [];
        formTemplates.forEach(form => {
            _formMentions.push({
                title : `Form-${form.title}`,
                name : `@FORM/${form.documentID} `,
                id : `@FORM/${form.documentID} `,
            })
        })
        // setMentions([...mentions,..._formMentions]);
        setMentions([...allMentions,..._formMentions]);

    }, [formTemplates])

    useEffect(() => {
        if (!_.isEmpty(template) && !_.isEmpty(template.templateJSON)) {
            var data = JSON.parse(template.templateJSON);
            var text = '';
            if (!_.isEmpty(data.blocks)) {
                data.blocks.forEach(ele => {
                    text += ele.text + ' ';
                });
            }
            setCounter({
                text: text.length,
                sms: Math.ceil(text.length / 160)
            })
        }
    }, [template])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setTemplate({
                ...template,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setTemplate({
                ...template,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleSelectChange = (e, data) => {

        setTemplate({
            ...template,
            [data.name]: e.value,

        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const onEditorStateChange = (obj) => {
        const templateJSON = JSON.stringify(obj.rawData)
		const textMessage = obj.value.replace(/<[^>]*>?/gm, '')
        setTemplate({
            ...template,
            // [obj.name]: obj.value,
            templateJSON: templateJSON,
            bodyHTML: textMessage,
            rawHTML: obj.value
        });
    }

    const handleSavetemplate = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(template['title'])) {
            formIsValid = false;
            errors['title'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        template.modifiedDate = window.firebase.firestore.Timestamp.now();
        template.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/servicetemplates/${template.documentID}`)
            .set({ ..._.pick(template, _.keys(templateVM)) }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((props.newTemplate === true ? 'Template added successfully' : 'Template updated successfully'), {
                    duration: 2000
                })
                props.handleClose(template);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const onMentionChange = notesText => {
		setActivityLogNotes(notesText);
        setTemplate({
            ...template,
            templateJSON: '',
            bodyHTML: notesText ? notesText : '',
        });
	};

    const onMentionSelect = (option, ownerID) => {
		console.log('select', option);
	};

    const clearTeamMentions = () => {
		setTeamMentions(null)
	}

    const convertTemplateJSON = (templateJSON) => {
        // const _templateJSON = JSON.stringify(obj.rawData)
		// const textMessage = obj.value.replace(/<[^>]*>?/gm, '')
        let data = JSON.parse(templateJSON);
        let text = '';
        if (!_.isEmpty(data.blocks)) {
            data.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        return text
    }

    return _.isEmpty(template) ? (<></>) : (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-add-emailtemplates">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label ><Translate text={'title'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'title'}
                                    className={`form-control ${errorFields["title"]}`}
                                    name="title"
                                    onChange={handleOnChange}
                                    value={template.title}
                                />

                            </div>

                            <div className="form-group col-md-6">
                                <label ><Translate text={'visibility'} /></label>
                                <ReactSelect
                                    options={visibilities}
                                    name={"visibility"}
                                    placeholder={'select visibility'}
                                    onChange={handleSelectChange}
                                    value={template.visibility}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                    isSearchable={false}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                {/* <TextEditor
                                    name={'bodyHTML'}
                                    placeholder={'message...'}
                                    value={template.rawHTML ? template.rawHTML : template.bodyHTML}
                                    onChange={onEditorStateChange}
                                    toolbarOptions={[]}
                                    height={350}
                                    enableMention={true}
                                    mentions={allMentions}                                    
                                />
                                <small className="form-text text-muted float-right"> <Translate text={'Characters'} /> : {counter.text} |  <Translate text={'SMS Counter'} /> : {counter.sms}</small> */}
                                <MentionEditor
                                    mentionsData={allMentions}
                                    onMentionChange={onMentionChange}
                                    onMentionSelect={onMentionSelect}
                                    notes={template.bodyHTML && _.isEmpty(template.templateJSON) ? template.bodyHTML : !_.isEmpty(template.templateJSON) ? convertTemplateJSON(template.templateJSON) : ''}
                                    focusOnMount={false}
                                    isNoteEmpty={errorNotes === 'input_error' && _.isEmpty(template.bodyHTML.trim()) ? true : false}
                                    teamMentions={teamMentions}
                                    clearTeamMentions={clearTeamMentions}
                                    clientUsers={props.clientUsers}
                                    ownerID={''}
                                    showCustomSuggestions={true}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                handleSavetemplate()
            }
            }>
                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default AddSMStemplate;