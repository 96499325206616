import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap' 
import _ from 'lodash';
import { stockTabs, NoDataDiv, LoadingDiv } from '../constants';

import CommonHelper from '../../../../services/common';

const StockSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleOpenStock,
	enableSharedStock,
	getDealerName,
	history,
}) => {
	const [activeTab, setActivetab] = useState('stock');
	const [stock, setStock] = useState([]);
	const [loader, setLoader] = useState([]);

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let refStock
			if (enableSharedStock && dealersettings.client.group) {
				refStock = window.firebase
				.firestore()
				.collection('stock')
				.where('groupID', '==', dealersettings.client.group)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(10);
			} else {
				refStock = window.firebase
				.firestore()
				.collection('stock')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(10);
			}

			var dashboardPipelines = combineLatest(collection(refStock))
				.pipe(
					map(([stock]) => {
						return [
							_.map(stock, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'stock',
									sortDate: obj.data().addedDate,
								};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					setStock(allDocs[0]);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }}>
			<div
				className='dropdown-menu dropdown-menu-left'
				aria-labelledby='dropdownMenuButton'
				style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
			>
				<div className='form-style globalsearch-wrap'>
					<div className='globalsearch-nav-wraper'>
						<div className='globalsearch-nav pl-0'>
							<Tab.Container defaultActiveKey={activeTab}>
								<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
									{stockTabs.map((rec, index) => {
										return (
											<OverlayTrigger
												placement='bottom'
												overlay={
														<Tooltip><span>{rec.title}</span></Tooltip>
												}
											>
												<Nav.Item
													as='li'
													bsPrefix='li-nav'
													key={index}
												>
													<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
															{rec.name}
													</Nav.Link>
												</Nav.Item>
										</OverlayTrigger>
										);
									})}
								</Nav>
							</Tab.Container>
						</div>
					</div>
					<div className='globalsearch-body'>
						{loader ? (
							<LoadingDiv />
						) : (
								<div className='global-item-wraper'>
									{activeTab === 'all' ? (
										<></>
									) : activeTab === 'enquiry' ? (
										<></>
									) : activeTab === 'contacts' ? (
										<></>
									) : activeTab === 'company' ? (
										<></>
									) : activeTab === 'stock' ? (
										<>
											{stock.length > 0 ? (
												<>
													{stock.map(obj => (
														<a
															className='global-item d-flex'
															href='#'
															onClick={e => {
																e.preventDefault();
																handleOpenStock(obj.documentID);
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
																</div>
															</div>
															<div>
																<span className='global-header'>
																	{`${obj.make} ${obj.model}`}
																</span>
																<span className='global-info'>
																	{' '}
																	{`${obj.extColor ? obj.extColor + ',' : ''} ${
																		obj.year
																		}`}
																	{obj.stockNo ? ` - ${obj.stockNo}` : ''}
																</span>
																{enableSharedStock && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
																	(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
																{obj.saleType && (
																	<div className='global-status'>
																		<div className='badge badge-pill badge-white'>
																			{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
																				obj.saleType, '')}
																		</div>
																	</div>
																)}
															</div>
														</a>
													))}
												</>
											) : (
													<NoDataDiv />
												)}
										</>
									) : activeTab === 'activity' ? (
										<></>
									) : (
																<></>
															)}
								</div>
							)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StockSearch;
