import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { InputText } from '../../components';
import Translate from '../../constants/translate';
import Papa from 'papaparse'
import _ from 'lodash';
import { Progress } from 'antd';
import toast from 'toasted-notes'
import CommonHelper from '../../services/common';
import Swal from 'sweetalert2'
import downloadFile from './import_sms_contact.csv'
import {useDropzone} from 'react-dropzone';

const ImportCSV = props => {
	const {
			acceptedFiles,
			getRootProps, 
			getInputProps,
			isDragActive,
			isDragAccept,
			isDragReject
	} = useDropzone({    
			maxFiles: 1,
	});
	const [errorFields, setErrorFields] = useState({});
	const [file, setFile] = useState(null);
	const [recipientsToBeSet, setRecipientsToBeSet] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const [progress, setProgress] = useState(0);
	const [data, setData] = useState({
        name: '',
        size: '',
	});

	useEffect(() => {
		if(_.isEmpty(acceptedFiles)) 
				return

		const validateFiles = files => {
				// console.log('acceptedFiles', acceptedFiles)
				let filteredFiles = [];

				acceptedFiles.forEach(file => {
						var _size = file.size;
						var filesize = _size / 1024 / 1024;
						if (filesize > 10) {
								Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
						} else {
								filteredFiles.push(file)
						}
				})

				if (filteredFiles.length > 0) {
						onSelectFile(filteredFiles)
				}
		}

		validateFiles(acceptedFiles)
	}, [acceptedFiles])

	const handleFileRemove = () => {
        setFile(null)
        setRecipientsToBeSet([])
        setIsLoading(false)
        setIsFileUploaded(false)
        setProgress(0)
        setData({
            name: '',
            size: '',
        })
    };

    const onSelectFile = (files) => {
			if (!files) {
				return
			}
			if (files && files.length > 0) {
				if (!files[0].name.match(/.(csv)$/i)) {
					Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select CSV file.'), '', 'info')
					return;
				}
			}
			setIsLoading(true)
			let addedFile = files[0];

			setFile(addedFile)
			setData({...data, name: addedFile.name, size: addedFile.size})
			setIsFileUploaded(true)
			Papa.parse(addedFile, {
            complete: updateRecipientsWithCSVData,
            header: true
			});	
	}

    const updateRecipientsWithCSVData = (result)  => {
		if (!result) {
			return
		}
        var data = result.data;
        // console.log(data);
		// const selectedContactsArr = data.filter(contact => contact.phone)
		let selectedContactsArr = data
			.map(contact => {
				return {
					phone: contact.Phone ? contact.Phone : contact.phone ? contact.phone : '',
					phoneCode: '',
					firstName: contact.FirstName ? contact.FirstName : contact['First Name'] ? contact['First Name'] : contact['first name'] ? contact['first name'] : contact['first_name'] ? contact['first_name'] : '',
					middleName: contact.MiddleName ? contact.MiddleName : contact['Middle Name'] ? contact['Middle Name'] : contact['middle name'] ? contact['middle name'] : contact['middle_name'] ? contact['middle_name'] : '',
					lastName: contact.LastName ? contact.LastName : contact['Last Name'] ? contact['Last Name'] : contact['last name'] ? contact['last name'] : contact['last_name'] ? contact['last_name'] : '',
					documentID: window.firebase.firestore().collection('campaignRecipients').doc().id,
					clientID: props.dealersettings.client.id,
        	addedBy: localStorage.uid
				};
			});
		selectedContactsArr = selectedContactsArr.filter(a => a.phone || a.firstName || a.lastName)
		let contactWithNoPhone = props.recipients.filter(contact => !contact.phone)
		let contactWithPhone = props.recipients.filter(contact => contact.phone)
		let csvContactWithNoPhone = selectedContactsArr.filter(contact => !contact.phone)
		let csvContactWithPhone = selectedContactsArr.filter(contact => contact.phone)
		contactWithPhone = _.uniqBy([...csvContactWithPhone ,...contactWithPhone], 'phone')
		contactWithNoPhone = [...csvContactWithNoPhone ,...contactWithNoPhone]
		setRecipientsToBeSet(csvContactWithPhone);
        setTimeout(() => {
            if (progress !== 100) {
                setProgress(progress + 50)
                setTimeout(() => {
                        setProgress(100)
                        setIsLoading(false)
                        // toast.notify('File added successfully', {
                        //     duration: 2000
                        // })
                }, 500)
            }
        }, 500)
    }


    const importContacts = () => {
			if (recipientsToBeSet.length > 0) {
				// props.setRecipients(recipientsToBeSet);
				
				props.handleClose(recipientsToBeSet)
			} else {
				toast.notify('No recipients file added yet', {
					duration: 2000
				})
			}
    }

	const baseStyle = {
		width: '100%',
		background: '#f2f7ff',
		border: '2px dashed #a7cbff',
		minHeight: '180px',
		float: 'left',
		textDecoration: 'none',
		color: '#4466f2',
	};
		
	const activeStyle = {
		border: '2px dashed #2bb673',
	};
		
	const acceptStyle = {
			borderColor: '#2bb673',
	};
		
	const rejectStyle = {
			borderColor: '#ff1744'
	};

	const style = useMemo(() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
	}), [
			isDragActive,
			isDragReject,
			isDragAccept
	]);
	
	return (
		<Modal
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			backdropClassName={props.show ? 'overlay-modal active' : ''}
			dialogClassName='modal-dialog-centered modal-file-manager'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						{' '}
						<Translate text={'Import CSV'} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='upload-file-box'>
							{/* <div className={`upload-box ${errorFields['url']}`}>
								<label
									className='upload-iconwrap'
									htmlFor='fileUpload'
									style={{ margin: 0, cursor: 'pointer' }}
								>
									<i className='ico icon-files-upload'></i>
									<span className='upload-text'>
										<Translate text={'Upload File'} />
									</span>
									<input
										type='file'
										id='fileUpload'
										style={{ display: 'none' }}
										onChange={e => onSelectFile(e)}
                                        onDrop={e => onSelectFile(e)}
									/>
								</label>
							</div> */}
							<div {...getRootProps({style, className: 'upload-iconwrap upload-box-dragndrop'})}>
									<input {...getInputProps()} />
									<i className="ico icon-files-upload"></i>
									<p>Drag 'n' drop csv file here, or click to upload file</p>
							</div>

							{isFileUploaded ? (
								<div className='uploaded-list-video'>
									<div className='flex-upload'>
										<div className='upload-clear'>
											<a
												href='#'
												onClick={e => handleFileRemove(e)}
												className={`${isLoading ? 'form-disable' : ''}`}
											>
												<i className='fa fa-times' aria-hidden='true'></i>
											</a>
										</div>
										<div className='file-upload-icon'>
                                            <i className="ico icon-files-excel"></i>
										</div>
										<div className='upload-data'>
											{data.name}
											{' (' + CommonHelper.showFileSize(data.size) + ')'}
										</div>
									</div>
									{isLoading ? <Progress percent={progress} /> : <></>}
								</div>
							) : (
								<></>
							)}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className={`btn btn-primary float-right`}
					onClick={importContacts}
				>
					<Translate text={'Import'} />
				</button>
				<a
					href={downloadFile}
					download='import_sms_contact.csv'
					className='btn btn-default float-left'
				>
					<Translate text={'Download Sample'} />
				</a>
			</Modal.Footer>
		</Modal>
	);
};

export default ImportCSV;
