import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect, AntDateRangePicker } from "../../components";
import { inboundleadCountVM } from './viewModel'
import _images from '../../images'
import CommonHelper from '../../services/common';
import { PopUpModal } from '../../components'
import WidgetSettings from './widgetSettingsModal'

const InboundLeadCounts = (props) => {
    const [counts, setCounts] = useState(inboundleadCountVM)
    const [userOptions, setUserOptions] = useState([])
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [dates, setDates] = useState([]);
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const enquiryStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.settings) &&
        props.dealersettings.client.settings.enquiryStatus) ? props.dealersettings.client.settings.enquiryStatus : []);


    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p === 'LeadsBucket')).map(rec => { return rec.id }) : [];
        let _users = [];
        _.filter(props.clientUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    useEffect(() => {
        if (!_.isArray(props.inboundleadEnquiries))
            return

        var _pipelines = _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }
        setCounts({
            totalPending: props.inboundleadEnquiries.filter(a =>
                a.pipelineValue === 'LeadsBucket'
                && (a.stageValue !== 'Archived' && a.statusValue !== 'prospectLost' && a.statusValue !== 'lost')
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'pending')) : true)
                && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true)),
            todayPending: props.inboundleadEnquiries.filter(a =>
                a.pipelineValue === 'LeadsBucket'
                && (a.stageValue !== 'Archived' && a.statusValue !== 'prospectLost' && a.statusValue !== 'lost')
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'pending')) : true)
                && a.stageDateValue.seconds >= todayStart.seconds
                && a.stageDateValue.seconds <= todayEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true)),
            monthPending: props.inboundleadEnquiries.filter(a =>
                a.pipelineValue === 'LeadsBucket'
                && (a.stageValue !== 'Archived' && a.statusValue !== 'prospectLost' && a.statusValue !== 'lost')
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'pending') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'pending')) : true)
                && a.stageDateValue.seconds >= monthStart.seconds
                && a.stageDateValue.seconds <= monthEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? a.ownerValue === props.selectedUser.value : true)),
            todayConverted: props.inboundleadEnquiries.filter(a =>
                (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= todayStart.seconds
                    && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.isConverted === true
                            && a.convertedDate.seconds >= todayStart.seconds
                            && a.convertedDate.seconds <= todayEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthConverted: props.inboundleadEnquiries.filter(a =>
                (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                            && a.isConverted === true
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.convertedDate.seconds >= monthStart.seconds
                            && a.convertedDate.seconds <= monthEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayShow: props.inboundleadEnquiries.filter(a =>
                a.bdcStatus === 'show'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= todayStart.seconds
                    && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds)) : (!_.isEmpty(a.firstWalkinDate)
                            && a.isConverted === true
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.firstWalkinDate.seconds >= todayStart.seconds
                            && a.firstWalkinDate.seconds <= todayEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthShow: props.inboundleadEnquiries.filter(a =>
                a.bdcStatus === 'show'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.firstWalkinDate)
                            && a.isConverted === true
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.firstWalkinDate.seconds >= monthStart.seconds
                            && a.firstWalkinDate.seconds <= monthEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayNoshow: props.inboundleadEnquiries.filter(a =>
                a.bdcStatus === 'noshow'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= todayStart.seconds
                    && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= todayStart.seconds
                        && a.oemConvertedDate.seconds <= todayEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                            && a.isConverted === true
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.convertedDate.seconds >= todayStart.seconds
                            && a.convertedDate.seconds <= todayEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthNoshow: props.inboundleadEnquiries.filter(a =>
                a.bdcStatus === 'noshow'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted' && a.oemConvertedDate
                    && a.oemConvertedDate.seconds >= monthStart.seconds
                    && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds) ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted' && a.oemConvertedDate
                        && a.isConvertedGroup === true
                        && a.oemConvertedDate.seconds >= monthStart.seconds
                        && a.oemConvertedDate.seconds <= monthEnd.seconds)) : (!_.isEmpty(a.convertedDate)
                            && a.isConverted === true
                            && a.pipelineValue !== 'LeadsBucket'
                            && a.convertedDate.seconds >= monthStart.seconds
                            && a.convertedDate.seconds <= monthEnd.seconds))
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayWon: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= todayStart.seconds
                && a.wonDate.seconds <= todayEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthWon: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && (a.statusValue.toLowerCase() === 'won' || a.statusValue.toLowerCase() === 'delivered')
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= monthStart.seconds
                && a.wonDate.seconds <= monthEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayLost: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'lost'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= todayStart.seconds
                && a.lostDate.seconds <= todayEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthLost: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'lost'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= monthStart.seconds
                && a.lostDate.seconds <= monthEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayDelivered: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'delivered'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= todayStart.seconds
                && a.deliveredDate.seconds <= todayEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            monthDelivered: props.inboundleadEnquiries.filter(a => !_.isEmpty(a.statusValue) && a.statusValue.toLowerCase() === 'delivered'
                && (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'region' && a.oemStatus === 'converted') ||
                    (props.dealersettings.level === 'group' && a.oemStatus === 'converted'
                        && a.isConvertedGroup === true)) : (
                    !_.isEmpty(a.convertedDate)
                    && a.isConverted === true
                    && a.pipelineValue !== 'LeadsBucket'
                ))
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= monthStart.seconds
                && a.deliveredDate.seconds <= monthEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? (localStorage.defaultModule === 'oem' ? ((props.dealersettings.level === 'oem' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'region' && a.oemConvertedBy === props.selectedUser.value) ||
                    (props.dealersettings.level === 'group' && a.oemConvertedBy === props.selectedUser.value)) : a.convertedBy === props.selectedUser.value) : true)),
            todayActivity: props.allActivities.filter(a => !_.isEmpty(a.modifiedDate)
                && a.isDone === true
                && a.modifiedDate.seconds >= todayStart.seconds
                && a.modifiedDate.seconds <= todayEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? _.filter(props.selectedUser.value.split(','), (v) => _.indexOf(a.searchUsers, v) >= 0).length > 0 : true)
                && (!_.isEmpty(props.selectedPipeline) ? (!_.isEmpty(a.enquiry) ? a.enquiry.pipeline === props.selectedPipeline.value : false) : true)),
            monthActivity: props.allActivities.filter(a => !_.isEmpty(a.modifiedDate)
                && a.isDone === true
                && a.modifiedDate.seconds >= monthStart.seconds
                && a.modifiedDate.seconds <= monthEnd.seconds
                && (!_.isEmpty(props.selectedUser) ? _.filter(props.selectedUser.value.split(','), (v) => _.indexOf(a.searchUsers, v) >= 0).length > 0 : true)
                && (!_.isEmpty(props.selectedPipeline) ? (!_.isEmpty(a.enquiry) ? a.enquiry.pipeline === props.selectedPipeline.value : false) : true)),
            overdueActivity: props.allActivities.filter(a => !_.isEmpty(a.startDate)
                && a.isDone === false
                && a.startDate.seconds < todayStart.seconds
                && (!_.isEmpty(props.selectedUser) ? _.filter(props.selectedUser.value.split(','), (v) => _.indexOf(a.searchUsers, v) >= 0).length > 0 : true)
                && (!_.isEmpty(props.selectedPipeline) ? (!_.isEmpty(a.enquiry) ? a.enquiry.pipeline === props.selectedPipeline.value : false) : true)),
        })

    }, [props.inboundleadEnquiries, props.allActivities, props.testdrives, props.tradeins, props.selectedUser, props.selectedPipeline])

    return (
        <>
            <div className="dashboard-panel-top">
                <div className="dashboard-panel-head">
                    <div className="float-left  d-flex">
                        <h3>Inbound Lead Stats</h3>
                    </div>
                    <div className="dash-widget-settings-wrap">
                        <a href="#" className="dropdown-toggle" onClick={props.handleOpenWidgetSettings}>
                            <i className="ico icon-settings"></i>
                        </a>
                    </div>
                    <div className="float-lg-right form-dashboard">
                        <div className="form-row justify-content-end">
                            <div className="form-group col-md-4 daterange-fix">
                                <AntDateRangePicker
                                    value={props.dateRange && props.applydateRange === true ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                                    id={"dashboard-date-range"}
                                    name={'date'}
                                    format='DD/MM/YYYY'
                                    onChange={(e) => { props.handleDateRangeChange(e, 'date') }}
                                    placeholder='DD/MM/YYYY'
                                    onCalendarChange={(value) => {
                                        if (value) {
                                            const [start, end] = value;
                                            setDates([start, end]);
                                        }
                                        else {
                                            setDates([])
                                        }
                                    }}
                                    disabledDate={disabledDate}
                                />
                            </div>
                            {
                                !ownerDashboard ? (<div className="form-group col-md-3"><ReactSelect
                                    options={userOptions}
                                    name={"user"}
                                    placeholder={'select user'}
                                    onChange={(e, data) => {
                                        props.setUser(e)
                                    }}
                                    value={!_.isEmpty(props.selectedUser) ? props.selectedUser.value : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect> </div>) : (<></>)
                            }
                        </div>
                    </div>
                </div>
                <div className="dashboard-panel-body">
                    <div className=" row ">
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-pending-leads"></i></div>
                                        <div className="dashboard-module-label"> Pending</div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                <div className="col-lg-12" onClick={() => {
                                                    if (counts.totalPending.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.totalPending.map(rec => { return rec.documentID })
                                                        }, 'Pending Lead Bucket')
                                                    }
                                                }}>
                                                    <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.totalPending.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-allocated-leads"></i></div>
                                        <div className="dashboard-module-label">Allocated</div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayConverted.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayConverted.map(rec => { return rec.documentID })
                                                                }, 'Today Allocated Leads')
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayConverted.length}</a></div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthConverted.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthConverted.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? 'MTD Allocated Leads' : 'Allocated Leads'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthConverted.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-show-leads"></i></div>
                                        <div className="dashboard-module-label"> Show</div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayShow.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayShow.map(rec => { return rec.documentID })
                                                                }, 'Today Show Leads')
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayShow.length}</a></div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthShow.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthShow.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? 'MTD Show Leads' : 'Show Leads'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthShow.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-noshow-leads"></i></div>
                                        <div className="dashboard-module-label"> No Show</div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayNoshow.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayNoshow.map(rec => { return rec.documentID })
                                                                }, 'Today No Show Leads')
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayNoshow.length}</a></div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthNoshow.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthNoshow.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? 'MTD No Show Leads' : 'No Show Leads'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthNoshow.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, `ico icon-won-leads`)}></i></div>
                                        <div className="dashboard-module-label">
                                            {
                                                _.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name} Leads` : 'Won Leads'
                                            }
                                        </div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayWon.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayWon.map(rec => { return rec.documentID })
                                                                }, `Today ${_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name} Leads` : 'Won Leads'}`)
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayWon.length}</a></div>
                                                        </div>
                                                    ) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthWon.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthWon.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? `MTD ${_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name} Leads` : 'Won Leads'}` : _.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name} Leads` : 'Won Leads'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthWon.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-lost-leads")}></i></div>
                                        <div className="dashboard-module-label">
                                            {
                                                _.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name} Leads` : 'Lost Leads'
                                            }
                                        </div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayLost.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayLost.map(rec => { return rec.documentID })
                                                                }, `Today ${_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name} Leads` : 'Lost Leads'}`)
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayLost.length}</a></div>
                                                        </div>) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthLost.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthLost.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? `MTD ${_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name} Leads` : 'Lost Leads'}` : _.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name} Leads` : 'Lost Leads'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthLost.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-delivered")}></i></div>
                                        <div className="dashboard-module-label">
                                            {
                                                _.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'
                                            }
                                        </div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayDelivered.length > 0) {
                                                                props.handleShowEnquiryList({
                                                                    enquiryIDs: counts.todayDelivered.map(rec => { return rec.documentID })
                                                                }, `Today ${_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'} Leads`)
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayDelivered.length}</a></div>
                                                        </div>) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthDelivered.length > 0) {
                                                        props.handleShowEnquiryList({
                                                            enquiryIDs: counts.monthDelivered.map(rec => { return rec.documentID })
                                                        }, (props.applydateRange === false ? `MTD ${_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'} Leads` : `${_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'} Leads`))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthDelivered.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-activities "></i></div>
                                        <div className="dashboard-module-label"> Activities </div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                {
                                                    props.applydateRange === false ? (
                                                        <div className="col-lg-6" onClick={() => {
                                                            if (counts.todayActivity.length > 0) {
                                                                props.handleShowActivityList({
                                                                    startDate: todayStart,
                                                                    endDate: todayEnd,
                                                                    isDone: true,
                                                                    dateType: 'modifiedDate'
                                                                }, 'Today Activities')
                                                            }
                                                        }}>
                                                            <div className="dashboard-count-title"> Today</div>
                                                            <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.todayActivity.length}</a></div>
                                                        </div>) : (<></>)
                                                }
                                                <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                    if (counts.monthActivity.length > 0) {
                                                        props.handleShowActivityList({
                                                            startDate: monthStart,
                                                            endDate: monthEnd,
                                                            isDone: true,
                                                            dateType: 'modifiedDate'
                                                        }, (props.applydateRange === false ? 'MTD Activities' : 'Activities'))
                                                    }
                                                }}>
                                                    {
                                                        props.applydateRange === false ?
                                                            (
                                                                <div className="dashboard-count-title">MTD</div>
                                                            ) : (<></>)
                                                    }
                                                    <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.monthActivity.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 dashicon-fix">
                                    <div className="dashboard-module-loop dashboard-module-flex">
                                        <div className="dashboard-module-icon"><i className="ico icon-overdue-activities "></i></div>
                                        <div className="dashboard-module-label"> Overdue Activities </div>
                                        <div className="dashboard-module-count-wraper">
                                            <div className=" row">
                                                <div className="col-lg-12" onClick={() => {
                                                    if (counts.overdueActivity.length > 0) {
                                                        props.handleShowActivityList({
                                                            isDone: false,
                                                            endDate: todayStart,
                                                            dateType: 'startDate'
                                                        }, 'Overdue Activities')
                                                    }
                                                }}>
                                                    <div className="dashboard-count-value overdue-fix"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.overdueActivity.length > 99 ? `99+` : counts.overdueActivity.length}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <PopUpModal show={props.showWidgetSettings}>
                <WidgetSettings
                    {...props}
                    show={props.showWidgetSettings}
                    handleClose={props.handleCloseWidgetSettings}
                    title={'BDC Dashboard Settings'}
                    sortedWidgets={props.sortedWidgets}
                    hiddenWidgets={props.hiddenWidgets}
                    removeUnAllowedWidgets={props.removeUnAllowedWidgets}
                    onLayoutChange={props.onLayoutChange}
                    layout={props.layout}
                    setLayout={props.setLayout}
                ></WidgetSettings>
            </PopUpModal>
        </>
    );
}

export default InboundLeadCounts;