import React, { useState, useEffect } from 'react';
import { TableView } from '../../../../components';
import { allreportFields } from '../viewModel';
import _ from 'lodash'
import { PopUpModal, ContentOverlay } from '../../../../components';
import CampaignPreview from '../campaignPreview';
import Translate from '../../../../constants/translate';
import axios from 'axios';
import moment from 'moment'

const ReportsList = ({ campaigns, showAddCampaign, apiURL, setShowAddCampaign, apiUrl, API_KEY, SERVER_PREFIX, onCreateCampaign, loader }) => {
	const [reports, setReports] = useState([]);
	const [reportsLoader, setReportsLoader] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});
	const [showPreview, setShowPreview] = useState({
			show: false,
			previewID: '',
			title: '',
      data: null
	})
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 330,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

  useEffect(() => {
		const fetchCampaigns = async () => {
			if (_.isEmpty(campaigns)) {
				return
			}
			try {
				const response = await axios.post(`${apiURL}visionrest-api/api/visionrest/get-batches`, {
					API_KEY: API_KEY,
				})
        //console.log('get batches resp', response)
				if (response && response.data && response.data.batches) {
					let converted = response.data.batches.map(campaign => {
						return convertReportVM(campaign);
					});
					 converted = _.orderBy(converted,['creation_time'],['desc'])
					setReports(converted);
				}
				setReportsLoader(false)
			} catch (error) {
				setReportsLoader(false)
				console.log(error)
			}
		}
		fetchCampaigns()
	}, [campaigns]);


	useEffect(() => {
		const interval = setInterval(() => {
			loadData();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	const loadData = async () => {
		try {
			if (_.isEmpty(campaigns)) {
				return
			}
			const response = await axios.post(`${apiURL}visionrest-api/api/visionrest/get-batches`, {
				API_KEY: API_KEY,
			})
			if (response && response.data && response.data.batches) {
				let converted = response.data.batches.map(campaign => {
					return convertReportVM(campaign);
				});
				converted = _.orderBy(converted,['creation_time'],['desc'])
				setReports(converted);
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleTemplateModalClose  = () => {
		setShowPreview({
			show: false,
			previewID: '',
			title: 'Campaign Report',
      data: null
		})
	}

	const handleActionClick = id => {
		let currentCampaign = reports.filter(campaign => campaign.documentID === id)[0]
		return (
			<>
				<div
					style={{ cursor: 'pointer' }}
					className='mini-button mr-1'
					onClick={e => {
    				console.log('handleActionClick', currentCampaign)
						setShowPreview({
							show: true,
							previewID: currentCampaign.id,
							title: 'Campaign Report',
              data: currentCampaign
						})
					}}
				>
					<i className='ico icon-zoom'></i>
				</div>
			</>
		);
	};

	const handleCampaigndetailsClick = (e, id) => {
		e.preventDefault();
		let currentCampaign = reports.filter(campaign => campaign.documentID === id)[0]

		setShowPreview({
			show: true,
			previewID: currentCampaign.id,
			title: 'Campaign Report',
			data: currentCampaign
		})
	};

  const filterCampaigns = (report) => {
    let returnVal = true

    if (searchText.length > 0 && !report.nameText.includes(searchText)) {
      returnVal = false
    }
    return returnVal
  }

  

  const convertReportVM = doc => {
		let objToReturn = { ...doc };
    const percentageSent = ((100 * doc.send_current_count) / doc.send_contact_count)
    //last_sent_time
		objToReturn.documentID = doc.id;
		objToReturn.nameText = campaigns.filter(campaign => campaign.id === doc.message_id).length ?
      campaigns.filter(campaign => campaign.id === doc.message_id)[0].nameText : '';

		objToReturn.name = (
			<div className='campaign-subject'>
				{campaigns.filter(campaign => campaign.id === doc.message_id).length ?
          campaigns.filter(campaign => campaign.id === doc.message_id)[0].name : ''}
			</div>
		);
    objToReturn.stats = (
			<>
				{`${doc.send_contact_count} of ${doc.send_current_count} Sent (${percentageSent})%`}
			</>
		);
		objToReturn.statusToShow = (
			<div
				className={`badge badge-pill badge-${doc.send_status === 'completed'
						? 'available'
						: (doc.send_status === 'initialising' || doc.send_status === 'pending' || doc.send_status === 'sending' )
							? 'pending'
							: (doc.send_status === 'failed' || doc.send_status === 'paused' || doc.send_status === 'cancelled')
								? 'draft'
								: 'draft'
					}-fill `}
			>
				{doc.send_status ? doc.send_status : ''}
			</div>
		);
		objToReturn.lastSent = (
			<div className='user-list-items-info '>
				<h4>{moment.unix(doc.last_sent_time).format('DD/MM/YYYY hh:mm A')}</h4>
				{/* <h5>Williams David</h5> */}
			</div>
		);
		objToReturn.lastSentOn = moment.unix(doc.last_sent_time).format('DD/MM/YYYY hh:mm A')

		return objToReturn;
	};

	return (
		<div className='campaign-right-panel'>
			<div className='campaign-list-container'>
				<h2>All Reports</h2>
				<div className='divider-line'></div>
				<div className='campaign-list-filter'>
					<div className='float-left'>
						<div className='filter-search search-icon'>
							<input placeholder='search' aria-label='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
						</div>
					</div>
				</div>
				<div className='campaign-list-table'>
					{(loader || reportsLoader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
						<div className='common-table'>
							{reports.length > 0 ? (
								<TableView
									isReorderable={false}
									datalist={reports.filter(report => filterCampaigns(report))}
									height={windowSize.windowHeight}
									width={windowSize.windowWidth}
									columns={allreportFields}
									dynamicFields={allreportFields
										.filter(e => e.default === true)
										.map(v => _.pick(v, ['value']).value)}
									settingsLoader={false}
									handleActionClick={handleActionClick}
									handleRowSingleClick={handleCampaigndetailsClick}
									isSettings={false}
									hasMore={false}
									isPaging={false}
								/>
							) : (
								<div className="text-center p-5">
										<p><Translate text={'No reports found'} /></p>
										<button className="btn btn-sm btn-default" onClick={onCreateCampaign}><i className="ico icon-add mr-2 pt-1 float-left"></i>
												<Translate text={'Create Campaign'} />
										</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<PopUpModal show={showPreview.show}>
					<CampaignPreview
						show={showPreview.show}
						handleClose={handleTemplateModalClose}
						title={showPreview.title}
						campaign_id={showPreview.previewID}
						data={showPreview.data}
						apiUrl={apiUrl}
						API_KEY={API_KEY}
						SERVER_PREFIX={SERVER_PREFIX}
					/>
				</PopUpModal>
		</div>
	);
};

export default ReportsList;
