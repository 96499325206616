/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    InputTextArea,
    AntDatePicker,
    ReactSelect,
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { objWorkLog } from '../viewModel';
import { firestoreDB } from '../../../services/helper';

let _hours = [];
let _minutes = [];
let _seconds = [];

export default class AddWorkLog extends Component {
    constructor(props) {
        super(props);
        this.newDocID = null;
        this.isLoading = false;
        this.mandatoryFields = [];
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs');
        this.state = {
            fields: Object.assign({}, objWorkLog),
            errors: {}
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, workLog, serviceID, allTechnicianUsers, groupUsers, clientUsers } = props;
        const fields = Object.assign({}, objWorkLog);

        _hours = [];
        for (var i = 0; i <= 23; i++) {
            _hours.push({ value: i.toString(), label: i.toString() + ' Hour', active: true });
        }

        _minutes = [];
        _seconds = [];
        for (var i = 0; i < 60; i++) {
            _minutes.push({ value: i.toString(), label: i.toString() + ' Minute', active: true });
            _seconds.push({ value: i.toString(), label: i.toString() + ' Seconds', active: true });
        }

        if (show === true && !_.isEmpty(workLog) && serviceID) {
            this.newDocID = workLog.documentID;
            const objData = Object.assign({}, workLog);

            for (let [key, value] of Object.entries(objData)) {
                fields[key] = value;
            }

            fields.hours = '0';
            fields.seconds = '0';
            fields.minutes = '0';

            if (fields.timeSpent > 0) {
                var duration = moment.duration(fields.timeSpent, 'seconds');
                duration = moment.duration(duration, 'milliseconds');

                fields.hours = duration.hours().toString();
                fields.minutes = duration.minutes().toString();
                fields.seconds = duration.seconds().toString();
            }

            this.setState({ fields: fields });
        }
        else if (show === true && _.isEmpty(workLog) && serviceID) {
            this.newDocID = this.ref.doc().id;
            fields.documentID = this.newDocID;
            fields.mannual = true;
            fields.hours = '0';
            fields.seconds = '0';
            fields.minutes = '0';
            fields.startTime = window.firebase.firestore.Timestamp.now();


            let _currentUser = allTechnicianUsers && allTechnicianUsers.filter(v => v.value === localStorage.uid)[0];
            if (!_.isEmpty(_currentUser)) fields.addedBy = localStorage.uid;

            fields.description = 'Manually Logged by ' + CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, localStorage.uid);

            this.setState({ fields: fields });
        }

    }


    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = null;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleOnDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''

        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }



    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = async (e) => {
        e.preventDefault();

        let _errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        // let errorClass = 'input_error';
        // const { errors, serviceID, dealersettings } = this.props;
        // for (let [key, value] of Object.entries(fields)) {
        //     if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
        //         if (!fields[key] && key !== 'totalCost') {
        //             formIsValid = false;
        //             _errors[key] = errorClass;
        //         }
        //     }

        //     if (key === 'roNumber' && !_.isEmpty(value) && (_.isEmpty(errors) || (!_.isEmpty(errors) && errors['roNumber']))) {
        //         if (await CommonHelper.checkServiceROExist(dealersettings.client.id, serviceID, value) === true) {
        //             Swal.fire('RO Number already exists', '', 'info');

        //             formIsValid = false;
        //             _errors[key] = errorClass;
        //         }
        //     }
        // }

        // this.setState({ errors: _errors });

        if (formIsValid === true) {
            this.handleSave();
        }
        else
            return formIsValid;

    };

    handleSave = async () => {
        this.isLoading = true;

        const objData = Object.assign({}, this.state.fields);
        const { serviceID, dealersettings, workLog } = this.props;

        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (_.isEmpty(workLog)) {
            objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
            objData.clientID = dealersettings ? dealersettings.client.id : '';
            objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.serviceID = serviceID;
            objData.addedBy = localStorage.uid;
        }

        if (objData.mannual) {
            var duration = moment.duration(parseInt(objData.hours), 'hours').add(parseInt(objData.minutes), 'minutes').add(parseInt(objData.seconds), 'seconds');
            duration = moment.duration(duration, 'milliseconds');
            objData.timeSpent = duration.asSeconds();
        }

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }

            if (['hours', 'minutes', 'seconds'].includes(propName)) delete objData[propName];
        }

        firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/workLogs/${objData.documentID}`).set(objData, { merge: true }).then((docRef) => {
            this.handleModalClose(this.newDocID);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    }



    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { description, addedBy, startTime, mannual } = this.state.fields;
        const { fields } = this.state;
        const { show, allTechnicianUsers, workLog } = this.props;

        return (
            <>
                <Modal
                    id="modal-add-service-add-log"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={!_.isEmpty(workLog) ?'Work Log' : 'Add Work Log'} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label><Translate text={'timeSpent'} /></label>
                                            <ReactSelect
                                                options={_hours}
                                                name={"hours"}
                                                placeholder={'select hours'}
                                                onChange={this.handleSelectSave}
                                                value={fields.hours ? fields.hours : ''}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["hours"]} basic-select`}
                                                isSearchable={true}
                                                removeClearable={true}
                                                isDisabled={!mannual}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>&nbsp;</label>
                                            <ReactSelect
                                                options={_minutes}
                                                name={"minutes"}
                                                placeholder={'select minutes'}
                                                onChange={this.handleSelectSave}
                                                value={fields.minutes ? fields.minutes : ''}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["minutes"]} basic-select`}
                                                isSearchable={true}
                                                removeClearable={true}
                                                isDisabled={!mannual}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label>&nbsp;</label>
                                            <ReactSelect
                                                options={_seconds}
                                                name={"seconds"}
                                                placeholder={'select seconds'}
                                                onChange={this.handleSelectSave}
                                                value={fields.seconds ? fields.seconds : ''}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["seconds"]} basic-select`}
                                                isSearchable={true}
                                                removeClearable={true}
                                                isDisabled={!mannual}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label><Translate text={'description'} /></label>
                                            <InputTextArea
                                                className={`form-control notes ${this.state.errors["description"]}`}
                                                name="description"
                                                onChange={this.handleOnAreaChange}
                                                value={description}
                                                maxLength="500"
                                                rows="3">
                                            </InputTextArea>
                                        </div>
                                    </div>

                                    <div className="form-row">

                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Technician'} /> </label>
                                            <ReactSelect
                                                options={allTechnicianUsers}
                                                name={"addedBy"}
                                                placeholder={'select technician'}
                                                onChange={this.handleSelectSave}
                                                value={addedBy ? addedBy : ''}
                                                classNamePrefix={`cursor-pointer ${this.state.errors["addedBy"]} basic-select`}
                                                isSearchable={true}
                                                removeClearable={true}
                                                isDisabled={!mannual}
                                            >
                                            </ReactSelect>
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'date'} /></label>
                                            <AntDatePicker
                                                value={startTime ? moment.unix(startTime.seconds).format('YYYY-MM-DD') : null}
                                                name={'startTime'}
                                                onChange={(e) => { this.handleOnDateChange(e, 'startTime') }}
                                                format='DD/MM/YYYY'
                                                placeholder='DD/MM/YYYY'
                                                className={`form-control ${this.state.errors["startTime"]}`}
                                                disabledDate={(current) => { return current && current > moment().endOf('day') }}
                                                allowClear={false}
                                                readOnly={!mannual}
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
