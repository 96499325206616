import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';

const AddLanguage = (props) => {
    const [language, setLanguage] = useState(props.language);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.language)) {
            setLanguage({
                addedBy: localStorage.uid,
                addedDate: window.firebase.firestore.Timestamp.now(),
            })
        }
    }, [])

    const handleReactSelectChange = (e, data) => {
        setLanguage({
            ...language,
            [data.name]: e ? e.value : null,
            ['name']: e ? e.label : null,
        });
    }

    const saveLanguage = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(language['code'])) {
            formIsValid = false;
            errors['code'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete language.active;
        window.firebase.firestore().doc(`${props.path}/${language.code}`).set(language, { merge: true })
            .then(snapshot => {
                let deleteLanguage = null;
                if (props?.language?.code && props.language.code !== language.code) {
                    deleteLanguage = props.language.code;
                    window.firebase.firestore().doc(`${props.path}/${props.language.code}`).delete();
                }
                setLoader(false)
                toast.notify('Language updated successfully', {
                    duration: 2000
                })
                props.handleClose(language, deleteLanguage);
            })
    }

    return _.isEmpty(language) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Language'} /></label>
                                    <ReactSelect
                                        options={props.dealersettings.languages && props.dealersettings.languages.map((doc) => {
                                            return {
                                                value: doc.code,
                                                active: _.isBoolean(doc.active) ? doc.active : true,
                                                label: doc.name
                                            };
                                        })}
                                        name={"code"}
                                        placeholder={'select language'}
                                        onChange={handleReactSelectChange}
                                        value={language.code}
                                        classNamePrefix={`${errorFields["code"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLanguage(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddLanguage;