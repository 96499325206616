import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from './pipelineCounts'
import { initialLayoutArr } from './viewModel';
import DragDropLayout from './dragDropLayout';
import { firestoreDB } from '../../services/helper';
import CommonHelper from '../../services/common';
import FleetList from './fleetList';
import FleetHistoryList from './fleethistoryList';
import { PopUpModal } from '../../components';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import { levelOptions } from './viewModel';
import { userLevel } from '../../services/enum';

const Dashboard = (props) => {
    const [loader, setLoader] = useState(true)
    const [monthData, setMonthData] = useState([])
    const [modelShow, setModelShow] = useState({ show: false })
    const [modelassginShow, setModelAssignShow] = useState({ show: false })
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month').toDate()),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month').toDate()),
        date: moment().format('YYYY-MM-DD')
    })
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState(true)
    const [dashboardFilter, setDashboardFilter] = useState({})
    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/fleetprodashboardSettings/${localStorage.uid}`);
    const _limit = 30
    const accessToApprove = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToApprove;
    const accessToReturn = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToReturn;
    const accessToReceive = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToReceive;
    // useEffect(() => {
    //     if (ownerDashboard)
    //         setUser({ value: props.dealersettings.id })
    // }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
            window.unsubrefMonthFleetData && window.unsubrefMonthFleetData.unsubscribe();
        }
    }, [])

    useEffect(() => {
        if (props.dealersettings.level === 'individual')
            return
        var collectionPromise = [];
        let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        let _levelIDNAme = CommonHelper.levelBasedID(props.dealersettings.level);
        //console.log('firebase_level==>', level.value, _levelIDs)
        for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
            let refstatusdateMinData = window.firebase.firestore().collection('fleetPro')
                .where('isDeleted', '==', false)
                .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                //.where('dateMin', '>=', dateRange.startDate)
                //.where('dateMin', '<=', dateRange.endDate)

            // let refstatusdateMaxData = window.firebase.firestore().collection('fleetPro')
            //     .where('isDeleted', '==', false)
            //     .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
            //     .where('dateMax', '>=', dateRange.startDate)
            //     .where('dateMax', '<=', dateRange.endDate)

            // let refaddeddateData = window.firebase.firestore().collection('fleetPro')
            //     .where('isDeleted', '==', false)
            //     .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
            //     .where('addedDate', '>=', dateRange.startDate)
            //     .where('addedDate', '<=', dateRange.endDate)
            //     .orderBy('addedDate', 'desc')

            let refeventData = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where(`fleetPro.${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                .limit(100)
                // .where('startDate', '>=', dateRange.startDate)
                // .where('startDate', '<=', dateRange.endDate)
                // .orderBy('startDate', 'desc')

            collectionPromise.push(refstatusdateMinData ? collection(refstatusdateMinData) : of([]));
            //collectionPromise.push(refstatusdateMaxData ? collection(refstatusdateMaxData) : of([]));
            //collectionPromise.push(refaddeddateData ? collection(refaddeddateData) : of([]));
            collectionPromise.push(refeventData ? collection(refeventData) : of([]));
        }
        if (collectionPromise.length > 0) {
            window.unsubrefMonthFleetData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach(doc => {
                        if (doc?.ref?.parent?.path === 'fleetPro') {
                            _fleetpro.push(CommonHelper.convertFleetProVM({ ...doc.data(), id: doc.id, path: 'fleetPro' }, props));
                        } else {
                            _fleetpro.push({
                                ...CommonHelper.convertFleetProAssignVM(doc.data(), props),
                                id: `assign-${doc.id}`,
                                path: 'fleetProAssign',
                                regionID: doc.data()?.fleetPro?.regionID || null,
                                subregionID: doc.data()?.fleetPro?.subregionID || null,
                                groupID: doc.data()?.fleetPro?.groupID || null,
                                statusAssign: 'booked'
                            })
                        }
                    })
                    setLoader(false)
                    setMonthData([..._.uniqBy(_fleetpro, 'id')]);
                });
        }
        else {
            setLoader(false)
        }
    }, [])

    useEffect(() => {
        if (props.dealersettings.level !== 'individual')
            return
        var collectionPromise = [];
        levelOptions.forEach(rec => {
            let _levelIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, rec.value, true, true);
            let _levelIDNAme = CommonHelper.levelBasedID(rec.value);
            //console.log('firebase_level==>', level.value, _levelIDs)
            for (let k = 0; k < Math.ceil(_levelIDs.length / _limit); k++) {
                let refstatusdateMinData = window.firebase.firestore().collection('fleetPro')
                    .where('isDeleted', '==', false)
                    .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                    // .where('dateMin', '>=', dateRange.startDate)
                    // .where('dateMin', '<=', dateRange.endDate)

                // let refstatusdateMaxData = window.firebase.firestore().collection('fleetPro')
                //     .where('isDeleted', '==', false)
                //     .where(`${_levelIDNAme}`, 'in', CommonHelper.arrPaginate(_levelIDs, _limit, k + 1))
                //     .where('dateMax', '>=', dateRange.startDate)
                //     .where('dateMax', '<=', dateRange.endDate)

                //collectionPromise.push(refstatusdateMaxData ? collection(refstatusdateMaxData) : of([]));
                collectionPromise.push(refstatusdateMinData ? collection(refstatusdateMinData) : of([]));
            }
        })
        const _clientIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, props.dealersettings.level, true, true);
        for (let k = 0; k < Math.ceil(_clientIDs.length / _limit); k++) {
            let refeventData = window.firebase.firestore().collection('fleetProAssign')
                .where('isDeleted', '==', false)
                .where('assignType', '==', 'retailerEvent')
                .where(`clientID`, 'in', CommonHelper.arrPaginate(_clientIDs, _limit, k + 1))
                // .where('startDate', '>=', dateRange.startDate)
                // .where('startDate', '<=', dateRange.endDate)
                // .orderBy('startDate', 'desc')

            collectionPromise.push(refeventData ? collection(refeventData) : of([]));
        }

        if (collectionPromise.length > 0) {
            const _subregionIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, userLevel.SUBREGION, true, true);
            const _groupIDs = CommonHelper.getAllFleetProByLevel(props.dealersettings, userLevel.GROUP, true, true);
            window.unsubrefMonthFleetData = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    var _fleetpro = []
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    querySnapshot.forEach(doc => {
                        if (doc?.ref?.parent?.path === 'fleetPro') {
                            const _fleetprodata = doc.data() || {};
                            if (_fleetprodata?.category === 'marketing' && ((_.isEmpty(_fleetprodata.subregionID) || (!_.isEmpty(_fleetprodata.subregionID) && _subregionIDs.includes(_fleetprodata.subregionID))) ||
                                (_.isEmpty(_fleetprodata.groupID) || (!_.isEmpty(_fleetprodata.groupID) && _groupIDs.includes(_fleetprodata.subregionID))))) {
                                _fleetpro.push(CommonHelper.convertFleetProVM({ ..._fleetprodata, id: doc.id, path: 'fleetPro' }, props));
                            }
                        } else {
                            _fleetpro.push({
                                ...CommonHelper.convertFleetProAssignVM(doc.data(), props),
                                id: `assign-${doc.id}`,
                                path: 'fleetProAssign',
                                regionID: doc.data()?.fleetPro?.regionID || null,
                                subregionID: doc.data()?.fleetPro?.subregionID || null,
                                groupID: doc.data()?.fleetPro?.groupID || null,
                                statusAssign: doc.data().assignType === 'retailerEvent' ? 'booked' : null
                            })
                        }
                    })
                    setLoader(false)
                    setMonthData([..._.uniqBy(_fleetpro, 'id')]);
                });
        }
        else {
            setLoader(false)
        }
    }, [])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (!_.isEmpty(data && data.eventsProDashboardLayout)) {
                setLayout(data.eventsProDashboardLayout)
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setModelShow({ show: false })
        setModelAssignShow({ show: false })
    }

    const handleShowList = (fleetdata, title) => {
        setModelShow({
            show: true,
            title,
            fleetdata
        })
    }
    const handleShowAssignList = (fleetassigndata, title) => {
        setModelAssignShow({
            show: true,
            title,
            fleetassigndata
        })
    }
    const onLayoutChange = async (_layout) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.map((rec, index) => {
            return {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: 11,
                minW: rec.i === 'RevenueCounts' ? 8 : 4,
                h: (rec.h === 1 || rec.h === undefined) ? 11 : rec.h,
                w: (rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({ eventsProDashboardLayout: _dLayout }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        handleShowList={handleShowList}
                        handleShowAssignList={handleShowAssignList}
                        monthData={monthData}
                        loader={loader}
                        dateRange={dateRange}
                        dashboardFilter={dashboardFilter}
                        setDashboardFilter={(val) => {
                            setDashboardFilter(val)
                        }}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {layoutLoader ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center text-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border text-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : <>
                            {<DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                dateRange={dateRange}
                                loader={loader}
                                initialLayout={layout}
                                setDateRange={(_dateRange) => {
                                    setDateRange(_dateRange)
                                }}
                                setLoader={(val) => {
                                    setLoader(val);
                                }}
                                monthData={monthData}
                                accessToApprove={accessToApprove}
                                accessToReturn={accessToReturn}
                                accessToReceive={accessToReceive}
                                dashboardFilter={dashboardFilter}
                            />}
                        </>
                    }

                </div>

            </div>
            <PopUpModal show={modelShow.show}>
                <FleetList
                    {...props}
                    {...modelShow}
                    handleClose={handleModelClose}
                    history={props.history}
                    isFromDashboard={true}
                ></FleetList>
            </PopUpModal>
            <PopUpModal show={modelassginShow.show}>
                <FleetHistoryList
                    {...props}
                    {...modelassginShow}
                    handleClose={handleModelClose}
                    history={props.history}
                    isFromDashboard={true}
                ></FleetHistoryList>
            </PopUpModal>

        </div>
    )
}

export default Dashboard