import React, { useState, useEffect, userRef } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';
import InboundLeadCounts from "./inboundLeadCounts";
import CommonHelper from '../../services/common'
import { PopUpModal, ReactTimePicker } from '../../components'
import EnquiryList from '../dashboard/enquiryList'
import ActivityList from '../dashboard/activitiesList'
import { initialLayoutArr, defaultWidgetSettings } from './viewModel';
import DragDropLayout from './dragDropLayout';
import { default as _images } from '../../images';
import Swal from 'sweetalert2';
import { firestoreDB } from '../../services/helper';

const defaulModelState = {
    enquiry: false,
    activity: false
}

const BDCDashboard = (props) => {
    const [loader, setLoader] = useState(true)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [inboundleadEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard ? { value: props.dealersettings.id } : null);
    const [applydateRange, setApplyDateRange] = useState(false)
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: []
    })
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState([])
    const [widgetSettingsLoader, setWidgetSettingsLoader] = useState(true)
    const [sortedWidgets, setSortedWidgets] = useState([])
    const [hiddenWidgets, setHiddenWidgets] = useState([])
    const [showWidgetSettings, setShowWidgetSettings] = useState(false);

    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/bdcDashboardSettings/${localStorage.uid}`);
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const managerLostApproval = (localStorage.defaultModule !== 'oem' && !_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.lostApproval) ? true : false

    const clientLostApproval = (localStorage.defaultModule !== 'oem' && !_.isEmpty(props.dealersettings.client.clientSettings)) ? Boolean(props.dealersettings.client.clientSettings.lostApproval) : false;

    useEffect(() => {
        return () => {
            window.bdcdashboardPipelines && window.bdcdashboardPipelines.unsubscribe()
        }
    }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {
        const widgetSettingsSnapShot = window.firebase.firestore().doc(`users/${localStorage.uid}/bdcDashboardSettings/${localStorage.uid}`)
            .onSnapshot(querySnapshot => {
                //console.log('querySnapshot', querySnapshot)
                if (querySnapshot.exists) {
                    const widgetsData = querySnapshot.data()
                    setSortedWidgets(widgetsData.sortedWidgets ? widgetsData.sortedWidgets : defaultWidgetSettings)
                    setHiddenWidgets(widgetsData.hiddenWidgets ? widgetsData.hiddenWidgets : [])
                    setWidgetSettingsLoader(false)
                } else {
                    setSortedWidgets(defaultWidgetSettings)
                    setHiddenWidgets([])
                    setWidgetSettingsLoader(false)
                }
            })
        return () => {
            widgetSettingsSnapShot && widgetSettingsSnapShot();
        }
    }, [])

    const removeUnAllowedWidgets = widgetName => {
        let valToReturn = true

        if (widgetName === 'LeadsBucket' && !(props.pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval))) {
            valToReturn = false
        }

        if (widgetName === 'Allusertarget')
            valToReturn = false

        return valToReturn
    }


    useEffect(() => {
        setLoader(true)
        //ALL INBOUND LEAD BUCKET  
        let refinboundLeads = firestoreDB(props.dealersettings).firestore().collection('inboundLeads')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', 0)
            .where('isDeleted', '==', false)
        refinboundLeads = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refinboundLeads, props.dealersettings, null, true);

        //LOST PENDING APPROVAL
        let lostPendingApproval = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', 'pendingLost')
            .where('isDeleted', '==', false)
        lostPendingApproval = CommonHelper.whereClientOrGroupOrRegionOrOemClause(lostPendingApproval, props.dealersettings, null, true);

        //ALL PENDING BUCKET  
        let refPendingBucketData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('pipeline', '==', 'LeadsBucket')
            .where('isDeleted', '==', false)
        refPendingBucketData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refPendingBucketData, props.dealersettings, null, true);
        if (localStorage.defaulModelState === 'oem') {
            refPendingBucketData = refPendingBucketData.where('oemStatus', '==', 'pending')
        }
        if (ownerDashboard && localStorage.defaultModule !== 'oem')
            refPendingBucketData = refPendingBucketData.where('owner', '==', props.dealersettings.id)

        //MTD CONVERTED ENQUIRIES  
        let refConvertedEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            // .where('clientID', '==', props.dealersettings.client.id)
            // .where('convertedDate', '>=', dateRange.startDate)
            // .where('convertedDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)
        //.orderBy('convertedDate', 'desc')
        refConvertedEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClauseForConvertedDate(refConvertedEnquiryData, props.dealersettings, dateRange);

        //WON ENQUIRIES    
        let refWonEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('status', '==', 'won')
            // .where('pipeline', 'in', _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
            // .where('clientID', '==', props.dealersettings.client.id)
            .where('wonDate', '>=', dateRange.startDate)
            .where('wonDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)
        refWonEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refWonEnquiryData, props.dealersettings, null, true);

        //LOST ENQUIRIES    
        let refLostEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('status', '==', 'lost')
            // .where('pipeline', 'in', _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
            // .where('clientID', '==', props.dealersettings.client.id)
            .where('lostDate', '>=', dateRange.startDate)
            .where('lostDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)
        refLostEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refLostEnquiryData, props.dealersettings, null, true);


        //DELIVERED ENQUIRIES    
        let refDeliveredEnquiryData = firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('status', '==', 'delivered')
            // .where('pipeline', 'in', _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
            // .where('clientID', '==', props.dealersettings.client.id)
            .where('deliveredDate', '>=', dateRange.startDate)
            .where('deliveredDate', '<=', dateRange.endDate)
            .where('isDeleted', '==', false)
        refDeliveredEnquiryData = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refDeliveredEnquiryData, props.dealersettings, null, true);


        //MTD DONE ACTIVITIES
        let refCompletedActivityData = firestoreDB(props.dealersettings).firestore().collection('activities')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('isDone', '==', true)
            .where('type', '==', 'activity')
            .where('modifiedDate', '>=', dateRange.startDate)
            .where('modifiedDate', '<=', dateRange.endDate)
        refCompletedActivityData = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(refCompletedActivityData, props.dealersettings, null, true);
        // if (ownerDashboard)
        //     refCompletedActivityData = refCompletedActivityData.where('owner', '==', props.dealersettings.id)

        //MTD OVERDUE ACTIVITIES
        let refOverdueActivityData = firestoreDB(props.dealersettings).firestore().collection('activities')
            //.where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('isDone', '==', false)
            .where('type', '==', 'activity')
            .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
            .orderBy('startDate', 'desc')
            .limit(100)
        refOverdueActivityData = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(refOverdueActivityData, props.dealersettings, null, true);
        if (ownerDashboard)
            refOverdueActivityData = refOverdueActivityData.where('owner', '==', props.dealersettings.id)

        //MTD WALKIN LEADS
        let refWalkinEnquiryData = '';
        if (localStorage.defaultModule !== 'oem')
            refWalkinEnquiryData = firestoreDB(props.dealersettings).firestore().collectionGroup('walkins')
                .where('clientID', '==', props.dealersettings.client.id)
                .where('walkinDate', '>=', dateRange.startDate)
                .where('walkinDate', '<=', dateRange.endDate)



        window.bdcdashboardPipelines = combineLatest(
            collection(refPendingBucketData),
            collection(refConvertedEnquiryData),
            collection(refWonEnquiryData),
            collection(refLostEnquiryData),
            collection(refDeliveredEnquiryData),
            collection(refCompletedActivityData),
            collection(refOverdueActivityData),
            refWalkinEnquiryData ? collection(refWalkinEnquiryData) : of([]),
            collection(refinboundLeads),
            lostPendingApproval ? collection(lostPendingApproval) : of([])
        ).pipe(
            map(([pendinBucket, convertedLeads, wonLeads, lostLeads, deliveredLeads, doneActivity, overdueActivity, walkins, inboundLeads, lostPendingApproval]) => {
                return [
                    _.map(pendinBucket, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(convertedLeads.filter(rec => ownerDashboard ? rec.data().convertedBy === props.dealersettings.id : true), function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(wonLeads.filter(rec => ownerDashboard ? rec.data().convertedBy === props.dealersettings.id : true), function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(lostLeads.filter(rec => ownerDashboard ? rec.data().convertedBy === props.dealersettings.id : true), function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(deliveredLeads.filter(rec => ownerDashboard ? rec.data().convertedBy === props.dealersettings.id : true), function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(doneActivity, function (obj) { return { ...CommonHelper.convertActivityLogVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(overdueActivity, function (obj) { return { ...CommonHelper.convertActivityLogVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                    _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(inboundLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id, 'stageDate': obj.data().addedDate } }),
                    _.map(lostPendingApproval, function (obj) { return { ...CommonHelper.convertEnquiryVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } })
                ]
            }))
            .subscribe(allDocs => {
                let _roleids = props.dealersettings.client.roles ? props.dealersettings.client.roles.filter(rec => rec.pipeline && rec.pipeline.some(p => p === 'LeadsBucket')).map(rec => { return rec.id }) : [];
                let inboundUserids = _.filter(props.clientUsers, (v) => _roleids.length > 0 ? _.includes(_roleids, v.roleID) : true).map(rec => { return rec.id })
                var _enquiries = [];
                var _activities = []
                for (let i = 0; i < 5; i++) {
                    allDocs[i].forEach(el => {
                        if (!_enquiries.some(e => e.documentID === el.documentID) &&
                            !_.isEmpty(el.contact) &&
                            (el.pipelineValue === 'LeadsBucket' ||
                                (localStorage.defaultModule !== 'oem' && el.isConverted === true && !_.isEmpty(el.convertedDate) ||
                                    (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'oem' && el.isConvertedOEM === true && !_.isEmpty(el.convertedDateOEM)) ||
                                    (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'region' && el.isConvertedRegion === true && !_.isEmpty(el.convertedDateRegion)) ||
                                    (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'group' && el.isConvertedGroup === true && !_.isEmpty(el.convertedDateGroup))
                                ))) {
                            _enquiries.push({
                                ...el,
                                firstWalkinDate: allDocs[7].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[7].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                                walkins: allDocs[7].filter(a => a.enquiryID === el.documentID)
                            })
                        }
                    });
                }
                for (let i = 5; i < 7; i++) {
                    allDocs[i].forEach(el => {
                        if (!_activities.some(e => e.documentID === el.documentID) && (el.pipelineValue === 'LeadsBucket' || inboundUserids.some(u => u === el.owner))) {
                            _activities.push(!el.enquiry ? {
                                ...CommonHelper.convertActivityLogVM({
                                    ...el,
                                    enquiry: el.enquiryID && !el.enquiry && _enquiries.find(a => a.documentID === el.enquiryID) ? _enquiries.find(a => a.documentID === el.enquiryID) : el.enquiry,
                                    contact: el.contactID && !el.contact && _enquiries.find(a => !_.isEmpty(a.contact) && a.contact.documentID === el.contactID) ?
                                        _enquiries.find(a => !_.isEmpty(a.contact) && a.contact.documentID === el.contactID).contact : el.contact,
                                    convertVM: false
                                }, { ...props })
                            } : {
                                ...el
                            })
                        }
                    });
                }
                allDocs[8].forEach(el => {//inbound lead array
                    _enquiries.push({
                        oemStatus: _.isEmpty(el.oemStatus) && localStorage.defaultModule === 'oem' ? 'pending' : el.oemStatus,
                        oemConvertedDate: el.oemStatus === 'converted' ? el.addedDate : null,
                        ...CommonHelper.convertInboundLeadVM(el, { ...props })
                    })
                });
                allDocs[9].forEach(el => {
                    if (!_enquiries.some(e => e.documentID === el.documentID) && !_.isEmpty(el.contact) && (el.pipelineValue === 'LeadsBucket' ||
                        (localStorage.defaultModule !== 'oem' && el.isConverted === true && !_.isEmpty(el.convertedDate) ||
                            (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'oem' && el.isConvertedOEM === true && !_.isEmpty(el.convertedDateOEM)) ||
                            (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'region' && el.isConvertedRegion === true && !_.isEmpty(el.convertedDateRegion)) ||
                            (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'group' && el.isConvertedGroup === true && !_.isEmpty(el.convertedDateGroup))
                        ))) {
                        _enquiries.push({
                            ...el,
                            firstWalkinDate: allDocs[7].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[7].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                            walkins: allDocs[7].filter(a => a.enquiryID === el.documentID)
                        })
                    }
                });
                setActivities(_activities)
                setTempData({
                    enquiries: _enquiries,
                    walkins: allDocs[7]
                })
                if (allDocs[7].length === 0)
                    setLoader(false)
            });

    }, [dateRange])


    useEffect(() => {
        async function getWalkinEnq(walkins, enquiries) {
            const promises = []
            _.uniq(_.map(walkins, function (e) { return e.enquiryID })).forEach(el => {
                if (!enquiries.some(e => e.documentID === el)) {
                    promises.push(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${el}`).get())
                }
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...CommonHelper.convertEnquiryVM({ ...snap.data(), 'documentID': snap.id }, { ...props }) }
                if (dataDoc.isDeleted === false) {
                    enquiries.push({
                        ...dataDoc,
                        firstWalkinDate: walkins.filter(a => a.enquiryID === snap.id).length > 0 ? _.orderBy(walkins.filter(a => a.enquiryID === snap.id), ['walkinDate'], ['asc'])[0].walkinDate : null,
                        walkins: walkins.filter(a => a.enquiryID === snap.id)
                    })
                }
            })
            setEnquiries(enquiries)
            setLoader(false)
        }
        if (tempData.walkins.length > 0)
            getWalkinEnq(tempData.walkins, tempData.enquiries);
        else
            setEnquiries(tempData.enquiries)

    }, [tempData])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (data && !_.isEmpty(data.bdcdashboardLayout)) {
                setLayout(data.bdcdashboardLayout)
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const onLayoutChange = async (_layout, sortedWidgets, hiddenWidgets) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.filter(a => a.i !== 'null').map((rec, index) => {
            return sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes(rec.i) && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).length === 1 ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                x: 0,
                y: 0
            } : hiddenWidgets.filter(a => removeUnAllowedWidgets(a)).includes(rec.i) ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                w: 0,
                h: 0
            } : {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: rec.i === 'LeadsBucket' ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
                minW: 4,
                h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? (rec.i === 'LeadsBucket' ? 4 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
                w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: rec.i === 'LeadsBucket' ? false : true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({
                bdcdashboardLayout: _dLayout,
            }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const handleShowEnquiryList = (filterValue, title) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Enquiry List')
        setModelShow({
            ...defaulModelState,
            enquiry: true
        })
    }

    const handleShowActivityList = (filterValue, title) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Activities List')
        setModelShow({
            ...defaulModelState,
            activity: true
        })
    }

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleCloseWidgetSettings = () => {
        setShowWidgetSettings(false)
    }

    const handleOpenWidgetSettings = e => {
        e.preventDefault()
        setShowWidgetSettings(true)
    }

    const removeWidget = async (val) => {
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to hide this widget.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }
        const localHiddenWidgets = [val, ...hiddenWidgets];
        const localSortedWidgets = sortedWidgets.filter(a => a !== val);
        const dashboardSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/bdcDashboardSettings/${localStorage.uid}`);
        await dashboardSettingsRef.set({
            hiddenWidgets: localHiddenWidgets,
            sortedWidgets: localSortedWidgets,
        }, { merge: true })
        onLayoutChange(layout, localSortedWidgets, localHiddenWidgets);
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <InboundLeadCounts {...props}
                        handleShowEnquiryList={handleShowEnquiryList}
                        handleShowActivityList={handleShowActivityList}
                        inboundleadEnquiries={inboundleadEnquiries}
                        allActivities={allActivities}
                        selectedUser={selectedUser}
                        dateRange={dateRange}
                        applydateRange={applydateRange}
                        sortedWidgets={sortedWidgets}
                        hiddenWidgets={hiddenWidgets}
                        handleCloseWidgetSettings={handleCloseWidgetSettings}
                        handleOpenWidgetSettings={handleOpenWidgetSettings}
                        showWidgetSettings={showWidgetSettings}
                        setShowWidgetSettings={setShowWidgetSettings}
                        removeUnAllowedWidgets={removeUnAllowedWidgets}
                        onLayoutChange={onLayoutChange}
                        layout={layout}
                        setLayout={setLayout}
                        setUser={(e) => {
                            setUser(e)
                        }}
                        handleDateRangeChange={(val) => {
                            if (val) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {layoutLoader ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center text-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border text-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : sortedWidgets.filter(a => removeUnAllowedWidgets(a)).length !== 0 ? (
                            <DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                handleShowEnquiryList={handleShowEnquiryList}
                                inboundleadEnquiries={inboundleadEnquiries}
                                selectedUser={selectedUser}
                                clientLostApproval={clientLostApproval}
                                managerLostApproval={managerLostApproval}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                allActivities={allActivities}
                                initialLayout={layout}
                                sortedWidgets={sortedWidgets}
                                hiddenWidgets={hiddenWidgets}
                                removeUnAllowedWidgets={removeUnAllowedWidgets}
                                removeWidget={removeWidget}
                            />
                        ) : (
                            <div className="common-table pt-5">
                                <div className="text-center p-5">
                                    <div className='no-data-img mb-3'>
                                        {' '}
                                        <img src={_images.nodata} width='60' height='60' alt='' />
                                    </div>
                                    <p>No Visible Widgets.</p>
                                    <button className="btn btn-sm btn-default" onClick={handleOpenWidgetSettings}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        Add a widget
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={inboundleadEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedUser={selectedUser}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allActivities}
                    filter={filter}
                    selectedUser={selectedUser}
                ></ActivityList>
            </PopUpModal>
        </div>
    )
}

export default BDCDashboard