/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash';
import Modal from "react-bootstrap/Modal";
/** COMPONENTS */
import { WidgetOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';

export default class Evaluators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            evaluators: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.isLoading = true;
        const { dealersettings, clientUsers } = this.props;

        const setttings = dealersettings.client.tradeinPro;
        let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
        if (_.isEmpty(_selPipeline))
            _selPipeline = Object.assign({}, setttings.pipelines[0]);
        // if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline)) {
        //     _selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline);
        // }

        if (!_.isEmpty(dealersettings.client.roles) && _selPipeline?.value) {

            let roleIds = [..._.map(dealersettings.client.roles.filter(e => _selPipeline.value && e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === _selPipeline.value)), function (obj) {
                return obj.documentID;
            })];

            let _selectedUsers = [];
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(clientUsers, (v) => _.includes(roleIds, v.roleID));

            this.setState({
                evaluators: _selectedUsers,
                isLoading: false
            });
        }
        else {
            this.setState({
                isLoading: false
            });
        }


    }

    render() {

        const { isLoading, evaluators } = this.state;
        const { show, title } = this.props;
        const divStyle = {
            height: '500px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="Evaluators-List"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={title} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>
                            <div style={divStyle}>
                                {
                                    isLoading
                                        ?
                                        <>
                                            <WidgetOverlay />
                                        </>
                                        :
                                        <>
                                            {

                                                (!_.isEmpty(evaluators) && evaluators.length) > 0
                                                    ?
                                                    <>
                                                        {evaluators.map(contact =>
                                                            <div key={contact.documentID} className="frontdesk-rearrange-box frontdesk-flex-queue" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.handleClose(contact.documentID, contact.name)

                                                            }}>
                                                                <div className={`frontdesk-rearrange-avatar`}>
                                                                    <img src={CommonHelper.showUserAvatar(contact.profileImage, contact.name)} alt="" className="img-object-fit" /> </div>
                                                                <div className="frontdesk-rearrange-detail">
                                                                    <h3>{contact.name}</h3>
                                                                    <h5>
                                                                        {contact.role ? contact.role + '  |  ' : '     '}
                                                                        {contact.email ? contact.email : ''}
                                                                    </h5>
                                                                </div>

                                                            </div>
                                                        )}</>
                                                    :
                                                    <>
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-Contacts"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'noEvaluatorFound'} /></div>
                                                            </div>

                                                        </div>
                                                    </>
                                            }

                                        </>
                                }

                            </div>

                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}


