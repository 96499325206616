import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomAdvFilters } from '../../components/customdropdown';
import { DefaultFilters, CustomFilters } from '../common'
import PipelineAdvanceFilters from './advanceFilters'
import Translate from '../../constants/translate';

const _defaultFilters = [
    { name: 'All Services ', value: {}, type: 'default' },
    { name: 'All CheckIn Services', value: { 'status': 'checkIn' }, type: 'default' },
    { name: 'All Workin Progress Services', value: { 'status': 'workInProgress' }, type: 'default' },
    { name: 'All Completed Services', value: { 'status': 'completed' }, type: 'default' }
]

const objServiceFilter = [
    "keywords", "date", "owner", "isVIP",
    "serviceAdvisor", "technician", 'partsInterpretor', 'workshopManager',
    "status", "make", "appointmentType", "stage", "slot",
    "jobAllocated", "jobConfirmed", "partsConfirmed", "workshopConfirmed",
    "isFav", "contactType", "gender", "nationality"
]

const Filters = (props) => {
    const [userID, setUserID] = useState('')
    const [show, setShow] = useState(false)
    const [searchField, setSearchField] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [defaultFilters, setDefaultFilters] = useState(_defaultFilters)
    const [columnFields, setColumnFields] = useState(objServiceFilter)
    const [activeTab, setActiveTab] = useState('All')

    const { pipelineFilter } = props;

    const node = useRef();

    useEffect(() => {
        setCustomFilters(props.customFilters)
        setUserID(props.dealersettings.id)
    }, [props]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) ||
        (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('ant-picker-') >= 0) ||
        (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('swal2-') >= 0) ||
        (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('filter-dont-close') >= 0) ||
        e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')
    ) {
        return;
    }
        setShow(false);
    };

    const handleSearchField = (e) => {
        setSearchField(e.target.value);
        if (e.target.value) {
            setCustomFilters([...props.customFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setDefaultFilters([..._defaultFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setColumnFields([...objServiceFilter.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase()))])
        }
        else {
            setCustomFilters(props.customFilters)
            setDefaultFilters(_defaultFilters)
            setColumnFields(objServiceFilter)
        }
    }

    const handleClearSearchField = (e) => {
        setSearchField('');
        setCustomFilters(props.customFilters)
        setDefaultFilters(_defaultFilters)
        setColumnFields(objServiceFilter)
    }

    const saveFavourite = (filterID, checked) => {
        const { dealersettings } = props;
        let settingsid = dealersettings.client.settings.id;
        let objData = customFilters.filter(e => e.documentID === filterID)[0];

        if (settingsid && filterID && !_.isEmpty(objData)) {
            let currentDate = window.firebase.firestore.Timestamp.now();
            let isFav = objData.isFav;

            if (checked)
                isFav.push(userID)
            else
                isFav = isFav.filter(m => m !== userID);

            const refStockData = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`).doc(filterID);
            refStockData.set({
                isFav: isFav,
                modifiedBy: userID,
                modifiedDate: currentDate,
                modifiedFrom: 'web'
            }, { merge: true }).then((docRef) => {
                toast.notify('Favourite saved successfully', {
                    duration: 2000
                });

            }).catch((error) => {
                console.error("Error updating filter_details: ", error);
            });
        }

    }

    const deleteFilter = (filterID) => {
        const { dealersettings } = props;
        let settingsid = dealersettings.client.settings.id;
        if (settingsid && filterID) {
            window.firebase.firestore().doc(`clientSettings/${settingsid}/filters/${filterID}`).delete().then(() => {
                toast.notify('Filter successfully deleted!', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error removing Filter: ", error);
            });
        }
    }

    const handleSelectFilter = (_filter) => {
        setShow(false);
        props.handleApplyFilter(_filter);
    }

    const displayFilterBadge = () => {
        if (!_.isEmpty(pipelineFilter) && !_.isEmpty(pipelineFilter.value)) {

            return <span className="badge badge-pill badge-advanced-filter">{Object.keys(pipelineFilter.value).length}</span>
        }
        else {
            return <></>
        }
    }

    const handleFilterClose = () => {
        setShow(false);
    }

    return (


        <Dropdown ref={node} alignRight show={show}>
            <div className="advanced-filter-btn mr-2 float-right">
                <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                    setShow(!show)
                }}>
                    {displayFilterBadge()}
                    <i className="ico icon-filter"></i>
                    <span>
                        {
                            !_.isEmpty(pipelineFilter)
                                ?
                                <>
                                    {
                                        pipelineFilter.name && (pipelineFilter.type === 'default' || pipelineFilter.type === 'custom')
                                            ?
                                            pipelineFilter.name
                                            :
                                            <Translate text={'Filter'}/>
                                    }
                                </>
                                :
                                <><Translate text={'All Services'}/></>
                        }

                    </span>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(pipelineFilter)
                        ?
                        <>
                            <a href="#" className="clear-btn" onClick={(e) => { e.preventDefault(); props.handleClearFilter(); }}> <i className="ico icon-remove"></i> </a>
                        </>
                        :
                        <></>
                }
            </div>
            <Dropdown.Menu as={CustomAdvFilters}
                id="table-dynamic-cols-settings-pipeline"
                className="dropdown-menu dropdown-menu-left"
                ChildClass="quick-filter-listing"
                xplacement="bottom-end"
                title={'Filters'}
                handleTextchange={handleSearchField}
                handleTextClear={handleClearSearchField}
                searchField={searchField}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                noSaveAccess={props.noSaveAccess}
                dealersettings={props.dealersettings}
            >
                {
                    props.noSaveAccess
                        ?
                        <>
                            <PipelineAdvanceFilters
                                columnFields={columnFields}
                                dealersettings={props.dealersettings}
                                clientUsers={props.clientUsers}
                                groupUsers={props.groupUsers}
                                pipelineFilter={pipelineFilter}
                                customFilters={customFilters}
                                handleClearFilter={props.handleClearFilter}
                                handleApplyFilter={props.handleApplyFilter}
                                handleFilterClose={handleFilterClose}
                                pipelineView={props.pipelineView}
                                showPipeline={props.showPipeline}
                                noSaveAccess={props.noSaveAccess}
                                pipeline={props.pipeline}
                            />
                        </>
                        :
                        <>
                            {(() => {
                                if (activeTab === 'All') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    defaultFilters && defaultFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'system'} /></ol>
                                                            {
                                                                defaultFilters.map((_filter, index) => {
                                                                    return (
                                                                        <DefaultFilters
                                                                            key={index}
                                                                            _filter={_filter}
                                                                            localFilter={pipelineFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                        ></DefaultFilters>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'customFilters'} /></ol>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={pipelineFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'service'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Custom') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={pipelineFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'service'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Favorites') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.filter(e => !_.isEmpty(e.isFav) && e.isFav.indexOf(userID) >= 0).map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={pipelineFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'service'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Filters') {
                                    return (
                                        <>
                                            <PipelineAdvanceFilters
                                                columnFields={columnFields}
                                                dealersettings={props.dealersettings}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                                pipelineFilter={pipelineFilter}
                                                customFilters={customFilters}
                                                handleClearFilter={props.handleClearFilter}
                                                handleApplyFilter={props.handleApplyFilter}
                                                handleFilterClose={handleFilterClose}
                                                pipelineView={props.pipelineView}
                                                showPipeline={props.showPipeline}
                                                pipeline={props.pipeline}
                                            />
                                        </>
                                    )
                                }
                            })()}
                        </>
                }
            </Dropdown.Menu>
        </Dropdown>





    )
}

export default Filters