/** LIBRARIES */
import React, { PureComponent } from 'react';
import { Overlay, Popover } from 'react-bootstrap'
import Translate from '../constants/translate';

class PopoverPanel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        //const ref = useRef(null);
        const { showpopover, targetpopover, position, closepopover, id, className, title, children, blockOutsideClick } = this.props;
        return (

            <div /*ref={ref}*/>
                <Overlay
                    show={showpopover}
                    target={targetpopover}
                    placement={position}
                    rootClose={true}
                    rootCloseEvent='click'
                    onHide={blockOutsideClick ? null : closepopover}
                //container={ref.current}
                >
                    <Popover id={id ? id : "popover-positioned-left"} className={`popover-lg ${className}`}>
                        {
                            title ? <Popover.Title as="h3">{<><span className="close" onClick={closepopover}>×</span>{title} </>}</Popover.Title>
                                : (<></>)
                        }
                        <Popover.Content>
                            {showpopover ? children : <></>}
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        );
    }
}

export default PopoverPanel;