import React from 'react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect, AutoComplete } from '../../components';
import { default as _images } from '../../images';
import { termOptions, drivaDoc } from './viewModel'
import { firestoreDB } from '../../services/helper';

const AddDrivaInfo = ({
	title,
	contact,
	enquiry,
	show,
	handleClose,
	currencySymbol,
	dealersettings,
	enquiryID,
	selectedVehicle,
	documentID,
	financeDetail,
	driva
}) => {

	let _vehiclePrice = selectedVehicle?.stock?.price
		? selectedVehicle.stock.price
		: selectedVehicle?.price
			? selectedVehicle.price
			: financeDetail?.request?.loan?.amount ? financeDetail.request.loan.amount : 0

	const [vehicleInfo] = React.useState({
		year: !_.isEmpty(selectedVehicle?.stock?.year)
			? selectedVehicle.stock.year
			: selectedVehicle?.year
				? selectedVehicle.year
				: financeDetail?.request?.extra?.vehicleYear ? financeDetail.request.extra.vehicleYear : '',
		make: !_.isEmpty(selectedVehicle?.stock?.make)
			? selectedVehicle.stock.make
			: selectedVehicle?.make
				? selectedVehicle.make
				: financeDetail?.request?.extra?.vehicleMake ? financeDetail.request.extra.vehicleMake : '',
		model: !_.isEmpty(selectedVehicle?.stock?.model)
			? selectedVehicle.stock.model
			: selectedVehicle?.model
				? selectedVehicle.model
				: financeDetail?.request?.extra?.vehicleModel ? financeDetail.request.extra.vehicleModel : '',
		saleType: !_.isEmpty(selectedVehicle?.stock?.saleType)
			? CommonHelper.getNameByValue(
				dealersettings &&
				dealersettings.client &&
				dealersettings.client.settings &&
				dealersettings.client.settings.salesType,
				selectedVehicle.stock.saleType,
				'',
			)
			: selectedVehicle?.saleType
				? CommonHelper.getNameByValue(
					dealersettings &&
					dealersettings.client &&
					dealersettings.client.settings &&
					dealersettings.client.settings.salesType,
					selectedVehicle.saleType,
					'',
				)
				: financeDetail?.request?.extra?.vehicleState ? financeDetail.request.extra.vehicleState : '',
		extColor: !_.isEmpty(selectedVehicle?.stock?.extColor)
			? selectedVehicle.stock.extColor
			: selectedVehicle?.extColor
				? selectedVehicle.extColor
				: '',
		price: _vehiclePrice
	});

	const [state, setState] = React.useState({
		term: documentID ? financeDetail?.request?.loan?.term ? financeDetail.request.loan.term : '' : '',
		balloon: documentID ? financeDetail?.request?.loan?.balloon ? financeDetail.request.loan.balloon : 0 : 0,
		amount: documentID ? financeDetail?.request?.loan?.amount ? financeDetail.request.loan.amount : _vehiclePrice ? _vehiclePrice : 0 : (_vehiclePrice ? _vehiclePrice : 0),
		deposit: documentID ? financeDetail?.request?.loan?.deposit ? financeDetail.request.loan.deposit : 0 : 0,
		creditScore: documentID ? financeDetail?.request?.creditScore?.band ? financeDetail.request.creditScore.band : '' : '',
		currentYears: financeDetail?.request?.user?.address?.durationYears ? financeDetail?.request?.user?.address?.durationYears : '',
		currentMonths: financeDetail?.request?.user?.address?.durationMonths ? financeDetail?.request?.user?.address?.durationMonths : '',
		previousYears: financeDetail?.request?.user?.previousAddress?.durationYears ? financeDetail?.request?.user?.previousAddress?.durationYears : '',
		previousMonths: financeDetail?.request?.user?.previousAddress?.durationMonths ? financeDetail?.request?.user?.previousAddress?.durationMonths : '',
	});

	const [errors, setErrors] = React.useState({
		term: '',
		balloon: '',
		amount: '',
		deposit: '',
		creditScore: '',
	});
	const [loader, setLoader] = React.useState(false);
	const [address, setAddress] = React.useState({
		address: financeDetail?.request?.user?.address?.full ? financeDetail?.request?.user?.address?.full : (contact?.address ? contact.address : ''),
		previousAddress: financeDetail?.request?.user?.previousAddress?.full ? financeDetail?.request?.user?.previousAddress?.full : '',
	});
	const currentYears = financeDetail?.request?.user?.address?.durationYears ? financeDetail?.request?.user?.address?.durationYears : ''
	const [showPreviousAddress, setShowPreviousAddress] = React.useState(currentYears && currentYears < 3 ? true : false);


	const onChange = (e, name) => {
		console.log('name', name, e.floatValue, !(e.floatValue > 100))
		if (name === 'balloon') {
			setState({
				...state,
				// [name]: e.floatValue ? e.floatValue : 0,
				[name]: e.floatValue ? e.floatValue < 101 ? e.floatValue : state.balloon : 0,
			});
			setErrors({
				...errors,
				[name]: '',
			});
		} else if (name === 'currentYears') {
			console.log('currentYears');
			setState({
				...state,
				[name]: e.floatValue ? e.floatValue < 100 ? e.floatValue : state.currentYears : 0,
			});
			setErrors({
				...errors,
				[name]: '',
			});
			if (e.floatValue && e.floatValue < 3) {
				setShowPreviousAddress(true)
			} else {
				setShowPreviousAddress(false)
			}
		} else if (name === 'currentMonths') {
			setState({
				...state,
				[name]: e.floatValue ? e.floatValue < 12 ? e.floatValue : state.currentMonths : 0,
			});
			setErrors({
				...errors,
				[name]: '',
			});
		} else if (name === 'previousYears') {
			setState({
				...state,
				[name]: e.floatValue ? e.floatValue < 100 ? e.floatValue : state.previousYears : 0,
			});
			setErrors({
				...errors,
				[name]: '',
			});
		} else if (name === 'previousMonths') {
			setState({
				...state,
				[name]: e.floatValue ? e.floatValue < 12 ? e.floatValue : state.previousMonths : 0,
			});
			setErrors({
				...errors,
				[name]: '',
			});
		} else if (name !== 'balloon' && name !== 'previousYears' && name !== 'previousMonths' && name !== 'currentYears' && name !== 'currentMonths') {
			setState({
				...state,
				[name]: e.floatValue,
			});
			setErrors({
				...errors,
				[name]: '',
			});
		}
	};

	const handleSelect = (e, data) => {
		console.log(e, data)
		if (data) {
			if (data.action === 'clear') {
				setState({
					...state,
					[data.name]: null,
				});
			} else {
				setState({
					...state,
					[data.name]: e.value,
				});
				setErrors({
					...errors,
					[data.name]: '',
				});
			}
		} else {
			setState({
				...state,
				[e.target.name]: e.target.value,
			});
		}
	};

	const apiURL =
		process.env.NODE_ENV === 'development'
			? 'http://localhost:5001/ams-pro-qa/us-central1/'
			: dealersettings.apiURL;

	const handleSubmit = async () => {
		// generate obj
		console.log('state,', state);
		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (!state['amount']) {
			formIsValid = false;
			errors['amount'] = errorClass;
		}
		if (state['term'] === '' || !state['term']) {
			formIsValid = false;
			errors['term'] = errorClass;
		}
		// if (state['creditScore'] === '' || !state['creditScore']) {
		// 		formIsValid = false;
		// 		errors['creditScore'] = errorClass;
		// }
		if (state['currentYears'] === '' || !state['currentYears']) {
			formIsValid = false;
			errors['currentYears'] = errorClass;
		}
		// if (state['currentMonths'] === '' || !state['currentMonths']) {
		// 	formIsValid = false;
		// 	errors['currentMonths'] = errorClass;
		// }
		if (showPreviousAddress && (state['previousYears'] === '' || !state['previousYears'])) {
			formIsValid = false;
			errors['previousYears'] = errorClass;
		}
		// if (showPreviousAddress && (state['previousMonths'] === '' || !state['previousMonths'])) {
		// 	formIsValid = false;
		// 	errors['previousMonths'] = errorClass;
		// }
		if (showPreviousAddress && (address['previousAddress'] === '' || !address['previousAddress'])) {
			formIsValid = false;
			errors['previousAddress'] = errorClass;
		}
		if (address['address'] === '' || !address['address']) {
			formIsValid = false;
			errors['address'] = errorClass;
		}
		if (!state['deposit']) {
			formIsValid = false;
			errors['deposit'] = errorClass;
		}
		setErrors(errors);
		if (!formIsValid) {
			return;
		}

		let Address1 = '', suburb = '   ';
		if (enquiry.contact.address) {
			const address = enquiry.contact.address.split(",");
			if (address.length > 0) Address1 = address[0];
			if (address.length > 1) suburb = (address[1].trim().split(' '))[0];
		}


		console.log('Address1', Address1)
		console.log('suburb', suburb)

		let reqObj = {
			"user": {
				"firstName": contact.firstName ? contact.firstName : "",
				"middleName": contact.middleName ? contact.middleName : "",
				"lastName": contact.lastName ? contact.lastName : "",
				// no code is being added in the number
				"mobile": contact.phone ? `${contact.phone}` : "",
				"email": contact.email ? contact.email : "",
				"dateOfBirth": contact.dob ? contact.dob : "",
				"address": {
					"full": Address1,
					"suburb": suburb,
					"postCode": contact.areaCode ? contact.areaCode : 2000,
					"state": contact.state ? contact.state : 2000,
					"durationYears": state.currentYears ? state.currentYears : 0,
					"durationMonths": state.currentMonths ? state.currentMonths : 0,
				},
			},
			"loan": {
				"amount": state.amount ? state.amount : 0,
				"deposit": state.deposit ? state.deposit : 0,
				"term": state.term ? state.term : 1,
				"balloon": state.balloon ? state.balloon : 0
			},
			"extra": {
				"vehicleYear": vehicleInfo.year ? vehicleInfo.year : "",
				"vehicleState": vehicleInfo.saleType.toLowerCase() === 'preowned' ? 'used' : (vehicleInfo.saleType.toLowerCase() === 'new' || vehicleInfo.saleType.toLowerCase() === 'demo') ? vehicleInfo.saleType.toLowerCase() : 'new',
				"vehicleMake": vehicleInfo.make ? vehicleInfo.make : "",
				"vehicleModel": vehicleInfo.model ? vehicleInfo.model : "",
				"source": dealersettings.client.name ? dealersettings.client.name : '',
			},
			"creditScore": {
				"band": state.creditScore ? state.creditScore : 'good'
			}
		}

		if (showPreviousAddress && address.previousAddress) {
			let Address2 = '', suburb1 = '   ';
			if (address.previousAddress) {
				const address1 = address.previousAddress.split(",");
				if (address1.length > 0) Address2 = address1[0];
				if (address1.length > 1) suburb1 = (address1[1].trim().split(' '))[0];
			}
			reqObj.user.previousAddress = {
				"full": Address2,
				"suburb": suburb1,
				"postCode": contact.areaCode ? contact.areaCode : 2000,
				"state": contact.state ? contact.state : 2000,
				"durationYears": state.currentYears ? state.currentYears : 0,
				"durationMonths": state.currentMonths ? state.currentMonths : 0,
			}
		}

		setLoader(true);

		const reqResponse = await axios.post(
			`${apiURL}xdriva-api/api/xdriva/driva`,
			{ api_key: driva.settings.api_key, partnerId: driva.settings.partnerId, isQA: driva.settings.isQA ? driva.settings.isQA : false, bodyData: reqObj, documentID: documentID ? documentID : null },
		);
		console.log('reqResponse', reqResponse);

		if (reqResponse.data.success === false) {
			if (!_.isEmpty(reqResponse.data.drivaTestjson) && reqResponse.data.drivaTestjson.error) {
				Swal.fire('Error', `Error: ${reqResponse.data.drivaTestjson.error}`, 'error')
			}
			if (!_.isEmpty(reqResponse.data.drivaTestjson) && reqResponse.data.drivaTestjson.message) {
				Swal.fire('Error', `Error: ${reqResponse.data.drivaTestjson.message}`, 'error')
			}
			setLoader(false);
			return
		}

		handleClose('saved');

		// Alert about Email and SMS Has been sent
		Swal.fire(CommonHelper.showLocale({dealersettings}, 'Success'), CommonHelper.showLocale({dealersettings}, 'A finance request has been sent.'), 'success')

		let newObj = {
			...drivaDoc,
			enquiryID: enquiryID,
			clientID: dealersettings.client.documentID,
			addedDate: window.firebase.firestore.Timestamp.now(),
			modifiedDate: window.firebase.firestore.Timestamp.now(),
			documentID: documentID ? documentID : reqResponse.data.drivaTestjson.uuid,
			addedBy: localStorage.uid,
			modifiedBy: localStorage.uid,
			// drivaID: reqResponse.data.drivaTestjson.uuid,
		}
		newObj.request = { ...reqObj }
		newObj.response = { ...reqResponse.data.drivaTestjson }


		// const obj = {
		// 	...reqResponse.data.drivaTestjson,
		// 	enquiryID: enquiryID,
		// 	clientID: dealersettings.client.documentID,
		// 	addedDate: window.firebase.firestore.Timestamp.now(),
		// 	modifiedDate: window.firebase.firestore.Timestamp.now(),
		// 	documentID: documentID ? documentID : reqResponse.data.drivaTestjson.uuid,
		// 	drivaID: reqResponse.data.drivaTestjson.uuid,
		// 	requirement: selectedVehicle,
		// 	loanInfo: {
		// 		amount: state.amount ? state.amount : 0,
		// 		deposit: state.deposit ? state.deposit : 0,
		// 		term: state.term ? state.term : 1,
		// 		balloon: state.balloon ? state.balloon : 0,
		// 		creditScore: state.creditScore ? state.creditScore : 0,
		// 	},
		// 	address: {
		// 		address: address.address ? address.address : '',
		// 		previousAddress: address.previousAddress ? address.previousAddress : '',
		// 		currentYears: state.currentYears ? state.currentYears : '',
		// 		currentMonths: state.currentMonths ? state.currentMonths : '',
		// 		previousYears: state.previousYears ? state.previousYears : '',
		// 		previousMonths: state.previousMonths ? state.previousMonths : '',
		// 	},
		// 	reqObj: reqObj,
		// 	type: 'driva',
		// 	status: 'Pending',
		// };

		const ref = firestoreDB(dealersettings)
			.firestore()
			.collection('finance')
			.doc(newObj.documentID);
		await ref.set(newObj, { merge: true })


		setLoader(false);
	};

	const handleOnChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		console.log('name, value', name, value);

		let fields = Object.assign({});
		var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
		var str = _.trim(value);

		if (name === 'email' || name === 'secondaryEmail') {
			fields[name] = _.trim(value);
			// this.setState({ fields: fields }, () => { this.errorChange(name); });
			setAddress({
				...address,
				...fields
			})
			console.log('fields1', fields);

		}
		else {
			if (array1.length > 0) {
				var newarray1 = [];
				for (var x = 0; x < array1.length; x++) {
					newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
				}
				fields[name] = newarray1.join(' ');
				fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
				// this.setState({ fields: fields }, () => { this.errorChange(name); });
				console.log('fields2', fields);
				setAddress({
					...address,
					...fields
				})
			} else {
				if (str && str.length >= 1) {
					var firstChar = str.charAt(0);
					var remainingStr = str.slice(1);
					str = firstChar.toUpperCase() + remainingStr;
				}
				fields[name] = str ? str.replace(/\u2028/g, '') : '';
				// this.setState({ fields: fields }, () => { this.errorChange(name); });
				console.log('fields3', fields);
				setAddress({
					...address,
					...fields
				})

			}
		}
	}

	const bindAddress = (place, name) => {
		let state = Object.assign({});
		let street = '';
		let componentForm = {
			street_number: 'short_name',
			route: 'long_name',
			sublocality_level_1: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			country: 'short_name',
			//administrative_area_level_1: 'long_name',
			//country: 'long_name',
			postal_code: 'short_name'
		};

		if (place.formatted_address !== null && name === 'address') {
			state['address'] = place.formatted_address;
		} else if (place.formatted_address !== null && name === 'previousAddress') {
			state['previousAddress'] = place.formatted_address;
		}

		if (!_.isEmpty(place.address_components)) {
			for (var i = 0; i < place.address_components.length; i++) {
				var addressType = place.address_components[i].types[0];
				if (componentForm[addressType]) {
					var val = place.address_components[i][componentForm[addressType]];

					if (addressType === "postal_code")
						state['areaCode'] = val;

					if (addressType === "administrative_area_level_1")
						state['state'] = val;

					if (addressType === "locality")
						state['suburb'] = val;

					if (addressType === "country") {
						state['country'] = val;
						// this.handleCountryChange(val);
					}


					if (addressType === "street_number" || addressType === "route") {
						street += ` ${val}`;
					}

					// if (addressType === "route") {
					//     var sublocality_level_1 = val;
					// }
				}
			}
		}

		if (street) {
			state['street'] = street.trim();
		}

		console.log('bindAddress, ', state);
		setAddress({
			...address,
			...state
		})
		// this.setState({ fields: state }, () => {
		// 		this.errorChange('address');
		// 		this.errorChange('areaCode');
		// 		this.errorChange('state');
		// 		this.errorChange('country');
		// });
	};

	return (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-loaninfo-mini'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className='modal-title'>
						<Translate text={title ? title : 'Finance Details'} />
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='pop-layout form-style'>
							<div className="pop-subhead">
								<h3>Personal Info</h3>
							</div>
							<div className="form-group">
								<div className="form-readonly-wrap">
									<div className="input-readonly">
										<div className="input-readonly-inner">
											<div className="calllogs-head"> <i className="ico icon-Contacts"></i> {CommonHelper.displayContactName([], contact)} </div>
											<div className="calllogs-contact-sub">
												<ul className="calllogs-contact-details">
													<li> <i className="ico icon-call"></i> {
														contact.phone
															? CommonHelper.getFullPhone(
																contact.phoneCode,
																contact.phone,
															)
															: '--'
													}</li>
													<li> <i className="ico icon-Calendar" style={{ 'fontSize': '16px', 'paddingTop': '1px' }}></i> {contact.email ? contact.email : "--"}</li>
													{contact.dob && (
														<li> <i className="ico icon-date"></i>{
															contact.dob
																? moment(contact.dob).format('DD/MM/YYYY')
																: ''
														}</li>
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="pop-subhead">
								<h3><Translate text={'vehicleInformation'} /></h3>
							</div>
							<div className="calllogs-vehicle-box">
								<div className="flex-vehicle">
									<div className="vehicle-item-image"> <a href="#"> <img src={CommonHelper.showBrandLogo(dealersettings, vehicleInfo.make)} className="img-object-fit-contain" alt="" width="70" height="70" /> </a> </div>
									<div className="vehicle-data">
										<div className="vehicle-item-title">{`${vehicleInfo.make ? vehicleInfo.make : ""} ${vehicleInfo.model ? vehicleInfo.model : ""}`}</div>
										<div className="vehicle-item-info">{`${vehicleInfo.extColor ? `${vehicleInfo.extColor}, ` : ""} ${vehicleInfo.year ? vehicleInfo.year : ""}`}</div>
										<div className="vehicle-item-status">
											<div className="badge badge-pill badge-white">{vehicleInfo.saleType ? vehicleInfo.saleType : "--"}</div>
										</div>
									</div>
								</div>
							</div>

							<div className="pop-subhead mt-3">
								<h3><Translate text={'address'} /></h3>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label><Translate text={'currentAddress'} /><span style={{ color: '#2a3042' }}>*</span></label>
									<AutoComplete
										className={`form-control ${errors[`address`] ? errors[`address`] : 'false'
											}`}
										bindAddress={(e) => bindAddress(e, 'address')}
										types={['address']}
										value={address.address}
										placeholder={'search address'}
										onChange={handleOnChange}
										componentRestrictions={{ country: 'au' }}
										name="address"
									/>
								</div>
								<div className="form-group col-md-3">
									<label><Translate text={'durationYears'} /><span style={{ color: '#2a3042' }}>*</span></label>
									<NumberFormat
										allowNegative={false}
										thousandSeparator={true}
										suffix={' Year(s)'}
										placeholder={'0 Year'}
										className={`form-control ${errors[`currentYears`] ? errors[`currentYears`] : 'false'
											}`}
										onValueChange={e => onChange(e, 'currentYears')}
										value={state.currentYears ? state.currentYears : ''}
									/>
								</div>
								<div className="form-group col-md-3">
									<label><Translate text={'durationMonths'} /></label>
									<NumberFormat
										allowNegative={false}
										thousandSeparator={true}
										suffix={' Month(s)'}
										placeholder={'0 Month'}
										className={`form-control ${errors[`currentMonths`] ? errors[`currentMonths`] : 'false'
											}`}
										onValueChange={e => onChange(e, 'currentMonths')}
										value={state.currentMonths ? state.currentMonths : ''}
									/>
								</div>
							</div>

							{showPreviousAddress ? (
								<div className="form-row">
									<div className="form-group col-md-6">
										<label><Translate text={'Previous Address'} /><span style={{ color: '#2a3042' }}>*</span></label>
										<AutoComplete
											className={`form-control ${errors[`previousAddress`] ? errors[`previousAddress`] : 'false'
												}`}
											bindAddress={(e) => bindAddress(e, 'previousAddress')}
											types={['address']}
											value={address.previousAddress}
											placeholder={'search address'}
											onChange={handleOnChange}
											componentRestrictions={{ country: 'au' }}
											name="previousAddress"
										/>
									</div>
									<div className="form-group col-md-3">
										<label><Translate text={'durationYears'} /><span style={{ color: '#2a3042' }}>*</span></label>
										<NumberFormat
											allowNegative={false}
											thousandSeparator={true}
											suffix={' Year(s)'}
											placeholder={'0 Year'}
											className={`form-control ${errors[`previousYears`] ? errors[`previousYears`] : 'false'
												}`}
											onValueChange={e => onChange(e, 'previousYears')}
											value={state.previousYears ? state.previousYears : ''}
										/>
									</div>
									<div className="form-group col-md-3">
										<label><Translate text={'durationMonths'} /></label>
										<NumberFormat
											allowNegative={false}
											thousandSeparator={true}
											suffix={' Month(s)'}
											placeholder={'0 Month'}
											className={`form-control ${errors[`previousMonths`] ? errors[`previousMonths`] : 'false'
												}`}
											onValueChange={e => onChange(e, 'previousMonths')}
											value={state.previousMonths ? state.previousMonths : ''}
										/>
									</div>
								</div>
							) : (<></>)}


							<div className="pop-subhead mt-3">
								<h3><Translate text={'Loan Info'} /></h3>
							</div>
							<div className="form-row">
								<div className="form-group col-md-6">
									<label><Translate text={'vehicleAmount'} /><span style={{ color: '#2a3042' }}>*</span></label>
									<NumberFormat
										allowNegative={false}
										thousandSeparator={true}
										prefix={currencySymbol}
										placeholder={currencySymbol}
										className={`form-control ${errors[`amount`] ? errors[`amount`] : 'false'
											}`}
										onValueChange={e => onChange(e, 'amount')}
										value={state.amount ? state.amount : ''}
									/>
								</div>
								<div className="form-group col-md-6">
									<label><Translate text={'Deposit'} /><span style={{ color: '#2a3042' }}>*</span></label>
									<NumberFormat
										allowNegative={false}
										thousandSeparator={true}
										prefix={currencySymbol}
										placeholder={currencySymbol}
										className={`form-control ${errors[`deposit`] ? errors[`deposit`] : 'false'
											}`}
										onValueChange={e => onChange(e, 'deposit')}
										value={state.deposit ? state.deposit : ''}
									/>
								</div>
								<div className="form-group col-md-6">
									<label><Translate text={'Term (Years)'} /><span style={{ color: '#2a3042' }}>*</span></label>
									<ReactSelect
										options={termOptions}
										name='term'
										placeholder={'select term '}
										onChange={handleSelect}
										value={state.term ? state.term : ''}
										classNamePrefix={`${errors[`term`] ? errors[`term`] : ''} basic-select`}
										menuPlacement={'top'}
									/>
								</div>
								<div className="form-group col-md-6">
									<label><Translate text={'Balloon Payment %'} /></label>
									<NumberFormat
										allowNegative={false}
										thousandSeparator={false}
										suffix={'%'}
										decimalScale={2}
										placeholder={'%'}
										className={`form-control ${errors[`balloon`] ? errors[`balloon`] : 'false'
											}`}
										onValueChange={e => onChange(e, 'balloon')}
										value={state.balloon ? state.balloon : ''}
									/>
								</div>
								{/* <div className="form-group col-md-6">
                  <label>Credit Score<span style={{color: '#2a3042'}}>*</span></label>
                  <ReactSelect
										options={creditScoreOptions}
										name='creditScore'
										placeholder={'select credit score'}
										onChange={handleSelect}
										value={state.creditScore ? state.creditScore : ''}
										classNamePrefix={`${errors[`creditScore`] ? errors[`creditScore`] : ''} basic-select`}
										menuPlacement={'top'}
									/>
                </div> */}
							</div>

						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-primary float-right'
					onClick={e => {
						if (loader) {
							return
						}
						e.preventDefault();
						handleSubmit();
					}}
				>
					{loader ? (
						<span
							className='spinner-border spinner-button mr-1'
							role='status'
							aria-hidden='true'
						></span>
					) : (
						<></>
					)}
					<Translate text={'submit'} />
				</button>
				<button
					type='button'
					className='btn btn-default float-left'
					onClick={e => {
						e.preventDefault();
						handleClose();
					}}
				>
					<Translate text={'close'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddDrivaInfo;
