/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import toast from 'toasted-notes'
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { InputCheckBox, UpgradePlan } from '../../../../components';

const Rules = ({ dealersettings }) => {
	const [state, setState] = useState({});
	const [passwordOptions, setPasswordOptions] = useState({});
	const [loader, setLoader] = useState(false);
	const [pageLoader, setPageLoader] = useState(true);
	const ref = window.firebase
		.firestore()
		.doc(
			`clients/${dealersettings.client.id}/securitySettings/${dealersettings.client.id}`,
		);

	useEffect(() => {
		window.securitySettingsSnapshot = ref.onSnapshot(snap => {
			if (snap.exists) {
				let data = snap.data()
				if (data.passwordExpiryForLockLicense) {
					data.passwordExpiryForLockLicense = data.passwordExpiryForLockLicense / 60
				}
				if (data.passwordExpiryForLockFileDownload) {
					data.passwordExpiryForLockFileDownload = data.passwordExpiryForLockFileDownload / 60
				}
				if (!isEmpty(data.passwordOptions)) {
					setPasswordOptions(!isEmpty(data.passwordOptions) ? data.passwordOptions : {})
					delete data.passwordOptions
					setState(data);
				} else {
					setState(data);
				}
				setPageLoader(false)
			}
		});

		return () => {
			window.securitySettingsSnapshot && window.securitySettingsSnapshot();
		};
	}, []);

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--settingscontainerHeight',
				720 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--settingscontainerHeight',
			720 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
			document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 50) + 'px')
		};
	}, []);

	const handleCheckChange = e => {
		let check = e.target.checked;
		// console.log(check);
		setState({
			...state,
			[e.target.name]: e.target.checked,
		});
	};

	const handleQuoteNumberChange = e => {
		var regexp = /^[0-9+ \b]+$/;
		let number = e.target.value;

		// if value is not blank, then test the regex
		if (number === '' || regexp.test(number)) {
			const num = number !== '' ? parseInt(number) : number
			if (e.target.name === 'passwordExpiryForLockLicense' || e.target.name === 'passwordExpiryForLockFileDownload') {
				setState({
					...state,
					[e.target.name]: num
				})
			} else if (e.target.name === 'minPasswordLength') {
				if (num > 0 && num < 6) {
					return
				}
				setPasswordOptions({
					...passwordOptions,
					[e.target.name]: num
				})
			}
		}
	};

	const onSave = async () => {
		const objLog = { ...state }
		if (objLog.passwordExpiryForLockLicense) {
			objLog.passwordExpiryForLockLicense = objLog.passwordExpiryForLockLicense * 60
		}
		if (objLog.passwordExpiryForLockFileDownload) {
			objLog.passwordExpiryForLockFileDownload = objLog.passwordExpiryForLockFileDownload * 60
		}
		try {
			setLoader(true)
			await ref.set({
				...objLog,
				passwordOptions
			}, { merge: true });
			setLoader(false)
			toast.notify('Security Rules Updated', {
				duration: 2000
			})

			//update settings date and priority
			if (!isEmpty(dealersettings.client)) {
				window.firebase.firestore().doc(`clients/${dealersettings.client.id}`)
					.set({
						settingsUpdate: window.firebase.firestore.Timestamp.now(),
						settingsUpdatePriority: 'normal',
						settingsUpdateBy: dealersettings?.name || null,
						settingsUpdateText: 'Security Rules has been updated',
					}, { merge: true })
			}
			//end 

		} catch (error) {
			setLoader(false)
			console.log(error);
		}
	}

	const handleCheckedChange = e => {
		console.log(`e.target.value, e.target.name, e.target.checked`, e.target.value, e.target.name, e.target.checked)

		setPasswordOptions({
			...passwordOptions,
			[e.target.name]: e.target.checked
		})
	}

	return (
		<>
			<h2>Security Rules</h2>
			<div className={`security-settings-inner form-style`}>
				<p>Enforce security rules on all users of this state.</p>
				<div className='divider-line'></div>
				<h3>Two-factor authentication</h3>
				<div className='w-100 d-block font-14 mb-4'>
					<div className='switch-list'>
						<ul>
							<li>
								{' '}
								<span className='switch switch-sm mr-2'>
									<InputCheckBox
										id='enableTwoFactorAuth'
										className='switch'
										name={'enableTwoFactorAuth'}
										checked={
											state.enableTwoFactorAuth
												? state.enableTwoFactorAuth
												: false
										}
										onChange={handleCheckChange}
									/>
									<label htmlFor='enableTwoFactorAuth'></label>
								</span>
								Enable two-factor authentication.
							</li>
						</ul>
					</div>
				</div>
				<div className='divider-line'></div>
				{/* <h3>Password Strength</h3>
				<div className='settings-inner w-100 d-inline-block font-14'>
					<h5 className='settings-subhead'>Password options</h5>
					<div className="check-list">
							<div className="checkbox icheck-success">
									<input type="checkbox" id="lowerCaseAndUpperCase" name={'lowerCaseAndUpperCase'} checked={passwordOptions.lowerCaseAndUpperCase} onChange={handleCheckedChange} />
									<label htmlFor="lowerCaseAndUpperCase"> <Translate text={'Both lowercase and uppercase letters.'} /> </label>
							</div>
					</div>
					<div className="check-list">
							<div className="checkbox icheck-success">
									<input type="checkbox" id="atLeaseOneNumber" name={'atLeaseOneNumber'} checked={passwordOptions.atLeaseOneNumber} onChange={handleCheckedChange} />
									<label htmlFor="atLeaseOneNumber"> <Translate text={'At least one number'} /> </label>
							</div>
					</div>
					<div className="check-list">
							<div className="checkbox icheck-success">
									<input type="checkbox" id="atLeaseOneSpeicalCharacter" name={'atLeaseOneSpeicalCharacter'} checked={passwordOptions.atLeaseOneSpeicalCharacter} onChange={handleCheckedChange} />
									<label htmlFor="atLeaseOneSpeicalCharacter"> <Translate text={'At least one special character'} /> </label>
							</div>
					</div>
					
					<h5 className='settings-subhead'>Minimum number of characters</h5>
					<div className='form-row'>
						<div className='form-group col-md-3'>
							<input
								type="text"
								autoComplete="off"
								placeholder={''}
								className={`form-control`}
								name="minPasswordLength"
								onChange={handleQuoteNumberChange}
								value={!isEmpty(passwordOptions) && passwordOptions.minPasswordLength ? passwordOptions.minPasswordLength : ''}
								maxLength={2}
							/>
						</div>
					</div>
				</div>
				<div className='divider-line'></div> */}
				<h3>Content Security</h3>
				{/* <p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p> */}
				<div className='w-100 d-block font-14 mb-4'>
					{' '}
					<div className='switch-list'>
						<ul>
							<li>
								{' '}
								<span className='switch switch-sm mr-2'>
									<InputCheckBox
										id='lockLicenseImage'
										className='switch'
										name={'lockLicenseImage'}
										checked={
											state.lockLicenseImage
												? state.lockLicenseImage
												: false
										}
										onChange={handleCheckChange}
									/>
									<label htmlFor='lockLicenseImage'></label>
								</span>
								Lock License Image
							</li>
							<li>
								{' '}
								<span className='switch switch-sm mr-2'>
									<InputCheckBox
										id='logPhoneScreenshots'
										className='switch'
										name={'logPhoneScreenshots'}
										checked={
											state.logPhoneScreenshots
												? state.logPhoneScreenshots
												: false
										}
										onChange={handleCheckChange}
									/>
									<label htmlFor='logPhoneScreenshots'></label>
								</span>
								Log Phone Screenshots
							</li>
							{/* logPhoneScreenshots */}
						</ul>
					</div>
					<h5 className='settings-subhead'>Password Expiry time (in minutes)</h5>
					<div className='form-row'>
						<div className='form-group col-md-3'>
							<input
								type="text"
								autoComplete="off"
								placeholder={''}
								className={`form-control`}
								name="passwordExpiryForLockLicense"
								onChange={handleQuoteNumberChange}
								value={state.passwordExpiryForLockLicense ? state.passwordExpiryForLockLicense : ''}
								maxLength={2}
							/>
						</div>
					</div>
				</div>
				<div className='divider-line'></div>
				<h3>Files Security</h3>
				{/* <p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p> */}
				<div className='w-100 d-block font-14 mb-4'>
					{' '}
					<div className='switch-list'>
						<ul>
							<li>
								{' '}
								<span className='switch switch-sm mr-2'>
									<InputCheckBox
										id='lockFileDownload'
										className='switch'
										name={'lockFileDownload'}
										checked={
											state.lockFileDownload
												? state.lockFileDownload
												: false
										}
										onChange={handleCheckChange}
									/>
									<label htmlFor='lockFileDownload'></label>
								</span>
								Lock File Download
							</li>
							{/* <li>
								{' '}
								<span className='switch switch-sm mr-2'>
									<InputCheckBox
										id='logPhoneScreenshots'
										className='switch'
										name={'logPhoneScreenshots'}
										checked={
											state.logPhoneScreenshots
												? state.logPhoneScreenshots
												: false
										}
										onChange={handleCheckChange}
									/>
									<label htmlFor='logPhoneScreenshots'></label>
								</span>
								Log Phone Screenshots
							</li> */}
							{/* logPhoneScreenshots */}
						</ul>
					</div>
					<h5 className='settings-subhead'>Password Expiry time (in minutes)</h5>
					<div className='form-row'>
						<div className='form-group col-md-3'>
							<input
								type="text"
								autoComplete="off"
								placeholder={''}
								className={`form-control`}
								name="passwordExpiryForLockFileDownload"
								onChange={handleQuoteNumberChange}
								value={state.passwordExpiryForLockFileDownload ? state.passwordExpiryForLockFileDownload : ''}
								maxLength={2}
							/>
						</div>
					</div>
				</div>
				<div className="settings-footer mt-3">
					<button type="button" className="btn btn-primary float-right" onClick={() => onSave()}>
						{
							loader ?
								(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
								: (<></>)
						}
						Save
					</button>
				</div>
			</div>
		</>
	);
};

export default Rules;
