import React from 'react';
import { NavLink } from 'react-router-dom';
import { MailRoutes } from '../../constants/routes';
import Translate from '../../constants/translate';

const EmailLeftBar = ({ handleOpenComposeModal, unreadCount, setSelectedMessage, structureType }) => {
	return (
		<div className='email-leftbar'>
			<div className='email-compose-wrap'>
        <a 
          onClick={e => {
						e.preventDefault(); 
						handleOpenComposeModal({
							type: 'compose',
							title: 'New Message'
						});
          }} 
          className='btn btn-primary btn-block compose-btn'
          style={{color: '#fff'}}
        >
					<i className='ico icon-mail-compose'> </i> <span>Compose</span>
				</a>
			</div>
			<div className='email-menulink'>
				<ul>
					{MailRoutes.map((rec, index) => {
						return (
							<li className='email-nav-item' key={index} onClick={() => setSelectedMessage(null)}>
								<NavLink to={rec.route} className='email-nav-link'>
									<div className='email-nav-item'>
										<div className='email-nav-inner email-nav-grid'>
											<i className={rec.class}> </i>
											<span>
												<Translate text={rec && rec.name} />
											</span>
										</div>
										<div className='email-nav-inner'>
											{unreadCount > 0 && rec.name === 'Inbox' && (
												<span className='badge badge-pill badge-mail'>{unreadCount}</span>
											)}
										</div>
									</div>
								</NavLink>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default EmailLeftBar;
