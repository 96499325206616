/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allStockFieldsTakeVM, objStock } from '../viewModel';
/** COMPONENTS */
import CommonHelper from '../../../services/common';
import { ContentOverlay, TableView } from '../../../components';
import FilterPanel from './filter'

//import { default as _images } from '../../images';
// import AddStock from '../stock/add'
// import StockHistory from '../history'

import StockListReducer from '../stockListReducer'
import Swal from 'sweetalert2';

const StockList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [stocks, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [sortName, setSortName] = useState('modifiedDate')
    const [sortOrder, setSortOrder] = useState('desc')
    const [hasMore, setHasMoreData] = useState([])
    const [stockFields, setStockFields] = useState(props.dealersettings.stockFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [OEMShow, setOEMShow] = useState(false)
    //const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [searchText, setSearchText] = useState(localStorage.OemStockSalesSearchText ? localStorage.OemStockSalesSearchText : '')
    const [stockCount, setStockCount] = useState(0)
    const [isPaging, setPaging] = useState(false)
    const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)

    const [csvHeader, setHeader] = useState([])
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    useEffect(() => {
        let headerFields = stockFields;
        let allHeaderFields = allStockFieldsTakeVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [stockFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubOEMLstStocks && window.unSubOEMLstStocks();
        }
    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'stocks')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        if (localStorage.stockFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.stockFilter])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        if (isFilterApplied || localStorage.stockFilter) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _stockFilter = JSON.parse(localStorage.stockFilter);
            let _filter = Object.assign({}, _stockFilter.value);
            let _sortOrder = sortName + ' ' + sortOrder;
            let _isWhere = false;
            _filter.stockType = 'oem'

            let searchStock = window.firebase.firestore().collection(`stock`)
                .where('isDeleted', '==', false)
                .where('settingsID', '==', props.dealersettings.client.settingsID)
                .where('isAllocated', '==', false)
                .where('status', '==', 'available')
            if (_filter.keywords) {
                _isWhere = true
                searchStock = searchStock
                    .where('keywords', 'array-contains', _filter.keywords.toLowerCase())
            }

            if (hasMore.length > 0) {
                searchStock = searchStock
                    .startAfter(hasMore[0])
                    .limit(_isWhere ? pageLimit : 500)
            }
            else {
                searchStock = searchStock
                    .limit(_isWhere ? pageLimit : 500)
            }

            searchStock
                .get()
                .then(docs => {
                    onQueryCollectionStocks(docs, _filter);
                })
        }
        else {
            let collectionDataRef = window.firebase.firestore().collection('stock')
                .where('settingsID', '==', props.dealersettings.client.settingsID)
                .where('isAllocated', '==', false)
                .where('status', '==', 'available')
                .where('isDeleted', '==', false)

            if (searchText && searchText.trim()) {
                collectionDataRef = collectionDataRef
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }

            collectionDataRef = collectionDataRef
                .orderBy('modifiedDate', 'desc')

            if (hasMore.length > 0) {
                collectionDataRef = collectionDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                collectionDataRef = collectionDataRef
                    .limit(pageLimit)
            }

            window.unSubOEMLstStocks = collectionDataRef
                .onSnapshot(onCollectionUpdate);
        }

        // return () => {
        //     window.unSubOEMLstStocks();
        // }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        const stocks = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                let item = doc.data()
                if (item.status !== 'inProduction' && item.status !== 'unavailable' && !item.clientID) {
                    stocks.push(dataMappingVM({
                        ...item,
                        documentID: doc.id
                    }));
                }
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : stocks,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }

        setDataload(false)
        setStockLoader(false)
        setPaging(false)
    }

    const onQueryCollectionStocks = (querySnapshot, _filter) => {

        let _stocks = [];

        if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.docs.forEach(function (doc) {
            var dataVM = dataMappingVM({ ...doc.data(), 'documentID': doc.id });
            _stocks.push(dataVM)
        });

        // _stocks = _stocks.filter(item => (
        //     ((_filter.dateType && _filter.startDate && _filter.endDate && item[`${_filter.dateType}Value`])
        //         ? (item[`${_filter.dateType}Value`].seconds >= moment(_filter.startDate).unix() && item[`${_filter.dateType}Value`].seconds <= moment(_filter.endDate).unix()) : true)
        //     && (_filter.keywords ? _.indexOf(item.keywords, _filter.keywords.toLowerCase()) >= 0 : true)
        //     && (_filter.owner ? item.addedByValue === _filter.owner : true)
        //     && (_filter.status === 'available' ? (item.statusValue === _filter.status && item.isAllocated === false) : true)
        //     && (_filter.status === 'allocated' ? item.isAllocated === true : true)
        //     && (_filter.saleType ? item.saleTypeValue === _filter.saleType : true)
        //     && (_filter.location ? item.locationValue === _filter.location : true)
        //     && (_filter.make ? item.make.toLowerCase().includes(_filter.make.toLowerCase()) : true)
        //     && (_filter.model ? item.model.toLowerCase().includes(_filter.model.toLowerCase()) : true)
        //     && (_filter.year ? item.year === _filter.year : true)
        //     && (_filter.isSale ? item.isSale === _filter.isSale : true)
        //     && (_filter.isTestDrive ? item.isTestDrive === _filter.isTestDrive : true)
        //     && (_filter.isLoan ? item.isLoan === _filter.isLoan : true)
        // ))
        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _stocks,
            sortName: '',
            sortOrder: ''
        });

        setStockLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const dataMappingVM = (doc) => {
        const dealersettings = props.dealersettings

        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;
        if (doc.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[doc.clientID] && dealersettings.group.clients[doc.clientID].settings) {
            doc.location = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.docLocation, doc.location, '');
            doc.saleType = CommonHelper.getNameByValue(dealersettings.group.clients[doc.clientID].settings.salesType, doc.saleType, '');
        }
        else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            doc.location = CommonHelper.getNameByValue(dealersettings.client.settings.docLocation, doc.location, '');
            doc.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, doc.saleType, '');
        }

        const objStockData = Object.assign({}, doc);
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }
        stock.regDateValue = doc.regDate
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.buildDate))
            stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

        stock.stockInDate = ((!_.isEmpty(stock.stockInDate))
            ?
            handleDiffDays(stock.stockInDate)
            :
            '');
        stock.statusName = stock.status ? stock.status : '';
        stock.status = CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, stock.status, '', 'ml-1');
        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));


        if (stock.clientID && stock.oemID && stock.isAllocated) {
            stock.status = (<div className="badge badge-pill badge-allocated-fill ">ALLOCATED</div>)
            let clientName = props.dealersettings.clients.filter(client => client.id === stock.clientID)
            if (clientName.length > 0) {
                stock.clientName = clientName[0].name ? clientName[0].name : ''
            } else {
                stock.clientName = ''
            }
        }

        stock.addedBy = CommonHelper.getUserNamebyId(allUsers, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(allUsers, stock.modifiedBy);
        return stock
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('stockFilter', JSON.stringify(_filter));
            localStorage.OemStockSalesSearchText = '';
            setFilterApplied(true);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.stockFilter)) {
            localStorage.removeItem('stockFilter');
            localStorage.OemStockSalesSearchText = '';
            setFilterApplied(false);
            setStockLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ stockFields: fields }, { merge: true })
            .then(() => {
                setStockFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('stockFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (
            <div style={{ width: '130px', float: 'left' }}>
                {/* <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelHistoryShow(true);
                    }}>
                    <i className="ico icon-history"></i>
                </div> */}

                {/* <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button ml-2"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelShow(true);
                    }}>
                    <i className="ico icon-edit"></i>
                </div> */}

                <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button ml-2"
                    title='Take it'
                    onClick={(e) => handleTakeIt(id)}
                >
                    <i className="ico icon-mail-inbox"></i>
                </div>
            </div>
        )
    }

    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
        handleTakeIt(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleOEMOpen = () => {
        setOEMShow(true);
    }

    const handleTakeIt = id => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to take this stock?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                try {
                    const ref = window.firebase.firestore().collection('stock').doc(id)
                    await ref.update({
                        clientID: props.dealersettings.client.id,
                        isAllocated: true,
                        oemID: props.dealersettings.client.settingsID,
                        groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                        regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        stockInDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        modifiedFrom: 'web',
                    })
                    toast.notify('Stock taken successfully', {
                        duration: 2000
                    })
                    props.handleClose()
                } catch (error) {
                    console.log(error)
                    toast.notify('Error in taking stock', {
                        duration: 2000
                    })
                }
            }
        })
    }

    return (
        <>
            <FilterPanel
                handleOEMOpen={handleOEMOpen}
                sortOrder={sortOrder}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                sortName={sortName}
                handlesortOrder={(val) => {
                    setSortOrder(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleSortNamechange={(val) => {
                    setSortName(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleAddStock={() => {
                    setModelShow(true);
                    setEditDocument(null);
                }}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setStockLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([])
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.stockFilter))
                        localStorage.removeItem('stockFilter');

                }}
                csvHeader={csvHeader}
                datalist={stocks}
            />
            {
                stockLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            <TableView
                                datalist={stocks}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allStockFieldsTakeVM}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(stockFields && stockFields.length > 0) ? stockFields : allStockFieldsTakeVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailsClick}
                                isSettings={false}
                                isReorderable={false}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                                handleTakeIt={handleTakeIt}
                            />
                        </div>
                    )
            }
        </>
    )
}

export default StockList