/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Slider } from 'antd';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, AntDateRangePicker,
    ReactSelect, InputText, ReactMultiSelect
    // ReactMultiSelect,
} from '../../components';
//import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { stockManagementScore, stockScoreGrades } from '../../services/enum';

//#region  ASSIGN VARIABLES
let locations = [];
let sale_type = [];
let usages = [];
let transmissions = [];
let transportMethods = [];
let groupClients = [];
let stockStatuses = [];

const stock_status = [
    { active: true, value: 'available', label: 'Available' },
    { active: true, value: 'sold', label: 'Sold' },
    { active: true, value: 'onLoan', label: 'on Loan' },
    { active: true, value: 'onTestdrive', label: 'on Testdrive' },
    { active: true, value: 'reserved', label: 'Reserved' },
    { active: true, value: 'inProduction', label: 'In Production' },
    { active: true, value: 'unavailable', label: 'Unavailable' }
]

const market_price = [
    { active: true, value: 'above', label: <div className={`badge badge-pill status-lost`}>{'Above Market Price'}</div> },
    { active: true, value: 'below', label: <div className={`badge badge-pill status-won`}>{'Below Market Price'}</div> }
]

const stock_enquiry = [
    { active: true, value: 'with', label: 'Stock With Enquiry' },
    { active: true, value: 'without', label: 'Stock Without Enquiry' }
]
const stock_images = [
    { active: true, value: 'with', label: 'Stock With Image' },
    { active: true, value: 'without', label: 'Stock Without Image' }
]

const sort_type = [
    { active: true, value: 'asc', label: 'Ascending' },
    { active: true, value: 'desc', label: 'Descending' }
]
const sort_name = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> },
    { active: true, value: 'stockInDate', label: <Translate text={'stockInDate'} /> },
    { active: true, value: 'make', label: <Translate text={'make'} /> },
    { active: true, value: 'model', label: <Translate text={'model'} /> },
    { active: true, value: 'year', label: <Translate text={'vehicleYear'} /> },
    //{ active: true, value: 'soldDate', label: <Translate text={'soldDate'} /> },
    //{ active: true, value: 'deliveredDate', label: <Translate text={'deliveredDate'} /> },
    { active: true, value: 'priceDate', label: <Translate text={'Price Updated Date'} /> },
    { active: true, value: 'regNoDate', label: <Translate text={'Plate Updated Date'} /> }
]

// const sale_type = [
//     { active: true, value: 'New', label: 'New' },
//     { active: true, value: 'Demo', label: 'Demo' },
//     { active: true, value: 'Preowned', label: 'Preowned' }
// ]

// const locations = [
//     { active: true, value: 'Dubai Warehouse', label: 'Dubai Warehouse' },
//     { active: true, value: 'Sharjah Warehouse', label: 'Sharjah Warehouse' },
//     { active: true, value: 'Ajman Warehouse', label: 'Ajman Warehouse' }
// ]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> },
    { active: true, value: 'stockInDate', label: <Translate text={'stockInDate'} /> },
    //{ active: true, value: 'soldDate', label: <Translate text={'soldDate'} /> },
    //{ active: true, value: 'deliveredDate', label: <Translate text={'deliveredDate'} /> },
    { active: true, value: 'warrantyStartDate', label: <Translate text={'warrantyStartDate'} /> },
    { active: true, value: 'warrantyExp', label: <Translate text={'warrantyExp'} /> },
    { active: true, value: 'regDate', label: <Translate text={'regDate'} /> },
    { active: true, value: 'regExp', label: <Translate text={'regExp'} /> },
    { active: true, value: 'priceDate', label: <Translate text={'Price Updated Date'} /> },
    { active: true, value: 'regNoDate', label: <Translate text={'Plate Updated Date'} /> }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const _marks = {
    0: '0',
    //30: '30',
    60: '60',
    //90: '90',
    120: '120',
    //150: '150',
    180: '180',
    // 210: '210',
    240: '240',
    //270: '270',
    300: '300',
    //330: '330',
    366: '366'
};
//#endregion
const StockAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [types, setTypes] = useState([])
    const [groups, setGroups] = useState([])
    const [years, setYears] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const { columnFields, dealersettings } = props;
    const stockCredit = ((dealersettings?.client?.clientSettings?.stockCredit) ? true : false);
    const stockLocations = dealersettings?.client?.clientSettings?.stockLocations;
    useEffect(() => {
        const { stockFilter } = props;

        if (!_.isEmpty(stockFilter))
            setFilterFields(stockFilter.value);
        else
            setFilterFields({});

    }, [props.stockFilter])

    const formatter = (value) => `${value} day${value > 1 ? '(s)' : ''}`;

    useEffect(() => {
        const { dealersettings, clientUsers, stockFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;
            let _fields = !_.isEmpty(stockFilter) ? stockFilter.value : {};
            const _years = [];
            const _makes = [];

            groupClients = []
            if ((dealersettings.group.enableSharedStock === true || localStorage.defaultModule === 'oem') && dealersettings?.group?.clients)
                groupClients = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid].name } })

            if (!_.isEmpty(stockLocations) && !_.isEmpty(dealersettings.clients))
                groupClients = _.map(dealersettings.clients.filter(m => stockLocations.includes(m.documentID)), function (e) { return { label: e.name, value: e.documentID } });

            // const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus)
            // dateTypes.forEach((obj) => {
            //     if (obj.value === 'soldDate') {
            //         obj.label = CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date';
            //     }
            //     else if (obj.value === 'deliveredDate') {
            //         obj.label = CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
            //     }
            // });

            // sort_name.forEach((obj) => {
            //     if (obj.value === 'soldDate') {
            //         obj.label = CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date';
            //     }
            //     else if (obj.value === 'deliveredDate') {
            //         obj.label = CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
            //     }
            // });

            locations = [];
            setttings.stockLocation && setttings.stockLocation.forEach((doc) => {
                locations.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            usages = [];
            setttings.stockUsage && setttings.stockUsage.forEach(doc => {
                usages.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            transmissions = [];
            setttings.transmissions && setttings.transmissions.forEach(doc => {
                transmissions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            transportMethods = [];
            setttings.stockTransport && setttings.stockTransport.forEach(doc => {
                transportMethods.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            stockStatuses = [];
            setttings.stockStatus && setttings.stockStatus.forEach(doc => {
                stockStatuses.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color || '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                _years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });


            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const types = [];
                const models = [];
                const groups = [];

                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _models.forEach((data, index) => {
                    return models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });

                setTypes(_.orderBy(types, ['label'], ['asc']));
                setGroups(_.orderBy(groups, ['label'], ['asc']));
                setModels(_.orderBy(models, ['label'], ['asc']));
            }

            const _clientUsers = [];
            if (clientUsers) {
                clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                    });
                });
            }

            setClientUsers(_clientUsers);
            setMakes(_makes);
            setYears(_years);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const onChange = (value, name) => {
        //console.log('onChange: ', value, name);
        let state = Object.assign({}, filterFields);
        state[name] = value;
        setFilterFields(state);
    };

    const onChangeComplete = (value, name) => {
        //console.log('onChangeComplete: ', name, value);
        let state = Object.assign({}, filterFields);
        state[name] = value;
        setFilterFields(state);
        // console.log('start: ', moment().add(parseInt(`-${value[0]}`), 'days').format('YYYY-MM-DD'));
        // console.log('end: ', moment().add(parseInt(`-${value[1]}`), 'days').format('YYYY-MM-DD'));

        // moment().add(parseInt(`-${value[1]}`), 'days').format('YYYY-MM-DD');
        // moment().add(parseInt(`-${value[0]}`), 'days').format('YYYY-MM-DD');
        // moment().format('YYYY-MM-DD');


    };

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state);
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        state[data.name] = value;

        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const types = [];
            const models = [];
            const groups = [];

            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data) => {
                return models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });

            setTypes(_.orderBy(types, ['label'], ['asc']));
            setGroups(_.orderBy(groups, ['label'], ['asc']));
            setModels(_.orderBy(models, ['label'], ['asc']));

            state[data.name] = e.value;

            if (state.hasOwnProperty('model')) state['model'] = null;
            if (state.hasOwnProperty('vehicleType')) state['vehicleType'] = null;
            if (state.hasOwnProperty('vehicleGroup')) state['vehicleGroup'] = null;

            setFilterFields(state)
        }
        else if (data.name === 'vehicleGroup' && e) {
            const models = [];

            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];

            _models && _models.filter(m => m.group === e.value && (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data) => {
                return models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });

            setModels(_.orderBy(models, ['label'], ['asc']));

            state[data.name] = e.value;

            if (state.hasOwnProperty('model')) state['model'] = null;

            setFilterFields(state)
        }
        else if (data.name === 'vehicleType' && e) {
            const models = [];

            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];

            _models && _models.filter(m => m.type === e.value && (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data) => {
                return models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });

            setModels(_.orderBy(models, ['label'], ['asc']));

            state[data.name] = e.value;

            if (state.hasOwnProperty('model')) state['model'] = null;

            setFilterFields(state)
        }
        else {
            if (e)
                state[data.name] = e.value;
            else
                state[data.name] = null;
            setFilterFields(state)
        }
    }

    const handleGroupChange = (e, data) => {

        let state = Object.assign({}, filterFields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setTypes(_.orderBy(types, ['label'], ['asc']));
        setFilterFields(state)

    }

    const handleTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else if (['priceAge', 'stockAge', 'plateAge'].includes(name))
                state[name] = [0, 30];
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
                delete state['vehicleGroup'];
                delete state['vehicleType'];
                setTypes([]);
                setGroups([]);
                setModels([]);
            }
        }
        setFilterFields(state);
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.stockFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }
    const handleApply = () => {

        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.stockFilter) && !_.isEmpty(props.stockFilter.value) && JSON.stringify(props.stockFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.stockFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }

    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };
    //#endregion    

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader"> <Translate text={'stock'} /></div>

                <div className="advanced-filter-list">

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('sorting') >= 0)
                            ?
                            <>
                                {/* sorting RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'sorting'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('sorting')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'sorting'}><Translate text={'sorting'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('sorting')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={sort_name}
                                                        id={"sorting-name"}
                                                        name={"sorting"}
                                                        placeholder={'select sort name'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.sorting) && !_.isEmpty(filterFields.sorting.name))
                                                                ? filterFields.sorting.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={sort_type}
                                                        id={"sorting-type"}
                                                        name={"sorting"}
                                                        placeholder={'select sort type'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.sorting) && !_.isEmpty(filterFields.sorting.type))
                                                                ? filterFields.sorting.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>


                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'owner'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'owner'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">

                                                    {/* <ReactSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') && !_.isEmpty(filterFields.owner) ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(groupClients) && !_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* clientID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={!_.isEmpty(stockLocations) ? 'stockLocations' : 'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={_.orderBy(groupClients, ['label'], ['asc'])}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') ? filterFields.clientID : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={_.orderBy(groupClients, ['label'], ['asc'])}
                                                        name={"clientID"}
                                                        placeholder={!_.isEmpty(stockLocations) ? 'select locations' : 'select dealer'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') && !_.isEmpty(filterFields.clientID) ? filterFields.clientID.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* status */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={stockStatuses}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={stockStatuses}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('status') && !_.isEmpty(filterFields.status) ? filterFields.status.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('saleType') >= 0)
                            ?
                            <>
                                {/* saleType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'saleType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'saleType'}><Translate text={'saleType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={sale_type}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('saleType') ? filterFields.saleType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={sale_type}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('saleType') && !_.isEmpty(filterFields.saleType) ? filterFields.saleType.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('marketPrice') >= 0)
                            ?
                            <>
                                {/* marketPrice */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'marketPrice'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketPrice')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'marketPrice'}><Translate text={'marketPrice'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketPrice')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={market_price}
                                                        name={"marketPrice"}
                                                        placeholder={'select market price'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('marketPrice') ? filterFields.marketPrice : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stockEnquiry') >= 0)
                            ?
                            <>
                                {/* stockEnquiry */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'stockEnquiry'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockEnquiry')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'stockEnquiry'}><Translate text={'stockEnquiry'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockEnquiry')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={stock_enquiry}
                                                        name={"stockEnquiry"}
                                                        placeholder={'select stock enquiry'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('stockEnquiry') ? filterFields.stockEnquiry : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (stockCredit && !_.isEmpty(columnFields) && columnFields.indexOf('carScore') >= 0)
                            ?
                            <>
                                {/* carScore */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'carScore'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('carScore')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'carScore'}><Translate text={'carScore'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('carScore')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={_.map(stockScoreGrades, function (e) {
                                                            return {
                                                                ...e,
                                                                label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (e.color ? e.color : '#6b717f') }}>{`${e.name} Grade (${e.id})`}</div>,
                                                                value: e.id,
                                                            }
                                                        })}
                                                        name={"carScore"}
                                                        placeholder={'select grade score'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('carScore') && !_.isEmpty(filterFields.carScore) ? filterFields.carScore.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (stockCredit && !_.isEmpty(columnFields) && columnFields.indexOf('managementRating') >= 0)
                            ?
                            <>
                                {/* managementRating */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'managementRating'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('managementRating')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'managementRating'}><Translate text={'managementRating'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('managementRating')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={_.map(stockManagementScore, function (e) {
                                                            return {
                                                                ...e,
                                                                label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (e.color ? e.color : '#6b717f') }}>{`${e.name} Grade (${e.label})`}</div>,
                                                                value: e.id,
                                                            }
                                                        })}
                                                        name={"managementRating"}
                                                        placeholder={'select management rating'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('managementRating') && !_.isEmpty(filterFields.managementRating) ? filterFields.managementRating.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stockAge') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'stockAge'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockAge')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'stockAge'}><Translate text={'stockAge'} /> &nbsp;{'(in days)'}</label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockAge')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <Slider
                                                    range
                                                    max={366}
                                                    step={1}
                                                    value={filterFields.hasOwnProperty('stockAge') ? filterFields.stockAge : null}
                                                    marks={_marks}
                                                    tooltip={{ formatter }}
                                                    //defaultValue={[0, 30]}
                                                    onChange={(val) => onChange(val, 'stockAge')}
                                                    onAfterChange={(val) => onChangeComplete(val, 'stockAge')}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <></>
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('priceAge') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'priceAge'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('priceAge')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'priceAge'}><Translate text={'Price Change'} /> &nbsp;{'(in days)'}</label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('priceAge')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <Slider
                                                    range
                                                    max={180}
                                                    step={1}
                                                    value={filterFields.hasOwnProperty('priceAge') ? filterFields.priceAge : null}
                                                    marks={_marks}
                                                    tooltip={{ formatter }}
                                                    onChange={(val) => onChange(val, 'priceAge')}
                                                    onAfterChange={(val) => onChangeComplete(val, 'priceAge')}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <></>
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('plateAge') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'plateAge'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('plateAge')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'plateAge'}><Translate text={'Registration Plate Change'} /> &nbsp;{'(in days)'}</label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('plateAge')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <Slider
                                                    range
                                                    max={180}
                                                    step={1}
                                                    value={filterFields.hasOwnProperty('plateAge') ? filterFields.plateAge : null}
                                                    marks={_marks}
                                                    tooltip={{ formatter }}
                                                    onChange={(val) => onChange(val, 'plateAge')}
                                                    onAfterChange={(val) => onChangeComplete(val, 'plateAge')}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <></>
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('priceNoDays') >= 0) && (<>
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'priceNoDays'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('priceNoDays')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'priceNoDays'}><Translate text={'Price Not Updated With in days'} /> </label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('priceNoDays')
                                    ?
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <NumberFormat
                                                    thousandSeparator={false}
                                                    placeholder={'enter price not updated in days'}
                                                    className={`form-control`}
                                                    onValueChange={onNumberChange.bind(this, 'priceNoDays')}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    suffix={' day{s)'}
                                                    value={filterFields.hasOwnProperty('priceNoDays') ? filterFields.priceNoDays : null}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <></>
                            }
                        </>)
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stockImages') >= 0)
                            ?
                            <>
                                {/* stockImages */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'stockImages'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockImages')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'stockImages'}><Translate text={'stockImages'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stockImages')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={stock_images}
                                                        name={"stockImages"}
                                                        placeholder={'select stock image'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('stockImages') ? filterFields.stockImages : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('location') >= 0)
                            ?
                            <>
                                {/* location */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'location'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('location')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'location'}><Translate text={'location'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('location')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={locations}
                                                        name={"location"}
                                                        placeholder={'select stock location'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('location') ? filterFields.location : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={locations}
                                                        name={"location"}
                                                        placeholder={'select stock location'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('location') && !_.isEmpty(filterFields.location) ? filterFields.location.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('usage') >= 0)
                            ?
                            <>
                                {/* usage */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'usage'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('usage')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'usage'}><Translate text={'usage'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('usage')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={usages}
                                                        name={"usage"}
                                                        placeholder={'select stock usage'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('usage') ? filterFields.usage : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={usages}
                                                        name={"usage"}
                                                        placeholder={'select stock usage'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('usage') && !_.isEmpty(filterFields.usage) ? filterFields.usage.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('transmission') >= 0)
                            ?
                            <>
                                {/* transmission */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'transmission'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('transmission')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'transmission'}><Translate text={'transmission'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('transmission')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={transmissions}
                                                        name={"transmission"}
                                                        placeholder={'select transmission'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('transmission') ? filterFields.transmission : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={transmissions}
                                                        name={"transmission"}
                                                        placeholder={'select transmission'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('transmission') && !_.isEmpty(filterFields.transmission) ? filterFields.transmission.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('transportMethod') >= 0)
                            ?
                            <>
                                {/* transportMethod */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'transportMethod'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('transportMethod')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'transportMethod'}><Translate text={'transportMethod'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('transportMethod')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={transportMethods}
                                                        name={"transportMethod"}
                                                        placeholder={'select stock transport method'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('transportMethod') ? filterFields.transportMethod : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={transportMethods}
                                                        name={"transportMethod"}
                                                        placeholder={'select stock transport method'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('transportMethod') && !_.isEmpty(filterFields.transportMethod) ? filterFields.transportMethod.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={groups}
                                                        name={"vehicleGroup"}
                                                        placeholder={'select group'}
                                                        onChange={handleGroupChange}
                                                        value={filterFields.hasOwnProperty('vehicleGroup') ? filterFields.vehicleGroup : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={types}
                                                        name={"vehicleType"}
                                                        placeholder={'select type'}
                                                        onChange={handleTypeChange}
                                                        value={filterFields.hasOwnProperty('vehicleType') ? filterFields.vehicleType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('year') >= 0)
                            ?
                            <>
                                {/* year */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'year'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('year')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'year'}><Translate text={'year'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('year')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={years}
                                                        name={"year"}
                                                        placeholder={'select vehicle year'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('year') ? filterFields.year : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('extColor') >= 0)
                            ?
                            <>
                                {/* extColor */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'extColor'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('extColor')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'extColor'}><Translate text={'exteriorColor'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('extColor')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'exterior colour'}
                                                        className={`form-control`}
                                                        name="extColor"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('extColor') ? filterFields.extColor : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('intColor') >= 0)
                            ?
                            <>
                                {/* intColor */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'intColor'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('intColor')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'intColor'}><Translate text={'interiorColor'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('intColor')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'interior colour / trim'}
                                                        className={`form-control`}
                                                        name="intColor"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('intColor') ? filterFields.intColor : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isStockArrived') >= 0)
                            ?
                            <>
                                {/* isStockArrived */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isStockArrived'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isStockArrived')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isStockArrived'}><Translate text={'Stock Arrived'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isStockArrived')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Stock Arrived'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isStockArrived"
                                                                checked={filterFields.hasOwnProperty('isStockArrived') ? filterFields.isStockArrived : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isStockArrived') }} />
                                                            <label htmlFor="_isStockArrived"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isSale') >= 0)
                            ?
                            <>
                                {/* isSale */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isSale'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isSale')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isSale'}><Translate text={'availableForSale'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isSale')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'availableForSale'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isSale"
                                                                checked={filterFields.hasOwnProperty('isSale') ? filterFields.isSale : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isSale') }} />
                                                            <label htmlFor="_isSale"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isTestDrive') >= 0)
                            ?
                            <>
                                {/* isTestDrive */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isTestDrive'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isTestDrive'}><Translate text={'availableForTestDrive'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'availableForTestDrive'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isTestDrive"
                                                                checked={filterFields.hasOwnProperty('isTestDrive') ? filterFields.isTestDrive : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isTestDrive') }} />
                                                            <label htmlFor="_isTestDrive"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isLoan') >= 0)
                            ?
                            <>
                                {/* isLoan */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isLoan'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isLoan')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isLoan'}><Translate text={'availableForLoan'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isLoan')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'availableForLoan'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isLoan"
                                                                checked={filterFields.hasOwnProperty('isLoan') ? filterFields.isLoan : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isLoan') }} />
                                                            <label htmlFor="_isLoan"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isServiceLoan') >= 0)
                            ?
                            <>
                                {/* isServiceLoan */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isServiceLoan'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isServiceLoan')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isServiceLoan'}><Translate text={'availableForServiceLoan'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isServiceLoan')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'availableForServiceLoan'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isServiceLoan"
                                                                checked={filterFields.hasOwnProperty('isServiceLoan') ? filterFields.isServiceLoan : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isServiceLoan') }} />
                                                            <label htmlFor="_isServiceLoan"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                </div>

            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button>
                            <button type="button" className="btn btn-default float-left ml-2" onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }



            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.stockFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'stocks'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default StockAdvanceFilters;