/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    ReactSelect, InputText
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { discount_type, service_type } from '../../../services/enum';

const discountTypes = [
    { active: true, value: 'percentage', label: 'Percentage' },
    { active: true, value: 'amount', label: 'Amount' },
]

export const objFields = {
    discountType: '',
    discount: null
}

export default class AddDiscountService extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.state = {
            fields: Object.assign({}, objFields),
            errors: {},
            fullServiceCost: {
                total: 0,
                labourCost: 0,
                partsCost: 0,
                packagesCost: 0,
                taxCost: 0,
                subTotal: 0,
            }
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, service } = props;
        const fields = Object.assign({}, objFields);
        if (show === true && service) {
            const objData = Object.assign({}, service);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }
            this.setState({ fields: fields }, () => { this.getTotalServiceCost(); });
        }
        else if (show === true) {
            this.setState({ fields: fields }, () => { this.getTotalServiceCost(); });
        }
    }

    getTotalServiceCost = () => {
        const { recommendations, service, servicePackages } = this.props;
        const { discount, discountType } = this.state.fields;
        let _serviceData = {
            ...service,
            discount: discount,
            discountType: discountType,
        }
        let _fullCost = CommonHelper.getTotalServiceCost(_serviceData, recommendations, servicePackages);
        this.setState({ fullServiceCost: _fullCost });

    }


    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { this.getTotalServiceCost(); });
        }

    }

    handleSelectSave = (e, data) => {
        //this.saveEnquiryField(data.name, e.value);
        let state = Object.assign({}, this.state.fields);
        state[data.name] = e.value;
        state['discount'] = null;
        this.setState({ fields: state }, () => { this.errorChange(data.name); this.getTotalServiceCost(); });
    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
        const { fullServiceCost } = this.state;
        let state = Object.assign({}, this.state.fields);
        if (e && fullServiceCost.subTotal >= e.floatValue)
            state[name] = e.floatValue;
        else
            state[name] = null;
        this.setState({ fields: state }, () => { this.errorChange(name); this.getTotalServiceCost(); });
    }

    onPercentageChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); this.getTotalServiceCost(); });
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            this.setState({ errors: errors });
        }

    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();
        let fields = Object.assign({}, this.state.fields);
        this.props.handleClose(fields);
    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { discount, discountType } = this.state.fields;
        const { fullServiceCost } = this.state;
        const { show, dealersettings, isReadOnly } = this.props;

        const { taxType, taxPercentage, serviceType, internalTaxExemption } = this.props.service;
        const _isTaxIncluded = (serviceType !== service_type.internal || (serviceType === service_type.internal && !internalTaxExemption)) && taxPercentage && taxType ? true : false;
        const _dispalyTax = _isTaxIncluded ? `(${taxType} Incl.)` : `(${taxType ? taxType : 'Tax'} Excl.)`

        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-add-parts-discount`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                {'Discount'}
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">
                                    <div className="labourcost-discountpop-head">
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td className="labourcost-costtxt blue-color"><Translate text={'Sub Total Cost'}/>:</td>
                                                    <td className="labourcost-cost-box right-align blue-color" ><span><NumberFormat value={fullServiceCost.subTotal} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></span></td>
                                                </tr>
                                                {
                                                    fullServiceCost.actualDiscount
                                                        ?
                                                        <>
                                                            <tr >
                                                                <td className="labourcost-costtxt "><Translate text={'discount'} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.actualDiscount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${this.currencySymbol}-`} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    _isTaxIncluded
                                                        ?
                                                        <>

                                                            <tr >
                                                                <td className="labourcost-costtxt">  {taxType}&nbsp;<Translate text={'Payable'} />:</td>
                                                                <td className="labourcost-cost-box right-align"><NumberFormat value={fullServiceCost.taxCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <tr>
                                                    <td className="labourcost-costtxt-green"> <Translate text={'Total Cost'} /> {_dispalyTax}:</td>
                                                    <td className="labourcost-cost-box-total right-align" ><span><NumberFormat value={fullServiceCost.total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={this.currencySymbol} /></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        isReadOnly
                                            ?
                                            <></>
                                            :
                                            <>
                                                <div className="form-row">

                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'discountType'} /></label>
                                                        <ReactSelect
                                                            options={discountTypes}
                                                            name={"discountType"}
                                                            placeholder={'select discount Type'}
                                                            onChange={this.handleSelectSave}
                                                            value={discountType}
                                                            classNamePrefix={`${this.state.errors["discountType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>

                                                    {
                                                        discountType === discount_type.percentage
                                                            ?
                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'Discount %'} /></label>
                                                                <NumberFormat
                                                                    id={'discount'}
                                                                    decimalScale={2}
                                                                    format="##%"
                                                                    suffix={'%'}
                                                                    placeholder={'discount %'}
                                                                    allowNegative={false}
                                                                    className={`form-control ${this.state.errors["discount"]}`}
                                                                    onValueChange={this.onPercentageChange.bind(this, 'discount')}
                                                                    value={discount ? discount : null}
                                                                />
                                                            </div>
                                                            :
                                                            <>
                                                                {
                                                                    discountType === discount_type.amount
                                                                        ?
                                                                        <div className="form-group col-md-12">
                                                                            <label ><Translate text={'Discount Amount'} /></label>
                                                                            <NumberFormat
                                                                                id={'discount'}
                                                                                decimalScale={2}
                                                                                allowNegative={false}
                                                                                thousandSeparator={true}
                                                                                prefix={this.currencySymbol}
                                                                                placeholder={this.currencySymbol}
                                                                                className={`form-control ${this.state.errors["discount"]}`}
                                                                                onValueChange={this.onCurrencyChange.bind(this, 'discount')}
                                                                                value={discount ? discount : null}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <></>

                                                                }
                                                            </>

                                                    }


                                                </div>

                                            </>
                                    }

                                </div>

                            </div>
                        </div>

                    </Modal.Body>
                    {
                        isReadOnly
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer className="modal-footer">

                                    <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                                        <Translate text={'cancel'} />
                                    </button>

                                    <button type="button"
                                        className={`btn btn-primary float-right `}
                                        onClick={(e) => this.handleSubmit(e)} >
                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                        <Translate text={'apply'} />
                                    </button>

                                </Modal.Footer>
                            </>
                    }

                </Modal>



            </>
        );
    }
}
