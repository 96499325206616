/** LIBRARIES */
import React from 'react'
import { Droppable } from "react-beautiful-dnd";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
/** COMPONENTS */
import EnquiryCell from './enquirycell'
import Translate from '../../constants/translate';
import _ from 'lodash'
import { mapProps } from 'recompose';

const EnquiryStages = ({ column, tasks, handleEnquiryFav, sidepanelOpen, stageLoading, stageCounts, clientUsers, groupUsers, origins, pipeline, isFilterApplied, financeStatuses, searchText, handleShowInboundLead, dealersettings, handleStageAndStatusFilter }) => {
    const enquiryStatus = dealersettings &&
        dealersettings.client &&
        dealersettings.client.settings &&
        dealersettings.client.settings.enquiryStatus ? dealersettings.client.settings.enquiryStatus : [];

    return (
        <div className="pipeline-board" >
            <div className="pipeline-board-header sticky-board-head">
                <h2>{column.title} </h2>
                <div className="stage-counter-wrap">
                    <div className="float-left">{
                        stageCounts && stageCounts[column.id] && stageCounts[column.id]['total'] ? (
                            <h5>{stageCounts[column.id]['total']}  <Translate text={stageCounts[column.id]['total'] > 1 ? 'Enquiries' : 'Enquiry'} /> </h5>
                        ) : (
                            <h5>{tasks.length} <Translate text={column.taskIds.length > 1 ? 'Enquiries' : 'Enquiry'} /></h5>
                        )
                    } </div>
                    {
                        (pipeline === 'LeadsBucket' || isFilterApplied === true || (searchText && searchText.length > 2)) ? (<></>) : (
                            <div className="float-right">
                                <div className="counter-stage-nav">
                                    <ul>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>{_.find(enquiryStatus, { value: 'open' }) ? `${_.find(enquiryStatus, { value: 'open' }).name}` : 'Open'}{stageCounts && stageCounts[column.id] && stageCounts[column.id]['open'] ? `(${stageCounts[column.id]['open']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-open" onClick={(e) => {
                                                e.preventDefault();
                                                if (stageCounts && stageCounts[column.id] && stageCounts[column.id]['open'])
                                                    handleStageAndStatusFilter(column.id, 'open');
                                            }} >{stageCounts && stageCounts[column.id] && stageCounts[column.id]['open'] ? stageCounts[column.id]['open'] > 99 ? '99+' : stageCounts[column.id]['open'] : 0}</li>
                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>  {_.find(enquiryStatus, { value: 'won' }) ? `${_.find(enquiryStatus, { value: 'won' }).name}` : 'Won'}{stageCounts && stageCounts[column.id] && stageCounts[column.id]['won'] ? `(${stageCounts[column.id]['won']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-won" onClick={(e) => {
                                                e.preventDefault();
                                                if (stageCounts && stageCounts[column.id] && stageCounts[column.id]['won'])
                                                    handleStageAndStatusFilter(column.id, 'won');
                                            }}>{stageCounts && stageCounts[column.id] && stageCounts[column.id]['won'] ? stageCounts[column.id]['won'] > 99 ? '99+' : stageCounts[column.id]['won'] : 0}</li>

                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>{_.find(enquiryStatus, { value: 'delivered' }) ? `${_.find(enquiryStatus, { value: 'delivered' }).name}` : 'Delivered'}  {stageCounts && stageCounts[column.id] && stageCounts[column.id]['delivered'] ? `(${stageCounts[column.id]['delivered']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-delivered" onClick={(e) => {
                                                e.preventDefault();
                                                if (stageCounts && stageCounts[column.id] && stageCounts[column.id]['delivered'])
                                                    handleStageAndStatusFilter(column.id, 'delivered');
                                            }}>{stageCounts && stageCounts[column.id] && stageCounts[column.id]['delivered'] ? stageCounts[column.id]['delivered'] > 99 ? '99+' : stageCounts[column.id]['delivered'] : 0}</li>

                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>{_.find(enquiryStatus, { value: 'lost' }) ? `${_.find(enquiryStatus, { value: 'lost' }).name}` : 'Lost'}  {stageCounts && stageCounts[column.id] && stageCounts[column.id]['lost'] ? `(${stageCounts[column.id]['lost']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-lost" onClick={(e) => {
                                                e.preventDefault();
                                                if (stageCounts && stageCounts[column.id] && stageCounts[column.id]['lost'])
                                                    handleStageAndStatusFilter(column.id, 'lost');
                                            }}>{stageCounts && stageCounts[column.id] && stageCounts[column.id]['lost'] ? stageCounts[column.id]['lost'] > 99 ? '99+' : stageCounts[column.id]['lost'] : 0}</li>

                                        </OverlayTrigger>{' '}

                                    </ul>
                                </div>

                            </div>
                        )
                    }


                </div>
            </div>
            <Droppable droppableId={column.id} >
                {(provided, snapshot) => (
                    <div className={"pipeline-item-wraper " + (snapshot.isDraggingOver ? "pipeline-dragging-Over" : "")}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {tasks.map((task, index) => (
                            <EnquiryCell
                                key={index}
                                index={index}
                                id={task.id}
                                isFav={task.isFav}
                                status={task.status}
                                stage={task.stage}
                                origin={task.origin}
                                task={task}
                                handleEnquiryFav={handleEnquiryFav}
                                sidepanelOpen={sidepanelOpen}
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}
                                origins={origins}
                                financeStatuses={financeStatuses}
                                dealersettings={dealersettings}
                                handleShowInboundLead={handleShowInboundLead}
                            />
                        ))}
                        {
                            stageLoading && stageLoading === column.id ?
                                (<div style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>)
                                : (<></>)
                        }
                        <span
                            style={{
                                display: "none"
                            }}
                        >
                            {provided.placeholder}
                        </span>
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default EnquiryStages