import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { MainContext } from '../layout/provider';
import { PopUpModal } from '../../components';
import CommonHelper from '../../services/common';
import ComposeEmail from './draftComposeEmail';
import _, { isEmpty } from 'lodash';
import toast from 'toasted-notes';
import { truncate } from 'lodash';
import Swal from 'sweetalert2';

const DraftEmailList = ({
	messages,
	selectedMessage,
	setSelectedMessage,
	filterThreads,
	selectThreads,
	threadsSelected,
	searchText,
	setSearchText,
	updateMessage,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	type,
	searchThreads,
	refreshThreads,
	token,
	structureType,
	userData,
	emailtemplates,
	threadsLoader,
	isLimitReached,
	history
}) => {
	const { dealersettings } = useContext(MainContext);
	const [filter, setFilter] = useState(null);
	const [loading, setLoading] = useState(false);
	const [sendLoader, setSendLoader] = useState(false);
	const [drafts, setDrafts] = useState([]);
	const [showComposeModal, setShowComposeModal] = useState({
		show: false,
		type: '',
		title: '',
		emailData: null,
		thread: '',
	});
	const nylasEmail = dealersettings.nylas && dealersettings.nylas.email_address ? dealersettings.nylas.email_address : dealersettings.email

	useEffect(() => {
		const loadMessages = async () => {
			if (!selectedMessage) {
				return;
			}

			setLoading(true);
			// console.log(selectedMessage);
			const messageIdList = selectedMessage.draft_ids;
			const messageIdListLength = selectedMessage.draft_ids.length;
			let arrayCompleted = false;
			// // console.log('THIS IS SELECTED MESSAGE IDS messageIdList', messageIdList);
			const nylasMessage = window.firebase
				.functions()
				.httpsCallable('nylas-getMessage');
			setDrafts([]);
			const check = [];
			messageIdList.forEach((messageId, index) => {
				const dataObj = {
					token: token,
					id: messageId,
				};

				nylasMessage(dataObj).then(message => {
					check.push(message.data);
					if (messageIdListLength === check.length) {
						arrayCompleted = true;
						setDrafts(_.orderBy(check, ['date'], ['asc']));
						setLoading(false);
					}
				});

				if (selectedMessage.unread === true) {
					updateMessage(selectedMessage.id, 'read', {});
				}
			});
		};

		loadMessages();
	}, [selectedMessage, token]);

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--emailListItemWrapHeight',
				window.innerHeight - 170 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--emailListItemWrapHeight',
			window.innerHeight - 170 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const getNameFromParticipants = (participants, email) => {
		if (participants.length === 0) {
			return 'Draft';
		}

		const filteredParticipants = participants.filter(
			participant => participant.email !== email,
		);

		// if (filteredParticipants.length === 0) {
		// 	return participants[0].name 
		// }

		// return `${filteredParticipants[0].name}`;

		if (filteredParticipants.length === 0) {
			if (participants && participants.length > 0 && participants[0].name) {
				return participants[0].name ? participants[0].name : '';
			}
		}
		if (filteredParticipants && filteredParticipants.length > 0 && filteredParticipants[0].name) {
			return filteredParticipants[0].name ? filteredParticipants[0].name : '';
		}
	};

	const handleFilter = type => {
		setFilter(type);
		filterThreads(type);
	};

	const handleMarkAs = () => { };

	const handleSelectThreads = type => {
		selectThreads(type);
	};

	const handleOpenComposeModal = ({ type, title, emailData, thread }) => {
		setShowComposeModal({
			...showComposeModal,
			show: true,
			type,
			title,
			emailData: emailData ? emailData : null,
			thread: thread ? thread : '',
		});
	};

	const handleCloseComposeModal = () => {
		setShowComposeModal({
			...showComposeModal,
			show: false,
			type: '',
			title: '',
			emailData: null,
		});
	};

	const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
		const { mailTo, mailCc, mailBcc, subject, email } = trigger;
		setSendLoader(true);

		if (mailTo === '' && mailCc === '' && mailBcc === '') {
			toast.notify('Please specify at least one recipient.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (subject === '') {
			toast.notify('Please write subject.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (email === '' || email === '<p></p>\n') {
			toast.notify('Please write in email body.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		try {
			let emailObj = {
				token: token,
				draft: {
					subject: trigger.subject,
					body: trigger.email,
				},
			};

			if (trigger.mailTo !== '') {
				emailObj.draft.to = trigger.mailTo.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailCc !== '') {
				emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailBcc !== '') {
				emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}
			// console.log(emailObj);

			setTimeout(() => {
				toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> sending...</div>),
					{
						position: 'bottom-left',
						duration: null
					},
				)
				setShowComposeModal({
					...showComposeModal,
					show: false,
					type: '',
					title: '',
					emailData: null,
				});
			}, 300);

			// console.log('emailObj', emailObj);
			const nylasSendEmail = window.firebase
				.functions()
				.httpsCallable('nylas-sendEmail');
			const resp = await nylasSendEmail(emailObj);

			if (resp.data.success) {
				setSendLoader(false);
				toast.closeAll();
				toast.notify('Email sent successfully.', {
					duration: 2000,
				});
			} else {
				toast.closeAll();
				setSendLoader(false);
				if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
					Swal.fire({
						title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
						cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
					}).then(result => {
						if (result.value) {
							window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
								nylas: null,
							}, { merge: true });
							history.push('/settings/profile?key=emailsettings');
						}
					});
					return
				}
				else {
					Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');
				}
			}

			// console.log('response', resp);
		} catch (error) {
			setSendLoader(false);
			console.log(error);
			toast.closeAll();
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'There was an error in sending email.'), '', 'error');

		}
	};

	return (
		<div className='email-list-left'>
			<div className='email-list-filter'>
				<Dropdown
					className='filter-pipeline-btn email-muti-check float-left dropdown-toggle'
					alignRight
				>
					{/* Add float right and d-inline by myself */}
					<div className='checkbox icheck-success muticheck-fix'>
						<input type='checkbox' id='successaa' checked={threadsSelected} />
						<label
							htmlFor='successaa'
							onClick={() =>
								handleSelectThreads(threadsSelected ? 'none' : 'all')
							}
						></label>
					</div>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('all')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('none')}
						>
							<div className='pipeline-check'> </div>
							None
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleSelectThreads('unstarred')}
						>
							<div className='pipeline-check'> </div>
							Unstarred
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				{/* <div className='filter-email-btn ml-2'>
					<a href='#'>
						<i className='ico icon-filter'></i>
						<span>Filter </span>
						<i className='ico icon-dropdown'></i>
					</a>
				</div> */}
				<Dropdown
					className='filter-pipeline-btn  ml-2 float-left dropdown-toggle'
					alignRight
				>
					<Dropdown.Toggle
						as={CustomToggle}
						id='dropdown-custom-components'
						className={
							filter === 'read' || filter === 'unread' || filter === 'starred' ? 'filter-active' : ''
						}
					>
						<i className='ico icon-filter'></i> <span>Filter</span>
						<i className='ico icon-dropdown'></i>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleFilter('clear')}
						>
							<div className='pipeline-check'> </div>
							All
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'read' ? 'active' : ''
								}`}
							onClick={() => handleFilter('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className={`current-pipeline ${filter === 'unread' ? 'active' : ''
								}`}
							onClick={() => handleFilter('unread')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleFilter('starred')}
						>
							<div className='pipeline-check'> </div>
							Starred
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				{/* @info: Mark Dropdown */}
				<Dropdown
					className='filter-pipeline-btn  ml-2 float-left dropdown-toggle'
					alignRight
				>
					<Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
						<i className='ico icon-dropdown'></i> <span>Mark</span>
					</Dropdown.Toggle>
					<Dropdown.Menu
						as={CustomMenu}
						className='rm-pointers dropdown-menu-lg dropdown-menu-right'
						ChildClass='pipeline-dropdown'
						xplacement='bottom-end'
					>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('read')}
						>
							<div className='pipeline-check'> </div>
							Read
						</Dropdown.Item>
						<Dropdown.Item
							className='current-pipeline'
							onClick={() => handleMarkAs('read')}
						>
							<div className='pipeline-check'> </div>
							Unread
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>

				{/* <div className='filter-email-btn ml-2'>
					<a href='#'>
						<span>Mark </span>
						<i className='ico icon-dropdown'></i>
					</a>
				</div> */}

				<div className='mail-refresh float-right'>
					<a href='#' onClick={() => refreshThreads(type)}>
						<i className='ico icon-refresh'></i>
					</a>
				</div>
			</div>
			<div className='email-list-search'>
				<div className='input-group '>
					<input
						type='text'
						className='form-control'
						placeholder='Search'
						value={searchText}
						onChange={e => {
							setSearchText(e.target.value);
						}}
					/>
					<div className='input-group-append input-search-clear'>
						{searchText.length > 0 && (
							<a
								href='#'
								className='input-search-clear-icon'
								onClick={() => {
									if (searchText.length > 0) {
										setSearchText('');
										refreshThreads();
									}
								}}
							>
								<i className='ico icon-remove'></i>
							</a>
						)}
						<button
							className='btn btn-default'
							type='button'
							onClick={e => {
								// console.log('button clicked')
								searchThreads(searchText);
							}}
						>
							{' '}
							<i className='ico icon-search-fill'></i>{' '}
						</button>
					</div>
				</div>
			</div>

			{threadsLoader || refreshLoader ? (
				<div
					className=''
					style={{
						minHeight: '80vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div className='spinner-loader pt-5 h-100'>
						<div className='d-flex h-100 justify-content-center align-items-center text-primary'>
							<div role='status' className='spinner-border text-primary'></div>
							<p className='text-muted mt-3'></p>
						</div>
					</div>
				</div>
			) : (
				<div className='email-list-item-wrap'>
					{messages &&
						messages.map(message => (
							<div
								className={`email-list-item email-list-box email-list-nowrap ${!isEmpty(selectedMessage) && selectedMessage.id === message.id && 'active'
									} ${message.unread === true &&
									!isEmpty(selectedMessage) && selectedMessage.id !== message.id &&
									'unread'
									}`}
								key={message.id}
								onClick={() => {
									setSelectedMessage(message);
									handleOpenComposeModal({
										type: 'compose',
										title: 'New Message',
										thread: message,
									});
								}}
							>
								<div className='email-list-flex email-list-checkbox'>
									<div className='checkbox icheck-success'>
										<input
											type='checkbox'
											id={`success-${message.id}`}
											checked={message.selected && message.selected === true}
										/>
										<label
											htmlFor='success'
											onClick={() =>
												selectThreads(
													message.selected === true
														? 'single-unselect'
														: 'single',
													message.id,
												)
											}
										></label>
									</div>
								</div>
								<div className='email-list-flex email-list-grid email-list-hidden'>
									<div className='email-list-item email-list-item-title'>
										<div className='email-list-flex email-list-grid'>
											<h6 className='email-list-text'>
												{truncate(
													getNameFromParticipants(
														message.participants,
														nylasEmail,
													), {
													length: 28,
												})}{' '}
												<span className='mail-count'>
													{message.message_ids.length > 1 &&
														message.message_ids.length}
												</span>
											</h6>
										</div>
										{/* <span className="mail-attach-icon"><i className="ico icon-attachment"></i></span> */}
										<div className='email-list-flex email-datetime'>
											<small>
												{moment.unix(message.last_message_timestamp).fromNow()}
											</small>
										</div>
									</div>
									<h5 className='email-list-head'>{message.subject}</h5>
									<span
										className='email-list-content email-list-hidden'
										style={{ paddingRight: '20px', position: 'relative' }}
									>
										{message.snippet}
										<span
											onClick={() => {
												if (isEmpty(selectedMessage)) {
													return
												}
												updateMessage(
													selectedMessage,
													`${selectedMessage.starred ? 'unstar' : 'star'}`,
													{},
												)
											}
											}
											className='mail-icon-fav'
											style={{
												display: 'inlineBlock',
												position: 'absolute',
												right: '-3px',
												zIndex: '99',
												top: '0px',
												padding: '0',
												width: '22px',
												textAlign: 'center',
											}}
										>
											<i
												className={`ico icon-star${message.starred ? '-active' : ''
													}`}
											></i>
										</span>
									</span>
								</div>
							</div>
						))}
					{type === 'spam' ? (
						<></>
					) : (
						<div className='email-load-more w-100 d-flex justify-content-center align-items-center py-3'>
							{isLimitReached ? <></> : (
								<button className='btn btn-primary' onClick={() => loadMoreThreads('drafts')}>
									{loadingMore && (
										<span
											className='spinner-border spinner-button mr-1'
											role='status'
											aria-hidden='true'
										></span>
									)}
									Load more
								</button>
							)}
						</div>
					)}
				</div>
			)}

			<PopUpModal show={showComposeModal.show}>
				<ComposeEmail
					show={showComposeModal.show}
					handleClose={handleCloseComposeModal}
					token={token}
					type={showComposeModal.type}
					title={showComposeModal.title}
					emailData={showComposeModal.emailData}
					thread={showComposeModal.thread}
					userData={userData}
					sendLoader={sendLoader}
					setSendLoader={setSendLoader}
					// handleReply={handleReply}
					handleSend={handleSend}
					structureType={structureType}
					emailtemplates={emailtemplates}
					dealersettings={dealersettings}
				></ComposeEmail>
			</PopUpModal>
		</div>
	);
};

export default DraftEmailList;
