/** LIBRARIES */
import React, { PureComponent } from "react";

class InputRange extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const { id, value } = this.props;
        //console.log('componentDidMount - InputRange', id, value)
        this.slideguage(id, value);
    }

    slideguage = (id, value) => {
        if (value >= 0 && value <= 25) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 25 && value <= 50) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else if (value >= 50 && value <= 75) {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
        else {
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, rgb(255, 0, 0) 0% , rgb(255, 0, 0) 25% , rgb(255, 179, 115) 25%, rgb(255, 179, 115) 50%, rgb(175, 204, 115) 50%, rgb(175, 204, 115) 75%, rgb(100, 215, 112) 75%, rgb(100, 215, 112) ' + value + '% , rgb(204,204,204) ' + value + '%, rgb(204,204,204) 100%)'
        }
    }

    render() {
        const { id, name, value, onChange } = this.props;
        //('RENDER INPUT RANGE', name, value)
        return (
            <input
                id={id ? id : name}
                name={name}
                type="range"
                min={0}
                max={100}
                step="1"
                value={value}
                onChange={onChange}
            />
        );
    }
}

export default InputRange;