/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
import TestdriveDetails from '../details';
import SecondaryLicense from '../details/secondaryLicense';
import ContactConsent from '../../contacts/consent'
import FleetDetails from '../../fleet/details';
import ContactUnsubscribe from '../../contacts/unsubscribe'
import TermsDetails from '../signature';
import ContactPrivacy from '../../contacts/privacy';
import ContactUnsubscribeAMS from '../../contacts/unsubscribe/oldAMS';
import FleetConfirmation from '../../fleetPro/confirmation'


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/testdrive/details/:id" exact render={(match) => <TestdriveDetails testdriveid={match.match.params.id} />} />
                        <Route path="/testdrivelicense/details/:id" exact render={(match) => <SecondaryLicense testdriveid={match.match.params.id} />} />
                        <Route path="/contact/consent/:id" exact render={(match) => <ContactConsent contactid={match.match.params.id} />} />
                        <Route path="/fleet/details/:id" exact render={(match) => <FleetDetails fleetid={match.match.params.id} />} />
                        <Route path="/terms/sign/:id" exact render={(match) => <TermsDetails testdriveid={match.match.params.id} />} />

                        <Route path="/contact/unsubscribe/:id" exact render={(match) => <ContactUnsubscribe contactid={match.match.params.id} />} />
                        <Route path="/contact/privacy/:id" exact render={(match) => <ContactPrivacy id={match.match.params.id} />} />
                        <Route path="/contact/unsubscribe/ams/:id" exact render={(match) => <ContactUnsubscribeAMS contactid={match.match.params.id} />} />

                        <Route path="/fleetpro/confirmation/:id" exact render={(match) => <FleetConfirmation assignid={match.match.params.id} />} />
                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;