/** LIBRARIES */
import React, { PureComponent } from 'react';
    import { Overlay, Popover } from 'react-bootstrap'


class PopoverwithFooter extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    
    render() {
        //const ref = useRef(null);
        const {showpopover, targetpopover, position, closepopover, id, className, title, children  } = this.props;
        return (
            
            <div /*ref={ref}*/>
                <Overlay
                    show={showpopover}
                    target={targetpopover}
                    placement={position}
                    rootClose={true}
                    rootCloseEvent='click'
                    onHide={closepopover}                    
                    //container={ref.current}
                >
                    <Popover id={id ? id : "popover-positioned-left"} className={`popover-lg ${className}`}>
                        <Popover.Title as="h3">{<><span className="close" onClick={closepopover}>×</span>{title}</>}</Popover.Title> 
                        { showpopover ? children : <></> } 
                    </Popover>
                </Overlay>
            </div>
        );
    }
}

export default PopoverwithFooter;