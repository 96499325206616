/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, AntMonthPicker
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { serviceStatus, serviceStatuses } from '../../services/enum'
import { objModules } from './viewModel'

//#region  ASSIGN VARIABLES
let campaigns = [];
let origins = [];
let appointmentTypes = [];
let slots = [];
let nationalities = [];

let genders = [];



const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'stageDate', label: <Translate text={'stageDate'} /> },
    { active: true, value: 'appointmentDate', label: <Translate text={'appointmentDate'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> },
    { active: true, value: 'checkInDate', label: <Translate text={'checkInDate'} /> },
    { active: true, value: 'startDate', label: <Translate text={'Job Start Date'} /> },
    { active: true, value: 'completeDate', label: <Translate text={'Job Complete Date'} /> },
    { active: true, value: 'clientContactDate', label: <Translate text={'clientContactDate'} /> },
]

const objDateField = {
    name: null,
    type: null,
    range: null
}
let allAdvisorUsers = [];
let allTechnicianUsers = [];
let allPartsUsers = [];
let allWorkshopUsers = [];
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const { columnFields } = props;
    const [serviceAllStatus, setAllStatus] = useState([]);
    const [technicianAllStatus, setAllTechStatus] = useState([]);

    const [stages, setStages] = useState([])

    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])

    useEffect(() => {
        //console.log('props.pipeline', props.pipeline)
        if (_.isEmpty(props.pipeline))
            return;

        let _stages = [];
        let defaultstages = props.pipeline.filter(e => e.default === true)[0].stages;
        defaultstages.forEach((doc) => {
            _stages.push({
                value: doc.value,
                label: doc.name
            });
        });

        setStages(_stages);

    }, [props.pipeline])

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const serviceSetttings = dealersettings.client.services;
            const brands = dealersettings.client.brands;
            const _makes = [];
            const _stages = [];

            let _fields = !_.isEmpty(pipelineFilter) ? pipelineFilter.value : {};

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            appointmentTypes = [];
            serviceSetttings && serviceSetttings.appointmentTypes && serviceSetttings.appointmentTypes.forEach(doc => {
                appointmentTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            slots = [];
            serviceSetttings && serviceSetttings.slots && serviceSetttings.slots.forEach(doc => {
                slots.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    searchlabel: doc.name,
                    label: <span style={{ color: `${doc.color ? doc.color : '#f47815 '}` }}>
                        <i className="ico icon-service-timer"></i> {doc.name}
                        {
                            doc.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></span> : <></>
                        }
                    </span>,
                });
            });

            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });


            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    models: doc.models
                });
            });

            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setModels(models);
            }

            let defaultstages = serviceSetttings.pipelines.filter(e => e.default === true)[0].stages;
            if (props.pipeline)
                defaultstages = props.pipeline.filter(e => e.default === true)[0].stages;

            defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').forEach((doc) => {
                _stages.push({
                    value: doc.value,
                    label: doc.name
                });
            });

            if (!_.isEmpty(dealersettings.client.roles)) {
                allAdvisorUsers = [];
                allTechnicianUsers = [];
                allPartsUsers = [];
                allWorkshopUsers = [];

                let roleTechIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.technician === true), function (obj) {
                    return obj.documentID;
                })];

                let roleAdvIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.serviceAdvisor === true), function (obj) {
                    return obj.documentID;
                })];

                let rolePartIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.partsInterpretor === true), function (obj) {
                    return obj.documentID;
                })];

                let roleWorkIds = [..._.map(dealersettings.client.roles.filter(e => e.servicePermissions && e.servicePermissions.workshopManager === true), function (obj) {
                    return obj.documentID;
                })];


                let _selectedTechUsers = [];
                let _selectedAdvUsers = [];
                let _selectedPartUsers = [];
                let _selectedWorkUsers = [];

                if (!_.isEmpty(roleTechIds))
                    _selectedTechUsers = _.filter(clientUsers, (v) => _.includes(roleTechIds, v.roleID));

                if (!_.isEmpty(roleAdvIds))
                    _selectedAdvUsers = _.filter(clientUsers, (v) => _.includes(roleAdvIds, v.roleID));

                if (!_.isEmpty(rolePartIds))
                    _selectedPartUsers = _.filter(clientUsers, (v) => _.includes(rolePartIds, v.roleID));

                if (!_.isEmpty(roleWorkIds))
                    _selectedWorkUsers = _.filter(clientUsers, (v) => _.includes(roleWorkIds, v.roleID));



                _selectedWorkUsers && _selectedWorkUsers.map(rec => {
                    return allWorkshopUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                _selectedPartUsers && _selectedPartUsers.map(rec => {
                    return allPartsUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                _selectedTechUsers && _selectedTechUsers.map(rec => {
                    return allTechnicianUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                _selectedAdvUsers && _selectedAdvUsers.map(rec => {
                    return allAdvisorUsers.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            let _serviceAllStatus = [];
            serviceSetttings && serviceSetttings.serviceStatus && serviceSetttings.serviceStatus.forEach((doc) => {
                _serviceAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });


            let _technicianAllStatus = [];
            serviceSetttings && serviceSetttings.technicianStatus && serviceSetttings.technicianStatus.forEach((doc) => {
                _technicianAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_serviceAllStatus);
            setAllTechStatus(_technicianAllStatus);
            setClientUsers(_clientUsers);
            setMakes(_makes);
            setStages(_stages);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'pipeline') {
            const { dealersettings } = props;
            let _pipeline = value;
            const _stages = [];
            if (dealersettings && dealersettings.client && dealersettings.client.sevices && _pipeline && !_pipeline.includes(',')) {
                const setttings = dealersettings.client.sevices;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').map((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            setStages(_stages);
            state[data.name] = _pipeline;
            if (state.hasOwnProperty('stage'))
                state['stage'] = null;
        }
        else {
            state[data.name] = value;
        }
        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });
            setModels(models);
            state[data.name] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'pipeline' && e) {
            const { dealersettings } = props;
            let _pipeline = e.value;
            if (dealersettings && dealersettings.client && dealersettings.client.sevices) {
                const setttings = dealersettings.client.sevices;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                const _stages = [];
                defaultstages.forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });

                state[data.name] = _pipeline;
                state['stage'] = null;
                setFilterFields(state)
                setStages(_stages);
            }
        }
        else {
            state[data.name] = e.value;
            setFilterFields(state)
        }


    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else if (name === 'pipeline') {
                state[name] = null;
                state['stage'] = null;
                setStages([]);
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'services'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'owner'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'owner'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('serviceAdvisor') >= 0)
                            ?
                            <>
                                {/* serviceAdvisor */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'serviceAdvisor'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('serviceAdvisor')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'serviceAdvisor'}><Translate text={'serviceAdvisor'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('serviceAdvisor')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={allAdvisorUsers}
                                                        name={"serviceAdvisor"}
                                                        placeholder={'select service advisor'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('serviceAdvisor') && filterFields.serviceAdvisor ? filterFields.serviceAdvisor.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('technician') >= 0)
                            ?
                            <>
                                {/* technician */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'technician'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('technician')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'technician'}><Translate text={'technician'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('technician')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={allTechnicianUsers}
                                                        name={"technician"}
                                                        placeholder={'select technician'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('technician') && filterFields.technician ? filterFields.technician.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('partsInterpretor') >= 0)
                            ?
                            <>
                                {/* partsInterpretor */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'partsInterpretor'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('partsInterpretor')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'partsInterpretor'}><Translate text={'partsInterpretor'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('partsInterpretor')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={allPartsUsers}
                                                        name={"partsInterpretor"}
                                                        placeholder={'select parts interpretor'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('partsInterpretor') && filterFields.partsInterpretor ? filterFields.partsInterpretor.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('workshopManager') >= 0)
                            ?
                            <>
                                {/* workshopManager */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'workshopManager'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('workshopManager')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'workshopManager'}><Translate text={'workshopManager'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('workshopManager')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={allWorkshopUsers}
                                                        name={"workshopManager"}
                                                        placeholder={'select workshop manager'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('workshopManager') && filterFields.workshopManager ? filterFields.workshopManager.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('stage') >= 0)
                            ?
                            <>
                                {/* stage */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success">
                                        <InputCheckBox
                                            className="switch"
                                            name={'stage'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'stage'}>{'Stages'}</label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('stage')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={stages}
                                                        name={"stage"}
                                                        placeholder={'select stage'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('stage') && filterFields.stage ? filterFields.stage.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={serviceAllStatus}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('status') ? filterFields.status : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('slot') >= 0)
                            ?
                            <>
                                {/* APPOINTMENT TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'slot'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('slot')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'slot'}><Translate text={'slot'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('slot')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={slots}
                                                        name={"slot"}
                                                        placeholder={'select slot'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('slot') && filterFields.slot ? filterFields.slot.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isFav') >= 0)
                            ?
                            <>
                                {/* isFav */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isFav'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isFav'}><Translate text={'Favourite Services'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isFav')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Favourite Services'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isFav"
                                                                checked={filterFields.hasOwnProperty('isFav') ? filterFields.isFav : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isFav') }} />
                                                            <label htmlFor="_isFav"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('appointmentType') >= 0)
                            ?
                            <>
                                {/* APPOINTMENT TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'appointmentType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('appointmentType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'appointmentType'}><Translate text={'appointmentType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('appointmentType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={appointmentTypes}
                                                        name={"appointmentType"}
                                                        placeholder={'select appointment type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('appointmentType') ? filterFields.appointmentType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('jobConfirmed') >= 0)
                            ?
                            <>
                                {/* jobConfirmed */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'jobConfirmed'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('jobConfirmed')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'jobConfirmed'}><Translate text={'jobConfirmed'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('jobConfirmed')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'jobConfirmed'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_jobConfirmed"
                                                                checked={filterFields.hasOwnProperty('jobConfirmed') ? filterFields.jobConfirmed : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'jobConfirmed') }} />
                                                            <label htmlFor="_jobConfirmed"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('jobAllocated') >= 0)
                            ?
                            <>
                                {/* jobAllocated */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'jobAllocated'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('jobAllocated')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'jobAllocated'}><Translate text={'jobAllocated'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('jobAllocated')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'jobAllocated'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_jobAllocated"
                                                                checked={filterFields.hasOwnProperty('jobAllocated') ? filterFields.jobAllocated : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'jobAllocated') }} />
                                                            <label htmlFor="_jobAllocated"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('partsConfirmed') >= 0)
                            ?
                            <>
                                {/* partsConfirmed */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'partsConfirmed'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('partsConfirmed')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'partsConfirmed'}><Translate text={'partsConfirmed'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('partsConfirmed')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'partsConfirmed'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_partsConfirmed"
                                                                checked={filterFields.hasOwnProperty('partsConfirmed') ? filterFields.partsConfirmed : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'partsConfirmed') }} />
                                                            <label htmlFor="_partsConfirmed"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('workshopConfirmed') >= 0)
                            ?
                            <>
                                {/* workshopConfirmed */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'workshopConfirmed'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('workshopConfirmed')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'workshopConfirmed'}><Translate text={'workshopConfirmed'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('workshopConfirmed')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'workshopConfirmed'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_workshopConfirmed"
                                                                checked={filterFields.hasOwnProperty('workshopConfirmed') ? filterFields.workshopConfirmed : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'workshopConfirmed') }} />
                                                            <label htmlFor="_workshopConfirmed"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    <div className="advanced-filter-subheader"><Translate text={'contact'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactType') >= 0)
                            ?
                            <>
                                {/* contactType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactType'}><Translate text={'preferredContactType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_type}
                                                        name={"contactType"}
                                                        placeholder={'select contact Type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactType') ? filterFields.contactType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVIP') >= 0)
                            ?
                            <>
                                {/* isVIP */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isVIP'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isVIP'}><Translate text={'vip'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'vip'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isVIP"
                                                                checked={filterFields.hasOwnProperty('isVIP') ? filterFields.isVIP : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isVIP') }} />
                                                            <label htmlFor="_isVIP"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('gender') >= 0)
                            ?
                            <>
                                {/* GENDER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'gender'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'gender'}><Translate text={'gender'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"gender"}
                                                        placeholder={'select gender'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('gender') ? filterFields.gender : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('nationality') >= 0)
                            ?
                            <>
                                {/* nationality */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'nationality'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'nationality'}><Translate text={'nationality'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={nationalities}
                                                        name={"nationality"}
                                                        placeholder={'select nationality'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('nationality') ? filterFields.nationality : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    <div className="advanced-filter-subheader"><Translate text={'vehicle'} /></div>

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'service'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;