import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import ReactExport from 'react-export-excel-xlsx-fix';
import _ from 'lodash'
import CommonHelper from '../../services/common';
import { TableView, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import _images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import Translate from '../../constants/translate';
import { allcontactFields } from '../eventsProAttendees/viewModel';
import moment from 'moment';
import AttendeeInfo from '../eventsProAttendees/details'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const AttendeesList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [attendees, setAttendees] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [attendeeFields, setAttendeeFields] = useState([]);//props.dealersettings.attendeeFields)
    const [searchText, setSearchText] = useState('')
    const [csvHeader, setHeader] = useState([])
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [loader, setLoader] = useState();
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [detailModal, setDetailModal] = useState({ show: false })
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const defaultCountry = props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';

    const [allAttendeeFields, setAllAttendeeFields] = useState([])

    const fetchDirectExcel = () => {
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    useEffect(() => {
        if (_.isEmpty(props.attendees))
            return

        setAttendees(props.attendees.map(rec => {
            return {
                ...convertAttendeeVM(rec)
            }
        }))

    }, [props.attendees])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {


            let _countries = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                _countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _states = [];
            props.dealersettings.states && props.dealersettings.states.forEach((doc) => {
                _states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    country_code: doc.country_code
                });
            });

            setCountries(_countries)
            setStates(_states)
        }
    }, [])

    const convertAttendeeVM = (doc) => {

        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const contact = Object.assign({});
        for (let [key, value] of Object.entries(doc)) {
            contact[key] = value;
        }

        contact.objDoc = Object.assign({}, contact);
        contact.firstName = _.trim(doc.firstName);
        contact.phone = CommonHelper.getFullPhone(doc.phoneCode, doc.phone);
        contact.displayPhone = doc.phone ? (doc.phoneCode ? doc.phoneCode : '') + doc.phone : '';
        contact.displayID = contact.displayID ? contact.displayID : '';
        contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : '';
        contact.createdOn = contact.addedDate ? moment.unix(contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        contact.updatedOn = contact.modifiedDate ? moment.unix(contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        contact.checkedOn = contact.checkinDate ? moment.unix(contact.checkinDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        contact.addedBy = CommonHelper.getUserNamebyId(allUsers, contact.addedBy);
        contact.modifiedBy = CommonHelper.getUserNamebyId(allUsers, contact.modifiedBy);
        contact.owner = contact.owner ? CommonHelper.getUserNamebyId(allUsers, contact.owner) : '';

        contact.country = CommonHelper.getLabelByValue(countries, contact.country, '');
        contact.state = CommonHelper.getLabelByValue(states.filter(a => doc?.country ? a.country_code === doc.country : true), contact.state, '');
        contact.licenseState = CommonHelper.getLabelByValue(props.dealersettings.states.filter(e => e.country_code === defaultCountry), doc.licenseState, '');
        contact.csvPhone = contact.displayPhone ? `${contact.displayPhone}` : ''

        contact.source = CommonHelper.getNameByValue(props.eventSettings?.source, contact.source, '');
        contact.dietaryPreference = CommonHelper.getNameByValue(props?.eventSettings?.dietary, contact.dietaryPreference, '');
        contact.eventSlot = CommonHelper.getNameByValue(props?.eventSettings?.slots, contact.eventSlot, '');
        contact.dataLocation = CommonHelper.getNameByValue(props?.eventSettings?.dataLocation, contact.dataLocation, '');
        if (!_.isEmpty(contact.notes)) contact.lastNote = _.orderBy(contact.notes, ["addedDate"], ["desc"])[0]?.notes;

        let dealersettings = props.dealersettings;
        let _setttings = contact.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[contact.clientID] && dealersettings.group.clients[contact.clientID].settings;
        if (_.isEmpty(_setttings))
            _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;

        contact.fullName = CommonHelper.getFullName(null, contact, '', true);
        contact.gender = CommonHelper.getNameByValue(_setttings?.genders, contact.gender, '');
        contact.licenseType = CommonHelper.getNameByValue(_setttings?.licenseType, contact.licenseType, '');

        if (contact.clientID)
            contact.dealerName = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

        contact.optinPhone = doc.optinPhone === null ? '' : (doc.optinPhone ? 'Yes' : 'No');
        contact.optinEmail = doc.optinEmail === null ? '' : (doc.optinEmail ? 'Yes' : 'No');
        contact.optinPost = doc.optinPost === null ? '' : (doc.optinPost ? 'Yes' : 'No');
        contact.optinSMS = doc.optinSMS === null ? '' : (doc.optinSMS ? 'Yes' : 'No');
        contact.marketingOptinEmail = doc.marketingOptinEmail === null ? '' : (doc.marketingOptinEmail ? 'Yes' : 'No');
        contact.marketingOptinSMS = doc.marketingOptinSMS === null ? '' : (doc.marketingOptinSMS ? 'Yes' : 'No');

        const enquiryAllStatus = Object.assign([], dealersettings?.client?.settings?.enquiryStatus);
        contact.leadStatusName = CommonHelper.getNameByValue(enquiryAllStatus, doc.leadStatus, '');
        let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
        contact.leadStatus = !_.isEmpty(doc.leadStatus) ?
            (
                _objCurrentStatus
                    ?
                    <>
                        <span key={doc.leadStatus}
                            className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                    </>
            ) : ('');
        contact.statusName = doc.status;
        contact.status = (contact.status
            ?
            <div key={contact.status} className={`badge badge-pill badge-events-${contact.status}`}>
                {contact.status}
            </div>
            :
            <></>);
        contact.checkedStatusName = contact.checkinDate ? 'CHECKED-IN' : '';
        contact.checkedStatus = contact.checkinDate ? <><div className="badge badge-pill badge-available-fill ">CHECKED-IN</div></> : <></>;
        contact.guestTag = contact.guestOf ? <><div className="badge badge-pill badge-white badge-mini">{contact.guestOf}</div></> : <></>;

        if (!_.isEmpty(doc.dynamicFields)) {

            props?.eventSettings?.attendeesDF && props.eventSettings.attendeesDF.forEach(rec => {
                if (rec.type === 'toggle') {
                    contact[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'monthYear') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment(doc.dynamicFields[rec.value]).format('MMM YYYY') : '';
                }
                else if (rec.type === 'multiselect' && doc.dynamicFields[rec.value]) {
                    contact[`${rec.value}Value`] = _.isArray(doc.dynamicFields[rec.value]) ? doc.dynamicFields[rec.value].join(',') : doc.dynamicFields[rec.value]
                    contact[rec.value] = _.isArray(doc.dynamicFields[rec.value]) ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<div className="Vehicle-availability">{doc.dynamicFields[rec.value]}</div>)
                }
                else {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value] : ''
                }
            })
        }

        return contact
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard) {
                    csvBtn.current && csvBtn.current.link.click();
                    Swal.fire({
                        icon: 'success',
                        title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                        showConfirmButton: false,
                        timer: 1500
                    })
                    toast.closeAll();
                }
                else
                    fetchDirectExcel();

            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        let headerFields = attendeeFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allAttendeeFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allAttendeeFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allAttendeeFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(_headers);

    }, [allAttendeeFields])

    useEffect(() => {
        if (_.isEmpty(props.eventSettings?.attendeesDF)) {
            setAllAttendeeFields([...allcontactFields]);
            return
        }

        let _allcontactFieldsVM = [...allcontactFields];
        props.eventSettings.attendeesDF.forEach(rec => {
            if (!allAttendeeFields.some(e => e.value === rec.value))
                _allcontactFieldsVM.push({
                    name: rec.name,
                    value: rec.value,
                    flex: 1,
                    default: true,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        _allcontactFieldsVM.forEach(rec => {
            if (!_allcontactFieldsVM.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setAllAttendeeFields([..._allcontactFieldsVM]);
        setAttendeeFields(_.differenceBy(attendeeFields, deleteids))
    }, [props.eventSettings])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ attendeeFields: fields }, { merge: true })
            .then(() => {
                setAttendeeFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('attendeeFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(true)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" data-id={id} onClick={(e) => {

            setDetailModal({
                show: true,
                title: 'Attendee',
                attendeeid: id
            })


        }}>
            <i className="ico icon-zoom"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, data) => {

        setDetailModal({
            show: true,
            title: 'Attendee',
            attendeeid: id
        })

    }


    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.eventsProPermissions)) ? props.dealersettings.rolePermissions.eventsProPermissions : null);


    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">

                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => {
                                            setSearchText(e?.target?.value)
                                        }} />
                                    </div>
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>
                                <div className="float-right">

                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchDirectExcel();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>                                                        
                                                        <ExcelFile
                                                            ref={csvDirectBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`events_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(attendees, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                                                                showConfirmButton: false,
                                                                timer: 1500
                                                            })
                                                            toast.closeAll();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`events_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(attendees, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                            </div>
                            {
                                props.isFromDashboard && loader ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={false}
                                            datalist={_.orderBy(attendees.filter(
                                                ({ keywords, firstName, middleName, lastName, email, phone }) => (!_.isEmpty(_.trim(searchText)) ?
                                                    (
                                                        (keywords && keywords?.includes(_.trim(searchText).toLowerCase()))
                                                        || (firstName && firstName.toLowerCase().includes(_.trim(searchText).toLowerCase()))
                                                        || (lastName && lastName.toLowerCase().includes(_.trim(searchText).toLowerCase()))
                                                        || (middleName && middleName.toLowerCase().includes(_.trim(searchText).toLowerCase()))
                                                        || (email && email.toLowerCase().includes(_.trim(searchText).toLowerCase()))
                                                        //|| (phone && phone.toLowerCase().includes(_.trim(searchText).toLowerCase()))
                                                    )
                                                    : true)), ['addedDate.seconds'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={allAttendeeFields.filter(e => e.value !== 'checkbox')}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(attendeeFields && attendeeFields.length > 0) ? attendeeFields.filter(e => e.value !== 'checkbox') : allAttendeeFields.filter(e => e.default === true && e.value !== 'checkbox').map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={enquiryDetailclick}
                                            isSettings={false}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
            <PopUpModal show={detailModal.show}>
                <AttendeeInfo
                    {...props}
                    {...detailModal}
                    clsActive='overlay-modal active'
                    handleClose={() => { setDetailModal({ show: false }) }}
                >
                </AttendeeInfo>
            </PopUpModal>

        </>
    );
}

export default AttendeesList;