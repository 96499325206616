
/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
/** COMPONENTS */
import { CustomToggle, CustomMenu } from '../../components/customdropdown';
import { InputCheckBox } from '../../components';
import Translate from '../../constants/translate';
import Filters from '../contacts/filters'
import CommonHelper from '../../services/common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [contactFilter, setContactFilter] = useState({})
    const [searchText, setSearchText] = useState(props.searchText)
    const [myContactsOnly, setMyContactsOnly] = useState(props.myContactsOnly)
    const csvBtn = useRef();

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        setMyContactsOnly(props.myContactsOnly);
    }, [props.myContactsOnly])

    useEffect(() => {
        if (!_.isEmpty(localStorage.smsCampcontactFilter) && Object.keys(JSON.parse(localStorage.smsCampcontactFilter)).length > 0) {
            let _contactFilter = JSON.parse(localStorage.smsCampcontactFilter);
            let _contactValue = _contactFilter.value;

            if (!_.isEmpty(_contactValue) && _contactValue.hasOwnProperty('keywords'))
                setSearchKeywords(_contactValue['keywords']);
            else
                setSearchKeywords('');

            setContactFilter(_contactFilter);
        }
        else {
            setContactFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.smsCampcontactFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(contactFilter)) {
                let _contactFilter = Object.assign({}, contactFilter)
                if (!_.isEmpty(contactFilter.value)) {
                    // update with the existing keyword
                    _contactFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _contactFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_contactFilter);
            }
            else {
                // create new filter with keyword
                let _contactFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_contactFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(contactFilter)) {
                let _contactFilter = Object.assign({}, contactFilter)
                if (!_.isEmpty(contactFilter.value)) {
                    if (Object.keys(contactFilter.value).length > 1) {
                        delete _contactFilter.value['keywords']
                        props.handleApplyFilter(_contactFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };
    //#endregion

    const mergeContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.mergeContact) ? true : false);

    const transferContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.transferContact) ? true : false);

    const exportContactData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportContactData) ? true : false);

    return (
        <div className="filter-panel">

            {/* {console.log('props.sortName', props.sortName, sortOrders.find(item => item.value === props.sortName))} */}

            {
                localStorage.defaultModule !== 'oem' && mergeContact && !props.isUsedInAudience
                    ?
                    <div className="filter-item-btn">
                        <a href="#" onClick={(e) => { e.preventDefault(); props.handleMergeContacts() }}>
                            <i className="ico icon-merge"></i> Merge
                        </a>
                    </div>
                    :
                    <></>
            }


            {/* <Dropdown className={`sort-btn float-left ${mergeContact ? 'ml-2' : ''}`} alignRight>
                <Dropdown.Toggle as={CustomToggle} >
                    <span id="spn-sort-name" data-name={props.sortName}>{props.sortName && sortOrders.find(item => item.value === props.sortName) ? sortOrders.find(item => item.value === props.sortName).label : 'Sort By'}</span>
                    <i className="ico icon-dropdown"></i>
                </Dropdown.Toggle>
                <a href="#" className="sort-ico"
                    onClick={(e) => {
                        e.preventDefault();
                        props.handlesortOrder(props.sortOrder === 'desc' ? 'asc' : 'desc')
                        localStorage.contactSortOrder = (props.sortOrder === 'desc' ? 'asc' : 'desc');
                    }}
                > <i id="spn-sort-order" data-name={props.sortOrder} className={`ico icon-sort-${props.sortOrder === 'desc' ? 'up' : 'down'}`}></i> </a>
                <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="pipeline-dropdown" xplacement="bottom-end">
                    {
                        sortOrders && sortOrders.length > 0 ?
                            sortOrders.map((rec, index) => {
                                return <Dropdown.Item key={index} className="current-pipeline" onClick={(e) => {
                                    e.preventDefault();
                                    props.handleSortNamechange(rec.value)
                                }}>
                                    {rec.value === props.sortName ? (<div className="pipeline-check">  <i className="ico icon-check"> </i></div>) : (<></>)}
                                    {rec.label}</Dropdown.Item>
                            })
                            : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown> */}

            {/* FILTER SEARCH KEYWORDS*/}
            <div className={`filter-search ${localStorage.defaultModule !== 'oem' && mergeContact ? 'ml-2' : ''}`}>
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="quick search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value
                            setSearchText(_val);
                            localStorage.campaignContactSearchText = _val;

                            if (_val && _val.length > 2) {
                                if (!props.bulksms) {
                                    props.handlesearchText(_val)
                                }
                            }

                            if (!_val)
                                props.handlesearchText('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                localStorage.campaignContactSearchText = searchText;
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.campaignContactSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                localStorage.campaignContactSearchText = searchText;
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>

            </div>

            {/* <div className="mycontact-btn ml-2">

                <span className="switch switch-sm">

                    <InputCheckBox
                        className={`switch`}
                        id="myContactsOnly"
                        name="myContactsOnly"
                        checked={myContactsOnly ? myContactsOnly : false}
                        onChange={(e) => {

                            let _chked = e.target.checked;
                            setMyContactsOnly(_chked);
                            localStorage.setItem('myContactsOnly', _chked)
                            props.handleContactsOnly(_chked)

                        }} />
                    <label htmlFor="myContactsOnly"><Translate text={'myContacts'} /></label>
                </span>

            </div> */}
            {/* <div className="filter-search ml-2">
                <div className="checkbox icheck-success ml-2">
                    <input
                        type="checkbox"
                        id="myContactsOnly"
                        name="myContactsOnly"
                        className="uncheck-activity"
                        checked={myContactsOnly ? myContactsOnly : false}
                        onChange={(e) => {

                            let _chked = e.target.checked;
                            setMyContactsOnly(_chked);
                            localStorage.setItem('myContactsOnly', _chked)
                            props.handleContactsOnly(_chked)

                        }} />
                    <label htmlFor="myContactsOnly"><Translate text={'myContacts'} /></label>
                </div>
            </div> */}


            {/* <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchKeywords ? searchKeywords : ''}
                        onChange={(e) => {
                            handleKeywordsOnChange(e);
                            if (!e.target.value)
                                handleSearchKeywords('')
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchKeywords(searchKeywords)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchKeywords && searchKeywords.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchKeywords('');
                                        handleSearchKeywords('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleSearchKeywords(searchKeywords)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
 */}


            <div className="float-right">

                {!props.isUsedInAudience && (
                    <>
                        <div className="filter-item-btn">

                            <NavLink to="/contacts/companies" exact >
                                <i className="ico icon-company"></i> <Translate text={'View in Companies'} />
                            </NavLink>
                        </div>
                        {
                            (exportContactData && props.contactCount > 0)
                                ?
                                <>                                    
                                    <div className="filter-item-btn ml-2">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                                        }} title={'Excel Export'}>
                                            <i className="ico icon-excel-download"></i>
                                        </a>
                                        <ExcelFile
                                        ref={csvBtn}
                                        element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                        filename={`contacts_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                        <ExcelSheet dataSet={CommonHelper.generateExcelData(props.datalist, props.csvHeader, props)} name={`Sheet1`} />
                                    </ExcelFile>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        }
                        {
                            (localStorage.defaultModule !== 'oem' && transferContact)
                                ?
                                <>
                                    <Dropdown className='filter-view-more  ml-2'>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                            className='common-button'
                                        >
                                            <i className='ico icon-more'></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            as={CustomMenu}
                                            ChildClass="more-dropdown"
                                            xplacement="bottom-end"
                                        >
                                            {
                                                transferContact ? (
                                                    <Dropdown.Item eventKey="1" onClick={(e) => { props.handleTransfer(e) }}><i className="ico icon-email"></i>  <Translate text={'transfer'} /></Dropdown.Item>
                                                ) : (<></>)
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                                :
                                <>
                                </>
                        }
                    </>
                )}

                {/* <div className="filter-add-button ml-2">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleAddContact();
                        }}
                    > <i className="ico icon-add"></i> <Translate text={'addContact'} /></button>
                </div> */}





            </div>

            <Filters
                searchKeywords={searchKeywords}
                contactFilter={contactFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
            >
            </Filters>


            {
                ((!_.isEmpty(props.contactCount) || props.contactCount > 0)) ?
                    (<>
                        <div className="list-count-btn mr-3" onClick={(e) => {
                            e.preventDefault();
                            // console.log('dealersettings', props.dealersettings)
                        }}>
                            <span>  {props.contactCount > 1 ? ' Contacts' : ' Contact'}</span>
                            <span className="list-count"> {props.contactCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }


        </div >
    )
}


export default FilterPanel
