/** LIBRARIES */
import React, { PureComponent, Component } from "react";
import NumberFormat from 'react-number-format';
import {
    AntMonthPicker,
    ReactSelect,
    InputText,
    InputAutoText,
    InputAutoNumber,
    AntDatePicker,
    ReactMultiSelect,
    InputCheckBox
} from './index';
import _ from 'lodash'
import moment from 'moment';

const DynamicInput = React.memo(({ index, errors, id, value, inputAutoFormat, currencySymbol, data,
    handleCheckChange, handleOnChange, saveEnquiryField, cancelEnquiryField, onCurrencyChange, handleDateChange,
    handleSelectSave, handleMultiSelectChange, handleMonthChange, AddUnAvailable
}) => {


    const { name, type, subList } = data;
    return (
        <>

            {(() => {
                if (type === 'toggle' || type === 'switch') {
                    return (
                        <>
                            <div className={`form-control ${errors[id]}`}>
                                <span className="switch switch-sm float-right">
                                    <InputCheckBox
                                        className="switch"
                                        id={index + '_' + id}
                                        name={id}
                                        checked={_.isBoolean(value) ? value : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={index + '_' + id}></label>
                                </span>
                            </div>
                        </>
                    )
                }
                else if (type === 'text') {
                    return (
                        <>
                            {
                                (inputAutoFormat)
                                    ?
                                    <>
                                        <InputAutoText
                                            type="text"
                                            autoComplete="off"
                                            placeholder={name}
                                            className={`form-control cursor-pointer ${errors[id]}`}
                                            name={id}
                                            onChange={handleOnChange}
                                            value={(!_.isObject(value) && value) ? value : ''}
                                            onSaveField={saveEnquiryField}
                                            onCancelField={cancelEnquiryField}
                                        />
                                    </>
                                    :
                                    <>
                                        <InputText
                                            name={id}
                                            value={(!_.isObject(value) && value) ? value : ''}
                                            onChange={handleOnChange}
                                            autoComplete="off"
                                            placeholder={name}
                                            className={`form-control ${errors[id]}`}
                                        />
                                    </>
                            }


                        </>
                    )
                }
                else if (type === 'number') {
                    return (
                        <>
                            {
                                (inputAutoFormat)
                                    ?
                                    <>
                                        <InputAutoNumber
                                            id={id}
                                            name={id}
                                            allowNegative={false}
                                            thousandSeparator={false}
                                            placeholder={name}
                                            className={`form-control cursor-pointer ${errors[id]}`}
                                            onValueChange={onCurrencyChange.bind(this, id)}
                                            value={(_.isNumber(value)) ? value : ''}
                                            onSaveField={saveEnquiryField}
                                            onCancelField={cancelEnquiryField}
                                        />
                                    </>
                                    :
                                    <>
                                        <NumberFormat
                                            id={id}
                                            name={id}
                                            allowNegative={false}
                                            thousandSeparator={false}
                                            placeholder={name}
                                            className={`form-control ${errors[id]}`}
                                            onValueChange={onCurrencyChange.bind(this, id)}
                                            value={(_.isNumber(value)) ? value : ''}
                                        />
                                    </>
                            }

                        </>
                    )
                }
                else if (type === 'price') {
                    return (
                        <>
                            {
                                (inputAutoFormat)
                                    ?
                                    <>
                                        <InputAutoNumber
                                            id={id}
                                            name={id}
                                            allowNegative={true}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            placeholder={currencySymbol}
                                            className={`form-control cursor-pointer ${errors[id]}`}
                                            onValueChange={onCurrencyChange.bind(this, id)}
                                            value={value ? value : null}
                                            onSaveField={saveEnquiryField}
                                            onCancelField={cancelEnquiryField}
                                        />
                                    </>
                                    :
                                    <>
                                        <NumberFormat
                                            id={id}
                                            name={id}
                                            allowNegative={true}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={currencySymbol}
                                            placeholder={currencySymbol}
                                            className={`form-control ${errors[id]}`}
                                            onValueChange={onCurrencyChange.bind(this, id)}
                                            value={value ? value : null}
                                        />
                                    </>
                            }

                        </>
                    )
                }
                else if (type === 'date') {
                    return (
                        <>
                            <AntDatePicker
                                value={!_.isEmpty(value) && _.isObject(value) ? moment.unix(value.seconds).format('YYYY-MM-DD') : null}
                                name={id}
                                onChange={(e) => { handleDateChange(e, id) }}
                                format='DD/MM/YYYY'
                                placeholder='DD/MM/YYYY'
                                className={`form-control ${errors[id]}`}
                                disableContainer={true}
                            />
                        </>
                    )
                }
                else if (type === 'monthYear' && handleMonthChange) {
                    return (
                        <>
                            <AntMonthPicker
                                value={_.isString(value) ? moment(value)._d : null}
                                name={id}
                                onChange={(e) => { handleMonthChange(e, id) }}
                                placeholder={name}
                                className={`form-control cursor-pointer ${errors[id]}`}
                                disabledDate={(current) => { return current && current < moment().startOf('month'); }}
                                format={'MMM YYYY'}
                                picker={'month'}
                            />
                        </>
                    )
                }
                else if (type === 'select') {
                    return (
                        <>
                            <ReactSelect
                                options={
                                    !_.isEmpty(subList) ?
                                        _.map(subList, function (e) { return { label: e, value: e } })
                                        : []
                                }
                                name={id}
                                placeholder={name}
                                onChange={handleSelectSave}
                                value={value ? value : ''}
                                classNamePrefix={`cursor-pointer ${errors[id]} basic-select`}
                                //removeClearable={true}
                                isSearchable={false}
                                isDisabled={false}
                                AddUnAvailable={AddUnAvailable|| false}
                            ></ReactSelect>
                        </>
                    )
                }
                else if (type === 'multiselect') {
                    return (
                        <>
                            <ReactMultiSelect
                                options={
                                    !_.isEmpty(subList) ?
                                        _.map(subList, function (e) { return { label: e, value: e } })
                                        : []
                                }
                                name={id}
                                placeholder={name}
                                onChange={handleMultiSelectChange}
                                value={_.isObject(value) ? value : []}
                                classNamePrefix={`${errors[id]} basic-select`}
                                isMulti={true}
                                isSearchable={true}
                                AddUnAvailable={AddUnAvailable|| false}
                            >
                            </ReactMultiSelect>
                        </>
                    )
                }
            })()}

        </>

    );

})

export default DynamicInput;