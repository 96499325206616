/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import {Modal} from "react-bootstrap";
import _ from 'lodash'
/** COMPONENTS */
import {
    ReactSelect
} from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
/** VIEW-MODELS */


const TransferOwner = (props) => {
    const [owner, setOwner] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [clientUsers, setClientUsers] = useState([])

    useEffect(() => {
        const { dealersettings, clientUsers } = props;
        setOwner(dealersettings ? dealersettings.id : '');

        if (!_.isEmpty(clientUsers)) {
            const _clientUsers = [];
            if (clientUsers) {
                clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit"/>
                            {doc.name}
                        </div>
                    });
                });
            }

            setClientUsers(_clientUsers);
        }

    }, [props.clientUsers])

    const handleSelectChange = (e) => {
        const { dealersettings } = props;
        if (e) {
            setOwner(e.value);
        }
        else {
            setOwner(dealersettings ? dealersettings.id : '');
        }
    }

    const handleTransfer = async () => {
        setLoading(true);
        props.handleClose(owner);
    }
    const { show, title } = props;
    return (
        <>

            <Modal
                show={show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-regno">
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="search-pop form-style">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'user'} /></label>
                                        <ReactSelect
                                            options={clientUsers}
                                            name={"owner"}
                                            placeholder={'select ownership'}
                                            onChange={handleSelectChange}
                                            value={owner ? owner : null}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {/* <button type="button" className="btn btn-default float-left" onClick={props.handleClose}>
                        <Translate text={'cancel'} />
                    </button> */}
                    <button type="button" className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`} onClick={(e) => handleTransfer(e)} >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'transfer'} />
                    </button>
                </Modal.Footer>
            </Modal>






        </>
    );

}
export default TransferOwner;