/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import moment from 'moment';
/** COMPONENTS */
import {
    AntDatePicker, InputText, InputTextArea,
    ReactSelect, DynamicInput,
    AntMonthPicker, InputCheckBox
} from '../../components';
import Translate from '../../constants/translate';
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import { default as _images } from '../../images';
/** VIEW-MODELS */
import { tipStatus } from '../../services/enum';
import CommonHelper from '../../services/common';
import { objTradeIP } from './viewModel'
import { objActivitylog } from '../pipeline/viewModel'
import { firestoreDB } from '../../services/helper';

let enquiryOptionsDF = [];

export default class EnquiryFields extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.ref = window.firebase.firestore().collection('tradeinPro');
        this.state = {
            fields: Object.assign({}, objTradeIP),
            mandatoryFields: {},
            errors: {},
            origins: [],
            lostReasons: [],
            lostSubReasons: [],
            newStatus: null,
            mentions: [],
            teamMentions: null,
            allUsers: [],
        }
        this.mentionsData = props.clientUsers.map(clientUser => {
            return {
                name: `@${clientUser.name}`,
                id: clientUser.id,
                avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
            };
        });
        props.dealersettings.client.teams.forEach(team => {
            this.mentionsData.push({
                name: `@${team.name}`,
                id: team.user_ids.join(','),
                avatar: _images.group
            })
        })
    }

    //#region MentionEditor
    onMentionChange = notesText => {
        const e = {
            target: {
                name: 'notes',
                value: notesText,
            },
        };

        // this.handleOnAreaChange(e, true);
        const fields = Object.assign({}, this.state.fields);
        fields.lostNotes = notesText
        this.setState({ fields: fields });

    };

    onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }
    //#endregion

    //#region PAGELOAD
    loadTradeinPro = () => {

        let { tradeinPro, dealersettings, errors, clientUsers } = this.props;
        let state = Object.assign({}, tradeinPro);


        this.setState({
            newStatus: this.props.newStatus,
            mandatoryFields: Object.assign({}, this.props.errors),
            fields: state
        });

        if (dealersettings && dealersettings.client && dealersettings.client.tradeinPro) {
            const origins = [];
            const lostReasons = [];
            const lostSubReasons = [];

            const setttings = dealersettings.client.tradeinPro;



            enquiryOptionsDF = [];
            if (setttings.enquiryOptionsDF)
                enquiryOptionsDF = setttings.enquiryOptionsDF;


            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (state.lostReason && !_.isEmpty(setttings.lostReasons) && !_.isEmpty(setttings.lostReasons.filter(e => e.value === state.lostReason)[0])) {
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === state.lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === state.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            let options = [];
            if (!_.isEmpty(dealersettings.client.roles) && state.clientID) {

                let roleIds = [..._.map(dealersettings.client.roles.filter(e => state.pipeline && e.tradeinProPipeline && e.tradeinProPipeline.some(m => m === state.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(clientUsers, (v) => _.includes(roleIds, v.roleID));

                if (state.pipeline) {
                    _selectedUsers && _selectedUsers.map(rec => {
                        return options.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })
                }
                else {
                    clientUsers.forEach(rec => {
                        return options.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })
                }
            }

            this.setState({
                origins: origins,
                lostReasons: lostReasons,
                lostSubReasons: lostSubReasons,
                allUsers: options,
            });
        }

        //console.log('tradeinPro fields', this.props.newStatus, Object.assign({}, this.props.errors))
    }

    componentDidMount() {
        this.loadTradeinPro();
    }


    //#endregion


    //#region ON CHANGE EVENT
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let mandatoryFields = Object.assign({}, this.state.mandatoryFields);
        let errorClass = 'input_error';
        if (_.isObject(errors) && !_.isEmpty(errors)) {
            if (mandatoryFields[key] && ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key]))) {
                if (fields.hasOwnProperty(key)) {
                    errors[key] = errorClass;
                }
                else {
                    if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                        (_.isEmpty(fields.dynamicFields) ||
                            (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                        )) {
                        errors[key] = errorClass;
                    }
                    // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
                    //     (_.isEmpty(fields.options) || (!_.isEmpty(fields.options) && fields.options.indexOf(key) < 0))) {
                    //     errors[key] = errorClass;
                    // }
                    else {
                        errors[key] = '';
                    }
                }
            }
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = value;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleDateChange = (value, name) => {
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = moment(value).format('YYYY-MM-DD');
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856} 
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleOptionsChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _reqOtherInfo = state.options ? state.options : [];
        if (checked)
            _reqOtherInfo.push(name)
        else
            _reqOtherInfo = _reqOtherInfo.filter(m => m !== name);

        state['options'] = _reqOtherInfo;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    handlePIDateSave = (val, name) => {
        if (val)
            val = moment(val).format('MMM YYYY');

        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    handleSelectSave = (e, data) => {
        //this.saveEnquiryField(data.name, e.value);
        let state = Object.assign({}, this.state.fields);
        state[data.name] = e.value;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleLostReasonSave = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        const { lostReasons } = this.state;
        const lostSubReasons = [];
        if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
            lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                lostSubReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        }

        state[data.name] = e.value;

        if (state.lostSubReason)
            state['lostSubReason'] = null;

        this.setState({ fields: state, lostSubReasons: lostSubReasons }, () => { this.errorChange(data.name); });

    };

    isPIValid = (date) => {
        let _date = moment(date, "MMM YYYY")
        if (moment().isBefore(_date) || moment().isSame(_date, 'month'))
            return ''
        else
            return 'form-alert '
    }
    //#endregion


    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = e.value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(_data.name); });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion


    //#region SUBMIT

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let mandatoryFields = Object.assign({}, this.state.mandatoryFields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(mandatoryFields)) {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !fields[key])) {
                if (fields.hasOwnProperty(key)) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
                else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
                    (_.isEmpty(fields.dynamicFields) ||
                        (!_.isEmpty(fields.dynamicFields) && _.isEmpty(fields.dynamicFields[key]) && !_.isBoolean(fields.dynamicFields[key]) && !_.isNumber(fields.dynamicFields[key]))
                    )) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
                // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
                //     (_.isEmpty(fields.options) || (!_.isEmpty(fields.options) && fields.options.indexOf(key) < 0))) {
                //     formIsValid = false;
                //     errors[key] = errorClass;
                // }
            }
        }
        console.log('errors', errors)
        this.setState({ errors: errors });

        if (formIsValid === true) {

            this.handleSave();


        }
        else
            return formIsValid;

    };

    handleSave = () => {
        this.isLoading = true;
        const { tradeinProID, dealersettings, newStatus, selectedOffer } = this.props;
        const { mentions, mandatoryFields } = this.state;
        const objData = Object.assign({}, this.state.fields);

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        for (var propName in objData) {
            if (
                objData[propName] === null ||
                objData[propName] === undefined ||
                objData[propName] === '' ||
                (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
            ) {
                delete objData[propName];
            }
        }

        if (mandatoryFields && !_.isEmpty(mandatoryFields["finalPrice"]) && !_.isEmpty(selectedOffer) && objData.finalPrice) {
            objData.finalizedBy = dealersettings ? dealersettings.id : '';
            objData.finalizedDate = window.firebase.firestore.Timestamp.now();
            objData.finalizedFrom = 'web';
        }

        let notes = null;
        if (objData.lostNotes) {
            notes = objData.lostNotes;
            //objData.lostNotes = notes;
        }

        //   console.log('handleSave', tradeinProID, objData, newStatus)
        const updateRef = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(tradeinProID);
        updateRef
            .set(objData, { merge: true })
            .then(docRef => {
                this.isLoading = false;

                if (notes) this.saveCustomerlog(notes, mentions);

                if (mandatoryFields && !_.isEmpty(mandatoryFields["valuerPrice"]) && !_.isEmpty(selectedOffer) && objData.valuerPrice) {
                    const currencySymbol = ((!_.isEmpty(dealersettings) &&
                        !_.isEmpty(dealersettings.client) &&
                        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
                    let _objLogData = {
                        tradeinProID: tradeinProID,
                        offerID: selectedOffer.documentID,
                        projectId: objData?.projectId ? objData?.projectId : null,
                        message: `The evaluation has been approved, setting the valuer price at ${CommonHelper.formatCurrency(currencySymbol, objData.valuerPrice)}.`
                    }
                    CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
                }

                // if (mandatoryFields && !_.isEmpty(mandatoryFields["finalPrice"]) && !_.isEmpty(selectedOffer) && objData.finalPrice) {
                //     const currencySymbol = ((!_.isEmpty(dealersettings) &&
                //         !_.isEmpty(dealersettings.client) &&
                //         !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
                //     let _objLogData = {
                //         tradeinProID: tradeinProID,
                //         offerID: selectedOffer.documentID,
                //         projectId: objData?.projectId ? objData?.projectId : null,
                //         message: `The evaluation has been approved, setting the final price at ${CommonHelper.formatCurrency(currencySymbol, objData.finalPrice)}.`
                //     }
                //     CommonHelper.saveTradeInProLog(dealersettings, _objLogData);
                // }

                this.props.handleClose(newStatus ? newStatus : null)
            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });
    }

    saveCustomerlog = (logNotes, mentions) => {
        // debugger;
        let newlogVM = Object.assign({}, objActivitylog);
        const { dealersettings, clientUsers, groupUsers, tradeinProID, tradeinPro, contact } = this.props;

        let _contactID = tradeinPro.contactID ? tradeinPro.contactID : (!_.isEmpty(tradeinPro.contact) ? tradeinPro.contact.documentID : (!_.isEmpty(contact) ? contact.documentID : null));
        let clientID = tradeinPro.clientID ? tradeinPro.clientID : (dealersettings ? dealersettings.client.id : '');

        newlogVM.type = 'tradeinProlog';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.tradeinProID = tradeinProID ? tradeinProID : this.state.tradeinProID;
        newlogVM.contactID = _contactID ? _contactID : null;

        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
        newlogVM.modifiedByName = newlogVM.addedByName;
        newlogVM.notes = logNotes ? logNotes : '';
        newlogVM.tags = ['lost notes']
        newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, clientID);
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;


        if (!_.isEmpty(mentions)) {
            if (mentions.length > 0) {
                let convertedIDs = []
                let rawIDs = mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })

                if (newlogVM.mentions && newlogVM.mentions.length > 0) {
                    newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
                } else {
                    newlogVM.mentions = _.uniq(convertedIDs)
                }
            }
        }

        firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });
    };


    handleCancel = (e) => {
        e.preventDefault();
        this.loadTradeinPro();
    }

    //#endregion


    render() {
        const { status, clientID, finalPrice, valuerPrice,
            lostReason, owner,
            lostSubReason, lostNotes,
            wonDate, lostDate, completedDate, dynamicFields
        } = this.state.fields;

        const { lostReasons, lostSubReasons, newStatus, mandatoryFields, allUsers, origins } = this.state;

        const { dealersettings, ismodifyStatusDate, selectedOffer } = this.props;

        const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);

        const _moduleSettings = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

        const readOnlyEnquiryView = false;//((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

        const canModifyTIP = true;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        let fieldsLength = !_.isEmpty(mandatoryFields) ? Object.keys(mandatoryFields).length : 0;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName={`${fieldsLength > 8 ? 'modal-dialog-scrollable ' : ''}modal-dialog-centered modal-add-regno`} >
                    <Modal.Header className="border-bottom" closeButton>
                        <Modal.Title><Translate text={this.props.title} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="search-pop form-style">

                                    <div className="form-row ">

                                        {
                                            (!_.isEmpty(mandatoryFields["valuerPrice"]))
                                                ?
                                                <>
                                                    {
                                                        !_.isEmpty(selectedOffer) && selectedOffer.evaluationPrice ?
                                                            <div className="form-group col-md-12">
                                                                <div className='vehicle-status-alert alert-user'>
                                                                    {/* {`Offer selected with ${selectedOffer.isSelfEvaluation ? 'evaluation' : 'wholesaler'} price of ${CommonHelper.formatCurrency(currencySymbol, selectedOffer.dealerPrice)}`} */}
                                                                    {`The evaluation price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, selectedOffer.evaluationPrice)}`}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'valuerPrice'} /></label>
                                                        <NumberFormat
                                                            id={'valuerPrice'}
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control ${this.state.errors["valuerPrice"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'valuerPrice')}
                                                            value={valuerPrice ? valuerPrice : (status === tipStatus.WON && selectedOffer?.evaluationPrice ? selectedOffer?.evaluationPrice : null)}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["finalPrice"]))
                                                ?
                                                <>
                                                    {
                                                        !_.isEmpty(selectedOffer) && valuerPrice ?
                                                            <div className="form-group col-md-12">
                                                                <div className='vehicle-status-alert alert-user'>
                                                                    {/* {`Offer selected with ${selectedOffer.isSelfEvaluation ? 'evaluation' : 'wholesaler'} price of ${CommonHelper.formatCurrency(currencySymbol, selectedOffer.dealerPrice)}`} */}
                                                                    {`The valuer price of the selected offer is ${CommonHelper.formatCurrency(currencySymbol, valuerPrice)}`}
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'finalPrice'} /></label>
                                                        <NumberFormat
                                                            id={'finalPrice'}
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            allowNegative={true}
                                                            prefix={currencySymbol}
                                                            placeholder={currencySymbol}
                                                            className={`form-control ${this.state.errors["finalPrice"]}`}
                                                            onValueChange={this.onCurrencyChange.bind(this, 'finalPrice')}
                                                            value={finalPrice ? finalPrice : null}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (!_.isEmpty(mandatoryFields["owner"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'owner'} /></label>
                                                        <ReactSelect
                                                            options={allUsers}
                                                            name={'owner'}
                                                            placeholder={'select owner'}
                                                            onChange={this.handleSelectSave}
                                                            value={owner}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['owner']} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={(canModifyTIP) ? false : true}
                                                        ></ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["origin"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'origin'} /></label>
                                                        <ReactSelect
                                                            options={origins}
                                                            name={"origin"}
                                                            placeholder={'select origin'}
                                                            onChange={this.handleSelectSave}
                                                            value={origin}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors["origin"]} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={canModifyTIP ? false : true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(mandatoryFields["lostReason"]))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'lostReason'} /></label>
                                                        <ReactSelect
                                                            options={lostReasons}
                                                            name={'lostReason'}
                                                            placeholder={'select lost reason'}
                                                            onChange={this.handleLostReasonSave}
                                                            value={lostReason}
                                                            classNamePrefix={`cursor-pointer ${this.state.errors['lostReason']} basic-select`}
                                                            removeClearable={true}
                                                            isDisabled={(canModifyTIP) ? false : true}
                                                        ></ReactSelect>
                                                    </div>
                                                    {
                                                        !_.isEmpty(lostSubReasons)
                                                            ?
                                                            <>
                                                                <div className="form-group col-md-12">
                                                                    <label><Translate text={'lostSubReason'} /></label>
                                                                    <ReactSelect
                                                                        options={lostSubReasons}
                                                                        name={'lostSubReason'}
                                                                        placeholder={'select lost sub reason'}
                                                                        onChange={this.handleSelectSave}
                                                                        value={lostSubReason}
                                                                        classNamePrefix={`cursor-pointer ${this.state.errors['lostSubReason']} basic-select`}
                                                                        removeClearable={true}
                                                                        isDisabled={(canModifyTIP) ? false : true}
                                                                    ></ReactSelect>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }





                                        {
                                            (ismodifyStatusDate && (status && status === tipStatus.WON) && !_.isEmpty(wonDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'wonDate'} /></label>
                                                        <AntDatePicker
                                                            value={wonDate ? moment.unix(wonDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'wonDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'wonDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["wonDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (ismodifyStatusDate && (status && status === tipStatus.COMPLETED || status === tipStatus.WON || status === tipStatus.LOST) && !_.isEmpty(completedDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'completedDate'} /></label>
                                                        <AntDatePicker
                                                            value={completedDate ? moment.unix(completedDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'completedDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'completedDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["completedDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                        {
                                            (ismodifyStatusDate && (status && (status === tipStatus.LOST)) && !_.isEmpty(lostDate))
                                                ?
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'lostDate'} /></label>
                                                        <AntDatePicker
                                                            value={lostDate ? moment.unix(lostDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'lostDate'}
                                                            onChange={(e) => { this.handleDeliveryDateChange(e, 'lostDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["lostDate"]}`}
                                                            allowClear={false}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </div>

                                    {!_.isEmpty(enquiryOptionsDF) && enquiryOptionsDF.filter(m => m.active === true).map((info, index) => (
                                        <div className="form-row " key={info.value}>
                                            {
                                                (!_.isEmpty(mandatoryFields[info.value]))
                                                    ?
                                                    <>
                                                        <div key={info.value} className="form-group col-md-12">
                                                            <label>{info.name}</label>
                                                            <DynamicInput
                                                                index={index}
                                                                data={info}
                                                                errors={this.state.errors ? this.state.errors : {}}
                                                                id={info.value}
                                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                                handleCheckChange={this.handleDynamicCheckChange}
                                                                handleOnChange={this.handleDynamicOnChange}
                                                                saveEnquiryField={this.saveDynamicField}
                                                                cancelEnquiryField={this.cancelDynamicField}
                                                                onCurrencyChange={this.onDynamicNumberChange}
                                                                handleDateChange={this.handleDynamicDateChange}
                                                                handleSelectSave={this.handleDynamicSelectSave}
                                                                handleMultiSelectChange={this.handleDynamicMultiSelectChange}
                                                                currencySymbol={currencySymbol}
                                                                inputAutoFormat={false}
                                                            >
                                                            </DynamicInput>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    ))}


                                    {
                                        (!_.isEmpty(mandatoryFields["lostNotes"]))
                                            ?
                                            <>
                                                <div className="form-row ">
                                                    <div className="form-group col-md-12">
                                                        <label><Translate text={'lostNotes'} /></label>
                                                        <MentionEditor
                                                            mentionsData={this.mentionsData}
                                                            onMentionChange={this.onMentionChange}
                                                            onMentionSelect={this.onMentionSelect}
                                                            notes={lostNotes ? lostNotes : ''}
                                                            focusOnMount={false}
                                                            isNoteEmpty={this.state.errors['lostNotes'] ? true : false}
                                                            teamMentions={this.state.teamMentions}
                                                            clearTeamMentions={this.clearTeamMentions}
                                                            clientUsers={this.props.clientUsers}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}
