import React, { Component } from 'react';
import { EditorState, ContentState, Modifier } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {
	defaultSuggestionsFilter,
} from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import createMentionEntities from './mentions';

import editorStyles from './editorStyles.module.css';
import _ from 'lodash';
import CommonHelper from '../../services/common';

// import './editorStyles.css';
const customSuggestionsFilter = (searchValue, suggestions) => {
	const size = list =>
		list.constructor.name === 'List' ? list.size : list.length;

	const get = (obj, attr) => (obj.get ? obj.get(attr) : obj[attr]);

	const value = searchValue.toLowerCase();
	const filteredSuggestions = suggestions.filter(
		suggestion =>
			!value || get(suggestion, 'name').toLowerCase().indexOf(value) > -1,
	);
	const length =
		size(filteredSuggestions) < 24 ? size(filteredSuggestions) : 24;
	return filteredSuggestions.slice(0, length);
};

export default class SimpleMentionEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mentionPlugin: createMentionPlugin(),
			editorState: EditorState.createWithContent(
				createMentionEntities(this.props.notes, this.props.mentionsData),
			),
			suggestions: this.props.mentionsData,
			firstUpdated: false
		};
	}

	onChange = editorState => {
		this.setState({
			editorState,
		});
		let text = editorState.getCurrentContent().getPlainText();
		text = text.charAt(0).toUpperCase() + text.slice(1);
		this.props.onMentionChange(text);
	};

	onSearchChange = ({ value }) => {
		if (this.props.showCustomSuggestions) {
			this.setState({
				suggestions: customSuggestionsFilter(value, this.props.mentionsData),
			});
		} else {
			this.setState({
				suggestions: defaultSuggestionsFilter(value, this.props.mentionsData),
			});
		}
	};

	onAddMention = e => {
		this.props.onMentionSelect(e, this.props.ownerID);
	};

	focus = () => {
		this.editor.focus();
	};

	renderContentAsRawJs() {
		const contentState = this.state.editorState
			.getCurrentContent()
			.getPlainText();

		return JSON.stringify(contentState, null, 2);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notes !== this.props.notes && this.props.notes === '') {
			this.setState({
				editorState: EditorState.moveFocusToEnd(this.state.editorState)
			}, () => {
				this.editor.focus();
			})
		}
		if (this.state.firstUpdated === false && this.props.shouldFocusOnMount && this.props.shouldFocusOnMount) {
			this.editor.focus();
			this.setState({
				firstUpdated: true
			})
		}
		if (this.props.smsTemplateData && !_.isEmpty(this.props.smsTemplateData) && this.props.smsTemplateData.active && this.props.smsTemplateData.textHTML) {
			this.setState({
				editorState: EditorState.push(this.state.editorState,
					ContentState.createFromText(this.props.smsTemplateData.textHTML))
				// EditorState.createWithContent(
				// createMentionEntities(this.props.smsTemplateData.textHTML, this.props.mentionsData),

				// mentionPlugin: createMentionPlugin()
			}, () => {
				this.props.clearSmsTemplateData()
				let text = this.state.editorState.getCurrentContent().getPlainText();
				text = text.charAt(0).toUpperCase() + text.slice(1);
				this.props.onMentionChange(text);
			})
		}
		if ((!_.isEmpty(localStorage.teamMentions) || !_.isEmpty(this.props.teamMentions)) && this.props.clearTeamMentions) {
			// test
			let ids = []
			if (_.isEmpty(localStorage.teamMentions) && _.isEmpty(this.props.teamMentions)) {
				return
			}
			if (!_.isEmpty(this.props.teamMentions)) {
				ids = this.props.teamMentions.split(',')
			}
			if (!_.isEmpty(localStorage.teamMentions) && localStorage.teamMentions) {
				ids = localStorage.teamMentions.split(',')
			}
			// console.log('dataasafsdas asdas ids', ids)
			if (!ids.length > 0) {
				return
			}

			const stateWithEntity = this.state.editorState.getCurrentContent().createEntity(
				'mention',
				'IMMUTABLE',
				{
					mention: { id: ids[0], name: `@${CommonHelper.getUserNamebyId(this.props.clientUsers, ids[0])}` },
				},
			)
			const entityKey = stateWithEntity.getLastCreatedEntityKey()
			const stateWithText = Modifier.insertText(stateWithEntity, this.state.editorState.getSelection(), `@${CommonHelper.getUserNamebyId(this.props.clientUsers, ids[0])}`, null, entityKey)

			ids.shift()
			localStorage.teamMentions = ids
			this.setState({
				editorState:
					EditorState.push(this.state.editorState, stateWithText)
			}, () => {
				let text = this.state.editorState.getCurrentContent().getPlainText();
				text = text.charAt(0).toUpperCase() + text.slice(1);
				this.props.onMentionChange(text);
			})
			this.props.clearTeamMentions()


			// test end
			/** 
			console.log('hitted addTeamMentions')
			let ids = this.props.teamMentions.split(',')
			console.log('ids', ids)
			let data = null
			ids.forEach(__id => {
							let name  = `@${CommonHelper.getUserNamebyId(this.props.clientUsers, __id)}`
							name = name.replaceAll(' ', '&nbsp;')
							if (data) {
											data = `${data}&nbsp;<span style="background: red;" className="draftJsMentionPlugin__mention__"><span data-text="true">${name}</span></span>`
							} else {
											data = `&nbsp;<span style="background: red;" className="draftJsMentionPlugin__mention__"><span data-text="true">${name}</span></span>`
							}
			})
			
			let { contentBlocks, entityMap } = htmlToDraft(data);
			let contentState = Modifier.replaceWithFragment(
							this.state.editorState.getCurrentContent(),
							this.state.editorState.getSelection(),
							ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
			)
								
			this.setState({
							editorState:
							EditorState.push(this.state.editorState, contentState, 'insert-fragment')
			}, () => {
							let text = this.state.editorState.getCurrentContent().getPlainText();
							text = text.charAt(0).toUpperCase() + text.slice(1);
							this.props.onMentionChange(text);
			})
			this.props.clearTeamMentions()
			*/

		}
	}

	render() {
		const { MentionSuggestions } = this.state.mentionPlugin;
		const plugins = [this.state.mentionPlugin];

		return (
			<div onClick={this.focus} id='simple-mention-editor' style={this.props.height ? { height: `${this.props.height}` } : {}}>
				<div
					style={this.props.height ? { height: `${this.props.height}` } : {}}
					className={`${this.props.showCustomSuggestions ? editorStyles.editorNoBG : editorStyles.editor} ${this.props.isNoteEmpty ? 'input_error' : ''}`}
				// className='mention-editor'
				>
					<Editor
						editorState={this.state.editorState}
						placeholder='Write Something...'
						onChange={this.onChange}
						plugins={plugins}
						ref={element => {
							this.editor = element;
						}}
					/>
					{this.props.showCustomSuggestions ? (
						<MentionSuggestions
							onSearchChange={this.onSearchChange}
							suggestions={this.state.suggestions}
							onAddMention={this.onAddMention}
							entryComponent={Entry}
						/>
					) : (
						<MentionSuggestions
							onSearchChange={this.onSearchChange}
							suggestions={this.state.suggestions}
							onAddMention={this.onAddMention}
						/>
					)}
				</div>
			</div>
		);
	}
}


function Entry(props) {
	const {
		mention,
		theme,
		searchValue,
		isFocused,
		...parentProps
	} = props;

	return (
		<div {...parentProps}>
			<div className={theme?.mentionSuggestionsEntryContainer}>
				{/* <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.avatar}
            className={theme?.mentionSuggestionsEntryAvatar}
            role="presentation"
          />
        </div> */}

				<div className={theme?.mentionSuggestionsEntryContainerRight}>
					{/* <div className={theme?.mentionSuggestionsEntryText}>
            {mention.name}
          </div> */}

					<div className={theme?.mentionSuggestionsEntryTitle}>
						{mention.title}
					</div>
				</div>
			</div>
		</div>
	);
}
