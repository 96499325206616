/** LIBRARIES */
import React, { PureComponent } from "react";
import Select, { components, StylesConfig } from "react-select";
import _ from 'lodash';

class ReactMultiSelect extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    DropdownIndicator = props => {
        return props.hasValue ? <></> : <components.DropdownIndicator {...props} />;
    };

    render() {
        const multiStyles = {
            control: base => ({
                ...base,
                minHeight: "33px"
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            clearIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            multiValue: (base, state) => {
                return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
            },
            multiValueLabel: (base, state) => {
                return state.data.isFixed
                    ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                    : base;
            },
            multiValueRemove: (base, state) => {
                return state.data.isFixed ? { ...base, display: 'none' } : base;
            },
        };

        const multiComponent = {
            IndicatorSeparator: () => null,
            DropdownIndicator: this.DropdownIndicator
        };

        let options = [];
        !_.isEmpty(this.props.options) && this.props.options.map((group) => {
            let _data = { ...group }
            if (!_.isEmpty(group.options)) {
                if (_.isBoolean(group.options.map(el => el.active)[0])) {
                    _data.options = group.options.filter(e => e.active === true);
                }
            }
            if (_.isBoolean(_data.active) && _data.active)
                options.push(_data)
            else if (!_.isBoolean(_data.active))
                options.push(_data)
        })

        const value = [];
        if (this.props.isGroup) {
            !_.isEmpty(this.props.value) && this.props.value.forEach((data) => {
                !_.isEmpty(options) && options.map((group) => {
                    var indexVal = group.options.findIndex(x => x.value === data);
                    if (indexVal >= 0)
                        value.push(group.options[indexVal])
                })
            })
        }
        else {
            !_.isEmpty(this.props.value) && _.isArray(this.props.value) && this.props.value.forEach((data) => {
                var indexVal = options.findIndex(x => x.value === data);
                if (indexVal >= 0) {
                    value.push(options[indexVal])
                }
                else if (this.props.AddUnAvailable) {
                    value.push({ value: data, label: data })
                }
            })
        }


        const customFilter = (option, searchText) => {
            if (
                (typeof (option.data.value) === 'string' && option.data.value.toLowerCase().includes(searchText.toLowerCase())) ||
                (typeof (option.data.label) === 'string' && option.data.label.toLowerCase().includes(searchText.toLowerCase())) ||
                (typeof (option.data.searchlabel) === 'string' && option.data.searchlabel.toLowerCase().includes(searchText.toLowerCase()))
            ) {
                return true;
            } else {
                return false;
            }
        }


        //console.log('RENDER REACT MULTI', this.props.name, this.props.value, this.props.options)

        //console.log('RENDER REACT MULTI',this.props.name, this.props.options, options)

        return (
            <div id={this.props.name}>
                <Select
                    options={options}
                    placeholder={this.props.placeholder}
                    className={`basic-single`}
                    classNamePrefix={this.props.classNamePrefix}
                    isClearable
                    components={multiComponent}
                    styles={multiStyles}
                    name={this.props.name}
                    onChange={this.props.onChange}
                    defaultValue={value}
                    value={value}
                    isMulti={this.props.isMulti}
                    isSearchable={this.props.isSearchable ? true : false}
                    closeMenuOnSelect={this.props.isMulti ? false : true}
                    hideSelectedOptions={this.props.isMulti ? false : true}
                    filterOption={customFilter}
                    menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : 'auto'}
                    isDisabled={this.props.isDisabled ? this.props.isDisabled : false}
                />
            </div>
        );
    }
}


export default ReactMultiSelect;

