/** LIBRARIES */
import React, { Component, Suspense, } from 'react';
/** PROVIDERS */
import { LayoutProvider, LayoutConsumer } from './provider';
import { RouteProvider } from './RouteProvider';
/** COMPONENTS */
import Navbar from './navbar';
import MainContainer from './container';
import AdminPanel from '../../admin/layout';
import VideoLayout from '../videos/layout'
import QuoteLayout from '../quote/layout'
import TradeInLayout from '../tradeIn/layout'
import TradeInNewLayout from '../tradeIn/layout/indexCustomer'
import TestdriveLayout from '../testdrive/layout'
import ReportLayouts from '../report/activity/layout'
import ServiceLayout from '../service/layout'
import CustomerApprovalLayout from '../service/layout/customerLayout'
import EnquiryLayout from '../pipeline/layout'
import { isEmpty } from 'lodash';
import SignRequestLayout from '../contacts/layout';
import CustomerReviewLayout from '../customerReview/layout/index';
import FormLayout from '../forms/layout/index';
import StockQuoteLayout from '../stockQuote/layout'
import ServiceInvoiceLayout from '../serviceInvoice/layout'
import EventsLayout from '../eventsPro/layout'
import StockReportLayout from '../stock/layout';

class MainLayout extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Submitted: false,
            superAdmin: false,
            AMSVideo: false,
            TradeIn: false,
            TradeInNew: false,
            Testdrive: false,
            ActivityReport: false,
            Quote: false,
            user: null,
            Services: false,
            SignRequest: false,
            CustomerReview: false,
            FormReview: false,
            CustomerApproval: false,
            EnquiryPreview: false,
            EmailPreview: false,
            StockQuote: false,
            ServiceInvoice: false,
            Events: false,
            Stock: false,
        }
    }

    getUser = async (uid) => {
        const snap = await window.firebase.firestore().collection('users').doc(uid).get()
        this.setState({ user: snap.data() })
        // console.log('this is the user Data', snap.data())
    }

    setUser = (data) => {
        this.setState({ user: { ...this.state.user, ...data } })
    }

    componentDidMount() {
        this._isMounted = true;
        let pathname = window.location.pathname;
        window.firebase.auth().onAuthStateChanged(currentuser => {
            // if (window.location.host === 'ams-pro-qa.web.app') {
            //     window.location.href = 'https://qa.fusionamspro.com/';
            // }
            // else if (window.location.host === 'ams-pro-live.web.app') {
            //     window.location.href = 'https://app.fusionamspro.com/';
            // }
            if (currentuser) {
                // if (isEmpty(window.firebase2.auth().currentUser)) {
                //     window.firebase2.auth().signInAnonymously()
                //         .catch((error) => {
                //             console.log('error on secondary firestore auth Anonymously', error)
                //         });
                // }
                if (pathname.indexOf('video/details') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, AMSVideo: true });
                }
                if (pathname.indexOf('quote/details') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Quote: true });
                }
                if (pathname.indexOf('service/invoice') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ServiceInvoice: true });
                }
                if (pathname.indexOf('stock/quote') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, StockQuote: true });
                }
                if (pathname.indexOf('tradein/details') >= 0 ||
                    pathname.indexOf('tradein/images') >= 0 ||
                    pathname.indexOf('tradeinpro/eval') >= 0 ||
                    pathname.indexOf('tradeinpro/preview') >= 0 ||
                    pathname.indexOf('tradeinpro/intake') >= 0 ||
                    pathname.indexOf('tradeinpro/internal') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, TradeIn: true });
                }
                if (pathname.indexOf('attendee/confirmation') >= 0 || pathname.indexOf('attendee/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Events: true });
                }
                if (pathname.indexOf('stock/pipeline/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Stock: true });
                }
                if (pathname.indexOf('tradein/customer') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, TradeInNew: true });
                }
                if (pathname.indexOf('dailyactivity/union') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0 || pathname.indexOf('email/preview') >= 0 || pathname.indexOf('dealertargets/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('dailyactivity/adamas') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('dailyactivity/summary') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('dailyactivity/gulson') >= 0 || pathname.indexOf('dailyactivity/tonyleahey') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('dailyactivity/bmw') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('dailyactivity/porsche') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('summary/report') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0 || pathname.indexOf('triggeractivity/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                if (pathname.indexOf('testdrive/details') >= 0 || pathname.indexOf('fleetpro/confirmation') >= 0 || pathname.indexOf('fleet/details') >= 0 || pathname.indexOf('contact/consent') >= 0 || pathname.indexOf('testdrivelicense/details') >= 0 || pathname.indexOf('contact/unsubscribe') >= 0 || pathname.indexOf('terms/sign') >= 0 || pathname.indexOf('contact/privacy') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Testdrive: true });
                }
                if (pathname.indexOf('service/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Services: true });
                }
                if (pathname.indexOf('signrequest/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, SignRequest: true });
                }
                if (pathname.indexOf('customer/rating') >= 0 || pathname.indexOf('customer/form') >= 0 || pathname.indexOf('/customer/delivery') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, CustomerReview: true });
                }
                if (pathname.indexOf('embed/form') >= 0 || pathname.indexOf('embed/form') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, FormReview: true });
                }
                if (pathname.indexOf('service/customer') >= 0 || pathname.indexOf('serviceinvoice/preview') >= 0 || pathname.indexOf('service/history') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, CustomerApproval: true });
                }
                if (pathname.indexOf('enquiry/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, EnquiryPreview: true });
                }
                localStorage.setItem('uid', currentuser.uid);
                localStorage.removeItem('objNotify');
                window.firebase.auth().currentUser.getIdTokenResult().then(async (data) => {
                    await window.firebase.firestore().doc(`users/${currentuser.uid}`).set({
                        superAdmin: data.claims.superadmin ? data.claims.superadmin : false
                    }, { merge: true });
                    if (pathname.indexOf('video/details') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, AMSVideo: true });
                    }
                    else if (pathname.indexOf('quote/details') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, Quote: true });
                    }
                    else if (pathname.indexOf('service/invoice') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ServiceInvoice: true });
                    }
                    else if (pathname.indexOf('stock/quote') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, StockQuote: true });
                    }
                    else if (pathname.indexOf('tradein/details') >= 0 ||
                        pathname.indexOf('tradein/images') >= 0 ||
                        pathname.indexOf('tradeinpro/eval') >= 0 ||
                        pathname.indexOf('tradeinpro/preview') >= 0 ||
                        pathname.indexOf('tradeinpro/intake') >= 0 ||
                        pathname.indexOf('tradeinpro/internal') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, TradeIn: true });
                    }
                    else if (pathname.indexOf('attendee/confirmation') >= 0 || pathname.indexOf('attendee/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, Events: true });
                    }
                    else if (pathname.indexOf('stock/pipeline/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, Stock: true });
                    }
                    else if (pathname.indexOf('tradein/customer') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, TradeInNew: true });
                    }
                    else if (pathname.indexOf('dailyactivity/union') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0 || pathname.indexOf('email/preview') >= 0 || pathname.indexOf('dealertargets/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('dailyactivity/adamas') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('dailyactivity/summary') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('dailyactivity/gulson') >= 0 || pathname.indexOf('dailyactivity/tonyleahey') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('dailyactivity/bmw') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('dailyactivity/porsche') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('summary/report') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0 || pathname.indexOf('triggeractivity/report') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                    }
                    else if (pathname.indexOf('testdrive/details') >= 0 || pathname.indexOf('fleetpro/confirmation') >= 0 || pathname.indexOf('fleet/details') >= 0 || pathname.indexOf('contact/consent') >= 0 || pathname.indexOf('testdrivelicense/details') >= 0 || pathname.indexOf('contact/unsubscribe') >= 0 || pathname.indexOf('terms/sign') >= 0 || pathname.indexOf('contact/privacy') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, Testdrive: true });
                    }
                    else if (pathname.indexOf('service/preview') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, Services: true });
                    }
                    else if (pathname.indexOf('signrequest/preview') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, SignRequest: true });
                    }
                    else if (pathname.indexOf('customer/rating') >= 0 || pathname.indexOf('customer/form') >= 0 || pathname.indexOf('customer/delivery') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, CustomerReview: true });
                    }
                    else if (pathname.indexOf('embed/form') >= 0 || pathname.indexOf('embed/form') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, FormReview: true });
                    }
                    else if (pathname.indexOf('service/customer') >= 0 || pathname.indexOf('serviceinvoice/preview') >= 0 || pathname.indexOf('service/history') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, CustomerApproval: true });
                    }
                    else if (pathname.indexOf('enquiry/preview') >= 0) {
                        this._isMounted && this.setState({ Submitted: true, EnquiryPreview: true });
                    }
                    // @memo: remove super admin check from here
                    else if (data.claims.superadmin) {
                        this._isMounted && this.setState({ Submitted: true, superAdmin: true, AMSVideo: false, Quote: false, ServiceInvoice: false, StockQuote: false, TradeIn: false, Testdrive: false, ActivityReport: false });
                    }
                    else {
                        this._isMounted && this.setState({ Submitted: true, superAdmin: false, AMSVideo: false, Quote: false, ServiceInvoice: false, StockQuote: false, TradeIn: false, Testdrive: false, Service: true, ActivityReport: false });
                    }
                    this.getUser(currentuser.uid)
                });

            }
            else {
                if (pathname.indexOf('video/details') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, AMSVideo: true });
                }
                else if (pathname.indexOf('quote/details') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Quote: true });
                }
                else if (pathname.indexOf('service/invoice') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ServiceInvoice: true });
                }
                else if (pathname.indexOf('stock/quote') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, StockQuote: true });
                }
                else if (pathname.indexOf('tradein/details') >= 0 ||
                    pathname.indexOf('tradein/images') >= 0 ||
                    pathname.indexOf('tradeinpro/eval') >= 0 ||
                    pathname.indexOf('tradeinpro/preview') >= 0 ||
                    pathname.indexOf('tradeinpro/intake') >= 0 ||
                    pathname.indexOf('tradeinpro/internal') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, TradeIn: true });
                }
                else if (pathname.indexOf('attendee/confirmation') >= 0 || pathname.indexOf('attendee/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Events: true });
                }
                else if (pathname.indexOf('stock/pipeline/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Stock: true });
                }
                else if (pathname.indexOf('tradein/customer') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, TradeInNew: true });
                }
                else if (pathname.indexOf('dailyactivity/union') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0 || pathname.indexOf('email/preview') >= 0 || pathname.indexOf('dealertargets/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('dailyactivity/adamas') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('dailyactivity/summary') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('dailyactivity/gulson') >= 0 || pathname.indexOf('dailyactivity/tonyleahey') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('dailyactivity/bmw') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('dailyactivity/porsche') >= 0 || pathname.indexOf('dailyactivity/report') >= 0 || pathname.indexOf('dynamic/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('summary/report') >= 0 || pathname.indexOf('summary/report') >= 0 || pathname.indexOf('summary/report') >= 0 || pathname.indexOf('triggeractivity/report') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, ActivityReport: true });
                }
                else if (pathname.indexOf('testdrive/details') >= 0 || pathname.indexOf('fleetpro/confirmation') >= 0 || pathname.indexOf('fleet/details') >= 0 || pathname.indexOf('contact/consent') >= 0 || pathname.indexOf('testdrivelicense/details') >= 0 || pathname.indexOf('contact/unsubscribe') >= 0 || pathname.indexOf('terms/sign') >= 0 || pathname.indexOf('contact/privacy') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Testdrive: true });
                }
                else if (pathname.indexOf('service/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, Services: true });
                }
                else if (pathname.indexOf('signrequest/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, SignRequest: true });
                }
                else if (pathname.indexOf('customer/rating') >= 0 || pathname.indexOf('customer/form') >= 0 || pathname.indexOf('customer/delivery') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, CustomerReview: true });
                }
                else if (pathname.indexOf('embed/form') >= 0 || pathname.indexOf('embed/form') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, FormReview: true });
                }
                else if (pathname.indexOf('service/customer') >= 0 || pathname.indexOf('serviceinvoice/preview') >= 0 || pathname.indexOf('service/history') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, CustomerApproval: true });
                }
                else if (pathname.indexOf('enquiry/preview') >= 0) {
                    this._isMounted && this.setState({ Submitted: true, EnquiryPreview: true });
                }
                else {
                    const brandTheme = localStorage.brandTheme;
                    localStorage.clear();
                    this.props.history.push(`${brandTheme ? `/login?theme=${brandTheme}` : '/login'}`)
                }

            }

        })
    }



    /*
data:
click_action: "FLUTTER_NOTIFICATION_CLICK"
clientID: "ICVrcE79shbvg9cZb0my"
recordID: "LRx8wMlHvdGcQjmSnVrj"
type: "activity"

notification:
body: "Hilal Baig mentioned you in appointment"
title: "Activity"
    */

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return this.state.Submitted
            ?
            (this.state.Quote
                ?
                (<QuoteLayout history={this.props.history} match={this.props.match} />)
                :
                (this.state.ServiceInvoice
                    ?
                    (<ServiceInvoiceLayout history={this.props.history} match={this.props.match} />)
                    :
                    (this.state.StockQuote
                        ?
                        (<StockQuoteLayout history={this.props.history} match={this.props.match} />)
                        :
                        (this.state.AMSVideo
                            ?
                            (<VideoLayout history={this.props.history} />)
                            :
                            (this.state.TradeIn
                                ?
                                (<TradeInLayout history={this.props.history} />)
                                :
                                (this.state.Events
                                    ?
                                    (<EventsLayout history={this.props.history} />)
                                    :
                                    (this.state.Stock
                                        ?
                                        (<StockReportLayout history={this.props.history} />)
                                        :
                                        (this.state.TradeInNew
                                            ?
                                            (<TradeInNewLayout history={this.props.history} />)
                                            :
                                            (this.state.Testdrive
                                                ?
                                                (<TestdriveLayout history={this.props.history} />)
                                                :
                                                (this.state.Services
                                                    ?
                                                    (<ServiceLayout history={this.props.history} />)
                                                    :
                                                    (this.state.EnquiryPreview
                                                        ?
                                                        (<EnquiryLayout history={this.props.history} />)
                                                        :
                                                        (this.state.CustomerApproval
                                                            ?
                                                            (<CustomerApprovalLayout history={this.props.history} />)
                                                            :
                                                            (this.state.ActivityReport
                                                                ?
                                                                (<ReportLayouts history={this.props.history} />)
                                                                :
                                                                (this.state.SignRequest
                                                                    ?
                                                                    (<SignRequestLayout history={this.props.history} />)
                                                                    :
                                                                    (this.state.CustomerReview
                                                                        ?
                                                                        (<CustomerReviewLayout history={this.props.history} />)
                                                                        :
                                                                        (this.state.FormReview
                                                                            ?
                                                                            (<FormLayout history={this.props.history} />)
                                                                            :
                                                                            // (this.state.superAdmin
                                                                            (this.state.user && isEmpty(this.state.user.settingsID) && this.state.superAdmin
                                                                                ?
                                                                                (<AdminPanel setUser={this.setUser} user={this.state.user} history={this.props.history} />)
                                                                                :
                                                                                (
                                                                                    <LayoutProvider history={this.props.history}>
                                                                                        <LayoutConsumer>
                                                                                            {({ currentClient, deviceTokens, removeDevicetoken, dealersettings, clientModule }) => (
                                                                                                <RouteProvider history={this.props.history} currentClient={currentClient} dealersettings={dealersettings}>
                                                                                                    <Suspense fallback={<></>}>
                                                                                                        {
                                                                                                            <>
                                                                                                                <Navbar setUser={this.setUser} user={this.state.user} history={this.props.history} deviceTokens={deviceTokens} removeDevicetoken={removeDevicetoken} dealersettings={dealersettings} currentClient={currentClient} clientModule={clientModule} />
                                                                                                                <MainContainer history={this.props.history} dealersettings={dealersettings} clientModule={clientModule} setUser={this.setUser} />
                                                                                                            </>
                                                                                                        }
                                                                                                    </Suspense>
                                                                                                </RouteProvider>
                                                                                            )}
                                                                                        </LayoutConsumer>
                                                                                    </LayoutProvider>
                                                                                ))))))))))))))))))
            :
            (<></>);
    }
}

export default MainLayout;