import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { TableView } from '../../components';

const CampaignDetails = ({
	setActiveTab,
	campaign,
	recipients,
	windowSize,
	allrecipientFields,
	handleActionClick,
	handleRowSingleClick,
	activeTab,
	bodyHTML,
}) => {
	const [activeSubTab, setActiveSubTab] = useState('campaignpreview');

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{campaign.settings.title} </div>
					<div className='campaign-add-sub-title'>
						<div className='badge badge-pill badge-available-fill float-left'>
							SENT
						</div>
						<span className='float-left campaign-add-date'>
							{' '}
							Delivered on{' '}
							{moment(campaign.send_time).format('DD/MM/YYYY hh:mm A')}{' '}
						</span>
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '75vh' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='campaignpreview' title='Campaign Preview'>
									<div>
										<div className=''>
											<div
												dangerouslySetInnerHTML={{
													__html: campaign.templateData
														? campaign.templateData.bodyHTML
														: bodyHTML,
												}}
											></div>
										</div>
									</div>
								</Tab>
								<Tab eventKey='details' title='Details'>
									<div className='common-table'>
										<table className='table table-bordered'>
											<tbody>
												<tr>
													<th className='head-light'>
														Delivery date &amp; time
													</th>
													<td>
														{moment(campaign.send_time).format(
															'DD/MM/YYYY hh:mm A',
														)}
													</td>
												</tr>

												<tr>
													<th className='head-light'>From name</th>
													<td>{campaign.settings.from_name}</td>
												</tr>
												<tr>
													<th className='head-light'>From email</th>
													<td>{campaign.settings.reply_to}</td>
												</tr>
												<tr>
													<th className='head-light'>Subject line</th>
													<td>{campaign.settings.subject_line}</td>
												</tr>
												<tr>
													<th className='head-light'>Recipients</th>
													<td>
														{campaign.recipients.list_name} -{' '}
														{campaign.recipients.recipient_count}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Tab>
								<Tab eventKey='recipients' title='Recipients'>
									<div className='common-table'>
										<TableView
											isReorderable={false}
											datalist={recipients}
											height={windowSize.windowHeight}
											width={windowSize.windowWidth}
											columns={allrecipientFields}
											dynamicFields={allrecipientFields
												.filter(e => e.default === true)
												.map(v => _.pick(v, ['value']).value)}
											settingsLoader={false}
											handleActionClick={handleActionClick}
											handleRowSingleClick={handleRowSingleClick}
											isSettings={false}
											hasMore={false}
											isPaging={false}
										/>
									</div>
								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignDetails;
