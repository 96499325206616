/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _, { isEmpty } from 'lodash'
import { default as _images } from '../../../images';

/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopoverListLoader, SidePanel } from '../../../components';
import TIPHistory from '../info'
import { objTradeIn } from '../../tradeIn/viewModel'
import { tipStatus } from '../../../services/enum'
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';
import QuickView from "../quickview";

class PopoverList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradeIns: [],
            errors: {},
            loading: true,
            showTIPpanel: { clsActive: '', clsName: 'sidebar-tradeinpro-quickview pac-container' },
        };
    }

    componentDidMount() {
        const { dealersettings, _keywords, tradeinProID, hasOffer } = this.props;
        if (dealersettings && _keywords) {

            firestoreDB(dealersettings).firestore().collection('tradeinPro')
                .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
                //.where('clientID', '==', (clientID ? clientID : dealersettings?.client?.id))
                .where('keywords', 'array-contains-any', _keywords)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    const tradeIns = [];
                    response.docs.forEach((doc) => {
                        let _enq = Object.assign({}, doc.data());
                        if ((_.isEmpty(tradeinProID) || (tradeinProID && tradeinProID !== doc.id)) &&
                            ((_enq?.tradein?.regNo && _keywords.includes(_enq?.tradein?.regNo.toLowerCase())) ||
                                (_enq?.tradein?.chassisNo && _keywords.includes(_enq?.tradein?.chassisNo.toLowerCase())))
                        ) {
                            _enq.documentID = doc.id;
                            if (hasOffer && _enq.status !== tipStatus.LOST && (!_.isEmpty(_enq.offer) || _enq.status === tipStatus.PENDING))
                                tradeIns.push(_enq);
                            else if (!hasOffer)
                                tradeIns.push(_enq);
                        }
                    });

                    this.setState({
                        tradeIns: _.orderBy(tradeIns, ["modifiedDate"], ["desc"]),
                        loading: false
                    });

                });
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    componentWillUnmount() {
        //this.unsubscribeENQZ && this.unsubscribeENQZ();
    }

    handleStatusChange = (tradeinid, _status) => {

    }

    //#region  QUICK VIEW TIP
    sidepanelTIPOpen = id => {
        const { tradeinProID } = this.props;

        if (tradeinProID !== id) {
            //this.props.history.push('/tradeinpro/details/' + id);

            this.setState({
                showTIPpanel: {
                    clsActive: 'active',
                    clsName: 'sidebar-tradeinpro-quickview pac-container',
                    tradeinproid: id,
                },
            });
        }


    };

    sidepanelTIPClose = (_type) => {
        if (_type === 'expand') this.props.handleClose('expand');

        this.setState({
            showTIPpanel: {
                clsActive: '',
                clsName: '',
                tradeinproid: 0,
            },
        });
    };
    //#endregion

    render() {

        const { tradeIns, loading, showTIPpanel } = this.state
        const { show, dealersettings, groupUsers, clientUsers, hideContinue } = this.props;

        const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
        const divStyle = {
            height: '500px',
            overflow: 'auto',
            position: "relative"
        };

        return (
            <>
                <Modal
                    id="tradein-completed-list"
                    show={show}
                    onEscapeKeyDown={false}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title className='w-100'>
                            <Translate text={'Existing Appraisals'} />
                            {
                                !hideContinue && (<div className="float-right kitchen-modal-status ml-2 pt-0 mr-2">
                                    <button type="button" className="btn btn-secondary float-right"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.handleClose('search')
                                        }}>
                                        <i className="ico icon-close"></i> <Translate text={'Continue, Search'} /></button>
                                </div>)
                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>

                            {/* <div className="service-loan-yellow-alert pb-3">
                                <div className="alert-warning pt-2 pb-2" role="alert">
                                    <div className="service-alert-flex">
                                        <div className="service-alert-flex-data pl-2">

                                            <p>{`Please mark these trade-ins  or `}</p>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div style={divStyle} >
                                {
                                    loading ? (<PopoverListLoader />) :
                                        (tradeIns && tradeIns.length > 0)
                                            ?
                                            <>
                                                {tradeIns.map((tradein, index) =>
                                                    <div key={tradein.documentID} id={tradein.documentID} className="vehicle-popover-loop pb-0" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.sidepanelTIPOpen(tradein.documentID)

                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width100 pb-0">
                                                                        <TIPHistory
                                                                            key={index}
                                                                            fields={tradein}
                                                                            dealersettings={dealersettings}
                                                                            clientUsers={clientUsers}
                                                                            groupUsers={groupUsers}
                                                                            sidepanelOpen={() => { }}
                                                                            history={this.props.history}
                                                                            showMore={false}
                                                                            companyView={true}
                                                                            isList={true}
                                                                            hideLogo={true}
                                                                        ></TIPHistory>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-tradein-module-icon")}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noTradeinFound'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }
                            </div>

                        </>
                    </Modal.Body>

                </Modal>

                <SidePanel
                    clsActive={showTIPpanel.clsActive}
                    clsName={showTIPpanel.clsName}
                    sidepanelClose={this.sidepanelTIPClose}
                    title=''
                >
                    <QuickView
                        {...this.props}
                        tradeinproid={showTIPpanel.tradeinproid}
                        sidepanelClose={this.sidepanelTIPClose}
                        isReadOnlyView={true}
                        //isHideExpandView={true}
                        history={this.props.history}
                    />
                </SidePanel>

            </>
        );
    }

}

export default PopoverList;