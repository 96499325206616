import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { default as _images } from '../../images';
import { MeiliSearch } from 'meilisearch'
import { Tabs, Tab } from 'react-bootstrap'
import MeilisearchIndexes from './allIndexes';
import MeilisearchTasks from './tasks';
//FOR LIVE
// static const kMeilisearchServerLive = "https://meilisearch.fusionamspro.com/";
//   static const kMeilisearchKeyLive = "fusion@Meili1#14";

//FOR QA
// host: 'http://ec2-13-238-255-91.ap-southeast-2.compute.amazonaws.com/',
// apiKey: 'YTNiMzU5YzhmY2U4YzhkNGMwNmE4YTIw',

// const client = new MeiliSearch(window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com" ? {
//   host: 'https://meilisearch.fusionamspro.com/',
//   apiKey: 'fusion@Meili1#14',
// } : {
//   host: 'http://ec2-13-238-255-91.ap-southeast-2.compute.amazonaws.com/',
//   apiKey: 'YTNiMzU5YzhmY2U4YzhkNGMwNmE4YTIw',
// });

const Meilisearch = (props) => {
  const [activeTab, setActiveTab] = useState(`settings`);
  const [collection, setCollection] = useState([]);
  const [client, setClient] = useState();

  useEffect(() => {
    window.firebase.firestore().doc(`/meilisearch/settings`)
      .get()
      .then(doc => {
        if (doc.exists) {
          setClient(new MeiliSearch({
            host: doc.data().server,
            apiKey: doc.data().key,
          }))
        }
      });
  }, [])

  useEffect(() => {
    if (_.isEmpty(client))
      return;
    let _collection = [];
    client.getIndexes({ limit: 100 }).then(data => {
      data.results.forEach(rec => {
        _collection.push({
          label: rec.uid,
          name: rec.uid,
          value: rec.uid,
          primaryKey: rec.primaryKey,
          uid: rec.uid
        })
      })
      setCollection(_collection)
    });
  }, [client])

  return client ? (
    <>
      <div className="middle-wrapper">
        <div className="settings-tab-wrap tabs meilisearch-tab-wrap">
          <div className="tabs-wraper">
            <nav>
              <Tabs defaultActiveKey={`settings`} transition={false} className="nav-fill" onSelect={(key) => {
                //console.log('onSelect', key)
                setActiveTab(key)
              }}>
                <Tab eventKey="settings" title="Indexes">
                  <MeilisearchIndexes
                    client={client}
                  />
                </Tab>
                <Tab eventKey="tasks" title="Task">
                  <MeilisearchTasks
                    client={client}
                    collection={collection}
                  />
                </Tab>
              </Tabs>
            </nav>
          </div>
        </div>
      </div>
    </>
  ) : (<></>)
}

export default Meilisearch