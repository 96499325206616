import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import uuid from 'react-uuid'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format'
import { ContentOverlay, ReactSelect, ReactMultiSelect, PopUpModal } from '../../../../components'
import images from '../../../../images'
import Translate from '../../../../constants/translate';
import _images from '../../../../images'
import CommonHelper from '../../../../services/common';
import { activityOptions, targetmandatoryFields, targetVM, levelOptions, enqStatus } from '../../../settings/company/dealerTargets/viewModel'
import { ClientContext } from '../../../settings/company/clientContext'
//import UserExists from './userExistsAlert'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { financestatus } from '../../../../services/enum';


const TargetKPIs = (props) => {

    const [activeMonth, setActiveMonth] = useState(moment().format("MMM"))
    const [errorFields, setErrorFields] = useState({});
    const [textEditor, setTexteditor] = useState({})
    const [targetSettings, setTargetSettings] = useState({ name: '', type: '', subType: '', calculationType: '', target: '', targetID: uuid() })
    const [editTaretSettings, setEditTargetSettings] = useState()
    const [btnLoader, setBtnLoader] = useState(false)
    const [copyloader, setCopyLoader] = useState(false)
    const [years, setYears] = useState([])
    const [selectedYear, setSelctedYear] = useState(moment().format("YYYY"))
    const [selectedForeCast, setSelectedForeCast] = useState({})
    //const { roles } = useContext(ClientContext);
    const newTarget = !_.isEmpty(props.target) ? false : true
    const [existsUsers, setUsers] = useState();
    const [showModel, setShowModel] = useState(false)

    const [yearloader, setYearLoader] = useState(false)

    const [clientLoader, setClientLoader] = useState(true)
    const [selectedLevel, setSelectedLevel] = useState({ level: props?.dealersettings?.level || 'oem' })
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [loader, setLoader] = useState(true)

    const targetOptions = [
        { value: 'wonForecast', name: 'Forecast', module: 'won', target: 6 },
        //{ value: 'deliveredTarget', name: 'Target', module: 'delivered', target: 10 },
        { value: 'deliveredForecast', name: 'Forecast', module: 'delivered', target: 6 },

    ]

    // const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.client) &&
    //     !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');


    useEffect(() => {
        if (!_.isEmpty(props.dealersettings)) {
            const { dealersettings } = props;
            let _regions = [], _groups = [], _clients = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (dealersettings.clients && dealersettings.clients.some(a => a.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (dealersettings.clients && dealersettings.clients.some(a => a.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });
            dealersettings.clients && dealersettings.clients.forEach((doc) => {
                _clients.push({
                    ...doc,
                    value: doc.id,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            setClientOptions(_clients)
            setRegionOptions(_regions)
            setGroupOptions(_groups)
            setClientLoader(false)
        }

    }, [])

    // useEffect(() => {
    //     if (_.isEmpty(props.target)) {
    //         const newtargetVM = Object.assign({}, targetVM);
    //         newtargetVM.documentID = window.firebase.firestore().collection(`targets`).doc().id;
    //         newtargetVM.clientID = props.selectedClient?.documentID;
    //         newtargetVM.addedDate = window.firebase.firestore.Timestamp.now();
    //         newtargetVM.addedBy = localStorage.uid;
    //         newtargetVM.modifiedDate = window.firebase.firestore.Timestamp.now();
    //         newtargetVM.modifiedBy = localStorage.uid;
    //         newtargetVM.isDeleted = false;
    //         newtargetVM.type = 'user';
    //         setSelectedForeCast(newtargetVM)
    //     }
    // }, [])

    useEffect(() => {
        if (!loader || clientLoader) {
            return
        }

        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }
        if (clients.length > 0) {
            window.firebase.firestore().doc(`clients/${clients[0].value}/salesForecast${props.saleType === 'used' ? 'used' : ''}/${clients[0].value}`).get()
                .then(snapshot => {
                    if (snapshot.exists) {
                        let target = snapshot.data()
                        if (!_.isEmpty(target))
                            setSelectedForeCast(target);
                    }
                    setLoader(false)
                })
        }
        else {
            setLoader(false)
        }

    }, [loader, clientLoader])

    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)
    }, [])

    const onAmountChange = (e, name) => {
        let _value = e.floatValue
        // console.log('onAmountChange value ', _value)
        if (_value === undefined) {
            _value = null
        }
        setSelectedForeCast({
            ...selectedForeCast,
            [selectedYear]: {
                ...selectedForeCast?.[selectedYear],
                [activeMonth]: {
                    ...selectedForeCast?.[selectedYear]?.[activeMonth],
                    [name]: _value
                }

            }
        })

    }


    const handleyearChange = (e, data) => {
        setSelctedYear(e.value)

        if (e.value === moment().format('YYYY')) {
            setActiveMonth(moment().format('MMM'))
        }
        else {
            setActiveMonth(moment().startOf('year').format('MMM'))
        }
    }

    const handleCopytoAll = () => {
        if (_.isEmpty(selectedForeCast) || _.isEmpty(selectedForeCast[selectedYear]) || _.isEmpty(selectedForeCast[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        setCopyLoader(true)
        var _currentTarget = selectedForeCast;
        for (let i = (Number(moment().month(activeMonth).format("M")) + 1); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[selectedYear],
                    [moment(i.toString(), 'M').format('MMM')]: { ...selectedForeCast[selectedYear][activeMonth] }
                }
            }
        }
        setSelectedForeCast(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setCopyLoader(false)
        }, 2000)


    }

    const handleCopyfromlastYear = async () => {
        if (_.isEmpty(selectedForeCast) || _.isEmpty(selectedForeCast[(parseInt(selectedYear) - 1)])) {
            Swal.fire(`${CommonHelper.showLocale(props, 'No targets defined in')} ${parseInt(selectedYear) - 1}`, '', 'error')
            return
        }
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(props, 'Do you want to copy targets from')} ${(parseInt(selectedYear) - 1)}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }
        setYearLoader(true)
        var _currentTarget = selectedForeCast;
        for (let i = (Number(moment().month(activeMonth).format("M")) + 1); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[(parseInt(selectedYear) - 1)]
                }
            }
        }
        setSelectedForeCast(_currentTarget)

        setTimeout(function () {
            toast.notify('Targets copied successfully.', {
                duration: 1000
            })
            setYearLoader(false)
        }, 2000)
    }

    const handleSave = () => {
        let errorClass = 'input_error';
        if (selectedLevel.level !== 'oem' && !_.isEmpty(selectedLevel.level) && _.isEmpty(selectedLevel.levelID)) {
            setErrorFields({ ['levelID']: errorClass })
            return
        }
        if (_.isEmpty(selectedForeCast) || _.isEmpty(selectedForeCast[selectedYear]) || _.isEmpty(selectedForeCast[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        setBtnLoader(true);
        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }
        const batch = window.firebase.firestore().batch();
        clients.forEach(rec => {
            batch.set(window.firebase.firestore().doc(`clients/${rec.value}/salesForecast${props.saleType === 'used' ? 'used' : ''}/${rec.value}`),
                selectedForeCast, { merge: true })
        })
        batch.commit()
            .then(snapshot => {
                setBtnLoader(false);
                props.handleClose();
                Swal.fire({
                    icon: 'success',
                    title: 'Target updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }


    return (<>
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-configure-integration-generic-api"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid pl-0 pr-0">
                    <div className="settings-head form-style">
                        <div className="form-row">
                            <div className={`form-group col-md-${(!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? '6' : '12'}`}>
                                <label ><Translate text={'Level'} /></label>
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={(e, data) => {
                                        if (e) {
                                            if (e.value === 'oem')
                                                setLoader(true)
                                            setSelectedLevel({
                                                level: e.value
                                            });

                                        }
                                        else
                                            setSelectedLevel(null);
                                    }}
                                    value={selectedLevel ? selectedLevel.level : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>

                            {
                                (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ?
                                    (
                                        <div className="form-group col-md-6">
                                            <label >{selectedLevel.level === 'individual' ? 'Client' : CommonHelper.autoCaps(selectedLevel.level)}</label>
                                            <ReactSelect
                                                options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                                name={'levelID'}
                                                placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                                onChange={(e, data) => {
                                                    if (e) {
                                                        //setLoader(true)
                                                        setSelectedLevel({
                                                            ...selectedLevel,
                                                            levelID: e.value
                                                        });
                                                        setErrorFields({})
                                                        setLoader(true)
                                                    }
                                                    else
                                                        setSelectedLevel(null);
                                                }}
                                                value={selectedLevel ? selectedLevel.levelID : ''}
                                                classNamePrefix={`${errorFields["levelID"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                            }


                        </div>

                    </div>

                </div>
                <div className="settings-head form-style border-top pt-3">
                    <div className="form-row">
                        <div className="form-group col-md-2">
                            <ReactSelect
                                options={years}
                                name={"year"}
                                placeholder={'select year'}
                                onChange={handleyearChange}
                                value={selectedYear}
                                classNamePrefix={`basic-select`}
                                removeClearable={true}
                                id={`target-year`}
                            >
                            </ReactSelect>
                        </div>
                        <div className="form-group col-md-6">
                            <div className="yeargrid">
                                <ul>
                                    {
                                        moment.monthsShort().map((rec, index) => {
                                            return <li key={index}><a
                                                id={rec}
                                                href="#"
                                                className={rec === activeMonth ? 'selected' : ''}
                                                onClick={(event) => {
                                                    if (event.target.id) {
                                                        setActiveMonth(event.target.id)
                                                    }
                                                    event.preventDefault();
                                                }}
                                            >{rec}</a></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="form-group col-md-4">
                            <div className="float-right ml-2">
                                <button type="button" className="btn btn-primary float-right" onClick={() => {
                                    handleCopyfromlastYear()
                                }} >
                                    {
                                        yearloader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }<Translate text={'Copy from last year'} /></button>
                            </div>
                            <div className="float-right">
                                <button type="button" className="btn btn-primary float-right" onClick={() => {
                                    handleCopytoAll()
                                }} >
                                    {
                                        copyloader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }<Translate text={'Copy to all months'} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        selectedLevel && (selectedLevel.level === 'oem' || !_.isEmpty(selectedLevel.levelID)) ? (
                            <div className="common-table mt-3">
                                <table
                                    className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="head-light" width="5%">SNo</th>
                                            <th scope="col" className="head-light" width="75%">Name</th>
                                            <th scope="col" className="head-light" width="10%">Target</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            targetOptions.map((rec, index) => {
                                                return <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.enquiryStatus, rec.module, CommonHelper.autoCaps(rec.module))}&nbsp;{rec.name}</td>
                                                    <td>
                                                        <div className='grid-one text-right number-value float-left w-100'>
                                                            <NumberFormat
                                                                allowNegative={false}
                                                                thousandSeparator={true}
                                                                className={`input-amount`}
                                                                decimalScale={2}
                                                                onValueChange={e => onAmountChange(e, rec.value)}
                                                                value={selectedForeCast?.[selectedYear]?.[activeMonth]?.[rec.value] || null}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        ) :
                            (
                                <div className="common-table mb-5 mt-5">
                                    <div className='h-100  w-100'>
                                        <div className='spinner-loader h-100'>
                                            <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                                <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                                <div className="no-data-txt mt-2">
                                                    <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                }

            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className={`btn btn-primary float-right ml-2 ${!_.isEmpty(textEditor) ? 'btn-disable' : ''}`} onClick={() => { handleSave() }}>
                    {
                        btnLoader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
        {/* <PopUpModal show={showModel}>
                <UserExists
                    {...props}
                    show={showModel}
                    handleClose={handleModelClose}
                    users={existsUsers}
                />
            </PopUpModal> */}
    </>)

}

export default TargetKPIs;