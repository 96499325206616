
export const clientLookups = [
    {
        name: 'Category',
        value: 'rosterCategories',
        module: 'Roster',
        icon: 'icon-mail-draft'
    },
    {
        name: 'Holidays',
        value: 'rosterHolidays',
        module: 'Roster',
        icon: 'icon-mail-draft'
    }
]

export const rosterLookupVM = {
    clickable: null,
    cron: null,
    date: null,
    dateFrom: null,
    dateTo: null,
    frequency: null,
    value: null,
    month: null,
    name: null,
    recurrence: null,
    day: null,
    color: null,
    level: '',
    active: true,
    cron: null,
    dealershipOff: null
}

export const tableFields = [
    {
        name: 'Name',
        value: 'strName',
        sortValue: 'strValue',
        flex: 1,
        default: true,
    },
    {
        name: 'Date / Recurrence',
        value: 'strCron',
        sortValue: 'strCron',
        flex: 1,
        default: true,
    },
    {
        name: 'Dealers',
        value: 'levelsDOM',
        sortValue: 'levelName',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const frequencyOptions = [
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' },
    { active: true, value: 'yearly', label: 'Yearly' },
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]