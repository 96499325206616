/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import toast from 'toasted-notes'
import _ from 'lodash'
import { ContentOverlay, InputCheckBox, PopUpModal } from '../../../../components';
import { mentions } from '../../workflow/templates/viewModel';
import images from '../../../../images';
import TemplateEditor from './templateEditor';
import AddTemplateHTML from './addTemplateHTML';
import Swal from 'sweetalert2';
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import AddLanguage from '../../company/dealerSettings/addLanguage'
import { Dropdown } from 'react-bootstrap'
import Translate from '../../../../constants/translate';
import { MainContext } from '../../../layout/provider';
import { useContext } from 'react';
import CommonHelper from '../../../../services/common';
const specialLinks = [{
	type: 'GDPR Info',
	label: 'SpecialLink.GDPRInfo',
	link: '@GDPR_INFO'
}];

const _mentions = [{
	name: 'GDPR Info',
	value: '@GDPR_INFO',
	id: '@GDPR_INFO'
},
{
	type: 'Facebook URL',
	label: 'Link.Facebook',
	link: '@FACEBOOK_URL'
},
{
	type: 'X URL',
	label: 'Link.X',
	link: '@X_URL'
},
{
	type: 'Instagram URL',
	label: 'Link.Instagram',
	link: '@INSTAGRAM_URL'
},
{
	type: 'Youtube URL',
	label: 'Link.Youtube',
	link: '@YOUTUBE_URL'
}];

const Rules = (props) => {
	const [state, setState] = useState({});
	const [loader, setLoader] = useState(false);
	const [pageLoader, setPageLoader] = useState(true);
	const [errorFields, setErrorFields] = useState({});
	const [showEdiorModel, setShowEdiorModel] = useState({ show: false })
	const [addHtml, setAddHtml] = useState({ show: false })
	const [showAddLanguageModal, setShowAddLanguageModel] = useState({ show: false })
	const [localizeData, setLocalizeData] = useState([])
	const [selectedLocalize, setSelectedLocalize] = useState({})
	const [languageLoader, setLanguageLoader] = useState(false)
	const [allMentions, setMentions] = useState([...mentions.map(mention => {
		return {
			name: `${mention.value}`,
			id: mention.value,
			title: `${mention.name}`,
		};
	}), ..._mentions]);
	const ref = window.firebase
		.firestore()
		.doc(
			`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}`,
		);
	const dynamicOptions = props.dealersettings?.client?.settings?.enquiryOptionsDF.filter(a => a.active === true);
	const { updateDealerSettings } = useContext(MainContext)

	useEffect(() => {
		ref.get()
			.then(snap => {
				if (snap.exists) {
					let data = snap.data()
					setState(data);
					setPageLoader(false)
				}
				else {
					setPageLoader(false)
				}
			});
	}, []);

	useEffect(() => {
		if (pageLoader)
			return
		window.firebase.firestore().collection(`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData`).get()
			.then(data => {
				setLanguageLoader(false);
				const _defaultData = {
					...state,
					name: 'Default',
					code: 'Default',
					active: true
				};
				const _data = []
				if (data.docs.length > 0) {
					data.docs.forEach(lang => {
						_data.push(lang.data())
					})
				}
				setSelectedLocalize(_defaultData)
				setLocalizeData([
					_defaultData,
					..._.orderBy(_data, 'code', 'asc')
				])
			})

	}, [pageLoader])

	useEffect(() => {
		if (_.isEmpty(state) || pageLoader)
			return
		ref.set({
			...state,
			modifiedDate: window.firebase.firestore.Timestamp.now(),
			modifiedBy: localStorage.uid,
		}, { merge: true })
			.then(() => {
				updateDealerSettings('gdpr', state)
				toast.notify('GDPR Settings Updated', {
					duration: 2000
				})
			})
	}, [state])

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--settingscontainerHeight',
				850 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--settingscontainerHeight',
			850 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
			document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 50) + 'px')
		};
	}, []);

	const handleCheckChange = e => {
		let check = e.target.checked;
		// console.log(check);
		setState({
			...state,
			[e.target.name]: e.target.checked,
		});
	};

	const handleQuoteNumberChange = e => {
		var regexp = /^[0-9+ \b]+$/;
		let number = e.target.value;

		// if value is not blank, then test the regex
		if (number === '' || regexp.test(number)) {
			const num = number !== '' ? parseInt(number) : number
			setState({
				...state,
				[e.target.name]: num
			})

		}
		setErrorFields({
			...errorFields,
			[e.target.name]: '',
		});
	};

	const onSave = async () => {
		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (state.enable === true) {
			if (!state['frequency']) {
				formIsValid = false;
				errors['frequency'] = errorClass;
			}
		}
		setErrorFields(errors);
		if (!formIsValid) {
			return;
		}
		const objLog = { ...state }
		try {
			setLoader(true)
			await ref.set({
				...objLog,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				modifiedBy: localStorage.uid,
			}, { merge: true });
			setLoader(false)
			toast.notify('GDPR Settings Updated', {
				duration: 2000
			})

			//update settings date and priority
			// if (!isEmpty(props.dealersettings.client)) {
			// 	window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
			// 		.set({
			// 			settingsUpdate: window.firebase.firestore.Timestamp.now(),
			// 			settingsUpdatePriority: 'normal'
			// 		}, { merge: true })
			// }
			//end 

		} catch (error) {
			setLoader(false)
			console.log(error);
		}
	}

	const handleModelClose = () => {
		setShowEdiorModel({ show: false })
	}

	const handleLanguageChange = (_data) => {
		if (!_.isEmpty(_data)) {
			setLocalizeData([..._.forEach(localizeData, function (obj) {
				if (obj.code === _data.code)
					_.set(obj, 'active', true);
				else
					_.set(obj, 'active', false);
			})]);
			setSelectedLocalize(_data);
		}
	}
	const handledeleteLanguage = (_data) => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then((result) => {
			if (result.value) {
				window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData/${_data.code}`).delete()
					.then(snapshot => {
						setSelectedLocalize(localizeData.find(a => a.code === 'Default'))
						setLocalizeData(localizeData.filter(a => a.code !== _data.code).map(rec => {
							return {
								...rec,
								active: rec.code === 'Default'
							}
						}))
						toast.notify('Language deleted successfully', {
							duration: 2000
						})
					})
			}
		})
	}

	return pageLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
		<>
			<h2><Translate text={'General Data Protection Regulation (GDPR)'} /></h2>
			<div className='divider-line'></div>
			<div className={`security-settings-inner form-style`}>
				<h3><Translate text={'Enable GDPR'} /></h3>
				<p className='pb-0'><Translate text={'GDPR Description'} /> </p>
				<div className="gdpr-active-sync">
					<div className="gdpr-active-sync-item active-tab">

						<div className="gdpr-label">
							<span className="gdpr-active-logo"><img src={images.gdpr} alt="GDPR" /></span> <Translate text={'General Data Protection Regulation (GDPR)'} />
						</div>
						<div className="gdpr-btn">
							<span className='switch switch-sm mr-2'>
								<InputCheckBox
									id='gdpr-enabled'
									className='switch'
									name={'enabled'}
									checked={
										state.enabled
											? state.enabled
											: false
									}
									onChange={handleCheckChange}
								/>
								<label htmlFor='gdpr-enabled'></label>
							</span>
						</div>
					</div>
				</div>
				<h3><Translate text={'Frequency'} /></h3>
				{/* <p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					aliquip ex ea commodo consequat.
				</p> */}
				<div className='w-100 d-block font-14 ml-2'>
					<div className='form-row'>
						<h5 className='settings-subhead'><Translate text={'Every'} /></h5>
						<div className='form-group col-md-1'>
							<input
								type="text"
								autoComplete="off"
								placeholder={''}
								className={`form-control ${errorFields['frequency']}`}
								name="frequency"
								onChange={handleQuoteNumberChange}
								value={state.frequency ? state.frequency : ''}
								maxLength={2}
							/>
						</div>
						<h5 className='settings-subhead'><Translate text={'Months'} /></h5>
					</div>
				</div>
				<div className="divider-line"></div>
				<h3><Translate text={'Email Template'} /></h3>
				<div className="settings-column-wraper w-100">
					<div className="settings-columns">
						<div className="settings-column-left" style={{ width: `300px` }}>
							<div className="settings-column-title">
								<div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setShowAddLanguageModel({ show: true, title: 'Add Language' }); }}> <i className="ico icon-add"></i></a></div>
								<h3><Translate text={'Languages'} /></h3>
							</div>
							<div className="settings-column-nav">
								{
									languageLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
										<ul>
											{
												localizeData.map((rec, index) => {
													return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
														{
															rec.code === 'Default' ? (
																<div className="role-list-items-lock"> <i className="ico icon-lock"></i> </div>
															) : (

																<Dropdown className="role-list-items-more" alignRight>
																	<Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
																		<i className="ico icon-more"></i>
																	</Dropdown.Toggle>
																	<Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
																		<Dropdown.Item eventKey="delete" onClick={(e) => { e.preventDefault(); setShowAddLanguageModel({ show: true, title: 'Edit Language', language: rec }); }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
																		<Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteLanguage(rec) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															)
														}
														<a href="#" className="column-list-left"
															onClick={(e) => {
																e.preventDefault();
																handleLanguageChange(rec);
															}}
														>{rec.name}
														</a>
													</li>
												})
											}


										</ul>)
								}
							</div>
						</div>
						<div className="settings-column-right-large">
							<div className="settings-inner pl-3 pr-3">
								{
									selectedLocalize.bodyHTML ? (<>
										<div className="gdpr-template-edit mt-2">
											<div className="float-left">
												<h5 className="settings-subhead"><Translate text={'Subject'} /> : {selectedLocalize.subject}</h5>
											</div>
											<div className="float-right"><button type="button" className="btn btn-red float-right ml-2" onClick={(e) => {
												e.preventDefault();
												Swal.fire({
													title: CommonHelper.showLocale(props, 'Are you sure?'),
													text: CommonHelper.showLocale(props, 'Do you want to delete'),
													icon: 'info',
													showCancelButton: true,
													confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
													cancelButtonText: CommonHelper.showLocale(props, 'No'),
												}).then(async (result) => {
													if (result.value) {
														setSelectedLocalize({
															...selectedLocalize,
															bodyHTML: null,
															templateJSON: null,
														})
														setLocalizeData(localizeData.map(rec => {
															return rec.code === selectedLocalize.code ? {
																...rec,
																bodyHTML: null,
																templateJSON: null,
															} : { ...rec }
														}))
														if (selectedLocalize.code === 'Default') {
															setState({
																...selectedLocalize,
																bodyHTML: null,
																templateJSON: null
															})
														}
														else {
															await window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData/${selectedLocalize.code}`).set({
																bodyHTML: null,
																templateJSON: null,
																modifiedBy: localStorage.uid,
																modifiedDate: window.firebase.firestore.Timestamp.now(),
																active: window.firebase.firestore.FieldValue.delete(),
															}, { merge: true });
															toast.notify('Template deleted successfully', {
																duration: 2000
															})
														}
													}
												})
											}}><i className="ico icon-delete mr-1" style={{ fontSize: '11px' }}></i> <Translate text={'delete'} /></button> <button onClick={(e) => {
												e.preventDefault();
												if (selectedLocalize.templateJSON)
													setShowEdiorModel({ show: true, title: 'Edit Template', template: selectedLocalize })
												else
													setAddHtml({ show: true, title: 'Edit Template', template: selectedLocalize })

											}}
												type="button" className="btn btn-primary float-right ml-2"><i className="ico icon-edit mr-1" style={{ fontSize: '11px' }}></i> <Translate text={'edit'} /></button></div>
										</div>
										<div className="gdpr-btn-box gdpr-flex-preview">
											<div
												dangerouslySetInnerHTML={{ __html: selectedLocalize.bodyHTML }}>
											</div>
										</div>

									</>
									) : (
										<div className="gdpr-btn-box gdpr-flex">
											<div className="gdpr-btn-info"><Translate text={'Design the content for your email.'} /></div>
											<div className="gdpr-btn-wrapper">
												<button type="button" className="btn btn-primary float-left" onClick={(e) => {
													e.preventDefault();
													setAddHtml({
														show: true,
														title: 'Add Template',
														template: null
													})
												}}> <i className="ico icon-add mr-2"></i> <Translate text={'Add Email HTML Template'} /> </button>
												<span><Translate text={'or'} upperCase={true} /></span>
												<button type="button" className="btn btn-secondary float-left ml-2" onClick={(e) => {
													e.preventDefault();
													setShowEdiorModel({
														show: true,
														title: 'Add Template',
														template: null
													})
												}}> <i className="ico icon-add mr-2"></i> <Translate text={'Custom Template Builder'} /> </button>
											</div>
										</div>
									)
								}


							</div>
						</div>
					</div>
				</div>

			</div>
			<PopUpModal show={showEdiorModel.show}>
				<TemplateEditor
					{...props}
					{...showEdiorModel}
					handleClose={(obj) => {
						if (obj) {
							setSelectedLocalize({
								...selectedLocalize,
								...obj
							})
							setLocalizeData(localizeData.map(rec => {
								return rec.code === selectedLocalize.code ? {
									...rec,
									...obj
								} : { ...rec }
							}))
							if (selectedLocalize?.code === 'Default') {
								setState({
									...state,
									...obj
								})
							}
							else {
								window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData/${selectedLocalize.code}`).set({
									...obj,
									modifiedBy: localStorage.uid,
									modifiedDate: window.firebase.firestore.Timestamp.now(),
									active: window.firebase.firestore.FieldValue.delete(),
								}, { merge: true });
							}
						}
						setShowEdiorModel({ show: false, title: '', template: null })
					}}
					formMentions={allMentions}
					dynamicOptions={dynamicOptions}
					specialLinks={specialLinks}
				/>
			</PopUpModal>
			<PopUpModal show={addHtml.show}>
				<AddTemplateHTML
					{...props}
					{...addHtml}
					handleClose={(email, subject) => {
						if (email) {
							setSelectedLocalize({
								...selectedLocalize,
								bodyHTML: email ? email : null,
								subject: subject ? subject : null,
							})
							setLocalizeData(localizeData.map(rec => {
								return rec.code === selectedLocalize.code ? {
									...rec,
									bodyHTML: email ? email : null,
									subject: subject ? subject : null,
								} : { ...rec }
							}))
							if (selectedLocalize?.code === 'Default') {
								setState({
									...state,
									bodyHTML: email ? email : null,
									subject: subject ? subject : null,
								})
							}
							else {
								window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData/${selectedLocalize.code}`).set({
									bodyHTML: email ? email : null,
									subject: subject ? subject : null,
									modifiedBy: localStorage.uid,
									modifiedDate: window.firebase.firestore.Timestamp.now(),
									active: window.firebase.firestore.FieldValue.delete(),
								}, { merge: true });
							}
						}
						setAddHtml({ show: false, title: '', template: null })
					}}
					dealersettings={props.dealersettings}
					formMentions={allMentions}
					dynamicOptions={dynamicOptions}
				/>
			</PopUpModal>
			<PopUpModal show={showAddLanguageModal.show}>
				<AddLanguage
					{...props}
					{...showAddLanguageModal}
					handleClose={(data, deleteData) => {
						setShowAddLanguageModel({
							show: false
						})
						if (data) {
							setLocalizeData(localizeData.some(a => a.code === data.code) ? [
								...localizeData.filter(a => deleteData ? a.code !== deleteData : true).map(rec => { return rec.code === data.code ? { ...rec, active: true } : { ...rec, active: false } }),
							] : [
								...localizeData.filter(a => (deleteData ? a.code !== deleteData : true) && a.code !== data.code).map(rec => { return { ...rec, active: false } }),
								{ ...data, active: true }
							])
							setSelectedLocalize(data);
						}
					}}
					dealersettings={props.dealersettings}
					dealer={props.dealersettings.client}
					path={`clients/${props.dealersettings.client.id}/gdprSettings/${props.dealersettings.client.id}/localizeData`}
				/>
			</PopUpModal>
		</>
	);
};

export default Rules;
