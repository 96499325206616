import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import NumberFormat from 'react-number-format';
import { InputText, ReactSelect, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { servicePartVM, levelOptions } from './viewModel'
import CommonHelper from '../../../../services/common';


const AddPart = (props) => {
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const convertVM = data => {
        let objToReturn = { ...data }
        if (objToReturn.level === 'oem') {
            objToReturn.levelID = objToReturn.oemID
        } else if (objToReturn.level === 'individual') {
            objToReturn.levelID = objToReturn.clientIDs
        }
        if (objToReturn.partCost.includes(currencySymbol)) {
            objToReturn.partCost = objToReturn.partCost.replace(currencySymbol, '')
        }

        return objToReturn
    }
    const [lookup, setLookup] = useState(props.lookup ? convertVM(props.lookup) : null)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const newLookup = !_.isEmpty(props.lookup) ? false : true;


    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, servicePartVM);
            setLookup(newlookupVM)
        }
    }, [])

    useEffect(() => {
        let brands = props.dealersettings.allMakeModels;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (lookup && lookup.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === lookup.make)[0]) ? makes.filter(m => m.value === lookup.make)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [lookup])



    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.dealersettings.settingName : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.dealersettings.oemID] : '',
                ['clientIDs']: ''
            });
        } else if (data.name === 'make') {
            setLookup({
                ...lookup,
                [data.name]: e ? e.value : null,
                model: ''
            });
        } else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.dealersettings.settingName : data.name === 'levelID' ? e.data.name : '',
                ['clientIDs']: e.clientIDs
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }

    const saveLookup = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};

        let lookupObjData = { ...lookup }
        if (_.isEmpty(lookupObjData['partName'])) {
            formIsValid = false;
            errors['partName'] = errorClass;
        }
        if (!lookupObjData['partCost']) {
            formIsValid = false;
            errors['partCost'] = errorClass;
        }
        if (!lookupObjData['partNumber']) {
            formIsValid = false;
            errors['partNumber'] = errorClass;
        }
        if (_.isEmpty(lookupObjData['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(lookupObjData.level) && lookupObjData.level !== 'oem' && _.isEmpty(lookupObjData['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)

        //Remove empty value from lookupObjData object
        for (var propName in lookupObjData) {
            if (lookupObjData[propName] === null || lookupObjData[propName] === undefined || lookupObjData[propName] === '' || lookupObjData[propName] === [] || (_.isObject(lookupObjData[propName]) && _.isEmpty(lookupObjData[propName]))) {
                delete lookupObjData[propName];
            }
        }


        let partObj = {
            partName: lookupObjData.partName ? lookupObjData.partName : '',
            partNumber: lookupObjData.partNumber ? lookupObjData.partNumber : '',
            partCost: lookupObjData.partCost ? lookupObjData.partCost : '',
            isDeleted: false,
            active: true,
            active: lookupObjData.active,
            addedBy: localStorage.uid,
            documentID: lookupObjData.documentID ? lookupObjData.documentID : `${props.dealersettings.oemID}-${lookupObjData.partNumber.replace(/\//g, '-')}`,
            make: lookup.make ? lookup.make : '',
            model: lookup.model ? lookup.model : ''
        }

        if (lookupObjData.level === 'oem') {
            partObj.level = lookupObjData.level
            partObj.oemID = props.dealersettings.oemID
        } else if (lookupObjData.level === 'individual') {
            partObj.level = lookupObjData.level
            partObj.clientIDs = lookupObjData.levelID
        }

        console.log('save part', partObj)
        await window.firebase.firestore().collection(`serviceParts`).doc(partObj.documentID).set(partObj, { merge: true })
        setLoader(false)
        if (newLookup) {
            props.handleClose(partObj, newLookup)
        } else {
            props.handleClose(partObj)
        }

        toast.notify((newLookup === true ? 'service part added successfully' : 'service part updated successfully'), {
            duration: 2000
        })
    }

    const onCurrencyChange = (e, name) => {
        setLookup({
            ...lookup,
            [name]: e.floatValue
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'partName'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'part name'}
                                        className={`form-control ${errorFields["partName"]}`}
                                        name="partName"
                                        onChange={handleOnChange}
                                        value={lookup.partName}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label> <Translate text={'make'} /></label>
                                    <ReactSelect
                                        options={makes}
                                        name={"make"}
                                        placeholder={'select make'}
                                        onChange={handleSelectChange}
                                        value={lookup.make}
                                        classNamePrefix={`${errorFields["make"]} basic-select`}
                                    // isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-6">
                                    <label> <Translate text={'model'} /></label>
                                    <ReactSelect
                                        options={models}
                                        name={"model"}
                                        placeholder={'select model'}
                                        onChange={handleSelectChange}
                                        value={lookup.model}
                                        classNamePrefix={`${errorFields["model"]} basic-select`}
                                    // isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-12">
                                    <label> <Translate text={'Part Cost'} /></label>
                                    <NumberFormat
                                        id={'partCost'}
                                        decimalScale={2}
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={currencySymbol}
                                        className={`form-control ${errorFields["partCost"]}`}
                                        onValueChange={(e) => {
                                            onCurrencyChange(e, 'partCost')
                                        }}
                                        value={lookup.partCost ? lookup.partCost : null}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label> <Translate text={'Part Number'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'part number'}
                                        className={`form-control ${errorFields["partNumber"]}`}
                                        name="partNumber"
                                        onChange={handleOnChange}
                                        value={lookup.partNumber}
                                    // disabled={disableEdit}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={`${!newLookup ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactMultiSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            classNamePrefix={`${!newLookup ? 'select-readonly' : ''}  ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left ${(newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? '' :
                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddPart;