/** LIBRARIES */
import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { OverlayTrigger, Tooltip, Card, Dropdown } from "react-bootstrap";
import moment from "moment";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import Swal from "sweetalert2";
import _ from "lodash";
import { collectionData } from "rxfire/firestore";
import { combineLatest, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { Modal } from "react-bootstrap";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
/** PROVIDERS */
import { LayoutConsumer } from "../../layout/provider";
import { StageProvider } from "../../pipeline/provider";
/** COMPONENTS */
import {
	PopUpModal,
	ReactSelect,
	LoaderOverlay,
	PopoverPanel,
	SidePanel,
	DropDownMenu,
	EvalScan, AntDatePicker, InputAutoNumber
} from "../../../components";
import Terms from "../../common/terms";
import LostApprovalNotes from "../../common/lostApprovalNotes";
import ReAuthenticate from "../../common/reAuthenticate";
import { CustomMenu, CustomToggle } from "../../../components/customdropdown";
import Translate from "../../../constants/translate";
import { default as _images } from "../../../images";
import QuickView from "../../pipeline/quickview";
import LinkEnquiryInfo from "../../pipeline/enquiryInfo";
import OwnedVehicleInfo from "../../contacts/owned/ownedVehicleInfo";
import OwnedEnquiryInfo from "../../contacts/owned/ownedEnquiryInfo";
import AddOwnedVehicle from "../../contacts/owned/addOwnedVehicle";
import AddOwnedEnquiryVehicle from "../../contacts/owned/addOwnedEnquiryVehicle";
import AddContact from "../../contacts/add";
import ContactInfo from "../../contacts/info";
import InviteAmsMe from "../../contacts/inviteME";
import AddTradeIn from "../../tradeIn/add";
import AutoGrab from "../../tradeIn/autograb";
import TradeInInfo from "../../tradeIn/tradeinInfo";
import SendTradeInLink from "../../tradeIn/sendLink";
import AddTestDrive from "../../testdrive/add";
import TestDriveInfo from "../../testdrive/testDriveInfo";
import QuotationInfo from "../../quote/info/quotationInfo";
import SelectVehicle from "../../quote/info/selectVehicle";
import AddCafe from "../../cafe/add";
import AddFinance from "../../finance/add";
import ViewFinance from "../../finance/list/viewFinance";
import CafeInfo from "../../cafe/cafeInfo";
import Requirements from "../../requirement/list";
import AddRequirement from "../../requirement/add";
import MultiRequirementSelect from "../requirements/multiRequirementSelect";
import Activity from "../details/activity";
import ActivityFinance from "../details/activityFinance";
import ActivityDynamic from "../details/activityDynamic"
import PipelineSwitch from "../pipelineSwitch";
import LostReasons from "../lostReasons";
import DeliveredOptions from "../deliverdOption";
import FilesCategory from "../../files/multiple";
import Files from "../../files";
import AddFile from "../../files/add";
import FileDocuments from "../../files/documents";
import Videos from "../../videos";
import AddVideo from "../../videos/add";
import WalkinInfo from "../walkins/walkinInfo";
import WalkinAdd from "../walkins/walkinAdd";
import LeadConvert from "../leadConvert";
import OEMLeadAllocation from "../oemLeadAllocation";
//import OEMLeadTransfer from '../oemLeadTransfer'
import LeadTransfer from "../leadTransfer";
import CommonHelper from "../../../services/common";
import enquiryHelper from "../../../services/enquiryHelper";
import ChatSidePanel from "../../chat/chatsidepanel";
import EnquiryFields from "../enquiryFields";
import EnquiryInputs from "../details/enquiryInputs";
import SendMail from "../details/sendMail";
import EventInfo from "../../pipeline/eventInfo";
import EventModal from "../../pipeline/eventModal";
import UserRatingInfo from "../../pipeline/userRatingInfo";
import PageNotFound from "../../../components/pagenotFound";
import MergeList from "../merge/enquiryList";
import FormFeedbackInfo from "../../pipeline/formFeedback";
import StockQuotesList from "../../stockQuote/list";
import TradeinCompletedList from "../../tradeinplus/list/completedList";
import TradeinLinkAppraisal from "../../tradeinplus/list/linkAppraisals";
import SubLeadsOptions from "../subLeads";
import EventsProInfo from "../../eventsProAttendees/eventCardInfo";
import ContactMergeInfo from "../../contacts/contactInfo"
import CAPLeads from './existinfCAPLeads'
import ProductAddOns from '../addons/list'
import ProductInfo from '../addons/productInfo'
import CreditScore from '../quickview/creditScore'

/** VIEW-MODELS */
import {
	enquiryStatus,
	cafeStatus,
	stockStatus,
	tipStatus,
	enqUnActionStatus,
	defaultFinanceTerms,
} from "../../../services/enum";
import {
	objContact,
	objOwnedVehicle,
	objOwnedEnquiryVehicle,
	contactBasicDetailsVM,
} from "../../contacts/viewModel";
import { objTradeIn } from "../../tradeIn/viewModel";
import { objTestDrive } from "../../testdrive/viewModel";
import { objCafe } from "../../cafe/viewModel";
import {
	objEnquiry,
	enquiryBasicDetailsVM,
	objActivitylog,
	enquiryBasicTDVM,
	objModules,
	objWalkIn,
} from "../viewModel";
import { objStock, stockBasicVM } from "../../stock/viewModel";
import { objRequirement } from "../../requirement/viewModel";
//import NumberFormat from 'react-number-format';
import FinanceInfo from "../../finance/financeInfo";
import AddProvider from "../../finance/addProvider";
import { object } from "@amcharts/amcharts4/core";
import AddDrivaInfo from "../../driva/driva";
import DrivaInfo from "../../driva/drivaInfo";
import DrivaPreview from "../../driva/drivaPreview";
import ScheduleContact from "../../settings/workflow/tvDevices/scheduleContact";
import ScheduleList from "../../settings/workflow/tvDevices/scheduleListPopup";
import { firestoreDB } from "../../../services/helper";
import AddActivity from "../../activity";
import AddCallNotes from "./addCallNotes";
import { sassFalse } from "sass";

//#region  ASSIGN VARIABLES
let titles = [];
let enquiryTypes = [];
let serviceTypes = [];
let leadSources = [];
let enquiryOptions = [];
let enquiryOptionsDF = [];
let enquiryOptionsStatic = [];
let financeStatuses = [];
let afterMarketStatuses = [];
let campaigns = [];
let paymentMethods = [];
let enquiryAllStatus = [];
let labels = [];

// const labels = [
// 	{ active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
// 	{ active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
// 	{ active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]

const __financestatus = [
	{
		active: true,
		value: "InProgress",
		label: (
			<div className={`badge badge-pill badge-status-inprogress`}>
				<Translate text={"InProgress"} upperCase={true} />
			</div>
		),
	},
	{
		active: true,
		value: "Pending",
		label: (
			<div className={`badge badge-pill badge-status-pending`}>
				<Translate text={"Pending"} upperCase={true} />
			</div>
		),
	},
	{
		active: true,
		value: "Won",
		label: (
			<div className={`badge badge-pill badge-status-won`}>
				<Translate text={"won"} upperCase={true} />
			</div>
		),
	},
	{
		active: true,
		value: "Lost",
		label: (
			<div className={`badge badge-pill badge-status-lost`}>
				<Translate text={"lost"} upperCase={true} />
			</div>
		),
	},
];

const fieldsData = {
	contact: "",
	businessContact: "",
	requirement: "",
	enquiry: "",
	company: "",
};

let enquirySortOptions = [
	"enquiryType",
	"origin",
	"campaign",
	"label",
	"purchaseIntention",
	"deliveryDate",
	"chassisNo",
	"regNo",
	"stockNo",
	"warrantyStartDate",
	"warrantyExpiry",
	"lostReason",
	"lostSubReason",
	"isFinanceRequired",
	"financeStatus",
	"depositAmount",
	"depositReciptNumber",
	"grossAmount",
	"contractNumber",
	"leadSource",
];
//#endregion

class EnquiryInfo extends Component {
	// _isMounted = false;
	constructor(props) {
		super(props);
		this.refStock = window.firebase.firestore().collection("stock");
		this.refEvents = window.firebase.firestore().collection("fusionevents");
		this.newID = firestoreDB(this.props.dealersettings)
			.firestore()
			.collection("enquiries")
			.doc().id;
		this.unsubscribeEnquiryDet = null;
		this.unsubscribeContact = null;
		this.unsubscribeRequirements = null;
		this.unsubscribeWalkins = null;
		this.unsubscribeTradeIn = null;
		this.unsubscribeTestDrives = null;
		this.unsubscribeCafeOrder = null;
		this.unsubscribeQuotations = null;
		this.unsubscribeLinkEnquiry = null;
		this.unsubscribeEnquiryOwned = null;
		this.unsubscribeproductAddons = null;
		this.unsubscribeFiles = null;
		this.unsubscribeOwned = null;
		this.unsubscribeAmsMe = null;
		this.mandatoryFields = [];
		this.mandatoryFieldsForTestDrive = [];
		this.mandatoryFieldsForFinance = [];
		this.mandatoryFieldsForVideo = [];
		this.state = {
			loading: false,
			activeLeftKey: "0",
			activeRightKey: "00",
			fields: Object.assign({}, objEnquiry),
			contact: Object.assign({}, objContact),
			requirement: Object.assign({}, objRequirement),
			stock: Object.assign({}, objStock),
			requirements: Object.assign([]),
			linkEnquiries: Object.assign([]),
			walkins: Object.assign([]),
			events: Object.assign([]),
			drivaQuotes: Object.assign([]),
			usersRating: Object.assign([]),
			formFeedback: Object.assign([]),
			tradeIns: Object.assign([]),
			testdrives: Object.assign([]),
			productAddOns: Object.assign([]),
			files: Object.assign([]),
			cafeOrders: Object.assign([]),
			ownedVehicles: Object.assign([]),
			ownedEnquiryVehicles: Object.assign([]),
			dealersettings: props.dealersettings,
			amsmeUser: {},
			modules: Object.assign({}, objModules),
			enquiryID: null,
			_clientID: null,
			activeTab: "sales",
			errors: {},
			pipelines: [],
			origins: [],
			lostReasons: [],
			lostSubReasons: [],
			pipelineUsers: [],
			allClientUsers: [],
			allFinanceUsers: [],
			allAfterMarketUsers: [],
			rottenDays: 0,
			enquiryInfo: {
				new: false,
				lead: false,
			},
			contactModal: {
				show: false,
				showSearch: false,
				title: "",
				clsActive: "",
				mandatoryFields: null,
			},
			eventModal: {
				show: false,
				ID: "",
				data: null,
				title: "",
				clsActive: "",
			},
			tradeInModal: {
				show: false,
				tradeinID: "",
				title: "",
				clsActive: "",
			},
			autoGrabModal: {
				show: false,
				tradeinID: "",
				selectedVehicle: {},
				title: "",
				clsActive: "",
			},
			testDriveModal: {
				show: false,
				testDriveID: "",
				title: "",
				clsActive: "",
			},
			cafeOrderModal: {
				show: false,
				cafeOrderID: "",
				title: "",
				clsCafeOrderActive: "",
			},
			addFinanceModal: {
				show: false,
				showTerms: false,
				title: "",
				type: "",
				terms: false,
				termsSignature: null,
				termsAndConditions: null,
			},
			addDrivaFinanceModal: {
				show: false,
				preview: false,
				selectVehicle: false,
				selectedVehicle: {},
				previewObj: {},
				title: "",
				documentID: null,
				financeDetail: null,
			},
			addProviderModal: {
				show: false,
			},
			viewFinanceModal: {
				show: false,
				title: "",
			},
			requirementModal: {
				show: false,
				Id: "",
				title: "",
				clsActive: "",
				requirement: null,
				mandatoryFields: null,
			},
			requirementMultiModal: {
				show: false,
				Id: "",
				title: "",
				clsActive: "",
				nxtStatus: null,
			},
			ownedModal: {
				show: false,
				ID: "",
				title: "",
				clsActive: "",
			},
			ownedEnquiryModal: {
				show: false,
				ID: "",
				data: null,
				title: "",
				clsActive: "",
			},
			showpanel: {
				clsActive: "",
				clsName: "sidebar-quickview",
				enquiryid: 0,
			},
			showSharePanel: {
				chatClsActive: "",
				chatClsName: "sidebar",
			},
			fileModal: {
				show: false,
				category: null,
				type: null,
				categories: null
			},
			videoModal: {
				show: false,
			},
			deliveryModal: {
				show: false,
			},
			lostModal: {
				show: false,
			},
			walkinModal: {
				show: false,
				editID: null,
			},
			selectVehicleModal: {
				show: false,
				isFinance: false,
			},
			enquiryModal: {
				show: false,
				errors: null,
				mandatoryFields: null,
				newStatus: null,
				newStatusValue: null,
				ismodifyStatusDate: false,
				isModifyInboundOwner: false,
			},
			pipelinePopOver: { showpopover: false, targetpopover: null },
			lostApprovalPopOver: { showpopover: false, targetpopover: null },
			filesPopOver: {
				show: false,
				category: null,
				type: null,
				categories: null,
				categoryName: null,
				targetpopover: null
			},
			defaulStages: [],
			defaultStatus: [],
			statusSettings: [],
			defaultMakes: [],
			activeStageIndex: 0,
			isReqChange: false,
			enquiryBasicVM: {},
			showLeadConvert: false,
			showOEMLeadConvert: false,
			showOEMLeadConvertPrev: false,
			showLeadTransfer: false,
			convertLeadLoader: false,
			financeDetails: {},
			quotations: [],
			loaderErapower: false,
			isEmptyPipeline: false,
			showNewLeadModal: false,
			showReAuthentication: false,
			showPipelineOnOpen: false,
			showPipelineModal: false,
			isAccessToReOpen: false,
			pagenotFound: false,
			showMergeEnqList: false,
			showNewLeadActivity: false,
			sendLinkModal: {
				show: false,
				tradeinID: null,
			},
			linkAppraisalModal: false,
			stockQuoteModal: {
				show: false,
				requirement: "",
			},
			scheduleContact: {
				show: false,
				title: "",
			},
			scheduleList: {
				show: false,
				title: "",
			},
			scheduleData: {},
			completedTradeinsList: {
				show: false,
				isMandatory: false,
				isReopen: false
			},
			loaderReserveVehicle: false,
			subLeadModal: {
				show: false,
				enquiries: null,
				mandatoryFields: null,
				soldIDs: null,
				status: null,
				nxtStatus: null,
				checkPrimary: null,
			},
			showEvalScan: false,
			showContactInfo: {
				show: false,
				data: null
			},
			showCAPLeads: {
				show: false
			},
			capInfo: {},
			showAddOns: false,
			productEditID: null,
			showAddFile: false,
			notesModel: {
				show: false,
				//type: null
			},
			loaderCreditScore: false
		};
	}

	//#region SHARE CHANGES
	handleSharePanelOpen = (e) => {
		e.preventDefault();
		localStorage.removeItem("showChat");
		this.setState({
			showSharePanel: {
				chatClsActive: "active",
				chatClsName: "sidebar",
			},
		});
	};

	handleSharePanelClose = () => {
		this.setState({
			showSharePanel: {
				chatClsActive: "",
				chatClsName: "sidebar",
			},
		});
	};
	//#endregion

	//#region LOG NOTE CHANGES
	handleLogNoteOpen = (_logType) => {
		let _type = _logType.split('_')[0];
		let _title = CommonHelper.capitalizeString(`Add ${_type.replace('log', ' Note')}`)
		this.setState({
			notesModel: {
				show: true,
				title: _title,
				customerlog: {
					type: _type,
					subType: _logType.split('_')[1],
					notes: "",
					mentions: [],
				},
			}
		})
	}
	handleLogNoteClose = (log, mentions) => {
		if (log?.notes) {
			const { fields } = this.state;
			const { dealersettings } = this.props;
			console.log('handleLogNoteClose', log, mentions)
			//mentions: mentions?.length > 0 ? mentions.map(r => { return r.id }) : []
			CommonHelper.saveActivityLog({ 'enquiry': fields }, log?.notes, log.type, log.subType, mentions, dealersettings);
		}
		this.setState({ notesModel: { show: false } })

	}
	//#endregion

	//#region FILE CHANGES

	handleFileClose = (ID) => {
		if (ID) {
			let state = Object.assign({}, this.state.fields);
			if (!state.isFiles) {
				state.isFiles = true;
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();

				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
				updateRef.update({
					isFiles: state.isFiles,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: "web",
				}).then((docRef) => {
					this.setState({ fields: state });
				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
			}
		}

		this.setState({
			fileModal: {
				show: false,
				category: null,
				type: null,
				categories: null
			},
			filesPopOver: {
				show: false,
				category: null,
				categoryName: null,
				type: null,
				categories: null,
				targetpopover: null
			},
			showAddFile: false,
		});
	};

	handleFileOpen = (e, category, type, categories, categoryName) => {
		if (this.state.fields.isContact) {
			if (e)
				this.setState({ filesPopOver: { show: true, category, type, categories, categoryName, targetpopover: e.target } });
			else
				this.setState({ fileModal: { show: true, category, type, categories } });

			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			return false;
		}
	};

	isValidForFile = () => {
		if (this.state.fields.isContact) {
			this.setState({ showAddFile: true });
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			return false;
		}
	};
	//#endregion

	//#region VIDEO CHANGES

	handleVideoClose = (ID) => {
		if (ID) {
			let state = Object.assign({}, this.state.fields);
			if (!state.isVideo) {
				state.isVideo = true;
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();

				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
				updateRef.update({
					isVideo: state.isVideo,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: "web",
				}).then((docRef) => {
					this.setState({ fields: state });
				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
			}
		}

		this.setState({
			videoModal: {
				show: false,
			},
		});
	};

	handleVideoOpen = () => {
		this.setState({
			videoModal: {
				show: true,
			},
		});
	};

	isValidForVideo = () => {
		const { fields, contact } = this.state;
		if (fields.isContact) {
			if (!_.isEmpty(this.mandatoryFieldsForVideo)) {
				var msg = "";
				const ObjCustomer = Object.assign({}, contact);

				this.mandatoryFieldsForVideo.some((el, index) => {
					if (el.trim() === "licenseFrontURL") {
						if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
							msg = "Please upload license front image";
							return true;
						}
					} else if (el.trim() === "licenseBackURL") {
						if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
							msg = "Please upload license back image";
							return true;
						}
					} else if (el.trim() === "phoneORemail") {
						if (!ObjCustomer["phone"] && !ObjCustomer["email"]) {
							msg = "Please enter Phone or Email";
							return true;
						}
					} else if (el.trim() === "phoneORemailORlicenseNo") {
						if (!ObjCustomer["phone"] && !ObjCustomer["email"] && !ObjCustomer["licenseNo"]) {
							msg = "Please enter Phone or Email or License No.";
							return true;
						}
					} else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
						msg = `${CommonHelper.showLocale(this.props, "Please enter")} ${CommonHelper.showLocale(this.props, el)}`;
						return true;
					}
				});

				if (msg) {
					Swal.fire({
						title: CommonHelper.showLocale(this.props, msg),
						icon: "info",
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
						cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
					}).then((result) => {
						if (result.value) {
							this.handleContactOpen("editContact", this.mandatoryFieldsForVideo);
						}
					});
					return false;
				} else {
					this.handleVideoOpen();
					return true;
				}
			} else {
				this.handleVideoOpen();
				return true;
			}
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact", this.mandatoryFieldsForVideo);
				}
			});
			return false;
		}
	};

	//#endregion

	//#region FUNCTIONS

	//#region REQUIREMENT CHANGES

	isValidForRequirement = async () => {
		const { dealersettings } = this.props;
		const enableServicePackages = !_.isEmpty(dealersettings) && dealersettings.enableServicePackages ? true : false;

		if (!this.state.fields.isContact && enableServicePackages) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			return false;
		} else {
			const { pipeline, stage } = this.state.fields;
			if (await this.isValidForStageUpdate(pipeline, stage, "requirement")) {
				this.setState({
					requirementModal: {
						show: true,
						title: "requirement",
						clsActive: "overlay-modal active",
					},
				});
			}
		}
	};

	handleChangeStockStatus = (stockID, status, deliveryDate) => {
		let modifiedBy = (this.props.dealersettings.id || localStorage.uid);
		let modifiedDate = window.firebase.firestore.Timestamp.now();
		const { fields } = this.state;

		let objData = {
			modifiedBy: modifiedBy,
			modifiedDate: modifiedDate,
			modifiedFrom: "web",
		};

		if (status) {
			objData.status = status;
			objData.statusModifiedBy = modifiedBy;
			objData.statusModifiedFrom = "web";
		}

		if (deliveryDate) {
			objData.deliveryDate = deliveryDate;
		}

		if (status === stockStatus.SOLD || status === stockStatus.DELIVERED) {
			objData.enquiryID = this.state.enquiryID ? this.state.enquiryID : null;

			if (status === stockStatus.SOLD) objData.soldDate = modifiedDate;

			if (status === stockStatus.DELIVERED) {
				objData.deliveredDate = modifiedDate;
				objData.warrantyStartDate = modifiedDate;
			}

			if (fields.deliveryDate && !deliveryDate)
				objData.deliveryDate = fields.deliveryDate;
		}

		if (status && status !== stockStatus.SOLD && status !== stockStatus.DELIVERED) {
			objData.soldDate = null;
			objData.deliveredDate = null;
		}

		const refStockData = window.firebase.firestore().collection("stock").doc(stockID);
		refStockData.set(objData, { merge: true }).catch((error) => {
			console.error("Error updating handleChangeStockStatus: ", error);

		});
	};

	handleSoldRequirements = (requirementID, isSold, setAsPrimary) => {
		const { dealersettings } = this.props;
		const { fields, requirements } = this.state;
		const _requirement = Object.assign({}, requirements.filter((e) => e.documentID === requirementID)[0]);

		_requirement.isSold = isSold;
		_requirement.modifiedBy = dealersettings ? dealersettings.id : "";
		_requirement.modifiedDate = window.firebase.firestore.Timestamp.now();

		const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${this.state.enquiryID}/requirements`).doc(requirementID);
		refReqData
			.update({
				isSold: _requirement.isSold,
				modifiedBy: _requirement.modifiedBy,
				modifiedDate: _requirement.modifiedDate,
				modifiedFrom: "web",
			})
			.then((docRef) => {
				//console.log(docRef);

				if (!_.isEmpty(_requirement.stock))
					this.handleChangeStockStatus(
						_requirement.stock.documentID,
						isSold ? stockStatus.SOLD : stockStatus.AVAILABLE
					);

				if (setAsPrimary && setAsPrimary === true)
					this.handleReqMarkAsPrimary(_requirement);
				// else if (fields?.requirement?.documentID === requirementID)
				// 	this.handleReqMarkAsPrimary(_requirement, true);
			})
			.catch((error) => {
				console.error("Error updating handleSoldRequirements: ", error);
			});
	};

	handleCheckSubLeads = async (soldIDs) => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, "Are you sure?"),
			text: CommonHelper.showLocale(this.props, `Do you want to create sub Leads/Enquiries for each sold vehicle`),
			icon: "info",
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
			cancelButtonText: CommonHelper.showLocale(this.props, "No"),
			allowOutsideClick: false,
			allowEscapeKey: false,
		}).then((result) => {
			if (result.value) this.handleCreateSubLeads(soldIDs, null, null, true);
		});
	};

	handleCheckRequirementSubLead = async (requirementID, isSold) => {
		const { linkID } = this.state.fields;
		const enableSubLeadsonWon = this.props.dealersettings?.client?.clientSettings?.enableSubLeadsonWon ? true : false;
		if (enableSubLeadsonWon && isSold && linkID) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Are you sure?"),
				text: CommonHelper.showLocale(this.props, `Do you want to create sub Lead/Enquiry for this vehicle`),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
				cancelButtonText: CommonHelper.showLocale(this.props, "No"),
				allowOutsideClick: false,
				allowEscapeKey: false,
			}).then((result) => {
				if (result.value) this.handleCreateSubLeads([requirementID]);
				else {
					this.handleSoldRequirements(requirementID, isSold, false);
					toast.notify("Requirement updated successfully.", {
						duration: 2000,
					});
				}
			});
		} else {
			this.handleSoldRequirements(requirementID, isSold, false);
			toast.notify("Requirement updated successfully.", {
				duration: 2000,
			});
		}
	};

	handleCreateSubLeads = async (soldIDs, _status, nxtStatus, checkPrimary) => {
		const { fields, requirements, statusSettings, linkEnquiries } = this.state;

		var _excluded = [
			"chassisNo",
			"regNo",
			"stockNo",
			"warrantyStartDate",
			"warrantyExpiry",
		];
		let _mandatoryFields = this.getMandatoryFieldsByType("enquiry", enquiryStatus.WON);
		let _requiredFields = _.filter(_mandatoryFields, (v) => _excluded.indexOf(v) < 0);

		if (!_.isEmpty(_requiredFields)) {
			// GENERATING TEMPERORY ENQUIRIES DATA
			let _enquiries = await enquiryHelper.generateSubLeadsData(
				this.props.dealersettings,
				fields,
				requirements,
				soldIDs,
				checkPrimary,
				_status,
				nxtStatus,
				statusSettings,
				linkEnquiries.length
			);
			// CHECKING IF ENQUIRIES DATA HAS ANY MANADATORY (EMPTY FIELDS)
			let errors = enquiryHelper.getMandatoryErrors(_enquiries.filter((e) => !e.isPrimary), _requiredFields, enquiryOptionsDF);

			// IF HAS MANDATORY, FILLING THE MANDATORY (EMPTY FIELDS)
			if (!_.isEmpty(errors)) {
				this.setState({
					subLeadModal: {
						show: true,
						enquiries: _enquiries,
						mandatoryFields: _requiredFields,
						soldIDs: soldIDs,
						status: _status,
						nxtStatus: nxtStatus,
						checkPrimary: checkPrimary,
					},
				});
			} else {
				// DIRECTLY SAVING SUB-LEADS
				this.handleSaveSubLeads(soldIDs, _status, nxtStatus, checkPrimary);
			}

			//console.log('_enquiries,-', _enquiries)
		} else {
			// DIRECTLY SAVING SUB-LEADS, IF HAS NO MANDATORY,
			this.handleSaveSubLeads(soldIDs, _status, nxtStatus, checkPrimary);
		}
	};

	handleSaveSubLeads = (soldIDs, _status, nxtStatus, checkPrimary) => {
		const { fields, requirements, statusSettings, linkEnquiries } = this.state;

		enquiryHelper.handleCreateSubLeads(this.props.dealersettings, fields, requirements, soldIDs, checkPrimary, _status, nxtStatus, statusSettings, linkEnquiries.length)
			.then(() => {
				if (checkPrimary && _status) {
					let objNav = Object.assign({}, { id: this.state.enquiryID, status: _status, name: CommonHelper.displayName(fields), });
					this.props.updatenavbar(objNav);
				}

				if (_status) this.checkTradeinCompleted(_status);
			});
	};

	handleCreateSubLeadsClose = (enquiries) => {
		const subLeadModal = Object.assign({}, this.state.subLeadModal);
		if (subLeadModal && !_.isEmpty(enquiries)) {
			const { fields } = this.state;
			if (subLeadModal.checkPrimary && subLeadModal.status) {
				let objNav = Object.assign({}, { id: this.state.enquiryID, status: subLeadModal.status, name: CommonHelper.displayName(fields), });
				this.props.updatenavbar(objNav);
			}

			if (subLeadModal.status) this.checkTradeinCompleted(subLeadModal.status);
		}
		this.setState({
			subLeadModal: {
				show: false,
				enquiries: null,
				mandatoryFields: null,
				soldIDs: null,
				status: null,
				nxtStatus: null,
				checkPrimary: null,
			},
		});
	};

	handleMultiRequirementClose = async (soldIDs) => {
		//console.log('handleMultiRequirementClose', soldIDs);
		let nxtStatus = this.state.requirementMultiModal.nxtStatus;
		this.setState({
			requirementMultiModal: {
				show: false,
				Id: "",
				title: "",
				clsActive: "",
				nxtStatus: null,
			},
		});
		const { isAfterSales } = this.state.fields;
		const enableSubLeadsonWon = this.props.dealersettings?.client?.clientSettings?.enableSubLeadsonWon ? true : false;
		if (enableSubLeadsonWon && !isAfterSales && !_.isEmpty(soldIDs) && Object.keys(soldIDs).length > 1) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Do you want to create sub Leads/Enquiries for each selected vehicle?"),
				//text: `You want to split enquiry.`,
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
				cancelButtonText: CommonHelper.showLocale(this.props, "No"),
				allowOutsideClick: false,
				allowEscapeKey: false,
			}).then((result) => {
				if (result.value)
					this.handleCreateSubLeads(soldIDs, enquiryStatus.WON, nxtStatus, true);
				else this.saveSoldRequirements(soldIDs, nxtStatus);
			});
		} else {
			this.saveSoldRequirements(soldIDs, nxtStatus);
		}
	};

	saveSoldRequirements = async (soldIDs, nxtStatus) => {
		if (!_.isEmpty(soldIDs) && Object.keys(soldIDs).length > 0) {
			const { requirement } = this.state.fields;
			let primaryReqID = !_.isEmpty(requirement) ? requirement.documentID : "";
			let primaryID = "";

			//IF NO PRIMARY VEHICLE SELECTED, MARK FIRST ONE AS PRIMARY
			if (soldIDs.indexOf(primaryReqID) < 0) {
				primaryID = soldIDs[0];
			}

			soldIDs.forEach((requirementID) => {
				this.handleSoldRequirements(requirementID, true, primaryID && primaryID === requirementID ? true : false);
			});
			// await this.saveEnquiryStatus(enquiryStatus.WON);
			// if (nxtStatus) this.saveEnquiryStatus(nxtStatus);

			await this.saveEnquiryField("status", enquiryStatus.WON);
			if (nxtStatus) this.saveEnquiryField("status", nxtStatus);
		}
	};

	handleRequirentsForWon = async (status, nextStatus) => {
		const { requirements } = this.state;
		const { isVehicle } = this.state.fields;

		//FOR SINGLE REQUIREMENT
		if (isVehicle && !_.isEmpty(requirements) && requirements.length === 1) {
			const requirement = Object.assign({}, this.state.requirements[0]);

			if (!_.isEmpty(requirement.stock)) {
				if (
					requirement.stock.status === stockStatus.AVAILABLE ||
					requirement.stock.status === stockStatus.MCRETAILED ||
					requirement.stock.status === stockStatus.INPRODUCTION ||
					requirement.stock.status === stockStatus.UNAVAILABLE
				) {
					if (requirement.stock.isServiceLoan) {
						Swal.fire(CommonHelper.showLocale(this.props, "This vehicle has been reserved for service loan only"), "", "info");
						return false;
					}

					this.handleSoldRequirements(requirement.documentID, true, false);
					await this.saveEnquiryStatus(enquiryStatus.WON);
					if (nextStatus) this.saveEnquiryStatus(nextStatus);
				} else {
					Swal.fire(CommonHelper.showLocale(this.props, "Stock is not available"), "", "info");
					return false;
				}
			} else {
				this.handleSoldRequirements(requirement.documentID, true, false);
				await this.saveEnquiryStatus(enquiryStatus.WON);
				if (nextStatus) this.saveEnquiryStatus(nextStatus);
			}
		} //FOR MULTIPLE REQUIREMENTS, SHOW MULTI SELECT OPTIONS TO WON
		else if (isVehicle && !_.isEmpty(requirements) && requirements.length > 1) {
			this.setState({
				requirementMultiModal: {
					show: true,
					title: "chooseVehicles",
					clsActive: "overlay-modal active",
					nxtStatus: nextStatus,
				},
			});
		}
	};

	handleReqMarkAsPrimary = (req, noToast) => {
		if (!_.isEmpty(req)) {
			let state = Object.assign({}, this.state.fields);
			state.requirement = req;
			state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			state.modifiedDate = window.firebase.firestore.Timestamp.now();

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
			updateRef.update({
				requirement: state.requirement,
				modifiedBy: state.modifiedBy,
				modifiedDate: state.modifiedDate,
				modifiedFrom: "web",
			}).then((docRef) => {
				if (!noToast)
					toast.notify("Requirement updated successfully.", {
						duration: 2000,
					});
				this.setState({ fields: state, requirement: state.requirement });
			}).catch((error) => {
				console.error("Error updating enquiries: ", error);
			});
		}
	};

	decrementQuantity = (requirementID) => {
		let requirements = Object.assign([], this.state.requirements);

		let requirement = Object.assign({}, requirements.filter((e) => e.documentID === requirementID)[0]);
		let quantity = 1;
		if (requirement.quantity && requirement.quantity > 1)
			quantity = parseInt(requirement.quantity) - 1;

		let modifiedBy = (this.props.dealersettings.id || localStorage.uid);
		let modifiedDate = window.firebase.firestore.Timestamp.now();

		requirements.filter(function (obj) {
			if (obj.documentID === requirementID) {
				obj.quantity = quantity;
				return true;
			}
			return false;
		});

		this.setState({ requirements: requirements });

		const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.state.enquiryID}/requirements`).doc(requirementID);
		refReqData.update({
			quantity: quantity,
			modifiedBy: modifiedBy,
			modifiedDate: modifiedDate,
			modifiedFrom: "web",
		})
			.catch((error) => {
				console.error("Error updating decrementQuantity: ", error);
			});
	};

	incrementQuantity = (requirementID) => {
		let requirements = Object.assign([], this.state.requirements);

		let requirement = Object.assign({}, requirements.filter((e) => e.documentID === requirementID)[0]);
		let quantity = (requirement.quantity ? parseInt(requirement.quantity) : 0) + 1;
		let modifiedBy = (this.props.dealersettings.id || localStorage.uid);
		let modifiedDate = window.firebase.firestore.Timestamp.now();

		requirements.filter(function (obj) {
			if (obj.documentID === requirementID) {
				obj.quantity = quantity;
				return true;
			}
			return false;
		});

		this.setState({ requirements: requirements });
		const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.state.enquiryID}/requirements`).doc(requirementID);
		refReqData.update({
			quantity: quantity,
			modifiedBy: modifiedBy,
			modifiedDate: modifiedDate,
			modifiedFrom: "web",
		})
			.catch((error) => {
				console.error("Error updating incrementQuantity: ", error);
			});
	};

	requirementSoldMark = (requirement, isSold) => {
		if (!_.isEmpty(requirement) && !_.isEmpty(requirement.stock)) {
			if (
				requirement.stock.status !== stockStatus.AVAILABLE &&
				requirement.stock.status !== stockStatus.MCRETAILED &&
				requirement.stock.status !== stockStatus.INPRODUCTION &&
				requirement.stock.status !== stockStatus.UNAVAILABLE &&
				isSold
			) {
				Swal.fire(CommonHelper.showLocale(this.props, "Stock is not available"), "", "info");
				return false;
			} else {
				if (requirement.stock.isServiceLoan) {
					Swal.fire(CommonHelper.showLocale(this.props, "This vehicle has been reserved for service loan only"), "", "info");
					return false;
				}

				this.handleCheckRequirementSubLead(requirement.documentID, isSold);
			}
		} else if (!_.isEmpty(requirement)) {
			this.handleCheckRequirementSubLead(requirement.documentID, isSold);
		}
	};

	deleteRequirement = (objData, requirementID) => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, "Are you sure?"),
			text: CommonHelper.showLocale(this.props, `You want to delete the requirement.`),
			icon: "info",
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
			cancelButtonText: CommonHelper.showLocale(this.props, "No"),
		}).then((result) => {
			if (result.value) {
				let modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				let modifiedDate = window.firebase.firestore.Timestamp.now();
				const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.state.enquiryID}/requirements`).doc(requirementID);
				refReqData.update({
					isDeleted: true,
					modifiedBy: modifiedBy,
					modifiedDate: modifiedDate,
					modifiedFrom: "web",
				}).then((docRef) => {
					//console.log(docRef);

					let _makeModel = objData
						? (objData.make ? objData.make + " " : "") +
						(objData.model ? objData.model + " " : "") +
						(objData.year ? objData.year : "")
						: "";
					let _logNote = "Requirement deleted " + (_makeModel ? " (" + _makeModel + ")" : "");
					CommonHelper.saveChangeLog(this.props.dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, "enquiry", "updated");

					this.handleDeleteOwnedVehicle(requirementID);
				}).catch((error) => {
					console.error("Error updating deleteRequirement: ", error);
				});
			}
		});
	};

	handleRequirementClose = () => {
		this.setState({
			requirementModal: {
				show: false,
				Id: "",
				title: "",
				clsActive: "",
				requirement: null,
				clone: null,
				mandatoryFields: null,
			},
		});
	};

	handleReqEdit = (req, isClone, mandatoryFields) => {
		this.setState({
			requirementModal: {
				show: true,
				title: isClone ? "cloneRequirement" : "requirement",
				clsActive: "overlay-modal active",
				Id: "",
				requirement: isClone ? null : req,
				clone: isClone ? req : null,
				mandatoryFields: mandatoryFields ? mandatoryFields : null,
			},
		});
	};
	//#endregion

	//#region CONTACT CHANGES

	handleMandatoryContactOpen = async (title, skipValidate) => {
		if ((skipValidate || title !== "addContact") ? true : await this.isValidEnquiryForAddContact()) {
			let _manFields = this.getMandatoryFieldsByType("contact");
			//console.log('handleMandatoryContactOpen', _manFields)
			this.setState({
				contactModal: {
					show: true,
					showSearch: true,
					title: title,
					clsActive: "overlay-modal active",
					mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
				},
			});
		}
	};

	isValidEnquiryForAddContact = async () => {
		let _manFields = this.getMandatoryFieldsByType("enquiry");
		console.log("_manFields,", _manFields);
		let _requiredFields = !_.isEmpty(_manFields) ? _.filter(_manFields, (v) => !v.startsWith("is") || v.startsWith("isFinanceRequired") || v.startsWith("isFav")) : null;
		if (!_.isEmpty(_requiredFields)) {
			let _objFields = Object.assign({}, { enquiry: _requiredFields.join(","), });
			return await this.handleMandatoryPermission(_objFields, "addContact");
		} else return true;
	};

	handleContactOpen = async (title, _manFields) => {
		//	console.log('handleContactOpen', _manFields)
		if (title === "addContact" ? await this.isValidEnquiryForAddContact() : true) {
			setTimeout(() => {
				this.setState({
					contactModal: {
						show: true,
						showSearch: true,
						title: title,
						clsActive: "overlay-modal active",
						mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
					},
				});
			}, 200);
		}
	};

	handleContactClose = (ID, data, isNew) => {
		if (data === "delete") {
			const { clientID, isAfterSales } = this.state.fields;
			const { enquiryID } = this.state;

			var objRecord = {
				id: enquiryID,
				clientid: clientID,
				routeID: ID,
			};
			this.props.handleRouteRemove(objRecord, isAfterSales ? "afterSales" : "enquiry");

			this.handleLayoutClose();

		} else if (ID) {
			//const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
			//refData.get().then((doc) => {
			if (data) {
				const objcontactData = Object.assign({}, data);
				const contact = Object.assign({}, objContact);
				const _contact = Object.assign({}, contactBasicDetailsVM);
				for (let [key, value] of Object.entries(objcontactData)) {
					contact[key] = value;
					if (_contact.hasOwnProperty(key)) _contact[key] = value;
				}
				contact.documentID = ID;
				if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
				if (_.isNil(objcontactData?.marketingOptinEmail))
					contact.marketingOptinEmail = null;
				if (_.isNil(objcontactData?.marketingOptinSMS))
					contact.marketingOptinSMS = null;
				let state = Object.assign({}, this.state.fields);
				state["isContact"] = true;
				state["contact"] = _contact;
				state.contactID = ID;

				if (state.isNewEnquiry) state.isNewEnquiry = false;

				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();
				state.modifiedFrom = "web";

				this.setState({ contact: contact, fields: state });
				let _isContact = state.isContact;
				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
				updateRef.update({
					isContact: true,
					contact: state.contact,
					contactID: state.contactID,
					isNewEnquiry: state.isNewEnquiry,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: "web",
					areaCode: objcontactData?.areaCode ? objcontactData.areaCode : null,
					prevPurchased: objcontactData?.prevPurchased
						? objcontactData.prevPurchased
						: null,
				}).then((docRef) => {
					const { isContact, status, displayID, documentID, tags } = state;

					if (state.contactID && isNew) {
						const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
						updateRepeatCustomer({
							contactID: state.contactID,
							projectId: state?.projectId ? state.projectId : null,
						});
					}

					if (isNew) {
						this.unsubscribeOwned = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${state.contactID}/ownedVehicles`).onSnapshot(this.onOwnedCollectionUpdate);

						if (!_.isEmpty(tags) && tags.some((m) => m.type === "fusionevents")) {
							firestoreDB(this.props.dealersettings).firestore().collection(`inboundLeads`)
								.where("enquiryID", "==", this.state.enquiryID)
								.where("systemType", "==", "fusionevents")
								.limit(1)
								.get().then((rec) => {
									if (rec.docs.length > 0) {
										let objInbound = rec.docs[0].data().request;
										let newEventVM = {};
										newEventVM.isCheckIn = objInbound.isCheckIn;
										newEventVM.checkinDate = objInbound.CheckedInTime;
										newEventVM.clientID = state.clientID;
										newEventVM.contactID = state.contactID;
										newEventVM.documentID = `${state.contactID}-${objInbound.EventId}`;
										newEventVM.eventCustomerID = objInbound.Id;
										newEventVM.eventDate = objInbound.EventStartDate;
										newEventVM.eventID = objInbound.EventId;
										newEventVM.location = objInbound.EventLocation;
										newEventVM.title = objInbound.EventTitle;
										Object.keys(newEventVM).forEach((key) => newEventVM[key] === undefined ? delete newEventVM[key] : {});
										window.firebase.firestore().doc(`fusionevents/${newEventVM.documentID}`).set(newEventVM, { merge: true }).catch((error) => { console.error(error); });
									}
								});
						}
					}

					this.props.updatenavbar({
						id: this.state.enquiryID,
						status: status && status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : status,
						name: CommonHelper.displayName(state),
						isNew: null,
						params: null,
					});

					this.setState({ enquiryInfo: { new: false, lead: false } });
				})
					.catch((error) => {
						console.error("Error updating enquiries: ", error);
					});
			} else {
				console.error("No such document!");
			}
			//});
		}

		this.setState({
			contactModal: {
				show: false,
				showSearch: false,
				title: "",
				clsActive: "",
			},
		});
	};
	//#endregion

	//#region TRADE-IN CHANGES
	isValidForTradeIn = async (isLink, isLinkAppraisal) => {
		if (this.state.fields.isContact) {
			const { pipeline, stage, isTradeIn } = this.state.fields;
			if (isTradeIn ? await this.isValidForStageUpdate(pipeline, stage, (isLink ? "tradeInLink" : (isLinkAppraisal ? "tradeInLinkAppraisal" : "tradeIn"))) : await this.isValidForStatusUpdate("isTradeIn")) {
				if (isLink) {
					this.setState({
						sendLinkModal: {
							show: true,
							tradeinID: null,
						},
					});
				} else if (isLinkAppraisal) {
					this.setState({ linkAppraisalModal: true })
				} else {
					this.setState({
						tradeInModal: {
							show: true,
							tradeinID: "",
							title: "addTradeIn",
							clsActive: "overlay-modal active",
						},
					});
				}
				return true;
			} else return false;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			// confirmAlert({
			// 	title: 'Please add contact',
			// 	buttons: [
			// 		{
			// 			label: 'Yes',
			// 			onClick: () => (this.handleContactOpen('addContact'))
			// 		}
			// 	]
			// });
			return false;
		}
	};

	handleReSendLink = (tradeinID) => {
		this.setState({
			sendLinkModal: {
				show: true,
				tradeinID: tradeinID,
			},
		});
	};

	handleCloseSendLink = (data, tradeinID) => {
		if (!_.isEmpty(data)) {
			toast.notify(
				<div style={{ fontSize: `14px` }}>
					<span
						className="spinner-border spinner-button  mr-2 spinner-border text-primary"
						role="status"
						aria-hidden="true"
					></span>
					{tradeinID ? "re-sending..." : "sending..."}
				</div>,
				{
					position: "top",
					duration: null,
				}
			);

			const objData = Object.assign({});
			const { fields, contact } = this.state;
			const { dealersettings } = this.props;

			if (!tradeinID) {
				objData.addedBy = (this.props.dealersettings.id || localStorage.uid);
				objData.addedDate = window.firebase.firestore.Timestamp.now();
				objData.addedFrom = "web";
				objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection("tradeins").doc().id;

				if (!_.isEmpty(contact)) {
					let _contact = Object.assign({}, contactBasicDetailsVM);
					for (let [key, value] of Object.entries(contact)) {
						if (_contact.hasOwnProperty(key)) _contact[key] = value;
					}
					objData.contact = _contact;
					objData.contactID = _contact.documentID;
				}

				if (!_.isEmpty(fields)) {
					let _enquiry = Object.assign({}, enquiryBasicTDVM);
					for (let [key, value] of Object.entries(fields)) {
						if (_enquiry.hasOwnProperty(key)) _enquiry[key] = value;
					}
					objData.enquiry = _enquiry;
					objData.enquiryID = _enquiry.documentID;
				}
			} else {
				objData.documentID = tradeinID;
			}

			objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = "web";
			objData.isDeleted = false;
			objData.clientID = fields.clientID ? fields.clientID : null;
			objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
			objData.linkURL = "tradein/customer/" + objData.documentID;

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("tradeins").doc(objData.documentID);
			updateRef.set(objData, { merge: true }).then((docRef) => {
				// toast.notify('Trade-In saved successfully', {
				// 	duration: 2000
				// })

				let _postObject = {
					documentID: objData.documentID,
					sendSMS: data.sendSMS ? true : false,
					phone: data.phone ? data.phone : null,
					phoneCode: data.phoneCode ? data.phoneCode : null,
					sendEmail: data.sendEmail ? true : false,
					email: data.email ? data.email : null,
					projectId: objData.projectId ? objData.projectId : null,
				};

				if (!_.isEmpty(data.emails)) {
					let _data = Array.prototype.map.call(data.emails.filter((item) => !_.isEmpty(item.value)), function (item) { return item.value ? item.value : ""; }).join(",");
					_postObject.ccEmails = _data;
				}

				//console.log("_postObject", _postObject);
				let _logNote = `Trade-In link ${tradeinID ? "re-sent" : "sent"}. ${data.phone ? "\nPhone: " + data.phone : ""
					} ${data.email ? "\nEmail: " + data.email : ""} ${data.ccEmails ? "\nCC Emails: " + data.ccEmails : ""
					}`;
				const tradeinSendLink = window.firebase.functions().httpsCallable("tradein-sendLink");
				tradeinSendLink(_postObject).then((data) => {
					toast.closeAll();
					if (data.data.success === true) {
						toast.notify(`Trade-In link ${tradeinID ? "re-send" : "send"} successfully.`, { duration: 2000, });
						if (objData.enquiryID)
							CommonHelper.saveChangeLog(objData.clientID, null, objData.enquiryID, _logNote, "tradein", "enquiry");
						// this.setState({
						// 	sendLinkModal: {
						// 		show: false,
						// 		tradeinID: null
						// 	}
						// })
					} else {
						Swal.fire(data.data.message, "", "error");
					}
				});
			})
				.catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
		}
		this.setState({
			sendLinkModal: {
				show: false,
				tradeinID: null,
			},
		});
	};

	handleTradeInClose = (tradeinID) => {
		let state = Object.assign({}, this.state.fields);
		if (tradeinID && !state.isTradeIn) {
			let objData = {};
			objData.isTradeIn = true;
			objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = "web";
			objData = this.handleMoveStageForStatusChange("isTradeIn", objData);

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
			updateRef.set(objData, { merge: true })
				.catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
		}

		this.setState({
			showEvalScan: false,
			tradeInModal: {
				show: false,
				tradeinID: "",
				title: "",
				clsActive: "",
			},
		});
	};

	handleAutograbClose = () => {

		this.setState({
			autoGrabModal: {
				show: false,
				tradeinID: "",
				title: "",
				clsActive: "",
			},
		});
	};

	handleEditTradeIn = (tradeinId, sent) => {
		this.setState({
			tradeInModal: {
				show: true,
				tradeinID: tradeinId,
				title: "tradeIn",
				clsActive: "overlay-modal active",
			},
		});
	};

	handleAutoGrap = (tradeinId, vehicle) => {
		this.setState({
			autoGrabModal: {
				show: true,
				tradeinID: tradeinId,
				selectedVehicle: vehicle,
				title: "Market Overlay",
				clsActive: "overlay-modal active",
			},
		});
	};

	//#endregion

	//#region TEST-DRIVE CHANGES

	isValidForTestDrive = () => {
		if (this.state.fields.isContact) {
			const ObjCustomer = Object.assign({}, this.state.contact);
			if (!_.isEmpty(this.mandatoryFieldsForTestDrive)) {
				var msg = "";
				this.mandatoryFieldsForTestDrive.some((el, index) => {
					if (el.trim() === "licenseFrontURL") {
						if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
							msg = "Please upload license front image";
							return true;
						}
					} else if (el.trim() === "licenseBackURL") {
						if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
							msg = "Please upload license back image";
							return true;
						}
					} else if (el.trim() === "phoneORemail") {
						if (!ObjCustomer["phone"] && !ObjCustomer["email"]) {
							msg = "Please enter Phone or Email";
							return true;
						}
					} else if (el.trim() === "phoneORemailORlicenseNo") {
						if (
							!ObjCustomer["phone"] &&
							!ObjCustomer["email"] &&
							!ObjCustomer["licenseNo"]
						) {
							msg = "Please enter Phone or Email or License No.";
							return true;
						}
					} else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
						msg = `${CommonHelper.showLocale(this.props, "Please enter")} ${CommonHelper.showLocale(this.props, el)}`;
						return true;
					}
				});

				if (msg) {
					Swal.fire({
						title: CommonHelper.showLocale(this.props, msg),
						icon: "info",
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
						cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
					}).then((result) => {
						if (result.value) {
							this.handleContactOpen("editContact", this.mandatoryFieldsForTestDrive);
						}
					});

					return false;
				} else {
					this.handleOpenTestDriveModal(ObjCustomer);
				}
			} else {
				this.handleOpenTestDriveModal(ObjCustomer);
			}
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact", this.mandatoryFieldsForTestDrive);
				}
			});
			return false;
		}
	};

	handleOpenTestDriveModal = async (ObjCustomer) => {
		if (ObjCustomer && ObjCustomer["licenseExpiry"] && moment()._d > moment(ObjCustomer["licenseExpiry"])._d) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "License is expired."),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Update Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("editContact", this.mandatoryFieldsForTestDrive);
				}
			});

			return false;
		} else {
			const { pipeline, stage, isTestDrive } = this.state.fields;

			if (isTestDrive ? await this.isValidForStageUpdate(pipeline, stage, "testDrive") : await this.isValidForStatusUpdate("isTestDrive")) {
				this.setState({
					testDriveModal: {
						show: true,
						testDriveID: "",
						title: "testDrive",
						clsActive: "overlay-modal active",
					},
				});
			} else return false;
		}
	};

	handleTestDriveClose = (testDriveID) => {
		let state = Object.assign({}, this.state.fields);
		if (testDriveID && !state.isTestDrive) {
			let objData = {};
			objData.isTestDrive = true;
			objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = "web";
			objData = this.handleMoveStageForStatusChange("isTestDrive", objData);

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
			updateRef.set(objData, { merge: true })
				.catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
		}

		this.setState({
			testDriveModal: {
				show: false,
				testDriveID: "",
				title: "",
				clsActive: "",
			},
		});
	};

	handleEditTestDrive = (testDriveID, status) => {
		this.setState({
			testDriveModal: {
				show: true,
				testDriveID: testDriveID,
				title: "testDrive",
				clsActive: "overlay-modal active",
			},
		});
	};

	//#endregion

	//#region CAFE CHANGES

	handleCafeClose = (cafeID) => {
		if (cafeID) {
			let state = Object.assign({}, this.state.fields);
			state.isCafe = true;
			state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			state.modifiedDate = window.firebase.firestore.Timestamp.now();

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID);
			updateRef.update({
				isCafe: state.isCafe,
				modifiedBy: state.modifiedBy,
				modifiedDate: state.modifiedDate,
				modifiedFrom: "web",
			}).then((docRef) => {
				this.setState({ fields: state });
			}).catch((error) => {
				console.error("Error updating enquiries: ", error);
			});
		}

		this.setState({
			cafeOrderModal: {
				show: false,
				cafeOrderID: "",
				title: "",
				clsCafeOrderActive: "",
			},
		});
	};

	handleOrderCancelled = (cafeID) => {
		if (cafeID) {
			let cafeOrders = Object.assign([], this.state.cafeOrders);

			cafeOrders.filter(function (obj) {
				if (obj.documentID === cafeID) {
					obj.status = cafeStatus.CANCELLED;
					return true;
				}
				return false;
			});

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("cafeOrders").doc(cafeID);
			updateRef.update({
				status: cafeStatus.CANCELLED,
				modifiedBy: (this.props.dealersettings.id || localStorage.uid),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				deliveredBy: (this.props.dealersettings.id || localStorage.uid),
				deliveredDate: window.firebase.firestore.Timestamp.now(),
				modifiedFrom: "web",
			}).then((docRef) => {
				toast.notify("Cafe order cancelled successfully", {
					duration: 2000,
				});
			}).catch((error) => {
				console.error("Error updating enquiries: ", error);
			});
		}
	};

	handleReOrder = (cafeID) => {
		if (cafeID) {
			let cafeOrders = Object.assign([], this.state.cafeOrders);
			let record = Object.assign({}, cafeOrders.filter((e) => e.documentID === cafeID)[0]);

			const objData = Object.assign({}, objCafe);
			let _date = moment()._d;
			if (_.isEmpty(objData.addedBy)) {
				objData.addedBy = (this.props.dealersettings.id || localStorage.uid);
				objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
			}
			objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);
			objData.modifiedFrom = "web";

			objData.orders = record.orders ? record.orders : [];
			objData.orderNotes = record.orderNotes ? record.orderNotes : null;
			objData.clientID = this.props.dealersettings ? this.props.dealersettings.client.id : "";
			objData.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;
			objData.enquiryID = this.state.enquiryID ? this.state.enquiryID : this.state.fields.documentID;
			objData.status = cafeStatus.PENDING;
			objData.orderNumber = "C-" + moment(_date).format("YYMMDD-HHmmss");

			for (var propName in objData) {
				if (objData[propName] === null || objData[propName] === undefined || objData[propName] === "" || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
					delete objData[propName];
				}
			}

			let newDocID = firestoreDB(this.props.dealersettings).firestore().collection("cafeOrders").doc().id;
			objData.documentID = newDocID;

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection("cafeOrders").doc(newDocID);
			updateRef.set(objData, { merge: true })
				.then((docRef) => {
					toast.notify("Cafe order placed successfully", {
						duration: 2000,
					});
				})
				.catch((error) => {
					console.error("Error updating contact_details: ", error);
				});
		}
	};
	//#endregion

	//#region FINANCE

	handleTermsClose = (data) => {
		if (!_.isEmpty(data)) {
			// let state = this.state.fields;
			// state.terms = data;
			this.setState({
				addFinanceModal: {
					showTerms: false,
					termsSignature: data.signature,
					termsAndConditions: data.termsAndConditions,
					show: true,
					title: "Add Finance Info",
					type: "add",
				},
			});
			// this.handleSelectedFile();
		} else {
			// if (!_.isEmpty(this.state.fileInfo) && this.state.fileInfo.id)
			// 		document.getElementById(this.state.fileInfo.id).value = "";
			// this.setState({ showTerms: false })
			this.setState({
				addFinanceModal: {
					showTerms: false,
				},
			});
		}
	};

	isValidForFinance = async () => {
		let state = Object.assign({}, this.state.fields);
		let _isbreak = false;

		["isContact", "isVehicle"].some((item) => {
			//console.log(item, this.mandatoryFieldsForFinance, state)
			if (item === "isContact") {
				if (!state[item]) {
					Swal.fire({
						title: CommonHelper.showLocale(this.props, "Please add contact"),
						icon: "info",
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
						cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
					}).then((result) => {
						if (result.value) {
							this.handleContactOpen(
								"addContact",
								this.mandatoryFieldsForFinance
							);
							_isbreak = true;
							return true;
						}
					});
					_isbreak = true;
					return true;
				} else {
					if (!_.isEmpty(this.mandatoryFieldsForFinance)) {
						var msg = "";
						const ObjCustomer = Object.assign({}, this.state.contact);

						this.mandatoryFieldsForFinance.some((el, index) => {
							if (el.trim() === "licenseFrontURL") {
								if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
									msg = "Please upload license front image";
									return true;
								}
							} else if (el.trim() === "licenseBackURL") {
								if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
									msg = "Please upload license back image";
									return true;
								}
							} else if (el.trim() === "phoneORemail") {
								if (!ObjCustomer["phone"] && !ObjCustomer["email"]) {
									msg = "Please enter Phone or Email";
									return true;
								}
							} else if (el.trim() === "phoneORemailORlicenseNo") {
								if (
									!ObjCustomer["phone"] &&
									!ObjCustomer["email"] &&
									!ObjCustomer["licenseNo"]
								) {
									msg = "Please enter Phone or Email or License No.";
									return true;
								}
							} else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
								msg = `${CommonHelper.showLocale(this.props, "Please enter")} ${CommonHelper.showLocale(this.props, el)}`;
								return true;
							}
						});

						if (msg) {
							Swal.fire({
								title: CommonHelper.showLocale(this.props, msg),
								icon: "info",
								showCancelButton: true,
								confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
								cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
							}).then((result) => {
								if (result.value) {
									this.handleContactOpen("editContact", this.mandatoryFieldsForFinance);
									_isbreak = true;
									return true;
								}
							});
							_isbreak = true;
							return true;
						}
					}
				}
			}

			if (item === "isVehicle") {
				if (!state[item]) {
					Swal.fire({
						title: CommonHelper.showLocale(this.props, "Please add requirement"),
						icon: "info",
						showCancelButton: true,
					}).then((result) => {
						if (result.value) {
							setTimeout(() => {
								this.setState({
									requirementModal: {
										show: true,
										title: "requirement",
										clsActive: "overlay-modal active",
									},
								});
							}, 200);

							_isbreak = true;
							return true;
						}
					});
					_isbreak = true;
					return true;
				}
			}
		});

		if (_isbreak) return false;
		else {
			if (await this.isValidForStatusUpdate("isFinance")) return true;
			else return false;
		}
	};

	handleAddFinanceOpen = () => {
		//console.log("handleAddFinanceOpen");
		if (
			!this.state.addFinanceModal.terms &&
			this.isValidForFinance() === true
		) {
			// RUN SIGNATURE TEST FUNCTION
			this.setState({
				addFinanceModal: {
					showTerms: true,
				},
			});
		}
	};

	handleAddFinanceClose = (documentID) => {
		if (documentID) {
			this.onFinanceCollectionUpdate();

			let state = Object.assign({}, this.state.fields);
			if (!state.isFinance) {
				let objData = {};
				objData.isFinance = true;
				objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				objData.modifiedDate = window.firebase.firestore.Timestamp.now();
				objData.modifiedFrom = "web";
				objData = this.handleMoveStageForStatusChange("isFinance", objData);

				const updateRef = firestoreDB(this.props.dealersettings)
					.firestore()
					.collection("enquiries")
					.doc(this.state.enquiryID);
				updateRef
					.set(objData, { merge: true })
					.then((docRef) => { })
					.catch((error) => {
						console.error("Error updating enquiries: ", error);
					});
			}
		}
		this.setState({
			addFinanceModal: {
				show: false,
				title: "",
				type: "",
			},
		});
	};

	handleAddDrivaFinanceOpen = () => {
		// check for
		// isValid: check contact and vehicle
		// check if it has one vehvicle or more than one
		// open the driva modal but with show:true and selectedVehicle,

		if (this.isValidForFinance() === true) {
			if (this.state.requirements.length > 1) {
				this.handleOpenSelectVehicleModal("isFinance");
			} else {
				let _requirement = "";
				if (this.state?.fields?.requirement?.documentID) {
					_requirement = this.state.fields.requirement;
				} else {
					_requirement = this.state.requirements[0];
				}
				if (!_.isEmpty(_requirement)) {
					this.setState({
						addDrivaFinanceModal: {
							show: true,
							selectedVehicle: _requirement,
						},
					});
				}
			}
		}
	};

	handleEditDrivaFinanceOpen = (financeDetail) => {
		if (this.isValidForFinance() === true) {
			this.setState({
				addDrivaFinanceModal: {
					show: true,
					selectedVehicle: this.state.requirements.filter(
						(req) => req.documentID === financeDetail?.requirement?.documentID
					).length
						? this.state.requirements.filter(
							(req) =>
								req.documentID === financeDetail?.requirement?.documentID
						)[0]
						: {},
					documentID: financeDetail.documentID,
					financeDetail,
				},
			});
		}
	};

	handleAddDrivaFinanceClose = (documentID) => {
		let state = Object.assign({}, this.state.fields);
		if (documentID === "saved" && !state.isFinance) {
			let objData = {};
			objData.isFinance = true;
			objData.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = "web";
			objData = this.handleMoveStageForStatusChange("isFinance", objData);

			const updateRef = firestoreDB(this.props.dealersettings)
				.firestore()
				.collection("enquiries")
				.doc(this.state.enquiryID);
			updateRef
				.set(objData, { merge: true })
				.then((docRef) => { })
				.catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
		}

		this.setState({
			addDrivaFinanceModal: {
				show: false,
				documentID: null,
				financeDetail: null,
			},
		});
		// if (obj) {
		// 	this.handleViewDrivaFinanceOpen(obj)
		// }
	};

	handleViewDrivaFinanceOpen = (data) => {
		this.setState({
			addDrivaFinanceModal: {
				preview: true,
				data,
			},
		});
	};

	handleViewDrivaFinanceClose = () => {
		this.setState({
			addDrivaFinanceModal: {
				preview: false,
			},
		});
	};

	handleAddProviderOpen = () => {
		this.setState({
			addProviderModal: {
				show: true,
			},
		});
	};

	handleAddProviderClose = () => {
		this.setState({
			addProviderModal: {
				show: false,
			},
		});
	};

	handleEditFinanceOpen = () => {
		this.setState({
			addFinanceModal: {
				show: true,
				title: "Edit Finance Info",
				type: "edit",
			},
		});
	};

	handleEditFinanceClose = () => {
		this.setState({
			addFinanceModal: {
				show: false,
				title: "",
				type: "",
			},
		});
	};

	handleViewFinanceOpen = () => {
		this.setState({
			viewFinanceModal: {
				show: true,
			},
		});
	};

	handleViewFinanceClose = () => {
		this.setState({
			viewFinanceModal: {
				show: false,
			},
		});
	};

	//#endregion

	//#region QUOTATION
	handleAddQuotation = async (enquiryID) => {
		if (!this.state.fields.isContact) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact", this.mandatoryFieldsForTestDrive);
				}
			});
		} else if (
			!this.state.fields.isVehicle &&
			_.isEmpty(this.state.fields["requirement"])
		) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add requirement"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					setTimeout(() => {
						this.setState({
							requirementModal: {
								show: true,
								title: "requirement",
								clsActive: "overlay-modal active",
							},
						});
					}, 200);
				}
			});
		} else if (await this.isValidForStatusUpdate("isQuotation")) {
			if (this.state.requirements.length > 1) {
				this.handleOpenSelectVehicleModal();
			} else {
				let _requirement = null;
				if (this.state?.fields?.requirement?.documentID) {
					_requirement = this.state.fields.requirement;
				} else {
					_requirement = this.state.requirements[0];
				}
				this.handleSearchQuotes(_requirement);
				// let _stockid = ''
				// if (this.state.requirement.documentID) {
				// 	_stockid = this.state.requirement.documentID
				// } else {
				// 	_stockid = this.state.requirements[0].documentID
				// }
				// if (_stockid) {
				// 	window.open(`${window.location.origin}/quote/details/${enquiryID}?type=new&stockID=${_stockid}`, '_blank');
				// }
			}
		}
	};

	handleSearchQuotes = async (requirement) => {
		if (!_.isEmpty(requirement)) {
			const { clientID } = this.state.fields;
			let _req =
				requirement.stock && requirement.stock.make
					? requirement.stock
					: requirement;
			const responseML = await window.firebase
				.firestore()
				.collection("stockQuotations")
				.where("clientID", "==", clientID)
				.where("isDeleted", "==", false)
				.where("vehicle.make", "==", _req.make)
				.where("vehicle.model", "==", _req.model)
				.limit(1)
				.get();
			if (responseML.docs.length > 0) {
				window.open(
					`${window.location.origin}/quote/details/${this.state.enquiryID}?type=new&stockID=${requirement.documentID}`,
					"_blank"
				);
			} else {
				const responseMK = await window.firebase
					.firestore()
					.collection("stockQuotations")
					.where("clientID", "==", clientID)
					.where("isDeleted", "==", false)
					.where("vehicle.make", "==", _req.make)
					.limit(1)
					.get();

				if (responseMK.docs.length > 0) {
					this.setState({
						stockQuoteModal: {
							show: true,
							requirement: requirement,
						},
					});
				} else {
					window.open(`${window.location.origin}/quote/details/${this.state.enquiryID}?type=new&stockID=${requirement.documentID}`, "_blank");
				}
			}
		}
	};

	handleCloseStockQuote = (id, requirement, quotation) => {
		if (id === "continue" && requirement) {
			window.open(`${window.location.origin}/quote/details/${this.state.enquiryID}?type=new&stockID=${requirement.documentID}`, "_blank");
		} else if (id === "select" && requirement && quotation) {
			window.open(`${window.location.origin}/quote/details/${this.state.enquiryID}?type=new&stockID=${requirement.documentID}&quoteID=${quotation.documentID}`, "_blank");
		}
		this.setState({
			stockQuoteModal: {
				show: false,
				requirement: null,
			},
		});
	};

	handleEditQuotation = (path) => {
		window.open(`${window.location.origin}/${path}`, "_blank");
	};

	handlePreviewQuotation = (path) => {
		window.open(`${window.location.origin}/${path}`, "_blank");
	};

	handleDeleteQuotation = async (id) => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, "Are you sure?"),
			text: CommonHelper.showLocale(this.props, `You want to delete this quotation.`),
			icon: "info",
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
			cancelButtonText: CommonHelper.showLocale(this.props, "No"),
		}).then(async (result) => {
			if (result.value) {
				const ref = firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.state.enquiryID).collection("quotations").doc(id);
				await ref.set(
					{
						isDeleted: true,
						modifiedBy: (this.props.dealersettings.id || localStorage.uid),
						modifiedDate: window.firebase.firestore.Timestamp.now(),
						modifiedFrom: "web",
					},
					{ merge: true }
				);

				let logNotes = "Quotation deleted";
				// if (!_.isEmpty(state) && state.displayID)
				// 	logNotes = 'Quotation: ' + state.displayID + ' deleted';

				let _objLogData = {
					notes: logNotes,
					type: "quotations",
					subType: "delete",
					recordId: id,
					enquiryID: this.state.enquiryID,
				};
				_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.state.dealersettings);
				CommonHelper.saveAuditLog(_objLogData);
				toast.notify("Quotation deleted successfully", {
					duration: 2000,
				});
			}
		});
	};

	handleOpenSelectVehicleModal = (type) => {
		if (type === "isFinance") {
			this.setState({
				selectVehicleModal: { show: true, isFinance: true },
			});
		} else {
			this.setState({
				selectVehicleModal: { show: true, isFinance: false },
			});
		}
	};

	handleCloseSelectVehicleModal = (requirement) => {
		if (!requirement) {
			this.setState({
				selectVehicleModal: { show: false, isFinance: false },
			});
		} else {
			this.setState({
				selectVehicleModal: { show: false, isFinance: false },
				addDrivaFinanceModal: {
					show: true,
					selectedVehicle: requirement,
				},
			});
		}
	};

	handleSelectRequirement = async (requirement) => {
		this.handleSearchQuotes(requirement);
		//window.open(`${window.location.origin}/quote/details/${this.state.enquiryID}?type=new&stockID=${requirement.documentID}`, '_blank');
	};
	//#endregion

	//#region OWNED VEHICLE

	isValidForOwned = () => {
		if (this.state.fields.isContact) {
			this.setState({
				ownedModal: {
					show: true,
					ID: "",
					title: CommonHelper.showVehicleText(this.props.dealersettings?.client?.category, 'addVehicle', 'addOwned'),
					clsActive: "overlay-modal active",
				},
			});
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			return false;
		}
	};

	ownedCount = () => {
		const { ownedEnquiryVehicles, ownedVehicles } = this.state;

		return parseInt(ownedEnquiryVehicles.length + ownedVehicles.length);
	};

	handleOwnedClose = (ID) => {
		this.setState({
			ownedModal: {
				show: false,
				ID: "",
				title: "",
				clsActive: "",
			},
		});
	};

	handleEditOwned = (ID) => {
		this.setState({
			ownedModal: {
				show: true,
				ID: ID,
				title: "editVehicle",
				clsActive: "overlay-modal active",
			},
		});
	};

	handleOwnedEnquiryClose = (data) => {
		this.setState({
			ownedEnquiryModal: {
				show: false,
				data: null,
				title: "",
				clsActive: "",
			},
		});
	};

	handleEditOwnedEnquiry = (data) => {
		this.setState({
			ownedEnquiryModal: {
				show: true,
				data: data,
				title: "editVehicle",
				clsActive: "overlay-modal active",
			},
		});
	};

	handleSaveOwnedVehicle = (requirement, _contactID, requirementID) => {
		const { ownedVehicles } = this.state;
		const { dealersettings } = this.props;
		let _requirement = !_.isEmpty(requirement.stock) ? requirement.stock : requirement;

		let isExists = ownedVehicles && ownedVehicles.filter((m) => (_requirement.vinNo ? m.vinNo === _requirement.vinNo : false) || (_requirement.regNo ? m.regNo === _requirement.regNo : false))[0];

		if (_.isEmpty(isExists)) {
			const objData = Object.assign({}, objOwnedVehicle);
			for (let [key, value] of Object.entries(_requirement)) {
				if (objData.hasOwnProperty(key)) objData[key] = value;
			}
			objData.addedBy = dealersettings ? dealersettings.id : "";
			objData.addedDate = window.firebase.firestore.Timestamp.now();
			objData.addedFrom = "web";
			objData.modifiedBy = dealersettings ? dealersettings.id : "";
			objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			objData.modifiedFrom = "web";
			objData.documentID = firestoreDB(dealersettings).firestore().collection("contactDetails").doc().id;
			objData.currentlyOwned = true;
			objData.exteriorColor = _requirement.extColor ? _requirement.extColor : "";
			objData.requirementID = requirementID;
			objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

			const _dmsSettings = Object.assign({}, dealersettings?.client?.integrations?.filter(e => e.type === "cap")[0]);
			if (_dmsSettings?.active && _dmsSettings?.enabled) objData.owned = true;

			//Remove empty value from object
			for (var propName in objData) {
				if (
					objData[propName] === null ||
					objData[propName] === undefined ||
					objData[propName] === "" ||
					(_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
				) {
					delete objData[propName];
				}
			}

			const refStockData = firestoreDB(dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(objData.documentID);
			refStockData.set(objData).then((docRef) => {
				//console.log(docRef);
			}).catch((error) => {
				console.error("Error updating contact_details: ", error);
			});
		}
	};

	handleDeleteOwnedVehicle = (requirementID) => {
		const { ownedVehicles, fields, contact } = this.state;

		let _contactID = fields.contactID ? fields.contactID : !_.isEmpty(fields.contact) ? fields.contact.documentID : !_.isEmpty(contact) ? contact.documentID : null;
		let objData = ownedVehicles && ownedVehicles.filter((m) => m.requirementID === requirementID)[0];

		if (!_.isEmpty(objData) && objData?.documentID && _contactID)
			firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`).delete();
	};
	//#endregion

	//#region  QUICK VIEW
	sidepanelOpen = (id) => {
		//console.log('sidepanelOpen', id);
		document.getElementsByTagName("body")[0].classList.remove("enquiry-bg");
		this.setState({
			showpanel: {
				clsActive: "active",
				clsName: "sidebar-quickview",
				enquiryid: id,
			},
		});
	};

	sidepanelClose = () => {
		document.getElementsByTagName("body")[0].classList.add("enquiry-bg");
		this.setState({
			showpanel: {
				clsActive: "",
				clsName: "sidebar-quickview",
				enquiryid: 0,
			},
		});
	};
	//#endregion

	//#region LOAD PAGE

	async loadEnquiry() {
		document.getElementsByTagName("body")[0].classList.remove("inner-bg");
		document.getElementsByTagName("body")[0].classList.add("enquiry-bg");
		const { enquiryid, clientUsers, groupUsers, isFromLayout } = this.props;
		if (enquiryid && this.props.dealersettings) {
			if (this.props.dealersettings.client.settings.pipelines.length === 0) {
				Swal.fire(CommonHelper.showLocale(this.props, "Access denied"), "", "info");
				this.props.history.push("/pipeline");
				return;
			}

			if (localStorage.defaultModule === 'oem' && this.props.updateDealerSettings) {

				const doc = await window.firebase.firestore().collection('enquiries').doc(this.props.enquiryid).get();
				if (doc.exists) {
					this.props.updateDealerSettings('projectId', null)
				}
				else {
					this.props.updateDealerSettings('projectId', window.secondaryProjectId)
				}
			}

			//console.log('loadEnquiry-pipelines', JSON.stringify(dealersettings.client.settings.pipelines))
			const refData = await firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(enquiryid).get();
			var _stage = "";

			if (refData.exists) {
				firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(enquiryid).set({ lastViewed: moment().utc().toISOString() }, { merge: true })
				const _objEnquiry = Object.assign({}, refData.data());

				if (localStorage.defaultModule !== "oem" && _objEnquiry.clientID && !object.keys(this.props.dealersettings.group.clients).some((e) => e === _objEnquiry.clientID)) {
					Swal.fire(CommonHelper.showLocale(this.props, "You dont have dealer access"), "", "info");
					this.props.history.push("/pipeline");
					return;
				}

				const enquiry = Object.assign({});
				let _isEmptyPipeline = false;
				for (let [key, value] of Object.entries(_objEnquiry)) {
					enquiry[key] = value;
				}
				enquiry.documentID = refData.id;

				if (_.isEmpty(enquiry.pipeline)) {
					enquiry.pipeline = "LeadsBucket";
					_isEmptyPipeline = true;
				}

				if (enquiry.pipeline === "LeadsBucket" && _.isEmpty(enquiry.ownerInbound) && !_.isEmpty(enquiry.owner))
					firestoreDB(this.props.dealersettings).firestore().doc(`enquiries/${enquiryid}`).set({ ownerInbound: enquiry.owner }, { merge: true });

				let dealersettings = this.props.dealersettings;
				if (enquiry.clientID && (localStorage.defaultModule === "oem" || dealersettings?.client?.id !== enquiry.clientID)) {
					dealersettings = await CommonHelper.updateClientSettings(enquiry.clientID, Object.assign({}, this.props.dealersettings));
					//console.log("_dealersettings,", dealersettings);
				}

				const _client = dealersettings.client;

				//console.log('E              X             I                S                  T             S', _isEmptyPipeline)
				// if (_client && _client.settings && !_client.settings.pipelines.some(item => item.value === enquiry.pipeline)) {
				// 	Swal.fire('Access denied.You dont have pipleine access. Please contact administrator.', '', 'info')
				// 	this.props.history.push('/pipeline');
				// 	return
				// }

				const contact = Object.assign({}, objContact);
				const requirement = Object.assign({}, objRequirement);
				//const stock = Object.assign({}, objStock);

				const origins = [];
				const pipelines = [];
				const lostReasons = [];
				const lostSubReasons = [];
				const _pipelineUsers = [];
				let defaultstages;
				let defaultStatus;
				let statusSettings;
				let defaultMakes;
				let modules;
				let stages = null;

				_stage = enquiry["stage"];

				// CHECKING ENQUIRY AS NEW OR NOT
				let search = window.location.search;
				let params = new URLSearchParams(search);
				let __enquiryType = params.get("type");
				let isNewEnq = enquiry.isNewEnquiry ? true : false;
				if (isNewEnq) {
					if (!__enquiryType) {
						__enquiryType = enquiry.pipeline === "LeadsBucket" ? "lead" : "enquiry";
						search = "?type=" + __enquiryType;
					}
					this.setState({
						enquiryInfo: {
							new: true,
							lead: __enquiryType === "lead" ? true : false,
						},
					});
				} else {
					this.setState({ enquiryInfo: { new: false, lead: false } });
				}

				//add enquiry into routes
				if (!isFromLayout)
					this.props.handleRouteChange({
						name: CommonHelper.displayName(enquiry),
						id: refData.id,
						displayID: enquiry.displayID ? enquiry.displayID : refData.id,
						status:
							enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST
								? enquiryStatus.LOST
								: enquiry.status,
						active: true,
						clientid: enquiry.clientID,
						//isNew: (__enquiryType && isNewEnq) ? __enquiryType : null,
						//params: (search && isNewEnq) ? search : '',
						module: enquiry.isAfterSales ? "afterSales" : "enquiry",
					});

				if (!_.isEmpty(enquiry["contact"])) {
					//console.log('enquiry[contact].documentID', enquiry['contact'].documentID)
					this.unsubscribeContact = firestoreDB(this.props.dealersettings).firestore().collection("contactDetails")
						.doc(enquiry["contact"].documentID)
						.onSnapshot(this.onContactCollectionUpdate);

					this.unsubscribeOwned = firestoreDB(this.props.dealersettings)
						.firestore()
						.collection(`contactDetails/${enquiry["contact"].documentID}/ownedVehicles`)
						//.orderBy('modifiedDate', 'desc')
						.onSnapshot(this.onOwnedCollectionUpdate);

					// const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
					// if (!enableServicePackages) {
					// 	this.unsubscribeEnquiryOwned = this.ref
					// 		.where('contact.documentID', '==', enquiry['contact'].documentID)
					// 		.where('status', '==', enquiryStatus.DELIEVERD)
					// 		//.orderBy('modifiedDate', 'desc')
					// 		.where('isDeleted', '==', false)
					// 		.onSnapshot(this.onEnquiryOwnedCollectionUpdate);
					// }

					const refContactData = await firestoreDB(this.props.dealersettings).firestore().collection("contactDetails")
						.doc(enquiry["contact"].documentID)
						.get();
					if (refContactData.exists) {
						const objcontactData = Object.assign({}, refContactData.data());
						for (let [key, value] of Object.entries(objcontactData)) {
							contact[key] = value;
						}
						if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
						if (_.isNil(objcontactData?.marketingOptinEmail))
							contact.marketingOptinEmail = null;
						if (_.isNil(objcontactData?.marketingOptinSMS))
							contact.marketingOptinSMS = null;
						contact.documentID = enquiry["contact"].documentID;
						enquiry.contactID = enquiry["contact"].documentID;
						//console.log('refContactData', objcontactData, contact);
					}
				}

				if (enquiry.isVehicle && !_.isEmpty(enquiry["requirement"])) {
					const objReqData = Object.assign({}, enquiry["requirement"]);
					for (let [key, value] of Object.entries(objReqData)) {
						requirement[key] = value;
					}

					/*if (requirement.stock && typeof requirement.stock === 'object' && Object.keys(requirement.stock).length > 0) {
								  const refStockData = await this.refStock.doc(requirement.stock.documentID).get();
								  if (refStockData.exists) {
									  const objStockData = Object.assign({}, refStockData.data());
									  for (let [key, value] of Object.entries(objStockData)) {
										  stock[key] = value;
									  }
									  stock.documentID = requirement.stock.documentID;
		  
								  }
							  }*/
				}



				if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
					const setttings = _client.settings;
					let _allPipelines = !_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;

					const accessToSelectAllKPIs = this.props.dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;

					if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
						this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

					if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
						this.mandatoryFieldsForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');

					if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.financeContact))
						this.mandatoryFieldsForFinance = setttings.mandatoryFields.financeContact.split(',');

					if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.videoContact))
						this.mandatoryFieldsForVideo = setttings.mandatoryFields.videoContact.split(',');

					if (!_.isEmpty(dealersettings?.rolePermissions?.excludeOrigins))
						this.mandatoryFields = _.uniq(_.union(this.mandatoryFields, ['origin']))

					let _selPipeline = _allPipelines.filter(e => e.value === enquiry.pipeline)[0];
					defaultstages = Object.assign([], _selPipeline?.stages.filter(m => m.value !== 'InboundLead' && m.value !== 'Converted'));
					statusSettings = _selPipeline?.statusSettings;
					defaultStatus = _selPipeline?.status;
					defaultMakes = _selPipeline?.makes;

					let _modules = !_.isEmpty(_allPipelines.filter(e => e.value === enquiry.pipeline)[0]) ? _allPipelines.filter(e => e.value === enquiry.pipeline)[0].modules : objModules;
					modules = !_.isEmpty(_modules) ? _modules : objModules;

					if (!_.isEmpty(defaultstages))
						stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

					enquiryAllStatus = [];
					setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
						enquiryAllStatus.push({
							...doc,
							label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
						});
					});

					titles = [];
					setttings.titles && setttings.titles.forEach((doc) => {
						titles.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
					setttings.origins && setttings.origins.forEach(doc => {
						origins.push({
							value: doc.value,
							active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
							isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
						});
					});

					enquiryTypes = [];
					setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
						enquiryTypes.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
							isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
						});
					});

					labels = [];
					setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
						labels.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: <div className={`badge badge-pill`} style={{
								color: `${doc.color ? doc.color : '#f47815 '}`,
								backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
								boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
							}}>{doc.name.toUpperCase()}</div>,
						});
					});

					serviceTypes = [];
					setttings.serviceTypes && setttings.serviceTypes.forEach(doc => {
						serviceTypes.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
						});
					});

					leadSources = [];
					setttings.leadSource && setttings.leadSource.forEach(doc => {
						leadSources.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
							isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
						});
					});

					paymentMethods = [];
					setttings.paymentMethods && setttings.paymentMethods.forEach(doc => {
						paymentMethods.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
						});
					});

					enquiryOptions = [];
					setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
						enquiryOptions.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
						});
					});

					enquiryOptionsDF = [];
					if (setttings.enquiryOptionsDF)
						enquiryOptionsDF = setttings.enquiryOptionsDF;

					enquiryOptionsStatic = [];
					if (setttings.enquiryOptionsStatic)
						enquiryOptionsStatic = setttings.enquiryOptionsStatic;

					financeStatuses = [];
					setttings.financeStatus && setttings.financeStatus.forEach(doc => {
						financeStatuses.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
						});
					});

					afterMarketStatuses = [];
					setttings.afterMarketStatus && setttings.afterMarketStatus.forEach(doc => {
						afterMarketStatuses.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
						});
					});

					campaigns = [];
					setttings.campaigns && setttings.campaigns.forEach(doc => {
						campaigns.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
							isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
						});
					});

					_allPipelines && _allPipelines.forEach((doc) => {
						pipelines.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name
						});
					});

					setttings.lostReasons && setttings.lostReasons.forEach(doc => {
						lostReasons.push({
							value: doc.value,
							active: _.isBoolean(doc.active) ? doc.active : true,
							label: doc.name,
							subList: !_.isEmpty(doc.subList) ? doc.subList : []
						});
					});

					if (enquiry.lostReason && setttings.lostReasons && !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0])) {
						let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList) ?
							setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList : null;

						!_.isEmpty(_subList) && _subList.forEach(doc => {
							lostSubReasons.push({
								value: doc.value,
								active: _.isBoolean(doc.active) ? doc.active : true,
								label: doc.name
							});
						});
					}
				}

				// GET USERS LIST BASED ON CILENT-ID AND PIPELINE
				let options = [];
				let optionsFM = [];
				let optionsAMM = [];
				if (!_.isEmpty(dealersettings.client.roles) && enquiry.clientID) {
					let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === enquiry.pipeline)), function (obj) {
						return obj.documentID;
					})];

					let roleFMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
						return obj.documentID;
					})];
					let roleAMMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
						return obj.documentID;
					})];

					let _selectedUsers = [];
					let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
					if (!_.isEmpty(roleIds))
						_selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleIds, v.roleID));

					if (_.isEmpty(_selectedUsers) && _.isEmpty(groupUsers) && enquiry.clientID !== dealersettings.client.id && localStorage.defaultModule !== 'oem')
						_selectedUsers = clientUsers;

					_selectedUsers && _selectedUsers.map(rec => {
						return options.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						})
					})

					let _selectedFMUsers = [];
					if (!_.isEmpty(roleFMIds))
						_selectedFMUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleFMIds, v.roleID));

					_selectedFMUsers && _selectedFMUsers.map(rec => {
						return optionsFM.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						})
					})

					let _selectedAMMUsers = [];
					if (!_.isEmpty(roleAMMIds))
						_selectedAMMUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleAMMIds, v.roleID));

					_selectedAMMUsers && _selectedAMMUsers.map(rec => {
						return optionsAMM.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						})
					})

				}
				else {
					clientUsers.map(rec => {
						return options.push({
							value: rec.id,
							active: _.isBoolean(rec.active) ? rec.active : true,
							searchlabel: rec.name,
							label: <div className="drop-image">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
								{rec.name}
							</div>
						})
					})
				}

				let _userOptions = [];
				clientUsers && clientUsers.map(rec => {
					return _userOptions.push({
						value: rec.id,
						active: _.isBoolean(rec.active) ? rec.active : true,
						searchlabel: rec.name,
						label: <div className="drop-image">
							<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
							{rec.name}
						</div>
					})
				})

				//console.log('pipelineUsers', contact, options, statusSettings,defaultMakes)
				this.setState({
					//enquiryID: refData.id,
					_clientID: enquiry.clientID ? enquiry.clientID : dealersettings.client.id,
					fields: enquiry,
					modules: modules,
					requirement: requirement,
					contact: contact,
					origins: origins,
					lostReasons: lostReasons,
					lostSubReasons: lostSubReasons,
					pipelines: pipelines,
					pipelineUsers: _.uniqBy(options, 'value'),
					allClientUsers: _.uniqBy(_userOptions, 'value'),
					allFinanceUsers: _.uniqBy(optionsFM, 'value'),
					allAfterMarketUsers: _.uniqBy(optionsAMM, 'value'),
					defaulStages: defaultstages,
					defaultStatus: defaultStatus,
					statusSettings: statusSettings,
					defaultMakes: defaultMakes,
					activeStageIndex: stages && stages.indexOf(_stage),
					isEmptyPipeline: _isEmptyPipeline,
					dealersettings: dealersettings
				}, () => {
					let _originFlow = params.get('origin');
					let _stageFlow = params.get('stage');
					this.unsubscribeEnquiryDet = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiryid)
						.onSnapshot(this.onEnquiryCollectionUpdate);
					if (_originFlow) {
						if (_originFlow.split('_')?.length > 1)
							this.handleEnquiryOptionClose(_originFlow.split('_')[0], _originFlow.split('_')[1])
						else
							this.handleEnquiryOptionClose(_originFlow)

						if (params.has('origin')) {
							params.delete('origin')
							this.props.history.replace({ search: params.toString() })
						}
					}
					if (_stageFlow) {
						this.handleEnquiryOptionClose('stage', _stageFlow)
						if (params.has('stage')) {
							params.delete('stage')
							this.props.history.replace({ search: params.toString() })
						}
					}
				});

				if (enquiry.isFinance === true) {
					this.onFinanceCollectionUpdate();
				}



				this.unsubscribeWalkins = firestoreDB(dealersettings).firestore().collection(`enquiries/${refData.id}/walkins`)
					.onSnapshot(this.onWalkinsCollectionUpdate);

				this.unsubscribeproductAddons = firestoreDB(dealersettings).firestore().collection(`enquiries/${refData.id}/productAddons`)
					.onSnapshot(this.onAddonsCollectionUpdate);

				const _permissions = this.props.dealersettings?.rolePermissions?.permissions;
				const accessToBusinessManager = ((!_.isEmpty(_permissions) && _permissions.accessToBusinessManager) ? true : false);
				const accessToProduct = ((!_.isEmpty(_permissions) && _permissions.accessToProduct) ? true : false);
				const accessToAfterMarketTab = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketTab) ? true : false);
				const showSalesDoc = this.props.dealersettings?.client?.clientSettings?.hideSalesDoc ? false : true;

				if (showSalesDoc || accessToBusinessManager || accessToProduct || accessToAfterMarketTab)
					this.unsubscribeFiles = firestoreDB(dealersettings).firestore().collection(`enquiries/${refData.id}/files`)
						.onSnapshot(this.onFilesCollectionUpdate);

				if (enquiry?.linkID || enquiry?.displayID) {
					this.unsubscribeLinkEnquiry = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
						.where('clientID', '==', enquiry.clientID ? enquiry.clientID : dealersettings.client.id)
						.where('linkID', '==', enquiry?.linkID ? enquiry.linkID : (enquiry?.displayID ? enquiry.displayID : ''))
						.where('isDeleted', '==', false)
						.onSnapshot(this.onLinkEnquiryCollectionUpdate);
				}

				this.unsubscribeTradeIn = firestoreDB(dealersettings).firestore().collection('tradeins')
					.where('enquiryID', '==', refData.id)
					.where('isDeleted', '==', false)
					// .orderBy('modifiedDate', 'desc')
					.onSnapshot(this.onTradeInsCollectionUpdate);
				this.unsubscribeEvent = this.refEvents
					.where('clientID', '==', enquiry.clientID ? enquiry.clientID : dealersettings.client.id)
					.where('contactID', '==', enquiry?.contactID ? enquiry.contactID : '')
					.onSnapshot(this.onEventCollectionUpdate);

				this.unsubscribeDrivaQuotes = firestoreDB(dealersettings).firestore().collection("finance")
					.where('enquiryID', '==', refData.id)
					.where('type', '==', 'driva')
					.onSnapshot(this.onDrivaQuotesCollectionUpdate);

				this.unsubscribeUsersRating = firestoreDB(dealersettings).firestore().collection("usersRating")
					.where('enquiryID', '==', refData.id)
					.onSnapshot(this.onUsersRatingCollectionUpdate);

				this.unsubscribeTestDrives = firestoreDB(dealersettings).firestore().collection('testdrives')
					.where('enquiryID', '==', refData.id)
					.where('isDeleted', '==', false)
					// .orderBy('modifiedDate', 'desc')
					.onSnapshot(this.onTestDrivesCollectionUpdate);

				this.unsubscribeCafeOrder = firestoreDB(dealersettings).firestore().collection('cafeOrders')
					.where('enquiryID', '==', refData.id)
					.where('isDeleted', '==', false)
					// .orderBy('addedDate', 'desc')
					.onSnapshot(this.onCafeOrdersCollectionUpdate);

				this.unsubscribeQuotations = firestoreDB(dealersettings).firestore().collection('enquiries').doc(refData.id).collection('quotations')
					// .where('enquiryID', '==', refData.id)
					.where('isDeleted', '==', false)
					.onSnapshot(this.onQuotationsCollectionUpdate);

				this.unsubscribeFormFeedback = firestoreDB(dealersettings).firestore().collection('formFeedback')
					.where('clientID', '==', enquiry.clientID ? enquiry.clientID : dealersettings.client.id)
					.where('contactID', '==', enquiry?.contactID ? enquiry.contactID : '')
					.onSnapshot(this.onFormFeedbackCollectionUpdate);

				this.unsubscribeUsersRating = window.firebase.firestore().collection('tvScheduler')
					.where('enquiryID', '==', refData.id)
					.where('isDeleted', '==', false)
					.onSnapshot(this.onDeviceCollectionUpdate);

				let refRequirementsData = firestoreDB(dealersettings).firestore().collection(`enquiries/${refData.id}/requirements`).where('isDeleted', '==', false)//.orderBy('addedDate', 'desc')
				this.unsubscribeRequirements = collectionData(refRequirementsData)
					.pipe(
						switchMap(requirementSnapshot => {
							const stockIds = _.uniq(requirementSnapshot.filter(item => (!_.isEmpty(item.stock))).map(item => item.stock.documentID))
							return combineLatest(
								of(requirementSnapshot),
								combineLatest(
									stockIds.length > 0 ? stockIds.map(stockID =>
										collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
											map(stockSnapshot => stockSnapshot[0])
										)
									) : of([])
								),
							)
						}),
						map(([requirementSnapshot, stockSnapshot]) => {
							//console.log('requirementSnapshot', requirementSnapshot, stockSnapshot)
							return requirementSnapshot.map(requirementDoc => {
								return {
									...requirementDoc,
									stock: (!_.isEmpty(requirementDoc.stock) && !_.isEmpty(stockSnapshot) && !_.isEmpty(stockSnapshot[0])) ? stockSnapshot?.find(a => a?.documentID === requirementDoc.stock?.documentID) : (!_.isEmpty(requirementDoc?.stock) ? requirementDoc.stock : {}),
								}
							})
						})
					)
					.subscribe(requirements => {
						this.onReqCollectionUpdate(requirements)
					});
			}
			else {
				this.setState({
					pagenotFound: true,
					isLoading: false,
					enquiryID: null
				})
			}
		}
	}

	onLinkEnquiryCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let linkEnquiries = [];
		querySnapshot.forEach((docSnapshot) => {
			const enquiry = Object.assign({}, objEnquiry);
			const _objEnquiry = Object.assign({}, docSnapshot.data());
			for (let [key, value] of Object.entries(_objEnquiry)) {
				enquiry[key] = value;
			}
			enquiry.documentID = docSnapshot.id;
			linkEnquiries.push(enquiry);
		});
		const { enquiryID } = this.state;
		linkEnquiries = _.orderBy(linkEnquiries.filter(e => e.documentID !== enquiryID), ['displayID'], ['asc'])
		this.setState({ linkEnquiries: linkEnquiries });
	}

	onEnquiryCollectionUpdate = (docSnapshot) => {
		document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');

		if (!docSnapshot.exists) {
			return;
		}

		const { dealersettings } = this.state;
		const pipelines = [];
		const lostReasons = [];
		const lostSubReasons = [];
		let rottendays = 0;
		let stages = null;
		let defaultstages;
		let defaultStatus;
		let statusSettings;
		let defaultMakes;
		let modules;

		const enquiry = Object.assign({});
		const _objEnquiry = Object.assign({}, docSnapshot.data());
		for (let [key, value] of Object.entries(_objEnquiry)) {
			enquiry[key] = value;
		}

		enquiry.documentID = docSnapshot.id;
		if (_.isEmpty(enquiry.pipeline))
			enquiry.pipeline = 'LeadsBucket';

		// if (!enquiry.counts || enquiry.counts.todayDate !== moment().format('YYYY-MM-DD')) {
		// 	let objcounts = enquiry.counts ? enquiry.counts : {};
		// 	objcounts.todayDate = moment().format('YYYY-MM-DD');

		// 	firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(docSnapshot.id).set({ counts: objcounts }, { merge: true });
		// }
		if (enquiry.clientID && dealersettings?.client?.documentID && dealersettings?.client?.documentID !== enquiry.clientID) {
			this.unsubscribeEnquiryDet && this.unsubscribeEnquiryDet();
			this.loadEnquiry();
			//console.log('onEnquiryCollectionUpdate,loadEnquiry', dealersettings)
			return;
		}

		//console.log('onEnquiryCollectionUpdate,loadEnquiry', dealersettings)
		let _client = dealersettings.client;

		if (!_.isEmpty(_client) && !_.isEmpty(_client.settings)) {
			const setttings = _client.settings;
			let _allPipelines = !_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;

			if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
				this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

			if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdriveContact))
				this.mandatoryFieldsForTestDrive = setttings.mandatoryFields.testdriveContact.split(',');

			if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.financeContact))
				this.mandatoryFieldsForFinance = setttings.mandatoryFields.financeContact.split(',');

			if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.videoContact))
				this.mandatoryFieldsForVideo = setttings.mandatoryFields.videoContact.split(',');

			if (!_.isEmpty(dealersettings?.rolePermissions?.excludeOrigins))
				this.mandatoryFields = _.uniq(_.union(this.mandatoryFields, ['origin']))

			if (enquiry.pipeline) {
				//console.log('_dealersettings,onEnquiryCollectionUpdate2', dealersettings, enquiry.pipeline)

				let _selectedPipeline = _allPipelines.filter(e => e.value === enquiry.pipeline)[0];
				defaultstages = Object.assign([], _selectedPipeline?.stages.filter(m => m.value !== 'InboundLead' && m.value !== 'Converted'));
				statusSettings = _selectedPipeline?.statusSettings;
				defaultStatus = _selectedPipeline?.status;
				defaultMakes = _selectedPipeline?.makes;
				stages = !_.isEmpty(defaultstages) && Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

				let _selectedStages = _selectedPipeline ? _selectedPipeline.stages : null;
				_selectedStages && _selectedStages.forEach(col => {
					if (enquiry.stage === col.value) {
						if (col.rottenDays) {
							var enqage = enquiry.modifiedDate ? moment().diff(moment.unix(enquiry.modifiedDate.seconds), 'days') : 0;
							rottendays = enqage - col.rottenDays;
						}
					}
				});
			}


			let _modules = !_.isEmpty(_allPipelines.filter(e => e.value === enquiry.pipeline)[0]) ? _allPipelines.filter(e => e.value === enquiry.pipeline)[0].modules : objModules;
			modules = !_.isEmpty(_modules) ? _modules : objModules;

			//stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

			_allPipelines.forEach((doc) => {
				pipelines.push({
					value: doc.value,
					label: doc.name
				});
			});

			setttings.lostReasons && setttings.lostReasons.forEach(doc => {
				lostReasons.push({
					value: doc.value,
					label: doc.name,
					subList: !_.isEmpty(doc.subList) ? doc.subList : []
				});
			});


			if (enquiry.lostReason && setttings.lostReasons && !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0])) {
				let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList) ?
					setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList : null;

				!_.isEmpty(_subList) && _subList.forEach(doc => {
					lostSubReasons.push({
						value: doc.value,
						label: doc.name
					});
				});
			}
		}

		let _enqbasicVM = Object.assign({}, enquiryBasicDetailsVM);
		_enqbasicVM.documentID = docSnapshot.id;
		for (let [key, value] of Object.entries(docSnapshot.data())) {
			if (_enqbasicVM.hasOwnProperty(key)) _enqbasicVM[key] = value;
		}

		if (enquiry.isFinanceRequestSent === true) {
			this.onFinanceCollectionUpdate();
		}

		const { displayID, status } = this.state.fields;
		if ((enquiry.displayID && _.isEmpty(displayID)) || (status !== enquiry.status) || (enquiry.displayID !== displayID)) {
			this.props.updatenavbar({
				id: this.state.enquiryID,
				displayID: enquiry.displayID,
				status: enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : enquiry.status,
				name: CommonHelper.displayName(enquiry),
			});
		}

		this.setState({
			enquiryID: enquiry.addedDate || enquiry.displayID ? docSnapshot.id : null,
			fields: enquiry,
			rottenDays: rottendays,
			pipelines: pipelines,
			modules: modules,
			lostReasons: lostReasons,
			lostSubReasons: lostSubReasons,
			defaulStages: defaultstages,
			defaultStatus: defaultStatus,
			statusSettings: statusSettings,
			defaultMakes: defaultMakes,
			activeStageIndex: stages && stages.indexOf(enquiry['stage']),
			enquiryBasicVM: _enqbasicVM
		});
	};

	onEventCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const events = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_event => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _event.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			// event.documentID = _event.id;

			let dealersettings = this.state.dealersettings;
			if (dealersettings && event.clientID && (event.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
				event.dealerName = CommonHelper.getOtherDealerName(dealersettings, event.clientID);
			}
			events.push(event);
		});

		this.setState({
			events: _.orderBy(events, ["modifiedDate"], ["desc"])
		});
	};

	onDrivaQuotesCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const drivaQuotes = [];
		querySnapshot.forEach(_drivaQuote => {
			const drivaQuote = Object.assign({});
			const drivaQuoteData = Object.assign({}, _drivaQuote.data());
			for (let [key, value] of Object.entries(drivaQuoteData)) {
				drivaQuote[key] = value;
			}
			drivaQuotes.push(drivaQuote);
		});

		this.setState({
			drivaQuotes: _.orderBy(drivaQuotes, ["modifiedDate"], ["desc"])
		});
	};

	onUsersRatingCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const usersRating = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_userRating => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _userRating.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			// event.documentID = _userRating.id;
			usersRating.push(event);
		});

		this.setState({
			usersRating: _.orderBy(usersRating, ["addedDate"], ["desc"])
		});
	};

	onFormFeedbackCollectionUpdate = querySnapshot => {
		const { enquiryid } = this.props;
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const formFeebacks = [];
		let _fields = Object.assign({});
		querySnapshot.forEach(_feedback => {
			const event = Object.assign({});
			const objEnquiryData = Object.assign({}, _feedback.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				event[key] = value;
			}
			//if (!_.isEmpty(event.response))
			formFeebacks.push({
				...event,
				documentID: _feedback.id,
				modifiedDate: event.modifiedDate || event.sentDate,
				formID: event?.formID?.replace(/"/g, '') || null,
				score: event.score > 100 ? 100 : event.score < 0 ? 0 : event.score,
				sortOrder: event.response ? 1 : 0
			});
		});

		this.setState({
			formFeedback: _.uniqBy(_.orderBy(formFeebacks.filter(a => a.enquiryID === enquiryid), 'sortOrder', 'desc'), 'formID')
		});
	};
	onContactCollectionUpdate = (docSnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const _contact = Object.assign({}, objContact);
		const objcontactData = Object.assign({}, docSnapshot.data());
		for (let [key, value] of Object.entries(objcontactData)) {
			if (_contact.hasOwnProperty(key))
				_contact[key] = value;
		}
		if (_.isNil(objcontactData?.optinSMS)) _contact.optinSMS = null;
		if (_.isNil(objcontactData?.marketingOptinEmail)) _contact.marketingOptinEmail = null;
		if (_.isNil(objcontactData?.marketingOptinSMS)) _contact.marketingOptinSMS = null;
		_contact.documentID = docSnapshot.id;

		const { contact } = this.state;

		if (!_.isEmpty(contact) && !_.isEmpty(_contact.amsMe) && !_.isEmpty(_contact.amsMe.meID) && JSON.stringify(contact.amsMe) !== JSON.stringify(_contact.amsMe)) {
			this.unsubscribeAmsMe = window.firebase.firestore()
				.collection(`amsmeUsers`)
				.doc(_contact.amsMe.meID)
				.onSnapshot(this.onAmsMeCollectionUpdate);
		}

		//	console.log('onContactCollectionUpdate', contact, objcontactData)
		this.setState({
			contact: _contact
		});
	}

	onAmsMeCollectionUpdate = docSnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		if (!docSnapshot.exists) {
			return;
		}

		let amsmeUser = Object.assign({}, docSnapshot.data());
		amsmeUser.documentID = docSnapshot.id;

		this.setState({ amsmeUser: amsmeUser });
	};

	onWalkinsCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let walkins = [];
		querySnapshot.forEach((_requirement) => {
			//const _stock = Object.assign({}, stockBasicVM);
			const requirement = Object.assign({}, objWalkIn);
			const objData = Object.assign({}, _requirement.data());
			for (let [key, value] of Object.entries(objData)) {
				requirement[key] = value;
			}
			requirement.documentID = _requirement.id;
			walkins.push(requirement);
		});

		walkins = _.orderBy(walkins, ['addedDate'], ['desc'])
		this.setState({ walkins: walkins });
	}

	onAddonsCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let _list = [];
		querySnapshot.forEach((data) => {
			const objData = Object.assign({}, data.data());
			objData.documentID = data.id;
			_list.push(objData);
		});

		_list = _.orderBy(_list, ['addedDate'], ['desc'])
		this.setState({ productAddOns: _list });
	}

	onFilesCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let _list = [];
		querySnapshot.forEach((data) => {
			const objData = Object.assign({}, data.data());
			objData.isDeleted = objData.isDeleted ? true : false;
			objData.documentID = data.id;
			_list.push(objData);
		});

		_list = _.orderBy(_list, ['addedDate'], ['desc'])
		this.setState({ files: _list });
	}

	onCafeOrdersCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let cafeOrders = [];
		querySnapshot.forEach((_cafeOrder) => {
			const cafeOrder = Object.assign({}, objCafe);
			const objCafeOrderData = Object.assign({}, _cafeOrder.data());
			for (let [key, value] of Object.entries(objCafeOrderData)) {
				cafeOrder[key] = value;
			}
			cafeOrder.documentID = _cafeOrder.id;
			cafeOrders.push(cafeOrder);
		});

		if (!_.isEmpty(cafeOrders)) {
			cafeOrders = cafeOrders.sort(function (x, y) {
				return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
			})
		}

		this.setState({
			cafeOrders: cafeOrders
		});
	}

	onQuotationsCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let quotations = [];
		querySnapshot.forEach((_quotation) => {
			quotations.push(_quotation.data());
		});

		if (!_.isEmpty(quotations)) {
			quotations = quotations.sort(function (x, y) {
				return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
			})
		}

		this.setState({
			quotations,
		});
	}

	onTradeInsCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let tradeIns = [];
		querySnapshot.forEach(_tradein => {
			const tradeIn = Object.assign({}, objTradeIn);
			const objTradeInData = Object.assign({}, _tradein.data());
			for (let [key, value] of Object.entries(objTradeInData)) {
				tradeIn[key] = value;
			}
			tradeIn.documentID = _tradein.id;
			tradeIn.addedDate = objTradeInData.addedDate ? objTradeInData.addedDate : window.firebase.firestore.Timestamp.now();
			tradeIns.push(tradeIn);
		});

		if (!_.isEmpty(tradeIns)) {
			tradeIns = tradeIns.sort(function (x, y) {
				return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
			})
		}

		this.setState({
			tradeIns: tradeIns
		});
	}

	onTestDrivesCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		let testdrives = [];
		querySnapshot.forEach((_testdrive) => {
			const testDrive = Object.assign({}, objTestDrive);
			const objTestDriveData = Object.assign({}, _testdrive.data());
			for (let [key, value] of Object.entries(objTestDriveData)) {
				testDrive[key] = value;
			}
			testDrive.documentID = _testdrive.id;

			if (testDrive.stockID && !testDrive.stock) {
				const refTDStockData = window.firebase.firestore().collection('stock').doc(testDrive.stockID);
				testdrives.push(
					refTDStockData.get().then((_testStock) => {
						if (_testStock.exists) {
							const _stock = Object.assign({}, stockBasicVM);
							const objTDStockData = Object.assign({}, _testStock.data());
							for (let [key, value] of Object.entries(objTDStockData)) {
								if (_stock.hasOwnProperty(key)) _stock[key] = value;
							}
							_stock.documentID = testDrive.stockID;
							testDrive.stock = _stock;
							return testDrive
						}
						else {
							console.error("No such document!");
						}
					})
				)
			}
			else {
				testdrives.push(testDrive);
			}
		});

		Promise.all(testdrives).then(_testdrives => {

			if (!_.isEmpty(_testdrives)) {
				_testdrives = _testdrives.sort(function (x, y) {
					return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
				})
			}

			this.setState({ testdrives: _testdrives })
		})
	}

	onEnquiryOwnedCollectionUpdate = querySnapshot => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const ownedEnquiries = [];

		querySnapshot.forEach((_enquiry) => {
			const enquiry = Object.assign({}, objEnquiry);
			const ownedEnquiry = Object.assign({}, objOwnedEnquiryVehicle);
			const objEnquiryData = Object.assign({}, _enquiry.data());
			for (let [key, value] of Object.entries(objEnquiryData)) {
				enquiry[key] = value;
			}
			enquiry.documentID = _enquiry.id;

			if (_.isEmpty(enquiry.pipeline))
				enquiry.pipeline = 'LeadsBucket';

			let dealersettings = this.state.dealersettings;
			if (enquiry.isVehicle && !_.isEmpty(enquiry.requirement)) {
				ownedEnquiry.enquiryID = enquiry.documentID;
				ownedEnquiry.displayID = enquiry.displayID ? enquiry.displayID : '';
				ownedEnquiry.regNo = enquiry.regNo ? enquiry.regNo : '';
				ownedEnquiry.owner = enquiry.owner ? enquiry.owner : '';
				ownedEnquiry.chassisNo = enquiry.chassisNo ? enquiry.chassisNo : '';
				ownedEnquiry.status = enquiry.status;
				ownedEnquiry.currentlyNotOwned = enquiry.currentlyNotOwned ? true : false;
				ownedEnquiry.modifiedDate = enquiry.modifiedDate;

				let _client = enquiry.clientID && !_.isEmpty(dealersettings) &&
					!_.isEmpty(dealersettings.group) &&
					!_.isEmpty(dealersettings.group.clients) &&
					dealersettings.group.clients[enquiry.clientID] ? dealersettings.group.clients[enquiry.clientID] : dealersettings.client;

				if (!_.isEmpty(enquiry.requirement.stock)) {
					ownedEnquiry.make = enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '';
					ownedEnquiry.model = enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '';
					ownedEnquiry.type = enquiry.requirement.stock.type ? enquiry.requirement.stock.type : '';
					ownedEnquiry.year = enquiry.requirement.stock.year ? enquiry.requirement.stock.year : '';
					ownedEnquiry.regNo = enquiry.requirement.stock.regNo ? enquiry.requirement.stock.regNo : '';
					ownedEnquiry.vinNo = enquiry.requirement.stock.vinNo ? enquiry.requirement.stock.vinNo : '';
					ownedEnquiry.extColor = enquiry.requirement.stock.extColor ? enquiry.requirement.stock.extColor : '';
					ownedEnquiry.saleType = enquiry.requirement.saleType ? CommonHelper.getNameByValue(_client && _client.settings && _client.settings.salesType,
						enquiry.requirement.saleType, '') : '';
				}
				else {
					ownedEnquiry.make = enquiry.requirement.make ? enquiry.requirement.make : '';
					ownedEnquiry.model = enquiry.requirement.model ? enquiry.requirement.model : '';
					ownedEnquiry.type = enquiry.requirement.type ? enquiry.requirement.type : '';
					ownedEnquiry.year = enquiry.requirement.year ? enquiry.requirement.year : '';
					ownedEnquiry.regNo = enquiry.requirement.regNo ? enquiry.requirement.regNo : '';
					ownedEnquiry.vinNo = enquiry.requirement.vinNo ? enquiry.requirement.vinNo : '';
					ownedEnquiry.extColor = enquiry.requirement.extColor ? enquiry.requirement.extColor : '';
					ownedEnquiry.saleType = enquiry.requirement.saleType ? CommonHelper.getNameByValue(_client && _client.settings && _client.settings.salesType,
						enquiry.requirement.saleType, '') : '';
				}

				ownedEnquiries.push(ownedEnquiry)
			}

		});

		this.setState({
			ownedEnquiryVehicles: _.orderBy(ownedEnquiries, ["modifiedDate"], ["desc"])
		});
	}

	onOwnedCollectionUpdate = (querySnapshot) => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		const ownedVehicles = [];
		querySnapshot.forEach((_owned) => {
			const owned = Object.assign({}, objOwnedVehicle);
			const objOwnedData = Object.assign({}, _owned.data());
			for (let [key, value] of Object.entries(objOwnedData)) {
				owned[key] = value;
			}
			owned.documentID = _owned.id;
			ownedVehicles.push(owned);
		});

		this.setState({
			ownedVehicles: _.orderBy(ownedVehicles, ["modifiedDate"], ["desc"])
		});
	}

	onFinanceCollectionUpdate = async () => {
		try {

			const { fields, contact } = this.state;
			const ObjectToSend = {
				AMSProInqId: fields.documentID,
				AMSProCustomerId: contact.documentID,
				DealerCode: fields.clientID,
			};

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(ObjectToSend),
			};

			const response = await fetch(
				this.props.dealersettings.financeURL + 'api/AMSProFinance/Action/FinanceDetails',
				requestOptions,
			);

			const respjson = await response.json();

			if (respjson.success) {
				//console.log(respjson);
				this.setState({
					financeDetails: respjson.data,
				});
			} else {
				console.log('onFinanceCollectionUpdate', respjson);
			}
		} catch (error) {
			console.log(error);
		}
	}

	onReqCollectionUpdate = (querySnapshot) => {
		//console.log('onCollectionUpdate', querySnapshot)
		const requirements = [];
		querySnapshot.forEach((_requirement) => {
			const requirement = Object.assign({}, objRequirement);
			const objData = Object.assign({}, _requirement);
			for (let [key, value] of Object.entries(objData)) {
				requirement[key] = value;
			}
			requirements.push(requirement);
		});

		this.setState({
			requirements: _.orderBy(requirements, ["addedDate"], ["desc"]),
			requirement: _.find(requirements, { documentID: this?.state?.requirement?.documentID }) ?
				_.find(requirements, { documentID: this?.state?.requirement?.documentID }) : null
		}, () => {
			this.saveReqCounts(requirements);
		});
	};

	saveReqCounts = (requirements) => {
		const { fields } = this.state;
		let _reCounts = requirements.length;
		let _soldCounts = requirements.filter(m => m.isSold).length;

		if (fields && fields.documentID && (fields.requirementCount !== _reCounts || fields.soldCount !== _soldCounts)) {
			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(fields.documentID);
			updateRef.update({
				requirementCount: _reCounts,
				soldCount: _soldCounts,
				modifiedFrom: 'web'
			})
		}
	}

	handleSpaceScrollLock = (e) => {
		if (e.keyCode == 32 && e.target == document.body) {
			e.preventDefault();
		}
	}

	componentDidMount() {
		localStorage.removeItem("objSales");
		this._isMounted = true;
		if (this._isMounted) {
			this.loadEnquiry();
			setTimeout(() => this.scrollIntoComments(), 4000);
		}

		window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

		if (this.props.isFromLayout)
			window.onbeforeunload = function () {
				this.props.history.push("/enquiry/details/" + this.state.enquiryID);
				return "";
			}.bind(this);

	}

	componentDidUpdate() {
		let classList = document.getElementsByTagName('body')[0].classList.value;
		if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
			//console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
			document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		}
		if (this.state.loading) {
			this.unSubscribeAllCollections();
			this.loadEnquiry();
		}
	}

	componentWillUnmount() {
		document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
		window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
		this.unSubscribeAllCollections();

		if (window.location.pathname.indexOf('pipeline/details') < 0) {
			this.props.resetnavbar();
		}
		this._isMounted = false;
		window.onbeforeunload = null;
		// if (this.props.isFromLayout && window.performance) {
		// 	if (performance.navigation.type == 1) {				
		// 		this.props.history.push("/enquiry/details/" + this.state.enquiryID);
		// 	}
		// }
	}

	unSubscribeAllCollections = () => {
		this.unsubscribeEnquiryDet && this.unsubscribeEnquiryDet();
		this.unsubscribeContact && this.unsubscribeContact();
		this.unsubscribeWalkins && this.unsubscribeWalkins();
		this.unsubscribeproductAddons && this.unsubscribeproductAddons();
		this.unsubscribeFiles && this.unsubscribeFiles();
		this.unsubscribeTradeIn && this.unsubscribeTradeIn();
		this.unsubscribeEvent && this.unsubscribeEvent();
		this.unsubscribeTestDrives && this.unsubscribeTestDrives();
		this.unsubscribeCafeOrder && this.unsubscribeCafeOrder();
		this.unsubscribeQuotations && this.unsubscribeQuotations();
		this.unsubscribeLinkEnquiry && this.unsubscribeLinkEnquiry();
		this.unsubscribeEnquiryOwned && this.unsubscribeEnquiryOwned();
		this.unsubscribeOwned && this.unsubscribeOwned();
		this.unsubscribeRequirements?.unsubscribe && this.unsubscribeRequirements.unsubscribe();
		this.unsubscribeAmsMe && this.unsubscribeAmsMe();
		this.setState({ fields: {} })
	}

	static getDerivedStateFromProps(props, state) {
		if (props.enquiryid !== state.enquiryID && state.enquiryID) {
			return {
				loading: true,
				enquiryID: null,
			};
		} else {
			return {
				loading: false,
			};
		}
	}

	scrollIntoComments = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let __comments = params.get('comments');
		let __tradeinID = params.get('tid');

		if (__comments && document.getElementById('enquiry-item-' + __comments)) {
			document.getElementById('enquiry-item-' + __comments).scrollIntoView({
				behavior: "smooth",
				block: "center"
			})
		}

		if (__tradeinID) this.handleEditTradeIn(__tradeinID)

	}

	onDeviceCollectionUpdate = querySnapshot => {

		const _scheduleData = [];
		querySnapshot.forEach(_data => {
			_scheduleData.push({
				..._data.data(),
				deviceName: _.find(this.props.dealersettings.tvDevices, { documentID: _data.data().deviceID }) ? _.find(this.props.dealersettings.tvDevices, { documentID: _data.data().deviceID }).name : null
			});
		});
		this.setState({
			scheduleData: _.orderBy(_scheduleData, ["startDate.seconds"], ["desc"])[0]
		});
	};

	//#endregion

	//#region NEW LEAD

	handleNewLeadOpen = () => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to create new enquiry.`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		}).then(result => {
			if (result.value) {
				this.setState({ showNewLeadModal: true });
			}
		});

	}

	handleNewLeadClose = (data) => {
		if (!_.isEmpty(data)) {
			let _existingEnquiry = Object.assign({}, this.state.fields);

			let state = Object.assign({}, objEnquiry);
			const { dealersettings, clientUsers } = this.props;
			state.addedBy = dealersettings ? dealersettings.id : '';
			state.addedDate = window.firebase.firestore.Timestamp.now();

			state.modifiedBy = dealersettings ? dealersettings.id : '';
			state.modifiedDate = window.firebase.firestore.Timestamp.now();
			state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, _existingEnquiry.clientID);

			state.pipeline = data.pipeline;
			state.stage = data.stage;
			state.owner = data.owner ? data.owner : '';
			state.status = data.status ? data.status : 'open';

			if (state.status)
				state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
					{
						date: window.firebase.firestore.Timestamp.now(),
						status: state.status,
						user: localStorage.uid
					}
				)

			state.stageDate = window.firebase.firestore.Timestamp.now();
			state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
			state.isDeleted = false;
			state.isNewEnquiry = true;

			if (!_.isEmpty(_existingEnquiry) && !_.isEmpty(_existingEnquiry.contact)) {
				const contact = Object.assign({}, _existingEnquiry.contact);
				state.contact = contact;
				state.isContact = true;
				state.contactID = contact.documentID;
				state.isNewEnquiry = false;
			}

			if (_.isEmpty(state.requirement)) {
				const roleID = _.find(clientUsers, { id: data.owner }) ? _.find(clientUsers, { id: data.owner }).roleID : '';
				const role = _.find(dealersettings.client.roles, { id: roleID });
				if (role && _.isArray(role.salesType) && role.salesType.length > 0) {
					state.requirement = {
						saleType: role.salesType[0]
					}
				}
			}

			const objData = Object.assign({}, state);
			//Remove empty value from object
			for (var propName in objData) {
				if (objData[propName] === null || objData[propName] === undefined || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
					delete objData[propName];
				}
			}

			objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

			let _comments = 'lost enquiry reference : ' + (_existingEnquiry.displayID ? _existingEnquiry.displayID : '');


			const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
			updateRef.set(objData, { merge: true }).then((docRef) => {
				let documentID = objData.documentID;
				toast.notify('Enquiry created sucessfully', { duration: 2000 });

				if (!_.isEmpty(_comments)) {
					this.saveCustomerlog(_comments, null, objData.documentID, objData.clientID);
				}

				if (objData.contactID) {
					const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
					updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
				}

				// lead reference ID Updated...
				const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
				updateRef.update({
					lostRefID: documentID,
					modifiedBy: dealersettings ? dealersettings.id : '',
					modifiedDate: window.firebase.firestore.Timestamp.now(),
					modifiedFrom: 'web'
				}).then((docRef) => {
					this.setState({ showNewLeadModal: false });
					this.props.history.push('/enquiry/details/' + objData.documentID);
				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});

			}).catch((error) => {
				console.error("Error updating contact_details: ", error);
			});
			//console.log('handleNewLeadClose', objData, _comments);

		}
		else {
			this.setState({ showNewLeadModal: false });
		}

	}
	//#endregion

	//#region CHANGE EVENT ENQUIRY

	handleOnChange = (e) => {
		const { name, value } = e.target;
		let state = Object.assign({}, this.state.fields);
		state[name] = value;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	handleOnCapChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		let fields = Object.assign({}, this.state.fields);
		if (value)
			fields[name] = _.trim(value.toUpperCase());
		else
			fields[name] = null;
		this.setState({ fields: fields }, () => { this.errorChange(name); });
	}

	errorChange = (key) => {
		let errors = Object.assign({}, this.state.errors);
		let fields = Object.assign({}, this.state.fields);
		let errorClass = 'input_error';
		if (!_.isEmpty(errors)) {
			if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
				if (key === 'status') {
					if (!this.state.enquiryInfo.lead)
						errors[key] = errorClass;
				}
				else
					errors[key] = errorClass;
			}
			else
				errors[key] = '';
			this.setState({ errors: errors });
		}
	};

	handleLostApprovalClose = (approvalStatus, notes, mentions) => {
		if (approvalStatus) {
			let state = Object.assign({}, this.state.fields);
			let _userID = (this.props.dealersettings.id || localStorage.uid);
			let _date = window.firebase.firestore.Timestamp.now();
			let objData = {};
			if (approvalStatus === 'approved') {
				objData.status = enquiryStatus.LOST;
				//objData = this.handleMoveStageForStatusChange(enquiryStatus.LOST, objData);
				objData.lastStatus = '';
				objData.lostDate = _date;
				objData = CommonHelper.removeBatchNumberOnLost(objData, state, enquiryOptionsDF);
			}

			if (approvalStatus === 'rejected') {
				objData.status = state.lastStatus;
				objData.lastStatus = '';
				objData.lostReason = '';
				objData.lostSubReason = '';
				objData.lostNotes = '';
				objData.lostDate = null;
				objData = this.handleRejectLostStatusChange(objData);
			}

			if (objData.status)
				objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
					{
						date: window.firebase.firestore.Timestamp.now(),
						status: objData.status,
						user: localStorage.uid
					}
				)

			// if (!_.isEmpty(mentions)) {
			// 	if (mentions.length > 0) {
			// 		let convertedIDs = []
			// 		let rawIDs = mentions.map(mentionObject => mentionObject.id)
			// 		rawIDs.forEach(id => {
			// 			if (id.includes(',')) {
			// 				let allIDs = id.split(',')
			// 				allIDs.forEach(a => {
			// 					convertedIDs.push(a)
			// 				})
			// 			} else {
			// 				convertedIDs.push(id)
			// 			}
			// 		})

			// 		if (objData.mentions && objData.mentions.length > 0) {
			// 			objData.mentions = _.uniq([...objData.mentions, ...convertedIDs])
			// 		} else {
			// 			objData.mentions = _.uniq(convertedIDs)
			// 		}
			// 	}
			// }

			objData.modifiedBy = _userID;
			objData.modifiedDate = _date;
			objData.modifiedFrom = 'web';

			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
			updateRef.set(objData, { merge: true }).then((docRef) => {

				if (approvalStatus === 'approved') this.checkTradeinCompleted(enquiryStatus.LOST);

				if (notes) {
					this.saveCustomerlog(notes, mentions, null, state.clientID);
				}
			}).catch((error) => {
				console.error("Error updating enquiries: ", error);
			});
		}
		this.setState({
			lostApprovalPopOver: { showpopover: false, targetpopover: '' }
		});
	}



	handlePipelineClose = async (data, origin) => {
		if (!_.isEmpty(data)) {
			let state = Object.assign({}, this.state.fields);
			//const _newStage = data.pipeline + '-' + data.stage;
			if (!_.isEmpty(origin) || state.isNewEnquiry || await this.isValidForStageUpdate(data.pipeline, data.stage, 'PipelineClose', data)) {

				const { dealersettings } = this.state;
				let _client = state.clientID && !_.isEmpty(dealersettings) &&
					!_.isEmpty(dealersettings.group) &&
					!_.isEmpty(dealersettings.group.clients) &&
					dealersettings.group.clients[state.clientID] ? dealersettings.group.clients[state.clientID] : dealersettings.client;

				const setttings = _client && _client.settings;

				let defaultstages = null;
				let defaultStatus = null;
				let statusSettings = null;
				let defaultMakes = null;
				let stages = null;
				let _allPipelines = !_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;
				if (data.pipeline) {
					let _selectedPipeline = _allPipelines.filter(e => e.value === data.pipeline)[0];
					defaultstages = Object.assign([], _selectedPipeline?.stages.filter(m => m.value !== 'InboundLead' && m.value !== 'Converted'));
					statusSettings = _selectedPipeline?.statusSettings;
					defaultStatus = _selectedPipeline?.status;
					defaultMakes = _selectedPipeline?.makes;
					stages = !_.isEmpty(defaultstages) && Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');
				}

				let _modules = !_.isEmpty(_allPipelines.filter(e => e.value === data.pipeline)[0]) ? _allPipelines.filter(e => e.value === data.pipeline)[0].modules : objModules;
				let modules = !_.isEmpty(_modules) ? _modules : objModules;



				if (state.stage !== data.stage || state.pipeline !== data.pipeline) {
					if (data.pipeline === 'LeadsBucket') {
						//state.status = null;
						state.isConverted = false;
						state.convertedBy = null;
						state.convertedDate = null;
					}

					let stageHistory = Object.assign([], state.stageHistory);

					let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
					if (!_.isEmpty(_stageData)) {
						stageHistory.push(_stageData);
						state.stageHistory = Object.assign([], stageHistory);
						state.pipeline = data.pipeline;
						state.stage = data.stage;
						state.stageDate = window.firebase.firestore.Timestamp.now();
					}
				}
				state.owner = data.owner ? data.owner : state.owner;
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();

				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
				let updateObj = {
					pipeline: state.pipeline,
					stage: state.stage,
					owner: state.owner,
					stageDate: state.stageDate,
					stageHistory: state.stageHistory,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: 'web'
				}
				if (origin === 'fromOpenLead' && data.status) {
					state.status = data.status;
					updateObj.status = data.status
					if (state.status)
						updateObj.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
							{
								date: window.firebase.firestore.Timestamp.now(),
								status: state.status,
								user: localStorage.uid
							}
						)
				};
				if (data.pipeline === 'LeadsBucket') {
					updateObj = {
						...updateObj,
						isConverted: false,
						convertedDate: null,
						status: null
					}
				}

				if (origin === 'fromOpenLead') {
					updateObj.wonDate = null;
					updateObj.lostDate = null;
					updateObj.lostReason = null;
					updateObj.lostSubReason = null;
					updateObj.lostNotes = '';
					updateObj.deliveryDate = null;
					state.wonDate = null;
					state.lostDate = null;
					state.lostReason = null;
					state.lostSubReason = null;
					state.lostNotes = '';
					state.deliveryDate = null;
				}
				await updateRef.update(updateObj).then((docRef) => {
					this.setState({
						fields: state,
						modules: modules,
						defaulStages: defaultstages,
						defaultStatus: defaultStatus,
						statusSettings: statusSettings,
						defaultMakes: defaultMakes,
						activeStageIndex: stages && stages.indexOf(data.stage)
					}, () => { this.errorChange(data.name); });

				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
			}
		}
		this.setState({
			pipelinePopOver: { showpopover: false, targetpopover: '' },
			showPipelineModal: false
		});
	}

	handleStageChange = async (id, name) => {
		//e.preventDefault();

		//const { id, name } = e.target;
		const { pipeline, status } = this.state.fields;

		if (pipeline === 'LeadsBucket' && name === 'Converted') {
			this.setState({ showLeadConvert: true, convertLeadLoader: false });
			return;
		}

		const { statusSettings } = this.state;
		let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === pipeline && e.stage === name)[0];
		if (_statusMndry && !_statusMndry.isModule && status !== _statusMndry.status) {
			let _status = CommonHelper.getNameByValue(enquiryAllStatus, _statusMndry.status)
			Swal.fire({
				title: CommonHelper.showLocale(this.props, 'Are you sure?'),
				text: `${CommonHelper.showLocale(this.props, 'You want to change enquiry status to')} ${_status.toUpperCase()}`,
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
				cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
				allowOutsideClick: false,
				allowEscapeKey: false
			}).then(result => {
				if (result.value) {
					this.handleStatusOptions(_statusMndry.status);
				}
			});
			return;
		}

		if (await this.isValidForStageUpdate(pipeline, name, 'stage', name)) {
			this.setState({
				activeStageIndex: id
			})
			let state = Object.assign({}, this.state.fields);

			let stageHistory = Object.assign([], state.stageHistory);
			let _stageData = CommonHelper.handleStageHistory(stageHistory, state.pipeline, name);
			if (!_.isEmpty(_stageData)) {
				stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], stageHistory);
				state.stage = name;
				state.stageDate = window.firebase.firestore.Timestamp.now();
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();

				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
				updateRef.update({
					stage: state.stage,
					stageDate: state.stageDate,
					stageHistory: state.stageHistory,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: 'web'
				}).then((docRef) => {
					this.setState({ fields: state });
				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});
			}
		}
	}

	onCurrencyChange = (name, e) => {
		//{formattedValue: "$9,856", value: "9856", floatValue: 9856}

		let state = Object.assign({}, this.state.fields);
		state[name] = e.floatValue;
		this.setState({ fields: state }, () => { this.errorChange(name) });
	}

	handleLeftActiveKey = (index) => {
		this.setState({ activeLeftKey: index });
	}
	handleRightActiveKey = (index) => {
		this.setState({ activeRightKey: index });
	}

	handleDeleteEnquiry = () => {
		const { enquiryInfo } = this.state;
		const { clientID } = this.state.fields;

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to delete ${enquiryInfo.lead ? 'lead' : 'enquiry'}?`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
		}).then(result => {
			if (result.value) {
				let state = Object.assign({}, this.state.fields);
				state.isDeleted = true;
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();

				const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
				updateRef.update({
					isDeleted: state.isDeleted,
					modifiedBy: state.modifiedBy,
					modifiedDate: state.modifiedDate,
					modifiedFrom: 'web'
				}).then((docRef) => {
					var objRecord = {
						id: this.state.enquiryID,
						clientid: clientID
					}

					if (state.contactID) {
						const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
						updateRepeatCustomer({ 'contactID': state.contactID, 'projectId': state?.projectId ? state.projectId : null })
					}
					//CommonHelper.saveChangeLog(clientID, objRecord.id, null, (enquiryInfo.lead ? 'Lead' : 'Enquiry') + ' deleted', 'enquiry', '');
					if (!this.props.isFromLayout)
						this.props.handleRouteRemove(objRecord, state.isAfterSales ? 'afterSales' : 'enquiry');
					toast.notify(`${enquiryInfo.lead ? 'Lead' : 'Enquiry'} deleted successfully.`, {
						duration: 2000,
					});
					this.setState({ fields: state });

					let logNotes = 'Enquiry deleted';
					if (!_.isEmpty(state) && state.displayID)
						logNotes = 'Enquiry: ' + state.displayID + ' deleted';

					let _objLogData = {
						notes: logNotes,
						type: 'enquiries',
						subType: 'delete',
						recordId: this.state.enquiryID,
					}
					_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.state.dealersettings);
					CommonHelper.saveAuditLog(_objLogData)
					this.handleLayoutClose();

				}).catch((error) => {
					console.error("Error updating enquiries: ", error);
				});

			}

		});

	}

	handleLostReasonsClose = data => {
		if (!_.isEmpty(data)) {
			!_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
				this.handleSoldRequirements(requirement.documentID, false, false);
			});
			this.saveEnquiryStatus(enquiryStatus.LOST);
		}
		this.setState({
			lostModal: { show: false },
		});
	};

	handleLostReasonsOpen = (e) => {
		this.setState({
			lostModal: {
				show: true
			}
		});
	}

	handleDeliveryOptionClose = data => {
		if (!_.isEmpty(data)) {
			this.saveEnquiryStatus(enquiryStatus.DELIEVERD);
		}
		this.setState({
			deliveryModal: { show: false },
		});
	};

	handleDeliveryOptionOpen = (e) => {
		this.setState({
			deliveryModal: {
				show: true
			},
		});
	}

	handleWalkinClose = data => {

		this.setState({
			walkinModal: { show: false, editID: null },
		});
	};

	handleEditWalkin = id => {
		this.setState({
			walkinModal: { show: true, editID: id },
		});
	}

	handleStatusOptions = (status) => {
		if (status === enquiryStatus.OPEN)
			this.handleEnquiryOpen();
		else if (status === enquiryStatus.WON)
			this.handleEnquiryWon();
		else if (status === enquiryStatus.LOST)
			this.handleEnquiryLost();
		else if (status === enquiryStatus.DELIEVERD)
			this.handleEnquiryDelivered();
		else if (status === enquiryStatus.PROSPECTLOST)
			this.handleEnquiryProspectLost();
		else if (status)
			this.handleDynamicStatus(status)
	}

	handleEnquiryOptionClose = (status, _statusValue) => {
		if (!_.isEmpty(status)) {

			let _enquiryModal = Object.assign({}, this.state.enquiryModal)
			//	console.log('handleEnquiryOptionClose', status, this.state.defaulStages)
			if (status === enquiryStatus.OPEN)
				this.handleEnquiryOpen();
			else if (status === enquiryStatus.WON)
				this.handleEnquiryWon();
			else if (status === enquiryStatus.LOST)
				this.handleEnquiryLost();
			else if (status === enquiryStatus.DELIEVERD)
				this.handleEnquiryDelivered();
			else if (status === enquiryStatus.PROSPECTLOST)
				this.handleEnquiryProspectLost();
			else if (status === 'addContact')
				this.handleMandatoryContactOpen(status)
			else if (status === 'testDrive' || status === 'isTestDrive')
				this.handleOpenTestDriveModal()
			else if (status === 'tradeIn' || status === 'isTradeIn')
				this.isValidForTradeIn()
			else if (status === 'tradeInLink')
				this.isValidForTradeIn(true)
			else if (status === 'tradeInLinkAppraisal')
				this.isValidForTradeIn(false, true)
			else if (status === 'requirement')
				this.isValidForRequirement()
			else if (status === 'isFinance') {

				const { dealersettings } = this.state;
				const drivaEnabled = ((!_.isEmpty(dealersettings) &&
					!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
					dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
					dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
					dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
				) ? true : false);

				if (drivaEnabled)
					this.handleAddDrivaFinanceOpen();
				else
					this.handleAddFinanceOpen();
			}
			else if (status === 'isQuotation')
				this.handleAddQuotation(this.state.enquiryID);
			else if (status === 'save')
				this.handleSave();
			else if (status === 'pushToDMS')
				this.handlePushErapower();
			else if (status === 'pushToPMDS')
				this.handlePushPMDS();
			else if (status === 'pushToKeyloop')
				this.handlePushKeyloop();
			else if (status === 'pushToRevolution')
				this.handlePushRevolution();
			else if (status === 'pushToAutoIT')
				this.handlePushAutoIT();
			else if (status === 'pushToTitan')
				this.handlePushTitan();
			else if (status === 'pushToCAP')
				this.handlePushCAP();
			else if (status === 'pushToReserveVehicle')
				this.handleReserveVehicle();
			else if (status === 'leadConvert')
				this.handleLeadConvertShow();
			else if (status === 'PipelineClose' && _enquiryModal.newStatus == 'PipelineClose' && _enquiryModal.newStatusValue)
				this.handlePipelineClose(_enquiryModal.newStatusValue)
			else if (['financeStatus', 'afterMarketStatus'].includes(status) && ((['financeStatus', 'afterMarketStatus'].includes(_enquiryModal.newStatus) && _enquiryModal.newStatusValue) || _statusValue))
				this.handleFinanceStatus((_enquiryModal.newStatusValue || _statusValue), status)
			else if (status === 'stage' && ((['stage'].includes(_enquiryModal.newStatus) && _enquiryModal.newStatusValue) || _statusValue)) {
				var indexVal = this.state.defaulStages.findIndex(x => x.value === (_enquiryModal.newStatusValue || _statusValue));
				if (indexVal >= 0) {
					this.handleStageChange(indexVal, (_enquiryModal.newStatusValue || _statusValue))
				}
			}
			else if (status === 'activity') {
				//this.handleDynamicStatus(status)
			}
			else if (status)
				this.handleDynamicStatus(status)

		}
		this.setState({
			enquiryModal: {
				show: false,
				errors: null,
				mandatoryFields: null,
				newStatus: null,
				newStatusValue: null,
				isModifyInboundOwner: false,
				ismodifyStatusDate: false
			}
		});
	};

	//#endregion

	//#region  SAVE ENQUIRY INFO

	cancelEnquiryField = (name, val) => {
		let state = Object.assign({}, this.state.fields);
		state[name] = val;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	handleEnquiryWon = async () => {
		const { isVehicle, requirement } = this.state.fields;
		const { modules, requirements } = this.state;

		if (!_.isEmpty(requirements) && _.isEmpty(requirement)) {
			Swal.fire(CommonHelper.showLocale(this.props, "Mark a vehicle as primary to continue."), "", "info");
			return false;
		}
		else if (await this.isValidForStatusUpdate(enquiryStatus.WON)) {
			//toast.notify('Enquiry WON successfully.-----------------', { duration: 2000 });
			if (isVehicle && !_.isEmpty(modules) && modules.requirement)
				this.handleRequirentsForWon(enquiryStatus.WON);
			else
				this.saveEnquiryStatus(enquiryStatus.WON);
		}
	}

	handleEnquiryDelivered = async (e) => {
		const { status, isVehicle } = this.state.fields;

		const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)
		if (isStatusSold || status == enquiryStatus.WON) {
			if (await this.isValidForStatusUpdate(enquiryStatus.DELIEVERD)) {
				this.saveEnquiryStatus(enquiryStatus.DELIEVERD);
			}
		}
		else { // when status delivered before enquiry won
			if (await this.isValidForStatusUpdate(enquiryStatus.DELIEVERD, null, enquiryStatus.WON)) {

				const { modules } = this.state;
				if (isVehicle && !_.isEmpty(modules) && modules.requirement)
					this.handleRequirentsForWon(enquiryStatus.WON, enquiryStatus.DELIEVERD);
				else {
					await this.saveEnquiryStatus(enquiryStatus.WON);
					this.saveEnquiryStatus(enquiryStatus.DELIEVERD);
				}
			}
		}


	}

	handleEnquiryLost = async (e) => {
		if (await this.isValidForStatusUpdate(enquiryStatus.LOST)) {
			!_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
				this.handleSoldRequirements(requirement.documentID, false, false);
			})
			this.saveEnquiryStatus(enquiryStatus.LOST);
		}
	}

	handleEnquiryProspectLost = async () => {
		if (await this.isValidForStatusUpdate(enquiryStatus.PROSPECTLOST)) {
			!_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
				this.handleSoldRequirements(requirement.documentID, false, false);
			})
			this.saveEnquiryStatus(enquiryStatus.PROSPECTLOST);
		}
	}

	handleEnquiryOpen = async () => {
		if (await this.isValidForStatusUpdate(enquiryStatus.OPEN)) {
			this.setState({ showPipelineOnOpen: true });
			// !_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
			// 	this.handleSoldRequirements(requirement.documentID, false, false);
			// })
			//this.saveEnquiryStatus(enquiryStatus.OPEN);
		}
	}

	handleDynamicStatus = async (_status) => {
		const { dealersettings } = this.state;
		const { status } = this.state.fields;
		const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
		let _objCurrentStatus = _status && enquiryAllStatus.filter(e => e.value === _status)[0];

		if (status === 'prospectLost' && _status === enquiryStatus.OPEN)
			this.saveEnquiryField('status', _status);
		else if (_status === enquiryStatus.WON)
			this.handleEnquiryWon();
		else if (_status === enquiryStatus.LOST)
			this.handleEnquiryLost();
		else if (_status === enquiryStatus.DELIEVERD)
			this.handleEnquiryDelivered();
		else if (_status === enquiryStatus.PROSPECTLOST)
			this.handleEnquiryProspectLost();
		else if (!_.isEmpty(_objCurrentStatus) && await this.isValidForStatusUpdate(_status)) {
			//toast.notify(`ENQUIRY ${_status} successfully.======`, { duration: 2000 });
			this.saveEnquiryField('status', _status);
		}
		// else if (_status === enquiryStatus.OPEN)
		// 	this.handleEnquiryOpen();

	}

	handleFinanceStatus = async (_status, name) => {
		if (_status && await this.isValidForFinanceStatusUpdate(_status, name)) {
			this.saveEnquiryField(name, _status);
			setTimeout(() => this.errorChange(name), 500);
		}
		else if (_.isEmpty(_status)) {
			this.saveEnquiryField(name, null);
		}
		return true;
	}

	handleOpenLeadClose = async (data) => {
		if (!_.isEmpty(data)) {
			await this.handlePipelineClose(data, 'fromOpenLead');
			!_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
				this.handleSoldRequirements(requirement.documentID, false, false);
			})
			if (!data.status)
				this.saveEnquiryStatus(enquiryStatus.OPEN, true);

		}
		this.setState({ showPipelineOnOpen: false, isAccessToReOpen: false });
	}

	handleDeliveryDateChange = (val, name) => {
		if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

		this.saveEnquiryField(name, val);
		setTimeout(() => this.errorChange(name), 500);
	};

	handleTimeChange = (val, name) => {
		//console.log(val)
		if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(val)._d)

		this.saveEnquiryField(name, val);
		setTimeout(() => this.errorChange(name), 500);
	};

	handleDateChange = (val, name) => {
		if (val) val = moment(val).format('YYYY-MM-DD');

		this.saveEnquiryField(name, val);
		setTimeout(() => this.errorChange(name), 500);
	};

	handlePIDateSave = (val, name) => {
		if (val) val = moment(val).format('MMM YYYY');

		this.saveEnquiryField(name, val);
		setTimeout(() => this.errorChange(name), 500);
	};

	handleSelectSave = async (e, data) => {
		if (['financeStatus', 'afterMarketStatus'].includes(data.name)) {
			this.handleFinanceStatus((e?.value || ''), data.name)
		}
		else {
			this.saveEnquiryField(data.name, (e?.value ? e.value : ''));
			setTimeout(() => this.errorChange(data.name), 500);
		}

		if (data.name === 'owner' && e.value) {
			const ref = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/quotations`)
			const snapshots = await ref.get()
			if (snapshots.size > 0) {
				let quotations = []
				snapshots.docs.forEach((doc) => {
					quotations.push(doc.data())
				});

				const batch = firestoreDB(this.props.dealersettings).firestore().batch();

				quotations.forEach(quote => {
					batch.set(firestoreDB(this.props.dealersettings).firestore().doc(`enquiries/${this.props.enquiryid}/quotations/${quote.documentID}`), {
						owner: e.value
					}, { merge: true });
				})
				batch.commit().catch((error) => {
					console.error("Error updating document: ", error);
				});
			}
		}
		// if (data.name === 'financeBM' && e.value) {
		// 	const { documentID, clientID, displayID, contact } = this.state.fields;
		// 	let _reqObject = {
		// 		"userID": e.value,
		// 		"notification": {
		// 			title: 'Finance Enquiry',
		// 			body: displayID + (contact ? ' - ' + CommonHelper.displayContactName([], contact) : ''),
		// 			type: 'finance',
		// 			recordID: documentID,
		// 			clientID: clientID
		// 		}
		// 	}
		// 	const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
		// 	genericNotify(_reqObject)
		// }

		// if (data.name === 'afterMarketBM' && e.value) {
		// 	const { documentID, clientID, displayID, contact } = this.state.fields;
		// 	let _reqObject = {
		// 		"userID": e.value,
		// 		"notification": {
		// 			title: 'After Market Enquiry',
		// 			body: displayID + (contact ? ' - ' + CommonHelper.displayContactName([], contact) : ''),
		// 			type: 'afterMarket',
		// 			recordID: documentID,
		// 			clientID: clientID
		// 		}
		// 	}
		// 	const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
		// 	genericNotify(_reqObject)
		// }
	};

	handleLostReasonSave = (e, data) => {
		const { lostSubReason } = this.state.fields;
		const { lostReasons } = this.state;
		const lostSubReasons = [];
		if (e?.value && !_.isEmpty(lostReasons.filter(m => m.value === e?.value)[0].subList)) {
			lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
				lostSubReasons.push({
					value: doc.value,
					label: doc.name
				});
			});
		}
		this.setState({ lostSubReasons: lostSubReasons });
		this.saveEnquiryField(data.name, (e?.value || ''));
		if (lostSubReason)
			this.saveEnquiryField('lostSubReason', null);

		setTimeout(() => this.errorChange(data.name), 500);
	};

	handleCheckChange = (e) => {
		const { name, checked } = e.target;
		this.saveEnquiryField(name, checked);
		setTimeout(() => this.errorChange(name), 500);
	}

	handleOptionsChange = (e) => {
		const { name, checked } = e.target;

		let state = Object.assign({}, this.state.fields);
		let _reqOtherInfo = Object.assign([], state.options);

		if (checked)
			_reqOtherInfo.push(name)
		else
			_reqOtherInfo = _reqOtherInfo.filter(m => m !== name);

		this.saveEnquiryField('options', _reqOtherInfo);
		setTimeout(() => this.errorChange(name), 500);
	}

	isPIValid = (date) => {
		let _date = moment(date, 'MMM YYYY');
		if (moment().isBefore(_date) || moment().isSame(_date, 'month')) return '';
		else return 'form-alert ';
	};

	handleSittingDays = _stage => {
		const { stageHistory, pipeline } = this.state.fields;
		//console.log('handleSittingDays', _stage, stageHistory)
		let diff = '0 day';

		if (!_.isEmpty(stageHistory) && stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
			let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
			if (_stageDays) {
				const date = moment.unix(_stageDays.date.seconds); //.fromNow()
				diff = moment().startOf('day').diff(date.startOf('day'), 'days');
				diff = diff + (_stageDays.sittingDays ? parseInt(_stageDays.sittingDays) : 0);

				if (diff === 1) {
					diff = diff + 'day';
				} else if (diff > 0) {
					diff = diff + 'days';
				}
			}
		}

		return diff;
	};

	handleStageComplete = _stage => {
		const { stageHistory, pipeline } = this.state.fields;
		//console.log('handleStageComplete', _stage, stageHistory)
		let response = 'active-disable';
		if (!_.isEmpty(stageHistory) &&
			stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
			let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
			if (!_.isEmpty(_stageDays)) {
				response = 'active';
			}
		}
		return response;
	};

	handleSaveEnquiryInfo = (e) => {
		e.preventDefault();

		let errors = {};
		let formIsValid = true;
		let fields = Object.assign({}, this.state.fields);

		let errorClass = 'input_error';

		for (let [key] of Object.entries(fields)) {
			if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
				if (key === 'status') {
					if (!this.state.enquiryInfo.lead) {
						formIsValid = false;
						errors[key] = errorClass;
					}
				}
				else {
					formIsValid = false;
					errors[key] = errorClass;
				}
			}
		}

		this.setState({ errors: errors });

		if (formIsValid === true) {
			this.saveEnquiryInfo(this);
		} else {
			let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
			if (id && document.getElementById(id)) {
				document.getElementById(id).scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
			return formIsValid;
		}
	};

	saveEnquiryInfo = (e) => {
		let state = Object.assign({}, this.state.fields);

		state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
		state.modifiedDate = window.firebase.firestore.Timestamp.now();
		state.modifiedFrom = 'web';

		const objData = Object.assign({}, state);
		//Remove empty value from object
		for (var propName in objData) {
			if (
				objData[propName] === null ||
				objData[propName] === undefined ||
				objData[propName] === '' ||
				(_.isObject(objData[propName]) && _.isEmpty(objData[propName]))
			) {
				delete objData[propName];
			}
		}

		const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
		updateRef.update(objData).then((docRef) => {
			toast.notify('Enquiry updated successfully.', {
				duration: 2000
			})
			this.setState({ fields: state });
		}).catch((error) => {
			console.error("Error updating enquiries: ", error);
		});
	};

	saveEnquiryStatus = async (val, _isReopen) => {
		await this.saveEnquiryField('status', val, _isReopen);

		if (val === enquiryStatus.DELIEVERD) {
			const { dealersettings } = this.props;
			const { requirements, contact } = this.state;
			const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
			if (!enableServicePackages && contact.documentID && !_.isEmpty(requirements)) {
				requirements.filter(e => e.isSold === true).forEach(_requirement => {
					this.handleSaveOwnedVehicle(_requirement, contact.documentID, _requirement.documentID)

					if (!_.isEmpty(_requirement.stock))
						this.handleChangeStockStatus(_requirement.stock.documentID, stockStatus.DELIVERED);
				});
			}
		}
		else if (val === enquiryStatus.OPEN) {
			const { dealersettings } = this.props;
			const { requirements, contact } = this.state;
			const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
			if (!enableServicePackages && contact.documentID && !_.isEmpty(requirements)) {
				requirements.forEach(_requirement => {
					this.handleDeleteOwnedVehicle(_requirement.documentID)
				});
			}
		}
	}

	saveEnquiryField = async (name, val, _isReopen) => {

		if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
			this.errorChange(name);
		}
		else {
			let objData = {};
			let state = Object.assign({}, this.state.fields);
			const { dealersettings, requirements } = this.state;
			//let _logNote = '';
			//_logNote = (_logNote ? _logNote + '\n' : '') + this.bindLogs(name, state[name], val);
			if (name !== 'isFav') {
				state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
				state.modifiedDate = window.firebase.firestore.Timestamp.now();
			}

			//09-11-2020
			// if (name === 'isDeleted') {
			// 	objData.isNewEnquiry = false;
			// }

			// if (name === 'status' && val !== enquiryStatus.LOST) {
			// 	delete state['lostReason'];
			// 	delete state['lostSubReason'];
			// }
			if (name === 'financeBM' && !state.isFinanceRequired) {
				objData.isFinanceRequired = true;
			}

			if (name === 'status') {
				if (state.isNewEnquiry)
					objData.isNewEnquiry = false;

				if (val === enquiryStatus.OPEN) {
					objData.wonDate = null;
					objData.lostDate = null;
					objData.lostReason = null;
					objData.lostSubReason = null;
					objData.lostNotes = '';
					objData.deliveryDate = null;
					//_logNote = (_logNote ? _logNote + '\n' : '') + this.bindLogs('wonDate', state.wonDate, objData.wonDate);
					//_logNote = (_logNote ? _logNote + '\n' : '') + this.bindLogs('lostDate', state.lostDate, objData.lostDate);
					//_logNote = (_logNote ? _logNote + '\n' : '') + this.bindLogs('lostReason', state.lostReason, objData.lostReason);
					//_logNote = (_logNote ? _logNote + '\n' : '') + this.bindLogs('deliveryDate', state.deliveryDate, objData.deliveryDate);
				}

				if (val === enquiryStatus.WON || val === enquiryStatus.CLOSED) {
					objData.wonDate = state.modifiedDate;
				}

				if (val === enquiryStatus.LOST) {
					objData.lostDate = state.modifiedDate;


					const lostApprovalEnabled = ((!_.isEmpty(dealersettings) &&
						!_.isEmpty(dealersettings.client) &&
						!_.isEmpty(dealersettings.client.clientSettings) &&
						dealersettings.client.clientSettings.lostApproval) ? true : false);

					const _permissions = ((!_.isEmpty(dealersettings) &&
						!_.isEmpty(dealersettings.rolePermissions) &&
						!_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);
					const lostApproval = ((!_.isEmpty(_permissions) && _permissions.lostApproval) ? true : false);

					if (!state.isAfterSales && lostApprovalEnabled && !lostApproval) {
						val = enquiryStatus.PENDINGLOST;
						objData.lastStatus = state.status;
					}
				}

				if (val === enquiryStatus.PROSPECTLOST) {
					objData.lostDate = state.modifiedDate;
				}

				if (val === enquiryStatus.DELIEVERD)
					objData.deliveredDate = state.modifiedDate;

				let __status = val === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : val;
				objData = this.handleMoveStageForStatusChange(__status, objData);

				if (val) {
					let _dynamicStatus = Object.assign({}, state.dynamicStatus)
					_dynamicStatus[val] = window.firebase.firestore.Timestamp.now();
					objData['dynamicStatus'] = _dynamicStatus;
				}

				if ((val === enquiryStatus.WON || val === enquiryStatus.DELIEVERD) && state?.requirement?.documentID) {
					const _requirement = Object.assign({}, requirements.filter((e) => e.documentID === state.requirement.documentID)[0]);
					if (!_.isEmpty(_requirement)) objData.requirement = _requirement;
				}

			}

			if (name === 'isNewEnquiry') {
				let __status = state.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : state.status;
				objData = this.handleMoveStageForStatusChange(__status, objData);
			}

			if (name === 'status' && val === enquiryStatus.WON && state.isAfterSales) {
				state[name] = enquiryStatus.CLOSED;
				objData[name] = enquiryStatus.CLOSED;
			}
			else {
				state[name] = val;
				objData[name] = val;
			}

			objData.modifiedBy = state.modifiedBy;
			objData.modifiedDate = state.modifiedDate;
			objData.modifiedFrom = 'web';

			this.setState({ fields: state });
			const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
			await updateRef.set(objData, { merge: true }).then((docRef) => {
				if (name === 'status')
					toast.notify('Enquiry updated successfully.', {
						duration: 2000,
					});

				if (name === 'isFinanceRequired' && val && dealersettings?.rolePermissions?.permissions?.accessToFinanceManager === true) {
					this.setState({ enquiryModal: { show: true, financeRequired: true } });
				}
				//CommonHelper.saveChangeLog(this.state._clientID, this.state.enquiryID, null, _logNote, 'enquiry', '');
				if (name === 'campaign' || name === 'origin') {
					this.saveContactOriginAndCampaign(objData);
				}

				if (name === 'enquiryType' && val && (val.toLowerCase().indexOf('walk') >= 0 || val.toLowerCase().indexOf('drive') >= 0)) {
					this.handleSaveWalkin(this.state.enquiryID, state.clientID)
				}

				if (name === 'status' || name === 'isNewEnquiry') {
					const { contact, isContact, displayID, documentID } = this.state.fields;
					let isExist = (this.state.enquiryInfo.new && objData.isNewEnquiry === false) ? true : false;
					let objNav = Object.assign({}, {
						id: this.state.enquiryID,
						status: state.status && state.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : state.status,
						name: CommonHelper.displayName(state),
					});

					if (isExist) {
						objNav.isNew = null;
						objNav.params = null;

						this.setState({
							enquiryInfo:
							{
								new: false,
								lead: false
							}
						})
					}
					this.props.updatenavbar(objNav);
				}

				if (name === 'status' && (val === enquiryStatus.WON || val === enquiryStatus.CLOSED || val === enquiryStatus.DELIEVERD)) {
					CommonHelper.savePrimaryFieldsToEnquiry(state, this.state.enquiryID, null, dealersettings);
				}

				if (state.contactID && name === 'status' && (val === enquiryStatus.WON || val === enquiryStatus.CLOSED || val === enquiryStatus.OPEN)) {
					const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
					updateRepeatCustomer({ 'contactID': state.contactID, 'projectId': state?.projectId ? state.projectId : null })
				}

				if (name === 'deliveryDate') {
					const { requirements, contact } = this.state;
					if (contact.documentID && !_.isEmpty(requirements)) {
						requirements.filter(e => e.isSold === true).forEach(_requirement => {
							if (!_.isEmpty(_requirement.stock))
								this.handleChangeStockStatus(_requirement.stock.documentID, null, val);
						});
					}
				}

				if (name === 'status' && (_isReopen || val === enquiryStatus.WON || val === enquiryStatus.LOST || val === enquiryStatus.DELIEVERD)) {
					this.checkTradeinCompleted(val, _isReopen);
				}

				if (name === 'status' && (val === enquiryStatus.PROSPECTLOST || val === enquiryStatus.LOST)) {
					CommonHelper.removeBatchNumberFromEnquiry(state, enquiryOptionsDF, dealersettings);
				}


			}).catch(error => {
				console.error('Error updating enquiries: ', error);
			});
		}
	};

	checkTradeinCompleted = async (val, _isReopen) => {
		const { tradeIns, enquiryID } = this.state;
		const { dealersettings } = this.props;
		if (_isReopen) {
			enquiryHelper.reopenAllCompletedTradeinPro(dealersettings, enquiryID);

		} else {
			const modifyTradeInProStatusAccess = (dealersettings?.rolePermissions?.permissions?.modifyTradeInProStatusAccess ? true : false);
			let _tradeinCompleted = tradeIns.filter(e => !_.isEmpty(e.tradeinProID) && (_isReopen ? [tipStatus.WON, tipStatus.LOST].includes(e.status) : e.status === tipStatus.COMPLETED))
			if (modifyTradeInProStatusAccess && !_.isEmpty(_tradeinCompleted)) {
				const mandTradeinStatusonWon = dealersettings?.client?.clientSettings?.mandTradeinStatusonWon ? true : false;
				this.setState({
					completedTradeinsList: {
						show: true,
						isMandatory: (mandTradeinStatusonWon || (val === enquiryStatus.WON || _isReopen ? false : true)),
						//isReopen: _isReopen
					}
				})
			}
		}


	}

	saveContactOriginAndCampaign = (objData) => {
		const { fields, contact } = this.state;
		const { campaign, origin } = objData;

		let _contact = !_.isEmpty(contact) ? contact : fields.contact;
		let _objData = {};

		if (campaign && _contact && !_contact.campaign) _objData.campaign = campaign;
		if (origin && _contact && !_contact.origin) _objData.origin = origin;

		let _contactID = fields.contactID ? fields.contactID : (!_.isEmpty(fields.contact) ? fields.contact.documentID : (!_.isEmpty(contact) ? contact.documentID : null));

		CommonHelper.saveContactOriginAndCampaign(_contactID, _objData.campaign, _objData.origin, this.props);
	}

	handleMoveStageForStatusChange = (_status, state) => {
		const { statusSettings } = this.state;
		const { stageHistory, requirement, statusHistory } = this.state.fields;

		let _make = requirement?.stock?.make ? requirement?.stock?.make : requirement?.make;
		let _saleType = requirement?.saleType;
		let _statusArray = !_.isEmpty(statusSettings) ? statusSettings.filter(e => e.status === _status) : []
		let data = null;
		if (!_.isEmpty(_statusArray)) {
			if (!_.isEmpty(_make) && !_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0])
				data = _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0]
			else if (!_.isEmpty(_make) && _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0])
				data = _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0]
			else if (!_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0])
				data = _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0];
			else if (_statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0])
				data = _statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0];
		}

		if (!_.isEmpty(data) && data.pipeline && data.stage) {
			let _stageHistory = Object.assign([], stageHistory);
			let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
			if (!_.isEmpty(_stageData)) {
				_stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], _stageHistory);
				state.pipeline = data.pipeline;
				state.stage = data.stage;
				state.stageDate = window.firebase.firestore.Timestamp.now();
			}

		}
		if (_.isEmpty(state.statusHistory)) {
			state.statusHistory = statusHistory || [];
		}
		state.statusHistory.push(
			{
				date: window.firebase.firestore.Timestamp.now(),
				status: _status || null,
				user: localStorage.uid
			}
		)

		return state;
	}

	handleRejectLostStatusChange = (state) => {
		const { stageHistory, pipeline, stage } = this.state.fields;
		let statusSettings = this.state.statusSettings;

		if (_.isEmpty(statusSettings)) {
			let _previousStage = !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null
			let _selPipeline = !_.isEmpty(_previousStage) && this.props.dealersettings.allPipelines.filter(e => e.value === _previousStage.pipeline)[0];
			statusSettings = _selPipeline && _selPipeline.statusSettings;
		}

		let _statusSettingData = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === 'lost' && e.pipeline === pipeline && e.stage === stage)[0];

		let data = !_.isEmpty(_statusSettingData) && !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null;

		if (!_.isEmpty(data) && data.pipeline && data.stage) {
			let _stageHistory = Object.assign([], stageHistory);
			let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
			if (!_.isEmpty(_stageData)) {
				stageHistory.push(_stageData);
				state.stageHistory = Object.assign([], _stageHistory);
				state.pipeline = data.pipeline;
				state.stage = data.stage;
				state.stageDate = window.firebase.firestore.Timestamp.now();
			}
			return state;
		}
		else
			return state;
	}

	handleSaveWalkin = (enquiryID, _clientID) => {
		this.isLoading = true;
		const { dealersettings } = this.state;

		let userID = dealersettings ? dealersettings.id : '';
		let clientID = dealersettings ? dealersettings.client.id : '';
		let currenDate = window.firebase.firestore.Timestamp.now();

		let objData = Object.assign({}, objWalkIn);
		objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc().id;
		objData.addedBy = userID;
		objData.addedDate = currenDate;
		objData.modifiedBy = userID;
		objData.modifiedDate = currenDate;
		//objData.clientID = _clientID ? _clientID : clientID;
		objData.enquiryID = enquiryID;
		objData.walkinDate = currenDate;
		objData.modifiedFrom = 'web';

		objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, _clientID)

		const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
		refOnjData.set(objData, { merge: true }).then((docRef) => {
			if (this.state.fields && (this.state.fields.bdcStatus === 'allocated' || this.state.fields.bdcStatus === 'noshow')) {
				firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
					bdcStatus: 'show'
				}, { merge: true })
			}
			CommonHelper.logFirstWalkin(enquiryID, currenDate, dealersettings);
		}).catch((error) => {
			console.error("Error updating walkin_details: ", error);
		});

	}
	//#endregion

	//#region MANDATORY CHECK

	getMandatoryFieldsByType = (type, _newStatus) => {
		const { dealersettings } = this.state;
		const { status, isAfterSales } = this.state.fields;
		let _mandEnquiryStatus;
		let _response = [];
		if (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.settings) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields.status))
			_mandEnquiryStatus = dealersettings.client.settings.mandatoryFields.status;

		if (!_.isEmpty(_mandEnquiryStatus) && !isAfterSales && (status || _newStatus)) {
			let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === (_newStatus ? _newStatus : status))[0];

			_newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

			if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type]) {
				_response = _.uniq(_newStatusMandatoryFields[type].split(','));
			}
		}
		else {
			let _newStatusMandatoryFields = [];
			_newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

			if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type])
				_response = _.uniq(_newStatusMandatoryFields[type].split(','))

		}
		return _response;
	}

	isValidForFinanceStatusUpdate = async (_newStatus, name, origin) => {
		const { dealersettings } = this.state;

		let _mandEnquiryStatus;
		let _isValid = true;
		if (!_.isEmpty(dealersettings?.client?.settings?.mandatoryFields?.[name]))
			_mandEnquiryStatus = dealersettings.client.settings.mandatoryFields[name];

		if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
			let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

			if (!_.isEmpty(_newStatusMandatoryFields)) {
				_isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : name), _newStatus)
			}
		}

		return _isValid;
	}

	isValidForStatusUpdate = async (_newStatus, origin, _previousStatus) => {
		const { dealersettings } = this.state;
		const { isAfterSales } = this.state.fields;

		if (isAfterSales && _newStatus === enquiryStatus.WON)
			return true;

		let _mandEnquiryStatus;
		let _isValid = true;
		if (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.settings) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields.status))
			_mandEnquiryStatus = dealersettings.client.settings.mandatoryFields.status;

		if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
			let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

			if (_previousStatus)
				_newStatusMandatoryFields = this.mergeNewAndPreviousStatus(_mandEnquiryStatus, Object.assign({}, _newStatusMandatoryFields), _previousStatus);

			_newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);
			if (!_.isEmpty(_newStatusMandatoryFields)) {
				_isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))
			}
		}
		else {
			let _newStatusMandatoryFields = [];

			_newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);

			_isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))

		}

		const isStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, _newStatus)
		if ((isStatusOpen || _newStatus === enquiryStatus.OPEN) && !_isValid)
			this.setState({ isAccessToReOpen: true });

		return _isValid;
	}

	isValidForStageUpdate = async (newPipeline, newStage, origin, originValue) => {
		const { pipeline, stage } = this.state.fields;
		const _newStage = newPipeline + '-' + newStage;
		const _oldStage = pipeline + '-' + stage;
		const { dealersettings } = this.state;

		let _mandPiplines;
		let _isValid = true;
		if (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.settings) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields.pipeline))
			_mandPiplines = dealersettings.client.settings.mandatoryFields.pipeline;

		if (!_.isEmpty(_mandPiplines)) {

			let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
			let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

			if (!_.isEmpty(__newPipeline)) {

				if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
					for (let [key] of Object.entries(fieldsData)) {
						__newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
					}
				}
				//	console.log('isValidFor StageUpdate', '==>', _newStage, '==>', _oldStage, '==>', __newPipeline, '==>', __oldPipeline);
				_isValid = await this.handleMandatoryPermission(__newPipeline, (origin ? origin : newStage), originValue)
				//	console.log('isValidFor StageUpdate-Response', _isValid);
			}
			else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
				for (let [key] of Object.entries(fieldsData)) {
					__oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
				}

				//	console.log('isValidForStageUpdate', '==>', _oldStage, '==>', __oldPipeline);
				_isValid = await this.handleMandatoryPermission(__oldPipeline, (origin ? origin : newStage), originValue)
				//	console.log('isValidFor StageUpdate-Response', _isValid);
			}
		}

		return _isValid;
	}

	mergeNewAndPreviousStatus = (_mandEnquiryStatus, _newMandatoryStatus, _prevStatus) => {
		if (!_.isEmpty(_mandEnquiryStatus)) {
			let __oldPipeline = _mandEnquiryStatus.filter(e => e.name === _prevStatus)[0];

			if (!_.isEmpty(__oldPipeline)) {
				for (let [key] of Object.entries(fieldsData)) {
					_newMandatoryStatus[key] = ((_newMandatoryStatus[key] ? _newMandatoryStatus[key] : "") + (__oldPipeline[key] ? (_newMandatoryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
				}
			}
		}
		return _newMandatoryStatus;
	}

	mergeStatusAndStage = (_mandEnquiryStatus, _newStatus) => {

		const { pipeline, stage } = this.state.fields;
		const _newStage = pipeline + '-' + stage;
		const { dealersettings } = this.state;

		let _mandPiplines;
		let _isValid = true;
		if (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.settings) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
			!_.isEmpty(dealersettings.client.settings.mandatoryFields.pipeline))
			_mandPiplines = dealersettings.client.settings.mandatoryFields.pipeline;

		if (!_.isEmpty(_mandPiplines)) {
			let __oldPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];

			if (!_.isEmpty(__oldPipeline)) {
				for (let [key] of Object.entries(fieldsData)) {
					_mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__oldPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
				}
			}

			if (_newStatus) {
				const { statusSettings } = this.state;
				let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _newStatus)[0];
				const _newStatusPipelineName = !_.isEmpty(_statusMndry) ? _statusMndry.pipeline + '-' + _statusMndry.stage : null;
				let __newStatusPipeline = !_.isEmpty(_newStatusPipelineName) && _mandPiplines.filter(e => e.name === _newStatusPipelineName)[0];
				if (!_.isEmpty(__newStatusPipeline)) {
					for (let [key] of Object.entries(fieldsData)) {
						_mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__newStatusPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __newStatusPipeline[key] : __newStatusPipeline[key]) : ""));
					}
				}
			}

		}

		return _mandEnquiryStatus;
	}

	//#endregion

	//#region MANDATORY PERMISSION
	handleMandatoryPermission = async (objFields, _newStatus, _newStatusValue) => {
		let isBreak = false;
		if (!_.isEmpty(objFields)) {
			const _fieldsData = Object.assign({}, fieldsData);
			for (let [key, value] of Object.entries(objFields)) {
				_fieldsData[key] = value;
			}
			const { contact } = this.state;
			for (let [key, value] of Object.entries(_fieldsData)) {
				if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
					if (!this.checkMandatoryContact(value)) {
						isBreak = true;
						break;
					}
				}

				if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
					if (!this.checkMandatoryContact(value)) {
						isBreak = true;
						break;
					}
				}

				if (key === 'enquiry' && !_.isEmpty(value)) {
					let _response = await this.checkMandatoryEnquiry(value, _newStatus, _newStatusValue)
					if (_response === false) {
						isBreak = true;
						break;
					}
				}

				// if (key === 'company') {
				// 	Swal.fire('Please add company')
				// 	isBreak = true;
				// 	break;
				// }
			}
		}
		if (isBreak)
			return false;
		else
			return true;
	}

	checkMandatoryContact = value => {
		const { isContact } = this.state.fields;
		const { modules } = this.state;
		let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

		//	console.log('checkMandatoryContact', isContact, _mandatoryFields, modules)
		if (!isContact && !_.isEmpty(modules) && modules.contact) {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, 'Please add contact'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
				cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
			}).then(result => {
				if (result.value) {
					this.handleContactOpen('addContact', _mandatoryFields);
				}
			});
			// <SweetAlert title="Please add contact" onConfirm={this.handleContactOpen('addContact', _mandatoryFields)}  />
			return false;

		}
		else if (!_.isEmpty(_mandatoryFields)) {
			const ObjCustomer = Object.assign({}, this.state.contact);
			let msg;
			_mandatoryFields.some((el, index) => {
				if (el.trim() === 'licenseFrontURL') {
					if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
						msg = 'Please upload license front image';
						return true;
					}
				} else if (el.trim() === 'licenseBackURL') {
					if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
						msg = 'Please upload license back image';
						return true;
					}
				} else if (el.trim() === 'phoneORemail') {
					if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
						msg = 'Please enter Phone or Email';
						return true;
					}
				} else if (el.trim() === 'phoneORemailORlicenseNo') {
					if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
						msg = 'Please enter Phone or Email or License No.';
						return true;
					}
				} else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
					msg = 'Please enter ' + el;
					return true;
				}
			});
			if (msg) {
				Swal.fire({
					title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
					icon: 'info',
					showCancelButton: true,
					confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
					cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
				}).then(result => {
					if (result.value) {
						//console.log('checkMandatoryContact', _mandatoryFields);
						this.handleContactOpen('addContact', _mandatoryFields);
					}
				});
				return false;
			} else {
				return true;
			}
		}
		else {
			return true;
		}
	}

	checkMandatoryEnquiry = async (value, _newStatus, _newStatusValue) => {
		let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

		if (!_.isEmpty(this.mandatoryFields))
			_mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

		if (!_.isEmpty(_mandatoryFields)) {
			const ObjEnquiry = Object.assign({}, objEnquiry);
			for (let [key, value] of Object.entries(this.state.fields)) {
				ObjEnquiry[key] = value;
			}

			let msg;

			const { modules, requirements } = this.state;
			let _soldRequirements = requirements.filter(e => e.isSold === true)
			let _isbreak = false;

			const { dealersettings } = this.state;
			const _moduleSettings = ((!_.isEmpty(dealersettings) &&
				!_.isEmpty(dealersettings.client) &&
				!_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);


			let _requiredObject = _.filter(_mandatoryFields, (v) => ((v.startsWith('is') || v.startsWith('files_') || v.endsWith('_note')) && !['isFinanceRequired', 'isFav'].includes(v)));
			let _requiredFields = _.filter(_mandatoryFields, (v) => (!_requiredObject.includes(v)));

			let _requiredForDeliverd = [];
			if (_newStatus === enquiryStatus.WON || _newStatus === enquiryStatus.CLOSED || _newStatus === enquiryStatus.DELIEVERD) {
				var _excluded = ['chassisNo', 'regNo', 'stockNo', 'warrantyStartDate', 'warrantyExpiry']

				if (_newStatus === enquiryStatus.DELIEVERD || ((_newStatus === enquiryStatus.WON || _newStatus === enquiryStatus.CLOSED) && !_.isEmpty(requirements) && requirements.length === 1))
					_requiredForDeliverd = _.filter(_requiredFields, (v) => (_excluded.indexOf(v) >= 0));
				_requiredForDeliverd.push('saleType');
				_requiredFields = _.filter(_requiredFields, (v) => (_excluded.indexOf(v) < 0));
			}

			//!_.isEmpty(_requiredObject) && _requiredObject.forEach(async (el) => {
			for (const el of _requiredObject) {
				if (_.trim(el) === 'isContact') {
					if (!ObjEnquiry[el] && _.isEmpty(ObjEnquiry['contact'])) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add contact'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								this.handleContactOpen('addContact');
							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isVehicle' && _newStatus !== 'requirement') {
					if ((!ObjEnquiry[el] &&
						(_.isEmpty(ObjEnquiry['requirement']) || (!_.isEmpty(ObjEnquiry['requirement']) && _.isEmpty(ObjEnquiry['requirement'].make) && _.isEmpty(ObjEnquiry['requirement'].stock))))
						&& !_.isEmpty(modules) && modules.requirement) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add requirement'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.isValidForRequirement();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isTestDrive' && !['isTestDrive', 'testDrive'].includes(_newStatus)) {
					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.testDrive) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add testdrive'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.isValidForTestDrive();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isTradeIn' && !['isTradeIn', 'tradeIn', 'tradeInLink', 'tradeInLinkAppraisal'].includes(_newStatus)) {
					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.tradeIn) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add tradein'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.isValidForTradeIn();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isCafe') {
					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.cafe) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add cafe'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.setState({
										cafeOrderModal: {
											show: true,
											cafeOrderID: '',
											title: 'orderCafe',
											clsCafeOrderActive:
												'overlay-modal active',
										},
									});
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isFiles') {
					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.files) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add file'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.isValidForFile();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el).includes('files_')) {
					let _data = await enquiryHelper.isFileCategoryExist(_.trim(el), this.state.enquiryID, dealersettings)
					if (_data === false) {
						let filesCategory = Object.assign([], dealersettings?.client?.settings?.filesCategory)
						let _info = _.find(filesCategory, { value: el.split('_')[2] })
						if (!_.isEmpty(_info)) {
							Swal.fire({
								title: CommonHelper.showLocale(this.props, `Please add file in ${CommonHelper.autoCaps(_info.module)} (${_info.name}) Folder.`),
								icon: 'info',
								showCancelButton: true,
								confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
								cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
							}).then(result => {
								if (result.value) {
									this.handleFileOpen(null, _info.value, _info.module, filesCategory.filter(e => e.module === _info.module && e.active), _info.name)
								}
							});
							_isbreak = true;
							return false;
						}
					}
				} else if (_.trim(el).includes('_note')) {
					let _data = await enquiryHelper.isActivityExist(_.trim(el), this.state.enquiryID, dealersettings)
					if (_data === false) {
						let _type = _.trim(el).split('_')[0];
						Swal.fire({
							title: CommonHelper.showLocale(this.props, `Please add ${_type.replace('log', ' note').toLowerCase()}.`),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								this.handleLogNoteOpen(_.trim(el))
							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isVideo') {
					const amsVideoEnabled = ((!_.isEmpty(_moduleSettings) &&
						!_.isEmpty(dealersettings.client.moduleSettings.amsVideo) &&
						dealersettings.client.moduleSettings.amsVideo.enabled) ? true : false);
					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.amsVideo && amsVideoEnabled) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add video'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.isValidForVideo();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				} else if (_.trim(el) === 'isFinance' && _newStatus !== 'isFinance') {
					const drivaEnabled = ((!_.isEmpty(dealersettings) &&
						!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
						dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
						dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
						dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
					) ? true : false);

					const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
						!_.isEmpty(dealersettings.client.moduleSettings.finance) &&
						dealersettings.client.moduleSettings.finance.enabled) ? true : false);

					if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.finance && (financeEnabled || drivaEnabled)) {
						Swal.fire({
							title: CommonHelper.showLocale(this.props, 'Please add finance'),
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
							cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
						}).then(result => {
							if (result.value) {
								setTimeout(() => {
									this.handleAddFinanceOpen();
								}, 200);

							}
						});
						_isbreak = true;
						return false;
					}
				}
			};

			if (_isbreak)
				return false;

			!_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
				if (!ObjEnquiry[el]) {
					if (ObjEnquiry.hasOwnProperty(el)) {
						msg = 'Please enter ' + el;
						return true;
					}
					else if (
						(!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === el && m.active === true)[0])) &&
						(_.isEmpty(ObjEnquiry.dynamicFields) ||
							(!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[el]) && !_.isBoolean(ObjEnquiry.dynamicFields[el]) && !_.isNumber(ObjEnquiry.dynamicFields[el]))
						)
					) {
						msg = 'Please enter ' + el;
						return true;
					}
					// else {
					// 	return true;
					// }
				}
			});

			if (msg) {
				let errors = {};
				let formIsValid = true;
				let errorClass = 'input_error';

				_requiredFields.forEach((key, index) => {
					if (
						!ObjEnquiry[key] &&
						_requiredFields.indexOf(key) >= 0
					) {
						if (ObjEnquiry.hasOwnProperty(key)) {
							formIsValid = false;
							errors[key] = errorClass;
						}
						else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
							(_.isEmpty(ObjEnquiry.dynamicFields) ||
								(!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[key]) && !_.isBoolean(ObjEnquiry.dynamicFields[key]) && !_.isNumber(ObjEnquiry.dynamicFields[key]))
							)) {
							formIsValid = false;
							errors[key] = errorClass;
						}
						// else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
						// 	(_.isEmpty(ObjEnquiry.options) || (!_.isEmpty(ObjEnquiry.options) && ObjEnquiry.options.indexOf(key) < 0))) {
						// 	formIsValid = false;
						// 	errors[key] = errorClass;
						// }
					}
				});
				//console.log('checkMandatory-Enquiry-_requiredFields', errors, _requiredForDeliverd)
				if (formIsValid === true) {
					return true;
				} else {
					this.setState({
						enquiryModal: {
							show: true,
							errors: errors,
							newStatus: _newStatus,
							newStatusValue: _newStatusValue,
							ismodifyStatusDate: false,
							isModifyInboundOwner: false,
							mandatoryFields: _requiredForDeliverd
						},
					});
					return formIsValid;
				}

			}
			if (!_.isEmpty(_requiredForDeliverd) && !_.isEmpty(_soldRequirements) && !_.isEmpty(modules) && modules.requirement) {
				if (CommonHelper.isValidSoldRequirements(_soldRequirements, _requiredForDeliverd) === false) {
					this.setState({
						enquiryModal: {
							show: true,
							mandatoryFields: _requiredForDeliverd,
							newStatus: _newStatus,
							newStatusValue: _newStatusValue,
							isModifyInboundOwner: false,
							ismodifyStatusDate: false
						},
					});
					return false;
				}
				else {
					return true;
				}

			} else {
				return true;
			}
		}
		else {
			return true;
		}
	}


	//#endregion 

	//#region  LEAD CONVERT
	handleOEMLeadModalClose = objFields => {
		if (!_.isEmpty(objFields)) {
			const { clientID, isAfterSales } = this.state.fields;
			let objRecord = {
				id: this.state.enquiryID,
				clientid: clientID
			}

			this.props.handleRouteRemove(objRecord, isAfterSales ? 'afterSales' : 'enquiry');
		}
		this.setState({ showOEMLeadConvert: false, showOEMLeadConvertPrev: false, convertLeadLoader: false });
	}

	handleLeadConvertShow = async () => {
		const { pipeline, stage } = this.state.fields;
		if (await this.isValidForStageUpdate(pipeline, 'Converted', 'leadConvert')) {
			this.setState({ showLeadConvert: true });
		}
	}

	handleModalClose = objFields => {
		if (_.isEmpty(objFields)) {
			this.setState({ showLeadConvert: false, convertLeadLoader: false });
		} else {
			var self = this;
			self.setState({ convertLeadLoader: true });
			let state = Object.assign({}, this.state.fields);
			let _contact = Object.assign({}, this.state.contact);
			const { dealersettings } = this.state;
			let _objData = {
				isConverted: true,
				unactionedNotify: enqUnActionStatus.PENDING,
				convertedDate: window.firebase.firestore.Timestamp.now(),
				stageDate: window.firebase.firestore.Timestamp.now(),
				convertedBy: localStorage.uid,
				modifiedBy: localStorage.uid,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				clientID: objFields.clientID ? objFields.clientID : state.clientID,
				projectId: objFields.projectId ? objFields.projectId : (state.projectId ? state.projectId : null),
				//groupID: (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null,
				owner: objFields.owner,
				pipeline: objFields.pipeline,
				stage: objFields.stage,
				status: enquiryHelper.bindDefaultStatus(objFields.status, state.status),
				stageHistory: objFields.stageHistory,
				modifiedFrom: 'web'
			}

			if (objFields.status)
				_objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
					{
						date: window.firebase.firestore.Timestamp.now(),
						status: objFields.status,
						user: localStorage.uid
					}
				)

			if (objFields.pipeline === 'LeadsBucket') {
				//_objData.status = null;
				_objData.isConverted = false;
				_objData.unactionedNotify = '';
				_objData.convertedBy = null;
				_objData.convertedDate = null;
				_objData.ownerInbound = objFields.owner;
			}
			else {
				_objData.lastStatus = '';
				_objData.lostReason = '';
				_objData.lostSubReason = '';
				_objData.lostNotes = '';
				_objData.lostDate = null;
				_objData.wonDate = null;

				if (_.isEmpty(state.tags) && state.pipeline === 'LeadsBucket') {
					_objData.tags = [{ type: 'inbound', refID: objFields.enquiryID, subType: '' }]
				}
			}

			let _contactChange = false;
			if (state.isContact && !_.isEmpty(_contact?.documentID) && objFields.clientID && state.clientID !== objFields.clientID) {
				if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(objFields.clientID))) {
					let _tags = Object.assign([], state.tags);
					_tags.push({
						type: 'Dealer Transfer',
						refID: (_contact?.documentID || null),
						clientID: (_contact?.clientID || null),
						origin: 'detail_convert'
					})
					_objData.engageNewContact = Object.assign({}, {
						...state.contact,
						documentID: null,
						displayID: null,
						clientID: null,
						projectId: null,
						groupID: null,
						regionID: null,
						oemID: null,
					});
					_objData.contact = {};
					_objData.contactID = null;
					_objData.isContact = false;
					_objData.tags = _tags;
					_objData.tags = _objData.tags ? _.union(_tags, _objData.tags) : _tags;
					_contactChange = true;
				}
			}

			const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
				!_.isEmpty(dealersettings.client) &&
				!_.isEmpty(dealersettings.client.clientSettings) &&
				dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

			if (enableCustShowNoShow && objFields.activityType === 'appointment' && !_.isEmpty(objFields.activityDate)) {
				_objData.bdcStatus = 'allocated';
				_objData.appointmentDate = objFields.activityDate;
			}
			//Remove empty value from object
			Object.keys(_objData).forEach(key => _objData[key] === undefined ? delete _objData[key] : {});
			firestoreDB(dealersettings).firestore().doc(`enquiries/${objFields.enquiryID}`)
				.set(_objData, { merge: true })
				.then(snapshot => {
					//console.log('success');

					//let _contactID = state.contactID ? state.contactID : state?.contact?.documentID
					if (objFields.owner && state.owner !== objFields.owner && _contact?.documentID)
						firestoreDB(dealersettings).firestore().doc(`contactDetails/${_contact?.documentID}`).set({ 'owner': objFields.owner }, { merge: true })

					if (state.owner !== objFields.owner || state.clientID !== objFields.clientID) {
						this.updateActivity(state.owner, objFields, _contactChange);
					}
					else
						self.setState({ showLeadConvert: false, convertLeadLoader: false });

					let logNotes = (_.isEmpty(objFields.notes) || _.isEmpty(objFields.notes.trim())) ? `${dealersettings.isPorsche === true ? 'Enquiry has been moved' : 'Lead has been converted'} to ${_.find(this.props.dealersettings.allPipelines, { 'value': objFields.pipeline }) ? _.find(this.props.dealersettings.allPipelines, { 'value': objFields.pipeline }).name : objFields.pipeline}` : objFields.notes;
					this.saveCustomerlog(logNotes, objFields.mentions, null, _objData.clientID);
					if (objFields.activityDate)
						this.createAppoitment(objFields);
					//CommonHelper.saveChangeLog(this.state._clientID, objFields.enquiryID, null, logNotes, 'enquiry', '');
					toast.notify('Enquiry converted successfully.', {
						duration: 2000,
					});

					this.handleLayoutClose();
					this.props.history.push("/enquiry/details/" + objFields.enquiryID);
					CommonHelper.refreshStageCount(objFields.pipeline, dealersettings);
				})
				.catch(error => {
					console.error(error);
					setTimeout(function () {
						self.setState({ convertLeadLoader: false });
					}, 1000);
					toast.notify('Something went wrong', {
						duration: 2000,
					});
				});
		}
	};

	saveCustomerlog = (logNotes, mentions, enquiryID, clientID) => {
		let newlogVM = Object.assign({}, objActivitylog);
		const { dealersettings, clientUsers, groupUsers } = this.props;
		newlogVM.type = 'log';
		newlogVM.subType = 'note';
		newlogVM.isDone = true;
		newlogVM.owner = localStorage.uid;
		newlogVM.addedBy = localStorage.uid;
		newlogVM.modifiedBy = localStorage.uid;
		newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
		newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
		newlogVM.enquiryID = enquiryID ? enquiryID : this.state.enquiryID;
		newlogVM.contactID = this.state.contact ? this.state.contact.documentID : null;
		newlogVM['enquiry'] = CommonHelper.getMinifiedData(this.state.fields, 'enquiry');
		newlogVM['contact'] = CommonHelper.getMinifiedData(this.state.contact, 'contact');
		//newlogVM.clientID = dealersettings ? dealersettings.client.id : null;
		newlogVM.documentID = firestoreDB(this.props.dealersettings).firestore().collection('activities').doc().id;
		newlogVM.startDate = window.firebase.firestore.Timestamp.now();
		newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
		newlogVM.modifiedByName = newlogVM.addedByName;
		newlogVM.notes = logNotes ? logNotes : '';
		newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, clientID);
		Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
		newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
		// if (mentions) {
		// 	newlogVM.mentions = mentions
		// }

		if (!_.isEmpty(mentions)) {
			if (mentions.length > 0) {
				let convertedIDs = []
				let rawIDs = mentions.map(mentionObject => mentionObject.id)
				rawIDs.forEach(id => {
					if (id.includes(',')) {
						let allIDs = id.split(',')
						allIDs.forEach(a => {
							convertedIDs.push(a)
						})
					} else {
						convertedIDs.push(id)
					}
				})

				if (newlogVM.mentions && newlogVM.mentions.length > 0) {
					newlogVM.mentions = _.uniq([...newlogVM.mentions, ...convertedIDs])
				} else {
					newlogVM.mentions = _.uniq(convertedIDs)
				}
			}
		}

		firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
			.set(newlogVM, { merge: true })
			.catch(error => {
				console.error(error);
			});
	};

	createAppoitment = (objFields) => {
		let newlogVM = Object.assign({}, objActivitylog);
		const { clientUsers, groupUsers } = this.props;
		const { fields, contact, dealersettings } = this.state;

		const objData = Object.assign({}, fields);
		//Remove empty value from object
		for (var propName in objData) {
			if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
				delete objData[propName];
			}
		}

		const objContData = Object.assign({}, contact);
		//Remove empty value from object
		for (var propName in objContData) {
			if (objContData[propName] === null || objContData[propName] === undefined || objContData[propName] === '' || (_.isObject(objContData[propName]) && _.isEmpty(objContData[propName]))) {
				delete objContData[propName];
			}
		}

		newlogVM.type = 'activity';
		newlogVM.subType = objFields.activityType ? objFields.activityType : 'appointment';
		newlogVM.title = objFields.activityType ? CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.activityTypes, objFields.activityType) : 'Appointment';
		newlogVM.owner = objFields.owner;
		newlogVM.addedBy = localStorage.uid;
		newlogVM.modifiedBy = localStorage.uid;
		newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
		newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
		newlogVM.modifiedFrom = 'web';
		newlogVM.enquiryID = this.state.enquiryID;
		newlogVM.contactID = contact ? contact.documentID : null;
		newlogVM.enquiry = !_.isEmpty(objData) ? objData : null;
		newlogVM.contact = !_.isEmpty(objContData) ? objContData : null;
		//newlogVM.clientID = dealersettings ? dealersettings.client.id : null;
		newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
		newlogVM.startDate = objFields.activityDate;
		newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(objFields.activityDate.seconds).add('hour', 1).toDate());
		newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
		newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

		const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings) &&
			dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

		if (enableCustShowNoShow)//objFields.pipeline !== 'LeadsBucket' && 
			newlogVM = CommonHelper.setCustShowNoShow(newlogVM, dealersettings, true);

		newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, objFields.clientID);
		firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
			.catch(error => {
				console.error(error);
			});;
	}



	handleSave = async () => {
		const { pipeline, stage, status, clientID } = this.state.fields
		//let _newStage = pipeline + '-' + stage;
		if (await this.isValidForStatusUpdate(status, 'save')) {
			this.sheduleNewLeadActivity();
		}
	}
	//#endregion

	//#region VERIFY
	handleVerifyEnqiry = () => {
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to verify enquiry.`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				let state = Object.assign({}, this.state.fields);
				if (!state.verifiedBy) {
					state.verifiedBy = (this.props.dealersettings.id || localStorage.uid);
					state.verifiedDate = window.firebase.firestore.Timestamp.now();
					state.modifiedBy = (this.props.dealersettings.id || localStorage.uid);
					state.modifiedDate = window.firebase.firestore.Timestamp.now();

					const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.state.enquiryID);
					updateRef.update({
						verifiedBy: state.verifiedBy,
						verifiedDate: state.verifiedDate,
						modifiedBy: state.modifiedBy,
						modifiedDate: state.modifiedDate,
						modifiedFrom: 'web'
					}).then((docRef) => {
						this.setState({ fields: state });

					}).catch((error) => {
						console.error("Error updating enquiries: ", error);
					});
				}
			}
		});

	}
	//#endregion

	//#region TRANSFER
	handleTransferEnqiry = () => {
		// Swal.fire({
		// 	title: 'Are you sure?',
		// 	text: `You want to transfer enquiry.`,
		// 	icon: 'info',
		// 	showCancelButton: true,
		// 	confirmButtonText: 'Yes',
		// 	cancelButtonText: 'No',
		// }).then(result => {
		// 	if (result.value) {

		// 	}
		// });

		this.setState({ showLeadTransfer: true });
	}

	handleCloseTransferEnqiry = async (objFields) => {
		if (_.isEmpty(objFields)) {
			this.setState({ showLeadTransfer: false, convertLeadLoader: false });
		} else {
			var self = this;
			self.setState({ convertLeadLoader: true });
			let state = Object.assign({}, this.state.fields);
			let _contact = Object.assign({}, this.state.contact);
			const { dealersettings } = this.state;
			let _objEnq = Object.assign({}, {
				stageDate: window.firebase.firestore.Timestamp.now(),
				modifiedBy: localStorage.uid,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				modifiedFrom: 'web',
				clientID: objFields.clientID ? objFields.clientID : state.clientID,
				projectId: objFields.projectId ? objFields.projectId : (state.projectId ? state.projectId : null),
				groupID: (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null,
				owner: objFields.owner ? objFields.owner : state.owner,
				pipeline: objFields.pipeline,
				stage: objFields.stage,
				stageHistory: objFields.stageHistory,
			});

			if (objFields.pipeline === 'LeadsBucket') {
				//_objEnq.status = null;
				_objEnq.isConverted = false;
				_objEnq.convertedBy = null;
				_objEnq.convertedDate = null;
			}

			if (state.pipeline === 'LeadsBucket' && objFields.pipeline !== 'LeadsBucket') {
				_objEnq.status = objFields.status ? objFields.status : enquiryStatus.OPEN;
				_objEnq.isConverted = true;
				_objEnq.unactionedNotify = enqUnActionStatus.PENDING;
				_objEnq.convertedBy = localStorage.uid;
				_objEnq.convertedDate = window.firebase.firestore.Timestamp.now();

				if (_objEnq.status)
					_objEnq.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
						{
							date: window.firebase.firestore.Timestamp.now(),
							status: _objEnq.status,
							user: localStorage.uid
						}
					)
			}
			let _contactChange = false;
			if (state.isContact && !_.isEmpty(_contact?.documentID) && objFields.clientID && state.clientID !== objFields.clientID) {
				if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(objFields.clientID))) {
					let _tags = Object.assign([], state.tags);
					_tags.push({
						type: 'Dealer Transfer',
						refID: (_contact?.documentID || null),
						clientID: (_contact?.clientID || null),
						origin: 'detail_transfer'
					})
					_objEnq.engageNewContact = Object.assign({}, {
						...state.contact,
						documentID: null,
						displayID: null,
						clientID: null,
						projectId: null,
						groupID: null,
						regionID: null,
						oemID: null,
					});
					_objEnq.contact = {};
					_objEnq.contactID = null;
					_objEnq.isContact = false;
					_objEnq.tags = _tags;
					_contactChange = true;
				}
			}
			Object.keys(_objEnq).forEach(key => _objEnq[key] === undefined ? delete _objEnq[key] : {});
			firestoreDB(dealersettings).firestore().doc(`enquiries/${objFields.enquiryID}`)
				.set(_objEnq, { merge: true })
				.then(snapshot => {
					//console.log('success');

					if ((objFields.owner && state.owner !== objFields.owner) || (objFields.clientID && state.clientID !== objFields.clientID)) {
						this.updateActivity(state.owner, objFields, _contactChange);
					}
					else
						self.setState({ showLeadTransfer: false, convertLeadLoader: false });

					if (state.clientID && state.clientID !== objFields.clientID) {
						this.updateClientIDs(objFields.enquiryID, objFields.clientID);
					}

					CommonHelper.saveActivityLog({ 'enquiry': _objEnq }, (objFields.notes ? objFields.notes : 'Lead has beed transferred'), 'log', 'note', null, dealersettings);

					if (dealersettings && dealersettings.group && dealersettings.group.enableSharedContact) {
						dealersettings.group.client_ids
							&& dealersettings.group.client_ids.forEach(cid => {
								if (state.clientID === cid && localStorage[cid])
									localStorage[cid] = JSON.stringify(JSON.parse(localStorage[cid]).filter(x => x.id !== state.documentID));
								else if (objFields.clientID === cid) {
									localStorage[cid] = JSON.stringify([...JSON.parse(localStorage[cid] ? localStorage[cid] : []).filter(x => x.id !== state.documentID), {
										name: CommonHelper.displayName(state),
										id: state.documentID,
										displayID: state.displayID ? state.displayID : state.documentID,
										status: state.status && state.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : state.status,
										active: true,
										clientid: objFields.clientID,
									}]);
								}
							})
					}
					CommonHelper.refreshStageCount(objFields.pipeline, dealersettings);
					toast.notify('Enquiry transferred successfully.', {
						duration: 2000,
					});
				})
				.catch(error => {
					console.error(error);
					setTimeout(function () {
						self.setState({ convertLeadLoader: false });
					}, 1000);
					toast.notify('Something went wrong', {
						duration: 2000,
					});
				});
		}
	};

	updateActivity = async (currentOwner, objFields, _contactChange) => {
		if (!_.isEmpty(objFields) && (objFields.clientID || objFields.owner)) {

			let _objAct = {}
			if (objFields.owner) _objAct.owner = objFields.owner;
			if (objFields.clientID) _objAct.clientID = objFields.clientID;
			if (_contactChange) {
				_objAct.contactID = null;
				_objAct.contact = {};
			}

			const batch = firestoreDB(this.props.dealersettings).firestore().batch();
			await firestoreDB(this.props.dealersettings).firestore().collection('activities')
				.where('enquiryID', '==', objFields.enquiryID)
				.where('owner', '==', currentOwner)
				.where('isDeleted', '==', false)
				//.where('isDone', '==', false)
				.get().then(response => {
					response.docs.forEach((doc) => {
						batch.update(doc.ref, _objAct);
					});
				});
			await firestoreDB(this.props.dealersettings).firestore().collection('activities')
				.where('enquiryID', '==', objFields.enquiryID)
				.where('owner', '==', currentOwner)
				.where('isHidden', '==', true)
				.where('isDeleted', '==', true)
				.get().then(response => {
					response.docs.forEach((doc) => {
						batch.update(doc.ref, _objAct);
					});
				});
			batch.commit()
				.then(() => {
					this.setState({ showLeadTransfer: false, showLeadConvert: false, convertLeadLoader: false });
				})
				.catch((error) => {
					console.log(error);
					toast.notify('Something went wrong', {
						duration: 2000
					})
				});
		}

	}

	updateClientIDs = async (enquiryID, clientID) => {
		if (!_.isEmpty(enquiryID) && clientID) {
			const batch = firestoreDB(this.props.dealersettings).firestore().batch();
			await firestoreDB(this.props.dealersettings).firestore().collection('tradeins')
				.where('enquiryID', '==', enquiryID)
				.where('isDeleted', '==', false)
				.get().then(response => {
					response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: clientID }); });
				});

			await firestoreDB(this.props.dealersettings).firestore().collection('testdrives')
				.where('enquiryID', '==', enquiryID)
				.where('isDeleted', '==', false)
				.get().then(response => {
					response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: clientID }); });
				});

			await firestoreDB(this.props.dealersettings).firestore().collection('cafeOrders')
				.where('enquiryID', '==', enquiryID)
				.where('isDeleted', '==', false)
				.get().then(response => {
					response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: clientID }); });
				});
			await firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`)
				.get().then(response => {
					response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: clientID }); });
				});
			batch.commit()
				.then(() => {
					this.setState({ showLeadTransfer: false, showLeadConvert: false, convertLeadLoader: false });
				})
				.catch((error) => {
					console.log(error);
					toast.notify('Something went wrong', {
						duration: 2000
					})
				});
		}

	}
	//#endregion

	//#region DYNAMIC FIELDS

	handleDynamicCheckChange = (e) => {
		const { name, checked } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = checked;
		this.saveEnquiryField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	}

	handleDynamicOnChange = (e) => {
		const { name, value } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	handleDynamicOnCapChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);

		if (value)
			_dynamicFields[name] = _.trim(value.toUpperCase());
		else
			_dynamicFields[name] = null;

		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}

	onDynamicNumberChange = (name, e) => {
		//{formattedValue: "$9,856", value: "9856", floatValue: 9856}

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);

		_dynamicFields[name] = e.floatValue;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name) });
	}

	handleDynamicSelectSave = (e, data) => {

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[data.name] = (e?.value || '');
		this.saveEnquiryField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(data.name), 500);
	};

	handleDynamicMultiSelectChange = (selectedOptions, _data) => {
		const value = [];
		!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
			value.push(data.value)
		})
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[_data.name] = value;
		this.saveEnquiryField('dynamicFields', _dynamicFields);
		this.setState({ fields: state });
	}

	handleDynamicDateChange = (val, name) => {

		if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = val;

		this.saveEnquiryField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	};

	saveDynamicField = (name, value) => {
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		this.saveEnquiryField('dynamicFields', _dynamicFields);
		setTimeout(() => this.errorChange(name), 500);
	}

	cancelDynamicField = (name, value) => {
		let state = Object.assign({}, this.state.fields);
		let _dynamicFields = Object.assign({}, state.dynamicFields);
		_dynamicFields[name] = value;
		state.dynamicFields = _dynamicFields;
		this.setState({ fields: state }, () => { this.errorChange(name); });
	}
	//#endregion

	//#region PUSH DMS
	handlePushErapower = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const erapowerreq = window.firebase.functions().httpsCallable('erapower-send');
					erapowerreq(this.state.fields).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong. Please contact support.'), '', 'error')
					})
				}
				catch (error) {
					Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong. Please contact support.'), '', 'error')
				}
			}
		});
	}

	handlePushPMDS = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const dmsreq = window.firebase.functions().httpsCallable(`pmds-send`);
					dmsreq({
						"clientID": this.state.fields.clientID,
						"enquiry": this.state.fields
					}).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}

	handlePushTitan = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const dmsreq = window.firebase.functions().httpsCallable(`titan-send`);
					dmsreq({
						"clientID": this.state.fields.clientID,
						"enquiry": this.state.fields
					}).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						} else if (res.data.data === 'ALREADY_SYNCED') {
							Swal.fire(res.data.message, '', 'info')
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}
	//PUSH TO CAP
	handlePushCAP = async () => {
		let result = await Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to CAP`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		})
		if (result.value) {
			this.pushtoCAP();
		}
	}

	pushtoCAP = () => {
		this.setState({ loaderErapower: true })
		try {
			const dmsreq = window.firebase.functions().httpsCallable(`cap-send`);
			dmsreq({
				"clientID": this.state.fields.clientID,
				"enquiry": this.state.fields
			}).then((res) => {
				this.setState({ loaderErapower: false });
				if (res.data.success) {
					toast.notify('Lead has been sent successfully', {
						duration: 2000,
					});
				}
				else if (res.data.capBP) {
					const _title = 'Already Exists';
					const _msg = 'BP Already Exists in CAP';
					Swal.fire({
						title: CommonHelper.showLocale(this.props, _title),
						html: res?.data?.message || _msg,
						showCancelButton: true,
						icon: 'info',
						confirmButtonClass: 'viewButton',
						confirmButtonColor: '#459E20',
						cancelButtonColor: '#17232D',
						confirmButtonText: CommonHelper.showLocale(this.props, 'View Contact'),
						cancelButtonText: CommonHelper.showLocale(this.props, 'Okay'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false
					}).then((result) => {
						if (result.value) {
							this.handleXContactShow(res.data.capBP)
						}
					});
				} else {
					Swal.fire(res.data.message, '', 'error')
				}
			}).catch((error) => {
				this.setState({ loaderErapower: false });
				console.log(error);
				Swal.fire('Something went wrong. Please contact support.', '', 'error')
			})
		}
		catch (error) {
			Swal.fire('Something went wrong. Please contact support.', '', 'error')
		}
	}

	handleXContactShow = (data) => {
		if (!_.isEmpty(data)) {
			this.setState({ capInfo: { leads: data?.Leads || [] } })
			data = this.convertContactVM(data);
			this.setState({
				showContactInfo:
				{
					show: true,
					data: data
				}
			});
		}

	}

	handleXContactClose = (type, objData) => {
		const { leads } = this.state.capInfo;
		const { documentID, tags } = this.state.fields;
		//console.log('handleXContactClose', type, objData);
		//let state = Object.assign({},this.state.fields);
		if (type && !_.isEmpty(objData)) {
			const contact = this.replaceContact(objData);
			if (leads?.length) {
				this.setState({
					showCAPLeads: {
						show: true,
						leads,
						contact,
						title: 'CAP Leads',
						enquiryID: documentID,
						capTags: tags
					}
				});
			} else {
				this.saveCAPDetails(contact)
			}
		}

		this.setState({
			showContactInfo:
			{
				show: false,
				data: null
			}
		});

	}

	saveCAPDetails = async (contact) => {
		this.setState({
			showCAPLeads: {
				show: false
			}
		})
		if (contact?.documentID) {
			await firestoreDB(this.props.dealersettings)
				.firestore()
				.doc(`contactDetails/${contact.documentID}`)
				.set(contact, { merge: true });
			await firestoreDB(this.props.dealersettings)
				.firestore()
				.doc(`enquiries/${this.state.fields.documentID}`)
				.update({
					'capBP': contact.capGuid || null,
				});
			this.pushtoCAP();
		}
	}

	replaceContact = (objData) => {
		const fields = Object.assign({}, objContact);
		for (let [key, value] of Object.entries(objData)) {
			if (key === 'dob' || key === 'licenseExpiry')
				fields[key] = value && moment(value).isValid() ? value : null;
			else
				fields[key] = value;
		}
		return CommonHelper.removeEmptyOrNull(fields)
	}

	convertContactVM = (doc) => {
		const objcontactData = Object.assign({}, doc);
		const contact = Object.assign({}, objContact);
		Object.keys(objContact).forEach(rec => {
			if (_.isBoolean(objContact[rec]) && !doc.hasOwnProperty(rec)) {
				delete contact[rec]
			}
		})
		for (let [key, value] of Object.entries(objcontactData)) {
			if (contact.hasOwnProperty(key))
				contact[key] = value;
		}

		contact.capGuid = doc.capGuid || null
		contact.capID = doc.capID || null

		return contact
	}


	//END

	// handlePushCAP = () => {

	// 	Swal.fire({
	// 		title: CommonHelper.showLocale(this.props, 'Are you sure?'),
	// 		text: CommonHelper.showLocale(this.props, `You want to push this lead to CAP`),
	// 		icon: 'info',
	// 		showCancelButton: true,
	// 		confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
	// 		cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
	// 		allowOutsideClick: false,
	// 		allowEscapeKey: false
	// 	}).then(result => {
	// 		if (result.value) {
	// 			this.setState({ loaderErapower: true })
	// 			try {
	// 				const dmsreq = window.firebase.functions().httpsCallable(`cap-send`);
	// 				dmsreq({
	// 					"clientID": this.state.fields.clientID,
	// 					"enquiry": this.state.fields
	// 				}).then((res) => {
	// 					this.setState({ loaderErapower: false });
	// 					if (res.data.success) {
	// 						toast.notify('Lead has been sent successfully', {
	// 							duration: 2000,
	// 						});
	// 					}
	// 					else {
	// 						Swal.fire(res.data.message, '', 'error')
	// 					}
	// 				}).catch((error) => {
	// 					this.setState({ loaderErapower: false });
	// 					console.log(error);
	// 					Swal.fire('Something went wrong. Please contact support.', '', 'error')
	// 				})
	// 			}
	// 			catch (error) {
	// 				Swal.fire('Something went wrong. Please contact support.', '', 'error')
	// 			}
	// 		}
	// 	});
	// }

	handlePushAutoIT = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const dmsreq = window.firebase.functions().httpsCallable(`autoit-send`);
					dmsreq({
						"clientID": this.state.fields.clientID,
						"enquiry": this.state.fields
					}).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						} else if (res.data.data === 'ALREADY_SYNCED') {
							Swal.fire(res.data.message, '', 'info')
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}

	handlePushKeyloop = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Sales Lead'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
			//denyButtonText: `Sales Order`,
			denyButtonColor: '#4466f2'
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const erapowerreq = window.firebase.functions().httpsCallable(`keyloop-send`);
					erapowerreq(this.state.fields).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}

	handlePushRevolution = () => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, `You want to push this lead to DMS`),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(result => {
			if (result.value) {
				this.setState({ loaderErapower: true })
				try {
					const dmsreq = window.firebase.functions().httpsCallable(`revolution-send`);
					dmsreq({
						"clientID": this.state.fields.clientID,
						"enquiry": this.state.fields
					}).then((res) => {
						this.setState({ loaderErapower: false });
						if (res.data.success) {
							toast.notify('Lead has been sent successfully', {
								duration: 2000,
							});
						} else if (res.data.data === 'ALREADY_SYNCED') {
							Swal.fire(res.data.message, '', 'info')
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderErapower: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}
	handleReserveVehicle = (keyloopReserved) => {

		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, keyloopReserved ? `You want to cancel reservation` : `You want to reserve this vehicle`),
			icon: 'info',
			//showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
			allowEscapeKey: false,
			allowOutsideClick: false,
			//denyButtonText: `Sales Order`,
			//denyButtonColor: '#4466f2'
		}).then(result => {
			if (result.value) {
				this.setState({ loaderReserveVehicle: true })
				try {
					const erapowerreq = window.firebase.functions().httpsCallable(`keyloop-reserve`);
					erapowerreq({
						...this.state.fields,
						cancel: Boolean(keyloopReserved)
					}).then((res) => {
						this.setState({ loaderReserveVehicle: false });
						if (res.data.success) {
							toast.notify(keyloopReserved ? 'reservation cancalled successfully' : 'Lead has been reserved successfully', {
								duration: 2000,
							});
						}
						else {
							Swal.fire(res.data.message, '', 'error')
						}
					}).catch((error) => {
						this.setState({ loaderReserveVehicle: false });
						console.log(error);
						Swal.fire('Something went wrong. Please contact support.', '', 'error')
					})
				}
				catch (error) {
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				}
			}
		});
	}

	isValidForPushToDMS = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToDMS') === true) {
			this.handlePushErapower();
		}

	}

	isValidForPushToPMDS = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToPMDS') === true) {
			this.handlePushPMDS();
		}
	}

	isValidForPushToCAP = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToCAP') === true) {
			this.handlePushCAP();
		}
	}

	isValidForPushToAutoIT = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToAutoIT') === true) {
			this.handlePushAutoIT();
		}
	}
	isValidForPushToTitan = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToTitan') === true) {
			this.handlePushTitan();
		}
	}
	isValidForPushToRevolution = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToRevolution') === true) {
			this.handlePushRevolution();
		}
	}
	isValidForPushToKeyloop = async (e) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToKeyloop') === true) {
			this.handlePushKeyloop();
		}
	}
	isValidForReserveVehicle = async (e, keyloopReserved) => {
		e.preventDefault();
		let _objFields = Object.assign({}, {
			enquiry: 'isVehicle,enquiryType'
		});

		if (await this.handleMandatoryPermission(_objFields, 'pushToReserveVehicle') === true) {
			this.handleReserveVehicle(keyloopReserved);
		}
	}
	//#endregion

	//#region RE-AUTHENTICATION
	handleReAuthOpen = () => {
		this.setState({ showReAuthentication: true });
	}

	handleReAuthOpenClose = (data) => {
		if (!_.isEmpty(data)) {
			this.forceUpdate();
		}
		this.setState({ showReAuthentication: false });
	}
	//#endregion

	handleSaveAuditLog = (imagePath) => {
		const { documentID } = this.state.contact;
		const { dealersettings } = this.state;
		let _name = CommonHelper.getFullNameWithID(null, this.state.contact, '');
		let _objLogData = {
			notes: 'License image viewed for ' + _name,
			type: 'licenseImage',
			subType: 'contactDetails',
			recordId: documentID ? documentID : null,
			contactID: documentID ? documentID : null,
			imageURL: imagePath,
		}

		_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
		CommonHelper.saveAuditLog(_objLogData);
	}

	handleEventOpen = (data) => {
		this.setState({
			eventModal:
			{
				show: true,
				data: data,
				title: 'Event',
				clsActive: 'overlay-modal active'
			}
		});
	}

	handleEventClose = (data) => {
		this.setState({
			eventModal:
			{
				show: false,
				data: null,
				title: '',
				clsActive: ''
			}
		});
	}

	//#region MERGE ENQUIRY
	handleOpenMerge = (e) => {
		e.preventDefault();
		this.setState({ showMergeEnqList: true });
	}

	handleCloseMerge = () => {
		this.setState({ showMergeEnqList: false });
	}

	handleLayoutClose = () => {
		const { isFromLayout, modalClose } = this.props;
		if (isFromLayout && modalClose) {
			modalClose();
		}
	}
	//#endregion


	sheduleNewLeadActivity = async () => {
		const { dealersettings } = this.props;
		const { status } = this.state.fields;
		const isDone = (status === enquiryStatus.WON || status === enquiryStatus.DELIEVERD || status === enquiryStatus.LOST || status === enquiryStatus.PENDINGLOST || status === enquiryStatus.PROSPECTLOST) ? true : false;
		if (dealersettings?.client?.clientSettings?.isNextActivityMandatoryNE && !isDone) {

			const responseMKA = await firestoreDB(dealersettings).firestore().collection('activities')
				.where('enquiryID', '==', this.state.enquiryID)
				.where('isDeleted', '==', false)
				.where('type', '==', 'activity')
				.where('isDone', '==', false)
				.limit(1)
				.get();

			if (responseMKA.docs.length > 0) {
				this.saveNewLead();
			}
			else {
				Swal.fire({
					title: CommonHelper.showLocale(this.props, 'Schedule new activity'),
					text: CommonHelper.showLocale(this.props, 'Do you want to schedule activity for lead?'),
					icon: 'info',
					showCancelButton: false,
					confirmButtonText: CommonHelper.showLocale(this.props, 'Schedule'),
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then((result) => {
					if (result.value)
						this.setState({ showNewLeadActivity: true })
				})
			}
		}
		else {
			this.saveNewLead();
		}
	}

	sheduleNewLeadActivityClose = (_data) => {
		if (!_.isEmpty(_data)) {
			this.saveNewLead();
		}
		this.setState({ showNewLeadActivity: false })

	}

	saveNewLead = () => {
		this.saveEnquiryField('isNewEnquiry', false);
		this.handleLayoutClose();
		this.props.history.push("/enquiry/details/" + this.state.enquiryID);
	}

	handleCloseLinkAppraisal = (tradeinProID, tradeinProData, isRelink) => {
		const { fields, enquiryID, contact, dealersettings } = this.state;
		let tradeinID = tradeinProData?.tradeinID ? tradeinProData?.tradeinID : tradeinProData?.tradein?.documentID
		let _oldEnqID = tradeinProData?.tradein?.enquiryID ? tradeinProData.tradein.enquiryID : tradeinProData?.tradein?.enquiry?.documentID
		let _oldEnq = tradeinProData?.tradein?.enquiry
		if (tradeinProID && tradeinID && tradeinProData) {

			// update tip
			let state = Object.assign({});
			state.ownerSales = fields.owner ? fields.owner : null;
			state.modifiedBy = dealersettings ? dealersettings.id : '';
			state.modifiedDate = window.firebase.firestore.Timestamp.now();
			state.modifiedFrom = 'web';

			let tradein = tradeinProData.tradein
			let _enqbasicVM = Object.assign({}, enquiryBasicDetailsVM);
			for (let [key, value] of Object.entries(fields)) {
				if (_enqbasicVM.hasOwnProperty(key)) _enqbasicVM[key] = value;
			}
			Object.keys(_enqbasicVM).forEach(key => _enqbasicVM[key] === undefined ? delete _enqbasicVM[key] : {});

			if (!_.isEmpty(tradein)) {
				let _vehicle = Object.assign({}, tradein);
				_vehicle.enquiryID = enquiryID;
				_vehicle.enquiry = _enqbasicVM;
				_vehicle.linkedAppraisal = true;
				Object.keys(_vehicle).forEach(key => _vehicle[key] === undefined ? delete _vehicle[key] : {});
				state['tradein'] = _vehicle;
			}

			if (!_.isEmpty(tradeinProData?.tags)) {
				let tags = Object.assign([], tradeinProData?.tags);
				if (isRelink) tags = tags.filter(item => item.type !== 'ams pro');
				tags.push({ type: 'ams pro', refID: enquiryID, subType: 'linked' })
				state.tags = tags;
			}
			else
				state.tags = [{ type: 'ams pro', refID: enquiryID, subType: 'linked' }];


			const batch = firestoreDB(dealersettings).firestore().batch();
			batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}`), state, { merge: true });

			// update tradein
			let tradein_state = Object.assign({
				enquiryID: enquiryID,
				enquiry: _enqbasicVM,
				status: tradeinProData?.status,
				linkedAppraisal: true,
				sent: true,
				modifiedBy: state.modifiedBy,
				modifiedFrom: 'web',
				modifiedDate: state.modifiedDate
			});
			Object.keys(tradein_state).forEach(key => tradein_state[key] === undefined ? delete tradein_state[key] : {});

			batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradeinID}`), tradein_state, { merge: true });

			// update enquiry
			if (tradeinID && !fields.isTradeIn) {
				let objData = {};
				objData.isTradeIn = true;
				objData.modifiedBy = state.modifiedBy;
				objData.modifiedDate = window.firebase.firestore.Timestamp.now();
				objData.modifiedFrom = "web";
				objData = this.handleMoveStageForStatusChange("isTradeIn", objData);
				batch.set(firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`), objData, { merge: true });
			}

			// Object.assign([], ['tradeinProlog', 'log']).forEach(_type => {
			// 	let newlogVM = Object.assign({});
			// 	newlogVM.type = _type;
			// 	newlogVM.subType = 'note';
			// 	newlogVM.isDone = true;
			// 	newlogVM.isDeleted = false;
			// 	newlogVM.addedBy = localStorage.uid;
			// 	newlogVM.modifiedBy = localStorage.uid;
			// 	newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
			// 	newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
			// 	if (_type === 'tradeinProlog') {
			// 		newlogVM.tradeinProID = tradeinProID;
			// 		let contactID = tradeinProData?.contactID ? tradeinProData?.contactID : tradeinProData?.contact?.documentID
			// 		newlogVM.contactID = contactID ? contactID : null;
			// 		newlogVM.notes = `Linked Appraisal with Enquiry. \n(Enquiry no. ${fields?.displayID?.toUpperCase()})`;
			// 	}
			// 	else {
			// 		newlogVM.enquiryID = enquiryID;
			// 		newlogVM.contactID = (contact && contact.documentID) ? contact.documentID : null;
			// 		newlogVM.notes = `Linked Enquiry with Appraisal. \n(Valuation no. ${tradeinProData.displayID?.toUpperCase()})`;
			// 	}

			// 	newlogVM.clientID = tradeinProData.clientID ? tradeinProData.clientID : (dealersettings ? dealersettings.client.id : null);
			// 	newlogVM.projectId = tradeinProData.projectId ? tradeinProData.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
			// 	newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
			// 	newlogVM.startDate = window.firebase.firestore.Timestamp.now();
			// 	Object.keys(newlogVM).forEach(key => newlogVM[key] === undefined ? delete newlogVM[key] : {});
			// 	batch.set(firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
			// })


			batch.commit().then(snapshot => {

				toast.notify('Appraisal linked successfully', {
					duration: 2000
				})

				const batch_primary = window.firebase.firestore().batch();
				//update change logs    
				let _makeModel = (tradein.make ? tradein.make + ' ' : '') + (tradein.model ? tradein.model + ' ' : '') + (tradein.year ? tradein.year : '') + (tradein.regNo ? ', ' + tradein.regNo : '');
				//1 - enquiry logs
				CommonHelper.saveChangeLog(fields.clientID, fields.groupID, enquiryID, `Appraisal has been linked with this enquiry. \n(Valuation no. ${tradeinProData.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'enquiry', batch_primary);
				//2 - tip logs
				CommonHelper.saveChangeLog(fields.clientID, fields.groupID, tradeinProID, `Appraisal has been linked with the enquiry. \n(Enquiry no. ${fields?.displayID?.toUpperCase()})`, 'tradein', 'tradeinPro', batch_primary);

				if (isRelink) { //if relink , update with change log
					//1 - enquiry logs
					CommonHelper.saveChangeLog(tradein.clientID, tradein.groupID, _oldEnqID, `Appraisal has been unlinked from this enquiry. \n(Valuation no. ${tradeinProData.displayID?.toUpperCase()})` + (_makeModel ? ' (' + _makeModel + ')' : ''), 'tradein', 'enquiry', batch_primary);
					//2 - tip logs
					CommonHelper.saveChangeLog(fields.clientID, fields.groupID, tradeinProID, `Appraisal has been unlinked from the enquiry. \n(Enquiry no. ${_oldEnq?.displayID?.toUpperCase()})`, 'tradein', 'tradeinPro', batch_primary);

				}
				batch_primary.commit();
			}).catch((error) => {
				console.error("Error updating document: ", error);
			});


		}

		this.setState({ linkAppraisalModal: false })

	}

	//#region CHANGE EVENT ADD_ONS  
	handleAddOnsOpen = (id) => {
		this.setState({ showAddOns: true, productEditID: id });
	}

	handleAddOnsClose = () => {
		const { isProduct } = this.state.fields;
		const { productAddOns } = this.state;
		if (!isProduct && !_.isEmpty(productAddOns)) {
			this.saveEnquiryField('isProduct', true);
		}
		this.setState({ showAddOns: false, productEditID: null });
	}
	//#endregion

	isValidForRequestCreditScore = () => {
		const { dealersettings, fields } = this.state;
		let _mandatoryFields = ['firstName', 'state', 'dob']

		if (this.state.fields.isContact) {
			const ObjCustomer = Object.assign({}, this.state.contact);
			var msg = "";

			let _creditScoreTC = CommonHelper.getTermsByField(dealersettings.client, 'creditScoreTC', ObjCustomer.language);
			if (!_.isEmpty(_creditScoreTC)) _mandatoryFields.push('creditScoreTerms')

			_mandatoryFields.some((el, index) => {
				if (_.isEmpty(ObjCustomer[el]) && !_.isBoolean(ObjCustomer[el])) {
					msg = `${CommonHelper.showLocale(this.props, "Please enter")} ${CommonHelper.showLocale(this.props, el)}`;
					return true;
				}
			});
			if (msg) {
				Swal.fire({
					title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
					icon: "info",
					showCancelButton: true,
					confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
					cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
				}).then((result) => {
					if (result.value) {
						this.handleContactOpen("editContact", _mandatoryFields);
					}
				});

				return false;

			} else {
				this.handleRequestCreditScore();
			}
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact", _mandatoryFields);
				}
			});
			return false;
		}
	}
	handleRequestCreditScore = () => {
		if (this.state.fields.isContact) {
			this.setState({ loaderCreditScore: true })
			try {
				const getCreditScore = window.firebase.functions().httpsCallable(`contact-getCreditScore`);
				getCreditScore({
					"contact": this.state.contact,
					"enquiryID": this.state.enquiryID,
					"userID": localStorage.uid
				}).then((res) => {
					console.log('getCreditScore', res)
					this.setState({ loaderCreditScore: false });
					if (res.data.success) {
						toast.notify('Requested successfully.', {
							duration: 2000,
						});
					}
					else {
						Swal.fire(res.data.message, '', 'error')
					}
				}).catch((error) => {
					this.setState({ loaderCreditScore: false });
					console.log(error);
					Swal.fire('Something went wrong. Please contact support.', '', 'error')
				})
			}
			catch (error) {
				Swal.fire('Something went wrong. Please contact support.', '', 'error')
			}
			return true;
		} else {
			Swal.fire({
				title: CommonHelper.showLocale(this.props, "Please add contact"),
				icon: "info",
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
				cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
			}).then((result) => {
				if (result.value) {
					this.handleContactOpen("addContact");
				}
			});
			return false;
		}

	}
	//#endregion
	render() {

		//console.log('R E - R E N D E R - E N Q U I R Y - E D I T ');
		let { displayID, pipeline, stage, status, clientID,
			origin, campaign,
			financeBM, financeStatus, financeAmount, financeProvider, financeRate, financeStartDate, financeTerms,
			afterMarketBM, afterMarketStatus, stageHistory,
			requirement, owner, ownerInbound, responseTimeDisplay, responseTime,
			isFav, isContact, isVehicle, isTestDrive, isTradeIn, isCafe, isFiles, isVideo, isFinance, isQuotation, isProduct,
			addedBy, addedDate, modifiedDate, modifiedBy,
			engageContactName, engageNewContact,
			deliveryDate, lostDate, wonDate, deliveredDate,
			lastStatus, linkID,
			verifiedBy, verifiedDate, tags, receivedDate, modifiedFrom,
			convertedBy, convertedDate, isDeleted, isNewEnquiry, addedFrom,
			lostRefID, transferHistory,
			//isConvertedOEM, isConvertedRegion, isConvertedGroup, 
			//convertedByGroup, convertedByOEM, convertedByRegion,
			//convertedDateGroup, convertedDateOEM, convertedDateRegion,
			oemConvertedBy, oemConvertedDate, oemStatus,
			requirementCount, soldCount, leadSource, isAfterSales
		} = this.state.fields;

		const _reqCount = requirementCount && requirementCount > 1 ? (requirementCount - 1) : null;
		const _wonCount = soldCount && soldCount > 1 ? (soldCount) : null;
		const _currentStatus = status;
		status = (status && status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : status;

		const { businessContact, isVIP, contactType, gdprDeleted, marketingOptinEmail, marketingOptinSMS,
			company, phone, phoneCode, email, optinPhone, optinSMS, optinEmail, optinPost, banLoanVehicle
		} = this.state.contact;

		const {
			activeLeftKey, activeRightKey, pipelinePopOver, rottenDays, lostApprovalPopOver,
			contactModal, tradeInModal, autoGrabModal, testDriveModal, cafeOrderModal, requirementModal,
			ownedModal, showpanel, requirementMultiModal, fileModal, videoModal, showSharePanel,
			addFinanceModal, addDrivaFinanceModal, viewFinanceModal, requirements, enquiryBasicVM, contact,
			modules, lostReasons, lostSubReasons, lostModal, deliveryModal, walkinModal, selectVehicleModal,
			enquiryInfo, enquiryModal, walkins, ownedEnquiryModal, isEmptyPipeline, linkEnquiries,
			pipelineUsers, allClientUsers, allFinanceUsers, showMergeEnqList, sendLinkModal, subLeadModal,
			showNewLeadModal, showPipelineOnOpen, isAccessToReOpen, eventModal, activeTab, completedTradeinsList,
			amsmeUser, dealersettings, showPipelineModal, stockQuoteModal, scheduleContact, scheduleData, scheduleList,
			showNewLeadActivity, defaultStatus, showEvalScan, linkAppraisalModal, showContactInfo, showCAPLeads,
			allAfterMarketUsers, showAddOns, productAddOns, productEditID, files, showAddFile, filesPopOver,
			showLeadConvert, showOEMLeadConvert, showLeadTransfer, showReAuthentication, addProviderModal, notesModel,
			loaderCreditScore
		} = this.state;

		let search = window.location.search;
		let params = new URLSearchParams(search);
		let backURL = params.get('back-url');

		const { groupUsers, clientUsers, isFromLayout } = this.props;
		const primaryReqID = (!_.isEmpty(requirement)) ? requirement.documentID : '';

		const isGroupDealer = localStorage.defaultModule !== 'oem' && clientID && !_.isEmpty(dealersettings) && clientID !== dealersettings.client.id ? true : false;
		let leadOwner = pipeline === 'LeadsBucket' && ownerInbound ? ownerInbound : owner;

		let _client = clientID && !_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.group) &&
			!_.isEmpty(dealersettings.group.clients) &&
			dealersettings.group.clients[clientID] ? dealersettings.group.clients[clientID] : dealersettings.client;

		const _hasNoPipelineAccess = !isAfterSales && _client && _client.settings && !_client.settings.pipelines.some(item => item.value === pipeline) ? true : false;

		const isDeivered = (status === enquiryStatus.DELIEVERD || status === enquiryStatus.LOST || status === enquiryStatus.PENDINGLOST || status === enquiryStatus.PROSPECTLOST) ? true : false;

		const _permissions = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

		const _moduleSettings = ((_client &&
			!_.isEmpty(_client.moduleSettings)) ? _client.moduleSettings : null);

		const _clientSettings = ((!_.isEmpty(_client) &&
			!_.isEmpty(_client.clientSettings)) ? _client.clientSettings : null);

		const liteVersion = (!_.isEmpty(_clientSettings) && _clientSettings.liteVersion && localStorage.defaultModule !== 'oem') ? true : false;
		const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? (isFromLayout ? false : true) : false);

		const _isOEMConverted = localStorage.defaultModule === 'oem' && clientID ? true : false;

		const canModifyEnquiry = (readOnlyEnquiryView || _hasNoPipelineAccess || isDeleted || _isOEMConverted || gdprDeleted) ? false : (((!_.isEmpty(this.state.fields) && this.state.fields.owner === dealersettings.id) ||
			(!_.isEmpty(_permissions) && _permissions.modifyOtherEnquiry)) ? (isGroupDealer ? false : true) : false);

		const modifyRequirements = ((!_.isEmpty(_permissions) && _permissions.modifyRequirements) ? true : false);

		const movePipeline = ((!_.isEmpty(_permissions) && _permissions.movePipeline) ? true : false);

		//const modifyPurhaseInt = ((!_.isEmpty(_permissions) && _permissions.modifyPurhaseInt) ? true : false);

		//const modifyCampaign = ((!_.isEmpty(_permissions) && _permissions.modifyCampaign) ? true : false);

		//const modifyEnquiryOrigin = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOrigin) ? true : false);

		const modifyEnquiryOwner = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOwner) ? true : false);

		//const modifyEnquiryType = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryType) ? true : false);

		//const modifyPaymentMethod = ((!_.isEmpty(_permissions) && _permissions.modifyPaymentMethod) ? true : false);

		//const modifyLabel = ((!_.isEmpty(_permissions) && _permissions.modifyLabel) ? true : false);

		const reopenEnquiry = ((!_.isEmpty(_permissions) && _permissions.reopenEnquiry) ? true : false);

		//const modifyLostReason = ((!_.isEmpty(_permissions) && _permissions.modifyLostReason) ? true : false);

		const modifyStageAfterDeliveredLost = ((!_.isEmpty(_permissions) && _permissions.modifyStageAfterDeliveredLost) ? true : false);

		const modifyStatusDate = ((!_.isEmpty(_permissions) && _permissions.modifyStatusDate) ? true : false);

		const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
			_moduleSettings.finance.enabled) ? (!_.isEmpty(modules) && modules.finance ? true : false) : false);

		const fusioneventsEnabled = ((!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.fusionevents) &&
			dealersettings.client.moduleSettings.fusionevents.enabled) ? true : false);

		const eventsProEnabled = ((!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.eventsPro) &&
			dealersettings.client.moduleSettings.eventsPro.enabled) ? true : false);

		const tradeinPlusEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPlus) &&
			_moduleSettings.tradeinPlus.enabled) ? true : false);

		const tradeinProEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.tradeinPro) &&
			_moduleSettings.tradeinPro.enabled) ? true : false);

		const amsVideoEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.amsVideo) &&
			_moduleSettings.amsVideo.enabled) ? true : false);

		const currencySymbol = ((!_.isEmpty(_client) &&
			!_.isEmpty(_client.currency)) ? _client.currency.symbol + ' ' : '$ ');

		const leadVerifyEnabled = ((!_.isEmpty(_clientSettings) && _clientSettings.leadVerification) ? true : false);
		const lostApprovalEnabled = ((!_.isEmpty(_clientSettings) && _clientSettings.lostApproval) ? true : false);
		const hideSalesDoc = ((!_.isEmpty(_clientSettings) && _clientSettings.hideSalesDoc) ? true : false);

		const lostApproval = ((!_.isEmpty(_permissions) && _permissions.lostApproval) ? true : false);
		const verifyEnquiry = ((!_.isEmpty(_permissions) && _permissions.verifyEnquiry) ? true : false);
		const createLeadOnLost = ((!_.isEmpty(_permissions) && _permissions.createLeadOnLost) ? true : false);
		//const previewEnquiryAccess = ((!_.isEmpty(_permissions) && _permissions.previewEnquiry) ? true : false);

		const setttings = _client && _client.settings;

		const eraPowerEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "erapower")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "erapower")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "erapower")[0].enabled === true &&
			(_.isEmpty(tags) || (!_.isEmpty(tags) && !tags.some(a => a.type === 'erapower' && !_.isEmpty(a.refID))))
		) ? true : false);

		const pmdsEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "pmds")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "pmds")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "pmds")[0].enabled === true &&
			dealersettings.client.integrations.filter(e => e.type === "pmds")[0].settings &&
			dealersettings.client.integrations.filter(e => e.type === "pmds")[0].settings.pushManually === true
		) ? true : false);

		const keyloopEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "keyloop")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "keyloop")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "keyloop")[0].enabled === true &&
			dealersettings.client.integrations.filter(e => e.type === "keyloop")[0].settings
		) ? true : false);

		const revolutionEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "revolution")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "revolution")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "revolution")[0].enabled === true &&
			dealersettings.client.integrations.filter(e => e.type === "revolution")[0].settings
		) ? true : false);

		const autoitEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "autoit")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "autoit")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "autoit")[0].enabled === true &&
			dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings &&
			dealersettings.client.integrations.filter(e => e.type === "autoit")[0].settings.pushManually === true
		) ? true : false);

		const titanEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "titan")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "titan")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "titan")[0].enabled === true &&
			dealersettings.client.integrations.filter(e => e.type === "titan")[0].settings &&
			dealersettings.client.integrations.filter(e => e.type === "titan")[0].settings.pushManually === true
		) ? true : false);

		const drivaEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
		) ? true : false);

		const driva = (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "driva")[0]
		)

		const capEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "cap")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "cap")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "cap")[0].enabled === true &&
			(_.isEmpty(tags) || (!_.isEmpty(tags) && !tags.some(a => a.type === 'cap' && !_.isEmpty(a.refID))))
		) ? true : false);

		let dealerName = '';
		if (clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
			dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);

		let dealerNameContact = '';
		if (contact && contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
			dealerNameContact = CommonHelper.getOtherDealerName(dealersettings, contact.clientID);

		//const enableLeadTransfer = ((!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && dealersettings.group.enableLeadTransfer) ? true : false);
		const dealerLeadTransfer = ((!_.isEmpty(_permissions) && _permissions.dealerLeadTransfer) ? true : false);

		const _transferHistoryData = !_.isEmpty(transferHistory) ? CommonHelper.getLatestTransferHistory(transferHistory, dealersettings, true) : null;

		//console.log('_transferHistoryData', _transferHistoryData, transferHistory)

		const isQuotationEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings) &&
			!_.isEmpty(dealersettings.client.clientSettings.quotation) &&
			dealersettings.client.clientSettings.quotation.enabled &&
			localStorage.defaultModule !== 'oem') ? true : false);
		const addQuotation = ((!_.isEmpty(_permissions) && _permissions.addQuotation) ? true : false);
		const deleteQuotation = ((!_.isEmpty(_permissions) && _permissions.deleteQuotation) ? true : false);

		let dynamicEnquiryDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.enquiryOptions))
			? dealersettings.client.enquiryOptions :
			Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

		dynamicEnquiryDetailList = isAfterSales ? _.uniq(_.union(['serviceType'], dynamicEnquiryDetailList)) : dynamicEnquiryDetailList;

		const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);
		const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);

		const enableProspectLost = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.enableProspectLost) ? true : false);

		const disableTradeInLink = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.disableTradeInLink) ? true : false);

		const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
		) ? true : false);

		const isAmsMeContact = amsMeEnabled && contact && contact.clientID && contact.documentID && !_.isEmpty(amsmeUser) && !_.isEmpty(amsmeUser.amsProLink) &&
			amsmeUser.amsProLink.filter(e => e.clientID === contact.clientID && e.contactID === contact.documentID)[0] ? true : false;

		//const amsMeID = !_.isEmpty(contact) && !_.isEmpty(contact.amsMe) && !_.isEmpty(contact.amsMe.meID) ? contact.amsMe.meID : null;

		// FOR DYNAMIC STATUS 		
		let _objCurrentStatus = status && enquiryAllStatus.filter(e => e.value === status)[0]
		const isDymStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, status)
		const isStatusOpen = (_.isEmpty(status) || isDymStatusOpen || status === enquiryStatus.PENDING) ? true : false;
		const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)

		let _dynamicStatusMenu = CommonHelper.bindEnquiryStatusMenu(enquiryAllStatus, status, this.state.fields, (canModifyEnquiry ? true : false), defaultStatus);

		const tvModuleEnabled = ((!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.tvModule) &&
			dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);
		const accessToTVDisplay = ((!_.isEmpty(_permissions) && _permissions.accessToTVDisplay) ? true : false);
		const accessToFinanceTab = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceManager) ? true : false);
		const accessToFinanceActivity = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceActivity) ? true : false);
		const accessToBusinessManager = ((!_.isEmpty(_permissions) && _permissions.accessToBusinessManager) ? true : false);

		const accessToAfterMarketTab = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketTab) ? true : false);
		const accessToAfterMarketActivity = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketActivity) ? true : false);

		let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;

		const ismodifyStatusDate = (modifyStatusDate && (isStatusSold || status === enquiryStatus.LOST || status === enquiryStatus.PROSPECTLOST || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD))
		const isModifyInboundOwner = (!_.isEmpty(ownerInbound) && pipeline !== 'LeadsBucket' && modifyEnquiryOwner)

		const accessToCreateSubLeads = ((!_.isEmpty(_permissions) && _permissions.accessToCreateSubLeads) ? true : false);
		const soldIDs = Array.prototype.map.call(requirements.filter(m => m.isSold), function (item) { return item.documentID; }).join(",").split(',');
		const enableSubLeadsonWon = (dealersettings?.client?.clientSettings?.enableSubLeadsonWon) ? true : false;

		const aircallEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
		) ? true : false);

		const evalexpertEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "evalexpert")[0].enabled === true
		) ? true : false);

		const _productAddons = Object.assign([], setttings?.productAddons?.filter(a => a.active))
		const filesCategory = Object.assign([], setttings?.filesCategory?.filter(a => a.active))
		const financeProviders = Object.assign([], setttings?.financeProviders?.filter(a => a.active))
		const accessToAddProduct = ((!_.isEmpty(_permissions) && _permissions.accessToAddProduct) ? true : false);
		const accessToProduct = ((!_.isEmpty(_permissions) && _permissions.accessToProduct) ? true : false);

		const accessToViewFinanceCreditScore = (!_.isEmpty(dealersettings?.client?.csID) && (!_.isEmpty(_permissions) && _permissions.accessToViewFinanceCreditScore) ? true : false);
		const accessToRequestFinanceCreditScore = ((!_.isEmpty(_permissions) && _permissions.accessToRequestFinanceCreditScore) ? true : false);
		return this.state.enquiryID ? (
			<>
				<div className={`middle-wrapper ${isFromLayout ? 'middle-wrapper-pipeline mt-0' : ''}`}>
					{
						enquiryInfo.new && !isFromLayout && !isDeleted
							?
							<>
								<div className="new-enq-header">
									<div className="new-enq-button">
										{
											owner === localStorage.uid || ownerInbound === localStorage.uid
												?
												<>
													<button type="button"
														className="btn btn-common-fullscreen btn-danger float-right ml-2"
														onClick={(e) => { e.preventDefault(); this.handleDeleteEnquiry(); }}>
														<Translate text={'delete'} />
													</button>
												</>
												:
												<>
												</>
										}

										<button type="button"
											className="btn btn-common-fullscreen button-blue float-right"
											onClick={(e) => { e.preventDefault(); this.handleSave(); }}>
											<Translate text={'save'} />
										</button>
									</div>
									<h4 className="modal-title" > <Translate text={enquiryInfo.lead ? 'addNewLead' : 'addNewEnquiry'} /></h4>
								</div>
							</>
							:
							<>
							</>
					}

					<div className="enquiry-detailswrap">
						<div className="enquiry-detail-head">
							<div className="float-left  enquiry-details-title-wrap">
								{
									(isContact)
										?
										<>
											<div className='enquiry-details-contact'>
												{businessContact ? (
													<i className={`ico icon-company ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} mr-1`}></i>
												) : (
													<i className={`ico icon-Contacts ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''} mr-1`}></i>
												)}

												<a
													href='#'
													onClick={e => {
														e.preventDefault();
														this.props.history.push(
															'/contacts/details/' +
															this.state.contact.documentID +
															'?back-url=/enquiry/details/' +
															this.state.enquiryID,
														);
													}}
												>
													{CommonHelper.displayContact(titles, this.state.contact, '--')}
												</a>
											</div>

											<>{CommonHelper.displayPreferredContact(this.state.contact)}</>

											{
												isVIP ?
													(<span className='enquiry-details-preferred'> ({'VIP'})</span>) :
													(<></>)
											}

											<span className='enquiry-details-favourite-ico'>
												{
													(isFav)
														?
														<>
															<a href='#' onClick={e => { e.preventDefault(); }}>
																<i
																	className='ico icon-star-active'
																	onClick={e => {
																		e.preventDefault();
																		this.saveEnquiryField('isFav', false);
																	}}
																></i>
															</a>

														</>
														:
														<>
															<a href='#' onClick={e => { e.preventDefault(); }}>
																<i
																	className='ico icon-star'
																	onClick={e => {
																		e.preventDefault();
																		this.saveEnquiryField('isFav', true);
																	}}
																></i>
															</a>
														</>
												}
											</span>

											<div className="enquiry-details-optin">
												{
													optinPhone === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'optoutPhone'} /></Tooltip>}
															>
																<i className={`ico icon-no-call`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}

												{
													optinSMS === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'optoutSMS'} /></Tooltip>}
															>
																<i className={`ico icon-no-sms`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}
												{
													optinEmail === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'optoutEmail'} /></Tooltip>}
															>
																<i className={`ico icon-no-email`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}
												{
													optinPost === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'optoutPost'} /></Tooltip>}
															>
																<i className={`ico icon-no-post`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}

												{
													marketingOptinEmail === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'marketingoptoutEmail'} /></Tooltip>}
															>
																<i className={`ico icon-no-email`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}
												{
													marketingOptinSMS === false
														?
														<span>
															<OverlayTrigger
																placement='bottom'
																overlay={<Tooltip><Translate text={'marketingoptoutSMS'} /></Tooltip>}
															>
																<i className={`ico icon-no-sms`}></i>
															</OverlayTrigger>
														</span>
														:
														<></>
												}
												{
													banLoanVehicle ? <span>
														<OverlayTrigger
															placement='bottom'
															overlay={
																<Tooltip><Translate text={'banLoanVehicle'} /></Tooltip>
															}
														>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i>
														</OverlayTrigger>
													</span> : <></>
												}
											</div>
										</>
										:
										<>
											{
												!_.isEmpty(engageNewContact)
													?
													<>
														<div className="enquiry-details-contact text-red-indicator">
															{CommonHelper.displayContactIcon(engageNewContact, 'mr-1')}

															{CommonHelper.displayContact(titles, engageNewContact, '--')}

														</div>
														{CommonHelper.displayPreferredContact(engageNewContact)}

														{
															engageNewContact.isVIP ?
																(<span className='enquiry-details-preferred'> ({'VIP'})</span>) :
																(<></>)
														}

														<span className="enquiry-details-favourite-ico">
															{(() => {
																if (isFav) {
																	return (
																		<a href="#"><i className="ico icon-star-active" onClick={(e) => {
																			e.preventDefault();
																			this.saveEnquiryField('isFav', false);
																		}}></i></a>
																	)
																}
																else {
																	return (
																		<a href="#"><i className="ico icon-star" onClick={(e) => {
																			e.preventDefault();
																			this.saveEnquiryField('isFav', true);
																		}}></i></a>
																	)
																}
															})()}

														</span>
														<div className="enquiry-details-optin">
															{
																engageNewContact.optinPhone === false
																	?
																	<span><i className={`ico icon-no-call`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.optinSMS === false
																	?
																	<span><i className={`ico icon-no-sms`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.optinEmail === false
																	?
																	<span><i className={`ico icon-no-email`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.optinPost === false
																	?
																	<span><i className={`ico icon-no-post`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.marketingOptinEmail === false
																	?
																	<span><i className={`ico icon-no-email`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.marketingOptinSMS === false
																	?
																	<span><i className={`ico icon-no-sms`}></i></span>
																	:
																	<></>
															}
															{
																engageNewContact.banLoanVehicle ? <span><i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, `ico icon-ban-loan`)}></i></span> : <></>
															}
														</div>
														{/* <div className="quickview-contact-list">
															<ul>
																{engageNewContact.phone ? <li><i className="ico icon-call"></i> {CommonHelper.getFullPhone(engageNewContact.phoneCode, engageNewContact.phone)}</li> : <></>}
																{engageNewContact.email ? <li><i className="ico icon-Mail"></i> <SendMail
																	email={engageNewContact.email}
																	enquiry={this.props.enquiry}
																	dealersettings={dealersettings}
																	history={this.props.history}
																>
																</SendMail></li> : <></>}


															</ul>
														</div> */}
													</>
													:
													<></>
											}
										</>
								}
								<div className="enquiry-subtitle">
									{
										(!_.isEmpty(requirement) && (requirement.make || requirement.stock))
											?
											<>
												{
													(!_.isEmpty(requirement.stock) && !_.isEmpty(requirement.stock.make))
														?
														<span>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
															{(requirement.stock.make ? requirement.stock.make : '') + ' ' + (requirement.stock.model ? requirement.stock.model : '')}
															{requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
																{CommonHelper.getNameByValue(_client && _client.settings && _client.settings.salesType,
																	requirement.saleType, '')}
															</div>) : ''}

														</span>
														:
														<span>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
															{(requirement.make ? requirement.make : '') + ' ' + (requirement.model ? requirement.model : '')}
															{requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
																{CommonHelper.getNameByValue(_client && _client.settings && _client.settings.salesType,
																	requirement.saleType, '')}
															</div>) : ''}

														</span>
												}

												{
													_reqCount
														?
														<OverlayTrigger
															placement='bottom'
															overlay={
																<Tooltip><Translate text={'Requirements'} /></Tooltip>
															}
														>
															<div className="badge badge-pill badge-blue badge-mini ml-1">
																+{_reqCount} <Translate text={'more'} lowerCase={true} />
															</div>
														</OverlayTrigger>

														:
														<></>
												}

												{
													_wonCount
														?
														<OverlayTrigger
															placement='bottom'
															overlay={
																<Tooltip><Translate text={'Won Requirements'} /></Tooltip>
															}
														>
															<div className="badge badge-pill badge-available badge-mini ml-1">
																{_wonCount} <Translate text={'won'} upperCase={true} />
															</div>

														</OverlayTrigger>

														:
														<></>
												}
											</>
											:
											<>
											</>

									}



								</div>
								{
									(isContact === false && isVehicle === false)
										?
										<div className="addnew-enquiry-title">{displayID ? displayID : (`ENQ-` + this.state.enquiryID)}</div>
										:
										<></>
								}
							</div>
							<div className="float-right">
								{
									(status)
										?
										<div className='current-status mt-3'>


											{
												_objCurrentStatus
													?
													<>
														<Translate text={'currentStatus'} />
														<div className={`badge badge-pill badge-${status === 'open' ? 'open' : 'empty'} ml-2`}
															style={{ background: (_objCurrentStatus && _objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
															{
																!_.isEmpty(_dynamicStatusMenu)
																	?
																	<DropDownMenu
																		text={_objCurrentStatus ? _objCurrentStatus.name : status}
																		keyVal="100898"
																		tIndex="-1"
																		menu={_dynamicStatusMenu}
																		handleChange={this.handleDynamicStatus}
																	>
																	</DropDownMenu>
																	:
																	<Translate
																		text={_objCurrentStatus ? _objCurrentStatus.name : status.toLowerCase()}
																		upperCase={true}
																	/>
															}
														</div>
													</>
													:
													<>

													</>
											}

											{
												(_currentStatus === enquiryStatus.PENDINGLOST && lastStatus && !lostApproval && lostApprovalEnabled)
													?
													<>
														<div
															className={`badge badge-pill badge-pendinglost ml-2`}
														>
															<Translate
																text={'pending approval'}
																upperCase={true}
															/>
														</div>
													</>
													:
													<>
													</>
											}

										</div>
										:
										<>
											{(pipeline && pipeline !== 'LeadsBucket') && (<div className='current-status mt-3'>
												<div className={`badge badge-pill badge-empty`} style={{ color: '#333' }}>
													<DropDownMenu
														text={'Select status'}
														keyVal="10045341"
														tIndex="-1"
														menu={_dynamicStatusMenu}
														handleChange={this.handleDynamicStatus}
													>
													</DropDownMenu>
												</div>
											</div>)}
										</>

								}

								{
									canModifyEnquiry
										? (
											<>
												{pipeline === 'LeadsBucket' ? (
													<>
														{
															enableProspectLost && !isNewEnquiry
																?
																<>
																	<div className="status-btn-wrap">
																		{(() => {
																			if (_.isEmpty(status) || isStatusOpen || status === 'reopen') {
																				return (
																					<>
																						<a href="#" className="btn btn-common button-red" onClick={(e) => {
																							e.preventDefault();
																							this.handleEnquiryProspectLost();
																						}}>  <Translate text={'prospectLost'} upperCase={true} /></a>
																					</>
																				)
																			}
																			else if ((status === 'prospectLost')) {
																				return (
																					<>
																						<a href="#" className="btn btn-common button-reopen" onClick={(e) => {
																							e.preventDefault();
																							this.saveEnquiryStatus('open', true)
																						}}> <Translate text={`reopen`} upperCase={true} /></a>
																					</>
																				)
																			}
																		})()}
																	</div>
																</>
																:
																<></>
														}
														{
															(_currentStatus === enquiryStatus.PENDINGLOST && lastStatus && lostApproval && lostApprovalEnabled)
																?
																<>
																	<div className='status-btn-wrap'>
																		<a href="#" className="btn btn-common button-grey " onClick={(e) => {
																			e.preventDefault();
																			this.setState({
																				lostApprovalPopOver: { showpopover: true, targetpopover: e.target }
																			});
																		}}><Translate text={'Lost Approval'} upperCase={true} /></a>
																	</div>
																</>
																:
																<>
																</>
														}

														{
															(_.isEmpty(status) || status !== enquiryStatus.PROSPECTLOST)
																?
																<>
																	{
																		(localStorage.defaultModule === 'oem' && _.isEmpty(clientID) && (dealersettings.level === 'oem' || dealersettings.level === 'region' || dealersettings.level === 'group'))
																			?
																			<>
																				{
																					isContact && oemStatus === 'pending'
																						?
																						<div className='status-btn-wrap'>
																							<a
																								href='#'
																								className='btn btn-common button-blue'
																								onClick={e => {
																									e.preventDefault();
																									if (dealersettings.level === 'oem')
																										this.setState({ showOEMLeadConvert: true });
																								}}
																							>
																								<Translate text={'allocate'} upperCase={true} />
																							</a>
																						</div>
																						:
																						<></>
																				}
																			</>
																			:
																			<>{
																				!enquiryInfo.new && localStorage.defaultModule !== 'oem' && localStorage.defaultModule !== 'reports'
																					?
																					<>
																						<div className='status-btn-wrap'>
																							<a
																								href='#'
																								className='btn btn-common button-blue'
																								onClick={e => {
																									e.preventDefault();
																									this.handleLeadConvertShow();
																								}}
																							>
																								<Translate text={'Convert'} upperCase={true} />
																							</a>
																						</div>
																					</>
																					:
																					<>
																					</>
																			}</>
																	}
																</>
																:
																<></>
														}

													</>
												) : (
													<>
														<div className='status-btn-wrap'>
															{(() => {
																if (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED) {
																	return (
																		<>

																			{
																				reopenEnquiry
																					?
																					<>
																						<a href="#" className="btn btn-common button-reopen" onClick={(e) => {
																							e.preventDefault();
																							this.handleEnquiryOpen();
																						}}> <Translate text={`reopen`} upperCase={true} /> </a>
																					</>
																					:
																					<></>
																			}
																		</>
																	)
																}
																else if ((status === enquiryStatus.LOST || status === enquiryStatus.DELIEVERD) && reopenEnquiry) {
																	return (
																		<>
																			<a href="#" className="btn btn-common button-reopen" onClick={(e) => {
																				e.preventDefault();
																				this.handleEnquiryOpen();
																			}}> <Translate text={`reopen`} upperCase={true} /> </a>
																		</>
																	)
																}
															})()}
															{(() => {
																if (tvModuleEnabled && accessToTVDisplay && (isStatusSold || status === enquiryStatus.DELIEVERD || status === enquiryStatus.WON)) {
																	return !_.isEmpty(scheduleData) ? (
																		<div className="reportsuccess-item-btn mr-2 float-right cursor-pointer ml-2" onClick={(e) => {
																			e.preventDefault();
																			this.setState({
																				scheduleContact: {
																					show: true,
																					title: 'Schedule Contact',
																					scheduleEnq: scheduleData
																				}
																			});
																		}}>
																			<div className="reportsuccess-logo"><i className="ico icon-monitor"></i></div>
																			<div className="reportsuccess-inner-text">
																				<div className="reportsuccess-inner-text-top"> {scheduleData.deviceName ? scheduleData.deviceName : 'Scheduled'} </div>
																				<div className="reportsuccess-inner-text-sub">{`${moment.unix(scheduleData.startDate.seconds).format('DD/MM/YYYY h:mm A')}`}</div>
																			</div>
																			<div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
																		</div>
																	) : (
																		<>
																			<a href="#" className="btn btn-secondary ml-2" onClick={(e) => {
																				e.preventDefault();
																				this.setState({
																					scheduleList: {
																						show: true,
																						title: 'Schedule Contact'
																					}
																				});
																			}}><i className="ico icon-monitor"></i>
																				<Translate text={`Schedule`} upperCase={true} /> </a>
																		</>
																	)
																}
															})()}
														</div>
														{
															!isAfterSales && ((_currentStatus === enquiryStatus.PENDINGLOST && lastStatus && lostApproval && lostApprovalEnabled) || (leadVerifyEnabled && verifyEnquiry && !verifiedBy) || (eraPowerEnabled) || (pmdsEnabled) || autoitEnabled || titanEnabled || keyloopEnabled || revolutionEnabled || capEnabled || (createLeadOnLost))
																?
																<>
																	<div className="btn-divider status-btn-wrap">
																		{
																			(_currentStatus === enquiryStatus.PENDINGLOST && lastStatus && lostApproval && lostApprovalEnabled)
																				?
																				<>
																					<a href="#" className="btn btn-common button-grey " onClick={(e) => {
																						e.preventDefault();
																						this.setState({
																							lostApprovalPopOver: { showpopover: true, targetpopover: e.target }
																						});
																					}}><Translate text={'Lost Approval'} upperCase={true} /></a>
																				</>
																				:
																				<>
																				</>
																		}
																		{
																			(eraPowerEnabled && !enquiryInfo.lead)
																				? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToDMS(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To DMS'} upperCase={true} />
																					</a>
																				) : capEnabled ? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToCAP(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To C@P'} upperCase={true} />
																					</a>
																				) : pmdsEnabled ? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToPMDS(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To DMS'} upperCase={true} />
																					</a>
																				) : autoitEnabled ? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToAutoIT(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To DMS'} upperCase={true} />
																					</a>
																				) : titanEnabled ? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToTitan(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To DMS'} upperCase={true} />
																					</a>
																				) : revolutionEnabled ? (
																					<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToRevolution(e)}>
																						{
																							this.state.loaderErapower ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																						}
																						<Translate text={'Push To DMS'} upperCase={true} />
																					</a>
																				) : keyloopEnabled ? (<>
																					{
																						(_.isEmpty(tags) || (!_.isEmpty(tags) && !tags.some(a => a.type === 'keyloop' && !_.isEmpty(a.refID)))) ? (
																							<a href="#" className="btn btn-common button-grey" onClick={(e) => this.isValidForPushToKeyloop(e)}>
																								{
																									this.state.loaderErapower ?
																										(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																										:
																										(<i className="fa fa-arrow-right mr-2" aria-hidden="true"></i>)
																								}
																								<Translate text={'Push To DMS'} upperCase={true} />
																							</a>
																						) : (<></>)
																					}
																					<a href="#" className={`btn btn-common button-${this?.state?.requirement?.stock?.keyloopReserved ? 'red' : 'green'}`} onClick={(e) => this.isValidForReserveVehicle(e, this?.state?.requirement?.stock?.keyloopReserved)}>
																						{
																							this.state.loaderReserveVehicle ?
																								(<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)
																								:
																								(<></>)
																						}
																						{
																							this?.state?.requirement?.stock?.keyloopReserved ? (
																								<Translate text={'Cancel Reservation'} upperCase={true} />
																							)
																								: (
																									<Translate text={'Reserve Vehicle'} upperCase={true} />
																								)
																						}

																					</a>
																				</>) : (<></>)
																		}
																		{
																			(leadVerifyEnabled && verifyEnquiry && !verifiedBy)
																				?
																				<>
																					<a href="#" className="btn btn-common button-blue " onClick={(e) => {
																						e.preventDefault();
																						this.handleVerifyEnqiry();
																					}}>  <Translate text={'verify'} upperCase={true} /></a>
																				</>
																				:
																				<>
																				</>
																		}
																		{
																			(createLeadOnLost && status === 'lost' && _.isEmpty(lostRefID))
																				?
																				<>
																					<a href="#" className="btn btn-common button-blue " onClick={(e) => {
																						e.preventDefault();
																						this.handleNewLeadOpen();
																					}}><i className="ico icon-enquiry mr-2" aria-hidden="true"></i> <Translate text={'New Lead'} upperCase={true} /></a>
																				</>
																				:
																				<>
																				</>
																		}

																	</div>
																</>
																:
																<></>
														}


													</>
												)}


											</>
										) : (
											<></>
										)}

								{
									backURL && !isFromLayout
										?
										<div className='filter-item-btn ml-2 mt-2'>
											<a
												href='#'
												onClick={e => {
													e.preventDefault();
													this.props.history.push(backURL);
												}}
											>
												<i className='fa fa-chevron-left' aria-hidden='true'></i>{' '}
												<Translate text={'back'} />
											</a>
										</div>
										:
										<></>
								}
								{
									!enquiryInfo.new
										?
										<>
											<Dropdown className='enquiry-view-more'>
												<Dropdown.Toggle as={CustomToggle} className='common-button'>
													<i className='ico icon-more'></i>
												</Dropdown.Toggle>
												<Dropdown.Menu
													as={CustomMenu}
													ChildClass="more-dropdown dropsub-fix"
													xplacement="bottom-end"
												>
													<Dropdown.Item eventKey="1" onClick={(e) => { this.handleSharePanelOpen(e) }}><i className="ico icon-share"></i> <Translate text={'Share'} /> </Dropdown.Item>

													{
														clientID
															?
															<Dropdown.Item eventKey="4" onClick={(e) => {
																e.preventDefault();
																const url = `/enquiry/preview/${this.state.enquiryID}?isView=y`;
																window.open(url, '_blank');
															}}><i className="ico icon-waiver"></i><Translate text={'preview'} /></Dropdown.Item>
															:
															<></>
													}

													{
														!isAfterSales && dealerLeadTransfer && canModifyEnquiry && (localStorage.defaultModule !== 'oem' || clientID)
															?
															<Dropdown.Item eventKey="2" onClick={(e) => { this.handleTransferEnqiry(e); }}><i className="fa fa-file-export transfer-icon-fix"></i><Translate text={'Dealer Transfer'} /></Dropdown.Item>
															:
															<></>
													}
													{
														(!_.isEmpty(dealersettings) &&
															!_.isEmpty(dealersettings.rolePermissions) &&
															!_.isEmpty(dealersettings.rolePermissions.permissions) &&
															dealersettings.rolePermissions.permissions.mergeContact) && canModifyEnquiry && isContact
															?
															<Dropdown.Item eventKey="4" onClick={(e) => { this.handleOpenMerge(e); }}><i className="ico icon-merge"></i> <Translate text={'merge'} /> </Dropdown.Item>
															:
															<></>
													}
													{
														(!_.isEmpty(dealersettings.rolePermissions) &&
															!_.isEmpty(dealersettings.rolePermissions.permissions) &&
															dealersettings.rolePermissions.permissions.deleteEnquiry) && canModifyEnquiry
															?
															<Dropdown.Item eventKey="3" onClick={(e) => { e.preventDefault(); this.handleDeleteEnquiry(); }}><i className="ico icon-delete"></i> <Translate text={enquiryInfo.new ? 'cancel' : 'delete'} /> </Dropdown.Item>
															:
															<></>
													}


												</Dropdown.Menu>
											</Dropdown>
										</>
										:
										<>
										</>
								}

							</div>
						</div>


						<div className="enquiry-detail-bottom">
							<div className="float-left mt-2">
								<ul className="enquiry-details">
									{
										isContact || !_.isEmpty(engageNewContact)
											?
											<>
												<li> <i className="ico icon-enquiry"
													onDoubleClick={(e) => {
														e.preventDefault();
														console.log('state.dealersettings:-', this.state.dealersettings)
														console.log('props.dealersettings:-', this.props.dealersettings)
														console.log('Enquiry:-', this.state.fields)
														console.log('Contact:-', this.state.contact)
														console.log('clientUsers:-', clientUsers)
														console.log('groupUsers:-', groupUsers)
														console.log('pipelineUsers:-', pipelineUsers)
													}}></i> {displayID ? displayID : (`ENQ-` + this.state.enquiryID)}</li>
											</>
											:
											<></>
									}



									{!_.isEmpty(_transferHistoryData) ?
										<li>
											<div className="oem-tag-id w-100 mt-0"><i className="ico icon-location"></i>
												{_transferHistoryData.name}
												<span className="user-role-span ml-1">{_transferHistoryData.value}</span>
											</div>
										</li> :
										<>
											{dealerName ? (
												<li> <span className="blue-color"><i className="ico icon-group"></i>{dealerName}</span></li>
											) : (
												<></>
											)}
										</>
									}

									{
										tags && tags.length > 0 ? (<li>
											{
												tags.map((r, i) => {
													return r.subType ? (
														<span key={i} className="lead-badge-border">
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
															<div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">{r.subType}</div>
														</span>
													) : (<div key={i} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title={r.refID || ''}>{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
												})
											}
										</li>) : (<></>)
									}
									{
										isContact
											?
											<>
												{
													aircallEnabled && phone ? (
														<li> <i className="ico icon-call"></i><a onClick={(e) => {
															e.preventDefault();
															this.props.dialNumber(CommonHelper.validPhone(phone, phoneCode), this?.state?.enquiryID, this?.state?.contact?.documentID)
														}} href='#' className='hyperlink-blue'>{phone ? CommonHelper.getFullPhone(phoneCode, phone) : '--'}</a></li>
													) : (
														<li> <i className="ico icon-call"></i> {phone ? CommonHelper.getFullPhone(phoneCode, phone) : '--'}</li>
													)
												}
												<li> <i className="ico icon-Mail"></i>
													{email ?
														<SendMail
															email={email}
															enquiry={this.state.fields}
															dealersettings={dealersettings}
															history={this.props.history}
														>
														</SendMail> : <>{'--'}</>}
												</li>
												{
													(businessContact)
														?
														<li>
															<i className={`ico icon-Contacts ${contactType && contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''}`}></i>
															{CommonHelper.getFullName(titles, this.state.contact, '--')}
														</li>
														:
														<li> <i className="ico icon-company"></i> {company && company.name ? company.name : '--'}</li>

												}
											</>
											:
											<>
												{
													!_.isEmpty(engageNewContact) && (<>
														<li> <i className="ico icon-call"></i> {engageNewContact?.phone ? CommonHelper.getFullPhone(engageNewContact?.phoneCode, engageNewContact?.phone) : '--'}</li>
														<li> <i className="ico icon-Mail"></i>
															{engageNewContact?.email ?
																<SendMail
																	email={engageNewContact?.email}
																	enquiry={this.state.fields}
																	dealersettings={dealersettings}
																	history={this.props.history}
																>
																</SendMail> : <>{'--'}</>}
														</li>
														{
															(engageNewContact?.businessContact)
																?
																<li>
																	<i className={`ico icon-Contacts ${engageNewContact?.contactType && engageNewContact?.contactType === 'Potential Customer' ? 'potentialcustomer-ico' : ''}`}></i>
																	{CommonHelper.getFullName(titles, engageNewContact, '--')}
																</li>
																:
																<li> <i className="ico icon-company"></i> {engageNewContact?.company && engageNewContact?.company.name ? engageNewContact?.company.name : '--'}</li>

														}
													</>
													)
												}
											</>
									}


									<li><i className="ico icon-date"></i>  <Translate text={'created'} /> - {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY') : '--'}</li>

									{
										(!_.isEmpty(walkins))
											?
											<li>
												<i className="ico icon-walkin"></i>
												<Translate text={'lastWalkIn'} /> -{' '}
												{moment.unix(walkins[0].walkinDate.seconds).format('DD MMM YYYY')}
											</li>
											:
											<></>
									}

									{
										(!isAfterSales && deliveryDate && (isStatusSold || status === enquiryStatus.WON || (!deliveredDate && status === enquiryStatus.DELIEVERD)))
											?
											<li className=" delivery-date">
												<i className="ico icon-delivery"></i>
												<Translate text={'estimatedDelivery'} /> -{' '}
												{moment.unix(deliveryDate.seconds).format('DD MMM YYYY')}
											</li>
											:
											<></>
									}

									{
										(!isAfterSales && deliveredDate && status === enquiryStatus.DELIEVERD)
											?
											<li className=" delivery-date">
												<i className="ico icon-delivery"></i>
												<Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered')} /> -{' '}
												{moment.unix(deliveredDate.seconds).format('DD MMM YYYY')}
											</li>
											:
											<></>
									}
									{/* {
													(financeStatus && financeEnabled)
														?
														<li>
															<i className="ico icon-finance"></i>
															<Translate text={'financeStatus'} />
															<div
																className={`badge badge-pill badge-status-${financeStatus.toLowerCase()} ml-2`}
															>
																<Translate
																	text={financeStatus.toLowerCase()}
																	upperCase={true}
																/>
															</div>
														</li>
														:
														<></>

												} */}
								</ul>
							</div>
							<div className="float-right">
								<InviteAmsMe
									dealersettings={dealersettings}
									clientUsers={this.props.clientUsers}
									amsMeEnabled={amsMeEnabled}
									isContact={isContact}
									isAmsMeContact={isAmsMeContact}
									contact={contact}
								></InviteAmsMe>

								<div
									className={`filter-item-btn filter-owner-btn ml-2 ${canModifyEnquiry ? "" : "form-disable"
										}`}
								>
									{pipeline === "LeadsBucket" ? (
										<ReactSelect
											options={isAfterSales ? allClientUsers : pipelineUsers}
											name={"ownerInbound"}
											placeholder={"select ownership"}
											onChange={this.handleSelectSave}
											value={ownerInbound ? ownerInbound : owner}
											classNamePrefix={`cursor-pointer ${this.state.errors["ownerInbound"]} basic-select`}
											removeClearable={true}
											isSearchable={true}
											isDisabled={
												canModifyEnquiry
													? modifyEnquiryOwner
														? false
														: true
													: true
											}
										></ReactSelect>
									) : (
										<ReactSelect
											options={isAfterSales ? allClientUsers : pipelineUsers}
											name={"owner"}
											placeholder={"select ownership"}
											onChange={this.handleSelectSave}
											value={owner ? owner : addedBy}
											classNamePrefix={`cursor-pointer ${this.state.errors["owner"]} basic-select`}
											removeClearable={true}
											isSearchable={true}
											isDisabled={
												canModifyEnquiry
													? modifyEnquiryOwner
														? false
														: true
													: true
											}
										></ReactSelect>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="enquiry-stagewrap">
						<div className="enquiry-stagewrap-head">
							<div className="float-left">
								{!isEmptyPipeline ? (
									<>
										<div
											className={`pipeline-info-drop ${movePipeline && canModifyEnquiry ? "" : "form-disable"}`}
											title={CommonHelper.showPSAge(stageHistory, pipeline, null, 'pipeline')}
											onClick={(e) => {
												e.preventDefault();
												if (
													!isAfterSales &&
													pipeline !== "LeadsBucket" &&
													movePipeline &&
													canModifyEnquiry
												) {
													if (isFromLayout)
														this.setState({ showPipelineModal: true });
													else
														this.setState({
															pipelinePopOver: {
																showpopover: true,
																targetpopover: e.target,
															},
														});
												}
											}}
										>
											<Translate text={"pipeline"} />
											<span className="pipline-arrow">
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</span>

											{this.state.pipelines.find((e) => e.value === pipeline)
												? this.state.pipelines.find((e) => e.value === pipeline)
													.label
												: ""}
										</div>
									</>
								) : (
									<></>
								)}

								{rottenDays > 0 && !enquiryInfo.new ? (
									<div className="pipeline-enquiry-status ml-2">
										<div className="badge badge-pill lead-inactive">
											<Translate text={"unattended for"} upperCase={true} />{" "}
											{rottenDays} <Translate text={"days"} upperCase={true} />
										</div>
									</div>
								) : (
									<></>
								)}

								{!isAfterSales && verifiedBy && !_.isEmpty(verifiedDate) ? (
									<div className="pipeline-enquiry-status ml-2">
										<div className="badge badge-pill badge-verified">
											<i className="ico icon-check"> </i>{" "}
											<Translate text={"Verified"} />
										</div>
									</div>
								) : (
									<></>
								)}

								{!canModifyEnquiry ? (
									<div className="pipeline-enquiry-status ml-2">
										<div className="badge badge-pill badge-readonly">
											<i className="ico icon-lock mr-1"> </i>{" "}
											<Translate text={"readonly"} upperCase={true} />
										</div>
									</div>
								) : (
									<></>
								)}
								{isDeleted ? (
									<div className="pipeline-enquiry-status ml-2">
										<div className="badge badge-pill badge-readonly">
											<i className="ico icon-delete mr-1"> </i>{" "}
											<Translate text={"deleted"} upperCase={true} />
										</div>
									</div>
								) : (
									<></>
								)}

								{gdprDeleted ? (
									<div className="pipeline-enquiry-status ml-2">
										<div className="badge badge-pill badge-merged">
											<i className="ico icon-delete mr-1"> </i>{" "}
											<Translate text={"gdprDeleted"} upperCase={true} />
										</div>
									</div>
								) : (
									<></>
								)}
								{this.state.fields["mergeID"] ? (
									<div
										className="pipeline-enquiry-status ml-2"
										onClick={(e) => {
											e.preventDefault();
											Swal.fire({
												title: CommonHelper.showLocale(
													this.props,
													"Are you sure?"
												),
												text: CommonHelper.showLocale(
													this.props,
													`You want to view merged enquiry`
												),
												icon: "info",
												showCancelButton: true,
												confirmButtonText: CommonHelper.showLocale(
													this.props,
													"Yes"
												),
												cancelButtonText: CommonHelper.showLocale(
													this.props,
													"No"
												),
											}).then((result) => {
												if (result.value) {
													this.props.history.push(
														"/enquiry/details/" + this.state.fields["mergeID"]
													);
												}
											});
										}}
									>
										<div className="badge badge-pill badge-merged">
											<i className="ico icon-merge mr-1"> </i>{" "}
											<Translate text={"merged"} upperCase={true} />
										</div>
									</div>
								) : (
									<></>
								)}
							</div>

							<div className="float-right mt-1">
								<ul className="enquiry-overview">
									{responseTimeDisplay && responseTime && responseTime > 0 ? (
										<>
											<li className="overview-green">
												<Translate text={"responseTime"} />: &nbsp;
												{responseTimeDisplay}
											</li>
										</>
									) : (
										<></>
									)}

									<li className="overview-orange">
										{" "}
										<Translate text={"enquiryAge"} />:{" "}
										{addedDate
											? moment
												.unix(addedDate.seconds)
												.fromNow(true)
												.replace("a month", "1 month")
											: "--"}
									</li>
									{!_.isEmpty(stageHistory) && (<>
										<li className="overview-blue">
											<Translate text={"pipelineAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage, 'pipeline')}</span>
										</li>
										<li className="overview-red">
											<Translate text={"stageAge"} />:&nbsp;<span>{CommonHelper.showPSAge(stageHistory, pipeline, stage)}</span>
										</li>
									</>)}
									<li>
										<Translate text={"inactive"} />:{" "}
										{modifiedDate
											? moment
												.unix(modifiedDate.seconds)
												.fromNow(true)
												.replace("a month", "1 month")
											: "--"}
									</li>
								</ul>
							</div>
						</div>
						{!isEmptyPipeline ? (
							<>
								<div className="enquiry-stagewrap-stages">
									<ul className="pipeline-step">
										{this.state.defaulStages &&
											this.state.defaulStages.map((_stage, index) => {
												let _sittingDays = this.state.activeStageIndex >= index ? CommonHelper.handleSittingDays(stageHistory, pipeline, _stage) : '';
												return (
													<OverlayTrigger
														key={index}
														placement="bottom"
														overlay={<Tooltip><>{_stage.name} {_sittingDays ? <><br />{_sittingDays}</> : ''}</></Tooltip>}
													>
														<li
															key={index}
															className={
																this.state.activeStageIndex >= index
																	? this.handleStageComplete(_stage.value)
																	: ""
															}
														>
															<a
																href="#"
																id={index}
																className={
																	canModifyEnquiry &&
																		(!isDeivered ||
																			(isDeivered &&
																				modifyStageAfterDeliveredLost))
																		? ""
																		: "form-disable"
																}
																name={_stage.value}
																onClick={(e) => {
																	e.preventDefault();
																	if (
																		canModifyEnquiry &&
																		(!isDeivered ||
																			(isDeivered &&
																				modifyStageAfterDeliveredLost))
																	)
																		this.handleStageChange(index, _stage.value);
																}}
															>
																{/* <>{this.handleSittingDays(_stage)}</> */}
																{_stage.name}
															</a>
														</li>
													</OverlayTrigger>
												);
											})}
									</ul>
								</div>
							</>
						) : (
							<></>
						)}
					</div>

					<div className="clearfix"></div>

					<div className="enquirydetail-grid flex-column mt-4">
						{/** LEFT TAB */}
						<aside className="small-column mr-3 ">
							<div className="enquiry-sidebar">
								<Accordion defaultActiveKey="0">
									{/** CONTACT INFO */}
									{!_.isEmpty(modules) && modules.contact ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="0"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "0")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("0");
													}}
												>
													<div className="mb-0">
														{isContact ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "0" ? "" : "collapsed"
																	}`}
															>
																<i className="ico icon-Contacts"></i>{" "}
																<Translate text={"contactInfo"} />{" "}
																<i className="arrow-collapse"></i>
																{/* {isDLScan ? (
																		<div className="badge badge-pill badge-white-card ml-1 mt-1">
																			<img src={_images.meSent} alt="" width="14" className="mr-1" />
																			{`Digital Driving Licence`}
																		</div>
																	) : (
																		<></>
																	)} */}
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{(() => {
													if (isContact) {
														return (
															<>
																{canModifyEnquiry ? (
																	<>
																		<div
																			className={`edit-button ${isFromLayout ? "" : "right-second-icon"
																				}`}
																		>
																			<a
																				href="#"
																				className="mini-button"
																				onClick={(e) => {
																					e.preventDefault();
																					this.handleMandatoryContactOpen(
																						"editContact"
																					);
																				}}
																			>
																				<i className="ico icon-edit"></i>
																			</a>
																		</div>
																	</>
																) : (
																	<></>
																)}
																{isFromLayout ? (
																	<></>
																) : (
																	<>
																		<div className="secondary-button ">
																			<a
																				href="#"
																				className="mini-button"
																				onClick={(e) => {
																					e.preventDefault();
																					this.props.history.push(
																						"/contacts/details/" +
																						this.state.contact.documentID +
																						"?back-url=/enquiry/details/" +
																						this.state.enquiryID
																					);
																				}}
																			>
																				<i className="ico icon-expand"></i>
																			</a>
																		</div>
																	</>
																)}
															</>
														);
													}
												})()}
												<Accordion.Collapse eventKey="0">
													<Card.Body>
														{(() => {
															if (isContact) {
																return (
																	<>
																		<ContactInfo
																			contact={contact}
																			dealersettings={dealersettings}
																		></ContactInfo>
																	</>
																);
															} else {
																return (
																	<>
																		{!_.isEmpty(engageNewContact) ||
																			!_.isEmpty(engageContactName) ? (
																			<>
																				<div className="alert-table-box p-2 ">
																					<div
																						className="alert alert-danger mb-2"
																						role="alert"
																					>
																						<i
																							className="fa fa-exclamation-triangle mr-1"
																							aria-hidden="true"
																						></i>
																						<Translate
																							text={
																								"Contact details is not saved!"
																							}
																						/>
																					</div>
																					<table className="table-fill">
																						<tbody>
																							<tr>
																								<td
																									className="text-left label-text"
																									width="30%"
																								>
																									<Translate text={"name"} />
																								</td>
																								<td
																									className="text-left"
																									width="70%"
																								>
																									{engageContactName
																										? engageContactName
																										: CommonHelper.displayContact(
																											titles,
																											engageNewContact,
																											"--"
																										)}
																								</td>
																							</tr>
																							<tr>
																								<td className="text-left label-text">
																									<Translate text={"phone"} />
																								</td>
																								<td className="text-left">
																									{engageNewContact &&
																										engageNewContact.phone
																										? CommonHelper.getFullPhone(
																											engageNewContact.phoneCode,
																											engageNewContact.phone
																										)
																										: "--"}
																								</td>
																							</tr>
																							<tr>
																								<td className="text-left label-text">
																									<Translate text={"email"} />
																								</td>
																								<td className="text-left">
																									{engageNewContact &&
																										engageNewContact.email
																										? engageNewContact.email
																										: "--"}
																								</td>
																							</tr>
																						</tbody>
																					</table>
																					{
																						(canModifyEnquiry || clientID === dealersettings.client.id) && (
																							<div className="text-center pt-3 pb-2">
																								<button
																									className="btn btn-sm btn-default"
																									onClick={(e) => {
																										e.preventDefault();
																										this.handleMandatoryContactOpen("addContact", true);
																									}}
																								>
																									<i className="ico icon-add mr-1"></i>{" "}
																									<Translate text={"add"} />{" "}
																								</button>
																							</div>)
																					}

																				</div>
																			</>
																		) : (
																			<>
																				<div className="text-center p-4">
																					<p>
																						<Translate text={"nocontactata"} />
																					</p>
																					{
																						canModifyEnquiry && (<button
																							className="btn btn-sm btn-default"
																							onClick={(e) => {
																								e.preventDefault();
																								this.handleMandatoryContactOpen(
																									"addContact"
																								);
																							}}
																						>
																							<i className="ico icon-add mr-1"></i>{" "}
																							<Translate text={"add"} />{" "}
																						</button>)
																					}

																				</div>
																			</>
																		)}
																	</>
																);
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/** VEHICLE INFO */}
									{!_.isEmpty(modules) && modules.requirement ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="1"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "1")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("1");
													}}
												>
													<div className="mb-0">
														{isVehicle ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "1" ? "" : "collapsed"
																	}`}
															>
																<i
																	className={CommonHelper.getVehicleIcons(
																		dealersettings?.client?.category,
																		"ico icon-Stock"
																	)}
																></i>
																<Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, "vehicles", "requirements")} />
																{isVehicle &&
																	!_.isEmpty(this.state.requirements) ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.requirements.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>

												{accessToCreateSubLeads &&
													enableSubLeadsonWon &&
													isStatusSold &&
													canModifyEnquiry &&
													isDeivered === false &&
													soldIDs &&
													soldIDs.length > 1 ? (
													<>
														<div className="edit-button right-second-icon">
															<a
																href="#"
																onClick={(e) => {
																	e.preventDefault();
																	this.handleCheckSubLeads(soldIDs);
																}}
																className="mini-button"
															>
																<OverlayTrigger
																	placement="bottom"
																	overlay={
																		<Tooltip>
																			<Translate
																				text={"Create Sub Enquiries"}
																			/>
																		</Tooltip>
																	}
																>
																	<i className="ico icon-sub-list"> </i>
																</OverlayTrigger>
															</a>
														</div>
													</>
												) : (
													<></>
												)}

												{isDeivered === false &&
													(modifyRequirements || isStatusOpen || !status) &&
													canModifyEnquiry ? (
													<>
														<div className="add-button">
															<a
																href="#"
																onClick={(e) => {
																	e.preventDefault();
																	this.isValidForRequirement();
																}}
																className="mini-button"
															>
																<i className="ico icon-add"></i>
															</a>
														</div>
													</>
												) : (
													<></>
												)}

												<Accordion.Collapse eventKey="1">
													<Card.Body>
														{(() => {
															if (!_.isEmpty(this.state.requirements)) {
																return (
																	<>
																		{this.state.requirements.map(
																			(_requirement, index) => (
																				<Requirements
																					key={index}
																					status={status}
																					requirement={_requirement}
																					handleEdit={this.handleReqEdit}
																					setPrimary={
																						this.handleReqMarkAsPrimary
																					}
																					setSold={this.requirementSoldMark}
																					decrementQuantity={
																						this.decrementQuantity
																					}
																					incrementQuantity={
																						this.incrementQuantity
																					}
																					deleteRequirement={
																						this.deleteRequirement
																					}
																					isPrimary={
																						primaryReqID ===
																							_requirement.documentID
																							? true
																							: false
																					}
																					enquiryID={this.state.enquiryID}
																					isDeivered={
																						isDeivered === false &&
																							(modifyRequirements ||
																								isStatusOpen ||
																								!status) &&
																							canModifyEnquiry
																							? false
																							: true
																					}
																					dealersettings={dealersettings}
																					clientUsers={this.props.clientUsers}
																					groupUsers={this.props.groupUsers}
																					enquiry={this.state.fields}
																				></Requirements>
																			)
																		)}
																	</>
																);
															} else {
																return isDeivered === false &&
																	(modifyRequirements ||
																		isStatusOpen ||
																		!status) &&
																	canModifyEnquiry ? (
																	<>
																		<div className="text-center p-3">
																			<p>
																				<Translate text={"norequirementdata"} />
																			</p>
																			<button
																				className="btn btn-sm btn-default"
																				onClick={(e) => {
																					e.preventDefault();
																					this.isValidForRequirement();
																				}}
																			>
																				<i className="ico icon-add mr-1"></i>{" "}
																				<Translate text={"add"} />
																			</button>
																		</div>
																	</>
																) : (
																	<>
																		<div className="text-center p-3">
																			<p>
																				<Translate text={"norequirementdata"} />
																			</p>
																		</div>
																	</>
																);
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/** TEST DRIVE INFO */}
									{!_.isEmpty(modules) && modules.testDrive ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="2"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "2")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("2");
													}}
												>
													<div className="mb-0">
														{isTestDrive ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "2" ? "" : "collapsed"
																	}`}
															>
																<i
																	className={CommonHelper.getVehicleIcons(
																		dealersettings?.client?.category,
																		"ico icon-activity-testdrive"
																	)}
																></i>
																<Translate text={"testDrive"} />{" "}
																{isTestDrive &&
																	this.state.testdrives.length > 0 ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.testdrives.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{isDeivered === false && canModifyEnquiry ? (
													<div className="add-button">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																this.isValidForTestDrive();
															}}
															className="mini-button"
														>
															<i className="ico icon-add"></i>
														</a>
													</div>
												) : (
													<></>
												)}
												<Accordion.Collapse eventKey="2">
													<Card.Body>
														{(() => {
															if (
																isTestDrive &&
																!_.isEmpty(this.state.testdrives)
															) {
																return (
																	<>
																		{this.state.testdrives.map(
																			(_testdrive, index) => (
																				<TestDriveInfo
																					key={index}
																					testdrive={_testdrive}
																					stockData={_testdrive.stock}
																					handleEditTestDrive={
																						this.handleEditTestDrive
																					}
																					isDeivered={
																						canModifyEnquiry ? isDeivered : true
																					}
																					dealersettings={dealersettings}
																				></TestDriveInfo>
																			)
																		)}
																	</>
																);
															} else {
																return isDeivered === false &&
																	canModifyEnquiry ? (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"notestdrivedata"} />
																			</p>
																			<button
																				className="btn btn-sm btn-default"
																				onClick={(e) => {
																					e.preventDefault();
																					this.isValidForTestDrive();
																				}}
																			>
																				<i className="ico icon-add mr-1"></i>{" "}
																				<Translate text={"add"} />
																			</button>
																		</div>
																	</>
																) : (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"notestdrivedata"} />
																			</p>
																		</div>
																	</>
																);
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/** TRADE IN INFO */}
									{!_.isEmpty(modules) && modules.tradeIn ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="3"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "3")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("3");
													}}
												>
													<div className="mb-0">
														{isTradeIn ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "3" ? "" : "collapsed"
																	}`}
															>
																<i
																	className={CommonHelper.getVehicleIcons(
																		dealersettings?.client?.category,
																		"ico icon-tradein"
																	)}
																></i>
																<Translate text={"tradeIn"} />
																{isTradeIn && this.state.tradeIns.length > 0 ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.tradeIns.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{isDeivered === false && canModifyEnquiry ? (
													<>
														{evalexpertEnabled ? (
															<div className={`evalsearch-logo-button ${disableTradeInLink ? "evalsearch-logo-right" : ""}`}>
																<a
																	href="#"
																	className="btn btn-secondary"
																	onClick={(e) => {
																		e.preventDefault();
																		this.setState({ showEvalScan: true });
																	}}
																>
																	<img src={_images.evalexpertLogo} />
																</a>
															</div>
														) : (
															<></>
														)}

														{tradeinProEnabled && (
															<div className={`edit-button right-${disableTradeInLink ? 'second' : 'third'}-icon`}>
																<a
																	href="#"
																	onClick={(e) => {
																		e.preventDefault();
																		this.isValidForTradeIn(false, true);
																	}}
																	className="mini-button btn-mini-secondary"
																>
																	<OverlayTrigger
																		placement="bottom"
																		overlay={
																			<Tooltip>
																				<Translate text={"Link Appraisal"} />
																			</Tooltip>
																		}
																	>
																		<i className="fa fa-link custom-icon-preview"> </i>
																	</OverlayTrigger>
																</a>
															</div>
														)}

														{disableTradeInLink ? (
															<></>
														) : (
															<>
																<div className="edit-button right-second-icon">
																	<a
																		href="#"
																		onClick={(e) => {
																			e.preventDefault();
																			this.isValidForTradeIn(true);
																		}}
																		className="mini-button btn-mini-primary"
																	>
																		<OverlayTrigger
																			placement="bottom"
																			overlay={
																				<Tooltip>
																					<Translate text={"Send Link"} />
																				</Tooltip>
																			}
																		>
																			<i className="ico icon-email"> </i>
																		</OverlayTrigger>
																	</a>
																</div>
															</>
														)}



														<div className="secondary-button">
															<a
																href="#"
																onClick={(e) => {
																	e.preventDefault();
																	this.isValidForTradeIn();
																}}
																className="mini-button"
															>
																<i className="ico icon-add"></i>
															</a>
														</div>
													</>
												) : (
													<></>
												)}

												<Accordion.Collapse eventKey="3">
													<Card.Body>
														{(() => {
															if (!_.isEmpty(this.state.tradeIns)) {
																return (
																	<>
																		{this.state.tradeIns.map(
																			(_tradeIn, index) => (
																				<TradeInInfo
																					key={index}
																					tradein={_tradeIn}
																					handleEditTradeIn={
																						this.handleEditTradeIn
																					}
																					handleAutoGrap={this.handleAutoGrap}
																					isDeivered={
																						canModifyEnquiry ? isDeivered : true
																					}
																					tradeinPlusEnabled={
																						tradeinPlusEnabled
																					}
																					contact={this.state.contact}
																					isContact={isContact}
																					dealersettings={dealersettings}
																					clientUsers={this.props.clientUsers}
																					groupUsers={this.props.groupUsers}
																					handleContactOpen={
																						this.handleContactOpen
																					}
																					handleReSendLink={
																						this.handleReSendLink
																					}
																					showTag={true}
																				></TradeInInfo>
																			)
																		)}
																	</>
																);
															} else {
																return isDeivered === false &&
																	canModifyEnquiry ? (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"notradeindata"} />
																			</p>
																			<button
																				className="btn btn-sm btn-default"
																				onClick={(e) => {
																					e.preventDefault();
																					this.isValidForTradeIn();
																				}}
																			>
																				<i className="ico icon-add mr-1"></i>{" "}
																				<Translate text={"add"} />
																			</button>
																		</div>
																	</>
																) : (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"notradeindata"} />
																			</p>
																		</div>
																	</>
																);
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/** CAFE INFO */}
									{!_.isEmpty(modules) && modules.cafe ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="4"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "4")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("4");
													}}
												>
													<div className="mb-0">
														{isCafe ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}

														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "4" ? "" : "collapsed"
																	}`}
															>
																<i className="ico icon-cafe"></i>
																<Translate text={"cafe"} />
																{isCafe && !_.isEmpty(this.state.cafeOrders) ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.cafeOrders.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{isDeivered === false && canModifyEnquiry ? (
													<div className="add-button">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																this.setState({
																	cafeOrderModal: {
																		show: true,
																		cafeOrderID: "",
																		title: "orderCafe",
																		clsCafeOrderActive: "overlay-modal active",
																	},
																});
															}}
															className="mini-button"
														>
															<i className="ico icon-add"></i>
														</a>
													</div>
												) : (
													<></>
												)}

												<Accordion.Collapse eventKey="4">
													<Card.Body>
														{(() => {
															if (isCafe && this.state.cafeOrders.length > 0) {
																return (
																	<>
																		<CafeInfo
																			cafeOrders={this.state.cafeOrders}
																			handleOrderCancelled={
																				this.handleOrderCancelled
																			}
																			handleReOrder={this.handleReOrder}
																			dealersettings={dealersettings}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			isDeivered={
																				canModifyEnquiry ? isDeivered : true
																			}
																		></CafeInfo>
																	</>
																);
															} else {
																return isDeivered === false &&
																	canModifyEnquiry ? (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"nocafedata"} />
																			</p>
																			<button
																				className="btn btn-sm btn-default"
																				onClick={(e) => {
																					e.preventDefault();
																					this.setState({
																						cafeOrderModal: {
																							show: true,
																							cafeOrderID: "",
																							title: "orderCafe",
																							clsCafeOrderActive:
																								"overlay-modal active",
																						},
																					});
																				}}
																			>
																				<i className="ico icon-add mr-1"></i>{" "}
																				<Translate text={"add"} />
																			</button>
																		</div>
																	</>
																) : (
																	<>
																		<div className="text-center p-4">
																			<p>
																				<Translate text={"nocafedata"} />
																			</p>
																		</div>
																	</>
																);
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/* FINANCE INFO */}
									{/* Driva */}
									{!_.isEmpty(modules) && modules.finance ? (
										<>
											{drivaEnabled ? (
												<>
													<Card>
														<Accordion.Toggle
															as={Card.Header}
															eventKey="5"
															onClick={(e) => {
																e.preventDefault();

																if (activeLeftKey === "5")
																	this.handleLeftActiveKey(null);
																else this.handleLeftActiveKey("5");
															}}
															id="finance-toggle"
														>
															<div className="mb-0">
																{this.state.drivaQuotes.length > 0 ? (
																	<div className="ribbon-wrapper-featured">
																		<div className="featured"></div>
																	</div>
																) : (
																	<></>
																)}
																<div className="cardlink">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																		}}
																		className={`btn btn-link ${activeLeftKey === "5" ? "" : "collapsed"
																			}`}
																	>
																		{" "}
																		<i className="ico icon-finance"></i>{" "}
																		<Translate text={"Finance"} />{" "}
																		{this.state.drivaQuotes.length > 0 ? (
																			<span className="badge badge-pill badge-cafe ml-1">
																				{this.state.drivaQuotes.length}
																			</span>
																		) : (
																			<></>
																		)}
																		<i className="arrow-collapse"></i>
																	</a>
																</div>
															</div>
														</Accordion.Toggle>
														{isDeivered === false && canModifyEnquiry ? (
															<>
																<div className="edit-button">
																	<a
																		className="mini-button"
																		onClick={(e) => {
																			e.preventDefault();
																			this.handleAddDrivaFinanceOpen();
																		}}
																	>
																		<i className={`ico icon-${"add"}`}></i>
																	</a>
																</div>
															</>
														) : (
															<></>
														)}
														<Accordion.Collapse
															eventKey="5"
															id="finance-collapse"
														>
															<Card.Body>
																{this.state.drivaQuotes.length > 0 ? (
																	this.state.drivaQuotes.map(
																		(financeDetail) => (
																			<DrivaInfo
																				financeDetail={financeDetail}
																				key={financeDetail.documentID}
																				handleViewFinanceOpen={
																					this.handleViewDrivaFinanceOpen
																				}
																				handleEditFinanceOpen={
																					this.handleEditDrivaFinanceOpen
																				}
																				isDeivered={
																					canModifyEnquiry ? isDeivered : true
																				}
																				currencySymbol={currencySymbol}
																			/>
																		)
																	)
																) : (
																	<>
																		<div className="text-center p-3">
																			<p>
																				<Translate text={"nofinancedata"} />
																			</p>
																			{isDeivered === false &&
																				canModifyEnquiry ? (
																				<>
																					<button
																						className="btn btn-sm btn-default"
																						onClick={(e) => {
																							e.preventDefault();
																							this.handleAddDrivaFinanceOpen();
																						}}
																					>
																						<i
																							className={`ico icon-${"add"} mr-1`}
																						></i>{" "}
																						<Translate text={"add"} />
																					</button>
																				</>
																			) : (
																				<></>
																			)}
																		</div>
																	</>
																)}
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</>
											) : financeEnabled ? (
												<>
													<Card>
														<Accordion.Toggle
															as={Card.Header}
															eventKey="5"
															onClick={(e) => {
																e.preventDefault();

																if (activeLeftKey === "5")
																	this.handleLeftActiveKey(null);
																else this.handleLeftActiveKey("5");
															}}
															id="finance-toggle"
														>
															<div className="mb-0">
																{isFinance ? (
																	<div className="ribbon-wrapper-featured">
																		<div className="featured"></div>
																	</div>
																) : (
																	<></>
																)}
																<div className="cardlink">
																	<a
																		onClick={(e) => {
																			e.preventDefault();
																		}}
																		className={`btn btn-link ${activeLeftKey === "5" ? "" : "collapsed"
																			}`}
																	>
																		{" "}
																		<i className="ico icon-finance"></i>{" "}
																		<Translate text={"Finance"} />{" "}
																		{isFinance &&
																			!_.isEmpty(this.state.financeDetails) ? (
																			<span className="badge badge-pill badge-cafe ml-1">
																				{
																					this.state.financeDetails.Requirement
																						.length
																				}
																			</span>
																		) : (
																			<></>
																		)}
																		<i className="arrow-collapse"></i>
																	</a>
																</div>
															</div>
														</Accordion.Toggle>
														{isDeivered === false && canModifyEnquiry ? (
															<>
																<div className="edit-button">
																	<a
																		className="mini-button"
																		onClick={(e) => {
																			e.preventDefault();
																			if (isFinance)
																				this.handleEditFinanceOpen();
																			else this.handleAddFinanceOpen();
																		}}
																	>
																		<i
																			className={`ico icon-${isFinance ? "edit" : "add"
																				}`}
																		></i>
																	</a>
																</div>
															</>
														) : (
															<></>
														)}
														<Accordion.Collapse
															eventKey="5"
															id="finance-collapse"
														>
															<Card.Body>
																{isFinance &&
																	!_.isEmpty(this.state.financeDetails) &&
																	!_.isEmpty(
																		this.state.financeDetails.Requirement
																	) ? (
																	this.state.financeDetails.Requirement.map(
																		(financeDetail) => (
																			<FinanceInfo
																				financeDetail={financeDetail}
																				key={financeDetail.AMSProId}
																				handleViewFinanceOpen={
																					this.handleViewFinanceOpen
																				}
																				handleViewFinanceClose={
																					this.handleViewFinanceClose
																				}
																				handleEditFinanceOpen={
																					this.handleEditFinanceOpen
																				}
																				isDeivered={
																					canModifyEnquiry ? isDeivered : true
																				}
																				currencySymbol={currencySymbol}
																				handleAddProviderOpen={
																					this.handleAddProviderOpen
																				}
																			/>
																		)
																	)
																) : (
																	<>
																		<div className="text-center p-3">
																			<p>
																				<Translate text={"nofinancedata"} />
																			</p>
																			{isDeivered === false &&
																				canModifyEnquiry ? (
																				<>
																					<button
																						className="btn btn-sm btn-default"
																						onClick={(e) => {
																							e.preventDefault();
																							if (isFinance)
																								this.handleEditFinanceOpen();
																							else this.handleAddFinanceOpen();
																						}}
																					>
																						<i
																							className={`ico icon-${isFinance ? "edit" : "add"
																								} mr-1`}
																						></i>{" "}
																						<Translate
																							text={isFinance ? "edit" : "add"}
																						/>
																					</button>
																				</>
																			) : (
																				<></>
																			)}
																		</div>
																	</>
																)}
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</>
											) : (
												<></>
											)}
										</>
									) : (
										<></>
									)}

									{/* PRODUCT ADD-ONS INFO */}
									{
										(accessToProduct) ? (<Card>
											<Accordion.Toggle
												as={Card.Header}
												eventKey="7"
												onClick={(e) => {
													e.preventDefault();

													if (activeLeftKey === "7")
														this.handleLeftActiveKey(null);
													else this.handleLeftActiveKey("3");
												}}
											>
												<div className="mb-0">
													{isProduct ? (
														<div className="ribbon-wrapper-featured">
															<div className="featured"></div>
														</div>
													) : (
														<></>
													)}
													<div className="cardlink">
														<a
															onClick={(e) => {
																e.preventDefault();
															}}
															className={`btn btn-link ${activeLeftKey === "7" ? "" : "collapsed"
																}`}
														>
															<i
																className={"ico icon-service-package"}></i>
															<Translate text={"Product Add-Ons"} />
															{this.state.productAddOns.length > 0 ? (
																<span className="badge badge-pill badge-cafe ml-1">
																	{this.state.productAddOns.length}
																</span>
															) : (
																<></>
															)}
															<i className="arrow-collapse"></i>
														</a>
													</div>
												</div>
											</Accordion.Toggle>
											{accessToAddProduct ? (
												<>
													<div className="secondary-button">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																this.handleAddOnsOpen();
															}}
															className="mini-button"
														>
															<i className="ico icon-add"></i>
														</a>
													</div>
												</>
											) : (
												<></>
											)}

											<Accordion.Collapse eventKey="7">
												<Card.Body>
													{(() => {
														if (!_.isEmpty(this.state.productAddOns)) {
															return (
																<>
																	{this.state.productAddOns.map(
																		(_info, index) => (
																			<ProductInfo
																				key={index}
																				product={_info}
																				enquiryID={this.state.enquiryID}
																				dealersettings={dealersettings}
																				clientUsers={this.props.clientUsers}
																				groupUsers={this.props.groupUsers}
																				handleAddOnsOpen={this.handleAddOnsOpen}
																			></ProductInfo>
																		)
																	)}
																</>
															);
														} else {
															return accessToAddProduct ? (
																<>
																	<div className="text-center p-4">
																		<p>
																			<Translate text={"nodata"} />
																		</p>
																		<button
																			className="btn btn-sm btn-default"
																			onClick={(e) => {
																				e.preventDefault();
																				this.handleAddOnsOpen();
																			}}
																		>
																			<i className="ico icon-add mr-1"></i>{" "}
																			<Translate text={"add"} />
																		</button>
																	</div>
																</>
															) : (
																<>
																	<div className="text-center p-4">
																		<p>
																			<Translate text={"nodata"} />
																		</p>
																	</div>
																</>
															);
														}
													})()}
												</Card.Body>
											</Accordion.Collapse>
										</Card>
										) : (
											<></>
										)}

									{/** FILES INFO */}
									{!_.isEmpty(modules) && modules.files ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="02"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "02")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("02");
													}}
												>
													<div className="mb-0">
														{isFiles ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "02" ? "" : "collapsed"
																	}`}
															>
																<i className="ico icon-files"></i>{" "}
																<Translate text={"Files"} />{" "}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{canModifyEnquiry ? (
													<div className="add-button">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																this.isValidForFile();
															}}
															className="mini-button"
														>
															<i className="ico icon-add"></i>
														</a>
													</div>
												) : (
													<></>
												)}
												<Accordion.Collapse eventKey="02">
													<Card.Body>
														<FilesCategory
															enquiryID={this.state.enquiryID}
															contactID={this.state.contact?.documentID}
															contact={this.state.contact}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															isDeivered={canModifyEnquiry ? isDeivered : true}
															showAddOpen={showAddFile}
															handleFileClose={this.handleFileClose}
															filesCategory={filesCategory}
														//showAdd={true}
														></FilesCategory>
														{/* <div className="text-center p-3">
                                                        <p></p>
                                                        <button className="btn btn-sm btn-default"><i className="ico icon-add mr-1"></i> Add </button>
                                                    </div> */}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}
									{/** VIDEO INFO */}
									{!_.isEmpty(modules) &&
										modules.amsVideo &&
										amsVideoEnabled ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="03"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "03")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("03");
													}}
												>
													<div className="mb-0">
														{isVideo ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "03" ? "" : "collapsed"
																	}`}
															>
																<i className="ico icon-video"></i>{" "}
																<Translate text={"Videos"} />{" "}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{isDeivered === false && canModifyEnquiry ? (
													<div className="add-button">
														<a
															href="#"
															onClick={(e) => {
																e.preventDefault();
																this.isValidForVideo();
															}}
															className="mini-button"
														>
															<i className="ico icon-add"></i>
														</a>
													</div>
												) : (
													<></>
												)}
												<Accordion.Collapse eventKey="03">
													<Card.Body>
														<Videos
															enquiryID={this.state.enquiryID}
															customerEmail={this.state.contact.email}
															customerPhone={this.state.contact.phone}
															customerPhoneCode={this.state.contact.phoneCode}
															dealersettings={dealersettings}
															clientUsers={this.props.clientUsers}
															groupUsers={this.props.groupUsers}
															isDeivered={canModifyEnquiry ? isDeivered : true}
														></Videos>
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/* QUOTATION INFO */}
									{isQuotationEnabled ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="6"
													onClick={(e) => {
														e.preventDefault();

														if (activeLeftKey === "6")
															this.handleLeftActiveKey(null);
														else this.handleLeftActiveKey("6");
													}}
													id="quotation-toggle"
												>
													<div className="mb-0">
														{isQuotation && this.state.quotations.length ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeLeftKey === "6" ? "" : "collapsed"
																	}`}
															>
																{" "}
																<i className="ico icon-contract-fill"></i>{" "}
																<Translate text={"Quotation"} />{" "}
																{isQuotation &&
																	!_.isEmpty(this.state.quotations) ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.quotations.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>
												{isDeivered === false &&
													canModifyEnquiry &&
													addQuotation ? (
													<>
														<div className="edit-button">
															<a
																className="mini-button"
																onClick={(e) => {
																	e.preventDefault();
																	this.handleAddQuotation(this.state.enquiryID);
																}}
															>
																<i className={`ico icon-add`}></i>
															</a>
														</div>
													</>
												) : (
													<></>
												)}
												<Accordion.Collapse eventKey="6" id="finance-collapse">
													<Card.Body>
														{isQuotation &&
															!_.isEmpty(this.state.quotations) ? (
															this.state.quotations.map((quotation) => (
																<QuotationInfo
																	key={quotation.documentID}
																	quotation={quotation}
																	enquiryID={this.state.enquiryID}
																	gdprDeleted={gdprDeleted}
																	isDeivered={
																		canModifyEnquiry ? isDeivered : true
																	}
																	currencySymbol={currencySymbol}
																	handleEditQuotation={this.handleEditQuotation}
																	handlePreviewQuotation={
																		this.handlePreviewQuotation
																	}
																	handleDeleteQuotation={
																		this.handleDeleteQuotation
																	}
																	addQuotation={addQuotation}
																	deleteQuotation={deleteQuotation}
																	clientUsers={this.props.clientUsers}
																	enquiryStatus={status}
																	dealersettings={dealersettings}
																	enquiry={this.state.fields}
																/>
															))
														) : (
															<>
																<div className="text-center p-3">
																	<p>
																		<Translate text={"No quotations found"} />
																	</p>
																	{addQuotation &&
																		isDeivered === false &&
																		canModifyEnquiry ? (
																		<>
																			<button
																				className="btn btn-sm btn-default"
																				onClick={(e) => {
																					e.preventDefault();
																					this.handleAddQuotation(
																						this.state.enquiryID
																					);
																				}}
																			>
																				<i
																					className={`ico icon-${"add"} mr-1`}
																				></i>{" "}
																				<Translate text={"add"} />
																			</button>
																		</>
																	) : (
																		<></>
																	)}
																</div>
															</>
														)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/* EVENT INFO */}
									{eventsProEnabled ? (
										<EventsProInfo
											{...this.props}
											cardKey="11"
											activeKey={activeRightKey}
											handleActiveKey={this.handleRightActiveKey}
											enquiryID={this.state.enquiryID}
										></EventsProInfo>
									) : (
										<></>
									)}

									{fusioneventsEnabled ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="05"
													onClick={(e) => {
														e.preventDefault();

														if (activeRightKey === "05")
															this.handleRightActiveKey(null);
														else this.handleRightActiveKey("05");
													}}
												>
													<div className="mb-0">
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeRightKey === "05" ? "" : "collapsed"
																	}`}
															>
																<i className="ico icon-mail-starred"></i>
																<Translate text={"Events"} />
																{this.state.events.length > 0 ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.events.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>

												<Accordion.Collapse eventKey="05">
													<Card.Body>
														{this.state.events &&
															this.state.events.length > 0 ? (
															<>
																{this.state.events.map((_event, index) => (
																	<EventInfo
																		key={index}
																		event={_event}
																		clientUsers={this.props.clientUsers}
																		groupUsers={this.props.groupUsers}
																		sidepanelOpen={this.sidepanelOpen}
																		history={this.props.history}
																		showMore={false}
																		dealersettings={dealersettings}
																		handleEventOpen={this.handleEventOpen}
																	></EventInfo>
																))}
															</>
														) : (
															<>
																<div className="text-center p-3">
																	<p>
																		<Translate text={"No Event Available"} />
																	</p>
																</div>
															</>
														)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="10"
											onClick={(e) => {
												e.preventDefault();

												if (activeRightKey === "10")
													this.handleRightActiveKey(null);
												else this.handleRightActiveKey("10");
											}}
										>
											<div className="mb-0">
												<div className="cardlink">
													<a
														onClick={(e) => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === "10" ? "" : "collapsed"
															}`}
													>
														<i className="ico icon-feedback"></i>
														<Translate text={"Feedback"} />
														{this.state.formFeedback &&
															this.state.formFeedback.length > 0 ? (
															<span className="badge badge-pill badge-cafe ml-1">
																{this.state.formFeedback.length}
															</span>
														) : (
															<></>
														)}
														<i className="arrow-collapse"></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>

										<Accordion.Collapse eventKey="10">
											<Card.Body>
												{this.state.formFeedback &&
													this.state.formFeedback.length > 0 ? (
													<>
														{this.state.formFeedback.map((_feedback, index) => (
															<FormFeedbackInfo
																key={index}
																formFeedback={_feedback}
																clientUsers={this.props.clientUsers}
																dealersettings={dealersettings}
															></FormFeedbackInfo>
														))}
													</>
												) : (
													<>
														<div className="text-center p-3">
															<p>
																<Translate text={"No Feedback Available"} />
															</p>
														</div>
													</>
												)}
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									{/* Users Rating INFO */}
									{this.state.usersRating.length > 0 ? (
										<>
											<Card>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="09"
													onClick={(e) => {
														e.preventDefault();

														if (activeRightKey === "09")
															this.handleRightActiveKey(null);
														else this.handleRightActiveKey("09");
													}}
												>
													<div className="mb-0">
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeRightKey === "09" ? "" : "collapsed"
																	}`}
															>
																{/* <i className="ico icon-mail-starred"></i> */}
																<Translate text={"Feedback"} />
																{this.state.usersRating.length > 0 ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{this.state.usersRating.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>

												<Accordion.Collapse eventKey="09">
													<Card.Body>
														{this.state.usersRating &&
															this.state.usersRating.length > 0 ? (
															<>
																{this.state.usersRating.map(
																	(_userRating, index) => (
																		<UserRatingInfo
																			key={index}
																			userRating={_userRating}
																			clientUsers={this.props.clientUsers}
																			groupUsers={this.props.groupUsers}
																			sidepanelOpen={this.sidepanelOpen}
																			history={this.props.history}
																			showMore={false}
																			dealersettings={dealersettings}
																			handleEventOpen={this.handleEventOpen}
																			enquiryID={this.state.enquiryID}
																		></UserRatingInfo>
																	)
																)}
															</>
														) : (
															<>
																<div className="text-center p-3">
																	<p>
																		<Translate text={"No Feedback Available"} />
																	</p>
																</div>
															</>
														)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}
								</Accordion>
							</div>
						</aside>

						{/** MIDDLE TAB */}
						<div className="mid-column">
							{(accessToFinanceTab || accessToAfterMarketTab) ? (
								<>
									<div className="mid-column-tabber-wrap">
										<div className="nav nav-tabs nav-fill">
											<a
												href="#"
												className={`nav-item nav-link ${activeTab === "sales" ? "active" : ""
													}`}
												onClick={(e) => {
													e.preventDefault();
													this.setState({ activeTab: "sales" });
												}}
											>
												<Translate text={"sales"} />{" "}
											</a>
											{
												accessToFinanceTab && (<a
													href="#"
													className={`nav-item nav-link ${activeTab === "finance" ? "active" : ""
														}`}
													onClick={(e) => {
														e.preventDefault();
														this.setState({ activeTab: "finance" });
													}}
												>
													{" "}
													<Translate text={"finance"} />{" "}
												</a>)
											}

											{
												accessToAfterMarketTab && (<a
													href="#"
													className={`nav-item nav-link ${activeTab === "afterMarket" ? "active" : ""
														}`}
													onClick={(e) => {
														e.preventDefault();
														this.setState({ activeTab: "afterMarket" });
													}}
												>
													{" "}
													<Translate text={"afterMarket"} />{" "}
												</a>)
											}

										</div>
									</div>
								</>
							) : (
								<></>
							)}

							{activeTab === "finance" && (
								<>
									{/** ACTIVITY TAB FINANCE*/}

									<div className="mid-column-finance-info">

										{
											accessToBusinessManager ?
												<>
													<div className="mid-column-finance-data form-style">
														{/* <h2>Finance Details</h2> */}
														<div className="form-row">
															<div className="form-group col-md-4">
																<label><Translate text={"Business Manager"} /> </label>
																<ReactSelect
																	options={allFinanceUsers}
																	name={"financeBM"}
																	placeholder={"select business manager"}
																	onChange={this.handleSelectSave}
																	value={financeBM ? financeBM : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={true}
																	isSearchable={true}
																></ReactSelect>
															</div>
															<div className="form-group col-md-4">
																<label><Translate text={"financeStatus"} /> </label>
																<ReactSelect
																	options={
																		financeEnabled ? __financestatus : financeStatuses
																	}
																	name={"financeStatus"}
																	placeholder={"select finance status"}
																	onChange={this.handleSelectSave}
																	value={financeStatus ? financeStatus : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={true}
																	isSearchable={false}
																></ReactSelect>
															</div>
															<div className="form-group col-md-4">
																<label><Translate text={"financeProvider"} /> </label>
																<ReactSelect
																	options={_.map(financeProviders, function (e) { return { ...e, label: e.name } })}
																	name={"financeProvider"}
																	placeholder={"select finance provider"}
																	onChange={this.handleSelectSave}
																	value={financeProvider ? financeProvider : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={false}
																	isSearchable={true}
																></ReactSelect>
															</div>

															<div className="form-group col-md-2">
																<label><Translate text={"startDate"} /> </label>
																<AntDatePicker
																	value={financeStartDate ? moment.unix(financeStartDate.seconds).format('YYYY-MM-DD') : null}
																	name={'financeStartDate'}
																	onChange={(e) => { this.handleDeliveryDateChange(e, 'financeStartDate') }}
																	format='DD/MM/YYYY'
																	placeholder='DD/MM/YYYY'
																	className={`form-control`}
																//disableContainer={true}
																/>
															</div>

															<div className="form-group col-md-2">
																<label><Translate text={"financeTerms"} /> </label>
																<ReactSelect
																	options={defaultFinanceTerms}
																	name={"financeTerms"}
																	placeholder={"finance terms"}
																	onChange={this.handleSelectSave}
																	value={financeTerms ? financeTerms : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={false}
																	isSearchable={false}
																></ReactSelect>
															</div>
															<div className="form-group col-md-4">
																<label><Translate text={"financeAmount"} /> </label>
																<InputAutoNumber
																	id="financeAmount_detail"
																	name="financeAmount"
																	allowNegative={false}
																	thousandSeparator={true}
																	allowLeadingZeros={false}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`form-control cursor-pointer`}
																	onValueChange={this.onCurrencyChange.bind(this, 'financeAmount')}
																	value={financeAmount ? financeAmount : null}
																	onSaveField={this.saveEnquiryField}
																	onCancelField={this.cancelEnquiryField}
																/>
															</div>
															<div className="form-group col-md-4">
																<label><Translate text={"financeRate"} /> </label>
																<InputAutoNumber
																	id="financeRate_detail"
																	name="financeRate"
																	allowNegative={false}
																	thousandSeparator={true}
																	allowLeadingZeros={false}
																	suffix={'%'}
																	placeholder={'finance interest rate'}
																	className={`form-control cursor-pointer`}
																	onValueChange={this.onCurrencyChange.bind(this, 'financeRate')}
																	value={financeRate ? financeRate : null}
																	onSaveField={this.saveEnquiryField}
																	onCancelField={this.cancelEnquiryField}
																/>
															</div>
														</div>
													</div>


												</>
												:
												<>
													<div className="mid-column-finance-infowrap">
														<div className="mid-column-finance-subwrap">
															<div className="mid-column-finance-infohead">
																<Translate text={"Business Manager"} />
															</div>

															<div className={`filter-item-btn filter-owner-btn ml-2`}>
																<ReactSelect
																	options={allFinanceUsers}
																	name={"financeBM"}
																	placeholder={"select business manager"}
																	onChange={this.handleSelectSave}
																	value={financeBM ? financeBM : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={true}
																	isSearchable={true}
																></ReactSelect>
															</div>
														</div>
														<div className="mid-column-finance-subwrap ml-4">
															<div className="mid-column-finance-infohead">
																<Translate text={"financeStatus"} />
															</div>

															<div className={`filter-item-btn filter-owner-btn ml-2`}>
																<ReactSelect
																	options={
																		financeEnabled ? __financestatus : financeStatuses
																	}
																	name={"financeStatus"}
																	placeholder={"select finance status"}
																	onChange={this.handleSelectSave}
																	value={financeStatus ? financeStatus : null}
																	classNamePrefix={`cursor-pointer basic-select`}
																	removeClearable={true}
																	isSearchable={false}
																></ReactSelect>
															</div>
														</div>
													</div>
												</>
										}

										{
											(filesCategory.filter(e => e.module === activeTab)[0]) && (
												<div className="mid-column-finance-documents">
													<h2><Translate text={"Documents"} /></h2>
													<div className="mid-column-finance-documents-inner">
														<FileDocuments
															filesCategory={filesCategory}
															activeTab={activeTab}
															handleFileOpen={this.handleFileOpen}
															files={files}
														/>
													</div>
												</div>
											)
										}
										{
											accessToViewFinanceCreditScore && (<CreditScore
												accessToRequestFinanceCreditScore={accessToRequestFinanceCreditScore}
												contact={contact}
												handleRequestCreditScore={this.isValidForRequestCreditScore}
												loaderCreditScore={loaderCreditScore}
												dealersettings={dealersettings}
												clientUsers={allDealerUsers}
											>
											</CreditScore>)}

									</div>

									{accessToFinanceActivity ? (
										<>
											<ActivityFinance
												enquiry={this.state.fields}
												enquiryBasicVM={this.state.enquiryBasicVM}
												clientUsers={this.props.clientUsers}
												groupUsers={this.props.groupUsers}
												dealersettings={dealersettings}
												isNewEnquiry={enquiryInfo.new}
												history={this.props.history}
												isValidForActivity={this.isValidForStageUpdate}
												isFromLayout={true}
												dialNumber={this.props.dialNumber}
											></ActivityFinance>
										</>
									) : (
										<></>
									)}
								</>
							)}

							{activeTab === "afterMarket" && (
								<>
									{/** ACTIVITY TAB AFTER MARKET */}

									<div className="mid-column-finance-info">
										<div className="mid-column-finance-infowrap">
											<div className="mid-column-finance-subwrap">
												<div className="mid-column-finance-infohead">
													<Translate text={"afterMarketBM"} />
												</div>

												<div className={`filter-item-btn filter-owner-btn ml-2`}>
													<ReactSelect
														options={allAfterMarketUsers}
														name={"afterMarketBM"}
														placeholder={"select aftermarket manager"}
														onChange={this.handleSelectSave}
														value={afterMarketBM ? afterMarketBM : null}
														classNamePrefix={`cursor-pointer basic-select`}
														removeClearable={true}
														isSearchable={true}
													></ReactSelect>
												</div>
											</div>
											<div className="mid-column-finance-subwrap ml-4">
												<div className="mid-column-finance-infohead">
													<Translate text={"afterMarketStatus"} />
												</div>

												<div className={`filter-item-btn filter-owner-btn ml-2`}>
													<ReactSelect
														options={afterMarketStatuses}
														name={"afterMarketStatus"}
														placeholder={"select aftermarket status"}
														onChange={this.handleSelectSave}
														value={afterMarketStatus ? afterMarketStatus : null}
														classNamePrefix={`cursor-pointer basic-select`}
														removeClearable={true}
														isSearchable={false}
													></ReactSelect>
												</div>
											</div>
										</div>
										{
											(filesCategory.filter(e => e.module === activeTab)[0]) && (
												<div className="mid-column-finance-documents">
													<h2><Translate text={"Documents"} /></h2>
													<div className="mid-column-finance-documents-inner">
														<FileDocuments
															filesCategory={filesCategory}
															activeTab={activeTab}
															handleFileOpen={this.handleFileOpen}
															files={files}
														/>
													</div>
												</div>
											)
										}
									</div>

									{accessToAfterMarketActivity ? (
										<>
											<ActivityDynamic
												originType={'afterMarket'}
												enquiry={this.state.fields}
												enquiryBasicVM={this.state.enquiryBasicVM}
												clientUsers={this.props.clientUsers}
												groupUsers={this.props.groupUsers}
												dealersettings={dealersettings}
												isNewEnquiry={enquiryInfo.new}
												history={this.props.history}
												isValidForActivity={this.isValidForStageUpdate}
												isFromLayout={true}
												dialNumber={this.props.dialNumber}
											></ActivityDynamic>
										</>
									) : (
										<></>
									)}
								</>
							)}

							{activeTab === "sales" && (
								<>
									{/** ACTIVITY TAB */}
									{hideSalesDoc ? <></> : <div className="mid-column-finance-info">
										{
											(filesCategory.filter(e => e.module === activeTab)[0]) && (
												<div className="mid-column-sales-documents">
													<h2><Translate text={"Documents"} /></h2>
													<div className="mid-column-finance-documents-inner">
														<FileDocuments
															filesCategory={filesCategory}
															activeTab={activeTab}
															handleFileOpen={this.handleFileOpen}
															files={files}
														/>
													</div>
												</div>
											)
										}
									</div>}
									{
										['enquiry'].includes(localStorage.defaultModule)
											?
											<Activity
												enquiry={this.state.fields}
												enquiryBasicVM={this.state.enquiryBasicVM}
												clientUsers={this.props.clientUsers}
												groupUsers={this.props.groupUsers}
												dealersettings={dealersettings}
												isNewEnquiry={enquiryInfo.new}
												history={this.props.history}
												isValidForActivity={this.isValidForStageUpdate}
												isFromLayout={true}
												isFinanceLayout={true}
												dialNumber={this.props.dialNumber}
											></Activity>
											:
											<ActivityDynamic
												originType={'enquiry'}
												enquiry={this.state.fields}
												enquiryBasicVM={this.state.enquiryBasicVM}
												clientUsers={this.props.clientUsers}
												groupUsers={this.props.groupUsers}
												dealersettings={dealersettings}
												isNewEnquiry={enquiryInfo.new}
												history={this.props.history}
												isValidForActivity={this.isValidForStageUpdate}
												isFromLayout={true}
												dialNumber={this.props.dialNumber}
											></ActivityDynamic>

									}


								</>
							)}

						</div>

						{/** RIGHT TAB */}
						<aside className="small-column ml-3">
							<div className="enquiry-sidebar">
								<Accordion defaultActiveKey="00">
									{/** ENQUIRY INFO */}
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="00"
											onClick={(e) => {
												e.preventDefault();

												if (activeRightKey === "00")
													this.handleRightActiveKey(null);
												else this.handleRightActiveKey("00");
											}}
										>
											<div className="mb-0">
												<div className="cardlink">
													<a
														onClick={(e) => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === "00" ? "" : "collapsed"
															}`}
													>
														<Translate text={"enquiryInfo"} />{" "}
														<i className="arrow-collapse"></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="00">
											<Card.Body>
												<div
													className={`info-table ${canModifyEnquiry ? "" : "form-disable"
														}`}
												>
													<table className="table-add add-form-style">
														<tbody>
															{(financeBM || accessToFinanceTab) &&
																!_.isEmpty(allFinanceUsers) ? (
																<tr>
																	<td
																		className="text-left label-text"
																		width="40%"
																	>
																		<Translate text={"financeBM"} />
																	</td>
																	<td className="text-left" width="60%">
																		<ReactSelect
																			options={allFinanceUsers}
																			name={"financeBM"}
																			placeholder={"select business manager"}
																			onChange={this.handleSelectSave}
																			value={financeBM}
																			classNamePrefix={`cursor-pointer basic-select`}
																			removeClearable={true}
																			isSearchable={true}
																			isDisabled={
																				accessToFinanceTab ? false : true
																			}
																		></ReactSelect>
																	</td>
																</tr>
															) : (
																<></>
															)}

															{(afterMarketBM || accessToAfterMarketTab) &&
																!_.isEmpty(allAfterMarketUsers) ? (
																<tr>
																	<td
																		className="text-left label-text"
																		width="40%"
																	>
																		<Translate text={"afterMarketBM"} />
																	</td>
																	<td className="text-left" width="60%">
																		<ReactSelect
																			options={allAfterMarketUsers}
																			name={"afterMarketBM"}
																			placeholder={"select aftermarket manager"}
																			onChange={this.handleSelectSave}
																			value={afterMarketBM}
																			classNamePrefix={`cursor-pointer basic-select`}
																			removeClearable={true}
																			isSearchable={true}
																			isDisabled={
																				accessToAfterMarketTab ? false : true
																			}
																		></ReactSelect>
																	</td>
																</tr>
															) : (
																<></>
															)}

															{dynamicEnquiryDetailList &&
																dynamicEnquiryDetailList.map((info, index) => (
																	<EnquiryInputs
																		key={index}
																		type={info}
																		fields={this.state.fields}
																		errors={this.state.errors}
																		currencySymbol={currencySymbol}
																		dealersettings={dealersettings}
																		canModifyEnquiry={canModifyEnquiry}
																		enquiryTypes={enquiryTypes}
																		enquiryAllStatus={enquiryAllStatus}
																		serviceTypes={serviceTypes}
																		leadSources={leadSources}
																		origins={this.state.origins}
																		campaigns={campaigns}
																		labels={labels}
																		lostReasons={lostReasons}
																		lostSubReasons={lostSubReasons}
																		financeStatuses={financeStatuses}
																		__financestatus={__financestatus}
																		enquiryOptionsDF={enquiryOptionsDF}
																		enquiryOptionsStatic={enquiryOptionsStatic}
																		financeEnabled={financeEnabled}
																		drivaEnabled={drivaEnabled}
																		handleSelectSave={this.handleSelectSave}
																		handlePIDateSave={this.handlePIDateSave}
																		isPIValid={this.isPIValid}
																		handleDeliveryDateChange={
																			this.handleDeliveryDateChange
																		}
																		handleTimeChange={this.handleTimeChange}
																		handleOnCapChange={this.handleOnCapChange}
																		saveEnquiryField={this.saveEnquiryField}
																		cancelEnquiryField={this.cancelEnquiryField}
																		handleDateChange={this.handleDateChange}
																		handleLostReasonSave={
																			this.handleLostReasonSave
																		}
																		handleCheckChange={this.handleCheckChange}
																		onCurrencyChange={this.onCurrencyChange}
																		handleOnChange={this.handleOnChange}
																		handleDynamicCheckChange={
																			this.handleDynamicCheckChange
																		}
																		handleDynamicOnChange={
																			this.handleDynamicOnChange
																		}
																		saveDynamicField={this.saveDynamicField}
																		cancelDynamicField={this.cancelDynamicField}
																		onDynamicNumberChange={
																			this.onDynamicNumberChange
																		}
																		handleDynamicDateChange={
																			this.handleDynamicDateChange
																		}
																		handleDynamicSelectSave={
																			this.handleDynamicSelectSave
																		}
																		handleDynamicMultiSelectChange={
																			this.handleDynamicMultiSelectChange
																		}
																	></EnquiryInputs>
																))}
														</tbody>
													</table>
												</div>
												<div className="enquiry-detailed-info">
													{ismodifyStatusDate || isModifyInboundOwner ? (
														<>
															<div className="edit-enquiry-detailes-btn">
																<a
																	href="#"
																	className="mini-button"
																	onClick={(e) => {
																		e.preventDefault();
																		this.setState({
																			enquiryModal: {
																				show: true,
																				errors: {},
																				newStatus: null,
																				newStatusValue: null,
																				ismodifyStatusDate: ismodifyStatusDate,
																				isModifyInboundOwner:
																					isModifyInboundOwner,
																				mandatoryFields: null,
																				financeRequired: false,
																			},
																		});
																	}}
																>
																	<i className="ico icon-edit"></i>
																</a>
															</div>
														</>
													) : (
														<></>
													)}
													<ul>
														<li>
															{" "}
															<span>
																<Translate text={"modifiedBy"} />:
															</span>{" "}
															{CommonHelper.getUserNamebyId(
																allDealerUsers,
																modifiedBy
															)}
														</li>
														<li>
															{" "}
															<span>
																<Translate text={"modifiedDate"} />:
															</span>{" "}
															{modifiedDate
																? moment
																	.unix(modifiedDate.seconds)
																	.format("DD MMM YYYY h:mm A")
																: "--"}
														</li>
														{!_.isEmpty(modifiedFrom) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"modifiedFrom"} />:
																	</span>{" "}
																	{modifiedFrom}
																</li>
															</>
														) : (
															<></>
														)}
														{(status === enquiryStatus.LOST ||
															status === enquiryStatus.PROSPECTLOST) &&
															!_.isEmpty(lostDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate
																			text={
																				CommonHelper.getNameByValue(
																					enquiryAllStatus,
																					"lost",
																					"Lost"
																				) + " Date"
																			}
																		/>
																		:
																	</span>{" "}
																	{lostDate
																		? moment
																			.unix(lostDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}
														{(isStatusSold ||
															status === enquiryStatus.WON ||
															status === enquiryStatus.CLOSED ||
															status === enquiryStatus.DELIEVERD) &&
															!_.isEmpty(wonDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate
																			text={
																				isAfterSales
																					? "closeWonDate"
																					: CommonHelper.getNameByValue(
																						enquiryAllStatus,
																						"won",
																						"Won"
																					) + " Date"
																			}
																		/>
																		:
																	</span>{" "}
																	{wonDate
																		? moment
																			.unix(wonDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}
														{status === enquiryStatus.DELIEVERD &&
															!_.isEmpty(deliveredDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate
																			text={
																				CommonHelper.getNameByValue(
																					enquiryAllStatus,
																					"delivered",
																					"Delivered"
																				) + " Date"
																			}
																		/>
																		:
																	</span>{" "}
																	{deliveredDate
																		? moment
																			.unix(deliveredDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}

														{verifiedBy && !_.isEmpty(verifiedDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"verifiedBy"} />:
																	</span>{" "}
																	{CommonHelper.getUserNamebyId(
																		allDealerUsers,
																		verifiedBy
																	)}
																</li>
																<li>
																	{" "}
																	<span>
																		<Translate text={"verifiedDate"} />:
																	</span>{" "}
																	{verifiedDate
																		? moment
																			.unix(verifiedDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}
														{oemConvertedBy && !_.isEmpty(oemConvertedDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"oemConvertedBy"} />:
																	</span>{" "}
																	{CommonHelper.getUserNamebyId(
																		allDealerUsers,
																		oemConvertedBy
																	)}
																</li>
																<li>
																	{" "}
																	<span>
																		<Translate text={"oemConvertedDate"} />:
																	</span>{" "}
																	{oemConvertedDate
																		? moment
																			.unix(oemConvertedDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}
														{/* {
															convertedByRegion && !_.isEmpty(convertedDateRegion)
																?
																<>
																	<li> <span><Translate text={'convertedByRegion'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), convertedByRegion)}</li>
																	<li> <span><Translate text={'convertedDateRegion'} />:</span> {convertedDateRegion ? moment.unix(convertedDateRegion.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
																</>
																:
																<></>
														}
														{
															convertedByGroup && !_.isEmpty(convertedDateGroup)
																?
																<>
																	<li> <span><Translate text={'convertedByGroup'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), convertedByGroup)}</li>
																	<li> <span><Translate text={'convertedDateGroup'} />:</span> {convertedDateGroup ? moment.unix(convertedDateGroup.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
																</>
																:
																<></>
														} */}
														{!_.isEmpty(receivedDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"receivedDate"} />:
																	</span>{" "}
																	{receivedDate
																		? moment
																			.unix(receivedDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}

														{!_.isEmpty(convertedBy) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"convertedBy"} />:
																	</span>{" "}
																	{CommonHelper.getUserNamebyId(
																		allDealerUsers,
																		convertedBy
																	)}
																</li>
															</>
														) : (
															<></>
														)}
														{!_.isEmpty(convertedDate) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"convertedDate"} />:
																	</span>{" "}
																	{convertedDate
																		? moment
																			.unix(convertedDate.seconds)
																			.format("DD MMM YYYY h:mm A")
																		: "--"}
																</li>
															</>
														) : (
															<></>
														)}
														{!_.isEmpty(ownerInbound) &&
															pipeline !== "LeadsBucket" ? (
															<li>
																{" "}
																<span>
																	<Translate text={"ownerInbound"} />:
																</span>{" "}
																{CommonHelper.getUserNamebyId(
																	allDealerUsers,
																	ownerInbound
																)}
															</li>
														) : (
															<></>
														)}

														<li>
															{" "}
															<span>
																<Translate text={"addedBy"} />:
															</span>{" "}
															{CommonHelper.getUserNamebyId(
																allDealerUsers,
																addedBy
															)}
														</li>
														<li>
															{" "}
															<span>
																<Translate text={"addedDate"} />:
															</span>{" "}
															{addedDate
																? moment
																	.unix(addedDate.seconds)
																	.format("DD MMM YYYY h:mm A")
																: "--"}
														</li>
														{!_.isEmpty(addedFrom) ? (
															<>
																<li>
																	{" "}
																	<span>
																		<Translate text={"addedFrom"} />:
																	</span>{" "}
																	{addedFrom}
																</li>
															</>
														) : (
															<></>
														)}
													</ul>
												</div>
											</Card.Body>
										</Accordion.Collapse>
									</Card>

									{/** LINK-ENQUIRIES */}
									{!_.isEmpty(linkEnquiries) ? (
										<>
											<Card id={"card-link-enquiries"}>
												<Accordion.Toggle
													as={Card.Header}
													eventKey="03"
													onClick={(e) => {
														e.preventDefault();

														if (activeRightKey === "03")
															this.handleRightActiveKey(null);
														else {
															this.handleRightActiveKey("03");
															if (
																document.getElementById("card-link-enquiries")
															) {
																setTimeout(function () {
																	document
																		.getElementById("card-link-enquiries")
																		.scrollIntoView({
																			behavior: "smooth",
																			block: "center",
																		});
																}, 1000);
															}
														}
													}}
												>
													<div className="mb-0">
														{!_.isEmpty(linkEnquiries) ? (
															<div className="ribbon-wrapper-featured">
																<div className="featured"></div>
															</div>
														) : (
															<></>
														)}
														<div className="cardlink">
															<a
																onClick={(e) => {
																	e.preventDefault();
																}}
																className={`btn btn-link ${activeRightKey === "03" ? "" : "collapsed"
																	}`}
															>
																<Translate text={"subEnquiries"} />
																{/* <i className="ico icon-sub-list"></i> */}
																{!_.isEmpty(linkEnquiries) ? (
																	<span className="badge badge-pill badge-cafe ml-1">
																		{linkEnquiries.length}
																	</span>
																) : (
																	<></>
																)}
																<i className="arrow-collapse"></i>
															</a>
														</div>
													</div>
												</Accordion.Toggle>

												<Accordion.Collapse eventKey="03">
													<Card.Body>
														{(() => {
															if (!_.isEmpty(linkEnquiries)) {
																return (
																	<>
																		{linkEnquiries.map((_enquiry, index) => (
																			<LinkEnquiryInfo
																				key={index}
																				enquiry={_enquiry}
																				clientUsers={this.props.clientUsers}
																				groupUsers={this.props.groupUsers}
																				sidepanelOpen={this.sidepanelOpen}
																				history={this.props.history}
																				showMore={false}
																				dealersettings={dealersettings}
																				campaigns={campaigns}
																			></LinkEnquiryInfo>
																		))}
																	</>
																);
															} else {
																return <></>;
															}
														})()}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										</>
									) : (
										<></>
									)}

									{/** CURRENT VEHICLE OWNED */}
									<Card id={"card-currently-owned"}>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="01"
											onClick={(e) => {
												e.preventDefault();

												if (activeRightKey === "01")
													this.handleRightActiveKey(null);
												else {
													this.handleRightActiveKey("01");
													if (document.getElementById("card-currently-owned")) {
														setTimeout(function () {
															document
																.getElementById("card-currently-owned")
																.scrollIntoView({
																	behavior: "smooth",
																	block: "center",
																});
														}, 1000);
													}
												}
											}}
										>
											<div className="mb-0">
												<div className="cardlink">
													<a
														onClick={(e) => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === "01" ? "" : "collapsed"
															}`}
													>
														<Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, "currentVehicleOwned", "currentlyOwned")} />
														{this.ownedCount() > 0 ? (
															<span className="badge badge-pill badge-cafe ml-1">
																{this.ownedCount()}
															</span>
														) : (
															<></>
														)}
														<i className="arrow-collapse"></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										{isDeivered === false && canModifyEnquiry ? (
											<div className="add-button">
												<a
													href="#"
													onClick={(e) => {
														e.preventDefault();
														this.isValidForOwned();
													}}
													className="mini-button"
												>
													<i className="ico icon-add"></i>
												</a>
											</div>
										) : (
											<></>
										)}

										<Accordion.Collapse eventKey="01">
											<Card.Body>
												{(() => {
													if (this.ownedCount() > 0) {
														return (
															<>
																{!enableServicePackages &&
																	!_.isEmpty(this.state.ownedEnquiryVehicles) &&
																	this.state.ownedEnquiryVehicles.map(
																		(_owned, index) => (
																			<OwnedEnquiryInfo
																				key={index}
																				requirement={_owned}
																				clientUsers={this.props.clientUsers}
																				groupUsers={this.props.groupUsers}
																				sidepanelOpen={this.sidepanelOpen}
																				handleEdit={this.handleEditOwnedEnquiry}
																				isDeivered={
																					canModifyEnquiry ? isDeivered : true
																				}
																			></OwnedEnquiryInfo>
																		)
																	)}

																{this.state.ownedVehicles &&
																	this.state.ownedVehicles.map(
																		(_owned, index) => (
																			<OwnedVehicleInfo
																				key={index}
																				requirement={_owned}
																				handleEdit={this.handleEditOwned}
																				isDeivered={
																					canModifyEnquiry ? isDeivered : true
																				}
																				dealersettings={dealersettings}
																			></OwnedVehicleInfo>
																		)
																	)}
															</>
														);
													} else {
														return isDeivered === false && canModifyEnquiry ? (
															<>
																<div className="text-center p-4">
																	<p>
																		<Translate text={"noRecordAvailable"} />
																	</p>
																	<button
																		className="btn btn-sm btn-default"
																		onClick={(e) => {
																			e.preventDefault();
																			this.isValidForOwned();
																		}}
																	>
																		<i className="ico icon-add mr-1"></i>{" "}
																		<Translate text={"add"} />{" "}
																	</button>
																</div>
															</>
														) : (
															<>
																<div className="text-center p-4">
																	<p>
																		<Translate text={"noRecordAvailable"} />
																	</p>
																</div>
															</>
														);
													}
												})()}
											</Card.Body>
										</Accordion.Collapse>
									</Card>

									{/** WALK-INS */}
									<Card id={"card-walkin"}>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="04"
											onClick={(e) => {
												e.preventDefault();

												if (activeRightKey === "04")
													this.handleRightActiveKey(null);
												else {
													this.handleRightActiveKey("04");
													if (document.getElementById("card-walkin")) {
														setTimeout(function () {
															document
																.getElementById("card-walkin")
																.scrollIntoView({
																	behavior: "smooth",
																	block: "center",
																});
														}, 1000);
													}
												}
											}}
										>
											<div className="mb-0">
												{!_.isEmpty(walkins) ? (
													<div className="ribbon-wrapper-featured">
														<div className="featured"></div>
													</div>
												) : (
													<></>
												)}
												<div className="cardlink">
													<a
														onClick={(e) => {
															e.preventDefault();
														}}
														className={`btn btn-link ${activeRightKey === "04" ? "" : "collapsed"
															}`}
													>
														<Translate text={"Walk-ins"} />
														{!_.isEmpty(walkins) ? (
															<span className="badge badge-pill badge-cafe ml-1">
																{walkins.length}
															</span>
														) : (
															<></>
														)}
														<i className="arrow-collapse"></i>
													</a>
												</div>
											</div>
										</Accordion.Toggle>
										{isDeivered === false && canModifyEnquiry ? (
											<div className="add-button">
												<a
													href="#"
													onClick={(e) => {
														e.preventDefault();
														this.setState({
															walkinModal: {
																show: true,
																editID: null,
															},
														});
													}}
													className="mini-button"
												>
													<i className="ico icon-add"></i>
												</a>
											</div>
										) : (
											<></>
										)}
										<Accordion.Collapse eventKey="04">
											<Card.Body>
												{(() => {
													if (!_.isEmpty(walkins)) {
														return (
															<>
																{walkins.map((_walkin, index) => (
																	<WalkinInfo
																		key={index}
																		documentID={index}
																		walkin={_walkin}
																		handleEdit={this.handleEditWalkin}
																		clientUsers={this.props.clientUsers}
																		groupUsers={this.props.groupUsers}
																		isDeivered={
																			canModifyEnquiry ? isDeivered : true
																		}
																	></WalkinInfo>
																))}
															</>
														);
													} else {
														return (
															<>
																<div className="text-center p-4">
																	<p>
																		<Translate text={"nowalkindata"} />
																	</p>
																	{isDeivered === false && canModifyEnquiry ? (
																		<button
																			className="btn btn-sm btn-default"
																			onClick={(e) => {
																				e.preventDefault();
																				this.setState({
																					walkinModal: {
																						show: true,
																						editID: null,
																					},
																				});
																			}}
																		>
																			<i className="ico icon-add mr-1"></i>{" "}
																			<Translate text={"add"} />
																		</button>
																	) : (
																		<></>
																	)}
																</div>
															</>
														);
													}
												})()}
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</div>
						</aside>
					</div>
				</div>

				<LayoutConsumer>
					{({ dealersettings, clientUsers, groupUsers, clientModule }) => (
						<StageProvider
							dealersettings={dealersettings}
							clientUsers={clientUsers}
							groupUsers={groupUsers}
							history={this.props.history}
							path={"contacts"}
						>
							<>
								<PopUpModal show={contactModal.show}>
									<AddContact
										docID={this.state.contact.documentID}
										contactName={engageContactName ? engageContactName : ""}
										engageNewContact={engageNewContact}
										show={contactModal.show}
										clsActive={contactModal.clsActive}
										handleClose={this.handleContactClose}
										title={contactModal.title}
										showSearch={isStatusOpen}
										mandatoryFields={contactModal.mandatoryFields}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										enquiry={this.state.fields}
										enquiryPipeline={pipeline}
										clientModule={clientModule}
										campaign={campaign}
										origin={origin}
										history={this.props.history}
										isFromLayout={isFromLayout}
									></AddContact>
								</PopUpModal>

								<PopUpModal show={tradeInModal.show}>
									<AddTradeIn
										docID={tradeInModal.tradeinID}
										show={tradeInModal.show}
										clsActive={tradeInModal.clsActive}
										handleClose={this.handleTradeInClose}
										title={tradeInModal.title}
										dealersettings={dealersettings}
										enquiryID={this.state.enquiryID}
										enquiry={this.state.fields}
										contact={contact}
										isDeivered={isAccessToReOpen || !isTradeIn ? false : canModifyEnquiry ? isDeivered : true}
										tradeinPlusEnabled={tradeinPlusEnabled}
									></AddTradeIn>
								</PopUpModal>
								<PopUpModal show={autoGrabModal.show}>
									<AutoGrab
										selectedVehicle={autoGrabModal.selectedVehicle}
										history={this.props.history}
										docID={autoGrabModal.tradeinID}
										show={autoGrabModal.show}
										clsActive={autoGrabModal.clsActive}
										handleClose={this.handleAutograbClose}
										title={autoGrabModal.title}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										dealersettings={dealersettings}
									></AutoGrab>
								</PopUpModal>

								<PopUpModal show={showEvalScan}>
									<EvalScan
										show={showEvalScan}
										handleClose={this.handleTradeInClose}
										dealersettings={dealersettings}
										enquiryID={this.state.enquiryID}
										enquiry={this.state.fields}
										contact={contact}
									/>
								</PopUpModal>

								<PopUpModal show={testDriveModal.show}>
									<AddTestDrive
										docID={testDriveModal.testDriveID}
										show={testDriveModal.show}
										clsActive={testDriveModal.clsActive}
										handleClose={this.handleTestDriveClose}
										title={testDriveModal.title}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										enquiry={this.state.fields}
										contact={contact}
										isDeivered={isAccessToReOpen || !isTestDrive ? false : canModifyEnquiry ? isDeivered : true}
										walkins={!_.isEmpty(walkins) ? walkins : []}
										clientModule={clientModule}
									></AddTestDrive>
								</PopUpModal>

								<PopUpModal show={cafeOrderModal.show}>
									<AddCafe
										docID={cafeOrderModal.cafeID}
										show={cafeOrderModal.show}
										clsActive={cafeOrderModal.clsCafeOrderActive}
										handleClose={this.handleCafeClose}
										title={cafeOrderModal.title}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										contact={contact}
									></AddCafe>
								</PopUpModal>

								<PopUpModal show={addFinanceModal.show}>
									<AddFinance
										show={addFinanceModal.show}
										title={addFinanceModal.title}
										type={addFinanceModal.type}
										handleClose={this.handleAddFinanceClose}
										contact={contact}
										requirement={this.state.fields.requirement}
										requirements={requirements}
										signature={{
											termsSignature: addFinanceModal.termsSignature ? addFinanceModal.termsSignature : "",
											termsAndConditions: addFinanceModal.termsAndConditions ? addFinanceModal.termsAndConditions : ""
										}}
										dealersettings={dealersettings}
										enquiryBasicVM={enquiryBasicVM}
										currencySymbol={currencySymbol}
										onFinanceCollectionUpdate={this.onFinanceCollectionUpdate}
									/>
								</PopUpModal>

								<PopUpModal show={addDrivaFinanceModal.show}>
									<AddDrivaInfo
										title={addDrivaFinanceModal.title}
										contact={contact}
										enquiry={this.state.fields}
										show={addDrivaFinanceModal.show}
										handleClose={this.handleAddDrivaFinanceClose}
										currencySymbol={currencySymbol}
										dealersettings={dealersettings}
										enquiryID={this.state.enquiryID}
										selectedVehicle={addDrivaFinanceModal.selectedVehicle}
										documentID={addDrivaFinanceModal.documentID}
										financeDetail={addDrivaFinanceModal.financeDetail}
										driva={driva}
									/>
								</PopUpModal>

								<PopUpModal show={addDrivaFinanceModal.preview}>
									<DrivaPreview
										show={addDrivaFinanceModal.preview}
										title={addDrivaFinanceModal.title}
										data={addDrivaFinanceModal.data}
										handleClose={this.handleViewDrivaFinanceClose}
										dealersettings={dealersettings}
										currencySymbol={currencySymbol}
									/>
								</PopUpModal>

								<PopUpModal show={addProviderModal.show}>
									<AddProvider
										show={addProviderModal.show}
										handleClose={this.handleAddProviderClose}
										contact={contact}
										dealersettings={dealersettings}
										enquiryBasicVM={enquiryBasicVM}
										onFinanceCollectionUpdate={this.onFinanceCollectionUpdate}
									/>
								</PopUpModal>

								<PopUpModal show={viewFinanceModal.show}>
									<ViewFinance
										show={viewFinanceModal.show}
										handleClose={this.handleViewFinanceClose}
										contact={contact}
										dealersettings={dealersettings}
										enquiryBasicVM={enquiryBasicVM}
										currencySymbol={currencySymbol}
									/>
								</PopUpModal>

								<PopUpModal show={addFinanceModal.showTerms}>
									<Terms
										show={addFinanceModal.showTerms}
										clsActive="overlay-modal active"
										imagePath="finance"
										title={"termsAndConditions"}
										terms={""}
										termsandCondition={CommonHelper.getTermsByField(dealersettings.client, "financeTC", contact?.language)}
										isDisable={false}
										handleClose={this.handleTermsClose}
										dealersettings={dealersettings}
										shouldReturnBase64={true}
									></Terms>
								</PopUpModal>

								<PopUpModal show={requirementModal.show}>
									<AddRequirement
										show={requirementModal.show}
										clsActive={requirementModal.clsActive}
										title={requirementModal.title}
										handleClose={this.handleRequirementClose}
										isVehicle={isVehicle}
										isAfterSales={isAfterSales}
										enquiryID={this.state.enquiryID}
										primaryID={primaryReqID}
										isDeivered={isAccessToReOpen || !isVehicle || _.isEmpty(this.state.requirements) ? false : isDeivered === false && (modifyRequirements || isStatusOpen || !status) && canModifyEnquiry ? false : true}
										requirement={requirementModal.requirement}
										clone={requirementModal.clone}
										mandatoryFields={requirementModal.mandatoryFields}
										enqStatus={status}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										clientModule={clientModule}
										requirements={this.state.requirements}
										contactID={this.state.contact.documentID}
										ownedVehicles={this.state.ownedVehicles}
										defaultMakes={this.state.defaultMakes}
									></AddRequirement>
								</PopUpModal>

								<PopUpModal show={requirementMultiModal.show}>
									<MultiRequirementSelect
										show={requirementMultiModal.show}
										clsActive={requirementMultiModal.clsActive}
										title={requirementMultiModal.title}
										handleClose={this.handleMultiRequirementClose}
										isVehicle={isVehicle}
										requirements={this.state.requirements}
										handleEdit={this.handleReqEdit}
										getMandatoryFieldsByType={this.getMandatoryFieldsByType}
										setPrimary={this.handleReqMarkAsPrimary}
										setSold={this.requirementSoldMark}
										decrementQuantity={this.decrementQuantity}
										incrementQuantity={this.incrementQuantity}
										deleteRequirement={this.deleteRequirement}
										primaryID={primaryReqID}
										enquiryID={this.state.enquiryID}
										status={status}
										isDeivered={isAccessToReOpen ? false : canModifyEnquiry ? isDeivered : true}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></MultiRequirementSelect>
								</PopUpModal>

								<PopUpModal show={ownedModal.show}>
									<AddOwnedVehicle
										docID={ownedModal.ID}
										contactID={this.state.contact.documentID}
										show={ownedModal.show}
										clsActive={ownedModal.clsActive}
										handleClose={this.handleOwnedClose}
										title={ownedModal.title}
										isDeivered={canModifyEnquiry ? isDeivered : true}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></AddOwnedVehicle>
								</PopUpModal>
								<PopUpModal show={ownedEnquiryModal.show}>
									<AddOwnedEnquiryVehicle
										vehicle={ownedEnquiryModal.data}
										show={ownedEnquiryModal.show}
										clsActive={ownedEnquiryModal.clsActive}
										handleClose={this.handleOwnedEnquiryClose}
										title={ownedEnquiryModal.title}
										isDeivered={canModifyEnquiry ? isDeivered : true}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></AddOwnedEnquiryVehicle>
								</PopUpModal>

								<PopUpModal show={fileModal.show}>
									<AddFile
										show={fileModal.show}
										activeTab={fileModal.category}
										module={fileModal.type}
										dynamicCategories={fileModal.categories}
										enquiryID={this.state.enquiryID}
										contactID={this.state.contact.documentID}
										handleClose={this.handleFileClose}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></AddFile>
								</PopUpModal>

								<PopUpModal show={videoModal.show}>
									<AddVideo
										show={videoModal.show}
										enquiryID={this.state.enquiryID}
										contactID={this.state.contact.documentID}
										handleClose={this.handleVideoClose}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									></AddVideo>
								</PopUpModal>

								<PopUpModal show={deliveryModal.show}>
									<DeliveredOptions
										title="deliveryOptions"
										show={deliveryModal.show}
										enquiryID={this.state.enquiryID}
										requirement={this.state.requirement}
										dealersettings={dealersettings}
										handleClose={this.handleDeliveryOptionClose}
									/>
								</PopUpModal>

								<PopUpModal show={lostModal.show}>
									<LostReasons
										title="lostReasons"
										show={lostModal.show}
										enquiryID={this.state.enquiryID}
										dealersettings={dealersettings}
										handleClose={this.handleLostReasonsClose}
									/>
								</PopUpModal>

								<PopUpModal show={enquiryModal.show}>
									<EnquiryFields
										title="enquiryInfo"
										show={enquiryModal.show}
										errors={enquiryModal.errors}
										newStatus={enquiryModal.newStatus}
										ismodifyStatusDate={enquiryModal.ismodifyStatusDate}
										isModifyInboundOwner={enquiryModal.isModifyInboundOwner}
										mandatoryFields={enquiryModal.mandatoryFields}
										financeRequired={enquiryModal?.financeRequired}
										enquiry={this.state.fields}
										contact={this.state.contact}
										canModifyEnquiry={canModifyEnquiry}
										enquiryID={this.state.enquiryID}
										requirement={this.state.fields.requirement}
										requirements={this.state.requirements}
										enquiryAllStatus={enquiryAllStatus}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										handleClose={this.handleEnquiryOptionClose}
										handleEditRequirement={this.handleReqEdit}
										isFromLayout={isFromLayout}
									/>
								</PopUpModal>

								<PopUpModal show={subLeadModal.show}>
									<SubLeadsOptions
										title="subLeadsInfo"
										show={subLeadModal.show}
										enquiries={subLeadModal.enquiries}
										newStatus={subLeadModal.status}
										mandatoryFields={subLeadModal.mandatoryFields}
										enquiry={this.state.fields}
										contact={this.state.contact}
										enquiryOptionsDF={enquiryOptionsDF}
										primaryID={this.state.enquiryID}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										handleClose={this.handleCreateSubLeadsClose}
										enquiryAllStatus={enquiryAllStatus}
									/>
								</PopUpModal>

								<PopUpModal show={walkinModal.show}>
									<WalkinAdd
										title="walk-in"
										show={walkinModal.show}
										docID={walkinModal.editID}
										enquiryID={this.state.enquiryID}
										walkins={!_.isEmpty(walkins) ? walkins : []}
										addedDate={!_.isEmpty(addedDate) ? addedDate : window.firebase.firestore.Timestamp.now()}
										dealersettings={dealersettings}
										handleClose={this.handleWalkinClose}
										enquiry={this.state.fields}
									/>
								</PopUpModal>

								<PopUpModal show={selectVehicleModal.show}>
									<SelectVehicle
										show={selectVehicleModal.show}
										handleClose={(req) => this.handleCloseSelectVehicleModal(req)}
										requirements={requirements}
										handleAddRequirement={this.handleSelectRequirement}
										title={"selectVehicle"}
										currencySymbol={currencySymbol}
										dealersettings={dealersettings}
										isFinance={selectVehicleModal.isFinance}
									/>
								</PopUpModal>

								{showpanel.enquiryid && (<SidePanel
									clsActive={showpanel.clsActive}
									clsName={showpanel.clsName}
									sidepanelClose={this.sidepanelClose}
									title=""
								>
									<QuickView
										enquiryid={showpanel.enquiryid}
										history={this.props.history}
										//target="_blank"
										sidepanelClose={this.sidepanelClose}
										isFromLayout={true}
										parentClose={this.sidepanelClose}
									/>
								</SidePanel>)}

								{showSharePanel.chatClsActive && (<ChatSidePanel
									sidepanelClose={this.handleSharePanelClose}
									clsActive={showSharePanel.chatClsActive}
									clsName={showSharePanel.chatClsName}
									title="selectUser"
									sharePanel={true}
									sendAs="enquiry"
									enquiry={this.state.fields}
								/>)}

								{pipelinePopOver.showpopover && (<PopoverPanel
									showpopover={pipelinePopOver.showpopover}
									targetpopover={pipelinePopOver.targetpopover}
									title="Pipeline"
									position="bottom"
									id="pipeline-switch"
									className="popover-pipeline-switch"
									closepopover={() => {
										this.setState({
											pipelinePopOver: {
												showpopover: false,
												targetpopover: "",
											},
										});
									}}
								>
									<PipelineSwitch
										pipeline={pipeline}
										stage={stage}
										clientID={this.state.fields.clientID}
										dealersettings={dealersettings}
										handleClose={this.handlePipelineClose}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										owner={leadOwner}
										status={status}
										isNewEnquiry={isNewEnquiry}
									/>
								</PopoverPanel>)}

								<PopUpModal show={showPipelineModal}>
									<Modal
										show={showPipelineModal}
										onHide={this.handlePipelineClose}
										backdrop="static"
										backdropClassName={
											showPipelineModal ? "overlay-modal active" : ""
										}
										dialogClassName="modal-dialog-centered"
									>
										<Modal.Header closeButton>
											<Modal.Title>{"Pipeline"}</Modal.Title>
										</Modal.Header>
										<Modal.Body className="pb-0 pt-0">
											<PipelineSwitch
												pipeline={pipeline}
												stage={stage}
												clientID={this.state.fields.clientID}
												dealersettings={dealersettings}
												handleClose={this.handlePipelineClose}
												clientUsers={clientUsers}
												groupUsers={groupUsers}
												owner={leadOwner}
												status={status}
												isNewEnquiry={isNewEnquiry}
												isNewEnq={true}
											/>
										</Modal.Body>
									</Modal>
								</PopUpModal>

								<PopUpModal show={showLeadConvert}>
									<LeadConvert
										show={showLeadConvert}
										handleModalClose={this.handleModalClose}
										pipeline={pipeline}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										loader={this.state.convertLeadLoader}
										enquiry={this.state.fields}
									/>
								</PopUpModal>

								<PopUpModal show={showOEMLeadConvert}>
									<OEMLeadAllocation
										show={showOEMLeadConvert}
										handleModalClose={this.handleOEMLeadModalClose}
										pipeline={pipeline}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										loader={this.state.convertLeadLoader}
										enquiry={this.state.fields}
										returnEnq={true}
									/>
								</PopUpModal>

								{/* <PopUpModal show={this.state.showOEMLeadConvertPrev}>
									<OEMLeadTransfer
										show={this.state.showOEMLeadConvertPrev}
										handleModalClose={this.handleOEMLeadModalClose}
										pipeline={pipeline}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										loader={this.state.convertLeadLoader}
										enquiry={this.state.fields}
									/>
								</PopUpModal> */}

								<PopUpModal show={showLeadTransfer}>
									<LeadTransfer
										show={showLeadTransfer}
										handleModalClose={this.handleCloseTransferEnqiry}
										pipeline={pipeline}
										stage={stage}
										owner={leadOwner}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										enquiryID={this.state.enquiryID}
										loader={this.state.convertLeadLoader}
										enquiry={this.state.fields}
									/>
								</PopUpModal>
								<PopUpModal show={showReAuthentication}>
									<ReAuthenticate
										show={showReAuthentication}
										handleClose={this.handleReAuthOpenClose}
										dealersettings={dealersettings}
									/>
								</PopUpModal>

								{lostApprovalPopOver.showpopover && (<PopoverPanel
									showpopover={lostApprovalPopOver.showpopover}
									targetpopover={lostApprovalPopOver.targetpopover}
									title="Lost Approval"
									position="bottom"
									id="notes-lost-approval"
									className="popover-pipeline-switch"
									closepopover={() => {
										this.setState({ lostApprovalPopOver: { showpopover: false, targetpopover: "", }, });
									}}
								>
									<LostApprovalNotes
										handleClose={this.handleLostApprovalClose}
										clientUsers={clientUsers}
										lostReason={this.state.fields.lostReason ? this.state.fields.lostReason : ""}
										lostSubReason={this.state.fields.lostSubReason ? this.state.fields.lostSubReason : ""}
										dealersettings={dealersettings}
										clientID={this.state.fields.clientID}
									/>
								</PopoverPanel>)}

								<PopUpModal show={showNewLeadModal}>
									<Modal
										show={showNewLeadModal}
										onHide={this.handleNewLeadClose}
										backdrop="static"
										backdropClassName={
											showNewLeadModal ? "overlay-modal active" : ""
										}
										dialogClassName="modal-dialog-centered"
									>
										<Modal.Header closeButton>
											<Modal.Title>{"Add New Enquiry"}</Modal.Title>
										</Modal.Header>
										<Modal.Body className="pb-0 pt-0">
											<PipelineSwitch
												clientID={this.state.fields.clientID}
												dealersettings={dealersettings}
												handleClose={this.handleNewLeadClose}
												clientUsers={clientUsers}
												groupUsers={groupUsers}
												pipeline={pipeline}
												owner={leadOwner}
												status={status}
												isNewEnq={true}
											/>
										</Modal.Body>
									</Modal>
								</PopUpModal>

								<PopUpModal show={showPipelineOnOpen}>
									<Modal
										show={showPipelineOnOpen}
										onHide={this.handleOpenLeadClose}
										backdrop="static"
										backdropClassName={
											showPipelineOnOpen ? "overlay-modal active" : ""
										}
										dialogClassName="modal-dialog-centered"
									>
										<Modal.Header closeButton>
											<Modal.Title>{"Select Pipeline "}</Modal.Title>
										</Modal.Header>
										<Modal.Body className="pb-0 pt-0">
											<PipelineSwitch
												clientID={this.state.fields.clientID}
												dealersettings={dealersettings}
												handleClose={this.handleOpenLeadClose}
												clientUsers={clientUsers}
												groupUsers={groupUsers}
												allClientUsers={isAfterSales ? allClientUsers : []}
												pipeline={pipeline}
												stage={stage}
												owner={leadOwner}
												status={status}
												isNewEnq={true}
											/>
										</Modal.Body>
									</Modal>
								</PopUpModal>

								<PopUpModal show={eventModal.show}>
									<EventModal
										show={eventModal.show}
										event={eventModal.data}
										title={eventModal.title}
										handleClose={this.handleEventClose}
										dealersettings={dealersettings}
									/>
								</PopUpModal>

								<PopUpModal show={showMergeEnqList}>
									<MergeList
										show={showMergeEnqList}
										title={"Select Enquiry To Merge"}
										dealersettings={dealersettings}
										handleClose={this.handleCloseMerge}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										contactID={this.state.contact.documentID}
										enquiryID={this.state.enquiryID}
										primaryEnquiry={this.state.fields}
									/>
								</PopUpModal>
								<PopUpModal show={sendLinkModal.show}>
									<SendTradeInLink
										show={sendLinkModal.show}
										tradeinID={sendLinkModal.tradeinID}
										handleClose={this.handleCloseSendLink}
										contact={this.state.contact}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									/>
								</PopUpModal>
								<PopUpModal show={stockQuoteModal.show}>
									<StockQuotesList
										show={stockQuoteModal.show}
										requirement={stockQuoteModal.requirement}
										handleClose={this.handleCloseStockQuote}
										enquiry={this.state.fields}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										currencySymbol={currencySymbol}
									/>
								</PopUpModal>
								<PopUpModal show={completedTradeinsList.show}>
									<TradeinCompletedList
										show={completedTradeinsList.show}
										isMandatory={completedTradeinsList.isMandatory}
										isReopen={completedTradeinsList.isReopen}
										enquiryID={this.state.enquiryID}
										handleClose={() => {
											this.setState({
												completedTradeinsList: {
													show: false,
													isMandatory: false,
													isReopen: false
												},
											});
										}}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										currencySymbol={currencySymbol}
									/>
								</PopUpModal>
								<PopUpModal show={linkAppraisalModal}>
									<TradeinLinkAppraisal
										show={linkAppraisalModal}
										clientID={this.state.fields.clientID}
										enquiryID={this.state.enquiryID}
										handleClose={this.handleCloseLinkAppraisal}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
									/>
								</PopUpModal>
								<PopUpModal show={scheduleContact.show}>
									<ScheduleContact
										{...this.props}
										{...scheduleContact}
										handleClose={() => {
											this.setState({ scheduleContact: { show: false, }, });
										}}
										allDevice={dealersettings.tvDevices}
										enquiry={{
											..._.pick(this.state.fields, _.keys(enquiryBasicDetailsVM)),
											["contactID"]: contact.documentID ? contact.documentID : (this.state.fields.contactID ? this.state.fields.contactID : (this.state.fields?.contact?.documentID || null)),
											["contact"]: this.state.fields.contact ? { ..._.pick(contact, _.keys(contactBasicDetailsVM)), } : {},
										}}
									/>
								</PopUpModal>
								<PopUpModal show={scheduleList.show}>
									<ScheduleList
										{...this.props}
										{...scheduleList}
										allDevice={dealersettings.tvDevices}
										handleClose={() => {
											this.setState({
												scheduleList: {
													show: false,
												},
											});
										}}
										enquiry={{
											..._.pick(this.state.fields, _.keys(enquiryBasicDetailsVM)),
											["contactID"]: contact.documentID ? contact.documentID : (this.state.fields.contactID ? this.state.fields.contactID : (this.state.fields?.contact?.documentID || null)),
											["contact"]: this.state.fields.contact ? { ..._.pick(contact, _.keys(contactBasicDetailsVM)), } : {},
										}}
									/>
								</PopUpModal>

								<PopUpModal show={showNewLeadActivity}>
									<AddActivity
										show={showNewLeadActivity}
										handleClose={this.sheduleNewLeadActivityClose}
										handleDelete={() => { }}
										title={"Add New Activity"}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										//activityLog={showScheduleActivityModel.activityLog}
										enquiry={{
											..._.pick(this.state.fields, _.keys(enquiryBasicDetailsVM)),
											["contactID"]: contact.documentID ? contact.documentID : (this.state.fields.contactID ? this.state.fields.contactID : (this.state.fields?.contact?.documentID || null)),
											["contact"]: this.state.fields.contact ? { ..._.pick(contact, _.keys(contactBasicDetailsVM)), } : {},
										}}
										contact={contact}
										clsActive="overlay-modal active"
										isNewActivity={true}
										isNewLeadActivity={true}
									></AddActivity>
								</PopUpModal>

								<PopUpModal show={showContactInfo.show}>
									<ContactMergeInfo
										show={showContactInfo.show}
										existingContact={showContactInfo.data}
										newContact={contact}
										isNewContact={false}
										enquiryID={this.state.fields.documentID}
										isNewEnquiry={false}
										handleClose={this.handleXContactClose}
										title={'Contact Details'}
										dealersettings={this.props.dealersettings}
										clientUsers={this.props.clientUsers}
										groupUsers={this.props.groupUsers}
										showMerge={true}
									>
									</ContactMergeInfo>
								</PopUpModal>
								<PopUpModal show={showCAPLeads.show}>
									<CAPLeads
										{...this.props}
										{...showCAPLeads}
										saveCAPDetails={this.saveCAPDetails}
										handleClose={() => {
											this.setState({ showCAPLeads: { show: false }, capInfo: {} })
										}}
									/>
								</PopUpModal>

								<PopUpModal show={showAddOns}>
									<ProductAddOns
										show={showAddOns}
										editID={productEditID}
										enquiryID={this.state.enquiryID}
										enquiry={this.state.fields}
										dealersettings={this.props.dealersettings}
										clientUsers={this.props.clientUsers}
										groupUsers={this.props.groupUsers}
										handleClose={this.handleAddOnsClose}
										serviceAddOns={!_.isEmpty(_productAddons) ? [..._productAddons] : []}
										selectedAddOns={productAddOns}
									/>
								</PopUpModal>

								<PopUpModal show={filesPopOver.show}>
									{
										filesPopOver.show && (
											<Modal
												show={filesPopOver.show}
												onHide={this.handleFileClose}
												backdrop={true}//"static"
												backdropClassName={
													filesPopOver.show ? "overlay-modal active" : ""
												}
												dialogClassName={`modal-dialog-centered modal-add-requirement`}
											>
												<Modal.Header closeButton>
													<Modal.Title>{"Documents"}</Modal.Title>
												</Modal.Header>
												<Modal.Body className="pb-0 pt-0">

													<div className="quickview-files-wraper">
														<div className="add-files-button">
															<a href="#" className="mini-button" onClick={(e) => {
																e.preventDefault();
																this.handleFileOpen(null, filesPopOver.category, filesPopOver.type, filesPopOver.categories, filesPopOver.categoryName);
															}}>
																<i className="ico icon-add"></i>
															</a>
														</div>
														<div className="file-manager-nav pl-1 file-manager-tooltipfix">
															<div className="file-manager-accordion-icon"> <i className="ico icon-folder"></i> </div>
															<div className="file-manager-accordion-head">{filesPopOver.categoryName ? filesPopOver.categoryName : 'Files'} </div>
														</div>
														< div style={{
															minHeight: "100px",
															//overflow: "auto",
															//position: "relative"
														}}>

															<Files
																enquiryID={this.state.enquiryID}
																contactID={this.state.contact?.documentID}
																contact={this.state.contact}
																dealersettings={dealersettings}
																clientUsers={clientUsers}
																groupUsers={groupUsers}
																isDeivered={canModifyEnquiry ? isDeivered : true}
																dynamicCategories={filesPopOver.categories}
																customTab={filesPopOver.category}
																module={filesPopOver.type}
															//showAdd={true}
															></Files>


														</div>

													</div>
												</Modal.Body>
											</Modal>
										)
									}
								</PopUpModal>

								<PopUpModal show={notesModel.show}>
									<AddCallNotes
										{...notesModel}
										dealersettings={dealersettings}
										clientUsers={clientUsers}
										groupUsers={groupUsers}
										handleClose={this.handleLogNoteClose}
									/>
								</PopUpModal>

							</>
						</StageProvider>
					)}
				</LayoutConsumer>
			</>
		) : this.state.pagenotFound ? (
			<PageNotFound />
		) : (
			<LoaderOverlay text="Fetching enquiry details..." active={true} />
		);
	}
}

export default EnquiryInfo;
