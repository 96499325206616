
/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import _ from 'lodash';
import moment from 'moment';
//import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { InputTextArea } from '../../components';
import CommonHelper from '../../services/common';
// import Users from '../users/list/index';
// import Contacts from '../contacts/list/index';
// import ContactActivityView from './activity'
import { default as _images } from '../../images';
//import AddEnquiry from '../pipeline/add';

import Requirements from '../requirement/list';
/** VIEW-MODELS */
import { engageStatus } from '../../services/enum'
import { objEngageRequest } from './viewModel'
import { objEnquiry, objActivitylog } from '../pipeline/viewModel';
import { objContact } from '../contacts/viewModel';
import { objRequirement } from '../requirement/viewModel';
import { firestoreDB } from "../../services/helper";
//import { objContact } from "../contacts/viewModel";


// const enquiryTypes = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Phone', label: 'Phone' },
//     { value: 'Email', label: 'Email' }
// ]

// const campaigns = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Test Campaign', label: 'Test Campaign' },
//     { value: 'Service Leads', label: 'Service Leads' }
// ]

export default class EngageRequest extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('engageRequests');
        this.unsubscribeEngageReq = null;
        this.state = {
            fields: Object.assign({}, objEngageRequest),
            showReject: false,
            showEnquiryModal: false,
            errors: {},
            enquiryTypes: [],
            origins: [],
            campaigns: [],
            titles: [],
        }
    }

    //#region PAGE LOAD EVENTS

    async loadEngageRequest(props) {
        const { show, docID, dealersettings } = props;

        const fields = Object.assign({}, objEngageRequest);
        if (show === true && docID) {
            this.newDocID = docID;
            const refData = await firestoreDB(dealersettings).firestore().collection('engageRequests').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                fields.documentID = refData.id;
                this.setState({ fields: fields });

                this.unsubscribeEngageReq = firestoreDB(dealersettings).firestore().collection('engageRequests')
                    .doc(docID)
                    .onSnapshot(this.onCollectionUpdate);

            } else {
                console.error("No such document2!");
            }

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                const setttings = dealersettings.client.settings;
                const origins = [];
                setttings.origins && setttings.origins.forEach(doc => {
                    origins.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

                const enquiryTypes = [];
                setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                    enquiryTypes.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                    });
                });

                const campaigns = [];
                setttings.campaigns && setttings.campaigns.forEach(doc => {
                    campaigns.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                    });
                });

                const titles = [];
                setttings.titles && setttings.titles.forEach((doc) => {
                    titles.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

                this.setState({
                    origins: origins,
                    campaigns: campaigns,
                    enquiryTypes: enquiryTypes,
                    titles: titles
                });
            }

        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEngageRequest(this.props);
    }

    componentWillUnmount() {
        this.unsubscribeEngageReq && this.unsubscribeEngageReq();
    }
    //#endregion

    //#region  CHANGE EVENTS
    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields });
        //this.errorChange(name);
    }

    handleEnquiryClose = () => {
        this.setState({ showEnquiryModal: false });
        this.props.setNotifyClose(true)
        this.props.handleClose();
    }
    //#endregion

    //#region SAVE & SUBMIT 
    handleRejectSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        //let fields = Object.assign({}, this.state.fields);
        //let errorClass = 'input_error';
        //this.setState({ errors: errors });
        if (formIsValid === true) {
            this.updateStatus(engageStatus.REJECT);
        }
        else
            return formIsValid;

    };

    handleAcceptSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        //let fields = Object.assign({}, this.state.fields);
        //let errorClass = 'input_error';
        //this.setState({ errors: errors });
        if (formIsValid === true) {
            this.updateStatus(engageStatus.ACCEPT);
        }
        else
            return formIsValid;

    };



    updateStatus = (status) => {
        const { dealersettings } = this.props;
        this.isLoading = true;
        const objData = Object.assign({}, this.state.fields);
        let _date = moment()._d;

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        }
        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.fromDate(_date);
        objData.status = status;
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (objData.status === engageStatus.ACCEPT)
            delete objData['rejectReason'];

        if (!objData.clientID)
            objData.clientID = dealersettings ? dealersettings.client.id : '';

        if (!objData.documentID)
            objData.documentID = firestoreDB(dealersettings).firestore().collection('engageRequests').doc().id;

        if (objData.status === engageStatus.ACCEPT) {
            this.loadEnquiry(objData);
        }
        else {
            this.updateRequest(objData, objData.status)
        }
    };
    //#endregion

    //#region ENQUIRY
    async loadEnquiry(ObjRequest) {
        const { campaign, origin, enquiryType } = this.state.fields;
        const { dealersettings, clientModule } = this.props;
        if (dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings) {

            const setttings = dealersettings.client.settings;


            let state = Object.assign({}, objEnquiry);

            if (_.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                state.addedDate = window.firebase.firestore.Timestamp.now();
                state.owner = dealersettings ? dealersettings.id : '';
            }
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, ObjRequest.clientID)
            // state.clientID = ObjRequest.clientID ? ObjRequest.clientID : (dealersettings ? dealersettings.client.id : '');
            // state.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);
            // state.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);
            // state.oemID = (dealersettings ? dealersettings.client.settingsID : null);

            if (setttings.pipelines.length > 0 && setttings.pipelines.some(item => item.value !== 'LeadsBucket')) {

                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value !== 'LeadsBucket')[0])
                if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline)) {
                    _pipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline);
                }
                let defaultstages = _pipeline?.stages;
                let defaultstatus = _pipeline?.status;
                let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

                state.pipeline = _pipeline.value;
                state.stage = stages[0];
                state.status = !_.isEmpty(defaultstatus) ? defaultstatus[0] : 'open';
                state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);

            }
            else {

                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
                state.status = null;
                state.ownerInbound = localStorage.uid;
            }

            if (campaign) state.campaign = campaign;
            if (origin) state.origin = origin;
            if (enquiryType) state.enquiryType = enquiryType;

            if (_.isEmpty(state.tags) && ObjRequest.documentID)
                state.tags = [{ type: 'frontdesk', refID: ObjRequest.documentID, subType: '' }];

            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.isDeleted = false;
            state.isNewEnquiry = true;

            if (!_.isEmpty(ObjRequest.contact)) {
                const refContactData = await firestoreDB(dealersettings).firestore().collection('contactDetails').doc(ObjRequest.contact.documentID).get();
                if (refContactData.exists) {
                    const contact = Object.assign({}, objContact);
                    const objcontactData = Object.assign({}, refContactData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                    }
                    contact.documentID = refContactData.id;
                    if (campaign && !contact.campaign) contact.campaign = campaign;
                    if (origin && !contact.origin) contact.origin = origin;

                    state.contact = contact;
                    state.isContact = true;
                    state.contactID = refContactData.id;
                    state.isNewEnquiry = false;
                }
            }
            else if (!_.isEmpty(ObjRequest.name)) {
                state.engageContactName = ObjRequest.name;
            }

            if (!_.isEmpty(ObjRequest.make)) {
                const _requirement = Object.assign({}, objRequirement);
                for (let [key, value] of Object.entries(ObjRequest)) {
                    if (value && (key === 'make' || key === 'model' || key === 'type' || key === 'group' || key === 'year' || key === 'saleType'))
                        _requirement[key] = value;
                }
                _requirement.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
                state.requirement = _requirement;
                state.isVehicle = true;
            }
            if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
                state.requirement = {
                    saleType: dealersettings.accessSalesType[0]
                }
            }

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

            const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                let documentID = objData.documentID;

                if (!_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
                    this.saveReqInfo(documentID, objData.requirement, objData.clientID)

                if (!_.isEmpty(objData.contact))
                    CommonHelper.saveContactOriginAndCampaign(objData.contact.documentID, objData.contact.campaign, objData.contact.origin, this.props)

                ObjRequest.enquiryID = documentID;
                if (ObjRequest.enquiryType && ObjRequest.enquiryType === 'walkin') {
                    const walkinID = updateRef.collection('walkins').doc().id
                    updateRef.collection('walkins').doc(walkinID).set({
                        addedBy: localStorage.uid,
                        addedDate: window.firebase.firestore.Timestamp.now(),
                        documentID: walkinID,
                        clientID: dealersettings ? dealersettings.client.id : '',
                        projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null,
                        enquiryID: objData.documentID,
                        modifiedBy: dealersettings ? dealersettings.id : '',
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        walkinDate: objData.addedDate,
                    }, { merge: true }).then(res => {
                        this.saveCustomerlog(ObjRequest, objData);
                        this.updateRequest(ObjRequest);
                        this.reArrangeQueue(objData.clientID);
                    }).catch((error) => {
                        console.error("Error adding enquiries: ", error);
                    });
                } else {
                    this.saveCustomerlog(ObjRequest, objData);
                    this.updateRequest(ObjRequest);
                    this.reArrangeQueue(objData.clientID);
                }
                if (objData.contactID) {
                    const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                    updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                }

                //CommonHelper.saveChangeLog(objData.clientID, documentID, logNotes, 'enquiry', 'added');
                this.props.history.push("/enquiry/details/" + documentID + '?type=enquiry');
            }).catch((error) => {
                console.error("Error adding enquiries: ", error);
            });
        }
    }

    saveReqInfo = (enquiryID, _objReq, clientID) => {
        try {

            const { dealersettings } = this.props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID)

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/requirements`).doc(requirementID);
            refReqData.set(objData, { merge: true }).then((docRef) => {

                let _makeModel = !_.isEmpty(objData.stock) ?
                    (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                    (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

                let _logNote = (!_.isEmpty(objData.stock) ? 'Requirement stock added' : 'Requirement added') + (_makeModel ? ' (' + _makeModel + ')' : '');

                CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated');

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        } catch (error) {
            //errorLog(error, 'saveReqInfo');
            console.error("Error adding enquiries: ", error);
        }

    };

    updateRequest = (objData) => {
        const { dealersettings } = this.props;
        const updateRef = firestoreDB(dealersettings).firestore().collection('engageRequests').doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            if (objData.callLogID) {
                const callLogRef = firestoreDB(dealersettings).firestore().collection('callLogs').doc(objData.callLogID)
                callLogRef.get().then(snapshot => {
                    if (snapshot.exists) {
                        let callLogData = {
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                        }
                        if (objData.status === engageStatus.REJECT) {
                            callLogData.status = 'rejected';
                            callLogData.rejectReason = objData.rejectReason ? objData.rejectReason : '';
                        } else if (objData.status === engageStatus.ACCEPT) {
                            callLogData.status = 'accepted';
                        }
                        callLogRef.set(callLogData, { merge: true }).then(res => {
                            this.isLoading = false;
                            toast.notify('Engage request updated successfully', {
                                duration: 2000
                            });
                            this.props.setNotifyClose(true)
                            this.props.handleClose();
                        })
                    }
                })
            } else {
                this.isLoading = false;
                toast.notify('Engage request updated successfully', {
                    duration: 2000
                });
                this.props.setNotifyClose(true)
                this.props.handleClose();
            }
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });
    }

    saveCustomerlog = (objFields, _enquiry) => {
        const { dealersettings, clientUsers } = this.props;
        const newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        newlogVM.subType = 'note';
        newlogVM.isDone = true;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = !_.isEmpty(objFields.enquiryID) ? objFields.enquiryID : null;
        newlogVM.contactID = !_.isEmpty(objFields.contact) && !_.isEmpty(objFields.contact.documentID) ? objFields.contact.documentID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        newlogVM.clientID = dealersettings.client.id;
        newlogVM.documentID = firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId(clientUsers, localStorage.uid);
        newlogVM.notes = (_.isEmpty(objFields.notes) || _.isEmpty(objFields.notes.trim())) ? '' : objFields.notes;
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });;
    }

    async reArrangeQueue(clientID) {
        const { dealersettings } = this.props;

        const queueRef = window.firebase.firestore().doc(`clients/${clientID}/queues/${dealersettings.department}`);
        await queueRef.get().then(async queueDoc => {
            if (queueDoc.exists) {
                const queue = queueDoc.data();
                if (!_.isEmpty(queue) && !_.isEmpty(queue.users) && queue.qTimer && queue.users.indexOf(localStorage.uid) >= 0) {
                    queue.users = queue.users.filter(e => e !== localStorage.uid).concat(localStorage.uid)
                    await queueRef.update({
                        users: queue.users
                    }).catch(err => console.error(err));
                }
            }
        });
    }
    handleEdit = () => {

    }
    //#endregion

    render() {
        const { documentID, contact, name, notes, campaign, origin, enquiryType, status, rejectReason } = this.state.fields;
        const { show, dealersettings, clientUsers, groupUsers } = this.props;
        const { showReject, showEnquiryModal, origins, campaigns, enquiryTypes, titles, fields } = this.state

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

        return (
            documentID
                ?
                <>
                    <Modal
                        id="engage-request"
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-engage-contact">

                        <Modal.Header>
                            <Modal.Title>
                                <h5 className="modal-title" ><Translate text={'engageRequest'} /></h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className={`container-fluid`}>
                                <div className="row">
                                    <div className="engage-request-head">
                                        <div className="text-center">
                                            <div className="engage-request-contact">
                                                <i className="ico icon-Contacts"></i>
                                            </div>
                                        </div>
                                        <div className="engage-request-info text-area-space">
                                            {notes}
                                        </div>
                                    </div>
                                    <div className="engage-request-body">
                                        <div className="engage-contact-details">
                                            <h3> <Translate text={'contactDetails'} /></h3>
                                            <table className="table-fill">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={'name'} /></td>
                                                        <td className="text-left">
                                                            {
                                                                (!_.isEmpty(contact))
                                                                    ?
                                                                    <>
                                                                        {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                                        {CommonHelper.displayContact(titles, contact, '')}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {name ? name : '--'}
                                                                    </>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'enquiryType')} /></td>
                                                        <td className="text-left">{CommonHelper.getLabelByValue(enquiryTypes, enquiryType, '--')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'origin')} /></td>
                                                        <td className="text-left">{CommonHelper.getLabelByValue(origins, origin, '--')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left label-text"><Translate text={CommonHelper.getStaticFieldName(dealersettings?.client?.settings?.enquiryOptionsStatic, 'campaign')} /></td>
                                                        <td className="text-left">{CommonHelper.getLabelByValue(campaigns, campaign, '--')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            fields.make
                                                ?
                                                <>
                                                    <div className="engage-contact-details mt-2">
                                                        <h3> <Translate text={'vehicleDetails'} /></h3>

                                                        <Requirements
                                                            requirement={fields}
                                                            handleEdit={this.handleEdit()}
                                                            setPrimary={this.handleEdit()}
                                                            decrementQuantity={this.handleEdit()}
                                                            incrementQuantity={this.handleEdit()}
                                                            isPrimary={false}
                                                            enquiryID={fields.documentID}
                                                            isDeivered={true}
                                                            hideMore={true}
                                                            dealersettings={dealersettings}
                                                            clientUsers={clientUsers}
                                                            groupUsers={groupUsers}
                                                        >
                                                        </Requirements>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            showReject
                                                ?
                                                <>
                                                    <div className="engage-request-notes">
                                                        <h3> <Translate text={'rejectReason'} /></h3>
                                                        <InputTextArea
                                                            className={`engage-request-notewrap ${this.state.errors["rejectReason"]}`}
                                                            name="rejectReason"
                                                            onChange={this.handleOnAreaChange}
                                                            value={rejectReason}
                                                            maxLength="500"
                                                            rows="3">
                                                        </InputTextArea>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            status === engageStatus.PENDING && !readOnlyEnquiryView
                                ?
                                <>
                                    {
                                        showReject
                                            ?
                                            <>
                                                <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>

                                                    <button type="button"
                                                        className="btn btn-default float-left"
                                                        onClick={(e) => { e.preventDefault(); this.setState({ showReject: false }) }}>
                                                        <Translate text={'cancel'} />
                                                    </button>
                                                    <button type="button"
                                                        className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                                        onClick={(e) => this.handleRejectSubmit(e)}>
                                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        <Translate text={'send'} />
                                                    </button>

                                                </Modal.Footer>
                                            </>
                                            :
                                            <>
                                                <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>

                                                    <button type="button"
                                                        className="btn btn-red float-left"
                                                        onClick={(e) => { e.preventDefault(); this.setState({ showReject: true }) }}
                                                    >
                                                        <Translate text={'reject'} />
                                                    </button>
                                                    <button type="button"
                                                        className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                                        onClick={(e) => this.handleAcceptSubmit(e)}
                                                    >
                                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        <Translate text={'accept'} />
                                                    </button>

                                                </Modal.Footer>
                                            </>
                                    }

                                </>
                                :
                                <>
                                    <Modal.Footer className="modal-footer">

                                        <button type="button"
                                            className="btn btn-default float-right"
                                            onClick={(e) => { e.preventDefault(); this.props.handleClose(); }}>
                                            <Translate text={'close'} />
                                        </button>

                                    </Modal.Footer>
                                </>
                        }



                    </Modal>
                    {/* <LayoutProvider history={this.props.history}>
                        <PopUpModal show={showEnquiryModal}>
                            <AddEnquiry
                                show={showEnquiryModal}
                                handleClose={this.handleEnquiryClose}
                                title={'addNewEnquiry'}
                                dealersettings={this.props.dealersettings}
                                clientUsers={this.props.clientUsers}
                                groupUsers={this.props.groupUsers}
                                history={this.props.history}
                                contact={contact}
                                campaign={campaign}
                                origin={origin}
                                enquiryType={enquiryType}
                            >
                            </AddEnquiry>
                        </PopUpModal>
                    </LayoutProvider> */}
                </>
                :
                <>
                </>
        );
    }
}

