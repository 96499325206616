import React, { useState, useEffect } from 'react'
import "../../../styles/testdrive.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay, GoogleMap } from '../../../components';
import Translate from '../../../constants/translate';
import { objTestDrive, objMark } from '../viewModel'

const SecondaryLicense = (props) => {
    const [testdrive, setTestdrive] = useState(objTestDrive)

    const { documentID, enquiryID,
        enquiry, users, contact, stock, client,
        clientID, contactID, stockID,
        startDate, startOdometer,
        endDate, endOdometer,
        beforeInspection, afterSignatureURL, afterInspection,
        isVehicleHandOver, expectedReturn,
        distance, duration, rating, speed, isLike, isDamage,
        secondaryLicenseFrontURL, secondaryLicenseBackURL,
        secondarySignatureURL, polylineCoordinates,
        testdriveFeedback, insurance,
        terms, status, addedBy, addedDate
    } = testdrive;


    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    const imageToBase64 = async (id, URL) => {
        await toDataURL(URL)
            .then(dataUrl => {
                //  console.log('imageToBase64:', dataUrl)
                document.getElementById(id).src = dataUrl;
                return dataUrl;


            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
                return URL;
            });
    }
    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`testdrives/${props.testdriveid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])
    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        // console.log('TestdriveDetails', props.testdriveid);
        let refCollectionDatat;
        if (props.testdriveid) {

            refCollectionDatat = docData(firestoreDB.firestore().doc(`testdrives/${props.testdriveid}`)
            )
                .pipe(
                    switchMap(tradeIns => {
                        //console.log(tradeIns);
                        let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
                        let _stockID = tradeIns.stockID ? tradeIns.stockID : tradeIns?.stock?.documentID
                        const enqIds = [tradeIns.enquiryID];
                        const clientIds = [tradeIns.clientID];
                        const contactIds = _contactID ? [_contactID] : [];
                        const stockIds = _stockID ? [_stockID] : [];
                        return combineLatest(
                            of(tradeIns),
                            combineLatest(
                                enqIds.length > 0 ? enqIds.map(enquiryID =>
                                    collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                        map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                contactIds.length > 0 ? contactIds.map(contactID =>
                                    collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
                                        map(contactSnapshot => contactSnapshot[0] ? contactSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                stockIds.length > 0 ? stockIds.map(stockID =>
                                    collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                        map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                        map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                        map(usersSnapshot => usersSnapshot)
                                    )
                                ) : of([])
                            ),
                        )
                    }),
                    map(([tradeIns, enquirySnapshot, contactSnapshot, stockSnapshot, clientSnapshot, usersSnapshot]) => {
                        const contactID = tradeIns.contactID ? tradeIns.contactID : (tradeIns.contact ? tradeIns.contact.documentID : '');
                        let _stockID = tradeIns.stockID ? tradeIns.stockID : tradeIns?.stock?.documentID
                        return {
                            ...tradeIns,
                            enquiry: enquirySnapshot.find(a => a.documentID === tradeIns.enquiryID),
                            contact: contactSnapshot.find(a => a.documentID === contactID),
                            stock: _stockID && stockSnapshot.find(a => a.documentID === _stockID) ? stockSnapshot.find(a => a.documentID === _stockID) : Object.assign({}, tradeIns.stock),
                            client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                            users: usersSnapshot[0],
                        }

                    })
                )
                .subscribe(_testdrives => {
                    //   console.log('TestdriveDetails', _testdrives);
                    if (!_.isEmpty(_testdrives))
                        setTestdrive(_testdrives)
                });
            return () => {
                refCollectionDatat && refCollectionDatat.unsubscribe()
            }
        }
    }, [firestoreDB])

    return (
        <>
            {
                !_.isEmpty(testdrive) && !_.isEmpty(enquiry)
                    ?
                    <>
                        <div className="quotation-wrap">
                            <div className="wrapper">
                                <div className="print-wrap">
                                    <div className="header">
                                        <div className="headerwrap-left">
                                            <div className="header-left">
                                                {
                                                    client && client.logoURL
                                                        ?
                                                        <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                        :
                                                        <>
                                                            <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </>
                                                }
                                            </div>
                                        </div>

                                        <div className="headerwrap-right">
                                            <div className='header-right'>
                                                {' '}
                                                {client.name} <br />
                                                <span>
                                                    {' '}
                                                    {client.address ? client.address : ''}
                                                    {client.phone ? (
                                                        <>
                                                            <br />
                                                            {`Phone: ${client.phone}`}
                                                        </>
                                                    ) : <></>}
                                                    {client.email || client.website ? (
                                                        <>
                                                            <br />
                                                            {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                        </>
                                                    ) : <></>}
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="clear"></div>
                                    <div className="middle">
                                        <div className="title-head"><Translate text={'Test Drive Secondary License'} upperCase={true} /></div>
                                        <div className="clear"></div>
                                        <div className="table-wraper">
                                            <div className="table-head valuation-num">{'Enquiry Information'}

                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Enquiry</span>  {enquiry && enquiry.displayID ? enquiry.displayID : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>User Name</span>{(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyDocumentId(users, addedBy) : '--'}  </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Department</span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserDepartmentbyDocumentId(users, addedBy) : '--'} </div></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="table-wraper brd-none">
                                            <div className="table-head">Contact & Vehicle Details &nbsp;
                                                {((contact && contact.displayID) ? '(' + contact.displayID + ')' : '')}
                                                {
                                                    contact && contact.isDLScan
                                                        ?
                                                        <div className="mrg-fixstatus badge-white ml-1 mt-0">
                                                            <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                            {`Digital Driving Licence`}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Contact Name</span>{CommonHelper.displayContactName(null, contact, '--')}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Licence No</span>{contact && contact.licenseNo ? contact.licenseNo : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Company</span>{contact?.businessContact && !_.isEmpty(contact?.company) && contact?.company.name ? contact?.company.name : '--'}</div></td>
                                                        </tr>

                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Vehicle</span>{stock?.make ? stock.make : ''} {stock?.model ? stock.model : ''}{stock?.year ? `, ${stock.year}` : ''}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Reg #</span>{stock && stock.regNo ? stock.regNo : '--'}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Stock #</span>{stock && stock.stockNo ? stock.stockNo : '--'} </div></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="tbl-lic-fix">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="33%">
                                                                    <div className="license-wrap">
                                                                        <span>Secondary Driver License Front</span>
                                                                        {
                                                                            (secondaryLicenseFrontURL)
                                                                                ?
                                                                                <a data-fancybox={`secondaryLicenseFrontURL`} href={secondaryLicenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="secondaryLicenseFrontURL" src={secondaryLicenseFrontURL} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td width="33%">
                                                                    <div className="license-wrap">
                                                                        <span>Secondary Driver License Back</span>
                                                                        {
                                                                            (secondaryLicenseBackURL)
                                                                                ?
                                                                                <a data-fancybox={`secondaryLicenseBackURL`} href={secondaryLicenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="secondaryLicenseBackURL" src={secondaryLicenseBackURL} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td width="33%">
                                                                    <div className="grid-one">
                                                                        <span>Secondary Driver Signature</span>
                                                                        <span style={{ textAlign: 'center', paddingTop: '20px' }}>
                                                                            {
                                                                                secondarySignatureURL
                                                                                    ?
                                                                                    <img id={`secondarySignatureURL`} src={secondarySignatureURL} width="120" />
                                                                                    :
                                                                                    <img id={`secondarySignatureURL`} src={_images.sign} width="120" />
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="push"></div>
                            </div>
                            <div className="clear"></div>
                            <div className="footer">
                                <div className="footer-bottom">

                                    <p>
                                        <span style={{ paddingLeft: 0 }} >
                                            {client.address ? <>{client.address}</> : <></>}
                                        </span>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                            {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                            {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                            {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-left">{'Powered by FusionSD.'}</div>

                                    <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>

                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default SecondaryLicense;