import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { pipelineVM } from './viewModel'
import Translate from '../../../../constants/translate';

const RearrangeStage = (props) => {
    const [stages, setStages] = useState(props.pipeline.stages);
    const [loader, setLoader] = useState(false)

    const saveStage = () => {
        // console.log(stages)
        // props.handleClose({
        //     ...props.pipeline,
        //     stages: [...stages]
        // })
        


        setLoader(true)
        const batch = window.firebase.firestore().batch();
        if (props.pipeline.level === 'oem') {
            const pipelineObj = {
                'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level), function (obj) {
                    return obj.value === props.pipeline.value ? {
                        ..._.pick(props.pipeline, _.keys(pipelineVM)),
                        stages: [...stages]
                    } : { ..._.pick(obj, _.keys(pipelineVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                pipelineObj, { merge: true });
        }
        else if (_.isArray(props.pipeline.levelID)) {
            props.pipeline.levelID.forEach(rec => {
                const pipelineObj = {
                    'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === rec), function (obj) {
                        return obj.value === props.pipeline.value ? {
                            ..._.pick(props.pipeline, _.keys(pipelineVM)),
                            stages: [...stages]
                        } : { ..._.pick(obj, _.keys(pipelineVM)) };
                    })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                    pipelineObj, { merge: true });
            })
        }
        else {
            const pipelineObj = {
                'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === props.pipeline.levelID), function (obj) {
                    return obj.value === props.pipeline.value ? {
                        ..._.pick(props.pipeline, _.keys(pipelineVM)),
                        stages: [...stages]
                    } : { ..._.pick(obj, _.keys(pipelineVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${props.pipeline.levelID}`),
                pipelineObj, { merge: true });
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText:  'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText:  'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                //end
                toast.notify('Stage moved successfully', {
                    duration: 2000
                })
                props.handleClose(props.pipeline)
            })
            .catch(error => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error') 
            });
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const [removed] = stages.splice(result.source.index, 1);
        stages.splice(result.destination.index, 0, removed);
        setStages(stages)
    }



    return _.isEmpty(stages) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rearrange-stage"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row " style={{ height: `${(stages.length * 60) + 30}px` }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="rearrange-stage-wrap">
                                    {
                                        stages.map((rec, index) => {
                                            return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                {(provided, snapshot) => (<div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="stage-rearrange-box stage-flex-queue">
                                                    <div className="stage-rearrange-count"> <span className="badge badge-pill badge-stage-queue ml-1">{index + 1}</span> </div>
                                                    <div className="queue-drag"> <i className="ico icon-drag"></i></div>
                                                    <div className="stage-rearrange-detail">
                                                        <h3>{rec.name} </h3>
                                                        {
                                                            rec.rottenDays > 0 ? (<h5><Translate text={'unattended for'} />&nbsp; {rec.rottenDays} &nbsp;<Translate text={'days'} /></h5>) : (<h5>&nbsp;</h5>)
                                                        }
                                                    </div>
                                                </div>
                                                )}
                                            </Draggable>
                                        })
                                    }

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveStage(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default RearrangeStage;