/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import { enquiryStatus } from '../../../services/enum'
import Translate from '../../../constants/translate';
import Requirements from '../../requirement/list'
import CommonHelper from '../../../services/common';
import { objEnquiry } from '../viewModel';
import { PopUpModal } from "../../../components";
import EnquiryFields from "../enquiryFields";
import enquiryHelper from "../../../services/enquiryHelper";

export default class SubLeadsList extends Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = [];
        this.state = {
            enquiries: [],
            reqSoldIds: [],
            errors: {},
            tempErrors: {},
            enquiryModal: {
                show: false,
                enquiry: null,
                errors: null,
                newStatus: null
            },
            isLoading: false
        }
    }

    componentDidMount() {
        //this.handleValidFields();
        const { enquiries } = this.props;
        this.setState({ enquiries: enquiries }, () => { this.handleValidFields(null, true) });
    }

    handleValidFields = (isScroll, isLoad) => {

        let errors = {};
        let tempErrors = {};
        let formIsValid = true;
        let _key;
        const { mandatoryFields, enquiryOptionsDF } = this.props;
        const { enquiries } = this.state;

        errors = enquiryHelper.getMandatoryErrors(enquiries.filter(e => !e.isPrimary), mandatoryFields, enquiryOptionsDF);

        if (!_.isEmpty(errors)) {
            _key = Object.keys(errors)[0];
            formIsValid = false;
            tempErrors = { ...errors };
            if (enquiries.filter(e => e.isPrimary)[0] && enquiries.filter(e => e.isPrimary)[0].documentID) {
                let _keyID = enquiries.filter(e => e.isPrimary)[0].documentID;
                tempErrors[_keyID] = Object.values(errors)[0];
            }
        }

        //console.log('errors', enquiries, errors, tempErrors)
        if (JSON.stringify(this.state.errors) !== JSON.stringify(errors)) {
            if (isLoad)
                this.setState({ errors: errors, tempErrors: tempErrors });
            else
                this.setState({ errors: errors });
        }


        if (isScroll && _key && document.getElementById(_key) && !_.isEmpty(errors[_key])) {
            document.getElementById(_key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }

        return formIsValid;
    }


    handleSave = () => {
        if (this.handleValidFields(true) === true) {
            const { enquiries } = this.state;
            const { dealersettings, enquiry } = this.props;
            this.setState({ isLoading: true });
            enquiryHelper.handleCreateSubEnquiriesOnWon(dealersettings, enquiries, enquiry).then(() => {
                this.props.handleClose(this.state.enquiries);
            });
        }
    }

    handleEdit = (enq, error) => {

        if (!_.isEmpty(error)) {
            let errors = {};
            error.forEach((key) => {
                errors[key] = 'input_error';
            });

            this.setState({
                enquiryModal: {
                    show: true,
                    errors: errors,
                    enquiry: enq,
                    newStatus: enquiryStatus.WON,
                },
            });
        }
    }

    displayText = (_key) => {
        const { enquiryOptionsDF } = this.props;
        if (!_.isEmpty(enquiryOptionsDF) && _.find(enquiryOptionsDF, { value: _key }))
            return _.find(enquiryOptionsDF, { value: _key }).name
        else
            return _key
    }

    handleEnquiryOptionClose = (_enq) => {
        if (!_.isEmpty(_enq)) {
            let enquiries = Object.assign([], this.state.enquiries);
            enquiries.filter(function (obj) {
                if (obj.documentID === _enq.documentID) {

                    for (let [key, value] of Object.entries(_enq)) {
                        obj[key] = value;
                    }

                    return true;
                }
                return false;
            });
            this.setState({ enquiries: enquiries }, () => { this.handleValidFields() });
        }
        this.setState({
            enquiryModal: {
                show: false,
                errors: null,
                enquiry: null,
                newStatus: null,
            },
        });
    }
    render() {

        const { show, primaryID, title, dealersettings, clientUsers, groupUsers, enquiryAllStatus } = this.props;
        const { reqSoldIds, errors, enquiries, enquiryModal, isLoading, tempErrors } = this.state;
        return (
            <>
                <Modal
                    id="multi-sub-enquiry-list"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-multi-list">
                    <Modal.Header>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="multi-list-wrap">

                                    {
                                        enquiries && enquiries.map((_enquiry, index) => (
                                            <div className={`vehicle-item`} key={index} id={_enquiry.documentID}>
                                                <Requirements
                                                    key={index}
                                                    requirement={_enquiry.requirement}
                                                    handleEdit={() => { }}
                                                    isPrimary={primaryID === _enquiry.documentID ? true : false}
                                                    dealersettings={dealersettings}
                                                    clientUsers={clientUsers}
                                                    groupUsers={groupUsers}
                                                    hideMore={true}
                                                    isMultiple={true}
                                                >
                                                </Requirements>
                                                {
                                                    errors && !_.isEmpty(errors[_enquiry.documentID])
                                                        ?
                                                        <div className="multi-vehicle-validate" onClick={(e) => { e.preventDefault(); this.handleEdit(_enquiry, errors[_enquiry.documentID]) }}>
                                                            <div className="multi-vehicle-validate-alert multi-vehicle-alert-red">
                                                                <b className="alert-link"><Translate text={'Required'} />:</b>
                                                                {
                                                                    errors[_enquiry.documentID].map((_err, index) => {
                                                                        return <><>{index > 0 ? ', ' : ''}</> <Translate key={index} text={this.displayText(_err)} /></>
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            {
                                                                tempErrors && !_.isEmpty(tempErrors[_enquiry.documentID])
                                                                    ?
                                                                    <>
                                                                        <div className="edit-button-mandatory">
                                                                            <a href="#" className="mini-button"
                                                                                onClick={(e) => { e.preventDefault(); this.handleEdit(_enquiry, tempErrors[_enquiry.documentID]) }}>
                                                                                <i className="ico icon-edit"></i>
                                                                            </a>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </>
                                                }
                                                {/* {
                                                    (_.isEmpty(errors) || (!_.isEmpty(errors) && _.isEmpty(errors[_enquiry.documentID]))) && tempErrors && !_.isEmpty(tempErrors[_enquiry.documentID])
                                                        ?
                                                        <>
                                                            <div className="edit-button-mandatory">
                                                                <a href="#" className="mini-button"
                                                                    onClick={(e) => { e.preventDefault(); this.handleEdit(_enquiry, tempErrors[_enquiry.documentID]) }}>
                                                                    <i className="ico icon-edit"></i>
                                                                </a>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                } */}
                                            </div>
                                        ))
                                    }

                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer >
                        <button type="button"
                            className={`btn btn-default float-left ${(isLoading) ? 'btn-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}>
                            <Translate text={'cancel'} />
                        </button>
                        <button type="button"
                            className={`btn btn-common button-blue float-right ${(!_.isEmpty(errors) || isLoading) ? 'btn-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleSave();
                            }}>
                            {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'create'} />
                        </button>
                    </Modal.Footer>
                </Modal>


                <PopUpModal show={enquiryModal.show}>
                    <EnquiryFields
                        title='enquiryInfo'
                        show={enquiryModal.show}
                        errors={enquiryModal.errors}
                        newStatus={enquiryModal.newStatus}
                        mandatoryFields={enquiryModal.mandatoryFields}
                        enquiry={enquiryModal.enquiry}
                        contact={enquiryModal.enquiry?.contact}
                        canModifyEnquiry={true}
                        enquiryID={enquiryModal.enquiry?.documentID}
                        //requirement={this.state.fields.requirement}
                        //requirements={this.state.requirements}
                        enquiryAllStatus={enquiryAllStatus}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        handleClose={this.handleEnquiryOptionClose}
                        handleEditRequirement={() => { }}
                        isDontSave={true}
                    />
                </PopUpModal>
            </>


        );
    }
}

