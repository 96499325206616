import React from 'react';
import _ from 'lodash'

const DefaultFilters = (props) => {
    const { handleSelectFilter, localFilter, _filter } = props;

    return (
        <>
            <li onClick={(e) => { e.preventDefault(); handleSelectFilter(_filter); }}>
                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                    {
                        (!_.isEmpty(localFilter) && _filter.name === localFilter.name && localFilter.type === 'default')
                            ?
                            <span className="quick-filter-check"><i className="ico icon-check"> </i></span>
                            :
                            <>
                                {
                                    (localFilter && _.isEmpty(localFilter) && _.isEmpty(_filter.value))
                                        ?
                                        <span className="quick-filter-check"><i className="ico icon-check"> </i></span>
                                        :
                                        <></>

                                }
                            </>
                    }
                    <span className="quick-filter-icon"><i className="ico icon-lock"></i></span> {_filter.name}
                </a>
            </li>
        </>
    )
}

export default DefaultFilters