import React, { useEffect, useRef } from 'react';
import SearchPanel from './searchPanel';

const SearchContainer = ({
    showSearchPanel,
    searchText,
    dealersettings,
    handleClose,
    handleSearchClose,
    history,
    className,
    clientUsers,
    groupUsers,
    clientID
}) => {
    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleSearchClose()
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(wrapperRef)

    return showSearchPanel && (
        <div className='dropdown' style={{ position: 'inherit' }} ref={wrapperRef}>

            <SearchPanel
                history={history}
                showSearchPanel={showSearchPanel}
                dealersettings={dealersettings}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
                searchText={searchText}
                handleClose={handleClose}
                className={className}
                clientID={clientID}
            />

        </div>
    );
};

export default SearchContainer;
