export const triggerVM = {
    documentID: null,
    clientID: '',
    category: '',
    title: '',
    subject: '',
    status: '',
    triggerType: '',
    todoType: '',
    templateID: null,
    make: '',
    model: '',
    bodyType: '',
    modelGroup: '',
    saleType: '',
    recipientType: '',
    replyType: '',
    ccType: '',
    bccType: '',
    recipientTo: '',
    mailBcc: '',
    replyTo: '',
    period: '',
    periodValue: 0,
    periodEvery: 0,
    periodEveryType: '',
    startAfter: 0,
    startAfterType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    isActive: true,
    notes: '',
    triggerFor: '',
    pipeline: '',
    stage: '',
    level: '',
    levelID: '',
    subLevelIDs: '',
    regionIDs: [],
    groupIDs: [],
    clientIDs: [],
    teammates: [],
    oemID: null,
    pipelines: [],
    stages: [],
    lostReasons: [],
    settingsID: null,
    enquiryType: [],
    campaign: [],
    origin: [],
    mailCc: '',
    ccType: '',
    module: '',
    triggerFeedback: '',
    callType: '',
    callStatus: '',
    fieldName: null,
    fieldValue: null,
    triggerAfter: null,
    cron: null,
    recurrence: null,
    frequency: null,
    day: null,
    date: null,
    month: null,
    multiple: null,
    schedulartime: null,
    stageTo: null,
    stageList: null,
    modelYearOP: null,
    modelYear: null,
    senderName: null,
    customTime: null,
    tradeinDone: null,
    financeStatus: null,
    afterMarketStatus: null,
    leadSource: null,
    startFromField: null,
    fieldOP: null
}


export const triggerCategory = [
    {
        name: "Activity",
        value: "activity",
        class: "ico icon-trigger-activity"
    },
    {
        name: "Log",
        value: "log",
        class: "ico icon-note"
    },
    {
        name: "Contact",
        value: "contact",
        class: "ico icon-trigger-contact"
    },
    {
        name: "Company",
        value: "company",
        class: "ico icon-trigger-company"
    },
    {
        name: "Enquiry",
        value: "enquiry",
        class: "ico icon-trigger-enquiry"
    },
    {
        name: "Inbound Lead",
        value: "inboundLead",
        class: "ico icon-inbound"
    },
    {
        name: "Test Drive",
        value: "testdrive",
        class: "ico icon-trigger-testdrive"
    },
    {
        name: "Trade-In",
        value: "tradein",
        class: "ico icon-trigger-tradein"
    },
    {
        name: "Loan Vehicle",
        value: "loanvehicle",
        class: "ico icon-trigger-fleet"
    }


];

export const eventTypes = [
    {
        active: true,
        name: "Contact Created",
        status: "contactCreated",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS once a contact is created.",
        label: "Contact Created Date"
    },
    {
        active: true,
        name: "Contact Field Update",
        status: "contactfieldUpdate",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS once a contact field is updated.",
        label: "Contact Modified Date"
    },
    {
        active: true,
        name: "Contact Birthday",
        status: "contactBirthday",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS to be triggered for the contact’s birthday.",
        label: "Contact Birthday",
    },
    // {
    //     active: true,
    //     name: "Recurrence",
    //     status: "contactRecurrence",
    //     value: "contact",
    //     desc: "Schedule an Activity, Email or SMS to be triggered on recurrence.",
    //     label: "Contact Created Date",
    //     recurrence: true
    // },
    {
        active: true,
        name: "Company Created",
        status: "companyCreated",
        value: "company",
        desc: "Schedule an Activity, Email or SMS once a company is created",
        label: "Company Created Date",
    },
    {
        active: true,
        name: "Enquiry Created",
        status: "enquiryCreated",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once an enquiry is created.",
        label: "Enquiry Created Date",
    },
    {
        active: true,
        name: "Pipeline/Stage Update",
        status: "enquiryUpdate",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS on pipeline/stage update.",
        label: "Enquiry Modified Date",
    },
    {
        active: true,
        name: "Customer Walkin",
        status: "firstWalkin",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS on customer walkin.",
        label: "Customer Walkin Date",
    },
    // hidden for now
    // {
    //     name: "Proposal Done",
    //     status: "proposalDone",
    //     value: "enquiry",
    //     desc: "Lorem ipsum dolor sit amet, consectetur.",
    //     label: "Proposal Done Date",
    // },
    {
        active: true,
        name: "Enquiry Won",
        status: "enquiryWon",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once an enquiry is marked as Won.",
        label: "Enquiry Won Date",
        statusValue: 'Won'
    },
    {
        active: true,
        name: "Enquiry Delivery Scheduled",
        label: "Enquiry Delivery Date",
        status: "enquiryDeliveryScheduled",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once an enquiry delivery is scheduled."
    },
    {
        active: true,
        name: "Enquiry Delivered",
        label: "Enquiry Delivery Date",
        status: "enquiryDelivered",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once an enquiry is marked as Delivered.",
        statusValue: 'Delivered'
    },
    {
        active: true,
        name: "Enquiry Lost",
        label: "Enquiry Lost Date",
        status: "enquiryLost",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once an enquiry is marked as Lost.",
        statusValue: 'Lost'
    },
    {
        active: true,
        name: "Warranty Expiry",
        label: "Warranty Expiry Date",
        status: "warrantyExpiry",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS on Warranty Expiry."
    },
    {
        active: true,
        name: "Contact / Enquiry Field Update",
        status: "enquiryContactFieldUpdate",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS once a contact field is updated.",
        label: "Contact Modified Date"
    },
    {
        active: true,
        name: "Finance Status Change",
        status: "financeStatus",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS to be triggered on finance status change.",
        label: "Enquiry Modified Date",
    },
    {
        active: true,
        name: "After Market Status Change",
        status: "afterMarketStatus",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS to be triggered on after market status change.",
        label: "Enquiry Modified Date",
    },
    {
        active: true,
        name: "Recurrence",
        status: "enquiryRecurrence",
        value: "enquiry",
        desc: "Schedule an Activity, Email or SMS to be triggered on recurrence.",
        label: "Enquiry Won Date",
        recurrence: true
    },
    {
        active: true,
        name: "Inbound Lead Received",
        status: "inboundLeadCreated",
        value: "inboundLead",
        desc: "Schedule an Email or SMS once an inbound lead is received.",
        label: "Inbound Received Date",
    },
    {
        active: true,
        name: "Test Drive Started",
        label: "Test Drive Started Date",
        status: "testdriveStarted",
        value: "testdrive",
        desc: "Schedule an Activity, Email or SMS once a Test Drive is started."
    },
    {
        active: true,
        name: "Test Drive Completed",
        label: "Test Drive Completed Date",
        status: "testdriveCompleted",
        value: "testdrive",
        desc: "Schedule an Activity, Email or SMS once a Test Drive is completed."
    },
    {
        active: true,
        name: "Trade-In Created",
        label: "Trade-In Created Date",
        status: "tradeinCreated",
        value: "tradein",
        desc: "Schedule an Activity, Email or SMS once a Trade-In is created."
    },
    {
        active: true,
        name: "Evaluation Sent",
        label: "Evaluation Sent Date",
        status: "evaluationSent",
        value: "tradein",
        desc: "Schedule an Activity, Email or SMS once an evaluation is sent."
    },
    {
        active: true,
        name: "Trade-In Won",
        label: "Trade-In Won Date",
        status: "tradeinWon",
        value: "tradein",
        desc: "Schedule an Activity, Email or SMS once a Trade-In is Won."
    },
    {
        active: true,
        name: "Trade-In Lost",
        label: "Trade-In Lost Date",
        status: "tradeinLost",
        value: "tradein",
        desc: "Schedule an Activity, Email or SMS once a Trade-In is Lost."
    },
    {
        active: true,
        name: "Appointment Scheduled",
        label: "Appointment Scheduled Date",
        status: "appointmentScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is scheduled."
    },
    {
        active: true,
        name: "Appointment Completed",
        label: "Appointment Completed Date",
        status: "appointmentCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is completed."
    },
    {
        active: true,
        name: "Test Drive Scheduled",
        label: "Test Drive Scheduled Date",
        status: "testdriveActivityScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Test Drive is scheduled."
    },
    {
        active: true,
        name: "Test Drive Completed",
        label: "Test Drive Completed Date",
        status: "testdriveActivityCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Test Drive is completed."
    },
    {
        active: true,
        name: "Call Scheduled",
        label: "Call Scheduled Date",
        status: "callScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is scheduled."
    },
    {
        active: true,
        name: "Call Completed",
        label: "Call Completed Date",
        status: "callCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is completed."
    },
    {
        active: true,
        name: "Email Scheduled",
        label: "Email Scheduled Date",
        status: "emailScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is scheduled."
    },
    {
        active: true,
        name: "Email Completed",
        label: "Email Completed Date",
        status: "emailCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is completed."
    },
    {
        active: true,
        name: "Delivery Scheduled",
        label: "Delivery Scheduled Date",
        status: "deliveryScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is scheduled."
    },
    {
        active: true,
        name: "Delivery Completed",
        label: "Delivery Completed Date",
        status: "deliveryCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is completed."
    },
    {
        active: true,
        name: "Vehicle Handover",
        label: "Vehicle Handover Date",
        status: "vehicleHandover",
        value: "loanvehicle",
        desc: "Schedule an Activity, Email or SMS once a vehicle is handed over."
    },
    {
        active: true,
        name: "Vehicle Return",
        label: "Vehicle Return Date",
        status: "vehicleReturn",
        value: "loanvehicle",
        desc: "Schedule an Activity, Email or SMS once a vehicle is returned."
    },
    {
        active: true,
        name: "Call Log Completed",
        label: "Call Completed Date",
        status: "callLogCompleted",
        value: "log",
        desc: "Add an Activity, Email or SMS trigger once a call log is completed."
    },
];

export const modelYearOptions = [
    { active: true, value: '===', label: 'equal' },
    { active: true, value: '>=', label: 'grater than or equal' },
    { active: true, value: '<=', label: 'less than or equal' },
    { active: true, value: '>', label: 'greater than' },
    { active: true, value: '<', label: 'less than' }
]

export const saleTypes = [
    { active: true, value: 'New', label: 'New' },
    { active: true, value: 'Demo', label: 'Demo' },
    { active: true, value: 'Preowned', label: 'Preowned' }
]

export const mailtotypes = [
    { active: true, value: 'contact', label: 'Contact' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'user', label: 'Users' },
    { active: true, value: 'financeBM', label: 'Business Manager' },
    { active: true, value: 'afterMarketBM', label: 'After Market Manager' },

]

export const tiggerFrequency = [
    { active: true, value: 'instant', label: 'Instant', name: 'Instant' },
    { active: true, value: 'afterMins', label: 'Minutes (No.in Minutes)', name: 'Minutes' },
    { active: true, value: 'afterHours', label: 'Hours (No.in Hours)', name: 'Hours' },
    { active: true, value: 'afterDays', label: 'Days (No.in Days)', name: 'Days' },
    { active: true, value: 'iteration', label: 'Iteration', name: 'Iteration' },
    { active: true, value: 'birthday', label: 'Birthday', name: 'Birthday' },
    { active: true, value: 'customTime', label: 'Custom Time', name: 'Custom Time' }
]

export const frequencyOptions = [
    { active: true, value: 'before', label: 'Before' },
    { active: true, value: 'after', label: 'After' }
]

export const priodEveryTypes = [
    { active: true, value: 'day', label: 'Day' },
    { active: true, value: 'month', label: 'Month' },
    { active: true, value: 'year', label: 'Year' }
]

export const triggerTypes = [
    { active: true, value: 'activity', label: 'Activity' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'sms', label: 'SMS' }
]


export const triggerlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Event Type',
        value: 'strStatus',
        sortValue: 'strStatus',
        flex: 1,
        default: true
    },
    // {
    //     name: 'Trigger Type',
    //     value: 'strtriggerType',
    //     flex: 1,
    //     default: true
    // },
    {
        name: 'Triggered On',
        value: 'strtriggerOn',
        sortValue: 'strtriggerOn',
        flex: 1,
        default: true,
        subText: 'strtriggerType'
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',
        sortValue: 'strlevels',
        flex: 1,
        default: true,
        subText: 'levelValue'
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        flex: 1,
        default: true,
        subText: 'createdBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        flex: 1,
        default: true,
        subText: 'updatedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Active',
        value: 'isActive',
        sortValue: 'isActive',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const replyTypes = [
    { active: true, value: 'owner', label: 'Owner' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'ownerandcustom', label: 'Owner & Custom' },
]

export const contactFieldNames = [
    { active: true, value: 'areaCode', label: 'Area/Zip Code', status: ['contactfieldUpdate', 'enquiryContactFieldUpdate'] },
    { active: true, value: 'prevPurchased', label: 'Prev. Purchased Alert', status: ['contactfieldUpdate', 'enquiryContactFieldUpdate'] },
    { active: true, value: 'creditScore', label: 'Finance Credit Score', status: ['contactfieldUpdate', 'enquiryContactFieldUpdate'] },
    { active: true, value: 'financeBM', label: 'Finance Business Manager', status: ['enquiryContactFieldUpdate'] },
    { active: true, value: 'afterMarketBM', label: 'Aftermarket Manager', status: ['enquiryContactFieldUpdate'] },
]


export const recurringOptions = [
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' },
    { active: true, value: 'yearly', label: 'Yearly' },
]

export const triggerDateOptions = [
    { active: true, value: 'addedDate', label: 'Contact Creation Date', category: 'contact' },
    { active: true, value: 'addedDate', label: 'Enquiry Creation Date', category: 'enquiry' },
    { active: true, value: 'stageDate', label: 'Enquiry Stage Date', category: 'enquiry' },
    { active: true, value: 'wonDate', label: 'Enquiry Won Date', category: 'enquiry' },
    { active: true, value: 'deliveredDate', label: 'Enquiry Delivered Date', category: 'enquiry' },
    { active: true, value: 'lostDate', label: 'Enquiry Lost Date', category: 'enquiry' }
]