/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _, { isEmpty } from 'lodash'
import Swal from 'sweetalert2';
import { default as _images } from '../../../images';

/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopoverListLoader } from '../../../components';
import TradeinInfo from '../tradeinInfo'
import { objTradeIn } from '../../tradeIn/viewModel'
import { tipStatus } from '../../../services/enum'
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';

class PopoverList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tradeIns: [],
            errors: {},
            loading: true
        };
    }

    componentDidMount() {
        const { dealersettings, enquiryID, isReopen } = this.props;
        if (dealersettings && enquiryID) {

            let _status = isReopen ? [tipStatus.WON, tipStatus.LOST] : [tipStatus.COMPLETED]
            firestoreDB(dealersettings).firestore().collection('tradeins')
                .where('enquiryID', '==', this.props.enquiryID)
                .where('status', 'in', _status)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    const tradeIns = [];
                    response.docs.forEach((doc) => {
                        let _enq = Object.assign({}, objTradeIn);
                        for (let [key, value] of Object.entries(doc.data())) {
                            if (_enq.hasOwnProperty(key))
                                _enq[key] = value;
                        }
                        _enq.documentID = doc.id;
                        tradeIns.push(_enq)
                    });

                    this.setState({
                        tradeIns: _.orderBy(tradeIns.filter(e => !_.isEmpty(e.tradeinProID) && _status.includes(e.status)), ["modifiedDate"], ["desc"]),
                        loading: false
                    });

                });
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    componentWillUnmount() {
        //this.unsubscribeENQZ && this.unsubscribeENQZ();
    }

    handleStatusChange = async (tradeinid, _status, _finalPrice) => {
        let tradeIns = this.state.tradeIns
        let errors = this.state.errors
        errors[tradeinid] = '';

        tradeinid && _status && tradeIns && tradeIns.forEach(_data => {
            if (_data.documentID === tradeinid) {
                _data.status = _status;
                if (_.isNumber(_finalPrice)) _data.offerPrice = _finalPrice;
            }

        })
        this.setState({
            tradeIns: _.orderBy(tradeIns, ["modifiedDate"], ["desc"]),
            errors: errors,
            loading: false
        });
    }

    handleDone = () => {
        const { isReopen, isMandatory } = this.props;
        const { tradeIns } = this.state;
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_upload_error';
        let _status = isReopen ? [tipStatus.WON, tipStatus.LOST] : [tipStatus.COMPLETED]
        tradeIns && tradeIns.forEach(_data => {
            if (isMandatory && _status.includes(_data.status)) {
                formIsValid = false;
                errors[_data.documentID] = errorClass;
            }
        })

        this.setState({ errors: errors });

        if (formIsValid === true)
            this.props.handleClose();
        else
            return false;
    }

    render() {

        const { tradeIns, loading, errors } = this.state
        const { show, dealersettings, groupUsers, clientUsers, isMandatory, isReopen } = this.props;

        const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
        const divStyle = {
            height: '420px',
            overflow: 'auto'
        };

        return (
            <Modal
                id="tradein-completed-list"
                show={show}
                onEscapeKeyDown={false}
                onHide={this.props.handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton={isMandatory ? false : true}>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'tradeIn'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className="service-loan-yellow-alert pb-3">
                            <div className="alert-warning pt-2 pb-2" role="alert">
                                <div className="service-alert-flex">
                                    <div className="service-alert-flex-data pl-2">
                                        {
                                            isReopen
                                                ?
                                                <p>{`Would you like to re-open the appraisal(s).`}</p>
                                                :
                                                <p>{`Please mark these trade-ins ${CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.WON, tipStatus.WON)} or ${CommonHelper.getNameByValue(tradeinProAllStatus, tipStatus.LOST, tipStatus.LOST)}`}</p>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={divStyle} >
                            {
                                loading ? (<PopoverListLoader />) :
                                    (tradeIns && tradeIns.length > 0)
                                        ?
                                        <>
                                            {tradeIns.map((tradeIn, index) =>
                                                <div key={index} className={`pb-0`} >
                                                    <TradeinInfo
                                                        tradein={tradeIn}
                                                        errors={errors}
                                                        handleEditTradeIn={() => { }}
                                                        handleReSendLink={() => { }}
                                                        dealersettings={dealersettings}
                                                        clientUsers={clientUsers}
                                                        groupUsers={groupUsers}
                                                        hideMore={true}
                                                        companyView={true}
                                                        isStatusChange={true}
                                                        isReopen={isReopen}
                                                        handleStatusChange={this.handleStatusChange}
                                                    >
                                                    </TradeinInfo>
                                                </div>
                                            )}</>
                                        :
                                        <>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-cafe-img">
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-tradein-module-icon")}></i> </div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'noTradeinFound'} /></div>
                                                </div>

                                            </div>
                                        </>
                            }
                        </div>

                    </>
                </Modal.Body>
                <Modal.Footer >
                    {
                        isMandatory
                            ?
                            <></>
                            :
                            <><button type="button"
                                className="btn btn-default float-left"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleClose();
                                }}>
                                <Translate text={'cancel'} />
                            </button></>
                    }

                    <button type="button"
                        className={`btn button-blue float-right `}
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleDone();
                        }}>

                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>


        );
    }

}

export default PopoverList;