import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ReactSelect, PopUpModal, ContentOverlay } from '../../../../components'
import Translate from '../../../../constants/translate';
import AddDepartment from './addDepartment'
import CommonHelper from '../../../../services/common'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';
import { levelOptions, departmentVM } from './viewModel'
import { ClientContext } from '../clientContext'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';

const UserDepartments = (props) => {
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [departmentLoader, setDepartmentloader] = useState(false)
    const [showAddDepartmentModal, setShowAddDepartmentModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editDepartment, setEditDepartment] = useState(null)
    const [oemDepartment, setOEMDepartment] = useState([])
    const [clientUsers, setClientUsers] = useState([])
    const [userLoader, setUserloader] = useState(false)
    const departmentFields = ['departments'];
    const [searchText, setSearchText] = useState('')
    const [selectedClient, setSelectedClient] = useState('')
    const [clientOptions, setClientOptions] = useState([]);
    const levelAccess = _.map((props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index), function (obj) {
            return obj.value;
        })
    const { clients } = useContext(ClientContext);

    useEffect(() => {
        if (props.activeKey === 'clients') {
            function handleResize() {
                //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
                document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            }
            window.addEventListener('resize', handleResize);
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
            document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }) ? _.find(clients, { 'documentID': props.dealersettings.client.id }) : clients[0])
            }
        }
    }, [clients])


    useEffect(() => {

        var oemsettings = combineLatest(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`)))
            .pipe(
                map((settings) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups] = settings;
                    return [
                        //spread the arrays out to combine as one array
                        { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id },
                    ];

                }))
            .subscribe(allDocs => {
                var lookups = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => lookups.push(e)) : lookups.push(rec) })
                let settingsDoc = [];
                lookups.forEach(rec => {
                    departmentFields.forEach((key) => {
                        if (rec[key]) {
                            rec[key].forEach((_department) => {
                                settingsDoc.push({
                                    ..._department,
                                    'active': (_.find(oemDepartment, { 'value': _department.value }) ? _.find(oemDepartment, { 'value': _department.value }).active : false)
                                });
                            })
                        }
                    })
                })
                if (settingsDoc.length > 0 && !settingsDoc.some(item => item.active === true)) {
                    _.find(settingsDoc, { 'value': settingsDoc[0].value }).active = true;

                }
                setSelectedDepartment(settingsDoc.find(item => item.active === true))
                setOEMDepartment(settingsDoc)
                setDepartmentloader(false)
            });
        return () => {
            oemsettings && oemsettings.unsubscribe()
        }
    }, [])

    useEffect(() => {
        //if (!_.isEmpty(selectedClient)) {
        //    getClientUsers()
        //}
        setUserloader(true)
        const clientUserSnapshot = window.firebase.firestore().collection('users')
            .where('settingsID', '==', props.dealersettings.oemID)
            .onSnapshot(querysnapshot => {
                let users = []
                querysnapshot.forEach((doc) => {
                    const _user = {
                        ...doc.data(),
                        documentID: doc.id
                    }
                    if (_user.isDisabled !== true && levelAccess.some(e => e === _user.level) && _user.arrayClientID && _user.arrayClientID.length > 0 && CommonHelper.arrayContainsArray(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), _user.arrayClientID)) {
                        users.push({
                            id: doc.id,
                            name: _user.name,
                            email: _user.email,
                            profileImage: _user.profileImage,
                            arrayClientID: _user.arrayClientID,
                            department: _user.department
                        })
                    }
                    else if (_.isEmpty(_user.arrayClientID) && !_.isEmpty(_user.settingsID)) {
                        users.push({
                            id: doc.id,
                            name: _user.name,
                            email: _user.email,
                            profileImage: _user.profileImage,
                            arrayClientID: _user.arrayClientID,
                            department: _user.department
                        })
                    }
                });
                setClientUsers(users)
                setUserloader(false)
            });

        return () => {
            clientUserSnapshot && clientUserSnapshot()
        }
    }, [])


    const handleDepartmentChange = (department) => {
        if (!_.isEmpty(department)) {
            setOEMDepartment([..._.forEach(oemDepartment, function (obj) {
                if (obj.value === department.value)
                    _.set(obj, 'active', true);
                else
                    _.set(obj, 'active', false);
            })]);
            setSelectedDepartment(department)
        }
    }

    const handleModelClose = () => {
        setShowAddDepartmentModel(false);
    }

    const handleeditDepartment = (department) => {
        setPopHeader('Edit Department')
        setEditDepartment(department)
        setShowAddDepartmentModel(true)
    }

    const handledeleteDepartment = async (department) => {
        if (clientUsers.filter(item => item.department === department.value).length > 0) {
            Swal.fire(CommonHelper.showLocale(props, 'Department cannot be removed as it has been assigned to other users.'), '', 'error')
            return;
        }

        var checkdepartmentUsers = await window.firebase.firestore().collection('users')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .where('department', '==', department.value)
            .limit(1)
            .get();
        if (checkdepartmentUsers.docs.length > 0) {
            Swal.fire(CommonHelper.showLocale(props, 'Department cannot be removed as it has been assigned to other dealer users.'), '', 'error')
            return;
        }

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                const departmentObj = {
                    ['departments']: [..._.map(oemDepartment.filter(item => item.value !== department.value), function (obj) {
                        return { ..._.pick(obj, _.keys(departmentVM)) };
                    })]
                }
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`)
                    .set(departmentObj, { merge: true })
                    .then(snapshot => {
                        //update settings date and priority
                        //if (!_.isEmpty(props.selectedClient)) {
                        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                            .set({
                                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                settingsUpdatePriority: 'normal',
                                settingsUpdateBy: props?.dealersettings?.name || null,
                                settingsUpdateText: 'Department Settings has been updated',
                            }, { merge: true })
                        //}
                        //end 
                        toast.notify('Department deleted successfully', {
                            duration: 2000
                        })
                        let logNotes = `${department.name} Department Deleted`
                        // let clientIDs = !_.isEmpty(objTrigger.clientIDs) ? objTrigger.clientIDs : objTrigger.clientID ? [objTrigger.clientID] : ''
                        let _objLogData = {
                            notes: logNotes,
                            type: 'dynamicFields',
                            subType: 'deleted',
                            recordId: department.value,
                            oemID: props.dealersettings.client.settingsID ? props.dealersettings.client.settingsID : '',
                            regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                            groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                            logBy: localStorage.uid,
                            logDate: window.firebase.firestore.Timestamp.now(),
                            platform: 'web',
                            documentID: window.firebase.firestore().collection('auditLogs').doc().id,
                            clientID: props.dealersettings.client.id
                        }
                        window.firebase.firestore().doc(`auditLogs/${_objLogData.documentID}`).set(_objLogData, { merge: true }).catch(error => {
                            console.error(error);
                        });
                    });
            }
        })
    }

    const handleClientUserChange = (e) => {
        setClientUsers([{ ...e.doc, 'department': selectedDepartment.value }, ...clientUsers.filter(item => item.id !== e.value)]);
        window.firebase.firestore().doc(`users/${e.value}`).set({ 'department': selectedDepartment.value }, { merge: true })
        e.doc.arrayClientID.forEach(clientid => {
            window.firebase.firestore().doc(`users/${e.value}/userClients/${clientid}`).set({ 'department': selectedDepartment.value }, { merge: true })
        })


        //update settings date and priority
        //if (!_.isEmpty(selectedClient)) {
        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
            .set({
                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                settingsUpdatePriority: 'normal',
                settingsUpdateBy: props?.dealersettings?.name || null,
                settingsUpdateText: 'User Department Settings has been updated',
            }, { merge: true })
        //}
        //end
    }

    const handleswitchDepartment = async (department, user) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'User is currently active in the Queue!'),
            text: CommonHelper.showLocale(props, 'Are you sure, you want to remove the user from the Queue.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                setClientUsers([{ ...user, 'department': department.value }, ...clientUsers.filter(item => item.id !== user.id)]);
                user.arrayClientID.forEach(clientid => {
                    window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({ isQueue: false, queueNum: 0 }, { merge: true })
                })
                window.firebase.firestore().doc(`users/${user.id}`).set({ 'department': department.value }, { merge: true })
                user.arrayClientID.forEach(clientid => {
                    window.firebase.firestore().doc(`users/${user.id}/userClients/${clientid}`).set({ 'department': department.value }, { merge: true })
                })

                //update settings date and priority
                //if (!_.isEmpty(selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'User Department Settings has been updated',
                    }, { merge: true })
                //}
                //end
            }
        })

    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = oemDepartment.splice(result.source.index, 1);
        oemDepartment.splice(result.destination.index, 0, removed);
        setOEMDepartment(oemDepartment)

        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`).set({
            departments: [..._.map(oemDepartment, function (obj) {
                return { ..._.pick(obj, _.keys(departmentVM)) };
            })]
        }, { merge: true })
            .then(snapshot => {
                toast.notify('Department updated successfully', {
                    duration: 2000
                })
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'User Department Settings has been updated',
                    }, { merge: true })
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return (
        <>
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            {
                                props.dealersettings.clients.map(v => _.pick(v, ['id']).id).length > 0 ? (
                                    <div className="col-md-4">
                                        <ReactSelect
                                            options={clientOptions}
                                            name={"clientID"}
                                            placeholder={'select client'}
                                            onChange={(e, data) => {
                                                if (e) {
                                                    //setLoader(true)
                                                    setSelectedClient(e.data)
                                                }
                                                else {
                                                    setSelectedClient(null)
                                                }
                                            }}
                                            value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                ) : (<></>)
                            }
                            <div className="filter-search search-icon col-md-4" style={{ marginLeft: '-15px' }}>
                                <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-column-wraper">
                <div className="settings-columns">
                    <div className="settings-column-left" style={{ width: `430px` }}>
                        <div className="settings-column-title">
                            <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setEditDepartment(null); setShowAddDepartmentModel(true); setPopHeader('Add Department') }}> <i className="ico icon-add"></i></a></div>
                            <h3><Translate text={'Departments'} /></h3>
                        </div>
                        <div className="settings-column-nav">
                            {
                                departmentLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (<ul
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {
                                                    oemDepartment.map((rec, index) => {
                                                        return <Draggable key={rec.value} draggableId={rec.value} index={index}>
                                                            {(provided1, snapshot1) => (<li
                                                                ref={provided1.innerRef}
                                                                {...provided1.draggableProps}
                                                                {...provided1.dragHandleProps}
                                                                key={index} className={`${rec.active === true ? 'active' : snapshot1.isDragging ? 'drag-active' : ''}`}>
                                                                {
                                                                    rec.value === 'Management' ? (
                                                                        <div className="role-list-items-lock"> <i className="ico icon-lock"></i> </div>
                                                                    ) : (

                                                                        <Dropdown className="role-list-items-more" alignRight>
                                                                            <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                                                <i className="ico icon-more"></i>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                                <Dropdown.Item eventKey="delete" onClick={(e) => { handleeditDepartment(rec) }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
                                                                                <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteDepartment(rec) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    )
                                                                }
                                                                <div className='drag-department-left'><i className="ico icon-drag"></i></div>
                                                                <a href="#" className="column-list-left"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleDepartmentChange(rec);
                                                                    }}
                                                                >{rec.name}
                                                                </a>
                                                                {provided1.placeholder}
                                                            </li>
                                                            )}
                                                        </Draggable>
                                                    })
                                                }
                                                {provided.placeholder}
                                            </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )}
                        </div>
                    </div>
                    <div className="settings-column-right-large">
                        <div className="settings-column-title">
                            <h3><Translate text={'users'} /></h3>
                        </div>
                        {
                            !_.isEmpty(selectedDepartment) ? (
                                <>
                                    <div className="user-list">
                                        <p><Translate text={'Add users to'} /> '{selectedDepartment.name}'</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ReactSelect
                                                    options={_.map(clientUsers.filter(item => _.isEmpty(item.department)), function (c) {
                                                        return {
                                                            value: c.documentID,
                                                            doc: c,
                                                            searchlabel: c.name,
                                                            label: (
                                                                <div className='drop-image'>
                                                                    <img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" width="50" className="img-object-fit" />
                                                                    {c.name}
                                                                </div>
                                                            ),
                                                            active: _.isBoolean(c.active) ? c.active : true,
                                                        };
                                                    })}
                                                    name={"clientID"}
                                                    placeholder={'select user'}
                                                    onChange={handleClientUserChange}
                                                    value={''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        userLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :

                                            clientUsers.length === 0 ? (<></>) : (<div className="user-list-items">
                                                <ul>
                                                    {
                                                        clientUsers.filter(item =>
                                                            item.department === selectedDepartment.value
                                                            && (!_.isEmpty(selectedClient) ? item.arrayClientID.some(e => e === selectedClient.documentID) : true)
                                                            && (!_.isEmpty(searchText) ? (
                                                                (!_.isEmpty(item.name) ? item.name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                                (!_.isEmpty(item.phone) ? item.phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                                (!_.isEmpty(item.email) ? item.email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                                            ) : true)
                                                        ).map((rec, index) => {
                                                            return <li key={index} className="user-list-box">
                                                                <Dropdown className="user-list-items-more" drop={'left'}>
                                                                    <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                                        <i className="ico icon-more"></i>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                        <Dropdown.Header className='nav-item-header nav-item text-left'>
                                                                            <Translate text={'Change Department'} />
                                                                        </Dropdown.Header>
                                                                        {
                                                                            oemDepartment.filter(item => item.active === false).map((_dept, index) => {
                                                                                return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleswitchDepartment(_dept, rec) }}> {_dept.name} </Dropdown.Item>
                                                                            })
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <div className="user-list-items-image">
                                                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" className="rounded-circle img-object-fit" />
                                                                </div>
                                                                <div className="user-list-items-info">
                                                                    <h4>{rec.name}</h4>
                                                                    <h5>{rec.email}</h5>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>)
                                    }
                                </>) : (<></>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showAddDepartmentModal}>
                <AddDepartment
                    {...props}
                    show={showAddDepartmentModal}
                    title={popHeader}
                    handleClose={handleModelClose}
                    department={editDepartment}
                    allDepartments={oemDepartment}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
        </>
    );
}

export default UserDepartments;