import React, { Component } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";

import ServiceTypeCounts from './serviceTypeCounts'
import AppointmentTypeCounts from './appointmentTypeCounts'
import StatusCounts from './statusCounts';
import JobCounts from './jobCounts'
import ServiceRevenueCounts from './revenueCounts'
import ActivitiesCount from './activitiesCount'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            mounted: false,
            layouts: { lg: props.initialLayout }
        };

        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialLayout !== this.state.layouts) {
            this.setState({ layouts: { lg: nextProps.initialLayout } });
        }
    }

    onBreakpointChange(breakpoint) {
        this.setState({
            currentBreakpoint: breakpoint
        });
    }

    onLayoutChange(layout, layouts) {
        this.props.onLayoutChange(layout, layouts);
    }

    calculateHeight(sectionID, layoutArr) {
        let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
        if (selectedSection.length === 0)
            return 30
        let columnHeight = Number(selectedSection[0].h)
        if (columnHeight === 1) {
            return 30
        }
        columnHeight = columnHeight - 1
        columnHeight = columnHeight * 45
        columnHeight = columnHeight + 30

        return columnHeight
    }

    filterLayouts = (layouts) => {

        if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
            if (layouts.lg[0].i === '0') {
                layouts.lg.shift()
            }
        }
        return layouts
    }


    render() {

        const {
            handleShowEnquiryList,
            selectedPipeline,
            selectedUser,
            dateRange,
            applydateRange,
            loader,
            pipeline,
        } = this.props;

        return (
            <div>
                <ResponsiveReactGridLayout
                    {...this.props}
                    layouts={this.filterLayouts(this.state.layouts)}
                    onBreakpointChange={this.onBreakpointChange}
                    onLayoutChange={this.onLayoutChange}
                    measureBeforeMount={false}
                    useCSSTransforms={this.state.mounted}
                    compactType={this.state.compactType}
                    preventCollision={!this.state.compactType}
                    margin={[15, 15]}
                    isResizable={true}
                    draggableHandle='.draggable-section'
                    draggableCancel='.dashboard-box-body'
                >

                    <div key={'LeadsByAppointmentType'} id={'LeadsByAppointmentType'}>
                        <AppointmentTypeCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            loader={loader}
                            dateRange={dateRange}
                            height={this.calculateHeight('LeadsByAppointmentType', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                        />
                    </div>

                    <div key={'JobCounts'} id={'JobCounts'}>
                        <JobCounts {...this.props}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            height={this.calculateHeight('JobCounts', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                            refreshData={this.props.refreshData}
                            sidepanelOpen={this.props.sidepanelOpen}
                        />
                    </div>

                    <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
                        <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            loader={loader}
                            dateRange={dateRange}
                            applydateRange={applydateRange}
                            height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                            refreshData={this.props.refreshData}
                        />
                    </div>

                    <div key={'LeadsByServiceType'} id={'LeadsByServiceType'}>
                        <ServiceTypeCounts {...this.props}
                            handleShowEnquiryList={handleShowEnquiryList}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            loader={loader}
                            dateRange={dateRange}
                            height={this.calculateHeight('LeadsByServiceType', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                        />
                    </div>

                    <div key={'RevenueCounts'} id={'RevenueCounts'}>
                        <ServiceRevenueCounts {...this.props}
                            handleShowEnquiryList={handleShowEnquiryList}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            loader={loader}
                            dateRange={dateRange}
                            height={this.calculateHeight('RevenueCounts', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                        />
                    </div>
                    <div key={'Activities'} id={'Activities'}>
                        <ActivitiesCount {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                            selectedPipeline={selectedPipeline}
                            selectedUser={selectedUser}
                            loader={loader}
                            dateRange={dateRange}
                            height={this.calculateHeight('Activities', this.props.initialLayout)}
                            reloadData={this.props.reloadData}
                        />
                    </div>




                </ResponsiveReactGridLayout>
            </div>
        );

    }
}

DragDropLayout.defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: function () { },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

