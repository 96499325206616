export const allcampaignFields = [
  {
      name: 'Title',
      value: '_title',
      flex: 1,
      default: true,
  },
  {
      name: 'Message Content',
      value: 'content',
      flex: 2,
      default: true
  },
  {
      name: 'Updated On',
      value: '_updatedOn',
      flex: 1,
      default: true,
  },
  {
    name: 'Status',
    value: '_status',
    flex: 1,
    default: true
  },
  {
      name: 'Stats',
      value: '_stats',
      flex: 1,
      default: true
  },
  {
      name: 'Settings',
      value: 'settings',
      width: 80,
      default: true,
      type: 'settings',
      flex: 0,
      fixedRight: true
  }
]

export const allrecipientFields = [
    {
        name: 'First Name',
        value: 'firstName',
        flex: 2,
        default: true,
    },
    {
        name: 'Middle Name',
        value: 'middleName',
        flex: 1,
        default: true
    },
    {
        name: 'Last Name',
        value: 'lastName',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    }
  ]


export const alleditrecipientFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'First Name',
        value: 'firstName',
        flex: 2,
        default: true,
    },
    {
        name: 'Middle Name',
        value: 'middleName',
        flex: 1,
        default: true
    },
    {
        name: 'Last Name',
        value: 'lastName',
        flex: 1,
        default: true
    },
    {
        name: 'Phone',
        value: '_phone',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        width: 75,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
  ]

  export const alldisplayrecipientFields = [
    {
        name: 'Name',
        value: 'fullName',
        flex: 2,
        default: true,
    },
    // {
    //     name: 'Middle Name',
    //     value: 'middleName',
    //     flex: 1,
    //     default: true
    // },
    // {
    //     name: 'Last Name',
    //     value: 'lastName',
    //     flex: 1,
    //     default: true
    // },
    {
        name: 'Phone',
        value: '_phone',
        elementName: 'csvPhone',
        flex: 1,
        default: true,
    },
    {
        name: 'Reply',
        value: '_replyCount',
        elementName: 'replyNotes',
        flex: 1,
        default: true,
    }
  ]