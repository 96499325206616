import React, { useState, useEffect, useContext } from "react";
import images from "../../../../images";
import { Modal } from "react-bootstrap";
import Translate from "../../../../constants/translate";
import { PopUpModal } from "../../../../components";
import Options from "../options";
import _ from 'lodash'


const EmailIntegration = (props) => {
  const [modalOptionsShow, setOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const [options, setOptions] = useState({
    origin: "",
    enqType: "",
  });
  const [moduleToChange, setModuleToChange] = useState({
    module: "",
    checked: null,
  });
  const emailIntgs = props?.integrations?.length ? props.integrations.filter(a => a.email === true) : [];
  return (<>
    <Modal
      id="add-cafe"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      backdropClassName={props.show ? "overlay-modal active" : ""}
      enforceFocus={false}
      dialogClassName={"modal-dialog modal-dialog-centered modal-configure-integration-generic-api"}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">
            <Translate text={props.title} />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="configure-integration-logo">
                    <img src={images.emailIntg} alt="" />
                  </div>
                </div>
              </div>
              <div className="form-row ">
                <div className="settings-tab-wrap tabs col-md-12">
                  <div className="tabs-wraper">
                    <p
                      style={{
                        margin: "0px auto 15px auto",
                        color: "#0000007a",
                        paddingBottom: "15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Email Integrations
                    </p>
                    <nav>
                      <div className="common-table w-100 mt-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Type"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="13%"
                              >
                                {" "}
                                <Translate text={"Enquiry Type"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Origin"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Campaign"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light border-right-0"
                                width="15%"
                              >
                                {" "}
                                <Translate text={"Actions"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {emailIntgs.length > 0 ? (
                              emailIntgs.map((rec, index) => {
                                return (
                                  <tr key={index}>
                                    <td> {rec.type} </td>

                                    <td> {_.find(props?.dealersettings?.client?.settings?.enquiryTypes, { value: rec?.settings?.enquiryType })?.name || ''} </td>
                                    <td> {_.find(props?.dealersettings?.client?.settings?.origins, { value: rec?.settings?.origin })?.name || ''} </td>
                                    <td> {_.find(props?.dealersettings?.client?.settings?.campaigns, { value: rec?.settings?.campaign })?.name || ''} </td>

                                    <td className="border-right-0">
                                      <div className="table-edit float-left mr-2">
                                        <a href="#" className="mini-button" onClick={(e) => {
                                          e.preventDefault();
                                          setModuleToChange({
                                            module: 'emailIntg',
                                            checked: true,
                                            data: rec
                                          });
                                          setOptionsShow({
                                            show: true,
                                            title: rec.type,
                                            isEdit: true,
                                          });
                                        }}>
                                          <i className="ico icon-edit"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <PopUpModal show={modalOptionsShow.show}>
      <Options
        {...props}
        {...modalOptionsShow}
        handleClose={() => { setOptionsShow({ show: false }) }}
        setOptions={setOptions}
        options={options}
        moduleToChange={moduleToChange}
        enableAddons={props.enableAddons}
      ></Options>
    </PopUpModal>
  </>)
};

export default EmailIntegration;
