/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import _ from "lodash";
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';
import { PopUpModal, PpsrScan } from "../../../components";

const VehicleInfo = ({ requirement, handleEdit, isDeivered, dealersettings, serviceID, clientID, groupUsers, clientUsers }) => {
    const [editshow, setEditshow] = useState(false)
    const [showPpsrScan, setShowPpsrScan] = useState(false);
    const node = useRef();
    const { make, model, saleType, type, currentlyOwned, extColor,
        year, exteriorColor, regNo, rego, vinNo, odometer
    } = requirement;

    const ppsrEnabled = (!_.isEmpty(dealersettings?.client?.moduleSettings?.ppsr) &&
        dealersettings.client.moduleSettings.ppsr.enabled &&
        dealersettings.client.moduleSettings.ppsr.active) ? true : false;

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className="vehicle-item" id={'veh_info_service'}>
            <div className="flex-vehicle">
                <div className="quickview-activity-more">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setEditshow(!editshow)
                        if (editshow)
                            document.addEventListener('click', setEditshow(false));
                    }}><i className="ico icon-more"></i></a>
                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                        <ul className="more-dropdown">
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                                    {
                                        (isDeivered)
                                            ?
                                            <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                                            :
                                            <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="vehicle-item-image" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                        <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                    </a>
                </div>

                <div className="vehicle-data" onClick={(e) => { e.preventDefault(); handleEdit(requirement) }}>
                    <div className="vehicle-item-title mini-badge">{make} {model}</div>
                    <div className="vehicle-item-info">{extColor ? `${extColor},` : ''} {year ? year : ''}</div>
                    <div className="vehicle-item-info"><Translate text={'reg'} upperCase={true} />#: {rego ? rego : (regNo ? regNo : '--')} </div>
                    <div className="vehicle-item-info"><Translate text={'VIN'} upperCase={true} />#: {vinNo ? vinNo : '--'} </div>
                    {odometer ? <div className="vehicle-item-info"><Translate text={'odometer'} upperCase={true} />#: <strong>{odometer}</strong> </div> : <></>}
                </div>
            </div>
            {
                (ppsrEnabled)
                    ?
                    <>
                        <div className="tradin-item-ppsr mt-2">
                            <div className="float-right">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowPpsrScan(true);
                                    }}
                                >
                                    <img
                                        src={_images.ppsr_logo}
                                        width="12"
                                        className="mr-1"
                                        style={{ marginTop: "-2px" }}
                                    />
                                    {"PPSR"}
                                </button>
                                {/* {ppsrEnabled && ( */}
                                {/* )} */}

                            </div>
                        </div>
                    </>
                    :
                    <></>
            }

            <PopUpModal show={showPpsrScan}>
                <PpsrScan
                    show={showPpsrScan}
                    clsActive="overlay-modal active"
                    regNo={rego ? rego : (regNo ? regNo : '')}
                    //regState={tradein?.regState}
                    serviceID={serviceID}
                    clientID={clientID}
                    chassisNo={vinNo}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                    handleClose={() => {
                        setShowPpsrScan(false);
                    }}
                ></PpsrScan>
            </PopUpModal>
        </div>
    )
};
export default VehicleInfo;


