
export const allcontactFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Name',
        value: 'fullName',
        flex: 1,
        default: true,
        subText: 'guestTag',
        subTextTitle: 'Guest',
        elementsubName: 'strGuestTag',
        nestedText: 'clientName',
        nestedTextTitle: 'Dealer Name',
        //sortValue: 'firstName',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
        //sortValue: 'email',
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        //sortValue: 'phone',
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type'

    },
    {
        name: 'Test Drive Vehicle',
        value: 'testdriveModel',
        flex: 1,
        default: true,
        subText: 'testdriveVin',
        subTextTitle: 'Test Drive Vin No',
    },
    {
        name: 'Owned Vehicle',
        value: 'ownedVehicleModel',
        flex: 1,
        default: true,
        subText: 'ownedVehicleYear',
        subTextTitle: 'Year',
    },
    {
        name: 'License No',
        value: 'licenseNo',
        flex: 1,
        default: true,
        //sortValue: 'licenseNo',

    },
    {
        name: 'Preferred Retailer',
        value: 'preferredRetailer',
        flex: 1,
        default: true,
    },
    {
        name: 'Source',
        value: 'source',
        flex: 1,
        default: true,
    },
    {
        name: 'RSVP Status',
        value: 'status',
        flex: 1,
        default: true,
        subText: 'actionedByName',
        subTextTitle: 'Actioned By',
        elementName: 'statusName',
        //sortValue: 'status.name'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        //sortValue: 'licenseExpiry',
        default: true,
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1,
        default: true,
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        sortValue: 'addedDate',
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By',
        //sortValue: 'modifiedDate',
    },
    {
        name: 'Checked-In',
        value: 'checkedStatus',
        flex: 1,
        subText: 'checkedOn',
        subTextTitle: 'Checked On',
        //sortValue: 'checkinDate',
        elementName: 'checkedStatusName',
        default: true,
    },
    {
        name: 'Last Note',
        value: 'lastNote',
        flex: 1,
        default: true,
        limit: 60
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        //sortValue: 'address',
        default: true,
        limit: 60
    },
    {
        name: 'State',
        value: 'state',
        flex: 1,
        //sortValue: 'state'
    },
    {
        name: 'Country',
        value: 'country',
        flex: 1,
        //sortValue: 'country'
    },
    {
        name: 'Dietary Preference',
        value: 'dietaryPreference',
        flex: 1,
        //sortValue: 'dietaryPreference',
        default: true,
    },
    {
        name: 'Slot Date',
        value: 'eventSlot',
        flex: 1,
        //sortValue: 'eventSlot',
        default: true,
    },
    {
        name: 'Slot Time',
        value: 'slotTime',
        flex: 1,
        //sortValue: 'slotTime',
        default: true,
    },
    {
        name: 'Data Location',
        value: 'dataLocation',
        flex: 1,
        //sortValue: 'dataLocation',
        default: true,
    },
    {
        name: 'Lead Status',
        value: 'leadStatus',
        flex: 1,
        //sortValue: 'leadStatus',
        elementName: 'leadStatusName',
        default: true,
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1,
        //sortValue: 'optinPhone',
        default: true,
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1,
        //sortValue: 'optinEmail',
        default: true,
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1,
        //sortValue: 'optinPost'
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1,
        //sortValue: 'optinSMS',
        default: true,
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        //sortValue: 'contactType'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allUnallocatedFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Name',
        value: 'fullName',
        flex: 1,
        default: true,
        subText: 'guestTag',
        subTextTitle: 'Guest',
        elementsubName: 'guestOf',
        nestedText: 'clientName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'firstName',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
        sortValue: 'email',
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        sortValue: 'phone',
    },
    {
        name: 'License No',
        value: 'licenseNo',
        flex: 1,
        default: true,
        sortValue: 'licenseNo',

    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type',
        elementsubName: 'vehicleSaletype'

    },
    {
        name: 'Preferred Retailer',
        value: 'preferredRetailer',
        flex: 1,
        default: true,
    },
    {
        name: 'RSVP Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName',
        sortValue: 'status.name'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        sortValue: 'licenseExpiry',
        default: true,
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1,
        default: true,
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        sortValue: 'addedDate',
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By',
        sortValue: 'modifiedDate',
    },
    {
        name: 'Checked-In',
        value: 'checkedStatus',
        flex: 1,
        subText: 'checkedOn',
        subTextTitle: 'Checked On',
        sortValue: 'checkinDate',
        elementName: 'checkedStatusName',
        default: true,
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        sortValue: 'owner.name'
    },
    {
        name: 'Notes',
        value: 'lastNote',
        flex: 1,
        default: true,
        limit: 60
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        sortValue: 'address',
        default: true,
        limit: 60
    },
    {
        name: 'State',
        value: 'state',
        flex: 1,
        sortValue: 'state'
    },
    {
        name: 'Dietary Preference',
        value: 'dietaryPreference',
        flex: 1,
        sortValue: 'dietaryPreference',
        default: true,
    },
    {
        name: 'Event Slot',
        value: 'eventSlot',
        flex: 1,
        sortValue: 'eventSlot',
        default: true,
    },
    {
        name: 'Data Location',
        value: 'dataLocation',
        flex: 1,
        sortValue: 'dataLocation',
        default: true,
    },
    {
        name: 'lead Status',
        value: 'leadStatus',
        flex: 1,
        sortValue: 'leadStatus',
        elementName: 'leadStatusName',
        default: true,
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1,
        sortValue: 'optinPhone',
        default: true,
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1,
        sortValue: 'optinEmail',
        default: true,
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1,
        sortValue: 'optinPost',
        default: true,
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1,
        sortValue: 'optinSMS'
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        sortValue: 'contactType'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 100,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allAllocatedFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'Name',
        value: 'fullName',
        flex: 2,
        default: true,
        // subText: 'contactNumber',
        // subTextTitle: 'Contact ID',
        nestedText: 'clientName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        elementName: 'csvPhone',
        default: true,
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleType',
        subTextTitle: 'Sale Type',
        elementsubName: 'vehicleSaletype'

    },
    {
        name: 'Vehicle Year',
        value: 'vehicleYear',
        flex: 1,
    },
    {
        name: 'Allocation Status',
        value: 'allocationStatus',
        flex: 1,
        default: true,
        nestedText: 'toClientName',
        nestedTextTitle: 'To Dealer Name',
        elementName: 'allocationStatusName'
    },
    {
        name: 'Allocation On',
        value: 'allocationDate',
        flex: 1,
        default: true
    },
    {
        name: 'Actioned On',
        value: 'actionedOn',
        flex: 1,
        default: true
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Updated By'
    },
    {
        name: 'Unactioned',
        value: 'unActioned',
        flex: 1,
        default: true,
        elementName: 'strunActioned'

    },
    {
        name: 'Response Time(hh:mm:ss)',
        value: 'strResponseTime',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const enquiryActivities = [
    {
        name: "Add Notes",
        value: "note",
        ico: "ico icon-note",
        emptymessage: "Click here to add notes..."
    }
]

export const enquirylogscompleted = [
    {
        name: "All",
        value: "all",
        type: "all",
        subType: "all",
    },
    // {
    //     name: "Notes",
    //     value: "allnotes",
    //     type: "allnotes",
    //     subType: "allnotes",
    // },    
    {
        name: "Notes",
        type: "log",
        subType: "note",
        value: "note"
    },
    {
        name: "EDMs",
        type: "log",
        subType: "edms",
        value: "edms"
    },
    // {
    //     name: "Change Logs",
    //     type: "log",
    //     subType: "log",
    //     value: "log"
    // }
]


export const USAFormatExcelFields = [
    {
        name: '(Do Not Modify) Lead',
        value: 'emptyValue',
    },
    {
        name: '(Do Not Modify) Row Checksum',
        value: 'emptyValue',
    },
    {
        name: '(Do Not Modify) Modified On',
        value: 'emptyValue',
    },
    {
        name: 'Status Reason',
        value: 'emptyValue',
    },
    {
        name: 'Area of Interest',
        value: 'emptyValue',
    },
    {
        name: 'Preferred Retailer',
        value: 'preferredRetailer',
    },
    {
        name: 'Source Campaign',
        value: 'emptyValue',
    },
    {
        name: 'Lead source',
        value: 'emptyValue',
    },
    {
        name: 'Address 1',
        value: 'street',
    },
    {
        name: 'Configuration Comments',
        value: 'emptyValue',
    },
    {
        name: 'Country/Region',
        value: 'country',
    },
    {
        name: 'Date of Birth',
        value: 'emptyValue',
    },
    {
        name: 'Do not allow Bulk Emails',
        value: 'stroptinEmail',
    },
    {
        name: 'Do not allow Mails',
        value: 'stroptinPost',
    },
    {
        name: 'Do not allow Phone Calls',
        value: 'stroptinPhone',
    },
    {
        name: 'Do not allow SMS',
        value: 'stroptinSMS',
    },
    {
        name: 'Email',
        value: 'email',
    },
    {
        name: 'First Name',
        value: 'firstName',
    },
    {
        name: 'Over 13',
        value: 'emptyValue',
    },
    {
        name: 'Originating event',
        value: 'eventTitle',
    },
    {
        name: 'Post/Zip Code',
        value: 'areaCode',
    },
    {
        name: 'Primary Phone',
        value: 'displayPhone',
    },
    {
        name: 'State/Province',
        value: 'state',
    },
    {
        name: 'Middle Name',
        value: 'middleName',
    },
    {
        name: 'Surname/Family Name',
        value: 'lastName',
    },
    {
        name: 'Street 2',
        value: 'street',
    },
    {
        name: 'Street 3',
        value: 'emptyValue',
    },
    {
        name: 'Town/City',
        value: 'suburb',
    },
    {
        name: 'Gender',
        value: 'emptyValue',
    },
    {
        name: 'Test Drive',
        value: 'testdriveDone',
    },
    {
        name: 'Test Drive Date',
        value: 'testdriveDate',
    },
    {
        name: 'Owned Vehicle',
        value: 'ownedVehicleModel',
    },
    {
        name: 'Year',
        value: 'ownedVehicleYear',
    },
    {
        name: 'Source',
        value: 'source',
    },
    {
        name: 'Event',
        value: 'eventTitle',
    },
    {
        name: 'Experience Date',
        value: 'emptyValue',
    },
    {
        name: 'Dietary Requirements(Contact)(Contact)',
        value: 'emptyValue',
    },
    {
        name: 'Status',
        value: 'statusName',
    },
    {
        name: 'Event Slot',
        value: 'eventSlotDate',
    },
    {
        name: 'Checked In',
        value: 'checkedOn',
    },
    {
        name: 'Test Drive Model',
        value: 'testdriveModel',
    },
    {
        name: 'Test Drive VIN No',
        value: 'testdriveVin',
    },
    {
        name: 'Configuration Notes',
        value: 'lastNote',
    }
]



