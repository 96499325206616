import React, { Component } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import CalendarEvents from "./calendarEvents";
import LiveEvents from "./liveEvents";
import EventAttendees from "./eventAttendees";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBreakpoint: "lg",
            compactType: "vertical",
            mounted: false,
            layouts: { lg: props.initialLayout }
        };

        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.initialLayout !== this.state.layouts) {
            this.setState({ layouts: { lg: nextProps.initialLayout } });
        }
    }

    onBreakpointChange(breakpoint) {
        this.setState({
            currentBreakpoint: breakpoint
        });
    }

    onLayoutChange(layout, layouts) {
        this.props.onLayoutChange(layout, layouts);
    }

    calculateHeight(sectionID, layoutArr) {
        let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
        if (selectedSection.length === 0)
            return 30
        let columnHeight = Number(selectedSection[0].h)
        if (columnHeight === 1) {
            return 30
        }
        columnHeight = columnHeight - 1
        columnHeight = columnHeight * 45
        columnHeight = columnHeight + 30

        return columnHeight
    }

    filterLayouts = (layouts) => {

        // if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
        //     if (layouts.lg[0].i === '0') {
        //         layouts.lg.shift()
        //     }
        // }
        return layouts
    }


    render() {

        const {
            handleShowEnquiryList,
            selectedPipeline,
            selectedUser,
            selectedClient,
            dateRange,
            applydateRange,
            loader,
            pipeline,
        } = this.props;

        return (
            <div>
                <ResponsiveReactGridLayout
                    {...this.props}
                    layouts={this.filterLayouts(this.state.layouts)}
                    onBreakpointChange={this.onBreakpointChange}
                    onLayoutChange={this.onLayoutChange}
                    measureBeforeMount={false}
                    useCSSTransforms={this.state.mounted}
                    compactType={this.state.compactType}
                    preventCollision={!this.state.compactType}
                    margin={[15, 15]}
                    isResizable={true}
                    draggableHandle='.draggable-section'
                    draggableCancel='.dashboard-box-body'
                >

                    <div key={'MonthEvents'} id={'MonthEvents'}>
                        <CalendarEvents
                            {...this.props}
                        />
                    </div>

                    <div key={'LiveEvents'} id={'LiveEvents'}>
                        <LiveEvents
                            {...this.props}
                        />
                    </div>
                    <div key={'EventAttendees'} id={'EventAttendees'}>
                        <EventAttendees
                            {...this.props}
                        />
                    </div>


                </ResponsiveReactGridLayout>
            </div>
        );

    }
}

DragDropLayout.defaultProps = {
    className: "layout",
    rowHeight: 30,
    onLayoutChange: function () { },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

