import React, { useState } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
 
import { ReactComponent as SendIcon } from '../../images/send.svg';
import { storageBucket } from '../../services/helper';

const InputMessage = ({ owner, handleMessageSend,dealersettings }) => {
	const [messageText, setMessageText] = useState('');
	const [loader, setLoader] = useState(false);

	const sendMessage = text => {
		if (text.length > 0) {
			handleMessageSend(text, 'text', owner);
			setMessageText('');
		}
	};

	const sendImageMessage = url => {
		handleMessageSend(url, 'image', owner);
		setLoader(false);
	};

	const sendDocumentMessage = (url, messageData) => {
		handleMessageSend(
			{
				documentURL: url,
				documentSizeKB: messageData.fileSize,
				documentName: messageData.fileName,
				documentExt: messageData.fileExt,
			},
			'file',
			owner,
		);
		setLoader(false);
	};

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			sendMessage(messageText);
		}
	};

	const onSelectFile = e => {
		const file = e.target.files[0];

		if (!file) {
			return;
		}

		if (loader) {
			return;
		}

		if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
			handleImage(e);
		} else if (file.name.match(/.(xlsx|xls|doc|docx|ppt|pptx|txt|pdf)$/i)) {
			handleFile(e);
		} else {
			Swal.fire(
				'File format not supported. Please select an image or document.',
				'',
				'info',
			);
		}
	};

	const handleImage = e => {
		const img = e.target.files[0];

		const imgsize = img.size / 1024 / 1024;
		if (imgsize > 10) {
			Swal.fire('Maximum file size exceeded.', '', 'info');
			return;
		}

		// Ext for file
		let ext = img.name.split('.').pop();

		// Saving Image
		saveFileToStorage(img, ext, 'messages', 'image');
	};

	const handleFile = e => {
		const file = e.target.files[0];

		const filesize = file.size / 1024 / 1024;
		if (filesize > 2) {
			Swal.fire('Maximum file size exceeded.', '', 'info');
			return;
		}

		// FIle Name
		const fileName = file.name;

		// File Size
		const fileSize = Math.round(file.size / 1000);

		// Ext for file
		let ext = file.name.split('.').pop();

		// Saving Image
		saveFileToStorage(file, ext, 'messages', 'document', {
			fileName,
			fileSize,
			fileExt: ext,
		});
	};

	const saveFileToStorage = (file, ext, path, type, messageData) => {
		let uid = moment()._d.getTime().toString() + '.' + ext;

		const storageRef = storageBucket(dealersettings).ref()
			.child(`${path}/${uid}`)
			.put(file);

		storageRef.on(
			'state_changed',
			snapshot => {
				const percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				// console.log('Upload is ' + percentage + '% done');
				if (percentage > 0 && percentage < 99) {
					setLoader(true);
				}
			},
			error => {
				console.log(error);
				setLoader(false);
			},
			() => {
				storageRef.snapshot.ref.getDownloadURL();
				storageBucket(dealersettings).ref(path)
					.child(uid)
					.getDownloadURL()
					.then(dataURL => {
						if (type === 'image') {
							sendImageMessage(dataURL);
						} else {
							sendDocumentMessage(dataURL, messageData);
						}
					});
			},
		);
	};

	return (
		<form onSubmit={e => e.preventDefault()}>
			<div className='chat-attachment'>
				<label htmlFor='abc'>
					<i className='ico icon-attachment'></i>
					<input
						className='fileInput'
						type='file'
						name=''
						id='abc'
						accept='.xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf'
						style={{ display: 'none' }}
						onChange={onSelectFile}
					/>
				</label>
			</div>
			<input
				type='text'
				className='chatApp__convInput'
				placeholder='Type your message...'
				value={messageText}
				onChange={e => setMessageText(e.target.value)}
				onKeyPress={handleKeyPress}
			/>
			<div className={'chatApp__convButton '}>
				{loader ? (
					<span
						className='spinner-border spinner-button mr-1'
						role='status'
						aria-hidden='true'
						style={{
							width: '1.2rem',
							height: '1.2rem',
							marginLeft: '2px',
							marginTop: '2px',
						}}
					></span>
				) : (
						<SendIcon onClick={() => sendMessage(messageText)} />
					)}
			</div>
		</form>
	);
};

export default InputMessage;
