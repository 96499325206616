import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect, AntDateRangePicker } from "../../components";
import { pipelineCountVMNEW } from './viewModel'
// import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState(pipelineCountVMNEW)
    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;
    // const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             setLoader(true)
    //             let dataToSet = {}


    //             let _filter = {
    //                 clientID: CommonHelper.getEventsProDealers(props.dealersettings),
    //                 timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
    //             }
    //             let _searchObject = {
    //                 type: "getEventsProDashboardCounts",
    //                 params: JSON.stringify(_filter),
    //             }

    //             const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getData2');
    //             const resp = await getDashboardCounts(_searchObject)

    //             if (resp.data.success) {
    //                 const data = resp.data.data
    //                 //console.log('data returned', data)

    //                 dataToSet = {
    //                     draft: _.sumBy(data, 'draft'),
    //                     live: _.sumBy(data, 'live'),
    //                     published: _.sumBy(data, 'published'),
    //                     closed: _.sumBy(data, 'closed'),
    //                 }
    //             } else {
    //                 // console.log('data not returned')
    //             }
    //             setCounts(dataToSet)
    //             setLoader(false)

    //         } catch (error) {
    //             console.error(error)
    //             setLoader(false)
    //         }
    //     }

    //     fetchData()
    // }, [])

    useEffect(() => {
        if (_.isEmpty(props.calendarEventData))
            return
        if (eventsProStatus?.length > 0) {
            let _cData = {};
            eventsProStatus.map(rec => {
                return _cData = {
                    ..._cData,
                    [rec.value]: props.calendarEventData.filter(a => a.status === rec.value)
                }
            })
        }
        setCounts({
            draft: props.calendarEventData.filter(a => a.status === 'draft'),
            closed: props.calendarEventData.filter(a => a.status === 'closed'),
            published: props.calendarEventData.filter(a => a.status === 'published'),
            live: props.calendarEventData.filter(a => a.status === 'live')
        })
    }, [props.calendarEventData])

    return (<>
        <div className="event-dashboard-month-wrap"> <h2>Event Stats - <span>{moment().format('MMMM YYYY')}</span></h2></div>
        <div className="events-counter-dashboard-wraper mb-4">
            <div className="container-fluid pl-0 pr-0">
                <div className="row">
                    <div className="col-md-3">
                        <div className="events-counter status-events-grey" onClick={() => {
                            if (counts.draft.length > 0) {
                                props.handleShowEventsList(counts.draft, 'Events List')
                            }
                        }}>
                            <i className="ico icon-events-dash-draft"></i>
                            <span className="count-numbers">{counts.draft.length}</span>
                            <span className="count-name"> Draft</span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="events-counter status-events-blue" onClick={() => {
                            if (counts.published.length > 0) {
                                props.handleShowEventsList(counts.published, 'Events List')
                            }
                        }}>
                            <i className="ico icon-events-dash-published"></i>
                            <span className="count-numbers">{counts.published.length}</span>
                            <span className="count-name"> Published</span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="events-counter status-events-orange" onClick={() => {
                            if (counts.live.length > 0) {
                                props.handleShowEventsList(counts.live, 'Events List')
                            }
                        }}>
                            <i className="ico icon-events-dash-live"></i>
                            <span className="count-numbers">{counts.live.length}</span>
                            <span className="count-name"> Live</span>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="events-counter status-events-green" onClick={() => {
                            if (counts.closed.length > 0) {
                                props.handleShowEventsList(counts.closed, 'Events List')
                            }
                        }}>
                            <i className="ico icon-events-dash-completed"></i>
                            <span className="count-numbers">{counts.closed.length}</span>
                            <span className="count-name">Completed</span>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </>);
}

export default PipelineCounts;