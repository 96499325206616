import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { allrecipientFields } from './viewModel';
import Report from './campaignReport';
import CampaignDetails from './campaignDetails'

let bodyHTML = `<div style="border: 1px solid #e5e5e5; padding: 15px 20px; max-width: 600px; margin: auto;">
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Hello ,</span><br><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">You got a new message from Tenderdash:</span></p>
<p style="padding: 12px; border-left: 6px solid #eee; font-style: italic;">&nbsp;</p>
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">&nbsp;</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Best wishes,</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Tenderdash team</span></p>
</div>`

const CampaignPreview = props => {
	const {API_KEY, SERVER_PREFIX} = props
	const [batch, setBatch] = useState({});
	const [batchStats, setBatchStats] = useState({});
	const [batchLinksStats, setBatchLinksStats] = useState({});
	const [message, setMessage] = useState({});
	const [contacts, setContacts] = useState([]);
	const [complainedContacts, setComplainedContacts] = useState([]);
	const [batchLoader, setBatchLoader] = useState(true);
	const [contactsLoader, setContactsLoader] = useState(true);
	const [complainedLoader, setComplainedLoader] = useState(true);
	
	const [campaign, setCampaign] = useState(null);
	const [activeTab, setActiveTab] = useState('detail');
	const [recipients, setRecipients] = useState([]);
	const [recipientLoader, setRecipientLoader] = useState(false);
	const [loader, setLoader] = useState(true);
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 380,
		windowHeight: window.innerHeight - 273,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 380,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			 try {
				const batchResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/get-batch-by-id`, {
					batchID: props.data.id,
					API_KEY: props.API_KEY
				});
				console.log('batchResponse', batchResponse.data.response)

				const batchStatsResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/get-batch-statistics`, {
					batchID: props.data.id,
					API_KEY: props.API_KEY
				});
				console.log('batchStatsResponse', batchStatsResponse.data.response)

				const batchLinksStatsResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/get-batch-links`, {
					batchID: props.data.id,
					API_KEY: props.API_KEY
				});
				console.log('batchLinksStatsResponse', batchLinksStatsResponse.data.response)

				const messageResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/message-content`, {
					campaignID: props.data.message_id,
					API_KEY: props.API_KEY
				});
				console.log('messageResponse', messageResponse.data.response)

				setMessage(messageResponse.data.response)
				setBatch(batchResponse.data.response)
				setBatchStats(batchStatsResponse.data.response)
				setBatchLinksStats(batchLinksStatsResponse.data.response)
				setBatchLoader(false)

				const batchContactsResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/get-batch-contacts`, {
					batchID: props.data.id,
					API_KEY: props.API_KEY
				});
				console.log('batchContactsResponse', batchContactsResponse.data.response)

				if (!_.isEmpty(batchContactsResponse) 
				&& !_.isEmpty(batchContactsResponse.data)
				&& !_.isEmpty(batchContactsResponse.data.response)) {
					setContacts(convertVM([].concat.apply([], Object.values(batchContactsResponse.data.response))))
				} else {
					setContacts([])
				}

				const batchComplainedContactsResponse = await axios.post(`${props.apiUrl}visionrest-api/api/visionrest/get-batch-complained`, {
					batchID: props.data.id,
					API_KEY: props.API_KEY
				});
				console.log('batchComplainedContactsResponse', batchComplainedContactsResponse.data.response)

				if (!_.isEmpty(batchComplainedContactsResponse) 
				&& !_.isEmpty(batchComplainedContactsResponse.data)
				&& !_.isEmpty(batchComplainedContactsResponse.data.response)) {
					setComplainedContacts(convertVM([].concat.apply([], Object.values(batchComplainedContactsResponse.data.response))))
				} else {
					setComplainedContacts([])
				}
				//[].concat.apply([], Object.values(temp1));

				setContactsLoader(false)

				} catch (error) {
					setBatchLoader(false)
					setContactsLoader(false)
					console.log(error);
				}
		}
		fetchData()
	}, []);

	const convertVM = (arr) => {
		return arr.map(item => {
			return {
				...item,
				sendTime: item.send_time ? moment.unix(item.send_time).format('DD/MM/YYYY hh:mm A') : '--',
				status: (
					<div
						className={`badge badge-pill badge-${item.send_status === 'completed'
								? 'available'
								: item.send_status === 'pending'
									? 'pending'
									: item.send_status === 'failed'
										? 'draft'
										: 'draft'
							}-fill `}
					>
						{item.send_status ? item.send_status : '--'}
					</div>
				)
			}
		})
	}

	const handleActionClick = () => {

	}
	const handleRowSingleClick = () => {

	}

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
              {true && (
								<div className='container-fluid pl-0 '>
									{batchLoader ? (
										<div className='loader-center-screen'>
											<div className="spinner-loader h-100 "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
										</div>
									) : activeTab === 'detail' ? (
										<CampaignDetails
										setActiveTab={setActiveTab}
										campaign={campaign}
										bodyHTML={bodyHTML}
										recipients={recipients}
										windowSize={windowSize}
										allrecipientFields={allrecipientFields}
										handleActionClick={handleActionClick}
										handleRowSingleClick={handleRowSingleClick}
										data={props.data}
										activeTab={activeTab}
										batch={batch}
										batchStats={batchStats}
										batchLinksStats={batchLinksStats}
										contacts={contacts}
										message={message}
										contactsLoader={contactsLoader}
										/>
									) : (
										<Report 
										setActiveTab={setActiveTab}
										campaign={campaign}
										activeTab={activeTab}
										batch={batch}
										batchStats={batchStats}
										batchLinksStats={batchLinksStats}
										contacts={contacts}
										complainedContacts={complainedContacts}
										message={message}
										data={props.data}
										contactsLoader={contactsLoader}

										/>
									)}
								</div>
              )}
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default CampaignPreview;
