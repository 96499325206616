import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { firestoreDB } from '../../../../services/helper';
import { levelOptions, pipelineVM } from "./viewModel";
import { TemplateContext } from '../templateContext'

const MovePipeline = (props) => {
    const [state, setState] = useState({
        pipeline: (props.pipeline && props.deleteStage) ? props.pipeline.value : '',
        stage: ''
    })
    const [stages, setStages] = useState([]);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const { clients } = useContext(TemplateContext);
    let pipelineIndex = _.find(levelOptions, { value: props.pipeline.level }) ? _.find(levelOptions, { value: props.pipeline.level }).index : 1;
    useEffect(() => {
        if (!_.isEmpty(props.pipeline)) {
            setStages(_.map(props.pipeline.stages.filter(item =>
                (props.pipeline.value === 'LeadsBucket' ? item.value !== 'Converted' : true) &&
                (!_.isEmpty(props.deleteStage) ? item.value !== props.deleteStage.value : true)), function (s) {
                    return {
                        value: s.value,
                        label: s.name,
                        active: _.isBoolean(s.active) ? s.active : true,
                    }
                }))
        }
    }, [])

    const handleSelectChange = (e, data) => {


        if (data.name === 'pipeline') {
            setState({
                ...state,
                [data.name]: e.value,
                ['stage']: ''
            })
            setStages(e.stages.filter(item => e.value === 'LeadsBucket' ? item.value !== 'Converted' : true))
        }
        else {
            setState({
                ...state,
                [data.name]: e.value
            })
        }
    }

    const movePipeline = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(state['pipeline'])) {
            formIsValid = false;
            errors['pipeline'] = errorClass;
        }
        if (_.isEmpty(state['stage'])) {
            formIsValid = false;
            errors['stage'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        if (!_.isEmpty(props.deleteStage)) {
            delStage()
        }
        else {
            delPipeline()
        }
    }

    const delPipeline = async () => {
        const batch = firestoreDB(props.dealersettings).firestore().batch();
        let clientIds = [props.selectedClient.documentID];
        if (props.pipeline.level === 'oem') {
            clientIds = clients.map((rec) => { return rec.documentID })
        }
        else if (props.pipeline.level === 'region') {
            clientIds = clients.filter(rec => rec.region === props.pipeline.levelID).map((rec) => { return rec.documentID })
        }
        else if (props.pipeline.level === 'group') {
            clientIds = clients.filter(rec => rec.group === props.pipeline.levelID).map((rec) => { return rec.documentID })
        }
        const promises = []
        clientIds.forEach(clientID => {
            promises.push(firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('clientID', '==', clientID)
                .where('isDeleted', '==', false)
                .where('pipeline', '==', props.deletePipeline.value)
                .get())
        })
        const snapshots = await Promise.all(promises)
        snapshots.forEach(response => {
            response.docs.forEach((doc) => {
                batch.update(doc.ref, {
                    pipeline: state.pipeline,
                    stage: state.stage
                });
            });
        });
        batch.commit()
            .then(async () => {


                //update settings date and priority
                if (!_.isEmpty(props.selectedClient)) {
                    window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText:  'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText:  'Pipeline Settings has been updated',
                        }, { merge: true })
                }
                //end

                let clinetpromise = []
                const batch = window.firebase.firestore().batch();
                if (props.pipeline.level === 'oem') {
                    const pipelineObj = {
                        'pipelines': [...props.allPipelines.filter(item => item.level === props.pipeline.level && item.value !== props.pipeline.value).map(rec => { return { ..._.pick(rec, _.keys(pipelineVM)) } })]
                            .map(obj => {
                                let pipelineToReturn = { ...obj }
                                if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === props.pipeline.value)) {
                                    pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== props.pipeline.value)] }
                                }
                                return pipelineToReturn
                            })
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                        pipelineObj, { merge: true });
                    clinetpromise.push(window.firebase.firestore().collection(`clients`).where('settingsID', '==', props.dealersettings.client.settingsID).get());
                }
                else if (_.isArray(props.pipeline.levelID)) {
                    props.pipeline.levelID.forEach(rec => {
                        const pipelineObj = {
                            'pipelines': [...props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === rec && item.value !== props.pipeline.value).map(rec => { return { ..._.pick(rec, _.keys(pipelineVM)) } })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === props.pipeline.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== props.pipeline.value)] }
                                    }
                                    return pipelineToReturn
                                })
                        }
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                            pipelineObj, { merge: true });
                        clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${props.pipeline.level === 'individual' ? 'documentID' : props.pipeline.level}`, '==', rec).get());

                    })
                }
                else {
                    const pipelineObj = {
                        'pipelines': [...props.allPipelines.filter(item => item.level === props.pipeline.level && item.levelID === props.pipeline.levelID && item.value !== props.pipeline.value).map(rec => { return { ..._.pick(rec, _.keys(pipelineVM)) } })]
                            .map(obj => {
                                let pipelineToReturn = { ...obj }
                                if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === props.pipeline.value)) {
                                    pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== props.pipeline.value)] }
                                }
                                return pipelineToReturn
                            })
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${props.pipeline.levelID}`),
                        pipelineObj, { merge: true });
                    clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${props.pipeline.level === 'individual' ? 'documentID' : props.pipeline.level}`, '==', props.pipeline.levelID).get());
                }

                let pipelinesortpromise = []
                const clinetSnapsnot = await Promise.all(clinetpromise);
                clinetSnapsnot.forEach((clients) => {
                    clients.docs.forEach(client => {
                        if (client.exists)
                            pipelinesortpromise.push(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${client.id}/enquiryOptions/${client.id}`).get());
                    })
                })
                const pipelinesortSnapsnot = await Promise.all(pipelinesortpromise);
                pipelinesortSnapsnot.forEach((sortOption) => {
                    if (sortOption.exists && !_.isEmpty(sortOption.data().pipelines)) {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${sortOption.id}/enquiryOptions/${sortOption.id}`),
                            {
                                pipelines: [...sortOption.data().pipelines.filter(item => item !== props.pipeline.value)]
                            }, { merge: true });
                    }
                })

                if (props.allPipelines.some(a => a.level !== props.pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === props.pipeline.value))) {
                    props.allPipelines.filter(a => a.level !== props.pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === props.pipeline.value))
                        .forEach(rec => {
                            var pipelineObj = [..._.map(props.allPipelines.filter(item => item.level === rec.level && rec.levelID === item.levelID), function (obj) {
                                return { ..._.pick(obj, _.keys(pipelineVM)) };
                            })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === props.pipeline.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== props.pipeline.value)] }
                                    }
                                    return pipelineToReturn
                                })

                            if (pipelineObj.length > 0) {
                                if (rec.level === 'oem') {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                                else {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${rec.level === 'group' ? 'groups' : rec.level === 'region' ? 'regions' : 'clients'}/${rec.levelID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                            }
                        })
                }


                batch.commit()
                    .then(snapshot => {
                        setLoader(false);
                        props.handleClose(props.pipeline)
                        toast.notify(`Pipeline deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })


            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const delStage = async () => {
        const batch = firestoreDB(props.dealersettings).firestore().batch();
        let clientIds = [props.selectedClient.documentID];
        if (props.pipeline.level === 'oem') {
            clientIds = clients.map((rec) => { return rec.documentID })
        }
        else if (props.pipeline.level === 'region') {
            clientIds = clients.filter(rec => rec.region === props.pipeline.levelID).map((rec) => { return rec.documentID })
        }
        else if (props.pipeline.level === 'group') {
            clientIds = clients.filter(rec => rec.group === props.pipeline.levelID).map((rec) => { return rec.documentID })
        }
        const promises = []
        clientIds.forEach(clientID => {
            promises.push(firestoreDB(props.dealersettings).firestore().collection('enquiries')
                .where('clientID', '==', clientID)
                .where('isDeleted', '==', false)
                .where('pipeline', '==', props.pipeline.value)
                .where('stage', '==', props.deleteStage.value)
                .get())
        })
        const snapshots = await Promise.all(promises)
        snapshots.forEach(response => {
            response.docs.forEach((doc) => {
                batch.update(doc.ref, {
                    pipeline: state.pipeline,
                    stage: state.stage
                });
            });
        });
        batch.commit()
            .then(() => {
                //update settings date and priority
                window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'critical',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Pipeline Settings has been updated',
                    }, { merge: true })

                //end


                const batch = window.firebase.firestore().batch();
                if (props.pipeline.level === 'oem') {
                    const pipelineObj = {
                        'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level), function (obj) {
                            return obj.value === props.pipeline.value ? { ..._.pick({ ...props.pipeline, stages: [...props.pipeline.stages.filter(item => item.value !== props.deleteStage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                        })].map(obj => {
                            let pipelineToReturn = { ...obj }
                            if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === props.deleteStage.value)) {
                                pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== props.deleteStage.value)] }
                            }
                            return pipelineToReturn
                        })
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                        pipelineObj, { merge: true });
                }
                else if (_.isArray(props.pipeline.levelID)) {
                    props.pipeline.levelID.forEach(rec => {
                        const pipelineObj = {
                            'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && rec === item.levelID), function (obj) {
                                return obj.value === props.pipeline.value ? { ..._.pick({ ...props.pipeline, stages: [...props.pipeline.stages.filter(item => item.value !== props.deleteStage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                            })].map(obj => {
                                let pipelineToReturn = { ...obj }
                                if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === props.deleteStage.value)) {
                                    pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== props.deleteStage.value)] }
                                }
                                return pipelineToReturn
                            })
                        }
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                            pipelineObj, { merge: true });
                    })
                }
                else {
                    const pipelineObj = {
                        'pipelines': [..._.map(props.allPipelines.filter(item => item.level === props.pipeline.level && props.pipeline.levelID === item.levelID), function (obj) {
                            return obj.value === props.pipeline.value ? { ..._.pick({ ...props.pipeline, stages: [...props.pipeline.stages.filter(item => item.value !== props.deleteStage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                        })].map(obj => {
                            let pipelineToReturn = { ...obj }
                            if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === props.deleteStage.value)) {
                                pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== props.deleteStage.value)] }
                            }
                            return pipelineToReturn
                        })
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${props.pipeline.level === 'group' ? 'groups' : props.pipeline.level === 'region' ? 'regions' : 'clients'}/${props.pipeline.levelID}`),
                        pipelineObj, { merge: true });
                }

                if (props.allPipelines.some(a => a.level !== props.pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === props.pipeline.value && b.stage === props.deleteStage.value))) {
                    props.allPipelines.filter(a => a.level !== props.pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === props.pipeline.value && b.stage === props.deleteStage.value))
                        .forEach(rec => {
                            var pipelineObj = [..._.map(props.allPipelines.filter(item => item.level === rec.level && rec.levelID === item.levelID), function (obj) {
                                return { ..._.pick(obj, _.keys(pipelineVM)) };
                            })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === props.deleteStage.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== props.deleteStage.value)] }
                                    }
                                    return pipelineToReturn
                                })

                            if (pipelineObj.length > 0) {
                                if (rec.level === 'oem') {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                                else {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${rec.level === 'group' ? 'groups' : rec.level === 'region' ? 'regions' : 'clients'}/${rec.levelID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                            }
                        })
                }

                batch.commit()
                    .then(snapshot => {
                        setLoader(false);
                        props.handleClose(props.pipeline)
                        toast.notify(`Stage deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })


            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(props.pipelineOptions) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-rearrange-stage"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row">
                    <div className="form-style w-100 pipeline-form-style">
                        <div className="form-row">
                            <div className="alert alert-primary" role="alert">
                                <Translate text={'moveb4Delete'} />
                            </div>
                            <div className="form-group col-md-12">
                                <label >   <Translate text={'pipeline'} /></label>
                                <ReactSelect
                                    options={_.map(props.pipelineOptions.filter(item => item.levelIndex <= pipelineIndex && item.active === true && (!_.isEmpty(props.deletePipeline) ? item.value !== props.deletePipeline.value : true)), function (c) {
                                        return {
                                            value: c.value,
                                            label: c.name,
                                            active: _.isBoolean(c.active) ? c.active : true,
                                            stages: _.map(c.stages.filter(item => !_.isEmpty(props.deleteStage) ? item.value !== props.deleteStage.value : true), function (s) {
                                                return {
                                                    value: s.value,
                                                    label: s.name,
                                                    active: _.isBoolean(s.active) ? s.active : true,
                                                }
                                            })
                                        };
                                    })}
                                    name={"pipeline"}
                                    placeholder={'select pipeline'}
                                    onChange={handleSelectChange}
                                    value={state.pipeline}
                                    classNamePrefix={`${errorFields["pipeline"]} basic-select`}
                                    removeClearable={true}
                                    isDisabled={props.pipeline && props.pipeline.value === 'LeadsBucket'}
                                >
                                </ReactSelect>
                            </div>
                            <div className="form-group col-md-12">
                                <label > <Translate text={'stage'} /></label>
                                <ReactSelect
                                    options={stages}
                                    name={"stage"}
                                    placeholder={'select stage'}
                                    onChange={handleSelectChange}
                                    value={state.stage}
                                    classNamePrefix={`${errorFields["stage"]} basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => movePipeline(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={`${props.inactive === true ? 'Inactive' : 'Delete'} ${!_.isEmpty(props.deleteStage) ? 'Stage' : 'Pipeline'}`} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default MovePipeline;