/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
/** COMPONENTS */
import { AntDatePicker, InputText } from '../../components';
import Translate from '../../constants/translate';
import { firestoreDB } from '../../services/helper';

export default class DeliveredOptions extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.state = {
            fields:
            {
                chassisNo: '',
                regNo: '',
                stockNo: '',
                warrantyExpiry: null,
            },
            errors: {}
        }
    }

    loadEnquiry = () => {
        const { stock } = this.props.requirement;
        if (!_.isEmpty(stock)) {
            let state = Object.assign({}, this.state.fields);
            state.chassisNo = stock.vinNo ? stock.vinNo : '';
            state.regNo = stock.regNo ? stock.regNo : '';
            state.stockNo = stock.stockNo ? stock.stockNo : '';
            state.warrantyExpiry = stock.warrantyExp ? stock.warrantyExp : null;
            this.setState({ fields: state });
        }
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.toUpperCase();
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }
    handleDateChange = (value, name) => {
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = moment(value).format('YYYY-MM-DD');
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    };

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        // for (let [key, value] of Object.entries(fields)) {
        //     if (!fields[key] && key === 'lostReason') {
        //         formIsValid = false;
        //         errors[key] = errorClass;
        //     }
        // }

        // this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleSave();
        }
        else
            return formIsValid;

    };

    handleSave = () => {
        this.isLoading = true;
        const { enquiryID, dealersettings } = this.props;
        const { chassisNo, regNo, stockNo, warrantyExpiry } = this.state.fields;

        let modifiedBy = dealersettings ? dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiryID);
        updateRef
            .update({
                chassisNo: chassisNo,
                regNo: regNo,
                stockNo: stockNo,
                warrantyExpiry: warrantyExpiry,
                //status: enquiryStatus.DELIEVERD,
                modifiedBy: modifiedBy,
                modifiedDate: modifiedDate,
            })
            .then(docRef => {
                this.isLoading = false;
                this.props.handleClose(this.state.fields)
            })
            .catch(error => {
                this.isLoading = false;
                console.error('Error updating enquiries: ', error);
            });
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.loadEnquiry();
    }


    render() {
        const { chassisNo, regNo, stockNo, warrantyExpiry } = this.state.fields;

        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="search-pop form-style">

                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'chassisNo'} /> </label>
                                            <InputText
                                                name="chassisNo"
                                                value={chassisNo}
                                                onChange={this.handleOnCapChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["chassisNo"]}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'regNo'} /> </label>
                                            <InputText
                                                name="regNo"
                                                value={regNo}
                                                onChange={this.handleOnCapChange}
                                                autoComplete="off"
                                                placeholder={'registration number'}
                                                className={`form-control ${this.state.errors["regNo"]}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'stockNo'} /> </label>
                                            <InputText
                                                name="stockNo"
                                                value={stockNo}
                                                onChange={this.handleOnCapChange}
                                                autoComplete="off"
                                                placeholder={'stock number'}
                                                className={`form-control ${this.state.errors["stockNo"]}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'warrantyExpiry'} /> </label>
                                            <AntDatePicker
                                                value={warrantyExpiry}
                                                name={'warrantyExpiry'}
                                                onChange={(e) => { this.handleDateChange(e, 'warrantyExpiry') }}
                                                format='DD/MM/YYYY'
                                                placeholder='DD/MM/YYYY'
                                                className={`form-control ${this.state.errors["warrantyExpiry"]}`}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        {/* <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => this.props.handleClose()}
                            ><Translate text={'cancel'} />
                            </button> */}
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}
