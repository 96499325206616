export const alerts = [
    // { name: 'Pending Approval', value: 'pendingApproval' },
    // { name: 'Request', value: 'requested' },
    // { name: 'Approved', value: 'approved' },
    // { name: 'Declined', value: 'declined' },
    // { name: 'Cancelled', value: 'cancelled' },
    // { name: 'Received', value: 'received' },
    // { name: 'Released', value: 'released' },
    { name: 'External / Internal Event', value: 'extIntEvent' },
    { name: 'Loan Vehicle', value: 'loanVehicle' },
    { name: 'Reminder Responsible Person', value: 'reminderUser' },
    { name: 'Reminder Fleet Owner', value: 'reminderOwner' },
]

export const mentions = [
    {
        name: 'Responsible Person Name',
        value: '@RESPONSIBLE_PERSON_NAME'
    },
    {
        name: 'Responsible Person Email',
        value: '@RESPONSIBLE_PERSON_EMAIL'
    },
    {
        name: 'Responsible Person Phone',
        value: '@RESPONSIBLE_PERSON_PHONE'
    },
    {
        name: 'Vehicle Model',
        value: '@VEHICLE_MODEL'
    },
    {
        name: 'RegNo',
        value: '@REG_NO'
    },
    {
        name: 'VIN No',
        value: '@VIN_NO'
    },
    {
        name: 'Colour',
        value: '@EXT_COLOUR'
    },
    {
        name: 'Interior Colour / Trim',
        value: '@INT_COLOR'
    },
    {
        name: 'User Name',
        value: '@USER_NAME'
    },
    {
        name: 'Fleet Owner Name',
        value: '@FLEET_OWNER_NAME'
    },
    {
        name: 'Fleet Owner Email',
        value: '@FLEET_OWNER_EMAIL'
    },
    {
        name: 'Fleet Owner Phone',
        value: '@FLEET_OWNER_PHONE'
    },
    {
        name: 'Dealer Name',
        value: '@DEALER_NAME'
    },
    {
        name: 'Dealer Phone',
        value: '@DEALER_PHONE'
    },
    {
        name: 'Dealer Email',
        value: '@DEALER_EMAIL'
    },
    {
        name: 'Dealer Address',
        value: '@DEALER_ADDRESS'
    },
    {
        name: 'Facebook URL',
        value: '@FACEBOOK_URL'
    },
    {
        name: 'X URL',
        value: '@X_URL'
    },
    {
        name: 'Instagram URL',
        value: '@INSTAGRAM_URL'
    },
    {
        name: 'YouTube URL',
        value: '@YOUTUBE_URL'
    },
    {
        name: 'Fleet Link',
        value: '@FLEET_LINK'
    },
    {
        name: 'Assign Type',
        value: '@ASSIGN_TYPE'
    },
    {
        name: 'Fleet Receive Link',
        value: '@FLEET_RECEIVE_LINK'
    },
    {
        name: 'Fleet Return Link',
        value: '@FLEET_RETURN_LINK'
    },
    {
        name: 'Current Month',
        value: '@CURRENT_MONTH'
    }
]
