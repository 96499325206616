/** LIBRARIES */
import React, { Suspense, Component, lazy, useEffect } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
import ApiLogs from './apis'
import Pricing from './pricing'

const BillingSettings = (props) => {
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <div className="settings-wrapper">
            <div className="settings-leftmenu">
                <h3>Billing</h3>
                <Suspense fallback={<></>}>
                    <ul>
                        <li>
                            <NavLink to={'/admin/billing/apis'} activeClassName={"active"}>
                                <i className='ico icon-settings-billing'></i>Billing
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/admin/billing/pricing'} activeClassName={"active"}>
                                <i className='ico icon-dynamic-pricefield'></i>Pricing
                            </NavLink>
                        </li>
                    </ul>
                </Suspense>
            </div>
            <div className="settings-middlewraper">
                <div className="settings-middlewraper-sub">
                    <Suspense fallback={<></>}>
                        <Switch>
                            <Route path="/admin/billing/apis" exact component={ApiLogs} />
                            <Route path="/admin/billing/pricing" exact component={Pricing} />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </div>
    );
}

export default BillingSettings;