import React, { useState, useRef, useEffect } from 'react';
//import './OtpInput.css';

const OtpInput = ({ length, onChange, type, onEnter, className, classRender, autoFocus }) => {
    const [otp, setOtp] = useState(Array(length).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        // Focus first input if autofocus true
        if (autoFocus) inputRefs.current[0].focus();
    }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);
        onChange(newOtp.join(''));

        // Focus next input
        if (element.nextSibling && element.value) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (element, index, event) => {
        // Focus to previous input on backspace
        if (event.key === 'Backspace' && !element.value && element.previousSibling) {
            element.previousSibling.focus();
        }
        if (event.key === 'Enter' && onEnter && length === (index + 1)) {
            onEnter(otp.join(''));
        }
    };

    return (
        <div className={`otp-input ${classRender || ''}`}>
            {otp.map((value, index) => (
                <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    autoComplete="off"
                    type={type ? type : "text"}
                    className={className}
                    maxLength="1"
                    value={value}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e.target, index, e)}
                />
            ))}
        </div>
    );
};

export default OtpInput;