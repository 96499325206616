/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _, { isEmpty } from 'lodash'
import { default as _images } from '../../../images';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, PopoverListLoader } from '../../../components';
import CommonHelper from '../../../services/common';
import { objEnquiry } from '../viewModel'
import { enquiryStatus } from '../../../services/enum'
import { firestoreDB } from '../../../services/helper';
import MergeEnquiry from './index'

class MergeEnquiryList extends Component {
    constructor(props) {
        super(props);
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.unsubscribeENQZ = null;
        this.state = {
            enquiries: [],
            filter: {
                iSearch: ''
            },
            loading: true,
            modalMergeShow: { show: false, mergeEnqIDs: [] }
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch } = filter
        let result = listings
        const filterForEnq = (item) => {
            let result = false;
            if (iSearch && item.keywords.includes(iSearch.toLowerCase())) {
                result = true
            }
            if (!_.isEmpty(item.contact)) {
                if (item.contact.firstName.toLowerCase().includes(iSearch.toLowerCase()) || item.contact.lastName.toLowerCase().includes(iSearch.toLowerCase())) {
                    result = true
                }
            }
            if (!_.isEmpty(item.requirement) && (!_.isEmpty(item.requirement.make) || !_.isEmpty(item.requirement.stock))) {
                if (item.requirement.stock === null) {
                    if (item.requirement.make.toLowerCase().includes(iSearch.toLowerCase()) || item.requirement.model.toLowerCase().includes(iSearch.toLowerCase())) {
                        result = true
                    }
                } else {
                    if (item.requirement.stock) {
                        if (item.requirement.stock.make.toLowerCase().includes(iSearch.toLowerCase()) || item.requirement.stock.model.toLowerCase().includes(iSearch.toLowerCase())) {
                            result = true
                        }
                    }
                }
            }
            return result

        }
        if (iSearch) {
            result = result.filter(item => filterForEnq(item))
        }
        return result
    }


    componentDidMount() {
        const { dealersettings, contactID, enquiryID } = this.props;
        if (dealersettings && contactID && enquiryID) {

            firestoreDB(dealersettings).firestore().collection('enquiries')
                .where('isContact', '==', true)
                .where('contact.documentID', '==', contactID)
                .where('isDeleted', '==', false)
                .where('clientID', '==', dealersettings.client.id)
                .get().then(response => {
                    const enquiries = [];

                    response.docs.forEach((doc) => {
                        let _enq = Object.assign({}, objEnquiry);

                        for (let [key, value] of Object.entries(doc.data())) {
                            if (_enq.hasOwnProperty(key) || key === 'keywords')
                                _enq[key] = value;
                        }

                        _enq.documentID = doc.id;
                        if (enquiryID !== _enq.documentID)
                            enquiries.push(_enq);

                    });

                    this.setState({
                        enquiries: _.orderBy(enquiries, ["modifiedDate"], ["desc"]),
                        loading: false
                    });

                });

        }
        else {
            this.setState({
                loading: false
            });
        }
    }


    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }


    handleSelectMergeEnquiry = (ID, objData) => {
        const { enquiryID, primaryEnquiry } = this.props;
        const _mergeEnqIDs = [];

        if (primaryEnquiry && primaryEnquiry.clientID !== objData.clientID) {
            Swal.fire(CommonHelper.showLocale(this.props, 'Only enquiries belonging to the same dealer can be merged.'), '', 'info');
            return;
        }

        _mergeEnqIDs.push(enquiryID);
        _mergeEnqIDs.push(ID);
        this.setState({ modalMergeShow: { show: true, mergeEnqIDs: _mergeEnqIDs } });
    }

    handleCloseMergeEnquiries = (objUpdatedEnq, removeEnqID) => {
        if (!_.isEmpty(objUpdatedEnq) && removeEnqID) {

            // updateListEnquiry(objUpdatedEnq);

            // dispatch({
            //     type: "REMOVE_ENQUIRY_LIST",
            //     data: { documentID: removeEnqID }
            // });

            // setSelectedIds({});

            // if (localStorage.pipelineFilter) {
            //     let _pipelineFilter = JSON.parse(localStorage.pipelineFilter);
            //     handleApplyFilter(_pipelineFilter);
            // }

            toast.notify(`Enquiry merged successfully`, {
                duration: 2000
            })

            this.props.handleClose();
        }

        this.setState({ modalMergeShow: { show: false, mergeEnqIDs: [] } });

    }

    render() {
        const { iSearch } = this.state.filter
        const { enquiries, filter, loading, modalMergeShow } = this.state
        const { show, title, showAdd, dealersettings, groupUsers, clientUsers } = this.props;
        const filteredList = MergeEnquiryList.applyFilter(enquiries, filter)
        const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
        const divStyle = {
            height: '400px',
            overflow: 'auto'
        };

        const liteVersion = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.clientSettings) &&
            dealersettings.client.clientSettings.liteVersion) ? true : false);

        return (
            <>
                <Modal
                    id="stockList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={title ? title : 'Merge Enquiries'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>
                            <div className={`vehicle-popover-head ${showAdd ? '' : 'pr-0'}`}>
                                <input
                                    type="text"
                                    id="isearch"
                                    aria-label="Search"
                                    placeholder="Search..."
                                    value={iSearch}
                                    autoComplete="off"
                                    onChange={event =>
                                        this.handleChange('iSearch', event.target.value)
                                    }
                                />
                            </div>
                            <div style={divStyle} >
                                {
                                    loading ? (<PopoverListLoader />) :
                                        (filteredList && filteredList.length > 0)
                                            ?
                                            <>
                                                {filteredList.map((enquiry, index) =>
                                                    <div key={index} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleSelectMergeEnquiry(enquiry.documentID, enquiry)

                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width70">
                                                                        <div className="customer-avatar-content">
                                                                            <div className="customer-avatar-image">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showBrandLogo(dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                                            </div>
                                                                            {
                                                                                enquiry.displayID ?
                                                                                    (<div className="input-readonly-sub"><i className="ico icon-enquiry mr-1"></i>{enquiry.displayID}</div>) :
                                                                                    (<></>)
                                                                            }
                                                                            {
                                                                                !_.isEmpty(enquiry.contact) ? (

                                                                                    <div className="link-enquiry-owner" style={{ borderBottom: '0px' }}>
                                                                                        <div className="link-enquiry-owner-view">
                                                                                            {CommonHelper.displayContactIcon(enquiry.contact, 'mr-1')}
                                                                                        </div>{CommonHelper.displayContact([], enquiry.contact, '--')}</div>
                                                                                ) : (<></>)
                                                                            }

                                                                            <div className="customer-avatar-title">

                                                                                {
                                                                                    (!_.isEmpty(enquiry.requirement) && (enquiry.requirement.make || enquiry.requirement.stock))
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                (!_.isEmpty(enquiry.requirement.stock) && !_.isEmpty(enquiry.requirement.stock.make))
                                                                                                    ?
                                                                                                    <span>
                                                                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                        {(enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '') + ' ' + (enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '')}
                                                                                                        {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                enquiry.requirement.saleType, '')}
                                                                                                        </div>) : ''}

                                                                                                    </span>
                                                                                                    :
                                                                                                    <span>
                                                                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                        {(enquiry.requirement.make ? enquiry.requirement.make : '') + ' ' + (enquiry.requirement.model ? enquiry.requirement.model : '')}
                                                                                                        {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                enquiry.requirement.saleType, '')}
                                                                                                        </div>) : ''}

                                                                                                    </span>
                                                                                            }

                                                                                            {
                                                                                                enquiry.requirementCount && enquiry.requirementCount > 1
                                                                                                    ?
                                                                                                    <OverlayTrigger
                                                                                                        placement='bottom'
                                                                                                        overlay={
                                                                                                            <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                                                            +{enquiry.requirementCount - 1} <Translate text={'more'} /></div>
                                                                                                    </OverlayTrigger>

                                                                                                    :
                                                                                                    <></>
                                                                                            }


                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                liteVersion && !_.isEmpty(enquiry.td) && !_.isEmpty(enquiry.td.make)
                                                                                                    ?
                                                                                                    <>
                                                                                                        <span>
                                                                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                                                                            {enquiry.td.make + ' ' + enquiry.td.model}
                                                                                                            {
                                                                                                                enquiry.td.saletype
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        <div className="badge badge-pill badge-white ml-1">
                                                                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                                                enquiry.td.saletype, '')}
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                    </>
                                                                                                            }
                                                                                                        </span>

                                                                                                    </>
                                                                                                    :
                                                                                                    <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                                                                            }

                                                                                        </>

                                                                                }

                                                                            </div>

                                                                            {
                                                                                !_.isEmpty(enquiry.tags) ? (
                                                                                    <div className="input-readonly-sub">
                                                                                        {
                                                                                            enquiry.tags.map((r, i) => {
                                                                                                return r.subType ? (<div key={i} className="float-left"><div className={`badge badge-pill badge-white text-uppercase ${i > 0 ? 'ml-1' : ''}`}>{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
                                                                                                    <div className="badge badge-pill badge-white ml-1 text-uppercase">{r.subType}</div></div>) :
                                                                                                    (<div key={i} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                ) : (<></>)
                                                                            }


                                                                            <div className="vehicle-item-status">
                                                                                <div className="calllogs-vehicle-owner">
                                                                                    <div className="owner-view">
                                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                            <img src={CommonHelper.getUserImagebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)} className="img-object-fit" />
                                                                                        </a>
                                                                                    </div>
                                                                                    {CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), enquiry.owner)}
                                                                                </div>
                                                                                {
                                                                                    enquiry.status ?
                                                                                        <>
                                                                                            {(() => {
                                                                                                let ___status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                                let _objCurrentStatus = enquiryAllStatus && enquiryAllStatus.filter(e => e.value === ___status)[0]

                                                                                                return <>
                                                                                                    {
                                                                                                        _objCurrentStatus
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className={`status-${___status === 'open' ? 'open' : 'empty'}  float-right`}
                                                                                                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                                >
                                                                                                                    {_objCurrentStatus.name}
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </>
                                                                                            })()}
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                {/* {
                                                                                    enquiry.status ? (
                                                                                        <span className={`status-${enquiry.status === 'open' ? 'open' : 'empty'} float-right`}
                                                                                            style={{ background: CommonHelper.getFieldByValue(enquiryAllStatus, enquiry.status, 'color', '#6b717f') }}>
                                                                                            <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, (enquiry.status === enquiryStatus.PENDINGLOST ? 'lost' : enquiry.status))} upperCase={true} />
                                                                                        </span>
                                                                                    )
                                                                                        : (<></>)
                                                                                } */}

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}</>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-enquiry"></i> </div>
                                                        <div className="no-data-txt mt-2">  <Translate text={'No enquiriy to merge'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }
                            </div>

                        </>
                    </Modal.Body>
                </Modal>
                <PopUpModal show={modalMergeShow.show}>
                    <MergeEnquiry
                        show={modalMergeShow.show}
                        enqIDA={modalMergeShow.mergeEnqIDs[0]}
                        enqIDB={modalMergeShow.mergeEnqIDs[1]}
                        isNewContact={false}
                        handleClose={this.handleCloseMergeEnquiries}
                        title={'Merge Enquiries'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </MergeEnquiry>
                </PopUpModal>
            </>
        );
    }

}

export default MergeEnquiryList;