/** LIBRARIES */
import React, { useEffect, useState, useContext, Fragment, useRef, useMemo } from 'react'
import _ from 'lodash'
import { ClientContext } from '../layout/clientContext'

import Translate from '../../constants/translate'
import { default as _images } from '../../images';

import moment from 'moment';
import { ContentOverlay, ReactMultiSelect, ReactSelect } from '../../components';

import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomFilter } from '../../components/customdropdown';

const tableColumns = [
    { name: 'Dealer', value: 'clientName', sort: true, width: 10 },
    { name: 'Age', value: 'clientAge', sort: true, width: 3 },
    { name: 'New Enquiries', value: 'newLead', sort: true, width: 8.7 },
    { name: 'Enquiry Rate', value: 'enqRate', sort: true, width: 8.7 },
    { name: 'New Contacts ', value: 'newCnt', sort: true, width: 8.7 },
    { name: 'New Contact Rate', value: 'contRate', sort: true, width: 8.7 },
    { name: 'Won', value: 'won', sort: true, width: 8.7 },
    { name: 'Won Conv %', value: 'wonRate', sort: true, width: 8.7 },
    { name: 'Test Drive', value: 'testdrives', sort: true, width: 8.7 },
    { name: 'Test Drive Conv %', value: 'tdRate', sort: true, width: 8.7 },
    { name: 'Activties Completed', value: 'activities', sort: true, width: 8.7 },
    { name: 'Overdue Activities', value: 'overdue', sort: true, width: 8.7 },
]

const Dashboard = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 150) })
    const [checkDataLoad, setDataload] = useState(true)

    const [clientOptions, setClientOptions] = useState([]);

    const [oemOptions, setOEMOptions] = useState([]);
    const [allclient, setAllClient] = useState([]);
    const [reportData, setReportData] = useState([]);

    const [nestedTbl, setNestedTbl] = useState({});


    const [filterFields, setFilterFields] = useState({})
    const [sortConfig, setSortConfig] = useState();

    const [dateRange, setDateRange] = useState({
        startDate: moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
        currentMonth: moment().month() + 1,
        monthStart: moment().startOf('month').startOf('day').unix(),
        monthEnd: moment().endOf('month').endOf('day').unix(),
        monthStartPrev: moment().subtract(1, 'month').startOf('month').startOf('day').unix(),
        monthEndPrev: moment().subtract(1, 'month').endOf('month').endOf('day').unix(),
        monthStartLast: moment().subtract(2, 'month').startOf('month').startOf('day').unix(),
        monthEndLast: moment().subtract(2, 'month').endOf('month').endOf('day').unix(),
    })

    const { oemSettings, clients } = useContext(ClientContext);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 150)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(oemSettings) || _.isEmpty(clients))
            return

        let _oemSettingsWithClients = [];
        oemSettings.forEach((rec) => {
            _oemSettingsWithClients.push({
                label: rec.name,
                value: rec.documentID,
                active: true,
                clients: clients.filter(a => a.settingsID === rec.documentID && (_.isEmpty(a.status) || a.status === 'active')).map((oemClient) => {
                    return {
                        addedDate: oemClient.addedDate || oemClient.modifiedDate,
                        label: oemClient.name,
                        value: oemClient.documentID,
                        oemName: rec.name,
                        oemID: rec.documentID,
                        active: true
                    }
                }),
            })
        })

        const clientArray = _oemSettingsWithClients.filter(e => !_.isEmpty(e.clients)).flatMap(item => item.clients);
        console.log('_oemSettingsWithClients', _oemSettingsWithClients)
        console.log('clientArray', clientArray)
        setOEMOptions(_oemSettingsWithClients);
        setAllClient(clientArray)
        setDataload(true)
    }, [oemSettings?.length, clients?.length])

    useEffect(() => {
        if (!checkDataLoad || _.isEmpty(allclient)) {
            return;
        }

        let _oemFilter = localStorage.oemDealerFilter ? JSON.parse(localStorage.oemDealerFilter) : {};
        let _filter = Object.assign({}, _oemFilter?.value);

        _filter.startDate = dateRange.startDate;
        _filter.endDate = dateRange.endDate;
        // _filter.timezone = moment.tz.guess();

        const filteredClient = allclient.map(item => {
            return {
                clientID: item.value,
                oemID: item.oemID
            }

        })
        //let filteredClient = CommonHelper.arrPaginate(allclientList, 50, (Math.ceil(clientOptions.length / 50) + 1))//['M9DA6lUwbNqsXPspjzCh', '9OaKXHd6f1pesGBdeIUS'];//

        _filter.clientID = filteredClient.map(item => { return item.clientID }).join(',');

        let _searchObject = {
            type: "getAllDealerStats",
            params: JSON.stringify(_filter),
        }

        // //console.log('_searchObject', _searchObject);
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        getData(_searchObject).then((resp) => {
            //console.log('generic-getData', resp);
            setClientOptions(filteredClient);
            // let __comments = filteredClient[filteredClient.length - 1];
            // if (__comments && document.getElementById('enquiry-item-' + __comments)) {
            //     document.getElementById('enquiry-item-' + __comments).scrollIntoView({
            //         behavior: "smooth",
            //         block: "center"
            //     })
            // }
            if (resp.data.success) {

                let _reportData = []
                let data = resp.data.data;
                data = data.map(r => {
                    return {
                        ...r,
                        ...convertObj(r)
                    }
                })
                const databyDealer = _.chain(data)
                    .groupBy("cid")
                    .map((value, key) => ({
                        value: key,
                        data: value
                    }))
                    .value();
                databyDealer.forEach(rec => {
                    const _dealerData = _.reverse(_.sortBy(rec.data, ['year', 'month']));
                    const currentClient = _.find(allclient, { value: rec.value });
                    let _monthData = []
                    for (let i = 0; i < _dealerData.length; i++) {
                        const daysInMonth = moment().month() + 1 === _dealerData[i].month ? moment().date() : moment(`${_dealerData[i].year}-${_dealerData[i].month}-01`).daysInMonth()
                        _monthData.push({
                            ..._dealerData[i],
                            clientAge: currentClient?.addedDate?.seconds ? moment().diff(moment.unix(currentClient.addedDate.seconds), 'days') : 0,
                            clientID: rec.value,
                            clientName: currentClient?.label,
                            oemID: currentClient?.oemID,
                            title: `Total ${moment().year(_dealerData[i].year).month(_dealerData[i].month - 1).format('MMM YYYY')}`,
                            enqRate: parseFloat((_dealerData[i]?.newLead / daysInMonth).toFixed(2)),
                            contRate: parseFloat((_dealerData[i]?.newCnt / daysInMonth).toFixed(2)),
                            tdRate: _dealerData[i]?.testdrives === 0 ? 0 : (_dealerData[i]?.testdrives === _dealerData[i]?.newLead || _dealerData[i]?.testdrives > _dealerData[i]?.newLead) ? 100 : Math.round((_dealerData[i]?.testdrives / _dealerData[i]?.newLead) * 100),
                            wonRate: _dealerData[i]?.won === 0 ? 0 : (_dealerData[i]?.won === _dealerData[i]?.newLead || _dealerData[i]?.won > _dealerData[i]?.newLead) ? 100 : Math.round((_dealerData[i]?.won / _dealerData[i]?.newLead) * 100)
                        })
                    }
                    _monthData = _monthData.map((r, i) => {
                        return {
                            ...r,
                            // enqRateUp: r?.enqRate === 0 ? false : _monthData[i + 1]?.enqRate <= r?.enqRate,
                            // contRateUp: r?.contRateUp === 0 ? false : _monthData[i + 1]?.contRate <= r?.contRate,
                            // tdRateUp: r?.tdRateUp === 0 ? false : _monthData[i + 1]?.tdRate <= r?.tdRate,
                            // wonRateUp: r?.wonRateUp === 0 ? false : _monthData[i + 1]?.wonRate <= r?.wonRate
                            enqRateUp: r?.enqRate > 0 ? true : false,
                            contRateUp: r?.contRate > 0 ? true : false,
                            tdRateUp: r?.tdRate > 0 ? true : false,
                            wonRateUp: r?.wonRate > 0 ? true : false
                        }
                    })
                    _reportData.push(..._monthData);
                });
                _reportData = _reportData.map(r => {
                    return {
                        ...r,
                        enqRate: _.isNaN(r.enqRate) ? 0 : r.enqRate,
                        contRate: _.isNaN(r.contRate) ? 0 : r.contRate,
                        tdRate: _.isNaN(r.tdRate) ? 0 : r.tdRate > 100 ? 100 : r.tdRate,
                        wonRate: _.isNaN(r.wonRate) ? 0 : r.wonRate > 100 ? 100 : r.wonRate,
                    }
                })
                _reportData = _reportData.filter(a => a.month === dateRange.currentMonth).map(r => { return { ...r, others: _.take(_reportData.filter(a => a.month !== dateRange.currentMonth && a.cid === r.cid), 2) } });
                console.log('_reportData', _reportData)
                setReportData(_reportData)
                setDataload(false)
            } else {
                setDataload(false)
            }

        });

    }, [checkDataLoad, allclient])

    const convertObj = (rec) => {
        let obj = {};
        Object.keys(rec).filter(r => r !== 'cid').forEach(r => {
            obj = {
                ...obj,
                [r]: parseInt(rec[r])
            }
        })
        return obj;
    }

    function calculateAge(_date) {
        if (_date) {
            // Convert date to Moment object
            const compareDate = moment.unix(_date.seconds);
            // Get current date
            const currentDate = moment();

            // Calculate difference in years, months, and days
            const years = currentDate.diff(compareDate, 'years');
            //compareDate.add(years, 'years');
            const months = currentDate.diff(compareDate, 'months');
            //compareDate.add(months, 'months');
            const days = currentDate.diff(compareDate, 'days');
            if (years) return <>{years} <span className="admin-ui-span">Year(s)</span></>
            else if (months) return <>{months} <span className="admin-ui-span">Month(s)</span></>
            else if (days) return <>{days} <span className="admin-ui-span">Day(s)</span></>
            else return <></>;
        }
        else {
            return <></>;
        }

    }

    //#region Filters
    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = [];
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value.push(data.value)
            })
        }
        if (value) state[data.name] = value; else delete state[data.name];
        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) state[data.name] = e.value; else {
            delete state[data.name];
            if (state["clientIDs"]) delete state["clientIDs"];
        }

        setFilterFields(state)
    }
    //#endregion

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...reportData];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [reportData, sortConfig]);

    return (
        <div className="middle-wrapper" style={{ padding: '15px' }}>
            {
                (_.isEmpty(clientOptions)) ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : (
                    <div className="admin-ui-dashboard-panel mb-3">
                        <div className="admin-ui-dashboard-panel-top">
                            <div className="admin-ui-dashboard-panel-head">
                                <div className="float-left">
                                    <h3>Dealer Stat</h3>
                                </div>
                                <div className="float-lg-right form-dashboard">
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <div className="inner-addon right-addon">
                                                <input type="text" placeholder='search' className="form-control" value={filterFields.searchText || ''} onChange={(e) => setFilterFields({ ...filterFields, searchText: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <ReactSelect
                                                options={_.orderBy(oemOptions, ['label'], ['asc'])}
                                                name={"oemID"}
                                                placeholder={'select oem'}
                                                onChange={handleSelectChange}
                                                value={filterFields.oemID || null}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                removeClearable={false}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <ReactMultiSelect
                                                options={_.orderBy(allclient.filter(e => filterFields.oemID ? e.oemID === filterFields.oemID : true), ['label'], ['asc'])}
                                                name={"clientIDs"}
                                                placeholder={'select dealerships'}
                                                onChange={handleMultiSelectChange}
                                                value={filterFields.clientIDs || []}
                                                classNamePrefix={`basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-ui-dashboard-panel-body">
                                <div className="common-table admin-ui-table" style={{ height: `${windowSize.windowHeight}px`, overflow: 'auto', zIndex: 1 }}>
                                    <table className="table table-bordered">
                                        <thead className='sticky-board-head'>
                                            <tr>
                                                {
                                                    tableColumns.map((col, index) => {
                                                        return <th key={index} scope="col" className="head-light" width={`${col.width}%`}>
                                                            {
                                                                col.sort ? (<div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    requestSort(col.value)
                                                                }}>
                                                                    <div className="table-sort"><a href="#">
                                                                        {
                                                                            sortConfig && sortConfig.key === col.value ?
                                                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                        }
                                                                    </a>
                                                                    </div>
                                                                    {col.name}
                                                                </div>) : (<>  {col.name}</>)
                                                            }
                                                        </th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sortedItems.filter(({ clientID, oemID, clientName }) =>
                                                    (!_.isEmpty(filterFields?.clientIDs) ? filterFields?.clientIDs.some(e => e === clientID) : true) &&
                                                    (!_.isEmpty(filterFields?.oemID) ? filterFields?.oemID === oemID : true) &&
                                                    (!_.isEmpty(filterFields?.searchText) ? clientName && clientName.toLowerCase().includes(filterFields.searchText.toLowerCase()) : true)
                                                ).map((rec, index) => {
                                                    return <Fragment key={index} >
                                                        {(() => {
                                                            let _data = _.find(reportData, { 'clientID': rec.clientID })
                                                            let _client = _.find(clients, { 'documentID': rec.clientID }) || _.find(allclient, { 'value': rec.clientID })
                                                            return (<>
                                                                <tr id={`enquiry-item-${rec.clientID}`}>
                                                                    <td className="td-head">
                                                                        <div title={_client.name} className="dealership-tbl cursor-pointer position-relative"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setNestedTbl({
                                                                                    ...nestedTbl,
                                                                                    [rec.clientID]: nestedTbl[rec.clientID] && !_.isEmpty(nestedTbl[rec.clientID].toString()) ? !nestedTbl[rec.clientID] : true
                                                                                })
                                                                            }}
                                                                        >
                                                                            {_client?.name?.length > 20 ? `${_client.name.substr(0, 17)}...` : _client.name}

                                                                            <div className="table-sort">
                                                                                <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();

                                                                                }}><i className={`ico icon-sort-${nestedTbl[rec.clientID] ? 'asc' : 'desc'}`}></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="admin-ui-age" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        console.log(_data)
                                                                        console.log(_data?.data)
                                                                    }}> {calculateAge(_client?.addedDate || _client?.modifiedDate)}</td>
                                                                    <td>{_data?.newLead || '0'}</td>
                                                                    <td className={_data?.enqRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(_data?.enqRate || 0)} <span className="float-right"><img src={_data?.enqRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                    <td>{_data?.newCnt || '0'}</td>
                                                                    <td className={_data?.contRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(_data?.contRate || 0)} <span className="float-right"><img src={_data?.contRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                    <td>{_data?.won || '0'}</td>
                                                                    <td className={_data?.wonRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(_data?.wonRate || 0)}% <span className="float-right"><img src={_data?.wonRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                    <td>{_data?.testdrives || '0'}</td>
                                                                    <td className={_data?.tdRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(_data?.tdRate || 0)}% <span className="float-right"><img src={_data?.tdRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                    <td>{_data?.activities || '0'}</td>
                                                                    <td>{_data?.overdue || '0'}</td>
                                                                    {/* <td>--</td>
                                                                    <td>--</td> */}
                                                                    {/* <td>{_data?.delivered || '0'}</td> */}
                                                                </tr>
                                                                <tr className={`tr-hover-fix ${nestedTbl[rec.clientID] === true ? '' : 'hide'}`}>
                                                                    <td colSpan='14' style={{ padding: ` 0.5rem !important` }}>
                                                                        <div className="common-table admin-ui-table">
                                                                            <table className="table table-bordered">
                                                                                <thead className='sticky-board-head'>
                                                                                    <tr>
                                                                                        <th colSpan={2} scope="col" className="head-light" width="12.7%">&nbsp;</th>
                                                                                        <th scope="col" className="head-light" width="8.9%">New Enquiries   </th>
                                                                                        <th scope="col" className="head-light" width="8.75%">Enquiry Rate   </th>
                                                                                        <th scope="col" className="head-light" width="8.8%">New Contacts   </th>
                                                                                        <th scope="col" className="head-light" width="8.75%">New Contact Rate   </th>
                                                                                        <th scope="col" className="head-light" width="8.9%">Won   </th>
                                                                                        <th scope="col" className="head-light" width="8.75%">Won Rate   </th>
                                                                                        <th scope="col" className="head-light" width="8.8%">Test Drive   </th>
                                                                                        <th scope="col" className="head-light" width="8.9%">Test Drive Rate  </th>
                                                                                        <th scope="col" className="head-light" width="8.75%">Activties Completed  </th>
                                                                                        <th scope="col" className="head-light" width="8.75%">Overdue Activities   </th>
                                                                                        {/* <th scope="col" className="head-light" width="7%">Active Users   </th>
                                                                                        <th scope="col" className="head-light" width="7%">Inactive Users   </th> */}
                                                                                        {/* <th scope="col" className="head-light" width="8.75%">Delivered  </th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        _data?.others && _data?.others.map((r, i) => {
                                                                                            return <tr key={i}>
                                                                                                <td colSpan={2}>
                                                                                                    <div className="dealership-tbl cursor-pointer position-relative">
                                                                                                        {r.title}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td >{r?.newLead || '0'}</td>
                                                                                                <td className={r?.enqRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(r?.enqRate || 0)} <span className="float-right"><img src={r?.enqRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                                                <td >{r?.newCnt || '0'}</td>
                                                                                                <td className={r?.contRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(r?.contRate || 0)} <span className="float-right"><img src={r?.contRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                                                <td >{r?.won || '0'}</td>
                                                                                                <td className={r?.wonRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(r?.wonRate || 0)}% <span className="float-right"><img src={r?.wonRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                                                <td >{r?.testdrives || '0'}</td>
                                                                                                <td className={r?.tdRateUp === false ? 'table-danger' : 'table-success'}>{Math.abs(r?.tdRate || 0)}% <span className="float-right"><img src={r?.tdRateUp === false ? _images.arrow_down_red : _images.arrow_up_green} height="12px" /></span></td>
                                                                                                <td >{r?.activities || '0'}</td>
                                                                                                <td >{r?.overdue || '0'}</td>
                                                                                                {/* <td >--</td>
                                                                                                <td >--</td> */}
                                                                                                {/* <td >{r?.delivered || '0'}</td> */}
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            )
                                                        })()}
                                                    </Fragment>
                                                })
                                            }
                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </div>

                    </div>
                )
            }


            {/* {
                (clientOptions.length < allclient.length) && (
                    <div className="loadmore-middle-btn">
                        <button
                            className={`btn btn-sm btn-loadmore  ${checkDataLoad ? 'form-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                            {
                                checkDataLoad ? (
                                    <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                ) : (<i className="fas fa-chevron-down"></i>)
                            }
                        </button>
                    </div>)
            } */}

        </div >
    );

}

export default Dashboard;