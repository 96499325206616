import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import CommonHelper from '../../../../services/common'
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const SalesFunnelBMW = (props) => {
    const [loader, setLoader] = useState(true)
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [allEnquiries, setEnquiries] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [olddateRange, setOldDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [pipelineOptions, setPipelineOption] = useState([])
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: [],
        requirements: []
    })
    const [csvData, setCSVData] = useState([])
    const [testDrives, setTestDrives] = useState([])

    let search = window.location.search;
    let params = new URLSearchParams(search);

    let _date = params.get('date');
    let _type = params.get('type');

    _date = _date ? _date : moment().format('YYYY-MM-DD');
    _type = _type ? _type : 'MTD'


    // let todayStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('day')._d)
    // let todayEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('day')._d)

    // let monthStart = 
    // let monthEnd = 
    // let yearStart = 
    // let yearEnd = 

    let startDate
    let endDate
    if (_type === 'MTD') {
        startDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d)
        endDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    } else if (_type === 'WTD') {
        startDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week')._d)
        endDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week')._d)
    } else if (_type === 'YTD') {
        startDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('year')._d)
        endDate = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('year')._d)
    }

    useEffect(() => {
        return () => {
            window.unSubSalesprogressReport && window.unSubSalesprogressReport.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(csvData))
            return

        document.querySelector('#download-data').click()
    }, [csvData])

    useEffect(() => {
        if (_.isEmpty(props.pipeline))
            return
        let _pipeline = [];
        props.pipeline.filter(item => item.value !== 'LeadsBucket').forEach(rec => {
            _pipeline.push({
                label: rec.name,
                active: _.isBoolean(rec.active) ? rec.active : true,
                value: rec.value,
                data: rec
            })
        })
        setPipelineOption(_pipeline)
        //setPipeline(_pipeline[0])
    }, [props.pipeline])

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            { title: 'Dealer Code', dynValue: 'reportDealerCode', dynWidth: 10, ...csvStyle.header },
            { title: 'Dealer Name', dynValue: 'reportDealerName', dynWidth: 15, ...csvStyle.header },
            { title: 'Cust ID', dynValue: 'reportCustID', dynWidth: 15, ...csvStyle.header },
            { title: 'Opp ID', dynValue: 'reportOppID', dynWidth: 15, ...csvStyle.header },
            { title: 'Used', dynValue: 'reportUsed', dynWidth: 8, ...csvStyle.header },
            { title: 'Opportunity Date', dynValue: 'reportOpportunityDate', dynWidth: 18, ...csvStyle.header },
            { title: 'Status Description', dynValue: 'reportStatusDescription', dynWidth: 18, ...csvStyle.header },
            { title: 'Campaign Name', dynValue: 'reportCampaignName', dynWidth: 16, ...csvStyle.header },
            { title: 'Source', dynValue: 'reportSource', dynWidth: 14, ...csvStyle.header },
            { title: 'Make', dynValue: 'reportMake', dynWidth: 10, ...csvStyle.header },
            { title: 'Model', dynValue: 'reportModel', dynWidth: 15, ...csvStyle.header },
            { title: 'Series', dynValue: 'reportSeries', dynWidth: 10, ...csvStyle.header },
            { title: 'Initial Contact Completed?', dynValue: 'reportInitialContactCompleted', dynWidth: 20, ...csvStyle.header },
            { title: 'Initial Contact Date Completed', dynValue: 'reportInitialContactCompletedDate', dynWidth: 20, ...csvStyle.header },
            { title: 'Appointment Completed?', dynValue: 'reportAppointmentCompleted', dynWidth: 20, ...csvStyle.header },
            { title: 'Appointment Date Completed', dynValue: 'reportAppointmentCompletedDate', dynWidth: 20, ...csvStyle.header },
            { title: 'Test drive Completed?', dynValue: 'reportTestdriveCompleted', dynWidth: 20, ...csvStyle.header },
            { title: 'Test drive Date Completed', dynValue: 'reportTestdriveCompletedDate', dynWidth: 20, ...csvStyle.header },
            { title: 'Offer Made Completed?', dynValue: 'reportOfferMadeCompleted', dynWidth: 20, ...csvStyle.header },
            { title: 'Offer Made Date Completed', dynValue: 'reportOfferMadeCompletedDate', dynWidth: 20, ...csvStyle.header },
            { title: 'Sale Completed?', dynValue: 'reportSaleCompleted', dynWidth: 20, ...csvStyle.header },
            { title: 'Sale Date Completed', dynValue: 'reportSaleCompletedDate', dynWidth: 20, ...csvStyle.header },
        )
        var exportData = []

        progressReport
            //  .filter(a => a.reportDealerName)
            .forEach(rec => {
                let obj = rec;
                let arrCols = []
                _headers.forEach(col => {
                    arrCols.push({ value: `${obj[col.dynValue]}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue].toString().length })
                })
                exportData.push(arrCols)
            })

        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])

    }, [progressReport])

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {

        if (_.isEmpty(firestoreDB))
            return

        if (!refreshData)
            return

        setLoader(true)


        //OPEN ENQUIRIES    
        let refOpenEnquiryData = ''
        refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('clientID', '==', props.clientid)
            .where('addedDate', '>=', startDate)
            .where('addedDate', '<=', endDate)
            .where('isDeleted', '==', false)

        //WON ENQUIRIES    
        let refWonEnquiryData = ''
        refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('status', '==', 'won')
            .where('clientID', '==', props.clientid)
            .where('wonDate', '>=', startDate)
            .where('wonDate', '<=', endDate)
            .where('isDeleted', '==', false)

        //LOST ENQUIRIES    
        let refLostEnquiryData = ''
        refLostEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('status', '==', 'lost')
            .where('clientID', '==', props.clientid)
            .where('lostDate', '>=', startDate)
            .where('lostDate', '<=', endDate)
            .where('isDeleted', '==', false)


        //DELIVERED ENQUIRIES    
        let refDeliveredEnquiryData = ''
        refDeliveredEnquiryData = firestoreDB.firestore().collection('enquiries')
            .where('status', '==', 'delivered')
            .where('clientID', '==', props.clientid)
            .where('deliveredDate', '>=', startDate)
            .where('deliveredDate', '<=', endDate)
            .where('isDeleted', '==', false)

        let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)

        window.unSubSalesprogressReport = combineLatest(
            refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
            refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
            refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
            refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
            collection(refClientData)
            // collection(refDoneActivityData),
        ).pipe(
            map(([openLeads, wonLeads, lostLeads, deliveredLeads, clients]) => {
                return [
                    _.map(openLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(wonLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(lostLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(deliveredLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                    // _.map(overdueActivity, function (obj) { return { ...CommonHelper.convertActivityLogVM({ ...obj.data(), 'documentID': obj.id }, { ...props }) } }),
                ]
            }))
            .subscribe(allDocs => {
                var _enquiries = [];
                for (let i = 0; i < 4; i++) {
                    allDocs[i].forEach(el => {
                        if (!_enquiries.some(e => e.documentID === el.documentID)) {
                            _enquiries.push({
                                ...el,
                                // testdrives: allDocs[4].filter(a => a.enquiryID === el.documentID),
                                // doneActivities: allDocs[5].filter(a => a.enquiryID === el.documentID),
                            })
                        }
                    });
                }
                // setTestdrives(allDocs[4])
                // setDoneActivities(allDocs[5]
                // .filter(item => !_.isEmpty(item.activityOwner))
                // )

                setTempData({
                    enquiries: _enquiries,
                })
                setClient(allDocs[4] ? Object.assign({}, allDocs[4][0]) : {})
                if (_enquiries.length === 0) {
                    setLoader(false)
                    setRefreshClientData(true)
                    setRefreshData(false)
                }
            });
        return () => {
            //dashboardPipelines && dashboardPipelines.unsubscribe()
        }

    }, [refreshData, firestoreDB])

    useEffect(() => {
        async function getClientSettings(clientID) {
            window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get().then(doc => {
                //   console.log('currentSettings', doc.data())
                let settingsDoc = doc.data();
                setClientSettings(settingsDoc);
            });
        }

        if (!_.isEmpty(client) && !_.isEmpty(client.documentID)) {
            getClientSettings(client.documentID)
        }
    }, [client, allEnquiries])

    useEffect(() => {
        async function fetchAdditionalData(enquiries) {
            const promises = []
            const testDrivePromises = []
            const activities = []
            const allTestDrives = []
            let settings
            // fetch client settings first
            const doc = await window.firebase.firestore().doc(`clients/${client.documentID}/currentSettings/${client.documentID}`).get()
            // console.log('currentSettings', doc.data())
            let settingsDoc = doc.data();
            settings = settingsDoc

            _.uniq(_.map(enquiries, function (e) { return e.documentID })).forEach(el => {
                promises.push(
                    firestoreDB.firestore().collection(`activities`)
                        .where('clientID', '==', props.clientid)
                        .where('enquiryID', '==', el)
                        .where('isDeleted', '==', false)
                        .where('isDone', '==', true)
                        .where('type', '==', 'activity')
                        .get()
                )
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                // console.log('snapshots in tempData', snap)
                if (snap.size > 0) {
                    snap.forEach(doc => {
                        activities.push(doc.data())
                    })
                }

            })

            _.uniq(_.map(enquiries, function (e) { return e.documentID })).forEach(el => {
                testDrivePromises.push(
                    firestoreDB.firestore().collection(`testdrives`)
                        .where('clientID', '==', props.clientid)
                        .where('enquiryID', '==', el)
                        .where('isDeleted', '==', false)
                        .where('status', '==', 'complete')
                        .limit(1).get()
                )
            });
            const TDsnapshots = await Promise.all(testDrivePromises)
            TDsnapshots.forEach(snap => {
                // console.log('snapshots in tempData', snap)
                if (snap.size > 0) {
                    snap.forEach(doc => {
                        allTestDrives.push(doc.data())
                    })
                }

            })
            setClientSettings(settings)
            setEnquiries(enquiries)
            setDoneActivities(activities)
            setTestDrives(allTestDrives)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
        }
        if (tempData.enquiries.length > 0)
            fetchAdditionalData(tempData.enquiries);
        else
            setEnquiries(tempData.enquiries)

    }, [tempData])

    useEffect(() => {
        if (_.isEmpty(allEnquiries) || !refreshClientData)
            return
        let reports = [];
        _.orderBy(allEnquiries, ['name'], ['asc']).forEach(async (rec, index) => {
            let CustID = ''
            let isUsed = ''
            let make = ''
            let model = ''
            let series = ''
            let initialContactCompleted = 'No'
            let initialContactCompletedDate = ''
            let appointmentCompleted = '' // leave it blank
            let appointmentCompletedDate = ''
            let testdriveCompleted = 'No'
            let testdriveCompletedDate = ''
            let offerMadeCompleted = 'No'
            let offerMadeCompletedDate = ''
            let SaleCompleted = 'No'
            let SaleCompletedDate = ''

            if (doneActivities.length > 0) {
                let enqActivities = doneActivities.filter(act => act.enquiryID === rec.documentID)
                if (enqActivities.length > 0) {
                    enqActivities = _.orderBy(enqActivities, ['modifiedDate.seconds'], ['desc'])
                    let enqActivity = enqActivities[0]
                    initialContactCompleted = 'Yes'
                    initialContactCompletedDate = !_.isEmpty(enqActivity.modifiedDate) ? moment(enqActivity.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''
                }
            }


            if (rec.status === 'won') {
                SaleCompleted = 'Yes'
                if (!_.isEmpty(rec.wonDate)) {
                    SaleCompletedDate = !_.isEmpty(rec.wonDate) ? moment(rec.wonDate.toDate()).format('DD/MM/YYYY h:mm A') : ''
                } else {
                    SaleCompletedDate = !_.isEmpty(rec.modifiedDate) ? moment(rec.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''
                }
            }

            if (!_.isEmpty(rec.dynamicFields) && clientSettings.settings.enquiryOptionsDF) {
                let enquiryOptionsDF = clientSettings.settings.enquiryOptionsDF
                let proposalDoneObj = enquiryOptionsDF.filter(obj => obj.name === "Proposal Done")[0]
                // check if it includes that proposalDoneObj
                if (rec.dynamicFields.hasOwnProperty(proposalDoneObj.value) && rec.dynamicFields[proposalDoneObj.value] === true) {
                    offerMadeCompleted = 'Yes'
                    offerMadeCompletedDate = !_.isEmpty(rec.modifiedDate) ? moment(rec.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''
                }
            }

            if (testDrives.length > 0) {
                let enqTestdrives = testDrives.filter(act => act.enquiryID === rec.documentID)
                if (enqTestdrives.length > 0) {
                    let enqTestdrive = enqTestdrives[0]
                    testdriveCompleted = 'Yes'
                    testdriveCompletedDate = !_.isEmpty(enqTestdrive.modifiedDate) ? moment(enqTestdrive.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''
                }
            }

            if (rec.contactID && !_.isEmpty(rec.contact)) {
                CustID = rec.contact.displayID ? rec.contact.displayID : ''
            }

            if (!_.isEmpty(rec.requirement)) {
                let salesType = ''
                if (!_.isEmpty(rec.requirement.stock)) {
                    salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                        rec.requirement.stock.saleType, '');
                    make = rec.requirement.stock.make
                    model = rec.requirement.stock.model
                } else if (rec.requirement.make && rec.requirement.model) {
                    salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                        rec.requirement.saleType, '');
                    make = rec.requirement.make
                    model = rec.requirement.model
                }
                if (salesType.toLowerCase().includes('preowned')) {
                    isUsed = 'Used'
                } else {
                    isUsed = 'New'
                }
            }

            if (make && model && !_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.settings)) {
                let brand = clientSettings.brands.filter(a => a.name.toLowerCase().includes(make.toLowerCase()))
                if (brand.length > 0) {
                    brand = brand[0]
                    let { models } = brand
                    let currentMakeModel = models.filter(a => a.value.toLowerCase().includes(model.toLowerCase()))
                    if (currentMakeModel.length > 0) {
                        currentMakeModel = currentMakeModel[0]
                        series = currentMakeModel.group
                    }
                }
            }

            reports.push({
                id: rec.documentID,
                reportDealerCode: '',
                reportDealerName: client.name ? client.name : '',
                reportCustID: CustID ? `${CustID}` : '',
                reportOppID: rec.displayID ? rec.displayID : '',
                reportUsed: isUsed ? isUsed : 'New',
                reportOpportunityDate: !_.isEmpty(rec.modifiedDate) ? moment(rec.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A') : '',
                reportStatusDescription: rec.statusName ? rec.statusName === 'open' ? 'Ongoing' : (rec.statusName === 'won' || rec.statusName === 'delivered') ? 'Won' : rec.statusName === 'lost' ? 'Lost' : '' : '',
                reportCampaignName: rec.campaign ? rec.campaign : '',
                reportSource: rec.enquiryType ? rec.enquiryType : '',
                reportMake: make ? make : '',
                reportModel: model ? model : '',
                reportSeries: series,
                reportInitialContactCompleted: initialContactCompleted,
                reportInitialContactCompletedDate: initialContactCompletedDate,
                reportAppointmentCompleted: appointmentCompleted,
                reportAppointmentCompletedDate: appointmentCompletedDate,
                reportTestdriveCompleted: testdriveCompleted,
                reportTestdriveCompletedDate: testdriveCompletedDate,
                reportOfferMadeCompleted: offerMadeCompleted,
                reportOfferMadeCompletedDate: offerMadeCompletedDate,
                reportSaleCompleted: SaleCompleted,
                reportSaleCompletedDate: SaleCompletedDate,
            })
        })
        setProgressReport(reports)
        setRefreshClientData(false)

    }, [refreshClientData])



    return (
        <>
            <ExcelFile element={<button type="button" className="btn btn-default ml-3" id='download-data' ><i className="ico icon-excel-download"></i> Export Excel</button>} filename={`salesprogress`}>
                <ExcelSheet dataSet={csvData} name="Sheet1" />
            </ExcelFile>
            {!_.isEmpty(csvData) && (
                <>
                    {/* @info: Below div is used for selector by puppeteer (backend)*/}
                    <div className="report-data-loaded report-data-load" />
                </>
            )}

        </>
        // <>
        //     <h2 className='mt-5 custom-margin-top'>Sales Funnel BMW
        //     <Dropdown className='enquiry-view-more float-right mt-0'>
        //             <Dropdown.Toggle as={CustomToggle} className='common-button'>
        //                 <i className='ico icon-more'></i>
        //             </Dropdown.Toggle>
        //             <Dropdown.Menu
        //                 as={CustomMenu}
        //                 ChildClass="more-dropdown dropsub-fix"
        //                 xplacement="bottom-end"
        //             >
        //                 {
        //                     <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-'}>
        //                         
        //                     </Dropdown.Item>
        //                 }
        //             </Dropdown.Menu>
        //         </Dropdown>
        //     </h2>
        //     <div className="divider-line"></div>
        //     <div className="report-content-head">
        //         <div className="row">
        //             <div className="col-md-12">
        //                 <div className="row">
        //                     <div className="form-group col-md-3 pr-0 daterange-fix">
        //                         <AntDateRangePicker
        //                             value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
        //                             id={"dashboard-date-range"}
        //                             name={'date'}
        //                             format='DD/MM/YYYY'
        //                             onChange={(e) => { handleDateRangeChange(e, 'date') }}
        //                             placeholder='DD/MM/YYYY'
        //                             onCalendarChange={(value) => {
        //                                 if (value) {
        //                                     const [start, end] = value;
        //                                     setDates([start, end]);
        //                                 }
        //                                 else {
        //                                     setDates([])
        //                                 }
        //                             }}
        //                             disabledDate={disabledDate}
        //                         />
        //                     </div>
        //                     <div className="form-group col-md-3">
        //                         <button type="button" className="btn btn-primary" onClick={() => {
        //                             if (JSON.stringify(olddateRange) !== JSON.stringify(dateRange)) {
        //                                 setRefreshData(true)
        //                                 setOldDateRange(dateRange)
        //                             }
        //                             else {
        //                                 setRefreshClientData(true)
        //                             }
        //                         }}> Generate</button>
        //                         <button type="button" className="btn btn-default ml-3" onClick={() => {
        //                             setDateRange({
        //                                 startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        //                                 endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
        //                             })
        //                             setOldDateRange({
        //                                 startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        //                                 endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
        //                             })
        //                             setRefreshData(true)
        //                         }}>Clear</button>
        //                     </div>
        //                 </div>
        //             </div>

        //         </div>
        //     </div>
        //     {
        //         loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="common-table" style={{ overflowX: 'auto' }}>


        //         </div>
        //         )
        //     }
        // </>

    )
}

export default SalesFunnelBMW;