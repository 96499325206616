import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { PopUpModal, ContentOverlay, TableView, ReactSelect,UpgradePlan } from '../../../../components'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import AddTrigger from './addTrigger'
import { triggerVM, triggerlistFields, tiggerFrequency, eventTypes, triggerTypes } from './viewModel';
import Translate from '../../../../constants/translate';
//import CommonHelper from '../../../../services/common';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { collection } from 'rxfire/firestore';
import { levelOptions } from '../fieldSettings/viewModel'
import { TemplateContext } from '../templateContext'
import CommonHelper from '../../../../services/common';
// const TriggerListReducer = (state, action) => {
//     function updateTriggers() {
//         return state.map((item, index) => {
//             if (item.documentID === action.data.documentID) {
//                 item.isActive = action.data.isActive;
//                 return item;
//             }
//             return item;
//         });
//     }
//     switch (action.type) {
//         case "SUCCESS": {
//             return action.data
//         }
//         case "APPENDTRIGGER": {
//             return [...state, ..._.differenceBy(action.data, state, 'documentID')]
//         }
//         case "ADDTRIGGER": {
//             return [action.data, ...state, action.data]
//         }
//         case "REMOVETRIGGER": {
//             return state.filter(item => item.documentID !== action.data.documentID)
//         }
//         case "UPDATETRIGGER": {
//             return [...state.filter(item => item.documentID !== action.data.documentID), action.data]
//         }
//         case "UPDATEACTIVEFIELDS": {
//             return updateTriggers();
//         }
//         default:
//             return state;
//     }
// };


const Triggers = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 255) })
    const [showTriggerModel, setShowTriggerModel] = useState(false)
    //const [triggerList, setTriggerList] = useState([])
    //const [searchText, setSearchText] = useState('')
    const [selectedTrigger, setTrigger] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const [loader, setLoader] = useState(true)
    const [triggers, setTriggers] = useState([])
    //const [checkDataLoad, setDataload] = useState(true)
    //const [hasMore, setHasMoreData] = useState([])
    //const [isPaging, setPaging] = useState(false)
    //const pageLimit = 100
    const { clients, groups, regions } = useContext(TemplateContext);
    const [queryParam, setQueryParam] = useState({})
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
    !_.isEmpty(props.dealersettings.client) &&
    !_.isEmpty(props.dealersettings.client.clientSettings) &&
    props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 255)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        // if (!checkDataLoad) {
        //     return;
        // }
        // let triggerDataRef = window.firebase.firestore().collection('triggers')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('isDeleted', '==', false)
        // // if (searchText) {
        // //     triggerDataRef = triggerDataRef
        // //         .where('keywords', 'array-contains', searchText)
        // // }

        // triggerDataRef = triggerDataRef
        //     .orderBy('addedDate', 'desc')

        // if (hasMore.length > 0) {
        //     triggerDataRef = triggerDataRef
        //         .startAfter(hasMore[0])
        //         .limit(pageLimit)
        // }
        // else {
        //     triggerDataRef = triggerDataRef
        //         .limit(pageLimit)
        // }

        // window.unSubTriggers = triggerDataRef
        //     .onSnapshot(onCollectionUpdate);
        let refOemTriggers = '';
        let refclientTriggers = '';
        let refTriggersAddedby = '';
        if (localStorage.defaultModule === 'oem') {
            refOemTriggers = window.firebase.firestore().collection('triggers')
                .where('isDeleted', '==', false)
                .where('module', '==', 'service')
            if (props.dealersettings.level === 'oem' && props.dealersettings.oemID) {
                refOemTriggers = refOemTriggers
                    .where('oemID', '==', props.dealersettings.oemID)
            }
            else if (props.dealersettings.level === 'region' && props.dealersettings.regionID) {
                refOemTriggers = refOemTriggers
                    .where('regionIDs', 'array-contains', props.dealersettings.regionID)
            }
            else if (props.dealersettings.level === 'group' && props.dealersettings.groupID) {
                refOemTriggers = refOemTriggers
                    .where('groupIDs', 'array-contains', props.dealersettings.groupID)
            }
            else {
                refOemTriggers = refOemTriggers
                    .where('clientID', '==', props.dealersettings.client.id);
                refclientTriggers = window.firebase.firestore().collection('triggers')
                    .where('isDeleted', '==', false)
                    .where('clientIDs', 'array-contains', props.dealersettings.client.id)
                    .where('module', '==', 'service')
            }
        }
        else {
            refOemTriggers = window.firebase.firestore().collection('triggers')
                .where('isDeleted', '==', false)
                .where('clientID', '==', props.dealersettings.client.id)
                .where('module', '==', 'service')
            refclientTriggers = window.firebase.firestore().collection('triggers')
                .where('isDeleted', '==', false)
                .where('clientIDs', 'array-contains', props.dealersettings.client.id)
                .where('module', '==', 'service')
        }
        refTriggersAddedby = window.firebase.firestore().collection('triggers')
            .where('isDeleted', '==', false)
            .where('settingsID', '==', !_.isEmpty(props.dealersettings.oemID) ? props.dealersettings.oemID : props.dealersettings.client.settingsID)
            .where('addedBy', '==', localStorage.uid)
            .where('module', '==', 'service')

        const unSubTriggerCollectionzz = combineLatest(
            refOemTriggers ? collection(refOemTriggers) : of([]),
            refclientTriggers ? collection(refclientTriggers) : of([]),
            refTriggersAddedby ? collection(refTriggersAddedby) : of([])
        ).pipe(
            map(([oemTriggers, clientTriggers, ownedTriggers]) => {
                return [
                    _.map(oemTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(clientTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                    _.map(ownedTriggers, function (obj) { return { ...obj.data(), documentID: obj.id } }),
                ]
            }))
            .subscribe(([oemTriggers, clientTriggers, ownedTriggers]) => {
                let _triggers = [];
                oemTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.documentID === rec.documentID))
                        _triggers.push(convertTriggerVM(rec));
                })
                clientTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.documentID === rec.documentID))
                        _triggers.push(convertTriggerVM(rec));
                })
                ownedTriggers.forEach((rec) => {
                    if (!_triggers.some(a => a.documentID === rec.documentID))
                        _triggers.push(convertTriggerVM(rec));
                })
                setTriggers(_triggers)
                setLoader(false)
            });

        return () => {
            unSubTriggerCollectionzz && unSubTriggerCollectionzz.unsubscribe()
        }

    }, [])

    // const onCollectionUpdate = (querySnapshot) => {
    //     let actionType;
    //     let snapshotDoc;
    //     querySnapshot.docChanges().forEach(change => {
    //         if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
    //         {
    //             snapshotDoc = convertTriggerVM({
    //                 ...change.doc.data(),
    //                 documentID: change.doc.id
    //             });
    //             if (change.type === 'added') {
    //                 actionType = "ADDTRIGGER";
    //             }
    //             else if (change.type === 'modified') {
    //                 actionType = "UPDATETRIGGER"
    //             }
    //             else if (change.type === 'removed') {
    //                 actionType = "REMOVETRIGGER"
    //             }
    //         }
    //     })
    //     const _triggers = [];
    //     if (!actionType) {
    //         if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
    //             setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
    //         }
    //         else {
    //             setHasMoreData([]);
    //         }
    //         querySnapshot.forEach((doc) => {
    //             _triggers.push(convertTriggerVM({
    //                 ...doc.data(),
    //                 documentID: doc.id
    //             }));
    //         });
    //     }
    //     if (actionType) {
    //         dispatch({
    //             type: actionType,
    //             data: snapshotDoc
    //         });
    //     }
    //     else {
    //         dispatch({
    //             type: isPaging ? "APPENDTRIGGER" : "SUCCESS",
    //             data: _triggers
    //         });
    //     }

    //     setDataload(false)
    //     setLoader(false)
    //     setPaging(false)
    // }

    const convertTriggerVM = (doc) => {
        const objtriggerData = Object.assign({}, doc);
        const _trigger = Object.assign({}, triggerVM);
        for (let [key, value] of Object.entries(objtriggerData)) {
            _trigger[key] = value;
        }
        if (_trigger.level === 'oem') {
            _trigger.levelsDOM = (<div className="Vehicle-availability">
                <div><span>{props.dealersettings.settingName}</span></div>
            </div>)
             _trigger.strlevels = props.dealersettings.settingName;
        }
        else if (_trigger.level === 'region' && _trigger.regionIDs) {
            _trigger.levelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(regions, (v) => _.indexOf(_trigger.regionIDs, v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>)
              _trigger.strlevels = _.filter(regions, (v) => _.indexOf(_trigger.regionIDs, v.documentID) >= 0)
              .map((r, i) => { return r.name }).join(',');
        }
        else if (_trigger.level === 'group' && _trigger.groupIDs) {
            _trigger.levelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(groups, (v) => _.indexOf(_trigger.groupIDs, v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>)
              _trigger.strlevels = _.filter(groups, (v) => _.indexOf(_trigger.groupIDs, v.documentID) >= 0)
              .map((r, i) => { return r.name }).join(',');
        }
        else if (_trigger.level === 'individual' && _trigger.clientIDs) {
            _trigger.levelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(clients, (v) => _.indexOf(_trigger.clientIDs, v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>)
               _trigger.strlevels = _.filter(clients, (v) => _.indexOf(_trigger.clientIDs, v.documentID) >= 0)
               .map((r, i) => { return r.name }).join(',');
        }
        else if (_trigger.clientID) {
            _trigger.level = 'individual';
            _trigger.levelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(clients, (v) => _.indexOf([_trigger.clientID], v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>)
                 _trigger.strlevels = _.filter(clients, (v) => _.indexOf([_trigger.clientID], v.documentID) >= 0)
                 .map((r, i) => { return r.name }).join(',');
        }
        _trigger.levelValue = _.find(levelOptions, { 'value': _trigger.level }) ? _.find(levelOptions, { 'value': _trigger.level }).label : _trigger.level;
        _trigger.strStatus = _.find(eventTypes, { 'status': doc.status }) ? _.find(eventTypes, { 'status': doc.status }).name : doc.status === 'enquiryCreatedREQ' ? 'Enquiry Created' : doc.status
        _trigger.strPeriod = _.find(tiggerFrequency, { 'value': doc.period }) ? _.find(tiggerFrequency, { 'value': doc.period }).name : doc.period
        _trigger.strtriggerType = doc.triggerType === 'email' ? 'Email' : doc.triggerType === 'sms' ? 'SMS' : 'Activity'
        _trigger.strtriggerOn = doc.period !== 'instant' ?
            doc.period === 'iteration' ?
                (<>{`Repeat ${doc.periodValue} times after every ${doc.periodEvery} ${doc.periodEveryType}`}</>)
                : doc.period === 'birthday' ? (<>{`Repeat ${doc.periodValue} times`}</>)
                    : (<>{`${doc.periodValue < 0 ? `Before ${-doc.periodValue}` : `After ${doc.periodValue}`} ${_.find(tiggerFrequency, { 'value': doc.period }) ? _.find(tiggerFrequency, { 'value': doc.period }).name : doc.period}`}</>)
            : _.find(tiggerFrequency, { 'value': doc.period }) ? _.find(tiggerFrequency, { 'value': doc.period }).name : doc.period
        return _trigger
    }

    const handleModelClose = () => {
        setShowTriggerModel(false)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditTrigger(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteTrigger(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }

    const hadleEditTrigger = (data) => {
        setPopHeader(data.title)
        setTrigger({
            ...data,
            status: data.status === 'enquiryCreatedREQ' ? 'enquiryCreated' : data.status
        })
        setShowTriggerModel(true)
    }

    const hadleDeleteTrigger = (data) => {
        //setPaging(false)
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`triggers/${data.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        isDeleted: true
                    }, { merge: true })
                    .then(() => {
                        setLoader(false);
                        toast.notify(`Trigger deleted successfully`, {
                            duration: 2000
                        })
                        // Added Audit Log
                        let logNotes = `${data.title ? data.title : 'Trigger'} Deleted`;
                        // let clientIDs = !_.isEmpty(data.clientIDs) ? data.clientIDs : data.clientID ? [data.clientID] : ''
                        // let clientIDs = [props.dealersettings.client.id]
						let _objLogData = {
							notes: logNotes, 
							type: 'triggers', 
							subType: 'delete',
							recordId: data.documentID,
                            oemID: props.dealersettings.client.settingsID ? props.dealersettings.client.settingsID : '',
                            regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                            groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                            logBy: localStorage.uid,
                            logDate: window.firebase.firestore.Timestamp.now(),
                            platform: 'web',
                            documentID: window.firebase.firestore().collection('auditLogs').doc().id,
                            clientID: props.dealersettings.client.id
                        }
                        window.firebase.firestore().doc(`auditLogs/${_objLogData.documentID}`).set(_objLogData, { merge: true }).catch(error => {
                            console.error(error);
                        });
                        // const batch = window.firebase.firestore().batch();
                        // clientIDs.forEach(clientID => {
                        //     const documentID = window.firebase.firestore().collection('auditLogs').doc().id
                        //     batch.set(window.firebase.firestore().doc(`auditLogs/${documentID}`), {..._objLogData, clientID, documentID}, { merge: true });
                        // })
                        // batch.commit().catch(error => {
                        //     console.error(error);
                        // });
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleTriggerdetailsClick = (e, id, data) => {
        hadleEditTrigger(data)
    }

    // const handlePagination = () => {
    //     //setPaging(true)
    //     //setDataload(true)
    // }

    const handleSwitchChange = (id, val) => {
        const objTrigger = triggers.find(item => item.documentID === id);
        if (!_.isEmpty(objTrigger)) {
            objTrigger.isActive = val
            // dispatch({
            //     type: "UPDATEACTIVEFIELDS",
            //     data: objTrigger
            // });
            window.firebase.firestore().doc(`triggers/${objTrigger.documentID}`)
                .set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    isActive: val
                }, { merge: true })
                .then(snapshot => {
                    toast.notify('Trigger updated successfully', {
                        duration: 2000
                    })
                    // Added Audit Logs
                    // console.log('clientID', objTrigger.clientID)
                    // console.log('clientIDs', objTrigger.clientIDs)
                    
                    let logNotes = val ? `${objTrigger.title ? objTrigger.title : 'Trigger'} Activated` : `${objTrigger.title ? objTrigger.title : 'Trigger'} Deactivated`;
                    // let clientIDs = !_.isEmpty(objTrigger.clientIDs) ? objTrigger.clientIDs : objTrigger.clientID ? [objTrigger.clientID] : ''
                    // let clientIDs = [props.dealersettings.client.id]
                    let _objLogData = {
                        notes: logNotes, 
                        type: 'triggers',
                        subType: val ? 'activated' : 'deactivated',
                        recordId: objTrigger.documentID,
                        oemID: props.dealersettings.client.settingsID ? props.dealersettings.client.settingsID : '',
                        regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                        groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                        logBy: localStorage.uid,
                        logDate: window.firebase.firestore.Timestamp.now(),
                        platform: 'web',
                        documentID: window.firebase.firestore().collection('auditLogs').doc().id,
                        clientID: props.dealersettings.client.id
                    }
                    window.firebase.firestore().doc(`auditLogs/${_objLogData.documentID}`).set(_objLogData, { merge: true }).catch(error => {
                        console.error(error);
                    });
                    // const batch = window.firebase.firestore().batch();
					// clientIDs.forEach(clientID => {
                    //     const documentID = window.firebase.firestore().collection('auditLogs').doc().id
					// 	batch.set(window.firebase.firestore().doc(`auditLogs/${documentID}`), {..._objLogData, clientID, documentID}, { merge: true });
					// })
					// batch.commit().catch(error => {
                    //     console.error(error);
                    // });
                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }

    }


    return (<>
      {
            liteVersion ? (<UpgradePlan />) : (<></>)
        }
        <h2> <Translate text={'Triggers'} /></h2>
        <div className="divider-line"></div>
        <div className={`settings-templates-wrap ${liteVersion ? 'form-lite' : ''}`}>
            {/* <div className="settings-head">
                <div className="float-left">
                    <div className="filter-search search-icon">
                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                    </div>
                </div>
                <div className="float-right">
                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                        setTrigger(null)
                        setPopHeader('Add Trigger')
                        setShowTriggerModel(true)
                    }} ><i className="ico icon-add mr-2"></i>  <Translate text={'Add Trigger'} /> </button>
                </div>

            </div> */}
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="filter-search search-icon">
                                    <input placeholder="Search" aria-label="Search" value={!_.isEmpty(queryParam) ? queryParam.searchText : ''} onChange={(e) => { setQueryParam({ ...queryParam, 'searchText': e.target.value }) }} />
                                </div>
                            </div>
                            <div className="col-md-3 pl-0">
                                <ReactSelect
                                    options={eventTypes.map(rec => {
                                        return {
                                            label: rec.name,
                                            value: rec.status
                                        }
                                    })}
                                    name={"eventType"}
                                    placeholder={'select event type'}
                                    onChange={(e) => {
                                        setQueryParam({ ...queryParam, 'eventType': e ? e.value : null })
                                    }}
                                    value={!_.isEmpty(queryParam) ? queryParam.eventType : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                >
                                </ReactSelect>
                            </div>
                            <div className="col-md-3 pl-0">
                                <ReactSelect
                                    options={triggerTypes}
                                    name={"triggerType"}
                                    placeholder={'select trigger type'}
                                    onChange={(e) => {
                                        setQueryParam({ ...queryParam, 'triggerType': e ? e.value : null })
                                    }}
                                    value={!_.isEmpty(queryParam) ? queryParam.triggerType : ''}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                            setTrigger(null)
                            setPopHeader('Add Trigger')
                            setShowTriggerModel(true)
                        }} ><i className="ico icon-add mr-2"></i>  <Translate text={'Add Trigger'} /> </button>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                triggers.length > 0 ? (
                    <div className="common-table" style={{ width: windowSize.windowWidth + 'px' }}>
                        <TableView
                            datalist={_.orderBy(triggers.filter(({ title, status, triggerType }) =>
                            (!_.isEmpty(queryParam) ? (
                                ((!_.isEmpty(title) && !_.isEmpty(queryParam.searchText)) ? title.toLowerCase().includes(queryParam.searchText.toLowerCase()) : true) &&
                                ((!_.isEmpty(triggerType) && !_.isEmpty(queryParam.triggerType)) ? triggerType.toLowerCase().includes(queryParam.triggerType.toLowerCase()) : true) &&
                                ((!_.isEmpty(status) && !_.isEmpty(queryParam.eventType)) ? status.toLowerCase().includes(queryParam.eventType.toLowerCase()) : true)
                            ) : true)
                            ), ['addedDate.seconds'], ['desc'])}
                            height={windowSize.windowHeight}
                            width={windowSize.windowWidth}
                            columns={triggerlistFields}
                            dynamicFields={triggerlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                            handleActionClick={handleActionClick}
                            handleRowSingleClick={handleTriggerdetailsClick}
                            // hasMore={hasMore}
                            // handlePagination={handlePagination}
                            // isPaging={true}
                            handleSwitchChange={handleSwitchChange}
                            isSorting={true}
                            isTranslate={true}
                            dealersettings={props.dealersettings}
                        />
                    </div>
                ) : (
                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                        <div className="text-center p-5">
                            <p> <Translate text={'No triggers found'} /></p>
                            <button className="btn btn-sm btn-default" onClick={() => {
                                setTrigger(null)
                                setPopHeader('Add Trigger')
                                setShowTriggerModel(true)
                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                <Translate text={'Add Trigger'} />
                            </button>
                        </div>
                    </div>
                )
        }
        <PopUpModal show={showTriggerModel}>
            <AddTrigger
                {...props}
                show={showTriggerModel}
                title={popHeader}
                handleClose={handleModelClose}
                trigger={selectedTrigger}
            />
        </PopUpModal>
    </>);
}

export default Triggers;