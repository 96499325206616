import React, { Component } from 'react'

export default class FullheightIframe extends Component {

    constructor() {
        super();
        this.state = {
            iFrameHeight: window.innerHeight - 320 + 'px',
            isLoaded: false,
            elem: null,
        }
    }

    render() {
        return (
            <iframe 
                title={`email-${this.props.messageID}`}
                id={`email-${this.props.messageID}`}
                style={{width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                onLoad={() => {
                    this.setState({
                        isLoaded: true
                    });
                }} 
                ref="iframe" 
                src={`${window.location.origin}/blank.html?messageID=${this.props.messageID}&token=${this.props.token}&linkedID=${this.props.linkedID}&apiURL=${this.props.apiURL}&threadID=${this.props.threadID}&accountID=${this.props.accountID}&iframeHeight=${this.state.iFrameHeight}&projectId=${this.props.projectId}`}
                width="100%" 
                height={this.state.iFrameHeight} 
                scrolling="no" 
                frameBorder="0"
            />
        );
    }
}