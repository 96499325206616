export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

export const tableHeader = [
    {
        name: 'API',
        value: 'type',
        align: 'left'
    },
    {
        name: 'Usage/Qty',
        value: 'totalQty',
        align: 'center'
    },
    {
        name: 'Unit Cost',
        value: 'unitPrice',
        currency: true,
        align: 'right'
    },
    {
        name: 'Amount',
        value: 'totalAmount',
        currency: true,
        align: 'right'
    }
]