import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import _ from 'lodash'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData, doc } from 'rxfire/firestore';
import Templates from './templates'
import Triggers from './triggers'
import Lookups from './lookups/index'
// import PipelineSettings from './pipeline'
import FieldSettings from './fieldSettings'
import Translate from '../../../constants/translate';
import FormTemplates from "./formTemplates";
import { TemplateContext } from './templateContext'
import CommonHelper from '../../../services/common';
import TradeInPipelines from './tradeinpipeline';
import SortAppraisalFields from "./appraisalOptions";
import WebForms from './webForms';
import SortTradeinOptions from "./tradeinOptions";
import CustomAlerts from './alerts';

const TradeInProWorkFlowSettings = (props) => {
    const [active, setActive] = useState('pipelines')
    const [smsTemplates, setSmsTemplates] = useState([]);
    const [emailtemplates, setEmailTemplates] = useState([]);
    const [edmTemplates, setEdmTemplates] = useState([]);
    const [triggerSmsTemplates, setTriggerSmsTemplates] = useState([]);
    const [loader, setLoader] = useState(true)
    const [clientGroups, setClientGroups] = useState([]);
    const [oemClients, setOEMClients] = useState([]);
    const [clientRegions, setClientRegions] = useState([])
    const [clientSettings, setClientSettings] = useState([])
    const [formTemplates, setFormTemplates] = useState([]);

    const [clientSettingsTradeIns, setClientSettingsTradeIns] = useState([])
    const [clientGroupsTradeIns, setClientGroupsTradeIns] = useState([])
    const [clientRegionsTradeIns, setClientRegionsTradeIns] = useState([])
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    useEffect(() => {
        if (_.isEmpty(props.dealersettings)) {
            return;
        }
        let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates`).
            where('visibility', '==', 'private')
            .where('isDeleted', '==', false)
        privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, props.dealersettings, null, true);

        var alltemplates = combineLatest(
            collectionData(privateTemplatesRef),
            collectionData(window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/tradeinProtemplates`)
                .where('visibility', '==', 'shared')
                .where('isDeleted', '==', false))
        ).pipe(
            map((templates) => {
                var [dealer, shared] = templates;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push(rec)
                })
                setEdmTemplates(listVM.filter(item => item.type === 'email'))
                setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'))
                setTriggerSmsTemplates(listVM.filter(item => item.type === 'sms'))
                setSmsTemplates(listVM.filter(item => item.type === 'smsTemplate'))
                setLoader(false)
            });
        return () => {
            alltemplates && alltemplates.unsubscribe()
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientGroups))
            return

        const refs = []
        clientGroups.forEach(group => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}/tradeinPro/${group.documentID}`)))
        })

        var groups_tradeinpro = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, name: getGroupOrRegionName(lookup.id, clientGroups) })
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientGroupsTradeIns(allDocs)
            });
        return () => {
            groups_tradeinpro && groups_tradeinpro.unsubscribe()
        }
    }, [clientGroups])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientRegions))
            return
        const refs = []
        clientRegions.forEach(region => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${region.documentID}/tradeinPro/${region.documentID}`)))
        })

        var regions_tradein = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, code: lookup.id, name: getGroupOrRegionName(lookup.id, clientRegions) })
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientRegionsTradeIns(allDocs)
            });
        return () => {
            regions_tradein && regions_tradein.unsubscribe()
        }
    }, [clientRegions])

    useEffect(() => {
        const clientsSnapshot = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/clients`)
            .onSnapshot(querySnapshot => {
                let _client = [];
                querySnapshot.forEach((rec) => {
                    _client.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientSettings(_client)
            })
        return () => {
            clientsSnapshot && clientsSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientSettings))
            return

        const refs = []
        clientSettings.forEach(clientOption => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${clientOption.documentID}/tradeinPro/${clientOption.documentID}`)))
        })
        var clients_tradeinpro = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    let valToReturn = []
                    lookups.forEach((lookup) => {
                        valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id },)
                    })
                    return valToReturn
                }))
            .subscribe(allDocs => {
                setClientSettingsTradeIns(allDocs)
            });
        return () => {
            clients_tradeinpro && clients_tradeinpro.unsubscribe()
        }
    }, [clientSettings])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', props.dealersettings.client.settingsID)
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])


    useEffect(() => {
        if (_.isEmpty(props.dealersettings)) {
            return;
        }
        let privateFormRef = window.firebase.firestore().collection(`formTemplates`)
            .where('clientID', '==', props.dealersettings.client.id)
            .where('visibility', '==', 'private')
            .where('type', '==', 'tradeinPro')
            .where('isDeleted', '==', false);

        var allforms = combineLatest(
            collectionData(privateFormRef),
            collectionData(window.firebase.firestore().collection(`formTemplates`)
                .where('oemID', '==', props.dealersettings.oemID)
                .where('visibility', '==', 'shared')
                .where('type', '==', 'tradeinPro')
                .where('isDeleted', '==', false))
        ).pipe(
            map((forms) => {
                var [dealer, shared] = forms;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push({
                            ...rec
                        })
                })
                setFormTemplates(listVM)
            });
        return () => {
            allforms && allforms.unsubscribe()
        }
    }, [])

    const smsTemplatesMemo = useMemo(() => smsTemplates, [smsTemplates])
    const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates])
    const edmTemplatesMemo = useMemo(() => edmTemplates, [edmTemplates])
    const triggerSmsTemplatesMemo = useMemo(() => triggerSmsTemplates, [triggerSmsTemplates])
    const clientsMemo = useMemo(() => oemClients, [oemClients])

    const groupsMemo = useMemo(() => clientGroupsTradeIns, [clientGroupsTradeIns])
    const regionsMemo = useMemo(() => clientRegionsTradeIns, [clientRegionsTradeIns])
    const clientSettingsMemo = useMemo(() => clientSettingsTradeIns, [clientSettingsTradeIns])
    const formTemplatesMemo = useMemo(() => formTemplates, [formTemplates])

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            key = "pipelines"
        }
        return key
    }

    const getGroupOrRegionName = (id, dataArr) => {
        if (dataArr.filter(a => a.documentID === id).length && dataArr.filter(a => a.documentID === id)[0].name) {
            return dataArr.filter(a => a.documentID === id)[0].name
        } else {
            return ''
        }
    }

    return (
        <>
            <Tab.Container defaultActiveKey={`${getQueryparam()}`} mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4> <Translate text={'Trade-In Pro Workflow Setup'} /></h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="pipelines" bsPrefix="li-a-nav"> <Translate text={'Pipeline Settings'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="fields" bsPrefix="li-a-nav"> <Translate text={'Mandatory Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="templates" bsPrefix="li-a-nav"> <Translate text={'Templates'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="webForms" bsPrefix="li-a-nav"> <Translate text={'Web Forms'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="formtemplates" bsPrefix="li-a-nav"> <Translate text={'Form Templates'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="triggers" bsPrefix="li-a-nav"> <Translate text={'Triggers'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="lookups" bsPrefix="li-a-nav"> <Translate text={'Dynamic Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="tradeinOptions" bsPrefix="li-a-nav"> <Translate text={'Tradein Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="enquiryOptions" bsPrefix="li-a-nav"> <Translate text={'Appraisal Fields'} /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="alerts" bsPrefix="li-a-nav"> <Translate text={'alerts'} /></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <TemplateContext.Provider value={{ smsTemplateList: smsTemplatesMemo, emailTemplateList: emailTemplatesMemo, edmTemplates: edmTemplatesMemo, triggerSmsTemplates: triggerSmsTemplatesMemo, loading: loader, groups: groupsMemo, clients: clientsMemo, regions: regionsMemo, clientSettings: clientSettingsMemo, formTemplates: formTemplatesMemo }}>
                    <div className={`settings-container mb-0 ${liteVersion ? '' : 'h-100'}`} style={liteVersion ? { overflow: 'hidden' } : {}}>
                        <div className="settings-container-sub" >
                            <Tab.Pane eventKey="pipelines">
                                <TradeInPipelines activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fields">
                                <FieldSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="templates">
                                <Templates activeKey={active} {...props} />
                                {/* <></> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="triggers">
                                <Triggers activeKey={active} {...props} />
                                {/* <></> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="lookups">
                                <Lookups activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="formtemplates">
                                <FormTemplates activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="enquiryOptions">
                                <SortAppraisalFields activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="webForms">
                                <WebForms activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tradeinOptions">
                                <SortTradeinOptions activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="alerts">
                                <CustomAlerts activeKey={active} {...props} />
                            </Tab.Pane>
                        </div>
                    </div>
                </TemplateContext.Provider>
            </Tab.Container>
        </>
    )
}

export default TradeInProWorkFlowSettings;