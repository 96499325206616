import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import trim from 'lodash/trim';

import {
	InputCheckBox,
	InputRadio,
	InputTextArea,
	ReactSelect,
	InputText,
	ReactMultiSelect,
	AntDatePicker,
	ReactTimePicker,
} from '../../../components';
import { activityLogOptions } from '../../pipeline/viewModel';
import { firestoreDB, removeHTMLTags, validateEmail } from '../../../services/helper';
import Translate from '../../../constants/translate';
import '../../../styles/customer-feedbackform.scss';
import './web-form.scss';
import common from '../../../services/common';

const EmbedForm = props => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const _preview = params.get('preview');

	const [splitformFields, setSplitFormFields] = useState();
	const [formFields, setFormFields] = useState();
	const [webFormTemplate, setWebFormTemplate] = useState();
	// const [clientData, setClient] = useState();
	const [view, setView] = useState('form');
	const [loader, setLoader] = useState(true);
	const [errorFields, setError] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [customerReview, setCustomerReview] = useState({});
	const [makes, setMakes] = useState([]);
	const [models, setModels] = useState([]);
	const [states, setStates] = useState([]);
	const [countries, setCountries] = useState([]);
	const [dealers, setDealers] = useState([]);
	const [successAlert, setSuccessAlert] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);
	const [activeForm, setActiveForm] = useState('');


	const sendPostMessage = () => {
		const iframe = document.querySelector('.dynamicform-embed-previewwrap')
		let message = { height: iframe ? iframe.scrollHeight : document.body.scrollHeight };
		window.top.postMessage(message, "*");
	}


	useEffect(() => {
		const getData = async () => {
			if (props.id) {
				const ref = window.firebase
					.firestore()
					.collection(`webFormTemplates`)
					.doc(props.id);
				const snapshots = await ref.get();

				if (snapshots.exists) {
					let splitFields = [];
					let arr = [];
					const formDoc = snapshots.data();
					let _allFields = snapshots.data().fields;
					if (_allFields[_allFields.length - 1] && _allFields[_allFields.length - 1].type === 'pageBreak') {
						_allFields = _allFields.splice(0, (_allFields.length - 1))
					}
					_allFields.forEach(rec => {
						arr.push(rec.name);
						if (rec.type === 'pageBreak') {
							splitFields.push(arr.splice(0, arr.length - 1));
							arr = [];
						}
					})
					splitFields.push(arr.splice(0, arr.length));
					setActiveForm('form-0')
					setSplitFormFields(splitFields);
					setFormFields(_allFields);
					setWebFormTemplate({
						...snapshots.data(),
						documentID: snapshots.id,
						title: snapshots.data().title,
					});
					setView('form');
					setLoader(false);
					sendPostMessage();
					// get make and models if there is a make and model fields otherwise dont fetch
					const allBrandsSnapshot = await window.firebase
						.firestore()
						.collection(`brands`)
						.where('isDeleted', '==', false)
						.get();
					const modelpromises = [];
					let allMakeModels = [];
					allBrandsSnapshot.docs.forEach(brand => {
						allMakeModels.push(brand.data());
						modelpromises.push(
							window.firebase
								.firestore()
								.doc(`brands/${brand.id}/models/${brand.id}`)
								.get(),
						);
					});
					const modelSnapshots = await Promise.all(modelpromises);
					modelSnapshots.forEach(snap => {
						allMakeModels = map(allMakeModels, function (make) {
							return snap.exists && snap.id === make.documentID
								? {
									...make,
									models: snap.data().models,
								}
								: {
									...make,
								};
						});
					});
					allMakeModels = orderBy(allMakeModels, ['name'], ['asc']);
					// console.log('allMakeModels', allMakeModels);
					let _makes = [];
					let _models = [];
					allMakeModels
						.filter(a => a.models?.length && (formDoc?.makes?.length ? formDoc.makes.includes(a.value) : true))
						.forEach(({ name, value, models }) => {
							_makes.push({
								name,
								value,
								label: name,
							});
							_models.push({
								name,
								value,
								models: models.map(a => {
									return {
										name: a.name,
										value: a.value,
										label: a.name,
									};
								}),
							});
						});
					setMakes(_makes);
					setModels(_models);
					if (_makes.length === 1 && _.find(formDoc.fields, { type: 'makeAndModel' })) {
						setCustomerReview({ make: _makes[0].value })
					}
					if (_allFields.some(a => a.type === 'state')) {
						const clientRef = await window.firebase
							.firestore()
							.collection(`clients`)
							.doc(snapshots.data().levelID).get();
						const remoteConfig = window.firebase.remoteConfig();
						try {
							await remoteConfig.fetchAndActivate()
								.then(async () => {
									let baseURL = window.location.origin;//remoteConfig.getString('baseURL');
									const states = remoteConfig.getString('states');

									const responseState = await fetch(baseURL + states);
									const statejson = await responseState.json();
									setStates(statejson
										.filter(item => clientRef?.data()?.country ? item.country_code === clientRef?.data()?.country : item.country_code === 'AU')
										.map(a => {
											return {
												name: a.name,
												value: a.code,
												label: a.name,
											};
										}));
								})

						} catch (error) {
							console.log('lookups', error);
						}
					}
					if (_allFields.some(a => a.type === 'country')) {
						const remoteConfig = window.firebase.remoteConfig();
						try {
							await remoteConfig.fetchAndActivate()
								.then(async () => {
									let baseURL = window.location.origin;//remoteConfig.getString('baseURL');
									const countries = remoteConfig.getString('countries');
									const responseCountry = await fetch(baseURL + countries);
									const countryjson = await responseCountry.json();
									setCountries(countryjson
										.map(a => {
											return {
												name: a.name,
												value: a.code,
												label: a.name,
											};
										}));
								})

						} catch (error) {
							console.log('lookups', error);
						}
					}
					if (_allFields.some(a => a.type === 'dealer')) {
						if (formDoc?.dealers?.length) {
							setDealers(formDoc.dealers.map(r => {
								return {
									label: r.n,
									value: r.id
								}
							}));

						} else {
							let clientsRef = window.firebase.firestore().collection('clients');

							if (snapshots.data().level === 'oem') {
								clientsRef = clientsRef.where('settingsID', '==', snapshots.data().oemID);
							} else if (snapshots.data().level === 'individual') {
								clientsRef = clientsRef.where('documentID', '==', snapshots.data().clientID);
							} else if (snapshots.data().level === 'group') {
								clientsRef = clientsRef.where('group', '==', snapshots.data().groupID);
							} else if (snapshots.data().level === 'region') {
								clientsRef = clientsRef.where('region', '==', snapshots.data().regionID);
							} else {
								clientsRef = clientsRef.where('documentID', '==', 'NODEALER');
							}

							const allClients = await clientsRef.get();
							if (allClients.size > 0) {
								let _dealer = [];
								allClients.docs.forEach(rec => {
									_dealer.push({
										label: rec.data().name,
										value: rec.id,
										country: rec.data().country,
									})
								})
								setDealers(_dealer);
							}
						}
					}
				} else {
					setErrorAlert(true);
					setLoader(false);
				}
			} else {
				setErrorAlert(true);
				setLoader(false);
			}
		};

		getData();
	}, []);

	const handleTypeChange = e => {
		if (_preview) {
			return;
		}
		const { name, value } = e.target;
		// console.log('handleTypeChange name, value', name, value);
		setCustomerReview({
			...customerReview,
			[name]: value,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleCheckChange = (e, value) => {
		if (_preview) {
			return;
		}
		const { name, checked, id } = e.target;
		// console.log('handleCheckChange name, checked, id', name, checked, id);
		let arrayToSet =
			customerReview && customerReview[name] ? [...customerReview[name]] : [];
		if (checked === true) {
			arrayToSet.push(value);
		} else {
			arrayToSet = arrayToSet.filter(a => a !== value);
		}
		// console.log('handleCheckChange arrayToSet', arrayToSet);
		setCustomerReview({
			...customerReview,
			[name]: arrayToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleDateChange = (val, name) => {
		// console.log('handleDateChange', val, name);
		let dateToSet = '';

		if (val) dateToSet = moment(val).format('YYYY-MM-DD');
		else dateToSet = null;

		setCustomerReview({
			...customerReview,
			[name]: dateToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleDateTimeChange = (val, name) => {
		// console.log('handleDateChange', val, name);
		let dateToSet = '';

		if (val) dateToSet = moment(val).format('YYYY-MM-DD') + ' ' + moment(customerReview?.[name] || new Date()).format('hh:mm A');
		else dateToSet = null;

		setCustomerReview({
			...customerReview,
			[name]: dateToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleTimeChange = (val, name) => {
		// console.log('handleDateChange', val, name);
		let dateToSet = '';

		if (val) dateToSet = moment(customerReview?.[name] || val).format('YYYY-MM-DD') + ' ' + moment(val).format('hh:mm A');
		else dateToSet = null;

		setCustomerReview({
			...customerReview,
			[name]: dateToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleOnChange = e => {
		if (_preview) {
			return;
		}
		e.preventDefault();
		const { name, value } = e.target;
		let valueToSet = '';
		if (value)
			valueToSet = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
				return c.toUpperCase();
			});
		else valueToSet = '';

		// console.log('handleOnChange : name | valueToSet', name, valueToSet);
		setCustomerReview({
			...customerReview,
			[name]: valueToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleOnEmailChange = e => {
		if (_preview) {
			return;
		}
		e.preventDefault();
		const { name, value } = e.target;
		let valueToSet = '';
		if (value) valueToSet = value;
		else valueToSet = '';

		// console.log('handleOnChange : name | valueToSet', name, valueToSet);
		setCustomerReview({
			...customerReview,
			[name]: valueToSet,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleOnPhoneChange = e => {
		if (_preview) {
			return;
		}
		e.preventDefault();
		const { name, value } = e.target;
		let valueToSet = '';

		let regexp = /^[0-9 \b]+$/;
		let telephone = trim(value);

		if (telephone === '' || regexp.test(telephone)) {
			valueToSet = trim(value);
			setCustomerReview({
				...customerReview,
				[name]: valueToSet,
			});
			setError({
				...errorFields,
				[name]: '',
			});
		}
		// console.log('handleOnChange : name | valueToSet', name, valueToSet);
	};

	const handleReactSelectChange = (e, data) => {
		// console.log(
		// 	'handleReactSelectChange : name | valueToSet',
		// 	data.name,
		// 	e && e.value && e.value,
		// );
		if (!isEmpty(e) && e.value) {
			if (data.name === 'make') {
				setCustomerReview({
					...customerReview,
					[data.name]: e.value,
					model: '',
				});
			} else {
				setCustomerReview({
					...customerReview,
					[data.name]: e.value,
				});
			}
		} else {
			if (data.name === 'make') {
				setCustomerReview({
					...customerReview,
					[data.name]: '',
					model: '',
				});
			} else {
				setCustomerReview({
					...customerReview,
					[data.name]: '',
				});
			}
		}
		setError({
			...errorFields,
			[data.name]: '',
		});
	};

	const handleReactMultiSelectChange = (selectedOptions, name) => {
		const value = [];
		!isEmpty(selectedOptions) &&
			selectedOptions.map(data => {
				value.push(data.value);
			});
		// console.log('handleReactMultiSelectChange : name | value', name, value);
		setCustomerReview({
			...customerReview,
			[name]: value,
		});
		setError({
			...errorFields,
			[name]: '',
		});
	};

	const handleSubmit = async e => {
		if (_preview || submitLoader) {
			return;
		}

		e.preventDefault();
		let formIsValid = true;
		let errorClass = 'feedback-invalid-red';
		let errors = {};

		formFields
			.filter(a => a.required === true)
			.forEach(rec => {
				if (rec.name.includes('makeAndModel')) {
					if (
						isEmpty(customerReview) ||
						!customerReview['make'] ||
						!customerReview['model']
					) {
						formIsValid = false;
						errors['make'] = errorClass;
						errors['model'] = errorClass;
					}
				} else if (rec.type === 'firstAndLastName') {
					if (
						isEmpty(customerReview) ||
						!customerReview['first-name'] ||
						!customerReview['last-name']
					) {
						formIsValid = false;
						if (!customerReview['first-name'])
							errors['first-name'] = errorClass;
						if (!customerReview['last-name'])
							errors['last-name'] = errorClass;
					}
					if (!isEmpty(customerReview) && customerReview['first-name'] && !customerReview['first-name'].trim()) {
						formIsValid = false;
						errors['first-name'] = errorClass;
					}
					if (!isEmpty(customerReview) && customerReview['last-name'] && !customerReview['last-name'].trim()) {
						formIsValid = false;
						errors['last-name'] = errorClass;
					}
				} else if (rec.type === 'phone') {
					if (isEmpty(customerReview) || !customerReview['phone']) {
						formIsValid = false;
						errors['phone'] = errorClass;
					}
				} else if (rec.type === 'country') {
					if (isEmpty(customerReview) || !customerReview['country']) {
						formIsValid = false;
						errors['country'] = errorClass;
					}
				} else if (rec.type === 'dealer') {
					if (isEmpty(customerReview) || !customerReview['dealer']) {
						formIsValid = false;
						errors['dealer'] = errorClass;
					}
				} else if (rec.type === 'state') {
					if (isEmpty(customerReview) || !customerReview['state']) {
						formIsValid = false;
						errors['state'] = errorClass;
					}
				} else if (rec.type === 'regNo') {
					if (isEmpty(customerReview) || !customerReview['regNo']) {
						formIsValid = false;
						errors['regNo'] = errorClass;
					}
				} else if (rec.type === 'email') {
					if (isEmpty(customerReview) || !customerReview['email']) {
						formIsValid = false;
						errors['email'] = errorClass;
					}
					if (
						!isEmpty(customerReview) &&
						customerReview['email'] &&
						!validateEmail(customerReview['email'])
					) {
						formIsValid = false;
						errors['email'] = errorClass;
					}
				} else {
					if (
						isEmpty(customerReview) ||
						(!isObject(customerReview[rec.name]) &&
							!isBoolean(customerReview[rec.name]) &&
							!isNumber(customerReview[rec.name]) &&
							!customerReview[rec.name])
					) {
						formIsValid = false;
						errors[rec.name] = errorClass;
					} else if (customerReview[rec.name] && _.isEmpty(customerReview[rec.name])) {
						formIsValid = false;
						errors[rec.name] = errorClass;
					}
				}
			});

		if (
			!isEmpty(customerReview) &&
			customerReview['email'] &&
			customerReview['email'].length > 1 &&
			!validateEmail(customerReview['email'])
		) {
			formIsValid = false;
			errors['email'] = errorClass;
		}
		setError(errors);
		if (!formIsValid) {
			return;
		}
		setSubmitLoader(true);
		const submissionDocID = window.firebase
			.firestore()
			.collection(`webFormsSubmissions`)
			.doc().id;

		const submissionObj = {
			documentID: submissionDocID,
			templateID: webFormTemplate.documentID,
			collection: 'webFormsSubmissions',
			response: customerReview,
			formFields: formFields,
			modifiedDate: window.firebase.firestore.Timestamp.now(),
			addedDate: window.firebase.firestore.Timestamp.now(),
		};

		if (webFormTemplate.level === 'oem') {
			submissionObj.oemID = webFormTemplate.oemID;
		} else if (webFormTemplate.level === 'individual') {
			submissionObj.clientID = webFormTemplate.clientID;
		} else if (webFormTemplate.level === 'group') {
			submissionObj.groupID = webFormTemplate.groupID;
		} else if (webFormTemplate.level === 'region') {
			submissionObj.regionID = webFormTemplate.regionID;
		}

		// const submissionRef = window.firebase
		// 	.firestore()
		// 	.doc(`webFormSubmissions/${submissionDocID}`);

		// await submissionRef.set(submissionObj, { merge: true });

		const submissionRef = window.firebase
			.functions()
			.httpsCallable('generic-saveData');

		await submissionRef({
			documentID: submissionDocID,
			collection: 'webFormSubmissions',
			// templateID: webFormTemplate.documentID,
			data: {
				...submissionObj,
			},
		});

		if (
			webFormTemplate.addTo === 'activity' &&
			webFormTemplate.activityType &&
			webFormTemplate.owner
		) {
			let dateNow = window.firebase.firestore.Timestamp.now();

			if (formFields.filter(a => a.type === 'date').length) {
				let dateKey = formFields.filter(a => a.type === 'date')[0].name;
				if (!isEmpty(customerReview[`${dateKey}`])) {
					dateNow = window.firebase.firestore.Timestamp.fromDate(
						moment(customerReview[`${dateKey}`]).toDate(),
					);
				}
			}

			let ref = ''
			let _activityTypes = [...activityLogOptions]
			let projectId = null;
			if (webFormTemplate.level === 'oem') {
				ref = window.firebase
					.firestore()
					.collection('clientSettings')
					.doc(webFormTemplate.oemID)
					.collection('currentSettings')
					.doc(webFormTemplate.oemID)
			} else if (webFormTemplate.level === 'individual' && webFormTemplate.clientID) {
				ref = window.firebase
					.firestore()
					.collection('clients')
					.doc(webFormTemplate.clientID)
					.collection('currentSettings')
					.doc(webFormTemplate.clientID)
			} else if (webFormTemplate.level === 'group' && webFormTemplate.levelID && webFormTemplate.settingsID) {
				ref = window.firebase
					.firestore()
					.collection('clientSettings')
					.doc(webFormTemplate.oemID)
					.collection('groups')
					.doc(webFormTemplate.levelID)
					.collection('currentSettings')
					.doc(webFormTemplate.levelID)
			} else if (webFormTemplate.level === 'region' && webFormTemplate.levelID && webFormTemplate.settingsID) {
				ref = window.firebase
					.firestore()
					.collection('clientSettings')
					.doc(webFormTemplate.oemID)
					.collection('regions')
					.doc(webFormTemplate.levelID)
					.collection('currentSettings')
					.doc(webFormTemplate.levelID)
			}

			if (ref) {
				const snap = await ref.get()
				if (snap.exists) {
					let currentSettings = snap.data()
					if (
						!isEmpty(currentSettings)
						&& webFormTemplate.level !== 'region'
						&& webFormTemplate.level !== 'group'
						&& !isEmpty(currentSettings.settings)
						&& !isEmpty(currentSettings.settings.activityTypes)
					) {
						_activityTypes = [...currentSettings.settings.activityTypes]
					} else if (
						!isEmpty(currentSettings)
						&& !isEmpty(currentSettings)
						&& !isEmpty(currentSettings.activityTypes)
						&& (webFormTemplate.level === 'region' || webFormTemplate.level === 'group')
					) {
						_activityTypes = [...currentSettings.activityTypes]
					}
					projectId = currentSettings?.projectId ? currentSettings.projectId : null
				}
			}
			// console.log('_activityTypes', _activityTypes)

			const activityObj = {
				addedDate: window.firebase.firestore.Timestamp.now(),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				addedBy: webFormTemplate.owner,
				modifiedBy: webFormTemplate.owner,
				documentID: firestoreDB(props.dealersettings).firestore().collection('activities').doc()
					.id,
				isDeleted: false,
				isDone: false,
				owner: webFormTemplate.owner,
				startDate: window.firebase.firestore.Timestamp.fromDate(
					dateNow.toDate(),
				),
				endDate: window.firebase.firestore.Timestamp.fromDate(
					moment(dateNow.toDate()).add(1, 'hours').toDate(),
				),
				subType: webFormTemplate.activityType,
				title: _activityTypes.filter(
					a => a.value === webFormTemplate.activityType,
				).length
					? _activityTypes.filter(
						a => a.value === webFormTemplate.activityType,
					)[0].name
					: 'Appointment',
				type: 'activity',
				notes: '',
				tags: ['webform'],
				projectId: webFormTemplate.projectId ? webFormTemplate.projectId : null
			};

			if (webFormTemplate.level === 'oem') {
				activityObj.oemID = webFormTemplate.oemID;
				activityObj.level = webFormTemplate.level;
				activityObj.levelID = webFormTemplate.oemID;
			} else if (webFormTemplate.level === 'individual') {
				activityObj.clientID = webFormTemplate.clientID;
				activityObj.level = webFormTemplate.level;
				activityObj.levelID = webFormTemplate.levelID;
			} else if (webFormTemplate.level === 'group') {
				activityObj.groupID = webFormTemplate.groupID;
				activityObj.level = webFormTemplate.level;
				activityObj.levelID = webFormTemplate.levelID;
			} else if (webFormTemplate.level === 'region') {
				activityObj.regionID = webFormTemplate.regionID;
				activityObj.level = webFormTemplate.level;
				activityObj.levelID = webFormTemplate.levelID;
			}

			if (customerReview['first-name']) {
				activityObj.notes = `${activityObj.notes} First Name: ${customerReview['first-name'].trim()} \n`;
			}

			if (customerReview['last-name']) {
				activityObj.notes = `${activityObj.notes} Last Name: ${customerReview['last-name'].trim()} \n`;
			}

			if (customerReview['email']) {
				activityObj.notes = `${activityObj.notes} Email: ${customerReview['email']} \n`;
			}

			if (customerReview['phone']) {
				activityObj.notes = `${activityObj.notes} Phone: ${customerReview['phone']} \n`;
			}

			if (customerReview['make']) {
				activityObj.notes = `${activityObj.notes} Make: ${customerReview['make']} \n`;
			}

			if (customerReview['model']) {
				activityObj.notes = `${activityObj.notes} Model: ${customerReview['model']} \n`;
			}

			if (customerReview['country']) {
				activityObj.notes = `${activityObj.notes} Country: ${customerReview['country']} \n`;
			}

			if (customerReview['dealer']) {
				activityObj.notes = `${activityObj.notes} Dealer: ${customerReview['dealer']} \n`;
			}

			if (
				customerReview['textarea-comments'] &&
				customerReview['textarea-comments'].length > 3
			) {
				activityObj.notes = `${activityObj.notes} Comments: ${customerReview['textarea-comments']} \n`;
			}

			// console.log('activityObj', activityObj)

			const genericSaveData = window.firebase
				.functions()
				.httpsCallable('generic-saveData');

			const resp = await genericSaveData({
				documentID: activityObj.documentID,
				collection: 'activities',
				data: {
					...activityObj,
				},
			});
			// console.log('resp func', resp)

			let _reqObject = {
				userID: activityObj.owner,
				notification: {
					title: 'Web Form',
					body: `Activity Recieved from Web Form`,
					type: 'activity',
					recordID: activityObj.documentID,
				},
			};

			if (webFormTemplate.level === 'oem') {
				_reqObject.notification.oemID = webFormTemplate.oemID;
			} else if (webFormTemplate.level === 'individual') {
				_reqObject.notification.clientID = webFormTemplate.clientID;
			} else if (webFormTemplate.level === 'group') {
				_reqObject.notification.groupID = webFormTemplate.groupID;
			} else if (webFormTemplate.level === 'region') {
				_reqObject.notification.regionID = webFormTemplate.regionID;
			}
			// console.log('generic-notify', _reqObject)
			const genericNotify = window.firebase
				.functions()
				.httpsCallable('generic-notify');
			const notifyResp = await genericNotify(_reqObject);
			// console.log('notifyResp func', notifyResp)

			setSubmitLoader(false);
			setSuccessAlert(true);
			setTimeout(() => {
				setSuccessAlert(false);
			}, 10000);
		} else if (webFormTemplate.addTo === 'inbound') {
			let dateNow = window.firebase.firestore.Timestamp.now();

			if (formFields.filter(a => a.type === 'date').length) {
				let dateKey = formFields.filter(a => a.type === 'date')[0].name;
				if (!isEmpty(customerReview[`${dateKey}`])) {
					dateNow = window.firebase.firestore.Timestamp.fromDate(
						moment(customerReview[`${dateKey}`]).toDate(),
					);
				}
			}

			const inboundObj = {
				notes: generateNotes(customerReview),
				systemType: 'webform',
				addedDate: window.firebase.firestore.Timestamp.now(),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				isDeleted: false,
				pipeline: 'LeadsBucket',
				refID: submissionDocID,
				documentID: submissionDocID,
				status: 0,
				stage: 'InboundLead',

				origin: webFormTemplate.origin ? webFormTemplate.origin : '',
				enquiryType: webFormTemplate.enquiryType
					? webFormTemplate.enquiryType
					: '',
				campaign: webFormTemplate.campaign ? webFormTemplate.campaign : '',
				projectId: webFormTemplate.projectId ? webFormTemplate.projectId : null
			};

			if (customerReview['make'] || customerReview['model']) {
				inboundObj.requirement = {
					model: customerReview['model'] ? customerReview['model'] : '',
					make: customerReview['make'] ? customerReview['make'] : '',
				};
			}

			if (webFormTemplate.level === 'oem') {
				inboundObj.oemID = webFormTemplate.oemID;
				inboundObj.oemStatus = 'pending';
			} else if (webFormTemplate.level === 'individual') {
				inboundObj.clientID = webFormTemplate.clientID;
			} else if (webFormTemplate.level === 'group') {
				inboundObj.groupID = webFormTemplate.groupID;
			} else if (webFormTemplate.level === 'region') {
				inboundObj.regionID = webFormTemplate.regionID;
			}

			if (
				customerReview['first-name'] ||
				customerReview['last-name'] ||
				customerReview['phone'] ||
				customerReview['email']
			) {
				inboundObj.contact = {
					// fill in the contact details
					firstName: customerReview['first-name']
						? customerReview['first-name'].trim()
						: '',
					lastName: customerReview['last-name']
						? customerReview['last-name'].trim()
						: '',
					email: customerReview['email'] ? customerReview['email'] : '',
					phone: customerReview['phone'] ? customerReview['phone'] : '',
					country: customerReview['country'] ? customerReview['country'] : '',
				};
			}
			if (customerReview['dealer']) {
				inboundObj.clientID = customerReview['dealer'];
			}
			// console.log('inboundObj', inboundObj)

			// const inboundRef = window.firebase.firestore().collection('inboundLeads').doc(inboundObj.documentID)
			// await inboundRef.set(inboundObj, {merge: true})

			const genericSaveData = window.firebase
				.functions()
				.httpsCallable('generic-saveData');

			// setTimeout(() => {
			// 	setSubmitLoader(false);
			// 	setView('end');
			// }, 5000);

			const resp = await genericSaveData({
				documentID: inboundObj.documentID,
				collection: 'inboundLeads',
				data: {
					...inboundObj,
				},
			});
			// console.log('resp func', resp)
			setSubmitLoader(false);
			setSuccessAlert(true);
			setTimeout(() => {
				setSuccessAlert(false);
			}, 10000);
		} else if (webFormTemplate.addTo === 'tradeinProInbound') {
			let dateNow = window.firebase.firestore.Timestamp.now();

			if (formFields.filter(a => a.type === 'date').length) {
				let dateKey = formFields.filter(a => a.type === 'date')[0].name;
				if (!isEmpty(customerReview[`${dateKey}`])) {
					dateNow = window.firebase.firestore.Timestamp.fromDate(
						moment(customerReview[`${dateKey}`]).toDate(),
					);
				}
			}

			const inboundObj = {
				notes: generateNotes(customerReview),
				systemType: 'webform',
				addedDate: window.firebase.firestore.Timestamp.now(),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				isDeleted: false,
				refID: submissionDocID,
				documentID: submissionDocID,
				status: 0,
				projectId: webFormTemplate.projectId ? webFormTemplate.projectId : null,
				origin: webFormTemplate.origin ? webFormTemplate.origin : '',
			};
			if (customerReview['make'] || customerReview['model'] || customerReview['regNo']) {
				inboundObj.tradein = {
					model: customerReview['model'] ? customerReview['model'] : '',
					make: customerReview['make'] ? customerReview['make'] : '',
					regNo: customerReview['regNo'] ? customerReview['regNo'] : null,
				};
			}

			if (webFormTemplate.level === 'oem') {
				inboundObj.oemID = webFormTemplate.oemID;
				inboundObj.oemStatus = 'pending';
			} else if (webFormTemplate.level === 'individual') {
				inboundObj.clientID = webFormTemplate.clientID;
			} else if (webFormTemplate.level === 'group') {
				inboundObj.groupID = webFormTemplate.groupID;
			} else if (webFormTemplate.level === 'region') {
				inboundObj.regionID = webFormTemplate.regionID;
			}

			if (
				customerReview['first-name'] ||
				customerReview['last-name'] ||
				customerReview['phone'] ||
				customerReview['email'] ||
				customerReview['state']
			) {
				inboundObj.contact = {
					// fill in the contact details
					firstName: customerReview['first-name']
						? customerReview['first-name'].trim()
						: '',
					lastName: customerReview['last-name']
						? customerReview['last-name'].trim()
						: '',
					email: customerReview['email'] ? customerReview['email'] : '',
					phone: customerReview['phone'] ? customerReview['phone'] : '',
					state: customerReview['state'] ? customerReview['state'] : '',
				};
			}

			const genericSaveData = window.firebase
				.functions()
				.httpsCallable('generic-saveData');

			// setTimeout(() => {
			// 	setSubmitLoader(false);
			// 	setView('end');
			// }, 5000);

			const resp = await genericSaveData({
				documentID: inboundObj.documentID,
				collection: 'tradeinProInbound',
				data: {
					...inboundObj,
				},
			});
			// console.log('resp func', resp)
			setSubmitLoader(false);
			setSuccessAlert(true);
			setTimeout(() => {
				setSuccessAlert(false);
			}, 10000);
		}
		setLoader(false);
		setSubmitLoader(false);
		setSuccessAlert(true);
		setCustomerReview({});
		setActiveForm('form-0')
		setTimeout(function () {
			sendPostMessage();
		}, 100);
		if (document.getElementsByClassName('form-control notes')) {
			document.getElementsByClassName('form-control notes').forEach(rec => {
				rec.value = ''
			})
		}

		if (window.top && webFormTemplate.redirectURL) {
			try {
				// Open the URL in the parent window
				window.top.location.href = webFormTemplate.redirectURL;

				// Close the current child window or iframe
				window.close();
			} catch (error) {
				console.error('Error opening URL in parent window:', error);
			}
		}
	};

	const generateNotes = (data) => {
		let notes = '';
		const fieldTypes = ['date', 'datetime', 'text', 'textarea', 'select', 'checkbox-group', 'radio-group'];
		formFields.forEach(rec => {
			if (fieldTypes.includes(rec.type) && data[rec.name] && removeHTMLTags(rec.label)) {
				if (rec.type === 'date' || rec.type === 'datetime') {
					notes += `${removeHTMLTags(rec.label)} : ${moment(data[rec.name]).format(`DD/MM/YYYY${rec.type === 'datetime' ? ' hh:mm A' : ''}`)} \n`
				} else {
					notes += `${removeHTMLTags(rec.label)} : ${_.isArray(data[rec.name]) ?
						rec.values.filter(a => data[rec.name].includes(a.value)).map(r => { return removeHTMLTags(r.label) }).join(',') :
						(rec.values && rec.values.find(e => e.value === data[rec.name]) ? rec.values.find(e => e.value === data[rec.name]).label : data[rec.name])} \n`
				}
			}
		});
		if (data['state']) {
			notes += `State : ${common.getLabelByValue(states, data['state'], '')} \n`;
		}
		const customfieldTypes = ['make', 'model', 'regNo'];
		customfieldTypes.forEach(rec => {
			if (data[rec]) {
				notes += `${common.autoCaps(rec)} : ${data[rec]} \n`;
			}
		})
		return notes;
	}

	const checkMand = (mandIndex) => {
		let formIsValid = true;
		let errorClass = 'feedback-invalid-red';
		let errors = {};

		formFields
			.filter(a => a.required === true && splitformFields[mandIndex].includes(a.name))
			.forEach(rec => {
				if (rec.name.includes('makeAndModel')) {
					if (
						isEmpty(customerReview) ||
						!customerReview['make'] ||
						!customerReview['model']
					) {
						formIsValid = false;
						errors['make'] = errorClass;
						errors['model'] = errorClass;
					}
				} else if (rec.type === 'firstAndLastName') {
					if (
						isEmpty(customerReview) ||
						!customerReview['first-name'] ||
						!customerReview['last-name']
					) {
						formIsValid = false;
						if (!customerReview['first-name'])
							errors['first-name'] = errorClass;
						if (!customerReview['last-name'])
							errors['last-name'] = errorClass;
					}
					if (!isEmpty(customerReview) && customerReview['first-name'] && !customerReview['first-name'].trim()) {
						formIsValid = false;
						errors['first-name'] = errorClass;
					}
					if (!isEmpty(customerReview) && customerReview['last-name'] && !customerReview['last-name'].trim()) {
						formIsValid = false;
						errors['last-name'] = errorClass;
					}
				} else if (rec.type === 'phone') {
					if (isEmpty(customerReview) || !customerReview['phone']) {
						formIsValid = false;
						errors['phone'] = errorClass;
					}
				} else if (rec.type === 'email') {
					if (isEmpty(customerReview) || !customerReview['email']) {
						formIsValid = false;
						errors['email'] = errorClass;
					}
					if (
						!isEmpty(customerReview) &&
						customerReview['email'] &&
						!validateEmail(customerReview['email'])
					) {
						formIsValid = false;
						errors['email'] = errorClass;
					}
				} else if (rec.type === 'regNo') {
					if (isEmpty(customerReview) || !customerReview['regNo']) {
						formIsValid = false;
						errors['regNo'] = errorClass;
					}
				} else if (rec.type === 'state') {
					if (isEmpty(customerReview) || !customerReview['state']) {
						formIsValid = false;
						errors['state'] = errorClass;
					}
				} else {
					if (
						isEmpty(customerReview) ||
						(!isObject(customerReview[rec.name]) &&
							!isBoolean(customerReview[rec.name]) &&
							!isNumber(customerReview[rec.name]) &&
							!customerReview[rec.name])
					) {
						formIsValid = false;
						errors[rec.name] = errorClass;
					} else if (customerReview[rec.name] && !customerReview[rec.name].trim()) {
						formIsValid = false;
						errors[rec.name] = errorClass;
					}
				}
			});

		if (
			!isEmpty(customerReview) &&
			customerReview['email'] &&
			customerReview['email'].length > 1 &&
			splitformFields[mandIndex].includes('email-') &&
			!validateEmail(customerReview['email'])
		) {
			formIsValid = false;
			errors['email'] = errorClass;
		}
		setError(errors);
		return formIsValid;
	}

	const styles = {
		primaryColor: {
			background:
				!isEmpty(webFormTemplate) && webFormTemplate.primaryColor
					? webFormTemplate.primaryColor
					: '#000000',
		},
		backgroundColor: {
			background:
				!isEmpty(webFormTemplate) && webFormTemplate.backgroundColor
					? webFormTemplate.backgroundColor
					: '#000000',
		},
		formAndFontColor: {
			background:
				!isEmpty(webFormTemplate) && webFormTemplate.formColor
					? webFormTemplate.formColor
					: '#fff',
			color:
				!isEmpty(webFormTemplate) && webFormTemplate.fontColor
					? webFormTemplate.fontColor
					: '#000000',
			height: `auto`,
		},
	};

	useEffect(() => {
		// Create a style tag
		const styleTag = document.createElement('style');

		// Define your custom CSS rules
		const customStyles = `
		  .field-radius-ctm {
			border-radius: ${webFormTemplate?.fieldRadius ? `${webFormTemplate?.fieldRadius}px !important` : '1px'};
		  }
		`;

		// Set the style tag's content with your custom styles
		styleTag.innerHTML = customStyles;

		// Append the style tag to the document head
		document.head.appendChild(styleTag);

		// Clean up function to remove the style tag when the component is unmounted
		return () => {
			document.head.removeChild(styleTag);
		};
	}, [webFormTemplate?.fieldRadius]); // Empty dependency array ensures this effect runs once after the initial render


	return loader ? (
		<>
			<div className='loader-center-screen' style={{ minHeight: '463px' }}>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div
							role='status'
							className='spinner-border'
							style={{ color: '#000' }}
						></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : (
		<div
			className='dynamicform-embed-previewwrap'
			style={{
				background:
					!isEmpty(webFormTemplate) && !isEmpty(webFormTemplate.backgroundColor)
						? webFormTemplate.backgroundColor
						: '#f4f7f8',
				// maxWidth: '463px',
				height: `auto`,
				fontFamily: webFormTemplate?.fontFamily ? webFormTemplate.fontFamily : 'Source Sans Pro',
				fontSize: webFormTemplate?.fontSize ? `${webFormTemplate?.fontSize}px` : '14px'
			}}
		>
			<div className='dynamicform-embed-main' style={styles.formAndFontColor}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='pop-layout dynamicform-style'>
							{!isEmpty(formFields) &&
								formFields.some(a => a.type === 'header') ? (
								<div className='feedbackform-title text-center mb-4'>
									<h3 style={{ fontFamily: 'inherit' }}>
										{removeHTMLTags(formFields.find(a => a.type === 'header').label)}
									</h3>
									{successAlert && (
										<div
											className='alert alert-success alert-dismissible fade show'
											role='alert'
										>
											<strong><Translate text={'success'} />!</strong> <Translate text={'Form has been submitted'} />
											<button
												type='button'
												className='close'
												aria-label='Close'
												style={{ padding: '6px 16px' }}
												onClick={e => {
													e.preventDefault();
													setSuccessAlert(false);
												}}
											>
												<span aria-hidden='true'>×</span>
											</button>
										</div>
									)}
								</div>
							) : (
								<div className='feedbackform-title'>
									<h3> </h3>
								</div>
							)}
							{errorAlert && (
								<div className='alert alert-danger fade show' role='alert'>
									<strong><Translate text={'error'} />!</strong> <Translate text={'Form has been deleted or invalid!'} />
								</div>
							)}
							{!isEmpty(formFields) &&
								formFields.filter(a => a.type !== 'header').length > 0 &&
								splitformFields.length > 0 ? (
								splitformFields.map((arrFields, i) => {
									return (
										<div key={i} className={`${activeForm === `form-${i}` ? '' : 'hide'}`}>
											{
												formFields
													.filter(a => a.type !== 'header' && arrFields.includes(a.name))
													.map((field, index) => {
														return (
															<div
																key={index}
																className='feedbackform-question-loop'
																id={field.name}
															>
																{field.type === 'makeAndModel' ? (
																	<>
																		<div className='row'>
																			<div className='col-6'>
																				<div
																					className={`feedbackform-question-container`}
																				>
																					<Translate text={'make'} />
																					{field.required === true ? (
																						<span className='feedback-required-red'>
																							*{' '}
																						</span>
																					) : (
																						<></>
																					)}
																					{!isEmpty(field.description) ? (
																						<OverlayTrigger
																							placement='bottom'
																							overlay={
																								<Tooltip>{field.description}</Tooltip>
																							}
																						>
																							<i className='ico icon-info icon-grey'></i>
																						</OverlayTrigger>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																			<div
																				className='col-6'
																				style={{ paddingLeft: '6px' }}
																			>
																				<div
																					className={`feedbackform-question-container`}
																				>
																					<Translate text={'model'} />
																					{field.required === true ? (
																						<span className='feedback-required-red'>
																							*{' '}
																						</span>
																					) : (
																						<></>
																					)}
																					{!isEmpty(field.description) ? (
																						<OverlayTrigger
																							placement='bottom'
																							overlay={
																								<Tooltip>{field.description}</Tooltip>
																							}
																						>
																							<i className='ico icon-info icon-grey'></i>
																						</OverlayTrigger>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		</div>
																	</>
																) : field.type === 'firstAndLastName' ? (
																	<>
																		<div className='row'>
																			<div className='col-6'>
																				<div
																					className={`feedbackform-question-container`}
																				>
																					<Translate text={'firstName'} />
																					{field.required === true ? (
																						<span className='feedback-required-red'>
																							*{' '}
																						</span>
																					) : (
																						<></>
																					)}
																					{!isEmpty(field.description) ? (
																						<OverlayTrigger
																							placement='bottom'
																							overlay={
																								<Tooltip>{field.description}</Tooltip>
																							}
																						>
																							<i className='ico icon-info icon-grey'></i>
																						</OverlayTrigger>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																			<div
																				className='col-6'
																				style={{ paddingLeft: '6px' }}
																			>
																				<div
																					className={`feedbackform-question-container`}
																				>
																					<Translate text={'lastName'} />
																					{field.required === true ? (
																						<span className='feedback-required-red'>
																							*{' '}
																						</span>
																					) : (
																						<></>
																					)}
																					{!isEmpty(field.description) ? (
																						<OverlayTrigger
																							placement='bottom'
																							overlay={
																								<Tooltip>{field.description}</Tooltip>
																							}
																						>
																							<i className='ico icon-info icon-grey'></i>
																						</OverlayTrigger>
																					) : (
																						<></>
																					)}
																				</div>
																			</div>
																		</div>
																	</>
																) : field.type === 'paragraph' ? (
																	<div className={`feedbackform-question-container feedbackform-paragraph-scroll`} dangerouslySetInnerHTML={{ __html: `${field.label}` }}>
																	</div>
																) : field.type === 'pageBreak' ? (
																	<></>
																) : (
																	<div className={`feedbackform-question-container`}>
																		{removeHTMLTags(field.label)}
																		{field.required === true ? (
																			<span className='feedback-required-red'>* </span>
																		) : (
																			<></>
																		)}
																		{!isEmpty(field.description) ? (
																			<OverlayTrigger
																				placement='bottom'
																				overlay={<Tooltip>{field.description}</Tooltip>}
																			>
																				<i className='ico icon-info icon-grey'></i>
																			</OverlayTrigger>
																		) : (
																			<></>
																		)}
																	</div>
																)}
																{field.type === 'radio-group' ? (
																	<div className='feedbackform-answer-container dynamic-radio'>
																		<div className='feedbackform-input-wrap'>
																			{field.values
																				.filter(a => !isEmpty(a.value))
																				.map((option, index) => {
																					return (
																						<div
																							key={index}
																							className='radio icheck-success'
																						>
																							<InputRadio
																								id={`${field.name}-${index}`}
																								type='radio'
																								value={option.value}
																								name={field.name}
																								checked={
																									customerReview &&
																										customerReview[field.name] ===
																										option.value
																										? true
																										: false
																								}
																								onChange={handleTypeChange}
																							/>
																							<label htmlFor={`${field.name}-${index}`}>
																								{' '}
																								{option.label}
																							</label>
																						</div>
																					);
																				})}
																		</div>
																	</div>
																) : field.type === 'checkbox-group' ? (
																	<div className='feedbackform-answer-container dynamic-checkbox'>
																		<div className='feedbackform-input-wrap'>
																			<ul
																				className={`${field.toggle === true
																					? 'feedback-switch-ul'
																					: ''
																					}`}
																			>
																				{field.values
																					.filter(a => !isEmpty(a.value))
																					.map((option, index) => {
																						return (
																							<li key={index}>
																								<div
																									className={`${field.toggle === true
																										? 'switch switch-sm'
																										: 'checkbox icheck-success'
																										}`}
																								>
																									<InputCheckBox
																										className='switch'
																										id={`${field.name}-${option.value}`}
																										name={field.name}
																										checked={
																											customerReview &&
																											customerReview[field.name] &&
																											customerReview[
																												field.name
																											].includes(option.value)
																										}
																										onChange={e => {
																											handleCheckChange(e, option.value);
																										}}
																									/>
																									<label htmlFor={`${field.name}-${option.value}`}>
																										{' '}
																										{option.label}
																									</label>
																								</div>
																							</li>
																						);
																					})}
																			</ul>
																		</div>
																	</div>
																) : field.type === 'select' ? (
																	<div className='form-row dynamic-select'>
																		<div className='form-group col-md-12'>
																			{field.multiple === true ? (
																				<ReactMultiSelect
																					options={field.values.filter(
																						a => !isEmpty(a.value),
																					)}
																					name={field.name}
																					placeholder={'select'}
																					onChange={option =>
																						handleReactMultiSelectChange(
																							option,
																							field.name,
																						)
																					}
																					value={
																						customerReview
																							? customerReview[field.name]
																							: ''
																					}
																					classNamePrefix={`field-radius-ctm basic-select`}
																					isMulti={true}
																					isSearchable={true}
																				></ReactMultiSelect>
																			) : (
																				<ReactSelect
																					options={field.values.filter(
																						a => !isEmpty(a.value),
																					)}
																					name={field.name}
																					placeholder={'select'}
																					onChange={(e, data) => {
																						handleReactSelectChange(e, data);
																					}}
																					value={
																						customerReview
																							? customerReview[field.name]
																							: ''
																					}
																					classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																				></ReactSelect>
																			)}
																		</div>
																	</div>
																) : field.type === 'state' ? (
																	<div className='form-row dynamic-select'>
																		<div className='form-group col-md-12'>
																			<ReactSelect
																				options={states}
																				name={'state'}
																				placeholder={'select state'}
																				onChange={(e, data) => {
																					handleReactSelectChange(e, data);
																				}}
																				value={
																					customerReview
																						? customerReview['state']
																						: ''
																				}
																				classNamePrefix={`${errorFields['state']} cursor-pointer field-radius-ctm basic-select`}
																			></ReactSelect>
																		</div>
																	</div>
																) : field.type === 'regNo' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields['regNo']
																					}`}
																				name={'regNo'}
																				onChange={handleOnChange}
																				value={
																					customerReview
																						? customerReview['regNo']
																						: ''
																				}
																				placeholder={'enter reg no.'}
																			/>
																		</div>
																	</div>
																) : field.type === 'text' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields[field.name]
																					}`}
																				name={field.name}
																				onChange={handleOnChange}
																				value={
																					customerReview
																						? customerReview[field.name]
																						: ''
																				}
																				placeholder={
																					!isEmpty(field.placeholder)
																						? field.placeholder
																						: ''
																				}
																			/>
																		</div>
																	</div>
																) : field.type === 'makeAndModel' ? (
																	<div className='form-row'>
																		<div className='form-group col-6'>
																			<ReactSelect
																				options={makes}
																				name={'make'}
																				placeholder={'select make'}
																				onChange={(e, data) => {
																					handleReactSelectChange(e, data);
																					// setCustomerReview({
																					// 	...customerReview,
																					// 	model: ''
																					// })
																				}}
																				value={
																					customerReview ? customerReview['make'] : ''
																				}
																				classNamePrefix={`${errorFields['make']} cursor-pointer field-radius-ctm basic-select`}
																			/>
																			{/* <InputText
																				autoComplete='off'
																				className={`form-control ${errorFields['make']}`}
																				name={'make'}
																				onChange={handleOnChange}
																				value={
																					customerReview ? customerReview['make'] : ''
																				}
																				placeholder={'enter make'}
																			/> */}
																		</div>
																		<div className='form-group col-6'>
																			<ReactSelect
																				options={
																					customerReview &&
																						customerReview.make &&
																						models.filter(
																							a => a.value === customerReview.make,
																						).length
																						? models.filter(
																							a => a.value === customerReview.make,
																						)[0].models
																						: []
																				}
																				name={'model'}
																				placeholder={'select model'}
																				onChange={(e, data) => {
																					handleReactSelectChange(e, data);
																				}}
																				value={
																					customerReview ? customerReview['model'] : ''
																				}
																				classNamePrefix={`${errorFields['model']} cursor-pointer field-radius-ctm basic-select`}
																			/>
																			{/* <InputText
																				autoComplete='off'
																				className={`form-control ${errorFields['model']}`}
																				name={'model'}
																				onChange={handleOnChange}
																				value={
																					customerReview ? customerReview['model'] : ''
																				}
																				placeholder={'enter model'}
																			/> */}
																		</div>
																	</div>
																) : field.type === 'firstAndLastName' ? (
																	<div className='form-row'>
																		<div className='form-group col-6'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields['first-name']}`}
																				name={'first-name'}
																				onChange={handleOnChange}
																				value={
																					customerReview ? customerReview['first-name'] : ''
																				}
																				placeholder={'enter first name'}
																			/>
																		</div>
																		<div className='form-group col-6'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields['last-name']}`}
																				name={'last-name'}
																				onChange={handleOnChange}
																				value={
																					customerReview ? customerReview['last-name'] : ''
																				}
																				placeholder={'enter last name'}
																			/>
																		</div>
																	</div>
																) : field.type === 'phone' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields['phone']}`}
																				name={'phone'}
																				onChange={handleOnPhoneChange}
																				value={
																					customerReview ? customerReview['phone'] : ''
																				}
																				placeholder={'enter phone'}
																			/>
																		</div>
																	</div>
																) : field.type === 'country' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<ReactSelect
																				options={countries}
																				name={'country'}
																				placeholder={
																					!isEmpty(field.placeholder)
																						? field.placeholder
																						: 'select country'
																				}
																				onChange={(e, data) => {
																					handleReactSelectChange(e, data);
																				}}
																				value={
																					customerReview ? customerReview['country'] : ''
																				}
																				classNamePrefix={`${errorFields['country']} cursor-pointer field-radius-ctm basic-select`}
																			/>
																		</div>
																	</div>
																) : field.type === 'dealer' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<ReactSelect
																				options={dealers.filter(a => customerReview?.country ? a.country === customerReview.country : true)}
																				name={'dealer'}
																				placeholder={
																					!isEmpty(field.placeholder)
																						? field.placeholder
																						: 'select dealer'
																				}
																				onChange={(e, data) => {
																					handleReactSelectChange(e, data);
																				}}
																				value={
																					customerReview ? customerReview['dealer'] : ''
																				}
																				classNamePrefix={`${errorFields['dealer']} cursor-pointer field-radius-ctm basic-select`}
																			/>
																		</div>
																	</div>
																) : field.type === 'email' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<InputText
																				autoComplete='off'
																				className={`form-control field-radius-ctm ${errorFields['email']}`}
																				name={'email'}
																				onChange={handleOnEmailChange}
																				value={
																					customerReview ? customerReview['email'] : ''
																				}
																				placeholder={'enter email'}
																			/>
																		</div>
																	</div>
																) : field.type === 'date' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<AntDatePicker
																				value={
																					customerReview
																						? customerReview[field.name]
																						: ''
																				}
																				name={field.name}
																				onChange={e => {
																					handleDateChange(e, field.name);
																				}}
																				format='DD/MM/YYYY'
																				placeholder='DD/MM/YYYY'
																				className={`form-control field-radius-ctm ${errorFields[field.name]
																					}`}
																				disableContainer={true}
																			/>
																		</div>
																	</div>
																) : field.type === 'datetime' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-9'>
																			<AntDatePicker
																				value={
																					customerReview
																						? customerReview[field.name]
																						: ''
																				}
																				name={field.name}
																				onChange={e => {
																					handleDateTimeChange(e, field.name);
																				}}
																				format='DD/MM/YYYY'
																				placeholder='DD/MM/YYYY'
																				className={`form-control field-radius-ctm ${errorFields[field.name]}`}
																				disableContainer={true}
																			/>
																		</div>
																		<div className='form-group col-md-3'>
																			<div className='inner-addon right-addon'>
																				<i className='bg-icons ico icon-time'></i>
																				<ReactTimePicker
																					value={
																						customerReview
																							? customerReview[field.name]
																							: ''
																					}
																					onChange={e => {
																						handleTimeChange(e, field.name)
																					}}
																					name={field.name}
																					timeIntervals={15}
																					placeholder='h:mm aa'
																					className={`form-control ${errorFields[field.name]}`}
																					minTime={field?.value?.split('-')?.[0] ? moment(`2024-01-01 ${field?.value?.split('-')?.[0]}`).toDate() : moment().set('hour', 6).set('minute', 0).toDate()}
																					maxTime={field?.value?.split('-')?.[1] ? moment(`2024-01-01 ${field?.value?.split('-')?.[1]}`).toDate() : moment().set('hour', 22).set('minute', 0).toDate()}
																				/>
																			</div>
																		</div>
																	</div>
																) : field.type === 'textarea' ? (
																	<div className='form-row'>
																		<div className='form-group col-md-12'>
																			<InputTextArea
																				className={`form-control field-radius-ctm notes ${errorFields[field.name]
																					}`}
																				name={field.name}
																				onChange={handleOnChange}
																				value={
																					customerReview
																						? customerReview[field.name]
																						: ''
																				}
																				maxLength='280'
																				rows='3'
																				placeholder={
																					!isEmpty(field.placeholder)
																						? field.placeholder
																						: ''
																				}
																			></InputTextArea>
																		</div>
																	</div>
																) : (
																	<></>
																)}
															</div>
														);
													})
											}
										</div>
									)
								})

							) : (
								<></>
							)}
							{/* <div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div> */}
							{!errorAlert && splitformFields.length === 1 && (
								<div className='form-row'>
									<div className='form-group col-md-12'>
										<a
											href='#'
											className={
												submitLoader
													? 'btn btn-dynamic w-100 d-inline-block mt-2 btn-disable'
													: 'btn btn-dynamic w-100 d-inline-block mt-2'
											}
											style={{
												background:
													!isEmpty(webFormTemplate) &&
														!isEmpty(webFormTemplate.primaryColor)
														? webFormTemplate.primaryColor
														: '#4466f2',
												color: '#fff',
												borderRadius: webFormTemplate?.btnRadius ? `${webFormTemplate?.btnRadius}px` : '1px'
											}}
											onClick={handleSubmit}
										>
											{submitLoader && (
												<span
													className='spinner-border spinner-button mr-1'
													role='status'
													aria-hidden='true'
												></span>
											)}
											<Translate text={'submit'} />
										</a>
									</div>
								</div>
							)}
							{
								splitformFields.length > 1 ? (
									splitformFields.map((arrFields, i) => {
										return (<div key={i} className={`mt-3 ${activeForm === `form-${i}` ? '' : 'hide'}`}>
											{
												i > 0 ? (
													<button type="button" id={i} className="btn btn-default float-left"
														style={{
															borderRadius: webFormTemplate?.btnRadius ? `${webFormTemplate?.btnRadius}px` : '1px'
														}}
														onClick={(e) => {
															setActiveForm(`form-${Number(e.target.id) - 1}`);
															setTimeout(function () {
																sendPostMessage();
															}, 100);
														}}><Translate text={'back'} /></button>
												) : (<></>)
											}
											{
												i >= 0 && i < (splitformFields.length - 1) ? (
													<button type="button" id={i} className="btn float-right" style={{
														background:
															!isEmpty(webFormTemplate) &&
																!isEmpty(webFormTemplate.primaryColor)
																? webFormTemplate.primaryColor
																: '#4466f2',
														color: '#fff',
														borderRadius: webFormTemplate?.btnRadius ? `${webFormTemplate?.btnRadius}px` : '1px'
													}} onClick={(e) => {
														let formValid = checkMand(Number(e.target.id));
														if (formValid) {
															setActiveForm(`form-${Number(e.target.id) + 1}`);
															setTimeout(function () {
																sendPostMessage();
															}, 100);
														}

													}}><Translate text={'Next'} /></button>
												) : (<></>)
											}
											{
												i === (splitformFields.length - 1) ? (
													<button type="button" id={i} className="btn float-right" style={{
														background:
															!isEmpty(webFormTemplate) &&
																!isEmpty(webFormTemplate.primaryColor)
																? webFormTemplate.primaryColor
																: '#4466f2',
														color: '#fff',
														borderRadius: webFormTemplate?.btnRadius ? `${webFormTemplate?.btnRadius}px` : '1px'
													}} onClick={handleSubmit}>
														{submitLoader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
														<Translate text={'submit'} /></button>
												) : (<></>)
											}
										</div>)
									})
								) : (<></>)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmbedForm;
