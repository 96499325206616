import React, { Component,Suspense,lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoginPage from './views/account/login';
import DefaultLayout from './views/layout';
//import DefaultLayout from './admin/layout';

class App extends Component {

    componentDidMount() {
        const secondary = window.firebase.initializeApp(window.firebase.app().options.projectId === 'ams-pro-live' ? {
            "apiKey": "AIzaSyA03uSTicWX8So32wH6CItJKZjSmB2LPcU",
            "authDomain": "ams-pro-eulive.firebaseapp.com",
            "projectId": "ams-pro-eulive",
            "storageBucket": "ams-pro-eulive.appspot.com",
            "messagingSenderId": "228382484752",
            "appId": "1:228382484752:web:bb515baea8490fef691d95",
            "measurementId": "G-1WZ9RXHPYF"
        } : {
            "apiKey": "AIzaSyAGFQEeyHUxh6N9KxYxczFPeVzMyxNdq1w",
            "authDomain": "ams-pro-migration.firebaseapp.com",
            "projectId": "ams-pro-migration",
            "storageBucket": "ams-pro-migration.appspot.com",
            "messagingSenderId": "673523496423",
            "appId": "1:673523496423:web:2806a6f809d15f3f8af942",
            "measurementId": "G-2HG7FFGNXP"
        }, 'EU Region')

        window.firebase2 = secondary;
        window.secondaryProjectId = secondary.options.projectId;
    }


    render() {
        return (
            <Router>
                <Suspense fallback={<></>}> 
                    <Switch>
                        <Route path="/login" exact name="Login Page" component={LoginPage} />
                        <Route path="/" name="Home" component={DefaultLayout} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
};

export default App;