export const objStock = {
  documentID: null,
  clientID: null,
  enquiryID: null,
  //keywords: [],
  stockNo: '',
  make: '',
  model: '',
  modelDescription: '',
  saleType: 'New',
  type: '',
  year: '',
  regNo: '',
  vinNo: '',
  extColor: '',
  intColor: '',
  trim: '',
  miles: '',
  price: null,
  purchasePrice: null,
  location: '',
  regDate: null,
  warrantyStartDate: null,
  warrantyExp: null,
  inStockDate: null,
  stockInDate: null,
  status: '', //stockStatus
  isLoan: false,
  isTestDrive: false,
  isSale: false,
  isAllocated: false,
  images: [],
  testDriveDetail: null, //objTestDriveDetail
  fleetDetail: null, //objFleetDetail
  statusModifiedBy: '',
  addedBy: '',
  addedDate: null,
  modifiedBy: '',
  modifiedFrom: 'web',
  modifiedDate: null,
  isDeleted: false
};


export const objRequirementStock = {
  documentID: null,
  clientID: null,
  stockNo: '',
  make: '',
  model: '',
  modelDescription: '',
  saleType: '',
  type: '',
  year: '',
  regNo: '',
  vinNo: '',
  extColor: '',
  intColor: '',
  trim: '',
  miles: '',
  price: null,
  purchasePrice: null,
  location: '',
  regDate: null,
  warrantyStartDate: null,
  warrantyExp: null,
  stockInDate: null,
  images: [],
  //keywords: [],
};

export const objTestDriveDetail = {
  testdriveID: null,
  status: '', //testDriveStatus
  contact: null,
  addedBy: '',
  addedDate: null,
  startDate: null,
  expectedReturn: null
}

export const objFleetDetail = {
  fleetID: null,
  status: '', //fleetStatus
  contact: null,
  staff: null,
  addedBy: '',
  addedDate: null,
  startDate: null,
  expectedReturn: null
}

export const stockBasicVM = {
  documentID: null,
  clientID: null,
  enquiryID: null,
  //keywords: [],
  images: [],
  stockNo: '',
  make: '',
  model: '',
  modelDescription: '',
  saleType: '',
  type: '',
  year: '',
  regNo: '',
  vinNo: '',
  extColor: '',
  intColor: '',
  price: null,
  purchasePrice: null,
  location: '',
  regDate: null,
  warrantyStartDate: null,
  warrantyExp: null,
  stockInDate: null,
  status: '', //stockStatus 
  statusModifiedBy: '',
};

export const mandatoryFields = [
  'make',
  'model',
  //'type',
  'year',
  'saleType',
  'status',
  'stockNo',
];

export const allStockFieldsVM = [
  {
     name: 'Checkbox',
     value: 'checkbox',
     subText: 'documentID',
     width: 40,
     default: true,
     type: 'checkbox',
     flex: 0,
     fixed: true
  },
  {
     name: 'Stock #',
     value: 'stockNo',
     subText: 'clientName',
     group: 'Stock',
     flex: 2,
     default: true
  },
  {
      name: 'Make',
      value: 'make',
      group: 'Stock',
      flex: 1,
      default: true
  },
  {
      name: 'Model',
      value: 'model',
      group: 'Stock',
      flex: 1,
      default: true
  },
  {
      name: 'Year',
      value: 'year',
      group: 'Stock',
      flex: 0.5,
      default: true
  },
  {
      name: 'Sale Type',
      value: 'saleType',
      group: 'Stock',
      flex: 0.5,
      default: true
  },
  {
      name: 'Reg #',
      value: 'regNo',
      group: 'Stock',
      flex: 1,
      default: true
  },
  {
      name: 'Available For',
      value: 'availability',
      group: 'Stock',
      elementName : 'availabileFor',
      flex: 1,
      default: true
  },
  {
      name: 'Status',
      value: 'status',
      group: 'Stock',
      elementName : 'statusName',
      flex: 1,
      default: true
  },
  {
      name: 'VIN #',
      value: 'vinNo',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Model Description',
      value: 'modelDescription',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Body Type',
      value: 'type',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Exterior Color',
      value: 'extColor',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Interior Color',
      value: 'intColor',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Odometer',
      value: 'miles',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Price',
      value: 'price',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Purchase Price',
      value: 'purchasePrice',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Location',
      value: 'location',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Registration Date',
      value: 'regDate',
      group: 'Stock',
      flex: 1
  },
  {
      name: 'Warranty Start Date',
      value: 'warrantyStartDate',
      group: 'Stock',
      flex: 1
  },
  {
    name: 'Warranty Expiry',
    value: 'warrantyExp',
    group: 'Stock',
    flex: 1
},
  {
      name: 'Stock Age',
      value: 'stockInDate',
      flex: 1
  },
  {
      name: 'Created On',
      value: 'createdOn',
      group: 'Stock',
      flex: 1,
      default: true,
      subText: 'addedBy',
      subTextTitle: 'Created By'
  },
  {
      name: 'Updated On',
      value: 'updatedOn',
      group: 'Stock',
      flex: 1,
      subText: 'modifiedBy',
      subTextTitle: 'Last Updated By'
  },
  {
      name: 'Settings',
      value: 'settings',
      subText: 'documentID',
      width: 70,
      default: true,
      type: 'settings',
      flex: 0,
      fixedRight: true
  }
]


export const stockBasicDetailsVM = {
  documentID: null,
  stockNo: '',
  make: '',
  model: '',
  modelDescription: '',
  saleType: '',
  bodyType: '',
  year: '',
  regNo: '',
  vinNo: '',
  extColor: '',
  intColor: '',
  price: null,
  location: '',
  regDate: null,
  stockInDate: null,
  status: '', //stockStatus
  images: []
};
