export const livingSituationVM = {
	ResidenceType: null,
	ResidenceYear: null,
	ResidenceStatus: null,
	ResidenceAddress: null,
	PreviousAddress: null,
};

export const employmentDetailsVM = {
	EmployerAddress: null,
	EmployerName: null,
	EmploymentPeriod: null,
	EmploymentStatus: null,
	Occupation: null,
	PreviousEmployer: null,
};

export const financialDetailsVM = {
	IncomeDetails: {
		PrimaryAmount: null,
		PrimaryFrequency: null,
		BenefitsAmount: null,
		BenefitsFrequency: null,
		OtherAmount: null,
		OtherFrequency: null,
		RentalAmount: null,
		RentalFrequency: null,
	},
	AssestsDetails: {
		PropertyOneValue: null,
		PropertyOneFinanced: null,
		PropertyTwoValue: null,
		PropertyTwoFinanced: null,
		SavingsAmount: null,
		DepositAmount: null,
	},
	LiabilitiesDetails: {
		MortgageAmount: null,
		PersonalLoanAmount: null,
		CreditLimit: null,
		OutstandingAmount: null,
	},
};

export const cropShowVM = {
	show: false,
	clsActive: '',
	src: null,
	id: '',
	type: '',
	ext: '',
	title: '',
}