/** LIBRARIES */
import React, { useEffect, Suspense, useState, useReducer, useMemo } from 'react';
import _ from 'lodash'
/** COMPONENTS */
import Navbar from './navbar';
import MainContainer from './container';

import { ClientContext } from './clientContext'

const ClientListReducer = (state, action) => {
    function updateClient() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateClient()
        }
        default:
            return state;
    }
};

const AdminLayout = (props) => {

    const [clients, dispatch] = useReducer(ClientListReducer, []);
    const [oemSettings, setOEMSettings] = useState([]);
    const [oemSettingsWithClients, setOEMSettingsWithClients] = useState([]);
    const [oemBrands, setOEMBrands] = useState([]);
    const [regionLookups, setRegions] = useState([]);
    const [countryLookups, setCountries] = useState([]);
    const [stateLookups, setStates] = useState([]);
    const [languageLookups, setLanguages] = useState([]);
    const [configURLs,setRemoteconfig] = useState(null)
    const remoteConfig = window.firebase.remoteConfig();


    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])


    useEffect(() => {
        getLookups()
    }, [])

    const getLookups = async () => {
        try {
            await remoteConfig.fetchAndActivate()
                .then(async () => {
                    let baseURL = window.location.origin;//remoteConfig.getString('baseURL');
                    const languages = remoteConfig.getString('languages');
                    const regions = remoteConfig.getString('regions');
                    const countries = remoteConfig.getString('countries');
                    const states = remoteConfig.getString('states');
                    setRemoteconfig(remoteConfig.getAll()) 
                    const requestOptions = {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    };
                    if (process.env.NODE_ENV === 'development')
                        baseURL = window.location.origin;

                    const responseLang = await fetch(baseURL + languages, requestOptions);
                    const languagejson = await responseLang.json();
                    setLanguages(languagejson);

                    const responseReg = await fetch(baseURL + regions);
                    const regjson = await responseReg.json();
                    setRegions(regjson);

                    const responseCountries = await fetch(baseURL + countries);
                    const countriesjson = await responseCountries.json();
                    setCountries(countriesjson);

                    const responseState = await fetch(baseURL + states);
                    const statejson = await responseState.json();
                    setStates(statejson);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        catch (error) {
            console.log('lookups', error);
        }
    }

    useEffect(() => {
        const OEMsettingSnapshot = window.firebase.firestore().collection(`clientSettings`)
            .onSnapshot(querySnapshot => {
                let _oemSettings = [];
                querySnapshot.forEach((rec) => {
                    _oemSettings.push({ ...rec.data(), 'documentID': rec.id,'id': rec.id, 'addedDate': rec.addedDate ? rec.addedDate : window.firebase.firestore.Timestamp.now() })
                })
                setOEMSettings(_.orderBy(_oemSettings,['addedDate'],['desc']))
            })
        return () => {
            OEMsettingSnapshot && OEMsettingSnapshot();
        }
    }, [])

    useEffect(() => {
        const OEMbrandsSnapshot = window.firebase.firestore().collection(`brands`)
            //.where('category', '==', 'Automotive')
            .where('isDeleted', '==', false)
            //.orderBy('addedDate', 'desc')
            .onSnapshot(querySnapshot => {
                let _oemBrands = [];
                querySnapshot.forEach((rec) => {
                    _oemBrands.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMBrands(_.orderBy(_oemBrands, ["name"], ["asc"]))
            })
        return () => {
            OEMbrandsSnapshot && OEMbrandsSnapshot();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(oemSettings)) {
            return
        }

        const fetchOEMClients = async () => {
            const _oemSettigns = []
            await Promise.all(
                oemSettings.map(async oemObj => {
                    let _oemClients = [];
                    const ref = window.firebase.firestore().collection(`clientSettings`).doc(oemObj.documentID).collection(`clients`)
                    const querySnapshots = await ref.get()
                    querySnapshots.forEach(rec => {
                        _oemClients.push({ ...rec.data(), 'documentID': rec.id, 'id': rec.id})
                    })
                    _oemSettigns.push({
                        ...oemObj, oemClients: _oemClients
                    })
                })
            )
            setOEMSettingsWithClients(_oemSettigns)
        }
        fetchOEMClients()
    }, [oemSettings])

    useEffect(() => {
        const clientDataRef = window.firebase.firestore().collection('clients')
            .onSnapshot(onCollectionUpdate);

        return () => {
            clientDataRef && clientDataRef();
        }
    }, [])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };
                if (change.type === 'added') {
                    actionType = "ADD_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        const arrclients = [];
        if (!actionType) {
            querySnapshot.forEach((doc) => {
                arrclients.push({
                    ...doc.data(),
                    documentID: doc.id
                });
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : arrclients
        });
    }
    const clientsMemo = useMemo(() => clients, [clients])
    const brandsMemo = useMemo(() => oemBrands, [oemBrands])

    const oemSettingsMemo = useMemo(() => oemSettings, [oemSettings])
    const oemSettingsWithClientsMemo = useMemo(() => oemSettingsWithClients, [oemSettingsWithClients])

    return (
        <Suspense fallback={<></>}>
            <>
                <ClientContext.Provider value={{ clients: clientsMemo, oemSettings: oemSettingsMemo, oemSettingsWithClients: oemSettingsWithClientsMemo, regionLookups: regionLookups, countryLookups: countryLookups, stateLookups: stateLookups, languageLookups: languageLookups, oemBrands: brandsMemo,configURLs:configURLs }}>
                    <Navbar history={props.history} />
                    <MainContainer history={props.history} setUser={props.setUser} user={props.user} />
                </ClientContext.Provider>
            </>
        </Suspense>
    );
}

export default AdminLayout;