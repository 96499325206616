import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc, collection } from 'rxfire/firestore';
import { TemplateContext } from '../templateContext'
import { ContentOverlay, PopUpModal, ReactSelect, UpgradePlan } from '../../../../components'
import Translate from '../../../../constants/translate';
import { firestoreDB } from '../../../../services/helper';
import PipelineStages from './pipelineStages'
import AddPipeline from './addPipeline'
import AddStage from './addStage'
import RearrangeStage from './rearrangeStage'
import RearrangePipeline from './rearrangePipeline'
import MovePipeline from './movePipeline';
import { pipelineVM, levelOptions } from "./viewModel";
import CommonHelper from '../../../../services/common';
const enquiryStatus = [
    // { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> },
    { active: true, value: 'prospectLost', label: <div className={`badge badge-pill badge-status-lost`}>PROSPECT LOST</div> }
]

const TradeinProPipelineSettings = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [sortPipelines, setSortPipelines] = useState([]);
    const [allPipelines, setAllPipelines] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [sortpipelineValues, setSortPipelineValues] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedClient, setSelectedClient] = useState('')
    const [showModel, setShowmodel] = useState(false)
    const [sortPipelineModel, setSortPipelineModel] = useState(false)
    const [showStageModel, setShowStagemodel] = useState(false)
    const [showRearrangeModel, setShowRearrangemodel] = useState(false)
    const [showMovePipeline, setShowMovePipeline] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editPipeline, setEditPipeline] = useState(null)
    const [editStage, setEditStage] = useState(null)
    const [deletePipeline, setDeletePipeline] = useState(null)
    const [deleteStage, setDeleteStage] = useState(null)
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const { clients, groups, regions, clientSettings } = useContext(TemplateContext);
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return
        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    groupIDs: _.uniq(clients.filter(c => c.region === rec.code).map(v => _.pick(v, ['group']).group)),
                    clientIDs: clients.filter(c => c.region === rec.code).map(v => _.pick(v, ['documentID']).documentID)
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    clientIDs: clients.filter(c => c.group === rec.documentID).map(v => _.pick(v, ['documentID']).documentID)
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return

        const refs = [
            doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`)),
        ]
        let clientsLength = clientOptions.length
        let regionsLength = regions.length
        let groupsLength = groups.length
        clientOptions.forEach(clientOption => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${clientOption.value}/tradeinPro/${clientOption.value}`)))
        })
        regions.forEach(region => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${region.documentID}/tradeinPro/${region.documentID}`)))
        })
        groups.forEach(group => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}/tradeinPro/${group.documentID}`)))
        })
        var alllookupsettings = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups] = lookups;

                    let valToReturn = [
                        { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id, 'level': 'oem' },
                    ]

                    lookups.forEach((lookup, index) => {
                        if (index > 0 && index <= clientsLength) {
                            valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, 'level': 'individual' },)
                        }
                        if (index > 0 && index <= clientsLength + regionsLength && index > clientsLength) {
                            valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, 'level': 'region' },)
                        }
                        if (index > 0 && index <= clientsLength + regionsLength + groupsLength && index > clientsLength + regionsLength) {
                            valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id, 'level': 'group' },)
                        }
                    })

                    return valToReturn
                }))
            .subscribe(allDocs => {
                var lookups = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => lookups.push(e)) : lookups.push(rec) })
                let settingsDoc = [];
                lookups.forEach(rec => {
                    if (rec['pipelines']) {
                        rec['pipelines'].forEach((_lookups) => {
                            settingsDoc.push({
                                ..._lookups,
                                'levelID': rec.documentID,
                                'documentID': rec.documentID,
                                'levelName': rec.name,
                                'level': rec.level,
                                'levelIndex': _.find(levelOptions, { value: rec.level }) ? _.find(levelOptions, { value: rec.level }).index : 1
                            })
                        })
                    }
                })

                setSortPipelines(settingsDoc)
                if (settingsDoc.length === 0)
                    setLoader(false)
            });
        return () => {
            alllookupsettings && alllookupsettings.unsubscribe()
        }
    }, [clientOptions])

    useEffect(() => {
        if (_.isEmpty(selectedClient) || _.isEmpty(sortPipelines))
            return
        const unsubpipelineSort = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${selectedClient.documentID}/tradeinPro/${selectedClient.documentID}/enquiryOptions/${selectedClient.documentID}`)
            .onSnapshot(querySnapshot => {
                setLoader(false);
                if (querySnapshot.exists && !_.isEmpty(querySnapshot.data().pipelines)) {
                    let _pipeline = [];
                    _.uniq(querySnapshot.data().pipelines).forEach(rec => {
                        if (_.find(sortPipelines, { value: rec })) {
                            if (_.find(sortPipelines, { value: rec }).level === 'individual') {
                                _pipeline.push(_.find(sortPipelines, { value: rec, levelID: selectedClient.documentID }))
                            } else {
                                _pipeline.push(_.find(sortPipelines, { value: rec }))
                            }
                        }
                    })
                    setAllPipelines(_pipeline)
                    setSortPipelineValues(querySnapshot.data().pipelines);
                }
                else {
                    setAllPipelines(sortPipelines)
                }
            })
        return () => {
            unsubpipelineSort && unsubpipelineSort();
        }
    }, [sortPipelines, selectedClient])

    useEffect(() => {
        if (_.isEmpty(selectedClient) || _.isEmpty(allPipelines))
            return
        let levelIDs = [selectedClient.settingsID, selectedClient.region, selectedClient.group, selectedClient.documentID]
        setPipelines(allPipelines.filter(e => levelIDs.some(item => item === e.levelID)));
    }, [selectedClient, allPipelines])

    const handleModelClose = (pipeline) => {
        setShowmodel(false)
        setShowStagemodel(false)
        setShowRearrangemodel(false)
        setShowMovePipeline(false)
        setSortPipelineModel(false)
        setEditPipeline(null)
        setEditStage(null)
        if (_.isEmpty(pipeline)) {
            return;
        }
        // if (pipelines.some(item => item.value === pipeline.value && item.levelID === pipeline.levelID)) {
        //     setPipelines([..._.map(pipelines, function (obj) {
        //         return obj.value === pipeline.value && obj.levelID === pipeline.levelID ? { ...pipeline } : { ...obj };
        //     })])
        // }
        // else {
        //     setPipelines([...pipelines, pipeline])
        // }
        setTimeout(function () {
            //self.setState({sectionloading:false})
            if (document.getElementById(pipeline.value + '-' + pipeline.levelID)) {
                document.getElementById(pipeline.value + '-' + pipeline.levelID).scrollIntoView(
                    {
                        behavior: "smooth",
                        block: "center"
                    });
            }
        }, 1000)
    }

    const hanleEditPipeline = (pipeline) => {

        setPopHeader('Edit Pipeline')
        setEditPipeline({
            ...pipeline,
            levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'individual' ? clientSettings.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
        })
        setShowmodel(true)

    }

    const hanleEditStage = (pipeline, stage) => {
        setEditPipeline({
            ...pipeline,
            levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'individual' ? clientSettings.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
        })
        setShowStagemodel(true)
        setPopHeader('Edit Stage')
        setEditStage(stage)
    }

    const handleAddStage = (pipeline) => {
        setEditPipeline({
            ...pipeline,
            levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'individual' ? clientSettings.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
        })
        setShowStagemodel(true)
        setPopHeader('Add New Stage')
        setEditStage(null)
    }

    const hanleDeleteStage = async (pipeline, stage) => {
        let clientIds = [selectedClient.documentID];
        if (pipeline.level === 'oem') {
            clientIds = clients.map((rec) => { return rec.documentID })
        }
        else if (pipeline.level === 'region') {
            clientIds = clients.filter(rec => rec.region === pipeline.levelID).map((rec) => { return rec.documentID })
        }
        else if (pipeline.level === 'group') {
            clientIds = clients.filter(rec => rec.group === pipeline.levelID).map((rec) => { return rec.documentID })
        }
        let promises = []
        let enquiries = [];
        clientIds.forEach(clientID => {
            promises.push(firestoreDB(props.dealersettings).firestore().collection('tradeinPro')
                .where('clientID', '==', clientID)
                .where('isDeleted', '==', false)
                .where('pipeline', '==', pipeline.value)
                .where('stage', '==', stage.value)
                .limit(1)
                .get())
        })
        const snapshots = await Promise.all(promises)
        snapshots.forEach(response => {
            response.docs.forEach((doc) => {
                enquiries.push(doc.data())
            });
        });

        if (enquiries.length > 0) {
            setPopHeader(`Delete ${stage.name} stage`)
            setDeleteStage(stage)
            setEditPipeline({
                ...pipeline,
                levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
            })
            setDeletePipeline(null)
            setShowMovePipeline(true)
        }
        else {
            deleteSelectedStage(pipeline, stage)
        }
    }

    const deleteSelectedStage = (pipeline, stage) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                pipeline = {
                    ...pipeline,
                    levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                            [pipeline.levelID]
                }


                const batch = window.firebase.firestore().batch();
                if (pipeline.level === 'oem') {
                    const pipelineObj = {
                        'pipelines': [..._.map(pipelines.filter(item => item.level === pipeline.level), function (obj) {
                            return obj.value === pipeline.value ? { ..._.pick({ ...pipeline, stages: [...pipeline.stages.filter(item => item.value !== stage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                        })]
                            .map(obj => {
                                let pipelineToReturn = { ...obj }
                                if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === stage.value)) {
                                    pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== stage.value)] }
                                }
                                return pipelineToReturn
                            })
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                        pipelineObj, { merge: true });
                }
                else if (_.isArray(pipeline.levelID)) {
                    pipeline.levelID.forEach(rec => {
                        const pipelineObj = {
                            'pipelines': [..._.map(pipelines.filter(item => item.level === pipeline.level && rec === item.levelID), function (obj) {
                                return obj.value === pipeline.value ? { ..._.pick({ ...pipeline, stages: [...pipeline.stages.filter(item => item.value !== stage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                            })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === stage.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== stage.value)] }
                                    }
                                    return pipelineToReturn
                                })
                        }
                        if (pipeline.level === 'individual') {
                            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${rec}/tradeinPro/${rec}`),
                                pipelineObj, { merge: true });
                        } else if (pipeline.level === 'group') {
                            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${rec}/tradeinPro/${rec}`),
                                pipelineObj, { merge: true });
                        } else if (pipeline.level === 'region') {
                            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${rec}/tradeinPro/${rec}`),
                                pipelineObj, { merge: true });
                        }
                        // batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}`),
                        //     pipelineObj, { merge: true });
                    })
                }
                else {
                    const pipelineObj = {
                        'pipelines': [..._.map(pipelines.filter(item => item.level === pipeline.level && pipeline.levelID === item.levelID), function (obj) {
                            return obj.value === pipeline.value ? { ..._.pick({ ...pipeline, stages: [...pipeline.stages.filter(item => item.value !== stage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                        })]
                            .map(obj => {
                                let pipelineToReturn = { ...obj }
                                if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === stage.value)) {
                                    pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== stage.value)] }
                                }
                                return pipelineToReturn
                            })
                    }
                    if (pipeline.level === 'individual') {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${pipeline.levelID}/tradeinPro/${pipeline.levelID}`),
                            pipelineObj, { merge: true });
                    } else if (pipeline.level === 'group') {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${pipeline.levelID}/tradeinPro/${pipeline.levelID}`),
                            pipelineObj, { merge: true });
                    } else if (pipeline.level === 'region') {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${pipeline.levelID}/tradeinPro/${pipeline.levelID}`),
                            pipelineObj, { merge: true });
                    }

                    // batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}`),
                    //     pipelineObj, { merge: true });
                }

                if (sortPipelines.some(a => a.level !== pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === pipeline.value && b.stage === stage.value))) {
                    sortPipelines.filter(a => a.level !== pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === pipeline.value && b.stage === stage.value))
                        .forEach(rec => {
                            var pipelineObj = [..._.map(sortPipelines.filter(item => item.level === rec.level && rec.levelID === item.levelID), function (obj) {
                                return { ..._.pick(obj, _.keys(pipelineVM)) };
                            })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage === stage.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.stage !== stage.value)] }
                                    }
                                    return pipelineToReturn
                                })

                            if (pipelineObj.length > 0) {
                                if (rec.level === 'oem') {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                                else {

                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${rec.level === 'group' ? 'groups' : rec.level === 'region' ? 'regions' : 'clients'}/${rec.levelID}/tradeinPro/${rec.levelID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                            }
                        })
                }

                batch.commit()
                    .then(snapshot => {
                        setLoader(false);
                        //update settings date and priority
                        if (!_.isEmpty(selectedClient)) {
                            window.firebase.firestore().doc(`clients/${selectedClient.documentID}`)
                                .set({
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: 'Tradein Pro Pipeline Settings has been updated',
                                }, { merge: true })
                        }
                        //end
                        toast.notify(`Stage deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })

                // let querySet = `clientSettings/${props.dealersettings.client.settingsID}`;
                // if (pipeline.level !== 'oem') {
                //     querySet = `clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}`;
                // }
                // window.firebase.firestore().doc(querySet)
                //     .set({
                //         'pipelines': [..._.map(pipelines.filter(item => item.levelID === pipeline.levelID), function (obj) {
                //             return obj.value === pipeline.value ? { ..._.pick({ ...pipeline, stages: [...pipeline.stages.filter(item => item.value !== stage.value)] }, _.keys(pipelineVM)) } : { ..._.pick(obj, _.keys(pipelineVM)) };
                //         })]
                //     }, { merge: true })
                //     .then(() => {
                //         setLoader(false);
                //         toast.notify(`Stage deleted successfully`, {
                //             duration: 2000
                //         })
                //     })
                //     .catch(error => {
                //         setLoader(false);
                //         console.log(error);
                //         toast.notify('Something went wrong', {
                //             duration: 2000
                //         })
                //     })
            }
        })
    }

    const deleteSelectedPipeline = (pipeline) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                // setPipelines([..._.map(pipelines, function (obj) {
                //     return obj.value === pipeline.value && obj.levelID === pipeline.levelID ? { ...pipeline, stages: [...pipeline.stages.filter(item => item.value !== stage.value)] } : { ...obj };
                // })])

                pipeline = {
                    ...pipeline,
                    levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                            [pipeline.levelID]
                }
                const pipelineObj = {
                    'pipelines': [...pipelines.filter(item => pipeline.levelID.some(a => a === item.levelID) && item.value !== pipeline.value).map(rec => { return { ..._.pick(rec, _.keys(pipelineVM)) } })]
                        .map(obj => {
                            let pipelineToReturn = { ...obj }
                            if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === pipeline.value)) {
                                pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== pipeline.value)] }
                            }
                            return pipelineToReturn
                        })
                }
                let clinetpromise = []
                const batch = window.firebase.firestore().batch();
                if (pipeline.level === 'oem') {
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                        pipelineObj, { merge: true });
                    clinetpromise.push(window.firebase.firestore().collection(`clients`).where('settingsID', '==', props.dealersettings.client.settingsID).get());
                }
                else if (_.isArray(pipeline.levelID)) {
                    pipeline.levelID.forEach(rec => {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${rec}/tradeinPro/${rec}`),
                            pipelineObj, { merge: true });
                        clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${pipeline.level === 'individual' ? 'documentID' : pipeline.level}`, '==', rec).get());
                    })
                }
                else {
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}/tradeinPro/${pipeline.levelID}`),
                        pipelineObj, { merge: true });
                    clinetpromise.push(window.firebase.firestore().collection(`clients`).where(`${pipeline.level === 'individual' ? 'documentID' : pipeline.level}`, '==', pipeline.levelID).get());
                }

                let pipelinesortpromise = []
                const clinetSnapsnot = await Promise.all(clinetpromise);
                clinetSnapsnot.forEach((clients) => {
                    clients.docs.forEach(client => {
                        if (client.exists)
                            pipelinesortpromise.push(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${client.id}/tradeinPro/${client.id}/enquiryOptions/${client.id}`).get());
                    })
                })
                const pipelinesortSnapsnot = await Promise.all(pipelinesortpromise);
                pipelinesortSnapsnot.forEach((sortOption) => {
                    if (sortOption.exists && !_.isEmpty(sortOption.data().pipelines)) {
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${sortOption.id}/tradeinPro/${sortOption.id}/enquiryOptions/${sortOption.id}`),
                            {
                                pipelines: [...sortOption.data().pipelines.filter(item => item !== pipeline.value)]
                            }, { merge: true });
                    }
                })

                if (sortPipelines.some(a => a.level !== pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === pipeline.value))) {
                    sortPipelines.filter(a => a.level !== pipeline.level && a.statusSettings && a.statusSettings.some(b => b.pipeline === pipeline.value))
                        .forEach(rec => {
                            var pipelineObj = [..._.map(sortPipelines.filter(item => item.level === rec.level && rec.levelID === item.levelID), function (obj) {
                                return { ..._.pick(obj, _.keys(pipelineVM)) };
                            })]
                                .map(obj => {
                                    let pipelineToReturn = { ...obj }
                                    if (pipelineToReturn.statusSettings && pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline === pipeline.value)) {
                                        pipelineToReturn = { ...pipelineToReturn, statusSettings: [...pipelineToReturn.statusSettings.filter(statusSetting => statusSetting.pipeline !== pipeline.value)] }
                                    }
                                    return pipelineToReturn
                                })

                            if (pipelineObj.length > 0) {
                                if (rec.level === 'oem') {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/tradeinPro/${props.dealersettings.client.settingsID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                                else {
                                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${rec.level === 'group' ? 'groups' : rec.level === 'region' ? 'regions' : 'clients'}/${rec.levelID}/tradeinPro/${rec.levelID}`),
                                        {
                                            pipelines: pipelineObj
                                        }, { merge: true });
                                }
                            }
                        })
                }

                batch.commit()
                    .then(snapshot => {
                        setLoader(false);
                        //update settings date and priority
                        if (!_.isEmpty(selectedClient)) {
                            window.firebase.firestore().doc(`clients/${selectedClient.documentID}`)
                                .set({
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: 'Tradein Pipeline Settings has been updated',
                                }, { merge: true })
                        }
                        //end
                        toast.notify(`Pipeline deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })

                // let querySet = `clientSettings/${props.dealersettings.client.settingsID}`;
                // if (pipeline.level !== 'oem') {
                //     querySet = `clientSettings/${props.dealersettings.client.settingsID}/${pipeline.level === 'group' ? 'groups' : pipeline.level === 'region' ? 'regions' : 'clients'}/${pipeline.levelID}`;
                // }
                // window.firebase.firestore().doc(querySet)
                //     .set({
                //         'pipelines': [...pipelines.filter(item => item.levelID === pipeline.levelID && item.value !== pipeline.value)]
                //     }, { merge: true })
                //     .then(() => {
                //         setLoader(false);
                //         toast.notify(`Pipeline deleted successfully`, {
                //             duration: 2000
                //         })
                //     })
                //     .catch(error => {
                //         setLoader(false);
                //         console.log(error);
                //         toast.notify('Something went wrong', {
                //             duration: 2000
                //         })
                //     })
            }
        })
    }

    const hanleRearrangeStage = (pipeline) => {
        setPopHeader('Rearrange Stage')
        setEditPipeline({
            ...pipeline,
            levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'individual' ? clientSettings.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
        })
        setShowRearrangemodel(true)
    }

    const hanleDeletePipeline = async (pipeline) => {
        let clientIds = [selectedClient.documentID];
        if (pipeline.level === 'oem') {
            clientIds = clients.map((rec) => { return rec.documentID })
        }
        else if (pipeline.level === 'region') {
            clientIds = clients.filter(rec => rec.region === pipeline.levelID).map((rec) => { return rec.documentID })
        }
        else if (pipeline.level === 'group') {
            clientIds = clients.filter(rec => rec.group === pipeline.levelID).map((rec) => { return rec.documentID })
        }
        let promises = []
        let enquiries = [];
        clientIds.forEach(clientID => {
            promises.push(firestoreDB(props.dealersettings).firestore().collection('tradeinPro')
                .where('clientID', '==', clientID)
                .where('isDeleted', '==', false)
                .where('pipeline', '==', pipeline.value)
                .limit(1)
                .get())
        })
        const snapshots = await Promise.all(promises)
        snapshots.forEach(response => {
            response.docs.forEach((doc) => {
                enquiries.push(doc.data())
            });
        });

        if (enquiries.length > 0) {
            setPopHeader(`Delete ${pipeline.name} Pipeline`)
            setDeleteStage(null)
            setEditPipeline({
                ...pipeline,
                levelID: pipeline.level === 'region' ? regions.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                    pipeline.level === 'groups' ? groups.filter(e => !_.isEmpty(e.pipelines) && e.pipelines.some(a => a.value === pipeline.value)).map(rec => { return rec.documentID }) :
                        [pipeline.levelID]
            })
            setDeletePipeline(pipeline)
            setShowMovePipeline(true)
        }
        else {
            deleteSelectedPipeline(pipeline)
        }
    }

    return (<>
        {
            liteVersion && !loader ? (<UpgradePlan />) : (<></>)
        }
        <h2> <Translate text={'Pipeline Settings'} /></h2>
        <div className="divider-line"></div>
        <div className={`settings-pipeline-wrap ${liteVersion ? 'form-lite' : ''}`}>
            <div className="settings-head">

                <div className="col-md-3 pl-0 float-left">
                    <ReactSelect
                        options={clientOptions}
                        name={"clientID"}
                        placeholder={'select client'}
                        onChange={(e, data) => {
                            if (e) {
                                if (pipelines.length > 0)
                                    setLoader(true)
                                setSelectedClient(e.data)
                            }
                            else {
                                setSelectedClient(null)
                            }
                        }}
                        value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                        classNamePrefix={`cursor-pointer basic-select`}
                        removeClearable={true}
                    >
                    </ReactSelect>
                </div>
                <div className="float-right">
                    <button type="button" className="btn btn-primary float-right"
                        onClick={() => {
                            setShowmodel(true)
                            setPopHeader('Add Pipeline')
                            setEditPipeline(null)
                        }}
                    ><i className="ico icon-add mr-2"></i>    <Translate text={'New Pipeline'} /></button>
                </div>
                {
                    pipelines.length > 1 ? (<div className="float-right mr-2">
                        <button type="button" className="btn btn-default float-right"
                            onClick={() => {
                                setSortPipelineModel(true)
                                setPopHeader('Sort Pipeline')
                            }}
                        ><i className="ico icon-rearrange mr-2 mt-1"></i>    <Translate text={'Sort Pipeline'} /></button>
                    </div>) : (<></>)
                }
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : pipelines.length > 0 ? (<>
                    {
                        pipelines.map((rec, index) => {
                            return <PipelineStages
                                {...props}
                                key={index}
                                pipeline={rec}
                                hanleEditPipeline={hanleEditPipeline}
                                hanleEditStage={hanleEditStage}
                                handleAddStage={handleAddStage}
                                hanleDeleteStage={hanleDeleteStage}
                                hanleRearrangeStage={hanleRearrangeStage}
                                hanleDeletePipeline={hanleDeletePipeline}
                                enquiryStatus={enquiryStatus}
                                allPipelines={pipelines}
                                dealersettings={props.dealersettings}
                                selectedClient={selectedClient}
                            />
                        })
                    }
                </>) : (
                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                        <div className="text-center p-5">
                            <p> <Translate text={'No pipelines found.'} /></p>
                            <button className="btn btn-sm btn-default" onClick={() => {
                                setShowmodel(true)
                                setPopHeader('Add Pipeline')
                                setEditPipeline(null)
                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                <Translate text={'Add Pipeline'} />
                            </button>
                        </div>
                    </div>
                )

            }

        </div>
        <PopUpModal show={showModel}>
            <AddPipeline
                {...props}
                show={showModel}
                title={popHeader}
                handleClose={handleModelClose}
                pipeline={editPipeline}
                allPipelines={sortPipelines}
                pipelines={pipelines}
                groupOptions={groupOptions}
                clientOptions={clientOptions}
                regionOptions={regionOptions}
                selectedClient={selectedClient}
                sortpipelineValues={sortpipelineValues}
            />
        </PopUpModal>
        <PopUpModal show={showStageModel}>
            <AddStage
                {...props}
                show={showStageModel}
                title={popHeader}
                handleClose={handleModelClose}
                allPipelines={sortPipelines}
                pipeline={editPipeline}
                stage={editStage}
                selectedClient={selectedClient}
                enquiryStatus={enquiryStatus}
            />
        </PopUpModal>
        <PopUpModal show={showRearrangeModel}>
            <RearrangeStage
                {...props}
                show={showRearrangeModel}
                title={popHeader}
                handleClose={handleModelClose}
                allPipelines={sortPipelines}
                pipeline={editPipeline}
                selectedClient={selectedClient}
            />
        </PopUpModal>
        <PopUpModal show={showMovePipeline}>
            <MovePipeline
                {...props}
                show={showMovePipeline}
                title={popHeader}
                handleClose={handleModelClose}
                allPipelines={sortPipelines}
                pipeline={editPipeline}
                deletePipeline={deletePipeline}
                deleteStage={deleteStage}
                selectedClient={selectedClient}
                sortpipelineValues={sortpipelineValues}
                pipelineOptions={pipelines}
            />
        </PopUpModal>
        <PopUpModal show={sortPipelineModel}>
            <RearrangePipeline
                {...props}
                show={sortPipelineModel}
                title={popHeader}
                handleClose={handleModelClose}
                allPipelines={pipelines}
                selectedClient={selectedClient}
            />
        </PopUpModal>
    </>);
}

export default TradeinProPipelineSettings;