import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { PopUpModal, ContentOverlay, TableView } from '../../../components'
import toast from 'toasted-notes'
import { reportlistFields } from './viewModel';
import ScheduleReport from '../scheduleReport';
import Swal from 'sweetalert2'
import cronstrue from 'cronstrue';
import ReportDetails from './details';

const StaticReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 290) : (window.innerWidth - 380), windowHeight: (window.innerHeight - 315) })
    const [loader, setLoader] = useState(true)
    const [reports, setReports] = useState([])
    const [selectedReport, setReport] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const [showSchedulerModel, setShowSchedulerModel] = useState(false)
    const [showDetailsModel, setShowDetailsModel] = useState(false)
    const [searchText, setSearchText] = useState()
    const timezone = props?.dealersettings?.client?.timezone ? props.dealersettings.client.timezone : moment.tz.guess();

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 290) : (window.innerWidth - 380),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        let refstaticReports = window.firebase.firestore().collection('schedular')
            .where('static', '==', true);

        if (localStorage.defaultModule === 'oem') {
            refstaticReports = refstaticReports.where('oemID', '==', props.dealersettings.oemID)
        }
        else{
            refstaticReports = refstaticReports.where('clientID', '==', props.dealersettings.client.id)
        }

        const refstaticReportsCollection = refstaticReports.onSnapshot(snap => {
            let _report = [];
            snap.docs.forEach(rec => {
                _report.push({
                    ...rec.data(),
                    enabled: rec.data().enabled ? true : false,
                    cronDesc: !_.isEmpty(rec.data().cron) ? `${rec.data().frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(rec.data().cron)}` : '',
                    strfileType: !_.isEmpty(rec.data().fileType) ? rec.data().fileType.toUpperCase() : '',
                    lastExecution: rec.data().enabled && !_.isEmpty(rec.data().log) && rec.data().log.dt ? moment.unix(rec.data().log.dt.seconds).tz(timezone).format('DD/MM/YYYY hh:mm A') : '',
                    nextExecution: rec.data().enabled && !_.isEmpty(rec.data().log) && rec.data().log.dtNext ? moment.unix(rec.data().log.dtNext.seconds).tz(timezone).format('DD/MM/YYYY hh:mm A') : '',
                    status: rec.data().enabled && !_.isEmpty(rec.data().log) && rec.data().log.success && !_.isEmpty(rec.data().log.success.toString()) ? rec.data().log.success : '',
                    strStatus: rec.data().enabled && !_.isEmpty(rec.data().log) && rec.data().log.success && !_.isEmpty(rec.data().log.success.toString()) ? rec.data().log.success === true ? (
                        <div className="badge badge-pill badge-active-fill">success</div>
                    ) : (
                        <div className="badge badge-pill badge-cancelled-fill">failed</div>
                    ) : '',
                    fileURL: rec.data().enabled && !_.isEmpty(rec.data().log) ? rec.data().log.url : '',
                    errorMessage: rec.data().enabled && !_.isEmpty(rec.data().log) ? rec.data().log.error : ''
                });
            })
            setReports(_report.filter(a => _.isEmpty(a.parentID)));
            setLoader(false);
        });

        return () => {
            refstaticReportsCollection && refstaticReportsCollection();
        }

    }, [])
    const handleModelClose = () => {
        setShowSchedulerModel(false)
        setShowDetailsModel(false)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                {
                    data.enabled === true ? (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                        setReport(data)
                        setShowDetailsModel(true)
                    }}>
                        <i className="ico icon-zoom"></i>
                    </div>) : (<></>)
                }
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditReport(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
            </div>
        )
    }


    const hadleEditReport = (data) => {
        setPopHeader('Edit Scheduler')
        setReport(data)
        setShowSchedulerModel(true)
    }

    const handleReportdetailsClick = (e, id, data) => {

    }

    const handleSwitchChange = (id, val) => {
        const objReport = reports.find(item => item.documentID === id);
        if (val === true) {
            setPopHeader('Edit Scheduler')
            setReport({
                ...objReport,
                enabled: true
            })
            setShowSchedulerModel(true)
        }
        else {
            if (!_.isEmpty(objReport)) {
                objReport.enabled = val
                window.firebase.firestore().doc(`schedular/${objReport.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        enabled: false,
                        cron: null,
                        schedulartime: null,
                        day: null,
                        date: null,
                        frequency: null,
                        multiSchedule: null
                    }, { merge: true })
                    .then(snapshot => {
                        if (objReport?.multiSchedule?.length > 0) {
                            try {
                                objReport.multiSchedule.forEach(async rec => {
                                    await window.firebase.firestore().doc(`schedular/${rec}`).delete()
                                })
                            } catch { }
                        }
                        toast.notify('Scheduler updated successfully', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        Swal.fire('Something went wrong', '', 'error')
                    });
            }
        }
    }
    return (<>
        <h2> Static Reports</h2>
        <div className="divider-line"></div>
        <div className="settings-templates-wrap">

            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="filter-search search-icon">
                                    <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :

                <div className="common-table" style={{ width: windowSize.windowWidth + 'px' }}>
                    <TableView
                        datalist={reports.filter(({ name }) =>
                        (!_.isEmpty(searchText) ? (
                            (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                        ) : true))}
                        height={windowSize.windowHeight}
                        width={windowSize.windowWidth}
                        columns={reportlistFields}
                        dynamicFields={reportlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                        handleActionClick={handleActionClick}
                        handleSwitchChange={handleSwitchChange}
                        handleRowSingleClick={handleReportdetailsClick}
                    />
                </div>
        }
        <PopUpModal show={showSchedulerModel}>
            <ScheduleReport
                {...props}
                show={showSchedulerModel}
                handleClose={handleModelClose}
                title={popHeader}
                report={selectedReport}
            ></ScheduleReport>
        </PopUpModal>
        <PopUpModal show={showDetailsModel}>
            <ReportDetails
                {...props}
                show={showDetailsModel}
                handleClose={handleModelClose}
                report={selectedReport}
            ></ReportDetails>
        </PopUpModal>
    </>);


}


export default StaticReport;