import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import { InputText, ReactSelect, InputMultipleText } from '../../components'
import Swal from 'sweetalert2'
import { attributes } from './viewModel';
import Translate from '../../constants/translate';
import { Settings } from 'meilisearch'

const UpdateAttributes = (props) => {

    const [collection, setCollection] = useState(props.collection);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false);
    const [task, setTask] = useState();

    useEffect(() => {
        async function getTask() {
            const resp = await props.meiliSearchConfig.getTasks({
                limit: 1,
                indexUid: [props.collection.value],
                type: ['settingsUpdate']
            });
            //console.log(resp);
            if (resp.results && resp.results.length > 0) {
                setTask(resp.results[0]);
                if ((resp.results[0].status === 'enqueued' || resp.results[0].status === 'processing'))
                    setCollection({
                        ...collection,
                        strfilterableAttributes: resp.results[0].details.filterableAttributes.join(','),
                        strsortableAttributes: resp.results[0].details.sortableAttributes.join(','),
                        strsearchableAttributes: resp.results[0].details.searchableAttributes.join(','),
                        strrankingRules: resp.results[0].details.rankingRules.join(',')
                    })
            }
        }

        getTask();
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setCollection({
                ...collection,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setCollection({
                    ...collection,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setCollection({
                    ...collection,
                    [name]: str
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }


    const updateSettings = async (e) => {
        try {
            setLoader(true);
            //console.log(collection.allSettings);
            const resp = await props.meiliSearchConfig.index(collection.value)
                .updateSettings(collection.allSettings);
            const task = await props.meiliSearchConfig.getTask(resp.taskUid)
            //console.log(task)
            toast.notify('Attributes updated successfully', {
                duration: 2000
            })
            props.handleClose();
        }
        catch (err) {
            console.log(err);
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }

    }

    const handleOnChangeMultipleText = (val, name) => {
        setCollection({
            ...collection,
            [`str${name}`]: !_.isEmpty(val) ? val.join(',') : [],
            allSettings: {
                ...collection.allSettings,
                [name]: val
            }
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeField = (index, name) => {
        let arr = collection[`str${name}`] ? collection[`str${name}`].split(',') : []
        arr.splice(index, 1)
        setCollection({
            ...collection,
            [`str${name}`]: arr.join(','),
            allSettings: {
                ...collection.allSettings,
                [name]: arr
            }
        })
    }
    return _.isEmpty(collection) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-update-password"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <div className="settings-tab-fields-wrap">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Collection</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'name'}
                                                        className={`form-control ${errorFields["name"]}`}
                                                        name="name"
                                                        onChange={handleOnChange}
                                                        value={collection.name}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Filterable Attributes</label>
                                                    <InputMultipleText
                                                        placeholder={`set filterable attributes`}
                                                        className={`form-control react-multi-tag`}
                                                        name="filterableAttributes"
                                                        onChange={(val) => { handleOnChangeMultipleText(val, 'filterableAttributes') }}
                                                        value={!_.isEmpty(collection.strfilterableAttributes) ? collection.strfilterableAttributes.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(val, index) => {
                                                            return <div data-tag key={index}>
                                                                {val}
                                                                <span data-tag-handle onClick={() => removeField(index, 'filterableAttributes')}>
                                                                    ×
                                                                </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Searchable Attributes</label>
                                                    <InputMultipleText
                                                        placeholder={`set searchable attributes`}
                                                        className={`form-control react-multi-tag`}
                                                        name="searchableAttributes"
                                                        onChange={(val) => { handleOnChangeMultipleText(val, 'searchableAttributes') }}
                                                        value={!_.isEmpty(collection.strsearchableAttributes) ? collection.strsearchableAttributes.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(val, index) => {
                                                            return <div data-tag key={index}>
                                                                {val}
                                                                <span data-tag-handle onClick={() => removeField(index, 'searchableAttributes')}>
                                                                    ×
                                                                </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Sortable Attributes</label>
                                                    <InputMultipleText
                                                        placeholder={`set sortable attributes`}
                                                        className={`form-control react-multi-tag`}
                                                        name="sortableAttributes"
                                                        onChange={(val) => { handleOnChangeMultipleText(val, 'sortableAttributes') }}
                                                        value={!_.isEmpty(collection.strsortableAttributes) ? collection.strsortableAttributes.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(val, index) => {
                                                            return <div data-tag key={index}>
                                                                {val}
                                                                <span data-tag-handle onClick={() => removeField(index, 'sortableAttributes')}>
                                                                    ×
                                                                </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Ranking Rules</label>
                                                    <InputMultipleText
                                                        placeholder={`set ranking rules`}
                                                        className={`form-control react-multi-tag`}
                                                        name="rankingRules"
                                                        onChange={(val) => { handleOnChangeMultipleText(val, 'rankingRules') }}
                                                        value={!_.isEmpty(collection.strrankingRules) ? collection.strrankingRules.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(val, index) => {
                                                            return <div data-tag key={index}>
                                                                {val}
                                                                <span data-tag-handle onClick={() => removeField(index, 'rankingRules')}>
                                                                    ×
                                                                </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {
                        task ? (
                            <>
                                <div className="vehicle-status-alert alert-user mb-1">
                                    <b className="alert-link">Status : </b>{task.status}<br />
                                    <b className="alert-link">Started At : </b>{moment(task.startedAt).format('DD/MM/YYYY h:mm A')}<br />
                                    {
                                        task.finishedAt ? (<>
                                            <b className="alert-link">Finished At : </b>{moment(task.finishedAt).format('DD/MM/YYYY h:mm A')}</>
                                        ) : (<></>)
                                    }
                                </div>
                            </>
                        ) : (<></>)
                    }
                    {
                        _.isEmpty(task) || task.status === 'succeeded' || task.status === 'failed' ? (
                            <>
                                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => updateSettings(e)}>
                                    {
                                        loader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }
                                    <Translate text={'save'} />
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-default float-right'
                                    onClick={() => props.handleClose()}
                                >
                                    <Translate text={'cancel'} />
                                </button>
                            </>
                        ) : (<></>)
                    }

                </Modal.Footer >
            </Modal >
        </>
    )
}

export default UpdateAttributes;