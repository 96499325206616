/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** PROVIDERS */

/** COMPONENTS */
import CommonHelper from '../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import Translate from '../../constants/translate';
import { serviceStatus } from '../../services/enum'
/** VIEW-MODELS */
import { objService, allCancelledFieldsVM } from '../service/viewModel'
import StockListReducer from '../stock/stockListReducer'
import { firestoreDB } from '../../services/helper';

const RejectLogList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [rejectContacts, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    //const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [rejectLoader, setRejectLoader] = useState(true)
    const pageLimit = 50

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            //window.removeEventListener('resize', handleResize);
            window.unSubscribeCancelLogList && window.unSubscribeCancelLogList();
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }
        let collectionDataRef = firestoreDB(props.dealersettings).firestore().collection('serviceJobs')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', serviceStatus.CANCELLED)
            .where('isDeleted', '==', false)

        if (searchText && searchText.trim()) {
            collectionDataRef = collectionDataRef
                .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        }

        collectionDataRef = collectionDataRef
            .orderBy('modifiedDate', 'desc')

        if (hasMore.length > 0) {
            collectionDataRef = collectionDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            collectionDataRef = collectionDataRef
                .limit(pageLimit)
        }

        window.unSubscribeCancelLogList = collectionDataRef
            .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        const rejectContacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                rejectContacts.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: rejectContacts,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : rejectContacts,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }


        setDataload(false)
        setRejectLoader(false)
        setPaging(false)
    }



    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;
        const _obj = Object.assign({}, doc);
        const queueVM = Object.assign({});
        for (let [key, value] of Object.entries(_obj)) {
            queueVM[key] = value;
        }

        queueVM.createdOn = moment.unix(queueVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        queueVM.updatedOn = moment.unix(queueVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        queueVM.appointmentDate = queueVM.appointmentDate ? moment(queueVM.appointmentDate).format('DD/MM/YYYY hh:mm A') : '';

        if (!_.isEmpty(queueVM.contact)) {
            queueVM.contactName = CommonHelper.displayContact([], queueVM.contact, '--');
            queueVM.contactNumber = doc.contact.displayID;
            queueVM.contactPhone = CommonHelper.getFullPhone(queueVM.phoneCode, queueVM.phone);
            queueVM.contactEmail = queueVM.email;

            if (dealersettings.group && doc.contact.clientID && (doc.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
                queueVM.dealerName = CommonHelper.getOtherDealerName(dealersettings, doc.contact.clientID);
            }
            else
                queueVM.dealerName = '';
        }
        if (!_.isEmpty(queueVM.vehicle)) {
            queueVM.vehicleModel = queueVM.vehicle.make + ' ' + queueVM.vehicle.model
            queueVM.vehicleYear = doc.vehicle.year ? doc.vehicle.year : '';
        }

        queueVM.userName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.owner);
        queueVM.addedByName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.addedBy);
        queueVM.modifiedByName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.modifiedBy);
        return queueVM
    }



    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            //sidepanelOpen(id)
            props.history.push("/service/details/" + id);
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const handleDetailsClick = (e) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handlesearchText = (val) => {
        setSearchText(val)
        setRejectLoader(true)
        setDataload(true);
        setPaging(false);
        setHasMoreData([])
    }
    return (
        <>
            <Modal
                id="rejected-logs"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'cancelledAppointments'} />
                            <span className="badge badge-pill badge-rejected-count-red ml-1">{props.cancelledCount}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid">
                        <div className="row">

                            <div className="settings-head">
                                <div className="float-left">
                                    {/* FILTER SEARCH KEYWORDS*/}
                                    <div className="filter-search">
                                        <div className="input-group ">
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="isearch"
                                                placeholder="quick search..."
                                                value={searchText ? searchText : ''}
                                                onChange={(e) => {
                                                    let _val = e.target.value
                                                    setSearchText(_val);
                                                    if (_val && _val.length > 2) {
                                                        handlesearchText(_val)
                                                    }

                                                    if (!_val)
                                                        handlesearchText('')
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handlesearchText(searchText)
                                                    }
                                                }}
                                                autoComplete="off"
                                            />
                                            <div className="input-group-append input-search-clear">
                                                {
                                                    searchText && searchText.length > 0
                                                        ?
                                                        <a href="#"
                                                            className="input-search-clear-icon"
                                                            title="Clear"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handlesearchText('')
                                                            }}
                                                        >
                                                            <i className="ico icon-remove"></i>
                                                        </a>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                <button
                                                    className="btn btn-default"
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handlesearchText(searchText)
                                                    }}>
                                                    <i className="ico icon-search-fill"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>


                            {
                                rejectLoader ? (<>
                                    <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                        <div className="spinner-loader h-100 undefined ">
                                            <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                <div role="status" className="spinner-border text-primary"></div>
                                                <p className="text-muted mt-3"></p>
                                            </div>
                                        </div>
                                    </div>
                                </>) :
                                    (
                                        <div className="common-table" >
                                            <TableView
                                                isReorderable={false}
                                                datalist={rejectContacts}
                                                height={windowSize.windowHeight}
                                                width={windowSize.windowWidth}
                                                columns={allCancelledFieldsVM}
                                                dynamicFields={allCancelledFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                settingsLoader={settingsLoader}
                                                handleActionClick={handleActionClick}
                                                handleRowSingleClick={handleDetailsClick}
                                                isSettings={false}
                                                hasMore={hasMore}
                                                handlePagination={handlePagination}
                                                isPaging={true}
                                            />
                                        </div>
                                    )
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default RejectLogList