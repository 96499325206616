/** LIBRARIES */
import React from 'react';
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import CommonHelper from "../../services/common";

const FleetViewItem = React.memo(({ id, addedBy, fleetTag, fleetLog, dealersettings, handleFleetOpen }) => {
    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div className="dashboard-activity-loop" id={`activity-${id}`}>
                    <div className="dashboard-activity-list">
                        {
                            fleetLog && !_.isEmpty(fleetLog.stock) ? (<>
                                <div className="dashboard-activity-title">
                                    {/* <div className="dashboard-activity-icon">
                                        <i className={`ico ${type === 'call' ? 'icon-' + type : type === 'email' ? 'icon-Mail' : type === 'task' ? 'icon-activity' : 'icon-activity-' + type}`}></i>
                                    </div> */}
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleFleetOpen(e, fleetLog) }}>
                                        {`${fleetLog.stock
                                            ? fleetLog.stock.make
                                            : ''
                                            } ${fleetLog.stock
                                                ? fleetLog.stock.model
                                                : ''
                                            }`}
                                    </a>
                                </div>
                            </>) : (<></>)
                        }

                        {
                            fleetLog && !_.isEmpty(fleetLog.contact) ? (<>
                                <div className="dashboard-activity-user">
                                    <i className="ico icon-Contacts"></i> {CommonHelper.displayContactName([], fleetLog.contact)}
                                </div>
                                {
                                    fleetLog.contact.clientID && (fleetLog.contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(dealersettings, fleetLog.contact.clientID)}</span>) : (<></>)
                                }
                            </>) : fleetLog && !_.isEmpty(fleetLog.staff) ? (<>
                                <div className="dashboard-activity-user">
                                    <i className="ico icon-Contacts"></i> {fleetLog.staff.name ? fleetLog.staff.name + ' (staff)' : ''}
                                </div>
                            </>) : (<></>)
                        }

                        {
                            fleetLog.expectedReturn
                                ?
                                <div className="dashboard-activity-date w-100 d-inline-block">

                                    <span className={`${fleetTag === 'Overdue' ? 'activity-due' : fleetTag === 'Today' ? '' : ''}`}>
                                        {
                                            fleetTag === 'Overdue'
                                                ? (moment().diff(moment.unix(fleetLog.expectedReturn.seconds), 'days') === 1 ? '1 day ago' : moment().to(moment.unix(fleetLog.expectedReturn.seconds)))
                                                : moment.unix(fleetLog.expectedReturn.seconds).format('DD/MM/YYYY h:mm A')
                                        }
                                    </span> - {addedBy}</div>
                                : fleetLog.startDate ?
                                    <div className="dashboard-activity-date w-100 d-inline-block">

                                        <span className={`${fleetTag === 'Overdue' ? 'activity-due' : fleetTag === 'Today' ? '' : ''}`}>
                                            {
                                                fleetTag === 'Overdue'
                                                    ? (moment().diff(moment.unix(fleetLog.startDate.seconds), 'days') === 1 ? '1 day ago' : moment().to(moment.unix(fleetLog.startDate.seconds)))
                                                    : moment.unix(fleetLog.startDate.seconds).format('DD/MM/YYYY h:mm A')
                                            }
                                        </span> - {addedBy}</div>
                                    : <></>
                        }

                    </div>
                </div>
            </>
            :
            <></>

    )
})
export default FleetViewItem;

