/** LIBRARIES */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import htmlToText from "html-to-text";
import NumberFormat from "react-number-format";
/** COMPONENTS */
import { CustomMenu, CustomToggle } from "../../../components/customdropdown";
import { AudioPlayer, PopUpModal, PopoverPanel } from "../../../components";
import CommonHelper from "../../../services/common";
import CustomerEditLog from "../quickview/editLogs";
import Translate from "../../../constants/translate";
import { objEnquiry } from "../viewModel";
import { objContact } from "../../contacts/viewModel";
import { objCompany } from "../../company/viewModel";
import { objTradeIP } from "../../tradeinplus/viewModel";
import { objService } from "../../service/viewModel";
import { objEventPro } from "../../eventsPro/viewModel";
import { callTypes, defaultFinanceTerms } from "../../../services/enum";
import AddCallNotes from "./addCallNotes";
import { mapProps } from "recompose";
import TriggerHistoryModal from "../../triggersLog/triggerHistoryModal";
import TranscriptModal from './transcriptModal'

const opts = [
  { active: true, value: true, label: "Opt In" },
  { active: true, value: false, label: "Opt Out" },
];

// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]

const __financestatus = [
  {
    active: true,
    value: "InProgress",
    label: (
      <div className={`badge badge-pill badge-status-inprogress`}>
        <Translate text={"InProgress"} upperCase={true} />
      </div>
    ),
  },
  {
    active: true,
    value: "Pending",
    label: (
      <div className={`badge badge-pill badge-status-pending`}>
        <Translate text={"Pending"} upperCase={true} />
      </div>
    ),
  },
  {
    active: true,
    value: "Won",
    label: (
      <div className={`badge badge-pill badge-status-won`}>
        <Translate text={"won"} upperCase={true} />
      </div>
    ),
  },
  {
    active: true,
    value: "Lost",
    label: (
      <div className={`badge badge-pill badge-status-lost`}>
        <Translate text={"lost"} upperCase={true} />
      </div>
    ),
  },
];

const __flowStatus = [
  {
    active: true,
    value: "updated",
    label: (
      <div className={`badge badge-pill badge-status-pending`}>
        <Translate text={"updated"} upperCase={true} />
      </div>
    ),
  },
  {
    active: true,
    value: "added",
    label: (
      <div className={`badge badge-pill badge-status-won`}>
        <Translate text={"added"} upperCase={true} />
      </div>
    ),
  },
  {
    active: true,
    value: "deleted",
    label: (
      <div className={`badge badge-pill badge-status-lost`}>
        <Translate text={"deleted"} upperCase={true} />
      </div>
    ),
  },
];

/*const enquiryStatus = [
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'closeWon', label: <div className={`badge badge-pill badge-status-won`}>CLOSE WON</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> },
    { active: true, value: 'pendingLost', label: <div className={`badge badge-pill badge-status-pendinglost`}>PENDING APPROVAL</div> },
    { active: true, value: 'prospectLost', label: <div className={`badge badge-pill badge-status-lost`}>PROSPECT LOST</div> }
]*/

let tradeinProAllStatus = [];
let serviceAllStatus = [];
let technicianAllStatus = [];
let eventsProAllStatus = [];
let afterMarketStatuses = [];
var excludedCompany = [
  "documentID",
  //"clientID",
  "projectId",
  "groupID",
  "regionID",
  "oemID",
  "associatedCompany",
  "addedBy",
  "modifiedBy",
  "modifiedFrom",
  "addedDate",
  "modifiedDate",
  //"isDeleted",
  //"owner",
  "keywords",
];
var excludedContact = [
  "documentID",
  //"clientID",
  "projectId",
  "groupID",
  "regionID",
  "oemID",
  "customerNumber",
  "addedBy",
  "modifiedBy",
  "modifiedFrom",
  "addedDate",
  "modifiedDate",
  //"isDeleted",
  //"owner",
  "tags",
  "keywords",
  "terms",
  "mergeID",
  "isRepeat",
  "creditScore",
  "creditScoreTerms"
];
var excludedEnquiry = [
  "documentID",
  "displayID",
  //"clientID",
  "projectId",
  "groupID",
  "regionID",
  "oemID",
  "addedBy",
  "modifiedBy",
  "modifiedFrom",
  "addedDate",
  "modifiedDate",
  //"isDeleted",
  "keywords",
  "contact",
  "contactID",
  "requirement",
  "isContact",
  "isVehicle",
  "isTestDrive",
  "isTradeIn",
  "isCafe",
  "isFiles",
  "isNotes",
  "isFinance",
  "isVideo",
  "isQuotation",
  "isProduct",
  "stageHistory",
  "responseTime",
  "responseTimeDisplay",
  "counts",
  "engageContactName",
  "lastStatus",
  "isNewEnquiry",
  "options",
  "nextActivity",
  "isConverted",
  "isConvertedGroup",
  "isConvertedOEM",
  "isConvertedRegion",
  "convertedGroupID",
  "convertedOEMID",
  "convertedRegionID",
  "quotation",
  "walkins",
  "tags",
  "requirementCount",
  "soldCount",
  "lostRefID",
  "bdcStatus",
  "settingsID",
  "oemStatus",
  "linkEnquiryID",
  "convertedLeadRefID",
  "createdDate",
  "td",
  "isInvoice",
  "invoice",
  "bulkDt",
  "unactionedNotify",
  "statusHistory",
];

var excludedTradeinPro = [
  "documentID",
  "projectId",
  "displayID",
  "addedBy",
  "modifiedBy",
  "modifiedFrom",
  "addedDate",
  "modifiedDate",
  "offer",
  "keywords",
  "contact",
  "contactID",
  "tradein",
  "isContact",
  "isTradein",
  "isFiles",
  "isNewTradein",
  "stageHistory",
  "tags",
  "settingsID",
  "finalizedFrom",
];

var excludedService = [
  "documentID",
  "clientID",
  "projectId",
  "contactID",
  "displayID",
  "counts",
  "keywords",
  "contact",
  "isServicePackage",
  "isContact",
  "isVehicle",
  "isTradeIn",
  "isInspection",
  "isChecklist",
  "isBbtChecklist",
  "isFiles",
  "isFav",
  "totalServiceCost",
  "totalCost",
  "stageHistory",
  "images",
  "parts",
  "videos",
  "marks",
  "teammates",
  "mentions",
  "addedBy",
  "modifiedBy",
  "addedDate",
  "modifiedDate",
  "modifiedFrom",
  "addedFrom",
  "taxType",
  "taxPercentage",
  "disclaimer",
  "userSign",
  "loanAppointmentID",
  "discount",
  "discountType",
  "totalDeclinedCost",
  "statusHistory",
  "responseDate",
  "dailyroID",
  "engageNewContact",
];

var excludedEventsPro = [
  "documentID",
  "projectId",
  "displayID",
  "addedBy",
  "modifiedBy",
  "modifiedFrom",
  "addedDate",
  "modifiedDate",
  "keywords",
  "eventDates",
  "imageURL",
  "areaCode",
  "country",
  "state",
  "isFiles",
  "isNewEvent",
  "stageHistory",
  "statusHistory",
  "tags",
  "settingsID",
  "counts",
];
const ActivityTag = React.memo((props) => {
  const {
    title,
    activityDate,
    id,
    type,
    addedBy,
    handleEditactivity,
    handleComplete,
    completed,
    activitylog,
    handleDeleteactivity,
    scheduled,
    isEditable,
    handlechangelog,
    handlesavelog,
    handlecancellog,
    textareaRef,
    handleDateChangelog,
    callStatus,
    completedTab,
    logLoader,
    dealersettings,
    handlePreviewEmail,
    modifiedBy,
    handleUnlickEmail,
    handleEDMsPreviewEmail,
    clientUsers,
    financeStatuses,
    groupUsers,
    titles,
    languages,
    countries,
    states,
    origins,
    enquiryTypes,
    campaigns,
    nationalities,
    genders,
    marital_status,
    license_type,
    interested,
    contact_method,
    company_type,
    lostReasons,
    lostSubReasons,
    pipelines,
    stages,
    enquiryOptionsDF,
    tipPipelines,
    tipStages,
    tipOffers,
    handleReplylog,
    contact,
    enquiry,
    tradeinPro,
    companyView,
    history,
    hideMoreOption,
    service,
    servicePipelines,
    serviceStages,
    originType,
    updateActivity,
    eventsPro,
    eventPipelines,
    eventStages,
    eventsProAttendee,
    handleFileChange,
    handletriggerhistory,
  } = props;
  const [taskDone, setTaskdone] = useState(false);
  const [arrcallstatus, setCallStatus] = useState([]);
  const [showLog, setShowLog] = useState(false);
  const [enquiryAllStatus, setAllStatus] = useState([]);
  const [notesModel, setNotesModel] = useState({ show: false });
  const [showHistoryModal, setShowHistoryModal] = useState({
    show: false,
    data: {},
    title: "Stats Timeline",
  });

  const [showTranscriptModal, setShowTranscriptModal] = useState({
    show: false,
    data: {}
  });
  const currencySymbol =
    !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.currency)
      ? dealersettings.client.currency.symbol + " "
      : "$ ";

  const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;

  const aircallSettings =
    !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.integrations) &&
      dealersettings.client.integrations.filter((e) => e.type === "aircall")[0] &&
      dealersettings.client.integrations.filter((e) => e.type === "aircall")[0]
        .active === true &&
      dealersettings.client.integrations.filter((e) => e.type === "aircall")[0]
        .enabled === true
      ? dealersettings.client.integrations.filter(
        (e) => e.type === "aircall"
      )[0].settings
      : null;

  const justcallSettings =
    !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.integrations) &&
      dealersettings.client.integrations.filter((e) => e.type === "justcall")[0] &&
      dealersettings.client.integrations.filter((e) => e.type === "justcall")[0]
        .active === true &&
      dealersettings.client.integrations.filter((e) => e.type === "justcall")[0]
        .enabled === true
      ? dealersettings.client.integrations.filter(
        (e) => e.type === "justcall"
      )[0].settings
      : null;

  const ringcentralSettings =
    !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.integrations) &&
      dealersettings.client.integrations.filter(
        (e) => e.type === "ringcentral"
      )[0] &&
      dealersettings.client.integrations.filter(
        (e) => e.type === "ringcentral"
      )[0].active === true &&
      dealersettings.client.integrations.filter(
        (e) => e.type === "ringcentral"
      )[0].enabled === true
      ? dealersettings.client.integrations.filter(
        (e) => e.type === "ringcentral"
      )[0].settings
      : null;

  useEffect(() => {
    setTaskdone(activitylog.isDone);
    setShowLog(false);
  }, [id]);

  useEffect(() => {
    if (
      !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.settings)
    ) {
      const setttings = dealersettings.client.settings;

      let options = [];
      setttings.callStatus &&
        setttings.callStatus.forEach((doc) => {
          options.push({
            value: doc.value,
            label: doc.name,
            active: _.isBoolean(doc.active) ? doc.active : true,
          });
        });

      let _enquiryAllStatus = [];
      setttings.enquiryStatus &&
        setttings.enquiryStatus.forEach((doc) => {
          _enquiryAllStatus.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-${doc.value === "open" ? "open" : "empty"
                  }`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name.toUpperCase()}
              </div>
            ),
          });
        });

      tradeinProAllStatus = [];
      dealersettings?.client?.tradeinPro?.tradeinProStatus &&
        dealersettings.client.tradeinPro.tradeinProStatus.forEach((doc) => {
          tradeinProAllStatus.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-${doc.value === "New" ? "open" : "empty"
                  }`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name}
              </div>
            ),
          });
        });

      eventsProAllStatus = [];
      dealersettings?.client?.eventsPro?.eventsProStatus &&
        dealersettings.client.eventsPro.eventsProStatus.forEach((doc) => {
          eventsProAllStatus.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-empty`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name}
              </div>
            ),
          });
        });

      serviceAllStatus = [];
      dealersettings?.client?.services?.serviceStatus &&
        dealersettings.client.services.serviceStatus.forEach((doc) => {
          serviceAllStatus.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-empty`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name}
              </div>
            ),
          });
        });

      technicianAllStatus = [];
      dealersettings?.client?.services?.technicianStatus &&
        dealersettings.client.services.technicianStatus.forEach((doc) => {
          technicianAllStatus.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-empty`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name}
              </div>
            ),
          });
        });

      afterMarketStatuses = [];
      dealersettings?.client?.settings?.afterMarketStatus &&
        dealersettings.client.settings.afterMarketStatus.forEach((doc) => {
          afterMarketStatuses.push({
            ...doc,
            label: (
              <div
                className={`badge badge-pill badge-status-empty`}
                style={{ background: doc.color ? doc.color : "#6b717f" }}
              >
                {doc.name}
              </div>
            ),
          });
        });
      setAllStatus(_enquiryAllStatus);
    }
  }, [dealersettings]);

  const bindSlot = (slot) => {
    return slot ? (
      <span style={{ color: `${slot.color ? slot.color : "#f47815 "}` }}>
        <i className="ico icon-service-timer"></i> {slot.name}
        {slot.isExpress ? (
          <span className="express-inline-tag">
            <div className="badge red-ribbon ml-2 express-fix">
              <Translate text={"express"} upperCase={true} />
            </div>
          </span>
        ) : (
          <></>
        )}
      </span>
    ) : (
      <>{"empty"}</>
    );
  };

  const bindChangeLog = (objLog) => {
    if (!_.isEmpty(objLog)) {
      if (!_.isEmpty(objLog.notes)) {
        if (!showLog) {
          setShowLog(true);
        }
        return (
          <div className="flow-activity-title text-area-space">
            {objLog.notes}
          </div>
        );
      } else {
        let _clients =
          dealersettings &&
          dealersettings.group &&
          dealersettings.group.clients;

        let _settings =
          objLog.clientID &&
          _clients &&
          _clients[objLog.clientID] &&
          _clients[objLog.clientID].settings;
        if (_.isEmpty(_settings))
          _settings = dealersettings.client && dealersettings.client.settings;

        let _tip_settings =
          objLog.clientID &&
          _clients &&
          _clients[objLog.clientID] &&
          _clients[objLog.clientID].tradeinPro;
        if (_.isEmpty(_tip_settings))
          _tip_settings =
            dealersettings.client && dealersettings.client.tradeinPro;

        let _events_settings =
          objLog.clientID &&
          _clients &&
          _clients[objLog.clientID] &&
          _clients[objLog.clientID].eventsPro;
        if (_.isEmpty(_events_settings))
          _events_settings =
            dealersettings.client && dealersettings.client.eventsPro;

        let _serviceSettings =
          objLog.clientID &&
          _clients &&
          _clients[objLog.clientID] &&
          _clients[objLog.clientID].services;
        if (_.isEmpty(_serviceSettings))
          _serviceSettings =
            dealersettings.client && dealersettings.client.services;

        let _oldData = Object.assign({}, objLog.oldData);
        let _newData = Object.assign({}, objLog.newData);

        let cells = [];
        if (objLog.origin === "contact") {
          let i = 0; //#CONTACT
          for (let [key] of Object.entries(objContact)) {
            if (
              excludedContact.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              }
              if (key === "company") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) ? _oldData[key].name : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) ? _newData[key].name : "empty"}
                    </span>
                  </div>
                );
              } else if (
                key === "userImageURL" ||
                key === "licenseFrontURL" ||
                key === "licenseBackURL" ||
                key === "visitingCardURL" ||
                key === "secondaryLicenseFrontUrl" ||
                key === "secondaryLicenseBackUrl" ||
                key === "userSignature"
              ) {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        __flowStatus,
                        _.isEmpty(_newData[key]) && !_.isEmpty(_oldData[key])
                          ? "deleted"
                          : !_.isEmpty(_oldData[key])
                            ? "updated"
                            : "added"
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "emails" ||
                key === "phones" ||
                key === "secondaryPhones" ||
                key === "secondaryEmails"
              ) {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        __flowStatus,
                        _.isEmpty(_newData[key]) && !_.isEmpty(_oldData[key])
                          ? "deleted"
                          : !_.isEmpty(_oldData[key])
                            ? "updated"
                            : "added"
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "businessContact" ||
                key === "isVIP" ||
                key === "isDLScan" ||
                key === "banLoanVehicle" ||
                key === "gdprDeleted"
              ) {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] === true ? "Yes" : "No"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] === true ? "Yes" : "No"}
                    </span>
                  </div>
                );
              } else if (key === "clientID") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "optinPhone" ||
                key === "optinSMS" ||
                key === "optinEmail" ||
                key === "optinPost" ||
                key === "marketingOptinEmail" ||
                key === "marketingOptinSMS"
              ) {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        opts,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        opts,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "title" || key === "secondaryTitle") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.titles,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.titles,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "language") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        languages,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        languages,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "origin") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.origins,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.origins,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "campaign") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.campaigns,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.campaigns,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "gender" || key === "secondaryGender") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.genders,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.genders,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "maritalStatus") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.maritalStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.maritalStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "licenseType") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.licenseType,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.licenseType,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "contactMethod") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.contactMethod,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.contactMethod,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "nationality") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        nationalities,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        nationalities,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "country") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        countries,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        countries,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "state" || key === "licenseState") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        states,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        states,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "interests") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByMultipleValues(
                        _settings && _settings.interests,
                        _oldData[key],
                        "empty",
                        true
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key])
                        ? (!_.isEmpty(_oldData[key])
                          ? CommonHelper.getLabelByMultipleValues(
                            _settings && _settings.interests,
                            _oldData[key],
                            "",
                            true
                          ) + ", "
                          : "") +
                        CommonHelper.getLabelByMultipleValues(
                          _settings && _settings.interests,
                          _newData[key],
                          "",
                          true
                        )
                        : "empty"}
                    </span>
                  </div>
                );
              } else if (key === "dob" || key === "licenseExpiry" || key === "secondaryDob" || key === "secondaryIDExpiry") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key]
                        ? moment(_oldData[key]).format("DD MMM YYYY")
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key]
                        ? moment(_newData[key]).format("DD MMM YYYY")
                        : "empty"}
                    </span>
                  </div>
                );
              } else if (key === "owner") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"contact"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                        ? _oldData[key]
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                        ? _newData[key]
                        : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }

          // if (_newData && _newData['modifiedFrom'] && cells.length > 0) {
          //     cells.push(
          //         <>
          //             <div key={'modifiedFrom'} className="flow-activity-title">
          //                 <Translate text={'modifiedFrom'} />{': '}
          //                 <span className="log-sub">
          //                     {!_.isEmpty(_newData['modifiedFrom']) ? _newData['modifiedFrom'] : 'empty'}
          //                 </span>
          //             </div>
          //         </>
          //     )
          // }

          return cells;
        } else if (objLog.origin === "company") {
          let i = 0; //#COMPANY
          for (let [key] of Object.entries(objCompany)) {
            if (
              excludedCompany.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              } else if (key === "emails" || key === "phones") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) ? "updated" : "added"}
                    </span>
                  </div>
                );
              } else if (key === "owner") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "clientID") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "type") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _settings && _settings.companyType,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _settings && _settings.companyType,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "country") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        countries,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        countries,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "state") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        states,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        states,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"company"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                        ? _oldData[key]
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                        ? _newData[key]
                        : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }

          return cells;
        } else if (objLog.origin === "tradeinPro") { //#TRADEINPRO
          for (let [key] of Object.entries(objTradeIP)) {
            let i = 199;
            if (
              excludedTradeinPro.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              } else if (key === "clientID") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "pipeline") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        tipPipelines,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        tipPipelines,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "stage") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        tipStages,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        tipStages,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "status") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        tradeinProAllStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        tradeinProAllStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "owner" || key === "ownerSales" || key === "finalizedBy") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "finalPrice" || key === "valuerPrice") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] ? (
                        <NumberFormat
                          value={_oldData[key]}
                          displayType={"text"}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      ) : (
                        <>{"empty"}</>
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? (
                        <NumberFormat
                          value={_newData[key]}
                          displayType={"text"}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      ) : (
                        <>{"empty"}</>
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "completedDate" ||
                key === "finalizedDate" ||
                key === "stageDate" ||
                key === "wonDate" ||
                key === "lostDate"
              ) {
                if (_.isEmpty(_newData[key]) || _newData[key]?.seconds > 0)
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      <Translate text={key} />
                      {": "}
                      <span className="log-sub">
                        {_oldData[key]
                          ? moment
                            .unix(_oldData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                        <span className="log-arrow">&rarr;</span>
                        {_newData[key]
                          ? moment
                            .unix(_newData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                      </span>
                    </div>
                  );
              } else if (key === "origin") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _tip_settings && _tip_settings.origins,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _tip_settings && _tip_settings.origins,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "lostReason") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _tip_settings && _tip_settings.lostReasons,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _tip_settings && _tip_settings.lostReasons,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "lostSubReason") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        lostSubReasons,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        lostSubReasons,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"tradeIn"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else if (key === "contactID") {
                if (
                  !_.isEmpty(_oldData[key]) &&
                  !_.isEmpty(_newData[key]) &&
                  _oldData[key] !== _newData[key]
                ) {
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      <Translate text={"contact"} />
                      {": "}
                      <span className="log-sub" data-id={_oldData[key]}>
                        {"Contact has been changed."}
                      </span>
                    </div>
                  );
                }
              } else if (key === "dynamicFields") {
                let newCells = bindDynamicChangeLog(
                  _oldData[key],
                  _newData[key],
                  _tip_settings
                );
                cells = _.union(cells, newCells);
              } else {
                cells.push(
                  <div
                    key={Math.round(Math.random() * 1000)}
                    className="flow-activity-title"
                  >
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                        ? _oldData[key]
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                        ? _newData[key]
                        : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }
          return cells;
        } else if (objLog.origin === "events") { //#EVENTSPRO
          for (let [key] of Object.entries(objEventPro)) {
            let i = 199;
            if (
              excludedEventsPro.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              }

              if (key === "clientID") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "clientIDs") {
                cells.push(
                  <div key={i} className="flow-activity-title">
                    <Translate text={"dealerships"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getMultipleDealersName(
                        dealersettings,
                        _oldData[key],
                        "empty",
                        "activityTags"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key])
                        ? CommonHelper.getMultipleDealersName(
                          dealersettings,
                          !_.isEmpty(_oldData[key])
                            ? [..._oldData[key], ..._newData[key]]
                            : _newData[key],
                          "",
                          "activityTags"
                        )
                        : "empty"}
                    </span>
                  </div>
                );
              } else if (key === "pipeline") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        eventPipelines,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        eventPipelines,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "stage") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        eventStages,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        eventStages,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "status") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        eventsProAllStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        eventsProAllStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "owner") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "startDate" ||
                key === "stageDate" ||
                key === "endDate" ||
                key === "closedDate" ||
                key === "publishedDate" ||
                key === "liveDate"
              ) {
                if (_.isEmpty(_newData[key]) || _newData[key]?.seconds > 0)
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      {key === "closedDate" ? (
                        <Translate
                          text={
                            CommonHelper.getNameByValue(
                              eventsProAllStatus,
                              "closed",
                              "Closed"
                            ) + " Date"
                          }
                        />
                      ) : key === "publishedDate" ? (
                        <Translate
                          text={
                            CommonHelper.getNameByValue(
                              eventsProAllStatus,
                              "published",
                              "Published"
                            ) + " Date"
                          }
                        />
                      ) : key === "liveDate" ? (
                        <Translate
                          text={
                            CommonHelper.getNameByValue(
                              eventsProAllStatus,
                              "live",
                              "Live"
                            ) + " Date"
                          }
                        />
                      ) : (
                        <Translate text={key} />
                      )}
                      {": "}
                      <span className="log-sub">
                        {_oldData[key]
                          ? moment
                            .unix(_oldData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                        <span className="log-arrow">&rarr;</span>
                        {_newData[key]
                          ? moment
                            .unix(_newData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                      </span>
                    </div>
                  );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"tradeIn"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else if (key === "dynamicFields") {
                let newCells = bindDynamicChangeLog(
                  _oldData[key],
                  _newData[key],
                  _tip_settings
                );
                cells = _.union(cells, newCells);
              } else {
                cells.push(
                  <div
                    key={Math.round(Math.random() * 1000)}
                    className="flow-activity-title"
                  >
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                        ? _oldData[key]
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                        ? _newData[key]
                        : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }
          return cells;
        } else if (objLog.origin === "service") { //#SERVICE
          for (let [key] of Object.entries(objService)) {
            let i = 299;
            if (
              excludedService.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              }

              if (key === "appointmentType") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _serviceSettings && _serviceSettings.appointmentTypes,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _serviceSettings && _serviceSettings.appointmentTypes,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "pipeline") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        servicePipelines,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        servicePipelines,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "stage") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        serviceStages,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        serviceStages,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "partStatus") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(
                        _serviceSettings && _serviceSettings.partStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(
                        _serviceSettings && _serviceSettings.partStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "clientID") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(
                        dealersettings,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "status" || key === "ownerResponse") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        serviceAllStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        serviceAllStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "technicianStatus") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        technicianAllStatus,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        technicianAllStatus,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "slot") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {bindSlot(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {bindSlot(_newData[key])}
                    </span>
                  </div>
                );
              } else if (key === "timeOfDay") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"timeOfDay"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(
                        CommonHelper.getSlotTimeValues(),
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(
                        CommonHelper.getSlotTimeValues(),
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "owner" ||
                key === "serviceAdvisor" ||
                key === "technician" ||
                key === "workshopManager" ||
                key === "partsInterpretor" ||
                key === "checkInBy"
              ) {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _oldData[key],
                        "empty"
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(
                        allUsers,
                        _newData[key],
                        "empty"
                      )}
                    </span>
                  </div>
                );
              } else if (key === "appointmentDate") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key]
                        ? moment(_oldData[key]).format("DD MMM YYYY h:mm A")
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key]
                        ? moment(_newData[key]).format("DD MMM YYYY h:mm A")
                        : "empty"}
                    </span>
                  </div>
                );
              } else if (
                key === "checkInDate" ||
                key === "stageDate" ||
                key === "completionDate" ||
                key === "clientContactDate" ||
                key === "quoteSentDate" ||
                key === "startDate" ||
                key === "completeDate" ||
                key === "responseDate" ||
                key === "nextService"
              ) {
                if (_.isEmpty(_newData[key]) || _newData[key]?.seconds > 0)
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      <Translate text={key} />
                      {": "}
                      <span className="log-sub">
                        {_oldData[key]
                          ? moment
                            .unix(_oldData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                        <span className="log-arrow">&rarr;</span>
                        {_newData[key]
                          ? moment
                            .unix(_newData[key].seconds)
                            .format("DD MMM YYYY h:mm A")
                          : "empty"}
                      </span>
                    </div>
                  );
              } else if (key === "totalServiceCost") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] ? (
                        <NumberFormat
                          value={_oldData[key]}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      ) : (
                        <>{"empty"}</>
                      )}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? (
                        <NumberFormat
                          value={_newData[key]}
                          decimalScale={2}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={currencySymbol}
                        />
                      ) : (
                        <>{"empty"}</>
                      )}
                    </span>
                  </div>
                );
              } else if (
                key === "jobAllocated" ||
                key === "jobConfirmed" ||
                key === "partsConfirmed" ||
                key === "workshopConfirmed"
              ) {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] === true ? "Yes" : "No"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] === true ? "Yes" : "No"}
                    </span>
                  </div>
                );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"service"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else if (key === "vehicle") {
                let newCells = bindVehicleChangeLog(
                  _oldData[key],
                  _newData[key]
                );
                cells = _.union(cells, newCells);
              } else if (key === "dynamicFields") {
                let newCells = bindDynamicChangeLog(
                  _oldData[key],
                  _newData[key],
                  _serviceSettings
                );
                cells = _.union(cells, newCells);
              } else {
                cells.push(
                  <div
                    key={Math.round(Math.random() * 1000)}
                    className="flow-activity-title"
                  >
                    <Translate text={key} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                        ? _oldData[key]
                        : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                        ? _newData[key]
                        : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }

          return cells;
        } else { //#ENQUIRY
          for (let [key] of Object.entries(objEnquiry)) {
            let i = 99;
            if (
              excludedEnquiry.indexOf(key) < 0 &&
              (_oldData[key] ? _oldData[key] : "empty") !==
              (_newData[key] ? _newData[key] : "empty") &&
              ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) ||
                (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))
            ) {
              if (!showLog) {
                setShowLog(true);
              }

              if (key === 'origin') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.origins, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.origins, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              } else if (key === 'enquiryType') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.enquiryTypes, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.enquiryTypes, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              }
              else if (key === 'financeProvider') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.financeProviders, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.financeProviders, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              }
              else if (key === 'financeTerms') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(defaultFinanceTerms, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(defaultFinanceTerms, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              }
              else if (key === 'serviceType') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.serviceTypes, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.serviceTypes, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              }
              else if (key === 'leadSource') {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.leadSource, _oldData[key], 'empty')}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.leadSource, _newData[key], 'empty')}
                    </span>
                  </div>
                )
              } else if (key === "campaign") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.campaigns, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.campaigns, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "label") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.bindEnquiryLabel(_settings && _settings.enquiryLabels, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.bindEnquiryLabel(_settings && _settings.enquiryLabels, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "clientID") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"dealership"} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getOtherDealerName(dealersettings, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getOtherDealerName(dealersettings, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "pipeline") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(pipelines, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(pipelines, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "stage") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(stages, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(stages, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "status") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(enquiryAllStatus, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(enquiryAllStatus, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "financeStatus") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(financeStatuses ? [...financeStatuses, ...__financestatus] : __financestatus, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(financeStatuses ? [...financeStatuses, ...__financestatus] : __financestatus, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "afterMarketStatus") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(afterMarketStatuses, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(afterMarketStatuses, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (
                key === "owner" ||
                key === "confirmedBy" ||
                key === "verifiedBy" ||
                key === "financeBM" ||
                key === "afterMarketBM" ||
                key === "ownerInbound" ||
                key === "convertedBy" ||
                key === "convertedByGroup" ||
                key === "convertedByOEM" ||
                key === "convertedByRegion" ||
                key === "oemConvertedBy"
              ) {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getUserNamebyId(allUsers, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getUserNamebyId(allUsers, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "purchaseIntention") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] ? moment(_oldData[key]).format("MMM YYYY") : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? moment(_newData[key]).format("MMM YYYY") : "empty"}
                    </span>
                  </div>
                );
              } else if (
                key === "warrantyStartDate" ||
                key === "warrantyExpiry"
              ) {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] ? moment(_oldData[key]).format("DD MMM YYYY") : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? moment(_newData[key]).format("DD MMM YYYY") : "empty"}
                    </span>
                  </div>
                );
              } else if (
                key === "financeStartDate" ||
                key === "deliveryDate" ||
                key === "stageDate" ||
                key === "wonDate" ||
                key === "lostDate" ||
                key === "deliveredDate" ||
                key === "confirmedDate" ||
                key === "verifiedDate" ||
                key === "receivedDate" ||
                key === "convertedDate" ||
                key === "convertedDateGroup" ||
                key === "convertedDateOEM" ||
                key === "convertedDateRegion" ||
                key === "oemConvertedDate"
              ) {
                if (_.isEmpty(_newData[key]) || _newData[key]?.seconds > 0)
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      {key === "wonDate" ? (
                        <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, "won", "Won") + " Date"} />
                      ) : key === "deliveredDate" ? (
                        <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, "delivered", "Delivered") + " Date"} />
                      ) : key === "lostDate" ? (
                        <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, "lost", "Lost") + " Date"} />
                      ) : (
                        <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                      )}
                      {": "}
                      <span className="log-sub">
                        {_oldData[key] ? moment.unix(_oldData[key].seconds).format("DD MMM YYYY h:mm A") : "empty"}
                        <span className="log-arrow">&rarr;</span>
                        {_newData[key] ? moment.unix(_newData[key].seconds).format("DD MMM YYYY h:mm A") : "empty"}
                      </span>
                    </div>
                  );
              } else if (key === "lostReason") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getNameByValue(_settings && _settings.lostReasons, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getNameByValue(_settings && _settings.lostReasons, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "lostSubReason") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLabelByValue(lostSubReasons, _oldData[key], "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLabelByValue(lostSubReasons, _newData[key], "empty")}
                    </span>
                  </div>
                );
              } else if (key === "transferHistory") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {CommonHelper.getLatestTransferHistoryHTML(_newData[key], dealersettings, false, "InboundLead", "empty")}
                      <span className="log-arrow">&rarr;</span>
                      {CommonHelper.getLatestTransferHistoryHTML(_newData[key], dealersettings, false, null, "empty")}
                    </span>
                  </div>
                );
              }
              else if (key === "financeAmount" || key === 'depositAmount' || key === 'grossAmount') {

                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {_oldData[key] ? <NumberFormat value={_oldData[key]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : <>{'empty'}</>}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? <NumberFormat value={_newData[key]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : <>{'empty'}</>}
                    </span>
                  </div>
                )
              }
              else if (key === "financeRate") {

                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />{': '}
                    <span className="log-sub">
                      {_oldData[key] ? <NumberFormat value={_oldData[key]} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} /> : <>{'empty'}</>}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] ? <NumberFormat value={_newData[key]} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={'%'} /> : <>{'empty'}</>}
                    </span>
                  </div>
                )
              } else if (
                key === "isFinanceRequired" ||
                key === "isFinanceRequestSent"
              ) {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {_oldData[key] === true ? "Yes" : "No"}
                      <span className="log-arrow">&rarr;</span>
                      {_newData[key] === true ? "Yes" : "No"}
                    </span>
                  </div>
                );
              } else if (key === "isDeleted") {
                cells.push(
                  <div key={`${i}${key}`} className="flow-activity-title">
                    <Translate text={"Enquiry"} />
                    {": "}
                    <span className="log-sub">
                      {deletedStatus(_oldData[key])}
                      <span className="log-arrow">&rarr;</span>
                      {deletedStatus(_newData[key])}
                    </span>
                  </div>
                );
              } else if (key === "contactID") {
                if (
                  !_.isEmpty(_oldData[key]) &&
                  !_.isEmpty(_newData[key]) &&
                  _oldData[key] !== _newData[key]
                ) {
                  cells.push(
                    <div key={`${i}${key}`} className="flow-activity-title">
                      <Translate text={"contact"} />
                      {": "}
                      <span className="log-sub" data-id={_oldData[key]}>
                        {"Contact has been changed."}
                      </span>
                    </div>
                  );
                }
              } else if (key === "dynamicFields") {
                let newCells = bindDynamicChangeLog(_oldData[key], _newData[key], _settings);
                cells = _.union(cells, newCells);
              } else {
                cells.push(
                  <div
                    key={Math.round(Math.random() * 1000)}
                    className="flow-activity-title"
                  >
                    <Translate text={CommonHelper.getStaticFieldName(_settings?.enquiryOptionsStatic,key)} />
                    {": "}
                    <span className="log-sub">
                      {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key]) ? _oldData[key] : "empty"}
                      <span className="log-arrow">&rarr;</span>
                      {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key]) ? _newData[key] : "empty"}
                    </span>
                  </div>
                );
              }
              i++;
            }
          }
          return cells;
        }
      }
    } else {
      return <>{""}</>;
    }
  };

  const bindVehicleChangeLog = (_oldData, _newData) => {
    const cells = [];
    _oldData = Object.assign({}, _oldData);
    _newData = Object.assign({}, _newData);
    if (!_.isEmpty(_newData)) {
      let i = 0;
      for (let [key] of Object.entries(_newData)) {
        if (_oldData[key] || _newData[key]) {
          if (key === "warrantyStartDate" || key === "warrantyExp") {
            cells.push(
              <div key={i} className="flow-activity-title">
                <Translate text={key} />
                {": "}
                <span className="log-sub">
                  {_oldData[key]
                    ? moment.unix(_oldData[key].seconds).format("DD MMM YYYY")
                    : "empty"}
                  <span className="log-arrow">&rarr;</span>
                  {_newData[key]
                    ? moment.unix(_newData[key].seconds).format("DD MMM YYYY")
                    : "empty"}
                </span>
              </div>
            );
          } else {
            cells.push(
              <div key={i} className="flow-activity-title">
                <Translate text={key} />
                {": "}
                <span className="log-sub">
                  {_oldData[key] ? _oldData[key] : "empty"}
                  <span className="log-arrow">&rarr;</span>
                  {_newData[key] ? _newData[key] : "empty"}
                </span>
              </div>
            );
          }
        }
        i++;
      }
      return cells;
    } else {
      return cells;
    }
  };

  const bindDynamicChangeLog = (_oldData, _newData, _settings) => {
    const cells = [];
    _oldData = Object.assign({}, _oldData);
    _newData = Object.assign({}, _newData);
    if (!_.isEmpty(_oldData) || !_.isEmpty(_newData)) {
      _settings &&
        _settings.enquiryOptionsDF &&
        _settings.enquiryOptionsDF.forEach((data, i) => {
          let key = data.value;
          if (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)) {
            //if ((excludedEnquiry.indexOf(key) < 0) && ((_oldData[key] ? _oldData[key] : 'empty') !== (_newData[key] ? _newData[key] : 'empty')) && ((!_.isEmpty(_oldData) && _oldData.hasOwnProperty(key)) || (!_.isEmpty(_newData) && _newData.hasOwnProperty(key)))) {

            if (data.type === "text" || data.type === "select") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {_oldData[key] ? _oldData[key] : "empty"}
                    <span className="log-arrow">&rarr;</span>
                    {_newData[key] ? _newData[key] : "empty"}
                  </span>
                </div>
              );
            } else if (data.type === "number") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {_.isNumber(_oldData[key]) ? _oldData[key] : "empty"}
                    <span className="log-arrow">&rarr;</span>
                    {_.isNumber(_newData[key]) ? _newData[key] : "empty"}
                  </span>
                </div>
              );
            } else if (data.type === "multiselect") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {!_.isEmpty(_oldData[key])
                      ? _oldData[key].join(", ")
                      : "empty"}
                    <span className="log-arrow">&rarr;</span>
                    {!_.isEmpty(_newData[key])
                      ? (!_.isEmpty(_oldData[key])
                        ? _oldData[key].join(", ") + ", "
                        : "") + _newData[key].join(", ")
                      : "empty"}
                  </span>
                </div>
              );
            } else if (data.type === "date") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {_oldData[key]
                      ? moment.unix(_oldData[key].seconds).format("DD MMM YYYY")
                      : "empty"}
                    <span className="log-arrow">&rarr;</span>
                    {_newData[key]
                      ? moment.unix(_newData[key].seconds).format("DD MMM YYYY")
                      : "empty"}
                  </span>
                </div>
              );
            } else if (data.type === "price") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {_oldData[key] ? (
                      <NumberFormat
                        value={_oldData[key]}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                      />
                    ) : (
                      <>{"empty"}</>
                    )}
                    <span className="log-arrow">&rarr;</span>
                    {_newData[key] ? (
                      <NumberFormat
                        value={_newData[key]}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                      />
                    ) : (
                      <>{"empty"}</>
                    )}
                  </span>
                </div>
              );
            } else if (data.type === "switch" || data.type === "toggle") {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {_oldData[key] === true ? "Yes" : "No"}
                    <span className="log-arrow">&rarr;</span>
                    {_newData[key] === true ? "Yes" : "No"}
                  </span>
                </div>
              );
            } else {
              cells.push(
                <div key={i} className="flow-activity-title">
                  {data.name}
                  {": "}
                  <span className="log-sub">
                    {!_.isEmpty(_oldData[key]) && !_.isObject(_oldData[key])
                      ? _oldData[key]
                      : "empty"}
                    <span className="log-arrow">&rarr;</span>
                    {!_.isEmpty(_newData[key]) && !_.isObject(_newData[key])
                      ? _newData[key]
                      : "empty"}
                  </span>
                </div>
              );
            }
          }
        });
      return cells;
    } else {
      return cells;
    }
  };

  const deletedStatus = (_data) => {
    if (_data)
      return (
        <div className={`badge badge-pill badge-status-lost`}>
          <Translate text={"deleted"} upperCase={true} />
        </div>
      );
    else
      return (
        <div className={`badge badge-pill badge-status-won`}>
          <Translate text={"active"} upperCase={true} />
        </div>
      );
  };

  const bindRequest = (_request) => {
    if (!_.isEmpty(_request)) return JSON.stringify(_request, undefined, 2);
    else return _request;
  };

  const _permissions =
    activitylog.clientID === dealersettings.client.id &&
      !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.rolePermissions) &&
      !_.isEmpty(dealersettings.rolePermissions.permissions)
      ? dealersettings.rolePermissions.permissions
      : null;

  const deleteActivity =
    !_.isEmpty(_permissions) && _permissions.deleteActivity && activitylog.originType !== 'mailjet' ? true : false;

  const modifyOtherActivity =
    !_.isEmpty(_permissions) && _permissions.modifyOtherActivity ? true : false;

  const modifyNotes =
    !_.isEmpty(_permissions) && _permissions.modifyNotes ? true : false;

  const unlinkEnquiry =
    !_.isEmpty(_permissions) && _permissions.unlinkEnquiry ? true : false;

  const editActivity =
    activitylog.clientID !== dealersettings.client.id
      ? false
      : type !== "message" &&
        (activitylog.type ===
          CommonHelper.getActivityType("activity", originType) ||
          type === "note") &&
        ((!_.isEmpty(activitylog.searchUsers) &&
          activitylog.searchUsers.indexOf(dealersettings.id) >= 0 &&
          activitylog.type ===
          CommonHelper.getActivityType("activity", originType)) ||
          (modifyOtherActivity &&
            activitylog.type ===
            CommonHelper.getActivityType("activity", originType)) ||
          (modifyNotes && type === "note"))
        ? true
        : false;

  let dealerName = "";
  if (
    !_.isEmpty(dealersettings) &&
    (activitylog.clientID !== dealersettings.client.id ||
      localStorage.defaultModule === "oem")
  ) {
    dealerName = CommonHelper.getOtherDealerName(
      dealersettings,
      activitylog.clientID
    );
  }

  const emailContent =
    !_.isEmpty(activitylog.email) &&
      !_.isEmpty(activitylog.email.body) &&
      activitylog.triggerType === "email"
      ? htmlToText.fromString(activitylog.email.body.replace(/<img[^>]*>/g, ""))
      : "";

  const _changeLogHTML = activitylog?.type === CommonHelper.getActivityType("log", originType) && activitylog?.subType === "log" ? bindChangeLog(activitylog) : null;
  return !_.isEmpty(dealersettings) ? (
    <>
      <div
        id={`enquiry-item-${id}`}
        className={`enquiry-flow-item ${(activitylog.type ===
          CommonHelper.getActivityType("log", originType) &&
          activitylog.subType === "note" &&
          !isEditable) ||
          (completedTab.type === "allnotes" && completed)
          ? "note"
          : ""
          } ${activitylog.subType === "log" && showLog ? "flow-style-none" : ""
          } ${activitylog.subType === "log" && !showLog ? "hide" : ""}`}
      >
        <div
          className={`flow-item  ${(activitylog.type ===
            CommonHelper.getActivityType("log", originType) &&
            activitylog.subType === "note" &&
            !isEditable) ||
            (completedTab.type === "allnotes" && completed)
            ? "note"
            : ""
            } ${activitylog.subType === "log" && showLog ? "flow-style-none" : ""
            } ${activitylog.subType === "log" && !showLog ? "hide" : ""}`}
        >
          {completedTab.type === "allnotes" && completed ? (
            <div className="flow-item-content">
              <div className="flow-text-wrap">
                <div className="flow-text text-area-space note">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CommonHelper.stringtoURL(activitylog.notes ? _.trim(activitylog.notes) : ''),
                    }}
                  ></div>
                </div>
                <div className="flow-activity-date">
                  <div className="flow-notes-sub">

                    {
                      activitylog.type !== CommonHelper.getActivityType("activity", originType) && (<>
                        <><span>{moment.unix(activitylog.addedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>{addedBy ? ` - ${addedBy}` : ""}</>

                        {activitylog.addedFrom ? (
                          <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${activitylog.addedFrom === "web" ? "monitor" : activitylog.addedFrom} mr-1`}  ></i>  {activitylog.addedFrom}{" "}</>
                        ) : (<></>)}

                        {activitylog.modifiedDate && activitylog.addedDate && activitylog.addedDate.seconds !== activitylog.modifiedDate.seconds && (<div >
                          <Translate text={"Last Edited"} />:&nbsp;
                          <span>{moment.unix(activitylog.modifiedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>
                          {modifiedBy ? ` - ${modifiedBy}` : ""}
                          {/* {activitylog.modifiedFrom ? (
                            <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${activitylog.modifiedFrom === "web" ? "monitor" : activitylog.modifiedFrom} mr-1`}  ></i>  {activitylog.modifiedFrom}{" "}</>
                          ) : (<></>)} */}
                        </div>)}

                        {activitylog.offerID &&
                          activitylog.originType &&
                          !_.isEmpty(tipOffers) &&
                          _.find(tipOffers, { documentID: activitylog.offerID })?.wholeSellerId ? (
                          <div className="badge badge-input-white ml-1">
                            Offer:&nbsp;
                            {CommonHelper.getUserNamebyId(dealersettings.wholesalers, _.find(tipOffers, { documentID: activitylog.offerID })?.wholeSellerId, "")}
                          </div>
                        ) : (
                          <></>
                        )}
                        {activitylog.originType === "serviceNotes" ? (
                          <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                            <Translate text={"serviceNotes"} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {activitylog.wholeSalerNotes ? (
                          <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                            <Translate text={"wholeSaler"} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {activitylog.isInternalNotes ? (
                          <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                            <Translate text={"Internal"} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>)
                    }

                    <div className="badge badge-pill badge-input-white text-uppercase">
                      <Translate text={activitylog.type === CommonHelper.getActivityType("activity", originType) ? "activity" : activitylog.subType} toUpperCase={true} />
                    </div>
                  </div>
                </div>
                {((!_.isEmpty(activitylog.tags) && activitylog.tags.length > 0) || activitylog?.aircall?.recordingVisibility || activitylog?.recordingVisibility) ? (
                  <div className="flow-notes-sub">

                    {!_.isEmpty(activitylog.tags) && activitylog.tags.map((rec, index) => {
                      return (
                        <div
                          key={index}
                          className="badge badge-pill badge-input-white mr-1 text-capitalize"
                        >
                          {CommonHelper.getNameByValue(
                            dealersettings.integrations,
                            _.isObject(rec) ? rec.type : rec,
                            _.isObject(rec) ? rec.type : rec
                          )}
                        </div>
                      );
                    })}

                    {activitylog?.aircall?.recordingVisibility ? (
                      <div
                        className={`badge badge-pill badge-${activitylog?.aircall?.recordingVisibility.toLowerCase()}-audio text-uppercase`}
                      >
                        {activitylog?.aircall?.recordingVisibility}
                      </div>
                    ) : (
                      <></>
                    )}
                    {activitylog?.recordingVisibility ? (
                      <div
                        className={`badge badge-pill badge-${activitylog?.recordingVisibility.toLowerCase()}-audio text-uppercase`}
                      >
                        {activitylog?.recordingVisibility}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              {isEditable ? (<div className="flow-item-content">
                <div className="flow-text-wrap">
                  <div className="editmode-item form-style">
                    <CustomerEditLog
                      handlechangelog={handlechangelog}
                      handlesavelog={handlesavelog}
                      handlecancellog={handlecancellog}
                      customerlog={activitylog}
                      textareaRef={textareaRef}
                      handleDateChangelog={handleDateChangelog}
                      loading={logLoader}
                      completedTab={completedTab}
                      dealersettings={dealersettings}
                      contact={contact}
                      enquiry={enquiry}
                      tradeinPro={tradeinPro}
                      eventsPro={eventsPro}
                      service={service}
                      originType={originType}
                      eventsProAttendee={eventsProAttendee}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              ) : !_.isEmpty(activitylog.thread) ? (
                <div className="flow-item-content">
                  <div className="flow-text-wrap">
                    <div className={`flow-text`}>
                      <div className="flow-activity-title">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePreviewEmail(
                              activitylog.thread,
                              id,
                              activitylog.token
                            );
                          }}
                        >
                          {activitylog.thread
                            ? activitylog.thread.subject
                            : "No Subject"}
                        </a>
                      </div>
                      {/* <div className="flow-activity-date"><span>{activitylog.startDate ? moment.unix(activitylog.startDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</span>
                                                       {
                                                            !_.isEmpty(activitylog.thread) && !_.isEmpty(activitylog.thread.participants) && activitylog.thread.participants.length > 0 ? (
                                                                <span className="flow-email-id">• &nbsp;From: {activitylog.thread.participants[0].name} </span>
                                                            ) : (<></>)
                                                        }
                                                    </div> */}
                      <div className="flow-email-content">
                        {activitylog.thread ? activitylog.thread.snippet : ""}
                      </div>
                      <div className="flow-activity-date">
                        <span>
                          {moment
                            .unix(activityDate.seconds)
                            .format("DD/MM/YYYY h:mm A")}
                        </span>
                        {addedBy ? ` - ${addedBy}` : ""}
                      </div>
                    </div>
                    {unlinkEnquiry && !_.isEmpty(activitylog.thread) ? (
                      <Dropdown className="enquiry-flowitem-more" alignRight>
                        <Dropdown.Toggle as={CustomToggle} className="dropdown">
                          <i className="ico icon-more"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          as={CustomMenu}
                          className="dropdown-menu dropdown-menu-right"
                          ChildClass="more-dropdown"
                          xplacement="bottom-end"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={(e) => {
                              handleUnlickEmail(e, activitylog, completedTab);
                            }}
                          >
                            {" "}
                            <Translate text={"unlink"} />{" "}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : activitylog.type === CommonHelper.getActivityType("log", originType) && activitylog.subType === "log" ? (
                <>
                  {!_.isEmpty(_changeLogHTML) && (
                    <div className="flow-item-content">
                      <div className="flow-text-wrap">
                        {_changeLogHTML}
                        <div className="flow-activity-date">
                          <span>{moment.unix(activityDate.seconds).format("DD/MM/YYYY h:mm A")}</span>
                          {addedBy ? ` - ${addedBy}` : ""}
                          {activitylog.modifiedFrom ? (<>{` - `}
                            <i className={`ico icon-${activitylog.modifiedFrom === "web" ? "monitor" : activitylog.modifiedFrom} mr-1`}></i>
                            {activitylog.modifiedFrom}{" "}
                          </>
                          ) : (<></>)}
                        </div>
                        {!_.isEmpty(dealerName) ? (<div className="dealer-group-title text-capitalize">    {dealerName}  </div>) : (<></>)}
                        {!_.isEmpty(activitylog.origin) ? (
                          <div className="badge badge-pill badge-input-white mr-1 text-capitalize">  {activitylog.origin}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>)
                  }
                </>
              ) : activitylog.type ===
                CommonHelper.getActivityType("log", originType) &&
                activitylog.subType === "salesforce" ? (
                <div className="flow-item-content">
                  <div className="flow-text-wrap">
                    <strong>{"Request: "}</strong>
                    <pre
                      style={{
                        maxHeight: "250px",
                        overflow: "auto",
                      }}
                    >
                      {bindRequest(activitylog.request)}
                    </pre>
                    {!_.isEmpty(activitylog.response) ? (
                      <div className="activity-outcome">
                        <strong>{"Response: "}</strong>
                        {activitylog.response}
                      </div>
                    ) : (
                      <></>
                    )}
                    {!_.isEmpty(activitylog.error) ? (
                      <div className="activity-outcome">
                        <strong>{"Error: "}</strong>
                        {activitylog.error}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="flow-activity-date">
                      <span>
                        {moment
                          .unix(activityDate.seconds)
                          .format("DD/MM/YYYY h:mm A")}
                      </span>
                      {addedBy ? ` - ${addedBy}` : ""}
                      {activitylog.success ? (
                        <div className="status-won ml-2 text-capitalize">
                          {"success"}
                        </div>
                      ) : (
                        <div className="status-lost ml-2 text-capitalize">
                          {"failed"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flow-item-content">
                  <div className="flow-text-wrap">
                    {activitylog.type ===
                      CommonHelper.getActivityType("activity", originType) ? (
                      <div className="flow-checkbox">
                        <div className="checkbox icheck-success">
                          {(activitylog.clientID === dealersettings.client.id &&
                            !_.isEmpty(activitylog.searchUsers) &&
                            activitylog.searchUsers.indexOf(dealersettings.id) >=
                            0) ||
                            (activitylog.clientID === dealersettings.client.id &&
                              !_.isEmpty(dealersettings.rolePermissions) &&
                              !_.isEmpty(
                                dealersettings.rolePermissions.permissions
                              ) &&
                              dealersettings.rolePermissions.permissions
                                .modifyOtherActivity) ? (
                            <input
                              type="checkbox"
                              id={`success-${id}`}
                              onChange={(e) => {
                                //setTaskdone(e.target.checked);
                                handleComplete(e, activitylog);
                              }}
                              checked={taskDone}
                              title={
                                scheduled ? "mark as done" : "mark as not done"
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              id={`success-${id}`}
                              checked={taskDone}
                              disabled={true}
                            />
                          )}
                          <label htmlFor={`success-${id}`}></label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={`flow-text text-area-space ${activitylog.type ===
                        CommonHelper.getActivityType("log", originType) &&
                        activitylog.subType === "note"
                        ? "note"
                        : activitylog.type ===
                          CommonHelper.getActivityType("activity", originType)
                          ? "flow-margin"
                          : ""
                        }`}
                    >
                      {!_.isEmpty(activitylog.email) &&
                        activitylog.triggerType === "email" ? (
                        <div className="flow-activity-title">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleEDMsPreviewEmail(e, activitylog);
                            }}
                          >
                            {title}
                          </a>
                        </div>
                      ) : activitylog.subType === "log" ? (
                        <>{bindChangeLog(activitylog)}</>
                      ) : activitylog.type ===
                        CommonHelper.getActivityType("log", originType) ? (
                        activitylog.subType === "call" &&
                          activitylog.notes &&
                          (activitylog?.tags?.includes("aircall") ||
                            activitylog?.tags?.includes("justcall") ||
                            activitylog?.tags?.includes("ringcentral")) ? (
                          <div className="flow-activity-notes-inner ml-0 mr-2">
                            <div className="flow-text text-area-space note">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CommonHelper.stringtoURL(
                                    activitylog.notes
                                  ),
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flow-text text-area-space note">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CommonHelper.stringtoURL(
                                    activitylog.notes
                                  ),
                                }}
                              ></div>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          <div className="flow-activity-title">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditactivity(e, activitylog, completedTab);
                              }}
                            >
                              {title}
                            </a>
                          </div>
                          {activitylog.contact && companyView === true ? (
                            <div className="calendar-activity-date font-weight-bold">
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(
                                    "/contacts/details/" +
                                    activitylog.contact.documentID +
                                    "?back-url=" +
                                    window.location.pathname
                                  );
                                }}
                              >
                                <i className="ico icon-Contacts"></i>
                                {CommonHelper.displayContactName([], activitylog.contact)}
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {!_.isEmpty(activitylog.email) &&
                        activitylog.triggerType === "email" ? (
                        <>
                          {emailContent.length > 200
                            ? emailContent.substring(0, 200) + "..."
                            : emailContent}
                        </>
                      ) : (
                        <></>
                      )}
                      {scheduled ? (
                        <>
                          <div className="flow-activity-date">
                            <span
                              className={`${activityDate < CommonHelper.getBaseDate() &&
                                !completed
                                ? "activity-due"
                                : activityDate > CommonHelper.getBaseDate() &&
                                  activityDate < CommonHelper.getEndDate() &&
                                  !completed
                                  ? "activity-upcoming"
                                  : ""
                                }`}
                            >
                              {activityDate < CommonHelper.getBaseDate()
                                ? moment().diff(
                                  moment.unix(activityDate.seconds),
                                  "days"
                                ) === 1
                                  ? "1 day ago"
                                  : moment().to(moment.unix(activityDate.seconds))
                                : moment
                                  .unix(activityDate.seconds)
                                  .format("DD/MM/YYYY h:mm A")}{" "}
                            </span>
                            {addedBy ? ` - ${addedBy}` : ""}
                          </div>
                        </>
                      ) : activitylog.type ===
                        CommonHelper.getActivityType("log", originType) ? (
                        activitylog.subType === "note" ? (
                          <div className="flow-notes-sub">
                            <><span>{moment.unix(activitylog.addedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>{addedBy ? ` - ${addedBy}` : ""}  </>
                            {activitylog.addedFrom ? (
                              <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${activitylog.addedFrom === "web" ? "monitor" : activitylog.addedFrom} mr-1`}  ></i>  {activitylog.addedFrom}{" "}</>
                            ) : (<></>)}

                            {activitylog.modifiedDate && activitylog.addedDate && activitylog.addedDate.seconds !== activitylog.modifiedDate.seconds && (<div >
                              <Translate text={"Last Edited"} />:&nbsp;
                              <span>{moment.unix(activitylog.modifiedDate.seconds).format("DD/MM/YYYY h:mm A")}</span>
                              {modifiedBy ? ` - ${modifiedBy}` : ""}
                              {/* {activitylog.modifiedFrom ? (
                                <>  &nbsp;{` - `}&nbsp;  <i className={`ico icon-${activitylog.modifiedFrom === "web" ? "monitor" : activitylog.modifiedFrom} mr-1`}  ></i>  {activitylog.modifiedFrom}{" "}</>
                              ) : (<></>)} */}
                            </div>)}

                            {activitylog.offerID &&
                              activitylog.originType &&
                              !_.isEmpty(tipOffers) &&
                              _.find(tipOffers, { documentID: activitylog.offerID })?.wholeSellerId ? (
                              <div className="badge badge-input-white ml-1">
                                Offer:&nbsp;
                                {CommonHelper.getUserNamebyId(dealersettings.wholesalers, _.find(tipOffers, { documentID: activitylog.offerID })?.wholeSellerId, "")}
                              </div>
                            ) : (
                              <></>
                            )}
                            {activitylog.originType === "serviceNotes" ? (
                              <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                                <Translate text={"serviceNotes"} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {activitylog.wholeSalerNotes ? (
                              <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                                <Translate text={"wholeSaler"} />
                              </div>
                            ) : (
                              <></>
                            )}
                            {activitylog.isInternalNotes ? (
                              <div className="badge badge-pill badge-input-white ml-1 text-uppercase">
                                <Translate text={"Internal"} />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : activitylog.callDuration > 0 ||
                          activitylog.callStatus ||
                          activitylog.callType ? (
                          <>
                            <div className="flow-activity-date">
                              {!_.isEmpty(activitylog.callStatus) ? (
                                <>
                                  <Translate text={"status"} />:{" "}
                                  {CommonHelper.getLabelByValue(
                                    arrcallstatus,
                                    activitylog.callStatus,
                                    "--"
                                  )}
                                </>
                              ) : (
                                <></>
                              )}

                              <span
                                className={`call-time ${_.isEmpty(activitylog.callStatus) ? "ml-0" : ""
                                  }`}
                              >
                                <i className="ico icon-time"></i>
                                {CommonHelper.convertsecstostr(
                                  activitylog.callDuration
                                )}
                              </span>
                            </div>
                            {!_.isEmpty(activitylog.callType) ? (
                              <div className="flow-activity-date">
                                <Translate text={"type"} />:{" "}
                                {CommonHelper.getNameByValue(
                                  callTypes,
                                  activitylog.callType,
                                  "--"
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {!_.isEmpty(activitylog?.aircall?.recording) ? (
                              <>
                                <div className="text-area-none w-100 mt-2 mb-2 position-relative">
                                  {activitylog?.aircall?.recordingVisibility ===
                                    "private" &&
                                    !aircallSettings?.emails?.some(
                                      (a) => a === dealersettings.email
                                    ) ? (
                                    <>
                                      <div className="audio-overlay-lock">
                                        <div className="audio-lock-insideico">
                                          <div className="badge badge-pill badge-private-call">
                                            <i className="ico icon-activity-lock"></i>{" "}
                                            Private{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <AudioPlayer
                                        id={`aircall-${type}-${id}`}
                                        recording={
                                          "https://firebasestorage.googleapis.com/v0/b/ams-pro-qa.appspot.com/o/audio%2FBlank.mp3?alt=media&token=b703df18-780c-4ab7-a0a3-432de23c1613"
                                        }
                                      ></AudioPlayer>
                                    </>
                                  ) : (
                                    <AudioPlayer
                                      id={`aircall-${type}-${id}`}
                                      recording={activitylog.aircall.recording}
                                    ></AudioPlayer>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {!_.isEmpty(activitylog?.recording) ? (
                              <>
                                <div className="text-area-none w-100 mt-2 mb-2 position-relative">
                                  {activitylog?.recordingVisibility ===
                                    "private" &&
                                    (!ringcentralSettings?.emails?.some(
                                      (a) => a === dealersettings.email
                                    ) && !justcallSettings?.emails?.some(
                                      (a) => a === dealersettings.email
                                    )) ? (
                                    <>
                                      <div className="audio-overlay-lock">
                                        <div className="audio-lock-insideico">
                                          <div className="badge badge-pill badge-private-call">
                                            <i className="ico icon-activity-lock"></i>{" "}
                                            Private{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <AudioPlayer
                                        id={`ringcentral-${type}-${id}`}
                                        recording={
                                          "https://firebasestorage.googleapis.com/v0/b/ams-pro-qa.appspot.com/o/audio%2FBlank.mp3?alt=media&token=b703df18-780c-4ab7-a0a3-432de23c1613"
                                        }
                                      ></AudioPlayer>
                                    </>
                                  ) : (<>
                                    <AudioPlayer
                                      id={`ringcentral-${type}-${id}`}
                                      recording={activitylog.recording}
                                    ></AudioPlayer>
                                    {
                                      !_.isEmpty(activitylog?.justcall) && !_.isEmpty(activitylog?.recording) && (
                                        <div className="call-transcript-link"> <a href="#" onClick={(e) => {
                                          e.preventDefault(); setShowTranscriptModal({
                                            ...showTranscriptModal,
                                            show: true,
                                            activitylog: activitylog,
                                            justcallSettings: justcallSettings,
                                            contact: props?.enquiry?.contact || props?.contact || null
                                          })
                                        }}><i className="ico icon-chat"></i> Transcript</a></div>)
                                    }
                                    {
                                      !_.isEmpty(activitylog?.transcript) && (
                                        <div className="call-transcript-link"> <a href="#" onClick={(e) => {
                                          e.preventDefault(); setShowTranscriptModal({
                                            ...showTranscriptModal,
                                            show: true,
                                            activitylog: activitylog,
                                            transcript: activitylog?.transcript,
                                            contact: props?.enquiry?.contact || props?.contact || null
                                          })
                                        }}><i className="ico icon-chat"></i> Transcript</a></div>)
                                    }
                                  </>)}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="flow-activity-date">
                              {activitylog.addedDate
                                ? moment
                                  .unix(activitylog.addedDate.seconds)
                                  .format("DD/MM/YYYY h:mm A")
                                : ""}
                              {addedBy ? ` - ${addedBy}` : ""}
                            </div>
                          </>
                        ) : (
                          <>
                            {activitylog?.files?.length > 0 ? (
                              <div className="flow-mms-wrapper">
                                {activitylog.files.map((rec, index) => {
                                  return (
                                    <div className="flow-mms-item-image">
                                      {rec?.type?.includes("image") ? (
                                        <a
                                          data-fancybox={`file-${index}`}
                                          href={rec.url}
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <img
                                            id={`file-${index}`}
                                            src={rec.url}
                                            alt={rec.name}
                                            className=""
                                            width="40"
                                            height="40"
                                          />
                                        </a>
                                      ) : (
                                        <a
                                          href={rec.url}
                                          target="_blank"
                                          className="hyperlink-blue"
                                        >
                                          {" "}
                                          {rec.name}{" "}
                                        </a>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="flow-activity-date">
                              {activitylog.addedDate
                                ? moment
                                  .unix(activitylog.addedDate.seconds)
                                  .format("DD/MM/YYYY h:mm A")
                                : ""}
                              {addedBy ? ` - ${addedBy}` : ""}
                              {activitylog.inbound ? (
                                <span className="sms-reply">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      handleReplylog(e, activitylog);
                                    }}
                                  >
                                    <i className="ico icon-mail-reply"></i>
                                    <Translate text={"Reply"} />
                                  </a>
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        )
                      ) : (
                        <div className="flow-activity-date">
                          <span>
                            {moment
                              .unix(activityDate.seconds)
                              .format("DD/MM/YYYY h:mm A")}
                          </span>
                          {addedBy ? ` - ${addedBy}` : ""}
                        </div>
                      )}
                      {activitylog.clientID &&
                        (activitylog.clientID !== dealersettings.client.id ||
                          localStorage.defaultModule === "oem") &&
                        dealersettings.group ? (
                        <span className="tbl-sub-dealer">
                          {CommonHelper.getOtherDealerName(
                            dealersettings,
                            activitylog.clientID
                          )}
                        </span>
                      ) : (
                        <></>
                      )}
                      {!_.isEmpty(activitylog.replies) &&
                        (activitylog.subType === "message" ||
                          activitylog.iconType === "message") ? (
                        <>
                          {activitylog.replies.map((rec, index) => {
                            return (
                              <div key={index} className="flow-message-out">
                                {rec.text}
                                {rec?.files?.length > 0 ? (
                                  <div className="flow-mms-wrapper">
                                    {rec.files.map((rec, index) => {
                                      return (
                                        <div className="flow-mms-item-image">
                                          {rec?.type?.includes("image") ? (
                                            <a
                                              data-fancybox={`file-${index}`}
                                              href={rec.url}
                                              onClick={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <img
                                                id={`file-${index}`}
                                                src={rec.url}
                                                alt={rec.name}
                                                className=""
                                                width="40"
                                                height="40"
                                              />
                                            </a>
                                          ) : (
                                            <a
                                              href={rec.url}
                                              target="_blank"
                                              className="hyperlink-blue"
                                            >
                                              {" "}
                                              {rec.name}{" "}
                                            </a>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {rec.date ? (
                                  <div className="flow-activity-date">
                                    {moment
                                      .unix(rec.date.seconds)
                                      .format("DD/MM/YYYY h:mm A")}
                                    {index + 1 === activitylog.replies.length ? (
                                      <span className="sms-reply">
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            handleReplylog(e, activitylog);
                                          }}
                                        >
                                          <i className="ico icon-mail-reply"></i>
                                          <Translate text={"Reply"} />
                                        </a>
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                      {!_.isEmpty(activitylog) &&
                        activitylog.type ===
                        CommonHelper.getActivityType("activity", originType) &&
                        activitylog.isDone === true &&
                        activitylog.subType &&
                        activitylog.subType === "call" &&
                        activitylog.activityOutcome &&
                        dealersettings &&
                        dealersettings.client &&
                        dealersettings.client.settings &&
                        !_.isEmpty(
                          dealersettings.client.settings.activityOutcome
                        ) &&
                        dealersettings.client.settings.activityOutcome.filter(
                          (a) => a.value === activitylog.activityOutcome
                        ).length ? (
                        <span className="activity-outcome">
                          <strong>
                            <Translate text={"Activity Outcome"} />:{" "}
                          </strong>
                          {
                            dealersettings.client.settings.activityOutcome.filter(
                              (a) => a.value === activitylog.activityOutcome
                            )[0].name
                          }
                        </span>
                      ) : (
                        <></>
                      )}
                      {activitylog.isDone === true &&
                        activitylog.type ===
                        CommonHelper.getActivityType("activity", originType) ? (
                        <span className="activity-upcoming">
                          <strong>
                            <Translate text={"completed"} />:
                          </strong>{" "}
                          {moment
                            .unix(activitylog.modifiedDate.seconds)
                            .format("DD/MM/YYYY h:mm A")}{" "}
                          - {modifiedBy}{" "}
                        </span>
                      ) : (
                        <></>
                      )}
                      {((!_.isEmpty(activitylog.tags) && activitylog.tags.length > 0) || activitylog?.aircall?.recordingVisibility || activitylog?.recordingVisibility) ? (
                        <div className="flow-notes-sub">

                          {!_.isEmpty(activitylog.tags) && activitylog.tags.map((rec, index) => {
                            return (
                              <div
                                key={index}
                                className="badge badge-pill badge-input-white mr-1 text-capitalize"
                              >
                                {CommonHelper.getNameByValue(
                                  dealersettings.integrations,
                                  _.isObject(rec) ? rec.type : rec,
                                  _.isObject(rec) ? rec.type : rec
                                )}
                              </div>
                            );
                          })}

                          {activitylog?.aircall?.recordingVisibility ? (
                            <div
                              className={`badge badge-pill badge-${activitylog?.aircall?.recordingVisibility.toLowerCase()}-audio text-uppercase`}
                            >
                              {activitylog?.aircall?.recordingVisibility}
                            </div>
                          ) : (
                            <></>
                          )}
                          {activitylog?.recordingVisibility ? (
                            <div
                              className={`badge badge-pill badge-${activitylog?.recordingVisibility.toLowerCase()}-audio text-uppercase`}
                            >
                              {activitylog?.recordingVisibility}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {!_.isEmpty(activitylog.triggerType) &&
                        activitylog.subType !== "gdpr" ? (
                        <div className="flow-notes-sub">
                          <div className="badge badge-pill badge-input-white mr-1 text-capitalize">
                            <Translate text={"edms"} />
                          </div>
                          {activitylog?.events?.length &&
                            activitylog?.events?.map((item, key) => {
                              if (!item?.name || item?.name === 'accepted') {
                                return null;
                              }
                              return (
                                <div
                                  key={`event-${key}`}
                                  className={`badge badge-pill badge-input-${item?.name} mr-1 text-capitalize cursor-pointer`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowHistoryModal({
                                      ...showHistoryModal,
                                      data: activitylog,
                                      show: true,
                                    });
                                  }}
                                >
                                  <Translate text={item?.name} />
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <></>
                      )}

                      {activitylog.inbound ? (
                        <div className="flow-notes-sub">
                          <div className="badge badge-pill badge-input-white mr-1 text-uppercase">
                            <Translate text={"Tracked Appointment"} />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {activitylog.type ===
                      CommonHelper.getActivityType("activity", originType) &&
                      !_.isEmpty(activitylog.notes) &&
                      activitylog.notes.trim().length > 0 ? (
                      <div className="flow-activity-notes-inner text-area-space">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: CommonHelper.stringtoURL(activitylog.notes),
                          }}
                        ></div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* {
                                                    activitylog?.aircall?.recordingVisibility === 'private' ? (
                                                        <div className="call-controls-wrapper"><div className="badge badge-pill badge-private-call">
                                                            <i className="ico icon-activity-lock"></i> Private </div>
                                                        </div>
                                                    ) : (<></>)
                                                } */}
                    {(!hideMoreOption &&
                      (_.isEmpty(activitylog.tags) ||
                        activitylog.tags.length === 0) &&
                      type !== "edms" &&
                      type !== "gdpr" &&
                      activitylog.subType !== "log" &&
                      (deleteActivity || editActivity)) ||
                      dealersettings.superAdmin === true ||
                      aircallSettings || justcallSettings ||
                      ringcentralSettings ? (
                      <Dropdown className="enquiry-flowitem-more" alignRight>
                        <Dropdown.Toggle as={CustomToggle} className="dropdown">
                          <i className="ico icon-more"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          as={CustomMenu}
                          className="dropdown-menu dropdown-menu-right"
                          ChildClass="more-dropdown"
                          xplacement="bottom-end"
                        >
                          {(aircallSettings || ringcentralSettings || justcallSettings) && activitylog.subType === "call" &&
                            (activitylog?.tags?.includes("aircall") ||
                              activitylog?.tags?.includes("justcall") ||
                              activitylog?.tags?.includes("ringcentral")) ? (
                            <Dropdown.Item
                              eventKey="5"
                              onClick={(e) => {
                                setNotesModel({
                                  show: true,
                                  title: "Add Notes",
                                  customerlog: {
                                    ...activitylog,
                                    type: "log",
                                    subType: "note",
                                    notes: "",
                                    mentions: [],
                                  },
                                });
                              }}
                            >
                              <i className="ico icon-note"></i>{" "}
                              <Translate text={"Add Notes"} />{" "}
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                          {editActivity && _.isEmpty(activitylog.tags) ? (
                            <>
                              <Dropdown.Item
                                eventKey="1"
                                onClick={(e) => {
                                  handleEditactivity(
                                    e,
                                    activitylog,
                                    completedTab
                                  );
                                }}
                              >
                                <i className="ico icon-edit"></i>{" "}
                                <Translate text={"edit"} />{" "}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <></>
                          )}
                          {(deleteActivity && _.isEmpty(activitylog.tags)) ||
                            dealersettings.superAdmin === true ? (
                            <Dropdown.Item
                              eventKey="2"
                              onClick={(e) => {
                                handleDeleteactivity(
                                  e,
                                  activitylog,
                                  completedTab
                                );
                              }}
                            >
                              <i className="ico icon-delete"></i>{" "}
                              <Translate text={"delete"} />
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                          {aircallSettings &&
                            activitylog?.aircall?.recordingVisibility ===
                            "private" &&
                            aircallSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            ) ? (
                            <Dropdown.Item
                              eventKey="3"
                              onClick={(e) => {
                                updateActivity(e, {
                                  ...activitylog,
                                  aircall: {
                                    ...activitylog.aircall,
                                    recordingVisibility: "shared",
                                  },
                                });
                              }}
                            >
                              <i className="ico icon-activity-meeting"></i>{" "}
                              <Translate text={"Shared"} />
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                          {aircallSettings &&
                            activitylog?.aircall?.recordingVisibility ===
                            "shared" &&
                            aircallSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            ) ? (
                            <Dropdown.Item
                              eventKey="4"
                              onClick={(e) => {
                                updateActivity(e, {
                                  ...activitylog,
                                  aircall: {
                                    ...activitylog.aircall,
                                    recordingVisibility: "private",
                                  },
                                });
                              }}
                            >
                              <i className="ico icon-reserve"></i>{" "}
                              <Translate text={"Private"} />
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                          {(ringcentralSettings || justcallSettings) &&
                            activitylog?.recordingVisibility === "private" &&
                            (ringcentralSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            ) || justcallSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            )) ? (
                            <Dropdown.Item
                              eventKey="3"
                              onClick={(e) => {
                                updateActivity(e, {
                                  ...activitylog,
                                  recordingVisibility: "shared",
                                });
                              }}
                            >
                              <i className="ico icon-activity-meeting"></i>{" "}
                              <Translate text={"Shared"} />
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                          {(ringcentralSettings || justcallSettings) &&
                            activitylog?.recordingVisibility === "shared" &&
                            (ringcentralSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            ) || justcallSettings?.emails?.some(
                              (a) => a === dealersettings.email
                            )) ? (
                            <Dropdown.Item
                              eventKey="4"
                              onClick={(e) => {
                                updateActivity(e, {
                                  ...activitylog,
                                  recordingVisibility: "private",
                                });
                              }}
                            >
                              <i className="ico icon-reserve"></i>{" "}
                              <Translate text={"Private"} />
                            </Dropdown.Item>
                          ) : (
                            <></>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {activitylog.type ===
          CommonHelper.getActivityType("activity", originType) ? (
          <span className="flow-icon">
            {" "}
            <i
              className={`ico ${CommonHelper.activityIconByValue(
                dealersettings,
                type
              )}`}
            ></i>{" "}
          </span>
        ) : (
          <span className="flow-icon">
            <i
              className={`ico icon-${activitylog.subType === "file"
                ? "attachment"
                : activitylog.subType === "call" && activitylog.callType
                  ? `${activitylog.subType}-${activitylog.callType}`
                  : activitylog.subType === "call" ||
                    activitylog.subType === "email"
                    ? activitylog.subType + "-log"
                    : activitylog.subType === "edms" ||
                      activitylog.subType === "gdpr"
                      ? activitylog.iconType
                      : activitylog.inbound
                        ? "sms-inbound"
                        : activitylog.subType
                }`}
            ></i>{" "}
          </span>
        )}
      </div>
      <PopUpModal show={notesModel.show}>
        <AddCallNotes
          {...notesModel}
          handleClose={(customerlog, mentions) => {
            if (customerlog?.notes) {
              let notes = "";
              notes = notes + customerlog?.notes + "\n";
              notes =
                notes +
                moment().format("DD/MM/YYYY hh:mm A") +
                " - " +
                dealersettings.name +
                "\n\n";

              updateActivity(null, {
                ...activitylog,
                notes: notes + (activitylog.notes ? activitylog.notes : ""),
                mentions:
                  mentions?.length > 0
                    ? mentions.map((r) => {
                      return r.id;
                    })
                    : [],
              });
              setNotesModel({ show: false });
            } else {
              setNotesModel({ show: false });
            }
          }}
        />
      </PopUpModal>
      <PopUpModal show={showHistoryModal.show}>
        <TriggerHistoryModal
          {...showHistoryModal}
          show={showHistoryModal.show}
          handleClose={() =>
            setShowHistoryModal({ ...showHistoryModal, show: false })
          }
        />
      </PopUpModal>

      <PopUpModal show={showTranscriptModal.show}>
        <TranscriptModal
          {...props}
          {...showTranscriptModal}
          show={showTranscriptModal.show}
          handleClose={() =>
            setShowTranscriptModal({ ...showTranscriptModal, show: false })
          }
        />
      </PopUpModal>
    </>
  ) : (
    <></>
  );
});

export default ActivityTag;
