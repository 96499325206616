export const objQueue = {
    documentID: null,
    autoRefreshed: null,
    qTask: '',
    qTime: null,
    qTimeout: null,
    qTimer: false,
    users: []
};

export const objEngageRequest = {
    documentID: null,
    clientID: null,
    projectId: null,
    user: null,
    contact: {},
    name: '',
    notes: '',
    campaign: '',
    origin: '',
    enquiryType: '',
    rejectReason: '',
    status: '',
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    saleType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const mandatoryFields = [
    'user'
];

export const allRejectFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true
    },
    {
        name: 'Rejected By',
        value: 'userName',
        flex: 1,
        default: true,
        subText: 'updatedOn'
    },
    {
        name: 'Reason',
        value: 'rejectReason',
        flex: 1,
        default: true
    },
    {
        name: 'Comments',
        value: 'notes',
        flex: 1,
        default: true,
        limit : 190
    },
    {
        name: 'Created By',
        value: 'addedBy',
        flex: 1,
        default: true,
        subText: 'createdOn'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 150,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]