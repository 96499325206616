import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash';
import { offersTabs, NoDataDiv, LoadingDiv } from '../constants';

import CommonHelper from '../../../../services/common';
import { firestoreDB } from '../../../../services/helper';

const WholesaleerSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	history,
}) => {
	const [activeTab, setActivetab] = useState('offers');
	const [offers, setOffers] = useState([]);
	const [loader, setLoader] = useState([]);

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let refWholesler = firestoreDB(dealersettings).firestore()
				.collectionGroup('offers')
				.where('wholeSellerId', '==', localStorage.uid)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.orderBy('addedDate', 'desc')
				.limit(10);

			var dashboardPipelines = combineLatest(collection(refWholesler))
				.pipe(
					map(([offers]) => {
						return [
							_.map(offers, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'offers',
									sortDate: obj.data().addedDate,
								};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					setOffers(allDocs[0]);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }}>
			<div
				className='dropdown-menu dropdown-menu-left'
				aria-labelledby='dropdownMenuButton'
				style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
			>
				<div className='form-style globalsearch-wrap'>
					<div className='globalsearch-nav-wraper'>
						<div className='globalsearch-nav pl-0'>
							<Tab.Container defaultActiveKey={activeTab}>
								<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
									{offersTabs.map((rec, index) => {
										return (
											<OverlayTrigger
												key={index}
												placement='bottom'
												overlay={
													<Tooltip><span>{rec.title}</span></Tooltip>
												}
											>
												<Nav.Item
													as='li'
													bsPrefix='li-nav'
													key={index}
												>
													<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
														<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
														{rec.name}
													</Nav.Link>
												</Nav.Item>
											</OverlayTrigger>
										);
									})}
								</Nav>
							</Tab.Container>
						</div>
					</div>
					<div className='globalsearch-body'>
						{loader ? (
							<LoadingDiv />
						) : (
							<div className='global-item-wraper'>
								{activeTab === 'all' ? (
									<></>
								) : activeTab === 'offers' ? (
									<>
										{offers.length > 0 ? (
											<>
												{offers.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															history.push(`/`);
															history.push(`wholesaler/details/${obj.documentID}`);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
															</div>
														</div>
														<div>
															{(!_.isEmpty(obj.tradeinPro) && !_.isEmpty(obj.tradeinPro.tradein) && (obj.tradeinPro.tradein.make)) ? (
																<span className='global-header'>
																	{' '}
																	{`${obj.tradeinPro.tradein
																		? obj.tradeinPro.tradein.make
																		: ''
																		} ${obj.tradeinPro.tradein
																			? obj.tradeinPro.tradein.model
																			: ''
																		}`}{' '}
																</span>
															) : (
																<></>
															)}
															<span className='global-info'>
																{' '}
																{CommonHelper.displayContactName([], obj.tradeinPro.contact, '--')}
															</span>
															<div className='global-status'>
																<span
																	className={`status-pending`}
																>
																	{' '}
																	Pending{' '}
																</span>
															</div>
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : (
									<></>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WholesaleerSearch;
