import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import EmailTemplateList from './emailtemplateList'
import SMSTemplateList from './smstemplateList'
import Translate from '../../../../constants/translate';
import { UpgradePlan } from '../../../../components'
import _ from 'lodash'
import CommonHelper from '../../../../services/common';
const Users = (props) => {
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);     
    return (
        <>
            {
                liteVersion ? (<UpgradePlan />) : (<></>)
            }
            <h2> <Translate text={'Templates'} /></h2>
            <div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`}>
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey="email" className="nav-fill" mountOnEnter={true}>
                            <Tab eventKey="email" title={CommonHelper.showLocale(props, "EDMs")}>
                                <EmailTemplateList {...props} type='email' />
                            </Tab>
                            <Tab eventKey="emailTemplate" title={CommonHelper.showLocale(props, "Email Templates")}>
                                <EmailTemplateList {...props} type='emailTemplate' />
                            </Tab>
                            <Tab eventKey="triggersms" title={CommonHelper.showLocale(props, "Trigger SMS")}>
                                <SMSTemplateList {...props} type='sms' />
                            </Tab>
                            <Tab eventKey="sms" title={CommonHelper.showLocale(props, "SMS")}>
                                <SMSTemplateList {...props} type='smsTemplate' />
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Users