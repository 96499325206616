/** LIBRARIES */
import React, { Component } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Swal from 'sweetalert2';
import _, { isEmpty } from 'lodash'
import moment from "moment";
/** PROVIDERS */
import { RouteConsumer } from '../layout/RouteProvider';
import { LayoutConsumer } from '../layout/provider';
import { StageProvider, StageConsumer } from '../pipeline/provider';
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { PopUpModal, SidePanel, UpgradeModal, DealerSelect, DropDownMenu } from '../../components';
import { default as _images } from '../../images';
import Notification from '../notification';
import Translate from '../../constants/translate';
import AddContact from '../contacts/add';
import AddCompany from '../company/add';
//import AddEnquiry from '../pipeline/add';
import AddActivity from '../activity';
import AddCafe from '../cafe/add';
import AddCallLog from '../callLogs/add'
import ActiveUsers from '../users/active';
import { AllModules, SettingsRoutes } from '../../constants/routes';
import ChatSidePanel from '../chat/chatsidepanel';
import CommonHelper from '../../services/common';
/** VIEW-MODELS */
import { objEnquiry } from '../pipeline/viewModel';
import { objService } from '../service/viewModel';
import { objTradeIP } from '../tradeinplus/viewModel';
import StockHistory from '../stock/history'
import SearchContainer from './searchPanel/searchContainer';
import CafeOrder from '../cafe/order';
import AddTestDrive from '../testdrive/add';
import AddClient from '../oem/dealers/addClientModal';
import AddFleet from '../fleet/add';
import AddEvent from '../eventsPro/add'
import InBoundLeadConvert from '../pipeline/inBoundLeadConvert';
import InBoundTIPConvert from '../tradeinplus/inBoundLeadConvert';
import NewTestDrive from '../testdrive/new';
import toast from 'toasted-notes';
import DownloadApp from './downloadApp';
import { serviceStatus, enquiryStatus, tipStatus, userLevel } from '../../services/enum';
import EnquiryModal from '../pipeline/details/enquiryModal'
import VSRDashboard from './vsrDashboard';
import { firestoreDB } from '../../services/helper';
import AppointmentList from '../service/appointment/list'
import AttendeeInfo from '../eventsProAttendees/details'
import ReAuthenticate from '../common/reAuthenticate';
import TwoFactorAuthModel from '../settings/profile/general-settings/twoFactorAuthModel';

const settingmenusort = [
	"clientSettings",
	"workflowSetup",
	"productsData",
	"modules",
	"integration",
	"locationMgnt",
	"securityMgnt",
	'billing'
]

const gaConstants = {
	addNewLead: 'btn_add_new_lead',
	addNewEnquiry: 'btn_add_new_enquiry',
	addTestDrive: 'btn_add_testdrive',
	addAppointment: 'btn_add_appointment',
	checkIn: 'btn_add_checkin',
	addContact: 'btn_add_contact',
	addCompany: 'btn_add_company',
	addActivity: 'btn_add_activity',
	orderCafe: 'btn_order_cafe',
	addCallLog: 'btn_add_calllog',
	addAppraisal: 'btn_add_tradein',
	addEvent: 'btn_add_tradein'
}

class Navbar extends Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		// this.refEnquiry = window.firebase.firestore().collection('enquiries');
		// this.refService = window.firebase.firestore().collection('serviceJobs');
		// this.refTradeIP = window.firebase.firestore().collection('tradeinPro');
		this.state = {
			fields: Object.assign({}, objEnquiry),
			fieldService: Object.assign({}, objService),
			fieldTradeIn: Object.assign({}, objTradeIP),
			clsActive: '',
			chatClsActive: '',
			smsClsActive: '',
			clsName: 'sidebar',
			chatClsName: 'sidebar',
			showContact: {
				show: false,
				clientID: null,
			},
			showCompany: false,
			showEnquiryModal: {
				show: false,
				enqID: null,
				title: '',
				isNewEnquiry: null
			},
			showActivity: false,
			showSearchPanel: false,
			showActiveUsers: false,
			showCafe: false,
			showCallLog: false,
			showTestDrive: false,
			isNewLead: false,
			searchText: '',
			companyTitle: 'Company',
			companyDocID: '',
			editActivity: {
				contactObj: null,
				enquiryObj: null,
				activityObj: null,
				stockObj: null,
				isEditActivity: false,
				title: ''
			},
			showAttendee: {
				show: false,
				docID: null,
			},
			showStock: {
				show: false,
				stockID: null,
			},
			showOffers: {
				show: false,
				offerID: null,
			},
			showCafeOrder: {
				show: false,
				cafeObj: null,
			},
			testDriveModal: {
				show: false,
				clsActive: '',
				enquiry: null,
				contact: null,
				enquiryID: null,
				title: 'Test Drive',
				docID: null
			},
			dealerModal: {
				show: false,
				brands: null,
				dealer: null
			},
			dealerSelectModal: {
				show: false,
				title: null,
				origin: null
			},
			loanVehicleModal: {
				docID: null,
				show: false,
				clsActive: '',
				stock: null,
				title: 'fleet',
			},
			inBoundModal: {
				docID: null,
				show: false,
				clsActive: '',
				title: 'Lead Details',
			},
			inBoundTIPModal: {
				docID: null,
				show: false,
				clsActive: '',
				title: 'Lead Details',
			},
			showScheduleActivityModel: {
				activityLog: null,
				show: false,
				contact: null,
				enquiry: null
			},
			//counts: {},
			showDownloadApp: false,
			showUpgradeModal: false,
			vsrModal: {
				show: false
			},
			appointmentModal: {
				show: false,
				title: null,
				status: null
			},
			searchDealer: '',
			showEvent: false,
			showReauthenticate: {
				show: false
			},
			showMFAEnableModel: {
				show: false
			},
			mfaLoader: false,
		};
		// this.handleLogout = this.handleLogout.bind(this);
		// this.handleContactShow = this.handleContactShow.bind(this);
		// this.handleContactClose = this.handleContactClose.bind(this);

		// this.handleCompanyShow = this.handleCompanyShow.bind(this);
		// this.handleCompanyClose = this.handleCompanyClose.bind(this);

		// this.handleEnquiryShow = this.handleEnquiryShow.bind(this);
		// this.handleEnquiryClose = this.handleEnquiryClose.bind(this);

		// this.handleActivityShow = this.handleActivityShow.bind(this);
		// this.handleActivityClose = this.handleActivityClose.bind(this);
	}


	componentDidMount() {
		document.documentElement.style.setProperty('--ddHeight', (window.innerHeight - 30) + 'px');
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	// handleSetCounts = (countsToSet) => {
	// 	this.setState({ counts: countsToSet })
	// }

	handleContactClose = () => {
		this.setState({
			showContact: {
				show: false,
				clientID: null,
			}
		});
	};

	gaClick = (eventName) => {
		if (eventName) {
			window.firebase.analytics().logEvent(eventName);
		}
	}

	isLiteVersion = () => {
		const { dealersettings } = this.props;
		const clientSettings = (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings)) ? dealersettings.client.clientSettings : null;

		return (!_.isEmpty(clientSettings) && clientSettings.liteVersion && localStorage.defaultModule !== 'oem') ? true : false;
	}

	handleContactShow = () => {

		// if (this.isLiteVersion()) {
		// 	this.setState({ showUpgradeModal: true })
		// 	return;
		// }
		const { dealersettings } = this.props;
		let _dealers = CommonHelper.getTradeinProDealers(dealersettings, true);
		if (localStorage.defaultModule !== 'tradeinPro' ||
			(localStorage.defaultModule === 'tradeinPro' &&
				_dealers &&
				_dealers.filter(e => ![dealersettings.client.id].includes(e)).length > 0 &&
				Object.assign([], _dealers).length === 1))
			this.setState({ showContact: { show: true, clientID: null } });
		else {
			this.handleDealerSelectOpen('Select Dealer', 'contactDetails')
		}
	};

	handleCompanyClose = () => {
		this.setState({ showCompany: false, companyDocID: '' });
	};

	handleResize = () => {
		document.documentElement.style.setProperty('--ddHeight', (window.innerHeight - 30) + 'px');
	}

	handleCompanyShow = () => {

		// if (this.isLiteVersion()) {
		// 	this.setState({ showUpgradeModal: true })
		// 	return;
		// }

		this.setState({ showCompany: true });
	};

	handleCompanyView = (id) => {
		this.setState({ showCompany: true, companyDocID: id });
	}

	handleEnquiryClose = () => {

		this.setState({
			showEnquiryModal: {
				show: false,
				enqID: null,
				title: '',
				isNewEnquiry: null
			}, isNewLead: false
		});
	};

	handleEnquiryShow = (isAfterSales) => {
		if (this.isLiteVersion()) {
			this.setState({ showUpgradeModal: true })
			return;
		}

		this.loadEnquiry(null, isAfterSales);
	};

	handleActivityShow = () => {
		this.setState({ showActivity: true });
	};

	handleCafeShow = () => {
		this.setState({ showCafe: true });
	};

	handleCafeClose = () => {
		this.setState({ showCafe: false });
	};

	handleLogShow = () => {
		this.setState({ showCallLog: true });
	};

	handleLogClose = () => {
		this.setState({ showCallLog: false });
	};

	handleNewTestDriveShow = () => {
		this.setState({ showTestDrive: true });
	};

	handleNewTestDriveClose = (_enqID, isNew) => {
		console.log('handleNewTestDriveClose', _enqID, isNew)
		if (_enqID) {
			this.setState({
				showEnquiryModal: {
					show: true,
					enqID: _enqID,
					title: isNew ? 'addNewEnquiry' : 'Enquiry',
					isNewEnquiry: isNew
				}
			});
		}
		this.setState({ showTestDrive: false });
	};

	handleActivityClose = async (objLog, isActivtyAlreadyDone) => {
		this.setState({ showActivity: false });
		if (objLog) {
			const { dealersettings } = this.props;
			if (objLog.isDone === true && this.props.dealersettings && this.props.dealersettings.client && this.props.dealersettings.client.clientSettings && this.props.dealersettings.client.clientSettings.isScheduleNextActivity && !isActivtyAlreadyDone) {
				let _isAlreadyScheduled = false;
				if (objLog.enquiryID || objLog?.enquiry?.documentID) {
					const responseMKA = await firestoreDB(dealersettings).firestore().collection('activities')
						.where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
						.where('isDeleted', '==', false)
						.where('type', '==', 'activity')
						.where('isDone', '==', false)
						.limit(1)
						.get();
					if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
				}
				if (_isAlreadyScheduled) return;

				const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(dealersettings, objLog?.enquiry);

				Swal.fire({
					title: CommonHelper.showLocale(this.props, 'Schedule next activity?'),
					text: CommonHelper.showLocale(this.props, 'Do you want to schedule next activity?'),
					icon: 'info',
					showCancelButton: mandScheduleNextActivity ? false : true,
					confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
					cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then((result) => {
					if (result.value) {
						// console.log(objLog)
						this.setState({
							showScheduleActivityModel: {
								activityLog: objLog,
								show: true,
								contact: objLog.contact ? objLog.contact : null,
								enquiry: objLog.enquiry ? objLog.enquiry : null,
							}
						})
					}
				})
			}
		}
	};

	handleScheduledActivityModelClose = () => {
		this.setState({
			showScheduleActivityModel: {
				show: false,
				activityLog: null,
				contact: null,
				enquiry: null
			}
		})
	}

	handleUsersClose = () => {
		this.setState({ showActiveUsers: false });
	};

	handleUsersShow = () => {
		this.setState({ showActiveUsers: true });
	};

	handleLeadEnquiryShow = () => {

		if (this.isLiteVersion()) {
			this.setState({ showUpgradeModal: true })
			return;
		}

		this.loadEnquiry(true);
	};

	handleServiceShow = async (e, _status) => {
		e.preventDefault();
		const { dealersettings } = this.props;
		const enableServiceDailyRO = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings) &&
			dealersettings.client.clientSettings.enableServiceDailyRO) ? true : false);

		if (enableServiceDailyRO) {
			this.setState({
				appointmentModal: {
					show: true,
					title: null,
					status: _status
				}
			})
		}
		else {
			this.loadNewService(_status);
		}
	};

	handleAppointmentClose = (_status, _data) => {
		if (!_.isEmpty(_status)) {
			this.loadNewService(_status, _data);
		}
		this.setState({
			appointmentModal: {
				show: false,
				title: null,
				status: null
			}
		})
	}

	handleTradeInShow = (e) => {
		e.preventDefault();

		const { dealersettings } = this.props;
		let _dealers = CommonHelper.getTradeinProDealers(dealersettings, true);
		if (_dealers && Object.assign([], _dealers).length === 1)
			this.loadNewTradeInPro(_dealers[0])
		else {
			this.handleDealerSelectOpen('Add Appraisal', 'tradeinPro')
		}
	};

	handleEventShow = (e) => {
		e.preventDefault();
		this.setState({ showEvent: true });
	};
	handleEventClose = () => {
		this.setState({ showEvent: false });
	};

	handleSearchText = (e) => {
		this.setState({ searchText: e.target.value }, () => {
			let val = this.state.searchText.trim();
			if (val.length > 2 && !this.state.showSearchPanel) {
				this.setState({ showSearchPanel: true })
			} else if (val.length < 3) {
				this.setState({ showSearchPanel: false })
			}
		});
	}

	handlePaste = (e) => {
		e.preventDefault()
		const text = e.clipboardData.getData('text/plain')
		this.setState({ searchText: text }, () => {
			let val = this.state.searchText.trim();
			if (val.length > 2 && !this.state.showSearchPanel) {
				this.setState({ showSearchPanel: true })
			} else if (val.length < 3) {
				this.setState({ showSearchPanel: false })
			}
		});
	}

	handleLogout = () => {
		const { deviceTokens, removeDevicetoken, currentClient } = this.props;
		Swal.fire({
			title: CommonHelper.showLocale(this.props, 'Are you sure?'),
			text: CommonHelper.showLocale(this.props, 'Do you want to logout.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
			allowOutsideClick: false,
			allowEscapeKey: false
		}).then(async (result) => {
			if (result.value) {
				// if (!_.isEmpty(currentClient)) {
				// 	console.log('isQueue', false)
				// 	window.firebase.firestore()
				// 		.doc(`users/${localStorage.uid}/userClients/${currentClient}`)
				// 		.update({
				// 			isQueue: false
				// 		}).catch((error) => {
				// 			console.error("Error updating contacts: ", error);
				// 		});
				// }

				// if (this.props.dealersettings.superAdmin === true) {
				// 	const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
				// 	await userRef.set({
				// 		arrayClientID: null,
				// 		roleID: null,
				// 		settingsID: null
				// 	}, { merge: true })
				// }

				if (deviceTokens && deviceTokens.length > 0) {
					removeDevicetoken();
					window.firebase
						.firestore()
						.doc(`/users/${localStorage.uid}`)
						.set(
							{
								tokkens: [
									...deviceTokens.filter(
										item => item !== localStorage.fcmtoken,
									),
								],
							},
							{ merge: true },
						).then(rec => {
							this.logout()
						});
				}
				else {
					this.logout()
				}

			}
		});
	};

	handleDownloadApp = () => {
		this.setState({
			showDownloadApp: true
		})
	}

	logout = async () => {
		const { dealersettings } = this.props
		try {
			try {
				const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${localStorage.browserKey}`)
				await userRef.set({
					logoutDate: window.firebase.firestore.Timestamp.now(),
					isLogin: false,
					forceLogout: false,
					modifiedDate: window.firebase.firestore.Timestamp.now()
				}, { merge: true })
			}
			catch (err) {
				console.log(err);
			}
			const logRef = window.firebase.firestore().collection('auditLogs')
			const auditLog = {
				clientID: dealersettings.client.id ? dealersettings.client.id : null,
				documentID: logRef.doc().id,
				groupID: dealersettings.groupID ? dealersettings.groupID : null,
				imageURL: null,
				logBy: localStorage.uid,
				logDate: window.firebase.firestore.Timestamp.now(),
				notes: null,
				oemID: dealersettings.oemID ? dealersettings.oemID : null,
				platform: 'web',
				recordID: localStorage.browserKey ? localStorage.browserKey : null,
				regionID: dealersettings.regionID ? dealersettings.regionID : null,
				subtype: 'logout',
				type: 'authentication',
			}
			await logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
			await window.firebase
				.auth()
				.signOut();
			await window.firebase2
				.auth()
				.signOut();
			toast.closeAll();
			const brandTheme = localStorage.brandTheme;
			localStorage.clear();
			return <Redirect to={`${brandTheme ? `/login?theme=${brandTheme}` : '/login'}`} />;
		} catch (error) {
			console.log(error)
		}
	}

	sidepanelClose = () => {
		this.setState({
			clsActive: '',
		});
	};

	chatSidepanelClose = () => {
		this.setState({
			chatClsActive: '',
		});
		localStorage.removeItem('showChat');
	};

	smsSidepanelClose = () => {
		this.setState({
			smsClsActive: '',
		});
		this.onClosingSidePanel();
	};

	onClosingSidePanel = () => {
		let pathname = window.location.pathname;
		if (pathname.indexOf('enquiry/details') >= 0 ||
			pathname.indexOf('contacts/details') >= 0 ||
			pathname.indexOf('tradeinpro/details') >= 0 ||
			pathname.indexOf('eventspro/details') >= 0 ||
			pathname.indexOf('wholesaler/details') >= 0 ||
			pathname.indexOf('service/details') >= 0 ||
			pathname.indexOf('fleetpro/details') >= 0 ||
			pathname.indexOf('/dashboard') >= 0 ||
			pathname.indexOf('/dashboard/bdc') >= 0 ||
			pathname.indexOf('/dashboard/service') >= 0 ||
			pathname.indexOf('/dashboard/events') >= 0 ||
			pathname.indexOf('/dashboard/tradeinpro') >= 0) {
			document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
			document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		} else if (
			(pathname.indexOf('pipeline') >= 0 ||
				pathname.indexOf('inbound') >= 0 ||
				pathname.indexOf('wholesaler') >= 0 ||
				pathname.indexOf('tradeinpro') >= 0 ||
				pathname.indexOf('aftersales') >= 0 ||
				pathname.indexOf('service') >= 0 ||
				pathname.indexOf('/kitchen') >= 0) &&
			(pathname.indexOf('ppsr/list') < 0 &&
				pathname.indexOf('tradeinpro/list') < 0 &&
				pathname.indexOf('eventspro/list') < 0 &&
				pathname.indexOf('fleetpro/list') < 0 &&
				pathname.indexOf('fleetpro/history') < 0 &&
				pathname.indexOf('eventspro/attendees') < 0 &&
				pathname.indexOf('service/list') < 0 &&
				pathname.indexOf('pipeline/oemlist') < 0 &&
				pathname.indexOf('pipeline/list') < 0 &&
				pathname.indexOf('inbound/list') < 0 &&
				pathname.indexOf('tipinbound/list') < 0 &&
				pathname.indexOf('inbound/allocation') < 0)) {
			document.getElementsByTagName('body')[0].classList.remove('inner-bg');
		}
	}

	//#region ENQUIRY
	loadEnquiry = (isNewLead, isAfterSales) => {
		const { dealersettings, clientModule } = this.props;

		if (dealersettings &&
			dealersettings.client &&
			dealersettings.client.settings) {

			const setttings = dealersettings.client.settings;

			let state = Object.assign({}, this.state.fields);
			if (_.isEmpty(state.addedBy)) {
				state.addedBy = dealersettings ? dealersettings.id : '';
				state.addedDate = window.firebase.firestore.Timestamp.now();
				state.owner = dealersettings ? dealersettings.id : '';
			}
			state.tags = [];
			state.modifiedBy = dealersettings ? dealersettings.id : '';
			state.modifiedDate = window.firebase.firestore.Timestamp.now();
			state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings)
			// state.clientID = dealersettings ? dealersettings.client.id : '';
			// state.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);
			// state.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);
			// state.oemID = (dealersettings ? dealersettings.client.settingsID : null);

			let logNotes = 'Enquiry created';


			if (isNewLead && setttings.pipelines.some(item => item.value === 'LeadsBucket') && setttings.pipelines.length > 0) {

				let _selPipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
				let _defaultstages = _selPipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
				let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

				state.pipeline = _selPipeline.value;
				state.stage = _stages[0];
				state.status = null;
				state.stageDate = window.firebase.firestore.Timestamp.now();
				state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
				if (dealersettings && localStorage.defaultModule === 'oem' && dealersettings.level === 'oem' && dealersettings.oemID) {
					state.oemStatus = 'pending';
					state.status = 'pending';
				}
				state.ownerInbound = localStorage.uid;
			}
			else if (isAfterSales) {
				state.isAfterSales = true
				state.pipeline = 'AfterSales';
				state.stage = 'new-enquiry';
				state.status = 'open';
				state.stageDate = window.firebase.firestore.Timestamp.now();
				state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
			}
			else {
				let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value !== 'LeadsBucket' && i.default === true)[0]);
				if (_.isEmpty(_selPipeline))
					_selPipeline = Object.assign({}, setttings.pipelines.filter(e => e.value !== 'LeadsBucket')[0]);
				if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline)) {
					_selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultPipeline);
				}

				let defaultstages = _selPipeline?.stages;
				let defaultstatus = _selPipeline?.status;
				let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

				state.pipeline = _selPipeline.value;
				state.stage = stages[0];
				state.status = !_.isEmpty(defaultstatus) ? defaultstatus[0] : 'open';
				state.stageDate = window.firebase.firestore.Timestamp.now();
				state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
			}

			if (state.status) state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);
			if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
				state.requirement = {
					saleType: dealersettings.accessSalesType[0]
				}
			}
			state.isDeleted = false;
			state.isNewEnquiry = true;

			const objData = Object.assign({}, state);
			//Remove empty value from object
			for (var propName in objData) {
				if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
					if (!['origin'].includes(propName)) delete objData[propName];
				}
			}

			if (!objData.documentID)
				objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

			const refReqData = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
			refReqData.set(objData, { merge: true }).then((docRef) => {
				let documentID = objData.documentID;
				//CommonHelper.saveChangeLog(objData.clientID, documentID, logNotes, 'enquiry', 'added');
				//this.props.history.push("/enquiry/details/" + documentID + '?type=' + (isNewLead ? 'lead' : 'enquiry'));

				this.setState({
					showEnquiryModal: {
						show: true,
						enqID: documentID,
						title: isNewLead ? 'addNewLead' : 'addNewEnquiry',
						isNewEnquiry: true
					}
				});
			}).catch((error) => {
				console.error("Error adding enquiries: ", error);
			});


		}

	}
	//#endregion

	//#region SERVICE
	loadNewService = async (_status, _dailyRO) => {
		const { dealersettings, clientModule } = this.props;
		if (dealersettings &&
			dealersettings.client &&
			dealersettings.client.services) {

			const _allPipelines = dealersettings?.client?.services?.pipelines;
			const selectOptions = !_.isEmpty(_allPipelines) && _allPipelines.length > 1 ? _allPipelines.reduce((options, { name, value }) => {
				options[value] = name;
				return options;
			}, {}) : null;

			let selectedPipeline;
			if (!_.isEmpty(selectOptions) && _status && _status !== serviceStatus.NEWSERVICE) {
				const { value: _selectedPipeline } = await Swal.fire({
					title: 'Select Pipeline to Continue',
					input: 'select',
					inputOptions: selectOptions,
					inputPlaceholder: 'select pipeline',
					showCancelButton: true,
					confirmButtonText: 'Continue',
					cancelButtonText: 'Cancel',
					showLoaderOnConfirm: true,
					allowOutsideClick: false,
					allowEscapeKey: false,
					inputValidator: (value) => {
						if (!value) {
							return 'You need to select an pipeline';
						}
					}
				});
				selectedPipeline = _selectedPipeline
			}

			console.log('selectedPipeline', selectedPipeline)

			const _clientService = (!_.isEmpty(dealersettings) &&
				!_.isEmpty(dealersettings.client) &&
				!_.isEmpty(dealersettings.client.clientSettings) &&
				!_.isEmpty(dealersettings.client.clientSettings.service)) ? dealersettings.client.clientSettings.service : null;

			const _taxType = (!_.isEmpty(_clientService) && _clientService.taxType) ? _clientService.taxType : null;
			const _taxPercentage = (!_.isEmpty(_clientService) && _clientService.tax) ? _clientService.tax : 0;
			const _internalTaxExemption = (!_.isEmpty(_clientService) && _clientService.internalTaxExemption) ? true : false;

			const setttings = dealersettings.client.services;

			let state = Object.assign({}, this.state.fieldService);
			if (_.isEmpty(state.addedBy)) {
				state.addedBy = dealersettings ? dealersettings.id : '';
				state.addedDate = window.firebase.firestore.Timestamp.now();
				state.owner = dealersettings ? dealersettings.id : '';
			}
			state.modifiedBy = dealersettings ? dealersettings.id : '';
			state.modifiedDate = window.firebase.firestore.Timestamp.now();
			//state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings)
			state.clientID = dealersettings ? dealersettings.client.id : '';
			state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
			state.appointmentDate = moment().format('YYYY-MM-DD HH:mm:ss');
			state.internalTaxExemption = _internalTaxExemption;

			if (_taxType && _taxPercentage) {
				state.taxType = _taxType;
				state.taxPercentage = _taxPercentage;
			}

			if (_status) {
				state.status = _status;
				if (_status !== serviceStatus.NEWSERVICE && !_.isEmpty(setttings)) {

					let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.default === true)[0]);
					if (_.isEmpty(_selPipeline))
						_selPipeline = Object.assign({}, setttings.pipelines[0]);

					if (selectedPipeline) _selPipeline = Object.assign({}, setttings.pipelines.filter(i => i.value === selectedPipeline)[0]);
					else if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline)) {
						_selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaultservicePipeline);
					}

					let statusSettings = _selPipeline && _selPipeline.statusSettings;

					let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === state.status)[0];
					if (!_.isEmpty(data) && data.pipeline && data.stage) {
						state.pipeline = data.pipeline;
						state.stage = data.stage;
					}
					else {
						let _stages = Array.prototype.map.call(_selPipeline.stages, function (item) { return item.value; }).join(",").split(',');
						state.pipeline = _selPipeline.value;
						state.stage = _stages[0];
					}

					state.stageDate = window.firebase.firestore.Timestamp.now();
					state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
					state.statusHistory = Object.assign([], [{ date: state.stageDate, status: state.status, user: localStorage.uid }]);

				}
			}
			else {
				state.status = serviceStatus.NEWSERVICE;
				state.statusHistory = Object.assign([], [{ date: window.firebase.firestore.Timestamp.now(), status: state.status, user: localStorage.uid }]);
			}


			if (state.status === serviceStatus.CHECKIN) {
				state.checkInBy = dealersettings ? dealersettings.id : '';
				state.checkInDate = window.firebase.firestore.Timestamp.now();

			}

			if (!_.isEmpty(_dailyRO)) {
				state.dailyroID = _dailyRO.documentID;

				if (!_.isEmpty(_dailyRO.contact)) state.engageNewContact = _dailyRO.contact;
				if (!_.isEmpty(_dailyRO.vehicle)) state.vehicle = _dailyRO.vehicle;
				if (!_.isEmpty(_dailyRO.serviceTime)) state.timeOfDay = _dailyRO.serviceTime;

				if (_dailyRO.serviceDate && _dailyRO.serviceTime)
					state.appointmentDate = _dailyRO.serviceDate + ' ' + _dailyRO.serviceTime + ':00';
				else if (_dailyRO.appointmentOn) state.appointmentDate = moment.unix(_dailyRO.appointmentOn.seconds).format('YYYY-MM-DD HH:mm:ss');

				let _selectedSlot = await CommonHelper.getServiceSlotByTimeOfDay(state.appointmentDate, _dailyRO.serviceTime, setttings.slots, dealersettings);
				if (!_.isEmpty(_selectedSlot)) state.slot = _selectedSlot;
			}

			state.isDeleted = false;

			const objData = Object.assign({}, state);
			//Remove empty value from object
			for (var propName in objData) {
				if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
					delete objData[propName];
				}
			}

			if (!objData.documentID)
				objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;

			const refReqData = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(objData.documentID);
			refReqData.set(objData, { merge: true }).then((docRef) => {
				let documentID = objData.documentID;

				const _client = objData.clientID && !_.isEmpty(dealersettings) &&
					!_.isEmpty(dealersettings.group) &&
					!_.isEmpty(dealersettings.group.clients) &&
					dealersettings.group.clients[objData.clientID] ? dealersettings.group.clients[objData.clientID] : dealersettings.client;

				if (!_.isEmpty(_client) && !_.isEmpty(_client.services) && !_.isEmpty(_client.services.recommendations)) {

					const labourCost = (!_.isEmpty(_clientService) && _clientService.labourCost) ? _clientService.labourCost : 0;
					const _categoriesList = Object.assign([], setttings && setttings.categories);
					const batch = firestoreDB(dealersettings).firestore().batch();
					_client.services.recommendations.filter(a => a.active === true).forEach(data => {
						//data.name = data.title ? data.title : data.name;
						if ((data.type === 'checklist' && _categoriesList.some(e => e.value === data.category && e.active === true)) || data.type !== 'checklist') {
							if (data.title) {
								data.name = data.title;
								delete data['title'];
							}
							data.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
							data.serviceID = documentID;
							data.detail = Object.assign({}, {
								option: 'customer',
								status: 'urgent',
								labourCost: labourCost ? labourCost : 0,
								oKToDo: true,
							})
							data.clientID = dealersettings ? dealersettings.client.id : '';
							data.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
							batch.set(firestoreDB(dealersettings).firestore().doc(`serviceJobs/${documentID}/recommendations/${data.documentID}`), data, { merge: true });

						}
					})

					if (objData.dailyroID) {
						batch.set(firestoreDB(dealersettings).firestore().doc(`serviceDailyRO/${objData.dailyroID}`), {
							status: 1,
							serviceID: documentID,
							modifiedBy: localStorage.uid,
							modifiedDate: window.firebase.firestore.Timestamp.now(),
							modifiedFrom: 'web'
						}, { merge: true });
						CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Job created from inbound. \n (ref#: ${_dailyRO.refID})`, 'service', 'updated', batch);
					}


					batch.commit().then(snapshot => {
						this.props.history.push("/service/details/" + documentID + '?type=' + 'service');
					}).catch((error) => {
						console.error("Error updating document: ", error);
					});
				}
				else {
					if (objData.dailyroID) {
						firestoreDB(dealersettings).firestore().doc(`serviceDailyRO/${objData.dailyroID}`).set({
							status: 1,
							serviceID: documentID,
							modifiedBy: localStorage.uid,
							modifiedDate: window.firebase.firestore.Timestamp.now(),
							modifiedFrom: 'web'
						}, { merge: true });
						CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.documentID, `Job created from inbound. \n (ref#: ${_dailyRO.refID})`, 'service', 'updated');
					}
					this.props.history.push("/service/details/" + documentID + '?type=' + 'service');
				}

			}).catch((error) => {
				console.error("Error adding enquiries: ", error);
			});


		}

	}
	//#endregion

	//#region TRADEIN-PLUS
	loadNewTradeInPro = (_clientID, _pipeline) => {
		const { dealersettings, clientModule } = this.props;
		if (dealersettings &&
			dealersettings.client &&
			dealersettings.client.tradeinPro) {

			const setttings = dealersettings.client.tradeinPro;

			let state = Object.assign({}, this.state.fieldTradeIn);
			if (_.isEmpty(state.addedBy)) {
				state.addedBy = dealersettings ? dealersettings.id : '';
				state.addedDate = window.firebase.firestore.Timestamp.now();
				state.owner = dealersettings ? dealersettings.id : '';
			}
			state.modifiedBy = dealersettings ? dealersettings.id : '';
			state.modifiedDate = window.firebase.firestore.Timestamp.now();
			state.clientID = _clientID ? _clientID : (dealersettings ? dealersettings.client.id : '');
			state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
			state.isDeleted = false;
			state.isNewTradein = true;

			let _selPipeline = Object.assign({}, setttings.pipelines.filter(i => _pipeline ? i.value === _pipeline : i.default === true)[0]);
			if (_.isEmpty(_selPipeline))
				_selPipeline = Object.assign({}, setttings.pipelines[0]);
			// if (setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline)) {
			// 	_selPipeline = setttings.pipelines.find(e => e.value === dealersettings?.rolePermissions?.defaulttradeinProPipeline);
			// }

			let defaultstages = _selPipeline.stages;
			let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

			state.pipeline = _selPipeline.value;
			state.stage = stages[0];
			state.status = tipStatus.NEW;
			state.stageDate = window.firebase.firestore.Timestamp.now();
			state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);

			const objData = Object.assign({}, state);
			//Remove empty value from object
			for (var propName in objData) {
				if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
					delete objData[propName];
				}
			}

			if (!objData.documentID)
				objData.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;

			const refReqData = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc(objData.documentID);
			refReqData.set(objData, { merge: true }).then((docRef) => {
				let documentID = objData.documentID;
				this.props.history.push("/tradeinpro/details/" + documentID);
			}).catch((error) => {
				console.error("Error adding enquiries: ", error);
			});


		}

	}
	//#endregion

	handleActivityModelDelete = (data) => {
		// console.log(data)
		this.handleActivityClose()
		this.setState({
			showActivity: false,
			editActivity: {
				contactObj: null,
				enquiryObj: null,
				activityObj: null,
				stockObj: null,
				isEditActivity: false,
				title: ''
			},
		})
	}

	handleOpenEditActivity = async (obj) => {
		let contact = null;
		let enquiry = null;

		if (!_.isEmpty(obj.enquiryID)) {
			const enquiryDoc = await firestoreDB(this.props.dealersettings)
				.firestore()
				.doc('enquiries/' + obj.enquiryID)
				.get();
			if (enquiryDoc.exists) {
				enquiry = enquiryDoc.data();
			}
		}
		if (!_.isEmpty(obj.contactID)) {
			const contactDoc = await firestoreDB(this.props.dealersettings)
				.firestore()
				.doc('contactDetails/' + obj.contactID)
				.get();
			if (contactDoc.exists) {
				contact = contactDoc.data();
			}
		}

		this.setState({
			editActivity: {
				contactObj: contact,
				enquiryObj: enquiry,
				activityObj: obj,
				stockObj: null,
				isEditActivity: true,
				title: 'Edit Activity'
			},
			showActivity: true
		})
	}

	handleAttendeeOpen = (docID) => {
		this.setState({
			showAttendee: {
				show: true,
				docID,
			}
		})
	}
	handleAttendeeClose = () => {
		this.setState({
			showAttendee: {
				show: false,
				docID: null,
			}
		})
	}

	handleOpenStock = (stockID) => {
		this.setState({
			showStock: {
				show: true,
				stockID,
			}
		})
	}
	handleOpenOffers = (offerID) => {
		this.setState({
			showOffers: {
				show: true,
				offerID,
			}
		})
	}
	handleCloseStock = () => {
		this.setState({
			showStock: {
				show: false,
				stockID: null,
			}
		})
	}

	handleOpenCafeOrder = (cafeObj) => {
		this.setState({
			showCafeOrder: {
				show: true,
				cafeObj,
			}
		})
	}

	handleCloseCafeOrder = () => {
		this.setState({
			showCafeOrder: {
				show: false,
				cafeObj: null,
			}
		})
	}

	handleTestDriveOpen = async (obj) => {
		this.setState({
			testDriveModal: {
				show: true,
				clsActive: 'overlay-modal active',
				enquiry: obj.enquiry,
				contact: obj.contact,
				enquiryID: obj.enquiryID,
				docID: obj.documentID,
				title: 'testDrive',
			}
		})
	}

	handleTestDriveClose = () => {
		this.setState({
			testDriveModal: {
				show: false,
				enquiry: null,
				contact: null,
				enquiryID: null,
				title: '',
			}
		})
	}

	handleDealerOpen = (obj, brands) => {
		console.log(obj, brands)
		this.setState({
			dealerModal: {
				show: true,
				brands: brands,
				dealer: obj,
				title: obj.name
			}
		})
	}

	handleDealerClose = () => {
		this.setState({
			dealerModal: {
				show: false,
				dealer: null,
				title: ''
			}
		})
	}

	handleLoanVehicleOpen = (obj) => {
		this.setState({
			loanVehicleModal: {
				show: true,
				docID: obj.documentID,
				stock: obj.stock,
			}
		})
	}

	handleLoanVehicleClose = () => {
		this.setState({
			loanVehicleModal: {
				show: false,
				docID: null,
				stock: null,
			}
		})
	}

	handleInBoundOpen = (obj) => {
		if (obj.renderType === 'tradeinPro') {
			this.setState({
				inBoundTIPModal: {
					show: true,
					docID: obj.documentID,
				}
			})
		}
		else {
			this.setState({
				inBoundModal: {
					show: true,
					docID: obj.documentID,
				}
			})
		}
	}

	handleInBoundClose = () => {
		this.setState({
			inBoundModal: {
				show: false,
				docID: null,
			},
			inBoundTIPModal: {
				show: false,
				docID: null,
			}
		})
	}

	handleOpenSupport = (e) => {
		e.preventDefault()
		window.Intercom('show')
	}

	handleSearchClose = () => {
		this.setState({ showSearchPanel: false })
	}

	handleOpenSupport = (e) => {
		e.preventDefault()
		window.Intercom('show')
	}

	handleDealerSelectOpen = (title, origin) => {
		this.setState({
			dealerSelectModal: {
				show: true,
				title: title,
				origin: origin
			}
		})
	}

	handleDealerSelectClose = (_clientID, _origin, _pipeline) => {

		if (_clientID && _origin === 'tradeinPro') {
			this.loadNewTradeInPro(_clientID, _pipeline);
		}

		if (_clientID && _origin === 'contactDetails') {
			this.setState({ showContact: { show: true, clientID: _clientID } });
		}

		this.setState({
			dealerSelectModal: {
				show: false,
				title: null,
				origin: null
			}
		})
	}

	handleEnableMFA = () => {
		const { dealersettings } = this.props;
		if (dealersettings.enableMFA) {
			this.setState({
				mfaLoader: true
			})
			const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
			enrollMFA({
				"uid": localStorage.uid,
				"phone": `+${dealersettings.phoneCode.replace('+', '')}${dealersettings.phone}`,
				"enroll": false
			}).then((data) => {
				this.setState({
					mfaLoader: false
				})
				if (data.data.success === true) {
					window.firebase.firestore().doc(`users/${localStorage.uid}`).set({ enableMFA: false }, { merge: true });
					this.setState({
						showReauthenticate: {
							show: true,
							title: 'Reauthenticate'
						}
					})
				}
				else {
					let msg = data.data.message;
					if (data.data.data.errorInfo && data.data.data.errorInfo.code === 'auth/invalid-phone-number') {
						msg = 'Invalid phone number.'
					}
					Swal.fire(msg, '', 'error');
				}
			});
		} else {
			this.setState({
				showReauthenticate: {
					show: true,
					title: 'Reauthenticate'
				}
			})
		}
	}

	// handleSwithOEM = () => {
	// 	localStorage.removeItem('oemswitchclient')
	// 	handleModuleChange(
	// 		'enquiry',
	// 		AllModules.find(item => item.module === 'enquiry').route,
	// 	);
	// 	localStorage.setItem('oemswitchclient',clientToNavigate[0].id)
	// 	handleClientChange(clientToNavigate[0].id);
	// 	this.props.history.push("/oem/dashboard");
	// }

	render() {
		const { clsActive, clsName, chatClsActive, chatClsName, smsClsActive, showEnquiryModal, dealerSelectModal, showContact, appointmentModal, showReauthenticate, showMFAEnableModel } = this.state;

		const { dealersettings } = this.props;

		const _messagemedia = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

		const _smshub = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

		const _podium = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

		const _mailchimp = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "mailchimp")[0]);

		const _vision6 = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "vision6")[0]);

		const _mailup = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "mailup")[0]);

		const _aircall = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "aircall")[0]);

		const _ringcentral = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

		const _justcall = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "justcall")[0]);

		const _mailjet = Object.assign({}, dealersettings &&
			dealersettings.client &&
			dealersettings.client.integrations &&
			dealersettings.client.integrations.filter(e => e.type === "mailjet")[0]);

		const d365testdriveEnabled = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
			dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0] &&
			dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0].active === true &&
			dealersettings.client.integrations.filter(e => e.type === "d365testdrive")[0].enabled === true
		) ? true : false);

		const _mailjetModule = dealersettings?.client?.moduleSettings?.mailjet;

		const showFormFeedback = dealersettings?.rolePermissions?.permissions?.showFormFeedback
		const showTvScheduler = dealersettings?.rolePermissions?.permissions?.accessToTVDisplay
		const accessVSR = dealersettings?.rolePermissions?.servicePermissions?.accessVSR
		const accessToPerformanceReview = dealersettings?.rolePermissions?.permissions?.accessToPerformanceReview
		const accessToAcquisitionReport = dealersettings?.rolePermissions?.permissions?.accessToAcquisitionReport
		const accessToRoster = dealersettings?.rolePermissions?.permissions?.accessToRoster
		const accessToTriggerLogs = dealersettings?.rolePermissions?.permissions?.accessToTriggerLogs
		const accessToTradeinHistory = dealersettings?.rolePermissions?.permissions?.accessToTradeinHistory
		const accessToMarketingROI = dealersettings?.rolePermissions?.permissions?.accessToMarketingROI
		const yearlyPerformanceReport = dealersettings?.rolePermissions?.permissions?.yearlyPerformanceReport
		const yearlySalesPerformanceReport = dealersettings?.rolePermissions?.permissions?.salesPerformanceReport
		const accessToRetailerTargetReport = dealersettings?.rolePermissions?.permissions?.accessToRetailerTargetReport


		const messageMediaEnabled = _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
		const smsHubEnabled = _smshub && _smshub.active && _smshub.enabled ? true : false;
		const podiumEnabled = _podium && _podium.active && _podium.enabled ? true : false;
		const ringcentralEnabled = _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
		const justcallEnabled = _justcall && _justcall.active && _justcall.enabled ? true : false;
		const ppsrEnabled = (!_.isEmpty(dealersettings?.client?.moduleSettings?.ppsr) &&
			dealersettings.client.moduleSettings.ppsr.enabled &&
			dealersettings.client.moduleSettings.ppsr.active) ? true : false;

		const _pipelines = (dealersettings && dealersettings.client && dealersettings.client.settings) ? dealersettings.client.settings.pipelines : [];

		const _userRoles = (dealersettings && dealersettings.roleID &&
			dealersettings.client && dealersettings.client.roles &&
			dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);

		const isInboundAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e === 'LeadsBucket') ? true : false;
		const isSalesAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e !== 'LeadsBucket') ? true : false;
		const isTIPInboundAccess = _userRoles?.tradeinProPermissions?.inboundAccess ? true : false;

		const _permissions = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

		const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
		const bdcDashboard = ((!_.isEmpty(_permissions) && _permissions.bdcDashboard) ? true : false);
		const disableToAddEnquiry = ((!_.isEmpty(_permissions) && _permissions.disableToAddEnquiry) ? true : false);
		const disableToAddContact = ((!_.isEmpty(_permissions) && _permissions.disableToAddContact) ? true : false);

		const _hasTestDriveAccess = !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client.settings.pipelines) &&
			dealersettings.client.settings.pipelines.some(m => !_.isEmpty(m.modules) && m.modules.testDrive === true) ? true : false;

		const _servicePermissions = ((!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.rolePermissions) &&
			!_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);
		const scheduleAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.scheduleAccess) ? true : false);
		const checkInAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.checkInAccess) ? true : false);
		const accessServiceStock = ((!_.isEmpty(_servicePermissions) && _servicePermissions.accessServiceStock) ? true : false);
		const accessToTradeinHistoryService = ((!_.isEmpty(_servicePermissions) && _servicePermissions.accessToTradeinHistory) ? true : false);

		const clientSettings = (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.clientSettings)) ? dealersettings.client.clientSettings : null;

		const enableNewTD = (!_.isEmpty(clientSettings) && clientSettings.enableNewTD) ? true : false;
		const inboundAllocation = (!_.isEmpty(clientSettings) && clientSettings.inboundAllocation) ? true : false;
		const enablePipelineList = (!_.isEmpty(clientSettings) && clientSettings.enablePipelineList) ? true : false;
		const enableFDInboundPipeline = (!_.isEmpty(clientSettings) && clientSettings.enableFDInboundPipeline) ? true : false;
		const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion && localStorage.defaultModule !== 'oem') ? true : false;

		const _moduleSettings = (!_.isEmpty(dealersettings) &&
			!_.isEmpty(dealersettings.client) &&
			!_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null;

		const cafeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.cafe) &&
			(_moduleSettings.cafe.enabled && _moduleSettings.cafe.active)) ? true : false);

		const frontdeskEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.frontdesk) &&
			(_moduleSettings.frontdesk.enabled && _moduleSettings.frontdesk.active)) ? true : false);

		const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
		const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
		const eventsProAllStatus = Object.assign([], dealersettings?.client?.eventsPro?.eventsProStatus)
		const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
		const fleetProAllStatus = Object.assign([], dealersettings?.client?.fleetPro?.fleetProStatus)

		const tvModuleEnabled = ((dealersettings && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.tvModule) &&
			dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);

		const performanceReviewEnabled = ((dealersettings && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.performanceReview) &&
			dealersettings.client.moduleSettings.performanceReview.enabled) ? true : false);

		const rosterEnabled = ((dealersettings && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.moduleSettings) &&
			!_.isEmpty(dealersettings.client.moduleSettings.roster) &&
			dealersettings.client.moduleSettings.roster.enabled) ? true : false);

		let tipClients = CommonHelper.getTradeinProDealers(dealersettings, true);

		const addEventAccess = dealersettings?.rolePermissions?.eventsProPermissions?.addEvent ? true : false;
		return (
			<>
				<RouteConsumer>
					{({ routes, handleRouteRemove, handleRouteRemoveAll, resetnavbar, handleRouteChange, updatenavbar }) => (
						<>
							<LayoutConsumer>
								{({
									dealersettings,
									clientUsers,
									handleClientChange,
									modules,
									handleModuleChange,
									clientModule,
									notificationCount,
									queueNum,
									nylasAccessToken,
									mailUnreadCount,
									pipeline,
									smsNotificationCount,
									inboundCount,
									updateDealerSettings,
									handleSleepModeOpen,
									unReadChatCounts,
									handleOEMChange,
									paymentDue,
									paymentDueDateText,
									groupUsers,
									currentClient,
									handleNotifyClick,
									handleNotifySwitchDealer,
									handleEnquirySwitchDealer,
									userNotifications,
									notifyClose,
									handlenotifyClose,
									smsNotifications,
									handleSetCounts,
									aircallModal,
									updateAircallModel,
									refreshAircallModel,
									aircallPhone,
									tradeinProinboundCount,
									updateRingcentralModel,
									ringcentralModal,
									updateJustcallModel,
									justcallModal
								}) => (
									<>
										{dealersettings ? (
											<>
												{paymentDue === true ? (
													<span className="subscription-user-toaster-top">
														<div className="subscription-user-toaster-message" style={{ opacity: '1' }}>
															<div
																className="subscription-user-toaster-message-wrapper"
																style={{ transform: 'translateY(0%) scale(1)' }}
															>
																<div data-reach-alert="true">
																	<div
																		className="subscription-user-toaster-alert subscription-toast-cell-green subscription-user-toaster-alert-center-text"
																	>
																		<div className="flex-toast-subscription">
																			<div className="subscription-toast-icon">
																				<img src={_images.dollarIcon} alt='dollar-icon' height="25" />
																			</div>
																			<div className="subscription-toast-data">
																				<div className="subscription-toast-item-title">
																					Overdue Account! {paymentDueDateText ? paymentDueDateText : ''}.<span className="subscription-link">
																						<a href="#" onClick={e => this.handleOpenSupport(e)}>Contact Support</a></span
																					>
																				</div>
																			</div>
																		</div>
																		{/* <button
																		className="subscription-user-toaster-alert-close"
																		type="button"
																		aria-label="Close"
																	>
																		<span aria-hidden="true">×</span>
																	</button> */}
																	</div>
																</div>
															</div>
														</div>
													</span>
												) : !dealersettings.enableTOTP && dealersettings.showMFABanner === 'true' ? (
													<span className="twofactor-user-toaster-top ">
														<div className="twofactor-user-toaster-message" style={{ opacity: 1 }}>
															<div className="twofactor-user-toaster-message-wrapper" style={{ transform: 'translateY(0%) scale(1)' }}>
																<div data-reach-alert="true">
																	<div className="twofactor-user-toaster-alert twofactor-toast-cell-green twofactor-user-toaster-alert-center-text">
																		<div className="flex-toast-twofactor">
																			<div className="twofactor-toast-icon"> <img src={_images.mutifactorshield} height="25" /> </div>
																			<div className="twofactor-toast-data">
																				<div className="twofactor-toast-item-title">Enhanced Security with Two-Factor Authentication<span className="twofactor-link">
																					<button className="btn btn-sm btn-default" onClick={(e) => {
																						e.preventDefault();
																						this.handleEnableMFA(e)
																					}}>{
																							this.state.mfaLoader ?
																								(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
																								: (<></>)
																						}
																						Enable Now
																					</button>
																				</span>Mandatory Starting from June 24th
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</span>
												) : (<></>)}
												<nav
													id='top-nav-bar'
													className='navbar navbar-expand-md navbar-dark bg-dark fixed-top'
													style={paymentDue === true || (!dealersettings.enableTOTP && dealersettings.showMFABanner === 'true') ? { marginTop: '35px' } : {}}
												>
													<NavLink to={routes.filter(item => clientModule ? item.module === clientModule || item.oemmodule === clientModule : false)[0] ? routes.filter(item => clientModule ? item.module === clientModule || item.oemmodule === clientModule : false)[0].route : '/dashboard'}>
														<div className='navbar-logo'>
															<img
																src={_images.logo}
																width='56'
																height='25'
																alt='AMS'
															/>
														</div>
													</NavLink>
													<div className='collapse navbar-collapse'>
														<form className='form-inline my-2 my-lg-0 search-bar' onSubmit={e => e.preventDefault()}>
															<input
																className='mr-sm-3'
																type='text'
																placeholder='Search'
																aria-label='Search'
																value={this.state.searchText}
																onChange={this.handleSearchText}
																onPaste={this.handlePaste}
															/>
														</form>

														<SearchContainer
															history={this.props.history}
															handleOpenStock={this.handleOpenStock}
															handleAttendeeOpen={this.handleAttendeeOpen}
															handleOpenEditActivity={this.handleOpenEditActivity}
															handleCompanyView={this.handleCompanyView}
															showSearchPanel={this.state.showSearchPanel}
															dealersettings={dealersettings}
															searchText={this.state.searchText}
															handleClose={() => this.setState({ showSearchPanel: false, searchText: '' })}
															handleSearchClose={this.handleSearchClose}
															handleOpenCafeOrder={this.handleOpenCafeOrder}
															handleTestDriveOpen={this.handleTestDriveOpen}
															handleDealerOpen={this.handleDealerOpen}
															handleLoanVehicleOpen={this.handleLoanVehicleOpen}
															handleInBoundOpen={this.handleInBoundOpen}
															handleOpenOffers={this.handleOpenOffers}
															pipeline={pipeline}
															modules={modules}
														/>

														<ul className={`navbar-nav mr-auto nav-fix ${clientModule && (_.includes(['enquiry', 'tradeinPro', 'afterSales', 'oem', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], clientModule) || (clientModule === 'frontdesk' && enableFDInboundPipeline)) ? 'sales-nav-fix' : ''}`}>
															{routes
																.filter(item =>
																	clientModule
																		? (item.module === clientModule || item.oemmodule === clientModule)
																		: false,
																)
																// filter for removing stock on group and region levels
																// .filter(route => {
																// 	if (dealersettings.level && (dealersettings.level === 'group' || dealersettings.level === 'region') && route.route === '/oem/stock') {
																// 		return false
																// 	} else {
																// 		return true
																// 	}
																// })
																.map((item, index) => {
																	return (
																		<li className='nav-item' key={index}>
																			{item.enquiries &&
																				(clientModule && clientModule === 'frontdesk' ? enableFDInboundPipeline : true) &&
																				item.enquiries.length > 0 ? (
																				<DropdownButton
																					alignRight
																					drop='down'
																					variant=''
																					title={
																						<>
																							<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, item.class, true, item.icoVehicle)}> </i>
																							<span className='badge badge-enquiry'>
																								{item.enquiries.length}
																							</span>
																							<span className='label'>
																								{CommonHelper.showLimitedChar(CommonHelper.showLocale({ dealersettings }, item.name.toLowerCase()), 9, 'span')}
																								{/* <Translate
																									text={item.name.toLowerCase()}
																								/> */}
																							</span>
																						</>
																					}
																					className='active-enquiry'
																				>
																					<ul className='basic-dropdown'>
																						{
																							(!_.isEmpty(item.enquiries))
																								?
																								<>
																									{
																										(item.enquiries.length > 1)
																											?
																											<>
																												<li >
																													<a href="#"
																														className="lead-clearall"
																														onClick={(e) => { e.preventDefault(); handleRouteRemoveAll(item.enquiries, clientModule); }}
																													> <Translate text={'Clear all'} /> </a>
																												</li>
																											</>
																											:
																											<></>
																									}
																									{
																										item.enquiries.map(
																											(rec, cindex) => {

																												return (
																													<li key={cindex}>

																														<div
																															onClick={() => {
																																handleRouteRemove(rec, clientModule);
																															}}
																															className='basic-lead-close'
																														>
																															{' '}
																															<i className='ico icon-remove'>
																																{' '}
																															</i>
																														</div>

																														<Dropdown.Item
																															eventKey={cindex}
																															as={Link}
																															to={`${rec.module === 'service' ? '/service/details/' :
																																(rec.module === 'tradeinPro' ? '/tradeinpro/details/' :
																																	(rec.module?.includes('eventsPro') ? '/eventspro/details/' :
																																		rec.module?.includes('fleetPro') ? '/fleetpro/details/' :
																																			(rec.module === 'wholesaler' ? '/wholesaler/details/' : '/enquiry/details/')))}${rec.id}${rec.params ? rec.params : ''}`}
																															active={rec.active}
																														>
																															<div className="d-flex">
																																<div className="lead-drop-name"> {CommonHelper.showLimitedChar(rec.name, 30, null, true)}</div>
																																{
																																	rec.isNew
																																		?
																																		<></>
																																		:
																																		<div className='enq-status'>
																																			{
																																				rec.status
																																					?
																																					<>
																																						{(() => {
																																							let __status = (rec.status && rec.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : rec.status;
																																							let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																																							if (rec.module === 'tradeinPro')
																																								_objCurrentStatus = __status && tradeinProAllStatus.filter(e => e.value === __status)[0]

																																							if (rec.module === 'service')
																																								_objCurrentStatus = __status && serviceAllStatus.filter(e => e.value === __status)[0]

																																							if (rec.module?.includes('eventsPro'))
																																								_objCurrentStatus = __status && eventsProAllStatus.filter(e => e.value === __status)[0]

																																							if (rec.module?.includes('fleetPro'))
																																								_objCurrentStatus = __status && fleetProAllStatus.filter(e => e.value === __status)[0]

																																							return <>
																																								{
																																									_objCurrentStatus
																																										?
																																										<>
																																											<span
																																												className={`status-${rec.status === 'open' || rec.status === 'New' ? 'open' : 'empty'} ml-2`}
																																												style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																																											>
																																												<Translate
																																													text={_objCurrentStatus.name}
																																													upperCase={true}
																																												/>
																																											</span>
																																										</>
																																										:
																																										<>
																																											{
																																												rec.name !== 'Enquiries'
																																													?
																																													<>
																																														<span
																																															className={`status-${rec.status.toLowerCase()} ml-2`}
																																														>
																																															<Translate text={rec.status.split(/(?=[A-Z])/).join(' ')} upperCase={true} />
																																														</span>
																																													</>
																																													:
																																													<></>
																																											}
																																										</>
																																								}
																																							</>
																																						})()}

																																					</>

																																					:
																																					<></>
																																			}
																																		</div>
																																}
																															</div>
																														</Dropdown.Item>
																													</li>
																												);
																											})
																									}
																								</>
																								:
																								<></>
																						}
																					</ul>
																				</DropdownButton>
																			) : item.submenu &&
																				((item.module === 'enquiry' && item.name.toLowerCase() === 'dashboard' && isSalesAccess && isInboundAccess && bdcDashboard)
																					|| (item.module === 'enquiry' && item.name.toLowerCase() === 'reports' && (accessToAcquisitionReport || accessToTriggerLogs || accessToMarketingROI || yearlyPerformanceReport || yearlySalesPerformanceReport) && !_.includes(['oem', 'tradeinPro', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], localStorage.defaultModule))
																					|| (item.module === 'oem' && item.name.toLowerCase() === 'reports' && (accessToRetailerTargetReport))
																					|| (item.route !== '/dashboard' && item.route !== '/reports' && item.route !== '/oem/reports')) &&
																				item.submenu.length > 0 ? (
																				<Dropdown
																					alignRight
																					className={`${item.submenu.some(
																						e =>
																							e.route ===
																							window.location.pathname,
																					)
																						? 'active'
																						: ''
																						}`}
																				>
																					<Dropdown.Toggle
																						as={CustomToggle}
																						className='dropdown-toggle nav-link'
																					>
																						<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, item.class, true, item.icoVehicle)}></i>{' '}
																						<span className='label'>
																							<Translate text={item.name.toLowerCase()} />
																						</span>
																					</Dropdown.Toggle>
																					<Dropdown.Menu
																						as={CustomMenu}
																						className='dropdown-menu dropdown-menu-right'
																						ChildClass='submenu-dropdown'
																						xplacement='bottom-end'
																					>
																						{item.submenu.filter(a => item.route === '/reports' ? (a.name === 'Reports' || (a.name === 'acquisitionreport' && accessToAcquisitionReport) || (a.name === 'triggerlogs' && accessToTriggerLogs) || (a.name === 'marketingroi' && accessToMarketingROI) || (a.name === 'yearlyperformance' && yearlyPerformanceReport) || (a.name === 'yearlysalesperformance' && yearlySalesPerformanceReport)) : dealersettings.isMclaren === true ? (localStorage.defaultModule === 'oem' ? a.route !== '/stock' : true) : a.route !== '/oem/stock/pipeline').map((rec, cindex) => {
																							return (
																								<>
																									{
																										((rec.name === 'testDrive' && _hasTestDriveAccess) || rec.name !== 'testDrive')
																											?
																											<>
																												<Dropdown.Item
																													key={cindex}
																													eventKey={cindex}
																													as={Link}
																													to={rec.route}
																													//className={'disabled'}
																													active={
																														window.location.pathname ===
																															rec.route
																															? true
																															: false
																													}
																												>
																													<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.class, true, rec.icoVehicle)}></i>
																													<Translate
																														text={rec.name === 'Stock Management' && dealersettings.isMclaren === true && localStorage.defaultModule !== 'oem' ? 'pipeline' : rec.name.toLowerCase()}
																													/>

																													{
																														liteVersion && rec.name.toLowerCase() === 'tradeinhistory'
																															?
																															<><span className="tag-pro ml-2 mt-0"><Translate text={'PRO'} /></span></>
																															:
																															<></>
																													}
																													{/* <div className="readonly-check"> <i className="ico icon-lock"></i></div> */}
																												</Dropdown.Item>
																											</>
																											:
																											<></>
																									}
																								</>
																							);
																						})}
																					</Dropdown.Menu>
																				</Dropdown>
																			) : item.route ? (
																				(item.name && (item.name.toLowerCase() === 'inbound' || (item.name.toLowerCase() === 'pipeline' && !_.includes(['tradeinPro', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], item.module))))
																					?
																					<>
																						{
																							((item.name.toLowerCase() === 'inbound' && item.module === 'frontdesk' && enableFDInboundPipeline && _pipelines.length > 0 && _pipelines.some(item => item.value === 'LeadsBucket')) ||
																								(item.name.toLowerCase() === 'inbound' && item.module === 'tradeinPro' && isTIPInboundAccess) ||
																								(item.name.toLowerCase() === 'inbound' && !_.includes(['frontdesk', 'tradeinPro'], item.module) && _pipelines.length > 0 && _pipelines.some(item => item.value === 'LeadsBucket')) ||
																								(item.name.toLowerCase() === 'pipeline' && !_.includes(['oem', 'tradeinPro'], item.module) && _pipelines.length > 0 && _pipelines.filter(item => item.value !== 'LeadsBucket').length > 0) ||
																								(item.name.toLowerCase() === 'pipeline' && item.module === 'oem' && enablePipelineList && _pipelines.length > 0))
																								?
																								<>
																									<NavLink
																										to={inboundAllocation && localStorage.defaultModule === 'oem' && item.name.toLowerCase() === 'inbound' ? '/inbound/allocation' : item.route}
																										//to={item.route}
																										activeClassName={'active'}
																										title={item.name}
																									>
																										<div className='nav-link'>
																											{
																												item.name.toLowerCase() === 'inbound' && item.module === 'tradeinPro' ? (
																													<span className="badge badge-pill badge-calendar">{tradeinProinboundCount > 99 ? '99+' : tradeinProinboundCount > 0 ? tradeinProinboundCount : ''}</span>
																												) : item.name.toLowerCase() === 'inbound' && inboundCount > 0 ? (
																													<span className="badge badge-pill badge-calendar">{inboundCount > 99 ? '99+' : inboundCount}</span>
																												) : (<></>)
																											}
																											<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, item.class, true, item.icoVehicle)}></i>
																											<span className='label'>
																												<Translate
																													text={item.name.toLowerCase()}
																												/>

																											</span>
																										</div>
																									</NavLink>
																								</>
																								:
																								<></>}
																					</>
																					:
																					<>
																						{
																							((!accessServiceStock && item.name && item.name.toLowerCase() === 'stock' && item.module === 'service') ||
																								(item.name.toLowerCase() === 'calendar' && item.module === 'fleetPro' && (dealersettings.level === userLevel.INDIVIDUAL)))
																								?
																								<></>
																								:
																								<NavLink
																									to={item.route}
																									activeClassName={'active'}
																									title={item.name}
																								>
																									<div className='nav-link'>
																										{
																											item.showcount === true && mailUnreadCount > 0 ? (
																												<span className="badge badge-pill badge-calendar">{mailUnreadCount > 99 ? '99+' : mailUnreadCount}</span>
																											) : (<></>)
																										}
																										{item.name.toLowerCase() === 'calendar'
																											&& dealersettings
																											&& dealersettings.outlookCalendar
																											&& dealersettings.outlookCalendar.refreshTokenExpired === true
																											? (
																												<span className="badge badge-pill badge-calendar-warning"><i className="ico icon-mail-spam"></i></span>
																											) : (
																												<></>
																											)}
																										<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, item.class, true, item.icoVehicle)}></i>
																										<span className='label'>
																											<Translate
																												text={item.name.toLowerCase()}
																											/>


																										</span>
																									</div>
																								</NavLink>

																						}

																					</>

																			) : (
																				<></>
																			)}
																		</li>
																	);
																})}
														</ul>

														<div className='float-right'>
															{(!_.includes(['enquiry', 'frontdesk', 'oem', 'service', 'tradeinPro', 'afterSales', 'reports', 'loanVehicle', 'eventsPro', 'oemeventsPro'], clientModule) ||
																(_.includes(['eventsPro', 'oemeventsPro'], clientModule) && !addEventAccess)) ? (
																<></>
															) : (
																<>
																	<Dropdown className='add-icon' alignRight>
																		<Dropdown.Toggle
																			as={CustomToggle}
																			id='dropdown-custom-components'
																		>
																			<i className='ico icon-add'></i>
																		</Dropdown.Toggle>

																		<Dropdown.Menu
																			as={CustomMenu}
																			className='rm-pointers dropdown-menu-lg dropdown-menu-right'
																			ChildClass='addicon-dropdown'
																			xplacement='bottom-end'
																		>
																			{dealersettings &&
																				dealersettings.client &&
																				dealersettings.client.settings &&
																				dealersettings.client.settings.pipelines.length > 0 && !readOnlyEnquiryView && !disableToAddEnquiry &&
																				_.includes(['enquiry', 'oem',], clientModule) ?
																				(dealersettings.client.settings.pipelines.length == 1 && dealersettings.client.settings.pipelines.some(item => item.value === 'LeadsBucket')) ? (<>
																					<Dropdown.Item
																						eventKey='0'
																						// onClick={this.handleLeadEnquiryShow}
																						onClick={(e) => { e.preventDefault(); this.handleLeadEnquiryShow(); this.gaClick(gaConstants.addNewLead); }}
																					>
																						<i className='ico icon-enquiry'></i>{' '}
																						<Translate text={'addNewLead'} />{' '}
																						{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																					</Dropdown.Item>
																				</>
																				) : (dealersettings.client.settings.pipelines.length >= 1 && dealersettings.client.settings.pipelines.some(item => item.value === 'LeadsBucket')) ? (<>
																					<Dropdown.Item
																						eventKey='0'
																						// onClick={this.handleLeadEnquiryShow}
																						onClick={(e) => { e.preventDefault(); this.handleLeadEnquiryShow(); this.gaClick(gaConstants.addNewLead); }}
																					>
																						<i className='ico icon-enquiry'></i>{' '}
																						<Translate text={'addNewLead'} />{' '}
																						{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																					</Dropdown.Item>
																					{
																						clientModule === 'enquiry'
																							?
																							<>
																								<Dropdown.Item
																									eventKey='1'
																									onClick={(e) => { e.preventDefault(); this.handleEnquiryShow(false); this.gaClick(gaConstants.addNewEnquiry); }}
																								>
																									<i className='ico icon-new-enquiry'></i>{' '}
																									<Translate text={'addNewEnquiry'} />{' '}
																									{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																								</Dropdown.Item>
																								{
																									_hasTestDriveAccess && enableNewTD
																										?
																										<>
																											<Dropdown.Item
																												eventKey='2'
																												// onClick={this.handleNewTestDriveShow}
																												onClick={(e) => { e.preventDefault(); this.handleNewTestDriveShow(); this.gaClick(gaConstants.addTestDrive); }}
																											>
																												<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-activity-testdrive')}></i>
																												<Translate text={'addTestDrive'} />
																											</Dropdown.Item>
																										</>
																										:
																										<></>
																								}

																							</>
																							:
																							<></>
																					}

																				</>
																				) :
																					(clientModule === 'enquiry' && dealersettings.client.settings.pipelines.length >= 1 && !dealersettings.client.settings.pipelines.some(item => item.value === 'LeadsBucket')) ? (
																						<>
																							<Dropdown.Item
																								eventKey='3'
																								// onClick={(e) => { e.preventDefault(); this.handleEnquiryShow(false) }}
																								onClick={(e) => { e.preventDefault(); this.handleEnquiryShow(false); this.gaClick(gaConstants.addNewEnquiry); }}

																							>
																								<i className='ico icon-new-enquiry'></i>{' '}
																								<Translate text={'addNewEnquiry'} />{' '}
																								{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																							</Dropdown.Item>
																							{
																								_hasTestDriveAccess && enableNewTD && clientModule === 'enquiry'
																									?
																									<>
																										<Dropdown.Item
																											eventKey='4'
																											// onClick={this.handleNewTestDriveShow}
																											onClick={(e) => { e.preventDefault(); this.handleNewTestDriveShow(); this.gaClick(gaConstants.addTestDrive); }}
																										>
																											<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-activity-testdrive')}></i>
																											<Translate text={'addTestDrive'} />
																										</Dropdown.Item>
																									</>
																									:
																									<></>
																							}
																						</>
																					) : (
																						<Dropdown.Item
																							eventKey='5'
																							// onClick={this.handleLeadEnquiryShow}
																							onClick={(e) => { e.preventDefault(); this.handleLeadEnquiryShow(); this.gaClick(gaConstants.addNewEnquiry); }}
																						>
																							<i className='ico icon-enquiry'></i>{' '}
																							<Translate text={'addNewLead'} />{' '}
																							{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																						</Dropdown.Item>
																					) :
																				(
																					<></>
																				)
																			}

																			{
																				dealersettings &&
																					dealersettings.client &&
																					dealersettings.client.settings &&
																					dealersettings.client.settings.pipelines.length > 0 &&
																					!readOnlyEnquiryView && !disableToAddEnquiry &&
																					clientModule === 'afterSales'
																					?
																					<>
																						<Dropdown.Item
																							eventKey='6'
																							onClick={(e) => { e.preventDefault(); this.handleEnquiryShow(true); this.gaClick(gaConstants.addNewEnquiry); }}
																						>
																							<i className='ico icon-new-enquiry'></i>{' '}
																							<Translate text={'addNewEnquiry'} />{' '}
																							{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																						</Dropdown.Item>

																					</>
																					:
																					<></>
																			}

																			{
																				dealersettings &&
																					dealersettings.client &&
																					dealersettings.client.settings &&
																					dealersettings.client.settings.pipelines.length > 0 &&
																					dealersettings.client.settings.pipelines.some(item => item.value === 'LeadsBucket') &&
																					!readOnlyEnquiryView && !disableToAddEnquiry && clientModule === 'frontdesk' && enableFDInboundPipeline
																					?
																					<>
																						<Dropdown.Item
																							eventKey='7'
																							onClick={e => { e.preventDefault(); this.handleLeadEnquiryShow(); this.gaClick(gaConstants.addNewLead); }}
																						>
																							<i className='ico icon-enquiry'></i>{' '}
																							<Translate text={'addNewLead'} />{' '}
																							{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																						</Dropdown.Item>

																					</>
																					:
																					<></>
																			}



																			{
																				clientModule === 'service'
																					?
																					<>
																						{
																							scheduleAccess
																								?
																								<>
																									<Dropdown.Item
																										eventKey='8'
																										onClick={e => { this.handleServiceShow(e, serviceStatus.NEWSERVICE); this.gaClick(gaConstants.addAppointment); }}
																									>
																										<i className='ico icon-activity'></i>
																										<Translate text={'addAppointment'} />
																									</Dropdown.Item>
																								</>
																								:
																								<></>
																						}

																						{
																							checkInAccess
																								?
																								<>
																									<Dropdown.Item
																										eventKey='9'
																										onClick={e => { this.handleServiceShow(e, serviceStatus.CHECKIN); this.gaClick(gaConstants.checkIn); }}
																									>
																										<i className='ico icon-new-enquiry'></i>
																										<Translate text={'checkIn'} />
																									</Dropdown.Item>
																								</>
																								:
																								<></>
																						}

																					</>
																					:
																					<></>
																			}

																			{
																				clientModule === 'tradeinPro'
																					?
																					<>

																						<Dropdown.Item
																							eventKey='10'
																							onClick={e => { this.handleTradeInShow(e); this.gaClick(gaConstants.addAppraisal); }}
																						>
																							<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
																							<Translate text={'addAppraisal'} />
																						</Dropdown.Item>


																					</>
																					:
																					<></>
																			}
																			{
																				clientModule?.includes('eventsPro') && addEventAccess
																					?
																					<>

																						<Dropdown.Item
																							eventKey='11'
																							onClick={e => { this.handleEventShow(e); this.gaClick(gaConstants.addEvent); }}
																						>
																							<i className={'ico icon-events-event'}></i>
																							<Translate text={'addEvent'} />
																						</Dropdown.Item>


																					</>
																					:
																					<></>
																			}

																			{
																				clientModule === 'reports'
																					?
																					<>

																						<Dropdown.Item
																							eventKey='12'
																							// onClick={this.handleLeadEnquiryShow}
																							onClick={(e) => { e.preventDefault(); this.handleLeadEnquiryShow(); this.gaClick(gaConstants.addNewEnquiry); }}
																						>
																							<i className='ico icon-enquiry'></i>{' '}
																							<Translate text={'addNewLead'} />{' '}
																							{liteVersion ? <><span className="tag-pro ml-2 mt-1"><Translate text={'PRO'} /></span></> : <></>}
																						</Dropdown.Item>
																					</>
																					:
																					<></>
																			}

																			{
																				(!_.includes(['eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], clientModule))
																					?
																					<>
																						{
																							(_.includes(['enquiry'], clientModule) && disableToAddContact) ? <></> : <><Dropdown.Item
																								eventKey='13'
																								onClick={e => { e.preventDefault(); this.handleContactShow(); this.gaClick(gaConstants.addContact); }}
																							>
																								<i className='ico icon-new-contact'></i>{' '}
																								<Translate text={'addContact'} />{' '}
																							</Dropdown.Item></>
																						}
																					</>
																					:
																					<></>
																			}

																			{
																				_.includes(['enquiry', 'tradeinPro', 'oem', 'frontdesk', 'afterSales'], clientModule)
																					?
																					<>
																						<Dropdown.Item
																							eventKey='14'
																							onClick={e => { e.preventDefault(); this.handleCompanyShow(); this.gaClick(gaConstants.addCompany); }}
																						>
																							<i className='ico icon-company'></i>
																							<Translate text={'addCompany'} />{' '}
																						</Dropdown.Item>
																					</>
																					:
																					<></>
																			}

																			{
																				_.includes(['enquiry', 'tradeinPro', 'oem', 'service', 'afterSales'], clientModule)
																					?
																					<>
																						<Dropdown.Item
																							eventKey='15'
																							onClick={e => { e.preventDefault(); this.handleActivityShow(); this.gaClick(gaConstants.addActivity); }}
																						>
																							<i className='ico icon-activity'></i>
																							<Translate text={'addActivity'} />
																						</Dropdown.Item>

																					</>
																					:
																					<></>
																			}

																			{
																				cafeEnabled && _.includes(['enquiry', 'frontdesk', 'afterSales'], clientModule)
																					?
																					<>
																						<Dropdown.Item
																							eventKey='16'
																							onClick={e => { e.preventDefault(); this.handleCafeShow(); this.gaClick(gaConstants.orderCafe); }}
																						>
																							<i className='ico icon-cafe'></i>
																							<Translate text={'orderCafe'} />
																						</Dropdown.Item>

																					</>
																					:
																					<></>
																			}

																			{
																				clientModule === 'frontdesk'
																					?
																					<>
																						<Dropdown.Item
																							eventKey='17'
																							onClick={e => { e.preventDefault(); this.handleLogShow(); this.gaClick(gaConstants.addCallLog); }}
																						>
																							<i className='ico icon-call-log'></i>
																							<Translate text={'addCallLog'} />
																						</Dropdown.Item>
																					</>
																					:
																					<></>
																			}

																		</Dropdown.Menu>
																	</Dropdown>
																</>
															)}

															{/* <div className={`badge badge-pill badge-empty mt-3 fs-100`} >
																<DropDownMenu
																	text={dealersettings.language ? dealersettings.language : 'en'}
																	keyVal="1745764598"
																	tIndex="-1"
																	menu={_languages.filter(e => e.value !== (dealersettings.language ? dealersettings.language : 'en'))}
																	handleChange={changeLanguage}
																>
																</DropDownMenu>
															</div> */}

															{
																(!_.isEmpty(_aircall) && _aircall.active && _aircall.enabled)
																	?
																	<div className="aircall-nav-icon ml-4">
																		<a href="#" onClick={(e) => {
																			e.preventDefault();
																			localStorage.removeItem('aircallLog');
																			if (_.isEmpty(aircallPhone)) {
																				refreshAircallModel();
																			}
																			else {
																				aircallPhone.isLoggedIn(response => {
																					if (!response) {
																						refreshAircallModel();
																					}
																				});
																			}
																			updateAircallModel(!aircallModal)
																		}}>
																			{/* <span className="badge badge-pill badge-aircall">9</span> */}
																			<img src={_images.aircallIcon} width="25" />

																		</a>
																		{
																			aircallModal ? (<div className={"overlay phoneactive"} onClick={(e) => {
																				localStorage.removeItem('aircallLog');
																				updateAircallModel(!aircallModal)
																			}}> </div>) : (<></>)
																		}
																		<div
																			className={`aircall-drop-bg ${aircallModal ? '' : 'hide'}`}
																			style={{ position: 'absolute', transform: 'translate3d(0px, 48px, 0px)', top: '0px', right: '0px', willChange: 'transform' }}>
																			<div className="aircall-iframe-wrap">
																				<h3 className="popover-header"><span className="close" onClick={(e) => {
																					e.preventDefault();
																					localStorage.removeItem('aircallLog');
																					updateAircallModel(!aircallModal)
																				}}>×</span>Aircall Phone  </h3>
																				<div id="aircall-phone"></div>
																			</div>
																		</div>
																	</div>
																	:
																	<></>
															}
															{
																ringcentralEnabled
																	?
																	<div className="aircall-nav-icon ml-4">
																		<a href="#" onClick={(e) => {
																			e.preventDefault();
																			localStorage.removeItem('ringcentralLog');
																			updateRingcentralModel(!ringcentralModal)
																		}}>
																			<img src={_images.ringcentralIcon} width="25" />

																		</a>
																		{
																			ringcentralModal ? (<div className={"overlay phoneactive"} onClick={(e) => {
																				localStorage.removeItem('ringcentralLog');
																				updateRingcentralModel(!ringcentralModal)
																			}}> </div>) : (<></>)
																		}
																		<div
																			className={`aircall-drop-bg ${ringcentralModal ? '' : 'hide'}`}
																			style={{ position: 'absolute', transform: 'translate3d(0px, 48px, 0px)', top: '0px', right: '0px', willChange: 'transform' }}>
																			<div className="aircall-iframe-wrap">
																				<h3 className="popover-header"><span className="close" onClick={(e) => {
																					e.preventDefault();
																					updateRingcentralModel(!ringcentralModal)
																				}}>×</span>Ringcentral  </h3>
																				<iframe style={{ border: 'none' }} id="rc-widget-adapter-frame" width="376" height="500" allow="microphone" src={`https://apps.ringcentral.com/integration/ringcentral-embeddable/1.8.7/app.html?disableLoginPopup=1&&clientId=${dealersettings?.ringcentralConfig?.client_id}&clientSecret=${dealersettings?.ringcentralConfig?.client_secret}&appServer=${dealersettings?.ringcentralConfig?.endpoint}&redirectUri=${window.location.origin}/redirect.html`}>
																				</iframe>
																			</div>
																		</div>
																	</div>
																	:
																	<></>
															}
															{
																justcallEnabled
																	?
																	<div className="aircall-nav-icon ml-4">
																		<a href="#" onClick={(e) => {
																			e.preventDefault();
																			//localStorage.removeItem('ringcentralLog');
																			updateJustcallModel(!justcallModal)
																		}}>
																			<img src={_images.justcallIcon} width="25" />

																		</a>
																		{
																			justcallModal ? (<div className={"overlay phoneactive"} onClick={(e) => {
																				//localStorage.removeItem('ringcentralLog');
																				updateJustcallModel(!justcallModal)
																			}}> </div>) : (<></>)
																		}
																		<div
																			className={`aircall-drop-bg ${justcallModal ? '' : 'hide'}`}
																			style={{ position: 'absolute', transform: 'translate3d(0px, 48px, 0px)', top: '0px', right: '0px', willChange: 'transform' }}>
																			<div className="aircall-iframe-wrap">
																				<h3 className="popover-header"><span className="close" onClick={(e) => {
																					e.preventDefault();
																					updateJustcallModel(!justcallModal)
																				}}>×</span>Justcall  </h3>
																				<iframe style={{ border: 'none' }} id="justcall-widget-phone" width="376" height="610" allow="microphone" src={`https://app.justcall.io/dialer`}>
																				</iframe>
																			</div>
																		</div>
																	</div>
																	:
																	<></>
															}
															{
																frontdeskEnabled && !_.includes(['oem', 'tradeinPro', 'service', 'wholesaler', 'tvModule', 'reports', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], localStorage.defaultModule)
																	?
																	<div className="switch-nav-icon ml-4"
																		onClick={e => {
																			e.preventDefault();
																			//if (clientModule === 'enquiry')
																			this.handleUsersShow();
																		}}>
																		<div className="q-count">
																			<div className={`q-ico  q-${queueNum > 0 ? 'online' : 'offline'}`}>
																				<img src={_images.queueicon} width="15" height="15" />
																			</div>
																			<span className="q-num">{queueNum > 0 ? ((queueNum < 10 ? '0' : '') + queueNum) : '--'}</span> </div>
																	</div>
																	:
																	<></>
															}

															{
																localStorage.defaultModule === 'service' && !_.isEmpty(dealersettings.workingServiceID)
																	?
																	<>
																		<div className='notification-icon ml-4'>
																			<a
																				href='#'
																				title={'Job Work In Progress'}
																				className='notification-btn'
																				onClick={e => {
																					e.preventDefault();

																					Swal.fire({
																						title: CommonHelper.showLocale(this.props, 'Job timer is active'),
																						icon: 'info',
																						showCancelButton: true,
																						confirmButtonText: CommonHelper.showLocale(this.props, 'View'),
																						cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
																						allowOutsideClick: false,
																						allowEscapeKey: false
																					}).then(async (result) => {
																						if (result.value) {
																							this.props.history.push("/service/details/" + dealersettings.workingServiceID);
																						}
																					});
																				}}
																			>

																				<i className="ico icon-timer icon-timer-workinprogress"></i>
																			</a>
																		</div>
																	</>
																	:
																	<></>
															}


															<div className='notification-icon ml-4'>
																<a
																	href='#'
																	title={'Notifications'}
																	className='notification-btn'
																	onClick={e => {
																		e.preventDefault();
																		this.setState({
																			clsActive: 'active',
																		});
																	}}
																>
																	<span className='badge badge-pill badge-notification'>
																		{notificationCount > 0
																			? notificationCount
																			: ''}
																	</span>
																	<i className="ico icon-notification"></i>
																</a>
															</div>
															{
																messageMediaEnabled || smsHubEnabled || podiumEnabled || ringcentralEnabled || justcallEnabled
																	?
																	<div className="sms-icon ml-4">
																		<a
																			href='#'
																			title={'SMS Notifications'}
																			onClick={e => {
																				e.preventDefault();
																				this.setState({
																					smsClsActive: 'active',
																				});
																			}}
																		>
																			<span className='badge badge-pill badge-sms'>
																				{smsNotificationCount > 0
																					? smsNotificationCount
																					: ''}
																			</span>
																			<i className='ico icon-sms'></i>
																		</a>
																	</div>
																	:
																	<></>
															}

															{
																Boolean(dealersettings.wholesalerDashboard) ? (<></>) : (<div className='chat-icon ml-4'>
																	<a
																		href='#'
																		title={'Chat'}
																		className='notification-btn'
																		id='chat-icon-div'
																		onClick={e => {
																			e.preventDefault();
																			localStorage.removeItem('showChat');
																			this.setState({
																				chatClsActive: 'active',
																			});
																		}}
																	>
																		<span className='badge badge-pill badge-notification'>
																			{!isEmpty(unReadChatCounts) && unReadChatCounts.total && unReadChatCounts.total > 0 ? unReadChatCounts.total : ''}
																		</span>

																		<i className='ico icon-chat'></i>
																	</a>
																</div>)
															}

															{/*
                                                                clientModule !== 'enquiry' ? (
                                                                    <>

                                                                    </>
                                                                ) : (
                                                                        <>
                                                                            <div className="q-count notification-icon ml-4" onClick={this.handleUsersShow}>
                                                                                <div className={`q-ico ${queueNum > 0 ? 'q-online' : 'q-offline'}`}>
                                                                                    <i className="ico icon-Contacts"></i>
                                                                                </div>
                                                                                <span className="q-num" id="spnQueueOrder"> {queueNum > 0 ? ((queueNum < 10 ? '0' : '') + queueNum) : '--'}</span>
                                                                            </div>
                                                                        </>
                                                                    )*/}

															<ul className='profile-drop'>
																<Dropdown
																	as='li'
																	className='nav-item ml-3 profile'
																	alignRight
																>
																	{/* <div className={'user-profile-wrap'}>
																		<span
																			className={`badge badge-pill badge-que-${queueNum > 0 ? 'online' : 'offline'
																				} cursor-pointer`}
																			onClick={e => {
																				e.preventDefault();
																				//if (clientModule === 'enquiry')
																				this.handleUsersShow();
																			}}
																		>
																			{queueNum > 0 ? queueNum : ` `}
																		</span>
																	</div> */}
																	<Dropdown.Toggle
																		as={CustomToggle}
																		className='nav-link dropdown-toggle user-profile'
																	>


																		<img src={CommonHelper.getUserImagebyId(clientUsers, dealersettings.id)} alt='User' className="rounded-circle img-object-fit" />

																		<div className='user-name'>
																			{dealersettings ? (
																				<>
																					<div>{CommonHelper.showLimitedChar(dealersettings.name, 20)}</div>
																					<div className='dealership'>
																						{
																							(clientModule === 'oem' && (dealersettings.level === 'oem' || dealersettings.level === 'region' || dealersettings.level === 'group'))
																								?
																								<>{CommonHelper.showLimitedChar(dealersettings.oemName ? dealersettings.oemName : dealersettings.roleName, 25)}</>
																								: ((clientModule === 'tradeinPro' && tipClients && tipClients.length > 1) || clientModule?.includes('eventsPro') || clientModule?.includes('fleetPro')) ?
																									<>{CommonHelper.showLimitedChar(dealersettings.oemName || dealersettings.client.name, 25)}</>
																									:
																									<>{CommonHelper.showLimitedChar(dealersettings.client.name, 25)}</>
																						}

																					</div>
																				</>
																			) : (
																				<>
																					<div></div>
																					<div className='dealership'></div>
																				</>
																			)}
																		</div>
																	</Dropdown.Toggle>

																	<Dropdown.Menu
																		as={CustomMenu}
																		className='rm-pointers dropdown-menu-lg dropdown-menu-right'
																		ChildClass='header-dropdown'
																		xplacement='bottom-end'
																	>
																		{dealersettings &&
																			dealersettings.allClients &&
																			dealersettings.allClients.length > 1 &&
																			!clientModule?.includes('oem') ? (
																			<>
																				<DropdownButton
																					drop='left'
																					title={
																						<>
																							<i className='ico icon-switch'> </i>
																							<Translate text={'Switch Dealer'} />
																						</>
																					}
																					className='btn-switch dd-dealer'
																				>
																					<div className="quick-submenu-search">
																						<div className="input-group ">
																							<input placeholder="search..." aria-label="Search" value={this.state.searchDealer} onChange={(e) => {
																								this.setState({ searchDealer: e.target.value })
																							}} />
																							<div className="input-group-append input-search-clear">
																								{
																									this.state.searchDealer && this.state.searchDealer.length > 0
																										?
																										<span className="input-search-clear-icon"
																											title="Clear"
																											onClick={(e) => {
																												e.preventDefault();
																												this.setState({ searchDealer: '' })
																											}}
																										>
																											<i style={{ fontSize: '9px', cursor: 'pointer' }} className="ico icon-remove"></i>
																										</span>
																										:
																										<>
																										</>
																								}
																							</div>
																						</div>
																					</div>
																					{_.orderBy(dealersettings.allClients, 'sortOrder', 'asc').filter(a => this.state.searchDealer ? a.name.toLowerCase().includes(this.state.searchDealer.toLowerCase()) : true).map(
																						(client, index) => {
																							return (
																								<Dropdown.Item
																									key={index}
																									id={client.id}
																									disabled={
																										client.id ===
																											dealersettings.client.id
																											? true
																											: false
																									}
																									onClick={e => {
																										e.preventDefault();
																										CommonHelper.clearlocalFilter();
																										handleClientChange(client.id);
																									}}
																								>
																									{client.id ===
																										dealersettings.client.id ? (
																										<div className='pipeline-check'>
																											{' '}
																											<i className='ico icon-check'>
																												{' '}
																											</i>
																										</div>
																									) : (
																										<></>
																									)}
																									{client.name}
																									{
																										client.suspended === true ? (
																											<div className="badge badge-pill badge-suspended ml-2">Suspended</div>
																										) : (<></>)
																									}
																								</Dropdown.Item>
																							);
																						},
																					)}
																				</DropdownButton>
																				<Dropdown.Divider />
																			</>
																		) : (
																			<></>
																		)}
																		{modules && modules.length > 1 ? (
																			<>
																				<DropdownButton
																					drop='left'
																					title={
																						<>
																							<i className='ico icon-settings-modules'>
																								{' '}
																							</i>
																							<Translate text={'Switch Modules'} />
																						</>
																					}
																					className='btn-switch'
																				>
																					{_.orderBy(modules, ["sortOrder"], ["asc"]).map((module, index) => {
																						let _moduleData = AllModules.find(item => item.module === module.name)
																						return (
																							<Dropdown.Item
																								key={index}
																								id={`module-${module.name}`}
																								disabled={module.default}
																								onClick={e => {
																									e.preventDefault();
																									localStorage.setItem('defaultModule', module.name);
																									handleModuleChange(
																										module.name,
																										_moduleData.route,
																									);

																									resetnavbar();
																								}}
																							>
																								{module.default === true ? (
																									<div className='pipeline-check'>
																										{' '}
																										<i className='ico icon-check'>
																											{' '}
																										</i>
																									</div>
																								) : (
																									<></>
																								)}
																								<i
																									className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, _moduleData.class, true, _moduleData.icoVehicle)}
																								>
																									{' '}
																								</i>
																								{
																									_moduleData.name
																								}
																							</Dropdown.Item>
																						);
																					})}
																				</DropdownButton>
																				<Dropdown.Divider />
																			</>
																		) : (
																			<></>
																		)}
																		{dealersettings &&
																			dealersettings.multiOEM &&
																			dealersettings.multiOEM.length > 1 ? (
																			<>
																				<DropdownButton
																					drop='left'
																					title={
																						<>
																							<i className='ico icon-settings-sites'> </i>
																							<Translate text={'Switch OEM'} />
																						</>
																					}
																					className='btn-switch'
																				>
																					{dealersettings.multiOEM.map(
																						(oem, index) => {
																							return (
																								<Dropdown.Item
																									key={index}
																									id={oem.id}
																									disabled={
																										oem.id ===
																											dealersettings.oemID
																											? true
																											: false
																									}
																									onClick={e => {
																										e.preventDefault();
																										handleOEMChange(oem);
																									}}
																								>
																									{oem.id ===
																										dealersettings.oemID ? (
																										<div className='pipeline-check'>
																											{' '}
																											<i className='ico icon-check'>
																												{' '}
																											</i>
																										</div>
																									) : (
																										<></>
																									)}
																									{oem.name}
																								</Dropdown.Item>
																							);
																						},
																					)}
																				</DropdownButton>
																				<Dropdown.Divider />
																			</>
																		) : (
																			<></>
																		)}
																		{
																			dealersettings.showSwitchOEM === true ? (
																				<>
																					<Dropdown.Item
																						eventKey='7'
																						onClick={() => {
																							localStorage.removeItem('oemswitchclient')
																							localStorage.setItem('defaultModule', 'oem');
																							//this.props.history.push("/oem/dashboard")
																							updateDealerSettings('showSwitchOEM', false);
																							if (dealersettings
																								&& dealersettings.allClients
																								&& dealersettings.allClients.some(a => a.settingsID === dealersettings.oemID)) {
																								handleClientChange(dealersettings.allClients.filter(a => a.settingsID === dealersettings.oemID)[0].id);
																							}
																							else {
																								handleClientChange(dealersettings.client.id);
																							}
																							resetnavbar();
																							this.props.history.push('/');
																							// handleModuleChange(
																							// 	'oem',
																							// 	AllModules.find(item => item.module === 'oem').route,
																							// );
																						}}
																					>
																						<i className='ico icon-locations'></i>
																						<Translate text={`Switch to ${dealersettings.level === 'region' ? 'Region' : dealersettings.level === 'group' ? 'Group' : 'OEM'} Dashboard`} />
																					</Dropdown.Item>
																					<Dropdown.Divider />
																				</>
																			) : (<></>)
																		}
																		{(accessToTradeinHistory || accessToTradeinHistoryService) && _.includes(['service', 'enquiry'], localStorage.defaultModule)
																			? (
																				<>
																					<Dropdown.Item
																						eventKey='1'
																						as={Link}
																						to='/tradein/history'
																					>
																						<i className='ico icon-tradein'></i>
																						<Translate text={'tradeinhistory'} />
																					</Dropdown.Item>
																					<Dropdown.Divider />
																				</>
																			) : (<></>)
																		}
																		{accessToPerformanceReview && performanceReviewEnabled
																			&& !_.includes(['oem', 'tradeinPro', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], localStorage.defaultModule)
																			? (
																				<>
																					<Dropdown.Item
																						eventKey='1'
																						as={Link}
																						to='/performance/review'
																					>
																						<i className='ico icon-chart-box'></i>
																						<Translate text={'Performance Review'} />
																					</Dropdown.Item>
																					<Dropdown.Divider />
																				</>
																			) : (<></>)
																		}
																		{
																			accessToRoster && rosterEnabled
																				&& !_.includes(['oem', 'tradeinPro', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], localStorage.defaultModule)
																				? (
																					<>
																						<Dropdown.Item
																							eventKey='42'
																							as={Link}
																							to={'/roster'}
																						>
																							<i className='ico icon-Calendar'></i>
																							<Translate text={'Roster'} />
																						</Dropdown.Item>
																						<Dropdown.Divider />
																					</>
																				) : (<></>)
																		}
																		{((!_.isEmpty(_mailchimp) && _mailchimp.active && _mailchimp.enabled)
																			|| (!_.isEmpty(_mailup) && _mailup.active && _mailup.enabled)
																			|| (!_.isEmpty(_mailjet) && _mailjet.active && _mailjet.enabled)
																			|| (!_.isEmpty(_mailjetModule) && _mailjetModule.active && _mailjetModule.enabled)
																			|| (!_.isEmpty(_vision6) && _vision6.active && _vision6.enabled)
																			|| (!_.isEmpty(_messagemedia) && _messagemedia.active && _messagemedia.enabled)
																			|| (!_.isEmpty(_smshub) && _smshub.active && _smshub.enabled)) && (
																				<>
																					<DropdownButton
																						drop='left'
																						title={
																							<>
																								<i className='ico icon-campaign'>
																									{' '}
																								</i>
																								<Translate text={'Campaigns'} />
																							</>
																						}
																						className='btn-switch'
																					>
																						{((!_.isEmpty(_mailchimp) && _mailchimp.active && _mailchimp.enabled)
																							|| (!_.isEmpty(_vision6) && _vision6.active && _vision6.enabled)
																							|| (!_.isEmpty(_mailup) && _mailup.active && _mailup.enabled)
																							|| (!_.isEmpty(_mailjet) && _mailjet.active && _mailjet.enabled)
																							|| (!_.isEmpty(_mailjetModule) && _mailjetModule.active && _mailjetModule.enabled)
																						) && (
																								<Dropdown.Item
																									eventKey='1'
																									as={Link}
																									to='/campaigns'
																								>
																									<i className='ico icon-Mail'></i>
																									<Translate text={'Email Campaigns'} />
																								</Dropdown.Item>
																							)}
																						{messageMediaEnabled || smsHubEnabled ? (
																							<Dropdown.Item
																								eventKey='2'
																								as={Link}
																								to={messageMediaEnabled ? '/bulksms' : smsHubEnabled ? '/smscampaign' : '/bulksmspodium'}
																							>
																								<i className='ico icon-message'></i>
																								<Translate text={'SMS Campaigns'} />
																							</Dropdown.Item>
																						) : (<></>)}
																					</DropdownButton>
																					<Dropdown.Divider />
																				</>
																			)}
																		{ppsrEnabled && !_.includes(['oem', 'tvModule', 'reports', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], clientModule) ? (
																			<>
																				<Dropdown.Item
																					eventKey='1'
																					as={Link}
																					to='/ppsr/list'
																				>
																					<i className='ico icon-ppsr-logo'></i>
																					<Translate text={'PPSR'} />
																				</Dropdown.Item>
																				<Dropdown.Divider />
																			</>
																		) : (<></>)}
																		{showFormFeedback && localStorage.defaultModule !== 'oem' ? (
																			<>
																				<Dropdown.Item
																					eventKey='1'
																					as={Link}
																					to='/feedbackforms'
																				>
																					<i className='ico icon-feedback'></i>
																					<Translate text={'Feedbacks'} />
																				</Dropdown.Item>
																				<Dropdown.Divider />
																			</>
																		) : (<></>)}
																		{showTvScheduler && tvModuleEnabled
																			&& !_.includes(['oem', 'tradeinPro', 'service', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro'], localStorage.defaultModule)
																			? (
																				<>
																					<Dropdown.Item
																						eventKey='1'
																						as={Link}
																						to='/schedule/contact'
																					>
																						<i className='ico icon-monitor'></i>
																						<Translate text={'TV Scheduler'} />
																					</Dropdown.Item>
																					<Dropdown.Divider />
																				</>
																			) : (<></>)}
																		<Dropdown.Item
																			eventKey='1'
																			as={Link}
																			to='/settings/profile'
																		>
																			<i className='ico icon-profile'></i>
																			<Translate text={'My Account'} />
																		</Dropdown.Item>
																		<Dropdown.Divider />
																		{
																			!_.isEmpty(dealersettings)
																				&& !_.isEmpty(dealersettings.rolePermissions)
																				&& !_.isEmpty(dealersettings.rolePermissions.admin)
																				&& !_.isEmpty(Object.keys(dealersettings.rolePermissions.admin).filter(a => a !== 'oemDashboard').filter(function (id) {
																					return dealersettings.rolePermissions.admin[id]
																				})[0]) ? (<><Dropdown.Item
																					eventKey='2'
																					as={Link}
																					to={_.find(SettingsRoutes, {
																						module: _.filter(settingmenusort, (v) => _.indexOf(Object.keys(dealersettings.rolePermissions.admin).filter(function (id) {
																							return dealersettings.rolePermissions.admin[id]
																						}), v) >= 0)[0]
																					}) ? _.find(SettingsRoutes, {
																						module: _.filter(settingmenusort, (v) => _.indexOf(Object.keys(dealersettings.rolePermissions.admin).filter(function (id) {
																							return dealersettings.rolePermissions.admin[id]
																						}), v) >= 0)[0]
																					}).route : 'settings/profile'}
																				>
																					<i className='ico icon-settings'></i>
																					<Translate text={'settings'} />
																				</Dropdown.Item>
																					<Dropdown.Divider />
																				</>) : (<></>)
																		}

																		{/* <Dropdown.Item
																			eventKey='42'
																			as={Link}
																			to={'/acquisition'}
																		>
																			<i className='ico icon-settings'></i>
																			<Translate text={'Acquisition'} />
																		</Dropdown.Item>
																		<Dropdown.Divider /> */}

																		{dealersettings.client
																			&& dealersettings.client.clientSettings
																			&& dealersettings.client.clientSettings.service
																			&& dealersettings.client.clientSettings.service.vsrURL
																			&& accessVSR && (
																				<>
																					<Dropdown.Header className='nav-item-header nav-item'>
																						<Translate text={'VSR Access'} upperCase={true} />
																					</Dropdown.Header>
																					<Dropdown.Item
																						eventKey='11'
																						onClick={async () => {
																							this.setState({
																								vsrModal: {
																									show: true,
																									title: 'VSR Dashboard',
																									url: dealersettings.client.clientSettings.service.vsrURL
																								}
																							})
																						}}
																					>
																						<i className='ico icon-settings-sites'></i>
																						<Translate text={'VSR Dashboard'} />
																					</Dropdown.Item>
																					<Dropdown.Divider />
																				</>
																			)}
																		<Dropdown.Header className='nav-item-header nav-item'>
																			<Translate text={'App Download'} upperCase={true} />
																		</Dropdown.Header>
																		<Dropdown.Item
																			eventKey='8'
																			onClick={(e) => { e.preventDefault(); this.handleDownloadApp() }}
																		>
																			<i className='ico icon-app-device'> </i>
																			<Translate text={'Download Mobile App'} />
																		</Dropdown.Item>
																		<Dropdown.Divider />
																		{/*	<Dropdown.Header className='nav-item-header nav-item'>
																			<Translate text={'Subscription'} />
																		</Dropdown.Header>
																		<Dropdown.Item eventKey='4'>
																			<i className='ico icon-billing'></i>
																			<Translate text={'Add Billing'} />
																		</Dropdown.Item>
																		<Dropdown.Item eventKey='5'>
																			<i className='ico icon-plan'></i><Translate text={'Manage Subscription'} />
																		</Dropdown.Item> 
																		<Dropdown.Divider />*/}
																		<Dropdown.Item
																			eventKey='6'
																			onClick={(e) => { e.preventDefault(); handleSleepModeOpen(); }}
																		>
																			<i className='ico icon-sleep-mode'></i>
																			<Translate text={'Sleep Mode'} />
																		</Dropdown.Item>
																		<Dropdown.Divider />
																		{dealersettings.superAdmin === true && (
																			<>
																				<Dropdown.Header className='nav-item-header nav-item'>
																					<Translate text={'Admin Access'} upperCase={true} />
																				</Dropdown.Header>
																				<Dropdown.Item
																					eventKey='11'
																					onClick={async () => {
																						const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
																						await userRef.set({
																							arrayClientID: null,
																							roleID: null,
																							settingsID: null
																						}, { merge: true })
																						this.props.setUser({
																							arrayClientID: null,
																							roleID: null,
																							settingsID: null
																						})
																						const dashboardRef = await window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`).get();
																						if (dashboardRef.exists) {
																							window.firebase.firestore().doc(`users/${localStorage.uid}/adminDashboardSettings/${dealersettings.oemID}`).set({
																								...dashboardRef.data()
																							}, { merge: true });
																						}
																						localStorage.removeItem(localStorage.uid);
																						localStorage.setItem('defaultModule', 'enquiry')
																						localStorage.removeItem('brandTheme')
																						this.props.history.push('/admin/dealers')

																						// window.location.href = window.location.origin + '/admin/dealers'
																					}}
																				>
																					<i className='ico icon-settings-sites'></i>
																					<Translate text={'Switch to Admin Panel'} />
																				</Dropdown.Item>
																				<Dropdown.Divider />
																			</>
																		)}
																		<Dropdown.Item
																			eventKey='7'
																			onClick={(e) => { e.preventDefault(); this.handleLogout(); }}
																		>
																			<i className='ico icon-logout'></i>
																			<Translate text={'logout'} />
																		</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</ul>
														</div>
													</div>
												</nav>

												<>
													<PopUpModal show={showEnquiryModal.show}>
														<EnquiryModal
															show={showEnquiryModal.show}
															handleClose={this.handleEnquiryClose}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
															title={showEnquiryModal.title}
															enquiryid={showEnquiryModal.enqID}
															isNewEnquiry={showEnquiryModal.isNewEnquiry}
															handleRouteChange={handleRouteChange}
															currentClient={currentClient}
															resetnavbar={resetnavbar}
															updatenavbar={updatenavbar}
															handleRouteRemove={handleRouteRemove}
															updateDealerSettings={updateDealerSettings}
															clientModule={clientModule}

														></EnquiryModal>
													</PopUpModal>

													<PopUpModal show={showContact.show}>
														<AddContact
															show={showContact.show}
															selectedClientID={showContact.clientID}
															handleClose={this.handleContactClose}
															title={'addContact'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															isRedirectToProfile={true}
															history={this.props.history}
															isFromLayout={true}
														></AddContact>
													</PopUpModal>

													<PopUpModal show={this.state.showCompany}>
														<AddCompany
															show={this.state.showCompany}
															handleClose={this.handleCompanyClose}
															docID={this.state.companyDocID ? this.state.companyDocID : ''}
															title={this.state.companyTitle ? this.state.companyTitle : 'Add New Company'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
														></AddCompany>
													</PopUpModal>

													<PopUpModal show={this.state.showEvent}>
														<AddEvent
															show={this.state.showEvent}
															clsActive='overlay-modal active'
															handleClose={this.handleEventClose}
															title={'event'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
														>
														</AddEvent>
													</PopUpModal>
													<PopUpModal show={this.state.showCallLog}>
														<AddCallLog
															show={this.state.showCallLog}
															handleClose={this.handleLogClose}
															title={'addCallLog'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
														></AddCallLog>
													</PopUpModal>
													<PopUpModal show={this.state.showActivity}>
														<AddActivity
															show={this.state.showActivity}
															handleClose={this.handleActivityClose}
															title={this.state.editActivity.title ? this.state.editActivity.title : 'Add New Activity'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
															isNewActivity={this.state.editActivity.isEditActivity ? false : true}
															stock={null}
															enquiry={this.state.editActivity.enquiryObj}
															contact={this.state.editActivity.contactObj}
															activityLog={this.state.editActivity.activityObj}
															// isNewEnquiry={props.isNewEnquiry}
															handleDelete={this.handleActivityModelDelete}
															// isNewEnquiry={false}
															isFromLayout={true}
														></AddActivity>
													</PopUpModal>
													<PopUpModal show={this.state.showScheduleActivityModel.show}>
														<AddActivity
															show={this.state.showScheduleActivityModel.show}
															handleClose={this.handleScheduledActivityModelClose}
															handleDelete={this.handleActivityModelDelete}
															title={'Add New Activity'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															activityLog={this.state.showScheduleActivityModel.activityLog}
															enquiry={this.state.showScheduleActivityModel.activityLog?.enquiry ? this.state.showScheduleActivityModel.activityLog?.enquiry : null}
															contact={this.state.showScheduleActivityModel.activityLog?.contact ? this.state.showScheduleActivityModel.activityLog?.contact : null}
															stock={null}
															clsActive='overlay-modal active'
															isNewActivity={true}
															// activeTab={activeTab}
															isScheduleActivity={true}
															isFromLayout={true}
														>
														</AddActivity>
													</PopUpModal>
													<PopUpModal show={this.state.showCafe}>
														<AddCafe
															show={this.state.showCafe}
															handleClose={this.handleCafeClose}
															title={'orderCafe'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															enquiryID={null}
															contact={null}
															showHistory={true}
														></AddCafe>
													</PopUpModal>
													<SidePanel
														clsActive={clsActive}
														clsName={clsName}
														sidepanelClose={this.sidepanelClose}
														title='Notifications'
														count={userNotifications.length}
													>
														<Notification
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															currentClient={currentClient}
															handleNotifyClick={handleNotifyClick}
															handleNotifySwitchDealer={handleNotifySwitchDealer}
															handleEnquirySwitchDealer={handleEnquirySwitchDealer}
															sidepanelClose={this.sidepanelClose}
															notifyClose={notifyClose}
															handlenotifyClose={handlenotifyClose}
															isSMSNotify={false}
														/>
													</SidePanel>

													<SidePanel
														clsActive={smsClsActive}
														clsName={clsName}
														sidepanelClose={this.smsSidepanelClose}
														title='SMS Notifications'
														isSMSNotify={true}
														count={smsNotifications.length}
													>
														<Notification
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															currentClient={currentClient}
															handleNotifyClick={handleNotifyClick}
															handleNotifySwitchDealer={handleNotifySwitchDealer}
															handleEnquirySwitchDealer={handleEnquirySwitchDealer}
															sidepanelClose={this.smsSidepanelClose}
															notifyClose={notifyClose}
															handlenotifyClose={handlenotifyClose}
															isSMSNotify={true}
														/>
													</SidePanel>
													<PopUpModal show={chatClsActive === 'active'}>
														<ChatSidePanel
															sidepanelClose={this.chatSidepanelClose}
															clsActive={chatClsActive}
															clsName={chatClsName}
															setUnreadCounts={handleSetCounts}
															shouldCalculateCounts={true}
															unreadCounts={unReadChatCounts}
															title='Chat'
														/>
													</PopUpModal>
													<PopUpModal show={this.state.showActiveUsers}>
														<ActiveUsers
															show={this.state.showActiveUsers}
															handleClose={this.handleUsersClose}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															queueNum={queueNum}
														></ActiveUsers>
													</PopUpModal>

													<PopUpModal show={this.state.showStock.show}>
														<StockHistory
															docID={this.state.showStock.stockID}
															show={this.state.showStock.show}
															clsActive='overlay-modal active'
															handleClose={this.handleCloseStock}
															title={'stockHistory'}
															dealersettings={dealersettings}
															//updateDealerSettings={props.updateDealerSettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															isFromLayout={true}
														>
														</StockHistory>
													</PopUpModal>

													<PopUpModal show={this.state.showAttendee.show}>
														<AttendeeInfo
															{...this.props}
															attendeeid={this.state.showAttendee.docID}
															show={this.state.showAttendee.show}
															clsActive='overlay-modal active'
															handleClose={this.handleAttendeeClose}
															title={'attendee'}
														>
														</AttendeeInfo>
													</PopUpModal>

													<PopUpModal show={this.state.showCafeOrder.show}>
														<CafeOrder
															cafe={this.state.showCafeOrder.cafeObj}
															show={this.state.showCafeOrder.show}
															handleClose={this.handleCloseCafeOrder}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
														>
														</CafeOrder>
													</PopUpModal>

													<PopUpModal show={this.state.testDriveModal.show}>
														<AddTestDrive
															docID={this.state.testDriveModal.docID}
															show={this.state.testDriveModal.show}
															clsActive={this.state.testDriveModal.clsActive}
															handleClose={this.handleTestDriveClose}
															title={this.state.testDriveModal.title}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															enquiryID={this.state.testDriveModal.enquiryID}
															enquiry={this.state.testDriveModal.enquiry}
															contact={this.state.testDriveModal.contact}
															isDeivered={false}
															isFromLayout={true}
														>
														</AddTestDrive>
													</PopUpModal>

													<PopUpModal show={this.state.dealerModal.show}>
														<AddClient
															show={this.state.dealerModal.show}
															title={this.state.dealerModal.title ? this.state.dealerModal.title : ''}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															handleClose={this.handleDealerClose}
															client={this.state.dealerModal.dealer}
															brands={this.state.dealerModal.brands}
														// title='Add a dealer'
														/>
													</PopUpModal>

													<PopUpModal show={this.state.loanVehicleModal.show}>
														<AddFleet
															docID={this.state.loanVehicleModal.docID}
															show={this.state.loanVehicleModal.show}
															clsActive='overlay-modal active'
															stock={this.state.loanVehicleModal.stock}
															handleClose={this.handleLoanVehicleClose}
															title={'fleet'}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															isFromLayout={true}
														>
														</AddFleet>
													</PopUpModal>
													<PopUpModal show={this.state.inBoundModal.show}>
														<InBoundLeadConvert
															show={this.state.inBoundModal.show}
															docID={this.state.inBoundModal.docID}
															stage={null}
															handleModalClose={this.handleInBoundClose}
															pipeline={null}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
														/>
													</PopUpModal>
													<PopUpModal show={this.state.inBoundTIPModal.show}>
														<InBoundTIPConvert
															show={this.state.inBoundTIPModal.show}
															docID={this.state.inBoundTIPModal.docID}
															stage={null}
															handleModalClose={this.handleInBoundClose}
															pipeline={null}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
														/>
													</PopUpModal>
													<PopUpModal show={this.state.showTestDrive}>
														<NewTestDrive
															show={this.state.showTestDrive}
															handleClose={this.handleNewTestDriveClose}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
															isFromLayout={true}
															d365testdriveEnabled={d365testdriveEnabled}
														/>
													</PopUpModal>
													<PopUpModal show={this.state.showDownloadApp}>
														<DownloadApp
															show={this.state.showDownloadApp}
															handleClose={() => {
																this.setState({
																	showDownloadApp: false
																})
															}}
														/>
													</PopUpModal>

													<PopUpModal show={this.state.showUpgradeModal}>
														<UpgradeModal
															show={this.state.showUpgradeModal}
															handleClose={() => {
																this.setState({ showUpgradeModal: false })
															}}
														/>
													</PopUpModal>

													<PopUpModal show={dealerSelectModal.show}>
														<DealerSelect
															show={dealerSelectModal.show}
															origin={dealerSelectModal.origin}
															title={dealerSelectModal.title}
															handleClose={this.handleDealerSelectClose}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															showOwner={false}
														/>
													</PopUpModal>
													<PopUpModal show={this.state.vsrModal.show}>
														<VSRDashboard
															{...this.state.vsrModal}
															handleClose={() => {
																this.setState({ vsrModal: { show: false } })
															}}
														/>
													</PopUpModal>

													<PopUpModal show={appointmentModal.show}>
														<AppointmentList
															show={appointmentModal.show}
															status={appointmentModal.status}
															handleClose={this.handleAppointmentClose}
															dealersettings={dealersettings}
															clientUsers={clientUsers}
															groupUsers={groupUsers}
															history={this.props.history}
														/>
													</PopUpModal>
													<PopUpModal show={showReauthenticate.show}>
														<ReAuthenticate
															{...this.props}
															{...showReauthenticate}
															hideHeader={true}
															handleClose={(type, user) => {
																this.setState({
																	showReauthenticate: {
																		show: false,
																	}
																})
																if (user) {
																	this.setState({
																		showMFAEnableModel: {
																			show: true,
																			title: 'Enable Multi-factor authentication',
																			currentUser: user
																		}
																	})
																} else if (type) {
																	Swal.fire('You have already enrolled in multi-factor authentication.', '', 'info');
																}

															}}
														>
														</ReAuthenticate>
													</PopUpModal>
													<PopUpModal show={showMFAEnableModel.show}>
														<TwoFactorAuthModel
															{...this.props}
															{...showMFAEnableModel}
															handleClose={() => {
																this.setState({
																	showMFAEnableModel: {
																		show: false,
																	}
																})
															}}
														>
														</TwoFactorAuthModel>
													</PopUpModal>
												</>
											</>
										) : (
											<></>
										)}
									</>
								)}
							</LayoutConsumer>
						</>
					)}
				</RouteConsumer>

			</>
		);
	}
}

export default Navbar;
