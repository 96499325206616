export const attendeesFieldsVM = [
    {
        name: 'Title',
        value: 'title'
    },
    {
        name: 'First Name',
        value: 'firstName'
    },
    {
        name: 'Last Name',
        value: 'lastName'
    },
    {
        name: 'Middle Name',
        value: 'middleName'
    },
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
      {
        name: 'Phone or Email',
        value: 'phoneORemail'
    },
    {
        name: 'Profile Image',
        value: 'userImageURL'
    },
    {
        name: 'License No',
        value: 'licenseNo'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry'
    },
    {
        name: 'License Front',
        value: 'licenseFrontURL'
    },
    {
        name: 'License Back',
        value: 'licenseBackURL'
    },
    {
        name: 'License Type',
        value: 'licenseType'
    },
    {
        name: 'License State',
        value: 'licenseState'
    },
    {
        name: 'Date of Birth',
        value: 'dob'
    },
    {
        name: 'Gender',
        value: 'gender'
    },
    {
        name: 'Address',
        value: 'address'
    },
    {
        name: 'State',
        value: 'state'
    },
    {
        name: 'Area Code',
        value: 'areaCode'
    },
    {
        name: 'Country',
        value: 'country'
    },
    {
        name: 'Data Location',
        value: 'dataLocation'
    }
];

export const eventsFieldsVM = [
    {
        name: 'Title',
        value: 'title'
    },
    {
        name: 'Description',
        value: 'description'
    },
    {
        name: 'Event Image',
        value: 'imageURL'
    },
    {
        name: 'Event Start Date',
        value: 'startDate'
    },
    {
        name: 'Event End Date',
        value: 'endDate'
    },
    {
        name: 'Event Location',
        value: 'location'
    },
    {
        name: 'Event Remainder',
        value: 'remainder'
    }
]

export const allModules = [
    "attendees",
    "events",
    "pipeline",
    "status"
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]


export const enquiryStatus = [
    { active: true, value: 'draft', name: 'Draft' },
    { active: true, value: 'Published', name: 'Published' },
    { active: true, value: 'live', name: 'Live' },
    { active: true, value: 'closed', name: 'Closed' },
]

export const mandSettingsVM = {
    attendees: '',
    events: '',
    pipeline: [],
    status: []
}


export const moduleNameValue = [
    {
        name: 'Events',
        value: 'events'
    },
    {
        name: 'Attendees',
        value: 'attendees'
    }
]

