/** LIBRARIES */
import React, { PureComponent } from "react";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';



class InputMultipleText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const { placeholder, value, onChange, validateEmail, getLabel, className } = this.props;
    //('RENDER INPUT TEXT', name, value)
    return (
      <ReactMultiEmail
        placeholder={placeholder}
        className={className}
        emails={value}
        onChange={onChange}
        validateEmail={email => {
          return validateEmail ? isEmail(email) : true; // return boolean
        }}
        getLabel={getLabel}
      />
    );
  }
}

export default InputMultipleText;