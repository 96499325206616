/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import NumberFormat from 'react-number-format';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { default as _images } from "../../../images";
import { PopUpModal, PopoverListLoader } from '../../../components';
import AddFleet from '../../fleet/add'
import AddTestDrive from '../../testdrive/add'
/** VIEW-MODELS */
import { stockStatus, stockAvail } from '../../../services/enum'
import { stockBasicDetailsVM } from '../viewModel'


class StockLoanList extends Component {
    constructor(props) {
        super(props);
        this.ref = window.firebase.firestore().collection('stock');
        this.state = {
            filter: {
                iSearch: '',
                isAvailable: false
            },
            loading: true,
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            },
        };
    }

    static applyFilter = (listings, filter) => {
        const { iSearch, isAvailable } = filter
        let result = Object.assign([], listings)

        if (iSearch) {
            result = result.filter(item =>
                item.make.toLowerCase().startsWith(iSearch) ||
                item.model.toLowerCase().startsWith(iSearch) ||
                (item.regNo ? item.regNo.toLowerCase().startsWith(iSearch) : false) ||
                (item.vinNo ? item.vinNo.toLowerCase().startsWith(iSearch) : false) ||
                (item.extColor ? item.extColor.toLowerCase().startsWith(iSearch) : false) ||
                (item.saleType ? item.saleType.toLowerCase().startsWith(iSearch) : false) ||
                (item.year ? item.year.toLowerCase().startsWith(iSearch) : false) ||
                (item.stockNo ? item.stockNo.toLowerCase().startsWith(iSearch) : false))
        }
        if (isAvailable)
            result = result.filter(item => item.status === stockStatus.AVAILABLE)

        return result
    }


    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    handleChange = (prop, value) => {
        let state = this.state.filter;
        state[prop] = value;
        this.setState({ filter: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = this.state.filter;
        state[name] = checked;
        this.setState({ filter: state });
    }

    handleLoanVehicleClose = () => {
        this.setState({
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            }
        })
    }

    handleTestDriveClose = () => {
        this.setState({
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            }
        })
    }

    handleSelectStock = (stockID, stock) => {
        const { isAvailStock } = this.props;
        if (isAvailStock) {
            if (stock.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stock.testDriveDetail)) {
                this.handleTestDriveStatusChange(stock.testDriveDetail, stock);
            }
            else if (stock.status === stockStatus.ONLOAN && !_.isEmpty(stock.fleetDetail)) {
                this.handleLoanStatusChange(stock.fleetDetail, stock);
            }
            else {
                this.props.handleClose(stockID, stock)
            }
        }
        else {
            this.props.handleClose(stockID, stock)
        }

    }

    handleTestDriveStatusChange = (objDetail, _stock) => {
        let _vehicleModel = !_.isEmpty(_stock) ? (_stock.make ? _stock.make : '') + ' ' + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '') : ''
        Swal.fire({
            title: `The ${_vehicleModel} is on testdrive`,
            text: CommonHelper.showLocale(this.props, 'doYouWantToReturnThisVehicleAnyway'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'yesReturn'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    testDriveModal:
                    {
                        show: true,
                        ID: objDetail.testdriveID,
                        title: 'testDrive',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock),
                        contact: (!_.isEmpty(objDetail.contact)) ? objDetail.contact : null
                    }
                });
            }
        })
    }

    handleLoanStatusChange = (objDetail, _stock) => {
        let _vehicleModel = !_.isEmpty(_stock) ? (_stock.make ? _stock.make : '') + ' ' + (_stock.model ? _stock.model : '') + (_stock.year ? ', ' + _stock.year : '') : ''
        Swal.fire({
            title: `The ${_vehicleModel} is already on loan`,
            text: CommonHelper.showLocale(this.props, 'doYouWantToReturnThisVehicleAnyway'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'yesReturn'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    fleetModal:
                    {
                        show: true,
                        ID: objDetail.fleetID,
                        title: 'loanVehicle',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock)
                    }
                });

            }
        })
    }

    render() {
        const { iSearch, isAvailable } = this.state.filter
        const { filter, loading, fleetModal, testDriveModal } = this.state;
        const { show, showAdd, clientUsers, dealersettings, groupUsers, serviceLoanStocks } = this.props;
        const filteredList = StockLoanList.applyFilter(
            serviceLoanStocks,
            filter
        )
        const divStyle = {
            height: '400px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="stockLoanList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'loanVehicles'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`vehicle-popover-head ${showAdd ? '' : 'pr-0'}`}>

                            {
                                showAdd
                                    ?
                                    <div className="vehicle-popover-add">
                                        <a href="#" className="mini-button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.handleAdd();
                                            }}> <i className="ico icon-add"></i>
                                        </a>
                                    </div>
                                    :
                                    <></>

                            }

                            <input
                                //className="form-control"
                                type="text"
                                id="isearch"
                                aria-label="Search"
                                placeholder="search..."
                                value={iSearch}
                                autoComplete="off"
                                onChange={event =>
                                    this.handleChange('iSearch', event.target.value)
                                }
                            />
                        </div>

                        <div className="popover-sub-head">
                            <div className="float-left popover-sub-head-count"><strong>{filteredList.length}</strong> <Translate text={'Vehicles'} /></div>
                            <div className="float-right">
                                <div className="checkbox icheck-success">
                                    <input
                                        type="checkbox"
                                        id="isAvailable"
                                        name="isAvailable"
                                        className="uncheck-activity"
                                        checked={isAvailable}
                                        onChange={(e) => {
                                            this.handleCheckChange(e, 'isAvailable')
                                        }} />
                                    <label htmlFor="isAvailable"><Translate text={'available'} /> </label>
                                </div>
                            </div>
                        </div>

                        <Suspense>
                            <div style={divStyle} >
                                {
                                    loading ? (<PopoverListLoader />) :
                                        filteredList.length > 0
                                            ?
                                            <>
                                                {filteredList.map(stock =>
                                                    <div key={stock.documentID} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleSelectStock(stock.documentID, stock)
                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width70">
                                                                        <div className="vehicle-stock-content">
                                                                            <div className="vehicle-stock-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={((stock.images && typeof stock.images === 'object' && Object.keys(stock.images).length > 0)) ? stock.images[0] : CommonHelper.showBrandLogo(dealersettings, stock.make)} className="img-object-fit-contain"  alt="" width="70" height="70" /> </a> </div>
                                                                            <div className="vehicle-stock-title">{stock.make + " " + stock.model + " " + stock.year}</div>
                                                                            <div className="vehicle-stock-titlesub"><strong><Translate text={'stockNo'} />:</strong> {stock.stockNo} <strong><Translate text={'vinNo'} />:</strong> {stock.vinNo} </div>
                                                                            <div className="vehicle-stock-titlesub"><strong><Translate text={'price'} />:</strong> {stock.price ? <NumberFormat value={stock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$ '} /> : ''} </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="divTableCell width30">
                                                                        <div className="vehicle-stock-status float-right">
                                                                            {stock.saleType ? <div className="badge badge-pill badge-white">{stock.saleType}</div> : <></>}
                                                                            {stock.status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stock.status, '', 'ml-1') : <></>}

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        {
                                                            stock.status && stock.status !== stockStatus.AVAILABLE && stock.statusModifiedBy && clientUsers //&& stock.enquiryID !== enquiryID
                                                                ?
                                                                <>
                                                                    <div className="vehicle-current-status pl-2 pr-2 mt-0 border-0">
                                                                        <div className="vehicle-status-alert alert-user" >
                                                                            <b className="alert-link">
                                                                                {/* {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()} */}
                                                                                {CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stock.status)?.toUpperCase()}
                                                                            </b>
                                                                            {' by '}
                                                                            {stock.statusModifiedBy && clientUsers.filter(e => e.id === stock.statusModifiedBy).length > 0 ? clientUsers.filter(m => m.id === stock.statusModifiedBy)[0].name + '.' : '--'}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'No vehicle found'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>
                    </Modal.Body>
                </Modal>

                <PopUpModal show={fleetModal.show}>
                    <AddFleet
                        docID={fleetModal.ID}
                        show={fleetModal.show}
                        clsActive='overlay-modal active'
                        stock={fleetModal.stock}
                        handleClose={this.handleLoanVehicleClose}
                        title={'fleet'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFleet>
                </PopUpModal>

                <PopUpModal show={testDriveModal.show}>
                    <AddTestDrive
                        docID={testDriveModal.ID}
                        show={testDriveModal.show}
                        clsActive='overlay-modal active'
                        handleClose={this.handleTestDriveClose}
                        title={'testDrive'}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isDeivered={false}
                        contact={null}
                    >
                    </AddTestDrive>
                </PopUpModal>
            </>
        );
    }

}

export default StockLoanList;