/** LIBRARIES */
import React from 'react'
import { Droppable } from "react-beautiful-dnd";
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
/** COMPONENTS */
import EnquiryCell from './enquirycell'

const EnquiryStages = ({ column, tasks, handleEnquiryFav, sidepanelOpen, stageLoading, stageCounts, clientUsers, groupUsers, pipeline, isFilterApplied, searchText, dealersettings }) => {

    return (
        <div className="pipeline-board" >
            <div className="pipeline-board-header sticky-board-head">
                <h2>{column.title} </h2>
                <div className="stage-counter-wrap">
                    <div className="float-left">{
                        stageCounts && stageCounts[column.id] && stageCounts[column.id]['total'] ? (
                            <h5>{stageCounts[column.id]['total']} {stageCounts[column.id]['total'] > 1 ? 'Appraisals' : 'Appraisal'} </h5>
                        ) : (
                                <h5>{tasks.length} {column.taskIds.length > 1 ? 'Appraisals' : 'Appraisal'} </h5>
                            )
                    } </div>
                    {/* {
                        (pipeline === 'LeadsBucket' || isFilterApplied === true || (searchText && searchText.length > 2)) ? (<></>) : (
                            <div className="float-right">
                                <div className="counter-stage-nav">
                                    <ul>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>Open {stageCounts && stageCounts[column.id] && stageCounts[column.id]['open'] ? `(${stageCounts[column.id]['open']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-open" >{stageCounts && stageCounts[column.id] && stageCounts[column.id]['open'] ? stageCounts[column.id]['open'] > 99 ? '99+' : stageCounts[column.id]['open'] : 0}</li>
                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>Won {stageCounts && stageCounts[column.id] && stageCounts[column.id]['won'] ? `(${stageCounts[column.id]['won']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-won" >{stageCounts && stageCounts[column.id] && stageCounts[column.id]['won'] ? stageCounts[column.id]['won'] > 99 ? '99+' : stageCounts[column.id]['won'] : 0}</li>

                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>Delivered {stageCounts && stageCounts[column.id] && stageCounts[column.id]['delivered'] ? `(${stageCounts[column.id]['delivered']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-delivered" >{stageCounts && stageCounts[column.id] && stageCounts[column.id]['delivered'] ? stageCounts[column.id]['delivered'] > 99 ? '99+' : stageCounts[column.id]['delivered'] : 0}</li>

                                        </OverlayTrigger>{' '}
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={
                                                <Tooltip>Lost {stageCounts && stageCounts[column.id] && stageCounts[column.id]['lost'] ? `(${stageCounts[column.id]['lost']})` : ''}</Tooltip>
                                            }
                                        >
                                            <li className="counter-stage-lost" >{stageCounts && stageCounts[column.id] && stageCounts[column.id]['lost'] ? stageCounts[column.id]['lost'] > 99 ? '99+' : stageCounts[column.id]['lost'] : 0}</li>

                                        </OverlayTrigger>{' '}

                                    </ul>
                                </div>

                            </div>
                        )
                    } */}


                </div>
            </div>
            <Droppable droppableId={column.id} >
                {(provided, snapshot) => (
                    <div className={"pipeline-item-wraper " + (snapshot.isDraggingOver ? "pipeline-dragging-Over" : "")}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {tasks.map((task, index) => (
                            <EnquiryCell
                                key={index}
                                index={index}
                                id={task.id}
                                isFav={task.isFav}
                                status={task.status}
                                stage={task.stage}
                                //origin={task.origin}
                                task={task}
                                handleEnquiryFav={handleEnquiryFav}
                                sidepanelOpen={sidepanelOpen}
                                clientUsers={clientUsers}
                                groupUsers={groupUsers}                               
                                dealersettings={dealersettings}
                            />
                        ))}
                        {
                            stageLoading && stageLoading === column.id ?
                                (<div style={{ textAlign: 'center' }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>)
                                : (<></>)
                        }
                        <span
                            style={{
                                display: "none"
                            }}
                        >
                            {provided.placeholder}
                        </span>
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default EnquiryStages