import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { pipelineCountVM } from '../../dashboard/viewModel';
import { PopUpModal } from '../../../components';
import InfoModal from './infoModal'
import CommonHelper from '../../../services/common'
const OEMCounts = (props) => {

	const [counts, setCounts] = useState(pipelineCountVM)
	const [countModal, setCountModal] = useState({
		title: '',
		type: '',
		show: false,
	})
	useEffect(() => {
		let countsByClients = []
		countsByClients = props.totalOEMCounts.filter(count => props.clientsToRender.some(client => client.documentID === count.clientID));

		let _dynStatus = {};
		if (props.enqStatus.length > 0) {
			props.enqStatus.filter(a => !Boolean(a.default)).forEach(rec => {
				_dynStatus = {
					..._dynStatus,
					[`month${rec.value}`]: _.sumBy(countsByClients,`month${rec.value}`) ? _.sumBy(countsByClients,`month${rec.value}`) : 0,
					[`today${rec.value}`]:  _.sumBy(countsByClients,`today${rec.value}`) ? _.sumBy(countsByClients,`today${rec.value}`) : 0,
				}
			})
		}
		
		setCounts({
			todayNew: _.sumBy(countsByClients,'todayNew') ? _.sumBy(countsByClients,'todayNew') : 0, 
			monthNew: _.sumBy(countsByClients,'monthNew') ? _.sumBy(countsByClients,'monthNew') : 0, 
			todayopen: _.sumBy(countsByClients,'todayOpen') ? _.sumBy(countsByClients,'todayOpen') : 0,  
			monthopen: _.sumBy(countsByClients,'monthOpen') ? _.sumBy(countsByClients,'monthOpen') : 0, 
			todaywon: _.sumBy(countsByClients,'todayWon') ? _.sumBy(countsByClients,'todayWon') : 0, 
			monthwon: _.sumBy(countsByClients,'monthWon') ? _.sumBy(countsByClients,'monthWon') : 0,
			todaylost: _.sumBy(countsByClients,'todayLost') ? _.sumBy(countsByClients,'todayLost') : 0, 
			monthlost: _.sumBy(countsByClients,'monthLost') ? _.sumBy(countsByClients,'monthLost') : 0, 
			todaydelivered: _.sumBy(countsByClients,'todayDelivered') ? _.sumBy(countsByClients,'todayDelivered') : 0, 
			monthdelivered: _.sumBy(countsByClients,'monthDelivered') ? _.sumBy(countsByClients,'monthDelivered') : 0, 
			todayTestdrive: _.sumBy(countsByClients,'todayTestdrive') ? _.sumBy(countsByClients,'todayTestdrive') : 0, 
			monthTestdrive: _.sumBy(countsByClients,'monthTestdrive') ? _.sumBy(countsByClients,'monthTestdrive') : 0,
			todayTradein: _.sumBy(countsByClients,'todayTradein') ? _.sumBy(countsByClients,'todayTradein') : 0, 
			monthTradein: _.sumBy(countsByClients,'monthTradein') ? _.sumBy(countsByClients,'monthTradein') : 0, 
			todayActivity: _.sumBy(countsByClients,'todayActivity') ? _.sumBy(countsByClients,'todayActivity') : 0,
			monthActivity: _.sumBy(countsByClients,'monthActivity') ? _.sumBy(countsByClients,'monthActivity') : 0, 
			overdueActivity: _.sumBy(countsByClients,'overdueActivity') ? _.sumBy(countsByClients,'overdueActivity') : 0,
			..._dynStatus
		})

	}, [props.totalOEMCounts, props.countsLoader, props.clientsToRender])

	const handleOpen = (title, type) => {
		setCountModal({
			title,
			type,
			show: true
		})
	}

	const handleClose = () => {
		setCountModal({
			title: '',
			type: '',
			show: false
		})
	}

	return (
		<>
			<div className='oem-rightbar-modules' style={{ minHeight: '500px' }}>
				{!props.countsLoader ? (
					<>
						<div className=' row '>
							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('New Leads', 'New'); }}>
									<div className='oem-module-icon'>
										<i className='ico icon-new-leads'></i>
									</div>
									<div className='oem-module-label'> New Leads</div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.todayNew}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.monthNew}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{
								props.enqStatus.length > 0 ?
									props.enqStatus.filter(a => a.value !== 'prospectLost').map((rec, index) => {
										return <div key={index} className='col-lg-6'>
											<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen(`${rec.name} Leads`, rec.default === true ? CommonHelper.autoCaps(rec.value) : rec.value); }}>
												<div className='oem-module-icon'>
													{
														rec.default === true ? <i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, `ico icon-${rec.value}${rec.value !== 'delivered' ? '-leads' : ''}`)}></i>
															: <i className='ico icon-show-leads' style={{ color: `${rec.color}` }}></i>
													}

												</div>
												<div className='oem-module-label'> {rec.name} Leads</div>

												<div className='oem-module-count-wraper'>
													<div className=' row'>
														<div className='col-lg-6'>
															<div className='oem-count-title'> Today</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link">{counts[`today${rec.value}`]}</a>
															</div>
														</div>
														<div className='col-lg-6 oem-count-border-left'>
															<div className='oem-count-title'> MTD</div>
															<div className='oem-count-value'>
																{' '}
																<a href="#" className="blue-link">{counts[`month${rec.value}`]}</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									}) : (
										<>
											<div className='col-lg-6'>
												<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Open Leads', '0pen'); }}>
													<div className='oem-module-icon'>
														<i className='ico icon-open-leads'></i>
													</div>
													<div className='oem-module-label'> Open Leads</div>

													<div className='oem-module-count-wraper'>
														<div className=' row'>
															<div className='col-lg-6'>
																<div className='oem-count-title'> Today</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.todayopen}</a>
																</div>
															</div>
															<div className='col-lg-6 oem-count-border-left'>
																<div className='oem-count-title'> MTD</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.monthopen}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-lg-6'>
												<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Won Leads', 'Won'); }}>
													<div className='oem-module-icon'>
														<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-won-leads')}></i>
													</div>
													<div className='oem-module-label'> Won Leads</div>

													<div className='oem-module-count-wraper'>
														<div className=' row'>
															<div className='col-lg-6'>
																<div className='oem-count-title'> Today</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link" >{counts.todaywon}</a>
																</div>
															</div>
															<div className='col-lg-6 oem-count-border-left'>
																<div className='oem-count-title'> MTD</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link" >{counts.monthwon}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-lg-6'>
												<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Lost Leads', 'Lost'); }}>
													<div className='oem-module-icon'>
														<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-lost-leads')}></i>
													</div>
													<div className='oem-module-label'> Lost Leads</div>

													<div className='oem-module-count-wraper'>
														<div className=' row'>
															<div className='col-lg-6'>
																<div className='oem-count-title'> Today</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.todaylost}</a>
																</div>
															</div>
															<div className='col-lg-6 oem-count-border-left'>
																<div className='oem-count-title'> MTD</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.monthlost}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='col-lg-6'>
												<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Delivered', 'Delivered'); }}>
													<div className='oem-module-icon'>
														<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-delivered')}></i>
													</div>
													<div className='oem-module-label'> Delivered </div>

													<div className='oem-module-count-wraper'>
														<div className=' row'>
															<div className='col-lg-6'>
																<div className='oem-count-title'> Today</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.todaydelivered}</a>
																</div>
															</div>
															<div className='col-lg-6 oem-count-border-left'>
																<div className='oem-count-title'> MTD</div>
																<div className='oem-count-value'>
																	{' '}
																	<a href="#" className="blue-link">{counts.monthdelivered}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									)


							}


							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Test Drive', 'Testdrive'); }}>
									<div className='oem-module-icon'>
										<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-oem-testdrive')}></i>
									</div>
									<div className='oem-module-label'> Test Drive </div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.todayTestdrive}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.monthTestdrive}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Trade-In', 'Tradein'); }}>
									<div className='oem-module-icon'>
										<i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, 'ico icon-oem-tradein')}></i>
									</div>
									<div className='oem-module-label'> Trade-In </div>

									<div className='oem-module-count-wraper'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.todayTradein}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.monthTradein}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Activities', 'Activity'); }}>
									<div className='oem-module-icon'>
										<i className='ico icon-activities '></i>
									</div>
									<div className='oem-module-label'> Activities </div>

									<div className='oem-module-count-wraper pt-2'>
										<div className=' row'>
											<div className='col-lg-6'>
												<div className='oem-count-title'> Today</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.todayActivity}</a>
												</div>
											</div>
											<div className='col-lg-6 oem-count-border-left'>
												<div className='oem-count-title'> MTD</div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.monthActivity}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-lg-6'>
								<div className='oem-module-loop oem-module-flex' onClick={(e) => { e.preventDefault(); handleOpen('Overdue Activities', 'overdueActivity'); }}>
									<div className='oem-module-icon'>
										<i className='ico icon-overdue-activities '></i>
									</div>
									<div className='oem-module-label'> Overdue Activities </div>

									<div className='oem-module-count-wraper pt-2'>
										<div className=' row'>
											<div className='col-lg-12'>
												<div className='oem-count-title'> Total </div>
												<div className='oem-count-value'>
													{' '}
													<a href="#" className="blue-link">{counts.overdueActivity}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="spinner-loader h-100" style={{ paddingTop: '280px' }}><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
					</>
				)}
			</div >
			<PopUpModal show={countModal.show}>
				<InfoModal
					show={countModal.show}
					handleClose={handleClose}
					title={countModal.title}
					type={countModal.type}
					counts={counts}
					clientsToRender={props.clientsToRender}
					totalOEMCounts={props.totalOEMCounts}
				/>
			</PopUpModal>
		</>
	);
};

export default OEMCounts;
