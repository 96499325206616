import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {  integrationFields } from './viewModel'
import { ContentOverlay, TableView, PopUpModal } from '../../components'
import AddIntegration from './addIntegration'


const Integrations = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth - 30, windowHeight: window.innerHeight - 123 });
    const [loader, setLoader] = useState(true)
    const [showIntegrationPopup, setShowModel] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [selectedIntegration, setSelectedIntegration] = useState(null)
    const [popHeader, setPopHeader] = useState('')
    const [integrations, setIntegrations] = useState([])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth - 30,
                windowHeight: window.innerHeight - 123,
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const integrationSnapshot = window.firebase.firestore().collection('integrations')
            .onSnapshot((querySnapshot) => {
                const integrationData = [];

                querySnapshot.forEach((doc) => {
                    integrationData.push({
                        ...doc.data(),
                        documentID: doc.id,
                    });
                });

                setIntegrations(integrationData);
                setLoader(false);
            });

        return () => {
            integrationSnapshot && integrationSnapshot();
        };
    }, []);

    const hadleEditIntegration = (data) => {
        setSelectedIntegration(data)
        setPopHeader('Edit Integration')
        setShowModel(true)
    }

    const handleAddIntegration = () => {
        setSelectedIntegration(null)
        setPopHeader('Add Integration')
        setShowModel(true)
    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            hadleEditIntegration(data)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const handleClientdetailsClick = (e, id, data) => {
        e.preventDefault();
        hadleEditIntegration(data)
    }

    const handleModelClose = () => {
        setShowModel(false)
    }

    const onSearchChange = (event) => {
        const text = event.target.value
        setSearchText(text)
    }

    const filterSearch = (name) => {
        let valToReturn = false
        const _searchText = searchText.toLowerCase()
        if (searchText && name) {
            if (name.toLowerCase().includes(_searchText.toLowerCase())) {
                valToReturn = true
            }

        } else {
            valToReturn = true
        }

        return valToReturn
    }


    return (
        <>
            <div className='container-fluid'>
                <div className="middle-wrapper">
                    <div className="filter-panel">
                        <div className="d-flex justify-content-between">
                            <div className="filter-search search-icon">
                                <div className='input-group template-search'>
                                    <input placeholder="Search" aria-label="Search"
                                        value={searchText} onChange={onSearchChange}
                                    />
                                    <div className='input-group-append input-search-clear'>
                                        {searchText.length > 0 && (
                                            <a href='#' className='input-search-clear-icon' style={{ top: '9px' }} onClick={() => setSearchText('')}>
                                                <i className='ico icon-remove'></i>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="filter-add-button">
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        handleAddIntegration()
                                    }}> <i className="ico icon-add"></i> Add Integration</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        {
                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                integrations.filter(({ name }) => filterSearch( name )).length > 0 ? (
                                    <div className="common-table">
                                        <TableView
                                            datalist={_.orderBy(integrations.filter(({ name }) => filterSearch( name )), ['name'], ['asc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={integrationFields}
                                            dynamicFields={integrationFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={handleClientdetailsClick}
                                            isSettings={false}
                                            isReorderable={false}
                                        />
                                    </div>
                                ) : (
                                    <div className="common-table">
                                        <div className="text-center p-5">
                                            <p>No Integration found.</p>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showIntegrationPopup}>
                <AddIntegration
                    show={showIntegrationPopup}
                    title={popHeader}
                    handleClose={handleModelClose}
                    integration={selectedIntegration}
                    integrations={integrations}
                />
            </PopUpModal>
        </>
    )
}

export default Integrations