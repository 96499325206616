/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2';
import { NavLink, Link } from 'react-router-dom';
//import ReactExport from 'react-data-export';
import moment from 'moment'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Filters from '../pipeline/filters'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {

        var dataArray = [];
        for (var i in props.stageCounts) {
            if (i !== 'Converted')
                dataArray.push(props.stageCounts[i]);
        }

        setCounts({
            total: _.sumBy(dataArray, "total")
        })

    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.ppsrFilter) && Object.keys(JSON.parse(localStorage.ppsrFilter)).length > 0) {
            let _ppsrFilter = JSON.parse(localStorage.ppsrFilter);
            let _filterValue = _ppsrFilter.value;

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setPipelineFilter(_ppsrFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.ppsrFilter])

    const accessToNewPPSR = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.accessToNewPPSR) ? true : false);
    return (
        <div className="filter-panel">

            {/* <div className="filter-switch">
                <NavLink to="/inbound" exact activeClassName={"switch-active"}>
                    <i className="ico icon-pipeline-1"></i>
                </NavLink>
                <NavLink to="/inbound/allocation" className="ml-1" exact activeClassName={"switch-active"}>
                    <i className="ico icon-list"></i>
                </NavLink>
            </div>

            <div className="tabber-nav float-left ml-2">
                <ul>
                    <li><a href="#" className={`${props.activeTab === 'unallocated' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleSetActiveTab('unallocated');
                        }}><Translate text={'unallocated'} />
                        {props.inboundCount ? <span className="badge badge-pill badge-counter ml-1">{props.inboundCount.unallocated}</span> : <></>}
                    </a></li>

                    <li><a href="#" className={`${props.activeTab === 'allocated' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleSetActiveTab('allocated')
                        }}
                    > <Translate text={'allocated'} />
                        {props.inboundCount ? <span className="badge badge-pill badge-counter ml-1">{props.inboundCount.allocated}</span> : <></>}
                    </a></li>


                </ul>
            </div> */}

            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.setItem('inBoundTIPSearchText', _val);
                            if (!_val)
                                props.handlesearchText('')

                            if (_val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.removeItem('inBoundTIPSearchText');
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>

            {
                (!_.isEmpty(props.selectedIds)) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span>{'Selected'}  {Object.keys(props.selectedIds).length > 1 ? ' Leads' : ' Lead'}</span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">

                {
                    props.activeTab === 'unallocated' && accessToNewPPSR
                        ?
                        <>
                            <div className="filter-add-button ml-2">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleOpenPPSR();
                                    }}
                                > <Translate text={'New PPSR'} /></button>
                            </div>
                        </>
                        :
                        <></>
                }



                {/* {
                    (exportData && counts.total > 0)
                        ?
                        <>
                            <div className="filter-item-btn ml-2">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    props.excelDownload(counts.total)
                                }} title={'Excel Export'}>
                                    <i className="ico icon-excel-download"></i>
                                </a>
                                <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`${props.activeTab}_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                    <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                </ExcelFile>
                            </div>
                        </>
                        :
                        <>
                        </>
                } */}



            </div>

            {/* <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={pipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                pipelineView={props.pipelineView}
                pipelineType={'allocation'}
            >
            </Filters> */}


            {/* {
                ((searchText && searchText.length > 2)) ? (<></>) : (
                    <div className="pipeline-counter mr-3">
                        <div className="counter-nav">
                            <ul>
                                {
                                    counts.total > 0 ? (
                                        <li><a href="#" className="counter-total">Total <span className="badge badge-pill badge-counter ml-1">{counts.total}</span></a></li>
                                    ) : (<></>)
                                }
                            </ul>
                        </div>
                    </div>
                )
            } */}

        </div >)
}

export default FilterPanel