export const reportVM = {
  documentID: null,
  // clientID: null,
  oemID: null,
  userIDs: [],
  dealerIDs: [],
  name: '',
  pipeline: '',
  type: '',
  subType: '',
  calculationType: '',
  addedBy: '',
  addedDate: null,
  modifiedBy: '',
  modifiedDate: null,
  isDeleted: false,
};


export const reportOptions = [
  { active: true, value: 'walkintestdrive', label: 'Walk In to Test Drive' },
  { active: true, value: 'enquiryOptions', label: 'KPIs' },
  { active: true, value: 'salesConversion', label: 'Sales Conversion' },
]

export const salesConversions = [
  { active: true, value: 'appointment', label: 'Appointment' },
  { active: true, value: 'call', label: 'Call' },
  { active: true, value: 'email', label: 'Email' },
  { active: true, value: 'walkin', label: 'Walk Ins' },
]

export const calculateOptions = [
  { active: true, value: 'percentage', label: 'Percentage' },
  { active: true, value: 'count', label: 'Count' }
]

export const mandatoryFields = [
  'name',
  'type',
  'subType',
  'calculationType',
  // 'userIDs',
  'clientIDs',
];

export const reportColumns = [
  {
    name: 'Walk Ins',
    value: 'walkin',
    type: 'walkintestdrive',

  },
  {
    name: 'Test Drives',
    value: 'testdrive',
    type: 'walkintestdrive'
  },
  {
    name: 'Walk In Test Drive Ratio',
    value: 'walkintestdrive',
    type: 'walkintestdrive',
    percentage: true
  },
  { type: 'salesConversion', value: 'appointment', name: 'Appointment', dynamic: true },
  { type: 'salesConversion', value: 'call', name: 'Call', dynamic: true },
  { type: 'salesConversion', value: 'email', name: 'Email', dynamic: true },
  { type: 'salesConversion', value: 'walkin', name: 'Walk Ins', dynamic: true },
  { type: 'salesConversion', value: 'totalLeads', name: 'Total', dynamic: false, cls: 'table-middle-total' },
  { type: 'salesConversion', value: 'won', name: 'Won', dynamic: false },
  { type: 'salesConversion', value: 'wonConv', name: 'Won Conv.', dynamic: false, percentage: true },
]

export const dealertargetDynCols = [
  { name: 'Actual', value: 'actual', color: '#4870c5' },
  { name: 'Average', value: 'average', color: '#ee8841' },
  { name: 'Forecast', value: 'forecast', color: '#3c42a9', dummyData: { width: 25, height: 7, top: 8, right: 20 }, dasharray: '8,4' },
  { name: 'Target-Cummulative', value: 'cummulativeTarget', color: '#ed7d31', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
  { name: 'Actual-Cummulative', value: 'cummulativeActual', color: '#000000', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
  { name: 'Target', value: 'target', color: '#f8cbad', borderColor: '#f19454' },
  { name: 'Actual', value: 'retailedActual', color: '#e7e6e6', borderColor: '#2f2f2f' },
  { name: 'Target', value: 'wonTarget', color: '#ed7d31' },

]