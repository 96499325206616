/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { NavLink, Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Filters from '../pipeline/filters'
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {

        var dataArray = [];
        for (var i in props.stageCounts) {
            if (i !== 'Converted')
                dataArray.push(props.stageCounts[i]);
        }

        setCounts({
            total: _.sumBy(dataArray, "total")
        })

    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.inBoundFilter) && Object.keys(JSON.parse(localStorage.inBoundFilter)).length > 0) {
            let _inBoundFilter = JSON.parse(localStorage.inBoundFilter);
            let _filterValue = _inBoundFilter.value;

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setPipelineFilter(_inBoundFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.inBoundFilter])


    const _permisions = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ?
        props.dealersettings.rolePermissions.permissions : null;

    const transferEnquiry = ((!_.isEmpty(_permisions) && _permisions.transferEnquiry) ? true : false);
    const movePipeline = ((!_.isEmpty(_permisions) && _permisions.movePipeline) ? true : false);
    const deleteEnquiry = ((!_.isEmpty(_permisions) && _permisions.deleteEnquiry) ? true : false);
    const mergeContact = ((!_.isEmpty(_permisions) && _permisions.mergeContact) ? true : false);
    const exportData = ((!_.isEmpty(_permisions) && _permisions.exportData) ? true : false);

    const _moduleSettings = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null;

    const frontdeskEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.frontdesk) &&
        (_moduleSettings.frontdesk.enabled && _moduleSettings.frontdesk.active)) ? true : false);

    return (
        <div className="filter-panel">

            <div className="filter-switch">
                <NavLink to="/inbound" exact activeClassName={"switch-active"}>
                    <i className="ico icon-pipeline-1"></i>
                </NavLink>
                {
                    localStorage.defaultModule === 'oem'
                        ?
                        <NavLink to="/inbound/allocation" className="ml-1" exact activeClassName={"switch-active"}>
                            <i className="ico icon-list"></i>
                        </NavLink>
                        :
                        <NavLink to="/inbound/list" className="ml-1" exact activeClassName={"switch-active"}>
                            <i className="ico icon-list"></i>
                        </NavLink>
                }

            </div>

            {
                localStorage.defaultModule !== 'oem' && mergeContact && props.showMore
                    ?
                    <div className="filter-item-btn ml-2">
                        <a href="#" onClick={(e) => { e.preventDefault(); props.handleMerge() }}>
                            <i className="ico icon-merge"></i> <Translate text={'merge'} />
                        </a>
                    </div>
                    :
                    <></>
            }

            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.setItem('inBoundSearchText', _val);
                            if (!_val)
                                props.handlesearchText('')

                            if (props.view !== 'list' && _val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.removeItem('inBoundSearchText');
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>

            {
                (!_.isEmpty(props.selectedIds) && props.showMore) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span><Translate text={'Selected Lead(s)'} />  </span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">

                {
                    props.showMore
                        ?
                        <>
                            {
                                (transferEnquiry || deleteEnquiry)
                                    ?
                                    <>
                                        <Dropdown className='filter-view-more  ml-2'>
                                            <Dropdown.Toggle
                                                as={CustomToggle}
                                                className='common-button'
                                            >
                                                <i className='ico icon-more'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                as={CustomMenu}
                                                ChildClass="more-dropdown"
                                                xplacement="bottom-end"
                                            >
                                                {
                                                    transferEnquiry ? (
                                                        <Dropdown.Item eventKey="1" onClick={(e) => { props.handleTransfer(e) }}><i className="ico icon-email"></i>  <Translate text={'transfer'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }
                                                {
                                                    deleteEnquiry ? (
                                                        <Dropdown.Item eventKey="2" onClick={(e) => { props.handleDelete(e) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            {
                                (exportData && counts.total > 0)
                                    ?
                                    <>
                                        <div className="filter-item-btn ml-2">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                props.excelDownload(counts.total)
                                            }} title={'Excel Export'}>
                                                <i className="ico icon-excel-download"></i>
                                            </a>
                                            <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`inbound_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                                <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                            </ExcelFile>
                                            {/* <CSVLink
                                                ref={props.csvBtn}
                                                uFEFF={false}
                                                data={props.csvData}
                                                headers={props.csvHeader}
                                                filename={`inbound_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}
                                                style={{ display: 'none' }}>
                                            </CSVLink> */}
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }


                        </>
                        :
                        <>
                        </>
                }

            </div>

            <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={pipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                pipelineView={props.pipelineView}
            >
            </Filters>

            {
                (props.readOnlyEnquiryView || localStorage.defaultModule === 'oem')
                    ?
                    <>
                    </>
                    :
                    <>
                        {
                            frontdeskEnabled
                                ?
                                <>
                                    <div className="filter-add-button float-right mr-2">
                                        <button type="button" className="btn btn-secondary"
                                            onClick={props.showCallLogsOpen}
                                            style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className="ico icon-file-logs  pt-0 mr-2" ></i>
                                            <Translate text={'Logs'} /></button>
                                    </div>
                                </>
                                :
                                <></>
                        }

                    </>
            }

            {
                (movePipeline && props.showMore)
                    ?
                    <>
                        <div className="filter-add-button float-right mr-2">
                            <button type="button" className="btn btn-primary"
                                onClick={(e) => { props.handleMove(e) }}
                                style={{ display: 'flex', alignItems: 'center' }}>

                                <Translate text={'allocate'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            {
                ((searchText && searchText.length > 2)) ? (<></>) : (
                    <div className="pipeline-counter mr-3">
                        <div className="counter-nav">
                            <ul>
                                {
                                    counts.total > 0 ? (
                                        <li><a href="#" className="counter-total"><Translate text={'Total'} /> <span className="badge badge-pill badge-counter ml-1">{counts.total}</span></a></li>
                                    ) : (<></>)
                                }
                            </ul>
                        </div>
                    </div>
                )
            }

        </div >)
}

export default FilterPanel