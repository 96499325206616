import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import moment from "moment";
import { ReactSelect, ReactTimePicker, AntDatePicker } from '../../components';
import { enquiryStatus } from '../../services/enum';
import Swal from 'sweetalert2'
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import MentionEditor from '../../components/mentionEditor/mentionEditor';
import { activityLogOptions } from './viewModel'
import { firestoreDB } from '../../services/helper';
import enquiryHelper from '../../services/enquiryHelper';

const LeadConvert = (props) => {

    const [fields, setFields] = useState({ clientID: '', pipeline: '', stage: '', owner: localStorage.uid, notes: '', enquiryID: props.enquiryID, activityDate: null, activityType: '' });
    const [defaulStages, setDefaulStages] = useState([]);
    const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const [allUsers, setAllusers] = useState([])
    const [groups, setGroups] = useState([]);
    const [scheduledActivities, setScheduledActivities] = useState([])
    const [teamMentions, setTeamMentions] = useState(null);
    const [mentions, setMentions] = useState([]);
    const [activityTypes, setActivityTypes] = useState(activityLogOptions);
    const [clientSettings, setClientSettings] = useState({});

    const [rosterMsg, setRosterMsg] = useState(null)

    const mentionsData = props.clientUsers.map(clientUser => {
        return {
            name: `@${clientUser.name}`,
            id: clientUser.id,
            avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
        };
    });
    props.dealersettings.client.teams.forEach(team => {
        mentionsData.push({
            name: `@${team.name}`,
            id: team.user_ids.join(','),
            avatar: _images.group
        })
    })



    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const dealerLeadTransfer = ((!_.isEmpty(_permissions) && _permissions.dealerLeadTransfer) ? true : false);
    const enableLeadTransfer = ((!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.group) && props.dealersettings.group.enableLeadTransfer) ? true : false);


    useEffect(() => {
        const { dealersettings, groupUsers, clientUsers, enquiry } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            let _groups = [];
            if (dealersettings.group && dealersettings.group.clients)
                _groups = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid].name, active: true } })

            const pipelines = [];
            let stages = null;
            let _allpipelines = enquiryHelper.allOpenPipelines(dealersettings.allPipelines, dealersettings)
            let _pipeline = Object.assign({}, _allpipelines.filter(e => e.value !== 'LeadsBucket')[0]);
            let defaultstages = _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            _allpipelines.filter(item => item.value !== 'LeadsBucket').forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let objFilds = {
                ...fields,
                'clientID': dealersettings.client.id,
                'pipeline': _pipeline ? _pipeline.value : '',
                'stage': stages ? stages[0] : '',
                'status': !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null,
                'owner': enquiry?.ownerId ? enquiry.ownerId : (enquiry?.owner ? enquiry.owner : localStorage.uid),
                'contactID': ((!_.isEmpty(enquiry.contact) && !_.isEmpty(enquiry.contact.documentID)) ? enquiry.contact.documentID : (enquiry.contactID ? enquiry.contactID : null))
            }

            if (objFilds.owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }


            setGroups(_groups)
            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(stages.indexOf(stages[0]))
            setErrors({})

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            let options = [];
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));


                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
            }
            else {
                props.clientUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
            }

            if (objFilds.owner) {
                let _currentUser = options.filter(v => v.value === objFilds.owner)[0];
                if (!_currentUser)
                    objFilds.owner = '';

            }

            setFields(objFilds);

            const _activityTypes = [];
            setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push(
                    {
                        label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                        name: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });

            if (!_.isEmpty(_activityTypes))
                setActivityTypes(_activityTypes);

            checkUserRoster(objFilds.owner, objFilds.activityDate);

        }
    }, [])

    useEffect(() => {

        if (_.isEmpty(fields)) {
            window.unSubscribeSelectionLeadActivities && window.unSubscribeSelectionLeadActivities();
            return;
        }


        window.unSubscribeSelectionLeadActivities = firestoreDB(props.dealersettings).firestore().collection('activities')
            //.where('searchUsers', 'array-contains', fields.owner)
            .where('enquiryID', '==', fields.enquiryID)
            .where('type', '==', 'activity')
            .where('isDeleted', '==', false)
            .where('isDone', '==', false)
            //.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))            
            .onSnapshot(querySnapshot => {
                let _activities = [];
                querySnapshot.forEach(function (doc) {
                    var logVM = {
                        ...doc.data(),
                        documentID: doc.id,
                        startDate: _.isEmpty(doc.data().startDate) ? doc.data().addedDate : doc.data().startDate
                    };
                    _activities.push(logVM)
                });

                if (!_.isEmpty(_activities)) {
                    _activities = _activities.sort(function (x, y) { return (x.startDate.seconds < y.startDate.seconds ? -1 : 1); });
                }

                setScheduledActivities(_activities);
            })

        return () => {
            window.unSubscribeSelectionLeadActivities && window.unSubscribeSelectionLeadActivities();
        }



    }, [fields.enquiryID])

    const handleDealerChange = async (e, data) => {
        const { dealersettings, groupUsers, clientUsers, pipeline } = props;
        const _clientSettings = await window.firebase.firestore().doc(`clients/${e.value}/currentSettings/${e.value}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });

        setClientSettings(Object.assign({}, _clientSettings));
        let objFilds = {
            ...fields,
            'clientID': e.value
        }

        if (!_.isEmpty(_clientSettings) && !_.isEmpty(_clientSettings.settings)) {

            const setttings = _clientSettings ? _clientSettings.settings : {};
            const pipelines = [];
            let _stages = null;
            let _currentClientID = dealersettings.client.id;
            let _shoWLeadBucket = ((pipeline && pipeline !== 'LeadsBucket' && e.value === _currentClientID) || e.value === _currentClientID) ? false : true;
            let _allpipelines = enquiryHelper.allOpenPipelines((!_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : setttings.pipelines), dealersettings)
            let _pipeline = Object.assign({}, _allpipelines[0]);
            let defaultstages = _pipeline.stages && _pipeline.stages.filter(item => item.value !== 'InboundLead' && item.value !== 'Converted');
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            _allpipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            objFilds.pipeline = _pipeline ? _pipeline.value : '';
            objFilds.stage = _stages[0];
            objFilds.status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            if (fields.owner === localStorage.uid) {
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
                const noPipelineAccess = _userRoles && objFilds.pipeline && _userRoles.pipeline && _userRoles.pipeline.some(u => u === objFilds.pipeline) ? false : true;
                if (noPipelineAccess)
                    objFilds.owner = '';
            }

            setDefaulStages(defaultstages)
            setPipelines(pipelines)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === e.value), (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                setAllusers(options)
                let _currentUser = fields.owner && options.filter(v => v.value === fields.owner)[0];
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
        }
        else {
            objFilds.pipeline = '';
            objFilds.stage = '';
            objFilds.owner = '';

            setDefaulStages([])
            setPipelines([])
            setActiveStageIndex(0)
            setAllusers([])
        }

        setFields(objFilds);
        checkUserRoster(objFilds.owner, objFilds.activityDate);
    }

    const handlePipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;

        let objFilds = {
            ...fields,
            'pipeline': e.value
        }

        if (!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.settings)) {

            const setttings = Object.assign({}, clientSettings.settings);
            let _stages = null;

            let _pipeline = Object.assign({}, (setttings.allPipelines.filter(v => v.value === e.value)[0]));
            let defaultstages = _pipeline?.stages;
            _stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');

            objFilds.stage = _stages[0];
            objFilds.status = !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null;

            setDefaulStages(defaultstages)
            setActiveStageIndex(_stages.indexOf(_stages[0]))

            // // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
            if (!_.isEmpty(dealersettings.client.roles) && !_.isEmpty(clientSettings.users)) {
                let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                    return obj.documentID;
                })];
                let _selectedUsers = [];
                let allDealerUsers = clientSettings.users;
                if (!_.isEmpty(roleIds))
                    _selectedUsers = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

                let options = [];
                _selectedUsers && _selectedUsers.map(rec => {
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
                //console.log('handlePipelineChange-allDealerUsers', allDealerUsers, roleIds, options)
                setAllusers(options)
                let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
                if (!_currentUser) {
                    objFilds.owner = '';
                }
            }
            else {
                setAllusers([])
                objFilds.owner = '';
            }
            setFields(objFilds);
            checkUserRoster(objFilds.owner, objFilds.activityDate);
        }
        else {
            handleDefaultPipelineChange(e, data);
        }


    }

    const handleDefaultPipelineChange = (e, data) => {
        const { dealersettings, groupUsers, clientUsers } = props;
        //const setttings = props.dealersettings;

        let _pipeline = Object.assign({}, (dealersettings.allPipelines.filter(v => v.value === e.value)[0]));
        let defaultstages = _pipeline.stages
        let stages = Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',')

        let objFilds = {
            ...fields,
            'pipeline': e.value,
            'stage': stages[0],
            'status': !_.isEmpty(_pipeline?.status) ? _pipeline.status[0] : null
        }

        if (fields.owner === localStorage.uid) {
            const _userRoles = (dealersettings && dealersettings.roleID &&
                dealersettings.client && dealersettings.client.roles &&
                dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);
            const noPipelineAccess = _userRoles && e.value && _userRoles.pipeline && _userRoles.pipeline.some(u => u === e.value) ? false : true;
            if (noPipelineAccess)
                objFilds.owner = '';
        }

        // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
        if (!_.isEmpty(dealersettings.client.roles)) {
            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === objFilds.pipeline)), function (obj) {
                return obj.documentID;
            })];
            let _selectedUsers = [];
            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (!_.isEmpty(roleIds))
                _selectedUsers = _.filter(allDealerUsers.filter(v => v.clientID === objFilds.clientID), (v) => _.includes(roleIds, v.roleID));

            let options = [];
            _selectedUsers && _selectedUsers.map(rec => {
                return options.push({
                    value: rec.id,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    searchlabel: rec.name,
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>
                })
            })
            setAllusers(options)
            let _currentUser = options.filter(v => v.value === fields.owner)[0];
            if (!_currentUser) {
                objFilds.owner = '';
            }
        }
        setFields(objFilds);
        setDefaulStages(defaultstages)
        setActiveStageIndex(stages.indexOf(stages[0]))
        checkUserRoster(objFilds.owner, objFilds.activityDate);

    }

    const handleStageChange = (e) => {
        e.preventDefault();
        const { id, name } = e.target;
        const { dealersettings } = props;
        let statusSettings = fields.pipeline && dealersettings.allPipelines.filter(e => e.value === fields.pipeline)[0]?.statusSettings;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === fields.pipeline && e.stage === name)[0];

        if (_statusMndry && _statusMndry.status !== enquiryStatus.OPEN) {
            let __stage = defaulStages.find(x => x.value === name);
            const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
            let _status = CommonHelper.getNameByValue(enquiryAllStatus, _statusMndry.status)
            Swal.fire(`${CommonHelper.showLocale(props, 'Enquiry status will be')} ${_status.toUpperCase()} ${CommonHelper.showLocale(props, 'for selecting stage')}: ${__stage ? __stage.name : ''}`, '', 'info')
            return;
        }

        setFields({ ...fields, ['stage']: name });
        setActiveStageIndex(id)
        setErrors('stage');
    }

    const handleDateChange = (val, name) => {
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (fields.activityDate ? moment.unix(fields.activityDate.seconds).format('HH:mm') : moment().format('HH:mm')))._d;


            setFields({
                ...fields,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
            });

            checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));

        }
    };
    const handleStartTimeChange = (val, name) => {

        setFields({
            ...fields,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

        checkUserRoster(fields.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(objfields)) {
            if (key === 'activityType' && !fields[key] && !_.isEmpty(fields['activityDate'])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
            else if (!fields[key] && key !== 'notes' && key !== 'contactID' && key !== 'activityDate' && key !== 'activityType' && key !== 'status') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        setErrors(errors);

        if (formIsValid === true) {
            let newstagehistory = {
                pipeline: fields.pipeline,
                stage: fields.stage,
                date: window.firebase.firestore.Timestamp.now()
            };

            const objData = Object.assign({}, (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.enqFields) ? props.enquiry.enqFields : props.enquiry));
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            let arrstagehistory = objData && objData.stageHistory ? objData.stageHistory : []
            if (arrstagehistory.some(e => e.stage === fields.stage && e.pipeline === fields.pipeline)) {
                newstagehistory = _.filter(arrstagehistory, { stage: fields.stage, pipeline: fields.pipeline })[0]
                newstagehistory.date = window.firebase.firestore.Timestamp.now()
            }
            arrstagehistory = [...arrstagehistory.filter(e => e.stage !== fields.stage), newstagehistory]

            let _mentions = [];
            if (!_.isEmpty(mentions)) {
                if (mentions.length > 0) {
                    let convertedIDs = []
                    let rawIDs = mentions.map(mentionObject => mentionObject.id)
                    rawIDs.forEach(id => {
                        if (id.includes(',')) {
                            let allIDs = id.split(',')
                            allIDs.forEach(a => {
                                convertedIDs.push(a)
                            })
                        } else {
                            convertedIDs.push(id)
                        }
                    })

                    _mentions = _.uniq(convertedIDs)
                }
            }

            props.handleModalClose({
                ...fields,
                enquiry: objData ? objData : null,
                stageHistory: arrstagehistory,
                mentions: _mentions,
            });
        }
        else
            return formIsValid;

    };

    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });

            setErrors({ ...errors, [data.name]: '' });

            if (data.name === 'owner')
                checkUserRoster(e.value, fields.activityDate);
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const onMentionChange = notesText => {
        if (notesText)
            notesText = notesText.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (notesText.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            notes: notesText
        });

        // handleChangelog(e, true);
    };

    const onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            setTeamMentions(idToPass)
        }

        // Check to avoid adding duplicate entries in mention
        const check = mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            setMentions([...mentions, { name, id: idToPass }]);
        }
    };

    const clearTeamMentions = () => {
        setTeamMentions(null)
    }

    const checkUserRoster = (userID, _date) => {
        if (userID) {
            const { dealersettings, groupUsers, clientUsers } = props;
            CommonHelper.checkUserRoster(dealersettings,
                {
                    owner: userID,
                    startDate: _date ? _date : window.firebase.firestore.Timestamp.now()
                }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers),
                (_date ? true : false))
                .then(msg => {
                    setRosterMsg(msg);
                })
        }
    }

    return (

        <Modal
            show={props.show}
            onHide={props.handleModalClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={'Convert'} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">


                <div className="container-fluid">
                    <div className="row">
                        <div className=" pop-layout form-style">
                            {
                                dealerLeadTransfer
                                    ?
                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'dealerships'} /></label>
                                            <ReactSelect
                                                options={groups}
                                                name={"clientID"}
                                                placeholder={'select dealer'}
                                                onChange={handleDealerChange}
                                                value={fields.clientID}
                                                classNamePrefix={`${errors["clientID"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    :
                                    <></>

                            }
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'pipeline'} /></label>
                                    <ReactSelect
                                        options={pipelines}
                                        name={"pipeline"}
                                        placeholder={'select pipeline'}
                                        onChange={handlePipelineChange}
                                        value={fields.pipeline}
                                        classNamePrefix={`${errors["pipeline"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Pipeline Stage'} /> </label>
                                    <div className={`${errors["stage"] ? 'custom_error' : ''}`}>
                                        <ul className={`pipeline-step `}>
                                            {
                                                defaulStages.length > 0 && defaulStages.map((_stage, index) => {
                                                    return (
                                                        <OverlayTrigger
                                                            key={index}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip>{_stage.name}</Tooltip>
                                                            }
                                                        >
                                                            <li key={index} className={activeStageIndex >= index ? 'active' : ''}>
                                                                <a href="#" id={index} name={_stage.value} onClick={(e) => handleStageChange(e)}>
                                                                    {/* {_stage.name} */}
                                                                    {<>&nbsp;</>}
                                                                </a>
                                                            </li>
                                                        </OverlayTrigger>

                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="form-group">
                                <label ><Translate text={'owner'} /></label>
                                <ReactSelect
                                    options={allUsers}
                                    name={"owner"}
                                    onChange={handleReactSelectChange}
                                    value={fields.owner}
                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>

                            {
                                !_.isEmpty(scheduledActivities)
                                    ?
                                    <div className="calendarlist-activity-item">
                                        <div className="scheduled-activities-title"> <Translate text={'Scheduled Activities'} /></div>
                                        {
                                            scheduledActivities.map((log, index) => {
                                                return <div key={index}>
                                                    {
                                                        index === 0
                                                            ?
                                                            <div className="calendarlist-activity-loop">

                                                                <div className="calendarlist-activity-list">
                                                                    <div className="calendarlist-activity-title">
                                                                        <div className="calendarlist-activity-icon">
                                                                            <i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, log.subType)}`}></i>
                                                                        </div>

                                                                        <a href="#" onClick={(e) => { e.preventDefault(); /*handleEditActivity(log.documentID) */ }}>{log.title}</a>
                                                                    </div>

                                                                    <div className="calendarlist-activity-date">{moment.unix(log.startDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner)}</div>
                                                                </div>

                                                            </div>
                                                            : <></>
                                                    }

                                                </div>
                                            })
                                        }
                                    </div>
                                    :
                                    <></>
                            }

                            <div className="lead-stage-layout form-style">
                                <div className="scheduled-activities-title"> <Translate text={'activity'} /></div>
                                <div className="form-row">

                                    <div className="form-group col-md-3">
                                        <label > <Translate text={'activityType'} /></label>
                                        <ReactSelect
                                            options={activityTypes}
                                            name={"activityType"}
                                            placeholder={'select activity type'}
                                            onChange={handleReactSelectChange}
                                            value={fields.activityType}
                                            classNamePrefix={`${errors["activityType"]} basic-select`}
                                            removeClearable={true}
                                            isSearchable={false}
                                        >
                                        </ReactSelect>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label ><Translate text={'activityDate'} /></label>
                                        <AntDatePicker
                                            value={fields.activityDate ? moment
                                                .unix(fields.activityDate.seconds)
                                                .format('YYYY-MM-DD') : null}
                                            name={'activityDate'}
                                            onChange={e => {
                                                handleDateChange(e, 'activityDate');
                                            }}
                                            format='DD/MM/YYYY'
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control`}
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label ><Translate text={'Time'} /></label>
                                        <div className='inner-addon right-addon'>
                                            <i className='bg-icons ico icon-time'></i>
                                            <ReactTimePicker
                                                value={fields.activityDate ? moment.unix(fields.activityDate.seconds)._d : null}
                                                name={'activityDate'}
                                                onChange={handleStartTimeChange}
                                                timeIntervals={15}
                                                placeholder='h:mm aa'
                                                className={`form-control`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label ><Translate text={'notes'} /></label>
                                {/* <textarea className={`${errors["notes"]} form-control notes`} name="notes" onChange={(e) => handleNotesChanges(e)} value={fields.notes ? fields.notes : ''} placeholder="notes" cols="80" rows="3"></textarea> */}
                                <MentionEditor
                                    mentionsData={mentionsData}
                                    onMentionChange={onMentionChange}
                                    onMentionSelect={onMentionSelect}
                                    notes={fields.notes ? fields.notes : ''}
                                    focusOnMount={false}
                                    isNoteEmpty={false}
                                    teamMentions={teamMentions}
                                    clearTeamMentions={clearTeamMentions}
                                    clientUsers={props.clientUsers}
                                    ownerID={fields.owner ? fields.owner : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer">
                {
                    !_.isEmpty(rosterMsg) ? (
                        <div className="activity-pop-info alert-danger mb-3">
                            <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                        </div>
                    ) : (<></>)
                }
                <button
                    type="button"
                    className="btn btn-primary float-right ml-2"
                    onClick={(e) => handleSubmit(e)}

                >
                    {
                        props.loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'done'} />
                </button>
                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => handleCancel(e)}
                ><Translate text={'cancel'} />
                </button>


            </Modal.Footer>
        </Modal>
    )
}

export default LeadConvert;