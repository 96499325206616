export const attributes = [{
  name: 'Filterable Attributes',
  value: 'filterAttributes'
},
{
  name: 'Searchable Attributes',
  value: 'searchAttributes'
},
{
  name: 'Sortable Attributes',
  value: 'sortableAttributes'
},
{
  name: 'Ranking Rules',
  value: 'rankingRules'
},]

export const collectionFields = [
  {
    name: 'Name',
    value: 'name',
    subText: 'numberOfDocuments',
    flex: 1,
    default: true
  },
  {
    name: 'Filterable Attributes',
    value: 'filterAttributes',
    flex: 2,
    default: true
  },
  {
    name: 'Searchable Attributes',
    value: 'searchAttributes',
    flex: 2,
    default: true
  },
  {
    name: 'Sortable Attributes',
    value: 'sortableAttributes',
    flex: 2,
    default: true
  },
  {
    name: 'Ranking Rules',
    value: 'rankingRules',
    flex: 2,
    default: true
  },
  {
    name: 'Settings',
    value: 'settings',
    subText: 'documentID',
    width: 200,
    default: true,
    type: 'settings',
    flex: 0,
    fixedRight: true
  }
]

export const taskFields = [
  {
    name: 'Id',
    value: 'uid',
    flex: 1,
    default: true
  },
  {
    name: 'Name',
    value: 'indexUid',
    flex: 1,
    default: true
  },
  {
    name: 'Status',
    value: 'status',
    flex: 1,
    default: true
  },
  {
    name: 'Type',
    value: 'type',
    flex: 1,
    default: true
  },
  {
    name: 'Enqueued At',
    value: 'strenqueuedAt',
    flex: 1,
    default: true
  },
  {
    name: 'Started At',
    value: 'strstartedAt',
    flex: 1,
    default: true
  },
  {
    name: 'Finished At',
    value: 'strfinishedAt',
    flex: 1,
    default: true
  },
  {
    name: 'Error',
    value: 'strerror',
    flex: 1,
    default: true
  }
]
export const allstatus = [
  {
    label: 'Enqueued',
    value: 'enqueued'
  },
  {
    label: 'Processing',
    value: 'processing'
  },
  {
    label: 'Succeeded',
    value: 'succeeded'
  },
  {
    label: 'Failed',
    value: 'failed'
  }
]
export const alltypes = [
  {
    label: 'Index Creation',
    value: 'indexCreation'
  },
  {
    label: 'Index Update',
    value: 'indexUpdate'
  },
  {
    label: 'Index Deletion',
    value: 'indexDeletion'
  },
  {
    label: 'Document AdditionOrUpdate',
    value: 'documentAdditionOrUpdate'
  },
  {
    label: 'Document Deletion',
    value: 'documentDeletion'
  },
  {
    label: 'Settings Update',
    value: 'settingsUpdate'
  },
  {
    label: 'Dump Creation',
    value: 'dumpCreation'
  }
]

export const contactFields = [
  {
    name: 'First Name',
    value: 'firstName'
  },
  {
    name: 'Middle Name',
    value: 'middleName'
  },
  {
    name: 'Last Name',
    value: 'lastName'
  },
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'Customer Number',
    value: 'customerNumber'
  },
  {
    name: 'Secondary First Name',
    value: 'secondaryFirstName'
  },
  {
    name: 'Secondary Last Name',
    value: 'secondaryLastName'
  },
  {
    name: 'Address',
    value: 'address'
  },
  {
    name: 'Phone',
    value: 'phone'
  },
  {
    name: 'Secondary Phone',
    value: 'secondaryPhone'
  },
  {
    name: 'Secondary Email',
    value: 'secondaryEmail'
  },
  {
    name: 'Secondary Preferred Name',
    value: 'secondaryPreferredName'
  },
  {
    name: 'Preferred Name',
    value: 'preferredName'
  },
  {
    name: 'Other Phones',
    value: 'phones'
  },
  {
    name: 'Other Emails',
    value: 'emails'
  },
  {
    name: 'Secondary Other Phones',
    value: 'secondaryPhones'
  },
  {
    name: 'Secondary Other Emails',
    value: 'secondaryEmails'
  },
  {
    name: 'License No',
    value: 'licenseNo'
  },
  {
    name: 'Driver Name',
    value: 'driverName'
  },
  {
    name: 'Designation',
    value: 'designation'
  },
  {
    name: 'Display ID',
    value: 'displayID'
  },
  {
    name: 'Company Name',
    value: 'company.name'
  }
];

export const companyFields = [
  {
    name: 'Name',
    value: 'name'
  },
  {
    name: 'Email',
    value: 'email'
  },
  {
    name: 'Address',
    value: 'address'
  },
  {
    name: 'Phone',
    value: 'phone'
  },
  {
    name: 'Website',
    value: 'website'
  },
  {
    name: 'Type',
    value: 'type'
  },
  {
    name: 'Abc/Acn',
    value: 'companyAbcAcn'
  },
  {
    name: 'Associated Company Name',
    value: 'associatedCompany.name'
  },
  {
    name: 'Associated Company Phone',
    value: 'associatedCompany.phone'
  },
  {
    name: 'Associated Company Email',
    value: 'associatedCompany.email'
  },
  {
    name: 'Associated Company Abc/Acn',
    value: 'associatedCompany.companyAbcAcn'
  },
  {
    name: 'Owner',
    value: 'companyOwner'
  }
];
export const enquiryFields = [
  {
    name: 'Display ID',
    value: 'displayID'
  },
  {
    name: 'First Name',
    value: 'contact.firstName'
  },
  {
    name: 'Middle Name',
    value: 'contact.middleName'
  },
  {
    name: 'Last Name',
    value: 'contact.lastName'
  },
  {
    name: 'Email',
    value: 'contact.email'
  },
  {
    name: 'Phone',
    value: 'contact.phone'
  },
  {
    name: 'Make',
    value: 'requirement.make'
  },
  {
    name: 'Model',
    value: 'requirement.model'
  },
  {
    name: 'Ext. Color',
    value: 'requirement.extColor'
  },
  {
    name: 'Int. Color',
    value: 'requirement.intColor'
  },
  {
    name: 'Reg No.',
    value: 'requirement.regNo'
  },
  {
    name: 'Chassis No.',
    value: 'requirement.vinNo'
  },
  {
    name: 'Sale Type',
    value: 'requirement.saleType'
  },
  {
    name: 'Make',
    value: 'requirement.stock.make'
  },
  {
    name: 'Model',
    value: 'requirement.stock.model'
  },
  {
    name: 'Model Description',
    value: 'requirement.stock.modelDescription'
  },
  {
    name: 'Model type',
    value: 'requirement.stock.type'
  },
  {
    name: 'Ext. Color',
    value: 'requirement.stock.extColor'
  },
  {
    name: 'Int. Color',
    value: 'requirement.stock.intColor'
  },
  {
    name: 'Stock No',
    value: 'requirement.stock.stockNo'
  },
  {
    name: 'Reg No.',
    value: 'requirement.stock.regNo'
  },
  {
    name: 'Chassis No.',
    value: 'requirement.stock.vinNo'
  }
]

export const stockFields = [
  {
    name: 'Make',
    value: 'make'
  },
  {
    name: 'Model',
    value: 'model'
  },
  {
    name: 'Year',
    value: 'year'
  },
  {
    name: 'Group',
    value: 'group'
  },
  {
    name: 'Model Description',
    value: 'modelDescription'
  },
  {
    name: 'Type',
    value: 'type'
  },
  {
    name: 'Ext. Color',
    value: 'extColor'
  },
  {
    name: 'Int. Color',
    value: 'intColor'
  },
  {
    name: 'Sale Type',
    value: 'saleType'
  },
  {
    name: 'Status',
    value: 'status'
  },
  {
    name: 'Stock No.',
    value: 'stockNo'
  },
  {
    name: 'Chassis No.',
    value: 'vinNo'
  },
  {
    name: 'Reg No.',
    value: 'regNo'
  },
  {
    name: 'Trade Plate',
    value: 'tradePlate'
  }
];

export const serviceJobsFields = [
  {
    name: 'Display ID',
    value: 'displayID'
  },
  {
    name: 'Status',
    value: 'status'
  },
  {
    name: 'RO Number',
    value: 'roNumber'
  },
  {
    name: 'First Name',
    value: 'contact.firstName'
  },
  {
    name: 'Middle Name',
    value: 'contact.middleName'
  },
  {
    name: 'Last Name',
    value: 'contact.lastName'
  },
  {
    name: 'Email',
    value: 'contact.email'
  },
  {
    name: 'Phone',
    value: 'contact.phone'
  },
  {
    name: 'Address',
    value: 'contact.address'
  },
  {
    name: 'Customer Number',
    value: 'contact.customerNumber'
  },
  {
    name: 'Secondary First Name',
    value: 'contact.secondaryFirstName'
  },
  {
    name: 'Secondary Last Name',
    value: 'contact.secondaryLastName'
  },
  {
    name: 'Secondary Email',
    value: 'contact.secondaryEmail'
  },
  {
    name: 'Secondary Phone',
    value: 'contact.secondaryPhone'
  },
  {
    name: 'Secondary Preferred Name',
    value: 'contact.secondaryPreferredName'
  },
  {
    name: 'Preferred Name',
    value: 'contact.preferredName'
  },
  {
    name: 'Other Phones',
    value: 'contact.phones'
  },
  {
    name: 'Other Emails',
    value: 'contact.emails'
  },
  {
    name: 'Secondary Other Phones',
    value: 'contact.secondaryPhones'
  },
  {
    name: 'Secondary Other Emails',
    value: 'contact.secondaryEmails'
  },
  {
    name: 'License No',
    value: 'contact.licenseNo'
  },
  {
    name: 'Driver Name',
    value: 'contact.driverName'
  },
  {
    name: 'Brake Notes',
    value: 'brakeNotes'
  },
  {
    name: 'Battery Notes',
    value: 'batteryNotes'
  },
  {
    name: 'Tyre Notes',
    value: 'tyreNotes'
  },
  {
    name: 'Payment Status',
    value: 'paymentStatus'
  },
  {
    name: 'Make',
    value: 'vehicle.make'
  },
  {
    name: 'Model',
    value: 'vehicle.model'
  },
  {
    name: 'Reg No.',
    value: 'vehicle.regNo'
  },
  {
    name: 'Chassis No.',
    value: 'vehicle.vinNo'
  },
  {
    name: 'Type',
    value: 'vehicle.type'
  },
  {
    name: 'Group',
    value: 'vehicle.group'
  }
]