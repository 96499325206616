import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { ReactSelect, ReactTimePicker } from '../../../components'
import Translate from '../../../constants/translate';
import moment from 'moment';
const AddSlot = (props) => {

    const [slot, setSlot] = useState(props.slot || { value: uuid() })
    const [errorFields, setErrorFields] = useState({});
    const newSlot = !_.isEmpty(props.slot) ? false : true
    const [eventDates, setEventDates] = useState([])

    useEffect(() => {
        let _dates = []
        for (var m = moment.unix(props.event.startDate.seconds).tz(props.timezone).startOf('day'); m.isSameOrBefore(moment.unix(props.event.endDate.seconds).tz(props.timezone).startOf('day')); m.add(1, 'days')) {
            _dates.push({
                value: m.format('YYYY-MM-DD'),
                label: m.format('DD/MM/YYYY'),
            })
        }
        setEventDates(_dates)
    }, [])


    const handleReactSelectChange = (e, data) => {
        setSlot({
            ...slot,
            [data.name]: e ? e.value : null,
        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleTimeChange = (date, name) => {
        let val = moment().format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
        const offset = moment().utcOffset() - moment().tz(props.timezone).utcOffset()
        setSlot({
            ...slot,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val).add(offset, 'minutes').toDate())
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9 \b]+$/;
        let number = _.trim(value);

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            if (name === 'total') {
                setSlot({
                    ...slot,
                    [name]: Number(_.trim(value)),
                    ['available']: Number(_.trim(value))
                });
            } else {
                setSlot({
                    ...slot,
                    [name]: Number(_.trim(value))
                });
            }

            setErrorFields({
                ...errorFields,
                [name]: ''
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(slot['startTime'])) {
            formIsValid = false;
            errors['startTime'] = errorClass;
        }
        if (_.isEmpty(slot['endTime'])) {
            formIsValid = false;
            errors['endTime'] = errorClass;
        }
        if (_.isEmpty(slot['date'])) {
            formIsValid = false;
            errors['date'] = errorClass;
        }
        setErrorFields(errors)
        if (slot.available && slot.total && slot.available > slot.total) {
            Swal.fire('Available slot should not be greater then total.', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        props.handleClose(slot, newSlot);
    }

    return _.isEmpty(slot) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'date'} /></label>
                                    <ReactSelect
                                        options={eventDates}
                                        id={"event-date-fix"}
                                        name={"date"}
                                        placeholder={'select date'}
                                        onChange={handleReactSelectChange}
                                        value={slot.date}
                                        classNamePrefix={`${errorFields["date"]} basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-6">
                                    <label > <Translate text={'from'} /></label>
                                    <ReactTimePicker
                                        value={slot.startTime ? moment(moment.unix(slot.startTime.seconds).tz(props.timezone).format('YYYY-MM-DD HH:mm')).toDate() : null}
                                        name={'startTime'}
                                        onChange={handleTimeChange}
                                        timeIntervals={15}
                                        placeholder='HH:MM'
                                        className={`form-control ${errorFields["startTime"]}`}
                                        minTime={moment().startOf('day')._d}
                                        maxTime={
                                            slot?.endTime ? moment(moment.unix(slot.endTime.seconds).tz(props.timezone).format('YYYY-MM-DD HH:mm')).toDate() : moment().endOf('day')._d
                                        }
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label > <Translate text={'to'} /></label>
                                    <ReactTimePicker
                                        value={slot.endTime ? moment(moment.unix(slot.endTime.seconds).tz(props.timezone).format('YYYY-MM-DD HH:mm')).toDate() : null}
                                        name={'endTime'}
                                        onChange={handleTimeChange}
                                        timeIntervals={15}
                                        placeholder='HH:MM'
                                        className={`form-control ${errorFields["endTime"]}`}
                                        minTime={
                                            slot?.startTime ? moment(moment.unix(slot.startTime.seconds).tz(props.timezone).format('YYYY-MM-DD HH:mm')).toDate() : moment().startOf('day')._d
                                        }
                                        maxTime={moment().endOf('day')._d}
                                    />
                                </div>
                                {
                                    newSlot ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Interval (Mins)'} /></label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder={'interval'}
                                                pattern="[0-9]*"
                                                className={`form-control ${errorFields["interval"]}`}
                                                name="interval"
                                                onChange={handleNumberChange}
                                                value={slot.interval || ''}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'total'} /></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={'total slots'}
                                        pattern="[0-9]*"
                                        className={`form-control ${errorFields["total"]}`}
                                        name="total"
                                        onChange={handleNumberChange}
                                        value={slot.total || ''}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'available'} /></label>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        placeholder={'available slots'}
                                        pattern="[0-9]*"
                                        className={`form-control ${errorFields["available"]}`}
                                        name="available"
                                        onChange={handleNumberChange}
                                        value={slot.available || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSubmit(e)}>
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddSlot;