import React from 'react'
import Translate from '../../../../constants/translate';
//if any lookup value add or update.please do update in oemsetup/viewmodel
export const clientLookups = [
    {
        name: 'Activity Types',
        value: 'activityTypes',
        module: 'Activity',
        icon: 'icon-activity'
    }, {
        name: 'Campaigns',
        value: 'campaigns',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sort: true,
    },
    {
        name: 'Call Status',
        value: 'callStatus',
        module: 'Activity',
        icon: 'icon-activity'
    },
    {
        name: 'Enquiry Status',
        value: 'enquiryStatus',
        sort: true,
        module: 'Enquiry',
        icon: 'icon-enquiry'
    },
    {
        name: 'Enquiry Types',
        value: 'enquiryTypes',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sort: true,
    },
    {
        name: 'Enquiry Options',
        value: 'enquiryOptionsDF',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sortOption: true

    },
    {
        name: 'Trade-In Options',
        value: 'tradeinOptionsDF',
        module: 'Enquiry',
        icon: 'icon-tradein',
        sortOption: true
    },
    {
        name: 'Stock Options',
        value: 'stockOptionsDF',
        module: 'Enquiry',
        icon: 'icon-Stock',
        sortOption: true
    },
    {
        name: 'Enquiry Label',
        value: 'enquiryLabels',
        module: 'Enquiry',
        icon: 'icon-enquiry'
    },
    {
        name: 'Finance Status',
        value: 'financeStatus',
        module: 'Finance',
        icon: 'icon-finance',
        hideDMSKeys: true
    },
    {
        name: 'Finance Providers',
        value: 'financeProviders',
        module: 'Finance',
        icon: 'icon-finance',
        hideDMSKeys: true
    },
    {
        name: 'After Market Status',
        value: 'afterMarketStatus',
        module: 'Enquiry',
        icon: 'icon-enquiry'
    },
    {
        name: 'Lead Origins',
        value: 'origins',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sort: true,
    },
    {
        name: 'Lost Reasons',
        value: 'lostReasons',
        module: 'Enquiry',
        icon: 'icon-enquiry'
    },
    {
        name: 'Requirement Other Info',
        value: 'reqOtherInfo',
        module: 'Vehicle',
        icon: 'icon-Stock'
    },
    {
        name: 'Vehicle Sales Type',
        value: 'salesType',
        module: 'Vehicle',
        icon: 'icon-Stock'
    },
    {
        name: 'Test Drive Feedback',
        value: 'testdriveFeedback',
        module: 'Test Drive',
        icon: 'icon-activity-testdrive'
    },
    {
        name: 'Title',
        value: 'titles',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'Genders',
        value: 'genders',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'Contact Interests',
        value: 'interests',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'License Type',
        value: 'licenseType',
        module: 'Contact',
        icon: 'icon-Contacts'
    },
    {
        name: 'Company Type',
        value: 'companyType',
        module: 'Company',
        icon: 'icon-company'
    },
    {
        name: 'Stock Location',
        value: 'stockLocation',
        module: 'Stock',
        icon: 'icon-Stock'
    },
    {
        name: 'Stock Usage',
        value: 'stockUsage',
        module: 'Stock',
        icon: 'icon-Stock'
    },
    {
        name: 'Stock Transport Method',
        value: 'stockTransport',
        module: 'Stock',
        icon: 'icon-Stock'
    },
    {
        name: 'Stock Status',
        value: 'stockStatus',
        module: 'Stock',
        icon: 'icon-Stock'
    },
    {
        name: 'Service Packages',
        value: 'servicePackages',
        module: 'Service',
        icon: 'icon-service-settings'
    },
    {
        name: 'Activity Outcome',
        value: 'activityOutcome',
        module: 'Activity',
        icon: 'icon-activity'
    },
    {
        name: 'Lead Source',
        value: 'leadSource',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sort: true,
    },
    {
        name: 'Cafe Categories',
        value: 'cafecategory',
        module: 'Cafe',
        icon: 'icon-cafe',
        subList: true,
        subListTitle: 'Add Ons',
    },
    {
        name: 'Service Types',
        value: 'serviceTypes',
        module: 'Service',
        icon: 'icon-service-settings'
    },
    {
        name: 'Daily Performance Review',
        value: 'checkoutPerformance',
        sort: true,
        module: 'Performance Review',
        icon: 'icon-chart-box',
        hideDMSKeys: true
    },
    {
        name: 'Transmissions',
        value: 'transmissions',
        module: 'Stock',
        icon: 'icon-Stock'
    },
    // {
    //     name: 'Acquisition Certifiable',
    //     value: 'acquisitionCertifiable'
    // },
    {
        name: 'Acquisition Buyer',
        value: 'acquisitionBuyer',
        module: 'Acquisition',
        icon: 'icon-mail-draft',
        hideDMSKeys: true
    },
    {
        name: 'Acquisition Source',
        value: 'acquisitionSource',
        module: 'Acquisition',
        icon: 'icon-mail-draft',
        hideDMSKeys: true,
    },
    {
        name: 'Acquisition Destination',
        value: 'acquisitionDestination',
        module: 'Acquisition',
        icon: 'icon-mail-draft',
        hideDMSKeys: true
    },
    {
        name: 'Add-ons',
        value: 'productAddons',
        module: 'Product Add-ons',
        icon: 'icon-service-package',
        hideDMSKeys: true
    },
    {
        name: 'Product Category',
        value: 'productCategory',
        module: 'Product Add-ons',
        icon: 'icon-service-package',
        hideDMSKeys: true
    },
    {
        name: 'Status',
        value: 'productStatus',
        module: 'Product Add-ons',
        icon: 'icon-service-package',
        sort: true,
        hideDMSKeys: true
    },
    {
        name: 'Provider',
        value: 'productProviders',
        module: 'Product Add-ons',
        icon: 'icon-service-package',
        hideDMSKeys: true
    },
    {
        name: 'File Category',
        value: 'filesCategory',
        module: 'Files',
        icon: 'icon-files',
        hideDMSKeys: true,
    },
    {
        name: 'Enquiry Options',
        value: 'enquiryOptionsStatic',
        module: 'Enquiry',
        icon: 'icon-enquiry',
        sortOption: true
    },
]

export const lookupVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    color: '',
    dmsKeys: [],
    price: null,
    default: null,
    icon: null,
    linkVehicle: null,
    afterSold: null,
    afterLost: null,
    sortNo: null,
    categories: [],
    category: null,
    module: null,
    terms: null,
    colors: [],
    autoSelect: null,
    subList: []
}

export const lookupSublistVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    dmsKeys: []
}

export const lookupDFVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    type: '',
    dmsKeys: [],
    key: null,
    showinPipeline: null
}


export const tblFieldsLookup = [
    {
        name: 'Name',
        value: 'strName',
        sortValue: 'strValue',
        subText: 'strPrice',
        flex: 1,
        default: true,
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',
        sortValue: 'levelName',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelValue',
        sortValue: 'levelValue',
        flex: 0,
        width: 120,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        sortValue: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const levelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'group', label: 'Group', index: 3 },
    { value: 'individual', label: 'Individual', index: 4 }
]

export const dmsKeys = [
    { value: 'batchNumber', label: 'Batch Number' },
    { value: 'dealerETA', label: 'Dealer ETA' },
    { value: 'shipName', label: 'Ship Name' },
    { value: 'locationStatus', label: 'Location Status' },
    { value: 'dealNumber', label: 'Deal Number' },
    { value: 'retailCategory', label: 'Retail Category' },
    { value: 'managerIntro', label: 'Sales Manager Intro' },
    { value: 'finIns', label: 'Finance / Insurance' },
    { value: 'afterMrkt', label: 'After Market' },
    { value: 'financePreApproval', label: 'Finance Pre-Approval' },
    { value: 'orderNumber', label: 'Order Number' },
]

export const fieldTypes = [
    { value: 'text', label: (<><i className="ico icon-dynamic-textfield mr-1"></i> <Translate text={'Text Field'} /></>) },
    { value: 'number', label: (<><i className="ico icon-dynamic-numberfield mr-1"></i> <Translate text={'Number Field'} /></>) },
    { value: 'price', label: (<><i className="ico icon-dynamic-pricefield mr-1"></i> <Translate text={'Price Field'} /></>) },
    { value: 'toggle', label: (<><i className="ico icon-dynamic-switch mr-1"></i> <Translate text={'Switch'} /></>) },
    { value: 'date', label: (<><i className="ico icon-dynamic-datefield mr-1"></i> <Translate text={'Date Field'} /></>) },
    { value: 'select', label: (<><i className="ico icon-dynamic-radio mr-1"></i> <Translate text={'Single Select'} /></>) },
    { value: 'multiselect', label: (<><i className="ico icon-dynamic-checkbox mr-1"></i> <Translate text={'Multi Select'} /></>) }
]

export const activityTypes = [
    {
        name: 'Appointment',
        value: "appointment",
        active: true,
        default: true,
        icon: 'activity-appointment',
        level: 'oem'
    },
    {
        name: "Test Drive",
        value: "testdrive",
        active: true,
        default: true,
        icon: 'activity-testdrive',
        icoVehicle: true,
        level: 'oem'
    },
    {
        name: "Call",
        value: "call",
        active: true,
        default: true,
        icon: 'call',
        level: 'oem'
    },
    {
        name: "Mail",
        value: "email",
        active: true,
        default: true,
        icon: 'Mail',
        level: 'oem'
    },
    {
        name: "Delivery",
        value: "delivery",
        active: true,
        default: true,
        icon: 'activity-delivery',
        icoVehicle: true,
        level: 'oem'
    },
    {
        name: "Task",
        value: "task",
        active: true,
        default: true,
        icon: 'activity',
        level: 'oem'
    }
]

export const activityIcons = [
    {
        name: 'Training',
        icon: 'activity-training'
    },
    {
        name: 'Flag',
        icon: 'activity-deadline'
    },
    {
        name: 'Food',
        icon: 'activity-food'
    },
    {
        name: 'Calendar',
        icon: 'activity-calendar'
    },
    {
        name: 'Down Arrow',
        icon: 'activity-downarrow'
    },
    {
        name: 'Card',
        icon: 'activity-card'
    },
    {
        name: 'File',
        icon: 'activity-file'
    },
    {
        name: 'Mobile',
        icon: 'activity-mobile'
    },
    {
        name: 'Camera',
        icon: 'activity-camera'
    },
    {
        name: 'Scissor',
        icon: 'activity-scissor'
    },
    {
        name: 'Tool',
        icon: 'activity-tool'
    },
    {
        name: 'Chat',
        icon: 'activity-chat'
    },
    {
        name: 'Uparrow',
        icon: 'activity-uparrow'
    },
    {
        name: 'Checkbox',
        icon: 'activity-checkbox'
    },
    {
        name: 'Board',
        icon: 'activity-sign'
    },
    {
        name: 'Shuffle',
        icon: 'activity-shuffle'
    },
    {
        name: 'User',
        icon: 'activity-contacts'
    },
    {
        name: 'Globe',
        icon: 'activity-world'
    },
    {
        name: 'Search',
        icon: 'activity-search'
    },
    {
        name: 'Clip',
        icon: 'activity-clip'
    },
    {
        name: 'Sound',
        icon: 'activity-sound'
    },
    {
        name: 'Brush',
        icon: 'activity-brush'
    },
    {
        name: 'Key',
        icon: 'activity-key'
    },
    {
        name: 'Lock',
        icon: 'activity-lock'
    },
    {
        name: 'Tag',
        icon: 'activity-tag'
    },
    {
        name: 'Finish',
        icon: 'activity-finish'
    },
    {
        name: 'Flight',
        icon: 'activity-plane'
    },
    {
        name: 'Loop',
        icon: 'activity-loop'
    },
    {
        name: 'Wifi',
        icon: 'activity-wifi'
    },
    {
        name: 'Truck',
        icon: 'activity-truck'
    },
    {
        name: 'Cart',
        icon: 'activity-cart'
    },
    {
        name: 'Bulb',
        icon: 'activity-bulb'
    },
    {
        name: 'Alert',
        icon: 'activity-alert'
    },
    {
        name: 'Slide',
        icon: 'activity-slide'
    },
    {
        name: 'Video',
        icon: 'activity-video'
    },
    {
        name: 'Heart',
        icon: 'activity-love'
    },
]

export const productCategory = [
    { value: 'finance', label: 'Finance' },
    { value: 'afterMarket', label: 'Aftermarket' }
]


export const fileCategory = [
    { value: 'sales', label: 'Sales' },
    { value: 'finance', label: 'Finance' },
    { value: 'afterMarket', label: 'Aftermarket' }
]

export const mappingProductCols = [
    { name: 'Title', value: 'title', type: 'text', mandatory: true, width: '20' },
    { name: 'Base Price', value: 'basePrice', type: 'price', formulaCols: 'fitPrice', destCol: 'costPrice', width: '13' },
    { name: 'Fit Price', value: 'fitPrice', type: 'price', formulaCols: 'basePrice', destCol: 'costPrice', width: '13' },
    { name: 'Cost Price', value: 'costPrice', type: 'price', width: '13', readOnly: true },
    { name: 'RRP', value: 'rrp', type: 'price', width: '13' },
    { name: 'Terms (Years)', value: 'terms', type: 'number', width: '13' }
]