/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Tab, Nav } from 'react-bootstrap'
import Translate from '../../../constants/translate';
import SalesProgress from './salesActivity'
import { PopUpModal, ContentOverlay } from '../../../components';
import AddReport from './addReport'
import CommonHelper from '../../../services/common';
import DynamicReport from './dynamicReport';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import AddEmailSettings from '../../reports/addEmailSettings'
import StaticReport from '../../reports/staticReport';
import uuid from 'react-uuid'
import moment from 'moment'
import DealerTarget from './dealerTarget';
import WeeklyActivityReport from './mclarenweeklyReport';

const Reports = (props) => {

    const [showModel, setShowmodel] = useState(false)
    const [dynamicReports, setReports] = useState([])
    const [reportIDs, setReportIDs] = useState([])
    const [loader, setLoader] = useState(true)
    const [key, setKey] = useState()
    const [showSMTPModel, setShowSMTPmodel] = useState(false)
    const [nylasCode, setNylasCode] = useState()
    const [staticReports, setStaticReports] = useState([])
    const [targetReports, setTargetReports] = useState([])
    const isMclaren = !_.isEmpty(props.dealersettings) && props.dealersettings.isMclaren === true ? true : false

    // useEffect(() => {
    //     document.getElementsByTagName('body')[0].classList.add('inner-bg');
    //     let search = window.location.search;
    //     let params = new URLSearchParams(search);
    //     let key = params.get('key');
    //     if (!key) {
    //         key = "salesprogress"
    //     }
    //     setKey(key)
    // }, [])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if (_.isEmpty(code))
            return

        setNylasCode(code)
        setShowSMTPmodel(true);
    }, [])

    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
            document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')
        }
        document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
        document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.dynReportsCollection && window.dynReportsCollection.unsubscribe()
            localStorage.removeItem('reportKey')
        }
    }, [])


    useEffect(() => {
        if (!props.dealersettings)
            return;
        localStorage.removeItem('reportKey')
        let refprivateReportsCollection = '';
        let refsharedReportsCollection = '';
        if (props.dealersettings.roleName === 'Administrator') {
            refprivateReportsCollection = window.firebase.firestore().collection('reports')
            refprivateReportsCollection = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refprivateReportsCollection, props.dealersettings, null, true);
        }
        else {
            refprivateReportsCollection = window.firebase.firestore().collection('reports')
                .where('owner', '==', localStorage.uid)
            refprivateReportsCollection = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refprivateReportsCollection, props.dealersettings, null, true);


            refsharedReportsCollection = window.firebase.firestore().collection('reports')
                .where('visibility', 'in', ['shared', 'sharedView'])
            refsharedReportsCollection = CommonHelper.whereClientOrGroupOrRegionOrOemClause(refsharedReportsCollection, props.dealersettings, null, true);
        }

        const refstaticReportsCollection = window.firebase.firestore().collection('schedular')
            .where('static', '==', true)
            .where('oemID', '==', props.dealersettings.oemID)
            .limit(1);

        let refdealerTargetCollection = window.firebase.firestore().collection('targets')
            .where('isDeleted', '==', false)
            .limit(1);
        if (props.dealersettings.level === 'group' && !_.isEmpty(props.dealersettings.arrayGroupID))
            refdealerTargetCollection = refdealerTargetCollection.where('groupID', 'in', CommonHelper.arrLimit(props.dealersettings.arrayGroupID, 9));
        else if (props.dealersettings.level === 'region' && !_.isEmpty(props.dealersettings.arrayRegionID))
            refdealerTargetCollection = refdealerTargetCollection.where('regionID', 'in', CommonHelper.arrLimit(props.dealersettings.arrayRegionID, 9));
        else
            refdealerTargetCollection = refdealerTargetCollection.where('oemID', '==', props.dealersettings.oemID);

        //console.log('start', new Date())
        window.dynReportsCollection = combineLatest(
            refprivateReportsCollection ? collection(refprivateReportsCollection) : of([]),
            refsharedReportsCollection ? collection(refsharedReportsCollection) : of([]),
            collection(refstaticReportsCollection),
            collection(refdealerTargetCollection),
        ).pipe(
            map(([privateReport, sharedReport, staticReport, targetReport]) => {
                return [
                    _.map(privateReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(sharedReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(staticReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(targetReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            })).subscribe(querySnapshot => {
                //console.log('end', new Date())

                let reports = [];
                for (let i = 0; i < 2; i++) {
                    querySnapshot[i].forEach(el => {
                        if (!reports.some(e => e.documentID === el.documentID)) {
                            const _defaultfilters = [{
                                id: uuid(),
                                name: 'date',
                                type: 'thisMonth',
                                value: {
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                }

                            }];
                            if (el.make) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'makeModels',
                                    value: {
                                        make: el.make[0]
                                    }
                                })
                            }
                            if (el.saleType) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'salesType',
                                    value: {
                                        salesType: el.saleType
                                    }
                                })
                            }
                            if (el.leadType) {
                                _defaultfilters.push({
                                    id: uuid(),
                                    name: 'leadType',
                                    value: {
                                        leadType: el.leadType
                                    }
                                })
                            }
                            reports.push({
                                ...el,
                                pipeline: _.isArray(el.pipeline) ? el.pipeline : el.pipeline !== 'all' ? [el.pipeline] : [],
                                columns: _.isArray(el.columns) ? el.columns : [el.columns],
                                displayBy: (el.reportType === 'table' || el.reportType === 'pie') ? null : el.displayBy,
                                objFilters: _.isEmpty(el.objFilters) ? _defaultfilters : el.objFilters,
                                level: _.isEmpty(el.level) ? 'individual' : el.level
                            })
                        }
                    });
                }
                if (isMclaren) {
                    if (_.isEmpty(localStorage.reportKey)) {
                        localStorage.setItem('reportKey', 'weeklyReport')
                        setKey('weeklyReport');
                    }
                    let _reportIDs = [..._.orderBy(reports, ['addedDate'], ['asc']).map(r => { return r.documentID }), 'weeklyReport'];
                    if (querySnapshot[2].length > 0) {
                        _reportIDs.push('staticReport')
                    }
                    if (querySnapshot[3].length > 0) {
                        _reportIDs.push('targetReport')
                    }
                    setReportIDs(_reportIDs)
                }
                else if (querySnapshot[2].length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport)) {
                    if (_.isEmpty(localStorage.reportKey)) {
                        localStorage.setItem('reportKey', 'staticReport')
                        setKey('staticReport');
                    }
                    let _reportIDs = [..._.orderBy(reports, ['addedDate'], ['asc']).map(r => { return r.documentID }), 'staticReport'];
                    if (querySnapshot[3].length > 0) {
                        _reportIDs.push('targetReport')
                    }
                    setReportIDs(_reportIDs)
                }
                else if (querySnapshot[3].length > 0) {
                    if (_.isEmpty(localStorage.reportKey)) {
                        localStorage.setItem('reportKey', 'targetReport')
                        setKey('targetReport');
                    }
                    setReportIDs([..._.orderBy(reports, ['addedDate'], ['asc']).map(r => { return r.documentID }), 'targetReport'])
                }
                else {
                    if (_.isEmpty(localStorage.reportKey)) {
                        const _key = reports.length > 0 ? _.orderBy(reports, ['addedDate'], ['asc'])[0].documentID : '';
                        localStorage.setItem('reportKey', _key)
                        setKey(_key)
                    }
                    setReportIDs(..._.orderBy(reports, ['addedDate'], ['asc']).map(r => { return r.documentID }))
                }

                setReports(_.orderBy(reports, ['addedDate'], ['asc']))
                setStaticReports(querySnapshot[2])
                setTargetReports(querySnapshot[3]);
                setLoader(false)
            })

    }, [])

    useEffect(() => {
        if (_.isEmpty(dynamicReports))
            return;

    }, [dynamicReports])

    const handleModelClose = (rec, isNew) => {
        setShowmodel(false)
        setShowSMTPmodel(false)
        if (isNew) {
            localStorage.setItem('reportKey', rec.documentID)
            setKey(rec.documentID)
        }
    }

    // const getQueryparam = () => {
    //     document.getElementsByTagName('body')[0].classList.add('inner-bg');
    //     let search = window.location.search;
    //     let params = new URLSearchParams(search);
    //     let key = params.get('key');
    //     if (!key) {
    //         key = "salesprogress"
    //     }
    //     return key
    // }
    return (
        <>
            <div className="middle-wrapper">
                <div className="oem-panel">
                    <Tab.Container activeKey={key} onSelect={(k) => {
                        localStorage.setItem('reportKey', k)
                        setKey(k)
                    }} mountOnEnter={true}>
                        <div id="report-wrapper">
                            <div id="sidebar-wrapper">
                                <div className="report-sidebar">
                                    <div className="custom-menu">
                                        <a href="#" className="btn-primary sidebar-toggle" onClick={(e) => {
                                            e.preventDefault()
                                            if (document.getElementById('report-wrapper')) {
                                                document.getElementById('report-wrapper').classList.toggle("toggled")
                                            }
                                        }}><i className="ico icon-menu"></i></a>
                                    </div>
                                    <div className="report-add-wrap">
                                        <h4>Reports </h4>
                                        <div className="report-email-settings"><a href="#" className="mini-button" onClick={() => {
                                            setShowSMTPmodel(true);
                                        }}> <i className="ico icon-settings"></i></a></div>
                                        <div className="report-add-button"><a href="#" className="mini-button" onClick={() => {
                                            setShowmodel(true)
                                        }}> <i className="ico icon-add"></i></a></div>
                                    </div>
                                    <Nav as="ul" bsPrefix="ul-nav report-ul-scroll">
                                        {/* <Nav.Item as="li" bsPrefix="li-nav">
                                            <Nav.Link eventKey="salesprogress" bsPrefix="li-a-nav"> <Translate text={'Sales Activity'} /></Nav.Link>
                                        </Nav.Item> */}
                                        {
                                            isMclaren ? (<><Nav.Item as="li" bsPrefix="li-nav">
                                                <Nav.Link eventKey="weeklyReport" bsPrefix="li-a-nav"> <Translate text={'Weekly Activity Report - New'} /></Nav.Link>
                                            </Nav.Item>
                                                <Nav.Item as="li" bsPrefix="li-nav">
                                                    <Nav.Link eventKey="weeklyReportused" bsPrefix="li-a-nav"> <Translate text={'Weekly Activity Report - Qualified'} /></Nav.Link>
                                                </Nav.Item></>) : (<></>)
                                        }
                                        {
                                            staticReports.length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport) ? (<Nav.Item as="li" bsPrefix="li-nav">
                                                <Nav.Link eventKey="staticReport" bsPrefix="li-a-nav"> <Translate text={'Static Reports'} /></Nav.Link>
                                            </Nav.Item>) : (<></>)
                                        }
                                        {
                                            targetReports.length > 0 ? (<Nav.Item as="li" bsPrefix="li-nav">
                                                <Nav.Link eventKey="targetReport" bsPrefix="li-a-nav"> <Translate text={'Dealer Target'} /></Nav.Link>
                                            </Nav.Item>) : (<></>)
                                        }
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                dynamicReports.map((rec, index) => {
                                                    return <Nav.Item key={index} as="li" bsPrefix="li-nav">
                                                        <Nav.Link eventKey={rec.documentID} bsPrefix="li-a-nav">{rec.name}</Nav.Link>
                                                    </Nav.Item>
                                                })
                                            )
                                        }
                                    </Nav>
                                </div>
                            </div>
                            <div id="page-content-wrapper">
                                <div className="report-content-area">
                                    <div className="report-content-sub">
                                        {/* <Tab.Pane eventKey="salesprogress">
                                            <SalesProgress {...props} />
                                        </Tab.Pane> */}
                                        {
                                            isMclaren ? (<>
                                                <Tab.Pane eventKey="weeklyReport">
                                                    <WeeklyActivityReport {...props} saleType={'new'} title={'Weekly Activity Report - New'}/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="weeklyReportused">
                                                    <WeeklyActivityReport {...props} saleType={'used'} title={'Weekly Activity Report - Qualified'}/>
                                                </Tab.Pane>
                                            </>) : (<></>)
                                        }
                                        {
                                            staticReports.length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport) ? (
                                                <Tab.Pane eventKey="staticReport">
                                                    <StaticReport {...props} />
                                                </Tab.Pane>
                                            ) : (<></>)
                                        }
                                        {
                                            targetReports.length > 0 ? (
                                                <Tab.Pane eventKey="targetReport">
                                                    <DealerTarget {...props} />
                                                </Tab.Pane>
                                            ) : (<></>)
                                        }
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : dynamicReports.length > 0 ? (
                                                dynamicReports.map((rec, index) => {
                                                    return <Tab.Pane key={index} eventKey={rec.documentID}>
                                                        <DynamicReport {...props} report={rec} activeKey={key} setKey={(k) => { localStorage.setItem('reportKey', k); setKey(k) }} reportIDs={reportIDs} />
                                                    </Tab.Pane>
                                                })) : staticReports.length > 0 && (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessStaticReport) ? (
                                                    <></>
                                                ) : targetReports.length > 0 ? (<></>) : (<div className="common-table">
                                                    <div className="text-center p-5">
                                                        <p><Translate text={'No report found'} /></p>
                                                        <button className="btn btn-sm btn-default" onClick={() => { setShowmodel(true) }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                            <Translate text={'Add Report'} />
                                                        </button>
                                                    </div>
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <PopUpModal show={showModel}>
                <AddReport
                    {...props}
                    show={showModel}
                    title={'Add Report'}
                    handleClose={handleModelClose}
                />
            </PopUpModal>
            <PopUpModal show={showSMTPModel}>
                <AddEmailSettings
                    {...props}
                    show={showSMTPModel}
                    title={'Email Settings'}
                    handleClose={handleModelClose}
                    nylasCode={nylasCode}
                />
            </PopUpModal>
        </>
    );
}

export default Reports;