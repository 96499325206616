import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, InputMultipleText, InputCheckBox, PopUpModal, ReactMultiSelect } from '../../../../components'
import { saleTypes, mailtotypes, triggerVM, tiggerFrequency, frequencyOptions, priodEveryTypes, eventTypes, replyTypes, tradeinProStatuses } from './viewModel'
import TemplatePreview from './previewTemplate'
import Translate from '../../../../constants/translate';
import _images from '../../../../images'
import CommonHelper from '../../../../services/common';
import { levelOptions } from '../fieldSettings/viewModel'
import { TemplateContext } from '../templateContext'

const activityTypes = [
    {
        label: (<><i className="ico icon-activity-appointment mr-1"></i>Appointment</>),
        value: "appointment",
        active: true,
    },
    {
        label: (<><i className="ico icon-activity-testdrive mr-1"></i>Test Drive</>),
        value: "testdrive",
        icoVehicle: true,
        active: true,
    },
    {
        label: (<><i className="ico icon-call mr-1"></i>Call</>),
        value: "call",
        active: true,
    },
    {
        label: (<><i className="ico icon-Mail mr-1"></i>Mail</>),
        value: "email",
        active: true,
    },
    {
        label: (<><i className="ico icon-activity-delivery mr-1"></i>Delivery</>),
        value: "delivery",
        icoVehicle: true,
        active: true,
    },
    {
        label: (<><i className="ico icon-activity mr-1"></i>Task</>),
        value: "task",
        active: true,
    }
]

const TriggerType = (props) => {
    //console.log(props)
    const [trigger, setTrigger] = useState(props.trigger);
    const [errorFields, setErrorFields] = useState({});
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [bodyTypes, setBodyTypes] = useState([])
    const [emailTemplates, setEmailTemplates] = useState([])
    const [smsTemplates, setSmsTemplates] = useState([])
    const [lostReasons, SetLostReasons] = useState([])


    const [showPreviewModel, setShowPreviewModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [template, setTemplate] = useState(null)
    const [loader, setLoader] = useState(false)
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [allTeamUsers, setAllTeamUsers] = useState([])

    const { triggerSmsTemplates, edmTemplates } = useContext(TemplateContext);

    const tradeinProStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.tradeinPro &&
        props.dealersettings.client.tradeinPro.tradeinProStatus ? props.dealersettings.client.tradeinPro.tradeinProStatus.map(r => { return { label: <div className={`badge badge-pill ${r.value === 'New' ? 'badge-status-new' : 'badge-status-empty'}`} style={{ background: r.color }}>{r.name}</div>, value: r.value } }) : [];

    useEffect(() => {
        setTrigger(props.trigger)
    }, [props.trigger])



    useEffect(() => {


        let users = [];
        users.push({
            label: <div className="drop-image">
                <img src={_images.nouser} alt="" width="50" />
                Owner
            </div>,
            value: 'owner',
            active: true,
        })
        if (!_.isEmpty(props.clientUsers)) {
            props.clientUsers.forEach(rec => {
                users.push({
                    label: <div className="drop-image">
                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                        {rec.name}
                    </div>,
                    value: rec.id,
                    searchlabel: rec.name,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
            })
        }
        setOwnerOptions(users)
    }, [props.clientUsers])

    useEffect(() => {
        if (_.isEmpty(edmTemplates)) {
            return;
        }
        let listVM = [];
        edmTemplates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push({
                    label: rec.title,
                    value: rec.documentID,
                    doc: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
        })
        setEmailTemplates(listVM)
    }, [edmTemplates])

    useEffect(() => {
        if (_.isEmpty(triggerSmsTemplates)) {
            return;
        }
        let listVM = [];
        triggerSmsTemplates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push({
                    label: rec.title,
                    value: rec.documentID,
                    doc: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                })
        })
        setSmsTemplates(listVM)
    }, [triggerSmsTemplates])

    useEffect(() => {
        setTrigger({
            ...trigger,
            'category': props.trigger.category,
            'status': eventTypes.find(item => item.value === props.trigger.category && item.status === props.trigger.status) ?
                eventTypes.find(item => item.value === props.trigger.category && item.status === props.trigger.status).status
                : eventTypes.filter(item => item.value === props.trigger.category)[0].status,
            'triggerType': props.newTrigger === true && props.trigger.category === 'activity' ? 'email' : trigger.triggerType
        })
    }, [props.trigger.category])
    useEffect(() => {
        if (_.isEmpty(trigger))
            return
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (trigger.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === trigger.make)[0]) ? makes.filter(m => m.value === trigger.make)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        const _teams = [];
        trigger.clientID === props.dealersettings.client.id && props.dealersettings.client.teams && props.dealersettings.client.teams.forEach(doc => {
            _teams.push({
                value: doc.id,
                type: 'team',
                searchlabel: doc.name,
                user_ids: doc.user_ids ? doc.user_ids : [],
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: (
                    <div className='drop-image'>
                        <img src={CommonHelper.showUserAvatar('', doc.name)} alt="" width="50" className="img-object-fit" />
                        {doc.name}
                    </div>
                )
            });
        });

        let _users = [];
        if (!_.isEmpty(trigger)) {
            if (trigger.clientID === props.dealersettings.client.id) {
                props.clientUsers.filter(e => e.id !== trigger.owner).map(rec => {
                    return _users.push({
                        value: rec.id,
                        type: 'user',
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                });
            }
            else if (!_.isEmpty(props.groupUsers)) {
                props.groupUsers.filter(e => e.clientID === trigger.clientID && e.id !== trigger.owner).map(rec => {
                    return _users.push({
                        value: rec.id,
                        type: 'user',
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                });
            }
        }

        const _lostReasons = [];
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings) {
            const setttings = props.dealersettings.client.settings;
            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });
        }
        SetLostReasons(_lostReasons)
        setMakes(makes)
        setModels(models)
        setBodyTypes(types)
        setAllTeamUsers([
            {
                label: 'Teams',
                options: _teams
            },
            {
                label: 'Users',
                options: _users
            }
        ])
    }, [props.dealersettings, trigger, trigger.make])

    const handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setTrigger({
            ...trigger,
            [name]: value ? value.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() }) : ''
        })

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setTrigger({
            ...trigger,
            [name]: str
        });
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleTextChange = e => {
        const { name, value } = e.target;

        setTrigger({
            ...trigger,
            [name]: (value || ''),
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };
    
    const handleOnChangeMultipleText = (val, name) => {
        setTrigger({
            ...trigger,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'make') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['model']: '',
                ['type']: ''
            })
        }
        else if (data.name === 'ccType') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['mailCc']: e && e.value === 'owner' ? e.value : ''
            })
        }
        else if (data.name === 'bccType') {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : '',
                ['mailBcc']: e && e.value === 'owner' ? e.value : ''
            })
        }
        else {
            setTrigger({
                ...trigger,
                [data.name]: e ? e.value : ''
            })
        }

        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setTrigger({
            ...trigger,
            [e.target.name]: e.target.checked
        })
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setTrigger({
                ...trigger,
                [e.target.name]: number !== '' ? Number(number) : number
            });
        }

        setErrorFields({
            ...errorFields,
            [e.target.name]: ''
        });
    };

    const removeEmail = (index, name) => {
        let arr = trigger[name] ? trigger[name].split(',') : []
        arr.splice(index, 1)
        setTrigger({
            ...trigger,
            [name]: arr.join(',')
        })
    }

    const saveTrigger = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(trigger['title'])) {
            formIsValid = false;
            errors['title'] = errorClass;
        }
        if (_.isEmpty(trigger['period'])) {
            formIsValid = false;
            errors['period'] = errorClass;
        }
        if (trigger.triggerType === 'tradeinProactivity') {
            if (_.isEmpty(trigger['todoType'])) {
                formIsValid = false;
                errors['todoType'] = errorClass;
            }
        }
        else {
            if (_.isEmpty(trigger['templateID'])) {
                formIsValid = false;
                errors['templateID'] = errorClass;
            }
            if (trigger.recipientType === 'custom' && _.isEmpty(trigger['recipientTo'])) {
                formIsValid = false;
                errors['recipientTo'] = errorClass;
            }
        }
        if (trigger.period !== 'instant') {
            if (!trigger['periodValue'] || trigger['periodValue'] === 0 || _.isEmpty(trigger['periodValue'].toString())) {
                formIsValid = false;
                errors['periodValue'] = errorClass;
            }
        }
        if (trigger.period === 'iteration') {
            if (!trigger['periodEvery'] || trigger['periodEvery'] === 0 || _.isEmpty(trigger['periodEvery'].toString())) {
                formIsValid = false;
                errors['periodEvery'] = errorClass;
            }
            if (_.isEmpty(trigger['periodEveryType'])) {
                formIsValid = false;
                errors['periodEveryType'] = errorClass;
            }
        }
        // if (_.isEmpty(trigger['pipeline'])) {
        //     formIsValid = false;
        //     errors['pipeline'] = errorClass;
        // }
        // if (!_.isEmpty(trigger['pipeline']) && _.isEmpty(trigger['stage'])) {
        //     formIsValid = false;
        //     errors['stage'] = errorClass;
        // }
        if (!trigger.level) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(trigger.level) && !trigger.subLevelIDs && trigger.level !== 'oem' && trigger.level !== 'individual') {
            formIsValid = false;
            errors['subLevelIDs'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (trigger.level) {
            const selectedOptions = trigger.subLevelIDs;
            trigger.level = trigger.level ? trigger.level : ''
            trigger.clientID = (trigger.level === 'individual' && selectedOptions.length === 1) ? selectedOptions[0] : null;
            trigger.oemID = null;
            trigger.regionIDs = [];
            trigger.groupIDs = [];
            trigger.clientIDs = [];
            if (trigger.level === 'oem') {
                trigger.oemID = props.dealersettings.client.settingsID
            } else if (trigger.level === 'region') {
                trigger.regionIDs = selectedOptions
            } else if (trigger.level === 'group') {
                trigger.groupIDs = selectedOptions
            } else if (trigger.level === 'individual') {
                trigger.clientIDs = selectedOptions
            }
        }
        trigger.modifiedDate = window.firebase.firestore.Timestamp.now();
        trigger.modifiedBy = localStorage.uid;
        trigger.module = 'tradeinPro';
        if (trigger.status === 'enquiryCreated' && (!_.isEmpty(trigger.make) || !_.isEmpty(trigger.model) || !_.isEmpty(trigger.bodyType) || !_.isEmpty(trigger.saleType)))
            trigger.status = 'enquiryCreatedREQ'
        if (_.isEmpty(trigger.recipientType) && (trigger.triggerType === 'email' || trigger.triggerType === 'sms'))
            trigger.recipientType = 'contact'
        window.firebase.firestore().doc(`triggers/${trigger.documentID}`)
            .set({
                ..._.pick({
                    ...trigger,
                    periodValue: (trigger.period !== 'iteration' && trigger.period !== 'instant' && trigger.period !== 'birthday' && props.frequencyValue === 'before') ? -trigger.periodValue : trigger.periodValue
                }, _.keys(triggerVM))
            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((props.newTrigger === true ? 'Trigger added successfully' : 'Trigger updated successfully'), {
                    duration: 2000
                })
                props.handleClose(trigger);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const previewTemplate = (type) => {
        if (_.isEmpty(trigger.templateID))
            return
        if (type === 'email' && emailTemplates.find(item => item.doc.documentID === trigger.templateID)) {
            setTemplate(emailTemplates.find(item => item.doc.documentID === trigger.templateID).doc)
            setPopHeader(emailTemplates.find(item => item.doc.documentID === trigger.templateID).label)
            setShowPreviewModel(true)
        }
        else if (smsTemplates.find(item => item.doc.documentID === trigger.templateID)) {
            setTemplate(smsTemplates.find(item => item.doc.documentID === trigger.templateID).doc)
            setPopHeader(smsTemplates.find(item => item.doc.documentID === trigger.templateID).label)
            setShowPreviewModel(true)
        }

    }

    const handlePreviewModelClose = () => {
        setShowPreviewModel(false)
        setTemplate(null)
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        if (name === 'pipelines') {
            setTrigger({
                ...trigger,
                stages: [],
                [name]: value
            });
        } else if (name === 'make') {
            setTrigger({
                ...trigger,
                ['model']: '',
                ['type']: '',
                [name]: value.join(',')
            });
        }
        else if (name === 'recipientTo') {
            setTrigger({
                ...trigger,
                [name]: !_.isEmpty(value) ? value.join(',') : ''
            })
        } else {
            setTrigger({
                ...trigger,
                [name]: value
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handlesetLevel = (e, data) => {
        if (data.name === 'level') {
            if (e.value === 'oem') {
                setTrigger({
                    ...trigger,
                    [data.name]: e.value,
                    ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : '',
                    ['subLevelIDs']: ''
                });
            } else {
                setTrigger({
                    ...trigger,
                    [data.name]: e.value,
                    ['levelID']: '',
                    ['subLevelIDs']: ''
                });
            }
            setErrorFields({
                ...errorFields,
                [data.name]: ''
            });
        }
    }

    const handleTemmateChange = selectedOptions => {
        let _usersList = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data, index) => {
            if (data.type === 'team') {
                _usersList = _.union(_usersList, Object.assign([], data.user_ids));
            }
            else
                _usersList = _.union(_usersList, [data.value]);
        });

        setTrigger({
            ...trigger,
            teammates: _usersList
        });
    };

    // const pipelineOptions = _.map(pipelineOptions, function (rec) {
    //     return {
    //         label: rec.name,
    //         value: rec.value
    //     }
    // })

    const pipelineOptions = props.dealersettings.client.tradeinPro ? props.dealersettings.client.tradeinPro.pipelines : [];

    return (
        <>
            <div className="add-trigger-columns h-100" >
                <div className="add-trigger-column-left">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Event Type'} /></h3>
                    </div>
                    <div className="add-trigger-column-nav">
                        <ul>
                            {
                                eventTypes.filter(item => item.value === trigger.category).map((rec, index) => {
                                    return <li key={index} className={`${rec.status === trigger.status ? 'active' : ''}`}>
                                        <a href="#" className="column-list-left" onClick={(e) => {
                                            e.preventDefault();
                                            if (rec.status === 'contactBirthday') {
                                                setTrigger({
                                                    ...trigger,
                                                    'status': rec.status,
                                                    'period': 'birthday'
                                                })
                                            }
                                            else {
                                                setTrigger({
                                                    ...trigger,
                                                    'status': rec.status
                                                })
                                            }
                                        }}>
                                            {
                                                rec.status === trigger.status ?
                                                    (<div className="trigger-active-check"><i className="ico icon-check"> </i></div>)
                                                    : (<></>)
                                            }

                                            <Translate text={rec.name} /> <span>{CommonHelper.showLocale(props, rec.desc)}</span> </a>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="add-trigger-column-middle">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Trigger Type'} /></h3>
                    </div>
                    <div className="add-trigger-submenu">
                        <ul>
                            {
                                props.value !== 'activity' && props.value !== 'tradeinProInbound' ?
                                    (<li><a href="#" className={`${trigger.triggerType === 'tradeinProactivity' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setTrigger({
                                                ...trigger,
                                                'triggerType': 'tradeinProactivity',
                                                'templateID': null
                                            })
                                        }}><i className="ico icon-trigger-activity"></i>  <Translate text={'activity'} /></a></li>)
                                    : (<></>)
                            }

                            <li><a href="#" className={`${trigger.triggerType === 'email' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTrigger({
                                        ...trigger,
                                        'triggerType': 'email',
                                        'templateID': null
                                    })
                                }}><i className="ico icon-trigger-email"></i>  <Translate text={'email'} /></a></li>
                            <li><a href="#" className={`${trigger.triggerType === 'sms' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setTrigger({
                                        ...trigger,
                                        'triggerType': 'sms',
                                        'templateID': null
                                    })
                                }}><i className="ico icon-trigger-sms"></i>  <Translate text={'SMS'} /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="settings-column-right">
                    <div className="add-trigger-column-title">
                        <h3> <Translate text={'Trigger Settings'} /></h3>
                    </div>
                    <div className="trigger-form-wraper form-style">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label > <Translate text={'title'} /> </label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={`${'Title'}`}
                                    className={`form-control ${errorFields["title"]}`}
                                    name="title"
                                    onChange={handleOnChange}
                                    value={trigger.title}
                                />
                            </div>
                        </div>
                        {
                            trigger.triggerType === 'email' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label > <Translate text={'Subject'} /> </label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={`${'subject'}`}
                                            className={`form-control ${errorFields["subject"]}`}
                                            name="subject"
                                            onChange={handleTextChange}
                                            value={trigger.subject}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label > <Translate text={'Sender Name'} /> </label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={`${'senderName'}`}
                                            className={`form-control ${errorFields["senderName"]}`}
                                            name="senderName"
                                            onChange={handleTextChange}
                                            value={trigger.senderName}
                                        />
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                        {
                            trigger.triggerType === 'tradeinProactivity' ? (
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'Activity Type'} /></label>
                                        <ReactSelect
                                            options={activityTypes}
                                            name={"todoType"}
                                            placeholder={'select activity type'}
                                            onChange={handleSelectChange}
                                            value={trigger.todoType}
                                            classNamePrefix={`${errorFields["todoType"]} basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>) : (<></>)
                        }
                        {
                            (trigger.category === 'appraisal') ? (
                                <div className="form-row">
                                    <div className={`form-group col-md-12`}>
                                        <label> <Translate text={'Pipelines'} /></label>
                                        <ReactMultiSelect
                                            options={_.map(pipelineOptions.filter(e => localStorage.defaultModule === 'oem' ? e.value === 'LeadsBucket' : true), function (rec) {
                                                return {
                                                    label: rec.name,
                                                    value: rec.value,
                                                    doc: rec,
                                                }
                                            })}
                                            name={"pipelines"}
                                            placeholder={'select pipelines'}
                                            onChange={e => handleReactMultiSelectChange(e, 'pipelines')}
                                            value={trigger.pipelines}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    {!_.isEmpty(trigger.pipelines) && trigger.pipelines.length === 1 && trigger.status !== 'servieStatus' ? (
                                        <div className={`form-group col-md-12`}>
                                            <label> <Translate text={'Stages'} /></label>
                                            <ReactMultiSelect
                                                options={_.map(pipelineOptions.filter(item => item.value === trigger.pipelines[0])[0].stages.filter(item => trigger.pipelines[0] === 'LeadsBucket' ? (item.value !== 'InboundLead' && item.value !== 'Converted') : true), function (s) {
                                                    return {
                                                        value: s.value,
                                                        label: s.name,
                                                        active: _.isBoolean(s.active) ? s.active : true,
                                                    }
                                                })}
                                                name={"stages"}
                                                placeholder={'select stages'}
                                                onChange={e => handleReactMultiSelectChange(e, 'stages')}
                                                value={trigger.stages}
                                                classNamePrefix={`basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                    ) : <></>}
                                </div>
                            ) : (<></>)
                        }
                        {
                            (trigger.category === 'contact' || trigger.category === 'company' || trigger.category === 'activity' || trigger.status === 'tradeinProCreated' || trigger.category === 'tradeinProInbound') ? (<></>) : (<>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'Status'} /></label>
                                        <ReactSelect
                                            options={tradeinProStatus.length ? tradeinProStatus : tradeinProStatuses}
                                            name={"tradeinProStatus"}
                                            placeholder={'select status'}
                                            onChange={handleSelectChange}
                                            value={trigger.tradeinProStatus}
                                            classNamePrefix={` basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>

                                </div>
                            </>
                            )
                        }
                        {
                            (trigger.triggerType === 'email' || trigger.triggerType === 'sms') ? (
                                <>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            {
                                                !_.isEmpty(trigger.templateID) ? (
                                                    <a href="#" className="inputlink-addmore" onClick={(e) => { e.preventDefault(); previewTemplate(trigger.triggerType); }}> <Translate text={'preview'} /></a>
                                                ) : (<></>)
                                            }
                                            <label> <Translate text={'Template'} /></label>
                                            <ReactSelect
                                                options={trigger.triggerType === 'email' ? emailTemplates : smsTemplates}
                                                name={"templateID"}
                                                placeholder={'select template'}
                                                onChange={handleSelectChange}
                                                value={trigger.templateID}
                                                classNamePrefix={`${errorFields["templateID"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'to'} /></label>
                                            <ReactSelect
                                                options={mailtotypes}
                                                name={"recipientType"}
                                                onChange={handleSelectChange}
                                                value={!_.isEmpty(trigger.recipientType) ? trigger.recipientType : 'contact'}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                    {
                                        trigger.recipientType === 'custom' ? (<div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={trigger.triggerType === 'email' ? 'To Email' : 'phone'} /></label>
                                                {
                                                    trigger.triggerType === 'email' ? (
                                                        <InputMultipleText
                                                            placeholder={`to emails`}
                                                            className={`form-control react-multi-tag ${errorFields["recipientTo"]}`}
                                                            name="recipientTo"
                                                            onChange={(emails) => { handleOnChangeMultipleText(emails, 'recipientTo') }}
                                                            value={!_.isEmpty(trigger.recipientTo) ? trigger.recipientTo.split(',') : []}
                                                            validateEmail={true}
                                                            getLabel={(email, index) => {
                                                                return <div data-tag key={index}>
                                                                    {email}
                                                                    <span data-tag-handle onClick={() => removeEmail(index, 'recipientTo')}>
                                                                        ×
                                                                    </span>
                                                                </div>
                                                            }}
                                                        />
                                                    ) : (
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={`phone`}
                                                            className={`form-control ${errorFields["recipientTo"]}`}
                                                            name="recipientTo"
                                                            onChange={handleOnChange}
                                                            value={trigger.recipientTo}
                                                        />
                                                    )
                                                }

                                            </div>
                                        </div>) : trigger.recipientType === 'user' ? (<div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={trigger.triggerType === 'email' ? 'To Email' : 'phone'} /></label>
                                                <ReactMultiSelect
                                                    options={_.map(props.clientUsers.filter(item => trigger.triggerType === 'sms' ? !_.isEmpty(item.phone) : true), function (rec) {
                                                        return {
                                                            label: <div className="drop-image">
                                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                {rec.name}
                                                            </div>,
                                                            value: trigger.triggerType === 'email' ? rec.email : rec.phone,
                                                            searchlabel: rec.name,
                                                            active: _.isBoolean(rec.active) ? rec.active : true,
                                                        };
                                                    })}
                                                    name={"recipientTo"}
                                                    placeholder={'select user'}
                                                    onChange={e => handleReactMultiSelectChange(e, 'recipientTo')}
                                                    value={!_.isEmpty(trigger.recipientTo) ? trigger.recipientTo.split(',') : []}
                                                    classNamePrefix={`${errorFields["recipientTo"]} basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>
                                        </div>) : (<></>)
                                    }
                                    {
                                        trigger.triggerType === 'email' ? (
                                            <>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Cc Emails To'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"ccType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.ccType) ? trigger.ccType : ''}
                                                            classNamePrefix={`${errorFields["ccType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.ccType && trigger.ccType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Cc Emails'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`cc emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="mailCc"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailCc') }}
                                                                value={!_.isEmpty(trigger.mailCc) ? trigger.mailCc.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'mailCc')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Bcc Emails To'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"bccType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.bccType) ? trigger.bccType : ''}
                                                            classNamePrefix={`${errorFields["bccType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.bccType && trigger.bccType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Bcc Emails'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`bcc emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="mailBcc"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'mailBcc') }}
                                                                value={!_.isEmpty(trigger.mailBcc) ? trigger.mailBcc.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'mailBcc')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label> <Translate text={'Reply Type'} /></label>
                                                        <ReactSelect
                                                            options={replyTypes}
                                                            name={"replyType"}
                                                            onChange={handleSelectChange}
                                                            value={!_.isEmpty(trigger.replyType) ? trigger.replyType : ''}
                                                            classNamePrefix={`${errorFields["replyType"]} basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>
                                                {trigger.replyType && trigger.replyType !== 'owner' && (
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label> <Translate text={'Reply To Email'} /></label>
                                                            <InputMultipleText
                                                                placeholder={`reply to emails`}
                                                                className={`form-control react-multi-tag`}
                                                                name="replyTo"
                                                                onChange={(emails) => { handleOnChangeMultipleText(emails, 'replyTo') }}
                                                                value={!_.isEmpty(trigger.replyTo) ? trigger.replyTo.split(',') : []}
                                                                validateEmail={true}
                                                                getLabel={(email, index) => {
                                                                    return <div data-tag key={index}>
                                                                        {email}
                                                                        <span data-tag-handle onClick={() => removeEmail(index, 'replyTo')}>
                                                                            ×
                                                                        </span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                        ) : (<></>)
                                    }
                                </>
                            ) : (<>
                                {
                                    trigger.triggerType === 'tradeinProactivity' ? (
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'teammate'} /></label>
                                                <ReactMultiSelect
                                                    options={allTeamUsers}
                                                    name={'teammates'}
                                                    placeholder={'select teammate'}
                                                    onChange={handleTemmateChange}
                                                    value={trigger.teammates ? trigger.teammates : []}
                                                    classNamePrefix={`basic-select`}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                    menuPlacement='bottom'
                                                    isGroup={true}
                                                ></ReactMultiSelect>
                                            </div>
                                        </div>) : (<></>)
                                }
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'user'} /></label>
                                        <ReactSelect
                                            options={ownerOptions}
                                            name={"triggerFor"}
                                            onChange={handleSelectChange}
                                            value={!_.isEmpty(trigger.triggerFor) ? trigger.triggerFor : 'owner'}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </>)
                        }
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>  <Translate text={'Frequency'} /></label>
                                <ReactSelect
                                    options={trigger.status === 'contactBirthday' ? tiggerFrequency.filter(e => e.value === 'birthday') : tiggerFrequency}
                                    name={"period"}
                                    onChange={handleSelectChange}
                                    value={trigger.period}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                        <div className="form-row">
                            {
                                (trigger.period === 'iteration' || trigger.period === 'birthday') ? (
                                    <div className="form-group col-md-12">
                                        <label> <Translate text={'Frequency Value'} /></label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={'number of occurrences'}
                                            className={`form-control ${errorFields["periodValue"]}`}
                                            name="periodValue"
                                            onChange={handleNumberChange}
                                            value={trigger.periodValue}
                                            removeClearable={true}
                                        />
                                    </div>
                                ) : trigger.period !== 'instant' ? (<>
                                    <div className="form-group col-md-6">
                                        <label> <Translate text={'Frequency Value'} /> </label>
                                        <ReactSelect
                                            options={frequencyOptions}
                                            name={"frequencyValue"}
                                            onChange={(e, date) => {
                                                props.setFrequencyValue(e.value)
                                            }}
                                            value={props.frequencyValue}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>&nbsp;</label>
                                        <InputText
                                            autoComplete="off"
                                            placeholder={`no. of ${trigger.period === 'afterMins' ? 'mins' : trigger.period === 'afterHours' ? 'hours' : 'days'}`}
                                            className={`form-control ${errorFields["periodValue"]}`}
                                            name="periodValue"
                                            onChange={handleNumberChange}
                                            value={trigger.periodValue}
                                            removeClearable={true}
                                        />
                                    </div>
                                </>) : (<></>)
                            }

                        </div>
                        {
                            trigger.period === 'iteration' ? (
                                <>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label> <Translate text={'Repeat Every'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={`repeat every`}
                                                className={`form-control ${errorFields["periodEvery"]}`}
                                                name="periodEvery"
                                                onChange={handleNumberChange}
                                                value={trigger.periodEvery}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>&nbsp;</label>
                                            <ReactSelect
                                                options={priodEveryTypes}
                                                placeholder={`select day/month/year`}
                                                name={"periodEveryType"}
                                                onChange={handleSelectChange}
                                                value={trigger.periodEveryType}
                                                classNamePrefix={`${errorFields["periodEveryType"]} basic-select`}
                                                removeClearable={false}
                                            >
                                            </ReactSelect>
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label> <Translate text={`Start After`} /> {_.find(eventTypes, { status: trigger.status }) && !_.isEmpty(_.find(eventTypes, { status: trigger.status }).label) ? _.find(eventTypes, { status: trigger.status }).label : ''}</label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={`start after ${_.find(eventTypes, { status: trigger.status }) && !_.isEmpty(_.find(eventTypes, { status: trigger.status }).label) ? _.find(eventTypes, { status: trigger.status }).label.toLocaleLowerCase() : ''}`}
                                                className={`form-control`}
                                                name="startAfter"
                                                onChange={handleNumberChange}
                                                value={trigger.startAfter}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>&nbsp;</label>
                                            <ReactSelect
                                                options={priodEveryTypes}
                                                placeholder={`select day/month/year`}
                                                name={"startAfterType"}
                                                onChange={handleSelectChange}
                                                value={trigger.startAfterType}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={false}
                                            >
                                            </ReactSelect>
                                        </div>

                                    </div>
                                </>
                            ) : (<></>)
                        }
                        <div className="form-row">
                            <div className={`form-group col-md-12`}>
                                <label> <Translate text={'Level'} /></label>
                                <ReactSelect
                                    options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={handlesetLevel}
                                    value={trigger.level}
                                    classNamePrefix={`${errorFields["level"]} cursor-pointer basic-select `}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>
                            {
                                (!_.isEmpty(trigger.level) && trigger.level !== 'oem') ?
                                    (<div className={`form-group col-md-12`}>
                                        <label> <Translate text={trigger.level === 'group' ? 'Group' : trigger.level === 'region' ? 'Region' : 'Client'} /></label>
                                        <ReactMultiSelect
                                            options={trigger.level === 'group' ? props.groupOptions : trigger.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'subLevelIDs'}
                                            placeholder={`select ${trigger.level === 'individual' ? 'client' : trigger.level}`}
                                            onChange={e => handleReactMultiSelectChange(e, 'subLevelIDs')}
                                            value={trigger.subLevelIDs ? _.isArray(trigger.subLevelIDs) ? trigger.subLevelIDs : [trigger.subLevelIDs] : []}
                                            classNamePrefix={`${errorFields["subLevelIDs"]} basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                            }
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label> <Translate text={'notes'} /></label>
                                <textarea className="form-control notes" name='notes' value={trigger.notes} rows="3" placeholder="activity notes..." onChange={handleOnAreaChange}></textarea>
                            </div>
                        </div>
                        <div className="trigger-form-footer">
                            <span className="switch switch-sm float-left">
                                <InputCheckBox
                                    className={`switch`}
                                    id={`trigger-active`}
                                    name={'isActive'}
                                    checked={trigger.isActive}
                                    onChange={(e) => { handleCheckedChange(e) }} />
                                <label htmlFor={`trigger-active`}><Translate text={'active'} /></label>
                            </span>
                            {(trigger.triggerType === 'sms' || trigger.triggerType === 'email') && (
                                <span className="switch switch-sm float-left">
                                    <InputCheckBox
                                        className={`switch`}
                                        id={`trigger-feedback`}
                                        name={'triggerFeedback'}
                                        checked={trigger.triggerFeedback}
                                        onChange={(e) => { handleCheckedChange(e) }} />
                                    <label htmlFor={`trigger-feedback`}><Translate text={'Trigger Feedback'} /></label>
                                </span>
                            )}
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { saveTrigger() }}>
                                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'save'} />
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => { props.handleClose() }} > <Translate text={'cancel'} /></button>
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModal show={showPreviewModel}>
                <TemplatePreview
                    {...props}
                    show={showPreviewModel}
                    title={popHeader}
                    handleClose={handlePreviewModelClose}
                    template={template}
                />
            </PopUpModal>
        </>
    );
}

export default TriggerType;