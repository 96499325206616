/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import { enquiryStatus, stockStatus } from '../../../services/enum'
import Translate from '../../../constants/translate';
import Requirements from '../../requirement/list'
import CommonHelper from '../../../services/common';

var _excluded = ['chassisNo', 'regNo', 'stockNo', 'warrantyStartDate', 'warrantyExpiry']
export default class MultiRequirementSelect extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.mandatoryFields = [];
        this.state = {
            reqSoldIds: [],
            errors: {},
        }
    }

    componentDidMount() {
        const { primaryID, getMandatoryFieldsByType } = this.props;
        if (primaryID) {
            let reqSoldIds = this.state.reqSoldIds;
            if (this.isValidRequirement(primaryID))
                reqSoldIds.push(primaryID);
            this.setState({ reqSoldIds: reqSoldIds }, () => { this.handleValidFields() });

        }

        if (getMandatoryFieldsByType) {
            let _mandatoryFields = getMandatoryFieldsByType('enquiry', enquiryStatus.WON);
            let _requiredFields = _.filter(_mandatoryFields, (v) => (_excluded.indexOf(v) >= 0));
            _requiredFields = _.map(_requiredFields, function (a) {
                return a === 'chassisNo' ? 'vinNo' : a === 'warrantyExpiry' ? 'warrantyExp' : a
            });
            _requiredFields.push('saleType');
            this.mandatoryFields = _requiredFields;
        }
    }

    componentDidUpdate() {
        if (!_.isEmpty(this.state.reqSoldIds)) {
            this.handleValidFields()
        }
    }

    handleAllChecked = (event) => {
        const { requirements } = this.props;
        let selectedIds = [];
        if (event.target.checked) {
            requirements && requirements.map(_requirement => {
                if (this.isValidRequirement(_requirement.documentID, true) === true)
                    return selectedIds.push(_requirement.documentID)
            })
        }

        this.setState({ reqSoldIds: selectedIds }, () => { this.handleValidFields() });

    }

    handleCheckChange = (e) => {

        const { name, checked } = e.target;
        let reqSoldIds = this.state.reqSoldIds;

        if (checked) {
            reqSoldIds.push(name);
        }
        else {
            let stindex = reqSoldIds.indexOf(name)
            reqSoldIds.splice(stindex, 1);

        }
        //console.log('handleCheckChange', name, checked, reqSoldIds)
        this.setState({ reqSoldIds: reqSoldIds }, () => { this.handleValidFields() });
    }

    isValidRequirement = (id, isAllCheck) => {
        const requirement = Object.assign({}, this.props.requirements.filter(e => e.documentID === id)[0]);

        if (!_.isEmpty(requirement)) {
            if (!_.isEmpty(requirement.stock)) {
                const userID = this.props.dealersettings ? this.props.dealersettings.id : '';
                //console.log('isValidRequirement',requirement.stock.model, requirement.stock.status, requirement.stock.statusModifiedBy, userID)
                if (requirement.stock.status === stockStatus.AVAILABLE || requirement.stock.status === stockStatus.MCRETAILED || requirement.stock.status === stockStatus.INPRODUCTION) {
                    return isAllCheck && requirement.stock.isServiceLoan ? false : true;
                }
                else if (requirement.stock.status === stockStatus.RESERVED && (requirement.stock.enquiryID && requirement.stock.enquiryID === this.props.enquiryID) && (requirement.stock.statusModifiedBy && requirement.stock.statusModifiedBy === userID)) {
                    return isAllCheck && requirement.stock.isServiceLoan ? false : true;
                }
                // else {
                //     let reqSoldIds = this.state.reqSoldIds;
                //     let stindex = reqSoldIds.indexOf(id)
                //     reqSoldIds.splice(stindex, 1);
                //     this.setState({ reqSoldIds: reqSoldIds });
                // }
            }
            else {
                return true;
            }
        }
        return false;
    }

    handleValidFields = (isScroll) => {

        let errors = {};
        let formIsValid = true;
        let _key;
        const { requirements } = this.props;
        const { reqSoldIds } = this.state;

        reqSoldIds && reqSoldIds.forEach(_recommID => {
            let fieldsDetail = Object.assign({}, requirements.filter(e => e.documentID === _recommID)[0]);

            if (!_.isEmpty(fieldsDetail.stock) && fieldsDetail.stock.make)
                fieldsDetail = Object.assign({}, fieldsDetail.stock);

            let errorKey = [];
            this.mandatoryFields && this.mandatoryFields.forEach((key) => {
                if ((_.isObject(fieldsDetail[key]) && _.isEmpty(fieldsDetail[key])) || (!_.isObject(fieldsDetail[key]) && !_.isBoolean(fieldsDetail[key]) && !_.isNumber(fieldsDetail[key]) && !fieldsDetail[key])) {
                    formIsValid = false;
                    errorKey.push(key);
                    //console.log(key + '_' + _recommendation.documentID, fieldsDetail[key])
                }
            });

            if (!_.isEmpty(errorKey)) {
                if (!_key) _key = _recommID;
                errors[_recommID] = errorKey;
            }

        });

        if (JSON.stringify(this.state.errors) !== JSON.stringify(errors))
            this.setState({ errors: errors });

        if (isScroll && _key && document.getElementById(_key) && !_.isEmpty(errors[_key])) {
            document.getElementById(_key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }

        return formIsValid;
    }


    handleSave = () => {
        if (this.handleValidFields(true) === true) {
            let reqSoldIds = this.state.reqSoldIds;
            this.props.handleClose(reqSoldIds);
        }
    }

    handleEdit = (req, isClone) => {
        this.props.handleEdit(req, isClone, this.state.errors[req.documentID])
    }

    render() {

        const { show, primaryID, title, isDeivered, enquiryID, dealersettings, clientUsers, groupUsers, requirements, status } = this.props;
        const { reqSoldIds, errors } = this.state;
        return (
            <>
                <Modal
                    id="multi-list"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-multi-list">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="multi-list-wrap">
                                    {
                                        (this.props.requirements && this.props.requirements.length > 0)
                                            ?
                                            <>

                                                <div className="file-manager-check">
                                                    <div className="checkbox icheck-success">
                                                        <input
                                                            id={'all-requirement'}
                                                            type="checkbox"
                                                            onChange={this.handleAllChecked}
                                                            value={'all-requirement'}
                                                            checked={(_.isObject(reqSoldIds) && !_.isEmpty(reqSoldIds) && !_.isEmpty(requirements) && reqSoldIds.length === requirements.length) ? true : false}
                                                        />
                                                        <label htmlFor={'all-requirement'}><Translate text={'Select All'} /></label>
                                                    </div>
                                                </div>

                                                <div className={`file-manager-action  mr-3`}>
                                                    <div className="file-manager-btn float-right">
                                                        {!_.isEmpty(reqSoldIds) ? reqSoldIds.length : 0} / {requirements.length}
                                                    </div>
                                                </div>

                                                {
                                                    this.props.requirements.map((_requirement, index) => (

                                                        <div className="multi-vehicle-item" key={index} id={_requirement.documentID + '_' + index}>

                                                            <div className={`multi-list-check ${this.isValidRequirement(_requirement.documentID) ? '' : 'btn-disable'}`}>
                                                                <div className="checkbox icheck-success">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={_requirement.documentID}
                                                                        name={_requirement.documentID}
                                                                        className="uncheck-activity"
                                                                        checked={(!_.isEmpty(reqSoldIds) && reqSoldIds.indexOf(_requirement.documentID) >= 0) ? true : false}
                                                                        onChange={(e) => {
                                                                            if (_requirement.stock && _requirement.stock.isServiceLoan) {
                                                                                Swal.fire(CommonHelper.showLocale(this.props, 'This vehicle has been reserved for service loan only'), '', 'info')
                                                                                return false;
                                                                            }
                                                                            this.handleCheckChange(e)
                                                                        }} />
                                                                    <label htmlFor={_requirement.documentID}></label>
                                                                </div>
                                                            </div>
                                                            <div className={`vehicle-item`}>
                                                                <Requirements
                                                                    key={index}
                                                                    status={status}
                                                                    requirement={_requirement}
                                                                    handleEdit={this.handleEdit}
                                                                    setPrimary={this.props.setPrimary}
                                                                    decrementQuantity={this.props.decrementQuantity}
                                                                    incrementQuantity={this.props.incrementQuantity}
                                                                    deleteRequirement={this.props.deleteRequirement}
                                                                    isPrimary={primaryID === _requirement.documentID ? true : false}
                                                                    dealersettings={dealersettings}
                                                                    clientUsers={clientUsers}
                                                                    groupUsers={groupUsers}
                                                                    isDeivered={isDeivered}
                                                                    isMultiple={true}
                                                                >
                                                                </Requirements>
                                                                {
                                                                    errors && !_.isEmpty(errors[_requirement.documentID])
                                                                        ?
                                                                        <div className="multi-vehicle-validate" onClick={(e) => { e.preventDefault(); this.handleEdit(_requirement) }}>
                                                                            <div className="multi-vehicle-validate-alert multi-vehicle-alert-red">
                                                                                <b className="alert-link"><Translate text={'Required'} />:</b>
                                                                                {
                                                                                    errors[_requirement.documentID].map((_err, index) => {
                                                                                        return <><>{index > 0 ? ', ' : ''}</> <Translate key={index} text={_err} /></>
                                                                                    })
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>

                                                        </div>


                                                    ))
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer >
                        <button type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}>
                            <Translate text={'cancel'} />
                        </button>
                        <button type="button"
                            className={`btn btn-common button-green float-right ${this.state.reqSoldIds.length === 0 ? 'btn-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleSave();
                            }}>
                            <Translate text={localStorage.defaultModule === 'afterSales' ? 'closeWon' : 'won'} />
                        </button>
                    </Modal.Footer>
                </Modal>
            </>


        );
    }
}

