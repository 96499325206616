/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import 'toasted-notes/src/styles.css';
import _ from 'lodash';
import Swal from 'sweetalert2'
/** COMPONENTS */
import { InputText, InputTextArea } from '../../../../components'
import { default as _images } from "../../../../images";
import Translate from '../../../../constants/translate';
import { firestoreDB } from '../../../../services/helper';

//#region  ASSIGN VARIABLES

const objForm = {
    clientID: null,
    email: '',
    comments: ''
}
//#endregion


class RequestForm extends Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = '';
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('companies');
        this.state = {
            fields: Object.assign({}, objForm),
            errors: {},
            isLoading: false
        }
    }


    //#region PAGE LOAD
    componentDidMount() {
        let state = this.state.fields;

        const { selectedClient, dealersettings } = this.props;
        state.clientID = !_.isEmpty(selectedClient) ? selectedClient : '';
        state.email = !_.isEmpty(dealersettings) ? dealersettings.email : '';
        this.setState({ fields: state })


    }
    //#endregion

    //#region CHANGE EVENT
    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);


        fields[name] = value;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {

            if (!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' && fields[key].length > 1) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }
    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            state[data.name] = e.value;
        }
        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }
    //#endregion

    //#region ADD, EDIT & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (['clientID', 'email'].indexOf(key) >= 0) {
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.sendRequest(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };

    sendRequest = (e) => {

        let objData = Object.assign({}, this.state.fields);
        this.setState({ isLoading: true });
        const { requests, selectedClient, dealersettings } = this.props;

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        objData.clientName = this.props.clients.filter(client => client.documentID === selectedClient).length ? this.props.clients.filter(client => client.documentID === selectedClient)[0].name : '';
        objData.userID = dealersettings ? dealersettings.id : '';
        objData.userName = dealersettings ? dealersettings.name : '';
        objData.module = requests ? requests.module : '';

       // console.log('sendRequest', objData)
        const clientsSendRequest = window.firebase.functions().httpsCallable('clients-sendRequest');
        clientsSendRequest(objData).then((response) => {
            //console.log('clientsSendRequest', response, response.data.message, response.data.success);
            if (response.data.success) {
                this.handleModalClose();
            }
            else {
                Swal.fire(response.data.message, '', 'error')
                this.setState({ isLoading: false });
            }
            this.setState({ isLoading: false });

        });


    };


    handleModalClose = () => {

        this.setState({ fields: Object.assign({}, objForm) });
        this.setState({ errors: {} });
        this.props.handleClose(this.props.requests);
    };
    //#endregion

    render() {
        //console.log('R E N D E R - C O M P A N Y ');
        let { clientID, email, comments } = this.state.fields;

        const { selectedClient, clients, show, requests, dealersettings } = this.props;
        return (
            <>

                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-addons-request">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="form-style w-100">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <div className="addons-request-logo"><img src={requests ? requests.image : _images.nolicense} /></div>
                                            {/* <p className="addons-request-text">Lorem ipsum is placeholder text commonly used in the graphic,
                                            print, and publishing industries for previewing layouts and visual mockups.</p> */}
                                        </div>

                                    </div>

                                    <div className="form-row">
                                        {/* <div className="form-group col-md-12">
                                            <label ><Translate text={'client'} /></label>
                                            <ReactSelect
                                                options={clients}
                                                name={"clientID"}
                                                placeholder={'select client'}
                                                onChange={this.handleReactSelectChange}
                                                value={clientID}
                                                classNamePrefix={`${this.state.errors["clientID"]} basic-select`}
                                                removeClearable={true}
                                                isDisabled={true}
                                            >
                                            </ReactSelect>
                                        </div> */}
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'email'}
                                                className={`form-control ${this.state.errors["email"]}`}
                                                name="email"
                                                onChange={this.handleOnChange}
                                                value={email}
                                            />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label><Translate text={'Comments'} /></label>
                                            <InputTextArea
                                                className={`form-control notes ${this.state.errors["comments"]}`}
                                                name="comments"
                                                onChange={this.handleOnAreaChange}
                                                value={comments}
                                                maxLength="280"
                                                rows="3">
                                            </InputTextArea>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-default float-left" onClick={this.props.handleClose}>
                            <Translate text={'cancel'} />
                        </button>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)} >
                            {this.state.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'Request'} />
                        </button>
                    </Modal.Footer>


                </Modal>

            </>
        );
    }
}

export default RequestForm;