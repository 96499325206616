/** LIBRARIES */
import React, { PureComponent } from "react";
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import InActiveInfo from './inactiveInfo'

export default class InActiveView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { users, frontdeskQueueActivation } = this.props;
        //console.log('InActiveView', users, clientUsers, activeQueueTab, activeTab)

        return (
            <>
                <div className="frontdesk-queue-table">
                    <div className="frontdesk-queue-table-scroll">
                        <div className=" row queue-grid">
                            {
                                users && users.map((user, index) => {

                                    return (
                                        <div key={index} className="col-lg-4 mb-3">
                                            <InActiveInfo
                                                key={index}
                                                index={index}
                                                user={user}
                                                userid={user.id}
                                                handleShow={this.props.handleShow}
                                                showQueueNumber={false}
                                                text={'Enable Queue'}
                                                isQueueActive={false}
                                                handleUserQueue={this.props.handleUserQueue}
                                                frontdeskQueueActivation={frontdeskQueueActivation}
                                            ></InActiveInfo>
                                        </div>
                                    )


                                })
                            }
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
