import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
import _ from 'lodash'
import { allFleetProFields } from '../fleetPro/viewModel'
import CommonHelper from '../../services/common';
import { TableView, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import _images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import Translate from '../../constants/translate';
import { fleetAssignStatus, fleetAssignTypes, fleetProStatus } from '../../services/enum';
import AddFleet from '../fleetPro/add';
import AssignFleet from '../fleetPro/assign'
import VehicleBookings from '../fleetPro/calndar';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const FleetList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [fleetData, setFleetData] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [fleetProFields, setFleetProFields] = useState(props.dealersettings.fleetProFields)
    const [searchText, setSearchText] = useState('')
    const [csvHeader, setHeader] = useState([])
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [loader, setLoader] = useState();
    const [bookingsModal, setBookingsModal] = useState({ show: false, fleetProID: null })
    const [fleetAssignModal, setfleetAssignModal] = useState({ show: false, docID: null, fleetProID: null })
    const [fleetModal, setfleetModal] = useState({ show: false, docID: null })
    const accessToVehicleScheduler = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToVehicleScheduler;
    const accessToVehicleBooking = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToVehicleBooking;
    const accessToFleetHistory = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToFleetHistory;
    const accessToEditVehicle = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToEditVehicle;
    const accessToDeleteVehicle = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToDeleteVehicle;
    const accessToAssign = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToAssign;
    const readOnlyFleetVehicle = props?.dealersettings?.rolePermissions?.fleetProPermissions?.readOnlyFleetVehicle;
    const accessToRequest = props?.dealersettings?.rolePermissions?.fleetProPermissions?.accessToRequest && !readOnlyFleetVehicle;

    const fetchDirectExcel = () => {
        // console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    useEffect(() => {
        if (_.isEmpty(props.fleetdata))
            return

        setFleetData(props.fleetdata)

    }, [props.fleetdata])

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard) {
                    setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
                    Swal.fire({
                        icon: 'success',
                        title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                        showConfirmButton: false,
                        timer: 1500
                    })
                    toast.closeAll();
                }
                else
                    fetchDirectExcel();

            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        let headerFields = fleetProFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allFleetProFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allFleetProFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allFleetProFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(_headers);

    }, [fleetProFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ fleetProFields: fields }, { merge: true })
            .then(() => {
                setFleetProFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('fleetProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(true)
                console.error(error)
            });

    }

    const handleActionClick = (id, obj) => {
        const _fleetPro = obj?.objDoc ? obj.objDoc : obj
        const _fleetAssign = _fleetPro.fleetAssign?.filter(e => e.clientID && [fleetAssignTypes.RetailerEvent].includes(e.assignType) && [fleetAssignStatus.REQUESTED].includes(e.status))[0]
        return props.dealersettings.level === 'individual' ? (
            <>
                <div style={{ width: '250px' }}>

                    <div style={{ paddingTop: '5px', float: 'left' }}>
                        <a href="#" className="mini-button-fleet-green  ml-2" title={'Vehicle Scheduler'} onClick={(e) => {
                            e.preventDefault();
                            const SONO = CommonHelper.getFleetProDynamicField(obj.dynamicFields, 'sono', props.dealersettings?.client?.fleetPro?.fleetOptionsDF)
                            setBookingsModal({
                                show: true,
                                title: `Booking Calendar`,
                                subTitle: ` - ${obj?.make || ''} ${obj?.model || ''} ${SONO ? '(SONO: ' + SONO + ')' : ''}`,
                                fleetProID: id,
                                accessToVehicleScheduler,
                                accessToVehicleBooking
                            })
                        }}> <i className="ico icon-Calendar"></i></a>
                        {
                            accessToFleetHistory
                                ?
                                <a href="#" className="mini-button-fleet-orange ml-2" title={'Fleet History'} onClick={(e) => { e.preventDefault(); props.history.push("/fleetpro/details/" + id); }}> <i className="ico icon-history"></i></a>
                                :
                                <a href="#" className="mini-button-fleet-orange ml-2" title={'Fleet History'} onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                        }


                    </div>

                    <div className="service-section-tbl-button">
                        {
                            accessToRequest && [fleetProStatus.OPERATIONAL].includes(obj.statusValue)
                                ?
                                <>
                                    <button type="submit" className={`btn btn-sm btn-${!_.isEmpty(_fleetAssign) ? 'green' : 'primary'} float-left ml-2`} onClick={(e) => { e.preventDefault(); handleFleetAssignOpen(id, obj?.objDoc) }}>
                                        <i className="ico icon-close"></i> {!_.isEmpty(_fleetAssign) ? 'Requested' : 'Request'}
                                    </button>
                                </>
                                :
                                <button type="submit" className="btn btn-sm btn-primary float-left ml-2 opacity-fleet-btn" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}><i className="ico icon-lock"></i> Request</button>
                        }

                    </div>

                </div>

            </>
        ) : (<>
            <div style={{ width: '250px' }}>

                <div style={{ paddingTop: '5px', float: 'left' }}>
                    <a href="#" className="mini-button-fleet-green  ml-2" onClick={(e) => {
                        e.preventDefault();
                        const SONO = CommonHelper.getFleetProDynamicField(obj.dynamicFields, 'sono', props.dealersettings?.client?.fleetPro?.fleetOptionsDF)
                        setBookingsModal({
                            show: true,
                            title: `Booking Calendar`,
                            subTitle: ` - ${obj?.make || ''} ${obj?.model || ''} ${SONO ? '(SONO: ' + SONO + ')' : ''}`,
                            fleetProID: id,
                            accessToVehicleScheduler,
                            accessToVehicleBooking
                        })
                    }}> <i className="ico icon-Calendar"></i></a>
                    {
                        accessToFleetHistory
                            ?
                            <a href="#" className="mini-button-fleet-orange ml-2" onClick={(e) => { e.preventDefault(); props.history.push("/fleetpro/details/" + id); }}> <i className="ico icon-history"></i></a>
                            :
                            <a href="#" className="mini-button-fleet-orange ml-2" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }

                    {
                        accessToEditVehicle
                            ?
                            <a href="#" className="mini-button ml-2" onClick={(e) => { e.preventDefault(); handleFleetOpen(id) }}> <i className="ico icon-edit"></i></a>
                            :
                            <a href="#" className="mini-button ml-2" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }
                    {
                        accessToDeleteVehicle
                            ?
                            <a href="#" className="mini-button ml-2" onClick={(e) => { e.preventDefault(); handleDeleteFleet(id, obj) }}> <i className="ico icon-delete"></i></a>
                            :
                            <a href="#" className="mini-button ml-2" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}> <i className="ico icon-lock"></i></a>
                    }

                </div>

                <div className="service-section-tbl-button">
                    {
                        accessToAssign && [fleetProStatus.OPERATIONAL].includes(obj.statusValue)
                            ?
                            <button type="submit" className="btn btn-sm btn-primary float-left ml-2" onClick={(e) => { e.preventDefault(); handleFleetAssignOpen(id) }}><i className="ico icon-close"></i> Assign</button>
                            :
                            <button type="submit" className="btn btn-sm btn-primary float-left ml-2 opacity-fleet-btn" onClick={(e) => { e.preventDefault(); }} style={{ cursor: 'not-allowed' }}><i className="ico icon-close"></i> Assign</button>
                    }

                </div>

            </div >

        </>)
    }

    const enquiryDetailclick = (e, id, data) => {

    }

    const handleFleetAssignOpen = (fleetProID, docID) => {
        setfleetAssignModal({ show: true, docID: docID, fleetProID: fleetProID })
    }

    const handleFleetOpen = (docID) => {
        setfleetModal({ show: true, docID: docID })
    }

    const handleFleetClose = (id, data) => {
        setfleetModal({ show: false, docID: null })
    }

    const handleFleetAssignClose = (id, data) => {
        setfleetAssignModal({ show: false, docID: null, fleetProID: null })
    }

    const handleBookingModelClose = (id, data) => {
        setBookingsModal({ show: false })
    }

    const handleDeleteFleet = async (id, data) => {
        const { dealersettings } = props;
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            html: CommonHelper.showLocale(props, `You want to delete this fleet.`),
            text: data.title ? data.title : '',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {

                const updateRef = window.firebase.firestore().collection('fleetPro').doc(id);
                updateRef.update({
                    isDeleted: true,
                    modifiedBy: dealersettings ? dealersettings.id : '',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }).then((docRef) => {

                    //if (props.handleRouteRemove) props.handleRouteRemove({ id: id, clientid: data.clientID }, 'fleetPro');

                    toast.notify(`Fleet deleted successfully`, {
                        duration: 2000,
                    });

                    // if (!_.isEmpty(data)) {
                    //     dispatch({
                    //         type: "REMOVE_LIST",
                    //         data: data
                    //     });
                    // }

                    let logNotes = 'Fleet deleted';
                    if (!_.isEmpty(data) && data.title)
                        logNotes = 'Fleet: ' + data.title + ' deleted';

                    let _objLogData = {
                        notes: logNotes,
                        type: 'fleetPro',
                        subType: 'delete',
                        recordId: id,
                    }
                    _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                    CommonHelper.saveAuditLog(_objLogData)

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });

            }

        });

    }

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.fleetProPermissions)) ? props.dealersettings.rolePermissions.fleetProPermissions : null);


    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">

                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>
                                <div className="float-right">

                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchDirectExcel();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvDirectBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`fleet_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(fleetData, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                                                                showConfirmButton: false,
                                                                timer: 1500
                                                            })
                                                            toast.closeAll();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`fleet_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(fleetData, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                            </div>
                            {
                                props.isFromDashboard && loader ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(fleetData.filter(
                                                ({ make, model, regNo, vinNo }) => (!_.isEmpty(searchText) ?
                                                    (
                                                        make.toLowerCase().includes(searchText.toLowerCase()) ||
                                                        model.toLowerCase().includes(searchText.toLowerCase()) ||
                                                        regNo.toLowerCase().includes(searchText.toLowerCase()) ||
                                                        vinNo.toLowerCase().includes(searchText.toLowerCase()))
                                                    : true)), ['startDate.seconds'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={allFleetProFields}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(fleetProFields && fleetProFields.length > 0) ? fleetProFields : allFleetProFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={enquiryDetailclick}
                                            isSettings={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
            <PopUpModal show={fleetModal.show}>
                <AddFleet
                    {...props}
                    docID={fleetModal.docID}
                    show={fleetModal.show}
                    clsActive='overlay-modal active'
                    handleClose={handleFleetClose}
                    title={fleetModal.docID ? 'fleet' : 'addFleet'}
                >
                </AddFleet>
            </PopUpModal>
            <PopUpModal show={fleetAssignModal.show}>
                <AssignFleet
                    {...props}
                    docID={fleetAssignModal.docID}
                    fleetProID={fleetAssignModal.fleetProID}
                    show={fleetAssignModal.show}
                    clsActive='overlay-modal active'
                    handleClose={handleFleetAssignClose}
                    title={fleetAssignModal.docID ? 'fleet' : 'assignFleet'}
                >
                </AssignFleet>
            </PopUpModal>
            <PopUpModal show={bookingsModal.show}>
                <VehicleBookings
                    {...props}
                    {...bookingsModal}
                    handleClose={handleBookingModelClose}
                />
            </PopUpModal>

        </>
    );
}

export default FleetList;