/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash';
/** COMPONENTS */
import { ReactSelect, ReactTimePicker } from '../../../components';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import Swal from 'sweetalert2';
//#region  ASSIGN VARIABLES
let titles = [];
//#endregion
export default class LinkShare extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            activeTab: 'dealer',
            dealer: null,
            links: {},
            shareLinks: {},
            eoiLink: null,
            registrationlink: null,
            eoiSourceLink: null,
            registrationSourcelink: null,
            errors: {},
            loader: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadSettings();
    }

    componentWillUnmount() {
        window.unsubShareLinks && window.unsubShareLinks();
        this._isMounted = false;
    }

    loadSettings = async () => {
        const { dealersettings, eventsPro, eventSettings } = this.props;
        //console.log('eventSettings', eventSettings)
        const { clientIDs, level, levelID } = eventsPro;
        let _source = {};
        if (eventSettings?.source?.length) {
            eventSettings.source.forEach(rec => {
                _source = {
                    ..._source,
                    [rec.documentID]: {
                        eoi: rec.eoiURL,
                        registration: rec.registrationURL
                    }
                }
            })
        }
        let _clients = CommonHelper.getAllEventClientsDataByLevel(dealersettings, clientIDs, level, levelID)
        let _dealers = [];
        _clients.forEach(rec => {
            _dealers.push({
                label: rec.name,
                value: rec.id,
                data: rec,
                active: true,
                projectId: rec.projectId
            })
        })

        let _sources = [];
        eventSettings?.source && eventSettings.source.forEach((doc) => {
            _sources.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });
        window.unsubShareLinks = firestoreDB(dealersettings).firestore().doc(`eventsPro/${eventsPro.documentID}/shareLinks/${eventsPro.documentID}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    this.setState({
                        shareLinks: {
                            ...snap.data(),
                            ..._source
                        }
                    });
                } else {
                    this.setState({
                        shareLinks: {
                            ..._source
                        }
                    });
                }
            })
        this.setState({
            links: {
                dealer: _dealers,
                source: _sources
            }
        });


    }

    generateLink = async (val, data) => {
        const { dealersettings, eventsPro } = this.props;
        const { activeTab } = this.state;
        if (!_.isEmpty(data)) {
            const result = await Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'Do you want to overwrite url?.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            })

            if (!result.value) {
                return
            }
        }
        this.setState({
            loader: {
                [val]: true
            }
        })
        let eoiLink = null
        let registrationlink = null
        let apiURL = (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") ? "https://app.fusionamspro.com" : "https://qa.fusionamspro.com";
        let eoi_url = `${apiURL}/attendee/confirmation/eoi/${eventsPro?.documentID}?${activeTab === 'dealer' ? 'cid' : 'source'}=${val}&type=eoi`;
        let registration_url = `${apiURL}/attendee/confirmation/eoi/${eventsPro?.documentID}?${activeTab === 'dealer' ? 'cid' : 'source'}=${val}&type=registration`;
        const getShortURL_EOI = window.firebase.functions().httpsCallable('eventsPro-getShortURL');
        await getShortURL_EOI({ url: eoi_url }).then((response) => {
            eoiLink = response?.data
        });
        const getShortURL_REG = window.firebase.functions().httpsCallable('eventsPro-getShortURL');
        await getShortURL_REG({ url: registration_url }).then((response) => {
            registrationlink = response?.data
        });
        firestoreDB(dealersettings).firestore().doc(`eventsPro/${eventsPro.documentID}/shareLinks/${eventsPro.documentID}`)
            .set(
                {
                    [val]: {
                        eoi: eoiLink,
                        registration: registrationlink
                    },
                    projectId: eventsPro.projectId || null
                }, { merge: true })
            .then(snapshot => {
                toast.notify(`Links generated successfully`, {
                    duration: 2000
                })
                this.setState({
                    loader: {
                        [val]: false
                    }
                })
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }


    render() {
        const { activeTab, links, shareLinks, loader } = this.state;
        const { dealersettings } = this.props;
        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.eventsProPermissions)) ? dealersettings.rolePermissions.eventsProPermissions : null);
        const accessToRegenerateURL = ((!_.isEmpty(_permissions) && _permissions.accessToRegenerateURL) ? true : false);

        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-interest-url">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <img src={_images.link_share} width="30" className="mr-1" /> Expression of Interest - Link to Share
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        {
                            !_.isEmpty(links?.source) && (<div className="file-manager-nav pl-0">
                                <ul>
                                    <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'dealer' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ activeTab: 'dealer' })

                                        }}><Translate text={'dealer'} /> </a></li>

                                    <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'source' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ activeTab: 'source' })
                                        }}
                                    > <Translate text={'source'} /> </a></li>
                                </ul>
                            </div>)
                        }
                        <div className="interest-url-share-table">
                            <div className="common-table w-100">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="head-light" width="20%">{CommonHelper.autoCaps(activeTab)}</th>
                                            <th scope="col" className="head-light" width="35%">Expression of Interest</th>
                                            <th scope="col" className="head-light" width="35%">Registration</th>
                                            <th scope="col" className="head-light" width="10%">Action</th>
                                        </tr>
                                    </thead>
                                    {
                                        links[activeTab] ? (
                                            <tbody>
                                                {
                                                    links[activeTab].map((rec, index) => {
                                                        return <tr key={index}>
                                                            <td><div className="url-dealer-name">{rec.label}</div></td>
                                                            <td>
                                                                {
                                                                    shareLinks?.[rec.value]?.['eoi'] ? (
                                                                        <div className="interest-url-share-bg">
                                                                            <div className="url-copy-inner"> <a href="#" onClick={(e) => {
                                                                                CommonHelper.handleCopy(e, shareLinks?.[rec.value]?.['eoi'])
                                                                            }}><i className="fas fa-copy mr-1"></i></a></div>
                                                                            <a href={shareLinks?.[rec.value]?.['eoi']} target='_blank'>{shareLinks?.[rec.value]?.['eoi']}</a>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="interest-url-share-bg opacity-generatebox">
                                                                            Generate link to view
                                                                        </div>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    shareLinks?.[rec.value]?.['registration'] ? (
                                                                        <div className="interest-url-share-bg">
                                                                            <div className="url-copy-inner"> <a href="#" onClick={(e) => {
                                                                                CommonHelper.handleCopy(e, shareLinks?.[rec.value]?.['registration'])
                                                                            }}><i className="fas fa-copy mr-1"></i></a></div>
                                                                            <a href={shareLinks?.[rec.value]?.['registration']} target='_blank'>{shareLinks?.[rec.value]?.['registration']}</a>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="interest-url-share-bg opacity-generatebox">
                                                                            Generate link to view
                                                                        </div>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <div style={{ width: '115px' }}>
                                                                    {
                                                                        activeTab !== 'source' && (_.isEmpty(shareLinks?.[rec.value]) || accessToRegenerateURL) ? (
                                                                            <button type="button" className="btn btn-primary float-right " onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.generateLink(rec.value, shareLinks?.[rec.value])
                                                                            }}>
                                                                                {
                                                                                    loader[rec.value] ?
                                                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                        : (<i className="fa fa-link custom-icon-preview mr-1"></i>)
                                                                                }
                                                                                Generate</button>
                                                                        ) : (
                                                                            <div className="filter-item-btn w-100"><a href="#" className='w-100'><i className="ico icon-lock"></i></a></div>
                                                                        )
                                                                    }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        ) : (<></>)
                                    }
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}
