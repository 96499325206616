
/** LIBRARIES */
import React, { PureComponent } from "react";
import Swal from 'sweetalert2'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import moment from 'moment'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { AntDatePicker, ReactSelect } from '../../../components';
import CommonHelper from '../../../services/common';

import { default as _images } from '../../../images';
import { firestoreDB } from '../../../services/helper';
import { defaultFinanceTerms } from "../../../services/enum";

const _marks = {
    0.5: '0.5',
    1: '1',
    1.5: '1.5',
    2: '2',
    2.5: '2.5',
    3: '3',
    3.5: '3.5',
    4: '4',
    4.5: '4.5',
    5: '5',
};

export default class AddOns extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            btnLoader: false,
            btnDelete: false
        }

    }


    componentDidMount() {
        this.setState({ fields: this.props.info })
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.info) !== JSON.stringify(prevProps.info)) {
            this.setState({ fields: this.props.info })
        }
    }

    componentWillUnmount() {
        this.setState({ fields: {} })
    }

    onAmountChange = (e, name) => {
        const fields = Object.assign([], this.state.fields);
        fields[name] = _.isNumber(e.floatValue) ? e.floatValue : null;
        this.setState({ fields: fields }, () => { this.errorChange(name) })
    }

    onChange = (value, name) => {
        const fields = Object.assign([], this.state.fields);
        fields[name] = value;
        this.setState({ fields: fields }, () => { this.errorChange(name) })
    };

    onChangeComplete = (value, name) => {
        const fields = Object.assign([], this.state.fields);
        fields[name] = value;
        this.setState({ fields: fields }, () => { this.errorChange(name) })
    };

    handleSelectChange = (e, name) => {
        const fields = Object.assign([], this.state.fields);
        fields[name] = (e?.value || null);
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleDateChange = (val, name) => {
        const fields = Object.assign([], this.state.fields);
        fields[name] = val ? window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()) : null;
        fields['terms'] = val && !fields['terms'] ? 1 : fields['terms'];
        this.setState({ fields: fields }, () => { this.errorChange(name) })
    };

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = this.state.fields;
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                errors[key] = errorClass;
            }
            else {
                errors[key] = '';
            }
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';

        // const { fields } = this.state;
        // Object.assign([], ['terms', 'startDate', 'price']).forEach((key) => {
        //     if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !_.isBoolean(fields[key]) && !_.isNumber(fields[key]) && !fields[key])) {
        //         formIsValid = false;
        //         errors[key] = errorClass;
        //     }
        // });

        // this.setState({ errors: errors })

        if (formIsValid) {
            this.handleSave();
        }
        else {
            return formIsValid;
        }
    }

    handleSave = () => {
        const { dealersettings, enquiryID, enquiry, handleSaved } = this.props;
        this.setState({ btnLoader: true })
        const objData = Object.assign({}, this.state.fields);
        let _txt = 'updated'
        if (_.isEmpty(objData.documentID)) {
            objData.addedBy = dealersettings ? dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
            objData.enquiryID = enquiryID;
            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
            objData.clientID = enquiry?.clientID ? enquiry?.clientID : dealersettings?.client?.id ? dealersettings?.client?.id : null;
            objData.projectId = enquiry?.projectId ? enquiry?.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            objData.isDeleted = false;
            _txt = 'added'
        }

        objData.modifiedBy = dealersettings ? dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (objData.startDate && objData.terms) {
            let _endDate = moment(moment.unix(objData.startDate.seconds)._d).add(objData.terms, 'years').add(-1, 'day')._d;
            objData.endDate = window.firebase.firestore.Timestamp.fromDate(_endDate)
        }
        else objData.endDate = objData.startDate

        const productAllStatus = Object.assign([], dealersettings?.client?.settings?.productStatus)
        if (!objData.status && !_.isEmpty(productAllStatus) && productAllStatus.length > 0)
            objData.status = (productAllStatus[0]?.value || null);

        for (var propName in objData) {
            if (objData[propName] === undefined || objData[propName] === Object.assign([]) ||
                (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        const updateRef = firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/productAddons/${objData.documentID}`);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            toast.notify('Product saved successfully', {
                duration: 2000
            })

            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.enquiryID, `Product: ${objData.title || ''} ${_txt}.`, 'product', 'enquiry');

            this.setState({ btnLoader: false })
            if (handleSaved) handleSaved(objData);

        }).catch((error) => {
            console.error("Error updating details: ", error);
        });
    }

    handleRemove = (objData) => {
        const { dealersettings } = this.props;
        const { enquiryID, documentID } = objData;
        if (enquiryID && documentID) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'Do you want to delete.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            }).then((result) => {
                if (result.value) {
                    firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/productAddons/${documentID}`).delete();
                    CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.enquiryID, `Product: ${objData.title || ''} deleted.`, 'product', 'enquiry');
                }
            })
        }


    }




    render() {

        const { provider, startDate, costPrice, rrp, grossProfit, terms, subList, id, documentID, status } = this.state.fields;
        const { errors, btnLoader, btnDelete, fields } = this.state;
        const { dealersettings } = this.props;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const formatter = (val) => CommonHelper.formatYear(val);

        let _productAllStatus = [];
        const productAllStatus = Object.assign([], dealersettings?.client?.settings?.productStatus)
        productAllStatus && productAllStatus.forEach((doc) => {
            _productAllStatus.push({
                ...doc,
                label: <div className={`status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
            });
        });

        let productProviders = Object.assign([], dealersettings?.client?.settings?.productProviders);

        const accessToAddProduct = (dealersettings?.rolePermissions?.permissions?.accessToAddProduct) ? true : false;
        const accessToDeleteProduct = (dealersettings?.rolePermissions?.permissions?.accessToDeleteProduct) ? true : false;

        let _frmClass = btnLoader || btnDelete || !accessToAddProduct ? 'form-disable' : ''
        return (
            <>

                <div className="form-style product-addon-popup-inner w-100">

                    <div className={`form-row ${_frmClass}`}>
                        {
                            !_.isEmpty(productProviders) && (<div className="form-group col-md-4">
                                <label>Insurance Provider</label>
                                <ReactSelect
                                    options={_.map(productProviders, function (e) { return { ...e, label: e.name } })}
                                    id={`provider_${id}`}
                                    name={`provider_${id}`}
                                    placeholder={'select provider'}
                                    onChange={(e) => { this.handleSelectChange(e, 'provider') }}
                                    value={provider ? provider : null}
                                    classNamePrefix={`cursor-pointer basic-select`}
                                    removeClearable={false}
                                >
                                </ReactSelect>
                            </div>)
                        }


                        <div className="form-group col-md-2">
                            <label>Start Date</label>
                            <AntDatePicker
                                value={startDate ? moment.unix(startDate.seconds).format('YYYY-MM-DD') : null}
                                name={`startDate_${id}`}
                                onChange={(e) => { this.handleDateChange(e, 'startDate') }}
                                format='DD/MM/YYYY'
                                placeholder='DD/MM/YYYY'
                                className={`form-control ${errors[`startDate`]}`}
                            />
                        </div>

                        <div className="form-group col-md-2">
                            <label>Terms (in years)</label>
                            <ReactSelect
                                options={defaultFinanceTerms}
                                id={`terms_${id}`}
                                name={`terms_${id}`}
                                placeholder={'select terms'}
                                onChange={(e) => { this.handleSelectChange(e, 'terms') }}
                                value={terms ? terms : null}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                                maxMenuHeight={150}
                            >
                            </ReactSelect>
                        </div>

                        <div className="form-group col-md-4">
                            <label>Status</label>
                            <ReactSelect
                                options={_productAllStatus}
                                id={`status_${id}`}
                                name={`status_${id}`}
                                placeholder={'select status'}
                                onChange={(e) => { this.handleSelectChange(e, 'status') }}
                                value={status ? status : null}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect>
                        </div>

                        <div className="form-group col-md-4 form-disable">
                            <label>Cost Price</label>
                            <NumberFormat
                                id={`costPrice_${id}`}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                prefix={currencySymbol}
                                placeholder={currencySymbol}
                                className={`form-control ${errors[`costPrice`]}`}
                                onValueChange={e => { }}
                                value={_.isNumber(costPrice) ? costPrice : null}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>RRP</label>
                            <NumberFormat
                                id={`rrp_${id}`}
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                prefix={currencySymbol}
                                placeholder={currencySymbol}
                                className={`form-control ${errors[`rrp`]}`}
                                onValueChange={e => this.onAmountChange(e, 'rrp')}
                                value={_.isNumber(rrp) ? rrp : null}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Gross Profit</label>
                            <NumberFormat
                                id={`grossProfit_${id}`}
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                prefix={currencySymbol}
                                placeholder={currencySymbol}
                                className={`form-control ${errors[`grossProfit`]}`}
                                onValueChange={e => this.onAmountChange(e, 'grossProfit')}
                                value={_.isNumber(grossProfit) ? grossProfit : null}
                            />
                        </div>


                        {/* <div className="form-group col-md-12">
                            <label>Terms (in years)</label>
                            <Slider
                                id={`terms_${value}`}
                                max={5}
                                min={0.5}
                                step={1}
                                value={_.isNumber(terms) ? terms : null}
                                marks={_marks}
                                tooltip={{ formatter }}
                                className={errors[`terms`] ? 'input_line_error' : ''}
                                onChange={(val) => this.onChangeComplete(val, 'terms')}
                                onAfterChange={(val) => this.onChangeComplete(val, 'terms')}
                            />
                        </div> */}

                    </div>

                    {accessToAddProduct && (<div className="product-addon-popup-inner-btn">

                        <button type="button"
                            className={`btn btn-primary float-right ${_frmClass}`}
                            onClick={(e) => { this.handleSubmit(e); }}>
                            {btnLoader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={documentID ? 'save' : 'add'} />
                        </button>
                        {documentID && accessToDeleteProduct && (<button type="button"
                            className={`btn btn-red float-right mr-2 ${_frmClass}`}
                            onClick={(e) => { e.preventDefault(); this.handleRemove(fields); }}>
                            {btnDelete ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'delete'} />
                        </button>)}

                    </div>)}


                </div>


            </>


        );
    }
}

