/** LIBRARIES */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap'
import _ from 'lodash';
/** PROVIDERS */
//import { PipelineProvider, PipelineConsumer } from './PipelineProvider';
import { LayoutConsumer } from '../../layout/provider';
import { RouteConsumer } from '../../layout/RouteProvider';
import { StageConsumer } from '../provider';
/** COMPONENTS */
//import { default as _images } from "../../../images";
import QuickInfo from './info'

class QuickView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSharePanel: {
                chatClsActive: '',
                chatClsName: 'sidebar',
            }
        }
    }

    render() {
        //console.log('RE RENDER QUICK VIEW')
        return (
            <LayoutConsumer>
                {({ dialNumber }) => (
                    <RouteConsumer>
                        {({ handleRouteChange, updatenavbar, handleRouteRemove }) => (
                            <>
                                <QuickInfo
                                    tradeinproid={this.props.tradeinproid}
                                    handleRouteChange={handleRouteChange}
                                    dealersettings={this.props.dealersettings}
                                    clientUsers={this.props.clientUsers}
                                    groupUsers={this.props.groupUsers}
                                    history={this.props.history}
                                    //updateDealerSettings={updateDealerSettings}

                                    updateTradeinPro={this.props.updateTradeinPro}
                                    updateListEnquiry={this.props.updateListEnquiry}
                                    isFilterApplied={this.props.isFilterApplied}
                                    sidepanelClose={this.props.sidepanelClose}
                                    updatenavbar={updatenavbar}
                                    handleRouteRemove={handleRouteRemove}
                                    isReadOnlyView={this.props.isReadOnlyView}
                                    handleModuleChange={this.props.handleModuleChange}
                                    isHideExpandView={this.props.isHideExpandView}
                                    dialNumber={dialNumber}
                                >
                                </QuickInfo>
                            </>
                        )}
                    </RouteConsumer>
                )}
            </LayoutConsumer>

        );
    }
}

export default withRouter(QuickView);