import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import BeePluginEditor from './beepluginEditor'
import { mentions, templateVM } from './viewModel'
import BASE_TEMPLATE from './blankTemplate.json';
import Addtemplate from './addTemplate'
import { PopUpModal } from '../../../../components';
import Translate from '../../../../constants/translate';
import { TemplateContext } from '../templateContext'
import CommonHelper from '../../../../services/common';
import SendTestEmail from './sendTestEmail'

const beeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}

const TemplateEditor = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [showEditModel, setShowEditModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const newTemplate = !_.isEmpty(props.template) ? false : true
    const { clients, groups } = useContext(TemplateContext);
    const [selectedClient, setSelectedClient] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [sendEmailModel, setSendEmailModel] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        setRegionOptions(_.filter(props.dealersettings.regions, (v) => _.indexOf(_.uniq(_.map(clients, 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    data: rec
                }
            }))
        setGroupOptions(groups.map((rec) => {
            return {
                value: rec.documentID,
                label: rec.name,
                data: rec,
                active: _.isBoolean(rec.active) ? rec.active : true,
                regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
            }
        }))

    }, [clients, groups])

    useEffect(() => {
        if (_.isEmpty(props.template)) {
            let newtemplateVM = Object.assign({}, templateVM);
            newtemplateVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/servicetemplates/`).doc().id;
            //newtemplateVM.clientID = props.dealersettings.client.id;
            newtemplateVM.addedBy = localStorage.uid;
            newtemplateVM.modifiedBy = localStorage.uid;
            newtemplateVM.addedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newtemplateVM.templateJSON = BASE_TEMPLATE
            newtemplateVM.visibility = 'private'
            newtemplateVM.type = props.type ? props.type : 'email'
            newtemplateVM = CommonHelper.setClientOrGroupOrRegionOrOemID(newtemplateVM, props.dealersettings, null, null, true);
            setTemplate(newtemplateVM)
        }
    }, [])

    const handleModelClose = (template) => {
        setShowEditModel(false)
        setSendEmailModel(false)
        if (!_.isEmpty(template)) {
            if (props.returnAfterCreation) {
                props.handleClose(template)
            } else {
                props.handleClose()
            }
        }
    }

    const save = (filename, content) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: 'text/plain;charset=utf-8' });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    };

    return _.isEmpty(template) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen">
                            <main role="main" className="container">
                                <BeePluginEditor
                                    {...props}
                                    className="containerClassName"
                                    template={newTemplate ? BASE_TEMPLATE : JSON.parse(template.templateJSON)}
                                    style={beeStyles}
                                    onSave={(jsonFile, htmlFile) => {
                                        setTemplate({
                                            ...template,
                                            ['bodyHTML']: htmlFile,
                                            ['templateJSON']: jsonFile
                                        })
                                        setPopHeader(newTemplate ? 'Add Template' : 'Edit template');
                                        setShowEditModel(true)
                                    }}
                                    onSend={htmlFile => {
                                        setTemplate({
                                            ...template,
                                            ['bodyHTML']: htmlFile
                                        })
                                        setPopHeader('Send Test Email');
                                        setSendEmailModel(true)
                                    }}
                                    onSaveAsTemplate={jsonFile => { save('email-template.json', jsonFile) }}
                                    onError={errorMessage => console.log(errorMessage)}
                                />
                            </main>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <PopUpModal show={showEditModel}>
                <Addtemplate
                    {...props}
                    show={showEditModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    template={template}
                    newTemplate={newTemplate}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
            <PopUpModal show={sendEmailModel}>
                <SendTestEmail
                    {...props}
                    show={sendEmailModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    template={template}
                    mentions={mentions}
                />
            </PopUpModal>
        </>
    )
}

export default TemplateEditor