import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect, AntDateRangePicker } from "../../components";
import { pipelineCountVMNEW } from './viewModel'
// import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState(pipelineCountVMNEW)
    const [pipelineOptions, setPipelineOption] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [dates, setDates] = useState([]);
    const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings.client.timezone)) {
    //         setTodayStart(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).startOf('day')._d))
    //         setTodayEnd(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).endOf('day')._d))
    //     }
    // }, [])

    const tradeinStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.tradeinPro) &&
        props.dealersettings.client.tradeinPro.tradeinProStatus) ? props.dealersettings.client.tradeinPro.tradeinProStatus : []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true)
                let dataToSet = {}


                let _filter = {
                    clientID: CommonHelper.getTradeinProDealers(props.dealersettings, false, true),
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
                }

                if (props.selectedPipeline) {
                    _filter.pipeline = props.selectedPipeline.value
                }
                else if (!_.isEmpty(props.pipeline)) {
                    _filter.pipeline = props.pipeline.map(item => item.value).join(',')
                }

                if (props.selectedUser) {
                    _filter.owner = props.selectedUser.value
                }
                if (props.selectedClient) {
                    _filter.clientID = props.selectedClient.value
                }
                if (props.dateRange) {
                    _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }

                let _searchObject = {
                    type: "getTradeinProDashboardCounts",
                    params: JSON.stringify(_filter),
                }

                const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getData');
                const resp = await getDashboardCounts(_searchObject)

                if (resp.data.success) {
                    const data = resp.data.data
                    //console.log('data returned', data)

                    dataToSet = {
                        ...dataToSet,
                        newToday: _.sumBy(data, 'newToday'),
                        newMonth: _.sumBy(data, 'newMonth'),
                        awaitingOffersToday: _.sumBy(data, 'awaitingOffersToday'),
                        awaitingOffersMonth: _.sumBy(data, 'awaitingOffersMonth'),
                        approvalsToday: _.sumBy(data, 'approvalsToday'),
                        approvalsMonth: _.sumBy(data, 'approvalsMonth'),
                        completedToday: _.sumBy(data, 'completedToday'),
                        completedMonth: _.sumBy(data, 'completedMonth'),
                        wonToday: _.sumBy(data, 'wonToday'),
                        wonMonth: _.sumBy(data, 'wonMonth'),
                        lostToday: _.sumBy(data, 'lostToday'),
                        lostMonth: _.sumBy(data, 'lostMonth'),
                    }
                } else {
                    // console.log('data not returned')
                }
                setCounts(dataToSet)
                setLoader(false)

            } catch (error) {
                console.error(error)
                setLoader(false)
            }
        }

        fetchData()
    }, [props.selectedPipeline, props.selectedUser, props.selectedClient, props.dateRange, props.refreshData, props.dealersettings.client.id])

    useEffect(() => {
        if (_.isEmpty(props.pipeline))
            return
        let _pipeline = [];
        props.pipeline.forEach(rec => {
            _pipeline.push({
                label: rec.name,
                active: _.isBoolean(rec.active) ? rec.active : true,
                value: rec.value,
                data: rec
            })
        })
        setPipelineOption(_pipeline)
    }, [props.pipeline])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return

        let _users = [];
        const { dealersettings } = props;

        if (!_.isEmpty(dealersettings.client.tradeinPro) && !_.isEmpty(dealersettings.client.tradeinPro.pipelines)) {
            const selectedPipelines = Array.prototype.map.call(dealersettings.client.tradeinPro.pipelines, function (item) { return item.value; });

            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.modules && e.modules.tradeinPro === true), function (obj) {
                return obj.documentID;
            })];

            if (!_.isEmpty(roleIds)) {
                _.filter(props.clientUsers, (v) => _.includes(roleIds, v.roleID)).forEach(rec => {
                    _users.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>,
                        data: rec
                    })
                })
            }

        }

        setUserOptions(_users)


    }, [props.clientUsers])

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    return (
        <div className="dashboard-panel-top">
            {loader && <div className='custom-counts-loader' />}
            <div className="dashboard-panel-head">
                <div className="float-left d-flex">
                    {
                        !_.isEmpty(props.selectedPipeline) ? (<h3>{props.selectedPipeline.label} Stats</h3>) : (<h3><Translate text={'allPipelineStats'} /></h3>)
                    }
                    {loader && <div className="align-items-center d-flex justify-content-center text-primary spinner-loader-h3"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div>}
                </div>
                <div className="float-lg-right form-dashboard">
                    <div className="form-row justify-content-end">
                        <div className="form-group col-md-3 daterange-fix">
                            <AntDateRangePicker
                                value={props.dateRange && props.applydateRange === true ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={'dateDashboard'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { props.handleDateRangeChange(e, 'dateDashboard') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                                disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={pipelineOptions}
                                name={"pipeline"}
                                placeholder={'select pipeline'}
                                onChange={(e, data) => {
                                    props.setPipeline(e)
                                }}
                                value={!_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : ''}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect>
                        </div>
                        <div className="form-group col-md-3"><ReactSelect
                            options={userOptions}
                            name={"user"}
                            placeholder={'select user'}
                            onChange={(e, data) => {
                                props.setUser(e)
                            }}
                            value={!_.isEmpty(props.selectedUser) ? props.selectedUser.value : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={false}
                        >
                        </ReactSelect> </div>

                        <div className="form-group col-md-3"><ReactSelect
                            options={props.dealersettings.clients &&
                                props.dealersettings.clients.filter(a => CommonHelper.getTradeinProDealers(props.dealersettings, true, true).includes(a.documentID)).map(rec => {
                                    return {
                                        label: rec.name,
                                        value: rec.documentID
                                    }
                                })}
                            name={"client"}
                            placeholder={'select client'}
                            onChange={(e, data) => {
                                props.setClient(e)
                            }}
                            value={!_.isEmpty(props.selectedClient) ? props.selectedClient.value : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={false}
                        >
                        </ReactSelect> </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-panel-body">
                <div className=" row col-lg-3-fix">
                    <div className="col-lg-4">
                        <div className="row">

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-tradein-new")}></i></div>
                                    <div className="dashboard-module-label">
                                        {
                                            _.find(tradeinStatus, { value: 'New' }) ? `${_.find(tradeinStatus, { value: 'New' }).name}` : 'New'
                                        }
                                    </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.newToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'New',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'addedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today New Appraisal', counts.newToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.newToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.newMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'New',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'addedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Appraisals' : 'Appraisals'), counts.newMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.newMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-tradein-awaiting")}></i></div>
                                    <div className="dashboard-module-label">
                                        {
                                            _.find(tradeinStatus, { value: 'InProgress' }) ? `${_.find(tradeinStatus, { value: 'InProgress' }).name}` : 'InProgress'
                                        }
                                    </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.awaitingOffersToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'InProgress',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'modifiedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, `Today ${_.find(tradeinStatus, { value: 'InProgress' }) ? `${_.find(tradeinStatus, { value: 'InProgress' }).name}` : 'InProgress'}`, counts.awaitingOffersToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.awaitingOffersToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.awaitingOffersMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'InProgress',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'modifiedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? `MTD ${_.find(tradeinStatus, { value: 'InProgress' }) ? `${_.find(tradeinStatus, { value: 'InProgress' }).name}` : 'InProgress'}` : `${_.find(tradeinStatus, { value: 'InProgress' }) ? `${_.find(tradeinStatus, { value: 'InProgress' }).name}` : 'InProgress'}`), counts.awaitingOffersMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.awaitingOffersMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-tradein-pending")}></i></div>
                                    <div className="dashboard-module-label">
                                        {
                                            _.find(tradeinStatus, { value: 'Pending' }) ? `${_.find(tradeinStatus, { value: 'Pending' }).name}` : 'Pending'
                                        }
                                    </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.approvalsToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'Pending',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'modifiedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, `Today ${_.find(tradeinStatus, { value: 'Pending' }) ? `${_.find(tradeinStatus, { value: 'Pending' }).name}` : 'Pending'}`, counts.approvalsToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.approvalsToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.approvalsMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'Pending',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'modifiedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? `MTD ${_.find(tradeinStatus, { value: 'Pending' }) ? `${_.find(tradeinStatus, { value: 'Pending' }).name}` : 'Pending'}` : `${_.find(tradeinStatus, { value: 'Pending' }) ? `${_.find(tradeinStatus, { value: 'Pending' }).name}` : 'Pending'}`), counts.approvalsMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.approvalsMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-delivered")}></i></div>
                                    <div className="dashboard-module-label"> {
                                        _.find(tradeinStatus, { value: 'Completed' }) ? `${_.find(tradeinStatus, { value: 'Completed' }).name}` : 'Completed'
                                    }</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.completedToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'Completed',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'completedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, `Today ${_.find(tradeinStatus, { value: 'Completed' }) ? `${_.find(tradeinStatus, { value: 'Completed' }).name}` : 'Completed'}`, counts.completedToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.completedToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.completedMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'Completed',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'completedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? `MTD ${_.find(tradeinStatus, { value: 'Completed' }) ? `${_.find(tradeinStatus, { value: 'Completed' }).name}` : 'Completed'}` : `${_.find(tradeinStatus, { value: 'Completed' }) ? `${_.find(tradeinStatus, { value: 'Completed' }).name}` : 'Completed'}`), counts.completedMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.completedMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-won-leads")}></i></div>
                                    <div className="dashboard-module-label"> {
                                        _.find(tradeinStatus, { value: 'Won' }) ? `${_.find(tradeinStatus, { value: 'Won' }).name}` : 'Won'
                                    } </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.wonToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'Won',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'wonDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Won', counts.wonToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.wonToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.wonMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'Won',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'wonDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? `MTD ${_.find(tradeinStatus, { value: 'Won' }) ? `${_.find(tradeinStatus, { value: 'Won' }).name}` : 'Won'}` : `${_.find(tradeinStatus, { value: 'Won' }) ? `${_.find(tradeinStatus, { value: 'Won' }).name}` : 'Won'}`), counts.wonMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.wonMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-module-icon"><i className={CommonHelper.getVehicleIcons(props?.dealersettings?.client?.category, "ico icon-lost-leads")}></i></div>
                                    <div className="dashboard-module-label"> {
                                        _.find(tradeinStatus, { value: 'Lost' }) ? `${_.find(tradeinStatus, { value: 'Lost' }).name}` : 'Lost'
                                    } </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.lostToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'Lost',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'lostDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, `Today ${_.find(tradeinStatus, { value: 'Lost' }) ? `${_.find(tradeinStatus, { value: 'Lost' }).name}` : 'Lost'}`, counts.lostToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.lostToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.lostMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'Lost',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'lostDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? `MTD ${_.find(tradeinStatus, { value: 'Lost' }) ? `${_.find(tradeinStatus, { value: 'Lost' }).name}` : 'Lost'}` : `${_.find(tradeinStatus, { value: 'Lost' }) ? `${_.find(tradeinStatus, { value: 'Lost' }).name}` : 'Lost'}`), counts.lostMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.lostMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default PipelineCounts;