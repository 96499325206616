/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';

const OwnedVehicleInfo = ({ requirement, handleEdit, isDeivered, dealersettings }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        documentID,
        make, model, saleType, type, currentlyOwned,
        year, exteriorColor, regNo, vinNo,
    } = requirement;


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className="vehicle-item flex-vehicle" id={'veh_' + documentID}>
            <div className="flex-vehicle">
                <div className="quickview-activity-more">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setEditshow(!editshow)
                        if (editshow)
                            document.addEventListener('click', setEditshow(false));
                    }}><i className="ico icon-more"></i></a>
                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                        <ul className="more-dropdown">
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(documentID) }}>
                                    {
                                        (isDeivered)
                                            ?
                                            <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                                            :
                                            <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                                    }
                                </a>
                            </li>
                            {/* <li> <a href="#" onClick={(e) => { }}> <i className="ico icon-delete"></i> Delete </a> </li> */}
                        </ul>
                    </div>
                </div>
                <div className="vehicle-item-image" onClick={(e) => { e.preventDefault(); handleEdit(documentID) }}>
                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                        <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                    </a>
                </div>

                <div className="vehicle-data" onClick={(e) => { e.preventDefault(); handleEdit(documentID) }}>
                    <div className="vehicle-item-title mini-badge">{make} {model}</div>
                    <div className="vehicle-item-info">{exteriorColor ? `${exteriorColor},` : ''} {year ? year : ''}</div>                    
                    <div className="vehicle-item-info"><Translate text={'regNo'} upperCase={true} />: {regNo ? regNo : '--'} </div>
                    {vinNo ? <div className="vehicle-item-info"><Translate text={'vinNo'} upperCase={true} />: {vinNo} </div> : <></>}
                    <div className="tradin-item-button">
                        <div className={`badge badge-pill badge-${currentlyOwned ? 'available' : 'sold'}-fill`} >
                        <Translate text={currentlyOwned ? 'currentlyOwned' : 'Previously Owned'} />
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default OwnedVehicleInfo;


