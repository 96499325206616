/** LIBRARIES */
import React, { Component, useEffect } from 'react'
import toast from 'toasted-notes';
import { OverlayTrigger, Tooltip, Card, Dropdown, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { PopUpModal, ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { tipOfferStatus, tipStatus } from '../../../services/enum';
import AddNotes from '../../tradeinplus/offerNotes/addWholesalerNote';
import OfferLogs from '../../tradeinplus/offerLogs/index'

import { objTradeIn, objMark } from '../../tradeIn/viewModel'
import { objTradeIPOffers, objOfferNotesVM } from '../../tradeinplus/viewModel'
import { firestoreDB } from '../../../services/helper';

class TradeInDetails extends Component {

    constructor(props) {
        super(props);
        this.unsubscribeService = null;

        this.state = {
            loader: false,
            loading: false,
            offerID: null,
            tradeInOffer: Object.assign({}, objTradeIPOffers),
            tradeIn: Object.assign({}, objTradeIn),
            recommendations: Object.assign([]),
            errorFields: Object.assign({}),
            wholesalerPrice: null,
            wholesalerNote: null,
            showNotes: false,
            showOfferLog: false
        }
    }

    handleSpaceScrollLock = (e) => {
        if (e.keyCode == 32 && e.target == document.body) {
            e.preventDefault();
        }
    }

    updateDimensions() {
        var tabHeight = (window.innerHeight - 265)
        var activitytabHeight = (window.innerHeight - 300)
        document.documentElement.style.setProperty('--quicktabHeight', tabHeight + 'px');
        document.documentElement.style.setProperty('--activitytabHeight', activitytabHeight + 'px');
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.loadTradeIn();
        }

        window.addEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidUpdate() {
        let classList = document.getElementsByTagName('body')[0].classList.value;
        if (classList.indexOf('enquiry-bg') >= 0 && classList.indexOf('inner-bg') >= 0) {
            //console.log('componentDidUpdate-classList', classList.indexOf('enquiry-bg'), classList.indexOf('inner-bg'));
            document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        }
        if (this.state.loading) {
            window?.refCollectionData?.unsubscribe && window.refCollectionData.unsubscribe()
            this.loadTradeIn();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.offerid !== state.offerID && state.offerID) {
            return {
                loading: true,
                offerID: null,
            };
        } else {
            return {
                loading: false,
            };
        }
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        window.removeEventListener('keydown', (e) => { this.handleSpaceScrollLock(e) });

        window.refCollectionData && window.refCollectionData.unsubscribe()
        if (window.location.pathname.indexOf('wholesaler/details') < 0) {
            if (this.props.resetnavbar) this.props.resetnavbar();
        }
        this._isMounted = false;

        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    loadTradeIn = async () => {
        const { offerid, dealersettings } = this.props;
        //console.log('loadTradeIn', offerid)
        window.refCollectionData = collectionData(firestoreDB(dealersettings).firestore().collectionGroup(`offers`).where('documentID', '==', offerid).limit(1))
            .pipe(
                switchMap(_tradeIns => {
                    //console.log(_tradeIns);
                    let tradeIns = _tradeIns[0] ? _tradeIns[0] : {}

                    // // //add tradeinpro into routes
                    // this.props.handleRouteChange({
                    //     name: tradeIns?.tradeinPro?.tradein?.make ? (tradeIns?.tradeinPro?.tradein?.make + ' ' + tradeIns?.tradeinPro?.tradein?.model) : offerid,
                    //     id: offerid,
                    //     displayID: tradeIns?.tradeinPro?.displayID ? tradeIns.tradeinPro.displayID : offerid,
                    //     status: tradeIns?.status,
                    //     active: true,
                    //     clientid: localStorage.uid,
                    //     isNew: null,
                    //     params: '',
                    //     module: 'wholesaler'
                    // }, 'wholesaler');


                    const enqIds = tradeIns.tradeinProID ? [tradeIns.tradeinProID] :
                        (tradeIns?.tradeinPro?.documentID ? [tradeIns.tradeinPro.documentID] : []);
                    const tradeIds = tradeIns?.tradeinPro?.tradeinID ? [tradeIns.tradeinPro.tradeinID] :
                        (tradeIns?.tradeinPro?.tradein?.documentID ? [tradeIns.tradeinPro.tradein.documentID] : []);
                    const clientIds = tradeIns.clientID ? [tradeIns.clientID] :
                        (tradeIns?.tradeinPro?.clientID ? [tradeIns.tradeinPro.clientID] : []);
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection('tradeinPro').where('documentID', '==', enquiryID)).pipe(
                                    map(proSnapshot => proSnapshot[0] ? proSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            tradeIds.length > 0 ? tradeIds.map(tradeID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection('tradeins').where('documentID', '==', tradeID)).pipe(
                                    map(tradeinSnapshot => tradeinSnapshot[0] ? tradeinSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(enquiryID =>
                                collectionData(firestoreDB(dealersettings).firestore().collection(`tradeinPro/${enquiryID}/offerNotes`).where('offerID', '==', offerid)).pipe(
                                    map(notesSnapshot => notesSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        // combineLatest(
                        //     clientIds.length > 0 ? clientIds.map(clientID =>
                        //         collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                        //             map(usersSnapshot => usersSnapshot)
                        //         )
                        //     ) : of([])
                        // ),
                    )
                }),
                map(([tradeIns, proSnapshot, tradeinSnapshot, notesSnapshot, clientSnapshot, clientSettingsSnapshot]) => {

                    const offerNotes = [];
                    notesSnapshot[0] && notesSnapshot[0].forEach((doc) => {
                        const _notes = Object.assign({}, objOfferNotesVM);
                        for (let [key, value] of Object.entries(doc)) {
                            _notes[key] = value;
                        }

                        if (!_notes.isDeleted && ((_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                            (_notes.isInternalNotes === true && _notes.wholeSalerNotes === true) || // isWholesalerInternalNotes 
                            (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
                        )) offerNotes.push(_notes);
                    });

                    return {
                        ...tradeIns,
                        tradeinPro: proSnapshot[0] ? proSnapshot[0] : tradeIns.tradeinPro,
                        tradein: tradeinSnapshot[0] ? tradeinSnapshot[0] : (tradeIns.tradeinPro && tradeIns.tradeinPro.tradein ? tradeIns.tradeinPro.tradein : {}),
                        offerNotes: offerNotes,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0],
                        //users: usersSnapshot[0],
                    }

                })
            )
            .subscribe(_tradeIns => {

                //console.log('_tradeIns', _tradeIns)
                if (!_.isEmpty(_tradeIns)) {

                    this.setState({
                        offerID: offerid,
                        tradeInOffer: Object.assign({}, _tradeIns),
                        tradeIn: Object.assign({}, _tradeIns.tradein),
                    })
                }

            });

    }

    handleMoveStageForStatusChange = (_status, state) => {
        const { dealersettings } = this.props;
        const { tradeInOffer } = this.state;
        // let _client = tradeInOffer.clientID && !_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.group) &&
        //     !_.isEmpty(dealersettings.group.clients) &&
        //     dealersettings.group.clients[tradeInOffer.clientID] &&
        //     dealersettings.group.clients[tradeInOffer.clientID].tradeinPro ? dealersettings.group.clients[tradeInOffer.clientID] : dealersettings.client;

        // const _settings = _client && _client.tradeinPro;
        const _settings = ((!_.isEmpty(tradeInOffer) &&
            !_.isEmpty(tradeInOffer.currentSettings) &&
            !_.isEmpty(tradeInOffer.currentSettings.tradeinPro)) ? tradeInOffer.currentSettings.tradeinPro : null);

        let _selectedPipeline = tradeInOffer?.tradeinPro && tradeInOffer?.tradeinPro.pipeline && !_.isEmpty(_settings) ? _settings.allPipelines.filter(item => item.value === tradeInOffer.tradeinPro.pipeline)[0] : null;
        let statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], tradeInOffer.tradeinPro.stageHistory);
            let _stageData = CommonHelper.handleStageHistory(_stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    onCurrencyChange = (e, name) => {

        this.setState({
            wholesalerPrice: e.floatValue,
            errorFields: {
                ...this.state.errorFields,
                [name]: ''
            }
        })
    }

    handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        this.setState({
            wholesalerNote: value
        })

    }

    handleSubmit = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};


        if (!this.state.wholesalerPrice) {
            formIsValid = false;
            errors['wholesalerPrice'] = errorClass;
        }

        this.setState({
            errorFields: errors
        })


        if (!formIsValid) {
            return;
        }

        this.handleSave();


    }

    handleSave = () => {
        this.setState({ loader: true })


        let objDataOffer = Object.assign({})
        const { tradeInOffer, wholesalerPrice, wholesalerNote } = this.state;
        let _currentUser = tradeInOffer.wholeSellerId;
        let _currentDate = window.firebase.firestore.Timestamp.now();

        objDataOffer.modifiedBy = _currentUser;
        objDataOffer.modifiedDate = _currentDate;
        objDataOffer.evaluatedBy = _currentUser;
        objDataOffer.evaluationDate = _currentDate;
        objDataOffer.isSelfEvaluation = false;
        objDataOffer.evaluationPrice = wholesalerPrice;
        //objDataOffer.dealerPrice = wholesalerPrice;
        objDataOffer.status = tipOfferStatus.COMPLETED;

        //Remove empty value from object
        for (var propName in objDataOffer) {
            if (objDataOffer[propName] === null || objDataOffer[propName] === undefined || objDataOffer[propName] === '' || objDataOffer[propName] === [] || (_.isObject(objDataOffer[propName]) && _.isEmpty(objDataOffer[propName]))) {
                delete objDataOffer[propName];
            }
        }

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offers`).doc(this.props.offerid);
        updateRef.set(objDataOffer, { merge: true }).then((docRef) => {

            toast.notify('appraisal updated successfully.', {
                duration: 2000,
            });

            if (!_.isEmpty(wholesalerNote))
                this.handleNotesClose(wholesalerNote)

            this.saveTradeInProLog();

            if (tradeInOffer?.tradeinPro.status === tipStatus.NEW || tradeInOffer?.tradeinPro.status === tipStatus.INPROGRESS) {
                this.saveTradeinPro(tipStatus.PENDING);
            }
            else
                this.setState({ loader: false })



        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });


    }

    saveTradeinPro = (_status) => {
        var objData = {
            status: _status,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        }
        const { tradeInOffer, tradeIn } = this.state;
        const { dealersettings } = this.props;
        const batch = firestoreDB(dealersettings).firestore().batch();
        if (tradeIn.documentID)
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeins/${tradeIn.documentID}`), { ...objData }, { merge: true });

        objData = this.handleMoveStageForStatusChange(objData.status, objData);

        const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro`).doc(tradeInOffer.tradeinProID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.setState({ loader: false })

            batch.commit();
        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });
    }

    saveTradeInProLog = () => {
        const { tradeInOffer, wholesalerPrice, tradeIn } = this.state;
        const { clientUsers, dealersettings } = this.props;
        const currencySymbol = !_.isEmpty(tradeInOffer?.currency) ? tradeInOffer?.currency.symbol + ' ' : '$ ';
        var objData = {
            documentID: window.firebase.firestore().collection('tradeins').doc().id,
            addedBy: tradeInOffer.wholeSellerId,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedFrom: 'web',
            offerID: this.props.offerid,
            tradeinProID: tradeInOffer.tradeinProID,
            isDeleted: false,
            projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
            message: `️An offer of ${CommonHelper.formatCurrency(currencySymbol, wholesalerPrice)} has been received from ${CommonHelper.getUserNamebyId(clientUsers, tradeInOffer.wholeSellerId, '')}.`
        }

        const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offerLogs`).doc(objData.documentID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            this.setState({ loader: false })


        }).catch(error => {
            this.setState({ loader: false })

            console.error('Error updating enquiries: ', error);
        });
    }

    handleNotesClose = (noteTxt, _mentions, _isInternal) => {

        if (noteTxt) {
            const { tradeInOffer, wholesalerPrice, tradeIn } = this.state;
            const { offerid, dealersettings } = this.props;
            let objData =
            {
                documentID: window.firebase.firestore().collection('tradeins').doc().id,
                notes: noteTxt,
                addedBy: tradeInOffer.wholeSellerId,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                offerID: tradeInOffer.documentID,
                tradeinProID: tradeInOffer.tradeinProID,
                projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
                wholeSalerNotes: true,
                isDeleted: false
            }

            if (_isInternal)
                objData.isInternalNotes = true;

            const updateRef = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeInOffer.tradeinProID}/offerNotes`).doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {


            }).catch(error => {

                console.error('Error updating enquiries: ', error);
            });

        }
        this.setState({ showNotes: false })

    }

    handleLogClose = (ID) => {
        this.setState({
            showOfferLog: false
        });
    }

    handleLogOpen = (ID) => {
        this.setState({
            showOfferLog: true
        });
    }

    render() {

        const { tradeInOffer, tradeIn, wholesalerNote, wholesalerPrice, errorFields, loader, loading, showNotes, offerID, showOfferLog } = this.state;
        const { dealersettings, offerid, clientUsers } = this.props;
        const { client, currentSettings, users, offerNotes, tradeinPro, evaluationPrice, clientID, status, addedDate, addedBy, currency, isReRequested, previousWSPrice } = tradeInOffer;
        const {
            make, model, year, modelDescription, specifications, tags, group,
            notes, regNo, regDate, chassisNo, engineNo, transmission, doors,
            mileage, exteriorColor, interiorColor, trim, spareTyre,
            massageVentilated, camera, contrastStiching, woodSteering, sunroof,
            owners, outstandingPayments, warranty, financeCo,
            serviceContFrom, serviceContTo, reconditioning, financed, tyreAge, lastService,
            type, engineType, engineSize,
            frontLeftWheel, frontRightWheel, rearLeftWheel, rearRightWheel,
            vehicleCondition, buildDate, complianceDate, spareKey, logBooks, serviceHistory,
            cargoBlind, headRests, sdCard, sent, warrantyExpiry,
            images, marks, inspectionSnapshot, sidePhotos,
            tradeinProID, offerPrice, reconditioningCost
        } = tradeIn;

        const currencySymbol = !_.isEmpty(currency) ? currency.symbol + ' ' : '$ ';
        let _badgeColor = status === tipOfferStatus.LOST ? 'orange' : 'green'
        return <>

            {
                !_.isEmpty(offerID)
                    ?
                    <>

                        <div className="quickview-header">
                            <div className="float-right">
                                {
                                    (status && _.isString(status))
                                        ?
                                        <div className="current-status-quickview mt-1 mr-2">
                                            <Translate text={'currentStatus'} />
                                            <div className={`badge badge-pill badge-${status.toLowerCase()} ml-2`}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                }}>
                                                <Translate text={status.split(/(?=[A-Z])/).join(' ').toLowerCase()} upperCase={true} />
                                            </div>
                                        </div>
                                        :
                                        <></>

                                }

                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{addedBy ? <>{CommonHelper.getUserNamebyId(clientUsers, addedBy)}</> : '--'}</Tooltip>
                                    }
                                >
                                    <div className="quick-view-avatar">
                                        <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                            <img src={CommonHelper.getUserImagebyId(clientUsers, addedBy)} alt="" className="img-object-fit" />
                                        </a>
                                    </div>
                                </OverlayTrigger>


                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip><Translate text={'Expand View'} /></Tooltip>
                                    }
                                >
                                    <div className="quick-view-expand">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push("/wholesaler/details/" + offerid);
                                        }}>
                                            <i className="ico icon-expand" data-toggle="tooltip" title="" data-placement="bottom" data-original-title=" Go to detail view"></i>
                                        </a>
                                    </div>
                                </OverlayTrigger>


                            </div>

                            <div className="quickview-mid-header">
                                <div className="float-left quickview-title-wrap w-100">
                                    <div className="quickview-contact"> <i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-Stock')}></i> {make ? make : ''}&nbsp;{model ? model : ''}</div>

                                    <div className="quickview-contact-list">                                        <ul>
                                        <li> {year ? year + (exteriorColor ? ', ' : ' ') : ''}&nbsp; {exteriorColor ? exteriorColor : ''}</li>
                                    </ul>
                                        {
                                            isReRequested
                                                ?
                                                <> <div className="quickview-id float-right">
                                                    <div className="badge badge-pill badge-white ml-1 text-capitalize"><Translate text={'reRequested'} /></div>
                                                </div></>
                                                :
                                                <></>
                                        }

                                    </div>
                                    <div className="quickview-service-subsection">
                                        <div className="quickview-service-vehicle-list">
                                            <div className="float-left">
                                                <ul>
                                                    {client?.name ? <li> <i className="ico icon-dealers"></i>{client.name}</li> : <></>}
                                                    {client?.email ? <li><i className="ico icon-email"></i> {client.email}</li> : <></>}
                                                </ul>
                                            </div>

                                            <div className="float-right">

                                                {
                                                    evaluationPrice
                                                        ?
                                                        <>
                                                            <div className="float-right">
                                                                <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                                                    <i className="ico icon-sent"></i>
                                                                    <NumberFormat value={evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }


                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                        <>
                            <div className="quickview-contentarea">
                                <section className="tabs">
                                    <div className="container">
                                        <div className="row">
                                            <div className="tabs-wraper">
                                                <Tabs defaultActiveKey="quickview" transition={false} id="noanim-tab-example" className="nav-fill">
                                                    <Tab eventKey="quickview" title="Trade-In Info" className="quick-view-tab-scroll">
                                                        <div className="quickview-activity-item">
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="row" >

                                                                    <div className="quickview-list">

                                                                        <div className="tradein-quickview-section-inspection-wraper">
                                                                            <div className="vehicle-title-inspection"><Translate text={'vehicleDetails'}/></div>
                                                                            <div className="float-right">

                                                                                <div className="service-rec-inspection">
                                                                                    <a data-fancybox={`tradein_inspection_img_quick`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                                                                        <button type="button" className="btn btn-inspection-service mt-1"><i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-inspection')}></i> <Translate text={'vehicleInspection'} />
                                                                                            <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                                            <div className="float-right ml-2">
                                                                                                <span className="badge badge-inspection">{!_.isEmpty(marks) ? marks.length : 0}</span>
                                                                                            </div>

                                                                                        </button>
                                                                                    </a>

                                                                                </div>
                                                                                {/* <button
                                                                                            type="button"
                                                                                            className="btn btn-inspection-service mt-1"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({ showCanvas: true })
                                                                                            }}
                                                                                        >
                                                                                            {this.state.isLoadingInspection ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <i className="ico icon-inspection"></i>}

                                                                                            <Translate text={'vehicleInspection'} />
                                                                                            {
                                                                                                marks && marks.length > 0
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                                                                                        <div className="float-right"><span className="badge badge-inspection ml-1">{marks.length}</span></div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            _isCompleted === false && canModifyTIP
                                                                                                                ?
                                                                                                                <div className="float-right ml-2"><i className="ico icon-add" aria-hidden="true"></i></div>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                    </>
                                                                                            }

                                                                                        </button> */}


                                                                            </div>

                                                                        </div>


                                                                        {
                                                                            !_.isEmpty(images) || !_.isEmpty(sidePhotos)
                                                                                ?
                                                                                <>
                                                                                    <div className="tradein-quickview-section-thumb-wraper mt-2">
                                                                                        {
                                                                                            !_.isEmpty(images) && images.map((data, index) => {
                                                                                                return <div className="tradein-quickview-section-item-image" key={index}>

                                                                                                    <a data-fancybox={`tradeinPro_img_quickview`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                                                                        <img src={data} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            })
                                                                                        }
                                                                                        {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                                            if (sidePhotos[key]) {
                                                                                                return (<div className="tradein-quickview-section-item-image" key={index}>
                                                                                                    <a data-fancybox={`tradeinPro_img_quickview`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="70" height="70">
                                                                                                        <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="70" height="70" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                </div>)
                                                                                            }

                                                                                        })}
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                </>
                                                                        }

                                                                        <table className="contact-tbl" width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                                            <tbody>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'make'} /></span>
                                                                                        <div>{make ? make : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'model'} /></span>
                                                                                        <div>{model ? model : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'type'} /></span>
                                                                                        <div>{type ? type : '--'} </div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'year'} /></span>
                                                                                        <div>{year ? year : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'registrationNumber'} /></span>
                                                                                        <div>{regNo ? regNo : '--'}</div> </td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'registrationExpiry'} /></span>
                                                                                        <div>{regDate ? moment(regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'chassisNo'} /></span>
                                                                                        <div>{chassisNo ? chassisNo : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'mileage'} /></span>
                                                                                        <div>{mileage ? mileage : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'colour'} /></span>
                                                                                        <div>{exteriorColor ? exteriorColor : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'interiorColor'} /></span>
                                                                                        <div>{interiorColor ? interiorColor : '--'}</div> </td>

                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'complianceDate'} /></span>
                                                                                        <div>{complianceDate ? moment(complianceDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'buildDate'} /></span>
                                                                                        <div>{buildDate ? moment(buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'engineType'} /></span>
                                                                                        <div>{engineType ? engineType : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'engineSize'} /></span>
                                                                                        <div>{engineSize ? engineSize : '--'}</div> </td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'financeCo'} /></span>
                                                                                        <div>{financeCo ? financeCo : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'spareKey'} /></span>
                                                                                        <div>{spareKey ? spareKey : '--'}</div> </td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'frontRightWheel'} /></span>
                                                                                        <div>{frontRightWheel ? frontRightWheel : '--'}</div> </td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'frontLeftWheel'} /></span>
                                                                                        <div>{frontLeftWheel ? frontLeftWheel : '--'}</div> </td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'rearRightWheel'} /></span>
                                                                                        <div>{rearRightWheel ? rearRightWheel : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'rearLeftWheel'} /></span>
                                                                                        <div>{rearLeftWheel ? rearLeftWheel : '--'}</div></td>
                                                                                </tr>
                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'serviceContFrom'} /></span>
                                                                                        <div>{serviceContFrom ? moment(serviceContFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div> </td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'serviceContTo'} /></span>
                                                                                        <div>{serviceContTo ? moment(serviceContTo, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div> </td>
                                                                                </tr>

                                                                                <tr className="tr-brk">
                                                                                    <td width="50%"><span className="subDept"><Translate text={'lastService'} /></span>
                                                                                        <div>{lastService ? moment(lastService, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                                                    <td width="50%"><span className="subDept"><Translate text={'warrantyExpiry'} /></span>
                                                                                        <div>{warrantyExpiry ? moment(warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="offer" title="Offer" className="quick-view-tab-scroll">
                                                        <div className="quickview-activity-item">
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="row" >
                                                                    <div className="quickview-service-head-section">

                                                                        <div className="wholesaler-offerlog-quickview">
                                                                            <button type="button"
                                                                                className="btn btn-default float-right tradein-section-logbtn"
                                                                                onClick={(e) => { e.preventDefault(); this.handleLogOpen(); }}
                                                                            ><i className="ico icon-service-log"></i> <Translate text={'Offer Log'} /></button>
                                                                            <div></div></div>

                                                                        <h3><Translate text={'Offer'} /></h3>
                                                                    </div>

                                                                    <div className="wholesaler-evaluation-offer-wrapper">

                                                                        {
                                                                            evaluationPrice
                                                                                ?
                                                                                <>


                                                                                    <div className="wholesaler-inputwrap">

                                                                                        <p className="wholesaler-top-fixmob"><b><Translate text={'You have quoted the price'} /></b> </p>
                                                                                        <div className="wholesaler-offer-cost"><NumberFormat
                                                                                            style={{ fontSize: 25, fontWeight: 700 }}
                                                                                            value={evaluationPrice}
                                                                                            displayType={'text'}
                                                                                            decimalScale={2}
                                                                                            thousandSeparator={true}
                                                                                            prefix={currencySymbol} /></div>
                                                                                    </div>

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        status === tipOfferStatus.PENDING
                                                                                            ?
                                                                                            <>
                                                                                                <div className="wholesaler-inputwrap">

                                                                                                    {
                                                                                                        isReRequested
                                                                                                            ?
                                                                                                            <p className="wholesaler-top-fixmob"><b><Translate text={'The manager has re-requested for the price'} /></b> </p>
                                                                                                            :
                                                                                                            <p className="wholesaler-top-fixmob"><b><Translate text={'Please insert your offer and click Send button'} /></b> </p>
                                                                                                    }
                                                                                                    <div className="form">

                                                                                                        <NumberFormat
                                                                                                            id={'wholesalerPrice'}
                                                                                                            decimalScale={2}
                                                                                                            allowNegative={true}
                                                                                                            thousandSeparator={true}
                                                                                                            prefix={currencySymbol}
                                                                                                            placeholder={currencySymbol}
                                                                                                            className={`form-control wholesaler-input-price ${errorFields["wholesalerPrice"]}`}
                                                                                                            onValueChange={(e) => {
                                                                                                                this.onCurrencyChange(e, 'wholesalerPrice')
                                                                                                            }}
                                                                                                            value={wholesalerPrice ? wholesalerPrice : null}
                                                                                                        />


                                                                                                        <div className="wholesaler-notes-item">


                                                                                                            <textarea
                                                                                                                className={`form-control notes`}
                                                                                                                name="notes" onChange={(e) => this.handleNotesChanges(e)}
                                                                                                                value={wholesalerNote ? wholesalerNote : ''}
                                                                                                                placeholder="notes"
                                                                                                                //cols="80"
                                                                                                                rows="3"
                                                                                                            ></textarea>

                                                                                                        </div>



                                                                                                        <div className="wholesaler-btn-wrap">
                                                                                                            {/* <input type="submit" className="wholesaler-blue-btn pull-right" value="Send" onclick="" /> */}

                                                                                                            <button type="button" className="wholesaler-blue-btn pull-right ml-2" onClick={(e) => this.handleSubmit(e)}>
                                                                                                                {
                                                                                                                    loader ?
                                                                                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                                                        : (<></>)
                                                                                                                }
                                                                                                                <Translate text={'send'} />
                                                                                                            </button>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }




                                                                                </>
                                                                        }
                                                                    </div>

                                                                    {
                                                                        previousWSPrice
                                                                            ?
                                                                            <div className="wholesaler-last-offer-alert alert-user mt-2 w-100">
                                                                                <Translate text={'Previous Offer'} /> : <b className="alert-link"><NumberFormat
                                                                                    value={previousWSPrice}
                                                                                    displayType={'text'}
                                                                                    decimalScale={2}
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol} /></b>

                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {/* <div className="wholesaler-evaluation-offer-wrapper">

                                                                        <div className="wholesaler-inputwrap">

                                                                            <p className="wholesaler-top-fixmob"><b>Enter Your Offer</b> </p>
                                                                            <div className="form">


                                                                                <div className="wholesaler-input-price"> <span>$ </span> <input data-language="en" className="wholesaler-input-box" placeholder="Price" type="text" maxlength="13" /></div>

                                                                                <div className="wholesaler-notes-item"><textarea className="form-control notes" id="" placeholder="Notes"></textarea></div>



                                                                                <div className="wholesaler-btn-wrap">
                                                                                    <input type="submit" className="wholesaler-blue-btn pull-right" value="Send" onclick="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="notes" title="Notes" className="quick-view-tab-scroll">

                                                        <div className="quickview-files-wraper">
                                                            <>

                                                                <div className="quickview-requirement-section">
                                                                    <div className="add-files-button">
                                                                        <a href="#" className="mini-button" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({ showNotes: true })
                                                                        }}>
                                                                            <i className="ico icon-add"></i>
                                                                        </a>
                                                                    </div>
                                                                    <h3><Translate text={'Notes'} /></h3>
                                                                </div>

                                                                {!_.isEmpty(offerNotes)
                                                                    ?
                                                                    <>

                                                                        <div className={`file-manager-control`}>
                                                                            {
                                                                                _.orderBy(offerNotes, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                                    return <div key={index} className="notes-item text-area-space ">
                                                                                        {noteData.notes}
                                                                                        <div className="notes-item-sub">

                                                                                            <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                                            &nbsp;{' - '}&nbsp;
                                                                                            {CommonHelper.getUserNamebyId(clientUsers, noteData.addedBy)}
                                                                                            {
                                                                                                (!noteData.wholeSalerNotes) ? (
                                                                                                    <div className="badge badge-pill badge-input-white ml-2 text-uppercase">{'dealer'}</div>
                                                                                                ) : (<></>)
                                                                                            }
                                                                                            {
                                                                                                (noteData.isInternalNotes) ? (
                                                                                                    <div className="badge badge-pill badge-input-white ml-1 text-uppercase">{'Internal'}</div>
                                                                                                ) : (<></>)
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="spinner-loader">
                                                                            <div className="no-cafe-flex justify-content-center align-items-center ">
                                                                                <div className="no-cafe-img">
                                                                                    <i className="ico icon-note icon-service-nonote"></i> </div>
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                            </>

                                                        </div>
                                                    </Tab>




                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </>
                    </>
                    :
                    <>
                        <ContentOverlay active={true} />
                    </>

            }


            <PopUpModal show={showNotes}>
                <AddNotes
                    show={showNotes}
                    handleClose={this.handleNotesClose}
                />
            </PopUpModal>

            <PopUpModal show={showOfferLog}>
                <OfferLogs
                    show={showOfferLog}
                    tradeinProID={tradeinProID}
                    offerID={this.props.offerid}
                    userID={localStorage.uid}
                    handleClose={this.handleLogClose}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                >
                </OfferLogs>
            </PopUpModal>
        </>




    }
}

export default TradeInDetails;
