/** LIBRARIES */
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import StarRatings from 'react-star-ratings'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import {
    ReactTimePicker, AntDatePicker, DropDownMenu,
    PopUpModal, InputCheckBox, InputText,
    Terms, AfterSignature, RouteMap, ImageCanvas
} from '../../../components';
import Translate from '../../../constants/translate';
import MentionEditor from '../../../components/mentionEditor/mentionEditor';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import SecondaryDriver from './secondaryDriver'
import Stocks from '../../stock/list/index';
import AddStock from '../../stock/add'
import SignOptions from '../../common/signOptions'
import InboundTestdriveInfo from '../inboundInfo'
/** VIEW-MODELS */
import { objTestDrive } from '../viewModel'
import { stockStatus, testDriveStatus, stockAvail } from '../../../services/enum'
import { objMark } from '../../tradeIn/viewModel'
import { stockBasicVM } from '../../stock/viewModel';
import { contactBasicWithKeywordsVM } from '../../contacts/viewModel'
import { enquiryBasicTDVM, objActivitylog, objWalkIn } from '../../pipeline/viewModel'
import { firestoreDB } from "../../../services/helper";

const sign_type = [
    { active: true, ico: 'ico icon-email', value: 'send', label: 'Send sign request', text: 'Send signature request to customer' },
    { active: true, ico: 'ico icon-sign', value: 'sign', label: 'Sign on-sight', text: 'Get signature on-site from customer' }
]
export default class AddTestDrive extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('testdrives');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.refStock = window.firebase.firestore().collection('stock');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.testdriveFeedback = [];
        this.isExpectedReturned = false;
        this.unsubscribeTerms = null;
        this.unsubscribeInsurance = null;
        this.unsubscribeSign = null;
        this.state = {
            fields: Object.assign({}, objTestDrive),
            fieldsOld: Object.assign({}),
            stock: Object.assign({}, stockBasicVM),
            mark: Object.assign({}, objMark),
            stockBookings: Object.assign([]),
            requestsTerms: Object.assign({}),
            requestsInsurance: Object.assign({}),
            requestsSign: Object.assign({}),
            showTermsOption: false,
            showInsuranceOption: false,
            showSignOption: false,
            errors: {},
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            stockModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: ''
            },
            showStocks: false,
            showBeforeInspection: false,
            showAfterInspection: false,
            showSecondaryDriver: false,
            showBeforeTerms: false,
            showBeforeInsurance: false,
            showAfterSignature: false,
            showRouteMap: false,
            mentions: [],
            teamMentions: null
        }
        this.mentionsData = props.clientUsers.map(clientUser => {
            return {
                name: `@${clientUser.name}`,
                id: clientUser.id,
                avatar: CommonHelper.showUserAvatar(clientUser.profileImage, clientUser.name)
            };
        });
        props.dealersettings.client.teams.forEach(team => {
            this.mentionsData.push({
                name: `@${team.name}`,
                id: team.user_ids.join(','),
                avatar: _images.group
            })
        })

        this.includeInsWaiverInTDTerms = ((props.dealersettings?.client?.clientSettings?.includeInsWaiverInTDTerms) ? true : false);
    }

    //#region  FUNCTIONS

    //#region PAGE LOAD
    async loadTestDrive(props) {
        const { show, dealersettings, docID, prefillData, enquiry, contact } = props;
        const fields = Object.assign({}, objTestDrive);

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;
            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.testdrive))
                this.mandatoryFields = setttings.mandatoryFields.testdrive.split(',');

            if (!_.isEmpty(setttings.testdriveFeedback))
                this.testdriveFeedback = setttings.testdriveFeedback;

        }

        if (show === true && docID) {//&& enquiryID
            this.newDocID = docID;
            const refData = await firestoreDB(dealersettings).firestore().collection('testdrives').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }

                if (!fields.documentID)
                    fields.documentID = docID;

                if (!_.isEmpty(fields.startDate))
                    fields.startDate = moment.unix(fields.startDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(fields.endDate))
                    fields.endDate = moment.unix(fields.endDate.seconds).format('YYYY-MM-DD HH:mm')

                if (!_.isEmpty(fields.expectedReturn)) {
                    this.isExpectedReturned = true;
                    fields.expectedReturn = moment.unix(fields.expectedReturn.seconds).format('YYYY-MM-DD HH:mm')
                }

                let _stockID = fields.stockID ? fields.stockID : (fields.stock ? fields.stock.documentID : null)
                // if (_stockID && (!_.isEmpty(fields.stock))) {
                //     fields.tradePlate = (fields.stock && fields.stock.tradePlate) ? fields.stock.tradePlate : '';
                //     this.setState({ stock: fields.stock });
                // }
                // else 
                if (_stockID) {
                    const refStockData = window.firebase.firestore().collection('stock').doc(_stockID);
                    refStockData.get().then((_testStock) => {
                        if (_testStock.exists) {
                            const stock = Object.assign({}, stockBasicVM);
                            const objTDStockData = Object.assign({}, _testStock.data());
                            for (let [key, value] of Object.entries(objTDStockData)) {
                                if (stock.hasOwnProperty(key))
                                    stock[key] = value;
                            }
                            stock.documentID = _testStock.id;
                            fields.stockID = _testStock.id;
                            fields.stock = stock;
                            fields.tradePlate = (stock && stock.tradePlate) ? stock.tradePlate : '';
                            this.setState({ stock: stock });
                        }
                        else if (!_.isEmpty(fields.stock)) {
                            fields.tradePlate = (fields.stock && fields.stock.tradePlate) ? fields.stock.tradePlate : '';
                            this.setState({ stock: fields.stock });
                        }
                        else {
                            console.error("No such document!");
                        }
                    });

                }
                else if (!_.isEmpty(fields.stock?.make) && fields.status === testDriveStatus.COMPLETE) {
                    fields.tradePlate = (fields.stock && fields.stock.tradePlate) ? fields.stock.tradePlate : '';
                    this.setState({ stock: fields.stock });
                }
                //commented 02-05-23 instructed by muzammal
                // if (!_.isEmpty(fields.stock) && !fields.stock.regNo && this.mandatoryFields.indexOf('tradePlate') < 0)
                //     this.mandatoryFields.push('tradePlate');


                if (!_.isEmpty(fields.beforeInspection)) {
                    const _marks = [];
                    fields.beforeInspection.map((mark) => {
                        const _mark = Object.assign({}, objMark);
                        for (let [key, value] of Object.entries(mark)) {
                            _mark[key] = value;
                        }
                        _marks.push(_mark)
                    })
                    fields.beforeInspection = _marks;
                }

                if (!_.isEmpty(fields.afterInspection)) {
                    const _marks = [];
                    fields.afterInspection.map((mark) => {
                        const _mark = Object.assign({}, objMark);
                        for (let [key, value] of Object.entries(mark)) {
                            _mark[key] = value;
                        }
                        _marks.push(_mark)
                    })
                    fields.afterInspection = _marks;
                }

                this.setState({ fields: fields, fieldsOld: fields }, () => { this.loadBookingAppointments(fields) });

            } else {
                console.error("No such document2!");
            }

        }
        else if (show === true && (!docID)) {// && enquiryID
            this.newDocID = this.ref.doc().id;
            const fields = Object.assign({}, objTestDrive);
            fields.documentID = this.newDocID;
            fields.startDate = moment().format('YYYY-MM-DD HH:mm')
            fields.testdriveFeedback = [];

            // if (!_.isEmpty(prefillData) && _.isObject(prefillData)) {
            //     for (let [key, value] of Object.entries(prefillData)) {
            //         if (fields.hasOwnProperty(key) && value)
            //             fields[key] = value;
            //     }
            // }

            if (!_.isEmpty(contact) && contact.documentID) {
                let _contact = Object.assign({}, contactBasicWithKeywordsVM);
                const refContactData = await this.refContact.doc(contact.documentID).get();
                if (refContactData.exists) {
                    const objcontactData = Object.assign({}, refContactData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        _contact[key] = value;
                    }
                    fields.contact = _contact;
                    fields.contactID = _contact.documentID;

                }
                else {
                    for (let [key, value] of Object.entries(contact)) {
                        if (_contact.hasOwnProperty(key))
                            _contact[key] = value;
                    }
                    fields.contact = _contact;
                    fields.contactID = _contact.documentID;
                }

            }

            if (!_.isEmpty(enquiry)) {
                let _enquiry = Object.assign({}, enquiryBasicTDVM);
                for (let [key, value] of Object.entries(enquiry)) {
                    if (_enquiry.hasOwnProperty(key))
                        _enquiry[key] = value;
                }
                fields.enquiry = _enquiry;
                fields.enquiryID = _enquiry.documentID;
            }
            this.setState({ fields: fields });
        }



    }

    loadBookingAppointments = (fields) => {
        let _fields = !_.isEmpty(fields) ? fields : this.state.fields;
        let stockID = _fields.stockID ? _fields.stockID : (_fields.stock ? _fields.stock.documentID : null)
        if (stockID && fields.status !== testDriveStatus.COMPLETE) {
            firestoreDB(this.props.dealersettings).firestore()
                .collection('loanAppointments')
                .where('stockID', '==', stockID)
                .get()
                .then(querySnapshot => {
                    let _bookings = [];
                    querySnapshot.docs.forEach(rec => {
                        _bookings.push(rec.data())
                    })
                    this.setState({ stockBookings: _bookings })
                });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadTestDrive(this.props);

    }

    componentWillUnmount() {
        this._isMounted = false;
        let _req = Object.assign({}, objTestDrive);
        _req.testdriveFeedback = [];
        _req.insurance = {};
        _req.terms = {};
        _req.keywords = [];
        _req.beforeInspection = [];
        _req.afterInspection = [];
        this.unsubscribeTerms && this.unsubscribeTerms();
        this.unsubscribeInsurance && this.unsubscribeInsurance();
        this.unsubscribeSign && this.unsubscribeSign();

        const { requestsTerms, requestsInsurance, requestsSign } = this.state;
        if (requestsTerms && requestsTerms.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsTerms.contactID}/signRequest/${requestsTerms.id}`).delete();

        if (requestsInsurance && requestsInsurance.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsInsurance.contactID}/signRequest/${requestsInsurance.id}`).delete();

        if (requestsSign && requestsSign.status === 'requested')
            firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${requestsSign.contactID}/signRequest/${requestsSign.id}`).delete();

        this.setState({ fields: _req });
    }
    //#endregion

    //#region  STOCK CHANGES
    handleStockClose = (ID, objData) => {

        if (ID && !_.isEmpty(objData)) {

            if (this.isValidForTestDrive(objData) === false)
                return;

            const fields = Object.assign({}, this.state.fields);

            const stock = Object.assign({}, stockBasicVM);
            for (let [key, value] of Object.entries(objData)) {
                if (stock.hasOwnProperty(key))
                    stock[key] = value;
            }

            fields.stockID = ID;
            fields.stock = stock;
            fields.tradePlate = (stock && stock.tradePlate) ? stock.tradePlate : '';
            fields.startOdometer = (stock && Number(stock.miles)) ? Number(stock.miles) : 0;
            //commented 02-05-23 instructed by muzammal
            // if (!_.isEmpty(fields.stock) && !fields.stock.regNo && this.mandatoryFields.indexOf('tradePlate') < 0)
            //     this.mandatoryFields.push('tradePlate');

            this.setState({ stock: stock, fields: fields }, () => { this.errorChange('stockID'); this.loadBookingAppointments(fields) });
            //   console.log('TESTDRIVE - handleStockClose', ID, stock)
        }
        else if (ID) {
            const refData = window.firebase.firestore().collection('stock').doc(ID);
            refData.get().then((doc) => {
                if (doc.exists) {

                    const objStockData = Object.assign({}, doc.data());

                    if (this.isValidForTestDrive(objStockData) === false)
                        return;

                    const stock = Object.assign({}, stockBasicVM);
                    for (let [key, value] of Object.entries(objStockData)) {
                        if (stock.hasOwnProperty(key))
                            stock[key] = value;
                    }
                    stock.documentID = doc.id;

                    const fields = Object.assign({}, this.state.fields);
                    fields.stockID = doc.id;
                    fields.stock = stock;
                    fields.tradePlate = (stock && stock.tradePlate) ? stock.tradePlate : '';
                    fields.startOdometer = (stock && Number(stock.miles)) ? Number(stock.miles) : 0;
                    //commented 02-05-23 instructed by muzammal
                    // if (!_.isEmpty(fields.stock) && !fields.stock.regNo && this.mandatoryFields.indexOf('tradePlate') < 0)
                    //     this.mandatoryFields.push('tradePlate');

                    this.setState({ stock: stock, fields: fields }, () => { this.errorChange('stockID'); this.loadBookingAppointments(fields) });
                    //  console.log('TESTDRIVE - handleStockClose', ID, stock)

                } else {
                    console.error("No such document!");
                }
            });
        }

        this.setState({
            showStocks: false,
            stockModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: ''
            }
        });
    }

    isValidForTestDrive = (_stock) => {
        if (_stock && _stock['regExp'] && (moment()._d > moment.unix(_stock['regExp'].seconds)._d)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Registration expiry date is expired'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Update Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
                if (result.value) {
                    this.setState({
                        stockModal:
                        {
                            show: true,
                            ID: _stock.documentID,
                            title: 'stock',
                            clsActive: 'overlay-modal active'
                        }
                    });
                }
            });

            return false;
        }
        else { return true; }
    }

    handleAddStock = () => {
        this.setState({
            showStocks: false,
            stockModal:
            {
                show: true,
                ID: '',
                title: 'addStock',
                clsActive: 'overlay-modal active'
            }
        });
    };

    isStockExist = () => {
        if (this.state.fields.stockID)
            return true;
        else
            return false;
    }
    //#endregion

    //#region DATEPICKER

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val) {
            let date = state[name];
            if (date)
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
            else
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
        }
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = moment(date).format('YYYY-MM-DD HH:mm');

        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    minStartDate = (current) => {
        // Can not select days before today and today
        const { enquiry } = this.props;
        var minDate = enquiry?.addedDate ? moment.unix(enquiry.addedDate.seconds).startOf('day') : moment().startOf('day')
        return current && current < minDate;
    }


    minEndDate = (current) => {
        // Can not select days before today and today
        let state = Object.assign({}, this.state.fields);
        var minDate = state.startDate ? moment(state.startDate).startOf('day') : moment().startOf('day')
        return current && current < minDate;
    }
    //#endregion

    //#region INSPECTION
    handleBeforeInspectionClose = (marks) => {
        const { beforeInspection } = this.state.fields;
        if (!_.isEmpty(marks) || (!_.isEmpty(beforeInspection) && _.isEmpty(marks))) {
            let state = Object.assign({}, this.state.fields);
            state.beforeInspection = marks;
            this.setState({ showBeforeInspection: false, fields: state }, () => { this.errorChange('beforeInspection'); })

        }
        else {
            this.setState({ showBeforeInspection: false })
        }
    }

    handleAfterInspectionClose = (marks) => {
        const { afterInspection } = this.state.fields;
        if (!_.isEmpty(marks) || (!_.isEmpty(afterInspection) && _.isEmpty(marks))) {
            let state = Object.assign({}, this.state.fields);
            state.afterInspection = marks;
            this.setState({ showAfterInspection: false, fields: state }, () => { this.errorChange('afterInspection'); })

        }
        else {
            this.setState({ showAfterInspection: false })
        }
    }
    //#endregion

    //#region  SECONDARY DRIVER

    isValidSecondaryDriver = () => {
        let fields = Object.assign({}, this.state.fields);
        if (fields.secondaryLicenseFrontURL || fields.secondaryLicenseBackURL || fields.secondarySignatureURL) {
            return true;
        }
        else {
            return false;
        }
    }
    handleSecondaryDriverClose = (data) => {
        if (!_.isEmpty(data)) {

            const fields = Object.assign({}, this.state.fields);
            for (let [key, value] of Object.entries(data)) {
                fields[key] = value;
            }
            this.setState({ showSecondaryDriver: false, fields: fields })
        }
        else {
            this.setState({ showSecondaryDriver: false })
        }
    }
    //#endregion

    //#region  TERMS

    handleBeforeTermsClose = (data, insuranceConsent) => {
        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.terms = data;

            if (this.includeInsWaiverInTDTerms && insuranceConsent) {
                let _insuranceTC = CommonHelper.getTermsByField(this.props.dealersettings?.client, 'insuranceTC', state?.contact?.language);
                state.insurance = {
                    ...data,
                    signature: null,
                    termsAndConditions: !_.isEmpty(_insuranceTC) ? _insuranceTC : ''
                }
            }

            this.setState({ showBeforeTerms: false, fields: state }, () => { this.errorChange('terms'); })

        }
        else {
            this.setState({ showBeforeTerms: false })
        }
    }

    handleBeforeInsuranceClose = (data) => {
        if (!_.isEmpty(data)) {
            let state = Object.assign({}, this.state.fields);
            state.insurance = data;
            this.setState({ showBeforeInsurance: false, fields: state }, () => { this.errorChange('insurance'); })

        }
        else {
            this.setState({ showBeforeInsurance: false })
        }
    }

    handleAfterSignatureClose = (imgURL) => {
        if (imgURL) {
            let state = Object.assign({}, this.state.fields);
            state.afterSignatureURL = imgURL;
            this.setState({ showAfterSignature: false, fields: state }, () => { this.errorChange('afterSignatureURL'); })

        }
        else {
            this.setState({ showAfterSignature: false })
        }
    }

    handleRouteMapClose = () => {
        this.setState({ showRouteMap: false })
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;

        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        //console.log('errorChange', key, fields)
        if (errors !== {}) {
            if (!fields[key] && ((this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) || key === 'stockID')) {
                if ((key === 'insurance' || key === 'terms') && _.isEmpty(fields[key])) {
                    if (this.includeInsWaiverInTDTerms) errors['terms'] = errorClass;
                    else errors[key] = errorClass;
                }

                if (fields.isVehicleHandOver === true && !this.props.docID) {
                    if (key === 'expectedReturn' || key === 'startOdometer') {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                            errors[key] = errorClass;
                        }
                    }
                }
                if (fields.isVehicleHandOver === true && this.props.docID) {
                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                        errors[key] = errorClass;
                    }
                }
                else {
                    if (key !== 'expectedReturn') {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                            errors[key] = errorClass;
                        }
                    }
                }
            }
            else {
                errors[key] = '';
            }
            this.setState({ errors: errors });
        }

    }


    handleTypeChange = e => {
        const { name, value } = e.target;
        let val = (value === 'Yes' ? true : false);
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state });
    };

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    changeRating = (newRating, name) => {
        let state = Object.assign({}, this.state.fields);
        state.rating = newRating;
        this.setState({ fields: state });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    handleFeedbackChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, this.state.fields);
        let _reqOtherInfo = state.testdriveFeedback;

        if (checked)
            _reqOtherInfo.push(name)
        else
            _reqOtherInfo = _reqOtherInfo.filter(m => m !== name);

        state.testdriveFeedback = _reqOtherInfo
        this.setState({ fields: state });
    }

    //#endregion

    //#region SAVE & SUBMIT 
    handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {

                if ((key === 'insurance' || key === 'terms') && _.isEmpty(fields[key])) {
                    //console.log('mandatoryFields - empty', key, value)
                    formIsValid = false;
                    if (this.includeInsWaiverInTDTerms) errors['terms'] = errorClass;
                    else errors[key] = errorClass;
                }

                if (fields.isVehicleHandOver === true && !this.props.docID) {
                    if (key === 'expectedReturn' || key === 'startOdometer' || key === 'stockID') {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
                else if (fields.isVehicleHandOver === true && this.props.docID) {
                    if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                        formIsValid = false;
                        errors[key] = errorClass;
                    }
                }
                else {
                    if (key !== 'expectedReturn') {
                        if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }

            }
        }

        if (!fields.stockID) {
            formIsValid = false;
            errors.endDate = errorClass;
        }

        if (fields.startDate && fields.endDate && (moment(fields.startDate)._d > moment(fields.endDate)._d)) {
            formIsValid = false;
            errors.endDate = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'End date should be greater than Start date'), '', 'info')
        }

        // if (fields.startDate && fields.expectedReturn && (moment(fields.startDate)._d > moment(fields.expectedReturn)._d)) {
        //     formIsValid = false;
        //     errors.expectedReturn = errorClass;
        //     Swal.fire('Expected return date should be greater than current time', '', 'info')
        // }

        if (fields.startOdometer && fields.endOdometer && parseInt(fields.startOdometer) > parseInt(fields.endOdometer)) {
            formIsValid = false;
            errors.endOdometer = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'End odometer reading should be equal or greater than the current odometer reading'), '', 'info')
        }

        this.setState({ errors: errors });
        //console.log('valid', formIsValid, errors)
        if (formIsValid === true) {
            const { stockBookings } = this.state;
            if (formIsValid && !_.isEmpty(stockBookings) && fields.startDate && fields.expectedReturn) {
                const { clientUsers, dealersettings } = this.props;
                let _startDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.startDate)._d);
                let _endDate = window.firebase.firestore.Timestamp.fromDate(moment(fields.expectedReturn)._d);
                // let _endDate = fields.endDate ?
                //     window.firebase.firestore.Timestamp.fromDate(moment(fields.endDate)._d) :
                //     window.firebase.firestore.Timestamp.fromDate(moment(fields.expectedReturn)._d);

                if (await CommonHelper.checkAppointments(
                    stockBookings.filter(a => a.recordID !== fields.documentID && a.enquiryID !== fields.enquiryID),
                    _startDate,
                    _endDate,
                    formIsValid,
                    clientUsers, dealersettings) === true)
                    this.saveTestDrive();
            }
            else {
                this.saveTestDrive();
            }

        }
        else
            return formIsValid;

    };

    saveTestDrive = async () => {
        this.isLoading = true;
        const { enquiry, docID, enquiryID, selectedInbound, dealersettings } = this.props;
        let objData = Object.assign({}, this.state.fields);

        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        if (!objData.enquiryID)
            objData.enquiryID = this.props.enquiryID;

        if (!objData.enquiry && !_.isEmpty(enquiry)) {
            let _enquiry = Object.assign({}, enquiryBasicTDVM);
            for (let [key, value] of Object.entries(enquiry)) {
                if (_enquiry.hasOwnProperty(key))
                    _enquiry[key] = value;
            }
            objData.enquiry = _enquiry;
        }

        if (objData.startDate)
            objData.startDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.startDate)._d);

        if (objData.endDate)
            objData.endDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.endDate)._d);

        if (objData.expectedReturn)
            objData.expectedReturn = window.firebase.firestore.Timestamp.fromDate(moment(objData.expectedReturn)._d);

        if (!objData.clientID || localStorage.defaultModule === 'oem')
            objData = CommonHelper.setClientOrGroupOrRegionOrOemID(objData, dealersettings, null, objData.clientID, true)

        if (objData.isVehicleHandOver === true && !objData.status) {
            delete objData['endDate'];
            delete objData['endOdometer'];
            delete objData['afterInspection'];
            delete objData['isLike'];
            delete objData['rating'];
            objData.status = testDriveStatus.PENDING;
        } else if (!objData.isVehicleHandOver || !this.state.fields.status || this.state.fields.status === testDriveStatus.PENDING) {
            objData.status = testDriveStatus.COMPLETE;
            // console.log('test drive completed trigger here this.newDocID', this.newDocID)
            const sendFeedbackResponse = await this.checkEnabledAndSendPodiumFeedbackReq(objData, enquiry, dealersettings)
            // console.log('sendFeedbackResponse', sendFeedbackResponse)
            if (sendFeedbackResponse && sendFeedbackResponse !== null) {
                objData.sendFeedbackResponse = sendFeedbackResponse
            }
        }

        if (!_.isEmpty(selectedInbound)) {
            objData.tags = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    type: (selectedInbound.systemType || ''),
                    refID: (selectedInbound.refID || ''),
                    subType: (selectedInbound.subType || '')
                }
            )
        }

        if (this.state.mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = this.state.mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })

            if (objData.mentions && objData.mentions.length > 0) {
                objData.mentions = _.uniq([...objData.mentions, ...convertedIDs])
            } else {
                objData.mentions = _.uniq(convertedIDs)
            }
        }


        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        //delete objData['stock'];

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        // console.log('fields', objData)
        let tradePlate = objData.tradePlate ? objData.tradePlate : '';
        if (objData.hasOwnProperty('tradePlate') && objData.stock) {
            objData.stock['tradePlate'] = tradePlate;
        }
        delete objData['tradePlate'];


        const updateRef = firestoreDB(dealersettings).firestore().collection('testdrives').doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {

            this.isLoading = false;
            toast.notify('Test-drive saved successfully', {
                duration: 2000
            });

            objData.tradePlate = tradePlate;

            if (objData.isVehicleHandOver === true && objData.status === testDriveStatus.PENDING) {
                this.addTestDriveDetailInStock(objData);
                CommonHelper.saveLoanAppointment(objData, 'testdrives', this.props.dealersettings);
            }
            else if ((objData.isVehicleHandOver === true || (this.state.stock?.status === stockStatus.ONTESTDRIVE)) && objData.status === testDriveStatus.COMPLETE) {
                this.removeTestDriveDetailFromStock(objData);
                CommonHelper.deleteLoanAppointments(this.newDocID, 'testdrives', this.props.dealersettings);
            }

            if (!docID && objData.startDate) {
                this.handleSaveWalkin((this.props.enquiryID ? this.props.enquiryID : objData.enquiryID), objData.startDate);
            }

            let _makeModel = objData.stock ? (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') : '';
            let _logNote = 'Test Drive added'
            if (this.props.docID)
                _logNote = 'Test Drive completed'

            CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.enquiryID, _logNote + (_makeModel ? ' (' + _makeModel + ')' : ''), 'testdrives', 'enquiry');

            this.addActivitylog(objData)
            this.handleModalClose(this.newDocID, objData);

        }).catch((error) => {
            console.error("Error updating testDrive_details: ", error);
        });

    };

    handleSaveWalkin = (enquiryID, walkinDate) => {
        this.isLoading = true;
        const { dealersettings } = this.props;
        try {
            let userID = dealersettings ? dealersettings.id : '';

            let currenDate = window.firebase.firestore.Timestamp.now();

            let objData = Object.assign({}, objWalkIn);
            objData.documentID = this.ref.doc().id;
            objData.addedBy = userID;
            objData.addedDate = currenDate;
            objData.modifiedBy = userID;
            objData.modifiedDate = currenDate;

            objData.enquiryID = enquiryID;
            objData.walkinDate = walkinDate;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemID(objData, dealersettings, null, null, true)

            const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
            refOnjData.set(objData).then((docRef) => {
                if (this.props.enquiry && (this.props.enquiry.bdcStatus === 'allocated' || this.props.enquiry.bdcStatus === 'noshow')) {
                    firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
                        bdcStatus: 'show'
                    }, { merge: true })
                }
                CommonHelper.logFirstWalkin(enquiryID, walkinDate, dealersettings);
            }).catch((error) => {
                console.error("Error updating walkin_details: ", error);
            });
        } catch (error) {
            console.error(error, 'handleSaveWalkin');
        }
    }

    addTestDriveDetailInStock = (obj) => {

        if (obj.stockID) {
            try {
                const { stock } = this.state;
                var objTestDriveDetail = {
                    testdriveID: obj.documentID,
                    clientID: obj.clientID ? obj.clientID : null,
                    projectId: obj.projectId ? obj.projectId : null,
                    status: obj.status, //testDriveStatus
                    contact: Object.assign({}, obj.contact),
                    addedBy: obj.modifiedBy,
                    addedDate: obj.modifiedDate,
                    startDate: obj.startDate,
                    expectedReturn: obj.expectedReturn ? obj.expectedReturn : null,
                }
                Object.keys(objTestDriveDetail).forEach(key => objTestDriveDetail[key] === undefined ? delete objTestDriveDetail[key] : {});
                var objDetail = {
                    status: stockStatus.ONTESTDRIVE,
                    lastStatus: stock?.status ? stock.status : null,
                    tradePlate: obj.tradePlate,
                    testDriveDetail: objTestDriveDetail,
                    statusModifiedBy: obj.modifiedBy,
                    statusModifiedFrom: 'web',
                    modifiedBy: obj.modifiedBy,
                    modifiedFrom: 'web',
                    modifiedDate: obj.modifiedDate,
                }

                if (obj.startOdometer)
                    objDetail.miles = parseInt(obj.startOdometer);

                Object.keys(objDetail).forEach(key => objDetail[key] === undefined ? delete objDetail[key] : {});
                const updateRef = this.refStock.doc(obj.stockID);
                updateRef.update(objDetail).then((docRef) => {

                }).catch((error) => {
                    console.error("Error updating testDrive_details: ", error);
                });
            } catch (error) {
                console.error(error, 'addTestDriveDetailInStock');
            }

        }
    }

    removeTestDriveDetailFromStock = (obj) => {
        if (obj.stockID || obj?.stock?.documentID) {
            let stockID = obj.stockID ? obj.stockID : (obj?.stock?.documentID ? obj.stock.documentID : null)
            this.updateStock(obj, stockID);
        }
        else {
            window.firebase.firestore().collection('stock')
                .where('testDriveDetail.testdriveID', '==', obj.documentID)
                .limit(1).get()
                .then(response => {
                    if (response && response.size > 0) {
                        const stockID = response.docs[0].id;
                        this.updateStock(obj, stockID);
                        firestoreDB(this.props.dealersettings).firestore().collection('testdrives').doc(obj.documentID).set({ stockID: stockID }, { merge: true });
                    }
                });
        }
    }

    updateStock = (obj, _stockID) => {
        if (_stockID) {
            try {
                const { stock } = this.state;
                const updateRef = this.refStock.doc(_stockID);

                var objDetail = {
                    status: stock?.lastStatus ? stock.lastStatus : stockStatus.AVAILABLE,
                    tradePlate: obj.tradePlate,
                    testDriveDetail: null,
                    statusModifiedBy: obj.modifiedBy,
                    statusModifiedFrom: 'web',
                    modifiedBy: obj.modifiedBy,
                    modifiedFrom: 'web',
                    modifiedDate: obj.modifiedDate,
                }

                if (obj.endOdometer)
                    objDetail.miles = parseInt(obj.endOdometer);

                Object.keys(objDetail).forEach(key => objDetail[key] === undefined ? delete objDetail[key] : {});
                updateRef.update(objDetail).then((docRef) => {
                }).catch((error) => {
                    console.error("Error updating testDrive_details: ", error);
                });
            } catch (error) {
                console.error(error, 'updateStock');
            }
        }
    }


    handleModalClose = (ID, objData) => {
        this.setState({ fields: Object.assign({}, objTestDrive) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData);
    };

    handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    handleGetTestDrivePath = async (url) => await fetch(url)
        .then(async (response) => {
            const responseJSON = await response.json();
            let _poliyCoordinates = [];
            if (_.isArray(responseJSON)) {
                responseJSON.forEach((doc) => {
                    _poliyCoordinates.push({ 'geopoint': doc });
                });
            }
            if (!_.isEmpty(_poliyCoordinates)) {
                let state = Object.assign({}, this.state.fields);
                state['polylineCoordinates'] = _poliyCoordinates;
                this.setState({ fields: state }, () => { this.setState({ showRouteMap: true }) });
            }
            else
                Swal.fire(CommonHelper.showLocale(this.props, 'No co-ordinates available in map view.'), '', 'info')
        })

    handleViewReport = () => {
        const { documentID, enquiryID } = this.state.fields;
        let enqID = (this.props.enquiryID ? this.props.enquiryID : enquiryID);

        firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${enqID}/files`)
            .where('recordID', '==', documentID)
            .where('isDeleted', '==', false)
            .orderBy('modifiedDate', 'desc').limit(1).get()
            .then(response => {
                if (response && response.size > 0) {
                    const objData = Object.assign({}, response.docs[0].data());
                    //    console.log('handleViewReport', objData)
                    if (objData.url)
                        window.open(objData.url, '_blank');
                    else {
                        Swal.fire(CommonHelper.showLocale(this.props, 'No report available.'), '', 'info')
                    }
                } else {
                    Swal.fire(CommonHelper.showLocale(this.props, 'No report available.'), '', 'info')
                }
            });
    }

    //#endregion

    //#region Check is podium Enabled and send Survery req
    checkEnabledAndSendPodiumFeedbackReq = async (testdriveObj, enquiryObj, dealersettings) => {
        const _podium = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "podium")[0]);
        const podiumEnabled = _podium?.active && _podium?.enabled ? true : false;
        const podiumLocationUid = _podium?.settings?.locationUid ? _podium.settings.locationUid : null;
        const podiumRefreshToken = _podium?.settings?.refresh_token ? _podium.settings.refresh_token : null;
        const sendSMSOnTestDriveDone = _podium?.settings?.sendSMSOnTestDriveDone ? _podium.settings.sendSMSOnTestDriveDone : null;

        const isDataValid = podiumEnabled &&
            podiumLocationUid &&
            podiumRefreshToken &&
            sendSMSOnTestDriveDone &&
            enquiryObj?.contact?.firstName &&
            enquiryObj?.contact?.lastName &&
            enquiryObj?.contact?.phone &&
            enquiryObj?.contact?.phoneCode &&
            enquiryObj?.contact?.email &&
            enquiryObj?.documentID &&
            testdriveObj?.documentID &&
            dealersettings?.client?.documentID

        if (isDataValid) {
            const feedbackObj = {
                firstName: enquiryObj?.contact?.firstName,
                middleName: enquiryObj?.contact?.middleName || '',
                lastName: enquiryObj?.contact?.lastName,
                phone: `${enquiryObj?.contact?.phoneCode}${enquiryObj?.contact?.phone}`,
                email: `${enquiryObj?.contact?.email}`,
                dealerId: dealersettings?.client?.documentID,
                leadId: enquiryObj?.documentID,
                status: "TestDriveDone",
                refId: testdriveObj?.documentID,
                locationUid: podiumLocationUid,
                make: testdriveObj?.stock?.make
            }
            const reqObj = {
                feedbackObj,
                refreshToken: podiumRefreshToken
            }
            // debugger;
            //console.log('reqObj', reqObj)
            const enablePodium = window.firebase.functions().httpsCallable('podium-sendfeedbackreq');
            const data = await enablePodium(reqObj)
            // debugger;

            //console.log('enablePodium', data)
            if (data.data.success === true && data.data.resp) {
                return data.data.resp
            } else {
                //console.log('not success', data)
                return null
            }
        }
        return null
    }
    //#endregion Check is podium Enabled and send Survery req
    //#region SIGN_REQUEST

    handleTermsClick = () => {
        const { docID } = this.props;
        const { fields } = this.state;

        if (fields.contact && fields.contact.documentID && _.isEmpty(docID) && _.isEmpty(fields.terms))
            this.setState({ showTermsOption: true })
        else
            this.setState({ showBeforeTerms: true })
    }
    handleTermsChange = (type) => {

        if (type === 'sign') {
            this.setState({ showBeforeTerms: true })
        }
        else if (type === 'send') {
            const { requestsTerms, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsTerms) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showBeforeTerms: true })
                    return;
                }
                let _testdriveTC = CommonHelper.getTermsByField(dealersettings?.client, 'testdriveTC', _contact?.language);
                objData.title = 'Test Drive Terms & Conditions';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_testdriveTC) ? _testdriveTC : ''
                }

                if (this.includeInsWaiverInTDTerms === true) {
                    let _insuranceTC = CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', _contact?.language);
                    objData.insuranceTerms = { 'termsAndConditions': !_.isEmpty(_insuranceTC) ? _insuranceTC : '' }
                    objData.isMandatoryInsurance = this.mandatoryFields?.indexOf('insurance') >= 0 ? true : false
                    objData.insuranceConsent = false
                }

                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeTerms = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsTerms: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeTerms && this.unsubscribeTerms();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.terms = _signRequest.terms;
                                    fields.terms['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    if (_signRequest.insuranceConsent === true && _signRequest.insuranceTerms) {
                                        fields.insurance = _signRequest.insuranceTerms;
                                        fields.insurance['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    }
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }

        this.setState({ showTermsOption: false })

    }


    handleInsuranceClick = () => {
        const { docID } = this.props;
        const { fields } = this.state

        if (fields.contact && fields.contact.documentID && _.isEmpty(docID) && _.isEmpty(fields.insurance))
            this.setState({ showInsuranceOption: true })
        else
            this.setState({ showBeforeInsurance: true })
    }
    handleInsuranceChange = (type) => {

        if (type === 'sign') {
            this.setState({ showBeforeInsurance: true })
        }
        else if (type === 'send') {
            const { requestsInsurance, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsInsurance) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showBeforeInsurance: true })
                    return;
                }
                let _insuranceTC = CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', _contact?.language);
                objData.title = 'Insurance Terms & Conditions';
                objData.type = 'termsConditions'
                objData.terms =
                {
                    'termsAndConditions': !_.isEmpty(_insuranceTC) ? _insuranceTC : ''
                }


                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeInsurance = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsInsurance: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeInsurance && this.unsubscribeInsurance();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.insurance = _signRequest.terms;
                                    fields.insurance['addedDate'] = window.firebase.firestore.Timestamp.now();
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }
        this.setState({ showInsuranceOption: false })
    }


    handleSignClick = () => {
        const { fields } = this.state

        if (fields.contact && fields.contact.documentID && _.isEmpty(fields.afterSignatureURL))
            this.setState({ showSignOption: true })
        else
            this.setState({ showAfterSignature: true })
    }
    handleSignChange = (type) => {

        if (type === 'sign') {
            this.setState({ showAfterSignature: true })
        }
        else if (type === 'send') {
            const { requestsSign, fields } = this.state
            const _contact = fields.contact;
            if (_.isEmpty(requestsSign) && _contact && _contact.documentID) {

                const { dealersettings } = this.props;

                const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
                    dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
                ) ? true : false);

                const isAmsMeContact = amsMeEnabled && _contact.amsMe && !_.isEmpty(_contact.amsMe.meID) ? true : false;

                let userID = dealersettings ? dealersettings.id : '';
                let currenDate = window.firebase.firestore.Timestamp.now();
                const _selectedStock = !_.isEmpty(this.props.stock) ? this.props.stock : this.state.fields.stock;

                let objData = Object.assign({});
                objData.id = this.ref.doc().id;
                objData.addedBy = userID;
                objData.addedDate = currenDate;
                objData.projectId = fields.projectId ? fields.projectId : dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                objData.clientID = fields.clientID ? fields.clientID : dealersettings.client.id;
                objData.clientName = CommonHelper.getOtherDealerName(dealersettings, objData.clientID);
                objData.status = 'requested'
                objData.contactID = _contact.documentID;

                if (isAmsMeContact)
                    objData.amsMe = _contact.amsMe
                if (_contact.email || _contact.phone) {
                    objData.contactName = CommonHelper.displayFullContact(null, _contact, '');
                    objData.email = _contact.email ? _contact.email : ''
                    objData.phone = _contact.phone ? _contact.phone : ''
                    objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : ''
                } else if (!isAmsMeContact) {
                    this.setState({ showAfterSignature: true })
                    return;
                }

                objData.title = 'Sign Test Drive ' + (_selectedStock.make ? _selectedStock.make : '') + ' ' + (_selectedStock.model ? _selectedStock.model : '');
                objData.type = 'signOnly';
                //objData.testdriveId = this.newDocID;


                const refOnjData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${objData.contactID}/signRequest`).doc(objData.id);
                refOnjData.set(objData, { merge: true }).then((docRef) => {
                    this.unsubscribeSign = firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${objData.contactID}/signRequest/${objData.id}`)
                        .onSnapshot(docSnapshot => {
                            if (docSnapshot.exists) {
                                const _signRequest = Object.assign({}, docSnapshot.data());
                                _signRequest.id = docSnapshot.id;

                                this.setState({
                                    requestsSign: _signRequest
                                });

                                if (_signRequest.status === 'signed') {
                                    this.unsubscribeSign && this.unsubscribeSign();
                                    const fields = Object.assign({}, this.state.fields);
                                    fields.afterSignatureURL = _signRequest.signature;
                                    this.setState({ fields: fields });
                                }
                            }
                        })
                }).catch((error) => {
                    console.error("Error updating walkin_details: ", error);
                });

            }

        }
        this.setState({ showSignOption: false })
    }

    //#endregion

    //#region 
    onMentionChange = notesText => {
        const fields = Object.assign({}, this.state.fields);
        fields.notes = notesText
        this.setState({ fields: fields });
    };

    onMentionSelect = (option, ownerID) => {
        // console.log('select', option);
        const { name, id } = option;
        let idToPass = id
        // return if user is mentioning himself
        if (idToPass === localStorage.uid) {
            return;
        }

        if (id.includes(',')) {
            let idToPass = id
            if (idToPass.includes(localStorage.uid)) {
                idToPass = idToPass.replace(`${localStorage.uid},`, '')
                idToPass = idToPass.replace(`${localStorage.uid}`, '')
            }
            if (ownerID && idToPass.includes(ownerID)) {
                idToPass = idToPass.replace(`${ownerID},`, '')
                idToPass = idToPass.replace(`${ownerID}`, '')
            }
            this.setState({
                teamMentions: idToPass
            })
        }

        // Check to avoid adding duplicate entries in mention
        const check = this.state.mentions.filter(mention => mention.id === idToPass);

        if (check.length === 0) {
            this.setState({
                mentions: [...this.state.mentions, { name, id: idToPass }]
            })
        }
    };

    clearTeamMentions = () => {
        this.setState({
            teamMentions: null
        })
    }

    addActivitylog = (objData) => {

        const { fieldsOld } = this.state;
        if (objData.notes && fieldsOld.notes !== objData.notes) {
            try {
                const { dealersettings, clientUsers } = this.props;
                const newlogVM = Object.assign({}, objActivitylog);
                newlogVM.type = 'log';
                newlogVM.subType = 'note';

                newlogVM.owner = localStorage.uid;
                newlogVM.addedBy = localStorage.uid;
                newlogVM.modifiedBy = localStorage.uid;
                newlogVM.modifiedFrom = 'web';

                if (!_.isEmpty(objData.mentions))
                    newlogVM.mentions = objData.mentions;

                newlogVM.addedByName = dealersettings ? dealersettings.name : '';
                newlogVM.modifiedByName = dealersettings ? dealersettings.name : '';
                newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                newlogVM.startDate = window.firebase.firestore.Timestamp.now();
                newlogVM.enquiryID = objData.enquiryID ? objData.enquiryID : null;
                newlogVM.contactID = objData.contactID ? objData.contactID : null;
                newlogVM['enquiry'] = CommonHelper.getMinifiedData(objData.enquiry, 'enquiry');
                newlogVM['contact'] = CommonHelper.getMinifiedData(objData?.contact, 'contact');
                newlogVM.clientID = objData.clientID ? objData.clientID : dealersettings.client.id;
                newlogVM.documentID = objData.documentID ? objData.documentID : firestoreDB(dealersettings).firestore().collection('activities').doc().id;
                newlogVM.notes = objData.notes ? objData.notes : '';
                newlogVM.isDone = true;

                newlogVM.tags = ['testdrive'];
                Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
                newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                firestoreDB(dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
                    .set(newlogVM, { merge: true })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (error) {
                console.error(error, 'addActivitylog');
            }
        }

    }
    //#endregion


    //#endregion

    render() {

        const { show, docID, isDeivered, dealersettings, clientUsers, groupUsers, selectedInbound } = this.props;

        const {
            documentID, contact, stockID,
            startDate, startOdometer, endDate, endOdometer,
            beforeInspection, notes,
            afterSignatureURL, afterInspection,
            isVehicleHandOver, expectedReturn,
            rating, testdriveFeedback, tradePlate,
            distance, speed, duration, maxSpeed,
            polylineCoordinates, insurance, terms, status, mapCoordinatesPath,
            secondaryLicenseBackURL, secondaryLicenseFrontURL, secondarySignatureURL
        } = this.state.fields;

        const {
            stockNo, vinNo,
            make, model, saleType, stockInDate,
            year, regNo, extColor, clientID
        } = this.state.stock;

        const { stockModal, showStocks, fields, requestsTerms, requestsInsurance, requestsSign,
            showTermsOption, showInsuranceOption, showSignOption, stock, teamMentions } = this.state;
        const isTermsRequested = requestsTerms && requestsTerms.status === 'requested' ? true : false;
        const isInsuranceRequested = requestsInsurance && requestsInsurance.status === 'requested' ? true : false;
        const isSignRequested = requestsSign && requestsSign.status === 'requested' ? true : false;

        const isDisable = (isVehicleHandOver && docID) ? true : false;
        const isPendingReturn = (docID && status === testDriveStatus.COMPLETE && stock.status === stockStatus.ONTESTDRIVE && stock?.testDriveDetail?.testdriveID === docID) ? true : false
        const isComplete = isPendingReturn ? false : (status === testDriveStatus.COMPLETE) ? true : (isDeivered ? isDeivered : false);

        const returnVehicle = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.returnVehicle) ? true : (docID ? false : true));

        const testdriveHandover = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            dealersettings.rolePermissions.permissions.testdriveHandover) ? true : false);

        //const { isPopoverOpen } = this.state;
        const stockLocations = dealersettings?.client?.clientSettings?.stockLocations
        return (
            this.newDocID ?
                <>
                    <Modal
                        id={`add-testdrive-${this.newDocID}`}
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName={`modal-dialog-centered modal-dialog-scrollable modal-add-testdrive`}>

                        <Modal.Header closeButton>
                            <Modal.Title className='w-100'>
                                <Translate text={this.props.title} />

                                {
                                    !_.isEmpty(fields.tags)
                                        ?
                                        <>
                                            <div className="float-right kitchen-modal-status ml-2 pt-2">
                                                {
                                                    fields.tags.map((rec, index) => {
                                                        return <div key={index} className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">
                                                            {CommonHelper.getNameByValue(dealersettings.integrations, rec.type)}

                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <> </>
                                }
                            </Modal.Title>
                            {
                                fields.clientID && (!_.isEmpty(stockLocations) || fields.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') ?
                                    (<div className="contact-pop-groupname"><div className="badge badge-pill badge-groupname ml-2">{CommonHelper.getOtherDealerName(dealersettings, fields.clientID)}</div></div>)
                                    : (<></>)
                            }
                        </Modal.Header>
                        <Modal.Body >
                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" pop-layout form-style testdrive-pop">

                                        {
                                            !_.isEmpty(selectedInbound) && (<InboundTestdriveInfo inbound={selectedInbound} dealersettings={dealersettings} />)
                                        }

                                        <div className="testdrive-pophead">
                                            <div className={`left`}>
                                                {
                                                    (isDisable || isComplete)
                                                        ?
                                                        <></>
                                                        :
                                                        <div className="testdrive-search" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                showStocks: true
                                                            });
                                                        }}>
                                                            <input placeholder="Add Vehicle" aria-label="Search" readOnly className={this.state.errors["stockID"]} />
                                                        </div>
                                                }

                                                {
                                                    ((stockID && !_.isEmpty(this.state.stock)) || (isComplete && !_.isEmpty(make)))
                                                        ?
                                                        (
                                                            <div className="vehicle-item flex-vehicle mt-2 add-testdrive-list">

                                                                <div className="vehicle-item-image">
                                                                    {
                                                                        (!_.isEmpty(this.state.stock.images))
                                                                            ?
                                                                            <a data-fancybox={`testdrive_img${documentID}`} href={this.state.stock.images[0]} onClick={(e) => { e.preventDefault(); }}>
                                                                                <img src={this.state.stock.images[0]} width="70" height="70" alt="" className="img-object-fit" />
                                                                            </a>
                                                                            :
                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                <img src={CommonHelper.showBrandLogo(dealersettings, make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                                            </a>
                                                                    }
                                                                </div>
                                                                <div className="vehicle-data">
                                                                    <div className="vehicle-item-title">{make} {model}</div>
                                                                    {/* <div className="vehicle-item-info">{extColor ? `${extColor}, ` : ''} {year ? year : ''}</div>
                                                                    <div className="vehicle-item-info">{'Reg #:'}{regNo ? `${regNo}, ` : '--'} {'Stock #:'}{stockNo ? ` ${stockNo}` : '--'} </div> */}
                                                                    {
                                                                        clientID && (!_.isEmpty(stockLocations) || clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (<div className="customer-avatar-titlesub blue-color"><strong>{CommonHelper.getOtherDealerName(dealersettings, clientID)}</strong></div>) : (<></>)
                                                                    }
                                                                    <div className="dashboard-testdrive-activity-column">
                                                                        Stock#: <div title={CommonHelper.showLocale(this.props, 'stockNo')}>{stockNo ? (<>{stockNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, stockNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div>
                                                                        {extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(this.props, 'exteriorColor')}>{extColor}</div></>)}
                                                                        {year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(this.props, 'year')}>{year}</div></>)}
                                                                        {regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(this.props, 'registrationNumber')}>{regNo ? (<>{regNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, regNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                                                                        {vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(this.props, 'vinNo')}>{vinNo ? (<>{vinNo} <span className="cursor-pointer copy-ico-opacity" onClick={(e) => CommonHelper.handleCopy(e, vinNo)}><i className="fas fa-copy"></i></span></>) : '--'}</div></>)}
                                                                        {/* {modelPrice && price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)} */}
                                                                    </div>
                                                                    <div className="vehicle-item-status">
                                                                        {saleType ? <div className="badge badge-pill badge-white">
                                                                            {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                saleType, '')}
                                                                        </div> : <></>}
                                                                        {this.state.stock.status ? CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, this.state.stock.status, '', 'ml-1') : <></>}
                                                                        {
                                                                            !_.isEmpty(stockInDate)
                                                                                ?
                                                                                <div className="badge badge-pill float-right status-stock-age">
                                                                                    <Translate text={'stockAge'} />{' : '}
                                                                                    {this.handleDiffDays(stockInDate)}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className={`vehicle-item flex-vehicle mt-2 add-testdrive-list`}>
                                                                <div className="text-center testdrive-vehicle-placeholder">
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
                                                                    <Translate text={'noVehicleHasBeenSelected'} />
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                            <div className="right">
                                                <div className="testdrive-contact-title mt-2">
                                                    {CommonHelper.displayContactIcon(contact, 'mr-1')}
                                                    {CommonHelper.displayContact([], contact, '--')}
                                                </div>
                                                {
                                                    (contact.businessContact && !_.isEmpty(contact.company))
                                                        ?
                                                        <div className="testdrive-contact-license">
                                                            {/* <i className={`ico icon-Contacts`}></i> */}
                                                            {CommonHelper.getFullName([], contact, '')}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    contact.displayID
                                                        ?
                                                        <div className="testdrive-contact-license"><Translate text={'contactID'} />:  {contact.displayID}</div>
                                                        :
                                                        <></>
                                                }
                                                <div className="testdrive-contact-license"><Translate text={'licenseNo'} />:  {contact.licenseNo ? contact.licenseNo : '--'}</div>
                                                {
                                                    contact.licenseExpiry
                                                        ?
                                                        <div className="testdrive-contact-license"><Translate text={'licenseExpiry'} />:
                                                            {moment(contact.licenseExpiry).format('DD MMM YYYY')}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    contact.isDLScan
                                                        ?
                                                        <div className="badge badge-pill badge-white ml-1 mt-1">
                                                            <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                            {`Digital Driving Licence`}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    !_.isEmpty(contact.terms)
                                                        ?
                                                        <div className="badge badge-pill badge-white mt-1">
                                                            <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                            {`Customer Consent ${contact.terms.verbalConsent ? '(Verbal)' : (contact.terms.consentType ? `(${contact.terms.consentType})` : '')}`}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                <div className={`testdrive-secondary-driver ${contact.isDLScan ? 'mt-1' : ''} ${this.state.errors["secondaryLicenseFrontURL"]}`}>
                                                    <button className={`btn btn-sm btn-default ${(isComplete && this.isValidSecondaryDriver() === false) ? 'btn-disable' : ''}`} onClick={(e) => {
                                                        e.preventDefault();

                                                        this.isStockExist()
                                                            ?
                                                            this.setState({ showSecondaryDriver: true })
                                                            :
                                                            this.errorChange('stockID')

                                                    }}>
                                                        {
                                                            this.isValidSecondaryDriver() === true
                                                                ?
                                                                <>
                                                                    <div className="ribbon-wrapper-featured"><div className="featured"></div></div>
                                                                    <i className="ico icon-card mr-1"></i>
                                                                    <Translate text={'secondaryDriver'} />
                                                                </>
                                                                :
                                                                <>
                                                                    <i className="ico icon-add mr-1"></i>
                                                                    <Translate text={'addSecondaryDriver'} />
                                                                </>
                                                        }

                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testdrive-popmiddle">
                                            <div className="testdrive-popmiddle-head">
                                                <div className=" float-left">
                                                    <h3><Translate text={'beforeTestDrive'} /></h3>
                                                </div>
                                                <div className=" float-right">

                                                    <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["beforeInspection"]}`} onClick={(e) => {
                                                        e.preventDefault();

                                                        this.isStockExist()
                                                            ?
                                                            this.setState({ showBeforeInspection: true })
                                                            :
                                                            this.errorChange('stockID')

                                                    }}>
                                                        {(!_.isEmpty(beforeInspection)) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                        <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                        <Translate text={'inspection'} />
                                                    </button>


                                                    <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["terms"]}`} onClick={(e) => {
                                                        e.preventDefault();

                                                        this.isStockExist()
                                                            ?
                                                            this.handleTermsClick()
                                                            :
                                                            this.errorChange('stockID')

                                                    }}>
                                                        {(!_.isEmpty(terms)) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}

                                                        {
                                                            isTermsRequested
                                                                ?
                                                                <>
                                                                    <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                    <Translate text={'Waiting for response'} />
                                                                </>
                                                                :
                                                                <><i className="ico icon-terms mr-1"></i><Translate text={'termsAndConditions'} /> </>
                                                        }
                                                    </button>

                                                    {
                                                        this.includeInsWaiverInTDTerms === false && (<button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["insurance"]}`} onClick={(e) => {
                                                            e.preventDefault();

                                                            this.isStockExist()
                                                                ?
                                                                this.handleInsuranceClick()
                                                                :
                                                                this.errorChange('stockID')


                                                        }}>
                                                            {(!_.isEmpty(insurance)) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                            {
                                                                isInsuranceRequested
                                                                    ?
                                                                    <>
                                                                        <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                        <Translate text={'Waiting for response'} />
                                                                    </>
                                                                    :
                                                                    <><i className="ico icon-waiver mr-1"></i><Translate text={'insuranceWaiver'} /></>
                                                            }
                                                        </button>)
                                                    }

                                                </div>
                                            </div>


                                            {
                                                (isComplete && !isVehicleHandOver)
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            testdriveHandover
                                                                ?
                                                                <>
                                                                    <div className={`testdrive-checkbox ${(isDisable || isComplete) ? 'form-disable' : ''}`}>
                                                                        <div className="checkbox icheck-success">

                                                                            <input
                                                                                type="checkbox"
                                                                                id="isVehicleHandOver"
                                                                                name="isVehicleHandOver"
                                                                                className="uncheck-activity"
                                                                                checked={isVehicleHandOver}
                                                                                onChange={(e) => {
                                                                                    this.handleCheckChange(e, 'isVehicleHandOver')
                                                                                }} />
                                                                            <label htmlFor="isVehicleHandOver"><Translate text={'carHandover'} /></label>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }

                                                    </>
                                            }


                                            {(isVehicleHandOver && !docID)
                                                ?
                                                <>
                                                    <div className="testdrive-form">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'expectedReturnDate'} /></label>
                                                                <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>

                                                                    <AntDatePicker
                                                                        value={expectedReturn ? moment(expectedReturn).format('YYYY-MM-DD') : null}
                                                                        name={'expectedReturn'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'expectedReturn') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                        disabledDate={this.minStartDate}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'expectedReturnTime'} /></label>
                                                                <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                                    <ReactTimePicker
                                                                        value={expectedReturn ? expectedReturn : null}
                                                                        //value={(startDate && typeof startDate === 'object' && Object.keys(startDate).length > 0) ? moment.unix(startDate.seconds)._d : null}
                                                                        name={'expectedReturn'}
                                                                        onChange={this.handleTimeChange}
                                                                        timeIntervals={15}
                                                                        placeholder='HH:MM'
                                                                        className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label ><Translate text={'currentOdometerReading'} /></label>
                                                                <NumberFormat
                                                                    id={'startOdometer'}
                                                                    thousandSeparator={true}
                                                                    placeholder={'current odometer reading'}
                                                                    className={`form-control ${this.state.errors["startOdometer"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'startOdometer')}
                                                                    value={startOdometer ? startOdometer : null} />
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label><Translate text={'tradePlate'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'trade plate'}
                                                                    className={`form-control ${this.state.errors["tradePlate"]}`}
                                                                    name="tradePlate"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={fields.tradePlate ? fields.tradePlate : ''}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={`testdrive-form ${(isComplete) ? 'form-disable' : ''}`}>
                                                        <div className="form-row">
                                                            <div className={`form-group col-md-3 ${(isDisable) ? 'form-disable' : ''}`}>
                                                                <label ><Translate text={'startDate'} /></label>
                                                                <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>

                                                                    <AntDatePicker
                                                                        value={startDate ? moment(startDate).format('YYYY-MM-DD') : null}
                                                                        name={'startDate'}
                                                                        onChange={(e) => { this.handleDateChange(e, 'startDate') }}
                                                                        format='DD/MM/YYYY'
                                                                        placeholder='DD/MM/YYYY'
                                                                        className={`form-control ${this.state.errors["startDate"]}`}
                                                                        disabledDate={this.minStartDate}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className={`form-group col-md-3 ${(isDisable) ? 'form-disable' : ''}`}>
                                                                <label ><Translate text={'startTime'} /></label>
                                                                <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                                    <ReactTimePicker
                                                                        value={startDate ? startDate : null}
                                                                        name={'startDate'}
                                                                        onChange={this.handleTimeChange}
                                                                        timeIntervals={15}
                                                                        placeholder='HH:MM'
                                                                        className={`form-control ${this.state.errors["startDate"]}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={`form-group col-md-3 ${(isComplete) ? 'form-disable' : ''}`}>
                                                                <label ><Translate text={'currentOdometerReading'} /></label>
                                                                <NumberFormat
                                                                    id={'startOdometer'}
                                                                    thousandSeparator={true}
                                                                    placeholder={'current odometer reading'}
                                                                    className={`form-control ${this.state.errors["startOdometer"]}`}
                                                                    onValueChange={this.onCurrencyChange.bind(this, 'startOdometer')}
                                                                    value={startOdometer ? startOdometer : null} />
                                                            </div>

                                                            <div className={`form-group col-md-3 ${(isComplete) ? 'form-disable' : ''}`}>
                                                                <label><Translate text={'tradePlate'} /></label>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'trade plate'}
                                                                    className={`form-control ${this.state.errors["tradePlate"]}`}
                                                                    name="tradePlate"
                                                                    onChange={this.handleOnCapChange}
                                                                    value={fields.tradePlate ? fields.tradePlate : ''}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {
                                                        (docID && isVehicleHandOver)
                                                            ?
                                                            <>
                                                                <div className={`testdrive-form ${(isComplete) ? 'form-disable' : ''}`}>
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-3">
                                                                            <label ><Translate text={'expectedReturnDate'} /></label>
                                                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>

                                                                                <AntDatePicker
                                                                                    value={expectedReturn ? moment(expectedReturn).format('YYYY-MM-DD') : null}
                                                                                    name={'expectedReturn'}
                                                                                    onChange={(e) => { this.handleDateChange(e, 'expectedReturn') }}
                                                                                    format='DD/MM/YYYY'
                                                                                    placeholder='DD/MM/YYYY'
                                                                                    className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                                //disabledDate={this.minStartDate}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group col-md-3">
                                                                            <label ><Translate text={'expectedReturnTime'} /></label>
                                                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                                                <ReactTimePicker
                                                                                    value={expectedReturn ? expectedReturn : null}
                                                                                    name={'expectedReturn'}
                                                                                    onChange={this.handleTimeChange}
                                                                                    timeIntervals={15}
                                                                                    placeholder='HH:MM'
                                                                                    className={`form-control ${this.state.errors["expectedReturn"]}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }
                                                </>
                                            }


                                        </div>
                                        <div className="divider-line"></div>

                                        {(isVehicleHandOver && !docID)
                                            ?
                                            <>
                                                <div className={`testdrive-dynamic-form mb-0 mt-2 form-group ${(isComplete) ? 'form-disable' : ''}`} >
                                                    <h3 className="testdrive-feedback"> <Translate text={'Notes'} /></h3>
                                                    <MentionEditor
                                                        mentionsData={this.mentionsData}
                                                        onMentionChange={this.onMentionChange}
                                                        onMentionSelect={this.onMentionSelect}
                                                        notes={notes ? notes : ''}
                                                        focusOnMount={false}
                                                        isNoteEmpty={this.state.errors.notes === 'input_error' && this.state.fields.notes === '' ? true : false}
                                                        teamMentions={teamMentions}
                                                        clearTeamMentions={this.clearTeamMentions}
                                                        clientUsers={this.props.clientUsers}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="testdrive-popmiddle-head mt-3">
                                                    <div className=" float-left">
                                                        <h3><Translate text={'afterTestDrive'} /></h3>
                                                    </div>
                                                    <div className=" float-right">

                                                        <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["afterInspection"]}`} onClick={(e) => {
                                                            e.preventDefault();

                                                            this.isStockExist()
                                                                ?
                                                                this.setState({ showAfterInspection: true })
                                                                :
                                                                this.errorChange('stockID')

                                                        }}>
                                                            {(!_.isEmpty(afterInspection)) ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                            <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-inspection')}></i>
                                                            <Translate text={'inspection'} />
                                                        </button>


                                                        <button className={`btn btn-sm btn-default  ml-1 ${this.state.errors["afterSignatureURL"]}`} onClick={(e) => {
                                                            e.preventDefault();

                                                            this.isStockExist()
                                                                ?
                                                                this.handleSignClick()
                                                                :
                                                                this.errorChange('stockID')

                                                        }}>
                                                            {afterSignatureURL ? <div className="ribbon-wrapper-featured"><div className="featured"></div></div> : <></>}
                                                            {
                                                                isSignRequested
                                                                    ?
                                                                    <>
                                                                        <span className="spinner-border spinner-button blue-color mr-2 ml-0 mt-0" role="status" aria-hidden="true"></span>
                                                                        <Translate text={'Waiting for response'} />
                                                                    </>
                                                                    :
                                                                    <><i className="ico icon-terms mr-1"></i><Translate text={'signature'} /></>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>

                                                {(isVehicleHandOver && !docID)
                                                    ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <div className={`testdrive-form ${(isComplete || !returnVehicle) ? 'form-disable' : ''}`}>
                                                            <div className="form-row">

                                                                <div className="form-group col-md-3">
                                                                    <label ><Translate text={'endDate'} /></label>
                                                                    <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>

                                                                        <AntDatePicker
                                                                            value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
                                                                            name={'endDate'}
                                                                            onChange={(e) => { this.handleDateChange(e, 'endDate') }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control ${this.state.errors["endDate"]}`}
                                                                            disabledDate={this.minEndDate}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="form-group col-md-3">
                                                                    <label ><Translate text={'endTime'} /></label>
                                                                    <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                                        <ReactTimePicker
                                                                            value={endDate ? endDate : null}
                                                                            name={'endDate'}
                                                                            onChange={this.handleTimeChange}
                                                                            timeIntervals={15}
                                                                            placeholder='HH:MM'
                                                                            className={`form-control ${this.state.errors["endDate"]}`}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group col-md-3">
                                                                    <label ><Translate text={'endOdometerReading'} /></label>
                                                                    <NumberFormat
                                                                        id={'endOdometer'}
                                                                        thousandSeparator={true}
                                                                        placeholder={'end odometer reading'}
                                                                        className={`form-control ${this.state.errors["endOdometer"]}`}
                                                                        onValueChange={this.onCurrencyChange.bind(this, 'endOdometer')}
                                                                        value={endOdometer ? endOdometer : null} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="divider-line"></div>

                                                    </>
                                                }

                                                <div className={`testdrive-dynamic-form mb-0 mt-2 form-group ${(isComplete) ? 'form-disable' : ''}`} >
                                                    <h3 className="testdrive-feedback"> <Translate text={'Notes'} /></h3>
                                                    <MentionEditor
                                                        mentionsData={this.mentionsData}
                                                        onMentionChange={this.onMentionChange}
                                                        onMentionSelect={this.onMentionSelect}
                                                        notes={notes ? notes : ''}
                                                        focusOnMount={false}
                                                        isNoteEmpty={this.state.errors.notes === 'input_error' && this.state.fields.notes === '' ? true : false}
                                                        teamMentions={teamMentions}
                                                        clearTeamMentions={this.clearTeamMentions}
                                                        clientUsers={this.props.clientUsers}
                                                    />
                                                </div>
                                                <div className={`testdrive-dynamic-form ${(isComplete || !returnVehicle) ? 'form-disable' : ''}`}>
                                                    <h3 className="testdrive-feedback"><i className="ico icon-feedback"></i> <Translate text={'feedback'} /></h3>

                                                    {!_.isEmpty(this.testdriveFeedback) && this.testdriveFeedback.filter(a => a.active).map(info => (
                                                        <div key={info.value} className="testdrive-qa">
                                                            <div className="float-left"> {info.name}</div>
                                                            <div className="float-right radio-testdrive-qa" >
                                                                <span className="switch switch-sm float-right">
                                                                    <InputCheckBox
                                                                        className="switch"
                                                                        id={info.value}
                                                                        name={info.value}
                                                                        checked={(!_.isEmpty(testdriveFeedback) && testdriveFeedback.indexOf(info.value) >= 0) ? true : false}
                                                                        onChange={(e) => { this.handleFeedbackChange(e) }} />
                                                                    <label htmlFor={info.value}></label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}


                                                    <div className="testdrive-qa">
                                                        <div className="float-left"> <Translate text={'customerSatisfaction'} /></div>
                                                        <div className="float-right">
                                                            <StarRatings
                                                                rating={rating ? rating : 0}
                                                                isAggregateRating={true}
                                                                changeRating={this.changeRating}
                                                                numberOfStars={5}
                                                                starRatedColor={'#ffc107'}
                                                                starHoverColor={'#ffc107'}
                                                                starDimension="20px"
                                                                starSpacing="2px"
                                                                name='rating'
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                            {
                                isComplete
                                    ?
                                    <>
                                        <div className="map-view-icon">
                                            {
                                                !_.isEmpty(mapCoordinatesPath)
                                                    ?
                                                    <>
                                                        <button type="button"
                                                            className={`btn btn-default float-left`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleGetTestDrivePath(mapCoordinatesPath);
                                                            }}>
                                                            <i className="ico icon-map mr-1"></i>
                                                            <Translate text={'mapView'} />
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button"
                                                            className={`btn btn-default float-left`}
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                if (!_.isEmpty(polylineCoordinates))
                                                                    this.setState({ showRouteMap: true })
                                                                else
                                                                    Swal.fire(CommonHelper.showLocale(this.props, 'No co-ordinates available in map view'), '', 'info')

                                                            }}>
                                                            <i className="ico icon-map mr-1"></i>
                                                            <Translate text={'mapView'} />
                                                        </button>
                                                    </>
                                            }

                                            <button type="button" className="btn btn-default float-right" onClick={(e) => {
                                                e.preventDefault();
                                                this.handleViewReport();
                                            }}>
                                                <i className="ico icon-waiver mr-1"></i>
                                                <Translate text={'report'} />
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <>

                                        <>
                                            {
                                                (docID && status === testDriveStatus.PENDING) || isPendingReturn
                                                    ?
                                                    <>
                                                        {
                                                            returnVehicle
                                                                ?
                                                                <>
                                                                    <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}><Translate text={'cancel'} /></button>
                                                                    <button type="button"
                                                                        className={`btn btn-success float-right`}
                                                                        onClick={(e) => this.handleSubmit(e)}>
                                                                        {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}

                                                                        <Translate text={'returnVehicle'} />
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}><Translate text={'cancel'} /></button>
                                                        <button type="button"
                                                            className={`btn btn-primary float-right`}
                                                            onClick={(e) => this.handleSubmit(e)}>
                                                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}

                                                            <Translate text={'save'} />
                                                        </button>
                                                    </>
                                            }
                                        </>

                                    </>
                            }
                        </Modal.Footer>

                    </Modal>

                    <PopUpModal show={showStocks}>
                        <Stocks
                            show={showStocks}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleClose={this.handleStockClose}
                            handleAdd={this.handleAddStock}
                            showAdd={true}
                            displayPrice={false}
                            availableType={stockAvail.TESTDRIVE}
                        />
                    </PopUpModal>
                    <PopUpModal show={stockModal.show}>
                        <AddStock
                            docID={stockModal.ID}
                            show={stockModal.show}
                            clsActive={stockModal.clsActive}
                            handleClose={this.handleStockClose}
                            title={stockModal.title}
                            enableSale={false}
                            enableTestDrive={true}
                            enableLoan={false}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                        >
                        </AddStock>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeInspection}>
                        <ImageCanvas
                            show={this.state.showBeforeInspection}
                            clsActive='overlay-modal active'
                            imagePath='testdrives'
                            marks={beforeInspection}
                            handleClose={this.handleBeforeInspectionClose}
                            isReadOnly={(isComplete) ? true : false}
                            dealersettings={dealersettings}
                            showCost={false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={this.state.showAfterInspection}>
                        <ImageCanvas
                            show={this.state.showAfterInspection}
                            clsActive='overlay-modal active'
                            imagePath='testdrives'
                            marks={afterInspection}
                            readOnlyMarks={beforeInspection}
                            handleClose={this.handleAfterInspectionClose}
                            isReadOnly={(isComplete) ? true : false}
                            dealersettings={dealersettings}
                            showCost={false}
                        ></ImageCanvas>
                    </PopUpModal>

                    <PopUpModal show={this.state.showSecondaryDriver}>
                        <SecondaryDriver
                            show={this.state.showSecondaryDriver}
                            clsActive='overlay-modal active'
                            licenseFront={secondaryLicenseFrontURL}
                            licenseBack={secondaryLicenseBackURL}
                            signURL={secondarySignatureURL}
                            marks={afterInspection}
                            handleClose={this.handleSecondaryDriverClose}
                            isDisable={(isComplete) ? true : false}
                            dealersettings={dealersettings}

                        ></SecondaryDriver>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeTerms}>
                        <Terms
                            show={this.state.showBeforeTerms}
                            clsActive='overlay-modal active'
                            imagePath='testdrives'
                            terms={terms}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'testdriveTC', contact?.language)}
                            includeInsWaiverInTDTerms={this.includeInsWaiverInTDTerms}
                            isMandatoryInsurance={this.mandatoryFields?.indexOf('insurance') >= 0 ? true : false}
                            insurance={insurance}
                            insuranceTC={CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', contact?.language)}
                            title={'termsAndConditions'}
                            isDisable={(isComplete) ? true : false}
                            handleClose={this.handleBeforeTermsClose}
                            dealersettings={dealersettings}

                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={this.state.showBeforeInsurance}>
                        <Terms
                            show={this.state.showBeforeInsurance}
                            clsActive='overlay-modal active'
                            imagePath='testdrives'
                            terms={insurance}
                            termsandCondition={CommonHelper.getTermsByField(dealersettings?.client, 'insuranceTC', contact?.language)}
                            title={'insuranceWaiver'}
                            handleClose={this.handleBeforeInsuranceClose}
                            isDisable={(isComplete) ? true : false}
                            dealersettings={dealersettings}
                        ></Terms>
                    </PopUpModal>

                    <PopUpModal show={this.state.showAfterSignature}>
                        <AfterSignature
                            show={this.state.showAfterSignature}
                            clsActive='overlay-modal active'
                            imagePath='testdrives'
                            signatureURL={afterSignatureURL}
                            handleClose={this.handleAfterSignatureClose}
                            isDisable={(isComplete) ? true : false}
                        ></AfterSignature>
                    </PopUpModal>

                    <PopUpModal show={this.state.showRouteMap}>
                        <RouteMap
                            show={this.state.showRouteMap}
                            clsActive='overlay-modal active'
                            polylineCoordinates={polylineCoordinates}
                            distance={distance}
                            speed={speed}
                            maxSpeed={maxSpeed}
                            duration={duration}
                            handleClose={this.handleRouteMapClose}
                            dealersettings={dealersettings}
                            isDisable={(isComplete) ? true : false}
                        ></RouteMap>
                    </PopUpModal>

                    <PopUpModal show={showTermsOption}>
                        <SignOptions
                            show={showTermsOption}
                            menu={isTermsRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleTermsChange}
                        />
                    </PopUpModal>

                    <PopUpModal show={showInsuranceOption}>
                        <SignOptions
                            show={showInsuranceOption}
                            menu={isInsuranceRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleInsuranceChange}
                        />
                    </PopUpModal>

                    <PopUpModal show={showSignOption}>
                        <SignOptions
                            show={showSignOption}
                            menu={isSignRequested ? sign_type.filter(e => e.value !== 'send') : sign_type}
                            handleClose={this.handleSignChange}
                        />
                    </PopUpModal>

                </>
                :
                <></>
        );
    }
}
