import React, { useState, useEffect } from 'react';
import {
	InputCheckBox,
	InputRadio,
	InputTextArea,
	ReactSelect,
	InputText,
	ReactMultiSelect,
	AntDatePicker,
	ReactTimePicker,
} from '../../../../components';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { removeHTMLTags } from '../../../../services/helper';

import '../../../../styles/customer-feedbackform.scss';

const PreviewForm = ({
	dealersettings,
	formFields,
	show,
	handleClose,
	title,
	feedbackForm,
}) => {
	const styles = {
		formAndFontColor: {
			background:
				!isEmpty(feedbackForm) && feedbackForm.formColor
					? feedbackForm.formColor
					: '#000000',
			color:
				!isEmpty(feedbackForm) && feedbackForm.fontColor
					? feedbackForm.fontColor
					: '#000000',
		},
	};

	useEffect(() => {
		// Create a style tag
		const styleTag = document.createElement('style');

		// Define your custom CSS rules
		const customStyles = `
		  .field-radius-ctm {
			border-radius: ${feedbackForm?.fieldRadius ? `${feedbackForm?.fieldRadius}px !important` : '1px'};
		  }
		`;

		// Set the style tag's content with your custom styles
		styleTag.innerHTML = customStyles;

		// Append the style tag to the document head
		document.head.appendChild(styleTag);

		// Clean up function to remove the style tag when the component is unmounted
		return () => {
			document.head.removeChild(styleTag);
		};
	}, [feedbackForm?.fieldRadius]); // Empty dependency array ensures this effect runs once after the initial render


	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog-centered modal-dialog-scrollable modal-email-preview modal-webform-preview'
				enforceFocus={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body className='pr-0 pl-0 pt-0'>
					<div className='container-fluid pr-0 pl-0'>
						<main role='main'>
							<div
								className='dynamicform-embed-previewwrap'
								style={{
									background:
										!isEmpty(feedbackForm) &&
											!isEmpty(feedbackForm.backgroundColor)
											? feedbackForm.backgroundColor
											: '#f4f7f8',
									fontFamily: feedbackForm?.fontFamily ? feedbackForm.fontFamily : 'Source Sans Pro',
									fontSize: feedbackForm?.fontSize ? `${feedbackForm?.fontSize}px` : '14px'
								}}
							>
								<div
									className='dynamicform-embed-main'
									style={styles.formAndFontColor}
								>
									<div className='container-fluid'>
										<div className='row'>
											<div className='pop-layout dynamicform-style'>
												{!isEmpty(formFields) &&
													formFields.some(a => a.type === 'header') ? (
													<div className='feedbackform-title text-center mb-4'>
														<h3 style={{ fontFamily: 'inherit' }}>
															{removeHTMLTags(formFields.find(a => a.type === 'header').label)}
														</h3>
													</div>
												) : (
													<div className='feedbackform-title'>
														<h3> </h3>
													</div>
												)}
												{!isEmpty(formFields) &&
													formFields.filter(a => a.type !== 'header').length >
													0 ? (
													formFields
														.filter(a => a.type !== 'header')
														.map((field, index) => {
															return (
																<div
																	key={index}
																	className='feedbackform-question-loop'
																	id={field.name}
																>
																	{field.type === 'makeAndModel' ? (
																		<>
																			<div className='row'>
																				<div className='col-6'>
																					<div
																						className={`feedbackform-question-container`}
																					>
																						{'Make'}
																						{field.required === true ? (
																							<span className='feedback-required-red'>
																								*{' '}
																							</span>
																						) : (
																							<></>
																						)}
																						{!_.isEmpty(field.description) ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>
																										{field.description}
																									</Tooltip>
																								}
																							>
																								<i className='ico icon-info icon-grey'></i>
																							</OverlayTrigger>
																						) : (
																							<></>
																						)}
																					</div>
																				</div>
																				<div
																					className='col-6'
																					style={{ paddingLeft: '6px' }}
																				>
																					<div
																						className={`feedbackform-question-container`}
																					>
																						{'Model'}
																						{field.required === true ? (
																							<span className='feedback-required-red'>
																								*{' '}
																							</span>
																						) : (
																							<></>
																						)}
																						{!_.isEmpty(field.description) ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>
																										{field.description}
																									</Tooltip>
																								}
																							>
																								<i className='ico icon-info icon-grey'></i>
																							</OverlayTrigger>
																						) : (
																							<></>
																						)}
																					</div>
																				</div>
																			</div>
																		</>
																	) : field.type === 'firstAndLastName' ? (
																		<>
																			<div className='row'>
																				<div className='col-6'>
																					<div
																						className={`feedbackform-question-container`}
																					>
																						{'First Name'}
																						{field.required === true ? (
																							<span className='feedback-required-red'>
																								*{' '}
																							</span>
																						) : (
																							<></>
																						)}
																						{!_.isEmpty(field.description) ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>
																										{field.description}
																									</Tooltip>
																								}
																							>
																								<i className='ico icon-info icon-grey'></i>
																							</OverlayTrigger>
																						) : (
																							<></>
																						)}
																					</div>
																				</div>
																				<div
																					className='col-6'
																					style={{ paddingLeft: '6px' }}
																				>
																					<div
																						className={`feedbackform-question-container`}
																					>
																						{'Last Name'}
																						{field.required === true ? (
																							<span className='feedback-required-red'>
																								*{' '}
																							</span>
																						) : (
																							<></>
																						)}
																						{!_.isEmpty(field.description) ? (
																							<OverlayTrigger
																								placement='bottom'
																								overlay={
																									<Tooltip>
																										{field.description}
																									</Tooltip>
																								}
																							>
																								<i className='ico icon-info icon-grey'></i>
																							</OverlayTrigger>
																						) : (
																							<></>
																						)}
																					</div>
																				</div>
																			</div>
																		</>
																	) : field.type === 'paragraph' ? (
																		<div className={`feedbackform-question-container feedbackform-paragraph-scroll`} dangerouslySetInnerHTML={{ __html: `${field.label}` }}>
																		</div>
																	) : field.type === 'pageBreak' ? (
																		<div id={field.name} className="form-page-break"> <span>Page Break </span> </div>
																	) : (
																		<div
																			className={`feedbackform-question-container`}
																		>
																			{removeHTMLTags(field.label)}
																			{field.required === true ? (
																				<span className='feedback-required-red'>
																					*{' '}
																				</span>
																			) : (
																				<></>
																			)}
																			{!_.isEmpty(field.description) ? (
																				<OverlayTrigger
																					placement='bottom'
																					overlay={
																						<Tooltip>
																							{field.description}
																						</Tooltip>
																					}
																				>
																					<i className='ico icon-info icon-grey'></i>
																				</OverlayTrigger>
																			) : (
																				<></>
																			)}
																		</div>
																	)}
																	{field.type === 'radio-group' ? (
																		<div className='feedbackform-answer-container dynamic-radio'>
																			<div className='feedbackform-input-wrap'>
																				{field.values
																					.filter(a => !_.isEmpty(a.value))
																					.map((option, index) => {
																						return (
																							<div
																								key={index}
																								className='radio icheck-success'
																							>
																								<InputRadio
																									id={`${field.name}-${index}`}
																									type='radio'
																									value={option.value}
																									name={field.name}
																									disabled={true}
																								/>
																								<label
																									htmlFor={`${field.name}-${index}`}
																								>
																									{' '}
																									{option.label}
																								</label>
																							</div>
																						);
																					})}
																			</div>
																		</div>
																	) : field.type === 'checkbox-group' ? (
																		<div className='feedbackform-answer-container dynamic-checkbox'>
																			<div className='feedbackform-input-wrap'>
																				<ul
																					className={`${field.toggle === true
																						? 'feedback-switch-ul'
																						: ''
																						}`}
																				>
																					{field.values
																						.filter(a => !_.isEmpty(a.value))
																						.map((option, index) => {
																							return (
																								<li key={index}>
																									<div
																										className={`${field.toggle === true
																											? 'switch switch-sm'
																											: 'checkbox icheck-success'
																											} form-disable`}
																									>
																										<InputCheckBox
																											className='switch'
																											id={`${option.value}`}
																											name={field.name}
																											onChange={e => { }}
																										/>
																										<label
																											htmlFor={`${option.value}`}
																										>
																											{' '}
																											{option.label}
																										</label>
																									</div>
																								</li>
																							);
																						})}
																				</ul>
																			</div>
																		</div>
																	) : field.type === 'select' ? (
																		<div className='form-row dynamic-select'>
																			<div className='form-group col-md-12'>
																				{field.multiple === true ? (
																					<ReactMultiSelect
																						options={field.values.filter(
																							a => !_.isEmpty(a.value),
																						)}
																						name={field.name}
																						placeholder={'select'}
																						value={''}
																						classNamePrefix={`field-radius-ctm basic-select`}
																						isMulti={true}
																						isSearchable={true}
																						isDisabled={true}
																					></ReactMultiSelect>
																				) : (
																					<ReactSelect
																						options={field.values.filter(
																							a => !_.isEmpty(a.value),
																						)}
																						name={field.name}
																						placeholder={'select'}
																						value={''}
																						classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																						isDisabled={true}
																					></ReactSelect>
																				)}
																			</div>
																		</div>
																	) : field.type === 'text' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<InputText
																					autoComplete='off'
																					className={`form-control field-radius-ctm `}
																					name={field.name}
																					value={''}
																					disabled={true}
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: ''
																					}
																				/>
																			</div>
																		</div>
																	) : field.type === 'makeAndModel' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<ReactSelect
																					options={[]}
																					name={'make'}
																					placeholder={'select make'}
																					onChange={(e, data) => { }}
																					value={''}
																					classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																					isDisabled={true}
																				/>
																			</div>
																			<div className='form-group col-md-6'>
																				<ReactSelect
																					options={[]}
																					name={'model'}
																					placeholder={'select model'}
																					onChange={(e, data) => { }}
																					value={''}
																					classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																					isDisabled={true}
																				/>
																			</div>
																		</div>
																	) : field.type === 'firstAndLastName' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-6'>
																				<InputText
																					autoComplete='off'
																					className={`form-control field-radius-ctm `}
																					name={'first-name'}
																					value={''}
																					disabled={true}
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: 'enter first name'
																					}
																				/>
																			</div>
																			<div className='form-group col-md-6'>
																				<InputText
																					autoComplete='off'
																					className={`form-control field-radius-ctm `}
																					name={'last-name'}
																					value={''}
																					disabled={true}
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: 'enter last name'
																					}
																				/>
																			</div>
																		</div>
																	) : field.type === 'phone' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<InputText
																					autoComplete='off'
																					className={`form-control field-radius-ctm `}
																					name={'phone'}
																					value={''}
																					disabled={true}
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: 'enter phone'
																					}
																				/>
																			</div>
																		</div>
																	) : field.type === 'email' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<InputText
																					autoComplete='off'
																					className={`form-control field-radius-ctm `}
																					name={'email'}
																					value={''}
																					disabled={true}
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: 'enter email'
																					}
																				/>
																			</div>
																		</div>
																	) : field.type === 'country' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<ReactSelect
																					options={[]}
																					name={'country'}
																					placeholder={!isEmpty(field.placeholder)
																						? field.placeholder
																						: 'enter country'}
																					onChange={(e, data) => {
																					}}
																					value={''}
																					classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																					isDisabled={true}
																				/>
																			</div>
																		</div>
																	) : field.type === 'dealer' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<ReactSelect
																					options={[]}
																					name={'dealer'}
																					placeholder={!isEmpty(field.placeholder)
																						? field.placeholder
																						: 'enter dealer'}
																					onChange={(e, data) => {
																					}}
																					value={''}
																					classNamePrefix={`cursor-pointer field-radius-ctm basic-select`}
																					isDisabled={true}
																				/>
																			</div>
																		</div>
																	) : field.type === 'datetime' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-9'>
																				<AntDatePicker
																					value={''}
																					name={'datetime'}
																					onChange={e => { }}
																					format='DD/MM/YYYY'
																					placeholder='DD/MM/YYYY'
																					className={`form-control field-radius-ctm`}
																				/>
																			</div>
																			<div className='form-group col-md-3'>
																				<div className='inner-addon right-addon'>
																					<i className='bg-icons ico icon-time'></i>
																					<ReactTimePicker
																						value={''}
																						name={'datetime'}
																						timeIntervals={15}
																						placeholder='h:mm aa'
																						className={`form-control`}
																					/>
																				</div>
																			</div>
																		</div>
																	) : field.type === 'textarea' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<InputTextArea
																					className={`form-control notes form-disable no-bg-textarea field-radius-ctm`}
																					name={field.name}
																					maxLength='280'
																					rows='3'
																					placeholder={
																						!isEmpty(field.placeholder)
																							? field.placeholder
																							: ''
																					}
																					onChange={(e, data) => {
																					}}
																					value={''}
																				></InputTextArea>
																			</div>
																		</div>
																	) : field.type === 'date' ? (
																		<div className='form-row'>
																			<div className='form-group col-md-12'>
																				<AntDatePicker
																					value={''}
																					name={'dob'}
																					onChange={e => { }}
																					format='DD/MM/YYYY'
																					placeholder='DD/MM/YYYY'
																					className={`form-control field-radius-ctm`}
																				/>
																			</div>
																		</div>
																	) : (
																		<></>
																	)}
																</div>
															);
														})
												) : (
													<></>
												)}
												{/* <div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label for=''>Title </label>
												<input type='text' className='form-control' id='' />
											</div>
										</div> */}

												<div className='form-row'>
													<div className='form-group col-md-12'>
														<a
															href='#'
															className='btn btn-dynamic w-100 d-inline-block mt-2'
															style={{
																background:
																	!isEmpty(feedbackForm) &&
																		!isEmpty(feedbackForm.primaryColor)
																		? feedbackForm.primaryColor
																		: '#4466f2',
																color: '#fff',
																pointerEvents: 'none',
																borderRadius: feedbackForm?.btnRadius ? `${feedbackForm?.btnRadius}px` : '1px'
															}}
														>
															Submit
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PreviewForm;
