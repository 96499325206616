
/** LIBRARIES */
import React, { useState, useMemo, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { Progress } from 'antd';
import Swal from 'sweetalert2'
import _ from 'lodash';
import moment from 'moment';
import {useDropzone} from 'react-dropzone';
/** COMPONENTS */
import { ReactSelect } from '../../../components';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';

import { objFiles, mandatoryFields } from '../../files/viewModel'
import { storageBucket } from '../../../services/helper';


const categories = [
    { active: true, value: 'agreements', label: 'Agreements' },
    { active: true, value: 'auditReports', label: 'Audit Reports' },
    { active: true, value: 'businessPlan', label: 'Business Plan' },
    { active: true, value: 'others', label: 'Others' }
]

const AddFile = props => {
  const {
    acceptedFiles,
    getRootProps, 
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
    } = useDropzone({    
        maxFiles: 1,
        accept: `.doc, .docx, .xls, .xlsx, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*`
    });
    let ref = window.firebase.firestore().collection('clients');
    const [state, setState] = useState({
        fields: Object.assign({}, objFiles),
        errors: {},
        category: props.activeTab ? props.activeTab : 'agreements',
        progress: 0
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(_.isEmpty(acceptedFiles)) 
            return

        const validateFiles = files => {
           // console.log('acceptedFiles', acceptedFiles)
            let filteredFiles = [];

            acceptedFiles.forEach(file => {
                var _size = file.size;
                var filesize = _size / 1024 / 1024;
                if (filesize > 10) {
                    Swal.fire('Maximum file size 10MB.', '', 'info')
                } else {
                    filteredFiles.push(file)
                }
            })

            if (filteredFiles.length > 0) {
                onSelectFile(filteredFiles)
            }
        }

        validateFiles(acceptedFiles)
    }, [acceptedFiles])

    //#region CHANGE EVENT
    const handleReactSelectChange = (e, data) => {
        let category = ''
        if (e)
            category = e.value;

        setState({ ...state, category: category });
    }

    const errorChange = (key) => {
        let errors = Object.assign({}, state.errors);
        let fields = Object.assign({}, state.fields);
        let errorClass = 'dashed_error';

        if (errors !== {}) {
            if (!fields[key] && mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            setState({ ...state, errors: errors });
        }

    }
    //#endregion
    //#region FILE UPLOAD

    const onSelectFile = (e) => {
        let files = e

        if (files && files.length > 0) {

            var _size = files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire('Maximum file size 10MB.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = files[0];
            reader.onloadend = () => {
                const fileVM = Object.assign({}, objFiles);
                fileVM.name = file.name;
                fileVM.size = file.size;
                fileVM.type = file.name.substr((file.name.lastIndexOf('.') + 1));
                fileVM.url = reader.result;
                setState({ ...state, fields: fileVM, progress: 0 }, () => { errorChange('url') });
            }
            reader.readAsDataURL(file)
        }
    }

    const handleFileRemove = (e) => {
        e.preventDefault();

        // document.getElementById("fileUpload").value = "";
        const fileVM = Object.assign({}, objFiles);
        setState({ ...state, fields: fileVM })
    }

    const showFileIcon = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                    type = <i className="ico icon-files-pdf"></i>;
                    break;
                case 'docx':
                case 'doc':
                    type = <i className="ico icon-files-word"></i>;
                    break;
                case 'xls':
                case 'xlsx':
                    type = <i className="ico icon-files-excel"></i>;
                    break;
                case 'zip':
                case '7z':
                case 'rar':
                    type = <i className="ico icon-files-zip"></i>;
                    break;
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = <i className="ico icon-files-audio"></i>;
                    break;
                case 'mp4':
                case 'avi':
                case 'wmv':
                case 'mpeg':
                case 'mov':
                    type = <i className="ico icon-files-video"></i>;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                    type = <i className="ico icon-files-image"></i>;
                    break;
                default:
                    type = <i className="ico icon-files-document"></i>;
            }

            return type;
        }
        else {
            return <i className="ico icon-files-document"></i>
        }


    }

    //#endregion


    //#region SAVE & SUBMIT 
    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, state.fields);
        let errorClass = 'dashed_error';

        for (let [key] of Object.entries(fields)) {
            if (mandatoryFields.indexOf(key) >= 0 && !fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        setState({ ...state, errors: errors });

        if (formIsValid === true) {
            addFile();
        }
        else {

            return formIsValid;
        }

    };

    const addFile = (e) => {
        setIsLoading(true)
        const testdrives = [];
        const objData = Object.assign({}, state.fields);

        let uid = moment()._d.getTime().toString() + '.' + objData.type;
        let clientID = props.clientID ? props.clientID : '';
        let contactID = props.contactID ? props.contactID : '';

        const dealerID = clientID
        const settingsID = props.dealersettings.client.settingsID

        if (dealerID && settingsID) { 
            var storageRef = storageBucket(props.dealersettings).ref(`${settingsID}/${dealerID}/files/${uid}`).putString(objData.url, 'data_url');
            storageRef.on('state_changed', (snapshot) => {
                //console.log(snapshot);
                var percentage = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);

                if (percentage > 0 && percentage < 99) {
                    setState({ ...state, progress: percentage })
                }
            },
                (error) => {
                    console.error(error);
                },
                () => {
                    testdrives.push(storageBucket(props.dealersettings).ref(`${settingsID}/${dealerID}/files`).child(uid).getDownloadURL()
                        .then(dataURL => {
                            //console.log(dataURL)
                            setState({ ...state, progress: 100 })
                            if (_.isEmpty(objData.addedBy)) {
                                objData.addedBy = props.dealersettings ? props.dealersettings.id : '';
                                objData.addedDate = window.firebase.firestore.Timestamp.now();
                                objData.addedFrom = 'web';
                            }
                            objData.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                            objData.modifiedFrom = 'web';
                            
                            objData.category = state.category;
                            objData.url = dataURL

                            if (!objData.clientID)
                                objData.clientID = clientID

                            if (!objData.contactID)
                                objData.contactID = contactID

                            if (!objData.documentID)
                                objData.documentID = ref.doc().id;

                            //Remove empty value from object
                            for (var propName in objData) {
                                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                                    delete objData[propName];
                                }
                            }

                            //console.log('objData', objData)

                            const updateRef = window.firebase.firestore().collection(`clients/${clientID}/files`).doc(objData.documentID);
                            updateRef.set(objData, { merge: true }).then((docRef) => {
                                //console.log(docRef);
                                setIsLoading(false)
                                toast.notify('File saved successfully', {
                                    duration: 2000
                                })
                                props.handleClose(objData.documentID);
                                props.setActiveTab(state.category)
                            }).catch((error) => {
                                console.error("Error updating contact_details: ", error);
                            });


                        })
                    )
                })
        }
    };
    
    const baseStyle = {
        width: '100%',
        background: '#f2f7ff',
        border: '2px dashed #a7cbff',
        minHeight: '180px',
        float: 'left',
        textDecoration: 'none',
        color: '#4466f2',
      };
      
    const activeStyle = {
        border: '2px dashed #2bb673',
    };
      
    const acceptStyle = {
        borderColor: '#2bb673',
    };
      
    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-file-manager"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={'Upload Files'} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            <div className="form-style w-100">
                                <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Choose Folder'} /></label>
                                        <ReactSelect
                                            options={categories}
                                            name={"category"}
                                            placeholder={'select model'}
                                            onChange={handleReactSelectChange}
                                            value={state.category}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </div>

                            <div className="upload-file-box">
                                {/*  
                                <div className={` ${state.errors["url"]}`}>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            style={{ display: 'none' }}
                                            onChange={(e) => onSelectFile(e)} 
                                        />
                                    <label 
                                        className="upload-iconwrap upload-box" 
                                        id="fileDtag" htmlFor="fileUpload" 
                                        style={{ margin: 0, cursor: 'pointer' }}
                                        onDrop={(e) => onSelectFile(e)}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                    >
                                        <i className="ico icon-files-upload"></i>
                                        <span className="upload-text"><Translate text={'Upload File'} /></span>
                                    </label>
                                </div>
                                  */}

                                    <div {...getRootProps({style, className: 'upload-iconwrap upload-box-dragndrop'})}>
                                        <input {...getInputProps()} />
                                        <i className="ico icon-files-upload"></i>
                                        <p>Drag 'n' drop some files here, or click to upload files</p>
                                    </div>

                                {
                                    state.fields.url
                                        ?
                                        <div className="uploaded-list-video">
                                            <div className="flex-upload">
                                                <div className="upload-clear">
                                                    <a href="#" onClick={(e) => handleFileRemove(e)} className={`${isLoading ? 'form-disable' : ''}`}>
                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div className="file-upload-icon">
                                                    {
                                                        showFileIcon(state.fields.type)
                                                    }
                                                </div>
                                                <div className="upload-data">
                                                    {state.fields.name}
                                                    {' (' + CommonHelper.showFileSize(state.fields.size) + ')'}
                                                </div>
                                            </div>
                                            {isLoading ? <Progress percent={state.progress} /> : <></>}
                                        </div>
                                        :
                                        <></>
                                }


                            </div>


                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose();
                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ${isLoading ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddFile