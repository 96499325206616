import React, { useState } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import SettingsTab from './settingsTab';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import { InputTextArea, TextEditor } from '../../../components';
import moment from 'moment';

const terms = [
    { name: 'Customer Consent', value: 'customerConsent', placeholder: 'customer consent' },
    { name: 'Age Consent', value: 'ageConsent', placeholder: 'age consent', text: true },
    { name: 'Disclaimer', value: 'disclaimer', placeholder: 'disclaimer' },
    { name: 'Test Drive', value: 'testdriveTC', placeholder: 'test drive terms & conditions' },
    { name: 'Marketing Preferences', value: 'marketingPreferences', placeholder: 'marketing preferences' },
    { name: 'Comments Title', value: 'commentsTitle', placeholder: 'comments title', text: true },
]

const EventSettings = (props) => {
    const [settings, setSettings] = useState(props.settings ? props.settings : {});
    const [activeTab, setActiveTab] = useState(`slots`);
    const [loader, setLoader] = useState(false)
    const timezone = props?.event?.timezone || moment.tz.guess();
    const updateSettings = (obj, newSettings, append) => {
        if (_.isArray(obj)) {
            setSettings({
                ...settings,
                [activeTab]: append ? [
                    ...obj,
                    ...(settings[activeTab] || [])
                ] : obj
            })
        }
        else {
            setSettings({
                ...settings,
                [activeTab]: settings[activeTab] ? newSettings ? [
                    ...settings[activeTab].map(rec => { return obj.value === rec.value ? { ...obj } : { ...rec } }),
                    obj
                ] : [
                    ...settings[activeTab].map(rec => { return obj.value === rec.value ? { ...obj } : { ...rec } })
                ] : [obj]
            })
        }

    }

    const updateMadatoryFields = (e, name, fieldName) => {
        if (name === 'guest' || name === 'guestEOI') {
            const { value } = e.target;
            var regexp = /^[0-9 \b]+$/;
            let number = _.trim(value);
            if (number === '' || regexp.test(number)) {
                setSettings({
                    ...settings,
                    [name]: Number(_.trim(value))
                })
            }
        } else if (name === 'dateFormat') {
            setSettings({
                ...settings,
                [name]: e ? e.value : null,
                ['dateFormatPhone']: e ? e.dateFormatPhone : null
            })
        } else if (name === 'timeFormat') {
            setSettings({
                ...settings,
                [name]: e ? e.value : null,
                ['timeFormatPhone']: e ? e.timeFormatPhone : null
            })
        } else if (fieldName === 'hiddenFields') {
            setSettings({
                ...settings,
                [fieldName]: settings[fieldName] ? {
                    ...settings[fieldName],
                    [name]: e
                } : {
                    [name]: e
                }
            })
        } else if (name === 'makes' || name === 'makesEOI') {
            setSettings({
                ...settings,
                [name]: e
            })
        }
        else {
            let mandFields = settings?.[activeTab]?.[name]?.split(',') || [];
            if (e.target.checked) {
                mandFields.push(e.target.name)
            } else {
                mandFields = mandFields.filter(a => a !== e.target.name);
            }
            setSettings({
                ...settings,
                [activeTab]: settings[activeTab] ? {
                    ...settings[activeTab],
                    [name]: _.uniq(_.reject(mandFields, _.isEmpty)).join(',')
                } : {
                    [name]: _.uniq(_.reject(mandFields, _.isEmpty)).join(',')
                }
            })
        }

    }


    const saveSettings = () => {
        setLoader(true)
        if (settings.slots) {
            settings.slots = settings.slots.map(rec => {
                let _name = rec.date?.seconds ? moment.unix(rec.date.seconds).tz(timezone).format(`${settings.dateFormat || 'DD/MM/YYYY'}`) : rec.date ? moment(rec.date).format(`${settings.dateFormat || 'DD/MM/YYYY'}`) : '';
                if (rec.startTime) {
                    _name += ' ' + moment.unix(rec.startTime.seconds).tz(timezone).format(`${settings.timeFormat || 'hh:mm A'}`);
                }
                if (rec.endTime) {
                    _name += ' - ' + moment.unix(rec.endTime.seconds).tz(timezone).format(`${settings.timeFormat || 'hh:mm A'}`);
                }
                return {
                    ...rec,
                    name: _name
                }
            })
        }
        if (settings.mandatoryFields) {
            Object.keys(settings.mandatoryFields).forEach(rec => {
                let _hiddenFields = settings?.hiddenFields?.[rec] ? settings.hiddenFields[rec] : [];
                let _mandatoryFields = settings?.mandatoryFields?.[rec] ? settings.mandatoryFields[rec].split(',') : [];

                settings.mandatoryFields[rec] = _.filter(_mandatoryFields, (v) => _.indexOf(_hiddenFields, v) < 0).join(',')
            })
        }
        firestoreDB(props.dealersettings).firestore().doc(`eventsPro/${props.eventsProID}/settings/${props.eventsProID}`)
            .set(
                {
                    ...CommonHelper.removeEmptyOrNull(settings),
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    projectId: window.secondaryProjectId
                })
            .then((snapshot) => {
                firestoreDB(props.dealersettings).firestore().doc(`eventsPro/${props.eventsProID}`)
                    .update(
                        {
                            dateFormat: settings?.dateFormat || null,
                            timeFormat: settings?.timeFormat || null
                        })
                setLoader(false)
                toast.notify(`Settings updated successfully`, {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const onEditorStateChange = (obj) => {
        let text = '';
        if (!_.isEmpty(obj) && !_.isEmpty(obj.rawData.blocks)) {
            obj.rawData.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        setSettings({
            ...settings,
            [obj.name]: _.isEmpty(text.trim()) ? null : obj.value
        });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog-centered modal-events-settings-mini"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`settings-tab-wrap tabs`} >
                        <div className='tabs-wraper'>
                            <nav>
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    className='nav-fill'
                                    mountOnEnter={true}
                                    onSelect={(key) => {
                                        setActiveTab(key)
                                    }}
                                >
                                    <Tab eventKey='slots' title={CommonHelper.showLocale(props, 'Slots')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.slots}
                                            allSettings={settings}
                                            updateSettings={updateSettings}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateMadatoryFields={updateMadatoryFields}
                                        />
                                    </Tab>
                                    <Tab eventKey='dietary' title={CommonHelper.showLocale(props, 'Dietary')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.dietary}
                                            allSettings={settings}
                                            updateSettings={updateSettings}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateMadatoryFields={updateMadatoryFields}
                                        />
                                    </Tab>
                                    <Tab eventKey='dataLocation' title={CommonHelper.showLocale(props, 'Data Capture Location')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.dataLocation}
                                            allSettings={settings}
                                            updateSettings={updateSettings}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateMadatoryFields={updateMadatoryFields}
                                        />
                                    </Tab>
                                    <Tab eventKey='attendeesDF' title={CommonHelper.showLocale(props, 'Attendee Fields')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.attendeesDF}
                                            allSettings={settings}
                                            updateSettings={updateSettings}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateMadatoryFields={updateMadatoryFields}
                                        />
                                    </Tab>
                                    <Tab eventKey='testdriveModels' title={CommonHelper.showLocale(props, 'Test Drive Vehicles')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.testdriveModels}
                                            allSettings={settings}
                                            updateSettings={updateSettings}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateMadatoryFields={updateMadatoryFields}
                                        />
                                    </Tab>
                                    {/* {
                                        props.event?.enableEOI ? (
                                            <Tab eventKey='source' title={CommonHelper.showLocale(props, 'Third Party Source')}>
                                                <SettingsTab
                                                    {...props}
                                                    settings={settings?.source}
                                                    allSettings={settings}
                                                    updateSettings={updateSettings}
                                                    activeTab={activeTab}
                                                    event={props.event}
                                                    updateMadatoryFields={updateMadatoryFields}
                                                />
                                            </Tab>
                                        ) : (<></>)
                                    } */}
                                    <Tab eventKey='mandatoryFields' title={CommonHelper.showLocale(props, 'Mandatory Fields')}>
                                        <SettingsTab
                                            {...props}
                                            settings={settings?.mandatoryFields}
                                            allSettings={settings}
                                            updateMadatoryFields={updateMadatoryFields}
                                            activeTab={activeTab}
                                            event={props.event}
                                            updateSettings={updateSettings}
                                        />
                                    </Tab>
                                    <Tab eventKey='privacyTerms' title={CommonHelper.showLocale(props, 'Privacy Terms')}>
                                        <Tabs defaultActiveKey={terms[0].value}
                                            className='nav-fill'
                                            mountOnEnter={true}>
                                            {
                                                terms.map((rec, index) => {
                                                    return <Tab key={index} eventKey={rec.value} title={CommonHelper.showLocale(props, rec.name)}>
                                                        <div className="settings-terms-wrap p-2 event-terms-fix">
                                                            {
                                                                rec.text ? (
                                                                    <InputTextArea
                                                                        className={`form-control`}
                                                                        name={rec.value}
                                                                        value={settings[rec.value]}
                                                                        onChange={(e) => {
                                                                            let { name, value } = e.target;
                                                                            if (value)
                                                                                value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
                                                                            setSettings({
                                                                                ...settings,
                                                                                [name]: value
                                                                            });
                                                                        }}
                                                                        rows="5">
                                                                    </InputTextArea>
                                                                ) : (
                                                                    <TextEditor
                                                                        name={rec.value}
                                                                        placeholder={rec.placeholder}
                                                                        value={settings[rec.value]}
                                                                        onChange={onEditorStateChange}
                                                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                                                        height={450}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </Tab>
                                                })
                                            }
                                        </Tabs>
                                    </Tab>
                                </Tabs>
                            </nav>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveSettings(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EventSettings;