import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { BarChart, ContentOverlay } from "../../../components";
import _images from '../../../images'
import Translate from '../../../constants/translate';
import { targets, colors, saleType } from '../../reports/yearlySalesPerformance/viewModel'


const YearlySalesPerformance = (props) => {
    const [loader, setLoader] = useState(true)
    const [reportData, setReportData] = useState([])
    const [reportDoc, setReportDoc] = useState([])
    const [client, setClient] = useState({})
    const [makes, setMakes] = useState({})
    const [years, setYears] = useState([])

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _reportID = params.get('id');
    const _clientIDs = params.get('clientids');
    const _makes = params.get('makes');

    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    const reportSnapshot = await window.firebase.firestore().doc(`reports/${_reportID}`).get();
                    setReportDoc(reportSnapshot.data());

                    let makePromise = [];
                    if (_makes)
                        _makes.split(',').forEach(rec => {
                            makePromise.push(window.firebase.firestore().doc(`brands/${rec}`).get());
                        })
                    const makeSnapshot = await Promise.all(makePromise);
                    let __makes = []
                    makeSnapshot.forEach(doc => {
                        if (doc.exists)
                            __makes.push(doc.data())
                    })
                    setMakes(__makes)
                    setClient(doc.data());
                } else {
                    setLoader(false)
                }
            })

    }, [])


    useEffect(() => {
        if (_.isEmpty(client))
            return

        const fiscalStart = client?.fiscalStart >= 0 ? client?.fiscalStart : 6;
        const currentfiscalStart = moment().month(fiscalStart).startOf('month').toDate();
        const currentfiscalEnd = moment().add(1, 'year').month(fiscalStart - 1).endOf('month').toDate();
        let _years = [];
        for (let i = 3; i > 0; i--) {
            _years.push({
                start: moment(currentfiscalStart).subtract(i, 'year').startOf('month').toDate(),
                end: moment(currentfiscalEnd).subtract(i, 'year').endOf('month').toDate()
            })
        }
        _years.push({
            start: currentfiscalStart,
            end: currentfiscalEnd
        })
        _years = _years.filter(r => r.start < moment().endOf('day').toDate()).map(r => {
            return {
                ...r,
                title: moment(r.start).year() === moment(r.end).year() ? `${moment(r.start).year()}` : `${moment(r.start).year()}-${moment(r.end).year()}`
            }
        })
        _years = _years.map((r, i) => {
            return {
                ...r,
                name: r.title,
                value: r.title,
                color: _years.length - 1 === i ? '#5c61d2' : colors[i],
                dummyData: _years.length - 1 === i ? { width: 20, height: 20 } : { width: 25, height: 7, top: 8, right: 20 },
                lineChart: _years.length - 1 !== i
            }
        })
        setYears(_years)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getOEMSummaryReport',
                params: JSON.stringify({
                    clientID: _clientIDs,
                    timezone: moment.tz.guess(),
                    startDate: moment(_years[0].start).format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                    wonLead: true,
                    hideWalkin: true
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data, _years)
                }
            });
        }
        catch (error) {
            setLoader(false)
        }

    }, [client])


    const loadReportData = (_allData, years) => {

        let _data = [];
        makes.filter(a => a?.value).forEach(mk => {
            let i = 0;
            saleType.forEach(rec => {
                const monthData = [];
                years.forEach(year => {
                    const months = [];
                    for (var m = moment(year.start).startOf('day'); m.isSameOrBefore(moment(year.end).startOf('day')); m.add(1, 'month')) {
                        const _startDate = moment(m).startOf('month').unix();
                        const _endDate = moment(m).endOf('month').unix();
                        const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.mk === mk.value), _startDate, _endDate, rec, 'sold', 'wd');
                        months.push(moment(m).month() <= moment().month() || moment(m).year() !== moment().year() ? {
                            wonCount: wonCount.actual,
                            arrwonCount: wonCount.arractual,
                            title: moment(m).format('MMM'),
                            name: `${year.title}-${moment(m).format('MMM')}`,
                            date: m
                        } : {
                            wonCount: null,
                            arrwonCount: [],
                            title: moment(m).format('MMM'),
                            name: `${year.title}-${moment(m).format('MMM')}`,
                            date: m
                        })
                    }
                    monthData.push({
                        name: year.title,
                        months
                    })
                })
                monthData.push({
                    name: `Target`,
                    months: monthData[0].months.map(r => {
                        return {
                            ...r,
                            name: `Target`,
                            wonCount: reportDoc?.target?.[mk.value.toLowerCase()]?.[rec.toLowerCase()] || 0,
                            arrwonCount: [],
                        }
                    })
                })
                _data.push({
                    title: `${mk.name.toUpperCase()} ${rec.toUpperCase()}`,
                    make: mk.value.toLowerCase(),
                    saleType: rec.toLowerCase(),
                    target: reportDoc?.target?.[mk.value.toLowerCase()]?.[rec.toLowerCase()] || 0,
                    monthData
                })
            });
        })
        if (_data.length) {
            const totalMonths = [];
            _data.forEach(rec => {
                rec.monthData.forEach(_months => {
                    totalMonths.push(..._months.months)
                })
            })
            const monthData = [];
            years.forEach(year => {
                const months = [];
                for (var m = moment(year.start).startOf('day'); m.isSameOrBefore(moment(year.end).startOf('day')); m.add(1, 'month')) {
                    months.push(moment(m).month() <= moment().month() || moment(m).year() !== moment().year() ? {
                        wonCount: _.sumBy(totalMonths.filter(a => a.name === `${year.title}-${moment(m).format('MMM')}`), 'wonCount'),
                        title: moment(m).format('MMM'),
                        name: `${year.title}-${moment(m).format('MMM')}`,
                        date: m
                    } : {
                        wonCount: null,
                        title: moment(m).format('MMM'),
                        name: `${year.title}-${moment(m).format('MMM')}`,
                        date: m
                    })
                }
                monthData.push({
                    name: year.title,
                    months
                })
            })
            monthData.push({
                name: `Target`,
                months: monthData[0].months.map(r => {
                    return {
                        ...r,
                        name: `Target`,
                        wonCount: reportDoc?.target?.['total']?.['total'] || 0,
                        arrwonCount: [],
                    }
                })
            })
            _data.push({
                title: 'TOTAL',
                make: 'total',
                saleType: 'total',
                target: reportDoc?.target?.['total']?.['total'] || 0,
                monthData
            })
        }
        _data = _data.map(data => {
            const cData = [];
            data.monthData.forEach(rec => {
                cData.push(...rec.months.map(a => { return { ...a, [rec.name]: a.wonCount, [`arr${rec.name}`]: a.arrwonCount } }))
            })
            return {
                ...data,
                chartData: cData
            }
        })
        setReportData(_.chain(_data).groupBy('make').map((value, key) => ({ key, value })).value());
    }

    const checkDataFilter = (_data, startDt, endDt, saleType, status, dateType, newlead) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won' || a.sts.toLowerCase() === 'delivered')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }


    return (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{client.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> Yearly Sales Performance Report <br></br>
                                    <span> {moment().format('DD/MM/YYYY hh:mm A')}</span>
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : reportData?.length === 0 ? (
                        <div className="table-wraper">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>) : (
                        <div className="table-page-break">
                            {
                                reportData.map((rec, j) => {
                                    return <div key={j} className='middle-section-wraper'>
                                        {
                                            rec.value.map((data, i) => {
                                                return <div key={i} className={`common-table-outer`}>
                                                    <table className="col-table-loop col-table">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="13" className="head-bg-main" scope="col">{data.title}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="head-light" scope="col" width="16%">        MONTH   </th>
                                                                {
                                                                    data.monthData[0].months.map((month, mi) => {
                                                                        return <th key={mi} scope="col" className="head-light text-center" width="7%">{month.title}</th>

                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.monthData.map((_monthdata, mdi) => {
                                                                    return <tr key={mdi}>
                                                                        <td className="text-left col-td-head"><b>{_monthdata.name === 'Target' ? (<>Target ({data.target})</>) : _monthdata.name}</b></td>
                                                                        {
                                                                            _monthdata.months.map((month, mi) => {
                                                                                return <td key={mi} className="text-center">
                                                                                    {month.wonCount}</td>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>
                                            })
                                        }
                                        <div className='report-loop-box-wrapper'>
                                            {
                                                rec.value.map((data, k) => {
                                                    return <div key={k} className={`col-loop-${data.title === 'TOTAL' ? '12' : '6'}`}>
                                                        <div className="col-report-box">
                                                            <BarChart
                                                                id={`${rec.key}-${k}`}
                                                                height={`360px`}
                                                                data={data.chartData}
                                                                columns={years.filter(r => !r.lineChart).map(r => { return r.value })}
                                                                lineColumns={[...years.filter(r => r.lineChart).map(r => { return r.value }), 'Target']}
                                                                dynamicColumns={[...years, ...targets]}
                                                                xCategory={'title'}
                                                                calculationType={'count'}
                                                                handleShowEnquiryList={(value, rec) => { }}
                                                                title={data.title}
                                                                dynColor={true}
                                                                labelMaxWidth={75}
                                                                hideBullet={true}
                                                                sameYAxis={true}
                                                            />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                        <div style={{ pageBreakAfter: 'always' }}></div>
                                    </div>

                                })
                            }
                        </div>
                    )
                }
                {
                    client ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                    {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{client.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }
            </section >
        </div >
    </>)
}

export default YearlySalesPerformance;