import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import UserList from './userList'
import UserRoles from './userRoles'

const Users = (props) => {

    return (
        <>
            <h2>User Management</h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey="users" className="nav-fill" mountOnEnter={true}>
                            <Tab eventKey="users" title="Users">
                                <UserList {...props}/>
                            </Tab>
                            <Tab eventKey="roles" title="Roles"> 
                                <UserRoles {...props}/> 
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Users