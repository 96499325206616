import React, { useState, useEffect, useContext, useMemo, useReducer } from 'react';
import _ from 'lodash'
import { TemplateContext } from '../templateContext'
import { ReactSelect, PopUpModal, ContentOverlay, TableView, UpgradePlan } from '../../../../components'
import Translate from '../../../../constants/translate';
import { levelOptions, sourceFields } from './viewModel';
import AddSource from './addSource';
import CommonHelper from '../../../../services/common';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import toast from 'toasted-notes'
import moment from 'moment';
import Swal from 'sweetalert2'

const ListReducer = (state, action) => {
    function updateData() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateData()
        }
        default:
            return state;
    }
};

const ThirdPartySource = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 255) })
    const { clients } = useContext(TemplateContext);
    const [loader, setLoader] = useState(true)
    const [allSource, dispatch] = useReducer(ListReducer, [])
    const [queryParam, setQueryParam] = useState({})
    const [sourceModel, setsourceModel] = useState({ show: false })
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [subregionOptions, setSubregionOptions] = useState([]);

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);


    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(props.dealersettings.regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setSubregionOptions(_.filter(props.dealersettings.subregions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'subregion')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(props.dealersettings.groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))

    }, [clientOptions])

    useEffect(() => {
        const collectionPromise = [];
        const userIndex = _.find(levelOptions, { value: props.dealersettings.level })?.index || 0;
        let _userLevel = levelOptions.filter(e => e.value !== 'oem' && e.index >= userIndex)
        _userLevel.forEach(level => {
            let _levelIDs = CommonHelper.getAllEventsProByLevel(props.dealersettings, level.value, true, true);
            for (let k = 0; k < Math.ceil(_levelIDs.length / 10); k++) {
                let refEnquiryData = window.firebase.firestore().collection('eventsProTPSource')
                    .where('isDeleted', '==', false)
                    .where('level', '==', level.value)
                    .where('levelIDs', 'array-contains-any', CommonHelper.arrPaginate(_levelIDs, 10, k + 1))
                collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
            }
        });
        if (collectionPromise.length > 0) {
            window.unsubSourcelist = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    allDocs.forEach(doc => {
                        querySnapshot.push(...doc);
                    });
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setLoader(false)
        }
    }, [])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        let _enquiries = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = convertVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _enquiries.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _.uniqBy(_enquiries, 'documentID'),
        });
        setLoader(false)
    }

    const convertVM = (doc) => {
        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objData = Object.assign({}, doc);
        const listVM = Object.assign({});
        for (let [key, value] of Object.entries(objData)) {
            //if (listVM.hasOwnProperty(key))
            listVM[key] = value;
        }

        if (listVM.level === 'individual' && listVM.levelIDs) {
            listVM.sublevelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(clients, (v) => _.indexOf(listVM.levelIDs, v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>)
            listVM.strlevels = _.filter(clients, (v) => _.indexOf(listVM.levelIDs, v.documentID) >= 0)
                .map((r, i) => { return r.name }).join(',');
        }
        else if (listVM.level && listVM.levelIDs) {
            listVM.sublevelsDOM = (<div className="Vehicle-availability">
                {
                    _.filter(props.dealersettings[`${listVM.level}s`], (v) => _.indexOf(listVM.levelIDs, v.documentID) >= 0)
                        .map((r, i) => { return <div key={i}><span>{r.name}</span></div> })
                }
            </div>);
            listVM.strlevels = _.filter(props.dealersettings[`${listVM.level}s`], (v) => _.indexOf(listVM.levelIDs, v.documentID) >= 0)
                .map((r, i) => { return r.name }).join(',');
        }
        listVM.levelValue = _.find(levelOptions, { 'value': listVM.level }) ? _.find(levelOptions, { 'value': listVM.level }).label : listVM.level;
        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.addedBy = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.addedBy);
        listVM.modifiedBy = doc.modifiedBy ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.modifiedBy) : '';

        listVM.eoiLink = (<div className="interest-url-share-bg">
            <div className="url-copy-inner"> <a href="#" onClick={(e) => {
                CommonHelper.handleCopy(e, listVM.eoiURL)
            }}><i className="fas fa-copy mr-1"></i></a></div>
            <a href={listVM.eoiURL} target='_blank'>{listVM.eoiURL}</a>
        </div>)
        listVM.registrationLink = (<div className="interest-url-share-bg">
            <div className="url-copy-inner"> <a href="#" onClick={(e) => {
                CommonHelper.handleCopy(e, listVM.registrationURL)
            }}><i className="fas fa-copy mr-1"></i></a></div>
            <a href={listVM.registrationURL} target='_blank'>{listVM.registrationURL}</a>
        </div>)

        return listVM;
    }

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 255)
            })
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 156) + 'px');
            document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 205) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 205) + 'px');
        //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 156) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    handleEdit(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteSource(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }
    const handledetailsClick = (e, id, data) => {
        //handleEdit(data)
    }

    const hadleDeleteSource = (data) => {
        //setPaging(false)
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`eventsProTPSource/${data.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        isDeleted: true
                    }, { merge: true })
                    .then(() => {
                        setLoader(false);
                        toast.notify(`Source deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleEdit = (data) => {
        setsourceModel({
            show: true,
            title: 'Edit Source',
            source: data
        })
    }

    const handleSwitchChange = (id, val) => {
        const source = allSource.find(item => item.documentID === id);
        if (!_.isEmpty(source)) {
            window.firebase.firestore().doc(`eventsProTPSource/${source.documentID}`)
                .set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    active: val
                }, { merge: true })
                .then(snapshot => {
                    toast.notify('Source updated successfully', {
                        duration: 2000
                    })
                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }

    }

    const handleAdd = () => {
        setsourceModel({
            show: true,
            title: 'Add Source'
        })
    }

    const handleModelClose = () => {
        setsourceModel({
            show: false
        })
    }

    return (<>
        <h2> <Translate text={'Third Party Source'} /></h2>
        <div className="divider-line"></div>
        <div className={`settings-templates-wrap`}>
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="filter-search search-icon">
                                    <input placeholder="Search" aria-label="Search" value={!_.isEmpty(queryParam) ? queryParam.searchText : ''} onChange={(e) => { setQueryParam({ ...queryParam, 'searchText': e.target.value }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                            handleAdd()
                        }} ><i className="ico icon-add mr-2"></i>  <Translate text={'Add Source'} /> </button>
                    </div>
                </div>
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                allSource.length > 0 ? (
                    <div className="common-table" style={{ width: windowSize.windowWidth + 'px' }}>
                        <TableView
                            datalist={_.orderBy(allSource.filter(({ title, status, triggerType }) =>
                            (!_.isEmpty(queryParam) ? (
                                ((!_.isEmpty(title) && !_.isEmpty(queryParam.searchText)) ? title.toLowerCase().includes(queryParam.searchText.toLowerCase()) : true) &&
                                ((!_.isEmpty(triggerType) && !_.isEmpty(queryParam.triggerType)) ? triggerType.toLowerCase().includes(queryParam.triggerType.toLowerCase()) : true) &&
                                ((!_.isEmpty(status) && !_.isEmpty(queryParam.eventType)) ? status.toLowerCase().includes(queryParam.eventType.toLowerCase()) : true)
                            ) : true)
                            ), ['addedDate.seconds'], ['desc'])}
                            height={windowSize.windowHeight}
                            width={windowSize.windowWidth}
                            columns={sourceFields}
                            dynamicFields={sourceFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                            handleActionClick={handleActionClick}
                            handleRowSingleClick={handledetailsClick}
                            handleSwitchChange={handleSwitchChange}
                            isSorting={true}
                            isTranslate={true}
                            dealersettings={props.dealersettings}
                        />
                    </div>
                ) : (
                    <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                        <div className="text-center p-5">
                            <p> <Translate text={'No data found'} /></p>
                            <button className="btn btn-sm btn-default" onClick={() => {
                                handleAdd()
                            }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                <Translate text={'Add Source'} />
                            </button>
                        </div>
                    </div>
                )
        }
        <PopUpModal show={sourceModel.show}>
            <AddSource
                {...props}
                {...sourceModel}
                handleClose={handleModelClose}
                groupOptions={groupOptions}
                individualOptions={clientOptions}
                regionOptions={regionOptions}
                subregionOptions={subregionOptions}
            />
        </PopUpModal>
    </>);
}

export default ThirdPartySource