/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes';
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { tipStatus, tipOfferStatus } from '../../../services/enum';
import { objTradeIPOffers, tipBasicDetailsVM } from '../viewModel';
import { firestoreDB } from "../../../services/helper";


export default class RequestOffersList extends Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = ['dealerPrice'];
        this.state = {
            errors: {},
            fields: Object.assign({ dealerPrice: props.offerPrice ? props.offerPrice : null }),
            isLoading: false,
            selfEvaluation: false

        }
    }

    componentDidMount() {

    }

    handleSelfEvalCheckChange = (e) => {

        const { name, checked } = e.target;

        this.setState({ selfEvaluation: checked });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state, selfEvaluation: (state[name] ? true : false) }, () => { this.errorChange(name) });
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (key) {
                if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                    errors[key] = errorClass;
                else
                    errors[key] = '';
            }
            else {
                errors = {};
                for (let [key, value] of Object.entries(fields)) {
                    if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                        if (!fields[key] && key !== 'totalCost') {
                            errors[key] = errorClass;
                        }
                    }
                }
            }

            this.setState({ errors: errors });
        }
    }


    handleValidFields = (isScroll) => {

        const { fields, selfEvaluation } = this.state;

        if (!fields.dealerPrice) {
            this.errorChange('dealerPrice')
            return false;
        }
        else
            return true;

    }


    handleSave = () => {
        if (this.handleValidFields(true) === true) {
            this.setState({ isLoading: true })

            this.saveSelfEvalOffers();
        }
    }

    saveSelfEvalOffers = () => {
        const { dealerPrice } = this.state.fields

        if (dealerPrice) {
            const { dealersettings, tradeinProID, tradeinpro, approveOfferAccess, groupUsers, clientUsers } = this.props;
            const currencySymbol = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

            let objData = Object.assign({}, objTradeIPOffers)

            let _currentUser = dealersettings ? dealersettings.id : '';
            let _currentDate = window.firebase.firestore.Timestamp.now();

            objData.addedBy = _currentUser;
            objData.addedDate = _currentDate;
            objData.modifiedBy = _currentUser;
            objData.modifiedDate = _currentDate;
            objData.evaluatedBy = _currentUser;
            objData.evaluationDate = _currentDate;
            objData.isSelfEvaluation = true;
            objData.evaluationPrice = dealerPrice;
            objData.dealerPrice = dealerPrice;
            objData.clientID = tradeinpro?.clientID ? tradeinpro.clientID : dealersettings.client.id;
            objData.projectId = tradeinpro?.projectId ? tradeinpro?.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            objData.status = tipOfferStatus.COMPLETED;
            objData.tradeinProID = tradeinProID;

            objData.currency = dealersettings?.client?.currency;

            let _tradein = Object.assign({}, tipBasicDetailsVM)
            for (let [key, value] of Object.entries(tradeinpro)) {
                if (_tradein.hasOwnProperty(key))
                    _tradein[key] = value;
            }
            _tradein.documentID = tradeinProID;

            //Remove empty value from object
            for (var propName in _tradein) {
                if (_tradein[propName] === null || _tradein[propName] === 0 || _tradein[propName] === undefined || _tradein[propName] === '' || _tradein[propName] === [] || (_.isObject(_tradein[propName]) && _.isEmpty(_tradein[propName]))) {
                    delete _tradein[propName];
                }
            }

            objData.tradeinPro = _tradein;

            let _msg = [];
            _msg.push(`${dealersettings?.name}'s self-evaluation offer sent with a price of ${CommonHelper.formatCurrency(currencySymbol, dealerPrice)}.`)
            // if (approveOfferAccess) {
            //     objData.approvedBy = _currentUser;
            //     objData.approvedDate = _currentDate;
            //     objData.approvedFrom = 'web';
            //     objData.isApproved = true;

            //     objData.selectedBy = _currentUser;
            //     objData.selectedDate = _currentDate;
            //     objData.selectedFrom = 'web';
            //     objData.isSelected = true;
            //     _msg.push(`Self evaluation offer ${CommonHelper.formatCurrency(currencySymbol, dealerPrice)} has been selected.`)
            //     _msg.push(`Evaluation approved with final price : ${CommonHelper.formatCurrency(currencySymbol, dealerPrice)}.`)
            // }
            // else {
            //     objData.selectedBy = _currentUser;
            //     objData.selectedDate = _currentDate;
            //     objData.selectedFrom = 'web';
            //     objData.isSelected = true;
            //     _msg.push(`Self evaluation offer ${CommonHelper.formatCurrency(currencySymbol, dealerPrice)} has been selected.`)
            // }

            objData.documentID = firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id;

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === 0 || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            const batch = firestoreDB(dealersettings).firestore().batch();
            batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${objData.tradeinProID}/offers/${objData.documentID}`), objData, { merge: true });

            if (objData['tradeinPro'])
                delete objData['tradeinPro']

            // batch.set(firestoreDB(dealersettings).firestore().doc(`tradeinPro/${objData.tradeinProID}`), {
            //     offer: objData,
            //     modifiedBy: _currentUser,
            //     modifiedDate: _currentDate,
            //     modifiedFrom: 'web'
            // }, { merge: true });

            _msg && _msg.forEach(_data => {
                let _objLogData = {
                    tradeinProID: tradeinProID,
                    offerID: objData.documentID,
                    projectId: objData?.projectId ? objData?.projectId : null,
                    message: _data
                }

                CommonHelper.saveTradeInProLog(dealersettings, _objLogData, batch);
            })

            batch.commit().then(snapshot => {

                toast.notify('Self evaluation offer saved successfully', { duration: 2000 });
                if (tradeinpro.status === tipStatus.NEW || tradeinpro.status === tipStatus.INPROGRESS) {

                    this.props.handleSave('status', tipStatus.PENDING, true)//(approveOfferAccess ? tipStatus.COMPLETED : tipStatus.PENDING)

                }
                // else if (tradeinpro.status === tipStatus.PENDING && approveOfferAccess) {
                //     this.props.handleSave('status', tipStatus.COMPLETED, true)

                // }

                this.setState({ isLoading: false })
                this.props.handleClose();


            }).catch((error) => {
                console.error("Error updating document: ", error);
            });


        }
        else
            this.setState({ isLoading: false })
    }

    render() {
        const divStyle = {
            maxHeight: '325px',
            overflow: 'auto'
        };
        const { show, title, dealersettings, clientUsers, groupUsers, tradeinpro } = this.props;
        const { users, selectedIds, isLoading, selfEvaluation, errors, selectedOffers } = this.state;
        const { dealerPrice } = this.state.fields;
        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.tradeinProPermissions)) ? dealersettings.rolePermissions.tradeinProPermissions : null);



        const selfEvaluationAccess = ((!_.isEmpty(_permissions) && _permissions.selfEvaluationAccess) ? true : false);
        const approveOfferAccess = false//((!_.isEmpty(_permissions) && _permissions.approveOfferAccess) ? true : false);

        return (
            <>
                <Modal
                    id="multi-list-self-evaluation"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-evaluation-list">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="modal-evaluation-list-wrap">

                                    {
                                        selfEvaluationAccess
                                            ?
                                            <>
                                                <h3 className="mt-3">
                                                    {
                                                        dealersettings.name
                                                            ? <>{`${dealersettings.name}'s Self-Evaluation`}</> : <Translate text={'Dealers Self Evaluation'} />
                                                    }

                                                </h3>

                                                <div className="tradein-section-selfevaluation">

                                                    {/* <div className="tradein-section-selfevaluation-head">
                                                        <div className="float-left">
                                                            <div className="checkbox icheck-success float-left mr-3">
                                                                <input
                                                                    type="checkbox"
                                                                    id={'self_Evaluation_Offers'}
                                                                    name={'self_Evaluation_Offers'}
                                                                    className="uncheck-activity"
                                                                    checked={selfEvaluation ? true : false}
                                                                    onChange={(e) => {
                                                                        this.handleSelfEvalCheckChange(e)
                                                                    }} />
                                                                <label htmlFor={'self_Evaluation_Offers'}><Translate text={'Self Evaluation'} /></label>
                                                            </div>
                                                        </div>

                                                    </div> */}
                                                    <div className="tradein-section-selfevaluation-middle">
                                                        <div className="form-style w-100">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-12">

                                                                    <NumberFormat
                                                                        id={'dealerPrice'}
                                                                        decimalScale={2}
                                                                        allowNegative={false}
                                                                        thousandSeparator={true}
                                                                        prefix={currencySymbol}
                                                                        placeholder={'Evaluation Price'}
                                                                        className={`form-control ${errors["dealerPrice"]}`}
                                                                        onValueChange={this.onCurrencyChange.bind(this, 'dealerPrice')}
                                                                        value={dealerPrice ? dealerPrice : null}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                            :
                                            <></>
                                    }

                                </div>
                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>
                        <button type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}>
                            <Translate text={'cancel'} />
                        </button>
                        <button type="button"
                            className={`btn btn-common button-green float-right `}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleSave();
                            }}>
                            {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={approveOfferAccess ? 'approve' : 'save'} />
                        </button>
                    </Modal.Footer>
                </Modal>
            </>


        );
    }
}

