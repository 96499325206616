import React, { useRef, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash';
import moment from 'moment';
import Translate from '../../../constants/translate';
import commonHelper from '../../../services/common'


const QuotationInfo = ({ enquiryID, dealersettings, isDeivered, quotation, clientUsers, currencySymbol, handleEditQuotation, handlePreviewQuotation, handleDeleteQuotation, addQuotation, deleteQuotation, enquiryStatus, enquiry, gdprDeleted }) => {
  const [editshow, setEditshow] = useState(false)
  const [requirementType] = useState(dealersettings && dealersettings.client && dealersettings.client.requirementType ? dealersettings.client.requirementType : 'vehicleContract')
  const node = useRef();


  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }

    setEditshow(false);
  };

  return (
    <div className='quotation-sidebar-wrap'>
      <div className='flex-quotation-sidebar'>
        {
          gdprDeleted
            ?
            <></>
            :
            <>
              <div className='quotation-sidebar-more'>
                {' '}
                <a href='#' onClick={(e) => {
                  e.preventDefault();
                  setEditshow(!editshow)
                  if (editshow)
                    document.addEventListener('click', setEditshow(false));
                }}>
                  <i className='ico icon-more'></i>
                </a>{' '}
                <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                  <ul className="more-dropdown">
                    {
                      (!addQuotation || isDeivered)
                        ?
                        <></>
                        :
                        <li>
                          <a href='' onClick={e => { e.preventDefault(); handleEditQuotation(`quote/details/${quotation.enquiryID}?type=edit&previewID=${quotation.documentID}`) }}>
                            <>
                              <i className="ico icon-edit"></i> <Translate text={quotation.quotationStatus === 'quote' && (enquiryStatus === 'won' || enquiryStatus === 'delivered') ? 'Generate order form' : 'edit'} />
                            </>
                          </a>
                        </li>
                    }

                    {(requirementType === 'automotiveServices') || (enquiry.pipeline === 'AfterSales') ? (
                      <li>
                        <a href='' onClick={e => { e.preventDefault(); handlePreviewQuotation(`quote/details/${quotation.enquiryID}?type=preview&previewID=${quotation.documentID}&showPrice=true`) }}>
                          <i className="ico icon-zoom"></i> <Translate text={'View with Price'} />
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a href='' onClick={e => { e.preventDefault(); handlePreviewQuotation(`quote/details/${quotation.enquiryID}?type=preview&previewID=${quotation.documentID}`) }}>
                          <i className="ico icon-zoom"></i> <Translate text={'View'} />
                        </a>
                      </li>
                    )}


                    {(requirementType === 'automotiveServices' || enquiry.pipeline === 'AfterSales') && (
                      <li>
                        <a href='' onClick={e => { e.preventDefault(); handlePreviewQuotation(`quote/details/${quotation.enquiryID}?type=preview&previewID=${quotation.documentID}&showPrice=false`) }}>
                          <i className="ico icon-zoom"></i> <Translate text={'View without Price'} />
                        </a>
                      </li>
                    )}
                    {deleteQuotation && !isDeivered ? (
                      <li>
                        <a href='' onClick={e => { e.preventDefault(); handleDeleteQuotation(quotation.documentID) }}>
                          <i className="ico icon-delete"></i> <Translate text={'Delete'} />
                        </a>
                      </li>
                    ) : (<></>)}

                  </ul>
                </div>
              </div>
            </>
        }

        <div className='quotation-sidebar-icon'>
          {' '}
          <i className='ico icon-contract'></i>{' '}
        </div>
        <div className='quotation-sidebar-data'>
          <div className='quotation-sidebar-text'>
            <div className='quotation-sidebar-id'>
              {' '}
              <strong>{quotation.quotationStatus === 'contract' ? 'Contract' : 'Quote'} #{quotation.displayID}</strong>

            </div>
            <div className='quotation-sidebar-title'>
              {' '}
              <strong>{quotation.vehicle.make ? quotation.vehicle.make : ''}{quotation.vehicle.model ? ` ${quotation.vehicle.model}` : ''}</strong>
            </div>
            <div className='quotation-sidebar-date'>
              <span>{!isEmpty(quotation.addedDate) ? moment(quotation.addedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''} </span>{` - ${!isEmpty(quotation.addedBy) ? commonHelper.getUserNamebyId(clientUsers, quotation.addedBy) : ''}`}
            </div>
            <div className='quotation-sidebar-inline'>
              <div className='quotation-sidebar-pricetag'>
                {' '}
                <NumberFormat
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={currencySymbol}
                  displayType={'text'}
                  value={quotation.payableOnDelivery ? quotation.payableOnDelivery : 0}
                />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationInfo;
