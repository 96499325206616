import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputText, ReactSelect, ReactMultiSelect } from '../../components'
import Translate from '../../constants/translate';
import uuid from 'react-uuid'
import { columnsAction } from './viewModel';

const CustomColumns = (props) => {
    const [dynColumns, setDynColumns] = useState(props?.report?.dynColumns ? props?.report?.dynColumns : [])
    const [errorFields, setErrorFields] = useState({});

    useEffect(() => {
        if (_.isEmpty(props?.report?.dynColumns)) {
            const newRow = { id: uuid() };
            setDynColumns([newRow])
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value, id } = e.target;
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setDynColumns(dynColumns.map(r => {
                return r.id === id ? {
                    ...r,
                    [name]: newarray1.join(' ')
                } : { ...r }
            }));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setDynColumns(dynColumns.map(r => {
                return r.id === id ? {
                    ...r,
                    [name]: str ? str.replace(/\u2028/g, '') : ''
                } : { ...r }
            }));
        }
        setErrorFields({
            ...errorFields,
            [`${name}-${id}`]: ''
        });
    }


    const handleSelectChange = (e, data, id) => {
        setDynColumns(dynColumns.map(r => {
            return r.id === id ? {
                ...r,
                [data.name]: e ? e.value : null
            } : { ...r }
        }));
        setErrorFields({
            ...errorFields,
            [`${data.name}-${id}`]: ''
        });
    }


    const handleMultiSelectChange = (selectedOptions, data, id) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setDynColumns(dynColumns.map(r => {
            return r.id === id ? {
                ...r,
                [data.name]: value
            } : { ...r }
        }));
        setErrorFields({
            ...errorFields,
            [`${data.name}-${id}`]: ''
        });

    }

    const handleSubmit = () => {
        if (isValidFilters()) {
            props.handleClose(dynColumns);
        }
    }

    const isValidFilters = () => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';

        dynColumns.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name-' + _data.id] = errorClass;
            }
            else if (_.isEmpty(_data.action)) {
                formIsValid = false;
                errors['action-' + _data.id] = errorClass;
            }
            else if (_.isEmpty(_data.columns)) {
                formIsValid = false;
                errors['columns-' + _data.id] = errorClass;
            }
            else if (_data.action === 'percentage' && _.isEmpty(_data.columns1)) {
                formIsValid = false;
                errors['columns1-' + _data.id] = errorClass;
            }
        });

        setErrorFields(errors);
        return formIsValid;
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.handleClose(null, true)}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-custom-columns"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid pr-0 pl-0">
                    <div className="rule-filter-head pl-0">
                        <div className="float-left rule-filter-info cursor-pointer">
                            Columns
                        </div>
                        <div className="float-right rule-add-btn">
                            <button type="button" className="btn btn-primary float-right ml-2"
                                onClick={(e) => {
                                    if (isValidFilters()) {
                                        const newRow = { id: uuid() }
                                        setDynColumns([
                                            ...dynColumns,
                                            newRow
                                        ]);
                                    }
                                }}>
                                <i className="ico icon-add mr-2"></i> Add
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`rules-group-wrapper query-builder`} style={{ minHeight: '250px' }}>
                    <div className="rules-group-body">
                        <div className="rules-list">
                            {
                                dynColumns.map((rec, index) => {
                                    return <div key={index} className={`rule-container`}>
                                        <div className="rule-actions">
                                            <a href="#"
                                                className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    Swal.fire({
                                                        title: 'Are you sure?',
                                                        text: `You want to delete?.`,
                                                        icon: 'info',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Yes',
                                                        cancelButtonText: 'No',
                                                    }).then(result => {
                                                        if (result.value) {
                                                            setDynColumns(dynColumns.filter(a => a.id !== rec.id))
                                                        }

                                                    });
                                                }}>
                                                <i className="ico icon-delete"></i>
                                            </a>
                                        </div>
                                        <div className="settings-head form-style mb-0">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-row">
                                                        <div className="col-3">
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control  ${errorFields["name-" + rec.id]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={rec?.name}
                                                                maxLength={500}
                                                                id={rec.id}
                                                            />
                                                        </div>
                                                        <div className={`col-2`}>
                                                            <ReactSelect
                                                                options={columnsAction}
                                                                name={"action"}
                                                                placeholder={'action'}
                                                                onChange={(e, data) => handleSelectChange(e, data, rec.id)}
                                                                value={rec?.action}
                                                                classNamePrefix={`${errorFields["action-" + rec.id]} cursor-pointer basic-select`}
                                                                id={rec.id}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className={`col-${rec?.action === 'percentage' ? '2' : '4'}`}>
                                                            <ReactMultiSelect
                                                                options={props.reportColumns.filter(a => a.calculationType.indexOf('count') >= 0
                                                                    && props.report.columns.includes(a.value))}
                                                                name={'columns'}
                                                                placeholder={`select columns`}
                                                                onChange={(options, data) => handleMultiSelectChange(options, data, rec.id)}
                                                                value={rec?.columns ? rec.columns : []}
                                                                classNamePrefix={`${errorFields["columns-" + rec.id]} cursor-pointer basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                                id={rec.id}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                        {
                                                            rec?.action === 'percentage' ? (<>
                                                                <div className="col-1">
                                                                    <span className='text-center percentage-custom'>%</span>
                                                                </div>
                                                                <div className="col-2">
                                                                    <ReactMultiSelect
                                                                        options={props.reportColumns.filter(a => a.calculationType.indexOf('count') >= 0
                                                                            && props.report.columns.includes(a.value))}
                                                                        name={'columns1'}
                                                                        placeholder={`select columns`}
                                                                        onChange={(options, data) => handleMultiSelectChange(options, data, rec.id)}
                                                                        value={rec?.columns1 ? rec.columns1 : []}
                                                                        classNamePrefix={`${errorFields["columns1-" + rec.id]} cursor-pointer basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                        id={rec.id}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            </>) : (<></>)
                                                        }
                                                        <div className={`col-2`}>
                                                            <ReactSelect
                                                                options={props.reportColumns.filter(a => a.calculationType.indexOf('count') >= 0
                                                                    && props.report.columns.includes(a.value))}
                                                                name={'moveAfter'}
                                                                placeholder={`move after`}
                                                                onChange={(e, data) => handleSelectChange(e, data, rec.id)}
                                                                value={rec?.moveAfter || null}
                                                                classNamePrefix={`${errorFields["moveAfter-" + rec.id]} cursor-pointer basic-select`}
                                                                isSearchable={true}
                                                                id={rec.id}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSubmit(e)}>
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose(null, true)} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default CustomColumns;