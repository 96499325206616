/** LIBRARIES */
import React, { useState, useEffect, useReducer, useMemo } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap'
/** VIEW-MODELS */

/** COMPONENTS */
import _images from '../../images'
import { CustomToggle, CustomMenu } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { CreatableReactSelect, ContentOverlay, ReactSelect, InputText, AntDatePicker, InputTextArea, PopUpModal, PieChart, NoDataPieChart, ReactMultiSelect } from '../../components';
import CompanyListReducer from '../company/companyListReducer'
import ScheduleReport from "../reports/scheduleReport";
import cronstrue from 'cronstrue';


const _mandatoryFields = [
    'make',
    'logDate',
    'model'
];

const acquisitionCertifiable = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' },
    { active: true, value: 'NA', label: 'N/A' }
]

const objFieldsVM = {
    logDate: null,
    make: '',
    model: '',
    certifiable: '',
    registration: '',
    buyer: '',
    vehicleSource: '',
    vehicleDestination: '',
    notes: ''
};

const AcquisitionList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 135) })
    const [acquisitions, dispatch] = useReducer(CompanyListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [isPaging, setPaging] = useState(false)
    const [pageLoader, setPageLoader] = useState(true)
    const pageLimit = 50
    const [searchText, setSearchText] = useState('');

    const [fields, setFields] = useState(objFieldsVM)
    const [errorFields, setErrorFields] = useState({});
    const [btnloader, setBtnLoader] = useState(false)

    const [editFields, setEditFields] = useState({})
    const [textEditor, setTexteditor] = useState({})
    const [schedulerShow, setSchedulerShow] = useState({
        show: false
    })
    const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'))
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers
    const [schedueReport, setSchedeReport] = useState({});
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [editMakes, setEditMakes] = useState([])
    const [editmodels, setEditModels] = useState([])
    const [filters, setFilters] = useState({})
    useEffect(() => {
        if (props?.dealersettings?.allMakeModels) {
            let _makes = [];
            let _models = [];
            props.dealersettings.allMakeModels.map(rec => {
                return _makes.push({
                    value: rec.value,
                    label: rec.name
                });
            })
            setMakes(_makes);
            if (fields.make
                && props.dealersettings.allMakeModels.filter(m => m.value === fields.make)[0]
                && props.dealersettings.allMakeModels.filter(m => m.value === fields.make)[0].models
            ) {
                props.dealersettings.allMakeModels.filter(m => m.value === fields.make)[0].models.map(rec => {
                    return _models.push({
                        value: rec.value,
                        label: CommonHelper.displayModelName(rec),
                    });
                });
            }
            setModels(_models);
        }
    }, [fields.make])

    useEffect(() => {
        if (props?.dealersettings?.allMakeModels && editFields) {
            let _makes = [];
            let _models = [];
            props.dealersettings.allMakeModels.map(rec => {
                return _makes.push({
                    value: rec.value,
                    label: rec.name
                });
            })
            setEditMakes(_makes);
            if (editFields.make
                && props.dealersettings.allMakeModels.filter(m => m.value === fields.make)[0]
                && props.dealersettings.allMakeModels.filter(m => m.value === fields.make)[0].models
            ) {
                props.dealersettings.allMakeModels.filter(m => m.value === editFields.make)[0].models.map(rec => {
                    return _models.push({
                        value: rec.value,
                        label: CommonHelper.displayModelName(rec),
                    });
                });
            }
            if (editFields.model && !_models.some(a => a.value === editFields.model)) {
                _models.push({
                    label: editFields.model,
                    value: editFields.model
                })
            }
            setEditModels(_models);
        }
    }, [editFields.make, editFields.model])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 135)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubscribeAcq && window.unSubscribeAcq();
        }
    }, [])

    useEffect(() => {
        const refScheduleReport = window.firebase.firestore().doc(`schedular/${props.dealersettings.client.id}-acquisition`)
            .onSnapshot(doc => {
                if (doc.exists) {
                    setSchedeReport(
                        {
                            ...doc.data(),
                            documentID: doc.id
                        })
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }
    }, [])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        let clientID = dealersettings.client ? dealersettings.client.id : '';

        let acqDataRef = window.firebase.firestore().collection('acquisitions')
            .where('clientID', '==', clientID)
            .where('isDeleted', '==', false)
            .where('logDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(filterDate).startOf('month')._d))
            .where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(filterDate).endOf('month')._d))
            .orderBy('logDate', 'desc')
        //acqDataRef = acqDataRef.orderBy('name', 'asc')

        // let searchKeywords = searchText;
        // if (searchKeywords) {
        //     let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)

        //     if (formattedSearchText.trim())
        //         acqDataRef = acqDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

        // }

        // if (hasMore.length > 0) {
        //     acqDataRef = acqDataRef
        //         .startAfter(hasMore[0])
        //         .limit(pageLimit)
        // }
        // else {
        //     acqDataRef = acqDataRef
        //         .limit(pageLimit)
        // }

        window.unSubscribeAcq = acqDataRef
            .onSnapshot(onCollectionUpdate);


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {

        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {

                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
                //console.log('onCollectionUpdate', change.doc.id, actionType, change.doc.data().owner);
            }
        })

        const acquisitions = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {

                acquisitions.push({
                    ...doc.data(),
                    documentID: doc.id
                });
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: acquisitions,
                sortName: 'logDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : acquisitions,
                sortName: 'logDate',
                sortOrder: 'desc'
            });
        }

        setDataload(false)
        setPageLoader(false)
        //setPaging(false)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    // const convertDataVM = (doc) => {

    //     doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
    //     doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

    //     const objComData = Object.assign({}, doc);
    //     const acquisition = Object.assign({});
    //     for (let [key, value] of Object.entries(objComData)) {
    //         acquisition[key] = value;
    //     }

    //     // acquisition.createdOn = !_.isEmpty(acquisition.addedDate) ? moment.unix(acquisition.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
    //     // acquisition.updatedOn = !_.isEmpty(acquisition.modifiedDate) ? moment.unix(acquisition.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

    //     // // let dealersettings = props.dealersettings;
    //     // // if (acquisition.clientID && dealersettings && dealersettings.group &&
    //     // //     dealersettings.group.clients && dealersettings.group.clients[acquisition.clientID] && dealersettings.group.clients[acquisition.clientID].settings) {
    //     // //     const _setttings = Object.assign({}, dealersettings.group.clients[acquisition.clientID].settings);

    //     // //     acquisition.type = CommonHelper.getNameByValue(_setttings.companyType, acquisition.type, '');
    //     // // }
    //     // // else {
    //     // //     const _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
    //     // //     acquisition.type = CommonHelper.getNameByValue(_setttings.companyType, acquisition.type, '');
    //     // // }

    //     // // if (acquisition.clientID && (acquisition.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
    //     // //     acquisition.dealerName = CommonHelper.getOtherDealerName(dealersettings, acquisition.clientID);
    //     // // }
    //     // // else
    //     // //     acquisition.dealerName = '';



    //     // //acquisition.owner = acquisition.owner ? CommonHelper.getUserNamebyId(allUsers, acquisition.owner) : '';
    //     // acquisition.addedBy = CommonHelper.getUserNamebyId(allUsers, acquisition.addedBy);
    //     // acquisition.modifiedBy = CommonHelper.getUserNamebyId(allUsers, acquisition.modifiedBy);
    //     return acquisition
    // }

    const handleDateChange = (val, name) => {

        setFields({
            ...fields,
            [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    };

    const handleEditDateChange = (val, name) => {

        setEditFields({
            ...editFields,
            [name]: val ? window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d) : null
        })
        setErrorFields({
            ...errorFields,
            ['edit' + name]: ''
        });

    };

    const handleSelectChange = (e, data) => {
        setFields({
            ...fields,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleCreate = (inputValue) => {
        let val = {
            label: inputValue,
            value: inputValue
        }
        setModels([...models, val])
        setFields({
            ...fields,
            ['model']: inputValue
        })
        setErrorFields({
            ...errorFields,
            ['model']: ''
        });
    };


    const handleEditCreate = (inputValue) => {
        let val = {
            label: inputValue,
            value: inputValue
        }
        setEditModels([...editmodels, val])
        setEditFields({
            ...editFields,
            ['model']: inputValue
        })
        setErrorFields({
            ...errorFields,
            ['editmodel']: ''
        });
    };

    const handleEditSelectChange = (e, data) => {
        if (data.name === 'make') {
            setEditFields({
                ...editFields,
                [data.name]: e ? e.value : '',
                ['model']: ''
            })
        }
        else {
            setEditFields({
                ...editFields,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['edit' + data.name]: ''
        });
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setFields({
                ...fields,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setFields({
                ...fields,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnEditChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setEditFields({
                ...editFields,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditFields({
                ...editFields,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['edit' + name]: ''
        });
    }

    const handleSubmit = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(fields)) {
            if (_mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(fields[key].toString())) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }
        setErrorFields(errors)

        if (!formIsValid) {
            return;
        }

        handleSave(true, fields)
    }

    const handleSave = (newFields, objData) => {

        const _userID = localStorage.uid;
        const _currentDate = window.firebase.firestore.Timestamp.now();
        if (newFields) {
            setBtnLoader(true)

            objData.addedBy = _userID;
            objData.addedDate = _currentDate;

            objData.isDeleted = false;
            objData.clientID = props.dealersettings ? props.dealersettings.client.id : '';
            objData.documentID = window.firebase.firestore().collection('acquisitions').doc().id;
        }

        objData.modifiedBy = _userID;
        objData.modifiedDate = _currentDate;

        window.firebase.firestore().doc(`acquisitions/${objData.documentID}`).set(objData, { merge: true })
            .then(snapshot => {
                setBtnLoader(false)
                setFields(objFieldsVM)
                toast.notify(`Acquisition ${newFields ? 'saved' : 'updated'} successfully`, {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const handleCancelFields = () => {
        setEditFields({})
        setTexteditor({})
    }

    const handleEditFields = (rec) => {
        setEditFields(rec)
        setTexteditor({
            [rec.documentID]: true
        })
    }

    const handleEditSave = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(editFields)) {
            if (_mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(editFields[key].toString())) {
                    formIsValid = false;
                    errors['edit' + key] = errorClass;
                }
            }
        }
        setErrorFields(errors)

        if (!formIsValid) {
            return;
        }

        setTexteditor({})
        handleSave(false, editFields);
        setEditFields({})
    }

    const handleDeleteFields = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {

                const updateRef = window.firebase.firestore().collection(`acquisitions`).doc(rec.documentID)
                updateRef.
                    set({
                        isDeleted: true,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then((docRef) => {
                        toast.notify('Acquisition successfully deleted.', {
                            duration: 2000
                        });
                    }).catch((error) => {
                        console.error("Error deleting Video: ", error);
                    });

            }
        })
    }

    const handlesearchText = (txt) => {
        setDataload(true)
        setHasMoreData([]);
        setPageLoader(true);
    }

    const acquisitionItems = useMemo(() => {
        let searchItems = [...acquisitions.filter(a =>
            (searchText ? (
                (!_.isEmpty(a.registration) ? a.registration.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.make) ? a.make.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.certifiable) ? a.certifiable.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.notes) ? a.notes.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.buyer) ? CommonHelper.getNameByValue(props.dealersettings.client.settings['acquisitionBuyer'], a.buyer).toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.vehicleSource) ? CommonHelper.getNameByValue(props.dealersettings.client.settings['acquisitionSource'], a.vehicleSource).toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.vehicleDestination) ? CommonHelper.getNameByValue(props.dealersettings.client.settings['acquisitionDestination'], a.vehicleDestination).toLowerCase().includes(searchText.toLowerCase()) : false) ||
                (!_.isEmpty(a.model) ? a.model.toLowerCase().includes(searchText.toLowerCase()) : false)
            )
                : true) &&
            (filters && !_.isEmpty(filters.make) ? filters.make.includes(a.make) : true) &&
            (filters && !_.isEmpty(filters.certifiable) ? a.certifiable === filters.certifiable : true) &&
            (filters && !_.isEmpty(filters.vehicleSource) ? filters.vehicleSource.includes(a.vehicleSource) : true) &&
            (filters && !_.isEmpty(filters.vehicleDestination) ? filters.vehicleDestination.includes(a.vehicleDestination) : true) &&
            (filters && !_.isEmpty(filters.buyer) ? filters.buyer.includes(a.buyer) : true)
        )];

        return searchItems;
    }, [acquisitions, searchText, filters]);

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setFilters({
            ...filters,
            [name]: value
        })

    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="filter-panel">

                    <div className="float-left fleet-head">
                        <h3><Translate text={'Acquisitions'} /></h3>
                    </div>

                    <div className="float-right">

                    </div>

                </div>
                <div className="filter-sub-panel mt-0 mb-0">

                    <div className="filter-search search-icon float-left">
                        <div className='input-group template-search'>
                            <input placeholder="Search" aria-label="Search" value={searchText}
                                onChange={(e) => {
                                    let _val = e.target.value
                                    setSearchText(_val);
                                    //handlesearchText(_val)
                                }} />
                            <div className='input-group-append input-search-clear'>
                                {!_.isEmpty(searchText) && (
                                    <a href='#' className='input-search-clear-icon' style={{ top: '9px' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchText('');
                                            //handlesearchText('')
                                        }}>
                                        <i className='ico icon-remove'></i>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='float-left ml-2' style={{ width: '150px' }}>
                        <ReactMultiSelect
                            options={makes}
                            name={"make"}
                            placeholder={'select make'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'make')}
                            value={filters.make ? filters.make : []}
                            classNamePrefix={`basic-select`}
                            id={`filter-make`}
                            isClearable={true}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                    <div className='float-left ml-2' style={{ width: '100px' }}>
                        <ReactSelect
                            options={acquisitionCertifiable}
                            name={"certifiable"}
                            placeholder={'certifiable'}
                            onChange={(e) => {
                                setFilters({
                                    ...filters,
                                    ['certifiable']: e ? e.value : ''
                                })
                            }}
                            value={filters.certifiable}
                            classNamePrefix={`basic-select`}
                            id={`filter-certifiable`}
                            isClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className='float-left ml-2' style={{ width: '200px' }}>
                        <ReactMultiSelect
                            options={props.dealersettings.client.settings['acquisitionBuyer'] ? _.map(props.dealersettings.client.settings['acquisitionBuyer'], function (c) {
                                return {
                                    value: c.value,
                                    label: c.name,
                                    active: _.isBoolean(c.active) ? c.active : true,
                                };
                            }) : []}
                            name={"buyer"}
                            placeholder={'select buyer'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'buyer')}
                            value={filters.buyer ? filters.buyer : []}
                            classNamePrefix={`basic-select`}
                            id={`filter-buyer`}
                            isClearable={true}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                    <div className='float-left ml-2' style={{ width: '200px' }}>
                        <ReactMultiSelect
                            options={props.dealersettings.client.settings['acquisitionSource'] ? _.map(props.dealersettings.client.settings['acquisitionSource'], function (c) {
                                return {
                                    value: c.value,
                                    label: c.name,
                                    active: _.isBoolean(c.active) ? c.active : true,
                                };
                            }) : []}
                            name={"vehicleSource"}
                            placeholder={'select source'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'vehicleSource')}

                            value={filters.vehicleSource ? filters.vehicleSource : []}
                            classNamePrefix={`basic-select`}
                            id={`filter-vehicleSource`}
                            isClearable={true}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>
                    <div className='float-left ml-2' style={{ width: '200px' }}>
                        <ReactMultiSelect
                            options={props.dealersettings.client.settings['acquisitionDestination'] ? _.map(props.dealersettings.client.settings['acquisitionDestination'], function (c) {
                                return {
                                    value: c.value,
                                    label: c.name,
                                    active: _.isBoolean(c.active) ? c.active : true,
                                };
                            }) : []}
                            name={"vehicleDestination"}
                            placeholder={'select destination'}
                            onChange={(option) => handleReactMultiSelectChange(option, 'vehicleDestination')}
                            value={filters.vehicleDestination ? filters.vehicleDestination : []}
                            classNamePrefix={`basic-select`}
                            id={`filter-vehicleDestination`}
                            isClearable={true}
                            isMulti={true}
                            isSearchable={true}
                        >
                        </ReactMultiSelect>
                    </div>






                    <div className="float-right">

                        <Dropdown className='enquiry-view-more float-right mt-0'>
                            <Dropdown.Toggle as={CustomToggle} className='common-button'>
                                <i className='ico icon-more'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={CustomMenu}
                                ChildClass="more-dropdown dropsub-fix"
                                xplacement="bottom-end"
                            >
                                <Dropdown.Item eventKey="2" onClick={(e) => {
                                    e.preventDefault();
                                    const url = `/dynamic/report/acquisition/${props.dealersettings.client.id}?date=${filterDate}`;
                                    window.open(url, '_blank');
                                }} ><i className="ico icon-mail-print" style={{ fontSize: '18px' }}></i> <Translate text={'Print Preview'}/>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                    setSchedulerShow({
                                        show: true,
                                        title: 'Schedule Report',
                                        report: {
                                            documentID: `${props.dealersettings.client.id}-acquisition`,
                                        }
                                    })
                                }}><i className="ico icon-email"></i> <Translate text={'Schedule Report'}/> </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {
                            !_.isEmpty(schedueReport) && schedueReport.cron && schedueReport.enabled ? (
                                <div className="reportsuccess-item-btn mr-2 float-right">
                                    <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                                    <div className="reportsuccess-inner-text">
                                        <div className="reportsuccess-inner-text-top"> <Translate text={'scheduled'}/> </div>
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                    </div>
                                    <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                    <div className='float-right mr-2'>
                        <AntDatePicker
                            value={filterDate}
                            name={'checkout-date'}
                            onChange={(val) => {
                                setFilterDate(moment(val).format('YYYY-MM-DD'));
                                setDataload(true)
                                setHasMoreData([]);
                                setPageLoader(true);
                            }}
                            format={'MMM YYYY'}
                            placeholder='DD/MM/YYYY'
                            className={`form-control`}
                            disabledDate={(current) => { return current && current > moment().endOf('day') }}
                            allowClear={false}
                            picker={'month'}
                        />
                    </div>
                </div>

                <div className="dynamicform-view-wrapper">

                    {
                        pageLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            (
                                <div className="dynamicform-sub-section-div p-0">
                                    <div className="dynamicform-left-panel">
                                        <div className="common-table form-style w-100" style={{ height: `${windowSize.windowHeight}px`, overflow: 'auto' }}>
                                            <table className="table table-bordered" >
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'date'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'make'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'model'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Certifiable'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Registration #'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Buyer'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Source'} /></th>
                                                        <th scope="col" className="head-light" width="10%"><Translate text={'Destination'} /></th>
                                                        <th scope="col" className="head-light" width="15%"><Translate text={'notes'} /></th>
                                                        <th scope="col" className="head-light border-right-0" width="5%"><Translate text={'Actions'} /></th>
                                                    </tr>  <tr>
                                                        <td width="10%"><div className="form-group mb-0">

                                                            <AntDatePicker
                                                                value={fields.logDate ? moment.unix(fields.logDate.seconds).format('YYYY-MM-DD') : null}
                                                                name={'logDate'}
                                                                id={`fields-logDate`}
                                                                onChange={(e) => { handleDateChange(e, 'logDate') }}
                                                                format='DD/MM/YYYY'
                                                                placeholder='DD/MM/YYYY'
                                                                className={`form-control ${errorFields["logDate"]}`}
                                                                disableContainer={true}
                                                                defaultPickerValue={filterDate}
                                                                disabledDate={(current) => { return current && (current < moment(filterDate).startOf('month') || current > moment(filterDate).endOf('month')) }}
                                                            />
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <ReactSelect
                                                                options={makes}
                                                                name={"make"}
                                                                placeholder={'select make'}
                                                                onChange={handleSelectChange}
                                                                value={fields.make}
                                                                classNamePrefix={`${errorFields["make"]} basic-select`}
                                                                id={`fields-make`}
                                                            >
                                                            </ReactSelect>
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <CreatableReactSelect
                                                                options={models}
                                                                name={"model"}
                                                                placeholder={'select model'}
                                                                onChange={handleSelectChange}
                                                                value={fields.model}
                                                                classNamePrefix={`${errorFields["model"]} basic-select`}
                                                                id={`fields-model`}
                                                                handleCreate={handleCreate}
                                                            >
                                                            </CreatableReactSelect>
                                                        </div></td>

                                                        <td width="10%"><div className="form-group mb-0">
                                                            <ReactSelect
                                                                // options={props.dealersettings.client.settings['acquisitionCertifiable'] ? _.map(props.dealersettings.client.settings['acquisitionCertifiable'], function (c) {
                                                                //     return {
                                                                //         value: c.value,
                                                                //         label: c.name,
                                                                //         active: _.isBoolean(c.active) ? c.active : true,
                                                                //     };
                                                                // }) : []}
                                                                options={acquisitionCertifiable}
                                                                name={"certifiable"}
                                                                placeholder={'select certifiable'}
                                                                onChange={handleSelectChange}
                                                                value={fields.certifiable}
                                                                classNamePrefix={`${errorFields["certifiable"]} basic-select`}
                                                                id={`fields-certifiable`}
                                                            >
                                                            </ReactSelect>
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'registration #'}
                                                                className={`form-control ${errorFields["registration"]}`}
                                                                name="registration"
                                                                onChange={handleOnChange}
                                                                value={fields.registration}
                                                                id={`fields-registration`}
                                                            />
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <ReactSelect
                                                                options={props.dealersettings.client.settings['acquisitionBuyer'] ? _.map(props.dealersettings.client.settings['acquisitionBuyer'], function (c) {
                                                                    return {
                                                                        value: c.value,
                                                                        label: c.name,
                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                    };
                                                                }) : []}
                                                                name={"buyer"}
                                                                placeholder={'select buyer'}
                                                                onChange={handleSelectChange}
                                                                value={fields.buyer}
                                                                classNamePrefix={`${errorFields["buyer"]} basic-select`}
                                                                id={`fields-buyer`}
                                                            >
                                                            </ReactSelect>
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <ReactSelect
                                                                options={props.dealersettings.client.settings['acquisitionSource'] ? _.map(props.dealersettings.client.settings['acquisitionSource'], function (c) {
                                                                    return {
                                                                        value: c.value,
                                                                        label: c.name,
                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                    };
                                                                }) : []}
                                                                name={"vehicleSource"}
                                                                placeholder={'select vehicle source'}
                                                                onChange={handleSelectChange}
                                                                value={fields.vehicleSource}
                                                                classNamePrefix={`${errorFields["vehicleSource"]} basic-select`}
                                                                id={`fields-vehicleSource`}
                                                            >
                                                            </ReactSelect>
                                                        </div></td>
                                                        <td width="10%"><div className="form-group mb-0">
                                                            <ReactSelect
                                                                options={props.dealersettings.client.settings['acquisitionDestination'] ? _.map(props.dealersettings.client.settings['acquisitionDestination'], function (c) {
                                                                    return {
                                                                        value: c.value,
                                                                        label: c.name,
                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                    };
                                                                }) : []}
                                                                name={"vehicleDestination"}
                                                                placeholder={'select vehicle destination'}
                                                                onChange={handleSelectChange}
                                                                value={fields.vehicleDestination}
                                                                classNamePrefix={`${errorFields["vehicleDestination"]} basic-select`}
                                                                id={`fields-vehicleDestination`}
                                                            >
                                                            </ReactSelect>
                                                        </div></td>
                                                        <td width="15%"><div className="form-group mb-0">
                                                            <InputTextArea
                                                                placeholder={'notes'}
                                                                className={`acquisition-notewrap ${errorFields["notes"]}`}
                                                                name="notes"
                                                                onChange={handleOnChange}
                                                                value={fields.notes}
                                                                id={`fields-notes`}
                                                                rows="1"
                                                            />
                                                        </div></td>

                                                        <td width="5%" className="border-right-0">
                                                            <div className="button-height-fix">
                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSubmit() }}>
                                                                    {
                                                                        btnloader ?
                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                            : (<><Translate text={'add'} /></>)
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </thead>

                                                <tbody>
                                                    {
                                                        !_.isEmpty(acquisitionItems) ?
                                                            acquisitionItems.map((rec, index) => {
                                                                return <tr
                                                                    key={index}
                                                                    onDoubleClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleEditFields(rec)
                                                                    }}>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<><AntDatePicker
                                                                                id={`fields-edit-logDate`}
                                                                                name="logDate"
                                                                                format='DD/MM/YYYY'
                                                                                placeholder='DD/MM/YYYY'
                                                                                className={`form-control ${errorFields["editlogDate"]}`}
                                                                                onChange={(e) => { handleEditDateChange(e, 'logDate') }}
                                                                                value={editFields.logDate ? moment.unix(editFields.logDate.seconds).format('YYYY-MM-DD') : null}
                                                                                disableContainer={true}
                                                                                disabledDate={(current) => { return current && (current < moment(filterDate).startOf('month') || current > moment(filterDate).endOf('month')) }}
                                                                            /></>) : (<>{rec.logDate ? moment.unix(rec.logDate.seconds).format('DD-MM-YYYY') : ''}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<ReactSelect
                                                                                id={`fields-edit-make`}
                                                                                options={editMakes}
                                                                                name={"make"}
                                                                                placeholder={'select make'}
                                                                                onChange={handleEditSelectChange}
                                                                                value={editFields.make}
                                                                                classNamePrefix={`${errorFields["editmake"]} basic-select`}
                                                                            >
                                                                            </ReactSelect>) : (<>{rec.make}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<CreatableReactSelect
                                                                                options={editmodels}
                                                                                name={"model"}
                                                                                placeholder={'select model'}
                                                                                onChange={handleEditSelectChange}
                                                                                value={editFields.model}
                                                                                classNamePrefix={`${errorFields["editmodel"]} basic-select`}
                                                                                id={`fields-edit-model`}
                                                                                handleCreate={handleEditCreate}
                                                                            >
                                                                            </CreatableReactSelect>) : (<>{rec.model}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%">{
                                                                        textEditor[rec.documentID] ? (<ReactSelect
                                                                            // options={props.dealersettings.client.settings['acquisitionCertifiable'] ? _.map(props.dealersettings.client.settings['acquisitionCertifiable'], function (c) {
                                                                            //     return {
                                                                            //         value: c.value,
                                                                            //         label: c.name,
                                                                            //         active: _.isBoolean(c.active) ? c.active : true,
                                                                            //     };
                                                                            // }) : []}
                                                                            options={acquisitionCertifiable}
                                                                            name={"certifiable"}
                                                                            placeholder={'select certifiable'}
                                                                            onChange={handleEditSelectChange}
                                                                            value={editFields.certifiable}
                                                                            classNamePrefix={`${errorFields["editcertifiable"]} basic-select`}
                                                                            id={`fields-edit-certifiable`}
                                                                        >
                                                                        </ReactSelect>) :
                                                                            (<>
                                                                                {rec.certifiable}
                                                                                {/* {_.find(props.dealersettings.client.settings['acquisitionCertifiable'], { 'value': rec.certifiable }) ? _.find(props.dealersettings.client.settings['acquisitionCertifiable'], { 'value': rec.certifiable }).name : rec.certifiable} */}
                                                                            </>)
                                                                    }</td>
                                                                    <td width="10%">{
                                                                        textEditor[rec.documentID] ? (<InputText
                                                                            autoComplete="off"
                                                                            placeholder={'registration'}
                                                                            className={`form-control ${errorFields["editregistration"]}`}
                                                                            name="registration"
                                                                            onChange={handleOnEditChange}
                                                                            value={editFields.registration}
                                                                            id={`fields-edit-registration`}
                                                                        />) : (<>{`${rec.registration}`}</>)
                                                                    }</td>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<ReactSelect
                                                                                options={props.dealersettings.client.settings['acquisitionBuyer'] ? _.map(props.dealersettings.client.settings['acquisitionBuyer'], function (c) {
                                                                                    return {
                                                                                        value: c.value,
                                                                                        label: c.name,
                                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                                    };
                                                                                }) : []}
                                                                                name={"buyer"}
                                                                                placeholder={'select buyer'}
                                                                                onChange={handleEditSelectChange}
                                                                                value={editFields.buyer}
                                                                                classNamePrefix={`${errorFields["editbuyer"]} basic-select`}
                                                                                id={`fields-edit-buyer`}
                                                                            >
                                                                            </ReactSelect>) :
                                                                                (<>{_.find(props.dealersettings.client.settings['acquisitionBuyer'], { 'value': rec.buyer }) ? _.find(props.dealersettings.client.settings['acquisitionBuyer'], { 'value': rec.buyer }).name : rec.buyer}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<ReactSelect
                                                                                options={props.dealersettings.client.settings['acquisitionSource'] ? _.map(props.dealersettings.client.settings['acquisitionSource'], function (c) {
                                                                                    return {
                                                                                        value: c.value,
                                                                                        label: c.name,
                                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                                    };
                                                                                }) : []}
                                                                                name={"vehicleSource"}
                                                                                placeholder={'select vehicle source'}
                                                                                onChange={handleEditSelectChange}
                                                                                value={editFields.vehicleSource}
                                                                                classNamePrefix={`${errorFields["editvehicleSource"]} basic-select`}
                                                                                id={`fields-edit-vehicleSource`}
                                                                            >
                                                                            </ReactSelect>) :
                                                                                (<>{_.find(props.dealersettings.client.settings['acquisitionSource'], { 'value': rec.vehicleSource }) ? _.find(props.dealersettings.client.settings['acquisitionSource'], { 'value': rec.vehicleSource }).name : rec.vehicleSource}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="10%">
                                                                        {
                                                                            textEditor[rec.documentID] ? (<ReactSelect
                                                                                options={props.dealersettings.client.settings['acquisitionDestination'] ? _.map(props.dealersettings.client.settings['acquisitionDestination'], function (c) {
                                                                                    return {
                                                                                        value: c.value,
                                                                                        label: c.name,
                                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                                    };
                                                                                }) : []}
                                                                                name={"vehicleDestination"}
                                                                                placeholder={'select vehicle destination'}
                                                                                onChange={handleEditSelectChange}
                                                                                value={editFields.vehicleDestination}
                                                                                classNamePrefix={`${errorFields["editvehicleDestination"]} basic-select`}
                                                                                id={`fields-edit-vehicleDestination`}
                                                                            >
                                                                            </ReactSelect>) :
                                                                                (<>{_.find(props.dealersettings.client.settings['acquisitionDestination'], { 'value': rec.vehicleDestination }) ? _.find(props.dealersettings.client.settings['acquisitionDestination'], { 'value': rec.vehicleDestination }).name : rec.vehicleDestination}</>)
                                                                        }
                                                                    </td>
                                                                    <td width="15%">{
                                                                        textEditor[rec.documentID] ? (<InputTextArea
                                                                            placeholder={'notes'}
                                                                            className={`acquisition-notewrap ${errorFields["notes"]}`}
                                                                            name="notes"
                                                                            onChange={handleOnEditChange}
                                                                            value={editFields.notes}
                                                                            id={`fields-edit-notes`}
                                                                            rows="1"
                                                                        />) : (<>
                                                                            {rec.notes ? <div className={'text-area-space'}>
                                                                                {rec.notes}
                                                                            </div> : <></>}
                                                                        </>)
                                                                    }</td>
                                                                    <td width="5%" className="border-right-0">
                                                                        <div style={{ width: '90px', float: 'left' }}>
                                                                            <div className="action-fix">
                                                                                {
                                                                                    textEditor[rec.documentID] ?
                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSave() }}> <i className="ico icon-check"></i></a></div>
                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelFields(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                        </>) :
                                                                                        (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditFields(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                            <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteFields(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                        </>)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            }) : (
                                                                <tr>
                                                                    <td colSpan={10} style={{ height: `${windowSize.windowHeight - 110}px` }}>
                                                                        <div className="spinner-loader h-100 p-5">
                                                                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                                <div className="no-data-txt mt-2"> <Translate text={'No Data'} /></div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    }

                                                </tbody>


                                            </table>
                                        </div>

                                    </div>
                                    <div className="dynamicform-right-panel">
                                        <div className="card dynamicform-custom-card h-100">
                                            <div className="card-header ">
                                            <Translate text={'report'} />
                                            </div>
                                            <div className="card-body" style={{ height: `${windowSize.windowHeight - 60}px`, overflow: 'auto' }}>
                                                <div className="w-100 d-inline-block">
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="80%"><Translate text={'Source'} upperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'Units'} upperCase={true} /></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props?.dealersettings?.client?.settings?.acquisitionSource && props.dealersettings.client.settings.acquisitionSource.map((data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{data.name}</td>
                                                                            <td className="count-large">{acquisitionItems.filter(e => e.vehicleSource === data.value).length}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                <tr>
                                                                    <td className='table-sub-total'>{'Total'}</td>
                                                                    <td className="count-large table-sub-total cursor-pointer">{acquisitionItems.filter(e => !_.isEmpty(e.vehicleSource)).length}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="w-100 d-inline-block">
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="80%"><Translate text={'Buyer'} upperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'Units'} upperCase={true} /></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props?.dealersettings?.client?.settings?.acquisitionBuyer && props.dealersettings.client.settings.acquisitionBuyer.map((data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{data.name}</td>
                                                                            <td className="count-large">{acquisitionItems.filter(e => e.buyer === data.value).length}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                {/* <tr>
                                                                    <td className='table-sub-total'>{'Total'}</td>
                                                                    <td className="count-large table-sub-total cursor-pointer">{acquisitionItems.filter(e => !_.isEmpty(e.vehicleSource)).length}</td>
                                                                </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="w-100 d-inline-block">
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="60%"><Translate text={'make'} upperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'Certifiable'} upperCase={true} /></th>
                                                                    <th scope="col" className="head-light" width="20%"><Translate text={'Total'} upperCase={true} /></th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props?.dealersettings?.client?.brands && props.dealersettings.client.brands.map((data, index) => {
                                                                        return <tr key={index}>
                                                                            <td>{data.name}</td>
                                                                            <td className="count-large">{acquisitionItems.filter(e => e.certifiable === 'Yes' && e.make.toLowerCase() === data.value.toLowerCase()).length}</td>
                                                                            <td className="count-large">{acquisitionItems.filter(e => e.make.toLowerCase() === data.value.toLowerCase()).length}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="report-mini-graph">
                                                    <div className="graph-head"><Translate text={'Source'} /></div>
                                                    <div className="graph-panel">
                                                        {
                                                            !_.isEmpty(acquisitionItems) ? (
                                                                <div className="dashboard-box-body p-0" style={{ minHeight: `350px` }}>
                                                                    <div className="text-center">
                                                                        <PieChart
                                                                            id={'acquisition-pie-chart'}
                                                                            height={'350px'}
                                                                            data={props?.dealersettings?.client?.settings?.acquisitionSource && props.dealersettings.client.settings.acquisitionSource.map((data) => {
                                                                                return {
                                                                                    name: data.name,
                                                                                    color: data.color,
                                                                                    count: acquisitionItems.filter(e => e.vehicleSource === data.value).length
                                                                                }
                                                                            })}
                                                                            handleShowEnquiryList={() => { }}
                                                                            legendPosition={'bottom'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                                                                    <div className="text-center">
                                                                        <NoDataPieChart
                                                                            id={'acquisition-pie-chart'}
                                                                            height={'350px'}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="report-mini-graph">
                                                    <div className="graph-head"><Translate text={'Certifiable'} /></div>
                                                    <div className="graph-panel">
                                                        {
                                                            !_.isEmpty(acquisitionItems) ? (
                                                                <div className="dashboard-box-body p-0" style={{ minHeight: `200px` }}>
                                                                    <div className="text-center">
                                                                        <PieChart
                                                                            id={'certifiable-pie-chart'}
                                                                            height={'200px'}
                                                                            data={acquisitionCertifiable.filter(a => a.value !== 'NA').map((data) => {
                                                                                return {
                                                                                    name: data.value === 'Yes' ? data.label : 'Total',
                                                                                    count: acquisitionItems.filter(e => props?.dealersettings?.client?.brands && props.dealersettings.client.brands.some(a =>
                                                                                        a.value.toLowerCase() === e.make.toLowerCase())
                                                                                        && (data.value === 'Yes' ? e.certifiable === 'Yes' : true)).length,
                                                                                    color: data.value === 'Yes' ? '#ef4136' : '#000000'
                                                                                }
                                                                            })}
                                                                            handleShowEnquiryList={() => { }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="dashboard-box-body" style={{ minHeight: `200px` }}>
                                                                    <div className="text-center">
                                                                        <NoDataPieChart
                                                                            id={'certifiable-pie-chart'}
                                                                            height={'200px'}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }

                </div>
            </div>
            <PopUpModal show={schedulerShow.show}>
                <ScheduleReport
                    {...props}
                    {...schedulerShow}
                    handleClose={() => setSchedulerShow({ show: false })}
                    reportPrefix={'acquisition'}
                ></ScheduleReport>
            </PopUpModal>
        </>
    )
}

export default AcquisitionList