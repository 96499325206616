import React, { useEffect,useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

const NoDataFunnelChart = (props) => {

   
 

    useEffect(() => {
        let chart = am4core.create(props.id, am4charts.SlicedChart);
        chart.data = props.data;
        chart.logo.disabled = true;
        chart.hiddenState.properties.opacity = 0;

        chart.data = [{
            "country": "Dummy",
            "disabled": true,
            "value": 1000,
            "color": am4core.color("#e9f0f3"),
            "opacity": 1,
            "strokeDasharray": "1",
            "tooltip": ""
        }];

         /* Create series */
         var series = chart.series.push(new am4charts.PyramidSeries());
         series.dataFields.value = "value";
         series.dataFields.category = "country";
         series.topWidth = am4core.percent(80);
         series.bottomWidth = am4core.percent(40);
         series.dataFields.hiddenInLegend = "disabled";

         /* Set tup slice appearance */
         var slice = series.slices.template;
         slice.propertyFields.fill = "color";
         slice.propertyFields.fillOpacity = "opacity";
         slice.propertyFields.stroke = "color";
         slice.propertyFields.strokeDasharray = "strokeDasharray";
         slice.propertyFields.tooltipText = "tooltip";

         series.labels.template.propertyFields.disabled = "disabled";
         series.ticks.template.propertyFields.disabled = "disabled";

        const funnelchart = chart;

        return () => {
            funnelchart && funnelchart.dispose()
        }
    }, [])
    //https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/
    return (
        <div id={props.id} style={{ width: "100%", height: props.height }}></div>
    );
}

export default NoDataFunnelChart;