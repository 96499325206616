import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import { TextEditor } from '../../../../components'

const PrivacyTerms = ({ dealer, onEditorStateChange, fleetPro, serviceFleetPro }) => {
    return (
        <>
            <Tabs defaultActiveKey="customerconsent" className="nav-fill">
                <Tab eventKey="customerconsent" title="Customer Consent">
                    <div className="settings-terms-wrap">
                        <div className="form-group">
                            <TextEditor
                                name={'customerConsent'}
                                placeholder={'customer consent'}
                                value={dealer.customerConsent}
                                onChange={onEditorStateChange}
                                toolbarOptions={['inline', 'list', 'textAlign']}
                                height={450}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="testdrivetc" title="Test Drive">
                    <div className="settings-terms-wrap">
                        <div className="form-group">
                            <TextEditor
                                name={'testdriveTC'}
                                placeholder={'test drive terms & conditions'}
                                value={dealer.testdriveTC}
                                onChange={onEditorStateChange}
                                toolbarOptions={['inline', 'list', 'textAlign']}
                                height={450}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="insurancewaiver" title="Insurance Waiver">
                    <div className="settings-terms-wrap">
                        <div className="form-group">
                            <TextEditor
                                name={'insuranceTC'}
                                placeholder={'insurance waiver terms & conditions'}
                                value={dealer.insuranceTC}
                                onChange={onEditorStateChange}
                                toolbarOptions={['inline', 'list', 'textAlign']}
                                height={450}
                            />
                        </div>
                    </div>
                </Tab>
                {
                    fleetPro ? (
                        <Tab eventKey="fleetTC" title="Fleet Terms">
                            <div className="settings-terms-wrap">
                                <div className="form-group">
                                    <TextEditor
                                        name={'fleetTC'}
                                        placeholder={'fleet terms & conditions'}
                                        value={dealer.fleetTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ) : (<></>)
                }
                {
                    serviceFleetPro ? (
                        <Tab eventKey="serviceFleetProTC" title="Service Fleet Pro Terms">
                            <div className="settings-terms-wrap">
                                <div className="form-group">
                                    <TextEditor
                                        name={'serviceFleetProTC'}
                                        placeholder={'service fleet terms & conditions'}
                                        value={dealer.serviceFleetProTC}
                                        onChange={onEditorStateChange}
                                        toolbarOptions={['inline', 'list', 'textAlign']}
                                        height={450}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ) : (<></>)
                }
            </Tabs>
        </>
    );
}

export default PrivacyTerms;