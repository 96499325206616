/** LIBRARIES */
import React from 'react';
import { Spinner } from 'react-bootstrap'

function SectionOverlay(props) {

    return (
        <div className={`section-loader ${props.active ? '' : 'hide'}`}>
            <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                <Spinner animation="border" role="status" variant="primary">
                </Spinner>
                <p className="text-muted mt-3">{props.text}</p>
            </div>
        </div>
    )
}

export default SectionOverlay