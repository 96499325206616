import React from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes';

import '../../../../styles/customer-feedbackform.scss';

const PublishForm = ({ show, handleClose, feedbackForm, title }) => {

	const text = `Live Endpoint URL: ${window.location.origin}/api/leads/${feedbackForm?.appraisal === true ? 'appraisal' : 'process'}

SystemType: ${feedbackForm?.value}
X-Secret-Key: ${feedbackForm?.secretKey}
X-Access-Key: ${feedbackForm?.accessKey}
`
	const handleClick = () => {
		navigator.clipboard.writeText(text);
		toast.notify('Code copied successfully', {
			duration: 2000,
		});
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-publish-dynamicform'
				enforceFocus={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{title || 'Embed Form'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<div className='link-share-dynamic-bg'>
								{/* <i className='fa- fa-link mr-1'></i> */}
								<xmp style={{ margin: '0px' }}>{text}</xmp>
							</div>
							<div className='d-inline-block w-100'>
								<button
									type='button'
									className='btn btn-primary float-right'
									onClick={handleClick}
								>
									<i className='fa fa-clone mr-1'></i> Copy Code
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PublishForm;
