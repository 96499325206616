import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import moment from 'moment'
import CommonHelper from '../../../services/common';
import { PopUpModal } from '../../../components';
import { default as _images } from "../../../images";
import { objContact } from '../../contacts/viewModel'
import ContactInfo from '../../contacts/contactInfo'
import { contactResponseCode, stockAvail, stockStatus } from '../../../services/enum';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { objRequirement } from '../../requirement/viewModel';

import Translate from '../../../constants/translate';
import AddFleet from '../add';

import AddStock from '../../stock/add'
import Stocks from '../../stock/list/index';
import { firestoreDB, storageBucket } from '../../../services/helper';

const LoanBookingsModal = ({ type, title, show, handleClose, data, dealersettings, clientUsers, groupUsers, handleUpdateLoanBooking }) => {
    const convertStockFromProps = (req) => {
        let objData = {}
        objData.make = req.Make ? req.Make : ''
        objData.model = req.Model ? req.Model : ''
        objData.year = req.Year ? req.Year : ''
        objData.regNo = req.RegNum ? req.RegNum : ''
        objData.vinNo = req.VIN ? req.VIN : ''
        return objData
    }

    const [fields, setFields] = useState({
        ...data,
    })
    const [_stock, setStock] = useState(data.request ? convertStockFromProps(data.request) : {})
    const [contact, setContact] = useState(data.contact ? data.contact : {})
    const [isLoading, setLoading] = useState(false);
    const [showContactInfo, setshowContactInfo] = useState(false)
    const [existingContactInfo, setexistingContactInfo] = useState({})
    const [isContactMerged, setContactMerged] = useState(false);
    const [showStock, setShowStock] = useState({
        show: false,
        data: null,
        id: null,
    });
    const [showFleet, setShowFleet] = useState({
        show: false,
        id: null,
    });
    const [showReturnVehicle, setShowReturnVehicle] = useState({
        show: false,
        id: null,
        data: null
    });

    const [showStockLists, setShowStockLists] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!_.isEmpty(contact)) {
            handleSaveContact();
        }
        // first create a contact
        // create stock
        // open loan vehicle modal with the stock as prop


        // crate contact
    }

    const uploadLicenseImage = async (imageBase64, name, contact) => {
        const ref = storageBucket(dealersettings).ref(`${contact.clientID}/contacts/${contact.documentID}/license`).child(name);
        const snapshot = await ref.putString(imageBase64, 'data_url');
        return snapshot.ref.getDownloadURL();
    }

    const handleSaveContact = async () => {
        try {
            setLoading(true);
            let objData = Object.assign({}, contact);
            let _param = {};
            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = dealersettings ? dealersettings.id : '';
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = dealersettings ? dealersettings.id : '';
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.isDeleted = false;
            objData.modifiedFrom = 'web';
            let defaultCountry = dealersettings?.client?.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
            //Remove empty value from object
            for (var propName in objData) {

                if (defaultCountry === 'AU' && (propName === 'phone' || propName === 'secondaryPhone')) {
                    objData[propName] = CommonHelper.validPhoneZeroPrefix(objData[propName], objData[`${propName}Code`])
                }

                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            if (_.isEmpty(objData.country)) objData.country = defaultCountry;

            // if (!_.isEmpty(enquiry.convertedLeadRefID) &&
            //     _.isEmpty(contact.mergeID) &&
            //     !_.isEmpty(objData.clientID) &&
            //     objData.clientID !== dealersettings.client.id) {
            //     objData = copyExistingContact(objData);
            // }
            let docID = objData.documentID;
            if (!objData.documentID)
                objData.documentID = firestoreDB(dealersettings).firestore().collection('contactDetails').doc().id;

            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(objData, dealersettings, objData.clientID, true);

            //save license images

            if (!_.isEmpty(data.licenseFront)) {
                try {
                    let frontImg = await uploadLicenseImage(data.licenseFront, moment()._d.getTime().toString() + '.png', objData);
                    objData.licenseFrontURL = frontImg;
                } catch (err) {
                    console.log(err)
                }
            }

            if (!_.isEmpty(data.licenseBack)) {
                try {
                    let backImg = await uploadLicenseImage(data.licenseBack, moment()._d.getTime().toString() + '.png', objData);
                    objData.licenseBackURL = backImg;
                } catch (err) {
                    console.log(err)
                }
            }


            // if (!objData.clientID)
            //     objData.clientID = fields.clientID ? fields.clientID : (dealersettings ? dealersettings.client.id : '');

            // if (!objData.groupID)
            //     objData.groupID = (dealersettings && dealersettings.client.group ? dealersettings.client.group : null);

            // if (!objData.regionID)
            //     objData.regionID = (dealersettings && dealersettings.client.region ? dealersettings.client.region : null);

            // if (!objData.oemID)
            //     objData.oemID = (dealersettings ? dealersettings.client.settingsID : null);

            if (objData.documentID)
                _param['contactID'] = objData.documentID;

            // if (objData.groupID) {
            //     _param['groupID'] = objData.groupID;
            // }

            const _pmds = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "pmds")[0]);
            if (_.isEmpty(docID) && !_.isEmpty(_pmds) && _pmds.active && _pmds.enabled)
                _param['pmds'] = _pmds;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'pmds')))
                _param['pmds'] = null;

            const _keyloop = Object.assign({}, !_.isEmpty(dealersettings) &&
                dealersettings.client &&
                dealersettings.client.integrations &&
                dealersettings.client.integrations.filter(e => e.type === "keyloop")[0]);
            _param['keyloop'] = _.isEmpty(docID) && !_.isEmpty(_keyloop) && _keyloop.active && _keyloop.enabled ? true : false;

            if (!_.isEmpty(objData.displayID) || (!_.isEmpty(objData.tags) && objData.tags.some(e => e.type === 'keyloop')))
                _param['keyloop'] = null;

            if (objData.mergeID) {
                _param['mergeID'] = objData.mergeID;
                //_param['pmds'] = null;
                delete objData['mergeID'];
            }

            _param = CommonHelper.setClientOrGroupOrRegionOrOemID(_param, dealersettings);
            _param['isShared'] = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.group) &&
                dealersettings.group.enableSharedContact) ? true : false);

            setContact(objData);
            const addContact = window.firebase.functions().httpsCallable('contact-saveContact');
            addContact({
                "model": objData,
                "param": _param
            }).then((res) => {

                let response = res.data;
                if (response.success === false) {
                    if (response.code === contactResponseCode.PhoneAlreadyExists ||
                        response.code === contactResponseCode.EmailAlreadyExists ||
                        response.code === contactResponseCode.LicenseAlreadyExists ||
                        response.code === contactResponseCode.PMDSAlreadyExists ||
                        response.code === contactResponseCode.KeyloopAlreadyExists) {
                        setLoading(false);
                        handleExistingContact(response);
                    }
                    else {
                        toast.notify(response.message, {
                            duration: 2000
                        })
                    }
                    return false;
                }
                else {
                    handleSaveApply();
                    //handleSaveStock();
                    return true;
                }
            });
        } catch (error) {
            errorLog(error, 'handleSaveContact');
        }
    }

    const handleExistingContact = (response) => {

        let _entity;
        let _msg;
        let _title;
        let _fullName = response.data ? CommonHelper.displayContactName([], response.data) : '';

        if (response.code === contactResponseCode.PhoneAlreadyExists) {
            _entity = "mobile no.";
            _title = "Mobile No. already exist";
        } else if (response.code === contactResponseCode.EmailAlreadyExists) {
            _entity = "email address";
            _title = "Email already exist";
        } else if (response.code === contactResponseCode.LicenseAlreadyExists) {
            _entity = "license no.";
            _title = "License No. already exist";
        }

        _msg = _entity ? `A contact ${_fullName} already has this ${_entity}.` : '';
        if (response.code === contactResponseCode.PMDSAlreadyExists || response.code === contactResponseCode.KeyloopAlreadyExists)
            _title = response.message;


        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, (_title ? _title : '')),
            html: _msg ? _msg : '',
            showCancelButton: true,
            confirmButtonClass: 'viewButton',
            confirmButtonColor: '#459E20',
            cancelButtonColor: '#17232D',
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'View Contact'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Okay'),
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                handleXContactShow(response.data)
            }
        });
    }

    const handleXContactShow = (data) => {
        try {

            if (!_.isEmpty(data)) {
                data = convertContactVM(data);
                setshowContactInfo(true);
                setexistingContactInfo(data);
            }
        } catch (error) {
            errorLog(error, 'handleXContactShow');
        }
    }

    const handleXContactClose = (type, objData) => {
        try {

            if (type && !_.isEmpty(objData)) {
                if (type === 'fillMissing') {
                    fillMissingContact(objData);
                }
                else if (type === 'replaceAll' || type === 'merge') {
                    setContactMerged(true);
                    replaceContact(objData)
                }
            }
            setshowContactInfo(false);
            setexistingContactInfo({})
        } catch (error) {
            errorLog(error, 'handleXContactClose');
        }
    }

    const convertContactVM = (doc) => {
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        return contact
    }

    const fillMissingContact = (objData) => {
        const _fields = Object.assign({}, contact);

        for (let [key, value] of Object.entries(objData)) {
            if (_fields[key] === null || _fields[key] === undefined || _fields[key] === false || _fields[key] === '' || _fields[key] === [] || (_.isObject(_fields[key]) && _.isEmpty(_fields[key]))) {
                _fields[key] = value;
            }
        }
        _fields.documentID = objData.documentID ? objData.documentID : '';
        _fields.owner = objData.owner ? objData.owner : '';
        _fields.addedBy = objData.addedBy ? objData.addedBy : '';
        _fields.addedDate = objData.addedDate ? objData.addedDate : null;
        _fields.isDeleted = false;
        setContact(_fields);
    }

    const replaceContact = (objData) => {
        const _fields = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objData)) {
            _fields[key] = value;
        }
        _fields.isDeleted = false;
        if (_.isEmpty(_fields.owner)) _fields.owner = localStorage.uid;
        setContact(_fields);
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: dealersettings.client.id,
            oemID: dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    const handleSaveStock = async () => {

        try {
            setLoading(true);
            const _objStock = Object.assign({}, objRequirement)
            let stock = {};
            let stockAlreadyExists = false
            if (dealersettings &&
                dealersettings.client &&
                dealersettings.client.settings) {
                if (data.request.RegNum) {
                    const checkStockByRegNum = await window.firebase.firestore().collection('stock')
                        .where('clientID', '==', dealersettings.client.id)
                        .where('regNo', '==', data.request.RegNum ? data.request.RegNum : '')
                        .where('isDeleted', '==', false).limit(1).get()

                    const checkStockByVIN = await window.firebase.firestore().collection('stock')
                        .where('clientID', '==', dealersettings.client.id)
                        .where('vinNo', '==', data.request.VIN ? data.request.VIN : '')
                        .where('isDeleted', '==', false).limit(1).get()

                    const checkStockByStockNo = await window.firebase.firestore().collection('stock')
                        .where('clientID', '==', dealersettings.client.id)
                        .where('stockNo', '==', data.request.StockNum ? data.request.StockNum : '')
                        .where('isDeleted', '==', false).limit(1).get()

                    if (checkStockByRegNum.size === 1) {
                        checkStockByRegNum.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (checkStockByVIN.size === 1) {
                        checkStockByVIN.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (checkStockByStockNo.size === 1) {
                        checkStockByStockNo.forEach(doc => {
                            stock = doc.data();
                        });
                    }

                    if (!_.isEmpty(stock)) {
                        _objStock.documentID = window.firebase.firestore().collection('stock').doc().id;
                        _objStock.stock = { ...stock };
                        _objStock.saleType = stock.saleType ? stock.saleType : '';
                        stockAlreadyExists = true
                    }
                }

                if (data.request.Make && data.request.Model && !stockAlreadyExists) {
                    // let _make = dealersettings.client.brands && dealersettings.client.brands.filter(m => m.value.toLowerCase() === data.request.Make.toLowerCase())[0];
                    let _make = data.request.Make ? data.request.Make : '';
                    if (!_.isEmpty(_make)) {
                        _objStock.documentID = window.firebase.firestore().collection('stock').doc().id;
                        // _objStock.make = _make.value ? _make.value : '';
                        _objStock.make = _make ? _make : '';
                        _objStock.model = data.request.Model ? data.request.Model : '';
                        _objStock.regNo = data.request.RegNum ? data.request.RegNum : ''
                        _objStock.vinNo = data.request.VIN ? data.request.VIN : ''
                        // _objStock.saleType = data.request.veh_type && data.request.veh_type.toLowerCase() == "used" ? "Preowned" : "New";
                        // _objStock.price = data.request.price ? parseInt(data.request.price) : null;
                        _objStock.year = data.request.Year ? data.request.Year : '';
                        // if (data.request.stock_number)
                        //     _objStock.stockNo = data.request.stock_number;

                        let _model = _make.models && _make.models.filter(m => m.value.toLowerCase() === data.request.Model.toLowerCase())[0];
                        if (!_.isEmpty(_model)) {
                            _objStock.model = _model.value ? _model.value : '';
                            _objStock.type = _model.type ? _model.type : '';
                        }
                    }
                }

                if (!stockAlreadyExists) {
                    handleStockOpen(_objStock)
                } else {
                    // if stock is loaned or onTestDrive
                    if (stock.status === 'onLoan' || stock.status === 'onTestDrive' || stock.status === 'pending') {
                        const result = await Swal.fire({
                            title: `Vehicle already loaned`,
                            text: 'Do you want to return the vehicle to continue?',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        })

                        if (!result.value) {
                            setLoading(false);
                            return
                        }
                        handleReturnVehicleOpen(stock)
                    } else {
                        setStock(stock)
                        handleFleetOpen()
                    }
                }
                setLoading(false);
            }
        } catch (error) {
            errorLog(error, 'handleSaveStock');
        }
    }


    const handleSaveApply = async () => {
        setShowStockLists(true);
    }

    const handleAddStock = () => {
        setShowStock({
            show: true,
            data: null,
            id: null,
            title: 'Loan Bookings'
        });
    }

    const handleStockOpen = (data) => {
        setShowStock({
            show: true,
            data: data,
            title: 'Loan Bookings'
        });
    }

    const handleStockClose = async (stockID, objData) => {
        // if (stockID)
        setShowStockLists(false);

        if (!_.isEmpty(objData)) {
            setStock(objData)
            handleFleetOpen()
        } else {
            setLoading(false);
        }

        setShowStock({
            show: false,
            data: null,
            id: null,
            title: 'Loan Bookings'
        });
    }

    const handleLoanDetail = () => {
        setShowFleet({
            show: true,
            fleetID: data.fleetID ? data.fleetID : ''
        });
    }


    const handleFleetOpen = (data) => {
        setShowFleet({
            show: true,
        });
    }

    const handleFleetClose = (fleetID) => {
        setShowFleet({
            show: false,
        });
        if (fleetID) {
            handleUpdateLoanBooking(data.documentID, fleetID)
        }
        handleClose()
    }

    const handleReturnVehicleOpen = (data) => {
        let fleetID = ''
        if (data && data.fleetDetail && data.fleetDetail.fleetID) {
            fleetID = data.fleetDetail.fleetID
        }
        setShowReturnVehicle({
            show: true,
            id: fleetID,
            data,
        });
    }

    const handleReturnVehicleClose = async (ID) => {
        let stockID = showReturnVehicle.data.documentID
        setShowReturnVehicle({
            show: false,
            id: null,
            data: null,
        });
        if (ID) {
            // get stock object 
            const stockRef = await window.firebase.firestore().collection('stock').doc(stockID).get()
            const stockData = stockRef.data()

            setStock(stockData)
            handleFleetOpen()
            // open
        }
    }

    const imgError = (image) => {
        image.onerror = "";
        image.src = _images.nolicensebg;
        return true;
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                backdropClassName={show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-integration-lead'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className='modal-title'>
                            <Translate text={title} />
                            <div className="lead-source-badge" style={{ marginTop: '5px' }}>
                                <div className="badge badge-pill badge-white">
                                    {CommonHelper.getNameByValue(dealersettings.integrations, 'adtorqueedgeloan')}
                                </div>
                            </div>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='integration-lead-body'>
                                <div className='integration-lead-contact-details'>
                                    <h3> <Translate text={'contactDetails'} /></h3>
                                    <div className="integration-lead-box">
                                        <div className="integration-lead-contact-head">
                                            <div className="integration-lead-contact-title"> {data.contactName}</div>
                                            {/* {contact && contact.preferredName ? <span className="integration-lead-contact-preferred">({contact.preferredName})</span> : <></>} */}
                                            <div className="integration-lead-contact-sub">
                                                <ul className="integration-lead-ul">
                                                    <li> <i className="ico icon-call"></i> {data.contact && data.contact.phone ? (data.contact.phone) : '--'}</li>
                                                    <li> <i className="ico icon-email"></i> {data.contact && data.contact.email ? (data.contact.email) : '--'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="integration-lead-contact-table">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    <tr className="tr-brk">
                                                        <td width="25%"><span className="table-label"><Translate text={'licenseNo'} /></span> {data.contact && data.contact.licenseNo ? (data.contact.licenseNo) : '--'}</td>
                                                        <td width="25%"><span className="table-label"><Translate text={'licenseExpiry'} /> </span> {data.contact && data.contact.licenseExpiry ? (data.contact.licenseExpiry) : '--'}</td>
                                                        <td width="50%">&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="25%"><span className="table-label"><Translate text={'licenseFront'} /></span>
                                                            {
                                                                data.licenseFront ? (
                                                                    <img src={data.licenseFront} width={'150px'} className="img-object-fit" alt="License Front" onError={(e) => { e.target.onerror = null; e.target.src = _images.nolicense }} />
                                                                ) :
                                                                    (<img src={_images.nolicense} alt="" className="" />)
                                                            }
                                                        </td>
                                                        <td width="25%"><span className="table-label"><Translate text={'licenseBack'} /></span>
                                                            {
                                                                data.licenseBack ? (
                                                                    <img src={data.licenseBack} width={'150px'} className="img-object-fit" alt="License Back" onError={(e) => { e.target.onerror = null; e.target.src = _images.nolicense }} />
                                                                ) : (<img src={_images.nolicense} alt="" className="" />)
                                                            }
                                                        </td>
                                                        <td width="50%">&nbsp;</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <h3> <Translate text={'vehicleDetails'} /></h3>
                                    <div className="integration-lead-box">
                                        <div className="integration-lead-vehicle-head">
                                            <div className="flex-vehicle">
                                                <div className="vehicle-item-image"> <a href="#"> <img src={CommonHelper.showBrandLogo(dealersettings, data?.request?.Make)} className="img-object-fit-contain" alt="" width="70" height="70" /> </a> </div>
                                                <div className="vehicle-data">
                                                    <div className="vehicle-item-title">
                                                        {data.request && data.request.Make ? (data.request.Make) : ''} &nbsp;
                                                        {data.request && data.request.Model ? (data.request.Model) : ''}
                                                    </div>
                                                    <div className="vehicle-item-info">
                                                        {data.request && data.request.Color ? `${data.request.Color}, ` : ''}
                                                        {data.request && data.request.Year ? (data.request.Year) : ''}
                                                    </div>
                                                    <div className="vehicle-item-info">
                                                        {data.request && data.request.RegNum ? `Reg No.: ${data.request.RegNum}` : ''}
                                                    </div>
                                                    <div className="vehicle-item-info">
                                                        {data.request && data.request.VIN ? `Vin No.: ${data.request.VIN}` : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h3> <Translate text={'Other Information'} /></h3>

                                    <div className="integration-lead-box">
                                        <div className="integration-lead-contact-table">
                                            <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                <tbody>
                                                    <tr className="tr-brk">
                                                        {/* <td width="25%"><span className="table-label">Origin </span>{fields.origin ? CommonHelper.getNameByValue(_settings && _settings.origins, fields.origin, '--') : '--'}</td> */}
                                                        {/* <td width="25%"><span className="table-label">Campaign</span> {fields.campaign ? CommonHelper.getNameByValue(_settings && _settings.campaigns, fields.campaign, '--') : '--'}</td> */}
                                                        <td width="25%">
                                                            <span className="table-label"><Translate text={'Received Date & Time'} /></span>
                                                            {data.addedDate ? moment.unix(data.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                        </td>
                                                        <td width="25%"><span className="table-label"><Translate text={'Appointment Date & Time'} /></span> {data.appointmentOn ? (data.appointmentOn) : '--'}</td>
                                                        {
                                                            data.loanedOn ? (
                                                                <td width="25%"><span className="table-label"><Translate text={'Converted On'} /></span> {data.loanedOn ? (data.loanedOn) : '--'}</td>
                                                            ) : (<></>)
                                                        }
                                                    </tr>
                                                    <tr className="tr-brk">
                                                        <td colSpan="4"><span className="table-label"><Translate text={'notes'} /> </span>{data.request && data.request.Notes ? (data.request.Notes) : '--'}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    {type === 'completed' ? (
                        <button
                            type='button'
                            className='btn btn-primary float-right ml-2 '
                            aria-label='Loan Detail'
                            onClick={e => handleLoanDetail(e)}
                        >
                            {' '}
                            {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'Loan Detail'} />
                        </button>
                    ) : (

                        <button
                            type='button'
                            className='btn btn-primary float-right ml-2 '
                            aria-label='accept'
                            onClick={e => handleSubmit(e)}
                        >
                            {' '}
                            {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'accept'} />
                        </button>
                    )}
                    <button
                        type='button'
                        className='btn btn-default float-right ml-2 '
                        aria-label='Close'
                        // disabled={}
                        onClick={handleClose}
                    >
                        {' '}
                        <Translate text={'close'} />
                    </button>
                </Modal.Footer>
            </Modal>
            <PopUpModal show={showContactInfo}>
                <ContactInfo
                    show={showContactInfo}
                    existingContact={existingContactInfo}
                    newContact={contact ? contact : {}}
                    isNewContact={isContactMerged ? false : true}
                    handleClose={handleXContactClose}
                    title={'Contact Details'}
                    dealersettings={dealersettings}
                    clientUsers={!_.isEmpty(groupUsers) ? groupUsers : clientUsers}
                >
                </ContactInfo>
            </PopUpModal>

            <PopUpModal show={showStockLists}>
                <Stocks
                    show={showStockLists}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                    handleClose={handleStockClose}
                    handleAdd={handleAddStock}
                    showAdd={true}
                    displayPrice={true}
                    availableType={stockAvail.LOAN}
                    alrtMsg='Do you want this selected vehicle for loan.'
                //fromRequirement={true}
                />
            </PopUpModal>

            <PopUpModal show={showStock.show}>
                <AddStock
                    docID={showStock.id}
                    show={showStock.show}
                    clsActive='overlay-modal active'
                    handleClose={handleStockClose}
                    title={'stock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={true}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                //prefillData={showStock.data}
                >
                </AddStock>
            </PopUpModal>

            <PopUpModal show={showFleet.show}>
                <AddFleet
                    docID={showFleet.fleetID}
                    show={showFleet.show}
                    clsActive='overlay-modal active'
                    stock={_stock}
                    handleClose={handleFleetClose}
                    title={'fleet'}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                    contact={contact}
                    isFromLoanBookings={true}
                >
                </AddFleet>
            </PopUpModal>

            <PopUpModal show={showReturnVehicle.show}>
                <AddFleet
                    docID={showReturnVehicle.id}
                    show={showReturnVehicle.show}
                    clsActive='overlay-modal active'
                    stock={showReturnVehicle.data}
                    handleClose={handleReturnVehicleClose}
                    title={'fleet'}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                    groupUsers={groupUsers}
                >
                </AddFleet>
            </PopUpModal>
        </>
    );
};

export default LoanBookingsModal;
