/** LIBRARIES */
import React, { Component } from 'react';
import { Spinner, Modal } from 'react-bootstrap'
import Fingerprint2 from "fingerprintjs2";
import moment from 'moment';
import _ from 'lodash';
/** COMPONENTS */
import { InputOTP, LoaderOverlay, PopUpModal } from '../../components'
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import Swal from 'sweetalert2';
import { messaging } from '../../init-fcm';

import "../../styles/login.scss";
import "../../styles/login-carexpert.scss";

import {
    PhoneMultiFactorGenerator,
    PhoneAuthProvider,
    multiFactor,
    TotpMultiFactorGenerator,
    TotpSecret,
    getAuth,
    getMultiFactorResolver,
} from "firebase/auth";
import TwoFactorAuthModel from '../settings/profile/general-settings/twoFactorAuthModel';

class LoginPage extends Component {
    constructor(props) {
        super(props);


        this.state = {
            fields: {},
            errors: {},
            loading: false,
            showresetpassword: false,
            resetpasswordloading: false,
            passwordlinksent: false,
            emailaddress: '',
            pageLoader: false,
            devicefcmToken: '',
            showVerifyMFA: false,
            phoneNumber: '',
            mfaCode: '',
            verificationId: '',
            mfaverifyLoader: false,
            resolver: null,
            showMFAEnableModel: { show: false },
            showVerifyTOTP: false,
            totpCode: '',
            totpverifyLoader: false,
            totperror: null,
            enforceMFA: 'false'
        };
        this.designCarExpert = false
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResetPasswordSubmit = this.handleResetPasswordSubmit.bind(this);
        this.handleMFACodeSubmit = this.handleMFACodeSubmit.bind(this);
        this.handleTOTPCodeSubmit = this.handleTOTPCodeSubmit.bind(this);
    }

    handleChange(field, e) {
        this.setState({ errors: {} });
        let fields = Object.assign({}, this.state.fields);
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    handleCodeChange(field, e) {
        this.setState({ errors: {} });
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleOTPChange(value, name) {
        this.setState({ errors: {} });
        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let fields = Object.assign({}, this.state.fields);
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["username"]) {
            formIsValid = false;
            errors["username"] = "error";
        }

        //Name
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "error";
        }

        if (typeof fields["username"] !== "undefined") {
            let lastAtPos = fields["username"].lastIndexOf('@');
            let lastDotPos = fields["username"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["username"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["username"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["message"] = "Email is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleResetPasswordValidation() {
        let fields = Object.assign({}, this.state.fields);
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["emailaddress"]) {
            formIsValid = false;
            errors["popvalidation"] = "error-outline";
        }


        if (fields["emailaddress"] && typeof fields["emailaddress"] !== "undefined") {
            let lastAtPos = fields["emailaddress"].lastIndexOf('@');
            let lastDotPos = fields["emailaddress"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["emailaddress"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["emailaddress"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["popmessage"] = "Email is not valid";
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        let errors = {};
        if (this.handleValidation()) {
            this.setState({ loading: true })

            window.firebase.auth().signInWithEmailAndPassword(this.state.fields["username"], this.state.fields["password"])
                .then(async () => {
                    const currentUser = window.firebase.auth().currentUser;
                    if (currentUser) {
                        // if (_.isEmpty(window.firebase2.auth().currentUser)) {
                        //     window.firebase2.auth().signInAnonymously()
                        //         .catch((error) => {
                        //             console.log('error on secondary firestore auth Anonymously', error)
                        //         });
                        // }

                        // const uid = currentUser.uid;
                        // const userData = { lastLoginTime: moment()._d };
                        // //this.getdealerSettings(uid);
                        // this.getBrowserkey(uid)
                        // this.deleteDuplicateTokens(uid);
                        // localStorage.setItem('uid', uid);
                        // localStorage.setItem('isCurrentlyLogin', true);
                        // localStorage.setItem('userName', (currentUser.displayName ? currentUser.displayName : currentUser.email));
                        // window.firebase.firestore().doc(`/users/${uid}`).set(userData, { merge: true });
                        // window.firebase.auth().currentUser.getIdTokenResult().then(async (data) => {
                        //     await window.firebase.firestore().doc(`users/${uid}`).set({
                        //         superAdmin: data.claims.superadmin ? data.claims.superadmin : false
                        //     }, { merge: true });
                        //     if (data.claims.superadmin) {
                        //         this.props.history.push('/admin/dealers');
                        //     }
                        //     else {
                        //         let search = window.location.search;
                        //         let params = new URLSearchParams(search);
                        //         //let continueURL = params.get('continue');
                        //         // if (continueURL) {
                        //         //     this.props.history.push(continueURL)
                        //         // } else {
                        //         this.props.history.push('/')
                        //         // }
                        //     }
                        // });

                        // Generate a TOTP secret.
                        //const multiFactorUser = await multiFactor(currentUser);
                        if (this.state.enforceMFA === 'true') {
                            this.setState({
                                showMFAEnableModel: {
                                    show: true,
                                    title: 'Enable Multi-factor authentication',
                                    currentUser
                                }
                            })
                        }
                    }
                    //this.setState({ loading: false })
                })
                .catch(async error => {
                    //console.log(error); 

                    if (error.code === 'auth/multi-factor-auth-required') {
                        const mfaResolver = getMultiFactorResolver(getAuth(window.firebase.app()), error);
                        if (_.find(mfaResolver.hints, { factorId: 'phone' })) {
                            let recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier('2fa-captcha', {
                                size: 'visible',
                                callback: function (response) {
                                    if (document.getElementById('2fa-captcha')) {
                                        document.getElementById('2fa-captcha').innerHTML = '';
                                    }
                                    console.log('captcha solved!');
                                },
                            });
                            // The user is enrolled in MFA, must be verified
                            let resolver = error.resolver;
                            this.setState({
                                phoneNumber: resolver.hints[0].phoneNumber
                            })
                            var phoneInfoOptions = {
                                multiFactorHint: resolver.hints[0],
                                session: resolver.session
                            };
                            var phoneAuthProvider = new window.firebase.auth.PhoneAuthProvider();
                            // Send SMS verification code
                            try {
                                let verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                                this.setState({
                                    loading: false,
                                    verificationId,
                                    showVerifyMFA: true,
                                    resolver
                                });
                            }
                            catch (err) {
                                console.log(err);
                                Swal.fire(`${err.code}-${err.message}`, '', 'error');
                                this.setState({
                                    loading: false
                                });
                            }
                        } else {
                            this.setState({
                                showVerifyTOTP: true,
                                totperror: error
                            });
                        }
                        // return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                        //     .then(function (verificationId) {
                        //         //SMS sent to registered mobile number
                        //         this.setState({
                        //             loading: false,
                        //             verificationId,
                        //             showVerifyMFA: true
                        //         });
                        //     })
                        //     .then(function (userCredential) {
                        //         console.log(userCredential)
                        //     });
                    }
                    else {
                        errors["message"] = error.message
                        this.setState({
                            loading: false,
                            errors
                        })

                    }

                });
        }
    }

    // Setup a global captcha
    getBrowserkey(uid) {
        var self = this;
        if (window.requestIdleCallback) {
            requestIdleCallback(async function () {
                await Fingerprint2.get(function (components) {
                    var values = components.map(function (component) { return component.value })
                    var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                    // console.log(murmur)
                    localStorage.setItem('browserKey', murmur);
                    // console.log('2A... this is browserKey and uid', murmur, '\t' , uid)
                    self.saveDeviceInfo(murmur, uid)
                    // if (!window.firebase.messaging.isSupported()) {
                    //     localStorage.setItem('fcmtoken', 'safari-' + murmur);
                    //     this.setState({ devicefcmToken: 'safari-' + murmur })
                    // }
                })
            })
        } else {
            setTimeout(async function () {
                await Fingerprint2.get(function (result, components) {
                    var values = result.map(function (result) { return result.value })
                    var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                    // console.log(murmur)
                    localStorage.setItem('browserKey', murmur);
                    // console.log('2B... this is browserKey and uid', murmur, '\t' , uid)
                    self.saveDeviceInfo(murmur, uid)
                    // if (!window.firebase.messaging.isSupported()) {
                    //     localStorage.setItem('fcmtoken', 'safari-' + murmur);
                    //     this.setState({ devicefcmToken: 'safari-' + murmur })
                    // }
                })
            }, 500)
        }
    }

    async saveDeviceInfo(id, uid) {
        try {
            // console.log('3... saveDeviceInfo ran', id, uid)

            const doc = await window.firebase.firestore().doc(`users/${uid}`).get()
            const userData = doc.data()
            const logRef = window.firebase.firestore().collection('auditLogs')
            const auditLog = {
                clientID: userData.defaultClientID ? userData.defaultClientID : null,
                documentID: logRef.doc().id,
                groupID: userData.groupID ? userData.groupID : null,
                imageURL: null,
                logBy: uid,
                logDate: window.firebase.firestore.Timestamp.now(),
                notes: null,
                oemID: userData.settingsID ? userData.settingsID : null,
                platform: 'web',
                recordID: id,
                regionID: userData.regionID ? userData.regionID : null,
                subtype: 'login',
                type: 'authentication',
            }
            let webdeviceInfo = {
                deviceID: id,
                deviceInfo: {},
                addedDate: window.firebase.firestore.Timestamp.now(),
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                name: this.checkBrowser(),
                platform: this.checkPlatform(),
                token: this.state.devicefcmToken,
                isLogin: true,
                forceLogout: false,
                userID: uid,
                loginDate: window.firebase.firestore.Timestamp.now(),
                clientID: userData.defaultClientID ? userData.defaultClientID : null,
                oemID: userData.settingsID ? userData.settingsID : null,
                ...this.checkVersion()
            }

            // const response = await fetch(`https://api.ipify.org/?format=json`);
            // const respjson = await response.json();
            // webdeviceInfo.deviceInfo['query'] = respjson.ip
            // use the ip to fetch request and save info
            const ipstackResp = await fetch(`https://api.ipstack.com/check?access_key=bd0542ba9b4743c0df5f8cf5920661cd`);
            const ipstackRespJSON = await ipstackResp.json();
            webdeviceInfo.geoLocation = ipstackRespJSON

            // console.log('4... webdeviceInfo', webdeviceInfo)
            // console.log('5... saveDeviceInfo ranauditLog', auditLog)

            await window.firebase.firestore().doc(`/users/${localStorage.uid}/devices/${id}`).set(webdeviceInfo, { merge: true });
            await logRef.doc(auditLog.documentID).set(auditLog, { merge: true })
        } catch (error) {
            console.log(error)
        }
    }

    checkBrowser() {
        // Chrome 1 - 79
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        // Opera 8.0+
        var isOpera = window.navigator.userAgent.indexOf(' OPR/') >= 0;

        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== 'undefined';

        // Detect Safari 
        let isSafari = window.navigator.userAgent.indexOf("Safari") > -1;

        // Discard Safari since it also matches Chrome 
        if ((isChrome) && (isSafari))
            isSafari = false;

        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia;



        // Edge (based on chromium) detection
        var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);

        // Blink engine detection
        var isBlink = (isChrome || isOpera) && !!window.CSS;


        var browserName = 'Unknown Browser'
        if (isOpera) { browserName = 'Opera'; }
        else if (isFirefox) { browserName = 'Firefox'; }
        else if (isSafari) { browserName = 'Safari'; }
        else if (isIE) { browserName = 'Internet Explorer'; }
        else if (isEdge) { browserName = 'Edge'; }
        else if (isChrome) { browserName = 'Chrome'; }
        else if (isEdgeChromium) { browserName = 'Edge Chromium'; }
        else if (isBlink) { browserName = 'Blink'; }

        return browserName;
    }

    checkPlatform() {
        var OSName = "Unknown OS";
        if (window.navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
        else if (window.navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
        else if (window.navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
        else if (window.navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux"

        return OSName;
    }

    checkVersion() {
        try {
            var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return { name: 'IE', version: (tem[1] || '') };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\bOPR|Edge\/(\d+)/)
                if (tem != null) { return { name: 'Opera', version: tem[1] }; }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
            return {
                name: M[0],
                version: M[1]
            };
        }
        catch (error) {
            return {
                version: null
            };
        }
    }

    componentDidMount() {
        // if (window.location.host === 'ams-pro-qa.web.app') {
        //     window.location.href = 'https://qa.fusionamspro.com/';
        // }
        // else if (window.location.host === 'ams-pro-live.web.app') {
        //     window.location.href = 'https://app.fusionamspro.com/';
        // }

        const urlParams = new URLSearchParams(window.location.search);
        const page = urlParams.get('theme');

        if (page === 'carExpert') {
            this.designCarExpert = true
            document.getElementsByTagName('body')[0].className = 'login-carexpert-landing';
        } else {
            this.designCarExpert = false
            document.getElementsByTagName('body')[0].className = 'login-page-landing';
        }

        this._isMounted = true;
        document.documentElement.style.setProperty('--loaderHeight', window.innerHeight + 'px');

        const remoteConfig = window.firebase.remoteConfig();
        remoteConfig.fetchAndActivate()
            .then(() => {
                this.setState({ enforceMFA: remoteConfig.getString('enforceMFA') || 'false' })
            })

        window.firebase.auth().onAuthStateChanged(currentuser => {
            // if (window.location.host === 'ams-pro-qa.web.app') {
            //     window.location.href = 'https://qa.fusionamspro.com/';
            // }
            // else if (window.location.host === 'ams-pro-live.web.app') {
            //     window.location.href = 'https://app.fusionamspro.com/';
            // }
            if (currentuser) {
                if (currentuser?.multiFactor?.enrolledFactors?.length || this.state.enforceMFA === 'false') {
                    localStorage.setItem('uid', currentuser.uid);
                    localStorage.removeItem('objNotify');
                    window.firebase.auth().currentUser.getIdTokenResult().then((data) => {
                        if (data.claims.superadmin) {
                            this.props.history.push('/admin/dealers');
                        }
                        else {
                            this.props.history.push('/')
                        }
                    });
                }
            }
            else {
                this._isMounted && this.setState({ pageLoader: false })
                this._isMounted && this.fnmessaging()
                window.Intercom('update', {
                    "hide_default_launcher": true
                })
            }
        })


    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].className = '';
        document.documentElement.style.setProperty('--loaderHeight', '100%');
        this._isMounted = false;
        window.Intercom('update', {
            "hide_default_launcher": false
        })
    }

    handleModalClose = () => {
        this.setState({
            showresetpassword: false,
            showVerifyMFA: false
        })
    }

    handleResetPasswordSubmit(e) {

        e.preventDefault();
        let errors = {};
        if (this.handleResetPasswordValidation()) {
            this.setState({ resetpasswordloading: true })
            try {
                const sendResetPasswordLink = window.firebase.functions().httpsCallable('amsadmin-sendResetPasswordLink');
                sendResetPasswordLink({
                    "email": this.state.fields["emailaddress"]
                }).then((data) => {
                    //console.log(data) 
                    if (data.data.success === true) {
                        this.setState({
                            emailaddress: this.state.fields["emailaddress"],
                            resetpasswordloading: false,
                            passwordlinksent: true,
                            errors
                        })
                    }
                    else {
                        errors["popmessage"] = data.data.message
                        this.setState({
                            resetpasswordloading: false,
                            errors
                        })
                    }
                });
            }
            catch (error) {
                errors["popmessage"] = 'Something went wrong. Please contact support.'
                this.setState({
                    resetpasswordloading: false,
                    errors
                })
            }
            // window.firebase.auth().sendPasswordResetEmail(this.state.fields["emailaddress"])
            //     .then((doc) => {
            //         this.setState({
            //             emailaddress: this.state.fields["emailaddress"],
            //             resetpasswordloading: false,
            //             passwordlinksent: true,
            //             errors
            //         })
            //     })
            //     .catch(error => {
            //         //console.log(error);
            //         errors["popmessage"] = error.message
            //         this.setState({
            //             resetpasswordloading: false,
            //             errors
            //         })

            //     });
        }
    }

    handleMFACodeSubmit(e) {
        e.preventDefault();
        let errors = {};
        const { verificationId, mfaCode, resolver } = this.state;
        let formIsValid = true;
        if (!this.state.mfaCode) {
            formIsValid = false;
            errors["mfavalidation"] = "error-outline";
        }

        this.setState({ errors: errors });

        if (formIsValid) {
            this.setState({ mfaverifyLoader: true });
            // const auth = getAuth(window.firebase.app());
            // const phoneAuthProvider = new PhoneAuthProvider(auth);
            var cred = PhoneAuthProvider.credential(
                verificationId, mfaCode);
            var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
            // Complete sign-in.
            resolver.resolveSignIn(multiFactorAssertion)
                .then(currentUser => {
                    // console.log('0... this is user', currentUser)
                    this.setState({ mfaverifyLoader: false });
                    const uid = currentUser.user.uid;
                    const userData = { lastLoginTime: moment()._d };
                    localStorage.setItem('uid', uid);
                    localStorage.setItem('isCurrentlyLogin', true);
                    localStorage.setItem('userName', (currentUser.user.name ? currentUser.user.name : currentUser.user.email));
                    window.firebase.firestore().doc(`/users/${uid}`).set(userData, { merge: true });
                    // console.log('1... this is user uid', uid)
                    this.getBrowserkey(uid)
                    this.deleteDuplicateTokens(uid);
                })
                .catch(err => {
                    errors["mfamessage"] = err.message
                    this.setState({
                        mfaverifyLoader: false,
                        errors
                    })
                });


        }
    }
    async handleTOTPCodeSubmit(e) {
        if (e) e.preventDefault();
        let errors = {};
        const { totpCode, totperror } = this.state;
        let formIsValid = true;
        if (!this.state.totpCode) {
            formIsValid = false;
            errors["totpvalidation"] = "error-outline";
        }

        this.setState({ errors: errors });

        if (formIsValid) {

            const mfaResolver = getMultiFactorResolver(getAuth(window.firebase.app()), totperror);
            const multiFactorAssertion =
                TotpMultiFactorGenerator.assertionForSignIn(
                    mfaResolver.hints[0].uid,
                    totpCode
                );
            try {
                this.setState({ totpverifyLoader: true });
                const userCredential = await mfaResolver.resolveSignIn(
                    multiFactorAssertion
                );
                this.setState({ totpverifyLoader: false });
                const uid = userCredential.user.uid;
                const userData = { lastLoginTime: moment()._d };
                localStorage.setItem('uid', uid);
                localStorage.setItem('isCurrentlyLogin', true);
                localStorage.setItem('userName', (userCredential.user.displayName || userCredential.user.email));
                window.firebase.firestore().doc(`/users/${uid}`).set(userData, { merge: true });
                // console.log('1... this is user uid', uid)
                this.getBrowserkey(uid)
                this.deleteDuplicateTokens(uid);
                // Successfully signed in!
            } catch (err) {
                errors["totpmessage"] = err.message
                if (err.code === 'auth/network-request-failed') {
                    errors["totpmessage"] = 'Code is incorrect/expired.'
                }
                this.setState({
                    totpverifyLoader: false,
                    errors
                })
                // Invalid or expired OTP.
            }

        }
    }
    deleteDuplicateTokens = (uid) => {
        const { devicefcmToken } = this.state
        if (_.isEmpty(devicefcmToken))
            return
        try {
            window.firebase.firestore().collection(`users`)
                .where('tokkens', 'array-contains', devicefcmToken)
                .get()
                .then(docs => {
                    if (docs.docs.length > 0) {
                        docs.docs.forEach(doc => {
                            var tokkens = doc.data().tokkens;
                            if (tokkens.some(e => e === devicefcmToken) && uid !== doc.id) {
                                window.firebase.firestore().doc(`/users/${doc.id}`)
                                    .set({
                                        tokkens: [
                                            ...tokkens.filter(
                                                e => e !== devicefcmToken,
                                            ),
                                        ],
                                    },
                                        { merge: true },
                                    );
                            }
                        })
                    }
                })
        }
        catch (error) {
            console.log(error)
        }
    }

    fnmessaging = () => {
        if (!messaging) {
            return
        }
        if (_.isEmpty(localStorage.fcmtoken)) {
            try {
                Notification.requestPermission().then(async (permission) => {
                    if (permission === 'granted') {
                        await messaging
                            .getToken()
                            .then(currentToken => {
                                //console.log(currentToken);
                                localStorage.setItem('fcmtoken', currentToken);
                                this.setState({ devicefcmToken: currentToken })
                            })
                            .catch(err => {
                                console.log('Unable to get permission to notify.', err);
                            });
                    } else {
                        console.log('Unable to get permission to notify.');
                    }
                }).catch(err => {
                    console.log('Unable to get permission to notify.', err);
                });
            } catch (err) {
                console.log('Unable to get permission to notify.');
            }
        }
        else {
            this.setState({ devicefcmToken: localStorage.fcmtoken })
        }


        // messaging.onTokenRefresh(fcmToken => {
        //     localStorage.setItem('fcmtoken', fcmToken);
        //     this.setState({ devicefcmToken: fcmToken })
        // });
    };


    render() {
        const { loading, showresetpassword, resetpasswordloading, passwordlinksent, emailaddress, pageLoader, showVerifyMFA, phoneNumber, mfaverifyLoader, showMFAEnableModel, showVerifyTOTP, totpverifyLoader } = this.state
        let _provider = null;
        if (window.location.hostname === "ams-pro-live.web.app" || window.location.hostname === "app.fusionamspro.com") {
            _provider = 'saml.simedarby-fusionsd-amspro';
            //simedarby-fusionsd-amspro
        } else {
            _provider = 'saml.jumpcloud';
        }
        return pageLoader ? (<LoaderOverlay text={'loading...'} active={true} />) : (
            <>
                <div className="login-panel">
                    <div className="login-panel-container">
                        <div className="login-panel-inner">
                            <div className="login-aside">
                                <div className="login-aside-content">
                                    <div className="float-left">
                                        <a className="login-logo" href="#" onClick={(e) => { e.preventDefault(); }}>
                                            <img alt="Logo" className="max-60px" src={_images.logoPro} />
                                        </a>
                                        <div className="float-right">
                                            {/* <div className="nav-right"><Translate text={'Not an AMS Pro user?'} />
                                                <button type="button" className="btn btn-success ml-3"><Translate text={'Try it free'} /></button>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className={this.designCarExpert ? 'login-aside-welcome column-aside-center' : 'login-aside-welcome'}>
                                        <div className={this.designCarExpert ? "login-flex-column column-flex-center" : 'login-flex-column'}>
                                            {!this.designCarExpert && (
                                                <div className="login-mid-column">
                                                    <div className="login-mid-text-column">
                                                        <h3>Made by Car People,
                                                            <br></br>
                                                            For Car People.</h3>

                                                        <p>A vision to enhance the customer experience of buying cars. With AMS, we will change the way it is done.</p>
                                                        <div className="login-graphics"><img src={_images.loginGraphics} alt="" /></div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="login-small-column">
                                                <div className="card bg-secondary shadow border-0 login-box">

                                                    <div className="card-body px-lg-5 py-lg-4">
                                                        <div className="text-center text-muted mb-4 login-head">
                                                            {this.designCarExpert && (
                                                                <div className="text-center mt-4 mb-4">
                                                                    <img
                                                                        src={_images.carExpertLogo}
                                                                        alt=""
                                                                        width="200"
                                                                    />
                                                                </div>
                                                            )}
                                                            <h2><Translate text={'Login'} /></h2>
                                                            {!this.designCarExpert && (
                                                                <h4><Translate text={'Please log in to continue.'} /></h4>
                                                            )}
                                                        </div>
                                                        <form name="form" onSubmit={this.handleSubmit}>
                                                            <div className="form-group mb-3">
                                                                <div className={`input-group input-group-alternative ${this.state.errors["username"]}`}>
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><i className="ico icon-login-user"></i> </span>
                                                                    </div>
                                                                    <input
                                                                        className="form-control"
                                                                        name="username"
                                                                        placeholder="user email"
                                                                        value={this.state.fields["username"] ? this.state.fields["username"] : ''}
                                                                        autoComplete="off"
                                                                        onChange={this.handleChange.bind(this, "username")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className={`input-group input-group-alternative ${this.state.errors["password"]}`}>
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><i className="ico icon-login-password"></i></span>
                                                                    </div>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        name="password"
                                                                        onChange={this.handleChange.bind(this, "password")}
                                                                        value={this.state.fields["password"] ? this.state.fields["password"] : ''}
                                                                        placeholder="Password"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="error-wrap">
                                                                {this.state.errors["message"]}
                                                            </div>
                                                            <div id="2fa-captcha"></div>

                                                            <div className="text-center">
                                                                <button className={this.designCarExpert ? 'btn btn-carexpert-red mt-2' : "btn btn-primary mt-2"} >
                                                                    {
                                                                        loading
                                                                            ?
                                                                            <Spinner
                                                                                as="span"
                                                                                animation="border"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            />
                                                                            :
                                                                            <><Translate text={'Login'} /></>
                                                                    }
                                                                </button>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-12">
                                                                    <div className="forgot-password">
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState({
                                                                                errors: {
                                                                                    ...this.state.errors,
                                                                                    ["popvalidation"]: '',
                                                                                    ["popmessage"]: ''
                                                                                },
                                                                                fields: {
                                                                                    ...this.state.fields,
                                                                                    ["emailaddress"]: ''
                                                                                },
                                                                                showresetpassword: true,
                                                                                passwordlinksent: false,
                                                                                emailaddress: ''
                                                                            })
                                                                        }}><Translate text={'Forgot password?'} /></a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                                        {
                                                            _provider ? (
                                                                <>
                                                                    <div className="login-section-divider">or</div>
                                                                    <div className="login-access-buttonwrap">
                                                                        <button type="button" className="btn btn-silver mt-2" onClick={() => {
                                                                            if (_provider) {
                                                                                var provider = new window.firebase.auth.SAMLAuthProvider(_provider);
                                                                                // provider.setCustomParameters({
                                                                                //     login_hint: 'thaha@fusionsd.com.au'
                                                                                // })
                                                                                window.firebase.auth().signInWithPopup(provider)
                                                                                    .then((result) => {
                                                                                        //console.log('logged in', result)
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.log(error);
                                                                                        if (error.code === 'auth/admin-restricted-operation') {
                                                                                            Swal.fire(`User not found in AMS Pro. Please contact administrator.`, '', 'error');
                                                                                        }
                                                                                    });
                                                                            }
                                                                            else {
                                                                                Swal.fire(`SAML Provider not defined. Please contact administrator.`, '', 'error');
                                                                            }

                                                                        }}> <i className="ico icon-activity-key"></i> <span>Login with</span> SSO</button>
                                                                    </div>
                                                                </>
                                                            ) : (<></>)
                                                        }

                                                    </div>

                                                    {/* <div className="card-footer sign-up-link">
                                                        <a href="#" onClick={(e) => { e.preventDefault(); }}> <Translate text={'Dont have an account?'} />&nbsp;<span><Translate text={'Sign up'} /></span></a>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="login-aside-footer">
                                        <div className="login-aside-copyright login-aside-link">
                                            <a href="http://fusionsd.com.au/" target="_blank">© Copyright FusionSD {moment()._d.getFullYear()}. All rights reserved.</a>
                                        </div>
                                        <div className="login-aside-link">
                                            <a href="https://www.fusionamspro.com/legal.html" target="_blank">Legal</a>
                                            {/* <a href="https://www.fusionamspro.com/privacy-policy.html" target="_blank">Privacy Policy</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <Modal
                    show={showresetpassword}
                    onHide={this.handleModalClose}
                    backdrop="static"
                    backdropClassName={showresetpassword ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-reset-password">
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    passwordlinksent ? (
                                        <div className="reset-password-success">
                                            <div className="text-center sent-mail-icon"> <img src={_images.mailsent} alt="" /></div>
                                            <h1 className="text-center"><Translate text={'Check your inbox'} /></h1>
                                            <p className="text-center m-b mt-3 mb-3 reset-info"> <Translate text={'Email sent to'} />&nbsp;<b style={{ color: '#2a3042' }}>{emailaddress}</b>. <Translate text={'Follow the underlying instructions to reset your password.'} /></p>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-secondary mt-2" onClick={this.handleModalClose}> <Translate text={'Back to Login'} /></button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="reset-password-wrap">
                                            <h1 className="text-center"><Translate text={'Forgot Password?'} /></h1>
                                            <p className="text-center m-b mt-3 reset-info">We'll email you instructions on how to reset your password.</p>
                                            <div className={`alert alert-danger ${this.state.errors["popmessage"] ? '' : 'hide'}`} role="alert" >
                                                <span>{this.state.errors["popmessage"]}</span>
                                            </div>
                                            <form onSubmit={this.handleResetPasswordSubmit}>
                                                <div className="form-group m-b-lg">

                                                    <input
                                                        className={`form-control ${this.state.errors["popvalidation"]}`}
                                                        name="emailaddress"
                                                        placeholder="Email"
                                                        value={this.state.fields["emailaddress"] ? this.state.fields["emailaddress"] : ''}
                                                        autoComplete="off"
                                                        onChange={this.handleChange.bind(this, "emailaddress")}
                                                    />
                                                </div>
                                                <button type="submit" className={this.designCarExpert ? "btn btn-carexpert-red pull-left mt-2" : "btn btn-primary pull-left mt-2"} >
                                                    {
                                                        resetpasswordloading
                                                            ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            :
                                                            <><Translate text={'Reset My Password'} /></>
                                                    }
                                                </button>
                                            </form>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showVerifyMFA}
                    backdrop="static"
                    backdropClassName={showVerifyMFA ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-reset-password">
                    <Modal.Header>

                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="reset-password-wrap">
                                    <div className="text-center sent-mail-icon"> <img src={_images.mfa} alt="" /></div>
                                    <h1 className="text-center"><Translate text={'Multi-factor authentication'} /></h1>
                                    <p className="text-center m-b mt-3 mb-3 reset-info">We have just sent you a SMS to your phone number: <b style={{ color: '#2a3042' }}>{phoneNumber}</b>. Please enter it below.</p>
                                    <div className={`alert alert-danger ${this.state.errors["mfamessage"] ? '' : 'hide'}`} role="alert" >
                                        <span>{this.state.errors["mfamessage"]}</span>
                                    </div>
                                    <form onSubmit={this.handleMFACodeSubmit}>
                                        <div className="form-group m-b-lg">

                                            <input
                                                className={`form-control ${this.state.errors["mfavalidation"]}`}
                                                name="mfaCode"
                                                placeholder="enter code"
                                                value={this.state.mfaCode ? this.state.mfaCode : ''}
                                                autoComplete="off"
                                                onChange={this.handleCodeChange.bind(this, "mfaCode")}
                                            />
                                        </div>
                                        <button type="submit" className={this.designCarExpert ? "btn btn-carexpert-red pull-left mt-2" : "btn btn-primary pull-left mt-2"}>
                                            {
                                                mfaverifyLoader
                                                    ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    :
                                                    <><Translate text={'Verify'} /></>
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showVerifyTOTP}
                    backdrop="static"
                    backdropClassName={showVerifyTOTP ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-reset-password">
                    <Modal.Header>

                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="reset-password-wrap">
                                    <div className="text-center sent-mail-icon"> <img src={_images.mfa} alt="" /></div>
                                    <h1 className="text-center"><Translate text={'Multi-factor authentication'} /></h1>
                                    <p className="text-center m-b mt-3 mb-3 reset-info">Open the Authenticator app on your mobile device and enter authentication code.</p>
                                    <div className={`alert alert-danger ${this.state.errors["totpmessage"] ? '' : 'hide'}`} role="alert" >
                                        <span>{this.state.errors["totpmessage"]}</span>
                                    </div>
                                    <form onSubmit={this.handleTOTPCodeSubmit}>
                                        <div className="form-group m-b-lg">

                                            {/* <input
                                                className={`form-control ${this.state.errors["totpvalidation"]}`}
                                                name="totpCode"
                                                placeholder="enter code"
                                                value={this.state.totpCode ? this.state.totpCode : ''}
                                                autoComplete="off"
                                                onChange={this.handleCodeChange.bind(this, "totpCode")}
                                            /> */}

                                            <InputOTP
                                                length={6}
                                                autoFocus={true}
                                                classRender={`otp-input-Center`}
                                                className={`${this.state.errors["totpvalidation"]}`}
                                                onChange={(value) => { this.handleOTPChange(value, "totpCode") }}
                                                onEnter={() => { this.handleTOTPCodeSubmit() }}
                                            />

                                        </div>
                                        <button type="submit" className={this.designCarExpert ? "btn btn-carexpert-red pull-left mt-2" : "btn btn-primary pull-left mt-2"}>
                                            {
                                                totpverifyLoader
                                                    ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    :
                                                    <><Translate text={'Verify'} /></>
                                            }
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <PopUpModal show={showMFAEnableModel.show}>
                    <TwoFactorAuthModel
                        {...showMFAEnableModel}
                        handleClose={() => {
                            this.setState({
                                showMFAEnableModel: {
                                    show: false,
                                },
                                loading: false
                            })
                        }}
                    >
                    </TwoFactorAuthModel>
                </PopUpModal>

            </>
        );
    }
}
export default LoginPage;