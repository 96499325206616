export const inboundleadCountVM = {
    monthActivity: [],
    monthDelivered: [],
    monthLost: [],
    totalPending: [],
    monthPending: [],
    monthConverted: [],
    monthWon: [],
    monthShow: [],
    monthNoshow: [],
    overdueActivity: [],


    todayActivity: [],
    todayDelivered: [],
    todayLost: [],
    todayWon: [],
    todayConverted: [],
    todayShow: [],
    todayNoshow: [],
    todayPending: []
}

export const leadBucketCountVM = {
    pendingInboundleads: [],
    todayConverted: [],
    monthConverted: [],
    lostApprovalList: []
}

export const bdcStages = [
    {
        name: "Allocated",
        value: "allocated",
        color: "#4967cc"
    },
    {
        name: "Show",
        value: "bdcshow",
        color: "#34c68d"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    }
]

export const bdcStatus = [
    {
        name: "Allocated",
        value: "allocated",
        color: "#4967cc"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    },
    {
        name: "Delivered",
        value: "delivered",
        color: "#049b8c"
    }
]

export const bdcShowNoShow = [
    {
        name: "Show",
        value: "bdcshow",
        color: "#34c68d"
    },
    {
        name: "No Show",
        value: "bdcnoshow",
        color: "#f97863"
    }
]


export const pipelineLabels = [
    {
        name: "Hot",
        value: "hot",
        color: "#ed1c24"
    },
    {
        name: "Warm",
        value: "warm",
        color: "#f7931e"
    },
    {
        name: "Cold",
        value: "cold",
        color: "#29abe2"
    },
]

export const initialLayoutArr = [ 
    { x: 0, y: 0, w: 4, h: 4, i: 'LeadsBucket', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByShow', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, }
]


export const widgetSettingsNames = [
    { name: 'Leads Bucket', value: 'LeadsBucket' },
    { name: 'Activities', value: 'Activities' },
    { name: 'Sales Funnel', value: 'SalesFunnel' },
    { name: 'Leads By Status', value: 'LeadsByStatus' },
    { name: 'Leads By Show / No Show', value: 'LeadsByShow' },
]

export const defaultWidgetSettings = [
    'LeadsBucket',
    'Activities',
    'SalesFunnel',
    'LeadsByStatus',
    'LeadsByShow',
]
