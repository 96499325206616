import React, { useState, useEffect, useMemo } from 'react';

import { default as _images } from '../../../images';
import ActivityTag from './activityTag'
import Translate from '../../../constants/translate';
import { ContentOverlay } from '../../../components'
import CommonHelper from '../../../services/common'
import _ from 'lodash'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';


const EnquiryActivity = (props) => {
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [stockStatuses, setstockStatuses] = useState([])
    const [activitylogs, setTempData] = useState([])
    useEffect(() => {
        const { dealersettings } = props;

        if (dealersettings && dealersettings.client) {
            let _setttings = dealersettings.client.settings;

            const _stockStatuses = [];
            _setttings.stockStatus && _setttings.stockStatus.forEach(doc => {
                _stockStatuses.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color || '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });


            setstockStatuses(_stockStatuses);

        }

    }, [props.dealersettings])



    useEffect(() => {
        if (_.isEmpty(props.stockID))
            return

        let changeLogData = window.firebase.firestore().collection('changeLogs')
        changeLogData = changeLogData.where('recordId', '==', props.stockID)

        var activitiesStockCollection = combineLatest(
            collection(changeLogData)
        ).pipe(
            map(([changelogs]) => {
                return [
                    _.map(changelogs, function (obj) {
                        return {
                            ...obj.data(),
                            documentID: obj.id,
                            origin: obj.data().type,
                            type: 'log',
                            subType: 'log',
                            isDone: true,
                            startDate: obj.data().logDate,
                            addedDate: obj.data().logDate,
                            owner: obj.data().logBy
                        }
                    })
                ]
            }))
            .subscribe(async ([changelogs]) => {
                setTempData(changelogs)
                setLoadingActivity(false);
            });
        return () => {
            activitiesStockCollection && activitiesStockCollection.unsubscribe()
        }

    }, [])


    const alllogsList = useMemo(() =>
        activitylogs.sort(function (x, y) {
            return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
        }), [activitylogs])


    const alllogsSorted = loadingActivity
        ? []
        : alllogsList;


    const divStyle = !_.isEmpty(alllogsSorted) ? {
        height: '400px',
        overflow: 'auto',
        position: "relative"
    } : {};

    return (
        <>
            <div className="mid-column" style={divStyle}>
                {
                    loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className={`enquiry-flow`}>
                            <div className="enquiry-flow-section">
                                {
                                    !_.isEmpty(alllogsSorted)
                                        ?
                                        <>
                                            <div className="enquiry-flow-wrap">
                                                <div className="flow-timelinebar"></div>
                                                {
                                                    alllogsSorted.map((log, index) => {
                                                        return <ActivityTag
                                                            key={index}
                                                            activityDate={log.type === 'log' ? log.addedDate : log.startDate}
                                                            id={log.documentID}
                                                            addedBy={log.owner ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner) : log.addedByName}
                                                            activitylog={log}
                                                            dealersettings={props.dealersettings}
                                                            clientUsers={props.clientUsers}
                                                            groupUsers={props.groupUsers}
                                                            stockStatuses={stockStatuses}
                                                            // countries={countries}
                                                            // states={states}
                                                            // nationalities={nationalities}
                                                            // pipelines={pipelines}
                                                            // stages={stages}
                                                            history={props.history}
                                                        >
                                                        </ActivityTag>
                                                    })
                                                }


                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='spinner-loader h-100 p-5'>
                                                <div className='no-data-flex h-100 justify-content-center align-items-center '>
                                                    {/* <div className='no-data-img'>
                                                        {' '}
                                                        <img src={_images.nodata} width='60' height='60' alt='' />
                                                    </div> */}
                                                    <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>
                        </div>
                    )
                }
            </div>

        </>
    )
}

export default EnquiryActivity