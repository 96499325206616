import React, { useState, useEffect } from 'react'
import "../../../styles/testdrive.scss";
import _ from 'lodash'
import moment from 'moment-timezone';
/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';

const TestdriveReport = (props) => {
    const [testdrive, setTestdrive] = useState({})
    const [firestoreDB, setFirestoreDB] = useState()
    const [event, setEvent] = useState()
    const [contact, setContact] = useState()
    const [client, setClient] = useState()
    const [loader, setLoader] = useState(true)
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _eid = params.get('eid');
    const _tid = params.get('tid');

    useEffect(() => {
        if (props.contactid) {
            window.firebase.firestore().doc(`eventsProAttendees/${props.contactid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(_eid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`).doc(props.contactid).get());

        Promise.all(_promise)
            .then(async docs => {
                if (docs[0]?.exists) {
                    setEvent({
                        ...docs[0].data(),
                        timezone: docs[0].data()?.timezone || moment.tz.guess()
                    })
                    if (docs[0].data().clientID) {
                        const clientDoc = await window.firebase.firestore().collection(`clients`).doc(docs[0].data().clientID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                    }
                    else if (docs[0].data().oemID) {
                        const clientDoc = await window.firebase.firestore().collection(`clientSettings`).doc(docs[0].data().oemID).get();
                        if (clientDoc.exists) {
                            setClient(clientDoc.data());
                        }
                    }
                }
                if (docs[1]?.exists) {
                    setContact({
                        ...docs[1].data()
                    })
                    setTestdrive(_.find(docs[1].data()?.testDrives, { documentID: _tid }))
                }
                setLoader(false)
            })

    }, [firestoreDB]);

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div className="quotation-wrap report-data-load">
                <div className="print-ico">
                    <a href="#" onClick={() => {
                        window.print();
                    }}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                </div>
                <div className="wrapper">
                    <div className="print-wrap">
                        <div className="header">
                            {
                                client && client.logoURL
                                    ?
                                    <div className="logo-center"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                    :
                                    <>
                                        <div className="dealer-center">{!_.isEmpty(client) ? client.name : '--'}</div>
                                    </>
                            }
                        </div>
                        <div className="clear"></div>
                        <div className="clear"></div>
                        <div className="middle">
                            <div className="title-head"><Translate text={'TEST DRIVE REPORT'} upperCase={true} /></div>
                            <div className="clear"></div>
                            <div className="table-wraper">
                                <div className="table-head valuation-num">{'Event Information'}</div>
                                <div className="table-details">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="33%"><div className="grid-one"> <span>Title</span>  {event?.title || '--'} </div></td>
                                                <td width="33%"><div className="grid-one"> <span>Location</span> {event?.location || '--'} </div></td>
                                                <td width="33%"><div className="grid-one"> <span>Date</span> {CommonHelper.showCombineDate(event?.startDate, event?.endDate) || '--'} </div></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="table-wraper brd-none">
                                <div className="table-head">Contact Details</div>
                                <div className="table-details">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="25%"><div className="grid-one"> <span>Contact Name</span>{CommonHelper.displayContactName(null, contact, '--')}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Phone</span>{contact?.phone || '--'} </div></td>
                                                <td width="25%"><div className="grid-one"> <span>Email</span>{contact?.email || '--'} </div></td>
                                                <td width="25%"><div className="grid-one"> <span>Licence No</span>{contact && contact.licenseNo ? contact.licenseNo : '--'} </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="tbl-lic-fix">
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td width="25%">
                                                        <div className="license-wrap">
                                                            <span>License Front</span>
                                                            {
                                                                (contact && contact.licenseFrontURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseFrontURL`} href={contact.licenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img id="licenseFrontURL" src={contact.licenseFrontURL} alt="" className="" width="150" />
                                                                    </a>
                                                                    :
                                                                    <img id="licenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td width="25%">
                                                        <div className="license-wrap">
                                                            <span>License Back</span>
                                                            {
                                                                (contact && contact.licenseBackURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseBackURL`} href={contact.licenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img id="licenseBackURL" src={contact.licenseBackURL} alt="" className="" width="150" />
                                                                    </a>
                                                                    :
                                                                    <img id="licenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                            }
                                                        </div>
                                                    </td>

                                                    <td width="25%"> </td>

                                                    <td width="25%">  </td>



                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            </div>


                            <div className="table-wraper brd-none">
                                <div className="table-head">Test Drive Information </div>
                                <div className="table-details">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="25%"><div className="grid-one"> <span>Make</span>{testdrive?.stock?.make || testdrive?.make || '--'}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Model</span>{testdrive?.stock?.model || testdrive?.model || '--'}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Year</span>{testdrive?.stock?.year || testdrive?.year || '--'}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Reg No</span>{testdrive?.stock?.regNo || testdrive?.regNo || '--'}</div></td>


                                            </tr>

                                            <tr>
                                                <td width="25%"><div className="grid-one"> <span>VIN</span>{testdrive?.stock?.vinNo || testdrive?.vinNo || '--'}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Color</span>{testdrive?.stock?.extColor || testdrive?.extColor || '--'}</div></td>
                                                <td width="25%"><div className="grid-one"> <span>Start Time</span>{testdrive?.startDate ?
                                                    (!_.isEmpty(event) && event.timezone ? moment(moment.unix(testdrive?.startDate.seconds)).tz(event.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment.unix(testdrive?.startDate.seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`))
                                                    : '--'} </div></td>
                                                <td width="25%"><div className="grid-one"> <span>End Time</span>{testdrive?.endDate ?
                                                    (!_.isEmpty(event) && event.timezone ? moment(moment.unix(testdrive?.endDate.seconds)).tz(event.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment.unix(testdrive?.endDate.seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`))
                                                    : '--'} </div></td>


                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                !_.isEmpty(testdrive?.terms)
                                    ?
                                    <>
                                        <div className="table-wraper brd-none page-break mt-5">
                                            <div className="table-head">TERMS & CONDITIONS</div>
                                            <div className="terms"
                                                dangerouslySetInnerHTML={{ __html: (testdrive?.terms?.termsAndConditions || '--') }} />

                                        </div>
                                        <div className="table-wraper brd-none page-break">
                                            <div className="table-head">Signature </div>
                                            <div className="vehicle-map">
                                                {
                                                    testdrive?.terms?.signature
                                                        ?
                                                        <img id={`terms-signature`} src={testdrive?.terms?.signature} width="120" />
                                                        :
                                                        <img id={`terms-signature`} src={_images.sign} width="120" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            testdrive?.terms?.addedDate
                                                ?
                                                <>
                                                    <div className="table-wraper cst-consent-wrapper">
                                                        <div className="footer-left"><Translate text={'Signed Date'} />  :&nbsp;
                                                            <strong>{(!_.isEmpty(event) && event.timezone ? moment(moment.unix(testdrive?.terms?.addedDate.seconds)).tz(event.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment.unix(testdrive?.terms?.addedDate.seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`))}</strong>
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }

                            {
                                !_.isEmpty(testdrive?.insurance)
                                    ?
                                    <>
                                        <div className="table-wraper brd-none page-break mt-1">
                                            <div className="table-head">Insurance Excess Reduction</div>
                                            <div className="terms"
                                                dangerouslySetInnerHTML={{ __html: (testdrive?.insurance?.termsAndConditions ? testdrive?.insurance?.termsAndConditions : '--') }} />

                                        </div>
                                        <div className="table-wraper brd-none page-break">
                                            <div className="table-head">Signature </div>
                                            <div className="vehicle-map">
                                                {
                                                    testdrive?.insurance?.signature
                                                        ?
                                                        <img id={`insurance-signature`} src={testdrive?.insurance?.signature} width="120" />
                                                        :
                                                        <img id={`insurance-signature`} src={_images.sign} width="120" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            testdrive?.insurance?.addedDate
                                                ?
                                                <>
                                                    <div className="table-wraper cst-consent-wrapper">
                                                        <div className="footer-left"><Translate text={'Signed Date'} />  :&nbsp;
                                                            <strong>{(!_.isEmpty(event) && event.timezone ? moment(moment.unix(testdrive?.insurance?.addedDate.seconds)).tz(event.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment.unix(testdrive?.insurance?.addedDate.seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`))}</strong>
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }

                        </div>
                    </div>
                    <div className="push"></div>
                </div>
                <div className="clear"></div>
                <div className="footer"> 
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className='footer-right'>Created On - {!_.isEmpty(event) && event.timezone ? moment.tz(event.timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : moment().format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`)}</div>

                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </>
    )
}
export default TestdriveReport;