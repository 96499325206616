/** LIBRARIES */
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../../constants/translate';

import { default as _images } from '../../../images';

const AddOns = (props) => {
    const [searchText, setSearchText] = useState('')
    const [selectedAddOns, setAddOns] = useState(props.selectedAddOns ? props.selectedAddOns : [])
    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

    //         const setttings = props.dealersettings.client.settings;

    //         let _titles = [];
    //         setttings.titles && setttings.titles.forEach((doc) => {
    //             _titles.push({
    //                 value: doc.value,
    //                 label: doc.name
    //             });
    //         });

    //         //setTitles(_titles);

    //     }
    // }, [props.dealersettings])

    const handleAdd = (data) => {
        let _dynamicFields = Object.assign([], selectedAddOns);
        _dynamicFields.push(data);
        setAddOns(_dynamicFields);
    }

    const handleRemove = (data) => {
        const { value } = data;
        let _dynamicFields = Object.assign([], selectedAddOns);
        _dynamicFields = _dynamicFields.filter(m => m.value !== value)
        setAddOns(_dynamicFields);
    }

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    let serviceAddOns = !_.isEmpty(props.serviceAddOns) && props.serviceAddOns.filter(item => (searchText ? item.name.toLowerCase().includes(searchText.toLowerCase()) : true));

    return (
        <>

            <Modal
                id="AddOns"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-packages">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Service Packages'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="package-item-head pr-0">
                                    <input
                                        type="text"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={searchText ? searchText : ''}
                                        autoComplete="off"
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="package-item-sub-head">
                                    {
                                        !_.isEmpty(serviceAddOns) ? <div className="float-left package-item-sub-head-count"><strong>{serviceAddOns.length}</strong> <Translate text={'Packages'} /></div> : <></>
                                    }
                                    {
                                        !_.isEmpty(selectedAddOns) ? <div className="float-right">
                                            <div className="list-count-btn ml-3 float-left"><span><Translate text={'Selected Packages'} /></span><span className="list-count"> {selectedAddOns.length} </span></div>
                                        </div> : <></>
                                    }
                                </div>
                                <div style={divStyle}>
                                    <div className="package-item-loop">
                                        {
                                            !_.isEmpty(serviceAddOns)
                                                ?
                                                <>
                                                    {
                                                        serviceAddOns.map((info, index) => {
                                                            return <div key={index} className="service-packages-box">
                                                                <div className="flex-package">
                                                                    <div className="package-item-icon"> <i className="ico icon-service-package"> </i> </div>
                                                                    <div className="package-data">
                                                                        <div className="package-item-title">{info.name}</div>
                                                                        <div className="package-item-info"> {(info.price || info.price === 0) ?
                                                                            <NumberFormat value={info.price ? info.price : 0} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                                                            : ''}
                                                                        </div>
                                                                    </div>
                                                                    <div className="package-add-button">
                                                                        {
                                                                            !_.isEmpty(selectedAddOns) && _.find(selectedAddOns, { value: info.value })
                                                                                ?
                                                                                <button className="btn btn-sm selected-package-btn"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleRemove(info);

                                                                                    }}><i className="ico icon-check mr-1"> </i><Translate text={'Selected'} /></button>
                                                                                :
                                                                                <button className="btn btn-sm btn-default add-package-btn"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        handleAdd(info);

                                                                                    }}><i className="ico icon-add mr-1"></i> <Translate text={'add'} /></button>

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100 p-5">
                                                        <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-service-package"></i> </div>
                                                            <div className="no-data-txt mt-2"> <Translate text={'No packages available'} /></div>
                                                        </div>

                                                    </div>
                                                </>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-default float-left"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose(null);

                        }}
                    ><Translate text={'cancel'} />
                    </button>

                    <button
                        type="button"
                        className="btn btn-primary float-right"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handleClose(selectedAddOns, true);
                        }}
                    >
                        <Translate text={'done'} />
                    </button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddOns