export const contactFieldsVM = [
    {
        name: 'Title',
        value: 'title'
    },
    {
        name: 'First Name',
        value: 'firstName'
    },
    {
        name: 'Middle Name',
        value: 'middleName'
    },
    {
        name: 'Last Name',
        value: 'lastName'
    },
    {
        name: 'Preferred Name',
        value: 'preferredName'
    },
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'Phone or Email',
        value: 'phoneORemail'
    },
    {
        name: 'Phone, Email or License',
        value: 'phoneORemailORlicenseNo'
    },
    {
        name: 'Other Phones',
        value: 'phones'
    },

    {
        name: 'Other Emails',
        value: 'emails'
    },
    {
        name: 'Profile Image',
        value: 'userImageURL'
    },
    {
        name: 'License No',
        value: 'licenseNo'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry'
    },
    {
        name: 'License Front',
        value: 'licenseFrontURL'
    },
    {
        name: 'License Back',
        value: 'licenseBackURL'
    },
    {
        name: 'License Type',
        value: 'licenseType'
    },
    {
        name: 'License State',
        value: 'licenseState'
    },
    {
        name: 'Date of Birth',
        value: 'dob'
    },
    {
        name: 'Gender',
        value: 'gender'
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus'
    },
    {
        name: 'Language',
        value: 'language'
    },
    {
        name: 'VIP Customer',
        value: 'isVIP'
    },
    {
        name: 'Contact Type',
        value: 'contactType'
    },
    {
        name: 'Address',
        value: 'address'
    },
    {
        name: 'State',
        value: 'state'
    },
    {
        name: 'Area Code',
        value: 'areaCode'
    },
    {
        name: 'Country',
        value: 'country'
    },
    {
        name: 'Nationality',
        value: 'nationality'
    },
    {
        name: 'Company',
        value: 'company'
    }
    ,
    {
        name: 'Designation',
        value: 'designation'
    },
    {
        name: 'Visiting Card',
        value: 'visitingCardURL'
    },
    {
        name: 'Contact Method',
        value: 'contactMethod'
    },
    {
        name: 'Interests',
        value: 'interests'
    },
    {
        name: 'Dependents',
        value: 'dependents'
    },
    {
        name: 'Driver Name',
        value: 'driverName'
    },
    {
        name: 'Origin',
        value: 'origin'
    },
    {
        name: 'Campaign',
        value: 'campaign'
    },
    {
        name: 'Opt-in Phone',
        value: 'optinPhone'
    },
    {
        name: 'Opt-in SMS',
        value: 'optinSMS'
    },
    {
        name: 'Opt-in Email',
        value: 'optinEmail'
    },
    {
        name: 'Opt-in Post',
        value: 'optinPost'
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail'
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS'
    },
    {
        name: 'Secondary First Name',
        value: 'secondaryFirstName'
    },
    {
        name: 'Secondary Last Name',
        value: 'secondaryLastName'
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone'
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail'
    },
    {
        name: 'Secondary Other Phones',
        value: 'secondaryPhones'
    },
    {
        name: 'Secondary Other Emails',
        value: 'secondaryEmails'
    },
    {
        name: 'Secondary License Front',
        value: 'secondaryLicenseFrontUrl'
    },
    {
        name: 'Secondary Back Front',
        value: 'secondaryLicenseBackUrl'
    }
];


export const appraisalFieldsVM = [
    {
        name: 'Contact',
        value: 'isContact'
    },
    {
        name: 'Trade-In',
        value: 'isTradein'
    },
    {
        name: 'Owner',
        value: 'owner'
    },
    {
        name: 'Lead Origin',
        value: 'origin'
    },
    {
        name: 'Lost Reason',
        value: 'lostReason'
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason'
    },
]

export const tradeinFieldsVM = [
    {
        name: 'Make',
        value: 'make'
    },
    {
        name: 'Model',
        value: 'model'
    },
    {
        name: 'Model Description',
        value: 'modelDescription'
    },
    {
        name: 'Body Type',
        value: 'type'
    },
    {
        name: 'Group',
        value: 'group'
    },
    {
        name: 'Year',
        value: 'year'
    },
    {
        name: 'Notes',
        value: 'notes'
    },
    {
        name: 'Reg. Number',
        value: 'regNo'
    },
    {
        name: 'Reg. Expiry',
        value: 'regDate'
    },
    {
        name: 'Drive Type',
        value: 'driveType',
    },
    {
        name: 'Fuel Type',
        value: 'fuelType',
    },
    {
        name: 'Cylinders',
        value: 'cylinders',
    },
    {
        name: 'Gears',
        value: 'gears',
    },
    {
        name: 'Seats',
        value: 'seats',
    },
    {
        name: 'Power kW',
        value: 'powerKw',
    },
    {
        name: 'Range',
        value: 'range',
    },
    {
        name: 'Series',
        value: 'series',
    },
    {
        name: 'Torque Nm',
        value: 'torqueNm',
    },
    {
        name: 'Transmission Type',
        value: 'transmissionType',
    },
    {
        name: 'Wheelbase',
        value: 'wheelbase',
    },
    {
        name: 'Registration State',
        value: 'regState',
    },
    {
        name: 'Registration Status',
        value: 'regStatus',
    },
    {
        name: 'Badge',
        value: 'badge',
    },
    {
        name: 'Region',
        value: 'region',
    },
    {
        name: 'Chassis Number',
        value: 'chassisNo'
    },
    {
        name: 'Engine Number',
        value: 'engineNo'
    },
    {
        name: 'Engine Type',
        value: 'engineType'
    },
    {
        name: 'Engine Capacity-Litres',
        value: 'engineSize'
    },
    {
        name: 'Transmission',
        value: 'transmission'
    },
    {
        name: 'Doors',
        value: 'doors'
    },
    {
        name: 'Mileage',
        value: 'mileage'
    },
    {
        name: 'Exterior Color',
        value: 'exteriorColor'
    },
    {
        name: 'Interior Color',
        value: 'interiorColor'
    },
    {
        name: 'Trim',
        value: 'trim'
    },
    {
        name: 'Owners',
        value: 'owners'
    },
    {
        name: 'Finance Company',
        value: 'financeCo'
    },
    {
        name: 'Service Contract From',
        value: 'serviceContFrom'
    },
    {
        name: 'Service Contract To',
        value: 'serviceContTo'
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry'
    },
    {
        name: 'Tyre Age',
        value: 'tyreAge'
    },
    {
        name: 'Last Service',
        value: 'lastService'
    },
    {
        name: 'Status',
        value: 'status'
    },
    {
        name: 'Front Left Wheel',
        value: 'frontLeftWheel'
    },
    {
        name: 'Front Right Wheel',
        value: 'frontRightWheel'
    },
    {
        name: 'Rear Left Wheel',
        value: 'rearLeftWheel'
    },
    {
        name: 'Rear Right Wheel',
        value: 'rearRightWheel'
    },
    {
        name: 'Vehicle Condition',
        value: 'vehicleCondition'
    },
    {
        name: 'Build Date',
        value: 'buildDate'
    },
    {
        name: 'Compliance Date',
        value: 'complianceDate'
    },
    {
        name: 'Spare Key',
        value: 'spareKey'
    },
    {
        name: 'Log Books',
        value: 'logBooks'
    },
    {
        name: 'Service History',
        value: 'serviceHistory'
    },
    {
        name: 'Cargo Blind',
        value: 'cargoBlind'
    }, {
        name: 'Head Rests',
        value: 'headRests'
    },
    {
        name: 'SD Card',
        value: 'sdCard'
    },
    {
        name: 'Spare Tyre',
        value: 'spareTyre'
    },
	{
        name: 'Reconditioning Cost',
        value: 'reconditioningCost'
    },
    {
        name: 'Vehicle Images',
        value: 'images'
    }
]

export const allModules = [
    "company",
    "contact",
    "businessContact",
    "tradeinPro",
    "service",
    "vehicle",
    "businessContact",
    "recommendation",
    "part",
    "enquiry",
    "fleet",
    "requirement",
    "stock",
    "testdrive",
    "tradein",
    "partContact",
    "testdriveContact",
    "tradeinContact",
    "financeContact",
    "videoContact",
    "finance",
    "pipeline",
    "status"
]

export const appraisalModules = [
    "company",
    "contact",
    "businessContact",
    "tradeinPro",
    "tradein",
    "pipeline",
    "status"
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]


export const enquiryStatus = [
    { active: true, value: 'New', name: 'New' },
    { active: true, value: 'InProgress', name: 'In Progress' },
    { active: true, value: 'Completed', name: 'Completed' },
    { active: true, value: 'Pending', name: 'Pending' },
    { active: true, value: 'Won', name: 'Won' },
    { active: true, value: 'Lost', name: 'Lost' },
]

export const mandSettingsVM = {
    company: '',
    contact: '',
    businessContact: '',
    tradeinPro: '',
    service: '',
    vehicle: '',
    recommendation: '',
    part: '',
    enquiry: '',
    fleet: '',
    requirement: '',
    stock: '',
    testdrive: '',
    tradein: '',
    partContact: '',
    testdriveContact: '',
    tradeinContact: '',
    financeContact: '',
    videoContact: '',
    pipeline: [],
    status: [],
    finance: ''
}


export const moduleNameValue = [
    {
        name: 'Appraisal',
        value: 'tradeinPro'
    },
    {
        name: 'Contact',
        value: 'contact'
    },
    {
        name: 'Business Contact',
        value: 'businessContact'
    },
    {
        name: 'Trade-In',
        value: 'tradein'
    }
]

