/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash';
import Modal from "react-bootstrap/Modal";
/** COMPONENTS */
import { WidgetOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';

export default class Evaluators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            evaluators: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.isLoading = true;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        const getEvaluators = window.firebase.functions().httpsCallable('tradeinplus-getEvaluators');
        getEvaluators({
            "clientID": clientID
        }).then((response) => {
            this.isLoading = false;
            //console.log('getEvaluators',clientID, response, response.data.message, response.data.success);
            if (response.data.success && !_.isEmpty(response.data.data)) {
                this.setState({
                    evaluators: response.data.data,
                    isLoading: false
                });
            }
            else {
                this.setState({
                    isLoading: false
                });
            }

        });
    }

    render() {

        const { isLoading, evaluators } = this.state;
        const { show, title } = this.props;
        const divStyle = {
            height: '300px',
            overflow: 'auto'
        };
        return (
            <>
                <Modal
                    id="Evaluators-List"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-terms">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={title} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>
                            <div style={divStyle}>
                                {
                                    isLoading
                                        ?
                                        <>
                                            <WidgetOverlay />
                                        </>
                                        :
                                        <>
                                            {

                                                (!_.isEmpty(evaluators) && evaluators.length) > 0
                                                    ?
                                                    <>
                                                        {evaluators.map(contact =>
                                                            <div key={contact.Id} className="frontdesk-rearrange-box frontdesk-flex-queue" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.handleClose(contact.Id, contact.FullName)

                                                            }}>
                                                                <div className={`frontdesk-rearrange-avatar`}>
                                                                    <img src={CommonHelper.showUserAvatar(contact.profileImage, contact.FullName)} alt="" className="img-object-fit" /> </div>
                                                                <div className="frontdesk-rearrange-detail">
                                                                    <h3>{contact.FullName}</h3>
                                                                    <h5>
                                                                        {contact.RoleName ? contact.RoleName + '  |  ' : '     '}
                                                                        {contact.EmailAddress ? contact.EmailAddress : ''}
                                                                    </h5>
                                                                </div>

                                                            </div>
                                                        )}</>
                                                    :
                                                    <>
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-Contacts"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'noEvaluatorFound'} /></div>
                                                            </div>

                                                        </div>
                                                    </>
                                            }

                                        </>
                                }

                            </div>

                        </>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}


