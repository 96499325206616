import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomAdvFilters } from '../../components/customdropdown';
import { DefaultFilters, CustomFilters } from '../common'
import TradeInAdvanceFilters from './advanceFilters'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

const _defaultFilters = [
    { id: 'all', name: 'All TradeIn', value: {}, type: 'default' },
    { id: 'Completed', name: 'Completed TradeIn', value: { 'status': 'Completed' }, type: 'default' },
    { id: 'Pending', name: 'Pending TradeIn', value: { 'status': 'Pending' }, type: 'default' },
    { id: 'Won', name: 'Won TradeIn', value: { 'status': 'Won' }, type: 'default' },
    { id: 'Lost', name: 'Lost TradeIn', value: { 'status': 'Lost' }, type: 'default' }
    // { name: 'New Stock', value: { 'saleType': 'New' }, type: 'default' }
]

const objtradeInFilter = [
    "keywords", "date", "owner", "status", "make", "sent"
]

const Filters = (props) => {
    const [userID, setUserID] = useState('')
    const [show, setShow] = useState(false)
    const [searchField, setSearchField] = useState('')
    const [customFilters, setCustomFilters] = useState([])
    const [defaultFilters, setDefaultFilters] = useState(_defaultFilters)
    const [columnFields, setColumnFields] = useState(objtradeInFilter)
    const [activeTab, setActiveTab] = useState('All')

    const { tradeInFilter } = props;

    const node = useRef();

    useEffect(() => {
        setCustomFilters(props.customFilters)
        setUserID(props.dealersettings.id)

        const tradeinProAllStatus = Object.assign([], props.dealersettings?.client?.tradeinPro?.tradeinProStatus)
        _defaultFilters.forEach((obj) => {
            if (obj.id === 'Completed') {
                obj.name = `${CommonHelper.getNameByValue(tradeinProAllStatus, 'Completed', 'Completed')} TradeIn`;
            }
            else if (obj.id === 'Won') {
                obj.name = `${CommonHelper.getNameByValue(tradeinProAllStatus, 'Won', 'Won')} TradeIn`;
            }
            else if (obj.id === 'Lost') {
                obj.name = `${CommonHelper.getNameByValue(tradeinProAllStatus, 'Lost', 'Lost')} TradeIn`;
            }
            else if (obj.id === 'Pending') {
                obj.name = `${CommonHelper.getNameByValue(tradeinProAllStatus, 'Pending', 'Pending')} TradeIn`;
            }
        });

        setDefaultFilters(_defaultFilters)
    }, [props]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('ant-picker-') >= 0) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('swal2-') >= 0) ||
            (!_.isEmpty(e.target.classList) && e.target.classList.value.indexOf('filter-dont-close') >= 0) ||
            e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')
        ) {
            return;
        }
        setShow(false);
    };

    const handleSearchField = (e) => {
        setSearchField(e.target.value);
        if (e.target.value) {
            setCustomFilters([...props.customFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setDefaultFilters([..._defaultFilters.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()))])
            setColumnFields([...objtradeInFilter.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase()))])
        }
        else {
            setCustomFilters(props.customFilters)
            setDefaultFilters(_defaultFilters)
            setColumnFields(objtradeInFilter)
        }
    }

    const handleClearSearchField = (e) => {
        setSearchField('');
        setCustomFilters(props.customFilters)
        setDefaultFilters(_defaultFilters)
        setColumnFields(objtradeInFilter)
    }

    // const saveFavourite = (filterID, checked) => {
    //     const { dealersettings } = props;
    //     let settingsid = dealersettings.client.settings.id;
    //     let objData = customFilters.filter(e => e.documentID === filterID)[0];

    //     if (settingsid && filterID && !_.isEmpty(objData)) {
    //         let currentDate = window.firebase.firestore.Timestamp.now();
    //         let isFav = objData.isFav;

    //         if (checked)
    //             isFav.push(userID)
    //         else
    //             isFav = isFav.filter(m => m !== userID);

    //         const refStockData = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`).doc(filterID);
    //         refStockData.set({
    //             isFav: isFav,
    //             modifiedBy: userID,
    //             modifiedDate: currentDate
    //         }, { merge: true }).then((docRef) => {
    //             toast.notify('Favourite saved successfully', {
    //                 duration: 2000
    //             });

    //         }).catch((error) => {
    //             console.error("Error updating filter_details: ", error);
    //         });
    //     }

    // }

    // const deleteFilter = (filterID) => {
    //     const { dealersettings } = props;
    //     let settingsid = dealersettings.client.settings.id;
    //     if (settingsid && filterID) {
    //         window.firebase.firestore().doc(`clientSettings/${settingsid}/filters/${filterID}`).delete().then(() => {
    //             toast.notify('Filter successfully deleted!', {
    //                 duration: 2000
    //             })

    //         }).catch((error) => {
    //             console.error("Error removing Filter: ", error);
    //         });
    //     }
    // }

    const handleSelectFilter = (_filter) => {
        setShow(false);
        props.handleApplyFilter(_filter);
    }

    const displayFilterBadge = () => {
        if (!_.isEmpty(tradeInFilter) && !_.isEmpty(tradeInFilter.value)) {

            return <span className="badge badge-pill badge-advanced-filter">{Object.keys(tradeInFilter.value).length}</span>
        }
        else {
            return <></>
        }
    }

    const handleFilterClose = () => {
        setShow(false);
    }

    return (


        <Dropdown ref={node} alignRight show={show}>
            <div className="advanced-filter-btn mr-2 float-right">
                <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                    setShow(!show)
                }}>
                    {displayFilterBadge()}
                    <i className="ico icon-filter"></i>
                    <span>
                        {
                            !_.isEmpty(tradeInFilter)
                                ?
                                <>
                                    {
                                        tradeInFilter.name && (tradeInFilter.type === 'default' || tradeInFilter.type === 'custom')
                                            ?
                                            tradeInFilter.name
                                            :
                                            'Filter'
                                    }
                                </>
                                :
                                <>{'All Trade-In'}</>
                        }

                    </span>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(tradeInFilter)
                        ?
                        <>
                            <a href="#" className="clear-btn" onClick={(e) => { e.preventDefault(); props.handleClearFilter(); }}> <i className="ico icon-remove"></i> </a>
                        </>
                        :
                        <></>
                }
            </div>
            <Dropdown.Menu as={CustomAdvFilters}
                id="table-dynamic-cols-settings-pipeline"
                className="dropdown-menu dropdown-menu-left"
                ChildClass="quick-filter-listing"
                xplacement="bottom-end"
                title={'Filters'}
                handleTextchange={handleSearchField}
                handleTextClear={handleClearSearchField}
                searchField={searchField}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                noSaveAccess={props.noSaveAccess}
                dealersettings={props.dealersettings}
            >
                {
                    props.noSaveAccess
                        ?
                        <>
                            <TradeInAdvanceFilters
                                columnFields={columnFields}
                                dealersettings={props.dealersettings}
                                clientUsers={props.clientUsers}
                                groupUsers={props.groupUsers}
                                tradeInFilter={tradeInFilter}
                                customFilters={customFilters}
                                handleClearFilter={props.handleClearFilter}
                                handleApplyFilter={props.handleApplyFilter}
                                handleFilterClose={handleFilterClose}
                                noSaveAccess={props.noSaveAccess}
                            />
                        </>
                        :
                        <>
                            {(() => {
                                if (activeTab === 'All') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    defaultFilters && defaultFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'system'} /></ol>
                                                            {
                                                                defaultFilters.map((_filter, index) => {
                                                                    return (
                                                                        <DefaultFilters
                                                                            key={index}
                                                                            _filter={_filter}
                                                                            localFilter={tradeInFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                        ></DefaultFilters>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }

                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            <ol className="quick-filter-subheader"><Translate text={'customFilters'} /></ol>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={tradeInFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'tradeins'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Custom') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={tradeInFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'tradeins'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Favorites') {
                                    return (
                                        <div className="quick-filter-body">
                                            <ul className={'quick-filter-listing'}>
                                                {
                                                    customFilters && customFilters.length > 0
                                                        ?
                                                        <>
                                                            {
                                                                customFilters.filter(e => !_.isEmpty(e.isFav) && e.isFav.indexOf(userID) >= 0).map((_filter, index) => {
                                                                    return (
                                                                        <CustomFilters
                                                                            key={index}
                                                                            userID={userID}
                                                                            _filter={_filter}
                                                                            localFilter={tradeInFilter}
                                                                            handleSelectFilter={handleSelectFilter}
                                                                            dealersettings={props.dealersettings}
                                                                            clientUsers={props.clientUsers}
                                                                            customFilters={customFilters}
                                                                            module={'tradeins'}
                                                                        ></CustomFilters>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    )
                                } else if (activeTab === 'Filters') {
                                    return (
                                        <>
                                            <TradeInAdvanceFilters
                                                columnFields={columnFields}
                                                dealersettings={props.dealersettings}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                                tradeInFilter={tradeInFilter}
                                                customFilters={customFilters}
                                                handleClearFilter={props.handleClearFilter}
                                                handleApplyFilter={props.handleApplyFilter}
                                                handleFilterClose={handleFilterClose}
                                            />
                                        </>
                                    )
                                }
                            })()}
                        </>
                }
            </Dropdown.Menu>
        </Dropdown>





    )
}

export default Filters