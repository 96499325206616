import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment'
import _ from 'lodash'

import { ContentOverlay, PopUpModal } from '../../components'
import Translate from '../../constants/translate';
import VideoInfo from './videoInfo'
import AddVideo from './add'

import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { objVideos } from './viewModel'

const VideoListReducer = (state, action) => {
    function updateClient() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateClient()
        }
        default:
            return state;
    }
};

const Videos = (props) => {
    const [files, dispatch] = useReducer(VideoListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [modelAddShow, setModelAddShow] = useState(false)
    const [filesLoader, setLoader] = useState(true)
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    useEffect(() => {
        return () => {
            window.unSubscribeVideosList && window.unSubscribeVideosList();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        let refCollectionData;

        if (props.enquiryID || props.contactID) {

            if (props.enquiryID) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${props.enquiryID}/videos`)
                    .where('isDeleted', '==', false)
                //.orderBy('modifiedDate', 'desc')
            }
            else if (props.contactID && !props.showAdd) {
                refCollectionData = firestoreDB(props.dealersettings).firestore().collectionGroup(`videos`)
                    .where('contactID', '==', props.contactID)
                    .where('isDeleted', '==', false)
                //.orderBy('modifiedDate', 'desc')
            }

            window.unSubscribeVideosList = refCollectionData
                .onSnapshot(onCollectionUpdate);
        }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADD_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })
        let _files = [];
        if (!actionType) {
            querySnapshot.forEach(function (doc) {
                var logVM = dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                });
                _files.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _.orderBy(_files, ["modifiedDate"], ["desc"])
        });
        setDataload(false)
        setLoader(false)
    }


    const dataMappingVM = (doc) => {
        const objlogData = Object.assign({}, doc);
        const fileVM = Object.assign({}, objVideos);
        for (let [key, value] of Object.entries(objlogData)) {
            fileVM[key] = value;
        }

        fileVM.sentDate = !_.isEmpty(fileVM.sentDate) ? moment.unix(fileVM.sentDate.seconds).format('DD/MM/YYYY hh:mm A') : fileVM.sentDate;
        fileVM.createdOn = moment.unix(fileVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.updatedOn = moment.unix(fileVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        fileVM.addedBy = CommonHelper.getUserNamebyId(allUsers, fileVM.addedBy);
        fileVM.modifiedBy = CommonHelper.getUserNamebyId(allUsers, fileVM.modifiedBy);

        return fileVM
    }

    //#region FILE CHANGES

    const handleFileClose = () => {
        setModelAddShow(false);
    }


    //#endregion

    return (
        <>
            {
                filesLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    !_.isEmpty(files) && files.length > 0 ?
                        (
                            <>
                                {
                                    files.map((file, index) => {
                                        return (<VideoInfo
                                            key={index}
                                            file={file}
                                            dealersettings={props.dealersettings}
                                            customerEmail={props.customerEmail}
                                            customerPhone={props.customerPhone}
                                            customerPhoneCode={props.customerPhoneCode}
                                            enquiryID={props.enquiryID}
                                            contactID={props.contactID}
                                            isDeivered={props.isDeivered}
                                        >
                                        </VideoInfo>)
                                    })
                                }

                            </>

                        )
                        : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={"No record found"} /></p>
                                    {/* {CommonHelper.showLocale(props, 'No record found.')} */}
                                </div>
                            </div>
                        )
            }


            <PopUpModal show={modelAddShow}>
                <AddVideo
                    show={modelAddShow}
                    enquiryID={props.enquiryID}
                    contactID={props.contactID}
                    handleClose={handleFileClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </AddVideo>
            </PopUpModal>

        </>

    )
}

export default Videos;