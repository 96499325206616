
/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [stockFilter, setStockFilter] = useState({})

    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        if (!_.isEmpty(localStorage.stockFilter) && Object.keys(JSON.parse(localStorage.stockFilter)).length > 0) {
            let _stockFilter = JSON.parse(localStorage.stockFilter);
            let _stockValue = _stockFilter.value;

            if (!_.isEmpty(_stockValue) && _stockValue.hasOwnProperty('keywords'))
                setSearchKeywords(_stockValue['keywords']);
            else
                setSearchKeywords('');

            setStockFilter(_stockFilter);
        }
        else {
            setStockFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.stockFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(stockFilter)) {
                let _stockFilter = Object.assign({}, stockFilter)
                if (!_.isEmpty(stockFilter.value)) {
                    // update with the existing keyword
                    _stockFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _stockFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_stockFilter);
            }
            else {
                // create new filter with keyword
                let _stockFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_stockFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(stockFilter)) {
                let _stockFilter = Object.assign({}, stockFilter)
                if (!_.isEmpty(stockFilter.value)) {
                    if (Object.keys(stockFilter.value).length > 1) {
                        delete _stockFilter.value['keywords']
                        props.handleApplyFilter(_stockFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };
    //#endregion

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    // const accessOEMStock = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.accessOEMStock) ? true : false);

    return (
        <>
            <div className="filter-panel">
                {/* <Dropdown className="sort-btn float-left" alignRight>
                    <Dropdown.Toggle as={CustomToggle} >
                        <span id="spn-sort-name" data-name={props.sortName}>{props.sortName && sortOrders.find(item => item.value === props.sortName) ? sortOrders.find(item => item.value === props.sortName).label : 'Sort By'}</span>
                        <i className="ico icon-dropdown"></i>
                    </Dropdown.Toggle>
                    <a href="#" className="sort-ico"
                        onClick={(e) => {
                            e.preventDefault();
                            props.handlesortOrder(props.sortOrder === 'desc' ? 'asc' : 'desc')
                            localStorage.stocksortOrder = (props.sortOrder === 'desc' ? 'asc' : 'desc');
                        }}
                    > <i id="spn-sort-order" data-name={props.sortOrder} className={`ico icon-sort-${props.sortOrder === 'desc' ? 'up' : 'down'}`}></i> </a>
                    <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="pipeline-dropdown" xplacement="bottom-end">
                        {
                            sortOrders && sortOrders.length > 0 ?
                                sortOrders.map((rec, index) => {
                                    return <Dropdown.Item key={index} className="current-pipeline" onClick={(e) => {
                                        e.preventDefault();
                                        props.handleSortNamechange(rec.value)
                                    }}>
                                        {rec.value === props.sortName ? (<div className="pipeline-check">  <i className="ico icon-check"> </i></div>) : (<></>)}
                                        {rec.label}</Dropdown.Item>
                                })
                                : (<></>)
                        }
                    </Dropdown.Menu>
                </Dropdown> */}

                {/* FILTER SEARCH KEYWORDS*/}
                <div className="filter-search">
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="quick search..."
                            value={searchText ? searchText : ''}
                            onChange={(e) => {

                                let _val = e.target.value
                                setSearchText(_val);
                                localStorage.OemStockSalesSearchText = _val;

                                if (_val && _val.length > 2) {
                                    props.handlesearchText(_val)
                                }

                                if (!_val)
                                    props.handlesearchText('')
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    localStorage.OemStockSalesSearchText = searchText;
                                    props.handlesearchText(searchText)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchText && searchText.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchText('');
                                            localStorage.OemStockSalesSearchText = '';
                                            props.handlesearchText('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }

                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.OemStockSalesSearchText = searchText;
                                    props.handlesearchText(searchText)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className="filter-search ml-2">
                    <div className="input-group ">
                        <input
                            className="form-control"
                            type="text"
                            id="isearch"
                            placeholder="Search..."
                            value={searchKeywords ? searchKeywords : ''}
                            onChange={(e) => {
                                handleKeywordsOnChange(e);
                                if (!e.target.value)
                                    handleSearchKeywords('')
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearchKeywords(searchKeywords)
                                }
                            }}
                            autoComplete="off"
                        />
                        <div className="input-group-append input-search-clear">
                            {
                                searchKeywords && searchKeywords.length > 0
                                    ?
                                    <a href="#"
                                        className="input-search-clear-icon"
                                        title="Clear"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSearchKeywords('');
                                            handleSearchKeywords('')
                                        }}
                                    >
                                        <i className="ico icon-remove"></i>
                                    </a>
                                    :
                                    <>
                                    </>
                            }

                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSearchKeywords(searchKeywords)
                                }}>
                                <i className="ico icon-search-fill"></i>
                            </button>
                        </div>
                    </div>
                </div> */}

                {/* <div className='btn btn-secondary ml-2 float-left' onClick={() => {
                    if (Object.keys(props.selectedIds).length > 0) {
                        props.handleOpenAllocateModal()
                    } else {
                        Swal.fire('Please select one or more stock(s) to allocate.');
                        return;
                    }
                }
                }>
                    <span><Translate text={'allocate'} /></span>
                </div> */}


                <div className="float-right">
                    {/* <div className="filter-add-button ml-2">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handleAddStock();
                            }}
                        > <i className="ico icon-add"></i> <Translate text={'addOemStock'} /></button>
                    </div>  */}


                    {/* {true ? ( */}
                    {/* {accessOEMStock ? (
                        <div className="filter-add-button ml-2">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.handleOEMOpen();
                                }}
                            > <i className="ico icon-search"></i> <Translate text={'OEM Stock'} /></button>
                        </div>
                    ) : <></>} */}

                    {/* {
                        (exportData)
                            ?
                            <>
                                <div className="filter-item-btn ml-2">
                                    <CSVLink uFEFF={false} data={props.datalist} headers={props.csvHeader} filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}>
                                        <i className="ico icon-excel-download"></i>
                                    </CSVLink>
                                </div>
                            </>
                            :
                            <>
                            </>
                    } */}

                    {/* <div className="filter-view-more  ml-2">
                        <a className="common-button" onClick={(e) => { e.preventDefault(); }}><i className="ico icon-more"></i></a>
                    </div> */}

                </div>



                {/**** FILTERS ****/}
                {/* <Filters
                    searchKeywords={searchKeywords}
                    stockFilter={stockFilter}
                    customFilters={props.customFilters}
                    dealersettings={props.dealersettings}
                    handleApplyFilter={props.handleApplyFilter}
                    handleClearFilter={props.handleClearFilter}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </Filters> */}

                {/* {
                    ((!_.isEmpty(props.stockCount) || props.stockCount > 0) && !localStorage.OemStockSalesSearchText) ?
                        (<>
                            <div className="list-count-btn mr-3">
                                <span>  {props.stockCount > 1 ? ' Stock' : ' Stock'}</span>
                                <span className="list-count"> {props.stockCount} </span>
                            </div>
                        </>)
                        : (<></>)
                } */}

            </div >
        </>
    )
}


export default FilterPanel
