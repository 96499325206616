import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';

import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';

/** COMPONENTS */
import CommonHelper from '../../../../services/common';
import { ContentOverlay } from '../../../../components';
import Translate from '../../../../constants/translate';

import { pipelineCountVM } from '../../../dashboard/viewModel'
import { inboundCountsVM } from './viewModel'

const DailyActivityReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [countsLoader, setCountsLoader] = useState(true)
    const [counts, setBmwNewCounts] = useState(pipelineCountVM)
    const [miniNewCounts, setMiniNewCounts] = useState(pipelineCountVM)
    const [bmwUsedCounts, setBmwUsedCounts] = useState(pipelineCountVM)
    const [miniUsedCounts, setMiniUsedCounts] = useState(pipelineCountVM)
    const [othersCounts, setOthersCounts] = useState(pipelineCountVM)
    const [withoutReqCounts, setWithoutReqCounts] = useState(pipelineCountVM)
    const [inboundCounts, setInboundCounts] = useState(inboundCountsVM)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [followupActivities, setFollowupActivities] = useState([]);
    const [followupEnquiries, setFollowupEnquiries] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdWalkins, setWalkins] = useState([]);
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [inclInboundLeadsinRpt, setinclInboundLeadsinRpt] = useState(false)

    const [dateRange, setDateRange] = useState({})
    //moment().day("sunday").day()

    const [tempData, setTempData] = useState([])

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {

        if (_.isEmpty(firestoreDB))
            return

        if (props.clientid) {

            //setLoader(true)

            // first fetch client and set the dates
            window.firebase.firestore().collection('clients').doc(props.clientid).get().then(clientSnap => {
                if (!clientSnap.exists) {
                    return
                }



                const clientData = clientSnap.data()
                // console.log(clientData)

                const _inclInboundLeadsinRpt = ((
                    !_.isEmpty(clientData) &&
                    !_.isEmpty(clientData.clientSettings) && clientData.clientSettings.inclInboundLeadsinRpt) ? true : false);

                let search = window.location.search;
                let params = new URLSearchParams(search);
                let _date = params.get('date');
                _date = _date ? _date : moment.tz(clientData.timezone).format('YYYY-MM-DD');

                let _dateRange = {
                    date: _date,
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('month').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('month').toDate()),
                    todayStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('day').toDate()),
                    todayEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('day').toDate()),
                    weekStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('week').toDate()),
                    weekEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('week').toDate()),
                }
                if (!_.isEmpty(clientData.weekStart)) {
                    moment.updateLocale('en', {
                        week: {
                            dow: moment().day(clientData.weekStart).day(),
                        },
                    })
                    _dateRange = {
                        ..._dateRange,
                        weekStart: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).startOf('week').toDate()),
                        weekEnd: window.firebase.firestore.Timestamp.fromDate(moment(_date).tz(clientData.timezone).endOf('week').toDate()),
                    }
                }
                _dateRange = {
                    ..._dateRange,
                    monthStart: _dateRange.startDate,
                    monthEnd: _dateRange.endDate
                }

                setDateRange(_dateRange)



                //LOST PENDING APPROVAL
                let lostPendingApproval = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('status', '==', 'pendingLost')
                    .where('isDeleted', '==', false)


                //MTD OPEN ENQUIRIES / CAMPAIGN /LEAD ORIGIN / ENQUIRY TYPE / LABEL  
                let refOpenEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('addedDate', '>=', _dateRange.startDate)
                    .where('addedDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD WON ENQUIRIES    
                let refWonEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'won')
                    .where('clientID', '==', props.clientid)
                    .where('wonDate', '>=', _dateRange.startDate)
                    .where('wonDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD LOST ENQUIRIES    
                let refLostEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'lost')
                    .where('clientID', '==', props.clientid)
                    .where('lostDate', '>=', _dateRange.startDate)
                    .where('lostDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD DELIVERED ENQUIRIES    
                let refDeliveredEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'delivered')
                    .where('clientID', '==', props.clientid)
                    .where('deliveredDate', '>=', _dateRange.startDate)
                    .where('deliveredDate', '<=', _dateRange.endDate)
                    .where('isDeleted', '==', false)

                //MTD TEST DRIVES    
                let refTestDriveData = firestoreDB.firestore().collection('testdrives')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('startDate', '>=', _dateRange.startDate)
                    .where('startDate', '<=', _dateRange.endDate)
                    // @alert: comment below line before pushing  
                    .orderBy('startDate', 'desc')

                //MTD TRADE IN COUNT    
                let refTradeinData = firestoreDB.firestore().collection('tradeins')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('addedDate', '>=', _dateRange.startDate)
                    .where('addedDate', '<=', _dateRange.endDate)


                //MTD OVERDUE ACTIVITIES 
                let refScheduleActivityData = firestoreDB.firestore().collection('activities')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', false)
                    .where('type', '==', 'activity')
                    .where('startDate', '>=', _dateRange.todayStart)
                    .where('startDate', '<=', _dateRange.todayEnd)
                    .orderBy('startDate', 'desc')

                //MTD WALKIN LEADS
                let refWalkinEnquiryData = firestoreDB.firestore().collectionGroup('walkins')
                    .where('clientID', '==', props.clientid)
                    .where('walkinDate', '>=', _dateRange.startDate)
                    .where('walkinDate', '<=', _dateRange.endDate)

                let refClientData = window.firebase.firestore().collection('clients').where('documentID', '==', props.clientid)
                let refClientSettings = window.firebase.firestore().collection('clients').doc(props.clientid).collection('currentSettings')

                let refLeadsBucketEnquiryData = firestoreDB.firestore().collection('enquiries')
                    .where('pipeline', '==', 'LeadsBucket')
                    .where('isDeleted', '==', false)
                    .where('clientID', '==', props.clientid)

                let refLeadsBucketEnquiryDataByDate = firestoreDB.firestore().collection('enquiries')
                    .where('clientID', '==', props.clientid)
                    .where('isDeleted', '==', false)
                    .where('convertedDate', '>=', _dateRange.startDate)
                    .where('convertedDate', '<=', _dateRange.endDate)
                    .orderBy('convertedDate', 'desc')

                let refInboundLeads = firestoreDB.firestore().collection('inboundLeads')
                    .where('clientID', '==', props.clientid)
                    .where('status', '==', 0)
                    .where('isDeleted', '==', false)

                var unionPipelines = combineLatest(
                    of([]),
                    of([]),
                    lostPendingApproval ? collection(lostPendingApproval) : of([]),
                    refOpenEnquiryData ? collection(refOpenEnquiryData) : of([]),
                    refWonEnquiryData ? collection(refWonEnquiryData) : of([]),
                    refLostEnquiryData ? collection(refLostEnquiryData) : of([]),
                    refDeliveredEnquiryData ? collection(refDeliveredEnquiryData) : of([]),
                    of([]),
                    collection(refLeadsBucketEnquiryDataByDate),
                    of([]),
                    collection(refTestDriveData),
                    collection(refTradeinData),
                    of([]),
                    collection(refScheduleActivityData),
                    collection(refWalkinEnquiryData),
                    collection(refClientData),
                    collection(refClientSettings),
                ).pipe(
                    map(([pendinBucket, convertedLeads, lostPendingApproval,
                        openLeads, wonLeads, lostLeads, deliveredLeads, leadsBucketEnquiryData, leadsBucketEnquiryDataByDate, inboundLeads,
                        testdriveLeads, tradeinLeads,
                        doneActivity, scheduleActivity, walkins, clients, clientsSetting]) => {
                        return [
                            _.map(pendinBucket, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(convertedLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(lostPendingApproval, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(openLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(wonLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(lostLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(deliveredLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(leadsBucketEnquiryData, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(leadsBucketEnquiryDataByDate, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(inboundLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(testdriveLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(tradeinLeads, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(doneActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(scheduleActivity, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(walkins, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(clients, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                            _.map(clientsSetting, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                        ]
                    }))
                    .subscribe(allDocs => {
                        var _enquiries = [];
                        for (let i = 0; i < 10; i++) {
                            allDocs[i].forEach(el => {
                                if (!_enquiries.some(e => e.documentID === el.documentID)) {
                                    _enquiries.push({
                                        ...convertEnquiryVM({
                                            ...el,
                                            testdrives: allDocs[10].filter(a => a.enquiryID === el.documentID),
                                            tradeins: allDocs[11].filter(a => a.enquiryID === el.documentID),
                                            walkins: allDocs[14].filter(a => a.enquiryID === el.documentID),
                                            firstWalkinDate: allDocs[14].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[14].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                                        })
                                    })
                                }
                            });
                        }

                        allDocs[10].forEach(el => {//testdrive array
                            if (!_enquiries.some(e => e.documentID === el.enquiryID) && !_.isEmpty(el.enquiry) && !_.isEmpty(el.enquiry.addedDate)) {
                                _enquiries.push({
                                    ...convertEnquiryVM({
                                        ...el.enquiry,
                                        'documentID': el.enquiryID,
                                        testdrives: [el],
                                        walkins: allDocs[14].filter(a => a.enquiryID === el.documentID),
                                        firstWalkinDate: allDocs[14].filter(a => a.enquiryID === el.documentID).length > 0 ? _.orderBy(allDocs[14].filter(a => a.enquiryID === el.documentID), ['walkinDate'], ['asc'])[0].walkinDate : null,
                                    })
                                })
                            }
                        });
                        var _activities = []
                        allDocs[13].forEach(el => {
                            if (el.enquiryID) {
                                _activities.push(el)
                            }
                        });

                        setTestdrives(allDocs[10])
                        setTradeins(allDocs[11])
                        setFollowupActivities(_activities)
                        //setEnquiries(_enquiries)
                        //setContacts(allDocs[10])
                        setWalkins(allDocs[14])
                        setClient(allDocs[15] ? Object.assign({}, allDocs[15][0]) : {})
                        setClientSettings(allDocs[16] ? Object.assign({}, allDocs[16][0]) : {})
                        setTempData({
                            enquiries: _enquiries.filter(a => !_.isEmpty(a.contact)),
                            walkins: allDocs[14],
                            activities: _activities,
                            tradeins: allDocs[11]
                        })
                        setinclInboundLeadsinRpt(_inclInboundLeadsinRpt)
                        //if (allDocs[11].length === 0)
                        //    setLoader(false)
                    });
                return () => {
                    unionPipelines && unionPipelines.unsubscribe()
                }
            })
        }

    }, [firestoreDB])



    useEffect(() => {
        if (_.isEmpty(tempData))
            return

        async function getWalkinEnq(walkins, enquiries, activities, tradeins) {
            const promises = []
            const followupPromises = []
            const followupEnquiries = []

            walkins.forEach(el => {
                if (!enquiries.some(e => e.documentID === el.enquiryID)) {
                    promises.push(firestoreDB.firestore().doc(`enquiries/${el.enquiryID}`).get())
                }
            });
            _.uniq(_.map(activities, function (e) { return e.enquiryID })).forEach(el => {
                followupPromises.push(firestoreDB.firestore().doc(`enquiries/${el}`).get())
            });

            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && (inclInboundLeadsinRpt ? true : dataDoc.pipeline !== 'LeadsBucket')) {
                    enquiries.push({
                        ...dataDoc,
                        walkins: walkins.filter(a => a.enquiryID === snap.id),
                        firstWalkinDate: walkins.filter(a => a.enquiryID === snap.id).length > 0 ? _.orderBy(walkins.filter(a => a.enquiryID === snap.id), ['walkinDate'], ['asc'])[0].walkinDate : null,
                        tradeins: tradeins.filter(a => a.enquiryID === snap.id),
                    })
                }
            })

            const followupsnapshots = await Promise.all(followupPromises)
            followupsnapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && (inclInboundLeadsinRpt ? true : dataDoc.pipeline !== 'LeadsBucket')) {
                    followupEnquiries.push({
                        ...convertEnquiryVM({
                            ...dataDoc,
                            walkins: walkins.filter(a => a.enquiryID === snap.id),
                            // firstWalkinDate: walkins.filter(a => a.enquiryID === snap.id).length > 0 ? _.orderBy(walkins.filter(a => a.enquiryID === snap.id), ['walkinDate'], ['asc'])[0].walkinDate : null,

                        })
                    })
                }
            })

            let uniqEnquiries = _.uniqBy(enquiries, 'documentID')
            setEnquiries(uniqEnquiries)
            setFollowupEnquiries(
                followupEnquiries
                    .filter(a =>  
                        (!_.isEmpty(a.requirement) && a.requirement.make && a.requirement.model && a.requirement.saleType)
                    ||  (!_.isEmpty(a.requirement) && !_.isEmpty(a.requirement.stock) && a.requirement.stock.make && a.requirement.stock.model && a.requirement.stock.saleType))
            )
            setLoader(false)

        }
        //if (tempData.walkins.length > 0)
        getWalkinEnq(tempData.walkins, tempData.enquiries, tempData.activities, tempData.tradeins);
        // else
        //     setEnquiries(tempData.enquiries);


    }, [tempData])

    useEffect(() => {
        if (loader || _.isEmpty(client) || _.isEmpty(clientSettings))
            return

        // if (!clientSettings || _.isEmpty(clientSettings))
        //     return

        // console.log('enquiries in calc useEffect', pipelineEnquiries.length)
        // if (!_.isEmpty(client.weekStart)) {
        //     weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
        //     weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week').add(moment(_date).day(client.weekStart).day(), 'day')._d)
        // }
        let enqWithoutLeadsBucket = pipelineEnquiries.filter(enq => (inclInboundLeadsinRpt ? true : enq.pipeline !== 'LeadsBucket'))
        let enqWithReq = enqWithoutLeadsBucket.filter(enq => !_.isEmpty(enq.requirement))
        let bmwEnqNew = enqWithReq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make.toLowerCase().includes('bmw') && !salesType.toLowerCase().includes('preowned')) {
                returnVal = true
            }
            return returnVal
        })

        let bmwEnqUsed = enqWithReq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes('bmw')) {
                returnVal = true
            }
            return returnVal
        })

        let miniEnqNew = enqWithReq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned')) {
                returnVal = false
            } else if (make.toLowerCase().includes('mini') && !salesType.toLowerCase().includes('preowned')) {
                returnVal = true
            }
            return returnVal
        })

        let miniEnqUsed = enqWithReq.filter(enq => {
            let returnVal = false;
            let salesType = ''
            let make = ''
            if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.stock.saleType, '');
                make = enq.requirement.stock.make
            } else if (enq.requirement.make && enq.requirement.model) {
                salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                    enq.requirement.saleType, '');
                make = enq.requirement.make
            }
            if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes('mini')) {
                returnVal = true
            }
            return returnVal
        })

        let othersEnq = enqWithoutLeadsBucket.filter(enq => {
            let returnVal = false;
            let make = ''
            if (!_.isEmpty(enq.requirement)) {
                if (!_.isEmpty(enq.requirement.stock) && !_.isEmpty(enq.requirement.stock.make)) {
                    make = enq.requirement.stock.make
                } else if (enq.requirement.make && enq.requirement.model) {
                    make = enq.requirement.make
                }
                if (make.toLowerCase() === 'bmw' || make.toLowerCase() === 'mini') {
                    returnVal = false
                } else if (make) {
                    returnVal = true
                } else {
                    returnVal = false
                }
            } else {
                returnVal = false
            }
            return returnVal
        })

        let withoutReqEnq = enqWithoutLeadsBucket.filter(enq => {
            let returnVal = false;
            if (!_.isEmpty(enq.requirement)) {
                returnVal = false
            } else {
                returnVal = true
            }
            return returnVal
        })

        // console.log('othersEnq', othersEnq)
        let bmwCountsNew = {
            todayAllEnq: bmwEnqNew,
            weekAllEnq: bmwEnqNew,
            monthAllEnq: bmwEnqNew,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                let make = '';
                let salesType = '';
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                        make = a.enquiry.requirement.stock.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.stock.saleType, '');
                    } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                        make = a.enquiry.requirement.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.saleType, '');
                    } else {
                        returnVal = false
                    }
                    if (salesType.toLowerCase().includes('preowned')) {
                        returnVal = false
                    } else if (make.toLowerCase().includes('bmw')) {
                        returnVal = true
                    }
                } else {
                    returnVal = false
                }

                return returnVal
            }),
        }

        let bmwCountsUsed = {
            todayAllEnq: bmwEnqUsed,
            weekAllEnq: bmwEnqUsed,
            monthAllEnq: bmwEnqUsed,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                let make = '';
                let salesType = '';
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                        make = a.enquiry.requirement.stock.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.stock.saleType, '');
                    } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                        make = a.enquiry.requirement.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.saleType, '');
                    } else {
                        returnVal = false
                    }
                    if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes('bmw')) {
                        returnVal = true
                    }
                } else {
                    returnVal = false
                }

                return returnVal
            }),
        }

        let miniCountsNew = {
            todayAllEnq: miniEnqNew,
            weekAllEnq: miniEnqNew,
            monthAllEnq: miniEnqNew,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                let make = '';
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                        make = a.enquiry.requirement.stock.make.toLowerCase()
                    } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                        make = a.enquiry.requirement.make.toLowerCase()
                    } else {
                        returnVal = false
                    }
                    if (make.toLowerCase().includes('mini')) {
                        returnVal = true
                    }
                } else {
                    returnVal = false
                }

                return returnVal
            }),
        }

        let miniCountsUsed = {
            todayAllEnq: miniEnqUsed,
            weekAllEnq: miniEnqUsed,
            monthAllEnq: miniEnqUsed,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                let make = '';
                let salesType = '';
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                        make = a.enquiry.requirement.stock.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.stock.saleType, '');
                    } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                        make = a.enquiry.requirement.make.toLowerCase()
                        salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                            a.enquiry.requirement.saleType, '');
                    } else {
                        returnVal = false
                    }
                    if (salesType.toLowerCase().includes('preowned') && make.toLowerCase().includes('mini')) {
                        returnVal = true
                    }
                } else {
                    returnVal = false
                }

                return returnVal
            }),
        }

        let othersCountsAll = {
            todayAllEnq: othersEnq,
            weekAllEnq: othersEnq,
            monthAllEnq: othersEnq,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                let make = '';
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    if (!_.isEmpty(a.enquiry.requirement.stock) && !_.isEmpty(a.enquiry.requirement.stock.make)) {
                        make = a.enquiry.requirement.stock.make.toLowerCase()
                    } else if (a.enquiry.requirement.make && a.enquiry.requirement.model) {
                        make = a.enquiry.requirement.make.toLowerCase()
                    } else {
                        returnVal = false
                    }
                    if (make.toLowerCase() === 'bmw' || make.toLowerCase() === 'mini') {
                        returnVal = false
                    } else if (make) {
                        returnVal = true
                    } else {
                        returnVal = false
                    }
                } else {
                    returnVal = false
                }

                return returnVal
            }),
        }

        let withoutReqCountsAll = {
            todayAllEnq: withoutReqEnq,
            weekAllEnq: withoutReqEnq,
            monthAllEnq: withoutReqEnq,
            eligibleTestdrives: mtdTestdrives.filter(a => {
                let returnVal = false;
                if (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement)) {
                    returnVal = false
                } else {
                    returnVal = true
                }
                return returnVal
            }),
        }

        bmwCountsNew = {
            ...bmwCountsNew,
            todayNew: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: bmwCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: bmwCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: bmwCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: bmwCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: bmwCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: bmwCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: bmwCountsNew.todayAllEnq.filter(a => a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: bmwCountsNew.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: bmwCountsNew.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: bmwCountsNew.todayAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: bmwCountsNew.weekAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: bmwCountsNew.monthAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        bmwCountsUsed = {
            ...bmwCountsUsed,
            todayNew: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: bmwCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: bmwCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: bmwCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: bmwCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: bmwCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: bmwCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: bmwCountsUsed.todayAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: bmwCountsUsed.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: bmwCountsUsed.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: bmwCountsUsed.todayAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: bmwCountsUsed.weekAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: bmwCountsUsed.monthAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        miniCountsNew = {
            ...miniCountsNew,
            todayNew: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: miniCountsNew.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: miniCountsNew.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: miniCountsNew.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: miniCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: miniCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: miniCountsNew.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: miniCountsNew.todayAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: miniCountsNew.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: miniCountsNew.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: miniCountsNew.todayAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: miniCountsNew.weekAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: miniCountsNew.monthAllEnq.filter(a =>
            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        miniCountsUsed = {
            ...miniCountsUsed,
            todayNew: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: miniCountsUsed.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: miniCountsUsed.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: miniCountsUsed.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: miniCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: miniCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: miniCountsUsed.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: miniCountsUsed.todayAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: miniCountsUsed.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: miniCountsUsed.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: miniCountsUsed.todayAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: miniCountsUsed.weekAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: miniCountsUsed.monthAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),

        }

        othersCountsAll = {
            ...othersCountsAll,
            todayNew: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: othersCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: othersCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: othersCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: othersCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: othersCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: othersCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: othersCountsAll.todayAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: othersCountsAll.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: othersCountsAll.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: othersCountsAll.todayAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: othersCountsAll.weekAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: othersCountsAll.monthAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        withoutReqCountsAll = {
            ...withoutReqCountsAll,
            todayNew: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekNew: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthNew: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayOpen: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.todayStart.seconds
                && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            weekOpen: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.weekStart.seconds
                && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            monthOpen: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'open'
                && !_.isEmpty(a.addedDate)
                && a.addedDate.seconds >= dateRange.monthStart.seconds
                && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            todayExisting: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.todayStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.todayStart.seconds
                    && a.wonDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.todayStart.seconds
                        && a.lostDate.seconds <= dateRange.todayEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.todayStart.seconds
                        && item.startDate.seconds <= dateRange.todayEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                        && item.walkinDate.seconds <= dateRange.todayEnd.seconds)))),
            weekExisting: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.weekStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.weekStart.seconds
                    && a.wonDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.weekStart.seconds
                        && a.lostDate.seconds <= dateRange.weekEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.weekStart.seconds
                        && item.startDate.seconds <= dateRange.weekEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                        && item.walkinDate.seconds <= dateRange.weekEnd.seconds)))),
            monthExisting: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.addedDate)
                && a.addedDate.seconds < dateRange.monthStart.seconds
                && (((a.status === 'won' || a.status === 'delivered') && !_.isEmpty(a.wonDate) && a.wonDate.seconds >= dateRange.monthStart.seconds
                    && a.wonDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.status === 'lost' && !_.isEmpty(a.lostDate) && a.lostDate.seconds >= dateRange.monthStart.seconds
                        && a.lostDate.seconds <= dateRange.monthEnd.seconds) ||
                    (a.testdrives && a.testdrives.some(item => item.startDate.seconds >= dateRange.monthStart.seconds
                        && item.startDate.seconds <= dateRange.monthEnd.seconds)) ||
                    (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                        && item.walkinDate.seconds <= dateRange.monthEnd.seconds)))),
            todayWon: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
            todayDelivered: withoutReqCountsAll.todayAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.todayStart.seconds
                && a.deliveredDate.seconds <= dateRange.todayEnd.seconds),
            weekDelivered: withoutReqCountsAll.weekAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.weekStart.seconds
                && a.deliveredDate.seconds <= dateRange.weekEnd.seconds),
            monthDelivered: withoutReqCountsAll.monthAllEnq.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'delivered'
                && !_.isEmpty(a.deliveredDate)
                && a.deliveredDate.seconds >= dateRange.monthStart.seconds
                && a.deliveredDate.seconds <= dateRange.monthEnd.seconds),
            todayTestdrive: withoutReqCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.todayStart.seconds
                && a.startDate.seconds <= dateRange.todayEnd.seconds),
            weekTestdrive: withoutReqCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.weekStart.seconds
                && a.startDate.seconds <= dateRange.weekEnd.seconds),
            monthTestdrive: withoutReqCountsAll.eligibleTestdrives.filter(a => !_.isEmpty(a.startDate)
                && a.startDate.seconds >= dateRange.monthStart.seconds
                && a.startDate.seconds <= dateRange.monthEnd.seconds),
            todayTradein: withoutReqCountsAll.todayAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.todayStart.seconds
                    && item.addedDate.seconds <= dateRange.todayEnd.seconds
                )),
            weekTradein: withoutReqCountsAll.weekAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.weekStart.seconds
                    && item.addedDate.seconds <= dateRange.weekEnd.seconds
                )),
            monthTradein: withoutReqCountsAll.monthAllEnq.filter(a =>
                a.isTradeIn === true
                && a.tradeins && a.tradeins.some(item => item.addedDate.seconds >= dateRange.monthStart.seconds
                    && item.addedDate.seconds <= dateRange.monthEnd.seconds
                )),
            todayWalkin: withoutReqCountsAll.todayAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.todayStart.seconds
                && item.walkinDate.seconds <= dateRange.todayEnd.seconds))),
            weekWalkin: withoutReqCountsAll.weekAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.weekStart.seconds
                && item.walkinDate.seconds <= dateRange.weekEnd.seconds))),
            monthWalkin: withoutReqCountsAll.monthAllEnq.filter(a =>

            (a.walkins && a.walkins.some(item => item.walkinDate.seconds >= dateRange.monthStart.seconds
                && item.walkinDate.seconds <= dateRange.monthEnd.seconds))),
        }

        let uniqEnquiries = _.uniqBy(pipelineEnquiries, 'documentID')

        let inboundCountsAll = {
            todayNew: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && a.pipeline === "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
                ...uniqEnquiries.filter(a =>
                    a.pipeline !== 'LeadsBucket'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            ], 'documentID'),
            weekNew: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && a.pipeline === "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
                ...uniqEnquiries.filter(a =>
                    a.pipeline !== 'LeadsBucket'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            ], 'documentID'),
            monthNew: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && a.pipeline === "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
                ...uniqEnquiries.filter(a =>
                    a.pipeline !== 'LeadsBucket'
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            ], 'documentID'),
            todayPending: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && a.pipeline === "LeadsBucket"
                    && !_.isEmpty(a.stageDate)
                    && a.stageDate.seconds >= dateRange.todayStart.seconds
                    && a.stageDate.seconds <= dateRange.todayEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                    && a.addedDate.seconds <= dateRange.todayEnd.seconds),
            ], 'documentID'),
            weekPending: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && a.pipeline === "LeadsBucket"
                    && !_.isEmpty(a.stageDate)
                    && a.stageDate.seconds >= dateRange.weekStart.seconds
                    && a.stageDate.seconds <= dateRange.weekEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.weekStart.seconds
                    && a.addedDate.seconds <= dateRange.weekEnd.seconds),
            ], 'documentID'),
            // _.uniqBy([], 'documentID'),
            monthPending: _.uniqBy([
                ...uniqEnquiries.filter(a => a.stage !== 'Archived'
                    && a.pipeline === "LeadsBucket"
                    && a.status !== 'prospectLost'
                    && a.status !== 'lost'
                    && !_.isEmpty(a.stageDate)
                    && a.stageDate.seconds >= dateRange.monthStart.seconds
                    && a.stageDate.seconds <= dateRange.monthEnd.seconds),
                ...uniqEnquiries.filter(a => a.status === 0
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= dateRange.monthStart.seconds
                    && a.addedDate.seconds <= dateRange.monthEnd.seconds),
            ], 'documentID'),
            todayConverted: uniqEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.todayStart.seconds
                && a.convertedDate.seconds <= dateRange.todayEnd.seconds),
            weekConverted: uniqEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.weekStart.seconds
                && a.convertedDate.seconds <= dateRange.weekEnd.seconds),
            monthConverted: uniqEnquiries.filter(a =>
                a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.monthStart.seconds
                && a.convertedDate.seconds <= dateRange.monthEnd.seconds),
            todayShow: _.uniqBy(uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'show')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.firstWalkinDate)
                && a.firstWalkinDate.seconds >= dateRange.todayStart.seconds
                && a.firstWalkinDate.seconds <= dateRange.todayEnd.seconds), 'documentID'),
            weekShow: _.uniqBy(uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'show')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.firstWalkinDate)
                && a.firstWalkinDate.seconds >= dateRange.weekStart.seconds
                && a.firstWalkinDate.seconds <= dateRange.weekEnd.seconds), 'documentID'),
            monthShow: _.uniqBy(uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'show')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.firstWalkinDate)
                && a.firstWalkinDate.seconds >= dateRange.monthStart.seconds
                && a.firstWalkinDate.seconds <= dateRange.monthEnd.seconds), 'documentID'),
            todayNoShow: uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'noshow')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.todayStart.seconds
                && a.convertedDate.seconds <= dateRange.todayEnd.seconds),
            weekNoShow: uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'noshow')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.weekStart.seconds
                && a.convertedDate.seconds <= dateRange.weekEnd.seconds),
            monthNoShow: uniqEnquiries.filter(a => !_.isEmpty(a.bdcStatus) && (a.bdcStatus === 'noshow')
                && a.pipeline !== 'LeadsBucket'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && a.convertedDate.seconds >= dateRange.monthStart.seconds
                && a.convertedDate.seconds <= dateRange.monthEnd.seconds),
            todayWon: uniqEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.todayStart.seconds
                && a.wonDate.seconds <= dateRange.todayEnd.seconds),
            weekWon: uniqEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.weekStart.seconds
                && a.wonDate.seconds <= dateRange.weekEnd.seconds),
            monthWon: uniqEnquiries.filter(a => !_.isEmpty(a.status) && (a.status.toLowerCase() === 'won' || a.status.toLowerCase() === 'delivered')
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.wonDate)
                && a.wonDate.seconds >= dateRange.monthStart.seconds
                && a.wonDate.seconds <= dateRange.monthEnd.seconds),
            todayLost: uniqEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.todayStart.seconds
                && a.lostDate.seconds <= dateRange.todayEnd.seconds),
            weekLost: uniqEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.weekStart.seconds
                && a.lostDate.seconds <= dateRange.weekEnd.seconds),
            monthLost: uniqEnquiries.filter(a => !_.isEmpty(a.status) && a.status.toLowerCase() === 'lost'
                && a.isConverted
                && !_.isEmpty(a.convertedDate)
                && !_.isEmpty(a.lostDate)
                && a.lostDate.seconds >= dateRange.monthStart.seconds
                && a.lostDate.seconds <= dateRange.monthEnd.seconds),
        }

        setBmwNewCounts(bmwCountsNew)
        setMiniNewCounts(miniCountsNew)
        setBmwUsedCounts(bmwCountsUsed)
        setMiniUsedCounts(miniCountsUsed)
        setWithoutReqCounts(withoutReqCountsAll)
        setOthersCounts(othersCountsAll)
        setInboundCounts(inboundCountsAll)

        setCountsLoader(false)

    }, [loader, client, clientSettings])

    const convertEnquiryVM = (doc) => {
        let listVM = { ...doc };

        if (!_.isEmpty(doc.contact)) {
            listVM.contactName = CommonHelper.displayFullContact([], doc.contact);
            listVM.contactNumber = doc.contact.displayID;
            listVM.phone = CommonHelper.getFullPhone(doc.contact.phoneCode, doc.contact.phone);
            listVM.email = doc.contact.email;
        }

        if (!_.isEmpty(doc.requirement)) {
            listVM.vehicleModelDescription = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make + ' ' + doc.requirement.stock.model : doc.requirement.make + ' ' + doc.requirement.model
            listVM.vehicleMake = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.make : doc.requirement.make
            listVM.vehicleModel = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.model : doc.requirement.model
            listVM.vehicleYear = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.year : doc.requirement.year;
            listVM.vehicleSaletype = doc.requirement.saleType;
            listVM.vehicleBodytype = !_.isEmpty(doc.requirement.stock) ? doc.requirement.stock.bodyType : doc.requirement.bodyType;
        }
        if (!_.isEmpty(doc.testdrives)) {
            listVM.testdrive = _.orderBy(Object.assign([], doc.testdrives), ['addedDate'], ['asc'])[0];
            listVM.testdriveModelDescription = !_.isEmpty(listVM.testdrive.stock) ? listVM.testdrive.stock.make + ' ' + listVM.testdrive.stock.model : ''
            listVM.testdriveSaletype = !_.isEmpty(listVM.testdrive.stock) ? listVM.testdrive.stock.saleType : ''
        }

        if (!_.isEmpty(doc.tradeins)) {
            listVM.tradein = _.orderBy(Object.assign([], doc.tradeins), ['addedDate'], ['asc'])[0];
            listVM.tradeinModelDescription = !_.isEmpty(listVM.tradein) ? listVM.tradein.make + ' ' + listVM.tradein.model : ''
            listVM.tradeinYear = !_.isEmpty(listVM.tradein) ? listVM.tradein.year : '';
            listVM.tradeinSaletype = !_.isEmpty(listVM.stock) ? listVM.tradein.saleType : '';
        }

        listVM.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.convertedOn = doc.convertedDate ? moment.unix(doc.convertedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.enquiryStatus = doc.status ? (<span className={`mrg-fixstatus badge-${doc.status === 'prospectLost' ? 'lost' : doc.status.toLowerCase()}`}>{doc.status === 'prospectLost' ? 'PROSPECT LOST' : doc.status}</span>) : '';
        return listVM
    }

    return (
        <>
            {
                !countsLoader && !_.isEmpty(client) && !_.isEmpty(clientSettings)
                    ?
                    <>
                        <div className="A4 landscape report-data-loaded report-data-load">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {`DAILY ACTIVITY REPORT ${client ? ' - ' + client.name.toUpperCase() : ''}`} <br></br>
                                                    <span> {moment(dateRange.date).format('DD MMMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">
                                    <div className="table-head">BMW NEW</div>
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Existing Leads</th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%">Trade In </th>
                                                <th width="10%">Sold</th>
                                                <th width="10%">Lost</th>
                                                <th width="10%">Walkin </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{counts.todayNew.length}</td>
                                                <td className="count-large">{counts.todayExisting.length}</td>
                                                <td className="count-large">{counts.todayTestdrive.length}</td>
                                                <td className="count-large">{counts.todayTradein.length}</td>
                                                <td className="count-large">{counts.todayWon.length}</td>
                                                <td className="count-large">{counts.todayLost.length}</td>
                                                <td className="count-large">{counts.todayWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{counts.weekNew.length}</td>
                                                <td className="count-large">{counts.weekExisting.length}</td>
                                                <td className="count-large">{counts.weekTestdrive.length}</td>
                                                <td className="count-large">{counts.weekTradein.length}</td>
                                                <td className="count-large">{counts.weekWon.length}</td>
                                                <td className="count-large">{counts.weekLost.length}</td>
                                                <td className="count-large">{counts.weekWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{counts.monthNew.length}</td>
                                                <td className="count-large">{counts.monthExisting.length}</td>
                                                <td className="count-large">{counts.monthTestdrive.length}</td>
                                                <td className="count-large">{counts.monthTradein.length}</td>
                                                <td className="count-large">{counts.monthWon.length}</td>
                                                <td className="count-large">{counts.monthLost.length}</td>
                                                <td className="count-large">{counts.monthWalkin.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="table-head">MINI NEW</div>
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Existing Leads</th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%"> Trade In </th>
                                                <th width="10%">Sold</th>
                                                <th width="10%">Lost</th>
                                                <th width="10%"> Walkin </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{miniNewCounts.todayNew.length}</td>
                                                <td className="count-large">{miniNewCounts.todayExisting.length}</td>
                                                <td className="count-large">{miniNewCounts.todayTestdrive.length}</td>
                                                <td className="count-large">{miniNewCounts.todayTradein.length}</td>
                                                <td className="count-large">{miniNewCounts.todayWon.length}</td>
                                                <td className="count-large">{miniNewCounts.todayLost.length}</td>
                                                <td className="count-large">{miniNewCounts.todayWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{miniNewCounts.weekNew.length}</td>
                                                <td className="count-large">{miniNewCounts.weekExisting.length}</td>
                                                <td className="count-large">{miniNewCounts.weekTestdrive.length}</td>
                                                <td className="count-large">{miniNewCounts.weekTradein.length}</td>
                                                <td className="count-large">{miniNewCounts.weekWon.length}</td>
                                                <td className="count-large">{miniNewCounts.weekLost.length}</td>
                                                <td className="count-large">{miniNewCounts.weekWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{miniNewCounts.monthNew.length}</td>
                                                <td className="count-large">{miniNewCounts.monthExisting.length}</td>
                                                <td className="count-large">{miniNewCounts.monthTestdrive.length}</td>
                                                <td className="count-large">{miniNewCounts.monthTradein.length}</td>
                                                <td className="count-large">{miniNewCounts.monthWon.length}</td>
                                                <td className="count-large">{miniNewCounts.monthLost.length}</td>
                                                <td className="count-large">{miniNewCounts.monthWalkin.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div
                                        className="report-outline-box"
                                        style={{
                                            padding: '5px 20px 5px 20px',
                                            border: '1px solid #ccc',
                                            display: 'inline-block',
                                            width: '100%',
                                        }}
                                    >
                                        <div className="table-head">BMW USED</div>
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="10%">&nbsp;</th>
                                                    <th width="10%">New Leads</th>
                                                    <th width="10%">Existing Leads</th>
                                                    <th width="10%">Test Drive  </th>
                                                    <th width="10%"> Trade In </th>
                                                    <th width="10%">Sold</th>
                                                    <th width="10%">Lost</th>
                                                    <th width="10%"> Walkin </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">TODAY</td>
                                                    <td className="count-large">{bmwUsedCounts.todayNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS WEEK</td>
                                                    <td className="count-large">{bmwUsedCounts.weekNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS MONTH</td>
                                                    <td className="count-large">{bmwUsedCounts.monthNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthWalkin.length}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="table-head">MINI USED</div>
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="10%">&nbsp;</th>
                                                    <th width="10%">New Leads</th>
                                                    <th width="10%">Existing Leads</th>
                                                    <th width="10%">Test Drive  </th>
                                                    <th width="10%"> Trade In </th>
                                                    <th width="10%">Sold</th>
                                                    <th width="10%">Lost</th>
                                                    <th width="10%"> Walkin </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">TODAY</td>
                                                    <td className="count-large">{miniUsedCounts.todayNew.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayExisting.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayTestdrive.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayTradein.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayWon.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayLost.length}</td>
                                                    <td className="count-large">{miniUsedCounts.todayWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS WEEK</td>
                                                    <td className="count-large">{miniUsedCounts.weekNew.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekExisting.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekTestdrive.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekTradein.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekWon.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekLost.length}</td>
                                                    <td className="count-large">{miniUsedCounts.weekWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS MONTH</td>
                                                    <td className="count-large">{miniUsedCounts.monthNew.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthExisting.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthTestdrive.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthTradein.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthWon.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthLost.length}</td>
                                                    <td className="count-large">{miniUsedCounts.monthWalkin.length}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="table-head">OTHERS USED</div>
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="10%">&nbsp;</th>
                                                    <th width="10%">New Leads</th>
                                                    <th width="10%">Existing Leads</th>
                                                    <th width="10%">Test Drive  </th>
                                                    <th width="10%"> Trade In </th>
                                                    <th width="10%">Sold</th>
                                                    <th width="10%">Lost</th>
                                                    <th width="10%"> Walkin </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">TODAY</td>
                                                    <td className="count-large">{othersCounts.todayNew.length}</td>
                                                    <td className="count-large">{othersCounts.todayExisting.length}</td>
                                                    <td className="count-large">{othersCounts.todayTestdrive.length}</td>
                                                    <td className="count-large">{othersCounts.todayTradein.length}</td>
                                                    <td className="count-large">{othersCounts.todayWon.length}</td>
                                                    <td className="count-large">{othersCounts.todayLost.length}</td>
                                                    <td className="count-large">{othersCounts.todayWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS WEEK</td>
                                                    <td className="count-large">{othersCounts.weekNew.length}</td>
                                                    <td className="count-large">{othersCounts.weekExisting.length}</td>
                                                    <td className="count-large">{othersCounts.weekTestdrive.length}</td>
                                                    <td className="count-large">{othersCounts.weekTradein.length}</td>
                                                    <td className="count-large">{othersCounts.weekWon.length}</td>
                                                    <td className="count-large">{othersCounts.weekLost.length}</td>
                                                    <td className="count-large">{othersCounts.weekWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS MONTH</td>
                                                    <td className="count-large">{othersCounts.monthNew.length}</td>
                                                    <td className="count-large">{othersCounts.monthExisting.length}</td>
                                                    <td className="count-large">{othersCounts.monthTestdrive.length}</td>
                                                    <td className="count-large">{othersCounts.monthTradein.length}</td>
                                                    <td className="count-large">{othersCounts.monthWon.length}</td>
                                                    <td className="count-large">{othersCounts.monthLost.length}</td>
                                                    <td className="count-large">{othersCounts.monthWalkin.length}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="table-head">TOTAL USED</div>
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="10%">&nbsp;</th>
                                                    <th width="10%">New Leads</th>
                                                    <th width="10%">Existing Leads</th>
                                                    <th width="10%">Test Drive  </th>
                                                    <th width="10%"> Trade In </th>
                                                    <th width="10%">Sold</th>
                                                    <th width="10%">Lost</th>
                                                    <th width="10%"> Walkin </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">TODAY</td>
                                                    <td className="count-large">{bmwUsedCounts.todayNew.length + miniUsedCounts.todayNew.length + othersCounts.todayNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayExisting.length + miniUsedCounts.todayExisting.length + othersCounts.todayExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayTestdrive.length + miniUsedCounts.todayTestdrive.length + othersCounts.todayTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayTradein.length + miniUsedCounts.todayTradein.length + othersCounts.todayTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayWon.length + miniUsedCounts.todayWon.length + othersCounts.todayWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayLost.length + miniUsedCounts.todayLost.length + othersCounts.todayLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.todayWalkin.length + miniUsedCounts.todayWalkin.length + othersCounts.todayWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS WEEK</td>
                                                    <td className="count-large">{bmwUsedCounts.weekNew.length + miniUsedCounts.weekNew.length + othersCounts.weekNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekExisting.length + miniUsedCounts.weekExisting.length + othersCounts.weekExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekTestdrive.length + miniUsedCounts.weekTestdrive.length + othersCounts.weekTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekTradein.length + miniUsedCounts.weekTradein.length + othersCounts.weekTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekWon.length + miniUsedCounts.weekWon.length + othersCounts.weekWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekLost.length + miniUsedCounts.weekLost.length + othersCounts.weekLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.weekWalkin.length + miniUsedCounts.weekWalkin.length + othersCounts.weekWalkin.length}</td>
                                                </tr>
                                                <tr>
                                                    <td className="td-head">THIS MONTH</td>
                                                    <td className="count-large">{bmwUsedCounts.monthNew.length + miniUsedCounts.monthNew.length + othersCounts.monthNew.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthExisting.length + miniUsedCounts.monthExisting.length + othersCounts.monthExisting.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthTestdrive.length + miniUsedCounts.monthTestdrive.length + othersCounts.monthTestdrive.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthTradein.length + miniUsedCounts.monthTradein.length + othersCounts.monthTradein.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthWon.length + miniUsedCounts.monthWon.length + othersCounts.monthWon.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthLost.length + miniUsedCounts.monthLost.length + othersCounts.monthLost.length}</td>
                                                    <td className="count-large">{bmwUsedCounts.monthWalkin.length + miniUsedCounts.monthWalkin.length + othersCounts.monthWalkin.length}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="table-head">LEADS CREATED WITHOUT VEHICLE MODEL REQUIREMENT</div>
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                <th className="cell-head" width="10%">&nbsp;</th>
                                                <th width="10%">New Leads</th>
                                                <th width="10%">Existing Leads</th>
                                                <th width="10%">Test Drive  </th>
                                                <th width="10%"> Trade In </th>
                                                <th width="10%">Sold</th>
                                                <th width="10%">Lost</th>
                                                <th width="10%"> Walkin </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td-head">TODAY</td>
                                                <td className="count-large">{withoutReqCounts.todayNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayExisting.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.todayWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS WEEK</td>
                                                <td className="count-large">{withoutReqCounts.weekNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekExisting.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.weekWalkin.length}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-head">THIS MONTH</td>
                                                <td className="count-large">{withoutReqCounts.monthNew.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthExisting.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthTestdrive.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthTradein.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthWon.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthLost.length}</td>
                                                <td className="count-large">{withoutReqCounts.monthWalkin.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    {inclInboundLeadsinRpt ? (
                                        <>
                                            <div className="table-head">INBOUND LEADS</div>
                                            <table className="table-loop">
                                                <thead>
                                                    <tr>
                                                        <th className="cell-head" width="10%">&nbsp;</th>
                                                        <th width="10%">New Leads</th>
                                                        <th width="10%">Pending Leads</th>
                                                        <th width="10%">Converted Leads  </th>
                                                        <th width="10%">Show </th>
                                                        <th width="10%">No Show</th>
                                                        <th width="10%">Won</th>
                                                        <th width="10%">Lost </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="td-head">TODAY</td>
                                                        <td className="count-large">{inboundCounts.todayNew.length}</td>
                                                        <td className="count-large">{inboundCounts.todayPending.length}</td>
                                                        <td className="count-large">{inboundCounts.todayConverted.length}</td>
                                                        <td className="count-large">{inboundCounts.todayShow.length}</td>
                                                        <td className="count-large">{inboundCounts.todayNoShow.length}</td>
                                                        <td className="count-large">{inboundCounts.todayWon.length}</td>
                                                        <td className="count-large">{inboundCounts.todayLost.length}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-head">THIS WEEK</td>
                                                        <td className="count-large">{inboundCounts.weekNew.length}</td>
                                                        <td className="count-large">{inboundCounts.weekPending.length}</td>
                                                        <td className="count-large">{inboundCounts.weekConverted.length}</td>
                                                        <td className="count-large">{inboundCounts.weekShow.length}</td>
                                                        <td className="count-large">{inboundCounts.weekNoShow.length}</td>
                                                        <td className="count-large">{inboundCounts.weekWon.length}</td>
                                                        <td className="count-large">{inboundCounts.weekLost.length}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td-head">THIS MONTH</td>
                                                        <td className="count-large">{inboundCounts.monthNew.length}</td>
                                                        <td className="count-large">{inboundCounts.monthPending.length}</td>
                                                        <td className="count-large">{inboundCounts.monthConverted.length}</td>
                                                        <td className="count-large">{inboundCounts.monthShow.length}</td>
                                                        <td className="count-large">{inboundCounts.monthNoShow.length}</td>
                                                        <td className="count-large">{inboundCounts.monthWon.length}</td>
                                                        <td className="count-large">{inboundCounts.monthLost.length}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    ) : (<></>)}


                                    <div className="table-head" style={{ fontSize: '16px', marginBottom: '1rem', }}>TODAY'S ENQUIRIES</div>
                                    <div className="table-head">BMW NEW</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                counts.todayNew && counts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }



                                        </tbody>
                                    </table>

                                    <div className="table-head">MINI NEW</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                miniNewCounts.todayNew && miniNewCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }


                                        </tbody>
                                    </table>

                                    <div className="table-head">BMW USED</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bmwUsedCounts.todayNew && bmwUsedCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }



                                        </tbody>
                                    </table>

                                    <div className="table-head">MINI USED</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                miniUsedCounts.todayNew && miniUsedCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }



                                        </tbody>
                                    </table>

                                    <div className="table-head">OTHERS USED</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                othersCounts.todayNew && othersCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }


                                        </tbody>
                                    </table>

                                    <div className="table-head">LEADS CREATED WITHOUT VEHICLE MODEL REQUIREMENT</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                withoutReqCounts.todayNew && withoutReqCounts.todayNew.filter(a => !_.isEmpty(a.addedDate)
                                                    && a.addedDate.seconds >= dateRange.todayStart.seconds
                                                    && a.addedDate.seconds <= dateRange.todayEnd.seconds).map((data, index) => {
                                                        return (<tr key={index}>
                                                            <td valign="top" align="left">
                                                                {/* {console.log(data)} */}
                                                                <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                                {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                            </td>

                                                            <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                                {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                            </td>

                                                            <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                            </td>

                                                            <td>{data.createdOn ? data.createdOn : '--'}
                                                                {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                            </td>

                                                            <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                            </td>

                                                            <td className="align-center">
                                                                {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                                {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                            </td>
                                                        </tr>)
                                                    })
                                            }


                                        </tbody>
                                    </table>

                                    {inclInboundLeadsinRpt ? (
                                        <>
                                            <div className="table-head">INBOUND LEADS</div>
                                            <table className="table-loop table-sub-fix">
                                                <thead>
                                                    <tr>
                                                        <th width="20%">CONTACT DETAILS</th>
                                                        <th width="15%">REQUIREMENTS</th>
                                                        <th width="15%">TEST DRIVE</th>
                                                        <th width="15%">TRADE-IN </th>
                                                        <th width="12%">STARTED ON</th>
                                                        <th width="12%">UPDATED ON </th>
                                                        <th width="10%" className="align-center"> STATUS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        inboundCounts.todayNew && inboundCounts.todayNew
                                                            .map((data, index) => {
                                                                return (<tr key={index}>
                                                                    <td valign="top" align="left">
                                                                        {/* {console.log(data)} */}
                                                                        <div style={{ display: 'flex' }}><strong>{data.contactName ? data.contactName : '--'} </strong> {data.systemType ? (<div className="mrg-fixstatus badge-open ml-1">{data.systemType}</div>) : !_.isEmpty(data.tags) ? (<div>{data.tags.map((a, i) => (<div className={`mrg-fixstatus badge-open ml-1`}>{a.type}</div>))}</div>) : (<></>)} </div>
                                                                        {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                                        {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                                        {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                                    </td>

                                                                    <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                                        {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                                    </td>

                                                                    <td>{data.isTestDrive}{'--'}
                                                                        {data.testdriveSaletype ? <></> : <></>}
                                                                    </td>

                                                                    <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                                        {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                                    </td>

                                                                    <td>{data.createdOn ? data.createdOn : '--'}
                                                                        {/* {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>} */}
                                                                    </td>

                                                                    <td>{data.updatedOn ? data.updatedOn : '--'}
                                                                        {/* {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>} */}
                                                                    </td>

                                                                    <td className="align-center">
                                                                        {data.enquiryStatus ? data.enquiryStatus : (<span className="mrg-fixstatus badge-lost" style={{ background: '#ff9e3e', border: '#ff9e3e' }}>PENDING</span>)}
                                                                        {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                                    </td>
                                                                </tr>)
                                                            })
                                                    }


                                                </tbody>
                                            </table>
                                        </>
                                    ) : (<></>)}


                                    <div className="table-head">OUTSTANDING FOLLOW UPS</div>
                                    <table className="table-loop table-sub-fix">
                                        <thead>
                                            <tr>
                                                <th width="20%">CONTACT DETAILS</th>
                                                <th width="15%">REQUIREMENTS</th>
                                                <th width="15%">TEST DRIVE</th>
                                                <th width="15%">TRADE-IN </th>
                                                <th width="12%">STARTED ON</th>
                                                <th width="12%">UPDATED ON </th>
                                                <th width="10%" className="align-center"> STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                followupEnquiries && followupEnquiries.map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td valign="top" align="left">
                                                            {/* {console.log(data)} */}
                                                            <span><strong>{data.contactName ? data.contactName : '--'} </strong></span>
                                                            {data.contactNumber ? <span className="sub-txt">{data.contactNumber}</span> : <></>}
                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                        </td>

                                                        <td>{data.vehicleModelDescription ? data.vehicleModelDescription : '--'}
                                                            {data.vehicleSaletype ? <span className="sub-txt">{data.vehicleSaletype}</span> : <></>}
                                                        </td>

                                                        <td>{data.isTestDrive}{data.testdriveModelDescription ? data.testdriveModelDescription : '--'}
                                                            {data.testdriveSaletype ? <span className="sub-txt">{data.testdriveSaletype}</span> : <></>}
                                                        </td>

                                                        <td>{data.isTradeIn}{data.tradeinModelDescription ? data.tradeinModelDescription : '--'}
                                                            {data.tradeinYear ? <span className="sub-txt">{data.tradeinYear}</span> : <></>}
                                                        </td>

                                                        <td>{data.createdOn ? data.createdOn : '--'}
                                                            {data.addedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.addedBy)}</span> : <></>}
                                                        </td>

                                                        <td>{data.updatedOn ? data.updatedOn : '--'}
                                                            {data.modifiedBy ? <span className="sub-txt">{CommonHelper.getUserNamebyId(clientSettings ? clientSettings.users : [], data.modifiedBy)}</span> : <></>}
                                                        </td>

                                                        <td className="align-center">
                                                            {data.enquiryStatus ? data.enquiryStatus : '--'}
                                                            {data.displayID ? <span className="sub-txt">{data.displayID}</span> : <></>}
                                                        </td>
                                                    </tr>)
                                                })
                                            }


                                        </tbody>
                                    </table>
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default DailyActivityReport;