/** LIBRARIES */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allcompanyFields, objCompany } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import FilterPanel from './filter'

//import { default as _images } from '../../images';
import AddCompany from './add'
import TransferOwner from '../common/transfer'
import ReAuthenticate from '../common/reAuthenticate';
import CompanyListReducer from './companyListReducer'
import { firestoreDB } from '../../services/helper';

const CompanyList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [companys, dispatch] = useReducer(CompanyListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [sortName, setSortName] = useState(localStorage.companySortName ? localStorage.companySortName : 'name')
    const [sortOrder, setSortOrder] = useState(localStorage.companySortOrder ? localStorage.companySortOrder : 'asc')
    const [hasMore, setHasMoreData] = useState([])
    const [companyFields, setCompanyFields] = useState(props.dealersettings.companyFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [isReadOnlyContact, setReadOnlyContact] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [companyLoader, setCompanyLoader] = useState(true)
    const pageLimit = 50

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})

    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [csvHeader, setHeader] = useState([])

    const [searchText, setSearchText] = useState(localStorage.companySearchText ? localStorage.companySearchText : '');
    const [filterClient, setFilterClient] = useState()
    const [contactCount, setContactCount] = useState(0);

    const [myContactsOnly, setMyContactsOnly] = useState(localStorage.myContactsOnly ? JSON.parse(localStorage.myContactsOnly) : false);

    const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    useEffect(() => {
        let headerFields = companyFields;
        let allHeaderFields = allcompanyFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(_headers);

    }, [companyFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubscribeCompanies && window.unSubscribeCompanies();
        }
    }, [])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings) && !isFilterApplied) {
            //setDataload(false)

            try {

                let _searchObject = {};
                const { dealersettings } = props;
                if (localStorage.defaultModule === 'tradeinPro')
                    _searchObject.clientID = filterClient ? filterClient : dealersettings.client.id
                else
                    _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, props.dealersettings);

                // if (enableSharedContact && props.dealersettings.client.group) {
                //     _searchObject.groupID = props.dealersettings.client.group
                // }
                // else { _searchObject.clientID = props.dealersettings.client.id }

                const addContact = window.firebase.functions().httpsCallable('companies-getCount');
                addContact(_searchObject).then((_data) => {
                    if (!_.isEmpty(_data) && !_.isEmpty(_data.data) && _data.data.length > 0) {
                        //console.log('companies-getCount', _data.data[0])
                        setContactCount(_data.data[0].contact_count);
                    }

                });
            }
            catch (error) {
                console.error(error)
            }
            setDataload(true)
        }
    }, [props.dealersettings, isFilterApplied, filterClient])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'company')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
        if (localStorage.companyFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.companyFilter])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        let clientID = dealersettings.client ? dealersettings.client.id : '';
        // const viewOtherContact = ((!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.rolePermissions) &&
        //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
        //     dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);

        if (localStorage.companyFilter && isValidFilter()) {

            let _companyFilter = JSON.parse(localStorage.companyFilter);
            let _filter = Object.assign({}, _companyFilter.value);
            let _sortOrder = sortName + ' ' + sortOrder;

            if (_filter['owner'] && isOtherContactOwners(_filter['owner'])) {
                setMyContactsOnly(false);
                localStorage.setItem('myContactsOnly', false)
            }
            else {
                _filter.owner = myContactsOnly ? localStorage.uid : _filter.owner;
                if (!_filter.owner)
                    delete _filter['owner'];
            }

            // if (!viewOtherContact)
            //     _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                delete _filter['date'];
            }

            if (localStorage.defaultModule === 'tradeinPro')
                _filter.clientID = filterClient ? filterClient : clientID
            else
                _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings);
            _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
            let _searchObject = {
                "type": "searchCompanies",
                "filters": JSON.stringify(_filter),
                "sortOrder": _sortOrder,
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            // if (!enableSharedContact || _filter.clientID)
            //     _searchObject.clientID = _filter.clientID ? _filter.clientID : clientID;
            if (localStorage.defaultModule === 'tradeinPro')
                _searchObject.clientID = _filter.clientID
            else
                _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);

            //console.log('generic-searchData', _searchObject, isFilterApplied, pageNum, _filter, localStorage.companyFilter)
            const searchCompanies = window.firebase.functions().httpsCallable('generic-searchData');
            searchCompanies(_searchObject).then((response) => {
                //console.log('generic-searchCompany', response);
                if (response.data.success && localStorage.companyFilter && isValidFilter()) {
                    onCollectionCompanies(response.data);
                    setContactCount(response.data.total)
                }
                else {
                    setDataload(false)
                    setCompanyLoader(false)
                    setPaging(false)
                }
            });

            window.unSubscribeCompanies && window.unSubscribeCompanies();
        }
        else {

            let searchKeywords = searchText;
            let selectClient = filterClient;
            let searchOwners = myContactsOnly ? [localStorage.uid] : [];

            if (localStorage.companyFilter) {
                let _companyFilter = JSON.parse(localStorage.companyFilter);
                let _filter = Object.assign({}, _companyFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                searchOwners = _filter['owner'] ? _filter['owner'].split(",") : searchOwners;

                setSearchText(searchKeywords);
                setFilterClient(selectClient);
                localStorage.companySearchText = searchKeywords;
                localStorage.companyFilterClient = selectClient;

                if (_filter['owner'] && isOtherContactOwners(_filter['owner'])) {
                    setMyContactsOnly(false);
                    localStorage.setItem('myContactsOnly', false)
                }

                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let companyDataRef = firestoreDB(dealersettings).firestore().collection('companies')

            if (localStorage.defaultModule === 'tradeinPro')
                companyDataRef = companyDataRef.where('clientID', '==', (selectClient ? selectClient : clientID))
            else
                companyDataRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(companyDataRef, dealersettings);
            // if (enableSharedContact && dealersettings.client.group) {
            //     companyDataRef = companyDataRef.where('groupID', '==', dealersettings.client.group)
            //     //companyDataRef = companyDataRef.where('clientID', '==', dealersettings.client.id)
            // }
            // else {
            //     companyDataRef = companyDataRef.where('clientID', '==', dealersettings.client.id)
            // }

            companyDataRef = companyDataRef.where('isDeleted', '==', false)

            if (searchKeywords) {
                let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)

                if (formattedSearchText.trim())
                    companyDataRef = companyDataRef.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

            }

            if (!_.isEmpty(searchOwners)) {
                companyDataRef = companyDataRef.where('owner', 'in', searchOwners)
            }


            // if (!viewOtherContact)
            //     companyDataRef = companyDataRef.where('owner', '==', dealersettings.id)

            // if (enableSharedContact && dealersettings.client.group) {
            companyDataRef = companyDataRef.orderBy('name', 'asc')
            // }
            // else {
            //     companyDataRef = companyDataRef
            //         .orderBy(sortName, sortOrder)
            // }

            if (hasMore.length > 0) {
                companyDataRef = companyDataRef
                    .startAfter(hasMore[0])
                    .limit(pageLimit)
            }
            else {
                companyDataRef = companyDataRef
                    .limit(pageLimit)
            }

            window.unSubscribeCompanies = companyDataRef
                .onSnapshot(onCollectionUpdate);
            // return () => {
            //     window.unSubscribeCompanies();
            // }
        }
    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {

        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {

                snapshotDoc = convertCompanyVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
                //console.log('onCollectionUpdate', change.doc.id, actionType, change.doc.data().owner);
            }
        })

        const companys = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {

                companys.push(convertCompanyVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: companys,
                sortName: 'name',
                sortOrder: 'asc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : companys,
                sortName: 'name',
                sortOrder: 'asc'
            });
        }


        setDataload(false)
        setCompanyLoader(false)
        setPaging(false)
    }

    const isValidFilter = () => {
        if (localStorage.companyFilter) {
            let _companyFilter = JSON.parse(localStorage.companyFilter);
            let _filter = Object.assign({}, _companyFilter.value);

            if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const isOtherContactOwners = (owner) => {
        let _owners = owner ? owner.split(',') : [];
        if (_owners.length > 1 || (_owners.length === 1 && _owners.indexOf(localStorage.uid) < 0)) {
            return true;
        }
        else {
            return false;
        }
    }

    const onCollectionCompanies = (querySnapshot) => {

        const _companys = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var dataVM = convertCompanyVM(doc);
            _companys.push(dataVM)
        });

        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _companys,
            sortName: 'name',
            sortOrder: 'asc'
        });

        setCompanyLoader(false)
        setDataload(false)
        setPaging(false)
    }

    const convertCompanyVM = (doc) => {

        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objCompanyData = Object.assign({}, doc);
        const company = Object.assign({}, objCompany);
        for (let [key, value] of Object.entries(objCompanyData)) {
            company[key] = value;
        }
        company.displayPhone = company.phone ? (company.phoneCode ? company.phoneCode : '') + company.phone : '';
        company.phone = CommonHelper.getFullPhone(company.phoneCode, company.phone);
        company.createdOn = !_.isEmpty(company.addedDate) ? moment.unix(company.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        company.updatedOn = !_.isEmpty(company.modifiedDate) ? moment.unix(company.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        let dealersettings = props.dealersettings;
        if (company.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[company.clientID] && dealersettings.group.clients[company.clientID].settings) {
            const _setttings = Object.assign({}, dealersettings.group.clients[company.clientID].settings);

            company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
        }
        else {
            const _setttings = dealersettings && dealersettings.client && dealersettings.client.settings;
            company.type = CommonHelper.getNameByValue(_setttings.companyType, company.type, '');
        }

        if (company.clientID && (company.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem')) {
            company.dealerName = CommonHelper.getOtherDealerName(dealersettings, company.clientID);
        }
        else
            company.dealerName = '';

        company.associatedCompany = !_.isEmpty(company.associatedCompany) ? company.associatedCompany.name : '';

        company.owner = company.owner ? CommonHelper.getUserNamebyId(allUsers, company.owner) : '';
        company.addedBy = CommonHelper.getUserNamebyId(allUsers, company.addedBy);
        company.modifiedBy = CommonHelper.getUserNamebyId(allUsers, company.modifiedBy);
        return company
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('companyFilter', JSON.stringify(_filter));
            localStorage.companySearchText = '';
            setFilterApplied(true);
            setCompanyLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }

    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.companyFilter)) {
            localStorage.removeItem('companyFilter');
            localStorage.companySearchText = '';
            setFilterApplied(false);
            setCompanyLoader(true)
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ companyFields: fields }, { merge: true })
            .then(() => {
                setCompanyFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('companyFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    setEditDocument(id);
                    setModelShow(true);
                }}>
                <i className="ico icon-edit"></i>
            </div>
        )
    }

    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        props.history.push("/company/details/" + id);
        setEditDocument(id);
        // setModelShow(true);
        setReadOnlyContact(true);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
        setReadOnlyContact(false);
    }

    const updateCompany = (objCompany) => {
        if (!_.isEmpty(objCompany)) {
            let _data = convertCompanyVM(objCompany);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "_UPDATE",
                    data: _data,
                    sortName: 'name',
                    sortOrder: 'asc'
                });
            }
        }

    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(companys) && companys.map(file => {
                let record = Object.assign({}, companys.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, companys.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    const handleShowTransferCompanies = () => {
        let _selectedIds = Object.assign({}, selectedIds);

        if (_.isEmpty(_selectedIds)) {
            Swal.fire(CommonHelper.showLocale(props, 'Please select company to transfer.'));
            return;
        }
        else {
            setModalTransferShow(true)
        }
    }

    const handleCloseTransferCompanies = async (owner) => {
        if (owner) {

            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();
            const batch = window.firebase.firestore().batch();
            let _selectedIds = Object.assign({}, selectedIds);
            for (let [key] of Object.entries(_selectedIds)) {
                await firestoreDB(props.dealersettings).firestore().collection('companies')
                    .doc(key)
                    .update({
                        owner: owner,
                        modifiedBy: modifiedBy,
                        modifiedFrom: 'web',
                        modifiedDate: modifiedDate
                    }).then((docRef) => {

                    }).catch((error) => {
                        console.error("Error updating companies: ", error);

                    });
                // console.log('_selectedIds', key)
                // batch.update(window.firebase.firestore().doc(`companies/${key}`), {
                //     owner: owner,
                //     modifiedBy: modifiedBy,
                //     modifiedDate: modifiedDate
                // });
            }

            batch.commit()
                .then(() => {
                    setSelectedIds({});
                    setModalTransferShow(false)
                    toast.notify('Company transferred successfully', {
                        duration: 2000
                    })
                })
                .catch((error) => {
                    console.log(error);
                    setModalTransferShow(false)
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
        else {
            setModalTransferShow(false);
        }
    }

    const excelDownload = async (total) => {
        const { dealersettings } = props;
        let clientID = dealersettings.client ? dealersettings.client.id : '';
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let _companyFilter = localStorage.companyFilter ? JSON.parse(localStorage.companyFilter) : {};
        let _filter = Object.assign({}, _companyFilter.value);
        let _sortOrder = sortName + ' ' + sortOrder;

        _filter.owner = myContactsOnly ? localStorage.uid : _filter.owner;
        if (!_filter.owner)
            delete _filter['owner'];

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        if (localStorage.defaultModule === 'tradeinPro')
            _filter.clientID = filterClient ? filterClient : clientID
        else
            _filter = CommonHelper.setClientOrGroupOrRegionOrOemID(_filter, dealersettings);

        _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess()
        let _searchObject = {
            "type": "searchCompanies",
            "filters": JSON.stringify(_filter),
            "sortOrder": _sortOrder,
            "pageNum": 0,
            "pageLimit": total > 999 ? 1000 : (total ? total : 1000)
        }

        if (localStorage.defaultModule === 'tradeinPro')
            _searchObject.clientID = _filter.clientID
        else
            _searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);

        //console.log('generic-searchData-export', _searchObject)

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchContacts(_searchObject);
        //console.log('generic-searchData', resp.data, resp);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = convertCompanyVM(doc);
                _enquiries.push(logVM)
            });
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props));
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from company',
                type: 'excelExport',
                subType: 'companies',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'));
        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload(contactCount);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    return (
        <>
            <FilterPanel
                sortOrder={sortOrder}
                customFilters={customFilters}
                dealersettings={props.dealersettings}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                handleApplyFilter={handleApplyFilter}
                handleClearFilter={handleClearFilter}
                sortName={sortName}
                handlesortOrder={(val) => {
                    setSortOrder(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleSortNamechange={(val) => {
                    setSortName(val)
                    setHasMoreData([]);
                    setDataload(true)
                }}
                handleTransfer={handleShowTransferCompanies}
                csvHeader={csvHeader}
                datalist={companys}
                contactCount={contactCount}
                searchText={searchText}
                handlesearchText={(val) => {
                    setSearchText(val)
                    setFilterApplied(false);
                    setCompanyLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.companyFilter))
                        localStorage.removeItem('companyFilter');

                }}
                myContactsOnly={myContactsOnly}
                handleContactsOnly={(val) => {
                    setMyContactsOnly(val);
                    setFilterApplied(false);
                    setCompanyLoader(true)
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.companyFilter))
                        localStorage.removeItem('companyFilter');

                }}
                filterClient={filterClient}
                handleFilterClient={(val) => {
                    setFilterClient(val)
                    setFilterApplied(false);
                    setCompanyLoader(true);
                    setDataload(true);
                    setPaging(false);
                    setHasMoreData([]);
                    setPageNum(0);
                    if (!_.isEmpty(localStorage.companyFilter))
                        localStorage.removeItem('companyFilter');

                }}
                csvData={csvData}
                excelDownload={excelDownload}
                csvBtn={csvBtn}
            />
            {
                companyLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            <TableView
                                isReorderable={true}
                                datalist={companys}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allcompanyFields.filter(e => localStorage.defaultModule === 'oem' ? e.value !== 'checkbox' : true)}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(companyFields && companyFields.length > 0) ? companyFields.filter(e => localStorage.defaultModule === 'oem' ? e !== 'checkbox' : true) : allcompanyFields.filter(e => e.default === true && (localStorage.defaultModule === 'oem' ? e.value !== 'checkbox' : true)).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailsClick}
                                isSettings={true}
                                hasMore={hasMore}
                                handlePagination={handlePagination}
                                isPaging={true}
                                selectedIds={selectedIds}
                                handleAllChecked={handleAllChecked}
                                handleCheckChange={handleCheckChange}
                                displayByValue={true}
                            />
                        </div>
                    )
            }


            <PopUpModal show={modelShow}>
                <AddCompany
                    show={modelShow}
                    docID={editDocumentID}
                    handleClose={handleClose}
                    title={'company'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isReadOnly={isReadOnlyContact}
                    isFilterApplied={isFilterApplied}
                    updateCompany={updateCompany}
                >
                </AddCompany>
            </PopUpModal>

            <PopUpModal show={modalTransferShow}>
                <TransferOwner
                    show={modalTransferShow}
                    handleClose={handleCloseTransferCompanies}
                    title={'Transfer Companies'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </TransferOwner>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>
        </>
    )
}

export default CompanyList