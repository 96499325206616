
export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 11, i: 'MonthEvents', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'LiveEvents', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'EventAttendees', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

]

export const pipelineCountVMNEW = {
    draft: [],
    live: [],
    published: [],
    closed: []
}

export const pipelinestatus = [
    {
        name: "New",
        value: "New",
        color: "#4967cc",
        sortOrder: 1
    },
    {
        name: "In Progress",
        value: "InProgress",
        color: "#29abe2",
        sortOrder: 2
    },
    {
        name: "Pending",
        value: "Pending",
        color: "#f7931e",
        sortOrder: 3
    },
    {
        name: "Completed",
        value: "Completed",
        color: "#049b8c",
        sortOrder: 4
    },
    {
        name: "Won",
        value: "Won",
        color: "#39b54a",
        sortOrder: 5
    },
    {
        name: "Lost",
        value: "Lost",
        color: "#ef4136",
        sortOrder: 6
    }

]