import React, { useState, useEffect, useContext } from "react";
import images from "../../../../images";
import { Modal } from "react-bootstrap";
import Translate from "../../../../constants/translate";
import { PopUpModal } from "../../../../components";
import _ from 'lodash'
import AddGoogleSheetIntegration from "./add";
import Swal from "sweetalert2";
import toast from 'toasted-notes';

const GoogleSheetIntegration = (props) => {
  const [modalOptionsShow, setOptionsShow] = useState({
    show: false,
    title: "",
    isEdit: false,
  });
  const googlesheetIntgs = props?.integrations?.length ? props.integrations.filter(a => a.googleSheet === true) : [];

  const handleDeleteSubList = (rec, index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let integrationRef;
        if (props.selectedLevel.level === "oem") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("integrations")
            .doc(rec.id);
        } else if (props.selectedLevel.level === "region") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("regions")
            .doc(props.selectedLevel.levelID)
            .collection("integrations")
            .doc(rec.id);
        } else if (props.selectedLevel.level === "group") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clientSettings`)
            .doc(props.dealersettings.client.settingsID)
            .collection("groups")
            .doc(props.selectedLevel.levelID)
            .collection("integrations")
            .doc(rec.id);
        } else if (props.selectedLevel.level === "individual") {
          integrationRef = window.firebase
            .firestore()
            .collection(`clients`)
            .doc(props.selectedLevel.levelID)
            .collection("integrations")
            .doc(rec.id);
        }
        integrationRef.delete()
          .then(() => {
            toast.notify('Log deleted successfully', {
              duration: 2000
            })
          });

      }
    })
  }

  return (<>
    <Modal
      id="add-cafe"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      backdropClassName={props.show ? "overlay-modal active" : ""}
      enforceFocus={false}
      dialogClassName={"modal-dialog modal-dialog-centered modal-configure-integration-generic-api"}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title">
            <Translate text={props.title} />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <div className="configure-integration-logo">
                    <img src={images.googlesheet} alt="" />
                  </div>
                </div>
              </div>
              <div className="form-row ">
                <div className="settings-tab-wrap tabs col-md-12">
                  <div className="tabs-wraper">
                    <nav>
                      <div className="common-table w-100 mt-2">
                        <div className="float-right">
                          <button type="button" className="btn btn-primary mb-2" onClick={(e) => {
                            e.preventDefault()
                            setOptionsShow({ show: true, title: 'Add Sheet Info' })
                          }}> Add</button>
                        </div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="head-light"
                                width="25%"
                              >
                                {" "}
                                <Translate text={"Name"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="25%"
                              >
                                {" "}
                                <Translate text={"Sheet Name"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light"
                                width="30%"
                              >
                                {" "}
                                <Translate text={"Sheet Key"} />
                              </th>
                              <th
                                scope="col"
                                className="head-light border-right-0"
                                width="20%"
                              >
                                {" "}
                                <Translate text={"Actions"} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {googlesheetIntgs.length > 0 ? (
                              googlesheetIntgs.map((rec, index) => {
                                return (
                                  <tr key={index}>
                                    <td> {rec?.settings?.name || ''} </td>
                                    <td> {rec?.settings?.sheetName || ''} </td>
                                    <td> {rec?.settings?.sheetKey || ''} </td>
                                    <td className="border-right-0">
                                      <div className="table-edit float-left mr-2">
                                        <a href="#" className="mini-button" onClick={(e) => {
                                          e.preventDefault();
                                          setOptionsShow({ show: true, title: `Edit Sheet Info (${rec?.settings?.sheetName})`, data: rec.settings })
                                        }}>
                                          <i className="ico icon-edit"></i>
                                        </a>
                                      </div>
                                      <div className="table-edit float-left mr-2">
                                        <a href="#" className="mini-button" onClick={(e) => {
                                          e.preventDefault();
                                          handleDeleteSubList(rec)
                                        }}>
                                          <i className="ico icon-delete"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <PopUpModal show={modalOptionsShow.show}>
      <AddGoogleSheetIntegration
        {...props}
        {...modalOptionsShow}
        handleClose={() => { setOptionsShow({ show: false }) }}
      ></AddGoogleSheetIntegration>
    </PopUpModal>
  </>)
};

export default GoogleSheetIntegration;
