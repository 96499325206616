import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import toast from 'toasted-notes'
import { } from './viewModel';
import { AntDatePicker, ContentOverlay, PopoverPanel, PopUpModal, ReactMultiSelect, ReactSelect, TableView } from '../../components';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import Swal from 'sweetalert2';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import parser from "cron-parser";
import ScheduleReport from "./scheduleReport";
import RosterNotes from './rosterNotes';

const Roster = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [loader, setLoader] = useState(true)
    const [tableFields, setTableFields] = useState([])
    const [filter, setFilter] = useState({ type: 'month', format: 'MMM', date: moment().format('YYYY-MM-DD'), month: moment().format('MMMM'), monthYear: moment().format('MMM-YYYY') })
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState()
    const [checkDataload, setDataLoad] = useState(true)
    const [monthData, setMonthData] = useState([])
    const [alluserData, setAllUserData] = useState([])
    const [requiredPopOver, setRequiredPopOver] = useState({ show: false, target: null, field: null })
    const [schedulerShow, setSchedulerShow] = useState({
        show: false
    })
    const [notesShow, setNotesShow] = useState({
        show: false
    })
    const [monthHolidays, setMonthHolidays] = useState([])

    const rosters = props?.dealersettings?.rosterSettings?.rosterCategories ?
        props.dealersettings.rosterSettings.rosterCategories.filter(a => a.active === true) : []

    const checkoutPerformance = props?.dealersettings?.client?.settings?.checkoutPerformance ?
        props.dealersettings.client.settings.checkoutPerformance.filter(a => a.active === true) : []

    const rosterHolidays = props?.dealersettings?.rosterSettings?.rosterHolidays ?
        props.dealersettings.rosterSettings.rosterHolidays.filter(a => a.active === true) : [];

    const accessToAddRoster = props?.dealersettings?.rolePermissions?.permissions?.accessToAddRoster;
    const accessToDeleteRoster = props?.dealersettings?.rolePermissions?.permissions?.accessToDeleteRoster;
    const accessToRosterNotes = props?.dealersettings?.rolePermissions?.permissions?.accessToRosterNotes;
    const accessToAddRosterNotes = props?.dealersettings?.rolePermissions?.permissions?.accessToAddRosterNotes;
    const disableUserSettings = props?.dealersettings?.rolePermissions?.permissions?.disableUserSettings ? true : false;
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth - 30,
                windowHeight: window.innerHeight - 170,
            });
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.refrosterSnapshot && window.refrosterSnapshot();
        };
    }, []);

    useEffect(() => {
        window.firebase.firestore().collection(`clientSettings/${props.dealersettings.oemID}/teams`)
            .where('clientID', '==', props.dealersettings.client.id)
            .get()
            .then(clientTeamsSnapshot => {
                var _clientteams = []
                if (clientTeamsSnapshot.docs.length > 0) {
                    clientTeamsSnapshot.docs.forEach(rec => {
                        if (rec.data().user_ids && rec.data().user_ids.length > 0) {
                            _clientteams.push({
                                ...rec.data(),
                                id: rec.id,
                                label: rec.data().name,
                                value: rec.id,
                                user_ids: props.clientUsers.filter(a => !Boolean(a.isDisabled)
                                    && rec.data().user_ids.includes(a.documentID)).map(r => { return r.documentID }),
                                user_emails: props.clientUsers.filter(a => !Boolean(a.isDisabled)
                                    && rec.data().user_ids.includes(a.documentID)).map(r => { return r.email })
                            })
                        }
                    })
                }
                setTeams(_clientteams);
                if (_clientteams.length === 0) {
                    setLoader(false)
                    setSelectedTeams(null)
                }
                else {
                    setSelectedTeams(_clientteams[0])
                }
            });

    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedTeams))
            return

        var startDate = moment(filter.date).startOf('month');
        var endDate = moment(filter.date).endOf('month');
        var _days = [{
            name: <div className='roster-head-light'>User</div>,
            value: 'userName',
            default: true,
            flex: 0,
            width: 200,
            fixed: true
        }];
        for (var m = startDate; m.isBefore(endDate); startDate.add(1, 'days')) {
            _days.push({
                name: <div className='roster-head-light text-center'>{m.format('DD')}<span className="roster-day">{m.format('ddd')}</span></div>,
                value: m.format('YYYY-MM-DD'),
                default: true,
                flex: 0,
                width: Math.floor((windowSize.windowWidth - 200) / moment().daysInMonth())
            })
        }
        setTableFields(_days)
        setLoader(true)
        window.firebase.firestore().collection('roster')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('month', '==', filter.monthYear)
            .get()
            .then(querySnapshot => {
                let snapshotData = [];
                querySnapshot.forEach((rec) => {
                    snapshotData.push({ ...rec.data(), 'id': rec.id })
                })
                let _data = [];
                _.orderBy(props.clientUsers.filter(a => selectedTeams.user_ids.includes(a.documentID)), ['name'], ['asc']).forEach(rec => {
                    _data.push({
                        documentID: rec.documentID,
                        name: rec.name,
                        profileImage: rec.profileImage,
                        userName: <div className="roster-user-list roster-cell-disable">
                            <div className={`roster-user-avatar roster-cell-disable`}>
                                <img className="rounded-circle img-object-fit roster-cell-disable" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} />
                            </div>
                            <div className='roster-cell-disable'>{rec.name}</div>
                        </div>,
                        department: rec.department,
                        sortOrder: rec.department === 'Management' ? 0 : 1,
                        ...setMonthUserData(snapshotData, rec.documentID, _days)

                    })
                })
                let managers = _data.filter(a => a.sortOrder === 0);
                let managerslastindex = managers[managers.length - 1];
                _data = _data.map(rec => {
                    return managerslastindex && managerslastindex.documentID === rec.documentID ? {
                        ...rec,
                        borderClass: 'roster-border-cell'
                    } : {
                        ...rec
                    }
                })
                setAllUserData(_data);
                setLoader(false)
            })
    }, [filter.monthYear, selectedTeams])

    useEffect(() => {
        if (_.isEmpty(alluserData))
            return

        setMonthData(_.orderBy(alluserData.filter(item =>
            (filter.searchText ? (
                (!_.isEmpty(item.name) ? item.name.toLowerCase().includes(filter.searchText.toLowerCase()) : false)
            ) : true) &&
            (!_.isEmpty(filter.users) ? filter.users.includes(item.documentID) : true)
        ), ['sortOrder'], ['asc']));

    }, [alluserData, filter.searchText, filter.users])

    const setMonthUserData = (_snapshotData, userid, _days) => {
        let _data = {};
        let _monthHolidays = []
        _days.filter(a => !Boolean(a.fixed)).forEach(rec => {
            let _date = _.find(_snapshotData, { date: rec.value });
            let rosterData = _date ? _.find(rosters, { value: _date[userid] }) : ''
            let _holiday = checkHoliday(rec.value);
            if (!_.isEmpty(_holiday)) {
                _monthHolidays = [
                    ..._monthHolidays.filter(a => a.value !== _holiday.value),
                    _holiday
                ];
            }
            _data = {
                ..._data,
                [`str-${rec.value}`]: rosterData ? rosterData.value : '',
                [rec.value]: rosterData ? <RosterDOM
                    rosterData={rosterData}
                    data={_date}
                    id={userid}
                /> :
                    !_.isEmpty(_holiday) ? (<OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>{_holiday.name}</Tooltip>
                        }
                    >
                        <div className={`roster-cell-align roster-cell-${_holiday.clickable ? '' : 'disable'}`} style={{ backgroundColor: _holiday.color, cursor: _holiday.clickable ? 'pointer' : 'default' }}></div>
                    </OverlayTrigger>)
                        : '',
            }
        })
        setMonthHolidays(_monthHolidays);
        return _data;
    }

    const checkHoliday = (_date) => {
        let result = {};
        rosterHolidays.forEach(rec => {
            if (rec.recurrence && rec.cron) {
                const options = {
                    currentDate: new Date(_date),
                    iterator: true,
                    //tz: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
                };
                const interval = parser.parseExpression(rec.cron, options);
                const _ms = interval?.next()?.value?._date?.ts;
                if (_ms && moment.unix(_ms / 1000).format('YYYY-MM-DD') === _date) {
                    result = rec;
                    return false;
                }
            }
            else if (!rec.recurrence && rec.dateFrom && rec.dateTo && moment(_date).isBetween(`${rec.dateFrom} 00:00`, `${rec.dateTo} 23:59`, undefined, '[]')) {
                result = rec;
                return false;
            }
        })
        return result;
    }

    const handleClientdetailsClick = (e, id, data, tProps) => {
        if (e.target.classList.contains('roster-cell-delete')) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to delete?'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then((result) => {
                if (result.value) {
                    let client_ids = _.find(props.clientUsers, { documentID: id }) ? _.find(props.clientUsers, { documentID: id }).arrayClientID : [];
                    if (!client_ids || client_ids.length === 0) {
                        client_ids.push(props.dealersettings.client.id);
                    }
                    const batch = window.firebase.firestore().batch();
                    client_ids.forEach(client => {
                        batch.set(window.firebase.firestore().doc(`roster/${client}-${tProps.columnKey}`), {
                            [id]: null,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                            userIDs: window.firebase.firestore.FieldValue.arrayRemove(id)
                        }, { merge: true });
                        batch.delete(window.firebase.firestore().doc(`rosterNotes/${id}-${tProps.columnKey}`));
                        if (checkoutPerformance && checkoutPerformance.some(a => a.categories && a.categories.includes(data[`str-${tProps.columnKey}`]))) {
                            batch.set(window.firebase.firestore().doc(`checkout/${client}-${tProps.columnKey}`), {
                                [id]: window.firebase.firestore.FieldValue.delete(),
                                modifiedBy: localStorage.uid,
                                modifiedDate: window.firebase.firestore.Timestamp.now(),
                                logDate: window.firebase.firestore.Timestamp.fromDate(moment(`${tProps.columnKey} 09:00:00`).toDate()),
                                clientID: client,
                            }, { merge: true });
                        }
                    })
                    batch.commit().then(() => {
                        let _holiday = checkHoliday(tProps.columnKey);
                        setAllUserData(alluserData.map(data => {
                            return data.documentID === id ? {
                                ...data,
                                [`str-${tProps.columnKey}`]: '',
                                [tProps.columnKey]: !_.isEmpty(_holiday) ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{_holiday.name}</Tooltip>
                                    }
                                >
                                    <div className={`roster-cell-align roster-cell-${_holiday.clickable ? '' : 'disable'}`} style={{ backgroundColor: _holiday.color, cursor: _holiday.clickable ? 'pointer' : 'default' }}></div>
                                </OverlayTrigger>)
                                    : ''
                            } : {
                                ...data
                            }
                        }))
                        toast.notify('Deleted successfully', {
                            duration: 2000
                        })
                    });
                }
            })
        }
        else if (e.target.classList.contains('roster-cell-note')) {
            let client_ids = _.find(props.clientUsers, { documentID: id }) ? _.find(props.clientUsers, { documentID: id }).arrayClientID : [];
            if (!client_ids || client_ids.length === 0) {
                client_ids.push(props.dealersettings.client.id);
            }
            setNotesShow({
                show: true,
                title: 'Notes',
                name: data.name,
                id,
                date: tProps.columnKey,
                client_ids
            })
        }
        else if (e.target.classList.contains('roster-cell-disable')) {

        }
        else {
            if (accessToAddRoster)
                setRequiredPopOver({
                    show: true,
                    target: e.target,
                    field: {
                        id,
                        name: data.name,
                        data: data[`str-${tProps.columnKey}`],
                        ...tProps
                    }
                });
        }

    }

    const RosterDOM = ({ rosterData, data, id }) => {
        return (<><div className='roster-cell-align' style={{ backgroundColor: rosterData.color }}>{rosterData.value}</div>
            {
                accessToDeleteRoster ?
                    <div className='roster-cell-delete'>&#x2716;</div>
                    : (<></>)
            }
            {
                data?.notes?.includes(id) && accessToRosterNotes ? (
                    <div className="roster-cell-note roster-note-icon"><i className="ico icon-mail-draft roster-cell-note"></i></div>
                ) : (<></>)
            }
        </>)
    }

    const CustomPopover = ({ id, columnKey, name, data }) => {
        return <div className="custom-drop custom-drop-align">
            <ul className="roster-selection-dropdown">
                {
                    rosters.map((rec, index) => {
                        return <li key={index}>
                            <a href="#" onClick={() => {
                                setRequiredPopOver({ show: false, target: '', field: '' });
                                let client_ids = _.find(props.clientUsers, { documentID: id }) ? _.find(props.clientUsers, { documentID: id }).arrayClientID : [];
                                if (!client_ids || client_ids.length === 0) {
                                    client_ids.push(props.dealersettings.client.id);
                                }
                                const batch = window.firebase.firestore().batch();
                                client_ids.forEach(client => {
                                    batch.set(window.firebase.firestore().doc(`roster/${client}-${columnKey}`), {
                                        [id]: rec.value,
                                        month: filter.monthYear,
                                        date: columnKey,
                                        logDate: window.firebase.firestore.Timestamp.fromDate(moment(`${columnKey} 09:00:00`).toDate()),
                                        clientID: client,
                                        modifiedBy: localStorage.uid,
                                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                                        userIDs: window.firebase.firestore.FieldValue.arrayUnion(id)
                                    }, { merge: true });
                                    if (checkoutPerformance && checkoutPerformance.some(a => a.categories && a.categories.includes(rec.value))) {
                                        const _pValue = checkoutPerformance.filter(a => a.categories && a.categories.includes(rec.value))[0].value;
                                        batch.set(window.firebase.firestore().doc(`checkout/${client}-${columnKey}`), {
                                            [id]: _pValue,
                                            modifiedBy: localStorage.uid,
                                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                                            logDate: window.firebase.firestore.Timestamp.fromDate(moment(`${columnKey} 09:00:00`).toDate()),
                                            clientID: client,
                                        }, { merge: true });
                                    }
                                })
                                batch.commit().then(() => {
                                    setAllUserData(alluserData.map(data => {
                                        return data.documentID === id ? {
                                            ...data,
                                            [`str-${columnKey}`]: rec.value,
                                            [columnKey]: <><div className='roster-cell-align' style={{ backgroundColor: rec.color }}>{rec.value}</div>
                                                {
                                                    accessToDeleteRoster ?
                                                        <div className='roster-cell-delete'>&#x2716;</div>
                                                        : (<></>)
                                                }
                                            </>
                                        } : {
                                            ...data
                                        }
                                    }))
                                    toast.notify('Logged successfully', {
                                        duration: 2000
                                    })
                                });
                            }}> <span className="d-inline-flex badge badge-pill roster-dropdown-badge-counter ml-1" style={{ backgroundColor: rec.color }} >
                                    {rec.value}</span> {rec.name} </a>
                        </li>
                    })
                }
                {
                    accessToAddRosterNotes && data ?
                        <li onClick={(e) => {
                            e.preventDefault();
                            let client_ids = _.find(props.clientUsers, { documentID: id }) ? _.find(props.clientUsers, { documentID: id }).arrayClientID : [];
                            if (!client_ids || client_ids.length === 0) {
                                client_ids.push(props.dealersettings.client.id);
                            }
                            setRequiredPopOver({ show: false, target: '', field: '' });
                            setNotesShow({
                                show: true,
                                title: 'Notes',
                                name,
                                id,
                                date: columnKey,
                                client_ids
                            })
                        }} className="roster-note-li"><button className="btn btn-sm btn-link"><i className="ico icon-add"></i> Add Notes</button></li>
                        : (<></>)
                }
            </ul>
        </div>
    }

    const updateRosterData = (id, date, _data) => {
        setAllUserData(alluserData.map(data => {
            return data.documentID === id ? {
                ...data,
                [date]: <RosterDOM
                    rosterData={_.find(rosters, { value: data[`str-${date}`] })}
                    data={_data}
                    id={id}
                />
            } : {
                ...data
            }
        }))
    }

    return (<>
        <div className='middle-wrapper'>
            <div className='filter-panel'>
                <div className='float-left fleet-head'>
                    <h3><Translate text={'Roster'} /></h3>
                </div>
                <div className='float-right'>
                    <div className="roster-pipeline-counter mr-2">
                        <div className="roster-counter-nav">
                            <ul>
                                {
                                    rosters.map((r, i) => {
                                        return <li key={i}><span className="roster-counter mr-1">
                                            <span className="d-inline-flex badge badge-pill roster-badge-counter ml-1" style={{ backgroundColor: r.color }}>{r.value}</span>
                                            {r.name} </span>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        monthHolidays && monthHolidays.length > 0 ? (
                            <div className="roster-pipeline-counter mr-2 pl-2" style={{ borderLeft: '1px solid #ccc' }}>
                                <div className="roster-counter-nav">
                                    <ul>
                                        {
                                            monthHolidays.map((r, i) => {
                                                return <li key={i}><span className="roster-counter mr-1">
                                                    <span className="d-inline-flex badge badge-pill roster-badge-counter ml-1" style={{ backgroundColor: r.color }}></span>
                                                    {r.name} </span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    <Dropdown className='enquiry-view-more float-right mt-0'>
                        <Dropdown.Toggle as={CustomToggle} className='common-button'>
                            <i className='ico icon-more'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            as={CustomMenu}
                            ChildClass="more-dropdown dropsub-fix"
                            xplacement="bottom-end"
                        >
                            {
                                !disableUserSettings && !_.isEmpty(props.dealersettings)
                                    && !_.isEmpty(props.dealersettings.rolePermissions)
                                    && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                                    && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                                        return props.dealersettings.rolePermissions.admin[id]
                                    }).some(e => e === 'clientSettings') ? (
                                    <Dropdown.Item eventKey="1" onClick={(e) => {
                                        props.history.push('/settings/company?key=users&item=teams');
                                    }}><i className="ico icon-activity-meeting"></i> <Translate text={'Manage Teams'} /> </Dropdown.Item>
                                ) : (<></>)
                            }
                            {
                                !_.isEmpty(props.dealersettings)
                                    && !_.isEmpty(props.dealersettings.rolePermissions)
                                    && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                                    && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                                        return props.dealersettings.rolePermissions.admin[id]
                                    }).some(e => e === 'clientSettings') ? (
                                    <Dropdown.Item eventKey="1" onClick={(e) => {
                                        props.history.push('/settings/company?key=roster');
                                    }}><i className="ico icon-settings"></i> <Translate text={'Roster Settings'} /> </Dropdown.Item>
                                ) : (<></>)
                            }
                            <Dropdown.Item eventKey="4" onClick={(e) => {
                                setSchedulerShow({
                                    show: true,
                                    title: 'Email Report',
                                    report: {
                                        documentID: 'EmailReport',
                                        url: `dynamic/report/roster/${props.dealersettings.client.id}?date=${filter.monthYear}&id=${selectedTeams.documentID}`
                                    }
                                })
                            }}><i className="ico icon-email"></i> <Translate text={'Email Report'} /> </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            </div>
            <div className="filter-sub-panel d-flex justify-content-between">
                <div className='float-left'>
                    <div className="filter-search search-icon">
                        <div className='input-group template-search'>
                            <input placeholder="Search" aria-label="Search" value={filter.searchText}
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        searchText: e.target.value
                                    })
                                }} />
                            <div className='input-group-append input-search-clear'>
                                {!_.isEmpty(filter.searchText) && (
                                    <a href='#' className='input-search-clear-icon' style={{ top: '9px' }}
                                        onClick={() =>
                                            setFilter({
                                                ...filter,
                                                searchText: ''
                                            })
                                        }>
                                        <i className='ico icon-remove'></i>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='float-left ml-2' style={{ width: '300px' }}>
                        <ReactMultiSelect
                            options={props.clientUsers.filter(a => selectedTeams ? selectedTeams.user_ids.includes(a.documentID) : false).map(rec => {
                                return {
                                    label: <div className="drop-image">
                                        <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                        {rec.name}
                                    </div>,
                                    value: rec.id
                                }
                            })}
                            name={`checkout-users`}
                            placeholder={'select users'}
                            onChange={(selectedOptions) => {
                                const value = [];
                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                    value.push(data.value)
                                })

                                setFilter({
                                    ...filter,
                                    users: value
                                })
                            }}
                            value={filter?.users ? filter.users : []}
                            classNamePrefix={`cursor-pointer basic-select`}
                            isClearable={true}
                            isMulti={true}
                            isSearchable={true}

                        >
                        </ReactMultiSelect>
                    </div>
                </div>
                <div className="calendar-year">{moment(filter.date).format('MMMM YYYY')}</div>
                <div className='float-right'>
                    <div className={`float-right ${loader ? 'btn-disable' : ''}`} style={{ width: '200px' }}>
                        <AntDatePicker
                            value={moment(filter.date)}
                            name={'roster-date'}
                            onChange={(val) => {
                                setFilter({
                                    ...filter,
                                    date: moment(val).format('YYYY-MM-DD'),
                                    month: moment(val).format('MMMM'),
                                    monthYear: moment(val).format('MMM-YYYY'),
                                })
                                setLoader(true)
                                setDataLoad(true)
                            }}
                            format={filter.format}
                            allowClear={false}
                            placeholder='DD/MM/YYYY'
                            className={`form-control`}
                            picker={filter.type}
                        />
                    </div>
                    <div className='float-left mr-2' style={{ width: '200px' }}>
                        <ReactSelect
                            options={teams.map(rec => {
                                return {
                                    label: rec.name,
                                    value: rec.value,
                                    data: rec
                                }
                            })}
                            name={`checkout-teams`}
                            placeholder={'select team'}
                            onChange={(e, data) => {
                                setSelectedTeams(e ? e.data : null)
                            }}
                            value={selectedTeams ? selectedTeams.value : null}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
            </div>
            <div className="calendar-panel">
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className="roster-common-table">
                            <TableView
                                datalist={monthData}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={tableFields}
                                dynamicFields={tableFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                headerHeight={55}
                                hideSerialNo={true}
                                handleRowSingleClick={handleClientdetailsClick}
                            />
                        </div>
                    )
                }

            </div>
        </div>
        <PopoverPanel
            showpopover={requiredPopOver.show}
            targetpopover={requiredPopOver.target}
            id={'roster-custom-drop'}
            position="left"
            className=""
            closepopover={() => {
                setRequiredPopOver({ show: false, target: '', field: '' });
            }}>
            <CustomPopover
                {...requiredPopOver.field}
            />
        </PopoverPanel>
        <PopUpModal show={schedulerShow.show}>
            <ScheduleReport
                {...props}
                {...schedulerShow}
                handleClose={() => setSchedulerShow({ show: false })}
                teams={teams}
            ></ScheduleReport>
        </PopUpModal>
        <PopUpModal show={notesShow.show}>
            <RosterNotes
                {...props}
                {...notesShow}
                handleClose={() => setNotesShow({ show: false })}
                updateRosterData={updateRosterData}
            ></RosterNotes>
        </PopUpModal>
    </>)
}

export default Roster