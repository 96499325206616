/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
/** COMPONENTS */
import CafeHistoryReducer from './history/historyReducer'
import CommonHelper from '../../services/common';
import { ContentOverlay, PopUpModal, TableView, PopoverPanel } from '../../components'
import Translate from '../../constants/translate';
import CafeOrderLIst from './list'
import AddCafe from './add';
/** VIEW-MODELS */
import { cafeStatus } from '../../services/enum'
import { firestoreDB } from '../../services/helper';
import { objCafe, objCafeHistoryVM, allCafeHistoryFields } from './viewModel'

const CafeOrdersList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [cafeHistory, dispatch] = useReducer(CafeHistoryReducer, { All: [] })
    const [checkDataLoad, setDataload] = useState({ "All": true })
    const [hasMore, setHasMoreData] = useState([])
    const [cafeHistoryFields, setCafeHistoryFields] = useState(props.dealersettings.cafeHistoryFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [cafeHistoryLoader, setLoader] = useState({ "All": true })
    const pageLimit = 50
    const [activeTab, setActiveTab] = useState('All')
    const [searchText, setSearchText] = useState(localStorage.cafeHistorySearchText ? localStorage.cafeHistorySearchText : '')
    const [cafePopOver, setCafePopOver] = useState({ show: false, target: null, cafe: null, count: 0 })
    const [cafeModal, setCafeModal] = useState({ show: false, title: '', reorders: null })

    const [titles, setTitles] = useState([])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    label: doc.name
                });
            });


            setTitles(_titles);
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [props.dealersettings])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubCafeOrders.unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        let refCafeOrdLstData = firestoreDB(props.dealersettings).firestore().collection('cafeOrders')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .orderBy('modifiedDate', 'desc')

        if (searchText && searchText.trim()) {
            refCafeOrdLstData = refCafeOrdLstData
                .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        }

        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            //console.log('lastRecord', lastRecord)
            refCafeOrdLstData = refCafeOrdLstData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refCafeOrdLstData = refCafeOrdLstData
                .limit(pageLimit)
        }

        // window.unSubCafeOrders = refCafeOrdLstData
        //     .onSnapshot(onFleetCollectionUpdate);

        window.unSubCafeOrders = collectionData(refCafeOrdLstData)
            .pipe(
                switchMap(fleetSnapshot => {
                    return combineLatest(
                        of(fleetSnapshot),
                    )
                }),
                map(([fleetSnapshot]) => {
                    return fleetSnapshot.map(snapDoc => {
                        return {
                            ...snapDoc,
                            //stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
                        }
                    })
                })
            )
            .subscribe(cafeHistorys => {
                onCollectionUpdate(cafeHistorys)
            });
        // return () => {
        //     cafeHistorys.unsubscribe()
        // }



    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (cafeHistorys) => {
        //console.log('onCollectionUpdate', activeTab, cafeHistorys)
        const _cafeHistorys = [];
        if (cafeHistorys.length > 0 && cafeHistorys.length === pageLimit) {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
            {
                type: activeTab,
                lastDoc: cafeHistorys[cafeHistorys.length - 1]
            }])
        }
        else {
            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
        }
        cafeHistorys.forEach(function (doc) {
            var dataVM = dataMappingVM(doc);
            _cafeHistorys.push(dataVM)
        });
        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
            data: _cafeHistorys,
            activetab: activeTab
        });
        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...cafeHistoryLoader,
            [activeTab]: false
        })
        setPaging(false)
    }


    const dataMappingVM = (doc) => {
        const { clientUsers } = props;
        const objlogData = Object.assign({}, doc);
        const cafeVM = Object.assign({}, objCafeHistoryVM);
        for (let [key, value] of Object.entries(objlogData)) {
            cafeVM[key] = value;
        }

        cafeVM.orderQuantity = orderQuantity(cafeVM.orders);
        cafeVM.addedby = CommonHelper.getUserNamebyId(clientUsers, cafeVM.addedBy);
        cafeVM.deliveredby = CommonHelper.getUserNamebyId(clientUsers, cafeVM.deliveredBy);
        cafeVM.addeddate = cafeVM.addedDate ? moment.unix(cafeVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        cafeVM.delivereddate = cafeVM.deliveredDate ? moment.unix(cafeVM.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';


        if (!_.isEmpty(cafeVM.contact)) {
            let _contact = Object.assign({}, cafeVM.contact);
            cafeVM.name = CommonHelper.getFullName(titles, _contact);
            cafeVM.displayID = _contact.displayID ? _contact.displayID : '';
            cafeVM.phone = CommonHelper.getFullPhone(_contact.phoneCode, _contact.phone);
            cafeVM.email = _contact.email ? (_contact.email) : '';
        }
        else if (!_.isEmpty(cafeVM.staff)) {
            cafeVM.name = cafeVM.staff.name ? cafeVM.staff.name : '';
        }


        cafeVM.cafeStatus = (cafeVM.status
            ?
            <div className={`badge badge-pill badge-${cafeVM.status.toLowerCase()}-fill`}>{cafeVM.status}</div>
            :
            <></>);

        return cafeVM
    }

    const orderQuantity = (orders) => {

        if (!_.isEmpty(orders)) {
            var total = 0;
            for (var i = 0; i < orders.length; i++) {
                total = total + (orders[i].quantity ? orders[i].quantity : 0);
            }
            return total;
        }
        else {
            return 0;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)

        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ cafeHistoryFields: fields }, { merge: true })
            .then(() => {
                setCafeHistoryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('cafeHistoryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }



    //#region CAFE CHANGES
    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    e.preventDefault();
                    handleEdit(e, obj);
                }}>
                <i className="ico icon-more"></i>
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        //handleEdit(e, obj);
    }

    const handleEdit = (e, obj) => {

        let _cafe = Object.assign({}, objCafe);
        for (let [key, value] of Object.entries(obj)) {
            //if (_stock.hasOwnProperty(key))
            _cafe[key] = value;
        }


        setCafePopOver({
            show: true,
            target: e.target,
            cafe: _cafe,
            count: _cafe.orderQuantity

        });
    }

    const handleCafeClose = (cafeID) => {
        setCafeModal({ show: false, title: '', reorders: null });
    }

    const handleOrderCancelled = (cafeID) => {
        if (cafeID) {
            const updateRef = firestoreDB(props.dealersettings).firestore().collection('cafeOrders').doc(cafeID);
            updateRef.update(
                {
                    status: cafeStatus.CANCELLED,
                    modifiedBy: props.dealersettings ? props.dealersettings.id : '',
                    modifiedFrom: 'web',
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    deliveredBy: props.dealersettings ? props.dealersettings.id : '',
                    deliveredDate: window.firebase.firestore.Timestamp.now()
                }
            ).then((docRef) => {
                toast.notify('Cafe order cancelled successfully', {
                    duration: 2000
                })
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }
        setCafePopOver({ show: false, target: '', cafe: null, count: 0 });
    }

    const handleReOrder = (cafeID) => {
        if (cafeID) {
            let cafeOrders = Object.assign([], cafeHistory[activeTab]);
            let record = Object.assign({}, cafeOrders.filter(e => e.documentID === cafeID)[0]);

            var reOrders = {
                orders: record.orders,
                orderNotes: record.orderNotes
            }
            setCafeModal({ show: true, title: 'orderCafe', reorders: reOrders });

        }
        setCafePopOver({ show: false, target: '', cafe: null, count: 0 });
    }
    //#endregion   




    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    return (
        <div className="middle-wrapper">

            <div className="filter-panel">
                <div className="float-left">

                    <div className="filter-search">
                        <div className="input-group ">
                            <input
                                className="form-control"
                                type="text"
                                id="isearch"
                                placeholder="search..."
                                value={searchText ? searchText : ''}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    localStorage.cafeHistorySearchText = e.target.value;

                                    if (!e.target.value) {
                                        setSearchText('');
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }
                                }}
                                autoComplete="off"
                            />
                            <div className="input-group-append input-search-clear">
                                {
                                    searchText && searchText.length > 0
                                        ?
                                        <a href="#"
                                            className="input-search-clear-icon"
                                            title="Clear"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSearchText('');
                                                localStorage.cafeHistorySearchText = '';
                                                setDataload({
                                                    ...checkDataLoad,
                                                    [activeTab]: true
                                                })
                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                            }}
                                        >
                                            <i className="ico icon-remove"></i>
                                        </a>
                                        :
                                        <>
                                        </>
                                }

                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }}>
                                    <i className="ico icon-search-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="float-right">

                    <div className="filter-add-button">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setCafeModal({ show: true, title: 'orderCafe', reorders: null });
                            }}
                        > <i className="ico icon-cafe"></i> <Translate text={'orderCafe'} /></button>
                    </div>
                </div>
            </div>


            {
                cafeHistoryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    cafeHistory[activeTab] && cafeHistory[activeTab].length > 0 ? (
                        <div className="contact-panel" style={{ width: windowSize.windowWidth + 'px' }}>
                            <TableView
                                isReorderable={true}
                                datalist={cafeHistory[activeTab] ? cafeHistory[activeTab] : []}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={allCafeHistoryFields}
                                handleSavesettings={handleSavesettings}
                                dynamicFields={(cafeHistoryFields && cafeHistoryFields.length > 0) ? cafeHistoryFields : allCafeHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                settingsLoader={settingsLoader}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleDetailsClick}
                                isSettings={true}
                                hasMore={hasMore.find(e => e.type === activeTab)}
                                handlePagination={handlePagination}
                                isPaging={true}
                                activityTab={activeTab}
                                displayByValue={true}
                            />
                        </div>
                    ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
            }




            <PopoverPanel
                showpopover={cafePopOver.show}
                targetpopover={cafePopOver.target}
                title={<><Translate text={'orders'} />&nbsp; <span className="badge badge-pill badge-activity ml-1">{cafePopOver.count}</span></>}
                position="left"
                id="cafe-items"
                className="popover-cafe"
                closepopover={() => {
                    setCafePopOver({ show: false, target: '', cafe: null, count: 0 });
                }}>
                <CafeOrderLIst
                    cafe={cafePopOver.cafe}
                    handleCancel={handleOrderCancelled}
                    handleReOrder={handleReOrder}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isDeivered={false}
                />
            </PopoverPanel>

            <PopUpModal show={cafeModal.show}>
                <AddCafe
                    reOrders={cafeModal.reorders}
                    show={cafeModal.show}
                    handleClose={handleCafeClose}
                    title={cafeModal.title}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    enquiryID={null}
                    contact={null}
                >
                </AddCafe>
            </PopUpModal>

        </div >

    )
}

export default CafeOrdersList;