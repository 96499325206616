import React, { useEffect,useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

const FunnelChart = (props) => {

    const [chartData, setChart] = useState(null)

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        if (JSON.stringify(chartData.data) !== JSON.stringify(props.data))
            chartData.data = props.data
        am4core.array.each(chartData.data, (item) => {
            if (_.find(props.data, { value: item.value }))
                item.count = _.find(props.data, { value: item.value }).count
        })

        chartData.invalidateRawData();

    }, [props.data])

    useEffect(() => {
        let chart = am4core.create(props.id, am4charts.SlicedChart);
        chart.data = props.data;
        chart.logo.disabled = true;
        chart.hiddenState.properties.opacity = 0;

        //legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.scrollable = true;
        chart.legend.valueLabels.template.text = "{value.percent.formatNumber('#.')}% ({count})";
        let markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;


        var series = chart.series.push(new am4charts.PyramidSeries());
        series.colors.step = 2;
        series.dataFields.value = "count";
        series.dataFields.category = "name";
        series.topWidth = am4core.percent(100);
        series.bottomWidth = am4core.percent(40);
        series.ticks.template.disabled = true;
        series.alignLabels = false;
        series.labels.template.text = "{value.percent.formatNumber('#.')}%";
        series.labels.template.fill = am4core.color("white");
     
        series.labels.template.events.on("ready", hideSmall);
        series.labels.template.events.on("visibilitychanged", hideSmall);

        function hideSmall(ev) {
            if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 1)) {
                ev.target.hide();
            }
            else {
                ev.target.show();
            }
        }
        series.slices.template.tooltipText = "{name}: {value.percent.formatNumber('#.')}% ({count})"; 
        series.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        series.slices.template.events.on("doublehit", function (ev) {
            props.handleShowEnquiryList(ev.target.dataItem.dataContext.value, ev.target.dataItem.dataContext.type, ev.target.dataItem.dataContext.startDate, ev.target.dataItem.dataContext.endDate, ev.target.dataItem.dataContext)
        }, this); 
        const funnelchart = chart;
        setChart(chart)

        return () => {
            funnelchart && funnelchart.dispose()
        }
    }, [])
    //https://www.amcharts.com/docs/v4/tutorials/chart-legend-in-an-external-container/
    return (
        <div id={props.id} style={{ width: "100%", height: props.height }}></div>
    );
}

export default FunnelChart;