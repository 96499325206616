import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { ContentOverlay } from "../../../components";
import _images from '../../../images'
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';

const options = [
    { active: true, value: 'campaigns', label: 'Campaigns', title: 'Campaign', field: 'cpgv' },
    { active: true, value: 'origins', label: 'Lead Origins', title: 'Origin', field: 'orgv' },
    { active: true, value: 'enquiryTypes', label: 'Enquiry Types', title: 'Enquiry Type', field: 'etv' },
]

const MarketingReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d),
        date: moment().format('YYYY-MM-DD'),
        monthYear: moment().format('MMM-YYYY')
    })
    const [reportData, setReportData] = useState([])
    const [dynReport, setDynReport] = useState()
    const [dealersettings, setDealersettings] = useState()
    const [currencySymbol, setcurrencySymbol] = useState('');
    const [propsSettings, setPropsSettings] = useState()

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    let _startdate = params.get('startDate');
    let _enddate = params.get('endDate');

    useEffect(() => {
        window.firebase.firestore().doc(`reports/${props.reportid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    setDynReport({
                        ...doc.data(),
                        'documentID': doc.id
                    })
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(dynReport) || _.isEmpty(dynReport.clientID))
            return

        window.firebase.firestore().doc(`clients/${dynReport.clientID}`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setcurrencySymbol(!_.isEmpty(doc.data().currency) ? doc.data().currency.symbol + ' ' : '$ ');
                }
                window.firebase.firestore().doc(`clients/${dynReport.clientID}/currentSettings/${dynReport.clientID}`)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            let timezone = !_.isEmpty(doc.data().timezone) ? doc.data().timezone : moment.tz.guess();
                            const _currentDate = moment().tz(timezone).format('YYYY-MM-DD HH:mm');
                            if (_startdate && _enddate) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_startdate).tz(timezone).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_enddate).tz(timezone).endOf('day').toDate()),
                                    date: moment(_startdate).format('YYYY-MM-DD'),
                                    monthYear: moment(_startdate).format('MMM-YYYY')
                                });
                            }
                            else if (_date === 'today') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('day').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                                    date: moment(_currentDate).format('YYYY-MM-DD'),
                                    monthYear: moment(_currentDate).format('MMM-YYYY')
                                });
                            }
                            else if (_date === 'wtd') {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                                        date: moment(_currentDate).format('YYYY-MM-DD'),
                                        monthYear: moment(_currentDate).format('MMM-YYYY')
                                    });
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day').toDate()),
                                        date: moment(_currentDate).format('YYYY-MM-DD'),
                                        monthYear: moment(_currentDate).format('MMM-YYYY')
                                    });
                                }
                            }
                            else if (dynReport.dataParam === 'upcoming' && !_.isEmpty(dynReport.upcomingDays)) {
                                if (!_.isEmpty(doc.data().weekStart)) {
                                    moment.updateLocale('en', {
                                        week: {
                                            dow: moment().day(doc.data().weekStart).day(),
                                        },
                                    })
                                }
                                if (moment().startOf('week').day() >= 1 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('week').toDate()),
                                        date: moment(_currentDate).format('YYYY-MM-DD'),
                                        monthYear: moment(_currentDate).format('MMM-YYYY')
                                    })
                                }
                                else if (moment().startOf('week').day() === 0 && dynReport.upcomingDays.includes('0')) {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week').toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays.filter(a => a !== '0'))).toDate()),
                                        date: moment(_currentDate).format('YYYY-MM-DD'),
                                        monthYear: moment(_currentDate).format('MMM-YYYY')
                                    })
                                }
                                else {
                                    setDateRange({
                                        startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays)).toDate()),
                                        endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays)).toDate()),
                                        date: moment(_currentDate).format('YYYY-MM-DD'),
                                        monthYear: moment(_currentDate).format('MMM-YYYY')
                                    })
                                }

                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('month').toDate()),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('month').toDate()),
                                    date: moment(_currentDate).format('YYYY-MM-DD'),
                                    monthYear: moment(_currentDate).format('MMM-YYYY')
                                });
                            }
                            setPropsSettings({
                                dealersettings: {
                                    ...doc.data(),
                                    client: {
                                        id: doc.id,
                                        settings: doc.data().settings,
                                        integrations: doc.data().integrations,
                                        workingHours: doc.data().workingHours,
                                    }
                                },
                                pipeline: doc.data().settings.allPipelines,
                                clientUsers: doc.data().users,

                            })
                            setDealersettings({
                                ...doc.data(),
                                'clientID': doc.id
                            })
                        }
                    })
            })

    }, [dynReport])


    useEffect(() => {
        if (_.isEmpty(dealersettings))
            return

        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getSummaryReport',
                params: JSON.stringify({
                    clientID: dealersettings.clientID,
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    hideTradein: true,
                    hideActivities: true,
                    hideWalkin: true,
                    hideTestdrive: true
                })
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
            });
        }
        catch (error) {
            setLoader(false)
        }

    }, [dealersettings])


    const loadReportData = (_allData) => {
        const _data = [];
        options.forEach(option => {
            let i = 0;
            const dynOptions = propsSettings?.dealersettings?.client?.settings?.[option.value] || [];
            dynOptions.filter(a => dynReport?.[option.value]?.length ? dynReport[option.value].includes(a.value) : false).forEach(rec => {
                const leadCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds);
                const wonCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds, 'sold', 'wd');
                const lostCount = checkDataFilter(_allData.filter((a) => a.col === 'enq' && a.stgv !== 'Archived' && a[option.field] === rec.value), dateRange.startDate.seconds, dateRange.endDate.seconds, 'lost', 'ld');
                const leadCost = dynReport?.[`${option.value}Cost`]?.[rec.value]?.leadCost || 0;
                const target = dynReport?.[`${option.value}Cost`]?.[rec.value]?.targetMonth?.[dateRange.monthYear] || dynReport?.[`${option.value}Cost`]?.[rec.value]?.target || 0;
                const totalCost = leadCost > 0 ? leadCount.actual * leadCost : dynReport?.[`${option.value}Cost`]?.[rec.value]?.totalCost || 0;
                const costperlead = totalCost > 0 && leadCount.actual > 0 ? _.round(totalCost / leadCount.actual, 2) : 0;
                const costperwon = totalCost > 0 && wonCount.actual > 0 ? _.round(totalCost / wonCount.actual, 2) : 0;
                const costperlost = totalCost > 0 && lostCount.actual > 0 ? _.round(totalCost / lostCount.actual, 2) : 0;
                _data.push({
                    groupKey: option.value,
                    groupName: i === 0 ? option.title : null,
                    groupValue: option.title,
                    name: `${rec.name} ${!rec.active ? '(In Active)' : ''} `,
                    value: rec.value,
                    newLeads: leadCount,
                    soldLeads: wonCount,
                    lostLeads: lostCount,
                    delta: target > 0 ? leadCount.actual - target : 0,
                    leadCost,
                    totalCost,
                    costperlead,
                    costperwon,
                    costperlost,
                    target
                })
                i++;
            });
        })
        setReportData(_data);
    }

    const checkDataFilter = (_data, startDt, endDt, status, dateType, newlead, saleType) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }


    return (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        dealersettings && dealersettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={dealersettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{dealersettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> {dynReport.name} <br></br>
                                    {
                                        _date === 'wtd' || _startdate || _enddate || dynReport.dataParam === 'upcoming' ? (
                                            <span> {`${moment.unix(dateRange.startDate.seconds).format('DD MMM')}-${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}</span>
                                        ) : !_.isEmpty(dealersettings.timezone) ? (
                                            <span> {moment().tz(dealersettings.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : reportData?.length === 0 ? (
                        <div className="table-wraper">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> No records found</div>
                            </div>
                        </div>) : (
                        <div className="table-wraper">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="head-light" scope="col" width="15%"></th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Target</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Actual</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Delta</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lead Cost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Activity Cost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost/Lead</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Sales</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost per Sales</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Lost</th>
                                        <th scope="col" className="head-light text-center" width="8.5%">Cost per Lost</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportData.map((data, index) => {
                                            return <tr key={index}>
                                                {
                                                    data.groupName ?
                                                        <td rowSpan={_.size(reportData.filter(a => a.groupValue === data.groupName))}><div className="dealership-tbl">{data.groupName}</div></td> : (<></>)
                                                }
                                                <td className="text-left">{data.name}</td>
                                                <td className="text-center ">{data?.target || 0}</td>
                                                <td className="text-center cursor-pointer">{data?.newLeads?.actual || 0}</td>
                                                <td className={`text-center  ${data?.delta < 0 ? 'text-red' : ''}`}>{data?.delta || 0}</td>
                                                <td className="text-center ">{<NumberFormat
                                                    thousandSeparator={true}
                                                    prefix={currencySymbol}
                                                    displayType={'text'}
                                                    value={data?.leadCost || 0}
                                                />
                                                }</td>
                                                <td className="text-center ">{<NumberFormat
                                                    thousandSeparator={true}
                                                    prefix={currencySymbol}
                                                    displayType={'text'}
                                                    value={data?.totalCost || 0}
                                                />
                                                }</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperlead || 0}
                                                    />
                                                </td>
                                                <td className="text-center  cursor-pointer">{data?.soldLeads?.actual || 0}</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperwon || 0}
                                                    />
                                                </td>
                                                <td className="text-center  cursor-pointer">{data?.lostLeads?.actual || 0}</td>
                                                <td className="text-center table-middle-total">
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        displayType={'text'}
                                                        value={data?.costperlost || 0}
                                                    />
                                                </td>
                                            </tr>
                                        })
                                    }
                                    <tr>
                                        <td className="table-sub-total">Total Leads</td>
                                        <td className="text-left text-size-total">{`${moment.unix(dateRange.startDate.seconds).format('DD MMM')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')} `}</td>
                                        <td className="text-center table-sub-total">{_.sumBy(reportData, 'target')}</td>
                                        <td className="text-center table-sub-total cursor-pointer">{_.sumBy(reportData, 'newLeads.actual')}</td>
                                        <td className={`text-center table-sub-total ${_.sumBy(reportData, 'delta') < 0 ? 'text-red' : ''}`}>{_.sumBy(reportData, 'delta')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'leadCost').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'totalCost').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperlead').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total cursor-pointer">{_.sumBy(reportData, 'soldLeads.actual')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperwon').toFixed(2)}
                                            />
                                        </td>
                                        <td className="text-center table-sub-total cursor-pointer">{_.sumBy(reportData, 'lostLeads.actual')}</td>
                                        <td className="text-center table-sub-total">
                                            <NumberFormat
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                displayType={'text'}
                                                value={_.sumBy(reportData, 'costperlost').toFixed(2)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    )
                }
                {
                    dealersettings ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {dealersettings.address ? <>{dealersettings.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {dealersettings.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{dealersettings.phone}</span></> : <></>}
                                    {dealersettings.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{dealersettings.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }
            </section >
        </div >
    </>)
}

export default MarketingReport;