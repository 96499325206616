import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { reportGroupBy } from '../../reports/viewModel'
import _images from '../../../images'
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';


const DealerReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [sortConfig, setSortConfig] = useState();
    const [progressReport, setProgressReport] = useState(props.dealerData)

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])




    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, sortConfig]);



    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            {
                                progressReport && progressReport.length === 0 ? (
                                    <div className="common-table">
                                        <div className="text-center p-5">
                                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                            <div className="no-data-txt mt-2"> No records found</div>
                                        </div>
                                    </div>
                                ) : <div className="common-table" style={{ overflowX: 'auto', height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <td className="head-light" width="16%">
                                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                        e.preventDefault()
                                                        requestSort('name')
                                                    }}>
                                                        <div className="table-sort"><a href="#">
                                                            {
                                                                sortConfig && sortConfig.key === 'name' ?
                                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                            }
                                                        </a>
                                                        </div>
                                                        {CommonHelper.getCustomValue(reportGroupBy, props.report.groupBy, 'tableHeader', 'value')}
                                                    </div>
                                                </td>
                                                {
                                                    props.report.columns.map((rec, index) => {
                                                        return <td key={index} className="head-light text-center" width="7%">
                                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                                e.preventDefault()
                                                                requestSort(rec)
                                                            }}>
                                                                <div className="table-sort"><a href="#">
                                                                    {
                                                                        sortConfig && sortConfig.key === rec ?
                                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                                    }
                                                                </a>
                                                                </div>
                                                                {
                                                                    props.reportDynColumns.find(item => item.value === rec) ?
                                                                        (<>{CommonHelper.getLabelByValue(props.reportDynColumns, rec)}</>) :
                                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                                }
                                                                {
                                                                    rec.includes('enqtype-') ? (
                                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                                    ) : (<></>)
                                                                }
                                                                {
                                                                    rec.includes('outcome-') ? (
                                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                                    ) : (<></>)
                                                                }
                                                            </div>
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                sortedItems.length > 0 ?
                                                    sortedItems.map((rec, index) => {
                                                        return <tr key={index}>
                                                            <td>
                                                                {
                                                                    props.report.groupBy === 'user' ? (<div className="table-user-list">
                                                                        <a href="#">
                                                                            <div className="table-user-avatar">
                                                                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                                            {rec.displayName}
                                                                        </a>
                                                                    </div>) : (
                                                                        <div className="dealership-tbl">
                                                                            {rec.displayName}
                                                                        </div>
                                                                    )
                                                                }
                                                            </td>
                                                            {
                                                                props.report.columns.map((val, index) => {
                                                                    return <td key={index} className="text-center"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}>{`${rec[val]}${props.reportDynColumns.find(item => item.value === val) && props.reportDynColumns.find(item => item.value === val).percentage ? '%' : ''}`}</td>
                                                                })
                                                            }
                                                        </tr>
                                                    }) : (
                                                        <tr>
                                                            <td colSpan={props.report.columns.length + 1}>
                                                                <div className="spinner-loader h-100 p-5">
                                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                        <div className="no-data-txt mt-2"> No data</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                            }
                                        </tbody>
                                        {
                                            sortedItems.length > 0 ?
                                                (<tfoot>
                                                    <tr>
                                                        <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                                        {
                                                            props.report.columns.map((val, index) => {
                                                                return <td key={index} className="text-center table-sub-total"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                    }}>
                                                                    {
                                                                        (val === 'walkintestdrive' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                            val === 'walkinsold' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0) :
                                                                                val === 'appointmenttestdrive' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0) :
                                                                                    val === 'testdrivetotestdrive' ? (_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0) :
                                                                                        val === 'appointmentsold' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "apptsold")) : 0) :
                                                                                            val === 'testdriveConv' ? (_.sumBy(progressReport, 'total') > 0 ? Math.round((100 / _.sumBy(progressReport, 'total')) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                                                val === 'tradeinwonConv' ? (_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0) :
                                                                                                    val === 'wonConv' ? (_.sumBy(progressReport, 'total') > 0 ? Math.round((100 / _.sumBy(progressReport, 'total')) * _.sumBy(progressReport, "won")) : 0) :
                                                                                                        val === 'responseTime' ? (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                            val === 'maxresponseTime' ? (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                val === 'minresponseTime' ? (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                    _.sumBy(progressReport, val))
                                                                    }
                                                                    {props.reportDynColumns.find(item => item.value === val) && props.reportDynColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                </tfoot>
                                                ) : (<></>)
                                        }
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DealerReport;