import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText, ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';

const queueTimer = [
    { active: true, label: 'Enable', value: true },
    { active: true, label: 'Disable', value: false }
]

const AddQueueSettings = (props) => { 
    const [department, setDepartment] = useState(props.department);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)



    const handleOnChange = (e) => {
        e.preventDefault()
    }

    const handleReactSelectChange = (e, data) => {
        setDepartment({
            ...department,
            [data.name]: Boolean(e.value)
        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDepartment({
                ...department,
                [e.target.name]: number !== '' ? Number(number) : ''
            });
        }

        setErrorFields({
            ...errorFields,
            [e.target.name]: ''
        });
    };


    const saveQueue = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (department['qTimer'] === undefined || _.isEmpty(department['qTimer'].toString())) {
            formIsValid = false;
            errors['qTimer'] = errorClass;
        }
        if (department['qTimer'] === true && (!department['qTimeout'] || department['qTimeout'] === 0 || _.isEmpty(department['qTimeout'].toString()))) {
            formIsValid = false;
            errors['qTimeout'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        window.firebase.firestore().doc(`clients/${props.selectedClient.documentID}/queues/${department.documentID}`)
            .set({
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                qTime: window.firebase.firestore.Timestamp.now(),
                qTimeout: department.qTimeout ? department.qTimeout : 0,
                qTimer: department.qTimer,
                users : department.users

            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Queue settings updated successfully', {
                    duration: 2000
                })
                props.handleClose()
            })
            .catch(error => {
                console.log(error);
                Swal.fire(error, '', 'error')
            });
    }

    return _.isEmpty(department) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Department Name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={department.name}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'Queue Timer'} /></label>
                                    <ReactSelect
                                        options={queueTimer}
                                        name={"qTimer"}
                                        placeholder={'select queue timer'}
                                        onChange={handleReactSelectChange}
                                        value={department.qTimer}
                                        classNamePrefix={`${errorFields["qTimer"]} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    department.qTimer === true ? (
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Queue Timeout (Minutes)'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'queue timeout'}
                                                className={`form-control ${errorFields["qTimeout"]}`}
                                                name="qTimeout"
                                                onChange={handleNumberChange}
                                                value={department.qTimeout}
                                            />
                                        </div>
                                    ) : (<></>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveQueue(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddQueueSettings;