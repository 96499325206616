import React, { Component } from 'react';
import { PopUpModal } from '../../../components';
import Signature from '../../../components/signature'
import moment from 'moment'
import { default as _images } from '../../../images';
import { storageBucket } from '../../../services/helper';

const objAgreement = {
  signature: '',
  termsAndConditions: '',
  signatureSalesExecutive : '',
  signatureSalesManager  : '',
  signatureCustomer: '',
};

const _termsandCondition = '...';

class Signatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fields: Object.assign({}, objAgreement),
        errors: {},
        showSignature: false,
        type: '',
        signatureSalesExecutive : this.props.signatures.signatureSalesExecutive ? this.props.signatures.signatureSalesExecutive : '',
        signatureSalesManager  : this.props.signatures.signatureSalesManager ? this.props.signatures.signatureSalesManager : '',
        signatureCustomer: this.props.signatures.signatureCustomer ? this.props.signatures.signatureCustomer : '',
        loader: false,
        executiveLoader: false,
        managerLoader: false,
        customerLoader: false,
    }

    //this.handleSignatureClose = this.handleSignatureClose.bind(this);
  }

  //#region IMAGE UPLOAD

  saveImageToStorage = (imageBase64, id, ext) => {
    let uid = moment()._d.getTime().toString() + '.' + ext;
    let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
    this.setState({ loader: true })
    //console.log('uid', uid);
    var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`).putString(imageBase64, 'data_url');
    storageRef.on('state_changed', (snapshot) => {
        //console.log(snapshot);
    },
        (error) => {
            console.error(error);
        },
        () => {
            storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}`).child(uid).getDownloadURL()
                .then(dataURL => {
                    let state = Object.assign({}, this.state.fields);
                    state[id] = dataURL;
                    state['termsAndConditions'] = this.props.termsandCondition ? this.props.termsandCondition : _termsandCondition;
                    this.setState({ [this.state.type]: dataURL }, () => { 
                      this.errorChange(id)
                      this.props.handleSetSignatures({
                        signatureSalesExecutive: this.state.signatureSalesExecutive,
                        signatureSalesManager: this.state.signatureSalesManager,
                        signatureCustomer: this.state.signatureCustomer,
                      })
                      this.setState({ loader: false })
                    });
                })
        })
};

//#endregion

handleSignatureClose = (imgURL) => {
  //console.log('imageURl, type', this.state.type, imgURL)
    if (imgURL) {
        let state = Object.assign({}, this.state.fields);
        state.signature = imgURL;
        this.setState({ fields: state, showSignature: false }, () => { this.errorChange('signature') });

        // @CHECK for what to return a base64 image or uploaded image url
        if (this.props.shouldReturnBase64 === true) {
            this.setState({
                fields: {
                    signature: imgURL,
                    termsAndConditions: this.props.termsandCondition
                        ? this.props.termsandCondition
                        : _termsandCondition,
                },
            });
        } else {
            this.saveImageToStorage(imgURL, 'signature', 'png');
        }
    }
    else {
        this.setState({ showSignature: false })
    }
}

errorChange = (key) => {
    let errors = Object.assign({}, this.state.errors);
    let fields = Object.assign({}, this.state.fields);
    let errorClass = 'input_error';

    if (errors !== {}) {
        if (!fields[key])
            errors[key] = errorClass;
        else
            errors[key] = '';
        this.setState({ errors: errors });
    }
}
//#endregion

//#region RENDER & SUBMIT

componentDidMount() {
    const fields = Object.assign({}, objAgreement);
    const objData = Object.assign({}, this.props.terms);

    for (let [key, value] of Object.entries(objData)) {
        fields[key] = value;
    }

    this.setState({ fields: fields })
}

componentWillUnmount() {

}

handleSubmit = (e) => {
    e.preventDefault();
    //console.log('handleSubmit refId=>', this.newDocID);
    let errors = {};
    let formIsValid = true;
    let fields = Object.assign({}, this.state.fields);
    let errorClass = 'input_error';

    for (let [key, value] of Object.entries(fields)) {
        if (!fields[key]) {
            formIsValid = false;
            errors[key] = errorClass;

        }
    }
    this.setState({ errors: errors });

    if (formIsValid === true) {
        //console.log('formIsValid')
        this.props.handleClose(fields)
    }
    else {
        this.setState({ showSignature: true })
        return formIsValid;
    }

  };



  render() {
    return (
      <>
      <div className='sign-wrap'>
        <div className='sign-box'>
          <div className='sign-box-head'>Sales Executive</div>
          <div className='sign-box-sign margin-top relative' onClick={(e) => {
              e.preventDefault();
              if (!this.state.loader) {
                this.setState({ showSignature: true, type: 'signatureSalesExecutive' })
              }
          }}>
            {this.state.loader && this.state.type === 'signatureSalesExecutive' && (
              <div className="img-loader">
                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                </div>
              </div>
            )}
            {this.props.signatures.signatureSalesExecutive ? (<>
              <div className="editsign-btn" style={{top: '0px'}}>
                <a href="#" className="editsign mini-button" >
                    <i className="ico icon-edit"></i>
                </a>
              </div>
              <img src={this.props.signatures.signatureSalesExecutive} alt='' height='50' style={{maxWidth: '150px'}}/>
              </>
            ) : (<img src={_images.nosign} alt='' height='50' />)}
          </div>
  
          <div>
            <b>{this.props.salesExecutiveName} </b>
            {/* <b>27/04/2021</b> */}
          </div>
        </div>
  
        <div className='sign-box'>
          <div className='sign-box-head'>Sales Manager</div>
          <div className='sign-box-sign margin-top relative' onClick={(e) => {
              e.preventDefault();
              if (!this.state.loader) {
                this.setState({ showSignature: true, type: 'signatureSalesManager' })
              }
          }}>
            {this.state.loader && this.state.type === 'signatureSalesManager' && (
              <div className="img-loader">
                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                </div>
              </div>
            )}
            {this.props.signatures.signatureSalesManager ? (<>
              <div className="editsign-btn" style={{top: '0px'}}>
                <a href="#" className="editsign mini-button" >
                    <i className="ico icon-edit"></i>
                </a>
              </div>
              <img src={this.props.signatures.signatureSalesManager} alt='' height='50' style={{maxWidth: '150px'}} /></>
            ) : (<img src={_images.nosign} alt='' height='50' />)}
          </div>

          {/* <b>David Johnny</b> */}
          {/* <b>27/04/2021</b> */}
        </div>
  
        <div className='sign-box'>
          <div className='sign-box-head'>Customer Signature</div>
          <div className='sign-box-sign margin-top relative' onClick={(e) => {
              e.preventDefault();
              if (!this.state.loader) {
                this.setState({ showSignature: true, type: 'signatureCustomer'  })
              }
          }}>
            {this.state.loader && this.state.type === 'signatureCustomer' && (
              <div className="img-loader">
                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                </div>
              </div>
            )}
            {this.props.signatures.signatureCustomer ? (<>
              <div className="editsign-btn" style={{top: '0px'}}>
                <a href="#" className="editsign mini-button" >
                    <i className="ico icon-edit"></i>
                </a>
              </div>
              <img src={this.props.signatures.signatureCustomer} alt='' height='50' style={{maxWidth: '150px'}}/></>
            ) : (<img src={_images.nosign} alt='' height='50'  />)}
          </div>
          <b>{this.props.customerName}</b>
          {/* <b>27/04/2021</b> */}
        </div>
      </div>
      <PopUpModal show={this.state.showSignature}>
          <Signature
              show={this.state.showSignature}
              clsActive='overlay-modal active'
              handleClose={this.handleSignatureClose}
          ></Signature>
      </PopUpModal>
      </>
    )
  }
}

export default Signatures;
