/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash'
import { Button, Popover } from 'antd';
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopUpModal } from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
/** VIEW-MODELS */
import { stockStatus, enquiryStatus } from '../../services/enum'
import Specifications from './add/specifications'
import StockHistory from './history'


const useHover = (props) => {
    const [isHovering, setIsHovering] = React.useState(false);

    const handleMouseOver = React.useCallback(() => { console.log('handleMouseOver', props.priceValue); setIsHovering(true) }, []);
    const handleMouseOut = React.useCallback(() => { console.log('handleMouseOut', props.priceValue); setIsHovering(false) }, []);

    const nodeRef = React.useRef();

    const callbackRef = React.useCallback(
        node => {
            if (nodeRef.current) {
                nodeRef.current.removeEventListener('mouseover', handleMouseOver);
                nodeRef.current.removeEventListener('mouseout', handleMouseOut);
            }

            nodeRef.current = node;

            if (nodeRef.current) {
                nodeRef.current.addEventListener('mouseover', handleMouseOver);
                nodeRef.current.addEventListener('mouseout', handleMouseOut);
            }
        },
        [handleMouseOver, handleMouseOut]
    );

    return [callbackRef, isHovering];
};

const PriceHistory = React.memo((props) => {
    const [hoverRef, isHovering] = useHover(props);
    const [content, setContent] = useState()

    useEffect(() => {

        if (!isHovering)
            return;

        handleHoverChange(isHovering);

    }, [isHovering]);

    const handleHoverChange = async (open) => {
        if (open) {
            let _priceLogs = [];

            try {
                await window.firebase.firestore().collection('changeLogs')
                    .where('recordId', '==', props.stockid)
                    .orderBy("newData.price", "desc")
                    .get().then(querySnapshot => {
                        querySnapshot.docs.forEach((doc) => {
                            let _data = doc.data();
                            if (_data?.oldData.price) {//_data?.newData?.price || 
                                _priceLogs.push({
                                    ..._data,
                                    documentID: doc.id,
                                    newPrice: _data?.newData?.price,
                                    oldPrice: _data?.oldData.price,
                                    addedDate: _data.logDate ? moment.unix(_data.logDate.seconds).format('DD MMM YYYY') : null,
                                    addedBy: _data.logBy ? CommonHelper.getUserNamebyId(props.allUsers, _data.logBy) : _data.addedByName
                                });
                            }

                        });
                    });
            } catch (error) {
                console.log(error);
            }


            setContent(<>{
                !_.isEmpty(_priceLogs)
                    ?
                    <>
                        <div class="stocknew-tooltip-white-table m-0 p-0" style={{
                            height: '200px',
                            maxHeight: '400px',
                            overflow: 'auto',
                            position: "relative"
                        }}>
                            <table class="table pr-1">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _.orderBy(_priceLogs, ['logDate'], ['desc']).map((log, index) => {
                                            return <tr key={index}>
                                                <td>{log.addedDate}
                                                    <div class="stocknew-tooltip-table-user">{log.addedBy}</div>
                                                </td>
                                                <td>
                                                    <span class="stocknew-tooltip-table-price">
                                                        {log.oldPrice ? <NumberFormat value={log.oldPrice} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props.currencySymbol} /> : <>{'empty'}</>}
                                                    </span>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <>
                        <div className='stocknew-tooltip-white-table m-0 p-0'>
                            <div className='no-data-flex justify-content-center align-items-center pb-3'>
                                {/* <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div> */}
                                <div className='no-data-txt mt-2'>  <Translate text={'No Logs'} /></div>
                            </div>
                        </div>
                    </>
            }</>)
        }
        else {
            setContent(<></>)
        }
    };


    return (
        <>

            <div ref={hoverRef}>
                <Popover
                    style={{ width: 500 }}
                    content={isHovering ? content : <></>}
                    title="Price History"
                    trigger="hover"
                    position={'left'}
                    open={isHovering}
                    onOpenChange={handleHoverChange}
                >
                    <NumberFormat
                        thousandSeparator={true}
                        prefix={props.currencySymbol}
                        displayType={'text'}
                        value={props.priceValue}
                        title='Retail Price'
                    />
                </Popover>
            </div>
        </>
    )
});

export default PriceHistory;


