/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { ReactSelect, InputText } from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';



export default class Reason extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:
            {
                reason: '',
                subReason: null,
            },
            errors: {}
        }
    }

    loadEnquiry = () => {
        const { reason } = this.props;
        this.setState({
            fields:
            {
                reason: reason ? reason : ''
            },
            errors: {}
        });
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        state[data.name] = e.value;
        if (_.trim(state.subReason)) state.subReason = null;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = !_.isEmpty(_.trim(value)) ? value.split(' ') : [];
        var str = _.trim(value);


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }

    }


    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }




    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            const { docID, docType } = this.props;
            fields.docID = docID;
            fields.type = docType;
            if (_.trim(fields.subReason)) fields.reason = _.trim(fields.subReason);
            this.props.handleClose(fields);
        }
        else
            return formIsValid;

    };

    handleCancel = (e) => {
        e.preventDefault();
        //this.loadEnquiry();
        let fields = Object.assign({}, this.state.fields);
        fields.reason = null;
        this.setState({ fields: fields }, () => { this.errorChange('reason'); });

    }


    render() {
        const { reason, subReason } = this.state.fields;
        const { show, title, isDisable, clientUsers, isFromPage } = this.props;


        return (
            <>
                <Modal
                    id="add-notes"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-reason-pop`}>

                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`${isFromPage ? '' : 'container-fluid'}`}>
                            <div className="w-100 d-inline-block">

                                <h1 className="text-center"><Translate text={'Reason'} /></h1>
                                {/* <p className="text-center m-b mt-3 reset-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}

                                <div className="form-style w-100 mt-3">

                                    <ReactSelect
                                        options={this.props.reasons}
                                        name={"reason"}
                                        placeholder={'select reason'}
                                        onChange={this.handleSelectSave}
                                        value={reason ? reason : null}
                                        classNamePrefix={`cursor-pointer ${this.state.errors["reason"]} basic-select`}
                                        removeClearable={true}
                                        isSearchable={true}
                                    >
                                    </ReactSelect>
                                    {
                                        reason && ['other', 'others'].includes(reason.toLowerCase().trim()) && (<>
                                            <InputText
                                                name="subReason"
                                                value={subReason}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'please specify other reason'}
                                                className={`form-control mt-4 ${this.state.errors["subReason"]}`}
                                            />
                                        </>)
                                    }

                                    <button type="button"
                                        className={`btn btn-primary pull-left mt-4 w-100`}
                                        onClick={(e) => this.handleSubmit(e)}><Translate text={'done'} /></button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


            </>
        );
    }
}
