/** LIBRARIES */
import React, { useState, useEffect, useReducer, Suspense } from 'react';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import _ from 'lodash'
import moment from 'moment';
/** PROVIDERS */
/** VIEW-MODELS */

/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { PopoverListLoader } from '../../../components';
import { enquiryBasicDetailsVM, contactBasicDetailsVM } from '../viewModel'

import { default as _images } from '../../../images';

import { enquiryStatus } from '../../../services/enum'
import { firestoreDB } from '../../../services/helper';

const EnquiryListReducer = (state, action) => {
    function updateEnquiry() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "REMOVE_ENQUIRY": {
            return state.filter(item => item.documentID !== action.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateEnquiry()
        }
        default:
            return state;
    }
};

const EnquiryList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [hasMore, setHasMoreData] = useState([])

    const [isPaging, setPaging] = useState(false)
    const [loader, setLoader] = useState(true)

    const pageLimit = 20
    const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : [])


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const { dealersettings } = props;

        if (!checkDataLoad) {
            return;
        }

        let enquirieslstIdxDataRef = firestoreDB(dealersettings).firestore().collection('enquiries')

        enquirieslstIdxDataRef = enquirieslstIdxDataRef
            .where('clientID', '==', dealersettings.client.id)
            .where('isDeleted', '==', false)

        if (_.trim(searchText) && _.trim(searchText).length > 2) {
            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            if (formattedSearchText.trim())
                enquirieslstIdxDataRef = enquirieslstIdxDataRef
                    .where('keywords', 'array-contains', formattedSearchText.trim().toLowerCase())
                    .orderBy('stageDate', 'desc')
        }
        else {
            enquirieslstIdxDataRef = enquirieslstIdxDataRef
                .where('status', 'in', ['won', 'delivered'])
                .orderBy('wonDate', 'asc')
        }

        if (hasMore.length > 0) {
            enquirieslstIdxDataRef = enquirieslstIdxDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            enquirieslstIdxDataRef = enquirieslstIdxDataRef
                .limit(pageLimit)
        }

        enquirieslstIdxDataRef
            //.onSnapshot(onCollectionUpdate);
            .get().then(querySnapshot => {
                const _enquiries = [];

                if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                    setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
                }
                else {
                    setHasMoreData([]);
                }
                querySnapshot.docs.forEach((doc) => {
                    let _enq = Object.assign({}, enquiryBasicDetailsVM);
                    for (let [key, value] of Object.entries(doc.data())) {
                        if (_enq.hasOwnProperty(key))
                            _enq[key] = value;
                    }
                    _enq.documentID = doc.id;
                    _enq.contactID = !_.isEmpty(doc.data().contact) ? doc.data().contact.documentID : null;
                    _enq.contact = !_.isEmpty(doc.data().contact) ? { ..._.pick(doc.data().contact, _.keys(contactBasicDetailsVM)) } : null;
                    _enquiries.push(_enq);
                });

                if (isPaging) {
                    dispatch({
                        type: "APPEND_LIST",
                        data: _enquiries.filter(a => a.status === 'won' || a.status === 'delivered')
                    });
                }
                else {
                    dispatch({
                        type: "SUCCESS",
                        data: _enquiries.filter(a => a.status === 'won' || a.status === 'delivered')
                    });
                }

                setDataload(false)
                setLoader(false)
                setPaging(false)

            });


    }, [checkDataLoad])


    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const listenScrollEvent = (e) => {
        if (hasMore.length === 0) return;
        //console.log('listenScrollEvent', e.target.scrollHeight - e.target.scrollTop, e.target.offsetHeight)
        if (e.target.scrollHeight - e.target.scrollTop === e.target.offsetHeight) {
            //console.log('listenScrollEvent')
            //handlePagination();
        }
        return;
    }

    const divStyle = {
        height: '500px',
        overflow: 'auto',
        position: "relative"
    };

    return (
        <>

            <Modal
                id="appraisal-List"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-terms">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'tradeIn'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className={`vehicle-popover-head pr-0`}>
                            <input
                                type="text"
                                aria-label="Search"
                                placeholder="Search..."
                                value={searchText ? searchText : ''}
                                autoComplete="off"
                                onChange={e => {
                                    let _value = e.target.value;
                                    if (!_value)
                                        setSearchText('');
                                    else
                                        setSearchText(_value);

                                    if (_.trim(_value) && _.trim(_value).length > 2)
                                        setLoader(true);

                                    setTimeout(() => {
                                        setHasMoreData([]);
                                        setDataload(true);
                                    }, 1000);


                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setHasMoreData([]);
                                        setDataload(true)
                                        setLoader(true)
                                    }
                                }}
                            />

                        </div>

                        <Suspense>
                            <div style={divStyle} onScroll={listenScrollEvent}>
                                {
                                    loader ? (<PopoverListLoader />) :
                                        (enquiries && enquiries.length > 0)
                                            ?
                                            <>
                                                {enquiries.map((enquiry, index) =>
                                                    <div key={index} className="vehicle-popover-loop" onClick={(e) => {
                                                        e.preventDefault();
                                                        props.handleEnquiryClose(enquiry.documentID, enquiry)

                                                    }}>
                                                        <div className="divTable">
                                                            <div className="divTableBody">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell width70" data-id={enquiry.documentID}>
                                                                        <div className="customer-avatar-content">
                                                                            <div className="customer-avatar-image">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}> <img src={CommonHelper.showBrandLogo(props.dealersettings, (!_.isEmpty(enquiry?.requirement?.stock) ? enquiry.requirement.stock?.make : enquiry.requirement?.make))} className="img-object-fit-contain" alt="" width="70" height="70" /> </a>
                                                                            </div>
                                                                            {
                                                                                enquiry.displayID ?
                                                                                    (<div className="input-readonly-sub"><i className="ico icon-enquiry mr-1"></i>{enquiry.displayID}</div>) :
                                                                                    (<></>)
                                                                            }
                                                                            <div className="customer-avatar-title">

                                                                                {
                                                                                    (!_.isEmpty(enquiry.requirement) && (enquiry.requirement.make || enquiry.requirement.stock))
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                (!_.isEmpty(enquiry.requirement.stock) && !_.isEmpty(enquiry.requirement.stock.make))
                                                                                                    ?
                                                                                                    <span>
                                                                                                        <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                        {(enquiry.requirement.stock.make ? enquiry.requirement.stock.make : '') + ' ' + (enquiry.requirement.stock.model ? enquiry.requirement.stock.model : '')}
                                                                                                        {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                            {CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                                                                                                                enquiry.requirement.saleType, '')}
                                                                                                        </div>) : ''}

                                                                                                    </span>
                                                                                                    :
                                                                                                    <span>
                                                                                                        <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'mr-1 ico icon-Stock')}></i>
                                                                                                        {(enquiry.requirement.make ? enquiry.requirement.make : '') + ' ' + (enquiry.requirement.model ? enquiry.requirement.model : '')}
                                                                                                        {enquiry.requirement.saleType ? (<div className="badge badge-pill badge-white badge-mini ml-1">
                                                                                                            {CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                                                                                                                enquiry.requirement.saleType, '')}
                                                                                                        </div>) : ''}

                                                                                                    </span>
                                                                                            }

                                                                                            {
                                                                                                enquiry.requirementCount && enquiry.requirementCount > 1
                                                                                                    ?
                                                                                                    <OverlayTrigger
                                                                                                        placement='bottom'
                                                                                                        overlay={
                                                                                                            <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="badge badge-pill badge-blue badge-mini ml-1">
                                                                                                            +{enquiry.requirementCount - 1} <Translate text={'more'} /></div>
                                                                                                    </OverlayTrigger>

                                                                                                    :
                                                                                                    <></>
                                                                                            }


                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>

                                                                                        </>

                                                                                }

                                                                            </div>


                                                                            {
                                                                                !_.isEmpty(enquiry.contact) ? (

                                                                                    <div className="link-enquiry-owner" style={{ borderBottom: '0px' }}>
                                                                                        <div className="link-enquiry-owner-view">
                                                                                            {CommonHelper.displayContactIcon(enquiry.contact, 'mr-1')}
                                                                                        </div>{CommonHelper.displayContact([], enquiry.contact, '--')}</div>
                                                                                ) : (<></>)
                                                                            }
                                                                            <div className="vehicle-item-status">
                                                                                {
                                                                                    enquiry.deliveryDate ? (
                                                                                        <div className="pipeline-item-div" title={'Delivery Date'}><i className="ico icon-timer"></i>
                                                                                            {moment.unix(enquiry.deliveryDate.seconds).format('DD MMM YYYY')}
                                                                                        </div>
                                                                                    ) : (<></>)
                                                                                }
                                                                                {
                                                                                    enquiry.status ?
                                                                                        <>
                                                                                            {(() => {
                                                                                                let ___status = (enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : enquiry.status;
                                                                                                let _objCurrentStatus = enquiryAllStatus && enquiryAllStatus.filter(e => e.value === ___status)[0]

                                                                                                return <>
                                                                                                    {
                                                                                                        _objCurrentStatus
                                                                                                            ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className={`status-${___status === 'open' ? 'open' : 'empty'}  float-right`}
                                                                                                                    style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                                                >
                                                                                                                    {_objCurrentStatus.name}
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            <></>
                                                                                                    }
                                                                                                </>
                                                                                            })()}
                                                                                        </>
                                                                                        :
                                                                                        <></>
                                                                                }
                                                                                <OverlayTrigger
                                                                                    placement='bottom'
                                                                                    overlay={
                                                                                        <Tooltip>{CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), enquiry.owner)}</Tooltip>
                                                                                    }
                                                                                >
                                                                                    <div className="calllogs-vehicle-owner float-right ml-2">
                                                                                        <div className="owner-view">
                                                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                                <img src={CommonHelper.getUserImagebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), enquiry.owner ? enquiry.owner : enquiry.documentID)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}</>
                                            :
                                            <>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-cafe-img">
                                                            <i className="ico icon-enquiry"></i> </div>
                                                        <div className="no-data-txt mt-2"> <Translate text={'noEnquiriesFound'} /></div>
                                                    </div>

                                                </div>
                                            </>
                                }

                            </div>
                        </Suspense>

                    </>
                </Modal.Body>
                {
                    hasMore && hasMore.length > 0 ? (
                        <Modal.Footer >
                            <div className="load-more-button pb-2"> <a href="#" className="mt-1" onClick={(e) => {
                                e.preventDefault();
                                handlePagination();
                            }}><Translate text={'Load More'} />
                                {
                                    !_.isEmpty(checkDataLoad) ? (
                                        <span className="spinner-border spinner-button ml-2 float-right" role="status" aria-hidden="true"></span>
                                    ) : (<i className="fa fa-angle-down" aria-hidden="true"></i>)
                                }

                            </a></div>
                        </Modal.Footer>

                    ) : (<></>)
                }
            </Modal>


        </>
    )
}

export default EnquiryList