/** LIBRARIES */
import React, { useState, useEffect, useReducer, useRef, Fragment } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import _, { stubFalse } from 'lodash'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
/** PROVIDERS */
//import { LayoutConsumer } from '../layout/provider';
/** VIEW-MODELS */
import { allStockFieldsVM, objStock } from '../viewModel';
/** COMPONENTS */
import CommonHelper from '../../../services/common';
import { BarChart, ContentOverlay, PopUpModal, TableView } from '../../../components';
import FilterPanel from '../filter'
import ReAuthenticate from '../../common/reAuthenticate';
import { stockStatus, stockAvail } from '../../../services/enum'
//import { default as _images } from '../../images';
import AddStock from '../../stock/add'
import StockHistory from '../history'
import OEMStock from '../oemStock/oemStock'

//import StockListReducer from './stockListReducer'
import FleetReducer from '../../fleet/fleetReducer'
import { firestoreDB } from '../../../services/helper';
import StockList from './stockList';
import { stockAge, columnValues, stockOverview, reportModels } from './viewModel';
import ReportHelper from './reportHelper';
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
import ScheduleReport from '../../reports/scheduleReport';

const stockTabsVM = [
    { active: true, value: 'pipeline', name: 'Pipeline', id: 'pipeline', module: 'oem' },
    { active: true, value: 'stock', name: 'Stock', id: 'stock', module: 'oem' },
    { active: true, hideScroll: true, value: 'unscheduled', name: 'Unscheduled Orders', id: 'unscheduled', module: 'oem' },
    { active: true, hideScroll: true, value: 'preowned', name: 'Preowned Stock', id: 'preowned' },
    { active: true, hideScroll: true, value: 'demo', name: 'Demo Stock', id: 'demo' },
    { active: true, hideScroll: true, value: 'delivered', name: 'Retailed Stock', id: 'delivered' },
    { active: true, hideScroll: true, value: 'unavailable', name: 'Unavailable Stock', id: 'unavailable' },
]

const statisFilters = [
    'region',
    'subregion',
    'country',
    'clients'
];

const StockPipeline = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 50), windowHeight: (window.innerHeight - 123) })
    // const [stocks, dispatch] = useReducer(StockListReducer, [])//
    // const [checkDataLoad, setDataload] = useState(true)//
    const [reportData, setReportData] = useState([]);
    const [tblStockAge, setTblStockAge] = useState([]);
    const [rptStockAge, setRptStockAge] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [tblstockOverview, setStockOverview] = useState({
        sold: [],
        notRetailed: [],
        notWholesaled: []
    })
    const [loader, setLoader] = useState(true)
    const [activeTab, setActiveTab] = useState('pipeline')
    const [qualifiedStocks, setQualifiedStocks] = useState({})


    const [stocks, dispatch] = useReducer(FleetReducer, { NewStock: [], OtherStock: [] })
    const [checkDataLoad, setDataload] = useState({ "Stock": true, "Pipeline": true, "Delivered": true, "Sold": true, "Unavailable": true })
    const [sortName, setSortName] = useState(localStorage.stocksortName ? localStorage.stocksortName : 'modifiedDate')
    const [sortOrder, setSortOrder] = useState(localStorage.stocksortOrder ? localStorage.stocksortOrder : 'desc')
    const [hasMore, setHasMoreData] = useState([])
    const [stockFields, setStockFields] = useState(props.dealersettings.stockFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    const [OEMShow, setOEMShow] = useState(false)
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    //const [stockLoader, setStockLoader] = useState(true)
    const pageLimit = 50

    const [stockLoader, setStockLoader] = useState({ "NewStock": true, "OtherStock": true })

    const [stockTabs, setTabs] = useState(stockTabsVM)

    const [customFilters, setCustomFilters] = useState([])
    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0)

    const [csvHeader, setHeader] = useState([])

    const [searchText, setSearchText] = useState(null)
    const [stockCount, setStockCount] = useState({})
    const [stockALLCount, setStockALLCount] = useState(0)
    const [scheduleRptModel, setscheduleRptModel] = useState({ show: false })
    const [scheduleReport, setScheduleReport] = useState({});
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const enableSharedStock = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedStock) ? true : false);

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const deleteStock = ((!_.isEmpty(_permissions) && _permissions.deleteStock) ? true : false);
    const accessToPipelineStock = ((!_.isEmpty(_permissions) && _permissions.accessToPipelineStock) ? true : false);
    const accessToStockVBM = ((!_.isEmpty(_permissions) && _permissions.accessToStockVBM) ? true : false);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    const _servicePermissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.servicePermissions)) ? props.dealersettings.rolePermissions.servicePermissions : null);
    const deleteServiceStock = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteServiceStock) ? true : false);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const vbmcurrencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.vbm)) ? props.dealersettings.client.vbm.symbol + ' ' : '$ ');

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])
    const [allStock, setAllStock] = useState([])
    const [unavailableStock, setUnavailableStock] = useState([])
    const [filterLoader, setFilterLoader] = useState(true);
    const [objFilter, setObjFilter] = useState({});
    const [filterClients, setFilterClients] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [showStockList, setShowStockList] = useState({ show: false });
    const stockUsage = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.stockUsage : [])
    let allStockFieldsListVM = [...allStockFieldsVM];
    allStockFieldsListVM = allStockFieldsListVM.filter(item => accessToStockVBM ? true : item.group !== 'VBM')
    const stockOptionsStatic = props.dealersettings?.client?.settings?.stockOptionsStatic
    
    useEffect(() => {
        const refScheduleReport = window.firebase.firestore().doc(`schedular/stockpipeline${props.dealersettings.client.id}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setScheduleReport(doc.data())
                }
                else {
                    setScheduleReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchText && searchText.length >= 2) {
                setFilterLoader(!filterLoader)
            }
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    useEffect(() => {

        const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : [])
        allStockFieldsListVM.forEach((obj) => {
            if (obj.value === 'stockInDate' && props.dealersettings.isMclaren === true) {
                obj.name = 'Wholesale Date';
            }
            else if (obj.value === 'soldDate') {
                obj.name = CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date';
            }
            else if (obj.value === 'deliveredDate') {
                obj.name = CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date';
            }
        });

        let headerFields = stockFields;
        let allHeaderFields = allStockFieldsListVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        setHeader(_headers);

    }, [stockFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 50),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubStocks && window.unSubStocks.unsubscribe();
        }

    }, [])

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'stocks')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    // useEffect(() => {
    //     //console.log('localStorage.pipelineFilter', isFilterApplied, localStorage.pipelineFilter)
    //     if (localStorage.stockFilter && !isFilterApplied)
    //         setFilterApplied(true);

    // }, [localStorage.stockFilter])

    useEffect(() => {
        const { dealersettings } = props;
        if (isFilterApplied && localStorage.stockFilter) {
            window.unSubStocks && window.unSubStocks.unsubscribe();
            let _stockFilter = JSON.parse(localStorage.stockFilter);
            let _filter = Object.assign({}, _stockFilter.value);
            let _isWhere = false;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }

            if (!_.isEmpty(_filter.make))
                _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

            let _filterStatus = _filter.status;
            if (activeTab === 'Stock') {
                _filter.status = _filter.status ?
                    ((_filter.status === 'available' || _filter.status === 'onLoan' || _filter.status === 'onTestdrive' || _filter.status === 'reserved') ? _filter.status : 'unknown')
                    : 'available,onLoan,onTestdrive,reserved'
            }
            else if (activeTab === 'Pipeline') {
                _filter.status = _filter.status ? (_filter.status === 'inProduction' ? _filter.status : 'unknown') : 'inProduction'
            }
            else if (activeTab === 'Sold') {
                _filter.status = _filter.status ? (_filter.status === 'sold' ? _filter.status : 'unknown') : 'sold'
            }
            else if (activeTab === 'Delivered') {
                _filter.status = _filter.status ? (_filter.status === 'delivered' ? _filter.status : 'unknown') : 'delivered'
            }
            else if (activeTab === 'Unavailable') {
                _filter.status = _filter.status ? (_filter.status === 'unavailable' ? _filter.status : 'unknown') : 'unavailable'
            }
            if (localStorage.defaultModule === 'oem' && props?.dealersettings?.clients?.length > 0) {
                _filter.clientID = props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(r => { return r.id }).join(',')
            }
            else {
                if (enableSharedStock && props.dealersettings.client.group)
                    _filter.groupID = props.dealersettings.client.group
                else
                    _filter.clientID = props.dealersettings.client.id;
            }
            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            let _searchObject = {
                "type": "searchStock",
                "filters": JSON.stringify(_filter),
                "sortOrder": "modifiedDate desc",
                "pageNum": 0,
                "pageLimit": 100
            }
            if (localStorage.defaultModule === 'oem' && props?.dealersettings?.clients?.length > 0) _searchObject.clientID = _filter.clientID;
            else {
                if (enableSharedStock && props.dealersettings.client.group)
                    _searchObject.groupID = props.dealersettings.client.group
                else
                    _searchObject.clientID = props.dealersettings.client.id;

                //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);
            }
            setLoader(true)
            const searchStock = window.firebase.functions().httpsCallable('generic-searchData');
            searchStock(_searchObject).then((response) => {
                //console.log('generic-searchStock', response);
                if (response.data.success) {
                    const _stocks = [];
                    const _unavailablestocks = [];
                    response.data.data.forEach(function (doc) {
                        var dataVM = dataMappingVM(doc);
                        if (dataVM.statusValue === 'unavailable') {
                            _unavailablestocks.push(dataVM)

                        } else {
                            _stocks.push(dataVM)
                        }
                    });
                    setAllStock(_stocks);
                    setUnavailableStock(_unavailablestocks);
                    setLoader(false);
                }
                else {
                    setLoader(false)
                }
            });
        }
    }, [isFilterApplied])

    useEffect(() => {
        if (isFilterApplied || !loader)
            return

        let stockcollectionPromise = [];
        if (localStorage.defaultModule === 'oem' && props?.dealersettings?.clients?.filter(a => a.settingsID === props.dealersettings.oemID)?.length > 0) {
            props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).forEach(rec => {
                let collectionDataRef = window.firebase.firestore().collection('stock')
                    .where('isDeleted', '==', false)
                    .where('clientID', '==', rec.id)
                    .where('saleType', '==', 'New')

                stockcollectionPromise.push(collectionDataRef ? collection(collectionDataRef) : of([]));
            })
        }
        else {
            let collectionDataRef = window.firebase.firestore().collection('stock')
                .where('isDeleted', '==', false)
            //.where('saleType', '==', 'New')
            if (enableSharedStock && props.dealersettings.client.group) {
                collectionDataRef = collectionDataRef.where('groupID', '==', props.dealersettings.client.group)
            }
            else {
                collectionDataRef = collectionDataRef.where('clientID', '==', props.dealersettings.client.id)
            }
            if (searchText && searchText.trim()) {
                collectionDataRef = collectionDataRef
                    .where('keywords', 'array-contains', searchText.trim().toLowerCase())
            }
            stockcollectionPromise.push(collectionDataRef ? collection(collectionDataRef) : of([]));
        }


        window.unSubStocks = combineLatest(stockcollectionPromise)
            .subscribe(allDocs => {
                var querySnapshot = [];
                allDocs.forEach(doc => {
                    querySnapshot.push(...doc);
                });
                const _stocks = [];
                const _unavailablestocks = [];
                querySnapshot.forEach((doc) => {
                    if (doc.data().status === 'unavailable') {
                        _unavailablestocks.push(dataMappingVM({
                            ...doc.data(),
                            documentID: doc.id
                        }));

                    } else {
                        _stocks.push(dataMappingVM({
                            ...doc.data(),
                            documentID: doc.id
                        }));
                    }

                });
                setAllStock(_stocks);
                setUnavailableStock(_unavailablestocks);
                setFilterLoader(!filterLoader)
                setLoader(false);
            });

        /*
                window.unSubStocks = collectionDataRef
                    .onSnapshot(querySnapshot => {
                        const _stocks = [];
                        const _unavailablestocks = [];
                        querySnapshot.forEach((doc) => {
                            if (doc.data().status === 'unavailable') {
                                _unavailablestocks.push(dataMappingVM({
                                    ...doc.data(),
                                    documentID: doc.id
                                }));
        
                            } else {
                                _stocks.push(dataMappingVM({
                                    ...doc.data(),
                                    documentID: doc.id
                                }));
                            }
        
                        });
                        const customerUsage = _.find(stockUsage, { name: 'Customer' }) ? _.find(stockUsage, { name: 'Customer' }).value : 'Customer';
                        const newStocks = _stocks.filter(a => a.saleType === 'New')
                        const piplineData = ReportHelper.getReportData(newStocks, customerUsage, reportModels);
                        setChartData(ReportHelper.getChartData(columnValues));
                        setReportData(piplineData);
                        setTblStockAge(ReportHelper.getStockAge(newStocks, stockAge, reportModels));
                        setRptStockAge(ReportHelper.getRptStockAge(newStocks, stockAge, reportModels));
                        const _stockOverview = ReportHelper.getStockOverview(newStocks, reportModels)
                        setStockOverview(_stockOverview);
                        const preowned = _stocks.filter(a => (a.saleType === 'Preowned'));
                        const demo = _stocks.filter(a => (a.saleType === 'Demo'));
                        const unscheduled = _stocks.filter(a => (a.saleType === 'New' && _.isEmpty(a.mclBuildSlot)));
                        const unavailable = _unavailablestocks;
                        setQualifiedStocks({
                            preowned,
                            demo,
                            unscheduled,
                            unavailable
                        })
                        if (searchText && searchText.trim()) {
                            setStockCount({
                                unavailable: unavailable.length,
                                preowned: preowned.length,
                                demo: demo.length,
                                unscheduled: unscheduled.length,
                                pipeline: _.sumBy(piplineData.map(a => { return a.allPipeline.length })),
                                stock: _stockOverview?.notRetailed?.length + _stockOverview?.notWholesaled?.length + _stockOverview?.sold?.length
                            })
                        }
                        else {
                            setStockCount({});
                        }
                        setLoader(false)
                    });
        */
    }, [isFilterApplied, loader])

    useEffect(() => {
        if (loader)
            return

        const _stocks = allStock.filter(a =>
            (!_.isEmpty(searchText) ? a.keywords.includes(searchText.toLowerCase()) : true) &&
            (filterClients.length > 0 ? filterClients.includes(a.clientID) : true)
        );
        const newStocks = _stocks.filter(a => a.saleType === 'New');
        const customerUsage = _.find(stockUsage, { name: 'Customer' }) ? _.find(stockUsage, { name: 'Customer' }).value : 'Customer';
        const piplineData = ReportHelper.getReportData(newStocks, customerUsage, reportModels);
        setChartData(ReportHelper.getChartData(columnValues));
        setReportData(ReportHelper.getReportData(newStocks, customerUsage, reportModels));
        setTblStockAge(ReportHelper.getStockAge(newStocks, stockAge, reportModels));
        setRptStockAge(ReportHelper.getRptStockAge(newStocks, stockAge, reportModels));
        const _stockOverview = ReportHelper.getStockOverview(newStocks, reportModels)
        setStockOverview(_stockOverview);
        const preowned = _stocks.filter(a => (a.saleType === 'Preowned' && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'));
        const demo = _stocks.filter(a => (a.saleType === 'Demo' && a.statusValue !== 'delivered' && a.statusValue !== 'mclarenRetailed'));
        const unscheduled = _stocks.filter(a => (a.saleType === 'New' && _.isEmpty(a.mclBuildSlot)));
        const delivered = _stocks.filter(a => (a.statusValue === 'delivered' || a.statusValue === 'mclarenRetailed'));
        const unavailable = unavailableStock;
        setQualifiedStocks({
            preowned,
            demo,
            unscheduled,
            unavailable,
            delivered
        })
        if (searchText && searchText.trim()) {
            setStockCount({
                delivered: delivered.length,
                unavailable: unavailable.length,
                preowned: preowned.length,
                demo: demo.length,
                unscheduled: unscheduled.length,
                pipeline: _.sumBy(piplineData.map(a => { return a.allPipeline.length })),
                stock: _stockOverview?.notRetailed?.length + _stockOverview?.notWholesaled?.length + _stockOverview?.sold?.length
            })
        }
        else {
            setStockCount({});
        }

    }, [loader, filterLoader])

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;
        doc.regDate = doc.regDate && doc.regDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regDate._seconds)._d) : doc.regDate;
        doc.regExp = doc.regExp && doc.regExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.regExp._seconds)._d) : doc.regExp;
        doc.warrantyStartDate = doc.warrantyStartDate && doc.warrantyStartDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyStartDate._seconds)._d) : doc.warrantyStartDate;
        doc.warrantyExp = doc.warrantyExp && doc.warrantyExp._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.warrantyExp._seconds)._d) : doc.warrantyExp;
        doc.stockInDate = doc.stockInDate && doc.stockInDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.stockInDate._seconds)._d) : doc.stockInDate;
        doc.addedDate = doc.addedDate && doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate && doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;


        doc.etaDate = doc.etaDate && doc.etaDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.etaDate._seconds)._d) : doc.etaDate;
        doc.fokDate = doc.fokDate && doc.fokDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.fokDate._seconds)._d) : doc.fokDate;
        doc.soldDate = doc.soldDate && doc.soldDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.soldDate._seconds)._d) : doc.soldDate;
        doc.deliveredDate = doc.deliveredDate && doc.deliveredDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveredDate._seconds)._d) : doc.deliveredDate;
        doc.deliveryDate = doc.deliveryDate && doc.deliveryDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.deliveryDate._seconds)._d) : doc.deliveryDate;

        const objStockData = Object.assign({}, doc);
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objStockData)) {
            stock[key] = value;
        }
        if (stock.clientID && (stock.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group) {
            stock.dealerName = CommonHelper.getOtherDealerName(dealersettings, stock.clientID);
        }
        else
            stock.dealerName = '';

        stock.regDateValue = doc.regDate
        stock.regExpValue = doc.regExp
        stock.warrantyExpValue = doc.warrantyExp
        stock.warrantyStartDateValue = doc.warrantyStartDate
        stock.stockInDateValue = doc.stockInDate
        stock.etaDateValue = doc.etaDate
        stock.addedDateValue = doc.addedDate
        stock.modifiedDateValue = doc.modifiedDate
        stock.addedByValue = doc.addedBy
        stock.statusValue = doc.status
        stock.saleTypeValue = doc.saleType
        stock.locationValue = doc.location
        stock.usageValue = doc.usage
        stock.transmissionValue = doc.transmission
        stock.transportMethodValue = doc.transportMethod
        stock.createdOn = moment.unix(stock.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        stock.updatedOn = moment.unix(stock.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (stock.clientID && dealersettings && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[stock.clientID] && dealersettings.group.clients[stock.clientID].settings) {
            let _settings = dealersettings.group.clients[stock.clientID].settings;
            stock.location = CommonHelper.getNameByValue(_settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(_settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(_settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(_settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(_settings.salesType, stock.saleType, '');
        }
        else if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            stock.location = CommonHelper.getNameByValue(dealersettings.client.settings.stockLocation, stock.location, '');
            stock.usage = CommonHelper.getNameByValue(dealersettings.client.settings.stockUsage, stock.usage, '');
            stock.transmission = CommonHelper.getNameByValue(dealersettings.client.settings.transmissions, stock.transmission, '');
            stock.transportMethod = CommonHelper.getNameByValue(dealersettings.client.settings.stockTransport, stock.transportMethod, '');
            stock.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, stock.saleType, '');
        }

        stock.price = doc.price ? CommonHelper.formatCurrency(currencySymbol, doc.price) : '';
        stock.purchasePrice = doc.purchasePrice ? CommonHelper.formatCurrency(currencySymbol, doc.purchasePrice) : '';

        stock.marginValue = doc.marginValue ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginValue) : '';
        stock.marginRetail = doc.marginRetail ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginRetail) : '';
        stock.marginOrderFill = doc.marginOrderFill ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginOrderFill) : '';
        stock.marginPayments = doc.marginPayments ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginPayments) : '';
        stock.marginMarketing = doc.marginMarketing ? CommonHelper.formatCurrency(vbmcurrencySymbol, doc.marginMarketing) : '';

        stock.stockAge = ((!_.isEmpty(stock.etaDateValue))
            ?
            handleDiffDays(stock.etaDateValue)
            :
            '');


        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.regExp))
            stock.regExp = moment.unix(stock.regExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stock.buildDate))
            stock.buildDate = moment(stock.buildDate, 'YYYY-MM-DD').format('MMM YYYY');

        if (!_.isEmpty(stock.soldDate))
            stock.soldDate = moment.unix(stock.soldDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.deliveredDate))
            stock.deliveredDate = moment.unix(stock.deliveredDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.deliveryDate))
            stock.deliveryDate = moment.unix(stock.deliveryDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.etaDate)) {
            stock.csvEtaDate = moment.unix(stock.etaDate.seconds).format('DD/MM/YYYY') + (stock.isStockArrived ? ' (Arrived)' : '');
            stock.etaDate = <>
                <>{moment.unix(stock.etaDate.seconds).format('DD/MM/YYYY')}</>
                <>{stock.isStockArrived ? <div className="badge badge-pill badge-available badge-mini ml-1">{'ARRIVED'}</div> : <></>}</>
            </>;
        }


        if (!_.isEmpty(stock.fokDate))
            stock.fokDate = moment.unix(stock.fokDate.seconds).format('DD/MM/YYYY');
        if (!_.isEmpty(stock.stockInDate))
            stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('DD/MM/YYYY');

        stock.statusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stock.status, '');
        stock.status = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stock.status, '');
        // stock.status = (stock.status
        //     ?
        //     <div className={`badge badge-pill badge-${stock.status.trim().toLowerCase()}-fill `}>
        //         {stock.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);


        stock.availability = (<div className="Vehicle-availability">
            {stock.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stock.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stock.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stock.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);


        stock.availabileFor = ((stock.isTestDrive ? 'Test Drive, ' : '') +
            (stock.isLoan ? 'Loan, ' : '') +
            (stock.isServiceLoan ? 'Service Loan, ' : '') +
            (stock.isSale ? 'Sale' : ''));

        stock.addedBy = CommonHelper.getUserNamebyId(allUsers, stock.addedBy);
        stock.modifiedBy = CommonHelper.getUserNamebyId(allUsers, stock.modifiedBy);
        stock.statusUpdatedBy = stock.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, stock.statusModifiedBy) : '';

        stock.vehicleModel = stock.model ? stock.model.trim() : stock.model;
        stock.stockAgeNo = stock.etaDateValue ? moment().diff(moment.unix(stock.etaDateValue.seconds), 'days') : -1;
        return stock
    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('stockFilter', JSON.stringify(_filter));
            localStorage.stockSearchText = '';
            setFilterApplied(true);
            setSearchText('');
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.stockFilter)) {
            localStorage.removeItem('stockFilter');
            localStorage.stockSearchText = '';
            setFilterApplied(false);
            setFilterLoader(!filterLoader)
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ stockFields: fields }, { merge: true })
            .then(() => {
                setStockFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('stockFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (
            <div >
                <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelHistoryShow(true);
                    }}>
                    <i className="ico icon-history"></i>
                </div>

                {
                    readOnlyEnquiryView
                        ?
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-zoom"></i>
                            </div>
                        </>
                        :
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-edit"></i>
                            </div>
                        </>
                }

                {
                    (deleteStock || deleteServiceStock)
                        ?
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={async (e) => {
                                    handleDeleteStock(id, data);
                                }}
                            >
                                <i className="ico icon-delete"></i>
                            </div>

                        </>
                        :
                        <></>
                }

            </div>
        )
    }

    const handleDeleteStock = async (id, data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the stock.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {

                let response = await handleCheckStock(id);
                //console.log("response", id, response)
                if (response.success) {
                    let _dataType = response.type + (response.id ? ' (' + response.id + ')' : '');
                    Swal.fire(CommonHelper.showLocale(props, 'Cannot delete stock: It is being used in ') + _dataType + '.', '', 'info')
                    return false;
                }
                else {
                    const { dealersettings } = props;
                    let modifiedBy = dealersettings ? dealersettings.id : '';
                    let modifiedDate = window.firebase.firestore.Timestamp.now();

                    const updateRef = window.firebase.firestore().collection(`stock`).doc(id)
                    updateRef.
                        set({
                            isDeleted: true,
                            modifiedFrom: 'web',
                            modifiedBy,
                            modifiedDate
                        }, { merge: true })
                        .then((docRef) => {
                            toast.notify('Stock successfully deleted.', {
                                duration: 2000
                            })


                            if (!_.isEmpty(data)) {
                                dispatch({
                                    type: "_REMOVE",
                                    data: data,
                                    sortName: 'modifiedDate',
                                    sortOrder: 'desc'
                                });
                            }


                            let logNotes = 'Stock deleted';
                            if (!_.isEmpty(data) && data.stockNo)
                                logNotes = 'Stock#: ' + data.stockNo + ' deleted';

                            let _objLogData = {
                                notes: logNotes,
                                type: 'stock',
                                subType: 'delete',
                                recordId: id,
                            }
                            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                            CommonHelper.saveAuditLog(_objLogData);

                        }).catch((error) => {
                            console.error("Error deleting Video: ", error);
                        });
                }
            }
        })
    }

    const handleDetailsClick = (e, id) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
    }
    const handleCheckStock = async (stockID) => {
        const stockPromises = [];

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('enquiries')
            .where('requirement.stock.documentID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collectionGroup('requirements')
            .where('stock.documentID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('stockID', '==', stockID)
            .where('type', '==', 'activity')
            //.where('subType', '==', 'testdrive')
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('testdrives')
            .where('stockID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        stockPromises.push(firestoreDB(props.dealersettings).firestore().collection('fleet')
            .where('stockID', '==', stockID)
            .where('isDeleted', '==', false)
            .limit(1)
            .get())

        let isExist = false;
        let _response;
        const snapshots = await Promise.all(stockPromises)
        if (!_.isEmpty(snapshots)) {
            snapshots.some((snap) => {
                if (snap.size > 0) {
                    isExist = true;
                    //console.log(stockID + ': exists')
                    snap.forEach((doc) => {
                        if (doc.ref.path.includes('testdrives')) {
                            _response = { success: true, type: 'Testdrive', data: doc.data() };
                            return true;
                        } else if (doc.ref.path.includes('enquiries') || doc.ref.path.includes('requirements')) {
                            let _resData = doc.data();
                            _response = { success: true, type: 'Enquiry', data: _resData, id: _resData.displayID };
                            return true;
                        } else if (doc.ref.path.includes('fleet')) {
                            _response = { success: true, type: 'Loan Vehicle', data: doc.data() };
                            return true;
                        } else if (doc.ref.path.includes('activities')) {
                            _response = { success: true, type: 'Activity', data: doc.data() };
                            return true;
                        }
                    });
                }
                else {
                    console.log(stockID + ': non-exists')
                }
            })
        }
        else {

            console.log(stockID + ': non-exists-valid')
        }

        if (isExist)
            return _response;
        else
            return { success: false };

    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }
    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
    }
    const handleHistoryClose = () => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const handleOEMClose = () => {
        setOEMShow(false);
    }
    const handleOEMOpen = () => {
        setOEMShow(true);
    }

    const handleChangeTab = (_data) => {
        setActiveTab(_data);
    }

    const updateStock = (objStock) => {
        if (!_.isEmpty(objStock)) {
            let _data = dataMappingVM(objStock);

            if (!_.isEmpty(_data)) {
                dispatch({
                    type: "_UPDATE",
                    data: _data,
                    sortName: 'modifiedDate',
                    sortOrder: 'desc'
                });
            }
        }

    }
    const excelMcLarenDownload = async () => {
        const { dealersettings } = props;
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        let _searchObject = {}
        _searchObject.clientID = dealersettings.client.id;
        _searchObject.accessToStockVBM = accessToStockVBM;
        const searchContacts = window.firebase.functions().httpsCallable('reports-McLarenStock');
        const resp = await searchContacts(_searchObject);

        if (resp.data.success) {


            CommonHelper.handleFileDownload(resp.data.data).then(doc => {
                // if (!doc.success)
                //     window.open(resp.data.data, '_blank');

                //console.log('Data downloaded successfully.')
                toast.closeAll();
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                })

                let _objLogData = {
                    notes: 'Excel export from McLaren Stock',
                    type: 'excelExport',
                    subType: 'stock'
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                CommonHelper.saveAuditLog(_objLogData);
            });
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

        //console.log('excelDownload', resp)
    }

    const excelDownload = async (total) => {
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        const { dealersettings } = props;
        // const isMclarenStock = true;//dealersettings.isMclaren;
        // if (isMclarenStock === true) {
        //     excelMcLarenDownload();
        //     return;
        // }

        let _companyFilter = localStorage.stockFilter ? JSON.parse(localStorage.stockFilter) : {};
        let _filter = Object.assign({}, _companyFilter.value);
        let _sortOrder = sortName + ' ' + sortOrder;


        if (!_filter.owner)
            delete _filter['owner'];

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
            delete _filter['date'];
        }

        if (!_.isEmpty(_filter.make))
            _filter = CommonHelper.handleMakeModelFilter(_filter, props.dealersettings);

        if (localStorage.defaultModule === 'oem' && props?.dealersettings?.clients?.length > 0) {
            _filter.clientID = props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(r => { return r.id }).join(',')
        }
        else {
            if (enableSharedStock && dealersettings.client.group)
                _filter.groupID = dealersettings.client.group
            else
                _filter.clientID = dealersettings.client.id;
        }
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchStock",
            "filters": JSON.stringify(_filter),
            "sortOrder": "modifiedDate desc",
            "pageNum": 0,
            "pageLimit": localStorage.stockFilter ? 5000 : (total ? total : 1000)
        }

        if (localStorage.defaultModule === 'oem' && props?.dealersettings?.clients?.length > 0) _searchObject.clientID = _filter.clientID;
        else {
            if (enableSharedStock && dealersettings.client.group)
                _searchObject.groupID = dealersettings.client.group
            else
                _searchObject.clientID = dealersettings.client.id;

            //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings);
        }

        //console.log('generic-searchData-export', _searchObject)

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )
        const searchContacts = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchContacts(_searchObject);
        //console.log('generic-searchData', resp);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = dataMappingVM(doc);
                _enquiries.push(logVM)
            });
            // setCSVData(_enquiries)
            // csvBtn.current && csvBtn.current.link.click();           
            setCSVData(CommonHelper.generateExcelData(_enquiries.filter(e => accessToPipelineStock ? true : (e.statusValue !== 'inProduction')), csvHeader, props, stockOptionsStatic));
            let _allSheetData = {};
            _allSheetData['Stock'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === 'available' || e.statusValue === 'onLoan' || e.statusValue === 'onTestdrive' || e.statusValue === 'reserved'), csvHeader, props, stockOptionsStatic);

            _allSheetData['Pipeline'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === 'inProduction'), csvHeader, props, stockOptionsStatic);

            _allSheetData['Sold'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === 'sold'), csvHeader, props, stockOptionsStatic);

            _allSheetData['Delivered'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === 'delivered'), csvHeader, props, stockOptionsStatic);

            _allSheetData['Unavailable'] = CommonHelper.generateExcelData(_enquiries.filter(e => e.statusValue === 'unavailable'), csvHeader, props, stockOptionsStatic);

            setSheetData(_allSheetData)

            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)

            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from stock',
                type: 'excelExport',
                subType: 'stock',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }

    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = 0;
                for (var i in stockCount) {
                    dataArray += parseFloat(data[i] ? data[i] : 0);
                }
                excelDownload(dataArray);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    let _allStockFieldsListVM = allStockFieldsListVM;
    if (deleteStock || deleteServiceStock)
        _allStockFieldsListVM.filter(function (obj) {
            if (obj.name === 'Settings') {
                obj.width = 110;
                return true;
            }
            return false;
        });

    return (
        <>
            <div>
                <FilterPanel
                    handleOEMOpen={handleOEMOpen}
                    sortOrder={sortOrder}
                    customFilters={customFilters}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    handleApplyFilter={handleApplyFilter}
                    handleClearFilter={handleClearFilter}
                    sortName={sortName}
                    handlesortOrder={(val) => {
                        setSortOrder(val)
                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                        setDataload({
                            ...checkDataLoad,
                            [activeTab]: true
                        })
                    }}
                    handleSortNamechange={(val) => {
                        setSortName(val)
                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                        setDataload({
                            ...checkDataLoad,
                            [activeTab]: true
                        })
                    }}
                    handleAddStock={() => {
                        setModelShow(true);
                        setEditDocument(null);
                    }}
                    csvHeader={csvHeader}
                    datalist={stocks}
                    searchText={searchText}
                    handlesearchText={(val) => {
                        setSearchText(val)
                        // setFilterApplied(false);
                        // if (!_.isEmpty(localStorage.stockFilter))
                        //     localStorage.removeItem('stockFilter');

                        if (_.isEmpty(val)) {
                            setFilterLoader(!filterLoader)
                        }

                    }}
                    stockCount={stockCount}
                    stockALLCount={stockALLCount}
                    readOnlyEnquiryView={localStorage.defaultModule === 'oem' || readOnlyEnquiryView}
                    csvData={csvData}
                    excelDownload={excelDownload}
                    excelMcLarenDownload={excelMcLarenDownload}
                    csvBtn={csvBtn}
                    activeTab={activeTab}
                    handleChangeTab={handleChangeTab}
                    sheetData={sheetData}
                    stockTabs={stockTabs.filter(a => localStorage.defaultModule === 'oem' ? a.module === localStorage.defaultModule : props.dealersettings.superAdmin === true ? true : a.value !== 'unavailable')}
                    pipeline={true}
                    showOEMFilter={localStorage.defaultModule === 'oem'}
                    objFilter={objFilter}
                    setMultibjFilter={(selectedOptions, name) => {
                        let value = [];
                        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                            value.push(data.value)
                        })
                        let _objFilter = objFilter;
                        if (name === 'region') {
                            _objFilter = {
                                ..._objFilter,
                                [name]: value,
                                ['subregion']: [],
                                ['country']: [],
                                ['dealer']: [],
                            };
                        } else if (name === 'subregion') {
                            _objFilter = {
                                ..._objFilter,
                                [name]: value,
                                ['country']: [],
                                ['dealer']: [],
                            };
                        } else if (name === 'country') {
                            _objFilter = {
                                ..._objFilter,
                                [name]: value,
                                ['dealer']: [],
                            };
                        } else {
                            _objFilter = {
                                ..._objFilter,
                                [name]: value,
                            };
                        }
                        setObjFilter(_objFilter);
                        setFilterClients(props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
                            (_objFilter?.region?.length > 0 ? _objFilter.region.includes(client.region) : true) &&
                            (_objFilter?.subregion?.length > 0 ? _objFilter.subregion.includes(client.subregion) : true) &&
                            (_objFilter?.country?.length > 0 ? _objFilter.country.includes(client.country) : true) &&
                            (_objFilter?.dealer?.length > 0 ? _objFilter.dealer.includes(client.documentID) : true)
                        ).map(r => { return r.id }) : [])
                        setFilterLoader(!filterLoader);
                    }}
                    filterClients={filterClients}
                    setscheduleRptModel={(val) => {
                        setscheduleRptModel(val)
                    }}
                    scheduleReport={scheduleReport}
                />
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className="middle-section-wraper pt-0" style={_.find(stockTabs, { value: activeTab })?.hideScroll === true ? {} : { overflow: 'auto', overflowX: 'hidden', height: `${windowSize.windowHeight - 10}px` }}>
                            {
                                activeTab === 'pipeline' ? (<>
                                    {
                                        chartData.map((data, i) => {
                                            return <div key={i}>
                                                <div className='report-section-heading'>  <Translate text={data.name} />     </div>
                                                <div className='report-loop-box-wrapper'>
                                                    {
                                                        data.value.map((rec, j) => {
                                                            return <div key={j} className="col-loop-3">
                                                                <div className="col-report-box">
                                                                    <BarChart
                                                                        id={`stock-${rec.v}`}
                                                                        height={`400px`}
                                                                        data={reportData.map(data => {
                                                                            return {
                                                                                ...data,
                                                                                [`${rec.v}data`]: data[rec.v],
                                                                                [rec.v]: data[rec.v].length
                                                                            }
                                                                        })}
                                                                        columns={[rec.v]}
                                                                        dynamicColumns={columnValues}
                                                                        xCategory={'name'}
                                                                        calculationType={'count'}
                                                                        handleShowEnquiryList={(value, data) => {
                                                                            if (data[`${value}data`]?.length > 0)
                                                                                setShowStockList({
                                                                                    show: true,
                                                                                    title: `${data.name} - ${rec.n}(${data.name})`,
                                                                                    dataList: data[`${value}data`]
                                                                                })
                                                                        }}
                                                                        hideLegend={true}
                                                                        title={`${rec.n} (${_.sumBy(reportData.map(data => {
                                                                            return {
                                                                                ...data,
                                                                                [rec.v]: data[rec.v].length
                                                                            }
                                                                        }), rec.v)})`}
                                                                        dynColor={true}
                                                                        labelMaxWidth={75}
                                                                    />
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }

                                    <div className='report-section-heading'>
                                        <Translate text={'Age Profile - Wholesale Not Retailed'} />
                                    </div>
                                    <div className='report-loop-box-wrapper'>
                                        <div className="col-loop-8">
                                            <div className="col-report-box" style={{ height: '450px', overflow: 'auto', overflowX: 'hidden' }}>
                                                <table className='col-table-loop col-table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='col-cell-head' width='20%'>Model</th>
                                                            {
                                                                stockAge.map((header, index) => {
                                                                    return <th className='text-center' width='13.3%' key={index}>{header.name}</th>
                                                                })
                                                            }
                                                            <th width='13.3%' className='text-center' >Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tblStockAge.map((rows, index) => {
                                                                return (<tr key={index}>
                                                                    <td className='col-td-head'>{rows.name}</td>
                                                                    {
                                                                        stockAge.map((row, index) => {
                                                                            return <td className='count-large text-center' key={index}>{rows[row.value]}</td>
                                                                        })
                                                                    }
                                                                    <td className='count-large cell-bg-light-orange text-center'>{rows.total}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className='col-td-head  cell-bg-light-orange'>Total</td>
                                                            {
                                                                stockAge.map((row, index) => {
                                                                    return <td className='count-large cell-bg-light-orange text-center' key={index}>{_.sumBy(tblStockAge, row.value)}</td>
                                                                })
                                                            }
                                                            <td className='count-large cell-bg-orange text-center'>{_.sumBy(tblStockAge, 'total')}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-loop-3">
                                            <div className="col-report-box">
                                                <BarChart
                                                    id={`stock-age-profile`}
                                                    height={`400px`}
                                                    data={rptStockAge.map(rec => {
                                                        return {
                                                            ...rec,
                                                            [`countdata`]: rec.count,
                                                            ['count']: rec.count.length
                                                        }
                                                    })}
                                                    columns={['count']}
                                                    dynamicColumns={stockAge}
                                                    xCategory={'name'}
                                                    calculationType={'count'}
                                                    handleShowEnquiryList={(value, data) => {
                                                        if (data?.countdata?.length > 0)
                                                            setShowStockList({
                                                                show: true,
                                                                title: 'Stock Age',
                                                                dataList: data.countdata
                                                            })
                                                    }}
                                                    hideLegend={true}
                                                    title={'Age Profile'}
                                                    dynColor={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>) : activeTab === 'stock' ? (<>
                                    {
                                        stockOverview.map((rec, index) => {
                                            return <div key={index}>
                                                <div className='report-section-heading'>
                                                    <Translate text={rec.name} />
                                                </div>
                                                <div className="common-table mb-4">
                                                    <TableView
                                                        isReorderable={true}
                                                        datalist={tblstockOverview[rec.value]}
                                                        height={tblstockOverview[rec.value].length * 60 < 300 ? 300 : (tblstockOverview[rec.value].length * 60) + 60}
                                                        width={windowSize.windowWidth}
                                                        columns={_allStockFieldsListVM}
                                                        handleSavesettings={handleSavesettings}
                                                        dynamicFields={(stockFields && stockFields.length > 0) ? _.filter(stockFields, (v) => (accessToStockVBM ? v : !v.startsWith('margin'))) : allStockFieldsListVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                        settingsLoader={settingsLoader}
                                                        handleActionClick={handleActionClick}
                                                        handleRowSingleClick={handleDetailsClick}
                                                        isSettings={true}
                                                        hasMore={hasMore.find(e => e.type === activeTab)}
                                                        handlePagination={handlePagination}
                                                        isPaging={false}
                                                        activityTab={activeTab}
                                                        displayByValue={true}
                                                    />
                                                </div>
                                            </div>
                                        })
                                    }
                                </>
                                ) : (<>
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={qualifiedStocks[activeTab]}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth + 20}
                                            columns={_allStockFieldsListVM}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(stockFields && stockFields.length > 0) ? _.filter(stockFields, (v) => (accessToStockVBM ? v : !v.startsWith('margin'))) : allStockFieldsListVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={handleDetailsClick}
                                            isSettings={true}
                                            hasMore={hasMore.find(e => e.type === activeTab)}
                                            handlePagination={handlePagination}
                                            isPaging={false}
                                            activityTab={activeTab}
                                            displayByValue={true}
                                        />
                                    </div>
                                </>)
                            }
                        </div >)
                }
            </div>
            <PopUpModal show={modelShow}>
                <AddStock
                    docID={editDocumentID}
                    show={modelShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={editDocumentID ? 'stock' : 'addStock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={false}
                    enableServiceLoan={!editDocumentID && localStorage.defaultModule === 'service' ? true : false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={isFilterApplied}
                    updateStock={updateStock}
                >
                </AddStock>
            </PopUpModal>

            <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                >
                </StockHistory>
            </PopUpModal>

            <PopUpModal show={OEMShow}>
                <OEMStock
                    docID={editDocumentID}
                    show={OEMShow}
                    clsActive='overlay-modal active'
                    handleClose={handleOEMClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}

                >
                </OEMStock>
            </PopUpModal>

            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

            <PopUpModal show={showStockList.show}>
                <StockList
                    {...props}
                    {...showStockList}
                    handleClose={() => {
                        setShowStockList({ show: false })
                    }}
                    allStockFieldsListVM={_allStockFieldsListVM}
                    stockFields={stockFields}
                    accessToStockVBM={accessToStockVBM}
                    settingsLoader={settingsLoader}
                    handleSavesettings={handleSavesettings}
                    handleCheckStock={handleCheckStock}
                    dataMappingVM={dataMappingVM}
                />
            </PopUpModal>
            <PopUpModal show={scheduleRptModel.show}>
                <ScheduleReport
                    {...props}
                    {...scheduleRptModel}
                    handleClose={() => {
                        setscheduleRptModel({ show: false })
                    }}
                ></ScheduleReport>
            </PopUpModal>
        </>
    )
}

export default StockPipeline