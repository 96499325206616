import React, { useState, useEffect, useReducer, useContext } from 'react'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import uuid from 'react-uuid'
import CommonHelper from '../../../../../services/common';
import { ContentOverlay, ReactSelect, InputText, ReactMultiSelect } from "../../../../../components";
import Translate from '../../../../../constants/translate';
import { activityOptions, calculateOptions, salesTypes, targetOptions, targetVM, targetmandatoryFields, dataQualityOptions, enqStatus, inboundStatus, quotationOptions, callLogs, tradeinOptions } from '../viewModel'
import { ClientContext } from '../../clientContext'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { financestatus } from '../../../../../services/enum';

const ClientTargets = (props) => {
    const [loader, setLoader] = useState(true)
    const [btnloader, setBtnLoader] = useState(false)
    const [selectedClient, setSelectedClient] = useState([])
    const [clientOptions, setClientOptions] = useState([]);
    const { clients } = useContext(ClientContext);
    const [selectedTarget, setSelectedTarget] = useState()
    const [oldselectedTarget, setOldTarget] = useState()
    const [targetSettings, setTargetSettings] = useState({ name: '', type: '', subType: '', calculationType: '', target: '', targetID: uuid() })
    const [editTaretSettings, setEditTargetSettings] = useState()
    const [copyloader, setCopyLoader] = useState(false)
    const [years, setYears] = useState([])
    const [selectedYear, setSelctedYear] = useState(moment().format("YYYY"))
    const [activeMonth, setActiveMonth] = useState(moment().format("MMM"))
    const [errorFields, setErrorFields] = useState({});
    const [textEditor, setTexteditor] = useState({})
    const [pipelineOptions, setPipelineOptions] = useState([])
    const [dynSalesTypes, setDynSaalesTypes] = useState(salesTypes)
    const [dynEnquiryOptions, setDynEnquiryOptions] = useState([])
    const [yearloader, setYearLoader] = useState(false)
    const [dynFinanceStatus, setDynFinanceStatus] = useState([])
    const [clientSettings, setClientSettings] = useState(props.dealersettings);

    const activityLogOptions = CommonHelper.activityLogOptions(clientSettings, activityOptions);

    const currencySymbol = ((!_.isEmpty(clientSettings) &&
        !_.isEmpty(clientSettings.client) &&
        !_.isEmpty(clientSettings.client.currency)) ? clientSettings.client.currency.symbol + ' ' : '$ ');

    const financeEnabled = (props?.dealersettings?.client?.moduleSettings?.finance?.enabled ? true : false);

    const enquiryStatus = ((!_.isEmpty(clientSettings) &&
        !_.isEmpty(clientSettings.client) &&
        !_.isEmpty(clientSettings.client.settings) &&
        !_.isEmpty(clientSettings.client.settings.enquiryStatus)) ? clientSettings.client.settings.enquiryStatus
            .filter(a => a.value !== 'prospectLost')
            .map(r => {
                return {
                    ...r,
                    label: r.name
                }
            }) : enqStatus)


    useEffect(() => {
        if (!_.isEmpty(clientSettings) && !_.isEmpty(clientSettings.client) && !_.isEmpty(clientSettings.client.settings)) {
            const setttings = clientSettings.client.settings;

            let _pipelines = [];
            setttings.allPipelines.filter(a => a.active === true).forEach(rec => {
                _pipelines.push({
                    label: rec.name,
                    value: rec.value,
                })
            })
            setPipelineOptions(_pipelines)

            let _salesTypes = [];
            setttings.salesType && setttings.salesType.filter(a => a.active === true).forEach((saleType) => {
                enquiryStatus.forEach(status => {
                    _salesTypes.push({
                        searchlabel: `${saleType.value.toLowerCase()}${status.value}`,
                        value: `${saleType.value.toLowerCase()}AND${status.value}`,
                        label: <>{saleType.name} <i className={`badge-status-${status.value === 'open' ? 'open' : 'empty'} status-i-fix ml-1`} style={{ background: status.color }}>{status.name}</i></>
                    })
                })
            });
            setDynSaalesTypes(_salesTypes)


            let _enqOptions = setttings.enquiryOptionsDF ? setttings.enquiryOptionsDF.filter(a => a.active === true).map((option) => {
                return {
                    searchlabel: `${option.name.toLowerCase()}`,
                    value: option.value,
                    type: option.type,
                    label: option.name
                }
            }) : [];
            setttings.enquiryOptionsDF && setttings.enquiryOptionsDF.filter(a => a.active === true).forEach((option) => {
                _enqOptions.push({
                    searchlabel: `${option.name.toLowerCase()}won`,
                    value: `${option.value}ANDwon`,
                    type: option.type,
                    label: <>{option.name} <i className={`badge-status-won status-i-fix ml-1`}>WON</i></>
                })
            });
            setDynEnquiryOptions(_enqOptions)

            let _financeStatus = []
            setttings.financeStatus && setttings.financeStatus.filter(a => a.active === true).forEach((option) => {
                _financeStatus.push({
                    value: option.value,
                    label: option.name
                })
            });
            if (financeEnabled) {
                _financeStatus = financestatus.map(option => {
                    return {
                        value: option.value,
                        label: option.name
                    }
                })
            }
            setDynFinanceStatus(_financeStatus)
        }
    }, [clientSettings])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])


    useEffect(() => {
        if (_.isEmpty(selectedClient))
            return

        const targetsDataRef = window.firebase.firestore().doc(`targets/client${selectedClient.documentID}`)
            .onSnapshot(querSnapshot => {
                if (querSnapshot.exists) {
                    setSelectedTarget(querSnapshot.data())
                    setOldTarget(querSnapshot.data())
                }
                else {
                    const newtargetVM = Object.assign({}, targetVM);
                    newtargetVM.documentID = `client${selectedClient.documentID}`;
                    newtargetVM.clientID = selectedClient.documentID;
                    newtargetVM.addedDate = window.firebase.firestore.Timestamp.now();
                    newtargetVM.addedBy = localStorage.uid;
                    newtargetVM.modifiedDate = window.firebase.firestore.Timestamp.now();
                    newtargetVM.modifiedBy = localStorage.uid;
                    newtargetVM.isDeleted = false;
                    newtargetVM.type = 'dealer';
                    setSelectedTarget(newtargetVM)
                    setOldTarget(newtargetVM)
                }
                setLoader(false)
            })


        return () => {
            targetsDataRef && targetsDataRef();
        }

    }, [selectedClient])

    useEffect(() => {
        let _years = []
        _years.push({
            label: moment().add(-1, 'year').format('YYYY'),
            value: moment().add(-1, 'year').format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().format('YYYY'),
            value: moment().format('YYYY'),
            active: true,
        })
        _years.push({
            label: moment().add(1, 'year').format('YYYY'),
            value: moment().add(1, 'year').format('YYYY'),
            active: true,
        })
        setYears(_years)
    }, [])



    const handleNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setTargetSettings({
                ...targetSettings,
                [name]: number !== '' ? Number(number) : number
            })
        }
    };

    const handleOnTargetChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setTargetSettings({
                ...targetSettings,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setTargetSettings({
                ...targetSettings,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnEditTargetChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setEditTargetSettings({
                ...editTaretSettings,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditTargetSettings({
                ...editTaretSettings,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['edit' + name]: ''
        });
    }

    const handleEditNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setEditTargetSettings({
                ...editTaretSettings,
                [name]: number !== '' ? Number(number) : number
            })
        }
    };
    const handleSelectChange = (e, data) => {
        if (data.name === 'type') {
            setTargetSettings({
                ...targetSettings,
                [data.name]: e ? e.value : '',
                ['subType']: '',
                ['calculationType']: ''
            })
        }
        else {
            setTargetSettings({
                ...targetSettings,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleEditSelectChange = (e, data) => {
        if (data.name === 'type') {
            setEditTargetSettings({
                ...editTaretSettings,
                [data.name]: e ? e.value : '',
                ['subType']: '',
                ['calculationType']: ''
            })
        }
        else {
            setEditTargetSettings({
                ...editTaretSettings,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['edit' + data.name]: ''
        });
    }
    const handleyearChange = (e, data) => {
        setSelctedYear(e.value)

        if (e.value === moment().format('YYYY')) {
            setActiveMonth(moment().format('MMM'))
        }
        else {
            setActiveMonth(moment().startOf('year').format('MMM'))
        }
    }
    const handleMultiSelectChange = (selectedOptions, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setTargetSettings({
            ...targetSettings,
            [name]: value.join(',')
        });
    }
    const handleEditMultiSelectChange = (selectedOptions, name) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setEditTargetSettings({
            ...editTaretSettings,
            [name]: value.join(',')
        });
    }
    const handleTargetSave = (newTarget) => {
        if (!newTarget) {
            setTargetSettings(editTaretSettings)
        }
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(targetSettings)) {
            if (targetmandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(targetSettings[key].toString())) {
                    formIsValid = false;
                    if (newTarget)
                        errors[key] = errorClass;
                    else
                        errors['edit' + key] = errorClass;
                }
            }
        }
        setErrorFields(errors)
        // if (newTarget && selectedTarget[selectedYear] && selectedTarget[selectedYear][activeMonth] && selectedTarget[selectedYear][activeMonth].some(item => item.type === targetSettings.type && item.subType === targetSettings.subType && item.calculationType === targetSettings.calculationType)) {
        //     Swal.fire('Target with same options already exists', '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        const _target = {
            ...selectedTarget,
            [selectedYear]: {
                ...selectedTarget[selectedYear],
                [activeMonth]: newTarget ? (selectedTarget[selectedYear] ? (selectedTarget[selectedYear][activeMonth] ? [...selectedTarget[selectedYear][activeMonth], targetSettings] : [targetSettings]) : [targetSettings]) :
                    [..._.map(selectedTarget[selectedYear][activeMonth], function (obj) {
                        return obj.targetID === targetSettings.targetID ? { ...targetSettings } : { ...obj }
                    })]

            }
        };
        //setSelectedTarget(_target)
        //setTargetSettings({ name: '', type: '', subType: '', calculationType: '', target: '', targetID: uuid() })
        handleSave(true, _target)
    }

    const handleEditTargetSave = (newTarget) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(editTaretSettings)) {
            if (targetmandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(editTaretSettings[key].toString())) {
                    formIsValid = false;
                    if (newTarget)
                        errors[key] = errorClass;
                    else
                        errors['edit' + key] = errorClass;
                }
            }
        }
        setErrorFields(errors)
        // if (selectedTarget[selectedYear] && selectedTarget[selectedYear][activeMonth] && selectedTarget[selectedYear][activeMonth].filter(item => item.targetID !== editTaretSettings.targetID).some(item => item.type === editTaretSettings.type && item.subType === editTaretSettings.subType && item.calculationType === editTaretSettings.calculationType)) {
        //     Swal.fire('Target with same options already exists', '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        const _target = {
            ...selectedTarget,
            [selectedYear]: {
                ...selectedTarget[selectedYear],
                [activeMonth]: newTarget ? (selectedTarget[selectedYear] ? [...selectedTarget[selectedYear][activeMonth], editTaretSettings] : [editTaretSettings]) :
                    [..._.map(selectedTarget[selectedYear][activeMonth], function (obj) {
                        return obj.targetID === editTaretSettings.targetID ? { ...editTaretSettings } : { ...obj }
                    })]

            }
        };
        //setSelectedTarget(_target)
        setTexteditor({})
        setEditTargetSettings(null)
        handleSave(false, _target);
    }

    const handleCopytoAll = () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[selectedYear]) || _.isEmpty(selectedTarget[selectedYear][activeMonth])) {
            Swal.fire(CommonHelper.showLocale(props, 'Please define target in current month'), '', 'error')
            return
        }
        setCopyLoader(true)
        var _currentTarget = selectedTarget;
        const _targetMonth = Number(moment().format("M")) < Number(moment().month(activeMonth).format("M")) ? moment().format("MMM") : activeMonth;
        for (let i = Number(moment().month(_targetMonth).format("M")); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[selectedYear],
                    [moment(i.toString(), 'M').format('MMM')]: [...selectedTarget[selectedYear][activeMonth]]
                }
            }
        }
        // setSelectedTarget(_currentTarget)

        // setTimeout(function () {
        //     toast.notify('Targets copied successfully.', {
        //         duration: 1000
        //     })
        //     setCopyLoader(false)
        // }, 2000)
        handleSave(false, _currentTarget);


    }

    const handleCopyfromlastYear = async () => {
        if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[(parseInt(selectedYear) - 1)])) {
            Swal.fire(`${CommonHelper.showLocale(props, 'No targets defined in')} ${parseInt(selectedYear) - 1}`, '', 'error')
            return
        }
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: `${CommonHelper.showLocale(props, 'Do you want to copy targets from')} ${(parseInt(selectedYear) - 1)}?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        })

        if (!result.value) {
            return
        }
        setYearLoader(true)
        var _currentTarget = selectedTarget;
        for (let i = (Number(moment().month(activeMonth).format("M")) + 1); i <= 12; i++) {
            _currentTarget = {
                ..._currentTarget,
                [selectedYear]: {
                    ..._currentTarget[(parseInt(selectedYear) - 1)]
                }
            }
        }
        // setSelectedTarget(_currentTarget)

        // setTimeout(function () {
        //     toast.notify('Targets copied successfully.', {
        //         duration: 1000
        //     })
        //     setCopyLoader(false)
        // }, 2000)
        handleSave(false, _currentTarget);
    }

    const handleSave = (newTarget, objTarget) => {
        // if (_.isEmpty(selectedTarget) || _.isEmpty(selectedTarget[selectedYear]) || _.isEmpty(selectedTarget[selectedYear][activeMonth])) {
        //     Swal.fire('Please define target in current month', '', 'error')
        //     return
        // }
        if (newTarget)
            setBtnLoader(true)
        window.firebase.firestore().doc(`targets/${objTarget.documentID}`).set(objTarget, { merge: true })
            .then(snapshot => {
                setBtnLoader(false)
                setCopyLoader(false)
                setYearLoader(false)
                setSelectedTarget(objTarget)
                setOldTarget(objTarget)
                setTargetSettings({ name: '', type: '', subType: '', calculationType: '', target: '', targetID: uuid() })
                toast.notify('Dealer Target updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }
    const getsubTypeName = (type, subType) => {
        var result = [];
        if (!_.isEmpty(type) && !_.isEmpty(subType)) {
            subType.split(',').forEach(rec => {
                let subtypeOptions = (type !== 'enquiryOptionsDF' && !_.isEmpty(clientSettings.client.settings[type])) ?
                    _.map(clientSettings.client.settings[type], function (c) {
                        return {
                            value: c.value,
                            label: c.name,
                            active: _.isBoolean(c.active) ? c.active : true,
                        };
                    }) : type === 'make' ? _.map(clientSettings.client.brands, function (c) {
                        return {
                            value: c.value,
                            label: c.name,
                            active: _.isBoolean(c.active) ? c.active : true,
                        };
                    }) : type === 'financeStatuses' ? dynFinanceStatus : type === 'enquiryOptionsDF' ? dynEnquiryOptions : type === 'salesTypes' ? dynSalesTypes : type === 'status' ? enquiryStatus : type === 'inboundStatus' ? inboundStatus : type === 'activities' ? activityLogOptions : type === 'dataQuality' ? dataQualityOptions : type === 'callLogs' ? callLogs : type === 'quotation' ? quotationOptions : type === 'tradein' ? tradeinOptions : targetOptions.filter(item => item.value === type)

                result.push(_.find(subtypeOptions, { 'value': rec }) ? _.find(subtypeOptions, { 'value': rec }).label : '')
            });
        }
        return (<div className={'Vehicle-availability'}>{
            result.filter(e => !_.isEmpty(e)).map((rec, index) => {
                return <div key={index}><span>{rec}</span></div>;
            })
        }</div>);
    }

    const handleDeleteTargetSettings = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                const _target = {
                    ...selectedTarget,
                    [selectedYear]: {
                        ...selectedTarget[selectedYear],
                        [activeMonth]: [...selectedTarget[selectedYear][activeMonth].filter(item => item.targetID !== rec.targetID)]

                    }
                }
                //setSelectedTarget(_target)
                handleSave(false, _target);
            }
        })
    }

    const handleCancelTargetSettings = () => {
        setEditTargetSettings(null)
        setTexteditor({})
    }

    const handleEditTargetSettings = (rec) => {
        setEditTargetSettings(rec)
        setTexteditor({
            [rec.targetID]: true
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const subList = selectedTarget[selectedYear][activeMonth]
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        const _target = {
            ...selectedTarget,
            [selectedYear]: {
                ...selectedTarget[selectedYear],
                [activeMonth]: subList

            }
        }
        handleSave(false, _target);
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })

    const updateClientSettings = async (clientID) => {
        let _clientsettings = await CommonHelper.updateClientSettings(clientID, Object.assign({}, props.dealersettings));
        setClientSettings(_clientsettings)
    }

    return (<>
        <div className="mt-2">
            <div className="settings-head">

                <div className="col-md-3 pl-0 float-left">
                    <ReactSelect
                        options={clientOptions}
                        name={"client"}
                        placeholder={'select client'}
                        onChange={(e) => {

                            if (!_.isEmpty(selectedClient) && e.value !== selectedClient.documentID)
                                setLoader(true)
                            setSelectedClient(e.data)
                            updateClientSettings(e.value)

                        }}
                        value={!_.isEmpty(selectedClient) ? selectedClient.documentID : ''}
                        classNamePrefix={`basic-select`}
                        removeClearable={true}
                    >
                    </ReactSelect>
                </div>
                {/* <div className="float-right">
                    <button type="button" className="btn btn-primary float-right"
                        onClick={() => {
                            handleSave()
                        }}
                    >{
                            btnloader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }  <Translate text={'save'} /> </button>
                </div> */}
            </div>
        </div>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                (<>
                    <div className="settings-head form-style border-top pt-3">
                        <div className="form-row">
                            <div className="form-group col-md-2">
                                <ReactSelect
                                    options={years}
                                    name={"year"}
                                    placeholder={'select year'}
                                    onChange={handleyearChange}
                                    value={selectedYear}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                    id={`target-year`}
                                >
                                </ReactSelect>
                            </div>
                            <div className="form-group col-md-5">
                                <div className="yeargrid">
                                    <ul>
                                        {
                                            moment.monthsShort().map((rec, index) => {
                                                return <li key={index}><a
                                                    id={rec}
                                                    href="#"
                                                    className={rec === activeMonth ? 'selected' : ''}
                                                    onClick={(event) => {
                                                        if (event.target.id) {
                                                            setActiveMonth(event.target.id)
                                                        }
                                                        event.preventDefault();
                                                    }}
                                                >{rec}</a></li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="form-group col-md-5">
                                <div className="float-right ml-2">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        handleCopyfromlastYear()
                                    }} >
                                        {
                                            yearloader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }<Translate text={'Copy from last year'} /></button>
                                </div>
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => {
                                        handleCopytoAll()
                                    }} >
                                        {
                                            copyloader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }<Translate text={'Copy to all months'} /></button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <div className="common-table form-style w-100">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'name'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'pipeline'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'make'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'saleType'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'Option'} /></th>
                                        <th scope="col" className="head-light" width="20%"><Translate text={'Sub Type'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'Calulation'} /></th>
                                        <th scope="col" className="head-light" width="10%"><Translate text={'Target'} /></th>
                                        <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'active'} /></th>
                                    </tr>  <tr>
                                        <td width="10%"><div className="form-group mb-0">
                                            <InputText
                                                id={`target-name`}
                                                autoComplete="off"
                                                placeholder={'name'}
                                                className={`form-control ${errorFields["name"]}`}
                                                name="name"
                                                onChange={handleOnTargetChange}
                                                value={targetSettings.name}
                                            />
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <ReactMultiSelect
                                                options={pipelineOptions}
                                                name={"pipeline"}
                                                placeholder={'select pipeline'}
                                                onChange={(option) => handleMultiSelectChange(option, 'pipeline')}
                                                value={!_.isEmpty(targetSettings.pipeline) ? targetSettings.pipeline.split(',') : []}
                                                classNamePrefix={`basic-select`}
                                                id={`target-pipeline`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <ReactMultiSelect
                                                options={clientSettings.client.brands ? clientSettings.client.brands.map(rec => {
                                                    return {
                                                        value: rec.value,
                                                        label: rec.name
                                                    };
                                                }) : []}
                                                name={"make"}
                                                placeholder={'select make'}
                                                onChange={(option) => handleMultiSelectChange(option, 'make')}
                                                value={!_.isEmpty(targetSettings.make) ? targetSettings.make.split(',') : []}
                                                classNamePrefix={`basic-select`}
                                                id={`target-make`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <ReactMultiSelect
                                                options={clientSettings.client.settings.salesType ? clientSettings.client.settings.salesType.map(rec => {
                                                    return {
                                                        value: rec.value,
                                                        label: rec.name
                                                    };
                                                }) : []}
                                                name={"saleType"}
                                                placeholder={'select sale type'}
                                                onChange={(option) => handleMultiSelectChange(option, 'saleType')}
                                                value={!_.isEmpty(targetSettings.saleType) ? targetSettings.saleType.split(',') : []}
                                                classNamePrefix={`basic-select`}
                                                id={`target-saletype`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <ReactSelect
                                                options={targetOptions}
                                                name={"type"}
                                                placeholder={'select type'}
                                                onChange={handleSelectChange}
                                                value={targetSettings.type}
                                                classNamePrefix={`${errorFields["type"]} basic-select`}
                                                id={`target-type`}
                                            >
                                            </ReactSelect>
                                        </div></td>
                                        <td width="20%"><div className="form-group mb-0">
                                            <ReactMultiSelect
                                                options={(!_.isEmpty(targetSettings.type) && targetSettings.type !== 'enquiryOptionsDF' && !_.isEmpty(clientSettings.client.settings[targetSettings.type]) ?
                                                    _.map(clientSettings.client.settings[targetSettings.type], function (c) {
                                                        return {
                                                            value: c.value,
                                                            label: c.name,
                                                            active: _.isBoolean(c.active) ? c.active : true,
                                                        };
                                                    }) : targetSettings.type === 'financeStatuses' ? dynFinanceStatus : targetSettings.type === 'enquiryOptionsDF' ? dynEnquiryOptions : targetSettings.type === 'salesTypes' ? dynSalesTypes : targetSettings.type === 'status' ? enquiryStatus : targetSettings.type === 'inboundStatus' ? inboundStatus : targetSettings.type === 'activities' ? activityLogOptions : targetSettings.type === 'dataQuality' ? dataQualityOptions : targetSettings.type === 'callLogs' ? callLogs : targetSettings.type === 'quotation' ? quotationOptions : targetSettings.type === 'tradein' ? tradeinOptions : targetOptions.filter(item => item.value === targetSettings.type))}
                                                name={"subType"}
                                                placeholder={'select sub type'}
                                                onChange={(option) => handleMultiSelectChange(option, 'subType')}
                                                value={!_.isEmpty(targetSettings.subType) ? targetSettings.subType.split(',') : []}
                                                classNamePrefix={`${errorFields["subType"]} basic-select`}
                                                id={`target-subType`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <ReactSelect
                                                options={calculateOptions.filter(e =>
                                                    (targetSettings.type === 'quotation' ||
                                                        (targetSettings.type === 'enquiryOptionsDF'
                                                            && targetSettings?.subType?.split(',')?.length === 1
                                                            && dynEnquiryOptions.some(a => targetSettings.subType.split(',').includes(a.value) && a.type === 'price')) ? true : e.value !== 'amount') &&
                                                    (targetSettings.type === 'overdueActivities' || targetSettings.type === 'unattendedLeads' ? e.value === 'count' : true))}
                                                name={"calculationType"}
                                                placeholder={'select calculation type'}
                                                onChange={handleSelectChange}
                                                value={targetSettings.calculationType}
                                                classNamePrefix={`${errorFields["calculationType"]} basic-select`}
                                                id={`target-calculationType`}
                                            >
                                            </ReactSelect>
                                        </div></td>
                                        <td width="10%"><div className="form-group mb-0">
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'target'}
                                                className={`form-control ${errorFields["target"]}`}
                                                name="target"
                                                onChange={handleNumberChange}
                                                value={targetSettings.target}
                                                id={`target-targetCount`}
                                            />
                                        </div></td>

                                        <td width="10%" className="border-right-0">
                                            <div className="button-height-fix">
                                                <button type="button" className="btn btn-primary" onClick={() => { handleTargetSave(true) }}>
                                                    {
                                                        btnloader ?
                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                            : (<Translate text={'add'} />)
                                                    }
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                </thead>
                                <Droppable droppableId="droppableDealerTarget">
                                    {(provided, snapshot) => (
                                        <tbody
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {
                                                selectedTarget[selectedYear] && selectedTarget[selectedYear][activeMonth] ?
                                                    selectedTarget[selectedYear][activeMonth].map((rec, index) => {
                                                        return <Draggable key={rec.targetID} draggableId={rec.targetID} index={index}>
                                                            {(provided1, snapshot1) => (<tr
                                                                ref={provided1.innerRef}
                                                                {...provided1.draggableProps}
                                                                {...provided1.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot1.isDragging,
                                                                    provided1.draggableProps.style
                                                                )}
                                                                onDoubleClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleEditTargetSettings(rec)
                                                                }}>
                                                                <td width="10%">
                                                                    {
                                                                        textEditor[rec.targetID] ? (<><InputText
                                                                            id={`target-edit-name`}
                                                                            autoComplete="off"
                                                                            placeholder={'name'}
                                                                            className={`form-control ${errorFields["editname"]}`}
                                                                            name="name"
                                                                            onChange={handleOnEditTargetChange}
                                                                            value={editTaretSettings.name}
                                                                        /></>) : (<>{rec.name}</>)
                                                                    }
                                                                </td>
                                                                <td width="10%">
                                                                    {
                                                                        textEditor[rec.targetID] ? (<ReactMultiSelect
                                                                            classNamePrefix={` basic-select`}
                                                                            id={`target-edit-pipeline`}
                                                                            options={pipelineOptions}
                                                                            name={"pipeline"}
                                                                            placeholder={'select pipeline'}
                                                                            onChange={(option) => handleEditMultiSelectChange(option, 'pipeline')}
                                                                            value={!_.isEmpty(editTaretSettings.pipeline) ? editTaretSettings.pipeline.split(',') : []}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>) : (<>{getsubTypeName('pipelines', rec.pipeline)}</>)
                                                                    }
                                                                </td>
                                                                <td width="10%">
                                                                    {
                                                                        textEditor[rec.targetID] ? (<ReactMultiSelect
                                                                            options={clientSettings.client.brands ? clientSettings.client.brands.map(rec => {
                                                                                return {
                                                                                    value: rec.value,
                                                                                    label: rec.name
                                                                                };
                                                                            }) : []}
                                                                            name={"make"}
                                                                            placeholder={'select make'}
                                                                            onChange={(option) => handleEditMultiSelectChange(option, 'make')}
                                                                            value={!_.isEmpty(editTaretSettings.make) ? editTaretSettings.make.split(',') : []}
                                                                            classNamePrefix={` basic-select`}
                                                                            id={`target-edit-make`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>) : (<>{getsubTypeName('make', rec.make)}</>)
                                                                    }
                                                                </td>
                                                                <td width="10%">{
                                                                    textEditor[rec.targetID] ? (<ReactMultiSelect
                                                                        options={clientSettings.client.settings.salesType ? clientSettings.client.settings.salesType.map(rec => {
                                                                            return {
                                                                                value: rec.value,
                                                                                label: rec.name
                                                                            };
                                                                        }) : []}
                                                                        name={"saleType"}
                                                                        placeholder={'select sale type'}
                                                                        onChange={(option) => handleEditMultiSelectChange(option, 'saleType')}
                                                                        value={!_.isEmpty(editTaretSettings.saleType) ? editTaretSettings.saleType.split(',') : []}
                                                                        classNamePrefix={` basic-select`}
                                                                        id={`target-edit-saleType`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>) : (<>{getsubTypeName('salesType', rec.saleType)}</>)
                                                                }</td>
                                                                <td width="10%">
                                                                    {
                                                                        textEditor[rec.targetID] ? (<ReactSelect
                                                                            options={targetOptions}
                                                                            name={"type"}
                                                                            placeholder={'select type'}
                                                                            onChange={handleEditSelectChange}
                                                                            value={editTaretSettings.type}
                                                                            classNamePrefix={`${errorFields["edittype"]} basic-select`}
                                                                            id={`target-edit-type`}
                                                                        >
                                                                        </ReactSelect>) : (<>{_.find(targetOptions, { 'value': rec.type }) ? _.find(targetOptions, { 'value': rec.type }).label : rec.type}</>)
                                                                    }
                                                                </td>
                                                                <td width="20%">{
                                                                    textEditor[rec.targetID] ? (<ReactMultiSelect
                                                                        options={(!_.isEmpty(editTaretSettings.type) && editTaretSettings.type !== 'enquiryOptionsDF' && !_.isEmpty(clientSettings.client.settings[editTaretSettings.type]) ?
                                                                            _.map(clientSettings.client.settings[editTaretSettings.type], function (c) {
                                                                                return {
                                                                                    value: c.value,
                                                                                    label: c.name,
                                                                                    active: _.isBoolean(c.active) ? c.active : true,
                                                                                };
                                                                            }) : editTaretSettings.type === 'financeStatuses' ? dynFinanceStatus : editTaretSettings.type === 'enquiryOptionsDF' ? dynEnquiryOptions : editTaretSettings.type === 'salesTypes' ? dynSalesTypes : editTaretSettings.type === 'status' ? enquiryStatus : editTaretSettings.type === 'inboundStatus' ? inboundStatus : editTaretSettings.type === 'activities' ? activityLogOptions : editTaretSettings.type === 'dataQuality' ? dataQualityOptions : editTaretSettings.type === 'callLogs' ? callLogs : editTaretSettings.type === 'quotation' ? quotationOptions : editTaretSettings.type === 'tradein' ? tradeinOptions : targetOptions.filter(item => item.value === editTaretSettings.type))}
                                                                        name={"subType"}
                                                                        placeholder={'select sub type'}
                                                                        onChange={(option) => handleEditMultiSelectChange(option, 'subType')}
                                                                        value={!_.isEmpty(editTaretSettings.subType) ? editTaretSettings.subType.split(',') : []}
                                                                        classNamePrefix={`${errorFields["editsubType"]} basic-select`}
                                                                        id={`target-edit-subType`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>) : (<>{getsubTypeName(rec.type, rec.subType)}</>)
                                                                }</td>
                                                                <td width="10%">{
                                                                    textEditor[rec.targetID] ? (<ReactSelect
                                                                        options={calculateOptions.filter(e =>
                                                                            (editTaretSettings.type === 'quotation' ||
                                                                                (editTaretSettings.type === 'enquiryOptionsDF'
                                                                                    && editTaretSettings?.subType?.split(',')?.length === 1
                                                                                    && dynEnquiryOptions.some(a => editTaretSettings.subType.split(',').includes(a.value) && a.type === 'price')) ? true : e.value !== 'amount') &&
                                                                            (editTaretSettings.type === 'overdueActivities' || editTaretSettings.type === 'unattendedLeads' ? e.value === 'count' : true))}
                                                                        name={"calculationType"}
                                                                        placeholder={'select calculation type'}
                                                                        onChange={handleEditSelectChange}
                                                                        value={editTaretSettings.calculationType}
                                                                        classNamePrefix={`${errorFields["editcalculationType"]} basic-select`}
                                                                        id={`target-edit-calculationType`}
                                                                    >
                                                                    </ReactSelect>) : (<>{_.find(calculateOptions, { 'value': rec.calculationType }) ? _.find(calculateOptions, { 'value': rec.calculationType }).label : rec.calculationType}</>)
                                                                }</td>
                                                                <td width="10%">{
                                                                    textEditor[rec.targetID] ? (<InputText
                                                                        autoComplete="off"
                                                                        placeholder={'target'}
                                                                        className={`form-control ${errorFields["edittarget"]}`}
                                                                        name="target"
                                                                        onChange={handleEditNumberChange}
                                                                        value={editTaretSettings.target}
                                                                        id={`target-edit-targetCount`}
                                                                    />) : (<>{`${rec.calculationType === 'amount' ? currencySymbol : ''}${rec.target.toLocaleString()}${rec.calculationType === 'percentage' ? '%' : ''}`}</>)
                                                                }</td>
                                                                <td width="10%" className="border-right-0">
                                                                    <div style={{ width: '90px', float: 'left' }}>
                                                                        <div className="action-fix">
                                                                            {
                                                                                textEditor[rec.targetID] ?
                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditTargetSave(false) }}> <i className="ico icon-check"></i></a></div>
                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelTargetSettings(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                    </>) :
                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditTargetSettings(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteTargetSettings(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                    </>)
                                                                            }
                                                                        </div>
                                                                        <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                    </div>
                                                                </td>
                                                                {provided1.placeholder}
                                                            </tr>
                                                            )}
                                                        </Draggable>
                                                    }) : (<></>)
                                            }
                                            {provided.placeholder}
                                        </tbody>
                                    )}
                                </Droppable>
                            </table>
                        </div>
                    </DragDropContext>
                </>)
        }
    </>);
}

export default ClientTargets;