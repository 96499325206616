import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect } from '../../../components'
import Translate from '../../../constants/translate';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CommonHelper from '../../../services/common';
import { eventFieldTypes, lookupDFVM, mappingKeys, saleTypes } from '../../settings/eventsProworkflow/lookups/viewModel';
const AddDynamicOptions = (props) => {

    const [lookup, setLookup] = useState(props.lookup)
    const [errorFields, setErrorFields] = useState({});
    const newLookup = !_.isEmpty(props.lookup) ? false : true
    const [addSubList, setAddSubList] = useState()
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.lookup) && !_.isEmpty(props.lookup.subList) ? props.lookup.subList : []);
    const [textEditor, setTexteditor] = useState({})


    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupDFVM);
            setLookup(newlookupVM)
        }
        else {
            setLookup(props.lookup)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }


    const handleSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e.value
        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }




    const saveLookup = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(lookup['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(lookup['value'])) {
            formIsValid = false;
            errors['value'] = errorClass;
        }
        if (_.isEmpty(lookup['type'])) {
            formIsValid = false;
            errors['type'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        props.handleClose(lookup, newLookup);
    }

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setAddSubList(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setAddSubList(str);
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }

            setEditSubList(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditSubList(str);
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleSublistSave = (rec, newlist, index) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec)) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        setErrorFields(errors)
        if (newlist && subList.some(item => item === rec)) {
            Swal.fire(CommonHelper.showLocale(props, 'Options with same name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj, i) {
                return i === index ? rec : obj;
            })];
        setSubList(addList)
        setLookup({
            ...lookup,
            ['subList']: addList
        });
        setAddSubList()
        setEditSubList(null)
        setTexteditor({})
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec, index) => {
        setEditSubList(rec)
        setTexteditor({
            [index]: true
        })
    }
    const handleDeleteSubList = (rec, index) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [];
                _.map(subList, (e, i) => {
                    return index !== i ? deleteList.push(e) : false
                })
                setSubList(deleteList)
                setLookup({
                    ...lookup,
                    ['subList']: deleteList
                });
            }
        })
    }

    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const [removed] = subList.splice(result.source.index, 1);
        subList.splice(result.destination.index, 0, removed);
        setSubList(subList)
        setLookup({
            ...lookup,
            ['subList']: subList
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })


    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        maxLength={500}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Field Type'} /></label>
                                    <ReactSelect
                                        options={eventFieldTypes}
                                        name={"type"}
                                        placeholder={'select field type'}
                                        onChange={handleSelectChange}
                                        value={lookup.type}
                                        removeClearable={true}
                                        classNamePrefix={`${errorFields["type"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (lookup.type === 'select' || lookup.type === 'multiselect') ? (
                                        <>
                                            <DragDropContext onDragEnd={onDragEnd}>

                                                <div className="common-table col-md-12">
                                                    <label > <Translate text={'Options'} /></label>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="head-light" width="45%"> <Translate text={'name'} /></th>
                                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                            </tr>
                                                            <tr>
                                                                <td><div className="table-input">
                                                                    <InputText
                                                                        id="sublistname"
                                                                        autoComplete="off"
                                                                        placeholder={'name'}
                                                                        className={`form-control ${errorFields["sublistname"]}`}
                                                                        name="name"
                                                                        onChange={handleOnSubChange}
                                                                        value={addSubList}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === 'Enter') {
                                                                                handleSublistSave(addSubList, true)
                                                                            }
                                                                        }}
                                                                        maxLength={500}
                                                                    />
                                                                </div>
                                                                </td>
                                                                <td className="border-right-0">
                                                                    <div className="button-height-fix">
                                                                        <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <Droppable droppableId="droppableSerOption">
                                                            {(provided, snapshot) => (
                                                                <tbody
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {
                                                                        subList.length > 0 ?
                                                                            subList.map((rec, index) => {
                                                                                return <Draggable key={`serOptions-${index}`} draggableId={`serOptions-${index}`} index={index}>
                                                                                    {(provided1, snapshot1) => (
                                                                                        <tr
                                                                                            ref={provided1.innerRef}
                                                                                            {...provided1.draggableProps}
                                                                                            {...provided1.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot1.isDragging,
                                                                                                provided1.draggableProps.style
                                                                                            )}
                                                                                            onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}>
                                                                                            <td style={{ width: '335px' }}>
                                                                                                {
                                                                                                    textEditor[_.findIndex(subList, (e) => {
                                                                                                        return e == rec;
                                                                                                    }, 0)] ? (<><InputText
                                                                                                        id="editsublistname"
                                                                                                        autoComplete="off"
                                                                                                        placeholder={'name'}
                                                                                                        className={`form-control ${errorFields["editsublistname"]}`}
                                                                                                        name="name"
                                                                                                        onChange={handleOnEditSubChange}
                                                                                                        value={editSubList}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === 'Enter') {
                                                                                                                handleSublistSave(editSubList, false, index)
                                                                                                            }
                                                                                                        }}
                                                                                                        maxLength={500}
                                                                                                    /></>) : (<>{rec}</>)
                                                                                                }
                                                                                            </td>
                                                                                            <td className="border-right-0">
                                                                                                <div className="action-fix">
                                                                                                    {
                                                                                                        textEditor[_.findIndex(subList, (e) => {
                                                                                                            return e == rec;
                                                                                                        }, 0)] ?
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false, index) }}> <i className="ico icon-check"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec, index) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                            </>) :
                                                                                                            (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec, index) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                                <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec, index) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                            </>)
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="table-edit float-right"> <i className="ico icon-drag"></i></div>
                                                                                            </td>
                                                                                            {provided1.placeholder}
                                                                                        </tr>
                                                                                    )}
                                                                                </Draggable>
                                                                            })
                                                                            : (<></>)
                                                                    }
                                                                    {provided.placeholder}
                                                                </tbody>
                                                            )}
                                                        </Droppable>
                                                    </table>
                                                </div>

                                            </DragDropContext>
                                        </>

                                    ) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Mapping Keys'} /></label>
                                    <ReactSelect
                                        options={mappingKeys.filter(a => a.fieldTypes.includes(lookup.type))}
                                        name={"mappingKey"}
                                        placeholder={'select mapping key'}
                                        onChange={handleSelectChange}
                                        value={lookup.mappingKey}
                                        removeClearable={true}
                                        classNamePrefix={`${errorFields["mappingKey"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    lookup.mappingKey === 'model' || lookup.mappingKey === 'currentlyOwned' ? (<>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Make'} /></label>
                                            <ReactSelect
                                                options={props.dealersettings?.client?.brands ? props.dealersettings?.client?.brands.map(rec => {
                                                    return {
                                                        label: rec.name,
                                                        value: rec.value
                                                    }
                                                }) : []}
                                                name={"make"}
                                                placeholder={'select make'}
                                                onChange={handleSelectChange}
                                                value={lookup.make}
                                                removeClearable={true}
                                                classNamePrefix={`${errorFields["make"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                        {
                                            lookup.mappingKey === 'model' ? (
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'Sale Type'} /></label>
                                                    <ReactSelect
                                                        options={props.dealersettings?.client?.settings?.salesType ? props.dealersettings?.client?.settings?.salesType.map(rec => {
                                                            return {
                                                                label: rec.name,
                                                                value: rec.value
                                                            }
                                                        }) : saleTypes}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={handleSelectChange}
                                                        value={lookup.saleType}
                                                        removeClearable={true}
                                                        classNamePrefix={`${errorFields["saleType"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : (<></>)
                                        }
                                    </>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLookup(e)}>
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddDynamicOptions;