import React from 'react'
import _ from 'lodash'
import { Tabs, Tab } from 'react-bootstrap'
import EmailTemplateList from './emailtemplateList'
import SMSTemplateList from './smstemplateList'
import TVTemplateList from './tvtemplateList'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import GenericTemplates from '../genericTemplates'

const Users = (props) => {
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    const tvModuleEnabled = ((!_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.moduleSettings) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings.tvModule) &&
        props.dealersettings.client.moduleSettings.tvModule.enabled) ? true : false);

    const accessToTVDisplay = ((!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.accessToTVDisplay) ? true : false);
    return (
        <>
            {/* {
                liteVersion ? (<UpgradePlan />) : (<></>)
                ${liteVersion ? 'form-lite' : ''}
            } */}
            <h2> <Translate text={'Templates'} /></h2>
            <div className={`settings-tab-wrap tabs `}>
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey="email" className="nav-fill" mountOnEnter={true}>
                            <Tab eventKey="email" title={CommonHelper.showLocale(props, "EDMs")}>
                                <EmailTemplateList {...props} type='email' />
                            </Tab>
                            <Tab eventKey="emailTemplate" title={CommonHelper.showLocale(props, "Email Templates")}>
                                <EmailTemplateList {...props} type='emailTemplate' />
                            </Tab>
                            <Tab eventKey="triggersms" title={CommonHelper.showLocale(props, "Trigger SMS")}>
                                <SMSTemplateList {...props} type='sms' />
                            </Tab>
                            <Tab eventKey="sms" title={CommonHelper.showLocale(props, "SMS")}>
                                <SMSTemplateList {...props} type='smsTemplate' />
                            </Tab>
                            {
                                tvModuleEnabled && accessToTVDisplay ? (
                                    <Tab eventKey="tvTemplate" title={CommonHelper.showLocale(props, "TV Template")}>
                                        <TVTemplateList {...props} type='tvTemplate' />
                                    </Tab>
                                ) : (<></>)
                            }
                            <Tab eventKey="genericTemplates" title={CommonHelper.showLocale(props, "Notify Templates")}>
                                <GenericTemplates {...props} />
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Users