import React from "react";

export const reportVM = {
    documentID: null,
    clientID: null,
    userIDs: [],
    name: '',
    pipeline: '',
    type: '',
    subType: '',
    calculationType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
};


export const reportOptions = [
    { active: true, value: 'walkintestdrive', label: 'Walk In to Test Drive' },
    { active: true, value: 'enquiryOptionsDF', label: 'KPIs' },
    { active: true, value: 'salesConversion', label: 'Sales Conversion' },
]

export const salesConversions = [
    { active: true, value: 'appointment', label: 'Appointment' },
    { active: true, value: 'call', label: 'Call' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'walkin', label: 'Walk Ins' },
]

export const calculateOptions = [
    { active: true, value: 'count', label: 'Count' },
    { active: true, value: 'duration', label: 'Duration' },
    { active: true, value: 'percentage', label: 'Percentage' }
]

export const datefilterType = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const mandatoryFields = [
    'name',
    'type',
    'subType',
    'calculationType',
    'userIDs'
];

export const leadTypes = [
    { value: 'new', label: 'New Leads Only' },
    { value: 'existing', label: 'Existing Leads Only' }
]

export const reportGroupBy = [
    { label: 'User', value: 'user', displayName: 'User', displayValue: 'User', tableHeader: 'User' },
    { label: 'Technician', value: 'technician', displayName: 'Technician', displayValue: 'Technician', tableHeader: 'Technician' },
    { label: 'Parts Interpretor', value: 'partsInterpretor', displayName: 'Parts Interpretor', displayValue: 'Parts Interpretor', tableHeader: 'Parts Interpretor' },
    { label: 'Workshop Manager', value: 'workshopManager', displayName: 'Workshop Manager', displayValue: 'workshop manager', tableHeader: 'Workshop Manager' },
    { label: 'Service Advisor', value: 'serviceAdvisor', displayName: 'Service Advisor', displayValue: 'service advisor', tableHeader: 'Service Advisor' },
    { label: 'Model', value: 'model', displayName: 'Make', displayValue: 'make', tableHeader: 'Model' },
    { label: 'Service Type', value: 'serviceType', displayName: 'Service Type', displayValue: 'service type', tableHeader: 'Service Type' },
    { label: 'Appointment Type', value: 'appointmentTypes', displayName: 'Appointment Type', displayValue: 'appointment type', tableHeader: 'Appointment Type' },
    { label: 'Status', value: 'serviceStatus', displayName: 'Status', displayValue: 'status', tableHeader: 'Status' },
    { label: 'Date Filter', value: 'dateFilter', displayName: 'Date Filter', displayValue: 'date filter', tableHeader: 'Date' },
]

export const filterOptions = [
    { label: 'Date', value: 'date', displayValue: 'date' },
    { label: 'User', value: 'user', displayValue: 'user' },
    { label: 'Technician', value: 'technician', displayValue: 'Technician' },
    { label: 'Parts Interpretor', value: 'partsInterpretor', displayValue: 'Parts Interpretor' },
    { label: 'Workshop Manager', value: 'workshopManager', displayValue: 'Workshop Manager' },
    { label: 'Service Advisor', value: 'serviceAdvisor', displayValue: 'Service Advisor' },
    { label: 'Service Type', value: 'serviceType', displayValue: 'Service Type' },
    { label: 'Appointment Type', value: 'appointmentTypes', displayValue: 'Appointment Type' },
    { label: 'Status', value: 'serviceStatus', displayValue: 'status' },
    { label: 'Make and Models', value: 'makeModels', displayValue: 'make' },
]

export const visibilities = [
    { active: true, value: 'private', label: <><i className="ico icon-reserve mr-1"></i> Private</> },
    { active: true, value: 'shared', label: <><i className="ico icon-edit-circle mr-1"></i> Shared - anyone can edit</> },
    { active: true, value: 'sharedView', label: <><i className="ico icon-activity-meeting mr-1"></i> Shared - only owner can edit</> },
]

export const reportDynColumns = [
    //end
    { label: 'Total Jobs', value: 'totalJobs', name: 'Total Jobs', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#4967CC' },
    { label: 'Pending Jobs', value: 'pendingjobs', name: 'Pending Jobs', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#9294F8' },
    { label: 'Scheduled', value: 'scheduled', name: 'Scheduled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#1036A0' },
    { label: 'Check In', value: 'checkIn', name: 'Check In', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#F7931E' },
    { label: 'Technician Requested', value: 'technicianRequested', name: 'Technician Requested', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#39B54A' },
    { label: 'Parts Requested', value: 'partsRequested', name: 'Parts Requested', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#049B8C' },
    { label: 'Workshop Requested', value: 'workshopRequested', name: 'Workshop Requested', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#EF4136' },
    { label: 'Advisor Requested', value: 'advisorRequested', name: 'Advisor Requested', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#DB4F9B' },
    { label: 'Owner Requested', value: 'ownerRequested', name: 'Owner Requested', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#FFAE64' },
    { label: 'Owner Approved', value: 'ownerApproved', name: 'Owner Approved', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#003F5C' },
    { label: 'Owner Declined', value: 'ownerDeclined', name: 'Owner Declined', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#29ABE2' },
    { label: 'Completed', value: 'completed', name: 'Completed', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#f97863' },
    { label: 'Cancelled', value: 'cancelled', name: 'Cancelled', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#5D9B40' },
    { label: 'Total Job Duration Time', value: 'overalljobDuration', name: 'Total Job Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#f97863', average: true },
    { label: 'Avg. Job Duration Time', value: 'jobDuration', name: 'Avg. Job Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#DB4F9B', average: true },
    { label: 'Max. Job Duration Time', value: 'maxjobDuration', name: 'Max. Job Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#FFAE64', average: true },
    { label: 'Min. Job Duration Time', value: 'minjobDuration', name: 'Min. Job Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#5D9B40', average: true },
    //service job duration from checkin to completed
    { label: 'Total Service Duration Time', value: 'overallserviceDuration', name: 'Total Service Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#f97863', average: true },
    { label: 'Avg. Service Duration Time', value: 'serviceDuration', name: 'Avg. Service Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#DB4F9B', average: true },
    { label: 'Max. Service Duration Time', value: 'maxserviceDuration', name: 'Max. Service Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#FFAE64', average: true },
    { label: 'Min. Service Duration Time', value: 'minserviceDuration', name: 'Min. Service Duration Time', view: ['table', 'column', 'target'], calculationType: ['duration'], color: '#5D9B40', average: true },
    //revenue    
    { label: 'Total Labour Cost', value: 'totalLabourCost', name: 'Total Labour Cost', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', currency: true },
    { label: 'Total Recommendation', value: 'totalRecommCost', name: 'Total Recommendation', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', currency: true },
    { label: 'Total Revenue', value: 'totalServiceCost', name: 'Total Revenue', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', currency: true },
    { label: 'Total Declined', value: 'totalDeclinedCost', name: 'Total Declined', view: ['table', 'column', 'pie', 'target'], calculationType: ['amount'], color: '#39B54A', currency: true },

    { label: 'Recommendation Approved', value: 'recomApproved', name: 'Recommendation Approved', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#003F5C' },
    { label: 'Recommendation Declined', value: 'recomDeclined', name: 'Recommendation Declined', view: ['table', 'column', 'pie', 'target'], calculationType: ['count', 'dqCount'], color: '#29ABE2' },
]


export const frequencyOptions = [
    { active: true, value: 'daily', label: 'Daily' },
    { active: true, value: 'weekly', label: 'Weekly' },
    { active: true, value: 'monthly', label: 'Monthly' }
]

export const dataOptions = [
    { active: true, value: 'today', label: `Today's Data` },
    { active: true, value: 'wtd', label: 'Last 7 Days Data' },
    { active: true, value: 'mtd', label: 'Current Month Data' }
]

export const columnTypes = [
    { label: 'Activities', value: 'activities' },
    { label: 'Trigger Activities', value: 'triggers' },
    { label: 'Customer Logs', value: 'logs' },
    { label: 'Duration', value: 'duration' },
    { label: 'Performance', value: 'performance' }
]

export const dataQualityOptions = [
    { label: 'With Phone', value: 'phone' },
    { label: 'Without Phone', value: 'nophone' },
    { label: 'With Email', value: 'email' },
    { label: 'Without Email', value: 'noemail' },
    { label: 'With Nationality', value: 'nationality' },
    { label: 'Without Nationality', value: 'nonationality' },
    { label: 'With Vehicle Model', value: 'make' },
    { label: 'Without Vehicle Model', value: 'nomake' },
    { label: 'With Origin', value: 'origin' },
    { label: 'Without Origin', value: 'noorigin' },
    { label: 'With Enquiry Type', value: 'enquiryType' },
    { label: 'Without Enquiry Type', value: 'noenquiryType' },
    { label: 'With Campaign', value: 'campaign' },
    { label: 'Without Campaign', value: 'nocampaign' },
]
export const defaultFilters = {
    user: [],
    dmstype: [],
    make: '',
    model: [],
    pipelines: [],
    enquiryTypes: [],
    campaigns: [],
    origins: [],
    status: [],
    label: [],
    lostReasons: [],
    lostsubreason: [],
    salesType: [],
    vehicleSearchOn: '',
}

export const dealertargetDynCols = [
    { name: 'Target', value: 'target', color: '#f8cbad', borderColor: '#f19454', dummyData: { width: 20, height: 20 } },
    { name: 'Actual', value: 'actual', color: '#e7e6e6', borderColor: '#2f2f2f', dummyData: { width: 20, height: 20, } },
    { name: 'Target-Cummulative', value: 'cummulativeTarget', color: '#ed7d31', dummyData: { width: 25, height: 7, top: 8, right: 20 } },
    { name: 'Actual-Cummulative', value: 'cummulativeActual', color: '#000000', dummyData: { width: 25, height: 7, top: 8, right: 20 } }
]

export const reportDynFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
        fixed: true
    },
    { value: 'totalJobs', name: 'Total Jobs', flex: 1, },
    { value: 'pendingjobs', name: 'Pending Jobs', flex: 1, },
    { value: 'scheduled', name: 'Scheduled', flex: 1, },
    { value: 'checkIn', name: 'Check In', flex: 1, },
    { value: 'technicianRequested', name: 'Technician Requested', flex: 1, },
    { value: 'partsRequested', name: 'Parts Requested', flex: 1, },
    { value: 'workshopRequested', name: 'Workshop Requested', flex: 1, },
    { value: 'advisorRequested', name: 'Advisor Requested', flex: 1, },
    { value: 'ownerRequested', name: 'Owner Requested', flex: 1, },
    { value: 'ownerApproved', name: 'Owner Approved', flex: 1, },
    { value: 'ownerDeclined', name: 'Owner Declined', flex: 1, },
    { value: 'completed', name: 'Completed', flex: 1, },
    { value: 'cancelled', name: 'Cancelled', flex: 1, },
    { value: 'jobDuration', name: 'Avg. Job Duration Time', flex: 1, },
    { value: 'maxjobDuration', name: 'Max. Job Duration Time', flex: 1, },
    { value: 'minjobDuration', name: 'Min. Job Duration Time', flex: 1, },
    { value: 'serviceDuration', name: 'Avg. Service Duration Time', flex: 1, },
    { value: 'maxserviceDuration', name: 'Max. Service Duration Time', flex: 1, },
    { value: 'minserviceDuration', name: 'Min. Service Duration Time', flex: 1, },
    { value: 'totalLabourCost', name: 'Total Labour Cost', flex: 1, },
    { value: 'totalRecommCost', name: 'Total Recommendation', flex: 1, },
    { value: 'totalServiceCost', name: 'Total Revenue', flex: 1, },
    { value: 'totalDeclinedCost', name: 'Total Declined', flex: 1, },
    { value: 'recomApproved', name: 'Recommendation Approved', flex: 1, },
    { value: 'recomDeclined', name: 'Recommendation Declined', flex: 1, },

]