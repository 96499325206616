import React, { useState, useEffect } from 'react';
import { Tabs,Tab } from 'react-bootstrap'
import _ from 'lodash'
import Makes from './makes'
import Models from './models'

const VehicleModel = (props) => {
    return (
        <>
            <h2>Vehicle Settings </h2>
            <div className='settings-tab-wrap tabs'>
                <div className='tabs-wraper'>
                    <nav>
                        <Tabs
                            defaultActiveKey='makes'
                            className='nav-fill'
                        >
                            <Tab eventKey='makes' title='Makes'>
                                <Makes />
                            </Tab>
                            <Tab eventKey='models' title='Models'>
                                <Models />
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div>
        </>
    )
};

export default VehicleModel