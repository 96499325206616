import React, { useState, useEffect, useRef, createRef } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
import toast from 'toasted-notes';
import { InputText, ReactSelect, PopUpModal } from '../../../../components';
import { visibilities, tvTemplateVM, resolutions, displayZooms } from './viewModel';
import Draggable from "react-draggable";
import uuid from 'react-uuid';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Resizable } from "re-resizable";
import HTMLTextEditor from './textEditor';
import images from '../../../../images';
import { SketchPicker } from 'react-color';
import LineThicknessEditor from './lineEditor';
import { storageBucket } from '../../../../services/helper';
import CommonHelper from '../../../../services/common';

const AddTemplateHTML = props => {
	const [template, setTemplate] = useState(
		props.template
			? props.template
			: {
				title: '',
				visibility: 'private',
				items: [],
			},
	);
	const [oldTemplate, setOldTemplate] = useState()
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(false);

	const [showEditor, setShowEditor] = useState(false)
	const [item, setItem] = useState()
	const [colorPicker, setColorPicker] = useState(false)
	const [imageloader, setImageLoader] = useState(false)
	const [displayWidth, setDisplayWidth] = useState('1366');
	const [displayHeight, setDisplayHeight] = useState('768');
	const [clszoom, setZoom] = useState(90);
	const [showlineEditor, setShowLineEditor] = useState(false)
	const [dragDisabled, setDragDisabled] = useState(true)
	const [hideBorder, setHideBorder] = useState(true)
	const [resizeEnable, setResizeEnable] = useState(false)
	const node = useRef([]);
	const styles = {
		colorPicker: {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background:
				!_.isEmpty(template) && template.bgColor
					? template.bgColor
					: '#FFFFFF',
		},
		swatch: {
			padding: '5px',
			background: '#fff',
			borderRadius: '1px',
			boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
			display: 'inline-block',
			cursor: 'pointer',
		},
		popover: {
			position: 'absolute',
			zIndex: '2',
		},
		cover: {
			position: 'fixed',
			top: '0px',
			right: '0px',
			bottom: '0px',
			left: '0px',
		},
		bgWrap: {
			background: `${template.bgColor ? template.bgColor : ''} ${template.bgImage ? `url(${template.bgImage})` : ''} no-repeat center center / cover`,
			border: '1px solid #dce3e6'
		},
		divOverlay: template.opacity > 0 ? {
			display: 'block',
			backgroundColor: '#000',
			zIndex: 9,
			opacity: template.opacity / 100,
			height: '100%',
			position: 'absolute',
			width: '100%'

		} : {},
		bragBox: {
			position: 'absolute',
			overflowWrap: 'break-word',
			borderRadius: '5px',
			margin: 'auto',
			userSelect: 'none'
		},
		resizeStyle: {
			marginTop: -5,
			marginLeft: -7,
			top: "50%",
			cursor: "ew-resize",
			border: "3px solid #4466f2",
			borderTop: "none",
			borderLeft: "none",
			borderBottom: "none",
			borderWidth: 15,
			borderColor: "#4466f2",
			width: 10,
			height: 30,
			boxSizing: "border-box",
			zIndex: 1,
			display: hideBorder ? 'none' : 'block'
		}
	};

	useEffect(() => {
		node.current = template.items.map((element, i) => node.current[i] ?? createRef());
	}, [template.items])

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);
	const handleClick = e => {



		if (node && node.current && node.current.some(item => item.current && item.current.contains(e.target))) {
			return;
		}
		setDragDisabled(true);
		setHideBorder(true);
		setResizeEnable(false);
		// setTemplate({
		// 	...template,
		// 	['items']: template.items.map(r => {
		// 		return {
		// 			...r,
		// 			focusCls: ''
		// 		}
		// 	})
		// })
	};

	useEffect(() => {
		if (_.isEmpty(props.template)) {
			let newtemplateVM = Object.assign({}, tvTemplateVM);
			newtemplateVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/templates/`).doc().id;
			newtemplateVM.addedBy = localStorage.uid;
			newtemplateVM.modifiedBy = localStorage.uid;
			newtemplateVM.addedDate = window.firebase.firestore.Timestamp.now();
			newtemplateVM.modifiedDate = window.firebase.firestore.Timestamp.now();
			newtemplateVM.visibility = 'private'
			newtemplateVM.displayResolution = '1366x768'
			newtemplateVM.displayZoom = 90
			//newtemplateVM.	 = '#FFFFFF'
			newtemplateVM.type = props.type ? props.type : 'tvTemplate'
			if (props.dealersettings.level === 'oem' && props.dealersettings.oemID && localStorage.defaultModule === 'oem') {
				newtemplateVM.oemID = props.dealersettings.oemID;
				newtemplateVM.dealerName = props.dealersettings.oemName;
			}
			else if (props.dealersettings.level === 'region' && props.dealersettings.regionID && localStorage.defaultModule === 'oem') {
				newtemplateVM.regionID = props.dealersettings.regionID;
				newtemplateVM.dealerName = props.dealersettings.oemName;
			}
			else if (props.dealersettings.level === 'group' && props.dealersettings.groupID && localStorage.defaultModule === 'oem') {
				newtemplateVM.groupID = props.dealersettings.groupID;
				newtemplateVM.dealerName = props.dealersettings.oemName;
			}
			else {
				newtemplateVM.clientID = props.dealersettings.client.id;
				newtemplateVM.dealerName = props.dealersettings.client.name;
			}
			setTemplate(newtemplateVM)
		}
		else {
			if (props.template.displayResolution) {
				setDisplayWidth(props.template.displayResolution.split('x')[0])
				setDisplayHeight(props.template.displayResolution.split('x')[1])
			}
			if (props.template.displayZoom) {
				setZoom(props.template.displayZoom);
			}
			setOldTemplate(props.template);
		}
	}, [])

	const handlePropsModelClose = () => {
		if (_.isEmpty(props.template) || JSON.stringify(template) === JSON.stringify(oldTemplate)) {
			props.handleClose()
		}
		else {
			Swal.fire({
				title: CommonHelper.showLocale(props, 'Are you sure?'),
				text: CommonHelper.showLocale(props, 'You want to discard changes.'),
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
				cancelButtonText: CommonHelper.showLocale(props, 'No'),
			}).then(result => {
				if (result.value) {
					props.handleClose()
				}
			});
		}
	}


	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		var str = value;

		if (str && str.length >= 1) {
			var firstChar = str.charAt(0);
			var remainingStr = str.slice(1);
			str = firstChar.toUpperCase() + remainingStr;
		}
		setTemplate({
			...template,
			[name]: str,
		});

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleSelectChange = (e, data) => {
		if (data.name === 'visibility' && e.value === 'private') {
			setTemplate({
				...template,
				[data.name]: e.value,
				level: '',
				levelID: '',
				subLevelIDs: '',
				regionIDs: [],
				groupIDs: [],
				clientIDs: [],
			});
		} else {
			setTemplate({
				...template,
				[data.name]: e.value,
			});
		}
		if (data.name === 'displayResolution') {
			setDisplayWidth(e.value.split('x')[0])
			setDisplayHeight(e.value.split('x')[1])
		}
		if (data.name === 'displayZoom') {
			setZoom(e.value)
		}
		setErrorFields({
			...errorFields,
			[data.name]: '',
		});
	};

	const handleSavetemplate = () => {
		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(template['title'])) {
			formIsValid = false;
			errors['title'] = errorClass;
		}
		// if (!template.lev el && template.visibility === 'shared') {
		//     formIsValid = false;
		//     errors['level'] = errorClass;
		// }
		// if (!_.isEmpty(template.level) && template.visibility === 'shared' && !template.subLevelIDs && template.level !== 'oem' && template.level !== 'individual') {
		//     formIsValid = false;
		//     errors['subLevelIDs'] = errorClass;
		// }
		if (template.level) {
			let arrToLooped = !_.isEmpty(template.subLevelIDs)
				? template.subLevelIDs.split(',')
				: [];
			let clientIDs = [];
			let regionIDs = [];
			let groupIDs = [];
			let level = template.level ? template.level : '';
			template.level = level;
			if (template.level === 'oem') {
				props.clientOptions.forEach(clientOption => {
					clientIDs.push(clientOption.value);
				});
				template.clientIDs = clientIDs;
			} else if (template.level === 'region') {
				regionIDs = arrToLooped;
				arrToLooped.forEach(regionID => {
					props.clientOptions.forEach(clientOption => {
						if (
							clientOption.data.region &&
							clientOption.data.region === regionID
						) {
							clientIDs.push(clientOption.value);
						}
					});
				});
				template.regionIDs = regionIDs;
				template.clientIDs = clientIDs;
			} else if (template.level === 'group') {
				groupIDs = arrToLooped;
				arrToLooped.forEach(groupID => {
					props.clientOptions.forEach(clientOption => {
						if (
							clientOption.data.group &&
							clientOption.data.group === groupID
						) {
							clientIDs.push(clientOption.value);
						}
					});
				});
				template.groupIDs = groupIDs;
				template.clientIDs = clientIDs;
			} else if (template.level === 'individual') {
				clientIDs = arrToLooped;
				template.clientIDs = clientIDs;
			}
		}
		setErrorFields(errors);
		if (!formIsValid) {
			return;
		}
		setLoader(true);
		if (props.title.includes('Add')) {
			template.addedDate = window.firebase.firestore.Timestamp.now();
			template.addedBy = localStorage.uid;
			template.documentID = window.firebase
				.firestore()
				.collection(`emailTemplates`)
				.doc().id;
			if (props.dealersettings.level === 'oem' && props.dealersettings.oemID && localStorage.defaultModule === 'oem') {
				template.oemID = props.dealersettings.oemID;
				template.dealerName = props.dealersettings.oemName;
			}
			else if (props.dealersettings.level === 'region' && props.dealersettings.regionID && localStorage.defaultModule === 'oem') {
				template.regionID = props.dealersettings.regionID;
				template.dealerName = props.dealersettings.oemName;
			}
			else if (props.dealersettings.level === 'group' && props.dealersettings.groupID && localStorage.defaultModule === 'oem') {
				template.groupID = props.dealersettings.groupID;
				template.dealerName = props.dealersettings.oemName;
			}
			else {
				template.clientID = props.dealersettings.client.id;
				template.dealerName = props.dealersettings.client.name;
			}
			template.isDeleted = false;
			template.templateJSON = null;
			template.type = props.type;
		}
		template.modifiedDate = window.firebase.firestore.Timestamp.now();
		template.modifiedBy = localStorage.uid;
		template.bodyHTML = '';
		if (_.isEmpty(template.bgColor)) {
			template.bgColor = '#ffffff';
		}
		template.items = template.items.map(rec => {
			return {
				...rec,
				focusCls: null
			}
		});
		// template.groupID = localStorage.uid
		// template.oemID = localStorage.uid
		// template.regionID = localStorage.uid

		//	console.log('template at save', template);
		window.firebase
			.firestore()
			.doc(
				`clientSettings/${props.dealersettings.client.settingsID}/templates/${template.documentID}`,
			)
			.set({ ..._.pick(template, _.keys(tvTemplateVM)) }, { merge: true })
			.then(snapshot => {
				setLoader(false);
				toast.notify(
					props.newTemplate === true
						? 'Template added successfully'
						: 'Template updated successfully',
					{
						duration: 2000,
					},
				);
				props.handleClose(template);
			})
			.catch(error => {
				console.error(error);
				toast.notify('Something went wrong', {
					duration: 2000,
				});
			});
	};

	const deleteNote = (id) => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				setTemplate({
					...template,
					'items': template.items.filter((item) => item.id !== id)
				})
			}
		})
	};

	const updatePos = (data, index) => {
		let layoutWidth = 0;
		let layoutHeight = 0;
		if (document.getElementById('drag-container')) {
			layoutWidth = document.getElementById('drag-container').clientWidth;
			layoutHeight = document.getElementById('drag-container').clientHeight;
		}
		let newArr = [...template.items];
		newArr[index].defaultPos = { x: data.x, y: data.y };
		newArr[index].left = layoutWidth > 0 ? calcPercentage(layoutWidth, data.x) : null;
		newArr[index].top = layoutHeight > 0 ? calcPercentage(layoutHeight, data.y) : null;
		newArr[index].x = layoutWidth > 0 ? calcXY(layoutWidth, data.x) : data.x;
		newArr[index].y = layoutHeight > 0 ? calcPercentage(layoutHeight, data.y) : data.y;
		setTemplate({
			...template,
			'items': newArr
		})
	};

	const calcPercentage = (w, x) => {
		let value = Math.round((100 / w) * x);
		if (value < 0)
			value = 0;
		if (value > 100)
			value = 100;
		return value;
	}

	const calcXY = (val, xy) => {
		return xy + (val * (3 / 100));;
	}

	const onSelectFile = (e, type, id, title, rec) => {
		if (e.target.files && e.target.files.length > 0) {
			var _size = e.target.files[0].size;
			var filesize = _size / 1024 / 1024;
			if (filesize > 10) {
				Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 10MB.'), '', 'info')
				return;
			}

			let reader = new FileReader();
			let file = e.target.files[0];
			reader.onloadend = () => {
				const image = new Image();
				image.src = reader.result;
				image.onload = () => {
					saveImageToStorage(reader.result, id, type, file.name.substr((file.name.lastIndexOf('.') + 1)), image.width, image.height, rec);
				};
			}
			reader.readAsDataURL(file)
		}
	}

	const saveImageToStorage = (imageBase64, id, type, ext, width, height, rec) => {
		let uid = moment()._d.getTime().toString() + '.' + ext;
		let _width = width > 200 ? 200 : width;
		if (!_.isEmpty(rec)) {
			_width = rec.width;
		}
		let _height = (height / width) * _width;
		setImageLoader(true)
		var storageRef = storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/tvtemplates/${uid}`).putString(imageBase64, 'data_url');
		storageRef.on('state_changed', (snapshot) => {
			//console.log(snapshot);
		},
			(error) => {
				//console.log(error);
			},
			() => {
				storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/tvtemplates`).child(uid).getDownloadURL()
					.then(dataURL => {
						if (id === 'bgImage') {
							setTemplate({
								...template,
								[id]: dataURL
							})
						}
						else {
							let newitem = {
								id: uuid(),
								width: _width,
								height: _height,
								src: dataURL,
								type: 'image',
								defaultPos: { x: Math.round(displayWidth / 2), y: Math.round(displayHeight / 2) },
							};
							if (!_.isEmpty(rec)) {
								newitem = {
									...rec,
									width: _width,
									height: _height,
									src: dataURL,
								}
							}
							setTemplate({
								...template,
								'items': [...template.items.filter(a => a.id !== newitem.id), newitem]
							})
						}
						setImageLoader(false)
					})
			})
	};


	const handleModelClose = (item, newItem) => {
		setShowEditor(false);
		setShowLineEditor(false);
		if (item) {
			setDragDisabled(newItem);
			setHideBorder(newItem);
			setTemplate({
				...template,
				['items']: [...template.items.filter(a => a.id !== item.id).map(r => {
					return {
						...r,
						focusCls: ''
					}
				}), {
					...item,
					focusCls: newItem ? null : 'tv-element-focus-border'
				}]
			})
		}

	}
	return _.isEmpty(template) ? (<></>) : (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							{/* <button
								type='button'
								className={`btn btn-secondary float-left ml-2 `}
								onClick={() => { }}
							>
								Preview
							</button> */}
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={handleSavetemplate}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									handlePropsModelClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<div className='form-style w-100'>
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label><Translate text={'title'} /></label>
											<InputText
												autoComplete='off'
												placeholder={'title'}
												className={`form-control ${errorFields['title']}`}
												name='title'
												onChange={handleOnChange}
												value={template.title}
												maxLength={150}
											/>
										</div>

										<div className='form-group col-md-6'>
											<label>
												<Translate text={'visibility'} />
											</label>
											<ReactSelect
												options={visibilities}
												name={'visibility'}
												placeholder={'select visibility'}
												onChange={handleSelectChange}
												value={template.visibility}
												classNamePrefix={`basic-select`}
												removeClearable={true}
												isSearchable={false}
											></ReactSelect>
										</div>
										<div className='tv-campaign-panel'>
											<div className='tv-campaign-main-container'>
												<div className='tv-campaign-wrapper'>
													<div className="tv-campaign-left-panel">
														<div className="tv-campaign-left-menu">
															<h4><Translate text={'Components'} /> </h4>
															<div className="tv-campaign-icon-panel">

																<div className="tv-campaign-icon-list" onClick={() => {
																	setItem(null);
																	setShowEditor(true);
																}}>
																	<div className="tv-campaign-icon-image"><img src={images.formatText} height="30" /></div>
																	<div className="tv-campaign-icon-text"> <Translate text={'Text'} /></div>


																</div>
																<div className="tv-campaign-icon-list">
																	<label htmlFor='addImage' className='cursor-pointer'>
																		<div className="tv-campaign-icon-image"><img src={images.formatImage} height="30" /></div>
																		<div className="tv-campaign-icon-text"> <Translate text={'Image'} /></div>
																	</label>
																	<input className="fileInput"
																		type="file"
																		name="testee"
																		id="addImage"
																		accept="image/*"
																		style={{ display: 'none' }}
																		onChange={(e) => onSelectFile(e, 'profile', 'image', 'Add Image')} />

																</div>
																<div className="tv-campaign-icon-list" onClick={() => {
																	setItem(null);
																	setShowLineEditor(true)
																}}>
																	<div className="tv-campaign-icon-image"><img src={images.formatLine} height="30" /></div>
																	<div className="tv-campaign-icon-text"> <Translate text={'Line'} /></div>
																</div>
															</div>
														</div>

														<div className="tv-campaign-left-menu">
															<h4>Layout </h4>
															<div className="tv-campaign-template-wrap ">
																<div className="w-100 d-inline-block tv-campaign-label"><Translate text={'Background Image'} /></div>
																<div className="tv-campaign-background-wrap">
																	<div className="tv-campaign-background-btn">
																		<div className="tv-campaign-bg-upload">
																			<label htmlFor="addBG" className="btn-common float-left mr-2 font-600"><Translate text={'Choose Picture'} />
																				<input className="fileInput"
																					type="file"
																					name="testee"
																					id="addBG"
																					accept="image/*"
																					style={{ display: 'none' }}
																					onChange={(e) => onSelectFile(e, 'profile', 'bgImage', 'Add Image')} />
																			</label>
																			<div className={`settings-delete-button ${template.bgImage ? '' : 'btn-disable'}`}> <a href="#" className="mini-button" onClick={(e) => {
																				e.preventDefault();
																				Swal.fire({
																					title: CommonHelper.showLocale(props, 'Are you sure?'),
																					text: CommonHelper.showLocale(props, 'Do you want to delete'),
																					icon: 'info',
																					showCancelButton: true,
																					confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
																					cancelButtonText: CommonHelper.showLocale(props, 'No'),
																				}).then(result => {
																					if (result.value) {
																						setTemplate({
																							...template,
																							'bgImage': null
																						})
																					}
																				})

																			}}> <i className="ico icon-delete"></i></a></div></div>
																	</div>
																</div>

																<div className="form-group mt-2"><div className="w-100 d-inline-block tv-campaign-label"><Translate text={'Background Color'} /></div>
																	<div>
																		<div
																			style={styles.swatch}
																			onClick={() => {
																				setColorPicker(!colorPicker);
																			}}
																		>
																			<div
																				className='custom-color-picker-form-template'
																				style={styles.colorPicker}
																			/>
																		</div>
																		{colorPicker ? (
																			<div style={styles.popover}>
																				<div
																					style={styles.cover}
																					onClick={() => {
																						setColorPicker(false);
																					}}
																				/>
																				<SketchPicker
																					color={
																						!_.isEmpty(template) && template.bgColor
																							? template.bgColor
																							: '#000000'
																					}
																					onChange={color =>
																						setTemplate({
																							...template,
																							bgColor: color.hex
																						})
																					}
																				/>
																			</div>
																		) : null}
																	</div>
																</div>
																<div className="w-100 d-inline-block tv-campaign-label"><Translate text={'Background Transparency'} /></div>
																<div className="slider-range w-100">
																	<input
																		id="opacity"
																		name="opacity"
																		type="range"
																		min={0}
																		max={100}
																		step="10"
																		value={template.opacity ? template.opacity : 0}
																		onChange={e => {
																			e.preventDefault()
																			const { name, value } = e.target;
																			setTemplate({
																				...template,
																				[name]: value
																			})
																		}}
																	/>
																</div>
															</div>
														</div>

														<div className="tv-campaign-left-menu">
															<h4><Translate text={'Panel Controls'} /></h4>
															<div className="form-style tv-campaign-template-wrap">

																<div className="form-row w-100">
																	<div className="form-group col-md-12">
																		<label><Translate text={'Display Resolution'} /></label>
																		<ReactSelect
																			options={resolutions}
																			name={'displayResolution'}
																			placeholder={'select display resolution'}
																			onChange={handleSelectChange}
																			value={template.displayResolution}
																			classNamePrefix={`basic-select`}
																			removeClearable={true}
																			isSearchable={false}
																		></ReactSelect>
																	</div>
																	<div className="form-group col-md-12">
																		<label><Translate text={'Display Zoom'} />  </label>
																		<div className="slider-range w-100">
																			<input
																				id="displayZoom"
																				name="displayZoom"
																				type="range"
																				min={10}
																				max={100}
																				step="10"
																				value={template.displayZoom ? template.displayZoom : 0}
																				onChange={e => {
																					e.preventDefault()
																					const { name, value } = e.target;
																					setZoom(value)
																					setTemplate({
																						...template,
																						[name]: value
																					})
																				}}
																			/>
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
													<div className="tv-campaign-right-panel">
														<div className='tv-campaign-right-ele' style={{ width: `${displayWidth}px`, height: `${displayHeight}px`, zoom: `${clszoom}%` }}>
															<div id="drag-container" className={`tv-campaign-drag ${template.items.length > 0 || template.bgColor || template.bgImage ? '' : 'tv-campaign-drag-fix'}`}>
																{
																	template.items.length > 0 || template.bgColor || template.bgImage ?
																		template.items.map((item, index) => {
																			return (
																				<Draggable
																					bounds="parent"
																					key={item.id}
																					defaultPosition={item.defaultPos}
																					onStop={(e, data) => {
																						updatePos(data, index);
																					}}
																					defaultClassName={`${hideBorder ? '' : item.focusCls}`}
																					disabled={dragDisabled}
																				>
																					<div ref={node.current[index]} style={styles.bragBox}>
																						<div className={`cursor-${hideBorder ? 'pointer' : 'move'}`} onClick={(e) => {
																							e.preventDefault();
																							setDragDisabled(false);
																							setHideBorder(false);
																							setResizeEnable(false);
																							setTemplate({
																								...template,
																								'items': template.items.map(r => {
																									return {
																										...r,
																										focusCls: r.id === item.id ? 'tv-element-focus-border' : ''
																									}
																								})
																							})

																						}}>
																							{
																								item.type === 'text' ? (<><div style={{ margin: 0 }}>
																									<div dangerouslySetInnerHTML={{ __html: item.text }} />
																								</div>
																								</>) : item.type === 'line' ? (
																									<>
																										<Resizable
																											defaultSize={{
																												width: item.width
																											}}
																											enable={{
																												top: false,
																												right: (resizeEnable && !_.isEmpty(item.focusCls)),
																												bottom: false,
																												left: (resizeEnable && !_.isEmpty(item.focusCls)),
																												topRight: false,
																												bottomRight: false,
																												bottomLeft: false,
																												topLeft: false
																											}}
																											handleStyles={{
																												right: {
																													...styles.resizeStyle,
																													left: "100%",
																													height: 20
																												},
																												left: {
																													...styles.resizeStyle,
																													left: 0,
																													height: 20
																												}
																											}}
																											onResizeStop={(e, direction, ref, d) => {
																												let newArr = [...template.items];
																												newArr[index].width = item.width + d.width;
																												newArr[index].height = item.height + d.height;
																												setTemplate({
																													...template,
																													'items': template.items
																												})
																											}}
																										>
																											<hr style={{ borderTop: `${item.thickness}px solid ${item.color}` }} />
																										</Resizable>
																									</>
																								) : (<>
																									<Resizable
																										size={{
																											width: item.width,
																											height: item.height
																										}}
																										enable={{
																											top: false,
																											right: (resizeEnable && !_.isEmpty(item.focusCls)),
																											bottom: false,
																											left: (resizeEnable && !_.isEmpty(item.focusCls)),
																											topRight: false,
																											bottomRight: false,
																											bottomLeft: false,
																											topLeft: false
																										}}
																										handleStyles={{
																											right: {
																												...styles.resizeStyle,
																												left: "100%"
																											},
																											left: {
																												...styles.resizeStyle,
																												left: 0
																											}
																										}}
																										style={{
																											//border: "solid 1px #ddd",
																											background: `url(${item.src}) 0% 0% / contain no-repeat`
																										}}
																										lockAspectRatio={true}
																										onResizeStop={(e, direction, ref, d) => {
																											let newArr = [...template.items];
																											newArr[index].width = item.width + d.width;
																											newArr[index].height = item.height + d.height;
																											setTemplate({
																												...template,
																												'items': template.items
																											})
																										}}
																									>
																									</Resizable>
																								</>
																								)

																							}
																						</div>
																						{
																							!_.isEmpty(item.focusCls) && !hideBorder ? (
																								<>
																									{
																										!resizeEnable ? (
																											<button className='tv-element-focus-btn btn-enable-action' onClick={(e) => deleteNote(item.id)}>
																												X
																											</button>
																										) : (<></>)
																									}
																									{
																										item.type === 'image' && !resizeEnable ? (
																											<>
																												<label htmlFor='editImage' className='cursor-pointer btn-enable-action tv-element-focus-btn'>
																													<Translate text={'edit'} />
																												</label>
																												<input className="fileInput"
																													type="file"
																													name="testee"
																													id="editImage"
																													accept="image/*"
																													style={{ display: 'none' }}
																													onChange={(e) => onSelectFile(e, 'profile', 'image', 'Add Image', item)} /></>
																										) : !resizeEnable ? (
																											<button className='tv-element-focus-btn btn-enable-action' onClick={
																												(e) => {
																													setItem(item);
																													if (item.type === 'text')
																														setShowEditor(true);
																													else
																														setShowLineEditor(true);
																												}}>
																												<Translate text={'edit'} />
																											</button>
																										) : (<></>)
																									}
																									{
																										(item.type === 'image' || item.type === 'line') && !resizeEnable ? (
																											<button className='tv-element-focus-btn btn-enable-action' onClick={
																												(e) => {
																													setDragDisabled(true);
																													setResizeEnable(true);
																												}}>
																												<Translate text={'Resize'} />
																											</button>
																										) : (<></>)
																									}
																									{
																										(item.type === 'image' || item.type === 'line') && resizeEnable ? (
																											<button className='tv-element-focus-btn btn-enable-action' onClick={
																												(e) => {
																													setDragDisabled(false);
																													setResizeEnable(false);
																												}}>
																												<Translate text={'Done'} />
																											</button>
																										) : (<></>)
																									}
																								</>
																							) : (<></>)
																						}
																					</div>

																				</Draggable>
																			);
																		})
																		: (<>
																			<div className='tv-campaign-drag-empty'>
																				<div className="tv-campaign-drag-icon"><img src={images.selectDrag} height="50" /></div>
																				<span><Translate text={'Add Text/Image'} /></span>
																				<p><Translate text={'The components to start build your template'} /></p>
																			</div>
																		</>)
																}

															</div>
															<div style={styles.divOverlay}>
															</div>
															<div className={`tv-campaign-canvas-wrap`} style={template.items.length > 0 || template.bgColor || template.bgImage ? styles.bgWrap : {}}>
																{
																	imageloader
																		?
																		<>
																			<div className="dashboard-widget-loader h-100">
																				<div className="d-flex h-100 justify-content-center align-items-center text-primary">
																					<div className="spinner-border" role="status">
																						<span className="sr-only">Loading...</span>
																					</div>
																				</div>

																			</div>
																		</>
																		:
																		<>
																		</>
																}
																{/* <div className="tv-campaign-control-builder">
																<div className="tv-campaign-control-builder-block">

																	<span className="tv-campaign-control-builder-text">Enter some text...</span>
																	<div className="tv-campaign-control-builder-edit">Edit</div>

																</div>

															</div> */}



															</div>
														</div>


													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div >
			</Modal >
			<PopUpModal show={showEditor}>
				<HTMLTextEditor
					{...props}
					show={showEditor}
					title={'Add Text'}
					handleClose={handleModelClose}
					item={item}
					displayHeight={displayHeight}
					displayWidth={displayWidth}
				/>
			</PopUpModal>
			<PopUpModal show={showlineEditor}>
				<LineThicknessEditor
					{...props}
					show={showlineEditor}
					handleClose={handleModelClose}
					item={item}
					displayHeight={displayHeight}
					displayWidth={displayWidth}
				/>
			</PopUpModal>
		</>
	);
};

export default AddTemplateHTML;
