import React from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
// import { PopUpModal } from '../../../../components';
// import { schedulerVM } from './viewModel';
//import moment from 'moment';
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import CustomToolbar from './toolbar'
// import CustomEvent from './eventView'
// import ScheduleContact from './scheduleContact'
// import { MainContext } from '../../../layout/provider';
import ScheduleList from './scheduleList';
//const localizer = momentLocalizer(moment);
// const AcitivityReducer = (state, action) => {
// 	function updateEnquiry() {
// 		return state.map((item) => {
// 			if (item.documentID === action.data.documentID) {
// 				return action.data;
// 			}
// 			return item;
// 		});
// 	}
// 	switch (action.type) {
// 		case "SUCCESS": {
// 			return action.data
// 		}
// 		case "APPEND_LIST": {
// 			return [...state, ..._.differenceBy(action.data, state, 'documentID')]
// 		}
// 		case "ADD_LOG_LIST": {
// 			return [action.data, ...state, action.data]
// 		}
// 		case "REMOVE_LOG_LIST": {
// 			return state.filter(item => item.documentID !== action.data.documentID)
// 		}
// 		case "REMOVE_LOG": {
// 			return state.filter(item => item.documentID !== action.documentID)
// 		}
// 		case "UPDATE_LOG_LIST": {
// 			return updateEnquiry()
// 		}
// 		default:
// 			return state;
// 	}
// };

const ScheduleListPopup = props => {
	// const [schedulerList, dispatch] = useReducer(AcitivityReducer, [])
	// const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 123)
	// const [viewstate, setViewState] = useState('week')
	// const [loader, setLoader] = useState(false);
	// const [monthData, setMonthData] = useState(moment()._d.getMonth())
	// const [dateRange, setDateRange] = useState({
	// 	startDate: moment().startOf('week').add(-1, 'day').startOf('day'),
	// 	endDate: moment().endOf('week').add(1, 'day').endOf('day')
	// })
	// const [checkDataLoad, setDataload] = useState(true)
	// const [schedule, setSchedule] = useState({
	// 	show: false,
	// 	title: '',
	// 	slotDate: null
	// });
	// const [searchText, setSearchText] = useState('')
	// const [selectedDevice, setSelectedDevice] = useState(null)
	// const { tvTemplates } = useContext(MainContext);

	// useEffect(() => {
	// 	function handleResize() {
	// 		setCaleandarHeight(window.innerHeight - 123)
	// 	}
	// 	window.addEventListener('resize', handleResize);
	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 		window.unrefSchedulatData && window.unrefSchedulatData();
	// 	}
	// }, []);

	// useEffect(() => {
	// 	let refActivitiesData = window.firebase.firestore().collection('tvScheduler')
	// 		.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(dateRange.startDate.toDate()))
	// 		.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(dateRange.endDate.toDate()))
	// 		.where('clientID', '==', props.dealersettings.client.id)
	// 		.where('isDeleted', '==', false)

	// 	window.unrefSchedulatData = refActivitiesData
	// 		.onSnapshot(onCollectionUpdate);

	// 	//}

	// }, [dateRange])

	// const onCollectionUpdate = (querySnapshot) => {
	// 	let actionType;
	// 	const _activities = [];
	// 	if (!actionType) {
	// 		querySnapshot.forEach(function (doc) {
	// 			var logVM = convertLogVM(doc.data())
	// 			_activities.push(logVM)
	// 		});
	// 	}
	// 	dispatch({
	// 		type: "SUCCESS",
	// 		data: _activities.filter(a => !_.isEmpty(props.device) ? a.deviceID === props.device.documentID : true)
	// 	});

	// 	setDataload(false)
	// }

	// const convertLogVM = (doc) => {

	// 	doc.startDate = !_.isObject(doc.startDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate)._d) : doc.startDate;
	// 	doc.endDate = !_.isObject(doc.endDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate)._d) : doc.endDate;

	// 	const objlogData = Object.assign({}, doc);
	// 	const logVM = Object.assign({}, schedulerVM);
	// 	for (let [key, value] of Object.entries(objlogData)) {
	// 		if (logVM.hasOwnProperty(key))
	// 			logVM[key] = value;
	// 	}
	// 	logVM.start = moment.unix(doc.startDate.seconds).toDate()
	// 	logVM.end = moment.unix(doc.endDate.seconds).toDate()
	// 	logVM.contactName = doc.enquiry && doc.enquiry.contact ? `${doc.enquiry.contact.firstName} ${doc.enquiry.contact.lastName}` : ''
	// 	logVM.vehiclemodel = doc.enquiry && doc.enquiry.requirement ? !_.isEmpty(doc.enquiry.requirement) ? (!_.isEmpty(doc.enquiry.requirement.stock) && !_.isEmpty(doc.enquiry.requirement.stock.make)) ? (!_.isEmpty(doc.enquiry.requirement.stock.make) ? (doc.enquiry.requirement.stock.make + ' ' + doc.enquiry.requirement.stock.model) : '') : (!_.isEmpty(doc.enquiry.requirement.make) ? (doc.enquiry.requirement.make + ' ' + doc.enquiry.requirement.model) : '') : '' : '';
	// 	if (_.find(props.allDevice, { documentID: logVM.deviceID })) {
	// 		logVM.deviceName = _.find(props.allDevice, { documentID: logVM.deviceID }).name;
	// 		logVM.wrapper = _.find(props.allDevice, { documentID: logVM.deviceID }).wrapper;
	// 	}
	// 	if (_.find(tvTemplates, { documentID: logVM.templateID })) {
	// 		logVM.template = _.find(tvTemplates, { documentID: logVM.templateID }).title;
	// 	}

	// 	return logVM
	// }

	// const handleSelectSlot = ({ start, end }) => {
	// 	setSchedule({
	// 		show: true,
	// 		slotDate: {
	// 			start,
	// 			end
	// 		},
	// 		title: 'Schedule',
	// 		device: props.device
	// 	})
	// }

	// const handleChangeviewstate = (view) => {
	// 	setViewState(view);
	// }

	// const onSelectEvent = useCallback(async (eventData) => {
	// 	setSchedule({
	// 		show: true,
	// 		title: 'Schedule',
	// 		scheduleEnq: eventData,
	// 		device: props.device
	// 	})
	// }, [])

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<ScheduleList
									{...props}
									popupModel={true}
								/>
							</div>
						</div>
					</Modal.Body>
				</div >
			</Modal>
			{/* <PopUpModal show={schedule.show}>
				<ScheduleContact
					{...props}
					{...schedule}
					handleClose={() => {
						setSchedule({ show: false, title: '' });
					}}

				/>
			</PopUpModal> */}
		</>
	);
};

export default ScheduleListPopup;
