/** LIBRARIES */
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { InputText, PopoverListLoader } from '../../../components';
import { firestoreDB } from "../../../services/helper";
import { default as _images } from '../../../images';
import TIPHistory from '../info'

export default class EvalScan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                regNum: '',
                vinNum: '',
            },
            errors: {},
            isLoading: false,
            pageLoading: false,
            tradeIns: null
        }
    }


    clearList = () => {
        this.setState({ tradeIns: null });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.replace(/[@!|~&\/\\#,+()$~%.'":*?_^`<>{}-]/g, '')).toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });
        if (_.isEmpty(fields[name])) this.clearList();
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!_.isEmpty(errors)) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (!fields.regNum && fields.vinNum) {
            formIsValid = false;
            errors['regNum'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleSearch();
        }
        else
            return formIsValid;
    };

    handleSearch = () => {
        this.setState({ isLoading: true, tradeIns: [] })
        const { dealersettings, clientID, enquiryID, serviceID } = this.props;
        let state = Object.assign({}, this.state.fields);

        let _keywords = [];
        if (state.vinNum) _keywords.push(state.vinNum.toLowerCase());
        if (state.regNum) _keywords.push(state.regNum.toLowerCase());
        if (dealersettings && !_.isEmpty(_keywords)) {

            firestoreDB(dealersettings).firestore().collection('tradeinPro')
                .where('clientID', '==', (clientID ? clientID : dealersettings?.client?.id))
                .where('keywords', 'array-contains-any', _keywords)
                .where('isDeleted', '==', false)
                .get().then(response => {
                    const tradeIns = [];
                    response.docs.forEach((doc) => {
                        let _enq = Object.assign({}, doc.data());

                        if (enquiryID && _enq?.tags?.find((a) => a.type === 'ams pro' && a.refID === enquiryID)) {

                        }
                        else if (serviceID && _enq?.tags?.find((a) => a.type === 'service' && a.refID === serviceID)) {

                        }
                        else if ((_enq?.tradein?.regNo && _keywords.includes(_enq?.tradein?.regNo.toLowerCase())) || (_enq?.tradein?.chassisNo && _keywords.includes(_enq?.tradein?.chassisNo.toLowerCase()))) {
                            _enq.documentID = doc.id;
                            tradeIns.push(_enq);
                        }
                    });

                    this.setState({
                        tradeIns: _.orderBy(tradeIns, ["modifiedDate"], ["desc"]),
                        isLoading: false
                    });

                });
        }
        else {
            this.setState({
                isLoading: false
            });
        }


    };

    handleSelect = (id, data) => {
        const { enquiryID, serviceID } = this.props;
        if (!_.isEmpty(data?.tags) && data?.tags.find((a) => a.type === (serviceID ? 'service' : 'ams pro'))) {


            if (enquiryID && data?.tags.find((a) => a.type === 'ams pro' && a.refID === enquiryID)) {
                Swal.fire('This Appraisal already linked with this enquiry.', '', 'info')
                return;
            }

            if (serviceID && data?.tags.find((a) => a.type === 'service' && a.refID === serviceID)) {
                Swal.fire('This Appraisal already linked with this service.', '', 'info')
                return;
            }

            let _enquiryNo = serviceID ? data?.tradein?.service?.displayID : data?.tradein?.enquiry?.displayID;

            let _type = serviceID ? 'service' : 'enquiry';

            Swal.fire({
                title: CommonHelper.showLocale(this.props, `This Appraisal already linked with another ${_type}${_enquiryNo ? ` ${_enquiryNo}` : ''}.`),
                text: CommonHelper.showLocale(this.props, `Would you like to disconnect from the linked ${_type} and associate with this ${_type}?`),
                icon: "info",
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, "Yes, Link"),
                cancelButtonText: CommonHelper.showLocale(this.props, "cancel"),
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.handleClose(id, data, true);
                    this.setState({ pageLoading: true })
                }
            });
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, "Are you sure?"),
                text: CommonHelper.showLocale(this.props, `Do you want to link this appraisal.`),
                icon: "info",
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, "Yes"),
                cancelButtonText: CommonHelper.showLocale(this.props, "No"),
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.handleClose(id, data);
                    this.setState({ pageLoading: true })
                }
            });
        }


    }


    render() {
        const { regNum, vinNum } = this.state.fields;
        const { show, dealersettings, groupUsers, clientUsers } = this.props;
        const { isLoading, tradeIns, pageLoading } = this.state;
        const divStyle = {
            maxHeight: '400px',
            overflow: 'auto',
            position: "relative"
        };
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName=" modal-dialog-scrollable modal-dialog-centered modal-ppsr-manager">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'Link Appraisal'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLoading || pageLoading
                                ?
                                <>
                                    <div className="dashboard-widget-loader h-100">
                                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"><Translate text={'Loading...'} /></span>
                                            </div>
                                        </div>

                                    </div>
                                </>
                                :
                                <>
                                </>
                        }

                        <div className="container-fluid">
                            <div className="row">
                                <div className="form-style w-100">
                                    <div className={`form-row ${isLoading || pageLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'vehicleRegNo'} /></label>
                                            <InputText
                                                name="regNum"
                                                value={regNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'registration number'}
                                                className={`form-control ${this.state.errors["regNum"]}`}
                                            />
                                        </div>

                                    </div>

                                    <div className="ppsr-text-divider">OR</div>

                                    <div className={`form-row ${isLoading || pageLoading ? 'form-disable' : ''}`}>
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'chassisNo'} /></label>
                                            <InputText
                                                type="text"
                                                name="vinNum"
                                                value={vinNum}
                                                onChange={this.handleOnChange}
                                                autoComplete="off"
                                                placeholder={'chassis/vin number'}
                                                className={`form-control ${this.state.errors["vinNum"]}`}
                                            />
                                        </div>

                                    </div>



                                    <div className="mt-2">
                                        {
                                            isLoading ? (<PopoverListLoader />) :
                                                (!_.isEmpty(tradeIns) && tradeIns.length > 0)
                                                    ?
                                                    <>
                                                        <h5>Appraisals</h5>
                                                        <div style={divStyle} >
                                                            {tradeIns.map((tradein, index) =>
                                                                <div key={tradein.documentID} id={tradein.documentID} className="vehicle-popover-loop pb-0" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.handleSelect(tradein.documentID, tradein)

                                                                }}>
                                                                    <div className="divTable">
                                                                        <div className="divTableBody">
                                                                            <div className="divTableRow">
                                                                                <div className="divTableCell width100 pb-0">
                                                                                    <TIPHistory
                                                                                        key={index}
                                                                                        fields={tradein}
                                                                                        dealersettings={dealersettings}
                                                                                        clientUsers={clientUsers}
                                                                                        groupUsers={groupUsers}
                                                                                        sidepanelOpen={() => { }}
                                                                                        history={this.props.history}
                                                                                        showMore={false}
                                                                                        companyView={true}
                                                                                        isList={true}
                                                                                        hideLogo={true}
                                                                                    ></TIPHistory>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        {_.isObject(tradeIns) && (<div className="spinner-loader h-100 p-5">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-tradein-module-icon")}></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'noTradeinFound'} /></div>
                                                            </div>

                                                        </div>)}
                                                    </>
                                        }
                                    </div>



                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className={`modal-footer ${isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose() }}> <Translate text={'cancel'} /></button>

                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'search'} />
                        </button>
                    </Modal.Footer>


                </Modal>


            </>


        );
    }
}

