
/** LIBRARIES */
import React from "react";



class SearchBar extends React.Component {
  // constructor(props) {
  //   super(props);
  //   /*this.autocompleteInput = React.createRef();
  //   this.autocomplete = null;
  //   this.handlePlaceChanged = this.handlePlaceChanged.bind(this);*/
  // }

  /*componentWillMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
        {"types": ["geocode"]});

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged(){
    
    const place = this.autocomplete.getPlace();
    //console.log('place_changed', place);
    //this.props.onPlaceLoaded(place);
  }*/



  render() {
    return (
        <input ref={this.autocompleteInput}  id="autocomplete" placeholder="Enter your address"
         type="text" className="form-control"></input>
    );
  }
}
export default SearchBar;