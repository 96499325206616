import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect, AntDateRangePicker } from "../../components";
import { pipelineCountVMNEW } from './viewModel'
// import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const PipelineCounts = (props) => {

    const [counts, setCounts] = useState(pipelineCountVMNEW)
    const [pipelineOptions, setPipelineOption] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [todayStart, setTodayStart] = useState(window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
    const [todayEnd, setTodayEnd] = useState(window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate
    const [dates, setDates] = useState([]);
    const [loader, setLoader] = useState(false);

    // useEffect(() => {
    //     if (!_.isEmpty(props.dealersettings.client.timezone)) {
    //         setTodayStart(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).startOf('day')._d))
    //         setTodayEnd(window.firebase.firestore.Timestamp.fromDate(moment().tz(props.dealersettings.client.timezone).endOf('day')._d))
    //     }
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoader(true)
                let dataToSet = {}


                let _filter = {
                    clientID: props.dealersettings.client.id,
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
                }

                if (props.selectedPipeline) {
                    _filter.pipeline = props.selectedPipeline.value
                }
                else if (!_.isEmpty(props.pipeline)) {
                    _filter.pipeline = props.pipeline.map(item => item.value).join(',')
                }

                if (props.selectedUser) {
                    _filter.owner = props.selectedUser.value
                }

                if (props.dateRange) {
                    _filter.startDate = moment(props.dateRange.startDate.toDate()).format('YYYY-MM-DD')
                    _filter.endDate = moment(props.dateRange.endDate.toDate()).format('YYYY-MM-DD')
                }

                let _searchObject = {
                    type: "getServiceDashboardCounts",
                    params: JSON.stringify(_filter),
                }

                const getDashboardCounts = window.firebase.functions().httpsCallable('generic-getData');
                const resp = await getDashboardCounts(_searchObject)

                if (resp.data.success) {
                    const data = resp.data.data[0]
                    //console.log('data returned', data)

                    dataToSet = {
                        ...dataToSet,
                        appointmentsToday: data.appointmentsToday,
                        appointmentsMonth: data.appointmentsMonth,
                        cancelledJobsToday: data.cancelledJobsToday,
                        cancelledJobsMonth: data.cancelledJobsMonth,
                        checkedInToday: data.checkedInToday,
                        checkedInMonth: data.checkedInMonth,
                        pendingJobsToday: data.pendingJobsToday,
                        pendingJobsMonth: data.pendingJobsMonth,
                        ownerApprovedToday: data.ownerApprovedToday,
                        ownerApprovedMonth: data.ownerApprovedMonth,
                        ownerDeclinedToday: data.ownerDeclinedToday,
                        ownerDeclinedMonth: data.ownerDeclinedMonth,
                        workInProgressToday: data.workInProgressToday,
                        workInProgressMonth: data.workInProgressMonth,
                        completedJobsToday: data.completedJobsToday,
                        completedJobsMonth: data.completedJobsMonth, 
                        partsRequestedToday: data.partsRequestedToday,
                        partsRequestedMonth: data.partsRequestedMonth,
                    }
                } else {
                    // console.log('data not returned')
                }
                setCounts(dataToSet)
                setLoader(false)

            } catch (error) {
                console.error(error)
                setLoader(false)
            }
        }

        fetchData()
    }, [props.selectedPipeline, props.selectedUser, props.dateRange, props.refreshData, props.dealersettings.client.id])

    useEffect(() => {
        if (_.isEmpty(props.pipeline))
            return
        let _pipeline = [];
        props.pipeline.forEach(rec => {
            _pipeline.push({
                label: rec.name,
                active: _.isBoolean(rec.active) ? rec.active : true,
                value: rec.value,
                data: rec
            })
        })
        setPipelineOption(_pipeline)
    }, [props.pipeline])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return

        let _users = [];
        const { dealersettings } = props;

        if (!_.isEmpty(dealersettings.client.services) && !_.isEmpty(dealersettings.client.services.pipelines)) {
            const selectedPipelines = Array.prototype.map.call(dealersettings.client.services.pipelines, function (item) { return item.value; });

            let roleIds = [..._.map(dealersettings.client.roles.filter(e => e.modules && e.modules.service === true), function (obj) {
                return obj.documentID;
            })];

            if (!_.isEmpty(roleIds)) {
                _.filter(props.clientUsers, (v) => _.includes(roleIds, v.roleID)).forEach(rec => {
                    _users.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>,
                        data: rec
                    })
                })
            }

        }

        setUserOptions(_users)


    }, [props.clientUsers])

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    return (
        <div className="dashboard-panel-top">
            {loader && <div className='custom-counts-loader' />}
            <div className="dashboard-panel-head">
                <div className="float-left d-flex">
                    {
                        !_.isEmpty(props.selectedPipeline) ? (<h3>{props.selectedPipeline.label} Stats</h3>) : (<h3><Translate text={'allPipelineStats'} /></h3>)
                    }
                    {loader && <div className="align-items-center d-flex justify-content-center text-primary spinner-loader-h3"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div>}
                </div>
                <div className="float-lg-right form-dashboard">
                    <div className="form-row justify-content-end">
                        <div className="form-group col-md-4 daterange-fix">
                            <AntDateRangePicker
                                value={props.dateRange && props.applydateRange === true ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={'dateDashboard'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { props.handleDateRangeChange(e, 'dateDashboard') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                                disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <ReactSelect
                                options={pipelineOptions}
                                name={"pipeline"}
                                placeholder={'select pipeline'}
                                onChange={(e, data) => {
                                    props.setPipeline(e)
                                }}
                                value={!_.isEmpty(props.selectedPipeline) ? props.selectedPipeline.value : ''}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={false}
                            >
                            </ReactSelect>
                        </div>
                        <div className="form-group col-md-3"><ReactSelect
                            options={userOptions}
                            name={"user"}
                            placeholder={'select user'}
                            onChange={(e, data) => {
                                props.setUser(e)
                            }}
                            value={!_.isEmpty(props.selectedUser) ? props.selectedUser.value : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={false}
                        >
                        </ReactSelect> </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-panel-body">
                <div className=" row col-lg-3-fix">
                    <div className="col-lg-4">
                        <div className="row">

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-appointments-dash"></i></div>
                                    <div className="dashboard-module-label"> Appointments</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.appointmentsToday) {
                                                            props.handleShowEnquiryList({
                                                                //status: 'scheduled',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'appointmentDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Appointments', counts.appointmentsToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.appointmentsToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.appointmentsMonth) {
                                                    props.handleShowEnquiryList({
                                                        //status: 'scheduled',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'appointmentDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Appointments' : 'Appointments'), counts.appointmentsMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.appointmentsMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-cancelledjobs-dash"></i></div>
                                    <div className="dashboard-module-label"> Cancelled</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.cancelledJobsToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'cancelled',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'modifiedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Cancelled', counts.cancelledJobsToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.cancelledJobsToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.cancelledJobsMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'cancelled',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'modifiedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Cancelled' : 'Cancelled'), counts.cancelledJobsMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.cancelledJobsMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-checkedin-dash"></i></div>
                                    <div className="dashboard-module-label"> Checked In</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.checkedInToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'checkIn',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'checkInDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Checked In', counts.checkedInToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.checkedInToday}</a></div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.checkedInMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'checkIn',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'checkInDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Checked In' : 'Checked In'), counts.checkedInMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.checkedInMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">
                            
                        <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-pendingjobs-dash"></i></div>
                                    <div className="dashboard-module-label"> Pending Jobs</div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.pendingJobsToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'pending',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'modifiedDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Pending Jobs', counts.pendingJobsToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.pendingJobsToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.pendingJobsMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'pending',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'modifiedDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Pending Jobs' : 'Pending Jobs'), counts.pendingJobsMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.pendingJobsMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-parts-req"></i></div>
                                    <div className="dashboard-module-label"> Parts Requested </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.partsRequestedToday) {
                                                            props.handleShowEnquiryList({ 
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'partsRequested',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Parts Requested', counts.partsRequestedToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.partsRequestedToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.partsRequestedMonth) {
                                                    props.handleShowEnquiryList({
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'partsRequested',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Parts Requested' : 'Parts Requested'), counts.partsRequestedMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.partsRequestedMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-ownerapproved-dash"></i></div>
                                    <div className="dashboard-module-label"> Owner Approved </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.ownerApprovedToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'ownerApproved',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'responseDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Owner Approved', counts.ownerApprovedToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.ownerApprovedToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.ownerApprovedMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'ownerApproved',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'responseDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Owner Approved' : 'Owner Approved'), counts.ownerApprovedMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.ownerApprovedMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                          

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row">

                        <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-ownerdeclined-dash"></i></div>
                                    <div className="dashboard-module-label"> Owner Declined </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.ownerDeclinedToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'ownerDeclined',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'responseDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today Owner Declined', counts.ownerDeclinedToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.ownerDeclinedToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.ownerDeclinedMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'ownerDeclined',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'responseDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Owner Declined' : 'Owner Declined'), counts.ownerDeclinedMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.ownerDeclinedMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-workinprogress-dash"></i></div>
                                    <div className="dashboard-module-label"> Work In Progress </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.workInProgressToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'workInProgress',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'startDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today  Work In Progress', counts.workInProgressToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.workInProgressToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.workInProgressMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'workInProgress',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'startDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD  Work In Progress' : ' Work In Progress'), counts.workInProgressMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.workInProgressMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 dashicon-fix">
                                <div className="dashboard-module-loop dashboard-module-flex">
                                    <div className="dashboard-service-module-icon"><i className="ico icon-service-completedjobs-dash"></i></div>
                                    <div className="dashboard-module-label"> Completed Jobs </div>
                                    <div className="dashboard-module-count-wraper">
                                        <div className=" row">
                                            {
                                                props.applydateRange === false ? (
                                                    <div className="col-lg-6" onClick={() => {
                                                        if (counts.completedJobsToday) {
                                                            props.handleShowEnquiryList({
                                                                status: 'completed',
                                                                startDate: todayStart,
                                                                endDate: todayEnd,
                                                                dateType: 'completeDate',
                                                                pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                            }, 'Today  Completed Jobs', counts.completedJobsToday)
                                                        }
                                                    }}>
                                                        <div className="dashboard-count-title"> Today</div>
                                                        <div className="dashboard-count-value"> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.completedJobsToday}</a></div>
                                                    </div>) : (<></>)
                                            }
                                            <div className={`${props.applydateRange === false ? 'col-lg-6 dashboard-count-border-left' : 'col-lg-12'}`} onClick={() => {
                                                if (counts.completedJobsMonth) {
                                                    props.handleShowEnquiryList({
                                                        status: 'completed',
                                                        startDate: monthStart,
                                                        endDate: monthEnd,
                                                        dateType: 'completeDate',
                                                        pipeline: _.map(props.pipeline, function (e) { return e.value; })
                                                    }, (props.applydateRange === false ? 'MTD Completed Jobs' : 'Completed Jobs'), counts.completedJobsMonth)
                                                }
                                            }}>
                                                {
                                                    props.applydateRange === false ?
                                                        (
                                                            <div className="dashboard-count-title">MTD</div>
                                                        ) : (<></>)
                                                }
                                                <div className={`dashboard-count-value ${props.applydateRange === true ? 'overdue-fix' : ''}`}> <a href="#" className="blue-link" onClick={(e) => { e.preventDefault() }}>{counts.completedJobsMonth}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default PipelineCounts;