/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
/** VIEW-MODELS */


const Info = ({ log, handleEdit, handleRemove, deleteLogAccess, canModify, groupUsers, clientUsers }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const _origin = log.modifiedFrom ? log.modifiedFrom : log.addedFrom;
    return (<div className="enquiry-flow-item" id={'wLog_' + log.documentID}>
        <div className="flow-item ">
            <div className="flow-item-content">
                <div className="flow-text-wrap">
                    <div className="flow-text text-area-space ">
                        {log.active ? <div className="icon-timer-workinprogress"><strong>{'Working...'}</strong></div> : <strong>{CommonHelper.showDuration(log.timeSpent)}
                            {log.mannual ? <span className="tech-worklog-manual">( <Translate text={'Manually logged'}/> )</span> : <></>}
                        </strong>}
                        {
                            log.description ? <><div className="flow-activity-notes-inner m-0">{log.description}</div></> : <></>
                        }
                        <div className="flow-activity-date">
                            <span><Translate text={'Started at'}/>: {moment.unix(log.startTime.seconds).format('DD/MM/YYYY hh:mm A')} </span> - {CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, log.addedBy)}
                            {_origin ?
                                <>
                                    {` - `}
                                    <i className={`ico icon-${_origin === 'web' ? 'monitor' : _origin} mr-1`}></i>
                                    {_origin} </> : <></>}
                        </div>
                    </div>


                    {/* <div className="enquiry-flowitem-more"> <a href="#"><i className="ico icon-more"></i></a> </div> */}
                    {
                        canModify
                            ?
                            <>
                                <div className="enquiry-flowitem-more">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setEditshow(!editshow)
                                        if (editshow) document.addEventListener('click', setEditshow(false));
                                    }}><i className="ico icon-more"></i></a>
                                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                        <ul className="more-dropdown">
                                            <li>
                                                <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(log) }}>
                                                    <i className="ico icon-edit"></i>  <Translate text={'edit'} />
                                                </a>
                                            </li>
                                            {
                                                canModify && !log.active && deleteLogAccess
                                                    ?
                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); handleRemove(log.documentID) }}>
                                                        <i className="ico icon-delete"></i>
                                                        <Translate text={'delete'} />
                                                    </a> </li>
                                                    :
                                                    <></>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                    }


                </div>

            </div>
        </div><span className="flow-icon-user">
            <img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, log.addedBy)} className="img-object-fit" width="300" height="300" />
        </span>
    </div>
    )
};

export default Info;
