/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './container';
 

const ReportLayouts = (props) => {



    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('daily-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])





    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default ReportLayouts;