import React, { useRef, useState, useEffect } from "react";
import { Dropdown, Modal, Popover } from "react-bootstrap";
import moment from "moment";
import CommonHelper from '../../services/common';
import _ from 'lodash'
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import { reminderOptions, defaultActivityTypes } from '../../services/enum';
import toast from 'toasted-notes';
import { firestoreDB } from "../../services/helper";

const ReminderPopUp = (props) => {
    const [activity, setActivity] = useState(props.activity)
    const node = useRef();
    const [showPop, setShowPop] = useState(false);

    const activityTypes = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.activityTypes ? props.dealersettings.client.settings.activityTypes : defaultActivityTypes;

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setShowPop(false);
    };

    const handleUpdate = (_reminder) => {
        firestoreDB(props.dealersettings)
            .firestore()
            .doc(`activities/${activity.documentID}`)
            .set({
                reminder: _reminder,
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now()
            }, { merge: true });
        toast.notify('Activity updated successfully', {
            duration: 2000,
        });
        props.handleClose();
    }

    return (activity ?
        <Modal
            id="reminder-pop-alert"
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-reminder-pop-alert">
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="reminder-popalert-head">
                            <div className="text-center">
                                <div className="reminder-popalert-contact"> <i className="ico icon-activity-alert"></i> </div>
                            </div>
                            <div className="reminder-popalert-title"><Translate text={'Upcoming Activity'} /></div>
                            <div className="reminder-popalert-info">{activity.title} on {moment.unix(activity.startDate.seconds).format('DD/MM/YYYY h:mm A')}</div>
                        </div>
                        <div className="reminder-popalert-body">

                            <div className="engage-contact-details">
                                <h3> <Translate text={'Activity Details'} /></h3>
                                <table className="table-fill">
                                    <tbody>
                                        {
                                            !_.isEmpty(activity.contact) ? (
                                                <tr>
                                                    <td className="text-left label-text"><Translate text={'Customer Name'} /></td>
                                                    <td className="text-left">{CommonHelper.getFullName([], activity.contact, '')}</td>
                                                </tr>
                                            ) : (<></>)
                                        }
                                        {
                                            activity.enquiry && activity.enquiry.requirement && (!_.isEmpty(activity.enquiry.requirement.make) || !_.isEmpty(activity.enquiry.requirement.stock)) ? (
                                                <tr>
                                                    <td className="text-left label-text"><Translate text={'Vehicle Model'} /></td>
                                                    <td className="text-left">{!_.isEmpty(activity.enquiry.requirement.stock) && !_.isEmpty(activity.enquiry.requirement.stock.make) ? activity.enquiry.requirement.stock.make + ' ' + activity.enquiry.requirement.stock.model : !_.isEmpty(activity.enquiry.requirement.make) ? activity.enquiry.requirement.make + ' ' + activity.enquiry.requirement.model : ''}</td>
                                                </tr>
                                            ) : (<></>)
                                        }
                                        <tr>
                                            <td className="text-left label-text"><Translate text={'type'} /></td>
                                            <td className="text-left">{CommonHelper.getNameByValue(activityTypes, activity.subType)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                !_.isEmpty(activity.notes) ? (
                                    <div className="reminder-popalert-notes">
                                        <h3> <Translate text={'notes'} /></h3>
                                        <div className="reminder-popalert-notewrap text-area-space">{activity.notes}</div>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-default float-left" onClick={() => {
                    props.handleClose();
                }}> <Translate text={'close'} /></button>
                <button type="button" className="btn btn-primary float-right" onClick={() => {
                    props.handleViewActivity(activity);
                }}><Translate text={'view'} /></button>
                {
                    activity.reminder > 1 ? (
                        <Dropdown className="snooze-btn-primary" alignRight>
                            <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                <i className="ico icon-timer"></i><span> <Translate text={'snooze'} /> </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                {
                                    reminderOptions.filter(a => a.value < activity.reminder).map((rec, index) => {
                                        return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleUpdate(rec.value) }}>
                                            {rec.label}
                                        </Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (<></>)
                }
            </Modal.Footer>
        </Modal >
        : <></>)
}

export default ReminderPopUp;