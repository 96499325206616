export const integrationFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true
    },
    {
        name: 'Value',
        value: 'value',
        flex: 2,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
export const integrationsVM = {
    documentID: null,
    name: '',
    value: '',
    type: '',
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    price: {}
}


   