/** LIBRARIES */
import React from 'react'
import moment from 'moment';
import { Draggable } from "react-beautiful-dnd";
import _ from 'lodash';
//import { OverlayTrigger, Tooltip } from 'react-bootstrap'
/** PROVIDERS */
import { StageConsumer } from './provider'
import CommonHelper from '../../../services/common'
/** COMPONENTS */
import { default as _images } from "../../../images";


const OrderCell = React.memo(({ id, isFav, status, stage, groupUsers, index, task, sidepanelOpen, clientUsers }) => {
    //console.log('OrderCell', task.addedby)
    function getStyle(style, snapshot) {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }
    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers

    return (
        <>
            <StageConsumer>
                {({ handleOrderStatus }) => (
                    <Draggable
                        draggableId={task.id}
                        index={index}
                        key={task.id}
                    >
                        {(provided1, snapshot1) => (
                            <>
                                <div

                                    ref={provided1.innerRef}
                                    {...provided1.draggableProps}
                                    {...provided1.dragHandleProps}
                                    style={getStyle(provided1.draggableProps.style, snapshot1)}
                                    className={`pipeline-item-kitchen ${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}`}
                                    id={`orders-${task.id}`}
                                    onClick={(e) => { e.preventDefault(); sidepanelOpen(task); }}
                                >
                                    <div className="pipeline-item-flex" >

                                        <div className="kitchen-item-image" > <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                            <div className="kitchen-item-count">
                                                <span className="badge badge-pill badge-kitchen ">{task.orderQuantity}</span>
                                            </div>
                                            <img src={task.orderImage ? task.orderImage : _images.nouser} width="60" height="60" alt="" className="img-object-fit" /> </a> </div>
                                        <div className="pipeline-item-data">
                                            <div className="kitchen-item-title">#{task.orderNumber}</div>
                                            {task.dealerName ? <div className="dealer-group-title blue-color">{task.dealerName}</div> : <></>}
                                            <div className="kitchen-item-info">{task.addedDate ? moment.unix(task.addedDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</div>
                                            <div className="kitchen-item-userlist">
                                                <div className="userkitchen"> <img src={CommonHelper.getUserImagebyId(allUsers, task.createdby)} width="300" height="300" alt="" className="img-object-fit" />  </div>
                                                {task.addedby ? task.addedby : CommonHelper.getUserNamebyId(allUsers, task.addedBy)}
                                            </div>
                                            <div className="kitchen-item-status">
                                                <div className={`badge badge-pill badge-${task.status.toLowerCase()}-fill`}>{task.status}</div>
                                            </div>
                                            {/* <div className="kitchen-item-status">

                                                <div className={`badge badge-pill badge-${task.status.toLowerCase()}-fill`}>{task.status}</div>
                                                <div className="kitchen-item-user">
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Tooltip>{task.addedby}</Tooltip>
                                                        }
                                                    >
                                                        <div className="pipeline-users"> <a href="#" onClick={(e) => { e.preventDefault(); }}><img src={_images.nouser} width="300" height="300" alt="" /></a> </div>
                                                    </OverlayTrigger>{' '}

                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Draggable>
                )}
            </StageConsumer>


        </>
    )
})

export default OrderCell