import React from 'react'

export const clientsVM = {
    documentID: null,
    category: '',
    clientBrandIDs: [],
    address: '',
    state: '',
    country: '',
    location: '',
    name: '',
    region: '',
    group: '',
    settingsID: null,
    financeTC: '',
    fleetTC: '',
    insuranceTC: '',
    testdriveTC: '',
    customerConsent: '',
    timezone: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isActive: true,
    logoURL: '',
    workingHours: '',
    email: '',
    phone: '',
    website: '',
    fax: '',
    weekStart: '',
    latitude: '',
    longitude: '',
    requirementType: null,
    sortOrder: null,
    paymentDue: null,
    paymentDueDate: null,
    clientSettings: null,
    excludeInOEMReport: null,
    sectionInOEMReport: null,
    sectionHeader: null,
    reportSaleType: [],
    projectId: null,
    subregion: null,
    status: null
}

export const groupVM = {
    documentID: null,
    client_ids: [],
    name: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null
}

export const regionVM = {
    documentID: null,
    name: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null
}

export const mandatoryFields = [
    'name',
    'region',
    'subregion'
];


export const clientlistFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true
    },
    {
        name: 'Region',
        value: 'regionName',
        flex: 1,
        default: true
    },
    {
        name: 'Address',
        value: 'address',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const reqTypes = [
    { value: 'automotive', label: 'Automotive' },
    { value: 'automotiveServices', label: 'Automotive Services' }
]

export const timezone = [
    { value: 'Asia/Dubai', label: '(UTC+04:00) Abu Dhabi, Muscat' },
    { value: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi' },
    { value: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { value: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney' }
]

export const weekDays = [
    { value: 'sunday', label: 'Sunday' },
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
]

export const states = [
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
]

export const countries = [
    { value: 'AU', label: 'Australia' },
    { value: 'IND', label: 'India' },
    { value: 'PKN', label: 'Pakistan' }
]

export const reportScheduler = [
    // { label: 'Daily Activity Report (Generic)',name: 'Daily Activity Report (Generic)', fileType: 'pdf', value: 'genericActivityReport', url: '/dailyactivity/report/' },
    // { label: 'Daily Activity Report (Union)',name: 'Daily Activity Report (Union)', fileType: 'pdf', value: 'unionActivityReport', url: '/dailyactivity/union/' },
    // { label: 'Daily Activity Report (Adamas)',name: 'Daily Activity Report (Adamas)', fileType: 'pdf', value: 'adamasActivityReport', url: '/dailyactivity/adamas/' },
    // { label: 'Daily Activity Report (BMW)',name: 'Daily Activity Report (BMW)', fileType: 'pdf', value: 'bmwActivityReport', url: '/dailyactivity/bmw/' },
    { label: 'Leads vs Sales Analysis Report', name: 'Leads vs Sales Analysis Report', fileType: 'pdf', value: 'leadSalesAnalysis', url: '/summary/report/leadsalesanalysis/' },
    { label: 'Lead Source Report', name: 'Lead Source Report', fileType: 'pdf', value: 'leadSourceReport', url: '/summary/report/leadSource/' },
    { label: 'Weekly Summary Report', name: 'Weekly Summary Report', fileType: 'pdf', value: 'summaryReport', url: '/summary/report/summaryreport/' },
    { label: 'Weekly Summary Report by Users', name: 'Weekly Summary Report by Users', fileType: 'pdf', value: 'summaryReportUses', url: '/summary/report/summaryreportusers/' },
    { label: 'OEM Summary Report', name: 'OEM Summary Report', fileType: 'pdf', value: 'summaryReportOEM', url: '/summary/report/oem/', reportType: 'oem' },
    { label: 'OEM Summary Report - Used', name: 'OEM Summary Report - Used', fileType: 'pdf', value: 'summaryReportOEMUsed', url: '/summary/report/oem/', reportType: 'oem', reportsaleType: 'used' },
    { label: 'Trigger Activity Report', name: 'Trigger Activity Report', fileType: 'pdf', value: 'triggerReport', url: '/triggeractivity/report' },
    //{ label: 'Summary Report by Models', name: 'Summary Report by Models', fileType: 'pdf', value: 'summaryReportModels', url: '/summary/report/byseries/' },
    // { label: 'Sales Funnel Report (Excel)',name: 'Sales Funnel Report', fileType: 'excel', value: 'salesFunnelReport' },
    // { label: 'Daily Activity Report (Excel)',name: 'Daily Activity Excel Report', fileType: 'excel', value: 'dailyActivityExcel' },
    { label: 'McLaren New Enquiries and Sales Activity report', name: 'McLaren New Enquiries and Sales Activity report', fileType: 'excel', customDate: true, value: 'McLarenExcelReport', url: 'McLarenExcelReport' },
    { label: 'McLaren Used Enquiries and Sales Activity report', name: 'McLaren Used Enquiries and Sales Activity report', fileType: 'excel', customDate: true, value: 'McLarenUsedExcelReport', url: 'McLarenUsedExcelReport' },
    { label: 'User Management', name: 'User Management', fileType: 'pdf', value: 'userManagement', url: '/dynamic/report/oem/users/', reportType: 'oem' },
    { label: 'Daily Status update report for batch numbers', name: 'Daily Status update report for batch numbers', fileType: 'pdf', value: 'statusUpdateReport', url: '/dynamic/report/statusupdate/' },
    { label: 'Inter Emirates Sales Report', name: 'Inter Emirates Sales Report', fileType: 'excel', value: 'InterEmiratesExcelReport', url: 'InterEmiratesExcelReport', reportType: 'oem', customDate: true, },
    { label: 'Inter Emirates Sales Report', name: 'Inter Emirates Sales Report', fileType: 'excel', value: 'InterEmiratesExcelReport', url: 'InterEmiratesExcelReport', customDate: true },
]

export const reportSaletypes = [
    { label: 'New', value: 'New' },
    { label: 'Used', value: 'Used' },
    { label: 'Empty', value: 'Empty' }
]

export const clientStatus = [
    { active: true, value: 'active', label: <div className={`badge badge-pill status-won`}>Active</div> },
    { active: true, value: 'inactive', label: <div className={`badge badge-pill status-lost`}>InActive</div> },
    { active: true, value: 'demo', label: <div className={`badge badge-pill status-technicianrequested`}>Demo</div> }
]