import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {Accordion,Card} from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2';
import _ from 'lodash'
import CommonHelper from '../../../../services/common';

const ActiveDevices = (props) => {
	const [devices, setDevices] = useState(null);

	useEffect(() => {
		const devicesRef = window.firebase
		.firestore()
		.collection(`users/${localStorage.uid}/devices`)
		.orderBy('loginDate', 'desc');
		
		window.userSnapshot = devicesRef.onSnapshot(userSnap => {
			let data = [];
			if (userSnap.empty) {
				return;
			}

			userSnap.forEach((doc, i) => {
				let docData = {
					...doc.data(),
					documentID : doc.id
				}; 
				docData.modifiedDate = docData.modifiedDate ? docData.modifiedDate.toDate() : '';
				docData.loginDate = docData.loginDate ? docData.loginDate.toDate() : '';
				docData.location = docData.geoLocation && docData.geoLocation.location && docData.geoLocation.city && docData.geoLocation.country_name && docData.geoLocation.region_name
					? `${docData.geoLocation.city} - ${docData.geoLocation.country_name} - ${docData.geoLocation.region_name}`
					: 'No Location Data';

				if (docData.isLogin) {
					console.log(i, ' data', docData)
					data.push(docData);	
				}
			});
			
			let currentDevice = [...data]
			let otherDevice = [...data]
			currentDevice = currentDevice.filter(a => a.deviceID === localStorage.browserKey)
			otherDevice = data.filter(a => a.deviceID !== localStorage.browserKey)

			setDevices(uniqBy([...currentDevice, ...otherDevice], 'deviceID'));
		});

		return () => {
			window.userSnapshot && window.userSnapshot();
		}
	}, []);

	const onLogout = async (e, id) => {
		const result = await Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to switch to log out this device.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		})

		if (!result.value) {
				return
		}
		try {
			const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/devices/${id}`)
			await userRef.set({
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				forceLogout: true,
				isLogin: false,
			}, { merge: true })
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<h2> <Translate text={'Active Devices'}/></h2>
			<div className='divider-line'></div>

			<div className='settings-active-devices mt-3'>
				<p><Translate text={'Below are all the devices that are logged into your AMS Pro account'} />
				
				</p>

				<div className='settings-active-device-list'>
					{devices &&
						devices.map((device,index) => (
							<Accordion key={index} className='quickview-related'>
								<Card className='card position-relative'>
									<Accordion.Toggle
										as={Card.Header}
										eventKey='0'
										className='card-header'
									>
										<div className='mb-0'>
											<div className='device-status'>
												<div className='badge badge-pill badge-active-fill ml-1'>
													 <Translate text={'active'}/>
												</div>
											</div>
											<div className='cardlink'>
												<a className='btn btn-link collapsed'>
													{device.platform.toLowerCase() === 'ios' ? (
														<i className="ico icon-ios fill-icon"></i>
													) : device.platform.toLowerCase() === 'android' ? (
														<i className="ico icon-android fill-icon"></i>
													) : (
														<i className={`ico icon-monitor fill-icon`}></i>
													)}

													<div className='device-info'>
														{/* Chrome, Mac | New South Wales - Australia */}
														{`${device.name}, ${device.platform} | ${device.location}`}
														<span className='device-date'>
															{/* 20 April 2020 09:17 */}
															{device.loginDate.toLocaleString(['en-AU'])}
														</span>
													</div>

													<i className='arrow-collapse'></i>
												</a>
											</div>
										</div>
									</Accordion.Toggle>
									{device.deviceID !== localStorage.browserKey && (
										<div className=" float-right linked-activities-add custom-toggle-btn">
											<button type="button" className="btn btn-primary float-right" onClick={e => onLogout(e, device.deviceID)}>
											<Translate text={'logout'} /> 
											</button>
										</div>
									)}
									<Accordion.Collapse eventKey='0'>
										<Card.Body>
											<div className='device-info-details'>
												<ul>
													<li>
														<strong> <Translate text={'IP address'}/></strong> :{' '}
														{!_.isEmpty(device.geoLocation) && device.geoLocation.ip ? device.geoLocation.ip : 'No IP Address'}
													</li>
													<li>
														<strong> <Translate text={'location'}/></strong> : {device.location}
													</li>
													<li>
														<strong> <Translate text={'Device'}/></strong> :{' '}
														{`${device.name}, ${device.platform}`}
													</li>
													<li>
														<strong> <Translate text={'Last Update'}/></strong> :{' '}
														{`${device.loginDate.toLocaleString([
															'en-AU',
														])}`}
													</li>
												</ul>
											</div>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						))}
				</div>

				{/* <h3 className='mt-3'>Device History</h3>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua.
				</p>

				<div className='common-table'>
					<table className='table table-bordered'>
						<thead>
							<tr>
								<th scope='col' className='head-light' width='30%'>
									Device
								</th>
								<th scope='col' className='head-light' width='30%'>
									Location
								</th>
								<th scope='col' className='head-light' width='20%'>
									Login
								</th>
								<th scope='col' className='head-light' width='20%'>
									Logout
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Chrome, Mac</td>
								<td>New South Wales - Australia</td>
								<td>15 April 2020 09:28</td>
								<td>15 April 2020 21:28</td>
							</tr>
							<tr>
								<td>Firefox, Windows New South</td>
								<td>New South Wales - Australia</td>
								<td>15 April 2020 09:28</td>
								<td>15 April 2020 21:28</td>
							</tr>
							<tr>
								<td>iPhone 11 Pro</td>
								<td>New South Wales - Australia</td>
								<td>15 April 2020 09:28</td>
								<td>15 April 2020 21:28</td>
							</tr>
						</tbody>
					</table>
				</div> */}
			</div>
		</>
	);
};

export default ActiveDevices;
