/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import { Progress } from 'antd';
import _ from 'lodash';
import moment from 'moment';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

import { objVideos, mandatoryVideo } from '../service/viewModel'
import { firestoreDB, storageBucket } from '../../services/helper';

export default class AddVideo extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.ref = firestoreDB(this.props.dealersettings).firestore().collection('tradeinPro');
        this.state = {
            fields: Object.assign({}, objVideos),
            errors: {},
            progress: 0
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'dashed_error';

        if (errors !== {}) {
            if (!fields[key] && mandatoryVideo.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }

    }
    //#endregion
    //#region FILE UPLOAD

    onSelectFile = (e) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(mpeg|mp4|avi|mov|wmv|flv|3gpp)$/i)) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Please select video file'), '', 'info')
                return;
            }

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 100) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 100MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                const fileVM = Object.assign({}, objVideos);
                fileVM.name = file.name;
                fileVM.size = file.size;
                fileVM.type = file.name.substr((file.name.lastIndexOf('.') + 1));
                fileVM.url = reader.result;
                this.setState({ fields: fileVM, progress: 0 }, () => { this.errorChange('url') });


            }
            reader.readAsDataURL(file)
        }
    }

    saveFileToStorage = (imageBase64, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;

        const { collection, recordID, dealersettings } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';

        var storageRef = storageBucket(dealersettings).ref(`${clientID}/${collection}/${recordID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(dealersettings).ref(`${clientID}/${collection}/${recordID}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log(dataURL)
                        return dataURL;
                    })
            })
    };

    handleFileRemove = (e) => {
        e.preventDefault();

        document.getElementById("videoUpload").value = "";
        const fileVM = Object.assign({}, objVideos);
        this.setState({ fields: fileVM })
    }

    showFileIcon = (type) => {
        if (type) {
            switch (type.toLowerCase()) {
                case 'pdf':
                    type = <i className="ico icon-files-pdf"></i>;
                    break;
                case 'docx':
                case 'doc':
                    type = <i className="ico icon-files-word"></i>;
                    break;
                case 'xls':
                case 'xlsx':
                    type = <i className="ico icon-files-excel"></i>;
                    break;
                case 'zip':
                case '7z':
                case 'rar':
                    type = <i className="ico icon-files-zip"></i>;
                    break;
                case 'mp3':
                case 'ogg':
                case 'wav':
                    type = <i className="ico icon-files-audio"></i>;
                    break;
                case 'mp4':
                case 'avi':
                case 'wmv':
                case 'mpeg':
                case 'mov':
                    type = <i className="ico icon-files-video"></i>;
                    break;
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'webp':
                    type = <i className="ico icon-files-image"></i>;
                    break;
                default:
                    type = <i className="ico icon-files-document"></i>;
            }

            return type;
        }
        else {
            return <i className="ico icon-files-document"></i>
        }


    }

    //#endregion


    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'dashed_error';

        for (let [key] of Object.entries(fields)) {
            if (mandatoryVideo.indexOf(key) >= 0 && !fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.addFile(this);
        }
        else {

            return formIsValid;
        }

    };

    addFile = (e) => {
        this.isLoading = true;
        const testdrives = [];
        const objData = Object.assign({}, this.state.fields);

        let uid = moment()._d.getTime().toString() + '.' + objData.type;
        const { collection, dealersettings, recordID } = this.props;
        let clientID = dealersettings ? dealersettings.client.id : '';
        let logNotes = 'Video added';
        //console.log(serviceID, clientID)

        if (recordID && clientID) {
            var storageRef = storageBucket(dealersettings).ref(`${clientID}/${collection}/${recordID}/${uid}`).putString(objData.url, 'data_url');
            storageRef.on('state_changed', (snapshot) => {
                //console.log(snapshot);
                var percentage = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);

                if (percentage > 0 && percentage < 99) {
                    this.setState({ progress: percentage })
                }
            },
                (error) => {
                    console.error(error);
                },
                () => {
                    testdrives.push(storageBucket(dealersettings).ref(`${clientID}/${collection}/${recordID}`).child(uid).getDownloadURL()
                        .then(dataURL => {
                            //console.log(dataURL)
                            this.setState({ progress: 100 })
                            if (_.isEmpty(objData.addedBy)) {
                                objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                                objData.addedDate = window.firebase.firestore.Timestamp.now();
                                objData.addedFrom = 'web';
                            }
                            objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
                            objData.modifiedFrom = 'web';

                            objData.url = dataURL

                            if (objData.name)
                                logNotes = 'Video: ' + objData.name + ' added';

                            if (!objData.clientID)
                                objData.clientID = clientID;

                            if (!objData.recordID)
                                objData.recordID = recordID

                            if (!objData.documentID)
                                objData.documentID = this.ref.doc().id;

                            //Remove empty value from object
                            for (var propName in objData) {
                                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                                    delete objData[propName];
                                }
                            }

                            //console.log('objData', objData)

                            const updateRef = firestoreDB(dealersettings).firestore().collection(`${collection}/${recordID}/videos`).doc(objData.documentID);
                            updateRef.set(objData, { merge: true }).then((docRef) => {
                                //console.log(docRef);
                                this.isLoading = false;
                                toast.notify('Video saved successfully', {
                                    duration: 2000
                                })
                                //CommonHelper.saveChangeLog(clientID, serviceID, recordID, logNotes, 'enquiry', 'videos', objData.documentID);
                                this.props.handleClose(objData.documentID);

                            }).catch((error) => {
                                console.error("Error updating video: ", error);
                            });


                        })
                    )
                })
        }
    };

    //#endregion

    render() {
        const { show } = this.props;
        const { progress } = this.state;
        const { name, url, type, size } = this.state.fields;
        //console.log(this.state.fields)
        return (
            <>
                <Modal show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    enforceFocus={false}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-file-manager"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> <Translate text={'Upload Video'} /></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">

                                <div className="upload-file-box">
                                    <div className={`upload-box ${this.state.errors["url"]}`}>
                                        <label className="upload-iconwrap" htmlFor="videoUpload" style={{ margin: 0, cursor: 'pointer' }}>
                                            <i className="ico icon-files-upload"></i>
                                            <span className="upload-text"><Translate text={'Upload Video'} /></span>
                                            <input
                                                type="file"
                                                id="videoUpload"
                                                style={{ display: 'none' }}
                                                onChange={(e) => this.onSelectFile(e)} />
                                        </label>
                                    </div>

                                    {
                                        url
                                            ?
                                            <div className="uploaded-list-video">
                                                <div className="flex-upload">
                                                    <div className="upload-clear">

                                                        <a href="#" onClick={(e) => this.handleFileRemove(e)} className={`${this.isLoading ? 'form-disable' : ''}`}>
                                                            <i className="fa fa-times" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                    <div className="file-upload-icon">
                                                        {
                                                            this.showFileIcon(type)
                                                        }
                                                    </div>
                                                    <div className="upload-data">
                                                        {name}
                                                        {' (' + CommonHelper.showFileSize(size) + ')'}
                                                    </div>
                                                </div>
                                                {this.isLoading ? <Progress percent={progress} /> : <></>}
                                            </div>
                                            :
                                            <></>
                                    }


                                </div>


                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}
                        ><Translate text={'cancel'} />
                        </button>

                        <button
                            type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>





            </>


        );
    }
}