import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Nav } from 'react-bootstrap'
import ModuleAddOns from "./addOns";
import Translate from '../../../constants/translate';
import { TemplateContext } from './templateContext'

const ModuleSettings = (props) => {
    const [clientGroups, setClientGroups] = useState([]);
    const [oemClients, setOEMClients] = useState([]);
    const [clientRegions, setClientRegions] = useState([])
    const [clientSettings, setClientSettings] = useState([])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        const clientsSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/clients`)
            .onSnapshot(querySnapshot => {
                let _client = [];
                querySnapshot.forEach((rec) => {
                    _client.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientSettings(_client)
            })
        return () => {
            clientsSnapshot && clientsSnapshot();
        }
    }, [])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', (localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID))
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])

    const groupsMemo = useMemo(() => clientGroups, [clientGroups])
    const clientsMemo = useMemo(() => oemClients, [oemClients])
    const regionsMemo = useMemo(() => clientRegions, [clientRegions])
    const clientSettingsMemo = useMemo(() => clientSettings, [clientSettings])

    return (<>
        <Tab.Container defaultActiveKey="addons" mountOnEnter={true}>
            <div className="settings-submenu">
                <h4><Translate text={'Module Settings'}/></h4>
                <Nav as="ul" bsPrefix="ul-nav">
                    <Nav.Item as="li" bsPrefix="li-nav">
                        <Nav.Link eventKey="addons" bsPrefix="li-a-nav"><Translate text={'Add-ons'}/></Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <TemplateContext.Provider value={{  groups: groupsMemo, clients: clientsMemo, regions: regionsMemo, clientSettings: clientSettingsMemo,  }}>
            <div className="settings-container mb-0 h-100">
                <div className="settings-container-sub" >
                    <Tab.Pane eventKey="addons">
                        <ModuleAddOns {...props}/>
                    </Tab.Pane>
                </div>
            </div>
            </TemplateContext.Provider>

        </Tab.Container>

    </>);
}

export default ModuleSettings;