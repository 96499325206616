import _ from 'lodash'

const StockHistoryReducer = (state, action) => {

    function updateAllFleet() {
        return state[action.activetab].map((item, index) => {
            var logVM = action.data.find(e => e.documentID === item.documentID)
            if (logVM) {
                for (let [key, value] of Object.entries(logVM)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function updateFleet() {
        return state[action.activetab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }

    switch (action.type) {
        case "SUCCESS_LIST": {
            return {
                ...state,
                [action.activetab]: action.data
            };
        }
        case "APPEND_LIST": {
            return {
                ...state,
                [action.activetab]: [...updateAllFleet(), ..._.differenceBy(action.data, state[action.activetab], 'documentID')]
            };
        }
        case "ADDNEW_LIST": {
            return {
                ...state,
                [action.activetab]: [action.data, ...state[action.activetab]]
            };
        }
        case "REMOVE_LIST": {
            return {
                ...state,
                [action.activetab]: state[action.activetab].filter(item => item.documentID !== action.data.documentID)
            };
        }
        case "SUCCESS": {
            return action.data;
        }
        case "UPDATE_LIST": {
            return {
                ...state,
                [action.activetab]: updateFleet()
            };
        }
        default:
            return state;
    }
};


export default StockHistoryReducer