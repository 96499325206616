import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from './pipelineCounts'
import { PopUpModal } from '../../../components'
import EnquiryList from '../enquiryList'
import ActivityList from '../activitiesList'
import { initialLayoutArr, defaultWidgetSettings } from './viewModel';
import CommonHelper from '../../../services/common';
import DragDropLayout from './dragDropLayout';
import { default as _images } from '../../../images';
import Swal from 'sweetalert2';

const defaulModelState = {
    enquiry: false,
    activity: false,
    testdrive: false,
    tradein: false
}

const AfterSalesDashboard = (props) => {
    const [loader, setLoader] = useState(false)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedPipeline, setPipeline] = useState();
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard ? { value: props.dealersettings.id } : null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [applydateRange, setApplyDateRange] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [refreshActivity, setRefreshActivity] = useState(false)
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState(true)
    const [widgetSettingsLoader, setWidgetSettingsLoader] = useState(true)
    const [sortedWidgets, setSortedWidgets] = useState([])
    const [hiddenWidgets, setHiddenWidgets] = useState([])
    const [showWidgetSettings, setShowWidgetSettings] = useState(false);

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/afterSalesDashboardSettings/${localStorage.uid}`);
    useEffect(() => {
        if (ownerDashboard)
            setUser({ value: props.dealersettings.id })
    }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {
        const widgetSettingsSnapShot = window.firebase.firestore().doc(`users/${localStorage.uid}/afterSalesDashboardSettings/${localStorage.uid}`)
            .onSnapshot(querySnapshot => {
                //console.log('querySnapshot', querySnapshot)
                if (querySnapshot.exists) {
                    const widgetsData = querySnapshot.data()
                    let hiddenWidgets = widgetsData.hiddenWidgets ? widgetsData.hiddenWidgets : [];
                    setSortedWidgets(widgetsData.sortedWidgets ? widgetsData.sortedWidgets : defaultWidgetSettings)
                    setHiddenWidgets(hiddenWidgets)
                    setWidgetSettingsLoader(false)
                } else {
                    setSortedWidgets(defaultWidgetSettings)
                    setHiddenWidgets([])
                    setWidgetSettingsLoader(false)
                }
            })
        return () => {
            widgetSettingsSnapShot && widgetSettingsSnapShot();
        }
    }, [])


    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (data && !_.isEmpty(data.dashboardLayout)) {
                setLayout(data.dashboardLayout)
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleShowEnquiryList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Enquiry List')
        setModelShow({
            ...defaulModelState,
            enquiry: true,
            counts
        })
    }

    const handleShowActivityList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Activities List')
        setModelShow({
            ...defaulModelState,
            activity: true,
            counts
        })
    }


    const onLayoutChange = async (_layout, sortedWidgets, hiddenWidgets) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.filter(a => a.i !== 'null').map((rec, index) => {
            return sortedWidgets.includes(rec.i) && sortedWidgets.length === 1 ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                x: 0,
                y: 0
            } : hiddenWidgets.includes(rec.i) ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                w: 0,
                h: 0
            } : {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
                minW: 4,
                h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? (rec.i === 'LeadsBucket' ? 4 : (rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
                w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({
                dashboardLayout: _dLayout,
            }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const reloadData = () => {
        // console.log('isDone true, refresh data start')
        setTimeout(() => {
            setRefreshData(!refreshData)
        }, 1000);
    }

    const reloadActivity = () => {
        // console.log('isDone true, refresh data start')
        setTimeout(() => {
            setRefreshActivity(!refreshActivity)
        }, 1000);
    }
    const handleCloseWidgetSettings = () => {
        setShowWidgetSettings(false)
    }

    const handleOpenWidgetSettings = e => {
        e.preventDefault()
        setShowWidgetSettings(true)
    }

    const removeWidget = async (val) => {
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to hide this widget.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
            allowOutsideClick: false,
                    allowEscapeKey: false
        })

        if (!result.value) {
            return
        }
        const localHiddenWidgets = [val, ...hiddenWidgets];
        const localSortedWidgets = sortedWidgets.filter(a => a !== val);
        const dashboardSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/afterSalesDashboardSettings/${localStorage.uid}`);
        await dashboardSettingsRef.set({
            hiddenWidgets: localHiddenWidgets,
            sortedWidgets: localSortedWidgets,
        }, { merge: true })
        onLayoutChange(layout, localSortedWidgets, localHiddenWidgets);
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        ownerDashboard={ownerDashboard}
                        handleShowEnquiryList={handleShowEnquiryList}
                        handleShowActivityList={handleShowActivityList}
                        pipelineEnquiries={[]}
                        testdrives={[]}
                        tradeins={[]}
                        selectedPipeline={selectedPipeline}
                        selectedUser={selectedUser}
                        dateRange={dateRange}
                        applydateRange={applydateRange}
                        setPipeline={(e) => {
                            setPipeline(e)
                        }}
                        setUser={(e) => {
                            setUser(e)
                        }}
                        handleDateRangeChange={(val) => {
                            if (val) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                        refreshData={refreshData}
                        sortedWidgets={sortedWidgets}
                        hiddenWidgets={hiddenWidgets}
                        handleCloseWidgetSettings={handleCloseWidgetSettings}
                        handleOpenWidgetSettings={handleOpenWidgetSettings}
                        showWidgetSettings={showWidgetSettings}
                        setShowWidgetSettings={setShowWidgetSettings}
                        onLayoutChange={onLayoutChange}
                        layout={layout}
                        setLayout={setLayout}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {(layoutLoader || widgetSettingsLoader) ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center text-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border text-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : sortedWidgets.length !== 0 ? (
                            <DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                handleShowEnquiryList={handleShowEnquiryList}
                                handleShowActivityList={handleShowActivityList}
                                selectedPipeline={selectedPipeline}
                                selectedUser={selectedUser}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                initialLayout={layout}
                                ownerDashboard={ownerDashboard}
                                reloadData={reloadData}
                                refreshData={refreshData}
                                reloadActivity={reloadActivity}
                                refreshActivity={refreshActivity}
                                sortedWidgets={sortedWidgets}
                                hiddenWidgets={hiddenWidgets}
                                removeWidget={removeWidget}
                                pipelineEnquiries={[]}
                                testdrives={[]}
                                tradeins={[]}
                            />
                        ) : (
                            <div className="common-table pt-5">
                                <div className="text-center p-5">
                                    <div className='no-data-img mb-3'>
                                        {' '}
                                        <img src={_images.nodata} width='60' height='60' alt='' />
                                    </div>
                                    <p>No Visible Widgets.</p>
                                    <button className="btn btn-sm btn-default" onClick={handleOpenWidgetSettings}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        Add a widget
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    reloadData={reloadData}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    reloadData={reloadActivity}
                ></ActivityList>
            </PopUpModal>
        </div>
    )
}

export default AfterSalesDashboard