export const notificationVM = {
    addedDate: null,
    body: '',
    clientID: null,
    data: '',
    documentID: null,
    contactID: null,
    enquiryID: null,
    serviceID: null,
    tradeinProID: null,
    fromUserID: null,
    isDeleted: false,
    isRead: false,
    recordID: null,
    title: '',
    toUserID: null,
    type: '',
    subType: ''
};