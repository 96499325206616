/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Placeholder } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

function StageLoader(props) {
    const [panelWidth, setPanelWidth] = useState('100%');
    useEffect(() => {
        document.documentElement.style.setProperty('--panelHeight', (window.innerHeight - 123) + 'px');
        document.documentElement.style.setProperty('--boardheight', (window.innerHeight - 123) + 'px');
        if (props.columns.length > 6 && window.outerWidth > 1500) {
            document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 6) - 15) + 'px')
            setPanelWidth((((((window.innerWidth - 30) / 6)) * props.columns.length) + 80) + 'px')
        }
        else if (window.outerWidth < 1500 && props.columns.length > 5) {
            if (window.outerWidth < 1100) {
                document.documentElement.style.setProperty('--boardwidth', '250px')
                setPanelWidth((270 * props.columns.length) + 'px')
            }
            else{
                document.documentElement.style.setProperty('--boardwidth', (((window.innerWidth - 30) / 5) - 15) + 'px')
                setPanelWidth((((((window.innerWidth - 30) / 5)) * props.columns.length) + 80) + 'px')
            } 
        }
        else if (window.outerWidth < 1100) {
            document.documentElement.style.setProperty('--boardwidth', '250px')
            setPanelWidth((270 * props.columns.length) + 'px')
        }
        else {
            document.documentElement.style.setProperty('--boardwidth', ((100 / props.columns.length) - 1.5).toFixed(2) + '%')
            setPanelWidth('100%')
        }
    }, [])
    return (
        <div className="pipeline-panel">
            {
                <div style={{ width: `${panelWidth}` }}>
                    {props.columns.length > 0 ? props.columns.map((stage, index) => {
                        return <div key={index} className="pipeline-board">
                            <div className="pipeline-board-header">
                                <h2>{(_.isArray(props.stages) && _.find(props.stages, { 'value': stage })) ? _.find(props.stages, { 'value': stage }).name : stage}</h2>
                            </div>
                            <div className="pipeline-item-wraper">
                                <div className="pipeline-item">
                                    <Placeholder>
                                        <Placeholder.Header>
                                            <Placeholder.Line />
                                            <Placeholder.Line />
                                        </Placeholder.Header>
                                        <Placeholder.Paragraph>
                                            <Placeholder.Line length='medium' />
                                            <Placeholder.Line length='short' />
                                        </Placeholder.Paragraph>
                                    </Placeholder>
                                </div>
                            </div>
                        </div>

                    }) : (<></>)
                    }
                </div>
            }
        </div>
    )
}

export default StageLoader;