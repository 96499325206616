import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect } from '../../../../components';
import Translate from '../../../../constants/translate';

const options = [
  { value: '1', label: 'Last 1 Month' },
  { value: '2', label: 'Last 2 Months' },
  { value: '3', label: 'Last 3 Months' },
];

const EmailSyncTimeModal = ({ title, show, handleClose, dealersettings }) => {
  const [state, setState] = useState({
    month: '1',
    error: false
  })

  const handleSelectChange = (e) => {
    if (e) {
      setState({ ...state, month: e.value })
    } else {
      setState({ ...state, month: '' })
    }
  }

  return (
    <Modal
      id='email-sync-model'
      show={show}
      onHide={handleClose}
      backdrop='static'
      backdropClassName={show ? 'overlay-modal active' : ''}
      enforceFocus={false}
      dialogClassName='modal-dialog modal-dialog-centered modal-update-password'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>
            <Translate text={title} />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="form-style w-100">
              <div className="form-row ">
                <div className="form-group col-md-12">
                  <label><Translate text={'Sync emails from'} /></label>
                  <ReactSelect
                    options={options}
                    name={"month"}
                    placeholder={'Select month'}
                    onChange={handleSelectChange}
                    value={state.month ? state.month : ''}
                    classNamePrefix={`${state.error ? 'input_error' : ''} basic-select`}
                  >
                  </ReactSelect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='modal-footer'>
        <button
          type='button'
          className='btn btn-primary float-right ml-2 '
          aria-label='Close'
          // disabled={}
          onClick={() => { handleClose(state.month) }
          }
        >
          {' '}
          <Translate text={'Continue'} />
        </button>
        <button
          type='button'
          className='btn btn-default float-right'
          onClick={() => handleClose(null)}
        >
          {/* {
								loader ?
									(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<></>)
							} */}
          <Translate text={'cancel'} />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailSyncTimeModal;
