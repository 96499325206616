import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { salesTabs, NoDataDiv, LoadingDiv } from '../../layout/searchPanel/constants';
import CommonHelper from '../../../services/common';
import NumberFormat from 'react-number-format';
import { objPartVM } from '../viewModel'

const SearchPanel = ({
    showSearchPanel,
    searchText,
    dealersettings,
    handleClose,
    history,
    service
}) => {

    const [serviceParts, setserviceParts] = useState([]);
    const [loader, setLoader] = useState([]);

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        if (searchText && searchText.length > 0) {
            setLoader(true);


            let refPartsDetails = window.firebase
                .firestore()
                .collection('serviceParts')
                .where('keywords', 'array-contains', searchText.toLowerCase().trim())
                //.where('clientIDs', 'array-contains', dealersettings.client.id)
                .where('active', '==', true)
                .where('isDeleted', '==', false)
                .limit(15);

            let refOEMPartsDetails = window.firebase
                .firestore()
                .collection('serviceParts')
                .where('keywords', 'array-contains', searchText.toLowerCase().trim())
                .where('oemID', '==', dealersettings.oemID ? dealersettings.oemID : dealersettings.client.settingsID)
                .where('active', '==', true)
                .where('isDeleted', '==', false)
                .limit(15);

            var partsSearchPanel = combineLatest(
                collection(refPartsDetails),
                collection(refOEMPartsDetails)
            )
                .pipe(
                    map(([serviceParts, serviceOEMParts]) => {
                        return [
                            _.map(serviceParts, function (obj) {
                                return {
                                    ...obj.data(),
                                    documentID: obj.id,
                                    renderType: 'serviceParts'
                                };
                            }),
                            _.map(serviceOEMParts, function (obj) {
                                return {
                                    ...obj.data(),
                                    documentID: obj.id,
                                    renderType: 'serviceParts'
                                };
                            }),
                        ];
                    }),
                )
                .subscribe(async ([parts, oemParts]) => {
                    //console.log('setserviceParts', dealersettings.oemID, parts, oemParts);

                    if (parts && dealersettings.client.id) {
                        parts = parts.filter(a => (a.clientIDs && a.clientIDs.some(b => b === dealersettings.client.id)))
                    }
                    let _allParts = oemParts.concat(parts);
                    let _parts = [];
                    _allParts && _allParts.forEach((val) => {
                        let _part = Object.assign({}, objPartVM)
                        for (let [key, value] of Object.entries(val)) {
                            _part[key] = value;
                        }
                        _parts.push(_part)
                    });
                    let _allList = getvehicleParts(_parts, service?.vehicle?.make, service?.vehicle?.model)
                    //console.log('_allList, ', _allList)
                    setserviceParts(_.uniqBy(_allList, 'documentID'));
                    setLoader(false);
                    partsSearchPanel && partsSearchPanel.unsubscribe();
                });
        }
        return () => {
            partsSearchPanel && partsSearchPanel.unsubscribe();
        };
    }, [searchText, dealersettings.client.id]);

    const getvehicleParts = (_list, _make, _model) => {
        let _all = _list.filter(e => !e.make && !e.model)
        let _makes = _list.filter(e => e.make === _make && !e.model)
        let _makeModels = _list.filter(e => e.make === _make && e.model === _model)
        return _.uniq([..._all, ..._makes, ..._makeModels])
    }

    return (
        <div
            className='dropdown-menu dropdown-menu-left'
            aria-labelledby='dropdownMenuButton'
            style={{ display: showSearchPanel ? 'block' : '' }}
        >
            <div className='form-style globalsearch-wrap'>
                <div className='globalsearch-body'>
                    {loader ? (
                        <LoadingDiv />
                    ) : (
                        <div className='global-item-wraper'>
                            {serviceParts.length > 0 ? (
                                <>
                                    {serviceParts.map(part => (
                                        <div
                                            className='global-item d-flex'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClose(part);
                                            }}
                                            key={part.documentID}
                                        >
                                            <div className="w-100 d-inline-block">
                                                {
                                                    part.partName ?
                                                        <span className="global-header"><i className="ico icon-service-settings mr-2"></i><span dangerouslySetInnerHTML={{ __html: CommonHelper.showHighlightSearchTxt(part.partName, searchText) }} /> </span>

                                                        : <></>
                                                }
                                                {
                                                    part.partNumber ?
                                                        <span className="global-info">
                                                            <span dangerouslySetInnerHTML={{ __html: CommonHelper.showHighlightSearchTxt(part.partNumber, searchText) }} />
                                                            {
                                                                part.partCost ?
                                                                    <div className="parts-list-cost"><strong><NumberFormat value={part.partCost} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} /></strong></div>
                                                                    : <></>
                                                            }
                                                        </span>
                                                        : <></>
                                                }


                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <NoDataDiv />
                            )}


                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default SearchPanel;
