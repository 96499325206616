import React from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import ReactHtmlParser, {
    convertNodeToElement,
    processNodes
} from "react-html-parser";
import Translate from '../../../../constants/translate';

function transform(node, index) {
    // return null to block certain elements
    // don't allow <span> elements
    if (node.type === "tag" && node.name === "span") {
        return null;
    }

    // Transform <ul> into <ol>
    // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
    if (node.type === "tag" && node.name === "ul") {
        node.name = "ol";
        return convertNodeToElement(node, index, transform);
    }

    // return an <i> element for every <b>
    // a key must be included for all elements
    if (node.type === "tag" && node.name === "b") {
        return <i key={index}>{processNodes(node.children, transform)}</i>;
    }

    // all links must open in a new window
    if (node.type === "tag" && node.name === "a") {
        node.attribs.target = "_blank";
        // console.log(node);
        // console.log(index);
        return convertNodeToElement(node, index, transform);
    }

    if (node.type === "tag" && node.name === "button") {
        return (
            <button variant="contained" color="primary" key={index}>
                {processNodes(node.children, transform)}
            </button>
        );
    }
}

const TemplatePreview = (props) => {



    return _.isEmpty(props.template) ? (<></>) : (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-template-preview">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">
                    {/* <div className="template-preview-panel form-disable">
                        {ReactHtmlParser(props.template.bodyHTML, {
                            decodeEntities: true,
                            transform
                        })}
                    </div> */}
                    <div
                        className="template-preview-panel form-disable"
                        dangerouslySetInnerHTML={{ __html: props.template.bodyHTML }}>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>);
}

export default TemplatePreview