
/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import toast from 'toasted-notes'
import { Dropdown } from 'react-bootstrap'
/** COMPONENTS */
import { CustomToggle, CustomMenu } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import Filters from './filters'

import { AntDateRangePicker } from '../../components'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const sortOrders = [
    { active: true, value: 'modifiedDate', label: 'Updated On' },
    { active: true, value: 'addedDate', label: 'Created On' }
]

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [tradeInFilter, setTradeInFilter] = useState({})

    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])


    useEffect(() => {
        if (!_.isEmpty(localStorage.tradeInFilter) && Object.keys(JSON.parse(localStorage.tradeInFilter)).length > 0) {
            let _tradeInFilter = JSON.parse(localStorage.tradeInFilter);
            let _testDriveValue = _tradeInFilter.value;

            if (!_.isEmpty(_testDriveValue) && _testDriveValue.hasOwnProperty('keywords'))
                setSearchKeywords(_testDriveValue['keywords']);
            else
                setSearchKeywords('');

            setTradeInFilter(_tradeInFilter);
        }
        else {
            setTradeInFilter({});
            setSearchKeywords('');
        }

    }, [localStorage.tradeInFilter])

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.exportData) ? true : false);

    return (
        <>
            <div className="filter-panel">
                <div className="float-left fleet-head">
                    <h3></h3>
                    <h3><Translate text={'tradeinhistory'} /></h3>
                </div>
            </div>

            <div className="filter-sub-panel">
                <div className="float-left">

                    <div className="filter-search">
                        <div className="input-group ">
                            <input
                                className="form-control"
                                type="text"
                                id="isearch"
                                placeholder="search..."
                                value={searchText ? searchText : ''}
                                onChange={(e) => {
                                    let _val = e.target.value
                                    setSearchText(_val);
                                    localStorage.tradeHistorySearchText = _val;

                                    if (_val && _val.length > 2) {
                                        props.handlesearchText(_val)
                                    }

                                    if (!_val)
                                        props.handlesearchText('')
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        localStorage.tradeHistorySearchText = searchText;
                                        props.handlesearchText(searchText)
                                    }
                                }}
                                autoComplete="off"
                            />
                            <div className="input-group-append input-search-clear">
                                {
                                    searchText && searchText.length > 0
                                        ?
                                        <a href="#"
                                            className="input-search-clear-icon"
                                            title="Clear"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSearchText('');
                                                localStorage.tradeHistorySearchText = '';
                                                props.handlesearchText('')
                                            }}
                                        >
                                            <i className="ico icon-remove"></i>
                                        </a>
                                        :
                                        <>
                                        </>
                                }


                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={(e) => {
                                        localStorage.tradeHistorySearchText = searchText;
                                        props.handlesearchText(searchText)
                                    }}>
                                    <i className="ico icon-search-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="form-dashboard form-row ml-2">
                        <div className="form-group col-md-4 daterange-fix">
                            <AntDateRangePicker
                                value={props.dateRange ? [moment.unix(props.dateRange.startDate.seconds), moment.unix(props.dateRange.endDate.seconds)] : null}
                                id={"history-date-range"}
                                name={'date'}
                                format='DD/MM/YYYY'
                                onChange={(e) => { props.handleDateRangeChange(e, 'date') }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        props.handleSetDates([start, end]);
                                    }
                                    else {
                                        props.handleSetDates([])
                                    }
                                }}
                            //disabledDate={disabledDate}
                            />
                        </div>
                    </div> */}

                </div>
                <div className="float-right">

                    {
                        (exportData)
                            ?
                            <>
                                <div className="filter-item-btn ml-2">
                                    <a href="#" onClick={(e) => {
                                        //e.preventDefault();
                                        props.excelDownload(e);
                                    }} title={'Excel Export'}>
                                        <i className="ico icon-excel-download"></i>
                                    </a>
                                    <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`tradeIn_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                        <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                    </ExcelFile>
                                    {/* <CSVLink
                                        ref={props.csvBtn}
                                        uFEFF={false}
                                        data={props.csvData}
                                        headers={props.csvHeader}
                                        filename={`testDrive_${moment().format('DD_MM_YYYY_HH_mm_ss')}.csv`}
                                        style={{ display: 'none' }}>
                                    </CSVLink> */}
                                </div>

                            </>
                            :
                            <>
                            </>
                    }


                </div>
                {/**** FILTERS ****/}
                <Filters
                    searchKeywords={searchKeywords}
                    tradeInFilter={tradeInFilter}
                    customFilters={props.customFilters}
                    dealersettings={props.dealersettings}
                    handleApplyFilter={props.handleApplyFilter}
                    handleClearFilter={props.handleClearFilter}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                >
                </Filters>


            </div>
        </>
    )
}


export default FilterPanel
