/** LIBRARIES */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
//const VideoDetails = lazy(() => import('../details'));
import TradeInDetails from '../details';
import TradeInImages from '../details/images';
import TradeInEval from '../../tradeinplus/evaluation'
import TradeInPreview from '../../tradeinplus/preview/index'
import TradeInPreviewInternal from '../../tradeinplus/preview/internal'
import TradeInPreviewIntake from '../../tradeinplus/preview/intake'


class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        let _isPRoEval = window.location.pathname.indexOf('tradeinpro/eval') >= 0 ? true : false;
        return (
            <main role="main" className={_isPRoEval ? `container customer-rec-bg` : `container-fluid  pl-0 pr-0`}>

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/tradein/details/:id" exact render={(match) => <TradeInDetails tradeinid={match.match.params.id} />} />
                        <Route path="/tradein/images/:id" exact render={(match) => <TradeInImages tradeinid={match.match.params.id} />} />
                        <Route path="/tradeinpro/eval/:id" exact render={(match) => <TradeInEval offerid={match.match.params.id} />} />
                        <Route path="/tradeinpro/preview/:id" exact render={(match) => <TradeInPreview tradeinid={match.match.params.id} />} />
                        <Route path="/tradeinpro/internal/:id" exact render={(match) => <TradeInPreviewInternal tradeinid={match.match.params.id} />} />
                        <Route path="/tradeinpro/intake/:id" exact render={(match) => <TradeInPreviewIntake tradeinid={match.match.params.id} />} />

                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;