import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { leadBucketCountVM } from './viewModel'
const LeadsBucket = (props) => {
    const [counts, setCounts] = useState(leadBucketCountVM)
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const monthStart = props.dateRange.startDate
    const monthEnd = props.dateRange.endDate

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false


    useEffect(() => {
        if (!_.isArray(props.pipelineEnquiries))
            return

        var _pipelines = _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
        if (!_.isEmpty(props.selectedPipeline)) {
            _pipelines = [props.selectedPipeline.value]
        }

        setCounts({
            todayConverted: props.pipelineEnquiries.filter(a =>
                _pipelines.some(e => e === a.pipelineValue)
                && !_.isEmpty(a.convertedDate)
                && a.isConverted === true
                && a.convertedDate.seconds >= todayStart.seconds
                && a.convertedDate.seconds <= todayEnd.seconds
                && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
            monthConverted: props.pipelineEnquiries.filter(a =>
                _pipelines.some(e => e === a.pipelineValue)
                && !_.isEmpty(a.convertedDate)
                && a.isConverted === true
                && a.convertedDate.seconds >= monthStart.seconds
                && a.convertedDate.seconds <= monthEnd.seconds
                && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
            pendingInboundleads: props.pipelineEnquiries.filter(a =>
                a.pipelineValue === 'LeadsBucket'
                && (a.stageValue === 'InboundLead' || a.stageValue === 'New' || a.stageValue === 'Contacted')
                && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true)),
            lostApprovalList: props.pipelineEnquiries.filter(a =>
                a.statusValue === 'pendingLost'
                && ((!_.isEmpty(props.selectedUser) && !ownerDashboard) ? a.ownerValue === props.selectedUser.value : true))
        })

    }, [props.pipelineEnquiries, props.selectedUser, props.selectedPipeline])


    return (<>
        <div className="dashboard-box draggable-section">
            <div className="row" style={{ minHeight: `165px` }}>
                {
                    (Boolean(props.clientLostApproval) && Boolean(props.managerLostApproval)) ? (
                        <div className="col-md-12">
                            <div className="dashboard-box-inner d-flex align-items-center justify-content-center h-100">
                                <div className="dashboard-lost-wrap cursor-pointer" onClick={() => {
                                    if (counts.lostApprovalList.length > 0) {
                                        props.handleShowEnquiryList({
                                            status: 'pendingLost',
                                            enableMulticheck: true,
                                            pipeline: _.map(props.dealersettings.allPipelines.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; })
                                        }, 'Pending Lost Approval')
                                    }
                                }}>
                                    Lost Approvals
                                <h2>{counts.lostApprovalList.length}</h2>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)
                }

            </div >
        </div >
    </>);
}

export default LeadsBucket;