import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { doc } from 'rxfire/firestore';

import { PopUpModal, ContentOverlay, TableView, UpgradePlan } from '../../../../components';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import AddForm from './addForm';
import { formListFields, levelOptions } from './viewModel';
import { TemplateContext } from '../templateContext';
import PreviewForm from './preview';
import PublishForm from './publishModal';
// import PreviewForm from './preview';

const FeedbackForms = props => {
	const [windowSize, setWindowSize] = useState({
		windowWidth:
			window.innerWidth < 1450
				? window.innerWidth - 410
				: window.innerWidth - 500,
		windowHeight: window.innerHeight - 255,
	});
	const [webFormsList, setWebFormsList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [addForm, setAddForm] = useState({
		show: false,
		title: '',
		template: null,
	});
	const [groupOptions, setGroupOptions] = useState([]);
	const [clientOptions, setClientOptions] = useState([]);
	const [regionOptions, setRegionOptions] = useState([]);
	const [previewForm, setPreviewForm] = useState({
		show: false,
		title: '',
		formFields: null,
		feedbackForm: '',
	});
	const [publishForm, setPublishForm] = useState({
		show: false,
		title: '',
		feedbackForm: '',
	});
	const [webForms, setWebForms] = useState([]);
	const [loading, setLoader] = useState(true);

	const { clients, groups, regions } = useContext(TemplateContext);
	const liteVersion = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.liteVersion) &&
		!Boolean(props.dealersettings.superAdmin) ? true : false);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth:
					window.innerWidth < 1450
						? window.innerWidth - 410
						: window.innerWidth - 500,
				windowHeight: window.innerHeight - 255,
			});
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		let refs = [];
		const _levelOptions =
			props.dealersettings.level === 'oem' ||
				_.isEmpty(props.dealersettings.level) ||
				!_.find(levelOptions, { value: props.dealersettings.level })
				? levelOptions
				: levelOptions.filter(
					e =>
						e.index >=
						_.find(levelOptions, {
							value: props.dealersettings.level,
						}).index,
				);

		// console.log('_levelOptions', _levelOptions);

		_levelOptions.forEach(rec => {
			if (rec.value === 'oem') {
				refs.push(
					doc(
						window.firebase
							.firestore()
							.collection(`webFormTemplates`)
							.where('oemID', '==', props.dealersettings.oemID)
							.where('type', '==', 'sales')
							.where('isDeleted', '==', false),
					),
				);
			} else if (rec.value === 'region') {
				regions.forEach(region => {
					refs.push(
						doc(
							window.firebase
								.firestore()
								.collection(`webFormTemplates`)
								.where('regionID', '==', region.documentID)
								.where('type', '==', 'sales')
								.where('isDeleted', '==', false),
						),
					);
				});
			} else if (rec.value === 'group') {
				groups.forEach(group => {
					refs.push(
						doc(
							window.firebase
								.firestore()
								.collection(`webFormTemplates`)
								.where('groupID', '==', group.documentID)
								.where('type', '==', 'sales')
								.where('isDeleted', '==', false),
						),
					);
				});
			} else {
				clients.forEach(client => {
					refs.push(
						doc(
							window.firebase
								.firestore()
								.collection(`webFormTemplates`)
								.where('clientID', '==', client.documentID)
								.where('type', '==', 'sales')
								.where('isDeleted', '==', false),
						),
					);
				});
			}
		});

		if (refs.length === 0) {
			setWebForms([]);
			setLoader(false);
		}

		var alllookupsettings = combineLatest(refs)
			.pipe(
				map(snapshots => {
					// console.log('webform: snapshots', snapshots);
					let valToReturn = [];

					snapshots.forEach(lookup => {
						valToReturn.push(
							_.map(lookup.docs, function (obj) {
								return obj.exists ? { ...obj.data(), id: obj.id } : {};
							}),
						);
					});

					return valToReturn;
				}),
			)
			.subscribe(allDocs => {
				let _webFormTemplates = [];
				allDocs.forEach(rec => {
					_.isArray(rec)
						? rec.forEach(e => _webFormTemplates.push(e))
						: _webFormTemplates.push(rec);
				});
				// console.log('webform: _webFormTemplates', _webFormTemplates);
				setWebForms(_webFormTemplates);
				setLoader(false);
			});
		return () => {
			alllookupsettings && alllookupsettings.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (_.isEmpty(webForms)) {
			setWebFormsList([]);
			return;
		}

		let listVM = [];
		webForms.forEach(rec => {
			if (!listVM.some(e => e.documentID === rec.documentID)) {
				listVM.push({
					...rec,
					createdBy: CommonHelper.getUserNamebyId(
						props.clientUsers,
						rec.addedBy,
					),
					updatedBy: CommonHelper.getUserNamebyId(
						props.clientUsers,
						rec.modifiedBy,
					),
					_addTo:
						rec.addTo && rec.addTo === 'activity'
							? 'Activity'
							: rec.addTo && rec.addTo === 'inbound'
								? 'Inbound'
								: '--',
					createdOn: rec.addedDate
						? moment.unix(rec.addedDate.seconds).format('DD/MM/YYYY hh:mm A')
						: '',
					updatedOn: rec.modifiedDate
						? moment.unix(rec.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A')
						: '',
					levelValue: _.find(levelOptions, { value: rec.level })
						? _.find(levelOptions, { value: rec.level }).label
						: rec.level,
					levelsDOM: rec.oemID ? (
						<div className='Vehicle-availability'>
							<div>
								<span>{props.dealersettings.settingName}</span>
							</div>
						</div>
					) : rec.clientID ? (
						<div className='Vehicle-availability'>
							<div>
								<span>
									{
										clients.filter(c => c.documentID === rec.clientID)?.[0]
											?.name
									}
								</span>
							</div>
						</div>
					) : rec.groupID ? (
						<div className='Vehicle-availability'>
							<div>
								<span>
									{groups.filter(c => c.documentID === rec.groupID)?.[0]?.name}
								</span>
							</div>
						</div>
					) : rec.regionID ? (
						<div className='Vehicle-availability'>
							<div>
								<span>
									{
										regions.filter(c => c.documentID === rec.regionID)?.[0]
											?.name
									}
								</span>
							</div>
						</div>
					) : (
						<></>
					),
					strlevels: rec.oemID ? (
						props.dealersettings.settingName
					) : rec.clientID ? (
						clients.filter(c => c.documentID === rec.clientID)?.[0]?.name
					) : rec.groupID ? (
						groups.filter(c => c.documentID === rec.groupID)?.[0]?.name
					) : rec.regionID ? (
						regions.filter(c => c.documentID === rec.regionID)?.[0]?.name
					) : (
						<></>
					),
					visibilityDOM:
						rec.visibility === 'private' ? <div>--</div> : <div>--</div>,
				});
			}
		});
		setWebFormsList(listVM);
	}, [webForms, props.clientUsers]);

	useEffect(() => {
		if (!_.isEmpty(clients)) {
			let _clients = [];
			_.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
				_clients.push({ label: _client.name, value: _client.documentID, data: _client })
			});
			setClientOptions(_clients)
		}
		else {
			setClientOptions([{ name: 'oem' }])
		}
	}, [clients])

	useEffect(() => {
		if (_.isEmpty(clientOptions))
			return


		setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
			.map((rec) => {
				return {
					value: rec.code,
					label: rec.name,
					data: rec,
					active: _.isBoolean(rec.active) ? rec.active : true
				}
			}))
		setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
			.map((rec) => {
				return {
					value: rec.documentID,
					label: rec.name,
					data: rec,
					active: _.isBoolean(rec.active) ? rec.active : true,
					regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
				}
			}))

	}, [clientOptions, groups, regions])

	const handleEmbedOpen = (e, data) => {
		e.preventDefault();
		if (_.isEmpty(data.fields) || data.fields.length === 0) {
			Swal.fire(CommonHelper.showLocale(props, 'Please add at least one field to embed form.'), '', 'info');
			return;
		}
		let feedbackForm = { ...data };

		let formIsValid = true;
		let errorClass = 'input_error';
		let errors = {};
		if (_.isEmpty(feedbackForm['title'])) {
			formIsValid = false;
			errors['title'] = errorClass;
		}
		if (_.isEmpty(feedbackForm['level'])) {
			formIsValid = false;
			errors['level'] = errorClass;
		}
		if (
			!_.isEmpty(feedbackForm['level']) &&
			feedbackForm['level'] !== 'oem' &&
			_.isEmpty(feedbackForm['levelID'])
		) {
			formIsValid = false;
			errors['levelID'] = errorClass;
		}
		if (_.isEmpty(feedbackForm['addTo'])) {
			formIsValid = false;
			errors['addTo'] = errorClass;
		}
		if (
			!_.isEmpty(feedbackForm['addTo']) &&
			feedbackForm['addTo'] === 'activity'
		) {
			if (_.isEmpty(feedbackForm['activityType'])) {
				formIsValid = false;
				errors['activityType'] = errorClass;
			}
			if (_.isEmpty(feedbackForm['owner'])) {
				formIsValid = false;
				errors['owner'] = errorClass;
			}
		}
		// console.log('one', formIsValid)
		if (!formIsValid) {
			// OPEN FORM
			handledetailsClick(e, data.documentID, data);
			// setShowSettings({
			// 	show: true,
			// 	title: 'Form Settings',
			// 	fromPublish: closeOnSave ? false : true
			// })
			return;
		} else {
			// OPEN EMBED MODAL
			setPublishForm({
				feedbackForm: feedbackForm,
				show: true,
				title: 'Publish Form',
			});
		}
	};

	const handleCloneForm = template => {
		let form = {
			...template,
			documentID: window.firebase
				.firestore()
				.collection(`webFormTemplates`)
				.doc().id,
			addedBy: localStorage.uid,
			modifiedBy: localStorage.uid,
			addedDate: window.firebase.firestore.Timestamp.now(),
			modifiedDate: window.firebase.firestore.Timestamp.now(),
			title: template.title + ' - Copy',
			addTo: '',
			origin: '',
			owner: '',
			enquiryType: '',
			activityType: '',
		};
		setAddForm({ show: true, title: form.title, template: form });
	};

	const handleActionClick = (id, data) => {
		return (data.level &&
			data.level === 'individual' &&
			data.clientID === props.dealersettings.client.id) ||
			(data.level &&
				data.level === 'region' &&
				data.regionID === props.dealersettings.regionID) ||
			(data.level &&
				data.level === 'group' &&
				data.groupID === props.dealersettings.groupID) ||
			(data.level &&
				data.level === 'oem' &&
				data.oemID === props.dealersettings.oemID) ||
			data.addedBy === localStorage.uid ? (
			<div className=''>
				<button
					type='button'
					className='btn btn-green float-left mr-2'
					style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
					onClick={() =>
						setPreviewForm({
							formFields: data.fields,
							show: true,
							title: data.title,
							feedbackForm: data,
						})
					}
				>
					{' '}
					<i
						className='fa fa-eye custom-icon-preview'
						aria-hidden='true'
					></i>{' '}
					<Translate text={'preview'} />{' '}
				</button>
				<button
					type='button'
					className='btn btn-secondary float-left mr-2'
					style={{ padding: `3px 8px`, fontSize: `11px` }}
					onClick={e => handleEmbedOpen(e, data)}
				>
					{' '}
					<i
						className='fa fa-link custom-icon-preview'
						aria-hidden='true'
						style={{ paddingTop: '0px', marginRight: '4px' }}
					></i>{' '}
					<span><Translate text={'Embed'} /></span>{' '}
				</button>
				<div
					title='Clone template'
					style={{ cursor: 'pointer' }}
					className='mini-button float-left mr-2'
					onClick={e => {
						handleCloneForm(data);
					}}
				>
					<i className='ico icon-rearrange'></i>
				</div>
				<div
					title='Edit template'
					style={{ cursor: 'pointer' }}
					className='mini-button float-left mr-2'
					onClick={e => {
						handleEditForm(data);
					}}
				>
					<i className={`ico icon-edit`}></i>
				</div>
				<div
					title='Delete template'
					style={{ cursor: 'pointer' }}
					className='mini-button'
					onClick={e => {
						handleDeleteForm(data);
					}}
				>
					<i className={`ico icon-delete`}></i>
				</div>
			</div>
		) : (
			<div className=''>
				<button
					type='button'
					className='btn btn-green float-left mr-2'
					style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
					onClick={() =>
						setPreviewForm({
							formFields: data.fields,
							show: true,
							title: data.title,
							feedbackForm: data,
						})
					}
				>
					{' '}
					<i
						className='fa fa-eye custom-icon-preview'
						aria-hidden='true'
					></i>{' '}
					<Translate text={'preview'} />{' '}
				</button>
				<button
					type='button'
					className='btn btn-secondary float-left mr-2'
					style={{ padding: `3px 8px`, fontSize: `11px` }}
					onClick={e => handleEmbedOpen(e, data)}
				>
					{' '}
					<i
						className='fa fa-link custom-icon-preview'
						aria-hidden='true'
						style={{ paddingTop: '0px', marginRight: '4px' }}
					></i>{' '}
					<span><Translate text={'Embed'} /></span>{' '}
				</button>
				<div
					title='Clone template'
					style={{ cursor: 'pointer' }}
					className='mini-button float-left mr-2'
					onClick={e => {
						handleCloneForm(data);
					}}
				>
					<i className='ico icon-rearrange'></i>
				</div>
				<div
					title='Edit template'
					style={{ cursor: 'pointer' }}
					className='mini-button float-left mr-2 btn-disable'
					onClick={e => {
						e.preventDefault();
					}}
				>
					<i className={`ico icon-lock`}></i>
				</div>
				<div
					title='Delete template'
					style={{ cursor: 'pointer' }}
					className='mini-button  btn-disable'
					onClick={e => {
						e.preventDefault();
					}}
				>
					<i className={`ico icon-lock`}></i>
				</div>
			</div>
		);
	};

	const handleEditForm = template => {
		setAddForm({ show: true, title: template.title, template: template });
	};
	const handledetailsClick = (e, id, data) => {
		e.preventDefault();
		if (
			data.clientID === props.dealersettings.client.id ||
			data.addedBy === localStorage.uid
		)
			handleEditForm(data);
	};

	const handleDeleteForm = template => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				window.firebase
					.firestore()
					.doc(`webFormTemplates/${template.documentID}`)
					.set(
						{
							isDeleted: true,
							modifiedBy: localStorage.uid,
							modifiedDate: window.firebase.firestore.Timestamp.now(),
						},
						{ merge: true },
					)
					.then(() => {
						toast.notify(`Form deleted successfully`, {
							duration: 2000,
						});
					})
					.catch(error => {
						console.log(error);
						Swal.fire('Something went wrong.', '', 'error');
					});
			}
		});
	};

	return (
		<>
			{
				liteVersion ? (<UpgradePlan />) : (<></>)
			}
			<div className={`${liteVersion ? 'form-lite' : ''}`}>
				<div className='settings-head'>
					<div className='float-left'>
						<div className='filter-search search-icon'>
							<input
								placeholder='Search'
								aria-label='Search'
								value={searchText}
								onChange={e => {
									setSearchText(e.target.value);
								}}
							/>
						</div>
					</div>

					<div className='float-right'>
						<button
							type='button'
							className='btn btn-primary float-right mr-2'
							onClick={() => {
								setAddForm({ show: true, title: 'Add Form', template: null });
							}}
						>
							<i className='ico icon-add mr-2'></i>{' '}
							<Translate text={'Add Form'} />
						</button>
					</div>
				</div>
				{loading ? (
					<div className='mt-5'>
						<ContentOverlay active={true} />
					</div>
				) : webFormsList.length > 0 ? (
					<div className='common-table'>
						<TableView
							datalist={_.orderBy(
								webFormsList.filter(({ title, bodyHTML, createdBy, updatedBy }) =>
									!_.isEmpty(searchText)
										? (!_.isEmpty(title)
											? title.toLowerCase().includes(searchText.toLowerCase())
											: false) ||
										(!_.isEmpty(createdBy)
											? createdBy
												.toLowerCase()
												.includes(searchText.toLowerCase())
											: false) ||
										(!_.isEmpty(updatedBy)
											? updatedBy
												.toLowerCase()
												.includes(searchText.toLowerCase())
											: false) ||
										(!_.isEmpty(bodyHTML)
											? bodyHTML
												.toLowerCase()
												.includes(searchText.toLowerCase())
											: false)
										: true,
								),
								['addedDate.seconds'],
								['desc'],
							)}
							height={windowSize.windowHeight}
							width={windowSize.windowWidth}
							columns={formListFields}
							dynamicFields={formListFields
								.filter(e => e.default === true)
								.map(v => _.pick(v, ['value']).value)}
							handleActionClick={handleActionClick}
							handleRowSingleClick={handledetailsClick}
							isSettings={false}
							isReorderable={false}
							isSorting={true}
							isTranslate={true}
							dealersettings={props.dealersettings}
						/>
					</div>
				) : (
					<div
						className='common-table'
						style={{ height: windowSize.windowHeight + 'px' }}
					>
						<div className='text-center p-5'>
							<p>
								{' '}
								<Translate text={'No web form found.'} />
							</p>
							<button
								className='btn btn-sm btn-default'
								onClick={() => {
									setAddForm({ show: true, title: 'Add Form', template: null });
								}}
							>
								<i className='ico icon-add mr-2 pt-1 float-left'></i>
								<Translate text={'Add Form'} />
							</button>
						</div>
					</div>
				)}
			</div>
			<PopUpModal show={addForm.show}>
				<AddForm
					{...props}
					show={addForm.show}
					title={addForm.title}
					handleClose={() => {
						setAddForm({ show: false, title: '', template: null });
					}}
					feedbackForm={addForm.template}
					type={props.type ? props.type : 'email'}
					dealersettings={props.dealersettings}
					groupOptions={groupOptions}
					clientOptions={clientOptions}
					regionOptions={regionOptions}
					setAddForm={setAddForm}
					addForm={addForm}
				/>
			</PopUpModal>
			<PopUpModal show={previewForm.show}>
				<PreviewForm
					{...previewForm}
					dealersettings={props.dealersettings}
					handleClose={() => {
						setPreviewForm({
							feedbackForm: null,
							show: false,
							title: '',
							formFields: null,
						});
					}}
				/>
			</PopUpModal>
			<PopUpModal show={publishForm.show}>
				<PublishForm
					{...publishForm}
					dealersettings={props.dealersettings}
					handleClose={() => {
						setPublishForm({ show: false, title: '', feedbackForm: null });
					}}
					isFromTableView={true}
				/>
			</PopUpModal>
		</>
	);
};

export default FeedbackForms;
