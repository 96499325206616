/** LIBRARIES */
import React, { PureComponent } from "react";
import NumberFormat from 'react-number-format';

export default class InputAutoNumber extends PureComponent {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            defaultVaue: "InputAutoNumber",
            focus: false
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    }

    handleOutsideClick = (e) => {
        const { id, name } = this.props;
        if (e.target.id === (id ? id : name) || (this.myRef && this.myRef.current && this.myRef.current.contains(e.target))) {
            return;
        }

        this.handleSave();
    }

    handleSave = () => {
        const { name, value, id, returnDefault } = this.props;
        const { defaultVaue } = this.state;

        if (returnDefault) {
            this.props.onSaveField(name, value, defaultVaue);
        }
        else if (defaultVaue !== 'InputAutoNumber' && defaultVaue !== value)
            this.props.onSaveField(name, value, defaultVaue);

        if (document.getElementById(id ? id : name)) document.getElementById(id ? id : name).classList.add('cursor-pointer')
        this.setState({ defaultVaue: 'InputAutoNumber', focus: false })
    }

    handleCancel = () => {
        const { name, value, id, returnDefault } = this.props;
        const { defaultVaue } = this.state;

        if (returnDefault) {
            this.props.onSaveField(name, value, defaultVaue);
        }
        else if (defaultVaue !== 'InputAutoNumber' && defaultVaue !== value)
            this.props.onCancelField(name, defaultVaue);

        if (document.getElementById(id ? id : name)) document.getElementById(id ? id : name).classList.add('cursor-pointer')
        this.setState({ defaultVaue: 'InputAutoNumber', focus: false })
    }

    render() {
        const { id, allowNegative, name, thousandSeparator, value, onValueChange, placeholder, prefix, suffix, className, allowLeadingZeros, returnDefault } = this.props;
        const { focus } = this.state
        //('RENDER INPUT TEXT', name, value)
        return (
            <div className="input-focus-edit">
                <div ref={this.myRef} className={`input-focus-buttonwrap ${!focus || returnDefault ? "hide" : ""}`}>
                    <button type="button" className="btn btn-focus float-left" title="save"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleSave();
                        }}>
                        {" "}
                        <i className="ico icon-check"></i>
                    </button>

                    <button type="button" className="btn btn-focus float-left " title="cancel"
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleCancel();
                        }}>
                        {" "}
                        <i className="ico icon-remove"></i>
                    </button>
                </div>

                <NumberFormat
                    autoComplete="off"
                    id={id ? id : name}
                    name={name}
                    allowNegative={allowNegative}
                    decimalScale={2}
                    thousandSeparator={thousandSeparator}
                    prefix={prefix}
                    suffix={suffix}
                    placeholder={placeholder}
                    className={className}
                    onValueChange={onValueChange}
                    value={value}
                    allowLeadingZeros={allowLeadingZeros}
                    onFocus={() => {
                        //console.log('onFocus', value);
                        document.getElementById(id ? id : name).classList.remove('cursor-pointer')
                        this.setState({
                            defaultVaue: value,
                            focus: true
                        })
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            this.handleSave()
                        }
                    }}
                />
            </div>

        );
    }
}