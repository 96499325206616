
/** LIBRARIES */
import React, { Component } from "react";
import {Modal} from "react-bootstrap";
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../constants/translate';
import { PopUpModal, Signature } from '../../components';
import { default as _images } from '../../images';
import { storageBucket } from "../../services/helper";

export default class AfterSignature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signatureURL: '',
            errors: {},
            showSignature: false
        }

        //this.handleSignatureClose = this.handleSignatureClose.bind(this);

    }
    
    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/${this.props.imagePath}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        this.setState({ signatureURL: dataURL }, () => { this.errorChange(id) });                        
                    })
            })
    };

    //#endregion


    handleSignatureClose = (imgURL) => {
        if (imgURL) {
            this.setState({ signatureURL: imgURL, showSignature: false })
            this.saveImageToStorage(imgURL, 'signatureURL', 'png');
        }
        else {
            this.setState({ showSignature: false })
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = this.state.signatureURL;
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }
    componentDidMount() {
        this.setState({ signatureURL: this.props.signatureURL })
    }

    componentWillUnmount() {

    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;

        let errorClass = 'input_error';

        if (!this.state.signatureURL) {
            formIsValid = false;
            errors['signatureURL'] = errorClass;
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            //console.log('formIsValid')
            this.props.handleClose(this.state.signatureURL, this.props.fieldID)
        }
        else
            return formIsValid;
    };



    render() {
        const { signatureURL } = this.state;
        const { show, isDisable } = this.props;

        return (
            <>
                <Modal
                    id="add-secondary-driver"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-secondary-driver">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'signature'}/></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className={`container-fluid ${(isDisable) ? 'form-disable' : ''}`}>
                            <div className="row">
                                <div className="secondary-license-wraper">


                                    <div className={`signature-box ${this.state.errors["signatureURL"]}`} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ showSignature: true })
                                    }}>
                                        {
                                            (signatureURL && signatureURL.indexOf('base64') > 0)
                                                ?
                                                <div className="img-loader">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only"><Translate text={'Loading...'} /></span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (signatureURL && !isDisable && signatureURL.indexOf('base64') <= 0)
                                                ?
                                                <div className="editsign-btn">
                                                    <a href="#" className="editsign mini-button" onClick={(e) => { e.preventDefault(); }}>
                                                        <i className="ico icon-edit" htmlFor="licBack"></i>
                                                    </a>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (signatureURL)
                                                ?
                                                <a data-fancybox={`signatureURL`} href={signatureURL} onClick={(e) => { e.preventDefault(); }}>
                                                    <img src={signatureURL} alt="" width="75" />
                                                </a>
                                                :
                                                <img src={_images.nosign} alt="" width="75" />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    {
                        isDisable
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer className="modal-footer">
                                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose('')}}><Translate text={'cancel'}/></button>
                                    <button type="button" 
                                    className={`btn btn-primary float-right ${(signatureURL && signatureURL.indexOf('base64') > 0) ? 'form-disable' : ''}`} 
                                    onClick={(e) => this.handleSubmit(e)}><Translate text={'done'}/></button>
                                </Modal.Footer>
                            </>
                    }

                </Modal>

                <PopUpModal show={this.state.showSignature}>
                    <Signature
                        show={this.state.showSignature}
                        clsActive='overlay-modal active'
                        handleClose={this.handleSignatureClose}
                    ></Signature>
                </PopUpModal>
            </>


        );
    }
}

