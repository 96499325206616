/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** PROVIDERS */
/** VIEW-MODELS */
import { allRejectFieldsVM, objQueue } from './viewModel';
/** COMPONENTS */
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import Translate from '../../constants/translate';
import Users from '../users/list/index';
import { engageStatus } from '../../services/enum'

import { objEngageRequest } from './viewModel'
import StockListReducer from '../stock/stockListReducer'

const RejectContactList = (props) => {
    //const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 179) })
    const [rejectContacts, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    //const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [rejectLoader, setRejectLoader] = useState(true)
    const pageLimit = 50

    useEffect(() => {
        // function handleResize() {
        //     setWindowSize({
        //         windowWidth: (window.innerWidth - 30),
        //         windowHeight: (window.innerHeight - 179)
        //     })
        // }
        // window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            //window.removeEventListener('resize', handleResize);
            window.unSubscribeRejConList && window.unSubscribeRejConList();
        }
    }, [])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }
        let collectionDataRef = firestoreDB(props.dealersettings).firestore().collection('engageRequests')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('status', '==', engageStatus.REJECT)
            .where('isDeleted', '==', false)
            .orderBy('modifiedDate', 'desc')

        if (hasMore.length > 0) {
            collectionDataRef = collectionDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            collectionDataRef = collectionDataRef
                .limit(pageLimit)
        }

        window.unSubscribeRejConList = collectionDataRef
            .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "_ADD";
                }
                else if (change.type === 'modified') {
                    actionType = "_UPDATE"
                }
                else if (change.type === 'removed') {
                    actionType = "_REMOVE"
                }
            }
        })
        const rejectContacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                rejectContacts.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "_APPEND",
                data: rejectContacts,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "_SUCCESS",
                data: actionType ? snapshotDoc : rejectContacts,
                sortName: 'modifiedDate',
                sortOrder: 'desc'
            });
        }


        setDataload(false)
        setRejectLoader(false)
        setPaging(false)
    }



    const dataMappingVM = (doc) => {

        const _obj = Object.assign({}, doc);
        const queueVM = Object.assign({}, objQueue);
        for (let [key, value] of Object.entries(_obj)) {
            queueVM[key] = value;
        }

        queueVM.createdOn = moment.unix(queueVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        queueVM.updatedOn = moment.unix(queueVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(queueVM.contact)) {
            queueVM.contactName = <div onClick={(e) => {
                e.preventDefault();
                const url = `/contacts/details/${queueVM.contact.documentID}`;
                window.open(url, '_blank');
            }}>{CommonHelper.displayContact([], queueVM.contact, '--')}</div>;
        }
        else {
            queueVM.contactName = queueVM.name ? queueVM.name : '';
        }
        queueVM.userName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.user);
        queueVM.addedBy = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.addedBy);
        queueVM.modifiedBy = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.modifiedBy);
        return queueVM
    }



    const handleActionClick = (id) => {
        return (

            <div className="fleet-btn-loan  reassign-fix" style={{ width: '130px', float: 'left' }}>
                <div className="rejected-delete">
                    <a href="#" className="mini-button ml-2" onClick={(e) => {
                        e.preventDefault();
                        deleteRequest(id);
                    }}>
                        <i className="ico icon-delete"></i>
                    </a>
                </div>
                <button type="submit" className="btn btn-sm btn-primary float-right" onClick={(e) => {
                    e.preventDefault();
                    setEditDocument(id);
                    setModelShow(true);
                }}>Reassign</button>
            </div>
        )
    }

    const handleDetailsClick = (e) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }
    const handleUserClose = (userid) => {
        //console.log('handleUserClose', userid, editDocumentID)
        if (userid && editDocumentID) {
            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();
            const updateRef = firestoreDB(props.dealersettings).firestore().collection('engageRequests').doc(editDocumentID);
            updateRef.update({
                isDeleted: true,
                modifiedBy: modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: modifiedDate
            }).then((docRef) => {
                if (userid && editDocumentID) {
                    createEngageRequest(userid, editDocumentID);
                    setEditDocument(null);
                }
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }


        setModelShow(false);

    }

    const deleteRequest = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the request'),
            icon: 'warning',
            showCancelButton: true,
            cconfirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                firestoreDB(props.dealersettings).firestore().collection('engageRequests').doc(id).delete().then(() => {
                    toast.notify('Engage request successfully deleted', {
                        duration: 2000
                    })

                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });

            }
        })


    }

    const createEngageRequest = async (userid, docID) => {
        const { dealersettings } = props;
        const fields = Object.assign({}, objEngageRequest);
        if (docID && userid) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('engageRequests').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                let userID = props.dealersettings ? props.dealersettings.id : '';
                let currentDate = window.firebase.firestore.Timestamp.now();

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }
                fields.clientID = props.dealersettings ? props.dealersettings.client.id : '';
                fields.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
                fields.documentID = firestoreDB(props.dealersettings).firestore().collection('engageRequests').doc().id;
                fields.status = engageStatus.PENDING;
                fields.user = userid;
                fields.addedBy = userID;
                fields.addedDate = currentDate;
                fields.modifiedBy = userID;
                fields.modifiedDate = currentDate;
                fields.isDeleted = false;
                fields.rejectReason = null;

                const updateRef = firestoreDB(props.dealersettings).firestore().collection('engageRequests').doc(fields.documentID);
                updateRef.set(fields, { merge: true }).then((docRef) => {
                    //console.log('engageRequests', fields.documentID)
                    toast.notify('Engage reassign send successfully.', {
                        duration: 2000
                    });

                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });

            } else {
                console.error("No such document2!");
            }

        }
    }
    // const handleHistoryClose = (id) => {
    //     setModelHistoryShow(false);
    //     setEditDocument(null);
    // }



    return (
        <>
            <Modal
                id="rejected-contact"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rejected-contact">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'rejectedContacts'} />
                            <span className="badge badge-pill badge-rejected-count-red ml-1">{props.rejectCount}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid">
                        <div className="row">

                            <div className="rejected-wrapper">
                                {
                                    rejectLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                        (
                                            <div className="common-table" style={{ width: '565px' }}>
                                                <TableView
                                                    isReorderable={false}
                                                    datalist={rejectContacts}
                                                    height={565}
                                                    width={1165}
                                                    columns={allRejectFieldsVM}
                                                    dynamicFields={allRejectFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                    settingsLoader={settingsLoader}
                                                    handleActionClick={handleActionClick}
                                                    handleRowSingleClick={handleDetailsClick}
                                                    isSettings={false}
                                                    hasMore={hasMore}
                                                    handlePagination={handlePagination}
                                                    isPaging={true}
                                                />
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelShow}>
                <Users
                    show={modelShow}
                    clientUsers={props.activeUsers}
                    groupUsers={props.groupUsers}
                    dealersettings={props.dealersettings}
                    handleUserClose={handleUserClose}
                    title='selectUsers'
                    showAdd={false}
                    showQueue={true}
                />
            </PopUpModal>

        </>
    )
}

export default RejectContactList