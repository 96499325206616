/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash';
import toast from 'toasted-notes';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, PopoverListLoader } from '../../../components';
import { default as _images } from "../../../images";
import CommonHelper from '../../../services/common';
//import AddContact from '../add';
import { objOfferNotesVM } from '../viewModel';

import AddNotes from './add';
import { firestoreDB } from '../../../services/helper';

class OfferNotes extends Component {
    constructor(props) {
        super(props);
        //this.ref = window.firebase.firestore().collection('tradeinPro');
        this.unsubscribe = null;
        this.state = {
            notesHistory: [],
            filter: {
                iSearch: '',
                myContact: false
            },
            loading: true,
            contactModal:
            {
                showContact: false,
                showContactSearch: false,
                contactTitle: '',
                clsContactActive: ''
            },
            showNotes: false,
        };
    }



    onNotesCollectionUpdate = (querySnapshot) => {
        const offerNotes = [];
        querySnapshot.forEach((doc) => {
            const _notes = Object.assign({}, objOfferNotesVM);
            for (let [key, value] of Object.entries(doc.data())) {
                _notes[key] = value;
            }
            _notes.documentID = doc.id;
            if (!_notes.isDeleted && ((_notes.isInternalNotes === true && _notes.wholeSalerNotes === false) || // isDealerInternalNotes 
                (_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
            )) offerNotes.push(_notes);
        });

        this.setState({
            notesHistory: offerNotes,
            loading: false
        });
    }

    componentDidMount() {
        const { tradeinProID, offerID, dealersettings } = this.props;
        if (tradeinProID && offerID) {

            this.unsubscribe = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinProID}/offerNotes`)
                .where('offerID', '==', offerID)
                .onSnapshot(this.onNotesCollectionUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }


    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions, _isInternal) => {
        if (noteTxt) {
            const { tradeinProID, offerID, dealersettings } = this.props;
            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id,
                projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null,
                notes: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: dealersettings ? dealersettings.id : '',
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                offerID,
                tradeinProID,
                isDeleted: false
            }

            if (_isInternal)
                _objNote.isInternalNotes = true;

            const updateRef = firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}/offerNotes/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }


    render() {

        const { notesHistory, loading, showNotes } = this.state
        const { show, groupUsers, dealersettings, clientUsers } = this.props;
        let _allUser = _.union((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), Object.assign([], dealersettings.wholesalers));

        const divStyle = {
            height: '400px',
            overflow: 'auto',
            position: "relative"
        };

        return (
            <>
                <Modal
                    id="stockList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'Offer Notes'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">
                                    <div className="quickview-files-wraper" style={divStyle}>
                                        <>

                                            <div className="quickview-requirement-section">
                                                <div className="add-files-button">
                                                    <a href="#" className="mini-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleNotesOpen();
                                                    }}>
                                                        <i className="ico icon-add"></i>
                                                    </a>
                                                </div>
                                                <h3><Translate text={'Notes'} /></h3>
                                            </div>

                                            {!_.isEmpty(notesHistory)
                                                ?
                                                <>

                                                    <div className={`file-manager-control`}>
                                                        {
                                                            _.orderBy(notesHistory, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                return <div key={index} className="notes-item text-area-space ">
                                                                    {noteData.notes}
                                                                    <div className="notes-item-sub">

                                                                        <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                        &nbsp;{' - '}&nbsp;{CommonHelper.getUserNamebyId(_allUser, noteData.addedBy)}
                                                                        {noteData.addedFrom ?
                                                                            <>
                                                                                &nbsp;{` - `}&nbsp;
                                                                                <i className={`ico icon-${noteData.addedFrom === 'web' ? 'monitor' : noteData.addedFrom} mr-1`}></i>
                                                                                {noteData.addedFrom} </> : <></>}

                                                                        {
                                                                            (noteData.wholeSalerNotes) ? (
                                                                                <div className="badge badge-pill badge-input-white ml-1 text-uppercase">{'wholeSaler'}</div>
                                                                            ) : (<></>)
                                                                        }

                                                                        {
                                                                            (noteData.isInternalNotes) ? (
                                                                                <div className="badge badge-pill badge-input-white ml-1 text-uppercase">{'Internal'}</div>
                                                                            ) : (<></>)
                                                                        }

                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100">
                                                        <div className="no-cafe-flex justify-content-center align-items-center">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-note icon-service-nonote"></i> </div>
                                                        </div>
                                                    </div>                                                        
                                                </>}
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <PopUpModal show={showNotes}>
                    <AddNotes
                        show={showNotes}
                        //serviceID={this.state.serviceID}
                        handleClose={this.handleNotesClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal>
            </>

        );
    }

}

export default OfferNotes;