export const objTradeIn = {
    documentID: null,
    oemID: null,
    clientID: null,
    projectId: null,
    enquiryID: null,
    serviceID: null,
    contactID: null,
    contact: null,
    enquiry: null,
    service: null,
    stockID: null,
    evalexpertID: null,
    tradeinProID: null,
    autograbID: null,
    make: '',
    model: '',
    year: '',
    modelDescription: '',
    //saleType: '',
    specifications: '',
    notes: '',
    regNo: '',
    regDate: null,
    chassisNo: '',
    engineNo: '',
    transmission: '',
    doors: '',
    mileage: '',
    exteriorColor: '',
    interiorColor: '',
    trim: '',
    spareTyre: '',
    massageVentilated: false,
    camera: false,
    contrastStiching: false,
    woodSteering: false,
    sunroof: false,
    owners: '',
    outstandingPayments: false,
    warranty: false,
    financeCo: '',
    serviceContFrom: null,
    warrantyExpiry: null,
    serviceContTo: null,
    reconditioning: false,
    reconditioningCost: 0,
    financed: false,
    tyreAge: '',
    lastService: null,
    status: '',
    type: '',
    group: '',
    engineType: '',
    engineSize: '',
    frontLeftWheel: '',
    frontRightWheel: '',
    rearLeftWheel: '',
    rearRightWheel: '',
    vehicleCondition: '',
    buildDate: null,
    complianceDate: null,
    spareKey: '',
    logBooks: '',
    serviceHistory: '',
    cargoBlind: '',
    headRests: '',
    sdCard: '',

    driveType: '',
    fuelType: '',
    cylinders: 0,
    gears: 0,
    seats: 0,
    powerKw: 0,
    range: 0,
    series: '',
    torqueNm: '',
    transmissionType: '',
    wheelbase: '',
    regStatus: '',
    regState: '',
    badge: '',
    region: '',

    sent: false,
    linkedAppraisal: false,
    offerPrice: 0,
    valuerPrice: 0,
    linkStatus: '',
    linkURL: '',
    images: [],
    sidePhotos: null,
    marks: [],
    dynamicFields: {},
    inspectionSnapshot: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    tags: []
};

export const sidePhotosVM = [
    {
        name: 'Front',
        value: 'front',
        default: true,
        ico: 'frontView'
    },
    {
        name: 'Left',
        value: 'left',
        default: true,
        ico: 'leftView'
    },
    {
        name: 'Right',
        value: 'right',
        default: true,
        ico: 'rightView'
    },
    {
        name: 'Rear',
        value: 'rear',
        default: true,
        ico: 'rearView'
    },
    {
        name: 'Front Left Corner',
        value: 'frontLeftCorner',
        default: true,
        ico: 'leftFrontView'
    },
    {
        name: 'Front Right Corner',
        value: 'frontRightCorner',
        default: true,
        ico: 'rightFrontView'
    },
    {
        name: 'Rear Left Corner',
        value: 'rearLeftCorner',
        default: true,
        ico: 'leftRearView'
    },
    {
        name: 'Rear Right Corner',
        value: 'rearRightCorner',
        default: true,
        ico: 'rightRearView'
    },
    {
        name: 'Odometer',
        value: 'odometer',
        default: true,
        ico: 'odometerView'
    },
    {
        name: 'Service Booklet',
        value: 'serviceBooklet',
        default: true,
        ico: 'bookletView'
    }
]
export const mandatoryFields = [
    'make',
    //'type',
    'model',
    'regNo'
];

export const objMark = {
    dx: 0,
    dy: 0,
    markType: '',
    notes: '',
    price: 0,
    currency: '',
    images: []
};

export const mandatoryMark = [
    'dx',
    'dy',
    'markType'
];

export const allTradeinFields = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        sortValue: 'contact."firstName"',
        subText: 'availability',
        subTextTitle: 'Availability',
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        group: 'Trade In',
        subText: 'year',
        default: true,
        subTextTitle: 'Year',
        sortValue: 'tradeinModel'
    },
    {
        name: 'Model Description',
        value: 'modelDescription',
        flex: 1,
        group: 'Trade In',
        default: true
    },
    {
        name: 'Valuer Price',
        value: 'valuerPrice',
        flex: 1,
        group: 'Trade In',
        default: true
    },
    {
        name: 'Final Price',
        value: 'finalPrice',
        flex: 1,
        group: 'Trade In',
        default: true
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        group: 'Trade In',
        default: true,
        elementName: 'tradeinstatusName',
        sortValue: 'tradein."status"'
    },
    {
        name: 'Enquiry Number',
        value: 'enqNumber',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'enq."displayID"'
    },
    {
        name: 'Enquiry Status',
        value: 'enqStatus',
        flex: 1,
        group: 'Enquiry',
        default: true,
        elementName: 'enqStatusName',
        sortValue: 'status.name'
    },
    {
        name: 'Requirement Model',
        value: 'reqModel',
        flex: 1,
        group: 'Enquiry',
        subText: 'reqSaletype',
        subTextTitle: 'Sale Type',
        sortValue: 'vehicleModel'
    },
    {
        name: 'Enquiry Owner',
        value: 'enqOwner',
        flex: 1,
        group: 'Enquiry',
        default: true,
        sortValue: 'owner.name'
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1,
        group: 'Enquiry',
        sortValue: 'enquiryType.name'
    },
    {
        name: 'Body Type',
        value: 'type',
        flex: 1,
        group: 'Trade In'
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1,
        group: 'Enquiry',
        default: true,
        sortValue: 'origin.name'
    },
    {
        name: 'Campaign',
        value: 'campaign',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'campaign.name'
    },
    {
        name: 'Label',
        value: 'label',
        group: 'Enquiry',
        flex: 1,
        elementName: 'labelName',
        sortValue: 'label.name'
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'pipeline.name'
    },
    {
        name: 'Stage',
        value: 'stage',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'stage.name'
    },
    {
        name: 'Purchase Intention',
        value: 'purchaseIntention',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Gross Amount',
        value: 'grossAmount',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Deposit Amount',
        value: 'depositAmount',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Deposit Recipt Number',
        value: 'depositReciptNumber',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Lead Source',
        value: 'leadSource',
        group: 'Enquiry',
        flex: 1,
        sortValue: 'leadSource.name'
    },
    {
        name: 'Contract Number',
        value: 'contractNumber',
        group: 'Enquiry',
        flex: 1,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        group: 'Trade In',
        subText: 'addedBy',
        default: true,
        subTextTitle: 'Created By',
        sortValue: 'tradein."addedDate"'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        group: 'Trade In',
        subText: 'modifiedBy',
        subTextTitle: 'Modified By'
    },
    {
        name: 'Reg. No',
        value: 'regNo',
        flex: 1,
        group: 'Trade In',
        default: true
    },
    {
        name: 'Reg. Date',
        value: 'regDate',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'VIN No',
        value: 'chassisNo',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Engine No',
        value: 'engineNo',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Engine Type',
        value: 'engineType',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Engine Capacity-Litres',
        value: 'engineSize',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Transmission',
        value: 'transmission',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Trim',
        value: 'trim',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Exterior Color',
        value: 'exteriorColor',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Interior Color',
        value: 'interiorColor',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    // {
    //     name: 'Sale Type',
    //     value: 'saleType',
    //     flex: 1,
    //     group: 'Trade In',
    //     default: false
    // },
    {
        name: 'Mileage',
        value: 'mileage',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Doors',
        value: 'doors',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Spare Tyre',
        value: 'spareTyre',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Notes',
        value: 'notes',
        flex: 1,
        group: 'Trade In',
        default: false,
        limit: 190
    },
    {
        name: 'Massage Ventilated',
        value: 'massageVentilated',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Camera',
        value: 'camera',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Contrast Stiching',
        value: 'contrastStiching',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Wood Steering',
        value: 'woodSteering',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Sunroof',
        value: 'sunroof',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Owners',
        value: 'owners',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Outstanding Payments',
        value: 'outstandingPayments',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Warranty',
        value: 'warranty',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Warranty Expiry',
        value: 'warrantyExpiry',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Financed',
        value: 'financed',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Finance Company',
        value: 'financeCo',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Service Contract From',
        value: 'serviceContFrom',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Service Contract To',
        value: 'serviceContTo',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Reconditioning',
        value: 'reconditioning',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Tyre Age',
        value: 'tyreAge',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Last Service',
        value: 'lastService',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Front Left Wheel',
        value: 'frontLeftWheel',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Front Right Wheel',
        value: 'frontRightWheel',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Rear Left Wheel',
        value: 'rearLeftWheel',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Rear Right Wheel',
        value: 'rearRightWheel',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Vehicle Condition',
        value: 'vehicleCondition',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Build Date',
        value: 'buildDate',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Compliance Date',
        value: 'complianceDate',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Drive Type',
        value: 'driveType',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Fuel Type',
        value: 'fuelType',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Cylinders',
        value: 'cylinders',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Gears',
        value: 'gears',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Seats',
        value: 'seats',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Power kW',
        value: 'powerKw',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Range',
        value: 'range',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Series',
        value: 'series',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Torque Nm',
        value: 'torqueNm',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Transmission Type',
        value: 'transmissionType',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Wheelbase',
        value: 'wheelbase',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Registration State',
        value: 'regState',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Registration Status',
        value: 'regStatus',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Badge',
        value: 'badge',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Region',
        value: 'region',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Spare Key',
        value: 'spareKey',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Log Books',
        value: 'logBooks',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Service History',
        value: 'serviceHistory',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Cargo Blind',
        value: 'cargoBlind',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Head Rests',
        value: 'headRests',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'SD Card',
        value: 'sdCard',
        flex: 1,
        group: 'Trade In',
        default: false
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        group: 'Contact',
        flex: 1
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        group: 'Contact',
        flex: 1,
        elementName: 'phone'
    },
    {
        name: 'License No',
        value: 'licenseNo',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License Type',
        value: 'licenseType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Birthday',
        value: 'dob',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Gender',
        value: 'gender',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        group: 'Contact',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        group: 'Contact',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        group: 'Contact',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Created By'
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        group: 'Contact',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Updated By'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const tradeinSortOptions = [
    "make",
    "group",
    "type",
    "model",
    "year",
    "modelDescription",
    "chassisNo",
    "regNo",
    "regDate",
    "regState",
    "regStatus",
    "region",
    "badge",
    "series",
    "status",
    "complianceDate",
    "buildDate",
    "exteriorColor",
    "interiorColor",
    "trim",
    "engineNo",
    "engineSize",
    "engineType",
    "driveType",
    "fuelType",
    "transmission",
    "transmissionType",
    "mileage",
    "cylinders",
    "gears",
    "doors",
    "seats",
    "powerKw",
    "range",
    "torqueNm",
    "Wheelbase",
    "vehicleCondition",
    "spareKey",
    "logBooks",
    "serviceHistory",
    "cargoBlind",
    "headRests",
    "sdCard",
    "frontRightWheel",
    "frontLeftWheel",
    "rearRightWheel",
    "rearLeftWheel",
    "spareTyre",
    "owners",
    "serviceContFrom",
    "serviceContTo",
    "lastService",
    "warrantyExpiry",
    "financeCo",
    "tyreAge",
    "reconditioningCost",
    "massageVentilated",
    "contrastStiching",
    "sunroof",
    "camera",
    "woodSteering",
    "warranty",
    "reconditioning",
    "financed",
    "outstandingPayments",
    "specifications",
    "notes"
];