/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
/** COMPONENTS */
//const VideoDetails = lazy(() => import('../details'));
import TradeInDetails from '../customer';

class MainContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <main role="main" className="container-fluid  pl-0 pr-0">

                <Suspense fallback={<></>}>
                    <Switch>
                        <Route path="/tradein/customer/:id" exact render={(match) => <TradeInDetails tradeinid={match.match.params.id} />} />

                    </Switch>
                </Suspense>

            </main>
        );
    }
}

export default MainContainer;