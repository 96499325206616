export const servicePartVM = {
  partNumber: '',
  partName: '',
  partCost: '',
  settingsID: '',
  keywords: [],
  clientIDs: [],
  oemID: '',
  active: true,
  isDeleted: false,
}

export const levelOptions = [
  { value: 'oem', label: 'OEM', index: 1 },
  { value: 'individual', label: 'Individual', index: 2 }
]


export const templatelistFields = [
  {
    name: 'Checkbox',
    value: 'checkbox',
    subText: 'documentID',
    width: 40,
    default: true,
    type: 'checkbox',
    flex: 0,
    fixed: true
  },
  {
    name: 'Part Number',
    value: 'partNumber',
    flex: 1,
    default: true
  },
  {
    name: 'Vehicle',
    value: 'vehicle',
    flex: 1,
    default: true
  },
  {
    name: 'Part Name',
    value: 'partName',
    flex: 1,
    default: true
  },
  {
    name: 'Part Cost',
    value: 'partCost',
    flex: 2,
    default: true
  },
  {
    name: 'Active',
    value: 'active',
    flex: 0,
    width: 120,
    default: true,
    type: 'switch',
  },
  {
    name: 'Settings',
    value: 'settings',
    subText: 'documentID',
    width: 80,
    default: true,
    type: 'settings',
    flex: 0,
    fixedRight: true
  }
]
