import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import _ from 'lodash';

const LoadConfiguration = props => {
    const [loader, setLoader] = useState(true);
	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
                        <div className='container-fluid'>
								{
									loader
										?
										<>
											<div className="dashboard-widget-loader h-100">
												<div className="d-flex h-100 justify-content-center align-items-center text-primary">
													<div className="spinner-border" role="status">
														<span className="sr-only"><Translate text={'Loading...'} /></span>
													</div>
												</div>

											</div>
										</>
										:
										<>
										</>
								}
								<iframe width="100%" height="100%" src={`${props.url}`} frameBorder="0" onLoad={() => setLoader(false)} />
							</div>
						</div>
					</Modal.Body>
				</div >
			</Modal>
		</>
	);
};

export default LoadConfiguration;
