/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, InputText, PopUpModal, InputCheckBox,
    VehicleScan, AntDatePicker, AutoGrabScan
} from '../../../components';
//import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
/** VIEW-MODELS */
import { objOwnedVehicle, mandatoryOwnedFields } from '../viewModel'
import { firestoreDB, storageBucket } from '../../../services/helper';

let transmissions = [];
export default class AddOwnedVehicle extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.refStock = window.firebase.firestore().collection('stock');
        this.state = {
            stock: Object.assign({}, objOwnedVehicle),
            showRegScan: false,
            showVinScan: false,
            errors: {},
            makes: [],
            models: [],
            types: [],
            groups: [],
            years: [],
            addMake: false,
            addType: false,
            addGroup: false,
            addModel: false,
            autograbSearch: false,
            vehicleScan: { show: false, type: null },
        }
        const _autograb = Object.assign({}, props.dealersettings?.client?.integrations.filter(e => e.type === "autograb")[0]);
        const _autoGrabEnabled = _autograb && _autograb.active && _autograb.enabled ? true : false;
        this.autograbEnabled = ((!_.isEmpty(props.dealersettings.client.moduleSettings) &&
            !_.isEmpty(props.dealersettings.client.moduleSettings.autograb) &&
            props.dealersettings.client.moduleSettings.autograb.enabled &&
            props.dealersettings.client.moduleSettings.autograb.vehicleDetail) ? true : _autoGrabEnabled);
        this.regoScanEnabled = ((!_.isEmpty(props.dealersettings.client.moduleSettings) &&
            !_.isEmpty(props.dealersettings.client.moduleSettings.regoScan) &&
            props.dealersettings.client.moduleSettings.regoScan.enabled) ? true : false);

    }

    //#region  LOAD DATA
    async loadStock(props) {
        const { show, dealersettings, contactID, docID } = props;
        const owned = Object.assign({}, objOwnedVehicle);
        if (show === true && docID && contactID) {
            const refData = await firestoreDB(dealersettings).firestore().collection(`contactDetails/${contactID}/ownedVehicles`).doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    owned[key] = value;
                }

                if (!owned.documentID)
                    owned.documentID = docID;

                this.setState({ stock: owned });

            } else {
                console.error("No such document2!");
            }

        }
        else if (show === true && (!docID) && contactID) {
            owned.documentID = this.refStock.doc().id;
            //this.setState({  });
            if (this.autograbEnabled)
                setTimeout(() => this.setState({ autograbSearch: true, stock: owned }), 500)
            else if (this.regoScanEnabled) {
                setTimeout(() => this.setState({ vehicleScan: { show: true, type: 'reg' }, stock: owned }), 500);
            }
            else
                this.setState({ autograbSearch: false, stock: owned });

        }

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;

            const years = [];
            const makes = [];


            transmissions = [];
            setttings.transmissions && setttings.transmissions.forEach(doc => {
                transmissions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true
                });
            });

            if (owned.make) {
                const types = [];
                const models = [];
                const groups = [];
                let _models = !_.isEmpty(makes.filter(m => m.value === owned.make)[0]) ? makes.filter(m => m.value === owned.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                this.setState({
                    types: _.orderBy(types, ['label'], ['asc']),
                    groups: _.orderBy(groups, ['label'], ['asc']),
                    models: models
                });

            }

            this.setState({
                makes: makes,
                years: years
            });

        }



    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadStock(this.props);

    }

    //#endregion

    //#region CHANGE EVENT STOCK
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.stock);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && mandatoryOwnedFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        let state = Object.assign({}, this.state.stock);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ stock: state }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ stock: state }, () => { this.errorChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.stock);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ stock: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.stock);
        state[name] = e.floatValue;
        this.setState({ stock: state }, () => { this.errorChange(name); });
    }

    handleSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.stock);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        this.setState({ stock: state }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = checked;
        this.setState({ stock: state });
    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.group ? m.group === state.group : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ stock: state, models: models }, () => { this.errorChange(data.name); });

    }

    handleGroupChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.type ? m.type === state.type : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.group ? m.group === state.group : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.group ? m.group === state.group : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        state['model'] = '';
        this.setState({ stock: state, models: models, types: _.orderBy(types, ['label'], ['asc']) }, () => { this.errorChange(data.name); });

    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const types = [];
        const groups = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        state['group'] = '';
        this.setState({
            stock: state,
            types: _.orderBy(types, ['label'], ['asc']),
            groups: _.orderBy(groups, ['label'], ['asc']),
            models: models
        }, () => { this.errorChange(data.name); });

    }


    handleSaveInfo = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.stock);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            if (mandatoryOwnedFields.indexOf(key) >= 0) {
                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        if (fields.warrantyStartDate && fields.warrantyExp && (moment.unix(fields.warrantyStartDate.seconds)._d > moment.unix(fields.warrantyExp.seconds)._d)) {
            formIsValid = false;
            errors.warrantyExp = errorClass;
            Swal.fire(CommonHelper.showLocale(this.props, 'Warranty Expiry date should be greater than Start date'), '', 'info')
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.saveStockInfo(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            document.getElementById(id).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
            return formIsValid;
        }

    };

    saveStockInfo = (e) => {
        this.isLoading = true;
        const objData = Object.assign({}, this.state.stock);
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
            objData.addedDate = window.firebase.firestore.Timestamp.now();
            objData.addedFrom = 'web';
        }
        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;

        let stockID = objData['documentID'];

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created  stock')
        }

        const refStockData = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${this.props.contactID}/ownedVehicles`).doc(stockID);
        refStockData.set(objData).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;
            this.props.handleClose();

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {
        const { src, id, ext } = cropShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            //stock: state,
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        let stockID = this.state.stock.documentID;
        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created one saveImageToStorage')
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}`).child(uid).getDownloadURL()
                    .then(dataURL => {

                        let state = Object.assign({}, this.state.stock);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        state.documentID = stockID;
                        this.setState({ stock: state, imageURL: null })
                    })
            })
    };

    //#endregion

    //#region DATEPICKER

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.stock);
        if (val)
            state[name] = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);
        else
            state[name] = null;
        this.setState({ stock: state }, () => { this.errorChange(name); });

    };

    handleValueDateChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.stock);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ stock: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.stock);
            state[name] = null;
            this.setState({ stock: state });
        }
    }

    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }
    //#endregion

    //#region  SCAN

    handleVehicleScan = (_type) => {
        if (this.autograbEnabled)
            this.setState({ autograbSearch: true });
        else if (this.regoScanEnabled)
            this.setState({ vehicleScan: { show: true, type: _type } })
    }

    handleScanClose = (data) => {
        if (!_.isEmpty(data)) {
            this.bindData(data);
        }
        this.setState({ vehicleScan: { show: false, type: null } })
    }

    bindData = (data) => {
        let state = Object.assign({}, this.state.stock);

        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.yearOfManufacturer) state.year = data.yearOfManufacturer.toString();
        if (data.bodyStyle) state.type = data.bodyStyle;
        if (data.rego) state.regNo = data.rego;
        if (data.vin) state.vinNo = data.vin;
        if (data.primaryColour) state.exteriorColor = data.primaryColour;

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }
                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ stock: state })
    }

    handleAutoGrabClose = (data, _fields) => {
        if (!_.isEmpty(data)) {
            this.bindAutoGrabTradeIn(data, _fields);
        }
        this.setState({ autograbSearch: false })
    }

    bindAutoGrabTradeIn = (_data, _fields) => {
        //console.log('bindAutoGrabTradeIn', _data)

        let state = Object.assign({}, this.state.stock);
        let data = Object.assign({}, _data?.vehicle);

        if (data.make) state.make = data.make;
        if (data.model) state.model = data.model;
        if (data.body_type) state.type = data.body_type;
        if (data.year) state.year = data.year;

        if (data.regNo) state.regNo = data.regNo; else if (_fields?.regNum) state.rego = _fields.regNum;
        if (data.vinNo) state.vinNo = data.vinNo; else if (_fields?.vinNum) state.chassisNo = _fields.vinNum;
        if (_data.colour) state.exteriorColor = _data.colour;

        if (data.transmission_type) {
            const _transmissions = this.props.dealersettings.client.settings.transmissions;
            if (!_.isEmpty(_transmissions) && _transmissions.filter(m => m.name.toLowerCase() === data.transmission_type.toLowerCase())[0]) {
                state.transmission = _transmissions.filter(m => m.name.toLowerCase() === data.transmission_type.toLowerCase())[0]?.value;
            }
            else
                state.transmission = data.transmission_type;
        }

        if (data.id) {
            state.autograbID = data.id;
        }

        const { dealersettings } = this.props;
        if (state.make && dealersettings && dealersettings.client && dealersettings.client.settings) {
            const { makes } = this.state;
            const types = [];
            const models = [];
            const groups = [];
            if (state.make) {
                let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }

                })

                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            this.setState({
                types: _.orderBy(types, ['label'], ['asc']),
                groups: _.orderBy(groups, ['label'], ['asc']),
                models: models
            });
        }

        this.setState({ stock: state })
    }

    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                stock: {
                    ...this.state.stock,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            stock: {
                ...this.state.stock,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }
    //#endregion

    render() {

        const {
            documentID, group,
            make, model, type, currentlyOwned,
            year, regNo, vinNo, exteriorColor, transmission,
            warrantyStartDate, warrantyExp, registrationExpiry
        } = this.state.stock;

        const { isDeivered, show, dealersettings } = this.props;
        const { addMake, addType, addModel, addGroup, autograbSearch, vehicleScan } = this.state;

        return (
            documentID
                ?
                <>
                    <Modal show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        enforceFocus={false}
                        dialogClassName="modal-dialog-centered modal-add-requirement"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title> <Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, (isDeivered ? 'vehicle' : this.props.title), 'addOwned')} /></Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="container-fluid">
                                <div className={`row ${isDeivered ? 'form-disable' : ''}`}>
                                    <div className=" pop-layout form-style requirement-pop">
                                        <>
                                            <div className="requirement-pop-middle">
                                                <div className="requirement-section-head"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehicleInformation', 'ownedInfo')} /> </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        {isDeivered
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                {
                                                                    !addMake ? (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addMake: true
                                                                            })
                                                                            setTimeout(() => {
                                                                                let elem = document.getElementById('make')
                                                                                elem.focus()
                                                                            }, 100)
                                                                        }}>
                                                                        + <Translate text={'add'} />
                                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addMake: false,
                                                                                errors: {
                                                                                    ...this.state.errors,
                                                                                    ['make']: 'input_error'
                                                                                },
                                                                                fields: {
                                                                                    ...this.state.fields,
                                                                                    ['make']: ''
                                                                                }
                                                                            })
                                                                        }}>
                                                                        x <Translate text={'cancel'} />
                                                                    </a>)
                                                                }
                                                            </>}

                                                        <label> <Translate text={'make'} /></label>
                                                        {addMake ?
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'make'}
                                                                className={`form-control ${this.state.errors["make"]}`}
                                                                name="make"
                                                                onChange={this.handleCustomOption}
                                                                value={make}
                                                                id='make'
                                                            /> : <ReactSelect
                                                                options={this.state.makes}
                                                                name={"make"}
                                                                placeholder={'select make'}
                                                                onChange={this.handleMakeChange}
                                                                value={make}
                                                                classNamePrefix={`${this.state.errors["make"]} basic-select`}
                                                                AddUnAvailable={true}
                                                            >
                                                            </ReactSelect>
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        {isDeivered
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                {
                                                                    !addGroup ? (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addGroup: true
                                                                            })
                                                                            setTimeout(() => {
                                                                                let elem = document.getElementById('group')
                                                                                elem.focus()
                                                                            }, 100)
                                                                        }}>
                                                                        + <Translate text={'add'} />
                                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addGroup: false,
                                                                                errors: {
                                                                                    ...this.state.errors,
                                                                                    ['group']: 'input_error'
                                                                                },
                                                                                stock: {
                                                                                    ...this.state.stock,
                                                                                    ['group']: ''
                                                                                }
                                                                            })
                                                                        }}>
                                                                        x <Translate text={'cancel'} />
                                                                    </a>)
                                                                }
                                                            </>}

                                                        <label><Translate text={'group'} /></label>
                                                        {addGroup ?
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'group'}
                                                                className={`form-control ${this.state.errors["group"]}`}
                                                                name="group"
                                                                onChange={this.handleCustomOption}
                                                                value={group}
                                                                id="group"
                                                            /> :
                                                            <ReactSelect
                                                                options={this.state.groups}
                                                                name={"group"}
                                                                placeholder={'select group'}
                                                                onChange={this.handleGroupChange}
                                                                value={group}
                                                                classNamePrefix={`${this.state.errors["group"]} basic-select`}
                                                                AddUnAvailable={true}
                                                            >
                                                            </ReactSelect>}
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        {isDeivered
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                {
                                                                    !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addType: true
                                                                            })
                                                                            setTimeout(() => {
                                                                                let elem = document.getElementById('type')
                                                                                elem.focus()
                                                                            }, 100)
                                                                        }}>
                                                                        + <Translate text={'add'} />
                                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addType: false,
                                                                                errors: {
                                                                                    ...this.state.errors,
                                                                                    ['type']: 'input_error'
                                                                                },
                                                                                fields: {
                                                                                    ...this.state.fields,
                                                                                    ['type']: ''
                                                                                }
                                                                            })
                                                                        }}>
                                                                        x <Translate text={'cancel'} />
                                                                    </a>)
                                                                }
                                                            </>}

                                                        <label><Translate text={'type'} /></label>
                                                        {addType ?
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'type'}
                                                                className={`form-control ${this.state.errors["type"]}`}
                                                                name="type"
                                                                onChange={this.handleCustomOption}
                                                                value={type}
                                                                id='type'
                                                            /> :
                                                            <ReactSelect
                                                                options={this.state.types}
                                                                name={"type"}
                                                                placeholder={'select type'}
                                                                onChange={this.handleTypeChange}
                                                                value={type}
                                                                classNamePrefix={`${this.state.errors["type"]} basic-select`}
                                                                AddUnAvailable={true}
                                                            >
                                                            </ReactSelect>}

                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        {isDeivered
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                {
                                                                    !addModel ? (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addModel: true
                                                                            })
                                                                            setTimeout(() => {
                                                                                let elem = document.getElementById('model')
                                                                                elem.focus()
                                                                            }, 100)
                                                                        }}>
                                                                        + <Translate text={'add'} />
                                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                addModel: false,
                                                                                errors: {
                                                                                    ...this.state.errors,
                                                                                    ['model']: 'input_error'
                                                                                },
                                                                                fields: {
                                                                                    ...this.state.fields,
                                                                                    ['model']: ''
                                                                                }
                                                                            })
                                                                        }}>
                                                                        x <Translate text={'cancel'} />
                                                                    </a>)
                                                                }
                                                            </>}

                                                        <label> <Translate text={'model'} /></label>
                                                        {addModel ?
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'model'}
                                                                className={`form-control ${this.state.errors["model"]}`}
                                                                name="model"
                                                                onChange={this.handleCustomOption}
                                                                value={model}
                                                                id='model'
                                                            /> :
                                                            <ReactSelect
                                                                options={this.state.models}
                                                                name={"model"}
                                                                placeholder={'select model'}
                                                                onChange={this.handleSelectChange}
                                                                value={model}
                                                                classNamePrefix={`${this.state.errors["model"]} basic-select`}
                                                                AddUnAvailable={true}
                                                            >
                                                            </ReactSelect>}

                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'year'} /></label>
                                                        <ReactSelect
                                                            options={this.state.years}
                                                            name={"year"}
                                                            placeholder={'select year'}
                                                            onChange={this.handleSelectChange}
                                                            value={year}
                                                            classNamePrefix={`${this.state.errors["year"]} basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>



                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'vinNo'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {
                                                                isDeivered
                                                                    ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            this.autograbEnabled || this.regoScanEnabled
                                                                                ?
                                                                                <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleVehicleScan('vin')
                                                                                }}>
                                                                                    <i className="ico icon-vin-no"></i>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </>
                                                            }


                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'vin no'}
                                                                className={`form-control ${this.state.errors["vinNo"]}`}
                                                                name="vinNo"
                                                                onChange={this.handleOnCapChange}
                                                                value={vinNo}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'registrationNumber'} /></label>
                                                        <div className="inner-addon right-addon">
                                                            {
                                                                isDeivered
                                                                    ?
                                                                    <>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            this.autograbEnabled || this.regoScanEnabled
                                                                                ?
                                                                                <div className="bg-icons tradein-icons" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.handleVehicleScan('reg')
                                                                                }}>
                                                                                    <i className="ico icon-reg-no "></i>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </>
                                                            }

                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'reg no'}
                                                                className={`form-control ${this.state.errors["regNo"]}`}
                                                                name="regNo"
                                                                onChange={this.handleOnCapChange}
                                                                value={regNo}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="form-row">

                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'warrantyStartDate'} /></label>
                                                        <AntDatePicker
                                                            value={warrantyStartDate ? moment.unix(warrantyStartDate.seconds).format('YYYY-MM-DD') : null}
                                                            name={'warrantyStartDate'}
                                                            onChange={(e) => { this.handleDateChange(e, 'warrantyStartDate') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["warrantyStartDate"]}`}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'warrantyExpiry'} /></label>
                                                        <AntDatePicker
                                                            value={warrantyExp ? moment.unix(warrantyExp.seconds).format('YYYY-MM-DD') : null}
                                                            name={'warrantyExp'}
                                                            onChange={(e) => { this.handleDateChange(e, 'warrantyExp') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["warrantyExp"]}`}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'registrationExpiry'} /></label>
                                                        <AntDatePicker
                                                            value={registrationExpiry ? moment.unix(registrationExpiry.seconds).format('YYYY-MM-DD') : null}
                                                            name={'registrationExpiry'}
                                                            onChange={(e) => { this.handleDateChange(e, 'registrationExpiry') }}
                                                            format='DD/MM/YYYY'
                                                            placeholder='DD/MM/YYYY'
                                                            className={`form-control ${this.state.errors["registrationExpiry"]}`}
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'exteriorColor'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'exterior colour'}
                                                            className={`form-control ${this.state.errors["exteriorColor"]}`}
                                                            name="exteriorColor"
                                                            onChange={this.handleOnChange}
                                                            value={exteriorColor}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label><Translate text={'transmission'} /></label>
                                                        <ReactSelect
                                                            options={transmissions}
                                                            name={"transmission"}
                                                            placeholder={'select transmission'}
                                                            onChange={this.handleSelectChange}
                                                            value={transmission}
                                                            classNamePrefix={`${this.state.errors["transmission"]} basic-select`}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>&nbsp;</label>
                                                        <div className="text-left" >
                                                            <Translate text={'currentlyOwned'} />
                                                            <span className="switch switch-sm float-right">
                                                                <InputCheckBox
                                                                    className="switch"
                                                                    id="currentlyOwned"
                                                                    name="currentlyOwned"
                                                                    checked={currentlyOwned ? true : false}
                                                                    onChange={(e) => { this.handleCheckChange(e) }} />
                                                                <label htmlFor="currentlyOwned"></label>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            isDeivered
                                ?
                                <>
                                </>
                                :
                                <>
                                    <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                                        <button
                                            type="button"
                                            className="btn btn-default float-left"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.handleClose();
                                            }}
                                        ><Translate text={'cancel'} />
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-primary float-right"
                                            onClick={(e) => this.handleSaveInfo(e)}
                                        >
                                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'save'} />
                                        </button>
                                    </Modal.Footer>
                                </>
                        }

                    </Modal>

                    <PopUpModal show={autograbSearch}>
                        <AutoGrabScan
                            show={autograbSearch}
                            clsActive='overlay-modal active'
                            regNo={regNo}
                            chassisNo={vinNo}
                            dealersettings={this.props.dealersettings}
                            handleClose={this.handleAutoGrabClose}
                        >
                        </AutoGrabScan>
                    </PopUpModal>

                    <PopUpModal show={vehicleScan.show}>
                        <VehicleScan
                            show={vehicleScan.show}
                            type={vehicleScan.type}
                            clsActive='overlay-modal active'
                            regNo={regNo}
                            chassisNo={vinNo}
                            dealersettings={this.props.dealersettings}
                            isEvalExpert={false}
                            handleClose={this.handleScanClose}
                        >
                        </VehicleScan>
                    </PopUpModal>

                </>
                :
                <></>

        );
    }
}