import React, { useRef, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash';
import Translate from '../../constants/translate';

const DrivaInfo = ({ financeDetail, handleViewFinanceOpen, handleEditFinanceOpen, isDeivered, currencySymbol, handleAddProviderOpen }) => {
  const [editshow, setEditshow] = useState(false)
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }

    setEditshow(false);
  };

  return (
    <div
      className='vehicle-item finance-item'
    >
      <div className='enquiry-more'>
        {' '}
        <a href='#' onClick={(e) => {
          e.preventDefault();
          setEditshow(!editshow)
          if (editshow)
            document.addEventListener('click', setEditshow(false));
        }}>
          <i className='ico icon-more'></i>
        </a>{' '}
        <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
          <ul className="more-dropdown">
            {
              financeDetail.status !== 'pending' || isDeivered
                ?
                <></>
                :
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); handleEditFinanceOpen(financeDetail) }}>
                    <>
                      <i className="ico icon-edit"></i> <Translate text={'edit'} />
                    </>
                  </a>
                </li>
            }

            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleViewFinanceOpen(financeDetail) }}>
                <i className="ico icon-zoom"></i> <Translate text={'view'} />
              </a>
            </li>

          </ul>
        </div>
      </div>
      <div className='vehicle-item-title'>
        {`${financeDetail?.request?.extra?.vehicleMake} ${financeDetail?.request?.extra?.vehicleModel}`}
      </div>
      <div className='vehicle-item-info'>
        <Translate text={'Finance Amount'} />:{' '}
        <span className='price'>
          {' '}
          <NumberFormat
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={financeDetail?.request?.loan?.amount ? financeDetail.request.loan.amount : 0}
            allowNegative={false}
          />
        </span>
      </div>
      {!isEmpty(financeDetail.status) && (
        <>
            <div className="tradin-item-button" style={{display: 'block'}}>
              <div className={`float-left ml-2 status-${financeDetail.status.toLowerCase() === 'approved' ? 'won' : 'pending'}`}>{financeDetail.status}</div>
            </div>    
        </>
      )}
    </div>
  )
}

export default DrivaInfo
