import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap' 

import { loanVehicleTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import { firestoreDB } from '../../../../services/helper';

const LoanVehicleSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleLoanVehicleOpen,
	handleOpenStock,
	handleTestDriveOpen,
	enableSharedStock,
	getDealerName,
	history,
}) => {
	const [activeTab, setActivetab] = useState('all');
	const [stock, setStock] = useState([]);
	const [testDrive, setTestDrive] = useState([]);
	const [loanVehicles, setLoanVehicles] = useState([]);
	const [loader, setLoader] = useState([]);
	const [all, setAll] = useState([]);

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let refStock
			if (enableSharedStock && dealersettings.client.group) {
				refStock = window.firebase
				.firestore()
				.collection('stock')
				.where('groupID', '==', dealersettings.client.group)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(10);
			} else {
				refStock = window.firebase
				.firestore()
				.collection('stock')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(10);
			}

			let refLoanVehicle = firestoreDB(dealersettings)
				.firestore()
				.collection('fleet')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('startDate', 'desc')
				.limit(10);

			let refTestDrive = firestoreDB(dealersettings)
				.firestore()
				.collection('testdrives')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('startDate', 'desc')
				.limit(10);

			var dashboardPipelines = combineLatest(
				collection(refStock),
				collection(refLoanVehicle),
				collection(refTestDrive),
			)
				.pipe(
					map(([stock, loanVehicles, testDrive]) => {
						return [
							_.map(stock, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'stock',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(loanVehicles, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'loanVehicle',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(testDrive, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'testDrive',
									sortDate: obj.data().addedDate,
								};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					setStock(allDocs[0]);
					setLoanVehicles(allDocs[1]);
					setTestDrive(allDocs[2]);
					setAll([...allDocs[0], ...allDocs[1], ...allDocs[2]]);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }}>
			<div
				className='dropdown-menu dropdown-menu-left'
				aria-labelledby='dropdownMenuButton'
				style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
			>
				<div className='form-style globalsearch-wrap'>
					<div className='globalsearch-nav-wraper'>
						<div className='globalsearch-nav pl-0'>
							<Tab.Container defaultActiveKey={activeTab}>
								<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
									{loanVehicleTabs.map((rec, index) => {
										return (
											<OverlayTrigger
													placement='bottom'
													overlay={
															<Tooltip><span>{rec.title}</span></Tooltip>
													}
												>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
														<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
														{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
										);
									})}
								</Nav>
							</Tab.Container>
						</div>
					</div>
					<div className='globalsearch-body'>
						{loader ? (
							<LoadingDiv />
						) : (
							<div className='global-item-wraper'>
								{activeTab === 'all' ? (
									<>
										{all.length > 0 ? (
											<>
												{_.orderBy(all, ['sortDate.seconds'], ['desc']).map(
													obj =>
														obj.renderType === 'testDrive' ? (
															<a
																className='global-item d-flex'
																href='#'
																onClick={e => {
																	e.preventDefault();
																	handleTestDriveOpen(obj);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-activity-testdrive')}></i>
																	</div>
																</div>
																<div>
																	<span className='global-header'>
																		{`${obj.stock.make} ${obj.stock.model}`}
																	</span>
																	<span className='global-info'>
																		{' '}
																		{`${
																			obj.stock.extColor
																				? obj.stock.extColor + ','
																				: ''
																		} ${obj.stock.year}`}
																		{obj.stock.stockNo
																			? ` - ${obj.stock.stockNo}`
																			: ''}
																	</span>
																	{obj.status && (
																		<div className='global-status'>
																			<div
																				className={`badge badge-pill badge-${obj.status.toLowerCase()}-fill`}
																			>
																				{obj.status}
																			</div>
																		</div>
																	)}
																</div>
															</a>
														) : obj.renderType === 'stock' ? (
															<a
																className='global-item d-flex'
																href='#'
																onClick={e => {
																	e.preventDefault();
																	handleOpenStock(obj.documentID);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
																	</div>
																</div>
																<div>
																	<span className='global-header'>
																		{`${obj.make} ${obj.model}`}
																	</span>
																	<span className='global-info'>
																		{' '}
																		{`${
																			obj.extColor ? obj.extColor + ',' : ''
																		} ${obj.year}`}
																		{obj.stockNo ? ` - ${obj.stockNo}` : ''}
																	</span>
																	{enableSharedStock && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
																	(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
																	{obj.saleType && (
																		<div className='global-status'>
																			<div className='badge badge-pill badge-white'>																				
																				{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                            obj.saleType, '')}
																			</div>
																		</div>
																	)}
																</div>
															</a>
														) : obj.renderType === 'loanVehicle' ? (
															<a
																className='global-item d-flex'
																href='#'
																onClick={e => {
																	e.preventDefault();
																	handleLoanVehicleOpen(obj);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
																	</div>
																</div>
																<div>
																	<span className='global-header'>
																		{`${obj.stock.make} ${obj.stock.model}`}
																	</span>
																	<span className='global-info'>
																		{' '}
																		{`${
																			obj.stock.extColor
																				? obj.stock.extColor + ','
																				: ''
																		} ${obj.stock.year}`}
																		{obj.stock.stockNo
																			? ` - ${obj.stock.stockNo}`
																			: ''}
																	</span>
																	{obj.status && (
																		<div className='global-status'>
																			<div
																				className={`badge badge-pill badge-${
																					obj.status.toLowerCase() === 'completed'
																						? 'completed'
																						: obj.status.toLowerCase()
																				}-fill`}
																			>
																				{obj.status}
																			</div>
																		</div>
																	)}
																</div>
															</a>
														) : (
															<></>
														),
												)}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'loanVehicle' ? (
									<>
										{loanVehicles.length > 0 ? (
											<>
												{loanVehicles.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleLoanVehicleOpen(obj);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.stock.make} ${obj.stock.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${
																	obj.stock.extColor
																		? obj.stock.extColor + ','
																		: ''
																} ${obj.stock.year}`}
																{obj.stock.stockNo
																	? ` - ${obj.stock.stockNo}`
																	: ''}
															</span>
															{obj.status && (
																<div className='global-status'>
																	<div
																		className={`badge badge-pill badge-${
																			obj.status.toLowerCase() === 'completed'
																				? 'completed'
																				: obj.status.toLowerCase()
																		}-fill`}
																	>
																		{obj.status}
																	</div>
																</div>
															)}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'testDrive' ? (
									<>
										{testDrive.length > 0 ? (
											<>
												{testDrive.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleTestDriveOpen(obj);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-activity-testdrive')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.stock.make} ${obj.stock.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${
																	obj.stock.extColor
																		? obj.stock.extColor + ','
																		: ''
																} ${obj.stock.year}`}
																{obj.stock.stockNo
																	? ` - ${obj.stock.stockNo}`
																	: ''}
															</span>
															{obj.status && (
																<div className='global-status'>
																	<div
																		className={`badge badge-pill badge-${obj.status.toLowerCase()}-fill`}
																	>
																		{obj.status}
																	</div>
																</div>
															)}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'stock' ? (
									<>
										{stock.length > 0 ? (
											<>
												{stock.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleOpenStock(obj.documentID);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.extColor ? obj.extColor + ',' : ''} ${
																	obj.year
																}`}
																{obj.stockNo ? ` - ${obj.stockNo}` : ''}
															</span>
															{enableSharedStock && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
																	(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
															{obj.saleType && (
																<div className='global-status'>
																	<div className='badge badge-pill badge-white'>
																	{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                                                            obj.saleType, '')}
																	</div>
																</div>
															)}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : (
									<></>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoanVehicleSearch;
