/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import NumberFormat from 'react-number-format';
//import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
/** COMPONENTS */
import { PopUpModal } from '../../components';
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import MergeContact from './merge'
import EnquiryList from './enquiryList'
/** VIEW-MODELS */
import { objContact } from './viewModel'


//#region  ASSIGN VARIABLES

//#endregion


let languages = [];
let license_state = [];
class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            titles: [],
            showNewOption: false,
            showMergeOption: false,
            showReAuthentication: false,
            showContactEnqList: false,
        }

    }


    //#region PAGE LOAD


    componentDidMount() {
        //console.log('componentDidMount- COMPANY', this.props)
        // const { show, dealersettings, clientUsers, existingContact } = this.props;
        // if (show && existingContact) {

        // }
        const { dealersettings } = this.props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;
            let defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

            const titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            license_state = [];
            defaultCountry && dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });

                });

            this.setState({
                titles: titles
            });
        }
    }

    convertContactVM = (doc) => {

        doc.addedDate = doc.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.addedDate._seconds)._d) : doc.addedDate;
        doc.modifiedDate = doc.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.modifiedDate._seconds)._d) : doc.modifiedDate;

        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({}, objContact);
        for (let [key, value] of Object.entries(objcontactData)) {
            if (contact.hasOwnProperty(key))
                contact[key] = value;
        }
        if (_.isNil(objcontactData?.optinSMS)) contact.optinSMS = null;
        if (_.isNil(objcontactData?.marketingOptinEmail)) contact.marketingOptinEmail = null;
        if (_.isNil(objcontactData?.marketingOptinSMS)) contact.marketingOptinSMS = null;
        // contact.fullName = CommonHelper.getFullName(titles, contact);
        // contact.phone = contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '';
        // contact.displayID = contact.displayID ? contact.displayID : '--';
        // // contact.fullName = (contact.title ? contact.title + ' ' : '') + contact.firstName + ' ' + contact.lastName + (contact.preferredName ? ' (' + contact.preferredName + ')' : '');
        // contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).format('DD/MM/YYYY') : '';
        // // contact.createdOn = moment.unix(contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        // // contact.updatedOn = moment.unix(contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');
        // // contact.company = !_.isEmpty(contact.company) ? contact.company.name : '';
        // contact.dob = contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : '';
        // // contact.secondaryName = contact.secondaryFirstName ? (contact.secondaryFirstName + ' ' + contact.secondaryLastName) : '';
        // // contact.addedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.addedBy);
        // // contact.modifiedBy = CommonHelper.getUserNamebyId(props.clientUsers, contact.modifiedBy);
        // contact.optinPhone = doc.optinPhone === false ? 'Yes' : '';
        // contact.optinEmail = doc.optinEmail === false ? 'Yes' : '';
        // contact.optinPost = doc.optinPost === false ? 'Yes' : '';
        return contact
    }

    //#endregion

    handleSelectOption = (e) => {
        e.preventDefault();
        const { enquiryID, existingContact, isViewOnly, dealersettings, groupUsers, clientUsers, showMerge } = this.props;

        const accessToCreateLeadByContactOwner = (dealersettings?.rolePermissions?.permissions?.accessToCreateLeadByContactOwner) ? true : false;

        if (accessToCreateLeadByContactOwner && enquiryID && existingContact.owner !== localStorage.uid) {
            let _txt = CommonHelper.showLocale(this.props, "Cannot select another owners contact")
            if (existingContact.owner)
                _txt = `${CommonHelper.showLocale(this.props, 'The contact owner is')} ${CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, existingContact.owner)}, ${CommonHelper.showLocale(this.props, "Cannot select another owners contact")}`
            Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.'), _txt, 'info');
            return;
        }

        if (isViewOnly && existingContact && existingContact.documentID) {
            this.props.handleClose(existingContact.documentID, existingContact)
        }
        // else if (isNewEnquiry && existingContact && existingContact.documentID) { // SaMeeR (muzammal 06-09-22)
        //     this.setState({ showContactEnqList: true })
        // }
        else {
            if (showMerge) {
                this.showMergeOption()
            } else {
                this.setState({ showNewOption: true })
            }
        }

    }

    handleCloseEnquiryList = (_enquiryid) => {
        if (_enquiryid) {
            if (_enquiryid === 'new')
                this.setState({ showNewOption: true })
            else {
                this.props.handleClose(_enquiryid);
            }

        }
        this.setState({ showContactEnqList: false })
    }

    handleSelect = (type) => {
        if (type)
            this.props.handleClose(type, this.props.existingContact);
        else
            this.props.handleClose();
        // Swal.fire({
        //     title: "Some of your fields already have values.",
        //     text: " Select any of the following",
        //     showCancelButton: true,
        //     confirmButtonText: 'Replace All',
        //     cancelButtonText: 'Fill missing',
        //     confirmButtonClass: 'replaceAllButton',
        //     cancelButtonClass: 'fillMissingButton',
        //     reverseButtons: true,
        //     allowOutsideClick: false,
        //     allowEscapeKey: false
        // }).then((result) => {
        //     if (result.value) {
        //         this.props.handleClose('replaceAll', this.props.existingContact);
        //         //ReplaceCustomer(rec);

        //     }
        //     else {
        //         this.props.handleClose('fillMissing', this.props.existingContact);

        //     }
        // });
    }

    showMergeOption = (e) => {
        this.setState({ showMergeOption: true })
    }

    closeMergeOption = (obj) => {
        //console.log('closeMergeOption', obj)
        if (!_.isEmpty(obj)) {
            if (this.props.isNewContact)
                this.props.handleClose('replaceAll', obj);
            else
                this.props.handleClose('merge', obj);
        }
        this.setState({ showMergeOption: false })
    }

    //#region RE-AUTHENTICATION
    handleReAuthOpen = () => {
        this.setState({ showReAuthentication: true });
    }

    handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            this.forceUpdate();
        }
        this.setState({ showReAuthentication: false });
    }
    //#endregion

    handleSaveAuditLog = (imagePath) => {
        const { documentID } = this.props.existingContact;
        const { dealersettings } = this.props;
        let _name = CommonHelper.getFullNameWithID(null, this.props.existingContact, '');
        let _objLogData = {
            notes: 'License image viewed for ' + _name,
            type: 'licenseImage',
            subType: 'contactDetails',
            recordId: documentID ? documentID : null,
            contactID: documentID ? documentID : null,
            imageURL: imagePath,
        }

        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
        CommonHelper.saveAuditLog(_objLogData);
    }



    render() {
        //console.log('R E N D E R - C O N T A C T ');
        const { clientID, contactType, title, displayID, firstName, middleName, lastName, preferredName, userImageURL, dob, businessContact,
            gender, maritalStatus, language, isVIP, nationality, address, phones, emails, phoneCode, banLoanVehicle,
            company, designation, contactMethod, licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL, secondaryTitle,
            phone, email, licenseState, secondaryLicenseFrontUrl, secondaryLicenseBackUrl, secondaryFirstName, secondaryLastName, secondaryPhones, secondaryEmails, secondaryPhone, secondaryEmail,
            licenseType, interests, origin, campaign, optinPhone, optinSMS, optinEmail, optinPost, dependents, driverName, owner,
            marketingOptinEmail, marketingOptinSMS
        } = this.props.existingContact;

        const { isReadOnly, isAttendee, show, dealersettings, clientUsers, groupUsers } = this.props;
        const { showNewOption, showMergeOption, titles, showContactEnqList } = this.state;

        const _clientData = clientID && !_.isEmpty(dealersettings) && dealersettings.group &&
            dealersettings.group.clients && dealersettings.group.clients[clientID];
        let _clientSettings = !_.isEmpty(dealersettings) && dealersettings.client && dealersettings.client.settings;

        if (!_.isEmpty(_clientData))
            _clientSettings = _clientData.settings;

        let dealerName = '';
        if (clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
            dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);


        const isPrivacyEnabled = CommonHelper.isPrivacyEnabled(dealersettings);


        return (
            <>

                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-duplicate-contact">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="pop-layout duplicate-contact-container form-style">
                                    <div className="duplicate-contact-head"><Translate text={'personalInfo'} /> </div>
                                    <div className="duplicate-form-wraper">
                                        <div className={`form-row ${(isReadOnly || isAttendee) ? 'form-disable' : ''}`}>

                                            {
                                                (userImageURL)
                                                    ?
                                                    <div className="form-group col-md-6 form-divider">
                                                        <label><Translate text={'userImageURL'} /></label>
                                                        <span className="form-span">
                                                            <div className="mergeview-profileimage">
                                                                <a data-fancybox={`contactInfo`} href={userImageURL} onClick={(e) => { e.preventDefault(); }}>
                                                                    <img src={userImageURL} alt="" className="rounded-circle img-object-fit" />
                                                                </a>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {
                                                (contactType)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'preferredContactType'} /></label>
                                                            <span className={`form-span`}>{contactType}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (displayID)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'contactID'} /></label>
                                                            <span className={`form-span`}>{displayID}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (dealerName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'dealership'} /></label>
                                                            <span className={`form-span`}>{dealerName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (title)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'title'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.titles, title)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (firstName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'firstName'} /></label>
                                                            <span className={`form-span`}>{firstName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (middleName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'middleName'} /></label>
                                                            <span className={`form-span`}>{middleName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (lastName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'lastName'} /></label>
                                                            <span className={`form-span`}>{lastName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (preferredName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'preferredName'} /></label>
                                                            <span className={`form-span`}>{preferredName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (phone)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'mobile'} /></label>
                                                            <span className={`form-span`}>
                                                                {CommonHelper.getFullPhone(phoneCode, phone)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (phones && !_.isEmpty(phones))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'phones'} /></label>
                                                            <span className={`form-span`}>
                                                                <>
                                                                    {
                                                                        phones.map((data, idx) => (
                                                                            data.value
                                                                                ?
                                                                                <div key={idx}>
                                                                                    {data.value}{' [' + data.name + ']'}<br />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        ))
                                                                    }
                                                                </>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (email)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'email'} /></label>
                                                            <span className={`form-span`}>{email}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (emails && !_.isEmpty(emails))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'emails'} /></label>
                                                            <span className={`form-span`}>
                                                                <>
                                                                    {
                                                                        emails.map((data, idx) => (
                                                                            data.value
                                                                                ?
                                                                                <div key={idx}>
                                                                                    {data.value}{' [' + data.name + ']'}<br />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        ))
                                                                    }
                                                                </>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (gender)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'gender'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.genders, gender, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (dob)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'dateOfBirth'} /></label>
                                                            <span className={`form-span`}>{moment(dob).format('DD MMM YYYY')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (owner)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'owner'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getUserNamebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, owner)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (licenseNo)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'licenseNo'} /></label>
                                                            <span className={`form-span`}>{licenseNo}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (licenseExpiry)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'licenseExpiry'} /></label>
                                                            <span className={`form-span`}>{moment(licenseExpiry).format('DD MMM YYYY')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (licenseState)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'licenseState'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getLabelByValue(license_state, licenseState)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (licenseType)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'licenseType'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.licenseType, licenseType, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                isAttendee
                                                    ?
                                                    <></>
                                                    :
                                                    <>
                                                        {
                                                            _.isBoolean(businessContact) ? (
                                                                <div className="form-group col-md-6">
                                                                    <div className="form-divider">
                                                                        <label><Translate text={'businessContact'} /></label>
                                                                        <span className={`form-span`}>{businessContact ? 'Yes' : 'No'}</span>
                                                                    </div>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            _.isBoolean(isVIP) ? (
                                                                <div className="form-group col-md-6">
                                                                    <div className="form-divider">
                                                                        <label><Translate text={'vip'} /></label>
                                                                        <span className={`form-span`}>{isVIP ? 'Yes' : 'No'}</span>
                                                                    </div>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            _.isBoolean(banLoanVehicle) ? (
                                                                <div className="form-group col-md-6">
                                                                    <div className="form-divider">
                                                                        <label><Translate text={'banLoanVehicle'} /></label>
                                                                        <span className={`form-span`}>{banLoanVehicle ? 'Yes' : 'No'}</span>
                                                                    </div>
                                                                </div>
                                                            ) : (<></>)
                                                        }


                                                    </>
                                            }

                                            {
                                                (address)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'address'} /></label>
                                                            <span className={`form-span`}>{address}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (!_.isEmpty(company))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'company'} /></label>
                                                            <span className={`form-span`}>{company.name}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (!_.isEmpty(designation))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'designation'} /></label>
                                                            <span className={`form-span`}>{designation}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (maritalStatus)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'maritalStatus'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.maritalStatus, maritalStatus, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (nationality)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'nationality'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(dealersettings.nationalities, nationality, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (language)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'preferredLanguage'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getLabelByValue(languages, language)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (contactMethod)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'preferredContactMethod'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.contactMethod, contactMethod, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (!_.isEmpty(interests))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'interests'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getLabelByMultipleValues(_clientSettings && _clientSettings.interests, interests, '--', true)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (driverName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'driverName'} /></label>
                                                            <span className={`form-span`}>{driverName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (dependents)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'dependents'} /></label>
                                                            <span className={`form-span`}>{dependents}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryTitle)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryTitle'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.titles, secondaryTitle)}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryFirstName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryFirstName'} /></label>
                                                            <span className={`form-span`}>{secondaryFirstName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryLastName)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryLastName'} /></label>
                                                            <span className={`form-span`}>{secondaryLastName}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryPhone)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryPhone'} /></label>
                                                            <span className={`form-span`}>{secondaryPhone}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryPhones && !_.isEmpty(secondaryPhones))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryPhones'} /></label>
                                                            <span className={`form-span`}>
                                                                <>
                                                                    {
                                                                        secondaryPhones.map((data, idx) => (
                                                                            data.value
                                                                                ?
                                                                                <div key={idx}>
                                                                                    {data.value}{' [' + data.name + ']'}<br />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        ))
                                                                    }
                                                                </>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryEmail)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryEmail'} /></label>
                                                            <span className={`form-span`}>{secondaryEmail}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (secondaryEmails && !_.isEmpty(secondaryEmails))
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'secondaryEmails'} /></label>
                                                            <span className={`form-span`}>
                                                                <>
                                                                    {
                                                                        secondaryEmails.map((data, idx) => (
                                                                            data.value
                                                                                ?
                                                                                <div key={idx}>
                                                                                    {data.value}{' [' + data.name + ']'}<br />
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        ))
                                                                    }
                                                                </>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (origin)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'origin'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.origins, origin, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                (campaign)
                                                    ?
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'campaign'} /></label>
                                                            <span className={`form-span`}>{CommonHelper.getNameByValue(_clientSettings && _clientSettings.campaigns, campaign, '--')}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                _.isBoolean(optinPhone) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'optinPhone'} /></label>
                                                            <span className={`form-span`}>{optinPhone ? 'Yes' : 'No'}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isBoolean(optinSMS) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'optinSMS'} /></label>
                                                            <span className={`form-span`}>{optinSMS === null ? '--' : (optinSMS ? 'Yes' : 'No')}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isBoolean(optinEmail) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'optinEmail'} /></label>
                                                            <span className={`form-span`}>{optinEmail ? 'Yes' : 'No'}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isBoolean(optinPost) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'optinPost'} /></label>
                                                            <span className={`form-span`}>{optinPost ? 'Yes' : 'No'}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isBoolean(marketingOptinEmail) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'marketingOptinEmail'} /></label>
                                                            <span className={`form-span`}>{marketingOptinEmail === null ? '--' : (marketingOptinEmail ? 'Yes' : 'No')}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }
                                            {
                                                _.isBoolean(marketingOptinSMS) ? (
                                                    <div className="form-group col-md-6">
                                                        <div className="form-divider">
                                                            <label><Translate text={'marketingOptinSMS'} /></label>
                                                            <span className={`form-span`}>{marketingOptinSMS === null ? '--' : (marketingOptinSMS ? 'Yes' : 'No')}</span>
                                                        </div>
                                                    </div>
                                                ) : (<></>)
                                            }

                                        </div>
                                    </div>

                                    {
                                        (licenseFrontURL || licenseBackURL || secondaryLicenseFrontUrl || secondaryLicenseBackUrl)
                                            ?
                                            <>
                                                <div className="duplicate-contact-head"><Translate text={'licenseInfo'} /> </div>
                                                <div className="duplicate-form-wraper">

                                                    <div className="form-row ">
                                                        {
                                                            (licenseFrontURL || licenseBackURL)
                                                                ?
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={'primaryID'} /></label>
                                                                    <div className="mergeview-license-img-wraper">
                                                                        {
                                                                            licenseFrontURL
                                                                                ?
                                                                                <div className="mergeview-licenseboxwrap">
                                                                                    <div className="mergeview-licensebox">
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}>
                                                                                                        <img src={_images.lock} alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                    :
                                                                                                    <a data-fancybox={`contactInfo`} href={licenseFrontURL} onClick={(e) => { e.preventDefault(); this.handleSaveAuditLog(licenseFrontURL); }}>
                                                                                                        <img src={licenseFrontURL} alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                            }
                                                                                        </>
                                                                                    </div>
                                                                                    <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {
                                                                            licenseBackURL
                                                                                ?
                                                                                <div className="mergeview-licenseboxwrap">
                                                                                    <div className="mergeview-licensebox">
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                    :
                                                                                                    <a data-fancybox={`contactInfo`} href={licenseBackURL} onClick={(e) => { e.preventDefault(); this.handleSaveAuditLog(licenseBackURL); }}>
                                                                                                        <img src={licenseBackURL} alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                            }
                                                                                        </>

                                                                                    </div>
                                                                                    <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            (secondaryLicenseFrontUrl || secondaryLicenseBackUrl)
                                                                ?
                                                                <div className="form-group col-md-6">
                                                                    <label ><Translate text={'secondaryID'} /></label>
                                                                    <div className="mergeview-license-img-wraper">
                                                                        {
                                                                            secondaryLicenseFrontUrl
                                                                                ?
                                                                                <div className="mergeview-licenseboxwrap">
                                                                                    <div className="mergeview-licensebox">
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                    :
                                                                                                    <a data-fancybox={`contactInfo`} href={secondaryLicenseFrontUrl} onClick={(e) => { e.preventDefault(); this.handleSaveAuditLog(secondaryLicenseFrontUrl); }}>
                                                                                                        <img src={secondaryLicenseFrontUrl} alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                            }
                                                                                        </>

                                                                                    </div>
                                                                                    <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            secondaryLicenseBackUrl
                                                                                ?
                                                                                <div className="mergeview-licenseboxwrap">
                                                                                    <div className="mergeview-licensebox">
                                                                                        <>
                                                                                            {
                                                                                                (isPrivacyEnabled)
                                                                                                    ?
                                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); this.handleReAuthOpen(); }}><img src={_images.lock} alt="" className="img-object-fit" /> </a>
                                                                                                    :
                                                                                                    <a data-fancybox={`contactInfo`} href={secondaryLicenseBackUrl} onClick={(e) => { e.preventDefault(); this.handleSaveAuditLog(secondaryLicenseBackUrl); }}>
                                                                                                        <img src={secondaryLicenseBackUrl} alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                            }
                                                                                        </>

                                                                                    </div>
                                                                                    <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }

                                                    </div>


                                                </div>
                                            </>
                                            :
                                            <></>
                                    }
                                </div >
                            </div >

                        </div >
                    </Modal.Body >
                    {
                        isReadOnly || isAttendee
                            ?
                            <></>
                            :
                            <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                                <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.props.handleClose(null) }}>
                                    <Translate text={'cancel'} />
                                </button>
                                <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSelectOption(e)} >
                                    {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                    <Translate text={'select'} />
                                </button>
                            </Modal.Footer>
                    }

                </Modal >



                <PopUpModal show={showNewOption}>
                    <Modal
                        show={showNewOption}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={showNewOption ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-replace-contact">
                        <Modal.Header>
                            <Modal.Title><Translate text={'Choose Option'} /> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">

                                    <ul className="replace-contact">
                                        {/* {
                                            this.props.isNewContact
                                                ?
                                                <>
                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); this.handleSelect('replaceAll') }}>
                                                        <i className="ico icon-replace"> </i>
                                                        <span className="replace-head"><Translate text={'Replace All'} /></span>
                                                        <span className="replace-sub"><Translate text={'Replace all values with selected contact'} /></span>
                                                    </a>
                                                    </li>

                                                    <li> <a href="#" onClick={(e) => { e.preventDefault(); this.handleSelect('fillMissing') }}>
                                                        <i className="ico icon-fill"> </i>
                                                        <span className="replace-head"><Translate text={'Fill Missing'} /></span>
                                                        <span className="replace-sub"><Translate text={'Fill missing values with selected contact'} /></span>
                                                    </a>
                                                    </li>
                                                </>
                                                :
                                                <></>
                                        } */}

                                        <li> <a href="#" onClick={(e) => { e.preventDefault(); this.showMergeOption(e) }}>
                                            <i className="ico icon-merge"> </i>
                                            <span className="replace-head"><Translate text={'merge'} /></span>
                                            <span className="replace-sub"><Translate text={'Merge existing values with selected contact'} /></span>
                                        </a>
                                        </li>

                                        <li> <a href="#" onClick={(e) => { e.preventDefault(); this.handleSelect() }}>
                                            <i className="ico icon-close-circle"> </i>
                                            <span className="replace-head"><Translate text={'cancel'} /></span>
                                            <span className="replace-sub"><Translate text={'Do nothing'} /></span>
                                        </a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </Modal.Body>


                    </Modal>
                </PopUpModal>

                <PopUpModal show={showMergeOption}>
                    <MergeContact
                        show={showMergeOption}
                        contactA={this.props.newContact}
                        contactB={this.props.existingContact}
                        isNewContact={this.props.isNewContact}
                        handleClose={this.closeMergeOption}
                        title={'Merge Contacts'}
                        dealersettings={this.props.dealersettings}
                    >
                    </MergeContact>
                </PopUpModal>
                <PopUpModal show={this.state.showReAuthentication}>
                    <ReAuthenticate
                        show={this.state.showReAuthentication}
                        handleClose={this.handleReAuthOpenClose}
                        dealersettings={dealersettings}
                    />
                </PopUpModal>

                {/* <PopUpModal show={showContactEnqList}>
                    <EnquiryList
                        show={showContactEnqList}
                        title={'Existing enquiries for the selected contact'}
                        handleClose={this.handleCloseEnquiryList}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        contactID={this.props.existingContact.documentID}
                    />
                </PopUpModal> */}

            </>
        );
    }
}

export default ContactInfo;