import React, { useState, useEffect } from 'react';
import { TableView } from '../../components';
import { allcampaignFields } from '../bulksms/viewModel';
import _ from 'lodash'
import { PopUpModal, ContentOverlay } from '../../components';
import MessagePreview from './preview';
import Translate from '../../constants/translate';
import SMSTemplateList from '../settings/workflow/templates/smstemplateList';

const BulkSMSList = ({ activeTab, campaigns, showAddCampaign, setShowAddCampaign, apiUrl, API_KEY, SERVER_PREFIX, onCreateCampaign, loader, dealersettings, clientUsers, smsTemplates }) => {
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});
	const [showPreview, setShowPreview] = useState({
			show: false,
			previewID: '',
			title: '',
			data: null
	})
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 330,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	const handleTemplateModalClose  = () => {
		setShowPreview({
			show: false,
			previewID: '',
			title: 'SMS Campaign Info',
			data: null
		})
	}

	const handleActionClick = id => {
		let currentCampaign = campaigns.filter(campaign => campaign.documentID === id)[0]

		return (
			<>
			{currentCampaign.status === 'sent' || currentCampaign.status === 'sending' || currentCampaign.status === 'scheduled' ? (
				<div
					style={{ cursor: 'pointer' }}
					className='mini-button mr-1'
					onClick={e => {
						setShowPreview({
							show: true,
							previewID: currentCampaign.documentID,
							title: 'SMS Campaign Info',
							data: currentCampaign
						})
					}}
				>
					<i className='ico icon-zoom'></i>
				</div>
			) : currentCampaign.status === 'draft' ? (
				<div
					style={{ cursor: 'pointer' }}
					className='mini-button mr-1'
					onClick={e => {
						setShowAddCampaign({
							...showAddCampaign,
							show: true,
							title: 'Edit Message',
							campaignID: currentCampaign.documentID,
						})
					}}
				>
					<i className='ico icon-edit'></i>
				</div>

			) : (<></>)}
			</>
		);
	};

	const handleCampaigndetailsClick = (e, id) => {
		e.preventDefault();
		let currentCampaign = campaigns.filter(campaign => campaign.documentID === id)[0]

		if (currentCampaign.status === 'sent' || currentCampaign.status === 'sending' || currentCampaign.status === 'scheduled') {
			setShowPreview({
				show: true,
				previewID: currentCampaign.documentID,
				title: 'SMS Campaign Info',
				data: currentCampaign
			})
		} else if (currentCampaign.status === 'draft') {
			setShowAddCampaign({
				...showAddCampaign,
				show: true,
				title: 'Edit Message',
				campaignID: currentCampaign.documentID,
			})
		}

    // this one will be called when opening a edit or preview/report modal

		// props.history.push("/contacts/details/" + id);
	};

  const filterCampaigns = (campaign) => {
    let returnVal = true

    if (activeTab === 'sent' && campaign.status !== 'sent') {
      returnVal = false
    } else if (activeTab === 'draft' && campaign.status !== 'draft') {
      returnVal = false
    } else if (activeTab === 'sending' && campaign.status !== 'sending') {
      returnVal = false
    }  else if (activeTab === 'scheduled' && campaign.status !== 'scheduled') {
      returnVal = false
    } 
    return returnVal
  }

	const filterSearch = (campaign) => {
		let returnVal = true

    if (searchText.length > 0) {
			if (campaign.content.toLowerCase().includes(searchText.toLowerCase()) || campaign.title.toLowerCase().includes(searchText.toLowerCase())) {
				returnVal = true
			} else {
				returnVal = false
			}
    } 
    return returnVal
	}

	return (
		<div className='bulksms-right-panel'>
			<div className='bulksms-list-container'>
				<h2>{activeTab === 'templates' ? 'SMS Templates' : activeTab === 'send' ? 'Sent' : activeTab === 'draft' ? 'Drafts' : activeTab === 'sending' ? 'Sending' : activeTab === 'scheduled' ? 'Scheduled' : 'All SMS Campaigns'}</h2>	
				<div className='divider-line'></div>
				<div className='bulksms-list-table'>
					<>
					{activeTab === 'templates' ? 
					(<>
						<SMSTemplateList type='smsTemplate' dealersettings={dealersettings} clientUsers={clientUsers} bulkSMS={true} />
					</>) : 
					(<>
						<div className='bulksms-list-filter'>
							<div className='float-left'>
								<div className='filter-search search-icon'>
									<input placeholder='search' aria-label='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
								</div>
							</div>
						</div>
						{loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
							<div className='common-table'>
								{campaigns.length > 0 ? (
									<TableView
										isReorderable={false}
										datalist={_.orderBy(campaigns.filter(campaign => filterCampaigns(campaign)).filter(campaign => filterSearch(campaign)),['modifiedDate'],['desc'])}
										height={windowSize.windowHeight}
										width={windowSize.windowWidth}
										columns={allcampaignFields}
										dynamicFields={allcampaignFields
											.filter(e => e.default === true)
											.map(v => _.pick(v, ['value']).value)}
										settingsLoader={false}
										handleActionClick={handleActionClick}
										handleRowSingleClick={handleCampaigndetailsClick}
										isSettings={false}
										hasMore={false}
										isPaging={false}
									/>
								) : (
									<div className="text-center p-5">
											<p><Translate text={'No sms campaign found'} /></p>
											<button className="btn btn-sm btn-default" onClick={onCreateCampaign}><i className="ico icon-add mr-2 pt-1 float-left"></i>
													<Translate text={'Create SMS Campaign'} />
											</button>
									</div>
								)}
							</div>
						)}
					</>)}
					</>
				</div>
			</div>
			<PopUpModal show={showPreview.show}>
					<MessagePreview
						show={showPreview.show}
						handleClose={handleTemplateModalClose}
						title={showPreview.title}
						campaign_id={showPreview.previewID}
						apiUrl={apiUrl}
						API_KEY={API_KEY}
						SERVER_PREFIX={SERVER_PREFIX}
						data={showPreview.data ? showPreview.data : ''}
						clientUsers={clientUsers}
						clientID={dealersettings.client.id}
					/>
				</PopUpModal>
		</div>
	);
};

export default BulkSMSList;
