/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';

const WalkinInfo = ({ walkin, clientUsers, handleEdit, isDeivered }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        documentID, walkinDate,
        addedBy, addedDate
    } = walkin;


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {

        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };
    return (
        <div className="walkin-wrap">
            <div className="flex-walkin">
                <div className="walkin-more">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setEditshow(!editshow)
                        if (editshow)
                            document.addEventListener('click', setEditshow(false));
                    }}><i className="ico icon-more"></i></a>
                    <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                        <ul className="more-dropdown">
                            <li>
                                <a href="#" onClick={(e) => { e.preventDefault(); handleEdit(documentID) }}>
                                    {
                                        (isDeivered)
                                            ?
                                            <><i className="ico icon-zoom"></i> <Translate text={'view'} /> </>
                                            :
                                            <><i className="ico icon-edit"></i> <Translate text={'edit'} /> </>
                                    }
                                </a>
                            </li>
                            {/* <li> <a href="#" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-delete"></i> <Translate text={'delete'} /> </a> </li> */}
                        </ul>
                    </div>
                </div>
                <div className="walkin-icon"> <i className="ico icon-walkin"></i> </div>
                <div className="walkin-data">
                    <div className="walkin-text">
                        <div className="walkin-title"> {walkinDate ? moment.unix(walkinDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</div>
                        <div className="walkin-date">
                            {CommonHelper.getUserNamebyId(clientUsers, addedBy)} {' - '}
                            {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    )
};
export default WalkinInfo;


