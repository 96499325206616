import React from 'react';
import Translate from '../../constants/translate';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';

const DrivaPreview = ({
	title,
	show,
	handleClose,
	data,
	currencySymbol
}) => {
	return (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-loan-info-pop'
		>
			<Modal.Header closeButton>
				<Modal.Title className='w-100'>
					<h3 className="modal-title" style={{ width: '100%' }}>
						<Translate text={title ? title : 'Finance Info'} />
						{!isEmpty(data.status) && (
							<>
								<div className="float-right kitchen-modal-status">
									<div className={`float-left ml-2 status-${data.status.toLowerCase() === 'approved' ? 'won' : 'pending'}`}>{data.status}</div>
								</div>
							</>
						)}
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='loaninfo-fnc-wrapper'>
					<div className='loaninfo-fnc-wrapper-top'>
						<div className='loaninfo-fnc-wrapper-head'>
							<div className='loaninfo-edit-button'>
								{/* <a href='#' className='mini-button'>
									{' '}
									<i className='ico icon-edit'></i>
								</a> */}
							</div>
							<h3><Translate text={'Loan Info'} /></h3>
						</div>
						<div className='loaninfo-fnc-panel-body'>
							<div className='row'>
								<div className='col-lg-3'>
									<div className='loaninfo-fnc-loop loaninfo-fnc-flex loaninfo-blue h-100'>
										<div className='creditscore-head'><Translate text={'carPrice'} /></div>
										<div className='creditscore-value'>
											<NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={data?.request?.loan?.amount ? data.request.loan.amount : 0}
											/>
										</div>
										{/* <div className='creditscore-head'>Out of 1,200</div> */}
									</div>
								</div>

								<div className='col-lg-9 pl-0'>
									<div className='loaninfo-fnc-loop loaninfo-fnc-flex h-100'>
										<div className='row'>
											<div className='col-lg-4 loaninfo-border-cell'>
												<div className='loaninfo-box-title'><Translate text={'deposit'} /></div>
												<div className='loaninfo-box-value'>
													<NumberFormat
														thousandSeparator={true}
														prefix={currencySymbol}
														displayType={'text'}
														value={data?.request?.loan?.deposit ? data.request.loan.deposit : 0}
													/>
												</div>
											</div>
											<div className='col-lg-4 loaninfo-border-cell'>
												<div className='loaninfo-box-title'><Translate text={'loanLength'} /></div>
												<div className='loaninfo-box-value'>{data?.request?.loan?.term ? data.request.loan.term : ''} <Translate text={'years'} /></div>
											</div>
											<div className='col-lg-4 loaninfo-border-cell'>
												<div className='loaninfo-box-title'><Translate text={'Vehicle Year'} /></div>
												<div className='loaninfo-box-value'>{data?.request?.extra?.vehicleYear ? data.request.extra.vehicleYear : ''}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{!isEmpty(data.status) && data.status === 'pending' && (
						<>
							<div className='loaninfo-table-head'><Translate text={'repaymentOptions'} /></div>
							<div className='common-table loaninfo-table-main mt-3'>
								<table className='table table-bordered'>
									<thead>
										<tr>
											<th scope='col' className='head-light' width='25%'>
												<Translate text={'Providers'} />
											</th>
											<th scope='col' className='head-light text-center' width='20%'>
												<Translate text={'monthlyPayment'} />
											</th>
											<th scope='col' className='head-light text-center' width='15%'>
												<Translate text={'annualRate'} />
											</th>
											<th scope='col' className='head-light text-center' width='10%'>
												<Translate text={'Comparison rate'} />
											</th>
											<th scope='col' className='head-light text-center' width='10%'>
												<Translate text={'Loan length(yrs)'} />
											</th>
											<th scope='col' className='head-light text-center' width='10%'>
												<Translate text={'balloonPayment'} />
											</th>
											<th
												scope='col'
												className='head-light text-center border-right-0'
												width='10%'
											>
												<Translate text={'Actions'} />
											</th>
										</tr>
									</thead>
									<tbody>
										{data?.response.quotes.length && data.response.quotes.map(quote => (
											<tr>
												<td>
													<img src={quote.logo ? quote.logo : ''} alt='' height='60' style={{ maxHeight: '31px', maxWidth: '91px' }} />
												</td>
												<td className='text-center'>
													<NumberFormat
														thousandSeparator={true}
														prefix={currencySymbol}
														displayType={'text'}
														value={quote.monthly}
													/>
												</td>
												<td className='text-center'>{quote.interestRate}%</td>
												<td className='text-center'>{quote.comparisonRate}%</td>

												<td className='text-center'>{data?.request?.loan?.term ? data.request.loan.term : ''} <Translate text={'years'} /></td>
												<td className='text-center'>{quote.balloonPaymentPercentage}%</td>
												<td className='text-center'>
													<div className='loaninfo-table-width-fix'>
														<a
															className='btn btn-sm btn-secondary'
															style={{ 'cursor': 'pointer', 'float': 'right' }}
															href={quote.applyLink}
															target='_blank'
															rel="noopener noreferrer"
														>
															<Translate text={'applyNow'} />
														</a>
													</div>
												</td>
											</tr>
										))}

									</tbody>
								</table>
							</div>
						</>
					)}


				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<a
					type='button'
					className='btn float-right ml-3'
					href={data?.response?.productURL ? data.response.productURL : ''}
					target='_blank'
					rel="noopener noreferrer"
					style={{
						color: '#fff',
						backgroundColor: '#4466f2',
						borderColor: '#2b57e2',
					}}
				>
					<Translate text={'View Details'} />
				</a>
				<button
					type='button'
					className='btn btn-default float-right'
					onClick={e => {
						e.preventDefault();
						handleClose();
					}}
				>
					<Translate text={'Close'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DrivaPreview;
