import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes';

import '../../../../styles/customer-feedbackform.scss';

const PublishForm = ({ show, handleClose, feedbackForm, isFromTableView }) => {
	const [height, setHeight] = useState(isFromTableView ? feedbackForm.iframeHeight : 0);

	useEffect(() => {
		const form = document.querySelector('.dynamicform-embed-previewwrap');
		if (form && form.scrollHeight) {
			setHeight(form.scrollHeight);
		}
	}, []);

	const text = `<object
  data="${window.location.origin}/embed/form/${feedbackForm?.documentID}"
  width="100%"
  height="${height}"
  type="text/html"
  aria-label='iframe form'
  id="FSD-Previewframe"
  scrolling="no"
></object>`;
	const handleClick = () => {
		navigator.clipboard.writeText(text);
		toast.notify('Code copied successfully', {
			duration: 2000,
		});
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-publish-dynamicform'
				enforceFocus={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{'Embed Form'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<p>
								Your form is securely published and ready to be embedded by copy
								and pasting this code in your html file.
							</p>
							<div className='link-share-dynamic-bg'>
								{/* <i className='fa- fa-link mr-1'></i> */}
								<xmp style={{ margin: '0px' }}>{text}</xmp>
							</div>
							<div className='d-inline-block w-100'>
								<button
									type='button'
									className='btn btn-primary float-right'
									onClick={handleClick}
								>
									<i className='fa fa-clone mr-1'></i> Copy Code
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PublishForm;
