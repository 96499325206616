import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import momenttz from 'moment-timezone'
import { InputText, ReactSelect, InputMultipleText, InputCheckBox, ReactTimePicker, AutoComplete } from '../../../../components'
import _images from '../../../../images'
import Translate from '../../../../constants/translate';
const weekDays = [
    { active: true, value: 'sunday', label: 'Sunday' },
    { active: true, value: 'monday', label: 'Monday' },
    { active: true, value: 'tuesday', label: 'Tuesday' },
    { active: true, value: 'wednesday', label: 'Wednesday' },
    { active: true, value: 'thursday', label: 'Thursday' },
    { active: true, value: 'friday', label: 'Friday' },
    { active: true, value: 'saturday', label: 'Saturday' },
]

const AppSettings = (props) => {
    return (<>
        <div className="settings-inner form-style">
            <div className="form-row settings-company pt-2">
                <div className="settings-companyimage">
                    {
                        (props.imageLoader)
                            ?
                            <div className="img-loader">
                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                    <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        (props.dealer.logoURL)
                            ?
                            <a data-fancybox={`logoURL`} href={props.dealer.logoURL} onClick={(e) => { e.preventDefault(); }}>
                                <img className="img-object-fit-contain" src={props.dealer.logoURL} alt="" height="65" />
                            </a>
                            :
                            <img src={_images.nocompany} alt="" height="65" />
                    }
                </div>
                <div className="settings-profile-info">
                    <div className="settings-profile-upload">
                        <label htmlFor="dealer-logoURL" className={`btn-common float-left mr-2 font-600`}>
                            <Translate text={'Choose Picture'} />
                            <input className="fileInput"
                                type="file"
                                name="testee"
                                id="dealer-logoURL"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={(e) => props.onSelectFile(e, 'profile', 'logoURL', 'Client Logo')} />
                        </label>
                        <div className={`settings-delete-button ${_.isEmpty(props.dealer.logoURL) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.deleteLogo();
                            }}> <i className="ico icon-delete"></i></a></div>
                    </div>
                    <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                </div>
            </div>
            <div className="settings-tab-fields-wrap">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'name'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'name'}
                            className={`form-control`}
                            name="name"
                            onChange={props.handleOnChange}
                            value={props.dealer.name}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'email'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'email'}
                            className={`form-control`}
                            name="email"
                            onChange={props.handleOnChange}
                            value={props.dealer.email}
                        />
                    </div>
                </div>
                <div className="form-row">

                    <div className="form-group col-md-6">
                        <label ><Translate text={'phone'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'phone'}
                            className={`form-control`}
                            name="phone"
                            onChange={props.handleNumberChange}
                            value={props.dealer.phone}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Fax'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'fax'}
                            className={`form-control`}
                            name="fax"
                            onChange={props.handleNumberChange}
                            value={props.dealer.fax}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'website'} /></label>
                        <InputText
                            autoComplete="off"
                            placeholder={'website'}
                            className={`form-control`}
                            name="website"
                            onChange={props.handleOnChange}
                            value={props.dealer.website}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Timezone'} /></label>
                        <ReactSelect
                            options={momenttz.tz.names().map(rec => {
                                return {
                                    label: rec, value: rec, active: true,
                                }
                            })}
                            name={"timezone"}
                            placeholder={'select timezone'}
                            onChange={props.handleReactSelectChange}
                            value={props.dealer.timezone}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'address'} /></label>
                        <AutoComplete
                            className={`form-control`}
                            bindAddress={props.bindAddress}
                            types={['address']}
                            value={props.dealer.address}
                            placeholder={'search address'}
                            onChange={props.handleOnChange}
                            name="address"
                        />
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'country'} /></label>
                        <ReactSelect
                            options={!_.isEmpty(props.countries) ? _.map(props.countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } }) : []}
                            name={"country"}
                            placeholder={'select country'}
                            onChange={props.handleReactSelectChange}
                            value={props.dealer.country}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-3">
                        <label ><Translate text={'state'} /></label>
                        <ReactSelect
                            options={
                                !_.isEmpty(props.dealer.country) && !_.isEmpty(props.states) ?
                                    _.map(props.states.filter(item => item.country_code === props.dealer.country), function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })
                                    : []
                            }
                            name={"state"}
                            placeholder={'select state'}
                            onChange={props.handleReactSelectChange}
                            value={props.dealer.state}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Currency'} /></label>
                        <ReactSelect
                            options={!_.isEmpty(props.currencies) ? _.map(props.currencies, function (e) {
                                return {
                                    label: `${e.name} (${e.code})`,
                                    value: e.code,
                                    data: e,
                                    active: true,
                                }
                            }) : []}
                            name={"currency"}
                            placeholder={'select currency'}
                            onChange={props.handleReactSelectChange}
                            value={!_.isEmpty(props.dealer.currency) ? props.dealer.currency.code : ''}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                    <div className="form-group col-md-6">
                        <label ><Translate text={'Week Start'} /></label>
                        <ReactSelect
                            options={weekDays}
                            name={"weekStart"}
                            placeholder={'select week start'}
                            onChange={props.handleReactSelectChange}
                            value={props.dealer.weekStart}
                            classNamePrefix={`cursor-pointer basic-select`}
                            removeClearable={true}
                        >
                        </ReactSelect>
                    </div>
                </div>
            </div>


            <h5 className="settings-subhead">Settings</h5>
            <div className="switch-list">
                <div className='row'>
                    <div className='col-sm-6'>
                        <ul>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="lead-verification-settings"
                                    className="switch"
                                    name={'leadVerification'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.leadVerification) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="lead-verification-settings"></label>
                            </span> Lead Verification</li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="new-next-activity"
                                    className="switch"
                                    name={'isNextActivityMandatoryNE'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.isNextActivityMandatoryNE) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="new-next-activity"></label>
                            </span> Next Activity Mandatory For New Enquiry </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="schedule-next-activity"
                                    className="switch"
                                    name={'isScheduleNextActivity'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.isScheduleNextActivity) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="schedule-next-activity"></label>
                            </span> Schedule Next Activity After Complete </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="enable-inbound-allocation"
                                    className="switch"
                                    name={'inboundAllocation'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.inboundAllocation) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="enable-inbound-allocation"></label>
                            </span> Enable Inbound Allocation </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="enable-pipeline-list"
                                    className="switch"
                                    name={'enablePipelineList'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.enablePipelineList) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="enable-pipeline-list"></label>
                            </span> Enable Pipeline List </li>

                        </ul>
                    </div>
                    <div className='col-sm-6'>
                        <ul>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="current-status-in-report"
                                    className="switch"
                                    name={'calcCurrentStatus'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.calcCurrentStatus) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="current-status-in-report"></label>
                            </span>{'Show Current Won/Sold Status in Reports'}</li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="multiTDperLead-in-report"
                                    className="switch"
                                    name={'multiTDperLead'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.multiTDperLead) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="multiTDperLead-in-report"></label>
                            </span> <Translate text={'Count multiple test drives per lead in all reports'} /> </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="soldTDWalkin-in-report"
                                    className="switch"
                                    name={'soldTDWalkin'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.soldTDWalkin) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="soldTDWalkin-in-report"></label>
                            </span> <Translate text={'Count sold/test drives walkins only in all reports'} /> </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="inclInboundLeadsinRpt-in-report"
                                    className="switch"
                                    name={'inclInboundLeadsinRpt'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.inclInboundLeadsinRpt) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="inclInboundLeadsinRpt-in-report"></label>
                            </span> <Translate text={'Include Inbound Leads in Dashboard'} /> </li>
                            <li> <span className="switch switch-sm mr-2">
                                <InputCheckBox
                                    id="inclInboundBuckeinRpt-in-report"
                                    className="switch"
                                    name={'inclInboundBuckeinRpt'}
                                    checked={!_.isEmpty(props.dealer.clientSettings) ? Boolean(props.dealer.clientSettings.inclInboundBuckeinRpt) : false}
                                    onChange={(e) => { props.handleCheckChange(e) }} />
                                <label htmlFor="inclInboundBuckeinRpt-in-report"></label>
                            </span> <Translate text={'Include Inbound Stage in Dashboard'} /> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AppSettings;