import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText } from '../../../../components'
import { mandSettingsVM, allModules, fleetStatus } from './viewModel'
import 'antd/dist/antd.css';
import { Tree } from 'antd';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
const levels = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
];

const AddFieldSettings = (props) => {

    const [loader, setLoader] = useState(false)
    const [treeData, setTreeData] = useState([])
    const [checkedKeys, setCheckedKeys] = useState([])
    const [disabledKeys, setDisabledKeys] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [selectedField, setSelectedField] = useState(props.selectedField)
    const [errorFields, setErrorFields] = useState({});
    const [levelOptions, setLevelOptions] = useState([])
    const mandFieldName = 'mandatoryFields'

    useEffect(() => {
        let _status = [];
        fleetStatus.forEach(e => {
            _status.push({
                title: e.name,
                key: e.value
            })
        })
        let _treedata = [{
            title: CommonHelper.showLocale(props, 'All Modules'),
            key: 'allModules',
            children: [
                {
                    title: CommonHelper.showLocale(props, 'status'),
                    key: 'allStatus',
                    children: _status
                },
                {
                    title: CommonHelper.showLocale(props, 'Fleet'),
                    key: 'fleetPro',
                },
                {
                    title: CommonHelper.showLocale(props, 'Retailer Event'),
                    key: 'retailerEvent',
                },
                {
                    title: CommonHelper.showLocale(props, 'Ext. Int. Event'),
                    key: 'extIntEvent',
                },
                {
                    title: CommonHelper.showLocale(props, 'Loan Vehicle'),
                    key: 'loanVehicle',
                },
            ]
        }]
        var modules = ['allStatus', props.activeModule]
        if (props.activeModule === 'retailerEvent' || props.activeModule === 'extIntEvent' || props.activeModule === 'loanVehicle')
            modules = [props.activeModule]
        setTreeData([{
            ..._treedata[0],
            children: [..._.filter(_treedata[0].children, (c) => _.indexOf(modules, c.key) >= 0)]
        }])

        setExpandedKeys(modules.some(m => m === 'allStatus') ? ['allModules', 'allStatus'] : ['allModules'])

    }, [props])

    useEffect(() => {
        if (!_.isEmpty(props.selectedField) && !_.isEmpty(props.fields)) {
            let userlevelindex = _.find(levels, { value: selectedField.level }) ? _.find(levels, { value: selectedField.level }).index : 1;
            if (userlevelindex > 0)
                setLevelOptions(levels.filter(l => l.index >= userlevelindex))
            else
                setLevelOptions(levels)
            var checkmodules = []
            var checkdisable = []
            var modules = [];
            modules.push(props.activeModule)
            if (props.activeModule === 'contact') {
                modules = ['contact']
            }
            else if (props.activeModule === 'businessContact') {
                modules = ['businessContact']
            }
            props.fields.forEach(rec => {
                let fieldlevelindex = _.find(levels, { value: rec.level }) ? _.find(levels, { value: rec.level }).index : userlevelindex;
                modules.forEach(m => {
                    if (rec[m] && rec[m].split(',').some(t => t === props.selectedField.value)) {
                        checkmodules.push(m);
                        if (fieldlevelindex < userlevelindex)
                            checkdisable.push(m);
                    }

                })
                if (!_.isEmpty(rec['status'])) {
                    rec['status'].forEach(el => {
                        if (el[props.activeModule] && el[props.activeModule].split(',').some(t => t === props.selectedField.value)) {
                            checkmodules.push(el.name);
                            if (fieldlevelindex < userlevelindex)
                                checkdisable.push(el.name);
                        }

                    });
                }
            })
            setCheckedKeys(checkmodules)
            setDisabledKeys(checkdisable)
            //[{ ...treeData[0],children : [..._.map(treeData[0].children,function(e) { return disabledKeys.indexOf(e.key) >= 0 ? { ...e, disableCheckbox: true } : !_.isEmpty(e.children) ? { ...e,'children' : [..._.map(e.children,function(el) { return  !_.isEmpty(el.children) ? {...el,'children' : [..._.map(el.children,function(ele) { return disabledKeys.indexOf(ele.key) >= 0 ? { ...ele, disableCheckbox: true } : { ...ele }  } )]} : {...el}  })]} : { ...e }  })] }]
        }
    }, [selectedField])

    const onCheck = (keys) => {
        let checkedKeys = _.uniq(_.flatten([keys, disabledKeys]))
        setCheckedKeys(checkedKeys)
        // setSelectedField({
        //     ...selectedField,
        //     ['required']: checkedKeys.length > 0 ? true : false
        // })
    };

    const saveSettings = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(selectedField['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(selectedField.level) && selectedField.level !== 'oem' && _.isEmpty(selectedField['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let mandSettings = {};
        let finalcheckedKeys = _.filter(checkedKeys, (c) => _.indexOf(disabledKeys, c) < 0)
        if (props.fields.find(item => item.levelID === selectedField.levelID)) {
            mandSettings = _.pick(props.fields.find(item => item.levelID === selectedField.levelID), _.keys(mandSettingsVM))
            if (_.isEmpty(mandSettings.pipeline)) mandSettings.pipeline = []
            if (_.isEmpty(mandSettings.status)) mandSettings.status = []
        }
        _.filter(finalcheckedKeys, (v) => _.indexOf(allModules, v) >= 0).forEach(key => {
            if (!_.isEmpty(mandSettings[key])) {
                if (!mandSettings[key].split(',').some(t => t === selectedField.value))
                    mandSettings[key] += "," + selectedField.value;
            }
            else {
                mandSettings[key] = selectedField.value
            }
        })
        _.filter(Object.keys(mandSettings), (v) => _.indexOf(finalcheckedKeys, v) < 0 && v.toLowerCase().includes(props?.activeModule?.toLowerCase()))
            .forEach(key => {
                mandSettings[key] = mandSettings[key].split(',').filter(e => e !== selectedField.value).join(',');
            })

        //status
        _.filter(finalcheckedKeys, (v) => _.indexOf(_.map(fleetStatus, function (e) { return e.value }), v) >= 0).forEach(e => {
            mandSettings['status'] = _.isEmpty(mandSettings['status']) ?
                [{
                    name: e,
                    [props.activeModule]: selectedField.value
                }] :
                mandSettings['status'].some(item => item.name === e) ? [..._.map(mandSettings['status'], function (obj) {
                    return obj.name === e ?
                        {
                            ...obj, [props.activeModule]: (obj[props.activeModule] ?
                                (!obj[props.activeModule].split(',').some(t => t === selectedField.value) ? obj[props.activeModule] += "," + selectedField.value : obj[props.activeModule])
                                : selectedField.value)
                        }
                        : { ...obj };
                })] : [...mandSettings['status'], {
                    name: e,
                    [props.activeModule]: selectedField.value
                }]
        });
        mandSettings.status
            .forEach(key => {
                if (!finalcheckedKeys.some(e => e === key.name) && key[props.activeModule]) {
                    key[props.activeModule] = key[props.activeModule].split(',').filter(e => e !== selectedField.value).join(',');
                }
            })


        setLoader(true)
        let querySet = `clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`;
        if (selectedField.level !== 'oem') {
            querySet = `clientSettings/${props.dealersettings.client.settingsID}/${selectedField.level === 'group' ? 'groups' : selectedField.level === 'region' ? 'regions' : 'clients'}/${selectedField.levelID}/fleetPro/${selectedField.levelID}`;
        }
        window.firebase.firestore().doc(querySet).set({
            mandatoryFields: { ...mandSettings }
        }, { merge: true })
            .then(snapshot => {
                //update settings date and priority
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Fleet Mandatory Settings has been updated',
                    }, { merge: true })
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Fleet Mandatory Settings has been updated',
                    }, { merge: true })
                //end
                setLoader(false)
                toast.notify('Field settings updated successfully.', {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    }

    // const handleRequiredChange = (e) => {
    //     setSelectedField({
    //         ...selectedField,
    //         [e.target.name]: e.target.checked
    //     })
    //     if (!e.target.checked) {
    //         setCheckedKeys([])
    //     }
    //     else {
    //         setCheckedKeys(['allModules'])
    //     }
    // }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setSelectedField({
                ...selectedField,
                [data.name]: e.value,
                ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : ''
            });
        }
        else {
            setSelectedField({
                ...selectedField,
                [data.name]: e.value
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    return _.isEmpty(selectedField) ? (<></>) : (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-fields"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="form-style w-100">
                        {
                            selectedField.level !== 'oem' ?
                                (<div className="alert alert-info field-alert" role="alert">
                                    <div className="field-alert-list"><strong>{selectedField.level === 'region' ? 'Region' : selectedField.level === 'group' ? 'Group' : 'Client'} : </strong> {selectedField.levelName} </div>
                                </div>) : (<></>)
                        }

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label > <Translate text={'Field Name'} /></label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'field name'}
                                    className={`form-control`}
                                    name="fieldName"
                                    onChange={() => { }}
                                    value={selectedField.name}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="required-filed-wraper">
                            <div className="required-filed-header">
                                <div className="float-left">
                                    <b> <Translate text={'Required'} /></b>
                                </div>
                                <div className="float-right"><i className="ico icon-info icon-grey mt-1 float-right"></i></div>
                            </div>

                            <div className="required-filed-items">
                                <Tree
                                    checkable
                                    expandedKeys={expandedKeys}
                                    checkedKeys={checkedKeys}
                                    onCheck={onCheck}
                                    treeData={treeData}
                                    onExpand={onExpand}
                                    autoExpandParent={autoExpandParent}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveSettings(e)}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>);
}

export default AddFieldSettings;
