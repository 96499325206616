export const allcampaignFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'statusToShow',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allrecipientFields = [
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Send Time',
        value: 'sendTime',
        flex: 1,
        default: true,
    }
]

export const reportContactFields = [
    {
        name: 'Id',
        value: 'IdRecipient',
        flex: 1,
        default: true,
    },
    {
        name: 'Email',
        value: 'Email',
        flex: 1,
        default: true,
    }
]


export const alleditrecipientFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true
    },
    {
        name: 'First Name',
        value: 'firstName',
        flex: 2,
        default: true,
    },
    {
        name: 'Middle Name',
        value: 'middleName',
        flex: 1,
        default: true
    },
    {
        name: 'Last Name',
        value: 'lastName',
        flex: 1,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        width: 75,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allreportFields = [
    {
        name: 'Name',
        value: 'name',
        flex: 2,
        default: true,
    },
    {
        name: 'Stats',
        value: 'stats',
        flex: 2,
        default: true,
    },
    {
        name: 'Last Sent',
        value: 'lastSent',
        flex: 1,
        default: true
    },
    {
        name: 'Status',
        value: 'statusToShow',
        flex: 2,
        default: true,
    },
    {
        name: 'Settings',
        value: 'settings',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]