
export const objFiles = {
    documentID: null,
    enquiryID: null,
    contactID: null,
    name: '',
    url: '',
    category: '',
    type: '',
    size: 0,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};


export const mandatoryFields = ['url']