import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'
import Swal from 'sweetalert2';
import moment from 'moment';
import toast from 'toasted-notes'

import CommonHelper from '../../services/common';
import { PopUpModal } from '../../components';
import ReactExport from 'react-export-excel-xlsx-fix';
import Translate from '../../constants/translate';
import { TableView } from '../../components';
import { alldisplayrecipientFields } from './viewModel';
import RecipientReply from './recipientReply'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const DisplayRecipients = ({ campaignID, title, show, handleClose, data, campaign, API_KEY, API_SECRET }) => {
  const [displayRecipients, setDisplayRecipients] = useState({
    show: false,
    data: null
  });
  const [loader, setLoader] = useState(true)
  const [recipients, setRecipients] = useState([])
  const [hasMore, setHasMoreData] = useState([])

  const [windowSize, setWindowSize] = useState({
    windowWidth: 960,
    windowHeight: window.innerHeight - 293,
  });

  const [csvHeader, setHeader] = useState([])
  const [csvData, setCSVData] = useState([])
  const csvBtn = useRef();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 960,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  useEffect(() => {

    var _headers = _.map(alldisplayrecipientFields, function (e) {
      return {
        label: e.name,
        key: !_.isEmpty(e.elementName) ? e.elementName : e.value
      }
    });
    _.filter(alldisplayrecipientFields, (v) => !_.isEmpty(v.subText)).forEach(rec => {
      var index = _.findIndex(_headers, (e) => {
        return e.key == rec.value;
      }, 0)
      if (index > 0) {
        _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
      }
      else {
        _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
      }
    })
    setHeader(_headers);

  }, [])


  useEffect(() => {
    const fetchData = async () => {
      //console.log('fetching data', data)
      let recipientsToAdd = []
      try {
        const objData = {
          "campaign": campaign,
          "messagemedia": {
            api_key: API_KEY,
            api_secret: API_SECRET
          }
        }
        const getSMSCampaignDetails = window.firebase.functions().httpsCallable('messagemedia-getCampaignDetail');
        const campaignDetail = await getSMSCampaignDetails(objData);
        if (campaignDetail?.data?.data?.messages?.length) {
          campaignDetail.data.data.messages.forEach(message => {
            if (message.status)
              recipientsToAdd.push(convertDataVM({
                ...message,
                replies: campaignDetail.data.data.messages
                  .filter(a => a.in_response_to === message.message_id)
                  .map(r => {
                    return {
                      date: window.firebase.firestore.Timestamp.fromDate(new Date(r.timestamp)),
                      text: r.content
                    }
                  })
              }));
          })
          if (title.includes('Delivered')) {
            recipientsToAdd = recipientsToAdd.filter(a => a.status === 'delivered')
          } else if (title.includes('Failed')) {
            recipientsToAdd = recipientsToAdd.filter(a => a.status === 'failed')
          } else if (title.includes('Replies')) {
            recipientsToAdd = recipientsToAdd.filter(a => a.isReply === true)
          }
          setRecipients(recipientsToAdd)
          setLoader(false)
        } else {
          setRecipients(recipientsToAdd)
          setLoader(false)
        }
        // let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients')
        // if (title.includes('Delivered')) {
        //   ref = ref.where('status', '==', 'delivered')
        // } else if (title.includes('Failed')) {
        //   ref = ref.where('status', '==', 'failed')
        // } else if (title.includes('Replies')) {
        //   ref = ref.where('isReply', '==', true)
        // }
        // const snapshots = await ref.limit(100).get()
        // if (!snapshots.empty) {
        //   snapshots.forEach((doc) => {
        //     recipientsToAdd.push(convertDataVM({ ...doc.data() }))
        //   });
        //   setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
        // }
        // setRecipients(recipientsToAdd)
        // setLoader(false)
      } catch (error) {
        console.log(error);
        setLoader(false)
      }
    }

    fetchData()
  }, [])

  const convertDataVM = (doc) => {
    const listVM = Object.assign({}, doc);

    listVM.fullName = _.find(listVM.metadata, { key: 'name' })?.value || '';

    let _notes = ''
    !_.isEmpty(listVM.replies) && listVM.replies.forEach(rec => {
      _notes = _notes.concat(rec.text + ' - ' + moment.unix(rec.date.seconds).format('DD/MM/YYYY h:mm A') + '\n')
    })
    listVM.replyNotes = _notes;
    listVM.isReply = !_.isEmpty(listVM.replies);
    listVM.status = listVM.status === 'DELIVERED' ? 'delivered' : 'failed';
    listVM._phone = listVM.destination_address;
    listVM.csvPhone = listVM.destination_address;
    listVM.content = listVM.content;
    listVM._replyCount = !_.isEmpty(listVM.replies) ? <>
      <a href="#" onClick={(e) => { e.preventDefault(); handleReplyOpen(listVM) }}>
        <div className="badge-sms-reply"><i className="ico icon-sms"> </i> {listVM.replies.length} Reply</div>
      </a>
    </> :
      <>
        <a href="#" onClick={(e) => { e.preventDefault(); }}>
          <div className="badge-sms-reply disable"><i className="ico icon-sms"> </i> 0 Reply</div>
        </a>
      </>
    return listVM;
  }

  const fetchMoreData = async () => {
    // if (recipients.length === data.recipientsCount) {
    //   return
    // }
    // let recipientsToAdd = []
    // let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients').startAfter(hasMore[0]).limit(100)
    // const snapshots = await ref.get()
    // if (!snapshots.empty) {
    //   snapshots.forEach((doc) => {
    //     recipientsToAdd.push(convertDataVM({ ...doc.data() }))
    //   });
    //   setHasMoreData([snapshots.docs[snapshots.docs.length - 1]])
    //   // recipientsToAdd = recipientsToAdd.filter(a => data.recipientIDs.some(id => id === a.documentID))
    //   setRecipients(_.uniqBy([...recipients, ...recipientsToAdd], 'phone'))
    // }
  }

  const handleActionClick = id => {
  };
  const handleRowSingleClick = () => {
  }
  const filterByType = (recs) => {
    let filteredRecs = [...recs]
    if (title.includes('Delivered')) {
      filteredRecs = recs.filter(a => a.status === 'delivered')
    } else if (title.includes('Failed')) {
      filteredRecs = recs.filter(a => a.status === 'failed')
    } else if (title.includes('Replies')) {
      filteredRecs = recs.filter(a => a.isReply === true)
    }

    return filteredRecs
  }

  const handleReplyOpen = (_info) => {
    setDisplayRecipients({ show: true, data: _info })
  }

  const excelDownload = async (smsRecipients) => {
    let total = smsRecipients.length;
    // if (total >= 100) {
    //   toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {'downloading...'}</div>),
    //     {
    //       position: 'top',
    //       duration: null
    //     },
    //   )
    //   let recipientsToAdd = []
    //   let ref = window.firebase.firestore().collection('smsCampaigns').doc(campaignID).collection('smsRecipients')
    //   if (title.includes('Delivered')) {
    //     ref = ref.where('status', '==', 'delivered')
    //   } else if (title.includes('Failed')) {
    //     ref = ref.where('status', '==', 'failed')
    //   } else if (title.includes('Replies')) {
    //     ref = ref.where('isReply', '==', true)
    //   }
    //   const snapshots = await ref.get()
    //   if (!snapshots.empty) {
    //     snapshots.forEach((doc) => {
    //       recipientsToAdd.push(convertDataVM({ ...doc.data() }))
    //     });
    //   }
    //   setCSVData(CommonHelper.generateExcelData(recipientsToAdd, csvHeader));
    //   setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
    // }
    // else {
    setCSVData(CommonHelper.generateExcelData(smsRecipients, csvHeader));
    setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
    //}

    toast.closeAll();
    Swal.fire({
      icon: 'success',
      title: 'Data downloaded successfully.',
      showConfirmButton: false,
      timer: 1500
    })
  }

  let smsRecipients = filterByType(recipients);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName='modal-dialog modal-dialog-centered modal-delivered-sms'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={title} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">


              <div className="common-table">
                {
                  loader
                    ?
                    <>
                      <div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>

                      </div>
                    </>
                    :
                    <>
                    </>
                }
                <TableView
                  isReorderable={false}
                  datalist={smsRecipients}
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={alldisplayrecipientFields}
                  dynamicFields={alldisplayrecipientFields
                    .filter(e => e.default === true)
                    .map(v => _.pick(v, ['value']).value)}
                  settingsLoader={false}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleRowSingleClick}
                  isSettings={false}
                  hasMore={hasMore}
                  isPaging={true}
                  handlePagination={fetchMoreData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          {
            !_.isEmpty(smsRecipients) && (<>
              <button
                type='button'
                className='btn btn-primary float-right ml-2 '
                onClick={(e) => {
                  e.preventDefault();
                  excelDownload(smsRecipients)
                }}
              >
                <i className="ico icon-excel-download mr-2 mt-1"></i>
                <Translate text={'Excel Download'} />
              </button>
              <ExcelFile
                ref={csvBtn}
                element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                filename={`${data?.title || 'SMS'}_${title}_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                <ExcelSheet dataSet={csvData} name={`Sheet1`} />
              </ExcelFile>
            </>
            )
          }

          <button
            type='button'
            className='btn btn-default float-right ml-2 '
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'Close'} />
          </button>
        </Modal.Footer>
      </Modal>
      <PopUpModal show={displayRecipients.show}>
        <RecipientReply
          show={displayRecipients.show}
          handleClose={() => { setDisplayRecipients({ show: false, data: null }) }}
          title={displayRecipients.title}
          data={displayRecipients.data}
        />
      </PopUpModal>
    </>
  );
};

export default DisplayRecipients;
