import React from 'react';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { InputCheckBox, InputText } from '../../../../components';
import moment from 'moment';


const StockSettings = (props) => {
    return (<>
        <div className="settings-tab-fields-wrap">
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email From'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email from'}
                        className={`form-control ${props.errorFields["emailFrom"]}`}
                        name="emailFrom"
                        id={"emailFrom"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.emailFrom || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email Subject'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email subject'}
                        className={`form-control ${props.errorFields["emailSubject"]}`}
                        name="emailSubject"
                        id={"emailSubject"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.emailSubject || ''}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email Subject Used'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email subject used'}
                        className={`form-control ${props.errorFields["emailSubjectUsed"]}`}
                        name="emailSubjectUsed"
                        id={"emailSubjectUsed"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.emailSubjectUsed || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email Subject Others'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email subject others'}
                        className={`form-control ${props.errorFields["emailSubjectOthers"]}`}
                        name="emailSubjectOthers"
                        id={"emailSubjectOthers"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.emailSubjectOthers || ''}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Parser'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'parser'}
                        className={`form-control ${props.errorFields["parser"]}`}
                        name="parser"
                        id={"parser"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.parser || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'Time (Mins)'} /></label>
                    <InputText
                        autoComplete="off"
                        placeholder={"enter min"}
                        className={`form-control `}
                        name="time"
                        onChange={(e) => {
                            props.handleQuoteNumberChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.time}
                        id={`time`}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Skip Keywords'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'skip kw'}
                        className={`form-control ${props.errorFields["skipKW"]}`}
                        name="skipKW"
                        id={"skipKW"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.skipKW || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'Updated On'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'updated On'}
                        className={`form-control ${props.errorFields["updatedOn"]}`}
                        name="updatedOn"
                        id={"updatedOn"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.updatedOn?.seconds ? moment.unix(props?.dealer?.clientSettings?.stockUpdate?.updatedOn?.seconds).format('DD/MM/YYYY hh:mm A') : ''}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label ><Translate text={'Task'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'task'}
                        className={`form-control ${props.errorFields["task"]}`}
                        name="task"
                        id={"task"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'stockUpdate')
                        }}
                        value={props?.dealer?.clientSettings?.stockUpdate?.task || ''}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <span className="switch switch-sm mr-2">
                        <InputCheckBox
                            id="stock-update-unavailable"
                            className="switch"
                            name={'unavailable'}
                            checked={Boolean(props?.dealer?.clientSettings?.stockUpdate?.unavailable)}
                            onChange={(e) => { props.handleQuoteCheckChange(e, 'stockUpdate') }} />
                        <label htmlFor="stock-update-unavailable"><Translate text={'Unavailable '} /></label>
                    </span>
                </div>
            </div>
        </div>
    </>);
}

export default StockSettings;