/** LIBRARIES */
import * as React from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2';
/** COMPONENTS */
import CommonHelper from '../../services/common'
import { PopUpModal } from '../../components';
/** VIEW-MODELS */
import { enquiryStatus, serviceStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

const StageContext = React.createContext()


const fieldsData = {
    contact: '',
    requirement: '',
    enquiry: '',
    company: ''
}

export const StageConsumer = StageContext.Consumer

export class StageProvider extends React.Component {
    constructor(props) {
        super(props);
        this.mandatoryFields = [];
        this.state = {
            filter: {},
            tradeins: {},
            pipeline: [],
            isLoading: false,
            columns: [],
            clientid: '',
            hasMore: [],
            pageNum: 0,
            pageLimit: 10,
            stageLoading: '',
            showNotifier: false,
            notifierMsg: '',
            notifierInfo: '',
            sectionloading: false,
            checkLoad: {},
            isFilterApplied: false,
            stageLoader: [],
            stageCounts: {},
            enquiryModal: {
                show: false,
                enquiry: {},
                errors: null,
                origin: {}
            },
            selectedEnquiry: {},
            searchText: (localStorage.wholesalerSearchText ? localStorage.wholesalerSearchText : ''),
        };

        //this.listenScrollEvent = this.listenScrollEvent.bind(this);
    }

    componentDidMount() {
        if (this.state.isLoading) {
            if (localStorage.wholesalerPipelineFilter && this.state.isFilterApplied === false)
                this.setState({ isFilterApplied: true }, () => { this.loadTradeinPro() })
            else
                this.loadTradeinPro()

            const { dealersettings } = this.props;
            // if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            //     const setttings = dealersettings.client.settings;
            //     if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
            //         this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

            //     enquiryOptions = [];
            //     setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
            //         enquiryOptions.push({
            //             value: doc.value,
            //             label: doc.name,
            //         });
            //     });

            //     enquiryOptionsDF = [];
            //     if (setttings.enquiryOptionsDF)
            //         enquiryOptionsDF = setttings.enquiryOptionsDF;
            // }
            this.refreshStageCount()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading) {
            this.loadTradeinPro()
        }
        if (JSON.stringify(prevState.columns) !== JSON.stringify(this.state.columns)) {
            this.refreshStageCount()
        }
    }

    componentWillUnmount() {
        this.state.pipeline.forEach(p => {
            p.stages.forEach(s => {
                window['unsubTradein' + p.value + s.value] && window['unsubTradein' + p.value + s.value]();
            })
        })
        window.unsubWholesalerTIPPipelineCount && window.unsubWholesalerTIPPipelineCount();
    }

    refreshStageCount = () => {
        if (window.location.pathname !== '/wholesaler')
            return

        const { pipeline } = this.state;
        const { dealersettings } = this.props;
        let _selectedPipeline = pipeline.find(item => item.default === true)
        var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && _dpl) {
            try {

                // let _searchObject = {
                //     "pipeline": _dpl,
                //     "owner": localStorage.uid,
                //     "settingsID": dealersettings.oemID ? dealersettings.oemID : null
                // }
                // _searchObject.clientID = dealersettings.client.id;
                // //_searchObject = CommonHelper.setClientOrGroupOrRegionOrOemID(_searchObject, dealersettings, null, null, true);

                // const stageCountsBQ = window.firebase.functions().httpsCallable('tradeins-stageCountsBQ');
                // stageCountsBQ(_searchObject).then((response) => {
                //     console.log('stageCountsBQ', _dpl, response);
                // });
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    isValidFilter = () => {
        if (localStorage.wholesalerPipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.wholesalerPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    loadTradeinPro = async (_isPaging) => {
        try {
            if (window.location.pathname !== '/wholesaler')
                return

            const { columns, pipeline, pageLimit, pageNum, searchText } = this.state;
            const { dealersettings } = this.props;
            let _selectedPipeline = pipeline.find(item => item.default === true);
            var _dpl = _selectedPipeline ? _selectedPipeline.value : '';

            // const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
            //     !_.isEmpty(dealersettings.rolePermissions) &&
            //     !_.isEmpty(dealersettings.rolePermissions.servicePermissions) &&
            //     dealersettings.rolePermissions.servicePermissions.viewOtherEnquiry) ? true : false);
            const viewOtherEnquiry = false; //Sameer
            let _pageNum = pageNum;

            //console.log('loadTradeinPro', pipeline, columns);


            let clientID = dealersettings.client ? dealersettings.client.id : '';
            let tradeins = this.state.tradeins
            if (_.isEmpty(this.state.tradeins)
                || JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)
                || (this.isValidFilter() && _pageNum === 0)
            ) {
                //console.log('EMPTY tradeins', isFilterApplied, _pageNum, Object.keys(this.state.tradeins).length, JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns))
                tradeins = {}
                _pageNum = 0
                this.setState({ tradeins: tradeins, pageNum: 0 })
            }

            //get total pipeline count by stages
            if ((!searchText && !localStorage.wholesalerPipelineFilter))
                this.getPipelineCount(viewOtherEnquiry, _dpl)

            //let _hasMore = false;
            let i = 0;
            columns.forEach((_rec) => {

                let lastRecord = this.state.hasMore.filter(e => e.Stage === _dpl + _rec);
                if (JSON.stringify(_.map(this.state.tradeins.columns, function (item) { if (item.pipeline === pipeline.find(item => item.default === true).value) return item.id; }).filter(item => !_.isEmpty(item)).join(",").split(',')) !== JSON.stringify(columns)) {
                    lastRecord = []
                }


                if (this.isValidFilter()) {
                    let _pipelineFilter = {};

                    if (localStorage.wholesalerPipelineFilter)
                        _pipelineFilter = JSON.parse(localStorage.wholesalerPipelineFilter);

                    let _filter = Object.assign({}, _pipelineFilter.value);

                    // _filter.pipeline = _dpl;
                    _filter.stage = _rec;

                    if (_pageNum === 0 || lastRecord.length > 0) {
                        if ((_pageNum === 0 || lastRecord.length > 0) && i === 0) {
                            i++;
                            _pageNum = (pageNum + 1);
                            this.setState({ pageNum: _pageNum })
                        }
                    }

                    _filter.wholeSellerId = localStorage.uid;

                    /* DATE RANGE FILTER */
                    if (!_.isEmpty(_filter.date)) {
                        _filter = CommonHelper.handleDateRangeFilter(_filter, dealersettings);
                        delete _filter['date'];
                    }

                    // if (_filter.rottenDays &&
                    //     !_.isEmpty(dealersettings) &&
                    //     !_.isEmpty(dealersettings.client) &&
                    //     !_.isEmpty(dealersettings.client.settings) &&
                    //     !_.isEmpty(dealersettings.client.settings.pipelines)) {
                    //     _filter.pipelineSettings = JSON.stringify(dealersettings.client.settings.pipelines)
                    // }

                    //_filter.clientID = clientID;
                    _filter.timezone = !_.isEmpty(dealersettings.client.timezone) ? dealersettings.client.timezone : moment.tz.guess();


                    let _searchObject = {
                        "type": "searchTradeinoffers",
                        "filters": JSON.stringify(_filter),
                        "sortOrder": "addedDate desc",
                        "pageNum": pageNum,
                        "pageLimit": pageLimit
                    }
                    _searchObject.clientID = clientID;

                    const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
                    searchEnquiries(_searchObject).then((response) => {
                        //console.log('generic-searchData', _rec, response);
                        if (response.data.success && this.isValidFilter()) {
                            this.setState({
                                stageCounts: {
                                    ...this.state.stageCounts,
                                    [_rec]: {
                                        total: response.data.total
                                    }
                                }
                            })
                            this.onCollectionEnquiry(response.data, _rec);
                            //     if (_pageNum === 0)
                            //         this.setState({ isLoading: columns[columns.length - 1] === _rec ? false : true, })
                        }
                    });

                    window['unsubTradein' + _dpl + _rec] && window['unsubTradein' + _dpl + _rec]();
                    window.unsubWholesalerTIPPipelineCount && window.unsubWholesalerTIPPipelineCount()
                }
                else {
                    var enquiryQuery = '';
                    let searchKeywords = searchText;
                    
                    if (localStorage.wholesalerPipelineFilter) {
                        let _pipelineFilter = JSON.parse(localStorage.wholesalerPipelineFilter);
                        let _filter = Object.assign({}, _pipelineFilter.value);

                        searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;
                       

                        this.setState({ searchText: searchKeywords });
                        localStorage.wholesalerSearchText = searchKeywords;
                        //console.log('search---Keywords', searchKeywords, searchText)
                    }


                    enquiryQuery = firestoreDB(dealersettings).firestore().collectionGroup('offers')
                        .where('wholeSellerId', '==', localStorage.uid)

                    if (searchKeywords) {
                        let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                        if (formattedSearchText.trim())
                            enquiryQuery = enquiryQuery.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())

                        enquiryQuery = enquiryQuery
                            .orderBy('addedDate', 'desc');
                    }
                    else {
                        
                        enquiryQuery = enquiryQuery
                            .where('status', '==', _rec)
                            .orderBy('addedDate', 'desc');
                    }


                    if (lastRecord.length > 0) {
                        enquiryQuery = enquiryQuery
                            .startAfter(lastRecord[0].lastDoc)
                            .limit(pageLimit)
                    }
                    else {
                        enquiryQuery = enquiryQuery
                            .limit(pageLimit)
                    }

                    window['unsubTradein' + _dpl + _rec] = enquiryQuery.onSnapshot(this.onCollectionUpdate)
                }

            });

            // if (_hasMore || (isFilterApplied && _pageNum === 0)) {
            //     _pageNum = (pageNum + 1);
            //     this.setState({ pageNum: _pageNum })
            // }
            //})
            if (Object.keys(tradeins).length === 0) {
                tradeins['tasks'] = {};
                let cols = {}
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];

                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    tradeins.tasks && Object.keys(tradeins.tasks).map(function (el) {
                        if (tradeins.tasks[el].stage === col.value) {
                            leadsids.push(tradeins.tasks[el].id)
                        }
                    })
                    cols[col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids
                    }
                })
                tradeins['columns'] = cols
            }

            this.setState({
                isLoading: false,
                checkLoad: {
                    ...this.state.checkLoad,
                    [_dpl]: true
                }
            }, () => {
                if (_isPaging) {
                    document.querySelector(".pipeline-panel").scrollTo({
                        top: document.querySelector(".pipeline-panel").scrollHeight,
                        behavior: "smooth"
                    });
                }
            })
        }
        catch (error) {
            this.setState({
                isLoading: false,
                stageLoading: ''
            })
            console.error(error)
        }
    }

    getPipelineCount = (viewOtherEnquiry, pipeline) => {
        const { dealersettings } = this.props;
        var querySet = `users/${localStorage.uid}/counts/wholesaler`

        // if (!viewOtherEnquiry) {
        //     querySet = `users/${localStorage.uid}/userClients/${dealersettings.client.id}`
        // }
        window.unsubWholesalerTIPPipelineCount = window.firebase.firestore().doc(querySet)
            .onSnapshot(docSnapshot => {

                if (docSnapshot.exists && docSnapshot.data().tradeinPro && docSnapshot.data().tradeinPro['appraisal'] && docSnapshot.data().tradeinPro['appraisal'].stages) {
                    var stageCount = {};
                    let _selectedPipeline = this.state.pipeline.find(item => item.default === true);
                    _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                        stageCount = {
                            ...stageCount,
                            [stage.value]: {
                                ...docSnapshot.data().tradeinPro['appraisal'].stages[stage.value],
                                ['total']: this.sumObj(docSnapshot.data().tradeinPro['appraisal'].stages[stage.value])
                            }
                        }
                    })
                    this.setState({
                        stageCounts: stageCount
                    })
                }
            })
    }

    sumObj = (obj) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum;
    }

    onCollectionUpdate = (querySnapshot) => {
        const { dealersettings } = this.props;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(async (change) => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                //console.log(change)
                let _data = change.doc.data()
                let _defaultPipeline = 'Appraisal';
                let _defaultStage = _data.status;


                snapshotDoc = this.convertDataVM({
                    ...change.doc.data(),
                    documentID: change.doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                });
                if (change.type === 'removed') {
                    const enqDoc = await firestoreDB(dealersettings).firestore().collectionGroup(`offers`).where('documentID', '==', change.doc.id).limit(1).get();
                    if (enqDoc.size > 0 && enqDoc.docs[0].data().wholeSellerId === dealersettings.id) {
                        snapshotDoc = this.convertDataVM({ ...enqDoc.docs[0].data(), documentID: enqDoc.docs[0].id });
                    }
                    else {
                        snapshotDoc.isDeleted = true;
                    }
                }
                this.updateEnquiry(snapshotDoc);
            }
        })
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _tradeins = this.state.tradeins
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            var _dplStage = '';
            if (querySnapshot.docs.length > 0)
                _dplStage = _dpl + querySnapshot.docs[0].data().status;
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {

                hasMoreData = [...hasMoreData.filter(e => e.Stage !== _dplStage), {
                    Stage: _dplStage,
                    docID: querySnapshot.docs[querySnapshot.docs.length - 1].data().documentID,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }]
            }

            querySnapshot.forEach(doc => {
                let _data = doc.data()
                let _defaultPipeline = 'Appraisal';
                let _defaultStage = _data.status;

                const _enq = {
                    ...doc.data(),
                    documentID: doc.id,
                    pipeline: _defaultPipeline,
                    stage: _defaultStage
                }
                result[doc.id] = this.convertDataVM(_enq)
                if (_tradeins.tasks) {
                    _tradeins['tasks'][doc.id] = { ...result[doc.id] }

                } else {
                    _tradeins['tasks'] = result
                }

                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }

                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value && _tradeins.tasks[el].pipeline === _dpl) {
                            leadsids.push(_tradeins.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _tradeins.tasks[el].modifiedDate ? moment().diff(moment.unix(_tradeins.tasks[el].modifiedDate.seconds), 'days') : 0
                                _tradeins.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            })
            if (querySnapshot.docs.length === 0) {
                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value) {
                            leadsids.push(_tradeins.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            }

            this.setState({
                tradeins: _tradeins,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dplStage),
                hasMoreData[0]] : (querySnapshot.docs.length > 0 ? [...hasMore.filter(item => item.Stage !== _dplStage)] : [...hasMore])
            })

        }
    }

    onCollectionEnquiry = (querySnapshot, _stage) => {
        //console.log('onCollectionEnquiry', _stage, querySnapshot)
        let snapshotDoc;
        const { dealersettings } = this.props;
        if (_.isEmpty(snapshotDoc)) {
            const { hasMore, pageLimit, pipeline } = this.state;
            let _tradeins = this.state.tradeins
            let result = {}
            let hasMoreData = []
            let _selectedPipeline = pipeline.find(item => item.default === true)
            let _dpl = _selectedPipeline ? _selectedPipeline.value : '';
            if (this.state.stageLoading) this.setState({ stageLoading: '' })
            if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
                hasMoreData.push({
                    Stage: _dpl + querySnapshot.data[0].status,
                    lastDoc: querySnapshot.data[querySnapshot.data.length - 1]
                })
            }
            querySnapshot.data.forEach(doc => {
                const _enq = doc;
                result[doc.documentID] = this.convertDataVM(_enq)
                if (_tradeins.tasks) {
                    _tradeins['tasks'][doc.documentID] = { ...result[doc.documentID] }

                } else {
                    _tradeins['tasks'] = result
                }

                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value && _tradeins.tasks[el].pipeline === _dpl) {
                            leadsids.push(_tradeins.tasks[el].id)
                            if (col.rottenDays) {
                                var enqage = _tradeins.tasks[el].modifiedDate ? moment().diff(moment.unix(_tradeins.tasks[el].modifiedDate.seconds), 'days') : 0
                                _tradeins.tasks[el].rottendays = enqage - col.rottenDays
                            }
                        }

                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            })
            if (querySnapshot.data.length === 0) {
                let cols = {}
                if (_tradeins.columns) {
                    cols = _tradeins.columns
                }
                let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
                _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                    var leadsids = [];
                    _tradeins.tasks && Object.keys(_tradeins.tasks).map(function (el) {
                        if (_tradeins.tasks[el].stage === col.value) {
                            leadsids.push(_tradeins.tasks[el].id)
                        }
                    })
                    cols[_dpl + col.value] = {
                        id: col.value,
                        title: col.name,
                        taskIds: leadsids,
                        pipeline: _dpl
                    }
                })
                _tradeins['columns'] = cols
            }

            this.setState({
                //isLoading: columns[columns.length - 1] === _stage ? false : true,
                tradeins: _tradeins,
                hasMore: hasMoreData[0] ? [...hasMore.filter(item => item.Stage !== _dpl + querySnapshot.data[0].status), hasMoreData[0]] : [...hasMore.filter(item => item.Stage !== _dpl + _stage)]

            })
        }
    }


    convertDataVM = (_enq) => {

        _enq.stageDate = _enq.stageDate && _enq.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.stageDate._seconds)._d) : _enq.stageDate;
        _enq.addedDate = _enq.addedDate && _enq.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.addedDate._seconds)._d) : _enq.addedDate;
        _enq.modifiedDate = _enq.modifiedDate && _enq.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.modifiedDate._seconds)._d) : _enq.modifiedDate;

        _enq.completedDate = _enq.completedDate && _enq.completedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.completedDate._seconds)._d) : _enq.completedDate;
        _enq.wonDate = _enq.wonDate && _enq.wonDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.wonDate._seconds)._d) : _enq.wonDate;
        _enq.lostDate = _enq.lostDate && _enq.lostDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_enq.lostDate._seconds)._d) : _enq.lostDate;

        _enq.stageHistory = []
        _enq.pipeline = 'Appraisal';
        _enq.stage = _enq.status;

        const { pipeline } = this.state;
        const { clientUsers, groupUsers, dealersettings } = this.props;
        var rottenDays = ''
        let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(col => {
            if (_enq.stage === col.value) {
                if (col.rottenDays) {
                    var enqage = _enq.modifiedDate ? moment().diff(moment.unix(_enq.modifiedDate.seconds), 'days') : 0
                    rottenDays = enqage - col.rottenDays + 'Days'
                }
            }
        })

        const objData = Object.assign({}, _enq);
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        let _tradeinPro = _enq && _enq.tradeinPro ? _enq.tradeinPro : {};

        return {
            id: _enq.documentID,
            displayID: _tradeinPro.displayID ? _tradeinPro.displayID : _tradeinPro.documentID,
            contactID: _tradeinPro.contact ? _tradeinPro.contact.documentID : '',
            name: _tradeinPro.contact ? CommonHelper.displayContactName([], _tradeinPro.contact) : '',
            businessContact: _tradeinPro.contact && _tradeinPro.contact.businessContact ? true : false,
            contactType: _tradeinPro.contact && _tradeinPro.contact.contactType ? _tradeinPro.contact.contactType : null,
            phone: (_tradeinPro.contact && _tradeinPro.contact.phone) ? _tradeinPro.contact.phone : '',
            phoneCode: (_tradeinPro.contact && _tradeinPro.contact.phoneCode ? (_tradeinPro.contact.phoneCode) : ''),
            email: _tradeinPro.contact ? (_tradeinPro.contact.email) : '',
            vehiclemodel: !_.isEmpty(_tradeinPro.tradein) ? (!_.isEmpty(_tradeinPro.tradein.make) ? (_tradeinPro.tradein.make + ' ' + _tradeinPro.tradein.model) : '') : '',
            vinNo: !_.isEmpty(_tradeinPro.tradein) ? (_tradeinPro.tradein.vinNo) : '',
            regNo: !_.isEmpty(_tradeinPro.tradein) ? (_tradeinPro.tradein.regNo) : '',
            stage: _enq.stage,
            status: _enq.status,
            pipeline: _enq.pipeline,
            label: _enq.label,
            isFav: _enq.isFav,
            isNewTradein: _enq.isNewTradein,
            ownerId: _tradeinPro.owner, owner: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _tradeinPro.owner),
            addedby: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _enq.addedBy),
            modifiedby: CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), _enq.modifiedBy),
            enquiryage: (_enq.addedDate ? (moment.unix(_enq.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_enq.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
            stageHistory: _enq.stageHistory,
            stageDate: _enq.stageDate ? _enq.stageDate : _enq.addedDate,
            modifiedDate: _enq.modifiedDate,
            counts: _enq.counts,
            rottenDays: rottenDays,
            isDeleted: _enq.isDeleted,
            addedDate: _enq.addedDate,
            enqFields: objData,
            tradeinPro: _tradeinPro,
            clientID: _enq.clientID ? _enq.clientID : null,
            clientName: _enq.clientID ? CommonHelper.getUserNamebyId(dealersettings.wholesalerclients, _enq.clientID) : null
        }
    }

    handleEnquiryFav = (id, val, stage, oldstage, _objTradeIP, _fieldName) => {
        //    console.log('handleEnquiryFav', id, val, stage, oldstage, _objTradeIP, _fieldName)


    }

    static getDerivedStateFromProps(props, state) {

        const { dealersettings } = props;
        //console.log('dealersettings', dealersettings)

        let pathname = window.location.pathname;
        if ((pathname.indexOf('wholesaler') >= 0) && pathname.indexOf('wholesaler/list') < 0) {
            document.getElementsByTagName('body')[0].className = '';
        }

        if (props.dealersettings.client.settings.wholesalerPipelines.length === 0) {
            return {
                isLoading: false
            }
        }

        let _selectedPipeline = props.dealersettings.client.settings.wholesalerPipelines[0];
        let nextColumns = Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(',')
        if ((JSON.stringify(nextColumns) !== JSON.stringify(state.columns)
            || props.dealersettings.client.id !== state.clientid)) {
            //props.dealersettings.ref = "";
            return {
                columns: Array.prototype.map.call((_selectedPipeline ? _selectedPipeline.stages : []), function (item) { return item.value; }).join(",").split(','),
                pipeline: props.dealersettings.client.settings.wholesalerPipelines,
                clientid: props.dealersettings.client.id,
                isLoading: true
            }
        }
        else {
            return {
                isLoading: false
            }
        }

    }

    loadMoreData = () => {
        if (this.state.hasMore.length === 0) return;

        this.setState({
            stageLoading: this.state.hasMore[0].Stage
        })
        this.loadTradeinPro(true);
    }

    // listenScrollEvent(e) {
    //     if (this.state.hasMore.length === 0) return;

    //     if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
    //         this.setState({
    //             stageLoading: this.state.hasMore[0].Stage
    //         })
    //         this.loadTradeinPro()
    //     }
    //     return;
    // }

    updateEnquiry = (_objTradeIP) => {

        //console.log(_objTradeIP.id, _objTradeIP);
        const { tradeins, pipeline } = this.state;
        const { } = this.props;
        if (_.isEmpty(tradeins))
            return

        _objTradeIP.stageDate = _objTradeIP.stageDate && _objTradeIP.stageDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.stageDate._seconds)._d) : (_objTradeIP.stageDate ? _objTradeIP.stageDate : _objTradeIP.addedDate);

        _objTradeIP.addedDate = _objTradeIP.addedDate && _objTradeIP.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.addedDate._seconds)._d) : _objTradeIP.addedDate;
        _objTradeIP.modifiedDate = _objTradeIP.modifiedDate && _objTradeIP.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(_objTradeIP.modifiedDate._seconds)._d) : _objTradeIP.modifiedDate;

        _objTradeIP.stageHistory = [..._.map(_objTradeIP.stageHistory, function (obj) {
            return obj.date._seconds ? { ...obj, 'date': window.firebase.firestore.Timestamp.fromDate(moment.unix(obj.date._seconds)._d) } : obj;
        })]


        if (_objTradeIP.isDeleted === true && tradeins.tasks[_objTradeIP.id]) {
            const oldpipipeline = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage] ? tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds : []
            const index = oldpipipeline.indexOf(_objTradeIP.id)
            oldpipipeline.splice(index, 1);

            delete tradeins['tasks'][_objTradeIP.id]
        }
        else if (tradeins.tasks[_objTradeIP.id] && tradeins.tasks[_objTradeIP.id].pipeline === _objTradeIP.pipeline) {
            if (!Object.keys(tradeins.tasks).some(enq => enq === _objTradeIP.id)) {
                tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds.splice(0, 0, _objTradeIP.id)
            }
            else if (tradeins.tasks[_objTradeIP.id].stage !== _objTradeIP.stage) {
                const oldsatge = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage] ? tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds : []
                const newstage = tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage] ? tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds : []
                const index = oldsatge.indexOf(_objTradeIP.id)
                if (index > -1) {
                    oldsatge.splice(index, 1);
                }
                newstage.splice(0, 0, _objTradeIP.id)
            }

            let rDays = 0;
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0]
            _selectedPipeline && _selectedPipeline.stages.forEach(col => {
                if (_objTradeIP.stage === col.value) {
                    if (col.rottenDays) {
                        var enqage = _objTradeIP.modifiedDate ? moment().diff(moment.unix(_objTradeIP.modifiedDate.seconds), 'days') : 0
                        rDays = enqage - col.rottenDays
                    }
                }
            })

            tradeins['tasks'][_objTradeIP.id] = {
                ..._objTradeIP,
                enquiryage: (_objTradeIP.addedDate ? (moment.unix(_objTradeIP.addedDate.seconds).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? '0 day' : moment.unix(_objTradeIP.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                rottendays: rDays
            }
        }
        else {
            let _selectedPipeline = pipeline.filter(item => item.default === true)[0];
            if (_selectedPipeline && _selectedPipeline.value === _objTradeIP.pipeline && !_.isEmpty(tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage])) {
                const newstage = tradeins.columns[_objTradeIP.pipeline + _objTradeIP.stage].taskIds
                newstage.splice(0, 0, _objTradeIP.id)

                tradeins['tasks'][_objTradeIP.id] = {
                    ..._objTradeIP,
                    enquiryage: (_objTradeIP.addedDate ? (moment.unix(_objTradeIP.addedDate.seconds).format('/DD/MM/YYYY') === moment().format('/DD/MM/YYYY') ? '0 day' : moment.unix(_objTradeIP.addedDate.seconds).fromNow().replace('ago', '').replace('a month', '1 month')) : '--'),
                    rottendays: 0
                }
            }
            else if (tradeins.tasks[_objTradeIP.id]) {
                const oldpipipeline = tradeins.columns[tradeins.tasks[_objTradeIP.id].pipeline + tradeins.tasks[_objTradeIP.id].stage].taskIds
                const index = oldpipipeline.indexOf(_objTradeIP.id)
                oldpipipeline.splice(index, 1);

                delete tradeins['tasks'][_objTradeIP.id]
            }
        }

        this.setState({ tradeins })
    }


    handleApplyFilter = (_filter) => {
        //console.log('handleApplyFilter', _filter)
        let _selectedPipeline = this.state.pipeline.filter(item => item.default === true)[0];
        _selectedPipeline && _selectedPipeline.stages.forEach(s => {
            window['unsubTradein' + _selectedPipeline.value + s.value] && window['unsubTradein' + _selectedPipeline.value + s.value]();
        })
        window.unsubWholesalerTIPPipelineCount && window.unsubWholesalerTIPPipelineCount();

        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('wholesalerPipelineFilter', JSON.stringify(_filter));
            localStorage.wholesalerSearchText = '';
            this.setState({ isFilterApplied: true, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {}, searchText: '' }, () => { this.loadTradeinPro() })
        }
        else {
            this.handleClearFilter();
        }

    }

    handleClearFilter = () => {
        //console.log('handleClearFilter',) 
        if (!_.isEmpty(localStorage.wholesalerPipelineFilter)) {
            localStorage.removeItem('wholesalerPipelineFilter');
            localStorage.wholesalerSearchText = '';
            this.setState({ isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {}, searchText: '' }, () => { this.loadTradeinPro() })
        }
    }

    handlesearchText = (val) => {
        localStorage.removeItem('wholesalerPipelineFilter');
        this.setState({ searchText: val, isFilterApplied: false, isLoading: true, pageNum: 0, hasMore: [], tradeins: {}, stageCounts: {} }, () => { this.loadTradeinPro() })
    }

    handlePageClear = () => {
        localStorage.removeItem('wholesalerPipelineFilter');
        this.setState({ pageNum: 0, hasMore: [] })
    }

    //#region MANDATORY PERMISSIONstage, oldstage, _objTradeIP.enqFields


    handleOpenEnquiry = (documentID) => {
        this.props.history.push('/wholesaler/details/' + documentID);
    }



    //#endregion

    handlePipelineChange = (value) => {
        //this.props.updateDealerSettings('tradeinPipeline', value);
        this.handlePageClear();
    }

    render() {

        const { children, dealersettings } = this.props
        const {
            columns, pipeline, tradeins, hasMore,
            isLoading, stageLoading, showNotifier, notifierMsg, notifierInfo,
            sectionloading,
            pageNum, isFilterApplied, stageCounts, searchText,
        } = this.state


        //console.log('E N Q U I R I E S', stageCounts)
        return (
            <>
                <StageContext.Provider
                    value={{
                        tradeins,
                        pipeline,
                        columns,
                        isLoading,
                        handleEnquiryFav: this.handleEnquiryFav,
                        loadMoreData: this.loadMoreData,
                        stageLoading,
                        showNotifier,
                        notifierMsg,
                        notifierInfo,
                        updateEnquiry: this.updateEnquiry,
                        sectionloading,
                        handleApplyFilter: this.handleApplyFilter,
                        handleClearFilter: this.handleClearFilter,
                        pageNum,
                        isFilterApplied,
                        stageCounts,
                        searchText,
                        hasMore,
                        handlesearchText: this.handlesearchText,
                        dealersettings: this.props.dealersettings,
                        handlePageClear: this.handlePageClear,
                        handlePipelineChange: this.handlePipelineChange
                    }}
                >
                    {children}
                </StageContext.Provider>

            </>
        )
    }

}