/** LIBRARIES */
import React, { Component } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
/** PROVIDERS */
import { ReactSelect, InputAutoNumber, AntDatePicker } from '../../../components';
import { LayoutConsumer } from '../../layout/provider';
import { PipelineConsumer } from './PipelineProvider';
import { StageConsumer } from '../provider';
import FilesCategory from '../../files/multiple'
import ActivityTab from './activityTab'
import FinanceTab from './financeTab'
import AfterMarketTab from './afterMarketTab'
import QuickActivityTab from './quickActivityTab'
import RealatedTab from './relatedTab'
import Requirements from '../../requirement/list';
import Translate from '../../../constants/translate';
import CommonHelper from "../../../services/common";
import { defaultFinanceTerms } from '../../../services/enum';
import FileDocuments from '../../files/documents';

class QuickTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'sales'
        }
        this.scrollRef = React.createRef()
    }
    updateDimensions() {
        var tabHeight = this.props.enquiry && this.props.enquiry.label ? (window.innerHeight - 250) : (window.innerHeight - 215)
        var activitytabHeight = (window.innerHeight - 300)
        document.documentElement.style.setProperty('--quicktabHeight', tabHeight + 'px');
        document.documentElement.style.setProperty('--activitytabHeight', activitytabHeight + 'px');
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    render() {
        const { activeTab } = this.state;
        return (
            <LayoutConsumer>
                {({ clientUsers, groupUsers }) => (
                    <StageConsumer>
                        {({ updateEnquiry, handleLeadConvertShow }) => (
                            <PipelineConsumer>
                                {({ enquiry, contact, isEmptyPipeline, errors, handleReactSelectChange, handleDateChange,
                                    saveEnquiryInfo, loading, customerlogList, activitiesList,
                                    enquiryBasicVM, isDeivered, canModifyEnquiry,
                                    isValidForStageUpdate, newStage, handleStageUpdate,
                                    requirements, handleReqEdit, handleReqMarkAsPrimary,
                                    requirementSoldMark, requirementAccess, clientsettings,
                                    subLeadAccess, soldIDs, handleCheckSubLeads,
                                    decrementQuantity, incrementQuantity, primaryReqID, deleteRequirement,
                                    handleRequirementOpen, handleStatusDateOpen, handleStatusOptions,
                                    enquiryAllStatus, isStatusOpen, modules, isValidForFinanceStatusUpdate,
                                    accessToFinanceTab, handleFinanceInfoOpen, financeAllStatues, accessToFinanceActivity,
                                    accessToAfterMarketTab, allAfterMarketUsers, afterMarketStatuses, accessToAfterMarketActivity,
                                    accessToProduct, filesCategory, handleFileOpen, files, currencySymbol,
                                    financeProviders, handleFirebaseDateChange, saveEnquiryField, cancelEnquiryField, onCurrencyChange
                                }) => loading ? (<div><Translate text={'Loading...'} /></div>) : (
                                    <>
                                        <div className="quickview-contentarea">
                                            <section className="tabs">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="tabs-wraper">
                                                            <Tabs defaultActiveKey="logs" transition={false} id="noanim-tab-example" className="nav-fill" onSelect={(key) => {
                                                                //console.log('onSelect', key)
                                                                if (['activity', 'logs'].includes(key)) this.setState({ activeTab: 'sales' })
                                                            }}>
                                                                <Tab eventKey="logs" title="Activity Logs" className="related-tab-scroll">
                                                                    <>
                                                                        {
                                                                            (accessToFinanceTab || accessToAfterMarketTab)
                                                                                ?
                                                                                <>
                                                                                    <div className="mid-quickview-tabber-wrap">
                                                                                        <div className="nav nav-tabs nav-fill" id="nav-logs-tab" role="tablist">
                                                                                            <a href="#" className={`nav-item nav-link ${activeTab === 'sales' ? 'active' : ''}`}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.setState({ activeTab: 'sales' })

                                                                                                }}><Translate text={'sales'} /> </a>

                                                                                            {
                                                                                                accessToFinanceTab && (<a href="#" className={`nav-item nav-link ${activeTab === 'finance' ? 'active' : ''}`}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        this.setState({ activeTab: 'finance' })
                                                                                                    }}
                                                                                                > <Translate text={'finance'} /> </a>)
                                                                                            }

                                                                                            {
                                                                                                accessToAfterMarketTab && (<a href="#" className={`nav-item nav-link ${activeTab === 'afterMarket' ? 'active' : ''}`}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        this.setState({ activeTab: 'afterMarket' })
                                                                                                    }}
                                                                                                > <Translate text={'afterMarket'} /> </a>)
                                                                                            }



                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {
                                                                            activeTab === 'finance' && (
                                                                                <>
                                                                                    <FinanceTab originType={'logs'} />

                                                                                    {
                                                                                        accessToFinanceActivity
                                                                                            ?
                                                                                            <>
                                                                                                <RealatedTab
                                                                                                    enquiry={enquiry}
                                                                                                    clientUsers={clientUsers}
                                                                                                    groupUsers={groupUsers}
                                                                                                    customerlogList={customerlogList}
                                                                                                    dealersettings={clientsettings}
                                                                                                    history={this.props.history}
                                                                                                    clientModule={this.props.clientModule}
                                                                                                    isValidForActivity={this.props.isValidForActivity}
                                                                                                    originType={activeTab}
                                                                                                    dialNumber={this.props.dialNumber}
                                                                                                    sidepanelClose={this.props.sidepanelClose}
                                                                                                ></RealatedTab>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>)}

                                                                        {
                                                                            activeTab === 'afterMarket' && (
                                                                                <>
                                                                                    <AfterMarketTab />

                                                                                    {
                                                                                        accessToAfterMarketActivity
                                                                                            ?
                                                                                            <>
                                                                                                <RealatedTab
                                                                                                    enquiry={enquiry}
                                                                                                    clientUsers={clientUsers}
                                                                                                    groupUsers={groupUsers}
                                                                                                    customerlogList={customerlogList}
                                                                                                    dealersettings={clientsettings}
                                                                                                    history={this.props.history}
                                                                                                    clientModule={this.props.clientModule}
                                                                                                    isValidForActivity={this.props.isValidForActivity}
                                                                                                    originType={activeTab}
                                                                                                    dialNumber={this.props.dialNumber}
                                                                                                    sidepanelClose={this.props.sidepanelClose}
                                                                                                ></RealatedTab>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>)}

                                                                        {
                                                                            activeTab === 'sales' && (
                                                                                <>
                                                                                    {clientsettings?.client?.clientSettings?.hideSalesDoc ? <></> : <div className="mid-quickview-finance-info">
                                                                                        {
                                                                                            (filesCategory.filter(e => e.module === activeTab)[0]) && (
                                                                                                <div className="quickview-column-sales-documents">
                                                                                                    <h2><Translate text={"Documents"} /></h2>
                                                                                                    <div className="quickview-column-finance-documents-inner">
                                                                                                        <FileDocuments
                                                                                                            filesCategory={filesCategory}
                                                                                                            activeTab={activeTab}
                                                                                                            handleFileOpen={handleFileOpen}
                                                                                                            files={files}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>}
                                                                                    <RealatedTab
                                                                                        enquiry={enquiry}
                                                                                        clientUsers={clientUsers}
                                                                                        groupUsers={groupUsers}
                                                                                        customerlogList={customerlogList}
                                                                                        dealersettings={clientsettings}
                                                                                        history={this.props.history}
                                                                                        clientModule={this.props.clientModule}
                                                                                        isValidForActivity={this.props.isValidForActivity}
                                                                                        originType={['enquiry'].includes(localStorage.defaultModule) ? null : 'enquiry'}
                                                                                        dialNumber={this.props.dialNumber}
                                                                                        sidepanelClose={this.props.sidepanelClose}
                                                                                    ></RealatedTab>
                                                                                </>)
                                                                        }

                                                                    </>


                                                                </Tab>
                                                                <Tab eventKey="activity" title="Schedule Activity" className="activity-tab-scroll" >
                                                                    <>
                                                                        {
                                                                            (accessToFinanceTab || accessToAfterMarketTab)
                                                                                ?
                                                                                <>
                                                                                    <div className="mid-quickview-tabber-wrap">
                                                                                        <div className="nav nav-tabs nav-fill" id="nav-logs-tab" role="tablist">
                                                                                            <a href="#" className={`nav-item nav-link ${activeTab === 'sales' ? 'active' : ''}`}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.setState({ activeTab: 'sales' })

                                                                                                }}><Translate text={'sales'} /> </a>

                                                                                            {
                                                                                                accessToFinanceTab && (<a href="#" className={`nav-item nav-link ${activeTab === 'finance' ? 'active' : ''}`}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        this.setState({ activeTab: 'finance' })
                                                                                                    }}
                                                                                                > <Translate text={'finance'} /> </a>)
                                                                                            }

                                                                                            {
                                                                                                accessToAfterMarketTab && (<a href="#" className={`nav-item nav-link ${activeTab === 'afterMarket' ? 'active' : ''}`}
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        this.setState({ activeTab: 'afterMarket' })
                                                                                                    }}
                                                                                                > <Translate text={'afterMarket'} /> </a>)
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {
                                                                            activeTab === 'finance' && (
                                                                                <>
                                                                                    <FinanceTab originType={'activity'} />

                                                                                    {
                                                                                        accessToFinanceActivity
                                                                                            ?
                                                                                            <>
                                                                                                <ActivityTab
                                                                                                    dealersettings={clientsettings}
                                                                                                    enquiry={enquiry}
                                                                                                    enquiryBasicVM={enquiryBasicVM}
                                                                                                    clientUsers={clientUsers}
                                                                                                    groupUsers={groupUsers}
                                                                                                    activitiesList={activitiesList}
                                                                                                    scrolltoTopActivity={this.scrolltoTopActivity}
                                                                                                    clientModule={this.props.clientModule}
                                                                                                    isValidForActivity={this.props.isValidForActivity}
                                                                                                    originType={activeTab}
                                                                                                ></ActivityTab>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>)}

                                                                        {
                                                                            activeTab === 'afterMarket' && (
                                                                                <>
                                                                                    <AfterMarketTab />
                                                                                    {
                                                                                        accessToAfterMarketActivity
                                                                                            ?
                                                                                            <>
                                                                                                <ActivityTab
                                                                                                    dealersettings={clientsettings}
                                                                                                    enquiry={enquiry}
                                                                                                    enquiryBasicVM={enquiryBasicVM}
                                                                                                    clientUsers={clientUsers}
                                                                                                    groupUsers={groupUsers}
                                                                                                    activitiesList={activitiesList}
                                                                                                    scrolltoTopActivity={this.scrolltoTopActivity}
                                                                                                    clientModule={this.props.clientModule}
                                                                                                    isValidForActivity={this.props.isValidForActivity}
                                                                                                    originType={activeTab}
                                                                                                ></ActivityTab>
                                                                                            </>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>)}


                                                                        {
                                                                            activeTab === 'sales' && (<>
                                                                                {clientsettings?.client?.clientSettings?.hideSalesDoc ? <></> : <div className="mid-quickview-finance-info">
                                                                                    {
                                                                                        (filesCategory.filter(e => e.module === activeTab)[0]) && (
                                                                                            <div className="quickview-column-sales-documents">
                                                                                                <h2><Translate text={"Documents"} /></h2>
                                                                                                <div className="quickview-column-finance-documents-inner">
                                                                                                    <FileDocuments
                                                                                                        filesCategory={filesCategory}
                                                                                                        activeTab={activeTab}
                                                                                                        handleFileOpen={handleFileOpen}
                                                                                                        files={files}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>}
                                                                                <ActivityTab
                                                                                    dealersettings={clientsettings}
                                                                                    enquiry={enquiry}
                                                                                    enquiryBasicVM={enquiryBasicVM}
                                                                                    clientUsers={clientUsers}
                                                                                    groupUsers={groupUsers}
                                                                                    activitiesList={activitiesList}
                                                                                    scrolltoTopActivity={this.scrolltoTopActivity}
                                                                                    clientModule={this.props.clientModule}
                                                                                    isValidForActivity={this.props.isValidForActivity}
                                                                                    originType={['enquiry'].includes(localStorage.defaultModule) ? null : 'enquiry'}
                                                                                ></ActivityTab>
                                                                            </>)
                                                                        }

                                                                    </>

                                                                </Tab>
                                                                <Tab eventKey="quickview" title="Enquiry Info" className="quick-view-tab-scroll">
                                                                    <QuickActivityTab
                                                                        enquiry={enquiry}
                                                                        contact={contact}
                                                                        isEmptyPipeline={isEmptyPipeline}
                                                                        errors={errors}
                                                                        handleReactSelectChange={handleReactSelectChange}
                                                                        handleDateChange={handleDateChange}
                                                                        saveEnquiryInfo={saveEnquiryInfo}
                                                                        dealersettings={clientsettings}
                                                                        updateEnquiry={updateEnquiry}
                                                                        clientUsers={clientUsers}
                                                                        groupUsers={groupUsers}
                                                                        handleLeadConvertShow={handleLeadConvertShow}
                                                                        canModifyEnquiry={canModifyEnquiry}
                                                                        isValidForStageUpdate={isValidForStageUpdate}
                                                                        isValidForFinanceStatusUpdate={isValidForFinanceStatusUpdate}
                                                                        newStage={newStage}
                                                                        handleStageUpdate={handleStageUpdate}
                                                                        clientModule={this.props.clientModule}
                                                                        handleStatusDateOpen={handleStatusDateOpen}
                                                                        handleFinanceInfoOpen={handleFinanceInfoOpen}
                                                                        handleStatusOptions={handleStatusOptions}
                                                                        enquiryAllStatus={enquiryAllStatus}
                                                                        isStatusOpen={isStatusOpen}
                                                                        requirements={requirements}
                                                                        updateListEnquiry={this.props.updateListEnquiry}
                                                                        isFilterApplied={this.props.isFilterApplied}
                                                                        accessToFinanceTab={accessToFinanceTab}
                                                                        accessToAfterMarketTab={accessToAfterMarketTab}
                                                                    ></QuickActivityTab>
                                                                </Tab>
                                                                <Tab eventKey="vehicles" title={CommonHelper.showVehicleText(clientsettings?.client?.category, "Vehicles", "Requirements")} className="quick-view-tab-scroll">

                                                                    <div className="quickview-files-wraper">


                                                                        <>
                                                                            {!_.isEmpty(requirements)
                                                                                ?
                                                                                <>
                                                                                    {requirementAccess || subLeadAccess ?
                                                                                        <div className="quickview-requirement-section">

                                                                                            {
                                                                                                subLeadAccess
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="edit-button"><a href="#" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            handleCheckSubLeads(soldIDs);
                                                                                                        }} className="mini-button">
                                                                                                            <OverlayTrigger
                                                                                                                placement='bottom'
                                                                                                                overlay={
                                                                                                                    <Tooltip><Translate text={'Create Sub Enquiries'} /></Tooltip>
                                                                                                                }
                                                                                                            >
                                                                                                                <i className="ico icon-sub-list">  </i>
                                                                                                            </OverlayTrigger>

                                                                                                        </a>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                            }

                                                                                            {
                                                                                                requirementAccess
                                                                                                    ?
                                                                                                    <>
                                                                                                        <div className="add-files-button">
                                                                                                            <a href="#" className="mini-button" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                handleRequirementOpen();
                                                                                                            }}>
                                                                                                                <i className="ico icon-add"></i>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                            }



                                                                                            <h3><Translate text={'Requirements'} /> {!_.isEmpty(requirements) ? <span className="badge badge-pill badge-cafe ml-1">{requirements.length}</span> : <></>}</h3>

                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                    }


                                                                                    <div className={`file-manager-control`}>
                                                                                        {
                                                                                            requirements.map((_requirement, index) => (
                                                                                                <Requirements
                                                                                                    key={index}
                                                                                                    status={enquiry.status}
                                                                                                    requirement={_requirement}
                                                                                                    handleEdit={handleReqEdit}
                                                                                                    setPrimary={handleReqMarkAsPrimary}
                                                                                                    setSold={requirementSoldMark}
                                                                                                    decrementQuantity={decrementQuantity}
                                                                                                    incrementQuantity={incrementQuantity}
                                                                                                    deleteRequirement={deleteRequirement}
                                                                                                    isPrimary={primaryReqID === _requirement.documentID ? true : false}
                                                                                                    enquiryID={enquiry.documentID}
                                                                                                    isDeivered={requirementAccess ? false : true}
                                                                                                    dealersettings={clientsettings}
                                                                                                    clientUsers={clientUsers}
                                                                                                    groupUsers={groupUsers}
                                                                                                    enquiry={enquiry}
                                                                                                >
                                                                                                </Requirements>

                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        requirementAccess
                                                                                            ?

                                                                                            <div className='text-center p-3'>
                                                                                                <p><Translate text={'norequirementdata'} /></p>
                                                                                                <button
                                                                                                    className='btn btn-sm btn-default'
                                                                                                    onClick={e => {
                                                                                                        e.preventDefault();
                                                                                                        handleRequirementOpen();
                                                                                                    }}
                                                                                                >
                                                                                                    <i className='ico icon-add mr-1'></i>{' '}
                                                                                                    <Translate text={'add'} />
                                                                                                </button>
                                                                                                {/* <div className="add-files-button">
                                                                                                <a href="#" className="mini-button" onClick={(e) => {
                                                                                                    e.preventDefault();

                                                                                                }}>
                                                                                                    <i className="ico icon-add"></i>
                                                                                                </a>
                                                                                            </div> */}
                                                                                            </div>
                                                                                            :
                                                                                            <>
                                                                                                <div className='text-center p-3'>
                                                                                                    <p><Translate text={'norequirementdata'} /></p>
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </>}
                                                                        </>

                                                                    </div>
                                                                </Tab>
                                                                {
                                                                    modules && modules.files && !_.isEmpty(enquiry)
                                                                        ?
                                                                        (<Tab eventKey="files" title="Files" className="quick-view-tab-scroll">
                                                                            <div className="quickview-files-wraper">
                                                                                <FilesCategory
                                                                                    enquiryID={enquiry.documentID}
                                                                                    contact={contact}
                                                                                    contactID={(enquiry.isContact && !_.isEmpty(enquiry.contact)) ? enquiry.contact.documentID : ''}
                                                                                    clientUsers={clientUsers}
                                                                                    groupUsers={groupUsers}
                                                                                    dealersettings={clientsettings}
                                                                                    isDeivered={canModifyEnquiry ? isDeivered : true}
                                                                                    showAdd={true}
                                                                                    filesCategory={filesCategory}
                                                                                ></FilesCategory>
                                                                            </div>
                                                                        </Tab>)
                                                                        :
                                                                        (<></>)
                                                                }

                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </>
                                )}
                            </PipelineConsumer>
                        )}
                    </StageConsumer>
                )}
            </LayoutConsumer>

        );
    }
}

export default QuickTab;