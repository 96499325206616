import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

const GuageChart = (props) => {

    const [chartData, setChart] = useState(null)

    useEffect(() => {
        if (_.isEmpty(chartData))
            return

        chartData.hands.values[0].value = props.value;
        chartData.radarContainer.children.values[3].text = `${props.value}%`
        chartData.invalidateRawData();

    }, [props.value])




    useEffect(() => {

        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        // create chart
        var chart = am4core.create(props.id, am4charts.GaugeChart);
        chart.logo.disabled = true;
        chart.innerRadius = 85;
 
        var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
        axis2.min = 0;
        axis2.max = 100;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = true;

        var range0 = axis2.axisRanges.create();
        range0.value = 0;
        range0.endValue = 50;
        range0.axisFill.fillOpacity = 1;
        range0.axisFill.fill = '#27b577';

        var range1 = axis2.axisRanges.create();
        range1.value = 50;
        range1.endValue = 100;
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill = '#e9ecef';

        /**
         * Label
         */

        var label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = 20;
        label.x = am4core.percent(50);
        label.y = am4core.percent(100);
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        label.text = `${props.value}%`;


        /**
         * Hand
         */

        var hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(1);
        hand.radius = am4core.percent(1);
        hand.startWidth = 1;
        hand.pin.disabled = true;
        hand.value = props.value;

        hand.events.on("propertychanged", function (ev) {
            range0.endValue = ev.target.value;
            range1.value = ev.target.value;
            axis2.invalidate();
        });


        // var axis = chart.xAxes.push(new am4charts.ValueAxis());
        // axis.min = 0;
        // axis.max = 100;
        // axis.strictMinMax = true;
        // axis.renderer.inside = false;
        // axis.renderer.line.strokeOpacity = 1;
        // axis.renderer.ticks.template.disabled = false
        // axis.renderer.ticks.template.strokeOpacity = 1;
        // axis.renderer.ticks.template.length = 5;
        // axis.renderer.grid.template.disabled = true;
        // axis.renderer.labels.template.radius = 10;
        // axis.renderer.labels.template.adapter.add("text", function (text) {
        //     return text + "%";
        // })


        const gaugechart = chart;
        setChart(chart)
        return () => {
            gaugechart && gaugechart.dispose()
        }
    }, [])



    return (
        <div id={props.id} style={{ width: "100%", height: props.height,marginTop:props.marginTop }}></div>
    );
}

export default GuageChart;