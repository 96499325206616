import React, { useState, useEffect } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ContentOverlay, ReactTimePicker, AntDatePicker } from '../../components';
import CommonHelper from '../../services/common';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import { objActivitylog, objEnquiry } from '../pipeline/viewModel'
import { testDriveBookingStatus } from '../../services/enum';
import { _returnMandatoryFields } from '../fleet/viewModel';
import { firestoreDB } from '../../services/helper';
import { objRequirement } from '../requirement/viewModel';

const TestDriveBooking = (props) => {

    const [booking, setFields] = useState({});

    const [errors, setErrors] = useState({});
    const [allUsers, setAllusers] = useState([]);

    const [isLoading, setLoading] = useState(false);


    const [titles, setTitles] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])

    const history = useHistory();

    useEffect(() => {
        if (_.isEmpty(props.docID))
            return;
        const { docID } = props;
        if (docID) {
            loadTIP();
        }

        return () => {
            // window.unsubscribeInboundEnq && window.unsubscribeInboundEnq();
        };
    }, [props.docID]);

    //#region  EVENT CHANGES     

    const checkUserRoster = (userID, _date) => {
        // if (userID) {
        //     const { dealersettings, groupUsers, clientUsers } = props;
        //     CommonHelper.checkUserRoster(dealersettings,
        //         {
        //             owner: userID,
        //             startDate: _date ? _date : window.firebase.firestore.Timestamp.now()
        //         }, (!_.isEmpty(groupUsers) ? groupUsers : clientUsers),
        //         (_date ? true : false))
        //         .then(msg => {
        //             setRosterMsg(msg);
        //         })
        // }
    }
    //#endregion

    //#region SUBMIT CHANGES



    const loadTIP = async () => {
        try {
            const { docID, dealersettings } = props;
            if (docID && dealersettings) {

                const refData = await firestoreDB(props.dealersettings).firestore().collection('testdriveBookings').doc(docID).get();

                if (refData.exists) {
                    let _objLead = Object.assign({}, refData.data());
                    _objLead.documentID = refData.id;
                    if (!_objLead.activityDate) _objLead.activityDate = window.firebase.firestore.Timestamp.now();

                    if (dealersettings && dealersettings.client && dealersettings.client.settings) {

                        const setttings = props.dealersettings.client.settings;
                        let defaultCountry = dealersettings?.client?.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';

                        let _titles = [];
                        setttings.titles && setttings.titles.forEach((doc) => {
                            _titles.push({
                                value: doc.value,
                                label: doc.name
                            });
                        });

                        const _countries = [];
                        dealersettings.countries && dealersettings.countries.forEach((doc) => {
                            _countries.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name
                            });
                        });

                        const _states = [];
                        defaultCountry && dealersettings.states && dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                            _states.push({
                                value: doc.code,
                                active: _.isBoolean(doc.active) ? doc.active : true,
                                label: doc.name,
                                name: doc.country_code
                            });
                        });

                        setTitles(_titles);
                        setCountries(_countries);
                        setStates(_states);
                        setErrors({})
                    }

                    // GET USERS LIST BASED ON CILENT-ID AND PIPELINE
                    let options = [];
                    let allDealerUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
                    _.filter(allDealerUsers.filter(v => v.clientID === _objLead.clientID)).map(rec => {
                        return options.push({
                            value: rec.id,
                            active: _.isBoolean(rec.active) ? rec.active : true,
                            searchlabel: rec.name,
                            label: <div className="drop-image">
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        })
                    })
                    setAllusers(options)

                    setFields(_objLead);
                }
            }
        } catch (error) {
            errorLog(error, 'loadTIP');
        }
    }

    const handleDateChange = (val, name) => {
        if (val) {
            val = moment(moment(val).format('YYYY-MM-DD') + ' ' + (booking.activityDate ? moment.unix(booking.activityDate.seconds).format('HH:mm') : moment().format('HH:mm')))._d;

            setFields({
                ...booking,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
            });

            //checkUserRoster(booking.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
        }
    };
    const handleStartTimeChange = (val, name) => {
        //console.log(val)
        setFields({
            ...booking,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d)
        });

        // checkUserRoster(booking.owner, window.firebase.firestore.Timestamp.fromDate(moment(val)._d));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { docID } = props;
        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, booking);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(objfields)) {
            if (key === 'activityDate' && !booking[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        //console.log('handleSubmit', errors, objfields)
        setErrors(errors);

        // if (formIsValid === true) {
        //     const inboundResult = await firestoreDB(props.dealersettings).firestore().doc(`tradeinProInbound/${docID}`).get()
        //     const inboundData = inboundResult.data();
        //     //    console.log('handleSubmit-check already exist', inboundData)
        //     if (!_.isEmpty(inboundData) && !_.isEmpty(inboundData.tradeinProID) && inboundData.status !== 0) {
        //         formIsValid = false;
        //         Swal.fire({
        //             title: CommonHelper.showLocale(props, `Lead already allocated`),
        //             showCancelButton: true,
        //             confirmButtonClass: 'viewButton',
        //             confirmButtonColor: '#459E20',
        //             cancelButtonColor: '#17232D',
        //             confirmButtonText: CommonHelper.showLocale(props, 'viewAppraisal'),
        //             cancelButtonText: CommonHelper.showLocale(props, 'Okay'),
        //             reverseButtons: true,
        //             allowOutsideClick: false,
        //             allowEscapeKey: false
        //         }).then((result) => {
        //             if (result.value) {
        //                 props.handleModalClose();
        //                 if (props.setNotifyClose)
        //                     props.setNotifyClose(true);

        //                 history.push("/tradeinpro/details/" + inboundData.tradeinProID);
        //             }
        //         });
        //         return
        //     }
        // }

        if (formIsValid === true) {
            if (!_.isEmpty(booking?.enquiryID)) {
                addTestDriveNextActivity(booking?.enquiryID, booking?.owner);
            } else {
                createNewEnquiry();
            }
        }
        else {
            setLoading(false);
            return formIsValid;
        }


    };


    //#endregion

    //#region  SAVE CHANGES


    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }

    const createNewEnquiry = async () => {

        try {
            if (!isLoading) setLoading(true);
            const { dealersettings } = props;
            const setttings = dealersettings.client.settings;
            let state = Object.assign({}, objEnquiry);

            state.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
            state.addedBy = dealersettings ? dealersettings.id : '';
            state.addedDate = (booking.addedDate ? booking.addedDate : window.firebase.firestore.Timestamp.now());
            state.addedFrom = 'web';
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.createdDate = window.firebase.firestore.Timestamp.now();
            state.modifiedFrom = 'web';
            state.isNewEnquiry = true;
            state.isDeleted = false;
            state.owner = (booking.owner || localStorage.uid);

            const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;
            state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, dealersettings, null, booking.clientID, inboundAllocation);

            if (_.isEmpty(state.clientID) && localStorage.defaultModule !== 'oem') {
                state.clientID = booking.clientID ? booking.clientID : (dealersettings ? dealersettings.client.id : '');
                state.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            }

            let _contact = Object.assign({}, booking.contact);
            if (!_.isEmpty(_contact)) {
                let objContactData = Object.assign({}, _contact);
                //Remove empty value from object
                for (var propName in objContactData) {
                    if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                        delete objContactData[propName];
                    }
                }
                state.contact = objContactData;
                state.contactID = objContactData.documentID;
                state.isContact = true;
                state.isNewEnquiry = false;
            }


            if (!_.isEmpty(booking?.vehicle)) {
                const _objRequirement = Object.assign({}, objRequirement)
                _objRequirement.documentID = window.firebase.firestore().collection('stock').doc().id;
                _objRequirement.stock = booking?.vehicle;
                _objRequirement.regNo = (booking?.vehicle?.regNo || '');
                _objRequirement.vinNo = (booking?.vehicle?.vinNo || '');
                _objRequirement.stockNo = (booking?.vehicle?.stockNo || '');
                _objRequirement.saleType = (booking?.vehicle?.saleType || '');
                state.isVehicle = true;
                state.requirement = _objRequirement;
            }

            let _selPipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
            let _defaultstages = _selPipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
            let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

            state.pipeline = _selPipeline.value;
            state.stage = _stages[0];
            state.status = null;
            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.ownerInbound = localStorage.uid;
            state.tags = window.firebase.firestore.FieldValue.arrayUnion(
                {
                    type: "amsme",
                    refID: (booking.meID ? booking.meID : ''),
                    subType: (booking.subType ? booking.subType : '')
                }
            )

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            const updateRef = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then(async (docRef) => {
                let documentID = objData.documentID;
                const batch = firestoreDB(props.dealersettings).firestore().batch();
                //toast.notify('Allocated sucessfully', { duration: 2000 });
                // //(_.isEmpty(state.documentID) && commented for dms changes, 
                if (objData.isVehicle && !_.isEmpty(documentID) && !_.isEmpty(objData.requirement))
                    saveReqInfo(documentID, objData.requirement, objData.clientID, batch)

                if (!_.isEmpty(documentID) && !_.isEmpty(booking.documentID))
                    await updateInBound(documentID, booking.documentID, batch);

                if (objData.contactID) {
                    const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                    updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                }

                if (!_.isEmpty(booking.activityDate)) addTestDriveNextActivity(documentID, objData.owner, batch)

                addTestDriveNextNotes(objData, batch);


                batch.commit()
                    .then(snapshot => {
                        setLoading(false);
                        props.handleClose(documentID)
                        toast.notify(`Test Drive Booked successfully.`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoading(false);
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    })


            }).catch((error) => {
                setLoading(false);
                console.error("Error updating contact_details: ", error);
            });

        } catch (error) {
            errorLog(error, 'handleSaveEnquiry');
        }
    }


    const saveReqInfo = (enquiryID, _objReq, clientID, batch) => {
        try {

            const { dealersettings } = props;
            const userID = dealersettings ? dealersettings.id : '';
            let objData = Object.assign({}, _objReq);
            const inboundAllocation = (localStorage.defaultModule === 'oem') ? true : false;
            objData = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(objData, dealersettings, null, clientID, inboundAllocation);

            if (_.isEmpty(objData.addedBy)) {
                objData.addedBy = userID
                objData.addedDate = window.firebase.firestore.Timestamp.now();
                objData.addedFrom = 'web';
            }
            objData.modifiedBy = userID
            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            if (!objData.enquiryID)
                objData.enquiryID = enquiryID;

            let requirementID = objData['documentID'];

            batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${enquiryID}/requirements/${requirementID}`), objData, { merge: true });

            let _makeModel = !_.isEmpty(objData.stock) ?
                (objData.stock.make ? objData.stock.make + ' ' : '') + (objData.stock.model ? objData.stock.model + ' ' : '') + (objData.stock.year ? objData.stock.year : '') :
                (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '');

            CommonHelper.saveChangeLog(dealersettings.client.id, objData.groupID, objData.enquiryID, `Requirement updated (${_makeModel} from Test drive booking request)`, 'enquiry', 'updated');


        } catch (error) {
            errorLog(error, 'saveReqInfo');
        }

    };
    const updateInBound = async (enquiryID, documentID, batch) => {
        try {

            const { dealersettings } = props;
            let _objReq = {
                status: testDriveBookingStatus.BOOKED,
                enquiryID: enquiryID,
                modifiedBy: dealersettings ? dealersettings.id : '',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }
            if (!_.isEmpty(batch)) {
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`testdriveBookings/${documentID}`), _objReq, { merge: true });
            }
            else {
                firestoreDB(props.dealersettings).firestore().doc(`testdriveBookings/${documentID}`).set(_objReq, { merge: true })
                    .catch(error => {
                        console.error(error);
                    });
            }


        } catch (error) {
            errorLog(error, 'updateInBound');
        }
    }
    const addTestDriveNextActivity = async (enquiryId, owner, batch) => {
        if (!batch) setLoading(true);
        try {
            const { dealersettings, clientUsers } = props;
            let newlogVM = Object.assign({}, objActivitylog);
            newlogVM.type = 'activity';
            newlogVM.subType = 'testdrive';
            newlogVM.title = 'Test Drive';

            newlogVM.owner = (owner || (booking?.owner || localStorage.uid));
            newlogVM.addedBy = localStorage.uid;
            newlogVM.modifiedBy = localStorage.uid;
            newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedFrom = 'web';

            newlogVM.enquiryID = enquiryId;
            newlogVM.contactID = booking?.contact?.documentID;

            newlogVM.contact = booking?.contact;
            newlogVM.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;

            newlogVM.startDate = (booking.activityDate || booking.bookingDate);
            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate.seconds).add('hour', 1).toDate());
            newlogVM.addedByName = CommonHelper.getUserNamebyId(clientUsers, localStorage.uid);
            newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, booking.clientID);
            // if (enableCustShowNoShow)//(stage && stage === 'Converted') || objFields.pipeline !== 'LeadsBucket')
            //     newlogVM = CommonHelper.setCustShowNoShow(newlogVM, props.dealersettings, enableCustShowNoShow, activityTypes);

            newlogVM.testdriveBookingID = booking?.documentID;
            newlogVM.shareWithAMSMe = true;

            newlogVM.stock = (booking?.vehicle || null);
            newlogVM.stockID = booking?.vehicle?.documentID;
            Object.keys(newlogVM).forEach(key => newlogVM[key] === undefined ? delete newlogVM[key] : {});

            if (!_.isEmpty(batch)) {
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
            }
            else {
                firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true }).then((docRef) => {
                    setLoading(false);
                    props.handleClose(enquiryId);
                    if (!_.isEmpty(enquiryId) && !_.isEmpty(booking.documentID)) updateInBound(enquiryId, booking.documentID);
                    toast.notify(`Test Drive Booked successfully.`, {
                        duration: 2000
                    })
                }).catch(error => {
                    console.error(error);
                });

            }


        } catch (error) {
            setLoading(false);
            errorLog(error, 'addTestDriveNextActivity');
        }

    }

    const addTestDriveNextNotes = async (_enquiry, batch) => {

        try {
            const { dealersettings, clientUsers } = props;
            let newlogVM = Object.assign({}, objActivitylog);
            newlogVM.type = 'log';
            newlogVM.subType = 'note';
            newlogVM.isDone = true;
            newlogVM.notes = "This Lead is from AMS ME for Test drive booking"
            newlogVM.owner = localStorage.uid;
            newlogVM.addedBy = localStorage.uid;
            newlogVM.modifiedBy = localStorage.uid;
            newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newlogVM.modifiedFrom = 'web';

            newlogVM.enquiryID = _enquiry.documentID;
            newlogVM.contactID = _enquiry?.contact?.documentID;
            newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
            newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
            newlogVM.groupID = (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null;
            newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;

            newlogVM.startDate = window.firebase.firestore.Timestamp.now();
            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(newlogVM.startDate.seconds).add('hour', 1).toDate());
            newlogVM.addedByName = CommonHelper.getUserNamebyId(clientUsers, localStorage.uid);
            
            newlogVM.tags = ["amsme"]
            Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
            newlogVM.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
            batch.set(firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`), newlogVM, { merge: true });
        } catch (error) {
            errorLog(error, 'addTestDriveNextNotes');
        }

    }
    //#endregion

    const { dealersettings } = props;

    const _settings = dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings;

    //console.log('C O N T A C T', booking.isDeleted);

    return (
        <>
            {
                !_.isEmpty(booking)
                    ?
                    <>
                        <Modal
                            show={props.show}
                            onHide={props.handleClose}
                            backdrop="static"
                            backdropClassName={props.show ? 'overlay-modal active' : ''}
                            dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-integration-lead"
                            enforceFocus={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title><Translate text={booking.status === testDriveBookingStatus.BOOKED ? 'Test Drive Booking' : 'Book a Test Drive'} />

                                    <div className="lead-source-badge">

                                        {booking?.isDeleted ?
                                            <><div className={`badge badge-pill badge-lost ml-2`}><Translate text={'deleted'} upperCase={true} /></div></>
                                            :
                                            <></>}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="modal-body">
                                <div className={`container-fluid ${(booking.isDeleted) ? 'form-disable' : ''}`}>
                                    <div className="row">
                                        <div className="integration-lead-body">
                                            <div className="integration-lead-contact-details">
                                                <h3> <Translate text={'contactDetails'} /></h3>
                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-head">
                                                        <div className="integration-lead-contact-title"> {CommonHelper.displayFullContact(titles, booking?.contact, '--')}</div>
                                                        {booking?.contact && booking?.contact.preferredName ? <span className="integration-lead-contact-preferred">({booking?.contact.preferredName})</span> : <></>}
                                                        <div className="integration-lead-contact-sub">
                                                            <ul className="integration-lead-ul">
                                                                <li> <i className="ico icon-call"></i> {booking?.contact && booking?.contact.phone ? (CommonHelper.getFullPhone(booking?.contact.phoneCode, booking?.contact.phone)) : '--'}</li>
                                                                <li> <i className="ico icon-email"></i> {booking?.contact && booking?.contact.email ? (booking?.contact.email) : '--'}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseNo'} /></span> {booking?.contact && booking?.contact.licenseNo ? (booking?.contact.licenseNo) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseExpiry'} /> </span> {booking?.contact && booking?.contact.licenseExpiry ? (booking?.contact.licenseExpiry) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'gender'} /></span> {booking?.contact && booking?.contact.gender ? CommonHelper.getNameByValue(dealersettings.client.settings.genders, booking?.contact.gender, booking?.contact.gender) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'companyName'} /></span> {booking?.contact && booking?.contact.company ? (_.isObject(booking?.contact.company) ? booking?.contact.company.name : booking?.contact.company) : '--'}</td>
                                                                </tr>
                                                                <tr className="tr-brk">

                                                                    <td colSpan="2"><span className="table-label"><Translate text={'address'} /></span> {booking?.contact && booking?.contact.address ? (booking?.contact.address) : '--'} </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'country'} /></span> {booking?.contact && booking?.contact.country ? CommonHelper.getLabelByValue(countries, booking?.contact.country) : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'state'} /> </span> {booking?.contact && booking?.contact.state ? CommonHelper.getLabelByValue(states, booking?.contact.state) : '--'}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <h3> <Translate text={'vehicleDetails'} /></h3>
                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-vehicle-head">
                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#"> <img src={CommonHelper.showBrandLogo(dealersettings, booking?.vehicle?.make)} className="img-object-fit-contain" alt="" width="70" height="70" /> </a> </div>
                                                            <div className="vehicle-data">
                                                                <div className="vehicle-item-title">
                                                                    {booking?.vehicle && booking?.vehicle.make ? (booking?.vehicle.make) : ''} &nbsp;
                                                                    {booking?.vehicle && booking?.vehicle.model ? (booking?.vehicle.model) : ''}
                                                                </div>
                                                                {/* <div className="vehicle-item-info">
                                                                    {booking?.vehicle && booking?.vehicle.extColor ? `${booking?.vehicle.extColor}, ` : ''}
                                                                    {booking?.vehicle && booking?.vehicle.year ? (booking?.vehicle.year) : ''}
                                                                </div> */}
                                                                {/* {booking?.tradein?.regNo ? <><div className="vehicle-item-info">
                                                                    <Translate text={'reg'} toUpperCase={true} />#: &nbsp; {booking?.vehicle.regNo}
                                                                </div></> : <></>} */}
                                                                {
                                                                    !_.isEmpty(booking?.vehicle) ? (<>
                                                                        <div className="dashboard-testdrive-activity-column">
                                                                            Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{booking?.vehicle?.stockNo || '--'}</div>
                                                                            {booking?.vehicle?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{booking?.vehicle?.extColor}</div></>)}
                                                                            {booking?.vehicle?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{booking?.vehicle?.year}</div></>)}
                                                                            {booking?.vehicle?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{booking?.vehicle?.regNo}</div></>)}
                                                                            {booking?.vehicle?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{booking?.vehicle?.vinNo}</div></>)}
                                                                        </div>
                                                                    </>) : (<></>)
                                                                }
                                                                {
                                                                    booking?.vehicle && booking?.vehicle.saleType ?
                                                                        <div className="vehicle-item-status">

                                                                            <div className="badge badge-pill badge-white ml-1">
                                                                                {CommonHelper.getNameByValue(_settings && _settings.salesType,
                                                                                    booking?.vehicle.saleType, '')}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <h3> <Translate text={'Other Information'} /></h3>

                                                <div className="integration-lead-box">
                                                    <div className="integration-lead-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%">
                                                                        <span className="table-label"><Translate text={'Received Date & Time'} /></span>
                                                                        {booking.addedDate ? moment.unix(booking.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                    <td width="25%">
                                                                        <span className="table-label"><Translate text={'owner'} /></span>
                                                                        {booking.owner ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), booking.owner) : '--'}
                                                                    </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'Booking Date & Time'} /></span> {booking.bookingDate ? moment.unix(booking.bookingDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'status'} /> </span>
                                                                        {booking.status && _.isString(booking.status) && (<div className={`badge badge-pill badge-${booking.status.toLowerCase()}`}>{booking.status.split(/(?=[A-Z])/).join(' ')}</div>)}

                                                                        {/* {booking.origin ? CommonHelper.getNameByValue(dealersettings.client.tradeinPro.origins, booking.origin, '--') : '--'} */}
                                                                    </td>


                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>


                                                {
                                                    booking.status === testDriveBookingStatus.REQUESTED
                                                        ?
                                                        <>
                                                            <div className="lead-stage-layout form-style">
                                                                <div className="scheduled-activities-title"> <Translate text={'booking'} /></div>
                                                                <div className="form-row">

                                                                    <div className="form-group col-md-6">
                                                                        <label ><Translate text={'bookingDate'} /></label>
                                                                        <AntDatePicker
                                                                            value={booking.activityDate ? moment
                                                                                .unix(booking.activityDate.seconds)
                                                                                .format('YYYY-MM-DD') : null}
                                                                            name={'activityDate'}
                                                                            onChange={e => {
                                                                                handleDateChange(e, 'activityDate');
                                                                            }}
                                                                            format='DD/MM/YYYY'
                                                                            placeholder='DD/MM/YYYY'
                                                                            className={`form-control ${errors["activityDate"]}`}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label ><Translate text={'Time'} /></label>
                                                                        <div className='inner-addon right-addon'>
                                                                            <i className='bg-icons ico icon-time'></i>
                                                                            <ReactTimePicker
                                                                                value={booking.activityDate ? moment.unix(booking.activityDate.seconds)._d : null}
                                                                                name={'activityDate'}
                                                                                onChange={handleStartTimeChange}
                                                                                timeIntervals={15}
                                                                                placeholder='h:mm aa'
                                                                                className={`form-control`}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {/* {
                                                                (!_.isEmpty(booking) && booking.modifiedBy && booking.modifiedDate) ? (
                                                                    <div className="lead-stage-layout form-style vehicle-status-alert alert-user mb-1 mt-2">
                                                                        <b className="alert-link"><Translate text={'allocated'} /> :</b>
                                                                        {moment.unix(booking.modifiedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), booking.modifiedBy)}

                                                                    </div>
                                                                ) : (<></>)
                                                            } */}

                                                        </>
                                                }

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </Modal.Body>

                            <>
                                {
                                    booking.status === testDriveBookingStatus.REQUESTED && !booking.isDeleted
                                        ?
                                        <>
                                            <Modal.Footer className="modal-footer">

                                                {/* {
                                        !_.isEmpty(rosterMsg) ? (
                                            <div className="activity-pop-info alert-danger mb-3">
                                                <i className="fa fa-exclamation-circle mr-2" aria-hidden="true"></i>{rosterMsg}
                                            </div>
                                        ) : (<></>)
                                    } */}


                                                <button
                                                    type="button"
                                                    className={`btn btn-primary float-right ml-2 ${isLoading ? 'form-disable' : ''}`}
                                                    onClick={(e) => handleSubmit(e)}
                                                >
                                                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'accept'} />
                                                </button>




                                            </Modal.Footer>
                                        </>
                                        :
                                        <>
                                            {
                                                booking && booking.enquiryID && !booking.isDeleted
                                                    ?
                                                    <>
                                                        <Modal.Footer className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary float-right ml-2"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (props.setNotifyClose)
                                                                        props.setNotifyClose(true);

                                                                    props.handleClose();
                                                                    history.push("/enquiry/details/" + booking.enquiryID);
                                                                }}
                                                            >
                                                                {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'viewEnquiry'} />
                                                            </button>
                                                        </Modal.Footer>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </>
                                }
                            </>


                        </Modal>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }

        </>
    )
}

export default TestDriveBooking;