import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from "moment";
import { reportVM, reportGroupBy, reportDynColumns, reportDynFields, calculateOptions, datefilterType, reportFilters, visibilities, columnTypes, leadTypes, modelGroupTypes } from './viewModel'
import Translate from '../../constants/translate';
import { InputText, ReactSelect, ReactMultiSelect, TableView, BarChart, PieChart, LineChart, PopUpModal, InputCheckBox } from '../../components'
import CommonHelper from '../../services/common';
import { tipStatus } from '../../services/enum'
import _images from '../../images'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import RearrangeColumns from '../reports/rearrangeColumns'
import NumberFormat from 'react-number-format';

const AddDynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 250) })
    const [report, setReport] = useState(!_.isEmpty(props.report) ? props.report : {});
    const [oldReport, setOldRreport] = useState()
    const newReport = !_.isEmpty(props.report) && !props.report.name.includes('- Copy') ? false : true
    const [errorFields, setErrorFields] = useState({});
    const [pipelineOptions, setPipelineOptions] = useState([])
    const [loader, setLoader] = useState(false)
    const [groupOptions, setGroupoptions] = useState([]);
    const [defaultGroup, setDefaultGroup] = useState(!_.isEmpty(props.report) ? props.report.groupBy : '');
    const [reportData, setReportData] = useState([]);
    const [reportFields, setReportFields] = useState(reportDynFields)
    const [showModel, setShowmodel] = useState(false)
    const [modelType, setModelType] = useState()
    const [popHeader, setPopHeader] = useState()
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [loadSettings, setLoadSettings] = useState(false);
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);
    const [clientOptions, setClientOptions] = useState([])
    const [displayOptions, setDisplayOptions] = useState();
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const roleTradeinProIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules && e.modules.tradeinPro === true), function (obj) {
        return obj.documentID;
    })];

    const tradeinProStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.tradeinPro &&
        props.dealersettings.client.tradeinPro.tradeinProStatus ? props.dealersettings.client.tradeinPro.tradeinProStatus : [];

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 250),
                tblWidth: (window.innerWidth - 695)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return
        let _clients = CommonHelper.tradeinProClients(props.dealersettings);
        if (_clients.length > 1) {
            _clients = [{ value: 'all', label: 'All' }, ..._clients]
        }
        setClientOptions(_clients)
    }, [])

    useEffect(() => {
        let option = [{ label: `Client`, value: 'client' }];
        if (!_.isEmpty(defaultGroup)) {
            option.push({ label: _.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).label : defaultGroup, value: defaultGroup });
        }
        setDisplayOptions(option)
    }, [defaultGroup])

    useEffect(() => {
        if (_.isEmpty(props.report)) {
            const newReportVM = Object.assign({}, reportVM);
            newReportVM.documentID = window.firebase.firestore().collection(`tradeinProReports`).doc().id;
            newReportVM.clientID = props.dealersettings.client.id;
            newReportVM.clientIDs = [];
            newReportVM.addedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.addedBy = localStorage.uid;
            newReportVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.modifiedBy = localStorage.uid;
            newReportVM.owner = localStorage.uid;
            newReportVM.isDeleted = false;
            newReportVM.reportType = 'table';
            newReportVM.groupBy = '';
            newReportVM.groupSubtype = '';
            newReportVM.columns = '';
            newReportVM.target = '';
            newReportVM.visibility = 'private';
            newReportVM.pipeline = ['all'];
            setReport(newReportVM)
        }
        else {
            setOldRreport(props.report)
        }
    }, [])

    useEffect(() => {
        let _leadSource = []
        let _sourceFields = []

        tradeinProStatus.filter(a => a.active === true)
            .forEach(rec => {
                _leadSource.push({
                    label: <>{rec.name}</>,
                    searchlabel: `${rec.name}`,
                    value: `status-${rec.value}`,
                    name: rec.name,
                    view: ['table', 'column', 'pie', 'target'],
                    calculationType: ['count'],
                    color: rec.color,
                    sourceType: 'TradeinPro Status',
                })
                if (rec.value === 'Won') {
                    _leadSource.push({
                        label: <>{rec.name} Conv. Ratio</>,
                        searchlabel: `${rec.name} Conv. Ratio`,
                        value: `status-${rec.value}Conv`,
                        name: `${rec.name} Conv. Ratio`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        color: rec.color,
                        sourceType: 'TradeinPro Status'
                    })
                    _sourceFields.push({
                        name: `${rec.name} Conv. Ratio`,
                        value: `status-${rec.value}Conv`,
                        flex: 1
                    })
                }
                _sourceFields.push({
                    name: rec.name,
                    value: `status-${rec.value}`,
                    flex: 1
                })

            })


        setReportColumns([...reportColumns, ..._leadSource]);
        setReportFields([...reportFields, ..._sourceFields])
        setLoadSettings(true)
    }, [])


    // useEffect(() => {
    //     if (_.isEmpty(props.dealersettings))
    //         return
    //     let _pipelines = [];
    //     props.dealersettings.client.tradeinPro.pipelines.filter(a => a.active === true).forEach(rec => {
    //         _pipelines.push({
    //             label: rec.name,
    //             value: rec.value,
    //             stages: rec.stages
    //         })
    //     })
    //     if (_pipelines.length > 1) {
    //         _pipelines = [{ value: 'all', label: 'All' }, ..._pipelines]
    //     }
    //     setPipelineOptions(_pipelines)
    // }, [props.dealersettings.allPipelines])

    useEffect(() => {
        if (_.isEmpty(report) || _.isEmpty(report.reportType) || _.isEmpty(report.columns) || _.isEmpty(report.groupSubtype) || !loadSettings) {
            setReportData([])
            return
        }

        let ownerDashboard = false;
        if (_.isArray(report.columns) && report.columns.length > 0) {
            setReportFields([
                ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                    return {
                        ...rec,
                        default: true
                    }
                }),
                ...report.columns.map(rec => {
                    return {
                        ..._.find(reportFields, { value: rec }),
                        default: true
                    }
                }),
                ..._.filter(reportFields, (v) => _.indexOf(report.columns, v.value) < 0 && v.value !== 'name').map(rec => {
                    return {
                        ...rec,
                        default: false
                    }
                }),
            ])
        }
        else if (report.columns) {
            setReportFields([
                ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                    return {
                        ...rec,
                        default: true
                    }
                }),
                {
                    ..._.find(reportFields, { value: report.columns }),
                    default: true
                },
                ..._.filter(reportFields, (v) => _.indexOf([report.columns], v.value) < 0 && v.value !== 'name').map(rec => {
                    return {
                        ...rec,
                        default: false
                    }
                }),
            ])
        }
        let _reportData = []
        if (report.groupBy === 'user') {
            _.filter(props.clientUsers.filter(
                a => Boolean(a.superAdmin) === false
                    && a.active === true
                    && (report.groupBy === 'user' ? _.includes(roleTradeinProIds, a.roleID) : true)
            ), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.id) >= 0) && (ownerDashboard ? v.id === localStorage.uid : true))
                .map(rec => {
                    return _reportData.push({
                        id: rec.id,
                        name: rec.name,
                        profileImage: CommonHelper.showUserAvatar(rec.profileImage, rec.name),
                        ...getRandomValues()
                    });
                });
        }
        else if ((report.groupBy === 'origins' || report.groupBy === 'lostReasons')
            && !_.isEmpty(props.dealersettings.client.tradeinPro[defaultGroup])) {
            _.filter(props.dealersettings.client.tradeinPro[defaultGroup], (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0) && v.active === true)
                .map(rec => {
                    return _reportData.push({
                        id: rec.value,
                        name: rec.name,
                        ...getRandomValues()
                    });
                })

        }
        else if (defaultGroup === 'tradeinProStatus') {
            if (props?.dealersettings?.client?.tradeinPro[defaultGroup])
                props.dealersettings.client.tradeinPro[defaultGroup].filter(a => a.active === true).map(rec => {
                    return _reportData.push({
                        id: rec.value,
                        name: rec.name,
                        ...getRandomValues()
                    });
                })
            else {
                _reportData = tipStatus;
            }
        }
        else if (report.groupBy === 'model' && !_.isEmpty(props.dealersettings.allMakeModels)) {
            _.filter(props.dealersettings.allMakeModels, (v) => v.models && (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0))
                .map(make => {
                    make.models.filter(a => !_.isEmpty(report.model) ? report.model.includes(a.value) : true).map(model => {
                        return _reportData.push({
                            id: make.value + '-' + model.value,
                            name: make.name + ' ' + model.name + (!_.isEmpty(model.type) ? ' ' + model.type : ''),
                            ...getRandomValues()
                        });
                    })
                })
        }
        else if (report.groupBy === 'dateFilter') {
            var dates = CommonHelper.getAllDaysofWeek(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0)
            if (report.groupSubtype === 'weekly') { dates = CommonHelper.getAllWeeks(moment().startOf('month'), moment().endOf('month'), moment().day(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 0).day()) }
            else if (report.groupSubtype === 'monthly') { dates = CommonHelper.getAllMonths(moment().startOf('year'), moment().endOf('year')) }

            dates.map(date => {
                return _reportData.push({
                    id: `${report.groupSubtype}-${moment(date[0]).format('DD/MM/YYYY')}`,
                    name: report.groupSubtype === 'daily' ? moment(date[0]).format('DD/MM/YYYY') : report.groupSubtype === 'monthly' ? moment(date[0]).format('MMM YYYY') : `${moment(date[0]).format('DD MMM')}-${moment(date[date.length - 1]).format('DD MMM YYYY')}`,
                    ...getRandomValues()
                });
            })
        }
        else if (report.groupBy === 'inboundSource' && !_.isEmpty(props.dealersettings.client.integrations)) {
            _.filter(props.dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true && e.appraisal === true), (v) => (report.groupSubtype.indexOf('all') >= 0 ? true : _.indexOf(report.groupSubtype, v.value) >= 0))
                .map(rec => {
                    return _reportData.push({
                        id: rec.value,
                        name: rec.name,
                        ...getRandomValues()
                    });
                })

        }
        setReportData(_reportData)
    }, [report.groupBy, report.groupSubtype, report.columns, report.target, loadSettings, report.model, report.group, report.modelType, report.modelGroupBy])

    const getRandomValues = () => {
        return {
            totalAppraisal: Math.floor(Math.random() * 50),
            dealerPrice: `${currencySymbol}${Math.round((Math.floor(Math.random() * 500) + Number.EPSILON) * 100) / 100}`,
            ...getLeadSourceCount()
        };
    }



    const handleSavesettings = (fields) => {
        setReport({
            ...report,
            ['columns']: fields.filter(a => a !== 'name')
        })
    }

    useEffect(() => {
        if (_.isEmpty(defaultGroup))
            return
        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
        let options = [];
        if (!ownerDashboard && defaultGroup !== 'dateFilter') {
            options.push({ value: 'all', label: 'All' })
        }
        if (defaultGroup === 'user') {
            let roleIds = [];
            // (props.dealersettings.client.roles
            //     && _.isArray(report.pipeline)
            //     && report.pipeline.length === 1) ? [..._.map(props.dealersettings.client.roles.filter(e => e.pipeline && e.pipeline.some(m => m === report.pipeline[0])), function (obj) {
            //         return obj.documentID;
            //     })] : [];

            props.clientUsers.filter(a => Boolean(a.superAdmin) === false
                && (ownerDashboard ? a.id === localStorage.uid : true)
                && (roleIds.length > 0 ? _.includes(roleIds, a.roleID) : true)
                && (defaultGroup === 'user' ? _.includes(roleTradeinProIds, a.roleID) : true)
            )
                .map(rec => {
                    return options.push({
                        value: rec.id,
                        searchlabel: rec.name,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        label: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        ),
                    });
                });
            if (options.length === 1 && options.some(a => a.value === 'all')) {
                options = [];
            }
        }
        else if ((report.groupBy === 'origins' || report.groupBy === 'lostReasons')
            && !_.isEmpty(props.dealersettings.client.tradeinPro[defaultGroup])) {
            props.dealersettings.client.tradeinPro[defaultGroup]
                .map(rec => {
                    return options.push({
                        value: rec.value,
                        label: rec.name,
                    });
                })

        }
        else if (defaultGroup === 'tradeinProStatus') {
            if (props?.dealersettings?.client?.tradeinPro[defaultGroup])
                props.dealersettings.client.tradeinPro[defaultGroup].filter(a => a.active === true).map(rec => {
                    return options.push({
                        value: rec.value,
                        label: <div className={`badge badge-pill ${rec.value === 'New' ? 'badge-status-open' : 'badge-status-empty'}`} style={{ background: rec.color }}>{rec.name}</div>
                    });
                })
            else {
                options = tipStatus;
            }
        }
        else if (defaultGroup === 'model' && !_.isEmpty(props.dealersettings.allMakeModels)) {
            props.dealersettings.allMakeModels.map(rec => {
                return options.push({
                    value: rec.value,
                    label: rec.name
                });
            })
        }
        else if (defaultGroup === 'dateFilter') {
            datefilterType.map(rec => {
                return options.push(rec);
            })
        }
        else if (defaultGroup === 'inboundSource') {
            props.dealersettings.client.integrations && _.map(props.dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true && e.appraisal === true), function (el) {
                return options.push({
                    label: el.name,
                    value: el.value
                })
            })
        }
        setGroupoptions(options);
    }, [props, defaultGroup]);

    const handleReportTypeChange = (e, val) => {
        e.preventDefault();
        setReportData([])
        setReport({
            ...report,
            'reportType': val,
            'columns': [],
            'groupSubtype': [],
            'calculationType': (val === 'target' || val === 'column') ? 'count' : ''
        })
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')
            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'groupBy') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['groupSubtype']: [],
                //['columnType']: '',
                //['columns']: ''
            })
        }
        else if (data.name === 'calculationType' || data.name === 'columnType') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['columns']: ''
            })
        }
        else if (data.name === 'modelGroupBy') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['model']: '',
                ['modelType']: '',
                ['group']: ''
            })
        }
        else {
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })
        }


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleEditNumberChange = e => {
        const { name, value } = e.target;
        var regexp = /^[0-9+ \b]+$/;
        let number = value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setReport({
                ...report,
                [name]: number !== '' ? Number(number) : number
            })
        }
    };

    const handleCheckedChange = (e) => {
        setReport({
            ...report,
            [e.target.name]: e.target.checked
        })
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        if (name === 'pipeline') {
            if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
                value = pipelineOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
            }
            else {
                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                    value.push(data.value);
                })
            }
        } else if (name === 'clientIDs') {
            if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions.some(item => item.value === 'all')) {
                value = clientOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
            }
            else {
                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                    value.push(data.value);
                })
            }
        } else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
            if (value.length > 1 && value[value.length - 1] === 'all') {
                value = ['all']
            }

            if (value.length > 1 && value.some(item => item === 'all')) {
                value = value.filter(item => item !== 'all')
            }

            // if (value.length > 1 && value[value.length - 1] === 'LeadsBucket') {
            //     value = ['LeadsBucket']
            // }

            // if (value.length > 1 && value.some(item => item === 'LeadsBucket')) {
            //     value = value.filter(item => item !== 'LeadsBucket')
            // }
        }
        if (name === 'groupSubtype') {
            setReport({
                ...report,
                [name]: value,
                ['model']: []
            });
        }
        else {
            setReport({
                ...report,
                [name]: value,
                //['groupBy']: name === 'pipeline' ? '' : report.groupBy,
                //['groupSubtype']: name === 'pipeline' ? '' : report.groupSubtype
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
        // if (name === 'pipeline') {
        //     setstageColumns(value, [], [])
        //     // setGroupoptions([]);
        //     // setDefaultGroup('')
        // }
    }

    const saveReport = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let mandatoryFields = ['name', 'groupBy', 'groupSubtype', 'columns', 'clientIDs']
        if (report.reportType === 'target' || report.reportType === 'column') {
            mandatoryFields = [...mandatoryFields, 'calculationType']
        }
        for (let [key] of Object.entries(report)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(report[key].toString().trim())) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        setLoader(true)
        window.firebase.firestore().doc(`tradeinProReports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newReport === true ? 'Report added successfully' : 'Report updated successfully'), {
                    duration: 2000
                })
                props.handleClose(report, newReport);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }

    const handleModelClose = () => {
        setShowmodel(false)
    }

    const handlePropsModelClose = () => {
        if (newReport || JSON.stringify(report) === JSON.stringify(oldReport)) {
            props.handleClose()
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'You want to discard changes.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then(result => {
                if (result.value) {
                    props.handleClose()
                }
            });
        }
    }

    // const setstageColumns = (pipeline, source, fields) => {
    //     let _leadSource = source
    //     let _sourceFields = fields
    //     if (pipeline.length === 1 && props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === pipeline[0])) {
    //         props.dealersettings.client.tradeinPro.pipelines.find(a => a.value === pipeline[0]).stages.forEach(rec => {
    //             if (rec.value !== 'InboundLead') {
    //                 _leadSource.push({
    //                     label: <>{rec.name}<span className="user-role-span">Stage</span></>,
    //                     searchlabel: `${rec.name} Stage`,
    //                     value: `stage-${rec.value}`,
    //                     name: rec.name,
    //                     view: ['table', 'column', 'pie', 'target'],
    //                     calculationType: ['count'],
    //                     color: '#4967CC',
    //                     sourceType: 'Stage',
    //                 })
    //                 _sourceFields.push({
    //                     name: rec.name,
    //                     value: `stage-${rec.value}`,
    //                     flex: 1,
    //                     sourceType: 'Stage'
    //                 })
    //             }
    //         })
    //         setReportColumns([...reportColumns.filter(a => a.sourceType !== 'Stage'), ..._leadSource]);
    //         setReportFields([...reportFields.filter(a => a.sourceType !== 'Stage'), ..._sourceFields])
    //         setLoadSettings(true);
    //     }
    //     else {
    //         setReportColumns([...reportColumns.filter(a => a.sourceType !== 'Stage'), ..._leadSource]);
    //         setReportFields([...reportFields.filter(a => a.sourceType !== 'Stage'), ..._sourceFields])
    //         setLoadSettings(true);
    //     }
    // };

    const getLeadSourceCount = () => {
        let leadSourceData = {};
        // if (_.isArray(report.pipeline) && report.pipeline.length === 1 && pipelineOptions.find(a => a.value === report.pipeline[0])) {
        //     pipelineOptions.find(a => a.value === report.pipeline[0]).stages.forEach(rec => {
        //         leadSourceData = {
        //             ...leadSourceData,
        //             [`stage-${rec.value}`]: Math.floor(Math.random() * 100)
        //         }
        //     })
        // }
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.tradeinPro &&
            props.dealersettings.client.tradeinPro.tradeinProStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`status-${rec.value}`]: Math.floor(Math.random() * 100),
                        [`status-${rec.value}Conv`]: Math.floor(Math.random() * 50) + '%',
                    }
                })
        return leadSourceData;
    }


    return _.isEmpty(report) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right"
                            onClick={() => handlePropsModelClose()}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen h-100">
                            <div className="container-fluid pl-0 ">
                                <div className="add-trigger-wrapper">

                                    <div className="add-trigger-leftmenu">
                                        <h3> <Translate text={'Report Type'} /></h3>
                                        <ul className="ul-nav" role="tablist">
                                            <li className="li-nav">
                                                <a href="#" className={`li-a-nav ${report.reportType === 'table' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'table')}>
                                                    <i className="ico ico icon-table-chart"></i>Table View</a>
                                            </li>
                                            {/* {
                                                !Boolean(report.static) ? (
                                                    <>
                                                        <li className="li-nav">
                                                            <a href="#" className={`li-a-nav ${report.reportType === 'column' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'column')}>
                                                                <i className="ico ico icon-bar-chart"></i>Column Chart</a>
                                                        </li>
                                                        <li className="li-nav">
                                                            <a href="#" className={`li-a-nav ${report.reportType === 'pie' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'pie')}>
                                                                <i className="ico ico icon-pie-chart"></i>Pie Chart</a>
                                                        </li>
                                                        <li className="li-nav">
                                                            <a href="#" className={`li-a-nav ${report.reportType === 'target' ? 'active' : ''}`} onClick={(e) => handleReportTypeChange(e, 'target')}>
                                                                <i className="ico ico icon-line-chart"></i>Target Report</a>
                                                        </li>
                                                    </>
                                                ) : (<></>)
                                            } */}

                                        </ul>
                                    </div>
                                    <div className='add-trigger-column-wraper'>
                                        <div className="add-trigger-columns h-100" >
                                            <div className="add-report-column-left border-right">
                                                <div className="add-trigger-column-title">
                                                    <h3> <Translate text={'Configure Data'} /></h3>
                                                </div>
                                                <div className="trigger-form-wraper form-style">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label > Name <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={`name`}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={report.name}
                                                            />
                                                        </div>
                                                        {/* <div className="form-group col-md-12">
                                                            <label >Pipeline <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <ReactMultiSelect
                                                                options={pipelineOptions}
                                                                name={"pipeline"}
                                                                placeholder={'select pipeline'}
                                                                onChange={(option) => handleReactMultiSelectChange(option, 'pipeline')}
                                                                value={report.pipeline}
                                                                classNamePrefix={` ${errorFields["pipeline"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div> */}
                                                        <div className="form-group col-md-12">
                                                            <label >{'Clients'} <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <ReactMultiSelect
                                                                options={clientOptions}
                                                                name={'clientIDs'}
                                                                placeholder={'select client'}
                                                                onChange={(option) => handleReactMultiSelectChange(option, 'clientIDs')}
                                                                value={report['clientIDs']}
                                                                classNamePrefix={` ${errorFields['clientIDs']} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label> Displaying <span style={{ color: '#FF0000' }}>*</span></label>
                                                            <ReactSelect
                                                                options={dynReportGroupBy.filter(a => a.value !== 'dataQuality')}
                                                                name={"groupBy"}
                                                                placeholder={'select displaying'}
                                                                onChange={(e, data) => {
                                                                    if (e)
                                                                        setDefaultGroup(e.value)
                                                                    handleSelectChange(e, data)
                                                                }}
                                                                value={report.groupBy}
                                                                classNamePrefix={`${errorFields["groupBy"]} basic-select`}
                                                                removeClearable={true}
                                                                isDisabled={Boolean(report.static)}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            {
                                                                (report.reportType === 'table') ? (<label>{_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayName : 'Segment by'} <span style={{ color: '#FF0000' }}>*</span></label>) :
                                                                    (<label>Segment by <span style={{ color: '#FF0000' }}>*</span></label>)
                                                            }
                                                            {
                                                                (report.groupBy === 'dateFilter') ? (
                                                                    <ReactSelect
                                                                        options={groupOptions}
                                                                        name={"groupSubtype"}
                                                                        placeholder={`select ${_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayValue : ''}`}
                                                                        onChange={handleSelectChange}
                                                                        value={report.groupSubtype}
                                                                        classNamePrefix={`${errorFields["groupSubtype"]} basic-select`}
                                                                    >
                                                                    </ReactSelect>
                                                                ) : (
                                                                    <ReactMultiSelect
                                                                        options={groupOptions}
                                                                        name={"groupSubtype"}
                                                                        placeholder={`select ${_.find(dynReportGroupBy, { 'value': defaultGroup }) ? _.find(dynReportGroupBy, { 'value': defaultGroup }).displayValue : ''}`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'groupSubtype')}
                                                                        value={_.isArray(report.groupSubtype) ? report.groupSubtype : []}
                                                                        classNamePrefix={`${errorFields["groupSubtype"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                )
                                                            }

                                                        </div>
                                                        {
                                                            report.groupBy === 'model' ? (
                                                                <div className="form-group col-md-12">
                                                                    <label>Group By</label>
                                                                    <ReactSelect
                                                                        options={modelGroupTypes}
                                                                        name={"modelGroupBy"}
                                                                        placeholder={`select group by`}
                                                                        onChange={handleSelectChange}
                                                                        value={report.modelGroupBy}
                                                                        classNamePrefix={`${errorFields["modelGroupBy"]} basic-select`}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (report.modelGroupBy === 'group') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.group) && report.group.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Group'); setModelType('group'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Group</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.uniqBy(_.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.group,
                                                                                    value: model.group
                                                                                }
                                                                            }), 'value') : []
                                                                        }
                                                                        name={"group"}
                                                                        placeholder={`select group`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'group')}
                                                                        value={_.isArray(report.group) ? report.group : []}
                                                                        classNamePrefix={`${errorFields["group"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (report.modelGroupBy === 'type') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.modelType) && report.modelType.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Type'); setModelType('modelType'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Type</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.uniqBy(_.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.type,
                                                                                    value: model.type
                                                                                }
                                                                            }), 'value') : []
                                                                        }
                                                                        name={"modelType"}
                                                                        placeholder={`select type`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'modelType')}
                                                                        value={_.isArray(report.modelType) ? report.modelType : []}
                                                                        classNamePrefix={`${errorFields["modelType"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            report.groupBy === 'model' && report.groupSubtype && report.groupSubtype.length === 1 && report.groupSubtype.some(a => a !== 'all') &&
                                                                (!_.isEmpty(report.model) || report.modelGroupBy === 'model') ? (
                                                                <div className="form-group col-md-12">
                                                                    {
                                                                        report.model && _.isArray(report.model) && report.model.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Models'); setModelType('model'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                                    }
                                                                    <label>Model</label>
                                                                    <ReactMultiSelect
                                                                        options={_.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0] ?
                                                                            _.filter(props.dealersettings.allMakeModels, (v) => _.indexOf(report.groupSubtype, v.value) >= 0)[0].models.map(model => {
                                                                                return {
                                                                                    label: model.name,
                                                                                    value: model.value
                                                                                }
                                                                            }) : []
                                                                        }
                                                                        name={"model"}
                                                                        placeholder={`select model`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'model')}
                                                                        value={_.isArray(report.model) ? report.model : []}
                                                                        classNamePrefix={`${errorFields["model"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                </div>
                                                            ) : (<></>)

                                                        }
                                                        {
                                                            (report.reportType === 'target' || report.reportType === 'column') ? (
                                                                <div className="form-group col-md-12">
                                                                    <label> Calculation Type </label>
                                                                    <ReactSelect
                                                                        options={calculateOptions}
                                                                        name={"calculationType"}
                                                                        placeholder={'select calculation type'}
                                                                        onChange={handleSelectChange}
                                                                        value={report.calculationType}
                                                                        classNamePrefix={`${errorFields["calculationType"]} basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        {
                                                            (report.reportType === 'table') ? (<></>) : (
                                                                <div className="form-group col-md-12">
                                                                    <label>View by</label>
                                                                    <ReactSelect
                                                                        options={displayOptions}
                                                                        name={"displayBy"}
                                                                        placeholder={'select'}
                                                                        onChange={handleSelectChange}
                                                                        value={report.displayBy}
                                                                        classNamePrefix={`basic-select`}
                                                                        removeClearable={false}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            (report.reportType === 'target' && (_.isEmpty(report.calculationType) || report.calculationType !== 'duration')) ?
                                                                (<><div className="form-group col-md-12">
                                                                    <label> Target </label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={`target`}
                                                                        className={`form-control ${errorFields["target"]}`}
                                                                        name="target"
                                                                        onChange={handleEditNumberChange}
                                                                        value={report.target}
                                                                    />
                                                                </div>
                                                                </>) : (<></>)
                                                        }
                                                        <div className="form-group col-md-12">
                                                            {
                                                                report.columns && _.isArray(report.columns) && report.columns.length > 1 ? (<a href="#" onClick={() => { setPopHeader('Rearrange Columns'); setModelType('column'); setShowmodel(true) }} className="inputlink-addmore custom-more"><i className="ico icon-rearrange rearrange-mini"></i>rearrange</a>) : (<></>)
                                                            }
                                                            {
                                                                report.reportType === 'table' ? (<label>Columns <span style={{ color: '#FF0000' }}>*</span></label>) :
                                                                    (<label>Measure by <span style={{ color: '#FF0000' }}>*</span></label>)
                                                            }
                                                            {
                                                                report.reportType === 'pie' || report.groupBy === 'lostReasons' ? (
                                                                    <ReactSelect
                                                                        options={report.groupBy === 'lostReasons' ? reportColumns.filter(a => a.value === 'total' && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)) : reportColumns.filter(a => a.view.indexOf(report.reportType) >= 0
                                                                            && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)
                                                                        )}
                                                                        name={"columns"}
                                                                        placeholder={`select columns`}
                                                                        onChange={handleSelectChange}
                                                                        value={_.isArray(report.columns) ? report.columns[0] : report.columns}
                                                                        classNamePrefix={`${errorFields["columns"]} basic-select`}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                ) : (
                                                                    <ReactMultiSelect
                                                                        options={report.groupBy === 'lostReasons' ? reportColumns.filter(a => a.value === 'total' && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)) : reportColumns.filter(a => a.view.indexOf(report.reportType) >= 0
                                                                            && (!_.isEmpty(report.calculationType) ? a.calculationType.indexOf(report.calculationType) >= 0 : true)
                                                                        )}
                                                                        name={"columns"}
                                                                        placeholder={`select columns`}
                                                                        onChange={(option) => handleReactMultiSelectChange(option, 'columns')}
                                                                        value={report.columns}
                                                                        classNamePrefix={`${errorFields["columns"]} basic-select`}
                                                                        isMulti={true}
                                                                        isSearchable={true}
                                                                    >
                                                                    </ReactMultiSelect>
                                                                )
                                                            }

                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label> Visibility </label>
                                                            <ReactSelect
                                                                options={visibilities}
                                                                name={"visibility"}
                                                                placeholder={'select visibility'}
                                                                onChange={handleSelectChange}
                                                                value={report.visibility}
                                                                classNamePrefix={`basic-select`}
                                                                removeClearable={true}
                                                                isSearchable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="trigger-form-footer">
                                                            <span className="switch switch-sm float-left">
                                                                <InputCheckBox
                                                                    className={`switch`}
                                                                    id={`report-includeZero`}
                                                                    name={'includeZero'}
                                                                    checked={report.includeZero}
                                                                    onChange={(e) => { handleCheckedChange(e) }} />
                                                                <label htmlFor={`report-includeZero`}>Include Zero Values</label>
                                                            </span>
                                                            <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { saveReport() }}>
                                                                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                <Translate text={'save'} />
                                                            </button>
                                                            <button type="button" className="btn btn-default float-right" onClick={() => { handlePropsModelClose() }} > <Translate text={'cancel'} /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="settings-column-right">
                                                <div className="add-trigger-column-title">
                                                    <h3> <Translate text={'Report Preview'} />
                                                        <span className="pipeline-inactive-span"> (Sample Data)</span>
                                                    </h3>
                                                </div>
                                                <div className="trigger-form-wraper form-style">
                                                    {
                                                        reportData && reportData.length === 0 ? (
                                                            <div className="common-table">
                                                                <div className="text-center p-5">
                                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                    <div className="no-data-txt mt-2"> No records found</div>
                                                                </div>
                                                            </div>
                                                        ) : report.reportType === 'table' ? (
                                                            <div className="common-table">
                                                                <TableView
                                                                    isReorderable={true}
                                                                    datalist={reportData}
                                                                    height={windowSize.tblHeight}
                                                                    width={windowSize.tblWidth}
                                                                    columns={reportFields}
                                                                    dynamicFields={reportFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                                    handleSavesettings={handleSavesettings}
                                                                />
                                                            </div>
                                                        ) : report.reportType === 'column' ?
                                                            (
                                                                <div className="text-center">
                                                                    <BarChart
                                                                        id={'report-bar-chart'}
                                                                        height={`${windowSize.tblHeight}px`}
                                                                        data={reportData}
                                                                        columns={report.columns ? _.isArray(report.columns) ? report.columns : [report.columns] : []}
                                                                        dynamicColumns={reportColumns}
                                                                        xCategory={'name'}
                                                                        calculationType={report.calculationType}
                                                                        handleShowEnquiryList={(value, startDate, endDate) => {
                                                                            // props.handleShowEnquiryList({
                                                                            //     'origin': value,
                                                                            //     'status': 'all',
                                                                            //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                            //     'startDate': startDate,
                                                                            //     'endDate': endDate,
                                                                            //     'dateType': 'addedDate'
                                                                            // })
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : report.reportType === 'pie' ?
                                                                (
                                                                    <div className="text-center">
                                                                        <PieChart
                                                                            id={'report-pie-chart'}
                                                                            height={`${windowSize.tblHeight}px`}
                                                                            data={reportData}
                                                                            columns={_.isArray(report.columns) ? report.columns[0] : report.columns}
                                                                            handleShowEnquiryList={(value, startDate, endDate) => {
                                                                                // props.handleShowEnquiryList({
                                                                                //     'origin': value,
                                                                                //     'status': 'all',
                                                                                //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                                //     'startDate': startDate,
                                                                                //     'endDate': endDate,
                                                                                //     'dateType': 'addedDate'
                                                                                // })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : report.reportType === 'target' ?
                                                                    (
                                                                        <div className="text-center">
                                                                            <LineChart
                                                                                id={'report-line-chart'}
                                                                                height={`${windowSize.tblHeight}px`}
                                                                                data={reportData}
                                                                                columns={report.target > 0 ? (_.isArray(report.columns) ? [...report.columns, 'target'] : [report.columns, 'target']) : (_.isArray(report.columns) ? report.columns : [report.columns])}
                                                                                dynamicColumns={reportColumns}
                                                                                calculationType={report.calculationType}
                                                                                handleShowEnquiryList={(value, startDate, endDate) => {
                                                                                    // props.handleShowEnquiryList({
                                                                                    //     'origin': value,
                                                                                    //     'status': 'all',
                                                                                    //     'pipeline': _.map(props.pipeline, function (e) { return e.value; }),
                                                                                    //     'startDate': startDate,
                                                                                    //     'endDate': endDate,
                                                                                    //     'dateType': 'addedDate'
                                                                                    // })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (<></>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <PopUpModal show={showModel}>
                <RearrangeColumns
                    {...props}
                    show={showModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    type={modelType}
                    columns={report.columns}
                    filters={report.filters}
                    reportColumns={reportColumns}
                    report={report}
                    setColumns={(fields) => {
                        setReport({
                            ...report,
                            ['columns']: fields
                        })
                        setReportFields([
                            ..._.filter(reportFields, (v) => _.indexOf(['name'], v.value) >= 0).map(rec => {
                                return {
                                    ...rec,
                                    default: true
                                }
                            }),
                            ...fields.map(rec => {
                                return {
                                    ..._.find(reportFields, { value: rec }),
                                    default: true
                                }
                            }),
                            ..._.filter(reportFields, (v) => _.indexOf(fields, v.value) < 0 && v.value !== 'name').map(rec => {
                                return {
                                    ...rec,
                                    default: false
                                }
                            }),
                        ])
                    }}
                    setFilters={(fields) => {
                        setReport({
                            ...report,
                            ['filters']: fields
                        })
                    }}
                    setReport={(fields, name) => {
                        setReport({
                            ...report,
                            [name]: fields
                        })
                    }}
                />
            </PopUpModal>
        </>
    )
}

export default AddDynamicReport