import React, { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import ReactExport from 'react-export-excel-xlsx-fix';
//import NumberFormat from 'react-number-format';
import { ContentOverlay, PopUpModal, TableView, InputCheckBox, ReactSelect, UpgradePlan } from '../../components'
import Translate from '../../constants/translate';
import { stockStatus } from '../../services/enum'
import FleetReducer from './fleetReducer'
import AddFleet from '../fleet/add'
import AddTestDrive from '../testdrive/add'
import AddStock from '../stock/add'
import CommonHelper from '../../services/common';
import ReAuthenticate from '../common/reAuthenticate';
import { objFleetStockVM, allFleetStockFieldsVM, allStockFleetFieldsVM } from './viewModel'
import { objStock } from '../stock/viewModel'
import LoanBookings from './loanBookings';
//import FleetVehicles from './list/index'
import FleetVehiclesList from './list/loanList'
import { firestoreDB } from '../../services/helper';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FleetListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
    const [fleets, dispatch] = useReducer(FleetReducer, { Available: [], Loaned: [], MyLoaned: [] })
    const [checkDataLoad, setDataload] = useState({ "Available": true, "Loaned": true, "MyLoaned": true })
    const [hasMore, setHasMoreData] = useState([])
    const [stockFleetFields, setStockFleetFields] = useState(props.dealersettings.stockFleetFields ? props.dealersettings.stockFleetFields : [])
    const [fleetFields, setFleetFields] = useState(props.dealersettings.fleetFields ? props.dealersettings.fleetFields : [])
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelStockShow, setModelStockShow] = useState(false)
    const [modelFleetShow, setModelFleetShow] = useState(false)
    const [modelTestDriveShow, setModelTestDriveShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [editClientID, setEditClient] = useState()
    const [modelStock, setModelStock] = useState({})
    const [isPaging, setPaging] = useState(false)
    const [fleetLoader, setLoader] = useState({ "Available": true, "Loaned": true, "MyLoaned": true })
    const pageLimit = 50
    const [activeTab, setActiveTab] = useState('Available')
    const [searchText, setSearchText] = useState(localStorage.fleetSearchText ? localStorage.fleetSearchText : '')
    const [filterStatus, setFilterStatus] = useState(localStorage.fleetStockStatus ? localStorage.fleetStockStatus : '')

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const [serviceLoanOnly, setServiceLoan] = useState(localStorage.serviceLoanOnly ? JSON.parse(localStorage.serviceLoanOnly) : false)

    const [csvHeader, setHeader] = useState([])
    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers;
    const enableSharedStock = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedStock) ? true : false);

    const clientSettings = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings)) ? props.dealersettings.client.clientSettings : null;

    const enableLoanBooking = (!_.isEmpty(clientSettings) && clientSettings.enableLoanBooking) ? true : false;
    const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion) ? true : false;
    const stockLocations = clientSettings?.stockLocations

    const [csvData, setCSVData] = useState([])
    const [loanBookingsCounts, setLoanBookingsCounts] = useState({})
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);
    const [showLoanBookings, setShowLoanBookings] = useState({
        show: false,
        data: null,
        title: 'Loan Bookings'
    });
    const [reloadLoanBookingCounts, setReloadLoanBookingCounts] = useState(false);

    const loan_type = [
        { value: 'onLoan', label: <Translate text={'onLoan'} /> },
        { value: 'onServiceLoan', label: <Translate text={'On Service Loan'} /> },
        { value: 'onTestdrive', label: <Translate text={'onTestDrive'} /> }
    ]
    const fleetTags = ["Overdue", "Today", "OnLoan"]
    const [fleetCounts, setFleetCounts] = useState({})
    const [activityCountsLoader, setActivityCountsLoader] = useState(false)
    const [showFleets, setShowFleets] = useState({
        show: false,
        type: null
    });


    useEffect(() => {
        fetchFleetCounts();
    }, [])

    const fetchFleetCounts = async () => {
        //console.log('fetchCounts', reload)

        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false


        let _filter = {
            clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }

        if (ownerDashboard) {
            _filter.owner = localStorage.uid
        }

        let _searchObject = {
            type: "getFleetCount",
            params: JSON.stringify(_filter),
        }
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        //console.log('resp getFleetCount, ', resp)
        if (resp.data.success) {
            console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            console.log(`data of the counts`, data)
            setFleetCounts(data)
            //setActivityCountsLoader(false)

        } else {
            // setActivityCountsLoader(false)

        }
    }

    useEffect(() => {
        const fetchCounts = async () => {
            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            let _searchObject = {
                type: "getLoanBookingCount",
                params: JSON.stringify(_filter),
            }
            const getData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await getData(_searchObject);
            if (resp.data.success) {
                const data = resp.data.data[0]
                setLoanBookingsCounts(data)
            } else {
            }
        }

        try {
            fetchCounts()
        } catch (error) {
            console.error(error)
        }
    }, [reloadLoanBookingCounts])

    useEffect(() => {
        let headerFields = (activeTab === 'Available') ? stockFleetFields : fleetFields;
        let allHeaderFields = (activeTab === 'Available') ? allStockFleetFieldsVM : allFleetStockFieldsVM;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subText, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })

        setHeader(CommonHelper.bindContactName(_headers, 'contactFullName'));
        //setHeader(_headers);

    }, [fleetFields, activeTab])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            const setttings = props.dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            props.dealersettings.languages && props.dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            props.dealersettings.nationalities && props.dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            setLanguages(_languages);
            setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 170)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubscribeFleetList && window.unSubscribeFleetList();
        }
    }, [])

    useEffect(() => {

        if (!checkDataLoad[activeTab]) {
            return;
        }

        let refStockData = window.firebase.firestore().collection('stock')
            .where('isDeleted', '==', false)

        if (!_.isEmpty(stockLocations)) refStockData = refStockData.where('clientID', 'in', CommonHelper.arrLimit(_.uniq(stockLocations), 25))
        else if (enableSharedStock && props.dealersettings.client.group)
            refStockData = refStockData.where('groupID', '==', props.dealersettings.client.group)
        else
            refStockData = refStockData.where('clientID', '==', props.dealersettings.client.id)


        if (searchText && searchText.trim()) {
            refStockData = refStockData
                .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        }

        if (activeTab === 'Available') {
            refStockData = refStockData
                .where('status', '==', stockStatus.AVAILABLE)
                .where('isLoan', '==', true)

            if (serviceLoanOnly)
                refStockData = refStockData
                    .where('isServiceLoan', '==', true)
        }
        else {
            if (!_.isEmpty(filterStatus) && filterStatus === 'onServiceLoan')
                refStockData = refStockData.where("status", "==", stockStatus.ONLOAN).where('isServiceLoan', '==', true)
            else if (!_.isEmpty(filterStatus))
                refStockData = refStockData.where("status", "==", filterStatus)
            else
                refStockData = refStockData.where("status", "in", [stockStatus.ONLOAN, stockStatus.ONTESTDRIVE])
        }
        //console.log(activeTab, filterStatus)
        refStockData = refStockData
            .orderBy('modifiedDate', 'desc')

        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            refStockData = refStockData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refStockData = refStockData
                .limit(pageLimit)
        }

        window.unSubscribeFleetList = refStockData
            .onSnapshot(onCollectionUpdate);

        // const _fleets = collectionData(refStockData)
        //     .pipe(
        //         switchMap(activitySnapshot => {
        //             return combineLatest(
        //                 of(activitySnapshot),
        //             )
        //         }),
        //         map(([activitySnapshot]) => {
        //             return activitySnapshot.map(activityDoc => {
        //                 return {
        //                     ...activityDoc
        //                 }
        //             })
        //         })
        //     )
        //     .subscribe(_fleets => {
        //         onCollectionUpdate(_fleets)
        //     });
        // return () => {
        //     _fleets.unsubscribe()
        // }

    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        const __fleets = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                __fleets.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: __fleets,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : __fleets,
                activetab: activeTab
            });
        }

        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...fleetLoader,
            [activeTab]: false
        })
        setPaging(false)
    }

    // const onCollectionUpdate = (_fleets) => {
    //     //console.log('onCollectionUpdate', activeTab)
    //     const __fleets = [];
    //     if (_fleets.length > 0 && _fleets.length === pageLimit) {
    //         setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
    //         {
    //             type: activeTab,
    //             lastDoc: _fleets[_fleets.length - 1]
    //         }])
    //     }
    //     else {
    //         setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
    //     }
    //     _fleets.forEach(function (doc) {
    //         var dataVM = dataMappingVM(doc);
    //         __fleets.push(dataVM)
    //     });
    //     dispatch({
    //         type: isPaging ? "APPEND_LIST" : "SUCCESS_LIST",
    //         data: __fleets,
    //         activetab: activeTab
    //     });
    //     setDataload({
    //         ...checkDataLoad,
    //         [activeTab]: false
    //     })
    //     setLoader({
    //         ...fleetLoader,
    //         [activeTab]: false
    //     })
    //     setPaging(false)
    // }

    const handleDiffDays = (_date) => {
        const difference = moment().diff(moment.unix(_date.seconds), 'days')
        if (difference > 1 || difference < -1)
            return difference + ' Days';
        else
            return difference + ' Day';

    }

    const dataMappingVM = (doc) => {
        let dealersettings = props.dealersettings;
        const objlogData = Object.assign({}, doc);
        const stockVM = Object.assign({}, objFleetStockVM);
        for (let [key, value] of Object.entries(objlogData)) {
            stockVM[key] = value;
        }


        if (!_.isEmpty(stockLocations) || (activeTab === 'Available' && enableSharedStock && stockVM.clientID && (stockVM.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem'))) {
            stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.clientID);
        }
        else
            stockVM.dealerName = '';

        stockVM.keywords = doc.keywords
        stockVM.createdOn = stockVM.addedDate ? moment.unix(stockVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        stockVM.updatedOn = stockVM.modifiedDate ? moment.unix(stockVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '--';
        stockVM.addedBy = stockVM.addedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.addedBy) : '--';
        stockVM.modifiedBy = stockVM.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.modifiedBy) : '--';
        stockVM.statusUpdatedBy = stockVM.statusModifiedBy ? CommonHelper.getUserNamebyId(allUsers, stockVM.statusModifiedBy) : '';

        if (!_.isEmpty(stockVM.regDate))
            stockVM.regDate = moment.unix(stockVM.regDate.seconds).format('DD/MM/YYYY');

        if (!_.isEmpty(stockVM.warrantyExp))
            stockVM.warrantyExp = moment.unix(stockVM.warrantyExp.seconds).format('DD/MM/YYYY');


        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            stockVM.location = CommonHelper.getNameByValue(dealersettings.client.settings.stockLocation, stockVM.location, '');
            stockVM.saleType = CommonHelper.getNameByValue(dealersettings.client.settings.salesType, stockVM.saleType, '');
            stockVM.statusName = CommonHelper.getNameByValue(dealersettings?.client?.settings?.stockStatus, stockVM.status, '');
            stockVM.stockStatus = CommonHelper.bindStockLabel(dealersettings?.client?.settings?.stockStatus, stockVM.status, '');
        }

        if (stockVM.status === stockStatus.ONLOAN && !_.isEmpty(stockVM.fleetDetail)) {
            if (!_.isEmpty(stockVM.fleetDetail.contact)) {
                let _contact = Object.assign({}, stockVM.fleetDetail.contact);

                stockVM.contactFullName = CommonHelper.displayFullContact(titles, _contact);
                stockVM.contactName = <div onClick={(e) => {
                    e.preventDefault();
                    const url = `/contacts/details/${_contact.documentID}`;
                    window.open(url, '_blank');
                }}>
                    {stockVM.contactFullName}</div>;

                stockVM.firstName = (_contact.firstName || '');
                stockVM.middleName = (_contact.middleName || '');
                stockVM.lastName = (_contact.lastName || '');
                stockVM.contactPhone = CommonHelper.getFullPhone(_contact.phoneCode, _contact.phone);
                stockVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
                stockVM.contactEmail = _contact.email;
                stockVM.licenseNo = _contact.licenseNo;
                stockVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
                stockVM.contactType = _contact.contactType;
                stockVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
                stockVM.gender = _contact.gender;
                stockVM.maritalStatus = _contact.maritalStatus;
                stockVM.contactMethod = _contact.contactMethod;
                stockVM.licenseType = _contact.licenseType;
                stockVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
                stockVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
                stockVM.campaign = CommonHelper.getLabelByValue(campaigns, _contact.campaign, '');
                stockVM.origin = CommonHelper.getLabelByValue(origins, _contact.origin, '');
                stockVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');

                if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                    stockVM.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
                    stockVM.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
                    stockVM.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
                    stockVM.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
                    stockVM.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
                }

                stockVM.address = _contact.address;
                stockVM.companyName = _contact.companyName;
                stockVM.designation = _contact.designation;

                stockVM.licenseState = _contact.licenseState;
                stockVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
                stockVM.secondaryContactPhone = CommonHelper.getFullPhone(_contact.secondaryPhoneCode, _contact.secondaryPhone);
                stockVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
                stockVM.secondaryContactEmail = _contact.secondaryEmail;
                stockVM.contactCreatedBy = _contact.addedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.addedBy) : '';
                stockVM.contactModifiedby = _contact.modifiedBy ? CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy) : '';
                stockVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                stockVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }
            else if (!_.isEmpty(stockVM.fleetDetail.staff)) {
                stockVM.contactFullName = stockVM.fleetDetail.staff.name ? stockVM.fleetDetail.staff.name : '';
                stockVM.contactName = stockVM.contactFullName;
            }

            if (stockVM.fleetDetail.fleetID)
                stockVM.fleetID = stockVM.fleetDetail.fleetID;

            stockVM.startDate = stockVM.fleetDetail.startDate ? moment.unix(stockVM.fleetDetail.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.expectedReturn = stockVM.fleetDetail.expectedReturn ? moment.unix(stockVM.fleetDetail.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.fleetAddedBy = CommonHelper.getUserNamebyId(allUsers, stockVM.fleetDetail.addedBy);
            stockVM.fleetOwner = stockVM.fleetDetail.addedBy;
            stockVM.fleetAddedDate = stockVM.fleetDetail.addedDate ? moment.unix(stockVM.fleetDetail.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.roNumber = stockVM.fleetDetail.roNumber ? stockVM.fleetDetail.roNumber : '';
            stockVM.loanType = stockVM.fleetDetail.loanType ? stockVM.fleetDetail.loanType : '';

            if (stockVM.fleetDetail.clientID && (!_.isEmpty(stockLocations) ||
                (activeTab !== 'Available' && enableSharedStock && (stockVM.fleetDetail.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')))) {
                stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.fleetDetail.clientID);
            }

        }

        if (stockVM.status === stockStatus.ONTESTDRIVE && !_.isEmpty(stockVM.testDriveDetail)) {
            if (!_.isEmpty(stockVM.testDriveDetail.contact)) {
                let _contact = Object.assign({}, stockVM.testDriveDetail.contact);
                stockVM.contactFullName = CommonHelper.displayFullContact(titles, _contact);
                stockVM.contactName = <div onClick={(e) => {
                    e.preventDefault();
                    const url = `/contacts/details/${_contact.documentID}`;
                    window.open(url, '_blank');
                }}>{stockVM.contactFullName}</div>;

                stockVM.firstName = (_contact.firstName || '');
                stockVM.middleName = (_contact.middleName || '');
                stockVM.lastName = (_contact.lastName || '');
                stockVM.contactPhone = CommonHelper.getFullPhone(_contact.phoneCode, _contact.phone);
                stockVM.phone = _contact.phone ? (_contact.phoneCode ? _contact.phoneCode : '') + _contact.phone : '';
                stockVM.contactEmail = _contact.email;
                stockVM.licenseNo = _contact.licenseNo;
                stockVM.licenseExpiry = _contact.licenseExpiry ? moment(_contact.licenseExpiry).format('DD/MM/YYYY') : '';
                stockVM.contactType = _contact.contactType;
                stockVM.dob = _contact.dob ? moment(_contact.dob).format('DD/MM/YYYY') : '';
                stockVM.gender = _contact.gender;
                stockVM.maritalStatus = _contact.maritalStatus;
                stockVM.contactMethod = _contact.contactMethod;
                stockVM.licenseType = _contact.licenseType;
                stockVM.isDLScan = _contact.isDLScan ? 'Yes' : 'No';
                stockVM.language = CommonHelper.getLabelByValue(languages, _contact.language, '');
                stockVM.campaign = CommonHelper.getLabelByValue(campaigns, _contact.campaign, '');
                stockVM.origin = CommonHelper.getLabelByValue(origins, _contact.origin, '');
                stockVM.nationality = CommonHelper.getLabelByValue(nationalities, _contact.nationality, '');


                if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                    stockVM.gender = CommonHelper.getNameByValue(dealersettings.client.settings.genders, _contact.gender, '');
                    stockVM.maritalStatus = CommonHelper.getNameByValue(dealersettings.client.settings.maritalStatus, _contact.maritalStatus, '');
                    stockVM.contactMethod = CommonHelper.getNameByValue(dealersettings.client.settings.contactMethod, _contact.contactMethod, '');
                    stockVM.interests = CommonHelper.getLabelByMultipleValues(dealersettings.client.settings.interests, _contact.interests, '', true);
                    stockVM.licenseType = CommonHelper.getNameByValue(dealersettings.client.settings.licenseType, _contact.licenseType, '');
                }

                stockVM.address = _contact.address;
                stockVM.companyName = _contact.companyName;
                stockVM.designation = _contact.designation;

                stockVM.licenseState = _contact.licenseState;
                stockVM.secondaryContactName = _contact.secondaryFirstName ? (_contact.secondaryFirstName + ' ' + _contact.secondaryLastName) : '';
                stockVM.secondaryContactPhone = CommonHelper.getFullPhone(_contact.secondaryPhoneCode, _contact.secondaryPhone);
                stockVM.secondaryPhone = _contact.secondaryPhone ? ((_contact.secondaryPhoneCode ? _contact.secondaryPhoneCode : '') + _contact.secondaryPhone) : '';
                stockVM.secondaryContactEmail = _contact.secondaryEmail;
                stockVM.contactCreatedBy = CommonHelper.getUserNamebyId(allUsers, _contact.addedBy);
                stockVM.contactModifiedby = CommonHelper.getUserNamebyId(allUsers, _contact.modifiedBy);
                stockVM.contactCreatedOn = _contact.addedDate ? moment.unix(_contact.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
                stockVM.contactModifiedOn = _contact.modifiedDate ? moment.unix(_contact.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            }

            if (stockVM.testDriveDetail.testdriveID)
                stockVM.testDriveID = stockVM.testDriveDetail.testdriveID;

            stockVM.startDate = stockVM.testDriveDetail.startDate ? moment.unix(stockVM.testDriveDetail.startDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.expectedReturn = stockVM.testDriveDetail.expectedReturn ? moment.unix(stockVM.testDriveDetail.expectedReturn.seconds).format('DD/MM/YYYY hh:mm A') : '';
            stockVM.fleetAddedBy = CommonHelper.getUserNamebyId(allUsers, stockVM.testDriveDetail.addedBy);
            stockVM.fleetOwner = stockVM.testDriveDetail.addedBy;
            stockVM.fleetAddedDate = stockVM.testDriveDetail.addedDate ? moment.unix(stockVM.testDriveDetail.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

            if (stockVM.testDriveDetail.clientID && (!_.isEmpty(stockLocations) ||
                (activeTab !== 'Available' && enableSharedStock && (stockVM.testDriveDetail.clientID !== props.dealersettings.client.id || localStorage.defaultModule === 'oem')))) {
                stockVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, stockVM.testDriveDetail.clientID);
            }
        }

        stockVM.stockInDays = ((!_.isEmpty(stockVM.stockInDate))
            ?
            handleDiffDays(stockVM.stockInDate)
            :
            '');

        // stockVM.stockStatus = (stockVM.status
        //     ?
        //     <div className={`badge badge-pill badge-${stockVM.status.trim().toLowerCase()}-fill `}>
        //         {stockVM.status.replace(/([A-Z])/g, ' $1').trim().toUpperCase()}
        //     </div>
        //     :
        //     <></>);
        stockVM.availability = (<div className="Vehicle-availability">
            {stockVM.isTestDrive ? <div><span>{'Test Drive'}</span></div> : <></>}
            {stockVM.isLoan ? <div><span>{'Loan'}</span></div> : <></>}
            {stockVM.isServiceLoan ? <div><span>{'Service Loan'}</span></div> : <></>}
            {stockVM.isSale ? <div><span>{'Sale'}</span></div> : <></>}
        </div>);

        stockVM.availabileFor = ((stockVM.isTestDrive ? 'Test Drive, ' : '') +
            (stockVM.isLoan ? 'Loan, ' : '') +
            (stockVM.isServiceLoan ? 'Service Loan, ' : '') +
            (stockVM.isSale ? 'Sale' : ''));

        return stockVM
    }

    const handleSavesettings = (fields, showToast) => {
        // if (liteVersion)
        //     return;

        setSettingsLoader(true)
        if (activeTab === 'Available') {
            window.firebase.firestore().doc(`/users/${localStorage.uid}`)
                .set({ stockFleetFields: fields }, { merge: true })
                .then(() => {
                    setStockFleetFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('stockFleetFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }
        else {
            window.firebase.firestore().doc(`/users/${localStorage.uid}`)
                .set({ fleetFields: fields }, { merge: true })
                .then(() => {
                    setFleetFields(fields)
                    setSettingsLoader(false)
                    props.updateDealerSettings('fleetFields', fields)
                    if (showToast)
                        toast.notify('Settings updated successfully', {
                            duration: 2000
                        })

                }).catch(error => {
                    setSettingsLoader(false)
                    console.error(error)
                });
        }
    }
    const handleEditFleet = async (id, obj) => {
        // if (liteVersion)
        //     return;
        //console.log('handleEditFleet', id, obj)
        let _stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(obj)) {
            if (_stock.hasOwnProperty(key))
                _stock[key] = value;
        }

        if (_stock.status === stockStatus.AVAILABLE) {
            if (isValidForFleet(_stock) === false)
                return;

            setModelFleetShow(true);
            setModelStock(_stock);
        }
        else if (_stock.status === stockStatus.ONTESTDRIVE && obj.testDriveID) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('testdrives').doc(obj.testDriveID).get();
            if (refData.exists) {
                setEditDocument(obj.testDriveID);
                setModelTestDriveShow(true);
                setModelStock(_stock);
            }
            else {
                updateStockStatus(id);
            }
        }
        else if (_stock.status === stockStatus.ONLOAN && obj.fleetID) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('fleet').doc(obj.fleetID).get();
            if (refData.exists) {
                setEditDocument(obj.fleetID);
                setModelFleetShow(true);
                setModelStock(_stock);
            }
            else {
                updateStockStatus(id);
            }

        }
    }

    const updateStockStatus = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, "Are you sure?"),
            text: CommonHelper.showLocale(props, "Do you want to return this vehicle?"),
            icon: "info",
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, "Yes"),
            cancelButtonText: CommonHelper.showLocale(props, "No"),
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`stock/${id}`)
                    .set({
                        status: stockStatus.AVAILABLE,
                        enquiryID: null,
                        testDriveDetail: null,
                        fleetDetail: null,
                        statusModifiedBy: localStorage.uid,
                        statusModifiedFrom: 'web',
                        modifiedBy: localStorage.uid,
                        modifiedFrom: 'web',
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then(() => {
                        toast.notify('Return successfully.', {
                            duration: 2000
                        })
                    })
            }
        });

    }

    const isValidForFleet = (_stock) => {
        if (_stock && _stock['regExp'] && (moment()._d > moment.unix(_stock['regExp'].seconds)._d)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Registration expiry date is expired'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Update Now'),
                cancelButtonText: CommonHelper.showLocale(props, 'Later'),
            }).then(result => {
                if (result.value) {
                    setEditDocument(_stock.documentID);
                    setModelStockShow(true);

                }
            });

            return false;
        }
        else { return true; }
    }

    const handleClose = (id) => {
        if (id) {
            setTimeout(() => {
                fetchFleetCounts();
            }, 3000);
        }
        setModelStockShow(false);
        setModelFleetShow(false);
        setModelTestDriveShow(false);
        setModelStock({});
        setEditDocument(null);
        setEditClient(null);
    }

    const handleAddVehicle = (id) => {

        // if (liteVersion)
        //     return;

        setModelStockShow(true);
    }



    const handleActionClick = (id, obj) => {


        if (obj.status === stockStatus.AVAILABLE) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        style={{ cursor: 'pointer' }}
                                        className="btn btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    >
                                        <Translate text={'loanVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }
        else if (obj.status === stockStatus.ONTESTDRIVE) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    ><Translate text={'returnVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }
        else if (obj.status === stockStatus.ONLOAN) {
            return (
                <>
                    {
                        readOnlyEnquiryView
                            ?
                            <><div className="mini-button float-right mr-3"><i className="ico icon-lock"></i></div></>
                            :
                            <>
                                <div className="fleet-btn-loan width-fix">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!readOnlyEnquiryView)
                                                handleEditFleet(id, obj);
                                        }}
                                    >
                                        <Translate text={'returnVehicle'} />
                                    </button>
                                </div>
                            </>
                    }
                </>
            );
        }

    }

    const handleDetailsClick = (e) => {
        e.preventDefault();
    }

    const handlePagination = () => {
        // if (liteVersion)
        //     return;

        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleChangeTab = (_data) => {
        // if (liteVersion)
        //     return;
        //console.log('handleChangeTab', _data)
        if (_data !== 'Available' && !_.isEmpty(stockLocations)) setFilterStatus(loan_type[0]?.value)
        setActiveTab(_data);
        setDataload({
            ...checkDataLoad,
            [_data]: true
        })
        setHasMoreData([...hasMore.filter(e => e.type !== _data)]);
    }

    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    const excelDownload = () => {
        // if (liteVersion)
        //     return;

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let refStockDataExport = window.firebase.firestore().collection('stock')
            .where('isDeleted', '==', false)

        if (!_.isEmpty(stockLocations)) refStockDataExport = refStockDataExport.where('clientID', 'in', CommonHelper.arrLimit(_.uniq(stockLocations), 30))
        else if (enableSharedStock && props.dealersettings.client.group)
            refStockDataExport = refStockDataExport.where('groupID', '==', props.dealersettings.client.group)
        else
            refStockDataExport = refStockDataExport.where('clientID', '==', props.dealersettings.client.id)


        if (searchText && searchText.trim()) {
            refStockDataExport = refStockDataExport
                .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        }

        if (activeTab === 'Available') {
            refStockDataExport = refStockDataExport
                .where('status', '==', stockStatus.AVAILABLE)
                .where('isLoan', '==', true)

            if (serviceLoanOnly)
                refStockDataExport = refStockDataExport
                    .where('isServiceLoan', '==', true)
        }
        else {
            if (!_.isEmpty(filterStatus) && filterStatus === 'onServiceLoan')
                refStockDataExport = refStockDataExport.where("status", "==", stockStatus.ONLOAN).where('isServiceLoan', '==', true)
            else if (!_.isEmpty(filterStatus))
                refStockDataExport = refStockDataExport.where("status", "==", filterStatus)
            else
                refStockDataExport = refStockDataExport
                    .where("status", "in", [stockStatus.ONLOAN, stockStatus.ONTESTDRIVE])
        }

        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> {CommonHelper.showLocale(props, 'downloading...')}</div>),
            {
                position: 'top',
                duration: null
            },
        )

        refStockDataExport
            .orderBy('modifiedDate', 'desc')
            .limit(1000)
            .onSnapshot((querySnapshot) => {
                let _enquiries = [];
                querySnapshot.forEach(doc => {
                    var logVM = dataMappingVM({
                        ...doc.data(),
                        documentID: doc.id
                    });
                    if (activeTab === 'MyLoaned' && logVM.fleetOwner === localStorage.uid) {
                        _enquiries.push(logVM);
                    }
                    else if (activeTab !== 'MyLoaned') {
                        _enquiries.push(logVM);
                    }

                });
                // console.log('refStockDataExport', _enquiries)
                setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
                setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
                toast.closeAll();
                Swal.fire({
                    icon: 'success',
                    title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                    showConfirmButton: false,
                    timer: 1500
                });

                let _objLogData = {
                    notes: 'Excel export from loan vehicle',
                    type: 'excelExport',
                    subType: 'fleet',
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                CommonHelper.saveAuditLog(_objLogData);
            });

        //refStockDataExport && refStockDataExport();
    }


    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                excelDownload();
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    const fleetList = useMemo(() => fleets[activeTab]
        .filter(
            ({ keywords, fleetOwner }) => (
                //(searchText ? keywords.some(i => i.toLowerCase() === searchText.toLowerCase()) : true) &&
                (activeTab === 'MyLoaned' ? fleetOwner === localStorage.uid : true)
            )
        )
        .sort(function (x, y) {
            return (x.modifiedDate.seconds > y.modifiedDate.seconds ? -1 : 1);
        }), [fleets, activeTab])

    const handleOpenLoanBookings = () => {
        setShowLoanBookings({
            show: true,
            data: null,
            title: 'Loan Bookings'
        });
    }

    const handleCloseLoanBookings = () => {
        setShowLoanBookings({
            show: false,
            data: null,
            title: 'Loan Bookings'
        });
    }

    const handleCloseFleets = () => {
        setShowFleets({
            show: false,
            type: null
        });
    }

    const handleFleetOpen = (fleetID) => {
        if (fleetID) {
            setEditDocument(fleetID);
            setModelFleetShow(true);
        }

    }

    return (
        <>
            {/* {liteVersion ? <UpgradePlan /> : <></>} */}

            <div className={`middle-wrapper`}>
                <div className="filter-panel">

                    <div className="float-left fleet-head">
                        <h3><Translate text={'loanVehicles'} /></h3>
                        <div className="mini-white-countbtn-wrap">
                            {
                                !_.isEmpty(fleetCounts) && fleetTags.map((rec, index) => {
                                    return <a key={rec} href="#"
                                        id={rec}
                                        className="mini-white-countbtn ml-1"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            //console.log(event, '==', event.target.id)
                                            //if (event.target.id) {
                                            setShowFleets({
                                                show: true,
                                                type: (event.target.id || rec)
                                            })
                                            //}

                                        }}
                                    >
                                        <span >{rec === 'Today' ? <Translate text={`Today's Expected Return`} /> : rec === 'OnLoan' ? <Translate text={'onLoan'} /> : <Translate text={'Overdue'} />}</span>

                                        {!_.isEmpty(fleetCounts) ?
                                            rec === 'Overdue' ? (<span className="mini-white-count">{fleetCounts.overdue ? fleetCounts.overdue : '0'}</span>)
                                                : rec === 'Today' ? (<span className="mini-white-count">{fleetCounts.today ? fleetCounts.today : '0'}</span>)
                                                    : rec === 'OnLoan' ? (<span className="mini-white-count">{fleetCounts.total ? fleetCounts.total : '0'}</span>)
                                                        : (<></>)
                                            : (<></>)
                                        }
                                    </a>
                                })
                            }
                            {/* <a href="#" className="mini-white-countbtn ml-1">
                                <span >  Overdue  </span> <span className="mini-white-count"> 10 </span></a>
                            <a href="#" className="mini-white-countbtn ml-1">
                                <span >  Today's Expected Return </span> <span className="mini-white-count"> 10 </span></a>
                            <a href="#" className="mini-white-countbtn ml-1">
                                <span >  On Loan  </span> <span className="mini-white-count"> 10 </span></a> */}

                        </div>
                        {/* <div className="fleet-count-btn ml-3"> <span >  <Translate text={'available'} /> </span> <span className="fleet-count"> {availableList.length} </span></div>
                    <div className="fleet-count-btn ml-2"> <span >  <Translate text={'loaned'} /> </span> <span className="fleet-count"> {loanList.length} </span></div> */}
                    </div>

                    <div className="float-right">
                        {enableLoanBooking ? (
                            <div className="booked-load-vehicle">
                                <button type="button" className="btn btn-sm btn-booked-loan float-left mr-2" onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenLoanBookings();
                                }}>
                                    <i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'ico icon-Stock')}></i>
                                    <Translate text={'Loan Bookings'} />
                                    <span className="badge badge-pill badge-booked-loan ml-2">{loanBookingsCounts.total ? loanBookingsCounts.total : '0'}</span>
                                </button>
                            </div>
                        ) : (<></>)}
                        {readOnlyEnquiryView
                            ?
                            <></>
                            :
                            <>
                                {
                                    props.dealersettings?.rolePermissions?.permissions?.addStock
                                        ?
                                        <div className="filter-add-button">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddVehicle();
                                                }}
                                            > <i className="ico icon-add"></i> <Translate text={'addVehicle'} /></button>
                                        </div>
                                        :
                                        <></>
                                }


                                <div className="filter-add-button ml-2">
                                    <button type="button" className="btn btn-secondary"
                                        onClick={(e) => {
                                            e.preventDefault();

                                            // if (liteVersion)
                                            //     return;

                                            props.history.push('/fleet/history');

                                        }}>
                                        <i className="ico icon-history mr-2"></i> <Translate text={'history'} />
                                    </button>
                                </div>
                            </>}

                        {
                            (exportData)
                                ?
                                <>
                                    <div className="filter-item-btn ml-2">
                                        <a href="#" onClick={(e) => {
                                            //e.preventDefault();
                                            excelDownload(e);
                                        }} title={'Excel Export'}>
                                            <i className="ico icon-excel-download"></i>
                                        </a>
                                        <ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`${`${activeTab}_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}`}>
                                            <ExcelSheet dataSet={csvData} name={`Sheet1`} />
                                        </ExcelFile>
                                        {/* <CSVLink
                                        ref={csvBtn}
                                        uFEFF={false}
                                        data={csvData}
                                        headers={csvHeader}
                                        filename={`${activeTab}_${moment().format('DD_MM_YYYY_HH_mm_ss')}.csv`}
                                        style={{ display: 'none' }}>
                                    </CSVLink> */}
                                    </div>
                                    {/* <div className="filter-item-btn ml-2">
                                    <CSVLink uFEFF={false} data={fleetList ? fleetList : []} headers={csvHeader} filename={`stock_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}>
                                        <i className="ico icon-excel-download"></i>
                                    </CSVLink>
                                </div> */}
                                </>
                                :
                                <>
                                </>
                        }

                        {/* <div className="filter-view-more  ml-2">
                        <a href="#" className="common-button" onClick={(e) => {
                            e.preventDefault();
                        }}><i className="ico icon-more"></i></a>
                    </div> */}

                    </div>

                </div>
                <div className="filter-sub-panel">
                    <div className="float-left">
                        {/* <div className="filter-item-btn">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                        }}>
                            <i className="ico icon-filter"></i>
                            <span>Filter </span>
                            <i className="ico icon-dropdown"></i>
                        </a>
                    </div> */}

                        {/* <div className="filter-search ml-2">
                        <input type="text" placeholder="Search" aria-label="Search"
                            value={searchText}
                            onChange={(e) => { setSearchText(e.target.value); }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    localStorage.fleetSearchText = searchText;
                                    setSearchText(searchText)
                                    setDataload({
                                        ...checkDataLoad,
                                        [activeTab]: true
                                    })
                                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                }
                            }} />
                    </div> */}
                        <div className="filter-search">
                            <div className="input-group ">
                                <input
                                    className="form-control"
                                    type="text"
                                    id="isearch"
                                    placeholder="search..."
                                    value={searchText ? searchText : ''}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        localStorage.fleetSearchText = e.target.value;
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        if (!e.target.value) {
                                            setSearchText('');
                                            // setDataload({
                                            //     ...checkDataLoad,
                                            //     [activeTab]: true
                                            // })
                                            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setSearchText(searchText)
                                            setDataload({
                                                ...checkDataLoad,
                                                [activeTab]: true
                                            })
                                            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        }
                                    }}
                                    autoComplete="off"
                                //disabled={liteVersion ? true : false}
                                />
                                <div className="input-group-append input-search-clear">
                                    {
                                        searchText && searchText.length > 0
                                            ?
                                            <a href="#"
                                                className="input-search-clear-icon"
                                                title="Clear"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSearchText('');
                                                    localStorage.fleetSearchText = '';
                                                    setDataload({
                                                        ...checkDataLoad,
                                                        [activeTab]: true
                                                    })
                                                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                }}
                                            >
                                                <i className="ico icon-remove"></i>
                                            </a>
                                            :
                                            <>
                                            </>
                                    }

                                    <button
                                        className="btn btn-default"
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();

                                            // if (liteVersion)
                                            //     return;

                                            setSearchText(searchText)
                                            setDataload({
                                                ...checkDataLoad,
                                                [activeTab]: true
                                            })
                                            setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                        }}>
                                        <i className="ico icon-search-fill"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {
                            activeTab === 'Available'
                                ?
                                <>
                                    <div className="mycontact-btn ml-2">
                                        <span className="switch switch-sm">
                                            <InputCheckBox
                                                className={`switch`}
                                                id="serviceLoanOnly"
                                                name="serviceLoanOnly"
                                                checked={serviceLoanOnly ? serviceLoanOnly : false}
                                                onChange={(e) => {
                                                    // if (liteVersion)
                                                    //     return;
                                                    let _chked = e.target.checked;
                                                    setServiceLoan(_chked);
                                                    localStorage.setItem('serviceLoanOnly', _chked)
                                                    setDataload({
                                                        ...checkDataLoad,
                                                        [activeTab]: true
                                                    })

                                                    setLoader({
                                                        ...fleetLoader,
                                                        [activeTab]: true
                                                    })
                                                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);

                                                }} />
                                            <label htmlFor="serviceLoanOnly"><Translate text={'serviceLoanOnly'} /></label>
                                        </span>
                                    </div>

                                </>
                                :
                                <>
                                    <div className="form-loan-history form-row ml-2">
                                        <div className="form-group col-md-12 daterange-fix">
                                            <ReactSelect
                                                options={loan_type}
                                                name={"filterStatus"}
                                                placeholder={'select status'}
                                                onChange={(e) => {
                                                    // console.log('select loan type', e)
                                                    if (e) {
                                                        setFilterStatus(e.value);
                                                        localStorage.fleetStockStatus = e.value;
                                                    }
                                                    else {
                                                        setFilterStatus('');
                                                        localStorage.fleetStockStatus = '';
                                                    }

                                                    setDataload({
                                                        ...checkDataLoad,
                                                        [activeTab]: true
                                                    })

                                                    setLoader({
                                                        ...fleetLoader,
                                                        [activeTab]: true
                                                    })
                                                    setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                                }}
                                                value={filterStatus}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={!_.isEmpty(stockLocations) ? true : false}
                                            >
                                            </ReactSelect>
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                    <div className="float-right">
                        <div className="tabber-nav">
                            <ul>
                                <li><a href="#" className={`${activeTab === 'Available' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChangeTab('Available');
                                    }}><Translate text={'available'} /> </a></li>

                                <li><a href="#" className={`${activeTab === 'Loaned' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChangeTab('Loaned')
                                    }}
                                > <Translate text={'loaned'} /> </a></li>

                                <li><a href="#" className={`${activeTab === 'MyLoaned' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChangeTab('MyLoaned')
                                    }}
                                > <Translate text={'myLoaned'} /> </a></li>

                            </ul>
                        </div>
                    </div>
                </div>

                <div className="calendar-panel">
                    {
                        fleetLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                            fleetList.length > 0 ? (
                                <div className="common-table">
                                    <TableView
                                        isReorderable={true}
                                        datalist={fleetList}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={
                                            (activeTab === 'Available')
                                                ?
                                                allStockFleetFieldsVM
                                                :
                                                allFleetStockFieldsVM
                                        }
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={
                                            (activeTab === 'Available')
                                                ? (stockFleetFields && stockFleetFields.length > 0) ? stockFleetFields : allStockFleetFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                                : (fleetFields && fleetFields.length > 0) ? fleetFields : allFleetStockFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)
                                        }
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={handleDetailsClick}
                                        isSettings={true}
                                        hasMore={hasMore.find(e => e.type === activeTab)}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        activityTab={activeTab}
                                        displayByValue={true}
                                    />
                                </div>
                            ) : (
                                <div className="common-table">
                                    <div className="text-center p-5">
                                        <p><Translate text={'No record found'} /></p>

                                    </div>
                                </div>
                            )
                    }


                </div>


                <PopUpModal show={modelFleetShow}>
                    <AddFleet
                        docID={editDocumentID}
                        show={modelFleetShow}
                        clsActive='overlay-modal active'
                        stock={modelStock}
                        selectedClientID={!_.isEmpty(stockLocations) ? modelStock?.clientID : null}
                        handleClose={handleClose}
                        title={'fleet'}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    >
                    </AddFleet>
                </PopUpModal>

                <PopUpModal show={modelStockShow}>
                    <AddStock
                        docID={editDocumentID}
                        show={modelStockShow}
                        selectedClientID={editClientID}
                        clsActive='overlay-modal active'
                        handleClose={handleClose}
                        title={'stock'}
                        enableSale={false}
                        enableTestDrive={false}
                        enableLoan={true}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    >
                    </AddStock>
                </PopUpModal>

                <PopUpModal show={modelTestDriveShow}>
                    <AddTestDrive
                        docID={editDocumentID}
                        show={modelTestDriveShow}
                        clsActive='overlay-modal active'
                        handleClose={handleClose}
                        title={'testDrive'}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        isDeivered={false}
                        //enquiryID={this.state.enquiryID}
                        contact={null}
                    >
                    </AddTestDrive>
                </PopUpModal>

                <PopUpModal show={showReAuthentication}>
                    <ReAuthenticate
                        show={showReAuthentication}
                        handleClose={handleReAuthOpenClose}
                        dealersettings={props.dealersettings}
                        privacy='file'
                    />
                </PopUpModal>

                <PopUpModal show={showLoanBookings.show}>
                    <LoanBookings
                        show={showLoanBookings.show}
                        title={showLoanBookings.title ? showLoanBookings.title : 'Loan Bookings'}
                        handleClose={handleCloseLoanBookings}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                        reloadLoanBookingCounts={reloadLoanBookingCounts}
                        setReloadLoanBookingCounts={setReloadLoanBookingCounts}
                    />
                </PopUpModal>

                <PopUpModal show={showFleets.show}>
                    <FleetVehiclesList
                        {...props}
                        show={showFleets.show}
                        activeFleetTag={showFleets.type}
                        handleClose={handleCloseFleets}
                        handleFleetOpen={handleFleetOpen}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    />
                </PopUpModal>
                {/* <PopUpModal show={showFleets.show}>
                    <FleetVehicles
                        show={showFleets.show}
                        activeFleetTag={showFleets.type}
                        handleClose={handleCloseFleets}
                        handleFleetOpen={handleFleetOpen}
                        dealersettings={props.dealersettings}
                        clientUsers={props.clientUsers}
                        groupUsers={props.groupUsers}
                    />
                </PopUpModal> */}

            </div>
        </>
    )
}

export default FleetListView;