import React, { useState, useEffect, useRef } from 'react'
import { Dropdown, Modal, Popover } from 'react-bootstrap';
import _ from 'lodash'
import { InputText, InputMultipleText,} from '../../../components';
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import ReactSummernote from 'react-summernote';

import Swal from 'sweetalert2'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection, collectionData } from 'rxfire/firestore';

const SendMail = (props) => {
    const [report, setReport] = useState();
    const [errorFields, setErrorFields] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [emailloader, setEmailLoader] = useState(false);
    const [emailHtml, setEmailHtml] = useState('');

    const [showPop, setShowPop] = useState(false);
    const [templates, setEmailTemplates] = useState([]);
    const [templateSearchText, setTemplateSearchText] = useState('');
    const node = useRef();

    useEffect(() => {
        if (_.isEmpty(report))
            return
        if (!initialized) {
            // console.log('emailType', emailType)
            var node = document.createElement('div');
            node.innerHTML = `${emailHtml}`;
            ReactSummernote.insertNode(node);
            setInitialized(true);
        }

    }, [report]);

    useEffect(() => {
        if (!_.isEmpty(report))
            return
        let _contact = Object.assign({}, props.contact);

        let _email = _contact?.businessContact && !_.isEmpty(_contact?.company) && _contact?.company?.email ? _contact?.company.email : _contact?.email
        setReport({
            ['recipients']: _email ? _email : '',
            ['url']: `/serviceinvoice/preview/${props.serviceid}?type=print`,
            ['addedDate']: window.firebase.firestore.Timestamp.now(),
            ['modifiedDate']: window.firebase.firestore.Timestamp.now(),
        })
    }, [])

    useEffect(() => {
        if (_.isEmpty(props.client)) {
            return;
        }

        let privateTemplatesRef = window.firebase.firestore().collection(`clientSettings/${props.client.settingsID}/servicetemplates`).
            where('visibility', '==', 'private')
            .where('isDeleted', '==', false)
        privateTemplatesRef = CommonHelper.whereClientOrGroupOrRegionOrOemClause(privateTemplatesRef, props.dealersettings, null, true);

        window.alltemplates = combineLatest(
            collectionData(privateTemplatesRef),
            collectionData(window.firebase.firestore().collection(`clientSettings/${props.client.settingsID}/servicetemplates`)
                .where('visibility', '==', 'shared')
                .where('isDeleted', '==', false))
        ).pipe(
            map((templates) => {
                var [dealer, shared] = templates;
                return [
                    [...dealer],
                    [...shared]
                ];
            }))
            .subscribe(allDocs => {
                let list = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec) })
                let listVM = [];
                list.forEach(rec => {
                    if (!listVM.some(e => e.documentID === rec.documentID))
                        listVM.push(rec)
                })
                setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'))

            });

    }, [props.client]);


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')

            })
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleOnEmailChange = e => {

        e.preventDefault();
        const { name, value } = e.target;
        let valueToSet = '';
        if (value) valueToSet = value;
        else valueToSet = '';

        setReport({
            ...report,
            [name]: valueToSet,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };


    const handleOnChangeMultipleText = (val, name) => {
        setReport({
            ...report,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const removeEmail = (index, name) => {
        let arr = report[name] ? _.isArray(report[name]) ? report[name] : report[name].split(',') : []
        arr.splice(index, 1)
        setReport({
            ...report,
            [name]: arr.join(',')
        })
    }

    const handleChange = obj => {
        //let htmlText = obj
        setEmailHtml(obj)
    };


    const handleSendEmail = () => {
        let rec = report;
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['subject'])) {
            formIsValid = false;
            errors['subject'] = errorClass;
        }
        if (_.isEmpty(rec['recipients']) && _.isEmpty(rec['otherRecipients'])) {
            formIsValid = false;
            Swal.fire('Please add recipients', '', 'error');
        }

        if (!_.isEmpty(rec['recipients'])) {

            let lastAtPos = rec['recipients'].lastIndexOf('@');
            let lastDotPos = rec['recipients'].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && rec['recipients'].indexOf('@@') === -1 && lastDotPos > 2 && (rec['recipients'].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors['recipients'] = errorClass;
            }
        }


        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let reportURL = report.url;
        let _recipients = [];

        if (!_.isEmpty(report.recipients)) {
            _recipients.push(report.recipients);
        }
        if (!_.isEmpty(report.otherRecipients)) {
            _recipients = [..._recipients, ...report.otherRecipients.split(',')]
        }
        setEmailLoader(true)
        try {
            let paramObj = {
                clientID: props.clientID,
                url: reportURL,
                subject: report.subject,
                recipients: _recipients,
                emailBody: emailHtml
            }
            props.handleClose(paramObj);
            // const sendEmail = window.firebase.functions().httpsCallable('reports-sendEmailNow');
            // sendEmail(paramObj).then((response) => {
            //     setEmailLoader(false)
            //     if (response.data.success) {
            //         
            //         toast.notify('Email sent successfully.', {
            //             duration: 2000,
            //         });
            //     }
            //     else {
            //         console.log(response.data.message);
            //         Swal.fire('Something went wrong', '', 'error')
            //     }
            // })

        }
        catch (error) {
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }


    const handleInsertTemplate = emailTemplate => {
        ReactSummernote.reset()

        var node = document.createElement('div');
        node.innerHTML = `${CommonHelper.mapTemplate(
            emailTemplate.bodyHTML,
            props.contact,
            null,
            props.dealersettings,
            props.clientUsers,
            null,
            props.service
        )}`
        ReactSummernote.insertNode(node)
        return;
    };

    const filterTemplates = (template) => {
        if (!templateSearchText) {
            return true
        } else if (template.title.toLowerCase().includes(templateSearchText.toLowerCase())) {
            return true
        }

        return false
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner')) {
            return;
        }
        setShowPop(false);
    };


    return _.isEmpty(report) ? (<></>) : (

        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Send Email'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid pl-0 pr-0">
                    <div className="row">
                        <div className=" pop-layout form-style">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label>Email Subject</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'subject'}
                                        className={`form-control ${errorFields["subject"]}`}
                                        name="subject"
                                        onChange={handleOnChange}
                                        value={report.subject}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Recipients Email</label>
                                    <InputText
                                        type="text"
                                        className={`form-control ${errorFields["recipients"]}`}
                                        name={"recipients"}
                                        placeholder={`recipients email`}
                                        value={report.recipients}
                                        onChange={handleOnEmailChange}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Other Recipients Email</label>
                                    <InputMultipleText
                                        placeholder={`other recipients email`}
                                        className={`form-control react-multi-tag`}
                                        name="otherRecipients"
                                        onChange={(emails) => { handleOnChangeMultipleText(emails, 'otherRecipients') }}
                                        value={!_.isEmpty(report.otherRecipients) ? report.otherRecipients.split(',') : []}
                                        validateEmail={true}
                                        getLabel={(email, index) => {
                                            return <div data-tag key={index}>
                                                {email}
                                                <span data-tag-handle onClick={() => removeEmail(index, 'otherRecipients')}>
                                                    ×
                                                </span>
                                            </div>
                                        }}
                                    />
                                </div>
                                <div className='form-group col-md-12 react-summer-editor'>
                                    <label>Email Body</label>
                                    <ReactSummernote
                                        children={<div dangerouslySetInnerHTML={{ __html: `<div></div>` }}></div>}
                                        options={{
                                            height: 160,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['style']],
                                                ['font', ['bold', 'underline', 'clear']],
                                                ['fontname', ['fontname']],
                                                ['para', ['paragraph']],
                                                ['color', ['color']],
                                                ['insert', ['link']],
                                                ['view', ['codeview']]
                                            ],
                                            disableResizeEditor: true
                                        }}
                                        onChange={handleChange}
                                    // onPaste={onEditorPaste}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-footer">

                <Dropdown
                    className='filter-pipeline-btn ml-2 float-left dropdown-toggle'
                    alignRight
                    ref={node}
                    show={showPop}
                >
                    <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' onClick={() => {
                        setShowPop(!showPop)
                    }}>
                        <i className='ico icon-template'></i> <span>Templates</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as={CustomMenu}
                        className='rm-pointers dropdown-menu-lg dropdown-menu-right template-add-dropdown'
                        ChildClass='pipeline-dropdown'
                        xplacement='top-end'
                    >
                        <Popover
                            id='popover-basic-mail'
                            className='popover popover-mail-templates popover-x in kv-popover-active show left left-bottom'
                            style={{
                                top: '-317px',
                                left: '42px',
                                display: 'block',
                                zIndex: '1029',
                                padding: '0px',
                            }}

                        >
                            <Popover.Title>
                                <h3 className='popover-header popover-title'>
                                    <span className='close' data-dismiss='popover-x' onClick={() => setShowPop(false)}>
                                        ×
									</span>
									Templates{' '}
                                </h3>
                                <div className='email-list-search'>
                                    <div className='input-group template-search'>
                                        <input type='text' className='form-control' placeholder='Search' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} style={{ width: '100%' }} />
                                        <div className='input-group-append input-search-clear'>
                                            {templateSearchText.length > 0 && (
                                                <a href='#' className='input-search-clear-icon' onClick={() => setTemplateSearchText('')}>
                                                    <i className='ico icon-remove'></i>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Popover.Title>
                            <Popover.Content>
                                <div className='popover-body popover-content p-0'>
                                    <div className='mailtemplate-column-nav'>
                                        <ul>
                                            {templates && templates.length > 0 && _.orderBy(templates, ['title'], ['asc']).filter(template => filterTemplates(template)).map((template, index) => (
                                                <li onClick={() => { handleInsertTemplate(template); setShowPop(false); }} key={index}>
                                                    <a href='#' key={index}>{template.title}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </Popover.Content>
                        </Popover>

                    </Dropdown.Menu>
                </Dropdown>

                <button
                    type="button"
                    className="btn btn-primary float-right ml-2"
                    onClick={(e) => { handleSendEmail() }}

                >
                    {
                        emailloader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                        Send Email
                </button>

                <button
                    type="button"
                    className="btn btn-default float-right"
                    onClick={(e) => { props.handleClose() }}
                ><Translate text={'cancel'} />
                </button>


            </Modal.Footer>
        </Modal >
    )
}

export default SendMail;