import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Modal, Tabs, Tab, Dropdown } from 'react-bootstrap'
import { InputText, ReactSelect, ImageCropHook, PopUpModal, ReactMultiSelect, AutoComplete, InputCheckBox } from '../../../../components'
import Translate from '../../../../constants/translate';
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { mandatoryFields, usersVM, levels, individuallevels, levelOptions } from './viewModel'
import _ from 'lodash'
import { default as _images } from "../../../../images";
import UpdatePassword from './updatePassword'
import { storageBucket, validateEmail } from "../../../../services/helper";
import { ClientContext } from '../clientContext'
import moment from 'moment';
import uuid from 'react-uuid'
import AddRole from './addRole'
import CommonHelper from '../../../../services/common'
import FetchDMSKey from './fetchDMSKey';

const AddUser = (props) => {
    const [user, setUser] = useState(props.user ? props.user : {});
    const [loader, setLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageLoader, setImageLoader] = useState(false)
    const [showPasswordModel, setShowPasswordModel] = useState(false)
    const [clientSearch, setClientSearch] = useState('')
    const newUser = !_.isEmpty(props.user) ? false : true
    const oldEmailaddress = !_.isEmpty(props.user) ? props.user.email : ''
    const oldPhoneNo = !_.isEmpty(props.user) ? props.user.phone : ''
    const oldEnableMFA = !_.isEmpty(props.user) ? props.user.enableMFA : ''
    const arruserClients = !_.isEmpty(props.user) && !_.isEmpty(props.user.arrayClientID) ? props.user.arrayClientID : []
    const oldDepartment = !_.isEmpty(props.user) ? props.user.department : []
    const oldRole = !_.isEmpty(props.user) ? props.user.roleID : []
    const [departments, setDepartments] = useState([])
    //const [roles, setRoles] = useState([])
    const [roleLoader, setRoleLoader] = useState(false)
    const [selectedClientID, setSelectedClientID] = useState('')
    const [regionGroupOptions, setRegionGroupOptions] = useState([])
    const [mandFields, setMandFields] = useState(mandatoryFields)
    const [key, setKey] = useState('login')
    const [integarions, setIntegration] = useState([]);
    const [addSubList, setAddSubList] = useState({ type: '', value: '', id: uuid() })
    const [textEditor, setTexteditor] = useState({})
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.user) && !_.isEmpty(props.user.dmsKeys) ? props.user.dmsKeys.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);
    const [showRoleModel, setShowRoleModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [clientGroups, setClientGroups] = useState([]);
    const [clientRegions, setClientRegions] = useState([]);
    const [clientSubregions, setClientSubregions] = useState([]);
    const [userClients, setUserClients] = useState([])
    const { clients, roles, groups, regions, subregions } = useContext(ClientContext);
    const [phoneCodes, setphoneCodes] = useState([])
    const [showMFAEnableModel, setShowMFAEnableModel] = useState(false)
    const [mfaLoader, setmfaLoader] = useState(false);
    const [dmsKeyModel, setdmsKeyModel] = useState({ show: false })
    const enableTwoFactorAuth = props.dealersettings
        && props.dealersettings.client
        && props.dealersettings.client.securitysettings ? Boolean(props.dealersettings.client.securitysettings.enableTwoFactorAuth) : false;

    const tradeinPro = props?.dealersettings?.clients?.some(c => c.moduleSettings
        && c.moduleSettings.tradeinPro
        && c.moduleSettings.tradeinPro.enabled === true
        && c.moduleSettings.tradeinPro.active === true);

    const levelAccess = _.map((props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index), function (obj) {
            return obj.value;
        })
    useEffect(() => {
        if (_.isEmpty(props.user)) {
            const newuserVM = Object.assign({}, usersVM);
            newuserVM.addedDate = window.firebase.firestore.Timestamp.now();
            newuserVM.addedBy = localStorage.uid;
            newuserVM.settingsID = props.dealersettings.client.settingsID;
            newuserVM.enableMFA = true;

            let countryCode = props.dealersettings && props.dealersettings.client.countryCode ? props.dealersettings.client.countryCode.toUpperCase() : 'AU';
            let phone_code = countryCode && props.dealersettings.countries && props.dealersettings.countries.filter(m => m.code === countryCode)[0];
            if (!_.isEmpty(phone_code)) {
                newuserVM.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
            }
            setUser(newuserVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(clients))
            return
        setUserClients(clients.filter(c => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), c.documentID) >= 0))
    }, [clients])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client.settings.departments)) {
            var _depts = [];
            props.dealersettings.client.settings.departments.forEach(rec => {
                _depts.push({ label: rec.name, value: rec.value, active: _.isBoolean(rec.active) ? rec.active : true, })
            })
            setDepartments(_depts);
            setMandFields([...mandFields, "department"])
        }
        else {
            setMandFields([...mandFields.filter(item => item !== "department")])
        }

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client)) {
            let _phoneCodes = [];
            props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
                if (doc.phone_code) {
                    _phoneCodes.push({
                        country_code: doc.code,
                        value: doc.phone_code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                    });
                }
            });
            setphoneCodes(_phoneCodes);
        }

    }, [props.dealersettings])

    useEffect(() => {
        if (!_.isEmpty(groups)) {
            setClientGroups(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(userClients.map(rec => { return rec }), 'group')), v.documentID) >= 0)
                .map(rec => {
                    return {
                        label: rec.name,
                        value: rec.documentID
                    }
                }))
        }
        if (!_.isEmpty(regions)) {
            setClientRegions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(userClients.map(rec => { return rec }), 'region')), v.code) >= 0)
                .map(rec => {
                    return {
                        label: rec.name,
                        value: rec.documentID
                    }
                }))
        }
    }, [groups, regions, userClients])

    useEffect(() => {
        if (_.isEmpty(user.arrayRegionID) || _.isEmpty(subregions) || _.isEmpty(userClients))
            return

        setClientSubregions(_.filter(subregions, (v) => _.indexOf(_.uniq(_.map(userClients.filter(a => user.arrayRegionID.includes(a.region)).map(rec => { return rec }), 'subregion')), v.code) >= 0)
            .map(rec => {
                return {
                    label: rec.name,
                    value: rec.documentID
                }
            }))
    }, [user.arrayRegionID, subregions, userClients])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.integrations) || _.isEmpty(props.dealersettings.client) || _.isEmpty(props.dealersettings.client.integrations))
            return
        let _integration = [];
        _.filter(props.dealersettings.integrations, (v) => _.indexOf(props.dealersettings.client.integrations.filter(e => e.active === true && e.enabled === true).map(v => _.pick(v, ['type']).type), v.value) >= 0)
            .map(rec => {
                return _integration.push({
                    label: rec.name,
                    value: rec.value
                })
            })
        setIntegration(_integration)
    }, [props.dealersettings])

    useEffect(() => {
        if (_.isEmpty(user) || _.isEmpty(user.roleID) || _.isEmpty(clientRegions))
            return

        if (user.level === 'region') {
            if (userClients.filter(c =>
                (!_.isEmpty(user.arrayRegionID) ? user.arrayRegionID.includes(c.region) : true)
                && (!_.isEmpty(user.arraySubregionID) ? user.arraySubregionID.includes(c.subregion) : true)
            ).length > 0) {
                setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...userClients.filter(c => (!_.isEmpty(user.arrayRegionID) ? user.arrayRegionID.includes(c.region) : true) && (!_.isEmpty(user.arraySubregionID) ? user.arraySubregionID.includes(c.subregion) : true))
                    .map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            }
            else {
                setRegionGroupOptions([])
            }
            setMandFields([...mandFields.filter(item => item !== "arrayGroupID"), "arrayClientID", "arrayRegionID"])
        }
        else if (user.level === 'group') {
            if (userClients.filter(c => (!_.isEmpty(user.arrayGroupID) ? user.arrayGroupID.includes(c.group) : true)).length > 0) {
                setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...userClients.filter(c => (!_.isEmpty(user.arrayGroupID) ? user.arrayGroupID.includes(c.group) : true)).map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            }
            else {
                setRegionGroupOptions([])
            }
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID"), "arrayClientID", "arrayGroupID"])
        }
        else if (user.level === 'individual' || (user.level === 'oem' && user.role !== 'Administrator')) {
            if (userClients.length > 0) {
                setRegionGroupOptions([{ label: 'All', value: 'all', clientIDs: [] }, ...userClients.map((rec) => { return { value: rec.documentID, label: rec.name, doc: rec, clientIDs: [rec.documentID] } })])
            }
            else {
                setRegionGroupOptions([])
            }
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID" && item !== "arrayGroupID"), "arrayClientID"])
        }
        else {
            setRegionGroupOptions([])
            setMandFields([...mandFields.filter(item => item !== "arrayRegionID" && item !== "arrayGroupID" && item !== "arrayClientID")])
        }


    }, [user, userClients, clientRegions])


    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'password' || name === 'cpassword') {
            setUser({
                ...user,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setUser({
                    ...user,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setUser({
                    ...user,
                    [name]: str
                });
            }
        }

        errorChange(name);

    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            if (data.name === 'roleID') {
                setUser({
                    ...user,
                    [data.name]: e.value,
                    ['role']: e.doc.name,
                    ['level']: e.doc.level,
                    ['arrayRegionID']: [],
                    ['arrayGroupID']: [],
                    ['regionID']: null,
                    ['groupID']: null,
                    ['arrayClientID']: (e.doc.level === 'oem' && e.doc.name === 'Administrator') ? clients.map(v => _.pick(v, ['documentID']).documentID) : []
                });
            }
            else if (data.name === 'regionID' || data.name === 'groupID') {
                setUser({
                    ...user,
                    [data.name]: e.value,
                    ['arrayClientID']: []
                });
            }
            else {
                setUser({
                    ...user,
                    [data.name]: e.value
                });
            }
        }
        else {
            if (data.name === 'regionID' || data.name === 'groupID') {
                setUser({
                    ...user,
                    [data.name]: null,
                    ['arrayClientID']: []
                });
            }
        }
    }

    const errorChange = (key) => {
        let errorClass = 'input_error';

        if (errorFields !== Object.assign({})) {
            if (!user[key] && mandFields.indexOf(key) >= 0) {
                setErrorFields({
                    ...errorFields,
                    [key]: errorClass
                });
            }
            else {
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            setErrorFields({
                                ...errorFields,
                                [key]: errorClass
                            });
                        }
                        else
                            setErrorFields({
                                ...errorFields,
                                [key]: ''
                            });
                    }
                }
                else
                    setErrorFields({
                        ...errorFields,
                        [key]: ''
                    });
            }


        }

    }

    const saveUser = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key, value] of Object.entries(user)) {
            if (mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(user[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        if (user.personelEmail && !validateEmail(user.personelEmail)) {
            formIsValid = false;
            errors['personelEmail'] = errorClass;
            setKey('other')
        }
        if (user.enableMFA === true) {
            if (_.isEmpty(user['phone']) || _.isEmpty(user['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
        }
        // if (newUser) {
        //     if (_.isEmpty(user['password'])) {
        //         formIsValid = false;
        //         errors['password'] = errorClass;
        //     }
        //     if (user['password'] !== user['cpassword']) {
        //         formIsValid = false;
        //         errors['cpassword'] = errorClass;
        //     }
        // }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (newUser === true) {
            const addUser = window.firebase.functions().httpsCallable('amsadmin-createUser');
            addUser({
                "email": user.email,
                "password": window.firebase.firestore().collection(`users`).doc().id,
                "phone": `+${user.phoneCode.replace('+', '')}${user.phone}`,
                "enableMFA": user.enableMFA,
                "name": user.name
            }).then(async (data) => {
                //console.log(data)
                if (data.data.success === true) {
                    user.documentID = data.data.data;
                    updateUser(user)
                }
                else {
                    const existingWholesaler = await window.firebase.firestore().collection('users')
                        .where('email', '==', user.email)
                        .where('isWholesaler', '==', true)
                        .limit(1)
                        .get();
                    if (existingWholesaler.docs && existingWholesaler.docs.length > 0) {
                        updateUser({
                            ...existingWholesaler.docs[0].data(),
                            ...user,
                            documentID: existingWholesaler.docs[0].id
                        });
                    }
                    else {
                        setLoader(false)
                        Swal.fire(data.data.message, '', 'error')
                    }
                }
            });
        }
        else {
            if (user.email !== oldEmailaddress) {
                const updateUserEmail = window.firebase.functions().httpsCallable('amsadmin-updateUserEmail');
                const resp = await updateUserEmail({
                    "email": user.email,
                    "uid": user.documentID
                });
                if (resp.data.success === true) {
                    //user.documentID = data.data.data;
                    if (user.nylasAccessToken) {
                        unAuthenticateUser(user)
                    }
                }
                else {
                    setLoader(false)
                    Swal.fire(resp.data.message, '', 'error')
                    return;
                }
            }
            if (user.phone !== oldPhoneNo || user.enableMFA !== oldEnableMFA) {
                const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
                const resp = await enrollMFA({
                    "uid": user.documentID,
                    "phone": `+${user.phoneCode.replace('+', '')}${user.phone}`,
                    "enroll": user.enableMFA
                })
                if (resp.data.success === true) {

                }
                else {
                    setLoader(false)
                    let msg = resp.data.message;
                    if (resp.data.data.errorInfo && resp.data.data.errorInfo.code === 'auth/invalid-phone-number') {
                        msg = 'Invalid phone number.'
                    }
                    Swal.fire(msg, '', 'error');
                    return;
                }
            }
            updateUser(user);
        }
    }

    const updateUser = (user) => {
        if (_.isEmpty(user) || _.isEmpty(user.documentID)) {
            Swal.fire(CommonHelper.showLocale(props, 'Invalid user.'), '', 'info')
            return
        }
        user.modifiedDate = window.firebase.firestore.Timestamp.now();
        user.modifiedBy = localStorage.uid
        if (user.arrayClientID.length > 0 && (_.isEmpty(user.defaultClientID) || !user.arrayClientID.some(e => e === user.defaultClientID)))
            user.defaultClientID = user.arrayClientID[0]
        if (user.arrayRegionID.length > 0 && (_.isEmpty(user.regionID) || !user.arrayRegionID.some(e => e === user.regionID)))
            user.regionID = user.arrayRegionID[0]
        if (user.arrayGroupID.length > 0 && (_.isEmpty(user.groupID) || !user.arrayGroupID.some(e => e === user.groupID)))
            user.groupID = user.arrayGroupID[0]
        delete user.password
        delete user.cpassword
        if (oldDepartment !== user.department) {
            user.arrayClientID.forEach(clientid => {
                window.firebase.firestore().doc(`users/${user.documentID}/userClients/${clientid}`).set({ isQueue: false, queueNum: 0 }, { merge: true })
            })
            user.arrayClientID.forEach(clientid => {
                window.firebase.firestore().doc(`users/${user.documentID}/userClients/${clientid}`).set({ 'department': user.department }, { merge: true })
            })
        }

        window.firebase.firestore().doc(`users/${user.documentID}`).set(user, { merge: true })
            .then(snapshot => {
                if ((JSON.stringify(arruserClients) !== JSON.stringify(user.arrayClientID) || oldRole !== user.roleID)) {
                    _.differenceWith(clients.map(v => _.pick(v, ['documentID']).documentID), user.arrayClientID, _.isEqual).forEach(_userClient => {
                        window.firebase.firestore().doc(`users/${user.documentID}/userClients/${_userClient}`).delete();
                    })
                    _.filter(clients, (v) => _.indexOf(_.uniq(_.map(user.arrayClientID)), v.documentID) >= 0).forEach(_userClient => {
                        window.firebase.firestore().doc(`users/${user.documentID}/userClients/${_userClient.documentID}`)
                            .set({
                                name: _userClient.name,
                                department: user.department,
                                roleID: user.roleID
                            }, { merge: true });
                    })
                }

                setLoader(false)
                toast.notify((newUser === true ? 'User added successfully' : 'User updated successfully'), {
                    duration: 2000
                })
                props.handleClose(user)
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }
    const unAuthenticateUser = async (_user) => {
        try {
            const nylasCancelAccount = window.firebase
                .functions()
                .httpsCallable('nylas-cancelAccount');
            const resp = await nylasCancelAccount({
                token: _user.nylasAccessToken,
            });
            if (resp.data) {
                console.log(resp.data);
            }
            if (resp.data.success) {
                window.firebase.firestore().doc(`users/${_user.documentID}`).set({
                    nylasAccessToken: null,
                    nylasAccountInfo: null,
                    nylasFolders: null,
                    nylasLabels: null,
                }, { merge: true })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {

                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        setUser({
            ...user,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);


        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        setImageLoader(true)
        var storageRef = storageBucket(props.dealersettings).ref(`users/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`users`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setUser({
                            ...user,
                            [id]: dataURL
                        });
                    })
            })
    };

    const handlePasswordModelClose = () => {
        setShowPasswordModel(false)
    }


    const handleReactMultiSelectChange = (selectedOptions, data) => {
        const value = [];
        let clientids = [];
        if (selectedOptions?.length > 0 && selectedOptions?.some(item => item.value === 'all')) {
            clientids = regionGroupOptions.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value);
                data.clientIDs.forEach(r => { clientids.push(r) })
            })
        }
        setUser({
            ...user,
            [data.name]: _.uniq(clientids)
            // ['arrayRegionID']: user.level === 'region' ? value : [],
            // ['arrayGroupID']: user.level === 'group' ? value : []
        });
    }

    const handleReactRegionMultiSelectChange = (selectedOptions, name) => {

        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
        })
        if (name === 'arrayRegionID') {
            setUser({
                ...user,
                [name]: _.uniq(value),
                ['arraySubregionID']: [],
                ['arrayClientID']: []
            });
        }
        else {
            setUser({
                ...user,
                [name]: _.uniq(value),
                ['arrayClientID']: (name === 'arrayRegionID' || name === 'arraySubregionID' || name === 'arrayGroupID') ? [] : user.arrayClientID
            });
        }
    }

    const bindAddress = (place) => {
        var objUser = user;
        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            sublocality_level_1: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            //administrative_area_level_1: 'long_name',
            //country: 'long_name',
            postal_code: 'short_name'
        };
        if (place.formatted_address !== null)
            objUser = {
                ...objUser,
                ['address']: place.formatted_address
            };

        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
                var val = place.address_components[i][componentForm[addressType]];
                if (addressType === "administrative_area_level_1")
                    objUser = {
                        ...objUser,
                        ['state']: val
                    };
                if (addressType === "postal_code")
                    objUser = {
                        ...objUser,
                        ['areaCode']: val
                    };
                if (addressType === "country")
                    objUser = {
                        ...objUser,
                        ['country']: val
                    };
            }
        }
        setUser(objUser);
    };

    const handlePhoneChange = e => {
        const { name, value } = e.target;
        const phoneReg = /^[0-9]+$/;
        if (phoneReg.test(value) || value === '') {
            setUser({ ...user, [name]: value });
        }
    };

    const handleAddPhoneCode = (name, value) => {
        let val = value ? value : '';
        setUser({ ...user, [name]: val });
    };

    const handleChange = e => {
        const { value, name } = e.target;
        setUser({ ...user, [name]: value });

        setErrorFields({
            ...errorFields,
            [name]: ''
        })
    };

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setAddSubList({
            ...addSubList,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleonSubSelectChange = (e, data) => {

        setAddSubList({
            ...addSubList,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            ['sublist' + data.name]: ''
        });
    }
    const handleonEditSubSelectChange = (e, data) => {

        setEditSubList({
            ...editSubList,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            ['editsublist' + data.name]: ''
        });
    }
    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setEditSubList({
            ...editSubList,
            [name]: value
        });
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }
    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.id !== rec.id)];
                setSubList(deleteList)
                setUser({
                    ...user,
                    ['dmsKeys']: deleteList
                });
            }
        })
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['type'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistname'] = errorClass;
            else
                errors['editsublistname'] = errorClass;
        }
        if (_.isEmpty(rec['value']) || _.isEmpty(rec['value'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistvalue'] = errorClass;
            else
                errors['editsublistvalue'] = errorClass;
        }
        setErrorFields(errors)
        // if (subList.filter(item => item.id !== rec.id).some(item => item.type === rec.type)) {
        //     Swal.fire(CommonHelper.showLocale(props, 'Integration with same keys already exists'), '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];
        setSubList(addList)
        setUser({
            ...user,
            ['dmsKeys']: addList
        });
        setAddSubList({ type: '', value: '', id: uuid() })
        setEditSubList(null)
        setTexteditor({})
    }

    const handleModelClose = (role) => {
        setShowRoleModel(false)
        if (!_.isEmpty(role)) {
            setUser({
                ...user,
                roleID: role.documentID,
                role: role.name,
                level: role.level
            })
        }
    }

    const emailVerification = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _mandFields = ["name", "email"];
        for (let [key] of Object.entries(user)) {
            if (_mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(user[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof user[key] !== "undefined") {
                        if (!validateEmail(user[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setVerifyLoader(true);
        try {
            const emailVerificationLink = window.firebase.functions().httpsCallable('amsadmin-sendNewUserEmail');
            emailVerificationLink({
                "email": user.email,
                "name": user.name,
                "settingsID": user.settingsID
            }).then((data) => {
                setVerifyLoader(false);
                if (data.data.success === true) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setVerifyLoader(false);
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong. Please contact support.'), '', 'error')
        }
    }

    const handleMFAChange = (cheked) => {
        if (cheked) {
            setShowMFAEnableModel(true);
        }
        else {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to disable multi-factor authentication.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then((result) => {
                if (result.value) {
                    handleEnableMFA(cheked)
                }
            })

        }
    }

    const handleMFAModelClose = (user) => {
        setShowMFAEnableModel(false);
        if (user)
            setUser({
                ...user,
                phone: user.phone,
                phoneCode: user.phoneCode
            })
    }

    const handleEnableMFA = (checked) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (checked) {
            if (_.isEmpty(user['phone']) || _.isEmpty(user['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
            setErrorFields(errors)
            if (!formIsValid) {
                return;
            }
        }
        setmfaLoader(true);
        try {
            const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
            enrollMFA({
                "uid": user.documentID,
                "phone": `+${user.phoneCode.replace('+', '')}${user.phone}`,
                "enroll": checked
            }).then((data) => {
                setmfaLoader(false);
                if (data.data.success === true) {
                    let updateUser = {
                        phoneCode: user.phoneCode,
                        phone: user.phone,
                        enableMFA: checked
                    }
                    setUser({
                        ...user,
                        ...updateUser
                    })
                    setShowMFAEnableModel(false);
                    window.firebase.firestore().doc(`users/${user.documentID}`).set(updateUser, { merge: true });
                    toast.notify(`Multi-factor authentication ${checked ? 'enabled' : 'disabled'} successfully.`, {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setmfaLoader(false);
            Swal.fire(CommonHelper.showLocale(props, 'Something went wrong. Please contact support.'), '', 'error')
        }
    }

    const handleCheckChange = (e) => {
        if (e.target.name === 'enableMFA')
            setUser({
                ...user,
                [e.target.name]: enableTwoFactorAuth ? true : e.target.checked
            })
        else
            setUser({
                ...user,
                [e.target.name]: e.target.checked
            })
    }


    return _.isEmpty(user) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-user"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="form-row settings-profile pt-2">

                                    <div className="settings-profileimage">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader pl-2">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (!_.isEmpty(user.profileImage))
                                                ?
                                                <a data-fancybox={`profileImage`} href={user.profileImage} onClick={(e) => { e.preventDefault(); }}>
                                                    <img src={user.profileImage} alt="" className="rounded-circle img-object-fit" />
                                                </a>
                                                :
                                                <img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
                                        }

                                    </div>
                                    <div className="settings-profile-info">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="profileImage" className={`btn-common float-left mr-2 font-600`}><Translate text={'Choose Picture'} />
                                                <input className="fileInput"
                                                    type="file"
                                                    name="profileImage"
                                                    id="profileImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'users', 'profileImage', 'Profile Image')} />
                                            </label>
                                            <div className={`settings-delete-button ${_.isEmpty(user.profileImage) ? 'btn-disable' : ''}`}> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setUser({
                                                        ...user,
                                                        ['profileImage']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a>
                                            </div>
                                        </div>
                                        <div className=" settings-limit"><Translate text={'maxSizeMB'} /></div>
                                    </div>

                                </div>
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="nav-fill" mountOnEnter={true}>
                                                <Tab eventKey="login" title="Login Details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label ><Translate text={'name'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={user.name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label ><Translate text={'email'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'email'}
                                                                className={`form-control ${errorFields["email"]}`}
                                                                name="email"
                                                                onChange={handleOnChange}
                                                                value={user.email}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">

                                                        <div className="form-group col-md-6">
                                                            <a href="#" className={`inputlink-addmore ycustom-more`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowRoleModel(true)
                                                                    setPopHeader('Add Role')
                                                                }}>
                                                                + <Translate text={'add'} />
                                                            </a>
                                                            <label ><Translate text={'Role'} /></label>
                                                            <ReactSelect
                                                                options={_.map(roles.filter(e => levelAccess.some(a => a === e.level)), function (c) {
                                                                    return {
                                                                        value: c.documentID,
                                                                        doc: c,
                                                                        label: <>{c.name}<span className="user-role-span">{_.find(levelOptions, { value: c.level }) ? _.find(levelOptions, { value: c.level }).label : c.level}</span></>,
                                                                        active: _.isBoolean(c.active) ? c.active : true,
                                                                        searchlabel: c.name
                                                                    };
                                                                })}
                                                                name={"roleID"}
                                                                placeholder={'select role'}
                                                                onChange={handleReactSelectChange}
                                                                value={user.roleID}
                                                                classNamePrefix={`${errorFields["roleID"]} cursor-pointer basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label ><Translate text={'Department'} /></label>
                                                            <ReactSelect
                                                                options={departments}
                                                                name={"department"}
                                                                placeholder={'select department'}
                                                                onChange={handleReactSelectChange}
                                                                value={user.department}
                                                                classNamePrefix={`${errorFields["department"]} cursor-pointer basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                    {
                                                        user.level === 'region' || user.level === 'group' ? (<div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>{user.level === 'region' ? 'Region' : 'Group'}</label>
                                                                <ReactMultiSelect
                                                                    options={user.level === 'region' ? clientRegions : clientGroups}
                                                                    name={`array${CommonHelper.autoCaps(user.level)}ID`}
                                                                    placeholder={`select ${user.level}`}
                                                                    onChange={(data) => handleReactRegionMultiSelectChange(data, `array${CommonHelper.autoCaps(user.level)}ID`)}
                                                                    value={user[`array${CommonHelper.autoCaps(user.level)}ID`]}
                                                                    classNamePrefix={`${errorFields["arrayRegionID"]} ${errorFields["arrayGroupID"]} basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>) : (<></>)
                                                    }
                                                    {
                                                        user.level === 'region' ? (<div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label>Subregion</label>
                                                                <ReactMultiSelect
                                                                    options={clientSubregions}
                                                                    name={`arraySubregionID`}
                                                                    placeholder={`select subregion`}
                                                                    onChange={(data) => handleReactRegionMultiSelectChange(data, `arraySubregionID`)}
                                                                    value={user[`arraySubregionID`]}
                                                                    classNamePrefix={`${errorFields["arraySubregionID"]} basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>) : (<></>)
                                                    }
                                                    {
                                                        (!_.isEmpty(user.level) && (user.level !== 'oem' || user.role !== 'Administrator')) ? (<div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'client'} /></label>
                                                                <ReactMultiSelect
                                                                    options={regionGroupOptions}
                                                                    name={'arrayClientID'}
                                                                    placeholder={'select client'}
                                                                    onChange={handleReactMultiSelectChange}
                                                                    value={user.arrayClientID}
                                                                    classNamePrefix={`${errorFields["arrayClientID"]} basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                            {
                                                                tradeinPro ?
                                                                    <div className="form-group col-md-12">
                                                                        <label ><Translate text={'Trade in Pro Client'} /></label>
                                                                        <ReactMultiSelect
                                                                            options={regionGroupOptions}
                                                                            name={'tradeinProClientID'}
                                                                            placeholder={'select client'}
                                                                            onChange={handleReactMultiSelectChange}
                                                                            value={user.tradeinProClientID}
                                                                            classNamePrefix={`${errorFields["tradeinProClientID"]} basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div> : (<></>)
                                                            }
                                                        </div>) : (<></>)
                                                    }
                                                    {/* {
                                                        newUser ? (<div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'password'} /></label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="new-password"
                                                                    placeholder={'password'}
                                                                    className={`form-control ${errorFields["password"]}`}
                                                                    name="password"
                                                                    onChange={handleOnChange}
                                                                    value={user.password}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label ><Translate text={'Confirm Password'} /></label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="new-password"
                                                                    placeholder={'confirm password'}
                                                                    className={`form-control ${errorFields["cpassword"]}`}
                                                                    name="cpassword"
                                                                    onChange={handleOnChange}
                                                                    value={user.cpassword}
                                                                />
                                                            </div>
                                                        </div>
                                                        ) : (<></>)
                                                    } */}
                                                    {/* {
                                                        enableTwoFactorAuth ? (<> */}
                                                    <div className="form-row">

                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Mobile No.'} /></label>
                                                            <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                                <div className='input-group-prepend'>
                                                                    <Dropdown className={`btn btn-outline-secondary`}>
                                                                        <Dropdown.Toggle as={CustomToggle}>
                                                                            <span id='spn-code-name' data-name={user.phoneCode}>
                                                                                {user.phoneCode &&
                                                                                    phoneCodes.find(item => item.value === user.phoneCode)
                                                                                    ? phoneCodes.find(
                                                                                        item => item.value === user.phoneCode,
                                                                                    ).value + ' '
                                                                                    : 'Code'}
                                                                            </span>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu
                                                                            as={CustomSearchMenu}
                                                                            className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                            ChildClass='pipeline-dropdown'
                                                                            xplacement='bottom-end'
                                                                        >
                                                                            {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                                phoneCodes.map((rec, index) => {
                                                                                    return (
                                                                                        <Dropdown.Item
                                                                                            key={index}
                                                                                            className={`current-pipeline ${user.phoneCode && rec.value === user.phoneCode
                                                                                                ? 'active'
                                                                                                : ''
                                                                                                }`}
                                                                                            onClick={e => {
                                                                                                e.preventDefault();
                                                                                                handleAddPhoneCode(
                                                                                                    'phoneCode',
                                                                                                    rec.value,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {rec.label}
                                                                                        </Dropdown.Item>
                                                                                    );
                                                                                })
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <InputText
                                                                    autoComplete="off"
                                                                    placeholder={'phone'}
                                                                    className={`form-control`}
                                                                    name="phone"
                                                                    onChange={handlePhoneChange}
                                                                    value={user.phone}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Two Factor Authentication'} /></label>
                                                            <span className="switch switch-sm mr-2 w-100 d-inline-block mt-2">
                                                                <InputCheckBox
                                                                    id="enable-mfa"
                                                                    className="switch"
                                                                    name={'enableMFA'}
                                                                    checked={Boolean(user.enableMFA)}
                                                                    onChange={(e) => { handleCheckChange(e) }} />
                                                                <label htmlFor="enable-mfa">Enable</label>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* </>) : (<></>)
                                                    } */}
                                                    {/* {
                                                        !newUser && enableTwoFactorAuth ? (
                                                            <div className="form-row">
                                                                <div className="user-mfa-wrapper">
                                                                    <h4>Two Factor Authentication</h4>
                                                                    {
                                                                        user.enableMFA ? (<div className="mfa-green">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> You have enabled Multi-factor Authentication </div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-red float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(false)}>
                                                                                    {
                                                                                        mfaLoader ?
                                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                            : (<></>)
                                                                                    }
                                                                                    Disable</button>
                                                                            </div>
                                                                        </div>) : (<div className="mfa-blue">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Enable Multi-factor Authentication</div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(true)}> Enable</button>
                                                                            </div>
                                                                        </div>)
                                                                    }


                                                                </div>
                                                            </div>
                                                        ) : (<></>)
                                                    } */}
                                                </Tab>
                                                <Tab eventKey="other" title="Other Info">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Personal Email'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'personel email'}
                                                                className={`form-control ${errorFields["personelEmail"]}`}
                                                                name="personelEmail"
                                                                onChange={handleChange}
                                                                value={user.personelEmail}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Designation'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'designation'}
                                                                className={`form-control ${errorFields["designation"]}`}
                                                                name="designation"
                                                                onChange={handleOnChange}
                                                                value={user.designation}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'address'} /></label>
                                                            <AutoComplete
                                                                className={`form-control`}
                                                                bindAddress={bindAddress}
                                                                types={['address']}
                                                                value={user.address ? user.address : ''}
                                                                placeholder={'search address'}
                                                                onChange={handleChange}
                                                                name="address"
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label > <Translate text={'country'} /></label>
                                                            <ReactSelect
                                                                options={!_.isEmpty(props.dealersettings.countries) ? _.map(props.dealersettings.countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } }) : []}
                                                                name={"country"}
                                                                placeholder={'select country'}
                                                                onChange={handleReactSelectChange}
                                                                value={user.country}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        <div className="form-group col-md-3">
                                                            <label><Translate text={'state'} /></label>
                                                            <ReactSelect
                                                                options={
                                                                    !_.isEmpty(user.country) && !_.isEmpty(props.dealersettings.states) ?
                                                                        _.map(props.dealersettings.states.filter(item => item.country_code === user.country), function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } })
                                                                        : []
                                                                }
                                                                name={"state"}
                                                                placeholder={'select state'}
                                                                onChange={handleReactSelectChange}
                                                                value={user.state}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                                removeClearable={false}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Office Phone No'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'work phone'}
                                                                className={`form-control`}
                                                                name="workPhone"
                                                                onChange={handlePhoneChange}
                                                                value={user.workPhone}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label > <Translate text={'Post/Area Code'} /></label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'area code'}
                                                                className={`form-control`}
                                                                name="areaCode"
                                                                onChange={handlePhoneChange}
                                                                value={user.areaCode}
                                                            />
                                                        </div>

                                                    </div>
                                                </Tab>
                                                <Tab eventKey="dms" title="DMS Keys">
                                                    <div className="common-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="head-light" width="40%"> <Translate text={'name'} /></th>
                                                                    <th scope="col" className="head-light" width="40%"> <Translate text={'Key'} /></th>
                                                                    <th scope="col" className="head-light border-right-0" width="20%"> <Translate text={'Actions'} /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><div className="">
                                                                        <ReactSelect
                                                                            options={integarions}
                                                                            name={"type"}
                                                                            placeholder={'select name'}
                                                                            onChange={handleonSubSelectChange}
                                                                            value={addSubList.type}
                                                                            classNamePrefix={`${errorFields["sublistname"]} cursor-pointer basic-select`}
                                                                            id="sublisttype"
                                                                        >
                                                                        </ReactSelect>
                                                                    </div></td>
                                                                    <td><div className="">
                                                                        <InputText
                                                                            id="sublistvalue"
                                                                            autoComplete="off"
                                                                            placeholder={'value'}
                                                                            className={`form-control ${errorFields["sublistvalue"]}`}
                                                                            name="value"
                                                                            onChange={handleOnSubChange}
                                                                            value={addSubList.value}
                                                                        />
                                                                    </div></td>
                                                                    <td className="border-right-0">
                                                                        <div className="button-height-fix" style={{ width: '150px' }}>
                                                                            <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                                            {
                                                                                addSubList.type === 'autoit' ? (
                                                                                    <button type="button" className="btn btn-green ml-1" onClick={() => {
                                                                                        setdmsKeyModel({
                                                                                            show: true,
                                                                                            title: `User Mapping - ${user.name}`
                                                                                        })
                                                                                    }}><i className='ico icon-refresh mr-1 float-left mt-1'></i><Translate text={'Fetch'} /></button>
                                                                                ) : (<></>)
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    subList.length > 0 ?
                                                                        subList.map((rec, index) => {
                                                                            return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                                <td>
                                                                                    {
                                                                                        textEditor[rec.id] ? (<> <ReactSelect
                                                                                            options={integarions}
                                                                                            name={"type"}
                                                                                            placeholder={'select name'}
                                                                                            onChange={handleonEditSubSelectChange}
                                                                                            value={editSubList.type}
                                                                                            classNamePrefix={`${errorFields["editsublistname"]} cursor-pointer basic-select`}
                                                                                            id="editsublistname"
                                                                                        >
                                                                                        </ReactSelect></>) : (<>{_.find(integarions, { 'value': rec.type }) ? _.find(integarions, { 'value': rec.type }).label : rec.type}</>)
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        textEditor[rec.id] ? (<><InputText
                                                                                            id="editsublistvalue"
                                                                                            autoComplete="off"
                                                                                            placeholder={'value'}
                                                                                            className={`form-control ${errorFields["editsublistvalue"]}`}
                                                                                            name="value"
                                                                                            onChange={handleOnEditSubChange}
                                                                                            value={editSubList.value}
                                                                                        /></>) : (<>{rec.value}</>)
                                                                                    }
                                                                                </td>
                                                                                <td className="border-right-0">
                                                                                    <div className="action-fix" style={{ width: '150px' }}>
                                                                                        {
                                                                                            textEditor[rec.id] ?
                                                                                                (<><div className="table-edit float-left mr-2 mt-1"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                                    <div className="table-edit float-left mr-2 mt-1"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                                    {
                                                                                                        editSubList.type === 'autoit' ? (
                                                                                                            <button type="button" className="btn btn-green ml-1" onClick={() => {
                                                                                                                setdmsKeyModel({
                                                                                                                    show: true,
                                                                                                                    title: `User Mapping - ${user.name}`
                                                                                                                })
                                                                                                            }}><i className='ico icon-refresh mr-1 float-left mt-1'></i><Translate text={'Fetch'} /></button>
                                                                                                        ) : (<></>)
                                                                                                    }
                                                                                                </>) :
                                                                                                (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                                    <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                                </>)
                                                                                        }

                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                        : (<></>)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </Tab>
                                                {
                                                    props.dealersettings.superAdmin === true ? (
                                                        <Tab eventKey="notifications" title="Notification Settings">
                                                            <div className='switch-list'>
                                                                <div className='row'>
                                                                    <div className='col-sm-6'>
                                                                        <ul>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='is-notify-sound'
                                                                                        className='switch'
                                                                                        name={'isNotifyWithSound'}
                                                                                        checked={
                                                                                            user.isNotifyWithSound
                                                                                                ? user.isNotifyWithSound
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='is-notify-sound'></label>
                                                                                </span>
                                                                                <Translate text={'Play sound on new notification'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='is-cafe-order-notify-sound'
                                                                                        className='switch'
                                                                                        name={'isCafeOrderNotifySound'}
                                                                                        checked={
                                                                                            user.isCafeOrderNotifySound
                                                                                                ? user.isCafeOrderNotifySound
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='is-cafe-order-notify-sound'></label>
                                                                                </span>
                                                                                <Translate text={'Play notification sound on new kitchen order'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-kitchen-booking'
                                                                                        className='switch'
                                                                                        name={'notifyKitchen'}
                                                                                        checked={
                                                                                            user.notifyKitchen
                                                                                                ? user.notifyKitchen
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-kitchen-booking'></label>
                                                                                </span>
                                                                                <Translate text={'Enable Kitchen notification'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-oem-inbound-lead'
                                                                                        className='switch'
                                                                                        name={'notifyInboundleadOEM'}
                                                                                        checked={
                                                                                            user.notifyInboundleadOEM
                                                                                                ? user.notifyInboundleadOEM
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-oem-inbound-lead'></label>
                                                                                </span>
                                                                                <Translate text={'Enable OEM inbound lead notification '} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-inbound-lead'
                                                                                        className='switch'
                                                                                        name={'notifyInboundlead'}
                                                                                        checked={
                                                                                            user.notifyInboundlead
                                                                                                ? user.notifyInboundlead
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-inbound-lead'></label>
                                                                                </span>
                                                                                <Translate text={'Enable inbound lead notification'} />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='col-sm-6'>
                                                                        <ul>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-service-booking'
                                                                                        className='switch'
                                                                                        name={'notifyServiceBooking'}
                                                                                        checked={
                                                                                            user.notifyServiceBooking
                                                                                                ? user.notifyServiceBooking
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-service-booking'></label>
                                                                                </span>
                                                                                <Translate text={'Enable service booking notification'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='ownDashboard'
                                                                                        className='switch'
                                                                                        name={'ownDashboard'}
                                                                                        checked={
                                                                                            user.ownDashboard
                                                                                                ? user.ownDashboard
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='ownDashboard'></label>
                                                                                </span>
                                                                                <Translate text={'Default my own dashboard'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-new-tradeinpro'
                                                                                        className='switch'
                                                                                        name={'notifyNewtradeinPro'}
                                                                                        checked={
                                                                                            user.notifyNewtradeinPro
                                                                                                ? user.notifyNewtradeinPro
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-new-tradeinpro'></label>
                                                                                </span>
                                                                                <Translate text={'Enable new appraisal notification'} />
                                                                            </li>
                                                                            <li>
                                                                                {' '}
                                                                                <span className='switch switch-sm mr-2'>
                                                                                    <InputCheckBox
                                                                                        id='notify-inbound-tradeinpro'
                                                                                        className='switch'
                                                                                        name={'notifyInboundleadTIP'}
                                                                                        checked={
                                                                                            user.notifyInboundleadTIP
                                                                                                ? user.notifyInboundleadTIP
                                                                                                : false
                                                                                        }
                                                                                        onChange={handleCheckChange}
                                                                                    />
                                                                                    <label htmlFor='notify-inbound-tradeinpro'></label>
                                                                                </span>
                                                                                <Translate text={'Enable Tradein Pro inbound lead notification'} />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab>) : (<></>)
                                                }
                                            </Tabs>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {
                        newUser === false ?
                            (<>
                                {/* <button type="button" className="btn btn-secondary float-left" onClick={() => { setShowPasswordModel(true) }}>Update Password</button> */}
                                <button type="button" className="btn btn-secondary float-left" onClick={() => emailVerification()} >

                                    {
                                        verifyLoader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<><i className="ico icon-email"></i></>)
                                    }
                                    <Translate text={'Resend Email Invitation'} /></button>
                            </>) : (<></>)
                    }

                    <button type="button" className={`btn btn-primary float-right ml-2 ${imageLoader === true ? 'btn-disable' : ''}`} onClick={(e) => saveUser(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        {
                            newUser ? (<Translate text={'Send Invitation'} />)
                                : (<Translate text={'Update'} />)
                        }

                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal>
            {
                showMFAEnableModel ? (
                    <Modal
                        show={showMFAEnableModel}
                        onHide={handleMFAModelClose}
                        backdrop="static"
                        backdropClassName={props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-update-password"
                        enforceFocus={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title><Translate text={'Enable Multi-factor authentication'} />  </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="form-style w-100">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'phone'} /></label>
                                                <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                    <div className='input-group-prepend'>
                                                        <Dropdown className={`btn btn-outline-secondary`}>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                <span id='spn-code-name' data-name={user.phoneCode}>
                                                                    {user.phoneCode &&
                                                                        phoneCodes.find(item => item.value === user.phoneCode)
                                                                        ? phoneCodes.find(
                                                                            item => item.value === user.phoneCode,
                                                                        ).value + ' '
                                                                        : 'Code'}
                                                                </span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu
                                                                as={CustomSearchMenu}
                                                                className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                ChildClass='pipeline-dropdown'
                                                                xplacement='bottom-end'
                                                            >
                                                                {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                    phoneCodes.map((rec, index) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={index}
                                                                                className={`current-pipeline ${user.phoneCode && rec.value === user.phoneCode
                                                                                    ? 'active'
                                                                                    : ''
                                                                                    }`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    handleAddPhoneCode(
                                                                                        'phoneCode',
                                                                                        rec.value,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {rec.label}
                                                                            </Dropdown.Item>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'phone'}
                                                        className={`form-control`}
                                                        name="phone"
                                                        onChange={handlePhoneChange}
                                                        value={user.phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleEnableMFA(true)}>
                                {
                                    mfaLoader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                <Translate text={'enable'} />
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => handleMFAModelClose()} ><Translate text={'cancel'} /></button>
                        </Modal.Footer>
                    </Modal>
                ) : (<></>)
            }
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={showPasswordModel}>
                <UpdatePassword
                    show={showPasswordModel}
                    handleClose={handlePasswordModelClose}
                    uid={!_.isEmpty(props.user) ? props.user.documentID : ''}
                />
            </PopUpModal>
            <PopUpModal show={showRoleModel}>
                <AddRole
                    {...props}
                    show={showRoleModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    role={null}
                    allRoles={roles}
                />
            </PopUpModal>
            <PopUpModal show={dmsKeyModel.show}>
                <FetchDMSKey
                    {...props}
                    {...dmsKeyModel}
                    user={user}
                    handleClose={(val) => {
                        setdmsKeyModel({ show: false })
                        if (val) {
                            handleSublistSave({
                                ...(!_.isEmpty(textEditor) ? editSubList : addSubList),
                                value: val
                            }, !!_.isEmpty(textEditor))
                        }
                    }}
                />
            </PopUpModal>
        </>
    )
}

export default AddUser