import React from 'react';
import _ from 'lodash'

const CustomEvent = React.memo((props) => {
    const { event, children } = props;

    return (props.viewstate === 'month' || props.type === 'date') ? (<div data-id={event.value} className={`${children.props.className} calendar-event-cell day-fleetpro-empty`}
        style={{
            ...children.props.style,
            border: `1px solid ${event.color}`,
            background: `${event.color}30`
        }}>
        <div className={`${children.props.children.props.className}`}>
            <div className="rbc-fleetpro-status">
                {
                    event.dealer ? (
                        <div className="rbc-fleetpro-label"><i className="ico icon-group"></i>{event.dealer}</div>
                    ) : (<></>)
                }
                <div className="badge badge-pill badge-status-empty float-left" style={{ background: event.color }}>
                    {event.name}
                </div>
            </div>
        </div>
    </div>) :
        (
            <div className={`${children.props.className} calendar-event-cell day-fleetpro-empty`} style={{
                ...children.props.style,
                border: `1px solid ${event.color}`,
                background: `${event.color}30`
            }} >
                <div className={`${children.props.children[0].props.className}`}>
                    <div className="rbc-fleetpro-status">
                        {
                            event.dealer ? (
                                <div className="rbc-fleetpro-label"><i className="ico icon-group"></i>{event.dealer}</div>
                            ) : (<></>)
                        }
                        <div className="badge badge-pill badge-status-empty float-left" style={{ background: event.color }}>
                            {event.name}
                        </div>
                    </div>
                </div>
            </div>
        );
})

export default CustomEvent