import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, ReactSelect, TableView, SidePanel } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { CustomToggle, CustomFilter, CustomMenu } from '../../../components/customdropdown';
import ReactExport from 'react-export-excel-xlsx-fix';
import { customerLogFields } from "./viewModel";
import { StageProvider, StageConsumer } from '../../pipeline/provider'
import QuickView from "../../pipeline/quickview";
import { callTypes } from '../../../services/enum'
import { firestoreDB } from '../../../services/helper';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const CustomerLogReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 373), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 250) })
    const [loader, setLoader] = useState(true)
    const [allCustomerLogs, setCustomerLogs] = useState([]);
    const [userOptions, setUserOptions] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedUser, setUser] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [selectedMake, setSelectedMake] = useState()
    const [selectedModel, setSelectedModel] = useState()
    const [selectedDMSType, setSelectedDMSType] = useState()
    const [filterShow, setShow] = useState(false)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [kpis, setKpis] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState()
    const [csvData, setCSVData] = useState([])
    const [tempData, setTempData] = useState([])
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [callStatus, setCallStatus] = useState([]);
    const [selectedCallType, setSelectedCallType] = useState()
    const [selectedCallStatus, setSelectedCallStatus] = useState()

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 250),
                tblWidth: (window.innerWidth - 373)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        let _kpis = [];
        _kpis.push({ label: 'Notes', name: 'Notes', value: 'note' })
        _kpis.push({ label: 'Call', name: 'Call', value: 'call' })
        _kpis.push({ label: 'Message', name: 'Message', value: 'message' })
        _kpis.push({ label: 'Email', name: 'Email', value: 'email' })
        setKpis(_kpis)
        setSelectedKpi(_kpis[0])
    }, [])

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
            window.unSubUserlogReport && window.unSubUserlogReport.unsubscribe()
        };
    }, []);

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            { title: 'Contact Name', dynValue: 'contactName', dynWidth: 10, ...csvStyle.header },
            { title: 'Email', dynValue: 'contactEmail', dynWidth: 10, ...csvStyle.header },
            { title: 'Phone', dynValue: 'phone', dynWidth: 10, ...csvStyle.header },
            { title: 'Vehicle Model', dynValue: 'vehicleModel', dynWidth: 10, ...csvStyle.header },
            { title: 'Sales Type', dynValue: 'vehicleSaletype', dynWidth: 10, ...csvStyle.header },
            { title: 'Notes', dynValue: 'notes', dynWidth: 10, ...csvStyle.header },
            { title: 'Created On', dynValue: 'activityAddedDate', dynWidth: 10, ...csvStyle.header },
            { title: 'Created By', dynValue: 'activityOwner', dynWidth: 10, ...csvStyle.header },
            { title: 'Enquiry Number', dynValue: 'enqNumber', dynWidth: 15, ...csvStyle.header }
        )
        var exportData = []
        progressReport.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue] ? obj[col.dynValue] : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return (h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth) ? {
                    ...h,
                    'width': { wch: w.dynWidth + 2 }
                } : h.width ? { ...h } : {
                    ...h,
                    'width': { wch: h.dynWidth + 2 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [progressReport])

    useEffect(() => {
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (selectedMake) {
            let _models = !_.isEmpty(makes.filter(m => m.value === selectedMake.value)[0]) ? makes.filter(m => m.value === selectedMake.value)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [selectedMake])

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {
            const setttings = props.dealersettings.client.settings;

            const arrcallstatus = [];
            setttings.callStatus && setttings.callStatus.forEach((doc) => {
                arrcallstatus.push({
                    value: doc.value,
                    searchlabel: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
            setCallStatus(arrcallstatus)
        }


    }, [])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec,
                name: rec.name
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    useEffect(() => {
        if (!refreshData)
            return

        setLoader(true)



        let refCustomerLogsData = firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .where('isDone', '==', true)
            .where('type', '==', 'log')
            .where('startDate', '>=', dateRange.startDate)
            .where('startDate', '<=', dateRange.endDate)
            .orderBy('startDate', 'desc')

        if (ownerDashboard)
            refCustomerLogsData = refCustomerLogsData.where('owner', '==', props.dealersettings.id)

        let reflinkEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('linkedDate', '>=', dateRange.startDate)
            .where('linkedDate', '<=', dateRange.endDate)


        window.unSubUserlogReport = combineLatest(
            collection(refCustomerLogsData),
            collection(reflinkEmailsData)
        ).pipe(
            map(([logs, linkEmails]) => {
                return [
                    _.map(logs, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(linkEmails, function (obj) {
                        return {
                            ...obj.data(),
                            documentID: obj.id,
                            type: 'log',
                            subType: 'email',
                            startDate: obj.data().linkedDate,
                            addedDate: obj.data().linkedDate,
                            owner: obj.data().linkedBy,
                            threadID: (obj.data().thread ? obj.data().thread.id : null)
                        }
                    }),
                ]
            }))
            .subscribe(allDocs => {
                let _tempData = allDocs[0].filter(a => a.notes && !_.isEmpty(a.notes.trim()) && (!_.isEmpty(a.enquiryID) || !_.isEmpty(a.contactID))).concat(allDocs[1].filter(a => (ownerDashboard ? a.owner === props.dealersettings.id : true) && (!_.isEmpty(a.enquiryID) || !_.isEmpty(a.contactID))))
                setTempData(_tempData)
                // setLoader(false)
                // setRefreshClientData(true)
                // setRefreshData(false)
                if (_tempData.length === 0) {
                    setCustomerLogs([])
                    setLoader(false)
                    setRefreshClientData(true)
                    setRefreshData(false)
                }
            });
        // return () => {
        //     unSubUserlogReport && unSubUserlogReport.unsubscribe()
        // }

    }, [refreshData])

    useEffect(() => {
        async function getEnquiries(logs) {
            const promises = []
            const _logs = [];
            _.uniq(_.map(logs.filter(e => !_.isEmpty(e.enquiryID) && _.isEmpty(e.enquiry)), function (e) { return e.enquiryID })).forEach(el => {
                promises.push(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${el}`).get())
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                if (snap.exists && snap.data().isDeleted === false) {
                    logs.filter(e => e.enquiryID === snap.id).forEach(rec => {
                        _logs.push({
                            ...CommonHelper.convertActivityLogVM({
                                ...rec,
                                enquiry: snap.data(),
                                contact: snap.data().contact
                            }, { ...props })
                        })
                    })
                }
            })

            const contactPromise = [];
            _.uniq(_.map(logs.filter(e => e.contactID && _.isEmpty(e.enquiryID) && _.isEmpty(e.contact)), function (e) { return e.contactID })).forEach(el => {
                contactPromise.push(firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${el}`).get())
            });
            const contactSnapshots = await Promise.all(contactPromise)
            contactSnapshots.forEach(snap => {
                if (snap.exists && snap.data().isDeleted === false) {
                    logs.filter(e => e.contactID === snap.id).forEach(rec => {
                        _logs.push({
                            ...CommonHelper.convertActivityLogVM({
                                ...rec,
                                contact: snap.data()
                            }, { ...props })
                        })
                    })
                }
            })

            let _merge = _logs.concat(_.map(logs, function (obj) { return { ...CommonHelper.convertActivityLogVM(obj, { ...props }) } }));
            let _uniq = _.uniqBy(_merge, 'documentID');
            setCustomerLogs(_uniq);
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
            window.unSubUserlogReport && window.unSubUserlogReport.unsubscribe()
        }
        if (tempData.length > 0) {
            getEnquiries(tempData);
        }
    }, [tempData])


    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.client) || _.isEmpty(props.dealersettings.client.settings) || !refreshClientData || _.isEmpty(selectedKpi))
            return

        setProgressReport(allCustomerLogs.filter(a =>
            a.subType === selectedKpi.value
            && (!_.isEmpty(selectedUser) ? a.owner === selectedUser.value : true)
            && (!_.isEmpty(selectedMake) ? (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement) && (a.enquiry.requirement.make === selectedMake.value || (!_.isEmpty(a.enquiry.requirement.stock) && a.enquiry.requirement.stock.make === selectedMake.value))) : true)
            && (!_.isEmpty(selectedModel) ? (!_.isEmpty(a.enquiry) && !_.isEmpty(a.enquiry.requirement) && (a.enquiry.requirement.model === selectedModel.value || (!_.isEmpty(a.enquiry.requirement.stock) && a.enquiry.requirement.stock.model === selectedModel.value))) : true)
            && (!_.isEmpty(selectedCallType) ? a.callType === selectedCallType.value : true)
            && (!_.isEmpty(selectedCallStatus) ? a.callStatus === selectedCallStatus.value : true)
        ).sort(function (x, y) {
            return (x.addedDate.seconds > y.addedDate.seconds ? -1 : 1);
        }))
        setRefreshClientData(false)

    }, [refreshClientData, selectedKpi])

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const filterCounts = () => {
        let objFilter = {
            user: (!_.isEmpty(selectedUser) && selectedUser.value !== 'all') ? selectedUser.value : '',
            dmstype: !_.isEmpty(selectedDMSType) ? selectedDMSType.value : '',
            make: !_.isEmpty(selectedMake) ? selectedMake.value : '',
            model: !_.isEmpty(selectedModel) ? selectedModel.value : '',
            callStatus: !_.isEmpty(selectedCallStatus) ? selectedCallStatus.value : '',
            callType: !_.isEmpty(selectedCallType) ? selectedCallType.value : '',
        }

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {

            if (!_.isEmpty(data.enquiryID))
                sidepanelOpen(data.enquiryID)
            else if (!_.isEmpty(data.contactID))
                props.history.push(`/contacts/details/${data.contactID}?comments=${id}`);
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const handleClientdetailsClick = (e, id, data) => {
        if (!_.isEmpty(data.enquiryID))
            sidepanelOpen(data.enquiryID)
        else if (!_.isEmpty(data.contactID))
            props.history.push(`/contacts/details/${data.contactID}?comments=${id}`);
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    return (
        <>
            <h2>Customer Logs
                <Dropdown className='enquiry-view-more float-right mt-0'>
                    <Dropdown.Toggle as={CustomToggle} className='common-button'>
                        <i className='ico icon-more'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as={CustomMenu}
                        ChildClass="more-dropdown dropsub-fix"
                        xplacement="bottom-end"
                    >
                        {
                            <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`customer-log-report`}>
                                    <ExcelSheet dataSet={csvData} name="Sheet1" />
                                </ExcelFile>
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </h2>
            <div className="divider-line"></div>
            <div className="report-content-head">

                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <div className="form-style d-block">
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={kpis}
                                        name={"kpis"}
                                        placeholder={'select kpi'}
                                        onChange={(e, data) => {
                                            setSelectedKpi(e)
                                            setRefreshClientData(true)
                                        }}
                                        value={!_.isEmpty(selectedKpi) ? selectedKpi.value : ''}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3 daterange-fix">
                                    <AntDateRangePicker
                                        value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                        id={"dashboard-date-range"}
                                        name={'cusotmerlog-date'}
                                        format='DD/MM/YYYY'
                                        onChange={(e) => { handleDateRangeChange(e, 'cusotmerlog-date') }}
                                        placeholder='DD/MM/YYYY'
                                        onCalendarChange={(value) => {
                                            if (value) {
                                                const [start, end] = value;
                                                setDates([start, end]);
                                            }
                                            else {
                                                setDates([])
                                            }
                                        }}
                                        disabledDate={disabledDate}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setRefreshData(true)
                                        }}> <i className="ico icon-search"></i> Search</button>
                                        <button type="button" className="btn btn-default ml-3" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            selectedCallStatus(null)
                                            selectedCallType(null)
                                            setRefreshData(true)
                                            setShowCount(false)
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3 pr-2">
                        <div className="float-right">
                            <Dropdown ref={node} alignRight show={filterShow}>
                                <div className="advanced-filter-btn mr-2 float-right">
                                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                        setShow(!filterShow)
                                    }}>
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i>Filters
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu as={CustomFilter}
                                    id="table-reports-cols-settings-pipeline"
                                    className="dropdown-menu dropdown-menu-left"
                                    ChildClass="form-style quick-filter-wrap"
                                    xplacement="bottom-end"
                                >
                                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                        <div className="report-filter-wraper form-style">
                                            {
                                                !ownerDashboard ? (<div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>User</label>
                                                        <ReactSelect
                                                            options={userOptions}
                                                            name={"user"}
                                                            placeholder={'select user'}
                                                            onChange={(e, data) => {
                                                                setUser(e)
                                                            }}
                                                            value={!_.isEmpty(selectedUser) ? selectedUser.value : ''}
                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                            removeClearable={true}
                                                        >
                                                        </ReactSelect>
                                                    </div>
                                                </div>) : (<></>)
                                            }
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Call Type</label>
                                                    <ReactSelect
                                                        options={callTypes}
                                                        name={"callType"}
                                                        placeholder={'select call type'}
                                                        onChange={(e) => {
                                                            setSelectedCallType(e)
                                                        }}
                                                        value={selectedCallType ? selectedCallType.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Call Status</label>
                                                    <ReactSelect
                                                        options={callStatus}
                                                        name={"callStatus"}
                                                        placeholder={'select call status'}
                                                        onChange={(e) => {
                                                            setSelectedCallStatus(e)
                                                        }}
                                                        value={selectedCallStatus ? selectedCallStatus.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Make</label>
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={(e) => {
                                                            setSelectedMake(e)
                                                            setSelectedModel(null)
                                                        }}
                                                        value={selectedMake ? selectedMake.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Model</label>
                                                    <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={(e) => {
                                                            setSelectedModel(e)
                                                        }}
                                                        value={selectedModel ? selectedModel.value : ''}
                                                        classNamePrefix={` basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="quick-filter-footer">
                                        <button type="button" className="btn btn-default float-left" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedCallStatus(null)
                                            setSelectedCallType(null)
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(false)
                                        }}>Clear</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                                            setRefreshClientData(true)
                                            setShow(false)
                                            setShowCount(true)
                                        }}>Apply</button>

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : (
                    <div className="common-table">
                        {
                            progressReport.length > 0 ? (<TableView
                                datalist={progressReport}
                                height={windowSize.tblHeight}
                                width={windowSize.tblWidth}
                                columns={customerLogFields}
                                dynamicFields={customerLogFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                rowHeight={70}

                            />) : (
                                <div style={{ height: `${windowSize.tblHeight}px` }}><div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> No data</div>
                                </div>
                                </div>
                            )
                        }
                        {
                            showpanel.clsActive ? (
                                <StageProvider dealersettings={props.dealersettings} clientUsers={props.clientUsers} groupUsers={props.groupUsers} history={props.history}>
                                    <StageConsumer>
                                        {({ enquiries, isLoading, pipeline, columns, handleEnquiryFav, listenScrollEvent, stageLoading, updateEnquiry, sectionloading }) => (
                                            <SidePanel
                                                clsActive={showpanel.clsActive}
                                                clsName={showpanel.clsName}
                                                sidepanelClose={sidepanelClose}
                                                title=''
                                            >
                                                <QuickView {...props} enquiryid={showpanel.enquiryid} isFilterApplied={true} sidepanelClose={sidepanelClose} />
                                            </SidePanel>
                                        )}
                                    </StageConsumer>
                                </StageProvider>
                            ) : (<></>)
                        }
                    </div>
                )
            }
        </>
    )
}

export default CustomerLogReport;