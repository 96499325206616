/** LIBRARIES */
import React, { PureComponent } from "react";
import _ from 'lodash';
import Swal from 'sweetalert2';
import CommonHelper from '../../../services/common';
import { enquiryStatus } from '../../../services/enum';
class ExpandView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleExpandEnquiry = () => {
        const { enquiry, enquiryid, history, target, handleRouteChange, dealersettings,
            handleClientChange, isReadOnlyView, handleModuleChange,
            isFromLayout, layoutClose
        } = this.props;
        //localStorage.defaultModule !== 'afterSales'
        if (localStorage.defaultModule !== 'oem' && isReadOnlyView && handleModuleChange) {
            if (enquiry.isAfterSales) {
                localStorage.setItem('objSales', JSON.stringify({ id: enquiryid, type: 'enquiry' }));
                localStorage.setItem('defaultModule', 'afterSales');
                handleModuleChange('afterSales');
            }
            else {
                localStorage.setItem('objSales', JSON.stringify({ id: enquiryid, type: 'enquiry' }));
                localStorage.setItem('defaultModule', 'enquiry');
                handleModuleChange('enquiry');
            }

        }
        else if (localStorage.defaultModule === 'oem' && enquiry.clientID &&
            ((dealersettings.level === 'oem' && enquiry.isConvertedOEM) ||
                (dealersettings.level === 'region' && enquiry.isConvertedRegion) ||
                (dealersettings.level === 'group' && enquiry.isConvertedGroup))
            && !_.isEmpty(enquiry.transferHistory)) {
            const _transferHistoryData = CommonHelper.getLatestTransferHistory(enquiry.transferHistory, dealersettings, true);
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: `${CommonHelper.showLocale(this.props, 'Do you want to switch to')} ${_transferHistoryData.name}?`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                    allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${enquiry.clientID}`)
                        .set({ defaultModule: 'enquiry' }, { merge: true });
                    localStorage.setItem('oemswitchclient', enquiry.clientID)
                    handleClientChange(enquiry.clientID);
                    if (!Boolean(enquiry.isEmptyPipeline))
                        localStorage.setItem('objSales', JSON.stringify({ id: enquiryid, type: 'enquiry' }));
                }
            });
        }
        else if (localStorage.defaultModule === 'oem' && isReadOnlyView) {
            Swal.fire({
                title:CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, 'Do you want to switch to dealer.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            }).then(result => {
                if (result.value) {
                    window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${enquiry.clientID}`)
                        .set({ defaultModule: 'enquiry' }, { merge: true });
                    localStorage.setItem('oemswitchclient', enquiry.clientID)
                    handleClientChange(enquiry.clientID);
                    localStorage.setItem('objSales', JSON.stringify({ id: enquiryid, type: 'enquiry' }));
                }
            });
        }
        else {

            handleRouteChange({
                name: (enquiry.isContact && !_.isEmpty(enquiry.contact) && (!_.isEmpty(enquiry.contact.firstName) || !_.isEmpty(enquiry.contact.lastName)))
                    ? CommonHelper.displayContact([], enquiry.contact, '--')
                    : (!_.isEmpty(enquiry.engageNewContact) ? CommonHelper.displayContact([], enquiry.engageNewContact, '--') : (enquiry.displayID ? enquiry.displayID : this.props.enquiryid)),
                id: enquiryid,
                status: enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : enquiry.status,
                active: true,
                clientid: enquiry.clientID,
                module: enquiry.isAfterSales ? 'afterSales' : 'enquiry'
            })

            if (enquiry.isAfterSales && localStorage.defaultModule !== 'afterSales' && handleModuleChange) {
                localStorage.setItem('defaultModule', 'afterSales');
                handleModuleChange('afterSales');
            }
            else if (!enquiry.isAfterSales && localStorage.defaultModule === 'afterSales' && handleModuleChange) {
                localStorage.setItem('defaultModule', 'enquiry');
                handleModuleChange('enquiry');
            }

            if (target) {
                const url = `/enquiry/details/${enquiryid}`;
                window.open(url, '_blank');
            }
            else {
                history.push("/enquiry/details/" + enquiryid);
                if (isFromLayout && layoutClose)
                    layoutClose();

            }
        }
    }

    render() {
        const { enquiry, dealersettings } = this.props;
        let _isOEMConverted = localStorage.defaultModule === 'oem' && !_.isEmpty(enquiry) && _.isEmpty(enquiry.clientID) &&
            (//(dealersettings.level === 'oem' && enquiry.isConvertedOEM) ||
                (dealersettings.level === 'region' && enquiry.isConvertedRegion) ||
                (dealersettings.level === 'group' && enquiry.isConvertedGroup)) &&
            !_.isEmpty(enquiry.transferHistory) ? true : false;
        return (
            <>
                {
                    _isOEMConverted
                        ?
                        <></>
                        :
                        <div className="quick-view-expand">
                            <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.handleExpandEnquiry();
                            }}>
                                <i className="ico icon-expand" data-toggle="tooltip" title="" data-placement="bottom" data-original-title=" Go to detail view"></i>
                            </a>
                        </div>
                }
            </>

        );
    }
}

export default ExpandView;