import React, { useState } from 'react';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { ReactSelect } from '../../../../components';
import uuid from 'react-uuid';
import Swal from 'sweetalert2';
import CommonHelper from '../../../../services/common';


const AutoEnqUpdate = (props) => {
    const [addSubList, setAddSubList] = useState({ id: uuid() })
    const [editSubList, setEditSubList] = useState()
    const [textEditor, setTexteditor] = useState({})
    const [errorFields, setErrorFields] = useState({});
    const pipelineOptions = props?.dealersettings?.client?.settings?.allPipelines?.map(r => { return { ...r, label: r.name } }) || [];
    const prodDays = props?.dealer?.clientSettings?.enqupdate?.prodDays || [];
    const handleTextChange = (e) => {
        setAddSubList({
            ...addSubList,
            [e.target.name]: e.target.value
        });
        setErrorFields({
            ...errorFields,
            ['sublist' + e.target.name]: ''
        });
    };

    const handleEditTextChange = (e) => {
        setEditSubList({
            ...editSubList,
            [e.target.name]: e.target.value
        });
        setErrorFields({
            ...errorFields,
            ['editsublist' + e.target.name]: ''
        });
    };

    const handleonSubSelectChange = (e, data) => {
        if (data.name === 'pipelineTo') {
            setAddSubList({
                ...addSubList,
                [data.name]: e ? e.value : '',
                stageTo: '',
            })
        } else {
            setAddSubList({
                ...addSubList,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + data.name]: ''
        });
    }

    const generateStageOptions = _pipeline => {
        const selectedPipeline = pipelineOptions.filter(rec => rec.value === _pipeline)[0]
        let stagesToReturn = !_.isEmpty(selectedPipeline) ? selectedPipeline.stages.map(stage => {
            return {
                active: true,
                label: stage.name ? stage.name : '',
                value: stage.value
            }
        }) : []

        if (!_.isEmpty(selectedPipeline) && selectedPipeline.value === 'LeadsBucket') {
            stagesToReturn = stagesToReturn.filter(stage => stage.value !== 'Converted')
        }

        return stagesToReturn
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['days']) || _.isEmpty(rec['days'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistdays'] = errorClass;
            else
                errors['editsublistdays'] = errorClass;
        }
        if (_.isEmpty(rec['stageTo']) || _.isEmpty(rec['stageTo'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['subliststageTo'] = errorClass;
            else
                errors['editsubliststageTo'] = errorClass;
        }
        if (_.isEmpty(rec['pipelineTo']) || _.isEmpty(rec['pipelineTo'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistpipelineTo'] = errorClass;
            else
                errors['editsublistpipelineTo'] = errorClass;
        }
        if (_.isEmpty(rec['pipeline']) || _.isEmpty(rec['pipeline'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistpipeline'] = errorClass;
            else
                errors['editsublistpipeline'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...prodDays, rec] : [
            ..._.map(prodDays, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];

        props.handleDynObjChange(addList, 'enqupdate', 'prodDays')
        setAddSubList({ id: uuid() })
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }

    const handleonEditSubSelectChange = (e, data) => {
        if (data.name === 'pipelineTo') {
            setEditSubList({
                ...editSubList,
                [data.name]: e ? e.value : '',
                stageTo: '',
            })
        } else {
            setEditSubList({
                ...editSubList,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + data.name]: ''
        });
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...prodDays.filter(item => item.id !== rec.id)];
                props.handleDynObjChange(deleteList, 'enqupdate', 'prodDays')
            }
        })
    }

    return (<>
        <div className="settings-tab-fields-wrap">
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email From'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email from'}
                        className={`form-control ${props.errorFields["emailFrom"]}`}
                        name="emailFrom"
                        id={"emailFrom"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.emailFrom || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'Email Subject'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'email subject'}
                        className={`form-control ${props.errorFields["emailSubject"]}`}
                        name="emailSubject"
                        id={"emailSubject"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.emailSubject || ''}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'File Email From'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'file email from'}
                        className={`form-control ${props.errorFields["fileEmailFrom"]}`}
                        name="fileEmailFrom"
                        id={"fileEmailFrom"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.fileEmailFrom || ''}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label ><Translate text={'File Name'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'file name'}
                        className={`form-control ${props.errorFields["fileName"]}`}
                        name="fileName"
                        id={"fileName"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.fileName || ''}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label ><Translate text={'Prod. Days Index'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'prod. days index'}
                        className={`form-control ${props.errorFields["prodDaysIndex"]}`}
                        name="prodDaysIndex"
                        id={"prodDaysIndex"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.prodDaysIndex || ''}
                    />
                </div>
                <div className="form-group col-md-3">
                    <label ><Translate text={'Time'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'time'}
                        className={`form-control ${props.errorFields["time"]}`}
                        name="time"
                        id={"time"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.time || ''}
                    />
                </div>
                <div className="form-group col-md-3">
                    <label ><Translate text={'Type'} /></label>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={'type'}
                        className={`form-control ${props.errorFields["type"]}`}
                        name="type"
                        id={"type"}
                        onChange={(e) => {
                            props.handleTextChange(e, 'enqupdate')
                        }}
                        value={props?.dealer?.clientSettings?.enqupdate?.type || ''}
                    />
                </div>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col" className="head-light" width="22.5%"> <Translate text={'Days'} /></th>
                        <th scope="col" className="head-light" width="22.5%"> <Translate text={'Pipeline'} /></th>
                        <th scope="col" className="head-light" width="22.5%"> <Translate text={'To Pipeline'} /></th>
                        <th scope="col" className="head-light" width="22.5%"> <Translate text={'To Stage'} /></th>
                        <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><div className="">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder={'days'}
                                className={`form-control ${errorFields["sublistdays"]}`}
                                name="days"
                                id={"days"}
                                onChange={handleTextChange}
                                value={addSubList.days || ''}
                            />
                        </div></td>
                        <td><div className="">
                            <ReactSelect
                                options={pipelineOptions}
                                name={"pipeline"}
                                placeholder={'select pipeline'}
                                onChange={handleonSubSelectChange}
                                value={addSubList.pipeline ? addSubList.pipeline : ''}
                                classNamePrefix={`${errorFields["sublistpipeline"]} cursor-pointer basic-select`}
                                id="sublistpipeline"
                            >
                            </ReactSelect>
                        </div></td>
                        <td><div className="">
                            <ReactSelect
                                options={pipelineOptions}
                                name={"pipelineTo"}
                                placeholder={'select pipeline'}
                                onChange={handleonSubSelectChange}
                                value={addSubList.pipelineTo ? addSubList.pipelineTo : ''}
                                classNamePrefix={`${errorFields["sublistpipelineTo"]} cursor-pointer basic-select`}
                            >
                            </ReactSelect>
                        </div></td>
                        <td><div className="">
                            <ReactSelect
                                options={generateStageOptions(addSubList.pipelineTo ? addSubList.pipelineTo : '')}
                                name={"stageTo"}
                                placeholder={'select stage'}
                                onChange={handleonSubSelectChange}
                                value={addSubList.stageTo}
                                classNamePrefix={`${errorFields["subliststageTo"]} cursor-pointer basic-select`}
                            >
                            </ReactSelect>
                        </div></td>
                        <td className="border-right-0">
                            <div className="button-height-fix">
                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                            </div>
                        </td>
                    </tr>
                    {
                        prodDays.length > 0 ?
                            prodDays.map((rec, index) => {
                                return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                    <td>
                                        {
                                            textEditor[rec.id] ? (<>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={'days'}
                                                    className={`form-control ${errorFields["editsublistdays"]}`}
                                                    name="days"
                                                    id={"days"}
                                                    onChange={handleEditTextChange}
                                                    value={editSubList.days || ''}
                                                />
                                            </>) : (<>{rec.days}</>)
                                        }
                                    </td>
                                    <td>
                                        {
                                            textEditor[rec.id] ? (<> <ReactSelect
                                                options={pipelineOptions}
                                                name={"pipeline"}
                                                placeholder={'select pipeline'}
                                                onChange={handleonEditSubSelectChange}
                                                value={editSubList.pipeline ? editSubList.pipeline : ''}
                                                classNamePrefix={`${errorFields["editsublistpipeline"]} cursor-pointer basic-select`}
                                                id="editsublistpipeline"
                                            >
                                            </ReactSelect></>) : (<>{pipelineOptions?.filter(item => item.value === rec.pipeline)?.length ? pipelineOptions.filter(item => item.value === rec.pipeline)[0].name : ''}</>)
                                        }
                                    </td>
                                    <td>
                                        {
                                            textEditor[rec.id] ? (<> <ReactSelect
                                                options={pipelineOptions}
                                                name={"pipelineTo"}
                                                placeholder={'select pipeline'}
                                                onChange={handleonEditSubSelectChange}
                                                value={editSubList.pipeline ? editSubList.pipelineTo : ''}
                                                classNamePrefix={`${errorFields["editsublistpipelineTo"]} cursor-pointer basic-select`}
                                                id="editsublistpipelineTo"
                                            >
                                            </ReactSelect></>) : (<>{pipelineOptions?.filter(item => item.value === rec.pipelineTo)?.length ? pipelineOptions.filter(item => item.value === rec.pipelineTo)[0].name : ''}</>)
                                        }
                                    </td>
                                    <td>
                                        {
                                            textEditor[rec.id] ? (<> <ReactSelect
                                                options={generateStageOptions(editSubList.pipelineTo ? editSubList.pipelineTo : '')}
                                                name={"stageTo"}
                                                placeholder={'select stage'}
                                                onChange={handleonEditSubSelectChange}
                                                value={editSubList.stageTo}
                                                classNamePrefix={`${errorFields["editsubliststageTo"]} cursor-pointer basic-select`}
                                                id="editsubliststageTo"
                                            >
                                            </ReactSelect></>) : (<>{pipelineOptions?.filter(item => item.value === rec.pipelineTo)?.[0]?.stages?.filter(stage => stage.value === rec.stageTo)?.length ? pipelineOptions.filter(item => item.value === rec.pipelineTo)[0].stages.filter(stage => stage.value === rec.stageTo)[0].name : ''}</>)
                                        }
                                    </td>
                                    <td className="border-right-0">
                                        <div className="action-fix">
                                            {
                                                textEditor[rec.id] ?
                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                    </>) :
                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                    </>)
                                            }

                                        </div>
                                    </td>
                                </tr>
                            })
                            : (<></>)
                    }
                </tbody>
            </table>
        </div>
    </>);
}

export default AutoEnqUpdate;