import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import _ from 'lodash';
import { NoDataDiv, LoadingDiv, fleetProTabs } from '../constants';
import CommonHelper from '../../../../services/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { userLevel, defaultfleetCategory } from '../../../../services/enum'
import AssignFleet from '../../../fleetPro/assign'
import { PopUpModal } from '../../../../components';

const FleetProSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	history,
}) => {
	const [activeTab, setActivetab] = useState('all');
	const [fleetPro, setFleetPro] = useState([]);
	const [all, setAll] = useState([]);
	const [checkDataLoad, setDataload] = useState(false)
	const [fleetAssignModal, setfleetAssignModal] = useState({ show: false, docID: null, fleetProID: null })
	const [loader, setLoader] = useState([]);

	const fleetProStatus = dealersettings?.client?.fleetPro?.fleetProStatus;
	const accessToFleetHistory = dealersettings?.rolePermissions?.fleetProPermissions?.accessToFleetHistory;


	useEffect(() => {
		setLoader(true)
		const timeoutId = setTimeout(() => {
			if (searchText && searchText.length > 2) {
				setDataload(true)
			}

			if (searchText === '')
				setDataload(true)
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [searchText]);

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}
		if (searchText.length > 0) {
			setLoader(true);


			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)


			let refFleetPro = window.firebase.firestore()
				.collection('fleetPro')
				//.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false);

			refFleetPro = refFleetPro
				.orderBy('addedDate', 'desc')
				.limit(100)
				.get();
			if (dealersettings.level === userLevel.INDIVIDUAL) {
				let _levelIDs = CommonHelper.getAllFleetProByLevel(dealersettings, dealersettings.level, true, true);
				refFleetPro = window.firebase.firestore()
					.collection('fleetProAssign')
					.where('clientID', 'in', CommonHelper.arrPaginate(_levelIDs, 30, 1))
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.orderBy('addedDate', 'desc')
					.limit(20)
					.get();
			}

			Promise.all([
				refFleetPro,
			])

				.then((allDocs) => {

					const allFleetPro = _.map(allDocs[0].docs, function (obj) {
						return obj?.ref?.parent?.path === 'fleetPro' ? CommonHelper.convertFleetProVM({
							...obj.data(),
							documentID: obj.id,
							renderType: 'fleetPro',
							sortDate: obj.data().addedDate,
							sono: CommonHelper.getFleetProDynamicField(obj.data().dynamicFields, 'sono', dealersettings?.client?.fleetPro?.fleetOptionsDF)
						}, { dealersettings, groupUsers: [] }) : CommonHelper.convertFleetProAssignVM({
							...obj.data(),
							documentID: obj.id,
							renderType: 'fleetProAssign',
							sortDate: obj.data().addedDate,
							sono: CommonHelper.getFleetProDynamicField(obj.data().dynamicFields, 'sono', dealersettings?.client?.fleetPro?.fleetOptionsDF)
						}, { dealersettings, groupUsers: [] });

					});
					let _fleetdata = []
					let _levelIDs = CommonHelper.getAllFleetProByLevel(dealersettings, dealersettings.level, true, true);
					let _levelIDNAme = CommonHelper.levelBasedID(dealersettings.level);

					_fleetdata.push(...allFleetPro.filter(a => _levelIDs.includes(a[_levelIDNAme])))

					setFleetPro(_.uniqBy(_fleetdata, 'documentID'));

					setAll([
						..._.uniqBy(_fleetdata, 'documentID'),
					]);

					setLoader(false);
					setDataload(false)
				})
		}
		else {
			setDataload(false)
		}
	}, [checkDataLoad]);

	return (<>
		<div
			className='dropdown-menu dropdown-menu-left'
			aria-labelledby='dropdownMenuButton'
			style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
		>
			<div className='form-style globalsearch-wrap'>
				<div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{fleetProTabs.filter(a => dealersettings.level === userLevel.INDIVIDUAL ? a.value !== 'fleetPro' : a.value !== 'fleetProAssign').map((rec, index) => {
									return (
										<OverlayTrigger
											key={index}
											placement='bottom'
											overlay={
												<Tooltip><span>{rec.title}</span></Tooltip>
											}
										>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
													<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
													{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<div className='globalsearch-body'>
					{loader ? (
						<LoadingDiv />
					) : (
						<div className='global-item-wraper'>
							{activeTab === 'all' ? (
								<>
									{all.length > 0 ? (
										<>
											{all
												// _.orderBy(all, ['sortDate.seconds'], ['desc'])
												.map(obj =>
													obj.renderType === 'fleetPro' ? (
														<a
															className='global-item d-flex'
															onClick={e => {
																e.preventDefault();
																if (accessToFleetHistory) {
																	history.push(`/`);
																	history.push(`fleetpro/details/${obj.documentID}`);
																	handleClose();
																}
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-fleetpro-icon'></i>
																</div>
															</div>
															<div>
																<span className='global-header'>{obj?.make || ''} {obj?.model || ''} {obj?.year || ''}</span>
																{/* {
																	obj.regNo ? (
																		<span className='global-info'>
																			{'Reg No : '}
																			{obj.regNo}
																		</span>
																	) : (<></>)
																}
																{
																	obj.vinNo ? (
																		<span className='global-info'>
																			{'Vin No : '}
																			{obj.vinNo}
																		</span>
																	) : (<></>)
																} */}
																{
																	obj.sono ? (
																		<span className='global-info'>
																			{'SONO : '}
																			{obj.sono}
																		</span>
																	) : (<></>)
																}
																<div className='global-status'>
																	{
																		_.find(defaultfleetCategory, { value: obj?.category }) ? (
																			<div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetCategory, { value: obj?.category }).name}</div>
																		) : (<></>)
																	}
																	{
																		_.find(fleetProStatus, { value: obj.statusValue }) ? (
																			<div className={`badge badge-pill badge-status-${_.find(fleetProStatus, { value: obj.statusValue }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(fleetProStatus, { value: obj.statusValue })?.color ? _.find(fleetProStatus, { value: obj.statusValue }).color : '#6b717f') }}>{_.find(fleetProStatus, { value: obj.statusValue }).name} </div>
																		) : (
																			<div className={`badge badge-pill badge-status-${obj.statusValue.toLowerCase()}`} >{obj.statusValue} </div>
																		)
																	}
																</div>
															</div>
														</a>
													) : obj.renderType === 'fleetProAssign' ? (
														<a
															className='global-item d-flex'
															onClick={e => {
																e.preventDefault();
																setfleetAssignModal({ show: true, docID: obj.documentID, fleetProID: null, fleetPro: null })
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-fleetpro-icon'></i>
																</div>
															</div>
															<div>
																<span className='global-header'>{obj?.vehicleModel || ''}</span>
																{
																	obj.dealerName ? (
																		<div className="global-info"><i className="ico icon-group mb-1"></i> <span className="blue-color">{obj.dealerName}</span> </div>
																	) : (<></>)
																}
																{
																	obj.eventName ? (
																		<span className='global-info'>
																			{'Event : '}
																			{obj.eventName}
																		</span>
																	) : (<></>)
																}
																{/* {
																	obj.regNo ? (
																		<span className='global-info'>
																			{'Reg No : '}
																			{obj.regNo}
																		</span>
																	) : (<></>)
																}
																{
																	obj.vinNo ? (
																		<span className='global-info'>
																			{'Vin No : '}
																			{obj.vinNo}
																		</span>
																	) : (<></>)
																} */}
																{
																	obj.sono ? (
																		<span className='global-info'>
																			{'SONO : '}
																			{obj.sono}
																		</span>
																	) : (<></>)
																}
																<div className='global-status'>
																	{obj.status}
																	{
																		_.find(defaultfleetCategory, { value: obj?.fleetPro?.category }) ? (
																			<div className="badge badge-pill badge-input-white ml-1">{_.find(defaultfleetCategory, { value: obj?.fleetPro?.category }).name}</div>
																		) : (<></>)
																	}
																</div>
															</div>
														</a>
													) : (
														<></>
													),
												)}
										</>
									) : (
										<NoDataDiv />
									)}
								</>

							) : activeTab === 'fleetPro' ? (
								<>
									{fleetPro.length > 0 ? (
										<>
											{fleetPro.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														if (accessToFleetHistory) {
															history.push(`/`);
															history.push(`fleetpro/details/${obj.documentID}`);
															handleClose();
														}

													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-fleetpro-icon'></i>
														</div>
													</div>
													<div>
														<span className='global-header'>{obj?.make || ''} {obj?.model || ''}</span>
														{/* {
															obj.regNo ? (
																<span className='global-info'>
																	{'Reg No : '}
																	{obj.regNo}
																</span>
															) : (<></>)
														}
														{
															obj.vinNo ? (
																<span className='global-info'>
																	{'Vin No : '}
																	{obj.vinNo}
																</span>
															) : (<></>)
														} */}
														{
															obj.sono ? (
																<span className='global-info'>
																	{'SONO : '}
																	{obj.sono}
																</span>
															) : (<></>)
														}
														<div className='global-status'>
															{
																_.find(defaultfleetCategory, { value: obj?.category }) ? (
																	<div className="badge badge-pill badge-input-white mr-1">{_.find(defaultfleetCategory, { value: obj?.category }).name}</div>
																) : (<></>)
															}
															{
																_.find(fleetProStatus, { value: obj.statusValue }) ? (
																	<div className={`badge badge-pill badge-status-${_.find(fleetProStatus, { value: obj.statusValue }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(fleetProStatus, { value: obj.statusValue })?.color ? _.find(fleetProStatus, { value: obj.statusValue }).color : '#6b717f') }}>{_.find(fleetProStatus, { value: obj.statusValue }).name} </div>
																) : (
																	<div className={`badge badge-pill badge-status-${obj.statusValue.toLowerCase()}`} >{obj.statusValue} </div>
																)
															}
														</div>
													</div>
												</a>
											))}
										</>

									) : (
										<NoDataDiv />
									)}
								</>

							) : activeTab === 'fleetProAssign' ? (
								<>
									{fleetPro.length > 0 ? (
										<>
											{fleetPro.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														setfleetAssignModal({ show: true, docID: obj.documentID, fleetProID: null, fleetPro: null })
														handleClose();

													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-fleetpro-icon'></i>
														</div>
													</div>
													<div>
														<span className='global-header'>{obj?.vehicleModel || ''}</span>
														{
															obj.dealerName ? (
																<div className="global-info"><i className="ico icon-group mb-1"></i> <span className="blue-color">{obj.dealerName}</span> </div>
															) : (<></>)
														}
														{
															obj.eventName ? (
																<span className='global-info'>
																	{'Event : '}
																	{obj.eventName}
																</span>
															) : (<></>)
														}
														{/* {
															obj.regNo ? (
																<span className='global-info'>
																	{'Reg No : '}
																	{obj.regNo}
																</span>
															) : (<></>)
														}
														{
															obj.vinNo ? (
																<span className='global-info'>
																	{'Vin No : '}
																	{obj.vinNo}
																</span>
															) : (<></>)
														} */}
														{
															obj.sono ? (
																<span className='global-info'>
																	{'SONO : '}
																	{obj.sono}
																</span>
															) : (<></>)
														}
														<div className='global-status'>
															{obj.status}
															{
																_.find(defaultfleetCategory, { value: obj?.fleetPro?.category }) ? (
																	<div className="badge badge-pill badge-input-white ml-1">{_.find(defaultfleetCategory, { value: obj?.fleetPro?.category }).name}</div>
																) : (<></>)
															}
														</div>
													</div>
												</a>
											))}
										</>

									) : (
										<NoDataDiv />
									)}
								</>

							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>
		</div >
		<PopUpModal show={fleetAssignModal.show}>
			<AssignFleet
				dealersettings={dealersettings}
				docID={fleetAssignModal.docID}
				fleetProID={fleetAssignModal.fleetProID}
				show={fleetAssignModal.show}
				clsActive='overlay-modal active'
				handleClose={() => {
					setfleetAssignModal({ show: false })
				}}
				title={fleetAssignModal.docID ? 'fleet' : 'assignFleet'}
			>
			</AssignFleet>
		</PopUpModal>
	</>);
};

export default FleetProSearch;
