import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { ContentOverlay, TableView } from '../../../components';

const CampaignDetails = ({
	setActiveTab,
	campaign,
	recipients,
	windowSize,
	allrecipientFields,
	handleActionClick,
	handleRowSingleClick,
	activeTab,
	bodyHTML,
	message,
	contacts,
	contactsLoader,
	setpageNum,
	pageNum,
	setDataload
}) => {
	const [activeSubTab, setActiveSubTab] = useState('campaignpreview');

	return (
		<>
			<div className='campaign-view-wrapper-head'>
				<div className='float-left'>
					<div className='campaign-add-title'>{campaign ? campaign.title : ''} </div>
					<div className='campaign-add-sub-title'>
						<div className={`badge badge-pill float-left badge-${campaign.status === 'sent'
							? 'available'
							: (campaign.status === 'pending' || campaign.status === 'sending')
								? 'pending'
								: (campaign.status === 'failed' || campaign.status === 'cancelled')
									? 'draft'
									: 'draft'
							}-fill `}
						>
							{campaign.status ? campaign.status : ''}
						</div>
						<span className='float-left campaign-add-date'>
							{' '}
							Delivered on{' '}
							{moment(campaign.CreationDate).format('DD/MM/YYYY hh:mm A')}{' '}
						</span>
					</div>
				</div>
				<div className='float-right campaign-tab-align'>
					<div className='campaign-tab-switch center-fix'>
						<ul className='campaign-tabs-list'>
							<li
								className='campaign-tab-item campaign-tab-active'
								onClick={() => setActiveTab('detail')}
							>
								Campaign Details
							</li>
							<li
								className='campaign-tab-item '
								onClick={() => setActiveTab('report')}
							>
								Report
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='campaign-view-wrapper' style={{ minHeight: '75vh' }}>
				<div className='tabs'>
					<div className='tabs-wraper'>
						<nav>
							<Tabs
								transition={false}
								className='nav-fill'
								defaultActiveKey={activeSubTab}
								onSelect={k => setActiveSubTab(k)}
							>
								<Tab eventKey='campaignpreview' title='Campaign Preview'>
									<div>
										<div className='text-center'>
											<div
												dangerouslySetInnerHTML={{
													__html: bodyHTML,
												}}
											></div>
										</div>
									</div>
								</Tab>
								<Tab eventKey='details' title='Details'>
									<div className='common-table'>
										<table className='table table-bordered'>
											<tbody>
												<tr>
													<th className='head-light'>
														Delivery date &amp; time
													</th>
													<td>
														{campaign.CreationDate ? moment(campaign.CreationDate).format('DD/MM/YYYY hh:mm A') : ''}
													</td>
												</tr>

												<tr>
													<th className='head-light'>From name</th>
													<td>{campaign.senderName}</td>
												</tr>
												<tr>
													<th className='head-light'>From email</th>
													<td>{campaign.senderEmail}</td>
												</tr>
												<tr>
													<th className='head-light'>Subject line</th>
													<td>{campaign.subject}</td>
												</tr>
												<tr>
													<th className='head-light'>Recipients</th>
													<td>
														{/* {campaign.recipients.list_name} -{' '} */}
														{campaign.send_contact_count}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Tab>
								<Tab eventKey='recipients' title='Recipients'>
									{contactsLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
										<div className='common-table'>
											<TableView
												isReorderable={false}
												datalist={contacts}
												height={windowSize.windowHeight}
												width={windowSize.windowWidth}
												columns={allrecipientFields}
												dynamicFields={allrecipientFields
													.filter(e => e.default === true)
													.map(v => _.pick(v, ['value']).value)}
												settingsLoader={false}
												handleActionClick={handleActionClick}
												handleRowSingleClick={handleRowSingleClick}
												isSettings={false}
												hasMore={[{ a: 1 }]}
												handlePagination={() => {
													setpageNum(pageNum + 1)
													setDataload(true)
												}}
												isPaging={true}
											/>
										</div>
									)}
								</Tab>
							</Tabs>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignDetails;
