import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { Modal } from 'react-bootstrap'
import { reportVM, reportOptions, salesConversions, calculateOptions, mandatoryFields } from './viewModel'
import { InputText, ReactMultiSelect, ReactSelect } from '../../components'
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const AddReport = (props) => {
    const [report, setReport] = useState(props.report);
    const [errorFields, setErrorFields] = useState({});
    const [pipelineOptions, setPipelineOptions] = useState([])
    const [loader, setLoader] = useState(false)
    const [userOptions, setUseroptions] = useState([]);
    const newReport = !_.isEmpty(props.report) ? false : true


    useEffect(() => {
        if (_.isEmpty(props.report)) {
            const newReportVM = Object.assign({}, reportVM);
            newReportVM.documentID = window.firebase.firestore().collection(`reports`).doc().id;
            newReportVM.clientID = props.dealersettings.client.id;
            newReportVM.addedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.addedBy = localStorage.uid;
            newReportVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newReportVM.modifiedBy = localStorage.uid;
            newReportVM.owner = localStorage.uid;
            newReportVM.isDeleted = false;
            setReport(newReportVM)
        }
    }, [])
    useEffect(() => {
        if (_.isEmpty(props.dealersettings))
            return
        let _pipelines = [{ value: 'all', label: 'All' }];
        props.dealersettings.allPipelines.filter(a => a.active === true && a.value !== 'LeadsBucket').forEach(rec => {
            _pipelines.push({
                label: rec.name,
                value: rec.value,
            })
        })
        setPipelineOptions(_pipelines)
    }, [props.dealersettings.allPipelines])

    useEffect(() => {
        let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.rolePermissions) &&
            !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
            props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
        let options = [];
        if (!ownerDashboard) {
            options.push({ value: 'all', label: 'All' })
        }
        props.clientUsers.filter(a => ownerDashboard ? a.id === localStorage.uid : true)
            .map(rec => {
                return options.push({
                    value: rec.id,
                    searchlabel: rec.name,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    label: (
                        <div className='drop-image'>
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    ),
                });
            });
        setUseroptions(options);
    }, [props.clientUsers]);

    const handleReactMultiSelectChange = (selectedOptions) => {
        let value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        if (value.length > 1 && value.some(item => item === 'all')) {
            value = value.filter(item => item !== 'all')
        }


        setReport({
            ...report,
            ['userIDs']: value
        });

        setErrorFields({
            ...errorFields,
            ['userIDs']: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'type') {
            setReport({
                ...report,
                [data.name]: e ? e.value : '',
                ['subType']: ''
            })
        }
        else {
            setReport({
                ...report,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleMultiSelectChange = (selectedOptions) => {
        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setReport({
            ...report,
            ['subType']: value.join(',')
        });
        setErrorFields({
            ...errorFields,
            ['subType']: ''
        });
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setReport({
                ...report,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setReport({
                ...report,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const saveReport = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(report)) {
            if (mandatoryFields.indexOf(key) >= 0) {
                if (_.isEmpty(report[key].toString())) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        setLoader(true)
        window.firebase.firestore().doc(`reports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newReport === true ? 'Report added successfully' : 'Report updated successfully'), {
                    duration: 2000
                })
                props.handleClose(report, newReport);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });

    }


    return _.isEmpty(report) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-user"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Name</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'name'}
                                                        className={`form-control ${errorFields["name"]}`}
                                                        name="name"
                                                        onChange={handleOnChange}
                                                        value={report.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Users</label>
                                                    <ReactMultiSelect
                                                        options={userOptions}
                                                        name={"userIDs"}
                                                        placeholder={'select user'}
                                                        onChange={handleReactMultiSelectChange}
                                                        value={report.userIDs}
                                                        classNamePrefix={`${errorFields["userIDs"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Pipeline</label>
                                                    <ReactSelect
                                                        options={pipelineOptions}
                                                        name={"pipeline"}
                                                        placeholder={'select pipeline'}
                                                        onChange={handleSelectChange}
                                                        value={report.pipeline}
                                                        classNamePrefix={`basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Options</label>
                                                    <ReactSelect
                                                        options={reportOptions}
                                                        name={"type"}
                                                        placeholder={'select report option'}
                                                        onChange={handleSelectChange}
                                                        value={report.type}
                                                        classNamePrefix={`${errorFields["type"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Sub Type</label>
                                                    <ReactMultiSelect
                                                        options={(!_.isEmpty(report.type) && !_.isEmpty(props.dealersettings.client.settings[report.type]) ?
                                                            _.map(report.type === 'enquiryOptionsDF' ? props.dealersettings.client.settings[report.type].filter(r => r.type === 'toggle') : props.dealersettings.client.settings[report.type], function (c) {
                                                                return {
                                                                    value: c.value,
                                                                    label: c.name,
                                                                    active: _.isBoolean(c.active) ? c.active : true,
                                                                };
                                                            }) : report.type === 'salesConversion' ? salesConversions : reportOptions.filter(item => item.value === report.type))}
                                                        name={"subType"}
                                                        placeholder={'select sub type'}
                                                        onChange={handleMultiSelectChange}
                                                        value={!_.isEmpty(report.subType) ? report.subType.split(',') : []}
                                                        classNamePrefix={`${errorFields["subType"]} basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Calculation Type</label>
                                                    <ReactSelect
                                                        options={report.type === 'enquiryOptionsDF' ? calculateOptions : calculateOptions.filter(e => e.value === 'percentage')}
                                                        name={"calculationType"}
                                                        placeholder={'select calculation type'}
                                                        onChange={handleSelectChange}
                                                        value={report.calculationType}
                                                        classNamePrefix={`${errorFields["calculationType"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveReport(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                        </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddReport;