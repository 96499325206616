import React, { Component } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	Polyline,
	InfoWindow
} from 'react-google-maps';

const mapOptions = {
	zoomControl: true,
	backgroundColor: '#dbdbdb',
	disableDefaultUI: true,
	styles: [
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#444444' }],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [{ color: '#f2f2f2' }],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'poi',
			elementType: 'labels.text',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'road',
			elementType: 'all',
			stylers: [{ saturation: -100 }, { lightness: 45 }],
		},
		{
			featureType: 'road.highway',
			elementType: 'all',
			stylers: [{ visibility: 'simplified' }],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.icon',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'transit',
			elementType: 'all',
			stylers: [{ visibility: 'off' }],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [{ color: '#dbdbdb' }, { visibility: 'on' }],
		},
	],
};

const RouteMap = compose(
	withProps({
		googleMapURL:
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyCSfc6kELIIrY-EJ-3pxXzMh2dojMNiyIc&libraries=places',
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	lifecycle({
		componentWillMount() {
			const refs = {};
			const arrCoords = [];
			let startLatLng = '';
			let endLatLng = '';
			console.log('rendering again')
			this.setState({
				bounds: null,
				polyLines: arrCoords,
				center: startLatLng,
				markers: [startLatLng, endLatLng],
				onMapMounted: ref => {
					refs.map = ref;
				},
			});
		},
	}),
	withScriptjs,
	withGoogleMap,
)(props => (
	<GoogleMap
		ref={map => {
			const bounds = new window.google.maps.LatLngBounds();
			props.clientCoordinatesArray.map(x => {
				return bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
			});
			map && map.fitBounds(bounds);
			props.onMapMounted(map);
		}}
		defaultZoom={15}
		center={props.center}
		defaultOptions={{
			mapTypeControl: false,
			fullscreenControl: false,
			streetViewControl: false,
			mapTypeId: 'roadmap',
		}}
		options={mapOptions}
	>
		<Polyline
			path={props.polyLines}
			options={mapOptions}
			defaultOptions={{
				mapTypeControl: false,
				fullscreenControl: false,
				streetViewControl: false,
				mapTypeId: 'roadmap',
			}}
		/>

		{props.clientCoordinatesArray ? (
			props.clientCoordinatesArray.map((client, index) => (
				<CustomMarker
					key={index}
					index={index}
					client={client}
					onMarkerClick={props.onMarkerClick}
				/>
			))
		) : (
			<></>
		)}
	</GoogleMap>
));

export default RouteMap;

class CustomMarker extends Component {
	state = {
			showInfoWindow: false
	};
	handleMouseOver = e => {
			this.setState({
					showInfoWindow: true
			});
	};
	handleMouseExit = e => {
			this.setState({
					showInfoWindow: false
			});
	};
	render() {
			const { showInfoWindow } = this.state;
			const { index, client, onMarkerClick } = this.props;
			return client.markerURL ? (
					<Marker
						key={index}
						icon={{
							url: client.markerURL ? client.markerURL : '',
							scaledSize: new window.google.maps.Size(30, 40)
						}}
						position={{ lat: client.lat, lng: client.lng }}
						onClick={() => onMarkerClick(client.documentID)}
						onMouseOver={this.handleMouseOver} 
						onMouseOut={this.handleMouseExit}
					>
						{showInfoWindow && (
									<InfoWindow>
											<span style={{ fontFamily: "'Source Sans Pro',Arial,Helvetica,sans-serif", fontWeight: '600' }}>{client.name}</span>
									</InfoWindow>
						)}
					</Marker>
			) : (
					<Marker
						key={index}
						position={{ lat: client.lat, lng: client.lng }}
						onClick={() => onMarkerClick(client.documentID)}
						onMouseOver={this.handleMouseOver} 
						onMouseOut={this.handleMouseExit}
					>
						{showInfoWindow && (
									<InfoWindow>
											<span style={{ fontFamily: "'Source Sans Pro',Arial,Helvetica,sans-serif", fontWeight: '600' }}>{client.name}</span>
									</InfoWindow>
						)}
					</Marker>
			)
	}
}

