/** LIBRARIES */
import React from 'react'
import moment from 'moment';
import { Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { enquiryStatus } from '../../services/enum'
import enquiryHelper from '../../services/enquiryHelper';

const EnquiryCell = React.memo(({ index, task, sidepanelOpen, clientUsers, groupUsers, origins, financeStatuses, handleEnquiryFav, handleShowInboundLead, dealersettings }) => {

    function getStyle(style, snapshot) {
        if (!snapshot.isDragging) {
            return !_.isEmpty(task.enqTileColor) ? {
                background: task.enqTileColor,
                border: `1px solid ${task.enqTileColor}`
            } : {};
        }
        if (!snapshot.isDropAnimating) {
            if (!_.isEmpty(task.enqTileColor)) {
                return {
                    ...style,
                    background: task.enqTileColor,
                    border: `1px solid ${task.enqTileColor}`
                }
            }
            else {
                return style;
            }
        }

        return !_.isEmpty(task.enqTileColor) ? {
            ...style,
            background: task.enqTileColor,
            border: `1px solid ${task.enqTileColor}`,
            transitionDuration: `0.001s`
        } : {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const _enquiryFields = !_.isEmpty(task.enqFields) ? task.enqFields : null;
    const _permissions = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.rolePermissions) &&
        !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    const canModifyEnquiry = (readOnlyEnquiryView || _enquiryFields?.contact?.gdprDeleted) ? false : (((!_.isEmpty(_enquiryFields) && _enquiryFields.owner === dealersettings.id) ||
        (!_.isEmpty(_permissions) &&
            _permissions.modifyOtherEnquiry)) ? true : false);
    const _addOns = Object.assign([], _enquiryFields && _enquiryFields.requirement && _enquiryFields.requirement.addOns);

    const liteVersion = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.clientSettings) &&
        dealersettings.client.clientSettings.liteVersion) ? true : false);

    const _financeStatus = task.financeStatus ? CommonHelper.getLabelByValue(financeStatuses, task.financeStatus, '') : '';
    const _financeStatuses = _.isObject(_financeStatus) ?
        <>{_financeStatus}</>
        :
        <>
            {
                _financeStatus
                    ?
                    <>
                        <div
                            className={`badge badge-pill badge-status-${_financeStatus.toLowerCase()} ml-2`}
                        >
                            <i className='ico icon-finance mr-1'></i>
                            <Translate
                                text={_financeStatus.toLowerCase()}
                                upperCase={true}
                            />
                        </div>
                    </>
                    :
                    <></>
            }
        </>

    let _objAMStatus = _enquiryFields?.afterMarketStatus && dealersettings?.client?.settings?.afterMarketStatus?.filter(e => e.value === _enquiryFields?.afterMarketStatus)[0]

    const _transferHistoryData = _enquiryFields && !_.isEmpty(_enquiryFields.transferHistory) ? CommonHelper.getLatestTransferHistory(_enquiryFields.transferHistory, dealersettings, true, task.stage) : null;
    const _reqCount = _enquiryFields && _enquiryFields.requirementCount > 1 ? (_enquiryFields.requirementCount - 1) : null;

    const _wonCount = _enquiryFields && _enquiryFields.soldCount > 1 ? (_enquiryFields.soldCount) : null;

    let ___status = (task.status && task.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : task.status;
    const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
    let _objCurrentStatus = ___status && enquiryAllStatus.filter(e => e.value === ___status)[0]

    const _enqTD = _enquiryFields && _enquiryFields.td;

    let enquiryOptionsDF = [];
    if (dealersettings?.client?.enquiryOptions?.length) {
        dealersettings.client.enquiryOptions.forEach(rec => {
            if (_.find(dealersettings.client.settings.enquiryOptionsDF, { value: rec }))
                enquiryOptionsDF.push(_.find(dealersettings.client.settings.enquiryOptionsDF, { value: rec }))
        });
    } else {
        enquiryOptionsDF = dealersettings.client.settings.enquiryOptionsDF;
    }
    let dynamicEnquiryDetailList = Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active && m.showinPipeline && m.showinPipeline.includes(task.pipeline))) ?
        Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active && m.showinPipeline && m.showinPipeline.includes(task.pipeline)), function (item) { return item.value; }).join(",").split(',') :
        []));

    const enquiryOptionsStatic = dealersettings.client.settings.enquiryOptionsStatic;
    let staticEnquiryDetailList = Object.assign([], (!_.isEmpty(enquiryOptionsStatic) && !_.isEmpty(enquiryOptionsStatic.filter(m => m.active && m.showinPipeline && m.showinPipeline.includes(task.pipeline))) ?
        Array.prototype.map.call(enquiryOptionsStatic.filter(m => m.active && m.showinPipeline && m.showinPipeline.includes(task.pipeline)), function (item) { return item.value; }).join(",").split(',') :
        []));

    const showTotalCost = (currencySymbol, addOns) => {
        let _addOns = Object.assign([], addOns);
        if (!_.isEmpty(_addOns)) {
            var total = 0;
            for (var i = 0; i < _addOns.length; i++) {
                total = total + (_addOns[i].price ? _addOns[i].price : 0);
            }
            return <NumberFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />;
        }
        else {
            return <></>;
        }
    }

    return (
        // <StageConsumer>
        //     {({ handleEnquiryFav, handleShowInboundLead, dealersettings }) => (
        <Draggable
            draggableId={task.id}
            index={index}
            key={task.id}
        >
            {(provided1, snapshot1) => (
                <>
                    <div
                        ref={provided1.innerRef}
                        {...provided1.draggableProps}
                        {...provided1.dragHandleProps}
                        style={getStyle(provided1.draggableProps.style, snapshot1)}
                        className={`pipeline-item ${task.enqTileColor ? 'tile-color-black' : ''} ${task.isNewEnquiry ? 'newlead' : (task.status ? task.status.toLowerCase() : '')}-cell ${!_.isEmpty(task.enqTileColor) ? '' : (!_.isEmpty(task.counts) && task.counts.overdue > 0) ? "overdue-box" : ((!_.isEmpty(task.counts) && (task.counts.today > 0 || task.counts.upcoming > 0)) || task.status === enquiryStatus.LOST || task.status === enquiryStatus.PENDINGLOST || task.status === enquiryStatus.DELIEVERD || task.status === enquiryStatus.PROSPECTLOST || task.stage === "InboundLead") ? "" : "indicator-box"} ${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}`}
                        id={`enquiry-${task.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            if (task.pipeline === 'LeadsBucket' && (task.stage === 'InboundLead' || task.stageValue === 'InboundLead')) {
                                handleShowInboundLead(task.id)
                            }
                            else {
                                sidepanelOpen(task.id)
                            }
                        }}
                    >
                        <div className="pipeline-control">

                            <div className="pipeline-favourite"><a href="#" onClick={(e) => {
                                e.stopPropagation();
                                handleEnquiryFav(task.id, !task.isFav, task.stage, task.stage, task, 'isFav')
                            }} ><i className={`ico icon-star${task.isFav ? '-active' : ''}`}></i></a></div>

                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip>
                                        <Translate text={`${!_.isEmpty(task.counts) ? task.counts.overdue > 0 ?
                                            'Overdue Activity' :
                                            (task.counts.today > 0 || task.counts.upcoming > 0) ?
                                                'Scheduled Activity' :
                                                'No Scheduled Activity' :
                                            'No Scheduled Activity'}`} />
                                    </Tooltip>
                                }
                            >
                                <div className="pipeline-action">
                                    <a href="#" className={`${!_.isEmpty(task.counts) ? task.counts.overdue > 0 ? 'indicator-outstanding' : (task.counts.today > 0 || task.counts.upcoming > 0) ? 'indicator-upcoming' : 'indicator' : 'indicator'}`}>
                                        <i className={`ico icon-${!_.isEmpty(task.counts) ? (task.counts.today > 0 || task.counts.upcoming > 0 || task.counts.overdue > 0) ? 'indicator-active' : 'indicator' : 'indicator'}`}></i></a>
                                </div>
                            </OverlayTrigger>


                        </div>

                        <div className="pipeline-item-head">
                            {/* <span className="badge badge-pill badge-lost">{index + 1}</span>  */}
                            {CommonHelper.displayContactIcon(task, 'mr-1')}
                            {
                                (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact))
                                    ?
                                    <>
                                        {CommonHelper.displayContact(null, task.enqFields.contact, '')}
                                    </>
                                    :
                                    <>
                                        {
                                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.engageNewContact))
                                                ?
                                                <>
                                                    {CommonHelper.displayContact(null, task.enqFields.engageNewContact, '')}
                                                </>
                                                :
                                                <>
                                                    {task.name}
                                                </>
                                        }
                                    </>
                            }
                        </div>
                        {
                            (!_.isEmpty(task.enqFields) && !_.isEmpty(task.enqFields.contact) && task.enqFields.contact.businessContact && !_.isEmpty(task.enqFields.contact.company))
                                ?
                                <div className="testdrive-contact-license">
                                    <i className={`ico icon-Contacts mr-1`}></i>
                                    {CommonHelper.displayContactName([], task.enqFields.contact, '')}
                                </div>
                                :
                                <></>
                        }

                        {task.displayID ? <div className="pipeline-item-phone"><i className="ico icon-enquiry"></i> {task.displayID}</div> : <></>}

                        {
                            (task.phone || task.email) ?
                                <div className="pipeline-item-phone">
                                    {task.phone ?
                                        CommonHelper.getFullPhone(task.phoneCode, task.phone)
                                        : task.email}
                                </div>
                                :
                                <>
                                    {
                                        (!_.isEmpty(task.enqFields) &&
                                            !_.isEmpty(task.enqFields.contact) &&
                                            task.enqFields.contact.businessContact &&
                                            !_.isEmpty(task.enqFields.contact.company) &&
                                            (task.enqFields.contact.company.phone || task.enqFields.contact.company.email))
                                            ?
                                            <div className="pipeline-item-phone">
                                                {task.enqFields.contact.company.phone ?
                                                    CommonHelper.getFullPhone(task.enqFields.contact.company.phoneCode, task.enqFields.contact.company.phone)
                                                    : task.enqFields.contact.company.email}
                                            </div>
                                            :
                                            <div className="no-data-red"><Translate text={'contactDetailsMissing'} /></div>
                                    }
                                </>
                        }
                        {
                            task.pipeline === 'LeadsBucket' && (task?.enqFields?.contact?.state)
                                ?
                                <div className="pipeline-item-phone">
                                    <Translate text={'state'} />:&nbsp;{CommonHelper.getNameByCode(dealersettings?.states, task.enqFields.contact.state, '')}
                                </div>
                                :
                                <></>
                        }
                        {
                            task.vehiclemodel ? (
                                <div className="pipeline-item-vehicle">{task.vehiclemodel}
                                    {
                                        task.saletype
                                            ?
                                            <>
                                                <div className="badge badge-pill badge-white ml-1">
                                                    {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                        task.saletype, '')}
                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    {
                                        task.stockNo ? (
                                            <><br /> Stock #:&nbsp;{task.stockNo}</>
                                        ) : (<></>)
                                    }
                                    {
                                        _reqCount
                                            ?
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip><Translate text={'Requirements'} /></Tooltip>
                                                }
                                            >
                                                <div className="badge badge-pill badge-blue ml-1">
                                                    +{_reqCount} <Translate text={'more'} lowerCase={true} />
                                                </div>
                                            </OverlayTrigger>

                                            :
                                            <></>
                                    }

                                    {
                                        _wonCount
                                            ?
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip><Translate text={'Won Requirements'} /></Tooltip>
                                                }
                                            >
                                                <div className="badge badge-pill badge-available ml-1">
                                                    {_wonCount}  <Translate text={'won'} upperCase={true} />
                                                </div>

                                            </OverlayTrigger>

                                            :
                                            <></>
                                    }


                                </div>
                            ) : <>
                                {
                                    liteVersion && !_.isEmpty(_enqTD) && !_.isEmpty(_enqTD.make)
                                        ?
                                        <>
                                            <div className="pipeline-item-vehicle">
                                                <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'mr-1 ico icon-activity-testdrive')}></i>
                                                {_enqTD.make + ' ' + _enqTD.model}
                                                {
                                                    _enqTD.saletype
                                                        ?
                                                        <>
                                                            <div className="badge badge-pill badge-white ml-1">
                                                                {CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
                                                                    _enqTD.saletype, '')}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                            </div>

                                        </>
                                        :
                                        <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>
                                }

                            </>
                        }
                        {
                            staticEnquiryDetailList.map((rec, index) => {
                                if ((!_.isEmpty(enquiryOptionsStatic) && !_.isEmpty(enquiryOptionsStatic.filter(m => m.active === true && m.value === rec)[0])) &&
                                    (_.isNumber(_enquiryFields[rec]) || _.isBoolean(_enquiryFields[rec]) || !_.isEmpty(_enquiryFields[rec])))
                                    return <div key={index} className="pipeline-item-phone">
                                        {(() => {
                                            let info = enquiryOptionsStatic.filter(m => m.active === true && m.value === rec)[0];
                                            return <><strong><Translate text={info.name} />:&nbsp;</strong>
                                                {enquiryHelper.showEnquiryStaticField(rec, _enquiryFields[rec], dealersettings, allUsers)}
                                            </>
                                        })()}
                                    </div>
                            })
                        }
                        {
                            dynamicEnquiryDetailList.map((rec, index) => {
                                if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0])) &&
                                    (_.isNumber(task[rec]) || _.isBoolean(task[rec]) || !_.isEmpty(task[rec])))
                                    return <div key={index} className="pipeline-item-phone">
                                        {(() => {
                                            let info = enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                            if (info.type === 'price')
                                                return <><strong><Translate text={info.name} />:&nbsp;</strong>
                                                    {task[rec] ? <NumberFormat style={{ fontSize: 12 }} value={task[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}
                                                </>
                                            else
                                                return <><strong><Translate text={info.name} />:&nbsp;</strong>{task[rec] ? task[rec] : '--'}</>

                                        })()}
                                    </div>
                            })
                        }
                        <div className="pipeline-item-source">
                            <div className="float-left" title={'Origin'}>
                                {CommonHelper.getLabelByValue(origins, task.origin, '')}
                            </div>
                            {
                                task.tags && task.tags.length > 0 ? (<>
                                    {
                                        task.tags.map((r, i) => {
                                            return r.subType ? (<div key={i} className="float-left"><div className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>
                                                <div title={r.subType} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.showLimitedChar(r.subType, 20)}</div></div>) :
                                                (<div key={i} className="badge badge-pill badge-white ml-1 text-uppercase">{CommonHelper.getNameByValue(dealersettings.integrations, r.type, r.type)}</div>)
                                        })
                                    }
                                </>) : (<></>)
                            }
                        </div>

                        <div className="pipeline-item-div w-100">
                            {
                                task.enquiryage
                                    ?
                                    <> <div className="float-left" title={`Enquiry ${task.addedDate ? `Date: ${moment.unix(task.addedDate.seconds).format('DD MMM YYYY h:mm A')}` : ' Age'}`}>
                                        <i className="ico icon-timer" ></i><span>{task.enquiryage}</span></div>
                                    </>
                                    :
                                    <> </>
                            }

                            {
                                task.displayAge && task.displayAgeTxt
                                    ?
                                    <>
                                        <div className="float-left" title={task.displayAgeTxt}>
                                            <i className="ico icon-timer ml-2" style={task.displayColor ? { color: task.displayColor } : {}}></i>
                                            <span style={task.displayColor ? { color: task.displayColor } : {}}>{task.displayAge}</span></div>
                                    </>
                                    :
                                    <> </>
                            }

                            {
                                task.label
                                    ?
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip><Translate text={'label'} /></Tooltip>
                                        }
                                    >
                                        {
                                            CommonHelper.bindEnquiryLabel(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.enquiryLabels, task.label, '', 'ml-1')
                                        }
                                        {/* <div className={`badge badge-pill badge-${task.label.toLowerCase()} ml-1`}>
                                            {task.label.toUpperCase()}</div> */}
                                    </OverlayTrigger>
                                    :
                                    <></>
                            }
                            {
                                !_.isEmpty(_financeStatuses) && (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip><Translate text={'financeStatus'} /></Tooltip>
                                    }
                                >
                                    <span>{_financeStatuses}</span>

                                </OverlayTrigger>)
                            }
                            {
                                !_.isEmpty(_objAMStatus) && (<OverlayTrigger
                                    placement='bottom'
                                    overlay={<Tooltip><Translate text={'afterMarketStatus'} /></Tooltip>}>
                                    <span className={`badge badge-pill badge-status-empty ml-2`} style={{ background: (_objAMStatus.color ? _objAMStatus.color : '#333') }}>
                                        <i className="ico icon-module-aftersales mr-1"></i> <Translate text={_objAMStatus.name} upperCase={true} />
                                    </span>
                                </OverlayTrigger>)
                            }
                        </div>
                        {
                            (task.stage === 'InboundLead' || task.stageValue === 'InboundLead') && localStorage.defaultModule === 'oem' && task.clientID && _.find(dealersettings.clients, { id: task.clientID }) ?
                                <div className="oem-tag-id w-100"><i className="ico icon-location"></i>{_.find(dealersettings.clients, { id: task.clientID }).name}
                                </div> : <></>
                        }
                        {!_.isEmpty(_transferHistoryData) ? <div className="oem-tag-id w-100"><i className="ico icon-location"></i>{_transferHistoryData.name} <span className="user-role-span ml-1">{_transferHistoryData.value}</span></div> : <></>}
                        {!_.isEmpty(_enquiryFields?.stageHistory) && (<div className="pipeline-item-lead-age pt-1">
                            <img src={_images.leadAge} height="16px" className="mr-1" /> Age
                            <div className="lead-age-inner" title='Pipeline Age'>P: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage, 'pipeline')}</span></div>
                            <div className="lead-age-inner" title='Stage Age'>S: <span>{CommonHelper.showPSAge(_enquiryFields.stageHistory, _enquiryFields.pipeline, _enquiryFields.stage)}</span></div>
                        </div>)}




                        <div className="pipeline-item-div w-100 mt-2">

                            {
                                task.owner ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{task.owner}</Tooltip>
                                    }
                                >
                                    <div className="float-right">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, task.ownerId)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<>
                                    {
                                        localStorage.defaultModule === 'oem' && _enquiryFields && !_.isEmpty(_enquiryFields.userEmail) && !_.isEmpty(allUsers)
                                            ?
                                            <>
                                                {(() => {
                                                    let _owner = allUsers.filter(m => m.email === _enquiryFields.userEmail)[0]
                                                    return <>
                                                        {
                                                            _owner
                                                                ?
                                                                <>
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        overlay={
                                                                            <Tooltip>{_owner.name}</Tooltip>
                                                                        }
                                                                    >
                                                                        <div className="float-right">
                                                                            <div className="pipeline-users pl-0">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img src={CommonHelper.getUserImagebyId(allUsers, _owner.id ? _owner.id : _owner.documentID)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                })()}
                                            </>
                                            :
                                            <></>
                                    }

                                </>)
                            }

                            {
                                task.financeBMName ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{'Finance Manager'}<br />{task.financeBMName}</Tooltip>
                                    }
                                >
                                    <div className="float-right mr-1">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, task.financeBMId)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<></>)
                            }

                            {
                                task.afterMarketBMName ? (<OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip>{'After Market Manager'}<br />{task.afterMarketBMName}</Tooltip>
                                    }
                                >
                                    <div className="float-right mr-1">
                                        <div className="pipeline-users pl-0">
                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                <img src={CommonHelper.getUserImagebyId(allUsers, task.afterMarketBMId)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                            </a>
                                        </div>
                                    </div>
                                </OverlayTrigger>) : (<></>)
                            }
                            <div className="float-left">
                                {
                                    task.status
                                        ?
                                        <div className="pipeline-button">
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip><Translate text={'status'} /></Tooltip>
                                                }
                                            >
                                                {
                                                    _objCurrentStatus
                                                        ?
                                                        <>
                                                            <span
                                                                className={`status-${task.status === 'open' ? 'open' : 'empty'}`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                                <Translate
                                                                    text={_objCurrentStatus.name}
                                                                    upperCase={true}
                                                                />

                                                            </span>
                                                        </>
                                                        :
                                                        <> </>
                                                }



                                            </OverlayTrigger>

                                        </div>
                                        :
                                        <></>
                                }

                                {(task.status && task.status === enquiryStatus.PENDINGLOST) ?
                                    <>
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip><Translate text={'Lost Pending Approval'} /></Tooltip>
                                            }
                                        >
                                            <div className="pipeline-pendinglost"> <span className="status-pendinglost-circle">PA</span> </div>
                                        </OverlayTrigger>

                                    </>
                                    :
                                    <></>}
                                {
                                    task.verifiedBy && !_.isEmpty(task.verifiedDate) ? (
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    <><span><Translate text={'verifiedBy'} />:</span> {CommonHelper.getUserNamebyId(allUsers, task.verifiedBy)}</>
                                                    <br></br>
                                                    <><span><Translate text={'verifiedDate'} />:</span> {task.verifiedDate ? moment.unix(task.verifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</>
                                                </Tooltip>
                                            }
                                        >

                                            <div className="pipeline-verified"> <span className="status-verified"> <i className="ico icon-check"> </i> </span></div>
                                        </OverlayTrigger>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    task.rottendays > 0 ?
                                        (<div className="pipeline-rotten"><div className="lead-inactive"> {task.rottendays} <Translate text={'days'} /> </div> </div>)
                                        : (<></>)
                                }

                                {
                                    (!_.isEmpty(task.enqFields) && task.enqFields.isTradeIn) ? (
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    <Translate text={'Trade-In'} />
                                                </Tooltip>
                                            }
                                        >
                                            <div className="tradein-card-icon"> <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-tradein")}></i> </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <></>
                                    )
                                }
                                {
                                    (!_.isEmpty(task.enqFields) && task.enqFields.isTestDrive) ? (
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    <Translate text={'testDrive'} />
                                                </Tooltip>
                                            }
                                        >
                                            <div className="tradein-card-icon"> <i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, "ico icon-activity-testdrive")}></i> </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <></>
                                    )
                                }
                                {
                                    !canModifyEnquiry && _enquiryFields && _enquiryFields.stage !== 'InboundLead' ? (
                                        <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    <Translate text={'readonly'} upperCase={true} />
                                                </Tooltip>
                                            }
                                        >
                                            <div className="readonly-card-icon"> <i className="ico icon-lock"></i> </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    !_.isEmpty(_addOns) ?
                                        (
                                            <OverlayTrigger
                                                placement='bottom'
                                                overlay={
                                                    <Tooltip>
                                                        <Translate text={'Requirement Cost'} />
                                                    </Tooltip>
                                                }
                                            >
                                                <div className="pipeline-button ml-1">
                                                    <span className="badge badge-pill badge-offer-price">{showTotalCost(currencySymbol, _addOns)}</span>
                                                </div>
                                            </OverlayTrigger>
                                        )
                                        : (<></>)
                                }

                            </div>
                        </div>

                    </div>
                </>
            )
            }
        </Draggable >
        //     )}
        // </StageConsumer>
    )
})

export default EnquiryCell