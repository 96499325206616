import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { ReactSelect } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { firestoreDB } from '../../services/helper';
import { attendeesMapVM, eventProMapVM, requirementMapVM } from '../eventsPro/viewModel';
import { eventAllocationStatus } from '../../services/enum';

const Allocation = (props) => {

    const [fields, setFields] = useState({ clientID: '', notes: '', owner: '' });
    const [regions, setRegions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState('clientID');
    const [loader, setLoader] = useState(false);

    const [allUsers, setAllusers] = useState([])

    useEffect(() => {
        const { dealersettings, groupUsers, clientUsers, attendee } = props;
        if (dealersettings) {

            let _dealers = [];
            let _allClients = dealersettings.clients;
            if (_allClients) {
                _dealers = Object.keys(_allClients).map(cid => {
                    return {
                        value: _allClients[cid].id ? _allClients[cid].id : _allClients[cid].documentID,
                        label: _allClients[cid].name,
                        group: _allClients[cid].group,
                        region: _allClients[cid].region,
                        projectId: _allClients[cid].projectId
                    }
                })
            }


            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name,
                        projectId: doc.projectId
                    });
                }
            });

            let _groups = [];
            if (dealersettings.groups)
                dealersettings.groups && dealersettings.groups.forEach((doc) => {
                    if (_dealers && _dealers.some(client => client.group === doc.id)) {
                        _groups.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name,
                            projectId: doc.projectId
                        });
                    }
                });

            setDealers(_dealers);
            //setGroups(_groups);
            //setRegions(_regions);

        }
    }, [])


    const handleDealerChange = (e, data) => {
        const { groupUsers, clientUsers } = props;
        let objFilds = {
            ...fields,
            'clientID': e.value,
            'projectId': e.projectId || null
        }

        let options = [];
        let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        allDealerUsers && allDealerUsers.filter(item =>
            (item.arrayClientID && item.arrayClientID.some(e => e === objFilds.clientID)) ||
            (item.clientIDs && item.clientIDs.some(e => e === objFilds.clientID))).map(rec => {
                if (!options.some(m => m.value === rec.id))
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
            })

        let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
        if (!_currentUser) {
            objFilds.owner = '';
        }

        setAllusers(options);
        setFields(objFilds);
    }

    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';

        if (!fields[activeTab]) {
            formIsValid = false;
            errors[activeTab] = errorClass;
        }

        //console.log('input_error', errors, objfields, mandatoryFields)
        setErrors(errors);

        if (formIsValid === true) {
            handleSave();
        }
        else
            return formIsValid;

    };

    const handleSave = async () => {
        try {
            const { attendee, attendees, dealersettings, isReAssign } = props;
            setLoader(true);


            const batchArray = [];
            batchArray.push(window.firebase2.firestore().batch());
            let operationCounter = 0;
            let batchIndex = 0;

            if (!_.isEmpty(props.attendees)) {
                for (let [key, value] of Object.entries(attendees)) {

                    if (isReAssign)
                        reTransferLead(batchArray[batchIndex], value);
                    else
                        transferLead(batchArray[batchIndex], value);

                    operationCounter++;
                    if (operationCounter === 299) {
                        batchArray.push(window.firebase2.firestore().batch());
                        batchIndex++;
                        operationCounter = 0;
                    }
                    //console.log('operationCounter', operationCounter)
                }
            }
            else {
                if (isReAssign)
                    reTransferLead(batchArray[batchIndex], attendee);
                else
                    transferLead(batchArray[batchIndex], attendee);
            }


            for (const batch of batchArray) {
                await batch.commit().then(() => {

                    if (batchArray[batchArray.length - 1] === batch) {
                        setLoader(false);
                        toast.notify(isReAssign ? 're-allocated successfully.' : 'allocated successfully.', {
                            duration: 2000
                        })
                        props.handleModalClose(props.returnEnq ? attendee : attendees);
                    }
                }).catch(function (error) {
                    setLoader(false);
                    console.error("Error updating document: ", error);
                });
            }

            // const batch = firestoreDB(props.dealersettings).firestore().batch();

            // if (!_.isEmpty(props.attendees)) {
            //     for (let [key, value] of Object.entries(props.attendees)) {
            //         transferLead(batch, value);
            //     }
            // }
            // else {
            //     const { attendee } = props;
            //     transferLead(batch, attendee);
            // }

            // batch.commit().then((docRef) => {              


            // }).catch((error) => {
            //     setLoader(false);
            //     console.error("Error updating document: ", error);
            // });

        } catch (error) {
            errorLog(error, 'transferLead');
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    const bindRequirementnotes = (_requirement) => {
        let notes = '';
        if (!_.isEmpty(_requirement)) {
            for (let [key, value] of Object.entries(_requirement)) {
                if (requirementMapVM.hasOwnProperty(key) && !_.isEmpty(value) && _.isString(value))
                    notes = notes.concat([key] + ': ' + value + '\n')
            }
        }

        return notes;
    }

    const transferLead = async (batch, attendee) => {
        const { dealersettings, clientUsers, eventsProID, eventSettings, eventsPro } = props;

        let objData = {}, objAttnData = {}

        if (fields.clientID) {
            objData.clientID = fields.clientID;
            objAttnData.toClientID = fields.clientID;
            objAttnData.toClientName = CommonHelper.getOtherDealerName(dealersettings, fields.clientID);
        }
        if (fields.owner) {
            let _userEmail = clientUsers && clientUsers.filter(m => m.id === fields.owner)[0] ? props.clientUsers.filter(m => m.id === fields.owner)[0].email : null;
            if (_userEmail) objData.userEmail = _userEmail
        }

        if (!_.isEmpty(eventsPro)) {
            if (!_.isEmpty(eventsPro)) {
                let eventData = Object.assign({});
                for (let [key, value] of Object.entries(eventsPro)) {
                    if (eventProMapVM.hasOwnProperty(key) && value !== undefined) eventData[key] = value;
                }
                objData.request = { ...eventData, eventProID: eventsProID };
            }
        }

        let _notes = [];
        let _reqNotes = '';
        let _currentlyOwned = CommonHelper.getAttendeOwned(eventSettings, attendee, true);
        let _previouslyOwned = CommonHelper.getAttendeOwned(eventSettings, attendee);
        let _requirements = CommonHelper.getAttendeReq(eventSettings, attendee);
        _requirements && _requirements.forEach((req) => {
            _reqNotes = _reqNotes ? `${_reqNotes} \n \n ${bindRequirementnotes(req)}` : bindRequirementnotes(req);
        });

        if (!_.isEmpty(attendee.notes)) attendee.notes.forEach(rec => { _notes.push({ ...rec, Notes: rec.notes }) });
        if (!_.isEmpty(_reqNotes)) _notes.push({ Notes: _reqNotes })


        if (!_.isEmpty(_notes)) objData['request'].Notes = _notes;
        if (!_.isEmpty(attendee.testDrives)) objData['request'].testDrives = attendee.testDrives;
        if (!_.isEmpty(attendee.requirements)) objData['request'].requirements = attendee.requirements;
        if (!_.isEmpty(_currentlyOwned)) objData['request'].currentlyOwned = _currentlyOwned;
        if (!_.isEmpty(_previouslyOwned)) objData['request'].previouslyOwned = _previouslyOwned;
        if (attendee.leadStatus) objData.leadStatus = attendee.leadStatus;

        if (fields.notes) {
            objData.notes = fields.notes;
        }

        const attendeesDF = eventSettings?.attendeesDF ? eventSettings.attendeesDF : [];
        const purchaseIntentions = _.find(attendeesDF, { mappingKey: 'purchaseIntention' });
        if (!_.isEmpty(attendee?.dynamicFields?.[purchaseIntentions?.value]))
            objData.purchaseIntention = moment(attendee.dynamicFields[purchaseIntentions.value]).format('MMM YYYY')

        objData.documentID = firestoreDB(dealersettings).firestore().collection('inboundLeads').doc().id;
        objData.eventsProID = eventsProID ? eventsProID : attendee.eventsProID;
        objData.eventsProAttendeeID = attendee.documentID;
        objData.pipeline = 'LeadsBucket';
        objData.stage = 'InboundLead';
        objData.systemType = 'eventsPro';
        objData.subType = eventsPro?.title ? eventsPro.title : '';
        objData.refID = objData.eventsProID;
        objData.isDeleted = false;
        objData.status = 0;
        objData.addedBy = localStorage.uid;
        objData.modifiedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.leadCreated = window.firebase.firestore.Timestamp.now();
        objData.leadModified = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedFrom = 'web';
        objData.projectId = fields?.projectId ? fields.projectId : null;

        objAttnData.inboundID = objData.documentID;
        objAttnData.allocationStatus = eventAllocationStatus.PENDING;
        objAttnData.allocationDate = window.firebase.firestore.Timestamp.now();
        objAttnData.modifiedBy = localStorage.uid;
        objAttnData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objAttnData.modifiedFrom = 'web';

        if (!_.isEmpty(attendee)) {
            let attendeeData = Object.assign({});

            for (let [key, value] of Object.entries(attendee)) {
                if (attendeesMapVM.hasOwnProperty(key)) {
                    if (key === 'dob' || key === 'licenseExpiry')
                        attendeeData[key] = value && moment(value).isValid() ? value : null;
                    else
                        attendeeData[key] = value;
                }
            }

            //Remove empty value from object
            for (var propName in attendeeData) {
                if (attendeeData[propName] === null || attendeeData[propName] === undefined || attendeeData[propName] === '' || attendeeData[propName] === [] || (_.isObject(attendeeData[propName]) && _.isEmpty(attendeeData[propName]))) {
                    delete attendeeData[propName];
                }
            }

            delete attendeeData['clientID'];
            delete attendeeData['groupID'];
            delete attendeeData['regionID'];
            delete attendeeData['oemID'];
            delete attendeeData['owner'];

            objData.contact = attendeeData;
        }

        if (!_.isEmpty(_requirements)) {
            let _newReq = Object.assign({}, _requirements[0]);
            Object.keys(_newReq).forEach(key => _newReq[key] === undefined ? delete _newReq[key] : {});
            objData.requirement = _newReq;
        }

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        //console.log('transferLead', objAttnData, objData);
        if (attendee.documentID)
            batch.set(window.firebase2.firestore().doc(`eventsProAttendees/${attendee.documentID}`), objAttnData, { merge: true });

        if (fields.projectId) {
            await window.firebase2.firestore().doc(`inboundLeads/${objData.documentID}`).set(objData, { merge: true })
        }
        else {
            await window.firebase.firestore().doc(`inboundLeads/${objData.documentID}`).set(objData, { merge: true })
        }
        //batch.set(firestoreDB({ client: { projectId: fields.projectId } }).firestore().doc(`inboundLeads/${objData.documentID}`), objData, { merge: true });


    }

    const reTransferLead = async (batch, attendee) => {
        const { dealersettings, clientUsers } = props;

        let objData = {}, objAttnData = {}
        if (attendee.inboundID) {

            if (fields.clientID) {
                objData.clientID = fields.clientID;
                objAttnData.toClientID = fields.clientID;
                objAttnData.toClientName = CommonHelper.getOtherDealerName(dealersettings, fields.clientID);
            }
            if (fields.owner) {
                let _userEmail = clientUsers && clientUsers.filter(m => m.id === fields.owner)[0] ? props.clientUsers.filter(m => m.id === fields.owner)[0].email : null;
                if (_userEmail) objData.userEmail = _userEmail
            }


            if (fields.notes) {
                objData.notes = fields.notes;
            }

            objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objData.modifiedFrom = 'web';
            objData.projectId = fields?.projectId || null

            objAttnData.allocationDate = window.firebase.firestore.Timestamp.now();
            objAttnData.modifiedBy = localStorage.uid;
            objAttnData.modifiedDate = window.firebase.firestore.Timestamp.now();
            objAttnData.modifiedFrom = 'web';

            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }
            //console.log('reTransferLead', objAttnData, objData);
            if (attendee.documentID)
                batch.set(window.firebase2.firestore().doc(`eventsProAttendees/${attendee.documentID}`), objAttnData, { merge: true });

            if (fields.projectId) {
                await window.firebase2.firestore().doc(`inboundLeads/${objData.documentID}`).set(objData, { merge: true })
            }
            else {
                await window.firebase.firestore().doc(`inboundLeads/${objData.documentID}`).set(objData, { merge: true })
            }

        }
    }

    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-company"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.isReAssign ? 'Re-Allocate Leads' : 'Allocate Leads'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style">

                                {
                                    activeTab === 'regionID'
                                        ?
                                        <>
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'Regions'} /></label>
                                                    <ReactSelect
                                                        options={regions}
                                                        name={"regionID"}
                                                        placeholder={'select region'}
                                                        onChange={handleReactSelectChange}
                                                        value={fields.regionID}
                                                        classNamePrefix={`${errors["regionID"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            {
                                                activeTab === 'groupID'
                                                    ?
                                                    <>
                                                        <div className="form-row ">
                                                            <div className="form-group col-md-12">
                                                                <label > <Translate text={'Groups'} /></label>
                                                                <ReactSelect
                                                                    options={groups}
                                                                    name={"groupID"}
                                                                    placeholder={'select group'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.groupID}
                                                                    classNamePrefix={`${errors["groupID"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-row ">
                                                            <div className="form-group col-md-12">
                                                                <label > <Translate text={'Dealers'} /></label>
                                                                <ReactSelect
                                                                    options={dealers}
                                                                    name={"clientID"}
                                                                    placeholder={'select dealer'}
                                                                    onChange={handleDealerChange}
                                                                    value={fields.clientID}
                                                                    classNamePrefix={`${errors["clientID"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>

                                                            {/* <div className="form-group col-md-12">
                                                                <label ><Translate text={'owner'} /></label>
                                                                <ReactSelect
                                                                    options={allUsers}
                                                                    name={"owner"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.owner ? fields.owner : ''}
                                                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div> */}
                                                        </div>

                                                    </>
                                            }
                                        </>
                                }

                                <div className="form-group">
                                    <label ><Translate text={'notes'} /></label>
                                    <textarea
                                        className={`${errors["notes"]} form-control notes`}
                                        name="notes" onChange={(e) => handleNotesChanges(e)}
                                        value={fields.notes ? fields.notes : ''}
                                        placeholder="notes"
                                        cols="80"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className={`modal-footer ${loader ? 'form-disable' : ''}`}>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ml-2 ${loader ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'done'} />
                    </button>
                    <button
                        type="button"
                        className="btn btn-default float-right"
                        onClick={(e) => handleCancel(e)}
                    ><Translate text={'cancel'} />
                    </button>


                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Allocation;