import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash'

import Translate from '../../../../constants/translate';
import { ContentOverlay, TableView } from '../../../../components';
import { reportContactFields } from '../viewModel';

const DisplayReportContacts = ({ contactsLoader, contacts, complainedContacts,title, show, handleClose, type }) => {
  const [windowSize, setWindowSize] = useState({
    windowWidth: 750,
    windowHeight: window.innerHeight - 293,
  });
  
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: 750,
        windowHeight: window.innerHeight - 293,
      });
    }
    window.addEventListener('resize', handleResize);
    document.getElementsByTagName('body')[0].classList.add('inner-bg');
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubCampaigns && window.unSubCampaigns();
    };
  }, []);

  const handleActionClick = id => {
  };
  const handleRowSingleClick = () => {
  }

  const filterByType = (recs) => {
    let filteredRecs = []
    if (type === 'opened') {
      filteredRecs = recs.filter(rec => rec.open_type !== 'not_opened')
    } else if (type === 'unopened') {
      filteredRecs = recs.filter(rec => rec.open_type === 'not_opened')
    } else if (type === 'profile') {
      filteredRecs = recs.filter(rec => rec.first_updated_profile_time !== 0)
    } else if (type === 'unsubscribed') {
      filteredRecs = recs.filter(rec => rec.unsubscribe_time !== 0)
    } else if (type === 'recipients') {
      filteredRecs = recs
    } else if (type !== 'opened') {
      filteredRecs = []
    }

    return filteredRecs
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        backdropClassName={show ? 'overlay-modal active' : ''}
        enforceFocus={false}
        dialogClassName='modal-dialog modal-dialog-centered modal-delivered-sms'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              <Translate text={title} />
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="settings-head">
              </div>
              {contactsLoader ? (<div className="mt-5" style={{width: "100%", minHeight: "40vh"}} ><ContentOverlay active={true} /></div>) : (
                <div className="common-table">
                  <TableView
                    isReorderable={false}
                    datalist={type === 'complained' ? complainedContacts : filterByType(contacts)}
                    height={windowSize.windowHeight}
                    width={windowSize.windowWidth}
                    columns={reportContactFields}
                    dynamicFields={reportContactFields
                      .filter(e => e.default === true)
                      .map(v => _.pick(v, ['value']).value)}
                    settingsLoader={false}
                    handleActionClick={handleActionClick}
                    handleRowSingleClick={handleRowSingleClick}
                    isSettings={false}
                    hasMore={[]}
                    isPaging={false}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-default float-right ml-2 '
            aria-label='Close'
            // disabled={}
            onClick={handleClose}
          >
            {' '}
            <Translate text={'Close'} />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisplayReportContacts;
