export const deviceVM = {
	documentID: null,
	name: '',
	displayName: '',
	clientID: null,
	oemID: null,
	addedBy: '',
	addedDate: null,
	modifiedBy: '',
	modifiedDate: null,
	isDeleted: false,
	visibility: null,
	templateID: null,
	url: null,
	shortURL: null,
	videoURL: null,
	embedURL: null,
	fileName: null,
	fileSize: null,
	showScheduler: null,
	slideInterval:null,
	slides:null
};

export const schedulerVM = {
	documentID: null,
	enquiryID: null,
	deviceID: null,
	templateID: null,
	clientID: null,
	oemID: null,
	addedBy: '',
	addedDate: null,
	modifiedBy: '',
	modifiedDate: null,
	isDeleted: false,
	enquiry: null,
	startDate: null,
	endDate: null
};

export const deviceListFields = [
	{
		name: 'Name',
		value: 'name',
		sortValue: 'name',
		flex: 1,
		default: true,
	},
	{
		name: 'Display Name',
		value: 'displayName',
		sortValue: 'displayName',
		flex: 1,
		default: true,
	},
	{
		name: 'Created On',
		value: 'createdOn',
		sortValue: 'createdOn',
		subText: 'createdBy',
		flex: 1,
		default: true,
	},
	// {
	// 	name: 'Updated On',
	// 	value: 'updatedOn',
	// 	sortValue: 'updatedOn',
	// 	subText: 'updatedBy',
	// 	flex: 1,
	// 	default: true,
	// },
	{
		name: 'Visibility',
		value: 'visibilityDOM',
		width: 80,
		flex: 0,
		default: true
	},
	{
		name: 'Settings',
		value: 'settings',
		subText: 'documentID',
		width: 320,
		default: true,
		type: 'settings',
		flex: 0,
		fixedRight: true,
	},
];



export const levelOptions = [
	{ value: 'oem', label: 'OEM', index: 1 },
	{ value: 'region', label: 'Region', index: 2 },
	{ value: 'group', label: 'Group', index: 3 },
	{ value: 'individual', label: 'Individual', index: 4 },
];

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]