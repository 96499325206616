/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
/** COMPONENTS */
import { AntDatePicker, ReactTimePicker } from '../../../components';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
import { objWalkIn } from '../viewModel'
import { firestoreDB } from '../../../services/helper';

export default class WalkinAdd extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.state = {
            fields: Object.assign({}, objWalkIn),
            errors: {}
        }
    }

    componentDidMount() {
        const { docID, walkins } = this.props;
        //console.log(docID, walkins)
        if (!_.isEmpty(walkins) && docID) {
            var state = Object.assign({}, walkins.filter(m => m.documentID === docID)[0]);

            if (!_.isEmpty(state.walkinDate))
                state.walkinDate = moment.unix(state.walkinDate.seconds).format('YYYY-MM-DD HH:mm')

            this.setState({ fields: state });
        }
        else {
            var _state = Object.assign({}, objWalkIn);
            _state.walkinDate = moment().format('YYYY-MM-DD HH:mm');
            this.setState({ fields: _state });
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && key === 'walkinDate')
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (!fields[key] && key === 'walkinDate') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.handleSave();
        }
        else
            return formIsValid;

    };

    handleSave = () => {
        this.isLoading = true;
        const { enquiryID, dealersettings } = this.props;

        let userID = dealersettings ? dealersettings.id : '';
        let clientID = dealersettings ? dealersettings.client.id : '';
        let currenDate = window.firebase.firestore.Timestamp.now();

        const objData = Object.assign({}, this.state.fields);
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = userID;
            objData.addedDate = currenDate;
        }
        objData.modifiedBy = userID;
        objData.modifiedDate = currenDate;
        objData.modifiedFrom = 'web';
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

        if (!objData.documentID) {
            objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;
        }

        if (!objData.clientID) {
            objData.clientID = clientID;
        }

        if (!objData.enquiryID) {
            objData.enquiryID = enquiryID;
        }

        if (objData.walkinDate)
            objData.walkinDate = window.firebase.firestore.Timestamp.fromDate(moment(objData.walkinDate)._d);

        const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData).then((docRef) => {
            this.isLoading = false;
            if (this.props.enquiry && (this.props.enquiry.bdcStatus === 'allocated' || this.props.enquiry.bdcStatus === 'noshow')) {
                firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
                    bdcStatus:'show'
                }, { merge: true })
            }
            CommonHelper.logFirstWalkin(enquiryID,objData.walkinDate, dealersettings);
            this.props.handleClose(this.state.fields);

        }).catch((error) => {
            console.error("Error updating walkin_details: ", error);
        });

    }

    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.fields);
        if (val) {
            let date = state[name];
            if (date)
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm');
            else
                state[name] = moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm');
        }
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name); });

    };

    handleTimeChange = (date, name) => {
        let state = Object.assign({}, this.state.fields);

        state[name] = moment(date).format('YYYY-MM-DD HH:mm');

        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    minStartDate = (current) => {
        const { addedDate } = this.props;
        var minDate = addedDate ? moment.unix(addedDate.seconds).startOf('day') : moment().startOf('day')
        return current && current < minDate;
    }


    render() {
        const { walkinDate } = this.state.fields;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title><Translate text={this.props.title} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="search-pop form-style">

                                    <div className="form-row ">
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'date'} /> </label>
                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-date"></i>

                                                <AntDatePicker
                                                    value={walkinDate ? moment(walkinDate).format('YYYY-MM-DD') : null}
                                                    name={'walkinDate'}
                                                    onChange={(e) => { this.handleDateChange(e, 'walkinDate') }}
                                                    format='DD/MM/YYYY'
                                                    placeholder='DD/MM/YYYY'
                                                    className={`form-control ${this.state.errors["walkinDate"]}`}
                                                    disabledDate={this.minStartDate}
                                                />

                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'time'} /> </label>
                                            <div className="inner-addon right-addon"> <i className="bg-icons ico icon-time"></i>
                                                <ReactTimePicker
                                                    value={walkinDate ? walkinDate : null}
                                                    name={'walkinDate'}
                                                    onChange={this.handleTimeChange}
                                                    timeIntervals={15}
                                                    placeholder='HH:MM'
                                                    className={`form-control ${this.state.errors["walkinDate"]}`}
                                                // minTime={moment.unix(addedDate.seconds)._d}
                                                // maxTime={moment.unix(CommonHelper.getEndDate(addedDate).seconds)._d}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        {/* <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => this.props.handleClose()}
                            ><Translate text={'cancel'} />
                            </button> */}
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}
