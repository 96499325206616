import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText } from '../../../../components'
import Translate from '../../../../constants/translate';
import { departmentVM } from './viewModel'
import uuid from 'react-uuid'
import CommonHelper from '../../../../services/common';

const AddDepartment = (props) => {
    const [department, setDepartment] = useState(props.department);
    const [errorFields, setErrorFields] = useState({});
    const newDepartment = !_.isEmpty(props.department) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.department)) {
            const newdepartmentVM = Object.assign({}, departmentVM);
            setDepartment(newdepartmentVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setDepartment({
                ...department,
                [name]: newarray1.join(' '),
                ['value']: newDepartment ? uuid() : department.value
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setDepartment({
                ...department,
                [name]: str,
                ['value']: newDepartment ? uuid() : department.value
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const saveDepartment = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(department['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (props.allDepartments.some(item => item.name === department.name && item.value !== department.value)) {
            Swal.fire(CommonHelper.showLocale(props, 'Department with same name already exists'), '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)

        window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}`).set({
            departments: newDepartment ? [..._.map(props.allDepartments, function (obj) { return { ..._.pick(obj, _.keys(departmentVM)) }; }), _.pick(department, _.keys(departmentVM))] :
                [..._.map(props.allDepartments, function (obj) {
                    return obj.value === department.value ? { ..._.pick(department, _.keys(departmentVM)) } : { ..._.pick(obj, _.keys(departmentVM)) };
                })]
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newDepartment === true ? 'Department added successfully' : 'Department updated successfully'), {
                    duration: 2000
                })
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Department Settings has been updated',
                    }, { merge: true })
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Department Settings has been updated',
                        }, { merge: true })
                }
                props.handleClose(department);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(department) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label ><Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={department.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveDepartment(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} ><Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddDepartment;