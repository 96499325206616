import React from 'react'
import Translate from "../../constants/translate"

export const pipelineCountVM = {
    monthActivity: [],
    monthDelivered: [],
    monthLost: [],
    monthOpen: [],
    monthNew: [],
    monthTestdrive: [],
    monthTradein: [],
    monthWon: [],
    monthWalkin: [],

    overdueActivity: [],
    todayActivity: [],
    todayDelivered: [],
    todayLost: [],
    todayOpen: [],
    todayTestdrive: [],
    todayTradein: [],
    todayWon: [],
    todayNew: [],
    todayWalkin: [],

    weekActivity: [],
    weekDelivered: [],
    weekLost: [],
    weekOpen: [],
    weekNew: [],
    weekTestdrive: [],
    weekTradein: [],
    weekWon: [],
    weekWalkin: [],
}

export const pipelineCountVMNEW = {
    monthActivity: 0,
    monthDelivered: 0,
    monthLost: 0,
    monthOpen: 0,
    monthNew: 0,
    monthTestdrive: 0,
    monthTradein: 0,
    monthWon: 0,
    monthWalkin: 0,

    overdueActivity: 0,
    todayActivity: 0,
    todayDelivered: 0,
    todayLost: 0,
    todayOpen: 0,
    todayTestdrive: 0,
    todayTradein: 0,
    todayWon: 0,
    todayNew: 0,
    todayWalkin: 0,

    weekActivity: 0,
    weekDelivered: 0,
    weekLost: 0,
    weekOpen: 0,
    weekNew: 0,
    weekTestdrive: 0,
    weekTradein: 0,
    weekWon: 0,
    weekWalkin: 0,
}

export const leadBucketCountVM = {
    pendingInboundleads: [],
    todayConverted: [],
    monthConverted: [],
    lostApprovalList: []
}

export const pipelinestatus = [
    {
        name: "Open",
        value: "open",
        color: "#67b7dc"
    },
    {
        name: "Won",
        value: "won",
        color: "#2bb673"
    },
    {
        name: "Lost",
        value: "lost",
        color: "#ef4136"
    },
    {
        name: "Delivered",
        value: "delivered",
        color: "#049b8c"
    }
]


export const pipelineLabels = [
    {
        name: "Hot",
        value: "hot",
        color: "#ed1c24"
    },
    {
        name: "Warm",
        value: "warm",
        color: "#f7931e"
    },
    {
        name: "Cold",
        value: "cold",
        color: "#29abe2"
    },
    {
        name: "Other/Blank",
        value: "others",
        color: "#67b7dc"
    }
]

export const initialLayoutArr = [
    { x: 0, y: 0, w: 4, h: 4, i: 'LeadsBucket', minW: 4, maxW: 12, minH: 3, maxH: 3, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 4, w: 4, h: 7, i: 'LeadsByLabels', minW: 4, maxW: 12, minH: 7, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByOrigin', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByEnquiryType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 4, h: 11, i: 'SalesFunnel', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 11, w: 4, h: 11, i: 'LeadsByCampaign', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 24, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 22, w: 4, h: 11, i: 'SalesTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 22, w: 4, h: 11, i: 'DealerTarget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 4, i: 'ActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'FleetCounts', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'LoanBookingsWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 0, w: 4, h: 3, i: 'InboundActivitiesWidget', minW: 4, maxW: 4, minH: 3, maxH: 3, isDraggable: true, isResizable: false, resizeHandles: false, isBounded: false, },
    { x: 0, y: 34, w: 4, h: 11, i: 'PerformanceReviewWidget', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'InboundActivities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'CampaignPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 34, w: 4, h: 11, i: 'OriginPerformance', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

]


export const widgetSettingsNames = [
    { name: <Translate text={'Leads Bucket'} />, value: 'LeadsBucket' },
    { name: <Translate text={'Enquiries By Labels'} />, value: 'LeadsByLabels' },
    { name: <Translate text={'activities'} />, value: 'Activities' },
    { name: <Translate text={'Inbound Activities'} />, value: 'InboundActivities' },
    { name: <Translate text={'Enquiries By Origin'} />, value: 'LeadsByOrigin' },
    { name: <Translate text={'Enquiries By Enquiry Type'} />, value: 'LeadsByEnquiryType' },
    { name: <Translate text={'Sales Funnel'} />, value: 'SalesFunnel' },
    { name: <Translate text={'Enquiries By Campaign'} />, value: 'LeadsByCampaign' },
    { name: <Translate text={'Enquiries By Status'} />, value: 'LeadsByStatus' },
    { name: <Translate text={'Sales Target'} />, value: 'SalesTarget' },
    { name: <Translate text={'dealerTarget'} />, value: 'DealerTarget' },
    { name: <Translate text={'Activities Counts'} />, value: 'ActivitiesWidget' },
    { name: <Translate text={'LoanTestDriveVehicle'} />, value: 'FleetCounts' },
    { name: <Translate text={'loanVehicleBookings'} />, value: 'LoanBookingsWidget' },
    { name: <Translate text={'Inbound Activities Counts'} />, value: 'InboundActivitiesWidget' },
    { name: <Translate text={'Daily Performance Review'} />, value: 'PerformanceReviewWidget' },
    { name: <Translate text={'Campaign Performance'} />, value: 'CampaignPerformance' },
    { name: <Translate text={'Origin Performance'} />, value: 'OriginPerformance' },

]

export const defaultWidgetSettings = [
    'LeadsBucket',
    'LeadsByLabels',
    'Activities',
    'InboundActivities',
    'LeadsByOrigin',
    'LeadsByEnquiryType',
    'SalesFunnel',
    'LeadsByCampaign',
    'LeadsByStatus',
    'SalesTarget',
    'DealerTarget',
    'ActivitiesWidget',
    'FleetCounts',
    'LoanBookingsWidget',
    'InboundActivitiesWidget',
    'PerformanceReviewWidget',
    'CampaignPerformance',
    'OriginPerformance'
]

export const liteVersionWidgetSettings = [
    'SalesFunnel',
    'Activities',
    'FleetCounts',
]

export const defaultStatsIcons = [
    { name: 'New Enquiries', icon: 'icon-new-leads', value: 'new' },
    { name: 'Assigned Enquiries', icon: 'icon-allocated-leads', value: 'allocated', color: '#34c68d' },
    { name: 'Showroom Visits', icon: 'icon-showroom-visit', value: 'walkin', color: '#f7931e' },
    { name: 'Leads Created in C@P', icon: 'icon-delivered', value: 'newCAP', cap: true },
    { name: 'In Process in C@P', icon: 'icon-open-leads', value: 'open', cap: true },
    { name: 'Test Drive Completed', icon: 'icon-oem-testdrive', value: 'testdrive' },
    { name: 'Won from C@P', icon: 'icon-won-leads', value: 'won', cap: true },
    { name: 'Delivered from C@P', icon: 'icon-delivered', value: 'delivered', cap: true },
    //{ name: 'Lost from C@P', icon: 'icon-lost-leads', value: 'lost', cap: true },
    //{ name: 'Lead Conversion', icon: 'icon-lead-conversion', value: 'leadConversion', color: '#3b589e' },
    { name: 'Outdated', icon: 'icon-overdue-activities', value: 'outdated', subList: [{ name: 'Enquiries', value: 'outdated' }, { name: 'Leads', value: 'outdatedLeads', cap: true }] }
]