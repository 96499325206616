import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import _ from 'lodash';
import { InputText, PopUpModal } from '../../../../components';
import SummerEditor from '../../../mail/summereditor/summereditor';
import SendTestEmail from '../../workflow/templates/sendTestEmail';
import htmlToText from 'html-to-text';
import ReactSummernote from 'react-summernote';
import { mentions } from './viewmodel';

const AddTemplateHTML = props => {
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState(
		props.template ? props.template.emailTemplate : '<p></p>',
	);
	const [subject, setSubject] = useState(
		props.template ? props.template.subject : '',
	);
	const [sendEmailModel, setSendEmailModel] = useState(false);

	useEffect(() => {
		if (props.template) {
			// if (!initialized) {
			// console.log('emailType', emailType)
			var node = document.createElement('div');
			node.innerHTML = `${props.template.emailTemplate}`;
			ReactSummernote.insertNode(node);
			// setInitialized(true);
			// }
		}
	}, [props.template]);

	const handleChange = obj => {
		setEmail(obj);
		setErrorFields({})
	};

	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		var str = (value || '');

		// if (str && str.length >= 1) {
		// 	var firstChar = str.charAt(0);
		// 	var remainingStr = str.slice(1);
		// 	str = firstChar.toUpperCase() + remainingStr;
		// }
		setSubject(str);

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleModelClose = template => {
		setSendEmailModel(false);
		console.log('email sent');
	};

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							{/* <button
								type='button'
								className={`btn btn-secondary float-left ml-2 `}
								onClick={() => setSendEmailModel(true)}
							>
								Send test
							</button> */}
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={() => {
									var text = email ? htmlToText.fromString(email.replace(/<img[^>]*>/g, "")) : '';
									if (_.isEmpty(text)) {
										setErrorFields({
											['email-html']: 'input_error border'
										})
										return
									}
									if (_.isEmpty(subject)) {
										setErrorFields({
											['subject']: 'input_error'
										})
										return
									}
									props.handleClose(email,subject);
								}}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'done'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<div className={`form-style w-100 ${errorFields['email-html']}`}>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<InputText
												autoComplete='off'
												placeholder={'subject'}
												className={`form-control ${errorFields['subject']}`}
												name='subject'
												onChange={handleOnChange}
												value={subject}
												maxLength={250}
											/>
										</div>

										<div className='form-group col-md-12 react-summer-editor mt-3'>
											<SummerEditor
												value={email}
												height={window.innerHeight - 150 + 'px'}
												handleChange={handleChange}
												emailType={'compose'}
												templateMentionsEnabled={true}
												formMentions={props.formMentions}
												customFonts={props?.dealersettings?.customFonts || []}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={sendEmailModel}>
				<SendTestEmail
					{...props}
					show={sendEmailModel}
					title={'Send Test Email'}
					handleClose={handleModelClose}
					template={{
						emailTemplate: email
					}}
					mentions={mentions}
				/>
			</PopUpModal>
		</>
	);
};

export default AddTemplateHTML;
