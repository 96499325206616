import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import toast from 'toasted-notes'
import { oemDataVM, oemlistFields } from './viewModel'
import { ContentOverlay, TableView, PopUpModal } from '../../../../components'
import AddOEM from './addOEM'
import { ClientContext } from '../../../layout/clientContext'



const OEMSetup = (props) => {
  const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 195) })
  const [oemData, setOEMData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loader, setLoader] = useState(true)
  const [showOEMModel, setShowOEMModel] = useState(false)
  const [popHeader, setPopHeader] = useState('')
  const [selectedOEM, setSelectedOEM] = useState('')
  const [btnLoader, setbtnLoader] = useState(false)

  const { oemSettings, oemBrands } = useContext(ClientContext);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
        windowHeight: (window.innerHeight - 195)
      })
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.unSubAdminClientList && window.unSubAdminClientList();
    }
  }, [])

  useEffect(() => {
    if (_.isEmpty(oemSettings) || _.isEmpty(oemBrands))
      return

    let _oemSettings = [];
    oemSettings.forEach((rec) => {
      _oemSettings.push({
        ...rec,
        ['strBrands']: (<div className="Vehicle-availability">
          {
            _.filter(oemBrands, (v) => _.indexOf(rec.brandIDs, v.documentID) >= 0).map((rec, index) => {
              return <div key={index}><span>{rec.name}
                {
                  rec.category !== 'Automotive' ? <> - {rec.category}</> : <></>
                }
              </span>
              </div>
            })
          }
        </div>)
      })
    })
    setOEMData(_oemSettings)
    setLoader(false)
  }, [oemSettings, oemBrands])

  const hadleEditOEM = (data) => {
    setSelectedOEM(_.pick(data, _.keys(oemDataVM)))
    setPopHeader('Edit OEM')
    setShowOEMModel(true)
  }

  const handleActionClick = (id, data) => {
    return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
      hadleEditOEM(data)
    }}>
      <i className="ico icon-edit"></i>
    </div>)
  }

  const handleOEMdetailsClick = (e, id, data) => {
    e.preventDefault();
    hadleEditOEM(data)
  }


  const handleModelClose = () => {
    setSelectedOEM(null)
    setShowOEMModel(false)
  }

  const handleAddOEM = () => {
    setSelectedOEM(null)
    setPopHeader('Add OEM')
    setShowOEMModel(true)
  }

  return (
    <>
      <h2>OEM Setup
        <div className="float-right">
          <button type="button" className="btn btn-default float-left" onClick={() => {
            setbtnLoader(true)
            window.firebase.firestore().doc(`deployments/webDeployments`)
              .set({
                updatedOn: window.firebase.firestore.Timestamp.now()
              }, { merge: true })
              .then(() => {
                setbtnLoader(false)
                toast.notify('Update successfully', {
                  duration: 2000
                })
              })
          }}>
            {
              btnLoader ?
                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                : (<></>)
            }
            Push Live Updates
          </button>
        </div>
      </h2>
      <div className="divider-line"></div>
      <div className="mt-2">
        <div className="settings-head form-style">
          <div className="form-row">
            <div className="col-3">
              <div className="filter-search search-icon">
                <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
              </div>
            </div>
            <div className="float-right">
              <div className="filter-add-button">
                <button type="button" className="btn btn-primary" onClick={() => {
                  handleAddOEM()
                }}> <i className="ico icon-add"></i> Add OEM</button>
              </div>
            </div>
          </div>
        </div>
        {
          loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
            oemData.length > 0 ? (
              <div className="common-table">
                <TableView
                  datalist={_.orderBy(oemData.filter(({ name, strBrands }) =>
                  (!_.isEmpty(searchText) ? (
                    (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false)
                    //(!_.isEmpty(strBrands) ? strBrands.toLowerCase().includes(searchText.toLowerCase()) : false)
                  ) : true)
                  ), ['name'], ['asc'])}
                  height={windowSize.windowHeight}
                  width={windowSize.windowWidth}
                  columns={oemlistFields}
                  dynamicFields={oemlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                  handleActionClick={handleActionClick}
                  handleRowSingleClick={handleOEMdetailsClick}
                  isSettings={false}
                  isReorderable={false}
                />
              </div>
            ) : (
              <div className="common-table">
                <div className="text-center p-5">
                  <p>No clients found.</p>
                  <button className="btn btn-sm btn-default" onClick={() => { handleAddOEM(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                    Add Client
                  </button>
                </div>
              </div>
            )
        }
      </div>
      <PopUpModal show={showOEMModel}>
        <AddOEM
          show={showOEMModel}
          title={popHeader}
          handleClose={handleModelClose}
          selectedOEM={selectedOEM}
        />
      </PopUpModal>
    </>
  )
}

export default OEMSetup