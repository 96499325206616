export const AdminSettingsRoutes = [
    {
        name: "Profile",
        route: "/admin/settings/profile",
        class: "ico icon-settings-profile"
    },
    {
        name: "Company",
        route: "/admin/settings/company",
        class: "ico icon-settings-company"
    },
    {
        name: "Products & Data",
        route: "/admin/settings/product",
        class: "ico icon-settings-product"
    },
    {
        name: "Modules",
        route: "/admin/settings/modules",
        class: "ico icon-settings-modules"
    },
    {
        name: "Integrations",
        route: "/admin/settings/integrations",
        class: "ico icon-settings-integrations"
    },
    {
        name: "Workflow Setup",
        route: "/admin/settings/workflow",
        class: "ico icon-settings-workflow"
    },
    // {
    //     name: "Location/Sites Management",
    //     route: "/admin/settings/sitemanagment",
    //     class: "ico icon-settings-sites"
    // },
    {
        name: "Security Management",
        route: "/admin/settings/securitymanagment",
        class: "ico icon-settings-security"
    },
    {
        name: "Billing",
        route: "/admin/settings/billing",
        class: "ico icon-settings-billing"
    }
];

export const CompanySettingsRoutes = [
    {
        name: "Clients",
        route: "/admin/settings/company/clients"
    },
    {
        name: "Users",
        route: "/admin/settings/company/users"
    },
    {
        name: "Lookups",
        route: "/admin/settings/company/lookups"
    }
];
