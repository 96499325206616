/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    InputTextArea, InputText,
    ReactSelect
} from '../../../components';
import { default as _images } from '../../../images';
import SearchContainer from '../searchContainerParts';
import { objPart, partQuotes } from '../viewModel'
import { firestoreDB } from '../../../services/helper';




// const markDownCode = [
//     { active: true, value: -5, label: '1' },
//     { active: true, value: -10, label: 'Trade' },
//     { active: true, value: 0, label: '3' },
//     { active: true, value: 15, label: '4' },
//     { active: true, value: 25, label: '10' }
// ]

// const partStatuses = [
//     { active: true, value: 'available', label: 'Available' },
//     { active: true, value: 'transit', label: 'Transit' },
//     { active: true, value: 'interstate', label: 'Interstate' },
//     { active: true, value: 'overseas', label: 'Overseas' },
//     { active: true, value: 'hornsby', label: 'Hornsby' },
//     { active: true, value: 'melbourne', label: 'Melbourne' },
//     { active: true, value: 'newcastle', label: 'Newcastle' }
// ]

let markDownCode = [];
let partStatuses = [];

export default class AddPart extends Component {
    constructor(props) {
        super(props);
        this.newDocID = null;
        this.isLoading = false;
        this.mandatoryFields = !_.isEmpty(this.props.mandatoryFields) ? this.props.mandatoryFields : ['partName', 'partCost'];
        this.state = {
            fields: Object.assign({}, objPart),
            errors: {},
            showParts: false,
            searchText: '',
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, docID, serviceID, part, dealersettings } = props;
        const fields = Object.assign({}, objPart);

        const _client = dealersettings.client;
        if (show === true && !_.isEmpty(_client) && !_.isEmpty(_client.services)) {
            const setttings = _client.services;

            markDownCode = [];
            setttings.markDownLevels && setttings.markDownLevels.forEach((doc) => {
                markDownCode.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            partStatuses = [];
            setttings.partStatus && setttings.partStatus.forEach((doc) => {
                partStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        }

        if (show === true && docID && serviceID && part) {
            this.newDocID = docID;
            const objData = Object.assign({}, part);

            for (let [key, value] of Object.entries(objData)) {
                fields[key] = value;
            }

            this.setState({ fields: fields });
        }
        else if (show === true && (!docID) && serviceID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
            fields.documentID = this.newDocID;
            fields.quantity = 1;
            this.setState({ fields: fields });
        }




    }

    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleSearchText = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            let val = this.state.searchText.trim();
            if (val.length > 2 && !this.state.showParts) {
                this.setState({ showParts: true })
            } else if (val.length < 3) {
                this.setState({ showParts: false })
            }
        });
    }

    handleSelectSave = (e, data) => {
        //this.saveEnquiryField(data.name, e.value);
        let state = Object.assign({}, this.state.fields);
        state[data.name] = e?.value;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''

        this.setState({ fields: fields }, () => { this.errorChange(name); });

    }

    handleTypeChange = (e) => {
        const { name, value } = e.target;

        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value;
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (key) {
                if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                    errors[key] = errorClass;
                else
                    errors[key] = '';
            }
            else {
                errors = {};
                for (let [key, value] of Object.entries(fields)) {
                    if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                        if (!fields[key] && key !== 'totalCost') {
                            errors[key] = errorClass;
                        }
                    }
                }
            }

            this.setState({ errors: errors });
        }
    }

    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        this.mandatoryFields && this.mandatoryFields.forEach(key => {
            if (!fields[key] && key !== 'totalCost') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        this.setState({ errors: errors });

        if (formIsValid === true) {

            if (this.props.isPackage)
                this.addPackagePart();
            else
                this.addRecommendation();
        }
        else
            return formIsValid;

    };

    addPackagePart = () => {
        this.isLoading = true;
        const { serviceID, docID, service, dealersettings } = this.props;
        const objData = Object.assign({}, this.state.fields);

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        objData.quantity = objData.quantity > 0 ? objData.quantity : 1
        let totalPartCost = parseFloat(objData.partCost * objData.quantity);

        if (objData.priceLevelCode) {
            // DISCOUNT / MARKDOWN
            let markupAmount = this.partMarkup(totalPartCost, objData.priceLevelCode);
            totalPartCost = parseFloat(markupAmount);
        }

        objData.totalCost = totalPartCost;

        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        if (service) {
            const _objData = Object.assign({});
            let recommendationParts = Object.assign([], service?.parts);

            if (!docID) {
                objData.addedFrom = 'web'
                recommendationParts.push(objData);
            }
            else {
                recommendationParts.filter(function (obj) {
                    //Update old data
                    if (obj.documentID === objData.documentID) {
                        for (let [key, value] of Object.entries(objData)) {
                            obj[key] = value;
                        }
                        obj.modifiedFrom = 'web'
                    }
                });
            }

            _objData.parts = Object.assign([], recommendationParts);
            _objData.modifiedBy = localStorage.uid;
            _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
            _objData.modifiedFrom = 'web';

            const updateRef = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}`);
            updateRef.set(_objData, { merge: true }).then((docRef) => {
                //console.log(docRef);
                this.isLoading = false;
                toast.notify('Part saved successfully', {
                    duration: 2000
                })

                if (this.props.handleStatusReScheduled)
                    this.props.handleStatusReScheduled();

                this.handleModalClose(this.newDocID);
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }



    };

    addRecommendation = () => {
        this.isLoading = true;
        const { serviceID, recommID, recommendation, docID, isOwnerResponsed, dealersettings } = this.props;
        const objData = Object.assign({}, this.state.fields);

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        objData.quantity = objData.quantity > 0 ? objData.quantity : 1
        let totalPartCost = parseFloat(objData.partCost * objData.quantity);

        if (objData.priceLevelCode) {
            // DISCOUNT / MARKDOWN
            let markupAmount = this.partMarkup(totalPartCost, objData.priceLevelCode);
            totalPartCost = parseFloat(markupAmount);
        }

        objData.totalCost = totalPartCost;

        if (!this.newDocID) {
            this.newDocID = firestoreDB(this.props.dealersettings).firestore().collection('serviceJobs').doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        if (recommID && recommendation) {
            const _recommendation = Object.assign({}, recommendation);
            let recommendationDetail = Object.assign({}, _recommendation.detail);
            let recommendationParts = Object.assign([], recommendationDetail.parts);

            if (!docID) {
                objData.addedFrom = 'web'
                recommendationParts.push(objData);
            }
            else {
                recommendationParts.filter(function (obj) {
                    //Update old data
                    if (obj.documentID === objData.documentID) {
                        for (let [key, value] of Object.entries(objData)) {
                            obj[key] = value;
                        }
                        obj.modifiedFrom = 'web'
                    }
                });
            }

            recommendationDetail.parts = Object.assign([], recommendationParts);
            _recommendation.detail = recommendationDetail;
            if (!_recommendation.projectId) _recommendation.projectId = (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);
            const updateRef = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/recommendations/${recommID}`);
            updateRef.set(_recommendation, { merge: true }).then((docRef) => {
                //console.log(docRef);
                this.isLoading = false;
                toast.notify('Part saved successfully', {
                    duration: 2000
                })

                // if (isOwnerResponsed) {
                //     let _objData = {
                //         status: serviceStatus.ADVISORREQUESTED,
                //         ownerResponse: null,
                //         modifiedBy: dealersettings ? dealersettings.id : '',
                //         modifiedDate: window.firebase.firestore.Timestamp.now(),
                //         modifiedFrom: 'web'
                //     }

                //     const updateJobRef = window.firebase.firestore().collection('serviceJobs').doc(serviceID);
                //     updateJobRef.update(_objData).then((docRef) => {
                //     }).catch((error) => {
                //         console.error("Error updating enquiries: ", error);
                //     });
                // }

                if (this.props.handleStatusReScheduled)
                    this.props.handleStatusReScheduled();

                this.handleModalClose(this.newDocID);
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }



    };

    partMarkup(totalPartCost, markupval) {
        var markupAmount = 0;

        if (markupval) {
            var discountPrice = parseFloat((totalPartCost * (markupval)) / 100);
            markupAmount = parseFloat(totalPartCost + (discountPrice));
        }
        return markupAmount;

    }

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}, objPart) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };

    handleSearchPartsClose = (objData) => {
        if (!_.isEmpty(objData)) {
            const fields = Object.assign({}, this.state.fields);
            let _data = Object.assign({}, objData);
            fields.partCost = _data.partCost > 0 ? _data.partCost : 0;
            fields.partName = _data.partName ? _data.partName : '';
            fields.partNumber = _data.partNumber ? _data.partNumber : '';
            this.setState({ fields: fields }, () => { this.errorChange(); });
        }

        this.setState({
            showParts: false,
            searchText: ''
        });
    }

    render() {

        const { partNumber, partName, partStatus, partCost, totalCost, quantity, comments, quote, priceLevelCode } = this.state.fields;
        const { searchText, showParts } = this.state
        const { show, clientUsers, dealersettings, docID, service } = this.props;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-add-service-recommendation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={this.props.title ? this.props.title : 'Add New Part'} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style service-section-recommendation-popup">

                                    <div className="recom-popup-middle-wrap">

                                        <div className="form-group position-relative">
                                            <div className="calllogs-search ">
                                                <InputText
                                                    value={searchText}
                                                    onChange={this.handleSearchText}
                                                    autoComplete="off"
                                                    placeholder={'search part'}
                                                    className={`mb-2`}
                                                />

                                                <SearchContainer
                                                    history={this.props.history}
                                                    showSearchPanel={showParts}
                                                    dealersettings={dealersettings}
                                                    service={service}
                                                    searchText={searchText ? searchText : ''}
                                                    handleClose={this.handleSearchPartsClose}
                                                    handleSearchClose={() => this.setState({ showParts: false })}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'partNumber'} /> </label>
                                                <InputText
                                                    name="partNumber"
                                                    value={partNumber}
                                                    onChange={this.handleOnChange}
                                                    autoComplete="off"
                                                    placeholder={'part number'}
                                                    className={`form-control ${this.state.errors["partNumber"]}`}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label><Translate text={'partName'} /> </label>
                                                <InputText
                                                    name="partName"
                                                    value={partName}
                                                    onChange={this.handleOnChange}
                                                    autoComplete="off"
                                                    placeholder={'part name'}
                                                    className={`form-control ${this.state.errors["partName"]}`}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label><Translate text={'quantity'} /></label>
                                                <NumberFormat
                                                    id={'quantity'}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    thousandSeparator={true}
                                                    placeholder={'quantity'}
                                                    className={`form-control ${this.state.errors["quantity"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'quantity')}
                                                    value={quantity ? quantity : null}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label><Translate text={'Part Cost'} /> {currencySymbol} <Translate text={'(per part)'} /> </label>
                                                <NumberFormat
                                                    id={'partCost'}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    allowNegative={false}
                                                    prefix={currencySymbol}
                                                    placeholder={currencySymbol}
                                                    className={`form-control ${this.state.errors["partCost"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'partCost')}
                                                    value={partCost ? partCost : null}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'priceLevelCode'} /></label>
                                                <ReactSelect
                                                    options={markDownCode}
                                                    name={"priceLevelCode"}
                                                    placeholder={'select price level code'}
                                                    onChange={this.handleSelectSave}
                                                    value={_.isNumber(priceLevelCode) ? priceLevelCode : null}
                                                    classNamePrefix={`cursor-pointer ${this.state.errors["priceLevelCode"]} basic-select`}
                                                    //removeClearable={true}
                                                    isSearchable={true}
                                                >
                                                </ReactSelect>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'quote'} /></label>
                                                <ReactSelect
                                                    options={partQuotes}
                                                    name={"quote"}
                                                    placeholder={'select part quote'}
                                                    onChange={this.handleSelectSave}
                                                    value={quote ? quote : ''}
                                                    classNamePrefix={`cursor-pointer ${this.state.errors["quote"]} basic-select`}
                                                    //removeClearable={true}
                                                    isSearchable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'partStatus'} /></label>
                                                <ReactSelect
                                                    options={partStatuses}
                                                    name={"partStatus"}
                                                    placeholder={'select part status'}
                                                    onChange={this.handleSelectSave}
                                                    value={partStatus ? partStatus : ''}
                                                    classNamePrefix={`cursor-pointer ${this.state.errors["partStatus"]} basic-select`}
                                                    //removeClearable={true}
                                                    isSearchable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label><Translate text={'comments'} /></label>
                                                <InputTextArea
                                                    className={`form-control notes ${this.state.errors["comments"]}`}
                                                    name="comments"
                                                    onChange={this.handleOnAreaChange}
                                                    value={comments}
                                                    maxLength="500"
                                                    rows="3">
                                                </InputTextArea>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
