/** LIBRARIES */
import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react'
//import 'semantic-ui-css/semantic.min.css'

class DropDownMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {

    //     if (nextProps.menu !== this.props.menu) {
    //       return false;
    //     }
    //     else
    //     {
    //         return true;
    //     }
    //  }
    render() {
        const { text, keyVal, className, menu, handleChange, tIndex, icon } = this.props;
        //console.log('RENDER - DropDownMenu', menu)
        return (

            <Dropdown text={text} key={keyVal} className={className} tabIndex={tIndex} icon={icon === false ? null : 'dropdown'} pointing='top right'>
                <Dropdown.Menu>
                    {
                        menu.map((data, idx) => (
                            <Dropdown.Item text={data.label} key={idx} onClick={() => handleChange(data.value)} />
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default DropDownMenu;