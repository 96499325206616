import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../images';
import { ContentOverlay } from '../../../components';
import Translate from '../../../constants/translate';
import Swal from 'sweetalert2';
import _ from 'lodash'
import CommonHelper from '../../../services/common';
const ContactUnsubscribeAMS = (props) => {

    const [contact, setContact] = useState({});
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const url = params.get('url') ? params.get('url') : null;
    const type = params.get('type') ? params.get('type') : '';


    const handleUnsubscribe = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to unsubscribe.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                setBtnLoader(true)
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Id: props.contactid, Name: type }),
                };
                fetch(url + '/api/CustomerApp/Action/UpdateOptinEmail', requestOptions)
                    .then((res) => {
                        return res.json()
                    }).then(res => {
                        setBtnLoader(false);
                        setContact({
                            ...contact,
                            optinEmail: false
                        })
                        Swal.fire(CommonHelper.showLocale(props, 'You have successfully unsubscribed'), '', 'success')
                    })
            }
        })

    }

    return loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
        _.isEmpty(contact) ? (
            <div className="unsubscribe-wrapper">
                <div className="unsubscribe-box">
                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribe} alt="" width="150" /> </div>
                    <div className="unsubscribe-header">
                        {/* <h2>Please confirm your Unsubscribe </h2> */}
                        {/* <h3>To confirm your Unsubscribe, please click the link below.</h3> */}
                    </div>
                    <div className="unsubscribe-button-wrap"><a className="unsubscribe-button" href="#" onClick={(e) => {
                        e.preventDefault();
                        handleUnsubscribe();

                    }}>{
                            btnLoader ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }<span><Translate text={'Yes, unsubscribe me'} /></span></a></div>
                </div>
            </div>
        ) : (
            <div className="unsubscribe-wrapper">

                <div className="unsubscribe-box">
                    <div className="unsubscribe-img">
                        <img src={_images.unsubscribed} alt="" width="150" /> </div>

                    <div className="unsubscribed">
                        <h2><Translate text={"You've been unsubscribed."} /></h2>
                    </div>

                </div>
            </div>
        )

}

export default ContactUnsubscribeAMS;