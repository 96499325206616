import React, { useState, useContext } from 'react';
import { default as _images } from '../../../images';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { PopUpModal } from '../../../components'
import _ from 'lodash'
import ComposeEmail from '../../mail/composeEmail'
import { MainContext } from '../../layout/provider';
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';
import { enqUnActionStatus } from '../../../services/enum';
const SendMail = (props) => {

    const [contactObj, setConatactObj] = useState(null);

    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
    });
    const [sendLoader, setSendLoader] = useState(false);
    const { emailTemplates } = useContext(MainContext);



    const nylasEnabled = !_.isEmpty(props.dealersettings.nylas) ? true : false;

    const handleComposeEmail = () => {
        if (_.isEmpty(props.dealersettings.nylas)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Please configure email settings'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Add Settings'),
                cancelButtonText: CommonHelper.showLocale(props, 'Later'),
            }).then(result => {
                if (result.value) {
                    props.history.push('/settings/profile?key=emailsettings');
                }
            });
        }
        else {
            setShowComposeModal({
                show: true,
                type: 'compose',
                title: 'New Message'
            })
        }

    }

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            show: false,
            type: '',
            title: ''
        })
    }

    const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
        const { mailTo, mailCc, mailBcc, subject, email } = trigger;
        setSendLoader(true);

        if (mailTo === '' && mailCc === '' && mailBcc === '') {
            toast.notify('Please specify at least one recipient.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (subject === '') {
            toast.notify('Please write subject.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (email === '' || email === '<p></p>\n') {
            toast.notify('Please write in email body.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        try {
            let emailObj = {
                token: props.dealersettings.nylasAccessToken,
                draft: {
                    subject: trigger.subject,
                    body: trigger.email,
                },
            };

            if (trigger.mailTo !== '') {
                emailObj.draft.to = trigger.mailTo.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailCc !== '') {
                emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailBcc !== '') {
                emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.files.length > 0) {
                emailObj.files = trigger.files;
            }

            setTimeout(() => {
                toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> sending...</div>),
                    {
                        position: 'bottom-left',
                        duration: null
                    },
                )
                setShowComposeModal({
                    ...showComposeModal,
                    show: false,
                    type: '',
                    title: '',
                    emailData: null,
                });
            }, 300);

            // console.log('emailObj', emailObj);
            const nylasSendEmail = window.firebase
                .functions()
                .httpsCallable('nylas-sendEmail');
            const resp = await nylasSendEmail(emailObj);

            if (resp.data.success) {
                linkEnqruiy(resp.data)
                setSendLoader(false);
            } else {
                setSendLoader(false);
                toast.closeAll()
                if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
                                nylas: null,
                            }, { merge: true });
                            props.history.push('/settings/profile?key=emailsettings');
                        }
                    });
                    return
                }
                else {
                    Swal.fire('There was an error in sending email.', '', 'error');
                }
            }

            // console.log('response', resp);
        } catch (error) {
            toast.closeAll()
            Swal.fire('There was an error in sending email.', '', 'error');

            setSendLoader(false);
            console.log(error);
        }
    };

    const linkEnqruiy = (data) => {

        var id = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id;
        const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

        const model = {
            documentID: id,
            clientID: props.dealersettings.client.documentID,
            thread: data.thread,
            token: props.dealersettings.nylasAccessToken,
            linkedBy: localStorage.uid,
            linkedDate: window.firebase.firestore.Timestamp.now(),
            type: CommonHelper.getActivityType('log', props.originType),
            projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
        };

        if (!_.isEmpty(props.enquiry)) {
            model.enquiryID = props.enquiry.documentID;
            model.enquiry = props.enquiry;
            if (!_.isEmpty(props.enquiry.contact)) {
                model.contactID = props.enquiry.contact.documentID;
                model.contact = props.enquiry.contact;
            }
        }
        else if (!_.isEmpty(props.tradeinPro)) {
            model.tradeinProID = props.tradeinPro.documentID;
            model.tradeinPro = props.tradeinPro;
            if (!_.isEmpty(props.tradeinPro.contact)) {
                model.contactID = props.tradeinPro.contact.documentID;
                model.contact = props.tradeinPro.contact;
            }
        }
        else if (!_.isEmpty(props.service)) {
            model.serviceID = props.service.documentID;
            model.service = props.service;
            if (!_.isEmpty(props.service.contact)) {
                model.contactID = props.service.contact.documentID;
                model.contact = props.service.contact;
            }
        }
        else if (!_.isEmpty(props.contact)) {
            model.contactID = props.contact.documentID;
            model.contact = props.contact;
        }

        linkref.set(model, {
            merge: true,
        });

        if (!_.isEmpty(model.enquiryID)) {
            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
                .set({
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    unactionedNotify: enqUnActionStatus.COMPLETED,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                }, { merge: true })
        }

        if (!_.isEmpty(data.data)) {
            let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(data.data.id)
            messageRef.set({
                ...data.data,
                projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
            })
        }
        toast.closeAll()
        toast.notify('Email sent successfully.', {
            duration: 2000,
        });
    }

    return (
        <>
            {
                nylasEnabled
                    ?
                    <a href='#' className="hyperlink-blue" onClick={e => { e.preventDefault(); handleComposeEmail() }}>
                        {props.email}
                    </a>
                    :
                    <a href={`mailto:${props.email}`} className="hyperlink-blue">
                        {props.email}
                    </a>
            }
            <PopUpModal show={showComposeModal.show}>
                <ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    token={props.dealersettings.nylasAccessToken}
                    type={showComposeModal.type}
                    title={showComposeModal.title}
                    emailData={showComposeModal.emailData}
                    dealersettings={props.dealersettings}
                    sendLoader={sendLoader}
                    setSendLoader={setSendLoader}
                    handleSend={handleSend}
                    structureType={!_.isEmpty(props.dealersettings.nylasAccountData) ? props.dealersettings.nylasAccountData.organization_unit + 's' : ''}
                    emailtemplates={emailTemplates}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : contactObj
                    }
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                    tradeinPro={!_.isEmpty(props.tradeinPro) ? props.tradeinPro : null}
                    userData={{ emailSignature: props.dealersettings.emailSignature }}
                ></ComposeEmail>
            </PopUpModal>
        </>
    )
}

export default SendMail