import _ from 'lodash'

const StockListReducer = (state, action) => {
    switch (action.type) {
        case "_SUCCESS": {
            return _.orderBy(action.data, [action.sortName], [action.sortOrder])
        }
        case "_APPEND": {
            return _.orderBy([...state, ..._.differenceBy(action.data, state, 'documentID')], [action.sortName], [action.sortOrder])
        }
        case "_ADD": {
            return _.orderBy([action.data, ...state, action.data], [action.sortName], [action.sortOrder])
        }
        case "_REMOVE": {
            return _.orderBy(state.filter(item => item.documentID !== action.data.documentID), [action.sortName], [action.sortOrder])
        }
        case "_UPDATE": {
            return _.orderBy([...state.filter(item => item.documentID !== action.data.documentID), action.data], [action.sortName], [action.sortOrder])
        }
        default:
            return state;
    }
};


export default StockListReducer;