import React, { useEffect, useState } from 'react';
import { default as _images } from '../../../../images';
import { InputCheckBox, InputRadio, InputTextArea, ReactSelect, InputText, ReactMultiSelect } from '../../../../components';
import _ from 'lodash';
import { Modal } from 'react-bootstrap'
import { rulesDynCols } from './viewModel';
import CustomTable from '../../../../components/customTable';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import CommonHelper from '../../../../services/common';


const PipelineRules = (props) => {
    const [state, setState] = useState(props.data)
    const [loader, setLoader] = useState(false)
    const [formloader, setFormLoader] = useState(true)
    const [dynFormValues, setDynFromValues] = useState({})
    const [dynCols, setDynCols] = useState(rulesDynCols)

    const handleSubmit = () => {
        if (!state?.rules?.length) {
            Swal.fire(CommonHelper.showLocale(props, 'Please add some data to save'), '', 'info')
            return
        }

        setLoader(true);
        window.firebase.firestore().doc(`formTemplates/${props.data.documentID}`)
            .set({
                rules: state.rules,
                modifiedBy: localStorage.uid,
                addedDate: window.firebase.firestore.Timestamp.now()
            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify(CommonHelper.showLocale(props, 'Form Rules Updated Successfully'), {
                    duration: 2000
                })
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'), '', 'error');
            });
    }

    useEffect(() => {
        if (_.isEmpty(props.formFields)) {
            return
        }
        let _values = {};
        props.formFields.forEach(rec => {
            _values = {
                ..._values,
                [rec.name]: rec.type === 'starRating' ? _.range(1, 6).map(r => { return { label: r.toString(), value: r } }) : rec.values || []
            }
        });
        _values.pipeline = props?.dealersettings?.client?.settings?.pipelines?.map(r => { return { label: r.name, value: r.value } })
        props?.dealersettings?.client?.settings?.pipelines && props.dealersettings.client.settings.pipelines.forEach(rec => {
            _values = {
                ..._values,
                [rec.value]: rec?.stages?.map(r => { return { label: r.name, value: r.value } })
            }
        })
        setDynFromValues(_values)
        setFormLoader(false)
    }, [props.formFields])


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-email-preview"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid pr-0 pl-0">
                        <main role='main'>
                            {
                                formloader ? (<></>) : (
                                    <CustomTable
                                        dealersettings={props.dealersettings}
                                        formFields={props.formFields}
                                        cols={dynCols}
                                        subList={state?.rules || []}
                                        setSubList={(list) => { setState({ state, rules: list }) }}
                                        {...dynFormValues}
                                    ></CustomTable>
                                )
                            }
                        </main >
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSubmit(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PipelineRules;
