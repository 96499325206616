import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { serviceTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { serviceStatus, tipStatus } from '../../../../services/enum'
import { firestoreDB } from '../../../../services/helper';

const ServiceSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleOpenStock,
	handleInBoundOpen,
	history,
	pipeline,
	enableSharedContact,
	getDealerName,
}) => {
	const [activeTab, setActivetab] = useState('all');

	const [serviceJobs, setServiceJobs] = useState([]);
	const [contactDetails, setContactDetails] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [stock, setStock] = useState([]);
	const [activities, setActivities] = useState([]);
	const [all, setAll] = useState([]);
	const [tradeins, setTradeins] = useState([]);

	const [loader, setLoader] = useState([]);

	const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);
	const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
	const technicianAllStatus = Object.assign([], dealersettings?.client?.services?.technicianStatus)
	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);

			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
			let companiesInContacts = []

			let refServiceJobs = firestoreDB(dealersettings).firestore().collection('serviceJobs')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)

			// if (!viewOtherEnquiry)
			// 	refServiceJobs = refServiceJobs.where('owner', '==', dealersettings.id)

			refServiceJobs = refServiceJobs
				// .orderBy('stageDate', 'desc')
				.limit(10);

			let refInboundLeads = '';
			if (pipeline.filter(item => item.value === 'LeadsBucket').length > 0) {
				refInboundLeads = firestoreDB(dealersettings)
					.firestore()
					.collection('inboundLeads')
					.where('clientID', '==', dealersettings.client.documentID)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('status', '==', 0)
					.where('isDeleted', '==', false)
					.orderBy('addedDate', 'desc')
					.limit(10);
			}

			let refContactDetails;
			if (enableSharedContact && dealersettings.client.group) {
				refContactDetails = firestoreDB(dealersettings)
					.firestore()
					.collection('contactDetails')
					.where('groupID', '==', dealersettings.client.group)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
			} else {
				refContactDetails = firestoreDB(dealersettings)
					.firestore()
					.collection('contactDetails')
					.where('clientID', '==', dealersettings.client.documentID)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
			}

			let refCompanies;
			if (enableSharedContact && dealersettings.client.group) {
				refCompanies = firestoreDB(dealersettings)
					.firestore()
					.collection('companies')
					.where('groupID', '==', dealersettings.client.group)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
			} else {
				refCompanies = firestoreDB(dealersettings)
					.firestore()
					.collection('companies')
					.where('clientID', '==', dealersettings.client.documentID)
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('isDeleted', '==', false)
					.limit(10);
			}

			let refTradeins = firestoreDB(dealersettings).firestore()
				.collection('tradeins')
				.where('clientID', '==', dealersettings.client.documentID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(15);

			var dashboardPipelines = combineLatest(
				collection(refServiceJobs),
				refInboundLeads ? collection(refInboundLeads) : of([]),
				collection(refContactDetails),
				collection(refCompanies),
				collection(refTradeins),
			)
				.pipe(
					map(([allServiceJobs, inboundLeads, contactDetails, companies, tradeIns]) => {
						return [
							_.map(allServiceJobs, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'service',
									sortDate: obj.data().stageDate,
									isInbound: false,
								};
							}),
							_.map(inboundLeads, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'service',
									sortDate: obj.data().addedDate,
									isInbound: true,
								};
							}),
							_.map(contactDetails, function (obj) {
								let contactObj = obj.data()
								if (!_.isEmpty(contactObj.company) && contactObj.company.documentID) {
									companiesInContacts.push({ ...contactObj.company, renderType: 'company', })
								}
								return {
									...contactObj,
									documentID: obj.id,
									renderType: 'contactDetail',
									sortDate: contactObj.addedDate,
								};
							}),
							_.map(companies, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'company',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(tradeIns, function (obj) {
									return {
										...obj.data(),
										documentID: obj.id,
										renderType: 'tradein',
										sortDate: obj.data().addedDate,
									};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					let allServiceJobs = [...allDocs[0]]
					let allCompanies = _.uniqBy([...allDocs[3], ...companiesInContacts], 'documentID')
					let allTradeins = [...allDocs[4].filter(e=> e.serviceID)]
					setServiceJobs(allServiceJobs);
					setContactDetails(allDocs[2]);
					setTradeins(allTradeins)
					setCompanies(allCompanies);
					setAll([
						...allServiceJobs,
						...allDocs[2],
						...allCompanies,
						...allTradeins,
					]);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div
			className='dropdown-menu dropdown-menu-left'
			aria-labelledby='dropdownMenuButton'
			style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
		>
			<div className='form-style globalsearch-wrap'>
				<div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{serviceTabs.map((rec, index) => {
									return (
										<OverlayTrigger
											placement='bottom'
											overlay={
												<Tooltip><span>{rec.title}</span></Tooltip>
											}
											key={index}
										>
											<Nav.Item
												as='li'
												bsPrefix='li-nav'
												key={index}
											>
												<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
													<i className={rec.ico}></i>
													{rec.name}
												</Nav.Link>
											</Nav.Item>
										</OverlayTrigger>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div>
				<div className='globalsearch-body'>
					{loader ? (
						<LoadingDiv />
					) : (
						<div className='global-item-wraper'>
							{activeTab === 'all' ? (
								<>
									{all.length > 0 ? (
										<>
											{all
												// _.orderBy(all, ['sortDate.seconds'], ['desc'])
												.map(obj =>
													obj.renderType === 'service' ? (
														<>
															{obj.isInbound ? (
																<>
																</>
															) : (
																<a
																	className='global-item d-flex'
																	onClick={(e) => {
																		e.preventDefault();
																		history.push(`/`);
																		history.push(`service/details/${obj.documentID}`);
																		handleClose();
																	}}
																	key={obj.documentID}
																>
																	<div className='global-space'>
																		<div className='global-icon-circle'>
																			<i className='ico icon-service-settings'></i>
																		</div>
																	</div>
																	<div>
																		{!_.isEmpty(obj.vehicle) ? (
																			<>
																				{!_.isEmpty(obj.vehicle.make) && obj.vehicle.model ? (
																					<span className='global-header'>
																						{' '}
																						{`${obj.vehicle
																							? obj.vehicle.make
																							: ''
																							} ${obj.vehicle
																								? obj.vehicle.model
																								: ''
																							}`}{' '}
																					</span>
																				) : (
																					<span className='global-header'>
																						{' '}
																						{`${obj.vehicle
																							? obj.vehicle.make ? obj.vehicle.make : ''
																							: ''
																							} ${obj.vehicle
																								? obj.vehicle.model ? obj.vehicle.model : ''
																								: ''
																							}`}{' '}
																					</span>
																				)}
																			</>
																		) : (
																			<></>
																		)}
																		<span className='global-info'>
																			{' '}
																			{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																			{obj.displayID ? ` - ${obj.displayID}` : ''}
																		</span>
																		{!_.isEmpty(obj) && obj.status && (
																			<div className='global-status'>
																				{(() => {
																					const _jobStatus = obj.status === serviceStatus.OWNERRESPONDED ? obj.ownerResponse : obj.status;
																					let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]

																					return <>
																						{
																							_objCurrentStatus
																								?
																								<>
																									<span
																										className={`status-empty mr-0`}
																										style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																									>
																										{_objCurrentStatus.name}
																									</span>
																								</>
																								:
																								<></>
																						}
																					</>
																				})()}

																				{
																					obj?.technicianStatus
																						?
																						<>
																							<OverlayTrigger
																								placement='bottom'
																								overlay={<Tooltip><Translate text={'technicianStatus'} /> <br /> {CommonHelper.getNameByValue(technicianAllStatus, obj?.technicianStatus, obj?.technicianStatus)}</Tooltip>}
																							>
																								<div className="status-empty ml-0">
																									<i className={`ico icon-timer`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, obj?.technicianStatus, 'color', '#b0babe') }}></i>
																								</div>
																							</OverlayTrigger>
																						</>
																						:
																						<></>
																				}


																			</div>
																		)}
																	</div>
																</a>
															)}
														</>
													) : obj.renderType === 'contactDetail' ? (
														<a
															className='global-item d-flex'
															onClick={e => {
																e.preventDefault();
																history.push(`/`);
																history.push(
																	`contacts/details/${obj.documentID}`,
																);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-Contacts'></i>
																</div>
															</div>
															<div>
																{(obj.firstName || obj.lastName) && (
																	<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
																)}
																{obj.phone ? (
																	<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
																) : obj.email ? (
																	<span className='global-info'>{`${obj.email}`}</span>
																) : (
																	<></>
																)}
																{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																	(<>
																		<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
															</div>
														</a>
													) : obj.renderType === 'company' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={() => {
																history.push(`/`)
																history.push(`/company/details/${obj.documentID}`)
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-company'></i>
																</div>
															</div>
															<div>
																{obj.name && (
																	<span className='global-header'>{obj.name}</span>
																)}
																{obj.address && (
																	<span className='global-info'>
																		{`${obj.address} ${obj.areaCode}`}
																	</span>
																)}
																{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
																	(<>
																		<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>}
															</div>
														</a>
													) : obj.renderType === 'tradein' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={(e) => {
																e.preventDefault();
																if (obj.serviceID) history.push(`/service/details/${obj.serviceID}?tid=${obj.documentID}`)
																handleClose();
															}}
															key={`${obj.renderType}-${obj.documentID}`}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
																</div>
															</div>
															<div>
																<span className='global-header'>
																	{`${obj.make} ${obj.model}`}
																</span>

																<span className='global-info'>
																	{' '}
																	{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																	{obj.displayID ? ` - ${obj.displayID}` : ''}
																	{obj.regNo ? ` - ${obj.regNo}` : ''}
																</span>
																{!_.isEmpty(obj) && obj.status && (
																	<div className='global-status'>
																		<>
																			{(() => {
																				const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
																				let _objCurrentStatus = obj.tradeinProID && obj.status && tradeinProAllStatus.filter(e => e.value === obj.status)[0]
																				return (
																					<>
																						{
																							_objCurrentStatus
																								?
																								<>
																									<div
																										className={`badge badge-pill badge-status-${obj.status === tipStatus.NEW ? 'open' : 'empty'} mr-2`}
																										style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																									>
																										<Translate
																											text={_objCurrentStatus.name}
																											upperCase={true}
																										/>
																									</div>
																								</>
																								:
																								<><div className={`badge badge-pill badge-status-${obj.status.toLowerCase()} mr-2`}>
																									<Translate
																										text={obj.status.toLowerCase()}
																										upperCase={true}
																									/>
																								</div></>
																						}
																					</>
																				);
																			})()}
																		</>
																	</div>
																)}
																{obj.highlightTags &&
																	obj.highlightTags.map((rec, index) => {
																		return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>;
																	})}
															</div>
														</a>
													) : obj.renderType === 'activity' ? (
														<>
															{obj.type === 'activity' ? (
																<a
																	className='global-item d-flex'
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		handleOpenEditActivity(obj);
																		handleClose();
																	}}
																	key={obj.documentID}
																>
																	<div className='global-space'>
																		<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																			<i className='ico icon-date'></i>
																		</div>
																	</div>
																	<div>
																		{obj.title && (
																			<span className='global-header'>
																				{obj.title}
																			</span>
																		)}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{!isEmpty(obj.addedDate) && (
																			<div className='global-date'>
																				{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																			</div>
																		)}
																	</div>
																</a>
															) : (
																<Link
																	className='global-item d-flex'
																	to={
																		obj.serviceID
																			? `service/details/${obj.serviceID}?comments=${obj.documentID}`
																			: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
																	}
																	key={obj.documentID}
																	onClick={handleClose}
																>
																	<div className='global-space'>
																		<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																			<i className='ico icon-date'></i>
																		</div>
																	</div>
																	<div>
																		{obj.title && (
																			<span className='global-header'>
																				{obj.title}
																			</span>
																		)}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{!isEmpty(obj.addedDate) && (
																			<div className='global-date'>
																				{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																			</div>
																		)}
																	</div>
																</Link>
															)}
														</>
													) : (
														<></>
													),
												)}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'service' ? (
								<>
									{serviceJobs.length > 0 ? (
										<>
											{serviceJobs.map(obj => (
												<>
													{obj.isInbound ? (
														<>
														</>
													) : (
														<a
															className='global-item d-flex'
															onClick={(e) => {
																e.preventDefault();
																history.push(`/`);
																history.push(`service/details/${obj.documentID}`);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-service-settings'></i>
																</div>
															</div>
															<div>
																{!_.isEmpty(obj.vehicle) ? (
																	<>
																		{!_.isEmpty(obj.vehicle.make) && obj.vehicle.model ? (
																			<span className='global-header'>
																				{' '}
																				{`${obj.vehicle
																					? obj.vehicle.make
																					: ''
																					} ${obj.vehicle
																						? obj.vehicle.model
																						: ''
																					}`}{' '}
																			</span>
																		) : (
																			<span className='global-header'>
																				{' '}
																				{`${obj.vehicle
																					? obj.vehicle.make ? obj.vehicle.make : ''
																					: ''
																					} ${obj.vehicle
																						? obj.vehicle.model ? obj.vehicle.model : ''
																						: ''
																					}`}{' '}
																			</span>
																		)}
																	</>
																) : (
																	<></>
																)}
																<span className='global-info'>
																	{' '}
																	{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																	{obj.displayID ? ` - ${obj.displayID}` : ''}
																</span>
																{!_.isEmpty(obj) && obj.status && (
																	<div className='global-status'>
																		{(() => {
																			const _jobStatus = obj.status === serviceStatus.OWNERRESPONDED ? obj.ownerResponse : obj.status;
																			let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]

																			return <>
																				{
																					_objCurrentStatus
																						?
																						<>
																							<span
																								className={`status-empty mr-0`}
																								style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																							>
																								{_objCurrentStatus.name}
																							</span>
																						</>
																						:
																						<></>
																				}
																			</>
																		})()}

																		{
																			obj?.technicianStatus
																				?
																				<>
																					<OverlayTrigger
																						placement='bottom'
																						overlay={<Tooltip><Translate text={'technicianStatus'} /> <br /> {CommonHelper.getNameByValue(technicianAllStatus, obj?.technicianStatus, obj?.technicianStatus)}</Tooltip>}
																					>
																						<div className="status-empty ml-0">
																							<i className={`ico icon-timer`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, obj?.technicianStatus, 'color', '#b0babe') }}></i>
																						</div>
																					</OverlayTrigger>
																				</>
																				:
																				<></>
																		}
																	</div>
																)}
															</div>
														</a>
													)}
												</>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'contacts' ? (
								<>
									{contactDetails.length > 0 ? (
										<>
											{contactDetails.map(obj => (
												<a
													className='global-item d-flex'
													onClick={(e) => {
														e.preventDefault();
														history.push(`/`);
														history.push(`contacts/details/${obj.documentID}`);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-Contacts'></i>
														</div>
													</div>
													<div>
														{(obj.firstName || obj.lastName) && (
															<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
														)}
														{obj.phone ? (
															<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
														) : obj.email ? (
															<span className='global-info'>{obj.email}</span>
														) : (
															<></>
														)}
														{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
															(<>
																<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>}
													</div>
												</a>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'company' ? (
								<>
									{companies.length > 0 ? (
										<>
											{companies.map(obj => (
												<a
													className='global-item d-flex'
													href='#'
													onClick={() => {
														history.push(`/`)
														history.push(`/company/details/${obj.documentID}`)
														// handleCompanyView(obj.documentID);
														handleClose();
													}}
													key={obj.documentID}
												>
													<div className='global-space'>
														<div className='global-icon-circle'>
															<i className='ico icon-company'></i>
														</div>
													</div>
													<div>
														{obj.name && (
															<span className='global-header'>{obj.name}</span>
														)}
														{obj.address && (
															<span className='global-info'>
																{`${obj.address} ${obj.areaCode}`}
															</span>
														)}
														{enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ?
															(<>
																<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>}
													</div>
												</a>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'tradein' ? (
								<>
									{tradeins.length > 0 ? (
										<>
											{tradeins.map(obj => (
												<Fragment key={`${obj.renderType}-${obj.documentID}`}>
													<a
														className='global-item d-flex'
														href='#'
														onClick={(e) => {
															e.preventDefault();
															if (obj.serviceID) history.push(`/service/details/${obj.serviceID}?tid=${obj.documentID}`)
															handleClose();
														}}
														key={`${obj.renderType}-${obj.documentID}`}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																{obj.displayID ? ` - ${obj.displayID}` : ''}
																{obj.regNo ? ` - ${obj.regNo}` : ''}
															</span>
															{!_.isEmpty(obj) && obj.status && (
																<div className='global-status'>
																	<>
																		{(() => {

																			const tradeinProAllStatus = Object.assign([], dealersettings?.client?.tradeinPro?.tradeinProStatus)
																			let _objCurrentStatus = obj.tradeinProID && obj.status && tradeinProAllStatus.filter(e => e.value === obj.status)[0]
																			return (
																				<>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<div
																									className={`badge badge-pill badge-status-${obj.status === tipStatus.NEW ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									<Translate
																										text={_objCurrentStatus.name}
																										upperCase={true}
																									/>
																								</div>
																							</>
																							:
																							<><div className={`badge badge-pill badge-status-${obj.status.toLowerCase()} mr-2`}>
																								<Translate
																									text={obj.status.toLowerCase()}
																									upperCase={true}
																								/>
																							</div></>
																					}
																				</>
																			);
																		})()}
																	</>
																</div>
															)}
															{
																obj.highlightTags && obj.highlightTags.map((rec, index) => {
																	return <div key={index} dangerouslySetInnerHTML={{ __html: rec }}></div>
																})
															}
														</div>
													</a>
												</Fragment>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : activeTab === 'activity' ? (
								<>
									{activities.length > 0 ? (
										<>
											{_.orderBy(
												activities,
												['sortDate.seconds'],
												['desc'],
											).map(obj => (
												<>
													{obj.type === 'activity' ? (
														<a
															className='global-item d-flex'
															href='#'
															onClick={e => {
																e.preventDefault();
																handleOpenEditActivity(obj);
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) && (
																	<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
															</div>
														</a>
													) : (
														<Link
															className='global-item d-flex'
															to={
																obj.serviceID
																	? `service/details/${obj.serviceID}?comments=${obj.documentID}`
																	: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
															}
															onClick={handleClose}
														>
															<div className='global-space'>
																<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																	<i className='ico icon-date'></i>
																</div>
															</div>
															<div>
																{obj.title && (
																	<span className='global-header'>{obj.title}</span>
																)}
																{!_.isEmpty(obj.contact) &&(
																	<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																)}
																{!isEmpty(obj.addedDate) && (
																	<div className='global-date'>
																		{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : !obj.isDone && obj.startDate ? ` - Scheduled Date: ${moment(obj.startDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																	</div>
																)}
															</div>
														</Link>
													)}
												</>
											))}
										</>
									) : (
										<NoDataDiv />
									)}
								</>
							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ServiceSearch;
