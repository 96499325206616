/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { Modal, Accordion, Card, Tabs, Tab } from "react-bootstrap";
import _ from 'lodash'
import { Tabs as AntdTab } from 'antd';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { ReactMultiSelect } from '../../../components';

import { default as _images } from '../../../images';
import { firestoreDB } from '../../../services/helper';
import AddProduct from './add'
import CommonHelper from '../../../services/common';


const AddOns = (props) => {
    //const { TabPane } = Tabs;
    const [searchText, setSearchText] = useState('')
    const [selectedAddOns, setAddOns] = useState(props.selectedAddOns ? props.selectedAddOns : [])
    const [serviceAddOns, setserviceAddOns] = useState([])
    const [activeRightKey, setActiveRightKey] = useState('')
    const [filterModule, setfilterModule] = useState([])
    const [active, setActive] = useState()
    const [activeTab, setActiveTab] = useState()


    useEffect(() => {
        setAddOns(props.selectedAddOns ? props.selectedAddOns : [])
    }, [props.selectedAddOns])

    useEffect(() => {
        if (!_.isEmpty(props.serviceAddOns)) {
            const _serviceAddOns = handleServiceAddons(Object.assign([], [...props?.['serviceAddOns']]))
            setserviceAddOns(_serviceAddOns);

            if (!_.isEmpty(props.editID)) {
                if (_.find(_serviceAddOns, { id: props.editID })?.module) setActiveTab(_.find(_serviceAddOns, { id: props.editID })?.module)
                if (_.find(_serviceAddOns, { id: props.editID })?.category) setActive(_.find(_serviceAddOns, { id: props.editID })?.category)
                setActiveRightKey(props.editID);
                if (document.getElementById('enquiry-item-' + props.editID)) {
                    document.getElementById('enquiry-item-' + props.editID).scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                    })
                }
            }
        }
    }, [])

    const handleServiceAddons = (addons) => {
        let _addons = [];
        let _productCategory = Object.assign([], props.dealersettings?.client?.settings?.productCategory)
        addons.forEach(rec => {

            if (!_.isEmpty(rec.subList)) {
                rec.subList.forEach(data => {
                    let _key = []
                    let _obj = {
                        ...data,
                        active: rec.active,
                        category: rec.category,
                        categoryName: CommonHelper.getNameByValue(_productCategory, rec.category, ''),
                        level: rec.level,
                        module: rec.module,
                        sortNo: rec.sortNo,
                    }
                    if (_obj.categoryName) _key.push(_obj.categoryName.toLowerCase())
                    if (_obj.module) _key.push(_obj.module.toLowerCase())
                    if (_obj.title) _key.push(_obj.title.toLowerCase())
                    _obj.keywords = _key
                    _addons.push(_obj)
                })
            }
            // else if (rec.value && rec.price) {
            //     _addons.push({ ...rec, module: rec.category })
            // }

        })
        return _addons
    }

    const handleSaved = (objData) => {
        // let _dynamicFields = Object.assign([], selectedAddOns);
        // _dynamicFields.push(objData);
        // setAddOns(_dynamicFields);
    }

    const handleMultiSelectChange = (selectedOptions) => {

        const value = [];
        !_.isEmpty(selectedOptions) &&
            selectedOptions.map((data) => {
                return value.push(data.value);
            });
        if (value.length) {
            setfilterModule(value);
        } else {
            setfilterModule("")
        }
        setActive('');
        setActiveTab('')
    }

    const handleRemove = (objData) => {
        const { dealersettings } = props;
        const { enquiryID, documentID } = objData;
        const accessToDeleteProduct = (dealersettings?.rolePermissions?.permissions?.accessToDeleteProduct) ? true : false;
        if (enquiryID && documentID && accessToDeleteProduct) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to delete.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            }).then((result) => {
                if (result.value) {
                    firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/productAddons/${documentID}`).delete();
                    CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.enquiryID, `Product: ${objData.title || ''} deleted.`, 'product', 'enquiry');
                }
            })
        }


    }

    const divStyle = {
        height: '500px',
        maxHeight: '600px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    let productCategory = _.chain(_.orderBy(serviceAddOns, ['category'], ['asc']))
        .groupBy("category")
        .map((value, key) => ({
            value: key,
            searchlabel: value[0]?.categoryName,
            label: <>
                {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.productCategory, key, 'others')}
                {value[0]?.module ? <div className={`badge badge-pill badge-input-white ml-1`}><Translate text={value[0]?.module} /></div> : <></>}
            </>,
            //label: CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.productCategory, key, 'others')
        }))
        .value()

    let _serviceAddOns = !_.isEmpty(serviceAddOns) ? serviceAddOns.filter(item =>
        (!_.isEmpty(filterModule) ? filterModule.includes(item.category) : true) &&
        (searchText ? (item.keywords?.includes(searchText.toLowerCase()) || item.title?.toLowerCase().includes(searchText.toLowerCase())) : true)) : [];

    let addOnsLists = _.chain(_.orderBy(_serviceAddOns, ['module'], ['asc']))
        .groupBy("module")
        .map((value, key) => (
            {
                name: key,
                categories: (_.chain(_.orderBy(Object.assign([], value), ['category'], ['asc']).filter(item2 => !_.isEmpty(item2.category)))
                    .groupBy("category")
                    .map((value, key) => (
                        {
                            name: key,
                            lists: value
                        }
                    ))
                    .value())
            }
        ))
        .value()



    //console.log('props.serviceAddOns', props.editID, activeRightKey)
    return (
        <>

            <Modal
                id="AddOns"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-checklist">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Product Add-Ons'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="product-list-filterwrap mb-3">
                            <div className="product-list-search">
                                <div className="input-group ">
                                    <input
                                        type="text"
                                        aria-label="Search"
                                        placeholder="Search..."
                                        value={searchText ? searchText : ''}
                                        autoComplete="off"
                                        onChange={e => {
                                            setSearchText(e.target.value);
                                            setActive('');
                                            setActiveTab('');
                                        }}
                                    />
                                    {
                                        searchText && searchText.length > 0
                                            ?
                                            <div className="input-group-append input-search-clear">
                                                <a href="#"
                                                    className="input-search-clear-icon"
                                                    title="Clear"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSearchText('');
                                                        setActive('');
                                                        setActiveTab('');
                                                    }}
                                                >
                                                    <i className="ico icon-remove"></i>
                                                </a>
                                            </div>
                                            :
                                            <>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="product-list-datefilter form-style">
                                <div className="inner-addon right-addon">
                                    <ReactMultiSelect
                                        options={_.orderBy(productCategory, ['label'], ['asc'])}
                                        //options={_.map(props.dealersettings?.client?.settings?.productCategory, function (e) { return { label: e.name, value: e.value, data: e } })}
                                        name={'category'}
                                        placeholder={`filter by categories`}
                                        onChange={handleMultiSelectChange}
                                        value={filterModule}
                                        classNamePrefix={`basic-select`}
                                        isMulti={true}
                                        isSearchable={true}
                                        removeClearable={false}
                                    >
                                    </ReactMultiSelect>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="checklist-popup-wrapper">

                                    <div className="checklist-popup-column-wraper product-addon-list-accordion product-scroll-fix pt-0 addons-wraper">
                                        {
                                            (!_.isEmpty(addOnsLists))
                                                ?
                                                <>
                                                    <div className={`settings-tab-wrap tabs`} >
                                                        <div className='tabs-wraper'>
                                                            <nav>

                                                                <Tabs
                                                                    defaultActiveKey={`${activeTab || addOnsLists[0]?.name}`}
                                                                    className='nav-fill addons-nav-fill'
                                                                    mountOnEnter={true}
                                                                    activeKey={`${activeTab || addOnsLists[0]?.name}`}
                                                                    onSelect={(key) => {
                                                                        setActiveTab(key)
                                                                        setActive();
                                                                    }}
                                                                >
                                                                    {
                                                                        addOnsLists && addOnsLists.map((_module, index) => {
                                                                            return <Tab
                                                                                key={index}
                                                                                eventKey={_module.name}
                                                                                title={CommonHelper.showLocale(props, _module.name)}>

                                                                                <AntdTab tabPosition={'left'} activeKey={`${active || _module.categories[0]?.name}`} onTabClick={(key, e) => { setActive(key); }}>
                                                                                    {
                                                                                        _module.categories && _.orderBy(_module.categories, ['category'], ['asc']).map((_category, index2) => {
                                                                                            return <div tab={CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.productCategory, _category.name, 'others')} key={_category.name}>
                                                                                                <div className="checklist-popup-column-right-large">

                                                                                                    <div className="checklist-popup-column-title">
                                                                                                        <h3>{CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.productCategory, _category.name, 'others')}</h3>
                                                                                                    </div>

                                                                                                    <div className="checklist-popup-large-details" style={divStyle}>
                                                                                                        <Accordion className="quickview-related" defaultActiveKey={props.editID ? props.editID : ''}>
                                                                                                            {
                                                                                                                _category.lists && _.orderBy(_category.lists, ['title'], ['asc']).map((_info, index3) => {
                                                                                                                    return <Card key={index3} >
                                                                                                                        <>
                                                                                                                            {(() => {
                                                                                                                                let selectedInfo = !_.isEmpty(selectedAddOns) && _.find(selectedAddOns, { id: _info.id }) ? _.find(selectedAddOns, { id: _info.id }) : _info
                                                                                                                                return <>
                                                                                                                                    <Accordion.Toggle as={Card.Header} eventKey={_info.id} id={`enquiry-item-${_info.id}`} onClick={(e) => {
                                                                                                                                        e.preventDefault();

                                                                                                                                        if (activeRightKey === _info.id)
                                                                                                                                            setActiveRightKey(null);
                                                                                                                                        else
                                                                                                                                            setActiveRightKey(_info.id);
                                                                                                                                    }}>
                                                                                                                                        <div className="mb-0" id={`recomm_${_info.documentID}`}>
                                                                                                                                            <div className="cardlink">
                                                                                                                                                <a
                                                                                                                                                    onClick={(e) => { e.preventDefault(); }}
                                                                                                                                                    className={`btn btn-link ${activeRightKey === _info.id ? '' : 'collapsed'}`}                                                                                            >

                                                                                                                                                    <div className="product-addon-popup-icon"> <i className="ico icon-service-package"> </i> </div>
                                                                                                                                                    <div className="recom-list-head">{_info.title} </div>

                                                                                                                                                    <div className="product-addon-add-button">
                                                                                                                                                        {
                                                                                                                                                            !_.isEmpty(selectedAddOns) && _.find(selectedAddOns, { id: selectedInfo.id })
                                                                                                                                                                ?
                                                                                                                                                                <button className="btn btn-sm selected-package-btn"
                                                                                                                                                                    onClick={(e) => {
                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                        e.stopPropagation();
                                                                                                                                                                        handleRemove(selectedInfo)
                                                                                                                                                                    }}><i className="ico icon-check mr-1"> </i><Translate text={'Selected'} /></button>
                                                                                                                                                                :
                                                                                                                                                                <button className="btn btn-sm btn-default add-package-btn"
                                                                                                                                                                    onClick={(e) => {
                                                                                                                                                                        e.preventDefault();
                                                                                                                                                                    }}><i className="ico icon-add mr-1"></i> <Translate text={'add'} /></button>

                                                                                                                                                        }
                                                                                                                                                    </div>


                                                                                                                                                    <i className="arrow-collapse"></i>
                                                                                                                                                </a>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </Accordion.Toggle>


                                                                                                                                    <Accordion.Collapse eventKey={_info.id}>
                                                                                                                                        <Card.Body>
                                                                                                                                            {
                                                                                                                                                activeRightKey === _info.id && (<AddProduct
                                                                                                                                                    {...props}
                                                                                                                                                    info={selectedInfo}
                                                                                                                                                    handleSaved={handleSaved}
                                                                                                                                                ></AddProduct>)
                                                                                                                                            }

                                                                                                                                        </Card.Body>
                                                                                                                                    </Accordion.Collapse>
                                                                                                                                </>
                                                                                                                            })()}
                                                                                                                        </>


                                                                                                                    </Card>
                                                                                                                })
                                                                                                            }
                                                                                                        </Accordion>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                    }
                                                                                </AntdTab>
                                                                            </Tab>
                                                                        })
                                                                    }

                                                                </Tabs>

                                                            </nav>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    <div className="cafe-items-pop" style={divStyle}>
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-service-package"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'No packages available'} /></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                        }


                                    </div>


                                </div>
                            </div>
                        </div>



                    </>
                </Modal.Body>
                <Modal.Footer className={`modal-footer`}>
                    <button type="button" className="btn btn-secondary float-right"
                        onClick={(e) => { e.preventDefault(); props.handleClose(); }}>
                        <Translate text={'done'} />
                    </button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default AddOns