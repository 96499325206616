import React, { useRef, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash';
import moment from 'moment';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
import { firestoreDB } from '../../../services/helper';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';

const InvoiceInfo = ({ dealersettings, isDeivered, quotation, clientUsers, currencySymbol, addInvoice, deleteInvoice, gdprDeleted }) => {
    const [editshow, setEditshow] = useState(false)

    const node = useRef();


    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }

        setEditshow(false);
    };

    const handleDeleteQuotation = async (id) => {
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings }, `You want to delete this invoice`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
        }).then(async result => {
            if (result.value) {
                const ref = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${quotation.serviceID}/invoices/${id}`)
                await ref.set({
                    isDeleted: true,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }, { merge: true })

                let logNotes = 'Invoice deleted';
                // if (!_.isEmpty(state) && state.displayID)
                // 	logNotes = 'Quotation: ' + state.displayID + ' deleted';

                let _objLogData = {
                    notes: logNotes,
                    type: 'invoices',
                    subType: 'delete',
                    recordId: id,
                    serviceID: quotation.serviceID
                }
                _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                CommonHelper.saveAuditLog(_objLogData)
                toast.notify('Invoice deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    const handlePaidQuotation = async (_isPaid) => {
        Swal.fire({
            title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
            text: CommonHelper.showLocale({ dealersettings }, `You want to mark this invoice as ${_isPaid ? 'paid' : 'unpaid'}.`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
            cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
        }).then(async result => {
            if (result.value) {
                const ref = firestoreDB(dealersettings).firestore().doc(`serviceJobs/${quotation.serviceID}/invoices/${quotation.documentID}`)
                await ref.set({
                    isPaid: _isPaid,
                    paidBy: _isPaid ? localStorage.uid : '',
                    paidDate: _isPaid ? window.firebase.firestore.Timestamp.now() : null,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                }, { merge: true })

                toast.notify('Invoice updated successfully', {
                    duration: 2000
                })
            }
        })

    }

    return (
        <div className='quotation-sidebar-wrap'>
            <div className='flex-quotation-sidebar'>
                {
                    gdprDeleted
                        ?
                        <></>
                        :
                        <>
                            <div className='quotation-sidebar-more'>
                                {' '}
                                <a href='#' onClick={(e) => {
                                    e.preventDefault();
                                    setEditshow(!editshow)
                                    if (editshow)
                                        document.addEventListener('click', setEditshow(false));
                                }}>
                                    <i className='ico icon-more'></i>
                                </a>{' '}
                                <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                                    <ul className="more-dropdown">
                                        {
                                            (!addInvoice || isDeivered)
                                                ?
                                                <></>
                                                :
                                                <li>
                                                    <a href='' onClick={e => {
                                                        e.preventDefault();
                                                        const url = `/service/invoice/${quotation.serviceID}?type=edit&previewID=${quotation.documentID}`;
                                                        window.open(url, '_blank');
                                                    }}>
                                                        <i className="ico icon-edit"></i> <Translate text={'edit'} />
                                                    </a>
                                                </li>
                                        }

                                        <li>
                                            <a href='' onClick={e => {
                                                e.preventDefault();
                                                const url = `/serviceinvoice/preview/${quotation.documentID}${addInvoice ? '?vbtn=y' : ''}`;
                                                window.open(url, '_blank');
                                            }}>
                                                <i className="ico icon-zoom"></i> <Translate text={'View'} />
                                            </a>
                                        </li>

                                        {deleteInvoice && !isDeivered ? (
                                            <li>
                                                <a href='' onClick={e => { e.preventDefault(); handleDeleteQuotation(quotation.documentID) }}>
                                                    <i className="ico icon-delete"></i> <Translate text={'Delete'} />
                                                </a>
                                            </li>
                                        ) : (<></>)}

                                    </ul>
                                </div>
                            </div>
                        </>
                }

                <div className='quotation-sidebar-icon'>
                    {' '}
                    <i className='ico icon-contract'></i>{' '}
                </div>
                <div className='quotation-sidebar-data'>
                    <div className='quotation-sidebar-text'>
                        <div className='quotation-sidebar-id'>
                            {' '}
                            <strong>Invoice#:{quotation.displayID}</strong>

                        </div>
                        <div className='quotation-sidebar-title'>
                            {' '}
                            <strong>{quotation.vehicle.make ? quotation.vehicle.make : ''}{quotation.vehicle.model ? ` ${quotation.vehicle.model}` : ''}</strong>
                        </div>
                        <div className='quotation-sidebar-date'>
                            <span>{!isEmpty(quotation.addedDate) ? moment(quotation.addedDate.toDate()).format('DD/MM/YYYY h:mm A') : ''} </span>{` - ${!isEmpty(quotation.addedBy) ? CommonHelper.getUserNamebyId(clientUsers, quotation.addedBy) : ''}`}
                        </div>
                        <div className='quotation-sidebar-inline'>
                            <div className='quotation-sidebar-pricetag'>
                                {' '}
                                <NumberFormat
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={currencySymbol}
                                    displayType={'text'}
                                    value={quotation.payableOnDelivery ? quotation.payableOnDelivery : 0}
                                />
                                <div></div>
                            </div>
                            <div className="quotation-mark-paid">
                                {
                                    quotation.isPaid ?
                                        <><div className="badge-paid mt-1" onClick={e => { e.preventDefault(); handlePaidQuotation(false) }}> <i className="ico icon-campaign-completed"> </i> PAID</div></>
                                        :
                                        <><button className="btn btn-sm btn-default" onClick={e => { e.preventDefault(); handlePaidQuotation(true) }}>  Mark as Paid</button></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceInfo;
