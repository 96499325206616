import React, { useState, useEffect, useCallback, useRef, useReducer, useMemo, useContext } from 'react';
import moment from 'moment';
import { default as _images } from '../../../images';
import { Tab, Nav } from 'react-bootstrap';
import ActivityTag from './activityTag'
import Translate from '../../../constants/translate';
import { objActivitylog, enquiryActivities, enquirylogscompleted, objWalkIn } from '../viewModel';
import CustomerEditLog from '../quickview/editLogs'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ContentOverlay, PopUpModal } from '../../../components'
import CommonHelper from '../../../services/common'
import AddActivity from '../../activity'
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import EmailPreview from "../../mail/emailPreview";
import ComposeEmail from '../../mail/composeEmail'
import TemplatePreview from '../../settings/workflow/triggers/previewTemplate'
import { MainContext } from '../../layout/provider';
import ActivityStatus from '../../activity/actvityStatusModal';
import { enquiryStatus, enqUnActionStatus } from '../../../services/enum';
import { objOfferNotesVM } from '../../tradeinplus/viewModel';
import { firestoreDB } from '../../../services/helper';

const AcitivitiesReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callType = action.data.oldcallType
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item, index) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallType = item.callType
                item.oldcallStatus = item.callStatus
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        case "SUCCESS_EMAIL_LOG": {
            return action.data;
        }
        default:
            return state;
    }
};


let languages = [];
let countries = [];
let states = [];
let financeStatuses = [];
let nationalities = [];

const ActivityDynamic = (props) => {
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [activitylogs, dispatch] = useReducer(AcitivitiesReducer, [])
    const [showActivity, setShowActivity] = useState(false);
    const [activeTab, setActivetab] = useState('note')
    const [newActivitylog, setNewActivitylog] = useState(null);
    const textareaRef = useRef(null);
    const [completedTab, setCompltedtab] = useState({ type: 'all', subtype: 'all' })
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const acitivtyScheduledTabs = ["All", "Overdue", "Today", "Upcoming", "EDMs"]
    const [activeScheduledTab, setActiveScheduledTab] = useState('All')
    const [logLoader, setLogloader] = useState(false)
    const [isNewActivity, setNewActivity] = useState(false)
    const [stockObj, setStockObj] = useState(null)
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [tradeinProObj, setTradeinProObj] = useState(null);
    const [serviceObj, setServiceObj] = useState(null);
    const [contactObj, setConatactObj] = useState(null);
    const [showPreviewModel, setPreviewModel] = useState(false)
    const [emailLog, setEmaillog] = useState(null)
    const [showEDMsPreviewModel, setEDMsPreviewModel] = useState(false)
    const [showComposeModal, setShowComposeModal] = useState({
        show: false,
        type: '',
        title: '',
        emailData: null,
    });
    const [sendLoader, setSendLoader] = useState(false);
    const { emailTemplates } = useContext(MainContext);

    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [pipelines, setPipelines] = useState([])
    const [stages, setStages] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });

    const [tempData, setTempData] = useState([])

    const _messagemedia = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

    const _smshub = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

    const _podium = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

    const _cmdotcom = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "cmdotcom")[0]);

    const _ringcentral = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

    const aircallEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "aircall")[0].enabled === true
    ) ? true : false);

    const justcallEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
    ) ? true : false);

    const tssEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "tss")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "tss")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "tss")[0].enabled === true
    ) ? true : false);

    const enableTwoWaySMS = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableTwoWaySMS) ? true : false);

    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    const ringcentralEnabled = enableTwoWaySMS && _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
    const messageMediaEnabled = enableTwoWaySMS && _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
    const smsHubEnabled = enableTwoWaySMS && _smshub && _smshub.active && _smshub.enabled ? true : false;
    const podiumEnabled = enableTwoWaySMS && _podium && _podium.active && _podium.enabled ? true : false;
    const cmdotcomEnabled = _cmdotcom && _cmdotcom.active && _cmdotcom.enabled ? true : false;
    const [sendLoading, setSendloading] = useState(false)
    let _allUser = _.union((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), Object.assign([], props.dealersettings.wholesalers));

    const _typeActivity = props.originType === 'enquiry' ? 'activity' : `${props.originType}activity`
    const _typeLog = props.originType === 'enquiry' ? 'log' : `${props.originType}log`
    const _originType = props.originType

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter, enquiryBasicVM } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {

            let _objEnq = !_.isEmpty(enquiryBasicVM) ? enquiryBasicVM : enquiryObj;
            let setttings = dealersettings.client.settings;

            let tip_settings = dealersettings.client && dealersettings.client.tradeinPro;
            let service_settings = dealersettings.client && dealersettings.client.services;

            if (_objEnq && _objEnq.clientID && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
                && !_.isEmpty(dealersettings.group.clients[_objEnq.clientID]) && !_.isEmpty(dealersettings.group.clients[_objEnq.clientID].settings))
                setttings = dealersettings.group.clients[_objEnq.clientID].settings;

            const brands = dealersettings.client.brands;
            const _makes = [];
            const _lostReasons = [];
            const _lostSubReasons = [];
            const _pipelines = [];
            const _stages = [];

            const _moduleSettings = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.moduleSettings)) ? dealersettings.client.moduleSettings : null);

            const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
                !_.isEmpty(dealersettings.client.moduleSettings.finance) &&
                dealersettings.client.moduleSettings.finance.enabled) ? true : false);

            // titles = [];
            // setttings.titles && setttings.titles.forEach((doc) => {
            //     titles.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name
            //     });
            // });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            // genders = [];
            // setttings.genders && setttings.genders.forEach(doc => {
            //     genders.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // marital_status = [];
            // setttings.maritalStatus && setttings.maritalStatus.forEach(doc => {
            //     marital_status.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // contact_method = [];
            // setttings.contactMethod && setttings.contactMethod.forEach(doc => {
            //     contact_method.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // company_type = [];
            // setttings.companyType && setttings.companyType.forEach(doc => {
            //     company_type.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // interested = [];
            // setttings.interests && setttings.interests.forEach(doc => {
            //     interested.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // license_type = [];
            // setttings.licenseType && setttings.licenseType.forEach(doc => {
            //     license_type.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            countries = [];
            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            states = [];
            dealersettings.states && dealersettings.states.forEach((doc) => {
                states.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            // origins = [];
            // setttings.origins && setttings.origins.forEach(doc => {
            //     origins.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // enquiryTypes = [];
            // setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
            //     enquiryTypes.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // leadSources = [];
            // setttings.leadSource && setttings.leadSource.forEach(doc => {
            //     leadSources.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // campaigns = [];
            // setttings.campaigns && setttings.campaigns.forEach(doc => {
            //     campaigns.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // enquiryOptions = [];
            // setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
            //     enquiryOptions.push({
            //         value: doc.value,
            //         active: _.isBoolean(doc.active) ? doc.active : true,
            //         label: doc.name,
            //     });
            // });

            // enquiryOptionsDF = [];
            // if (setttings.enquiryOptionsDF)
            //     enquiryOptionsDF = setttings.enquiryOptionsDF;


            financeStatuses = [];
            !financeEnabled && setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });

                !_.isEmpty(doc.subList) && doc.subList.forEach(docsub => {
                    _lostSubReasons.push({
                        value: docsub.value,
                        label: docsub.name
                    });
                });
            });

            dealersettings.allPipelines && dealersettings.allPipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    label: doc.name
                });

                !_.isEmpty(doc.stages) && doc.stages.forEach(docsub => {
                    _stages.push({
                        value: docsub.value,
                        label: docsub.name
                    });
                });
            });

            setLostReasons(_lostReasons);
            setLostSubReasons(_lostSubReasons);
            setPipelines(_pipelines);
            setStages(_stages);

        }

    }, [props.dealersettings])

    useEffect(() => {
        if (_.isEmpty(props.enquiry) || _.isEmpty(props.enquiry.documentID) || activitylogs.length === 0)
            return
        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${props.enquiry.documentID}`)
            .set({
                counts: {
                    todayDate: moment().format('YYYY-MM-DD'),
                    overdue: scheduleActvitiescount('Overdue'),
                    today: scheduleActvitiescount('Today'),
                    upcoming: scheduleActvitiescount('Upcoming')
                }
            }, { merge: true })

    }, [activitylogs])

    useEffect(() => {
        if (_.isEmpty(props.contact) && _.isEmpty(props.enquiry) && _.isEmpty(props.tradeinPro) && _.isEmpty(props.service))
            return

        let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
            .where('isDeleted', '==', false)
            .where('type', 'in', [_typeActivity, _typeLog])
        if (props.contact)
            refActivitiesData = refActivitiesData.where('contactID', '==', props.contact.documentID)
        else
            refActivitiesData = refActivitiesData.where('enquiryID', '==', props.enquiry.documentID)


        let linkEmailsData = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
        if (props.contact)
            linkEmailsData = linkEmailsData.where('contactID', '==', props.contact.documentID)
        else
            linkEmailsData = linkEmailsData.where('enquiryID', '==', props.enquiry.documentID).where('type', 'in', [_typeLog])

        let edmsData = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
            .where('isDeleted', '==', false)
        if (_originType && _originType !== 'enquiry')
            edmsData = edmsData.where('recipientType', '==', `${_originType}BM`)

        if (props.contact)
            edmsData = edmsData.where('contactID', '==', props.contact.documentID)
        else
            edmsData = edmsData.where('enquiryID', '==', props.enquiry.documentID)


        let changeLogData = window.firebase.firestore().collection('changeLogs')
        if (props.contact)
            changeLogData = changeLogData.where('recordId', '==', props.contact.documentID)
        else
            changeLogData = changeLogData.where('recordId', "in", !_.isEmpty(props.enquiry.contact) ? [props.enquiry.documentID, props.enquiry.contact.documentID] : [props.enquiry.documentID])


        let campaignSMS = ''
        if (props.contact)
            campaignSMS = window.firebase.firestore().collectionGroup('smsRecipients')
                .where('contactID', '==', props.contact.documentID)

        let aircallContactsLogs = ''
        if ((aircallEnabled || justcallEnabled || tssEnabled) && props?.enquiry?.contact?.documentID) {
            aircallContactsLogs = firestoreDB(props.dealersettings).firestore().collection('activities')
                .where('isDeleted', '==', false)
                .where('type', '==', _typeLog)
                .where('subType', '==', 'call')
                .where('tags', 'array-contains-any', ['aircall', 'justcall', 'tss'])
                .where('contactID', '==', props?.enquiry?.contact?.documentID)
        }

        var activitiesCollection = combineLatest(
            collection(refActivitiesData),
            collection(linkEmailsData),
            collection(edmsData),
            collection(changeLogData),
            campaignSMS ? collection(campaignSMS) : of([]),
            aircallContactsLogs ? collection(aircallContactsLogs) : of([])
        ).pipe(
            map(([activities, emails, edms, changelogs, campaignsms, aircallcontactsLogs]) => {
                return [
                    _.map(activities, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate), owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
                    _.map(emails, function (obj) { return { ...obj.data(), documentID: obj.id, type: CommonHelper.getActivityType(_typeLog, _originType), subType: 'email', isDone: true, startDate: obj.data().linkedDate, addedDate: obj.data().linkedDate, owner: obj.data().linkedBy, threadID: (obj.data().thread ? obj.data().thread.id : null) } }),
                    _.map(edms, function (obj) { return { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate } }),
                    _.map(changelogs, function (obj) { return { ...obj.data(), documentID: obj.id, origin: obj.data().type, type: CommonHelper.getActivityType(_typeLog, _originType), subType: 'log', isDone: true, startDate: obj.data().logDate, addedDate: obj.data().logDate, owner: obj.data().logBy } }),
                    _.map(campaignsms, function (obj) {
                        return {
                            ...obj.data(),
                            documentID: obj.id,
                            iconType: 'message',
                            type: CommonHelper.getActivityType(_typeLog, _originType),
                            triggerType: 'edms',
                            subType: 'edms',
                            isDone: true,
                            startDate: obj.data().messagemediaresp ? window.firebase.firestore.Timestamp.fromDate(moment(obj.data().messagemediaresp.submitted_date).toDate()) : null,
                            addedDate: obj.data().messagemediaresp ? window.firebase.firestore.Timestamp.fromDate(moment(obj.data().messagemediaresp.submitted_date).toDate()) : null,
                            notes: obj.data().messagemediaresp ? obj.data().messagemediaresp.original_text : null,
                            owner: obj.data().addedBy
                        }
                    }),
                    _.map(aircallcontactsLogs, function (obj) { return { ...obj.data(), documentID: obj.id, startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate), owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } }),
                ]
            }))
            .subscribe(async ([activities, emails, edms, changelogs, campaignsms, aircallcontactsLogs]) => {


                if (emails.length === 0) {
                    setLoadingActivity(false)
                    dispatch({
                        type: "SUCCESS",
                        data: activities.concat(emails).concat(edms).concat(changelogs).concat(campaignsms.filter(a => !_.isEmpty(a.notes))).concat(aircallcontactsLogs.filter(a => _.isEmpty(a.enquiryID)))
                    });
                }
                else {
                    setTempData(activities.concat(emails).concat(edms).concat(changelogs).concat(campaignsms.filter(a => !_.isEmpty(a.notes))).concat(aircallcontactsLogs.filter(a => _.isEmpty(a.enquiryID))))
                }
            });
        return () => {
            activitiesCollection && activitiesCollection.unsubscribe()
        }

    }, [])

    // useEffect(() => {
    //     if (props.noContact === true)
    //         setLoadingActivity(false)
    // }, [props.noContact])

    useEffect(() => {
        if (props.loadContacts === false || props.loadContacts === undefined)
            return


        let querySet = [];
        props.contacts.forEach(contact => {

            let edmsData = firestoreDB(props.dealersettings).firestore().collection('triggersLog')
                .where('isDeleted', '==', false)
                .where('contactID', '==', contact.documentID)

            if (_originType && _originType !== 'enquiry')
                edmsData = edmsData.where('recipientType', '==', `${_originType}BM`)

            querySet.push(collection(firestoreDB(props.dealersettings).firestore().collection('activities')
                .where('isDeleted', '==', false)
                .where('contactID', '==', contact.documentID)))
            querySet.push(collection(firestoreDB(props.dealersettings).firestore().collection('enquiryEmails')
                .where('contactID', '==', contact.documentID)))
            querySet.push(collection(edmsData))
        })
        querySet.push(collection(window.firebase.firestore().collection('changeLogs')
            .where('recordId', '==', props.companyID)))

        var activitiesCollection = combineLatest(querySet).pipe(
            map((logs) => {
                return logs.map(docs => {
                    return [
                        ..._.map(docs, function (obj) {
                            return obj.data().type === CommonHelper.getActivityType(_typeActivity, _originType) ? { ...obj.data(), documentID: obj.id, startDate: (_.isEmpty(obj.data().startDate) ? obj.data().addedDate : obj.data().startDate), owner: (!_.isEmpty(obj.data().owner) ? obj.data().owner : obj.data().addedBy) } :
                                obj.data().thread ? { ...obj.data(), documentID: obj.id, type: CommonHelper.getActivityType(_typeLog, _originType), subType: 'email', isDone: true, startDate: obj.data().linkedDate, addedDate: obj.data().linkedDate, owner: obj.data().linkedBy, threadID: (obj.data().thread ? obj.data().thread.id : null) } :
                                    obj.data().triggerID ? { ...obj.data(), documentID: obj.id, iconType: obj.data().subType, subType: 'edms', addedDate: obj.data().startDate } :
                                        obj.data().recordId ? { ...obj.data(), documentID: obj.id, origin: obj.data().type, type: CommonHelper.getActivityType(_typeLog, _originType), subType: 'log', isDone: true, startDate: obj.data().logDate, addedDate: obj.data().logDate, owner: obj.data().logBy } :
                                            {}
                        })
                    ]
                })
            }))
            .subscribe(async (allActivities) => {

                let _activities = [];
                allActivities.forEach(r => {
                    r.forEach(d => {
                        if (!_.isEmpty(d))
                            _activities.push(d)
                    })
                });
                if (_activities.filter(e => e.type === CommonHelper.getActivityType(_typeLog, _originType) && e.subType === 'email' && !_.isEmpty(e.thread)).length === 0) {
                    setLoadingActivity(false)
                    dispatch({
                        type: "SUCCESS",
                        data: _activities
                    });
                }
                else {
                    setTempData(_activities)
                }

            });
        return () => {
            activitiesCollection && activitiesCollection.unsubscribe()
        }

    }, [props.contacts])

    useEffect(() => {
        if (tempData.length === 0)
            return
        async function getTheardEmails(activities) {
            const promises = []
            activities.filter(e => e.type === CommonHelper.getActivityType(_typeLog, _originType) && e.subType === 'email' && !_.isEmpty(e.thread)).forEach(el => {
                promises.push(window.firebase.firestore().doc(`nylas-accounts/${el.account_id ? el.account_id : el.thread.account_id}/nylas-threads/${el.threadID}`).get())
            });
            const snapshots = await Promise.all(promises)
            snapshots.forEach(snap => {
                if (snap.exists) {
                    activities = [...activities.filter(e => e.threadID !== snap.id), {
                        ..._.find(activities, { 'threadID': snap.id }),
                        thread: snap.data()
                    }]
                }
            })
            setLoadingActivity(false)
            dispatch({
                type: "SUCCESS",
                data: activities
            });
        }

        getTheardEmails(tempData);

    }, [tempData])


    // useEffect(() => {
    //     setLoadingActivity(true)
    //     const refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities');
    //     if (props.contact) {
    //         refActivitiesData
    //             .where('contactID', '==', props.contact.documentID)
    //             .where('isDeleted', '==', false)
    //             .orderBy('addedDate', 'desc')
    //             .onSnapshot(onCollectionUpdate);
    //     }
    //     else {
    //         refActivitiesData
    //             .where('enquiryID', '==', props.enquiry.documentID)
    //             .where('isDeleted', '==', false)
    //             .orderBy('addedDate', 'desc')
    //             .onSnapshot(onCollectionUpdate);
    //     }

    //     return () => {
    //         refActivitiesData && refActivitiesData()
    //     }

    //     // refActivitiesData.get()
    //     //     .then((querySnapshot) => {
    //     //         onCollectionUpdate(querySnapshot);
    //     //     })
    //     //     .catch(error => {
    //     //         //console.log(error)
    //     //         setLoadingActivity(false)
    //     //     });
    // }, [])


    useEffect(() => {
        let newlogVM = Object.assign({}, objActivitylog);
        if (activeTab === 'activity') {
            newlogVM.type = CommonHelper.getActivityType(_typeActivity, _originType);
            newlogVM.subType = 'appointment';
            newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
            newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment().add(1, 'hours')._d);
            newlogVM.title = 'Appointment';           
        }
        else {
            newlogVM.type = CommonHelper.getActivityType(_typeLog, _originType);
            newlogVM.subType = activeTab;
            newlogVM.isDone = true;
            newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
        }
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), localStorage.uid);
        if (!_.isEmpty(props.enquiry)) {
            newlogVM.enquiryID = props.enquiry.documentID;
            newlogVM.enquiry = CommonHelper.getMinifiedData(props.enquiry, 'enquiry');
            if (!_.isEmpty(props.enquiry.contact)) {
                newlogVM.contactID = props.enquiry.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.enquiry.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.tradeinPro)) {
            newlogVM.tradeinProID = props.tradeinPro.documentID;
            newlogVM.tradeinPro = CommonHelper.getMinifiedData(props.tradeinPro, 'tradeinPro');
            if (!_.isEmpty(props.tradeinPro.contact)) {
                newlogVM.contactID = props.tradeinPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.tradeinPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.eventsPro)) {
            newlogVM.eventsProID = props.eventsPro.documentID;
            newlogVM.eventsPro = CommonHelper.getMinifiedData(props.eventsPro, 'eventsPro');
            if (!_.isEmpty(props.eventsPro.contact)) {
                newlogVM.contactID = props.eventsPro.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.eventsPro.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.service)) {
            newlogVM.serviceID = props.service.documentID;
            newlogVM.service = CommonHelper.getMinifiedData(props.service, 'service');
            if (!_.isEmpty(props.service.contact)) {
                newlogVM.contactID = props.service.contact.documentID;
                newlogVM.contact = CommonHelper.getMinifiedData(props.service.contact, 'contact');
            }
        }
        else if (!_.isEmpty(props.contact)) {
            newlogVM.contactID = props.contact.documentID;
            newlogVM.contact = CommonHelper.getMinifiedData(props.contact, 'contact');
        }
        //newlogVM.clientID = props.dealersettings.client.id;
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings, null, (!_.isEmpty(props.enquiry)
            ? props.enquiry.clientID
            : !_.isEmpty(props.tradeinPro)
                ? props.tradeinPro.clientID
                : !_.isEmpty(props.service)
                    ? props.service.clientID
                    : null));

        setNewActivitylog(newlogVM)

    }, [activeTab, showActivityModel, showActivity])

    const onCollectionUpdate = (querySnapshot) => {

        const _activities = [];
        querySnapshot.forEach(function (doc) {
            const objlogData = Object.assign({}, doc.data());

            objlogData.documentID = doc.id;
            const logVM = Object.assign({}, objActivitylog);
            for (let [key, value] of Object.entries(objlogData)) {
                logVM[key] = value;
            }
            if (logVM.type === CommonHelper.getActivityType(_typeLog, _originType)) {
                logVM.startDate = logVM.addedDate
            }
            _activities.push(logVM)
        });
        setLoadingActivity(false)

        dispatch({
            type: "SUCCESS",
            data: _activities
        });
    }

    const onEmailsCollectionUpdate = (querySnapshot) => {

        const _emaillogs = [];
        querySnapshot.forEach(function (doc) {
            const objlogData = Object.assign({}, doc.data());
            objlogData.documentID = doc.id;
            _emaillogs.push(objlogData)
        });

        dispatch({
            type: "SUCCESS_EMAIL_LOG",
            data: _emaillogs
        });
    }

    const activitypanelOpen = async () => {

        const { enquiry } = props;
        if (props.isValidForActivity && !_.isEmpty(enquiry)) {
            if (await props.isValidForActivity(enquiry.pipeline, enquiry.stage, 'activity')) activityOpen();
        }
        else activityOpen();

    }
    const activityOpen = () => {
        if (activeTab === 'activity') {
            setNewActivity(true)
            setActivityHeader('Add New Activity')
            setShowActivityModel(true)
        }
        else {
            setShowActivity(true)
        }
    }

    const scheduleactivitypanelOpen = async () => {

        const { enquiry } = props;
        if (props.isValidForActivity && enquiry) {
            if (await props.isValidForActivity(enquiry.pipeline, enquiry.stage, 'activity')) scheduleactivityOpen();
        }
        else scheduleactivityOpen();

    }
    const scheduleactivityOpen = () => {
        setNewActivitylog(null);
        setStockObj(null);
        setEnquiryObj(null);
        setTradeinProObj(null)
        setConatactObj(null);
        setNewActivity(true);
        setActivityHeader('Add New Activity');
        setShowActivityModel(true)
    }

    const handleDateChangelog = useCallback((value, name, objLog) => {
        if (objLog.isEditable) {
            dispatch({
                type: "CHANGELOG",
                id: objLog.documentID,
                field: name,
                value: (name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber') ? value : window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
            });
        }
        else {
            setNewActivitylog({
                ...objLog,
                'subType': objLog.subType,
                [name]: (name === 'callDuration' || name === 'callStatus' || name === 'callType' || name === 'sourceNumber') ? value : window.firebase.firestore.Timestamp.fromDate(moment(value)._d)
            });
        }

    }, [])

    const handleFileChange = useCallback((url, name, objLog, aTab) => {
        setNewActivitylog({
            ...objLog,
            subType: objLog.subType,
            ['files']: _.isEmpty(url) ? null : [{
                name,
                url
            }],
        });
    }, []);

    const handlecancellog = useCallback((e, objLog) => {

        e.preventDefault();
        // setNewActivitylog(null)  
        if (objLog.isEditable) {
            dispatch({
                type: "CANCELLOG",
                data: objLog
            });
        }
        else {
            setShowActivity(false)
        }

    }, []);
    const handlechangelog = useCallback((e, objLog) => {
        var domTextref = document.getElementById('notes-tab-' + objLog.documentID)
        if (e.target.name === 'notes' && e.target.value.length === 0) {
            domTextref && domTextref.classList.add('input_error')
        }
        else {
            domTextref && domTextref.classList.remove('input_error')
        }
        e.preventDefault && e.preventDefault();
        if (objLog.isEditable) {
            dispatch({
                type: "CHANGELOG",
                id: objLog.documentID,
                field: e.target.name,
                value: e.target.value
            });
        }
        else {
            setNewActivitylog({
                ...objLog,
                [e.target.name]: e.target.value
            });
        }

    }, [])


    const handleRadioChange = useCallback((e, objLog) => {
        //e.preventDefault && e.preventDefault();
        setNewActivitylog({
            ...objLog,
            [e.target.name]: e.target.checked
        });

    }, [])


    const handlesavelog = useCallback((e, objLog, cTab, mentions, isSend, _contact, _enquiry, _tradeinPro) => {
        e.preventDefault();
        let updatelog = false;

        var domTextref = document.getElementById('notes-tab-' + objLog.documentID)
        if (objLog.notes.length === 0 && domTextref) {
            domTextref.focus();
            domTextref.classList.add('input_error')
            return
        }
        delete objLog.oldnotes;
        delete objLog.oldcallDuration;
        delete objLog.oldcallType;
        delete objLog.oldcallStatus;
        delete objLog.oldaddedDate;
        delete objLog.startDate;
        delete objLog.searchUsers;
        objLog.startDate = objLog.addedDate;
        if (objLog.isNewlog) {
            delete objLog.isNewlog;
            objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        }
        else if (objLog.isEditable) {
            delete objLog.isEditable;
            objLog.modifiedBy = localStorage.uid;
            objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
            updatelog = true;
        }
        objLog.modifiedByName = props.dealersettings.name;
        objLog.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        if (mentions.length > 0) {
            let convertedIDs = []
            let rawIDs = mentions.map(mentionObject => mentionObject.id)
            rawIDs.forEach(id => {
                if (id.includes(',')) {
                    let allIDs = id.split(',')
                    allIDs.forEach(a => {
                        convertedIDs.push(a)
                    })
                } else {
                    convertedIDs.push(id)
                }
            })

            if (objLog.mentions && objLog.mentions.length > 0) {
                objLog.mentions = _.uniq([...objLog.mentions, ...convertedIDs])
            } else {
                objLog.mentions = _.uniq(convertedIDs)
            }
        }
        if (isSend)
            setSendloading(true)
        else
            setLogloader(true)

        //console.log('handlesavelog', _contact, _enquiry)
        if (objLog.oemNote === true) {
            firestoreDB(props.dealersettings)
                .firestore()
                .doc(`oemNotes/${objLog.documentID}`)
                .set(objLog, { merge: true })
                .then(snapshot => {
                    toast.notify('Log updated successfully', {
                        duration: 2000,
                        position: 'top-right',
                    });
                    setActivetab(objLog.subType)
                    setLogloader(false)
                    setShowActivity(false)
                    setSendloading(false)
                })
        }
        else {
            firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set(objLog, { merge: true })
                .then(snapshot => {
                    objLog.startDate = objLog.addedDate;
                    // dispatch({
                    //     type: updatelog ? "UPDATELOG" : "SAVELOG",
                    //     data: objLog
                    // });

                    //update enquiry modified date if new log is added
                    if (!_.isEmpty(objLog.enquiryID)) {
                        let _objEq = {
                            modifiedBy: localStorage.uid,
                            modifiedFrom: 'web',
                            modifiedDate: window.firebase.firestore.Timestamp.now(),
                        }
                        if (objLog.subType !== 'note') _objEq.unactionedNotify = enqUnActionStatus.COMPLETED;
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set(_objEq, { merge: true })
                    }

                    if ((props?.contact?.phone || props?.enquiry?.contact?.phone || props?.tradeinPro?.contact?.phone || props?.service?.contact?.phone) && objLog.type === CommonHelper.getActivityType(_typeLog, _originType) && objLog.subType === 'message' && (messageMediaEnabled || smsHubEnabled || podiumEnabled || cmdotcomEnabled || ringcentralEnabled || justcallEnabled) && isSend) {
                        try {
                            handleSendToMessageMedia(objLog, _contact, (_enquiry ? _enquiry : _tradeinPro))
                        }
                        catch (error) {
                            console.error(error)
                        }
                    }

                    setActivetab(objLog.subType)
                    setLogloader(false)
                    setShowActivity(false)
                    setSendloading(false)

                    toast.notify('Log updated successfully', {
                        duration: 2000
                    })
                    if (document.getElementById('enquiry-item-' + objLog.documentID)) {
                        setTimeout(function () {
                            document.getElementById('enquiry-item-' + objLog.documentID).scrollIntoView(
                                {
                                    behavior: "smooth",
                                    block: "center"
                                });
                        }, 1500);
                    }
                })
                .catch(error => {
                    setLogloader(false)
                    setSendloading(false)
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }, [])

    const handleSendToMessageMedia = (objLog, _objcontact, _objenquiry) => {
        var objData = Object.assign({}, {
            "clientID": objLog.clientID,
            "activityID": objLog.documentID,
            "text": objLog.notes,
            "owner": objLog.owner,
            "projectId": props.dealersettings?.client?.projectId || null
        });


        if (!_.isEmpty(_objcontact)) {
            let _contact = Object.assign({}, _objcontact);
            objData.phone = _contact.phone ? _contact.phone : '';
            if (podiumEnabled) {
                objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
                objData.contactName = CommonHelper.displayContactName([], _objcontact)
                objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
            }
            if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
                objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
            }
        }
        else if (!_.isEmpty(_objenquiry.contact)) {
            let _contact = Object.assign({}, _objenquiry.contact);
            objData.phone = _contact.phone ? _contact.phone : '';
            if (podiumEnabled) {
                objData.phone = _contact.phone && _contact.phoneCode ? `${_contact.phoneCode}${_contact.phone}` : _contact.phone ? _contact.phone : '';
                objData.contactName = CommonHelper.displayContactName([], _contact)
                objData.senderName = props?.dealersettings?.name ? props.dealersettings.name : ''
            }
            if (cmdotcomEnabled || ringcentralEnabled || justcallEnabled) {
                objData.phoneCode = _contact.phoneCode ? _contact.phoneCode : '';
            }
        }
        if (objLog?.files?.length > 0) {
            objData.mms = objLog.files[0].url
        }
        if (justcallEnabled) {
            objData.email = props?.dealersettings?.email || null;
        }
        if (props?.dealersettings?.client?.integrations?.filter(e => e.type === "justcall")?.[0]?.settings) {
            objData = {
                ...objData,
                ...props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].settings,
                sourceNumber: objLog.sourceNumber || null
            }
        }
        //   console.log('handleSendToMessageMedia', objData);
        const messagemediasend = window.firebase.functions().httpsCallable(`${messageMediaEnabled ? 'messagemedia' : smsHubEnabled ? 'smshub' : cmdotcomEnabled ? 'cmdotcom' : ringcentralEnabled ? 'ringcentral' : justcallEnabled ? 'justcall' : 'podium'}-send`);
        messagemediasend(objData).then((_data) => {
            console.log('messagemediasend', _data);
        });

    }

    const handleReplylog = useCallback((e, objLog) => {
        e.preventDefault();
        setActivetab('message');//
        setShowActivity(true);
        if (document.getElementById('activity-nav-new')) {
            document.getElementById('activity-nav-new').scrollIntoView(
                {
                    behavior: "smooth",
                    block: "center"
                });
        }
    });

    const handleCompleteContinue = (isDone, objLog, activityOutcome) => {
        // check mandActivityNotes
        if (CommonHelper.isValidActivityNotesCheck(objLog, isDone, props.dealersettings) === false) {
            handleEditactivity(null, { ...objLog, 'isDone': isDone, 'isFromComplete': true });
            return;
        }

        delete objLog.searchUsers;

        objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        objLog.modifiedBy = localStorage.uid;
        if (activityOutcome) {
            objLog.activityOutcome = activityOutcome
        }
        if (!activityOutcome && isDone === false && objLog.isDone === true) {
            objLog.activityOutcome = null
        }

        objLog.isDone = isDone

        // var checkboxDOM = document.getElementById('success-' + objLog.documentID);
        // checkboxDOM.checked = e.target.checked;
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
            .set(objLog, { merge: true })
            .then(snapshot => {

                dispatch({
                    type: "UPDATELOG",
                    data: objLog
                });
                toast.notify('Log updated successfully', {
                    duration: 2000
                })
                //update enquiry modified date if activity is mark as done
                if (!_.isEmpty(objLog.enquiryID) && objLog.isDone === true) {
                    let objUpdate = {
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        unactionedNotify: enqUnActionStatus.COMPLETED,
                    }
                    // if (objLog.inbound === true) {
                    //     objUpdate = {
                    //         ...objUpdate,
                    //         bdcStatus: 'show'
                    //     }
                    // }
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set(objUpdate, { merge: true })
                }
                // if (document.getElementById('enquiry-item-' + objLog.documentID)) {
                //     setTimeout(function () {
                //         document.getElementById('enquiry-item-' + objLog.documentID).scrollIntoView(
                //             {
                //                 behavior: "smooth",
                //                 block: "center"
                //             });
                //     }, 1500);
                // }
                if (objLog.isDone === true) {
                    if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                        Swal.fire({
                            title: CommonHelper.showLocale(props, 'Please select'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                            cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                .set({
                                    bdcStatus: result.value ? 'show' : 'noshow'
                                }, { merge: true })
                            shedulenextactivity(objLog);
                            if (result.value)
                                logWalkin(objLog.enquiryID)

                            CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), _typeLog, 'note', null, props.dealersettings);
                        });
                    }
                    else {
                        shedulenextactivity(objLog);
                    }
                }
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const handleComplete = useCallback((e, objLog) => {
        const isDone = e.target.checked
        const { dealersettings } = props
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });
            // console.log('activtyOutcomes', activtyOutcomes)
            // console.log('isMandatory', isMandatory)
            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { isDone, objLog }
            });
        } else {
            handleCompleteContinue(isDone, objLog);
        }
    }, [])

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        //console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                handleCompleteContinue(data.isDone, data.objLog,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }


    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }
    }

    const handleDeleteactivity = useCallback((e, objLog) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                delete objLog.searchUsers;
                objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
                objLog.modifiedBy = localStorage.uid;
                objLog.isDeleted = true;
                firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    isDeleted: true
                }, { merge: true })
                    .then(snapshot => {
                        dispatch({
                            type: "REMOVELOG",
                            data: objLog
                        });
                        toast.notify('Log deleted successfully', {
                            duration: 2000
                        })

                        let logNotes = 'Log deleted';
                        if (!_.isEmpty(objLog) && objLog.type)
                            logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'activities',
                            subType: 'delete',
                            recordId: objLog.documentID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);

                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })

    }, [])

    const handleEditactivity = useCallback(async (e, objLog, cTab) => {
        e && e.preventDefault();
        if (objLog.type === CommonHelper.getActivityType(_typeLog, _originType)) {
            dispatch({
                type: "EDITLOG",
                data: objLog
            });
        }
        else {
            if (objLog.stockID) {
                if (!_.isEmpty(objLog.stock)) {
                    setStockObj(objLog.stock);
                } else {
                    const stockDoc = await window.firebase
                        .firestore()
                        .doc('stock/' + objLog.stockID)
                        .get();
                    if (stockDoc.exists) {
                        const stockData = stockDoc.data();
                        stockData.documentID = stockDoc.id;
                        setStockObj(stockData);
                    }
                }
            }
            if (objLog.contactID) {
                if (!_.isEmpty(objLog.contact)) {
                    setConatactObj(objLog.contact);
                } else {
                    const contactDoc = await firestoreDB(props.dealersettings).firestore().doc('contactDetails/' + objLog.contactID).get();
                    if (contactDoc.exists) {
                        const contactData = contactDoc.data();
                        contactData.documentID = contactDoc.id;
                        setConatactObj(contactData);
                    }
                }
            }
            if (objLog.enquiryID) {
                if (!_.isEmpty(objLog.enquiry)) {
                    setEnquiryObj(objLog.enquiry);
                } else {
                    const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('enquiries/' + objLog.enquiryID)
                        .get();
                    if (enquiryDoc.exists) {
                        const enquiryData = enquiryDoc.data();
                        enquiryData.documentID = enquiryDoc.id;
                        setEnquiryObj(enquiryData);
                    }
                }
            }
            if (objLog.tradeinProID) {
                if (!_.isEmpty(objLog.tradeinPro)) {
                    setTradeinProObj(objLog.tradeinPro);
                } else {
                    const enquiryDoc = await firestoreDB(props.dealersettings).firestore()
                        .doc('tradeinPro/' + objLog.tradeinProID)
                        .get();
                    if (enquiryDoc.exists) {
                        const enquiryData = enquiryDoc.data();
                        enquiryData.documentID = enquiryDoc.id;
                        setTradeinProObj(enquiryData);
                    }
                }
            }
            if (objLog.serviceID) {
                if (!_.isEmpty(objLog.service)) {
                    setServiceObj(objLog.service);
                } else {
                    const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('serviceJobs/' + objLog.serviceID)
                        .get();
                    if (enquiryDoc.exists) {
                        const enquiryData = enquiryDoc.data();
                        enquiryData.documentID = enquiryDoc.id;
                        setServiceObj(enquiryData);
                    }
                }
            }
            setNewActivity(false)
            setActivityHeader('Edit Activity')
            setNewActivitylog(objLog)
            setShowActivityModel(true)
        }
    }, [])

    const handleActivityModelDelete = (objLog) => {
        setShowActivityModel(false)
        dispatch({
            type: "REMOVELOG",
            data: objLog
        });
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setStockObj(null)
        setEnquiryObj(null)
        setTradeinProObj(null)
        setConatactObj(null);
        setShowActivityModel(false)
        if (objLog) {
            // dispatch({
            //     type: activitylogs.some(e => e.documentID === objLog.documentID) ? "UPDATELOG" : "SAVELOG",
            //     data: objLog
            // });
            if (document.getElementById('enquiry-item-' + objLog.documentID)) {
                setTimeout(function () {
                    document.getElementById('enquiry-item-' + objLog.documentID).scrollIntoView(
                        {
                            behavior: "smooth",
                            block: "center"
                        });
                }, 1500);
            }

            if (objLog.isDone === true && !isActivtyAlreadyDone) {
                if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, 'Please select'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set({
                                bdcStatus: result.value ? 'show' : 'noshow'
                            }, { merge: true })
                        shedulenextactivity(objLog);
                        if (result.value)
                            logWalkin(objLog.enquiryID)

                        CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), _typeLog, 'note', null, props.dealersettings);
                    });
                }
                else {
                    shedulenextactivity(objLog);
                }
            }
        }
    }

    const handleScheduledActivityModelClose = (objLog) => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null
        })

        if (objLog) {
            // dispatch({
            //     type: "CHECKLOG",
            //     data: objLog,
            //     activeTab: objLog.isDone ? 'Completed' :
            //         objLog.startDate.seconds < parseInt(moment().startOf('day').format('X')) ? 'Overdue' :
            //             objLog.startDate.seconds > parseInt(moment().endOf('day').format('X')) ? 'Upcoming' :
            //                 'Today'
            // });
            if (objLog && document.getElementById('activity-' + objLog.documentID)) {
                setTimeout(function () {
                    document.getElementById('activity-' + objLog.documentID).scrollIntoView(
                        {
                            behavior: "smooth",
                            block: "center"
                        });
                }, 1000);
            }
        }
    }

    const activitylogList = useMemo(() => activitylogs
        .filter(
            ({ type, subType, isDone, startDate }) => ((type === CommonHelper.getActivityType(_typeActivity, _originType) || subType === 'edms') && isDone === false &&
                (activeScheduledTab === 'Overdue' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate < CommonHelper.getBaseDate()) :
                    activeScheduledTab === 'Today' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate > CommonHelper.getBaseDate() && startDate < CommonHelper.getEndDate()) :
                        activeScheduledTab === 'Upcoming' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate > CommonHelper.getEndDate()) :
                            activeScheduledTab === 'EDMs' ? (type === CommonHelper.getActivityType(_typeLog, _originType) && subType === 'edms') :
                                true
                ))
        )
        .sort(function (x, y) {
            return (x.startDate.seconds < y.startDate.seconds ? -1 : 1);
        }), [activitylogs, activeScheduledTab])

    const activitylogSorted = loadingActivity
        ? []
        : activitylogList;

    const alllogsList = useMemo(() =>

        activitylogs
            .filter(
                ({ type, isDone, subType, notes }) => isDone === true && (completedTab.type === 'all' ?
                    (subType !== 'log') : (completedTab.type === 'allnotes' ?
                        ((['note', 'call'].includes(subType) || [CommonHelper.getActivityType(_typeActivity, _originType)].includes(type)) && !_.isEmpty(notes)) :
                        ((CommonHelper.getActivityType(completedTab.type) === CommonHelper.getActivityType(_typeLog, _originType) ? true : isDone === true) && type === CommonHelper.getActivityType(completedTab.type) && (completedTab.subtype ? subType === completedTab.subtype : true)))
                )
            )
            .sort(function (x, y) {
                return (x.startDate.seconds > y.startDate.seconds ? -1 : 1);
            }), [activitylogs, completedTab])

    const alllogsSorted = loadingActivity
        ? []
        : alllogsList;

    const checklength = (ftype, fsubtype) => {
        return fsubtype === 'log' && ftype === 'log' ? 0 : activitylogs
            .filter(
                ({ type, isDone, subType, notes }) => isDone === true && (ftype === 'all' ?
                    (subType !== 'log') : (ftype === 'allnotes' ?
                        ((['note', 'call'].includes(subType) || [CommonHelper.getActivityType(_typeActivity, _originType)].includes(type)) && !_.isEmpty(notes)) :
                        ((CommonHelper.getActivityType(ftype, _originType) === CommonHelper.getActivityType(_typeLog, _originType) ? true : isDone === true) && type === CommonHelper.getActivityType(ftype, _originType) && (fsubtype ? subType === fsubtype : true)))
                )
            ).length
    }

    const scheduleActvitiescount = (ftype) => {
        return activitylogs
            .filter(
                ({ type, isDone, subType, startDate }) => ((type === CommonHelper.getActivityType(_typeActivity, _originType) || subType === 'edms') && isDone === false &&
                    (ftype === 'Overdue' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate < CommonHelper.getBaseDate()) :
                        ftype === 'Today' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate > CommonHelper.getBaseDate() && startDate < CommonHelper.getEndDate()) :
                            ftype === 'Upcoming' ? (type === CommonHelper.getActivityType(_typeActivity, _originType) && startDate > CommonHelper.getEndDate()) :
                                ftype === 'EDMs' ? (type === CommonHelper.getActivityType(_typeLog, _originType) && subType === 'edms') :
                                    true
                    ))
            ).length
    }


    const handlePreviewEmail = useCallback((emailLog, id, token) => {
        setEmaillog({
            ...emailLog,
            documentID: id,
            token: token
        })
        setActivityHeader(emailLog.subject)
        setPreviewModel(true)
    }, [])

    const handleModelClose = () => {
        setEmaillog(null)
        setPreviewModel(false)
        setEDMsPreviewModel(false)
    }

    const handleUnlickEmail = useCallback((e, objLog) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to unlink.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                firestoreDB(props.dealersettings).firestore().doc(`enquiryEmails/${objLog.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Email unlink successfully.', {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })

    }, [])

    const handleEDMsPreviewEmail = useCallback((e, objLog) => {
        setActivityHeader(objLog.title)
        setEmaillog({
            ...objLog,
            ['bodyHTML']: objLog.email && objLog.email.body ? objLog.email.body : ''
        })
        setEDMsPreviewModel(true)
    }, [])

    const handleComposeEmail = () => {
        if (_.isEmpty(props.dealersettings.nylas)) {
            Swal.fire({
                title: CommonHelper.showLocale(props, 'Please configure email settings'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Add Settings'),
                cancelButtonText: CommonHelper.showLocale(props, 'Later'),
            }).then(result => {
                if (result.value) {
                    props.history.push('/settings/profile?key=emailsettings');
                }
            });
        }
        else {
            setShowComposeModal({
                show: true,
                type: 'compose',
                title: 'New Message'
            })
        }

    }

    const handleCloseComposeModal = () => {
        setShowComposeModal({
            show: false,
            type: '',
            title: ''
        })
    }

    const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
        const { mailTo, mailCc, mailBcc, subject, email } = trigger;
        setSendLoader(true);

        if (mailTo === '' && mailCc === '' && mailBcc === '') {
            toast.notify('Please specify at least one recipient.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (subject === '') {
            toast.notify('Please write subject.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        if (email === '' || email === '<p></p>\n') {
            toast.notify('Please write in email body.', {
                duration: 2000,
            });
            setSendLoader(false);
            return;
        }

        try {
            let emailObj = {
                token: props.dealersettings.nylasAccessToken,
                draft: {
                    subject: trigger.subject,
                    body: trigger.email,
                },
            };

            if (trigger.mailTo !== '') {
                emailObj.draft.to = trigger.mailTo.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailCc !== '') {
                emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.mailBcc !== '') {
                emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
                    return {
                        name: '',
                        email: email,
                    };
                });
            }

            if (trigger.files.length > 0) {
                emailObj.files = trigger.files;
            }

            setTimeout(() => {
                toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> sending...</div>),
                    {
                        position: 'bottom-left',
                        duration: null
                    },
                )
                setShowComposeModal({
                    ...showComposeModal,
                    show: false,
                    type: '',
                    title: '',
                    emailData: null,
                });
            }, 300);

            // console.log('emailObj', emailObj);
            const nylasSendEmail = window.firebase
                .functions()
                .httpsCallable('nylas-sendEmail');
            const resp = await nylasSendEmail(emailObj);

            if (resp.data.success) {
                linkEnqruiy(resp.data)
                setSendLoader(false);
            } else {
                setSendLoader(false);
                toast.closeAll()
                if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, `the accounts credentials are out of date`),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Reauthenticate'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Later'),
                    }).then(result => {
                        if (result.value) {
                            window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
                                nylas: null,
                            }, { merge: true });
                            props.history.push('/settings/profile?key=emailsettings');
                        }
                    });
                    return
                }
                else {
                    Swal.fire(CommonHelper.showLocale(props, 'There was an error in sending email.'), '', 'error');
                }
            }

            // console.log('response', resp);
        } catch (error) {
            toast.closeAll()
            Swal.fire(CommonHelper.showLocale(props, 'There was an error in sending email.'), '', 'error');

            setSendLoader(false);
            console.log(error);
        }
    };

    const linkEnqruiy = (data) => {

        var id = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc().id;
        const linkref = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id);

        const model = {
            documentID: id,
            clientID: props.dealersettings.client.documentID,
            thread: data.thread,
            token: props.dealersettings.nylasAccessToken,
            linkedBy: localStorage.uid,
            linkedDate: window.firebase.firestore.Timestamp.now(),
            type: _typeLog,
            projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
        };

        if (!_.isEmpty(props.enquiry)) {
            model.enquiryID = props.enquiry.documentID;
            model.enquiry = props.enquiry;
            if (!_.isEmpty(props.enquiry.contact)) {
                model.contactID = props.enquiry.contact.documentID;
                model.contact = props.enquiry.contact;
            }
        }
        else if (!_.isEmpty(props.contact)) {
            model.contactID = props.contact.documentID;
            model.contact = props.contact;
        }

        linkref.set(model, {
            merge: true,
        });

        if (!_.isEmpty(model.enquiryID)) {
            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${model.enquiryID}`)
                .set({
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    unactionedNotify: enqUnActionStatus.COMPLETED,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                }, { merge: true })
        }

        if (!_.isEmpty(data.data)) {
            let messageRef = firestoreDB(props.dealersettings).firestore().collection('enquiryEmails').doc(id).collection('messages').doc(data.data.id)
            messageRef.set({
                ...data.data,
                projectId: props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null
            })
        }
        toast.closeAll()
        toast.notify('Email sent successfully.', {
            duration: 2000,
        });
    }


    const updateActivity = (e, objLog) => {
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).update(objLog, { merge: true }).then(snapshot => {
            dispatch({
                type: "UPDATELOG",
                data: objLog
            });
            toast.notify('Log updated successfully', {
                duration: 2000
            });
        })
    }

    let _excludedList = ['note']
    //_excludedList = props?.dealersettings?.client?.gdpr?.enabled ? _excludedList : [..._excludedList, 'gdpr']

    return (
        <>
            <div className="mid-column-finance">
                {
                    props.companyView === true ? (<></>) : (<div className="mid-column-wrap">
                        <div className="activity-nav" id="activity-nav-new">
                            <Tab.Container activeKey={activeTab} >
                                <Nav as="ul" bsPrefix="ul-nav"
                                    onSelect={(k) => setActivetab(k)}>
                                    {
                                        enquiryActivities.map((rec, index) => {
                                            return <Nav.Item as="li" bsPrefix="li-nav" key={index}>
                                                <Nav.Link eventKey={rec.value} bsPrefix="li-a-nav">{<><i className={rec.ico}></i>
                                                    <Translate text={(rec.value === 'message' && (props?.contact?.phone || props?.enquiry?.contact?.phone || props?.tradeinPro?.contact?.phone || props?.service?.contact?.phone) && (messageMediaEnabled || smsHubEnabled || podiumEnabled || ringcentralEnabled || justcallEnabled)) ? 'sendMessage' : rec.name} />
                                                </>}
                                                </Nav.Link>
                                            </Nav.Item>
                                        })
                                    }
                                </Nav>

                            </Tab.Container>
                        </div>
                        {
                            showActivity ? (
                                activeTab === "activity" ? (
                                    <div className="activity-nav-subinput"
                                        onClick={() => {
                                            activitypanelOpen()
                                        }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>

                                ) : activeTab === "call" && aircallEnabled ? (
                                    <div className="activity-nav-subinput"
                                        onClick={() => {
                                            const _contact =
                                                !_.isEmpty(props.enquiry)
                                                    ? props.enquiry.contact
                                                    : !_.isEmpty(props.tradeinPro)
                                                        ? props.tradeinPro.contact
                                                        : !_.isEmpty(props.service)
                                                            ? props.service.contact
                                                            : !_.isEmpty(props.contact)
                                                                ? props.contact
                                                                : null
                                            if (_contact?.phone) {
                                                props.dialNumber(CommonHelper.validPhone(_contact?.phone, _contact?.phoneCode), props?.enquiry?.documentID, _contact?.documentID, _typeLog)
                                            } else {
                                                Swal.fire(CommonHelper.showLocale(props, 'Please add phone number to log a call'), '', 'info');
                                            }
                                        }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>

                                ) : (activeTab === "note" || activeTab === "call" || activeTab === "message") ? (
                                    <div className="activity-nav-editmode pl-3 pr-3">
                                        <CustomerEditLog
                                            handlechangelog={handlechangelog}
                                            handlesavelog={handlesavelog}
                                            handlecancellog={handlecancellog}
                                            customerlog={newActivitylog}
                                            textareaRef={textareaRef}
                                            handleDateChangelog={handleDateChangelog}
                                            loading={logLoader}
                                            dealersettings={props.dealersettings}
                                            messageMediaEnabled={messageMediaEnabled}
                                            ringcentralEnabled={ringcentralEnabled}
                                            justcallEnabled={justcallEnabled}
                                            smsHubEnabled={smsHubEnabled}
                                            cmdotcomEnabled={cmdotcomEnabled}
                                            podiumEnabled={podiumEnabled}
                                            sendLoading={sendLoading}
                                            contact={props.contact}
                                            enquiry={props.enquiry}
                                            tradeinPro={props.tradeinPro}
                                            service={props.service}
                                            shouldFocusOnMount={true}
                                            handleRadioChange={handleRadioChange}
                                            originType={_originType}
                                            handleFileChange={handleFileChange}
                                        />
                                    </div>
                                ) : (activeTab === "call" && aircallEnabled) ? (
                                    <div className="activity-nav-subinput"
                                        onClick={() => {
                                            const _contact =
                                                !_.isEmpty(props.enquiry)
                                                    ? props.enquiry.contact
                                                    : !_.isEmpty(props.tradeinPro)
                                                        ? props.tradeinPro.contact
                                                        : !_.isEmpty(props.service)
                                                            ? props.service.contact
                                                            : !_.isEmpty(props.contact)
                                                                ? props.contact
                                                                : null
                                            if (_contact?.phone) {
                                                props.dialNumber(CommonHelper.validPhone(_contact?.phone, _contact?.phoneCode), props?.enquiry?.documentID, _contact?.documentID, _typeLog)
                                            } else {
                                                Swal.fire(CommonHelper.showLocale(props, 'Please add phone number to log a call'), '', 'info');
                                            }
                                        }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                                ) : (activeTab === "email") ? (
                                    <div className="activity-nav-subinput"
                                        onClick={() => {
                                            handleComposeEmail()
                                        }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                                ) : (
                                    <div className="activity-nav-subinput"><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                                )
                            ) : (activeTab === "email") ? (
                                <div className="activity-nav-subinput"
                                    onClick={() => {
                                        handleComposeEmail()
                                    }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                            ) : (
                                <div className="activity-nav-subinput"
                                    onClick={() => {

                                        activitypanelOpen()

                                    }}><span className="activity-edit-label"><Translate text={enquiryActivities.filter(e => e.value === activeTab)[0].emptymessage} /></span></div>
                            )
                        }
                    </div>
                    )
                }
                {
                    loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                        <div className={`enquiry-flow mb-3 ${props.companyView === true ? '' : 'mt-3'}`}>
                            <div className="enquiry-flow-section">
                                <span className="badge badge-pill badge-scheduled"><Translate text={'scheduled'} /></span>
                                {
                                    activitylogs.filter(e => (e.type === CommonHelper.getActivityType(_typeActivity, _originType) || e.subType === 'edms') && e.isDone === false).length > 0 ? (
                                        <div className="enquiry-flow-nav">
                                            <ul>
                                                {
                                                    acitivtyScheduledTabs.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-completed-tabs nav-li-${rec} ${(activeScheduledTab === rec) ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveScheduledTab(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            ><Translate text={rec.toLowerCase()} />
                                                                {
                                                                    scheduleActvitiescount(rec) > 0 ? (
                                                                        <span className="count">({scheduleActvitiescount(rec)})</span>
                                                                    ) : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    ) : (<></>)
                                }
                                <div className="enquiry-flow-wrap mt-4 mb-4 border-bottom pb-3">
                                    <div className="flow-timelinebar"></div>
                                    {
                                        activitylogs.filter(e => (e.type === CommonHelper.getActivityType(_typeActivity, _originType) || e.subType === 'edms') && e.isDone === false).length > 0 ? (
                                            activitylogSorted.length > 0 ? (
                                                activitylogSorted.map((log, index) => {
                                                    return <ActivityTag
                                                        key={index}
                                                        title={log.title}
                                                        activityDate={log.startDate}
                                                        id={log.documentID}
                                                        type={log.subType}
                                                        addedBy={log.owner ? CommonHelper.getUserNamebyId(_allUser, log.owner) : log.addedByName}
                                                        modifiedBy={log.modifiedBy ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.modifiedBy) : log.addedByName}
                                                        handleEditactivity={handleEditactivity}
                                                        handleComplete={handleComplete}
                                                        completed={log.isDone}
                                                        activitylog={log}
                                                        handleDeleteactivity={handleDeleteactivity}
                                                        scheduled={true}
                                                        isEditable={log.isEditable}
                                                        callDuration={log.callDuration}
                                                        callStatus={log.callStatus}
                                                        handlechangelog={handlechangelog}
                                                        handlesavelog={handlesavelog}
                                                        handlecancellog={handlecancellog}
                                                        textareaRef={textareaRef}
                                                        handleDateChangelog={handleDateChangelog}
                                                        completedTab={completedTab}
                                                        logLoader={logLoader}
                                                        dealersettings={props.dealersettings}
                                                        handleUnlickEmail={handleUnlickEmail}
                                                        handleEDMsPreviewEmail={handleEDMsPreviewEmail}
                                                        handleReplylog={handleReplylog}
                                                        contact={props.contact}
                                                        enquiry={props.enquiry}
                                                        tradeinPro={props.tradeinPro}
                                                        tipOffers={props.tipOffers}
                                                        service={props.service}
                                                        companyView={props.companyView}
                                                        history={props.history}
                                                        originType={_originType}
                                                        updateActivity={updateActivity}
                                                    >
                                                    </ActivityTag>
                                                })
                                            ) : (<></>)
                                        ) : (
                                            <div className="text-center p-5">
                                                <p><Translate text={'You have no scheduled activities'} /></p>
                                                {
                                                    props.companyView === true ? (<></>) : (<button className="btn btn-sm btn-default" onClick={() => {
                                                        scheduleactivitypanelOpen();
                                                    }}><i className="ico icon-add mr-1"></i>
                                                        <Translate text={'Schedule an activity'} />
                                                    </button>)
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <span className="badge badge-pill badge-completed"><Translate text={'Completed'} /></span>

                                <div className="enquiry-flow-nav">
                                    {/* <Nav as="ul" bsPrefix="ul-nav"
                                            activeKey={completedTab.subtype}
                                            onSelect={(key) => {
                                                var sKey = enquirylogscompleted.filter(e => e.value === key)[0]
                                                setCompltedtab({
                                                    type: sKey.type,
                                                    subtype: sKey.subType
                                                })
                                            }}>
                                            {
                                                enquirylogscompleted.map((rec, index) => {
                                                    return <Nav.Item as="li" bsPrefix="li-nav" key={index}>
                                                        <Nav.Link eventKey={rec.value} bsPrefix="li-a-nav" className={`nav-${rec.value}`}>{rec.name}
                                                            {
                                                                checklength(rec.type, rec.subType) > 0 ? (
                                                                    <span className="count">{checklength(rec.type, rec.subType)}</span>
                                                                ) : (<></>)
                                                            }
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                })
                                            }
                                        </Nav> */}
                                    <ul>
                                        {
                                            enquirylogscompleted.filter(a => !_excludedList.includes(a.value)).map((rec, index) => {
                                                return <li key={index}>
                                                    <a href="#"
                                                        id={rec.value}
                                                        className={`nav-completed-tabs nav-li-${rec.value} ${(completedTab.subtype === rec.value || (completedTab.type === rec.value && rec.value === CommonHelper.getActivityType(_typeActivity, _originType))) ? 'active' : ''}`}
                                                        onClick={(event) => {
                                                            var sKey = enquirylogscompleted.filter(e => e.value === event.target.id)[0]
                                                            if (sKey) {
                                                                setCompltedtab({
                                                                    type: CommonHelper.getActivityType(sKey.type, _originType),
                                                                    subtype: sKey.subType
                                                                })
                                                            }
                                                            event.preventDefault();
                                                        }}
                                                    ><Translate text={rec.name} />
                                                        {
                                                            checklength(rec.type, rec.subType) > 0 ? (
                                                                <span className="count">({checklength(rec.type, rec.subType)})</span>
                                                            ) : (<></>)
                                                        }
                                                    </a>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="enquiry-flow-wrap mt-4 ">
                                    <div className="flow-timelinebar"></div>
                                    {
                                        alllogsSorted.map((log, index) => {
                                            return <ActivityTag
                                                key={index}
                                                title={log.title}
                                                notes={log.notes}
                                                activityDate={log.type === CommonHelper.getActivityType(_typeLog, _originType) ? log.addedDate : log.startDate}
                                                id={log.documentID}
                                                type={log.subType}
                                                addedBy={log.owner ? CommonHelper.getUserNamebyId(_allUser, log.owner) : log.addedByName}
                                                modifiedBy={log.modifiedBy ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.modifiedBy) : log.addedByName}
                                                handleEditactivity={handleEditactivity}
                                                handleComplete={handleComplete}
                                                completed={log.isDone}
                                                activitylog={log}
                                                handleDeleteactivity={handleDeleteactivity}
                                                scheduled={false}
                                                isEditable={log.isEditable}
                                                callDuration={log.callDuration}
                                                callStatus={log.callStatus}
                                                handlechangelog={handlechangelog}
                                                handlesavelog={handlesavelog}
                                                handlecancellog={handlecancellog}
                                                textareaRef={textareaRef}
                                                handleDateChangelog={handleDateChangelog}
                                                completedTab={completedTab}
                                                dealersettings={props.dealersettings}
                                                handlePreviewEmail={handlePreviewEmail}
                                                handleUnlickEmail={handleUnlickEmail}
                                                handleEDMsPreviewEmail={handleEDMsPreviewEmail}
                                                //titles={titles}
                                                languages={languages}
                                                countries={countries}
                                                states={states}
                                                //origins={origins}
                                                //enquiryTypes={enquiryTypes}
                                                //leadSources={leadSources}
                                                //campaigns={campaigns}
                                                financeStatuses={financeStatuses}
                                                //lostReasons={lostReasons}
                                                lostSubReasons={lostSubReasons}
                                                pipelines={pipelines}
                                                //enquiryOptionsDF={enquiryOptionsDF}
                                                nationalities={nationalities}
                                                //genders={genders}
                                                //marital_status={marital_status}
                                                //license_type={license_type}
                                                //interested={interested}
                                                //contact_method={contact_method}
                                                //company_type={company_type}
                                                stages={stages}
                                                clientUsers={props.clientUsers}
                                                groupUsers={props.groupUsers}
                                                handleReplylog={handleReplylog}
                                                contact={props.contact}
                                                enquiry={props.enquiry}
                                                companyView={props.companyView}
                                                history={props.history}
                                                originType={_originType}
                                                updateActivity={updateActivity}
                                            >
                                            </ActivityTag>
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={newActivitylog}
                    enquiry={
                        !_.isEmpty(props.enquiryBasicVM) ? props.enquiryBasicVM : enquiryObj
                    }
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : contactObj
                    }
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                    isNewEnquiry={props.isNewEnquiry}
                    isFromLayout={props.isFromLayout}
                    originType={_originType}
                ></AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    randomData={showScheduleActivityModel.activityLog}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : !_.isEmpty(props.enquiryBasicVM) ? props.enquiryBasicVM : enquiryObj}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : !_.isEmpty(props.enquiry)
                        ? props.enquiry.contact
                        : !_.isEmpty(props.contactBasicVM)
                            ? props.contactBasicVM
                            : null
                    }
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    // activeTab={activeTab}
                    isScheduleActivity={true}
                    isFromLayout={props.isFromLayout}
                    originType={_originType}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                    originType={_originType}
                />
            </PopUpModal>
            <PopUpModal show={showPreviewModel}>
                <EmailPreview
                    show={showPreviewModel}
                    handleClose={handleModelClose}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    emailLog={emailLog}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : contactObj
                    }
                    history={props.history}
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                />
            </PopUpModal>
            <PopUpModal show={showEDMsPreviewModel}>
                <TemplatePreview
                    {...props}
                    show={showEDMsPreviewModel}
                    title={activityHeader}
                    handleClose={handleModelClose}
                    template={emailLog}
                    originType={_originType}
                />
            </PopUpModal>
            <PopUpModal show={showComposeModal.show}>
                <ComposeEmail
                    show={showComposeModal.show}
                    handleClose={handleCloseComposeModal}
                    token={props.dealersettings.nylasAccessToken}
                    type={showComposeModal.type}
                    title={showComposeModal.title}
                    emailData={showComposeModal.emailData}
                    dealersettings={props.dealersettings}
                    sendLoader={sendLoader}
                    setSendLoader={setSendLoader}
                    handleSend={handleSend}
                    structureType={!_.isEmpty(props.dealersettings.nylasAccountData) ? props.dealersettings.nylasAccountData.organization_unit + 's' : ''}
                    emailtemplates={emailTemplates}
                    contact={
                        !_.isEmpty(props.enquiry)
                            ? props.enquiry.contact
                            : !_.isEmpty(props.contactBasicVM)
                                ? props.contactBasicVM
                                : contactObj
                    }
                    enquiry={!_.isEmpty(props.enquiry) ? props.enquiry : null}
                    userData={{ emailSignature: props.dealersettings.emailSignature }}
                ></ComposeEmail>
            </PopUpModal>
        </>
    )
}

export default ActivityDynamic