/** LIBRARIES */
import React, {  } from 'react';
import moment from 'moment'
import Translate from '../../constants/translate';
   
const EventInfo = ({
	event,
  handleEventOpen
}) => {
	const {
		documentID,
		title,
		location,
		eventDate,
		checkinDate,
		isCheckIn,
		invitationSent,
	} = event;

  const eventDateStart = moment(eventDate, "YYYY-MM-DD hh:mm A").format('ddd, DD MMM, hh:mm A')
  const checkinDateConverted = checkinDate ? moment(checkinDate).format('ddd, DD MMM, hh:mm A') : ''
  const eventDay = moment(eventDate, "YYYY-MM-DD hh:mm A").format('DD')
  const eventMonth = moment(eventDate, "YYYY-MM-DD hh:mm A").format('MMM')

	return (
		<div
			className='event-app-item flex-event-app'
			id={'info_' + documentID}
			onClick={e => {
				e.preventDefault();
        handleEventOpen(event)
			}}
		>
			<div className='event-app-item-date'>
				<div className='event-app-item-subdate'>{eventDay}</div>
				<div className='event-app-item-submonth'>{eventMonth}</div>
			</div>
			<div className='event-app-item-data'>
				<div className='event-app-item-title'>{title ? title : ''}</div>

				<div className='event-app-item-location'>{location ? location : ''}</div>
				<div className='event-app-item-info'>
					<i className='ico icon-time'></i> {eventDateStart ? eventDateStart : ''}
				</div>

        {isCheckIn ? (
          <>
          <div className='event-app-item-button d-inline-block w-100'>
            <div className='badge badge-pill badge-event-checkedin'><Translate text={'checkIn'} /></div>
          </div>
          <div className='event-app-item-button d-inline-block w-100'>
            <div className='event-app-item-checkin'> {checkinDateConverted ? checkinDateConverted : ''} </div>
          </div>
          </>
        ) : invitationSent ? (
          <>
            <div className="event-app-item-button d-inline-block w-100">
              <div className="badge badge-pill badge-event-invited"><Translate text={'Invited'} /></div>
            </div>
          </>) : (<></>)}
			</div>
		</div>
	);
};

export default EventInfo;
