import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Modal } from 'react-bootstrap';
import Translate from '../../../constants/translate';
import { AntDateRangePicker, AntDatePicker, ReactSelect } from '../../../components';
import moment from 'moment';
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import images from '../../../images';
import CommonHelper from '../../../services/common';


const AddScheduler = (props) => {
    const [loader, setLoader] = useState(false)
    const [statusDaterange, setstatusDaterange] = useState(props.fleetPro?.statusDaterange || {})
    const [statusBooking, setStatusBooking] = useState(!_.isEmpty(props.statusBooking) ? props.statusBooking : []);
    const [addFields, setAddFields] = useState({ status: '', value: '' })
    const [editFields, setEditFields] = useState()
    const [textEditor, setTexteditor] = useState({})
    const [errorFields, setErrorFields] = useState({});
    const fleetProAllStatus = Object.assign([], props.dealersettings?.client?.fleetPro?.fleetProStatus?.filter(a => a.booking === true))
    let fleetProStatus = fleetProAllStatus ? _.map(fleetProAllStatus, function (c) {
        return {
            ...c,
            value: c.value,
            searchlabel: c.name,
            label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (c.color ? c.color : '#6b717f') }}>{c.name.toUpperCase()}</div>,
            active: _.isBoolean(c.active) ? c.active : true,
        };
    }) : []

    //console.log('fleetProStatus', fleetProAllStatus)
    const handleAddSelectChange = (e, data) => {
        setAddFields({
            ...addFields,
            [data.name]: e ? e.value : '',
            ['value']: uuid()
        })
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleAddDateRangeChange = (val) => {
        if (val) {
            setAddFields({
                ...addFields,
                ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[0]).toDate()),
                ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[1]).toDate())
            });
        } else {
            setAddFields({
                ...addFields,
                ['startDate']: null,
                ['endDate']: null
            })
        }

    }

    const handleEditSelectChange = (e, data) => {
        setEditFields({
            ...editFields,
            [data.name]: e ? e.value : ''
        })
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleEditDateRangeChange = (val) => {
        if (val) {
            setEditFields({
                ...editFields,
                ['startDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[0]).toDate()),
                ['endDate']: window.firebase.firestore.Timestamp.fromDate(moment(val[1]).toDate())
            });
        } else {
            setEditFields({
                ...editFields,
                ['startDate']: null,
                ['endDate']: null
            })
        }

    }

    const handleListSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['status'])) {
            formIsValid = false;
            if (newlist)
                errors['statusname'] = errorClass;
            else
                errors['editstatusname'] = errorClass;
        }
        if (_.isEmpty(rec['startDate'])) {
            formIsValid = false;
            if (newlist)
                errors['startDatevalue'] = errorClass;
            else
                errors['editstartDatevalue'] = errorClass;
        }
        setErrorFields(errors)
        // if (statusBooking.filter(item => item.value !== rec.value).some(item => item.name === rec.name)) {
        //     Swal.fire(CommonHelper.showLocale(props, 'Package with same name already exists'), '', 'error')
        //     formIsValid = false;
        // }
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...statusBooking, rec] : [
            ..._.map(statusBooking, function (obj) {
                return obj.value === rec.value ? { ...rec } : { ...obj };
            })];
        setStatusBooking(addList)
        setAddFields({ status: '', value: '' })
        setEditFields(null)
        setTexteditor({})
    }

    const handleListCancel = () => {
        setEditFields(null)
        setTexteditor({})
    }

    const handleEditList = (rec) => {
        setEditFields(rec)
        setTexteditor({
            [rec.value]: true
        })
    }
    const handleDeleteList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...statusBooking.filter(item => item.value !== rec.value)];
                setStatusBooking(deleteList)
            }
        })
    }

    const handleSave = () => {
        setLoader(true);
        window.firebase.firestore().doc(`/fleetPro/${props.fleetProID}`)
            .set({ statusBooking: statusBooking }, { merge: true })
            .then(() => {
                setLoader(false);
                props.handleClose();
            });
    }

    const disabledDate = (current, name) => {
        const disabledDates = [];

        props.fleetProStatus.filter(a => a.scheduler === true).forEach(rec => {
            if (statusDaterange?.[rec.value]?.startDate && statusDaterange?.[rec.value]?.endDate) {
                for (var m = moment.unix(statusDaterange[rec.value].startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(statusDaterange[rec.value].endDate.seconds).startOf('day')); m.add(1, 'days')) {
                    disabledDates.push(m.format('YYYY-MM-DD'))
                }
            }
        });

        statusBooking.filter(a => name ? a.value !== name : true).forEach(rec => {
            if (rec?.startDate && rec?.endDate) {
                for (var m = moment.unix(rec.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(rec.endDate.seconds).startOf('day')); m.add(1, 'days')) {
                    disabledDates.push(m.format('YYYY-MM-DD'))
                }
            }
        });

        return disabledDates.find(date => date === moment(current).format("YYYY-MM-DD")) || (props?.fleetPro?.defleetDate?.seconds ? current >= moment.unix(props.fleetPro.defleetDate.seconds).startOf('day') : false);
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-product-addon"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className="head-light" width="45%"> <Translate text={'status'} /></th>
                                        <th scope="col" className="head-light" width="45%"> <Translate text={'date'} /></th>
                                        {/* <th scope="col" className="head-light" width="45%">Value</th> */}
                                        <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                    </tr>

                                    <tr>
                                        <td>
                                            <ReactSelect
                                                options={fleetProStatus}
                                                name={"status"}
                                                placeholder={'select status'}
                                                onChange={handleAddSelectChange}
                                                value={addFields.status}
                                                classNamePrefix={`${errorFields["statusname"]} basic-select`}
                                                id={`add-status`}
                                            >
                                            </ReactSelect>
                                        </td>
                                        <td>

                                            <AntDateRangePicker
                                                value={addFields?.startDate ? [moment.unix(addFields.startDate.seconds), moment.unix(addFields.endDate.seconds)] : null}
                                                id={"add-date-range"}
                                                name={`add-date`}
                                                format='DD/MM/YYYY'
                                                onChange={(e) => { handleAddDateRangeChange(e) }}
                                                placeholder='DD/MM/YYYY'
                                                className={`ant-picker-billing ${errorFields["startDatevalue"]}`}
                                                disabledDate={(val) => disabledDate(val)}
                                            />

                                        </td>
                                        <td className="border-right-0">
                                            <div className="button-height-fix">
                                                <button type="button" className="btn btn-primary" onClick={() => { handleListSave(addFields, true) }}> <Translate text={'add'} /></button>
                                            </div>
                                        </td>
                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        statusBooking.length > 0 ?
                                            _.orderBy(statusBooking, ["startDate"], ["desc"]).map((rec, index) => {
                                                return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditList(rec) }}>
                                                    <td >
                                                        {
                                                            textEditor[rec.value] ? (<ReactSelect
                                                                options={fleetProStatus}
                                                                name={"status"}
                                                                placeholder={'select status'}
                                                                onChange={handleEditSelectChange}
                                                                value={editFields.status}
                                                                classNamePrefix={`${errorFields["editstatusname"]} basic-select`}
                                                                id={`edit-status`}
                                                            >
                                                            </ReactSelect>) : (<>{fleetProStatus.filter(enqStatus => enqStatus.value === rec.status).length > 0 ? fleetProStatus.filter(enqStatus => enqStatus.value === rec.status)[0]?.label : ''}</>)
                                                        }
                                                    </td>
                                                    <td >
                                                        {
                                                            textEditor[rec.value] ? (<AntDateRangePicker
                                                                value={editFields?.startDate ? [moment.unix(editFields.startDate.seconds), moment.unix(editFields.endDate.seconds)] : null}
                                                                id={"edit-date-range"}
                                                                name={`edit-date`}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleEditDateRangeChange(e) }}
                                                                placeholder='DD/MM/YYYY'
                                                                className={`ant-picker-billing ${errorFields["editstartDatevalue"]}`}
                                                                disabledDate={(val) => disabledDate(val, editFields.value)}
                                                            />) : (<>{CommonHelper.showCombineDate(rec.startDate, rec.endDate)}</>)
                                                        }
                                                    </td>
                                                    <td className="border-right-0">
                                                        <div className="action-fix">
                                                            {
                                                                textEditor[rec.value] ?
                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleListSave(editFields, false) }}> <i className="ico icon-check"></i></a></div>
                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleListCancel(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                    </>) :
                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                    </>)
                                                            }

                                                        </div>

                                                    </td>

                                                </tr>

                                            })
                                            : (<></>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className={`btn btn-primary float-right ml-2`} onClick={(e) => { handleSave() }}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'save'} />
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default AddScheduler;