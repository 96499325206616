/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';
/** COMPONENTS */
import { AntDatePicker, ReactTimePicker } from '../../../components';
import { serviceStatus } from '../../../services/enum';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import {
    objContact
} from '../../contacts/viewModel';
import {
    objService
} from '../viewModel';
import { objRequirement } from '../../requirement/viewModel';
import Requirements from '../../requirement/list';
import { firestoreDB } from '../../../services/helper';
//#region  ASSIGN VARIABLES
let titles = [];
let enquiryTypes = [];
let appointmentTypes = [];
let campaigns = [];
let origins = [];
//#endregion

export default class NotifyServiceRequest extends Component {
    constructor(props) {
        super(props);
        this.isLoading = true;
        //this.ref = window.firebase.firestore().collection('serviceJobs');
        this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
        this.unsubscribeConfirmService = null;
        this.state = {
            fields: Object.assign({}, objService),
            contact: Object.assign({}, objContact),
            requirement: Object.assign({}, objRequirement)
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadEnquiry();
    }

    componentWillUnmount() {
        this.unsubscribeConfirmService && this.unsubscribeConfirmService();
    }

    async loadEnquiry() {
        const { docID, dealersettings, clientUsers } = this.props;
        if (docID && dealersettings) {
            if (dealersettings.client.services.pipelines.length === 0) {
                this.props.history.push('/service');
                return
            }
            const refData = await firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(docID).get();

            if (refData.exists) {
                if (!dealersettings.client.services.pipelines.some(item => item.value === refData.data().pipeline)) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Access denied.You dont have pipleine access. Please contact administrator.'), '', 'info')
                    this.props.history.push('/pipeline');
                    return
                }
                const enquiry = Object.assign({}, objService);
                const contact = Object.assign({}, objContact);
                const requirement = Object.assign({}, objRequirement);


                const _clientUsers = [];
                const _objService = Object.assign({}, refData.data());
                for (let [key, value] of Object.entries(_objService)) {
                    enquiry[key] = value;
                }
                enquiry.documentID = refData.id;

                if (enquiry.isContact && !_.isEmpty(enquiry['contact'])) {
                    const refContactData = await this.refContact.doc(enquiry['contact'].documentID).get();
                    if (refContactData.exists) {
                        const objcontactData = Object.assign({}, refContactData.data());
                        for (let [key, value] of Object.entries(objcontactData)) {
                            contact[key] = value;
                        }
                        contact.documentID = enquiry['contact'].documentID;
                    }
                }

                if (enquiry.isVehicle && !_.isEmpty(enquiry['vehicle'])) {
                    const objReqData = Object.assign({}, enquiry['vehicle']);
                    for (let [key, value] of Object.entries(objReqData)) {
                        requirement[key] = value;
                    }
                }

                const _client = enquiry.clientID && !_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.group) &&
                    !_.isEmpty(dealersettings.group.clients) &&
                    dealersettings.group.clients[enquiry.clientID] ? dealersettings.group.clients[enquiry.clientID] : dealersettings.client;

                if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
                    const setttings = dealersettings.client.settings;

                    titles = [];
                    setttings.titles && setttings.titles.forEach((doc) => {
                        titles.push({
                            value: doc.value,
                            label: doc.name
                        });
                    });

                }

                if (!_.isEmpty(_client) && !_.isEmpty(_client.services)) {
                    const setttings = _client.services;

                    appointmentTypes = [];
                    setttings.appointmentTypes && setttings.appointmentTypes.forEach((doc) => {
                        appointmentTypes.push({
                            value: doc.value,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    });
                }

                if (clientUsers) {
                    clientUsers.forEach(doc => {
                        _clientUsers.push({
                            value: doc.id,
                            searchlabel: doc.name,
                            label: (
                                <div className='drop-image'>
                                    <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                                    {doc.name}
                                </div>
                            ),
                        });
                    });
                }

                this.isLoading = false;
                this.setState({
                    fields: enquiry,
                    requirement: requirement,
                    contact: contact,
                    clientUsers: _clientUsers
                });

                this.unsubscribeConfirmService = firestoreDB(dealersettings).firestore().collection('serviceJobs')
                    .doc(docID)
                    .onSnapshot(this.onCollectionUpdate);

            }
        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const fields = Object.assign({}, this.state.fields);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;
        this.setState({ fields: fields });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.isLoading = true;
        const { docID, dealersettings } = this.props;

        // let userID = dealersettings ? dealersettings.id : '';
        // let currenDate = window.firebase.firestore.Timestamp.now();

        // const updateRef = this.ref.doc(docID);
        // updateRef
        //     .update({
        //         confirmedBy: userID,
        //         confirmedDate: currenDate,
        //         modifiedBy: userID,
        //         modifiedDate: currenDate,
        //         modifiedFrom: 'web'
        //     })
        //     .then(docRef => {
        //         this.isLoading = false;
        if (this.props.setNotifyClose)
            this.props.setNotifyClose(true)
        this.props.handleClose(this.state.fields)
        this.props.history.push(`/service/details/${this.state.fields.documentID}`);
        //     })
        //     .catch(error => {
        //         this.isLoading = false;
        //         console.error('Error updating enquiries: ', error);
        //     });


    };

    handleEdit = () => {

    }

    render() {
        const { dealersettings, clientUsers, groupUsers } = this.props;
        const { fields, contact, requirement } = this.state;
        const _settings = dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings;

        const _jobStatus = fields.status && fields.status === serviceStatus.OWNERRESPONDED ? fields.ownerResponse : fields.status;
        const serviceAllStatus = Object.assign([], dealersettings?.client?.services?.serviceStatus)
        const technicianAllStatus = Object.assign([], dealersettings?.client?.services?.technicianStatus)
        let _objCurrentStatus = _jobStatus && serviceAllStatus.filter(e => e.value === _jobStatus)[0]
        return (
            this.isLoading
                ?
                <></> :
                <>
                    <Modal
                        show={this.props.show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-service-lead">
                        <Modal.Header >
                            <Modal.Title>
                                <Translate text={this.props.title} />
                                {
                                    (_jobStatus)
                                        ?
                                        <div className="lead-service-pop-badge">
                                            {
                                                !_.isEmpty(_objCurrentStatus)
                                                    ?
                                                    <>
                                                        <div
                                                            className={`badge badge-pill badge-service-empty ml-2`} style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}>
                                                            <Translate
                                                                text={_objCurrentStatus.name}
                                                                upperCase={true}
                                                            />

                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className={`badge badge-pill badge-service-${_jobStatus.toLowerCase()} ml-2`}>
                                                            <Translate text={_jobStatus.split(/(?=[A-Z])/).join(' ').toLowerCase()} upperCase={true} />
                                                        </div>
                                                    </>
                                            }

                                            {/* {
                                                fields?.technicianStatus
                                                    ?
                                                    <>

                                                        <i className={`ico icon-timer ml-2 mt-1`} style={{ color: CommonHelper.getFieldByValue(technicianAllStatus, fields?.technicianStatus, 'color', '#333') }}></i>


                                                    </>
                                                    :
                                                    <></>
                                            } */}
                                        </div>
                                        :
                                        <></>

                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="lead-service-pop-body">
                                            <div className="lead-service-pop-contact-details">
                                                {/* <div className="vehicle-status-alert alert-user mt-2"> <b className="alert-link">Richard Roberts</b> assigned you .. text here. </div> */}
                                                <h3> <Translate text={'Service Details'} /></h3>

                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'Job Number'} /> </span> {fields.displayID ? fields.displayID : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'roNumber'} /></span> {fields.roNumber ? fields.roNumber : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'appointmentDate'} /></span>
                                                                        {fields.appointmentDate ? moment(fields.appointmentDate).format('DD MMM YYYY h:mm A') : '--'}
                                                                    </td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'slot'} /></span>
                                                                        {
                                                                            !_.isEmpty(fields.slot)
                                                                                ?
                                                                                <>
                                                                                    <span style={{ color: `${fields.slot.color ? fields.slot.color : '#f47815 '}` }}>
                                                                                        <i className="ico icon-service-timer"></i> {fields.slot.name}
                                                                                        {
                                                                                            fields.slot.isExpress ? <span className="express-inline-tag"><div className="badge red-ribbon ml-2 express-fix"><Translate text={'express'} upperCase={true} /></div></span> : <></>
                                                                                        }
                                                                                    </span>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <h3> <Translate text={'contactDetails'}/></h3>
                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-contact-head">
                                                        <div className="lead-service-pop-contact-title"> {CommonHelper.displayFullContact(titles, contact, '--')}</div>

                                                        <div className="lead-service-pop-contact-sub">
                                                            <ul className="lead-service-pop-contact-details">
                                                                <li> <i className="ico icon-enquiry"></i> {contact && contact.displayID ? contact.displayID : '--'}</li>
                                                                <li> <i className="ico icon-call"></i> {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}</li>
                                                                <li> <i className="ico icon-email"></i> {contact && contact.email ? contact.email : '--'}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="lead-service-pop-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label"><Translate text={'licenseNo'}/></span> {contact && contact.licenseNo ? contact.licenseNo : '--'}</td>
                                                                    <td width="25%"><span className="table-label"><Translate text={'gender'}/></span> {contact && contact.gender ? CommonHelper.getNameByValue(_settings ? _settings.genders : [], contact.gender, '') : '--'}</td>
                                                                    <td colSpan="2"><span className="table-label"><Translate text={'address'}/></span> {contact && contact.address ? contact.address : '--'} </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <h3> <Translate text={'vehicleDetails'}/></h3>
                                                <div className="lead-service-pop-box">
                                                    <div className="lead-service-pop-vehicle-head">
                                                        <div className="flex-vehicle">
                                                            <div className="vehicle-item-image"> <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.showBrandLogo(dealersettings, fields?.vehicle?.make)} className="img-object-fit-contain" width="70" height="70" alt="" />
                                                            </a> </div>
                                                            <div className="vehicle-data">
                                                                <div className="vehicle-item-title">{fields.vehicle && fields.vehicle.make ? (fields.vehicle.make) : ''} &nbsp;
                                                                    {fields.vehicle && fields.vehicle.model ? (fields.vehicle.model) : ''}</div>
                                                                <div className="vehicle-item-info">{fields.vehicle && fields.vehicle.year ? (fields.vehicle.year) : ''}</div>
                                                                <div className="vehicle-item-info"><Translate text={'reg'}/>#: {fields.vehicle && fields.vehicle.rego ? (fields.vehicle.rego) : (fields.vehicle && fields.vehicle.regNo ? (fields.vehicle.regNo) : '--')}</div>
                                                                <div className="vehicle-item-info"><Translate text={'VIN'}/>#: {fields.vehicle && fields.vehicle.vinNo ? (fields.vehicle.vinNo) : '--'}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="lead-service-pop-contact-table">
                                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                            <tbody>
                                                                <tr className="tr-brk">
                                                                    <td width="25%"><span className="table-label">Reg#</span> </td>
                                                                    <td width="25%"><span className="table-label">VIN#</span> </td>
                                                                    <td width="25%"><span className="table-label">title</span> text</td>
                                                                    <td width="25%"><span className="table-label">title</span> text</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button"
                                className={`btn btn-default float-left`}
                                onClick={(e) => { e.preventDefault(); this.props.handleClose(); }} >
                                <Translate text={'cancel'} />
                            </button>

                            <button type="button"
                                className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                                onClick={(e) => this.handleSubmit(e)} >
                                {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'view'} />
                            </button>
                        </Modal.Footer>
                    </Modal>

                </>
        );
    }
}
