/** LIBRARIES */
import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** COMPONENTS */
import QuoteForm from '../form';
import { default as _images } from '../../../images';

const MainContainer = props => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let _type = params.get('type');
	let _stockID = params.get('stockID');
	let _previewID = params.get('previewID');
	let _showPrice = params.get('showPrice')

	const tab = (_type === 'new' || _type === 'edit') ? 'details' : 'preview';
	const [activeTab, setActiveTab] = useState(tab);
	const [newlySavedData, setNewlySavedData] = useState({});
	const [client, setClient] = useState({});
	const [clientSettings, setClientSettings] = useState({});
	const [currencySymbol, setCurrencySymbol] = useState('$ ');

	let serviceID = window.location.pathname.replace(
		'/service/invoice/',
		'',
	);

	// const [firestoreDB, setFirestoreDB] = useState()
	// useEffect(() => {
	// 	window.firebase.firestore().doc(`serviceJobs/${serviceID}`).get()
	// 		.then(doc => {
	// 			if (doc.exists) {
	// 				setFirestoreDB(window.firebase)
	// 			}
	// 			else {
	// 				setFirestoreDB(window.firebase2)
	// 			}
	// 		})
	// }, [])

	// useEffect(() => {
	// 	if (activeTab === 'preview')
	// 		props.history.push(`/serviceinvoice/preview/BPSD2DYvGCyqbD2EWoFO`);
	// }, [activeTab])



	// useEffect(() => {
	// 	if (_.isEmpty(firestoreDB))
	// 		return
	// 	const fetchData = async () => {
	// 		if ((_type === 'print' || _type === 'preview') && _previewID) {
	// 			let quoteData = {}
	// 			const quoteRef = firestoreDB.firestore().doc(`serviceJobs/${serviceID}/invoices/${_previewID}`);
	// 			const quoteSnap = await quoteRef.get();
	// 			quoteData = quoteSnap.data();
	// 			if (quoteData.clientID) {
	// 				const clientSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}`).get()
	// 				let clientData = clientSnap.data();
	// 				const langtermsSnapshot = await window.firebase.firestore().collection(`clients/${quoteData.clientID}/localizeData`).get();
	// 				if (clientData && langtermsSnapshot.docs.length > 0) {
	// 					langtermsSnapshot.docs.forEach(rec => {
	// 						clientData[rec.id] = rec.data();
	// 					})
	// 				}
	// 				const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${quoteData.clientID}/currentSettings/${quoteData.clientID}`).get()
	// 				const clientSettingsData = clientSettingsSnap.data();
	// 				setClient(clientData);
	// 				setClientSettings(clientSettingsData);
	// 				setCurrencySymbol(((!_.isEmpty(clientData) &&
	// 					!_.isEmpty(clientData.currency)) ? clientData.currency.symbol + ' ' : '$ '))
	// 			}
	// 			setNewlySavedData(quoteData)
	// 		}
	// 	}
	// 	fetchData()
	// }, [firestoreDB])

	return activeTab === 'details' ? (
		<QuoteForm
			setActiveTab={setActiveTab}
			serviceID={serviceID}
			history={props.history}
			newlySavedData={newlySavedData}
			setNewlySavedData={setNewlySavedData}
			client={client}
			setClient={setClient}
			clientSettings={clientSettings}
			setClientSettings={setClientSettings}
			currencySymbol={currencySymbol}
			setCurrencySymbol={setCurrencySymbol}
			_type={_type}
			_stockID={_stockID}
			_previewID={_previewID}
		/>
	) : (
		<div
			className='middle-wrapper h-100 mt-0'
			style={{
				minHeight: '90vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background: 'floralwhite'
			}}
		>
			<div className='spinner-loader h-100'>
				<div className='no-data-flex-wrap h-100 justify-content-center align-items-center text-primary'>
					<div className='no-data-img-wrap'>
						<img src={_images.pagenotFound} height='190' alt='' />
					</div>
					<div className='no-data-txt-wrap'>
						<div className='no-data-content-head'>
							Page Not Found.
                                                    </div>
						<div className='no-data-content'>
							The page you requested could not be found or may be deleted.
                                                    </div>

					</div>
				</div>
			</div>
		</div>
	);
};

export default MainContainer;
