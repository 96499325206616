import _ from 'lodash'
const AcitivityReducer = (state, action) => {
    function cancelEditable() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.notes = action.data.oldnotes
                item.callDuration = action.data.oldcallDuration
                item.callStatus = action.data.oldcallStatus
                item.addedDate = action.data.oldaddedDate
                return item;
            }
            return item;
        });
    }
    function updatechangelogs() {
        return state.map((item, index) => {
            if (item.documentID === action.id) {
                item[action.field] = action.value;
                return item;
            }
            return item;
        });
    }

    function updateCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = false;
                item.isNewlog = false;
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function editCustomerlog() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isEditable = true;
                item.oldnotes = item.notes
                item.oldcallDuration = item.callDuration
                item.oldcallStatus = item.callStatus
                item.oldaddedDate = item.addedDate
                return item;
            }
            return item;
        });
    }
    function updateAllActivity() {
        return state[action.activetab].map((item, index) => {
            var logVM = action.data.find(e => e.documentID === item.documentID)
            if (logVM) {
                for (let [key, value] of Object.entries(logVM)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function updateActivity() {
        return state[action.activetab].map((item, index) => {
            if (item.documentID === action.data.documentID) { 
                for (let [key, value] of Object.entries(action.data)) {
                    item[key] = value;
                }
                return item;
            }
            return item;
        });
    }
    function updateDoneActivities() {
        return state[action.activetab].map((item, index) => {
            if (item.documentID === action.data.documentID) {
                item.isDone = action.data.isDone;
                return item;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS_LIST": {
            return {
                ...state,
                [action.activetab]: action.data
            };
        }
        case "APPEND_LIST": {
            return {
                ...state,
                [action.activetab]: [...updateAllActivity(), ..._.differenceBy(action.data, state[action.activetab], 'documentID')]
            };
        }
        case "UPDATE_LIST": {
            return {
                ...state,
                [action.activetab]: updateActivity()
            };
        }
        case "ADDNEW_LIST": {
            return {
                ...state,
                [action.activetab]: [action.data, ...state[action.activetab]]
            };
        }
        case "REMOVE_LIST": {
            return {
                ...state,
                [action.activetab]: state[action.activetab].filter(item => item.documentID !== action.data.documentID)
            };
        }
        case "UPDATE_DONE_LIST": {
            return {
                ...state,
                [action.activetab]: updateDoneActivities()
            };
        }
        case "SUCCESS": {
            return action.data;
        }
        case "ADDNEWLOG": {
            return [action.data, ...state];
        }
        case "REMOVELOG": {
            return state.filter(item => item.documentID !== action.data.documentID);
        }
        // case "UPDATELOG": {
        //     return [...state.filter(item => item.documentID !== action.data.documentID), action.data]

        // }
        case "CANCELLOG": {
            return cancelEditable(action.data);
        }
        case "CHANGELOG": {
            return updatechangelogs();
        }
        case "SAVELOG": {
            return [...state, action.data];
        }
        case "EDITLOG": {
            return editCustomerlog();
        }
        case "UPDATELOG": {
            return updateCustomerlog();
        }
        case "CLEAR_ALL": {
            return {
                Overdue: [],
                Today: [],
                Upcoming: [],
                Completed: []
            };
        }
        default:
            return state;
    }
};


export default AcitivityReducer