import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText, ReactSelect } from '../../../../components'
import { roleVM ,levelOptions} from './viewModel'

const AddRole = (props) => {
    const [role, setRole] = useState(props.role);
    const [cloneRole, setCloneRole] = useState('')
    const [errorFields, setErrorFields] = useState({});
    const newRole = !_.isEmpty(props.role) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.role)) {
            const newroleVM = Object.assign({}, roleVM);
            newroleVM.documentID = window.firebase.firestore().collection(`clientSettings/${props.settingsID}/roles`).doc().id;
            newroleVM.addedDate = window.firebase.firestore.Timestamp.now();
            newroleVM.addedBy = localStorage.uid;
            newroleVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newroleVM.modifiedBy = localStorage.uid;
            setRole(newroleVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setRole({
                ...role,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setRole({
                ...role,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleCloneRoleChange = (e, date) => {
        if (e) {
            setRole({
                ...role,
                ['admin']: e.doc.admin,
                ['modules']: e.doc.modules,
                ['permissions']: e.doc.permissions,
                ['pipeline']: e.doc.pipeline,
            });
            setCloneRole(e.value)
        }
        else{ 
            setRole({
                ...role,
                ['modules']: {},
                ['permissions']: {},
                ['pipeline']: {}
            });
            setCloneRole('')
        }
    }

    const handleSelectChange = (e, data) => {
        setRole({
            ...role,
            [data.name]: e.value,
        });
        setCloneRole(null)
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const saveRole = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(role['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (newRole && props.allRoles.some(item => item.name === role.name)) {
            Swal.fire('Role with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete role.active;
        window.firebase.firestore().doc(`clientSettings/${props.settingsID}/roles/${role.documentID}`).set(role, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newRole === true ? 'Role added successfully' : 'Role updated successfully'), {
                    duration: 2000
                })
                props.handleClose(role);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(role) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Role Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={role.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Level</label>
                                    <ReactSelect
                                        options={levelOptions}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={role.level}
                                        classNamePrefix={`${!newRole ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={!newRole}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    newRole ? (<div className="form-group col-md-12">
                                        <label >Clone</label>
                                        <ReactSelect
                                            options={_.map(props.allRoles, function (c) {
                                                return {
                                                    value: c.documentID,
                                                    doc: c,
                                                    label: c.name
                                                };
                                            })}
                                            name={"colneRole"}
                                            placeholder={'select role'}
                                            onChange={handleCloneRoleChange}
                                            value={cloneRole}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                          
                                        >
                                        </ReactSelect>
                                    </div>) : (<></>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveRole(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                        Save
                        </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddRole;