/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { ReactSelect, InputText } from '../../components';
import CommonHelper from '../../services/common';
import { serviceStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';

export const objFields = {
    status: ''
}

export default class ReRequestStatus extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = ['status'];
        this.statuses = [];
        this.state = {
            fields: Object.assign({}, objFields),
            errors: {},
        }
        const { dealersettings } = this.props;

    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, currentStatus } = props;

        if (show === true) {
            if (currentStatus === serviceStatus.PARTSREQUESTED) {
                this.serviceStatuses = [
                    { active: true, value: 'technicianRequested', label: <div className={`badge badge-pill status-technicianrequested`}><Translate text={'technicianRequested'} upperCase={true}/></div> }
                ]
            }
            else if (currentStatus === serviceStatus.WORKSHOPREQUESTED) {
                this.serviceStatuses = [
                    { active: true, value: 'technicianRequested', label: <div className={`badge badge-pill status-technicianrequested`}><Translate text={'technicianRequested'} upperCase={true}/></div> },
                    { active: true, value: 'partsRequested', label: <div className={`badge badge-pill status-partsrequested`}><Translate text={'partsRequested'} upperCase={true}/></div> }
                ]
            }
            else if (currentStatus === serviceStatus.ADVISORREQUESTED) {
                this.serviceStatuses = [
                    { active: true, value: 'technicianRequested', label: <div className={`badge badge-pill status-technicianrequested`}><Translate text={'technicianRequested'} upperCase={true}/></div> },
                    { active: true, value: 'partsRequested', label: <div className={`badge badge-pill status-partsrequested`}><Translate text={'partsRequested'} upperCase={true}/></div> },
                    { active: true, value: 'workshopRequested', label: <div className={`badge badge-pill status-workshoprequested`}><Translate text={'workshopRequested'} upperCase={true}/></div> }
                ]
            }
            else if (currentStatus === serviceStatus.OWNERREQUESTED) {
                this.serviceStatuses = [
                    { active: true, value: 'technicianRequested', label: <div className={`badge badge-pill status-technicianrequested`}><Translate text={'technicianRequested'} upperCase={true}/></div> },
                    { active: true, value: 'partsRequested', label: <div className={`badge badge-pill status-partsrequested`}><Translate text={'partsRequested'} upperCase={true}/></div> },
                    { active: true, value: 'workshopRequested', label: <div className={`badge badge-pill status-workshoprequested`}><Translate text={'workshopRequested'} upperCase={true}/></div> },
                    { active: true, value: 'advisorRequested', label: <div className={`badge badge-pill status-advisorrequested`}><Translate text={'advisorRequested'} upperCase={true}/></div> }
                ]
            }
            const fields = Object.assign({}, objFields);
            this.setState({ fields: fields });
        }
    }


    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT



    handleSelectSave = (e, data) => {
        //this.saveEnquiryField(data.name, e.value);
        let state = Object.assign({}, this.state.fields);
        state[data.name] = e.value;
        this.setState({ fields: state }, () => { this.errorChange(data.name); });
    }



    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            this.setState({ errors: errors });
        }

    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.saveStatus(fields.status);
        }
        else
            return formIsValid;
    };

    saveStatus = (_status) => {
        this.isLoading = true;
        const { serviceID, dealersettings } = this.props;

        let _confirmMsg = ''
        let _objData = {};

        _objData.status = _status;
        _objData.modifiedBy = dealersettings ? dealersettings.id : '';
        _objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        _objData.modifiedFrom = 'web';

        if (_objData.status === serviceStatus.TECHNICIANREQUESTED) {
            _confirmMsg = 'You want to send to technician.'
        }
        if (_objData.status === serviceStatus.PARTSREQUESTED) {
            _confirmMsg = 'You want to send to parts.'
        }
        else if (_objData.status === serviceStatus.WORKSHOPREQUESTED) {
            _confirmMsg = 'You want to send to workshop.'
        }
        else if (_objData.status === serviceStatus.ADVISORREQUESTED) {
            _confirmMsg = 'You want to send to advisor.'
        }

        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, _confirmMsg),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
                    allowEscapeKey: false
        }).then(result => {
            if (result.value) {
                _objData = this.props.handleMoveStageForStatusChange(_objData.status, _objData);

                const updateRef = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc(serviceID);
                updateRef.update(_objData).then((docRef) => {
                    this.isLoading = false;
                    toast.notify(`Service status has been updated.`, { duration: 2000 });
                    this.props.handleClose();

                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }

        });


    }



    render() {
        const { fields } = this.state;
        const { show, dealersettings } = this.props;

        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-add-parts-discount`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                {'Status Update'}
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">


                                    <div className="form-row">

                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'status'} /></label>
                                            <ReactSelect
                                                options={this.serviceStatuses}
                                                name={"status"}
                                                placeholder={'select status'}
                                                onChange={this.handleSelectSave}
                                                value={fields.status}
                                                classNamePrefix={`${this.state.errors["status"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>




                                    </div>


                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.props.handleClose()}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'confirm'} />
                        </button>

                    </Modal.Footer>


                </Modal>



            </>
        );
    }
}
