export const usersVM = {
    documentID: null,
    defaultClientID: null,
    name: '',
    email: '',
    isDeleted: false,
    isQueue: false,
    profileImage: '',
    arrayClientID: [],
    arrayRegionID: [],
    arraySubregionID: [],
    arrayGroupID: [],
    tokkens: [],
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    level: '',
    role: '',
    roleID: '',
    department: '',
    settingsID: null,
    personelEmail: '',
    phoneCode: '',
    phone: '',
    workPhone: '',
    address: '',
    areaCode: '',
    country: '',
    state: '',
    nylasAccessToken: '',
    dmsKeys: [],
    regionID: null,
    groupID: null,
    enableMFA: false,
    designation: '',
    notifyInboundleadTIP: null,
    notifyNewtradeinPro: null,
    ownDashboard: null,
    notifyInboundlead: null,
    notifyInboundleadOEM: null,
    notifyKitchen: null,
    isCafeOrderNotifySound: null,
    isNotifyWithSound: null,
    notifyServiceBooking: null,
    tradeinProClientID: []
}

export const wholesalerVM = {
    documentID: null,
    defaultClientID: null,
    name: '',
    email: '',
    isDeleted: false,
    profileImage: '',
    arrayClientID: [],
    tokkens: [],
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    phone: '',
    phoneCode: '',
    enableMFA: false,
    designation: '',
    arrayOEMID: [],
    settingsID: null,
    isWholesaler: false,
    wholesalerClientID: [],
    wholesalerOEMID: [],
}



export const roleVM = {
    addedDate: null,
    addedBy: '',
    modifiedDate: null,
    modifiedBy: '',
    oemID: '',
    admin: {
        clientSettings: false,
        workflowSetup: false,
        productsData: false,
        modules: false,
        integration: false,
        locationMgnt: false,
        securityMgnt: false,
        billing: false
    },
    documentID: null,
    defaultPipeline: null,
    defaultservicePipeline: null,
    defaulttradeinProPipeline: null,
    level: '',
    modules: {
        enquiry: false,
        frontdesk: false,
        kitchen: false,
        loanVehicle: false,
        stock: false,
        oem: false,
        service: false,
        tradeinPro: false,
        serviceFleetPro: false
    },
    name: '',
    permissions: {
        deleteActivity: false,
        deleteEnquiry: false,
        exportData: false,
        lostApproval: false,
        mergeContact: false,
        modelPrice: false,
        modifyActivityOwner: false,
        modifyCampaign: false,
        modifyEnquiryOrigin: false,
        modifyEnquiryOwner: false,
        modifyEnquiryType: false,
        modifyLabel: false,
        modifyLostReason: false,
        modifyOtherActivity: false,
        modifyOtherEnquiry: false,
        modifyPurhaseInt: false,
        modifyRequirements: false,
        modifyStatusDate: false,
        modifyVehicleInfo: false,
        movePipeline: false,
        queueReorder: false,
        reopenEnquiry: false,
        returnVehicle: false,
        testdriveHandover: false,
        transferEnquiry: false,
        verifyEnquiry: false,
        viewOtherEnquiry: false,
        viewOtherContact: false,
        modifyContactOwner: false,
        modifyOtherContact: false,
        transferContact: false,
        deleteContact: false,
        deleteFiles: false,
        modifyNotes: false,
        ownerDashboard: true,
        unlinkEnquiry: false,
        accessOEMStock: false,
        accessDealertarget: false,
        viewOtherActivity: false,
        modifyStageAfterDeliveredLost: false,
        roNumRequired: false,
        dealerLeadTransfer: false,
        modifyLeadSource: false
    },
    pipeline: [],
    salesType: [],
    servicePipeline: [],
    servicePermissions: {},
    tradeinProPipeline: [],
    tradeinProPermissions: {},
    eventsProPipeline: [],
    eventsProPermissions: {},
    fleetProPermissions: {},
    serviceFleetProPermissions: {},
    excludeOrigins:[]
}


export const mandatoryFields = [
    'name',
    'email',
    'roleID'
];

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]

export const rolePermissions = [
    {
        name: 'Access to client settings',
        value: 'clientSettings',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to workflow setup',
        value: 'workflowSetup',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to service workflow setup',
        value: 'serviceworkflowSetup',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to trade-in pro workflow setup',
        value: 'tradeinProworkflowSetup',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to events pro workflow setup',
        value: 'eventsProworkflowSetup',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to fleet workflow setup',
        value: 'fleetProworkflowSetup',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to products & data',
        value: 'productsData',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to modules',
        value: 'modules',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to integrations',
        value: 'integration',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to location management',
        value: 'locationMgnt',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to security management',
        value: 'securityMgnt',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to billing',
        value: 'billing',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Access to OEM Dashboard',
        value: 'oemDashboard',
        type: 'admin',
        groupBy: 'admin',
        sortorder: 'a'
    },
    {
        name: 'Enquiry',
        value: 'enquiry',
        type: 'modules',
        module: 'sales',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Front Desk',
        value: 'frontdesk',
        type: 'modules',
        module: 'sales',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Kitchen',
        value: 'kitchen',
        type: 'modules',
        module: 'sales',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Loan Vehicle',
        value: 'loanVehicle',
        type: 'modules',
        module: 'sales',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Stock',
        value: 'stock',
        type: 'modules',
        module: 'sales',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Service',
        value: 'service',
        type: 'modules',
        module: 'service',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Trade-In Pro',
        value: 'tradeinPro',
        type: 'modules',
        module: 'tradeinPro',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Events Pro',
        value: 'eventsPro',
        type: 'modules',
        module: 'eventsPro',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Fleet Pro',
        value: 'fleetPro',
        type: 'modules',
        module: 'fleetPro',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Service Fleet Pro',
        value: 'serviceFleetPro',
        type: 'modules',
        module: 'serviceFleetPro',
        sortorder: 'b',
        groupBy: 'modules'
    },
    {
        name: 'Delete Activity',
        value: 'deleteActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modifiy other activity',
        value: 'modifyOtherActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify activity owner',
        value: 'modifyActivityOwner',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Delete Enquiry',
        value: 'deleteEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Export Data',
        value: 'exportData',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Export Contact List',
        value: 'exportContactData',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Lost Approval',
        value: 'lostApproval',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Modify contact Owner',
        value: 'modifyContactOwner',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Disable access to modify contact',
        value: 'disableModifyContact',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Transfer contact',
        value: 'transferContact',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Delete contact',
        value: 'deleteContact',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },    
    {
        name: 'Disable To Add New Contact',
        value: 'disableToAddContact',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to merge contacts/companies',
        value: 'mergeContact',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify enquiry owner',
        value: 'modifyEnquiryOwner',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify other enquiry',
        value: 'modifyOtherEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete files',
        value: 'deleteFiles',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify  campaign',
        value: 'modifyCampaign',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lead origin',
        value: 'modifyEnquiryOrigin',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify enquiry type',
        value: 'modifyEnquiryType',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify label (Hot/Warm/Cold)',
        value: 'modifyLabel',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lost reason',
        value: 'modifyLostReason',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify purchase intension',
        value: 'modifyPurhaseInt',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify requirements',
        value: 'modifyRequirements',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify VIN/Reg/Warranty Expiry for the Won/Delivered',
        value: 'modifyVehicleInfo',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify won / delivered / lost date',
        value: 'modifyStatusDate',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to move pipeline',
        value: 'movePipeline',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to queue reorder',
        value: 'queueReorder',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to reopen enquiry',
        value: 'reopenEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to return vehicle',
        value: 'returnVehicle',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to test drive handover',
        value: 'testdriveHandover',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to transfer enquiry',
        value: 'transferEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to verify enquiry',
        value: 'verifyEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Allow to edit notes',
        value: 'modifyNotes',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'View others enquiry',
        value: 'viewOtherEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to own dashboard',
        value: 'ownerDashboard',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to unlink contact / enquiry',
        value: 'unlinkEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to OEM stock',
        value: 'accessOEMStock',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to dealer target',
        value: 'accessDealertarget',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view other users calendar activity',
        value: 'viewOtherActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify stage after delivered / lost',
        value: 'modifyStageAfterDeliveredLost',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to show stock price',
        value: 'modelPrice',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add stock',
        value: 'addStock',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add preowned/demo stock only',
        value: 'addUsedDemoStockOnly',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to edit stock',
        value: 'editStock',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to update missing stock fields',
        value: 'updateMissingStockFields',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete stock',
        value: 'deleteStock',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'RO number required in loan vehicle',
        value: 'roNumRequired',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to transfer lead to other dealer',
        value: 'dealerLeadTransfer',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to readonly view for enquiry / stock',
        value: 'readOnlyEnquiryView',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to BDC Dashboard',
        value: 'bdcDashboard',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to upload dealer documents',
        value: 'dealerFileUpload',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add quotation',
        value: 'addQuotation',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete quotation',
        value: 'deleteQuotation',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to create new enquiry on lost',
        value: 'createLeadOnLost',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to static report',
        value: 'accessStaticReport',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to enquiry detail report',
        value: 'previewEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to edit dynamic reports',
        value: 'editDynamicReports',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Mandatory Schedule Next Activity On Open Status',
        value: 'mandScheduleNextActivityOpen',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Mandatory Schedule Next Activity On Open / Won Status',
        value: 'mandScheduleNextActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify lead source',
        value: 'modifyLeadSource',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Separate Inbound Activies on Dashboard',
        value: 'separateInbdUserAct',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to schedule contact export',
        value: 'scheduleContactList',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete inbound lead',
        value: 'deleteInboundLead',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to frontdesk queue activation',
        value: 'frontdeskQueueActivation',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Show Trade-In Price',
        value: 'accessToShowTradeInPrice',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Delete Trade-In',
        value: 'deleteTradeIn',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Show Feedback List',
        value: 'showFormFeedback',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Add Dealers',
        value: 'addDealer',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Pipeline Stock',
        value: 'accessToPipelineStock',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Stock VBM',
        value: 'accessToStockVBM',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify Stock VBM',
        value: 'accessToModifyVBM',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add/update TV Display',
        value: 'accessToTVDisplay',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to View TV Scheduler Only',
        value: 'accessToTVDisplayOnly',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to sales performance review',
        value: 'accessToPerformanceReview',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Disable to add new Enquiry/Lead',
        value: 'disableToAddEnquiry',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to modify TradeIn Pro Status',
        value: 'modifyTradeInProStatusAccess',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to finance tab',
        value: 'accessToFinanceManager',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view finance activity',
        value: 'accessToFinanceActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Finance business manager',
        value: 'accessToBusinessManager',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to after market tab',
        value: 'accessToAfterMarketTab',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view after market activity',
        value: 'accessToAfterMarketActivity',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'After Market Manager',
        value: 'accessToAfterMarketBM',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to acquisition report',
        value: 'accessToAcquisitionReport',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view roster',
        value: 'accessToRoster',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add roster',
        value: 'accessToAddRoster',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete roster',
        value: 'accessToDeleteRoster',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view roster notes',
        value: 'accessToRosterNotes',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to add roster notes',
        value: 'accessToAddRosterNotes',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to delete roster notes',
        value: 'accessToDeleteRosterNotes',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to create lead by contact owner only',
        value: 'accessToCreateLeadByContactOwner',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to create subleads after won',
        value: 'accessToCreateSubLeads',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to reports only',
        value: 'accessToReportsOnly',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to request new PPSR',
        value: 'accessToNewPPSR',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view market overlay',
        value: 'accessToMarketOverlay',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to registration check',
        value: 'accessToRegistrationCheck',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to view trigger logs',
        value: 'accessToTriggerLogs',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Tradein History',
        value: 'accessToTradeinHistory',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to select all origin/enquiry types/campaign/lead source',
        value: 'accessToSelectAllKPIs',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Marketing ROI',
        value: 'accessToMarketingROI',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Yearly Performance Report',
        value: 'yearlyPerformanceReport',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Sales Performance Report',
        value: 'salesPerformanceReport',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Product Add-ons',
        value: 'accessToProduct',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Add Product Add-ons',
        value: 'accessToAddProduct',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Delete Product Add-ons',
        value: 'accessToDeleteProduct',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access to Export User List',
        value: 'accessToExportUser',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Disable to view dealer settings',
        value: 'disableDealerSettings',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Disable to view user/roles settings',
        value: 'disableUserSettings',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Disable to view clients/groups settings',
        value: 'disableClientGroupSettings',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access To Retailer Target Report',
        value: 'accessToRetailerTargetReport',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access To View Finance Credit Score',
        value: 'accessToViewFinanceCreditScore',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    {
        name: 'Access To Request Finance Credit Score',
        value: 'accessToRequestFinanceCreditScore',
        type: 'permissions',
        groupBy: 'sales',
        sortorder: 'd'
    },
    //service module permission
    {
        name: 'Access to schedule appointment',
        value: 'scheduleAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to check in',
        value: 'checkInAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to confirm quotation',
        value: 'confirmQuotationAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Send to customer access',
        value: 'sendToCustomerAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to start work',
        value: 'workInProgressAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to complete service',
        value: 'completedAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to delete service',
        value: 'deleteService',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to modify service',
        value: 'modifyService',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to preview service',
        value: 'previewService',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Technician',
        value: 'technician',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Parts Interpretor',
        value: 'partsInterpretor',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Workshop Manager',
        value: 'workshopManager',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Service Advisor',
        value: 'serviceAdvisor',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to Export Data',
        value: 'exportData',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to Export Contact List',
        value: 'exportContactData',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to move pipeline',
        value: 'movePipeline',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to transfer Service',
        value: 'transferService',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to check list',
        value: 'checklistAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to bbt check list',
        value: 'checklistBBTAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to service packages',
        value: 'packagesAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to inspection',
        value: 'inspectionAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to recommendation',
        value: 'recommendationAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {

        name: 'Access to parts',
        value: 'partsAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'e'
    },
    {
        name: 'Access to readonly view for service',
        value: 'viewOnlyAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to parts Confirmation',
        value: 'partsConfirmedAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to workshop Confirmation',
        value: 'workshopConfirmedAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to job allocation',
        value: 'jobAllocatedAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to add discount',
        value: 'discountAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to change status',
        value: 'statusReRequestAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to view stock',
        value: 'accessServiceStock',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to delete stock',
        value: 'deleteServiceStock',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to VSR Dashboard',
        value: 'accessVSR',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to delete log timer',
        value: 'deleteLogAccess',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to add invoice',
        value: 'addInvoice',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to delete invoice',
        value: 'deleteInvoice',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    {
        name: 'Access to Tradein History',
        value: 'accessToTradeinHistory',
        type: 'servicePermissions',
        groupBy: 'service',
        sortorder: 'd'
    },
    //tradeinpro module permission
    {
        name: 'Access to self evaluation',
        value: 'selfEvaluationAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to approve offer',
        value: 'approveOfferAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to resend offer',
        value: 'resendOfferAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to request offer',
        value: 'requestOfferAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to modify status',
        value: 'modifyStatusAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    // {
    //     name: 'Access to whole saler',
    //     value: 'wholeSaler',
    //     type: 'tradeinProPermissions',
    //     groupBy: 'tradeinPro',
    //     sortorder: 'g'
    // },
    {
        name: 'Access to move pipeline',
        value: 'movePipeline',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to delete appraisal',
        value: 'deleteTradein',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to modify appraisal',
        value: 'modifyTradein',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to price log',
        value: 'priceLogAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to modify offers',
        value: 'modifyOfferAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to add price on behalf of wholesaler',
        value: 'putPriceAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    // {
    //     name: 'Access to view others appraisal',
    //     value: 'viewOtherAppraisal',
    //     type: 'tradeinProPermissions',
    //     groupBy: 'tradeinPro',
    //     sortorder: 'g'
    // },
    {
        name: 'Access to tradein detail report',
        value: 'previewAppraisal',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to Export Data',
        value: 'exportData',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to reopen appraisal',
        value: 'reopenAppraisal',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to show damage cost',
        value: 'showDamageCost',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to Inbound',
        value: 'inboundAccess',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to Delete Inbound Lead',
        value: 'deleteInboundLead',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to Show Trade-In Price',
        value: 'accessToShowTradeInPrice',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Lock Offer Price',
        value: 'lockOfferPrice',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Lock Final Price',
        value: 'lockFinalPrice',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Lock Valuer Price',
        value: 'lockValuerPrice',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    {
        name: 'Access to Print Intake Agreement',
        value: 'accessToShowIntakeAgreement',
        type: 'tradeinProPermissions',
        groupBy: 'tradeinPro',
        sortorder: 'g'
    },
    //eventspro module permission
    {
        name: 'Access to add event',
        value: 'addEvent',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to delete event',
        value: 'deleteEvent',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to Manage Template',
        value: 'manageTemplate',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to Export Data',
        value: 'exportData',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to Add Attendee',
        value: 'addAttendee',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to Delete Attendee',
        value: 'deleteAttendee',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access to Live Events Only',
        value: 'accessToLiveOnlyEvents',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access To Change Event Status',
        value: 'accessToChangeEventStatus',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    {
        name: 'Access To Regenerate url',
        value: 'accessToRegenerateURL',
        type: 'eventsProPermissions',
        groupBy: 'eventsPro',
        sortorder: 'i'
    },
    //fleetpro module permission
    {
        name: 'Access to Add fleet vehicles',
        value: 'accessToAddVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to edit fleet vehicle',
        value: 'accessToEditVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to delete fleet vehicle',
        value: 'accessToDeleteVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to Edit Defleet Date',
        value: 'accessToEditDefleetDate',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to booking / assign',
        value: 'accessToAssign',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to request',
        value: 'accessToRequest',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to approve',
        value: 'accessToApprove',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to return',
        value: 'accessToReturn',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to receive',
        value: 'accessToReceive',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'View Only (not able to add/edit any vehicles in fleets / make any booking)',
        value: 'readOnlyFleetVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to Marketing fleet',
        value: 'accessToMarketingFleet',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to PR Fleet',
        value: 'accessToPRFleet',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to MSO Assest',
        value: 'accessToMSOAssest',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to vehicle scheduler',
        value: 'accessToVehicleScheduler',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to vehicle booking',
        value: 'accessToVehicleBooking',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to update fleet vehicle status',
        value: 'accessToUpdateFleetStatus',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to schedule the transit',
        value: 'accessToScheduleTransit',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to fleet history',
        value: 'accessToFleetHistory',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to view allocated fleet',
        value: 'accessToViewAllocatedFleet',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to view FOK fleet',
        value: 'accessToViewFOKFleet',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to On-Fleeting vehicle',
        value: 'accessToOnFleetingVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to operational vehicle',
        value: 'accessToOperationalVehicle',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to de-fleet vehicles',
        value: 'accessToDeFleetVehicles',
        type: 'fleetProPermissions',
        groupBy: 'fleetPro',
        sortorder: 'j'
    },
    //service fleetpro module permission
    {
        name: 'Access to Driver',
        value: 'accessToDriver',
        type: 'serviceFleetProPermissions',
        groupBy: 'serviceFleetPro',
        sortorder: 'j'
    },
    {
        name: 'Access to Create Job',
        value: 'accessToCreateJob',
        type: 'serviceFleetProPermissions',
        groupBy: 'serviceFleetPro',
        sortorder: 'j'
    },
]

export const userlistFields = [
    {
        name: 'Name',
        value: 'userName',
        sortValue: 'name',
        flex: 2,
        subText: 'dmsTags',
        subTextTitle: 'DMS Keys',
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        sortValue: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Role',
        value: 'roleName',
        sortValue: 'role',
        flex: 1,
        default: true,
    },
    {
        name: 'Last Login',
        value: 'lastlogin',
        sortValue: 'lastloginSec',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'isDisabled',
        sortValue: 'isDisabled',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Status',
        value: 'status',
        sortValue: 'statusValue',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const wholesalerlistFields = [
    {
        name: 'Name',
        value: 'userName',
        sortValue: 'name',
        flex: 2,
        default: true
    },
    {
        name: 'Email',
        value: 'email',
        sortValue: 'email',
        flex: 1,
        default: true,
    },
    {
        name: 'Last Login',
        value: 'lastlogin',
        sortValue: 'lastlogin',
        flex: 1,
        default: true
    },
    {
        name: 'Active',
        value: 'isDisabled',
        sortValue: 'isDisabled',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Status',
        value: 'status',
        sortValue: 'statusValue',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const timezone = [
    { active: true, value: 'Asia/Dubai', label: '(UTC+04:00) Abu Dhabi, Muscat' },
    { active: true, value: 'Asia/Karachi', label: '(UTC+05:00) Islamabad, Karachi' },
    { active: true, value: 'Asia/Kolkata', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { active: true, value: 'Australia/Sydney', label: '(UTC+10:00) Canberra, Melbourne, Sydney' }
]

export const levels = [
    { active: true, value: 'oem', label: 'OEM' },
    { active: true, value: 'region', label: 'Region' },
    { active: true, value: 'group', label: 'Group' },
    { active: true, value: 'individual', label: 'Individual' }
]

export const individuallevels = [
    { active: true, value: 'oem', label: 'OEM' },
    { active: true, value: 'individual', label: 'Individual' }
]

export const targetOptions = [
    { active: true, value: 'activities', label: 'Activities' },
    { active: true, value: 'campaigns', label: 'Campaigns' },
    { active: true, value: 'enquiryTypes', label: 'Enquiry Types' },
    { active: true, value: 'enquiryOptions', label: 'Enquiry Options' },
    { active: true, value: 'origins', label: 'Lead Origins' },
    { active: true, value: 'salesTypes', label: 'Sales Types' },
    { active: true, value: 'testdrive', label: 'Test Drive' },
    { active: true, value: 'tradein', label: 'Trade-In' },
]

export const salesTypes = [
    { active: true, value: 'all', label: 'All' },
    { active: true, value: 'New', label: 'New' },
    { active: true, value: 'Demo', label: 'Demo' },
    { active: true, value: 'Preowned', label: 'Preowned' }
]

export const calculateOptions = [
    { active: true, value: 'percentage', label: 'Percentage' },
    { active: true, value: 'count', label: 'Count' }
]

export const activityOptions = [
    { active: true, value: 'all', label: 'All' },
    { active: true, value: 'appointment', label: 'Appointment' },
    { active: true, value: 'testdrive', label: 'Test Drive' },
    { active: true, value: 'call', label: 'Call' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'delivery', label: 'Delivery' },
    { active: true, value: 'task', label: 'Task' }
]

export const targetmandatoryFields = [
    'name',
    'type',
    'subType',
    'calculationType',
    'target'
];


export const teamVM = {
    clientID: '',
    documentID: null,
    user_ids: [],
    name: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null
}

export const departmentVM = {
    name: '',
    value: ''
}

export const userexportFields = [
    {
        label: 'Name',
        key: 'name'
    },
    {
        label: 'Email',
        key: 'email'
    },
    {
        label: 'Phone',
        key: 'phone'
    },
    {
        label: 'Role',
        key: 'role',
    },
    {
        label: 'Department',
        key: 'department',
    },
    {
        label: 'Dealer',
        key: 'dealer',
    },
    {
        label: 'Created On',
        key: 'createdOn',
        date: true
    },
    {
        label: 'Last Login',
        key: 'lastlogin',
        date: true
    },
    {
        label: 'Last Actioned On',
        key: 'actionedOn',
        date: true
    }
]

export const dateTypes = [
    { label: '' }
]