import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { ContentOverlay, ReactSelect, AntMonthPicker, PopoverPanel, BarChart } from "../../../components";
import _images from '../../../images'
import CommonHelper from '../../../services/common';
import TargetPopover from '../targetPopover'
import { dealertargetDynCols } from '../viewModel';

const SalesTarget = (props) => {
    const [loader, setLoader] = useState(true)
    const [selectedUser, setUser] = useState({ value: localStorage.uid });
    const [months, setMonths] = useState(moment().format("MMM"))
    const [year, setYear] = useState(moment().format("YYYY"));
    const [targets, setTargets] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [targetPopOver, setTargetPopOver] = useState({ show: false, target: null, field: null })
    const [activeTarget, setActiveTarget] = useState(null)
    const [cummulativeTargets, setCummulativeTargets] = useState([]);
    const isMclaren = props.dealersettings.isMclaren === true;
    const [startDate, setStartDate] = useState(isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d))
    const [endDate, setEndDate] = useState(isMclaren === true ?
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('year')._d) :
        window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d))
    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])


    useEffect(() => {
        if (_.isEmpty(selectedUser)) {
            setLoader(false)
            return
        }

        setLoader(true)
        const reportData = window.firebase.functions().httpsCallable('generic-getData');
        reportData({
            type: props?.dealersettings?.mvwReport ? "getSalesTargetV2" : "getSalesTarget",
            params: JSON.stringify({
                clientID: props.dealersettings.client.id,
                startDate: moment.unix(startDate.seconds).format('YYYY-MM-DD'),
                endDate: moment.unix(endDate.seconds).format('YYYY-MM-DD'),
                owner: selectedUser.value,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                calcCurrentStatus: calcCurrentStatus
            })
        }).then((response) => {
            setLoader(false);
            if (response.data.success) {
                setTargets(response.data.data);
                let _target = _.chain(response.data.data)
                    .groupBy('targetID')
                    .map((value, key) => {
                        return {
                            targetName: value[0].name,
                            calculationType: value[0].calculationType,
                            targetID: key,
                            counts: value.map((rec, index) => {
                                return {
                                    ...rec,
                                    delta: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.totarget ? -(parseInt(rec.totarget)) : 0 : null,
                                    target: parseInt(rec.target),
                                    actual: moment().month(rec.month).month() <= moment().month(`${rec.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? rec.actual ? parseInt(rec.actual) : 0 : null,
                                    index
                                }
                            })
                        }
                    }).value();
                let _cummulativeTarget = _target.map(rec => {
                    return {
                        ...rec,
                        counts: rec.counts.map((r, i) => {
                            return {
                                ...r,
                                cummulativeTarget: _.sumBy(rec.counts.filter(a => a.index <= i), 'target'),
                                cummulativeActual: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.actual : _.sumBy(rec.counts.filter(a => a.index <= i), 'actual') : null,
                                cummulativeDelta: moment().month(r.month).month() <= moment().month(`${r.year < moment().year() ? 'Dec' : moment().format('MMM')}`).month() ? (r.type === 'overdueActivities' || r.type === 'unattendedLeads') ? r.delta : _.sumBy(rec.counts.filter(a => a.index <= i), 'delta') : null
                            }
                        })
                    }
                })
                setCummulativeTargets(_cummulativeTarget);
            }
        });
    }, [selectedUser, startDate, endDate])



    const targetCalculation = (leads, target, monthLeads, month, targetList) => {
        monthLeads = _.uniq(_.map(monthLeads, function (e) { return e.documentID }))
        var targetVM = {
            ...target,
            id: target.targetID,
            month: month,
            sortOrder: _.findIndex(targetList, (e) => {
                return e.targetID == target.targetID;
            }, 0)
        }
        if (target.calculationType === 'count') {
            targetVM = {
                ...targetVM,
                target: target.target,
                actual: leads.length,
                percentage: target.target > 0 ? Math.round((100 / target.target) * leads.length) + '%' : '0%',
                showRed: (target.target > leads.length) ? true : false,
                different: target.target - leads.length
            }
        }
        else {
            var targetPtg = target.target;
            var actualPtg = monthLeads.length > 0 ? Math.round((100 / monthLeads.length) * leads.length) : 0;
            targetVM = {
                ...targetVM,
                target: targetPtg + '%',
                actual: actualPtg + '%',
                percentage: targetPtg > 0 ? Math.round((100 / targetPtg) * actualPtg) + '%' : '0%',
                showRed: (targetPtg > actualPtg) ? true : false,
                different: targetPtg > 0 ? (100 - (Math.round((100 / targetPtg) * actualPtg))) + '%' : '0%'
            }
        }
        return targetVM
    }

    const handleShowTargetDetails = (e, target) => {
        setActiveTarget(target)
        setTargetPopOver({
            show: true,
            target: e.target
        });
        e.preventDefault()
    }

    return (
        <>
            <h2>Sales Target</h2>
            <div className="divider-line"></div>
            <div className="report-content-head">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="form-group col-md-3 pr-0">
                                {
                                    isMclaren === true ? (
                                        <>
                                            <label htmlFor="">Year</label>
                                            <AntMonthPicker
                                                value={moment().month(months).year(year)._d}
                                                name={'year'}
                                                onChange={val => {
                                                    setMonths(moment(val).format("MMM"))
                                                    setYear(moment(val).format("YYYY"))
                                                    setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('year')._d))
                                                    setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('year')._d))
                                                }}
                                                placeholder='select year'
                                                className={`form-control cursor-pointer`}
                                                format={'YYYY'}
                                                picker={'year'}
                                            /></>
                                    ) : (
                                        <>
                                            <label htmlFor="">Month</label>
                                            <AntMonthPicker
                                                value={moment().month(months).year(year)._d}
                                                name={'month'}
                                                onChange={val => {
                                                    setMonths(moment(val).format("MMM"))
                                                    setYear(moment(val).format("YYYY"))
                                                    setStartDate(window.firebase.firestore.Timestamp.fromDate(moment(val).startOf('month')._d))
                                                    setEndDate(window.firebase.firestore.Timestamp.fromDate(moment(val).endOf('month')._d))
                                                }}
                                                placeholder='select month'
                                                className={`form-control cursor-pointer`}
                                                format={'MMM YYYY'}
                                                picker={'month'}
                                            /></>
                                    )
                                }
                            </div>
                            {
                                (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.ownerDashboard) ? (
                                    <></>) : (<div className="form-group col-md-6">
                                        <label htmlFor="">User</label>
                                        <ReactSelect
                                            options={userOptions}
                                            name={"user"}
                                            placeholder={'select user'}
                                            onChange={(e, data) => {
                                                setUser(e)
                                            }}
                                            value={!_.isEmpty(selectedUser) ? selectedUser.value : ''}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>)
                            }
                        </div>
                    </div>

                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : isMclaren === true ? (<div className="common-table">
                    {
                        !_.isEmpty(cummulativeTargets) ? cummulativeTargets.map((cTarget, index) => {
                            return (<Fragment key={index}>
                                <h5>{cTarget.targetName}</h5>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <td className="head-light" width="20%"></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">TARGET</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="count-large text-center">{`${_rec.target !== null ? `${_rec.target}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className="count-large text-center">{`${_rec.actual !== null ? `${_rec.actual}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            <td className="head-light" width="20%"><span className="th-blue-clr">DELTA</span></td>
                                            {
                                                cTarget.counts.map((_rec, _i) => {
                                                    return <td key={_i} className={`count-large text-center ${_rec.showRed === "true" && _rec.calculationType === 'percentage' ? 'down-ico' : ''}`}>
                                                        {
                                                            _rec.showRed === "false" && _rec.calculationType === 'percentage' ? <img src={_images.tickgreen} alt="" height="20" /> :
                                                                `${_rec.delta ? `${_rec.delta}${cTarget.calculationType === 'percentage' ? '%' : ''}` : ''}`
                                                        }
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                {
                                    cTarget.calculationType === 'count' ? (<table className="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <td className="head-light" width="20%"></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="head-light text-center" width={`${(80 / cTarget.counts.length).toFixed(2)}%`}><strong style={{ fontWeight: 800 }}>{_rec.month}</strong></td>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">TARGET - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeTarget}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">ACTUAL - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeActual}</td>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td className="head-light" width="20%"><span className="th-blue-clr">DELTA - CUMMULATIVE</span></td>
                                                {
                                                    cTarget.counts.map((_rec, _i) => {
                                                        return <td key={_i} className="text-center">{_rec.cummulativeDelta}</td>
                                                    })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>) : (<></>)
                                }
                                <div className="text-center">
                                    <BarChart
                                        id={cTarget.targetID}
                                        height={`400px`}
                                        data={cTarget.counts}
                                        columns={['target', 'actual']}
                                        lineColumns={cTarget.calculationType === 'count' ? ['cummulativeTarget', 'cummulativeActual'] : []}
                                        dynamicColumns={dealertargetDynCols}
                                        xCategory={'month'}
                                        calculationType={cTarget.calculationType}
                                        handleShowEnquiryList={(value, startDate, endDate) => {

                                        }}
                                        hideBullet={true}
                                        dynColor={true}
                                    />
                                </div>
                            </Fragment>)
                        }) : (<div className="spinner-loader h-100 p-5">
                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> Target not set for selected user</div>
                            </div>
                        </div>)
                    }
                </div>
                ) : (<>
                    {
                        _.isEmpty(selectedUser) ? (<div className="spinner-loader h-100 p-5">
                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> Please select user</div>
                            </div>
                        </div>) : !_.isEmpty(targets) ?
                            (<table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <td className="head-light" width="30%">KPI</td>
                                        <td className="head-light text-center" width="10%">Target</td>
                                        <td className="head-light text-center" width="10%">Actual</td>
                                        <td className="head-light text-center" width="10%">To Target</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        targets.length > 0 ?
                                            targets.map((rec, index) => {
                                                return <tr key={index}>
                                                    <td><div className="dealership-tbl cursor-pointer float-left" onClick={(e) => {
                                                        handleShowTargetDetails(e, rec)
                                                    }}>{rec.name}</div></td>
                                                    <td className="text-center">{rec.target}</td>
                                                    <td className="text-center">
                                                        <div className={rec.showRed === "true" ? 'down-ico' : 'up-ico'}>
                                                            {rec.actual}
                                                        </div>
                                                    </td>
                                                    <td className="text-center">

                                                        {
                                                            rec.showRed === "true" ? (
                                                                <span className={'down-ico ml-2'}>
                                                                    {rec.totarget}
                                                                </span>
                                                            ) : (
                                                                <img src={_images.tickgreen} alt="" height="20" />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            }) : (
                                                <tr>
                                                    <td colSpan="4">
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                <div className="no-data-txt mt-2"> Target not set for selected user / month</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </table>) : (<div className="spinner-loader h-100 p-5">
                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                    <div className="no-data-txt mt-2"> Target not set for selected user / month</div>
                                </div>
                            </div>)
                    }
                </>)
            }
            <PopoverPanel
                showpopover={targetPopOver.show}
                targetpopover={targetPopOver.target}
                title={'Target Details '}
                position="right"
                className="popover-required-fields"
                closepopover={() => {
                    setTargetPopOver({ show: false, target: '', field: '' });
                }}>
                <TargetPopover
                    {...props}
                    activeTarget={activeTarget}
                />
            </PopoverPanel>
        </>
    )
}

export default SalesTarget;