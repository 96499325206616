import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';

import FleetFields from './fleetFields'

import { allModules, levelOptions } from './viewModel'
import AddFieldSettings from './addFieldSettings'

import { TemplateContext } from '../templateContext'
import { PopUpModal, PopoverPanel, UpgradePlan } from '../../../../components'
import RequiredFields from './requiredFields'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import RetailerEventFields from './retailerEventFields';
import ExtIntEventFields from './extIntEventFields';
import LoanVehicleFields from './loanVehicleFields';

const FieldSettings = (props) => {

    const [loader, setLoader] = useState(true);
    const [fields, setFields] = useState([]);
    const [selectedClient, setSelectedClient] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [showModel, setShowmodel] = useState(false)
    const [selectedField, setSelectedField] = useState(null)
    const [activeModule, setActiveModule] = useState(null)
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [requiredPopOver, setRequiredPopOver] = useState({ show: false, target: null, field: null })
    const { clients, groups, regions } = useContext(TemplateContext);
    const [fleetOptionsDF, setfleetOptionsDF] = useState();

    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    useEffect(() => {
        setSelectedLevel({
            'level': props.dealersettings.level,
            'levelID': props.dealersettings.level === 'oem' ? props.dealersettings.oemID :
                props.dealersettings.level === 'region' ? props.dealersettings.regionID :
                    props.dealersettings.level === 'group' ? props.dealersettings.groupID :
                        props.dealersettings.client.id,
            'region': (props.dealersettings.level === 'group' || props.dealersettings.level === 'individual') ? props.dealersettings.client.region : '',
            'group': props.dealersettings.level === 'individual' ? props.dealersettings.client.group : '',
            'levelName': props.dealersettings.level === 'region' ? (_.find(regions, { code: props.dealersettings.client.region }) ? _.find(regions, { code: props.dealersettings.client.region }).name : '') :
                props.dealersettings.level === 'group' ? (_.find(groups, { documentID: props.dealersettings.client.group }) ? _.find(groups, { documentID: props.dealersettings.client.group }).name : '') :
                    props.dealersettings.level === 'individual' ? (_.find(clients, { documentID: props.dealersettings.client.id }) ? _.find(clients, { documentID: props.dealersettings.client.id }).name : '') : ''
        })
    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
            if (_.isEmpty(selectedClient)) {
                setSelectedClient(_.find(clients, { 'documentID': props.dealersettings.client.id }))
            }
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionID: _.find(clients, { "group": rec.documentID }) ? _.find(clients, { "group": rec.documentID }).region : ''
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        if (_.isEmpty(selectedLevel) || _.isEmpty(selectedLevel.levelID)) {
            return;
        }
        var allfieldsettings = combineLatest(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`)),
            (selectedLevel.level !== 'oem' ?
                doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${selectedLevel.level === 'region' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.region) ? selectedLevel.region : 'region')}/fleetPro/${selectedLevel.level === 'region' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.region) ? selectedLevel.region : 'region')}`))
                : of({})),
            (selectedLevel.level !== 'oem' && selectedLevel.level !== 'region' ?
                doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${selectedLevel.level === 'group' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.group) ? selectedLevel.group : 'group')}/fleetPro/${selectedLevel.level === 'group' ? selectedLevel.levelID : (!_.isEmpty(selectedLevel.group) ? selectedLevel.group : 'group')}`))
                : of({})),
            (selectedLevel.level !== 'oem' && selectedLevel.level !== 'region' && selectedLevel.level !== 'group' ?
                doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${selectedLevel.level === 'individual' ? selectedLevel.levelID : 'client'}/fleetPro/${selectedLevel.level === 'individual' ? selectedLevel.levelID : 'client'}`))
                : of({})))
            .pipe(
                map((fields) => {
                    // Destructure the values to combine a single array.
                    var [oemFields, regionFields, groupFields, clientFields] = fields;
                    let _oemFields = !_.isEmpty(oemFields) ? oemFields.data() : {}
                    let _regionFields = !_.isEmpty(regionFields) ? regionFields.data() : {}
                    let _groupFields = !_.isEmpty(groupFields) ? groupFields.data() : {}
                    let _clientFields = !_.isEmpty(clientFields) ? clientFields.data() : {}
                    return [
                        //spread the arrays out to combine as one array
                        { ..._oemFields, id: oemFields.id, documentID: oemFields.id, 'level': 'oem' },
                        { ..._regionFields, id: regionFields.id, documentID: regionFields.id, 'level': 'region' },
                        { ..._groupFields, id: groupFields.id, documentID: groupFields.id, 'level': 'group' },
                        { ..._clientFields, id: clientFields.id, documentID: clientFields.id, 'level': 'individual' }
                    ];

                }))
            .subscribe(allFields => {
                var _fields = [];
                allFields.forEach(rec => { _.isArray(rec) ? rec.forEach(e => _fields.push(e)) : _fields.push(rec) })
                let fieldsDoc = [];
                let pipelineDoc = [];
                _fields.forEach(rec => {
                    var f = { 'levelID': rec.documentID, 'levelName': rec.name, 'level': rec.level }
                    allModules.forEach(module => {
                        if (rec['mandatoryFields'] && rec['mandatoryFields'][module]) {
                            f = {
                                ...f,
                                [module]: rec['mandatoryFields'][module]
                            }
                        }
                    })
                    fieldsDoc.push(f)
                })
                let settingsDoc = {};
                _fields.forEach(rec => {
                    if (rec['fleetOptionsDF']) {
                        rec['fleetOptionsDF'].forEach((_lookups) => {
                            if (settingsDoc['fleetOptionsDF']) {
                                settingsDoc['fleetOptionsDF'] = [
                                    ...settingsDoc['fleetOptionsDF'],
                                    {
                                        ..._lookups,
                                        'levelID': rec.id,
                                        'levelName': rec.name,
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                        'documentID': _lookups.value,
                                        'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                    }
                                ]
                            }
                            else {
                                settingsDoc['fleetOptionsDF'] = [{
                                    ..._lookups,
                                    'levelID': rec.id,
                                    'levelName': rec.name,
                                    'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                    'levelIndex': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).index : 1,
                                    'documentID': _lookups.value,
                                    'disableEdit': (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                        (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                }]
                            }
                        })
                    }
                    else if (_.isEmpty(settingsDoc['fleetOptionsDF'])) {
                        settingsDoc['fleetOptionsDF'] = [];
                    }
                })
                setfleetOptionsDF(settingsDoc ? settingsDoc.fleetOptionsDF : []);
                setFields(fieldsDoc)
                setLoader(false)
            });
        return () => {
            allfieldsettings && allfieldsettings.unsubscribe()
        }
    }, [selectedLevel])

    const handleModelClose = () => {
        setShowmodel(false)
    }

    const handlesetloading = (val) => {
        setLoader(val)
    }

    const handlesetfield = (name, module) => {
        setActiveModule(module)
        setSelectedField(name);
        setShowmodel(true)
    }

    const handlesetLevel = (e, data) => {
        if (data.name === 'level') {
            if (e.value === 'oem')
                setLoader(true)
            setSelectedLevel({
                ...selectedLevel,
                [data.name]: e.value,
                ['levelID']: e.value === 'oem' ? props.dealersettings.client.settingsID : ''
            });
        }
        else {
            setSelectedLevel({
                ...selectedLevel,
                [data.name]: e.value,
                'region': !_.isEmpty(e.regionID) ? e.regionID : !_.isEmpty(e.data.region) ? e.data.region : '',
                'group': !_.isEmpty(e.data.group) ? e.data.group : '',
                ['levelName']: e.label,
            });
            if (data.name === 'levelID') {
                setLoader(true)
            }
        }
    }

    const handlesetRequired = (e, field, module) => {
        setActiveModule(module)
        setRequiredPopOver({
            show: true,
            target: e.target,
            field: field
        });
        e.preventDefault()
    }

    return (<>
        {
            liteVersion && !loader ? (<UpgradePlan />) : (<></>)
        }
        <h2> <Translate text={'Field Settings'} /> </h2>
        <div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`}>
            <div className='tabs-wraper'>
                <nav>
                    <Tabs
                        defaultActiveKey='fleet'
                        className='nav-fill'
                    >
                        <Tab eventKey='fleet' title={CommonHelper.showLocale(props, 'Fleet')}>
                            <FleetFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                                fleetOptionsDF={fleetOptionsDF}
                            />
                        </Tab>
                        <Tab eventKey='retailerEvent' title={CommonHelper.showLocale(props, 'Retailer Events')}>
                            <RetailerEventFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='extIntEvents' title={CommonHelper.showLocale(props, 'Ext. Int. Events')}>
                            <ExtIntEventFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                        <Tab eventKey='loanVehicle' title={CommonHelper.showLocale(props, 'Loan Vehicle')}>
                            <LoanVehicleFields
                                {...props}
                                loader={loader}
                                fields={fields}
                                selectedClient={selectedClient}
                                groupOptions={groupOptions}
                                clientOptions={clientOptions}
                                regionOptions={regionOptions}
                                setloading={handlesetloading}
                                setfield={handlesetfield}
                                selectedLevel={selectedLevel}
                                setLevel={handlesetLevel}
                                setRequired={handlesetRequired}
                            />
                        </Tab>
                    </Tabs>
                </nav>
            </div>
        </div>
        <PopUpModal show={showModel}>
            <AddFieldSettings
                {...props}
                show={showModel}
                title={'Edit Field'}
                handleClose={handleModelClose}
                groupOptions={groupOptions}
                clientOptions={clientOptions}
                regionOptions={regionOptions}
                fields={fields}
                selectedField={selectedField}
                activeModule={activeModule}
                selectedClient={selectedClient}
            />
        </PopUpModal>
        <PopoverPanel
            showpopover={requiredPopOver.show}
            targetpopover={requiredPopOver.target}
            title={'Required Fields '}
            position="left"
            className="popover-required-fields"
            closepopover={() => {
                setRequiredPopOver({ show: false, target: '', field: '' });
            }}>
            <RequiredFields
                {...props}
                fields={fields}
                fieldName={requiredPopOver.field}
                activeModule={activeModule}
            />
        </PopoverPanel>
    </>);
}

export default FieldSettings;