import React, { useState } from 'react';
import {  Modal } from 'react-bootstrap';
import { default as _images } from '../../../images';
import _ from 'lodash';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';

const SelectVehicle = ({
	show,
	handleClose,
	requirements,
	handleAddRequirement,
	title,
	currencySymbol,
	dealersettings,
	isFinance,
}) => {
	const [requirementsArr, setRequirements] = useState(requirements);
	const handleSubmit = async () => {
		let selectedRequirements = requirementsArr.filter(
			requirement => requirement.Checked && requirement.Checked === true,
		);
		console.log('selectedRequirements,', selectedRequirements);
		handleAddRequirement(selectedRequirements);
		handleClose();
	};
	
	const returnReqVal = (object, key) => {
		if (!_.isEmpty(object.stock))
			return object.stock[key] ? object.stock[key] : ''
		else
			return object[key] ? object[key] : ''
	}

	return (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-multi-list'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'><Translate text={title ? title : 'Multiple Vehicle'} /></h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='multi-list-wrap'>
							{/* <div className="alert alert-danger" role="alert">
								Please select a vehicle for Lorem ipsum dolor sit amet.
							</div> */}
							{_.orderBy(requirementsArr, ['addedDate.seconds']).map(
								requirement => (
									<div
										className='multi-vehicle-item'
										key={requirement.documentID}
                    onClick={() => {
											if (isFinance) {
												handleClose(requirement);
											} else {
												handleAddRequirement(requirement)
												handleClose();
											}
										}}
									>
										<div className='vehicle-item flex-vehicle' style={{ paddingLeft: '1rem'}}>
											<div className='enquiry-more'> </div>
											<div className='vehicle-item-image'>
												{' '}
												<a>
													<img
														src={CommonHelper.showBrandLogo(dealersettings, returnReqVal(requirement, 'make'))} className="img-object-fit-contain"
														width='70'
														height='70'
														alt=''
													/>{' '}
												</a>{' '}
											</div>
											<div className='vehicle-data'>
												<div className='vehicle-item-title'>
													{`${returnReqVal(requirement, 'make')}
													 ${returnReqVal(requirement, 'model')}`}
													</div>
												<div className='vehicle-item-info'>
													{`${returnReqVal(requirement, 'extColor') }
													 ${returnReqVal(requirement, 'intColor') } 
													  ${returnReqVal(requirement, 'year')}`}
												</div>
												{/* <div className='vehicle-item-info'>Stock #ST-2538</div> */}
												<div className='vehicle-item-status'>
													<div className='badge badge-pill badge-white'>
														{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
																						returnReqVal(requirement, 'saleType'), '')}
													</div>
													{/* <div className='badge badge-pill badge-available ml-1'>
													Available
												</div> */}
													{returnReqVal(requirement, 'price') && (
														<div className='vehicle-item-price'>{`${currencySymbol}${returnReqVal(requirement, 'price')}`}</div>
													)}
												</div>
											</div>
										</div>
									</div>
								),
							)}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-default float-right'
					onClick={e => {
						e.preventDefault();
						handleClose();
					}}
				>
					<Translate text={'Close'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SelectVehicle;
