import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import toast from 'toasted-notes'
import { InputText, ReactSelect, ReactMultiSelect } from '../../components';
import { visibilities, templateVM } from './viewModel'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { userLevel } from '../../services/enum';

const Addtemplate = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)

    const [dealers, setdealers] = useState([]);
    const [regions, setregions] = useState([]);
    const [subregions, setsubregions] = useState([]);
    const [groups, setgroups] = useState([]);

    const levelOptions = [
        //{ value: 'oem', label: 'OEM', index: 1 },
        { value: 'region', label: 'Region', index: 2 },
        { value: 'subregion', label: 'Sub Region', index: 3 },
        { value: 'group', label: 'Group', index: 4 },
        { value: 'individual', label: 'Individual', index: 5 }
    ]

    useEffect(() => {
        if (props.template) {
            let fields = props.template;
            if (!_.isEmpty(fields.clientIDs) && _.isEmpty(fields.level)) fields.level = userLevel.INDIVIDUAL;
            setTemplate(fields)
        }


    }, []);

    useEffect(() => {
        if (props.dealersettings) {

            const { dealersettings } = props;
            let _dealers = [];
            if (dealersettings.clients)
                dealersettings.clients.filter(c =>
                    c.moduleSettings
                    && c.moduleSettings.eventsPro
                    && c.moduleSettings.eventsPro.enabled === true
                    && c.moduleSettings.eventsPro.active === true
                ).forEach(rec => {
                    _dealers.push({
                        ...rec,
                        label: rec.name,
                        value: rec.id,
                        data: rec,
                        active: true,
                    })
                })

            let _subregions = [];
            dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                    _subregions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            setregions(_regions);
            setsubregions(_subregions);
            setgroups(_groups);
            setdealers(_dealers);
        }
    }, [props.dealersettings]);

    //console.log(template)
    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;


        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setTemplate({
                ...template,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setTemplate({
                ...template,
                [name]: str
            });
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'clientIDs') {
            setTemplate({
                ...template,
                [data.name]: [e.value],
                ['clientID']: e.value
            });
        }
        else if (data.name === 'visibility' && e.value === 'private') {
            setTemplate({
                ...template,
                [data.name]: e.value,
                ['clientID']: props.dealersettings.client.id,
                ['clientIDs']: [props.dealersettings.client.id]
            });
        } else {
            setTemplate({
                ...template,
                [data.name]: e.value,

            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let value = [];
        if (data.name === 'clientIDs' && selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            value = loadDealers().filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
        }

        let state = {
            ...template,
            [data.name]: value,
        }

        if (data.name === 'regionIDs') {
            state['subregionIDs'] = [];
        }
        if (data.name === 'subregionIDs' || data.name === 'regionIDs') {
            state['clientIDs'] = [];
        }

        setTemplate(state);
        setErrorFields({
            ...errorFields,
            [data.name]: '',
        });
    }

    //#region SaMeeR for OEM

    const handleLevelSelectChange = (e, data) => {
        let state = Object.assign({}, template);
        if (e) {
            if (data.name === 'clientIDs') state['clientID'] = e.value;

            state[data.name] = [e.value];
        }
        else {
            if (data.name === 'clientIDs') state['clientID'] = null;
            state[data.name] = null;
        }
        setTemplate(state);
        setErrorFields({
            ...errorFields,
            [data.name]: '',
        });
    }
    const handleReactMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        if (selectedOptions?.length > 0 && selectedOptions.some(item => item.value === 'all')) {
            let options = Object.assign([], template.level === userLevel.GROUP ? loadGroups() :
                template.level === userLevel.REGION ? loadRegions() :
                    template.level === userLevel.SUBREGION ? loadSubRegions() :
                        template.level === userLevel.INDIVIDUAL ? loadDealers() : [])

            value = options.filter(e => e.value !== 'all').map(rec => { return rec.value })
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value)
            })
        }


        setTemplate({
            ...template,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    }

    const handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, template);
        if (e) {
            if (data.name === 'clientID') {
                state.clientName = e.label;
            }

            state[data.name] = e.value;
            if (data.name === 'visibility' || data.name === 'level') {
                state['clientIDs'] = [];
                state['regionIDs'] = [];
                state['subregionIDs'] = [];
                state['groupIDs'] = [];
                state['levelID'] = [];
                state['clientIDs'] = [];
                state['clientID'] = null;
            }
        }
        else {
            state[data.name] = '';
        }

        setTemplate(state);
        setErrorFields({
            ...errorFields,
            [data.name]: '',
        });
    }

    const loadDealers = () => {
        const { regionIDs, subregionIDs, groupIDs } = template;

        let _dealers = [];
        if (!_.isEmpty(groupIDs)) {
            _dealers = dealers.filter(e => groupIDs.includes(e.group))
        }
        else if (!_.isEmpty(subregionIDs)) {
            _dealers = dealers.filter(e => subregionIDs.includes(e.subregion))
        }
        else if (!_.isEmpty(regionIDs))
            _dealers = dealers.filter(e => regionIDs.includes(e.region))
        else
            _dealers = dealers

        if (_dealers.length > 1) {
            _dealers = [{ label: 'All', value: 'all' }, ..._dealers];
        }

        return _dealers;
    }

    const loadGroups = (isAll) => {
        const { regionIDs, subregionIDs } = template;

        let _groups = [];
        if (!_.isEmpty(subregionIDs)) {
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && subregionIDs.includes(client.subregion)))
        }
        else if (!_.isEmpty(regionIDs)) {
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && regionIDs.includes(client.region)))
        }
        else
            _groups = groups

        if (isAll && _groups.length > 1) {
            _groups = [{ label: 'All', value: 'all' }, ..._groups];
        }

        return _groups;
    }

    const loadSubRegions = (isAll) => {
        const { regionIDs } = template;

        let _subregions = [];
        if (!_.isEmpty(regionIDs)) {
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && regionIDs.includes(client.region)))
        }
        else _subregions = subregions

        if (isAll && _subregions.length > 1) {
            _subregions = [{ label: 'All', value: 'all' }, ..._subregions];
        }

        return _subregions;
    }

    const loadRegions = (isAll) => {

        let _regions = [...regions];
        if (isAll && _regions.length > 1) {
            _regions = [{ label: 'All', value: 'all' }, ..._regions];
        }
        return _regions;
    }
    //#endregion

    const handleSavetemplate = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        const { dealersettings } = props;

        ['title', 'category'].forEach(key => {
            if ((_.isObject(template[key]) && _.isEmpty(template[key])) || (!_.isObject(template[key]) && !template[key])) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        })

        if (dealersettings.level !== userLevel.INDIVIDUAL) {
            if (_.isEmpty(template['level'])) {
                formIsValid = false;
                errors['level'] = errorClass;
            }
            if (['region', 'subregion', 'group'].includes(template.level) && _.isEmpty(template['levelID'])) {
                formIsValid = false;
                errors['levelID'] = errorClass;
            }

            if (['individual'].includes(template.level) && _.isEmpty(template['clientIDs'])) {
                formIsValid = false;
                errors['clientIDs'] = errorClass;
            }
        }
        else if (dealersettings.level === userLevel.INDIVIDUAL && _.isEmpty(template['clientIDs'])) {
            formIsValid = false;
            errors['clientIDs'] = errorClass;
        }

        console.log('errors', errors, template)
        // if (_.isEmpty(template['title'])) {
        //     formIsValid = false;
        //     errors['title'] = errorClass;
        // }
        // if (_.isEmpty(template['clientIDs'])) {
        //     formIsValid = false;
        //     errors['clientIDs'] = errorClass;
        // }
        // if (_.isEmpty(template['category'])) {
        //     formIsValid = false;
        //     errors['category'] = errorClass;
        // }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        template.clientIDs = CommonHelper.getAllEventClientsByLevel(dealersettings, template.clientIDs, template.level, template.levelID)
        template.clientID = template.clientIDs[0];
        template.clientName = CommonHelper.getOtherDealerName(dealersettings, template.clientID)
        //console.log('template', template)
        setLoader(true)
        template.modifiedDate = window.firebase.firestore.Timestamp.now();
        template.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`eventsProTemplates/${template.documentID}`)
            .set({ ..._.pick(template, _.keys(templateVM)) }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((props.newTemplate === true ? 'Template added successfully' : 'Template updated successfully'), {
                    duration: 2000
                })
                props.handleClose(template);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className='form-group col-md-12'>
                                <label><Translate text={'title'} /></label>
                                <InputText
                                    autoComplete='off'
                                    placeholder={'title'}
                                    className={`form-control ${errorFields['title']}`}
                                    name='title'
                                    onChange={handleOnChange}
                                    value={template.title}
                                    maxLength={150}
                                />
                            </div>
                            <div className='form-group col-md-12'>
                                <label>
                                    <Translate text={'category'} />
                                </label>
                                <ReactSelect
                                    options={props?.dealersettings?.client?.eventsPro?.categories ?
                                        props.dealersettings.client.eventsPro.categories.map(rec => {
                                            return {
                                                value: rec.value,
                                                label: rec.name
                                            }
                                        })
                                        : []}
                                    name={'category'}
                                    placeholder={'select category'}
                                    onChange={handleSelectChange}
                                    value={template.category}
                                    classNamePrefix={`${errorFields["category"]} basic-select`}
                                    removeClearable={true}
                                    isSearchable={false}
                                ></ReactSelect>
                            </div>
                            {/* <div className='form-group col-md-12'>
                                <label>
                                    <Translate text={'visibility'} />
                                </label>
                                <ReactSelect
                                    options={visibilities}
                                    name={'visibility'}
                                    placeholder={'select visibility'}
                                    onChange={handleSelectChange}
                                    value={template.visibility}
                                    classNamePrefix={`basic-select`}
                                    removeClearable={true}
                                    isSearchable={false}
                                ></ReactSelect>
                            </div>

                            <div className='form-group col-md-12'>
                                <label>
                                    <Translate text={'Dealer'} />
                                </label>
                                {
                                    template.visibility === 'private' ? (
                                        <ReactSelect
                                            options={props?.dealersettings?.clients ? props.dealersettings.clients.filter(a => CommonHelper.getAllEventsProDealers(props.dealersettings, true).includes(a.documentID)).map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.documentID
                                                }
                                            }) : []}
                                            name={'clientIDs'}
                                            placeholder={'select dealer'}
                                            onChange={handleSelectChange}
                                            value={template.clientIDs ? template.clientIDs[0] : null}
                                            classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
                                            removeClearable={true}
                                            isSearchable={false}
                                        ></ReactSelect>
                                    ) : (
                                        <ReactMultiSelect
                                            options={props?.dealersettings?.clients ? props.dealersettings.clients.filter(a => CommonHelper.getEventsProDealers(props.dealersettings, true).includes(a.documentID)).map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.documentID
                                                }
                                            }) : []}
                                            name={"clientIDs"}
                                            placeholder={'select dealers'}
                                            onChange={handleMultiSelectChange}
                                            value={template.clientIDs ? template.clientIDs : []}
                                            classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                            menuPlacement='bottom'
                                        >
                                        </ReactMultiSelect>
                                    )
                                }
                            </div> */}
                            <div className="form-group col-md-12">
                                <label ><Translate text={'visibility'} /></label>
                                <ReactSelect
                                    options={visibilities}
                                    name={"visibility"}
                                    placeholder={'select visibility'}
                                    onChange={handleReactSelectChange}
                                    value={template.visibility ? template.visibility : 'private'}
                                    classNamePrefix={`basic-select`}
                                    menuPlacement='top'
                                    removeClearable={true}
                                    isSearchable={false}
                                >
                                </ReactSelect>
                            </div>

                            <div className="form-group col-md-12">
                                <label > <Translate text={'Level'} /></label>
                                <ReactSelect
                                    options={(props.dealersettings.level === userLevel.OEM || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                        levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                    name={"level"}
                                    placeholder={'select level'}
                                    onChange={handleReactSelectChange}
                                    value={template.level}
                                    classNamePrefix={`${errorFields["level"]} basic-select`}
                                    removeClearable={true}
                                >
                                </ReactSelect>
                            </div>

                            {
                                template.visibility === 'shared'
                                    ?
                                    <>

                                        {
                                            (!_.isEmpty(template.level) && template.level !== userLevel.OEM && template.level !== userLevel.INDIVIDUAL) ? (<div className="form-group col-md-12">
                                                <label ><Translate text={template.level === userLevel.GROUP ? 'Groups' : template.level === userLevel.REGION ? 'regions' : template.level === userLevel.SUBREGION ? 'subRegions' : 'Clients'} /></label>
                                                <ReactMultiSelect
                                                    options={
                                                        template.level === userLevel.GROUP ? loadGroups(true) :
                                                            template.level === userLevel.REGION ? loadRegions(true) :
                                                                template.level === userLevel.SUBREGION ? loadSubRegions(true) :
                                                                    loadDealers(true)
                                                    }
                                                    name={'levelID'}
                                                    placeholder={`select ${template.level}`}
                                                    onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                                    value={template.levelID ? _.isArray(template.levelID) ? template.levelID : [template.levelID] : []}
                                                    classNamePrefix={` ${errorFields["levelID"]} basic-select`}
                                                    removeClearable={true}
                                                    isMulti={true}
                                                    isSearchable={true}
                                                >
                                                </ReactMultiSelect>
                                            </div>) : template.level == userLevel.INDIVIDUAL && (
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'Clients'} /> </label>
                                                        <ReactMultiSelect
                                                            options={loadDealers(true)}
                                                            name={"clientIDs"}
                                                            placeholder={'select clients'}
                                                            onChange={handleMultiSelectChange}
                                                            value={template.clientIDs}
                                                            classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            menuPlacement='bottom'
                                                        >
                                                        </ReactMultiSelect>
                                                    </div>
                                                </>)
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            (!_.isEmpty(template.level) && template.level !== userLevel.OEM && template.level !== userLevel.INDIVIDUAL) ? (<div className="form-group col-md-12">
                                                <label ><Translate text={template.level} /></label>
                                                <ReactSelect
                                                    options={
                                                        template.level === userLevel.GROUP ? loadGroups() :
                                                            template.level === userLevel.REGION ? loadRegions() :
                                                                template.level === userLevel.SUBREGION ? loadSubRegions() :
                                                                    loadDealers()
                                                    }
                                                    name={'levelID'}
                                                    placeholder={`select ${template.level}`}
                                                    onChange={handleLevelSelectChange}
                                                    value={template.levelID ? _.isArray(template.levelID) ? template.levelID[0] : template.levelID : null}
                                                    classNamePrefix={` ${errorFields["levelID"]} basic-select`}
                                                    removeClearable={true}
                                                    isSearchable={true}
                                                >
                                                </ReactSelect>
                                            </div>) : template.level == userLevel.INDIVIDUAL && (
                                                <>
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'client'} /> </label>
                                                        <ReactSelect
                                                            options={dealers}
                                                            name={'clientIDs'}
                                                            placeholder={'select client'}
                                                            onChange={handleLevelSelectChange}
                                                            value={template.clientIDs ? _.isArray(template.clientIDs) ? template.clientIDs[0] : template.clientIDs : null}
                                                            classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
                                                            removeClearable={true}
                                                            isSearchable={true}
                                                        ></ReactSelect>
                                                    </div>
                                                </>)
                                        }
                                    </>
                            }







                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                handleSavetemplate()
            }
            }>
                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default Addtemplate;