import React, { useState, useEffect, useReducer, useContext } from 'react'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { ContentOverlay, TableView, ReactSelect, PopUpModal } from "../../../../../components";
import Translate from '../../../../../constants/translate';
import { kpitargetlistFields, targetVM } from '../viewModel'
import TargetKPIs from './targetKPIs'
import { ClientContext } from '../../clientContext'
import CommonHelper from '../../../../../services/common';

const TargetListReducer = (state, action) => {
    function updateTargets() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPENDTARGETS": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADDTARGETS": {
            return [action.data, ...state, action.data]
        }
        case "REMOVETARGETS": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATETARGETS": {
            return updateTargets();
        }
        default:
            return state;
    }
};

const UserKPITargets = (props) => {
    const [targets, dispatch] = useReducer(TargetListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [selectedClient, setSelectedClient] = useState([])
    const [showModel, setShowModel] = useState(false);
    const [popHeader, setPopHeader] = useState('')
    const [searchText, setSearchText] = useState('')
    const [selectedTarget, setTarget] = useState(null)
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [isPaging, setPaging] = useState(false)
    const [clientSettings, setClientSettings] = useState(props.dealersettings);
    const pageLimit = 999

    const { clients } = useContext(ClientContext);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubTargets && window.unSubTargets();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }
        let targetsDataRef = window.firebase.firestore().collection('targets')
            .where('oemID', '==', props.dealersettings.oemID)
            .where('type', '==', 'kpi')
            .where('isDeleted', '==', false)

        // targetsDataRef = targetsDataRef
        //     .orderBy('addedDate', 'desc')

        if (hasMore.length > 0) {
            targetsDataRef = targetsDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            targetsDataRef = targetsDataRef
                .limit(pageLimit)
        }

        window.unSubTargets = targetsDataRef
            .onSnapshot(onCollectionUpdate);


    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertTargetVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDTARGETS";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATETARGETS"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVETARGETS"
                }
            }
        })
        const _targets = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                _targets.push(convertTargetVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (actionType) {
            dispatch({
                type: actionType,
                data: snapshotDoc
            });
        }
        else {
            dispatch({
                type: isPaging ? "APPENDTARGETS" : "SUCCESS",
                data: _.orderBy(_targets, ['addedDate.seconds'], ['desc'])
            });
        }

        setDataload(false)
        setLoader(false)
        setPaging(false)
    }

    const convertTargetVM = (doc) => {
        const objtargetData = Object.assign({}, doc);
        const _target = Object.assign({}, targetVM);
        for (let [key, value] of Object.entries(objtargetData)) {
            _target[key] = value;
        }
        _target.usersName = (<div className="Vehicle-availability">
            {
                _.filter(_.uniqBy(props.groupUsers, 'id'), (v) => _.indexOf(doc.userIDs, v.documentID) >= 0).map((rec, index) => {
                    return <div key={index}><span>{rec.name}</span></div>
                })
            }
        </div>);
        _target.createdBy = CommonHelper.getUserNamebyId(props.groupUsers, doc.addedBy);
        _target.updatedBy = CommonHelper.getUserNamebyId(props.groupUsers, doc.modifiedBy);
        _target.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        _target.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        return _target
    }



    const handleAddUserTarget = () => {
        setPopHeader('Add Target')
        setTarget(null)
        setShowModel(true)
    }

    const handleModelClose = () => {
        setTarget(null)
        setShowModel(false)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditTarget(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteTarget(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }

    const hadleEditTarget = (data) => {
        const targetData = Object.assign({}, data);
        delete targetData.usersName;
        delete targetData.createdBy;
        delete targetData.updatedBy;
        delete targetData.createdOn;
        delete targetData.updatedOn;
        setPopHeader('Edit Target')
        setTarget(targetData)
        setShowModel(true)
    }

    const hadleDeleteTarget = (data) => {
        setPaging(false)
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`targets/${data.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        isDeleted: true
                    }, { merge: true })
                    .then(() => {
                        setLoader(false);
                        toast.notify(`User Target deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleClientdetailsClick = (e, id, data) => {
        hadleEditTarget(data)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const updateClientSettings = async (clientID) => {
        let _clientsettings = await CommonHelper.updateClientSettings(clientID, Object.assign({}, props.dealersettings));
        setClientSettings(_clientsettings)
    }

    return (<>
        <div className="mt-2">
            <div className="settings-head form-style">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="filter-search search-icon col-md-3 pl-0">
                                <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="float-right">
                            <button type="button" className="btn btn-primary float-right"
                                onClick={() => {
                                    handleAddUserTarget()
                                }}
                            ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Target'} /></button>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    targets.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={targets.filter(({ title, userNames }) =>
                                (!_.isEmpty(searchText) ? (
                                    (!_.isEmpty(title) ? title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(userNames) ? userNames.toLowerCase().includes(searchText.toLowerCase()) : false)
                                ) : true)
                                )}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={kpitargetlistFields}
                                dynamicFields={kpitargetlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handleClientdetailsClick}
                                isSettings={false}
                                isReorderable={false}
                                handlePagination={handlePagination}
                                isPaging={true}
                                isSorting={true}
                                isTranslate={true}
                                dealersettings={props.dealersettings}
                            />
                        </div>
                    ) : (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <p><Translate text={'No targets found'} /></p>
                                <button className="btn btn-sm btn-default" onClick={() => { handleAddUserTarget() }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                    <Translate text={'Add Target'} />
                                </button>
                            </div>
                        </div>
                    )
            }
        </div>
        <PopUpModal show={showModel}>
            <TargetKPIs
                {...props}
                show={showModel}
                title={popHeader}
                handleClose={handleModelClose}
                selectedClient={selectedClient}
                target={selectedTarget}
                clientUsers={_.uniqBy(props.groupUsers, 'id')}
                allTargets={targets}
                dealersettings={clientSettings}
            />
        </PopUpModal>
    </>);
}

export default UserKPITargets;