/** LIBRARIES */
import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap'

class PasswordSecurity extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <h2>Security/Password</h2>
                <div className="settings-tab-wrap tabs">
                    <div className="tabs-wraper">
                        <nav>
                            <Tabs defaultActiveKey="changepassword" transition={false} className="nav-fill">
                                <Tab eventKey="changepassword" title="Change Password ">
                                    <div>
                                        Change Password
                        </div>
                                </Tab>
                                <Tab eventKey="authentication" title="Two Factor Authentication">
                                    <div>
                                        Two Factor Authentication
                        </div>
                                </Tab>
                            </Tabs>
                        </nav>
                    </div>
                </div>

            </>
        );
    }
}

export default PasswordSecurity;