import React, { useState, useEffect, useContext } from 'react';
import _, { isEmpty } from 'lodash'
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import { ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { MainContext } from '../../../layout/provider';
import CommonHelper from '../../../../services/common';

const MergeModal = (props) => {
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const { clientUsers } = useContext(MainContext)
    const [btnloader, setBtnLoader] = useState(false)

    useEffect(() => {
        if (!isEmpty(props.userList)) {
            const _clientUsers = [];

            props.userList.forEach(doc => {
                _clientUsers.push({
                    value: doc.documentID,
                    searchlabel: doc.name,
                    label: (
                        <div className='drop-image'>
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    ),
                });
            });

            setUsers(_clientUsers)
        }
    }, [props.userList])


    const handleSelectSave = (e, data) => {
        setSelectedUser(e ? e.value : null)
        setErrorFields({})
    }

    const disableUser = async () => {
        let errorClass = 'input_error';
        let errors = {};
        if (!selectedUser) {
            errors['user'] = errorClass;
            setErrorFields(errors)
            return
        }

        try {
            setLoader(true)

            const disableUser = window.firebase.functions().httpsCallable('amsadmin-disableUser');
            const resp = await disableUser({
                "projectId": props?.dealersettings?.client?.projectId ? props?.dealersettings?.client?.projectId : null,
                "fromUserID": props.id,
                "toUserID": selectedUser
            })

            if (resp && resp.data && resp.data.success) {
                // console.log('response of function call', resp)
                const userRef = window.firebase.firestore().collection('users').doc(props.id)
                await userRef.update({
                    isDisabled: true,
                    tokkens: [],
                    isWholesaler: false,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                })
                try {
                    window.firebase.firestore().collection(`users/${props.id}/devices`)
                        .where('isLogin', '==', true)
                        .get()
                        .then(snap => {
                            if (snap.docs.length > 0) {
                                snap.docs.forEach(doc => {
                                    window.firebase.firestore().doc(`users/${props.id}/devices/${doc.id}`).set({
                                        isLogin: false,
                                        token: null
                                    }, { merge: true })
                                })
                            }
                        });
                    window.firebase.firestore().doc(`amsmeUsers/${props.id}`)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                window.firebase.firestore().doc(`amsmeUsers/${props.id}`).delete();
                            }
                        })
                } catch (err) { }
                toast.notify('User disabled successfully', {
                    duration: 2000
                })
            } else {
                toast.notify('Something went wrong, please try again.', {
                    duration: 2000
                })
            }
            props.handleClose()
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
            toast.notify('Something went wrong', {
                duration: 2000
            })
            const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
            const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
            ref.set({
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedBy: localStorage.uid,
                clientID: props.dealersettings.client.id,
                oemID: props.dealersettings.client.settingsID,
                error: error.message ? error.message : '',
                description: error.message ? error.message : '',
                currentPath: window.location.pathname,
            }).then(() => {
                console.log('Error has been uploaded')
            }).catch((error) => {
                console.error("Error adding log: ", error);
            });

        }
    }

    const disablewithouttransfer = async () => {

        try {
            setBtnLoader(true)

            const disableUser = window.firebase.functions().httpsCallable('amsadmin-disableUser');
            const resp = await disableUser({
                "projectId": props?.dealersettings?.client?.projectId ? props?.dealersettings?.client?.projectId : null,
                "fromUserID": props.id,
                "toUserID": 'NONE'
            })

            if (resp && resp.data && resp.data.success) {
                // console.log('response of function call', resp)
                const userRef = window.firebase.firestore().collection('users').doc(props.id)
                await userRef.update({
                    isDisabled: true,
                    tokkens: [],
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                })
                try {
                    window.firebase.firestore().collection(`users/${props.id}/devices`)
                        .where('isLogin', '==', true)
                        .get()
                        .then(snap => {
                            if (snap.docs.length > 0) {
                                snap.docs.forEach(doc => {
                                    window.firebase.firestore().doc(`users/${props.id}/devices/${doc.id}`).set({
                                        isLogin: false,
                                        token: null
                                    }, { merge: true })
                                })
                            }
                        });
                    window.firebase.firestore().doc(`amsmeUsers/${props.id}`)
                        .get()
                        .then((doc) => {
                            if (doc.exists) {
                                window.firebase.firestore().doc(`amsmeUsers/${props.id}`).delete();
                            }
                        })
                } catch (err) { }
                toast.notify('User disabled successfully', {
                    duration: 2000
                })
            } else {
                toast.notify('Something went wrong, please try again.', {
                    duration: 2000
                })
            }
            props.handleClose()
            setBtnLoader(false)
        } catch (error) {
            setBtnLoader(false)
            console.log(error)
            toast.notify('Something went wrong', {
                duration: 2000
            })
            const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
            const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
            ref.set({
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedBy: localStorage.uid,
                clientID: props.dealersettings.client.id,
                oemID: props.dealersettings.client.settingsID,
                error: error.message ? error.message : '',
                description: error.message ? error.message : '',
                currentPath: window.location.pathname,
            }).then(() => {
                console.log('Error has been uploaded')
            }).catch((error) => {
                console.error("Error adding log: ", error);
            });

        }
    }

    return (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-transfer-leads modal-merge-data"
        enforceFocus={false}
    >
        <Modal.Header closeButton>
            <Modal.Title> <Translate text={props.title} /></Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid" >
                <div className="row">
                    <div className="form-style w-100 pipeline-form-style">
                        <div className="form-row">
                            <div className="alert alert-primary" role="alert">
                                <Translate text={'moveb4Disable'} />
                            </div>
                            <div className="form-group col-md-12">
                                <label >   <Translate text={'Select user to transfer'} /></label>
                                <ReactSelect
                                    options={users.filter(user => user.value !== props.id)}
                                    name={"owner"}
                                    placeholder={'select user'}
                                    onChange={handleSelectSave}
                                    value={selectedUser}
                                    classNamePrefix={`${errorFields["user"]} cursor-pointer  basic-select`}
                                    removeClearable={false}
                                    isSearchable={true}
                                    isDisabled={false}
                                >
                                </ReactSelect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
            {
                props.dealersettings.superAdmin === true ? (
                    <button type="button" className={`btn btn-red float-left ${selectedUser ? 'btn-disable' : ''}`} onClick={disablewithouttransfer}>
                        {
                            btnloader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Disable Without Transfer
                    </button>
                ) : (<></>)
            }

            <button type="button" className="btn btn-primary float-right ml-2" onClick={disableUser}>
                {
                    loader ?
                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                        : (<></>)
                }
                Transfer

            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
        </Modal.Footer>
    </Modal>
    );
}

export default MergeModal;