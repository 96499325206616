import React from 'react'

export const targetOptions = [
    { active: true, value: 'activities', label: 'Activities' },
    { active: true, value: 'callLogs', label: 'Call Logs' },
    { active: true, value: 'campaigns', label: 'Campaigns' },
    { active: true, value: 'dataQuality', label: 'Data Quality' },
    { active: true, value: 'enquiryTypes', label: 'Enquiry Types' },
    { active: true, value: 'enquiryOptionsDF', label: 'Enquiry Options' },
    { active: true, value: 'origins', label: 'Lead Origins' },
    { active: true, value: 'salesTypes', label: 'Sales Types' },
    { active: true, value: 'status', label: 'Enquiry Status' },
    { active: true, value: 'financeStatuses', label: 'Finance Status' },
    // { active: true, value: 'inboundStatus', label: 'Inbound Status' },
    { active: true, value: 'testdrive', label: 'Test Drive' },
    { active: true, value: 'tradein', label: 'Trade-In' },
    { active: true, value: 'quotation', label: 'Quotation' },
    { active: true, value: 'newLeads', label: 'New Leads' },
    { active: true, value: 'overdueActivities', label: 'Overdue Activities' },
    { active: true, value: 'unattendedLeads', label: 'Unattended Leads' },
    { active: true, value: 'enquiryLabels', label: 'Labels' },

]

export const salesTypes = [
    { active: true, searchlabel: 'newopen', value: 'newANDopen', label: <>New <i className="status-open status-i-fix ml-1">open</i></> },
    { active: true, searchlabel: 'demoopen', value: 'demoANDopen', label: <>Demo <i className="status-open status-i-fix ml-1">open</i></> },
    { active: true, searchlabel: 'preownedopen', value: 'preownedANDopen', label: <>Preowned <i className="status-open status-i-fix ml-1">open</i></> },

    { active: true, searchlabel: 'newwon', value: 'newANDwon', label: <>New <i className="status-won status-i-fix ml-1">won</i></> },
    { active: true, searchlabel: 'demowon', value: 'demoANDwon', label: <>Demo <i className="status-won status-i-fix ml-1">won</i></> },
    { active: true, searchlabel: 'preownedwon', value: 'preownedANDwon', label: <>Preowned <i className="status-won status-i-fix ml-1">won</i></> },

    { active: true, searchlabel: 'newlost', value: 'newANDlost', label: <>New <i className="status-lost status-i-fix ml-1">lost</i></> },
    { active: true, searchlabel: 'demolost', value: 'demoANDlost', label: <>Demo <i className="status-lost status-i-fix ml-1">lost</i></> },
    { active: true, searchlabel: 'preownedlost', value: 'preownedANDlost', label: <>Preowned <i className="status-lost status-i-fix ml-1">lost</i></> },

    { active: true, searchlabel: 'newdelivered', value: 'newANDdelivered', label: <>New <i className="status-delivered status-i-fix ml-1">delivered</i></> },
    { active: true, searchlabel: 'demodelivered', value: 'demoANDdelivered', label: <>Demo <i className="status-delivered status-i-fix ml-1">delivered</i></> },
    { active: true, searchlabel: 'preowneddelivered', value: 'preownedANDdelivered', label: <>Preowned <i className="status-delivered status-i-fix ml-1">delivered</i></> },
]

export const enqStatus = [
    { active: true, value: 'open', label: 'Open' },
    { active: true, value: 'won', label: 'Won' },
    { active: true, value: 'lost', label: 'Lost' },
    { active: true, value: 'delivered', label: 'Delivered' }
]

export const callLogs = [
    { active: true, value: 'callANDinbound', label: 'Inbound Call Logs' },
    { active: true, value: 'callANDoutbound', label: 'Outbound Call Logs' },
    { active: true, value: 'call', label: 'Total Call Logs' }
]


export const inboundStatus = [
    { active: true, value: 'created', label: 'Created' },
    { active: true, value: 'converted', label: 'Converted' },
    { active: true, value: 'show', label: 'Show' },
    { active: true, value: 'noshow', label: 'No Show' }
]

export const calculateOptions = [
    { active: true, value: 'percentage', label: 'Percentage' },
    { active: true, value: 'count', label: 'Count' },
    { active: true, value: 'amount', label: 'Amount' }
]

export const activityOptions = [
    { active: true, value: 'appointment', label: 'Appointment' },
    { active: true, value: 'testdrive', label: 'Test Drive' },
    { active: true, value: 'call', label: 'Call' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'delivery', label: 'Delivery' },
    { active: true, value: 'task', label: 'Task' }
]

export const dataQualityOptions = [
    { active: true, value: 'firstName', label: 'First name' },
    { active: true, value: 'middleName', label: 'Middle name' },
    { active: true, value: 'lastName', label: 'Last name' },
    { active: true, value: 'phone', label: 'Phone' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'licenseNo', label: 'License' },
    { active: true, value: 'dob', label: 'Date of birth' },
    { active: true, value: 'address', label: 'Address' },
    { active: true, value: 'make', label: 'Vehicle Model' },
    { active: true, value: 'enquiryType', label: 'Enquiry Type' },
    { active: true, value: 'origin', label: 'Origin' },
    { active: true, value: 'campaign', label: 'Campaign' }
]

export const quotationOptions = [
    { active: true, value: 'all', label: 'Quotation Generated' },
    { active: true, value: 'won', label: <>Quotation <i className="status-won status-i-fix ml-1">won</i></> },
    { active: true, value: 'delivered', label: <>Quotation <i className="status-delivered status-i-fix ml-1">delivered</i></> }
]

export const tradeinOptions = [
    { active: true, value: 'tradein', label: 'Trade-In' },
    { active: true, value: 'Completed', label: <>Trade-In <i className="status-delivered status-i-fix ml-1">Completed</i></> },
    { active: true, value: 'Won', label: <>Trade-In <i className="status-won status-i-fix ml-1">won</i></> },
    { active: true, value: 'Lost', label: <>Trade-In <i className="status-lost status-i-fix ml-1">lost</i></> }
]

export const targetmandatoryFields = [
    'name',
    'type',
    'subType',
    'calculationType',
    'target'
];

export const customtargetmandatoryFields = [
    'name',
    'calculationType',
    'target'
];

export const targetVM = {
    documentID: null,
    clientID: null,
    userIDs: [],
    title: '',
    type: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
};

export const subtargetlistVM = {
    name: '',
    type: '',
    subType: '',
    calculationType: '',
    targetID: ''
};

export const targetlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Users',
        value: 'usersName',
        flex: 2,
        default: true,
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'targetID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]