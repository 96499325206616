/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
import 'toasted-notes/src/styles.css';
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import Filters from './filters'

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [wholesalerPipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ open: 0, won: 0, lost: 0, delivered: 0, total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        var dpl = props.pipeline.filter(e => e.default)[0] ? props.pipeline.filter(e => e.default)[0].value : '';
        var dataArray = [];
        if (props.stageCounts) {
            for (var i in props.stageCounts) {
                if (i !== 'Converted')
                    dataArray.push(props.stageCounts[i]);
            }
        }

        setCounts({
            //open: _.sumBy(dataArray, "open"),
            //won: _.sumBy(dataArray, "won"),
            //lost: _.sumBy(dataArray, "lost"),
            //delivered: _.sumBy(dataArray, "delivered"),
            total: _.sumBy(dataArray, "total")
        })

    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.wholesalerPipelineFilter) && Object.keys(JSON.parse(localStorage.wholesalerPipelineFilter)).length > 0) {
            let _pipelineFilter = JSON.parse(localStorage.wholesalerPipelineFilter);
            let _filterValue = _pipelineFilter.value;

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setPipelineFilter(_pipelineFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.wholesalerPipelineFilter])

    //#region SEARCH KEYWORDS
    const handleKeywordsOnChange = (e) => {
        e.preventDefault()
        const { value } = e.target;

        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSearchKeywords(newarray1.join(' '));
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSearchKeywords(str);
        }
    };

    const handleSearchKeywords = (_keywords) => {
        if (_keywords && _keywords.trim()) {
            if (!_.isEmpty(wholesalerPipelineFilter)) {
                let _pipelineFilter = Object.assign({}, wholesalerPipelineFilter)
                if (!_.isEmpty(wholesalerPipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['keywords'] = _keywords.trim()
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'keywords': _keywords.trim() }
                }
                props.handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'keywords': _keywords.trim() }, type: 'filter' })
                props.handleApplyFilter(_pipelineFilter);
            }
        }
        else if (_keywords && !_keywords.trim()) {
            setSearchKeywords('');
        } else {
            if (!_.isEmpty(wholesalerPipelineFilter)) {
                let _pipelineFilter = Object.assign({}, wholesalerPipelineFilter)
                if (!_.isEmpty(wholesalerPipelineFilter.value)) {
                    if (Object.keys(wholesalerPipelineFilter.value).length > 1) {
                        delete _pipelineFilter.value['keywords']
                        props.handleApplyFilter(_pipelineFilter);
                    }
                    else {
                        props.handleClearFilter();
                    }
                }

            }
        }
    };
    //#endregion

    const transferEnquiry = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions) &&
        props.dealersettings.rolePermissions.tradeinProPermissions.transferEnquiry) ? true : false);

    const movePipeline = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions) &&
        props.dealersettings.rolePermissions.tradeinProPermissions.movePipeline) ? true : false);

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions) &&
        props.dealersettings.rolePermissions.tradeinProPermissions.exportData) ? true : false);

    return (
        <div className="filter-panel">
            {/* <div className="filter-switch">
                <NavLink to="/tradeinpro" exact activeClassName={"switch-active"}>
                    <i className="ico icon-pipeline-1"></i>
                </NavLink>
                <NavLink to="/tradeinpro/list" className="ml-1" exact activeClassName={"switch-active"}>
                    <i className="ico icon-list"></i>
                </NavLink>
            </div> */}

            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search ml-0">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.wholesalerSearchText = _val;

                            if (!_val)
                                props.handlesearchText('')

                            if (_val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.wholesalerSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>


            {
                (!_.isEmpty(props.selectedIds) && props.showMore) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span><Translate text={Object.keys(props.selectedIds).length > 1 ? 'Selected Appraisals' : 'Selected Appraisal'} /></span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">
       

            </div>

            <Dropdown className="filter-pipeline-btn float-right dropdown-toggle" alignRight>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <i className="ico icon-pipeline-1"></i> <span>
                        {props.pipeline.length > 0 ? props.pipeline.filter(e => e.default)[0].name : ''}
                    </span>
                    <i className="ico icon-dropdown"></i>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(props.dealersettings)
                        && !_.isEmpty(props.dealersettings.rolePermissions)
                        && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                        && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                            return props.dealersettings.rolePermissions.admin[id]
                        }).some(e => e === 'tradeinProworkflowSetup') ? (<Dropdown.Item className="edit" bsPrefix="edit" as={Link} to='/settings/tradeinProworkflow?key=pipelines'>
                            <i className="ico icon-edit"></i>
                        </Dropdown.Item>) : (<></>)
                }

                <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="pipeline-dropdown" xplacement="bottom-end">
                    {
                        props.pipeline && props.pipeline.length > 0 ?
                            props.pipeline.map((rec, index) => {
                                return <Dropdown.Item key={index} className="current-pipeline" onClick={(e) => {
                                    e.preventDefault();
                                    //props.handlePipelineChange(rec.value)
                                }}>
                                    {rec.default ? (<div className="pipeline-check">  <i className="ico icon-check"> </i></div>) : (<></>)}
                                    {rec.name}</Dropdown.Item>
                            })
                            : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown>

            {/**** FILTERS ****/}
            <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={wholesalerPipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                showPipeline={props.showMore}
                pipeline={props.pipeline}
                noSaveAccess={true}
            >
            </Filters>


            {/* {
                ((searchText && searchText.length > 2) || (props.pipeline.filter(e => e.default)[0] && props.pipeline.filter(e => e.default)[0].value === 'LeadsBucket' && props.view === 'pipeline')) ? (<></>) : (
                    <div className="pipeline-counter mr-3">
                        <div className="counter-nav">
                            <ul>
                                {
                                    counts.total > 0 ? (
                                        <li><a href="#" className="counter-total">Total <span className="badge badge-pill badge-counter ml-1">{counts.total}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.open > 0 ? (
                                        <li><a href="#" className="counter-open"> Open <span className="badge badge-pill badge-counter ml-1">{counts.open}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.won > 0 ? (
                                        <li><a href="#" className="counter-won"> Won <span className="badge badge-pill badge-counter ml-1">{counts.won}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.delivered > 0 ? (
                                        <li><a href="#" className="counter-delivered"> Delivered <span className="badge badge-pill badge-counter ml-1">{counts.delivered}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    counts.lost > 0 ? (
                                        <li><a href="#" className="counter-lost"> Lost <span className="badge badge-pill badge-counter ml-1">{counts.lost}</span></a></li>
                                    ) : (<></>)
                                }
                                
                            </ul>
                        </div>
                    </div>
                )
            } */}

            {
                (!_.isEmpty(props.pipelineCount) || props.pipelineCount > 0) ?
                    (<>
                        <div className="list-count-btn ml-3" >
                            <span>  <Translate text={props.pipelineCount > 1 ? 'Appraisals' : 'Appraisal'} /></span>
                            <span className="list-count"> {props.pipelineCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }

        </div >)
}

export default FilterPanel