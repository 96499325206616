import React, { useState, useEffect } from 'react';
import { default as _images } from '../../../images';
import { ContentOverlay } from '../../../components';
import Swal from 'sweetalert2';
import _ from 'lodash'
import "../../../styles/gdpr-data-deletion.scss";

const ContactPrivacy = (props) => {

    const [gdprLog, setGDPRLog] = useState();
    const [client, setClient] = useState();
    const [contact, setContact] = useState();
    const [loader, setLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState();
    const [keepLoader, setKeepLoader] = useState();
    const [firestoreDB, setFirestoreDB] = useState();
    const [projectId, setProjectId] = useState()

    useEffect(() => {
        window.firebase.firestore().doc(`gdprLog/${props.id}`).get()
            .then(async (doc) => {
                if (doc.exists) {
                    const clientDoc = await window.firebase.firestore().doc(`clients/${doc.data().clientID}`).get()
                    setClient({
                        ...clientDoc.data(),
                        documentID: clientDoc.id
                    });
                    setGDPRLog({
                        ...doc.data(),
                        documentID: doc.id
                    })
                    if (clientDoc?.data()?.projectId === window.secondaryProjectId) {
                        setFirestoreDB(window.firebase2)
                        setProjectId(clientDoc?.data()?.projectId)
                    }
                    else {
                        setFirestoreDB(window.firebase)
                    }
                }
                else {
                    setContact({
                        gdprResponseTime: window.firebase.firestore.Timestamp.now()
                    })
                }

            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB) || _.isEmpty(gdprLog))
            return

        firestoreDB.firestore().doc(`contactDetails/${gdprLog.contactID}`).get()
            .then((doc) => {
                if (doc.exists) {
                    setContact(doc.data())
                }
                else {
                    setContact({
                        gdprResponseTime: window.firebase.firestore.Timestamp.now()
                    })
                }
                setLoader(false)
            })
    }, [firestoreDB])

    const handleDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete info?.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                setBtnLoader(true)
                try {
                    const unsubscribeContact = window.firebase.functions().httpsCallable('contact-deleteContact');
                    unsubscribeContact({
                        clientID: client.documentID,
                        contactID: gdprLog.contactID,
                        projectId: projectId ? projectId : null
                    }).then((res) => {
                        const gdprSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                        gdprSaveData({
                            documentID: gdprLog.documentID,
                            collection: 'gdprLog',
                            data: {
                                gdprDeleted: true,
                                gdprResponseTime: window.firebase.firestore.Timestamp.now(),
                            },
                        });
                        setBtnLoader(false);
                        setContact({
                            ...contact,
                            gdprResponseTime: window.firebase.firestore.Timestamp.now(),
                            gdprDeleted: true
                        })
                        Swal.fire('Your data deleted successfully', '', 'success')
                    });
                }
                catch (error) {
                    setBtnLoader(false);
                    setContact({
                        ...contact,
                        gdprResponseTime: window.firebase.firestore.Timestamp.now(),
                        gdprDeleted: true
                    })
                    Swal.fire('Your data deleted successfully', '', 'success')
                }
            }
        })

    }

    const handleKeepInfo = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to keep info?.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.value) {
                setKeepLoader(true)
                try {
                    const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                    await genericSaveData({
                        documentID: gdprLog.contactID,
                        collection: 'contactDetails',
                        data: {
                            projectId: projectId ? projectId : null,
                            gdprResponseTime: window.firebase.firestore.Timestamp.now(),
                        },
                    });
                    const gdprSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                    await gdprSaveData({
                        documentID: gdprLog.documentID,
                        collection: 'gdprLog',
                        data: {
                            gdprResponseTime: window.firebase.firestore.Timestamp.now(),
                        },
                    });
                    const newlogVM = Object.assign({});
                    newlogVM.type = 'contact';
                    newlogVM.subType = 'added';
                    newlogVM.logDate = window.firebase.firestore.Timestamp.now();
                    newlogVM.clientID = client.documentID;
                    newlogVM.recordId = gdprLog.contactID;
                    newlogVM.documentID = window.firebase.firestore().collection('changeLogs').doc().id;
                    newlogVM.notes = 'Customer Optin to keep information (GDPR)';

                    const changelogData = window.firebase.functions().httpsCallable('generic-saveData');
                    await changelogData({
                        documentID: newlogVM.documentID,
                        collection: 'changeLogs',
                        data: newlogVM,
                    });

                    setKeepLoader(false);
                    setContact({
                        ...contact,
                        gdprResponseTime: window.firebase.firestore.Timestamp.now()
                    })
                    Swal.fire('Data saved successfully', '', 'success')
                }
                catch (error) {
                    setKeepLoader(false);
                    setContact({
                        ...contact,
                        gdprResponseTime: window.firebase.firestore.Timestamp.now()
                    })
                    Swal.fire('Data saved successfully', '', 'success')
                }
            }
        })
    }

    return loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
        (<>
            <div className="cst-datadeletion-header">
                <div className="container">
                    <div className="cst-datadeletion-logo-wrap">
                        <span className="cst-datadeletion-logo-img mr-3">
                            {
                                client.logoURL ? (
                                    <img
                                        style={{ maxHeight: '50px' }}
                                        src={client.logoURL}
                                        alt='logo'
                                    />
                                ) : (
                                    <>{client.name}</>
                                )
                            }
                        </span>
                    </div>
                </div>
            </div>
            <div className={`cst-datadeletion-wrap-bg ${keepLoader || btnLoader ? 'btn-disable' : ''}`}>
                <div className="cst-datadeletion-wrap">
                    {
                        _.isEmpty(contact.gdprResponseTime) ? (
                            <div className="cst-datadeletion-loop">

                                <div className="cst-datadeletion-tophead"> <img src={_images.data_deletion} width="35" />Personal Data Deletion</div>
                                <div className="cst-datadeletion-data">
                                    <p>The General Data Protection Regulation (EU) (GDPR) is a regulation in EU law on data protection and privacy in the European Union (EU) and the European Economic Area (EEA).  </p>
                                </div>
                                <div className="cst-datadeletion-customerinfo">
                                    <h2>Customer confirmation required</h2>
                                    <p>As per GDPR data retention rules, you can choose the required option from the below regarding your personal information that is stored with us. If you decided to delete the information, all the personal information stored with us will be removed and we will not have any record in our systems for the reference. The action is irreversible. If you do not action, the system will keep the information as it is.</p>
                                </div>
                                <div className="cst-datadeletion-btn">
                                    <a href="#" className={`btn btn-common button-blue`} onClick={(e) => {
                                        e.preventDefault();
                                        handleKeepInfo()
                                    }}>{
                                            keepLoader ? (<span className="spinner-border spinner-button mr-2 mb-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        } Keep Information</a>
                                    <a href="#" className={`btn btn-common button-red ml-3`} onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete()
                                    }}>{
                                            btnLoader ? (<span className="spinner-border spinner-button mr-2 mb-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        } Delete Information</a>
                                </div>
                            </div>
                        ) : contact.gdprDeleted === true ? (
                            <div className="cst-datadeletion-loop">
                                <div className="data-del-img">
                                    <img src={_images.tickgreen} alt="" width="150" /> </div>

                                <div className="data-del-info">
                                    <h2>Personal information deleted. </h2>
                                    <h3>Your request has been completed and we have deleted your personal information.
                                    </h3>
                                    <h3> If you have any assistance, please feel free to contact us
                                    </h3>

                                    <div className="data-del-support">
                                        {
                                            client?.name ? (
                                                <span><b>{client.name}</b></span>
                                            ) : (<></>)
                                        }
                                        {
                                            client?.address ? (
                                                <span>{client.address}</span>
                                            ) : (<></>)
                                        }
                                        {
                                            client?.phone ? (
                                                <span>{client?.phone}</span>
                                            ) : (<></>)
                                        }
                                        {
                                            client?.email ? (
                                                <span> {client?.email}</span>
                                            ) : (<></>)
                                        }
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div className="cst-datadeletion-loop">
                                <div className="data-del-img">
                                    <img src={_images.tickgreen} alt="" width="150" /> </div>

                                <div className="data-del-info">
                                    <h2>Your personal information is safe with us. </h2>
                                    <h3>Your request has been completed and we have saved your personal information.</h3>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>)

}

export default ContactPrivacy;