/** LIBRARIES */
import React, { Component } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import _ from 'lodash';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    DropDownMenu, InputText
} from '../../components';
import { CustomToggle, CustomSearchMenu } from '../../components/customdropdown';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';

const emailList = [
    { active: true, value: 'cc', label: 'Add CC' }
]

export const objCustomer = {
    fullName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneCode: '',
    phone: '',
    email: '',
    emails: [],
    sendSMS: false,
    sendEmail: false
}

export default class SendTradeInLink extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.messageMediaEnabled = false;
        this.ringcentralEnabled = false;
        this.smsHubEnabled = false;
        this.podiumEnabled = false;
        this.justcallEnabled = false;
        this.smsEnabled = false;
        //this.ref = window.firebase.firestore().collection('tradeins');
        this.state = {
            fields: Object.assign({}, objCustomer),
            fieldsInternal: Object.assign({}, objCustomer),
            errors: {},
            errorsInternal: {},
            showParts: false,
            searchText: '',
            phoneCodes: [],
            activeTab: 'customer',
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, contact, dealersettings } = props;
        const fields = Object.assign({}, objCustomer);
        const fieldsInternal = Object.assign({}, objCustomer);

        const _messagemedia = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

        const _smshub = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

        const _podium = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

        const _ringcentral = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

        const _justcall = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "justcall")[0]);

        this.ringcentralEnabled = _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
        this.messageMediaEnabled = _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
        this.smsHubEnabled = _smshub && _smshub.active && _smshub.enabled ? true : false;
        this.podiumEnabled = _podium && _podium.active && _podium.enabled ? true : false;
        this.justcallEnabled = _justcall && _justcall.active && _justcall.enabled ? true : false;

        this.smsEnabled = (this.messageMediaEnabled || this.justcallEnabled || this.smsHubEnabled) ? true : false;

        if (show === true && contact) {
            const objData = Object.assign({}, contact);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }
            fields.fullName = CommonHelper.displayContactName(null, objData);

            if (fields.phone && this.smsEnabled) fields['sendSMS'] = true;
            if (fields.email) fields['sendEmail'] = true;
            fieldsInternal.phoneCode = fields.phoneCode;
            this.setState({ fields: fields, fieldsInternal: fieldsInternal }, () => { this.loadSettings(); });
        }
        else if (show === true) {

            if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.countries) && !_.isEmpty(dealersettings.client)) {
                let countryCode = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
                let phone_code = dealersettings.countries.filter(m => m.code === countryCode)[0];
                if (!_.isEmpty(phone_code)) {
                    fields.phoneCode = phone_code.phone_code ? phone_code.phone_code : '';
                    fieldsInternal.phoneCode = fields.phoneCode;
                }
            }

            this.setState({ fields: fields, fieldsInternal: fieldsInternal }, () => { this.loadSettings(); });
        }
    }

    loadSettings = () => {
        const { dealersettings } = this.props;
        const phoneCodes = [];



        dealersettings && dealersettings.countries && dealersettings.countries.forEach((doc) => {
            if (doc.phone_code) {
                phoneCodes.push({
                    country_code: doc.code,
                    value: doc.phone_code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                });
            }
        });

        this.setState({
            phoneCodes: phoneCodes
        });
    }

    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleAddPhoneCode = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = value ? value : '';
        this.setState({ fields: state });
    };

    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            state['sendSMS'] = value ? true : false;
            this.setState({ fields: state });
        }
    };

    handleOnEmailChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);

        fields[name] = value;

        if (value) {
            let lastAtPos = value.lastIndexOf('@');
            let lastDotPos = value.lastIndexOf('.');

            if (lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)
                fields['sendEmail'] = true
            else
                fields['sendEmail'] = false
        }
        else
            fields['sendEmail'] = false

        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    handleCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    //#region CHANGE EVENT INTERNAL

    handleAddPhoneCodeInternal = (name, value) => {
        let state = Object.assign({}, this.state.fieldsInternal);
        state[name] = value ? value : '';
        this.setState({ fieldsInternal: state });
    };

    handleEmailChangeInternal = idx => evt => {
        let state = Object.assign({}, this.state.fieldsInternal);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fieldsInternal: state });
    };
    handleAddEmailInternal = (type) => {
        let state = Object.assign({}, this.state.fieldsInternal);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fieldsInternal: state });
    };
    handleRemoveEmailInternal = idx => {
        let state = Object.assign({}, this.state.fieldsInternal);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fieldsInternal: state });
    };

    onHandleTelephoneChangeInternal = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fieldsInternal);
        var regexp = /^[0-9+ \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            state['sendSMS'] = value ? true : false;
            this.setState({ fieldsInternal: state });
        }
    };

    handleOnEmailChangeInternal = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fieldsInternal);

        fields[name] = value;

        if (value) {
            let lastAtPos = value.lastIndexOf('@');
            let lastDotPos = value.lastIndexOf('.');

            if (lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && (value.length - lastDotPos) > 2)
                fields['sendEmail'] = true
            else
                fields['sendEmail'] = false
        }
        else
            fields['sendEmail'] = false

        this.setState({ fieldsInternal: fields }, () => { this.errorChangeInternal(name); });
    }

    errorChangeInternal = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errorsInternal);
        let fields = Object.assign({}, this.state.fieldsInternal);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errorsInternal: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        // let errors = {};
        let formIsValid = true;
        const { activeTab, fieldsInternal } = this.state;
        let fields = Object.assign({}, (activeTab === 'customer' ? this.state.fields : fieldsInternal));

        if (!fields.sendEmail && !fields.sendSMS) {
            if (this.smsEnabled)
                Swal.fire(CommonHelper.showLocale(this.props, 'Please select Mobile or Email to send'), '', 'info');
            else
                Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Email to send'), '', 'info');
            return false;
        }
        // let errorClass = 'input_error';

        // for (let [key, value] of Object.entries(fields)) {
        //     if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
        //         if (!fields[key] && key !== 'totalCost') {
        //             formIsValid = false;
        //             errors[key] = errorClass;
        //         }
        //     }
        // }

        // this.setState({ errors: errors });

        // if (formIsValid === true) {

        // }
        // else
        //  

        if (_.isEmpty(fields.fullName) && fields.email) {
            const { groupUsers, clientUsers } = this.props;
            const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            if (_.find(allUsers, { email: fields.email })?.name)
                fields.fullName = _.find(allUsers, { email: fields.email })?.name;
            else
                fields.fullName = fields.email.split('@')[0];
        }

        this.isLoading = true;
        this.forceUpdate();
        this.props.handleClose(fields, this.props.tradeinID);
        return formIsValid;
    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { fullName, phoneCode, phone, email, emails, sendSMS, sendEmail } = this.state.fields;
        const { phoneCodes, errors, activeTab, fieldsInternal } = this.state;
        const { show, showInternal } = this.props;



        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'Send Trade-In Link'} toUpperCase={true} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`bg-modal-body ${showInternal ? 'pt-2' : ''}`}>
                        {
                            showInternal
                                ?
                                <div className="file-manager-nav pl-0">
                                    <ul>
                                        <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'customer' ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ activeTab: 'customer' })

                                            }}><Translate text={'customer'} /> </a></li>

                                        <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'internal' ? 'active' : ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ activeTab: 'internal' })
                                            }}
                                        > <Translate text={'internal'} /> </a></li>
                                    </ul>
                                </div>
                                :
                                <></>
                        }

                        {
                            activeTab === 'customer'
                                ?
                                <>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className=" pop-layout form-style">

                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label ><Translate text={'Customer Name'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'full name'}
                                                            className={`form-control ${this.state.errors["fullName"]}`}
                                                            name="fullName"
                                                            onChange={(e) => { e.preventDefault(); }}
                                                            value={fullName}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                    {
                                                        this.smsEnabled
                                                            ?
                                                            <>
                                                                <div className="form-group col-md-12">
                                                                    <label ><Translate text={'mobile'} /></label>
                                                                    <div className={`input-group country-code ${this.state.errors["phone"] ? 'phone_error' : ''}`}>

                                                                        <div className="input-group-prepend">
                                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                                    <span id="spn-code-name" data-name={phoneCode}>
                                                                                        {phoneCode && phoneCodes.find(item => item.value === phoneCode)
                                                                                            ? phoneCodes.find(item => item.value === phoneCode).value + ' ' :
                                                                                            'Code'
                                                                                        }</span>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                                    ChildClass="pipeline-dropdown"
                                                                                    xplacement="bottom-end"
                                                                                >
                                                                                    {
                                                                                        !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                                            phoneCodes.map((rec, index) => {
                                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(phoneCode && rec.value === phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleAddPhoneCode('phoneCode', rec.value)
                                                                                                }}>
                                                                                                    {rec.label}
                                                                                                </Dropdown.Item>)
                                                                                            })
                                                                                            : (<></>)
                                                                                    }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                        <InputText
                                                                            autoComplete="off"
                                                                            placeholder={'phone'}
                                                                            pattern="[0-9]*"
                                                                            className={`form-control`}
                                                                            name="phone"
                                                                            onChange={this.onHandleTelephoneChange}
                                                                            value={phone}
                                                                        />
                                                                    </div>
                                                                    {/* <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder={'phone'}
                                                pattern="[0-9]*"
                                                className={`form-control `}
                                                name="phone"
                                                onChange={this.onHandleTelephoneChange}
                                                value={phone}
                                            /> */}
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }


                                                    <div className="form-group col-md-12">
                                                        <DropDownMenu
                                                            text='+ add CC Email'
                                                            keyVal="1002"
                                                            tIndex="-1"
                                                            className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                            menu={emailList}
                                                            handleChange={this.handleAddEmail}
                                                        >
                                                        </DropDownMenu>
                                                        <label ><Translate text={'email'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'email'}
                                                            className={`form-control ${this.state.errors["email"]}`}
                                                            name="email"
                                                            onChange={this.handleOnEmailChange}
                                                            value={email}
                                                            readOnly={true}
                                                        //readOnly={true}
                                                        />
                                                    </div>

                                                    {!_.isEmpty(emails) && emails.map((email, idx) => (

                                                        <div className="form-group col-md-12" key={idx}>
                                                            <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmail(idx) }}>- <Translate text={'remove'} /></a>
                                                            <label >{email.name} <Translate text={'email'} /> </label>
                                                            <InputText
                                                                type="text"
                                                                className="form-control"
                                                                id={idx}
                                                                placeholder={`${email.name.toLowerCase()} email `}
                                                                value={email.value}
                                                                onChange={this.handleEmailChange(idx)}
                                                            />
                                                        </div>
                                                    ))}

                                                    <div className="form-group col-md-12">
                                                        <div className="checkbox icheck-success">
                                                            <input
                                                                type="checkbox"
                                                                id="sendEmail"
                                                                name="sendEmail"
                                                                className="uncheck-activity"
                                                                checked={sendEmail ? true : false}
                                                                disabled={errors["email"] ? true : false}
                                                                onChange={(e) => {
                                                                    this.handleCheckChange(e, 'sendEmail')
                                                                }} />
                                                            <label htmlFor="sendEmail"> <Translate text={'sendEmailToCustomer'} /></label>
                                                        </div>
                                                        {   // @ask?: why is handleCheckChangesmsHubEnabled here
                                                            this.smsEnabled
                                                                ?
                                                                <>
                                                                    <div className="checkbox icheck-success">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="sendSMS"
                                                                            name="sendSMS"
                                                                            className="uncheck-activity"
                                                                            checked={sendSMS ? true : false}
                                                                            disabled={phone ? false : true}
                                                                            onChange={(e) => {
                                                                                this.handleCheckChange(e, 'sendSMS')
                                                                            }} />
                                                                        <label htmlFor="sendSMS"> <Translate text={'Send SMS To Customer'} /></label>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }


                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className=" pop-layout form-style">

                                                <div className="form-row">
                                                    {
                                                        this.smsEnabled
                                                            ?
                                                            <>
                                                                <div className="form-group col-md-12">
                                                                    <label ><Translate text={'mobile'} /></label>
                                                                    <div className={`input-group country-code ${this.state.errorsInternal["phone"] ? 'phone_error' : ''}`}>

                                                                        <div className="input-group-prepend">
                                                                            <Dropdown className={`btn btn-outline-secondary`} >
                                                                                <Dropdown.Toggle as={CustomToggle} >
                                                                                    <span id="spn-code-name" data-name={fieldsInternal.phoneCode}>
                                                                                        {fieldsInternal.phoneCode && phoneCodes.find(item => item.value === fieldsInternal.phoneCode)
                                                                                            ? phoneCodes.find(item => item.value === fieldsInternal.phoneCode).value + ' ' :
                                                                                            'Code'
                                                                                        }</span>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu as={CustomSearchMenu}
                                                                                    className="rm-pointers dropdown-menu-lg dropdown-menu-right"
                                                                                    ChildClass="pipeline-dropdown"
                                                                                    xplacement="bottom-end"
                                                                                >
                                                                                    {
                                                                                        !_.isEmpty(phoneCodes) && phoneCodes.length > 0 ?
                                                                                            phoneCodes.map((rec, index) => {
                                                                                                return (<Dropdown.Item key={index} className={`current-pipeline ${(fieldsInternal.phoneCode && rec.value === fieldsInternal.phoneCode) ? 'active' : ''}`} onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    this.handleAddPhoneCodeInternal('phoneCode', rec.value)
                                                                                                }}>
                                                                                                    {rec.label}
                                                                                                </Dropdown.Item>)
                                                                                            })
                                                                                            : (<></>)
                                                                                    }
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                        <InputText
                                                                            autoComplete="off"
                                                                            placeholder={'phone'}
                                                                            pattern="[0-9]*"
                                                                            className={`form-control`}
                                                                            name="phone"
                                                                            onChange={this.onHandleTelephoneChangeInternal}
                                                                            value={fieldsInternal.phone}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }


                                                    <div className="form-group col-md-12">
                                                        <DropDownMenu
                                                            text='+ add CC Email'
                                                            keyVal="1002"
                                                            tIndex="-1"
                                                            className={`inputlink-addmore ${this.state.errorsInternal["emails"] ? 'label-error' : ''}`}
                                                            menu={emailList}
                                                            handleChange={this.handleAddEmailInternal}
                                                        >
                                                        </DropDownMenu>
                                                        <label ><Translate text={'email'} /></label>
                                                        <InputText
                                                            autoComplete="off"
                                                            placeholder={'email'}
                                                            className={`form-control ${this.state.errorsInternal["email"]}`}
                                                            name="email"
                                                            onChange={this.handleOnEmailChangeInternal}
                                                            value={fieldsInternal.email}
                                                        />
                                                    </div>

                                                    {!_.isEmpty(fieldsInternal.emails) && fieldsInternal.emails.map((email, idx) => (

                                                        <div className="form-group col-md-12" key={idx}>
                                                            <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmailInternal(idx) }}>- <Translate text={'remove'} /></a>
                                                            <label >{email.name} <Translate text={'email'} /> </label>
                                                            <InputText
                                                                type="text"
                                                                className="form-control"
                                                                id={idx}
                                                                placeholder={`${email.name.toLowerCase()} email `}
                                                                value={email.value}
                                                                onChange={this.handleEmailChangeInternal(idx)}
                                                            />
                                                        </div>
                                                    ))}

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>
                        }

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'send'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
