/** LIBRARIES */
import React, { Component } from "react";
import { Dropdown, Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import { PopUpModal } from '../../components';
import { default as _images } from "../../images";
import CommonHelper from '../../services/common';
import ImageMarker from './imgMarker'
import MarkingInfo from './markingInfo'

class ImageCanvas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMarker: {
                show: false,
                mark: {},
                index: 0
            },
            marks: Object.assign([], this.props.marks),
            marktype: 'Info',
            showDamage: false,
        }
        const { dealersettings } = this.props;
        this.currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');
        //this.handleDamageClose = this.handleDamageClose.bind(this);
    }

    //#region MARKER VIEW

    handleMarkerDelete = (index) => {
        if (index >= 0) {
            let marks = Object.assign([], this.state.marks);
            marks.splice(index, 1);
            this.setState({
                marks: [...Object.assign([], marks)],
                showMarker: {
                    show: false,
                    mark: {},
                    index: 0,
                    isReadOnly: false
                }
            })

            toast.notify('Mark deleted successfully', {
                duration: 2000
            })
        }
        else {
            this.setState({
                showMarker: {
                    show: false,
                    mark: {},
                    index: 0,
                    isReadOnly: false
                }
            })
        }
    }

    handleMarkerClose = (mark, index) => {

        if (!_.isEmpty(mark)) {
            let marks = Object.assign([], this.state.marks);
            marks.splice(index, 1);
            this.setState({
                marks: Object.assign([], [...marks, mark]),
                showMarker: {
                    show: false,
                    mark: {},
                    index: 0,
                    isReadOnly: false
                }
            })
            toast.notify('Mark saved successfully', {
                duration: 2000
            })
        }
        else {
            this.setState({
                showMarker: {
                    show: false,
                    mark: {},
                    index: 0,
                    isReadOnly: false
                }
            })
        }

    }

    handleMarkChange = (type) => {
        this.setState({ marktype: type })
    };

    handleShowMark = (mark, index, isReadOnly) => {
        this.setState({
            showMarker: {
                show: true,
                mark: mark,
                index: index,
                isReadOnly: isReadOnly
            }
        })
    }
    //#endregion

    handleDamageClose = () => {
        this.setState({ showDamage: false })
    }

    handleClearMarks = () => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, 'Do you want to clear the recent markers.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                this.setState({ marks: Object.assign([], this.props.marks) })
            }
        })

    }

    handleaddmarks = (e) => {
        //console.log('handleaddmarks')
        //e.preventDefault();
        var addMark = {
            dx: e.clientX - document.getElementById("inspect-cont").getBoundingClientRect().x - 8,
            dy: e.clientY - document.getElementById("inspect-cont").getBoundingClientRect().y - 8,
            markType: this.state.marktype,
            currency: this.currencySymbol,
            //price: 0
        }
        this.setState({ marks: [...this.state.marks, addMark] }, () => {
            this.handleShowMark(addMark, this.state.marks.length - 1, this.props.isReadOnly);
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.handleClose(this.state.marks, this.props.fieldID)

    };

    loadInspectionImage = () => {
        const { dealersettings } = this.props;

        if (dealersettings.isMclaren === true)
            return 'inspectionMcLaren'
        else
            return CommonHelper.getNoVehicleIcons(dealersettings?.client?.category, 'inspection')

    }

    render() {
        const { marks, marktype, showMarker, showDamage } = this.state;
        const { show, isReadOnly, readOnlyMarks, dealersettings } = this.props;
        //console.log("add-inspection", this.props)
        return (
            <>
                <Modal
                    id="add-inspection"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-inspection">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title"><Translate text={'vehicleInspection'} />
                                {
                                    ((marks && marks.length > 0) || (readOnlyMarks && readOnlyMarks.length > 0))
                                        ?
                                        <>
                                            <span className="badge badge-pill badge-cafe ml-1">{marks.length}</span>

                                            <button type="button" className="float-right btn btn-default btn-inspection-detail"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showDamage: true })
                                                }}>
                                                <Translate text={'details'} />
                                            </button>
                                        </>
                                        :
                                        <></>
                                }

                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`container-fluid`}>
                            <div className="row">
                                <div className="inspection-wrap" id="inspect-cont">

                                    <img
                                        src={_images[this.loadInspectionImage()]}
                                        alt=""
                                        width="395"
                                        height="600"
                                    />

                                    <div
                                        className="markcontainer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (!isReadOnly)
                                                this.handleaddmarks(e)
                                        }}
                                    />
                                    {
                                        (!_.isEmpty(readOnlyMarks))
                                            ?
                                            readOnlyMarks.map((mark, index) => {
                                                return <img key={index}
                                                    src={require(`../../images/${mark.markType}.png`)}
                                                    style={{ top: mark.dy + "px", left: mark.dx + "px" }}
                                                    alt={mark.markType + '-' + index}
                                                    onClick={(e) => { e.preventDefault(); this.handleShowMark(mark, index, true) }}
                                                />
                                            })
                                            :
                                            <></>
                                    }
                                    {
                                        marks && marks.map((mark, index) => {
                                            return <img key={index}
                                                src={require(`../../images/${mark.markType}.png`)}
                                                style={{ top: mark.dy + "px", left: mark.dx + "px" }}
                                                alt={mark.markType + '-' + index}
                                                onClick={(e) => { e.preventDefault(); this.handleShowMark(mark, index, isReadOnly) }}
                                            />
                                        })
                                    }
                                </div>


                            </div>
                        </div>

                    </Modal.Body>
                    {
                        isReadOnly
                            ?
                            <></>
                            :
                            <>
                                <Modal.Footer>
                                    <div className="inspection-icon-wrap">
                                        <Dropdown className="dropup" alignRight drop={'up'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-button-drop-info"
                                                className={`inspection-icon info rounded-pill ${marktype === 'Info' ? 'info-selected' : ''}`}>
                                                <span className="badge badge-pill badge-mark ">i</span><Translate text={'info'} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu" ChildClass="marker-dropdown" xplacement="bottom-end">
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('Info') }}> <span className="badge badge-pill major-info ">i</span>  <Translate text={'info'} /> </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown className="dropup" alignRight drop={'up'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-button-drop-scratch"
                                                className={`inspection-icon scratch rounded-pill ${(marktype === 'MinorScratch' || marktype === 'MajorScratch') ? 'scratch-selected' : ''}`}
                                            >
                                                <span className="badge badge-pill badge-mark ">S</span> <Translate text={'scratch'} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu" ChildClass="marker-dropdown" xplacement="bottom-end">
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MinorScratch') }}><span className="badge badge-pill minor-scratch ">S</span> <Translate text={'Minor Scratch'} /> </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MajorScratch') }}><span className="badge badge-pill major-scratch ">S</span> <Translate text={'Major Scratch'} /> </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown className="dropup" alignRight drop={'up'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-button-drop-rust"
                                                className={`inspection-icon rust rounded-pill ${(marktype === 'MinorRust' || marktype === 'MajorRust') ? 'rust-selected' : ''}`}
                                            >
                                                <span className="badge badge-pill badge-mark ">R</span> <Translate text={'rust'} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu" ChildClass="marker-dropdown" xplacement="bottom-end">
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MinorRust') }}> <span className="badge badge-pill minor-rust ">R</span> <Translate text={'Minor Rust'} />  </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MajorRust') }}> <span className="badge badge-pill major-rust ">R</span> <Translate text={'Major Rust'} /> </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown className="dropup" alignRight drop={'up'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-button-drop-dent"
                                                className={`inspection-icon dent rounded-pill ${(marktype === 'MinorDent' || marktype === 'MajorDent') ? 'dent-selected' : ''}`}
                                            >
                                                <span className="badge badge-pill badge-mark ">D</span><Translate text={'dent'} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu" ChildClass="marker-dropdown" xplacement="bottom-end">
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MinorDent') }}> <span className="badge badge-pill minor-dent ">D</span>  <Translate text={'Minor Dent'} /> </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MajorDent') }}> <span className="badge badge-pill major-dent ">D</span>  <Translate text={'Major Dent'} /></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown className="dropup" alignRight drop={'up'}>
                                            <Dropdown.Toggle as={CustomToggle} id="dropdown-button-drop-crack"
                                                className={`inspection-icon crack rounded-pill ${(marktype === 'MinorCrack' || marktype === 'MajorCrack') ? 'crack-selected' : ''}`}
                                            >
                                                <span className="badge badge-pill badge-mark ">C</span> <Translate text={'crack'} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={CustomMenu} className="dropdown-menu" ChildClass="marker-dropdown" xplacement="bottom-end">
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MinorCrack') }}> <span className="badge badge-pill minor-crack ">C</span> <Translate text={'Minor Crack'} />  </Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { e.preventDefault(); this.handleMarkChange('MajorCrack') }}> <span className="badge badge-pill major-crack ">C</span> <Translate text={'Major Crack'} /> </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>



                                    </div>
                                </Modal.Footer>
                                <Modal.Footer>
                                    <button type="button" className="btn btn-default float-left" onClick={(e) => { e.preventDefault(); this.handleClearMarks(); }}><Translate text={'clear'} /></button>
                                    <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}><Translate text={'done'} /></button>
                                </Modal.Footer>
                            </>
                    }

                </Modal>

                <PopUpModal show={showMarker.show}>
                    <ImageMarker
                        show={showMarker.show}
                        mark={showMarker.mark}
                        index={showMarker.index}
                        isReadOnly={showMarker.isReadOnly}
                        imagePath={this.props.imagePath}
                        clsActive='overlay-modal active'
                        handleClose={this.handleMarkerClose}
                        handleDelete={this.handleMarkerDelete}
                        dealersettings={this.props.dealersettings}
                        showCost={this.props.showCost}
                        isCompressedImage={this.props.isCompressedImage}
                    ></ImageMarker>
                </PopUpModal>

                <PopUpModal show={showDamage}>
                    <MarkingInfo
                        show={showDamage}
                        clsActive='overlay-modal active'
                        beforeMarks={readOnlyMarks}
                        marks={marks}
                        handleClose={this.handleDamageClose}
                        showCost={this.props.showCost}
                    ></MarkingInfo>
                </PopUpModal>
            </>


        );
    }
}

export default ImageCanvas;