/** LIBRARIES */
import React, { Suspense, Component,lazy } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
/** COMPONENTS */
import { AdminSettingsRoutes } from '../routes';
import Translate from '../../constants/translate';
// const GeneralSettings = lazy(() => import('./profile'));
// const CompanySettings = lazy(() => import('./company'));
// const ProductSettings = lazy(() => import('./products'));

import GeneralSettings from './profile';
import CompanySettings from './company';
import ProductSettings from './products';
import ModuleSettings from './modules';
import IntegrationSettings from './integrations'

class SettingsLayut extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        //console.log(SettingsRoutes)
        return (
            <div className="settings-wrapper">
                <div className="settings-leftmenu">
                    <h3>Admin Settings</h3>
                    <Suspense fallback={<></>}>
                        <ul>
                            {
                                AdminSettingsRoutes.map((rec, index) => {
                                    return <li key={index}>
                                        <NavLink key={index} to={rec.route} activeClassName={"active"}>
                                            <i className={rec.class}></i><Translate text={rec.name.toLowerCase()} />
                                        </NavLink>
                                    </li>
                                })
                            }
                        </ul>
                    </Suspense>
                </div>
                <div className="settings-middlewraper">
                    <div className="settings-middlewraper-sub">
                        <Suspense fallback={<></>}>
                            <Switch>
                                <Route path="/admin/settings/profile" exact component={GeneralSettings} />
                                <Route path="/admin/settings/company" exact component={CompanySettings} />
                                <Route path="/admin/settings/product" exact component={ProductSettings} />
                                <Route path="/admin/settings/modules" exact component={ModuleSettings} />
                                <Route path="/admin/settings/integrations" exact component={IntegrationSettings} />
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </div>
        );
    }
}

export default SettingsLayut;