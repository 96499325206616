import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
import { InputText, AntDateRangePicker } from '../../components'
import Swal from 'sweetalert2'



const SyncData = (props) => {

    const [collection, setCollection] = useState(props.collection);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false);
    const newCollection = !_.isEmpty(props.collection) ? false : true

    //console.log(props.meiliSearchConfig);

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email') {
            setCollection({
                ...collection,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setCollection({
                    ...collection,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setCollection({
                    ...collection,
                    [name]: str
                });
            }
        }

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }


    const syncData = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(collection.dateRange)) {
            formIsValid = false;
            errors['collection-dateRange'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        const dataRef = await window.firebase.firestore().collection(collection.value)
            .where('modifiedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(collection.dateRange.startDate).toDate()))
            .where('modifiedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(collection.dateRange.endDate).toDate()))
            .get();
        const _syncdata = [];
        dataRef.docs.forEach((doc) => {
            _syncdata.push({
                ...doc.data(),
                _firestore_id: doc.id
            });
        });
        //console.log(_syncdata)
        try {
            const index = props.meiliSearchConfig.index(collection.value)
            let response = await index.addDocuments(_syncdata)
            //console.log(response)
            setLoader(false);
            Swal.fire(`${_syncdata.length} records updated`, '', 'success')
        }
        catch (err) {
            console.log(err)
            setLoader(false);
            Swal.fire(`${JSON.stringify(err)}`, '', 'error')
        }

        props.handleClose();
    }

    return _.isEmpty(collection) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-update-password"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <div className="settings-tab-fields-wrap">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Collection</label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'name'}
                                                        className={`form-control ${errorFields["name"]}`}
                                                        name="name"
                                                        onChange={handleOnChange}
                                                        value={collection.name}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label >Date Range</label>
                                                    <AntDateRangePicker
                                                        value={collection.dateRange ? [collection.dateRange.startDate, collection.dateRange.endDate] : null}
                                                        id={"dashboard-date-range"}
                                                        name={'collection-dateRange'}
                                                        format='DD/MM/YYYY'
                                                        onChange={(val) => {
                                                            if (val) {
                                                                setCollection({
                                                                    ...collection,
                                                                    dateRange: {
                                                                        startDate: val[0].startOf('day'),
                                                                        endDate: val[1].endOf('day')
                                                                    }
                                                                })
                                                            }
                                                            else {
                                                                setCollection({
                                                                    ...collection,
                                                                    dateRange: null
                                                                })
                                                            }
                                                        }}
                                                        placeholder='DD/MM/YYYY'
                                                        className={` ${errorFields["collection-dateRange"]}`}

                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => syncData(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Sync
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SyncData;