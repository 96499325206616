import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import Translate from "../../constants/translate";
import CommonHelper from "../../services/common";
import _ from 'lodash'

const TriggerHistoryModal = (props) => {
  const events = _.isArray(props.data?.events) ? props.data?.events
    ?.filter((element) => {
      return element?.name;
    })
    ?.sort((a, b) => b?.date?.seconds - a?.date?.seconds) : [];
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      backdropClassName={props.show ? "overlay-modal active" : ""}
      dialogClassName="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-trigger-log-popup"
      enforceFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="">
          {props.title}
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={props.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          props.data?.email?.error && _.isEmpty(props.data?.email?.mailgunId) ? (
            <div className="alert alert-danger" role="alert">
              {props.data?.email?.error}
            </div>
          ) : (<></>)
        }
        <div className="trigger-log-container">
          <div className="enquiry-flow mb-3">
            <div className="enquiry-flow-section">
              <div className="enquiry-flow-wrap">
                <div className="flow-timelinebar"></div>

                {events?.length > 0 && (
                  <div className="enquiry-flow-item mb-4">
                    <div className="flow-item">
                      <div className="flow-item-content">
                        <div className="flow-text-wrap">
                          {events?.map((item) => {
                            if (!item?.name || item?.name === "accepted") {
                              return null;
                            }
                            return (
                              <div className="flow-text-wrap">
                                <div className="flow-text">
                                  <div className="flow-activity-title">
                                    <span className="log-sub">
                                      <div
                                        className={`badge badge-pill badge-input-${item?.name} mr-1 text-capitalize`}
                                      >
                                        {item?.name}
                                      </div>
                                      {
                                        item?.email ? (
                                          <div className="flow-activity-date">
                                            {item?.email}
                                          </div>
                                        ) : (<></>)
                                      }
                                      <div className="flow-activity-date">
                                        {item?.date
                                          ? moment
                                            .unix(item?.date?.seconds)
                                            .format("DD/MM/YYYY hh:mm A")
                                          : ""}
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <span className="flow-icon">
                      <i className="ico icon-email-log"></i>
                    </span>
                  </div>
                )}

                <div className="enquiry-flow-item mb-4">
                  <div className="flow-item">
                    <div className="flow-item-content">
                      <div className="flow-text-wrap">
                        <div className="flow-text">
                          <div className="flow-activity-title">
                            <span className="log-sub">
                              {props?.data?.isDone &&
                                (props?.data?.email?.mailgunId || props?.data?.email?.mailjetId) &&
                                !props?.data?.email?.error ? (
                                <div className="badge badge-pill  badge-input-delivered">
                                  <Translate text={`Sent`} />
                                </div>
                              ) : props?.data?.isDone &&
                                props?.data?.email?.error ? (
                                <div className="badge badge-pill  badge-input-failed">
                                  <Translate text={`failed`} />
                                </div>
                              ) : (
                                <div className="badge badge-pill  badge-input-unsubscribed">
                                  <Translate text={`Pending`} />
                                </div>
                              )}
                              {
                                props?.data?.contact?.firstName ? (
                                  <div className="flow-activity-date">
                                    <span>
                                      Contact:
                                      <strong>
                                        {CommonHelper.displayContactName([], props?.data?.contact)}
                                      </strong>
                                    </span>
                                  </div>
                                ) : (<></>)
                              }
                              {
                                props?.data?.email?.to ? (
                                  <div className="flow-activity-date">
                                    <span>
                                      Sent to:
                                      <strong>
                                        {props?.data?.email?.to || ""}
                                      </strong>
                                    </span>
                                  </div>
                                ) : (<></>)
                              }
                              {
                                props?.data?.email?.mailCc ? (
                                  <div className="flow-activity-date">
                                    <span>
                                      Cc:
                                      <strong>
                                        {props?.data?.email?.mailCc || ""}
                                      </strong>
                                    </span>
                                  </div>
                                ) : (<></>)
                              }
                              {
                                props?.data?.email?.mailBcc ? (
                                  <div className="flow-activity-date">
                                    <span>
                                      Bcc:
                                      <strong>
                                        {props?.data?.email?.mailBcc || ""}
                                      </strong>
                                    </span>
                                  </div>
                                ) : (<></>)
                              }
                              <div className="flow-activity-date">
                                {props.data?.startDate
                                  ? moment
                                    .unix(props.data?.startDate.seconds)
                                    .format("DD/MM/YYYY hh:mm A")
                                  : ""}{" "}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="flow-icon">
                    <i className="ico icon-email-log"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TriggerHistoryModal;
