/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker, AntMonthPicker
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { serviceStatus, serviceStatuses } from '../../services/enum'
import { objModules } from './viewModel'

//#region  ASSIGN VARIABLES

const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'all', label: <Translate text={'all'} /> },
    { active: true, value: 'addedDate', label: <Translate text={'addedDate'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'modifiedDate'} /> },
]

const attendeeStatuses = [
    { active: true, value: 'accepted', name: 'Accepted', label: <div className={`badge badge-pill badge-events-accepted`}><Translate text={'accepted'} upperCase={true} /></div> },
    { active: true, value: 'tentative', name: 'Tentative', label: <div className={`badge badge-pill badge-events-tentative`}><Translate text={'tentative'} upperCase={true} /></div> },
    { active: true, value: 'declined', name: 'Declined', label: <div className={`badge badge-pill badge-events-declined`}><Translate text={'declined'} upperCase={true} /></div> },

]

const allocationStatuses = [
    { active: true, value: 'pending', name: 'Pending', label: <div className={`badge badge-pill status-pending`}><Translate text={'pending'} upperCase={true} /></div> },
    { active: true, value: 'accepted', name: 'Accepted', label: <div className={`badge badge-pill status-accepted`}><Translate text={'accepted'} upperCase={true} /></div> }

]

const yesNoOptions = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' }
]

let _enquiryAllStatus = [];
let event_slots = [];
let data_location = [];

const objDateField = {
    name: null,
    type: null,
    range: null
}
//#endregion

const PipelineAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const { columnFields } = props;

    const [dealers, setDealers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [regions, setRegions] = useState([]);
    const [subregions, setSubRegions] = useState([]);

    const [dietary_preferences, setdietary_preferences] = useState([]);

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        const { pipelineFilter } = props;
        if (!_.isEmpty(pipelineFilter))
            setFilterFields(pipelineFilter.value);
        else
            setFilterFields({});

    }, [props.pipelineFilter])

    useEffect(() => {
        let _dietary_preferences = [];
        props.eventSettings?.dietary && props.eventSettings.dietary.forEach((doc) => {
            _dietary_preferences.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        event_slots = [];
        props.eventSettings?.slots && props.eventSettings.slots.forEach((doc) => {
            event_slots.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        data_location = [];
        props.eventSettings?.dataLocation && props.eventSettings.dataLocation.forEach((doc) => {
            data_location.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        setdietary_preferences(_dietary_preferences)

    }, [props.eventSettings])

    useEffect(() => {
        const { dealersettings, clientUsers, pipelineFilter } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.clients)) {
            let _dealers = [];
            dealersettings.clients.filter(c =>
                c.moduleSettings
                && c.moduleSettings.eventsPro
                && c.moduleSettings.eventsPro.enabled === true
                && c.moduleSettings.eventsPro.active === true
            ).forEach(rec => {
                _dealers.push({
                    ...rec,
                    label: rec.name,
                    value: rec.id,
                    active: true,
                })
            })

            let _subregions = [];
            dealersettings.subregions && dealersettings.subregions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.subregion === doc.id)) {
                    _subregions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            dealersettings.groups && dealersettings.groups.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.group === doc.id)) {
                    _groups.push({
                        ...doc,
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });
            setGroups(_groups);
            setRegions(_regions);
            setDealers(_dealers);
            setSubRegions(_subregions)
        }


        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.client.brands;
            const _makes = [];

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    models: doc.models
                });
            });

            _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.filter(e => !_.includes(['delivered', 'pendingLost', 'prospectLost', 'pending', 'closeWon'], e.value) && !Boolean(e.afterSold)).forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }


            setClientUsers(_clientUsers);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }
    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'region') {
            delete state['subregion']
            delete state['group']
            state['dealer'] = ''
        }

        if (data.name === 'subregion') {
            delete state['group']
            state['dealer'] = ''
        }

        if (data.name === 'group') {
            state['dealer'] = ''
        }

        state[data.name] = value;

        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'region' && e) {
            state[data.name] = e.value;
            delete state['subregion']
            delete state['group']
            state['dealer'] = ''
            setFilterFields(state)
        }
        else {
            state[data.name] = e ? e.value : '';
            setFilterFields(state)
        }

    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'region') {
                state[name] = null;
                //state['dealer'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'region') {
                delete state['dealer'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);

    }


    const handlePIDateSave = (val, name) => {
        let state = Object.assign({}, filterFields);
        if (val) val = moment(val).format('MMM YYYY');
        state[name] = val;
        setFilterFields(state);
    };

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)
    }

    const onNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  
        let state = Object.assign({}, filterFields);
        state[name] = e.value;
        setFilterFields(state)
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.pipelineFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.pipelineFilter) && !_.isEmpty(props.pipelineFilter.value) && JSON.stringify(props.pipelineFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.pipelineFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };

    const loadDealers = () => {
        if (!_.isEmpty(filterFields?.group)) {
            let _sub = Object.assign([], filterFields.group.split(','))
            return dealers.filter(e => _sub.includes(e.group))
        }
        else if (!_.isEmpty(filterFields?.subregion)) {
            let _sub = Object.assign([], filterFields.subregion.split(','))
            return dealers.filter(e => _sub.includes(e.subregion))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            return dealers.filter(e => _regs.includes(e.region))
        }
        else
            return dealers
    }

    const loadGroups = () => {
        let _groups = [];
        if (!_.isEmpty(filterFields?.subregion)) {
            let _regs = Object.assign([], filterFields.subregion.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.subregion)))
        }
        else if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _groups = groups.filter(doc => dealers.some(client => client.group === doc.value && _regs.includes(client.region)))
        }
        else
            _groups = groups

        return _groups;
    }

    const loadSubRegions = () => {
        let _subregions = [];
        if (!_.isEmpty(filterFields?.region)) {
            let _regs = Object.assign([], filterFields.region.split(','))
            _subregions = subregions.filter(doc => dealers.some(client => client.subregion === doc.value && _regs.includes(client.region)))
        }
        else _subregions = subregions

        return _subregions;
    }

    //#endregion

    //#region DYNAMIC CHANGE EVENTS
    const handleDynamicCheckChange = (e, _type) => {
        const { name, checked } = e.target;
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (checked) {
            dynamicFields.push({
                'name': name, 'type': _type
            })
        }
        else {
            dynamicFields = dynamicFields.filter(e => e.name !== name)
        }
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = value ? value.trim() : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const onDynamicCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = e ? e.floatValue : null;
            }
        });

        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = value;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicSwitchOption = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === data.name) {
                obj.value = e ? (e.value === 'Yes' ? true : false) : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
    }

    const handleDynamicDateTypeChange = (e, data) => {

        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        if (e) {
            dynamicFields.filter(function (obj) {
                if (obj.name === data.name) {
                    obj.dateType = e.value;

                    if (e.value === 'custom') {
                        obj.range = null;
                        obj.startDate = null;
                        obj.endDate = null;
                    }
                    else {
                        let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                        for (let [key, value] of Object.entries(objData)) {
                            obj[key] = value;
                        }
                    }

                }
            });
            setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });
        }
    }


    const handleDynamicDateRangeChange = (val, name) => {
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);

        dynamicFields.filter(function (obj) {
            if (obj.name === name) {

                if (obj.dateType === 'custom' && val) {
                    obj.range = val;
                    let objData = CommonHelper.handleDynamicDateRangeFilter(obj, props.dealersettings)
                    for (let [key, value] of Object.entries(objData)) {
                        obj[key] = value;
                    }
                }
                else {
                    obj.range = null;
                    obj.startDate = null;
                    obj.endDate = null;
                }
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });


    };

    const handleDynamicMonthChange = (val, name) => {
        let dynamicFields = Object.assign([], filterFields?.dynamicFields);
        dynamicFields.filter(function (obj) {
            if (obj.name === name) {
                obj.value = val ? moment(val).format('MMM YYYY') : null;
            }
        });
        setFilterFields({ ...filterFields, 'dynamicFields': dynamicFields });

    };
    //#endregion

    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader">   <Translate text={'attendees'} /> </div>

                <div className="advanced-filter-list">
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('dealer') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'dealer'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealer')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'dealer'}><Translate text={'region&dealer'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('dealer')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={regions}
                                                        name={"region"}
                                                        placeholder={'select regions'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('region') && filterFields.region ? filterFields.region.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={loadSubRegions()}
                                                        name={"subregion"}
                                                        placeholder={'select sub regions'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('subregion') && filterFields.subregion ? filterFields.subregion.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={loadGroups()}
                                                        name={"group"}
                                                        placeholder={'select groups'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('group') && filterFields.group ? filterFields.group.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={loadDealers()}
                                                        name={"dealer"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('dealer') && filterFields.dealer ? filterFields.dealer.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    //menuPlacement={'top'}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>



                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'RSVP Status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={attendeeStatuses}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('status') && filterFields.status ? filterFields.status.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('allocationStatus') >= 0)
                            ?
                            <>
                                {/* allocationStatus */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'allocationStatus'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('allocationStatus')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'allocationStatus'}><Translate text={'allocationStatus'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('allocationStatus')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={allocationStatuses}
                                                        name={"allocationStatus"}
                                                        placeholder={'select allocation status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('allocationStatus') ? filterFields.allocationStatus : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('leadStatus') >= 0)
                            ?
                            <>
                                {/* leadStatus */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'leadStatus'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadStatus')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'leadStatus'}><Translate text={'leadStatus'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadStatus')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={_enquiryAllStatus}
                                                        name={"leadStatus"}
                                                        placeholder={'select lead status'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('leadStatus') && filterFields.leadStatus ? filterFields.leadStatus.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isNew') >= 0)
                            ?
                            <>
                                {/* isNew */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isNew'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isNew')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isNew'}><Translate text={'New Attendees'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isNew')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'New Attendees'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isNew"
                                                                checked={filterFields.hasOwnProperty('isNew') ? filterFields.isNew : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isNew') }} />
                                                            <label htmlFor="_isNew"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isCheckedIn') >= 0)
                            ?
                            <>
                                {/* isCheckedIn */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isCheckedIn'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isCheckedIn')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isCheckedIn'}><Translate text={'Attendees Checked-In'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isCheckedIn')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Attendees Checked-In'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isCheckedIn"
                                                                checked={filterFields.hasOwnProperty('isCheckedIn') ? filterFields.isCheckedIn : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isCheckedIn') }} />
                                                            <label htmlFor="_isCheckedIn"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isTestDrive') >= 0)
                            ?
                            <>
                                {/* isTestDrive */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isTestDrive'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isTestDrive'}><Translate text={'Attendees Test Drive'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isTestDrive')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Attendees Test Drive'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isTestDrive"
                                                                checked={filterFields.hasOwnProperty('isTestDrive') ? filterFields.isTestDrive : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isTestDrive') }} />
                                                            <label htmlFor="_isTestDrive"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('dietaryPreference') >= 0)
                            ?
                            <>
                                {/* dietaryPreference */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'dietaryPreference'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('dietaryPreference')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'dietaryPreference'}><Translate text={'dietaryPreference'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('dietaryPreference')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={dietary_preferences}
                                                        name={"dietaryPreference"}
                                                        placeholder={'select dietary preference'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('dietaryPreference') && filterFields.dietaryPreference ? filterFields.dietaryPreference.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('eventSlot') >= 0)
                            ?
                            <>
                                {/* eventSlot */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'eventSlot'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('eventSlot')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'eventSlot'}><Translate text={'eventSlot'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('eventSlot')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={event_slots}
                                                        name={"eventSlot"}
                                                        placeholder={'select event slot'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('eventSlot') && filterFields.eventSlot ? filterFields.eventSlot.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('dataLocation') >= 0)
                            ?
                            <>
                                {/* dataLocation */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'dataLocation'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('dataLocation')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'dataLocation'}><Translate text={'dataLocation'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('dataLocation')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={data_location}
                                                        name={"dataLocation"}
                                                        placeholder={'select data location'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('dataLocation') && filterFields.dataLocation ? filterFields.dataLocation.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        props.eventSettings?.attendeesDF && props.eventSettings.attendeesDF.filter(m => m.active === true && m.type !== 'date').map((info, index) => {
                            return <Fragment key={index}>

                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={info.value}
                                            checked={(!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value })) ? true : false}
                                            onChange={(e) => { handleDynamicCheckChange(e, info.type) }} />
                                        <label htmlFor={info.value}><Translate text={info.name} /></label>
                                    </div>
                                </div>
                                {
                                    (!_.isEmpty(filterFields) && filterFields.dynamicFields && _.find(filterFields.dynamicFields, { name: info.value }))
                                        ?
                                        <>
                                            <div className="advanced-filter-box form-style">


                                                {(() => {
                                                    let dynamicfilterFields = _.find(filterFields.dynamicFields, { name: info.value })

                                                    if (info.type === 'toggle' || info.type === 'switch') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactSelect
                                                                            options={yesNoOptions}
                                                                            id={index + '_' + info.value}
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicSwitchOption}
                                                                            value={_.isBoolean(dynamicfilterFields?.value) ? (dynamicfilterFields.value ? 'Yes' : 'No') : null}
                                                                            classNamePrefix={`cursor-pointer basic-select`}
                                                                            removeClearable={true}
                                                                        >
                                                                        </ReactSelect>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'text') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <InputText
                                                                            name={info.value}
                                                                            onChange={handleDynamicOnChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields.value : ''}
                                                                            autoComplete="off"
                                                                            placeholder={info.name}
                                                                            className={`form-control`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'number') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={false}
                                                                            thousandSeparator={false}
                                                                            placeholder={info.name}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : ''}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'price') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <NumberFormat
                                                                            id={info.value}
                                                                            name={info.value}
                                                                            allowNegative={true}
                                                                            decimalScale={2}
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            placeholder={currencySymbol}
                                                                            className={`form-control `}
                                                                            onValueChange={onDynamicCurrencyChange.bind(this, info.value)}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value : null}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>

                                                        )
                                                    }
                                                    else if (info.type === 'monthYear') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <AntMonthPicker
                                                                            value={dynamicfilterFields?.value && _.isString(dynamicfilterFields?.value) ? moment(dynamicfilterFields?.value)._d : null}
                                                                            id={info.value}
                                                                            onChange={(e) => { handleDynamicMonthChange(e, info.value) }}
                                                                            placeholder={info.name}
                                                                            className={`form-control cursor-pointer`}
                                                                            format={'MMM YYYY'}
                                                                            picker={'month'}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    else if (info.type === 'multiselect' || info.type === 'select') {
                                                        return (
                                                            <>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-12">
                                                                        <ReactMultiSelect
                                                                            options={
                                                                                !_.isEmpty(info.subList) ?
                                                                                    _.map(info.subList, function (e) { return { label: e, value: e } })
                                                                                    : []
                                                                            }
                                                                            name={info.value}
                                                                            placeholder={info.name}
                                                                            onChange={handleDynamicMultiSelectChange}
                                                                            value={dynamicfilterFields?.value ? dynamicfilterFields?.value.split(',') : []}
                                                                            classNamePrefix={`basic-select`}
                                                                            isMulti={true}
                                                                            isSearchable={true}
                                                                        >
                                                                        </ReactMultiSelect>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    // else if (info.type === 'date') {
                                                    //     return (
                                                    //         <>
                                                    //             <div className="form-row">
                                                    //                 <div className="form-group col-md-12">
                                                    //                     <ReactSelect
                                                    //                         options={dateOptions}
                                                    //                         id={'_' + info.value}
                                                    //                         name={info.value}
                                                    //                         placeholder={'select date option'}
                                                    //                         onChange={handleDynamicDateTypeChange}
                                                    //                         value={
                                                    //                             (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType))
                                                    //                                 ? dynamicfilterFields.dateType :
                                                    //                                 null
                                                    //                         }
                                                    //                         classNamePrefix={`cursor-pointer basic-select`}
                                                    //                         removeClearable={true}
                                                    //                     >
                                                    //                     </ReactSelect>
                                                    //                 </div>
                                                    //             </div>
                                                    //             {
                                                    //                 (!_.isEmpty(dynamicfilterFields) && !_.isEmpty(dynamicfilterFields.dateType) && dynamicfilterFields.dateType === 'custom')
                                                    //                     ?
                                                    //                     <>
                                                    //                         <div className="form-row">
                                                    //                             <div className="form-group col-md-12">
                                                    //                                 <AntDateRangePicker
                                                    //                                     value={
                                                    //                                         !_.isEmpty(dynamicfilterFields?.range)
                                                    //                                             ? dynamicfilterFields?.range :
                                                    //                                             null
                                                    //                                     }
                                                    //                                     id={index + '_' + info.value}
                                                    //                                     name={index + '_' + info.value}
                                                    //                                     format='DD/MM/YYYY'
                                                    //                                     onChange={(e) => { handleDynamicDateRangeChange(e, info.value) }}
                                                    //                                     placeholder='DD/MM/YYYY'
                                                    //                                 />
                                                    //                             </div>
                                                    //                         </div>
                                                    //                     </>
                                                    //                     :
                                                    //                     <></>
                                                    //             }

                                                    //         </>
                                                    //     )
                                                    // }

                                                })()}

                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </Fragment>
                        })
                    }

                </div>
            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className={`btn btn-default float-left ${props.noSaveAccess ? '' : 'ml-2'}`} onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }

            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.pipelineFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'eventsProAttendees'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default PipelineAdvanceFilters;