/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/tradein.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { objTradeIn, tradeinSortOptions } from '../../tradeIn/viewModel'
import { objTradeIP } from '../viewModel'
import { tipStatus } from '../../../services/enum';

const TradeInDetails = (props) => {
    const [tradeInPro, setTradeInPro] = useState(objTradeIP)
    const [tradeIn, setTradeIn] = useState(objTradeIn)
    const [tradeinProID, setTradeInID] = useState(null)
    const [isPDF, setPDF] = useState(true)
    const [accessToShowTradeInPrice, setShowPrice] = useState(true)

    const [enquiryOptionsDF, setenquiryOptionsDF] = useState([])
    const [tradeinOptionsDF, settradeinOptionsDF] = useState([])
    const [dealersettings, setdealerSettings] = useState({})

    const {
        status, addedBy, offer, owner, addedDate, lostDate, origin,
        client, users, lostReason, lostSubReason
    } = tradeInPro;

    const {
        specifications, tags,
        notes,
        images, marks, inspectionSnapshot, sidePhotos,

    } = tradeIn;

    let _tags = _.uniqBy((tradeInPro?.tags || []), _.union((tags || [])), 'type')

    const currencySymbol = ((!_.isEmpty(tradeInPro) &&
        !_.isEmpty(tradeInPro.client) &&
        !_.isEmpty(tradeInPro.client.currency)) ? tradeInPro.client.currency.symbol + ' ' : '$ ');

    const _settings = !_.isEmpty(tradeInPro?.currentSettings?.tradeinPro) ? tradeInPro.currentSettings.tradeinPro : null;
    let objCurrentStatus = status && _settings && _settings.tradeinProStatus && _settings.tradeinProStatus.filter(e => e.value === status)[0]
    const _isTradeinCompleted = [tipStatus.COMPLETED, tipStatus.WON, tipStatus.LOST].includes(status) ? true : false;
    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`tradeinPro/${props.tradeinid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('TradeInDetails', props.tradeinid);
        if (props.tradeinid) {
            loadTradeIn();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadTradeIn = async () => {


        window.refCollectionData = docData(firestoreDB.firestore().doc(`tradeinPro/${props.tradeinid}`)//.doc(props.tradeinid).get()
            // .where('documentID', '==', props.tradeinid)
            // .where('isDeleted', '==', false)
        )
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);

                    const enqIds = tradeIns?.tradeinID ? [tradeIns.tradeinID] :
                        (tradeIns?.tradein?.documentID ? [tradeIns.tradein.documentID] : []);
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            enqIds.length > 0 ? enqIds.map(tradeID =>
                                collectionData(firestoreDB.firestore().collection('tradeins').where('documentID', '==', tradeID)).pipe(
                                    map(tradeinSnapshot => tradeinSnapshot[0] ? tradeinSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                    map(clientSettingsSnapshot => clientSettingsSnapshot)
                                )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                    map(usersSnapshot => usersSnapshot)
                                )
                            ) : of([])
                        ),
                    )
                }),
                map(([tradeIns, tradeinSnapshot, clientSnapshot, clientSettingsSnapshot, usersSnapshot]) => {

                    return {
                        ...tradeIns,
                        tradein: tradeinSnapshot[0] ? tradeinSnapshot[0] : tradeIns.tradein,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        currentSettings: clientSettingsSnapshot[0],
                        users: usersSnapshot[0],
                    }

                })
            )
            .subscribe(_tradeIns => {
                let search = window.location.search;
                let params = new URLSearchParams(search);
                let backURL = params.get('isView');

                setPDF(backURL ? false : true);

                if (backURL === 'yes')
                    setShowPrice(false)

                if (!_.isEmpty(_tradeIns)) {
                    //console.log('_tradeIns', _tradeIns)
                    //setTradeInPro(_tradeIns)
                    //setTradeIn(_tradeIns.tradein);

                    let _dealerSettings = {};
                    let _client = Object.assign({}, _tradeIns.client);
                    let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                    _dealerSettings.client = Object.assign({
                        ..._client,
                        id: _tradeIns.clientID,
                        name: _clientSettings.name,
                        timezone: _clientSettings.timezone,
                        phone: _clientSettings.phone,
                        logoURL: _clientSettings.logoURL,
                        address: _clientSettings.address,
                        moduleSettings: _clientSettings.moduleSettings,
                        currency: _clientSettings.settings && _clientSettings.settings.currency,
                        integrations: _clientSettings.integrations,
                        settings: _clientSettings?.settings,
                        tradeinPro: _clientSettings?.tradeinPro
                    });

                    let _tradeinOptionsDF = [];
                    if (_dealerSettings?.client?.settings?.tradeinOptionsDF)
                        _tradeinOptionsDF = _dealerSettings?.client?.settings?.tradeinOptionsDF;
                    settradeinOptionsDF(_tradeinOptionsDF);

                    let _enquiryOptionsDF = [];
                    if (_dealerSettings?.client?.tradeinPro?.enquiryOptionsDF)
                        _enquiryOptionsDF = _dealerSettings?.client?.tradeinPro?.enquiryOptionsDF;
                    setenquiryOptionsDF(_enquiryOptionsDF);
                    setdealerSettings(_dealerSettings);

                    saveTradeinPro(_tradeIns, _enquiryOptionsDF)
                    saveTradein(_tradeIns.tradein, _tradeinOptionsDF);

                    setTradeInID(props.tradeinid)

                    if (_tradeIns?.tradein?.sent && _tradeIns?.tradein?.evalexpertID) {
                        getDetailsFromEvalExpert(_tradeIns?.tradein?.documentID, _tradeIns.tradein);
                    }

                }


            });

    }

    const saveTradein = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : tradeinOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeIn(_fields);
    }

    const saveTradeinPro = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : enquiryOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeInPro(_fields);
    }

    const getDetailsFromEvalExpert = async (docID, _fields) => {
        try {

            const evalexpertGet = window.firebase.functions().httpsCallable('evalexpert-get');
            evalexpertGet(_fields).then((_response) => {

                if (_response && _response.data && _response.data.success && _response.data.data) {
                    //console.log('evalexpert-get--', _response.data.data[0]);
                    var tradein = CommonHelper.convertEvalMappingVM(_response.data.data[0], _fields);
                    saveTradein(tradein);
                }

            }).catch((error) => {
                //Swal.fire('Something went wrong. Please contact support.', '', 'error')
            });
        } catch (error) {
            console.log(error);
        }
    }




    const getInspection = (id, _marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        // var canvas = document.getElementById("canvas");
        var context = canvas.getContext('2d');

        var img = new Image();
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            document.getElementById(id).src = dataURL;
            //console.log('imageToBase64:', dataURL)
            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    let enquirySortOptions = [
        "origin", "lostReason", "lostSubReason"
    ];
    let dynamicDetailProList = (!_.isEmpty(dealersettings?.client?.tradeinPro?.sortEnquiryOptions))
        ? dealersettings.client.tradeinPro.sortEnquiryOptions :
        Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));


    let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.sortTradeinOptions))
        ? dealersettings.client.settings.sortTradeinOptions :
        Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

    return (
        <>

            {
                !_.isEmpty(tradeinProID)
                    ?
                    <>
                        <div>

                            <div className="quotation-wrap report-data-load">
                                <div className="print-ico">
                                    <a href="#" onClick={() => {
                                        window.print();
                                    }}>  <img src={_images.print} width="19" height="18" /> <span>Print</span></a>
                                </div>
                                <div className="wrapper">
                                    <div className="print-wrap">
                                        <div className="header">
                                            <div className="headerwrap-left">
                                                <div className="header-left">
                                                    {
                                                        client && client.logoURL
                                                            ?
                                                            <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="headerwrap-right">
                                                <div className='header-right'>
                                                    {' '}
                                                    {client.name} <br />
                                                    <span>
                                                        {' '}
                                                        {client.address ? client.address : ''}
                                                        {client.phone ? (
                                                            <>
                                                                <br />
                                                                {`Phone: ${client.phone}`}
                                                            </>
                                                        ) : <></>}
                                                        {client.email || client.website ? (
                                                            <>
                                                                <br />
                                                                {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                            </>
                                                        ) : <></>}
                                                        <br />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                        <div className="clear"></div>
                                        <div className="middle">
                                            <div className="title-head"><Translate text={'Vehicle Appraisal'} upperCase={true} /></div>
                                            <div className="clear"></div>
                                            <div className="table-head valuation-num"><Translate text={'Appraisal Information'} />
                                                {
                                                    status && objCurrentStatus
                                                        ?
                                                        <>
                                                            <div
                                                                className={`mrg-fixstatus badge-${status === tipStatus.NEW ? 'open' : 'empty'} ml-2`} style={{ background: (objCurrentStatus && objCurrentStatus.color ? objCurrentStatus.color : '#333') }}>
                                                                <Translate
                                                                    text={objCurrentStatus ? objCurrentStatus.name : status.toLowerCase()}
                                                                    upperCase={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    tradeIn.isDeletedFromTradeIn
                                                        ?
                                                        <>
                                                            <div className={`mrg-fixstatus badge-lost ml-2`}>
                                                                {'Deleted From TradeIn Plus'}
                                                            </div>
                                                        </>
                                                        :
                                                        <>

                                                        </>
                                                }

                                                {
                                                    !_.isEmpty(_tags) && _tags.map((rec, index) => {
                                                        return <div key={index}
                                                            className="badge badge-pill badge-white ml-1 badge-mini text-capitalize">
                                                            {CommonHelper.getNameByValue(!_.isEmpty(tradeInPro.client) && tradeInPro.client.integrations, rec.type)}
                                                        </div>
                                                    })
                                                }

                                                {tradeIn.EvaluationNumber ?
                                                    <div className="vehicle-valuation-num"> Valuation No.: <span style={{ color: '#4466f2' }}> {tradeIn.EvaluationNumber} </span> </div> :
                                                    <>
                                                        {
                                                            tradeIn.evalexpertID ?
                                                                <div className="vehicle-valuation-num"> Eval Expert No.: <span style={{ color: '#4466f2' }}> {tradeIn.evalexpertID} </span> </div> :
                                                                <>
                                                                    {
                                                                        tradeInPro.displayID ?
                                                                            <div className="vehicle-valuation-num"> Valuation No.: <span style={{ color: '#4466f2' }}> {tradeInPro.displayID} </span> </div> :
                                                                            <></>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                }

                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'owner'} /></span> {(!_.isEmpty(users) && owner) ? CommonHelper.getUserNamebyId(users, owner) : '--'}</div></td>
                                                            <td width="25%">
                                                                <div className="grid-one">
                                                                    <span><Translate text={'origin'} /></span>
                                                                    {origin ? CommonHelper.getNameByValue(_settings && _settings.origins, origin, '--') : '--'}
                                                                </div>
                                                            </td>

                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'createdBy'} /></span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyId(users, addedBy) : '--'}</div></td>
                                                            <td width="25%"><div className="grid-one"> <span><Translate text={'createdOn'} /></span>{addedDate ?
                                                                (!_.isEmpty(client) && client.timezone ?
                                                                    moment(moment.unix(addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                    moment.unix(addedDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                : '--'} </div></td>
                                                        </tr>


                                                        {
                                                            (status === tipStatus.LOST)
                                                                ?
                                                                <>
                                                                    {(() => {

                                                                        let _lostReasons = _settings && lostReason && _settings.lostReasons.filter(e => e.value === lostReason)[0];
                                                                        return <tr>

                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={'lostReason'} /></span>
                                                                                    {lostReason && _lostReasons ? _lostReasons.name : '--'}
                                                                                </div>
                                                                            </td>
                                                                            <td width="25%">
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={'lostSubReason'} /></span>
                                                                                    {lostSubReason ? CommonHelper.getNameByValue(_lostReasons && _lostReasons.subList, lostSubReason, '--') : '--'}
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan='2'>
                                                                                <div className="grid-one">
                                                                                    <span><Translate text={CommonHelper.getNameByValue(_settings?.tradeinProStatus, 'lost', 'Lost') + ' Date'} /></span>
                                                                                    {lostDate ?
                                                                                        (!_.isEmpty(client) && client.timezone ?
                                                                                            moment(moment.unix(lostDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') :
                                                                                            moment.unix(lostDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                                        : '--'}
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    })()}
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                        {
                                                            _.chunk(dynamicDetailProList.filter(e => !["origin", "lostReason", "lostSubReason"].includes(e)), 4).map((stats, i) => {
                                                                return <tr key={i}>
                                                                    {
                                                                        stats.map((rec, index) => {

                                                                            return <td key={index} width="25%"><div className="grid-one">

                                                                                {(() => {
                                                                                    if (objTradeIP.hasOwnProperty(rec)) {
                                                                                        return <><span><Translate text={rec} /></span>{tradeInPro[rec] ? tradeInPro[rec] : '--'}</>
                                                                                    }
                                                                                    else if (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                        let info = enquiryOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                        if (info.type === 'price')
                                                                                            return <><span><Translate text={info.name} /></span>{tradeInPro[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeInPro[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</>
                                                                                        else
                                                                                            return <><span><Translate text={info.name} /></span>{tradeInPro[rec] ? tradeInPro[rec] : '--'}</>
                                                                                    }
                                                                                })()}
                                                                            </div></td>
                                                                        })
                                                                    }
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="table-wraper mt-0">
                                                <div className="table-head valuation-num">CUSTOMER INFORMATION &nbsp;
                                                    {((tradeInPro?.contact?.displayID) ? '(' + tradeInPro.contact.displayID + ')' : '')}&nbsp;
                                                </div>
                                                <div className="table-details">
                                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span>CUSTOMER</span> {CommonHelper.displayFullContact(null, tradeInPro.contact, '--')} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'phone'} /></span>{tradeInPro?.contact?.phone ?
                                                                    CommonHelper.getFullPhone(tradeInPro?.contact?.phoneCode, tradeInPro?.contact?.phone, true)
                                                                    : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'email'} /></span> {tradeInPro?.contact?.email ? tradeInPro?.contact?.email : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'Address'} /></span>{tradeInPro?.contact?.address ? tradeInPro?.contact?.address : '--'} </div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="table-wraper brd-none mt-0">
                                                <div className="table-head">Vehicle Details </div>
                                                <div className="table-details">
                                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                        <tbody>
                                                            {
                                                                _.chunk(dynamicDetailList.filter(e => !['notes', 'specifications', 'status'].includes(e)), 4).map((stats, i) => {
                                                                    return <tr key={i}>
                                                                        {
                                                                            stats.map((rec, index) => {

                                                                                return <td key={index} width="25%"><div className="grid-one">

                                                                                    {(() => {
                                                                                        if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                                                                                            return <>
                                                                                                <span><Translate text={rec} /></span>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}
                                                                                            </>
                                                                                        }
                                                                                        else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                                                                                            return <>
                                                                                                <span><Translate text={rec} /></span>{tradeIn[rec] ? 'YES' : 'NO'}
                                                                                            </>
                                                                                        }
                                                                                        else if (rec === 'reconditioningCost') {
                                                                                            return <>
                                                                                                <span><Translate text={rec} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}
                                                                                            </>
                                                                                        }
                                                                                        else if (objTradeIn.hasOwnProperty(rec)) {
                                                                                            return <><span><Translate text={rec} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                        }
                                                                                        else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                                            let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                                            if (info.type === 'price')
                                                                                                return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</>
                                                                                            else
                                                                                                return <><span><Translate text={info.name} /></span>{tradeIn[rec] ? tradeIn[rec] : '--'}</>
                                                                                        }
                                                                                    })()}
                                                                                </div></td>
                                                                            })
                                                                        }
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div className="table-details">
                                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span>Dealer Name</span>{!_.isEmpty(client) ? client.name : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'make'} /></span>{make ? make : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'model'} /></span>{model ? model : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'modelDescription'} /></span> {modelDescription ? modelDescription : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'year'} /></span> {year ? year : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'registrationNumber'} /></span>{regNo ? regNo : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'registrationExpiry'} /></span>{regDate ? moment(regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'mileage'} /></span> {mileage ? mileage : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'chassisNo'} /></span> {chassisNo ? chassisNo : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'engineNo'} /></span>{engineNo ? engineNo : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'transmission'} /></span> {transmission ? transmission : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'type'} /></span>{type ? type : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'complianceDate'} /></span> {complianceDate ? moment(complianceDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'buildDate'} /></span> {buildDate ? moment(buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'engineType'} /></span>{engineType ? engineType : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'colour'} /></span>{exteriorColor ? exteriorColor : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'vehicleCondition'} /></span> {vehicleCondition ? vehicleCondition : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'interiorColor'} /></span>{interiorColor ? interiorColor : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'trim'} /></span>{trim ? trim : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'doors'} /></span>{doors ? doors : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'engineSize'} /></span>{engineSize ? engineSize : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'owners'} /></span> {owners ? owners : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'financeCo'} /></span>{financeCo ? financeCo : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'spareKey'} /></span>{spareKey ? spareKey : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'serviceHistory'} /></span>{serviceHistory ? serviceHistory : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'cargoBlind'} /></span>{cargoBlind ? cargoBlind : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'logBooks'} /></span>{logBooks ? logBooks : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'headRests'} /></span>{headRests ? headRests : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'frontRightWheel'} /></span>{frontRightWheel ? frontRightWheel : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'frontLeftWheel'} /></span>{frontLeftWheel ? frontLeftWheel : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'rearRightWheel'} /></span>{rearRightWheel ? rearRightWheel : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'rearLeftWheel'} /></span>{rearLeftWheel ? rearLeftWheel : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'serviceContFrom'} /></span>{serviceContFrom ? moment(serviceContFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'serviceContTo'} /></span>{serviceContTo ? moment(serviceContTo, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'lastService'} /></span>{lastService ? moment(lastService, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'warrantyExpiry'} /></span>{warrantyExpiry ? moment(warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'} </div></td>
                                                            </tr>
                                                            <tr>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'spareWheel'} /></span>{spareTyre ? spareTyre : '--'} </div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'sdCard'} /></span>{sdCard ? sdCard : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'tyreAge'} /></span>{tyreAge ? tyreAge : '--'}</div></td>
                                                                <td width="25%"><div className="grid-one"> <span><Translate text={'reconditioningCost'} /></span>{reconditioningCost ? <NumberFormat style={{ fontSize: 12 }} value={reconditioningCost} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="subDept"><Translate text={'massageVentilated'} /></span>{massageVentilated ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'contrastStiching'} /></span>{contrastStiching ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'sunroof'} /></span>{sunroof ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'camera'} /></span>{camera ? 'YES' : 'NO'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="subDept"><Translate text={'woodSteering'} /></span>{woodSteering ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'warranty'} /></span>{warranty ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'reconditioning'} /></span>{reconditioning ? 'YES' : 'NO'}</td>
                                                                <td><span className="subDept"><Translate text={'financed'} /></span>{financed ? 'YES' : 'NO'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><span className="subDept"><Translate text={'outstandingPayments'} /></span>{outstandingPayments ? 'YES' : 'NO'}</td>
                                                                <td width="25%"><div className="grid-one"> <span>Created By</span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyDocumentId(users, addedBy) : '--'} </div></td>
                                                                <td><span className="subDept"></span>&nbsp;</td>
                                                                <td><span className="subDept"></span>&nbsp;</td>
                                                                <td><span className="subDept"></span>&nbsp;</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div> */}
                                            </div>

                                            <div className="mid-wrap mt-2">
                                                <div className="leftbox">
                                                    <h4><Translate text={'vehicleImages'} /></h4>
                                                    <>
                                                        {
                                                            (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                                ?
                                                                <>
                                                                    {
                                                                        tradeIn?.documentID
                                                                            ?
                                                                            <>
                                                                                <div className="report-vehicle-link-wrap">
                                                                                    {/* <p>Please find the requested pictures in the link below.</p> */}
                                                                                    <div className="button-view"><a target="_blank" href={`${window.location.origin}/tradein/images/${tradeIn.documentID}`} className="gallery-link btn-bg ">Click here to view images</a></div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {/* {
                                                                                    !_.isEmpty(images) && images.map((data, index) => (
                                                                                        <div className="gridimg" key={index}>
                                                                                            <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="60" height="60">
                                                                                                <img id={`tradein-before-img-${index}`} src={data} width="60" height="60" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))
                                                                                }

                                                                                {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                                                    if (sidePhotos[key]) {
                                                                                        return (<div className="gridimg" key={index}>
                                                                                            <a data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="60" height="60">
                                                                                                <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="60" height="60" alt="" className="img-object-fit" />
                                                                                            </a>
                                                                                        </div>)
                                                                                    }

                                                                                })}

                                                                                {
                                                                                    !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                                        return <div key={index}>
                                                                                            {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                                <div className="gridimg" key={index}>
                                                                                                    <a data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="60" height="60">
                                                                                                        <img id={`tradein-mark-img-${index}`} src={data} width="60" height="60" alt="" className="img-object-fit" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    })
                                                                                } */}
                                                                            </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="gridimg">
                                                                        <a href="#" onClick={(e) => { e.preventDefault(); }} width="60" height="60">
                                                                            <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="60" height="60" alt="" className="img-object-fit" />
                                                                        </a>
                                                                    </div>
                                                                </>
                                                        }

                                                    </>
                                                </div>
                                                <div className="rightbox">
                                                    <div className="report-insp-wrap">
                                                        <a data-fancybox={`tradein_inspection_img`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="60" height="60">
                                                            <img id={`tradein_inspection_img`} src={getInspection(`tradein_inspection_img`, marks)}
                                                                width="750"
                                                                height="1118"
                                                                alt="" />
                                                        </a>

                                                    </div>
                                                    {/* <div className="inspection-wrap">
                                                        <img
                                                            src={_images.inspection}
                                                            alt=""
                                                            width="395"
                                                            height="600"
                                                        />

                                                        {
                                                            (!_.isEmpty(marks))
                                                                ?
                                                                marks.map((mark, index) => {
                                                                    return <img key={index}
                                                                        src={require(`../../../images/${mark.markType}.png`)}
                                                                        style={{ top: (mark.dy / 2) + "px", left: (mark.dx / 2) + "px" }}
                                                                        alt={mark.markType + '-' + index}
                                                                    />
                                                                })
                                                                :
                                                                <></>
                                                        }

                                                    </div> */}

                                                </div>
                                            </div>

                                            {
                                                !_.isEmpty(specifications) && !_.isEmpty(dynamicDetailList.filter(e => ['specifications'].includes(e))[0])
                                                    ?
                                                    <>
                                                        <div className="table-wraper brd-none page-break mt-2">
                                                            <div className="table-head"><Translate text={'Specifications'} /></div>
                                                            <div className="terms text-area-space mt-2"
                                                                dangerouslySetInnerHTML={{ __html: (specifications ? '&middot; ' + specifications.replace(/\n/g, "<br/> &middot; ") : '--') }} />

                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            {
                                                offer && offer.dealerPrice && _isTradeinCompleted
                                                    ?
                                                    <div className="table-wraper mt-0">
                                                        <div className="table-head"> <Translate text={'Offer Price'} /> </div>
                                                        <div className="offers">
                                                            <ul>
                                                                <li className="offer-green" style={{ marginBottom: 0 }}>
                                                                    <div className="offer-tick"><img src={_images.tickCompleted} width="50" height="46" /></div>
                                                                    <h3 style={{ marginTop: 7 }}><NumberFormat value={offer.dealerPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> </h3>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : <></>
                                            }

                                            {
                                                !_.isEmpty(dynamicDetailList.filter(e => ['notes'].includes(e))[0]) && !_.isEmpty(notes)
                                                    ?
                                                    <div className="right-notes pt-2">
                                                        {/* <h4><Translate text={'notes'} /></h4> */}
                                                        <div className="notes text-area-space">{notes ? notes : '--'}</div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="push"></div>
                                </div>
                                <div className="clear"></div>
                                <div className="footer">
                                    {/* <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client?.address ? <>{client.address}</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div> */}
                                    <div className="footer-cpy">
                                        {/* <div className="footer-left">Powered by FusionSD.</div> */}
                                        <div className="footer-left">
                                            Powered by{' '}
                                            <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a>
                                        </div>

                                        <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                        </div>


                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>

            }
        </>



    )
};

export default TradeInDetails;
