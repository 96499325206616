/** LIBRARIES */
import React, { Suspense, useEffect, useContext, Fragment } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import _ from 'lodash'
/** COMPONENTS */
import { SettingsRoutes } from '../../constants/routes';
import Translate from '../../constants/translate';
import { MainContext } from '../layout/provider';
//  const GeneralSettings = lazy(() => import('./profile'));
//  const CompanySettings = lazy(() => import('./company'));
//  const WorkFlowSettings = lazy(() => import('./workflow'));
//  const ProductSettings = lazy(() => import('./products'));

import GeneralSettings from './profile';
import CompanySettings from './company';
import WorkFlowSettings from './workflow';
import ServiceWorkFlowSettings from './serviceworkflow';
import ProductSettings from './products';
import ModuleSettings from './moduleSettings';
import IntegrationSettings from './integrationSettings';
import SecurityManagementSettings from './securitymanagement';
import TradeinProWorkFlowSettings from './tradeinProworkflow';
import EventsProWorkFlowSettings from './eventsProworkflow';
import FleetProWorkFlowSettings from './fleetworkflow';
import BillingSettings from './billing';
import LegalSettings from './legal';

const SettingsLayout = (props) => {
	const { paymentDue } = useContext(MainContext)

	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
		document.getElementsByClassName('container-fluid') && document.getElementsByClassName('container-fluid')[0].classList.add('pl-0', 'pr-0')
		document.getElementById('root') && document.getElementById('root').classList.add('h-100');
		return () => {
			document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
			document.getElementsByClassName('container-fluid') && document.getElementsByClassName('container-fluid')[0].classList.remove('pl-0', 'pr-0')
			document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
		};
	}, []);

	const sales = (_.isEmpty(props.dealersettings.client.moduleSettings)
		|| _.isEmpty(props.dealersettings.client.moduleSettings.sales)
		|| (props.dealersettings.client.moduleSettings.sales.enabled === true && props.dealersettings.client.moduleSettings.sales.active === true)) ? true : false
	const service = (!_.isEmpty(props.dealersettings.client.moduleSettings)
		&& !_.isEmpty(props.dealersettings.client.moduleSettings.service)
		&& props.dealersettings.client.moduleSettings.service.enabled === true
		&& props.dealersettings.client.moduleSettings.service.active === true) ? true : false;
	const tradeinPro = (!_.isEmpty(props.dealersettings.client.moduleSettings)
		&& !_.isEmpty(props.dealersettings.client.moduleSettings.tradeinPro)
		&& props.dealersettings.client.moduleSettings.tradeinPro.enabled === true
		&& props.dealersettings.client.moduleSettings.tradeinPro.active === true) ? true : false;

	const eventsPro = (!_.isEmpty(props.dealersettings.client.moduleSettings)
		&& !_.isEmpty(props.dealersettings.client.moduleSettings.eventsPro)
		&& props.dealersettings.client.moduleSettings.eventsPro.enabled === true
		&& props.dealersettings.client.moduleSettings.eventsPro.active === true) ? true : false;

	const fleetPro = (!_.isEmpty(props.dealersettings.client.moduleSettings)
		&& !_.isEmpty(props.dealersettings.client.moduleSettings.fleetPro)
		&& props.dealersettings.client.moduleSettings.fleetPro.enabled === true
		&& props.dealersettings.client.moduleSettings.fleetPro.active === true) ? true : false;

	const clientSettings = (!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings)) ? props.dealersettings.client.clientSettings : null;

	const liteVersion = (!_.isEmpty(clientSettings) && clientSettings.liteVersion && localStorage.defaultModule !== 'oem') ? true : false;

	return (
		<div className={`settings-wrapper`} style={paymentDue === true ? { paddingTop: '85px' } : {}}>
			<div className='settings-leftmenu'>
				<h3> <Translate text={'settings'} /></h3>

				<ul>
					{SettingsRoutes.filter(a => a.name === 'Profile' || (!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.admin) && props.dealersettings.rolePermissions.admin[a.module])).map((rec, index) => {
						return ((rec.module === 'workflowSetup' && !sales) || (rec.module === 'serviceworkflowSetup' && !service) || (rec.module === 'tradeinProworkflowSetup' && !tradeinPro) || (rec.module === 'eventsProworkflowSetup' && !eventsPro) || (rec.module === 'fleetProworkflowSetup' && !fleetPro)) ? (<Fragment key={index} ></Fragment>) : (
							<li key={index}>
								<NavLink key={index} to={rec.route} activeClassName={'active'}>
									<i className={rec.class}></i>
									{
										rec.module === 'clientSettings' && localStorage.defaultModule?.includes('oem') ? (
											<>{`${props.dealersettings.level === 'oem' ? 'OEM' : props.dealersettings.level === 'region' ? 'Region' : props.dealersettings.level === 'group' ? 'Group' : 'Dealer'} Settings`}</>
										) : (<Translate text={rec.name} />)
									}

									{
										liteVersion && (rec.module === 'workflowSetup' || rec.module === 'serviceworkflowSetup' || rec.module === 'tradeinProworkflowSetup' || rec.module === 'eventsProworkflowSetup' || rec.module === 'fleetProworkflowSetup')
											?
											<><span className="tag-pro-inline ml-2 mt-0"><Translate text={'PRO'} /></span></>
											:
											<></>
									}

								</NavLink>
							</li>
						);
					})}
					<li >
						<NavLink to={'/settings/legal'} activeClassName={'active'}>
							<i className={'ico icon-contract-fill'}></i>
							<Translate text={'Legal'} />
						</NavLink>
					</li>
				</ul>
			</div>
			<div className='settings-middlewraper'>
				<div className='settings-middlewraper-sub'>
					{/* <LayoutConsumer>
						{({ dealersettings, clientUsers, updatePipelineSettings, updateDealerSettings, pipeline }) => dealersettings ? ( */}
					<Suspense fallback={<></>}>
						<Switch>
							<Route
								path='/settings/profile'
								exact
								render={() => <GeneralSettings {...props} />}
							/>
							<Route
								path='/settings/company'
								exact
								render={() => <CompanySettings  {...props} />}
							/>
							<Route
								path='/settings/workflow'
								exact
								render={() => <WorkFlowSettings  {...props} />}
							/>
							<Route
								path='/settings/serviceworkflow'
								exact
								render={() => <ServiceWorkFlowSettings  {...props} />}
							/>
							<Route
								path='/settings/tradeinProworkflow'
								exact
								render={() => <TradeinProWorkFlowSettings  {...props} />}
							/>
							<Route
								path='/settings/eventsProworkflow'
								exact
								render={() => <EventsProWorkFlowSettings  {...props} />}
							/>
							<Route
								path='/settings/product'
								exact
								render={() => <ProductSettings  {...props} />}
							/>
							<Route
								path='/settings/modules'
								exact
								render={() => <ModuleSettings  {...props} />}
							/>
							<Route
								path='/settings/integrations'
								exact
								render={() => <IntegrationSettings  {...props} />}
							/>
							<Route
								path='/settings/securitymanagment'
								exact
								render={() => <SecurityManagementSettings  {...props} />}
							/>
							<Route
								path='/settings/billing'
								exact
								render={() => <BillingSettings  {...props} />}
							/>
							<Route
								path='/settings/fleetworkflow'
								exact
								render={() => <FleetProWorkFlowSettings  {...props} />}
							/>
							<Route
								path='/settings/legal'
								exact
								render={() => <LegalSettings  {...props} />}
							/>
						</Switch>
					</Suspense>
					{/* ) : (<></>)
						}
					</LayoutConsumer> */}
				</div>
			</div>
		</div >
	);
};

export default SettingsLayout;
