/** LIBRARIES */
import React, { PureComponent } from "react";
import NumberFormat from 'react-number-format';
import Translate from '../../../constants/translate';
import {
    InputText, InputTextArea,
    AntMonthPicker,
    ReactSelect,
    AntDatePicker,
    InputCheckBox,
    DynamicInput
} from '../../../components';
import _ from 'lodash'
import moment from 'moment';
import CommonHelper from '../../../services/common';

class TipInfoInputs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {

        const {
            make, model, year, group,
            regNo, vinNo, status,
            dynamicFields
        } = this.props.fields;

        const { fields, errors, currencySymbol, type, isComplete,
            years, fleetOptionsDF, fleetProStatus,

            handleOnChange, handleVehicleScan, handleOnCapChange, handleDateChange, onValueChange,
            handleCustomOption, handleReactSelectChange, onCurrencyChange, handleCheckChange,
            handleOnAreaChange, handleFirebaseTimeChange, handleFirebaseDateChange,

            handleDynamicCheckChange, handleDynamicOnChange, saveDynamicField, cancelDynamicField, onDynamicNumberChange,
            handleDynamicDateChange, handleDynamicSelectSave, handleDynamicMultiSelectChange,

            makes, addMake, handleAddMakeEnable, handleAddMakeDisable, handleMakeChange,
            groups, addGroup, handleAddGroupEnable, handleAddGroupDisable, handleGroupChange,
            types, addType, handleAddTypeEnable, handleAddTypeDisable, handleTypeChange,
            models, addModel, handleAddModelEnable, handleAddModelDisable,
            dealersettings, groupUsers, clientUsers, allFleetUsers,
            formClassName,

            dealers, fleetgroups, subregions, regions,
            loadDealers, loadGroups, loadSubRegions, loadRegions
        } = this.props;
        let allUsers = (!_.isEmpty(groupUsers) ? groupUsers : clientUsers);
        let _formClass = formClassName ? formClassName : 'form-group col-md-6'

        let license_state = [];
        const client = dealersettings.client;
        let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';
        dealersettings.states && dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
            license_state.push({
                value: doc.code,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        return (
            <>

                {(() => {
                    if (type === 'make') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {/* {isComplete
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addMake ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddMakeEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddMakeDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>} */}

                                    <label> <Translate text={'make'} /></label>
                                    {addMake || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'make'}
                                            className={`form-control ${errors["make"]}`}
                                            name="make"
                                            onChange={handleCustomOption}
                                            value={make}
                                            id='make'
                                        /> :
                                        <ReactSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={'select make'}
                                            onChange={handleMakeChange}
                                            value={make}
                                            classNamePrefix={`${errors["make"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'group') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {/* {isComplete
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addGroup ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddGroupEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddGroupDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>} */}

                                    <label><Translate text={'group'} /></label>
                                    {addGroup || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'group'}
                                            className={`form-control ${errors["group"]}`}
                                            name="group"
                                            onChange={handleCustomOption}
                                            value={group}
                                            id="group"
                                        /> :
                                        <ReactSelect
                                            options={groups}
                                            name={"group"}
                                            placeholder={'select group'}
                                            onChange={handleGroupChange}
                                            value={group}
                                            classNamePrefix={`${errors["group"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'type') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {/* {isComplete
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddTypeEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddTypeDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>} */}

                                    <label><Translate text={'type'} /></label>
                                    {addType || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'type'}
                                            className={`form-control $errors["type"]}`}
                                            name="type"
                                            onChange={handleCustomOption}
                                            value={fields.type}
                                            id='type'
                                        /> :
                                        <ReactSelect
                                            options={types}
                                            name={"type"}
                                            placeholder={'select body type'}
                                            onChange={handleTypeChange}
                                            value={fields.type}
                                            classNamePrefix={`${errors["type"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'model') {
                        return (
                            <>
                                <div className={_formClass}>
                                    {/* {isComplete
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                !addModel ? (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddModelEnable();
                                                    }}>
                                                    + <Translate text={'add'} />
                                                </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddModelDisable();
                                                    }}>
                                                    x <Translate text={'cancel'} />
                                                </a>)
                                            }
                                        </>} */}

                                    <label> <Translate text={'model'} /></label>
                                    {addModel || isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'model'}
                                            className={`form-control ${errors["model"]}`}
                                            name="model"
                                            onChange={handleCustomOption}
                                            value={model}
                                            id='model'
                                        /> :
                                        <ReactSelect
                                            options={models}
                                            name={"model"}
                                            placeholder={'select model'}
                                            onChange={handleReactSelectChange}
                                            value={model}
                                            classNamePrefix={`${errors["model"]} basic-select`}
                                            AddUnAvailable={true}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}
                                </div>
                            </>
                        )
                    }
                    else if (type === 'year') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={'year'} /></label>
                                    {isComplete ?
                                        <InputText
                                            readOnly={isComplete}
                                            autoComplete="off"
                                            placeholder={'year'}
                                            className={`form-control ${errors["year"]}`}
                                            name="year"
                                            onChange={() => { }}
                                            value={year}
                                        /> :
                                        <ReactSelect
                                            options={years}
                                            name={"year"}
                                            placeholder={'select year'}
                                            onChange={handleReactSelectChange}
                                            value={year}
                                            classNamePrefix={`${errors["year"]} basic-select`}
                                            removeClearable={isComplete}
                                        >
                                        </ReactSelect>}

                                </div>
                            </>
                        )
                    }
                    else if (type === 'regNo') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={'registrationNumber'} /></label>
                                    <div className="inner-addon right-addon">
                                        <InputText
                                            name="regNo"
                                            value={regNo}
                                            onChange={handleOnCapChange}
                                            autoComplete="off"
                                            placeholder={'registration number'}
                                            className={`form-control ${errors["regNo"]}`}
                                            readOnly={isComplete}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    } else if (type === 'vinNo') {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={'vinNo'} /></label>
                                    <div className="inner-addon right-addon">
                                        <InputText
                                            name="vinNo"
                                            value={vinNo}
                                            onChange={handleOnCapChange}
                                            autoComplete="off"
                                            placeholder={'chassis/vin number'}
                                            className={`form-control ${errors["vinNo"]}`}
                                            readOnly={isComplete}
                                        />
                                    </div>

                                </div>

                            </>
                        )

                    } else if (type === 'status') {
                        return (
                            <>
                                <div className={`${_formClass} ${(isComplete) ? 'form-disable' : ''}`}>
                                    <label><Translate text={'status'} /></label>
                                    <ReactSelect
                                        options={fleetProStatus}
                                        name={"status"}
                                        placeholder={'select status'}
                                        onChange={handleReactSelectChange}
                                        value={status}
                                        classNamePrefix={`${errors["status"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    } else if (['mileage'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <NumberFormat
                                        id={type}
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        className={`form-control ${errors[type]}`}
                                        onValueChange={onValueChange.bind(this, type)}
                                        value={fields[type] ? fields[type] : null}
                                    />
                                </div>
                            </>
                        )

                    } else if (['compliancxxxxeDate', 'builxxxxxdDate'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type} /></label>
                                    <AntMonthPicker
                                        value={fields[type]}
                                        name={type}
                                        onChange={(e) => { handleDateChange(e, type) }}
                                        placeholder='MMM YYYY'
                                        className={`form-control ${errors[type]}`}
                                        format={'MMM YYYY'}
                                        picker={'month'}
                                        disabled={isComplete}
                                    />
                                </div>
                            </>
                        )
                    } else if (['modelDescription', 'extColor', 'intColor'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <InputText
                                        name={type}
                                        value={fields[type]}
                                        onChange={handleOnChange}
                                        autoComplete="off"
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        className={`form-control ${errors[type]}`}
                                        readOnly={isComplete}
                                    />
                                </div>
                            </>
                        )
                    } else if (['fleetOwner', 'country'].includes(type)) {
                        return (
                            <>
                                <div className={`${_formClass} ${(isComplete) ? 'form-disable' : ''}`}>
                                    <label><Translate text={type} /></label>
                                    <ReactSelect
                                        options={
                                            type === 'fleetOwner' ? allFleetUsers :
                                                type === 'country' ? (!_.isEmpty(dealersettings.countries) ? _.map(dealersettings.countries, function (e) { return { label: e.name, value: e.code, active: _.isBoolean(e.active) ? e.active : true, } }) : []) :
                                                    []}
                                        name={type}
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        onChange={handleReactSelectChange}
                                        value={fields[type]}
                                        classNamePrefix={`${errors[type]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    } else if (['regionID', 'subregionID', 'groupID'].includes(type)) {
                        return (
                            <>
                                <div className={`${_formClass} ${(isComplete) ? 'form-disable' : ''}`}>
                                    <label><Translate text={type} /></label>
                                    <ReactSelect
                                        options={
                                            type === 'regionID' ? loadRegions() :
                                                type === 'subregionID' ? loadSubRegions() :
                                                    type === 'groupID' ? loadGroups() :
                                                        []}
                                        name={type}
                                        placeholder={CommonHelper.showLocale(this.props, type).toLowerCase()}
                                        onChange={handleReactSelectChange}
                                        value={fields[type]}
                                        classNamePrefix={`${errors[type]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </>
                        )
                    } else if (['defleetDate', 'rexxxxxgDate'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type === 'regDate' ? 'registrationExpiry' : type} /> </label>
                                    <AntDatePicker
                                        value={fields[type] ? moment.unix(fields[type].seconds).format('YYYY-MM-DD') : null}
                                        name={type}
                                        onChange={(e) => { handleFirebaseDateChange(e, type) }}
                                        format='DD/MM/YYYY'
                                        placeholder='DD/MM/YYYY'
                                        className={`form-control ${errors[type]}`}
                                        allowClear={true}
                                    />
                                    {/* <AntDatePicker
                                        value={fields[type] ? fields[type] : null}
                                        name={type}
                                        onChange={(e) => { handleDateChange(e, type) }}
                                        format='DD/MM/YYYY'
                                        placeholder='DD/MM/YYYY'
                                        className={`form-control ${errors[type]}`}
                                        readOnly={isComplete}
                                    /> */}
                                </div>
                            </>
                        )
                    } else if (['reconditionxxxxxxxxxingCost'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label> <Translate text={type} /></label>
                                    <NumberFormat
                                        id={type}
                                        decimalScale={2}
                                        allowNegative={true}
                                        thousandSeparator={true}
                                        prefix={currencySymbol}
                                        placeholder={currencySymbol}
                                        className={`form-control ${errors[type]}`}
                                        onValueChange={onCurrencyChange.bind(this, type)}
                                        value={fields[type] ? fields[type] : null}
                                    />
                                </div>
                            </>
                        )
                    } else if (['contrastSxxxxxxtiching'].includes(type)) {
                        return (
                            <>
                                <div className={_formClass}>
                                    <label><Translate text={type} /></label>
                                    <div className="form-control">

                                        <span className="switch switch-sm float-right">
                                            <InputCheckBox
                                                className="switch"
                                                id={type}
                                                name={type}
                                                checked={fields[type] ? true : false}
                                                onChange={(e) => { handleCheckChange(e) }} />
                                            <label htmlFor={type}></label>
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    } else if (type === 'xxxxxxxxx') {
                        return (
                            <>
                                {/* <div className="form-group col-md-12">
                                    <label><Translate text={'specifications'} /></label>
                                    {
                                        isComplete
                                            ?
                                            <>
                                                <div className={'notes-item notes-item-scroll text-area-space'}>
                                                    {specifications ? specifications : '--'}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InputTextArea
                                                    className={`form-control notes ${errors["specifications"]}`}
                                                    name="specifications"
                                                    onChange={handleOnAreaChange}
                                                    value={specifications}
                                                    maxLength="5000"
                                                    rows="5">
                                                </InputTextArea>
                                            </>
                                    }

                                </div> */}
                            </>
                        )
                    } else if (type === 'notxxxxxxxxes') {
                        return (
                            <>
                                {/* <div className="form-group col-md-12">
                                    <label><Translate text={'notes'} /></label>
                                    {
                                        isComplete
                                            ?
                                            <>
                                                <div className={'notes-item notes-item-scroll text-area-space'}>
                                                    {notes ? notes : '--'}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <InputTextArea
                                                    className={`form-control notes ${errors["notes"]}`}
                                                    name="notes"
                                                    onChange={handleOnAreaChange}
                                                    value={notes}
                                                    maxLength="280"
                                                    rows="3">
                                                </InputTextArea>
                                            </>
                                    }

                                </div> */}
                            </>
                        )
                    }
                    else {
                        return (
                            <>
                                {!_.isEmpty(fleetOptionsDF) && fleetOptionsDF.filter(m => m.active === true).map((info, index) => {
                                    if (type === info.value) {
                                        return (<div className={_formClass} key={info.value}>
                                            <label>{info.name}</label>

                                            <DynamicInput
                                                index={'0' + index}
                                                data={info}
                                                errors={errors ? errors : {}}
                                                id={info.value}
                                                value={dynamicFields ? dynamicFields[info.value] : null}
                                                handleCheckChange={handleDynamicCheckChange}
                                                handleOnChange={handleDynamicOnChange}
                                                saveEnquiryField={saveDynamicField}
                                                cancelEnquiryField={cancelDynamicField}
                                                onCurrencyChange={onDynamicNumberChange}
                                                handleDateChange={handleDynamicDateChange}
                                                handleSelectSave={handleDynamicSelectSave}
                                                handleMultiSelectChange={handleDynamicMultiSelectChange}
                                                currencySymbol={currencySymbol}
                                                inputAutoFormat={false}
                                            >
                                            </DynamicInput>

                                        </div>)
                                    }

                                })}
                            </>
                        )
                    }

                })()}

            </>

        );
    }
}

export default TipInfoInputs;