/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    InputCheckBox, PopoverListLoader
} from '../../../components';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { objQueue } from '../../frontdesk/viewModel'

export default class ActiveUsers extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeUSRZ = null;
        this.state = {
            activeUsers: [],
            isQueue: false,
            contactLoader: true,
            usersList: []
        };
    }

    componentDidMount() {
        const { dealersettings, queueNum } = this.props;

        let clientID = dealersettings.client.id
        //let userID = dealersettings.id;

        if (queueNum > 0)
            this.setState({ isQueue: true })

        //var user = clientUsers.filter(item => item.id === userID).length > 0 ? clientUsers.filter(item => item.id === userID)[0] : null;
        //if (user) {
        this.unsubscribeUSRZ = window.firebase.firestore()
            .doc(`clients/${clientID}/queues/${dealersettings.department}`)
            .onSnapshot(this.onCollectionUpdate);

        //this.getdealerSettings();
        //}
    }

    componentWillUnmount() {
        this.unsubscribeUSRZ && this.unsubscribeUSRZ();
    }

    onCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            this.setState({ contactLoader: false })
            return;
        }

        const queue = Object.assign({}, objQueue);
        const _obj = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_obj)) {
            queue[key] = value;
        }
        queue.documentID = docSnapshot.id;

        this.setState({ activeUsers: queue.users, contactLoader: false })
    }

    bindActiveUser = (userid, index) => {
        const { clientUsers } = this.props;
        //const { usersList } = this.state;

        var user = clientUsers.filter(item => item.id === userid).length > 0 ? clientUsers.filter(item => item.id === userid)[0] : null;
        //console.log('bindActiveUser', Object.assign({}, user))
        if (user) {
            return (
                <>
                    <div className="queue-drag">
                        <div className="list-grid-count float-right"> <span className="badge badge-pill badge-grid-queue ml-1">{index + 1}</span> </div>
                    </div>
                    <div className={`frontdesk-rearrange-avatar ${index === 0 ? 'active' : ''}`}>
                        {/* <div className="frontdesk-rearrange-count"> <span className="badge badge-pill badge-frontdesk-queue ml-1">{index + 1}</span> </div> */}
                        <img src={CommonHelper.showUserAvatar(user.profileImage, user.name)} alt="" className="img-object-fit" /> </div>
                    <div className="frontdesk-rearrange-detail">
                        <h3>{user.name}</h3>
                        <h5>
                            {user.role ? user.role : '     '}
                            {/* {'  |  '}
                            {user.departmentName ? user.departmentName : user.department} */}
                        </h5>
                    </div>
                </>
            )
        }
    }

    getdealerSettings = () => {

        this.setState({ loadingText: 'Fetching users settings...' });
        let clientid = this.props.dealersettings.client.id
        let settingsid = this.props.dealersettings.client.settings.id
        let users = this.props.clientUsers
        //await asyncForEach(users, async (_user) => {
        users.forEach(async (_user) => {
            const userClientSnapshot = await window.firebase.firestore().doc(`users/${_user.id}/userClients/${clientid}`).get()
            const settingSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}`).get()
            let department = userClientSnapshot.exists ? userClientSnapshot.data().department : '';
            let departments = settingSnapshot.exists ? settingSnapshot.data().departments : null;
            let departmentName = department;
            if (department && !_.isEmpty(departments)) {
                var userDepartment = departments.filter(item => item.value === department).length > 0 ? departments.filter(item => item.value === department)[0] : null;
                if (userDepartment)
                    departmentName = userDepartment.name
            }
            if (!_.isEmpty(userClientSnapshot.data().roleID)) {
                const roleSnapshot = await window.firebase.firestore().doc(`clientSettings/${settingsid}/roles/${userClientSnapshot.data().roleID}`).get()
                users.map((item) => {
                    if (item.id === _user.id) {
                        item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
                        return item;
                    }
                    return item;
                });
            }
            users.map((item) => {
                if (item.id === _user.id) {
                    item.roleID = userClientSnapshot.exists ? userClientSnapshot.data().roleID : '';
                    //item.role = roleSnapshot.exists ? roleSnapshot.data().name : '';
                    item.queueNum = userClientSnapshot.exists ? userClientSnapshot.data().queueNum : '';
                    item.department = department;
                    item.departmentName = departmentName;
                    return item;
                }
                return item;
            });

            if (users[users.length - 1].id === _user.id)
                this.setState({ contactLoader: false });

        });
        this.setState({ usersList: users });

    };

    handleCheckChange = (e) => {
        const { checked } = e.target;
        const { dealersettings } = this.props;

        let clientID = dealersettings.client.id
        let userID = dealersettings.id;
        //console.log('handleCheckChange', checked, userID, clientID)
        this.setState({ isQueue: checked })
        window.firebase.firestore()
            .doc(`users/${userID}/userClients/${clientID}`)
            .update({
                isQueue: checked
            }).catch((error) => {
                console.error("Error updating contacts: ", error);
            });


    }

    render() {

        const { show } = this.props;
        const { contactLoader, activeUsers } = this.state;
        const filteredList = activeUsers;

        return (
            <>
                <Modal
                    id="user-Active-List"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rearrange-queue">

                    <Modal.Header >
                        <Modal.Title className="w-100">
                            <h5 className="modal-title float-left" ><Translate text={'queueList'} /> </h5>
                            <div className="queue-switch">
                                <label ><Translate text={'queue'} /></label>
                                <span className="switch switch-sm ml-2">
                                    <InputCheckBox
                                        className={`switch`}
                                        id="isQueue"
                                        name="isQueue"
                                        checked={this.state.isQueue}
                                        onChange={(e) => { this.handleCheckChange(e) }} />
                                    <label htmlFor="isQueue"></label>
                                </span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="rearrange-queue-wrap" >
                                        {
                                            contactLoader ? (<PopoverListLoader />) :
                                                (!_.isEmpty(filteredList) && filteredList.length > 0
                                                    ?
                                                    <>
                                                        {filteredList.map((userid, index) =>
                                                            <div key={index} className={`frontdesk-rearrange-box frontdesk-flex-queue`}>
                                                                {this.bindActiveUser(userid, index)}
                                                            </div>

                                                        )}
                                                    </>
                                                    :
                                                    <>
                                                        <div className="spinner-loader h-100 p-5">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-Contacts"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'noUserFound'} /></div>
                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>

                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <button
                            type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}
                        ><Translate text={'cancel'} />
                        </button> */}

                        <button
                            type="button"
                            className={`btn btn-primary float-right ${this.isLoading ? 'form-disable' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.handleClose();
                            }}
                        >
                            <Translate text={'done'} />
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}

