/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import _ from 'lodash'
/** COMPONENTS */
import {
    PopUpModal
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';
import AttendeeInfo from './details'
/** VIEW-MODELS */

const EventCardInfo = (props) => {
    const [attendees, setAttendees] = useState([])
    const [eventsModal, setEventsModal] = useState({ show: false, docID: null })

    useEffect(() => {
        if (!props.enquiryID && !props.contactID) {
            return;
        }
        let refCollectionData;

        if (props.enquiryID || props.contactID) {

            if (props.enquiryID) {
                refCollectionData = firestoreDB(props.dealersettings, true).firestore().collection(`eventsProAttendees`)
                    .where('enquiryID', '==', props.enquiryID)
                    .where('isDeleted', '==', false)
            }
            else if (props.contactID) {
                refCollectionData = firestoreDB(props.dealersettings, true).firestore().collection(`eventsProAttendees`)
                    .where('contactID', '==', props.contactID)
                    .where('isDeleted', '==', false)
            }

            refCollectionData
                .get()
                .then((querySnapshot) => {
                    let _list = [];
                    querySnapshot.forEach(function (doc) {
                        _list.push({
                            ...doc.data(),
                            documentID: doc.id,
                            startDate: doc.data()?.eventsPro?.startDate ? doc.data().eventsPro.startDate : doc.data().addedDate
                        })
                    });
                    setAttendees(_.uniqBy([
                        ...attendees,
                        ..._list
                    ], 'documentID'));
                });
        }

    }, [props.enquiryID, props.contactID])

    useEffect(() => {
        if (_.isEmpty(props.enquiries))
            return

        let triggerPromise = [];
        props.enquiries.filter(a => a.eventsProAttendeeID).forEach(rec => {
            triggerPromise.push(firestoreDB(props.dealersettings, true).firestore().doc(`eventsProAttendees/${rec.eventsProAttendeeID}`).get())
        })

        if (triggerPromise.length > 0) {

            Promise.all(triggerPromise)
                .then(snapshots => {
                    let _list = [];
                    snapshots.forEach(obj => {
                        if (obj.exists) {
                            _list.push({
                                ...obj.data(),
                                documentID: obj.id,
                                startDate: obj.data()?.eventsPro?.startDate ? obj.data().eventsPro.startDate : obj.data().addedDate
                            })
                        }
                    })
                    setAttendees(_.uniqBy([
                        ...attendees,
                        ..._list.filter(a => a.isDeleted === false)
                    ], 'documentID'));
                })
        }
    }, [props.enquiries ? props.enquiries.length : false])

    const handleEventOpen = (docID) => {
        setEventsModal({ show: true, docID: docID })
    }
    const handleEventClose = () => {
        setEventsModal({ show: false, docID: null })
    }
    const eventsProStatus = props.dealersettings?.client?.eventsPro?.eventsProStatus;
    return (

        <>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={props.cardKey}
                    onClick={e => {
                        e.preventDefault();

                        if (props.activeKey === props.cardKey)
                            props.handleActiveKey(null);
                        else props.handleActiveKey(props.cardKey);
                    }}
                >
                    <div className='mb-0'>
                        <div className="cardlink">
                            <a onClick={(e) => { e.preventDefault(); }} className={`btn btn-link ${props.activeKey === props.cardKey ? '' : 'collapsed'}`}>
                                <i className='ico icon-events-event'></i>{' '}
                                <Translate text={'eventsPro'} />
                                {!_.isEmpty(attendees) ? <span className="badge badge-pill badge-cafe ml-1">{attendees.length}</span> : <></>}
                                <i className="arrow-collapse"></i>
                            </a></div>
                    </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={props.cardKey}>
                    <Card.Body>
                        {
                            !_.isEmpty(attendees)
                                ?
                                <>
                                    {
                                        _.orderBy(attendees, 'startDate.seconds', 'desc').map((attendee, index) => {

                                            return <div key={index} className='events-dashboard-livewrap mb-2' id={`attID-${attendee.documentID}`} onClick={(e) => {
                                                e.preventDefault();
                                                handleEventOpen(attendee.documentID);
                                            }}>
                                                {(() => {
                                                    let currentEvent = Object.assign({}, attendee?.eventsPro);
                                                    let _objCurrentStatus = eventsProStatus.filter(e => e.value === currentEvent?.status)[0];
                                                    return <div className="events-dashboard-live-flex" id={`evtID-${currentEvent.documentID}`}>
                                                        <div className="events-dashboard-live-image">
                                                            {
                                                                currentEvent.imageURL ? (
                                                                    <img src={currentEvent.imageURL} alt="" width="70" height="70" />
                                                                ) : (
                                                                    <img src={_images.noEvent} alt="" width="70" height="70" />
                                                                )
                                                            }
                                                        </div>
                                                        <div className="events-dashboard-live-data">
                                                            <div className="events-dashboard-card-title">{currentEvent.title}</div>
                                                            <div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {currentEvent.location} </div>
                                                            <div className="events-dashboard-card-sub">
                                                                <i className="ico icon-date"></i> {CommonHelper.showCombineDate(currentEvent.startDate, currentEvent.endDate)}
                                                            </div>
                                                            <div className="events-dashboard-card-sub">
                                                                <i className="ico icon-time"></i> {CommonHelper.showCombineTime(currentEvent.startDate, currentEvent.endDate)}
                                                            </div>
                                                            <div className="events-dashboard-card-sub justify-content-between">
                                                                {
                                                                    attendee.checkinDate ?
                                                                        <div className="badge badge-pill badge-available-fill">CHECKED-IN</div>
                                                                        :
                                                                        <></>
                                                                }

                                                                {
                                                                    attendee.isNew ?
                                                                        <div className="badge badge-pill badge-white">NEW</div>
                                                                        :
                                                                        <></>
                                                                }
                                                                {/* {
                                                                    currentEvent.status ? (
                                                                        <>
                                                                            {
                                                                                _objCurrentStatus
                                                                                    ?
                                                                                    <>
                                                                                        <div
                                                                                            className={`badge badge-pill badge-empty`}
                                                                                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                                        >
                                                                                            {_objCurrentStatus.name}
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <><div className={`badge badge-pill badge-status-${currentEvent.status.toLowerCase()}`} >{currentEvent.status} </div></>
                                                                            }
                                                                        </>
                                                                    ) : (<></>)
                                                                } */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                })()}
                                            </div>

                                        })
                                    }
                                </>
                                :
                                <>
                                    <div className='text-center p-4'>
                                        <p><Translate text={'No Events Found'} /></p>
                                    </div>
                                </>
                        }

                    </Card.Body>
                </Accordion.Collapse>
            </Card>

            <PopUpModal show={eventsModal.show}>
                <AttendeeInfo
                    {...props}
                    attendeeid={eventsModal.docID}
                    show={eventsModal.show}
                    clsActive='overlay-modal active'
                    handleClose={handleEventClose}
                    title={'attendee'}
                >
                </AttendeeInfo>
            </PopUpModal>
        </>



    )
};

export default EventCardInfo;
