/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
import NumberFormat from 'react-number-format';
/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import { firestoreDB } from '../../../services/helper';
import { DropDownMenu } from "../../../components";
/** VIEW-MODELS */


const ProductInfo = ({ product, enquiryID, handleAddOnsOpen, dealersettings }) => {
    const [editshow, setEditshow] = useState(false)
    const node = useRef();
    const {
        documentID, startDate, endDate, price, title, id,
        subList, terms, category, provider, status, module,
        costPrice, rrp, grossProfit,
    } = product;


    const productAllStatus = Object.assign([], dealersettings?.client?.settings?.productStatus)
    let _objCurrentStatus = status && productAllStatus.filter(e => e.value === status)[0]

    let _productAllStatus = [];
    productAllStatus && productAllStatus.forEach((doc) => {
        _productAllStatus.push({
            ...doc,
            label: <div className={`status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
            //label: <div className={`status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
        });
    });
    const currencySymbol = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const accessToAddProduct = (dealersettings?.rolePermissions?.permissions?.accessToAddProduct) ? true : false;
    const accessToDeleteProduct = (dealersettings?.rolePermissions?.permissions?.accessToDeleteProduct) ? true : false;

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        }
        setEditshow(false);
    };

    const handleRemove = (objData) => {
        const { enquiryID, documentID } = objData;
        if (enquiryID && documentID) {
            Swal.fire({
                title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
                text: CommonHelper.showLocale({ dealersettings }, 'Do you want to delete.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
                cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
            }).then((result) => {
                if (result.value) {
                    firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/productAddons/${documentID}`).delete();
                    CommonHelper.saveChangeLog(objData.clientID, objData.groupID, objData.enquiryID, `Product: ${objData.title || ''} deleted.`, 'product', 'enquiry');
                }
            })
        }
    }

    const handleStatusChange = (status) => {

        if (enquiryID && documentID) {
            //   console.log('handleStatusChange', status)
            let modifiedBy = dealersettings ? dealersettings.id : "";
            let modifiedDate = window.firebase.firestore.Timestamp.now();

            const updateRef = firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}/productAddons/${documentID}`)
            updateRef.update({
                status: status,
                modifiedBy: modifiedBy,
                modifiedFrom: "web",
                modifiedDate: modifiedDate,
            });
        }
    }



    return (


        <div className="product-addon-item" id={'addons_' + documentID}>


            <div className="flex-product-addon" onClick={(e) => {
                e.preventDefault();
                //handleAddOnsOpen(value);
            }}>

                {
                    (accessToAddProduct || accessToDeleteProduct) && (<div className="product-addon-item-more">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setEditshow(!editshow)
                            if (editshow)
                                document.addEventListener('click', setEditshow(false));
                        }}><i className="ico icon-more"></i></a>
                        <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
                            <ul className="more-dropdown">

                                {accessToAddProduct && (<li> <a href="#" onClick={(e) => { e.preventDefault(); handleAddOnsOpen(id); }}>
                                    <i className="ico icon-edit"></i>
                                    <Translate text={'edit'} />
                                </a>
                                </li>)}

                                {accessToDeleteProduct && (<li> <a href="#" onClick={(e) => { e.preventDefault(); handleRemove(product); }}>
                                    <i className="ico icon-delete"></i>
                                    <Translate text={'delete'} />
                                </a>
                                </li>)}

                            </ul>
                        </div>

                    </div>)
                }


                <div className="product-addon-item-icon">
                    <i className="ico icon-service-package"> </i>
                </div>

                <div className="product-addon-item-data" onClick={(e) => { e.preventDefault(); if (accessToAddProduct) handleAddOnsOpen(id); }}>
                    <div className="product-addon-item-title">{title}
                        {module && <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title='Module'>{module}</div>}
                        {category && <div className="badge badge-pill badge-white ml-1 badge-mini text-capitalize" title='Category'>{CommonHelper.getNameByValue(dealersettings?.client?.settings?.productCategory, category, '')}</div>}
                    </div>
                    {provider && (<div className="product-addon-item-info product-addon-blue" title='Provider'>{CommonHelper.getNameByValue(dealersettings?.client?.settings?.productProviders, provider, '')}   </div>)}

                    {startDate && (<div className="product-addon-item-info">Period: {CommonHelper.showCombineDate(startDate, endDate)} &nbsp;
                        {terms && (`(${CommonHelper.formatYear(terms)})`)}
                    </div>)}

                    {costPrice && (<div className="product-addon-item-info">Cost Price: <NumberFormat
                        value={costPrice}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                    />
                    </div>)}
                    {grossProfit && (<div className="product-addon-item-info">Gross Profit: <NumberFormat
                        value={grossProfit}
                        displayType={"text"}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={currencySymbol}
                    />
                    </div>)}
                    <div className="product-addon-item-status">

                        {
                            !_.isEmpty(_productAllStatus) && (<div className={`status-empty float-left`}
                                style={{ background: CommonHelper.getFieldByValue(productAllStatus, status, 'color', '#b0babe') }}>
                                <DropDownMenu
                                    text={status ? CommonHelper.getNameByValue(productAllStatus, status) : 'Select status'}
                                    keyVal="178768341"
                                    tIndex="-15"
                                    menu={_productAllStatus.filter(e => e.value !== status)}
                                    handleChange={handleStatusChange}
                                >
                                </DropDownMenu>
                            </div>)

                        }
                        {/* {
                            _objCurrentStatus && (<div
                                className={`status-empty`}
                                style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                            >
                                {_objCurrentStatus.name}
                            </div>)
                        } */}

                        {
                            rrp && (<div className="product-addon-item-price product-addon-blue" title="RRP">RRP:&nbsp;
                                <NumberFormat
                                    value={rrp}
                                    displayType={"text"}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    prefix={currencySymbol}
                                />
                            </div>)
                        }

                    </div>
                </div>
            </div>

        </div>


    )
};

export default ProductInfo;
