export const allactivefieldsVM = [
  {
    name: 'User',
    value: '_user',
    sortValue: 'struser',
    flex: 2,
    default: true,
  },
  {
    name: 'Device',
    value: '_device',
    sortValue: 'strdevice',
    flex: 2,
    default: true
  },
  {
    name: 'Location',
    value: '_location',
    sortValue: 'strlocation',
    flex: 2,
    default: true,
  },
  {
    name: 'Login',
    value: '_loginDate',
    sortValue: 'strloginDate',
    flex: 2,
    default: true
  },
  // {
  //   name: 'Action',
  //   value: '_action',
  //   flex: 1,
  //   default: true
  // },
  {
    name: 'Settings',
    value: 'settings',
    subText: 'documentID',
    width: 90,
    default: true,
    type: 'settings',
    flex: 0,
    fixedRight: true
  }
]

export const deviceshistoryfieldsVM = [
  {
      name: 'User',
      value: '_user',
      sortValue: 'struser',
      flex: 1,
      default: true,
  },
  {
      name: 'Device',
      value: '_device',
      sortValue: 'strdevice',
      flex: 1,
      default: true
  },
  {
      name: 'Location',
      value: '_location',
      sortValue: 'strlocation',
      flex: 1,
      default: true,
  },
  {
    name: 'Login',
    value: '_loginDate',
    sortValue: 'strloginDate',
    flex: 1,
    default: true
  },
  {
    name: 'Logout',
    value: '_logoutDate',
    sortValue: 'strlogoutDate',
    flex: 1,
    default: true
  },
  
]

export const auditlogsfieldsVM = [
  {
      name: 'User',
      value: '_user',
      sortValue: 'userName',
      flex: 1,
      default: true,
  },
  {
      name: 'Event',
      value: '_event',
      sortValue: 'strevent',
      flex: 2,
      default: true
  },
  {
      name: 'Type',
      value: '_type',
      sortValue: '_type',
      flex: 1,
      default: true,
  },
  {
    name: 'Logged on',
    value: '_logDate',
    sortValue: 'strlogDate',
    flex: 1,
    default: true
  },
  {
    name: 'Action',
    value: '_action',
    // flex: 1,
    width: 90,
    default: true,
    flex: 0,
    fixedRight: true
  },
]

export const typeOptions = [
	{ active: true, value: 'authentication', label: 'Authentication', index: 1 },
	{ active: true, value: 'excelExport', label: 'Excel Export', index: 2 },
	{ active: true, value: 'files', label: 'Files', index: 3 },
	{ active: true, value: 'licenseImage', label: 'License Image', index: 5 },
	{ active: true, value: 'screenshot', label: 'Screenshot', index: 6 },
	{ active: true, value: 'activities', label: 'Activities', index: 7 },
	{ active: true, value: 'companies', label: 'Companies', index: 8 },
	{ active: true, value: 'enquiries', label: 'Enquiries', index: 9 },
	{ active: true, value: 'stock', label: 'Stock', index: 10 },
	{ active: true, value: 'videos', label: 'Videos', index: 11 },
	{ active: true, value: 'triggers', label: 'Triggers', index: 11 },
	{ active: true, value: 'dynamicFields', label: 'Dynamic Fields', index: 11 },
  { active: true, value: 'inboundLeads', label: 'InboundLeads', index: 12 },
]

