/** LIBRARIES */
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import {Modal} from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import Translate from '../constants/translate';

class ImageCrop extends PureComponent {
    constructor(props) {
        super(props);
        this.refCrop = {
            unit: "%",
            width: 80,
            height: 80
        }
        this.state = {
            croppedImageUrl: null,
            crop: {
                unit: "%"
                //aspect: 16 / 9
            }
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        //this.setState({ crop });
        this.refCrop = Object.assign({}, crop);
        //console.log('onCropChange', this.refCrop)
        this.forceUpdate()

    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop
            );

            //console.log('makeClientCrop', croppedImageUrl)
            this.setState({ croppedImageUrl });
        }
        else {
            //console.log('makeClientCrop2')
            this.setState({ croppedImageUrl: null });
        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image;

    }

    handleCancel(e) {
        e.preventDefault();
        this.refCrop = Object.assign({}, {
            unit: "%",
            width: 80,
            height: 80
        });
        this.props.handleClose(this.props.cropShow.id);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.refCrop = Object.assign({}, {
            unit: "%",
            width: 80,
            height: 80
        });
        if (this.state.croppedImageUrl)
            this.props.cropShow.src = this.state.croppedImageUrl;

        this.props.handleSelect(this.props.cropShow)
    }
    render() {
        const { show, src, title } = this.props.cropShow;

        return (
            <>

                <Modal
                    show={show}
                    onHide={this.handleCancel}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog modal-crop modal-dialog-centered">

                    <Modal.Header /*closeButton*/>
                        <Modal.Title> <h4 className="modal-title"><Translate text={'Crop'}/> - {title}</h4>	</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className="text-center p-3">
                            <ReactCrop
                                src={src}
                                crop={this.refCrop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => this.handleCancel(e)}
                        >  <Translate text={'cancel'}/></button>
                        <button type="button" className="btn btn-primary ml-2" onClick={(e) => this.handleSubmit(e)} ><Translate text={'confirm'}/></button>

                    </Modal.Footer>
                </Modal>
            </>


        );
    }
}

export default ImageCrop;