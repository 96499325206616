import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import Translate from '../../../constants/translate';
import { ReactSelect } from '../../../components';
import { MainContext } from '../../layout/provider';

const OEMFilter = ({
	show,
	handleClose,
	title,
	clientLst,
	regionParent,
	subregionParent,
	countryParent,
	stateParent,
	dealerParent,
	clearFilter,
	dealersettings,
	handleApply
}) => {
	const remoteConfig = window.firebase.remoteConfig();
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [loader, setLoader] = useState(true);
	const [region, setRegion] = useState(regionParent ? regionParent : null);
	const [subregion, setSubregion] = useState(subregionParent ? subregionParent : null);
	const [country, setCountry] = useState(countryParent ? countryParent : null);
	const [state, setState] = useState(stateParent ? stateParent : null);
	const [dealer, setDealer] = useState(dealerParent ? dealerParent : null);
	const { regions, subregions } = useContext(MainContext);

	useEffect(() => {
		const getLookups = async () => {
			try {
				await remoteConfig
					.fetchAndActivate()
					.then(async () => {
						let baseURL = window.location.origin; //remoteConfig.getString('baseURL');
						const countries = remoteConfig.getString('countries');
						const states = remoteConfig.getString('states');
						if (process.env.NODE_ENV === 'development')
							baseURL = window.location.origin;

						const responseCountries = await fetch(baseURL + countries);
						const countriesjson = await responseCountries.json();
						setCountries(countriesjson);
						const responseState = await fetch(baseURL + states);
						const statejson = await responseState.json();
						setStates(statejson);
					})
					.catch(err => {
						console.error(err);
					});
				setLoader(false);
			} catch (error) {
				// console.log('oem filter', error);
				setLoader(false);
			}
		};

		getLookups();
	}, [remoteConfig, regions]);


	const getRegions = () => {
		let regionsToReturn;
		let filteredRegions;

		filteredRegions = dealersettings.regions.filter(region => {
			if (clientLst.some(client => client.region === region.code)) {
				return true
			}
			return false;
		})

		regionsToReturn = filteredRegions.map(e => {
			return { label: e.name, value: e.code };
		})

		return regionsToReturn
	}

	const getSubregions = () => {
		let regionsToReturn;
		let filteredSubregions;

		filteredSubregions = dealersettings.subregions.filter(subregion => {
			if (clientLst.some(client => (client.subregion === subregion.code) &&
				(region ? client.region === region.value : true))) {
				return true
			}
			return false;
		})

		regionsToReturn = filteredSubregions.map(e => {
			return { label: e.name, value: e.code };
		})

		return regionsToReturn
	}

	const getCountries = () => {
		let countriesToReturn;
		let filteredCountries;
		let checkByRegionCountries;

		let convertedCountries = countries.map(country => {
			// find a client where this country exists 
			let client = clientLst.filter(cl => cl.countryName === country.name)
			client = client.length > 0 ? client[0] ? client[0] : '' : ''

			let regionLabel = client.regionName ? client.regionName : ''
			let subregionLabel = client.subregionName ? client.subregionName : ''
			return {
				...country,
				regionLabel,
				subregionLabel
			}
		})

		checkByRegionCountries = convertedCountries.filter(item => {
			let returnVal = false
			if (!_.isEmpty(region) && !_.isEmpty(subregion)) {
				if ((item.subregionLabel && item.subregionLabel.toLowerCase() === subregion.label.toLowerCase()) &&
					(item.regionLabel && item.regionLabel.toLowerCase() === region.label.toLowerCase())) {
					returnVal = true
				}
			}
			else if (!_.isEmpty(region)) {
				if (item.region.toLowerCase() === region.code.toLowerCase()) {
					returnVal = true
				} else if (item.regionLabel && item.regionLabel.toLowerCase() === region.label.toLowerCase()) {
					returnVal = true
				}
			}
			else if (!_.isEmpty(subregion)) {
				if (item.subregionLabel && item.subregionLabel.toLowerCase() === subregion.label.toLowerCase()) {
					returnVal = true
				}
			} else {
				returnVal = true
			}
			return returnVal
		});

		filteredCountries = checkByRegionCountries.filter(country => {
			if (clientLst.some(client => client.country === country.code)) {
				return true
			}
			return false;
		})

		countriesToReturn = filteredCountries.map(e => {
			return { label: e.name, value: e.code };
		})

		return countriesToReturn
	}

	const getStates = () => {
		let statesToReturn = states;

		if (country) {
			statesToReturn = statesToReturn.filter(
				item => item.country_code === country.value,
			)

			statesToReturn = statesToReturn.filter(state => {
				if (clientLst.some(client => client.state === state.code && client.country === country.value)) {
					return true
				}
				if (clientLst.some(client => client.state === state.name && client.country === country.value)) {
					return true
				}
				return false;
			})

			statesToReturn = statesToReturn.map(state => {
				return { label: state.name, value: state.code }
			})

			return statesToReturn
		} else {
			return false
		}
	}

	const getDealers = () => {
		let clientsToReturn = clientLst;

		if (region) {
			clientsToReturn = clientsToReturn.filter(client =>
				client.region === region.value
			);
		}

		if (subregion) {
			clientsToReturn = clientsToReturn.filter(client =>
				client.subregion === subregion.value
			);
		}

		if (country) {
			clientsToReturn = clientsToReturn.filter(client =>
				client.country === country.value
			);

			if (state) {
				clientsToReturn = clientsToReturn.filter(client =>
					client.state === state.value
				);
			}
		}

		clientsToReturn = clientsToReturn.map(client => {
			return {
				value: client.documentID,
				doc: client,
				label: client.name,
				active: _.isBoolean(client.active) ? client.active : true,
			};
		})

		return clientsToReturn
	}


	const handleRegion = (e, regions) => {
		// console.log(e, regions)
		if (!e) {
			setRegion(null);
			setCountry(null);
			setState(null);
			if (!_.isEmpty(dealer)) {
				setDealer(null);
			}
			return;
		}
		let code = regions.filter(region => region.name === e.label)[0].code
		setRegion({ value: e.value, label: e.label, code });
		setCountry(null);
		setSubregion(null);
		setState(null);
		if (!_.isEmpty(dealer)) {
			setDealer(null);
		}
	};

	const handleSubregion = (e, subregions) => {
		// console.log(e, regions)
		if (!e) {
			setSubregion(null);
			setCountry(null);
			setState(null);
			if (!_.isEmpty(dealer)) {
				setDealer(null);
			}
			return;
		}
		let code = subregions.filter(subregion => subregion.name === e.label)[0].code
		setSubregion({ value: e.value, label: e.label, code });
		setCountry(null);
		setState(null);
		if (!_.isEmpty(dealer)) {
			setDealer(null);
		}
	};


	const handleCountry = e => {
		if (!e) {
			setCountry(null);
			setState(null);
			if (!_.isEmpty(dealer)) {
				setDealer(null);
			}
			return;
		}
		// console.log(e);
		setCountry({ value: e.value, label: e.label });
		setState(null);
		if (!_.isEmpty(dealer)) {
			setDealer(null);
		}
	};

	const handleState = e => {
		if (!e) {
			setState(null);
			if (!_.isEmpty(dealer)) {
				setDealer(null);
			}
			return;
		}
		// console.log(e);
		setState({ value: e.value, label: e.label });
		if (!_.isEmpty(dealer)) {
			setDealer(null);
		}
	};

	const handleDealer = e => {
		if (!e) {
			setDealer(null);
			return;
		}
		setDealer({ value: e.value, label: e.label });
		// if (!state && e.value && e.label && e.doc) {
		// 	console.log('state is not set')
		// 	console.log('state is not set e.value, e.label e.doc',e.value, e.label, e.doc)
		// 	console.log(states)
		// 	let state = states.filter(state => {
		// 		if (e.doc.state === state.code) {
		// 			return true
		// 		}
		// 		if (e.doc.state === state.name) {
		// 			return true
		// 		}
		// 		return false;
		// 	})
		// 	console.log('state', state)
		// 	console.log('state.length > 0', state.length > 0)
		// 	if (state.length > 0) {
		// 		state = state[0]
		// 		if (state.code && state.name) {
		// 			setState({ value: state.code, label: state.name });
		// 		}
		// 	}
		// }
	};

	return loader ? (
		<>
		</>
	) : (
		<Modal
			id='add-finance'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-add-finance modal-filter-oem'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className='modal-title'>
						<Translate text={title ? title : 'Filter Dealers'} />
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className=' pop-layout form-style'>
							<div className='requirement-pop-middle'>
								<div className='form-row'>
									<div className='form-group '>
										<label>
											{' '}
											<Translate text={'region'} />
										</label>
										<ReactSelect
											options={getRegions()}
											name={'make'}
											placeholder={'Select region'}
											onChange={e => handleRegion(e, regions)}
											value={region ? region.value : ''}
											classNamePrefix={`cursor-pointer basic-select oem-filter-select`}
											AddUnAvailable={true}
										></ReactSelect>
									</div>
									<div className='form-group '>
										<label>
											{' '}
											<Translate text={'subregion'} />
										</label>
										<ReactSelect
											options={getSubregions()}
											name={'subregion'}
											placeholder={'Select subregion'}
											onChange={e => handleSubregion(e, subregions)}
											value={subregion ? subregion.value : ''}
											classNamePrefix={`cursor-pointer basic-select oem-filter-select`}
											AddUnAvailable={true}
										></ReactSelect>
									</div>
									<div className='form-group '>
										<label>
											{' '}
											<Translate text={'country'} />
										</label>
										<ReactSelect
											options={getCountries()}
											name={'make'}
											placeholder={'Select country'}
											onChange={handleCountry}
											// onChange={this.handleMakeChange}
											value={country ? country.value : ''}

											// add class ${errorFields["country"]}
											classNamePrefix={` cursor-pointer basic-select oem-filter-select`}
											AddUnAvailable={true}
										></ReactSelect>
									</div>
									<div className='form-group '>
										<label>
											{' '}
											<Translate text={'state'} />
										</label>
										<ReactSelect
											options={getStates()}
											name={'make'}
											placeholder={'Select state'}
											onChange={handleState}
											// onChange={this.handleMakeChange}
											value={state ? state.value : ''}

											// add class ${errorFields["country"]}
											classNamePrefix={` cursor-pointer basic-select oem-filter-select`}
											AddUnAvailable={true}
											menuPlacement='top'
										></ReactSelect>
									</div>
									<div className='form-group '>
										<label>
											{' '}
											<Translate text={'dealerships'} />
										</label>
										<ReactSelect
											options={getDealers()}
											name={'make'}
											placeholder={'Select dealership'}
											onChange={handleDealer}
											// onChange={this.handleMakeChange}
											value={dealer ? dealer.value : ''}
											// add class ${errorFields["country"]}
											classNamePrefix={` cursor-pointer basic-select oem-filter-select`}
											AddUnAvailable={true}
											menuPlacement='top'
										></ReactSelect>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-default float-left'
					onClick={e => {
						e.preventDefault();
						clearFilter(clientLst)
						handleClose()
					}}
				>
					<Translate text={'Clear'} />
				</button>
				<button
					type='button'
					className='btn btn-primary float-right'
					onClick={e => {
						e.preventDefault();
						handleApply(region, subregion, country, state, dealer)
						handleClose()
					}}
				>
					<Translate text={'Apply'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default OEMFilter;
