import React from 'react'
//if any lookup value add or update.please do update in oemsetup/viewmodel
export const clientLookups = [{
    name: 'Campaigns',
    value: 'campaigns'
},
{
    name: 'Call Status',
    value: 'callStatus'
},
{
    name: 'Enquiry Types',
    value: 'enquiryTypes'
},
{
    name: 'Enquiry Options',
    value: 'enquiryOptionsDF'
},
{
    name: 'Finance Status',
    value: 'financeStatus'
},
{
    name: 'Lead Origins',
    value: 'origins'
},
{
    name: 'Lost Reasons',
    value: 'lostReasons'
},
{
    name: 'Requirement Other Info',
    value: 'reqOtherInfo'
},
{
    name: 'Vehicle Sales Type',
    value: 'salesType'
},
{
    name: 'Test Drive Feedback',
    value: 'testdriveFeedback'
},
{
    name: 'Title',
    value: 'titles'
},
{
    name: 'Genders',
    value: 'genders'
},
{
    name: 'Marital Status',
    value: 'maritalStatus'
},
{
    name: 'Contact Method',
    value: 'contactMethod'
},
{
    name: 'Contact Interests',
    value: 'interests'
},
{
    name: 'License Type',
    value: 'licenseType'
},
{
    name: 'Company Type',
    value: 'companyType'
},
{
    name: 'Stock Location',
    value: 'stockLocation'
},
{
    name: 'Activity Outcome',
    value: 'activityOutcome'
},
{
    name: 'Lead Source',
    value: 'leadSource'
}
]

export const lookupVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    color: '',
    dmsKeys: []
}

export const lookupSublistVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    dmsKeys: []
}
export const lookupDFVM = {
    name: '',
    value: '',
    level: '',
    active: true,
    subList: [],
    type: '',
    dmsKeys: [],
    key: null
}


export const tblFieldsLookup = [
    {
        name: 'Name',
        value: 'strName',
        flex: 2,
        default: true
    }, {
        name: 'OEM/Group/Client',
        value: 'levelName',
        subText: 'levelValue',
        flex: 2,
        default: true
    },
    {
        name: 'Active',
        value: 'active',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch',
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }]

export const levelOptions = [
    { active: true, value: 'oem', label: 'OEM', index: 1 },
    { active: true, value: 'region', label: 'Region', index: 2 },
    { active: true, value: 'group', label: 'Group', index: 3 },
    { active: true, value: 'individual', label: 'Individual', index: 4 }
]



export const dmsKeys = [
    { value: 'batchNumber', label: 'Batch Number' },
    { value: 'dealerETA', label: 'Dealer ETA' },
    { value: 'shipName', label: 'Ship Name' },
    { value: 'locationStatus', label: 'Location Status' },
    { value: 'dealNumber', label: 'Deal Number' },
    { value: 'retailCategory', label: 'Retail Category' },
    { value: 'managerIntro', label: 'Sales Manager Intro' },
    { value: 'finIns', label: 'Finance / Insurance' },
    { value: 'afterMrkt', label: 'After Market' },
    { value: 'financePreApproval', label: 'Finance Pre-Approval' },
]

export const fieldTypes = [
    { value: 'text', label: (<><i className="ico icon-dynamic-textfield mr-1"></i> Text Field</>) },
    { value: 'number', label: (<><i className="ico icon-dynamic-numberfield mr-1"></i> Number Field</>) },
    { value: 'price', label: (<><i className="ico icon-dynamic-pricefield mr-1"></i> Price Field</>) },
    { value: 'toggle', label: (<><i className="ico icon-dynamic-switch mr-1"></i> Switch</>) },
    { value: 'date', label: (<><i className="ico icon-dynamic-datefield mr-1"></i> Date Field</>) },
    { value: 'select', label: (<><i className="ico icon-dynamic-radio mr-1"></i> Single Select</>) },
    { value: 'multiselect', label: (<><i className="ico icon-dynamic-checkbox mr-1"></i> Multi Select</>) }
]