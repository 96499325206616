import React, { useEffect, useState } from 'react';
import EmailListBody from '../emailListBody';
import EmailList from '../list';
import _ from 'lodash'

const Starred = ({
	messages,
	setMessages,
	token,
	handleOpenComposeModal,
	filterThreads,
	updateMessage,
	selectedMessage,
	setSelectedMessage,
	shouldChildSetSelectedMsg,
	setShouldChildSetSelectedMsg,
	searchText,
	setSearchText,
	loadMoreThreads,
	loadingMore,
	refreshLoader,
	searchThreads,
	refreshThreads,
	structureType,
	handleMarkAs,
	selectThreads,
	userData,
	nylasAccountData,
	labels,
	folders,
	checkDataLoad,
	setDataload,
	isPaging,
	setPaging,
	hasMore,
	setHasMoreData,
	dispatch,
	setLoadingMore,
	isLimitReached,
	setIsLimitReached
}) => {
	const [filteredMessages, setFilteredMessages] = useState([]);
	const [threadsSelected, setThreadsSelected] = useState(false);
	const [threadsLoader, setThreadsLoader] = useState(true);
	const pageLimit = 30

	useEffect(() => {
		if (!checkDataLoad) {
			return;
		}

		let folderID = null;
		if (structureType === 'labels') {
			labels.forEach(label => {
				if (label.name === 'inbox') {
					folderID = label.id
				}
			})
		} else if (structureType === 'folders') {
			folders.forEach(folder => {
				if (folder.name === 'inbox') {
					folderID = folder.id
				}
			})
		}

		let threadsRef = window.firebase.firestore()
			.collection(`nylas-accounts`)
			.doc(userData.nylas.account_id)
			.collection('nylas-threads')
			.orderBy('last_message_timestamp', 'desc')
			.where('starred', '==', true)

		if (hasMore.length > 0) {
			threadsRef = threadsRef
				.startAfter(hasMore[0])
				.limit(pageLimit)
		}
		else {
			threadsRef = threadsRef
				.limit(pageLimit)
		}

		const unsubstarredlist = threadsRef
			.onSnapshot(onCollectionUpdate);
		return () => {
			unsubstarredlist && unsubstarredlist();
		}

	}, [checkDataLoad])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		let snapshotDoc;
		let messagesArr = [];

		querySnapshot.docChanges().forEach(change => {
			if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
			{
				snapshotDoc = {
					...change.doc.data(),
				};
				if (change.type === 'added') {
					actionType = "ADD_LIST";
				}
				else if (change.type === 'modified') {
					actionType = "UPDATE_LIST"
				}
				else if (change.type === 'removed') {
					actionType = "REMOVE_LIST"
				}
			}
		})
		if (!actionType) {
			if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
				setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
			}
			else {
				setHasMoreData([]);
				setIsLimitReached(true)
			}
			if (querySnapshot.size > 0) {
				querySnapshot.forEach(doc => {
					messagesArr.push(doc.data());
				});
				// setMessages(messagesArr)
			} else {
				messagesArr = [];
				// setMessages([])
				// return
			}
		}

		dispatch({
			type: isPaging ? "APPEND_LIST" : actionType ? actionType : "SUCCESS",
			data: actionType ? snapshotDoc : messagesArr
		});


		setDataload(false)
		// setLoader(false)
		setThreadsLoader(false)
		setPaging(false)
		setLoadingMore(false)
	}

	useEffect(() => {
		const filteringMessages = () => {
			if (messages && messages.length > 0) {
				let filteredMessages1 = messages.filter(message => {
					let result = false;

					if (message.starred === true) {
						result = true;
					}
					message[structureType].forEach(label => {
						if (label && label.name === 'trash') {
							result = false;
						}
					})

					return result;
				});

				setFilteredMessages([...filteredMessages1]);
				if (shouldChildSetSelectedMsg) {
					// setSelectedMessage(filteredMessages1[0]);;
				} else if (!shouldChildSetSelectedMsg) {
					setShouldChildSetSelectedMsg(true);
				}
			}
		}
		filteringMessages();
	}, [messages]);


	return (
		<div className='email-list-wraper'>
			<EmailList
				messages={filteredMessages}
				setSelectedMessage={setSelectedMessage}
				selectedMessage={selectedMessage}
				filterThreads={filterThreads}
				selectThreads={selectThreads}
				threadsSelected={threadsSelected}
				searchText={searchText}
				setSearchText={setSearchText}
				updateMessage={updateMessage}
				loadMoreThreads={loadMoreThreads}
				type={'starred'}
				loadingMore={loadingMore}
				searchThreads={searchThreads}
				refreshThreads={refreshThreads}
				isLimitReached={isLimitReached}
				threadsLoader={threadsLoader}
				refreshLoader={refreshLoader}
				handleMarkAs={handleMarkAs}
				setThreadsSelected={setThreadsSelected}
				setFilteredMessages={setFilteredMessages}
				filteredMessages={filteredMessages}
			/>
			{filteredMessages.length > 0 ? (
				<EmailListBody
					selectedMessage={selectedMessage}
					setSelectedMessage={setSelectedMessage}
					token={token}
					handleOpenComposeModal={handleOpenComposeModal}
					updateMessage={updateMessage}
					threadsLoader={threadsLoader}
					refreshThreads={refreshThreads}
					type={'starred'}
					userData={userData}
				/>
			) : (
					<div className='email-list-body'></div>
				)}
		</div>
	);
};

export default Starred;
