/** LIBRARIES */
import React, { Component, Suspense } from 'react';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
/** COMPONENTS */
import List from './list'
import DealerList from './dealerList';
import { userLevel } from '../../services/enum';

class Stock extends Component {

    render() {
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => (

                    <Suspense >
                        <>
                            {
                                dealersettings.level === userLevel.INDIVIDUAL ? (
                                    <DealerList
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        history={this.props.history}
                                        dealersettings={dealersettings}
                                        updateDealerSettings={updateDealerSettings}
                                    />
                                ) : (
                                    <List
                                        clientUsers={clientUsers}
                                        groupUsers={groupUsers}
                                        history={this.props.history}
                                        dealersettings={dealersettings}
                                        updateDealerSettings={updateDealerSettings}
                                    />
                                )
                            }
                        </>

                    </Suspense>
                )}
            </LayoutConsumer>
        );
    }

}
export default Stock;