import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import _images from '../../images'
import { ContentOverlay, PieChart, AntDatePicker, NoDataPieChart, ReactSelect } from "../../components";
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';

const PerformanceReview = (props) => {
    const [loader, setLoader] = useState(true)
    const [checkoutData, setCheckoutData] = useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [performance, setPerformance] = useState([])
    const [filter, setFilter] = useState({ type: 'month', format: 'MMM YYYY', date: moment().format('YYYY-MM-DD') })
    const [teams, setTeams] = useState([])
    const [selectedTeams, setSelectedTeams] = useState()


    useEffect(() => {
        let _teams = props.dealersettings.client
            && props.dealersettings.client.teams
            ? props.dealersettings.client.teams.map(rec => {
                return {
                    label: rec.name,
                    value: rec.id,
                    user_ids: rec.user_ids
                }
            }) : []
        setTeams(_teams)
        if (_teams.length > 0)
            setSelectedTeams(_teams[0]);
        setRefreshData(true)
    }, [])

    // useEffect(() => {
    //     setRefreshData(true)
    // }, [props.selectedUser])

    useEffect(() => {
        if (!refreshData)
            return
        setLoader(true)
        let _performance = [];
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.checkoutPerformance &&
            props.dealersettings.client.settings.checkoutPerformance.filter(a => a.active === true)
                .map(rec => {
                    return _performance.push({
                        label: (<div className="drop-image"><div className="drop-colorpicker" style={{ background: rec.color, width: '15px', height: '15px', marginTop: '3px' }}></div>{rec.name}</div>),
                        value: rec.value,
                        color: rec.color,
                        name: rec.name,
                    })
                })
        setPerformance(_performance)
        window.firebase.firestore().collection('checkout')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('logDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).startOf(filter.type)._d))
            .where('logDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment(filter.date).endOf(filter.type)._d))
            .get()
            .then(docs => {
                let snapshotData = [];
                docs.forEach((rec) => {
                    snapshotData.push({ ...rec.data(), 'id': rec.id })
                })

                let _data = []
                _.orderBy(props.clientUsers, ['name'], ['asc']).filter(a =>
                    Boolean(a.superAdmin) === false &&
                    !Boolean(a.isDisabled) &&
                    //(props.selectedUser ? a.id === props.selectedUser.value : true) &&
                    (props.ownerDashboard ? a.id === localStorage.uid : (selectedTeams ? _.includes(selectedTeams.user_ids, a.documentID) : true))).forEach(rec => {
                        _data.push({
                            documentID: rec.documentID,
                            user: <div className='table-user-list'>
                                <a href="#">
                                    <div className="table-user-avatar">
                                        <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                    {rec.name}
                                </a>
                            </div>,
                            profileImage: CommonHelper.showUserAvatar(rec.profileImage, rec.name),
                            userName: rec.name,
                            department: rec.department,
                            ...getPerformanceData(rec.documentID, _performance, snapshotData)
                        })
                    })
                setCheckoutData(_data)
                setLoader(false)
                setRefreshData(false)
            })
    }, [refreshData])

    const getPerformanceData = (id, _performance, _snapshotData) => {
        let _data = {};
        _performance.forEach(rec => {
            _data = {
                ..._data,
                [rec.value]: _snapshotData.filter(a => a[id] === rec.value).length
            }
        })
        return _data;
    }

    return (<>
        <div className="dashboard-box  draggable-section">
            <div className="dashboard-box-header">
                {
                    props.ownerDashboard === true ? (<></>) : (
                        <div className="form-group col-md-3 dashboard-widget-target-select" style={{ right: '115px' }}>
                            <ReactSelect
                                options={teams}
                                name={`checkout-teams`}
                                placeholder={'select team'}
                                onChange={(e, data) => {
                                    setSelectedTeams(e)
                                    setRefreshData(true)
                                }}
                                value={selectedTeams ? selectedTeams.value : null}
                                classNamePrefix={`cursor-pointer basic-select`}
                                removeClearable={true}
                            >
                            </ReactSelect>
                        </div>
                    )
                }
                <div className="form-group col-md-2 dashboard-widget-target-select" style={{ right: '35px' }}>
                    <AntDatePicker
                        value={filter.date}
                        name={'checkout-date'}
                        onChange={(val) => {
                            setFilter({
                                ...filter,
                                date: moment(val).format('YYYY-MM-DD')
                            })
                            setRefreshData(true)
                        }}
                        format={'MMM YYYY'}
                        placeholder='DD/MM/YYYY'
                        className={`form-control`}
                        disabledDate={(current) => { return current && current > moment().endOf('day') }}
                        allowClear={false}
                        picker={filter.type}
                    />
                </div>
                <div className="dashboard-toolbox">
                    {/* <a href="#" className="mini-button"> <i className="ico icon-filter"></i></a> 
                     <a href="#" className="mini-button ml-1"> <i className="ico icon-more"></i></a>  */}
                    {
                        props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                            <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                        )
                    }

                </div>
                <h3><Translate text={'Performance Review'} /> - {moment(filter.date).format(filter.format)}</h3>
            </div>
            <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        checkoutData.length > 0 ?
                            (
                                <div className="dashboard-salestarget" style={{ height: 'calc(100% - 0px)' }} >
                                    <div className="salestarget-chart">
                                        {
                                            performance.some(v => _.sumBy(checkoutData, v.value) > 0) ? (

                                                <PieChart
                                                    id={'performance-pie-chart'}
                                                    height={'145px'}
                                                    data={performance.map(rec => {
                                                        return {
                                                            ...rec,
                                                            count: _.sumBy(checkoutData, `${rec.value}`)
                                                        }
                                                    })}
                                                    disableLegend={true}
                                                    semiChart={true}
                                                    handleShowEnquiryList={(value, startDate, endDate, data) => {

                                                    }}
                                                />) :
                                                <div className="text-center">
                                                    <NoDataPieChart
                                                        id={'performance-pie-chart'}
                                                        height={'145px'}
                                                        semiChart={true}
                                                    />
                                                </div>
                                        }
                                    </div>
                                    <div className="salestarget-user pr-3" style={{ height: 'calc(100% - 150px)', minHeight: `275px`, overflow: `auto`, marginTop: `-15px` }}>
                                        {
                                            checkoutData.map((rec, index) => {
                                                return <div key={index} className="performance-user-loop performance-user-flex">
                                                    <div className="performance-user-avatar">
                                                        <img className="img-object-fit" src={rec.profileImage} />
                                                    </div>
                                                    <div className="performance-user-detail">
                                                        <h3>{rec.userName}</h3>
                                                        <div className="performance-data">
                                                            <ul className="performance-list">
                                                                {
                                                                    performance.map((r, i) => {
                                                                        return <li key={i} style={{ borderLeft: `3px solid ${r.color}` }}><span>{r.name}</span>{rec[r.value]}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            ) : (
                                <div className="spinner-loader h-100 p-5">
                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2"> No data</div>
                                    </div>
                                </div>
                            )
                }

            </div>
        </div>
    </>
    )
}

export default PerformanceReview;