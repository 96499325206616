import React, { useState, useEffect } from 'react';
import moment from 'moment';
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap'
import { isEmpty } from 'lodash';

import { ReactComponent as CloseIcon } from '../../images/close.svg';

import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import GroupInfo from '../../components/groupInfo';
import { PopUpModal } from '../../components';
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';

import InputMessage from './inputmessage';
import ChatMessageList from './chatmessagelist';

const ChatContainer = ({
	clientUsers,
	clientUser,
	handleCloseShowChat,
	dealersettings,
	sidepanelClose,
	markAsRead,
	contactChat
}) => {
	const name = dealersettings.name;
	const [loading, setLoading] = useState(true);
	const [currentAddress, setCurrentAddress] = useState(null);
	const [messages, setMessages] = useState([]);
	const [timestamp, setTimestamp] = useState(null);
	const [limitReached, setLimitReached] = useState(false);
	const [timestampForNewMessages, setTimestampForNewMessages] = useState(null);
	const [showGroupInfo, setShowGroupInfo] = useState({
		show: false,
		data: null,
	});

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--chatContainerHeight',
				window.innerHeight - 176 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--chatContainerHeight',
			window.innerHeight - 176 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		let alternateAddress = `${localStorage.uid}-${clientUser.id}`;
		let address = `${clientUser.id}-${localStorage.uid}`;
		let firstObserver;

		let arrToSort = []
		arrToSort.push(localStorage.uid)
		arrToSort.push(clientUser.id)
		let sortedArr = arrToSort.sort()
		let idForMessage = sortedArr.join('-')
		address = idForMessage
		alternateAddress = idForMessage
		// console.log(`address, alternateAddress`,address, alternateAddress)

		if (clientUser.conversationType === 'group') {
			address = clientUser.dbAddress
			alternateAddress = clientUser.dbAddress
		}
		if (contactChat) {
			address = clientUser.dbAddress
			alternateAddress = clientUser.dbAddress
		}

		let firstRef = window.firebase
			.firestore()
			.collection('messages')
			.doc(address)
			.collection(address);

		let secondRef = window.firebase
			.firestore()
			.collection('messages')
			.doc(alternateAddress)
			.collection(alternateAddress);
		/**
		 * 1. Fetch first n docs with orderBy desc timestamp and get data of the last of it (OneTime)
		 * 2. Add a listener which fetches all docs where timestamp > nth doc timestamp (Realtime)
		 * 3. onMoreDocsLoading fetch docs where timestamp < nth doc timestamp (OneTime)
		 */

		// 1
		const fetchMessages = () => {
			firstRef
				.orderBy('timestamp', 'desc')
				.limit(10)
				.get()
				.then(firstSnap => {
					// console.log('firstSnap',firstSnap)
					if (firstSnap.size > 0) {
						let localTimestamp = firstSnap.docs[
							firstSnap.docs.length - 1
						].data().timestamp - 10;
						setCurrentAddress(address);
						setTimestamp(localTimestamp);
						setTimestampForNewMessages(localTimestamp);

						// console.log('timestamp added on first');

						let observerTimestamp = timestampForNewMessages
							? timestampForNewMessages
							: localTimestamp;

						// console.log(observerTimestamp)
						firstObserver = firstRef
							.where('timestamp', '>', observerTimestamp)
							.orderBy('timestamp', 'desc')
							.onSnapshot(snapshots => {
								// console.log('firstObserver firstSnap snapshots',snapshots)

								let messagesArr = [];
								snapshots.forEach(doc => {
									messagesArr.push(doc.data());
								});
								setMessages([...messages, ...messagesArr]);
								setLoading(false);

								return;
							});
					} else {
						// console.log('timestamp not added in first try');

						secondRef
							.orderBy('timestamp', 'desc')
							.limit(10)
							.get()
							.then(secondSnap => {
								// console.log('secondSnap',secondSnap)
								if (secondSnap.size > 0) {
									let localTimestamp = secondSnap.docs[
										secondSnap.docs.length - 1
									].data().timestamp - 10;

									setCurrentAddress(alternateAddress);
									setTimestamp(localTimestamp);
									setTimestampForNewMessages(localTimestamp);

									// console.log('timestamp added on second');

									let observerTimestamp = timestampForNewMessages
										? timestampForNewMessages
										: localTimestamp;

									firstObserver = secondRef
										.where('timestamp', '>', observerTimestamp)
										.orderBy('timestamp', 'desc')
										.onSnapshot(snapshots => {
											let messagesArr = [];
											snapshots.forEach(doc => {
												messagesArr.push(doc.data());
											});
											setMessages([...messages, ...messagesArr]);
											setLoading(false);
										});
								} else {
									// console.log('timestamp not added in second try');
									setCurrentAddress(alternateAddress);
									firstObserver = secondRef
										.where('timestamp', '>', moment()._d.getTime())
										.orderBy('timestamp', 'desc')
										.onSnapshot(snapshots => {
											let messagesArr = [];
											snapshots.forEach(doc => {
												messagesArr.push(doc.data());
											});
											setMessages([...messages, ...messagesArr]);
											setLoading(false);
										});
								}
							});
					}
				});
		};

		fetchMessages();

		return () => {
			firstObserver && firstObserver();
		};
	}, [clientUser.id, clientUser.dbAddress]);

	const handleScroll = e => {
		if (limitReached) {
			return;
		}

		let difference = e.target.scrollHeight - e.target.offsetHeight;
		const scrollTop = e.target.scrollTop * -1;
		difference = difference - 2;

		// console.log(scrollTop, difference);

		if (scrollTop > difference) {
			loadMoreMessages();
		}
	};

	const loadMoreMessages = async () => {
		try {
			let ref = window.firebase
				.firestore()
				.collection('messages')
				.doc(currentAddress)
				.collection(currentAddress);

			let snapshots = await ref
				.where('timestamp', '<', timestamp)
				.orderBy('timestamp', 'desc')
				.limit(10)
				.get();

			if (snapshots.docs.length === 0) {
				return;
			}

			let messagesArr = [];

			snapshots.forEach(doc => {
				messagesArr.push(doc.data());
			});

			let nextTimeStamp = snapshots.docs[snapshots.docs.length - 1].data()
				.timestamp;

			if (nextTimeStamp === timestamp) {
				setLimitReached(true);
				return;
			} else {
				setTimestamp(nextTimeStamp);
				setMessages([...messages, ...messagesArr]);
			}
		} catch (error) {
			console.log(error);
		}
	};


	const handleMessageSend = async (content, type, nameFrom) => {
		// console.log('bef1 content, type, nameFrom',content, type, nameFrom)
		let contactName = ''
		let contactAvatar = null
		let contactID = ''
		if (
			contactChat &&
			!isEmpty(clientUser.participantInfo) &&
			clientUser.participantInfo.filter(
				participant => participant.isAMSME === true,
			).length
		) {
			const doc = clientUser.participantInfo.filter(
				participant => participant.isAMSME === true,
			)[0];
			contactName = doc.name
			contactAvatar = doc.avatar
			contactID = doc.id
		}
		try {
			if (currentAddress === null) {
				return;
			}

			const timestamp = moment()._d.getTime();

			const messageRef = window.firebase
				.firestore()
				.collection('messages')
				.doc(currentAddress)
				.collection(currentAddress)
				.doc(timestamp.toString());

			let messageModel = {
				content,
				idFrom: localStorage.uid,
				idTo: contactChat ? contactID : clientUser.id,
				idToAMSMe: contactChat ? true : false,
				timestamp,
				type,
				oemID: dealersettings.client.settingsID,
				clientID: dealersettings.client.id,
				isRead: false,
				documentID: null,
				avatarFrom: CommonHelper.getUserImagebyId(clientUsers, localStorage.uid),
				addedFrom: 'web',
				nameFrom: dealersettings.name
			};

			let messageInfoModel = {
				clientID: dealersettings.client.id,
				isRead: false,
				lastMessage: {},
				oemID: dealersettings.client.settingsID,
				participantInfo: [],
				timestamp,
			};

			if (clientUser.conversationType === 'group') {
				messageModel.idTo = clientUser.dbAddress
				messageModel.groupPendingReads = []
				clientUser.participants.forEach(participant => {
					if (participant !== localStorage.uid) {
						messageModel.groupPendingReads.push(participant)
					}
				})
				messageInfoModel.groupPendingReads = []
				clientUser.participants.forEach(participant => {
					if (participant !== localStorage.uid) {
						messageInfoModel.groupPendingReads.push(participant)
					}
				})
				messageInfoModel.lastMessage = { ...messageModel }
			} else {
				messageInfoModel.participantInfo.push({
					avatar: CommonHelper.getUserImagebyId(clientUsers, localStorage.uid),
					name: nameFrom,
					isAMSME: false,
					id: localStorage.uid
				})
				messageInfoModel.participantInfo.push({
					avatar: contactChat ? contactAvatar : CommonHelper.getUserImagebyId(clientUsers, clientUser.id),
					name: contactChat ? contactName : clientUsers.filter(a => a.id === clientUser.id).length ? clientUsers.filter(a => a.id === clientUser.id)[0].name : '',
					isAMSME: contactChat ? true : false,
					id: contactChat ? contactID : clientUser.id
				})
				messageInfoModel.lastMessage = { ...messageModel }
			}
			// console.log('ef1 messageModel', messageModel)
			// console.log('ef1 messageInfoModel', messageInfoModel)

			await messageRef.set(messageModel, { merge: true });
			const ref = window.firebase
				.firestore()
				.collection('messages')
				.doc(currentAddress)
			await ref.set(messageInfoModel, { merge: true });

		} catch (error) {
			console.log(error);
		}
	};

	const leaveGroup = (shouldCloseModal) => {
		// console.log('leave group', e)
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to leave this group.',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				let objData = { ...clientUser }
				objData.participants = objData.participants.filter(participant => participant !== localStorage.uid)
				if (!objData.participants.includes(objData.addedBy) && objData.participants.length > 0) {
					objData.addedBy = objData.participants[0]
				}
				let documentID = objData['dbAddress']
				delete objData['dbAddress']
				// console.log('this is the doc id ', documentID, objData)
				window.firebase.firestore().doc(`messages/${documentID}`).set(objData, { merge: true })
					.then(() => {
						if (shouldCloseModal) {
							setShowGroupInfo({ show: false, data: null })
						}
						handleCloseShowChat()
						toast.notify(`Left ${objData.name ? objData.name : 'group chat'} successfully`, {
							duration: 2000
						})
					})
					.catch(error => {
						console.error(error);
						toast.notify('Something went wrong', {
							duration: 2000
						})
					});
			}
		})
	}

	return (
		<section id='chatApp' className='chatApp'>
			<div className={'chatApp__room'}>
				<div className={'chatApp__conv'}>
					<div className={`chatApp__convTitle ${clientUser.conversationType ? 'custom__convTitle' : ''}`}>
						<span id='chatApp-close-icon' onClick={handleCloseShowChat}>
							<CloseIcon />
						</span>
						<span>
							{contactChat &&
								!isEmpty(clientUser.participantInfo) &&
								clientUser.participantInfo.filter(
									participant => participant.isAMSME === true,
								).length
								? clientUser.participantInfo.filter(
									participant => participant.isAMSME === true,
								)[0].name
								: clientUser.name}
						</span>
						{clientUser.conversationType && (
							<Dropdown className='quick-view-more'>
								<Dropdown.Toggle
									as={CustomToggle}
								//className='common-button'
								>
									<i className='ico icon-more'></i>
								</Dropdown.Toggle>
								<Dropdown.Menu
									as={CustomMenu}
									ChildClass="more-dropdown groupchat-dropdown"
									xplacement="bottom-end"
								>
									<Dropdown.Item eventKey="1" onClick={(e) => { leaveGroup(e) }}><i className="fas fa-sign-out-alt"></i>  <Translate text={'leaveGroup'} /></Dropdown.Item>
									<Dropdown.Item eventKey="2" onClick={(e) => { setShowGroupInfo({ show: true, data: clientUser }) }}><i className="fas fa-info-circle"></i> <Translate text={'Group Info'} /></Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)
						}
					</div>
					<ChatMessageList
						clientUsers={clientUsers}
						messages={messages}
						loading={loading}
						sidepanelClose={sidepanelClose}
						timestamp={timestamp}
						handleScroll={handleScroll}
						markAsRead={markAsRead}
						clientUser={clientUser}
						contactChat={contactChat}
					/>
					<div className={'chatApp__convSendMessage clearfix'}>
						<InputMessage
							isLoading={false}
							owner={name}
							ownerAvatar={'https://i.pravatar.cc/150?img=32'}
							handleMessageSend={handleMessageSend}
							contactChat={contactChat}
							dealersettings={dealersettings}
							// sendMessage={props.sendMessage}
							// typing={props.typing}
							// resetTyping={props.resetTyping}
						/>
					</div>
				</div>
			</div>
			<PopUpModal show={showGroupInfo.show}>
					<GroupInfo
							show={showGroupInfo.show}
							title={'Group Info'}
							handleClose={() => setShowGroupInfo({ show: false, data: null })}
							dealersettings={dealersettings}
							clientUsers={clientUsers}
							groupData={showGroupInfo.data}
							leaveGroup={leaveGroup}
					/>
			</PopUpModal>
		</section>
	);
};
/* end ChatContainer component */
/* ========== */

export default ChatContainer;
