import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment';
import { ReactTimePicker, AntDatePicker, PopUpModal, ReactSelect, SidePanel } from '../../../../components'
import Translate from '../../../../constants/translate';
import { schedulerVM } from './viewModel'
import CommonHelper from '../../../../services/common';
import EnquiryList from '../../../pipeline/popoverlist/list';
import { MainContext } from '../../../layout/provider';
import AddTVtemplate from '../templates/addTVTemplate'
import { StageProvider, StageConsumer } from '../../../pipeline/provider'
import QuickView from "../../../pipeline/quickview";
import { firestoreDB } from '../../../../services/helper';

const ScheduleContact = (props) => {
    const [scheduleEnq, setScheduleEnq] = useState(props.scheduleEnq)
    const [oldscheduleEnq, setOldScheduleEnq] = useState(props.scheduleEnq)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newSchedule = !_.isEmpty(props.scheduleEnq) ? false : true;
    const [showScheduledActivities, setShowScheduled] = useState(true);
    const [scheduledActivities, setScheduledActivities] = useState([])
    const [selectedEnquiry, setEnquiry] = useState(
        !_.isEmpty(props.scheduleEnq) ? props.scheduleEnq.enquiry : props.enquiry ? props.enquiry : null,
    );
    const [enquiryPopover, setEnquiryPopover] = useState({
        show: false,
        target: null,
    });
    const [showtvTemplate, setShowtvTemplate] = useState({
        show: false,
    });
    const { tvTemplates } = useContext(MainContext);
    const [availableDevice, setAvailableDevice] = useState([])
    const [deleteLoader, setDeleteloader] = useState(false);
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-quickview' })
    const [allusers, setAllUsers] = useState(props.allUsers)
    const [olddisplayName, setolddisplayName] = useState()
    const [displayName, setdisplayName] = useState()
    //const [otherUsers, setOtherUsers] = useState([])

    useEffect(() => {
        if (_.isEmpty(props.scheduleEnq)) {
            const newschedulerVM = Object.assign({}, schedulerVM);
            newschedulerVM.documentID = window.firebase.firestore().collection(`tvScheduler`).doc().id;
            newschedulerVM.deviceID = props.device ? props.device.documentID : null;
            newschedulerVM.clientID = props.dealersettings.client.id;
            newschedulerVM.oemID = props.dealersettings.oemID;
            newschedulerVM.addedBy = localStorage.uid;
            newschedulerVM.modifiedBy = localStorage.uid;
            newschedulerVM.addedDate = window.firebase.firestore.Timestamp.now();
            newschedulerVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            if (props.slotDate) {
                newschedulerVM.startDate = window.firebase.firestore.Timestamp.fromDate(
                    moment(props.slotDate.start).toDate(),
                );
                newschedulerVM.endDate = window.firebase.firestore.Timestamp.fromDate(
                    moment(props.slotDate.end).toDate(),
                );
            }
            if (!_.isEmpty(props.enquiry)) {
                newschedulerVM.enquiryID = props.enquiry.documentID;
                newschedulerVM.enquiry = props.enquiry;
            }
            // else {
            //     newschedulerVM.startDate = window.firebase.firestore.Timestamp.now();
            //     newschedulerVM.endDate = window.firebase.firestore.Timestamp.fromDate(
            //         moment().add(1, 'hours').toDate(),
            //     );
            // }
            setScheduleEnq(newschedulerVM)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(scheduleEnq)) {
            return;
        }

        if (scheduleEnq.startDate && showScheduledActivities) {
            window.firebase.firestore().collection('tvScheduler')
                .where('isDeleted', '==', false)
                .where('oemID', '==', props.dealersettings.oemID)
                .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.unix(scheduleEnq.startDate.seconds).startOf('day').toDate()))
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.unix(scheduleEnq.startDate.seconds).endOf('day').toDate()))
                .get().then(querySnapshot => {

                    let _activities = [];
                    //let otherUsers = [];
                    querySnapshot.forEach(function (doc) {
                        var logVM = {
                            ...doc.data(),
                            documentID: doc.id,
                            startDate: _.isEmpty(doc.data().startDate) ? doc.data().addedDate : doc.data().startDate,
                            title: doc.data().enquiry && doc.data().enquiry.contact ? CommonHelper.displayContactName([], doc.data().enquiry.contact) : '',
                            contactName: doc.data().enquiry && doc.data().enquiry.contact ? CommonHelper.displayContactName([], doc.data().enquiry.contact) : '',
                            deviceName: _.find(props.allDevice, { documentID: doc.data().deviceID }) ? _.find(props.allDevice, { documentID: doc.data().deviceID }).name : '--',
                            dealerName: doc.data().clientID !== props.dealersettings.client.id ? CommonHelper.getOtherDealerName(props.dealersettings, doc.data().clientID) : null

                        };
                        // if (doc.data()?.enquiry?.owner && !allusers.some(a => a.id === doc.data()?.enquiry?.owner)) {
                        //     otherUsers.push(doc.data()?.enquiry?.owner)
                        // }
                        //if (logVM.deviceID === props.device.documentID)
                        _activities.push(logVM)
                    });
                    _activities = _activities.filter(a => !_.isEmpty(props.device) ? a.deviceID === props.device.documentID : true)
                    if (!_.isEmpty(_activities)) {
                        if (scheduleEnq && scheduleEnq.documentID)
                            _activities = _activities.filter(e => e.documentID !== scheduleEnq.documentID).sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });
                        else
                            _activities = _activities.sort(function (x, y) { return (x.startDate.seconds > y.startDate.seconds ? -1 : 1); });
                    }
                    // if (props?.scheduleEnq?.enquiry?.owner && !allusers.some(a => a.id === props?.scheduleEnq?.enquiry?.owner)) {
                    //     otherUsers.push(props?.scheduleEnq?.enquiry?.owner)
                    // }
                    //setOtherUsers(_.uniq(otherUsers))
                    setScheduledActivities(_activities);
                })

        }

    }, [scheduleEnq ? scheduleEnq.startDate : true])

    useEffect(() => {
        if (_.isEmpty(selectedEnquiry?.contact?.documentID))
            return

        firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${selectedEnquiry.contact.documentID}`).get()
            .then(doc => {
                if (doc.exists) {
                    setdisplayName(doc.data().tvDisplayName || CommonHelper.displayContactName([], doc.data()));
                    setolddisplayName(doc.data().tvDisplayName || CommonHelper.displayContactName([], doc.data()));
                }
            })

    }, [selectedEnquiry])

    // useEffect(() => {
    //     if (_.isEmpty(otherUsers))
    //         return;

    //     let userPromise = [];

    //     otherUsers.forEach(user => {
    //         if (!allusers.some(a => a.id === user))
    //             userPromise.push(window.firebase.firestore().doc(`users/${user}`).get());
    //     })

    //     Promise.all(userPromise)
    //         .then(snapshot => {
    //             let users = [];
    //             snapshot.forEach(doc => {
    //                 users.push({
    //                     ...doc.data(),
    //                     id: doc.id
    //                 })
    //             })
    //             setAllUsers([
    //                 ...allusers,
    //                 ...users
    //             ])
    //         })

    // }, [otherUsers])

    useEffect(() => {
        if (!_.isEmpty(props.device) || _.isEmpty(scheduleEnq) || _.isEmpty(scheduleEnq.startDate) || _.isEmpty(scheduleEnq.endDate))
            return


        window.firebase.firestore().collection('tvScheduler')
            .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.unix(scheduleEnq.startDate.seconds).startOf('day').toDate()))
            .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.unix(scheduleEnq.startDate.seconds).endOf('day').toDate()))
            .where('oemID', '==', props.dealersettings.oemID)
            .where('isDeleted', '==', false)
            .get()
            .then(querySnapshot => {
                let _list = []
                querySnapshot.forEach(function (doc) {
                    _list.push({
                        ...doc.data(),
                        documentID: doc.id
                    })
                });
                const bookedDevice = _list.filter(a =>
                    scheduleEnq.startDate.seconds >= a.startDate.seconds
                    && scheduleEnq.startDate.seconds < a.endDate.seconds
                    && (oldscheduleEnq ? a.documentID !== oldscheduleEnq.documentID : true)
                ).map(rec => { return rec.deviceID });
                const _availableDevice = _.filter(props.allDevice.filter(a => !Boolean(a.showScheduler)), (v) => _.indexOf(bookedDevice, v.documentID) < 0);
                setAvailableDevice(_availableDevice);
                setScheduleEnq({
                    ...scheduleEnq,
                    deviceID: _availableDevice.some(a => a.documentID === scheduleEnq.deviceID) ? scheduleEnq.deviceID : null
                })
            });

    }, [scheduleEnq ? scheduleEnq.startDate : false, scheduleEnq ? scheduleEnq.endDate : false])

    const handleDateChange = (val, name) => {
        if (val) {
            if (scheduleEnq.startDate)
                val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment.unix(scheduleEnq.startDate.seconds).format('HH:mm')).toDate();
            else
                val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).toDate();

            if (!showScheduledActivities)
                setShowScheduled(true);

            setScheduleEnq({
                ...scheduleEnq,
                [name]: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()),
                endDate: null,
            });
            setErrorFields({
                ...errorFields,
                [name]: null,
            });
        }
    };

    const handleStartTimeChange = (val, name) => {
        //console.log(val)
        setScheduleEnq({
            ...scheduleEnq,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()),
            endDate: null,
        });
        setErrorFields({
            ...errorFields,
            [name]: null,
        });
    };

    const handleEndTimeChange = (val, name) => {

        val = `${moment.unix(scheduleEnq.startDate.seconds).format('YYYY-MM-DD')} ${moment(val).format('HH:mm')}`;

        setScheduleEnq({
            ...scheduleEnq,
            [name]: window.firebase.firestore.Timestamp.fromDate(moment(val).toDate()),
        });
        setErrorFields({
            ...errorFields,
            [name]: null,
        });
    };

    const handleEnquiryClose = (id, objData) => {
        setScheduleEnq({
            ...scheduleEnq,
            ['enquiryID']: id,
            ['enquiry']: objData
        });
        setEnquiry(objData);
        setEnquiryPopover({ show: false, target: null });
        setErrorFields({
            ...errorFields,
            ['enquiryID']: null,
        });
    };

    const handleReactSelectChange = (e, data) => {
        setScheduleEnq({
            ...scheduleEnq,
            [data.name]: e ? e.value : null,
        });
        setErrorFields({
            ...errorFields,
            [data.name]: null,
        });
    };


    // CAN UPDATE DIFFIRENT LEVEL
    const handleSave = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(scheduleEnq['templateID'])) {
            formIsValid = false;
            errors['templateID'] = errorClass;
        }
        if (_.isEmpty(props.device) && _.isEmpty(scheduleEnq['deviceID'])) {
            formIsValid = false;
            errors['deviceID'] = errorClass;
        }
        if (_.isEmpty(scheduleEnq['startDate'])) {
            formIsValid = false;
            errors['startDate'] = errorClass;
        }
        if (_.isEmpty(scheduleEnq['endDate'])) {
            formIsValid = false;
            errors['endDate'] = errorClass;
        }
        // if (_.isEmpty(scheduleEnq['enquiryID'])) {
        //     formIsValid = false;
        //     errors['enquiryID'] = errorClass;
        // }
        setErrorFields(errors);
        if (_.isEmpty(errors) && scheduledActivities.length > 0) {
            var slotExists = scheduledActivities.find(item =>
                (scheduleEnq.startDate.seconds >= item.startDate.seconds && scheduleEnq.startDate.seconds < item.endDate.seconds && scheduleEnq.deviceID === item.deviceID) ||
                (scheduleEnq.endDate.seconds > item.startDate.seconds && scheduleEnq.endDate.seconds <= item.endDate.seconds && scheduleEnq.deviceID === item.deviceID) ||
                (scheduleEnq.startDate.seconds <= item.startDate.seconds && scheduleEnq.endDate.seconds >= item.endDate.seconds && scheduleEnq.deviceID === item.deviceID)
            );
            // var slotExists = scheduledActivities.find(item => scheduleEnq.startDate.seconds >= item.startDate.seconds
            //     && scheduleEnq.startDate.seconds < item.endDate.seconds
            //     && scheduleEnq.deviceID === item.deviceID);
            if (slotExists) {
                Swal.fire({
                    title: 'Booking Exists',
                    text:
                        'This slot is already booked for ' +
                        slotExists.contactName +
                        ' from ' +
                        moment
                            .unix(slotExists.startDate.seconds)
                            .format('DD/MM/YYYY hh:mm A') +
                        ' to ' +
                        moment.unix(slotExists.endDate.seconds).format('hh:mm A') +
                        '. Please select alternate slot',
                    icon: 'info',
                });
                formIsValid = false;
            }
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        scheduleEnq.modifiedDate = window.firebase.firestore.Timestamp.now();
        scheduleEnq.modifiedBy = localStorage.uid;
        scheduleEnq.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        if (displayName !== olddisplayName && selectedEnquiry?.contact?.documentID) {
            try {
                if (scheduleEnq?.enquiry?.contact) {
                    scheduleEnq.enquiry.contact.tvDisplayName = displayName;
                }
                firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${selectedEnquiry.contact.documentID}`)
                    .update({ tvDisplayName: displayName });
            } catch { }

        }

        window.firebase
            .firestore()
            .doc(
                `tvScheduler/${scheduleEnq.documentID}`,
            )
            .set({ ..._.pick(scheduleEnq, _.keys(schedulerVM)) }, { merge: true })
            .then(snapshot => {
                setLoader(false);
                toast.notify(
                    'Lead scheduled successfully.',
                    {
                        duration: 2000,
                    },
                );
                props.handleClose();
            })
            .catch(error => {
                console.error(error);
                Swal.fire('Something went wrong', '', 'error');
            });
    }

    const deleteScheduler = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(result => {
            if (result.value) {
                setDeleteloader(true);

                window.firebase
                    .firestore()
                    .doc(`tvScheduler/${scheduleEnq.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        isDeleted: true
                    }, { merge: true })
                    .then(snapshot => {
                        setDeleteloader(false);
                        props.handleClose();
                        toast.notify('Schedule deleted successfully', {
                            duration: 2000,
                        });
                    })
                    .catch(error => {
                        setDeleteloader(false);
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000,
                        });
                    });
            }
        });
    };

    const sidepanelClose = (objData) => {

        //handleStatusUpdateDone(objData);
        setShowPanel({
            ...showpanel,
            clsActive: '',
            enquiryid: 0
        })
    }

    const sidepanelOpen = (val) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            enquiryid: val
        })
    }

    const handleStatusUpdateDone = () => {

    }

    const getExcludedTimes = () => {
        let arrExcludedTimes = [];
        let start = moment('2024-08-08 02:00 PM'); // Start at midnight
        let end = moment('2024-08-08 06:30 PM');     // End at the last minute of the day

        while (start <= end) {
            arrExcludedTimes.push(start.toDate());
            start = start.add(15, 'minutes');
        }

        return arrExcludedTimes;
    };


    return _.isEmpty(scheduleEnq) ? (<></>) : (<>
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-company"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
                {scheduleEnq.dealerName ? <div className="mt-2 ml-2"><div className="badge badge-pill badge-groupname">{scheduleEnq.dealerName}</div></div> : <></>}
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className=' pop-layout form-style'>
                            <div className="form-row">

                                <div className='form-group col-md-6'>
                                    <label ><Translate text={'date'} /></label>
                                    <div className='inner-addon right-addon'>
                                        <AntDatePicker
                                            value={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).format('YYYY-MM-DD') : null}
                                            name={'startDate'}
                                            onChange={e => {
                                                handleDateChange(e, 'startDate');
                                            }}
                                            format='DD/MM/YYYY'
                                            placeholder='DD/MM/YYYY'
                                            className={`form-control ${errorFields['startDate']}`}
                                            disabledDate={(current) => { return current && current < moment().startOf('day') }}
                                        />
                                    </div>
                                </div>
                                <div className='form-group col-md-3'>
                                    <label><Translate text={'from'} /></label>
                                    <div className='inner-addon right-addon'>
                                        <i className='bg-icons ico icon-time'></i>

                                        <ReactTimePicker
                                            value={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).toDate() : null}
                                            name={'startDate'}
                                            onChange={handleStartTimeChange}
                                            timeIntervals={15}
                                            placeholder='h:mm aa'
                                            className={`form-control ${errorFields['startDate']}`}
                                            minTime={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).set('hour', 6).set('minute', 0).toDate() : moment().set('hour', 6).set('minute', 0)}
                                            maxTime={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).set('hour', 22).set('minute', 0).toDate() : moment().set('hour', 6).set('minute', 0)}
                                            excludedTimes={scheduleEnq.startDate && moment.unix(scheduleEnq.startDate.seconds).format('YYYY-MM-DD') === '2024-08-08' ? getExcludedTimes() : null}
                                        />
                                    </div>
                                </div>
                                <div className='form-group col-md-3'>
                                    <label ><Translate text={'to'} /></label>
                                    <div className='inner-addon right-addon'>
                                        <i className='bg-icons ico icon-time'></i>

                                        <ReactTimePicker
                                            value={scheduleEnq.endDate ? moment.unix(scheduleEnq.endDate.seconds).toDate() : null}
                                            name={'endDate'}
                                            onChange={handleEndTimeChange}
                                            timeIntervals={15}
                                            placeholder='h:mm aa'
                                            className={`form-control ${errorFields['endDate']}`}
                                            minTime={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).toDate() : moment().set('hour', 6).set('minute', 0)}
                                            maxTime={scheduleEnq.startDate ? moment.unix(scheduleEnq.startDate.seconds).set('hour', 22).set('minute', 0).toDate() : moment().set('hour', 6).set('minute', 0)}
                                            excludedTimes={scheduleEnq.startDate && moment.unix(scheduleEnq.startDate.seconds).format('YYYY-MM-DD') === '2024-08-08' ? getExcludedTimes() : null}
                                        />
                                    </div>
                                </div>

                            </div>
                            {
                                !_.isEmpty(scheduledActivities)
                                    ?
                                    (<><div className="calendarlist-activity-item">
                                        <div className="scheduled-activities-title"> <Translate text={'Scheduled Contacts'} /></div>
                                        {
                                            scheduledActivities.map((log, index) => {
                                                return (
                                                    <div key={index} className="calendarlist-activity-loop">

                                                        <div className="calendarlist-activity-list float-none">
                                                            <div className="calendarlist-activity-title">
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setOldScheduleEnq(log);
                                                                    setScheduleEnq(log);
                                                                    setEnquiry(log?.enquiry)
                                                                }}>{log.title}</a>
                                                            </div>
                                                            {
                                                                log.dealerName ? (
                                                                    <div className="blue-color"><i className="ico icon-group"></i> {log.dealerName}</div>
                                                                ) : (<></>)
                                                            }
                                                            <div className="calendarlist-activity-date">
                                                                <div className='float-right'>
                                                                    {
                                                                        _.find(allusers, { id: log?.enquiry?.owner }) ?
                                                                            (<>
                                                                                <div className="owner-view">
                                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img src={CommonHelper.getUserImagebyId(allusers, log.enquiry.owner)} className="img-object-fit" />
                                                                                    </a>
                                                                                </div>
                                                                                {CommonHelper.getUserNamebyId(allusers, log.enquiry.owner)}
                                                                            </>
                                                                            ) : (<></>)
                                                                    }
                                                                </div>
                                                                <div className='float-left'>
                                                                    {moment.unix(log.startDate.seconds).format('DD/MM/YYYY h:mm A')}-{moment.unix(log.endDate.seconds).format('h:mm A')}
                                                                    {
                                                                        log.deviceName ? (<><i className="ico icon-monitor ml-2"></i> {log.deviceName}</>) : (<></>)
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                        <div className='clearfix'></div>
                                    </>)
                                    :
                                    <></>
                            }
                            {
                                _.isEmpty(props.device) ? (
                                    <div className='form-group'>
                                        <label ><Translate text={'Available Device'} /></label>
                                        <ReactSelect
                                            options={availableDevice.map(rec => {
                                                return {
                                                    label: rec.name,
                                                    value: rec.documentID
                                                }
                                            })}
                                            placeholder={'select device'}
                                            name={'deviceID'}
                                            onChange={handleReactSelectChange}
                                            value={scheduleEnq.deviceID}
                                            classNamePrefix={`${errorFields["deviceID"]} basic-select`}
                                            removeClearable={false}
                                            isSearchable={true}
                                        ></ReactSelect>
                                    </div>
                                ) : (<></>)
                            }
                            <div className='form-group position-relative'>
                                {
                                    Boolean(props.dealersettings.rolePermissions.admin.workflowSetup) ? (
                                        <a href="#" onClick={() => {
                                            if (!_.isEmpty(scheduleEnq.templateID)) {
                                                setShowtvTemplate({
                                                    show: true,
                                                    template: _.find(tvTemplates, { documentID: scheduleEnq.templateID }),
                                                    title: 'Edit Template'
                                                })
                                            }
                                            else {
                                                setShowtvTemplate({
                                                    show: true,
                                                    title: 'Add Template'
                                                })
                                            }
                                        }} className="inputlink-addmore custom-more">
                                            {
                                                !_.isEmpty(scheduleEnq.templateID) ? (<>
                                                    <i className="ico icon-edit rearrange-mini"></i>edit
                                                </>) : (<>
                                                    <i className="ico icon-add rearrange-mini"></i>add
                                                </>)
                                            }

                                        </a>
                                    ) : (<></>)
                                }
                                <label ><Translate text={'Template'} /></label>
                                <ReactSelect
                                    options={tvTemplates.map(rec => {
                                        return {
                                            label: rec.title,
                                            value: rec.documentID
                                        }
                                    })}
                                    name={'templateID'}
                                    placeholder={'select template'}
                                    onChange={handleReactSelectChange}
                                    value={scheduleEnq.templateID}
                                    classNamePrefix={`${errorFields["templateID"]} basic-select`}
                                    removeClearable={false}
                                    isSearchable={true}
                                ></ReactSelect>
                            </div>
                            <div className='form-group'>
                                <label ><Translate text={'Link Enquiry'} /></label>
                                {selectedEnquiry ? (
                                    <div className='form-readonly-wrap'>
                                        <div className='input-readonly'>
                                            {
                                                _.isEmpty(props.enquiry) && props.dealersettings.client.id === scheduleEnq.clientID ? (<div className='input-readonly-close'>
                                                    <a
                                                        href='#'
                                                        onClick={() => {
                                                            setEnquiry(null);
                                                            setScheduleEnq({
                                                                ...scheduleEnq,
                                                                enquiryID: null,
                                                                enquiry: null,
                                                            });
                                                        }}
                                                    >
                                                        <i className='ico icon-remove'></i>{' '}
                                                    </a>
                                                </div>) : (<></>)
                                            }
                                            <div
                                                className='input-readonly-inner cursor-pointer'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    sidepanelOpen(selectedEnquiry.documentID);
                                                }}
                                            >
                                                <div className='inner-left'>
                                                    <i className='ico icon-enquiry'></i>
                                                </div>
                                                <div className='inner-right'>
                                                    {(!_.isEmpty(selectedEnquiry.requirement) && (!_.isEmpty(selectedEnquiry.requirement.make) || !_.isEmpty(selectedEnquiry.requirement.stock))) ? (
                                                        <>
                                                            <div className='input-readonly-head'>
                                                                {!_.isEmpty(selectedEnquiry.requirement.stock)
                                                                    ? selectedEnquiry.requirement.stock.make +
                                                                    ' ' +
                                                                    selectedEnquiry.requirement.stock.model
                                                                    : selectedEnquiry.requirement.make +
                                                                    ' ' +
                                                                    selectedEnquiry.requirement.model}
                                                                {
                                                                    selectedEnquiry.requirement.saleType
                                                                        ?
                                                                        <>
                                                                            <div className="badge badge-pill badge-white ml-1">
                                                                                {CommonHelper.getNameByValue(props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings && props.dealersettings.client.settings.salesType,
                                                                                    selectedEnquiry.requirement.saleType, '')}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </div>
                                                        </>
                                                    ) : <>

                                                        <div className="no-data-red"><Translate text={CommonHelper.showVehicleText(props.dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>

                                                    </>}
                                                    {
                                                        selectedEnquiry.contact ? (
                                                            <div className="link-enquiry-owner" style={{ borderBottom: '0px' }}>
                                                                <div className="link-enquiry-owner-view">
                                                                    {CommonHelper.displayContactIcon(selectedEnquiry.contact, 'mr-1')}
                                                                </div>{CommonHelper.displayContact([], selectedEnquiry.contact, '--')}</div>
                                                        ) : (<div className='input-readonly-head'>{selectedEnquiry.displayID}</div>)
                                                    }

                                                    {
                                                        selectedEnquiry.deliveryDate ? (
                                                            <div className="pipeline-item-div" title={'Delivery Date'}><i className="ico icon-timer"></i>
                                                                {moment.unix(selectedEnquiry.deliveryDate.seconds).format('DD MMM YYYY')}
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                    {selectedEnquiry.status && _.isString(selectedEnquiry.status) ? <>
                                                        {(() => {
                                                            const enquiryAllStatus = (props.dealersettings && props.dealersettings.client && props.dealersettings.client.settings ? props.dealersettings.client.settings.enquiryStatus : [])
                                                            let _objCurrentStatus = enquiryAllStatus.filter(e => e.value === selectedEnquiry.status)[0]

                                                            return <div className='input-readonly-sub'>
                                                                {
                                                                    _objCurrentStatus
                                                                        ?
                                                                        <>
                                                                            <div
                                                                                className={`badge badge-pill badge-${selectedEnquiry.status === 'open' ? 'open' : 'empty'}`}
                                                                                style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                                                                            >
                                                                                {_objCurrentStatus.name}
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>


                                                                        </>
                                                                }
                                                                {
                                                                    _.find(allusers, { id: selectedEnquiry?.owner }) ?
                                                                        (<div className='float-right'>
                                                                            <div className="owner-view">
                                                                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img src={CommonHelper.getUserImagebyId(allusers, selectedEnquiry.owner)} className="img-object-fit" />
                                                                                </a>
                                                                            </div>
                                                                            {CommonHelper.getUserNamebyId(allusers, selectedEnquiry.owner)}
                                                                        </div>
                                                                        ) : (<></>)
                                                                }
                                                            </div>
                                                        })()}

                                                    </> : selectedEnquiry.statusValue && _.isString(selectedEnquiry.statusValue) ? (<div className='input-readonly-sub'>
                                                        <div
                                                            className={`badge badge-pill badge-${selectedEnquiry.statusValue.toLowerCase()}`}
                                                        >
                                                            {selectedEnquiry.statusValue.split(/(?=[A-Z])/).join(' ')}
                                                        </div>
                                                    </div>) : (
                                                        <></>
                                                    )}


                                                </div>
                                            </div>

                                        </div>
                                        {
                                            showpanel.enquiryid
                                                ?
                                                <>
                                                    <StageProvider
                                                        dealersettings={props.dealersettings}
                                                        clientUsers={props.clientUsers}
                                                        groupUsers={props.groupUsers}
                                                    >
                                                        <StageConsumer>
                                                            {() => (

                                                                <SidePanel
                                                                    clsActive={showpanel.clsActive}
                                                                    clsName={showpanel.clsName}
                                                                    sidepanelClose={sidepanelClose}
                                                                    title=''
                                                                >
                                                                    <QuickView {...props}
                                                                        enquiryid={showpanel.enquiryid}
                                                                        isHideExpandView={false}
                                                                        isFilterApplied={true}
                                                                        updateListEnquiry={handleStatusUpdateDone}
                                                                        sidepanelClose={sidepanelClose}
                                                                    />
                                                                </SidePanel>
                                                            )}
                                                        </StageConsumer>
                                                    </StageProvider>
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>
                                ) : (
                                    <input
                                        type='text'
                                        className={`form-control ${errorFields["enquiryID"]}`}
                                        placeholder='Select Enquiry'
                                        readOnly
                                        onClick={e => {
                                            e.preventDefault();
                                            setEnquiryPopover({ show: true, target: e.target });
                                        }}
                                    />
                                )}
                            </div>
                            {
                                selectedEnquiry ? (
                                    <div className='form-group'>
                                        <label ><Translate text={'TV Display Name'} /></label>
                                        <input
                                            type='text'
                                            value={displayName}
                                            className={`form-control`}
                                            placeholder='tv display name'
                                            onChange={e => {
                                                e.preventDefault();
                                                setdisplayName(e.target.value)
                                            }}
                                        />
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                {
                    !newSchedule ? (
                        <button
                            type='button'
                            className='btn btn-red float-left'
                            onClick={() => {
                                deleteScheduler();
                            }}
                        >
                            {deleteLoader ? (
                                <span
                                    className='spinner-border spinner-button mr-1'
                                    role='status'
                                    aria-hidden='true'
                                ></span>
                            ) : (
                                <>
                                    <i className='ico icon-delete marker-delete'></i>
                                </>
                            )}
                            <Translate text={'delete'} />
                        </button>) : (<></>)
                }
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleSave(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
        <PopUpModal show={enquiryPopover.show}>
            <EnquiryList
                {...props}
                {...enquiryPopover}
                handleEnquiryClose={handleEnquiryClose}
                handleClose={() => {
                    setEnquiryPopover({ show: false });
                }}
            />
        </PopUpModal>
        <PopUpModal show={showtvTemplate.show}>
            <AddTVtemplate
                {...props}
                {...showtvTemplate}
                handleClose={(data) => {
                    setShowtvTemplate({
                        show: false
                    })
                    if (data)
                        setScheduleEnq({
                            ...scheduleEnq,
                            templateID: data.documentID
                        })
                }}
                type={'tvTemplate'}
            />
        </PopUpModal>
    </>);
}

export default ScheduleContact;