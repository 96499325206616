/** LIBRARIES */
import React, { useEffect, useState, useMemo } from 'react';
import { Tab, Col, Nav, Row } from 'react-bootstrap'
import _ from 'lodash'
/** COMPONENTS */
import Users from './users/index'
import Clients from './clients/index'
import DealerSettings from './dealerSettings'
import DealerTargets from './dealerTargets'
import QueueTargets from './queueSettings'
import CafeSettings from './cafe'
import { ClientContext } from './clientContext'
import Translate from '../../../constants/translate';
import OEMSettings from './oemSettings'
import OEMTarget from './oemTargets'
import Wholesalers from './wholesaler'
import RosterSettings from './roster';
import GDPRSettings from '../securitymanagement/sections/gdpr';

const CompanySettings = (props) => {
    const [active, setActive] = useState('pipelines')
    const [clientGroups, setClientGroups] = useState([]);
    const [clientRegions, setClientRegions] = useState([]);
    const [clientSubRegions, setClientSubRegions] = useState([]);
    const [clientTeams, setClientTeams] = useState([]);
    const [oemClients, setOEMClients] = useState([]);
    const [oemRoles, setOEMRoles] = useState([]);

    const sales = (_.isEmpty(props.dealersettings.client.moduleSettings)
        || _.isEmpty(props.dealersettings.client.moduleSettings.sales)
        || (props.dealersettings.client.moduleSettings.sales.enabled === true && props.dealersettings.client.moduleSettings.sales.active === true)) ? true : false
    const cafe = (_.isEmpty(props.dealersettings.client.moduleSettings)
        || _.isEmpty(props.dealersettings.client.moduleSettings.cafe)
        || (props.dealersettings.client.moduleSettings.cafe.enabled === true && props.dealersettings.client.moduleSettings.cafe.active === true)) ? true : false
    const tradeinPro = (!_.isEmpty(props.dealersettings.client.moduleSettings)
        && !_.isEmpty(props.dealersettings.client.moduleSettings.tradeinPro)
        && props.dealersettings.client.moduleSettings.tradeinPro.enabled === true
        && props.dealersettings.client.moduleSettings.tradeinPro.active === true) ? true : false;

    const rosterEnabled = (!_.isEmpty(props.dealersettings.client.moduleSettings)
        && !_.isEmpty(props.dealersettings.client.moduleSettings.roster)
        && props.dealersettings.client.moduleSettings.roster.enabled === true
        && props.dealersettings.client.moduleSettings.roster.active === true) ? true : false;

    const disableDealerSettings = props?.dealersettings?.rolePermissions?.permissions?.disableDealerSettings ? true : false;
    const disableUserSettings = props?.dealersettings?.rolePermissions?.permissions?.disableUserSettings ? true : false;
    const disableClientGroupSettings = props?.dealersettings?.rolePermissions?.permissions?.disableClientGroupSettings ? true : false;

    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 70) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        const groupSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage?.defaultModule?.includes('oem') ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/groups`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _groups = [];
                querySnapshot.forEach((rec) => {
                    _groups.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientGroups(_groups)
            })
        return () => {
            groupSnapshot && groupSnapshot();
        }
    }, [])

    useEffect(() => {
        const regionSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage?.defaultModule?.includes('oem') ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/regions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _regions = [];
                querySnapshot.forEach((rec) => {
                    _regions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientRegions(_regions)
            })
        return () => {
            regionSnapshot && regionSnapshot();
        }
    }, [])

    useEffect(() => {
        const subregionSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/subregions`)
            .orderBy('name', 'asc')
            .onSnapshot(querySnapshot => {
                let _subregions = [];
                querySnapshot.forEach((rec) => {
                    _subregions.push({ ...rec.data(), 'documentID': rec.id, 'code': rec.id })
                })
                setClientSubRegions(_subregions)
            })
        return () => {
            subregionSnapshot && subregionSnapshot();
        }
    }, [])

    useEffect(() => {

        let refTeamsCollection = window.firebase.firestore().collection(`clientSettings/${(localStorage?.defaultModule?.includes('oem') ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/teams`)
        if (props.dealersettings.level === 'oem' && localStorage?.defaultModule?.includes('oem'))
            refTeamsCollection = refTeamsCollection.where('oemID', '==', props.dealersettings.oemID);
        else if (props.dealersettings.level === 'region' && localStorage?.defaultModule?.includes('oem'))
            refTeamsCollection = refTeamsCollection.where('regionID', '==', props.dealersettings.regionID);
        else if (props.dealersettings.level === 'group' && localStorage?.defaultModule?.includes('oem'))
            refTeamsCollection = refTeamsCollection.where('groupID', '==', props.dealersettings.groupID);
        else
            refTeamsCollection = refTeamsCollection.where('clientID', '==', props.dealersettings.client.id);

        const teamSnapshot = refTeamsCollection
            .onSnapshot(querySnapshot => {
                let _teams = [];
                querySnapshot.forEach((rec) => {
                    _teams.push({ ...rec.data(), 'documentID': rec.id })
                })
                setClientTeams(_.orderBy(_teams, ['addedDate.seconds'], ['desc']))
            })
        return () => {
            teamSnapshot && teamSnapshot();
        }
    }, [])

    useEffect(() => {
        const oemclientSnapshot = window.firebase.firestore().collection('clients')
            .where('settingsID', '==', (localStorage?.defaultModule?.includes('oem') ? props.dealersettings.oemID : props.dealersettings.client.settingsID))
            .onSnapshot(querySnapshot => {
                let _clients = [];
                querySnapshot.forEach((rec) => {
                    _clients.push({ ...rec.data(), 'documentID': rec.id, 'id': rec.id })
                })
                setOEMClients(_clients)
            })
        return () => {
            oemclientSnapshot && oemclientSnapshot();
        }
    }, [])

    useEffect(() => {
        const oemroleSnapshot = window.firebase.firestore().collection(`clientSettings/${(localStorage?.defaultModule?.includes('oem') ? props.dealersettings.oemID : props.dealersettings.client.settingsID)}/roles`)
            .orderBy('addedDate', 'asc')
            .onSnapshot(querySnapshot => {
                let _roles = [];
                querySnapshot.forEach((rec) => {
                    _roles.push({ ...rec.data(), 'documentID': rec.id })
                })
                setOEMRoles(_.orderBy(_roles, 'name', 'asc'))
            })
        return () => {
            oemroleSnapshot && oemroleSnapshot();
        }
    }, [])

    const getQueryparam = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let key = params.get('key');
        if (!key) {
            let keys = ['dealer', 'clients', 'users', 'targets']
            if (disableDealerSettings) keys = keys.filter(a => a !== 'dealer')
            if (disableClientGroupSettings) keys = keys.filter(a => a !== 'clients')
            if (disableUserSettings) keys = keys.filter(a => a !== 'users')
            key = keys[0]
        }
        return key
    }


    const subregionsMemo = useMemo(() => clientSubRegions, [clientSubRegions])
    const regionsMemo = useMemo(() => clientRegions, [clientRegions])
    const groupsMemo = useMemo(() => clientGroups, [clientGroups])
    const teamsMemo = useMemo(() => clientTeams, [clientTeams])
    const clientsMemo = useMemo(() => oemClients, [oemClients])
    const rolesMemo = useMemo(() => oemRoles, [oemRoles])

    return (
        <>
            <Tab.Container defaultActiveKey={`${getQueryparam()}`} mountOnEnter={true}
                onSelect={(key) => {
                    setActive(key)
                }}>
                <div className="settings-submenu">
                    <h4>
                        {
                            localStorage?.defaultModule?.includes('oem') ? (
                                <>{`${props.dealersettings.level === 'oem' ? 'OEM' : props.dealersettings.level === 'region' ? 'Region' : props.dealersettings.level === 'group' ? 'Group' : 'Dealer'} Settings`}</>
                            ) : (<Translate text={'clientSettings'} />)
                        }
                    </h4>
                    <Nav as="ul" bsPrefix="ul-nav">
                        {
                            disableDealerSettings ? (<></>) : !localStorage?.defaultModule?.includes('oem') ? (<>
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="dealer" bsPrefix="li-a-nav"><Translate text={'Dealer Settings'} /></Nav.Link>
                                </Nav.Item>
                            </>) : (<>
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="dealer" bsPrefix="li-a-nav">{`${props.dealersettings.level === 'oem' ? 'OEM' : props.dealersettings.level === 'region' ? 'Region' : props.dealersettings.level === 'group' ? 'Group' : 'Dealer'} Settings`}</Nav.Link>
                                </Nav.Item>
                            </>)
                        }
                        {
                            disableClientGroupSettings ? (<></>) : (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="clients" bsPrefix="li-a-nav"><Translate text={'Clients'} /></Nav.Link>
                                </Nav.Item>
                            )
                        }
                        {
                            disableUserSettings ? (<></>) : (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="users" bsPrefix="li-a-nav"><Translate text={'users'} /></Nav.Link>
                                </Nav.Item>
                            )
                        }
                        {
                            tradeinPro ? (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="wholesaler" bsPrefix="li-a-nav"><Translate text={'wholeSaler'} /></Nav.Link>
                                </Nav.Item>
                            ) : (<></>)
                        }
                        {/* {
                            localStorage.defaultModule !== 'oem' && sales ? ( */}
                        <Nav.Item as="li" bsPrefix="li-nav">
                            <Nav.Link eventKey="targets" bsPrefix="li-a-nav"><Translate text={'Targets'} /></Nav.Link>
                        </Nav.Item>
                        {/* ) : (<></>)
                        } */}
                        {
                            !localStorage?.defaultModule?.includes('oem') && sales && props.dealersettings.client.moduleSettings && props.dealersettings.client.moduleSettings.frontdesk && props.dealersettings.client.moduleSettings.frontdesk.enabled ? (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="queue" bsPrefix="li-a-nav"><Translate text={'Queue Settings'} /></Nav.Link>
                                </Nav.Item>
                            ) : (<></>)
                        }
                        {
                            !localStorage?.defaultModule?.includes('oem') && cafe ? (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="cafe" bsPrefix="li-a-nav"><Translate text={'Cafe Settings'} /></Nav.Link>
                                </Nav.Item>
                            ) : (<></>)
                        }
                        {
                            !localStorage?.defaultModule?.includes('oem') && rosterEnabled ? (
                                <Nav.Item as="li" bsPrefix="li-nav">
                                    <Nav.Link eventKey="roster" bsPrefix="li-a-nav"><Translate text={'Roster Settings'} /></Nav.Link>
                                </Nav.Item>
                            ) : (<></>)
                        }
                        <Nav.Item as='li' bsPrefix='li-nav'>
                            <Nav.Link eventKey='gdpr' bsPrefix='li-a-nav'>
                                <Translate text={'GDPR Settings'} />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <ClientContext.Provider value={{ groups: groupsMemo, regions: regionsMemo, subregions: subregionsMemo, clients: clientsMemo, roles: rolesMemo, teams: teamsMemo }}>
                    <div className={`settings-container mb-0 h-100`}>
                        <div className="settings-container-sub" >
                            {
                                !localStorage?.defaultModule?.includes('oem') ? (<>
                                    <Tab.Pane eventKey="dealer">
                                        <DealerSettings activeKey={active} {...props} />
                                    </Tab.Pane>
                                </>) : (<>
                                    <Tab.Pane eventKey="dealer">
                                        <OEMSettings activeKey={active} {...props} />
                                    </Tab.Pane>
                                </>)
                            }
                            <Tab.Pane eventKey="clients">
                                <Clients activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="users" unmountOnExit={true} mountOnEnter={true}>
                                <Users activeKey={active} {...props} />
                            </Tab.Pane>
                            {
                                tradeinPro ? (<Tab.Pane eventKey="wholesaler" unmountOnExit={true} mountOnEnter={true}>
                                    <Wholesalers activeKey={active} {...props} />
                                </Tab.Pane>) : (<></>)
                            }
                            {
                                !localStorage?.defaultModule?.includes('oem') ? (
                                    <Tab.Pane eventKey="targets">
                                        <DealerTargets activeKey={active} {...props} />
                                    </Tab.Pane>
                                ) : (<Tab.Pane eventKey="targets">
                                    <OEMTarget activeKey={active} {...props} />
                                </Tab.Pane>)
                            }
                            {
                                !localStorage?.defaultModule?.includes('oem') && props.dealersettings.client.moduleSettings && props.dealersettings.client.moduleSettings.frontdesk && props.dealersettings.client.moduleSettings.frontdesk.enabled ? (<Tab.Pane eventKey="queue">
                                    <QueueTargets activeKey={active} {...props} />
                                </Tab.Pane>) : (<></>)
                            }
                            {
                                !localStorage?.defaultModule?.includes('oem') && cafe ? (
                                    <Tab.Pane eventKey="cafe">
                                        <CafeSettings activeKey={active} {...props} />
                                    </Tab.Pane>
                                ) : (<></>)
                            }
                            <Tab.Pane eventKey="roster">
                                <RosterSettings activeKey={active} {...props} />
                            </Tab.Pane>
                            <Tab.Pane eventKey='gdpr'>
                                <GDPRSettings {...props} />
                            </Tab.Pane>
                        </div>
                    </div>
                </ClientContext.Provider>
            </Tab.Container>
        </>
    )
}

export default CompanySettings;