import React from 'react';
import moment from "moment";
import Translate from '../../constants/translate';
import FilterType from './filterType'
const CustomToolbar = (toolbar) => {



    const goToDayView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'day')

        toolbar.onView("day");
        toolbar.handleChangeviewstate("day");
    };
    const goToWeekView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'week')

        toolbar.onView("week");
        toolbar.handleChangeviewstate("week");
    };
    const goToMonthView = () => {
        let mDate = toolbar.date;
        setDateRange(mDate, 'month')

        toolbar.onView("month");
        toolbar.handleChangeviewstate("month");
    };
    const goToAgendaView = () => {
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear(), mDate.getMonth(), 1);
        setDateRange(newDate, 'agenda')

        toolbar.onView("agenda");
        toolbar.onNavigate("next", newDate);
        toolbar.handleChangeviewstate("agenda");
    };
    const goToBack = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() - 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)

        toolbar.handlechangeyear(newDate.getFullYear())
        toolbar.onNavigate("prev", newDate);
    };
    const goToNext = () => {
        let mDate = toolbar.date;
        let newDate;
        if (toolbar.viewstate === "month" || toolbar.viewstate === "agenda") {
            newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
        } else if (toolbar.viewstate === "week") {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 7,
                1
            );
        } else {
            newDate = new Date(
                mDate.getFullYear(),
                mDate.getMonth(),
                mDate.getDate() + 1,
                1
            );
        }
        if (toolbar.monthData !== newDate.getMonth()) {
            toolbar.handlechangemonth(newDate.getMonth())
        }
        setDateRange(newDate, toolbar.viewstate)
        toolbar.handlechangeyear(newDate.getFullYear())
        toolbar.onNavigate("next", newDate);
    };

    const goToToday = () => {
        const now = moment()._d;
        toolbar.date.setDate(now.getDate());
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        if (toolbar.monthData !== now.getMonth()) {
            toolbar.handlechangemonth(now.getMonth())
        }
        setDateRange(now, toolbar.viewstate)
        toolbar.handlechangeyear(moment().year())
        toolbar.onNavigate("current");
    };

    const setDateRange = (date) => {
        toolbar.setDateRange({
            startDate: window.firebase.firestore.Timestamp.fromDate(moment(date).startOf('month').startOf('day').add(-7, 'day').toDate()),
            endDate: window.firebase.firestore.Timestamp.fromDate(moment(date).endOf('month').endOf('day').add(7, 'day').toDate()),
            date: moment(date).format('YYYY-MM')
        })
    }

    /*const goToBackYear = () => {
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear() - 1, 1);
        toolbar.onNavigate("prev", newDate);
    };

    const goToNextYear = () => {
        let mDate = toolbar.date;
        let newDate = new Date(mDate.getFullYear() + 1, 1);
        toolbar.onNavigate("next", newDate);
    };

    const month = () => {
        const date = moment(toolbar.date);
        let month = date.format("MMMM");

        return <span className="rbc-toolbar-label">{month}</span>;
    };


    const day = () => {
        const date = moment(toolbar.date);
        let day;
        if (toolbar.viewstate === "day") {
            day = date.format("ddd") + " " + date.format("Do");
        }
        return <span className="rbc-toolbar-label">{day}</span>;
    };*/

    return (
        <div className="filter-sub-panel">
            <div className="d-flex justify-content-between ">
                {/* <div className="">
                    <FilterType activetab={toolbar.activetab} handlechangefilter={toolbar.handlechangefilter} handleuncheckfilter={toolbar.handleuncheckfilter} dealersettings={toolbar.dealersettings} />
                </div> */}
                <div className="calendar-year">{toolbar.label}</div>
                <div className="">
                    <div className="calendar-switch-buttons">
                        <div className="btn-group">
                            <button className="btn btn-default" onClick={goToToday}><Translate text={'today'} /></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToBack}> <i className="fa fa-chevron-left"></i></button>
                            <button type="button" className="btn btn-default btn-sm" onClick={goToNext}> <i className="fa fa-chevron-right"></i></button>
                        </div>

                    </div>
                    <div className="tabber-nav">
                        <ul>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); goToDayView(); }} className={`${toolbar.viewstate === 'day' ? 'active' : ''}`}><Translate text={'day'} /></a></li>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); goToWeekView(); }} className={`${toolbar.viewstate === 'week' ? 'active' : ''}`}> <Translate text={'week'} /></a></li>
                            <li><a href="#" onClick={(e) => { e.preventDefault(); goToMonthView(); }} className={`${toolbar.viewstate === 'month' ? 'active' : ''}`}><Translate text={'month'} /></a></li>
                            {/* <li><a href="#" onClick={(e) => { e.preventDefault(); goToAgendaView(); }} className={`${toolbar.viewstate === 'agenda' ? 'active' : ''}`}><Translate text={'agenda'} /></a></li> */}
                        </ul>
                    </div></div>
            </div>
        </div>
    );
};

export default CustomToolbar;