import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import { tblFieldsLookup, lookupVM, levelOptions, fleetProLookups, lookupSublistVM } from './viewModel'
import { TemplateContext } from '../templateContext'
import { ReactSelect, PopUpModal, ContentOverlay, TableView, UpgradePlan, InputCheckBox } from '../../../../components'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import AddLookup from './addLookup'
import AddDynamicOptions from './addDynamicOptions'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { doc } from 'rxfire/firestore';
import AddLostReason from './addLostreason'
import { defaultfleetCategory, defaultfleetStatuses } from '../../../../services/enum'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Lookups = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 630) : (window.innerWidth - 725), windowHeight: (window.innerHeight - 315) })
    const [activeLookup, setActiveLookup] = useState('fleetProStatus')
    const [selectedOEM, setSelectedOEM] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [allLookups, setAlllookups] = useState({});
    const [loader, setLoader] = useState(true)
    const [showLookupModel, setShowLookupModel] = useState(false)
    const [showLostReasonModel, setShowLostReasonModel] = useState(false)
    const [showDynamicOptionModel, setShowDynamicOptionModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedLookup, setSelectedLookup] = useState(null)
    const [queryParam, setQueryParam] = useState({})
    const { clients, groups, regions } = useContext(TemplateContext);
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [typeFilter, setTypeFilter] = useState(null)
    const [categoryFilter, setCategoryFilter] = useState(null)

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);
    const activeLookUpName = CommonHelper.showLocale(props, activeLookup, (_.find(fleetProLookups, { 'value': activeLookup }) ? _.find(fleetProLookups, { 'value': activeLookup }).name : 'Lookup'))
    useEffect(() => {
        if (props.activeKey === 'lookups') {
            function handleResize() {
                setWindowSize({
                    windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 630) : (window.innerWidth - 725),
                    windowHeight: (window.innerHeight - 315)
                })
                //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 156) + 'px');
                document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 205) + 'px');
            }
            window.addEventListener('resize', handleResize);
            document.documentElement.style.setProperty('--rolenavbarHeight', (window.innerHeight - 205) + 'px');
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 156) + 'px');
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client })
            });
            setClientOptions(_clients)
        }
    }, [clients])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return


        setRegionOptions(_.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
            .map((rec) => {
                return {
                    value: rec.code,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    groupIDs: _.uniq(clients.filter(c => c.region === rec.code).map(v => _.pick(v, ['group']).group)),
                    clientIDs: clients.filter(c => c.region === rec.code).map(v => _.pick(v, ['documentID']).documentID)
                }
            }))
        setGroupOptions(_.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
            .map((rec) => {
                return {
                    value: rec.documentID,
                    label: rec.name,
                    data: rec,
                    active: _.isBoolean(rec.active) ? rec.active : true,
                    regionIDs: clients.filter(c => c.group === rec.documentID).map(v => _.pick(v, ['region']).region),
                    clientIDs: clients.filter(c => c.group === rec.documentID).map(v => _.pick(v, ['documentID']).documentID)
                }
            }))

    }, [clientOptions, groups, regions])

    useEffect(() => {
        //getDealersettings()
    }, [])

    useEffect(() => {
        if (_.isEmpty(clientOptions))
            return

        const getLevelName = (id, name, type) => {
            const { regions, groups, clients, settingName } = props.dealersettings
            let levelName = name

            if (type === 'OEM') {
                return settingName
            }

            if (type === 'Individual' && !_.isEmpty(clients) && clients.filter(client => client.id === id).length) {
                return clients.filter(client => client.id === id)[0].name
            }

            if (type === 'Region' && !_.isEmpty(regions) && regions.filter(region => region.id === id).length) {
                return regions.filter(region => region.id === id)[0].name
            }

            if (type === 'Group' && !_.isEmpty(groups) && groups.filter(group => group.id === id).length) {
                return groups.filter(group => group.id === id)[0].name
            }

            return levelName
        }

        const refs = [
            doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`)),
        ]
        let clientsLength = clientOptions.length
        let regionsLength = regions.length
        let groupsLength = groups.length
        clientOptions.forEach(clientOption => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/clients/${clientOption.value}/fleetPro/${clientOption.value}`)))
        })
        regions.forEach(region => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/regions/${region.documentID}/fleetPro/${region.documentID}`)))
        })
        groups.forEach(group => {
            refs.push(doc(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/groups/${group.documentID}/fleetPro/${group.documentID}`)))
        })

        var alllookupsettings = combineLatest(refs)
            .pipe(
                map((lookups) => {
                    // Destructure the values to combine a single array.
                    var [oemlookups] = lookups;
                    const regionLookups = []
                    const groupLookups = []
                    const clientLookups = []


                    lookups.forEach((lookup, index) => {
                        if (index > 0 && index <= clientsLength) {
                            // valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id,  },)
                            clientLookups.push(lookup)
                        }
                        if (index > 0 && index <= clientsLength + regionsLength && index > clientsLength) {
                            // valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id,  },)
                            regionLookups.push(lookup)
                        }
                        if (index > 0 && index <= clientsLength + regionsLength + groupsLength && index > clientsLength + regionsLength) {
                            // valToReturn.push({ ...lookup.data(), id: lookup.id, documentID: lookup.id,  },)
                            groupLookups.push(lookup)
                        }
                    })

                    let valToReturn = [
                        { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id },
                        _.map(_.filter(regionLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.region')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } }),
                        _.map(_.filter(groupLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.group')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } }),
                        _.map(_.filter(clientLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.documentID')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } })
                    ]

                    return valToReturn

                    // return [
                    //     //spread the arrays out to combine as one array
                    //     { ...oemlookups.data(), id: oemlookups.id, documentID: oemlookups.id },
                    //     _.map(_.filter(regionLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.region')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } }),
                    //     _.map(_.filter(groupLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.group')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } }),
                    //     _.map(_.filter(clientLookups, (v) => _.indexOf(_.uniq(_.map(clientOptions, 'data.documentID')), v.id) >= 0), function (obj) { return { ...obj.data(), id: obj.id, documentID: obj.id } })
                    // ];

                }))
            .subscribe(allDocs => {
                var lookups = [];
                allDocs.forEach(rec => { _.isArray(rec) ? rec.forEach(e => lookups.push(e)) : lookups.push(rec) })
                let settingsDoc = {};
                setSelectedOEM(lookups[0])
                lookups.forEach(rec => {
                    fleetProLookups.filter(e => e.value !== 'fleetOptionsDF').forEach((key) => {
                        if (rec[key.value]) {
                            rec[key.value].forEach((_lookups) => {
                                if (settingsDoc[key.value]) {
                                    settingsDoc[key.value] = [
                                        ...settingsDoc[key.value],
                                        {
                                            ..._lookups,
                                            'strName': (key.value === 'fleetProStatus') && _lookups.color ? (<div className={`badge badge-pill ${_lookups.value === 'New' ? 'badge-status-new' : 'badge-status-empty'}`} style={{ background: _lookups.color }}>{_lookups.name}</div>) : _lookups.name,
                                            'levelID': rec.id,
                                            'levelName': getLevelName(rec.id, rec.name, _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level),
                                            'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                            'documentID': _lookups.value,
                                            'disableEdit': _lookups.default === true ? false : (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                                (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false,
                                            'disableDelete': (_lookups.default === true) ? true : (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                                (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                        }
                                    ]
                                }
                                else {
                                    settingsDoc[key.value] = [{
                                        ..._lookups,
                                        'strName': (key.value === 'fleetProStatus') && _lookups.color ? (<div className={`badge badge-pill ${_lookups.value === 'New' ? 'badge-status-new' : 'badge-status-empty'}`} style={{ background: _lookups.color }}>{_lookups.name}</div>) : _lookups.name,
                                        'levelID': rec.id,
                                        'levelName': getLevelName(rec.id, rec.name, _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level),
                                        'levelValue': _.find(levelOptions, { 'value': _lookups.level }) ? _.find(levelOptions, { 'value': _lookups.level }).label : _lookups.level,
                                        'documentID': _lookups.value,
                                        'disableEdit': _lookups.default === true ? false : (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false,
                                        'disableDelete': (_lookups.default === true) ? true : (props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: _lookups.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: _lookups.level }).index) ? true : false
                                    }]
                                }
                            })
                        }
                        else if (_.isEmpty(settingsDoc[key.value])) {
                            settingsDoc[key.value] = [];
                        }
                    })
                })
                if (_.isEmpty(settingsDoc.fleetProStatus)) {
                    settingsDoc.fleetProStatus = defaultfleetStatuses.map((rec, index) => {
                        return {
                            ...rec,
                            'strName': (<div className={`badge badge-pill badge-fleet-${rec.value.toLowerCase()}`}>{rec.name.toUpperCase()}</div>),
                            'strValue': rec.name,
                            'levelID': props.dealersettings.client.settingsID,
                            'levelName': props.dealersettings.settingName,
                            'levelValue': _.find(levelOptions, { 'value': rec.level }) ? _.find(levelOptions, { 'value': rec.level }).label : rec.level,
                            'documentID': rec.value,
                            'disableEdit': false,
                            'disableDelete': true,
                            'static': true
                        }
                    })
                }
                if (_.isEmpty(settingsDoc.category)) {
                    settingsDoc.category = defaultfleetCategory.map((rec, index) => {
                        return {
                            ...rec,
                            'strName': rec.name,
                            'strValue': rec.name,
                            'levelID': props.dealersettings.client.settingsID,
                            'levelName': props.dealersettings.settingName,
                            'levelValue': _.find(levelOptions, { 'value': rec.level }) ? _.find(levelOptions, { 'value': rec.level }).label : rec.level,
                            'documentID': rec.value,
                            'disableEdit': false,
                            'disableDelete': true,
                            'static': true
                        }
                    })
                }
                setAlllookups(settingsDoc)
                setLoader(false)
            });
        return () => {
            alllookupsettings && alllookupsettings.unsubscribe()
        }
    }, [clientOptions])

    const handleActionClick = (id, data) => (
        <div className="action-fix">
            {
                data.disableEdit ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                    <i className="ico icon-lock"></i>
                </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditLookup(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>)
            }
            {
                data.disableDelete ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                    <i className="ico icon-lock"></i>
                </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteLookup(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>)
            }

        </div>
    )

    const hadleDeleteLookup = (data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let activeLookupName = (_.find(fleetProLookups, { 'value': activeLookup }) ? _.find(fleetProLookups, { 'value': activeLookup }).name : 'Lookups')
                const batch = window.firebase.firestore().batch();
                if (data.level === 'oem') {
                    const lookupObj = {
                        [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && (_.isArray(data.levelID) ? data.levelID.some(a => item.levelID === a) : item.levelID === data.levelID)), function (obj) {
                            return activeLookup === 'lostReasons' ? { ..._.pick(obj, _.keys(lookupSublistVM)) } :
                                { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`),
                        {
                            ...lookupObj,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now()
                        }, { merge: true });
                }
                else if (_.isArray(data.levelID)) {
                    data.levelID.forEach(rec => {
                        const lookupObj = {
                            [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && item.levelID === rec), function (obj) {
                                return activeLookup === 'lostReasons' ? { ..._.pick(obj, _.keys(lookupSublistVM)) } :
                                    { ..._.pick(obj, _.keys(lookupVM)) };
                            })]
                        }
                        batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${rec}/fleetPro/${rec}`),
                            {
                                ...lookupObj,
                                modifiedBy: localStorage.uid,
                                modifiedDate: window.firebase.firestore.Timestamp.now()
                            }, { merge: true });
                    })
                }
                else {
                    const lookupObj = {
                        [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.value !== data.value && item.levelID === data.levelID), function (obj) {
                            return activeLookup === 'lostReasons' ? { ..._.pick(obj, _.keys(lookupSublistVM)) } :
                                { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}/fleetPro/${data.levelID}`),
                        {
                            ...lookupObj,
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now()
                        }, { merge: true });
                }

                batch.commit()
                    .then(snapshot => {
                        setLoader(false)
                        //update settings date and priority
                        if (!_.isEmpty(props.selectedClient)) {
                            window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                                .set({
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: 'Fleet Dynamic Fields Settings has been updated',
                                }, { merge: true })
                        }
                        //end 
                        toast.notify(activeLookupName + ' deleted successfully', {
                            duration: 2000
                        })
                        let dynamicFieldName = activeLookupName
                        if (dynamicFieldName === 'Campaigns') {
                            dynamicFieldName = 'Campaign'
                        }
                        let logNotes = `${data.name} ${dynamicFieldName} Deleted`
                        // let clientIDs = !_.isEmpty(objTrigger.clientIDs) ? objTrigger.clientIDs : objTrigger.clientID ? [objTrigger.clientID] : ''
                        let _objLogData = {
                            notes: logNotes,
                            type: 'dynamicFields',
                            subType: 'deleted',
                            recordId: data.documentID,
                            oemID: props.dealersettings.client.settingsID ? props.dealersettings.client.settingsID : '',
                            regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                            groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                            logBy: localStorage.uid,
                            logDate: window.firebase.firestore.Timestamp.now(),
                            platform: 'web',
                            documentID: window.firebase.firestore().collection('auditLogs').doc().id,
                            clientID: props.dealersettings.client.id
                        }
                        window.firebase.firestore().doc(`auditLogs/${_objLogData.documentID}`).set(_objLogData, { merge: true }).catch(error => {
                            console.error(error);
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        Swal.fire('Something went wrong', '', 'error')
                    });
            }
        })
    }

    const hadleEditLookup = (data) => {
        let _header = `${CommonHelper.showLocale(props, 'edit')} ${activeLookUpName}`;
        setPopHeader(_header)
        setSelectedLookup(data)
        if (activeLookup === 'lostReasons') {
            setShowLostReasonModel(true)
        }
        else {
            setShowLookupModel(true)
        }
    }

    const handleClientdetailsClick = (e, id, data) => {
        if (data.disableEdit)
            return
        hadleEditLookup(data)
    }

    const handleAddLookup = () => {
        let _header = `${CommonHelper.showLocale(props, 'add')} ${activeLookUpName}`;
        setPopHeader(_header)
        setSelectedLookup(null)
        if (activeLookup === 'lostReasons') {
            setShowLostReasonModel(true)
        }
        else {
            setShowLookupModel(true)
        }
    }

    const handleModelClose = (data, isNew) => {
        setSelectedLookup(null)
        setShowLookupModel(false)
        setShowLostReasonModel(false)
        setShowDynamicOptionModel(false)
    }

    const handleSwitchChange = (id, checked, data) => {
        if (_.isEmpty(data))
            return
        let activeLookupName = (_.find(fleetProLookups, { 'value': activeLookup }) ? _.find(fleetProLookups, { 'value': activeLookup }).name : 'Lookups')
        const batch = window.firebase.firestore().batch();
        if (data.level === 'oem') {
            const lookupObj = {
                [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.level === data.level), function (obj) {
                    return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) } : { ..._.pick(obj, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`),
                {
                    ...lookupObj,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }, { merge: true });
        }
        else if (_.isArray(data.levelID)) {
            data.levelID.forEach(rec => {
                const lookupObj = {
                    [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && item.levelID === rec), function (obj) {
                        return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) } : { ..._.pick(obj, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) };
                    })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${rec}/fleetPro/${rec}`),
                    {
                        ...lookupObj,
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now()
                    }, { merge: true });
            })
        }
        else {
            const lookupObj = {
                [activeLookup]: [..._.map(allLookups[activeLookup].filter(item => item.level === data.level && item.levelID === data.levelID), function (obj) {
                    return obj.value === data.value ? { ..._.pick({ ...data, 'active': checked }, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) } : { ..._.pick(obj, _.keys(activeLookup === 'lostReasons' ? lookupSublistVM : lookupVM)) };
                })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}/fleetPro/${data.levelID}`),
                {
                    ...lookupObj,
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now()
                }, { merge: true });
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                //if (!_.isEmpty(props.selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Fleet Dynamic Field Settings has been updated',
                    }, { merge: true })
                //}
                //end 
                toast.notify(`${activeLookupName} updated successfully`, {
                    duration: 2000
                })
                let dynamicFieldName = activeLookupName
                if (dynamicFieldName === 'Campaigns') {
                    dynamicFieldName = 'Campaign'
                }
                let logNotes = checked ? `${data.name} ${dynamicFieldName} Activated` : `${data.name} ${dynamicFieldName} Deactivated`;
                // let clientIDs = !_.isEmpty(objTrigger.clientIDs) ? objTrigger.clientIDs : objTrigger.clientID ? [objTrigger.clientID] : ''
                let _objLogData = {
                    notes: logNotes,
                    type: 'dynamicFields',
                    subType: checked ? 'activated' : 'deactivated',
                    recordId: data.documentID,
                    oemID: props.dealersettings.client.settingsID ? props.dealersettings.client.settingsID : '',
                    regionID: props.dealersettings.client.region ? props.dealersettings.client.region : '',
                    groupID: props.dealersettings.client.group ? props.dealersettings.client.group : '',
                    logBy: localStorage.uid,
                    logDate: window.firebase.firestore.Timestamp.now(),
                    platform: 'web',
                    documentID: window.firebase.firestore().collection('auditLogs').doc().id,
                    clientID: props.dealersettings.client.id
                }
                window.firebase.firestore().doc(`auditLogs/${_objLogData.documentID}`).set(_objLogData, { merge: true }).catch(error => {
                    console.error(error);
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const lookupList = useMemo(() => allLookups[activeLookup] ? _.chain(allLookups[activeLookup])
        .groupBy("value")
        .map((value, key) => ({
            ...value[0],
            levelName: value.map(r => { return r.levelName }).join(','),
            levelID: value.map(r => { return r.levelID }),
            levelsDOM: (<div className="Vehicle-availability">
                {
                    value.map((r, i) => { return <div key={i}><span>{r.levelName}</span></div> })
                }
            </div>),
        }))
        .value()
        .filter(
            ({ name, value, isActive, levelID, levelName }) => (!_.isEmpty(queryParam) ?
                (
                    (!_.isEmpty(queryParam.levelID) ? queryParam.levelID.some(item => levelID.includes(item)) : true) &&
                    (!_.isEmpty(queryParam.searchText) ? (name.toLowerCase().includes(queryParam.searchText.toLowerCase()) ||
                        value.toLowerCase().includes(queryParam.searchText.toLowerCase()) ||
                        (!_.isEmpty(levelName) ? levelName.toLowerCase().includes(queryParam.searchText.toLowerCase()) : false)) : true)
                )
                : true)) : [], [activeLookup, allLookups, queryParam])


    const onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        let _activeLookups = _.sortBy(allLookups[activeLookup].map((rec, index) => {
            return {
                ...rec,
                ['sortNo']: rec.sortNo > 0 ? rec.sortNo : (index + 1)
            }
        }), ['sortNo'], ['asc']);
        const [removed] = _activeLookups.splice(result.source.index, 1);
        _activeLookups.splice(result.destination.index, 0, removed);
        const _sortLookups = _activeLookups.map((r, i) => { return { ...r, sortNo: (i + 1) } });
        setAlllookups({
            ...allLookups,
            [activeLookup]: _sortLookups
        })
        const batch = window.firebase.firestore().batch();

        _sortLookups.forEach(data => {
            if (data.level === 'oem') {
                const lookupObj = {
                    [activeLookup]: [..._.map(_sortLookups.filter(item => item.level === data.level), function (obj) {
                        return { ..._.pick(obj, _.keys(lookupVM)) };
                    })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/fleetPro/${props.dealersettings.client.settingsID}`),
                    lookupObj, { merge: true });
            }
            else if (_.isArray(data.levelID)) {
                data.levelID.forEach(rec => {
                    const lookupObj = {
                        [activeLookup]: [..._.map(_sortLookups.filter(item => item.level === data.level && item.levelID === rec), function (obj) {
                            return { ..._.pick(obj, _.keys(lookupVM)) };
                        })]
                    }
                    batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${rec}/fleetPro/${rec}`),
                        lookupObj, { merge: true });
                })
            }
            else {
                const lookupObj = {
                    [activeLookup]: [..._.map(_sortLookups.filter(item => item.level === data.level && item.levelID === data.levelID), function (obj) {
                        return { ..._.pick(obj, _.keys(lookupVM)) };
                    })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${data.level === 'group' ? 'groups' : data.level === 'region' ? 'regions' : 'clients'}/${data.levelID}/fleetPro/${data.levelID}`),
                    lookupObj, { merge: true });
            }
        });
        batch.commit()
            .then(snapshot => {
                setLoader(false)
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Fleet Dynamic Field Settings has been updated',
                    }, { merge: true })
                if (localStorage.defaultModule === 'oem' && props.dealersettings.oemID) {
                    window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}`)
                        .set({
                            settingsUpdate: window.firebase.firestore.Timestamp.now(),
                            settingsUpdatePriority: 'normal',
                            settingsUpdateBy: props?.dealersettings?.name || null,
                            settingsUpdateText: 'Fleet Dynamic Field Settings has been updated',
                        }, { merge: true })
                }
            })
            .catch((error) => {
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#e6eaf2"),
        ...draggableStyle,
    })


    const SortTableView = ({ datalist }) => {
        return (<div className="common-table">
            <DragDropContext onDragEnd={onDragEnd}>
                <table
                    className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" className="head-light" style={{ width: '20px' }}><Translate text={'SNo'} /></th>
                            <th scope="col" className="head-light" width="50%"><Translate text={'name'} /></th>
                            <th scope="col" className="head-light" width="20%">OEM/Region/Group/Client</th>
                            <th scope="col" className="head-light" width="20%"><Translate text={'Level'} /></th>
                            <th scope="col" className="head-light border-right-0" width="10%"><Translate text={'Active'} /></th>
                            <th scope="col" className="head-light border-left-0 border-right-0" style={{ width: '60px' }}>&nbsp;</th>
                            <th scope="col" className="head-light border-left-0" style={{ width: '20px' }}>&nbsp;</th>
                        </tr>
                    </thead>
                    <Droppable droppableId="table">
                        {(provided, snapshot) => (
                            <tbody  {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {
                                    datalist.map((rec, index) => {
                                        return <Draggable key={rec.value} draggableId={rec.value} index={index} isDragDisabled={!_.isEmpty(selectedLevel)}>
                                            {(provided, snapshot) => (<tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                <td id={`tr-${rec.value}`} style={{ width: '20px' }}>{index + 1}</td>
                                                <td width="50%">{rec.strName}</td>
                                                <td width="20%">{rec.levelsDOM}</td>
                                                <td width="20%">{rec.levelValue}</td>
                                                <td width="10%" className=" border-right-0"><div className="float-left">
                                                    <span className={`switch switch-sm mr-2 ${rec.disableDelete === true ? 'btn-disable' : ''}`}>
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            id={`${rec.value}_${index}`}
                                                            name={rec.value}
                                                            checked={Boolean(rec.active)}
                                                            onChange={(e) => { handleSwitchChange(rec.value, e.target.checked, rec) }} />
                                                        <label htmlFor={`${rec.value}_${index}`}></label>
                                                    </span>
                                                </div></td>
                                                <td style={{ width: '60px' }} className=" border-left-0 border-right-0">
                                                    <div className="action-fix">
                                                        {
                                                            (rec.disableEdit === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button mr-2">
                                                                <i className="ico icon-lock"></i>
                                                            </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                                                                e.preventDefault();
                                                                hadleEditLookup(rec)
                                                            }}>
                                                                <i className="ico icon-edit"></i>
                                                            </div>)
                                                        }
                                                        {
                                                            (rec.disableDelete === true || rec.level === 'default') ? (<div style={{ cursor: 'pointer' }} className="mini-button">
                                                                <i className="ico icon-lock"></i>
                                                            </div>) : (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                                                                e.preventDefault();
                                                                hadleDeleteLookup(rec)
                                                            }}>
                                                                <i className="ico icon-delete"></i>
                                                            </div>)
                                                        }

                                                    </div>
                                                </td>
                                                <td style={{ width: '20px' }} className=" border-left-0"><div className="field-drag" style={!_.isEmpty(selectedLevel) ? { opacity: `0.4` } : {}}> <i className="ico icon-drag"></i></div></td>

                                                {provided.placeholder}
                                            </tr>)}
                                        </Draggable>
                                    })
                                }
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </table>
            </DragDropContext>
        </div>)
    }

    return (
        <>
            {
                liteVersion ? (<UpgradePlan />) : (<></>)
            }
            <h2> <Translate text={'Dynamic Field Settings'} /></h2>
            <div className="divider-line"></div>
            <div className={`lookups-wrap ${liteVersion ? 'form-lite' : ''}`}>
                <div className="settings-column-wraper">
                    <div className="settings-columns">
                        <div className="settings-column-left">
                            <div className="settings-column-title">
                                <h3> <Translate text={'Dynamic Fields'} /></h3>
                            </div>
                            <div className="settings-column-nav roles-nav-left">
                                {
                                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : !_.isEmpty(selectedOEM) ? (
                                        <ul>
                                            {
                                                fleetProLookups.filter(e => e.value !== 'fleetOptionsDF').map((rec, index) => {
                                                    return <li key={index} className={`${rec.value === activeLookup ? 'active' : ''}`}>
                                                        <a href="#" className="column-list-left" onClick={(e) => {
                                                            e.preventDefault();
                                                            setActiveLookup(rec.value)
                                                        }}>
                                                            {CommonHelper.showLocale(props, rec.value, rec.name)}
                                                        </a>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                        <div className="settings-column-right-large">
                            <div className="settings-column-title">
                                {
                                    !_.isEmpty(selectedOEM) ?
                                        (
                                            <>
                                                <div className="column-add-button"><a href="#" className="mini-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleAddLookup(activeLookup);
                                                    }}> <i className="ico icon-add"></i></a></div>
                                                <h3>{activeLookUpName}</h3>
                                            </>)
                                        : (<h3>{activeLookUpName}</h3>)
                                }
                            </div>
                            <div className="lookups-details">
                                {
                                    !_.isEmpty(selectedOEM) ?
                                        (<div className="settings-head form-style">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="filter-search search-icon w-100">
                                                        <input className="w-100" placeholder="Search" aria-label="Search" value={!_.isEmpty(queryParam.searchText) ? queryParam.searchText : ''} onChange={(e) => { setQueryParam({ ...queryParam, 'searchText': e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-3 pl-0">
                                                            <ReactSelect
                                                                options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                                                    levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                                                name={"level"}
                                                                placeholder={'select level'}
                                                                onChange={(e, data) => {
                                                                    if (e) {
                                                                        setSelectedLevel({
                                                                            level: e.value
                                                                        });
                                                                        if (e.value === 'oem')
                                                                            setQueryParam({ ...queryParam, 'levelID': [props.dealersettings.client.settingsID] })
                                                                    }
                                                                    else {
                                                                        setSelectedLevel(null);
                                                                        setQueryParam({ ...queryParam, 'levelID': [] })
                                                                    }

                                                                }}
                                                                value={selectedLevel ? selectedLevel.level : ''}
                                                                classNamePrefix={`cursor-pointer basic-select`}
                                                            >
                                                            </ReactSelect>
                                                        </div>
                                                        {
                                                            (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ? (<div className="col-md-3 pl-0">
                                                                <ReactSelect
                                                                    options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                                                    name={'levelID'}
                                                                    placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                                                    onChange={(e, data) => {
                                                                        if (e) {
                                                                            setSelectedLevel({
                                                                                ...selectedLevel,
                                                                                levelID: e.value
                                                                            });
                                                                            if (selectedLevel.level === 'region')
                                                                                setQueryParam({ ...queryParam, 'levelID': [props.dealersettings.client.settingsID, e.value] })
                                                                            else if (selectedLevel.level === 'group')
                                                                                setQueryParam({ ...queryParam, 'levelID': [props.dealersettings.client.settingsID, ...e.regionIDs, e.value] })
                                                                            else
                                                                                setQueryParam({ ...queryParam, 'levelID': [props.dealersettings.client.settingsID, e.data.region, e.data.group, e.value] })

                                                                        }
                                                                        else {
                                                                            //setQueryParam({ ...queryParam, 'levelID': [] })
                                                                            setSelectedLevel({
                                                                                ...selectedLevel,
                                                                                levelID: null
                                                                            });
                                                                        }

                                                                    }}
                                                                    value={selectedLevel ? selectedLevel.levelID : ''}
                                                                    classNamePrefix={`basic-select`}
                                                                >
                                                                </ReactSelect>
                                                            </div>) : (<></>)
                                                        }


                                                        {
                                                            (activeLookup === 'recommendations') ?
                                                                (<div className="col-md-3 pl-0">
                                                                    <ReactSelect
                                                                        options={!_.isEmpty(allLookups.categories) ? allLookups.categories.filter(a => a.active).map((a, index) => {
                                                                            return {
                                                                                value: a.value ? a.value : '',
                                                                                label: a.name ? a.name : '',
                                                                                name: a.name ? a.name : '',
                                                                                index: index
                                                                            }
                                                                        }) : []}
                                                                        name={"category"}
                                                                        placeholder={'select category'}
                                                                        onChange={(e) => {
                                                                            setTypeFilter('checklist')
                                                                            setCategoryFilter(e && e.value ? e.value : null);
                                                                        }}
                                                                        value={categoryFilter}
                                                                        classNamePrefix={`basic-select`}
                                                                        removeClearable={false}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>)
                                                                :
                                                                (<></>)
                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </div>) : (<></>)
                                }
                                {
                                    !_.isEmpty(selectedOEM) ? (loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                        !_.isEmpty(allLookups[activeLookup]) && allLookups[activeLookup].length > 0 ? _.find(fleetProLookups, { 'value': activeLookup }) && _.find(fleetProLookups, { 'value': activeLookup }).sort === true ?
                                            <SortTableView
                                                datalist={_.sortBy(lookupList.map((rec, index) => {
                                                    return {
                                                        ...rec,
                                                        ['sortNo']: rec.sortNo > 0 ? rec.sortNo : (index + 1)
                                                    }
                                                }), ['sortNo'], ['asc'])}
                                            />
                                            :
                                            (
                                                <div className="common-table">
                                                    <TableView
                                                        datalist={lookupList}
                                                        height={windowSize.windowHeight}
                                                        width={windowSize.windowWidth}
                                                        columns={tblFieldsLookup}
                                                        dynamicFields={tblFieldsLookup.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                        handleActionClick={handleActionClick}
                                                        handleRowSingleClick={handleClientdetailsClick}
                                                        isSettings={false}
                                                        isReorderable={false}
                                                        handleSwitchChange={handleSwitchChange}
                                                        isSorting={true}
                                                        isTranslate={true}
                                                        dealersettings={props.dealersettings}
                                                    />
                                                </div>
                                            ) : (
                                            <div className="common-table">
                                                <div className="text-center p-5">
                                                    <p>{`${CommonHelper.showLocale(props, 'no')} ${activeLookUpName} ${CommonHelper.showLocale(props, 'found')}.`}</p>
                                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddLookup() }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                        {`${CommonHelper.showLocale(props, 'add')} ${activeLookUpName}`}
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    )
                                        : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PopUpModal show={showLookupModel}>
                <AddLookup
                    {...props}
                    show={showLookupModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
            <PopUpModal show={showDynamicOptionModel}>
                <AddDynamicOptions
                    {...props}
                    show={showDynamicOptionModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
            <PopUpModal show={showLostReasonModel}>
                <AddLostReason
                    {...props}
                    show={showLostReasonModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    lookup={selectedLookup}
                    activeLookup={activeLookup}
                    allLookups={allLookups}
                    selectedOEM={selectedOEM}
                    groupOptions={groupOptions}
                    clientOptions={clientOptions}
                    regionOptions={regionOptions}
                />
            </PopUpModal>
        </>
    )
}

export default Lookups