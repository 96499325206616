/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react'
import "../../../styles/service-invoice.scss";
import _ from 'lodash'
import moment from 'moment';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import { LoaderOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { serviceStatus } from '../../../services/enum';


const ServicePreview = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [servieData, setServieData] = useState({})
    const [services, setServices] = useState([])

    const { client } = servieData



    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`serviceJobs/${props.serviceid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('ServicePreview', props.serviceid);
        if (props.serviceid) {
            loadServieData();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadServieData = async () => {




        window.refCollectionData = docData(firestoreDB.firestore().doc(`serviceJobs/${props.serviceid}`))
            .pipe(
                switchMap(tradeIns => {
                    //console.log(tradeIns);
                    const serviceIds = [props.serviceid];
                    const enqIds = tradeIns.contactID ? [tradeIns.contactID] : [];
                    const clientIds = [tradeIns.clientID];
                    return combineLatest(
                        of(tradeIns),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(firestoreDB.firestore().collection('serviceJobs')
                                    .where('clientID', '==', clientID)
                                    .where('vehicle.vinNo', '==', tradeIns.vehicle.vinNo)
                                    .where('isDeleted', '==', false)).pipe(
                                        map(enquirySnapshot => enquirySnapshot)
                                    )
                            ) : of([])
                        ),
                        combineLatest(
                            clientIds.length > 0 ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        )
                    )
                }),
                map(([tradeIns, enquirySnapshot, clientSnapshot]) => {

                    return {
                        ...tradeIns,
                        client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                        services: enquirySnapshot[0]
                    }

                })
            )
            .subscribe(async (_tradeIns) => {


                if (!_.isEmpty(_tradeIns)) {
                    setServieData(_tradeIns);

                    let _services = Object.assign([], _tradeIns.services);
                    let __promise = []
                    let recommendations = []
                    let servicePackages = []

                    _services.forEach((job) => {
                        __promise.push(firestoreDB.firestore().collection(`serviceJobs/${job.documentID}/recommendations`)
                            .where('choice', '==', 'Required')
                            .where('detail.oKToDo', '==', true)
                            .where('active', '==', true).get());
                        __promise.push(firestoreDB.firestore().collection(`serviceJobs/${job.documentID}/servicePackages`).where('active', '==', true).get());
                    });

                    const __snapsnot = await Promise.all(__promise);
                    __snapsnot.forEach((snap) => {
                        snap.docs && snap.docs.forEach(_offer => {
                            if (_offer.exists) {
                                if (_offer.ref.parent.id === 'recommendations')
                                    recommendations.push(_offer.data());
                                else if (_offer.ref.parent.id === 'servicePackages')
                                    servicePackages.push(_offer.data());
                            }
                        })

                    })
                    _services.forEach((job) => {
                        job.recommendations = recommendations.filter(e => e.serviceID === job.documentID)
                        job.servicePackages = servicePackages.filter(e => e.serviceID === job.documentID)
                    });
                    setServices(_services)
                    setIsLoading(false);
                    //console.log('ServicePreview__services', _services);

                }
                window.refCollectionData && window.refCollectionData.unsubscribe()

            });

    }

    const bindServiceRecomm = (_service) => {
        let _txt = [];
        if (_service.status === serviceStatus.CANCELLED) {
            _txt.push(`APPOINTMENT CANCELLED`)
        }
        else {
            if (_service.vehicle.odometer)
                _txt.push(`@${_service.vehicle.odometer}`)

            if (_service.servicePackages)
                _txt = [..._txt, ...Array.prototype.map.call(_.orderBy(_service.servicePackages, ['category'], ['asc']), function (item) { return item.name; })]

            if (_service.recommendations)
                _txt = [..._txt, ...Array.prototype.map.call(_.orderBy(_service.recommendations, ['category'], ['asc']), function (item) { return item.name; })]
        }

        return _txt.join(', ')

    }

    const bindServiceRepairs = (_service) => {
        let _txt = [];


        if (_service.parts) {
            _service.parts.forEach(_parts => {
                if (_parts?.partName) _txt.push(_parts.partName)
            })
        }

        if (_service.recommendations) {
            _service.recommendations.forEach(_recommData => {
                let recommendationParts = Object.assign([], _recommData?.detail?.parts);
                if (!_.isEmpty(recommendationParts)) {
                    for (var i = 0; i < recommendationParts.length; i++) {
                        if (recommendationParts[i]?.partName) _txt.push(recommendationParts[i].partName)
                    }
                }
            })
        }

        return _txt.join(', ')

    }

    const ContentDOM = ({ classname, id, previewType }) => {
        return (
            <div className={`dynamic-report-print-wrap report-data-loaded report-data-load ${classname}`} id={id}>
                <div className="print-wrap">
                    <div className={`${previewType === 'print' ? "page-header-print" : "page-header"}`}>
                        <div className="header">
                            <div className="invoice-sub-headerwrapper">

                                <div className="invoice-sub-header">
                                    <div className="invoice-sub-header-logo">
                                        {
                                            client?.servicelogoURL ? (
                                                <img src={client?.servicelogoURL} height="60" />
                                            ) : client?.logoURL ? (
                                                <img src={client?.logoURL} height="60" />
                                            ) : (<>{client?.name}</>)
                                        }
                                    </div>
                                    <div className="invoice-sub-header-company-address">
                                        {
                                            client?.address ? client?.address : ''
                                        }
                                        {
                                            client?.phone ? ` | PH: ${client?.phone}` : ''
                                        }
                                        {
                                            client?.email ? ` | Email: ${client?.email}` : ''
                                        }
                                    </div>

                                    <div className="invoice-sub-header-abn">
                                        {
                                            client?.abn ? `A.B.N: ${client?.abn}` : ''
                                        }
                                        {
                                            client?.acn ? ` ${client?.abn ? '|' : ''} A.C.N: ${client?.acn}` : ''
                                        }
                                        {
                                            client?.invoiceSettings?.licenseNo ? ` ${client?.abn || client?.acn ? '|' : ''}  ${client?.invoiceSettings?.licenseNo}` : ''
                                        }
                                    </div>

                                </div>


                                <div className="invoice-sub-title">
                                    <div className="invoice-sub-header-titleleft">Vehicle Service/Repair History</div>
                                    <div className="invoice-sub-header-titleright">VIN: <span className="inv-num">{servieData?.vehicle?.vinNo}</span></div>


                                </div>
                            </div>



                        </div>
                    </div>



                    <table style={previewType === 'print' ? { width: '100%', marginTop: '-15px' } : { width: '100%' }}>
                        <thead>
                            <tr>
                                <td>
                                    <div className={`${previewType === 'print' ? "page-header-space-history" : ""}`}></div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="invoice-sub-table-list invoice-border-none" style={previewType === 'print' ? { minHeight: '400px' } : {}}>

                                        <table className="form-bottom-sub-fix table-td-fix table-page-break-fix">
                                            <thead>
                                                <tr>
                                                    <th><strong>ID</strong></th>
                                                    <th><strong>REGO</strong></th>
                                                    <th><strong>MAKE</strong></th>
                                                    <th><strong>DATE IN</strong></th>
                                                    <th><strong>SERVICE</strong></th>
                                                    <th><strong>REPAIRS</strong></th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {
                                                    _.orderBy(services, ['addedDate'], ['asc']).map((_service, index) => {
                                                        return <Fragment key={index}>
                                                            <tr style={index % 2 != 0 ? { backgroundColor: '#f8f8f8' } : {}}>
                                                                <td width="10%">{_service.displayID}</td>
                                                                <td width="15%">{_service.vehicle.rego}</td>
                                                                <td width="15%">{_service.vehicle.make}</td>
                                                                <td width="10%">{_service.checkInDate ? moment.unix(_service.checkInDate.seconds).format('DD/MM/YYYY') : (_service.addedDate ? moment.unix(_service.addedDate.seconds).format('DD/MM/YYYY') : '--')}</td>
                                                                <td width="10%">{_service.serviceType === 'internal' ? 'INTER SERVICE' : ''}</td>
                                                                <td width="40%">{bindServiceRepairs(_service)}</td>
                                                            </tr>


                                                            <tr style={index % 2 != 0 ? { backgroundColor: '#f8f8f8' } : {}}>
                                                                <td colspan="6" className="td-sub">
                                                                    {bindServiceRecomm(_service)}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    })
                                                }

                                            </tbody>
                                        </table>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <div className={`${previewType === 'print' ? "page-footer-space" : ""}`}></div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <div className={`${previewType === 'print' ? services?.length > 25 ? "page-footer-print" : "page-footer-print-fixed" : "page-footer"}`}>
                        <div className="footer">
                            <div className="footer-bottom">

                                <div className="ft-left">

                                    <span style={{ paddingLeft: 0 }} >
                                        {client.address ? <>{client.address}</> : <></>}
                                    </span>
                                    <span style={{ paddingLeft: 0 }}>
                                        {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                        {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                        {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                        {client?.invoiceSettings?.licenseNo ? <>&nbsp; | &nbsp;<span style={{ paddingLeft: 0 }} >{client?.invoiceSettings?.licenseNo}</span></> : <></>}
                                        {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                    </span>

                                </div>

                                {/* <div className="ft-right"></div>

                                <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div> */}
                                <div className="clear"></div>
                            </div>
                            <div className="footer-cpy">
                                <div className="footer-left">Powered by <a target="_blank" href="http://fusionsd.com.au/">Fusion SD</a></div>
                                <div className='footer-right'>{!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="clear"></div>
            </div>
        )
    }


    return (
        <>

            {
                isLoading
                    ?
                    <>
                        <LoaderOverlay active={true} text='Fetching details...' />
                    </>
                    :
                    <>

                        <div className="button-wrap">
                            <a href="#" className="print-btn" onClick={() => window.print()}> <img src={_images.printWhite} width="19" height="18" /> <span><Translate text={'Print'} /></span></a>
                        </div>

                        <ContentDOM
                            classname={'no-print-content'}
                            id={'print-section'}
                            previewType={'view'}
                        />

                        <ContentDOM
                            classname={'print-content'}
                            id={'print-section'}
                            previewType={"print"}
                        />
                    </>
            }
        </>



    )
};

export default ServicePreview;
