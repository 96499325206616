import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import moment from 'moment'
import { PopUpModal, ContentOverlay, TableView, UpgradePlan } from '../../../../components'
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import AddForm from './addForm';
import { formListFields, formVM } from './viewModel'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators'
import { collectionData } from 'rxfire/firestore';
import { TemplateContext } from '../templateContext'
import PreviewForm from '../../workflow/feedbackForms/preview';

const FormTemplates = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [formList, setFormList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [addForm, setAddForm] = useState({
        show: false,
        title: '',
        template: null
    })
    const [previewForm, setPreviewForm] = useState({
        show: false,
        title: '',
        formFields: null
    })
    const { formTemplates, loading } = useContext(TemplateContext);
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })

        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(formTemplates)) {
            return;
        }

        let listVM = [];
        formTemplates.forEach(rec => {
            if (!listVM.some(e => e.documentID === rec.documentID))
                listVM.push({
                    ...rec,
                    createdBy: CommonHelper.getUserNamebyId(props.clientUsers, rec.addedBy),
                    updatedBy: CommonHelper.getUserNamebyId(props.clientUsers, rec.modifiedBy),
                    createdOn: rec.addedDate ? moment.unix(rec.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '',
                    updatedOn: rec.modifiedDate ? moment.unix(rec.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '',
                    visibilityDOM: rec.visibility === 'private' ?
                        (<div className="badge badge-pill badge-private" onClick={(e) => { console.log(e) }} >Private </div>)
                        : (<div className="badge badge-pill badge-shared" onClick={(e) => { console.log(e) }}>Shared  </div>)

                })
        })
        setFormList(listVM)
    }, [formTemplates])

    const hadleCloneForm = (template) => {
        let form = {
            ...template,
            'documentID': window.firebase.firestore().collection(`feedbackForm`).doc().id,
            'clientID': props.dealersettings.client.id,
            'addedBy': localStorage.uid,
            'modifiedBy': localStorage.uid,
            'addedDate': window.firebase.firestore.Timestamp.now(),
            'modifiedDate': window.firebase.firestore.Timestamp.now(),
            'visibility': 'private',
            'title': template.title + ' - Copy'
        };
        setAddForm({ show: true, title: form.title, template: form })
    }

    const handleActionClick = (id, data) => {
        return (data.clientID === props.dealersettings.client.id || data.addedBy === localStorage.uid) ? (
            <div className="">
                <button
                    type='button'
                    className='btn btn-green float-left mr-2'
                    style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
                    onClick={() =>
                        setPreviewForm({
                            formFields: data.fields,
                            show: true,
                            title: data.title
                        })
                    }
                >
                    {' '}
                    <i className="fa fa-eye custom-icon-preview" aria-hidden="true"></i>  Preview{' '}
                </button>
                <div title="Clone template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleCloneForm(data)
                }}>
                    <i className="ico icon-rearrange"></i>
                </div>
                <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {

                    hadleEditForm(data)

                }}>
                    <i className={`ico icon-edit`}></i>
                </div>
                <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {

                    hadleDeleteForm(data)

                }}>
                    <i className={`ico icon-delete`}></i>
                </div>
            </div>
        ) : (
            <div className="">
                <button
                    type='button'
                    className='btn btn-green float-left mr-2'
                    style={{ padding: `3px 8px 4px`, fontSize: `11px` }}
                    onClick={() =>
                        setPreviewForm({
                            formFields: data.fields,
                            show: true,
                            title: data.title
                        })
                    }
                >
                    {' '}
                    <i className="fa fa-eye custom-icon-preview" aria-hidden="true"></i>  Preview{' '}
                </button>
                <div title="Clone template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleCloneForm(data)
                }}>
                    <i className="ico icon-rearrange"></i>
                </div>
                <div title="Edit template" style={{ cursor: 'pointer' }} className="mini-button float-left mr-2 btn-disable" onClick={(e) => {

                    e.preventDefault()
                }}>
                    <i className={`ico icon-lock`}></i>
                </div>
                <div title="Delete template" style={{ cursor: 'pointer' }} className="mini-button  btn-disable" onClick={(e) => {

                    e.preventDefault()
                }}>
                    <i className={`ico icon-lock`}></i>
                </div>
            </div>
        )
    }


    const hadleEditForm = (template) => {
        setAddForm({ show: true, title: template.title, template: template })
    }
    const handledetailsClick = (e, id, data) => {
        e.preventDefault();
        if (data.clientID === props.dealersettings.client.id || data.addedBy === localStorage.uid)
            hadleEditForm(data)
    }

    const hadleDeleteForm = (template) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`formTemplates/${template.documentID}`)
                    .set({
                        'isDeleted': true,
                        'modifiedBy': localStorage.uid,
                        'modifiedDate': window.firebase.firestore.Timestamp.now()
                    }, { merge: true })
                    .then(() => {
                        toast.notify(`Form deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        Swal.fire('Something went wrong.', '', 'error');
                    })
            }
        })
    }


    return (<>
        {
            liteVersion ? (<UpgradePlan />) : (<></>)
        }
        <div className={`${liteVersion ? 'form-lite' : ''}`}>
            <div className="settings-head">

                <div className="float-left">
                    <div className="filter-search search-icon">
                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                    </div>
                </div>

                <div className="float-right">
                    <button type="button" className="btn btn-primary float-right mr-2" onClick={() => {
                        setAddForm({ show: true, title: 'Add Form', template: null })
                    }} ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Form'} /></button>
                </div>

            </div>
            {
                loading ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                    formList.length > 0 ? (
                        <div className="common-table">
                            <TableView
                                datalist={_.orderBy(formList.filter(({ title, bodyHTML, createdBy, updatedBy }) =>
                                (!_.isEmpty(searchText) ? (
                                    (!_.isEmpty(title) ? title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(createdBy) ? createdBy.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(updatedBy) ? updatedBy.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                    (!_.isEmpty(bodyHTML) ? bodyHTML.toLowerCase().includes(searchText.toLowerCase()) : false)
                                ) : true)
                                ), ['addedDate.seconds'], ['desc'])}
                                height={windowSize.windowHeight}
                                width={windowSize.windowWidth}
                                columns={formListFields}
                                dynamicFields={formListFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                handleActionClick={handleActionClick}
                                handleRowSingleClick={handledetailsClick}
                                isSettings={false}
                                isReorderable={false}
                                isSorting={true}
                                isTranslate={true}
                                dealersettings={props.dealersettings}
                            />
                        </div>
                    ) : (
                        <div className="common-table" style={{ height: windowSize.windowHeight + 'px' }}>
                            <div className="text-center p-5">
                                <p> <Translate text={'No templates found'} /></p>
                                <button className="btn btn-sm btn-default" onClick={() => {
                                    setAddForm({ show: true, title: 'Add Form', template: null })
                                }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                    <Translate text={'Add Form'} />
                                </button>
                            </div>
                        </div>
                    )
            }
        </div>
        <PopUpModal show={addForm.show}>
            <AddForm
                {...props}
                show={addForm.show}
                title={addForm.title}
                handleClose={() => { setAddForm({ show: false, title: '', template: null }) }}
                feedbackForm={addForm.template}
                type={props.type ? props.type : 'email'}
                dealersettings={props.dealersettings}
            />
        </PopUpModal>
        <PopUpModal show={previewForm.show}>
            <PreviewForm
                {...previewForm}
                dealersettings={props.dealersettings}
                handleClose={() => { setPreviewForm({ show: false, title: '', formFields: null }) }}
            />
        </PopUpModal>
    </>);
}

export default FormTemplates;