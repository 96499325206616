export const activityListVM = {
    documentID: "",
    addedBy: "",
    addedDate: null,
    clientID: "",
    contactID: "",
    enquiryID: "",
    stockID: "",
    startDate: null,
    endDate: null,
    isDone: false,
    isDeleted: false,
    modifiedBy: "",
    modifiedFrom: 'web',
    modifiedDate: null,
    notes: "",
    subType: "",
    type: "",
    title: '',
    teammates: [],
    callDuration: 0,
    linkActivityID: '',
    callStatus: '',
    owner: '',
    addedByName: '',
    dueDate: '',
    dueTime: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    licenseNo: '',
    licenseExpiry: null,
    contactType: '',
    dob: null,
    gender: '',
    maritalStatus: '',
    language: '',
    nationality: '',
    address: '',
    companyName: '',
    designation: '',
    contactMethod: '',
    licenseType: '',
    licenseState: '',
    secondaryContactName: '',
    secondaryContactPhone: '',
    secondaryContactEmail: '',
    contactCreatedBy: '',
    contactModifiedby: '',
    contactCreatedOn: null,
    contactModifiedOn: null,
    completedBy: '',
    completedDate: null,
    contact: {},
    enquiry: {},
    tradeinPro: {},
    stock: {},
    inbound: null,
    activityOutcome: '',
    tradeinProID: null
}

export const allactivityFields = [
    {
        name: 'Checkbox',
        value: 'checkbox',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'checkbox',
        flex: 0,
        fixed: true,

    },
    {
        name: 'Done',
        value: 'documentID',
        subText: 'isDone',
        width: 60,
        default: true,
        type: 'checkbox',
        flex: 0,
        align: 'center'
    },
    {
        name: 'Title',
        value: 'title',
        flex: 1,
        default: true,
        icon: true,
        sortValue: 'activity."title"'
    },
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 2,
        default: true,
        subText: 'contactNumber',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'cont."firstName"'
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        flex: 1,
        default: true,
        sortValue: 'cont."email"'
    },
    {
        name: 'Contact Phone',
        value: 'contactPhone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        sortValue: 'cont."phone"'
    },
    {
        name: 'License No',
        value: 'licenseNo',
        flex: 1,
        default: false,
        sortValue: 'cont."licenseNo"'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        default: false,
        sortValue: 'cont."licenseExpiry"'
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1,
        default: false
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1,
        default: false
    },
    {
        name: 'Note',
        value: 'notes',
        flex: 2,
        default: true,
        sortValue: 'activity."notes"',
        displayType: 'notes',
        limit: 190
    },
    {
        name: 'Due Date',
        value: 'dueDate',
        subText: 'dueTime',
        flex: 1,
        default: true,
        subTextTitle: 'Due Time',
        sortValue: 'activity."startDate"'
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'createdBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'updatedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Completed On',
        value: 'activityCompletedDate',
        subText: 'activityCompletedBy',
        flex: 1,
        default: false,
        subTextTitle: 'Completed By'
    },
    {
        name: 'Activity Owner',
        value: 'activityOwner',
        flex: 1,
        default: true,
        sortValue: 'owner.name'
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1,
        default: false,
        sortValue: 'cont."contactType"'
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1,
        default: false,
        sortValue: 'cont."dob"'
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1,
        default: false
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1,
        default: false
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1,
        default: false
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1,
        default: false
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        default: false
    },
    {
        name: 'Company Name',
        value: 'companyName',
        flex: 1,
        default: false
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Name',
        value: 'secondaryContactName',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Phone',
        value: 'secondaryContactPhone',
        flex: 1,
        default: false
    },
    {
        name: 'Secondary Contact Email',
        value: 'secondaryContactEmail',
        flex: 1,
        default: false
    },
    {
        name: 'Contact Created On',
        value: 'contactCreatedOn',
        subText: 'contactCreatedBy',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Created By'
    },
    {
        name: 'Contact Updated On',
        value: 'contactModifiedOn',
        subText: 'contactModifiedby',
        flex: 1,
        default: false,
        subTextTitle: 'Contact Created On'
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1,
        sortValue: 'cont.optinPhone'
    },
    {
        name: 'Activity Outcome',
        value: 'activityOutcomeName',
        flex: 1,
        sortValue: 'activityOutcome.name'
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1,
        sortValue: 'cont.optinEmail'
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1,
        sortValue: 'cont.optinPost'
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1,
        sortValue: 'cont.optinSMS'
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
        sortValue: 'cont.marketingOptinEmail'
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
        sortValue: 'cont.marketingOptinSMS'
    },
    {
        name: 'Pipeline',
        value: 'enqPipeline',
        flex: 1,
        sortValue: 'pipeline.name'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]
