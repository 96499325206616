import React from 'react';
import StarRatings from 'react-star-ratings'
import { default as _images } from '../../../../images';
import { InputCheckBox, InputRadio, InputTextArea, ReactSelect, InputText, ReactMultiSelect } from '../../../../components';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

import '../../../../styles/customer-feedbackform.scss';
import { removeHTMLTags } from '../../../../services/helper';

const PreviewForm = ({ dealersettings, formFields, show, handleClose, title, customerReview, data }) => {

    const styles = {
        formAndFontColor: {
			background:
				!_.isEmpty(data) && data.formColor
					? data.formColor
					: '#FFFFFF',
			color:
				!_.isEmpty(data) && data.fontColor
					? data.fontColor
					: '#000000',	
		},
		fontColor: {
			color:
				!_.isEmpty(data) && data.fontColor
					? data.fontColor
					: '#545E6B',	
		},
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                backdropClassName={show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-email-preview"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body 
                    className="customer-feedback-page-landing pr-0 pl-0 pt-0" 
                    style={{
                        background:
                            !_.isEmpty(data) && !_.isEmpty(data.backgroundColor)
                                ? data.backgroundColor
                                : '#4466f2'
                    }}
                >
                    <div className="container-fluid pr-0 pl-0">
                        <main role='main'>
                            <div className='feedbackform-header position-relative'>
                                <div className='container'>
                                    <div className='feedbackform-logo-wrap'>
                                        <span className='feedbackform-logo-img mr-3'>
                                            {
                                                dealersettings && dealersettings.client && dealersettings.client.logoURL ? (
                                                    <img
                                                        style={{ maxHeight: '50px' }}
                                                        src={dealersettings.client.logoURL}
                                                        alt='logo'
                                                    />
                                                ) : (
                                                    <>{dealersettings.client.name}</>
                                                )
                                            }

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='container'>
                                {
                                    formFields && formFields.some(a => a.type === 'header') ?
                                        (
                                            <div className='feedbackform-title mt-0'>
                                                <h1
                                                 style={{
                                                    color: !_.isEmpty(data) && data.headerColor
                                                        ? data.headerColor
                                                        : '#ffffff'
                                                }}
                                                >
                                                    {removeHTMLTags(formFields.find(a => a.type === 'header').label)}
                                                </h1>
                                            </div>
                                        ) : (<></>)
                                }
                                <div className='feedbackform-wrap-bg'>
                                    <div className='feedbackform-wrap' style={styles.formAndFontColor}>
                                        {
                                            formFields && formFields.filter(a => a.type !== 'header').length > 0 ? (
                                                formFields.filter(a => a.type !== 'header').map((field, index) => {
                                                    return <div key={index} className='feedbackform-question-loop' id={field.name}>
                                                        <div className={`feedbackform-question-container`}>
                                                            {removeHTMLTags(field.label)}
                                                            {
                                                                field.required === true ? (
                                                                    <span className="feedback-required-red">* </span>
                                                                ) : (<></>)
                                                            }
                                                            {
                                                                !_.isEmpty(field.description) ? (
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        overlay={
                                                                            <Tooltip>{field.description}</Tooltip>
                                                                        }
                                                                    >
                                                                        <i className="ico icon-info icon-grey"></i>
                                                                    </OverlayTrigger>
                                                                ) : (<></>)
                                                            }
                                                        </div>
                                                        {
                                                            field.type === 'radio-group' ? (
                                                                <div className='feedbackform-answer-container'>
                                                                    <div className='feedbackform-input-wrap'>
                                                                        {
                                                                            field.values.filter(a => !_.isEmpty(a.value)).map((option, index) => {
                                                                                return <div key={index} className='radio icheck-success form-disable'>
                                                                                    <InputRadio
                                                                                        id={`${field.name}-${index}`}
                                                                                        type="radio"
                                                                                        value={option.value}
                                                                                        name={field.name}
                                                                                        checked={customerReview && customerReview[field.name] === option.value ? true : false}
                                                                                    />
                                                                                    <label style={styles.fontColor} htmlFor={`${field.name}-${index}`}> {option.label}</label>
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ) : field.type === 'checkbox-group' ? (
                                                                <div className='feedbackform-answer-container'>
                                                                    <div className='feedbackform-input-wrap'>
                                                                        <ul className={`${field.toggle === true ? 'feedback-switch-ul' : ''}`}>
                                                                            {
                                                                                field.values.filter(a => !_.isEmpty(a.value)).map((option, index) => {
                                                                                    return <li key={index}><div className={`${field.toggle === true ? 'switch switch-sm' : 'checkbox icheck-success'} form-disable`}>
                                                                                        <InputCheckBox
                                                                                            className="switch"
                                                                                            id={`${option.value}`}
                                                                                            name={field.name}
                                                                                            checked={customerReview && customerReview[field.name] && customerReview[field.name].includes(option.value)}
                                                                                            onChange={(e) => { }}
                                                                                        />
                                                                                        <label style={styles.fontColor} htmlFor={`${option.value}`}>  {option.label}</label>
                                                                                    </div></li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            ) : field.type === 'select' ? (
                                                                <div className='form-row'>
                                                                    <div className='form-group col-md-12'>
                                                                        {
                                                                            field.multiple === true ? (
                                                                                <ReactMultiSelect
                                                                                    options={field.values.filter(a => !_.isEmpty(a.value))}
                                                                                    name={field.name}
                                                                                    placeholder={'select'}
                                                                                    classNamePrefix={`basic-select`}
                                                                                    isMulti={true}
                                                                                    isSearchable={true}
                                                                                    value={customerReview ? customerReview[field.name] : ''}
                                                                                    isDisabled={true}
                                                                                >
                                                                                </ReactMultiSelect>
                                                                            ) : (
                                                                                <ReactSelect
                                                                                    options={field.values.filter(a => !_.isEmpty(a.value))}
                                                                                    name={field.name}
                                                                                    placeholder={'select'}
                                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                                    value={customerReview ? customerReview[field.name] : ''}
                                                                                    isDisabled={true}
                                                                                >
                                                                                </ReactSelect>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                            ) : field.type === 'text' ? (
                                                                <div className='form-row'>
                                                                    <div className='form-group col-md-12'>
                                                                        <InputText
                                                                            autoComplete="off"
                                                                            className={`form-control  feedbackform-no-bg-textarea`}
                                                                            name={field.name}
                                                                            value={customerReview ? customerReview[field.name] : ''}
                                                                            disabled={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : field.type === 'starRating' ? (
                                                                <div className='form-row'>
                                                                    <div className='form-group col-md-12 form-disable'>
                                                                        <StarRatings
                                                                            isAggregateRating={true}
                                                                            numberOfStars={5}
                                                                            starRatedColor={'#ffc107'}
                                                                            starHoverColor={'#ffc107'}
                                                                            starDimension="20px"
                                                                            starSpacing="2px"
                                                                            rating={customerReview ? customerReview[field.name] : 0}
                                                                            name={field.name}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : field.type === 'textarea' ? (
                                                                <div className='form-row'>
                                                                    <div className='form-group col-md-12'>
                                                                        <InputTextArea
                                                                            className={`form-control notes form-disable feedbackform-no-bg-textarea`}
                                                                            name={field.name}
                                                                            maxLength="280"
                                                                            rows="3"
                                                                            value={customerReview ? customerReview[field.name] : ''}
                                                                        >
                                                                        </InputTextArea>
                                                                    </div>
                                                                </div>
                                                            ) : (<></>)
                                                        }

                                                    </div>
                                                })
                                            ) : (<></>)
                                        }
                                        <div className="feedbackform-question-loop feedbackform-submitwrap">
                                            <div className="clear"></div><footer>
                                                <div className="footer-cpy" style={{background: 'inherit'}}>
                                                    <div className="footer-bottom">

                                                        <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </footer>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </main >
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PreviewForm;
