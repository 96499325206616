import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { PopUpModal, TableView } from '../../../components'
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import AddStock from '../../stock/add'
import StockHistory from '../history'

const StockList = (props) => {
    const [stocks, setStockList] = useState(props.dataList)
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [searchText, setSearchText] = useState('')
    const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [modelShow, setModelShow] = useState(false)
    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ? props.dealersettings.rolePermissions.permissions : null);
    const _servicePermissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.servicePermissions)) ? props.dealersettings.rolePermissions.servicePermissions : null);
    const deleteServiceStock = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteServiceStock) ? true : false);
    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);
    const deleteStock = ((!_.isEmpty(_permissions) && _permissions.deleteStock) ? true : false);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleActionClick = (id, data) => {
        return (
            <div >
                <div
                    style={{ cursor: 'pointer' }}
                    className="mini-button"
                    onClick={(e) => {
                        setEditDocument(id);
                        setModelHistoryShow(true);
                    }}>
                    <i className="ico icon-history"></i>
                </div>

                {
                    readOnlyEnquiryView
                        ?
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-zoom"></i>
                            </div>
                        </>
                        :
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={(e) => {
                                    setEditDocument(id);
                                    setModelShow(true);
                                }}>
                                <i className="ico icon-edit"></i>
                            </div>
                        </>
                }

                {
                    (deleteStock || deleteServiceStock)
                        ?
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className="mini-button ml-2"
                                onClick={async (e) => {
                                    handleDeleteStock(id, data);
                                }}
                            >
                                <i className="ico icon-delete"></i>
                            </div>

                        </>
                        :
                        <></>
                }

            </div>
        )
    }

    const handleDeleteStock = async (id, data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the stock.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {

                let response = await props.handleCheckStock(id);
                //console.log("response", id, response)
                if (response.success) {
                    let _dataType = response.type + (response.id ? ' (' + response.id + ')' : '');
                    Swal.fire(CommonHelper.showLocale(props, 'Cannot delete stock: It is being used in ') + _dataType + '.', '', 'info')
                    return false;
                }
                else {
                    const { dealersettings } = props;
                    let modifiedBy = dealersettings ? dealersettings.id : '';
                    let modifiedDate = window.firebase.firestore.Timestamp.now();

                    const updateRef = window.firebase.firestore().collection(`stock`).doc(id)
                    updateRef.
                        set({
                            isDeleted: true,
                            modifiedFrom: 'web',
                            modifiedBy,
                            modifiedDate
                        }, { merge: true })
                        .then((docRef) => {
                            toast.notify('Stock successfully deleted.', {
                                duration: 2000
                            })


                            if (!_.isEmpty(data)) {
                                setStockList(stocks.filter(a => a.documentID !== id))
                            }


                            let logNotes = 'Stock deleted';
                            if (!_.isEmpty(data) && data.stockNo)
                                logNotes = 'Stock#: ' + data.stockNo + ' deleted';

                            let _objLogData = {
                                notes: logNotes,
                                type: 'stock',
                                subType: 'delete',
                                recordId: id,
                            }
                            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
                            CommonHelper.saveAuditLog(_objLogData);

                        }).catch((error) => {
                            console.error("Error deleting Video: ", error);
                        });
                }
            }
        })
    }
    const handleClose = (id) => {
        setModelShow(false);
        setEditDocument(null);
    }
    const handleHistoryClose = () => {
        setModelHistoryShow(false);
        setEditDocument(null);
    }

    const updateStock = (objStock) => {
        if (!_.isEmpty(objStock)) {
            let _data = props.dataMappingVM(objStock);

            if (!_.isEmpty(_data)) {
                setStockList(stocks.map((item) => {
                    if (item.documentID === _data.documentID) {
                        for (let [key, value] of Object.entries(_data)) {
                            item[key] = value;
                        }
                        return item;
                    }
                    return item;
                }))
            }
        }

    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">

                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>
                            </div>
                            {
                                props.settingsLoader === true ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={stocks.filter(
                                                ({ vehicleModel, regNo, vinNo, mclBuildSlot, status }) => (!_.isEmpty(searchText) ?
                                                    ((vehicleModel && vehicleModel.toLowerCase().includes(searchText.toLowerCase())) ||
                                                        (regNo && regNo.toLowerCase().includes(searchText.toLowerCase())) ||
                                                        (vinNo && vinNo.toLowerCase().includes(searchText.toLowerCase())) ||
                                                        (status && status.toLowerCase().includes(searchText.toLowerCase())) ||
                                                        (mclBuildSlot && mclBuildSlot.toLowerCase().includes(searchText.toLowerCase()))
                                                    )
                                                    : true))}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={props.allStockFieldsListVM}
                                            handleSavesettings={props.handleSavesettings}
                                            dynamicFields={(props.stockFields && props.stockFields.length > 0) ? _.filter(props.stockFields, (v) => (props.accessToStockVBM ? v : !v.startsWith('margin'))) : props.allStockFieldsListVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={props.settingsLoader}
                                            handleActionClick={handleActionClick}
                                            isSettings={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelShow}>
                <AddStock
                    docID={editDocumentID}
                    show={modelShow}
                    clsActive='overlay-modal active'
                    handleClose={handleClose}
                    title={editDocumentID ? 'stock' : 'addStock'}
                    enableSale={false}
                    enableTestDrive={false}
                    enableLoan={false}
                    enableServiceLoan={!editDocumentID && localStorage.defaultModule === 'service' ? true : false}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    isFilterApplied={true}
                    updateStock={updateStock}
                >
                </AddStock>
            </PopUpModal>

            <PopUpModal show={modelHistoryShow}>
                <StockHistory
                    docID={editDocumentID}
                    show={modelHistoryShow}
                    clsActive='overlay-modal active'
                    handleClose={handleHistoryClose}
                    title={'stockHistory'}
                    dealersettings={props.dealersettings}
                    updateDealerSettings={props.updateDealerSettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    history={props.history}
                >
                </StockHistory>
            </PopUpModal>
        </>
    );
}

export default StockList;