/** LIBRARIES */
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
/** COMPONENTS */
import Translate from '../../../constants/translate';

import { default as _images } from '../../../images';

const AddOnsInfo = (props) => {
    const [selectedAddOn, setAddOns] = useState(props.selectedInfos ? props.selectedInfos : {})    

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');
    return (
        <>

            <Modal
                id="AddOnsInfo"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-view-package-info">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Package Details'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>
                        <div className="container-fluid">
                            <div className="row">

                                <div className="view-package-wrapper">
                                    {selectedAddOn.name ? <div className="view-package-title">{selectedAddOn.name}</div> : <></>}
                                    <div className="view-package-price">{selectedAddOn.price ?
                                        <NumberFormat value={selectedAddOn.price} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currencySymbol} />
                                        : ''}</div>
                                    {selectedAddOn.description ? <div className="view-package-length"><Translate text={'durationHRS'} />:&nbsp;{selectedAddOn.description}</div> : <></>}
                                </div>
                                {
                                    !_.isEmpty(selectedAddOn.subList)
                                        ?
                                        <>
                                            <div className="view-package-middle">
                                                <h3><Translate text={'Service Includes'} /></h3>
                                                <ul>
                                                    {
                                                        selectedAddOn.subList.map((info, index) => {
                                                            return <li key={index}> <i className="ico icon-check"> </i> {info.name} </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }


                            </div>
                        </div>

                    </>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddOnsInfo