import React, { useState, useEffect } from 'react'
import "../../../styles/testdrive.scss";
import _ from 'lodash'
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay, GoogleMap } from '../../../components';
import Translate from '../../../constants/translate';
import { objTestDrive } from '../viewModel'

const TestdriveDetails = (props) => {
    const [testdrive, setTestdrive] = useState(objTestDrive)

    const {
        enquiry, users, contact, stock, client,

        startDate, startOdometer,
        endDate, endOdometer,
        beforeInspection, afterSignatureURL, afterInspection,
        isVehicleHandOver, expectedReturn,
        distance, duration, speed, maxSpeed,
        secondaryLicenseFrontURL, secondaryLicenseBackURL,
        polylineCoordinates, insurance,
        terms, status, addedBy, modifiedDate
    } = testdrive;
    const includeInsWaiverInTDTerms = (!_.isEmpty(insurance?.termsAndConditions) && _.isEmpty(insurance?.signature)) ? true : false;
    const showDuration = () => {
        let duration = testdrive.duration ? testdrive.duration : 0;

        var hours = Math.floor(duration / 60 / 60);
        var minutes = Math.floor(duration / 60) - (hours * 60);
        var seconds = duration % 60;

        return (hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0'));
    }

    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    const imageToBase64 = async (id, URL) => {
        // await toDataURL(URL)
        //     .then(dataUrl => {
        //         console.log('imageToBase64:', dataUrl)
        document.getElementById(id).src = URL;
        return URL;


        //     }).catch((error) => {
        //         console.error("Error updating enquiries: ", error);
        //         return URL;
        //     });
    }

    const handleGetTestDrivePath = async (url, _testdrive) => await fetch(url)
        .then(async (response) => {
            const responseJSON = await response.json();
            let _poliyCoordinates = [];
            if (_.isArray(responseJSON)) {
                responseJSON.forEach((doc) => {
                    _poliyCoordinates.push({ 'geopoint': doc });
                });
            }
            if (!_.isEmpty(_poliyCoordinates)) {
                // let state = Object.assign({}, testdrive);
                // state['polylineCoordinates'] = _poliyCoordinates;
                setTestdrive({
                    ..._testdrive,
                    polylineCoordinates: _poliyCoordinates
                });
            }
            else {
                setTestdrive(_testdrive);
            }
        })


    const getInspection = (id, _marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        // var canvas = document.getElementById("canvas");
        var context = canvas.getContext('2d');

        var img = new Image();
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();

            if (id && document.getElementById(id))
                document.getElementById(id).src = dataURL;

            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`testdrives/${props.testdriveid}`).get()
            .then(doc => {
                if (doc.exists) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //  console.log('TestdriveID', props.testdriveid);
        let refCollectionDatat;
        if (props.testdriveid) {

            refCollectionDatat = docData(firestoreDB.firestore().doc(`testdrives/${props.testdriveid}`)
            )
                .pipe(
                    switchMap(tradeIns => {
                        //console.log(tradeIns);
                        let _contactID = tradeIns.contactID ? tradeIns.contactID : tradeIns?.contact?.documentID
                        let _stockID = tradeIns.stockID ? tradeIns.stockID : tradeIns?.stock?.documentID
                        const enqIds = [tradeIns.enquiryID];
                        const clientIds = [tradeIns.clientID];
                        const contactIds = _contactID ? [_contactID] : [];
                        const stockIds = _stockID ? [_stockID] : [];
                        return combineLatest(
                            of(tradeIns),
                            combineLatest(
                                enqIds.length > 0 ? enqIds.map(enquiryID =>
                                    collectionData(firestoreDB.firestore().collection('enquiries').where('documentID', '==', enquiryID)).pipe(
                                        map(enquirySnapshot => enquirySnapshot[0] ? enquirySnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                contactIds.length > 0 ? contactIds.map(contactID =>
                                    collectionData(firestoreDB.firestore().collection('contactDetails').where('documentID', '==', contactID)).pipe(
                                        map(contactSnapshot => contactSnapshot[0] ? contactSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                stockIds.length > 0 ? stockIds.map(stockID =>
                                    collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                                        map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                        map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                        map(usersSnapshot => usersSnapshot)
                                    )
                                ) : of([])
                            ),
                        )
                    }),
                    map(([tradeIns, enquirySnapshot, contactSnapshot, stockSnapshot, clientSnapshot, usersSnapshot]) => {
                        const contactID = tradeIns.contactID ? tradeIns.contactID : (tradeIns.contact ? tradeIns.contact.documentID : '');
                        let _stockID = tradeIns.stockID ? tradeIns.stockID : tradeIns?.stock?.documentID
                        return {
                            ...tradeIns,
                            enquiry: enquirySnapshot.find(a => a.documentID === tradeIns.enquiryID),
                            contact: contactSnapshot.find(a => a.documentID === contactID),
                            stock: _stockID && stockSnapshot.find(a => a.documentID === _stockID) ? stockSnapshot.find(a => a.documentID === _stockID) : Object.assign({}, tradeIns.stock),
                            client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                            users: usersSnapshot[0],
                        }

                    })
                )
                .subscribe(_testdrives => {
                    //   console.log('TestdriveDetails', _testdrives);

                    if (!_.isEmpty(_testdrives)) {
                        if (_testdrives.mapCoordinatesPath)
                            handleGetTestDrivePath(_testdrives.mapCoordinatesPath, _testdrives);
                        else
                            setTestdrive(_testdrives);
                    }

                });
            return () => {
                refCollectionDatat && refCollectionDatat.unsubscribe()
            }
        }
    }, [firestoreDB])

    const licenseSize = '16.5%'

    return (
        <>
            {
                !_.isEmpty(testdrive) && !_.isEmpty(enquiry)
                    ?
                    <>
                        <div className="quotation-wrap report-data-load">
                            <div className="wrapper">
                                <div className="print-wrap">
                                    <div className="header">
                                        <div className="headerwrap-left">
                                            <div className="header-left">
                                                {
                                                    client && client.logoURL
                                                        ?
                                                        <div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                        :
                                                        <>
                                                            <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                        </>
                                                }
                                            </div>
                                        </div>

                                        <div className="headerwrap-right">
                                            <div className='header-right'>
                                                {' '}
                                                {client.name} <br />
                                                <span>
                                                    {' '}
                                                    {client.address ? client.address : ''}
                                                    {client.phone ? (
                                                        <>
                                                            <br />
                                                            {`Phone: ${client.phone}`}
                                                        </>
                                                    ) : <></>}
                                                    {client.email || client.website ? (
                                                        <>
                                                            <br />
                                                            {client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
                                                        </>
                                                    ) : <></>}
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="clear"></div>
                                    <div className="middle">
                                        <div className="title-head"><Translate text={'TEST DRIVE REPORT'} upperCase={true} /></div>
                                        <div className="clear"></div>
                                        <div className="table-wraper">
                                            <div className="table-head valuation-num">{'Enquiry Information'}
                                                {
                                                    status
                                                        ?
                                                        <div className={`mrg-fixstatus badge-${status.toLowerCase()} ml-2`}>
                                                            <Translate
                                                                text={status.toLowerCase()}
                                                                upperCase={true}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    status !== 'complete' && isVehicleHandOver
                                                        ?
                                                        <div className={`mrg-fixstatus badge-inprogress ml-2`}>
                                                            <Translate
                                                                text={'carHandover'}
                                                                upperCase={true}
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Enquiry</span>  {enquiry && enquiry.displayID ? enquiry.displayID : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>User Name</span>{(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserNamebyDocumentId(users, addedBy) : '--'}  </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Department</span> {(!_.isEmpty(users) && addedBy) ? CommonHelper.getUserDepartmentbyDocumentId(users, addedBy) : '--'} </div></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="table-wraper brd-none">
                                            <div className="table-head">Contact & Vehicle Details &nbsp;
                                                {((contact && contact.displayID) ? '(' + contact.displayID + ')' : '')}
                                                {
                                                    contact && contact.isDLScan
                                                        ?
                                                        <div className="mrg-fixstatus badge-white ml-1 mt-0">
                                                            <img src={_images.meSent} alt="" width="14" className="mr-1" />
                                                            {`Digital Driving Licence`}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>

                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Contact Name</span>{CommonHelper.displayContactName(null, contact, '--')}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Licence No</span>{contact && contact.licenseNo ? contact.licenseNo : '--'} </div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Company</span>{contact?.businessContact && !_.isEmpty(contact?.company) && contact?.company.name ? contact?.company.name : '--'}</div></td>
                                                        </tr>

                                                        <tr>
                                                            <td width="33%"><div className="grid-one"> <span>Vehicle</span>{stock?.make ? stock.make : ''} {stock?.model ? stock.model : ''}{stock?.year ? `, ${stock.year}` : ''}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Reg #</span>{stock && stock.regNo ? stock.regNo : '--'}</div></td>
                                                            <td width="33%"><div className="grid-one"> <span>Stock #</span>{stock && stock.stockNo ? stock.stockNo : '--'} </div></td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="tbl-lic-fix">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span>License Front</span>
                                                                        {
                                                                            (contact && contact.licenseFrontURL)
                                                                                ?
                                                                                <a data-fancybox={`licenseFrontURL`} href={contact.licenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="licenseFrontURL" src={contact.licenseFrontURL} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="licenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span>License Back</span>
                                                                        {
                                                                            (contact && contact.licenseBackURL)
                                                                                ?
                                                                                <a data-fancybox={`licenseBackURL`} href={contact.licenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="licenseBackURL" src={contact.licenseBackURL} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="licenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span>Secondary License Front</span>
                                                                        {
                                                                            (contact && contact.secondaryLicenseFrontUrl)
                                                                                ?
                                                                                <a data-fancybox={`secondaryLicenseFrontUrl`} href={contact.secondaryLicenseFrontUrl} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="secondaryLicenseFrontUrl" src={contact.secondaryLicenseFrontUrl} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseFrontUrl" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td width="25%">
                                                                    <div className="license-wrap">
                                                                        <span>Secondary License Back</span>
                                                                        {
                                                                            (contact && contact.secondaryLicenseBackUrl)
                                                                                ?
                                                                                <a data-fancybox={`secondaryLicenseBackUrl`} href={contact.secondaryLicenseBackUrl} onClick={(e) => { e.preventDefault(); }}>
                                                                                    <img id="secondaryLicenseBackUrl" src={contact.secondaryLicenseBackUrl} alt="" className="" width="150" />
                                                                                </a>
                                                                                :
                                                                                <img id="secondaryLicenseBackUrl" src={_images.nolicense} alt="" className="" width="150" />
                                                                        }
                                                                    </div>
                                                                </td>


                                                            </tr>
                                                            {
                                                                (secondaryLicenseFrontURL || secondaryLicenseBackURL) && (<tr>

                                                                    <td width="25%">
                                                                        <div className="license-wrap">
                                                                            <span>Secondary Driver License Front</span>
                                                                            {
                                                                                (secondaryLicenseFrontURL)
                                                                                    ?
                                                                                    <a data-fancybox={`secondaryLicenseFrontURL`} href={secondaryLicenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img id="secondaryLicenseFrontURL" src={secondaryLicenseFrontURL} alt="" className="" width="150" />
                                                                                    </a>
                                                                                    :
                                                                                    <img id="secondaryLicenseFrontURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                            }
                                                                        </div>
                                                                    </td>

                                                                    <td width="25%">
                                                                        <div className="license-wrap">
                                                                            <span>Secondary Driver License Back</span>
                                                                            {
                                                                                (secondaryLicenseBackURL)
                                                                                    ?
                                                                                    <a data-fancybox={`secondaryLicenseBackURL`} href={secondaryLicenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                                        <img id="secondaryLicenseBackURL" src={secondaryLicenseBackURL} alt="" className="" width="150" />
                                                                                    </a>
                                                                                    :
                                                                                    <img id="secondaryLicenseBackURL" src={_images.nolicense} alt="" className="" width="150" />
                                                                            }
                                                                        </div>
                                                                    </td>



                                                                </tr>)
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="table-wraper brd-none">
                                            <div className="table-head">Test Drive Information </div>
                                            <div className="table-details">
                                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span>Start Time</span>{startDate ?
                                                                (!_.isEmpty(client) && client.timezone ? moment(moment.unix(startDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(startDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                : '--'} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>Start Odometer</span>{startOdometer ? <NumberFormat value={startOdometer} displayType={'text'} thousandSeparator={true} /> : <>{'--'}</>} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>End Time</span>{endDate ?
                                                                (!_.isEmpty(client) && client.timezone ? moment(moment.unix(endDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(endDate.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                : '--'} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>Return Odometer</span>{endOdometer ? <NumberFormat value={endOdometer} displayType={'text'} thousandSeparator={true} /> : <>{'--'}</>}</div></td>
                                                        </tr>

                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span>Avg Speed</span>{speed ? speed.toFixed(2) : '0'} Km/hr </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>Max Speed</span>{maxSpeed ? maxSpeed.toFixed(2) : '0'} Km/hr </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>Duration</span>{duration ? showDuration() : '00:00:00'} </div></td>
                                                            <td width="25%"><div className="grid-one"> <span>Distance</span>{distance ? distance.toFixed(2) : '0'} Km</div></td>

                                                        </tr>

                                                        <tr>
                                                            <td width="25%"><div className="grid-one"> <span><Translate
                                                                text={'carHandover'}
                                                            /> </span>
                                                                <img id="testdrive-handover" src={(isVehicleHandOver ? _images.tickcircular : _images.closecircular)} alt="" width="18" />
                                                            </div>
                                                            </td>
                                                            <td width="25%"><div className="grid-one"> <span>Expected Return</span>
                                                                {expectedReturn ?
                                                                    (!_.isEmpty(client) && client.timezone ? moment(moment.unix(expectedReturn.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(expectedReturn.seconds).format('DD/MM/YYYY hh:mm A'))
                                                                    : '--'}
                                                            </div>
                                                            </td>
                                                            <td width="25%"></td>
                                                            <td width="25%"></td>

                                                        </tr>

                                                        {/* <tr>
                                                            <td colSpan="4">
                                                                <span>NOTES</span>
                                                                <div className="notes text-area-space">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vestibulum non sapien pulvinar semper. Cras convallis massa et ipsum facilisis suscipit.</div>
                                                            </td>

                                                        </tr> */}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="testdrive-info">

                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tbody>
                                                    <tr>
                                                        <td width="50%" align="left" valign="top">
                                                            <h4>Before Test Drive </h4>
                                                            <div className="testdrive-img">
                                                                {
                                                                    !_.isEmpty(beforeInspection)
                                                                        ?
                                                                        beforeInspection && beforeInspection.map((mark, index) => {
                                                                            return <div key={index}>
                                                                                {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                    <div key={index} className="testdrive-thumb">
                                                                                        <img id={`testdrive-before-${index}`} src={data} width="78" />
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        })
                                                                        :
                                                                        <>
                                                                            <div className="testdrive-thumb">
                                                                                <img id={`testdrive-before`} src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="78" />
                                                                            </div>
                                                                        </>
                                                                }

                                                            </div>

                                                            <div className="signbox">
                                                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                    <tbody>
                                                                        <tr>

                                                                            <td width="100%" align="left" valign="top" ><h5>Vehicle Inspection </h5>
                                                                                <div><img id={`testdrive-before-ins`} src={getInspection(`testdrive-before-ins`, beforeInspection)} width="150" /></div>

                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>

                                                        </td>
                                                        {
                                                            status === 'complete'
                                                                ?

                                                                <td width="50%" align="left" valign="top">
                                                                    <h4> After Test Drive </h4>
                                                                    <div className="testdrive-img">
                                                                        {

                                                                        }
                                                                        {
                                                                            !_.isEmpty(afterInspection)
                                                                                ?
                                                                                afterInspection && afterInspection.map((mark, index) => {
                                                                                    return <div key={index}>
                                                                                        {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                                            <div key={index} className="testdrive-thumb">
                                                                                                <img id={`testdrive-after-${index}`} src={data} width="78" />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                })
                                                                                :
                                                                                <>
                                                                                    <div className="testdrive-thumb">
                                                                                        <img id={`testdrive-after`} src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="78" />
                                                                                    </div>
                                                                                </>
                                                                        }

                                                                    </div>
                                                                    <div className="signbox">
                                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td width="50%" align="left" valign="top"><h5>Signature </h5>
                                                                                        <div className="sign">
                                                                                            {
                                                                                                afterSignatureURL
                                                                                                    ?
                                                                                                    <img id={`afterSignatureURL`} src={afterSignatureURL} width="120" />
                                                                                                    :
                                                                                                    <img id={`afterSignatureURL`} src={_images.sign} width="120" />
                                                                                            }

                                                                                            <span>{CommonHelper.displayContactName(null, contact, '--')}</span>
                                                                                            {
                                                                                                modifiedDate
                                                                                                    ?
                                                                                                    <>
                                                                                                        <br />
                                                                                                        <span className='pt-1'>
                                                                                                            <strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(modifiedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(modifiedDate.seconds).format('DD/MM/YYYY hh:mm A'))}</strong>
                                                                                                        </span>
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="50%" align="left" valign="top"><h5>Vehicle Inspection </h5>
                                                                                        <div><img id={`testdrive-after-ins`} src={getInspection(`testdrive-after-ins`, afterInspection)} width="150" /></div>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </div>

                                                                </td>
                                                                :
                                                                <></>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                        {
                                            status !== 'complete' && isVehicleHandOver
                                                ?
                                                <></>
                                                :
                                                <div className="table-wraper brd-none page-break mt-5">
                                                    <div className="table-head">Vehicle Path Track </div>
                                                    <div className="vehicle-map">
                                                        {
                                                            !_.isEmpty(polylineCoordinates)
                                                                ?
                                                                <>
                                                                    <GoogleMap
                                                                        polylineCoordinates={polylineCoordinates}
                                                                        dealersettings={testdrive}
                                                                    >
                                                                    </GoogleMap>
                                                                </>
                                                                :
                                                                <>
                                                                    <img id={`testdrive-nomap`} src={_images.nomap} width="220" />
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                        }

                                        {

                                            !_.isEmpty(testdrive.client) && !_.isEmpty(testdrive.client.testdriveRoutemap) ?
                                                <div className="table-wraper brd-none page-break mt-5">
                                                    <div className="table-head">ROUTE MAP </div>
                                                    <div className="vehicle-map">
                                                        <img id={`testdrive-nomap`} src={testdrive.client.testdriveRoutemap} width="650" />
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                        {
                                            !_.isEmpty(terms)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break mt-5">
                                                        <div className="table-head">TERMS & CONDITIONS</div>
                                                        <div className="terms"
                                                            dangerouslySetInnerHTML={{ __html: (terms.termsAndConditions ? terms.termsAndConditions : '--') }} />

                                                    </div>
                                                    {includeInsWaiverInTDTerms && (<>
                                                        <div className="table-wraper brd-none page-break mt-1">
                                                            <div className="table-head">Insurance Excess Reduction</div>
                                                            <div className="terms"
                                                                dangerouslySetInnerHTML={{ __html: (insurance?.termsAndConditions ? insurance.termsAndConditions : '--') }} />

                                                        </div>
                                                        <div className={`form-terms-check-container`}>
                                                            <div className="checkbox icheck-success">
                                                                <input
                                                                    type="checkbox"
                                                                    id="insuranceConsent"
                                                                    name="insuranceConsent"
                                                                    className="uncheck-activity"
                                                                    checked={true}
                                                                    onChange={(e) => { console.log(e, 'insuranceConsent') }} />
                                                                <label htmlFor="insuranceConsent">{'I agree to insurance waiver terms & conditions'}</label>
                                                            </div>
                                                        </div>
                                                    </>)}
                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head">Signature </div>
                                                        <div className="vehicle-map">
                                                            {
                                                                terms.signature
                                                                    ?
                                                                    <img id={`terms-signature`} src={terms.signature} width="120" />
                                                                    :
                                                                    <img id={`terms-signature`} src={_images.sign} width="120" />
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        terms.addedDate
                                                            ?
                                                            <>
                                                                <div className="table-wraper cst-consent-wrapper">
                                                                    <div className="footer-left"><Translate text={'Signed Date'} />  :&nbsp;
                                                                        <strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(terms.addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(terms.addedDate.seconds).format('DD/MM/YYYY hh:mm A'))}</strong>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                        {
                                            (!_.isEmpty(insurance) && !includeInsWaiverInTDTerms)
                                                ?
                                                <>
                                                    <div className="table-wraper brd-none page-break mt-1">
                                                        <div className="table-head">Insurance Excess Reduction</div>
                                                        <div className="terms"
                                                            dangerouslySetInnerHTML={{ __html: (insurance.termsAndConditions ? insurance.termsAndConditions : '--') }} />

                                                    </div>

                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head">Signature </div>
                                                        <div className="vehicle-map">
                                                            {
                                                                insurance.signature
                                                                    ?
                                                                    <img id={`insurance-signature`} src={insurance.signature} width="120" />
                                                                    :
                                                                    <img id={`insurance-signature`} src={_images.sign} width="120" />
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        insurance.addedDate
                                                            ?
                                                            <>
                                                                <div className="table-wraper cst-consent-wrapper">
                                                                    <div className="footer-left"><Translate text={'Signed Date'} />  :&nbsp;
                                                                        <strong>{(!_.isEmpty(client) && client.timezone ? moment(moment.unix(insurance.addedDate.seconds)).tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment.unix(insurance.addedDate.seconds).format('DD/MM/YYYY hh:mm A'))}</strong>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="push"></div>
                            </div>
                            <div className="clear"></div>
                            <div className="footer">
                                <div className="footer-bottom">

                                    <p>
                                        <span style={{ paddingLeft: 0 }} >
                                            {client.address ? <>{client.address}</> : <></>}
                                        </span>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client?.phone ? <>&nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                            {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                            {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                            {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-left">{'Powered by FusionSD.'}</div>

                                    <div className='footer-right'>Created On - {!_.isEmpty(client) && client.timezone ? moment.tz(client.timezone).format('DD/MM/YYYY hh:mm A') : moment().format('DD/MM/YYYY hh:mm A')}</div>

                                </div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="mt-5"><ContentOverlay active={true} /></div>
                    </>
            }
        </>
    )
}
export default TestdriveDetails;