/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import { InputText } from '../../../../components'
import toast from 'toasted-notes'
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2'
import ReactSummernote from 'react-summernote';
import SummerEditor from './summereditor';
import _ from 'lodash';

const mentions = [{
    name: 'First Name',
    value: '@FIRST_NAME'
},
{
    name: 'Last Name',
    value: '@LAST_NAME'
},
{
    name: 'Preferred Name',
    value: '@PREF_NAME'
},
{
    name: 'Full Name',
    value: '@FULL_NAME'
},
{
    name: 'Customer Email',
    value: '@CUSTOMER_EMAIL'
},
{
    name: 'Customer Phone',
    value: '@CUSTOMER_PHONE'
},
{
    name: 'Company Name',
    value: '@COMPANY_NAME'
},
{
    name: 'User Name',
    value: '@USER_NAME'
},
{
    name: 'User Email',
    value: '@USER_EMAIL'
},
{
    name: 'User Phone',
    value: '@USER_PHONE'
},
{
    name: 'Dealer Name',
    value: '@DEALER_NAME'
},
{
    name: 'Dealer Phone',
    value: '@DEALER_PHONE'
},
{
    name: 'Dealer Email',
    value: '@DEALER_EMAIL'
},
{
    name: 'Dealer Address',
    value: '@DEALER_ADDRESS'
},
{
    name: 'Dealer Logo',
    value: '@DEALER_LOGO'
},
{
    name: 'Test Drive Make',
    value: '@TD_MAKE'
},
{
    name: 'Test Drive Model',
    value: '@TD_MODEL'
},
{
    name: 'Test Drive Start Date',
    value: '@TD_STARTDATE'
},
{
    name: 'Test Drive End Date',
    value: '@TD_ENDDATE'
},
{
    name: 'Test Drive Exp. Return Date',
    value: '@TD_EXP_RETN_DATE'
},
{
    name: 'Fleet Make',
    value: '@FLEET_MAKE'
},
{
    name: 'Fleet Model',
    value: '@FLEET_MODEL'
},
{
    name: 'Fleet Start Date',
    value: '@FLEET_STARTDATE'
},
{
    name: 'Fleet End Date',
    value: '@FLEET_ENDDATE'
},
{
    name: 'Fleet Drive Exp. Return Date',
    value: '@FLEET_EXP_RETN_DATE'
},
{
    name: 'Current Month',
    value: '@CURRENT_MONTH'
}]

const GenericTemplates = (props) => {
    const [state, setState] = useState({});
    const [loader, setLoader] = useState();
    const [key, setKey] = useState('consent')
    const [email, setEmail] = useState('')

    useEffect(() => {
        window.firebase.firestore().doc(`genericTemplates/${key}`)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.exists) {
                    var node = document.createElement('div');
                    node.innerHTML = `${querySnapshot.data().bodyHTML}`;
                    ReactSummernote.insertNode(node);
                    setState(querySnapshot.data());
                    setEmail(querySnapshot.data().bodyHTML);
                }
                else {
                    setState({})
                }
            });
    }, [key])

    useEffect(() => {
        if (!_.isEmpty(email) && !_.isEmpty(state)){
            setState({
                ...state,
                bodyHTML: email
            })
        }       
    }, [email])

    const handleChange = obj => {
        setEmail(obj)
    };

    const saveTemplate = () => {
        setLoader(true);
        window.firebase.firestore().doc(`genericTemplates/${key}`).set({
            ...state,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid
        }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify('Template updated successfully', {
                    duration: 2000
                })
            })
            .catch(error => {
                console.error(error);
                setLoader(false);
                Swal.fire('Something went wrong.', '', 'error')
            });
    }
    const handleOnChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        var str = value;

        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setState({
            ...state,
            [name]: str,
        });
    };

    return (
        <>
            <h2>Generic Templates</h2>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey="consent" transition={false} className="nav-fill" onSelect={(val) => {
                            setEmail('');
                            setState({});
                            setKey(val);
                        }} unmountOnExit={true} mountOnEnter={true}>
                            <Tab eventKey="consent" title="Consent">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>
                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'consent'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="testdrive" title="Test Drive">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'testdrive'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="loanVehicle" title="Loan Vehicle">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'loanVehicle'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="inboundLead" title="Inbound Lead Received">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'inboundLead'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="mentionNotes" title="Mention on Notes">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'mentionNotes'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>                        
                            <Tab eventKey="teammates" title="Teammates">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'teammates'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="scheduleActivity" title="Schedule Activity">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'scheduleActivity'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="lostApproval" title="Lost Approval">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'lostApproval'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="transferLead" title="Transfer Lead">
                                <div className="settings-terms-wrap">
                                    <div className='form-group'>

                                        <InputText
                                            autoComplete='off'
                                            placeholder={'subject'}
                                            className={`form-control`}
                                            name='subject'
                                            onChange={handleOnChange}
                                            value={state.subject}
                                            maxLength={250}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <SummerEditor
                                            name={'transferLead'}
                                            value={email}
                                            height={'450px'}
                                            handleChange={handleChange}
                                            emailType={'compose'}
                                            templateMentionsEnabled={true}
                                            mentions={mentions}
                                        />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </nav>
                    <div className="settings-footer mt-3">
                        <button type="button" className="btn btn-primary float-right" onClick={() => saveTemplate()}>
                            {
                                loader ?
                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                    : (<></>)
                            }
                            <Translate text={'save'} />
                        </button>
                    </div>
                </div>
            </div>

        </>
    );
}

export default GenericTemplates;