import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { InputText, ReactSelect, ContentOverlay } from '../../components'
import Translate from '../../constants/translate';
import axios from 'axios';
import { validateEmail } from "../../services/helper";

const smtpTypes = [
    { label: 'Custom SMTP Settings', value: 'smtp' },
    { label: 'Simple Email Service (Amazon SES)', value: 'awsses' },
    { label: 'Nylas', value: 'nylas' }
];

const AddEmailSettings = (props) => {

    const [smtpSettings, setSMTPSettings] = useState()
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(true)
    const [btnloader, setBtnLoader] = useState(false)
    const [checkawsLoader, setCheckAWSLoader] = useState(false)
    const [resetSMTPLoader, setResetSMTPLoader] = useState(false)
    const [resetawssesLoader, setResetawssesLoader] = useState(false)
    const [awsLoader, setAWSLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const _nylas = Object.assign({}, props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.integrations &&
        props.dealersettings.client.integrations.filter(e => e.type === "nylas")[0]);
    const nylasEnabled = _nylas && _nylas.active && _nylas.enabled ? true : false;

    useEffect(() => {
        setLoader(true);
        let refQuery = window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}/smtpSettings/${props.dealersettings.client.id}`);
        if (localStorage.defaultModule === 'oem') {
            refQuery = window.firebase.firestore().doc(`clientSettings/${props.dealersettings.oemID}/smtpSettings/${props.dealersettings.oemID}`);
        }
        const settingsDoc = refQuery.get()
            .then(doc => {
                if (doc.exists) {
                    setSMTPSettings(doc.data());
                    if (!_.isEmpty(props.nylasCode && _.isEmpty(doc.data().nylasAccessToken))) {
                        authnulasEmail(props.nylasCode)
                    }
                }
                else {
                    setSMTPSettings({
                        type: 'smtp'
                    })
                } 
                setLoader(false);
            });
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setSMTPSettings({
            ...smtpSettings,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }


    const handleSelectChange = (e, data) => {
        setSMTPSettings({
            ...smtpSettings,
            [data.name]: e.value
        });
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    async function authnulasEmail(code) {
        try {
            setBtnLoader(true)
            const nylasExchangeCodeForToken = window.firebase
                .functions()
                .httpsCallable('nylas-exchangeCodeForToken');
            const resp = await nylasExchangeCodeForToken({
                code: code,
            });

            const nylasGetAccountInfo = window.firebase
                .functions()
                .httpsCallable('nylas-getAccountInfo');
            const accInfo = await nylasGetAccountInfo({
                token: resp.data,
            });
            setBtnLoader(false);
            saveSMTPSettings({
                ...smtpSettings,
                nylasAccountInfo: accInfo.data,
                nylasAccessToken: resp.data
            }, false);

        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }

    const checkVerifyAWSSES = async () => {
        const apiURL = props.dealersettings.apiURL
        try {
            setCheckAWSLoader(true)
            const response = await axios.post(`${apiURL}awsses-api/api/awsses/checkVerification`, {
                email: smtpSettings.email
            })
            // console.log('repsonse awsses/checkVerification', response)
            if (response &&
                response.data &&
                response.data.success &&
                !_.isEmpty(response.data.data) &&
                !_.isEmpty(response.data.data.VerificationAttributes) &&
                !_.isEmpty(response.data.data.VerificationAttributes) &&
                !_.isEmpty(response.data.data.VerificationAttributes[smtpSettings.email]) &&
                response.data.data.VerificationAttributes[smtpSettings.email].VerificationStatus) {

                // console.log('status resp', response.data.data.VerificationAttributes[smtpSettings.email])

                if (response.data.data.VerificationAttributes[smtpSettings.email].VerificationStatus === 'Pending') {
                    Swal.fire('Email Verification is still pending', '', 'warning')
                } else {
                    Swal.fire('Email Verified', '', 'success')
                    saveSMTPSettings({
                        ...smtpSettings,
                        pending: false,
                        verified: true
                    }, true);
                }
            } else {
                Swal.fire('Unable to verify email.', response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setCheckAWSLoader(false)
        } catch (error) {
            console.log(error)
            setCheckAWSLoader(false)
            Swal.fire('Unable to verify email.', '', 'error')
        }
    }

    const resetAWSSES = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to switch to reset Amazon SES?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            })

            if (!result.value) {
                return
            }
            Swal.fire('Email account is disabled successfully.', '', 'success')
            setResetawssesLoader(true)
            setResetawssesLoader(false)
            saveSMTPSettings({
                type: smtpSettings.type
            }, false);
        } catch (error) {
            setResetawssesLoader(false)
            console.log(error)
        }
    }

    const verifyAWSSES = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(smtpSettings.email)) {
            formIsValid = false;
            errors['awsemail'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        const apiURL = props.dealersettings.apiURL
        try {
            setAWSLoader(true)
            const response = await axios.post(`${apiURL}awsses-api/api/awsses/verifyEmail`, {
                email: smtpSettings.email
            })
            // console.log('repsonse awsses/verifyEmail', response)
            if (response && response.data && response.data.success) {
                Swal.fire('Verification Email Sent.', response.data.message, 'success')
                saveSMTPSettings({
                    ...smtpSettings,
                    pending: true
                }, false);
            } else {
                Swal.fire('Unable to verify email.', response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setAWSLoader(false)
        } catch (error) {
            console.log(error)
            setAWSLoader(false)
            Swal.fire('Unable to verify email.', '', 'error')
        }
    }

    const verifySTMP = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(smtpSettings.smtpFrom) || !validateEmail(smtpSettings.smtpFrom)) {
            formIsValid = false;
            errors['smtpFrom'] = errorClass;
        }
        if (_.isEmpty(smtpSettings.smtpUri)) {
            formIsValid = false;
            errors['smtpUri'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        const apiURL = props.dealersettings.apiURL
        try {
            setVerifyLoader(true)
            const response = await axios.post(`${apiURL}mailer-api/api/mailer/verify`, {
                smtpUri: smtpSettings.smtpUri,
                from: smtpSettings.smtpFrom,
            })
            // console.log('repsonse smtpverify', response)
            if (response && response.data && response.data.success) {
                Swal.fire('Email Verified successfully.', '', 'success')
                saveSMTPSettings({
                    ...smtpSettings,
                    smtpVerified: true
                },true);
            } else {
                Swal.fire('Unable to verify SMTP Settings.', response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setVerifyLoader(false)
        } catch (error) {
            console.log(error)
            setVerifyLoader(false)
            Swal.fire('Unable to verify SMTP Settings.', '', 'error')
        }
    }

    const resetSTMP = async () => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to switch to reset SMPT Settings?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            })

            if (!result.value) {
                return
            }

            setResetSMTPLoader(true)
            setResetSMTPLoader(false)
            saveSMTPSettings({
                type: smtpSettings.type
            },false);
        } catch (error) {
            setResetSMTPLoader(false)
            console.log(error)
        }
    }


    const saveSMTPSettings = (_smtpSettings, _close) => {
        setSMTPSettings(_smtpSettings);
        let refQuery = `clients/${props.dealersettings.client.id}/smtpSettings/${props.dealersettings.client.id}`;
        if (localStorage.defaultModule === 'oem') {
            refQuery = `clientSettings/${props.dealersettings.oemID}/smtpSettings/${props.dealersettings.oemID}`;
        }
        window.firebase.firestore().doc(`${refQuery}`).set({
            ..._smtpSettings,
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now()
        })
            .then(snapshot => {
                if (_close)
                    props.handleClose();
            })
            .catch(error => {
                console.error(error);
                Swal.fire('Something went wrong. Please contact support.', '', 'error')
            })
    }

    async function unAuthenticateDealerEmail() {
        try {
            setBtnLoader(true)
            const nylasCancelAccount = window.firebase
                .functions()
                .httpsCallable('nylas-cancelAccount');
            const resp = await nylasCancelAccount({
                token: props.dealer.nylasAccessToken,
            });
            if (resp.data.success) {
                Swal.fire('Email disabled successfully.', '', 'success')
                setBtnLoader(false)
                saveSMTPSettings({
                    type: smtpSettings.type
                }, false);
            }
        } catch (error) {
            toast.notify('Something went wrong', {
                duration: 2000,
            });
            console.log(error);
        }
    }

    const disableEmail = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to disable email account?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                unAuthenticateDealerEmail()
            }
        });
    };

    const connectNylas = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(smtpSettings.email_address) || !validateEmail(smtpSettings.email_address)) {
            formIsValid = false;
            errors['email_address'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setBtnLoader(true)
        saveSMTPSettings({
            ...smtpSettings,
            nylasAccountInfo: null,
            nylasAccessToken: null
        }, false);
        authenticateUser()
    }

    const authenticateUser = async () => {
        const nylasUrlForAuthentication = window.firebase
            .functions()
            .httpsCallable('nylas-urlForAuthentication');
        const authUrl = await nylasUrlForAuthentication({
            options: {
                loginHint: smtpSettings.email_address,
                redirectURI: `${window.location.origin}/reports`,
                scopes: ['email.modify', 'email.send'],
            },
        });
        if (authUrl.data) {
            console.log(authUrl.data);
            window.location.replace(authUrl.data);
        }
    };


    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-lost-reasons"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    {
                        loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<div className="row">
                            <div className="form-style w-100">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label > <Translate text={'Email Type'} /></label>
                                        <ReactSelect
                                            options={smtpTypes.filter(a => nylasEnabled ? true : a.value !== 'nylas')}
                                            name={"type"}
                                            placeholder={'select type'}
                                            onChange={handleSelectChange}
                                            value={smtpSettings.type ? smtpSettings.type : 'smtp'}
                                            classNamePrefix={`${errorFields["type"]} basic-select`}
                                            removeClearable={false}
                                        >
                                        </ReactSelect>
                                    </div>
                                    {
                                        smtpSettings.type === 'smtp' ? (
                                            <>
                                                <div className="form-group col-md-12">
                                                    <label> <Translate text={'From Email'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'from email'}
                                                        className={`form-control ${errorFields["smtpFrom"]}`}
                                                        name="smtpFrom"
                                                        onChange={handleOnChange}
                                                        value={smtpSettings.smtpFrom}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'SMTP connection URI'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'smtp://username:password@smtp.hostname.com:465'}
                                                        className={`form-control  ${errorFields["smtpUri"]}`}
                                                        name="smtpUri"
                                                        onChange={handleOnChange}
                                                        value={smtpSettings.smtpUri}
                                                        maxLength={500}
                                                    />
                                                </div>
                                                {smtpSettings.smtpVerified ? (<div className="mfa-green mt-2">
                                                    <div className="float-left mt-1"><i className="ico icon-settings-security"></i> SMTP Settings are verified</div>
                                                    <div className="float-right ">
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetSTMP() }}>
                                                            {
                                                                resetSMTPLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            Disable SMTP Settings
                                                        </button>
                                                    </div>
                                                </div>) : (<div className="mfa-red mt-2">
                                                    <div className="float-left mt-1"><i className="ico icon-settings-security"></i> SMTP Settings are unverified</div>
                                                    <div className="float-right ">
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { verifySTMP() }}>
                                                            {
                                                                verifyLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            Verify SMTP Settings
                                                        </button>
                                                    </div>
                                                </div>)}
                                            </>
                                        ) : smtpSettings.type === 'awsses' ? (
                                            <>

                                                <div className="form-group col-md-12">
                                                    <label><Translate text={'Email'} /></label>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'enter email'}
                                                        className={`form-control ${errorFields["awsemail"]}`}
                                                        name="email"
                                                        onChange={handleOnChange}
                                                        value={smtpSettings.email}
                                                    />
                                                </div>
                                                {smtpSettings.verified ? (<div className="mfa-green mt-2">
                                                    <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Email verified</div>
                                                    <div className="float-right ">
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetAWSSES() }}>
                                                            {
                                                                resetawssesLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            Disable
                                                        </button>
                                                    </div>
                                                </div>) :
                                                    smtpSettings.pending ? (
                                                        (
                                                            <div className="mfa-yellow mt-2">
                                                                <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Pending verification</div>
                                                                <div className="float-right w-100 d-inline-block mt-2">
                                                                    <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { verifyAWSSES() }}>
                                                                        {
                                                                            awsLoader ?
                                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                : (<></>)
                                                                        }
                                                                        Resend Verification Email
                                                                    </button>
                                                                    <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetAWSSES() }}>
                                                                        {
                                                                            resetawssesLoader ?
                                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                : (<></>)
                                                                        }
                                                                        Disable
                                                                    </button>
                                                                    <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { checkVerifyAWSSES() }}>
                                                                        {
                                                                            checkawsLoader ?
                                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                : (<></>)
                                                                        }
                                                                        Check Verification
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                {smtpSettings.verified ? (<></>)
                                                    : smtpSettings.pending ? (<></>)
                                                        : (
                                                            <div className="form-group col-md-12 mt-3">
                                                                <button type="button" className="btn btn-primary float-right" onClick={() => verifyAWSSES()}>
                                                                    {
                                                                        awsLoader ?
                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                            : (<></>)
                                                                    }
                                                                    <Translate text={'save'} />
                                                                </button>
                                                            </div>
                                                        )}
                                            </>
                                        ) : (!_.isEmpty(smtpSettings.nylasAccessToken) && !_.isEmpty(smtpSettings.nylasAccountInfo)) ? (
                                            <div className="email-active-sync">
                                                <div className="email-active-sync-item active-tab">
                                                    <div className="email-sync-status">
                                                        <button type="button" className="btn btn-common float-right" onClick={() => { disableEmail() }}>
                                                            {
                                                                btnloader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            Disable </button>
                                                    </div>
                                                    <h4>{smtpSettings.nylasAccountInfo.email_address}</h4>
                                                </div>
                                            </div>
                                        ) : (<><div className="form-group col-md-12">
                                            <label><Translate text={'Email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'enter email'}
                                                className={`form-control ${errorFields["email_address"]}`}
                                                name="email_address"
                                                onChange={handleOnChange}
                                                value={smtpSettings.email_address}
                                            />
                                        </div>
                                            <div className="form-group col-md-12 mt-3">
                                                <button type="button" className="btn btn-primary float-right" onClick={() => connectNylas()}>
                                                    {
                                                        btnloader ?
                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                            : (<></>)
                                                    }
                                                    <Translate text={'Connect'} />
                                                </button>
                                            </div>
                                        </>)
                                    }

                                </div>
                            </div>
                        </div>)
                    }

                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddEmailSettings;