import { truncate } from 'lodash';
import React from 'react';
import CommonHelper from '../../services/common';

const FileItem = ({ file, token,apiURL }) => {
	const [downloadLoader, setDownloadLoader] = React.useState(false);

	const handleDownload = async (fileID, file, token) => {
		try {
			setDownloadLoader(true);
			window.location.assign(
				`${apiURL}/nylas-getFile?id=${fileID}&token=${token}`,
			);
			setDownloadLoader(false);
		} catch (error) {
			setDownloadLoader(false);

			console.log(error);
		}
	};

	const showFileIcon = type => {
		if (type) {
			switch (type.toLowerCase()) {
				case 'pdf':
					type = <i className='ico icon-files-pdf'></i>;
					break;
				case 'docx':
				case 'doc':
					type = <i className='ico icon-files-word'></i>;
					break;
				case 'xls':
				case 'xlsx':
					type = <i className='ico icon-files-excel'></i>;
					break;
				case 'zip':
				case '7z':
				case 'rar':
					type = <i className='ico icon-files-zip'></i>;
					break;
				case 'mp3':
				case 'ogg':
				case 'wav':
					type = <i className='ico icon-files-audio'></i>;
					break;
				case 'mp4':
				case 'avi':
				case 'wmv':
				case 'mpeg':
				case 'mov':
					type = <i className='ico icon-files-video'></i>;
					break;
				case 'jpg':
				case 'jpeg':
				case 'png':
					type = <i className='ico icon-files-image'></i>;
					break;
				default:
					type = <i className='ico icon-files-document'></i>;
			}

			return type;
		} else {
			return <i className='ico icon-files-document'></i>;
		}
	};

	return (
		<li>
			<span className='mailbox-attachment-icon'>
				{showFileIcon(file.filename.split('.').pop())}
			</span>
			<div className='mailbox-attachment-info'>
				<a href='#' className='mailbox-attachment-name'>
					<i className='ico icon-attachment'></i>
					{truncate(
						`${file.filename}`,
						{
							length: 23,
						},
					)}
				</a>
				<div className='mailbox-download-wrap'>
					<span className='mailbox-attachment-size clearfix'>
						<span>{' (' + CommonHelper.showFileSize(file.size) + ')'}</span>

						{downloadLoader ? (
							<a
								href='#'
								className='btn btn-default btn-sm float-right'
								// onClick={() => handleDownload(file.id, file)}
								style={{ padding: '5px 0px 5px 5px' }}
							>
								<span
									className='spinner-border spinner-button mr-1 mt-0'
									role='status'
									aria-hidden='true'
								></span>
							</a>
						) : (
							<a
								href='#'
								className='btn btn-default btn-sm float-right'
								onClick={() => handleDownload(file.id, file, token)}
							>
								<i className='ico icon-mail-download'></i>
							</a>
						)}
					</span>
				</div>
			</div>
		</li>
	);
};
// padding: 5px 0px 5px 5px;
export default FileItem;
