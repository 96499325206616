import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { formTypes, levelOptions } from './viewModel';
import Translate from '../../../../constants/translate';
import { InputText, ReactSelect } from '../../../../components';
import { activityLogOptions } from '../../../pipeline/viewModel';
import CommonHelper from '../../../../services/common';

import '../../../../styles/customer-feedbackform.scss';

const FormSettings = ({
	dealersettings,
	show,
	handleClose,
	handleSelectChange,
	feedbackForm,
	clientUsers,
	handleOnChange,
	errorFields,
	clientOptions,
	setFeedbackForm,
	setErrorFields,
	handleSave,
	fromPublish,
	loader
}) => {
	const [activityTypeOptions, setActivityTypeOptions] = useState(activityLogOptions);
	const [userOptions, setUserOptions] = useState([]);
	const [originOptions, setOriginOptions] = useState([]);

	useEffect(() => {

		clearOptions()
		window.firebase
			.firestore()
			.collection('clients')
			.doc(feedbackForm.clientID)
			.collection('currentSettings')
			.doc(feedbackForm.clientID)
			.get()
			.then(snap => {
				let currentSettings = snap.data()
				if (!_.isEmpty(currentSettings) && !_.isEmpty(currentSettings.settings)) {
					console.log('currentSettings individual', snap.data())
					const settings = currentSettings.settings;
					const users = currentSettings.users;
					parseClientSettings(settings, users, currentSettings.tradeinPro)
				} else {
					console.log('currentSettings individual else case', snap.data())
					parseClientSettings(dealersettings.client.settings, clientUsers, dealersettings.client.tradeinPro)
				}
			})
	}, [dealersettings, clientUsers, feedbackForm.clientID]);

	const clearOptions = () => {
		setUserOptions([]);
		setOriginOptions([]);
		setActivityTypeOptions([])
	}

	const parseClientSettings = (settings, _users, tradeinPro) => {
		const origins = [];
		const _activityTypes = [];
		const _clientUsers = [];

		tradeinPro.origins &&
			tradeinPro.origins.forEach(doc => {
				origins.push({
					value: doc.value,
					active: _.isBoolean(doc.active) ? doc.active : true,
					label: doc.name,
				});
			});

		settings.activityTypes &&
			settings.activityTypes
				.filter(a => a.active === true)
				.forEach(doc => {
					_activityTypes.push({
						label: (
							<>
								<i className={`ico icon-${doc.icon} mr-1`}></i>
								{doc.name}
							</>
						),
						name: doc.name,
						value: doc.value,
						active: true,
					});
				});

		if (!_.isEmpty(_users)) {
			_users.forEach(doc => {
				_clientUsers.push({
					value: doc.id,
					searchlabel: doc.name,
					label: (
						<div className='drop-image'>
							<img
								src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)}
								alt=''
								width='50'
								className='img-object-fit'
							/>
							{doc.name}
						</div>
					),
					active: true,
				});
			});
		} else if (clientUsers) {
			clientUsers.forEach(doc => {
				_clientUsers.push({
					value: doc.id,
					searchlabel: doc.name,
					label: (
						<div className='drop-image'>
							<img
								src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)}
								alt=''
								width='50'
								className='img-object-fit'
							/>
							{doc.name}
						</div>
					),
					active: _.isBoolean(doc.active) ? doc.active : true,
				});
			});
		}

		if (!_.isEmpty(_activityTypes)) {
			setActivityTypeOptions(_activityTypes);
		}
		setOriginOptions(origins);
		setUserOptions(_clientUsers);
	}

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog modal-dialog-centered modal-update-password'
				enforceFocus={false}
			>
				<Modal.Header closeButton>
					<Modal.Title><Translate text={'Form Settings'} /></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<div className='form-style w-100'>
								<div className='form-row'>
									<div className='form-group col-md-12'>
										<label><Translate text={'title'} /></label>
										<span className='feedback-required-red'>* </span>
										<InputText
											autoComplete='off'
											placeholder={'title'}
											className={`form-control ${errorFields['title']}`}
											name='title'
											onChange={handleOnChange}
											value={feedbackForm ? feedbackForm.title : ''}
										/>
									</div>

									<div className='form-group col-md-12'>
										<label>
											<Translate text={'Level'} />
										</label>
										<span className='feedback-required-red'>* </span>
										<ReactSelect
											options={
												dealersettings.level === 'oem' ||
													_.isEmpty(dealersettings.level) ||
													!_.find(levelOptions, { value: dealersettings.level })
													? levelOptions
													: levelOptions.filter(
														e =>
															e.index >=
															_.find(levelOptions, {
																value: dealersettings.level,
															}).index,
													)
											}
											name={'level'}
											placeholder={'select level'}
											onChange={(e, data) => {
												if (e) {
													setFeedbackForm({
														...feedbackForm,
														level: e.value,
														levelID: '',
														owner: '',
														activityType: '',
														origin: '',
														enquiryType: '',
														campaign: '',
													});
													setErrorFields({
														...errorFields,
														level: '',
														levelID: '',
													});
												} else {
													setFeedbackForm({
														...feedbackForm,
														level: '',
														levelID: '',
													});
												}
											}}
											value={feedbackForm.level}
											classNamePrefix={`${errorFields['level']} cursor-pointer basic-select`}
											removeClearable={true}
										/>
									</div>
									{!_.isEmpty(feedbackForm.level) &&
										feedbackForm.level !== 'oem' ? (
										<div
											className='form-group col-md-12'
											id={'fieldsettings-levelid-enq'}
										>
											<label>
												<Translate text={`${feedbackForm.level === 'individual' ? 'Select Client' : feedbackForm.level === 'group' ? 'Select Group' : feedbackForm.level === 'region' ? 'Select Region' : ''}`} />
											</label>
											<span className='feedback-required-red'>* </span>
											<ReactSelect
												options={clientOptions}
												name={'levelID'}
												placeholder={`select ${feedbackForm.level === 'individual'
													? 'client'
													: feedbackForm.level
													}`}
												onChange={(e, data) => {
													if (e) {
														setFeedbackForm({
															...feedbackForm,
															levelID: e.value,
															owner: '',
															activityType: '',
															origin: '',
															enquiryType: '',
															campaign: '',
														});
														setErrorFields({
															...errorFields,
															levelID: '',
														});
													} else {
														setFeedbackForm({
															...feedbackForm,
															levelID: '',
														});
													}
												}}
												value={feedbackForm.levelID}
												classNamePrefix={`${errorFields['levelID']} basic-select`}
												removeClearable={true}
											></ReactSelect>
										</div>
									) : (
										<></>
									)}

									<div className='form-group col-md-12'>
										<label>
											<Translate text={'Add To'} />
										</label>
										<span className='feedback-required-red'>* </span>
										<ReactSelect
											options={formTypes}
											name={'addTo'}
											placeholder={'select add to'}
											onChange={handleSelectChange}
											value={feedbackForm ? feedbackForm.addTo : ''}
											classNamePrefix={`${errorFields['addTo']} basic-select `}
											removeClearable={true}
											isSearchable={false}
										></ReactSelect>
									</div>
									{feedbackForm &&
										feedbackForm.addTo &&
										feedbackForm &&
										feedbackForm.addTo === 'activity' ? (
										<>
											<div className='form-group col-md-12'>
												<label>
													<Translate text={'owner'} />
													<span className='feedback-required-red'>* </span>
												</label>
												<ReactSelect
													options={userOptions}
													name={'owner'}
													placeholder={'select owner'}
													onChange={handleSelectChange}
													value={feedbackForm ? feedbackForm.owner : ''}
													classNamePrefix={`${errorFields['owner']} basic-select`}
													removeClearable={true}
													isSearchable={true}
												></ReactSelect>
											</div>
											<div className='form-group col-md-12'>
												<label>
													<Translate text={'activityType'} />
													<span className='feedback-required-red'>* </span>
												</label>
												<ReactSelect
													options={activityTypeOptions}
													name={'activityType'}
													placeholder={'select activity type'}
													onChange={handleSelectChange}
													value={feedbackForm ? feedbackForm.activityType : ''}
													classNamePrefix={`${errorFields['activityType']} basic-select`}
													removeClearable={true}
													isSearchable={false}
												></ReactSelect>
											</div>
										</>
									) : feedbackForm &&
										feedbackForm.addTo &&
										feedbackForm &&
										feedbackForm.addTo === 'tradeinProInbound' ? (
										<>
											<div className='form-group col-md-12'>
												<label>
													<Translate text={'origin'} />
												</label>
												<ReactSelect
													options={originOptions}
													name={'origin'}
													placeholder={'select origin'}
													onChange={handleSelectChange}
													value={feedbackForm ? feedbackForm.origin : ''}
													classNamePrefix={`basic-select`}
													removeClearable={true}
													isSearchable={false}
												></ReactSelect>
											</div>
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<button
						type='button'
						className={`btn btn-primary float-right`}
						onClick={e => { handleSave(fromPublish ? false : true, handleClose); }}
					>
						{loader ? (
							<span
								className='spinner-border spinner-border-sm mr-1'
								role='status'
								aria-hidden='true'
							></span>
						) : (
							<></>
						)}
						<Translate text={'save'} />
					</button>
					<button
						type='button'
						className='btn btn-default float-left'
						onClick={() => handleClose()}
					>
						<Translate text={'cancel'} />
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default FormSettings;
