import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import moment from 'moment'
import NumberFormat from 'react-number-format';
import { InputText, ReactSelect, ReactTimePicker, InputCheckBox, ReactMultiSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { lookupVM, serviceLookups, levelOptions, markDownLevelsVM, servicePartsVM, displayOptions } from './viewModel'
import { statusColors } from '../../../../services/enum'
import CommonHelper from '../../../../services/common';

const AddLookup = (props) => {

    const [lookup, setLookup] = useState(props.lookup)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newLookup = !_.isEmpty(props.lookup) ? false : true;
    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM);
            if (props.activeLookup === 'slots') {
                newlookupVM.isExpress = false
            }
            if (newLookup && props.activeLookup === 'serviceStatus') {
                newlookupVM.afterComplete = true;
                newlookupVM.displayOption = 'afterComplete';
            }
            setLookup(newlookupVM)
        }
        else {
            if (props.lookup && Boolean(props?.lookup?.default) === false && props.activeLookup === 'serviceStatus') {
                let displayOption = 'afterComplete';
                if (props?.lookup?.beforeComplete === true) {
                    displayOption = 'beforeComplete';
                }
                else if (props?.lookup?.beforeApproval === true) {
                    displayOption = 'beforeApproval';
                }
                else if (props?.lookup?.afterApproval === true) {
                    displayOption = 'afterApproval';
                }
                setLookup({
                    ...lookup,
                    ['displayOption']: displayOption
                })
            }
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handlecolorSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e ? e.value : ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: (data.name === 'level' && e.value === 'oem') ? [props.selectedOEM.id] : '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs
            });
        }
        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handleReactMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })

        setLookup({
            ...lookup,
            [name]: value
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            afterComplete: false,
            beforeComplete: false,
            beforeApproval: false,
            afterApproval: false,
            [e.target.name]: e.target.checked
        })
    }

    const saveLookup = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _regionLookups = [];
        let _groupLookups = [];
        let _clientLookups = []

        let lookupObjData = { ...lookup }

        let activeLookupName = (_.find(serviceLookups, { 'value': props.activeLookup }) ? _.find(serviceLookups, { 'value': props.activeLookup }).name : 'Lookups')
        if (_.isEmpty(lookupObjData['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (!lookupObjData['price'] && props.activeLookup === 'servicePackages') {
            formIsValid = false;
            errors['price'] = errorClass;
        }
        if (!lookupObjData['color'] && (props.activeLookup === 'slots' || props.activeLookup === 'serviceStatus' || props.activeLookup === 'technicianStatus')) {
            formIsValid = false;
            errors['color'] = errorClass;
        }
        if (!lookupObjData['to'] && props.activeLookup === 'slots') {
            formIsValid = false;
            errors['to'] = errorClass;
        }
        if (!lookupObjData['from'] && props.activeLookup === 'slots') {
            formIsValid = false;
            errors['from'] = errorClass;
        }
        if (!lookupObjData['total'] && props.activeLookup === 'slots') {
            formIsValid = false;
            errors['total'] = errorClass;
        }
        if (!lookupObjData['code'] && props.activeLookup === 'markDownLevels') {
            formIsValid = false;
            errors['code'] = errorClass;
        }
        if (!lookupObjData['partCost'] && props.activeLookup === 'serviceParts') {
            formIsValid = false;
            errors['partCost'] = errorClass;
        }
        if (!lookupObjData['partNumber'] && props.activeLookup === 'serviceParts') {
            formIsValid = false;
            errors['partNumber'] = errorClass;
        }
        if (_.isEmpty(lookupObjData['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }

        if (!_.isEmpty(lookupObjData.level) && lookupObjData.level !== 'oem' && _.isEmpty(lookupObjData['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.name.toLowerCase() === lookupObjData.name.toLowerCase() && lookupObjData.levelID.some(a => item.levelID === a))) {            
            Swal.fire(`${activeLookupName} ${CommonHelper.showLocale(props, 'with same name already exists')}`, '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)

        //Remove empty value from lookupObjData object
        for (var propName in lookupObjData) {
            if (lookupObjData[propName] === null || lookupObjData[propName] === undefined || lookupObjData[propName] === '' || lookupObjData[propName] === [] || (_.isObject(lookupObjData[propName]) && _.isEmpty(lookupObjData[propName]))) {
                delete lookupObjData[propName];
            }
        }

        const batch = window.firebase.firestore().batch();
        if (lookupObjData.level === 'oem') {
            const lookupObj = {
                [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level), function (obj) { return { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) }; }), _.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level), function (obj) {
                        return obj.value === lookupObjData.value ? { ..._.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) } : { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/services/${props.dealersettings.client.settingsID}`),
                lookupObj, { merge: true });
        }
        else if (_.isArray(lookupObjData.levelID)) {
            lookupObjData.levelID.forEach(rec => {
                const lookupObj = {
                    [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level && item.levelID === rec), function (obj) { return { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) }; }), _.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM))] :
                        [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level && rec === item.levelID), function (obj) {
                            return obj.value === lookupObjData.value ? { ..._.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) } : { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) };
                        })]
                }
                batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookupObjData.level === 'group' ? 'groups' : lookupObjData.level === 'region' ? 'regions' : 'clients'}/${rec}/services/${rec}`),
                    lookupObj, { merge: true });
            })
        }
        else {
            const lookupObj = {
                [props.activeLookup]: newLookup ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level && item.levelID === lookupObjData.levelID), function (obj) { return { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) }; }), _.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM))] :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => item.level === lookupObjData.level && item.levelID === lookupObjData.levelID), function (obj) {
                        return obj.value === lookupObjData.value ? { ..._.pick(lookupObjData, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) } : { ..._.pick(obj, _.keys(props.activeLookup === 'markDownLevels' ? markDownLevelsVM : props.activeLookup === 'serviceParts' ? servicePartsVM : lookupVM)) };
                    })]
            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/${lookupObjData.level === 'group' ? 'groups' : lookupObjData.level === 'region' ? 'regions' : 'clients'}/${lookupObjData.levelID}/services/${lookupObjData.levelID}`),
                lookupObj, { merge: true });
        }

        if (lookup.level !== 'oem' && (props.activeLookup === 'serviceStatus' || props.activeLookup === 'technicianStatus') && props.allLookups[props.activeLookup].some(a => a.static === true)) {
            const lookupObj = {
                [props.activeLookup]: _.map(props.allLookups[props.activeLookup].filter(item => item.level === 'oem'), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; })

            }
            batch.set(window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/services/${props.dealersettings.client.settingsID}`),
                {
                    ...lookupObj,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid
                }, { merge: true });
        }

        batch.commit()
            .then(snapshot => {
                setLoader(false)
                //update settings date and priority
                // if (!_.isEmpty(props.selectedClient)) {
                window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'Service Dynamic Field Settings has been updated',
                    }, { merge: true })
                // }
                //end 
                toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookupObjData, newLookup)
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
                Swal.fire('Something went wrong', '', 'error')
            });

    }

    const onCurrencyChange = (e, name) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        setLookup({
            ...lookup,
            [name]: e.floatValue
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleStartTimeChange = (val, name) => {
        setLookup({
            ...lookup,
            [name]: moment(val).format('HH:mm'),
            to: moment(val).add(1, 'hours').format('HH:mm')
        });
    };

    const handleEndTimeChange = (val, name) => {
        setLookup({
            ...lookup,
            [name]: moment(val).format('HH:mm')
        });
    };

    const handleCheckChange = async e => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked,
        });
    };

    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                        disabled={(newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? false :
                                            (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? true : false}
                                        maxLength={500}
                                    />
                                </div>
                                {
                                    props.activeLookup === 'slots' ? (
                                        <>
                                            <div className='form-group col-md-6'>
                                                <label><Translate text={'Start Time'} /></label>
                                                <div className='inner-addon right-addon'>
                                                    <i className='bg-icons ico icon-time'></i>

                                                    <ReactTimePicker
                                                        value={!_.isEmpty(lookup.from) ? (typeof lookup.from === 'string') ? moment(lookup.from, 'HH:mm')._d : lookup.from : null}
                                                        name={'from'}
                                                        onChange={handleStartTimeChange}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields["from"]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group col-md-6'>
                                                <label ><Translate text={'End Time'} /></label>
                                                <div className='inner-addon right-addon'>
                                                    <i className='bg-icons ico icon-time'></i>

                                                    <ReactTimePicker
                                                        value={!_.isEmpty(lookup.to) ? (typeof lookup.to === 'string') ? moment(lookup.to, 'HH:mm')._d : lookup.to : null}
                                                        name={'to'}
                                                        onChange={handleEndTimeChange}
                                                        timeIntervals={30}
                                                        placeholder='h:mm aa'
                                                        className={`form-control ${errorFields["to"]}`}
                                                        minTime={moment(lookup.to.seconds)._d}
                                                        maxTime={
                                                            moment(
                                                                CommonHelper.getEndDate(lookup.from)
                                                                    .seconds,
                                                            )._d
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    (props.activeLookup === 'financeStatus' || props.activeLookup === 'serviceStatus' || props.activeLookup === 'technicianStatus' || props.activeLookup === 'slots') ? (
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'Colour'} /></label>
                                            <ReactSelect
                                                options={statusColors}
                                                name={"color"}
                                                placeholder={'select status colour'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.color}
                                                classNamePrefix={`${lookup.disableDelete ? 'select-readonly' : ''} ${errorFields["color"]} basic-select`}
                                                isDisabled={lookup.disableDelete}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'markDownLevels' ? (
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'Markdown Value (%)'} /></label>
                                            <NumberFormat
                                                id={'code'}
                                                allowNegative={true}
                                                thousandSeparator={false}
                                                // prefix={currencySymbol}
                                                placeholder={'Code'}
                                                className={`form-control ${errorFields["code"]}`}
                                                onValueChange={(e) => {
                                                    onCurrencyChange(e, 'code')
                                                }}
                                                value={lookup.code ? lookup.code : null}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'serviceParts' ? (
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'Part Cost'} /></label>
                                            <NumberFormat
                                                id={'partCost'}
                                                decimalScale={2}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                prefix={currencySymbol}
                                                placeholder={currencySymbol}
                                                className={`form-control ${errorFields["partCost"]}`}
                                                onValueChange={(e) => {
                                                    onCurrencyChange(e, 'partCost')
                                                }}
                                                value={lookup.partCost ? lookup.partCost : null}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'serviceParts' ? (
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'Part Number'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'part number'}
                                                className={`form-control ${errorFields["partNumber"]}`}
                                                name="partNumber"
                                                onChange={handleOnChange}
                                                value={lookup.partNumber}
                                            // disabled={disableEdit}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'slots' ? (
                                        <div className="form-group col-md-12">
                                            <label> <Translate text={'No. of Slots'} /></label>
                                            <NumberFormat
                                                id={'total'}
                                                allowNegative={false}
                                                thousandSeparator={true}
                                                // prefix={currencySymbol}
                                                // placeholder={currencySymbol}
                                                decimalScale={3}
                                                className={`form-control ${errorFields["total"]}`}
                                                onValueChange={(e) => {
                                                    onCurrencyChange(e, 'total')
                                                }}
                                                value={lookup.total ? lookup.total : null}
                                            />
                                        </div>
                                    ) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Level'} /></label>
                                    <ReactSelect
                                        options={(props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level })) ? levelOptions :
                                            levelOptions.filter(e => e.index >= _.find(levelOptions, { value: props.dealersettings.level }).index)}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={`${!newLookup ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label ><Translate text={lookup.level === 'group' ? 'Group' : lookup.level === 'region' ? 'Region' : 'client'} /></label>
                                        <ReactMultiSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level === 'individual' ? 'client' : lookup.level}`}
                                            onChange={(rec) => { handleReactMultiSelectChange(rec, 'levelID') }}
                                            value={lookup.levelID ? _.isArray(lookup.levelID) ? lookup.levelID : [lookup.levelID] : []}
                                            classNamePrefix={`${!newLookup ? 'select-readonly' : ''}  ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            isDisabled={!newLookup}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>) : (<></>)
                                }
                                {
                                    props.activeLookup === 'slots' ? (
                                        <>
                                            <div className='switch-list'>
                                                <ul>
                                                    <li>
                                                        {' '}
                                                        <span className='switch switch-sm mr-2'>
                                                            <InputCheckBox
                                                                id='is-express'
                                                                className='switch'
                                                                name={'isExpress'}
                                                                checked={
                                                                    lookup.isExpress
                                                                        ? lookup.isExpress
                                                                        : false
                                                                }
                                                                onChange={handleCheckChange}
                                                            />
                                                            <label htmlFor='is-express'></label>
                                                        </span>
                                                        Express
                                                    </li>
                                                    <li>
                                                        {' '}
                                                        <span className='switch switch-sm mr-2'>
                                                            <InputCheckBox
                                                                id='half-an-hour-slot'
                                                                className='switch'
                                                                name={'halfanHrSlot'}
                                                                checked={
                                                                    lookup.halfanHrSlot
                                                                        ? lookup.halfanHrSlot
                                                                        : false
                                                                }
                                                                onChange={handleCheckChange}
                                                            />
                                                            <label htmlFor='half-an-hour-slot'></label>
                                                        </span>
                                                        1/2 Hour Slot
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    ) : (<></>)
                                }
                                {
                                    props.activeLookup === 'serviceStatus' && Boolean(lookup?.default) === false ? (<>
                                        <div className="form-group col-md-12">
                                            <label >Diplay Option</label>
                                            <ReactSelect
                                                options={displayOptions}
                                                name={"displayOption"}
                                                placeholder={'select option'}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setLookup({
                                                            ...lookup,
                                                            afterComplete: false,
                                                            beforeComplete: false,
                                                            beforeApproval: false,
                                                            afterApproval: false,
                                                            [e.value]: true,
                                                            ['displayOption']: e.value
                                                        })
                                                    }
                                                }}
                                                value={lookup.displayOption}
                                                classNamePrefix={`${errorFields["displayOption"]} basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        {/* <span className={`switch switch-sm float-left mt-2 `}>
                                            <InputCheckBox
                                                className={`switch`}
                                                id={`lookup-afterComplete`}
                                                name={'afterComplete'}
                                                checked={lookup.afterComplete ? lookup.afterComplete : false}
                                                onChange={(e) => { handleCheckedChange(e) }} />
                                            <label htmlFor={`lookup-afterComplete`}>After Complete</label>
                                        </span>
                                        <span className={`switch switch-sm float-left mt-2 `}>
                                            <InputCheckBox
                                                className={`switch`}
                                                id={`lookup-beforeComplete`}
                                                name={'beforeComplete'}
                                                checked={lookup.beforeComplete ? lookup.beforeComplete : false}
                                                onChange={(e) => { handleCheckedChange(e) }} />
                                            <label htmlFor={`lookup-beforeComplete`}>Before Complete</label>
                                        </span>
                                        <span className={`switch switch-sm float-left mt-2 `}>
                                            <InputCheckBox
                                                className={`switch`}
                                                id={`lookup-beforeApproval`}
                                                name={'beforeApproval'}
                                                checked={lookup.beforeApproval ? lookup.beforeApproval : false}
                                                onChange={(e) => { handleCheckedChange(e) }} />
                                            <label htmlFor={`lookup-beforeApproval`}>Before Approval</label>
                                        </span> */}
                                    </>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className={`switch switch-sm float-left mt-2 ${lookup.disableDelete ? 'btn-disable' : (newLookup || props.dealersettings.level === 'oem' || _.isEmpty(props.dealersettings.level) || !_.find(levelOptions, { value: props.dealersettings.level }) || !_.find(levelOptions, { value: lookup.level })) ? '' :
                    (_.find(levelOptions, { value: props.dealersettings.level }).index > _.find(levelOptions, { value: lookup.level }).index) ? 'btn-disable' : ''}`}>
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}><Translate text={'active'} /></label>
                </span>
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddLookup;