import React, { useEffect } from 'react';
import _ from 'lodash';
 
import { ContentOverlay } from '../../components';

import ChatMessageItem from './chatmessageitem';
 
const ChatMessageList = ({ contactChat, messages, loading, sidepanelClose, handleScroll, clientUsers, clientUser, markAsRead }) => {
	useEffect(() => {
		if (messages.length > 0) {
				markAsRead(clientUser)
		}
	}, [messages, clientUser])

	return (
		<div className={'chatApp__convTimeline'} onScroll={handleScroll}>
			{loading ? (
				<div className='mt-5'>
					<ContentOverlay active={true} />
				</div>
			) : (
				_.orderBy(_.uniqBy(messages,'timestamp'), ['timestamp'], ['desc']).map(messageItem => (
					<ChatMessageItem
						key={messageItem.timestamp}
						clientUsers={clientUsers}
						senderAvatar={'a link'}
						messageItem={messageItem}
						sidepanelClose={sidepanelClose}
						contactChat={contactChat}
						clientUser={clientUser}
					/>
				))
			)}
		</div>
	);
};

export default ChatMessageList;
