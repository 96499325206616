import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { ContentOverlay, BarChart, LineChart } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import ReportHelper from '../../oem/reports/reportHelper';
import { dealertargetDynCols } from '../../oem/reports/viewModel';
import Swal from 'sweetalert2';
import { combineLatest } from 'rxjs';
import { doc } from 'rxfire/firestore';



const WeeklyActivityReportPDF = (props) => {
    const [loader, setLoader] = useState(true)
    const [oemSettings, setOEMSettings] = useState({});
    const [leadsRR, setLeadRR] = useState({})
    const [testdriveRR, setTestdriveRR] = useState({})
    const [soldRR, setSoldRR] = useState({})
    const [deliveredRR, setDeliveredRR] = useState({})
    const [newLeads, setNewLeads] = useState([])
    const [wonLeads, setWonLeads] = useState([])
    const [deliveredLeads, setDeliveredLeads] = useState([])
    const [testdrives, setTestDrives] = useState([])
    const [clientTarget, setClientTarget] = useState([])
    const [targetLoader, setTargetLoader] = useState(true)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const year = params.get('year') ? params.get('year') : moment().year();
    const oemid = params.get('oemid') ? params.get('oemid').split(',') : [];
    const region = params.get('region') ? params.get('region').split(',') : [];
    const subregion = params.get('subregion') ? params.get('subregion').split(',') : [];
    const country = params.get('country') ? params.get('country').split(',') : [];
    const dealer = params.get('dealer') ? params.get('dealer').split(',') : [];
    const make = params.get('make') ? params.get('make') : null;
    const model = params.get('model') ? params.get('model').split(',') : [];
    const showdealer = params.get('showdealer');
    const saleType = params.get('saleType');
    const allClient = localStorage.allClients ? JSON.parse(localStorage.allClients) : [];


    useEffect(() => {
        window.firebase.firestore().doc(`clientSettings/${oemid}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    setOEMSettings({
                        ...doc.data(),
                        'documentID': doc.id
                    })
                }
            })
    }, [])

    useEffect(() => {

        const targetPromise = [];
        dealer.forEach(cid => {
            targetPromise.push(doc(window.firebase.firestore().doc(`clients/${cid}/salesForecast${saleType === 'used' ? 'used' : ''}/${cid}`)))
            targetPromise.push(doc(window.firebase.firestore().doc(`targets/client${cid}`)))
        })
        const oemsalesforecastSnap = combineLatest(targetPromise)
            .subscribe(allDocs => {
                let data = [];
                allDocs.forEach(rec => {
                    if (rec.exists) {
                        data.push({
                            ...rec.data(),
                            clientID: rec.data()?.clientID || rec.id
                        })
                    }
                })
                setClientTarget(CommonHelper.removeEmptyOrNull(data));
                setTargetLoader(false)
            });
        return () => {
            oemsalesforecastSnap && oemsalesforecastSnap.unsubscribe();
        }
    }, [])

    // useEffect(() => {
    //     if (_.isEmpty(dynReport))
    //         return
    //     async function getClientSettings() {
    //         const promises = []
    //         const clientpromises = []
    //         const users = [];
    //         const settings = [];
    //         let _timezone = '';
    //         dynReport.clientIDs.forEach(cid => {
    //             promises.push(window.firebase.firestore().doc(`clients/${cid}/currentSettings/${cid}`).get())
    //         })

    //         const snapshots = await Promise.all(promises)
    //         snapshots.forEach(snap => {
    //             if (snap.exists) {
    //                 if (snap.data().users) {
    //                     users.push([...snap.data().users])
    //                 }
    //                 settings.push({
    //                     ...snap.data(),
    //                     id: snap.id
    //                 });
    //             }
    //             else {
    //                 clientpromises.push(window.firebase.firestore().doc(`clients/${snap.id}`).get())
    //             }
    //         });
    //         const clientsnapshots = await Promise.all(clientpromises);
    //         clientsnapshots.forEach(snap => {
    //             if (snap.exists) {
    //                 settings.push({
    //                     ...snap.data(),
    //                     id: snap.id
    //                 });
    //             }
    //         });
    //         if (dynReport.level === 'group') {
    //             const groupsSnapshot = await window.firebase.firestore().collection(`clientSettings/${dynReport.settingsID}/groups`).get();
    //             if (groupsSnapshot.docs.length > 0) {
    //                 var _groups = []
    //                 let i = 0;
    //                 groupsSnapshot.docs.forEach(rec => {
    //                     i++;
    //                     _groups.push({
    //                         ...rec.data(),
    //                         documentID: rec.id,
    //                         id: rec.id,
    //                         sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
    //                     })
    //                 })
    //                 setGroups(_.orderBy(_groups, ['sortOrder'], ['asc']))
    //             }
    //         }
    //         if (dynReport.level === 'region') {
    //             const regionsSnapshot = await window.firebase.firestore().collection(`clientSettings/${dynReport.settingsID}/regions`).get();
    //             if (regionsSnapshot.docs.length > 0) {
    //                 var _regions = []
    //                 let i = 0;
    //                 regionsSnapshot.docs.forEach(rec => {
    //                     i++;
    //                     _regions.push({
    //                         name: rec.data().name,
    //                         documentID: rec.id,
    //                         id: rec.id,
    //                         code: rec.id,
    //                         sortOrder: rec.data().sortOrder && Number(rec.data().sortOrder) > 0 ? Number(rec.data().sortOrder) : i
    //                     })
    //                 })
    //                 setRegions(_.orderBy(_regions, ['sortOrder'], ['asc']))
    //             }
    //         }
    //         const oempromises = []
    //         if (dynReport.oemID) {
    //             oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.oemID}`).get());
    //         }
    //         else if (dynReport.regionID) {
    //             oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.settingsID}/regions/${dynReport.regionID}`).get());
    //         }
    //         else if (dynReport.groupID) {
    //             oempromises.push(window.firebase.firestore().doc(`clientSettings/${dynReport.settingsID}/groups/${dynReport.groupID}`).get());
    //         }
    //         let weekStart = '';
    //         const oemsnapshots = await Promise.all(oempromises)
    //         oemsnapshots.forEach(snap => {
    //             if (snap.exists) {
    //                 setOEMSettings(snap.data());
    //                 _timezone = snap.data().timezone;
    //                 weekStart = snap.data().weekStart;
    //                 setcurrencySymbol(!_.isEmpty(snap.data().currency) ? snap.data().currency.symbol + ' ' : '$ ');
    //                 if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.calcCurrentStatus)
    //                     setcalcCurrentStatus('true');
    //                 if (!_.isEmpty(snap.data().clientSettings) && snap.data().clientSettings.multiTDperLead)
    //                     setMultiTDperLead('true');
    //             }
    //         });
    //         if (_.isEmpty(_timezone))
    //             _timezone = settings.some(e => !_.isEmpty(e.timezone)) ? settings.filter(e => !_.isEmpty(e.timezone))[0].timezone : moment.tz.guess();
    //         setTimezone(_timezone);
    //         const _currentDate = moment().tz(timezone).format('YYYY-MM-DD HH:mm');
    //         if (_date === 'today') {
    //             setDateRange({
    //                 startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('day')._d),
    //                 endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day')._d)
    //             });
    //         }
    //         else if (_date === 'wtd') {
    //             if (!_.isEmpty(weekStart)) {
    //                 moment.updateLocale('en', {
    //                     week: {
    //                         dow: moment().day(weekStart).day(),
    //                     },
    //                 })
    //                 setDateRange({
    //                     startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week')._d),
    //                     endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day')._d),
    //                 });
    //             }
    //             else {
    //                 setDateRange({
    //                     startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).add('day', -6).startOf('day')._d),
    //                     endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('day')._d)
    //                 });
    //             }
    //         }
    //         else if (dynReport.dataParam === 'upcoming' && !_.isEmpty(dynReport.upcomingDays)) {
    //             if (!_.isEmpty(weekStart)) {
    //                 moment.updateLocale('en', {
    //                     week: {
    //                         dow: moment().day(weekStart).day(),
    //                     },
    //                 })
    //             }
    //             if (moment().startOf('week').day() >= 1 && dynReport.upcomingDays.includes('0')) {
    //                 setDateRange({
    //                     startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays.filter(a => a !== '0')))._d),
    //                     endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).endOf('week')._d)
    //                 })
    //             }
    //             else if (moment().startOf('week').day() === 0 && dynReport.upcomingDays.includes('0')) {
    //                 setDateRange({
    //                     startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).startOf('week')._d),
    //                     endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays.filter(a => a !== '0')))._d),
    //                 })
    //             }
    //             else {
    //                 setDateRange({
    //                     startDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.min(dynReport.upcomingDays))._d),
    //                     endDate: window.firebase.firestore.Timestamp.fromDate(moment(_currentDate).day(_.max(dynReport.upcomingDays))._d),
    //                 })
    //             }

    //         }
    //         else {
    //             setDateRange({
    //                 startDate: window.firebase.firestore.Timestamp.fromDate(moment().tz(_timezone).startOf('month')._d),
    //                 endDate: window.firebase.firestore.Timestamp.fromDate(moment().tz(_timezone).endOf('month')._d)
    //             });
    //         }
    //         setDealersettings(settings);
    //         setAllUsers(users);
    //     }
    //     getClientSettings();

    // }, [dynReport]) 

    useEffect(() => {
        if (targetLoader)
            return
        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getSummaryReport',
                params: JSON.stringify({
                    clientID: dealer.join(','),
                    startDate: moment().year(year).startOf('year').format('YYYY-MM-DD'),
                    endDate: moment().year(year).endOf('year').format('YYYY-MM-DD'),
                    hideTradein: true,
                    hideActivities: true,
                    hideWalkin: true
                })
            }).then((response) => {
                setLoader(true)
                //setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data);
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [targetLoader])

    const loadReportData = (reportData) => {
        const data = reportData.filter(a => (make ? (a.mk && a.mk.toLowerCase() === make.toLowerCase()) : true)
            && (model?.length > 0 ? (a.ml && model.some(b => b.toLowerCase() === a.ml.toLowerCase())) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() === 'new') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() !== 'new') : true)
        )
        const weeks = ReportHelper.getAllWeeks({ dealersettings: { client: oemSettings } }, year);
        const months = ReportHelper.getAllMonths();
        const leads = ReportHelper.leadsCounts(data, weeks, year);
        let nextRecord = {};
        let lastRecord = _.find(leads, { weekNo: (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) });
        setLeadRR({
            rr: parseFloat((_.sumBy(leads, 'actual') / (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week())).toFixed(2)),
            lastWeek: lastRecord?.name ? lastRecord?.name : `WK ${year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()}`,
            lastWeekActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: (lastRecord?.actual - lastRecord?.average).toFixed(2)
        })
        setNewLeads(leads);
        const testdrives = ReportHelper.testdriveCounts(data, weeks, year);
        lastRecord = _.find(testdrives, { weekNo: (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) });
        setTestdriveRR({
            rr: parseFloat((_.sumBy(testdrives, 'actual') / (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week())).toFixed(2)),
            lastWeek: lastRecord?.name ? lastRecord?.name : `WK ${year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()}`,
            lastWeekActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: (lastRecord?.actual - lastRecord?.average).toFixed(2)
        })
        setTestDrives(testdrives)
        const soldLeads = ReportHelper.wonCounts(data, months, year, { clients: dealer }, dealer, clientTarget);
        lastRecord = _.find(soldLeads, { monthNo: (year < moment().year() ? 11 : moment().month()) });
        nextRecord = _.find(soldLeads, { monthNo: (year < moment().year() ? 11 : moment().month() + 1) });
        setSoldRR({
            rr: parseFloat((_.sumBy(soldLeads, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonth: lastRecord?.name ? lastRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month()).format('MMM')}`,
            lastMonthActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: lastRecord?.actual - parseFloat((_.sumBy(soldLeads, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            nextForecast: nextRecord?.forecast,
            nextMonth: nextRecord?.name ? nextRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month() + 1).format('MMM')}`,
        })
        setWonLeads(soldLeads)
        const delivereddata = ReportHelper.deliveredCounts(data, months, year, { clients: dealer }, dealer, clientTarget);
        lastRecord = _.find(delivereddata, { monthNo: (year < moment().year() ? 11 : moment().month()) });
        nextRecord = _.find(delivereddata, { monthNo: (year < moment().year() ? 11 : moment().month() + 1) });
        setDeliveredRR({
            rr: parseFloat((_.sumBy(delivereddata, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonth: lastRecord?.name ? lastRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month()).format('MMM')}`,
            lastMonthActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: lastRecord?.actual - parseFloat((_.sumBy(delivereddata, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonthYTD: _.sumBy(delivereddata.filter(a => a.monthNo <= (year < moment().year() ? 11 : moment().month())), 'actual'),
            lastMonthTargetYTD: _.sumBy(delivereddata.filter(a => a.monthNo <= (year < moment().year() ? 11 : moment().month())), 'target'),
            nextForecast: nextRecord?.forecast,
            nextTarget: nextRecord?.target,
            nextMonth: nextRecord?.name ? nextRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month() + 1).format('MMM')}`,
        })
        setDeliveredLeads(delivereddata)
        setLoader(false)
    }

    return (loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        oemSettings && oemSettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={oemSettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{oemSettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> Weekly Activity Report - {saleType === 'new' ? 'New' : 'Qualified'}<br></br>
                                    <span> {moment().format('DD MMMM YYYY')}</span>
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
                        {
                            allClient && (region.length > 0 || subregion.length > 0 || country.length > 0 || showdealer || make || model.length > 0) ? (
                                <div className="report-filter-data-list mt-2">
                                    {
                                        region.length > 0 ? (<>
                                            <strong>Region :</strong> <span>{_.uniq(allClient.filter(a => region.includes(a.region)).map(r => { return r.regionName })).join(',')}</span>
                                        </>) : (<></>)
                                    }
                                    {
                                        subregion.length > 0 ? (<>
                                            <strong>Subregion :</strong> <span>{_.uniq(allClient.filter(a => subregion.includes(a.subregion)).map(r => { return r.subregionName })).join(',')}</span>
                                        </>) : (<></>)
                                    }
                                    {
                                        country.length > 0 ? (<>
                                            <strong>Country :</strong> <span>{_.uniq(allClient.filter(a => country.includes(a.country)).map(r => { return r.countryName })).join(',')}</span>
                                        </>) : (<></>)
                                    }
                                    {
                                        dealer.length > 0 && showdealer ? (<>
                                            <strong>Dealer :</strong> <span>{_.uniq(allClient.filter(a => dealer.includes(a.id)).map(r => { return r.name })).join(',')}</span>
                                        </>) : (<></>)
                                    }
                                    {
                                        make ? (<>
                                            <strong>Make :</strong> <span>{make}</span>
                                        </>) : (<></>)
                                    }
                                    {
                                        model.length > 0 ? (<>
                                            <strong>Model :</strong> <span>{_.uniq(model).join(',')}</span>
                                        </>) : (<></>)
                                    }
                                </div>
                            ) : (<></>)
                        }

                        <div className="table-wraper">
                            <div className="text-center mc-chart-box-border">
                                <LineChart
                                    id={`weekly-report-line-chart-new-leads`}
                                    height={`450px`}
                                    data={newLeads}
                                    dynamicColumns={dealertargetDynCols}
                                    xCategory={'name'}
                                    columns={['actual', 'average']}
                                    handleShowEnquiryList={(val, rec) => {
                                    }}
                                    title={'Leads'}
                                    hideLabel={true}
                                    minGridDistance={10}
                                    labelRotation={90}
                                />
                                <div className='mc-bottom-box'>
                                    {leadsRR.year} Run Rate: <strong>{leadsRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last week ({leadsRR.lastWeek}): <strong>{leadsRR.lastWeekActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{leadsRR.delta}</strong>
                                </div>
                            </div>
                            <div className="text-center mc-chart-box-border ">
                                <LineChart
                                    id={`weekly-report-line-chart-test-drives`}
                                    height={`450px`}
                                    data={testdrives}
                                    dynamicColumns={dealertargetDynCols}
                                    xCategory={'name'}
                                    columns={['actual', 'average']}
                                    handleShowEnquiryList={(val, rec) => {
                                    }}
                                    title={'Test Drives'}
                                    hideLabel={true}
                                    minGridDistance={10}
                                    labelRotation={90}
                                />
                                <div className='mc-bottom-box'>
                                    {testdriveRR.year} Run Rate: <strong>{testdriveRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last week ({testdriveRR.lastWeek}): <strong>{testdriveRR.lastWeekActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{testdriveRR.delta}</strong>
                                </div>
                            </div>
                            <div className="text-center mc-chart-box-border">
                                <LineChart
                                    id={`weekly-report-line-chart-won-leads`}
                                    height={`450px`}
                                    data={wonLeads}
                                    dynamicColumns={dealertargetDynCols}
                                    xCategory={'name'}
                                    columns={['actual', 'forecast', 'wonTarget']}
                                    handleShowEnquiryList={(val, rec) => {
                                    }}
                                    title={'Orders'}
                                    hideLabel={true}
                                />
                                <div className='mc-bottom-box'>
                                    {soldRR.year} Run Rate: <strong>{soldRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last Month ({soldRR.lastMonth}): <strong>{soldRR.lastMonthActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{soldRR.delta}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Next Month ({soldRR.nextMonth}) Forecast: <strong>{soldRR.nextForecast}</strong>
                                </div>
                            </div>
                            <div className="text-center mc-chart-box-border">
                                <BarChart
                                    id={`weekly-report-line-chart-delivered-leads`}
                                    height={`450px`}
                                    data={deliveredLeads}
                                    columns={['target', 'retailedActual']}
                                    lineColumns={['cummulativeTarget', 'cummulativeActual', 'forecast']}
                                    dynamicColumns={dealertargetDynCols}
                                    xCategory={'name'}
                                    handleShowEnquiryList={(val, rec) => {
                                    }}
                                    hideBullet={true}
                                    dynColor={true}
                                    title={'Retailed'}
                                />
                                <div className='mc-bottom-box'>
                                    {deliveredRR.year} Run Rate: <strong>{deliveredRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last Month ({deliveredRR.lastMonth}): <strong>{deliveredRR.lastMonthActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{deliveredRR.delta}</strong><br />
                                    {deliveredRR.lastMonth} YTD - Actual: <strong>{deliveredRR.lastMonthYTD}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;{deliveredRR.lastMonth} YTD - Target: <strong>{deliveredRR.lastMonthTargetYTD}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{deliveredRR.lastMonthYTD - deliveredRR.lastMonthTargetYTD}</strong><br />
                                    Next Month ({deliveredRR.nextMonth}) Forecast: <strong>{deliveredRR.nextForecast}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Next Month ({deliveredRR.nextMonth}) Target: <strong>{deliveredRR.nextTarget}</strong>
                                </div>
                            </div>
                        </div>
                    </>)
                }
            </section>
        </div>
    </>)
}

export default WeeklyActivityReportPDF;