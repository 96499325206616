import React, { useState, useEffect } from 'react'
import "../../../../styles/dailyActivity.scss";
import _ from 'lodash'
import moment from 'moment';

/** COMPONENTS */
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';

const SummaryReportByUsers = (props) => {
    const [loader, setLoader] = useState(true)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [client, setClient] = useState({});
    const [clientSettings, setClientSettings] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [tempData, setTempData] = useState({ enquiries: [] })
    const [countsCalculated, setCountsCalculated] = useState(false)
    const [makeAllData, setMakeAllData] = useState([]);
    const [userAllData, setUserAllData] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');
    _date = _date ? _date : moment().format('YYYY-MM-DD');

    let momentMonths = moment.monthsShort()
    // let momentMonths = [moment().format('MMM')]
    let months = []
    momentMonths.forEach((monthName, index) => {
        var monthDate = moment(monthName, 'MMM')
        if (moment().month() + 1 >= monthDate.month() + 1) {
            var objectToAdd = {}
            var startDate = window.firebase.firestore.Timestamp.fromDate(monthDate.startOf('month').toDate())
            var endDate = window.firebase.firestore.Timestamp.fromDate(monthDate.endOf('month').toDate())

            objectToAdd.title = monthName;
            objectToAdd.number = index + 1;
            objectToAdd.startDate = startDate;
            objectToAdd.endDate = endDate;
            months.push(objectToAdd)
        }
    })

    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    })

    let weekStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('week')._d)
    let weekEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('week')._d)
    let monthStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('month')._d)
    let monthEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('month')._d)
    let yearStart = window.firebase.firestore.Timestamp.fromDate(moment(_date).startOf('year')._d)
    let yearEnd = window.firebase.firestore.Timestamp.fromDate(moment(_date).endOf('year')._d)


    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().doc(`clients/${props.clientid}`).get()
            .then(doc => {
                if (doc.exists && doc.data()?.projectId === window.secondaryProjectId) {
                    setFirestoreDB(window.firebase2)
                }
                else {
                    setFirestoreDB(window.firebase)
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        const getData = async () => {
            if (!refreshData)
                return

            setLoader(true)

            let refOpenEnquiryPromises = []
            let refWonEnquiryPromises = []
            let refLostEnquiryPromises = []
            let refDeliveredEnquiryPromises = []
            const testDrivePromises = []
            const actPromises = []
            const walkinPromises = []

            let refOpenEnquiryData = []
            let refWonEnquiryData = []
            let refLostEnquiryData = []
            let refDeliveredEnquiryData = []
            const allTestDrives = []
            const allAct = []
            const allWalkin = []

            let allEnqs = []

            months.forEach((monthData) => {
                refOpenEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('addedDate', '>=', monthData.startDate)
                    .where('addedDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                refWonEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'won')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('wonDate', '>=', monthData.startDate)
                    .where('wonDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                // refLostEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                //     .where('status', '==', 'lost')
                //     // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                //     .where('clientID', '==', props.clientid)
                //     .where('lostDate', '>=', monthData.startDate)
                //     .where('lostDate', '<=', monthData.endDate)
                //     .where('isDeleted', '==', false)
                //     .get())

                refDeliveredEnquiryPromises.push(firestoreDB.firestore().collection('enquiries')
                    .where('status', '==', 'delivered')
                    // .where('pipeline', 'in', _.map(props.pipeline.filter(item => item.value !== 'LeadsBucket'), function (e) { return e.value; }))
                    .where('clientID', '==', props.clientid)
                    .where('deliveredDate', '>=', monthData.startDate)
                    .where('deliveredDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .get())

                testDrivePromises.push(firestoreDB.firestore().collection('testdrives')
                    .where('clientID', '==', props.clientid)
                    .where('startDate', '>=', monthData.startDate)
                    .where('startDate', '<=', monthData.endDate)
                    .where('isDeleted', '==', false)
                    .orderBy('startDate', 'desc')
                    .get())

                actPromises.push(firestoreDB.firestore().collection(`activities`)
                    .where('clientID', '==', props.clientid)
                    .where('type', '==', 'activity')
                    .where('isDeleted', '==', false)
                    .where('isDone', '==', true)
                    .where('modifiedDate', '>=', monthData.startDate)
                    .where('modifiedDate', '<=', monthData.endDate)
                    .get())

                walkinPromises.push(firestoreDB.firestore().collectionGroup(`walkins`)
                    .where('clientID', '==', props.clientid)
                    .where('walkinDate', '>=', monthData.startDate)
                    .where('walkinDate', '<=', monthData.endDate)
                    .get())
            })

            const snapshots1 = await Promise.all(refOpenEnquiryPromises)
            snapshots1.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refOpenEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots2 = await Promise.all(refWonEnquiryPromises)
            snapshots2.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refWonEnquiryData.push(doc.data())
                    });
                }
            })

            // const snapshots3 = await Promise.all(refLostEnquiryPromises)
            // snapshots3.forEach((snap, index) => {
            //     if (snap.size > 0) {
            //         snap.forEach((doc) => {
            //             refLostEnquiryData.push(doc.data())
            //         });
            //     }
            // })

            const snapshots4 = await Promise.all(refDeliveredEnquiryPromises)
            snapshots4.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        refDeliveredEnquiryData.push(doc.data())
                    });
                }
            })

            const snapshots5 = await Promise.all(testDrivePromises)
            snapshots5.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allTestDrives.push(doc.data())
                    });
                }
            })

            const snapshots6 = await Promise.all(actPromises)
            snapshots6.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allAct.push(doc.data())
                    });
                }
            })

            const snapshots7 = await Promise.all(walkinPromises)
            snapshots7.forEach((snap, index) => {
                if (snap.size > 0) {
                    snap.forEach((doc) => {
                        allWalkin.push(doc.data())
                    });
                }
            })

            let refClientData = await window.firebase.firestore().collection('clients').doc(props.clientid).get()
            refClientData = refClientData.data()

            allEnqs.push(refOpenEnquiryData)
            allEnqs.push(refWonEnquiryData)
            allEnqs.push([])
            allEnqs.push(refDeliveredEnquiryData)

            var _enquiries = [];
            for (let i = 0; i < 4; i++) {
                allEnqs[i].forEach(el => {
                    if (!_enquiries.some(e => e.documentID === el.documentID)) {
                        _enquiries.push({
                            ...el,
                            walkins: allWalkin.filter(a => a.enquiryID === el.documentID)
                        })
                    }
                });
            }

            setTestdrives(allTestDrives)
            setTempData({
                enquiries: _enquiries,
                testdrives: allTestDrives,
                activities: allAct
            })
            setClient(refClientData)
            if (_enquiries.length === 0) {
                setLoader(false)
                setRefreshClientData(true)
                setRefreshData(false)
            }
        }
        getData()
    }, [refreshData, firestoreDB])

    useEffect(() => {
        async function getClientSettings(clientID) {
            window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get().then(doc => {
                //  console.log('currentSettings', doc.data())
                let settingsDoc = doc.data();
                setClientSettings(settingsDoc);
            });
        }

        if (!_.isEmpty(client) && !_.isEmpty(client.documentID)) {
            getClientSettings(client.documentID)
        }
    }, [client, pipelineEnquiries])

    useEffect(() => {
        async function fetchAdditionalData(enquiries, testdrives, activities) {
            const testDrivesPromises = []
            let settings
            // fetch client settings first
            const doc = await window.firebase.firestore().doc(`clients/${client.documentID}/currentSettings/${client.documentID}`).get()
            // console.log('currentSettings', doc.data())
            let settingsDoc = doc.data();
            settings = settingsDoc

            // checking for those test drives whose enquiry is not fetched yet 
            testdrives.filter((testDrive) => testDrive.enquiryID).forEach((testDrive) => {
                if (!enquiries.some((enq) => enq.documentID === testDrive.enquiryID)) {
                    testDrivesPromises.push(firestoreDB.firestore().doc(`enquiries/${testDrive.enquiryID}`).get())
                }
            })
            const snapshots = await Promise.all(testDrivesPromises)
            let _enquiries = []
            snapshots.forEach(snap => {
                const dataDoc = { ...snap.data(), 'documentID': snap.id };
                if (dataDoc.isDeleted === false && dataDoc.pipeline !== 'LeadsBucket') {
                    _enquiries.push(dataDoc)
                }
            })

            enquiries = _.uniqBy([...enquiries, ..._enquiries], 'documentID')

            let enqWithTestDrives = enquiries.map(enq => {
                let managerIntro = false;
                if (!_.isEmpty(enq.dynamicFields) && settings && settings.enquiryOptionsDF) {
                    let enquiryOptionsDF = settings.enquiryOptionsDF
                    let managerIntroductionDoneObj = enquiryOptionsDF.filter(obj => obj.name === "Manager-Introduction" || obj.value === "Manager-Introduction")
                    if (managerIntroductionDoneObj.length > 0) {
                        managerIntroductionDoneObj = managerIntroductionDoneObj[0]
                        // check if it includes that managerIntroductionDoneObj
                        if (enq.dynamicFields.hasOwnProperty(managerIntroductionDoneObj.value) && enq.dynamicFields[managerIntroductionDoneObj.value] === true) {
                            managerIntro = true;
                        }
                    }
                }

                return {
                    ...enq,
                    testdrives: testdrives.filter(w => w.enquiryID === enq.documentID),
                    activities: activities.filter(w => w.enquiryID === enq.documentID),
                    isManagerIntro: managerIntro
                }
            })
            let eligibleEnq = enqWithTestDrives.filter(enq => !_.isEmpty(enq.requirement))

            setEnquiries(eligibleEnq)
            setClientSettings(settings)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
        }
        if (tempData.enquiries.length > 0)
            fetchAdditionalData(tempData.enquiries, tempData.testdrives, tempData.activities);
        else {
            setEnquiries(tempData.enquiries)
            setLoader(false)
            setRefreshClientData(true)
            setRefreshData(false)
        }
    }, [tempData])

    useEffect(() => {
        if (!_.isArray(pipelineEnquiries) || _.isEmpty(pipelineEnquiries) || _.isEmpty(client))
            return

        let str = `BMW:New;MINI:New;All:Used`
        str = client && client.clientSettings && client.clientSettings.summaryReportByUsers && client.clientSettings.summaryReportByUsers.make
        const a = str.split(`;`)
        let allMakes = []
        a.forEach(grp => {
            let data = grp.split(':')
            allMakes.push({
                make: data[0].toLowerCase(),
                saleType: data[1].toLowerCase(),
            })
        })

        let totalCounts = {
            yearAllEnq: [],
            weekAllEnq: [],
            monthAllEnq: [],
            info: 'totals',
            yearWalkin: [],
            weekWalkin: [],
            monthWalkin: [],
            yearPhoneIn: [],
            weekPhoneIn: [],
            monthPhoneIn: [],
            yearHeadOffice: [],
            weekHeadOffice: [],
            monthHeadOffice: [],
            yearAutogate: [],
            weekAutogate: [],
            monthAutogate: [],
            yearInternet: [],
            weekInternet: [],
            monthInternet: [],
            yearOutbound: [],
            weekOutbound: [],
            monthOutbound: [],
            yearOther: [],
            weekOther: [],
            monthOther: [],
            yearTestdriveCount: [],
            weekTestdriveCount: [],
            monthTestdriveCount: [],
            yearSale: [],
            weekSale: [],
            monthSale: [],
            yearFaceToFace: [],
            weekFaceToFace: [],
            monthFaceToFace: [],
            yearAllFollowups: 0,
            weekAllFollowups: 0,
            monthAllFollowups: 0,
            yearAllFollowupLeads: 0,
            weekAllFollowupLeads: 0,
            monthAllFollowupLeads: 0,
            yearManagerIntro: [],
            weekManagerIntro: [],
            monthManagerIntro: [],
        }

        let allData = []

        allMakes.forEach(item => {
            let eligibleEnq = pipelineEnquiries
            let makeEnq = eligibleEnq.filter(enq => {
                let makeReturnVal = false;
                let saleTypeReturnVal = false;
                let salesType = ''
                let make = ''
                if (!_.isEmpty(enq.requirement.stock)) {
                    salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                        enq.requirement.stock.saleType, '');
                    make = enq.requirement.stock.make
                } else if (enq.requirement.make && enq.requirement.model) {
                    salesType = CommonHelper.getNameByValue(clientSettings.settings.salesType,
                        enq.requirement.saleType, '');
                    make = enq.requirement.make
                }
                // CHECK FOR MAKES
                if (item.make === 'all') {
                    // all no make check
                    makeReturnVal = true
                } else {
                    // check with make
                    if (make.toLowerCase().includes(item.make)) {
                        makeReturnVal = true
                    } else {
                        makeReturnVal = false
                    }
                }

                // CHECK FOR SALE TYPES
                if (item.saleType === 'all') {
                    // all no make check
                    saleTypeReturnVal = true
                } else if (item.saleType === 'new') {
                    if (!salesType.toLowerCase().includes('preowned')) {
                        saleTypeReturnVal = true
                    } else {
                        saleTypeReturnVal = false
                    }
                } else if (item.saleType === 'used') {
                    if (salesType.toLowerCase().includes('preowned')) {
                        saleTypeReturnVal = true
                    } else {
                        saleTypeReturnVal = false
                    }
                }
                return (saleTypeReturnVal && makeReturnVal)
            })

            let makeCounts = {
                yearAllEnq: makeEnq.filter(a => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= yearStart.seconds
                    && a.addedDate.seconds <= yearEnd.seconds),
                weekAllEnq: makeEnq.filter(a => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= weekStart.seconds
                    && a.addedDate.seconds <= weekEnd.seconds),
                monthAllEnq: makeEnq.filter(a => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.addedDate)
                    && a.addedDate.seconds >= monthStart.seconds
                    && a.addedDate.seconds <= monthEnd.seconds),
                info: item
            }

            makeCounts = {
                ...makeCounts,
                yearWalkin: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
                weekWalkin: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
                monthWalkin: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'walk')),
                yearPhoneIn: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
                weekPhoneIn: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
                monthPhoneIn: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'phone')),
                yearHeadOffice: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
                weekHeadOffice: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
                monthHeadOffice: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'headoffice')),
                yearAutogate: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
                weekAutogate: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
                monthAutogate: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'autogate')),
                yearInternet: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
                weekInternet: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
                monthInternet: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'internet')),
                yearOutbound: makeCounts.yearAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
                weekOutbound: makeCounts.weekAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
                monthOutbound: makeCounts.monthAllEnq.filter(a => checkEnqType(a.enquiryType, 'outbound')),
                yearOther: makeCounts.yearAllEnq.filter(a =>
                    !checkEnqType(a.enquiryType, 'outbound')
                    && !checkEnqType(a.enquiryType, 'internet')
                    && !checkEnqType(a.enquiryType, 'autogate')
                    && !checkEnqType(a.enquiryType, 'headoffice')
                    && !checkEnqType(a.enquiryType, 'walk')
                    && !checkEnqType(a.enquiryType, 'phone')),
                weekOther: makeCounts.weekAllEnq.filter(a =>
                    !checkEnqType(a.enquiryType, 'outbound')
                    && !checkEnqType(a.enquiryType, 'internet')
                    && !checkEnqType(a.enquiryType, 'autogate')
                    && !checkEnqType(a.enquiryType, 'headoffice')
                    && !checkEnqType(a.enquiryType, 'walk')
                    && !checkEnqType(a.enquiryType, 'phone')),
                monthOther: makeCounts.monthAllEnq.filter(a =>
                    !checkEnqType(a.enquiryType, 'outbound')
                    && !checkEnqType(a.enquiryType, 'internet')
                    && !checkEnqType(a.enquiryType, 'autogate')
                    && !checkEnqType(a.enquiryType, 'headoffice')
                    && !checkEnqType(a.enquiryType, 'walk')
                    && !checkEnqType(a.enquiryType, 'phone')),
                yearTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.testdrives)
                    && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                        && t.startDate.seconds >= yearStart.seconds
                        && t.startDate.seconds <= yearEnd.seconds)
                ),
                weekTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.testdrives)
                    && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                        && t.startDate.seconds >= weekStart.seconds
                        && t.startDate.seconds <= weekEnd.seconds)
                ),
                monthTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                    && !_.isEmpty(a.testdrives)
                    && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                        && t.startDate.seconds >= monthStart.seconds
                        && t.startDate.seconds <= monthEnd.seconds)
                ),
                yearSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= yearStart.seconds
                    && a.wonDate.seconds <= yearEnd.seconds),
                weekSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= weekStart.seconds
                    && a.wonDate.seconds <= weekEnd.seconds),
                monthSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                    && !_.isEmpty(a.wonDate)
                    && a.wonDate.seconds >= monthStart.seconds
                    && a.wonDate.seconds <= monthEnd.seconds),
                yearFaceToFace: makeCounts.yearAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
                weekFaceToFace: makeCounts.weekAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
                monthFaceToFace: makeCounts.monthAllEnq.filter(a => a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0),
                yearAllFollowups: calculateAllFollowups(makeCounts.yearAllEnq),
                weekAllFollowups: calculateAllFollowups(makeCounts.weekAllEnq),
                monthAllFollowups: calculateAllFollowups(makeCounts.monthAllEnq),
                yearAllFollowupLeads: calculateAllFollowupLeads(makeCounts.yearAllEnq),
                weekAllFollowupLeads: calculateAllFollowupLeads(makeCounts.weekAllEnq),
                monthAllFollowupLeads: calculateAllFollowupLeads(makeCounts.monthAllEnq),
                yearManagerIntro: makeCounts.yearAllEnq.filter(a => a.isManagerIntro),
                weekManagerIntro: makeCounts.weekAllEnq.filter(a => a.isManagerIntro),
                monthManagerIntro: makeCounts.monthAllEnq.filter(a => a.isManagerIntro),
            }
            allData.push(makeCounts)
        })

        let allUsersData = []
        clientSettings.users.forEach(user => {
            let totalUserCounts = {
                yearAllEnq: [],
                weekAllEnq: [],
                monthAllEnq: [],
                info: 'totals',
                yearWalkin: [],
                weekWalkin: [],
                monthWalkin: [],
                yearPhoneIn: [],
                weekPhoneIn: [],
                monthPhoneIn: [],
                yearHeadOffice: [],
                weekHeadOffice: [],
                monthHeadOffice: [],
                yearAutogate: [],
                weekAutogate: [],
                monthAutogate: [],
                yearInternet: [],
                weekInternet: [],
                monthInternet: [],
                yearOutbound: [],
                weekOutbound: [],
                monthOutbound: [],
                yearOther: [],
                weekOther: [],
                monthOther: [],
                yearTestdriveCount: [],
                weekTestdriveCount: [],
                monthTestdriveCount: [],
                yearSale: [],
                weekSale: [],
                monthSale: [],
                yearFaceToFace: [],
                weekFaceToFace: [],
                monthFaceToFace: [],
                yearAllFollowups: 0,
                weekAllFollowups: 0,
                monthAllFollowups: 0,
                yearAllFollowupLeads: 0,
                weekAllFollowupLeads: 0,
                monthAllFollowupLeads: 0,
                yearManagerIntro: [],
                weekManagerIntro: [],
                monthManagerIntro: [],
            };

            let allData = [];

            allMakes.forEach(item => {
                let eligibleEnq = pipelineEnquiries;
                let makeEnq = eligibleEnq.filter(enq => {
                    let makeReturnVal = false;
                    let saleTypeReturnVal = false;
                    let salesType = '';
                    let make = '';
                    if (!_.isEmpty(enq.requirement.stock)) {
                        salesType = CommonHelper.getNameByValue(
                            clientSettings.settings.salesType,
                            enq.requirement.stock.saleType,
                            '',
                        );
                        make = enq.requirement.stock.make;
                    } else if (enq.requirement.make && enq.requirement.model) {
                        salesType = CommonHelper.getNameByValue(
                            clientSettings.settings.salesType,
                            enq.requirement.saleType,
                            '',
                        );
                        make = enq.requirement.make;
                    }
                    // CHECK FOR MAKES
                    if (item.make === 'all') {
                        // all no make check
                        makeReturnVal = true;
                    } else {
                        // check with make
                        if (make.toLowerCase().includes(item.make)) {
                            makeReturnVal = true;
                        } else {
                            makeReturnVal = false;
                        }
                    }

                    // CHECK FOR SALE TYPES
                    if (item.saleType === 'all') {
                        // all no make check
                        saleTypeReturnVal = true;
                    } else if (item.saleType === 'new') {
                        if (!salesType.toLowerCase().includes('preowned')) {
                            saleTypeReturnVal = true;
                        } else {
                            saleTypeReturnVal = false;
                        }
                    } else if (item.saleType === 'used') {
                        if (salesType.toLowerCase().includes('preowned')) {
                            saleTypeReturnVal = true;
                        } else {
                            saleTypeReturnVal = false;
                        }
                    }
                    return saleTypeReturnVal && makeReturnVal;
                });

                let makeCounts = {
                    yearAllEnq: makeEnq.filter(
                        a =>
                            a.pipeline !== 'LeadsBucket' &&
                            !_.isEmpty(a.addedDate) &&
                            a.addedDate.seconds >= yearStart.seconds &&
                            a.addedDate.seconds <= yearEnd.seconds &&
                            user.id === a.owner,
                    ),
                    weekAllEnq: makeEnq.filter(
                        a =>
                            a.pipeline !== 'LeadsBucket' &&
                            !_.isEmpty(a.addedDate) &&
                            a.addedDate.seconds >= weekStart.seconds &&
                            a.addedDate.seconds <= weekEnd.seconds &&
                            user.id === a.owner,
                    ),
                    monthAllEnq: makeEnq.filter(
                        a =>
                            a.pipeline !== 'LeadsBucket' &&
                            !_.isEmpty(a.addedDate) &&
                            a.addedDate.seconds >= monthStart.seconds &&
                            a.addedDate.seconds <= monthEnd.seconds &&
                            user.id === a.owner,
                    ),
                    info: item,
                };

                makeCounts = {
                    ...makeCounts,
                    yearWalkin: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'walk'),
                    ),
                    weekWalkin: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'walk'),
                    ),
                    monthWalkin: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'walk'),
                    ),
                    yearPhoneIn: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'phone'),
                    ),
                    weekPhoneIn: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'phone'),
                    ),
                    monthPhoneIn: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'phone'),
                    ),
                    yearHeadOffice: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'headoffice'),
                    ),
                    weekHeadOffice: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'headoffice'),
                    ),
                    monthHeadOffice: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'headoffice'),
                    ),
                    yearAutogate: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'autogate'),
                    ),
                    weekAutogate: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'autogate'),
                    ),
                    monthAutogate: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'autogate'),
                    ),
                    yearInternet: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'internet'),
                    ),
                    weekInternet: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'internet'),
                    ),
                    monthInternet: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'internet'),
                    ),
                    yearOutbound: makeCounts.yearAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'outbound'),
                    ),
                    weekOutbound: makeCounts.weekAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'outbound'),
                    ),
                    monthOutbound: makeCounts.monthAllEnq.filter(a =>
                        checkEnqType(a.enquiryType, 'outbound'),
                    ),
                    yearOther: makeCounts.yearAllEnq.filter(
                        a =>
                            !checkEnqType(a.enquiryType, 'outbound') &&
                            !checkEnqType(a.enquiryType, 'internet') &&
                            !checkEnqType(a.enquiryType, 'autogate') &&
                            !checkEnqType(a.enquiryType, 'headoffice') &&
                            !checkEnqType(a.enquiryType, 'walk') &&
                            !checkEnqType(a.enquiryType, 'phone'),
                    ),
                    weekOther: makeCounts.weekAllEnq.filter(
                        a =>
                            !checkEnqType(a.enquiryType, 'outbound') &&
                            !checkEnqType(a.enquiryType, 'internet') &&
                            !checkEnqType(a.enquiryType, 'autogate') &&
                            !checkEnqType(a.enquiryType, 'headoffice') &&
                            !checkEnqType(a.enquiryType, 'walk') &&
                            !checkEnqType(a.enquiryType, 'phone'),
                    ),
                    monthOther: makeCounts.monthAllEnq.filter(
                        a =>
                            !checkEnqType(a.enquiryType, 'outbound') &&
                            !checkEnqType(a.enquiryType, 'internet') &&
                            !checkEnqType(a.enquiryType, 'autogate') &&
                            !checkEnqType(a.enquiryType, 'headoffice') &&
                            !checkEnqType(a.enquiryType, 'walk') &&
                            !checkEnqType(a.enquiryType, 'phone'),
                    ),
                    yearTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                        && !_.isEmpty(a.testdrives)
                        && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                            && t.startDate.seconds >= yearStart.seconds
                            && t.startDate.seconds <= yearEnd.seconds)
                    ),
                    weekTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                        && !_.isEmpty(a.testdrives)
                        && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                            && t.startDate.seconds >= weekStart.seconds
                            && t.startDate.seconds <= weekEnd.seconds)
                    ),
                    monthTestdriveCount: makeEnq.filter((a) => a.pipeline !== "LeadsBucket"
                        && !_.isEmpty(a.testdrives)
                        && a.testdrives.some((t) => !_.isEmpty(t.startDate)
                            && t.startDate.seconds >= monthStart.seconds
                            && t.startDate.seconds <= monthEnd.seconds)
                    ),
                    yearSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                        && !_.isEmpty(a.wonDate)
                        && a.wonDate.seconds >= yearStart.seconds
                        && a.wonDate.seconds <= yearEnd.seconds),
                    weekSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                        && !_.isEmpty(a.wonDate)
                        && a.wonDate.seconds >= weekStart.seconds
                        && a.wonDate.seconds <= weekEnd.seconds),
                    monthSale: makeEnq.filter(a => (a.status === 'won' || a.status === 'delivered')
                        && !_.isEmpty(a.wonDate)
                        && a.wonDate.seconds >= monthStart.seconds
                        && a.wonDate.seconds <= monthEnd.seconds),
                    yearFaceToFace: makeCounts.yearAllEnq.filter(
                        a =>
                            a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0,
                    ),
                    weekFaceToFace: makeCounts.weekAllEnq.filter(
                        a =>
                            a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0,
                    ),
                    monthFaceToFace: makeCounts.monthAllEnq.filter(
                        a =>
                            a.soldDate || a.testdrives.length > 0 || a.walkins.length > 0,
                    ),
                    yearAllFollowups: calculateAllFollowups(makeCounts.yearAllEnq),
                    weekAllFollowups: calculateAllFollowups(makeCounts.weekAllEnq),
                    monthAllFollowups: calculateAllFollowups(makeCounts.monthAllEnq),
                    yearAllFollowupLeads: calculateAllFollowupLeads(
                        makeCounts.yearAllEnq,
                    ),
                    weekAllFollowupLeads: calculateAllFollowupLeads(
                        makeCounts.weekAllEnq,
                    ),
                    monthAllFollowupLeads: calculateAllFollowupLeads(
                        makeCounts.monthAllEnq,
                    ),
                    yearManagerIntro: makeCounts.yearAllEnq.filter(
                        a => a.isManagerIntro,
                    ),
                    weekManagerIntro: makeCounts.weekAllEnq.filter(
                        a => a.isManagerIntro,
                    ),
                    monthManagerIntro: makeCounts.monthAllEnq.filter(
                        a => a.isManagerIntro,
                    ),
                };
                allData.push(makeCounts);
            });
            allData.forEach(data => {
                totalUserCounts.yearAllEnq = _.uniqBy(
                    [...totalUserCounts.yearAllEnq, ...data.yearAllEnq],
                    'documentID',
                );
                totalUserCounts.weekAllEnq = _.uniqBy(
                    [...totalUserCounts.weekAllEnq, ...data.weekAllEnq],
                    'documentID',
                );
                totalUserCounts.monthAllEnq = _.uniqBy(
                    [...totalUserCounts.monthAllEnq, ...data.monthAllEnq],
                    'documentID',
                );
                totalUserCounts.yearWalkin = _.uniqBy(
                    [...totalUserCounts.yearWalkin, ...data.yearWalkin],
                    'documentID',
                );
                totalUserCounts.weekWalkin = _.uniqBy(
                    [...totalUserCounts.weekWalkin, ...data.weekWalkin],
                    'documentID',
                );
                totalUserCounts.monthWalkin = _.uniqBy(
                    [...totalUserCounts.monthWalkin, ...data.monthWalkin],
                    'documentID',
                );
                totalUserCounts.yearPhoneIn = _.uniqBy(
                    [...totalUserCounts.yearPhoneIn, ...data.yearPhoneIn],
                    'documentID',
                );
                totalUserCounts.weekPhoneIn = _.uniqBy(
                    [...totalUserCounts.weekPhoneIn, ...data.weekPhoneIn],
                    'documentID',
                );
                totalUserCounts.monthPhoneIn = _.uniqBy(
                    [...totalUserCounts.monthPhoneIn, ...data.monthPhoneIn],
                    'documentID',
                );
                totalUserCounts.yearHeadOffice = _.uniqBy(
                    [...totalUserCounts.yearHeadOffice, ...data.yearHeadOffice],
                    'documentID',
                );
                totalUserCounts.weekHeadOffice = _.uniqBy(
                    [...totalUserCounts.weekHeadOffice, ...data.weekHeadOffice],
                    'documentID',
                );
                totalUserCounts.monthHeadOffice = _.uniqBy(
                    [...totalUserCounts.monthHeadOffice, ...data.monthHeadOffice],
                    'documentID',
                );
                totalUserCounts.yearAutogate = _.uniqBy(
                    [...totalUserCounts.yearAutogate, ...data.yearAutogate],
                    'documentID',
                );
                totalUserCounts.weekAutogate = _.uniqBy(
                    [...totalUserCounts.weekAutogate, ...data.yearAllEnq],
                    'documentID',
                );
                totalUserCounts.monthAutogate = _.uniqBy(
                    [...totalUserCounts.monthAutogate, ...data.monthAutogate],
                    'documentID',
                );
                totalUserCounts.yearInternet = _.uniqBy(
                    [...totalUserCounts.yearInternet, ...data.yearInternet],
                    'documentID',
                );
                totalUserCounts.weekInternet = _.uniqBy(
                    [...totalUserCounts.weekInternet, ...data.weekInternet],
                    'documentID',
                );
                totalUserCounts.monthInternet = _.uniqBy(
                    [...totalUserCounts.monthInternet, ...data.monthInternet],
                    'documentID',
                );
                totalUserCounts.yearOutbound = _.uniqBy(
                    [...totalUserCounts.yearOutbound, ...data.yearOutbound],
                    'documentID',
                );
                totalUserCounts.weekOutbound = _.uniqBy(
                    [...totalUserCounts.weekOutbound, ...data.weekOutbound],
                    'documentID',
                );
                totalUserCounts.monthOutbound = _.uniqBy(
                    [...totalUserCounts.monthOutbound, ...data.monthOutbound],
                    'documentID',
                );
                totalUserCounts.yearOther = _.uniqBy(
                    [...totalUserCounts.yearOther, ...data.yearOther],
                    'documentID',
                );
                totalUserCounts.weekOther = _.uniqBy(
                    [...totalUserCounts.weekOther, ...data.weekOther],
                    'documentID',
                );
                totalUserCounts.monthOther = _.uniqBy(
                    [...totalUserCounts.monthOther, ...data.monthOther],
                    'documentID',
                );
                totalUserCounts.yearTestdriveCount = totalUserCounts.yearAllEnq
                    .filter(a => a.testdrives.length > 0)
                    .map(a => a.testdrives.length);
                totalUserCounts.weekTestdriveCount = totalUserCounts.weekAllEnq
                    .filter(a => a.testdrives.length > 0)
                    .map(a => a.testdrives.length);
                totalUserCounts.monthTestdriveCount = totalUserCounts.monthAllEnq
                    .filter(a => a.testdrives.length > 0)
                    .map(a => a.testdrives.length);
                totalUserCounts.yearSale = _.uniqBy(
                    [...totalUserCounts.yearSale, ...data.yearSale],
                    'documentID',
                );
                totalUserCounts.weekSale = _.uniqBy(
                    [...totalUserCounts.weekSale, ...data.weekSale],
                    'documentID',
                );
                totalUserCounts.monthSale = _.uniqBy(
                    [...totalUserCounts.monthSale, ...data.monthSale],
                    'documentID',
                );
                totalUserCounts.yearFaceToFace = _.uniqBy(
                    [...totalUserCounts.yearFaceToFace, ...data.yearFaceToFace],
                    'documentID',
                );
                totalUserCounts.weekFaceToFace = _.uniqBy(
                    [...totalUserCounts.weekFaceToFace, ...data.weekFaceToFace],
                    'documentID',
                );
                totalUserCounts.monthFaceToFace = _.uniqBy(
                    [...totalUserCounts.monthFaceToFace, ...data.monthFaceToFace],
                    'documentID',
                );
                totalUserCounts.yearAllFollowups = calculateAllFollowups(
                    totalUserCounts.yearAllEnq,
                );
                totalUserCounts.weekAllFollowups = calculateAllFollowups(
                    totalUserCounts.weekAllEnq,
                );
                totalUserCounts.monthAllFollowups = calculateAllFollowups(
                    totalUserCounts.monthAllEnq,
                );
                totalUserCounts.yearAllFollowupLeads = calculateAllFollowupLeads(
                    totalUserCounts.yearAllEnq,
                );
                totalUserCounts.weekAllFollowupLeads = calculateAllFollowupLeads(
                    totalUserCounts.weekAllEnq,
                );
                totalUserCounts.monthAllFollowupLeads = calculateAllFollowupLeads(
                    totalUserCounts.monthAllEnq,
                );
                totalUserCounts.monthManagerIntro = _.uniqBy(
                    [
                        ...totalUserCounts.monthManagerIntro,
                        ...data.monthManagerIntro,
                    ],
                    'documentID',
                );
                totalUserCounts.weekManagerIntro = _.uniqBy(
                    [...totalUserCounts.weekManagerIntro, ...data.weekManagerIntro],
                    'documentID',
                );
                totalUserCounts.yearManagerIntro = _.uniqBy(
                    [...totalUserCounts.yearManagerIntro, ...data.yearManagerIntro],
                    'documentID',
                );
            });
            allData.push(totalUserCounts)

            allUsersData.push({
                userID: user.id,
                name: user.name,
                counts: allData,
                // usedCounts: userUsedCounts
            });
        });
        //  console.log('allUsersData', allUsersData)


        allData.forEach(data => {
            totalCounts.yearAllEnq = _.uniqBy([...totalCounts.yearAllEnq, ...data.yearAllEnq], 'documentID')
            totalCounts.weekAllEnq = _.uniqBy([...totalCounts.weekAllEnq, ...data.weekAllEnq], 'documentID')
            totalCounts.monthAllEnq = _.uniqBy([...totalCounts.monthAllEnq, ...data.monthAllEnq], 'documentID')
            totalCounts.yearWalkin = _.uniqBy([...totalCounts.yearWalkin, ...data.yearWalkin], 'documentID')
            totalCounts.weekWalkin = _.uniqBy([...totalCounts.weekWalkin, ...data.weekWalkin], 'documentID')
            totalCounts.monthWalkin = _.uniqBy([...totalCounts.monthWalkin, ...data.monthWalkin], 'documentID')
            totalCounts.yearPhoneIn = _.uniqBy([...totalCounts.yearPhoneIn, ...data.yearPhoneIn], 'documentID')
            totalCounts.weekPhoneIn = _.uniqBy([...totalCounts.weekPhoneIn, ...data.weekPhoneIn], 'documentID')
            totalCounts.monthPhoneIn = _.uniqBy([...totalCounts.monthPhoneIn, ...data.monthPhoneIn], 'documentID')
            totalCounts.yearHeadOffice = _.uniqBy([...totalCounts.yearHeadOffice, ...data.yearHeadOffice], 'documentID')
            totalCounts.weekHeadOffice = _.uniqBy([...totalCounts.weekHeadOffice, ...data.weekHeadOffice], 'documentID')
            totalCounts.monthHeadOffice = _.uniqBy([...totalCounts.monthHeadOffice, ...data.monthHeadOffice], 'documentID')
            totalCounts.yearAutogate = _.uniqBy([...totalCounts.yearAutogate, ...data.yearAutogate], 'documentID')
            totalCounts.weekAutogate = _.uniqBy([...totalCounts.weekAutogate, ...data.yearAllEnq], 'documentID')
            totalCounts.monthAutogate = _.uniqBy([...totalCounts.monthAutogate, ...data.monthAutogate], 'documentID')
            totalCounts.yearInternet = _.uniqBy([...totalCounts.yearInternet, ...data.yearInternet], 'documentID')
            totalCounts.weekInternet = _.uniqBy([...totalCounts.weekInternet, ...data.weekInternet], 'documentID')
            totalCounts.monthInternet = _.uniqBy([...totalCounts.monthInternet, ...data.monthInternet], 'documentID')
            totalCounts.yearOutbound = _.uniqBy([...totalCounts.yearOutbound, ...data.yearOutbound], 'documentID')
            totalCounts.weekOutbound = _.uniqBy([...totalCounts.weekOutbound, ...data.weekOutbound], 'documentID')
            totalCounts.monthOutbound = _.uniqBy([...totalCounts.monthOutbound, ...data.monthOutbound], 'documentID')
            totalCounts.yearOther = _.uniqBy([...totalCounts.yearOther, ...data.yearOther], 'documentID')
            totalCounts.weekOther = _.uniqBy([...totalCounts.weekOther, ...data.weekOther], 'documentID')
            totalCounts.monthOther = _.uniqBy([...totalCounts.monthOther, ...data.monthOther], 'documentID')
            totalCounts.yearTestdriveCount = totalCounts.yearAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length)
            totalCounts.weekTestdriveCount = totalCounts.weekAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length)
            totalCounts.monthTestdriveCount = totalCounts.monthAllEnq.filter(a => a.testdrives.length > 0).map(a => a.testdrives.length)
            totalCounts.yearSale = _.uniqBy([...totalCounts.yearSale, ...data.yearSale], 'documentID')
            totalCounts.weekSale = _.uniqBy([...totalCounts.weekSale, ...data.weekSale], 'documentID')
            totalCounts.monthSale = _.uniqBy([...totalCounts.monthSale, ...data.monthSale], 'documentID')
            totalCounts.yearFaceToFace = _.uniqBy([...totalCounts.yearFaceToFace, ...data.yearFaceToFace], 'documentID')
            totalCounts.weekFaceToFace = _.uniqBy([...totalCounts.weekFaceToFace, ...data.weekFaceToFace], 'documentID')
            totalCounts.monthFaceToFace = _.uniqBy([...totalCounts.monthFaceToFace, ...data.monthFaceToFace], 'documentID')
            totalCounts.yearAllFollowups = calculateAllFollowups(totalCounts.yearAllEnq)
            totalCounts.weekAllFollowups = calculateAllFollowups(totalCounts.weekAllEnq)
            totalCounts.monthAllFollowups = calculateAllFollowups(totalCounts.monthAllEnq)
            totalCounts.yearAllFollowupLeads = calculateAllFollowupLeads(totalCounts.yearAllEnq)
            totalCounts.weekAllFollowupLeads = calculateAllFollowupLeads(totalCounts.weekAllEnq)
            totalCounts.monthAllFollowupLeads = calculateAllFollowupLeads(totalCounts.monthAllEnq)
            totalCounts.monthManagerIntro = _.uniqBy([...totalCounts.monthManagerIntro, ...data.monthManagerIntro], 'documentID')
            totalCounts.weekManagerIntro = _.uniqBy([...totalCounts.weekManagerIntro, ...data.weekManagerIntro], 'documentID')
            totalCounts.yearManagerIntro = _.uniqBy([...totalCounts.yearManagerIntro, ...data.yearManagerIntro], 'documentID')
        })

        allData.push(totalCounts)
        setMakeAllData(allData)
        setUserAllData(allUsersData)
        setCountsCalculated(true)
    }, [pipelineEnquiries])

    const checkEnqType = (type, strToCheck) => {
        let returnVal = false

        let enqType = CommonHelper.getNameByValue(clientSettings.settings.enquiryTypes, type, '')

        if (enqType.toLowerCase().includes(strToCheck.toLowerCase())) {
            returnVal = true
        } else {
            returnVal = false
        }
        if (strToCheck === 'headoffice') {
            if (enqType.toLowerCase().includes(strToCheck.toLowerCase()) ||
                enqType.toLowerCase().includes('bmw australia') ||
                enqType.toLowerCase().includes('mini australia') ||
                enqType.toLowerCase().includes('bmw group crm') ||
                enqType.toLowerCase().includes('bmw head office')) {
                returnVal = true
            } else {
                returnVal = false
            }
        }
        return returnVal
    }

    const calculatePercentage = (val1, val2) => {
        return (val2 > 0 && val1 > 0 ?
            parseFloat((100 / (val1) * val2).toFixed(2)) + "%" :
            "0.00%");
    }

    const calculateAllFollowups = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => a.activities)
        let count = 0;
        ArrOfActivitiesArr.forEach(activities => {
            let allDates = [];
            activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date)) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    const calculateAllFollowupLeads = (enquiries) => {
        let ArrOfActivitiesArr = enquiries.filter(a => a.activities.length).map(a => {
            return {
                activities: a.activities,
                addedDate: a.addedDate,
            }
        })
        let count = 0;
        ArrOfActivitiesArr.forEach(activitiesObj => {
            let addedDate = moment(activitiesObj.addedDate.toDate()).format('YYYY/MM/DD')
            let allDates = [];
            activitiesObj.activities.forEach(a => {
                let date = moment(a.addedDate.toDate()).format('YYYY/MM/DD')
                if (!allDates.includes(date) && addedDate !== date) {
                    allDates.push(date)
                }
            })
            count += allDates.length
        })
        return count
    }

    const generateReportHeader = ({ make, saleType }) => {
        let makeStr = `BMW `
        let saleTypeStr = `New `

        if (make === 'all') {
            makeStr = 'ALL '
        } else {
            makeStr = `${make.toUpperCase()} `
        }

        if (saleType === 'all') {
            saleTypeStr = ''
        } else {
            saleTypeStr = `${saleType.toUpperCase()} `
        }
        return makeStr + saleTypeStr + 'ENQUIRY'
    }

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('daily-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.add('d-none');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
            document.querySelector('#top-nav-bar') && document.querySelector('#top-nav-bar').classList.remove('d-none');
        }
    }, [])

    const generateTablesByUsers = () => {
        return userAllData.map(user => (
            <>
                <h1 key={user.userID} className='report-user-name'>{user.name}</h1>
                {/* LOOP  */}
                {user.counts && user.counts.length > 0 && user.counts.filter(item => item.info !== 'totals').map(item => (
                    <table className="table-loop">
                        <thead>
                            <tr>
                                <th className="cell-head" width="15%">{generateReportHeader(item.info)}</th>
                                <th width="5.3%">WALKIN</th>
                                <th width="5.3%">PHONE-IN</th>
                                <th width="5.3%">HEAD OFFICE</th>
                                <th width="5.3%">AUTOGATE </th>
                                <th width="5.3%">INTERNET  </th>
                                <th width="5.3%">OUTBOUND</th>
                                <th width="5.3%">OTHER</th>
                                <th width="5.3%">TOTAL LEADS</th>
                                <th width="5.3%">FACE TO FACE</th>
                                <th width="5.3%">TEST DRIVE</th>
                                <th width="5.3%">SALE</th>
                                <th width="5.3%">FOLLOW UP RATE %</th>
                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                <th width="5.3%">LEAD TO SALE %</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMM')} - {moment(weekEnd.toDate()).format('DD MMM YYYY')}</td>
                                <td className="count-large">{item.weekWalkin.length}</td>
                                <td className="count-large">{item.weekPhoneIn.length}</td>
                                <td className="count-large">{item.weekHeadOffice.length}</td>
                                <td className="count-large">{item.weekAutogate.length}</td>
                                <td className="count-large">{item.weekInternet.length}</td>
                                <td className="count-large">{item.weekOutbound.length}</td>
                                <td className="count-large">{item.weekOther.length}</td>
                                <td className="count-large">{item.weekAllEnq.length}</td>
                                <td className="count-large">{item.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                <td className="count-large">{item.weekTestdriveCount.length}</td> {/* TestDrive  */}
                                <td className="count-large">{item.weekSale.length}</td> {/* Sale  */}
                                <td className="count-large">{calculatePercentage(item.weekAllFollowups, item.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                <td className="count-large">{calculatePercentage(item.weekTestdriveCount.length, item.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekSale.length)}</td> {/* LEAD TO SALE   */}
                            </tr>
                            <tr>
                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                <td className="count-large">{item.monthWalkin.length}</td>
                                <td className="count-large">{item.monthPhoneIn.length}</td>
                                <td className="count-large">{item.monthHeadOffice.length}</td>
                                <td className="count-large">{item.monthAutogate.length}</td>
                                <td className="count-large">{item.monthInternet.length}</td>
                                <td className="count-large">{item.monthOutbound.length}</td>
                                <td className="count-large">{item.monthOther.length}</td>
                                <td className="count-large">{item.monthAllEnq.length}</td>
                                <td className="count-large">{item.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                <td className="count-large">{item.monthTestdriveCount.length}</td> {/* TestDrive  */}
                                <td className="count-large">{item.monthSale.length}</td> {/* Sale  */}
                                <td className="count-large">{calculatePercentage(item.monthAllFollowups, item.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                <td className="count-large">{calculatePercentage(item.monthTestdriveCount.length, item.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthSale.length)}</td> {/* LEAD TO SALE   */}
                            </tr>
                            {/* <tr>
                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                <td className="count-large">{item.yearWalkin.length}</td>
                                <td className="count-large">{item.yearPhoneIn.length}</td>
                                <td className="count-large">{item.yearHeadOffice.length}</td>
                                <td className="count-large">{item.yearAutogate.length}</td>
                                <td className="count-large">{item.yearInternet.length}</td>
                                <td className="count-large">{item.yearOutbound.length}</td>
                                <td className="count-large">{item.yearOther.length}</td>
                                <td className="count-large">{item.yearAllEnq.length}</td>
                                <td className="count-large">{item.yearFaceToFace.length}</td>  
                                <td className="count-large">{item.yearTestdriveCount.length}</td>  
                                <td className="count-large">{item.yearSale.length}</td> 
                                <td className="count-large">{calculatePercentage(item.yearAllFollowups, item.yearAllFollowupLeads)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearTestdriveCount.length)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearTestdriveCount.length, item.yearSale.length)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearSale.length)}</td>  
                            </tr> */}
                        </tbody>
                    </table>
                ))}

                {/* TOTALS  */}
                {user.counts && user.counts.length > 0 && user.counts.filter(item => item.info === 'totals').map((item, index) => (
                    <table className="table-loop">
                        <thead>
                            <tr>
                                <th className="cell-head" width="15%">{`TOTALS`}</th>
                                <th width="5.3%">WALKIN</th>
                                <th width="5.3%">PHONE-IN</th>
                                <th width="5.3%">HEAD OFFICE</th>
                                <th width="5.3%">AUTOGATE </th>
                                <th width="5.3%">INTERNET  </th>
                                <th width="5.3%">OUTBOUND</th>
                                <th width="5.3%">OTHER</th>
                                <th width="5.3%">TOTAL LEADS</th>
                                <th width="5.3%">FACE TO FACE</th>
                                <th width="5.3%">TEST DRIVE</th>
                                <th width="5.3%">SALE</th>
                                <th width="5.3%">FOLLOW UP RATE %</th>
                                <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                <th width="5.3%">TEST DRIVE TO SALE %</th>
                                <th width="5.3%">LEAD TO SALE %</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMM')} - {moment(weekEnd.toDate()).format('DD MMM YYYY')}</td>
                                <td className="count-large">{item.weekWalkin.length}</td>
                                <td className="count-large">{item.weekPhoneIn.length}</td>
                                <td className="count-large">{item.weekHeadOffice.length}</td>
                                <td className="count-large">{item.weekAutogate.length}</td>
                                <td className="count-large">{item.weekInternet.length}</td>
                                <td className="count-large">{item.weekOutbound.length}</td>
                                <td className="count-large">{item.weekOther.length}</td>
                                <td className="count-large">{item.weekAllEnq.length}</td>
                                <td className="count-large">{item.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                <td className="count-large">{item.weekTestdriveCount.length}</td> {/* TestDrive  */}
                                <td className="count-large">{item.weekSale.length}</td> {/* Sale  */}
                                <td className="count-large">{calculatePercentage(item.weekAllFollowups, item.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                <td className="count-large">{calculatePercentage(item.weekTestdriveCount.length, item.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekSale.length)}</td> {/* LEAD TO SALE   */}
                            </tr>
                            <tr>
                                <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                <td className="count-large">{item.monthWalkin.length}</td>
                                <td className="count-large">{item.monthPhoneIn.length}</td>
                                <td className="count-large">{item.monthHeadOffice.length}</td>
                                <td className="count-large">{item.monthAutogate.length}</td>
                                <td className="count-large">{item.monthInternet.length}</td>
                                <td className="count-large">{item.monthOutbound.length}</td>
                                <td className="count-large">{item.monthOther.length}</td>
                                <td className="count-large">{item.monthAllEnq.length}</td>
                                <td className="count-large">{item.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                <td className="count-large">{item.monthTestdriveCount.length}</td> {/* TestDrive  */}
                                <td className="count-large">{item.monthSale.length}</td> {/* Sale  */}
                                <td className="count-large">{calculatePercentage(item.monthAllFollowups, item.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                <td className="count-large">{calculatePercentage(item.monthTestdriveCount.length, item.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthSale.length)}</td> {/* LEAD TO SALE   */}
                            </tr>
                            {/* <tr>
                                <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                <td className="count-large">{item.yearWalkin.length}</td>
                                <td className="count-large">{item.yearPhoneIn.length}</td>
                                <td className="count-large">{item.yearHeadOffice.length}</td>
                                <td className="count-large">{item.yearAutogate.length}</td>
                                <td className="count-large">{item.yearInternet.length}</td>
                                <td className="count-large">{item.yearOutbound.length}</td>
                                <td className="count-large">{item.yearOther.length}</td>
                                <td className="count-large">{item.yearAllEnq.length}</td>
                                <td className="count-large">{item.yearFaceToFace.length}</td> 
                                <td className="count-large">{item.yearTestdriveCount.length}</td>  
                                <td className="count-large">{item.yearSale.length}</td>  
                                <td className="count-large">{calculatePercentage(item.yearAllFollowups, item.yearAllFollowupLeads)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearTestdriveCount.length)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearTestdriveCount.length, item.yearSale.length)}</td>  
                                <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearSale.length)}</td>  
                            </tr> */}
                        </tbody>
                        {user.counts.length === (index + 1) && (
                            <>
                                {/* @info: Below div is used for selector by puppeteer (backend)*/}
                                <div className="report-data-loaded report-data-load" />
                            </>
                        )}
                    </table>
                ))}
            </>
        ))
    }

    return (

        <>
            {
                (countsCalculated && !_.isEmpty(clientSettings)) ?
                    <>
                        <div className="A4 landscape">
                            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                                <div className="header">
                                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="60%"><div className="header-left">
                                                    {
                                                        !_.isEmpty(client) && client.logoURL
                                                            ?
                                                            <div className="logo-union"> <img src={client.logoURL ? client.logoURL : ''} height="60" /></div>
                                                            :
                                                            <>
                                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                            </>
                                                    }
                                                </div></td>

                                                <td width="40%"><div className="header-right"> {'SUMMARY REPORT BY USERS'} <br></br>
                                                    <span> {moment(_date).format('DD MMM YYYY')}</span> </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-wraper">

                                    {/* LOOP  */}
                                    {makeAllData && makeAllData.length > 0 && makeAllData.filter(item => item.info !== 'totals').map(item => (
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="15%">{generateReportHeader(item.info)}</th>
                                                    <th width="5.3%">WALKIN</th>
                                                    <th width="5.3%">PHONE-IN</th>
                                                    <th width="5.3%">HEAD OFFICE</th>
                                                    <th width="5.3%">AUTOGATE </th>
                                                    <th width="5.3%">INTERNET  </th>
                                                    <th width="5.3%">OUTBOUND</th>
                                                    <th width="5.3%">OTHER</th>
                                                    <th width="5.3%">TOTAL LEADS</th>
                                                    <th width="5.3%">FACE TO FACE</th>
                                                    <th width="5.3%">TEST DRIVE</th>
                                                    <th width="5.3%">SALE</th>
                                                    <th width="5.3%">FOLLOW UP RATE %</th>
                                                    <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                    <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                    <th width="5.3%">LEAD TO SALE %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMM')} - {moment(weekEnd.toDate()).format('DD MMM YYYY')}</td>
                                                    <td className="count-large">{item.weekWalkin.length}</td>
                                                    <td className="count-large">{item.weekPhoneIn.length}</td>
                                                    <td className="count-large">{item.weekHeadOffice.length}</td>
                                                    <td className="count-large">{item.weekAutogate.length}</td>
                                                    <td className="count-large">{item.weekInternet.length}</td>
                                                    <td className="count-large">{item.weekOutbound.length}</td>
                                                    <td className="count-large">{item.weekOther.length}</td>
                                                    <td className="count-large">{item.weekAllEnq.length}</td>
                                                    <td className="count-large">{item.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                    <td className="count-large">{item.weekTestdriveCount.length}</td> {/* TestDrive  */}
                                                    <td className="count-large">{item.weekSale.length}</td> {/* Sale  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllFollowups, item.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekTestdriveCount.length, item.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                                </tr>
                                                <tr>
                                                    <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                    <td className="count-large">{item.monthWalkin.length}</td>
                                                    <td className="count-large">{item.monthPhoneIn.length}</td>
                                                    <td className="count-large">{item.monthHeadOffice.length}</td>
                                                    <td className="count-large">{item.monthAutogate.length}</td>
                                                    <td className="count-large">{item.monthInternet.length}</td>
                                                    <td className="count-large">{item.monthOutbound.length}</td>
                                                    <td className="count-large">{item.monthOther.length}</td>
                                                    <td className="count-large">{item.monthAllEnq.length}</td>
                                                    <td className="count-large">{item.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                    <td className="count-large">{item.monthTestdriveCount.length}</td> {/* TestDrive  */}
                                                    <td className="count-large">{item.monthSale.length}</td> {/* Sale  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllFollowups, item.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthTestdriveCount.length, item.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                                </tr>
                                                {/* <tr>
                                                    <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                    <td className="count-large">{item.yearWalkin.length}</td>
                                                    <td className="count-large">{item.yearPhoneIn.length}</td>
                                                    <td className="count-large">{item.yearHeadOffice.length}</td>
                                                    <td className="count-large">{item.yearAutogate.length}</td>
                                                    <td className="count-large">{item.yearInternet.length}</td>
                                                    <td className="count-large">{item.yearOutbound.length}</td>
                                                    <td className="count-large">{item.yearOther.length}</td>
                                                    <td className="count-large">{item.yearAllEnq.length}</td>
                                                    <td className="count-large">{item.yearFaceToFace.length}</td>  
                                                    <td className="count-large">{item.yearTestdriveCount.length}</td>  
                                                    <td className="count-large">{item.yearSale.length}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllFollowups, item.yearAllFollowupLeads)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearTestdriveCount.length)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearTestdriveCount.length, item.yearSale.length)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearSale.length)}</td>  
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    ))}

                                    {/* TOTALS  */}
                                    {makeAllData && makeAllData.length > 0 && makeAllData.filter(item => item.info === 'totals').map(item => (
                                        <table className="table-loop">
                                            <thead>
                                                <tr>
                                                    <th className="cell-head" width="15%">{`TOTALS`}</th>
                                                    <th width="5.3%">WALKIN</th>
                                                    <th width="5.3%">PHONE-IN</th>
                                                    <th width="5.3%">HEAD OFFICE</th>
                                                    <th width="5.3%">AUTOGATE </th>
                                                    <th width="5.3%">INTERNET  </th>
                                                    <th width="5.3%">OUTBOUND</th>
                                                    <th width="5.3%">OTHER</th>
                                                    <th width="5.3%">TOTAL LEADS</th>
                                                    <th width="5.3%">FACE TO FACE</th>
                                                    <th width="5.3%">TEST DRIVE</th>
                                                    <th width="5.3%">SALE</th>
                                                    <th width="5.3%">FOLLOW UP RATE %</th>
                                                    <th width="5.3%">LEAD TO TESTDRIVE %</th>
                                                    <th width="5.3%">TEST DRIVE TO SALE %</th>
                                                    <th width="5.3%">LEAD TO SALE %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="td-head">WTD {moment(weekStart.toDate()).format('DD MMM')} - {moment(weekEnd.toDate()).format('DD MMM YYYY')}</td>
                                                    <td className="count-large">{item.weekWalkin.length}</td>
                                                    <td className="count-large">{item.weekPhoneIn.length}</td>
                                                    <td className="count-large">{item.weekHeadOffice.length}</td>
                                                    <td className="count-large">{item.weekAutogate.length}</td>
                                                    <td className="count-large">{item.weekInternet.length}</td>
                                                    <td className="count-large">{item.weekOutbound.length}</td>
                                                    <td className="count-large">{item.weekOther.length}</td>
                                                    <td className="count-large">{item.weekAllEnq.length}</td>
                                                    <td className="count-large">{item.weekFaceToFace.length}</td> {/* FaceToFace  */}
                                                    <td className="count-large">{item.weekTestdriveCount.length}</td> {/* TestDrive  */}
                                                    <td className="count-large">{item.weekSale.length}</td> {/* Sale  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllFollowups, item.weekAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekTestdriveCount.length, item.weekSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                    <td className="count-large">{calculatePercentage(item.weekAllEnq.length, item.weekSale.length)}</td> {/* LEAD TO SALE   */}
                                                </tr>
                                                <tr>
                                                    <td className="td-head">MTD {moment(monthStart.toDate()).format('MMM')}</td>
                                                    <td className="count-large">{item.monthWalkin.length}</td>
                                                    <td className="count-large">{item.monthPhoneIn.length}</td>
                                                    <td className="count-large">{item.monthHeadOffice.length}</td>
                                                    <td className="count-large">{item.monthAutogate.length}</td>
                                                    <td className="count-large">{item.monthInternet.length}</td>
                                                    <td className="count-large">{item.monthOutbound.length}</td>
                                                    <td className="count-large">{item.monthOther.length}</td>
                                                    <td className="count-large">{item.monthAllEnq.length}</td>
                                                    <td className="count-large">{item.monthFaceToFace.length}</td> {/* FaceToFace  */}
                                                    <td className="count-large">{item.monthTestdriveCount.length}</td> {/* TestDrive  */}
                                                    <td className="count-large">{item.monthSale.length}</td> {/* Sale  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllFollowups, item.monthAllFollowupLeads)}</td> {/* FOLLOW UP RATE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthTestdriveCount.length)}</td> {/* LEAD TO TESTDRIVE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthTestdriveCount.length, item.monthSale.length)}</td> {/* TEST DRIVE TO SALE  */}
                                                    <td className="count-large">{calculatePercentage(item.monthAllEnq.length, item.monthSale.length)}</td> {/* LEAD TO SALE   */}
                                                </tr>
                                                {/* <tr>
                                                    <td className="td-head">YTD {moment(yearStart.toDate()).format('YYYY')}</td>
                                                    <td className="count-large">{item.yearWalkin.length}</td>
                                                    <td className="count-large">{item.yearPhoneIn.length}</td>
                                                    <td className="count-large">{item.yearHeadOffice.length}</td>
                                                    <td className="count-large">{item.yearAutogate.length}</td>
                                                    <td className="count-large">{item.yearInternet.length}</td>
                                                    <td className="count-large">{item.yearOutbound.length}</td>
                                                    <td className="count-large">{item.yearOther.length}</td>
                                                    <td className="count-large">{item.yearAllEnq.length}</td>
                                                    <td className="count-large">{item.yearFaceToFace.length}</td>  
                                                    <td className="count-large">{item.yearTestdriveCount.length}</td>  
                                                    <td className="count-large">{item.yearSale.length}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllFollowups, item.yearAllFollowupLeads)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearTestdriveCount.length)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearTestdriveCount.length, item.yearSale.length)}</td>  
                                                    <td className="count-large">{calculatePercentage(item.yearAllEnq.length, item.yearSale.length)}</td>  
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    ))}
                                    {generateTablesByUsers()}
                                </div>
                                <div className="footer">
                                    <div className="footer-bottom">

                                        <p>
                                            <span style={{ paddingLeft: 0 }} >
                                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                            </span>
                                            <span style={{ paddingLeft: 0 }}>
                                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="footer-cpy">
                                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                                        <div className="footer-right"></div>

                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </section>

                        </div>
                    </>
                    :
                    <>
                        <div className='loader-center-screen'>
                            <div className="spinner-loader h-100 undefined "><div className=" d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="report-loader spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                        </div>
                    </>
            }
        </>
    )
}
export default SummaryReportByUsers;