/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import MainContainer from './container';

const QuoteLayout = (props) => {
    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.add('inv-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])

    return (
        <main role="main" className="container-fluid  pl-0 pr-0">
            <Suspense fallback={<></>}>
                <>
                    <MainContainer history={props.history} match={props.match} />
                </>
            </Suspense>
        </main>
    );
}

export default QuoteLayout;