/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './container';


const TestdriveLayout = (props) => {



    useEffect(() => {
        if (window.location.pathname.indexOf('contact/unsubscribe') >= 0)
            document.getElementsByTagName('body')[0].classList.add('unsubscribe-page-landing');
        else if (window.location.pathname.indexOf('contact/privacy') >= 0)
            document.getElementsByTagName('body')[0].classList.add('privacy-page-landing');
        else if (window.location.pathname.indexOf('terms/sign') >= 0)
            document.getElementsByTagName('body')[0].classList.add('customer-tradeinform-page-landing');
            else if (window.location.pathname.indexOf('fleetpro/confirmation') >= 0)
            document.getElementsByTagName('body')[0].classList.add('fleetpro-externalpage-landing');
        else
            document.getElementsByTagName('body')[0].classList.add('testdrive-page-landing');
        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])





    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default TestdriveLayout;