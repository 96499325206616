/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../styles/texteditor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import _images from '../images'
import _ from 'lodash'

const TextEditor = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        document.documentElement.style.setProperty('--texteditorHeight', (props.height) + 'px !important');
    }, [props.height])

    useEffect(() => {
        if (toHtml(editorState) === props.value) return //added
        setEditorState(
            EditorState.push(
                editorState,
                ContentState.createFromBlockArray(
                    htmlToDraft(props.value || ''),
                ),
            ),
        )
    }, [props.value])

    const toHtml = (es) => {
        return draftToHtml(convertToRaw(es.getCurrentContent())) // added
    }

    const onEditorStateChange = (es) => {
        if (props.disabled) {
            return
        }
        setEditorState(es)
        const html = toHtml(es) //added
        if (props.value !== html) {
            props.onChange({ name: props.name, value: html, rawData: convertToRaw(es.getCurrentContent()) })
        }
    }



    return (
        <div>
            <Editor
                handlePastedText={() => false}
                placeholder={props.placeholder}
                editorState={editorState}
                wrapperClassName="editor-item-custom"
                editorClassName={`editor-textarea ${props.enableMention ? 'overflow-inherit' : ''}`}
                toolbarClassName="editor-toolbar"
                onEditorStateChange={onEditorStateChange}
                mention={props.enableMention ? {
                    separator: ' ',
                    trigger: '@',
                    suggestions: _.map(props.mentions, function (e) {
                        return {
                            text: e.name,
                            value: e.value.replace('@','')
                        }
                    }),
                } : []}
                toolbar={{
                    options: props.toolbarOptions,//['inline', 'list','textAlign'],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                        inDropdown: false,
                        className: 'toolbar-editor-separator',
                        bold: { className: 'editor-button' },
                        italic: { className: 'editor-button' },
                        underline: { className: 'editor-button' },
                    },
                    list: {
                        inDropdown: false,
                        className: 'toolbar-editor-separator',
                        options: ['unordered', 'ordered'],
                        unordered: { className: 'editor-button' },
                        ordered: { className: 'editor-button' },
                    },
                    textAlign: {
                        inDropdown: false,
                        left: { className: 'editor-button' },
                        center: { className: 'editor-button' },
                        right: { className: 'editor-button' },
                        justify: { className: 'editor-button' },
                    }
                    //colorPicker: { className: 'demo-option-custom', popupClassName: 'demo-popup-custom' },
                }}
            />
        </div>
    );
}

export default TextEditor;


// class TextEditor extends Component {
//     constructor(props) {
//         super(props);
//         const contentBlock = htmlToDraft(this.props.value);
//         if (contentBlock) {
//             const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
//             const editorState = EditorState.createWithContent(contentState);
//             this.state = {
//                 editorState,
//             };
//         }
//         else {
//             this.state = {
//                 editorState: EditorState.createEmpty(),
//             };
//         }
//     }
//     componentDidMount = () => {
//         //this.editor.focus();
//     }

//     UNSAFE_componentWillReceiveProps

//     // onEditorStateChange = (editorState) => {
//     //     this.setState({
//     //         editorState,
//     //     });

//     //     console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
//     // };

//     // uploadImageCallBack(file) {
//     //   return new Promise((resolve, reject) => {
//     //       const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
//     //       xhr.open("POST", "https://api.imgur.com/3/image");
//     //       xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
//     //       const data = new FormData(); // eslint-disable-line no-undef
//     //       data.append("image", file);
//     //       xhr.send(data);
//     //       xhr.addEventListener("load", () => {
//     //         const response = JSON.parse(xhr.responseText);
//     //         //console.log(response);
//     //         resolve(response);
//     //       });
//     //       xhr.addEventListener("error", () => {
//     //         const error = JSON.parse(xhr.responseText);
//     //         reject(error);
//     //       });

//     //     });
//     // } 

//     render() {
//         const { editorState } = this.state;
//         return (
//             <div>
//                 <Editor
//                     editorState={editorState}
//                     wrapperClassName="editor-item"
//                     editorClassName="editor-textarea"
//                     toolbarClassName="editor-toolbar"
//                     // mention={{
//                     //     separator: ' ',
//                     //     trigger: '@',
//                     //     suggestions: [
//                     //         //{ text: <div><img src="https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg" alt="" />APPLE</div>, value: 'apple', url: 'apple' },
//                     //         { text: 'Willims David', value: 'Willims David', url: 'Willims-David' }
//                     //     ],
//                     // }}
//                     //hashtag={{}}
//                     onEditorStateChange={this.props.onEditorStateChange}
//                     toolbar={{

//                         options: ['inline', 'list'],
//                         inline: {
//                             options: ['bold', 'italic', 'underline'],
//                             inDropdown: false,
//                             bold: { className: 'editor-button' },
//                             italic: { className: 'editor-button' },
//                             underline: { className: 'editor-button' },
//                         },
//                         list: {
//                             inDropdown: false,
//                             options: ['unordered', 'ordered'],
//                             unordered: { className: 'editor-button' },
//                             ordered: { className: 'editor-button' },
//                         },
//                         // link: {
//                         //     inDropdown: false,
//                         //     showOpenOptionOnHover: true,
//                         //     defaultTargetOption: '_self',
//                         //     options: ['link'],
//                         //     popupClassName: undefined,
//                         //     link: { className: 'editor-button' },
//                         // },
//                         //  image: {
//                         //   // icon: image,
//                         //    className: undefined,
//                         //    component: undefined,
//                         //    popupClassName: undefined,
//                         //    urlEnabled: true,
//                         //    uploadEnabled: true,
//                         //    alignmentEnabled: true,
//                         //    uploadCallback: uploadImageCallBack,
//                         //    previewImage: false,
//                         //    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//                         //    alt: { present: false, mandatory: false },
//                         //    defaultSize: {
//                         //      height: 'auto',
//                         //      width: 'auto',
//                         //    },
//                         //  }
//                     }}
//                 />
//             </div>
//         );
//     }
// }

//export default TextEditor;