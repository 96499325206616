import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash'
import { InputText } from '../../../../components';
import Translate from '../../../../constants/translate';

const Addtemplate = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    //console.log(template)
    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;


        if (str && str.length >= 1) {
            var firstChar = str.charAt(0);
            var remainingStr = str.slice(1);
            str = firstChar.toUpperCase() + remainingStr;
        }
        setTemplate({
            ...template,
            [name]: str
        });

        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }
    const handleTextChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setTemplate({
            ...template,
            [name]: (value || ''),
        });
        setErrorFields({
            ...errorFields,
            [name]: '',
        });
    };

    return (<Modal

        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={props.show ? 'overlay-modal active' : ''}
        dialogClassName="modal-dialog-centered modal-save-filter">

        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className="modal-title" > <Translate text={props.title} /></h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <div className="container-fluid">
                <div className="row">

                    <div className="form-style w-100">

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >Subject</label>
                                <InputText
                                    autoComplete="off"
                                    placeholder={'subject'}
                                    className={`form-control ${errorFields["subject"]}`}
                                    name="subject"
                                    onChange={handleTextChange}
                                    value={template?.subject}
                                    maxLength={250}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal.Body>
        <Modal.Footer className="modal-footer">
            <button type="button" className={`btn btn-primary float-right`} onClick={(e) => {
                if (_.isEmpty(template?.subject)) {
                    setErrorFields({
                        ['subject']: 'input_error'
                    })
                    return
                }
                props.handleClose({
                    ...template,
                    ...props.template
                });
            }
            }>
                {loader ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-left" onClick={() => {
                props.handleClose();
            }}>
                <Translate text={'cancel'} />
            </button>

        </Modal.Footer>
    </Modal>);
}

export default Addtemplate;