import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import _ from 'lodash';

import Translate from '../../../constants/translate';
import { ContentOverlay, PopUpModal } from '../../../components';
import FileInfo from '../../files/fileInfo';
import AddFile from './addFileModal';
import _images from '../../../images'

import FleetHistoryReducer from '../../fleet/history/historyReducer';
import CommonHelper from '../../../services/common';
import { objFiles } from '../../files/viewModel';
import { firestoreDB } from '../../../services/helper';

const Files = props => {
	// quotation
	// contract
	// others
	//const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 170) })
	const [files, dispatch] = useReducer(FleetHistoryReducer, {
		quotation: [],
		contract: [],
		others: [],
	});
	const [checkDataLoad, setDataload] = useState({
		quotation: true,
		contract: true,
		others: true,
	});
	//const [hasMore, setHasMoreData] = useState([])
	const [modelAddShow, setModelAddShow] = useState(false);
	const [isPaging, setPaging] = useState(false);
	const [filesLoader, setLoader] = useState({
		quotation: true,
		contract: true,
		others: true,
	});
	const [checkFile, setCheckFile] = useState({
		quotation: [],
		contract: [],
		others: [],
	});
	//const pageLimit = 20
	const [activeTab, setActiveTab] = useState('quotation');
	//const [searchText, setSearchText] = useState('')

	const [editshow, setEditshow] = useState(false);
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleClick = e => {
		if (node && node.current && node.current.contains(e.target)) {
			return;
		}
		setEditshow(false);
	};

	useEffect(() => {
		// function handleResize() {
		//     setWindowSize({
		//         windowWidth: (window.innerWidth - 30),
		//         windowHeight: (window.innerHeight - 170)
		//     })
		// }
		// window.addEventListener('resize', handleResize);

		return () => {
			//window.removeEventListener('resize', handleResize);
			window.unSubscribeFileList && window.unSubscribeFileList();
		};
	}, []);

	useEffect(() => {
		if (!checkDataLoad[activeTab]) {
			return;
		}
		let refCollectionData;

		if (props.enquiryID || props.contactID) {
			if (props.enquiryID) {
				refCollectionData = window.firebase
					.firestore()
					.collection(`stock/${props.enquiryID}/files`)
					.where('category', '==', activeTab)
					.where('isDeleted', '==', false)
				//.orderBy('modifiedDate', 'desc')
				//.orderBy('documentID', 'desc');
			} else if (props.contactID && !props.showAdd) {
				refCollectionData = firestoreDB(props.dealersettings).firestore().collectionGroup(`files`)
					.where('contactID', '==', props.contactID)
					.where('category', '==', activeTab)
					.where('isDeleted', '==', false)
				//.orderBy('modifiedDate', 'desc')
				//.orderBy('documentID', 'desc');
			}

			//

			// let lastRecord = hasMore.filter(e => e.type === activeTab);
			// if (lastRecord.length > 0) {
			//     refLoanData = refLoanData
			//         .startAfter(lastRecord[0].lastDoc.modifiedDate, lastRecord[0].lastDoc.documentID)
			//         .limit(pageLimit)
			// }
			// else {
			//     refLoanData = refLoanData
			//         .limit(pageLimit)
			// }

			window.unSubscribeFileList = refCollectionData.onSnapshot(
				onCollectionUpdate,
			);
		}
	}, [checkDataLoad, activeTab]);

	const onCollectionUpdate = querySnapshot => {
		let actionType;
		let snapshotDoc;
		querySnapshot.docChanges().forEach(change => {
			if (change.oldIndex >= 0) {
				//if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
				snapshotDoc = dataMappingVM({
					...change.doc.data(),
					documentID: change.doc.id,
				});
				if (change.type === 'added') {
					actionType = 'ADDNEW_LIST';
				} else if (change.type === 'modified') {
					actionType = 'UPDATE_LIST';
				} else if (change.type === 'removed') {
					actionType = 'REMOVE_LIST';
				}
			}
		});
		let _files = [];
		if (!actionType) {
			// if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
			//     setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
			//     {
			//         type: activeTab,
			//         lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1].data()
			//     }])
			// }
			// else {
			//     setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
			// }
			querySnapshot.forEach(function (doc) {
				var logVM = dataMappingVM({
					...doc.data(),
					documentID: doc.id,
				});
				_files.push(logVM);
			});
		}
		dispatch({
			type: actionType ? actionType : isPaging ? 'APPEND_LIST' : 'SUCCESS_LIST',
			data: actionType ? snapshotDoc : _.orderBy(_files, ["modifiedDate"], ["desc"]),
			activetab: activeTab,
		});
		setDataload({
			...checkDataLoad,
			[activeTab]: false,
		});
		setLoader({
			...filesLoader,
			[activeTab]: false,
		});
		setPaging(false);
	};

	const dataMappingVM = doc => {
		let users = props.groupUsers ? props.groupUsers : props.clientUsers
		const objlogData = Object.assign({}, doc);
		const fileVM = Object.assign({}, objFiles);
		for (let [key, value] of Object.entries(objlogData)) {
			fileVM[key] = value;
		}

		fileVM.createdOn = moment
			.unix(fileVM.addedDate.seconds)
			.format('DD/MM/YYYY hh:mm A');
		fileVM.updatedOn = moment
			.unix(fileVM.modifiedDate.seconds)
			.format('DD/MM/YYYY hh:mm A');
		fileVM.addedBy = CommonHelper.getUserNamebyId(
			(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers),
			fileVM.addedBy,
		);
		fileVM.modifiedBy = CommonHelper.getUserNamebyId(
			(!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers),
			fileVM.modifiedBy,
		);

		return fileVM;
	};

	const handleAllChecked = event => {
		let selectedIds = [];
		if (event.target.checked) {
			files[activeTab] &&
				files[activeTab].map(file => {
					return selectedIds.push(file.documentID);
				});
		}

		setCheckFile({
			...checkFile,
			[activeTab]: selectedIds,
		});
	};

	const handleCheckChange = e => {
		const { name, checked } = e.target;
		//console.log('handleCheckChange', name, checked)

		let selectedIds = checkFile[activeTab];

		if (checked) {
			selectedIds.push(name);
		} else {
			let stindex = selectedIds.indexOf(name);
			selectedIds.splice(stindex, 1);
		}

		setCheckFile({
			...checkFile,
			[activeTab]: selectedIds,
		});
	};

	const handleFileMoveTo = category => {
		setEditshow(false);
		let selectedIds = Object.assign([], checkFile[activeTab]);
		let fileIds = Object.assign([], checkFile[activeTab]);
		let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
		let modifiedDate = window.firebase.firestore.Timestamp.now();
		let enquiryID = props.enquiryID;

		fileIds &&
			fileIds.forEach(fileID => {
				if (!props.enquiryID) {
					let _files = files[activeTab].filter(
						item => item.documentID === fileID,
					);
					enquiryID = _files && _files.length > 0 ? _files[0].enquiryID : '*';
				}

				let stindex = selectedIds.indexOf(fileID);
				selectedIds.splice(stindex, 1);

				const updateRef = window.firebase
					.firestore()
					.collection(`stock/${enquiryID}/files`)
					.doc(fileID);
				updateRef
					.set(
						{
							category,
							modifiedBy,
							modifiedDate,
						},
						{ merge: true },
					)
					.then(docRef => { })
					.catch(error => {
						console.error('Error updating contact_details: ', error);
					});
			});
		//console.log('selectedIds', selectedIds)
		setCheckFile({
			...checkFile,
			[activeTab]: selectedIds,
		});
	};

	const handleFileDelete = () => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete the selected file.'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then(result => {
			if (result.value) {
				let selectedIds = Object.assign([], checkFile[activeTab]);
				let fileIds = Object.assign([], checkFile[activeTab]);
				let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
				let modifiedDate = window.firebase.firestore.Timestamp.now();
				let enquiryID = props.enquiryID;

				fileIds &&
					fileIds.forEach(fileID => {
						let _files = files[activeTab].filter(item => item.documentID === fileID)
						if (!props.enquiryID) {
							enquiryID =
								_files && _files.length > 0 ? _files[0].enquiryID : '*';
						}

						let stindex = selectedIds.indexOf(fileID);
						selectedIds.splice(stindex, 1);

						const updateRef = window.firebase
							.firestore()
							.collection(`stock/${enquiryID}/files`)
							.doc(fileID);
						updateRef
							.set(
								{
									isDeleted: true,
									modifiedBy,
									modifiedDate,
								},
								{ merge: true },
							)
							.then(docRef => {
								let logNotes = 'File deleted';
								if (_files && _files.name)
									logNotes = 'File: ' + _files.name + ' deleted';

								let _objLogData = {
									notes: logNotes,
									type: 'files',
									subType: 'delete',
									recordId: fileID,
									enquiryID: enquiryID,
									path: `stock/${enquiryID}/files`,
								}
								_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
								CommonHelper.saveAuditLog(_objLogData);

							})
							.catch(error => {
								console.error('Error updating contact_details: ', error);
							});
					});

				setCheckFile({
					...checkFile,
					[activeTab]: selectedIds,
				});
			}
		});
	};

	//#region FILE CHANGES

	const handleFileClose = () => {
		setModelAddShow(false);
	};

	return (
		<>
			<Modal
				id='dealer-files'
				show={props.show}
				onHide={props.handleClose}
				backdrop='static'
				backdropClassName={props.show ? 'overlay-modal active' : ''}
				enforceFocus={false}
				dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-add-dealer-file'
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h3 className='modal-title'>
							<Translate text={'Documents'} />
						</h3>
						<div className='add-button'>
							<a href='#' className='mini-button' onClick={() => setModelAddShow(true)}>
								<i className='ico icon-add'></i>
							</a>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<>
								{props.showAdd ? (
									<div className='add-files-button'>
										<a
											href='#'
											className='mini-button'
											onClick={e => {
												e.preventDefault();
												setModelAddShow(true);
											}}
										>
											<i className='ico icon-add'></i>
										</a>
									</div>
								) : (
									<></>
								)}

								<div className='file-manager-nav pl-0'>
									<ul>
										<li>
											<a
												href='#'
												className={`nav-activity-tabs  ${activeTab === 'quotation' ? 'active' : ''
													}`}
												onClick={e => {
													e.preventDefault();
													setActiveTab('quotation');
												}}
											>
												<span>
													<Translate text={'quotation'} />{' '}
												</span>
											</a>
										</li>

										<li>
											<a
												href='#'
												className={`nav-activity-tabs  ${activeTab === 'contract' ? 'active' : ''
													}`}
												onClick={e => {
													e.preventDefault();
													setActiveTab('contract');
												}}
											>
												{' '}
												<span>
													<Translate text={'contract'} />{' '}
												</span>
											</a>
										</li>

										<li>
											<a
												href='#'
												className={`nav-activity-tabs  ${activeTab === 'others' ? 'active' : ''
													}`}
												onClick={e => {
													e.preventDefault();
													setActiveTab('others');
												}}
											>
												{' '}
												<span>
													<Translate text={'others'} />{' '}
												</span>
											</a>
										</li>
									</ul>
								</div>

								{filesLoader[activeTab] ? (
									<div className='mb-4 mt-5 w-100' style={{ minHeight: '180px' }}>
										<ContentOverlay active={true} />
									</div>
								) : files[activeTab] && files[activeTab].length > 0 ? (
									<>
										{props.isDeivered ? (
											<></>
										) : (
											<>
												<div className='file-manager-control'>
													<div className='file-manager-check'>
														<div className='checkbox icheck-success'>
															<input
																id={activeTab}
																type='checkbox'
																onChange={handleAllChecked}
																value={activeTab}
																checked={
																	_.isObject(checkFile[activeTab]) &&
																		!_.isEmpty(checkFile[activeTab]) &&
																		!_.isEmpty(files[activeTab]) &&
																		checkFile[activeTab].length ===
																		files[activeTab].length
																		? true
																		: false
																}
															/>
															<label htmlFor={activeTab}>
																<Translate text={'Select All'} />
															</label>
														</div>
													</div>

													{_.isObject(checkFile[activeTab]) &&
														!_.isEmpty(checkFile[activeTab]) ? (
														<div className={`file-manager-action`}>
															<div className='file-manager-btn float-right'>
																<a
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		handleFileDelete();
																	}}
																>
																	{' '}
																	<i className='ico icon-delete'></i>
																	<span>
																		{' '}
																		<Translate text={'delete'} />
																	</span>
																</a>
															</div>

															<div className='file-manager-btn float-right mr-2'>
																<a
																	href='#'
																	onClick={e => {
																		e.preventDefault();
																		setEditshow(!editshow);
																		if (editshow)
																			document.addEventListener(
																				'click',
																				setEditshow(false),
																			);
																	}}
																>
																	<i className='ico icon-folder'></i>
																	<span>
																		{' '}
																		<Translate text={'Move To'} />
																	</span>
																</a>
																<div
																	ref={node}
																	className={`custom-drop file-manager-drop-align ${editshow ? '' : 'hide'
																		}`}
																>
																	<ul className='more-dropdown'>
																		<li
																			style={
																				activeTab === 'quotation'
																					? { display: 'none' }
																					: {}
																			}
																		>
																			<a
																				href='#'
																				onClick={e => {
																					e.preventDefault();
																					handleFileMoveTo('quotation');
																				}}
																			>
																				<i className='ico icon-folder'></i>
																				<Translate text={'quotation'} />
																			</a>
																		</li>
																		<li
																			style={
																				activeTab === 'contract'
																					? { display: 'none' }
																					: {}
																			}
																		>
																			<a
																				href='#'
																				onClick={e => {
																					e.preventDefault();
																					handleFileMoveTo('contract');
																				}}
																			>
																				<i className='ico icon-folder'></i>
																				<Translate text={'contract'} />
																			</a>
																		</li>
																		<li
																			style={
																				activeTab === 'others'
																					? { display: 'none' }
																					: {}
																			}
																		>
																			<a
																				href='#'
																				onClick={e => {
																					e.preventDefault();
																					handleFileMoveTo('others');
																				}}
																			>
																				<i className='ico icon-folder'></i>
																				<Translate text={'others'} />
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													) : (
														<></>
													)}
												</div>
											</>
										)}

										{files[activeTab].map((file, index) => {
											return (
												<FileInfo
													key={index}
													file={file}
													dealersettings={props.dealersettings}
													selectedfiles={checkFile[activeTab]}
													handleCheckChange={handleCheckChange}
													isDeivered={props.isDeivered}
													enquiryID={props.enquiryID}
													contactID={props.contactID}
													pathToDelete={`stock/${props.enquiryID}/files`}
													history={props.history}
												></FileInfo>
											);
										})}
									</>
								) : (
									<div className="spinner-loader w-100 p-5 no-data-flex justify-content-center align-items-cente" style={{ minHeight: '245px' }}>
										<div className="no-data-flex h-100 justify-content-center align-items-center ">
											<div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
											<div className="no-data-txt mt-2">  <Translate text={'No files'} /></div>
										</div>
									</div>
								)}
							</>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<button
						type='button'
						className='btn btn-default float-right'
						onClick={e => {
							e.preventDefault();
							props.handleClose()
						}}
					>
						<Translate text={'Close'} />
					</button>
				</Modal.Footer>
			</Modal>
			<PopUpModal show={modelAddShow}>
				<AddFile
					show={modelAddShow}
					enquiryID={props.enquiryID}
					contactID={props.contactID}
					handleClose={handleFileClose}
					dealersettings={props.dealersettings}
					clientUsers={props.clientUsers}
					groupUsers={props.groupUsers}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
				></AddFile>
			</PopUpModal>
		</>
	);
};

export default Files;
