import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment';
import { ReactSelect, AntDateRangePicker } from '../../../components';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import CommonHelper from '../../../services/common';

const ReportDetails = (props) => {
    const [report, setReport] = useState(props.report);
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false);
    const [dateOptions, setDateOptions] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [dates, setDates] = useState([]);

    useEffect(() => {
        if (props.dealersettings
            && props.dealersettings.client
            && props.dealersettings.client.weekStart) {
            moment.updateLocale('en', {
                week: {
                    dow: moment().day(props.dealersettings.client.weekStart ? props.dealersettings.client.weekStart : 1).day(),
                },
            })
        }

        // console.log('M R E P O R T', props.report.value, )
        let _options = [];
        if (report.frequency === 'daily') {
            _options.push({ label: 'Today', value: moment().format('YYYY-MM-DD') })
            _options.push({ label: 'Yesterday', value: moment().add(-1, 'days').format('YYYY-MM-DD') })
        }
        else if (report.frequency === 'weekly') {
            _options.push({ label: 'This Week', value: moment().format('YYYY-MM-DD') })
            _options.push({ label: 'Last Week', value: moment().add(-1, 'week').endOf('week').format('YYYY-MM-DD') })
        }
        else {
            _options.push({ label: 'This Month', value: moment().format('YYYY-MM-DD') })
            _options.push({ label: 'Last Month', value: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD') })
        }
        if (props.report.customDate === true) {
            _options.push({ label: 'Custom', value: 'custom' });
        }
        setDateOptions(_options)
    }, [])

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const handleSubmit = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(report.date)) {
            formIsValid = false;
            errors['date'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        try {
            const sendEmail = window.firebase.functions().httpsCallable('reports-sendEmail');
            sendEmail({
                documentID: report.documentID,
                date: report.date
            }).then((response) => {
                setLoader(false)
                if (response.data.success) {
                    toast.notify('Email sent successfully.', {
                        duration: 2000,
                    });
                    props.handleClose();
                }
                else {
                    Swal.fire(response.data.message, '', 'error')
                }
            })

        }
        catch (error) {
            setLoader(false)
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }

    const handleExcelDownload = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(report.date)) {
            formIsValid = false;
            errors['date'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        try {
            const downloadExcel = window.firebase.functions().httpsCallable(`reports-${report.url}`);

            const data = {
                "documentID": report.documentID,
                "startDate": moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                "endDate": moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
            };
            downloadExcel(data).then((response) => {
                //console.log('reports-McLarenUsedExcelReport', response)
                if (response?.data?.data?.downloadURL) {
                    toast.closeAll();
                    Swal.fire({
                        icon: 'success',
                        title: CommonHelper.showLocale(props.dealersettings, 'Report downloaded successfully.'),
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.open(response?.data?.data?.downloadURL, '_blank');
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
                setLoader(false)
            })

        }
        catch (error) {
            setLoader(false)
            console.log(error);
            Swal.fire('Something went wrong', '', 'error')
        }
    }

    return !_.isEmpty(report) ? (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-scheduler-manager"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title className='w-100'>{'Report Scheduler'}
                    <div className="float-right scheduler-manager-status">
                        {report.strStatus}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            {
                                !_.isEmpty(report.lastExecution) ? (
                                    <div className="scheduler-manager-title">
                                        <div className="scheduler-manager-flex">
                                            <div className="scheduler-manager-icon"> <i className="ico icon-files-document"></i> </div>
                                            <div className="scheduler-manager-data"> <strong>{report.name}</strong>
                                                <div className="scheduler-manager-execution">Last Execution : <strong>{report.lastExecution}</strong></div>
                                            </div>
                                        </div>
                                        {
                                            !_.isEmpty(report.fileURL) ? (
                                                <div className="scheduler-manager-dwnld-btn">
                                                    <button type="button" className="btn btn-default float-left" onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(report.fileURL, '_blank');
                                                    }}>
                                                        <i className="ico icon-mail-download scheduler-manager-ico"></i> Download</button>
                                                </div>
                                            ) : (<></>)
                                        }

                                    </div>
                                ) : (<></>)
                            }
                            {
                                !_.isEmpty(report.errorMessage) ? (
                                    <div className="alert alert-warning scheduler-manager-alert" role="alert"> {report.errorMessage}</div>

                                ) : (<></>)
                            }
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <label>Report Data</label>
                                    <ReactSelect
                                        options={dateOptions}
                                        name={"date"}
                                        placeholder={'select report data'}
                                        onChange={(e) => {
                                            if (e) {
                                                if (e.value === "custom") {
                                                    setReport({
                                                        ...report,
                                                        date: e ? e.value : null
                                                    });
                                                    setShowDateRangePicker(true);
                                                } else {
                                                    setReport({
                                                        ...report,
                                                        date: e ? e.value : null
                                                    });
                                                    setShowDateRangePicker(false);
                                                }
                                            }
                                        }}
                                        value={report.date}
                                        classNamePrefix={`${errorFields["date"]} cursor-pointer basic-select`}
                                        removeClearable={false}
                                    >

                                    </ReactSelect>
                                </div>
                            </div>
                            {
                                showDateRangePicker ? (
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label>Date</label>
                                            <AntDateRangePicker
                                                value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                                id={"dashboard-date-range"}
                                                name={'staticreports-date'}
                                                format='DD/MM/YYYY'
                                                onChange={(e) => { handleDateRangeChange(e, 'staticreports-date') }}
                                                placeholder='DD/MM/YYYY'
                                                onCalendarChange={(value) => {
                                                    if (value) {
                                                        const [start, end] = value;
                                                        setDates([start, end]);
                                                    }
                                                    else {
                                                        setDates([])
                                                    }
                                                }}

                                            //disabledDate={disabledDate}
                                            />
                                        </div>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                {
                    showDateRangePicker ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-primary float-right ml-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleExcelDownload()
                                }}
                            >
                                {
                                    loader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                Download
                            </button>
                            <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => { props.handleClose() }}
                            >Cancel
                            </button>
                        </>
                    ) : (
                        <>
                            <button

                                type="button"
                                className="btn btn-primary float-right ml-2"
                                onClick={(e) => { handleSubmit() }}

                            >
                                {
                                    loader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                Send Now
                            </button>
                            <button
                                type="button"
                                className="btn btn-default float-right"
                                onClick={(e) => { props.handleClose() }}
                            >Cancel
                            </button>
                        </>

                    )}
            </Modal.Footer>
        </Modal >

    ) : (<></>)
}

export default ReportDetails