export const objCafeItem = {
    documentID: '',
    name: '',
    image: '',
    category: '',
    isDeleted: false,
    isAvailable: true,
    sortOrder: 0,
    sortIndex: null,
    subList: []
};
export const cafeSettingsFields = [
    {
        name: 'Name',
        value: 'cafeName',
        flex: 1,
        default: true
    },
    {
        name: 'Available',
        value: 'isAvailable',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const mandatoryFields = ['image', 'name', 'category']

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]