/** LIBRARIES */
import React, {
    useRef,
    useState
} from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
//import { default as _images } from '../../images';
import Translate from '../../../constants/translate';
//import CustomerlogReducer from './customerlogReducer';
import CustomerEditLog from '../../pipeline/quickview/editLogs';


function AddCallNotes(props) {
    const [customerlog, setCustomerlog] = useState(props.customerlog)
    const textareaRef = useRef(null);

    const handlechangelog = (e, customerlog) => {
        setCustomerlog({
            ...customerlog,
            [e.target.name]: e.target.value
        })
    }

    const handlesavelog = (e, customerlog, completedTab, mentions) => {
        props.handleClose(customerlog, mentions)
    }

    const handlecancellog = () => {
        props.handleClose()
    }

    return (
        <>
            <Modal show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity"
            >
                <Modal.Header closeButton>
                    <Modal.Title> <Translate text={props.title} /></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style" style={{ minHeight: '200px' }}>
                                <div className='mid-column-wrap'>
                                    <div className='activity-nav-editmode pl-3 pr-3'>
                                        <CustomerEditLog
                                            handlechangelog={handlechangelog}
                                            handlesavelog={handlesavelog}
                                            handlecancellog={handlecancellog}
                                            customerlog={customerlog}
                                            textareaRef={textareaRef}
                                            loading={false}
                                            clientUsers={props.clientUsers}
                                            dealersettings={props.dealersettings}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddCallNotes;
