import React from 'react'
import Translate from '../../constants/translate';

export const objEventPro = {
    documentID: null,
    clientID: null,
    clientName: null,
    clientIDs: null,
    projectId: null,
    displayID: null,
    levelID: null,
    oemID: null,

    pipeline: '',
    stage: '',
    status: '',
    level: '',
    timezone: '',
    displayName: '',

    title: null,
    description: null,
    imageURL: null,
    remainder: null,
    location: null,
    state: '',
    areaCode: '',
    country: '',
    visibility: 'private',

    isFiles: false,
    isFav: false,
    isNewEvent: false,
    enableEOI: false,

    startDate: null,
    endDate: null,
    eventDates: [],
    stageDate: null,
    closedDate: null,
    publishedDate: null,
    liveDate: null,

    //dynamicFields: {},
    counts: {},
    stageHistory: [],
    //statusHistory: [],
    tags: [],

    owner: '',
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
};

export const defaultMandatoryEventPro = ['title', 'location', 'startDate', 'endDate', 'level']
export const defaultMandatoryAttendees = ['firstName']

export const objAttendees = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    eventsProID: null,
    contactID: null,
    contactType: 'Potential Customer',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    userImageURL: '',
    userSignature: '',
    dob: null,
    gender: '',
    isDLScan: false,
    address: '',
    state: '',
    areaCode: '',
    country: '',
    phoneCode: '',
    phone: '',
    email: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    owner: '',
    leadStatus: '',
    dietaryPreference: '',
    source: '',
    eventSlot: '',
    dataLocation: '',
    status: '',
    terms: {},
    dynamicFields: {},
    ownedVehicle: {},
    notes: [],
    requirements: [],
    testDrives: [],
    guests: [],
    checkinDate: null,
    guestOf: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
}

export const attendeesMapVM = {
    clientID: null,
    projectId: null,
    groupID: null,
    regionID: null,
    oemID: null,
    contactType: 'Potential Customer',
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    userImageURL: '',
    userSignature: '',
    dob: null,
    gender: '',
    isDLScan: false,
    address: '',
    state: '',
    areaCode: '',
    country: '',
    phoneCode: '',
    phone: '',
    email: '',
    licenseNo: '',
    licenseExpiry: null,
    licenseFrontURL: '',
    licenseBackURL: '',
    licenseType: '',
    licenseState: '',
    optinPhone: true,
    optinEmail: true,
    optinPost: true,
    optinSMS: true,
    owner: '',
    terms: {},
}

export const eventProMapVM = {
    documentID: null,
    status: '',
    title: null,
    description: null,
    location: null,
    visibility: 'private',
    startDate: null,
    endDate: null,
    owner: '',
};

export const requirementMapVM = {
    make: '',
    model: '',
    saleType: '',
    type: '',
    group: '',
    year: '',
    regNo: '',
    vinNo: '',
    extColor: '',
    intColor: '',
    stockNo: '',
    transmission: '',
    warrantyStartDate: null,
    warrantyExp: null,
    price: null,
};

export const testDriveMapVM = {
    startDate: null,
    startOdometer: 0,
    endDate: null,
    endOdometer: 0,
    expectedReturn: null,
    insurance: {},
    terms: {},
};

export const dietaryVM = [
    {
        name: 'Veg Meals',
        value: 'Veg-Meals'
    },
    {
        name: 'Non-Veg Meals',
        value: 'Non-Veg-Meals'
    }
]

export const attendeesDFVM = [{
    subList: [
        "Artura",
        "GT",
        "750S Coupe",
        "750S Spider",
        "Ultimate"
    ],
    saleType: "New",
    make: "McLaren",
    name: "New Cars",
    active: true,
    mappingKey: "model",
    type: "multiselect",
    value: "settings-new-cars"
},
{
    subList: [
        "720S",
        "720S Spider",
        "750S Coupe",
        "750S Spider",
        "600LT",
        "600LT Spider",
        "765LT",
        "765LT Spider",
        "620R",
        "540C",
        "570GT",
        "570S",
        "570S Spider",
        "P1",
        "F1",
        "Other"
    ],
    saleType: "Preowned",
    make: "McLaren",
    name: "Qualified Cars",
    active: true,
    mappingKey: "model",
    type: "multiselect",
    value: "settings-preowned-cars"
},
{
    subList: [
        "Accessories",
        "Extended Warranty",
        "Services And Maintenance",
        "Other"
    ],
    name: "Aftersales",
    active: true,
    type: "multiselect",
    value: "settings-aftersales"
},
{
    subList: [
        "Driving",
        "Lifestyle",
        "Pure McLaren GT Series",
        "Other"
    ],
    name: "Experiences",
    active: true,
    type: "multiselect",
    value: "settings-experiences"
},
{
    subList: [
        "MSO Commissions"
    ],
    name: "MSO Commissions",
    active: true,
    type: "select",
    value: "settings-mso-commissions"
},
{
    subList: [],
    name: "Purchase Intention",
    active: true,
    mappingKey: "purchaseIntention",
    type: "monthYear",
    value: "settngs-purchase-intention"
},
{
    subList: [
        "Conquest (Never Owned McLaren Before)",
        "Loyalty (Currently Owns)",
        "Lapsed (Previously Owned)"
    ],
    name: "Customer Type",
    active: true,
    type: "select",
    mappingKey: "contactType",
    value: "settings-customer-type"
}]

export const eventCountsVM = {
    attendees: 0,
    invitations: 0,
    accepted: 0,
    tentative: 0,
    rejected: 0,
    checkedIn: 0,
    new: 0,
    testDrive: 0
}
export const allEventsProFields = [
    // {
    //     name: 'Favourite',
    //     value: 'documentID',
    //     subText: 'isFav',
    //     width: 80,
    //     default: true,
    //     type: 'favorite',
    //     flex: 0,
    //     align: 'center'
    // },
    // {
    //     name: 'Event Number',
    //     value: 'displayID',
    //     flex: 1,
    //     default: true,
    //     nestedText: 'dealerName',
    //     nestedTextTitle: 'Dealer Name',
    // },
    {
        name: 'Title',
        value: 'title',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        // subText: 'displayID',
        // subTextTitle: 'Event ID',
    },
    {
        name: 'Location',
        value: 'location',
        flex: 1,
        default: true,
    },
    {
        name: 'Event Date',
        value: 'eventDate',
        flex: 1,
        default: true,
        nestedText: 'eventTime',
        nestedTextTitle: 'Event Time',
    },
    // {
    //     name: 'Event Time',
    //     value: 'eventTime',
    //     flex: 1,
    //     default: true,
    // },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        default: true,
        subText: 'addedBy',
        subTextTitle: 'Created By'
    },
    {
        name: 'Last Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By'
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        flex: 1,
        default: true,
        elementName: 'visibility'
    },
    {
        name: 'Region/Subregion/Group/Client',
        value: 'dealersNameTag',
        flex: 1,
        default: true,
        elementName: 'dealersName'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName'
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
    },
    {
        name: 'Stage Date',
        value: 'stageDate',
        flex: 1,
    },
    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
    },
    {
        name: 'CLosed Date',
        value: 'closedDate',
        flex: 1
    },
    {
        name: 'Published Date',
        value: 'publishedDate',
        flex: 1
    },
    {
        name: 'Live Date',
        value: 'liveDate',
        flex: 1
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const tiggerFrequency = [
    { active: true, value: 'instant', label: 'Instant', name: 'Instant' },
    { active: true, value: 'afterMins', label: 'Minutes (No.in Minutes)', name: 'Minutes' },
    { active: true, value: 'afterHours', label: 'Hours (No.in Hours)', name: 'Hours' },
    { active: true, value: 'afterDays', label: 'Days (No.in Days)', name: 'Days' },
]

export const attendeeStatus = [
    { active: true, value: 'accepted', label: 'Accepted' },
    { active: true, value: 'tentative', label: 'Tentative' },
    { active: true, value: 'declined', label: 'Declined' },
    { active: true, value: 'eoi-response', label: 'EOI-Response' },
    { active: true, value: 'eoi-confirmed', label: 'EOI-Confirmed' },
    { active: true, value: 'eoi-unqualified', label: 'EOI Unqualified' },
    { active: true, value: 'checkIn', label: 'Checked In' },
]

export const frequencyOptions = [
    { active: true, value: 'before', label: 'Before' },
    { active: true, value: 'after', label: 'After' }
]

export const triggerDateOptions = [
    { active: true, index: 5, status: null, value: 'startDate', label: 'Event Start Date' },
    { active: true, index: 5, status: null, value: 'endDate', label: 'Event End Date' },
    { active: true, index: 2, status: 'published', value: 'publishedDate', label: 'Published Date' },
    { active: true, index: 3, status: 'live', value: 'liveDate', label: 'Live Date' },
    { active: true, index: 4, status: 'closed', value: 'closedDate', label: 'Closed Date' },
    { active: true, index: 5, status: null, value: 'customDate', label: 'Custom Date' }
]

export const emailTo = [
    { active: true, status: 'eventsProStatus', value: 'all', label: 'All Attendees' },
    //{ active: true, status: 'eventsProStatus', value: 'statusAccepted', label: 'All Confirmed Attendees' },
    { active: true, status: 'eventsProAttendeeStatus', value: 'attendee', label: 'Accepted/Declined/CheckedIn Attendees' },
    { active: true, status: 'eventsProStatus', value: 'isShowTrue', label: 'Event Show Attendees' },
    { active: true, status: 'eventsProStatus', value: 'isShowFalse', label: 'Event No Show Attendees' },
    //{ active: true, status: 'eventsProStatus', value: 'testdrive', label: 'Test Driven Attendees' },
]

export const createList = [
    { active: true, value: 'html', label: <><i className="ico icon-add mr-1"></i> <Translate text={'Add Email HTML'} /></> },
    { active: true, value: 'editor', label: <><i className="ico icon-add mr-1"></i> <Translate text={'Add Email Template'} /></> }
]

export const actionList = [
    { active: true, value: 'edit', label: <><i className="ico icon-edit mr-1"></i> <Translate text={'Edit'} /></> },
    { active: true, value: 'clone', label: <><i className="ico icon-rearrange mr-1"></i> <Translate text={'Clone'} /></> }
]

export const userLevelOptions = [
    { value: 'oem', label: 'OEM', index: 1 },
    { value: 'region', label: 'Region', index: 2 },
    { value: 'subregion', label: 'Subregion', index: 3 },
    { value: 'group', label: 'Group', index: 4 },
    { value: 'individual', label: 'Individual', index: 5 }
]