import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../../../constants/translate';
import images from '../../../../images';
import _ from 'lodash';
import Swal from 'sweetalert2';
import toast from 'toasted-notes'
import NumberFormat from 'react-number-format';

const DisableModal = ({
	title,
	show,
	handleClose,
	selectedClient,
	moduleToChange,
	disableAllNylasEmail,
	nylasDisableLoader,
	clientUsers,
	enableAddons,
	data
}) => {
	const [state, setState] = useState({
		nylasSeats: !_.isEmpty(data.settings) ? data.settings.nylasSeats : '0'
	});
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(true);
	const [users, setUsers] = useState();
	const [singleUserDisableLoader, setSingleUserDisableLoader] = useState('');

	useEffect(() => {
		async function getClientSettings(clientID) {
			const doc = await window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get()
				let settingsDoc = doc.data();
				let usersList = settingsDoc.users

				const promises = []
				const allUsers = []
				usersList.forEach(user => {
						promises.push(window.firebase.firestore().collection('users').doc(user.id).get())
				});
				const snapshots = await Promise.all(promises)
				snapshots.forEach(snap => {
						const dataDoc = { ...snap.data(), 'documentID': snap.id };
						allUsers.push(dataDoc)
				})
				setUsers(allUsers.filter(user => !_.isEmpty(user.nylas)))
				setLoader(false)
		}
		if (!_.isEmpty(selectedClient) && !_.isEmpty(selectedClient)) {
			getClientSettings(selectedClient)
		}
	}, [])
	
	const handleChange = e => {
		if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
			if (e.target.value >= 50000) {
				return
			}
			setState({ ...state, [e.target.name]: e.target.value });
			if (e.target.value.length > 0) {
				setErrorFields({
					...errorFields,
					[e.target.name]: ''
				})
			}
		}
	};

	const disableSingleUser = async user => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to disable this user.',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async result => {
			if (result.value) {
				setSingleUserDisableLoader(user.documentID)
				const nylasCancelAccount = window.firebase.functions().httpsCallable('nylassync-cancelUserAccount');
        const data = await nylasCancelAccount({
            "accountID": user.nylas.account_id,
						"userID": user.documentID
        })
        // console.log(data)

        if (data?.data?.success === true) {
            toast.notify(`User's integration has been disabled successfully.`, {
                duration: 2000
            })
						const newUsers = users.filter(u => u.documentID !== user.documentID)
						setUsers(newUsers)
        }
        else {
            Swal.fire(data?.data?.message, '', 'error')
        }
				setSingleUserDisableLoader('')
			}
		});
	}

	const onSave = () => {
		if (loader) {
			return
		}
		let nylasSeats = state.nylasSeats ? state.nylasSeats : 0
		nylasSeats = Number(nylasSeats)
		if (nylasSeats < users.length) {
			Swal.fire('You cannot set the seats below the integrated users!', '', 'error')
			setErrorFields({
				nylasSeats: 'input_error',
			});
			return
		}
		if (!state.nylasSeats) {
			setErrorFields({
				nylasSeats: 'input_error',
			});
		} else {
			setErrorFields({});
			// console.log('THIS SHOULD BE THE PARAMS',
			// moduleToChange.module,
			// moduleToChange.checked,
			// state,)
			enableAddons(
				moduleToChange.module,
				moduleToChange.checked,
				state)
			handleClose(null)
		}
		
	}

	return (
		<Modal
			id='add-cafe'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-centered modal-configure-integration'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						<Translate text={`Disable ${title} Integration`} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container-fluid'>
					<div className='row'>
						<div className='form-style w-100'>
							<div className='form-row'>
								<div className='form-group col-md-12'>
									<div className='configure-integration-logo'>
										<img src={images.nylas} alt='' />
									</div>
								</div>
							</div>
							<div className='form-row '>
								<p
									style={{
										margin: '0px auto 15px auto',
										color: '#0000007a',
										paddingBottom: '15px',
									}}
								>
									Nylas Email API provides to enable you to integrate full email
									functionality into your app. You can get more information
									about nylas from{' '}
									<a
										href='https://www.nylas.com/products/email-api/'
										target='_blank'
										style={{ color: '#2b57e2', fontWeight: '600' }}
									>
										here
									</a>
									.
								</p>
							</div>
							<div className='subscription-box-wrapper'>
								<div className='subscription-box-left'>
									<h4> Your Subscription</h4>
									<table
										width='100%'
										cellSpacing='0'
										cellPadding='0'
										border='0'
									>
										<tbody>
											<tr>
												<td width='90'>
													<div className='subscription-users-input'>
														<input
															type='text'
															className={`form-control ${errorFields['nylasSeats']}`}
															value={state.nylasSeats ? state.nylasSeats : ''}
															placeholder={''}
															onChange={handleChange}
															name='nylasSeats'
														/>
													</div>
												</td>
												<td>
													<span className='subscription-user-title'>Seats</span>
												</td>
											</tr>
										</tbody>
									</table>

									<p>
										We charge you at the end of each month according to the
										number of subscribed users in the system. Once a
										subscription expires, the user should lose access to the
										integration.{' '}
									</p>
								</div>
								<div className='subscription-box-right'>
									<div className='subscription-price-wrapper'>
										<h3>${state.nylasSeats ? state.nylasSeats : 0}</h3>
										<h5>Per Month</h5>
									</div> 
								</div>
							</div>
							{!loader ? (
								<>
									<div className='subscription-user-tablehead mt-3 d-flex align-items-center'>
										<span>User Management</span> 
										{(!users.length || users.length <= 0) ? (<></>) : (
											<div className="list-count-btn ml-3"><span style={{fontSize: '13px', fontWeight: '400'}}>Active Users</span><span className="list-count" style={{fontSize: '13px', fontWeight: '400'}}> {users.length} </span></div>
										)}
									</div>
									<div className='common-table' style={{maxHeight: '200px', overflowY: 'scroll'}}>
										<table className='table table-bordered'>
											<thead>
												<tr>
													<th scope='col' className='head-light' width='30%'>
														Users
													</th>
													<th scope='col' className='head-light' width='30%'>
														Email
													</th>
													<th scope='col' className='head-light' width='25%'>
														Role
													</th>

													<th scope='col' className='head-light text-center' width='15%'>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{users.length > 0 ? users.map(user => (
													<tr>
														<td>{user.name ? user.name : ''}</td>
														<td>{user.email ? user.email : ''}</td>
														<td>{user.role ? user.role : ''} </td>

														<td className=' text-center'>
															<button
																type='button'
																className='btn btn-secondary btn-disable-mini float-right'
																onClick={() => disableSingleUser(user)}
															>
																{singleUserDisableLoader === user.documentID ? (
																	<span
																		className='spinner-border spinner-button mr-1'
																		role='status'
																		aria-hidden='true'
																	></span>
																) : (
																	<></>
																)}
																Disable
															</button>
														</td>
													</tr>
												)) : (<></>)}
											</tbody>
										</table>
									</div>
								</>
							) : (
								<div style={{height: '232px'}}>
									<div className="spinner-loader pt-5 h-100"><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
								</div>
							)}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
				<button
					type='button'
					className='btn btn-primary float-right ml-2 '
					aria-label='Save'
					onClick={onSave}
				>
					{' '}
					<Translate text={'Save'} />
				</button>
				<button
					type='button'
					className='btn btn-default float-right'
					onClick={() => handleClose(null)}
				>
					{/* {
								loader ?
									(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<></>)
							} */}
					<Translate text={'close'} />
				</button>
				<button
					type='button'
					className='btn btn-secondary float-left'
					onClick={() => {
						Swal.fire({
							title: 'Are you sure?',
							text: 'Do you want to disable all users?',
							icon: 'info',
							showCancelButton: true,
							confirmButtonText: 'Yes',
							cancelButtonText: 'No',
						}).then(async result => {
							if (result.value) {
								disableAllNylasEmail(selectedClient)}
							}
						)}
					}
				>
					{nylasDisableLoader ? (
						<span
							className='spinner-border spinner-button mr-1'
							role='status'
							aria-hidden='true'
						></span>
					) : (
						<></>
					)}
					<Translate text={'Disable All Users'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DisableModal;
