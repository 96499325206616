/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import ReactExport from 'react-export-excel-xlsx-fix';
import moment from 'moment'
import Translate from '../../constants/translate';
import Filters from './filters'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [pipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ open: 0, won: 0, lost: 0, delivered: 0, total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        var dpl = props.pipeline.filter(e => e.default)[0] ? props.pipeline.filter(e => e.default)[0].value : '';
        var dataArray = [];
        for (var i in props.stageCounts) {
            if (i !== 'Converted')
                dataArray.push(props.stageCounts[i]);
        }
        if (dpl === 'LeadsBucket') {
            setCounts({
                total: _.sumBy(dataArray, "total")
            })
        }
        else {
            setCounts({
                open: _.sumBy(dataArray, "open"),
                won: _.sumBy(dataArray, "won"),
                lost: _.sumBy(dataArray, "lost"),
                delivered: _.sumBy(dataArray, "delivered"),
                total: _.sumBy(dataArray, "total")
            })
        }
    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.pipelineOEMFilter) && Object.keys(JSON.parse(localStorage.pipelineOEMFilter)).length > 0) {
            let _pipelineFilter = JSON.parse(localStorage.pipelineOEMFilter);
            let _filterValue = _pipelineFilter.value;

            if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                setSearchKeywords(_filterValue['keywords']);
            else
                setSearchKeywords('');

            setPipelineFilter(_pipelineFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.pipelineOEMFilter])

    
    const _permisions = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions)) ?
        props.dealersettings.rolePermissions.permissions : null;
    
    const exportData = ((!_.isEmpty(_permisions) && _permisions.exportData) ? true : false);

    return (
        <div className="filter-panel">
            
            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search ml-2">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.enquiryOEMSearchText = _val;

                            if (!_val)
                                props.handlesearchText('')

                            if (props.view !== 'list' && _val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.enquiryOEMSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
            
            {
                (!_.isEmpty(props.selectedIds) && props.showMore) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span><Translate text={Object.keys(props.selectedIds).length > 1 ? 'Selected Enquiries' : 'Selected Enquiry'}/></span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">
                {
                    props.showMore
                        ?
                        <>
                            

                            {
                                (exportData && counts.total > 0)
                                    ?
                                    <>
                                        <div className="filter-item-btn ml-2">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                props.excelDownload(counts.total)
                                            }} title={'Excel Export'}>
                                                <i className="ico icon-excel-download"></i>
                                            </a>
                                            <ExcelFile ref={props.csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`enquiry_${moment().format('DD_MM_YYYY_HH_mm')}`}>
                                                <ExcelSheet dataSet={props.csvData} name={`Sheet1`} />
                                            </ExcelFile>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }


                        </>
                        :
                        <>
                        </>
                }



            </div>
           
            {/**** FILTERS ****/}
            <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={pipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                showPipeline={props.showMore}
                pipeline={props.pipeline}
                pipelineType={'allocation'}
            >
            </Filters>
            

            {
                (!_.isEmpty(props.pipelineCount) || props.pipelineCount > 0) ?
                    (<>
                        <div className="list-count-btn ml-3" >
                            <span>  <Translate text={props.pipelineCount > 1 ? 'Enquiries' : 'Enquiry'}/></span>
                            <span className="list-count"> {props.pipelineCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }

        </div >)
}

export default FilterPanel