import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import _ from 'lodash';
import toast from 'toasted-notes';
import ReactSummernote from 'react-summernote';
import { InputText, ReactSelect, PopUpModal, ReactMultiSelect } from '../../components';
import { visibilities, templateVM, mentions } from './viewModel';
import SummerEditor from '../mail/summereditor/summereditor';
import SendTestEmail from '../settings/workflow/templates/sendTestEmail';
import CommonHelper from '../../services/common';
import Addtemplate from './addTemplate';
import { userLevel } from '../../services/enum';

const AddTemplateHTML = props => {
	const [template, setTemplate] = useState(props.template);
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(false);
	const [email, setEmail] = useState(
		props.template ? props.template.bodyHTML : '<p></p>',
	);
	const [sendEmailModel, setSendEmailModel] = useState(false);
	const [showEditModel, setShowEditModel] = useState({ show: false })
	const newTemplate = !_.isEmpty(props.template) ? false : true

	useEffect(() => {
		if (props.template) {
			// if (!initialized) {
			// console.log('emailType', emailType)
			var node = document.createElement('div');
			node.innerHTML = `${props.template.bodyHTML}`;
			ReactSummernote.insertNode(node);
			// setInitialized(true);
			// }
		}
	}, [props.template]);

	useEffect(() => {
		if (_.isEmpty(props.template)) {
			const docID = window.firebase.firestore().collection(`eventsProTemplates`).doc().id;
			const newtemplateVM = Object.assign({}, templateVM);
			newtemplateVM.documentID = docID;
			if (props.dealersettings.level === userLevel.INDIVIDUAL) {
				newtemplateVM.clientID = props.dealersettings.client.id;
				newtemplateVM.clientIDs = [props.dealersettings.client.id];
				newtemplateVM.level = userLevel.INDIVIDUAL;
			}
			newtemplateVM.addedBy = localStorage.uid;
			newtemplateVM.modifiedBy = localStorage.uid;
			newtemplateVM.addedDate = window.firebase.firestore.Timestamp.now();
			newtemplateVM.modifiedDate = window.firebase.firestore.Timestamp.now();
			newtemplateVM.visibility = 'private';
			newtemplateVM.projectId = props?.dealersettings?.client?.projectId ? props.dealersettings.client.projectId : null;
			setTemplate(newtemplateVM)
		}
	}, [])


	const handleOnChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		var str = value;

		if (str && str.length >= 1) {
			var firstChar = str.charAt(0);
			var remainingStr = str.slice(1);
			str = firstChar.toUpperCase() + remainingStr;
		}
		setTemplate({
			...template,
			[name]: str,
		});

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleSelectChange = (e, data) => {
		if (data.name === 'clientIDs') {
			setTemplate({
				...template,
				[data.name]: [e.value],
				['clientID']: e.value
			});
		}
		else if (data.name === 'visibility' && e.value === 'private') {
			setTemplate({
				...template,
				[data.name]: e.value,
				['clientID']: props.dealersettings.client.id,
				['clientIDs']: [props.dealersettings.client.id]
			});
		}
		else {
			setTemplate({
				...template,
				[data.name]: e.value,
			});
		}

		setErrorFields({
			...errorFields,
			[data.name]: '',
		});
	};

	const handleMultiSelectChange = (selectedOptions, data) => {
		const value = [];
		!_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
			value.push(data.value)
		})
		setTemplate({
			...template,
			[data.name]: value,
		});
		setErrorFields({
			...errorFields,
			[data.name]: '',
		});
	}

	const handleSavetemplate = () => {
		setShowEditModel({
			show: true,
			title: newTemplate ? 'Add Template' : 'Edit template',
			template: {
				...template,
				['bodyHTML']: email,
			}
		})
		// let formIsValid = true;
		// let errorClass = 'input_error';
		// let _dynamicFields = [];
		// let errors = {};
		// if (_.isEmpty(template['title'])) {
		// 	formIsValid = false;
		// 	errors['title'] = errorClass;
		// }
		// if (_.isEmpty(template['clientIDs'])) {
		// 	formIsValid = false;
		// 	errors['clientIDs'] = errorClass;
		// }
		// if (_.isEmpty(template['category'])) {
		// 	formIsValid = false;
		// 	errors['category'] = errorClass;
		// }
		// setErrorFields(errors);
		// if (!formIsValid) {
		// 	return;
		// }
		// setLoader(true);
		// template.modifiedDate = window.firebase.firestore.Timestamp.now();
		// template.modifiedBy = localStorage.uid;
		// var _contentEmail = email;
		// // if (props.dynamicOptions) {
		// // 	props.dynamicOptions.forEach(rec => {
		// // 		if (_contentEmail && _contentEmail.includes(`@${rec.name.replace(/ /g, '_')}`)) {
		// // 			_dynamicFields.push(`${rec.value.replace(/@/g, '')}`);
		// // 			_contentEmail = _contentEmail.replace(new RegExp(`@${rec.name.replace(/ /g, '_')}`, 'g'), `@${rec.value}`);
		// // 		}
		// // 	})
		// // }
		// template.bodyHTML = _contentEmail;
		// //template.dynamicFields = _dynamicFields;
		// // template.groupID = localStorage.uid
		// // template.oemID = localStorage.uid
		// // template.regionID = localStorage.uid

		// //	console.log('template at save', template);
		// window.firebase
		// 	.firestore()
		// 	.doc(
		// 		`eventsProTemplates/${template.documentID}`,
		// 	)
		// 	.set({ ..._.pick(template, _.keys(templateVM)) }, { merge: true })
		// 	.then(snapshot => {
		// 		setLoader(false);
		// 		toast.notify(
		// 			props.newTemplate === true
		// 				? 'Template added successfully'
		// 				: 'Template updated successfully',
		// 			{
		// 				duration: 2000,
		// 			},
		// 		);
		// 		props.handleClose(template);
		// 	})
		// 	.catch(error => {
		// 		console.error(error);
		// 		toast.notify('Something went wrong', {
		// 			duration: 2000,
		// 		});
		// 	});
	};
	const handleChange = obj => {
		setEmail(obj);
	};

	const handleModelClose = template => {
		setSendEmailModel(false);
		console.log('email sent');
	};

	const handleModelHTMLClose = (template) => {
		setShowEditModel({ show: false })
		setSendEmailModel(false)
		if (!_.isEmpty(template)) {
			if (props.returnAfterCreation) {
				props.handleClose(template)
			} else {
				props.handleClose()
			}
		}
	}

	return _.isEmpty(template) ? (<></>) : (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className={`btn btn-secondary float-left ml-2 `}
								onClick={() => setSendEmailModel(true)}
							>
								<Translate text={'Send Test'} />
							</button>
							<button
								type='button'
								className={`btn btn-primary float-left ml-2 mr-2 email-send-btn `}
								onClick={handleSavetemplate}
							>
								{loader ? (
									<span
										className='spinner-border spinner-button mr-1'
										role='status'
										aria-hidden='true'
									></span>
								) : (
									<></>
								)}
								<Translate text={'save'} />
							</button>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid'>
								<div className='form-style w-100'>
									<div className='form-row'>
										{/* 										
										<div className='form-group col-md-3'>
											<label><Translate text={'title'} /></label>
											<InputText
												autoComplete='off'
												placeholder={'title'}
												className={`form-control ${errorFields['title']}`}
												name='title'
												onChange={handleOnChange}
												value={template.title}
												maxLength={150}
											/>
										</div>
										<div className='form-group col-md-3'>
											<label>
												<Translate text={'category'} />
											</label>
											<ReactSelect
												options={props?.dealersettings?.client?.eventsPro?.categories ?
													props.dealersettings.client.eventsPro.categories.map(rec => {
														return {
															value: rec.value,
															label: rec.name
														}
													})
													: []}
												name={'category'}
												placeholder={'select category'}
												onChange={handleSelectChange}
												value={template.category}
												classNamePrefix={`${errorFields["category"]} basic-select`}
												removeClearable={true}
												isSearchable={false}
											></ReactSelect>
										</div>
										<div className='form-group col-md-3'>
											<label>
												<Translate text={'visibility'} />
											</label>
											<ReactSelect
												options={visibilities}
												name={'visibility'}
												placeholder={'select visibility'}
												onChange={handleSelectChange}
												value={template.visibility}
												classNamePrefix={`basic-select`}
												removeClearable={true}
												isSearchable={false}
											></ReactSelect>
										</div>

										<div className='form-group col-md-3'>
											<label>
												<Translate text={'Dealer'} />
											</label>
											{
												template.visibility === 'private' ? (
													<ReactSelect
														options={props?.dealersettings?.clients ? props.dealersettings.clients.filter(a => CommonHelper.getAllEventsProDealers(props.dealersettings, true).includes(a.documentID)).map(rec => {
															return {
																label: rec.name,
																value: rec.documentID
															}
														}) : []}
														name={'clientIDs'}
														placeholder={'select dealer'}
														onChange={handleSelectChange}
														value={template.clientIDs ? template.clientIDs[0] : null}
														classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
														removeClearable={true}
														isSearchable={false}
													></ReactSelect>
												) : (
													<ReactMultiSelect
														options={props?.dealersettings?.clients ? props.dealersettings.clients.filter(a => CommonHelper.getEventsProDealers(props.dealersettings, true).includes(a.documentID)).map(rec => {
															return {
																label: rec.name,
																value: rec.documentID
															}
														}) : []}
														name={"clientIDs"}
														placeholder={'select dealers'}
														onChange={handleMultiSelectChange}
														value={template.clientIDs ? template.clientIDs : []}
														classNamePrefix={`${errorFields["clientIDs"]} basic-select`}
														isMulti={true}
														isSearchable={true}
														menuPlacement='bottom'
													>
													</ReactMultiSelect>
												)
											}
										</div> */}
										<div className='form-group col-md-12 react-summer-editor mt-3'>
											<SummerEditor
												value={email}
												height={window.innerHeight - 250 + 'px'}
												handleChange={handleChange}
												emailType={'compose'}
												templateMentionsEnabled={true}
												otherMentions={props.formMentions}
												customFonts={props.dealersettings?.customFonts || []}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<PopUpModal show={sendEmailModel}>
				<SendTestEmail
					{...props}
					show={sendEmailModel}
					title={'Send Test Email'}
					handleClose={handleModelClose}
					template={{
						...template,
						bodyHTML: email
					}}
					selectedClient={template?.clientIDs?.[0] || props.dealersettings.client.id}
					mentions={mentions}
				/>
			</PopUpModal>
			<PopUpModal show={showEditModel.show}>
				<Addtemplate
					{...props}
					{...showEditModel}
					handleClose={handleModelHTMLClose}
					newTemplate={newTemplate}
				/>
			</PopUpModal>
		</>
	);
};

export default AddTemplateHTML;
