export const enqiuryOptions = [
    {
        name: 'Enquiry Type',
        type: 'select',
        value: 'enquiryType'
    },
    {
        name: 'Origin',
        type: 'select',
        value: 'origin'
    },
    {
        name: 'Campaign',
        type: 'select',
        value: 'campaign'
    },
    {
        name: 'Label',
        type: 'select',
        value: 'label'
    },
    {
        name: 'Purchase Intention',
        type: 'date',
        value: 'purchaseIntention'
    },
    {
        name: 'Delivery Date',
        type: 'date',
        value: 'deliveryDate'
    },
    {
        name: 'Chassis No.',
        type: 'text',
        value: 'chassisNo'
    },
    {
        name: 'Reg. No.',
        type: 'text',
        value: 'regNo'
    },
    {
        name: 'Stock No.',
        type: 'text',
        value: 'stockNo'
    },
    {
        name: 'Warranty Start Date',
        type: 'date',
        value: 'warrantyStartDate'
    },
    {
        name: 'Warranty Expiry',
        type: 'date',
        value: 'warrantyExpiry'
    },
    {
        name: 'Lost Reason',
        type: 'select',
        value: 'lostReason'
    },
    {
        name: 'Lost Sub Reason',
        type: 'select',
        value: 'lostSubReason'
    },
    {
        name: 'Finance Required',
        type: 'toggle',
        value: 'isFinanceRequired'
    },
    {
        name: 'Finance Status',
        type: 'select',
        value: 'financeStatus'
    },
    {
        name: 'Deposit Amount',
        type: 'price',
        value: 'depositAmount'
    },
    {
        name: 'Deposit Receipt Number',
        type: 'text',
        value: 'depositReciptNumber'
    },
    {
        name: 'Gross Amount',
        type: 'price',
        value: 'grossAmount'
    },
    {
        name: 'Sales Contract Number',
        type: 'text',
        value: 'contractNumber'
    },
    {
        name: 'Lead Source',
        type: 'select',
        value: 'leadSource'
    }
]