import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
import _images from '../../images'
import CommonHelper from '../../services/common';
import Translate from '../../constants/translate';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { firestoreDB } from '../../services/helper';
import { PopoverPanel } from '../../components';
import images from '../../images';

const CalendarEvents = (props) => {
    const [dateState, setDateState] = useState(new Date())
    const [eventsPopOver, setEventsPopOver] = useState({ showpopover: false, targetpopover: null })
    const eventsProStatus = props?.dealersettings?.client?.eventsPro?.eventsProStatus;

    const changeDate = (e) => {
        setDateState(e)
    }



    return (
        <>
            <div className="dashboard-box">
                {
                    props.loader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); props.setLoader(true); }}> <i className="ico icon-refresh"></i></a>

                    </div>
                    <h3><Translate text={'Events'} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ minHeight: `350px` }}>
                    <Calendar
                        onChange={changeDate}
                        value={dateState}
                        className={'dashboard-calendar-mini'}
                        onClickDay={(value, event) => {
                            const _events = props.monthData.filter(a => a.eventDate === moment(value).format('YYYY-MM-DD'));
                            if (_events.length > 0) {
                                setEventsPopOver({
                                    showpopover: true,
                                    targetpopover: event.target,
                                    events: _.uniqBy(_events, 'id')
                                })
                            }
                        }}
                        tileClassName={'calendar-mini-day'}
                        tileContent={({ date }) => {
                            if (props.monthData.some(a => a.eventDate === moment(date).format('YYYY-MM-DD'))) {
                                return <span className="dots">
                                    <span className="dot"></span>
                                </span>;
                            }
                        }}
                        view={'month'}
                        prev2Label={null}
                        next2Label={null}
                        onActiveStartDateChange={({ activeStartDate }) => {
                            props.setLoader(true)
                            props.setDateRange({
                                startDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).startOf('month').add(-7, 'days').toDate()),
                                endDate: window.firebase.firestore.Timestamp.fromDate(moment(activeStartDate).endOf('month').add(7, 'days').toDate()),
                                date: moment(activeStartDate).format('YYYY-MM-DD')
                            })
                        }}
                        navigationLabel={({ date }) => <div className='dashboard-calendar-mini-header'>{`${moment(date).format('MMM YYYY')}`}</div>}
                    />
                </div>
            </div>
            <PopoverPanel
                showpopover={eventsPopOver.showpopover}
                targetpopover={eventsPopOver.targetpopover}
                title='Events'
                position='right'
                id='date-events'
                className='popover-pipeline-switch'
                closepopover={() => {
                    setEventsPopOver({ showpopover: false, targetpopover: '' });
                }}>
                <div style={{ padding: '15px' }}>
                    {
                        eventsPopOver?.events?.map((event, index) => {
                            return <div key={index} className='events-dashboard-livewrap mb-2' onClick={(e) => {
                                e.preventDefault();
                                props.history.push("/eventspro/details/" + event.id);
                            }}>
                                <div className="events-dashboard-live-flex">
                                    <div className="events-dashboard-live-image">
                                        {
                                            event.imageURL ? (
                                                <img src={event.imageURL} alt="" width="70" height="70" />
                                            ) : (
                                                <img src={images.noEvent} alt="" width="70" height="70" />
                                            )
                                        }
                                    </div>
                                    <div className="events-dashboard-live-data">
                                        <div className="events-dashboard-card-title">{event.title}</div>
                                        <div className="events-dashboard-card-sub"><i className="ico icon-location"></i> {event.location} </div>
                                        <div className="events-dashboard-card-sub">
                                            <i className="ico icon-date"></i> {CommonHelper.showCombineDate(event.startDate, event.endDate, event.timezone)}
                                            <i className="ico icon-time ml-2"></i> {CommonHelper.showCombineTime(event.startDate, event.endDate, event.timezone)}
                                            </div>
                                        {
                                            event.status ? (
                                                <div className="events-dashboard-card-sub">
                                                    {
                                                        _.find(eventsProStatus, { value: event.status }) ? (
                                                            <div className={`badge badge-pill badge-status-${_.find(eventsProStatus, { value: event.status }).value === 'New' ? 'open' : 'empty'}`} style={{ background: (_.find(eventsProStatus, { value: event.status })?.color ? _.find(eventsProStatus, { value: event.status }).color : '#6b717f') }}>{_.find(eventsProStatus, { value: event.status }).name} </div>
                                                        ) : (
                                                            <div className={`badge badge-pill badge-status-${event.status.toLowerCase()}`} >{event.status} </div>
                                                        )
                                                    }
                                                </div>
                                            ) : (<></>)
                                        }

                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </PopoverPanel>
        </>
    );
}

export default CalendarEvents;