import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputText, ReactSelect, InputCheckBox } from '../../../../components'
import { lookupVM, clientLookups } from './viewModel'
import { statusColors } from '../../../../services/enum'

const AddLookup = (props) => {
    const [lookup, setLookup] = useState(props.lookup)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newLookup = !_.isEmpty(props.lookup) ? false : true
    const levelOptions = [
        { active: true, value: 'oem', label: 'OEM', index: 1 },
        { active: true, value: 'region', label: 'Region', index: 2 },
        { active: true, value: 'group', label: 'Group', index: 3 },
        { active: true, value: 'individual', label: 'Individual', index: 4 }
    ]

    useEffect(() => {
        if (_.isEmpty(props.lookup)) {
            const newlookupVM = Object.assign({}, lookupVM);
            setLookup(newlookupVM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setLookup({
                ...lookup,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setLookup({
                    ...lookup,
                    [name]: newarray1.join(' '),
                    ['value']: newLookup ? uuid() : lookup.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setLookup({
                    ...lookup,
                    [name]: str,
                    ['value']: newLookup ? uuid() : lookup.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSelectChange = (e, data) => {
        if (data.name === 'level') {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['levelID']: '',
                ['groupIDs']: '',
                ['clientIDs']: ''
            });
        }
        else {
            setLookup({
                ...lookup,
                [data.name]: e.value,
                ['levelName']: (data.name === 'level' && e.value === 'oem') ? props.selectedOEM.name : data.name === 'levelID' ? e.data.name : '',
                ['groupIDs']: e.groupIDs,
                ['clientIDs']: e.clientIDs
            });
        }


        setErrorFields({
            ...errorFields,
            [data.name]: ''
        });
    }

    const handlecolorSelectChange = (e, data) => {
        setLookup({
            ...lookup,
            [data.name]: e ? e.value : ''
        });
    }

    const handleCheckedChange = (e) => {
        setLookup({
            ...lookup,
            [e.target.name]: e.target.checked
        })
    }

    const saveLookup = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let activeLookupName = (_.find(clientLookups, { 'value': props.activeLookup }) ? _.find(clientLookups, { 'value': props.activeLookup }).name : 'Lookups')
        if (_.isEmpty(lookup['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        if (_.isEmpty(lookup['value'])) {
            formIsValid = false;
            errors['value'] = errorClass;
        }
        if (_.isEmpty(lookup['level'])) {
            formIsValid = false;
            errors['level'] = errorClass;
        }
        if (!_.isEmpty(lookup.level) && lookup.level !== 'oem' && _.isEmpty(lookup['levelID'])) {
            formIsValid = false;
            errors['levelID'] = errorClass;
        }
        setErrorFields(errors)
        if (newLookup && props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && (lookup.level === 'oem' ? item.level === lookup.level : lookup.level === 'group' ? (item.level !== 'client' && item.levelID === lookup.levelID) : item.levelID === lookup.levelID))) {
            Swal.fire(activeLookupName + ' with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        let querySet = `clientSettings/${props.selectedOEM.id}`;
        let _regionLookups = [];
        let _groupLookups = [];
        let _clientLookups = []
        if (lookup.level !== 'oem') {
            querySet = `clientSettings/${props.selectedOEM.id}/${lookup.level === 'group' ? 'groups' : lookup.level === 'region' ? 'regions' : 'clients'}/${lookup.levelID}`;
        }
        if (lookup.level === 'oem') {
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'region')) {
                _regionLookups = _.chain(props.allLookups[props.activeLookup].filter(item => item.level === 'region'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'group')) {
                _groupLookups = _.chain(props.allLookups[props.activeLookup].filter(item => item.level === 'group'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'client')) {
                _clientLookups = _.chain(props.allLookups[props.activeLookup].filter(item => item.level === 'client'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
        }
        else if (lookup.level === 'region') {
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'group')) {
                _groupLookups = _.chain(_.filter(props.allLookups[props.activeLookup], (v) => _.indexOf(lookup.groupIDs, v.levelID) >= 0 && v.level === 'group'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'individual')) {
                _clientLookups = _.chain(_.filter(props.allLookups[props.activeLookup], (v) => _.indexOf(lookup.clientIDs, v.levelID) >= 0 && v.level === 'individual'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
        }
        else if (lookup.level === 'group') {
            if (props.allLookups[props.activeLookup] && props.allLookups[props.activeLookup].some(item => item.value === lookup.value && item.level === 'client')) {
                _clientLookups = _.chain(props.allLookups[props.activeLookup].filter(item => item.level === 'client'))
                    .groupBy("levelID")
                    .map((value, key) => ({
                        levelID: key, lookups: [..._.map(value.filter(item => item.value !== lookup.value), function (obj) {
                            return {
                                ..._.pick(obj, _.keys(lookupVM))
                            };
                        })]
                    }))
                    .value()
            }
        }

        _clientLookups.forEach(client => {
            window.firebase.firestore().doc(`clientSettings/${props.selectedOEM.id}/clients/${client.levelID}`)
                .set({ [props.activeLookup]: client.lookups }, { merge: true })
        })
        _groupLookups.forEach(group => {
            window.firebase.firestore().doc(`clientSettings/${props.selectedOEM.id}/groups/${group.levelID}`)
                .set({ [props.activeLookup]: group.lookups }, { merge: true })
        })
        _regionLookups.forEach(region => {
            window.firebase.firestore().doc(`clientSettings/${props.selectedOEM.id}/regions/${region.levelID}`)
                .set({ [props.activeLookup]: region.lookups }, { merge: true })
        })
        window.firebase.firestore().doc(querySet)
            .set({
                [props.activeLookup]: newLookup ? (props.allLookups[props.activeLookup] ? [..._.map(props.allLookups[props.activeLookup].filter(item => item.level !== 'oem' ? (item.level === lookup.level && item.levelID === lookup.levelID) : item.level === lookup.level), function (obj) { return { ..._.pick(obj, _.keys(lookupVM)) }; }), _.pick(lookup, _.keys(lookupVM))] : [_.pick(lookup, _.keys(lookupVM))]) :
                    [..._.map(props.allLookups[props.activeLookup].filter(item => item.level !== 'oem' ? (item.level === lookup.level && item.levelID === lookup.levelID) : item.level === lookup.level), function (obj) {
                        return obj.value === lookup.value ? { ..._.pick(lookup, _.keys(lookupVM)) } : { ..._.pick(obj, _.keys(lookupVM)) };
                    })]
            }, { merge: true })
            .then(snapshot => {
                setLoader(false)
                window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.id}/clients`)
                    .get()
                    .then(clientsCollection => {
                        if (clientsCollection.docs.length > 0) {
                            const batch = window.firebase.firestore().batch();
                            clientsCollection.docs.forEach(client => {
                                batch.set(window.firebase.firestore().doc(`clients/${client.id}`), {
                                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                    settingsUpdatePriority: 'normal',
                                    settingsUpdateBy: props?.dealersettings?.name || null,
                                    settingsUpdateText: 'Dynamic Field Settings has been updated',
                                }, { merge: true });
                            })
                            batch.commit().catch((error) => {
                                console.error("Error updating document: ", error);
                            });
                        }
                    })
                toast.notify((newLookup === true ? activeLookupName + ' added successfully' : activeLookupName + ' updated successfully'), {
                    duration: 2000
                })
                props.handleClose(lookup, newLookup)
            })
            .catch(error => {
                console.log(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }


    return _.isEmpty(lookup) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={lookup.name}
                                    />
                                </div>
                                {
                                    props.activeLookup === 'financeStatus' ? (
                                        <div className="form-group col-md-12">
                                            <label > Colour</label>
                                            <ReactSelect
                                                options={statusColors}
                                                name={"color"}
                                                placeholder={'select status colour'}
                                                onChange={handlecolorSelectChange}
                                                value={lookup.color}
                                                classNamePrefix={`basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>
                                    ) : (<></>)
                                }
                                <div className="form-group col-md-12">
                                    <label >Level</label>
                                    <ReactSelect
                                        options={levelOptions}
                                        name={"level"}
                                        placeholder={'select level'}
                                        onChange={handleSelectChange}
                                        value={lookup.level}
                                        classNamePrefix={`${!newLookup ? 'select-readonly' : ''} ${errorFields["level"]} basic-select`}
                                        removeClearable={true}
                                        isDisabled={!newLookup}
                                    >
                                    </ReactSelect>
                                </div>
                                {
                                    (!_.isEmpty(lookup.level) && lookup.level !== 'oem') ? (<div className="form-group col-md-12">
                                        <label >{lookup.level === 'group' ? 'Group' : 'Client'}</label>
                                        <ReactSelect
                                            options={lookup.level === 'group' ? props.groupOptions : lookup.level === 'region' ? props.regionOptions : props.clientOptions}
                                            name={'levelID'}
                                            placeholder={`select ${lookup.level}`}
                                            onChange={handleSelectChange}
                                            value={lookup.levelID}
                                            classNamePrefix={`${!newLookup ? 'select-readonly' : ''}  ${errorFields["levelID"]} basic-select`}
                                            removeClearable={true}
                                            isDisabled={!newLookup}
                                        >
                                        </ReactSelect>
                                    </div>) : (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <span className="switch switch-sm float-left">
                    <InputCheckBox
                        className={`switch`}
                        id={`lookup-active`}
                        name={'active'}
                        checked={lookup.active ? lookup.active : false}
                        onChange={(e) => { handleCheckedChange(e) }} />
                    <label htmlFor={`lookup-active`}>Active</label>
                </span>
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveLookup(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                        Save
                        </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddLookup;