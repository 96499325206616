/** LIBRARIES */
import React, { PureComponent } from "react";
import moment from 'moment'
import _ from 'lodash';
import { Tooltip } from 'antd';
import CommonHelper from '../../../services/common';
import Timer from './timer'
import { serviceStatus } from "../../../services/enum";
import { PopUpModal } from '../../../components';
import TimeLog from './log'
import AddTimeLog from './addLog'
import { default as _images } from "../../../images";
import Translate from '../../../constants/translate';
import { firestoreDB } from "../../../services/helper";

export default class WorkLog extends PureComponent {
    constructor(props) {
        super(props);
        this.unsubscribeWorkLogs = null;
        this.state = {
            loading: false,
            workLogs: Object.assign([]),
            totalSpent: 0,
            showLog: false,
            addLog: false
        };
    }

    loadData = () => {
        const { serviceID, dealersettings } = this.props;
        this.unsubscribeWorkLogs = firestoreDB(dealersettings).firestore().collection(`serviceJobs/${serviceID}/workLogs`)
            //.where('addedBy', '==', localStorage.uid)
            .onSnapshot(this.onCollectionUpdate);
    }

    onCollectionUpdate = querySnapshot => {

        const workLogs = [];
        let totalSpent = 0;
        querySnapshot.forEach(_data => {
            const data = Object.assign({});
            for (let [key, value] of Object.entries(_data.data())) {
                data[key] = value;
            }
            data.documentID = _data.id;
            workLogs.push(data);
            totalSpent += data.timeSpent ? data.timeSpent : 0;
        });

        this.setState({
            workLogs: _.orderBy(workLogs, ["startTime"], ["desc"]),
            totalSpent: totalSpent
        });
    };

    componentDidMount() {

        this.loadData();

        // var duration = moment.duration(10, 'hours').add(10, 'minutes').add(10, 'seconds');
        // duration = moment.duration(duration, 'milliseconds');
        // const asSeconds = duration.asSeconds();
        // const hours = duration.hours();
        // const minutes = duration.minutes();
        // const seconds = duration.seconds();
        // console.log('asSeconds', asSeconds)
        // console.log('hours', hours)
        // console.log('minutes', minutes)
        // console.log('seconds', seconds)
    }

    componentWillUnmount() {
        this.unsubscribeWorkLogs && this.unsubscribeWorkLogs();
    }

    addLog = () => {

        const objData = Object.assign({});
        const { serviceID, dealersettings, service, updateDealerSettings } = this.props;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';

        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        objData.mannual = false;
        objData.active = true;
        objData.documentID = firestoreDB(dealersettings).firestore().collection('serviceJobs').doc().id;
        objData.clientID = dealersettings ? dealersettings.client.id : '';
        objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
        objData.serviceID = serviceID;
        objData.startTime = window.firebase.firestore.Timestamp.now();

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/workLogs/${objData.documentID}`).set(objData, { merge: true }).then((docRef) => {

            let _data = {
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }
            if (service?.technicianStatus !== serviceStatus.WORKINPROGRESS)
                _data.technicianStatus = serviceStatus.WORKINPROGRESS;

            firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}`).set(_data, { merge: true })

            window.firebase.firestore().doc(`users/${objData.addedBy}`).set({ "workingServiceID": serviceID }, { merge: true });

            if (updateDealerSettings && objData.addedBy === localStorage.uid)
                updateDealerSettings('workingServiceID', serviceID)

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    updateLog = (_log) => {

        const objData = Object.assign({});
        const { serviceID, updateDealerSettings, dealersettings } = this.props;

        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';

        objData.active = false;

        var startTime = moment.unix(_log.startTime.seconds);
        //var currentTime = moment();
        //var duration = moment.duration(currentTime.diff(startTime));
        objData.timeSpent = moment().diff(startTime, 'seconds')

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }
        firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}/workLogs/${_log.documentID}`).set(objData, { merge: true }).then((docRef) => {

            firestoreDB(dealersettings).firestore().doc(`serviceJobs/${serviceID}`).set({
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                modifiedFrom: 'web'
            }, { merge: true });

            if (_log.addedBy) window.firebase.firestore().doc(`users/${_log.addedBy}`).set({ "workingServiceID": null }, { merge: true });
            if (updateDealerSettings && _log.addedBy === localStorage.uid)
                updateDealerSettings('workingServiceID', null)
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };


    render() {
        const { workLogs, totalSpent, showLog, addLog } = this.state;
        const { clientUsers, groupUsers, dealersettings, serviceID, canModify, isIcon } = this.props;
        const activeTimer = _.find(workLogs, { 'active': true, 'addedBy': localStorage.uid })

        const _servicePermissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.servicePermissions)) ? dealersettings.rolePermissions.servicePermissions : null);
        const technicianAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.technician) ? true : false);
        const deleteLogAccess = ((!_.isEmpty(_servicePermissions) && _servicePermissions.deleteLogAccess) ? true : false);
        return (
            <>
                <div className="float-right tech-worklog-timer-mainwrap">
                    {
                        canModify && technicianAccess
                            ?
                            <>
                                {
                                    !_.isEmpty(activeTimer)
                                        ?
                                        <div className="tech-worklog-timer-wrap">
                                            <div className="tech-worklog-timer-icon active">
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.updateLog(activeTimer);
                                                }}>

                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                        <path d="M15,16H13V8H15M11,16H9V8H11M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="tech-worklog-timer-digit">
                                                <span><Translate text={'Started at'} /> {moment.unix(activeTimer.startTime.seconds).format('hh:mm A')}</span>

                                                <div className="timer active"><Timer timerStarted={activeTimer.startTime}></Timer></div></div>
                                            <div className="tech-worklog-timer-user"><img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, activeTimer.addedBy)} alt="" width="300" height="300" className="img-object-fit" /></div>

                                        </div>
                                        :
                                        <div className="tech-worklog-timer-wrap">
                                            {
                                                dealersettings.workingServiceID && dealersettings.workingServiceID !== serviceID
                                                    ?
                                                    <>
                                                        <div className="tech-worklog-timer-icon">
                                                            <a href="#" title="Timer is Running on another job" onClick={(e) => {
                                                                e.preventDefault();
                                                            }}>
                                                                <img src={_images.timerOut} alt="" width="18" />
                                                            </a>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="tech-worklog-timer-icon">
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.addLog();
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                    <path d="M10,16.5V7.5L16,12M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </>
                                            }

                                            <div className="tech-worklog-timer-digit">
                                                <span><Translate text={'No Active Timer'} /></span>

                                                <div className="timer">00:00:00</div></div>
                                            <div className="tech-worklog-timer-user"><img src={CommonHelper.getUserImagebyId(!_.isEmpty(groupUsers) ? groupUsers : clientUsers, localStorage.uid)} alt="" width="300" height="300" className="img-object-fit" /></div>

                                        </div>

                                }
                            </>
                            :
                            <></>
                    }



                    <div className="tech-worklog-btn ml-2">
                        <a href="#" className={`left ${isIcon ? 'pr-2' : ''}`} onClick={(e) => {
                            e.preventDefault();
                            if (totalSpent > 0) this.setState({ showLog: true });
                        }}>
                            {
                                isIcon && canModify
                                    ?
                                    <>
                                        <Tooltip placement="bottom" title={`( ${CommonHelper.showDuration(totalSpent)} logged )`} >
                                            <i className={`ico icon-timer ${(totalSpent > 0) ? 'blue-color' : ''}`}></i>
                                        </Tooltip>
                                    </>
                                    :
                                    <>
                                        <i className={`ico icon-timer ${(totalSpent > 0) ? 'blue-color' : ''}`}></i>
                                        <span><Translate text={'Worklogs'} /></span>
                                        {
                                            (totalSpent > 0)
                                                ?
                                                <div className="tech-worklog-logged">( <strong className="mr-1">{CommonHelper.showDuration(totalSpent)}</strong> <Translate text={'logged'} lowerCase={true} /> )</div>
                                                :
                                                <div className="tech-worklog-logged">{'( No Log )'}</div>
                                        }
                                    </>
                            }
                        </a>

                        {
                            canModify
                                ?
                                <>
                                    <a href="#" className="edit right" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ addLog: true });
                                    }}>
                                        <i className="ico icon-add"></i></a>
                                </>
                                :
                                <></>
                        }


                    </div>




                </div>


                <PopUpModal show={showLog}>
                    <TimeLog  {...this.props}
                        show={showLog}
                        workLogs={workLogs}
                        canModify={canModify}
                        deleteLogAccess={deleteLogAccess}
                        totalSpent={totalSpent}
                        handleClose={() => { this.setState({ showLog: false }); }}
                    >
                    </TimeLog>
                </PopUpModal>

                <PopUpModal show={addLog}>
                    <AddTimeLog {...this.props}
                        show={addLog}
                        workLogs={workLogs}
                        handleClose={() => { this.setState({ addLog: false }); }}
                    >
                    </AddTimeLog>
                </PopUpModal>

            </>

        );
    }
}

