/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import { ReactSelect, InputText, AntDatePicker, ImageCropHook, PopUpModal, InputRadio } from '../../../../components';
import { default as _images } from '../../../../images';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import AddFleet from '../../../fleet/add'
import AddTestDrive from '../../../testdrive/add'
/** VIEW-MODELS */
import { objStock } from './viewModel'
import { stockStatus } from '../../../../services/enum'
import { storageBucket } from '../../../../services/helper';

let sale_type = [];
let locations = [];
let clientID = [];
const stock_status = [
    { active: true, value: 'available', label: 'Available' },
    { active: true, value: 'unavailable', label: 'Unavailable' },
    { active: true, value: 'inProduction', label: 'In Production' },
]

// const locations = [
//     { active: true, value: 'Dubai Warehouse', label: 'Dubai Warehouse' },
//     { active: true, value: 'Sharjah Warehouse', label: 'Sharjah Warehouse' },
//     { active: true, value: 'Ajman Warehouse', label: 'Ajman Warehouse' }
// ]

export default class AddStock extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.refStock = window.firebase.firestore().collection('stock');
        this.unsubscribeSTKS = null;
        this.mandatoryFields = [];
        this.state = {
            stock: Object.assign({}, objStock),
            currentDealers: props.dealersettings.clients.map(client => {
                return { value: client.id, label: client.name }
            }),
            errors: {},
            makes: [],
            models: [],
            types: [],
            years: [],
            currentStatus: null,
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            },
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            },
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
            addMake: false,
            addType: false,
            addModel: false,
        }
        //this.handleDateChange = this.handleDateChange.bind(this);
    }

    //#region  LOAD DATA
    async loadStock(props) {
        const { show, dealersettings, docID, enableSale, enableTestDrive, enableLoan } = props;

        if (show === true && docID) {

            this.unsubscribeSTKS = this.refStock
                .doc(docID)
                .onSnapshot(this.onStockCollectionUpdate);

            /*const refData = await this.refStock.doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());
                const stock = Object.assign({}, objStock);
                for (let [key, value] of Object.entries(objData)) {
                    stock[key] = value;
                }

                if (!stock.documentID)
                    stock.documentID = docID;

                if (stock.regDate && typeof stock.regDate === 'object' && Object.keys(stock.regDate).length > 0)
                    stock.regDate = moment.unix(stock.regDate.seconds).format('YYYY-MM-DD')

                if (stock.warrantyExp && typeof stock.warrantyExp === 'object' && Object.keys(stock.warrantyExp).length > 0)
                    stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD')

                if (stock.inStockDate && typeof stock.inStockDate === 'object' && Object.keys(stock.inStockDate).length > 0)
                    stock.inStockDate = moment.unix(stock.inStockDate.seconds).format('YYYY-MM-DD')

                if (stock.stockInDate && typeof stock.stockInDate === 'object' && Object.keys(stock.stockInDate).length > 0)
                    stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('YYYY-MM-DD')

                this.setState({ stock: stock, currentStatus: stock.status });

            } else {
               //"No such document2!");
            }*/

        }
        else if (show === true && (!docID)) {
            const stock = Object.assign({}, objStock);
            stock.documentID = this.refStock.doc().id;
            stock.status = stockStatus.AVAILABLE;

            if (enableSale)
                stock.isSale = enableSale;
            if (enableTestDrive)
                stock.isTestDrive = enableTestDrive;
            if (enableLoan)
                stock.isLoan = enableLoan;
            clientID = dealersettings.client.id;
            this.setState({ stock: stock });
        }

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            let setttings = dealersettings.client.settings;

            if (clientID && dealersettings && dealersettings.group &&
                dealersettings.group.clients && dealersettings.group.clients[clientID] && dealersettings.group.clients[clientID].settings)
                setttings = dealersettings.group.clients[clientID].settings;

            locations = [];
            setttings.stockLocation && setttings.stockLocation.forEach(doc => {
                locations.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.stock))
                this.mandatoryFields = setttings.mandatoryFields.stock.split(',');

            const brands = dealersettings.allMakeModels;

            const years = [];
            const makes = [];

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                years.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            _.orderBy(brands, ['name'], ['asc']).forEach((doc) => {
                makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                });
            });

            this.setState({
                makes: makes,
                years: years
            });

        }



    }

    onStockCollectionUpdate = (docSnapshot) => {
        if (!docSnapshot.exists) {
            return;
        }
        const { enableSale, enableTestDrive, enableLoan, dealersettings } = this.props;
        let makes = !_.isEmpty(this.state.makes) ? this.state.makes : dealersettings.allMakeModels;

        const objData = Object.assign({}, docSnapshot.data());
        const stock = Object.assign({}, objStock);
        for (let [key, value] of Object.entries(objData)) {
            stock[key] = value;
        }

        if (!stock.documentID)
            stock.documentID = docSnapshot.id;

        if (!_.isEmpty(stock.regDate))
            stock.regDate = moment.unix(stock.regDate.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.warrantyStartDate))
            stock.warrantyStartDate = moment.unix(stock.warrantyStartDate.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.warrantyExp))
            stock.warrantyExp = moment.unix(stock.warrantyExp.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.inStockDate))
            stock.inStockDate = moment.unix(stock.inStockDate.seconds).format('YYYY-MM-DD')

        if (!_.isEmpty(stock.stockInDate))
            stock.stockInDate = moment.unix(stock.stockInDate.seconds).format('YYYY-MM-DD')

        if (enableSale)
            stock.isSale = enableSale;
        if (enableTestDrive)
            stock.isTestDrive = enableTestDrive;
        if (enableLoan)
            stock.isLoan = enableLoan;

        if (stock.make) {
            const types = [];
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === stock.make)[0]) ? makes.filter(m => m.value === stock.make)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
            this.setState({ types: _.orderBy(types, ['label'], ['asc']), models: models });

        }
        clientID = stock.clientID;
        this.setState({ stock: stock, currentStatus: stock.status });
    }

    componentDidMount() {
        this.setState({
            stock: {
                isSale: true
            }
        })

        this._isMounted = true;
        if (this._isMounted)
            this.loadStock(this.props);

    }

    componentWillUnmount() {
        this.unsubscribeSTKS && this.unsubscribeSTKS();
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('prevState', prevState)
        // console.log('prevProps', prevProps)
        // console.log('statestock', this.state.stock)
        if (!prevState.stock.stockInDate && this.state.stock.stockInDate) {
            if (this.state.stock.oemID && this.state.stock.clientID && this.state.stock.isAllocated)
                this.setState({
                    hideFooter: true
                })
        }
    }

    //#endregion

    //#region CHANGE EVENT STOCK
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.stock);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        let state = Object.assign({}, this.state.stock);
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            state[name] = newarray1.join(' ');
            this.setState({ stock: state }, () => { this.errorChange(name); });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            state[name] = str;
            this.setState({ stock: state }, () => { this.errorChange(name); });
        }
    }

    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.stock);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ stock: fields }, () => { this.errorChange(name); });

    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}  

        let state = Object.assign({}, this.state.stock);
        state[name] = e.floatValue;
        this.setState({ stock: state }, () => { this.errorChange(name); });
    }

    handleSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.stock);
        if (e) {
            state[data.name] = e.value;
            if (data.name === 'clientID') {
                state['clientName'] = this.state.currentDealers.filter(cl => cl.value === e.value)[0].label;
            }
        } else {
            state[data.name] = '';
        }

        this.setState({ stock: state }, () => { this.errorChange(data.name); });

    }

    handleTypeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        state['model'] = '';
        this.setState({ stock: state, models: models }, () => { this.errorChange(data.name); });
    }

    handleMakeChange = (e, data) => {
        let makes = this.state.makes
        let state = Object.assign({}, this.state.stock);
        const types = [];
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (e) {
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];
            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _.orderBy(_models, ['name'], ['asc']).forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }

        state['model'] = '';
        state['type'] = '';
        this.setState({ stock: state, types: _.orderBy(types, ['label'], ['asc']), models: models }, () => { this.errorChange(data.name); });

    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = checked;
        this.setState({ stock: state }, () => {
            this.errorChange(name);
        });

    }
    handleSaleTypeChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.stock);
        state[name] = value;
        this.setState({ stock: state }, () => { this.errorChange(name); });

    };

    handleStatusReload = () => {
        let state = Object.assign({}, this.state.stock);
        state.status = this.state.currentStatus;
        this.setState({ stock: state });
    }



    handleLoanVehicleClose = () => {
        this.setState({
            fleetModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null
            }
        })
    }

    handleTestDriveClose = () => {
        this.setState({
            testDriveModal:
            {
                show: false,
                ID: '',
                title: '',
                clsActive: '',
                stock: null,
                contact: null
            }
        })
    }



    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {
        const { src, id, type, ext } = cropShow;
        this.saveImageToStorage(src, id, ext);

        this.setState({
            //stock: state,
            imageURL: src,
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)
        }
    }

    saveImageToStorage = (imageBase64, id, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        let clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';
        let stockID = this.state.stock.documentID;
        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created one saveImageToStorage')
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${clientID}/stock/${stockID}`).child(uid).getDownloadURL()
                    .then(dataURL => {

                        let state = Object.assign({}, this.state.stock);
                        let _data = Object.assign([], state[id]);
                        _data.push(dataURL);
                        state[id] = Object.assign([], _data);
                        state.documentID = stockID;
                        this.setState({ stock: state, imageURL: null })
                    })
            })
    };

    handleImageDel = (index) => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, `Are you sure`),
            text: CommonHelper.showLocale(this.props, 'Do you want to delete this image.'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        }).then((result) => {
            if (result.value) {
                let state = Object.assign({}, this.state.stock);
                let _data = Object.assign([], state.images);
                _data.splice(index, 1);
                state.images = Object.assign([], _data);
                this.setState({ stock: state })
                toast.notify('Image deleted successfully', {
                    duration: 2000
                })
            }
        })

    }

    //#endregion

    //#region DATEPICKER
    handleDateChange = (val, name) => {
        let state = Object.assign({}, this.state.stock);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;
        this.setState({ stock: state }, () => { this.errorChange(name); });

    };

    handleRegDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['regDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('regDate'); });

    }

    handleWarrantyDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['warrantyExp'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('warrantyExp'); });

    }

    handleInStockDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['inStockDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('inStockDate'); });

    }

    handleAgeDateChange = (moment) => {
        let state = Object.assign({}, this.state.stock);
        state['stockInDate'] = moment.format('YYYY-MM-DD');
        this.setState({ stock: state }, () => { this.errorChange('stockInDate'); });

    }

    handleValueDateChange = (name, date) => {
        var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        if (date.formattedValue !== null && date.formattedValue !== "" && pattern.test(date.formattedValue)) {
            let state = Object.assign({}, this.state.stock);
            state[name] = moment(date.formattedValue, 'DD/MM/YYYY').format('YYYY-MM-DD');
            this.setState({ stock: state });
        }
        else if (!date.formattedValue) {
            let state = Object.assign({}, this.state.stock);
            state[name] = null;
            this.setState({ stock: state });
        }
    }

    limit = (val, max) => {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    dateFormat = (val) => {
        let date = this.limit(val.substring(0, 2), '31');
        let month = this.limit(val.substring(2, 4), '12');
        let year = this.limit(val.substring(4, 8), (moment()._d.getFullYear() + 20).toString);
        let fullDate = date + (month.length ? '/' + month : '') + (year.length ? '/' + year : '');
        return fullDate
    }
    //#endregion

    //#region SAVE INFO
    handleSaveInfo = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.stock);

        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {

            //if (key === 'make' || key === 'model' || key === 'type' || key === 'year' || key === 'saleType' || key === 'stockNo') {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
                formIsValid = false;
                errors[key] = errorClass;
            }
            //}
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            //this.saveStockInfo(this);
            const { currentStatus } = this.state;

            if (currentStatus && (currentStatus === stockStatus.ONLOAN || currentStatus === stockStatus.ONTESTDRIVE) && currentStatus !== fields.status) {
                if (currentStatus === stockStatus.ONTESTDRIVE && !_.isEmpty(fields.testDriveDetail)) {
                    formIsValid = false;
                    this.handleTestDriveStatusChange(fields.testDriveDetail);
                }
                else if (currentStatus === stockStatus.ONLOAN && !_.isEmpty(fields.fleetDetail)) {
                    formIsValid = false;
                    this.handleLoanStatusChange(fields.fleetDetail);
                }
                else {
                    this.saveStockInfo(this);
                }
            }
            else {
                this.saveStockInfo(this);
            }
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }

            return formIsValid;
        }

    };

    handleTestDriveStatusChange = (objDetail) => {
        Swal.fire({
            //title: 'Are you sure?',
            text: CommonHelper.showLocale(this.props, 'Please return test drive vehicle to proceed with the update status'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Return'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    testDriveModal:
                    {
                        show: true,
                        ID: objDetail.testdriveID,
                        title: 'testDrive',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock),
                        contact: (!_.isEmpty(objDetail.contact)) ? objDetail.contact : null
                    }
                });
            }
            else {
                this.handleStatusReload();
                //this.saveStockInfo(this);
            }
        })
    }

    handleLoanStatusChange = (objDetail) => {
        Swal.fire({
            //title: 'Are you sure?',
            text: CommonHelper.showLocale(this.props, 'Please return loan vehicle to proceed with the update status'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Return'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'Cancel'),
        }).then((result) => {
            if (result.value) {
                this.setState({
                    fleetModal:
                    {
                        show: true,
                        ID: objDetail.fleetID,
                        title: 'loanVehicle',
                        clsActive: 'overlay-modal active',
                        stock: Object.assign({}, this.state.stock)
                    }
                });

            }
            else {
                this.handleStatusReload();
                //this.saveStockInfo(this);
            }
        })
    }
    saveStockInfo = (e) => {
        //console.log('saveStockInfo');
        this.isLoading = true;
        const objData = Object.assign({}, this.state.stock);
        const _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
        const _currentDate = window.firebase.firestore.Timestamp.now();
        if (_.isEmpty(objData.addedBy)) {
            objData.addedBy = _userID;
            objData.addedDate = _currentDate;
        }

        if (!_.isEmpty(this.state.currentStatus) && this.state.currentStatus !== objData.status) {
            objData.statusModifiedBy = _userID;
            objData.statusModifiedFrom = 'web';
        }

        if (objData.status !== stockStatus.RESERVED)
            objData.enquiryID = null;

        objData.modifiedBy = _userID;
        objData.modifiedDate = _currentDate;
        let stockID = objData['documentID'];

        objData.regDate = (objData.regDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.regDate)._d) : null);
        objData.warrantyStartDate = (objData.warrantyStartDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.warrantyStartDate)._d) : null);
        objData.warrantyExp = (objData.warrantyExp ? window.firebase.firestore.Timestamp.fromDate(moment(objData.warrantyExp)._d) : null);
        objData.inStockDate = (objData.inStockDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.inStockDate)._d) : null);
        objData.stockInDate = (objData.stockInDate ? window.firebase.firestore.Timestamp.fromDate(moment(objData.stockInDate)._d) : null);
        objData.isAllocated = false

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        // if (!objData.clientID)
        //     objData.clientID = this.props.dealersettings ? this.props.dealersettings.client.id : '';

        if (!objData.settingsID) {
            objData.settingsID = this.props.dealersettings ? this.props.dealersettings.client.settingsID : '';
        }

        if (objData.isAllocated) {
            objData.status = 'available'
            objData.stockInDate = window.firebase.firestore.Timestamp.now()
        }

        if (!stockID) {
            stockID = this.refStock.doc().id;
            //console.log('newly created  stock')
        }

        const refStockData = this.refStock.doc(stockID);
        refStockData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;

            if (this.props.isFilterApplied)
                this.props.updateStock(objData);

            this.props.handleClose(stockID, objData);

        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };
    //#endregion

    handleCustomOption = e => {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        if (e.target.value === '') {
            this.setState({
                stock: {
                    ...this.state.stock,
                    [e.target.name]: ''
                },
                errors: {
                    ...this.state.errors,
                    [e.target.name]: 'input_error'
                }
            })
            return
        }

        this.setState({
            stock: {
                ...this.state.stock,
                [e.target.name]: capitalizeFirstLetter(e.target.value)
            },
            errors: {
                ...this.state.errors,
                [e.target.name]: ''
            }
        })
    }


    render() {
        const { clientUsers, groupUsers, dealersettings, enableSale, enableTestDrive, enableLoan, show } = this.props;

        const { fleetModal, testDriveModal, addMake, addType, addModel } = this.state
        const {
            stockNo,
            make, model, modelDescription, saleType, type,
            year, price, regNo, vinNo, extColor, intColor,
            miles, regDate, warrantyExp, stockInDate, status, warrantyStartDate,
            purchasePrice, location, tradePlate,
            isLoan, isTestDrive, isSale, images,
        } = this.state.stock;

        const currencySymbol = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) &&
            !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

        return (
            <>
                <Modal show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    enforceFocus={false}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-oem-stock"
                >
                    <Modal.Header closeButton>
                        <Modal.Title> <Translate text={this.props.title} /></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="pop-layout form-style">

                                    <div className="addstock-pop-head">
                                        <div className="form-row">
                                            {/* <div id="saleType" className={`form-group col-md-6 ${this.state.errors["saleType"] ? 'custom_error' : ''}`}>
                                                <label > <Translate text={'saleType'} /></label>
                                                <div>
                                                    <div className={`radio icheck-success float-left mr-3`}>
                                                        <InputRadio
                                                            id="New"
                                                            type="radio"
                                                            value="New"
                                                            name="saleType"
                                                            checked={saleType === 'New' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="New"><Translate text={'new'} /></label>
                                                    </div>
                                                    <div className={`radio icheck-success float-left mr-3`}>
                                                        <InputRadio
                                                            id="Demo"
                                                            type="radio"
                                                            value="Demo"
                                                            name="saleType"
                                                            checked={saleType === 'Demo' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="Demo"><Translate text={'demo'} /></label>
                                                    </div>
                                                    <div className={`radio icheck-success float-left`}>
                                                        <InputRadio
                                                            id="Preowned"
                                                            type="radio"
                                                            value="Preowned"
                                                            name="saleType"
                                                            checked={saleType === 'Preowned' ? true : false}
                                                            onChange={this.handleSaleTypeChange}
                                                        />
                                                        <label htmlFor="Preowned"><Translate text={'preowned'} /></label>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="form-group col-md-12">
                                                <label ><Translate text={'availableFor'} /></label>
                                                <div>
                                                    <div className={`checkbox icheck-success float-left mr-3 ${enableTestDrive ? 'form-disable' : ''}`}>
                                                        <input
                                                            type="checkbox"
                                                            id="isTestDrive"
                                                            name="isTestDrive"
                                                            className="uncheck-activity"
                                                            checked={isTestDrive}
                                                            onChange={(e) => {
                                                                this.handleCheckChange(e, 'isTestDrive')
                                                            }} />
                                                        <label htmlFor="isTestDrive"><Translate text={'testDrive'} /></label>
                                                    </div>
                                                    <div className={`checkbox icheck-success float-left mr-3 ${enableLoan ? 'form-disable' : ''}`}>
                                                        <input
                                                            type="checkbox"
                                                            id="isLoan"
                                                            name="isLoan"
                                                            className="uncheck-activity"
                                                            checked={isLoan}
                                                            onChange={(e) => {
                                                                this.handleCheckChange(e, 'isLoan')
                                                            }} />
                                                        <label htmlFor="isLoan"><Translate text={'loanVehicle'} /></label>
                                                    </div>
                                                    <div className={`checkbox icheck-success float-left ${enableSale ? 'form-disable' : ''}`}>
                                                        <input
                                                            type="checkbox"
                                                            id="isSale"
                                                            name="isSale"
                                                            className="uncheck-activity"
                                                            checked={isSale}
                                                            onChange={(e) => {
                                                                // this.handleCheckChange(e, 'isSale')
                                                            }} />
                                                        <label htmlFor="isSale"><Translate text={'sales'} /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="requirement-pop-middle">
                                        {/* <div className="requirement-section-head">Vehicle Information </div> */}

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                {
                                                    !addMake ? (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addMake: true
                                                            })
                                                            setTimeout(() => {
                                                                let elem = document.getElementById('make')
                                                                elem.focus()
                                                            }, 100)
                                                        }}>
                                                        + <Translate text={'add'} />
                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addMake: false,
                                                                errors: {
                                                                    ...this.state.errors,
                                                                    ['make']: 'input_error'
                                                                },
                                                                stock: {
                                                                    ...this.state.stock,
                                                                    ['make']: ''
                                                                }
                                                            })
                                                        }}>
                                                        x <Translate text={'cancel'} />
                                                    </a>)
                                                }
                                                <label> <Translate text={'make'} /></label>
                                                {addMake ?
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'make'}
                                                        className={`form-control ${this.state.errors["make"]}`}
                                                        name="make"
                                                        onChange={this.handleCustomOption}
                                                        value={make}
                                                        id='make'
                                                    /> :
                                                    <ReactSelect
                                                        options={this.state.makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={this.handleMakeChange}
                                                        value={make}
                                                        classNamePrefix={`${this.state.errors["make"]} basic-select`}
                                                        AddUnAvailable={true}
                                                        isDisabled={this.state.stock.isAllocated}
                                                    >
                                                    </ReactSelect>
                                                }
                                            </div>
                                            <div className="form-group col-md-6">
                                                {
                                                    !addType ? (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addType: true
                                                            })
                                                            setTimeout(() => {
                                                                let elem = document.getElementById('type')
                                                                elem.focus()
                                                            }, 100)
                                                        }}>
                                                        + <Translate text={'add'} />
                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addType: false,
                                                                errors: {
                                                                    ...this.state.errors,
                                                                    ['type']: 'input_error'
                                                                },
                                                                stock: {
                                                                    ...this.state.stock,
                                                                    ['type']: ''
                                                                }
                                                            })
                                                        }}>
                                                        x <Translate text={'cancel'} />
                                                    </a>)
                                                }
                                                <label><Translate text={'type'} /></label>
                                                {addType ?
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'type'}
                                                        className={`form-control ${this.state.errors["type"]}`}
                                                        name="type"
                                                        onChange={this.handleCustomOption}
                                                        value={type}
                                                        id="type"
                                                    /> :
                                                    <ReactSelect
                                                        options={this.state.types}
                                                        name={"type"}
                                                        placeholder={'select type'}
                                                        onChange={this.handleTypeChange}
                                                        value={type}
                                                        classNamePrefix={`${this.state.errors["type"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>}
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                {
                                                    !addModel ? (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addModel: true,
                                                            })
                                                            setTimeout(() => {
                                                                let elem = document.getElementById('model')
                                                                elem.focus()
                                                            }, 100)
                                                        }}>
                                                        + <Translate text={'add'} />
                                                    </a>) : (<a href="#" className="inputlink-addmore custom-more"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                addModel: false,
                                                                errors: {
                                                                    ...this.state.errors,
                                                                    ['model']: 'input_error'
                                                                },
                                                                stock: {
                                                                    ...this.state.stock,
                                                                    ['model']: ''
                                                                }
                                                            })
                                                        }}>
                                                        x <Translate text={'cancel'} />
                                                    </a>)
                                                }
                                                <label> <Translate text={'model'} /></label>
                                                {addModel ?
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'model'}
                                                        className={`form-control ${this.state.errors["model"]}`}
                                                        name="model"
                                                        onChange={this.handleCustomOption}
                                                        value={model}
                                                        id="model"
                                                    /> :
                                                    <ReactSelect
                                                        options={this.state.models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={this.handleSelectChange}
                                                        value={model}
                                                        classNamePrefix={`${this.state.errors["model"]} basic-select`}
                                                        AddUnAvailable={true}
                                                    >
                                                    </ReactSelect>}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'year'} /></label>
                                                <ReactSelect
                                                    options={this.state.years}
                                                    name={"year"}
                                                    placeholder={'select year'}
                                                    onChange={this.handleSelectChange}
                                                    value={year}
                                                    classNamePrefix={`${this.state.errors["year"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label> <Translate text={'modelDescription'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'model description'}
                                                    className={`form-control ${this.state.errors["modelDescription"]}`}
                                                    name="modelDescription"
                                                    onChange={this.handleOnChange}
                                                    value={modelDescription}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'exteriorColor'} /></label>
                                                {console.log('extColor', extColor)}
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'exterior colour'}
                                                    className={`form-control ${this.state.errors["extColor"]}`}
                                                    name="extColor"
                                                    onChange={this.handleOnChange}
                                                    value={extColor}
                                                />
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'interiorColor'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'interior colour'}
                                                    className={`form-control ${this.state.errors["intColor"]}`}
                                                    name="intColor"
                                                    onChange={this.handleOnChange}
                                                    value={intColor}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'stockNo'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'stock no'}
                                                    className={`form-control ${this.state.errors["stockNo"]}`}
                                                    name="stockNo"
                                                    onChange={this.handleOnCapChange}
                                                    value={stockNo}
                                                />
                                            </div>

                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'vinNo'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'vin no'}
                                                    className={`form-control ${this.state.errors["vinNo"]}`}
                                                    name="vinNo"
                                                    onChange={this.handleOnCapChange}
                                                    value={vinNo}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'regNo'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'reg no'}
                                                    className={`form-control ${this.state.errors["regNo"]}`}
                                                    name="regNo"
                                                    onChange={this.handleOnCapChange}
                                                    value={regNo}
                                                />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="form-group col-md-6">
                                                <label> <Translate text={'registrationDate'} /></label>
                                                {/* <ReactDatePicker
                                                    value={regDate}
                                                    name={'regDate'}
                                                    onChange={this.handleDateChange}
                                                    format={this.dateFormat}
                                                    placeholder={'DD/MM/YYYY'}
                                                    onValueChange={this.handleValueDateChange.bind(this, 'regDate')}
                                                    className={`form-control ${this.state.errors["regDate"]}`}
                                                /> */}
                                                <AntDatePicker
                                                    value={regDate}
                                                    name={'regDate'}
                                                    onChange={(e) => { this.handleDateChange(e, 'regDate') }}
                                                    format='DD/MM/YYYY'
                                                    placeholder='DD/MM/YYYY'
                                                    className={`form-control ${this.state.errors["regDate"]}`}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'warrantyStartDate'} /></label>
                                                <AntDatePicker
                                                    value={warrantyStartDate}
                                                    name={'warrantyStartDate'}
                                                    onChange={(e) => { this.handleDateChange(e, 'warrantyStartDate') }}
                                                    format='DD/MM/YYYY'
                                                    placeholder='DD/MM/YYYY'
                                                    className={`form-control ${this.state.errors["warrantyStartDate"]}`}
                                                />
                                            </div>

                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'warrantyExpiry'} /></label>
                                                <AntDatePicker
                                                    value={warrantyExp}
                                                    name={'warrantyExp'}
                                                    onChange={(e) => { this.handleDateChange(e, 'warrantyExp') }}
                                                    format='DD/MM/YYYY'
                                                    placeholder='DD/MM/YYYY'
                                                    className={`form-control ${this.state.errors["warrantyExp"]}`}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'stockIntakeDate'} /></label>
                                                {/* <ReactDatePicker
                                                    value={stockInDate}
                                                    name={'stockInDate'}
                                                    onChange={this.handleDateChange}
                                                    format={this.dateFormat}
                                                    placeholder={'DD/MM/YYYY'}
                                                    className={`form-control`}
                                                    //maxDate={moment()._d}
                                                    readOnly={true}
                                                /> */}
                                                <AntDatePicker
                                                    value={stockInDate}
                                                    name={'stockInDate'}
                                                    onChange={(e) => { this.handleDateChange(e, 'stockInDate') }}
                                                    format='DD/MM/YYYY'
                                                    placeholder='DD/MM/YYYY'
                                                    className={`form-control ${this.state.errors["stockInDate"]}`}
                                                />
                                            </div>



                                        </div>

                                        <div className="form-row">

                                            <div className="form-group col-md-6">
                                                <label> <Translate text={'price'} /></label>
                                                <NumberFormat
                                                    id={'price'}
                                                    decimalScale={2}
                                                    allowNegative={true}
                                                    thousandSeparator={true}
                                                    prefix={currencySymbol}
                                                    placeholder={currencySymbol}
                                                    className={`form-control ${this.state.errors["price"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'price')}
                                                    value={price ? price : null}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label><Translate text={'purchasePrice'} /></label>
                                                <NumberFormat
                                                    id={'purchasePrice'}
                                                    decimalScale={2}
                                                    allowNegative={true}
                                                    thousandSeparator={true}
                                                    prefix={currencySymbol}
                                                    placeholder={currencySymbol}
                                                    className={`form-control ${this.state.errors["purchasePrice"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'purchasePrice')}
                                                    value={purchasePrice ? purchasePrice : null}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'location'} /></label>
                                                <ReactSelect
                                                    options={locations}
                                                    name={"location"}
                                                    placeholder={'select location'}
                                                    onChange={this.handleSelectChange}
                                                    value={location}
                                                    classNamePrefix={`${this.state.errors["location"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'tradePlate'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'trade plate'}
                                                    className={`form-control ${this.state.errors["tradePlate"]}`}
                                                    name="tradePlate"
                                                    onChange={this.handleOnCapChange}
                                                    value={tradePlate}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label><Translate text={'odometerReading'} /> </label>
                                                <NumberFormat
                                                    id={'odometerReading'}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    placeholder={'odometer reading'}
                                                    className={`form-control ${this.state.errors["miles"]}`}
                                                    onValueChange={this.onCurrencyChange.bind(this, 'miles')}
                                                    value={miles ? miles : null} />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label><Translate text={'status'} /></label>
                                                <ReactSelect
                                                    options={stock_status}
                                                    name={"status"}
                                                    placeholder={'select status'}
                                                    onChange={this.handleSelectChange}
                                                    value={status}
                                                    classNamePrefix={`${this.state.errors["status"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>

                                            {this.state.stock.isAllocated ? (
                                                <div className="form-group col-md-6">
                                                    <label><Translate text={'select dealer'} /></label>
                                                    <ReactSelect
                                                        options={this.state.currentDealers}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={this.handleSelectChange}
                                                        value={this.state.stock.clientID ? this.state.stock.clientID : ''}
                                                        classNamePrefix={`${this.state.errors["clientID"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            ) : <></>}

                                            <div className="form-group col-md-6">
                                                <label><Translate text={'saleType'} /></label>
                                                <ReactSelect
                                                    options={sale_type}
                                                    name={"saleType"}
                                                    placeholder={'select sale type'}
                                                    onChange={this.handleSelectChange}
                                                    value={saleType}
                                                    classNamePrefix={`${this.state.errors["saleType"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="requirement-pop-inner">
                                        <div className="requirement-section-head"><Translate text={'vehicleImages'} /></div>
                                        <div className="requirement-image-wraper">

                                            <div className="requirement-item-image upload">
                                                <label htmlFor="images">
                                                    <img src={_images.addvehicle} alt="" htmlFor="images" />
                                                    <input className="fileInput"
                                                        type="file"
                                                        name="images"
                                                        id="images"
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => this.onSelectFile(e, 'stock', 'images', 'Stock Image')} />
                                                </label>
                                            </div>
                                            {images && images.map((data, index) => (
                                                <div className="requirement-item-image" key={index}>
                                                    <div className="image-delete img-delete-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleImageDel(index);
                                                    }}>
                                                        <i className="ico icon-delete" ></i>
                                                    </div>
                                                    <a data-fancybox={`vehicle_img`} href={data} onClick={(e) => { e.preventDefault(); }}>
                                                        <img src={data} width="70" height="70" alt="" />
                                                    </a>
                                                </div>
                                            ))}
                                            {
                                                (this.state.imageURL) ?
                                                    (
                                                        <div className="requirement-item-image">
                                                            <div className="img-loader">
                                                                <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={this.state.imageURL} width="70" height="70" alt="" />
                                                            </a>
                                                        </div>
                                                    )
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {this.state.hideFooter ? (
                        <></>
                    ) : (
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-default float-left"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleClose();
                                }}
                            ><Translate text={'cancel'} />
                            </button>

                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={(e) => this.handleSaveInfo(e)}
                            >
                                {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                <Translate text={'save'} />
                            </button>
                        </Modal.Footer>
                    )}
                </Modal>

                <ImageCropHook
                    cropShow={this.state.cropShow}
                    handleClose={this.handleCropClose}
                    handleSelect={this.handleCropSelect}
                ></ImageCropHook>

                <PopUpModal show={fleetModal.show}>
                    <AddFleet
                        docID={fleetModal.ID}
                        show={fleetModal.show}
                        clsActive={fleetModal.clsActive}
                        stock={fleetModal.stock}
                        handleClose={this.handleLoanVehicleClose}
                        title={fleetModal.title}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    >
                    </AddFleet>
                </PopUpModal>

                <PopUpModal show={testDriveModal.show}>
                    <AddTestDrive
                        docID={testDriveModal.ID}
                        show={testDriveModal.show}
                        clsActive={testDriveModal.clsActive}
                        handleClose={this.handleTestDriveClose}
                        title={testDriveModal.title}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isDeivered={false}
                        contact={
                            (!_.isEmpty(testDriveModal.stock) && !_.isEmpty(testDriveModal.stock.testDriveDetail))
                                ?
                                testDriveModal.stock.testDriveDetail.contact
                                :
                                testDriveModal.contact
                        }
                    >
                    </AddTestDrive>
                </PopUpModal>


            </>


        );
    }
}