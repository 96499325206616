import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactSelect } from '../../../components';
import Translate from '../../../constants/translate';

const SelectDealer = ({ title, show, handleClose, dealersettings}) => {
  const [state, setState] = useState({
    currentDealers: dealersettings.clients.map(client => {
      return {value: client.id, label: client.name}
    }),
    clientID: '',
    error: false
  })

  const handleSelectChange = (e) => {
    if (e) {
      setState({...state, clientID: e.value,  error: false})
    } else {
      setState({...state, clientID: '', error: true})
    }
  }

	return (
		<Modal
			id='add-cafe'
			show={show}
			onHide={handleClose}
			backdrop='static'
			backdropClassName={show ? 'overlay-modal active' : ''}
			enforceFocus={false}
			dialogClassName='modal-dialog modal-dialog-centered modal-file-manager'
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h5 className='modal-title'>
						<Translate text={title} />
					</h5>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
      <div className="container-fluid">
                            <div className="row">

                                <div className="form-style w-100">
                                    <div className="form-row ">
                                        <div className="form-group col-md-12">
                                        <label><Translate text={'select dealer'} /></label>
                                                <ReactSelect
                                                    options={state.currentDealers}
                                                    name={"clientID"}
                                                    placeholder={'Select dealer'}
                                                    onChange={handleSelectChange}
                                                    value={state.clientID ? state.clientID : ''}
                                                    classNamePrefix={`${state.error ? 'input_error' : ''} basic-select`}
                                                >
                                                </ReactSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
			</Modal.Body>
			<Modal.Footer className='modal-footer'>
          <button
            type='button'
            className='btn btn-primary float-right ml-2 '
            aria-label='Close'
            // disabled={}
				  	onClick={() => {
              if (!state.error && state.clientID) {
                handleClose(state.clientID)
              } else {
                setState({...state, error: true})
              }
            }
          }
          >
            {' '}
            <Translate text={'Allocate'} />
          </button>
				<button
					type='button'
          className='btn btn-primary float-right'
          onClick={() => handleClose(null)}
				>
					{/* {
								loader ?
									(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
									: (<></>)
							} */}
					<Translate text={'close'} />
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SelectDealer;
