import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _ from 'lodash';
import { NoDataDiv, LoadingDiv } from '../../layout/searchPanel/constants';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';

import Swal from 'sweetalert2'
import Translate from '../../../constants/translate';

const SearchPanel = ({
    showSearchPanel,
    searchText,
    dealersettings,
    handleClose,
    history,
    className,
    clientUsers,
    groupUsers,
    clientID
}) => {

    const [contactDetails, setContactDetails] = useState([]);
    const [loader, setLoader] = useState([]);
    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
    useEffect(() => {
        if (searchText && searchText.length > 0) {
            setLoader(true);

            let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)
            let refContactDetails = firestoreDB(dealersettings)
                .firestore()
                .collection('contactDetails')
                .where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
                .where('isDeleted', '==', false)
                .orderBy('firstName', 'asc')
                .limit(10);

            if (clientID) {
                refContactDetails = CommonHelper.whereClientOrGroupTradeinPROContactClause(refContactDetails, dealersettings, clientID);
            }
            else {
                if (dealersettings.group.enableSharedContact && dealersettings.client.group) {
                    refContactDetails = refContactDetails
                        .where('groupID', '==', dealersettings.client.group)
                }
                else {
                    refContactDetails = refContactDetails
                        .where('clientID', '==', dealersettings.client.id)
                }
            }



            var contactSearchPanel = combineLatest(
                collection(refContactDetails)
            )
                .pipe(
                    map(([contactDetails]) => {
                        return [
                            _.map(contactDetails, function (obj) {
                                return {
                                    ...obj.data(),
                                    documentID: obj.id,
                                    renderType: 'contactDetail',
                                    sortDate: obj.data().addedDate,
                                };
                            }),
                        ];
                    }),
                )
                .subscribe(allDocs => {
                    setContactDetails(allDocs[0]);
                    setLoader(false);
                });
        }
        return () => {
            contactSearchPanel && contactSearchPanel.unsubscribe();
        };
    }, [searchText, dealersettings.client.documentID]);

    return (
        <div
            className='dropdown-menu dropdown-menu-left'
            aria-labelledby='dropdownMenuButton'
            style={{ display: showSearchPanel ? 'block' : '' }}
        >
            <div className={`form-style ${className ? className : 'globalsearch-wrap'}`}>
                {/* <div className='globalsearch-nav-wraper'>
					<div className='globalsearch-nav pl-0'>
						<Tab.Container defaultActiveKey={activeTab}>
							<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
								{salesTabs.map((rec, index) => {
									return (
										<Nav.Item
											as='li'
											bsPrefix='li-nav'
											key={index}
											title={rec.title}
										>
											<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
												{
													<>
														<i className={rec.ico}></i>
														{rec.name}
													</>
												}
											</Nav.Link>
										</Nav.Item>
									);
								})}
							</Nav>
						</Tab.Container>
					</div>
				</div> */}
                <div className='globalsearch-body'>
                    {loader ? (
                        <LoadingDiv />
                    ) : (
                        <div className='global-item-wraper'>
                            {contactDetails.length > 0 ? (
                                <>
                                    {contactDetails.map(contact => (
                                        <div
                                            className='global-item d-flex customer-content'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // history.push(`/`);
                                                // history.push(`contacts/details/${obj.documentID}`);
                                                if (contact?.gdprDeleted) {
                                                    Swal.fire(CommonHelper.showLocale({ dealersettings }, "Can't select GDPR contact"));
                                                    return;
                                                }
                                                handleClose(contact.documentID, contact);
                                            }}
                                            key={contact.documentID}
                                        >
                                            <div className=''>
                                                {(contact.firstName || contact.lastName) && (
                                                    <span className='global-header'>
                                                        {CommonHelper.displayContactIcon(contact, 'mr-2')}
                                                        {CommonHelper.displayContact([], contact, '--')}
                                                    </span>
                                                )}
                                                {
                                                    (contact.businessContact && !_.isEmpty(contact.company))
                                                        ?
                                                        <div className="global-info">
                                                            <i className={`ico icon-Contacts mr-1`}></i>
                                                            {CommonHelper.getFullName([], contact, '')}
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    contact.displayID ?
                                                        <span className='global-info'><i className="ico icon-enquiry mr-1"></i>{contact.displayID}</span>
                                                        : <></>
                                                }
                                                {contact.phone ? (
                                                    <span className='global-info'><i className="ico icon-call mr-1"></i>{CommonHelper.getFullPhone(contact.phoneCode, contact.phone)}</span>
                                                ) : <></>}
                                                {contact.email ? (
                                                    <span className='global-info'><i className="ico icon-email mr-1"></i>{contact.email}</span>
                                                ) : (
                                                    <></>
                                                )}
                                                {
                                                    contact.clientID && (contact.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ? (
                                                        <div className="blue-color">{CommonHelper.getOtherDealerName(dealersettings, contact.clientID)}</div>
                                                    ) : (<></>)
                                                }


                                            </div>
                                            {/* {
                                                contact.owner && !_.isEmpty(allUsers) ? (<OverlayTrigger
                                                    placement='bottom'
                                                    overlay={
                                                        <Tooltip>{CommonHelper.getUserNamebyId(allUsers, contact.owner)}</Tooltip>
                                                    }
                                                >
                                                    <div className="float-right owner-avatar-right">
                                                        <div className="pipeline-users p-2">
                                                            <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                <img src={CommonHelper.getUserImagebyId(allUsers, contact.owner)} width="200" height="200" alt="" className="rounded-circle img-object-fit" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>) : (<></>)
                                            } */}

                                            {
                                                ((contact.owner && !_.isEmpty(allUsers)) || contact.gdprDeleted)
                                                    ?
                                                    <div className="float-right owner-avatar-search">
                                                        {
                                                            contact.gdprDeleted ?
                                                                <div className="badge badge-pill badge-warm float-left mr-2" style={{ height: '21px', paddingTop: '2px' }}>
                                                                    <i className="ico icon-delete mr-1"> </i><Translate text={'gdprDeleted'} upperCase={true} />
                                                                </div> : <></>
                                                        }
                                                        {
                                                            contact.owner && !_.isEmpty(allUsers) ? (<OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                    <Tooltip>{CommonHelper.getUserNamebyId(allUsers, contact.owner)}</Tooltip>
                                                                }
                                                            >
                                                                <div className="pipeline-users pl-2">
                                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                                        <img src={CommonHelper.getUserImagebyId(allUsers, contact.owner)} width="200" height="200" alt="" className="rounded-circle img-object-fit" />
                                                                    </a>
                                                                </div>
                                                            </OverlayTrigger>) : (<></>)
                                                        }
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <NoDataDiv />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default SearchPanel;
