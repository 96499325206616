import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment'
import toast from 'toasted-notes'
import Swal from 'sweetalert2';

import { TableView, ContentOverlay, ReactSelect } from '../../components';
import Translate from '../../constants/translate';
import { allfeedbackFields, responseOptions, csvHeader } from './viewModel';
import ReactExport from 'react-export-excel-xlsx-fix';
import CommonHelper from '../../services/common';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const FormFeedbacksList = ({
	activeTab,
	feedbacks,
	loader,
	fetchMoreData,
	hasMore,
	triggers,
	setSelectedTrigger,
	selectedTrigger,
	yesNoFilter,
	setYesNoFilter,
	dealersettings,
	setCSVData,
	csvData
}) => {
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});
	const csvBtn = useRef();

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 330,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubFeedbacks && window.unSubFeedbacks();
		};
	}, []);

	useEffect(() => {
		if (_.isEmpty(csvData)) {
			return
		}
		setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
		toast.closeAll();
		Swal.fire({
			icon: 'success',
			title: CommonHelper.showLocale({dealersettings}, 'Data downloaded successfully.'),
			showConfirmButton: false,
			timer: 1500
		});

		let _objLogData = {
			notes: 'Excel export from loan vehicle',
			type: 'excelExport',
			subType: 'fleet',
		}
		_objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, dealersettings);
		CommonHelper.saveAuditLog(_objLogData);
	}, [csvData]);

	const filterFeedbacks = feedback => {
		let returnVal = true;

		if (activeTab === 'email' && feedback._type !== 'Email') {
			returnVal = false;
		} else if (activeTab === 'sms' && feedback._type !== 'SMS') {
			returnVal = false;
		}
		return returnVal;
	};

	const filterYesNo = feedback => {
		let returnVal = true;

		if (feedback.triggerType === 'email' && yesNoFilter) {
			returnVal = false;
		}
		else if (yesNoFilter && yesNoFilter !== 'other' && !feedback?.response?.toLowerCase()?.includes(yesNoFilter)) {
			returnVal = false;
		} else if (yesNoFilter && yesNoFilter === 'other' && (feedback?.response?.toLowerCase()?.includes('yes') || feedback?.response?.toLowerCase()?.includes('no'))) {
			returnVal = false;
		} else if (yesNoFilter && yesNoFilter === 'other' && feedback?.response?.toLowerCase()?.length < 3) {
			returnVal = false;
		}
		return returnVal;
	};

	const filterSearch = feedback => {
		let returnVal = true;

		if (searchText.length > 0) {
			if (
				feedback._participant
					.toLowerCase()
					.includes(searchText.toLowerCase())
				//|| feedback._content.toLowerCase().includes(searchText.toLowerCase()
			) {
				returnVal = true;
			} else {
				returnVal = false;
			}
		}
		return returnVal;
	};

	const handlePagination = () => {
		if (hasMore.length) {
			fetchMoreData()
		}
	}

	const excelDownload = () => {
		setCSVData(CommonHelper.generateExcelData(_.orderBy(
			feedbacks
				.filter(feedback => filterFeedbacks(feedback))
				.filter(feedback => filterSearch(feedback))
				.filter(feedback => filterYesNo(feedback)),
			['modifiedDate'],
			['desc'],
		), csvHeader))
	}

	return (
		<div className='bulksms-right-panel'>
			<div className='bulksms-list-container'>
				<h2>
					{activeTab === 'templates'
						? 'SMS Templates'
						: activeTab === 'sms'
							? 'SMS Feedbacks'
							: activeTab === 'email'
								? 'Email Feedbacks'
								: 'All Feedbacks'}
				</h2>
				<div className='divider-line'></div>
				<div className='bulksms-list-table'>
					<div className='bulksms-list-filter'>
						<div className='float-left'>
							<div className='filter-search search-icon'>
								<input
									placeholder='search'
									aria-label='Search'
									value={searchText}
									onChange={e => setSearchText(e.target.value)}
								/>
							</div>
						</div>
						<div className='float-left' style={{ width: '180px', marginLeft: '8px' }}>
							<ReactSelect
								options={triggers.map((trigger, index) => {
									return {
										value: trigger.documentID,
										label: trigger.title,
										index: index
									}
								})}
								name={"trigger"}
								placeholder={'select trigger'}
								onChange={(e, data) => {
									if (e) {
										setSelectedTrigger(e.value);
									}
									else
										setSelectedTrigger(null);
									setYesNoFilter(null);
								}}
								value={selectedTrigger ? selectedTrigger : ''}
								classNamePrefix={`cursor-pointer basic-select`}
								removeClearable={false}
							/>
						</div>
						<div className='float-left' style={{ width: '180px', marginLeft: '8px' }}>
							<ReactSelect
								options={responseOptions}
								name={"response"}
								placeholder={'select response'}
								onChange={(e, data) => {
									if (e) {
										setYesNoFilter(e.value);
									}
									else
										setYesNoFilter(null);
								}}
								value={yesNoFilter ? yesNoFilter : ''}
								classNamePrefix={`cursor-pointer basic-select`}
								removeClearable={false}
							/>
						</div><div className="filter-item-btn ml-2 float-right">
							<a href="#" onClick={(e) => {
								//e.preventDefault();
								excelDownload(e);
							}} title={'Excel Export'}>
								<i className="ico icon-excel-download"></i>
							</a>
							<ExcelFile ref={csvBtn} element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>} filename={`${`feedbacks_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}`}>
								<ExcelSheet dataSet={csvData} name={`Sheet1`} />
							</ExcelFile>
							{/* <CSVLink
									ref={csvBtn}
									uFEFF={false}
									data={csvData}
									headers={csvHeader}
									filename={`${activeTab}_${moment().format('DD_MM_YYYY_HH_mm_ss')}.csv`}
									style={{ display: 'none' }}>
							</CSVLink> */}
						</div>
					</div>
					{loader ? (
						<div className='mt-5'>
							<ContentOverlay active={true} />
						</div>
					)
						: !selectedTrigger ? (
							<div className='common-table'>
								<div className='text-center p-5'>
									<p>
										<Translate text={'Please select a trigger'} />
									</p>
								</div>
							</div>
						)
							: feedbacks.length > 0 ? (
								<div className='common-table'>
									<TableView
										isReorderable={false}
										datalist={_.orderBy(
											feedbacks
												.filter(feedback => filterFeedbacks(feedback))
												.filter(feedback => filterSearch(feedback))
												.filter(feedback => filterYesNo(feedback)),
											['modifiedDate'],
											['desc'],
										)}
										height={windowSize.windowHeight}
										width={windowSize.windowWidth}
										columns={allfeedbackFields}
										dynamicFields={allfeedbackFields
											.filter(e => e.default === true)
											.map(v => _.pick(v, ['value']).value)}
										settingsLoader={false}
										handleActionClick={() => { }}
										handleRowSingleClick={() => { }}
										isSettings={false}
										hasMore={hasMore}
										isPaging={true}
										handlePagination={handlePagination}
									/>
								</div>
							) : (
								<div className='common-table'>
									<div className='text-center p-5'>
										<p>
											<Translate text={'No feedbacks found'} />
										</p>
									</div>
								</div>)}
				</div>
			</div>
		</div>
	);
};

export default FormFeedbacksList;
