import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { msalConfig } from "../../../../services/msal/authConfig";
import toast from 'toasted-notes'
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import { ContentOverlay } from '../../../../components';

const CalendarSettingsTab = ({
	dealersettings,
	loader,
	updateDealerSettings
}) => {
	const [contactUs, setContactUsShow] = useState(false)
	const [outlookCalendar, setOutlookCalendar] = useState(null);
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [syncing, setSyncing] = useState(false);
	const [disableLoader, setDisableLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false)
	const [pageloader, setPageloader] = useState(false);

	const OutlookEnabled = true

	const apiURL =
		process.env.NODE_ENV === 'development'
			? 'http://localhost:5001/ams-pro-qa/us-central1/'
			: dealersettings.apiURL;

	useEffect(() => {
		window.firebase
			.firestore()
			.doc(`users/${localStorage.uid}/outlookCalendar/${localStorage.uid}`)
			.get()
			.then(snap => {
				if (snap.exists) {
					setOutlookCalendar(snap.data());
					setIsAuthenticated(true);
				}
				setPageloader(false)
			});
	}, [])

	useEffect(() => {
		const auth = async () => {
			try {
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);
				const code = urlParams.get('code')
				const error = urlParams.get('error')
				const state = urlParams.get('state')
				const error_description = urlParams.get('error_description')

				if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.googleCalendar)) {
					setSyncing(false)
					setIsDisabled(true)
					return
				}

				if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.outlookCalendar) && !dealersettings.outlookCalendar.refreshTokenExpired) {
					setSyncing(false)
					setIsAuthenticated(true);
					setOutlookCalendar(dealersettings.outlookCalendar)
					return
				}
	
				console.log('one')
				if (!code || state !== 'outlookcalendar' || error || error_description) {
					if (error_description) {
						toast.notify(error_description, {
							duration: 2000,
						});
					}
					return
				}
				console.log('tow')
	
				setSyncing(true)
				let reqBody = {
					"uid": localStorage.uid,
					"code": code,
					"expiryDate": moment().add(70, 'h').toISOString(),
					"redirectURL": `${window.location.origin}/settings/profile`
				}
				console.log('three')

				const resp = await axios.post(
					`${apiURL}outlook-api/api/outlook/saveAccountInfo`,
					reqBody,
				);
				console.log('reqResponse', resp);
				if (resp.data.success) {
					console.log('fiyr')

					setSyncing(false)
					setIsAuthenticated(true);
					setOutlookCalendar(resp.data.outlookCalendar)
					updateDealerSettings('outlookCalendar', resp.data.outlookCalendar)

					toast.notify('Outlook enabled successfully.', {
						duration: 2000,
					});
				}
				else {
					Swal.fire('Something went wrong.', '', 'error');
					console.error(resp.data.message)
				}
			} catch (error) {
				console.error(error)
			}
		}
		auth()
	}, [dealersettings])

	const handleSignIn = () => {
		if (isDisabled) {
			Swal.fire(CommonHelper.showLocale({ dealersettings }, 'You are already logged in with Google Calendar.'), '', 'error');
			return
		}
		const authURL = `${msalConfig.authority}oauth2/v2.0/authorize?
			client_id=${msalConfig.clientId}
			&response_type=code
			&redirect_uri=${encodeURIComponent(`${window.location.origin}/settings/profile`)}
			&response_mode=query
			&scope=${msalConfig.scopes}
			&state=outlookcalendar`

		window.location.replace(authURL);
	}

	const handleSignOut = async () => {
		Swal.fire({
			title: CommonHelper.showLocale({ dealersettings }, 'Are you sure?'),
			text: CommonHelper.showLocale({ dealersettings }, 'You want to disable?.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Yes'),
			cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'No'),
		}).then(async result => {
			if (result.value) {
				try {
					setDisableLoader(true)
					await axios.post(
						`${apiURL}outlook-api/api/outlook/removeAccountInfo`,
						{
							"refreshToken": outlookCalendar.refreshToken,
							"subscriptionID": outlookCalendar.subscriptionID,
						},
					);
					await window.firebase.firestore().doc(`users/${localStorage.uid}/outlookCalendar/${localStorage.uid}`).delete();
					updateDealerSettings('outlookCalendar', null)
					setDisableLoader(false)
					setIsAuthenticated(false)
					setOutlookCalendar(null)
					toast.notify('Outlook disabled successfully.', {
						duration: 2000,
					});
				} catch (error) {
					console.log(error)
					setDisableLoader(false)
				}
			}
		})
	}

	return pageloader ? (
		<div className='mt-5'>
			<ContentOverlay active={true} />
		</div>
	) : (
		<>
			<div className='email-settings-inner form-style'>
				{!isAuthenticated ? (
					<div className='email-sync-wrap'>
						{
							syncing ? (<div className="alert alert-primary" role="alert">
								<span className="spinner-border spinner-button mr-2 mt-1" role="status" aria-hidden="true"></span>
								<Translate text={'We are enabling your outlook account with AMS Pro'} />

							</div>) : (<></>)
						}

						{!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.outlookCalendar) && dealersettings.outlookCalendar.refreshTokenExpired ? (
							<>
								<h1><Translate text={'Your outlook calendar session has been expired'} /></h1>
								<p><Translate text={'Please authenticate your outlook calendar session to continue'} />

								</p>
								<div className='email-active-sync'>
									<div className='email-active-sync-item mfa-yellow mt-2'>
										<div className='email-sync-status'>
											{syncing ? (
												<button
													type='button'
													className='btn btn-common float-right'
												>
													<span
														className='spinner-border spinner-button mr-1 mt-0'
														role='status'
														aria-hidden='true'
													></span>
													<Translate text={'Enabling'} />
												</button>
											) : (
												<button
													type='button'
													className='btn btn-common float-right'
													onClick={() => handleSignIn()}
												>
													{loader && (
														<span
															className='spinner-border spinner-button mr-1 mt-0'
															role='status'
															aria-hidden='true'
														></span>
													)}
													<Translate text={'Reauthenticate'} />
												</button>
											)}
										</div>
										<h4 className='d-flex align-items-center'>
											<span className='email-active-logo' style={{ width: '30px' }}>
												<i
													className="ico icon-mail-spam"
													style={{
														height: '29px',
														width: '40px',
														fontSize: '26px',
													}}
												></i>
											</span>
											{dealersettings.email ? dealersettings.email : 'User has no email'}
										</h4>
									</div>
								</div>
							</>
						) : (
							<>
								<h1><Translate text={"Your Outlook Account is not logged in"} /></h1>
								<p><Translate text={"Link or disable your outlook account below"} />
								</p>
								<div className='email-active-sync'>
									<div className='email-active-sync-item active-tab'>
										<div className='email-sync-status'>
											{syncing ? (
												<button
													type='button'
													className='btn btn-common float-right'
												>
													<span
														className='spinner-border spinner-button mr-1 mt-0'
														role='status'
														aria-hidden='true'
													></span>
													<Translate text={'Enabling'} />
												</button>
											) : (
												<button
													type='button'
													className='btn btn-common float-right'
													onClick={() => handleSignIn()}
												>
													{loader && (
														<span
															className='spinner-border spinner-button mr-1 mt-0'
															role='status'
															aria-hidden='true'
														></span>
													)}
													<Translate text={'enable'} />
												</button>
											)}
										</div>
										<h4 className='d-flex align-items-center'>
											<span className='email-active-logo' style={{ width: '30px' }}>
												<i
													className="ico icon-Calendar"
													style={{
														height: '40px',
														width: '40px',
														fontSize: '26px',
													}}
												></i>
											</span>
											{dealersettings.email ? dealersettings.email : 'User has no email'}
										</h4>
									</div>
								</div>
							</>
						)}
					</div>
				) : (
					<div className='email-sync-wrap'>
						<h1><Translate text={'Your Outlook Account is logged in'} /></h1>
						<p><Translate text={"Link or disable your outlook account below"} />

						</p>

						<div className='email-active-sync'>
							<div className='email-active-sync-item active-tab'>
								{(isAuthenticated) ? (
									<div className='email-sync-status'>
										<button
											type='button'
											className='btn btn-common float-right'
											onClick={handleSignOut}
										>
											{disableLoader && (
												<span
													className='spinner-border spinner-button mr-1 mt-0'
													role='status'
													aria-hidden='true'
												></span>
											)}
											<Translate text={'disable'} />
										</button>
									</div>
								) : (
									<div className='email-sync-status'>
										<button
											type='button'
											className='btn btn-common float-right btn-primary-fullscreen'
											style={{ borderRadius: '4px' }}
											onClick={() => setContactUsShow(true)}
										>
											<Translate text={'contactUs'} />
										</button>
									</div>
								)}


								<h4 className='d-flex align-items-center'>
									<span className='email-active-logo' style={{ width: '30px' }}>
										<i
											className="ico icon-Calendar"
											style={{
												height: '40px',
												width: '40px',
												fontSize: '26px',
											}}
										></i>
									</span>
									{!_.isEmpty(outlookCalendar) && !_.isEmpty(outlookCalendar.email) ? outlookCalendar.email : dealersettings.email ? dealersettings.email : ''}
								</h4>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default CalendarSettingsTab;
