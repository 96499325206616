/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox, AntDateRangePicker,
    ReactSelect, InputText, ReactMultiSelect, InputMultipleText
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';

import SaveFilter from '../filters/saveFilter'
import { firestoreDB } from '../../services/helper';


//#region  ASSIGN VARIABLES
let titles = [];
let languages = [];
let license_state = [];
let defaultCountry = '';
let campaigns = [];
let origins = [];
let nationalities = [];

let enquiryTypes = [];
let leadSources = [];
let genders = [];
let marital_status = [];
let license_type = [];
let interested = [];
let contact_method = [];
let groupClients = [];
let sale_type = [];
let labels = [];
let vehicleYears = [];

// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
/*const enquiryStatus = [
    { active: true, value: 'open', label: <div className={`badge badge-pill badge-status-open`}>OPEN</div> },
    { active: true, value: 'won', label: <div className={`badge badge-pill badge-status-won`}>WON</div> },
    { active: true, value: 'delivered', label: <div className={`badge badge-pill badge-status-delivered`}>DELIVERED</div> },
    { active: true, value: 'lost', label: <div className={`badge badge-pill badge-status-lost`}>LOST</div> }
]*/
const contact_type = [
    { active: true, value: 'Current Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Current Customer'} /></div> },
    { active: true, value: 'Potential Customer', label: <div className={`badge badge-pill badge-input-white`}><Translate text={'Potential Customer'} /></div> }
]

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> },
    { active: true, value: 'wonDate', label: <Translate text={'Lead Won Date'} /> },    
    { active: true, value: 'deliveredDate', label: <Translate text={'Lead Delivered Date'} /> },
    { active: true, value: 'lostDate', label: <Translate text={'Lead Lost Date'} /> },
]

const objDateField = {
    name: null,
    type: null,
    range: null
}

const vehicle_search_type = [
    { active: true, value: 'requirement', label: <Translate text={'Requirement Model'} /> },
    { active: true, value: 'testdrive', label: <Translate text={'Test Drive Model'} /> }
]

const contact_dob_type = [
    { active: true, value: 'contactswithDOB', label: <Translate text={'contactswithDOB'} /> },
    { active: true, value: 'contactswithoutDOB', label: <Translate text={'contactswithoutDOB'} /> }
]

const contact_phone = [
    { active: true, value: 'contactswithPhone', label: <Translate text={'contactswithPhone'} /> },
    { active: true, value: 'contactswithoutPhone', label: <Translate text={'contactswithoutPhone'} /> }
]

const contact_email = [
    { active: true, value: 'contactswithEmail', label: <Translate text={'contactswithEmail'} /> },
    { active: true, value: 'contactswithoutEmail', label: <Translate text={'contactswithoutEmail'} /> }
]

const yesNoOptions = [
    { active: true, value: 'Yes', label: 'Yes' },
    { active: true, value: 'No', label: 'No' }
]

const opts = [
    { active: true, value: 'true', label: <Translate text={'Opt In'} /> },
    { active: true, value: 'false', label: <Translate text={'Opt Out'} /> }
]

//#endregion
const ContactAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [types, setTypes] = useState([])
    const [groups, setGroups] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [companys, setCompanys] = useState([])
    const [lostReasons, setLostReasons] = useState([])
    const [lostSubReasons, setLostSubReasons] = useState([])
    const [enquiryAllStatus, setAllStatus] = useState([]);

    const [inboundSources, setInboundSources] = useState([])
    const [inboundSubTypes, setInboundSubTypes] = useState([])

    const { columnFields } = props;

    const [pipelines, setPipelines] = useState([])
    const [stages, setStages] = useState([])

    const [fusionEvents, setFusionEvents] = useState([])

    const enableSharedContact = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.group) &&
        props.dealersettings.group.enableSharedContact) ? true : false);


    useEffect(() => {
        if (!props.dealersettings?.client?.moduleSettings?.fusionevents?.enabled)
            return;

        const fetchData = async () => {
            const { dealersettings } = props;
            let _searchObject = {
                type: "getFusionEvents",
                params: JSON.stringify({ clientID: dealersettings?.client?.id })
            }
            const getFusionEvents = window.firebase.functions().httpsCallable('generic-getData');
            let _resp = await getFusionEvents(_searchObject);
            let _data = _resp?.data?.data;
            let _fusionevents = [];
            _data && _data.forEach(doc => {
                _fusionevents.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            setFusionEvents(_fusionevents);
            //console.log('getFusionEvents', _fusionevents);

        }

        if (props.dealersettings?.client?.moduleSettings?.fusionevents?.enabled)
            fetchData();
    }, [])

    useEffect(() => {
        const userRefDoc = firestoreDB(props.dealersettings).firestore().collection('companies')
            .where('clientID', '==', props.dealersettings.client.id)
            .where('isDeleted', '==', false)
            .limit(100)
            .onSnapshot((querySnapshot) => {
                const _companys = [];
                querySnapshot.forEach(doc => {
                    const { name } = doc.data();
                    _companys.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: name
                    });
                });
                setCompanys(_companys)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    useEffect(() => {
        const { contactFilter } = props;

        if (!_.isEmpty(contactFilter))
            setFilterFields(contactFilter.value);
        else
            setFilterFields({});

    }, [props.contactFilter])

    useEffect(() => {
        const { dealersettings, clientUsers, contactFilter } = props;

        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            const setttings = dealersettings.client.settings;
            //const brands = dealersettings.allMakeModels;
            const brands = dealersettings.client.brands;
            const _makes = [];
            const _lostReasons = [];
            const _inboundSources = [];
            const countries = []; const states = [];
            const _stages = [];

            const _pipelines = [];
            setttings.pipelines.forEach((doc) => {
                _pipelines.push({
                    value: doc.value,
                    label: doc.name
                });
            });


            if (localStorage.defaultModule !== 'tradeinPro' && dealersettings.group && dealersettings.group.enableSharedContact === true && dealersettings.group.clients)
                groupClients = Object.keys(dealersettings.group.clients).map(cid => { return { value: cid, label: props.dealersettings.group.clients[cid] ? props.dealersettings.group.clients[cid].name : '' } })


            // if (localStorage.defaultModule === 'tradeinPro' && _.isEmpty(groupClients)) {
            //     let _dealers = [];
            //     dealersettings.clients && dealersettings.clients.filter(c =>
            //         c.moduleSettings
            //         && c.moduleSettings.tradeinPro
            //         && c.moduleSettings.tradeinPro.enabled === true
            //         && c.moduleSettings.tradeinPro.active === true
            //     ).forEach(rec => {
            //         _dealers.push({
            //             label: rec.name,
            //             value: rec.id,
            //             data: rec,
            //             active: true,
            //         })
            //     })
            //     groupClients = _dealers;
            // }


            defaultCountry = dealersettings.client.countryCode ? dealersettings.client.countryCode.toUpperCase() : 'AU';
            let _fields = !_.isEmpty(contactFilter) ? contactFilter.value : {};


            vehicleYears = [];
            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                vehicleYears.push({
                    value: i.toString(),
                    label: i.toString(),
                    active: true,
                });
            }

            titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            genders = [];
            setttings.genders && setttings.genders.forEach(doc => {
                genders.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            marital_status = [];
            setttings.maritalStatus && setttings.maritalStatus.forEach(doc => {
                marital_status.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            contact_method = [];
            setttings.contactMethod && setttings.contactMethod.forEach(doc => {
                contact_method.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            labels = [];
            setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
                labels.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill`} style={{
                        color: `${doc.color ? doc.color : '#f47815 '}`,
                        backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                    }}>{doc.name.toUpperCase()}</div>,
                });
            });

            interested = [];
            setttings.interests && setttings.interests.forEach(doc => {
                interested.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            license_type = [];
            setttings.licenseType && setttings.licenseType.forEach(doc => {
                license_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            leadSources = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                leadSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            sale_type = [];
            setttings.salesType && setttings.salesType.forEach(doc => {
                sale_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('country') && _fields.country && !_.isEmpty(dealersettings.states)) {
                dealersettings.states.filter(e => e.country_code === _fields.country).forEach((data) => {
                    states.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: data.name
                    });
                });
                setStates(states);
            }

            license_state = [];
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    models: doc.models
                });
            });

            // if (!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make && !_fields['make'].includes(',')) {
            //     const models = [];
            //     let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

            //     _models && _models.forEach((data, index) => {
            //         models.push({
            //             value: data.value,
            //             active: _.isBoolean(data.active) ? data.active : true,
            //             label: CommonHelper.displayModelName(data),
            //         });
            //     });
            //     setModels(models);
            // }

            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                const types = [];
                const groups = [];

                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.filter((model, index) => {
                    if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                        types.push({
                            value: model.type,
                            label: model.type,
                            active: _.isBoolean(model.active) ? model.active : true,
                        });
                    }

                    if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                        groups.push({
                            value: model.group,
                            active: _.isBoolean(model.active) ? model.active : true,
                            label: model.group
                        });
                    }
                })

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        active: _.isBoolean(data.active) ? data.active : true,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setTypes(_.orderBy(types, ['label'], ['asc']));
                setGroups(_.orderBy(groups, ['label'], ['asc']));
                setModels(_.orderBy(models, ['label'], ['asc']));
            }

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('pipeline') &&
                _fields['pipeline'] &&
                !_fields['pipeline'].includes(',')) {
                let defaultstages = setttings.pipelines.filter(e => e.value === _fields['pipeline'])[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }

            setttings.lostReasons && setttings.lostReasons.forEach(doc => {
                _lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            dealersettings.client.integrations && dealersettings.client.integrations.filter(e => e.inbound === true && e.enabled === true && e.active === true && !Boolean(e.appraisal)).forEach(doc => {
                _inboundSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('lostReason') &&
                _fields.lostReason && !_fields['lostReason'].includes(',') &&
                !_.isEmpty(setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0])) {
                const _lostSubReasons = [];
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === _fields.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
                setLostSubReasons(_lostSubReasons);
            }

            if (!_.isEmpty(_fields) && _fields.hasOwnProperty('inboundSource') && _fields.inboundSource &&
                !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0])) {
                const _inboundSubTypes = [];
                let _subList = !_.isEmpty(_inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList) ?
                    _inboundSources.filter(e => e.value === _fields.inboundSource)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
                setInboundSubTypes(_inboundSubTypes);
            }

            // const viewOtherContact = ((!_.isEmpty(dealersettings) &&
            //     !_.isEmpty(dealersettings.rolePermissions) &&
            //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
            //     dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;
                // if (!viewOtherContact)
                //     ___clientUsers = ___clientUsers.filter(m => m.id === localStorage.uid);

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        searchlabel: doc.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            let _enquiryAllStatus = [];
            setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
                _enquiryAllStatus.push({
                    ...doc,
                    label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name.toUpperCase()}</div>,
                });
            });
            setAllStatus(_enquiryAllStatus);

            setClientUsers(_clientUsers);
            setMakes(_makes);
            setCountries(countries);
            setLostReasons(_lostReasons);
            setInboundSources(_inboundSources);
            setPipelines(_pipelines);
            setStages(_stages);
        }

    }, [props.dealersettings, props.clientUsers])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }

    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let state = Object.assign({}, filterFields);
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        if (data.name === 'pipeline') {
            const { dealersettings } = props;
            let _pipeline = value;
            const _stages = [];
            if (dealersettings && dealersettings.client && dealersettings.client.settings && _pipeline && !_pipeline.includes(',')) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                defaultstages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead').map((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });
            }
            setStages(_stages);
            state[data.name] = _pipeline;
            if (state.hasOwnProperty('stage'))
                state['stage'] = null;
        }
        else if (data.name === 'make') {
            let _make = value;
            const models = [];

            if (_make && !_make.includes(',')) {
                let _models = !_.isEmpty(makes.filter(m => m.value === _make)[0]) ? makes.filter(m => m.value === _make)[0].models : [];
                _models && _models.forEach((item, index) => {
                    models.push({
                        value: item.value,
                        active: _.isBoolean(item.active) ? item.active : true,
                        label: CommonHelper.displayModelName(item),
                    });
                });
            }

            setModels(models);
            state[data.name] = _make;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
        }
        else if (data.name === 'lostReason') {
            let _lostReason = value;
            const lostSubReasons = [];

            if (_lostReason && !_lostReason.includes(',') && !_.isEmpty(lostReasons.filter(m => m.value === _lostReason)[0].subList)) {
                lostReasons.filter(m => m.value === _lostReason)[0].subList.forEach(item => {
                    lostSubReasons.push({
                        value: item.value,
                        active: _.isBoolean(item.active) ? item.active : true,
                        label: item.name
                    });
                });
            }

            setLostSubReasons(lostSubReasons);
            state[data.name] = _lostReason;
            if (state.hasOwnProperty('lostSubReason'))
                state['lostSubReason'] = null;

        }
        else {
            state[data.name] = value;
        }

        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            const types = [];
            const groups = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true
                    });
                }

                if (!_.isEmpty(model.group) && index === _models.findIndex(obj => { return obj.group === model.group; })) {
                    groups.push({
                        value: model.group,
                        active: _.isBoolean(model.active) ? model.active : true,
                        label: model.group
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    active: _.isBoolean(data.active) ? data.active : true,
                    label: CommonHelper.displayModelName(data),
                });
            });

            setTypes(_.orderBy(types, ['label'], ['asc']));
            setGroups(_.orderBy(groups, ['label'], ['asc']));
            setModels(_.orderBy(models, ['label'], ['asc']));

            state[data.name] = e.value;

            if (state.hasOwnProperty('model')) state['model'] = null;
            if (state.hasOwnProperty('vehicleType')) state['vehicleType'] = null;
            if (state.hasOwnProperty('vehicleGroup')) state['vehicleGroup'] = null;

            //setFilterFields(state)
        }
        else if (data.name === 'lostReason' && e) {
            const lostSubReasons = [];
            if (!_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
                lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }
            setLostSubReasons(lostSubReasons);
            state[data.name] = e.value;
            if (state.hasOwnProperty('lostSubReason'))
                state['lostSubReason'] = null;
            //setFilterFields(state)
        }
        else if (data.name === 'inboundSource' && e) {
            const _inboundSubTypes = [];
            if (!_.isEmpty(inboundSources.filter(m => m.value === e.value)[0].subList)) {
                inboundSources.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                    _inboundSubTypes.push({
                        value: doc.Name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.Name
                    });
                });
            }
            setInboundSubTypes(_inboundSubTypes);
            state[data.name] = e.value;
            if (state.hasOwnProperty('inboundSubType'))
                state['inboundSubType'] = null;
            setFilterFields(state)
        }
        else if (data.name === 'pipeline' && e?.value) {
            const { dealersettings } = props;
            let _pipeline = e.value;
            if (dealersettings && dealersettings.client && dealersettings.client.settings) {
                const setttings = dealersettings.client.settings;
                let defaultstages = setttings.pipelines.filter(v => v.value === _pipeline)[0].stages;
                const _stages = [];
                defaultstages.forEach((doc) => {
                    _stages.push({
                        value: doc.value,
                        label: doc.name
                    });
                });

                state[data.name] = _pipeline;
                state['stage'] = null;
                //setFilterFields(state);
                setStages(_stages);
            }
        }
        else if (data.name === 'optinEmail' || data.name === 'optinPhone' || data.name === 'optinSMS' || data.name === 'optinPost' || data.name === 'marketingOptinEmail' || data.name === 'marketingOptinSMS') {
            state[data.name] = e && e.value === 'true' ? true : false;
        }
        else {

            if (data.name === 'yearFrom' && !state['yearTo'] && e?.value)
                state['yearTo'] = e.value;
            if (data.name === 'yearTo' && !state['yearFrom'] && e?.value)
                state['yearFrom'] = e.value;

            if (e)
                state[data.name] = e.value;
            else
                state[data.name] = null;
        }

        if (data.name === 'country') {
            if (e)
                handleCountryChange(e.value);
            else
                handleCountryChange('');

            if (state.hasOwnProperty('state'))
                state['state'] = '';
        }

        setFilterFields(state)
    }

    const handleGroupChange = (e, data) => {

        let state = Object.assign({}, filterFields);
        const models = [];
        const types = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.group === e.value && (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleType ? m.type === state.vehicleType : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }

            _models && _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).forEach((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.filter(m => state.vehicleGroup ? m.group === state.vehicleGroup : true).findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })
        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setTypes(_.orderBy(types, ['label'], ['asc']));
        setFilterFields(state)

    }

    const handleTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        const models = [];

        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';

        if (state.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === state.make)[0]) ? makes.filter(m => m.value === state.make)[0].models : [];
            if (e) {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => m.type === e.value && (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
            else {
                _models && _.orderBy(_models, ['name'], ['asc']).filter(m => (state.vehicleGroup ? m.group === state.vehicleGroup : true)).forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                        active: _.isBoolean(data.active) ? data.active : true,
                    });
                });
            }
        }

        if (state.hasOwnProperty('model')) state['model'] = null;
        setModels(_.orderBy(models, ['label'], ['asc']));
        setFilterFields(state)
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (name === 'make') {
                state[name] = null;
                //state['model'] = null;
            }
            else if (name === 'country') {
                state[name] = null;
                //state['state'] = null;
            }
            else if (name === 'ownerShip') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state['owner'] = userID;
            }
            else if (name === 'pipeline') {
                state[name] = null;
                state['stage'] = null;
                setStages([]);
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
                delete state['vehicleSearchOn'];
                delete state['vehicleGroup'];
                delete state['vehicleType'];
                setTypes([]);
                setGroups([]);
                setModels([]);
            }

            if (name === 'country') {
                delete state['state'];
            }

            if (name === 'ownerShip') {
                delete state['owner'];
            }

            if (name === 'inboundSource') {
                delete state['inboundSubType'];
            }

            if (name === 'pipeline')
                delete state['stage'];
        }

        setFilterFields(state);
    }

    const handleCountryChange = cCode => {
        const { dealersettings } = props;
        const states = [];
        if (cCode && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === cCode).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        setStates(states)
    }

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state)

    }

    const removeMultipleText = (index, name) => {
        let arr = filterFields[name] ? filterFields[name].split(',') : []
        arr.splice(index, 1)
        setFilterFields({
            ...filterFields,
            [name]: arr.join(',')
        })
    }

    const handleOnChangeMultipleText = (val, name) => {
        setFilterFields({
            ...filterFields,
            [name]: !_.isEmpty(val) ? val.join(',') : ''
        })
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.contactFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {
        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.contactFilter) && !_.isEmpty(props.contactFilter.value) && JSON.stringify(props.contactFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.contactFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }
    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };
    //#endregion

    // const viewOtherContact = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);
    return (
        <>
            <div className="quick-filter-body pb-5">
                <div className="advanced-filter-subheader">   <Translate text={'contact'} /></div>

                <div className="advanced-filter-list">

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            id={'ownerShip'}
                                            name={'ownerShip'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'ownerShip'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') && filterFields.owner ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(groupClients) && !_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* clientID */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={groupClients}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') ? filterFields.clientID : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactType') >= 0)
                            ?
                            <>
                                {/* contactType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactType'}><Translate text={'preferredContactType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_type}
                                                        name={"contactType"}
                                                        placeholder={'select contact Type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactType') ? filterFields.contactType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isDLScan') >= 0)
                            ?
                            <>
                                {/* isDLScan */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isDLScan'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isDLScan')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isDLScan'}><Translate text={'Digital Driving License'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isDLScan')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'isDLScan'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isDLScan"
                                                                checked={filterFields.hasOwnProperty('isDLScan') ? filterFields.isDLScan : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isDLScan') }} />
                                                            <label htmlFor="_isDLScan"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isVIP') >= 0)
                            ?
                            <>
                                {/* isVIP */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isVIP'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isVIP'}><Translate text={'vip'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isVIP')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'vip'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isVIP"
                                                                checked={filterFields.hasOwnProperty('isVIP') ? filterFields.isVIP : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isVIP') }} />
                                                            <label htmlFor="_isVIP"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('banLoanVehicle') >= 0)
                            ?
                            <>
                                {/* banLoanVehicle */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'banLoanVehicle'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('banLoanVehicle')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'banLoanVehicle'}><Translate text={'banLoanVehicle'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('banLoanVehicle')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'banLoanVehicle'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_banLoanVehicle"
                                                                checked={filterFields.hasOwnProperty('banLoanVehicle') ? filterFields.banLoanVehicle : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'banLoanVehicle') }} />
                                                            <label htmlFor="_banLoanVehicle"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        !_.isEmpty(props.dealersettings.client?.clientSettings?.prevPurchased) && (!_.isEmpty(columnFields) && columnFields.indexOf('prevPurchased') >= 0)
                            ?
                            <>
                                {/* prevPurchased */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'prevPurchased'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('prevPurchased')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'prevPurchased'}><Translate text={'prevPurchased'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('prevPurchased')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={yesNoOptions}
                                                        name={"prevPurchased"}
                                                        placeholder={'select previously purchased'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('prevPurchased') ? filterFields.prevPurchased : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>

                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('businessContact') >= 0)
                            ?
                            <>
                                {/* businessContact */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'businessContact'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('businessContact')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'businessContact'}><Translate text={'businessContact'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('businessContact')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'businessContact'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_businessContact"
                                                                checked={filterFields.hasOwnProperty('businessContact') ? filterFields.businessContact : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'businessContact') }} />
                                                            <label htmlFor="_businessContact"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('gender') >= 0)
                            ?
                            <>
                                {/* GENDER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'gender'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'gender'}><Translate text={'gender'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('gender')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={genders}
                                                        name={"gender"}
                                                        placeholder={'select gender'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('gender') ? filterFields.gender : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('maritalStatus') >= 0)
                            ?
                            <>
                                {/* maritalStatus */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'maritalStatus'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('maritalStatus')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'maritalStatus'}><Translate text={'maritalStatus'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('maritalStatus')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={marital_status}
                                                        name={"maritalStatus"}
                                                        placeholder={'select marital status'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('maritalStatus') ? filterFields.maritalStatus : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('nationality') >= 0)
                            ?
                            <>
                                {/* nationality */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'nationality'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'nationality'}><Translate text={'nationality'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('nationality')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={nationalities}
                                                        name={"nationality"}
                                                        placeholder={'select nationality'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('nationality') ? filterFields.nationality : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('country') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'country'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('country')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'country'}><Translate text={'country&state'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('country')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={countries}
                                                        name={"country"}
                                                        placeholder={'select country'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('country') ? filterFields.country : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={states}
                                                        name={"state"}
                                                        placeholder={'select state'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('state') ? filterFields.state : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('licenseState') >= 0)
                            ?
                            <>
                                {/* license_state */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'licenseState'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('licenseState')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'licenseState'}><Translate text={'licenseState'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('licenseState')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={license_state}
                                                        name={"licenseState"}
                                                        placeholder={'select license state'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('licenseState') ? filterFields.licenseState : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('licenseType') >= 0)
                            ?
                            <>
                                {/* license_state */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'licenseType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('licenseType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'licenseType'}><Translate text={'licenseType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('licenseType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={license_type}
                                                        name={"licenseType"}
                                                        placeholder={'select license state'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('licenseType') ? filterFields.licenseType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('company') >= 0)
                            ?
                            <>
                                {/* companyName */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'company'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('company')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'company'}><Translate text={'companyName'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('company')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={companys}
                                                        name={"company"}
                                                        placeholder={'select company'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('company') ? filterFields.company : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactMethod') >= 0)
                            ?
                            <>
                                {/* preferredContactMethod */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactMethod'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactMethod')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactMethod'}><Translate text={'preferredContactMethod'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactMethod')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_method}
                                                        name={"contactMethod"}
                                                        placeholder={'select contact Method'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactMethod') ? filterFields.contactMethod : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('language') >= 0)
                            ?
                            <>
                                {/* language */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'language'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('language')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'language'}><Translate text={'language'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('language')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={languages}
                                                        name={"language"}
                                                        placeholder={'select language'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('language') ? filterFields.language : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }


                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactcampaign') >= 0)
                            ?
                            <>
                                {/* campaign */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactcampaign'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactcampaign')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactcampaign'}><Translate text={'campaign'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactcampaign')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={campaigns}
                                                        name={"contactcampaign"}
                                                        placeholder={'select campaign'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('contactcampaign') && filterFields.contactcampaign ? filterFields.contactcampaign.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactorigin') >= 0)
                            ?
                            <>
                                {/* origin */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactorigin'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactorigin')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactorigin'}><Translate text={'origin'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactorigin')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={origins}
                                                        name={"contactorigin"}
                                                        placeholder={'select origin'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('contactorigin') && filterFields.contactorigin ? filterFields.contactorigin.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactsDOB') >= 0)
                            ?
                            <>
                                {/* contactsDOB */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactsDOB'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsDOB')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactsDOB'}><Translate text={'contactsDOB'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsDOB')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_dob_type}
                                                        name={"contactsDOB"}
                                                        placeholder={'select contact dob'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactsDOB') ? filterFields.contactsDOB : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactsPhone') >= 0)
                            ?
                            <>
                                {/* contactsPhone */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactsPhone'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsPhone')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactsPhone'}><Translate text={'contactsPhone'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsPhone')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_phone}
                                                        name={"contactsPhone"}
                                                        placeholder={'select contact phone'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactsPhone') ? filterFields.contactsPhone : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('contactsEmail') >= 0)
                            ?
                            <>
                                {/* contactsEmail */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'contactsEmail'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsEmail')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'contactsEmail'}><Translate text={'contactsEmail'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('contactsEmail')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={contact_email}
                                                        name={"contactsEmail"}
                                                        placeholder={'select contact email'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('contactsEmail') ? filterFields.contactsEmail : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('optinPhone') >= 0)
                            ?
                            <>
                                {/* optinPhone */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'optinPhone'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinPhone')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'optinPhone'}><Translate text={'optinPhone'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinPhone')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPhone"}
                                                        placeholder={'select phone opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('optinPhone') && _.isBoolean(filterFields.optinPhone) ? (filterFields.optinPhone ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                {/* <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'optinPhone'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_optinPhone"
                                                                checked={filterFields.hasOwnProperty('optinPhone') ? filterFields.optinPhone : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'optinPhone') }} />
                                                            <label htmlFor="_optinPhone"></label>
                                                        </span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('optinSMS') >= 0)
                            ?
                            <>
                                {/* optinSMS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'optinSMS'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinSMS')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'optinSMS'}><Translate text={'optinSMS'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinSMS')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinSMS"}
                                                        placeholder={'select sms opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('optinSMS') && _.isBoolean(filterFields.optinSMS) ? (filterFields.optinSMS ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                {/* <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'optinSMS'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_optinSMS"
                                                                checked={filterFields.hasOwnProperty('optinSMS') ? filterFields.optinSMS : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'optinSMS') }} />
                                                            <label htmlFor="_optinSMS"></label>
                                                        </span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('optinEmail') >= 0)
                            ?
                            <>
                                {/* optinEmail */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'optinEmail'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinEmail')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'optinEmail'}><Translate text={'optinEmail'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinEmail')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinEmail"}
                                                        placeholder={'select email opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('optinEmail') && _.isBoolean(filterFields.optinEmail) ? (filterFields.optinEmail ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                {/* <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'optinEmail'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_optinEmail"
                                                                checked={filterFields.hasOwnProperty('optinEmail') ? filterFields.optinEmail : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'optinEmail') }} />
                                                            <label htmlFor="_optinEmail"></label>
                                                        </span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('optinPost') >= 0)
                            ?
                            <>
                                {/* optinPost */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'optinPost'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinPost')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'optinPost'}><Translate text={'optinPost'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('optinPost')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"optinPost"}
                                                        placeholder={'select post opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('optinPost') && _.isBoolean(filterFields.optinPost) ? (filterFields.optinPost ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                {/* <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'optinPost'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_optinPost"
                                                                checked={filterFields.hasOwnProperty('optinPost') ? filterFields.optinPost : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'optinPost') }} />
                                                            <label htmlFor="_optinPost"></label>
                                                        </span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('marketingOptinEmail') >= 0)
                            ?
                            <>
                                {/* marketingOptinEmail */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'marketingOptinEmail'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketingOptinEmail')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'marketingOptinEmail'}><Translate text={'marketingOptinEmail'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketingOptinEmail')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"marketingOptinEmail"}
                                                        placeholder={'select email opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('marketingOptinEmail') && _.isBoolean(filterFields.marketingOptinEmail) ? (filterFields.marketingOptinEmail ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('marketingOptinSMS') >= 0)
                            ?
                            <>
                                {/* marketingOptinSMS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'marketingOptinSMS'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketingOptinSMS')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'marketingOptinSMS'}><Translate text={'marketingOptinSMS'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('marketingOptinSMS')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={opts}
                                                        name={"marketingOptinSMS"}
                                                        placeholder={'select sms opts'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('marketingOptinSMS') && _.isBoolean(filterFields.marketingOptinSMS) ? (filterFields.marketingOptinSMS ? 'true' : 'false') : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('noEnquiry') >= 0)
                            ?
                            <>
                                {/* noEnquiry */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'noEnquiry'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('noEnquiry')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'noEnquiry'}><Translate text={'Contact without Leads'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('noEnquiry')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'Contact without Leads'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_noEnquiry"
                                                                checked={filterFields.hasOwnProperty('noEnquiry') ? filterFields.noEnquiry : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'noEnquiry') }} />
                                                            <label htmlFor="_noEnquiry"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('areaCode') >= 0)
                            ?
                            <>
                                {/* areaCode */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'areaCode'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'areaCode'}><Translate text={'areaCode'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('areaCode')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputMultipleText
                                                        placeholder={`area code`}
                                                        className={`form-control react-multi-tag`}
                                                        name="areaCode"
                                                        onChange={(m) => { handleOnChangeMultipleText(m, 'areaCode') }}
                                                        value={filterFields.hasOwnProperty('areaCode') && filterFields.areaCode ? filterFields.areaCode.split(',') : []}
                                                        validateEmail={false}
                                                        getLabel={(_data, index) => {
                                                            return <div data-tag key={index}>
                                                                {_data}
                                                                <span data-tag-handle onClick={() => removeMultipleText(index, 'areaCode')}>
                                                                    ×
                                                                    </span>
                                                            </div>
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                    <div className="advanced-filter-subheader"><Translate text={'enquiry'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('pipeline') >= 0)
                            ?
                            <>
                                {/* pipeline */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success">
                                        <InputCheckBox
                                            className="switch"
                                            name={'pipeline'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('pipeline')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'pipeline'}>{'Pipeline & Stages'}</label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('pipeline')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={pipelines}
                                                        name={"pipeline"}
                                                        placeholder={'select pipeline'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('pipeline') && filterFields.pipeline ? filterFields.pipeline.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(stages)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactMultiSelect
                                                                    options={stages}
                                                                    name={"stage"}
                                                                    placeholder={'select stage'}
                                                                    onChange={handleMultiSelectChange}
                                                                    value={filterFields.hasOwnProperty('stage') && filterFields.stage ? filterFields.stage.split(',') : []}
                                                                    classNamePrefix={`basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('status') >= 0)
                            ?
                            <>
                                {/* STATUS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'status'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'status'}><Translate text={'status'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('status')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={enquiryAllStatus}
                                                        name={"status"}
                                                        placeholder={'select status'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('status') && filterFields.status ? filterFields.status.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('inboundSource') >= 0)
                            ?
                            <>
                                {/* ENQQUIRY TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'inboundSource'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'inboundSource'}><Translate text={'Inbound Source'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('inboundSource')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={inboundSources}
                                                        name={"inboundSource"}
                                                        placeholder={'select inbound source'}
                                                        // onChange={handleMultiSelectChange}
                                                        // value={filterFields.hasOwnProperty('inboundSource') && !_.isEmpty(filterFields.inboundSource) ? filterFields.inboundSource.split(',') : []}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('inboundSource') ? filterFields.inboundSource : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    // isMulti={true}
                                                    // isSearchable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(inboundSubTypes)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactSelect
                                                                    options={inboundSubTypes}
                                                                    name={"inboundSubType"}
                                                                    placeholder={'select inbound Sub Type'}
                                                                    onChange={handleSelectChange}
                                                                    value={filterFields.hasOwnProperty('inboundSubType') ? filterFields.inboundSubType : null}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('enquiryType') >= 0)
                            ?
                            <>
                                {/* ENQQUIRY TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'enquiryType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('enquiryType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'enquiryType'}><Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic,'enquiryType')} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('enquiryType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={enquiryTypes}
                                                        name={"enquiryType"}
                                                        placeholder={'select enquiry type'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('enquiryType') && filterFields.enquiryType ? filterFields.enquiryType.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('leadSource') >= 0)
                            ?
                            <>
                                {/* ENQQUIRY TYPE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'leadSource'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadSource')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'leadSource'}><Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic,'leadSource')} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('leadSource')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={leadSources}
                                                        name={"leadSource"}
                                                        placeholder={'select lead source'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('leadSource') && filterFields.leadSource ? filterFields.leadSource.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('label') >= 0)
                            ?
                            <>
                                {/* LABEL */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'label'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('label')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'label'}><Translate text={CommonHelper.getStaticFieldName(props?.dealersettings?.client?.settings?.enquiryOptionsStatic,'label')} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('label')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={labels}
                                                        name={"label"}
                                                        placeholder={'select label'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('label') && filterFields.label ? filterFields.label.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('lostReason') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'lostReason'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'lostReason'}>{'Lost Reason & Sub-Reasons'}</label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('lostReason')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={lostReasons}
                                                        name={"lostReason"}
                                                        placeholder={'select lost reason'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('lostReason') && filterFields.lostReason ? filterFields.lostReason.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement={'top'}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(lostSubReasons)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactMultiSelect
                                                                    options={lostSubReasons}
                                                                    name={"lostSubReason"}
                                                                    placeholder={'select lost sub reason'}
                                                                    onChange={handleMultiSelectChange}
                                                                    value={filterFields.hasOwnProperty('lostSubReason') && filterFields.lostSubReason ? filterFields.lostSubReason.split(',') : []}
                                                                    classNamePrefix={`basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                    menuPlacement={'top'}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('fusionevents') >= 0)
                            ?
                            <>

                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'fusionevents'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('fusionevents')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'fusionevents'}><Translate text={'fusionevents'} /></label>
                                    </div>
                                </div>

                                {(() => {
                                    return (
                                        <>
                                            {
                                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('fusionevents')
                                                    ?
                                                    <div className="advanced-filter-box form-style">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactMultiSelect
                                                                    options={fusionEvents}
                                                                    name={"fusionevents"}
                                                                    placeholder={'select fusion events'}
                                                                    onChange={handleMultiSelectChange}
                                                                    value={filterFields.hasOwnProperty('fusionevents') && !_.isEmpty(filterFields.fusionevents) ? filterFields.fusionevents.split(',') : []}
                                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </>
                                    )

                                })()}

                            </>
                            :
                            <></>
                    }

                    <div className="advanced-filter-subheader"><Translate text={'requirement'} /></div>
                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('make') ? filterFields.make : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={groups}
                                                        name={"vehicleGroup"}
                                                        placeholder={'select group'}
                                                        onChange={handleGroupChange}
                                                        value={filterFields.hasOwnProperty('vehicleGroup') ? filterFields.vehicleGroup : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={types}
                                                        name={"vehicleType"}
                                                        placeholder={'select type'}
                                                        onChange={handleTypeChange}
                                                        value={filterFields.hasOwnProperty('vehicleType') ? filterFields.vehicleType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('model') && filterFields.model ? filterFields.model.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement={'top'}
                                                    >
                                                    </ReactMultiSelect>
                                                    {/* <ReactSelect
                                                        options={models}
                                                        name={"model"}
                                                        placeholder={'select model'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('model') ? filterFields.model : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <ReactSelect
                                                        options={vehicleYears}
                                                        name={"yearFrom"}
                                                        placeholder={'select year from'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('yearFrom') ? filterFields.yearFrom : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <ReactSelect
                                                        options={vehicleYears}
                                                        name={"yearTo"}
                                                        placeholder={'select year to'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('yearTo') ? filterFields.yearTo : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={vehicle_search_type}
                                                        name={"vehicleSearchOn"}
                                                        placeholder={'search vehicle based on'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('vehicleSearchOn') ? filterFields.vehicleSearchOn : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }
                    {/* {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('make') >= 0)
                            ?
                            <>
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'make'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'make'}><Translate text={'make&model'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('make')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('make') && filterFields.make ? filterFields.make.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement={'top'}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                            {
                                                !_.isEmpty(models)
                                                    ?
                                                    <>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-12">
                                                                <ReactMultiSelect
                                                                    options={models}
                                                                    name={"model"}
                                                                    placeholder={'select model'}
                                                                    onChange={handleMultiSelectChange}
                                                                    value={filterFields.hasOwnProperty('model') && filterFields.model ? filterFields.model.split(',') : []}
                                                                    classNamePrefix={`basic-select`}
                                                                    isMulti={true}
                                                                    isSearchable={true}
                                                                    menuPlacement={'top'}
                                                                >
                                                                </ReactMultiSelect>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={vehicle_search_type}
                                                        name={"vehicleSearchOn"}
                                                        placeholder={'search vehicle based on'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('vehicleSearchOn') ? filterFields.vehicleSearchOn : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                    //removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    } */}

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('saleType') >= 0)
                            ?
                            <>
                                {/* saleType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'saleType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'saleType'}><Translate text={'saleType'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('saleType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={sale_type}
                                                        name={"saleType"}
                                                        placeholder={'select sale type'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('saleType') && filterFields.saleType ? filterFields.saleType.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        menuPlacement={'top'}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                </div>

            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            <button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button>
                            <button type="button" className="btn btn-default float-left ml-2" onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }


            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.contactFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'contacts'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default ContactAdvanceFilters;