export const formVM = {
    documentID: null,
    title: '',
    type: '',
    fields: '',
    clientID: null,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    visibility: '',
    oemID: null,
    groupID: null,
    regionID: null,
    backgroundColor: '#4466f2' ,
	primaryColor: '#4466f2',
	formColor: '#ffffff',
	fontColor: '#333333',
	headerColor: '#ffffff',
}

export const visibilities = [
    { active: true, value: 'private', label: 'Private' },
    { active: true, value: 'shared', label: 'Shared' }
]

export const formListFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Created On',
        value: 'createdOn',
        sortValue: 'createdOn',
        subText: 'createdBy',
        flex: 1,
        default: true
    },
    {
        name: 'Updated On',
        value: 'updatedOn',
        sortValue: 'updatedOn',
        subText: 'updatedBy',
        flex: 1,
        default: true
    },
    {
        name: 'Visibility',
        value: 'visibilityDOM',
        width: 80,
        flex: 0,
        default: true
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 280,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const rulesDynCols = [
    { name: 'Form Fields', value: 'formFields', placeholder: 'form fields', type: 'select', mandatory: true, width: 30, dWidth: 287 },
    { name: 'Form Values', value: 'formValues', placeholder: 'form values', type: 'multi', mandatory: true, width: 25, dWidth: 239, fromFieldValue: 'formFields' },
    { name: 'Move Pipeline', value: 'pipeline', type: 'select', mandatory: true, width: 15, dWidth: 143 },
    { name: 'Move Stage', value: 'stage', placeholder: 'stage', type: 'select', mandatory: true, width: 15, dWidth: 143, fromFieldValue: 'pipeline' },
]
