/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { Tab, Nav } from 'react-bootstrap'
import Translate from '../../constants/translate';
import { PopUpModal, ContentOverlay } from '../../components'
import AddDynamicReport from './addReport'
import ViewDynamicReport from './viewReport'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import AddEmailSettings from '../reports/addEmailSettings'
import uuid from 'react-uuid'
import moment from 'moment'


const Reports = (props) => {
    const [showDynModel, setShowDynmodel] = useState(false)
    const [dynamicReports, setReports] = useState([])
    const [reportIDs, setReportIDs] = useState([])
    const [loader, setLoader] = useState(true)
    const [key, setKey] = useState()
    const [showSMTPModel, setShowSMTPmodel] = useState(false)
    const [nylasCode, setNylasCode] = useState()


    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if (_.isEmpty(code))
            return

        setNylasCode(code)
        setShowSMTPmodel(true);
    }, [])

    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
            document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
            document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')
        }
        document.documentElement.style.setProperty('--reportulheight', (window.innerHeight - 130) + 'px')
        document.documentElement.style.setProperty('--reportpanelheight', (window.innerHeight - 50) + 'px')
        document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 130) + 'px')

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.dynReportsCollection && window.dynReportsCollection.unsubscribe()
            localStorage.removeItem('reportKey')
        }
    }, [])


    useEffect(() => {
        if (!props.dealersettings)
            return;
        localStorage.removeItem('reportKey')
        let refprivateReportsCollection = '';
        let refsharedReportsCollection = '';
        if (props.dealersettings.roleName === 'Administrator') {
            refprivateReportsCollection = window.firebase.firestore().collection('tradeinProReports')
                .where('clientID', '==', props.dealersettings.client.id);
        }
        else {
            refprivateReportsCollection = window.firebase.firestore().collection('tradeinProReports')
                .where('owner', '==', localStorage.uid)
                .where('clientID', '==', props.dealersettings.client.id);


            refsharedReportsCollection = window.firebase.firestore().collection('tradeinProReports')
                .where('visibility', 'in', ['shared', 'sharedView'])
                .where('clientID', '==', props.dealersettings.client.id);
        }


        window.dynReportsCollection = combineLatest(
            refprivateReportsCollection ? collection(refprivateReportsCollection) : of([]),
            refsharedReportsCollection ? collection(refsharedReportsCollection) : of([]),
            of([]),
        ).pipe(
            map(([privateReport, sharedReport, staticReport]) => {
                return [
                    _.map(privateReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(sharedReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } }),
                    _.map(staticReport, function (obj) { return { ...obj.data(), 'documentID': obj.id } })
                ]
            })).subscribe(querySnapshot => {
                let reports = [];
                for (let i = 0; i < 2; i++) {
                    querySnapshot[i].forEach(el => {
                        if (!reports.some(e => e.documentID === el.documentID && !Boolean(e.static))) {
                            const _defaultfilters = [{
                                id: uuid(),
                                name: 'date',
                                type: 'thisMonth',
                                value: {
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                }

                            }];
                            reports.push({
                                ...el,
                                pipeline: _.isArray(el.pipeline) ? el.pipeline : el.pipeline !== 'all' ? [el.pipeline] : [],
                                columns: _.isArray(el.columns) ? el.columns : [el.columns],
                                objFilters: _.isEmpty(el.objFilters) ? _defaultfilters : el.objFilters
                            })
                        }
                    });
                }

                if (_.isEmpty(localStorage.reportKey)) {
                    const _key = reports.length > 0 ? _.orderBy(reports, ['addedDate'], ['asc'])[0].documentID : '';
                    localStorage.setItem('reportKey', _key)
                    setKey(_key)
                }
                setReportIDs(..._.orderBy(reports, ['addedDate'], ['asc']).map(r => { return r.documentID }))
                setReports(_.orderBy(reports, ['addedDate'], ['asc']))
                setLoader(false)
            })

    }, [props.dealersettings])



    const handleModelDynClose = (rec, isNew) => {
        setShowSMTPmodel(false)
        setShowDynmodel(false)
        if (isNew) {
            localStorage.setItem('reportKey', rec.documentID)
            setKey(rec.documentID)
        }
    }

    return (
        <>
            <div className="middle-wrapper">
                <div className="oem-panel">
                    <Tab.Container activeKey={key} onSelect={(k) => {
                        localStorage.setItem('reportKey', k);
                        setKey(k)
                    }} mountOnEnter={true}>
                        <div id="report-wrapper">
                            <div id="sidebar-wrapper">
                                <div className="report-sidebar">
                                    <div className="custom-menu">
                                        <a href="#" className="btn-primary sidebar-toggle" onClick={(e) => {
                                            e.preventDefault()
                                            if (document.getElementById('report-wrapper')) {
                                                document.getElementById('report-wrapper').classList.toggle("toggled")
                                            }
                                        }}><i className="ico icon-menu"></i></a>
                                    </div>
                                    <div className="report-add-wrap">
                                        <h4>Reports </h4>
                                        <div className="report-email-settings"><a href="#" className="mini-button" onClick={() => {
                                            setShowSMTPmodel(true);
                                        }}> <i className="ico icon-settings"></i></a></div>
                                        <div className="report-add-button"><a href="#" className="mini-button" onClick={() => {
                                            setShowDynmodel(true)
                                            //setShowmodel(true)
                                        }}> <i className="ico icon-add"></i></a></div>
                                    </div>
                                    <Nav as="ul" bsPrefix="ul-nav report-ul-scroll">
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                                                dynamicReports.map((rec, index) => {
                                                    return <Nav.Item key={index} as="li" bsPrefix="li-nav">
                                                        <Nav.Link eventKey={rec.documentID} bsPrefix="li-a-nav">{rec.name}</Nav.Link>
                                                    </Nav.Item>
                                                })
                                            )
                                        }

                                    </Nav>
                                </div>
                            </div>
                            <div id="page-content-wrapper">
                                <div className="report-content-area">
                                    <div className="report-content-sub">
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : dynamicReports.length > 0 ? (
                                                dynamicReports.map((rec, index) => {
                                                    return <Tab.Pane key={index} eventKey={rec.documentID}>
                                                        <ViewDynamicReport {...props} report={rec} activeKey={key} setKey={(k) => { localStorage.setItem('reportKey', k); setKey(k) }} />
                                                    </Tab.Pane>
                                                })
                                            ) : (<div className="common-table">
                                                <div className="text-center p-5">
                                                    <p><Translate text={'No report found'} /></p>
                                                    <button className="btn btn-sm btn-default" onClick={() => { setShowDynmodel(true) }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                        <Translate text={'Add Report'} />
                                                    </button>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <PopUpModal show={showDynModel}>
                <AddDynamicReport
                    {...props}
                    show={showDynModel}
                    title={'Add Report'}
                    handleClose={handleModelDynClose}
                />
            </PopUpModal>
            <PopUpModal show={showSMTPModel}>
                <AddEmailSettings
                    {...props}
                    show={showSMTPModel}
                    title={'Email Settings'}
                    handleClose={handleModelDynClose}
                    nylasCode={nylasCode}
                />
            </PopUpModal>
        </>);
}

export default Reports;