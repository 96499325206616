/** LIBRARIES */
import React, { Component, Suspense } from 'react';
/** PROVIDERS */
import { LayoutConsumer } from '../layout/provider';
/** COMPONENTS */
import List from './list'

class TradeInHistory extends Component {

    render() {
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => (

                    <Suspense >
                        {/* <div className="middle-wrapper"> */}
                        <List
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            history={this.props.history}
                            dealersettings={dealersettings}
                            updateDealerSettings={updateDealerSettings}
                        />
                        {/* </div> */}

                    </Suspense>
                )}
            </LayoutConsumer>
        );
    }

}

export default TradeInHistory;