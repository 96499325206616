import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import BASE_TEMPLATE from './blankTemplate.json';
import { PopUpModal } from '../../../../components';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import BeePluginEditor from '../../workflow/templates/beepluginEditor';
import SendTestEmail from '../../workflow/templates/sendTestEmail';
import Addtemplate from './addTemplate'
import { mentions } from './viewmodel';

const beeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}

const TemplateEditor = (props) => {
    const [template, setTemplate] = useState(props.template);
    const [popHeader, setPopHeader] = useState('')
    const newTemplate = !_.isEmpty(props.template) ? false : true
    const [sendEmailModel, setSendEmailModel] = useState(false)
    const [showEditModel, setShowEditModel] = useState({ show: false })

    const handleModelClose = (template) => {
        setSendEmailModel(false)
        if (!_.isEmpty(template)) {
            if (props.returnAfterCreation) {
                props.handleClose(template)
            } else {
                props.handleClose()
            }
        }
    }

    const save = (filename, content) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: 'text/plain;charset=utf-8' });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    };

    return (
        <>
            <Modal
                show={props.show}
                backdrop="static"
                backdropClassName={'overlay-modal active'}
                dialogClassName="modal-dialog fullscreenmodal-lg add-triggers-fullscreen">
                <div className="modal-content modal-content-fullscreen">
                    <Modal.Header className="modal-header-fullscreen">
                        <div className="modal-title">  <Translate text={props.title} /></div>
                        <button type="button" className="close close-fullscreen float-right" onClick={() => { props.handleClose() }}>×</button>
                    </Modal.Header>
                    <Modal.Body className="modal-body-fullscreen">
                        <div className="popup-wrap-fullscreen">
                            <main role="main" className="container">
                                <BeePluginEditor
                                    {...props}
                                    className="containerClassName"
                                    template={newTemplate ? BASE_TEMPLATE : JSON.parse(template.templateJSON)}
                                    style={beeStyles}
                                    specialLinks={props.specialLinks}
                                    onSave={(jsonFile, htmlFile) => {
                                        var _contentEmail = htmlFile;
                                        var _contentJson = jsonFile;
                                        setShowEditModel({
                                            show: true,
                                            title: 'Subject',
                                            template: {
                                                ['emailTemplate']: _contentEmail,
                                                ['templateJSON']: _contentJson
                                            }
                                        })
                                    }}
                                    onSend={htmlFile => {
                                        setTemplate({
                                            ...template,
                                            ['emailTemplate']: htmlFile
                                        })
                                        setPopHeader('Send Test Email');
                                        setSendEmailModel(true)
                                    }}
                                    onSaveAsTemplate={jsonFile => { save('email-template.json', jsonFile) }}
                                    onError={errorMessage => console.log(errorMessage)}
                                />
                            </main>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <PopUpModal show={showEditModel}>
                <Addtemplate
                    {...props}
                    {...showEditModel}
                    handleClose={(template) => {
                        if (!_.isEmpty(template))
                            props.handleClose(template)
                        else
                            setShowEditModel({ show: false })
                    }}
                    newTemplate={newTemplate}
                />
            </PopUpModal>
            <PopUpModal show={sendEmailModel}>
                <SendTestEmail
                    {...props}
                    show={sendEmailModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    template={template}
                    mentions={mentions}
                />
            </PopUpModal>
        </>
    )
}

export default TemplateEditor