import React, { useState } from 'react';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import SaveFilter from '../../filters/saveFilter'
import { PopUpModal } from '../../../components';
import CommonHelper from '../../../services/common';

const CustomFilters = (props) => {
    const [showSaveFilter, setShowSaveFilter] = useState(false)

    const { handleSelectFilter, localFilter, _filter, userID } = props;

    const handleDeleteFilter = (filterID) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete filter.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                const { dealersettings } = props;
                let settingsid = dealersettings.client.settings.id;
                if (settingsid && filterID) {
                    window.firebase.firestore().doc(`clientSettings/${settingsid}/filters/${filterID}`).delete().then(() => {
                        toast.notify('Filter successfully deleted!', {
                            duration: 2000
                        })

                    }).catch((error) => {
                        console.error("Error removing Filter: ", error);
                    });
                }
            }
        })
    };

    const saveFavourite = (filterID, checked) => {
        const { dealersettings } = props;
        let settingsid = dealersettings.client.settings.id;
        let objData = _filter;

        if (settingsid && filterID && !_.isEmpty(objData)) {
            let currentDate = window.firebase.firestore.Timestamp.now();
            let isFav = objData.isFav;

            if (checked)
                isFav.push(userID)
            else
                isFav = isFav.filter(m => m !== userID);

            const refStockData = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`).doc(filterID);
            refStockData.set({
                isFav: isFav,
                modifiedBy: userID,
                modifiedDate: currentDate,
                modifiedFrom: 'web'
            }, { merge: true }).then((docRef) => {
                toast.notify('Favourite saved successfully', {
                    duration: 2000
                });

            }).catch((error) => {
                console.error("Error updating filter_details: ", error);
            });
        }

    }

    const editFilter = () => {
        setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        setShowSaveFilter(false);

    }

    return (
        <>
            <li>
                <a href="#" onClick={(e) => { e.preventDefault(); handleSelectFilter(_filter); }}>
                    {
                        (!_.isEmpty(localFilter) && _filter.documentID === localFilter.documentID && localFilter.type === 'custom')
                            ?
                            <span className="quick-filter-check"><i className="ico icon-check"> </i></span>
                            :
                            <>
                            </>
                    }
                    {_filter.name}
                </a>

                {
                    (_filter.addedBy === userID || props?.dealersettings?.roleName === 'Administrator')
                        ?
                        <span className="icon-custom-edit" onClick={(e) => {
                            e.preventDefault();
                            editFilter();
                        }}><i className="ico icon-edit"></i></span>
                        :
                        <></>
                }

                <span className={`icon-fav ${(!_.isEmpty(_filter.isFav) && _filter.isFav.indexOf(userID) >= 0) ? 'fav-checked' : ''}`} >
                    {
                        (!_.isEmpty(_filter.isFav) && _filter.isFav.indexOf(userID) >= 0)
                            ?
                            <i className="ico icon-star-active" onClick={(e) => {
                                e.preventDefault();
                                saveFavourite(_filter.documentID, false)
                            }}></i>
                            :
                            <i className="ico icon-star" onClick={(e) => {
                                e.preventDefault();
                                saveFavourite(_filter.documentID, true)
                            }}></i>
                    }

                </span>

                {
                    (!_.isEmpty(localFilter) && _filter.documentID === localFilter.documentID && localFilter.type === 'custom' && (_filter.addedBy === userID || props?.dealersettings?.roleName === 'Administrator'))
                        ?
                        <>
                            {/* <span className="icon-filter-delete"><i className="ico icon-share"></i></span> */}
                        </>
                        :
                        <>
                            {
                                (_filter.addedBy === userID || props?.dealersettings?.roleName === 'Administrator')
                                    ?
                                    <span className="icon-filter-delete" onClick={(e) => {
                                        e.preventDefault();
                                        handleDeleteFilter(_filter.documentID)
                                    }}><i className="ico icon-trash"></i></span>
                                    :
                                    <></>
                            }
                        </>
                }

            </li>


            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={_filter.value}
                    activeFilter={_filter}
                    filtersList={props.customFilters}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    module={props.module}
                >
                </SaveFilter>
            </PopUpModal>
        </>
    )
}

export default CustomFilters