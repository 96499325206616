import React, { useState, useEffect } from 'react'
import "../../../styles/tradeinCustomer.scss";
import _ from 'lodash'
import moment from 'moment';
import Swal from 'sweetalert2'
import SignatureCanvas from 'react-signature-canvas'

import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { ContentOverlay } from '../../../components';
import Translate from '../../../constants/translate';


const TestdriveDetails = (props) => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [signRequest, setsignRequest] = useState({})
    const [trimmedDataURL, setTrimmedDataURL] = useState(null)
    const [pagenotFound, setpagenotFound] = useState(false);
    const [firestoreDB, setFirestoreDB] = useState()
    const [storageBucket, setStorageBucket] = useState()
    const [projectId, setProjectId] = useState()
    const [errorInsurance, setErrorInsurance] = useState(false)

    const {
        title,
        client,
        terms,
    } = signRequest;

    let signPad = {}

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let customerID = params.get('cid');

        if (props.testdriveid && customerID) {
            loadServieData(customerID);
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        if (_.isEmpty(firestoreDB.auth().currentUser)) {
            firestoreDB.auth().signInWithEmailAndPassword('secondarydb@fusionamspro.com', 'secondaryDB@321')
                .catch((error) => {
                    console.log('error on secondary firestore auth user', error)
                });
        }
    }, [firestoreDB])

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let customerID = params.get('cid');
        if (customerID.includes('@')) {
            setFirestoreDB(window.firebase);
            setStorageBucket(window.firebase.storage())
        }
        else {
            window.firebase.firestore().doc(`contactDetails/${customerID}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase);
                        setStorageBucket(window.firebase.storage())
                    }
                    else {
                        setFirestoreDB(window.firebase2);
                        setStorageBucket(window.firebase.app().storage(`gs://${window.firebase.app().options.projectId}-eu`));
                        setProjectId(window.secondaryProjectId);
                    }
                })
        }

    }, [])

    const loadServieData = async (customerID) => {

        //window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`).get()
        window.refCollectionData = docData(firestoreDB.firestore().doc(`contactDetails/${customerID}/signRequest/${props.testdriveid}`))
            .pipe(
                switchMap(notify => {
                    const clientIds = notify.clientID ? [notify.clientID] : null;
                    return combineLatest(
                        of(notify),
                        combineLatest(
                            clientIds ? clientIds.map(clientID =>
                                collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                    map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                )
                            ) : of([])
                        )
                    )


                }),
                map(([notify, clientSnapshot]) => {

                    if (!_.isEmpty(notify))
                        return {
                            ...notify,
                            client: clientSnapshot ? clientSnapshot.find(a => a.documentID === notify.clientID) : null
                        }
                    else {
                        return {}
                    }

                })
            )
            .subscribe(_notify => {

                if (!_.isEmpty(_notify)) {
                    setsignRequest(_notify);

                    setIsPageLoading(false);
                }
                else {
                    setpagenotFound(true)
                }
                window.refCollectionData && window.refCollectionData.unsubscribe()


            });

    }

    const handleInsuranceCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        setsignRequest({ ...signRequest, insuranceConsent: checked });
        //this.setState({ [name]: checked });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (signRequest?.isMandatoryInsurance === true && !signRequest?.insuranceConsent) {
            setErrorInsurance(true)
            return false;
        }
        else if (signPad.getSignaturePad()._isEmpty) {
            Swal.fire('Please provide a signature.', '', 'info')
            return false;
        }
        else {
            //console.log(signPad.getTrimmedCanvas().toDataURL('image/png'))
            saveImageToStorage(signPad.getTrimmedCanvas().toDataURL('image/png'))
            // let promises = [];
            // let file = signPad.getTrimmedCanvas().toDataURL('image/png')
            // let uid = moment()._d.getTime().toString() + index + '.' + file.name.substr((file.name.lastIndexOf('.') + 1));
            // let storageRef = storageBucket(props.dealersettings).ref(`${clientID}/tradeins/${this.newDocID}/${uid}`);
            // promises.push(
            //     storageRef.putString(file, 'data_url').then((snapshot) => {
            //         return snapshot.ref.getDownloadURL()
            //     })
            // );
        }
    };

    const saveImageToStorage = (imageBase64) => {
        setLoading(true)
        let uid = moment()._d.getTime().toString();
        const { clientID, id, type } = signRequest

        var storageRef = storageBucket.ref(`${clientID}/signRequest/${id}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                console.error(error);
            },
            () => {
                storageBucket.ref(`${clientID}/signRequest/${id}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        //console.log('saveImageToStorage-2', dataURL);

                        let _objData = {
                            ...signRequest
                        }

                        let objData = { insuranceConsent: signRequest?.insuranceConsent ? true : false }
                        if (type === 'signOnly') {
                            _objData.signature = dataURL
                            objData.signature = dataURL
                        }
                        else {
                            _objData.terms['signature'] = dataURL
                            objData.terms = _objData.terms
                        }
                        _objData.status = 'signed';
                        objData.status = 'signed';
                        objData.projectId = projectId ? projectId : null;

                        let _reqObject = {
                            "documentID": signRequest.id,
                            "collection": `contactDetails/${signRequest.contactID}/signRequest`,
                            "data": objData
                        }

                        //console.log('_reqObject', _reqObject)
                        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
                        genericSaveData(_reqObject).then((response) => {
                            //console.log(response);
                            if (response.data.success) {
                                setsignRequest(_objData);
                                setLoading(false)
                            }
                            else {
                                setLoading(false)
                                //this.forceUpdate();
                            }
                        });



                    })
            })
    };


    return (
        <>
            {
                isPageLoading
                    ?
                    <>
                        {
                            pagenotFound
                                ?
                                <>
                                    <div
                                        className='middle-wrapper h-100 mt-0'
                                        style={{
                                            minHeight: '90vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'floralwhite'
                                        }}
                                    >
                                        <div className='spinner-loader h-100'>
                                            <div className='no-data-flex-wrap h-100 justify-content-center align-items-center text-primary'>
                                                <div className='no-data-img-wrap'>
                                                    <img src={_images.pagenotFound} height='190' alt='' />
                                                </div>
                                                <div className='no-data-txt-wrap'>
                                                    <div className='no-data-content-head'>
                                                        Page Not Found.
                                                    </div>
                                                    <div className='no-data-content'>
                                                        The page you requested could not be found or may be deleted.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <><div className="mt-5"><ContentOverlay active={true} /></div></>
                        }

                    </>
                    :
                    <>

                        <div className="cst-tradeinform-header">
                            <div className="container">
                                <div className="cst-tradeinform-logo-wrap">
                                    <span className="cst-tradeinform-logo-img mr-3">
                                        {
                                            !_.isEmpty(client) && client.logoURL
                                                ?
                                                <img src={client.logoURL} height="60" />
                                                :
                                                <></>
                                        }

                                    </span>

                                </div>
                            </div>
                        </div>

                        <div className="container">

                            <div className="cst-tradeinform-wrap-bg">
                                <div className="cst-tradeinform-wrap">

                                    <div className="cst-tradeinform-loop">

                                        <div className="cst-tradeinform-vehiclehead">{title}</div>
                                        <div className="cst-tradeinform-field-wrap form-style">
                                            <div className="tabs-wraper">

                                                <div className="form-row">

                                                    <div className="form-group col-md-12">

                                                        <div className="table-wraper brd-none page-break mt-3">
                                                            {(!_.isEmpty(signRequest?.insuranceTerms) || signRequest?.isMandatoryInsurance) && (<div className="cst-tradeinform-upload mt-2 pl-0">Terms & Conditions</div>)}
                                                            {
                                                                !_.isEmpty(signRequest.terms)
                                                                    ?
                                                                    <div className="terms"
                                                                        dangerouslySetInnerHTML={{ __html: (signRequest.terms.termsAndConditions ? signRequest.terms.termsAndConditions : '--') }} />
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                        {
                                                            (!_.isEmpty(signRequest?.insuranceTerms) || signRequest?.isMandatoryInsurance) ?
                                                                <><div className="table-wraper brd-none page-break mt-2">
                                                                    <div className="cst-tradeinform-upload mt-2 pl-0"><Translate text={'Insurance Waiver'} /></div>
                                                                    {
                                                                        !_.isEmpty(signRequest.insuranceTerms)
                                                                            ?
                                                                            <div className="terms"
                                                                                dangerouslySetInnerHTML={{ __html: (signRequest.insuranceTerms.termsAndConditions ? signRequest.insuranceTerms.termsAndConditions : '--') }} />
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>

                                                                    <div className={`form-terms-check-container ${signRequest.insuranceConsent ? '' : (errorInsurance ? 'input_error' : '')} ${(signRequest.status === 'requested') ? '' : 'form-disable'}`}>
                                                                        <div className="checkbox icheck-success">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="insuranceConsent"
                                                                                name="insuranceConsent"
                                                                                className="uncheck-activity"
                                                                                checked={(signRequest.insuranceConsent) ? true : false}
                                                                                onChange={(e) => { handleInsuranceCheckChange(e, 'insuranceConsent') }} />
                                                                            <label htmlFor="insuranceConsent">{'I agree to insurance waiver terms & conditions'}</label>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>}
                                                    </div>
                                                </div>

                                                <div className="form-row pb-4">
                                                    <div className="form-group col-md-12">
                                                        {/* <label><Translate text={'Signature'} /></label> */}
                                                        <div className="cst-tradeinform-upload mt-2 pl-0">{'Signature'}</div>
                                                        <div className="table-wraper brd-none page-break">
                                                            <div className="vehicle-map">
                                                                {
                                                                    signRequest.status === 'requested'
                                                                        ?
                                                                        <SignatureCanvas
                                                                            penColor='black'
                                                                            ref={(ref) => { signPad = ref }}
                                                                            canvasProps={{ width: 800, height: 200, className: 'sigCanvas', src: _images.sign }}
                                                                        />
                                                                        :
                                                                        <>
                                                                            {
                                                                                signRequest.terms && signRequest.terms.signature
                                                                                    ?
                                                                                    <img id={`terms-signature`} src={signRequest.terms.signature} width="120" />
                                                                                    :
                                                                                    <img id={`terms-signature`} src={signRequest.signature} width="120" />
                                                                            }
                                                                        </>
                                                                }

                                                                {/* */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                            <div className="cst-tradeinform-submitwrap">
                                                {

                                                    signRequest.status === 'requested'
                                                        ?
                                                        <>

                                                            <div className={`accordion-bottom approve-btn ${isLoading ? 'form-disable' : ''}`} >

                                                                <a href="#"
                                                                    className={`btn btn-common float-left mt-3 `}
                                                                    onClick={(e) => {
                                                                        signPad.clear();
                                                                        setTrimmedDataURL(null);
                                                                    }}>
                                                                    <Translate text={'clear'} upperCase={true} />
                                                                </a>

                                                                <a href="#"
                                                                    className={`btn btn-common button-black float-right mt-3 `}
                                                                    onClick={(e) => { handleSubmit(e) }}>
                                                                    {isLoading ? <span className="spinner-border spinner-button mr-2 mt-0" role="status" aria-hidden="true"></span> : <></>}
                                                                    <Translate text={'Send'} upperCase={true} />
                                                                </a>

                                                                <div className="clear"> </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <footer>
                                                    <div className="footer-cpy">
                                                        <div className="footer-bottom">
                                                            <div className="cpy-wrap"> <img src={_images.safeTag} width="120" alt="" /></div>
                                                            <div className="cpy-wrap">
                                                                <span style={{ paddingLeft: 0 }} >
                                                                    {client.name ? <strong>{client.name}</strong> : <></>}
                                                                </span>
                                                                <span style={{ paddingLeft: 0 }}>
                                                                    {client?.address ? <> &nbsp; | &nbsp;<span style={{ paddingLeft: 0 }} >{client.address}</span></> : <></>}
                                                                    {client?.phone ? <> &nbsp; | &nbsp;<Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                                                    {client?.abn ? <>&nbsp; | &nbsp;<Translate text={'ABN'} />: <span style={{ paddingLeft: 0 }} >{client.abn}</span></> : <></>}
                                                                    {client?.acn ? <>&nbsp; | &nbsp;<Translate text={'ACN'} />: <span style={{ paddingLeft: 0 }} >{client.acn}</span></> : <></>}
                                                                    {client?.website ? <>&nbsp; | &nbsp;<Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                                                </span>
                                                            </div>
                                                            <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>
                                                </footer>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        {/*<div className="quotation-wrap report-data-load">
                            <div className="wrapper">
                                <div className="print-wrap">
                                    <div className="header">
                                        <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td width="60%"><div className="header-left">

                                                        <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                                    </div></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="clear"></div>
                                    <div className="clear"></div>
                                    <div className="middle">
                                        <div className="clear"></div>



                                        {
                                            !_.isEmpty(signRequest)
                                                ?
                                                <>

                                                    <div className="table-wraper brd-none page-break mt-5">
                                                        <div className="table-head">{title}</div>

                                                        {
                                                            !_.isEmpty(signRequest.terms)
                                                                ?
                                                                <div className="terms"
                                                                    dangerouslySetInnerHTML={{ __html: (signRequest.terms.termsAndConditions ? signRequest.terms.termsAndConditions : '--') }} />
                                                                :
                                                                <></>
                                                        }
                                                    </div>

                                                    <div className="table-wraper brd-none page-break">
                                                        <div className="table-head">Signature </div>
                                                        <div className="vehicle-map">
                                                            {
                                                                signRequest.status === 'requested'
                                                                    ?
                                                                    <SignatureCanvas
                                                                        penColor='black'
                                                                        ref={(ref) => { signPad = ref }}
                                                                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas', src: _images.sign }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {
                                                                            signRequest.terms && signRequest.terms.signature
                                                                                ?
                                                                                <img id={`terms-signature`} src={signRequest.terms.signature} width="120" />
                                                                                :
                                                                                <img id={`terms-signature`} src={signRequest.signature} width="120" />
                                                                        }
                                                                    </>
                                                            }

                                                            
                                                        </div>
                                                    </div>
                                                    {

                                                        signRequest.status === 'requested'
                                                            ?
                                                            <>
                                                                <button type="button" className="btn btn-common float-left mt-3" onClick={() => {
                                                                    signPad.clear();
                                                                    setTrimmedDataURL(null);
                                                                }}><Translate text={'clear'} upperCase={true} /></button>


                                                                <div className={`accordion-bottom approve-btn ${isLoading ? 'form-disable' : ''}`} >
                                                                    <a href="#"
                                                                        className={`btn btn-common button-black float-right mt-3 `}
                                                                        onClick={(e) => { handleSubmit(e) }}>
                                                                        {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                                        <Translate text={'Send'} upperCase={true} />
                                                                    </a>
                                                                    <div className="clear"> </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }

                                                </>
                                                :
                                                <>
                                                </>
                                        }




                                    </div>
                                </div>
                                <div className="push"></div>
                            </div>
                            <div className="clear"></div>
                            <div className="footer">
                                <div className="footer-bottom">

                                    <p>
                                        <span style={{ paddingLeft: 0 }} >
                                            {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                                        </span>
                                        <span style={{ paddingLeft: 0 }}>
                                            {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                            {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                                        </span>
                                    </p>
                                </div>
                                <div className="footer-cpy">
                                    <div className="footer-left">{'Powered by FusionSD.'}</div>

                                    <div className="footer-right"></div>

                                </div>
                                <div className="clear"></div>
                            </div>
                                    </div>*/}
                    </>

            }
        </>
    )
}
export default TestdriveDetails;