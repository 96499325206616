import React, { useState, useEffect, useReducer, useCallback } from 'react';
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { ContentOverlay, PopUpModal, PopoverwithFooter } from '../../components'
import CommonHelper from '../../services/common'
import { default as _images } from '../../images';
import AcitivitylogReducer from '../pipeline/quickview/activitylogReducer'
import AddActivity from '../activity'
import ActivityView from './activityView'
import { objActivitylog, activitylogTypes, objWalkIn } from '../pipeline/viewModel'
import FilterPanel from './filterPanel'
import ActivityStatus from '../activity/actvityStatusModal';
import { enqUnActionStatus, enquiryStatus } from '../../services/enum';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const ActivitiesCount = (props) => {
    const [loadingActivity, setLoadingActivity] = useState(true)
    const [showActivity, setShowActivity] = useState(false);
    const [activitylogs, dispatch] = useReducer(AcitivitylogReducer, { Overdue: [], Today: [], Upcoming: [] })
    const acitivtyTags = ["Overdue", "Today", "Upcoming"]
    const [activeAcitivtyTag, setActiveactivity] = useState('Overdue')
    const [selectedActivitylog, setSelectedActivitylog] = useState(null);
    const [activeTab, setActivetab] = useState('appointment')
    const [editable, setEditable] = useState(false);
    const [filtertype, setFiltertype] = useState(null)
    const [sectionLoading, setSectionLoading] = useState(false);
    const [showActivityModel, setShowActivityModel] = useState(false)
    const [enquiryObj, setEnquiryObj] = useState(null);
    const [contactObj, setContactObj] = useState(null);
    const [stockObj, setStockObj] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState({ "Overdue": true, "Today": true, "Upcoming": true })
    const [pagingLoader, setPagingloader] = useState(false)
    const pageLimit = 10
    const [activityHeader, setActivityHeader] = useState('Add New Activity')
    const [isNewActivity, setNewActivity] = useState(false)
    const [selectedUser, setUser] = useState([])
    const [searchText, setSearchText] = useState('')
    const [filterPopOver, setFilterPopOver] = useState({ show: false, target: null })
    //const [viewOtherActivity, setOtherActivity] = useState(false);
    const [userOptions, setUserOptions] = useState([])
    const [showScheduleActivityModel, setShowScheduleActivityModel] = useState({
        show: false,
        activityLog: null,
        contact: null,
        enquiry: null
    })
    const [activityCountsLoader, setActivityCountsLoader] = useState(false)
    const [activityCounts, setActivityCounts] = useState({})
    const [showOutcomeModal, setShowOutcomeModal] = useState({
        show: false,
        clsActive: '',
        isMandatory: false,
        data: {}
    });
    const [pageNum, setPageNum] = useState({ Overdue: 0, Today: 0, Upcoming: 0 });
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [refreshTime, setRefreshTime] = useState(0)
    const docID = localStorage.defaultModule === 'oem' ? props.dealersettings.oemID : props.dealersettings.client.id;
    const docPathRef = window.firebase.firestore().doc(`users/${localStorage.uid}/dashoardWidgets/${docID}-${props.docID}`);
    const enableCustShowNoShow = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);

    // let accessToFinanceActivity = (!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions) &&
    //     !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //     props.dealersettings.rolePermissions.permissions.accessToFinanceActivity) ? true : false

    let ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    // useEffect(() => {
    //     setOtherActivity((!_.isEmpty(props.dealersettings) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions) &&
    //         !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
    //         props.dealersettings.rolePermissions.permissions.modifyOtherActivity) ? true : false)
    // }, [props.dealersettings])

    // useEffect(() => {
    //     if (_.isEmpty(props.clientUsers))
    //         return
    //     let _users = [];
    //     props.clientUsers.forEach(rec => {
    //         _users.push({
    //             value: rec.id,
    //             active: _.isBoolean(rec.active) ? rec.active : true,
    //             searchlabel: rec.name,
    //             label: <div className="drop-image">
    //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" />
    //                 {rec.name}
    //             </div>,
    //             data: rec
    //         })
    //     })
    //     setUserOptions(_users)
    // }, [props.clientUsers])

    useEffect(() => {
        if (refreshTime === 0)
            return;

        const timer = setTimeout(() => {
            if (moment().unix() - refreshTime > 1000) {
                if (_.isEmpty(props.dashboardFilter))
                    fetchCounts(true);
            }
        }, props.refreshTimer);
        return () => clearTimeout(timer);
    }, [refreshTime])

    useEffect(() => {
        if (!Boolean(props.dealersettings.ownDashboard) || props.fromOEM)
            return
        fetchCounts();
        setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!initialLoad || (props.dealersettings.ownDashboard && !props.fromOEM))
            return
        window.unsubdashboardactivityCount = docPathRef
            .onSnapshot(snap => {
                if (snap.exists) {
                    setInitialLoad(false)
                    setActivityCounts(snap.data().counts);
                    setRefreshTime(snap.data().logDate ? snap.data().logDate.seconds : 0);
                    if (snap.data().logDate && moment().unix() - snap.data().logDate.seconds > 1000) {
                        setTimeout(() => {
                            fetchCounts(true);
                        }, props.delayTimer);
                    }
                }
                else {
                    fetchCounts();
                }
            })
    }, [initialLoad])

    useEffect(() => {
        let _actName = activitylogTypes.filter(e => e.type === activeTab)[0] ? activitylogTypes.filter(e => e.type === activeTab)[0].name : activeTab
        let newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = props?.activityType || "activity";
        newlogVM.subType = activeTab;
        newlogVM.title = _actName;
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        //newlogVM.clientID = props.dealersettings.client.id;
        newlogVM.documentID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        newlogVM.startDate = window.firebase.firestore.Timestamp.fromDate(moment()._d);
        newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(['call'].includes(activeTab) ? moment().add(15, 'minutes')._d : moment().add(1, 'hours')._d);
        //window.firebase.firestore.Timestamp.fromDate(moment().add(1, 'hours')._d);
        newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), localStorage.uid);
        newlogVM = CommonHelper.setLevelIDs(newlogVM, props.dealersettings);
        setSelectedActivitylog(newlogVM)
    }, [activeTab])

    useEffect(() => {
        return () => {
            window.unsubdashboardactivitylist && window.unsubdashboardactivitylist();
            window.unsubdashboardactivityCount && window.unsubdashboardactivityCount();
        }
    }, [])

    useEffect(() => {

        let dataload = { "Overdue": true, "Today": true, "Upcoming": true }
        setHasMoreData([])
        dispatch({
            type: "CLEAR_ALL"
        });
        setPageNum({ Overdue: 0, Today: 0, Upcoming: 0 })
        setDataload({
            ...dataload,
            [activeAcitivtyTag]: true
        })

    }, [props?.dashboardFilter])

    const fetchCounts = async () => {

        let _filter = {
            //clientID: props.dealersettings.client.id,
            timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
        }
        if (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'oem' && props.dealersettings.oemID)
            _filter.oemID = props.dealersettings.oemID;
        else if (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'group' && props.dealersettings.groupID)
            _filter.groupID = props.dealersettings.groupID;
        else if (localStorage.defaultModule === 'oem' && props.dealersettings.level === 'region' && props.dealersettings.regionID)
            _filter.regionID = props.dealersettings.regionID;
        else {
            _filter.clientID = props.dealersettings.client.id;
        }
        if (props?.dashboardFilter?.pipelines?.length > 0) {
            _filter.pipeline = props.dashboardFilter.pipelines.join(',')
        } else if (props?.pipeline) {
            if (inclInboundLeadsinRpt)
                _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
            else
                _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
        }
        if (props?.dashboardFilter?.user?.length > 0) {
            _filter.owner = props.dashboardFilter.user.join(',')
        }
        if (props?.dashboardFilter?.makes?.length > 0) {
            _filter.make = props.dashboardFilter.makes.join(',')
        }
        if (props.ownerDashboard) {
            _filter.owner = localStorage.uid
        }
        if (props?.dashboardFilter?.daterange?.startDate && props?.dashboardFilter?.daterange?.endDate) {
            _filter.startDate = moment(props.dashboardFilter.daterange.startDate.toDate()).format('YYYY-MM-DD')
            _filter.endDate = moment(props.dashboardFilter.daterange.endDate.toDate()).format('YYYY-MM-DD')
        }

        //if (accessToFinanceActivity)
        _filter.type = props?.activityType || "activity";

        if (props?.dashboardFilter?.salesType?.length > 0) {
            _filter.saleType = props.dashboardFilter.salesType.join(',')
        } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
            _filter.saleType = props.dealersettings.accessSalesType.join(',');
        }

        let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
        if (!_.isEmpty(excludeOrigins))
            _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

        let _searchObject = {
            type: props?.dealersettings?.mvwReport ? "getActivitesCountV2" : "getActivitesCount",
            params: JSON.stringify(_filter),
        }
        //setActivityCountsLoader(true)
        const getData = window.firebase.functions().httpsCallable('generic-getData');
        const resp = await getData(_searchObject);
        if (resp.data.success) {
            //console.log('resp leadsBucket', resp)
            // console.log('getActivitesCount',resp)
            const data = resp.data.data[0]
            // console.log(`data of the counts`, data)
            setActivityCounts(data)
            setActivityCountsLoader(false)
            if (_.isEmpty(props.dashboardFilter)) {
                await docPathRef.set({ counts: data, logDate: window.firebase.firestore.Timestamp.now() }, { merge: true })
            }
            setFirstTimeLoaded(true)
        } else {
            setActivityCountsLoader(false)
            setFirstTimeLoaded(true)
        }
    }

    useEffect(() => {
        if (initialLoad)
            return;
        if (!_.isEmpty(props.dashboardFilter)) {
            window.unsubdashboardactivityCount && window.unsubdashboardactivityCount();
            setFirstTimeLoaded(false);
            fetchCounts();
        }
        else {
            setInitialLoad(true)
        }

    }, [props.dashboardFilter])

    useEffect(() => {
        if (initialLoad)
            return;
        fetchCounts();
    }, [props.refreshData])

    useEffect(() => {
        if (!checkDataLoad[activeAcitivtyTag] || !_.isEmpty(props.dashboardFilter) || props?.dealersettings?.accessSalesType?.length) {
            return;
        }

        setPagingloader(true)
        let refActivitiesData = firestoreDB(props.dealersettings).firestore().collection('activities')
            //.where('clientID', '==', props.dealersettings.client.id)
            //.where('type', '==', 'activity')
            .where('isDeleted', '==', false)
        refActivitiesData = CommonHelper.whereClientOrGroupOrRegionOrOemLevel(refActivitiesData, props.dealersettings, null, true);

        //if (accessToFinanceActivity)
        //    refActivitiesData = refActivitiesData.where('type', 'in', ['activity', 'financeactivity'])
        //else
        refActivitiesData = refActivitiesData.where('type', '==', props?.activityType || "activity")


        if (ownerDashboard)
            refActivitiesData = refActivitiesData.where('searchUsers', 'array-contains', localStorage.uid)
        else if (props?.dashboardFilter?.user?.length > 0)
            refActivitiesData = refActivitiesData.where('searchUsers', 'array-contains-any', props.dashboardFilter.user)

        if (props?.dashboardFilter?.pipelines?.length > 0)
            refActivitiesData = refActivitiesData.where('enquiry.pipeline', 'in', props.dashboardFilter.pipelines)
        else if (props?.pipeline) {
            if (inclInboundLeadsinRpt)
                refActivitiesData = refActivitiesData.where('enquiry.pipeline', 'in', props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value))
            else
                refActivitiesData = refActivitiesData.where('enquiry.pipeline', 'in', props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value))
        }

        if (activeAcitivtyTag === 'Overdue') {
            refActivitiesData = refActivitiesData
                .where('isDone', '==', false)
                .where('startDate', '<', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
        }
        else if (activeAcitivtyTag === 'Upcoming') {
            refActivitiesData = refActivitiesData
                .where('isDone', '==', false)
                .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().add('day', 1).startOf('day')._d))
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().add('day', 1).endOf('day')._d))
        }
        else if (activeAcitivtyTag === 'Today') {
            refActivitiesData = refActivitiesData
                .where('isDone', '==', false)
                .where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d))
                .where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d))
        }
        else {
            refActivitiesData = refActivitiesData
                .where('isDone', '==', true)
        }


        if (activeAcitivtyTag === 'Completed' || localStorage.defaultModule === 'oem') {
            refActivitiesData = refActivitiesData
                .orderBy('startDate', 'desc')
        }
        else if (activeAcitivtyTag === 'Upcoming') {
            refActivitiesData = refActivitiesData
                .orderBy('startDate', 'asc')
        }
        else {
            refActivitiesData = refActivitiesData
                .orderBy('startDate', 'asc')
        }

        //.limit(pageLimit)
        let lastRecord = hasMore.filter(e => e.type === activeAcitivtyTag);
        if (lastRecord.length > 0) {
            refActivitiesData = refActivitiesData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refActivitiesData = refActivitiesData
                .limit(pageLimit)
        }

        window.unsubdashboardactivitylist = refActivitiesData
            .onSnapshot(onCollectionUpdate);
    }, [checkDataLoad, activeAcitivtyTag])

    useEffect(() => { //new
        if (!checkDataLoad[activeAcitivtyTag] || (_.isEmpty(props.dashboardFilter) && _.isEmpty(props?.dealersettings?.accessSalesType))) {
            return;
        }
        const fetchActivities = async () => {
            let _filter = {
                clientID: props.dealersettings.client.id,
                timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess()
            }
            if (props?.dashboardFilter?.pipelines?.length > 0) {
                _filter.pipeline = props.dashboardFilter.pipelines.join(',')
            } else if (props?.pipeline) {
                if (inclInboundLeadsinRpt)
                    _filter.pipeline = props.pipeline.filter(item => !Boolean(item.disableReport)).map(item => item.value).join(',')
                else
                    _filter.pipeline = props.pipeline.filter(item => item.value !== 'LeadsBucket' && !Boolean(item.disableReport)).map(item => item.value).join(',')
            }
            if (props?.dashboardFilter?.user?.length > 0) {
                _filter.owner = props.dashboardFilter.user.join(',')
            }
            if (ownerDashboard) {
                _filter.owner = localStorage.uid
            }
            if (props?.dashboardFilter?.makes?.length > 0) {
                _filter.enqMake = props.dashboardFilter.makes.join(',')
            }
            //if (accessToFinanceActivity)
            _filter.type = props?.activityType || "activity";


            if (props?.dashboardFilter?.salesType?.length > 0) {
                _filter.enqSaletype = props.dashboardFilter.salesType.join(',')
            } else if (_.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) {
                _filter.enqSaletype = props.dealersettings.accessSalesType.join(',');
            }

            let excludeOrigins = Object.assign([], props.dealersettings?.rolePermissions?.excludeOrigins)
            if (!_.isEmpty(excludeOrigins))
                _filter.origin = CommonHelper.getDataExcluded(props.dealersettings.client?.settings?.origins, excludeOrigins, true, true, true);

            _filter.dateType = 'startDate'
            _filter.isDone = 'false'
            _filter.searchType = activeAcitivtyTag.toLowerCase();

            let _searchObject = {
                "type": "searchActivities",
                "filters": _filter,
                "sortOrder": `startDate asc`,
                "pageNum": pageNum[activeAcitivtyTag],
                pageLimit,
            }
            _searchObject.clientID = props.dealersettings.client ? props.dealersettings.client.id : '';;

            setPagingloader(true)
            const getData = window.firebase.functions().httpsCallable('generic-searchData');
            const resp = await getData(_searchObject);
            setDataload({
                ...checkDataLoad,
                [activeAcitivtyTag]: false
            })
            setLoadingActivity(false)
            setPagingloader(false)
            if (resp.data.success) {

                let activities_ = resp.data.data
                activities_.forEach(rec => {
                    rec.startDate = rec.startDate && rec.startDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.startDate._seconds)._d) : (rec.startDate ? rec.startDate : null);
                    rec.endDate = rec.endDate && rec.endDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.endDate._seconds)._d) : (rec.endDate ? rec.endDate : null);
                    rec.addedDate = rec.addedDate && rec.addedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.addedDate._seconds)._d) : (rec.addedDate ? rec.addedDate : null);
                    rec.modifiedDate = rec.modifiedDate && rec.modifiedDate._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.modifiedDate._seconds)._d) : (rec.modifiedDate ? rec.modifiedDate : null);
                })

                //let activities_ = resp.data.data.map(a => CommonHelper.convertActivityLogVM(a, { ...props }))
                if (activities_.length > 0 && activities_.length === pageLimit) {
                    setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag),
                    {
                        type: activeAcitivtyTag,
                        lastDoc: activities_[activities_.length - 1]
                    }])
                }
                else {
                    setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag)]);
                }
                if (activities_.length >= pageLimit)
                    setPageNum({
                        ...pageNum,
                        [activeAcitivtyTag]: pageNum[activeAcitivtyTag] + 1
                    })

                dispatch({
                    type: "ACTIVITIES_APPEND_LIST",
                    data: activities_,
                    activetab: activeAcitivtyTag
                });
                window.unsubdashboardactivitylist && window.unsubdashboardactivitylist();
            }
        }
        fetchActivities();
    }, [checkDataLoad, activeAcitivtyTag])

    const onCollectionUpdate = async (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = {
                    ...change.doc.data(),
                    documentID: change.doc.id
                };
                if (change.type === 'added') {
                    actionType = "ADDNEWLOG";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_ACTIVITY"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVELOG"
                }
            }
        })
        const _activities = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag),
                {
                    type: activeAcitivtyTag,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]
                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeAcitivtyTag)]);
            }
            querySnapshot.forEach(function (doc) {
                const objlogData = Object.assign({}, doc.data());

                objlogData.documentID = doc.id;
                let logVM = Object.assign({}, objActivitylog);
                for (let [key, value] of Object.entries(objlogData)) {
                    logVM[key] = value;
                }
                logVM.contact = !_.isEmpty(objlogData?.contact) ? objlogData.contact : objlogData?.enquiry?.contact;
                _activities.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : _activities,
            activeTab: activeAcitivtyTag
        });
        setDataload({
            ...checkDataLoad,
            [activeAcitivtyTag]: false
        })
        setLoadingActivity(false)
        setPagingloader(false)
    }


    const handleAddActivity = () => {
        setSelectedActivitylog(null)
        setEnquiryObj(null)
        setContactObj(null)
        setStockObj(null)
        setNewActivity(true)
        setActivityHeader('Add New Activity')
        setShowActivityModel(true)
    }

    const handleEditactivity = useCallback(async (e, logVM) => {
        e && e.preventDefault();
        if (logVM.stockID) {
            if (!_.isEmpty(logVM.stock)) {
                setStockObj(logVM.stock)
            }
            else {
                const stockDoc = await window.firebase.firestore().doc('stock/' + logVM.stockID).get();
                if (stockDoc.exists) {
                    const stockData = stockDoc.data()
                    stockData.documentID = stockDoc.id;
                    setStockObj(stockData)
                }
            }
        }
        let _contactID = logVM.contactID ? logVM.contactID : logVM?.contact?.documentID;
        if (_contactID) {
            //if (!_.isEmpty(logVM.contact)) {
            //    setContactObj(logVM.contact);
            //} else {
            const contactDoc = await firestoreDB(props.dealersettings)
                .firestore()
                .doc('contactDetails/' + _contactID)
                .get();
            if (contactDoc.exists) {
                const contactData = contactDoc.data();
                contactData.documentID = contactDoc.id;
                setContactObj(contactData);
            }
            //}
        }
        if (logVM.enquiryID) {
            // if (!_.isEmpty(logVM.enquiry)) {
            //     setEnquiryObj(logVM.enquiry);
            // } else {
            const enquiryDoc = await firestoreDB(props.dealersettings).firestore().doc('enquiries/' + logVM.enquiryID)
                .get();
            if (enquiryDoc.exists) {
                const enquiryData = enquiryDoc.data();
                enquiryData.documentID = enquiryDoc.id;
                setEnquiryObj(enquiryData);
            }
            //}
        }
        setSelectedActivitylog(logVM)
        setNewActivity(false)
        setActivityHeader('Edit Activity')
        setShowActivityModel(true)
    }, [])

    const handleCompleteContinue = (isDone, objLog, activityOutcome) => {
        // check mandActivityNotes
        if (CommonHelper.isValidActivityNotesCheck(objLog, isDone, props.dealersettings) === false) {
            handleEditactivity(null, { ...objLog, 'isDone': isDone, 'isFromComplete': true });
            return;
        }

        delete objLog.searchUsers;
        objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
        objLog.modifiedBy = localStorage.uid;
        objLog.isDone = isDone
        if (activityOutcome) {
            objLog.activityOutcome = activityOutcome
        }
        setSectionLoading(true)
        firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`)
            .set(objLog, { merge: true })
            .then(snapshot => {
                fetchCounts();
                //props.reloadData && props.reloadData()
                // dispatch({
                //     type: "SAVELOG",
                //     data: objLog
                // });
                toast.notify('Log updated successfully', {
                    duration: 2000
                })
                setShowActivity(false)
                //setActivetab('appointment')
                //setActiveactivity('Completed')
                setEditable(false)

                setSectionLoading(false)
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                //update enquiry modified date if activity is mark as done
                if (!_.isEmpty(objLog.enquiryID) && objLog.isDone === true) {
                    let objUpdate = {
                        modifiedBy: localStorage.uid,
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        unactionedNotify: enqUnActionStatus.COMPLETED,
                    }
                    // if (objLog.inbound === true) {
                    //     objUpdate = {
                    //         ...objUpdate,
                    //         bdcStatus: 'show'
                    //     }
                    // }
                    firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                        .set(objUpdate, { merge: true })
                }
                if (objLog.isDone === true) {
                    //props.reloadData && props.reloadData();
                    if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                        Swal.fire({
                            title: CommonHelper.showLocale(props, 'Please select'),
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                            cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                                .set({
                                    bdcStatus: result.value ? 'show' : 'noshow'
                                }, { merge: true })
                            shedulenextactivity(objLog);
                            if (result.value)
                                logWalkin(objLog.enquiryID)

                            CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                        });
                    }
                    else {
                        shedulenextactivity(objLog);
                    }
                }
            })
            .catch(error => {
                console.error(error);
                setSectionLoading(false)
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    const handleComplete = useCallback((e, objLog, aTab) => {
        const isDone = e.target.checked
        const { dealersettings } = props
        if (
            dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings &&
            !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
            dealersettings.client.settings.activityOutcome.filter(item => item.active === true).length &&
            !objLog.isDone && isDone &&
            objLog.subType && objLog.subType === 'call'
        ) {
            const isMandatory = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.mandActivityOutcome) ? true : false);
            const activtyOutcomes = [];
            _.orderBy(
                dealersettings.client.settings.activityOutcome.filter(
                    item => item.active === true,
                ),
                ['name'],
                ['asc'],
            ).forEach((data, index) => {
                activtyOutcomes.push({
                    value: data.value,
                    name: data.name,
                    label: data.name,
                    price: data.price,
                });
            });
            // console.log('activtyOutcomes', activtyOutcomes)
            // console.log('isMandatory', isMandatory)
            setShowOutcomeModal({
                show: true,
                clsActive: 'overlay-modal active',
                isMandatory,
                activtyOutcomes,
                data: { isDone, objLog }
            });
        } else {
            handleCompleteContinue(isDone, objLog);
        }
    }, [])

    const handleCloseOutcomeModal = (isMandatory, selectedStatus, data) => {
        //console.log('handleCloseOutcomeModal', isMandatory, selectedStatus, data)
        if (isMandatory) {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                setShowOutcomeModal({
                    show: false,
                    clsActive: '',
                    isMandatory: false,
                    activtyOutcomes: null,
                    data: {}
                });
                var checkboxDOM = document.getElementsByClassName('uncheck-activity');
                for (let i = 0; i < checkboxDOM.length; i++) {
                    checkboxDOM[i].checked = false;
                }
                return
            }
        } else {
            if (selectedStatus) {
                handleCompleteContinue(data.isDone, data.objLog, selectedStatus)
            } else {
                handleCompleteContinue(data.isDone, data.objLog,)
            }
        }
        setShowOutcomeModal({
            show: false,
            clsActive: '',
            isMandatory: false,
            activtyOutcomes: null
        });
    }


    const logWalkin = (enquiryID) => {
        const objData = Object.assign({}, objWalkIn);
        objData.documentID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        objData.addedBy = localStorage.uid;
        objData.addedDate = window.firebase.firestore.Timestamp.now();
        objData.addedFrom = 'web';
        objData.modifiedBy = localStorage.uid;
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();
        objData.modifiedFrom = 'web';
        objData.clientID = props.dealersettings.client.id;
        objData.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        objData.enquiryID = enquiryID;
        objData.walkinDate = window.firebase.firestore.Timestamp.now();

        const refOnjData = firestoreDB(props.dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
        refOnjData.set(objData);
        CommonHelper.logFirstWalkin(enquiryID, objData.walkinDate, props.dealersettings);
    }

    const shedulenextactivity = async (objLog) => {
        if (props.dealersettings && props.dealersettings.client && props.dealersettings.client.clientSettings && props.dealersettings.client.clientSettings.isScheduleNextActivity) {
            let _isAlreadyScheduled = false;
            if (objLog.enquiryID || objLog?.enquiry?.documentID) {
                const responseMKA = await firestoreDB(props.dealersettings).firestore().collection('activities')
                    .where('enquiryID', '==', (objLog.enquiryID ? objLog.enquiryID : objLog?.enquiry?.documentID))
                    .where('isDeleted', '==', false)
                    .where('type', '==', 'activity')
                    .where('isDone', '==', false)
                    .limit(1)
                    .get();
                if (responseMKA.docs.length > 0) _isAlreadyScheduled = true;
            }
            if (_isAlreadyScheduled) return;

            const mandScheduleNextActivity = CommonHelper.mandScheduleNextActivity(props.dealersettings, objLog?.enquiry);

            Swal.fire({
                title: CommonHelper.showLocale(props, 'Schedule next activity?'),
                text: CommonHelper.showLocale(props, 'Do you want to schedule next activity?'),
                icon: 'info',
                showCancelButton: mandScheduleNextActivity ? false : true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.value) {
                    setShowScheduleActivityModel({
                        activityLog: {
                            ...objLog,
                            inbound: null
                        },
                        show: true,
                        contact: objLog.contact ? objLog.contact : null,
                        enquiry: objLog.enquiry ? objLog.enquiry : null,
                    })
                }
            })
        }
    }

    const handleScheduledActivityModelClose = () => {
        setShowScheduleActivityModel({
            show: false,
            activityLog: null,
            contact: null,
            enquiry: null,
        })
        fetchCounts();
        // setTimeout(() => {
        //     props.reloadData && props.reloadData();
        // }, 2000);
    }

    const handleDeleteactivity = useCallback((e, objLog) => {

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'You want to delete?'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                delete objLog.searchUsers;
                objLog.modifiedDate = window.firebase.firestore.Timestamp.now();
                objLog.modifiedBy = localStorage.uid;
                objLog.isDeleted = true;
                //setSectionLoading(true)
                firestoreDB(props.dealersettings).firestore().doc(`activities/${objLog.documentID}`).set({
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedBy: localStorage.uid,
                    modifiedFrom: 'web',
                    isDeleted: true
                }, { merge: true })
                    .then(snapshot => {
                        // dispatch({
                        //     type: "REMOVELOG",
                        //     data: objLog
                        // });
                        toast.notify('Log deleted successfully', {
                            duration: 2000
                        })
                        // setTimeout(function () {
                        //     setSectionLoading(false)
                        // }, 1000);

                        let logNotes = 'Log deleted';
                        if (!_.isEmpty(objLog) && objLog.type)
                            logNotes = objLog.type + ': ' + (objLog.subType ? objLog.subType : '') + ' deleted';

                        let _objLogData = {
                            notes: logNotes,
                            type: 'activities',
                            subType: 'delete',
                            recordId: objLog.documentID,
                        }
                        _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
                        CommonHelper.saveAuditLog(_objLogData);

                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }, [])

    const handleActivityModelDelete = (objLog) => {
        setStockObj(null)
        setSelectedActivitylog(null)
        setShowActivityModel(false)
        fetchCounts();
    }

    const handleActivityModelClose = (objLog, isActivtyAlreadyDone) => {
        setShowActivityModel(false)
        setStockObj(null)
        setEnquiryObj(null)
        setContactObj(null)
        setSelectedActivitylog(null)
        // if (objLog && (!_.isEmpty(props.dashboardFilter) || !_.isEmpty(props?.dealersettings?.accessSalesType))) {
        //     dispatch({
        //         type: "CHECKLOG",
        //         data: objLog,
        //         activeTab: objLog.isDone ? 'Completed' :
        //             objLog.startDate.seconds < parseInt(moment().startOf('day').format('X')) ? 'Overdue' :
        //                 objLog.startDate.seconds > parseInt(moment().endOf('day').format('X')) ? 'Upcoming' :
        //                     'Today'
        //     });
        // }
        if (objLog) {
            fetchCounts();
            if (objLog.isDone === true && !isActivtyAlreadyDone) {
                if ((objLog.inbound === true || objLog.subType === 'appointment' || objLog.subType === 'testdrive') && !_.isEmpty(objLog.enquiryID) && enableCustShowNoShow) {
                    Swal.fire({
                        title: CommonHelper.showLocale(props, 'Please select'),
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: CommonHelper.showLocale(props, 'Customer Show'),
                        cancelButtonText: CommonHelper.showLocale(props, 'Customer No Show'),
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        firestoreDB(props.dealersettings).firestore().doc(`enquiries/${objLog.enquiryID}`)
                            .set({
                                bdcStatus: result.value ? 'show' : 'noshow'
                            }, { merge: true })
                        shedulenextactivity(objLog);
                        if (result.value)
                            logWalkin(objLog.enquiryID)

                        CommonHelper.saveActivityLog(objLog, (result.value ? 'Customer Show' : 'Customer No Show'), 'log', 'note', null, props.dealersettings);
                    });
                }
                else {
                    shedulenextactivity(objLog);
                }
            }
        }
        // setTimeout(() => {
        //     props.reloadData && props.reloadData();
        // }, 2000);
    }

    return (
        <>
            <div className="dashboard-box">
                {
                    activityCountsLoader ? (<div className="dashboard-widget-loader h-100">
                        <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                        </div>
                    </div>) : (<></>)
                }
                <div className="dashboard-box-header draggable-section">
                    <div className="dashboard-toolbox">
                        <a href="#" className="mini-button mr-1" onClick={(e) => { e.preventDefault(); setActivityCountsLoader(true); fetchCounts(); }}> <i className="ico icon-refresh"></i></a>
                        <a href="#" className="mini-button" onClick={(e) => {
                            e.preventDefault();
                            handleAddActivity();
                        }}> <i className="ico icon-plus"></i></a>
                        {
                            props.fromOEM || props.hideRemoveBtn ? (<></>) : (
                                <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); props.removeWidget() }}> <i className="ico icon-remove"></i></a>
                            )
                        }
                        {/* <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); setFilterPopOver({ show: true, target: e.target }); e.preventDefault(); }}> <i className="ico icon-filter"></i></a>
                        <a href="#" className="mini-button ml-1" onClick={(e) => { e.preventDefault(); }}> <i className="ico icon-more"></i></a>*/} </div>
                    <h3><Translate text={`${props?.titlePrefix || ''} Activities`} /></h3>
                </div>
                <div className="dashboard-box-body" style={{ height: 'calc(100% - 60px)' }}>
                    <div className="dashboard-activities-wrap" style={{ minHeight: `350px`, height: 'calc(100% - 0px)' }}>
                        {
                            loadingActivity ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (
                                    <>
                                        <div className="dashboard-activities-nav pl-0">
                                            <ul>
                                                {
                                                    acitivtyTags.map((rec, index) => {
                                                        return <li key={index}>
                                                            <a href="#"
                                                                id={rec}
                                                                className={`nav-li-${rec} ${activeAcitivtyTag === rec ? 'active' : ''}`}
                                                                onClick={(event) => {
                                                                    if (event.target.id) {
                                                                        setActiveactivity(event.target.id)
                                                                    }
                                                                    event.preventDefault();
                                                                }}
                                                            >{<Translate text={rec.toLocaleLowerCase() === 'upcoming' ? 'tomorrow' : rec.toLocaleLowerCase()} />}
                                                                {!_.isEmpty(activityCounts) ?
                                                                    rec === 'Overdue' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.overdue ? activityCounts.overdue : '0'}</span>)
                                                                        : rec === 'Today' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.today ? activityCounts.today : '0'}</span>)
                                                                            : rec === 'Upcoming' ? (<span className="badge badge-pill badge-widget-counter ml-1">{activityCounts.upcoming ? activityCounts.upcoming > 99 ? '99+' : activityCounts.upcoming : '0'}</span>)
                                                                                : (<></>)
                                                                    : (<></>)
                                                                }
                                                            </a>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            activitylogs[activeAcitivtyTag].length > 0 ? (
                                                <>
                                                    <div className="dashboard-activity-item" style={{ height: 'calc(100% - 90px)' }}>
                                                        {
                                                            activitylogs[activeAcitivtyTag].map((log, index) => {
                                                                return <ActivityView
                                                                    key={index}
                                                                    title={log.title}
                                                                    activityDate={log.startDate}
                                                                    id={log.documentID}
                                                                    type={log.subType}
                                                                    addedBy={CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), log.owner, log.addedByName)}
                                                                    handleEditactivity={handleEditactivity}
                                                                    handleComplete={handleComplete}
                                                                    completed={log.isDone}
                                                                    activitytag={activeAcitivtyTag}
                                                                    dealersettings={props.dealersettings}
                                                                    handleDeleteactivity={handleDeleteactivity}
                                                                    activitylog={log}
                                                                />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            ) : (<></>)
                                        }
                                        {
                                            (!pagingLoader && activitylogs[activeAcitivtyTag].length == 0) ? (<><div className="dashboard-activity-item" >
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> <Translate text={'No activities'} /> </div>
                                                </div>
                                            </div></>) : (<></>)
                                        }
                                        {
                                            pagingLoader ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                                hasMore.filter(e => e.type === activeAcitivtyTag).length > 0 ?
                                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        setDataload({
                                                            ...checkDataLoad,
                                                            [activeAcitivtyTag]: true
                                                        })
                                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                                    : (<></>)
                                        }
                                    </>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showActivityModel}>
                <AddActivity
                    show={showActivityModel}
                    handleClose={handleActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={activityHeader}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={selectedActivitylog}
                    enquiry={enquiryObj}
                    contact={contactObj}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={isNewActivity}
                    originType={props.originType}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showScheduleActivityModel.show}>
                <AddActivity
                    show={showScheduleActivityModel.show}
                    handleClose={handleScheduledActivityModelClose}
                    handleDelete={handleActivityModelDelete}
                    title={'Add New Activity'}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    activityLog={showScheduleActivityModel.activityLog}
                    enquiry={enquiryObj ? enquiryObj : showScheduleActivityModel.enquiry ? showScheduleActivityModel.enquiry : null}
                    contact={contactObj ? contactObj : showScheduleActivityModel.contact ? showScheduleActivityModel.contact : null}
                    stock={stockObj}
                    clsActive='overlay-modal active'
                    isNewActivity={true}
                    isScheduleActivity={true}
                    originType={props.originType}
                // activeTab={activeTab}
                >
                </AddActivity>
            </PopUpModal>
            <PopUpModal show={showOutcomeModal.show}>
                <ActivityStatus
                    show={showOutcomeModal.show}
                    isMandatory={showOutcomeModal.isMandatory}
                    activtyOutcomes={showOutcomeModal.activtyOutcomes}
                    data={showOutcomeModal.data}
                    handleClose={handleCloseOutcomeModal}
                    title={'Select Activity Outcome'}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
            {/* <PopoverwithFooter
                showpopover={filterPopOver.show}
                targetpopover={filterPopOver.target}
                title='Filter'
                position='left'
                className='popover-dashboard-filter'
                closepopover={() => {
                    setFilterPopOver({ show: false, target: '' })
                }}>
                <FilterPanel
                    userOptions={userOptions}
                    selectedUser={selectedUser}
                    viewOtherEnquiry={viewOtherActivity}
                    searchText={searchText}
                    showSearchText={false}
                    setUser={(e) => {
                        if (e) {
                            setUser(e)
                        }
                        else {
                            setUser(null)
                        }
                    }}
                    setSearchText={(e) => {
                        setSearchText(e.target.value)
                    }}
                    applyFilter={() => {
                        let dataload = { "Overdue": true, "Today": true, "Upcoming": true }
                        setFilterPopOver({ show: false, target: '' })
                        setHasMoreData([])
                        dispatch({
                            type: "CLEAR_ALL"
                        });
                        setDataload({
                            ...dataload,
                            [activeAcitivtyTag]: true
                        })
                    }}
                    clearFilter={() => {
                        setFilterPopOver({ show: false, target: '' })
                    }}
                />
            </PopoverwithFooter> */}
        </>
    );
}

export default ActivitiesCount;