/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import _ from 'lodash';
import toast from 'toasted-notes';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, PopoverListLoader } from '../../../components';
import { default as _images } from "../../../images";
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';

//import AddNotes from './add';

class OfferLogs extends Component {
    constructor(props) {
        super(props);
        //this.ref = window.firebase.firestore().collection('tradeinPro');
        this.unsubscribe = null;
        this.state = {
            notesHistory: [],
            filter: {
                iSearch: '',
                myContact: false
            },
            loading: true,
            contactModal:
            {
                showContact: false,
                showContactSearch: false,
                contactTitle: '',
                clsContactActive: ''
            },
            showNotes: false,
        };
    }



    onNotesCollectionUpdate = (querySnapshot) => {
        const contacts = [];
        querySnapshot.forEach((doc) => {

            let contact = Object.assign({}, doc.data());

            contact.documentID = doc.id;
            if (!contact.isDeleted) contacts.push(contact);
        });

        this.setState({
            notesHistory: contacts,
            loading: false
        });
    }

    componentDidMount() {
        const { tradeinProID, offerID, userID, dealersettings } = this.props;
        if (tradeinProID && offerID) {

            let refEnquiryData = firestoreDB(dealersettings).firestore().collection(`tradeinPro/${tradeinProID}/offerLogs`);

            if (userID)
                refEnquiryData = refEnquiryData.where('addedBy', '==', userID)

            this.unsubscribe = refEnquiryData
                .where('offerID', '==', offerID)
                .onSnapshot(this.onNotesCollectionUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }


    handleNotesOpen = () => {
        this.setState({ showNotes: true });
    }

    handleNotesClose = (noteTxt, _mentions, _isInternal) => {

        if (noteTxt) {
            const { tradeinProID, offerID, dealersettings } = this.props;
            let convertedIDs = []
            if (!_.isEmpty(_mentions)) {
                let rawIDs = _mentions.map(mentionObject => mentionObject.id)
                rawIDs.forEach(id => {
                    if (id.includes(',')) {
                        let allIDs = id.split(',')
                        allIDs.forEach(a => {
                            convertedIDs.push(a)
                        })
                    } else {
                        convertedIDs.push(id)
                    }
                })
            }

            let _objNote =
            {
                documentID: firestoreDB(dealersettings).firestore().collection('tradeinPro').doc().id,
                projectId: dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null,
                notes: noteTxt,
                mentions: Object.assign([], convertedIDs),
                addedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                offerID,
                tradeinProID,
                isDeleted: false,
            }

            if (_isInternal)
                _objNote.isInternalNotes = true;

            const updateRef = firestoreDB(dealersettings).firestore().doc(`tradeinPro/${tradeinProID}/OfferLogs/${_objNote.documentID}`);
            updateRef.set(_objNote, { merge: true }).then((docRef) => {

                toast.notify('notes added successfully', {
                    duration: 2000
                })

            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });

        }

        this.setState({ showNotes: false });
    }


    render() {

        const { notesHistory, loading, showNotes } = this.state
        const { show, groupUsers, dealersettings, clientUsers } = this.props;

        let _allUser = _.union((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), Object.assign([], dealersettings.wholesalers));


        const divStyle = {
            height: '400px',
            overflow: 'auto',
            position: "relative"
        };

        return (
            <>
                <Modal
                    id="stockList"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-log-activity">

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" ><Translate text={'Offer Logs'} /></h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">
                                    <div className="quickview-files-wraper" style={divStyle}>
                                        <>

                                            {/* <div className="quickview-requirement-section">
                                                <div className="add-files-button">
                                                    <a href="#" className="mini-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleNotesOpen();
                                                    }}>
                                                        <i className="ico icon-add"></i>
                                                    </a>
                                                </div>
                                                <h3><Translate text={'Notes'} /></h3>
                                            </div> */}

                                            {!_.isEmpty(notesHistory)
                                                ?
                                                <>

                                                    <div className={`file-manager-control`}>
                                                        {
                                                            _.orderBy(notesHistory, ['addedDate'], ['desc']).map((noteData, index) => {
                                                                return <div key={index} className="notes-item text-area-space ">
                                                                    {noteData.message}
                                                                    <div className="notes-item-sub">

                                                                        <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                                        &nbsp;{' - '}&nbsp;{CommonHelper.getUserNamebyId(_allUser, noteData.addedBy)}

                                                                        {noteData.addedFrom ?
                                                                            <>
                                                                                &nbsp;{` - `}&nbsp;
                                                                                <i className={`ico icon-${noteData.addedFrom === 'web' ? 'monitor' : noteData.addedFrom} mr-1`}></i>
                                                                                {noteData.addedFrom} </> : <></>}
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="spinner-loader h-100">
                                                        <div className="no-cafe-flex justify-content-center align-items-center h-100">
                                                            <div className="no-cafe-img">
                                                                <i className="ico icon-service-log"></i> </div>
                                                        </div>
                                                    </div>
                                                </>}
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* <PopUpModal show={showNotes}>
                    <AddNotes
                        show={showNotes}
                        //serviceID={this.state.serviceID}
                        handleClose={this.handleNotesClose}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                    />
                </PopUpModal> */}
            </>

        );
    }

}

export default OfferLogs;