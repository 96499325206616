/** LIBRARIES */
import React, { Component } from 'react'
import Swal from 'sweetalert2';
import _ from 'lodash';
/** COMPONENTS */
import { Routes } from '../../constants/routes';
import CommonHelper from '../../services/common';

export const RouteContext = React.createContext()

export const RouteConsumer = RouteContext.Consumer

let userModules = ['oem', 'wholesaler', 'tradeinPro', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro']
let modulesList = ['service', 'tradeinPro', 'wholesaler', 'afterSales', 'reports', 'eventsPro', 'oemeventsPro', 'fleetPro', 'oemfleetPro']

const modulesListVM = [
    { active: true, value: 'service', name: 'Services' },
    { active: true, value: 'tradeinPro', name: 'Appraisals' },
    { active: true, value: 'wholesaler', name: 'Appraisals' },
    { active: true, value: 'afterSales', name: 'Enquiries' },
    { active: true, value: 'reports', name: 'Enquiries' },
    { active: true, value: 'eventsPro', name: 'Events' },
    { active: true, value: 'oemeventsPro', name: 'Events' },
    { active: true, value: 'fleetPro', name: 'Fleets' },
    { active: true, value: 'oemfleetPro', name: 'Fleets' },
]

export class RouteProvider extends Component {

    constructor(props) {
        super(props);
        this.refEnquiry = window.firebase.firestore().collection('enquiries');
        this.state = {
            routes: Routes,
            clientid: '',
            routeLoading: false
        };

    }

    handleRouteChange = (val, _module) => {
        //16-02-2021
        // if (val.clientid !== this.state.clientid) {
        //     return this.props.history.push('/dashboard');
        // }
        const newMenu = [];
        var _dmenu = [];
        let _ID = _.includes(userModules, localStorage.defaultModule) ? localStorage.uid : val.clientid;
        const { dealersettings } = this.props;
        if (localStorage[_ID]) {
            _dmenu = _.uniqBy(JSON.parse(localStorage[_ID]), 'id');
        }
        try {
            var enqLimit = 20;
            if (dealersettings?.group?.enableSharedContact && localStorage.defaultModule !== 'oem') {
                enqLimit = 10;
            }
            if (_dmenu.length > enqLimit) {
                _dmenu = _.drop(_dmenu, (_dmenu.length - enqLimit));
            }
        } catch (err) { _dmenu = []; }

        _dmenu.forEach(function (rec) {
            if (rec.id !== val.id)
                rec.active = false;
            else
                rec.active = true;
        });

        _dmenu.map((item) => {
            if (item.id === val.id) {
                item.displayID = val.displayID ? val.displayID : item.displayID;
                item.status = val.status ? val.status : item.status;
                item.name = val.name ? val.name : item.name;
                return item;
            }
            return item;
        })

        if (_dmenu.some(item => item.id === val.id) === false) {
            _dmenu.push(val);
        }
        localStorage[_ID] = JSON.stringify(_dmenu);
        if (dealersettings?.group?.enableSharedContact && !_.includes(userModules, localStorage.defaultModule)) {
            dealersettings.group.client_ids
                && dealersettings.group.client_ids.forEach(cid => {
                    if (cid !== val.clientid && localStorage[cid]) {
                        _dmenu = [...JSON.parse(localStorage[cid]).map(rec => {
                            return {
                                ...rec,
                                'active': false
                            }
                        }), ..._dmenu]
                    }
                })
        }

        if (_.includes(modulesList, localStorage.defaultModule)) {
            let moduleName = (localStorage.defaultModule === 'reports' ? 'enquiry' :
                localStorage.defaultModule === 'oemeventsPro' ? 'eventsPro' :
                    localStorage.defaultModule === 'oemfleetPro' ? 'fleetPro' :
                        localStorage.defaultModule)
            newMenu.push({
                name: CommonHelper.getNameByValue(modulesListVM, localStorage.defaultModule),
                module: localStorage.defaultModule,
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => m.module === moduleName))
            });
        }
        else {
            newMenu.push({
                name: "Enquiries",
                module: (localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'frontdesk') ? localStorage.defaultModule : "enquiry",
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => !_.includes(modulesList, m.module)))
            });
        }

        this.setState({ routes: [...Routes, ...newMenu] })

    }

    updatenavbar = (val, _module) => {
        const { clientid } = this.state
        const newMenu = [];
        var _dmenu = [];
        let _ID = _.includes(userModules, localStorage.defaultModule) ? localStorage.uid : clientid;
        if (localStorage[_ID]) {
            _dmenu = _.uniqBy(JSON.parse(localStorage[_ID]), 'id');
        }
        //_dmenu = [..._dmenu.filter(item=>item.id !== val.id),val]
        _dmenu.map((item) => {
            if (item.id === val.id) {
                item.displayID = val.displayID ? val.displayID : item.displayID;
                item.status = val.status ? val.status : item.status;
                item.name = val.name ? val.name : item.name;
                item.isNew = (val.isNew === null ? val.isNew : item.isNew);
                item.params = (val.params === null ? val.params : item.params);

                return item;
            }
            return item;
        })
        localStorage[_ID] = JSON.stringify(_dmenu);
        if (_.includes(modulesList, localStorage.defaultModule)) {
            let moduleName = (localStorage.defaultModule === 'reports' ? 'enquiry' :
                localStorage.defaultModule === 'oemeventsPro' ? 'eventsPro' :
                    localStorage.defaultModule === 'oemfleetPro' ? 'fleetPro' :
                        localStorage.defaultModule)
            newMenu.push({
                name: CommonHelper.getNameByValue(modulesListVM, localStorage.defaultModule),
                module: localStorage.defaultModule,
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => m.module === moduleName))
            });
        }
        else {
            newMenu.push({
                name: "Enquiries",
                module: (localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'frontdesk') ? localStorage.defaultModule : "enquiry",
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => !_.includes(modulesList, m.module)))
            });
        }

        this.setState({ routes: [...Routes, ...newMenu] })
    }

    handleRouteRemove = (val, _module) => {
        this.handleRemoveEnquiry(val, null, _module);
        // if (val.isNew) {
        //     Swal.fire({
        //         title: 'Are you sure?',
        //         text: `You want to cancel ${val.isNew ? val.isNew : 'enquiry'}?`,
        //         icon: 'info',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes',
        //         cancelButtonText: 'No',
        //     }).then(result => {
        //         if (result.value) {
        //             const updateRef = this.refEnquiry.doc(val.id);
        //             updateRef.update({
        //                 isDeleted: true,
        //                 modifiedBy: this.props.dealersettings ? this.props.dealersettings.id : '',
        //                 modifiedFrom: 'web',
        //                 modifiedDate: window.firebase.firestore.Timestamp.now()
        //             }).then((docRef) => {
        //                 this.handleRemoveEnquiry(val);
        //             }).catch((error) => {
        //                 console.error("Error updating enquiries: ", error);
        //             });
        //         }
        //     });
        // }
        // else {
        //     this.handleRemoveEnquiry(val);
        // }
    }

    handleRouteRemoveAll = (enquiries, _module) => {
        if (!_.isEmpty(enquiries)) {
            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: CommonHelper.showLocale(this.props, `You want to clear all.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    enquiries && enquiries.forEach((val) => {
                        this.handleRemoveEnquiry(val, 'all', _module);
                    });
                }
            });
        }
    }

    handleRemoveEnquiry = (val, clearAll, _module) => {

        const newMenu = [];
        const { dealersettings } = this.props;
        let _ID = _.includes(userModules, localStorage.defaultModule) ? localStorage.uid : val.clientid;
        var _dmenu = JSON.parse(localStorage[_ID]);
        _dmenu = _dmenu.filter(x => x.id !== val.id);
        localStorage[_ID] = JSON.stringify(_dmenu);
        if (dealersettings?.group?.enableSharedContact && !_.includes(userModules, localStorage.defaultModule)) {
            dealersettings.group.client_ids
                && dealersettings.group.client_ids.forEach(cid => {
                    if (cid !== val.clientid && localStorage[cid]) {
                        _dmenu = [...JSON.parse(localStorage[cid]), ..._dmenu]
                    }
                })
        }
        if (_.includes(modulesList, localStorage.defaultModule)) {
            let moduleName = (localStorage.defaultModule === 'reports' ? 'enquiry' :
                localStorage.defaultModule === 'oemeventsPro' ? 'eventsPro' :
                    localStorage.defaultModule === 'oemfleetPro' ? 'fleetPro' :
                        localStorage.defaultModule)
            newMenu.push({
                name: CommonHelper.getNameByValue(modulesListVM, localStorage.defaultModule),
                module: localStorage.defaultModule,
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => m.module === moduleName))
            });
        }
        else {
            newMenu.push({
                name: "Enquiries",
                module: (localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'frontdesk') ? localStorage.defaultModule : "enquiry",
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => !_.includes(modulesList, m.module)))
            });
        }
        this.setState({ routes: [...Routes, ...newMenu] })

        let _nxtData = null;
        if (val.routeID)
            _nxtData = { id: val.routeID }
        else if (localStorage.defaultModule === 'service' || _module === 'service')
            _nxtData = _dmenu.filter(m => m.module === 'service')[0];
        else if (localStorage.defaultModule === 'tradeinPro' || _module === 'tradeinPro')
            _nxtData = _dmenu.filter(m => m.module === 'tradeinPro')[0];
        else if (localStorage.defaultModule === 'eventsPro' || _module === 'eventsPro')
            _nxtData = _dmenu.filter(m => m.module === 'eventsPro')[0];
        else if (localStorage.defaultModule === 'fleetPro' || _module === 'fleetPro')
            _nxtData = _dmenu.filter(m => m.module === 'fleetPro')[0];
        else if (localStorage.defaultModule === 'oemfleetPro' || _module === 'oemfleetPro')
            _nxtData = _dmenu.filter(m => m.module === 'oemfleetPro')[0];
        else if (localStorage.defaultModule === 'oemeventsPro' || _module === 'oemeventsPro')
            _nxtData = _dmenu.filter(m => m.module === 'oemeventsPro')[0];
        else if (localStorage.defaultModule === 'wholesaler' || _module === 'wholesaler')
            _nxtData = _dmenu.filter(m => m.module === 'wholesaler')[0];
        else if (localStorage.defaultModule === 'afterSales' || _module === 'afterSales')
            _nxtData = _dmenu.filter(m => m.module === 'afterSales')[0];
        else if (localStorage.defaultModule === 'reports' || _module === 'reports')
            _nxtData = _dmenu.filter(m => m.module === 'enquiry')[0];
        else
            _nxtData = _dmenu.filter(m => !_.includes(modulesList, m.module))[0];

        if (_nxtData && !clearAll) {
            if ((localStorage.defaultModule === 'service' || _module === 'service'))
                this.props.history.push('/service/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
            else if ((localStorage.defaultModule === 'tradeinPro' || _module === 'tradeinPro'))
                this.props.history.push('/tradeinpro/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
            else if ((_.includes(['eventsPro', 'oemeventsPro'], localStorage.defaultModule) || _module === 'eventsPro'))
                this.props.history.push('/eventspro/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
            else if ((_.includes(['fleetPro', 'oemfleetPro'], localStorage.defaultModule) || _module === 'fleetPro'))
                this.props.history.push('/fleetpro/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
            else if ((localStorage.defaultModule === 'wholesaler' || _module === 'wholesaler'))
                this.props.history.push('/wholesaler/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
            else
                this.props.history.push('/enquiry/details/' + _nxtData.id + (_nxtData.params ? _nxtData.params : ''));
        }
        else {
            if (localStorage.defaultModule === 'service' || _module === 'service')
                this.props.history.push('/service');
            else if (localStorage.defaultModule === 'tradeinPro' || _module === 'tradeinPro')
                this.props.history.push('/tradeinpro');
            else if (_.includes(['eventsPro', 'oemeventsPro'], localStorage.defaultModule) || _module === 'eventsPro')
                this.props.history.push('/eventspro/list');
            else if ((_.includes(['fleetPro', 'oemfleetPro'], localStorage.defaultModule) || _module === 'fleetPro'))
                this.props.history.push('/fleetpro/list');
            else if (localStorage.defaultModule === 'wholesaler' || _module === 'wholesaler')
                this.props.history.push('/wholesaler');
            else if (localStorage.defaultModule === 'afterSales' || _module === 'afterSales')
                this.props.history.push('/aftersales');
            else if (localStorage.defaultModule === 'reports' || _module === 'reports')
                this.props.history.push('/reports');
            else {
                const { dealersettings } = this.props;
                const inboundAllocation = ((!_.isEmpty(dealersettings) &&
                    !_.isEmpty(dealersettings.client) &&
                    !_.isEmpty(dealersettings.client.clientSettings) &&
                    dealersettings.client.clientSettings.inboundAllocation) ? true : false);
                const _userRoles = (dealersettings && dealersettings.roleID &&
                    dealersettings.client && dealersettings.client.roles &&
                    dealersettings.client.roles.filter(e => e.documentID === dealersettings.roleID)[0]);

                const isInboundAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e === 'LeadsBucket') ? true : false;
                const isSalesAccess = _userRoles && _userRoles.pipeline && _userRoles.pipeline.some(e => e !== 'LeadsBucket') ? true : false;

                if (localStorage.defaultModule === 'oem' && inboundAllocation)
                    this.props.history.push('/inbound/allocation');
                else if (localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'frontdesk')
                    this.props.history.push('/inbound');
                else if (isSalesAccess)
                    this.props.history.push('/pipeline');
                else if (isInboundAccess)
                    this.props.history.push('/inbound');
                else
                    this.props.history.push('/dashboard');
            }

        }

    }

    resetnavbar = () => {
        console.log('resetnavbar', localStorage.defaultModule);
        const { clientid } = this.state
        const { dealersettings } = this.props;
        const newMenu = [];
        var _dmenu = [];
        let _ID = _.includes(userModules, localStorage.defaultModule) ? localStorage.uid : clientid;
        if (localStorage[_ID]) {
            _dmenu = _.uniqBy(JSON.parse(localStorage[_ID]), 'id');
        }
        _dmenu.forEach(function (rec) {
            rec.active = false;
        });
        localStorage[_ID] = JSON.stringify(_dmenu);
        if (dealersettings?.group?.enableSharedContact && !_.includes(userModules, localStorage.defaultModule)) {
            dealersettings.group.client_ids
                && dealersettings.group.client_ids.forEach(cid => {
                    if (cid !== clientid && localStorage[cid]) {
                        _dmenu = [...JSON.parse(localStorage[cid]).map(rec => {
                            return {
                                ...rec,
                                'active': false
                            }
                        }), ..._dmenu]
                        localStorage[cid] = JSON.stringify(_dmenu.filter(x => x.clientid === cid));
                    }
                })
        }
        if (_.includes(modulesList, localStorage.defaultModule)) {
            let moduleName = (localStorage.defaultModule === 'reports' ? 'enquiry' :
                localStorage.defaultModule === 'oemeventsPro' ? 'eventsPro' :
                    localStorage.defaultModule === 'oemfleetPro' ? 'fleetPro' :
                        localStorage.defaultModule)
            newMenu.push({
                name: CommonHelper.getNameByValue(modulesListVM, localStorage.defaultModule),
                module: localStorage.defaultModule,
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => m.module === moduleName))
            });
        }
        else {
            newMenu.push({
                name: "Enquiries",
                module: (localStorage.defaultModule === 'oem' || localStorage.defaultModule === 'frontdesk') ? localStorage.defaultModule : "enquiry",
                class: "ico icon-list",
                enquiries: Object.assign([], _dmenu.filter(m => !_.includes(modulesList, m.module)))
            });
        }

        this.setState({ routes: [...Routes, ...newMenu] })
    }

    componentDidMount() {
        this.resetnavbar()
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.routeLoading)
            this.resetnavbar()
    }
    static getDerivedStateFromProps(props, state) {
        if (props.currentClient !== state.clientid) {
            return {
                clientid: props.currentClient,
                routeLoading: true
            }
        }
        else {
            return {
                routeLoading: false
            }
        }
        return null;
    }

    render() {

        const { children } = this.props
        const { routes } = this.state
        return (
            <RouteContext.Provider
                value={{
                    routes: routes,
                    handleRouteChange: this.handleRouteChange,
                    handleRouteRemove: this.handleRouteRemove,
                    resetnavbar: this.resetnavbar,
                    updatenavbar: this.updatenavbar,
                    handleRouteRemoveAll: this.handleRouteRemoveAll
                }}
            >
                {children}
            </RouteContext.Provider>
        )
    }

}