import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'

import { ContentOverlay, PopUpModal, TableView, ReactSelect } from '../../../components'
import Translate from '../../../constants/translate';
import CafeOrder from '../order'
import CafeHistoryReducer from '../history/historyReducer'
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';

import { objCafe, objCafeHistoryVM, allCafeHistoryFields } from '../viewModel'

const CafeHistoryListView = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 175) })
    const [cafeHistory, dispatch] = useReducer(CafeHistoryReducer, { Delivered: [], Cancelled: [] })
    const [checkDataLoad, setDataload] = useState({ "Delivered": true, "Cancelled": true })
    const [hasMore, setHasMoreData] = useState([])
    const [cafeHistoryFields, setCafeHistoryFields] = useState(props.dealersettings.cafeHistoryFields)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelCafeShow, setModelCafeShow] = useState(false)
    const [modelCafe, setModelCafe] = useState({})
    const [isPaging, setPaging] = useState(false)
    const [cafeHistoryLoader, setLoader] = useState({ "Delivered": true, "Cancelled": true })
    const pageLimit = 50
    const [activeTab, setActiveTab] = useState('Delivered')
    const [searchText, setSearchText] = useState(localStorage.cafeHistorySearchText ? localStorage.cafeHistorySearchText : '')

    const [titles, setTitles] = useState([])
    //const [languages, setLanguages] = useState([])
    //const [nationalities, setNationalities] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])

    const [selectedClient, setSelectedClient] = useState('')
    const [clients, setClients] = useState([])

    useEffect(() => {
        const { dealersettings } = props;
        if (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.settings)) {
            setDataload({
                ...checkDataLoad,
                [activeTab]: false
            })
            const setttings = dealersettings.client.settings;

            let _titles = [];
            setttings.titles && setttings.titles.forEach((doc) => {
                _titles.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _languages = [];
            dealersettings.languages && dealersettings.languages.forEach((doc) => {
                _languages.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _nationalities = [];
            dealersettings.nationalities && dealersettings.nationalities.forEach((doc) => {
                _nationalities.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            let _campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _origins = [];
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            let _clients = [];
            dealersettings.clients && dealersettings.clients.forEach(rec => {
                _clients.push({
                    label: rec.name,
                    value: rec.id,
                    data: rec,
                    active: true,
                })
            })

            setClients(_clients);
            setSelectedClient(dealersettings.client.id)
            setCampaigns(_campaigns);
            setOrigins(_origins);
            setTitles(_titles);
            //setLanguages(_languages);
            //setNationalities(_nationalities)
            setDataload({
                ...checkDataLoad,
                [activeTab]: true
            })
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 175)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            //window.unSubCafeHistory.unsubscribe()
            window.unSubCafeHistory && window.unSubCafeHistory();
        }
    }, [])

    useEffect(() => {
        if (!checkDataLoad[activeTab]) {
            return;
        }

        let _clientID = props.dealersettings.client.id
        if (selectedClient)
            _clientID = selectedClient;

        let _status = activeTab.toLowerCase()
        let refCafeHistLstData = firestoreDB(props.dealersettings).firestore().collection('cafeOrders')
            .where('clientID', '==', _clientID)
            .where('status', '==', _status)
            .where('isDeleted', '==', false)
            .orderBy('modifiedDate', 'desc')

        if (searchText && searchText.trim()) {
            refCafeHistLstData = refCafeHistLstData
                .where('keywords', 'array-contains', searchText.trim().toLowerCase())
        }

        let lastRecord = hasMore.filter(e => e.type === activeTab);
        if (lastRecord.length > 0) {
            //console.log('lastRecord', lastRecord)
            refCafeHistLstData = refCafeHistLstData
                .startAfter(lastRecord[0].lastDoc)
                .limit(pageLimit)
        }
        else {
            refCafeHistLstData = refCafeHistLstData
                .limit(pageLimit)
        }

        window.unSubCafeHistory = refCafeHistLstData
            .onSnapshot(onCollectionUpdate);

        // window.unSubCafeHistory = collectionData(refCafeHistLstData)
        //     .pipe(
        //         switchMap(fleetSnapshot => {
        //             return combineLatest(
        //                 of(fleetSnapshot),
        //             )
        //         }),
        //         map(([fleetSnapshot]) => {
        //             return fleetSnapshot.map(snapDoc => {
        //                 return {
        //                     ...snapDoc,
        //                     //stock: stockSnapshot.find(a => a.documentID === snapDoc.stockID),
        //                 }
        //             })
        //         })
        //     )
        //     .subscribe(cafeHistorys => {
        //         onCollectionUpdate(cafeHistorys)
        //     });


    }, [checkDataLoad, activeTab])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = dataMappingVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDNEW_LIST";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATE_LIST"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVE_LIST"
                }
            }
        })

        const _cafeHistorys = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab),
                {
                    type: activeTab,
                    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1]

                }])
            }
            else {
                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
            }
            querySnapshot.forEach((doc) => {
                _cafeHistorys.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }

        if (isPaging) {
            dispatch({
                type: "APPEND_LIST",
                data: _cafeHistorys,
                activetab: activeTab
            });
        }
        else {
            dispatch({
                type: actionType ? actionType : "SUCCESS_LIST",
                data: actionType ? snapshotDoc : _cafeHistorys,
                activetab: activeTab
            });
        }

        setDataload({
            ...checkDataLoad,
            [activeTab]: false
        })
        setLoader({
            ...cafeHistoryLoader,
            [activeTab]: false
        })
        setPaging(false)
    }

    const dataMappingVM = (doc) => {
        const { clientUsers, dealersettings, groupUsers } = props;
        let isMultiDealer = dealersettings && dealersettings.clients && dealersettings.clients.length > 1 ? true : false;
        const objlogData = Object.assign({}, doc);
        const cafeVM = Object.assign({}, objCafeHistoryVM);
        for (let [key, value] of Object.entries(objlogData)) {
            cafeVM[key] = value;
        }
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        cafeVM.orderQuantity = orderQuantity(cafeVM.orders);
        cafeVM.addedby = cafeVM.addedByName ? cafeVM.addedByName : (cafeVM?.staff?.documentID === cafeVM.addedBy ? cafeVM.staff.name : CommonHelper.getUserNamebyId(allUsers, cafeVM.addedBy));
        cafeVM.deliveredby = CommonHelper.getUserNamebyId(allUsers, cafeVM.deliveredBy);
        cafeVM.addeddate = cafeVM.addedDate ? moment.unix(cafeVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        cafeVM.delivereddate = cafeVM.deliveredDate ? moment.unix(cafeVM.deliveredDate.seconds).format('DD/MM/YYYY hh:mm A') : '';

        if (isMultiDealer)
            cafeVM.cafeDealerName = CommonHelper.getOtherDealerName(dealersettings, cafeVM.clientID);

        if (!_.isEmpty(cafeVM.contact)) {
            let _contact = Object.assign({}, cafeVM.contact);
            cafeVM.name = CommonHelper.getFullName(titles, _contact);
            cafeVM.displayID = _contact.displayID ? _contact.displayID : '';
            cafeVM.phone = CommonHelper.getFullPhone(_contact.phoneCode, _contact.phone);
            cafeVM.email = _contact.email ? (_contact.email) : '';
        }
        else if (!_.isEmpty(cafeVM.staff)) {
            cafeVM.name = cafeVM.staff.name ? cafeVM.staff.name : '';
        }


        cafeVM.cafeStatus = (cafeVM.status
            ?
            <div className={`badge badge-pill badge-${cafeVM.status.toLowerCase()}-fill`}>{cafeVM.status}</div>
            :
            <></>);

        return cafeVM
    }

    const orderQuantity = (orders) => {

        if (!_.isEmpty(orders)) {
            var total = 0;
            for (var i = 0; i < orders.length; i++) {
                total = total + (orders[i].quantity ? orders[i].quantity : 0);
            }
            return total;
        }
        else {
            return 0;
        }
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)

        window.firebase.firestore().doc(`/users/${localStorage.uid}`)
            .set({ cafeHistoryFields: fields }, { merge: true })
            .then(() => {
                setCafeHistoryFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('cafeHistoryFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }
    const handleEditFleet = (id, obj) => {

        let _stock = Object.assign({}, objCafe);
        for (let [key, value] of Object.entries(obj)) {
            //if (_stock.hasOwnProperty(key))
            _stock[key] = value;
        }

        setModelCafeShow(true);
        setModelCafe(_stock);
    }

    const handleClose = (id) => {
        //console.log('handleClose', id)
        setModelCafeShow(false);
        setModelCafe({});

    }


    const handleActionClick = (id, obj) => {

        return (
            <div
                style={{ cursor: 'pointer' }}
                className="mini-button"
                onClick={(e) => {
                    e.preventDefault();
                    handleEditFleet(id, obj);
                }}>
                <i className="ico icon-more"></i>
            </div>
        )


    }

    const handleDetailsClick = (e, id, obj) => {
        e.preventDefault();
        handleEditFleet(id, obj);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload({
            ...checkDataLoad,
            [activeTab]: true
        })
    }

    const handleChangeTab = (_data) => {
        //console.log('handleChangeTab', _data)
        setActiveTab(_data);
        setDataload({
            ...checkDataLoad,
            [_data]: true
        })
        setHasMoreData([...hasMore.filter(e => e.type !== _data)]);
    }

    return (
        <div className="middle-wrapper">
            {/* <FilterPanel handleAddActivity={handleAddActivity} /> */}
            <div className="filter-panel">

                <div className="float-left fleet-head">
                    <h3><Translate text={'cafeHistory'} /></h3>
                    {/* <div className="fleet-count-btn ml-3"> <span >  <Translate text={'available'} /> </span> <span className="fleet-count"> {availableList.length} </span></div>
                    <div className="fleet-count-btn ml-2"> <span >  <Translate text={'loaned'} /> </span> <span className="fleet-count"> {loanList.length} </span></div> */}
                </div>

                <div className="float-right">

                </div>

            </div>
            <div className="filter-sub-panel">
                <div className="float-left">
                    {/* <div className="filter-item-btn">
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                        }}>
                            <i className="ico icon-filter"></i>
                            <span>Filter </span>
                            <i className="ico icon-dropdown"></i>
                        </a>
                    </div> */}

                    <div className="filter-search">
                        <div className="input-group ">
                            <input
                                className="form-control"
                                type="text"
                                id="isearch"
                                placeholder="search..."
                                value={searchText ? searchText : ''}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    localStorage.cafeHistorySearchText = e.target.value;

                                    if (!e.target.value) {
                                        setSearchText('');
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }
                                }}
                                autoComplete="off"
                            />
                            <div className="input-group-append input-search-clear">
                                {
                                    searchText && searchText.length > 0
                                        ?
                                        <a href="#"
                                            className="input-search-clear-icon"
                                            title="Clear"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSearchText('');
                                                localStorage.cafeHistorySearchText = '';
                                                setDataload({
                                                    ...checkDataLoad,
                                                    [activeTab]: true
                                                })
                                                setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                            }}
                                        >
                                            <i className="ico icon-remove"></i>
                                        </a>
                                        :
                                        <>
                                        </>
                                }

                                <button
                                    className="btn btn-default"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText(searchText)
                                        setDataload({
                                            ...checkDataLoad,
                                            [activeTab]: true
                                        })
                                        setHasMoreData([...hasMore.filter(e => e.type !== activeTab)]);
                                    }}>
                                    <i className="ico icon-search-fill"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {
                        clients && clients.length > 1
                            ?
                            <>
                                <div className="form-loan-history form-row ml-2">
                                    <div className="form-group col-md-12 daterange-fix">
                                        <ReactSelect
                                            options={clients}
                                            name={"client"}
                                            placeholder={'select client'}
                                            onChange={(e) => {
                                                setSelectedClient(e.value);
                                                setDataload({
                                                    ...checkDataLoad,
                                                    [activeTab]: true
                                                })
                                            }}
                                            value={!_.isEmpty(selectedClient) ? selectedClient : ''}
                                            classNamePrefix={`basic-select`}
                                            removeClearable={true}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                    }


                </div>
                <div className="float-right">
                    <div className="tabber-nav">
                        <ul>
                            <li><a href="#" className={`${activeTab === 'Delivered' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleChangeTab('Delivered');
                                }}><Translate text={'delivered'} /> </a></li>

                            <li><a href="#" className={`${activeTab === 'Cancelled' ? 'active' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleChangeTab('Cancelled')
                                }}
                            > <Translate text={'cancelled'} /> </a></li>


                        </ul>
                    </div>
                </div>
            </div>

            <div className="calendar-panel">
                {
                    cafeHistoryLoader[activeTab] ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        cafeHistory[activeTab] && cafeHistory[activeTab].length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    isReorderable={true}
                                    datalist={cafeHistory[activeTab] ? cafeHistory[activeTab] : []}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={allCafeHistoryFields}
                                    handleSavesettings={handleSavesettings}
                                    dynamicFields={(cafeHistoryFields && cafeHistoryFields.length > 0) ? cafeHistoryFields : allCafeHistoryFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    settingsLoader={settingsLoader}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleDetailsClick}
                                    isSettings={true}
                                    hasMore={hasMore.find(e => e.type === activeTab)}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    activityTab={activeTab}
                                    displayByValue={true}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No record found'} /></p>

                                </div>
                            </div>
                        )
                }


            </div>

            <PopUpModal show={modelCafeShow}>
                <CafeOrder
                    show={modelCafeShow}
                    handleClose={handleClose}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    cafe={modelCafe}
                >
                </CafeOrder>
            </PopUpModal>

        </div>
    )
}

export default CafeHistoryListView;