import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { InputText, ReactSelect } from '../../../../components'
import { regionVM } from './viewModel'

const AddSubRegion = (props) => {
    const [subRegion, setSubRegion] = useState(props.subRegion);
    const [errorFields, setErrorFields] = useState({});
    const newSubRegion = !_.isEmpty(props.subRegion) ? false : true
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        if (_.isEmpty(props.subRegion)) {
            const newSubRegionM = Object.assign({}, regionVM);
            newSubRegionM.documentID = window.firebase.firestore().collection(`clientSettings/${props.selectedOEM.value}/subregions`).doc().id;
            newSubRegionM.addedBy = localStorage.uid;
            newSubRegionM.modifiedBy = localStorage.uid;
            newSubRegionM.addedDate = window.firebase.firestore.Timestamp.now();
            newSubRegionM.modifiedDate = window.firebase.firestore.Timestamp.now();
            setSubRegion(newSubRegionM)
        }
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setSubRegion({
                ...subRegion,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setSubRegion({
                ...subRegion,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleReactSelectChange = (e, data) => {
        setSubRegion({
            ...subRegion,
            [data.name]: e ? e.value : null,
        });
    }

    const saveSubRegion = (e) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(subRegion['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }
        setErrorFields(errors)
        if (props.allSubRegions.filter(item => item.value !== subRegion.documentID).some(item => item.label === subRegion.name)) {
            Swal.fire('Subregion with same name already exists', '', 'error')
            formIsValid = false;
        }
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        delete subRegion.active;
        subRegion.modifiedDate = window.firebase.firestore.Timestamp.now();
        subRegion.modifiedBy = localStorage.uid
        window.firebase.firestore().doc(`clientSettings/${props.selectedOEM.value}/subregions/${subRegion.documentID}`).set(subRegion, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newSubRegion === true ? 'Subregion added successfully' : 'Subregion updated successfully'), {
                    duration: 2000
                })
                props.handleClose(subRegion);
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }

    return _.isEmpty(subRegion) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-update-password"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Subregion Name</label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={subRegion.name}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label >Data Subregion</label>
                                    <ReactSelect
                                        options={props.projects}
                                        name={"projectId"}
                                        placeholder={'select subregion'}
                                        onChange={handleReactSelectChange}
                                        value={subRegion.projectId}
                                        classNamePrefix={`${errorFields["projectId"]} basic-select`}
                                    >
                                    </ReactSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => saveSubRegion(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    Save
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddSubRegion;