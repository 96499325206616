/** LIBRARIES */
import React, { Component } from 'react';
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { InputTextArea } from '../../../components';

export default class AddNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields:
            {
                notes: ''
            },
            errors: {}
        }
    }

    loadEnquiry = () => {
        const { notes } = this.props;
        this.setState({
            fields:
            {
                notes: notes
            },
            errors: {}
        });
    }

    componentDidMount() {
        this.loadEnquiry();
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name); });

        // var array1 = value.split(' ');
        // var str = value;

        // if (array1.length > 0) {
        //     var newarray1 = [];
        //     for (var x = 0; x < array1.length; x++) {
        //         newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
        //     }
        //     fields[name] = newarray1.join(' ');
        //     this.setState({ fields: fields });
        // } else {
        //     if (str && str.length >= 1) {
        //         var firstChar = str.charAt(0);
        //         var remainingStr = str.slice(1);
        //         str = firstChar.toUpperCase() + remainingStr;
        //     }
        //     fields[name] = str;
        //     this.setState({ fields: fields });
        // }


    }
    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);

        let errorClass = 'input_error';

        for (let [key, value] of Object.entries(fields)) {
            if (!fields[key]) {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.props.handleClose(fields.notes);
        }
        else
            return formIsValid;

    };

    handleCancel = (e) => {
        e.preventDefault();
        //this.loadEnquiry();
        let fields = Object.assign({}, this.state.fields);
        fields.notes = null;
        this.setState({ fields: fields }, () => { this.errorChange('notes'); });

    }


    render() {
        const { notes } = this.state.fields;
        const { isDisable } = this.props;

        return (
            <>
                <div className={`${(isDisable) ? 'form-disable' : ''}`}>
                    <div className="popover-body popover-content">
                        <div className="form-style popover-middle-pipeline">
                            <div className="form-row ">
                                <div className="form-group col-md-12">
                                    <InputTextArea
                                        className={`form-control notes ${this.state.errors["notes"]}`}
                                        name="notes"
                                        autoFocus={isDisable ? false : true}
                                        onChange={this.handleOnChange}
                                        value={notes}
                                        maxLength="500"
                                        rows="3">
                                    </InputTextArea>
                                </div>
                            </div>
                        </div>
                        {
                            isDisable
                                ?
                                <></>
                                :
                                <>
                                    <div className="popover-footer d-inline-block width100">
                                        <button
                                            type="button"
                                            className="btn btn-primary float-right ml-2"
                                            onClick={(e) => this.handleSubmit(e)}
                                        ><Translate text={'done'} />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-default float-right"
                                            onClick={(e) => this.handleCancel(e)}
                                        ><Translate text={'clear'} />
                                        </button>
                                    </div>
                                </>
                        }

                    </div>
                </div>

            </>
        );
    }
}
