import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { ContentOverlay, ReactSelect } from '../../../components'
import moment from 'moment'
import images from '../../../images'
import NumberFormat from 'react-number-format'
import { ClientContext } from '../../layout/clientContext'
import { apisOptions, levelOptions } from './viewModel';
import Swal from 'sweetalert2'

const Pricing = (props) => {
    const [clientLoader, setClientLoader] = useState(true)
    const [loader, setLoader] = useState(true)
    const [btnLoader, setBtnLoader] = useState(false)
    const [selectedLevel, setSelectedLevel] = useState({ level: 'oem' })

    const [oemOptions, setOEMOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [regionOptions, setRegionOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);

    const [selectedOEM, setSelectedOEM] = useState()
    const { oemSettings } = useContext(ClientContext);
    const [apiPrice, setApiPrice] = useState({})

    const currencySymbol = '$ ';

    useEffect(() => {
        if (_.isEmpty(oemSettings))
            return

        let _oemSettings = [];
        oemSettings.forEach((rec) => {
            _oemSettings.push({ label: rec.name, value: rec.id, data: rec })
        })
        setOEMOptions(_.orderBy(_oemSettings, ['label'], ['asc']));
        if (_oemSettings.length > 0)
            setSelectedOEM(_oemSettings[0].data)
    }, [oemSettings])

    useEffect(() => {
        if (_.isEmpty(selectedOEM))
            return

        const fetchOptions = async () => {
            let _clients = [];
            let _regions = [];
            let _groups = [];

            const clientSnapshots = await window.firebase.firestore()
                .collection(`clients`)
                .where('settingsID', '==', selectedOEM.id).get()
            clientSnapshots.docs.forEach(rec => {
                _clients.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const regionSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`regions`)
                .get()
            regionSnapshots.docs.forEach(rec => {
                _regions.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })

            const groupSnapshots = await window.firebase.firestore()
                .collection(`clientSettings`)
                .doc(selectedOEM.id)
                .collection(`groups`)
                .get()
            groupSnapshots.docs.forEach(rec => {
                _groups.push({
                    label: rec.data().name,
                    value: rec.id,
                    data: rec.data()
                })
            })


            const autograbDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'autograb')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            const ppsrDoc = await window.firebase.firestore().collection(`integrations`).where("type", "==", 'ppsr')
                .limit(1).get()
                .then(async snapshot => {
                    if (snapshot && snapshot.size > 0) {
                        return snapshot.docs[0].data()
                    }
                    else return null;
                });

            let priceLog = {};
            if (autograbDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...autograbDoc.price
                }
            }
            if (ppsrDoc?.price) {
                priceLog = {
                    ...priceLog,
                    ...ppsrDoc.price
                }
            }
            setApiPrice(priceLog);
            setClientOptions(_clients)
            setRegionOptions(_regions)
            setGroupOptions(_groups)
            setClientLoader(false)
        }
        fetchOptions()

    }, [selectedOEM])

    useEffect(() => {
        if (_.isEmpty(selectedOEM) || !loader || clientLoader) {
            return
        }

        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.data.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.data.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }
        if (clients.length > 0) {
            window.firebase.firestore().doc(`clients/${clients[0].value}/modulePricing/${clients[0].value}`).get()
                .then(snapshot => {
                    if (snapshot.exists) {
                        let priceLog = {};
                        if (snapshot.data().autograb) {
                            priceLog = {
                                ...priceLog,
                                ...snapshot.data().autograb
                            }
                        }
                        if (snapshot.data().ppsr) {
                            priceLog = {
                                ...priceLog,
                                ...snapshot.data().ppsr
                            }
                        }
                        if (!_.isEmpty(priceLog))
                            setApiPrice(priceLog);
                    }
                    setLoader(false)
                })
        }
        else {
            setLoader(false)
        }

    }, [selectedOEM, loader, clientLoader])

    const onAmountChange = (e, name) => {
        let _value = e.floatValue
        // console.log('onAmountChange value ', _value)
        if (_value === undefined) {
            _value = ''
        }

        setApiPrice({ ...apiPrice, [name]: _value })
    }

    const handleSave = () => {
        setBtnLoader(true);
        let clients = clientOptions;
        if (selectedLevel.level === 'region') {
            clients = clientOptions.filter(a => a.data.region === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'group') {
            clients = clientOptions.filter(a => a.data.group === selectedLevel.levelID);
        }
        else if (selectedLevel.level === 'individual') {
            clients = clientOptions.filter(a => a.value === selectedLevel.levelID);
        }
        const batch = window.firebase.firestore().batch();
        let _logs = _.chain(apisOptions)
            .groupBy("module")
            .map((value, key) => ({
                name: key,
                value: Object.fromEntries(
                    value.map(e => [e.value, apiPrice[e.value]])
                )
            }))
            .value()
        clients.forEach(rec => {
            batch.set(window.firebase.firestore().doc(`clients/${rec.value}/modulePricing/${rec.value}`),
                {
                    ...Object.fromEntries(
                        _logs.map(e => [e.name, e.value])
                    )
                }, { merge: true })
        })
        batch.commit()
            .then(snapshot => {
                setBtnLoader(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Price updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    return (
        <>

            <div className="settings-container">
                <div className="settings-container-sub">

                    <h2>Pricing</h2>
                    <div className="divider-line"></div>

                    <div className="settings-api-billing-wrap mt-3">

                        <div className="settings-head form-style">
                            <div className="row">
                                <div className="col-md-12 settings-api-billing-padding">
                                    <div className="row">
                                        <div className="col-md-2 pr-0">
                                            <ReactSelect
                                                options={oemOptions}
                                                name={"oem"}
                                                placeholder={'select oem'}
                                                onChange={(e) => {
                                                    setLoader(true)
                                                    setClientLoader(true)
                                                    setSelectedOEM(e.data)
                                                    setSelectedLevel({ level: 'oem' })
                                                }}
                                                value={!_.isEmpty(selectedOEM) ? selectedOEM.id : ''}
                                                classNamePrefix={`basic-select`}
                                                removeClearable={true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                {/* <label for="">Level</label> */}
                                                <ReactSelect
                                                    options={levelOptions}
                                                    name={"level"}
                                                    placeholder={'select level'}
                                                    onChange={(e, data) => {
                                                        if (e) {
                                                            if (e.value === 'oem')
                                                                setLoader(true)
                                                            setSelectedLevel({
                                                                level: e.value
                                                            });

                                                        }
                                                        else
                                                            setSelectedLevel(null);
                                                    }}
                                                    value={selectedLevel ? selectedLevel.level : ''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                {/* <label for="">Dealer</label> */}
                                                {
                                                    (!_.isEmpty(selectedLevel) && selectedLevel.level !== 'oem') ?
                                                        (
                                                            // <div className="col-md-3 pr-0">
                                                            <ReactSelect
                                                                options={selectedLevel.level === 'group' ? groupOptions : selectedLevel.level === 'region' ? regionOptions : clientOptions}
                                                                name={'levelID'}
                                                                placeholder={`select ${selectedLevel.level === 'individual' ? 'client' : selectedLevel.level}`}
                                                                onChange={(e, data) => {
                                                                    if (e) {
                                                                        setLoader(true)
                                                                        setSelectedLevel({
                                                                            ...selectedLevel,
                                                                            levelID: e.value
                                                                        });
                                                                    }
                                                                    else
                                                                        setSelectedLevel(null);
                                                                }}
                                                                value={selectedLevel ? selectedLevel.levelID : ''}
                                                                classNamePrefix={`basic-select`}
                                                                removeClearable={true}
                                                            >
                                                            </ReactSelect>
                                                            //  </div>
                                                        ) : (<></>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                    selectedLevel && (selectedLevel.level === 'oem' || !_.isEmpty(selectedLevel.levelID)) ? (<>
                                        <div className="common-table mt-3">
                                            <table
                                                className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="head-light" width="5%">SNo</th>
                                                        <th scope="col" className="head-light" width="75%">Apis</th>
                                                        <th scope="col" className="head-light" width="10%">AMS Price</th>
                                                        <th scope="col" className="head-light" width="10%">Dealer Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        apisOptions.filter(a => a.module !== 'mailjet').map((rec, index) => {
                                                            return <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{rec.name}</td>
                                                                <td>
                                                                    {
                                                                        rec.amsPrice ? (
                                                                            <div className='grid-one text-right number-value float-left w-100 form-disable'>
                                                                                <NumberFormat
                                                                                    allowNegative={false}
                                                                                    thousandSeparator={true}
                                                                                    prefix={currencySymbol}
                                                                                    placeholder={currencySymbol}
                                                                                    className={`input-amount`}
                                                                                    decimalScale={3}
                                                                                    value={rec.amsPrice}
                                                                                />
                                                                            </div>
                                                                        ) : (<></>)
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <div className='grid-one text-right number-value float-left w-100'>
                                                                        <NumberFormat
                                                                            allowNegative={false}
                                                                            thousandSeparator={true}
                                                                            prefix={currencySymbol}
                                                                            placeholder={currencySymbol}
                                                                            className={`input-amount`}
                                                                            decimalScale={3}
                                                                            onValueChange={e =>
                                                                                onAmountChange(
                                                                                    e,
                                                                                    rec.value
                                                                                )
                                                                            }
                                                                            value={apiPrice[rec.value]}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="settings-head form-style">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="row">
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button type="button" className="btn btn-primary float-right"
                                                        onClick={() => {
                                                            handleSave();
                                                        }}
                                                    >
                                                        {btnLoader ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                        Save</button>
                                                </div>
                                            </div>
                                        </div></>) : (
                                        (
                                            <div className="common-table" style={{ height: (window.innerHeight - 340) + 'px' }}>
                                                <div className='h-100  w-100'>
                                                    <div className='spinner-loader h-100'>
                                                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                                            <div className="no-data-txt mt-2">
                                                                <p>{`Please select ${selectedLevel ? (selectedLevel.level === 'individual' ? 'client' : selectedLevel.level) : ''}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing