/** LIBRARIES */
import React, { useEffect } from "react";
//import GreenAudioPlayer from 'green-audio-player'
import _ from 'lodash'
const AudioPlayer = React.memo(({ id, recording }) => {

    useEffect(() => {
        if (!_.isEmpty(recording) && id) {
            //new GreenAudioPlayer(`.${id}`, { stopOthersOnPlay: true });
            //var audio = document.getElementById(id);
            //var source = document.getElementById(`${id}-source`);
            //source.src = recording;
            //audio.load(); //call this to just preload the audio without playing
        }
        document.addEventListener('play', function (e) {
            var audios = document.getElementsByTagName('audio');
            for (var i = 0, len = audios.length; i < len; i++) {
                if (audios[i] != e.target) {
                    audios[i].pause();
                }
            }
        }, true);
    }, [])

    useEffect(() => {
        if (document.getElementById(id)) {
            var audio = document.getElementById(id);
            audio.load();
        }
    }, [recording])

    return (
        <>
            <div className={`w-100 ${id}`}>
                <audio controls id={id} controlsList="nodownload" className="w-100">
                    <source id={`${id}-source`} src={recording} type="audio/mpeg" />
                </audio>
            </div>

        </>

    );

})

export default AudioPlayer;
