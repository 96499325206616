/** LIBRARIES */
import React, { Component } from "react";
import moment from 'moment';
import { Modal } from 'react-bootstrap'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import _ from 'lodash';
//import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'
//import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
//import { PopUpModal } from '../../../components';
import { default as _images } from "../../../images";

//import { objCafe } from '../viewModel'
import { cafeStatus } from '../../../services/enum'
import { firestoreDB } from '../../../services/helper';
import { objCafe } from '../viewModel'

class CafeOrder extends Component {
    constructor(props) {
        super(props);
        this.unsubscribeCORS = null;
        //this.ref = firestoreDB(this.props.dealersettings).firestore().firestore().collection('cafeOrders');
        this.state = {
            fields: Object.assign({}),
            kitchenNotes: '',
            isLoading: false,
            isCancelLoading: false
        }

    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        let { value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        this.setState({ kitchenNotes: value });
    }

    handleSubmit = (status) => {


        if (status === cafeStatus.CANCELLED)
            this.setState({ isCancelLoading: true });
        else
            this.setState({ isLoading: true });

        const { dealersettings } = this.props;
        const userID = dealersettings ? dealersettings.id : ''
        let fields = Object.assign({}, this.state.fields);

        if (status && fields) {
            let objData = Object.assign({}, {
                status: status.toLowerCase(),
                modifiedBy: userID,
                isDeleted: false,
                documentID: fields.documentID,
                modifiedFrom: 'web',
                modifiedDate: window.firebase.firestore.Timestamp.now(),
                deliveredNotes: this.state.kitchenNotes ? this.state.kitchenNotes : fields.deliveredNotes,
                deliveredBy: (status !== cafeStatus.PREPARING ? userID : fields.deliveredBy),
                deliveredDate: (status !== cafeStatus.PREPARING ? window.firebase.firestore.Timestamp.now() : fields.deliveredDate)
            });

            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    delete objData[propName];
                }
            }

            //console.log('handleSubmit', objData, cafe)
            const updateRef = firestoreDB(dealersettings).firestore().collection('cafeOrders').doc(fields.documentID);
            updateRef.update(objData).then((docRef) => {
                this.setState({ isLoading: false, isCancelLoading: false });
                toast.notify('Cafe order updated successfully', {
                    duration: 2000
                })
                this.props.handleClose(fields.documentID);
            }).catch((error) => {
                console.error("Error updating enquiries: ", error);
            });
        }


    };

    //#region PAGE LOAD EVENTS

    async loadCafeOrder(props) {
        const { show, docID, cafe, dealersettings } = props;
        let _recordID = docID ? docID : (cafe && cafe.documentID ? cafe.documentID : null);
        //const fields = Object.assign({}, objCafe);
        if (show === true && _recordID) {
            this.newDocID = _recordID;

            this.unsubscribeCORS = firestoreDB(dealersettings).firestore().collection('cafeOrders')
                .doc(_recordID)
                .onSnapshot(this.onCollectionUpdate);

            // const refData = await this.ref.doc(docID).get();
            // if (refData.exists) {
            //     const objData = Object.assign({}, refData.data());

            //     for (let [key, value] of Object.entries(objData)) {
            //         fields[key] = value;
            //     }

            //     fields.documentID = refData.id;
            //     this.setState({ fields: fields });               

            // } else {
            //     console.error("No such document2!");
            // }
        }
    }

    onCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }

        const { clientUsers, groupUsers } = this.props;
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        const fields = Object.assign({}, objCafe);
        const objData = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
        }
        fields.documentID = docSnapshot.id;

        fields.addedByName = fields.addedByName ? fields.addedByName : (fields?.staff?.documentID === fields.addedBy ? fields?.staff?.name : CommonHelper.getUserNamebyId(allUsers, fields.addedBy));

        this.setState({ fields: fields });
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadCafeOrder(this.props);
    }

    componentWillUnmount() {
        this.unsubscribeCORS && this.unsubscribeCORS();
    }
    //#endregion

    render() {
        //const { } = this.state;
        const { show, clientUsers, groupUsers } = this.props;
        const { isLoading, isCancelLoading } = this.state
        const divStyle = {
            maxHeight: '300px',
            overflow: 'auto'
        };
        const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers
        const { orderNumber, orders, status, deliveredNotes,
            orderNotes, addedBy, modifiedBy, deliveredBy,
            addedDate, modifiedDate, deliveredDate, addedByName } = this.state.fields;       

        return (
            (!_.isEmpty(this.state.fields))
                ?
                <>
                    <Modal
                        id="kitchen-popup"
                        show={show}
                        onHide={this.props.handleClose}
                        backdrop="static"
                        backdropClassName={show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-add-kitchen">

                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className="modal-title"><Translate text={'orders'} />
                                    <div className="float-right kitchen-modal-status">
                                        <div className={`badge badge-pill badge-${status.toLowerCase()}-fill`}>{status}</div>
                                    </div>

                                </h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={`container-fluid`}>
                                <div className="row">
                                    <div className="kitchen-detail-head">
                                        <div className="w-50 float-left">
                                            <div className="kitchen-detail-user-flex">

                                                <div className="kitchen-detail-user-image">
                                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                                        <img src={CommonHelper.getUserImagebyId(allUsers, addedBy, addedByName)} alt="" className=" rounded-circle img-object-fit" />
                                                    </a>
                                                </div>
                                                <div className="kitchen-detail-user-data">
                                                    <div className="kitchen-detail-user-title">{addedByName}</div>
                                                    <div className="kitchen-detail-user-info"><Translate text={'salesPerson'} /></div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="w-50 float-left text-right">
                                            <div className="kitchen-detail-orderid">#{orderNumber}</div>
                                            <div className="kitchen-detail-orderdate">{addedDate ? moment.unix(addedDate.seconds).format('DD/MM/YYYY h:mm A') : '--'}</div>
                                        </div>
                                    </div>

                                    <div className="kitchen-detail-body">
                                        {
                                            status === cafeStatus.PREPARING
                                                ?
                                                <div className="alert alert-custom " role="alert">
                                                    <i className="ico icon-menu-orders"></i> <Translate text={'orderIsPreparing'} />
                                                </div>
                                                :
                                                <></>
                                        }
                                        <h3><Translate text={'Order Items'} /></h3>
                                        <div className="kitchen-detail-item-list">

                                            <div style={divStyle}>
                                                {
                                                    orders && orders.map(order =>
                                                        <div key={order.documentID} className="kitchen-detail-loop">
                                                            <div className="divTable">
                                                                <div className="divTableBody">
                                                                    <div className="divTableRow">
                                                                        <div className="divTableCell width90">
                                                                            <div className="kitchen-detail-loop-content">
                                                                                <div className="kitchen-detail-loop-image">
                                                                                    <img src={order.image} alt="" className="img-object-fit" /> {order.name} </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="divTableCell width10 kitchen-detail-loop-badge">
                                                                            <div className="kitchen-detail-loop-count float-right"> {order.quantity} </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {!_.isEmpty(order.subItems) && (<div className="cafe-item-note-custom mt-2 pl-2 pr-2 pb-1">
                                                                {order.subItems.map((item) => (
                                                                    <div className={`cafe-item-multi-notes`} key={item.id}>{item.value}</div>
                                                                ))}
                                                            </div>)}
                                                        </div>
                                                    )

                                                }
                                            </div>

                                        </div>


                                        {orderNotes && (<div className="kitchen-detail-notes">
                                            <h3> <Translate text={'orderNotes'} /></h3>
                                            <textarea
                                                className={'kitchen-detail-order-notewrap'}
                                                name={'orderNotes'}
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={orderNotes ? orderNotes : ''}
                                                readOnly={true}
                                            >
                                            </textarea>

                                        </div>)}

                                        <div className="kitchen-detail-notes">
                                            <h3> <Translate text={(status === cafeStatus.CANCELLED ? 'cancelledNotes' : (status === cafeStatus.DELIVERED ? 'deliveredNotes' : 'kitchenNotes'))} /></h3>
                                            <textarea
                                                className={'kitchen-detail-notewrap'}
                                                name={'deliveredNotes'}
                                                value={deliveredNotes ? deliveredNotes : this.state.kitchenNotes}
                                                onChange={this.handleOnAreaChange}
                                                readOnly={(status === cafeStatus.PREPARING || status === cafeStatus.PENDING) ? false : true}
                                            >
                                            </textarea>

                                        </div>


                                    </div>

                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className={`${isLoading ? 'form-disable' : ''}`}>
                            {
                                status === cafeStatus.DELIVERED || status === cafeStatus.CANCELLED
                                    ?
                                    <>
                                        <div className={`vehicle-status-alert alert-${status === cafeStatus.CANCELLED ? 'danger' : 'user'} mb-1`}>
                                            <b className="alert-link">
                                                <Translate text={status === cafeStatus.CANCELLED ? 'cancelled' : (status === cafeStatus.READY ? 'ready' : 'delivered')} />: &nbsp;
                                            </b>
                                            {
                                                (deliveredDate && deliveredBy)
                                                    ?
                                                    <>
                                                        {moment.unix(deliveredDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId(allUsers, deliveredBy)}
                                                    </>
                                                    :
                                                    <>
                                                        {moment.unix(modifiedDate.seconds).format('DD/MM/YYYY h:mm A')} - {CommonHelper.getUserNamebyId(allUsers, modifiedBy)}
                                                    </>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>

                                        <button
                                            type="button"
                                            className="btn btn-red float-left"
                                            onClick={(e) => { e.preventDefault(); this.handleSubmit(cafeStatus.CANCELLED) }}
                                        >
                                            {isCancelLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'cancelOrder'} />
                                        </button>
                                        <button type="button"
                                            className="btn btn-primary float-right"
                                            onClick={(e) => { e.preventDefault(); this.handleSubmit(cafeStatus.DELIVERED) }}
                                        >
                                            {isLoading ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                                            <Translate text={'deliverOrder'} />
                                        </button>

                                    </>
                            }
                        </Modal.Footer>
                    </Modal>


                </>
                :
                <></>

        );
    }
}

export default CafeOrder;