/** LIBRARIES */
import React, { Component } from 'react';

import Swal from 'sweetalert2';
import moment from 'moment';
import toast from 'toasted-notes'
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';


class InviteAmsMe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderAmsMe: false,
            chatLoader: false
        };
    }

    handleAmsMeInvite = () => {
        Swal.fire({
            title: CommonHelper.showLocale(this.props, 'Are you sure?'),
            text: CommonHelper.showLocale(this.props, `You want to invite to AMS ME`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(async result => {
            if (result.value) {
                this.setState({ loaderAmsMe: true })

                const { contact, dealersettings } = this.props;
                try {

                    let _objreq = {
                        "clientID": dealersettings.client.id ? dealersettings.client.id : '',
                        "contactID": contact.documentID ? contact.documentID : '',
                        "name": CommonHelper.displayContactName(null, contact, '--'),
                        "email": contact.email ? contact.email : '',
                        "clientName": dealersettings.client.name ? dealersettings.client.name : '',
                        "phone": (contact.phoneCode ? contact.phoneCode : '') + (contact.phone ? contact.phone : '')
                    }

                    const amsMeInvite = window.firebase.functions().httpsCallable('amsme-invite');
                    amsMeInvite(_objreq).then((res) => {
                        this.setState({ loaderAmsMe: false });
                        if (res.data.success) {
                            toast.notify('Invite sent successfully', {
                                duration: 2000,
                            });
                        }
                        else {
                            Swal.fire(res.data.message, '', 'error')
                        }
                    }).catch((error) => {
                        this.setState({ loaderAmsMe: false });
                        console.log(error);
                        Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong. Please contact support.'), '', 'error')
                    })
                }
                catch (error) {
                    Swal.fire(CommonHelper.showLocale(this.props, 'Something went wrong. Please contact support.'), '', 'error')
                }
            }
        })

    }

    handleClickChat = async (e) => {
        e.preventDefault()
        if (this.state.chatLoader) {
            return
        }
        this.setState({ loaderAmsMe: true })
        const { contact, dealersettings } = this.props;
        const meID = this.props.contact.amsMe.meID

        const createConversation = async (conversationID) => {

            const contactName = CommonHelper.displayContactName(null, contact, '--')
            const contactAvatar = CommonHelper.showNameAvatar(contactName)
            const contactID = meID
            const nameFrom = dealersettings.name
            try {
                if (!conversationID) {
                    return;
                }
                const timestamp = moment()._d.getTime();
                const messageRef = window.firebase
                    .firestore()
                    .collection('messages')
                    .doc(conversationID)
                    .collection(conversationID)
                    .doc(timestamp.toString());

                let messageModel = {
                    content: 'hello',
                    idFrom: localStorage.uid,
                    idTo: contactID,
                    idToAMSMe: true,
                    nameFrom: nameFrom,
                    timestamp,
                    type: 'text',
                    oemID: dealersettings.client.settingsID,
                    clientID: dealersettings.client.id,
                    isRead: false,
                    documentID: null,
                    avatarFrom: CommonHelper.getUserImagebyId(this.props.clientUsers, localStorage.uid),
                    addedFrom: 'web',
                };

                let messageInfoModel = {
                    clientID: dealersettings.client.id,
                    isRead: false,
                    lastMessage: {},
                    oemID: dealersettings.client.settingsID,
                    participantInfo: [],
                    timestamp,
                };

                messageInfoModel.participantInfo.push({
                    avatar: CommonHelper.getUserImagebyId(this.props.clientUsers, localStorage.uid),
                    name: nameFrom,
                    isAMSME: false,
                    id: localStorage.uid
                })
                messageInfoModel.participantInfo.push({
                    avatar: contactAvatar ? contactAvatar : '',
                    name: contactName ? contactName : '',
                    isAMSME: true,
                    id: contactID ? contactID : '',
                    email: contact.email ? contact.email : null,
                    phone: contact.phone ? contact.phone : null,
                })
                messageInfoModel.lastMessage = { ...messageModel }

                await messageRef.set(messageModel, { merge: true });
                const ref = window.firebase
                    .firestore()
                    .collection('messages')
                    .doc(conversationID)
                await ref.set(messageInfoModel, { merge: true });

                handleOpenChat(conversationID)
            } catch (error) {
                console.log(error);
            }
        }

        const handleOpenChat = (conversationID) => {
            //console.log('conversationID', conversationID)
            const chatCloseIcon = document.querySelector('#chatApp-close-icon')
            if (chatCloseIcon)
                chatCloseIcon.click()

            const chatOpenIcon = document.querySelector('#chat-icon-div')
            if (chatOpenIcon)
                chatOpenIcon.click()

            const contactTab = document.querySelector('.contacts-tab')
            if (contactTab)
                contactTab.click()

            this.setState({ loaderAmsMe: false })

            setTimeout(() => {
                const chatListItem = document.querySelector(`#chat-list-item-${conversationID}`)
                if (chatListItem) {
                    chatListItem.click()
                }
            }, 1000)
        }

        let address = `${meID}-${localStorage.uid}`;

        let arrToSort = []
        arrToSort.push(localStorage.uid)
        arrToSort.push(meID)
        let sortedArr = arrToSort.sort()
        let idForMessage = sortedArr.join('-')
        address = idForMessage

        let firstRef = window.firebase
            .firestore()
            .collection('messages')
            .doc(address)
            .collection(address);

        try {
            const firstSnap = await firstRef
                .orderBy('timestamp', 'desc')
                .limit(1)
                .get()

            if (firstSnap.size > 0) {
                // if user already has a convo then open
                handleOpenChat(address);
            } else {
                createConversation(address);
            }

        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { loaderAmsMe } = this.state;
        const { dealersettings, amsMeEnabled, isContact, isAmsMeContact, contact } = this.props;

        return (
            <>
                {
                    amsMeEnabled && isContact
                        ?
                        <>
                            {
                                isAmsMeContact
                                    ?
                                    <>
                                        <div className='chatinner-item-btn mr-2' onClick={(e) => this.handleClickChat(e)}>
                                            <a href='#'>
                                                <div className='chatinner-logo'>
                                                    <i className='ico icon-chat'></i>
                                                </div>
                                                <div className='chatinner-inner-text'>
                                                    <div className='chatinner-inner-text-top'>  <Translate text={'chat'} />  </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="me-item-btn mr-2 pointer-none">
                                            <a href="#">
                                                <div className="me-logo"><img src={_images.meIcon} width="26" alt="" /></div>
                                                <div className="me-inner-text">
                                                    <div className="me-inner-text-top"> <Translate text={'Joined AMS Me'} /> </div>
                                                    <div className="me-inner-text-sub">{contact.amsMe.joinedDate ? moment.unix(contact.amsMe.joinedDate.seconds).format('DD MMM YYYY h:mm A') : '--'} </div>
                                                </div>
                                                <div className="me-invite-right"><img src={_images.meJoined} alt="" width="22" /></div>
                                            </a>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            contact.meInvite
                                                ?
                                                <>
                                                    <div className="me-item-btn mr-2 pointer-none">

                                                        <a href="#">
                                                            <div className="me-logo"><img src={_images.meIcon} width="26" alt="" /></div>
                                                            <div className="me-inner-text">
                                                                <div className="me-inner-text-top"> <Translate text={'Invitation sent'} /> </div>
                                                                <div className="me-inner-text-sub"> {moment.unix(contact.meInvite.seconds).format('DD MMM YYYY h:mm A')} </div>
                                                            </div>
                                                            <div className="me-invite-right"><img src={_images.meSent} alt="" width="22" /></div>
                                                        </a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div
                                                        className={`me-item-btn mr-2 ${(loaderAmsMe) ? 'form-disable' : ''}`}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.handleAmsMeInvite();
                                                        }}>
                                                        <a href="#">
                                                            <div className="me-logo"><img src={_images.meIcon} width="26" alt="" /></div>
                                                            <div className="me-inner-text">
                                                                <div className="me-inner-text-top">

                                                                    <Translate text={'Invite to AMS ME'} />
                                                                </div>
                                                            </div>
                                                            <div className="me-invite-right">
                                                                {loaderAmsMe ?
                                                                    <div className="spinner-border spinner-border-sm ml-2 text-primary" role="status"></div>
                                                                    : <><img src={_images.meInvite} alt="" width="22" /></>}

                                                            </div>
                                                        </a>

                                                    </div>
                                                </>
                                        }
                                    </>
                            }
                        </>
                        :
                        <></>
                }

            </>

        );
    }

}

export default InviteAmsMe;