import React from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'toasted-notes';

import '../../../../styles/customer-feedbackform.scss';

const EmbedForm = ({ show, handleClose, device,title }) => {
	const text = `${device.shortURL}`;
	const handleClick = () => {
		navigator.clipboard.writeText(text);
		toast.notify('Code copied successfully', {
			duration: 2000,
		});
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				backdropClassName={show ? 'overlay-modal active' : ''}
				dialogClassName='modal-dialog modal-dialog-scrollable modal-dialog-centered modal-publish-dynamicform'
				enforceFocus={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='container-fluid'>
						<div className='row'>
							<p>
								Please copy and paste this url in your TV device browser.
							</p>
							<div className='link-share-dynamic-bg'>
								{/* <i className='fa- fa-link mr-1'></i> */}
								<xmp style={{ margin: '0px' }}>{text}</xmp>
							</div>
							<div className='d-inline-block w-100'>
								<button
									type='button'
									className='btn btn-primary float-right'
									onClick={handleClick}
								>
									<i className='fa fa-clone mr-1'></i> Copy Code
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EmbedForm;
