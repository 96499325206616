import React from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import images from '../../images'
import Translate from '../../constants/translate';

const DownloadApp = (props) => {
    return (<Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        backdropClassName={'overlay-modal active'}
        dialogClassName="modal-dialog-centered modal-download-app"
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="modal-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 mb-3">
                        <div className="qr-device-img mt-2 pr-3"> <img src={images.appDownload} alt="" /> </div>
                    </div>
                    <div className="col-md-7 mb-3">
                        <div className="qr-head">
                            <h3><span className="qr-blue"> <Translate text={'Scan your desired QR code below.'} /></span></h3>
                            <p><Translate text={'Scan the QR code to download the AMS Pro App on your device.'} /></p>
                        </div>
                        <div className="store-box">
                            <div className="store-qrcode"><a href="https://apps.apple.com/us/app/ams-pro/id1533378803" target="_blank"><img src={images.appstore} width="200" height="200" alt="" /></a></div>
                            <div className="store-link"><a href="https://apps.apple.com/us/app/ams-pro/id1533378803" target="_blank"><img src={images.appstoreIcon} width="380" height="113" alt="" /></a></div>
                        </div>
                        <div className="store-box ml-5">
                            <div className="store-qrcode"><a href="https://play.google.com/store/apps/details?id=com.fusionsd.amspro" target="_blank"><img src={images.playstore} width="200" height="200" alt="" /></a></div>
                            <div className="store-link"><a href="https://play.google.com/store/apps/details?id=com.fusionsd.amspro" target="_blank"><img src={images.playstoreIcon} width="380" height="113" alt="" /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
    )
}

export default DownloadApp;