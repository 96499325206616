/** LIBRARIES */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash'
import GaugeComponent from 'react-gauge-component'
/** PROVIDERS */
import { defaultCreditScore } from '../../../services/enum';
import CreditScoreHistory from './creditScoreHistory';
import { PopUpModal } from '../../../components';


const CreditScore = (props) => {
    const [showHistory, setShowHistory] = useState(false);
    const { originType, accessToRequestFinanceCreditScore, contact, handleRequestCreditScore, loaderCreditScore, clientUsers, dealersettings } = props
    let masterScale = contact?.creditScore?.resp?.masterscale
    let _data = masterScale ? defaultCreditScore.filter(e => masterScale >= e.lowScore && masterScale <= e.highScore)[0] : null
    return (
        <>

            <div className="mid-column-creditscore-new">
                <h2>Credit Score</h2>

                {accessToRequestFinanceCreditScore && (<div className="mid-column-creditscore-new-button">
                    <button type="button" className="btn btn-secondary float-right"
                        onClick={(e) => {
                            e.preventDefault();
                            handleRequestCreditScore();
                        }}
                    >
                        {loaderCreditScore && (<span className="spinner-border spinner-button mr-2" role="status" aria-hidden="true"></span>)}
                        {'Request Credit Score'}
                    </button>
                </div>)}
                {!_.isEmpty(contact?.creditScore?.resp) && (<div className="mid-column-creditscore-history-button">
                    <button type="button" className="btn btn-default float-right mr-2 " onClick={(e) => {
                        e.preventDefault();
                        setShowHistory(true)
                    }}>
                        <i className="ico icon-history"></i> History
                    </button>
                </div>)}

                <div className="mid-column-creditscore-new-inner form-style">
                    <div className="form-row mid-column-form-row-flex">
                        <div className="form-group col-md-12 flex-full">
                            <div className="form-group col-md-4">
                                <div className="form-group-bgfix-flex">


                                    <div className="form-group-score-graph">
                                        <GaugeComponent
                                            id={`finance-guage-chart${originType || '-band'}`}
                                            minValue={0}
                                            style={{
                                                height: 200,
                                                width: 250,
                                                marginBottom: '-20px',
                                                marginTop: '-20px'
                                            }}
                                            maxValue={1000}
                                            value={_.round(masterScale || 0)}
                                            type="radial"
                                            labels={{
                                                valueLabel: {
                                                    style: { fontSize: "35px", fill: "#21130d" }
                                                },
                                                tickLabels: {
                                                    type: "inner",
                                                    defaultTickValueConfig: {
                                                        style: { fill: "#21130d" }
                                                    },
                                                    ticks: [
                                                        { value: 300 },
                                                        { value: 500 },
                                                        { value: 700 },
                                                        { value: 800 },
                                                        { value: 1000 }
                                                    ]
                                                }
                                            }}
                                            arc={{
                                                //colorArray: ['#f00c01', '#fd6420', '#ffa400', '#95ae03', '#57b10e'],
                                                //subArcs: [{ limit: 300 }, { limit: 500 }, { limit: 700 }, { limit: 800 }, { limit: 1000 }],
                                                nbSubArcs: 90,
                                                padding: 0.01,
                                                width: 0.4,
                                                subArcs: [
                                                    {
                                                        limit: 300,
                                                        color: '#f00c01',
                                                        tooltip: { text: 'Very Poor' },
                                                        showTick: true
                                                    },
                                                    {
                                                        limit: 500,
                                                        color: '#fd6420',
                                                        tooltip: { text: 'Poor' },
                                                        showTick: true
                                                    },
                                                    {
                                                        limit: 700,
                                                        color: '#ffa400',
                                                        tooltip: { text: 'Fair' },
                                                        showTick: true
                                                    },
                                                    {
                                                        limit: 800,
                                                        color: '#95ae03',
                                                        tooltip: { text: 'Good' },
                                                        showTick: true
                                                    },
                                                    {
                                                        limit: 1000,
                                                        color: '#57b10e',
                                                        tooltip: { text: 'Excellent' },
                                                        showTick: true
                                                    }
                                                ],
                                                padding: 0.02,
                                                width: 0.3
                                            }}
                                            pointer={{
                                                elastic: true,
                                                type: 'needle',
                                                animationDelay: 0
                                            }}
                                        />
                                    </div>


                                </div>
                            </div>
                            <div className="form-group col-md-8">
                                <div className="form-group-right-flex flow-row">



                                    <div className="form-group-score-value-wrap"> <label>Risk Odds </label>

                                        <span className="form-group-score-value ">{contact?.creditScore?.resp?.['risk_odds'] || 'n/a'}</span></div>

                                    <div className="form-group-score-value-wrap"> <label>Status </label>

                                        <span className="form-group-score-value ">{_data?.title || 'n/a'}</span></div>
                                    <div className="form-group-score-value-wrap"> <label > Date </label>

                                        <span className="form-group-score-value ">{contact?.creditScore?.['dt'] ? moment.unix(contact.creditScore['dt'].seconds).format('DD MMM YYYY') : 'n/a'}</span></div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <PopUpModal show={showHistory}>
                <CreditScoreHistory
                    show={showHistory}
                    contact={contact}
                    handleClose={() => { setShowHistory(false) }}
                    dealersettings={dealersettings}
                    clientUsers={clientUsers}
                ></CreditScoreHistory>
            </PopUpModal>
        </>
    )
}

export default CreditScore