import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from '../../dashboard/pipelineCounts'
import { PopUpModal } from '../../../components'
import EnquiryList from '../../dashboard/enquiryList'
import ActivityList from '../../dashboard/activitiesList'
import { initialLayoutArr, mclarenLayoutArr } from './viewModel';
import TestDriveList from '../../dashboard/testdriveList'
import TradeInList from '../../dashboard/tradeinList'
import { default as _images } from '../../../images';
import Swal from 'sweetalert2';
import CommonHelper from '../../../services/common';
import DragDropLayout from './dragDropLayout';

const defaulModelState = {
    enquiry: false,
    activity: false,
    testdrive: false,
    tradein: false
}

const DashboardSales = (props) => {
    const [loader, setLoader] = useState(false)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdContacts, setContacts] = useState([]);
    const [selectedPipeline, setPipeline] = useState();
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard ? { value: props.dealersettings.id } : null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [applydateRange, setApplyDateRange] = useState(false)
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState(true)
    const [dashboardFilter, setDashboardFilter] = useState({})
    const isMclaren = !_.isEmpty(props.dealersettings) && props.dealersettings.isMclaren === true ? true : false


    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/oemDashboardSettings/${localStorage.uid}`);
    const enquiryStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.settings) &&
        props.dealersettings.client.settings.enquiryStatus) ? props.dealersettings.client.settings.enquiryStatus.filter(a => Boolean(a.active)) : []);

    let _dynStatus = enquiryStatus.filter(a => a.value !== 'prospectLost').map(r => { return r.value });
    const allStats = ['new', ..._dynStatus, 'delivery', 'pendingdelivery', 'testdrive', 'tradein'];
    const staticDashboard = isMclaren;

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])

    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (staticDashboard === true) {
                setLayout(sortedMclarenLayout(mclarenLayoutArr))
                setLayoutLoader(false)
            }
            else if (data && !_.isEmpty(data.dashboardLayout)) {
                setLayout(data.dashboardLayout.filter(a => a.i !== 'null'));
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleShowEnquiryList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Enquiry List')
        setModelShow({
            ...defaulModelState,
            enquiry: true,
            counts
        })
    }

    const handleShowActivityList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Activities List')
        setModelShow({
            ...defaulModelState,
            activity: true,
            counts
        })
    }

    const handleShowTestDriveList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Test Drive List')
        setModelShow({
            ...defaulModelState,
            testdrive: true,
            counts
        })
    }

    const handleShowTradeinList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Trade-In List')
        setModelShow({
            ...defaulModelState,
            tradein: true,
            counts
        })
    }

    const onLayoutChange = async (_layout) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.filter(a => a.i !== 'null').map((rec, index) => {
            return {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
                minW: 4,
                h: (props.dealersettings.isMclaren === true && rec.i === 'DealerTarget' && rec.h < 22) ? 22 : (props.dealersettings.isMclaren === false && rec.i === 'DealerTarget' && rec.h >= 22) ? 11 : (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
                w: (props.dealersettings.isMclaren === true && rec.i === 'DealerTarget' && rec.w < 8) ? 8 : (props.dealersettings.isMclaren === false && rec.i === 'DealerTarget' && rec.w >= 8) ? 4 : (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        if (staticDashboard === true) {
            setLayout(sortedMclarenLayout(mclarenLayoutArr))
        } else {
            setLayout(_dLayout)
        }
        try {
            await userRef.set({
                dashboardLayout: _dLayout,
            }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const sortedMclarenLayout = (mclarenLayoutArr) => {
        let _layout = mclarenLayoutArr.filter(a => a.x >= 0);
        let i = 0;
        let lastX = 0;
        let pipelines = props.pipeline.filter(a => a.value !== 'LeadsBucket');
        mclarenLayoutArr.filter(a => a.x < 0).forEach(rec => {
            let lastY = _.last(_layout).y;
            if (lastX === 0) lastY += 11;
            _layout.push({ ...rec, w: 4, h: 11, x: lastX, y: lastY })
            if (!_.isEmpty(pipelines[i])) {
                _layout.push({ i: `SalesFunnel-${pipelines[i].name}`, w: 4, h: 11, x: lastX + 4, y: lastY })
                _layout.push({ i: `SalesFunnel-${pipelines[i].name}-OVERALL`, w: 4, h: 11, x: lastX + 8, y: lastY })
                lastX = 12;
            } else {
                lastX += 4;
            }
            if (lastX === 12) lastX = 0
            i++;
        })
        return _layout;
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        handleShowEnquiryList={handleShowEnquiryList}
                        handleShowActivityList={handleShowActivityList}
                        handleShowTestDriveList={handleShowTestDriveList}
                        handleShowTradeinList={handleShowTradeinList}
                        onLayoutChange={onLayoutChange}
                        layout={layout}
                        setLayout={setLayout}
                        dashboardFilter={dashboardFilter}
                        enquiryStatus={enquiryStatus}
                        sortedStats={allStats}
                        dateRange={dateRange}
                        applydateRange={applydateRange}
                        fromOEM={true}
                        setDashboardFilter={(val) => {
                            setDashboardFilter(val)
                            if (val?.daterange) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.startDate.toDate()).startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.endDate.toDate()).endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            } else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {(layoutLoader) ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center text-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border text-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                handleShowEnquiryList={handleShowEnquiryList}
                                handleShowActivityList={handleShowActivityList}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                initialLayout={layout}
                                enquiryStatus={enquiryStatus}
                                isMclaren={isMclaren}
                                dashboardFilter={dashboardFilter}
                                fromOEM={true}
                                staticDashboard={staticDashboard}
                            />
                        )
                    }
                </div>
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={pipelineEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    fromOEM={true}
                    dashboardFilter={dashboardFilter}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={allActivities}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    fromOEM={true}
                    dashboardFilter={dashboardFilter}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTestdrives}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                    fromOEM={true}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.tradein}>
                <TradeInList
                    {...props}
                    show={modelShow.tradein}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTradeins}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                    fromOEM={true}
                ></TradeInList>
            </PopUpModal>
        </div>
    )
}

export default DashboardSales