import React, { useState, useEffect } from 'react';
import { TableView } from '../../../../components';
import { allcampaignFields } from '../viewModel';
import _ from 'lodash'
import { PopUpModal, ContentOverlay } from '../../../../components';
import CampaignPreview from '../campaignPreview';
import Translate from '../../../../constants/translate';

const CampaignsList = ({ activeTab, campaigns, setCampaigns, showAddCampaign, setShowAddCampaign, apiUrl, onCreateCampaign, loader, refresh_token, client_id, client_secret }) => {
	const [searchText, setSearchText] = useState('');
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 330,
		windowHeight: window.innerHeight - 273,
	});
	const [showPreview, setShowPreview] = useState({
		show: false,
		previewID: '',
		title: '',
		campaign: null
	})
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 330,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	const handleTemplateModalClose = () => {
		setShowPreview({
			show: false,
			previewID: '',
			title: 'Campaign Info'
		})
	}

	const handleActionClick = id => {
		let currentCampaign = campaigns.filter(campaign => campaign.documentID === id)[0]

		return (
			<>
				{currentCampaign.status === 'sent' || currentCampaign.status === 'sending' ? (
					<div
						style={{ cursor: 'pointer' }}
						className='mini-button mr-1'
						onClick={e => {
							setShowPreview({
								show: true,
								campaign: currentCampaign,
								title: 'Campaign Info'
							})
						}}
					>
						<i className='ico icon-zoom'></i>
					</div>
				) : (
					<></>
				)}
			</>
		);
	};

	const handleCampaigndetailsClick = (e, id) => {
		e.preventDefault();
		let currentCampaign = campaigns.filter(campaign => campaign.documentID === id)[0]

		if (currentCampaign.status === 'sent' || currentCampaign.status === 'sending') {
			setShowPreview({
				show: true,
				previewID: currentCampaign.documentID,
				title: 'Campaign Info'
			})
		} else {
			setShowAddCampaign({
				...showAddCampaign,
				show: true,
				title: 'Edit Campaign',
				campaignID: currentCampaign.documentID,
			})
		}

		// this one will be called when opening a edit or preview/report modal

		// props.history.push("/contacts/details/" + id);
	};

	const filterCampaigns = (campaign) => {
		let returnVal = true

		if (activeTab === 'ongoing' && campaign.status !== 'sending') {
			returnVal = false
		} else if (activeTab === 'draft' && campaign.status !== 'save') {
			returnVal = false
		} else if (activeTab === 'completed' && campaign.status !== 'sent') {
			returnVal = false
		}

		if (searchText.length > 0 && !campaign.settings.title.includes(searchText)) {
			returnVal = false
		}
		return returnVal
	}

	return (
		<div className='campaign-right-panel'>
			<div className='campaign-list-container'>
				<h2>All Campaigns</h2>
				<div className='divider-line'></div>
				<div className='campaign-list-filter'>
					<div className='float-left'>
						<div className='filter-search search-icon'>
							<input placeholder='search' aria-label='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
						</div>
						{/* <div className='sort-btn float-left ml-2 dropdown'>
							{' '}
							<a href='#' className='dropdown-toggle'>
								<span id='spn-sort-name' data-name='addedDate'>
									Created On
								</span>
								<i className='ico icon-dropdown'></i>
							</a>{' '}
							<a href='#' className='sort-ico'>
								{' '}
								<i
									id='spn-sort-order'
									data-name='desc'
									className='ico icon-sort-up'
								></i>{' '}
							</a>{' '}
						</div> */}
					</div>
				</div>
				<div className='campaign-list-table'>
					{loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
						<div className='common-table'>
							{campaigns.length > 0 ? (
								<TableView
									isReorderable={false}
									datalist={campaigns.filter(campaign => filterCampaigns(campaign))}
									height={windowSize.windowHeight}
									width={windowSize.windowWidth}
									columns={allcampaignFields}
									dynamicFields={allcampaignFields
										.filter(e => e.default === true)
										.map(v => _.pick(v, ['value']).value)}
									settingsLoader={false}
									handleActionClick={handleActionClick}
									handleRowSingleClick={handleCampaigndetailsClick}
									isSettings={false}
									hasMore={false}
									isPaging={false}
								/>
							) : (
								<div className="text-center p-5">
									<p><Translate text={'No campaigns found'} /></p>
									<button className="btn btn-sm btn-default" onClick={onCreateCampaign}><i className="ico icon-add mr-2 pt-1 float-left"></i>
										<Translate text={'Create Campaign'} />
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<PopUpModal show={showPreview.show}>
				<CampaignPreview
					{...showPreview}
					handleClose={handleTemplateModalClose}
					apiUrl={apiUrl}
					refresh_token={refresh_token}
					client_id={client_id}
					client_secret={client_secret}
				/>
			</PopUpModal>
		</div>
	);
};

export default CampaignsList;
