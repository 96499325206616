import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
import { optionsVM, genuineAccessoriesVM, lessDepositVM } from '../viewModel'
import commonHelper from '../../../services/common'
import Translate from '../../../constants/translate';
import { TextEditor } from '../../../components';

import { default as _images } from '../../../images';
import Swal from 'sweetalert2';
import './quote.scss';
//import Signatures from './sign';
import { AntDatePicker } from '../../../components';

const QuoteForm = ({
	history,
	setActiveTab,
	setNewlySavedData,
	client,
	setClient,
	setClientSettings,
	clientSettings,
	currencySymbol,
	setCurrencySymbol,
	_type,
	_previewID,
	newlySavedData
}) => {
	const [template, setTemplate] = useState({
	})
	const [errorFields, setErrorFields] = useState({});
	const [loader, setLoader] = useState(true);
	const [saveLoader, setSaveLoader] = useState(false);
	const [editTitle, setEditTitle] = useState({
		show: false,
		title: 'Vehicle Contract'
	})

	//const [contact, setContact] = useState({});sameer
	//const [orderDetails, setOrderDetails] = useState({});sameer
	const [vehicleInfo, setVehicleInfo] = useState({});
	const [state, setState] = useState({
		gstPercentage: 0,
		subTotalExGST: 0,
		subTotalExGSTDiscounts: 0,
		gstPayable: 0,
		subTotalIncGST: 0,
		totalIncGST: 0,
		totalDeposit: 0,
		totalTrades: 0,
		payableOnDelivery: 0,
		title: 'Vehicle Contract',
		quotationStatus: 'quote',
		type: 'vehicleContract' // or automotiveService
	});

	const [signatures, setSignatures] = useState({
		signatureSalesExecutive: '',
		signatureSalesManager: '',
		signatureCustomer: '',
	})

	// const [generic, setGeneric] = useState({
	// 	status: ''
	// })

	//const [enquiry, setEnquiry] = useState()
	//const [stock, setStock] = useState()
	//const [contactData, setContactData] = useState()
	//const [preview, setPreview] = useState()

	const [editPrice, setEditPrice] = useState(false)

	const [showOptions, setShowOptions] = useState(true)
	const [options, setOptions] = useState([]);
	const [optionInput, setOptionInput] = useState(optionsVM)
	const [optionsEdit, setOptionsEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	//sameer
	// const [showAllowance, setShowAllowance] = useState(true)
	// const [allowance, setAllowance] = useState([]);
	// const [allowanceInput, setAllowanceInput] = useState(optionsVM);
	// const [allowanceEdit, setAllowanceEdit] = useState({
	// 	editID: null,
	// 	show: true,
	// 	...optionsVM
	// })

	const [showdealerdelivery, setShowdealerdelivery] = useState(true)
	const [dealerdelivery, setdealerdelivery] = useState([]);
	const [dealerdeliveryInput, setdealerdeliveryInput] = useState(optionsVM);
	const [dealerdeliveryEdit, setdealerdeliveryEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	// const [showtrades, setShowtrades] = useState(true)
	// const [trades, settrades] = useState([]);
	// const [tradesInput, settradesInput] = useState(optionsVM);
	// const [tradesEdit, settradesEdit] = useState({
	// 	editID: null,
	// 	show: true,
	// 	...optionsVM
	// })

	const [showdiscounts, setShowdiscounts] = useState(true)
	const [discounts, setdiscounts] = useState([]);
	const [discountsInput, setdiscountsInput] = useState(optionsVM);
	const [discountsEdit, setdiscountsEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [showservices, setShowservices] = useState(true)
	const [services, setservices] = useState([]);
	const [servicesInput, setservicesInput] = useState(optionsVM);
	const [servicesEdit, setservicesEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [showinsurance, setShowinsurance] = useState(true)
	const [insurance, setinsurance] = useState([]);
	const [insuranceInput, setinsuranceInput] = useState(optionsVM);
	const [insuranceEdit, setinsuranceEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [showVatExclusiveItems, setShowVatExclusiveItems] = useState(true)
	const [vatExclusiveItems, setVatExclusiveItems] = useState([]);
	const [vatExclusiveItemsInput, setVatExclusiveItemsInput] = useState(optionsVM);
	const [VatExclusiveItemsEdit, setVatExclusiveItemsEdit] = useState({
		editID: null,
		show: true,
		...optionsVM
	})

	const [showAccessories, setShowAccessories] = useState(true)
	const [accessories, setAccessories] = useState([]);
	const [accessoriesInput, setAccessoriesInput] = useState(genuineAccessoriesVM);
	const [AccessoriesEdit, setAccessoriesEdit] = useState({
		editID: null,
		show: true,
		...genuineAccessoriesVM
	})

	const [showAfterMarket, setShowAfterMarket] = useState(true)
	const [afterMarket, setAfterMarket] = useState([]);
	const [afterMarketInput, setAfterMarketInput] = useState(genuineAccessoriesVM);
	const [AfterMarketEdit, setAfterMarketEdit] = useState({
		editID: null,
		show: true,
		...genuineAccessoriesVM
	})
	//sameer
	// const [showLessDeposit, setShowLessDeposit] = useState(true)
	// const [lessDeposit, setLessDeposit] = useState([]);
	// const [lessDepositInput, setLessDepositInput] = useState(lessDepositVM);
	// const [LessDepositEdit, setLessDepositEdit] = useState({
	// 	editID: null,
	// 	show: true,
	// 	...lessDepositVM
	// })

	//let enquiryID = null;sameer

	let _clientID = history.location.pathname.replace(
		'/stock/quote/',
		'',
	);

	const onEditorStateChange = (obj) => {
		const templateJSON = JSON.stringify(obj.rawData)
		let data = templateJSON ? JSON.parse(templateJSON) : '';
		let text = '';
		if (!_.isEmpty(data) && !_.isEmpty(data.blocks)) {
			data.blocks.forEach(ele => {
				text += ele.text + ' ';
			});
		}
		text = text.trim()
		const textMessage = text
		setTemplate({
			...template,
			[obj.name]: obj.value,
			templateJSON,
			textMessage
		});
		setState({
			...state,
			notes: textMessage
		})
	}

	const parseIncomingDate = date => {
		// console.log(date)
		if (date.toDate) {
			return date
		} else {
			return window.firebase.firestore.Timestamp.fromDate(moment(date)._d)
		}
	};

	// fetch enqruiry details
	useEffect(() => {
		const getData = async () => {
			// console.log('enquiryID', enquiryID);
			try {
				//let companyData = null
				//sameer
				// const enquiryRef = window.firebase.firestore().doc(`enquiries/${enquiryID}`);
				// const enquirySnap = await enquiryRef.get();
				// const enquiryData = enquirySnap.data();
				// setEnquiry(enquiryData)
				//sameer
				// let contactID = enquiryData.contactID ? enquiryData.contactID : (!_.isEmpty(enquiryData.contact) && !_.isEmpty(enquiryData.contact.documentID)) ? enquiryData.contact.documentID : ''
				// const contactSnap = await window.firebase
				// 	.firestore()
				// 	.doc(`contactDetails/${contactID}`)
				// 	.get();
				// const contactData = contactSnap.data();
				// setContactData(contactData)

				// if (contactData.businessContact) {
				// 	const companyRef = window.firebase.firestore().doc(`companies/${contactData.company.documentID}`);
				// 	const companySnap = await companyRef.get();
				// 	companyData = companySnap.data();
				// }

				const clientSnap = await window.firebase.firestore().doc(`clients/${_clientID}`).get()
				const clientData = clientSnap.data();

				const clientSettingsSnap = await window.firebase.firestore().doc(`clients/${_clientID}/currentSettings/${_clientID}`).get()
				const clientSettingsData = clientSettingsSnap.data();

				setCurrencySymbol(((!_.isEmpty(clientData) &&
					!_.isEmpty(clientData.currency)) ? clientData.currency.symbol + ' ' : '$ '))
				setClient(clientData);
				setClientSettings(clientSettingsData)
				let stateToBeSet = {}

				if (!_.isEmpty(clientData) && !_.isEmpty(clientData.clientSettings) && !_.isEmpty(clientData.clientSettings.quotation)) {
					stateToBeSet = {
						...stateToBeSet,
						gstPercentage: clientData.clientSettings.quotation.tax,
						taxType: clientData.clientSettings.quotation.taxType ? clientData.clientSettings.quotation.taxType : 'VAT'
					}
				}

				if (_type === 'new' && _.isEmpty(newlySavedData)) {
					let type = 'vehicleContract'
					let title = 'Vehicle Contract'

					//sameer
					// if (clientData.requirementType === 'automotiveServices' || enquiryData.pipeline === 'AfterSales') {
					// 	type = 'automotiveService'
					// 	title = 'Service Quotation'
					// } else {
					// 	type = 'vehicleContract'
					// 	title = 'Vehicle Contract'
					// }
					//sameer

					stateToBeSet = {
						...stateToBeSet,
						type,
						title
					}

					//sameer
					// const stockRef = window.firebase.firestore().doc(`enquiries/${enquiryID}/requirements/${_stockID}`);
					// const stockSnap = await stockRef.get();
					// const stockData = stockSnap.data();

					//sameer
					// if (type === 'vehicleContract') {
					// 	const tradeinsRef = window.firebase.firestore().collection(`tradeins`)
					// 		.where('isDeleted', '==', false)
					// 		.where('enquiryID', '==', enquiryID)

					// 	const tradeinsSnap = await tradeinsRef.get();
					// 	const tradeinsData = []
					// 	tradeinsSnap.forEach((doc, index) => {
					// 		// console.log(doc.id, '=>', doc.data());
					// 		let data = doc.data()
					// 		const id = `${new Date().valueOf().toString()}${index}`
					// 		let _data = { item: `${data.make} ${data.model} ${data.year}`, amount: 0, id, addedDate: window.firebase.firestore.Timestamp.now() }
					// 		tradeinsData.push(_data);
					// 	});
					// 	if (!_.isEmpty(tradeinsData)) {
					// 		//	console.log('these are tradins', tradeinsData)
					// 		settrades(tradeinsData)
					// 	}
					// }

					// setStock(stockData)
					// let requirement = {}

					// if (!_.isEmpty(stockData)) {
					// 	if (!_.isEmpty(stockData.stock)) {
					// 		requirement = stockData.stock
					// 	} else if (stockData.make && stockData.model) {
					// 		requirement = stockData
					// 	}
					// }
					// //	console.log('this is requirement', requirement)

					// if (!_.isEmpty(requirement) && !_.isEmpty(requirement.addOns) && type !== 'vehicleContract') {
					// 	const addOns = requirement.addOns
					// 	const _services = addOns.map(addOn => {
					// 		return { item: addOn.name, amount: addOn.price, id: addOn.value, addedDate: window.firebase.firestore.Timestamp.now(), titleReadonly: true }
					// 	})
					// 	setservices(_services)
					// }

					//sameer
					// if (!_.isEmpty(enquiryData) && enquiryData.depositAmount && enquiryData.depositReciptNumber) {
					// 	setLessDeposit([...lessDeposit, { date: window.firebase.firestore.Timestamp.now(), reference: enquiryData.depositReciptNumber, amount: enquiryData.depositAmount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					// }

					//sameer
					// if (contactData.businessContact) {
					// 	setContact({
					// 		name: companyData.name ? companyData.name : '',
					// 		phone: companyData.phone ? companyData.phone : '',
					// 		email: companyData.email ? companyData.email : '',
					// 		address: companyData.address ? companyData.address : '',
					// 		businessContact: true,
					// 	});
					// } else {
					// 	setContact({
					// 		firstName: contactData.firstName ? contactData.firstName : '',
					// 		lastName: contactData.lastName ? contactData.lastName : '',
					// 		phone: contactData.phone ? contactData.phone : '',
					// 		email: contactData.email ? contactData.email : '',
					// 		dob: contactData.dob ? contactData.dob : '',
					// 		licenseNo: contactData.licenseNo ? contactData.licenseNo : '',
					// 		address: contactData.address ? contactData.address : '',
					// 		businessContact: false,
					// 	});
					// }

					// setOrderDetails({
					// 	leadID: enquiryData.displayID ? enquiryData.displayID : '',
					// 	orderNo: '',
					// 	orderDate: '',
					// 	estDelivery: enquiryData.deliveryDate ? parseIncomingDate(enquiryData.deliveryDate) : '',
					// 	leadOwner: enquiryData.owner ? commonHelper.getUserNamebyId(clientSettingsData.users, enquiryData.owner) : '',
					// 	status: enquiryData.status ? enquiryData.status : ''
					// });
					// setVehicleInfo({
					// 	make: requirement.make ? requirement.make : '',
					// 	model: requirement.model ? requirement.model : '',
					// 	type: requirement.type ? requirement.type : '',
					// 	year: requirement.year ? requirement.year : '',
					// 	saleType: requirement.saleType ? commonHelper.getNameByValue(clientSettingsData && clientSettingsData.settings && clientSettingsData.settings.salesType,
					// 		requirement.saleType, '') : '',
					// 	rego: requirement.regNo ? requirement.regNo : '',
					// 	regoExpiry: requirement.regDate ? parseIncomingDate(requirement.regDate) : '',
					// 	vinNo: requirement.vinNo ? requirement.vinNo : '',
					// 	engineNo: requirement.engineNo ? requirement.engineNo : '',
					// 	extColor: requirement.extColor ? requirement.extColor : '',
					// 	stockNo: requirement.stockNo ? requirement.stockNo : '',
					// 	buildDate: requirement.buildDate ? parseIncomingDate(requirement.buildDate) : '',
					// 	odometer: requirement.miles ? requirement.miles : '',
					// 	purchasePrice: type === 'vehicleContract' ? requirement.purchasePrice ? requirement.purchasePrice : requirement.price ? requirement.price : 0 : 0,
					// });
					//sameer
					// setGeneric({
					// 	status: enquiryData.status ? enquiryData.status : ''
					// })
					let search = window.location.search;
					let params = new URLSearchParams(search);

					let _make = params.get('mk');
					let _model = params.get('ml');
					let _vehicletype = params.get('ty');
					let _group = params.get('gp');
					setVehicleInfo({
						make: _make ? _make : '',
						model: _model ? _model : '',
						type: _vehicletype ? _vehicletype : '',
						purchasePrice: 0,
					});

				} else if ((_type === 'edit' && _previewID) || (_type === 'new' && !_.isEmpty(newlySavedData))) {
					console.log('edit', newlySavedData)
					let quoteData = {}
					if (!_.isEmpty(newlySavedData)) {
						quoteData = newlySavedData
					} else {
						const quoteRef = window.firebase.firestore().doc(`stockQuotations/${_previewID}`);
						const quoteSnap = await quoteRef.get();
						quoteData = quoteSnap.data();
					}
					//sameer
					//setPreview(quoteData)

					// convert the dates 
					// console.log('quoteData before', quoteData)
					// if (quoteData.vehicle.regoExpiry) {
					// 	quoteData.vehicle.regoExpiry = window.firebase.firestore.Timestamp.fromDate(new Date(quoteData.vehicle.regoExpiry))
					// }
					// if (quoteData.vehicle.buildDate) {
					// 	quoteData.vehicle.buildDate = window.firebase.firestore.Timestamp.fromDate(new Date(quoteData.vehicle.buildDate))
					// }
					//sameer
					// if (quoteData.orderDetails && quoteData.orderDetails.orderDate) {
					// 	quoteData.orderDetails.orderDate = window.firebase.firestore.Timestamp.fromDate(new Date(quoteData.orderDetails.orderDate))
					// }
					// if (quoteData.orderDetails && quoteData.orderDetails.estDelivery) {
					// 	quoteData.orderDetails.estDelivery = window.firebase.firestore.Timestamp.fromDate(new Date(quoteData.orderDetails.estDelivery))
					// }

					//sameer
					// if (!_.isEmpty(quoteData.lessDeposit)) {
					// 	quoteData.lessDeposit = quoteData.lessDeposit.map(item => {
					// 		let _date = window.firebase.firestore.Timestamp.fromDate(new Date(item.date))
					// 		return { ...item, date: _date }
					// 	})
					// }

					// const stockRef = window.firebase.firestore().doc(`enquiries/${enquiryID}/requirements/${quoteData.requirementID}`);
					// const stockSnap = await stockRef.get();
					// const stockData = stockSnap.data();
					// setStock(stockData)
					// let requirement = {}

					// if (!_.isEmpty(stockData)) {
					// 	if (!_.isEmpty(stockData.stock)) {
					// 		requirement = stockData.stock
					// 	} else if (stockData.make && stockData.model) {
					// 		requirement = stockData
					// 	}
					// }

					//sameer
					// if (!_.isEmpty(enquiryData) && enquiryData.depositAmount && enquiryData.depositReciptNumber) {
					// 	setLessDeposit([...lessDeposit, { date: window.firebase.firestore.Timestamp.now(), reference: enquiryData.depositReciptNumber, amount: enquiryData.depositAmount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					// }

					// if (quoteData.orderDetails && enquiryData.status) {
					// 	quoteData.orderDetails.status = enquiryData.status
					// }
					//sameer
					// if (quoteData.orderDetails) {
					// 	setOrderDetails(quoteData.orderDetails);
					// }
					//setContact(quoteData.contact); sameer
					setVehicleInfo(quoteData.vehicle);
					//sameer
					// setGeneric({
					// 	status: enquiryData.status ? enquiryData.status : ''
					// })
					stateToBeSet = {
						...stateToBeSet,
						subTotalExGST: quoteData.subTotalExGST,
						subTotalExGSTDiscounts: quoteData.subTotalExGSTDiscounts ? quoteData.subTotalExGSTDiscounts : 0,
						gstPayable: quoteData.gstPayable,
						subTotalIncGST: quoteData.subTotalIncGST,
						totalIncGST: quoteData.totalIncGST,
						totalDeposit: quoteData.totalDeposit,
						totalTrades: quoteData.totalTrades,
						payableOnDelivery: quoteData.payableOnDelivery,
						displayID: quoteData.displayID,
						documentID: quoteData.documentID,
						//enquiryID: quoteData.enquiryID,sameer
						isDeleted: quoteData.isDeleted,
						//requirementID: quoteData.requirementID,sameer
						addedBy: quoteData.addedBy,
						modifiedBy: quoteData.modifiedBy,
						addedDate: quoteData.addedDate,
						modifiedDate: quoteData.modifiedDate,
						title: quoteData.title ? quoteData.title : "",
						type: quoteData.type ? quoteData.type : "vehicleContract",
						notes: quoteData.notes ? quoteData.notes : " ",
						quotationStatus: quoteData.quotationStatus ? quoteData.quotationStatus : "",
						modifiedFrom: 'web',
					}
					if (quoteData.type === 'vehicleContract') {
						setSignatures({
							signatureSalesExecutive: quoteData.signatureSalesExecutive,
							signatureSalesManager: quoteData.signatureSalesManager,
							signatureCustomer: quoteData.signatureCustomer,
						})
						setAccessories(quoteData.accessories ? quoteData.accessories : [])
						setAfterMarket(quoteData.afterMarket ? quoteData.afterMarket : [])
						setOptions(quoteData.options ? quoteData.options : [])
						setdealerdelivery(quoteData.dealerDelivery ? quoteData.dealerDelivery : [])
						//settrades(quoteData.trades ? quoteData.trades : [])sameer
						setinsurance(quoteData.otherInsurance ? quoteData.otherInsurance : [])
						//setAllowance(quoteData.allowance ? quoteData.allowance : [])sameer
						setVatExclusiveItems(quoteData.vatExclusiveItems ? quoteData.vatExclusiveItems : [])
						//setLessDeposit(quoteData.lessDeposit ? quoteData.lessDeposit : [])sameer
						setTemplate({ bodyHTML: quoteData.notes ? quoteData.notes : ' ' })
					} else {
						//setLessDeposit(quoteData.lessDeposit ? quoteData.lessDeposit : [])sameer
						setTemplate({ bodyHTML: quoteData.notes ? quoteData.notes : ' ' })
						setdiscounts(quoteData.discounts ? quoteData.discounts : [])
						setservices(quoteData.services ? quoteData.services : [])
					}
				}

				setState(stateToBeSet)
				setLoader(false)
			} catch (error) {
				console.error(error)
				setLoader(false)
			}
		};

		getData();
		// console.log('enquiryID', enquiryID);
	}, [newlySavedData]);

	useEffect(() => {
		if (loader) {
			return
		}
		let optionsTotal = 0
		let discountsTotal = 0
		let servicesTotal = 0
		let allowanceTotal = 0
		let accessoriesTotal = 0
		let dealerdeliveryTotal = 0
		let totalTrades = 0
		let insuranceTotal = 0
		let afterMarketTotal = 0
		let vatExclusiveItemsTotal = 0
		let totalDeposit = 0
		let vehiclePrice = vehicleInfo.purchasePrice ? vehicleInfo.purchasePrice : 0
		vehiclePrice = Math.round((vehiclePrice + Number.EPSILON) * 100) / 100

		if (!_.isEmpty(options)) {
			optionsTotal = options.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		if (!_.isEmpty(discounts)) {
			discountsTotal = discounts.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		if (!_.isEmpty(services)) {
			servicesTotal = services.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		if (!_.isEmpty(dealerdelivery)) {
			dealerdeliveryTotal = dealerdelivery.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		//sameer
		// if (!_.isEmpty(trades)) {
		// 	totalTrades = trades.reduce((accumulator, currentValue) => {
		// 		return accumulator + currentValue.amount;
		// 	}, 0);
		// }

		if (!_.isEmpty(insurance)) {
			insuranceTotal = insurance.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		// if (!_.isEmpty(allowance)) {
		// 	allowanceTotal = allowance.reduce((accumulator, currentValue) => {
		// 		return accumulator + currentValue.amount;
		// 	}, 0);
		// }

		if (!_.isEmpty(accessories)) {
			accessoriesTotal = accessories.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		if (!_.isEmpty(afterMarket)) {
			afterMarketTotal = afterMarket.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		if (!_.isEmpty(vatExclusiveItems)) {
			vatExclusiveItemsTotal = vatExclusiveItems.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.amount;
			}, 0);
		}

		//sameer
		// if (!_.isEmpty(lessDeposit)) {
		// 	totalDeposit = lessDeposit.reduce((accumulator, currentValue) => {
		// 		return accumulator + currentValue.amount;
		// 	}, 0);
		// }

		optionsTotal = Math.round((optionsTotal + Number.EPSILON) * 100) / 100
		discountsTotal = Math.round((discountsTotal + Number.EPSILON) * 100) / 100
		servicesTotal = Math.round((servicesTotal + Number.EPSILON) * 100) / 100
		allowanceTotal = Math.round((allowanceTotal + Number.EPSILON) * 100) / 100
		accessoriesTotal = Math.round((accessoriesTotal + Number.EPSILON) * 100) / 100
		dealerdeliveryTotal = Math.round((dealerdeliveryTotal + Number.EPSILON) * 100) / 100
		totalTrades = Math.round((totalTrades + Number.EPSILON) * 100) / 100
		insuranceTotal = Math.round((insuranceTotal + Number.EPSILON) * 100) / 100
		afterMarketTotal = Math.round((afterMarketTotal + Number.EPSILON) * 100) / 100
		vatExclusiveItemsTotal = Math.round((vatExclusiveItemsTotal + Number.EPSILON) * 100) / 100
		totalDeposit = Math.round((totalDeposit + Number.EPSILON) * 100) / 100

		let subTotalExGST = vehiclePrice + optionsTotal + servicesTotal + accessoriesTotal + afterMarketTotal + dealerdeliveryTotal
		let subTotalExGSTDiscounts = subTotalExGST
		subTotalExGST = subTotalExGST - allowanceTotal - discountsTotal
		subTotalExGST = Math.round((subTotalExGST + Number.EPSILON) * 100) / 100
		let gstPercentage = state.gstPercentage
		let gstPayable = (gstPercentage / 100) * subTotalExGST;
		gstPayable = Math.round((gstPayable + Number.EPSILON) * 100) / 100
		let subTotalIncGST = gstPayable + subTotalExGST
		subTotalIncGST = Math.round((subTotalIncGST + Number.EPSILON) * 100) / 100

		let totalIncGST = subTotalIncGST + vatExclusiveItemsTotal + insuranceTotal
		totalIncGST = Math.round((totalIncGST + Number.EPSILON) * 100) / 100
		let payableOnDelivery = totalIncGST - totalDeposit - totalTrades
		payableOnDelivery = Math.round((payableOnDelivery + Number.EPSILON) * 100) / 100  // make it totalDeposit and totalTrades and store them in db
		setState({
			...state,
			subTotalExGST,
			subTotalExGSTDiscounts,
			gstPayable,
			subTotalIncGST,
			totalIncGST,
			totalDeposit,
			totalTrades,
			payableOnDelivery,
		})
	}, [options, discounts, services, accessories, afterMarket, dealerdelivery, insurance, vatExclusiveItems, vehicleInfo.purchasePrice, loader])

	const handleChange = (e, type) => {
		const value = e.target.value;
		if (type === 'contact') {
			//setContact({ ...contact, [e.target.name]: value });sameer
		} else if (type === 'vehicleInfo') {
			setVehicleInfo({ ...vehicleInfo, [e.target.name]: value });
		} else if (type === 'orderDetails') {
			//setOrderDetails({ ...orderDetails, [e.target.name]: value });sameer
		} else if (type === 'options') {
			const errors = { ...errorFields }
			errors[`optionInput${e.target.name}`] = ''
			setErrorFields(errors)
			setOptionInput({ ...optionInput, [e.target.name]: value })
		} else if (type === 'optionsEdit') {
			const errors = { ...errorFields }
			errors[`optionEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setOptionsEdit({ ...optionsEdit, [e.target.name]: value })
		} else if (type === 'dealerdelivery') {
			const errors = { ...errorFields }
			errors[`dealerdeliveryInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdealerdeliveryInput({ ...dealerdeliveryInput, [e.target.name]: value })
		} else if (type === 'dealerdeliveryEdit') {
			const errors = { ...errorFields }
			errors[`dealerdeliveryEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdealerdeliveryEdit({ ...dealerdeliveryEdit, [e.target.name]: value })
		} else if (type === 'trades') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`tradesInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// settradesInput({ ...tradesInput, [e.target.name]: value })
		} else if (type === 'tradesEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`tradesEditInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// settradesEdit({ ...tradesEdit, [e.target.name]: value })
		} else if (type === 'services') {
			const errors = { ...errorFields }
			errors[`servicesInput${e.target.name}`] = ''
			setErrorFields(errors)
			setservicesInput({ ...servicesInput, [e.target.name]: value })
		} else if (type === 'servicesEdit') {
			const errors = { ...errorFields }
			errors[`servicesEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setservicesEdit({ ...servicesEdit, [e.target.name]: value })
		} else if (type === 'discounts') {
			const errors = { ...errorFields }
			errors[`discountsInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdiscountsInput({ ...discountsInput, [e.target.name]: value })
		} else if (type === 'discountsEdit') {
			const errors = { ...errorFields }
			errors[`discountsEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setdiscountsEdit({ ...discountsEdit, [e.target.name]: value })
		} else if (type === 'insurance') {
			const errors = { ...errorFields }
			errors[`insuranceInput${e.target.name}`] = ''
			setErrorFields(errors)
			setinsuranceInput({ ...insuranceInput, [e.target.name]: value })
		} else if (type === 'insuranceEdit') {
			const errors = { ...errorFields }
			errors[`insuranceEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setinsuranceEdit({ ...insuranceEdit, [e.target.name]: value })
		} else if (type === 'allowanceEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`allowanceEditInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// setAllowanceEdit({ ...allowanceEdit, [e.target.name]: value })
		} else if (type === 'VatExclusiveItemsEdit') {
			const errors = { ...errorFields }
			errors[`VatExclusiveItemsEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setVatExclusiveItemsEdit({ ...VatExclusiveItemsEdit, [e.target.name]: value })
		} else if (type === 'AccessoriesEdit') {
			const errors = { ...errorFields }
			errors[`AccessoriesEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setAccessoriesEdit({ ...AccessoriesEdit, [e.target.name]: value })
		} else if (type === 'AfterMarketEdit') {
			const errors = { ...errorFields }
			errors[`AfterMarketEditInput${e.target.name}`] = ''
			setErrorFields(errors)
			setAfterMarketEdit({ ...AfterMarketEdit, [e.target.name]: value })
		} else if (type === 'LessDepositEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`LessDepositEditInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// setLessDepositEdit({ ...LessDepositEdit, [e.target.name]: value })
		} else if (type === 'allowance') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`allowanceInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// setAllowanceInput({ ...allowanceInput, [e.target.name]: value })
		} else if (type === 'vatExclusiveItems') {
			const errors = { ...errorFields }
			errors[`vatExclusiveItemsInput${e.target.name}`] = ''
			setErrorFields(errors)
			setVatExclusiveItemsInput({ ...vatExclusiveItemsInput, [e.target.name]: value })
		} else if (type === 'lessDeposit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`lessDepositInput${e.target.name}`] = ''
			// setErrorFields(errors)
			// setLessDepositInput({ ...lessDepositInput, [e.target.name]: value })
		} else if (type === 'accessories') {
			const errors = { ...errorFields }
			errors[`accessoriesInput${e.target.name}`] = ''
			setErrorFields(errors)
			setAccessoriesInput({ ...accessoriesInput, [e.target.name]: value })
		} else if (type === 'afterMarket') {
			const errors = { ...errorFields }
			errors[`afterMarketInput${e.target.name}`] = ''
			setErrorFields(errors)
			setAfterMarketInput({ ...afterMarketInput, [e.target.name]: value })
		} else if (type === 'title') {
			const errors = { ...errorFields }
			errors.title = ''
			setErrorFields(errors)
			setEditTitle({ ...editTitle, title: value })
		}
	};

	const onAmountChange = (e, name, type) => {
		let _value = e.floatValue
		// console.log('onAmountChange value ', _value)
		if (_value === undefined) {
			_value = ''
		}
		// console.log('onAmountChange value after convert', _value)

		if (type === 'options') {
			const errors = { ...errorFields }
			errors[`optionInput${name}`] = ''
			setErrorFields(errors)
			setOptionInput({ ...optionInput, [name]: _value })
		} else if (type === 'optionsEdit') {
			const errors = { ...errorFields }
			errors[`optionEditInput${name}`] = ''
			setErrorFields(errors)
			setOptionsEdit({ ...optionsEdit, [name]: _value })
		} else if (type === 'dealerdelivery') {
			const errors = { ...errorFields }
			errors[`dealerdeliveryInput${name}`] = ''
			setErrorFields(errors)
			setdealerdeliveryInput({ ...dealerdeliveryInput, [name]: _value })
		} else if (type === 'dealerdeliveryEdit') {
			const errors = { ...errorFields }
			errors[`dealerdeliveryEditInput${name}`] = ''
			setErrorFields(errors)
			setdealerdeliveryEdit({ ...dealerdeliveryEdit, [name]: _value })
		} else if (type === 'trades') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`tradesInput${name}`] = ''
			// setErrorFields(errors)
			// settradesInput({ ...tradesInput, [name]: _value })
		} else if (type === 'tradesEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`tradesEditInput${name}`] = ''
			// setErrorFields(errors)
			// settradesEdit({ ...tradesEdit, [name]: _value })
		} else if (type === 'services') {
			const errors = { ...errorFields }
			errors[`servicesInput${name}`] = ''
			setErrorFields(errors)
			setservicesInput({ ...servicesInput, [name]: _value })
		} else if (type === 'servicesEdit') {
			const errors = { ...errorFields }
			errors[`servicesEditInput${name}`] = ''
			setErrorFields(errors)
			setservicesEdit({ ...servicesEdit, [name]: _value })
		} else if (type === 'discounts') {
			const errors = { ...errorFields }
			errors[`discountsInput${name}`] = ''
			setErrorFields(errors)
			setdiscountsInput({ ...discountsInput, [name]: _value })
		} else if (type === 'discountsEdit') {
			const errors = { ...errorFields }
			errors[`discountsEditInput${name}`] = ''
			setErrorFields(errors)
			setdiscountsEdit({ ...discountsEdit, [name]: _value })
		} else if (type === 'insurance') {
			const errors = { ...errorFields }
			errors[`insuranceInput${name}`] = ''
			setErrorFields(errors)
			setinsuranceInput({ ...insuranceInput, [name]: _value })
		} else if (type === 'insuranceEdit') {
			const errors = { ...errorFields }
			errors[`insuranceEditInput${name}`] = ''
			setErrorFields(errors)
			setinsuranceEdit({ ...insuranceEdit, [name]: _value })
		} else if (type === 'allowanceEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`allowanceEditInput${name}`] = ''
			// setErrorFields(errors)
			// setAllowanceEdit({ ...allowanceEdit, [name]: _value })
		} else if (type === 'VatExclusiveItemsEdit') {
			const errors = { ...errorFields }
			errors[`VatExclusiveItemsEditInput${name}`] = ''
			setErrorFields(errors)
			setVatExclusiveItemsEdit({ ...VatExclusiveItemsEdit, [name]: _value })
		} else if (type === 'LessDepositEdit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`LessDepositEditInput${name}`] = ''
			// setErrorFields(errors)
			// setLessDepositEdit({ ...LessDepositEdit, [name]: _value })
		} else if (type === 'AccessoriesEdit') {
			const errors = { ...errorFields }
			errors[`AccessoriesEdit${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let rate = Number(AccessoriesEdit['rate'])
				let quantity = Number(_value)
				amount = { ...AccessoriesEdit, [name]: _value, amount: quantity * rate }
			} else if (name === 'rate') {
				let quantity = Number(AccessoriesEdit['quantity'])
				let rate = Number(_value)
				amount = { ...AccessoriesEdit, [name]: _value, amount: rate * quantity }
			}
			setAccessoriesEdit(amount)
		} else if (type === 'AfterMarketEdit') {
			const errors = { ...errorFields }
			errors[`AfterMarketEdit${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let rate = Number(AfterMarketEdit['rate'])
				let quantity = Number(_value)
				amount = { ...AfterMarketEdit, [name]: _value, amount: quantity * rate }
			} else if (name === 'rate') {
				let quantity = Number(AfterMarketEdit['quantity'])
				let rate = Number(_value)
				amount = { ...AfterMarketEdit, [name]: _value, amount: rate * quantity }
			}
			setAfterMarketEdit(amount)
		} else if (type === 'allowance') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`allowanceInput${name}`] = ''
			// setErrorFields(errors)
			// setAllowanceInput({ ...allowanceInput, [name]: _value })
		} else if (type === 'vatExclusiveItems') {
			const errors = { ...errorFields }
			errors[`vatExclusiveItemsInput${name}`] = ''
			setErrorFields(errors)
			setVatExclusiveItemsInput({ ...vatExclusiveItemsInput, [name]: _value })
		} else if (type === 'lessDeposit') {
			//sameer
			// const errors = { ...errorFields }
			// errors[`lessDepositInput${name}`] = ''
			// setErrorFields(errors)
			// setLessDepositInput({ ...lessDepositInput, [name]: _value })
		} else if (type === 'accessories') {
			const errors = { ...errorFields }
			errors[`accessoriesInput${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let rate = Number(accessoriesInput['rate'])
				let quantity = Number(_value)
				amount = { ...accessoriesInput, [name]: _value, amount: quantity * rate }
			} else if (name === 'rate') {
				let quantity = Number(accessoriesInput['quantity'])
				let rate = Number(_value)
				amount = { ...accessoriesInput, [name]: _value, amount: rate * quantity }
			}
			setAccessoriesInput(amount)
		} else if (type === 'afterMarket') {
			const errors = { ...errorFields }
			errors[`afterMarketInput${name}`] = ''
			setErrorFields(errors)
			let amount
			if (name === 'quantity') {
				let rate = Number(afterMarketInput['rate'])
				let quantity = Number(_value)
				amount = { ...afterMarketInput, [name]: _value, amount: quantity * rate }
			} else if (name === 'rate') {
				let quantity = Number(afterMarketInput['quantity'])
				let rate = Number(_value)
				amount = { ...afterMarketInput, [name]: _value, amount: rate * quantity }
			}
			setAfterMarketInput(amount)
		} else if (type === 'purchasePrice') {
			const errors = { ...errorFields }
			errors[`vehicleInfopurchasePrice`] = ''
			setErrorFields(errors)
			setVehicleInfo({ ...vehicleInfo, ['purchasePrice']: _value })
		} else if (type === 'vehicleInfo') {
			//	console.log('odomter', _value ? _value : '')
			setVehicleInfo({ ...vehicleInfo, [name]: _value })
		}
	}

	const handleKeyPress = (event, type) => {
		const numberChecker = val => {
			let returnVal = true
			if (val === undefined || val === '') {
				returnVal = true
			} else {
				returnVal = false
			}
			return returnVal
		}
		let isValid = true
		let errors = {}
		const errorClass = 'input_error'
		if (event.key === 'Enter') {
			if (type === 'options') {
				if (!optionInput.item.trim()) {
					isValid = false
					errors = { ...errors, optionInputitem: errorClass }
				}
				//	console.log('options amount numberChecker(optionInput.amount) optionInput.amount', numberChecker(optionInput.amount), '  ', optionInput.amount)
				if (numberChecker(optionInput.amount)) {
					isValid = false
					errors = { ...errors, optionInputamount: errorClass }
				}
				if (isValid) {
					setOptions([...options, { item: optionInput.item.trim(), amount: optionInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setOptionInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'optionsEdit') {
				if (!optionsEdit.item.trim()) {
					isValid = false
					errors = { ...errors, optionEditInputitem: errorClass }
				}
				if (numberChecker(optionsEdit.amount)) {
					isValid = false
					errors = { ...errors, optionEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedOptions = options.map(a => {
						if (a.id === optionsEdit.editID) {
							return {
								addedDate: optionsEdit.addedDate,
								id: optionsEdit.id,
								amount: optionsEdit.amount,
								item: optionsEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setOptions(updatedOptions)
					setOptionsEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'dealerdelivery') {
				if (!dealerdeliveryInput.item.trim()) {
					isValid = false
					errors = { ...errors, dealerdeliveryInputitem: errorClass }
				}
				if (numberChecker(dealerdeliveryInput.amount)) {
					isValid = false
					errors = { ...errors, dealerdeliveryInputamount: errorClass }
				}
				if (isValid) {
					setdealerdelivery([...dealerdelivery, { item: dealerdeliveryInput.item.trim(), amount: dealerdeliveryInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setdealerdeliveryInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'dealerdeliveryEdit') {
				if (!dealerdeliveryEdit.item.trim()) {
					isValid = false
					errors = { ...errors, optionEditInputitem: errorClass }
				}
				if (numberChecker(dealerdeliveryEdit.amount)) {
					isValid = false
					errors = { ...errors, optionEditInputamount: errorClass }
				}
				if (isValid) {
					let updateddealerdelivery = dealerdelivery.map(a => {
						if (a.id === dealerdeliveryEdit.editID) {
							return {
								addedDate: dealerdeliveryEdit.addedDate,
								id: dealerdeliveryEdit.id,
								amount: dealerdeliveryEdit.amount,
								item: dealerdeliveryEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setdealerdelivery(updateddealerdelivery)
					setdealerdeliveryEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'trades') {
				//sameer
				// if (!tradesInput.item.trim()) {
				// 	isValid = false
				// 	errors = { ...errors, tradesInputitem: errorClass }
				// }
				// if (numberChecker(tradesInput.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, tradesInputamount: errorClass }
				// }
				// if (isValid) {
				// 	settrades([...trades, { item: tradesInput.item.trim(), amount: tradesInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
				// 	settradesInput(optionsVM)
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'tradesEdit') {
				//sameer
				// if (!tradesEdit.item.trim()) {
				// 	isValid = false
				// 	errors = { ...errors, optionEditInputitem: errorClass }
				// }
				// if (numberChecker(tradesEdit.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, optionEditInputamount: errorClass }
				// }
				// if (isValid) {
				// 	let updatedtrades = trades.map(a => {
				// 		if (a.id === tradesEdit.editID) {
				// 			return {
				// 				addedDate: tradesEdit.addedDate,
				// 				id: tradesEdit.id,
				// 				amount: tradesEdit.amount,
				// 				item: tradesEdit.item.trim(),
				// 			}
				// 		} else {
				// 			return a
				// 		}
				// 	})

				// 	settrades(updatedtrades)
				// 	settradesEdit({
				// 		editID: null,
				// 		show: true,
				// 		...optionsVM
				// 	})
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'services') {
				if (!servicesInput.item.trim()) {
					isValid = false
					errors = { ...errors, servicesInputitem: errorClass }
				}
				if (numberChecker(servicesInput.amount)) {
					isValid = false
					errors = { ...errors, servicesInputamount: errorClass }
				}
				if (isValid) {
					setservices([...services, { item: servicesInput.item.trim(), amount: servicesInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setservicesInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'servicesEdit') {
				if (!servicesEdit.item.trim()) {
					isValid = false
					errors = { ...errors, optionEditInputitem: errorClass }
				}
				if (numberChecker(servicesEdit.amount)) {
					isValid = false
					errors = { ...errors, optionEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedservices = services.map(a => {
						if (a.id === servicesEdit.editID) {
							return {
								addedDate: servicesEdit.addedDate,
								id: servicesEdit.id,
								amount: servicesEdit.amount,
								item: servicesEdit.item.trim(),
								titleReadonly: servicesEdit.titleReadonly ? true : false
							}
						} else {
							return a
						}
					})

					setservices(updatedservices)
					setservicesEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'discounts') {
				if (!discountsInput.item.trim()) {
					isValid = false
					errors = { ...errors, discountsInputitem: errorClass }
				}
				if (numberChecker(discountsInput.amount)) {
					isValid = false
					errors = { ...errors, discountsInputamount: errorClass }
				}
				if (isValid) {
					setdiscounts([...discounts, { item: discountsInput.item.trim(), amount: discountsInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setdiscountsInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'discountsEdit') {
				if (!discountsEdit.item.trim()) {
					isValid = false
					errors = { ...errors, optionEditInputitem: errorClass }
				}
				if (numberChecker(discountsEdit.amount)) {
					isValid = false
					errors = { ...errors, optionEditInputamount: errorClass }
				}
				if (isValid) {
					let updateddiscounts = discounts.map(a => {
						if (a.id === discountsEdit.editID) {
							return {
								addedDate: discountsEdit.addedDate,
								id: discountsEdit.id,
								amount: discountsEdit.amount,
								item: discountsEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setdiscounts(updateddiscounts)
					setdiscountsEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'insurance') {
				if (!insuranceInput.item.trim()) {
					isValid = false
					errors = { ...errors, insuranceInputitem: errorClass }
				}
				if (numberChecker(insuranceInput.amount)) {
					isValid = false
					errors = { ...errors, insuranceInputamount: errorClass }
				}
				if (isValid) {
					setinsurance([...insurance, { item: insuranceInput.item.trim(), amount: insuranceInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setinsuranceInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'insuranceEdit') {
				if (!insuranceEdit.item.trim()) {
					isValid = false
					errors = { ...errors, optionEditInputitem: errorClass }
				}
				if (numberChecker(insuranceEdit.amount)) {
					isValid = false
					errors = { ...errors, optionEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedinsurance = insurance.map(a => {
						if (a.id === insuranceEdit.editID) {
							return {
								addedDate: insuranceEdit.addedDate,
								id: insuranceEdit.id,
								amount: insuranceEdit.amount,
								item: insuranceEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setinsurance(updatedinsurance)
					setinsuranceEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'allowanceEdit') {
				//sameer
				// if (!allowanceEdit.item.trim()) {
				// 	isValid = false
				// 	errors = { ...errors, allowanceEditInputitem: errorClass }
				// }
				// if (numberChecker(allowanceEdit.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, allowanceEditInputamount: errorClass }
				// }
				// if (isValid) {
				// 	let updatedAllowance = allowance.map(a => {
				// 		if (a.id === allowanceEdit.editID) {
				// 			return {
				// 				addedDate: allowanceEdit.addedDate,
				// 				id: allowanceEdit.id,
				// 				amount: allowanceEdit.amount,
				// 				item: allowanceEdit.item.trim(),
				// 			}
				// 		} else {
				// 			return a
				// 		}
				// 	})

				// 	setAllowance(updatedAllowance)
				// 	setAllowanceEdit({
				// 		editID: null,
				// 		show: true,
				// 		...optionsVM
				// 	})
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'VatExclusiveItemsEdit') {
				if (!VatExclusiveItemsEdit.item.trim()) {
					isValid = false
					errors = { ...errors, VatExclusiveItemsEditInputitem: errorClass }
				}
				if (numberChecker(VatExclusiveItemsEdit.amount)) {
					isValid = false
					errors = { ...errors, VatExclusiveItemsEditInputamount: errorClass }
				}
				if (isValid) {
					let updatedVatExclusiveItems = vatExclusiveItems.map(a => {
						if (a.id === VatExclusiveItemsEdit.editID) {
							return {
								addedDate: VatExclusiveItemsEdit.addedDate,
								id: VatExclusiveItemsEdit.id,
								amount: VatExclusiveItemsEdit.amount,
								item: VatExclusiveItemsEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setVatExclusiveItems(updatedVatExclusiveItems)
					setVatExclusiveItemsEdit({
						editID: null,
						show: true,
						...optionsVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'LessDepositEdit') {
				//sameer
				// if (!LessDepositEdit.reference) {
				// 	isValid = false
				// 	errors = { ...errors, LessDepositEditInputreference: errorClass }
				// }
				// if (!LessDepositEdit.date) {
				// 	isValid = false
				// 	errors = { ...errors, LessDepositEditInputdate: errorClass }
				// }
				// if (numberChecker(LessDepositEdit.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, LessDepositEditInputamount: errorClass }
				// }
				// if (isValid) {
				// 	let updatedLessDeposit = lessDeposit.map(a => {
				// 		if (a.id === LessDepositEdit.editID) {
				// 			return {
				// 				addedDate: LessDepositEdit.addedDate,
				// 				id: LessDepositEdit.id,
				// 				amount: LessDepositEdit.amount,
				// 				date: LessDepositEdit.date,
				// 				reference: LessDepositEdit.reference,
				// 			}
				// 		} else {
				// 			return a
				// 		}
				// 	})

				// 	setLessDeposit(updatedLessDeposit)
				// 	setLessDepositEdit({
				// 		editID: null,
				// 		show: true,
				// 		...lessDepositVM
				// 	})
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'AccessoriesEdit') {
				if (!AccessoriesEdit.item.trim()) {
					isValid = false
					errors = { ...errors, AccessoriesEditInputitem: errorClass }
				}
				if (numberChecker(AccessoriesEdit.quantity)) {
					isValid = false
					errors = { ...errors, AccessoriesEditquantity: errorClass }
				}
				if (numberChecker(AccessoriesEdit.rate)) {
					isValid = false
					errors = { ...errors, AccessoriesInputrate: errorClass }
				}
				if (isValid) {
					let amount = AccessoriesEdit.quantity * AccessoriesEdit.rate

					let updatedAccessories = accessories.map(a => {
						if (a.id === AccessoriesEdit.editID) {
							return {
								addedDate: AccessoriesEdit.addedDate,
								id: AccessoriesEdit.id,
								amount: amount,
								rate: AccessoriesEdit.rate,
								quantity: AccessoriesEdit.quantity,
								item: AccessoriesEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setAccessories(updatedAccessories)
					setAccessoriesEdit({
						editID: null,
						show: true,
						...genuineAccessoriesVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'AfterMarketEdit') {
				if (!AfterMarketEdit.item.trim()) {
					isValid = false
					errors = { ...errors, AfterMarketEditInputitem: errorClass }
				}
				if (numberChecker(AfterMarketEdit.quantity)) {
					isValid = false
					errors = { ...errors, AfterMarketEditquantity: errorClass }
				}
				if (numberChecker(AfterMarketEdit.rate)) {
					isValid = false
					errors = { ...errors, AfterMarketInputrate: errorClass }
				}
				if (isValid) {
					let amount = AfterMarketEdit.quantity * AfterMarketEdit.rate

					let updatedAfterMarket = afterMarket.map(a => {
						if (a.id === AfterMarketEdit.editID) {
							return {
								addedDate: AfterMarketEdit.addedDate,
								id: AfterMarketEdit.id,
								amount: amount,
								rate: AfterMarketEdit.rate,
								quantity: AfterMarketEdit.quantity,
								item: AfterMarketEdit.item.trim(),
							}
						} else {
							return a
						}
					})

					setAfterMarket(updatedAfterMarket)
					setAfterMarketEdit({
						editID: null,
						show: true,
						...genuineAccessoriesVM
					})
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'allowance') {
				//sameer
				// if (!allowanceInput.item.trim()) {
				// 	isValid = false
				// 	errors = { ...errors, allowanceInputitem: errorClass }
				// }
				// if (numberChecker(allowanceInput.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, allowanceInputamount: errorClass }
				// }
				// if (isValid) {
				// 	setAllowance([...allowance, { item: allowanceInput.item.trim(), amount: allowanceInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
				// 	setAllowanceInput(optionsVM)
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'vatExclusiveItems') {
				if (!vatExclusiveItemsInput.item.trim()) {
					isValid = false
					errors = { ...errors, vatExclusiveItemsInputitem: errorClass }
				}
				if (numberChecker(vatExclusiveItemsInput.amount)) {
					isValid = false
					errors = { ...errors, vatExclusiveItemsInputamount: errorClass }
				}
				if (isValid) {
					setVatExclusiveItems([...vatExclusiveItems, { item: vatExclusiveItemsInput.item.trim(), amount: vatExclusiveItemsInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setVatExclusiveItemsInput(optionsVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'lessDeposit') {
				//sameer
				// // console.log('lessDeposit', { date: lessDepositInput.date, reference: lessDepositInput.reference, amount: lessDepositInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() })
				// if (!lessDepositInput.date) {
				// 	isValid = false
				// 	errors = { ...errors, lessDepositInputdate: errorClass }
				// }
				// // if (!lessDepositInput.reference) {
				// // 	isValid = false
				// // 	errors = { ...errors, lessDepositInputreference: errorClass }
				// // }
				// if (numberChecker(lessDepositInput.amount)) {
				// 	isValid = false
				// 	errors = { ...errors, lessDepositInputamount: errorClass }
				// }
				// if (isValid) {
				// 	setLessDeposit([...lessDeposit, { date: lessDepositInput.date, reference: lessDepositInput.reference, amount: lessDepositInput.amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
				// 	setLessDepositInput(lessDepositVM)
				// } else {
				// 	setErrorFields({ ...errorFields, ...errors })
				// }
			} else if (type === 'accessories') {
				if (!accessoriesInput.item.trim()) {
					isValid = false
					errors = { ...errors, accessoriesInputitem: errorClass }
				}
				if (numberChecker(accessoriesInput.quantity)) {
					isValid = false
					errors = { ...errors, accessoriesInputquantity: errorClass }
				}
				if (numberChecker(accessoriesInput.rate)) {
					isValid = false
					errors = { ...errors, accessoriesInputrate: errorClass }
				}
				if (isValid) {
					let amount = accessoriesInput.quantity * accessoriesInput.rate
					setAccessories([...accessories, { item: accessoriesInput.item.trim(), quantity: accessoriesInput.quantity, rate: accessoriesInput.rate, amount: amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setAccessoriesInput(genuineAccessoriesVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'afterMarket') {
				if (!afterMarketInput.item.trim()) {
					isValid = false
					errors = { ...errors, afterMarketInputitem: errorClass }
				}
				if (numberChecker(afterMarketInput.quantity)) {
					isValid = false
					errors = { ...errors, afterMarketInputquantity: errorClass }
				}
				if (numberChecker(afterMarketInput.rate)) {
					isValid = false
					errors = { ...errors, afterMarketInputrate: errorClass }
				}
				if (isValid) {
					let amount = afterMarketInput.quantity * afterMarketInput.rate
					setAfterMarket([...afterMarket, { item: afterMarketInput.item.trim(), quantity: afterMarketInput.quantity, rate: afterMarketInput.rate, amount: amount, id: new Date().valueOf().toString(), addedDate: window.firebase.firestore.Timestamp.now() }])
					setAfterMarketInput(genuineAccessoriesVM)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'purchasePrice') {
				if (numberChecker(vehicleInfo.purchasePrice)) {
					isValid = false
					errors = { ...errors, vehicleInfopurchasePrice: errorClass }
				}
				if (isValid) {
					setEditPrice(false)
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			} else if (type === 'title') {
				if (!editTitle.title) {
					isValid = false
					errors = { ...errors, title: errorClass }
				}
				if (isValid) {
					setState({ ...state, title: editTitle.title })
					setEditTitle({ show: false, title: '' })
				} else {
					setErrorFields({ ...errorFields, ...errors })
				}
			}
		}
	}

	const handleClearEdit = type => {
		if (type === 'options') {
			setOptionsEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'dealerdelivery') {
			setdealerdeliveryEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'trades') {
			//sameer
			// settradesEdit({
			// 	editID: null,
			// 	show: true,
			// 	...optionsVM
			// })
		} else if (type === 'services') {
			setservicesEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'discounts') {
			setdiscountsEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'insurance') {
			setinsuranceEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'allowance') {
			//sameer
			// setAllowanceEdit({
			// 	editID: null,
			// 	show: true,
			// 	...optionsVM
			// })
		} else if (type === 'VatExclusiveItems') {
			setVatExclusiveItemsEdit({
				editID: null,
				show: true,
				...optionsVM
			})
		} else if (type === 'Accessories') {
			setAccessoriesEdit({
				editID: null,
				show: true,
				...genuineAccessoriesVM
			})
		} else if (type === 'AfterMarket') {
			setAfterMarketEdit({
				editID: null,
				show: true,
				...genuineAccessoriesVM
			})
		} else if (type === 'LessDeposit') {
			//sameer
			// setLessDepositEdit({
			// 	editID: null,
			// 	show: true,
			// 	...lessDepositVM
			// })
		}
	}

	const handleRemoveItem = (type, data) => {
		Swal.fire({
			title: 'Are you sure?',
			text: `You want to delete this row.`,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then(async result => {
			if (result.value) {
				if (type === 'options') {
					let _options = options.filter(a => a.id !== data.id)
					setOptions(_options)
				} else if (type === 'dealerdelivery') {
					let _dealerdelivery = dealerdelivery.filter(a => a.id !== data.id)
					setdealerdelivery(_dealerdelivery)
				} else if (type === 'trades') {
					//sameer
					// let _trades = trades.filter(a => a.id !== data.id)
					// settrades(_trades)
				} else if (type === 'services') {
					let _services = services.filter(a => a.id !== data.id)
					setservices(_services)
				} else if (type === 'discounts') {
					let _discounts = discounts.filter(a => a.id !== data.id)
					setdiscounts(_discounts)
				} else if (type === 'insurance') {
					let _insurance = insurance.filter(a => a.id !== data.id)
					setinsurance(_insurance)
				} else if (type === 'allowance') {
					//sameer
					// let _allowance = allowance.filter(a => a.id !== data.id)
					// setAllowance(_allowance)
				} else if (type === 'vatExclusiveItems') {
					let _vatExclusiveItems = vatExclusiveItems.filter(a => a.id !== data.id)
					setVatExclusiveItems(_vatExclusiveItems)
				} else if (type === 'lessDeposit') {
					//let _lessDeposit = lessDeposit.filter(a => a.id !== data.id)
					//setLessDeposit(_lessDeposit)sameer
				} else if (type === 'accessories') {
					let _accessories = accessories.filter(a => a.id !== data.id)
					setAccessories(_accessories)
				} else if (type === 'afterMarket') {
					let _afterMarket = afterMarket.filter(a => a.id !== data.id)
					setAfterMarket(_afterMarket)
				}
			}
		})
	}

	const onSave = async () => {
		if (saveLoader) {
			return
		}
		// convert dates before sending
		let _vehicleInfo = { ...vehicleInfo }
		//let _orderDetails = { ...orderDetails }
		//let _contact = { ...contact }
		//let _lessDeposit = [...lessDeposit]

		//sameer
		// if (!_.isEmpty(_contact.dob)) {
		// 	_contact.dob = moment(_contact.dob).format('YYYY-MM-DD')
		// }

		if (!_vehicleInfo.purchasePrice) {
			_vehicleInfo.purchasePrice = 0
		}

		if (!_.isEmpty(_vehicleInfo.regoExpiry)) {
			_vehicleInfo.regoExpiry = moment(_vehicleInfo.regoExpiry.toDate()).format('YYYY-MM-DD')
		}
		if (!_.isEmpty(_vehicleInfo.buildDate)) {
			_vehicleInfo.buildDate = moment(_vehicleInfo.buildDate.toDate()).format('YYYY-MM-DD')
		}

		//sameer
		// if (!_.isEmpty(_orderDetails.orderDate)) {
		// 	_orderDetails.orderDate = moment(_orderDetails.orderDate.toDate()).format('YYYY-MM-DD')
		// }
		// if (!_.isEmpty(_orderDetails.estDelivery)) {
		// 	_orderDetails.estDelivery = moment(_orderDetails.estDelivery.toDate()).format('YYYY-MM-DD')
		// }

		//sameer
		// if (!_.isEmpty(_lessDeposit)) {
		// 	_lessDeposit = _lessDeposit.map(item => {
		// 		let _date = moment(item.date.toDate()).format('YYYY-MM-DD')
		// 		return { ...item, date: _date }
		// 	})
		// }

		let _quotationStatus = 'quote'
		//sameer
		// if (_orderDetails.status === 'won' || _orderDetails.status === 'delivered') {
		// 	_quotationStatus = 'contract'
		// } else {
		// 	_quotationStatus = 'quote'
		// }

		// check if its new
		if (!state.documentID) {
			setSaveLoader(true)
			const documentID = window.firebase.firestore().collection('stockQuotations').doc().id

			let objectToSave = {
				displayID: new Date().valueOf().toString(),
				documentID,
				//enquiryID,sameer
				//contact: _contact,sameer
				clientID: _clientID ? _clientID : '',
				//contactID: contactData.documentID ? contactData.documentID : '',sameer
				vehicle: _vehicleInfo,
				gstPercentage: state.gstPercentage,
				taxType: state.taxType,
				subTotalExGST: state.subTotalExGST,
				subTotalExGSTDiscounts: state.subTotalExGSTDiscounts,
				subTotalIncGST: state.subTotalIncGST,
				gstPayable: state.gstPayable,
				totalIncGST: state.totalIncGST,
				payableOnDelivery: state.payableOnDelivery,
				title: state.title ? state.title : 'Vehicle Contract',
				type: state.type ? state.type : 'vehicleContract',
				notes: state.notes ? state.notes : ' ',
				isDeleted: false,
				//requirementID: _stockID,sameer
				addedBy: localStorage.uid,
				modifiedBy: localStorage.uid,
				addedDate: window.firebase.firestore.Timestamp.now(),
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				modifiedFrom: 'web',
				quotationStatus: _quotationStatus,
				//lessDeposit: _lessDeposit,sameer
				totalDeposit: state.totalDeposit,
				owner: localStorage.uid,//enquiry && enquiry.owner ? enquiry.owner : '',sameer
			}
			if (state.type === 'vehicleContract') {
				objectToSave = {
					...objectToSave,
					options,
					dealerDelivery: dealerdelivery,
					//trades,sameer
					otherInsurance: insurance,
					accessories,
					afterMarket,
					//allowance, sameer
					vatExclusiveItems,
					signatureSalesExecutive: signatures.signatureSalesExecutive ? signatures.signatureSalesExecutive : '',
					signatureSalesManager: signatures.signatureSalesManager ? signatures.signatureSalesManager : '',
					signatureCustomer: signatures.signatureCustomer ? signatures.signatureCustomer : '',
					totalTrades: state.totalTrades,
					//orderDetails: _orderDetails,sameer
				}
			} else {
				objectToSave = {
					...objectToSave,
					discounts,
					services,
				}
			}
			// console.log('objectosave', objectToSave)

			const ref = window.firebase.firestore().doc(`stockQuotations/${documentID}`)
			// console.log(objectToSave)
			await ref.set(objectToSave, { merge: true })
			//sameer
			// let _quotation = { id: objectToSave.documentID, amount: objectToSave.payableOnDelivery }
			// const enqRef = window.firebase.firestore().doc(`enquiries/${enquiryID}`)
			// let objData = {};
			// if (!_.isEmpty(enquiry) && !enquiry.isQuotation) {
			// 	objData.isQuotation = true;
			// 	objData = handleMoveStageForStatusChange('isQuotation', objData);
			// }
			// objData.quotation = _quotation;
			// objData.modifiedBy = localStorage.uid;
			// objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			// objData.modifiedFrom = 'web';

			// await enqRef.set(objData, { merge: true })
			setNewlySavedData(objectToSave)
			window.history.pushState("", "", `/stock/quote/${_clientID}?type=edit&previewID=${documentID}`);
			setActiveTab('preview')
			//add isQuotation True
			setSaveLoader(false)
		} else {
			setSaveLoader(true)

			let objectToSave = {
				displayID: state.displayID,
				documentID: state.documentID,
				//enquiryID: state.enquiryID,
				clientID: _clientID ? _clientID : '',
				//contactID: contactData.documentID ? contactData.documentID : '',
				//contact: _contact,sameer
				vehicle: _vehicleInfo,
				gstPercentage: state.gstPercentage,
				taxType: state.taxType,
				subTotalExGST: state.subTotalExGST,
				subTotalExGSTDiscounts: state.subTotalExGSTDiscounts,
				subTotalIncGST: state.subTotalIncGST,
				gstPayable: state.gstPayable,
				totalIncGST: state.totalIncGST,
				payableOnDelivery: state.payableOnDelivery,
				title: state.title ? state.title : 'Vehicle Contract',
				type: state.type ? state.type : 'vehicleContract',
				notes: state.notes ? state.notes : ' ',
				isDeleted: false,
				//requirementID: state.requirementID,sameer
				modifiedBy: localStorage.uid,
				modifiedDate: window.firebase.firestore.Timestamp.now(),
				modifiedFrom: 'web',
				quotationStatus: _quotationStatus,
				//lessDeposit: _lessDeposit,sameer
				totalDeposit: state.totalDeposit,
				//owner: enquiry && enquiry.owner ? enquiry.owner : '',sameer
			}

			if (state.type === 'vehicleContract') {
				objectToSave = {
					...objectToSave,
					options,
					dealerDelivery: dealerdelivery,
					//trades,sameer
					otherInsurance: insurance,
					accessories,
					afterMarket,
					//allowance, sameer
					vatExclusiveItems,
					signatureSalesExecutive: signatures.signatureSalesExecutive ? signatures.signatureSalesExecutive : '',
					signatureSalesManager: signatures.signatureSalesManager ? signatures.signatureSalesManager : '',
					signatureCustomer: signatures.signatureCustomer ? signatures.signatureCustomer : '',
					totalTrades: state.totalTrades,
					//orderDetails: _orderDetails,sameer
				}
			} else {
				objectToSave = {
					...objectToSave,
					discounts,
					services,
				}
			}
			// console.log('objectToSave', objectToSave)
			const ref = window.firebase.firestore().doc(`stockQuotations/${objectToSave.documentID}`)
			// console.log(objectToSave)
			await ref.set(objectToSave, { merge: true })
			//sameer
			// let _quotation = { id: objectToSave.documentID, amount: objectToSave.payableOnDelivery }
			// const enqRef = window.firebase.firestore().doc(`enquiries/${enquiryID}`)
			// let objData = {};
			// if (!_.isEmpty(enquiry) && !enquiry.isQuotation) {
			// 	objData.isQuotation = true;
			// 	objData = handleMoveStageForStatusChange('isQuotation', objData);
			// }
			// objData.quotation = _quotation;
			// objData.modifiedBy = localStorage.uid;
			// objData.modifiedDate = window.firebase.firestore.Timestamp.now();
			// objData.modifiedFrom = 'web';

			// await enqRef.set(objData, { merge: true })
			setNewlySavedData(objectToSave)
			setActiveTab('preview')
			//add isQuotation True
			setSaveLoader(false)
		}
	}

	// const handleMoveStageForStatusChange = (_status, state) => {

	// 	const { stageHistory } = enquiry;
	// 	let statusSettings = null;

	// 	if (enquiry && enquiry.pipeline && !_.isEmpty(clientSettings.settings) && !_.isEmpty(clientSettings.settings.allPipelines)) {
	// 		let _selPipeline = clientSettings.settings.allPipelines.filter(e => e.value === enquiry.pipeline)[0];
	// 		statusSettings = _selPipeline && _selPipeline.statusSettings;
	// 	}

	// 	let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
	// 	if (!_.isEmpty(data) && data.pipeline && data.stage) {

	// 		state.stageDate = window.firebase.firestore.Timestamp.now();
	// 		let _stageHistory = Object.assign([], stageHistory);
	// 		var hasRecord = false;

	// 		_stageHistory.filter(function (obj) {
	// 			//Update old stage data
	// 			if (obj.stage === state.stage && obj.pipeline === state.pipeline) {
	// 				obj.sittingDays = (obj.sittingDays ? obj.sittingDays : 0) + parseInt(moment().diff(moment.unix(obj.date.seconds), 'days'));
	// 			}

	// 			// Update new stage data
	// 			if (obj.stage === data.stage && obj.pipeline === data.pipeline) {
	// 				hasRecord = true;
	// 				obj.date = state.stageDate;
	// 			}
	// 		});

	// 		if (hasRecord === false) {
	// 			_stageHistory.push({
	// 				date: state.stageDate,
	// 				stage: data.stage,
	// 				pipeline: data.pipeline,
	// 				sittingDays: 0,
	// 			});
	// 		}
	// 		state.stageHistory = Object.assign([], _stageHistory);
	// 		state.pipeline = data.pipeline;
	// 		state.stage = data.stage;
	// 		return state;
	// 	}
	// 	else
	// 		return state;
	// }

	const handleSetSignatures = objectData => {
		setSignatures(objectData)
	}

	const handleDateChange = (val, name) => {
		if (name === 'orderDate') {
			// setOrderDetails({ sameer
			// 	...orderDetails,
			// 	orderDate: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			// })
		} else if (name === 'estDelivery') {
			// setOrderDetails({ sameer
			// 	...orderDetails,
			// 	estDelivery: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			// })
		} else if (name === 'regoExpiry') {
			setVehicleInfo({
				...vehicleInfo,
				regoExpiry: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			})
		} else if (name === 'buildDate') {
			setVehicleInfo({
				...vehicleInfo,
				buildDate: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			})
		} else if (name === 'lessDepositInput.date') { // lessDeposit
			//sameer
			// const errors = { ...errorFields }
			// errors[`lessDepositInputdate`] = ''
			// setErrorFields(errors)
			// setLessDepositInput({
			// 	...lessDepositInput,
			// 	date: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			// })
		} else if (name === 'LessDepositEditInput.date') { // lessDeposit
			//sameer
			// const errors = { ...errorFields }
			// errors[`LessDepositEditInputdate`] = ''
			// setErrorFields(errors)
			// setLessDepositEdit({
			// 	...LessDepositEdit,
			// 	date: val ? window.firebase.firestore.Timestamp.fromDate(moment(val)._d) : null,
			// })
		}
	};

	return !loader ? (
		<>
			<div className='dynamicreport-stage-container' >
				<div className='dynamicreport-stage-wraper' >
					<div className='dynamicreport-stage-loop active'>
						<div className='dynamicreport-stage-loop-box'>
							<div className='dynamicreport-stage-count'>1</div>
							<div className='dynamicreport-stage-title-wrap'>
								<span className='dynamicreport-stage-title'>Contract Details</span>
							</div>
						</div>
					</div>

					<div className='dynamicreport-stage-seperator'>
						<svg
							stroke='currentColor'
							fill='none'
							strokeWidth='2'
							viewBox='0 0 24 24'
							strokeLinecap='round'
							strokeLinejoin='round'
							height='1em'
							width='1em'
							xmlns='http://www.w3.org/2000/svg'
						>
							<polyline points='9 18 15 12 9 6'></polyline>
						</svg>
					</div>

					<div className='dynamicreport-stage-loop'>
						<div className='dynamicreport-stage-loop-box'>
							<div className='dynamicreport-stage-count'>2</div>
							<div className='dynamicreport-stage-title-wrap'>
								<span className='dynamicreport-stage-title'>Preview </span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='button-wrap'>
				{!_.isEmpty(state.modifiedDate) && (
					<div id='updated-date' style={{ float: 'left', padding: '10px' }}>
						<div>Updated On : {moment(state.modifiedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
					</div>
				)}
				<a
					className='update-btn'
					onClick={onSave}
				// onclick=''
				>
					{
						saveLoader ?
							(<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
							: (<></>)
					}
					Save &amp; Preview
				</a>
			</div>

			<div
				className='dynamic-report-print-wrap'
				id='print-section'
			>
				<div className='print-wrap'>
					<div className='header'>
						<div className='headerwrap-left'>
							<div className='header-left'>
								{
									client && client.logoURL
										?
										<div className="logo"> <img alt='' src={client.logoURL ? client.logoURL : ''} height="60" /></div>
										:
										<>
											<div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
										</>
								}
							</div>
						</div>

						<div className='headerwrap-right'>
							<div className='header-right'>
								{' '}
								{client.name} <br />
								<span>
									{' '}
									{client.address ? client.address : ''}
									{client.phone ? (
										<>
											<br />
											{`Phone: ${client.phone}`}
										</>
									) : <></>}
									{client.email || client.website ? (
										<>
											<br />
											{client.email ? client.email : ''}{client.website ? ` | ${client.website}` : ''}
										</>
									) : <></>}
									<br />
								</span>
							</div>
						</div>
					</div>

					<div className='clear'></div>


					<div className='middle'>
						{editTitle.show ? (
							<div className="title-head">
								<table width="100%" border="0" cellSpacing="0" cellPadding="0">
									<tbody>
										<tr className="items-list">
											<td width="90%">
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['title']}`}
													name='title'
													value={editTitle.title}
													onChange={e => handleChange(e, 'title')}
													placeholder='title'
													onKeyPress={e => handleKeyPress(e, 'title')}
												/>
											</td>
											<td width="10%">
												<div className="form-controls-td">
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'title')}
													><img src={_images.plusIcon} alt="" width="14"
														/></a>
													<button type="button" className="close-btn" onClick={() => setEditTitle({ show: false, title: '' })}>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															viewBox="0 0 24 24"
														>
															<g id="x" transform="translate(8 8)">
																<path
																	id="Line_109"
																	d="M0 0L0.001 11.313"
																	className="cls-1"
																	data-name="Line 109"
																	transform="rotate(135 2.343 5.656)"
																	fill="none"
																	stroke="#733dd9"
																	strokeLinecap="round"
																	strokeWidth="1"
																></path>
																<path
																	id="Line_110"
																	d="M0.001 0L0 11.313"
																	className="cls-1"
																	data-name="Line 110"
																	transform="rotate(-135 1.658 4)"
																	fill="none"
																	stroke="#733dd9"
																	strokeLinecap="round"
																	strokeWidth="1"
																></path>
															</g>
														</svg>
													</button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<div className="clear"></div>
							</div>
						) : (
							<div className='title-head'>{state.title ? state.title : 'Vehicle Contract'}{' '}
								<button className='addmore-field-btn' type='button' onClick={() => setEditTitle({ show: true, title: state.title ? state.title : 'Vehicle Contract' })}>
									<a className="edit-icon-input"><img src={_images.editIcon} alt="" width="12" /></a>
								</button>
							</div>
						)}

						<div className='clear'></div>
					</div>

					{/* <div className='section-wraper'>
						<div className='section-head'>
							<strong>Contact Information</strong>
						</div>
						<div className='section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>

									{contact.businessContact ? (
										<tr>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Name</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='name'
														value={contact.name}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Email</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='email'
														value={contact.email ? contact.email : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Phone</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='phone'
														value={contact.phone ? contact.phone : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
										</tr>
									) : (
										<tr>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>First Name</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='firstName'
														value={contact.firstName}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Last Name</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='lastName'
														value={contact.lastName}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Phone</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='phone'
														value={contact.phone ? contact.phone : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
										</tr>
									)}

									{contact.businessContact ? (
										<></>
									) : (
										<tr>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Email</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='email'
														value={contact.email ? contact.email : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>DOB</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='dob'
														value={contact.dob ? moment(contact.dob).format('DD/MM/YYYY') : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>License</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='licenseNo'
														value={contact.licenseNo ? contact.licenseNo : ''}
														onChange={e => handleChange(e, 'contact')}
														disabled={true}
													/>
												</div>
											</td>
										</tr>
									)}

									<tr>
										<td colSpan="3">
											<div className="grid-one">
												<span>Address</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='address'
													value={contact.address ? contact.address : ''}
													onChange={e => handleChange(e, 'address')}
													disabled={true}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div> */}

					{/* {state.type === 'vehicleContract' && (
						<div className='section-wraper'>
							<div className='section-head'>
								<strong>Order Details</strong>
							</div>
							<div className='section-details'>
								<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
									<tbody>
										<tr>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Lead ID</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='leadID'
														value={
															orderDetails && orderDetails.leadID ? orderDetails.leadID : ''
														}
														onChange={e => handleChange(e, 'orderDetails')}
														disabled={true}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Order No</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq'
														name='orderNo'
														value={
															orderDetails && orderDetails.orderNo ? orderDetails.orderNo : ''
														}
														onChange={e => handleChange(e, 'orderDetails')}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Order Date</span>
													<AntDatePicker
														value={orderDetails && orderDetails.orderDate ? moment.unix(orderDetails.orderDate.seconds).format('YYYY-MM-DD') : null}
														name={'orderDate'}
														onChange={(e) => { handleDateChange(e, 'orderDate') }}
														format='DD/MM/YYYY'
														placeholder='DD/MM/YYYY'
														className={`input-enq`}
													/>
												</div>
											</td>
										</tr>
										<tr>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Est. Delivery</span>
													<AntDatePicker
														value={orderDetails && orderDetails.estDelivery ? moment.unix(orderDetails.estDelivery.seconds).format('YYYY-MM-DD') : null}
														name={'estDelivery'}
														onChange={(e) => { handleDateChange(e, 'estDelivery') }}
														format='DD/MM/YYYY'
														placeholder='DD/MM/YYYY'
														className={`input-enq`}
													/>
												</div>
											</td>
											<td width='33%'>
												<div className='grid-one'>
													{' '}
													<span>Lead Owner</span>
													<input
														autoComplete="off"
														type='text'
														className='input-enq input-enq-autofill'
														name='leadOwner'
														value={
															orderDetails && orderDetails.leadOwner
																? orderDetails.leadOwner
																: ''
														}
														onChange={e => handleChange(e, 'orderDetails')}
														disabled={true}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					)} */}

					<div className='section-wraper'>
						<div className='section-head'>
							<strong>Vehicle Information</strong>
						</div>
						<div className='section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>
									<tr>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Make</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='make'
													value={
														vehicleInfo.make ? vehicleInfo.make : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Model</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='model'
													value={
														vehicleInfo.model ? vehicleInfo.model : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
										<td width='33%'>
											<div className='grid-one'>
												{' '}
												<span>Type</span>
												<input
													autoComplete="off"
													type='text'
													className='input-enq input-enq-autofill'
													name='type'
													value={
														vehicleInfo.type ? vehicleInfo.type : ''
													}
													onChange={e => handleChange(e, 'vehicleInfo')}
													disabled={true}
												/>
											</div>
										</td>
									</tr>										
								</tbody>
							</table>
						</div>
					</div>

					{state.type === 'vehicleContract' && (
						<>
							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Vehicle</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setEditPrice(true)}>
											<a className="edit-icon-input"><img src={_images.editIcon} alt="" width="12" /></a>
												Edit Price
											</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<tbody>
											<tr>
												<td width='70%'>
													<div className='grid-one'>
														{' '}
														<span>
															{' '}
																Make &amp; Model:{' '}
															<strong>{`${vehicleInfo.make} ${vehicleInfo.model}`}</strong>
														</span>
													</div>
												</td>
												<td width='30%'>
													<div className='grid-one text-right number-value'>
														{' '}
														{editPrice ? (
															<span style={{ display: 'flex' }}>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['vehicleInfopurchasePrice']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'purchasePrice',
																			'purchasePrice',
																		)
																	}
																	value={vehicleInfo.purchasePrice}
																	onKeyPress={e => handleKeyPress(e, 'purchasePrice')}
																/>
																<button type='button' className='close-btn pl-2' onClick={e => {
																	let isValid = true
																	let errors = {}
																	const errorClass = 'input_error'
																	if (!vehicleInfo.purchasePrice) {
																		isValid = false
																		errors = { ...errors, vehicleInfopurchasePrice: errorClass }
																	}
																	if (isValid) {
																		setEditPrice(false)
																	} else {
																		setErrorFields({ ...errorFields, ...errors })
																	}
																}}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</span>
														) : (
															<span>
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={vehicleInfo.purchasePrice}
																/>
															</span>
														)}
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div></div>
							</div>
							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Options</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowOptions(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
												Add More
											</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{options.length > 0 && options.map((option, index) => (
												<Fragment key={option.id}>
													{optionsEdit.show && optionsEdit.editID === option.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{options.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['optionEditInputitem']}`}
																	name='item'
																	value={optionsEdit.item}
																	onChange={e => handleChange(e, 'optionsEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'optionsEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['optionEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'optionsEdit',
																		)
																	}
																	value={optionsEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'optionsEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'optionsEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('options')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (<tr className='items-list' key={option.id}>
														<td className='serial_no'>{index + 1}</td>
														<td>
															<span>{option.item}</span>
														</td>

														<td className='amount'>
															<span>
																{' '}
																<strong>
																	<NumberFormat
																		thousandSeparator={true}
																		prefix={currencySymbol}
																		displayType={'text'}
																		value={option.amount ? option.amount : 0}
																	/>
																</strong>
															</span>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="edit-icon-input" onClick={() => setOptionsEdit({ ...optionsEdit, show: true, editID: option.id, ...option })}><img src={_images.editIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={e => handleRemoveItem('options', option)}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>)}
												</Fragment>
											))}
											{showOptions && (
												<tr className='items-list'>
													<td className='serial_no'>{options.length === 0 ? '1' : options.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['optionInputitem']}`}
															name='item'
															value={optionInput.item}
															onChange={e => handleChange(e, 'options')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'options')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['optionInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'options',
																)
															}
															value={optionInput.amount}
															onKeyPress={e => handleKeyPress(e, 'options')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'options')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowOptions(false)
																setOptionInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div>
								</div>
							</div>

							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Accessories</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowAccessories(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='40%'>Item</th>
												<th width='10%'>Quantity</th>
												<th width='15%'>Rate</th>
												<th width='20%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{accessories.length > 0 && accessories.map((_accessories, index) => (
												<Fragment key={_accessories.id}>
													{AccessoriesEdit.show && AccessoriesEdit.editID === _accessories.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{accessories.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['AccessoriesEditInputitem']}`}
																	name='item'
																	value={AccessoriesEdit.item}
																	onChange={e => handleChange(e, 'AccessoriesEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'AccessoriesEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	placeholder='0'
																	className={`input-enq ${errorFields['AccessoriesEditInputquantity']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'quantity',
																			'AccessoriesEdit',
																		)
																	}
																	value={AccessoriesEdit.quantity}
																	onKeyPress={e => handleKeyPress(e, 'AccessoriesEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['AccessoriesEditInputrate']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'rate',
																			'AccessoriesEdit',
																		)
																	}
																	value={AccessoriesEdit.rate}
																	onKeyPress={e => handleKeyPress(e, 'AccessoriesEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq input-enq-autofill ${errorFields['AccessoriesEditInputamount']}`}
																	decimalScale={2}
																	// onValueChange={e =>
																	// 	onAmountChange(
																	// 		e,
																	// 		'amount',
																	// 		'accessories',
																	// 	)
																	// }
																	value={AccessoriesEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'AccessoriesEdit')}
																	disabled={true}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'AccessoriesEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('Accessories')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (

														<tr className='items-list' key={_accessories.id}>
															<td className='serial_no'>{index + 1}</td>
															<td>
																<span>{_accessories.item}</span>
															</td>
															<td>
																<span>{_accessories.quantity}</span>
															</td>
															<td>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_accessories.rate ? _accessories.rate : 0}
																		/>
																	</strong>
																</span>
															</td>


															<td className='amount'>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_accessories.amount ? _accessories.amount : 0}
																		/>
																	</strong>
																</span>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="edit-icon-input" onClick={() => setAccessoriesEdit({ ...AccessoriesEdit, show: true, editID: _accessories.id, ..._accessories })}><img src={_images.editIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={e => handleRemoveItem('accessories', _accessories)}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													)}
												</Fragment>
											))}
											{showAccessories && (
												<tr className='items-list'>
													<td className='serial_no'>{accessories.length === 0 ? '1' : accessories.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['accessoriesInputitem']}`}
															name='item'
															value={accessoriesInput.item}
															onChange={e => handleChange(e, 'accessories')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'accessories')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															placeholder='0'
															className={`input-enq ${errorFields['accessoriesInputquantity']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'quantity',
																	'accessories',
																)
															}
															value={accessoriesInput.quantity}
															onKeyPress={e => handleKeyPress(e, 'accessories')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['accessoriesInputrate']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'rate',
																	'accessories',
																)
															}
															value={accessoriesInput.rate}
															onKeyPress={e => handleKeyPress(e, 'accessories')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq input-enq-autofill ${errorFields['accessoriesInputamount']}`}
															// decimalScale={2}
															// onValueChange={e =>
															// 	onAmountChange(
															// 		e,
															// 		'amount',
															// 		'accessories',
															// 	)
															// }
															value={accessoriesInput.amount}
															onKeyPress={e => handleKeyPress(e, 'accessories')}
															disabled={true}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'accessories')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowAccessories(false)
																setAccessoriesInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}

										</tbody>
									</table>
								</div>
								<div></div>
							</div>

							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong> After Market </strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowAfterMarket(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='40%'>Item</th>
												<th width='10%'>Quantity</th>
												<th width='15%'>Rate</th>
												<th width='20%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{afterMarket.length > 0 && afterMarket.map((_afterMarket, index) => (
												<Fragment key={_afterMarket.id}>
													{AfterMarketEdit.show && AfterMarketEdit.editID === _afterMarket.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{afterMarket.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['AfterMarketEditInputitem']}`}
																	name='item'
																	value={AfterMarketEdit.item}
																	onChange={e => handleChange(e, 'AfterMarketEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'AfterMarketEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	placeholder='0'
																	className={`input-enq ${errorFields['AfterMarketEditInputquantity']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'quantity',
																			'AfterMarketEdit',
																		)
																	}
																	value={AfterMarketEdit.quantity}
																	onKeyPress={e => handleKeyPress(e, 'AfterMarketEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['AfterMarketEditInputrate']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'rate',
																			'AfterMarketEdit',
																		)
																	}
																	value={AfterMarketEdit.rate}
																	onKeyPress={e => handleKeyPress(e, 'AfterMarketEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq input-enq-autofill ${errorFields['AfterMarketEditInputamount']}`}
																	// decimalScale={2}
																	// onValueChange={e =>
																	// 	onAmountChange(
																	// 		e,
																	// 		'amount',
																	// 		'afterMarket',
																	// 	)
																	// }
																	value={AfterMarketEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'AfterMarketEdit')}
																	disabled={true}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'AfterMarketEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('AfterMarket')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (

														<tr className='items-list' key={_afterMarket.id}>
															<td className='serial_no'>{index + 1}</td>
															<td>
																<span>{_afterMarket.item}</span>
															</td>
															<td>
																<span>{_afterMarket.quantity}</span>
															</td>
															<td>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_afterMarket.rate ? _afterMarket.rate : 0}
																		/>
																	</strong>
																</span>
															</td>


															<td className='amount'>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_afterMarket.amount ? _afterMarket.amount : 0}
																		/>
																	</strong>
																</span>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="edit-icon-input" onClick={() => setAfterMarketEdit({ ...AfterMarketEdit, show: true, editID: _afterMarket.id, ..._afterMarket })}><img src={_images.editIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={e => handleRemoveItem('afterMarket', _afterMarket)}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													)}
												</Fragment>
											))}
											{showAfterMarket && (
												<tr className='items-list'>
													<td className='serial_no'>{afterMarket.length === 0 ? '1' : afterMarket.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['afterMarketInputitem']}`}
															name='item'
															value={afterMarketInput.item}
															onChange={e => handleChange(e, 'afterMarket')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'afterMarket')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															placeholder='0'
															className={`input-enq ${errorFields['afterMarketInputquantity']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'quantity',
																	'afterMarket',
																)
															}
															value={afterMarketInput.quantity}
															onKeyPress={e => handleKeyPress(e, 'afterMarket')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['afterMarketInputrate']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'rate',
																	'afterMarket',
																)
															}
															value={afterMarketInput.rate}
															onKeyPress={e => handleKeyPress(e, 'afterMarket')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq input-enq-autofill ${errorFields['afterMarketInputamount']}`}
															// decimalScale={2}
															// onValueChange={e =>
															// 	onAmountChange(
															// 		e,
															// 		'amount',
															// 		'afterMarket',
															// 	)
															// }
															value={afterMarketInput.amount}
															onKeyPress={e => handleKeyPress(e, 'afterMarket')}
															disabled={true}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'afterMarket')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowAfterMarket(false)
																setAfterMarketInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}

										</tbody>
									</table>
								</div>
								<div></div>
							</div>

							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Dealer Delivery</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowdealerdelivery(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{dealerdelivery.length > 0 && dealerdelivery.map((dealerdelivery, index) => (
												<Fragment key={dealerdelivery.id}>
													{dealerdeliveryEdit.show && dealerdeliveryEdit.editID === dealerdelivery.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{dealerdelivery.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['dealerdeliveryEditInputitem']}`}
																	name='item'
																	value={dealerdeliveryEdit.item}
																	onChange={e => handleChange(e, 'dealerdeliveryEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'dealerdeliveryEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['dealerdeliveryEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'dealerdeliveryEdit',
																		)
																	}
																	value={dealerdeliveryEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'dealerdeliveryEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'dealerdeliveryEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('dealerdelivery')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (<tr className='items-list' key={dealerdelivery.id}>
														<td className='serial_no'>{index + 1}</td>
														<td>
															<span>{dealerdelivery.item}</span>
														</td>

														<td className='amount'>
															<span>
																{' '}
																<strong>
																	<NumberFormat
																		thousandSeparator={true}
																		prefix={currencySymbol}
																		displayType={'text'}
																		value={dealerdelivery.amount ? dealerdelivery.amount : 0}
																	/>
																</strong>
															</span>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="edit-icon-input" onClick={() => setdealerdeliveryEdit({ ...dealerdeliveryEdit, show: true, editID: dealerdelivery.id, ...dealerdelivery })}><img src={_images.editIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={e => handleRemoveItem('dealerdelivery', dealerdelivery)}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>)}
												</Fragment>
											))}
											{showdealerdelivery && (
												<tr className='items-list'>
													<td className='serial_no'>{dealerdelivery.length === 0 ? '1' : dealerdelivery.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['dealerdeliveryInputitem']}`}
															name='item'
															value={dealerdeliveryInput.item}
															onChange={e => handleChange(e, 'dealerdelivery')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'dealerdelivery')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['dealerdeliveryInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'dealerdelivery',
																)
															}
															value={dealerdeliveryInput.amount}
															onKeyPress={e => handleKeyPress(e, 'dealerdelivery')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'dealerdelivery')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowdealerdelivery(false)
																setdealerdeliveryInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div></div>
							</div>

							{/* <div className='sub-section-wraper'>
								<div className='section-head'>
									<strong> Allowance</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowAllowance(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{allowance.length > 0 && allowance.map((_allowance, index) => (
												<Fragment key={_allowance.id}>
													{allowanceEdit.show && allowanceEdit.editID === _allowance.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{allowance.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['allowanceEditInputitem']}`}
																	name='item'
																	value={allowanceEdit.item}
																	onChange={e => handleChange(e, 'allowanceEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'allowanceEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['allowanceEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'allowanceEdit',
																		)
																	}
																	value={allowanceEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'allowanceEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'allowanceEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('allowance')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (
														<tr className='items-list' key={_allowance.id}>
															<td className='serial_no'>{index + 1}</td>
															<td>
																<span>{_allowance.item}</span>
															</td>

															<td className='amount'>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={_allowance.amount ? _allowance.amount : 0}
																		/>
																	</strong>
																</span>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="edit-icon-input" onClick={() => setAllowanceEdit({ ...allowanceEdit, show: true, editID: _allowance.id, ..._allowance })}><img src={_images.editIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={e => handleRemoveItem('allowance', _allowance)}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													)}
												</Fragment>
											))}
											{showAllowance && (
												<tr className='items-list'>
													<td className='serial_no'>{allowance.length === 0 ? '1' : allowance.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['allowanceInputitem']}`}
															name='item'
															value={allowanceInput.item}
															onChange={e => handleChange(e, 'allowance')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'allowance')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['allowanceInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'allowance',
																)
															}
															value={allowanceInput.amount}
															onKeyPress={e => handleKeyPress(e, 'allowance')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'allowance')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowAllowance(false)
																setAllowanceInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div></div>
							</div> */}
						</>
					)}

					{/* Services Start */}
					{/* {state.type !== 'vehicleContract' && (
						<div className='sub-section-wraper'>
							<div className='section-head'>
								<strong>Services</strong>

								<div className='addmore-field'>
									<button className='addmore-field-btn' type='button' onClick={() => setShowservices(true)}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='16'
											viewBox='0 0 16 16'
										>
											<g
												id='plus-square-outline'
												transform='translate(-.266 .217)'
											>
												<g
													id='Rectangle_1143'
													fill='rgba(255,255,255,0)'
													stroke='#000'
													transform='translate(.266 -.217)'
												>
													<rect
														width='16'
														height='16'
														stroke='none'
														rx='3'
													></rect>
													<rect
														width='15'
														height='15'
														x='.5'
														y='.5'
														fill='none'
														rx='2.5'
													></rect>
												</g>
												<g id='Group_588' transform='translate(5.264 4.783)'>
													<path
														id='Line_109'
														d='M0 0L0 6'
														stroke='#000'
														fill='none'
														strokeLinecap='round'
														transform='translate(3)'
													></path>
													<path
														id='Line_110'
														d='M0 0L0 6'
														stroke='#000'
														fill='none'
														strokeLinecap='round'
														transform='rotate(90 1.5 4.5)'
													></path>
												</g>
											</g>
										</svg>
										Add More
									</button>
								</div>
							</div>

							<div className='sub-section-details'>
								<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
									<thead>
										<tr>
											<th width='5%'>S.N.</th>
											<th width='70%'>Item</th>
											<th width='15%'>Amount</th>
											<th width='10%'></th>
										</tr>
									</thead>
									<tbody>
										{services.length > 0 && services.map((services, index) => (
											<Fragment key={services.id}>
												{servicesEdit.show && servicesEdit.editID === services.id ? (
													<tr className='items-list'>
														<td className='serial_no'>{services.length === 0 ? '1' : index + 1}</td>
														<td>
															<input
																autoComplete="off"
																type='text'
																className={`input-enq ${servicesEdit.titleReadonly ? 'input-enq-autofill' : ''} ${errorFields['servicesEditInputitem']}`}
																name='item'
																value={servicesEdit.item}
																onChange={e => handleChange(e, 'servicesEdit')}
																placeholder='Add Item'
																onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
																disabled={servicesEdit.titleReadonly ? true : false}
															/>
														</td>

														<td className='amount'>
															<NumberFormat
																allowNegative={false}
																thousandSeparator={true}
																prefix={currencySymbol}
																placeholder={currencySymbol}
																className={`input-enq ${errorFields['servicesEditInputamount']}`}
																decimalScale={2}
																onValueChange={e =>
																	onAmountChange(
																		e,
																		'amount',
																		'servicesEdit',
																	)
																}
																value={servicesEdit.amount}
																onKeyPress={e => handleKeyPress(e, 'servicesEdit')}
															/>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'servicesEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={() => {
																	handleClearEdit('services')
																}}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>
												) : (<tr className='items-list' key={services.id}>
													<td className='serial_no'>{index + 1}</td>
													<td>
														<span>{services.item}</span>
													</td>

													<td className='amount'>
														<span>
															{' '}
															<strong>
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={services.amount ? services.amount : 0}
																/>
															</strong>
														</span>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="edit-icon-input" onClick={() => setservicesEdit({ ...servicesEdit, show: true, editID: services.id, ...services })}><img src={_images.editIcon} alt="" width="14" /></a>
															{!services.titleReadonly ? (
																<button type='button' className='close-btn' onClick={e => handleRemoveItem('services', services)}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															) : (<></>)}

														</div>
													</td>
												</tr>)}
											</Fragment>
										))}
										{showservices && (
											<tr className='items-list'>
												<td className='serial_no'>{services.length === 0 ? '1' : services.length + 1}</td>
												<td>
													<input
														autoComplete="off"
														type='text'
														className={`input-enq ${errorFields['servicesInputitem']}`}
														name='item'
														value={servicesInput.item}
														onChange={e => handleChange(e, 'services')}
														placeholder='Add Item'
														onKeyPress={e => handleKeyPress(e, 'services')}
													// disabled={true}
													/>
												</td>

												<td className='amount'>
													<NumberFormat
														allowNegative={false}
														thousandSeparator={true}
														prefix={currencySymbol}
														placeholder={currencySymbol}
														className={`input-enq ${errorFields['servicesInputamount']}`}
														decimalScale={2}
														onValueChange={e =>
															onAmountChange(
																e,
																'amount',
																'services',
															)
														}
														value={servicesInput.amount}
														onKeyPress={e => handleKeyPress(e, 'services')}
													/>
												</td>
												<td>
													<div className='form-controls-td'>
														<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'services')}><img src={_images.plusIcon} alt="" width="14" /></a>
														<button type='button' className='close-btn' onClick={() => {
															setShowservices(false)
															setservicesInput(optionsVM)
														}}>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
															>
																<g id='x' transform='translate(8 8)'>
																	<path
																		id='Line_109'
																		d='M0 0L0.001 11.313'
																		className='cls-1'
																		data-name='Line 109'
																		transform='rotate(135 2.343 5.656)'
																		fill='none'
																		stroke='#733dd9'
																		strokeLinecap='round'
																		strokeWidth='1'
																	></path>
																	<path
																		id='Line_110'
																		d='M0.001 0L0 11.313'
																		className='cls-1'
																		data-name='Line 110'
																		transform='rotate(-135 1.658 4)'
																		fill='none'
																		stroke='#733dd9'
																		strokeLinecap='round'
																		strokeWidth='1'
																	></path>
																</g>
															</svg>
														</button>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div></div>
						</div>
					)} */}
					{/* Services End */}

					{state.type !== 'vehicleContract' && (
						<div className='total-estimate'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>
									<tr>
										<td className='costtxt'>Total</td>
										<td className='cost-box'>
											<strong><NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.subTotalExGSTDiscounts ? state.subTotalExGSTDiscounts : 0}
											/></strong>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}

					{state.type !== 'vehicleContract' && (
						<div className='sub-section-wraper'>
							<div className='section-head'>
								<strong>Discounts</strong>

								<div className='addmore-field'>
									<button className='addmore-field-btn' type='button' onClick={() => setShowdiscounts(true)}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='16'
											height='16'
											viewBox='0 0 16 16'
										>
											<g
												id='plus-square-outline'
												transform='translate(-.266 .217)'
											>
												<g
													id='Rectangle_1143'
													fill='rgba(255,255,255,0)'
													stroke='#000'
													transform='translate(.266 -.217)'
												>
													<rect
														width='16'
														height='16'
														stroke='none'
														rx='3'
													></rect>
													<rect
														width='15'
														height='15'
														x='.5'
														y='.5'
														fill='none'
														rx='2.5'
													></rect>
												</g>
												<g id='Group_588' transform='translate(5.264 4.783)'>
													<path
														id='Line_109'
														d='M0 0L0 6'
														stroke='#000'
														fill='none'
														strokeLinecap='round'
														transform='translate(3)'
													></path>
													<path
														id='Line_110'
														d='M0 0L0 6'
														stroke='#000'
														fill='none'
														strokeLinecap='round'
														transform='rotate(90 1.5 4.5)'
													></path>
												</g>
											</g>
										</svg>
										Add More
									</button>
								</div>
							</div>

							<div className='sub-section-details'>
								<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
									<thead>
										<tr>
											<th width='5%'>S.N.</th>
											<th width='70%'>Item</th>
											<th width='15%'>Amount</th>
											<th width='10%'></th>
										</tr>
									</thead>
									<tbody>
										{discounts.length > 0 && discounts.map((discounts, index) => (
											<Fragment key={discounts.id}>
												{discountsEdit.show && discountsEdit.editID === discounts.id ? (
													<tr className='items-list'>
														<td className='serial_no'>{discounts.length === 0 ? '1' : index + 1}</td>
														<td>
															<input
																autoComplete="off"
																type='text'
																className={`input-enq ${errorFields['discountsEditInputitem']}`}
																name='item'
																value={discountsEdit.item}
																onChange={e => handleChange(e, 'discountsEdit')}
																placeholder='Add Item'
																onKeyPress={e => handleKeyPress(e, 'discountsEdit')}
															/>
														</td>

														<td className='amount'>
															<NumberFormat
																allowNegative={false}
																thousandSeparator={true}
																prefix={currencySymbol}
																placeholder={currencySymbol}
																className={`input-enq ${errorFields['discountsEditInputamount']}`}
																decimalScale={2}
																onValueChange={e =>
																	onAmountChange(
																		e,
																		'amount',
																		'discountsEdit',
																	)
																}
																value={discountsEdit.amount}
																onKeyPress={e => handleKeyPress(e, 'discountsEdit')}
															/>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'discountsEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={() => {
																	handleClearEdit('discounts')
																}}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>
												) : (<tr className='items-list' key={discounts.id}>
													<td className='serial_no'>{index + 1}</td>
													<td>
														<span>{discounts.item}</span>
													</td>

													<td className='amount'>
														<span>
															{' '}
															<strong>
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={discounts.amount ? discounts.amount : 0}
																/>
															</strong>
														</span>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="edit-icon-input" onClick={() => setdiscountsEdit({ ...discountsEdit, show: true, editID: discounts.id, ...discounts })}><img src={_images.editIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={e => handleRemoveItem('discounts', discounts)}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>)}
											</Fragment>
										))}
										{showdiscounts && (
											<tr className='items-list'>
												<td className='serial_no'>{discounts.length === 0 ? '1' : discounts.length + 1}</td>
												<td>
													<input
														autoComplete="off"
														type='text'
														className={`input-enq ${errorFields['discountsInputitem']}`}
														name='item'
														value={discountsInput.item}
														onChange={e => handleChange(e, 'discounts')}
														placeholder='Add Item'
														onKeyPress={e => handleKeyPress(e, 'discounts')}
													/>
												</td>

												<td className='amount'>
													<NumberFormat
														allowNegative={false}
														thousandSeparator={true}
														prefix={currencySymbol}
														placeholder={currencySymbol}
														className={`input-enq ${errorFields['discountsInputamount']}`}
														decimalScale={2}
														onValueChange={e =>
															onAmountChange(
																e,
																'amount',
																'discounts',
															)
														}
														value={discountsInput.amount}
														onKeyPress={e => handleKeyPress(e, 'discounts')}
													/>
												</td>
												<td>
													<div className='form-controls-td'>
														<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'discounts')}><img src={_images.plusIcon} alt="" width="14" /></a>
														<button type='button' className='close-btn' onClick={() => {
															setShowdiscounts(false)
															setdiscountsInput(optionsVM)
														}}>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
															>
																<g id='x' transform='translate(8 8)'>
																	<path
																		id='Line_109'
																		d='M0 0L0.001 11.313'
																		className='cls-1'
																		data-name='Line 109'
																		transform='rotate(135 2.343 5.656)'
																		fill='none'
																		stroke='#733dd9'
																		strokeLinecap='round'
																		strokeWidth='1'
																	></path>
																	<path
																		id='Line_110'
																		d='M0.001 0L0 11.313'
																		className='cls-1'
																		data-name='Line 110'
																		transform='rotate(-135 1.658 4)'
																		fill='none'
																		stroke='#733dd9'
																		strokeLinecap='round'
																		strokeWidth='1'
																	></path>
																</g>
															</svg>
														</button>
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div></div>
						</div>
					)}

					<div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								{/* {allowance.length > 0 && (
									<tr>
										<td className='costtxt'>Allowance</td>
										<td className='cost-box'>- <NumberFormat
											thousandSeparator={true}
											prefix={currencySymbol}
											displayType={'text'}
											value={allowance.reduce((accumulator, currentValue) => {
												return accumulator + currentValue.amount;
											}, 0)}
										/></td>
									</tr>
								)} */}
								{state.gstPercentage && (
									<>
										<tr>
											<td className='costtxt'>{state.type === 'vehicleContract' ? 'Vehicle Sub Total excluding ' : 'Vehicle Total excluding '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
											<td className='cost-box'><NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.subTotalExGST}
											/></td>
										</tr>
										<tr>
											<td className='costtxt'>{state.taxType === 'VAT' ? 'VAT' : state.taxType} payable</td>
											<td className='cost-box'><NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.gstPayable}
											/></td>
										</tr>
										<tr>
											<td className='costtxt'>{'Vehicle Total inclusive of '}{state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
											<td className='cost-box'>
												<strong><NumberFormat
													thousandSeparator={true}
													prefix={currencySymbol}
													displayType={'text'}
													value={state.subTotalIncGST}
												/></strong>
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
					</div>

					{state.type === 'vehicleContract' && (
						<>
							{/* <div className='sub-section-wraper'>
								<div className='section-head'>
									<strong> {state.taxType === 'VAT' ? 'VAT' : state.taxType} Exclusive Items</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowVatExclusiveItems(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{vatExclusiveItems.length > 0 && vatExclusiveItems.map((vatExclusiveItem, index) => (
												<Fragment key={vatExclusiveItem.id}>
													{VatExclusiveItemsEdit.show && VatExclusiveItemsEdit.editID === vatExclusiveItem.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{vatExclusiveItems.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['VatExclusiveItemsEditInputitem']}`}
																	name='item'
																	value={VatExclusiveItemsEdit.item}
																	onChange={e => handleChange(e, 'VatExclusiveItemsEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'VatExclusiveItemsEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['vatExclusiveItemsEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'VatExclusiveItemsEdit',
																		)
																	}
																	value={VatExclusiveItemsEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'VatExclusiveItemsEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'VatExclusiveItemsEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('VatExclusiveItems')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (
														<tr className='items-list' key={vatExclusiveItem.id}>
															<td className='serial_no'>{index + 1}</td>
															<td>
																<span>{vatExclusiveItem.item}</span>
															</td>

															<td className='amount'>
																<span>
																	{' '}
																	<strong>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={currencySymbol}
																			displayType={'text'}
																			value={vatExclusiveItem.amount ? vatExclusiveItem.amount : 0}
																		/>
																	</strong>
																</span>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="edit-icon-input" onClick={() => setVatExclusiveItemsEdit({ ...VatExclusiveItemsEdit, show: true, editID: vatExclusiveItem.id, ...vatExclusiveItem })}><img src={_images.editIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={e => handleRemoveItem('vatExclusiveItems', vatExclusiveItem)}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													)}
												</Fragment>
											))}
											{showVatExclusiveItems && (
												<tr className='items-list'>
													<td className='serial_no'>{vatExclusiveItems.length === 0 ? '1' : vatExclusiveItems.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['vatExclusiveItemsInputitem']}`}
															name='item'
															value={vatExclusiveItemsInput.item}
															onChange={e => handleChange(e, 'vatExclusiveItems')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'vatExclusiveItems')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['vatExclusiveItemsInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'vatExclusiveItems',
																)
															}
															value={vatExclusiveItemsInput.amount}
															onKeyPress={e => handleKeyPress(e, 'vatExclusiveItems')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'vatExclusiveItems')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowVatExclusiveItems(false)
																setVatExclusiveItemsInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div></div>
							</div> */}

							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Other Insurance</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowinsurance(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{insurance.length > 0 && insurance.map((insurance, index) => (
												<Fragment key={insurance.id}>
													{insuranceEdit.show && insuranceEdit.editID === insurance.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{insurance.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['insuranceEditInputitem']}`}
																	name='item'
																	value={insuranceEdit.item}
																	onChange={e => handleChange(e, 'insuranceEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'insuranceEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['insuranceEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'insuranceEdit',
																		)
																	}
																	value={insuranceEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'insuranceEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'insuranceEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('insurance')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (<tr className='items-list' key={insurance.id}>
														<td className='serial_no'>{index + 1}</td>
														<td>
															<span>{insurance.item}</span>
														</td>

														<td className='amount'>
															<span>
																{' '}
																<strong>
																	<NumberFormat
																		thousandSeparator={true}
																		prefix={currencySymbol}
																		displayType={'text'}
																		value={insurance.amount ? insurance.amount : 0}
																	/>
																</strong>
															</span>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="edit-icon-input" onClick={() => setinsuranceEdit({ ...insuranceEdit, show: true, editID: insurance.id, ...insurance })}><img src={_images.editIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={e => handleRemoveItem('insurance', insurance)}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>)}
												</Fragment>
											))}
											{showinsurance && (
												<tr className='items-list'>
													<td className='serial_no'>{insurance.length === 0 ? '1' : insurance.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['insuranceInputitem']}`}
															name='item'
															value={insuranceInput.item}
															onChange={e => handleChange(e, 'insurance')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'insurance')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['insuranceInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'insurance',
																)
															}
															value={insuranceInput.amount}
															onKeyPress={e => handleKeyPress(e, 'insurance')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'insurance')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowinsurance(false)
																setinsuranceInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div></div>
							</div>
						</>
					)}
					{state.type === 'vehicleContract' && (
						<div className='total-estimate'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<tbody>
									{/* <tr>
										<td className='costtxt'>Vehicle Total inclusive of {state.taxType === 'VAT' ? 'VAT' : state.taxType}</td>
										<td className='cost-box'>
											<strong><NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.totalIncGST}
											/></strong>
										</td>
									</tr> */}
									<tr>
										<td className='costtxt-blue'>Balance Payable on Delivery</td>
										<td className='cost-box total'>
											<strong><NumberFormat
												thousandSeparator={true}
												prefix={currencySymbol}
												displayType={'text'}
												value={state.payableOnDelivery}
											/></strong>
										</td>
									</tr>
								</tbody>
							</table>

						</div>
					)}

					{/* {state.type === 'vehicleContract' && (
						<>
							<div className='sub-section-wraper'>
								<div className='section-head'>
									<strong>Trade(s)</strong>

									<div className='addmore-field'>
										<button className='addmore-field-btn' type='button' onClick={() => setShowtrades(true)}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='16'
												height='16'
												viewBox='0 0 16 16'
											>
												<g
													id='plus-square-outline'
													transform='translate(-.266 .217)'
												>
													<g
														id='Rectangle_1143'
														fill='rgba(255,255,255,0)'
														stroke='#000'
														transform='translate(.266 -.217)'
													>
														<rect
															width='16'
															height='16'
															stroke='none'
															rx='3'
														></rect>
														<rect
															width='15'
															height='15'
															x='.5'
															y='.5'
															fill='none'
															rx='2.5'
														></rect>
													</g>
													<g id='Group_588' transform='translate(5.264 4.783)'>
														<path
															id='Line_109'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='translate(3)'
														></path>
														<path
															id='Line_110'
															d='M0 0L0 6'
															stroke='#000'
															fill='none'
															strokeLinecap='round'
															transform='rotate(90 1.5 4.5)'
														></path>
													</g>
												</g>
											</svg>
										Add More
									</button>
									</div>
								</div>

								<div className='sub-section-details'>
									<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
										<thead>
											<tr>
												<th width='5%'>S.N.</th>
												<th width='70%'>Item</th>
												<th width='15%'>Amount</th>
												<th width='10%'></th>
											</tr>
										</thead>
										<tbody>
											{trades.length > 0 && trades.map((trades, index) => (
												<Fragment key={trades.id}>
													{tradesEdit.show && tradesEdit.editID === trades.id ? (
														<tr className='items-list'>
															<td className='serial_no'>{trades.length === 0 ? '1' : index + 1}</td>
															<td>
																<input
																	autoComplete="off"
																	type='text'
																	className={`input-enq ${errorFields['tradesEditInputitem']}`}
																	name='item'
																	value={tradesEdit.item}
																	onChange={e => handleChange(e, 'tradesEdit')}
																	placeholder='Add Item'
																	onKeyPress={e => handleKeyPress(e, 'tradesEdit')}
																/>
															</td>

															<td className='amount'>
																<NumberFormat
																	allowNegative={false}
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	placeholder={currencySymbol}
																	className={`input-enq ${errorFields['tradesEditInputamount']}`}
																	decimalScale={2}
																	onValueChange={e =>
																		onAmountChange(
																			e,
																			'amount',
																			'tradesEdit',
																		)
																	}
																	value={tradesEdit.amount}
																	onKeyPress={e => handleKeyPress(e, 'tradesEdit')}
																/>
															</td>
															<td>
																<div className='form-controls-td'>
																	<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'tradesEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
																	<button type='button' className='close-btn' onClick={() => {
																		handleClearEdit('trades')
																	}}>
																		<svg
																			xmlns='http://www.w3.org/2000/svg'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																		>
																			<g id='x' transform='translate(8 8)'>
																				<path
																					id='Line_109'
																					d='M0 0L0.001 11.313'
																					className='cls-1'
																					data-name='Line 109'
																					transform='rotate(135 2.343 5.656)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																				<path
																					id='Line_110'
																					d='M0.001 0L0 11.313'
																					className='cls-1'
																					data-name='Line 110'
																					transform='rotate(-135 1.658 4)'
																					fill='none'
																					stroke='#733dd9'
																					strokeLinecap='round'
																					strokeWidth='1'
																				></path>
																			</g>
																		</svg>
																	</button>
																</div>
															</td>
														</tr>
													) : (<tr className='items-list' key={trades.id}>
														<td className='serial_no'>{index + 1}</td>
														<td>
															<span>{trades.item}</span>
														</td>

														<td className='amount'>
															<span>
																{' '}
																<strong>
																	<NumberFormat
																		thousandSeparator={true}
																		prefix={currencySymbol}
																		displayType={'text'}
																		value={trades.amount ? trades.amount : 0}
																	/>
																</strong>
															</span>
														</td>
														<td>
															<div className='form-controls-td'>
																<a className="edit-icon-input" onClick={() => settradesEdit({ ...tradesEdit, show: true, editID: trades.id, ...trades })}><img src={_images.editIcon} alt="" width="14" /></a>
																<button type='button' className='close-btn' onClick={e => handleRemoveItem('trades', trades)}>
																	<svg
																		xmlns='http://www.w3.org/2000/svg'
																		width='24'
																		height='24'
																		viewBox='0 0 24 24'
																	>
																		<g id='x' transform='translate(8 8)'>
																			<path
																				id='Line_109'
																				d='M0 0L0.001 11.313'
																				className='cls-1'
																				data-name='Line 109'
																				transform='rotate(135 2.343 5.656)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																			<path
																				id='Line_110'
																				d='M0.001 0L0 11.313'
																				className='cls-1'
																				data-name='Line 110'
																				transform='rotate(-135 1.658 4)'
																				fill='none'
																				stroke='#733dd9'
																				strokeLinecap='round'
																				strokeWidth='1'
																			></path>
																		</g>
																	</svg>
																</button>
															</div>
														</td>
													</tr>)}
												</Fragment>
											))}
											{showtrades && (
												<tr className='items-list'>
													<td className='serial_no'>{trades.length === 0 ? '1' : trades.length + 1}</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['tradesInputitem']}`}
															name='item'
															value={tradesInput.item}
															onChange={e => handleChange(e, 'trades')}
															placeholder='Add Item'
															onKeyPress={e => handleKeyPress(e, 'trades')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['tradesInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'trades',
																)
															}
															value={tradesInput.amount}
															onKeyPress={e => handleKeyPress(e, 'trades')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'trades')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																setShowtrades(false)
																settradesInput(optionsVM)
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div></div>
							</div>
						</>
					)} */}

					{/* <div className='sub-section-wraper'>
						<div className='section-head'>
							<strong> Less Deposit </strong>

							<div className='addmore-field'>
								<button className='addmore-field-btn' type='button' onClick={() => setShowLessDeposit(true)}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 16 16'
									>
										<g
											id='plus-square-outline'
											transform='translate(-.266 .217)'
										>
											<g
												id='Rectangle_1143'
												fill='rgba(255,255,255,0)'
												stroke='#000'
												transform='translate(.266 -.217)'
											>
												<rect
													width='16'
													height='16'
													stroke='none'
													rx='3'
												></rect>
												<rect
													width='15'
													height='15'
													x='.5'
													y='.5'
													fill='none'
													rx='2.5'
												></rect>
											</g>
											<g id='Group_588' transform='translate(5.264 4.783)'>
												<path
													id='Line_109'
													d='M0 0L0 6'
													stroke='#000'
													fill='none'
													strokeLinecap='round'
													transform='translate(3)'
												></path>
												<path
													id='Line_110'
													d='M0 0L0 6'
													stroke='#000'
													fill='none'
													strokeLinecap='round'
													transform='rotate(90 1.5 4.5)'
												></path>
											</g>
										</g>
									</svg>
										Add More
									</button>
							</div>
						</div>

						<div className='sub-section-details'>
							<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
								<thead>
									<tr>
										<th width='5%'>S.N.</th>
										<th width='40%'>Date</th>
										<th width='30%'>Reference</th>

										<th width='15%'>Amount</th>
										<th width='10%'></th>
									</tr>
								</thead>
								<tbody>
									{lessDeposit.length > 0 && lessDeposit.map((_lessDeposit, index) => (
										<Fragment key={_lessDeposit.id}>
											{LessDepositEdit.show && LessDepositEdit.editID === _lessDeposit.id ? (
												<tr className='items-list'>
													<td className='serial_no'>{lessDeposit.length === 0 ? '1' : index + 1}</td>
													<td>
														<AntDatePicker
															value={LessDepositEdit.date ? moment.unix(LessDepositEdit.date.seconds).format('YYYY-MM-DD') : null}
															name={'LessDepositEditInput.date'}
															onChange={(e) => { handleDateChange(e, 'LessDepositEditInput.date') }}
															format='DD/MM/YYYY'
															placeholder='DD/MM/YYYY'
															className={`input-enq ${errorFields['LessDepositEditInputdate']}`}
														/>
													</td>
													<td>
														<input
															autoComplete="off"
															type='text'
															className={`input-enq ${errorFields['LessDepositEditInputreference']}`}
															name='reference'
															value={LessDepositEdit.reference}
															onChange={e => handleChange(e, 'LessDepositEdit')}
															placeholder='Add Reference'
															onKeyPress={e => handleKeyPress(e, 'LessDepositEdit')}
														/>
													</td>

													<td className='amount'>
														<NumberFormat
															allowNegative={false}
															thousandSeparator={true}
															prefix={currencySymbol}
															placeholder={currencySymbol}
															className={`input-enq ${errorFields['LessDepositEditInputamount']}`}
															decimalScale={2}
															onValueChange={e =>
																onAmountChange(
																	e,
																	'amount',
																	'LessDepositEdit',
																)
															}
															value={LessDepositEdit.amount}
															onKeyPress={e => handleKeyPress(e, 'LessDepositEdit')}
														/>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'LessDepositEdit')}><img src={_images.plusIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={() => {
																handleClearEdit('LessDeposit')
															}}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											) : (
												<tr className='items-list' key={_lessDeposit.id}>
													<td className='serial_no'>{index + 1}</td>
													<td>
														<span>{moment(_lessDeposit.date.toDate()).format('DD/MM/YYYY')}</span>
													</td>
													<td>
														<span>{_lessDeposit.reference ? _lessDeposit.reference : '--'}</span>
													</td>

													<td className='amount'>
														<span>
															{' '}
															<strong>
																<NumberFormat
																	thousandSeparator={true}
																	prefix={currencySymbol}
																	displayType={'text'}
																	value={_lessDeposit.amount ? _lessDeposit.amount : 0}
																/>
															</strong>
														</span>
													</td>
													<td>
														<div className='form-controls-td'>
															<a className="edit-icon-input" onClick={() => setLessDepositEdit({ ...LessDepositEdit, show: true, editID: _lessDeposit.id, ..._lessDeposit })}><img src={_images.editIcon} alt="" width="14" /></a>
															<button type='button' className='close-btn' onClick={e => handleRemoveItem('lessDeposit', _lessDeposit)}>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	width='24'
																	height='24'
																	viewBox='0 0 24 24'
																>
																	<g id='x' transform='translate(8 8)'>
																		<path
																			id='Line_109'
																			d='M0 0L0.001 11.313'
																			className='cls-1'
																			data-name='Line 109'
																			transform='rotate(135 2.343 5.656)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																		<path
																			id='Line_110'
																			d='M0.001 0L0 11.313'
																			className='cls-1'
																			data-name='Line 110'
																			transform='rotate(-135 1.658 4)'
																			fill='none'
																			stroke='#733dd9'
																			strokeLinecap='round'
																			strokeWidth='1'
																		></path>
																	</g>
																</svg>
															</button>
														</div>
													</td>
												</tr>
											)}
										</Fragment>
									))}
									{showLessDeposit && (
										<tr className='items-list'>
											<td className='serial_no'>{lessDeposit.length === 0 ? '1' : lessDeposit.length + 1}</td>
											<td>
												<AntDatePicker
													value={lessDepositInput.date ? moment.unix(lessDepositInput.date.seconds).format('YYYY-MM-DD') : null}
													name={'lessDepositInput.date'}
													onChange={(e) => { handleDateChange(e, 'lessDepositInput.date') }}
													format='DD/MM/YYYY'
													placeholder='DD/MM/YYYY'
													className={`input-enq ${errorFields['lessDepositInputdate']}`}
												/>
											</td>
											<td>
												<input
													autoComplete="off"
													type='text'
													className={`input-enq ${errorFields['lessDepositInputreference']}`}
													name='reference'
													value={lessDepositInput.reference}
													onChange={e => handleChange(e, 'lessDeposit')}
													placeholder='Add Reference'
													onKeyPress={e => handleKeyPress(e, 'lessDeposit')}
												/>
											</td>

											<td className='amount'>
												<NumberFormat
													allowNegative={false}
													thousandSeparator={true}
													prefix={currencySymbol}
													placeholder={currencySymbol}
													className={`input-enq ${errorFields['lessDepositInputamount']}`}
													decimalScale={2}
													onValueChange={e =>
														onAmountChange(
															e,
															'amount',
															'lessDeposit',
														)
													}
													value={lessDepositInput.amount}
													onKeyPress={e => handleKeyPress(e, 'lessDeposit')}
												/>
											</td>
											<td>
												<div className='form-controls-td'>
													<a className="add-icon-input" onClick={() => handleKeyPress({ key: 'Enter' }, 'lessDeposit')}><img src={_images.plusIcon} alt="" width="14" /></a>
													<button type='button' className='close-btn' onClick={() => {
														setShowLessDeposit(false)
														setLessDepositInput(lessDepositVM)
													}}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
														>
															<g id='x' transform='translate(8 8)'>
																<path
																	id='Line_109'
																	d='M0 0L0.001 11.313'
																	className='cls-1'
																	data-name='Line 109'
																	transform='rotate(135 2.343 5.656)'
																	fill='none'
																	stroke='#733dd9'
																	strokeLinecap='round'
																	strokeWidth='1'
																></path>
																<path
																	id='Line_110'
																	d='M0.001 0L0 11.313'
																	className='cls-1'
																	data-name='Line 110'
																	transform='rotate(-135 1.658 4)'
																	fill='none'
																	stroke='#733dd9'
																	strokeLinecap='round'
																	strokeWidth='1'
																></path>
															</g>
														</svg>
													</button>
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div></div>
					</div> */}

					{/* <div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								{trades.length > 0 && (
									<tr>
										<td className='costtxt'>Trade(s)</td>
										<td className='cost-box'>- <NumberFormat
											thousandSeparator={true}
											prefix={currencySymbol}
											displayType={'text'}
											value={trades.reduce((accumulator, currentValue) => {
												return accumulator + currentValue.amount;
											}, 0)}
										/></td>
									</tr>
								)}
								<tr>
									<td className='costtxt'>Deposit</td>
									<td className='cost-box'>- <NumberFormat
										thousandSeparator={true}
										prefix={currencySymbol}
										displayType={'text'}
										value={state.totalDeposit}
									/></td>
								</tr>
							</tbody>
						</table>
					</div> */}
					{/* <div style={{ marginTop: '3rem' }}>
						<div className='section-head'>
							<strong> Notes </strong>
						</div>

						<div className='sub-section-details' style={{ border: '0px' }}>
							<TextEditor
								name={'bodyHTML'}
								placeholder={'write here...'}
								value={template.bodyHTML}
								onChange={onEditorStateChange}
								toolbarOptions={[]}
								height={350}
								enableMention={false}
							// mentions={mentions}
							/>
						</div>
					</div> */}
					{/* <div className='total-estimate'>
						<table width='100%' cellSpacing='0' cellPadding='0' border='0'>
							<tbody>
								<tr>
									<td className='costtxt-blue'>Balance Payable on Delivery</td>
									<td className='cost-box total'>
										<strong><NumberFormat
											thousandSeparator={true}
											prefix={currencySymbol}
											displayType={'text'}
											value={state.payableOnDelivery}
										/></strong>
									</td>
								</tr>

							</tbody>
						</table>
					</div> */}


					{/*//sameer
					 {(generic.status === 'won' || generic.status === 'delivered') && state.type === 'vehicleContract' && (
						<Signatures
							handleSetSignatures={handleSetSignatures}
							signatures={signatures}
							customerName={contact.businessContact ? `${contact.name}` : `${contact.firstName ? contact.firstName : ''}${contact.lastName ? ` ${contact.lastName}` : ''}`}
							salesExecutiveName={orderDetails && orderDetails.leadOwner}
						/>
					)} */}

					<div className='clear'></div>

					<div className='footer'>
						<div className="footer-bottom">
							<p>
								<span style={{ paddingLeft: 0 }} >
									{client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
								</span>
								<span style={{ paddingLeft: 0 }}>
									{client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
									{client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
								</span>
							</p>
						</div>
						<div className='footer-cpy'>
							<div className='footer-left'>
								Powered by{' '}
								<a target='_blank' rel="noopener noreferrer" href='http://fusionsd.com.au/'>
									Fusion SD
								</a>
							</div>
							{!_.isEmpty(state.addedDate) && (
								<div className='footer-right'>Created On - {moment(state.addedDate.toDate()).format('DD/MM/YYYY h:mm A')}</div>
							)}
						</div>
						<div className='clear'></div>
					</div>

					<div className='clear'></div>
				</div>
				<div className='clear'></div>
			</div>

		</>
	) : (
		<>
			<div className='loader-center-screen'>
				<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
			</div>
		</>)
};

export default QuoteForm;