/** LIBRARIES */
import React, { useState, useEffect, useReducer } from 'react';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import _ from 'lodash'
/** PROVIDERS */

/** COMPONENTS */
import CommonHelper from '../../services/common';
import { ContentOverlay, PopUpModal, TableView } from '../../components';
import Translate from '../../constants/translate';
import Users from '../users/list/index';
import { callLogStatus } from '../../services/enum'
import { combineLatest, of } from 'rxjs';
import { collection } from 'rxfire/firestore';
/** VIEW-MODELS */
import { objCallLogs, allRejectFieldsVM } from './viewModel'
import StockListReducer from '../stock/stockListReducer'
import { objContact } from "../contacts/viewModel";
import { objEnquiry, objActivitylog } from "../pipeline/viewModel";
import { firestoreDB } from '../../services/helper';

const RejectLogList = (props) => {
    //const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 179) })
    const [rejectContacts, dispatch] = useReducer(StockListReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [hasMore, setHasMoreData] = useState([])
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [modelShow, setModelShow] = useState(false)
    //const [modelHistoryShow, setModelHistoryShow] = useState(false)
    const [editDocumentID, setEditDocument] = useState()
    const [isPaging, setPaging] = useState(false)
    const [rejectLoader, setRejectLoader] = useState(true)
    const pageLimit = 20
    const allDealers = localStorage.defaultModule === 'frontdesk' && !_.isEmpty(props.dealersettings?.clients) ? props.dealersettings.clients.map(r => { return r.documentID }) : [props.dealersettings.client.id];
    useEffect(() => {
        // function handleResize() {
        //     setWindowSize({
        //         windowWidth: (window.innerWidth - 30),
        //         windowHeight: (window.innerHeight - 179)
        //     })
        // }
        // window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            //window.removeEventListener('resize', handleResize);
            //window.unSubscribeRejLogList && window.unSubscribeRejLogList();
            window.unSubscribeRejLogList && window.unSubscribeRejLogList.unsubscribe();
        }
    }, [])

    useEffect(() => {

        if (!checkDataLoad) {
            return;
        }

        const { dealersettings } = props;
        var collectionPromise = [];
        for (let k = 0; k < Math.ceil(allDealers.length / 10); k++) {
            let collectionDataRef = firestoreDB(dealersettings).firestore().collection('callLogs')
                .where('clientID', 'in', CommonHelper.arrPaginate(allDealers, 10, k + 1))
                .where('isDeleted', '==', false)
                .where('status', '==', callLogStatus.REJECTED)
                .orderBy('modifiedDate', 'desc')

            if (hasMore[k]) {
                collectionDataRef = collectionDataRef
                    .startAfter(hasMore[k])
                    .limit(pageLimit)
            }
            else {
                if (isPaging) {
                    collectionDataRef = '';
                } else {
                    collectionDataRef = collectionDataRef
                        .limit(pageLimit)
                }
            }
            collectionPromise.push(collectionDataRef ? collection(collectionDataRef) : of([]));
        }

        if (collectionPromise.length > 0) {
            window.unSubscribeRejLogList = combineLatest(collectionPromise)
                .subscribe(allDocs => {
                    var querySnapshot = [];
                    let i = 0;
                    let _hasMore = {};
                    allDocs.forEach(doc => {
                        if (doc.length > 0 && doc.length === pageLimit) {
                            _hasMore = {
                                ..._hasMore,
                                [i]: doc[doc.length - 1]
                            };
                        }
                        else {
                            _hasMore = {
                                ..._hasMore,
                                [i]: null
                            };
                        }
                        querySnapshot.push(...doc);
                        i++;
                    });
                    setHasMoreData(_hasMore);
                    onCollectionUpdate(querySnapshot);
                });
        }
        else {
            setDataload(false)
            setRejectLoader(false)
            setPaging(false)
        }

        // let collectionDataRef = firestoreDB(props.dealersettings).firestore().collection('callLogs')
        //     .where('clientID', '==', props.dealersettings.client.id)
        //     .where('status', '==', callLogStatus.REJECTED)
        //     .where('isDeleted', '==', false)
        //     .orderBy('modifiedDate', 'desc')

        // if (hasMore.length > 0) {
        //     collectionDataRef = collectionDataRef
        //         .startAfter(hasMore[0])
        //         .limit(pageLimit)
        // }
        // else {
        //     collectionDataRef = collectionDataRef
        //         .limit(pageLimit)
        // }

        // window.unSubscribeRejLogList = collectionDataRef
        //     .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;

        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = dataMappingVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         });
        //         if (change.type === 'added') {
        //             actionType = "_ADD";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "_UPDATE"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "_REMOVE"
        //         }
        //     }
        // })
        const rejectContacts = [];
        if (!actionType) {
            // if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            //     setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            // }
            // else {
            //     setHasMoreData([]);
            // }
            querySnapshot.forEach((doc) => {
                rejectContacts.push(dataMappingVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        dispatch({
            type: isPaging ? "_APPEND" : "_SUCCESS",
            data: _.uniqBy(rejectContacts, 'documentID'),
            sortName: 'modifiedDate',
            sortOrder: 'desc'
        });
        // if (isPaging) {
        //     dispatch({
        //         type: "_APPEND",
        //         data: rejectContacts,
        //         sortName: 'modifiedDate',
        //         sortOrder: 'desc'
        //     });
        // }
        // else {
        //     dispatch({
        //         type: actionType ? actionType : "_SUCCESS",
        //         data: actionType ? snapshotDoc : rejectContacts,
        //         sortName: 'modifiedDate',
        //         sortOrder: 'desc'
        //     });
        // }


        setDataload(false)
        setRejectLoader(false)
        setPaging(false)
    }



    const dataMappingVM = (doc) => {

        const _obj = Object.assign({}, doc);
        const queueVM = Object.assign({}, objCallLogs);
        for (let [key, value] of Object.entries(_obj)) {
            queueVM[key] = value;
        }

        queueVM.createdOn = moment.unix(queueVM.addedDate.seconds).format('DD/MM/YYYY hh:mm A');
        queueVM.updatedOn = moment.unix(queueVM.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A');

        if (!_.isEmpty(queueVM.contact)) {
            queueVM.contactName = CommonHelper.displayContact([], queueVM.contact, '--');
        }

        if (queueVM.clientID && props.dealersettings.group && queueVM.clientID !== props.dealersettings.client.id && localStorage.defaultModule === 'frontdesk') {
            queueVM.dealerName = CommonHelper.getOtherDealerName(props.dealersettings, queueVM.clientID);
        } else {
            queueVM.dealerName = ''
        }
        queueVM.userName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.user);
        queueVM.addedByName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.addedBy);
        queueVM.modifiedByName = CommonHelper.getUserNamebyId(props.clientUsers, queueVM.modifiedBy);
        return queueVM
    }



    const handleActionClick = (id, data) => {
        return (

            <div className="fleet-btn-loan  reassign-fix" style={{ width: '260px', float: 'left' }}>
                <div className="rejected-delete">
                    <a href="#" className="mini-button" onClick={(e) => {
                        e.preventDefault();
                        deleteRequest(id);
                    }}>
                        <i className="ico icon-delete"></i>
                    </a>
                </div>
                <button type="submit" className="btn btn-sm btn-info float-right ml-2" onClick={(e) => {
                    e.preventDefault();
                    sendToBucket(data);

                }}><Translate text={'sendToInBound'} /></button>

                <button type="submit" className="btn btn-sm btn-primary float-right" onClick={(e) => {
                    e.preventDefault();
                    setEditDocument(id);
                    setModelShow(true);
                }}><Translate text={'reassign'} /></button>
            </div>
        )
    }

    const handleDetailsClick = (e) => {
        e.preventDefault();
        //props.history.push("/contacts/details/" + id);
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const sendToBucket = async (data) => {
        data.enquiryID = firestoreDB(props.dealersettings).firestore().collection('enquiries').doc().id;
        data.activityID = firestoreDB(props.dealersettings).firestore().collection('activities').doc().id;
        data.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
        data.status = callLogStatus.ACCEPTED;
        const objData = Object.assign({}, {
            isDeleted: false,
            status: callLogStatus.ACCEPTED,
            modifiedBy: data.modifiedBy,
            modifiedFrom: 'web',
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            enquiryID: data.enquiryID,
            activityID: data.activityID
        });

        const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(data.documentID);

        const roleSnapshot = await window.firebase.firestore().collection(`clientSettings/${props.dealersettings.client.settingsID}/roles`)
            .where('pipeline', 'array-contains', 'LeadsBucket')
            .get();

        let roleIds = [];
        let _notificationIds = [];
        let userIds = [];
        if (roleSnapshot.docs.length > 0) {
            roleSnapshot.docs.forEach((role) => {
                roleIds.push(role.id);
            })
        }
        let allDealerUsers = (!_.isEmpty(props.groupUsers) ? props.groupUsers.filter(v => v.clientID === data.clientID) : props.clientUsers);
        if (!_.isEmpty(roleIds))
            _notificationIds = _.filter(allDealerUsers, (v) => _.includes(roleIds, v.roleID));

        if (!_.isEmpty(_notificationIds))
            userIds = [..._.map(_notificationIds.filter(e => e.id !== localStorage.uid), function (obj) {
                return obj.id;
            })];

        if (!_.isEmpty(userIds))
            objData.notifyUserIDs = userIds;

        Object.keys(objData).forEach(key => objData[key] === undefined ? delete objData[key] : {});
        updateRef.update(objData).then((docRef) => {
            toast.notify('Engage request send successfully', {
                duration: 2000
            });
            loadEnquiry(data);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });
    }

    const loadEnquiry = async (objLogRequest) => {

        const { dealersettings } = props;
        if (dealersettings &&
            dealersettings.client &&
            dealersettings.client.settings) {


            let state = Object.assign({}, objEnquiry);

            if (_.isEmpty(state.addedBy)) {
                state.addedBy = dealersettings ? dealersettings.id : '';
                state.addedDate = window.firebase.firestore.Timestamp.now();
                state.owner = objLogRequest.modifiedBy ? objLogRequest.modifiedBy : (dealersettings ? dealersettings.id : '');
            }
            state.modifiedBy = dealersettings ? dealersettings.id : '';
            state.modifiedDate = window.firebase.firestore.Timestamp.now();
            state.clientID = objLogRequest.clientID ? objLogRequest.clientID : (dealersettings ? dealersettings.client.id : '');
            state.projectId = objLogRequest.projectId ? objLogRequest.projectId : (dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null);

            if (state.clientID === dealersettings.client.id) {
                const setttings = dealersettings.client.settings;
                let _pipeline = Object.assign({}, setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0])
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
            }
            else if (state.clientID) {

                const _clientSettings = await window.firebase.firestore().doc(`clients/${state.clientID}/currentSettings/${state.clientID}`).get().then(doc => { if (doc.exists) return doc.data(); else return null; });
                const setttings = _clientSettings ? _clientSettings.settings : {};
                let _pipeline = Object.assign({}, (!_.isEmpty(setttings.allPipelines) ? setttings.allPipelines.filter(e => e.value === 'LeadsBucket')[0] : setttings.pipelines.filter(e => e.value === 'LeadsBucket')[0]));
                let _defaultstages = _pipeline.stages.filter(item => item.value !== 'Converted' && item.value !== 'InboundLead')
                let _stages = Array.prototype.map.call(_defaultstages, function (item) { return item.value; }).join(",").split(',');

                state.pipeline = _pipeline.value;
                state.stage = _stages[0];
            }

            state.status = null;
            state.ownerInbound = objLogRequest.modifiedBy ? objLogRequest.modifiedBy : (dealersettings ? dealersettings.id : '');
            if (_.isEmpty(state.tags) && objLogRequest.documentID)
                state.tags = [{ type: 'frontdesk', refID: objLogRequest.documentID, subType: '' }];

            state.stageDate = window.firebase.firestore.Timestamp.now();
            state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
            state.isDeleted = false;
            state.isNewEnquiry = true;

            if (!_.isEmpty(objLogRequest.contact) && objLogRequest.contactID) {
                const refContactData = await firestoreDB(dealersettings).firestore().collection('contactDetails').doc(objLogRequest.contactID).get();
                if (refContactData.exists) {
                    const contact = Object.assign({}, objContact);
                    const objcontactData = Object.assign({}, refContactData.data());
                    for (let [key, value] of Object.entries(objcontactData)) {
                        contact[key] = value;
                    }
                    contact.documentID = refContactData.id;
                    state.contact = contact;
                    state.isContact = true;
                    state.contactID = refContactData.id;
                    state.isNewEnquiry = false;
                }
            }
            else if (!_.isEmpty(objLogRequest.contact) && !objLogRequest.contactID) {
                var _contact = objLogRequest.contact;
                var ObjCont = {
                    firstName: _contact.firstName ? _contact.firstName : '',
                    middleName: _contact.middleName ? _contact.middleName : '',
                    lastName: _contact.lastName ? _contact.lastName : '',
                    phoneCode: _contact.phoneCode ? _contact.phoneCode : '',
                    phone: _contact.phone ? _contact.phone : '',
                    email: _contact.email ? _contact.email : '',
                }
                state.engageNewContact = ObjCont;
            }

            if (objLogRequest.enquiryType) state.enquiryType = objLogRequest.enquiryType
            if (objLogRequest.origin) state.origin = objLogRequest.origin
            if (objLogRequest.campaign) state.campaign = objLogRequest.campaign

            // if (_.isEmpty(state.requirement) && _.isArray(dealersettings.accessSalesType) && dealersettings.accessSalesType.length > 0) {
            //     state.requirement = {
            //         saleType: dealersettings.accessSalesType[0]
            //     }
            // }

            const objData = Object.assign({}, state);
            //Remove empty value from object
            for (var propName in objData) {
                if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                    if (!['origin'].includes(propName)) delete objData[propName];
                }
            }

            if (objLogRequest.enquiryID)
                objData.documentID = objLogRequest.enquiryID;

            if (!objData.documentID)
                objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

            const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                let documentID = objData.documentID;
                objLogRequest.enquiryID = documentID;
                addActivitylog(objLogRequest, objData);

                if (objData.contactID) {
                    const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                    updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
                }
                //this.props.history.push("/enquiry/details/" + documentID + '?type=enquiry');
            }).catch((error) => {
                console.error("Error adding enquiries: ", error);
            });
        }
    }

    const addActivitylog = (objData, _enquiry) => {
        const { dealersettings, clientUsers } = props;
        const newlogVM = Object.assign({}, objActivitylog);
        newlogVM.type = 'log';
        newlogVM.subType = 'call';
        newlogVM.owner = localStorage.uid;
        newlogVM.addedBy = localStorage.uid;
        newlogVM.modifiedBy = localStorage.uid;
        newlogVM.modifiedFrom = 'web';

        if (!_.isEmpty(objData.teammates))
            newlogVM.teammates = objData.teammates;

        if (!_.isEmpty(objData.mentions))
            newlogVM.mentions = objData.mentions;

        newlogVM.addedByName = dealersettings ? dealersettings.name : '';
        newlogVM.modifiedByName = dealersettings ? dealersettings.name : '';
        newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
        newlogVM.startDate = window.firebase.firestore.Timestamp.now();
        newlogVM.enquiryID = objData.enquiryID ? objData.enquiryID : null;
        newlogVM.contactID = objData.contactID ? objData.contactID : null;
        newlogVM['enquiry'] = CommonHelper.getMinifiedData(_enquiry, 'enquiry');
        newlogVM['contact'] = CommonHelper.getMinifiedData(_enquiry?.contact, 'contact');
        newlogVM.clientID = objData.clientID ? objData.clientID : props.dealersettings.client.id;
        newlogVM.documentID = objData.activityID ? objData.activityID : firestoreDB(dealersettings).firestore().collection('activities').doc().id;
        newlogVM.callDuration = objData.callDuration ? objData.callDuration : 0;
        newlogVM.callType = objData.callType ? objData.callType : '';
        newlogVM.callStatus = objData.callStatus ? objData.callStatus : '';
        newlogVM.notes = objData.notes ? objData.notes : '';
        newlogVM.isDone = true;
        
        Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
        newlogVM.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
        firestoreDB(props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
            .set(newlogVM, { merge: true })
            .catch(error => {
                console.error(error);
            });
    }

    const handleUserClose = (userid) => {
        //console.log('handleUserClose', userid, editDocumentID)
        if (userid && editDocumentID) {
            let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
            let modifiedDate = window.firebase.firestore.Timestamp.now();
            const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(editDocumentID);
            updateRef.update({
                isDeleted: true,
                modifiedBy: modifiedBy,
                modifiedFrom: 'web',
                modifiedDate: modifiedDate
            }).then((docRef) => {
                if (userid && editDocumentID) {
                    createEngageRequest(userid, editDocumentID);
                    setEditDocument(null);
                }
            }).catch((error) => {
                console.error("Error updating contact_details: ", error);
            });
        }


        setModelShow(false);

    }

    const deleteRequest = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete the request'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                let modifiedDate = window.firebase.firestore.Timestamp.now();
                const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(id);
                updateRef.update({
                    isDeleted: true,
                    modifiedBy: modifiedBy,
                    modifiedFrom: 'web',
                    modifiedDate: modifiedDate
                }).then((docRef) => {
                    toast.notify('Engage request successfully deleted', {
                        duration: 2000
                    });
                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });
            }
        })


    }

    const createEngageRequest = async (userid, docID) => {

        const fields = Object.assign({}, objCallLogs);
        if (docID && userid) {
            const refData = await firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                let userID = props.dealersettings ? props.dealersettings.id : '';
                let currentDate = window.firebase.firestore.Timestamp.now();

                for (let [key, value] of Object.entries(objData)) {
                    fields[key] = value;
                }
                //fields.clientID = props.dealersettings ? props.dealersettings.client.id : '';
                fields.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;
                fields.documentID = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc().id;
                fields.status = callLogStatus.PENDING;
                fields.user = userid;
                fields.addedBy = userID;
                fields.addedDate = currentDate;
                fields.modifiedBy = userID;
                fields.modifiedDate = currentDate;
                fields.isDeleted = false;
                fields.rejectReason = null;

                const updateRef = firestoreDB(props.dealersettings).firestore().collection('callLogs').doc(fields.documentID);
                updateRef.set(fields, { merge: true }).then((docRef) => {
                    // console.log('callLogs', fields.documentID)
                    toast.notify('Engage reassign send successfully.', {
                        duration: 2000
                    });

                }).catch((error) => {
                    console.error("Error updating contact_details: ", error);
                });

            } else {
                console.error("No such document2!");
            }

        }
    }
    // const handleHistoryClose = (id) => {
    //     setModelHistoryShow(false);
    //     setEditDocument(null);
    // }



    return (
        <>
            <Modal
                id="rejected-logs"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-rejected-contact">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={'rejectedContacts'} />
                            <span className="badge badge-pill badge-rejected-count-red ml-1">{props.rejectCount}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="container-fluid">
                        <div className="row">

                            <div className="rejected-wrapper">
                                {
                                    rejectLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                        (
                                            <div className="common-table" style={{ width: '565px' }}>
                                                <TableView
                                                    isReorderable={false}
                                                    datalist={rejectContacts}
                                                    height={565}
                                                    width={1165}
                                                    columns={allRejectFieldsVM}
                                                    dynamicFields={allRejectFieldsVM.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                    settingsLoader={settingsLoader}
                                                    handleActionClick={handleActionClick}
                                                    handleRowSingleClick={handleDetailsClick}
                                                    isSettings={false}
                                                    hasMore={hasMore}
                                                    handlePagination={handlePagination}
                                                    isPaging={true}
                                                />
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <PopUpModal show={modelShow}>
                <Users
                    show={modelShow}
                    clientUsers={props.activeUsers}
                    dealersettings={props.dealersettings}
                    handleUserClose={handleUserClose}
                    title='selectUsers'
                    showAdd={false}
                    showQueue={true}
                />
            </PopUpModal>

        </>
    )
}

export default RejectLogList