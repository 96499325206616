import React, {
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useState,
	useReducer
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { collectionData } from 'rxfire/firestore';
import toast from 'toasted-notes';
import _, { isEmpty } from 'lodash';

import Inbox from './sections/inbox';
import Sent from './sections/sent';
import Drafts from './sections/drafts';
import Trash from './sections/trash';
import Starred from './sections/starred';
import AllMail from './sections/allmail';
import Spam from './sections/spam';
import Important from './sections/important';

import EmailLeftBar from './emailLeftBar';
import ComposeEmail from './composeEmail';
import { PopUpModal } from '../../components';
import { TemplateContext } from '../settings/workflow/templateContext';
import { MainContext } from '../layout/provider';
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';

const ThreadListReducer = (state, action) => {
	// console.log('state, action', state, action)
	function updateThread() {
		return state.map((item, index) => {
			if (item.id === action.data.id) {
				return action.data;
			}
			return item;
		});
	}
	switch (action.type) {
		case "SUCCESS": {
			return action.data
		}
		case "APPEND_LIST": {
			return [...state, ..._.differenceBy(action.data, state, 'id')]
		}
		case "ADD_LIST": {
			return [action.data, ...state]
		}
		case "REMOVE_LIST": {
			return state.filter(item => item.id !== action.data.id)
		}
		case "UPDATE_LIST": {
			return updateThread()
		}
		default:
			return state;
	}
};

const Mail = ({ location, history }) => {
	const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}`);
	const [offset, setOffset] = useState(25);
	// const [unreadCount, setUnreadCount] = useState(0);
	const [messages1, setMessages] = useState(null);
	const [messages, dispatch] = useReducer(ThreadListReducer, [])
	const [labels, setLabels] = useState(null);
	const [folders, setFolders] = useState(null);
	const [emailtemplates, setEmailTemplates] = useState([]);
	const [structureType, setStructureType] = useState(null);
	const [selectedMessage, setSelectedMessage] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [sendLoader, setSendLoader] = useState(false);
	const [loader, setLoader] = useState(true);
	const [refreshLoader, setRefreshLoader] = useState(false)
	const [userData, setUserData] = useState(null);
	const [nylasAccountData, setNylasAccountData] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [shouldChildSetSelectedMsg, setShouldChildSetSelectedMsg] = useState(
		true,
	);
	const [showComposeModal, setShowComposeModal] = useState({
		show: false,
		type: '',
		title: '',
		emailData: null,
	});
	// const [isLimitReached, setIsLimitReached] = useState({
	// 	sent: false,
	// 	spam: false,
	// 	trash: false,
	// 	inbox: false,
	// 	important: false,
	// 	drafts: false,
	// 	allmail: false,
	// 	starred: false,
	// });
	const [isLimitReached, setIsLimitReached] = useState(false);
	const [checkDataLoad, setDataload] = useState(true)
	const [isPaging, setPaging] = useState(false)
	const [hasMore, setHasMoreData] = useState([])
	const [currentSection, setCurrentSection] = useState({
		name: '',
		label: '',
		folder: '',
		folderID: ''
	})

	const {
		dealersettings,
		newTheardID,
		clearTheardID,
		setNylasAccessToken,
		handleUnreadCount,
		mailUnreadCount,
		setUnreadCount,
	} = useContext(MainContext);

	const [selectedCustomer, setCustomer] = useState(null);
	const [selectedEnquiry, setEnquiry] = useState(null);

	useEffect(() => {
		setIsLimitReached(false)
		setDataload(true)
		setHasMoreData([])
		setPaging(false)
		dispatch({
			type: "SUCCESS",
			data: []
		});
		let _section = '';
		let _label = '';
		let _folder = '';
		let _folderID = '';

		const path = location.pathname;

		if (path.includes('inbox')) {
			_section = 'inbox';
			_label = 'inbox';
			_folder = 'inbox';
		} else if (path.includes('sent')) {
			_section = 'sent';
			_label = 'sent';
			_folder = 'sent';
		} else if (path.includes('drafts')) {
			_section = 'drafts';
			_label = 'drafts';
			_folder = 'drafts';
		} else if (path.includes('allmail')) {
			_section = 'allmail';
			_label = 'all';
			_folder = 'archive';
		} else if (path.includes('important')) {
			_section = 'important';
			_label = 'important';
			_folder = 'important';
		} else if (path.includes('spam')) {
			_section = 'spam';
			_label = 'spam';
			_folder = 'spam';
		} else if (path.includes('trash')) {
			_section = 'trash';
			_label = 'trash';
			_folder = 'trash';
		} else if (path.includes('starred')) {
			_section = 'starred';
		}


		if (structureType === 'labels' && _section !== 'starred') {
			labels && labels.forEach(label => {
				if (label.name === _label) {
					_folderID = label.id
				}
			})
		} else if (structureType === 'folders' && _section !== 'starred') {
			folders && folders.forEach(folder => {
				if (folder.name === _folder) {
					_folderID = folder.id
				}
			})
		}
		setCurrentSection({
			name: _section,
			label: _label,
			folder: _folder,
			folderID: _folderID,
		})
	}, [location.key, structureType, folders, labels])

	useEffect(() => {
		const fetchingNewThread = async () => {
			if (!newTheardID) {
				return;
			}
			const nylasThreads = window.firebase
				.functions()
				.httpsCallable('nylas-getThread');
			let reqObj = {
				token: token,
				id: newTheardID,
			};
			const newThread = await nylasThreads(reqObj);
			// console.log(newThread.data)
			let otherThreads = messages.filter(thrd => thrd.id !== newTheardID)
			setMessages(
				_.orderBy(
					_.unionBy(otherThreads, [newThread.data], 'id'),
					['last_message_timestamp'],
					['desc'],
				),
			);
			if (selectedMessage && selectedMessage.id === newThread.data.id) {
				setSelectedMessage(newThread.data)
			}
			clearTheardID();
			// fetchCount(token)
		};
		// fetchingNewThread();
	}, [newTheardID]);

	useEffect(() => {
		document.getElementById('main-container-fluid') &&
			document.getElementById('main-container-fluid').classList.add('px-0');
		return () => {
			document.getElementById('main-container-fluid') &&
				document
					.getElementById('main-container-fluid')
					.classList.remove('px-0');
		};
	}, []);

	useEffect(() => {
		const authMail = async () => {
			try {
				// console.log('1. Fetch start of userData')
				const userSnapshot = await userRef.get();
				const data = userSnapshot.data();
				setUserData(data);
				// console.log('2. Fetch end of userData', data)

				if (isEmpty(data.nylas)) {
					history.push('/settings/profile?key=emailsettings');
					return
				}

				let nylasAccountID = null;
				if (dealersettings && !isEmpty(dealersettings.nylas)) {
					nylasAccountID = dealersettings.nylas.account_id
				} else if (data && !isEmpty(data.nylas)) {
					nylasAccountID = data.nylas.account_id
				}

				if (nylasAccountID) {
					// console.log('3. Fetch start of nylasAccountData')
					const nylasAccountRef = window.firebase.firestore().collection(`nylas-accounts`).doc(nylasAccountID)
					const nylasAccountSnapshot = await nylasAccountRef.get();
					let nylasAccountData
					if (nylasAccountSnapshot.exists) {
						nylasAccountData = nylasAccountSnapshot.data()
						setNylasAccountData(nylasAccountData)
						// console.log('4. Fetch end of nylasAccountData', nylasAccountData)
					}

					if (nylasAccountData.syncing) {
						history.push('/settings/profile?key=emailsettings');
						return
					}

					// now set structure type
					if (data && !isEmpty(data.nylas)) {
						setStructureType(`${data.nylas.organization_unit}s`);
						// console.log('5. Set setStructureType', `${data.nylas.organization_unit}s`)
					}

					if (!isEmpty(nylasAccountData)) {
						if (isEmpty(nylasAccountData.folders)) {
							Swal.fire('Error', 'Something went wrong in syncing your emails, Please disable and enable Email sync again from email settings', 'error')
							history.push('/settings/profile?key=emailsettings');
						}

						setToken(nylasAccountData.token);
						setNylasAccessToken(nylasAccountData.token);
						// console.log('6. Set NylasAccessToken', nylasAccountData.token)

						// set folders or labels
						if (`${data.nylas.organization_unit}s` === 'labels') {
							// console.log('7. Set setLabels', nylasAccountData.folders)
							setLabels(nylasAccountData.folders)
						} else if (`${data.nylas.organization_unit}s` === 'folders') {
							// console.log('7. Set setFolders', nylasAccountData.folders)
							setFolders(nylasAccountData.folders)
						}
					}

					setLoading(false);

				} else {
					history.push('/settings/profile?key=emailsettings');
				}
			} catch (error) {
				console.error(error);
			}
		};

		authMail();
	}, [location.search, history]);

	useEffect(() => {
		if (_.isEmpty(dealersettings)) {
			return;
		}
		if (!_.isEmpty(emailtemplates)) {
			return;
		}

		var alltemplates = combineLatest(
			collectionData(
				window.firebase
					.firestore()
					.collection(
						`clientSettings/${dealersettings.client.settingsID}/templates`,
					)
					.where('clientID', '==', dealersettings.client.id)
					.where('visibility', '==', 'private')
					.where('isDeleted', '==', false),
			),
			collectionData(
				window.firebase
					.firestore()
					.collection(
						`clientSettings/${dealersettings.client.settingsID}/templates`,
					)
					.where('visibility', '==', 'shared')
					.where('isDeleted', '==', false),
			),
		)
			.pipe(
				map(templates => {
					var [dealer, shared] = templates;
					return [[...dealer], [...shared]];
				}),
			)
			.subscribe(allDocs => {
				let list = [];
				allDocs.forEach(rec => {
					_.isArray(rec) ? rec.forEach(e => list.push(e)) : list.push(rec);
				});
				let listVM = [];
				list.forEach(rec => {
					if (!listVM.some(e => e.documentID === rec.documentID))
						listVM.push(rec);
				});
				setEmailTemplates(listVM.filter(item => item.type === 'emailTemplate'));
				setLoader(false);
			});
		return () => {
			alltemplates && alltemplates.unsubscribe();
		};
	}, []);

	const emailTemplatesMemo = useMemo(() => emailtemplates, [emailtemplates]);

	const handleMarkAs = async (type, setThreadsSelected) => {
		let nylasAccountID = null;
		if (dealersettings && !isEmpty(dealersettings.nylas)) {
			nylasAccountID = dealersettings.nylas.account_id
		} else if (userData && !isEmpty(userData.nylas)) {
			nylasAccountID = userData.nylas.account_id
		}
		let selectedMessages = messages.filter(message => message.selected === true)
		let msgsThatWereSelected = messages.filter(message => message.selected === true)

		if (selectedMessages.length > 0) {
			selectedMessages = selectedMessages.map(async item => {
				let selectedMessage = item
				let threadRef = window.firebase.firestore()
					.collection('nylas-accounts')
					.doc(nylasAccountID)
					.collection('nylas-threads')
					.doc(selectedMessage.id)
				selectedMessage.unread = type === 'unread' ? true : false;
				delete selectedMessage.selected
				await threadRef.set(selectedMessage, { merge: true })
				// selectedMessage.selected = false
				return selectedMessage
			})

			setThreadsSelected(false)

			msgsThatWereSelected.forEach(async selectedMessage => {
				// console.log(selectedMessage)
				if (type === 'unread') {
					await updateMessage(selectedMessage, 'unread', {})
				} else {
					await updateMessage(selectedMessage, 'read', {})
				}
			})
		} else {
			toast.notify('No conversation selected.', {
				duration: 2000,
			});
			setThreadsSelected(false)
		}
		// console.log(type)
	};

	const refreshThreads = () => {
		setHasMoreData([])
		setDataload(true)
	};

	const loadMoreThreads = async (type = 'inbox') => {
		try {
			setPaging(true)
			setDataload(true)
			setLoadingMore(true);

		} catch (error) {
			setLoadingMore(false);
			console.error(error);
		}
	};



	const handleOpenComposeModal = ({ type, title, emailData, contact, enquiry }) => {
		setShowComposeModal({
			...showComposeModal,
			show: true,
			type,
			title,
			emailData: emailData ? emailData : null,
		});
		setCustomer(contact)
		setEnquiry(enquiry)
	};

	const handleCloseComposeModal = () => {
		setShowComposeModal({
			...showComposeModal,
			show: false,
			type: '',
			title: '',
			emailData: null,
		});
		setCustomer(null)
		setEnquiry(null)
	};

	const searchThreads = async textToSearch => {
		try {
			if (!currentSection.folderID || !textToSearch) {
				return
			}

			let threadsRef = window.firebase.firestore().collection(`nylas-accounts/${userData.nylas.account_id}/nylas-threads`)
				.where('keywords', 'array-contains', textToSearch)
			threadsRef = threadsRef.orderBy('last_message_timestamp', 'desc').limit(50)

			const snap = await threadsRef.get()
			let _messages = []
			if (snap.size > 0) {
				snap.forEach(doc => {
					_messages.push(doc.data())
				})
				dispatch({
					type: "SUCCESS",
					data: _messages
				});
			}
			setHasMoreData([])
			setIsLimitReached(true)

		} catch (error) {
			console.error(error);
		}
	};

	const filterThreads = async type => {
		try {
			if (!currentSection.folderID) {
				return
			}

			if (type !== 'clear') {
				let threadsRef = window.firebase.firestore().collection(`nylas-accounts/${userData.nylas.account_id}/nylas-threads`)
					.where('folderIDs', 'array-contains', currentSection.folderID)

				if (type === 'unread') {
					threadsRef = threadsRef.where('unread', '==', true)
				} else if (type === 'read') {
					threadsRef = threadsRef.where('unread', '==', false)
				} else if (type === 'starred') {
					threadsRef = threadsRef.where('starred', '==', true)
				}

				threadsRef = threadsRef.orderBy('last_message_timestamp', 'desc').limit(100)

				const snap = await threadsRef.get()
				let _messages = []
				if (snap.size > 0) {
					snap.forEach(doc => {
						_messages.push(doc.data())
					})
					dispatch({
						type: "SUCCESS",
						data: _messages
					});
				}
				setHasMoreData([])
				setIsLimitReached(true)
			} else {
				dispatch({
					type: "SUCCESS",
					data: []
				});
				setDataload(true)
				setHasMoreData([])
			}

			// let nylasAccountID = null;
			// 	if (dealersettings && !isEmpty(dealersettings.nylas)) {
			// 		nylasAccountID = dealersettings.nylas.account_id
			// 	} else if (userData && !isEmpty(userData.nylas)) {
			// 		nylasAccountID = userData.nylas.account_id
			// 	} 
			// let threadRef = window.firebase.firestore()
			// 	.collection('nylas-accounts')
			// 	.doc(nylasAccountID)
			// 	.collection('nylas-threads')

			// 	if (type !== 'clear') {
			// 		if (type === 'unread') {
			// 			threadRef = threadRef.where('unread', '==', true)
			// 		} else {
			// 			threadRef = threadRef.where('unread', '==', false)
			// 		}
			// 		threadRef = threadRef.orderBy('last_message_timestamp', 'desc').limit(100)
			// 		const snapshot = await threadRef.get()
			// 		if (snapshot.size > 0) {
			// 			const data = snapshot.data()
			// 			console.log('There are the readddun',data)
			// 		}
			// 	} else {

			// 	}

			// const nylasMessages = window.firebase
			// 	.functions()
			// 	.httpsCallable('nylas-threads');
			// let reqObj = {
			// 	token: token,
			// 	options: {
			// 		limit: 25,
			// 	},
			// };
			// if (type !== 'clear') {
			// 	reqObj.options.unread = type === 'unread' ? true : false;
			// }
			// const messages = await nylasMessages(reqObj);
			// setMessages(messages.data);
		} catch (error) {
			console.error(error);
		}
	};

	const updateMessage = async (message, type, data) => {
		// console.log('A updateMessage args', message, type, data)

		let nylasAccountID = null;
		if (dealersettings && !isEmpty(dealersettings.nylas)) {
			nylasAccountID = dealersettings.nylas.account_id
		} else if (userData && !isEmpty(userData.nylas)) {
			nylasAccountID = userData.nylas.account_id
		}

		let threadRef = window.firebase.firestore()
			.collection('nylas-accounts')
			.doc(nylasAccountID)
			.collection('nylas-threads')
			.doc(message.id)


		let messageId = message.id;
		let includesSentLabel;

		message.labels.forEach(label => {
			if (label && label.name === 'sent') {
				includesSentLabel = true;
			}
		});

		// util function
		const getLabel = (labelName, labelsArr) => {
			let label = labelsArr.filter(label => {
				if (label && label.name === labelName) {
					return true;
				}
				return false;
			});
			label = label[0];
			return label;
		};

		// util function
		const getFolder = (folderName, foldersArr) => {
			let folder = foldersArr.filter(folder => {
				if (folder && folder.name === folderName) {
					return true;
				}
				return false;
			});
			folder = folder[0];
			return folder;
		};

		try {
			let reqObj = {
				token: token,
			};

			let labelId;
			let folderId;
			let focusedMessage;
			const nylasUpdateThread = window.firebase
				.functions()
				.httpsCallable('nylas-updateThread');
			reqObj = {
				token: token,
				id: messageId,
				// unread: true,
				// "starred": "false",
				// "folder_id": "FOLDERID",
				// "label_ids": "labelIDS"
			};
			// console.log('reqObj at initialization of nylasUpdateThread', reqObj)
			// console.log('reqObj before moveToTrash', reqObj)
			if (type === 'moveToTrash') {
				if (structureType === 'labels') {
					if (includesSentLabel) {
						reqObj.label_ids = [
							{ id: getLabel('sent', labels).id },
							{ id: getLabel('trash', labels).id },
						];
					} else {
						reqObj.label_ids = [{ id: getLabel('trash', labels).id }];
					}
				} else {
					if (includesSentLabel) {
						reqObj.folder_id = [
							{ id: getFolder('sent', folders).id },
							{ id: getFolder('trash', folders).id },
						];
					} else {
						// reqObj.folder_id = [{ id: getFolder('trash', folders).id }];
						reqObj.folder_id = [{ id: getFolder('trash', folders).id }]
					}
				}
				// console.log('reqObj after moveToTrash', reqObj)
			}
			if (type === 'moveToInbox') {
				if (structureType === 'labels') {
					if (includesSentLabel) {
						reqObj.label_ids = [
							{ id: getLabel('sent', labels).id },
							{ id: getLabel('inbox', labels).id },
						];
					} else {
						reqObj.label_ids = [{ id: getLabel('inbox', labels).id }];
					}
				} else {
					if (includesSentLabel) {
						reqObj.folder_id = [
							{ id: getFolder('sent', folders).id },
							{ id: getFolder('inbox', folders).id },
						];
					} else {
						reqObj.folder_id = [{ id: getFolder('inbox', folders).id }];
					}
				}
			}
			if (type === 'moveToArchive') {
				if (structureType === 'labels') {
					if (includesSentLabel) {
						reqObj.label_ids = [
							{ id: getLabel('sent', labels).id },
							{ id: getLabel('all', labels).id },
						];
					} else {
						reqObj.label_ids = [{ id: getLabel('all', labels).id }];
					}
				} else {
					if (includesSentLabel) {
						reqObj.folder_id = [
							{ id: getFolder('sent', folders).id },
							{ id: getFolder('archive', folders).id },
						];
					} else {
						reqObj.folder_id = [{ id: getFolder('archive', folders).id }];
					}
				}
			}
			if (type === 'star') {
				reqObj.starred = true;
			}
			if (type === 'unstar') {
				reqObj.starred = false;
			}
			if (type === 'read') {
				reqObj.unread = false;
			}
			if (type === 'unread') {
				reqObj.unread = true;
			}

			let updatedMessages = messages.map(message => {
				let updatedMessage = message;

				if (updatedMessage.id === messageId) {
					if (type === 'moveToArchive') {
						if (structureType === 'labels') {
							if (includesSentLabel) {
								updatedMessage.labels = [
									getLabel('sent', labels),
									getLabel('all', labels),
								];
							} else {
								updatedMessage.labels = [getLabel('all', labels)];
							}
						} else if (structureType === 'folders') {
							if (includesSentLabel) {
								updatedMessage.folders = [
									getLabel('sent', folders),
									getLabel('archive', folders),
								];
							} else {
								updatedMessage.folders = [getLabel('archive', folders)];
							}
						}
						setSelectedMessage(null)
						toast.notify('Conversation archived.', {
							duration: 2000,
						});
					}

					if (type === 'moveToTrash') {
						if (structureType === 'labels') {
							if (includesSentLabel) {
								updatedMessage.labels = [
									getLabel('sent', labels),
									getLabel('trash', labels),
								];
							} else {
								updatedMessage.labels = [getLabel('trash', labels)];
							}
						} else if (structureType === 'folders') {
							if (includesSentLabel) {
								updatedMessage.folders = [
									getLabel('sent', folders),
									getLabel('trash', folders),
								];
							} else {
								updatedMessage.folders = [getLabel('trash', folders)];
							}
						}
						setSelectedMessage(null)
						toast.notify('Conversation moved to Trash.', {
							duration: 2000,
						});
					}
					if (type === 'moveToInbox') {
						if (structureType === 'labels') {
							if (includesSentLabel) {
								updatedMessage.labels = [
									getLabel('sent', labels),
									getLabel('inbox', labels),
								];
							} else {
								updatedMessage.labels = [getLabel('inbox', labels)];
							}
						} else if (structureType === 'folders') {
							if (includesSentLabel) {
								updatedMessage.folders = [
									getLabel('sent', folders),
									getLabel('inbox', folders),
								];
							} else {
								updatedMessage.folders = [getLabel('inbox', folders)];
							}
						}
						setSelectedMessage(null)
						toast.notify('Conversation moved to Inbox.', {
							duration: 2000,
						});
					}
					if (type === 'star') {
						updatedMessage.starred = true;
						focusedMessage = updatedMessage;
						toast.notify('Conversation starred.', {
							duration: 2000,
						});
					}
					if (type === 'unstar') {
						updatedMessage.starred = false;
						focusedMessage = updatedMessage;
						toast.notify('Conversation unstarred.', {
							duration: 2000,
						});
					}
					if (type === 'read') {
						updatedMessage.unread = false;
						focusedMessage = updatedMessage;
						//setUnreadCount(mailUnreadCount - 1);
						// handleUnreadCount(mailUnreadCount - 1)
					}
					if (type === 'unread') {
						updatedMessage.unread = true;
					}

					// console.log(updatedMessage);
					threadRef.set(updatedMessage, { merge: true })
				}

				return updatedMessage;
			});

			// setMessages([...updatedMessages]);
			if (type === 'read' || type === 'star' || type === 'unstar') {
				// setSelectedMessage(focusedMessage);
				setShouldChildSetSelectedMsg(false);
			}
			// console.log('reqObj before request', reqObj);
			// const response = await nylasUpdateThread(reqObj);
			// console.log('response of updateMessage',response)
		} catch (error) {
			console.log(error);
		}
	};

	const handleReply = async (trigger, emailData, handleClose, replyToAll) => {
		const { mailTo, mailCc, mailBcc } = trigger;

		setSendLoader(true);

		if (mailTo === '' && mailCc === '' && mailBcc === '') {
			toast.notify('Please specify at least one recipient.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		try {
			let emailObj = {
				token: token,
				draft: {
					// "subject": trigger.subject,
					body: trigger.email,
				},
			};

			emailObj.draft.to = trigger.mailTo.split(',').map(email => {
				return {
					name: '',
					email: email,
				};
			});
			// emailObj.draft.replyTo = trigger.mailTo.split(',').map(email => {
			// 	return {
			// 		name: '',
			// 		email: email,
			// 	};
			// });
			emailObj.draft.body = trigger.email;
			emailObj.draft.replyToMessageId = emailData.replyToMessageId;

			if (replyToAll) {
				emailObj.draft.replyToCC = trigger.mailCc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}
			// console.log(emailObj);

			setTimeout(() => {
				toast.notify('Email has been sent.', {
					duration: 2000,
				});
				setShowComposeModal({
					...showComposeModal,
					show: false,
					type: '',
					title: '',
					emailData: null,
				});
			}, 300);

			const nylasSendEmail = window.firebase
				.functions()
				.httpsCallable('nylas-sendEmail');
			const resp = await nylasSendEmail(emailObj);

			let thread = resp.data.thread
			let presentThread = messages.filter(thrd => thrd.id === thread.id)
			if (presentThread.length > 0) {
				let otherThreads = messages.filter(thrd => thrd.id !== thread.id)
				// setMessages(
				// 	_.orderBy(
				// 		_.unionBy(otherThreads, [thread], 'id'),
				// 		['last_message_timestamp'],
				// 		['desc'],
				// 	),
				// );
				if (selectedMessage && selectedMessage.id === thread.id) {
					setSelectedMessage(thread)
				}
			}
			if (resp.data.success) {
				setSendLoader(false);
			} else {
				setSendLoader(false);
				if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
					Swal.fire({
						title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
						cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
					}).then(result => {
						if (result.value) {
							window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
								nylas: null,
							}, { merge: true });
							history.push('/settings/profile?key=emailsettings');
						}
					});
					return
				}
				else {
					Swal.fire('There was an error in sending email.', '', 'error');
				}
			}
		} catch (error) {
			setSendLoader(false);
			console.log(error);
		}
	};

	const handleSend = async (trigger, emailData, handleClose, replyToAll) => {
		const { mailTo, mailCc, mailBcc, subject, email } = trigger;
		setSendLoader(true);

		if (mailTo === '' && mailCc === '' && mailBcc === '') {
			toast.notify('Please specify at least one recipient.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (subject === '') {
			toast.notify('Please write subject.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		if (email === '' || email === '<p></p>\n') {
			toast.notify('Please write in email body.', {
				duration: 2000,
			});
			setSendLoader(false);
			return;
		}

		try {
			let emailObj = {
				token: token,
				draft: {
					subject: trigger.subject,
					body: trigger.email,
				},
			};

			if (trigger.mailTo !== '') {
				emailObj.draft.to = trigger.mailTo.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailCc !== '') {
				emailObj.draft.cc = trigger.mailCc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.mailBcc !== '') {
				emailObj.draft.bcc = trigger.mailBcc.split(',').map(email => {
					return {
						name: '',
						email: email,
					};
				});
			}

			if (trigger.files.length > 0) {
				emailObj.files = trigger.files;
			}

			setTimeout(() => {
				toast.notify('Email has been sent.', {
					duration: 2000,
				});
				setShowComposeModal({
					...showComposeModal,
					show: false,
					type: '',
					title: '',
					emailData: null,
				});
			}, 300);

			// console.log('emailObj', emailObj);
			const nylasSendEmail = window.firebase
				.functions()
				.httpsCallable('nylas-sendEmail');
			const resp = await nylasSendEmail(emailObj);
			let thread = resp.data.thread
			let email = resp.data.data
			// console.log('res of email that i sent', resp);
			// console.log('res email', email);
			// console.log('res thread', thread);

			// check if we are in sent 
			if (window.location.pathname === '/mail/sent') {
				setMessages(
					_.orderBy(
						_.unionBy(messages, [thread], 'id'),
						['last_message_timestamp'],
						['desc'],
					),
				);
			}

			// check if the thread is in the messages array
			// check if the thread is selected or not currently
			// 
			// setMessages(
			// 	_.orderBy(
			// 		_.unionBy(messages, recentThreads.data, 'id'),
			// 		['last_message_timestamp'],
			// 		['desc'],
			// 	),
			// );
			if (resp.data.success) {
				// setTrigger({
				// 	mailTo: '',
				// 	mailCc: '',
				// 	mailBcc: '',
				// 	replyTo: '',
				// 	subject: '',
				// 	email: '',
				// });
				setSendLoader(false);
			} else {
				setSendLoader(false);
				if (resp.data && resp.data.data && resp.data.data.statusCode === 401) {
					Swal.fire({
						title: CommonHelper.showLocale({ dealersettings }, `the accounts credentials are out of date`),
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: CommonHelper.showLocale({ dealersettings }, 'Reauthenticate'),
						cancelButtonText: CommonHelper.showLocale({ dealersettings }, 'Later'),
					}).then(result => {
						if (result.value) {
							window.firebase.firestore().doc(`users/${localStorage.uid}`).set({
								nylas: null,
							}, { merge: true });
							history.push('/settings/profile?key=emailsettings');
						}
					});
					return
				}
				else {
					Swal.fire('There was an error in sending email.', '', 'error');
				}
			}

			// console.log('response', resp);
		} catch (error) {
			Swal.fire('There was an error in sending email.', '', 'error');

			setSendLoader(false);
			console.log(error);
		}
	};

	const selectThreads = (type, threadId, filteredMessages, setFilteredMessages, setThreadsSelected) => {
		// console.log('select Thread run', type, threadId);
		let selectedMessages = filteredMessages.map(message => {
			let editedMessage = message;

			if (type !== 'single' && type !== 'single-unselect') {
				editedMessage.selected = false;
			}

			if (message.unread === true && type === 'unread') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.unread === false && type === 'read') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.starred === true && type === 'starred') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (message.starred === false && type === 'unstarred') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'all') {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'single' && message.id === threadId) {
				editedMessage.selected = true;
				return editedMessage;
			} else if (type === 'single-unselect' && message.id === threadId) {
				editedMessage.selected = false;
				return editedMessage;
			} else if (
				type !== 'unread' &&
				type !== 'read' &&
				type !== 'starred' &&
				type !== 'unstarred' &&
				type !== 'all'
			) {
				if (message.selected) {
					// console.log(message, 'message.selected')
					editedMessage.selected = true;
				}
			}

			return editedMessage;
		});

		setFilteredMessages([...selectedMessages]);
		setThreadsSelected(type === 'none' ? false : true)

		if (type === 'single-unselect') {
			let selectedMessages = messages.filter(message => message.selected === true)
			if (selectedMessages.length === 0) {
				setThreadsSelected(false)
			}
		}
	};

	return loading ? (
		<div
			className='mt-5'
			style={{
				minHeight: '80vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div className='spinner-loader pt-5 h-100'>
				<div className='d-flex h-100 justify-content-center align-items-center text-primary'>
					<div role='status' className='spinner-border text-primary'></div>
					<p className='text-muted mt-3'></p>
				</div>
			</div>
		</div>
	) : (
		<div className='middle-wrapper h-100'>
			<div className='email-main-container'>
				<div className='email-wrapper'>
					{/* <button className='btn btn-primary' onClick={test}>
					test
					</button> */}
					<EmailLeftBar
						handleOpenComposeModal={handleOpenComposeModal}
						unreadCount={mailUnreadCount}
						setSelectedMessage={setSelectedMessage}
						structureType={structureType}
					/>
					<TemplateContext.Provider
						value={{
							emailTemplateList: emailTemplatesMemo,
							loading: loader,
						}}
					>
						<Suspense fallback={<></>}>
							<Switch>
								<Route
									exact
									path='/mail/inbox'
									render={() => (
										<Inbox
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/starred'
									render={() => (
										<Starred
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/sent'
									render={() => (
										<Sent
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/drafts'
									render={() => (
										<Drafts
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											userData={userData}
											emailtemplates={emailtemplates}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
											history={history}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/allmail'
									render={() => (
										<AllMail
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/important'
									render={() => (
										<Important
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/spam'
									render={() => (
										<Spam
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
								<Route
									exact
									path='/mail/trash'
									render={() => (
										<Trash
											messages={messages}
											token={token}
											handleOpenComposeModal={handleOpenComposeModal}
											filterThreads={filterThreads}
											updateMessage={updateMessage}
											selectedMessage={selectedMessage}
											setSelectedMessage={setSelectedMessage}
											shouldChildSetSelectedMsg={shouldChildSetSelectedMsg}
											setShouldChildSetSelectedMsg={
												setShouldChildSetSelectedMsg
											}
											searchText={searchText}
											setSearchText={setSearchText}
											loadMoreThreads={loadMoreThreads}
											loadingMore={loadingMore}
											refreshLoader={refreshLoader}
											searchThreads={searchThreads}
											refreshThreads={refreshThreads}
											structureType={structureType}
											isLimitReached={isLimitReached}
											setIsLimitReached={setIsLimitReached}
											setMessages={setMessages}
											handleMarkAs={handleMarkAs}
											selectThreads={selectThreads}
											userData={userData}
											nylasAccountData={nylasAccountData}
											labels={labels}
											folders={folders}
											checkDataLoad={checkDataLoad}
											setDataload={setDataload}
											isPaging={isPaging}
											setPaging={setPaging}
											hasMore={hasMore}
											setHasMoreData={setHasMoreData}
											dispatch={dispatch}
											setLoadingMore={setLoadingMore}
										/>
									)}
								/>
							</Switch>
						</Suspense>
					</TemplateContext.Provider>
				</div>
			</div>
			<PopUpModal show={showComposeModal.show}>
				<ComposeEmail
					show={showComposeModal.show}
					handleClose={handleCloseComposeModal}
					token={token}
					type={showComposeModal.type}
					title={showComposeModal.title}
					emailData={showComposeModal.emailData}
					// requirements={requirements.filter(filterRequirments)}
					dealersettings={dealersettings}
					userData={userData}
					sendLoader={sendLoader}
					setSendLoader={setSendLoader}
					handleReply={handleReply}
					handleSend={handleSend}
					structureType={structureType}
					isLimitReached={isLimitReached}
					setIsLimitReached={setIsLimitReached}
					emailtemplates={emailtemplates}
					contact={selectedCustomer}
					enquiry={selectedEnquiry}
				></ComposeEmail>
			</PopUpModal>
		</div>
	);
};

export default Mail;
