import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash'
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import CommonHelper from '../../../services/common';
import Translate from '../../../constants/translate';
import TestdriveBookingDetails from '../bookingDetails';
import { ContentOverlay } from "../../../components";

const StockLink = (props) => {

    //const [slotLoader, setSlotLoader] = useState(false);
    //const [testdriveSlots, setTestDriveSlots] = useState([]);
    const { selectedStock, activityLog, dealersettings, testdriveSlots, slotLoader } = props;



    return (<>
        <div className='form-readonly-wrap'>
            <div className='input-readonly'>
                {(() => {
                    return activityLog.owner === localStorage.uid ||
                        (activityLog.teammates &&
                            activityLog.teammates.some(
                                item => item === localStorage.uid,
                            )) ? (
                        <div className='input-readonly-close'>
                            <a
                                href='#'
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.removeLink();
                                }}
                            >
                                <i className='ico icon-remove'></i>{' '}
                            </a>
                        </div>
                    ) : (
                        <></>
                    )
                })()}
                <div className='input-readonly-inner'>
                    <div className='link-vehicle-item flex-vehicle'>
                        <div className='vehicle-item-image'>
                            {!_.isEmpty(selectedStock.images) ? (
                                <a
                                    data-fancybox={`stock_img${selectedStock.documentID}`}
                                    href={selectedStock.images[0]}
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className='zoom-ico'>
                                        <i className='ico icon-zoom'></i>
                                    </div>
                                    <img
                                        src={selectedStock.images[0]}
                                        width='70'
                                        height='70'
                                        alt=''
                                    />
                                </a>
                            ) : (
                                <a
                                    href='#'
                                    onClick={e => {
                                        e.preventDefault();
                                    }}
                                >
                                    <img
                                        src={CommonHelper.showBrandLogo(props.dealersettings, selectedStock.make)}
                                        className="img-object-fit-contain"
                                        width='70'
                                        height='70'
                                        alt=''
                                    />
                                </a>
                            )}
                        </div>
                        <div className='vehicle-data'>
                            <div className='vehicle-item-title'>
                                {selectedStock.make} {selectedStock.model}
                            </div>
                            <div className="dashboard-testdrive-activity-column">
                                Stock#: <div title={CommonHelper.showLocale(props, 'stockNo')}>{selectedStock?.stockNo || '--'}</div>
                                {selectedStock?.extColor && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'exteriorColor')}>{selectedStock?.extColor}</div></>)}
                                {selectedStock?.year && (<><span className="veh-details-seperator">|</span>&nbsp;<div title={CommonHelper.showLocale(props, 'year')}>{selectedStock?.year}</div></>)}
                                {selectedStock?.regNo && (<><span className="veh-details-seperator">|</span>REG#:&nbsp;<div title={CommonHelper.showLocale(props, 'registrationNumber')}>{selectedStock?.regNo}</div></>)}
                                {selectedStock?.vinNo && (<><span className="veh-details-seperator">|</span>VIN#:&nbsp;<div title={CommonHelper.showLocale(props, 'vinNo')}>{selectedStock?.vinNo}</div></>)}
                                {/* {modelPrice && props.displayPrice && selectedStock.price && (<><span className="veh-details-seperator">|</span><Translate text={'price'} />:&nbsp;<NumberFormat value={selectedStock.price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /></>)} */}
                            </div>
                            {/* <div className='vehicle-item-info'>
                                {selectedStock.extColor
                                    ? `${selectedStock.extColor}, `
                                    : ''}{' '}
                                {selectedStock.year ? selectedStock.year : ''}
                            </div>
                            <div className='vehicle-item-info'>
                                <Translate text={'stock'} /> #:{' '}
                                {selectedStock.stockNo
                                    ? selectedStock.stockNo
                                    : '--'}
                            </div> */}
                            <div className='vehicle-item-status'>
                                {
                                    selectedStock.clientID && selectedStock.clientID !== props.dealersettings.client.id && props.dealersettings.group ? (
                                        <span className="input-readonly-head blue-color">{CommonHelper.getOtherDealerName(props.dealersettings, selectedStock.clientID)}</span>
                                    )
                                        : (<></>)
                                }
                                {selectedStock.saleType ? (
                                    <div className='badge badge-pill badge-white'>
                                        {CommonHelper.getNameByValue(props.dealersettings?.client?.settings?.salesType, selectedStock.saleType, '')}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {selectedStock.status ? CommonHelper.bindStockLabel(props.dealersettings?.client?.settings?.stockStatus, selectedStock.status, '', 'ml-1') : <></>}

                            </div>
                        </div>
                    </div>
                    {slotLoader ? (
                        <div className='testdrive-booking-wraper mt-5'>
                            <ContentOverlay active={true} />
                        </div>
                    ) : Object.keys(testdriveSlots).length === 0 ? (
                        <></>
                    ) : (
                        <div className='testdrive-booking-wraper'>
                            <h4> <Translate text={'bookedSlots'} /></h4>
                            {Object.keys(testdriveSlots).map((date, i) => {
                                return (
                                    <div key={i}>
                                        <h5>{date}</h5>
                                        <div className='testdrive-booked-listing'>
                                            {testdriveSlots[date].map(
                                                (slot, index) => {
                                                    return (
                                                        <TestdriveBookingDetails
                                                            key={index}
                                                            clientUsers={props.clientUsers}
                                                            slot={slot}
                                                        />
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>)
}

export default StockLink;