import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import _, { isEmpty } from 'lodash'
import CommonHelper from '../../services/common';
import { default as _images } from '../../images';

const SleepMode = (props) => {
    const [currentpassword, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [errorFields, setErrorFields] = useState({})

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;

        setPassword(value);

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSubmit = async () => {

        let formIsValid = true;
        let errorClass = 'screenlock-error';
        let errors = {};
        if (_.isEmpty(currentpassword)) {
            formIsValid = false;
            errors['currentpassword'] = errorClass;
        }
        setErrorFields(errors)

        if (!formIsValid) {
            return;
        }

        setLoading(true);

        const cred = window.firebase.auth.EmailAuthProvider.credential(
            window.firebase.auth().currentUser.email,
            currentpassword,
        );

        window.firebase
            .auth()
            .currentUser.reauthenticateWithCredential(cred)
            .then(() => {
                setLoading(false);
                props.handleClose(true);
                return;
            })
            .catch(error => {
                setLoading(false);
                if (error.code === 'auth/multi-factor-auth-required') {

                    props.handleClose(true);
                    return;
                }
                else {
                    let _errors = {};
                    if (error && error.message)
                        _errors["message"] = error.message;
                    else
                        _errors["message"] = "password is not valid";

                    setErrorFields(_errors)
                    console.log(error);
                }


            });

    }

    const { dealersettings, show, clientUsers, unReadChatCounts, smsNotificationCount, notificationCount } = props;

    const _messagemedia = Object.assign({}, dealersettings &&
        dealersettings.client &&
        dealersettings.client.integrations &&
        dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

    const _smshub = Object.assign({}, dealersettings &&
        dealersettings.client &&
        dealersettings.client.integrations &&
        dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

    const _podium = Object.assign({}, dealersettings &&
        dealersettings.client &&
        dealersettings.client.integrations &&
        dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

    const _ringcentral = Object.assign({}, dealersettings &&
        dealersettings.client &&
        dealersettings.client.integrations &&
        dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

    const enableTwoWaySMS = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.clientSettings) &&
        dealersettings.client.clientSettings.enableTwoWaySMS) ? true : false);

    const justcallEnabled = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.integrations) &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0] &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].active === true &&
        props.dealersettings.client.integrations.filter(e => e.type === "justcall")[0].enabled === true
    ) ? true : false);

    const messageMediaEnabled = enableTwoWaySMS && _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
    const smsHubEnabled = enableTwoWaySMS && _smshub && _smshub.active && _smshub.enabled ? true : false;
    const podiumEnabled = enableTwoWaySMS && _podium && _podium.active && _podium.enabled ? true : false;
    const ringcentralEnabled = enableTwoWaySMS && _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;

    return (
        <>

            <Modal
                show={show}
                backdrop='static'
                backdropClassName={'overlay-modal active'}
                dialogClassName='modal-dialog fullscreenmodal-lg screenlock-fullscreen'
            >
                <div className='modal-content modal-content-fullscreen'>
                    <Modal.Header className='screenlock-header-fullscreen'>

                        <div className="float-left">
                            <div className="screenlock-logo">
                                <img src={_images.logoPro} width="76" height="60" alt="" />
                            </div>
                        </div>
                        {
                            !_.isEmpty(dealersettings)
                                ?
                                <>
                                    <div className="float-right">
                                        <div className="screenlock-user-wrap">
                                            <div className="screenlock-userthumb">
                                                <div className="screenlock-userthumbimage">
                                                    <img src={CommonHelper.getUserImagebyId(clientUsers, dealersettings.id)} alt='User' className="rounded-circle img-object-fit" />
                                                </div>
                                                <div className="screenlock-userthumb-info" onClick={(e) => {
                                                    e.preventDefault();
                                                    //props.handleClose(true);
                                                }}> {CommonHelper.showLimitedChar(dealersettings.name, 30)} </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                        }
                    </Modal.Header>
                    <Modal.Body className='modal-body-fullscreen'>
                        <div className="popup-wrap-fullscreen h-100 ">
                            <div className="container-fluid pl-0 d-flex align-items-center">


                                <div className="screenlock-notification-panel">

                                    <div className="screenlock-head">
                                        <h2><Translate text={'Locked'} /> </h2>
                                        <p><Translate text={'Enter your password to unlock again'} /></p>
                                    </div>

                                    <div className="screenlock-notify">
                                        <div className="row justify-content-md-center">

                                            <div className="col-md-4">
                                                <div className="item-box-screenlock">
                                                    <div className="item-box-icon">
                                                        <span className="badge badge-pill badge-screenlock">
                                                            {notificationCount > 0
                                                                ? notificationCount
                                                                : ''}
                                                        </span>
                                                        <i className="ico icon-notification"></i>
                                                    </div>

                                                    <div className="item-box-title">
                                                        <Translate text={'notifications'} />
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                (messageMediaEnabled || smsHubEnabled || podiumEnabled || ringcentralEnabled || justcallEnabled)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="item-box-screenlock">
                                                            <div className="item-box-icon">
                                                                <span className="badge badge-pill badge-screenlock">
                                                                    {smsNotificationCount > 0
                                                                        ? smsNotificationCount
                                                                        : ''}
                                                                </span>
                                                                <i className="ico icon-sms"></i>
                                                            </div>

                                                            <div className="item-box-title">
                                                                <Translate text={'Messages'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {
                                                !Boolean(dealersettings.wholesalerDashboard)
                                                    ?
                                                    <div className="col-md-4">
                                                        <div className="item-box-screenlock">
                                                            <div className="item-box-icon">
                                                                <span className="badge badge-pill badge-screenlock">
                                                                    {!isEmpty(unReadChatCounts) && unReadChatCounts.total && unReadChatCounts.total > 0 ? unReadChatCounts.total : ''}
                                                                </span>
                                                                <i className="ico icon-chat"></i>
                                                            </div>

                                                            <div className="item-box-title">
                                                                <Translate text={'chat'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }


                                        </div>
                                    </div>


                                    <div className="screenlock-unlock-wrap">

                                        <div className="form-group">
                                            <div className={`input-group input-group-alternative ${errorFields['currentpassword']}`}>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ico icon-login-password"></i></span>
                                                </div>

                                                <input
                                                    type="password"
                                                    autoComplete="new-password"
                                                    className={`form-control`}
                                                    name="currentpassword"
                                                    onChange={handleOnChange}
                                                    value={currentpassword ? currentpassword : ''}
                                                    placeholder="Password"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleSubmit(e);
                                                        }
                                                    }}
                                                />
                                                {
                                                    errorFields["message"]
                                                        ?
                                                        <div className="authentication-error">{errorFields["message"]}</div>
                                                        :
                                                        <></>
                                                }
                                                <button type="button"
                                                    className={`btn btn-primary screenlock-unlock-btn ${isLoading ? 'form-disable' : ''}`}
                                                    onClick={(e) => handleSubmit(e)}
                                                >
                                                    {isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                                                    <Translate text={'Unlock'} />
                                                </button>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </div>
            </Modal>

        </>
    );

}
export default SleepMode;