import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { ContentOverlay, AntDateRangePicker, PopUpModal, BarChart, PieChart, LineChart, NoDataPieChart, ReactMultiSelect, AntMonthPicker, ReactSelect } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import { reportGroupBy, reportDynColumns, defaultFilters, filterOptions, reportFilters, labels } from '../../reports/viewModel'
import { CustomMenu, CustomToggle, CustomFilter } from '../../../components/customdropdown';
import AddReport from './addReport'
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
//import ReactExport from 'react-data-export';
import DynamicFilters from "../../reports/dynFilters";
import DealerReport from './dealerReport';
import ScheduleReport from "../../reports/scheduleReport";
import EnquiryList from '../../dashboard/enquiryList';
import ActivityList from '../../dashboard/activitiesList'
import LogListModel from "../../reports/logsList";
import TradeInList from '../../dashboard/tradeinList'
import CustomFilters from '../../reports/customFilters';
import uuid from 'react-uuid'
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';
import QuoteList from '../../dashboard/quotesList'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import TestDriveList from '../../dashboard/testdriveList';
import SalesForecast from './salesForecast'
import ReportHelper from './reportHelper';
import { dealertargetDynCols } from './viewModel';
import { combineLatest } from 'rxjs';
import { doc } from 'rxfire/firestore';

const defaultModals = {
    enquiry: false,
    testdrive: false,
    activity: false,
    logs: false,
    sheduleReport: false,
    scheduleActivity: false,
    quote: false
}

const WeeklyActivityReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 200) })
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year')
    })
    const [dealerData, setDealerData] = useState([])
    const [popHeader, setPopHeader] = useState()
    const [objFilter, setObjFilter] = useState(defaultFilters)
    const [modelShow, setModelShow] = useState(defaultModals)
    const [filter, setFilter] = useState(null)
    const [counts, setListCounts] = useState(null)
    const [year, setyear] = useState(moment().year());
    const [newLeads, setNewLeads] = useState([])
    const [wonLeads, setWonLeads] = useState([])
    const [deliveredLeads, setDeliveredLeads] = useState([])
    const [testdrives, setTestDrives] = useState([])
    const [showForecastModel, setShowForecastModel] = useState(false)
    const [leadsRR, setLeadRR] = useState({})
    const [testdriveRR, setTestdriveRR] = useState({})
    const [soldRR, setSoldRR] = useState({})
    const [deliveredRR, setDeliveredRR] = useState({})
    const [targetLoader, setTargetLoader] = useState(true)
    const [clientTarget, setClientTarget] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [filterLoader, setFilterLoader] = useState(false)

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 200),
                tblWidth: (window.innerWidth - 695)
            })
        }
        // document.addEventListener("mousedown", handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.oemsalesforecastSnap && window.oemsalesforecastSnap.unsubscribe();
        }
    }, [])

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _objFilter = objFilter;
        if (name === 'region') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['subregion']: [],
                ['country']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['country']: [],
                ['dealer']: [],
            };
        } else if (name === 'country') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['dealer']: [],
            };
        } else {
            _objFilter = {
                ..._objFilter,
                [name]: value,
            };
        }
        _objFilter.clients = props?.dealersettings?.clients ? props.dealersettings.clients.filter(client =>
            (_objFilter?.region?.length > 0 ? _objFilter.region.includes(client.region) : true) &&
            (_objFilter?.subregion?.length > 0 ? _objFilter.subregion.includes(client.subregion) : true) &&
            (_objFilter?.country?.length > 0 ? _objFilter.country.includes(client.country) : true) &&
            (_objFilter?.dealer?.length > 0 ? _objFilter.dealer.includes(client.documentID) : true)
        ).map(r => { return r.id }) : []
        setObjFilter(_objFilter);
    }


    const getRegions = () => {
        let regionsToReturn;
        let filteredRegions;

        filteredRegions = props.dealersettings.regions.filter(region => {
            if (props.dealersettings.clients.some(client => client.region === region.code)) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredRegions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getSubregions = () => {
        let regionsToReturn;
        let filteredSubregions;

        filteredSubregions = props.dealersettings.subregions.filter(subregion => {
            if (props.dealersettings.clients.some(client => (client.subregion === subregion.code) &&
                (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true))) {
                return true
            }
            return false;
        })

        regionsToReturn = filteredSubregions.map(e => {
            return { label: e.name, value: e.code };
        })

        return regionsToReturn
    }

    const getCountries = () => {
        let countriesToReturn;
        let filteredCountries;

        filteredCountries = props.dealersettings.countries.filter(country => {
            if (props.dealersettings.clients.filter(client => (
                (!_.isEmpty(objFilter['region']) ? objFilter['region'].includes(client.region) : true) &&
                (!_.isEmpty(objFilter['subregion']) ? objFilter['subregion'].includes(client.subregion) : true)
            )).some(client =>
                (client.country === country.code)
            )) {
                return true
            }
            return false;
        })

        countriesToReturn = filteredCountries.map(e => {
            return { label: e.name, value: e.code };
        })

        return countriesToReturn
    }

    const getDealers = () => {
        let clientsToReturn = props.dealersettings.clients;

        if (!_.isEmpty(objFilter['region'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['region'].includes(client.region)
            );
        }

        if (!_.isEmpty(objFilter['subregion'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['subregion'].includes(client.subregion)
            );
        }

        if (!_.isEmpty(objFilter['country'])) {
            clientsToReturn = clientsToReturn.filter(client =>
                objFilter['country'].includes(client.country)
            );
        }

        clientsToReturn = clientsToReturn.map(client => {
            return {
                value: client.documentID,
                doc: client,
                label: client.name,
                active: _.isBoolean(client.active) ? client.active : true,
            };
        })

        return clientsToReturn
    }

    useEffect(() => {

        const targetPromise = [];
        CommonHelper.getAllDealers(props.dealersettings, true).forEach(cid => {
            targetPromise.push(doc(window.firebase.firestore().doc(`clients/${cid}/salesForecast${props.saleType === 'used' ? 'used' : ''}/${cid}`)))
            targetPromise.push(doc(window.firebase.firestore().doc(`targets/client${cid}`)))
        })
        window.oemsalesforecastSnap = combineLatest(targetPromise)
            .subscribe(allDocs => {
                let data = [];
                allDocs.forEach(rec => {
                    if (rec.exists) {
                        data.push({
                            ...rec.data(),
                            clientID: rec.data()?.clientID || rec.id
                        })
                    }
                })
                setClientTarget(CommonHelper.removeEmptyOrNull(data));
                setTargetLoader(false)
            });
    }, [])

    useEffect(() => {
        if (targetLoader || !loader)
            return
        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: 'getSummaryReport',
                params: JSON.stringify({
                    clientID: CommonHelper.getAllDealers(props.dealersettings),
                    startDate: dateRange.startDate.format('YYYY-MM-DD'),
                    endDate: dateRange.endDate.format('YYYY-MM-DD'),
                    hideTradein: true,
                    hideActivities: true,
                    hideWalkin: true
                })
            }).then((response) => {

                //setRefreshData(false)
                if (response.data.success) {
                    setDealerData(response.data.data);
                    loadReportData(response.data.data);
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [targetLoader, loader])

    const loadReportData = (reportData) => {
        const data = reportData.filter(a => (objFilter?.clients?.length > 0 ? objFilter.clients.includes(a.cid) : true)
            && (objFilter?.make ? (a.mk && a.mk.toLowerCase() === objFilter?.make.toLowerCase()) : true)
            && (objFilter?.model?.length > 0 ? (a.ml && objFilter.model.some(b => b.toLowerCase() === a.ml.toLowerCase())) : true)
            && ((props.saleType === 'new') ? (a.st && a.st.toLowerCase() === 'new') : true)
            && ((props.saleType === 'used') ? (a.st && a.st.toLowerCase() !== 'new') : true)
        )
        const weeks = ReportHelper.getAllWeeks(props, year);
        const months = ReportHelper.getAllMonths();
        const leads = ReportHelper.leadsCounts(data, weeks, year, objFilter, getDealers(), clientTarget);
        let nextRecord = {};
        let lastRecord = _.find(leads, { weekNo: (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) });
        setLeadRR({
            rr: parseFloat((_.sumBy(leads, 'actual') / (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week())).toFixed(2)),
            lastWeek: lastRecord?.name ? lastRecord?.name : `WK ${year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()}`,
            lastWeekActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: (lastRecord?.actual - lastRecord?.average).toFixed(2)
        })
        setNewLeads(leads);
        const testdrives = ReportHelper.testdriveCounts(data, weeks, year, objFilter, getDealers(), clientTarget);
        lastRecord = _.find(testdrives, { weekNo: (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) });
        setTestdriveRR({
            rr: parseFloat((_.sumBy(testdrives, 'actual') / (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week())).toFixed(2)),
            lastWeek: lastRecord?.name ? lastRecord?.name : `WK ${year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()}`,
            lastWeekActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: (lastRecord?.actual - lastRecord?.average).toFixed(2)
        })
        setTestDrives(testdrives)
        const soldLeads = ReportHelper.wonCounts(data, months, year, objFilter, getDealers(), clientTarget);
        lastRecord = _.find(soldLeads, { monthNo: (year < moment().year() ? 11 : moment().month()) });
        nextRecord = _.find(soldLeads, { monthNo: (year < moment().year() ? 11 : moment().month() + 1) });
        setSoldRR({
            rr: parseFloat((_.sumBy(soldLeads, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonth: lastRecord?.name ? lastRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month()).format('MMM')}`,
            lastMonthActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: lastRecord?.actual - parseFloat((_.sumBy(soldLeads, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            nextForecast: nextRecord?.forecast,
            nextMonth: nextRecord?.name ? nextRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month() + 1).format('MMM')}`,
        })
        setWonLeads(soldLeads)
        const delivereddata = ReportHelper.deliveredCounts(data, months, year, objFilter, getDealers(), clientTarget);
        lastRecord = _.find(delivereddata, { monthNo: (year < moment().year() ? 11 : moment().month()) });
        nextRecord = _.find(delivereddata, { monthNo: (year < moment().year() ? 11 : moment().month() + 1) });
        setDeliveredRR({
            rr: parseFloat((_.sumBy(delivereddata, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonth: lastRecord?.name ? lastRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month()).format('MMM')}`,
            lastMonthActual: lastRecord?.actual ? lastRecord?.actual : 0,
            delta: lastRecord?.actual - parseFloat((_.sumBy(delivereddata, 'actual') / (year < moment().year() ? 11 : moment().month()) + 1).toFixed(2)),
            lastMonthYTD: _.sumBy(delivereddata.filter(a => a.monthNo <= (year < moment().year() ? 11 : moment().month())), 'actual'),
            lastMonthTargetYTD: _.sumBy(delivereddata.filter(a => a.monthNo <= (year < moment().year() ? 11 : moment().month())), 'target'),
            nextForecast: nextRecord?.forecast,
            nextTarget: nextRecord?.target,
            nextMonth: nextRecord?.name ? nextRecord?.name : `${moment().month(year < moment().year() ? 11 : moment().month() + 1).format('MMM')}`,
        })
        setDeliveredLeads(delivereddata)
        setLoader(false)
    }

    const handleModelClose = (rec, isNew) => {
        setModelShow({})
    }


    const handleShowEnquiryList = (val, rec, module) => {
        if (!val.includes('actual')) {
            return;
        }

        setFilter({
            documentIDs: rec[`arr${val}`]
        })
        setListCounts(rec[`arr${val}`].length)


        setFilter({
            documentIDs: rec[`arr${val}`]
        })
        setListCounts(rec[`arr${val}`].length)
        if (module === 'testdrive') {
            setPopHeader('Test Drives List')
            setModelShow({
                ...modelShow,
                testdrive: true
            })
        }
        else {
            setPopHeader('Enquiry List')
            setModelShow({
                ...modelShow,
                enquiry: true
            })
        }
    }

    useEffect(() => {
        if (loader)
            return
        const _data = dealerData;
        loadReportData(_data);
    }, [objFilter, clientTarget])

    const handleModelDynClose = (rec, isNew) => {
        setShowForecastModel(false)
    }

    useEffect(() => {
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (objFilter?.make) {
            let _models = !_.isEmpty(makes.filter(m => m.value === objFilter.make)[0]) ? makes.filter(m => m.value === objFilter.make)[0].models : [];
            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [objFilter])


    return (<>
        <div className="report-page-head">
            <h2>{props.title}</h2>
            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    <Dropdown.Item eventKey="1" onClick={(e) => { setShowForecastModel(true) }}><i className="ico icon-line-chart"></i> Forecast </Dropdown.Item>
                    <Dropdown.Item eventKey="4" onClick={(e) => {
                        e.preventDefault();
                        let url = `/dynamic/report/weeklyactivity/${localStorage.uid}-weeklyReport`;
                        localStorage.setItem('allClients', JSON.stringify(props.dealersettings.clients));
                        url += `?year=${year}&oemid=${props.dealersettings.oemID}`;
                        if (objFilter) {
                            Object.keys(objFilter).filter(a => a !== 'dealer').forEach(rec => {
                                if (objFilter?.[rec]?.length > 0) {
                                    url += _.isArray(objFilter[rec]) ? `&${rec}=${objFilter[rec].join(',')}` : `&${rec}=${objFilter[rec]}`;
                                }
                            })
                        }
                        if (objFilter?.clients?.length > 0) {
                            url += `&dealer=${objFilter.clients.join(',')}`;
                        } else if (props?.dealersettings?.clients?.filter(a => a.settingsID === props.dealersettings.oemID)?.length > 0) {
                            url += `&dealer=${props.dealersettings.clients.filter(a => a.settingsID === props.dealersettings.oemID).map(a => { return a.id }).join(',')}`;
                        }
                        if (objFilter?.dealer?.length > 0) {
                            url += `&showdealer=true`;
                        }
                        if (props?.saleType) {
                            url += `&saleType=${props.saleType}`;
                        }
                        window.open(url, '_blank');
                    }}><i className="ico icon-mail-print" style={{ fontSize: '18px' }}></i> Print Preview </Dropdown.Item>
                    {/* <Dropdown.Item eventKey="4" onClick={(e) => {
                        setModelShow({
                            ...modelShow,
                            sheduleReport: true
                        })
                    }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <div className="divider-line"></div>
        <div className='rules-group-wrapper query-builder'>
            <div className='rules-group-body'>
                <div className='rule-container '>
                    <div className='settings-head form-style mb-0'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-row'>
                                    <div className="col-md-2">
                                        <AntMonthPicker
                                            value={moment().year(year).toDate()}
                                            name={'year'}
                                            onChange={val => {
                                                setyear(moment(val).year())
                                                setDateRange({
                                                    startDate: moment(val).startOf('year'),
                                                    endDate: moment(val).endOf('year')
                                                })
                                                setLoader(true)
                                            }}
                                            placeholder='select year'
                                            className={`form-control cursor-pointer`}
                                            format={'YYYY'}
                                            picker={'year'}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <ReactMultiSelect
                                            options={getRegions()}
                                            name={'region'}
                                            placeholder={`select region`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'region')
                                            }}
                                            value={objFilter['region']}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="col-md-2">
                                        <ReactMultiSelect
                                            options={getSubregions()}
                                            name={'subregion'}
                                            placeholder={`select subregion`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'subregion')
                                            }}
                                            value={objFilter['subregion']}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="col-md-2">

                                        <ReactMultiSelect
                                            options={getCountries()}
                                            name={'country'}
                                            placeholder={`select country`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'country')
                                            }}
                                            value={objFilter['country']}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                    <div className="col-md-2">

                                        <ReactMultiSelect
                                            options={getDealers()}
                                            name={'dealer'}
                                            placeholder={`select dealer`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'dealer')
                                            }}
                                            value={objFilter['dealer']}
                                            classNamePrefix={`basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='rule-container '>
                    <div className='settings-head form-style mb-0'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-row'>
                                    <div className={`col-2`}>
                                        <ReactSelect
                                            options={makes}
                                            name={"make"}
                                            placeholder={'select make'}
                                            onChange={(e, data) => {
                                                setObjFilter({
                                                    ...objFilter,
                                                    make: e?.value || null
                                                });
                                            }}
                                            value={objFilter?.make || null}
                                            classNamePrefix={` cursor-pointer basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                    <div className={`col-4`}>
                                        <ReactMultiSelect
                                            options={models}
                                            name={'model'}
                                            placeholder={`select model`}
                                            onChange={(e) => {
                                                setMultibjFilter(e, 'model')
                                            }}
                                            value={objFilter?.model || []}
                                            classNamePrefix={`cursor-pointer basic-select`}
                                            isMulti={true}
                                            isSearchable={true}
                                        >
                                        </ReactMultiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="filter-collapse-report-footer">
                    <button type="button" className="btn btn-green float-left ml-3" onClick={() => { setFilterLoader(!filterLoader) }}>Apply</button>
                </div> */}
            </div>
        </div>
        {
            loader ? (<div className="mt-5" > <ContentOverlay active={true} /></div>) : (
                <>
                    <div className="text-center mc-chart-box-border">
                        <LineChart
                            id={`weekly-report-line-chart-${props.saleType}`}
                            height={`450px`}
                            data={newLeads}
                            dynamicColumns={dealertargetDynCols}
                            xCategory={'name'}
                            columns={['actual', 'average']}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                            title={'Leads'}
                            hideLabel={true}
                            minGridDistance={10}
                            labelRotation={90}
                        />
                        <div className='mc-bottom-box'>
                            {leadsRR.year} Run Rate: <strong>{leadsRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last week ({leadsRR.lastWeek}): <strong>{leadsRR.lastWeekActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{leadsRR.delta}</strong>
                        </div>
                    </div>
                    <div className="text-center mc-chart-box-border ">
                        <LineChart
                            id={`weekly-report-line-chart-test-drives-${props.saleType}`}
                            height={`450px`}
                            data={testdrives}
                            dynamicColumns={dealertargetDynCols}
                            xCategory={'name'}
                            columns={['actual', 'average']}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                            title={'Test Drives'}
                            hideLabel={true}
                            minGridDistance={10}
                            labelRotation={90}
                        />
                        <div className='mc-bottom-box'>
                            {testdriveRR.year} Run Rate: <strong>{testdriveRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last week ({testdriveRR.lastWeek}): <strong>{testdriveRR.lastWeekActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{testdriveRR.delta}</strong>
                        </div>
                    </div>
                    <div className="text-center mc-chart-box-border">
                        <LineChart
                            id={`weekly-report-line-chart-won-leads-${props.saleType}`}
                            height={`450px`}
                            data={wonLeads}
                            dynamicColumns={dealertargetDynCols}
                            xCategory={'name'}
                            columns={['actual', 'forecast', 'wonTarget']}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                            title={'Orders'}
                            hideLabel={true}
                        />
                        <div className='mc-bottom-box'>
                            {soldRR.year} Run Rate: <strong>{soldRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last Month ({soldRR.lastMonth}): <strong>{soldRR.lastMonthActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{soldRR.delta}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Next Month ({soldRR.nextMonth}) Forecast: <strong>{soldRR.nextForecast}</strong>
                        </div>
                    </div>
                    <div className="text-center mc-chart-box-border">
                        <BarChart
                            id={`weekly-report-line-chart-delivered-leads-${props.saleType}`}
                            height={`450px`}
                            data={deliveredLeads}
                            columns={['target', 'retailedActual']}
                            lineColumns={['cummulativeTarget', 'cummulativeActual', 'forecast']}
                            dynamicColumns={dealertargetDynCols}
                            xCategory={'name'}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                            hideBullet={true}
                            dynColor={true}
                            title={'Retailed'}
                        />
                        <div className='mc-bottom-box'>
                            {deliveredRR.year} Run Rate: <strong>{deliveredRR.rr}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Last Month ({deliveredRR.lastMonth}): <strong>{deliveredRR.lastMonthActual}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{deliveredRR.delta}</strong><br />
                            {deliveredRR.lastMonth} YTD - Actual: <strong>{deliveredRR.lastMonthYTD}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;{deliveredRR.lastMonth} YTD - Target: <strong>{deliveredRR.lastMonthTargetYTD}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Delta: <strong>{deliveredRR.lastMonthYTD - deliveredRR.lastMonthTargetYTD}</strong><br />
                            Next Month ({deliveredRR.nextMonth}) Forecast: <strong>{deliveredRR.nextForecast}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;Next Month ({deliveredRR.nextMonth}) Target: <strong>{deliveredRR.nextTarget}</strong>
                        </div>
                    </div>
                </>
            )
        }

        <PopUpModal show={modelShow.enquiry}>
            <EnquiryList
                {...props}
                show={modelShow.enquiry}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
                counts={counts}
            ></EnquiryList>
        </PopUpModal>
        <PopUpModal show={modelShow.activity}>
            <ActivityList
                {...props}
                show={modelShow.activity}
                handleClose={handleModelClose}
                counts={counts}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.logs}>
            <LogListModel
                {...props}
                show={modelShow.logs}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></LogListModel>
        </PopUpModal>
        <PopUpModal show={modelShow.tradein}>
            <TradeInList
                {...props}
                show={modelShow.tradein}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></TradeInList>
        </PopUpModal>
        <PopUpModal show={modelShow.testdrive}>
            <TestDriveList
                {...props}
                show={modelShow.testdrive}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></TestDriveList>
        </PopUpModal>
        <PopUpModal show={modelShow.quote}>
            <QuoteList
                {...props}
                show={modelShow.quote}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                isReadOnlyView={true}
            ></QuoteList>
        </PopUpModal>
        <PopUpModal show={showForecastModel}>
            <SalesForecast
                {...props}
                show={showForecastModel}
                title={'Add Forecast'}
                handleClose={handleModelDynClose}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.sheduleReport}>
            <ScheduleReport
                {...props}
                show={modelShow.sheduleReport}
                handleClose={handleModelClose}
                title={popHeader}
                oemReport={true}
                report={{ documentID: `${localStorage.uid}-weeklyReport` }}
            ></ScheduleReport>
        </PopUpModal>
    </>)
}

export default WeeklyActivityReport;