import React, { useRef, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { isEmpty } from 'lodash';
import Translate from '../../constants/translate';

const FinanceInfo = ({ financeDetail, handleViewFinanceOpen, handleEditFinanceOpen, isDeivered, currencySymbol, handleAddProviderOpen }) => {
  const [editshow, setEditshow] = useState(false)
  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      return;
    }

    setEditshow(false);
  };

  return (
    <div
      className='vehicle-item finance-item'
    // onClick={handleViewFinanceOpen}
    >
      <div className='enquiry-more'>
        {' '}
        <a href='#' onClick={(e) => {
          e.preventDefault();
          setEditshow(!editshow)
          if (editshow)
            document.addEventListener('click', setEditshow(false));
        }}>
          <i className='ico icon-more'></i>
        </a>{' '}
        <div ref={node} className={`custom-drop custom-drop-align ${editshow ? '' : 'hide'}`}>
          <ul className="more-dropdown">
            {
              isDeivered
                ?
                <></>
                :
                <li>
                  <a href="#" onClick={(e) => { e.preventDefault(); handleEditFinanceOpen() }}>
                    <>
                      <i className="ico icon-edit"></i> <Translate text={'edit'} />
                    </>
                  </a>
                </li>
            }

            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); handleViewFinanceOpen() }}>
                <i className="ico icon-zoom"></i> <Translate text={'view'} />
              </a>
            </li>

          </ul>
        </div>
      </div>
      <div className='vehicle-item-title'>
        {`${financeDetail.Make} ${financeDetail.Model}`}
      </div>
      <div className='vehicle-item-info'>
        <Translate text={'vehiclePrice'} />:{' '}
        <span className='price'>
          {' '}
          <NumberFormat
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={financeDetail.CarPrice}
          />
        </span>
      </div>
      <div 
        className='vehicle-item-status' 
        style={{
          width: '100%',
          height: '30px',
          marginTop: '0px',
        }}
      >
        <div className='finance-amount'>
          {' '}
          <NumberFormat
            thousandSeparator={true}
            prefix={currencySymbol}
            displayType={'text'}
            value={financeDetail.FinanceAmount}
            />
        </div>
      </div>
      {!isEmpty(financeDetail.FinanceRequest) && (
        <>
            <div className="tradin-item-button" style={{display: 'block'}}>
              {financeDetail.FinanceRequest[0].LeadStatus && (
                  <div className={`float-left badge badge-pill badge-${financeDetail.FinanceRequest[0].LeadStatus.toLowerCase()}`}>{financeDetail.FinanceRequest[0].LeadStatus}</div>
              )}
              {financeDetail.FinanceRequest[0].Status && (
                  <div className={`float-right ml-2 status-${financeDetail.FinanceRequest[0].Status.toLowerCase() === 'approved' ? 'won' : 'lost'}`}>{financeDetail.FinanceRequest[0].Status}</div>
              )}
            </div>    
        </>
      )}
      {isEmpty(financeDetail.FinanceRequest) && (
        <>
            <div className="tradin-item-button" style={{display: 'block'}}>
              <button type="button" className="btn btn-primary float-left" onClick={handleAddProviderOpen}> <Translate text={'Request For Finance'} upperCase={true} /></button>
            </div>
        </>
      )}
    </div>
  )
}

export default FinanceInfo
