import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Translate from "../../../constants/translate";
import { AudioPlayer, ContentOverlay } from "../../../components";
import _ from 'lodash'
import images from "../../../images";

const TranscriptModal = (props) => {
    const [loader, setLoader] = useState(true);
    const [state, setState] = useState([])
    useEffect(() => {
        if (!_.isEmpty(props.transcript)) {
            setLoader(false)
        } else {
            const transcript = window.firebase.functions().httpsCallable('justcall-getCallTranscript');
            transcript({
                ...props.justcallSettings,
                callID: props?.activitylog?.justcall?.call_id || props?.activitylog?.justcall?.callid,
            }).then(async (resp) => {
                setLoader(false)
                if (resp?.data?.success) {
                    setState(resp?.data?.data)
                }
            })
        }

    }, [])

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? "overlay-modal active" : ""}
            dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-call-transcript"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="modal-title" ><Translate text={'Call Transcript'} /></h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <>
                    <div className="call-transcript-wrap">
                        <div className="call-transcript-info">
                            <div className="call-transcript-sub">
                                <div className="call-transcript-callicon"><i className={`ico icon-call${props?.activitylog?.callType ? '-' + props.activitylog.callType : ''}`}></i></div>
                                <div>
                                    <div className="call-transcript-user">Call with {props?.contact?.firstName || 'Customer'}</div>
                                    <div className="call-transcript-number">{moment.unix(props?.activitylog?.addedDate?.seconds).format("DD/MM/YYYY h:mm A")}</div>
                                </div>

                            </div>
                            {
                                props?.activitylog?.recording ? (
                                    <div className="call-transcript-audio">
                                        <AudioPlayer
                                            id={`justcall-recording-${props.activitylog.documentID}`}
                                            recording={props.activitylog.recording}
                                        ></AudioPlayer>
                                    </div>
                                ) : (<></>)
                            }
                        </div>
                    </div>
                    {
                        loader ? (
                            <div className="mt-5" style={{ minHeight: '150px' }}><ContentOverlay active={true} /></div>) :
                            state?.length > 0 ? (
                                <div className="call-chat-list-wrap">
                                    {
                                        state.map((rec, index) => {
                                            return <div key={index} className="call-chat-list-loop">
                                                <div className="call-chat-info">
                                                    <span className="call-chat-duration">{rec.time}</span>
                                                </div>
                                                <div className="call-chat-content">
                                                    <span className="call-chat-owner" style={{ color: rec.speaker === 'Customer' ? '#21c4d3' : '#4466f2' }}>
                                                        {rec.speaker === 'Customer' && props?.contact?.firstName ? props.contact.firstName : rec.speaker}
                                                    </span> {rec.sentence}
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            ) : !_.isEmpty(props.transcript) ? (
                                <div className="call-chat-list-wrap text-area-space editor-item-note p-4">
                                    {props.transcript}
                                </div>
                            ) : (
                                <div className="spinner-loader" style={{ minHeight: '150px' }}>
                                    <div className="no-data-flex h-100 justify-content-center align-items-center mt-5">
                                        <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                        <div className="no-data-txt mt-2"> No transcript found</div>
                                    </div>
                                </div>
                            )
                    }


                </>
            </Modal.Body>
        </Modal>
    );
};

export default TranscriptModal;
