/** LIBRARIES */
import React, { Component, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
import { LoaderOverlay } from '../../components'
import { LayoutConsumer } from '../layout/provider';
import { FrontDeskConsumer, FrontDeskProvider } from './provider';
/** COMPONENTS */
import List from './list'

class FrontDesk extends Component {

    render() {
        return (
            <LayoutConsumer>
                {({ dealersettings, clientUsers, groupUsers }) => (
                    <FrontDeskProvider history={this.props.history} clientUsers={clientUsers} groupUsers={groupUsers} dealersettings={dealersettings}>
                        <FrontDeskConsumer>
                            {({ usersList, loadingText }) => (loadingText || _.isEmpty(dealersettings)) ? (<LoaderOverlay text={loadingText} active={loadingText ? true : false} />) : (

                                <Suspense fallback={<></>}>
                                    {
                                        <>

                                            <List
                                                clientUsers={usersList}
                                                history={this.props.history}
                                                dealersettings={dealersettings}
                                            />

                                        </>
                                    }
                                </Suspense>

                            )}
                        </FrontDeskConsumer>
                    </FrontDeskProvider>

                )}
            </LayoutConsumer>
        );
    }

}

export default FrontDesk;