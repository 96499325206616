/** LIBRARIES */
import React, { Component } from 'react';
import _ from 'lodash'
import { Modal } from "react-bootstrap";
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import {
    ReactSelect, AutoComplete,
    AntDatePicker,
    InputText,
    ImageCropHook
} from '../../../components';
import Translate from '../../../constants/translate';
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
/** VIEW-MODELS */
import { objUser } from '../viewModel'
import { storageBucket } from '../../../services/helper';
//#region  ASSIGN VARIABLES
let license_state = [];
let defaultCountry = '';
let license_type = [];
//#endregion

class EditUser extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.ref = window.firebase.firestore().collection('users');
        this.newDocID = null;
        this.mandatoryFields = [];
        this.state = {
            fields: Object.assign({}, objUser),
            errors: {},
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            },
        }
    }

    //#region PAGE LOAD
    async loadUser(props) {
        const { show, dealersettings, docID } = props;
        const fields = Object.assign({}, objUser);
        if (show === true && docID) {
            this.newDocID = docID;
            const refData = await this.ref.doc(docID).get();
            if (refData.exists) {
                const objData = Object.assign({}, refData.data());

                for (let [key, value] of Object.entries(objData)) {
                    if (fields.hasOwnProperty(key))
                        fields[key] = value;
                }
                fields.documentID = docID;
                this.setState({ fields: fields }, () => { this.loadSettings(); });
            } else {
                console.error("No such document2!");
            }

        }

    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadUser(this.props);

    }

    loadSettings = () => {
        const { dealersettings } = this.props;
        const { fields } = this.state;
        const countries = []; const states = [];

        this.selectedClientID = dealersettings.client.id;

        let _clientData = this.selectedClientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
            && !_.isEmpty(dealersettings.group.clients[this.selectedClientID]) ? dealersettings.group.clients[this.selectedClientID] : dealersettings.client;

        if (_clientData) {
            const setttings = _clientData.settings;

            defaultCountry = _clientData.countryCode ? _clientData.countryCode.toUpperCase() : 'AU';

            license_type = [];
            setttings.licenseType && setttings.licenseType.forEach(doc => {
                license_type.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            dealersettings.countries && dealersettings.countries.forEach((doc) => {
                countries.push({
                    value: doc.code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });

            });

            fields.country && dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === fields.country).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

            license_state = [];
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

        }

        this.setState({
            countries: countries,
            states: states
        });
    }
    //#endregion

    //#region CHANGE EVENT

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'secondaryEmail') {
            fields[name] = value;
            this.setState({ fields: fields }, () => { this.errorChange(name); });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                fields[name] = newarray1.join(' ');
                fields[name] = fields[name] ? fields[name].replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                fields[name] = str ? str.replace(/\u2028/g, '') : '';
                this.setState({ fields: fields }, () => { this.errorChange(name); });
            }
        }
    }

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            this.setState({ fields: state }, () => { this.errorChange(name) });
        }
    };

    handleDateChange = (val, name) => {
        //console.log('handleDateChange', val, name)
        let state = Object.assign({}, this.state.fields);
        if (val)
            state[name] = moment(val).format('YYYY-MM-DD');
        else
            state[name] = null;

        this.setState({ fields: state }, () => { this.errorChange(name) });
    };

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                // if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                //     if (typeof fields[key] !== "undefined") {
                //         let lastAtPos = fields[key].lastIndexOf('@');
                //         let lastDotPos = fields[key].lastIndexOf('.');

                //         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                //             errors[key] = errorClass;
                //         }
                //         else
                //             errors[key] = '';
                //     }
                // }
                // else
                errors[key] = '';
            }

            this.setState({ errors: errors });
        }
        //console.log('errorChange', isScroll, document.getElementById(key), errors, key)
        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    handleReactSelectChange = (e, data) => {
        //console.log('handleReactSelectChange', e, data)

        let state = Object.assign({}, this.state.fields);
        if (e)
            state[data.name] = e.value;
        else
            state[data.name] = '';


        if (data.name === 'country') {
            if (e)
                this.handleCountryChange(e.value);
            else
                this.handleCountryChange('');
            state['state'] = '';
        }

        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleCountryChange = make => {
        const { dealersettings } = this.props;
        const states = [];
        if (make && !_.isEmpty(dealersettings)) {
            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === make).forEach((doc) => {
                    states.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
        }
        this.setState({ states: states });
    }

    //#endregion

    //#region IMAGE UPLOAD

    saveImageToStorage = (imageBase64, id, type, ext) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;

        if (!this.newDocID) {
            this.newDocID = this.props.docID ? this.props.docID : this.ref.doc().id;
        }

        var storageRef = storageBucket(this.props.dealersettings).ref(`${this.selectedClientID}/users/${this.newDocID}/${type}/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(this.props.dealersettings).ref(`${this.selectedClientID}/users/${this.newDocID}/${type}`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        let state = Object.assign({}, this.state.fields);
                        state[id] = dataURL;
                        this.setState({ fields: state }, () => { this.errorChange(id) });
                    })
            })
    };

    isImageUploading = () => {
        let state = Object.assign({}, this.state.fields);
        if (this.isLoading ||
            (state.licenseBackURL && state.licenseBackURL.indexOf('base64') > 0) ||
            (state.licenseFrontURL && state.licenseFrontURL.indexOf('base64') > 0) ||
            (state.profileImage && state.profileImage.indexOf('base64') > 0))
            return true;
        else
            return false;
    }


    //#endregion

    //#region IMAGE CROP
    handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        let state = Object.assign({}, this.state.fields);
        state[id] = src;
        this.setState({ fields: state })
        this.saveImageToStorage(src, id, type, ext);

        this.setState({
            cropShow: {
                show: false,
                clsActive: '',
                src: null,
                id: '',
                type: '',
                ext: '',
                title: ''
            }
        });
    }

    onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 10) {
                Swal.fire(CommonHelper.showLocale(this.props, 'Maximum file size 10MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {

                this.setState({
                    cropShow: {
                        show: true,
                        clsActive: 'overlay-modal active',
                        src: reader.result,
                        id: id,
                        type: type,
                        ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                        title: title
                    }
                })
            }
            reader.readAsDataURL(file)

        }
    }

    handleSelectedFile = () => {
        const { file, id, type, title } = this.state.fileInfo;
        let reader = new FileReader();

        reader.onloadend = () => {

            this.setState({
                cropShow: {
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                },
                fileInfo: {
                    file: null,
                    id: '',
                    type: '',
                    title: ''
                }
            })
        }
        reader.readAsDataURL(file)

    }

    //#endregion


    //#region SAVE & SUBMIT 
    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        let _mandatoryFields = this.mandatoryFields;
        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        for (let [key] of Object.entries(fields)) {
            if (_mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                if ((_.isObject(fields[key]) && _.isEmpty(fields[key])) || (!_.isObject(fields[key]) && !fields[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;
                }
            }
        }

        this.setState({ errors: errors });

        if (formIsValid === true) {
            this.editUser(this);
        }
        else {
            let id = !_.isEmpty(errors) && Object.keys(errors)[Object.keys(errors).length - 1];
            if (id && document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            }
            return formIsValid;
        }

    };

    editUser = (e) => {
        this.isLoading = true;
        const objData = Object.assign({}, this.state.fields);

        objData.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        objData.modifiedDate = window.firebase.firestore.Timestamp.now();

        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        if (!this.newDocID) {
            this.newDocID = this.ref.doc().id;
            //console.log('newly created one')
        }

        if (!objData.documentID)
            objData.documentID = this.newDocID;

        const updateRef = this.ref.doc(this.newDocID);
        updateRef.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
            this.isLoading = false;
            toast.notify('User saved successfully', {
                duration: 2000
            })
            this.handleModalClose(this.newDocID, objData);
        }).catch((error) => {
            console.error("Error updating contact_details: ", error);
        });

    };

    handleModalClose = (ID, objData) => {
        this.setState({ fields: Object.assign({}, objUser) });
        this.setState({ errors: {} });
        this.props.handleClose(ID, objData);
    };

    //#endregion



    render() {
        //console.log('R E N D E R - U S E R ', this.state.fields.images);       

        const { name, profileImage, dob, address, state, areaCode, country,
            licenseNo, licenseExpiry, licenseFrontURL, licenseBackURL,
            licenseState, licenseType,
            phone, workPhone, email, personelEmail
        } = this.state.fields;

        const { countries, states } = this.state;
        const { show, dealersettings } = this.props;

        return (
            <>
                <Modal show={show}
                    onHide={this.handleModalClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    enforceFocus={false}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-staff-info"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='w-100'><Translate text={'staffDetails'} /></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="pop-layout form-style">
                                    <div className={`staff-info-pop-middle`}>

                                        <div className="form-row">

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'name'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'name'}
                                                    className={`form-control ${this.state.errors["name"]}`}
                                                    name="name"
                                                    onChange={this.handleOnChange}
                                                    value={name}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'dateOfBirth'} /></label>
                                                <div className="inner-addon right-addon">
                                                    <AntDatePicker
                                                        value={dob}
                                                        name={'dob'}
                                                        onChange={(e) => { this.handleDateChange(e, 'dob') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["dob"]}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'phone'} /> </label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={'phone'}
                                                    pattern="[0-9]*"
                                                    className={`form-control `}
                                                    name="phone"
                                                    onChange={this.onHandleTelephoneChange}
                                                    value={phone}
                                                />
                                            </div>
                                            {/* <div className="form-group col-md-6">
                                                <label ><Translate text={'workPhone'} /> </label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={'work phone'}
                                                    pattern="[0-9]*"
                                                    className={`form-control `}
                                                    name="workPhone"
                                                    onChange={this.onHandleTelephoneChange}
                                                    value={workPhone}
                                                />
                                            </div> */}

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'email'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'email'}
                                                    className={`form-control ${this.state.errors["email"]}`}
                                                    name="email"
                                                    onChange={this.handleOnChange}
                                                    value={email}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'address'} /> </label>
                                                <AutoComplete
                                                    className={`form-control ${this.state.errors["address"]}`}
                                                    bindAddress={this.bindAddress}
                                                    types={['address']}
                                                    value={address}
                                                    placeholder={'search address'}
                                                    onChange={this.handleOnChange}
                                                    componentRestrictions={country ? { country: country.toLowerCase() } : { country: [] }}
                                                    name="address"
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'areaCode'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'area/zip code'}
                                                    className={`form-control ${this.state.errors["areaCode"]}`}
                                                    value={areaCode}
                                                    name="areaCode"
                                                    onChange={this.handleOnChange}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'state'} /></label>
                                                <ReactSelect
                                                    options={states}
                                                    name={"state"}
                                                    placeholder={'select state'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={state}
                                                    classNamePrefix={`${this.state.errors["state"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'country'} /></label>
                                                <ReactSelect
                                                    options={countries}
                                                    name={"country"}
                                                    placeholder={'select country'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={country}
                                                    classNamePrefix={`${this.state.errors["country"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>


                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'licenseNo'} /></label>
                                                <InputText
                                                    autoComplete="off"
                                                    placeholder={'license #'}
                                                    className={`form-control ${this.state.errors["licenseNo"]}`}
                                                    name="licenseNo"
                                                    onChange={this.handleOnChange}
                                                    value={licenseNo}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'licenseExpiry'} /></label>
                                                <div className="inner-addon right-addon">

                                                    <AntDatePicker
                                                        value={licenseExpiry}
                                                        name={'licenseExpiry'}
                                                        onChange={(e) => { this.handleDateChange(e, 'licenseExpiry') }}
                                                        format='DD/MM/YYYY'
                                                        placeholder='DD/MM/YYYY'
                                                        className={`form-control ${this.state.errors["licenseExpiry"]}`}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'licenseState'} /></label>
                                                <ReactSelect
                                                    options={license_state}
                                                    name={"licenseState"}
                                                    placeholder={'select license state'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={licenseState}
                                                    classNamePrefix={`${this.state.errors["licenseState"]} basic-select`}
                                                >
                                                </ReactSelect>
                                            </div>


                                            <div className="form-group col-md-6">
                                                <label ><Translate text={'licenseType'} /></label>
                                                <ReactSelect
                                                    options={license_type}
                                                    name={"licenseType"}
                                                    placeholder={'select license type'}
                                                    onChange={this.handleReactSelectChange}
                                                    value={licenseType}
                                                    classNamePrefix={`${this.state.errors["licenseType"]} basic-select`}
                                                    AddUnAvailable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>License</label>
                                                <div className="staff-license-img-wraper">


                                                    <div className="staff-licenseboxwrap">
                                                        <div className={`staff-licensebox ${this.state.errors["licenseFrontURL"]}`}>
                                                            {
                                                                (licenseFrontURL && licenseFrontURL.indexOf('base64') > 0)
                                                                    ?
                                                                    <div className="img-loader">
                                                                        <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                            <label htmlFor="licenseFrontURL" className={`${licenseFrontURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                <i className={`ico ${licenseFrontURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseFrontURL"></i>
                                                                <input className="fileInput"
                                                                    type="file"
                                                                    name="licFront"
                                                                    id="licenseFrontURL"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => this.onSelectFile(e, 'license', 'licenseFrontURL', 'License Front')} />
                                                            </label>

                                                            {
                                                                (licenseFrontURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseFrontURL`} href={licenseFrontURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img src={licenseFrontURL} alt="" className="img-object-fit" />
                                                                    </a>
                                                                    :
                                                                    <img src={_images.nolicensebg} alt="" className="" />
                                                            }


                                                        </div>
                                                        <small className="form-text text-muted"><Translate text={'front'} /></small>
                                                    </div>


                                                    <div className="staff-licenseboxwrap">
                                                        <div className={`staff-licensebox ${this.state.errors["licenseBackURL"]}`}>
                                                            {
                                                                (licenseBackURL && licenseBackURL.indexOf('base64') > 0)
                                                                    ?
                                                                    <div className="img-loader">
                                                                        <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                                            <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                            }
                                                            <label htmlFor="licenseBackURL" className={`${licenseBackURL ? 'licensebox-edit mini-button' : ''}`}>
                                                                <i className={`ico ${licenseBackURL ? 'icon-edit' : 'icon-upload'}`} htmlFor="licenseBackURL"></i>
                                                                <input className="fileInput"
                                                                    type="file"
                                                                    name="licBack"
                                                                    id="licenseBackURL"
                                                                    accept="image/*"
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => this.onSelectFile(e, 'license', 'licenseBackURL', 'License Back')} />
                                                            </label>
                                                            {
                                                                (licenseBackURL)
                                                                    ?
                                                                    <a data-fancybox={`licenseBackURL`} href={licenseBackURL} onClick={(e) => { e.preventDefault(); }}>
                                                                        <img src={licenseBackURL} alt="" className="img-object-fit" />
                                                                    </a>
                                                                    :
                                                                    <img src={_images.nolicensebg} alt="" className="" />
                                                            }

                                                        </div>
                                                        <small className="form-text text-muted"><Translate text={'back'} /></small>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className={`${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null, null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right ${this.isImageUploading() ? 'form-disable' : ''}`}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'save'} />
                        </button>
                    </Modal.Footer>
                </Modal>
                <ImageCropHook
                    cropShow={this.state.cropShow}
                    handleClose={this.handleCropClose}
                    handleSelect={this.handleCropSelect}
                ></ImageCropHook>
            </>


        );
    }
}


export default EditUser;