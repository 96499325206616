import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import toast from 'toasted-notes'
import moment from 'moment'
import { ContentOverlay, TableView, PopUpModal, ReactSelect } from '../../../../components'
import { wholesalerVM, wholesalerlistFields } from '../users/viewModel'
import { default as _images } from "../../../../images";
import AddWholesaler from './addWholesaler'
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';
import Swal from 'sweetalert2';

const WholesalerList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [wholesalerList, setWholesalerList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [showwholesalerModel, setShowWholesalerModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedWholesaler, setSelectedwholesaler] = useState(null)
    const [showMergeModal, setShowMergeModal] = useState({
        show: false,
        id: null,
    })
    const [filter, setFilter] = useState(true)
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubAdminWholesalerList && window.unSubAdminWholesalerList();
        }
    }, [])

    useEffect(() => {
        //if (!_.isEmpty(selectedClient)) {
        window.unSubAdminWholesalerList = window.firebase.firestore().collection('users')
            .where('isWholesaler', '==', true)
            .where('wholesalerClientID', 'array-contains', props.dealersettings.client.id)
            .onSnapshot(querySnapshot => {
                let _wholesalers = [];
                querySnapshot.docs.forEach(doc => {
                    const _wholesalerdoc = {
                        ...doc.data(),
                        documentID: doc.id
                    };
                    _wholesalers.push(convertVM(_wholesalerdoc))

                })
                setWholesalerList(_wholesalers)
                setLoader(false)
            });
        //}
    }, [])

    const convertVM = (doc) => {
        const objwholesalerData = Object.assign({}, doc);
        const listVM = Object.assign({}, wholesalerVM);
        for (let [key, value] of Object.entries(objwholesalerData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }

        if (doc.isDisabled !== true && doc.isDisabled !== false) {
            listVM.isDisabled = !false
        } else {
            listVM.isDisabled = !doc.isDisabled
        }
        listVM.disabledUser = doc.isDisabled;
        listVM.userName = <><div className="table-list-users"><img src={CommonHelper.showUserAvatar(doc.profileImage, listVM.name)} alt="" className="img-object-fit" /></div> {listVM.name}</>
        listVM.lastlogin = doc.lastLoginTime ? moment.unix(doc.lastLoginTime.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.status = doc.tokkens && doc.tokkens.length > 0 ?
            (<div className="badge badge-pill badge-online ml-1"> <Translate text={'Online'} /></div>)
            : (<div className="badge badge-pill badge-offline ml-1"> <Translate text={'Offline'} /></div>);
        listVM.statusValue = doc.tokkens && doc.tokkens.length > 0 ? 'online' : 'offline';
        return listVM
    }

    const hadleEditWholesaler = (id) => {
        const editwholesaler = wholesalerList.find(item => item.documentID === id);
        const _wholesaler = Object.assign({}, wholesalerVM);
        for (let [key, value] of Object.entries(editwholesaler)) {
            if (_wholesaler[key] !== undefined)
                _wholesaler[key] = value;
        }
        setSelectedwholesaler(_wholesaler)
        setPopHeader('Edit Wholesaler')
        setShowWholesalerModel(true)
    }

    const hadleDeleteWholesaler = (id) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `Do you want to delete this wholesaler?`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                try {
                    const deleteUser = window.firebase.functions().httpsCallable('amsadmin-deleteUser');
                    const resp = await deleteUser({
                        "uid": id,
                    })
                    if (resp && resp.data && resp.data.success) {
                        window.firebase.firestore().doc(`users/${id}`).delete()
                            .then(snap => {
                                toast.notify('Wholesaler deleted successfully', {
                                    duration: 2000
                                })
                            })
                    } else {
                        Swal.fire(resp.data.message, '', 'error')
                        return;
                    }
                } catch (err) {
                    console.log(err)
                    Swal.fire(CommonHelper.showLocale(props, 'Something went wrong.'), '', 'error')
                    return;
                }
            }
        })
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                {
                    data.disabledUser === true ? (
                        <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                            hadleDeleteWholesaler(id)
                        }}>
                            <i className="ico icon-delete"></i>
                        </div>
                    ) : (
                        <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                            hadleEditWholesaler(id)
                        }}>
                            <i className="ico icon-edit"></i>
                        </div>
                    )
                }
            </div>
        )
    }

    const handleWholesalerdetailsClick = (e, id) => {
        e.preventDefault();
        hadleEditWholesaler(id)
    }




    const handleModelClose = () => {
        setSelectedwholesaler(null)
        setShowWholesalerModel(false)
    }
    const handleMergeModalClose = () => {
        setShowMergeModal({
            ...showMergeModal,
            show: false,
            id: null,
        })
    }

    const handleAddwholesaler = () => {
        setSelectedwholesaler(null)
        setPopHeader('Add Wholesaler')
        setShowWholesalerModel(true)
    }



    const handleSwitchChange = async (id, checked, data) => {
        if (_.isEmpty(data))
            return

        let _checked = !checked;
        // console.log(id, _checked, data)

        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, `Do you want to ${_checked ? 'disable' : 'enable'} this wholesaler?`),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then(async (result) => {
            if (result.value) {
                if (!_checked) {
                    // enabling user
                    const enableUser = window.firebase.functions().httpsCallable('amsadmin-enableUser');
                    const resp = await enableUser({
                        "uid": id,
                    })

                    // console.log('response of function call', resp)
                    if (resp && resp.data && resp.data.success) {
                        toast.notify('Wholesaler enabled successfully', {
                            duration: 2000
                        })
                    } else {
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    }
                } else if (_checked) {
                    if (data.arrayClientID.length > 0 && !_.isEmpty(data.settingsID)) {
                        const wholesalerRef = window.firebase.firestore().collection('users').doc(id)
                        await wholesalerRef.update({
                            isWholesaler: false,
                            wholesalerClientID: [],
                            wholesalerOEMID: [],
                            modifiedBy: localStorage.uid,
                            modifiedDate: window.firebase.firestore.Timestamp.now()
                        })
                    }
                    else {
                        await disableWholesaler(id, true)
                    }
                }
            }
        })
    }

    const disableWholesaler = async (id, isDisabled) => {
        try {
            const wholesalerRef = window.firebase.firestore().collection('users').doc(id)
            await wholesalerRef.update({
                isDisabled: isDisabled,
                tokkens: [],
                modifiedBy: localStorage.uid,
                modifiedDate: window.firebase.firestore.Timestamp.now()
            })
            try {
                window.firebase.firestore().collection(`users/${id}/devices`)
                    .where('isLogin', '==', true)
                    .get()
                    .then(snap => {
                        if (snap.docs.length > 0) {
                            snap.docs.forEach(doc => {
                                window.firebase.firestore().doc(`users/${id}/devices/${doc.id}`).set({
                                    isLogin: false,
                                    token: null
                                }, { merge: true })
                            })
                        }
                    });
            } catch (err) { }

            toast.notify('Wholesaler disabled successfully', {
                duration: 2000
            })
        } catch (error) {
            toast.notify('Something went wrong', {
                duration: 2000
            })
            console.log(error)
        }
    }

    const handleToggleFilter = (state) => {
        setFilter(state)
    }

    return (
        <>
            <h2><Translate text={'Wholesaler Management'} /></h2>
            <div className="user-management mt-2">
                <div className="settings-head form-style">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="switch-tab ml-3">
                                    <ul>
                                        <li onClick={() => handleToggleFilter(true)}><a href="#" className={filter ? 'active' : ''}><Translate text={'Activated'} /></a></li>
                                        <li onClick={() => handleToggleFilter(false)}><a href="#" className={!filter ? 'active' : ''}><Translate text={'Deactivated'} /></a></li>
                                    </ul>
                                </div>
                                <div className="filter-search search-icon col-md-3">
                                    <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                </div>
                            </div></div>
                        <div className="col-md-3">
                            <div className="float-right">
                                <button type="button" className="btn btn-primary float-right" onClick={() => { handleAddwholesaler(); }}><i className="ico icon-add mr-2"></i><Translate text={'Add Wholesaler'} /></button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        wholesalerList.length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    datalist={_.orderBy(wholesalerList
                                        .filter(wholesaler => {
                                            let val;
                                            if (filter) { // show enabled
                                                if (wholesaler.isDisabled) {
                                                    val = true
                                                } else {
                                                    val = false
                                                }
                                            } else { // show disabled
                                                if (wholesaler.isDisabled) {
                                                    val = false
                                                } else {
                                                    val = true
                                                }
                                            }
                                            return val
                                        })
                                        .filter(({ name, phone, email }) =>
                                        (!_.isEmpty(searchText) ? (
                                            (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                            (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                            (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                        ) : true)
                                        ), ['addedDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={wholesalerlistFields}
                                    dynamicFields={wholesalerlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleWholesalerdetailsClick}
                                    isSettings={false}
                                    isPaging={true}
                                    isReorderable={false}
                                    handleSwitchChange={handleSwitchChange}
                                    isSorting={true}
                                    isTranslate={true}
                                    dealersettings={props.dealersettings}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p><Translate text={'No wholesaler found'} /></p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddwholesaler(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        <Translate text={'Add Wholesaler'} />
                                    </button>
                                </div>
                            </div>
                        )
                }

            </div>
            <PopUpModal show={showwholesalerModel}>
                <AddWholesaler
                    {...props}
                    show={showwholesalerModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    wholesaler={selectedWholesaler}
                />
            </PopUpModal>
        </>
    )
}

export default WholesalerList