import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'react-bootstrap'
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import _ from 'lodash';
import moment from 'moment';
import {
	ImageCropHook,
	InputText,
	ContentOverlay,
	ReactSelect,
	AutoComplete,
	InputCheckBox,
	AntDatePicker,
} from '../../../../components';
import { cropShowModel, formDataModel } from '../viewModel';
import images from '../../../../images';
import Translate from '../../../../constants/translate';
import { storageBucket, validateEmail } from '../../../../services/helper';
import CommonHelper from '../../../../services/common';
import { MainContext } from '../../../layout/provider';
import { default as _images } from '../../../../images';
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';

const _languages = [
	{ active: true, value: 'en', label: 'English' },
	{ active: true, value: 'fr', label: 'French' },
	{ active: true, value: 'de', label: 'German' },
	{ active: true, value: 'nl', label: 'Dutch' },
	{ active: true, value: 'it', label: 'Italian' },
	{ active: true, value: 'es', label: 'Spanish' },
	{ active: true, value: 'sv', label: 'Swedish' },
	{ active: true, value: 'zh-Hans-CN', label: 'Chinese' },
	{ active: true, value: 'ar', label: 'Arabic' }
]

const PersonalInfo = props => {
	const { updateDealerSettings, changeLanguage } = useContext(MainContext);

	const [loader, setLoader] = useState(true);
	const [btnLoader, setBtnLoader] = useState(false);
	const [userData, setUserData] = useState(null);
	const [olduserData, setOlduserData] = useState(null);
	const [cropShow, setCropShow] = useState(cropShowModel);
	const [imageLoader, setImageLoader] = useState(false);
	const [errorFields, setErrorFields] = useState({});
	const [phoneCodes, setphoneCodes] = useState([])

	const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.rolePermissions) &&
		!_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
		props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

	const sales = (_.isEmpty(props.dealersettings.client.moduleSettings)
		|| _.isEmpty(props.dealersettings.client.moduleSettings.sales)
		|| (props.dealersettings.client.moduleSettings.sales.enabled === true && props.dealersettings.client.moduleSettings.sales.active === true)) ? true : false
	const service = (!_.isEmpty(props.dealersettings.client.moduleSettings)
		&& !_.isEmpty(props.dealersettings.client.moduleSettings.service)
		&& props.dealersettings.client.moduleSettings.service.enabled === true
		&& props.dealersettings.client.moduleSettings.service.active === true) ? true : false;
	const tradeinPro = (_.isEmpty(props.dealersettings.client.moduleSettings)
		|| _.isEmpty(props.dealersettings.client.moduleSettings.tradeinPro)
		|| (props.dealersettings.client.moduleSettings.tradeinPro.enabled === true && props.dealersettings.client.moduleSettings.tradeinPro.active === true)) ? true : false

	const kitchen = (_.isEmpty(props.dealersettings.client.moduleSettings)
		|| _.isEmpty(props.dealersettings.client.moduleSettings.kitchen)
		|| (props.dealersettings.client.moduleSettings.kitchen.enabled === true && props.dealersettings.client.moduleSettings.kitchen.active === true)) ? true : false


	const _userRoles =
		props.dealersettings &&
		props.dealersettings.roleID &&
		props.dealersettings.client &&
		props.dealersettings.client.roles &&
		props.dealersettings.client.roles.filter(
			e => e.documentID === props.dealersettings.roleID,
		)[0];
	const isInboundAccess =
		sales &&
			_userRoles &&
			_userRoles.pipeline &&
			_userRoles.pipeline.some(e => e === 'LeadsBucket')
			? true
			: false;
	const serviceAccess = (service &&
		(!_.isEmpty(props.dealersettings) &&
			!_.isEmpty(props.dealersettings.rolePermissions) &&
			!_.isEmpty(props.dealersettings.rolePermissions.modules) &&
			props.dealersettings.rolePermissions.modules.service) ? true : false);
	const kitchenAccess = (kitchen &&
		(!_.isEmpty(props.dealersettings) &&
			!_.isEmpty(props.dealersettings.rolePermissions) &&
			!_.isEmpty(props.dealersettings.rolePermissions.modules) &&
			props.dealersettings.rolePermissions.modules.kitchen) ? true : false);

	const lostApprovalEnabled = props?.dealersettings?.client?.clientSettings?.lostApproval ? true : false;

	const lostApproval = props?.dealersettings?.rolePermissions?.permissions?.lostApproval ? true : false;

	const isInboundAccessTIP =
		tradeinPro
			&& props?.dealersettings?.rolePermissions?.modules?.tradeinPro
			&& props?.dealersettings?.rolePermissions?.tradeinProPermissions?.inboundAccess
			? true
			: false;

	useEffect(() => {
		if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client)) {
			let _phoneCodes = [];
			props.dealersettings.countries && props.dealersettings.countries.forEach((doc) => {
				if (doc.phone_code) {
					_phoneCodes.push({
						country_code: doc.code,
						value: doc.phone_code,
						active: _.isBoolean(doc.active) ? doc.active : true,
						label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
					});
				}
			});
			setphoneCodes(_phoneCodes);
		}

	}, [props.dealersettings])

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const userRefDoc = window.firebase
					.firestore()
					.doc(`users/${localStorage.uid}`);

				const userSnapShot = await userRefDoc.get();
				const data = userSnapShot.data();
				setUserData(_.pick(data, _.keys(formDataModel)));
				setOlduserData(_.pick(data, _.keys(formDataModel)));
				setLoader(false);
			} catch (error) {
				console.log(error);
			}
		};

		fetchUser();
	}, []);

	useEffect(() => {
		function handleResize() {
			document.documentElement.style.setProperty(
				'--settingscontainerHeight',
				1040 + 'px',
			);
		}
		window.addEventListener('resize', handleResize);

		document.documentElement.style.setProperty(
			'--settingscontainerHeight',
			1040 + 'px',
		);
		return () => {
			window.removeEventListener('resize', handleResize);
			//document.documentElement.style.setProperty('--settingscontainerHeight', (window.innerHeight - 50) + 'px')
		};
	}, []);

	const handleChange = e => {
		const { value, name } = e.target;
		setUserData({ ...userData, [name]: value });

		setErrorFields({
			...errorFields,
			[name]: '',
		});
	};

	const handleOnChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		var array1 = value.split(' ');
		var str = value;

		if (name === 'email' || name === 'password' || name === 'cpassword') {
			setUserData({
				...userData,
				[name]: value
			});
		}
		else {
			if (array1.length > 0) {
				var newarray1 = [];
				for (var x = 0; x < array1.length; x++) {
					newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
				}
				setUserData({
					...userData,
					[name]: newarray1.join(' ')
				});
			} else {
				if (str && str.length >= 1) {
					var firstChar = str.charAt(0);
					var remainingStr = str.slice(1);
					str = firstChar.toUpperCase() + remainingStr;
				}
				setUserData({
					...userData,
					[name]: str
				});
			}
		}

		setErrorFields({
			...errorFields,
			[name]: '',
		});

	}


	const handlePhoneChange = e => {
		const { name, value } = e.target;
		const phoneReg = /^[0-9]+$/;
		if (phoneReg.test(value) || value === '') {
			setUserData({ ...userData, [name]: value });
		}
	};

	const handleAddPhoneCode = (name, value) => {
		let val = value ? value : '';
		setUserData({ ...userData, [name]: val });
	};

	const handleDeleteImage = () => {
		setUserData({
			...userData,
			profileImage: '',
		});
	};

	const handleReactSelectChange = (e, data) => {
		setUserData({
			...userData,
			[data.name]: e ? e.value : '',
		});
	};

	const bindAddress = place => {
		var objUser = userData;
		let componentForm = {
			street_number: 'short_name',
			route: 'long_name',
			sublocality_level_1: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			country: 'short_name',
			//administrative_area_level_1: 'long_name',
			//country: 'long_name',
			postal_code: 'short_name',
		};
		if (place.formatted_address !== null)
			objUser = {
				...objUser,
				['address']: place.formatted_address,
			};

		for (var i = 0; i < place.address_components.length; i++) {
			var addressType = place.address_components[i].types[0];
			if (componentForm[addressType]) {
				var val = place.address_components[i][componentForm[addressType]];
				if (addressType === 'administrative_area_level_1')
					objUser = {
						...objUser,
						['state']: val,
					};
				if (addressType === 'postal_code')
					objUser = {
						...objUser,
						['areaCode']: val,
					};
				if (addressType === 'country')
					objUser = {
						...objUser,
						['country']: val,
					};
			}
		}
		setUserData(objUser);
	};

	const onSelectFile = (e, type, id, title) => {
		const img = e.target.files[0].size;

		if (!img) {
			return;
		}

		if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
			Swal.fire(CommonHelper.showLocale(props, 'File format not supported. Please select image file.'), '', 'info');
			return;
		}

		const imgsize = img / 1024 / 1024;
		if (imgsize > 10) {
			Swal.fire(CommonHelper.showLocale(props, 'Maximum file size exceeded.'), '', 'info');
			return;
		}

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setCropShow({
				show: true,
				clsActive: 'overlay-modal active',
				src: reader.result,
				id: id,
				type: type,
				ext: file.name.substr(file.name.lastIndexOf('.') + 1),
				title: title,
			});
		};
		reader.readAsDataURL(file);
	};

	const handleCropClose = id => {
		if (id && document.getElementById(id))
			document.getElementById(id).value = '';
		setCropShow({
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});
	};

	const handleCropSelect = cropShow => {
		const { src, id, type, ext } = cropShow;

		setUserData({
			...userData,
			[id]: src,
		});

		saveImageToStorage(src, id, type, ext);

		setCropShow({
			show: false,
			clsActive: '',
			src: null,
			id: '',
			type: '',
			ext: '',
			title: '',
		});
	};

	const saveImageToStorage = (imageBase64, id, type, ext) => {
		let uid = moment()._d.getTime().toString() + '.' + ext;
		setImageLoader(true);
		const storageRef = storageBucket(props.dealersettings).ref(`users/${uid}`)
			.putString(imageBase64, 'data_url');
		storageRef.on(
			'state_changed',
			snapshot => {
				const percentage =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;

			},
			error => {
				console.log(error);
			},
			() => {
				storageBucket(props.dealersettings).ref(`users`)
					.child(uid)
					.getDownloadURL()
					.then(dataURL => {
						setImageLoader(false);
						setUserData({
							...userData,
							[id]: dataURL,
						});
					});
			},
		);
	};

	const handleImageDel = (id) => {
		Swal.fire({
			title: CommonHelper.showLocale(props, 'Are you sure?'),
			text: CommonHelper.showLocale(props, 'Do you want to delete this image.'),
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
		}).then((result) => {
			if (result.value) {
				setUserData({
					...userData,
					[id]: null,
				});
				let _profile = id + 'Profile';
				if (_profile && document.getElementById(_profile))
					document.getElementById(_profile).value = "";
			}
		})
	}

	const handleSubmit = async () => {
		try {
			let formIsValid = true;
			let errorClass = 'input_error';
			let errors = {};
			if (!_.isEmpty(userData['email']) && !validateEmail(userData['email'])) {
				formIsValid = false;
				errors['email'] = errorClass;
			}
			if (
				!_.isEmpty(userData['personelEmail']) &&
				!validateEmail(userData['personelEmail'])
			) {
				formIsValid = false;
				errors['personelEmail'] = errorClass;
			}
			setErrorFields(errors);
			if (!formIsValid) {
				return;
			}

			setBtnLoader(true);
			const objectToUpdate = {
				...userData,
				notifyInboundlead: isInboundAccess
					? Boolean(userData.notifyInboundlead)
					: false,
				notifyInboundleadOEM: isInboundAccess
					? Boolean(userData.notifyInboundleadOEM)
					: false,
				notifyKitchen: kitchenAccess
					? Boolean(userData.notifyKitchen)
					: false,
				notifyServiceBooking: serviceAccess
					? Boolean(userData.notifyServiceBooking)
					: false,
				notifyInboundleadTIP: isInboundAccess
					? Boolean(userData.notifyInboundleadTIP)
					: false,
			};

			if (objectToUpdate.email !== userData.email) {
				const updateUserEmail = window.firebase
					.functions()
					.httpsCallable('amsadmin-updateUserEmail');

				const resp = await updateUserEmail({
					uid: userData.documentID,
					email: objectToUpdate.email,
				});

				setUserData({ ...userData, email: objectToUpdate.email });

				if (resp.data.success === true) {
					// toast.notify('Email updated successfully', {
					// 	duration: 2000,
					// });
				} else {
					Swal.fire(resp.data.message, '', 'error');
					setBtnLoader(false);
					return;
				}
			}

			if (userData.enableMFA === true && olduserData.phone !== userData.phone) {
				const enrollMFA = window.firebase
					.functions()
					.httpsCallable('amsadmin-enrollMFA');

				const resp = await enrollMFA({
					"uid": userData.documentID,
					"phone": `+${userData.phoneCode.replace('+', '')}${userData.phone}`,
					"enroll": true
				});

				setUserData({ ...userData, phone: objectToUpdate.phone });

				if (resp.data.success === true) {
					// toast.notify('Email updated successfully', {
					// 	duration: 2000,
					// });
				} else {
					let msg = resp.data.message;
					if (resp.data.data.errorInfo && resp.data.data.errorInfo.code === 'auth/invalid-phone-number') {
						msg = 'Invalid phone number.'
					}
					Swal.fire(msg, '', 'error');
					setBtnLoader(false);
					return;
				}
			}

			window.firebase
				.firestore()
				.doc(`users/${localStorage.uid}`)
				.set(objectToUpdate, { merge: true })
				.then(snapshot => {
					toast.notify('Personel info updated successfully', {
						duration: 2000,
					});
					setBtnLoader(false);
					setOlduserData(objectToUpdate);
					if (objectToUpdate.language) {
						changeLanguage(objectToUpdate.language);
					}
					if (olduserData.notifyInboundlead !== userData.notifyInboundlead || olduserData.notifyInboundleadOEM !== userData.notifyInboundleadOEM || olduserData.notifyInboundleadTIP !== userData.notifyInboundleadTIP || olduserData.notifyKitchen !== userData.notifyKitchen || olduserData.currentDealerNorification !== userData.currentDealerNorification) {
						window.firebase
							.firestore()
							.doc(`clients/${props.dealersettings.client.id}`)
							.set(
								{
									settingsUpdate: window.firebase.firestore.Timestamp.now(),
									settingsUpdatePriority: 'normal',
									settingsUpdateBy: props?.dealersettings?.name || null,
									settingsUpdateText: 'Profile Settings has been updated',
								},
								{ merge: true },
							);
					}
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCheckChange = async e => {
		let check = e.target.checked;
		let _name = e.target.name
		setUserData({
			...userData,
			[_name]: check,
		});

		if (_name === 'ownDashboard') {
			props.updateDealerSettings('ownDashboard', check)
		}

		if (['isCafeOrderNotifySound', 'ownDashboard', 'isNotifyWithSound'].includes(_name)) {
			try {
				let objToSave = {}
				const userRef = window.firebase
					.firestore()
					.doc(`users/${localStorage.uid}`);
				objToSave[_name] = check
				await userRef.set(objToSave, { merge: true });
				if (['isCafeOrderNotifySound', 'isNotifyWithSound'].includes(_name))
					localStorage.setItem(_name, check);

			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleDateChange = (val, name) => {
		//console.log('handleDateChange', val, name)
		let value = '';
		if (val) value = moment(val).format('YYYY-MM-DD');
		else value = null;

		setUserData({ ...userData, [name]: value });
	};

	return (
		<>
			<h2>
				{' '}
				<Translate text={'personalInfo'} />
			</h2>
			<div className='divider-line'></div>
			{loader || _.isEmpty(userData) ? (
				<div className='mt-5'>
					<ContentOverlay active={true} />
				</div>
			) : (
				<>
					<div className='settings-inner form-style'>
						<div className='form-row settings-profile pt-2'>
							<div className='settings-profileimage'>
								{userData.profileImage &&
									userData.profileImage.indexOf('base64') > 0 ? (
									<div className='img-loader'>
										<div className='d-flex h-100 justify-content-center align-items-center text-secondary'>
											<div className='spinner-border' role='status'>
												{' '}
												<span className='sr-only'>Loading...</span>{' '}
											</div>
										</div>
									</div>
								) : (
									<img
										src={CommonHelper.showUserAvatar(
											userData.profileImage,
											userData.name,
										)}
										alt=''
										className='rounded-circle img-object-fit'
									/>
								)}
							</div>
							<div className='settings-profile-info'>
								<div className='settings-profile-upload'>
									<label className='btn-common float-left mr-2 font-600'>
										{' '}
										<Translate text={'Choose Picture'} />
										<input
											className='fileInput'
											type='file'
											name='profileImage'
											accept='image/*'
											style={{ display: 'none' }}
											id='profileImage'
											onChange={e =>
												onSelectFile(
													e,
													'users',
													'profileImage',
													'Profile Image',
												)
											}
										/>
									</label>
									<div className='settings-delete-button'>
										{' '}
										<span className='mini-button' onClick={handleDeleteImage}>
											{' '}
											<i className='ico icon-delete'></i>
										</span>
									</div>
								</div>
								<div className='settings-limit'>
									<Translate text={'maxSizeMB'} />
								</div>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'name'} />
								</label>

								<input
									type='text'
									name='name'
									value={userData.name}
									className='form-control'
									onChange={handleOnChange}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'Role'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'role'}
									className={`form-control`}
									name='role'
									onChange={handleChange}
									value={props.dealersettings.roleName}
									disabled={true}
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'Email Address'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'email'}
									className={`form-control ${errorFields['email']}`}
									name='email'
									onChange={handleChange}
									value={userData.email}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'Personal Email'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'personel email'}
									className={`form-control ${errorFields['personelEmail']}`}
									name='personelEmail'
									onChange={handleChange}
									value={userData.personelEmail}
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className="form-group col-md-6">
								<label > <Translate text={'Mobile No.'} /></label>
								<div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
									<div className='input-group-prepend'>
										<Dropdown className={`btn btn-outline-secondary`}>
											<Dropdown.Toggle as={CustomToggle}>
												<span id='spn-code-name' data-name={userData.phoneCode}>
													{userData.phoneCode &&
														phoneCodes.find(item => item.value === userData.phoneCode)
														? phoneCodes.find(
															item => item.value === userData.phoneCode,
														).value + ' '
														: 'Code'}
												</span>
											</Dropdown.Toggle>

											<Dropdown.Menu
												as={CustomSearchMenu}
												className='rm-pointers dropdown-menu-lg dropdown-menu-right'
												ChildClass='pipeline-dropdown'
												xplacement='bottom-end'
											>
												{!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
													phoneCodes.map((rec, index) => {
														return (
															<Dropdown.Item
																key={index}
																className={`current-pipeline ${userData.phoneCode && rec.value === userData.phoneCode
																	? 'active'
																	: ''
																	}`}
																onClick={e => {
																	e.preventDefault();
																	handleAddPhoneCode(
																		'phoneCode',
																		rec.value,
																	);
																}}
															>
																{rec.label}
															</Dropdown.Item>
														);
													})
												) : (
													<></>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<InputText
										autoComplete="off"
										placeholder={'phone'}
										className={`form-control`}
										name="phone"
										onChange={handlePhoneChange}
										value={userData.phone}
									/>
								</div>
							</div>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'Designation'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'designation'}
									className={`form-control`}
									name='designation'
									onChange={handleOnChange}
									value={userData.designation}
								/>
							</div>
						</div>



						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'address'} />
								</label>
								<AutoComplete
									className={`form-control`}
									bindAddress={bindAddress}
									types={['address']}
									value={userData.address ? userData.address : ''}
									placeholder={'search address'}
									onChange={handleChange}
									name='address'
								/>
							</div>


							<div className='form-group col-md-3'>
								<label>
									{' '}
									<Translate text={'country'} />
								</label>
								<ReactSelect
									options={
										!_.isEmpty(props.dealersettings.countries)
											? _.map(props.dealersettings.countries, function (e) {
												return {
													label: e.name,
													value: e.code,
													active: _.isBoolean(e.active) ? e.active : true,
												};
											})
											: []
									}
									name={'country'}
									placeholder={'select country'}
									onChange={handleReactSelectChange}
									value={userData.country}
									classNamePrefix={`cursor-pointer basic-select`}
									removeClearable={false}
								></ReactSelect>
							</div>

							<div className='form-group col-md-3'>
								<label>
									<Translate text={'state'} />
								</label>
								<ReactSelect
									options={
										!_.isEmpty(userData.country) &&
											!_.isEmpty(props.dealersettings.states)
											? _.map(
												props.dealersettings.states.filter(
													item => item.country_code === userData.country,
												),
												function (e) {
													return {
														label: e.name,
														value: e.code,
														active: _.isBoolean(e.active) ? e.active : true,
													};
												},
											)
											: []
									}
									name={'state'}
									placeholder={'select state'}
									onChange={handleReactSelectChange}
									value={userData.state}
									classNamePrefix={`cursor-pointer basic-select`}
									removeClearable={false}
								></ReactSelect>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'Office Phone No'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'work phone'}
									className={`form-control`}
									name='workPhone'
									onChange={handlePhoneChange}
									value={userData.workPhone}
								/>
							</div>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'areaCode'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'area code'}
									className={`form-control`}
									name='areaCode'
									onChange={handleChange}
									value={userData.areaCode}
								/>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									{' '}
									<Translate text={'licenseNo'} />
								</label>
								<InputText
									autoComplete='off'
									placeholder={'license #'}
									className={`form-control`}
									name='licenseNo'
									onChange={handleChange}
									value={userData.licenseNo ? userData.licenseNo : ''}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>
									<Translate text={'licenseExpiry'} />
								</label>
								<AntDatePicker
									value={userData.licenseExpiry ? userData.licenseExpiry : null}
									name={'licenseExpiry'}
									onChange={e => {
										handleDateChange(e, 'licenseExpiry');
									}}
									format='DD/MM/YYYY'
									placeholder='DD/MM/YYYY'
									className={`form-control`}
								/>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<label>
									<Translate text={'language'} />
								</label>
								<ReactSelect
									options={_languages}
									name={'language'}
									placeholder={'select language'}
									onChange={handleReactSelectChange}
									value={userData.language}
									classNamePrefix={`cursor-pointer basic-select`}
									removeClearable={false}
								></ReactSelect>
							</div>
						</div>
						{
							Boolean(props?.dealersettings?.wholesalerDashboard) || Boolean(props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly) ? (<></>) : (
								<>
									<div className='form-row'>
										<div className='profileinfo-license-wrapper'>
											<label>
												<Translate text={'License'} />
											</label>
											<div className='profileinfo-license-img-wraper'>
												<div className='profileinfo-licenseboxwrap'>
													<div className={`profileinfo-licensebox`}>
														{userData.licenseFrontURL &&
															userData.licenseFrontURL.indexOf('base64') > 0 ? (
															<div className='img-loader'>
																<div className='d-flex h-100 justify-content-center align-items-center text-secondary'>
																	<div className='spinner-border' role='status'>
																		{' '}
																		<span className='sr-only'>Loading...</span>{' '}
																	</div>
																</div>
															</div>
														) : (
															<></>
														)}
														<label
															htmlFor='licenseFrontURLProfile'
															className={`${userData.licenseFrontURL
																? 'licensebox-edit secondary-license mini-button'
																: ''
																}`}
														>
															<i
																className={`ico ${userData.licenseFrontURL
																	? 'icon-edit'
																	: 'icon-upload'
																	}`}
																htmlFor='licenseFrontURLProfile'
															></i>
															<input
																className='fileInput'
																type='file'
																name='licFront'
																id='licenseFrontURLProfile'
																accept='image/*'
																style={{ display: 'none' }}
																onChange={e =>
																	onSelectFile(
																		e,
																		'license',
																		'licenseFrontURL',
																		'License Front',
																	)
																}
															/>
														</label>

														{
															userData.licenseFrontURL
																?
																<>
																	<label title="Delete" htmlFor="licFrontDel"
																		className="licensebox-edit secondary-license mini-button mini-hover-red"
																		style={{ marginRight: '28px' }}
																		onClick={(e) => {
																			e.preventDefault();
																			handleImageDel('licenseFrontURL');
																		}}>
																		<i className="ico icon-delete" htmlFor="licFrontDel"></i>
																	</label>
																</>
																:
																<></>
														}

														{userData.licenseFrontURL ? (
															<a
																data-fancybox={`licenseFrontURL`}
																href={userData.licenseFrontURL}
																onClick={e => {
																	e.preventDefault();
																}}
															>
																<img
																	src={userData.licenseFrontURL}
																	alt=''
																	className='img-object-fit'
																/>
															</a>
														) : (
															<img src={_images.nolicensebg} alt='' />
														)}
													</div>
													<small className='form-text text-muted'>
														<Translate text={'front'} />
													</small>
												</div>

												<div className='profileinfo-licenseboxwrap'>
													<div className={`profileinfo-licensebox`}>
														{userData.licenseBackURL &&
															userData.licenseBackURL.indexOf('base64') > 0 ? (
															<div className='img-loader'>
																<div className='d-flex h-100 justify-content-center align-items-center text-secondary'>
																	<div className='spinner-border' role='status'>
																		{' '}
																		<span className='sr-only'>Loading...</span>{' '}
																	</div>
																</div>
															</div>
														) : (
															<></>
														)}
														<label
															htmlFor='licenseBackURLProfile'
															className={`${userData.licenseBackURL
																? 'licensebox-edit secondary-license mini-button'
																: ''
																}`}
														>
															<i
																className={`ico ${userData.licenseBackURL
																	? 'icon-edit'
																	: 'icon-upload'
																	}`}
																htmlFor='licenseBackURLProfile'
															></i>
															<input
																className='fileInput'
																type='file'
																name='licBack'
																id='licenseBackURLProfile'
																accept='image/*'
																style={{ display: 'none' }}
																onChange={e =>
																	onSelectFile(
																		e,
																		'license',
																		'licenseBackURL',
																		'License Back',
																	)
																}
															/>
														</label>

														{
															userData.licenseBackURL
																?
																<>
																	<label title="Delete" htmlFor="licBackDel"
																		className="licensebox-edit secondary-license mini-button mini-hover-red"
																		style={{ marginRight: '28px' }}
																		onClick={(e) => {
																			e.preventDefault();
																			handleImageDel('licenseBackURL');
																		}}>
																		<i className="ico icon-delete" htmlFor="licBackDel"></i>
																	</label>
																</>
																:
																<></>
														}

														{userData.licenseBackURL ? (
															<a
																data-fancybox={`licenseBackURL`}
																href={userData.licenseBackURL}
																onClick={e => {
																	e.preventDefault();
																}}
															>
																<img
																	src={userData.licenseBackURL}
																	alt=''
																	className='img-object-fit'
																/>
															</a>
														) : (
															<img src={_images.nolicensebg} alt='' />
														)}
													</div>
													<small className='form-text text-muted'>
														<Translate text={'Back'} />
													</small>
												</div>
											</div>
										</div>
									</div>
									<div className='switch-list'>
										<div className='row'>
											<div className='col-sm-6'>
												<h5 className='settings-subhead'><Translate text={'settings'} /></h5>
												<ul>
													{
														sales ? (<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='is-cafe-order-notify-sound'
																	className='switch'
																	name={'isCafeOrderNotifySound'}
																	checked={
																		userData.isCafeOrderNotifySound
																			? userData.isCafeOrderNotifySound
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='is-cafe-order-notify-sound'></label>
															</span>
															<Translate text={'Play notification sound on new kitchen order'} />
														</li>) : (<></>)
													}
													{(() => {
														return (
															<li>
																<span className='switch switch-sm mr-2'>
																	<InputCheckBox
																		id='is-notify-with-sound'
																		className='switch'
																		name={'isNotifyWithSound'}
																		checked={userData.isNotifyWithSound ? userData.isNotifyWithSound : false}
																		onChange={handleCheckChange}
																	/>
																	<label htmlFor='is-notify-with-sound'></label>
																</span>
																<Translate text={'Play sound on new notification'} />
															</li>
														)
													})()}

													{kitchenAccess ? (
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='notify-kitchen-booking'
																	className='switch'
																	name={'notifyKitchen'}
																	checked={
																		userData.notifyKitchen
																			? userData.notifyKitchen
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='notify-kitchen-booking'></label>
															</span>
															<Translate text={'Enable Kitchen notification'} />
														</li>
													) : (
														<></>
													)}
													{isInboundAccess ? (localStorage.defaultModule === 'oem' ? (
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='notify-oem-inbound-lead'
																	className='switch'
																	name={'notifyInboundleadOEM'}
																	checked={
																		userData.notifyInboundleadOEM
																			? userData.notifyInboundleadOEM
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='notify-oem-inbound-lead'></label>
															</span>
															<Translate text={'Enable inbound lead notification'} />
														</li>
													) : (
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='notify-inbound-lead'
																	className='switch'
																	name={'notifyInboundlead'}
																	checked={
																		userData.notifyInboundlead
																			? userData.notifyInboundlead
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='notify-inbound-lead'></label>
															</span>
															<Translate text={'Enable inbound lead notification'} />
														</li>
													)) : (
														<></>
													)}
													{serviceAccess ? (
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='notify-service-booking'
																	className='switch'
																	name={'notifyServiceBooking'}
																	checked={
																		userData.notifyServiceBooking
																			? userData.notifyServiceBooking
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='notify-service-booking'></label>
															</span>
															<Translate text={'Enable service booking notification'} />
														</li>
													) : (
														<></>
													)}
													{!ownerDashboard ? (
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='ownDashboard'
																	className='switch'
																	name={'ownDashboard'}
																	checked={
																		userData.ownDashboard
																			? userData.ownDashboard
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='ownDashboard'></label>
															</span>
															<Translate text={'Default my own dashboard'} />
														</li>
													) : (
														<></>
													)}
													{tradeinPro ? (<>
														<li>
															{' '}
															<span className='switch switch-sm mr-2'>
																<InputCheckBox
																	id='notify-new-tradeinpro'
																	className='switch'
																	name={'notifyNewtradeinPro'}
																	checked={
																		userData.notifyNewtradeinPro
																			? userData.notifyNewtradeinPro
																			: false
																	}
																	onChange={handleCheckChange}
																/>
																<label htmlFor='notify-new-tradeinpro'></label>
															</span>
															<Translate text={'Enable new appraisal notification'} />
														</li>
														{
															isInboundAccessTIP ? (
																<li>
																	{' '}
																	<span className='switch switch-sm mr-2'>
																		<InputCheckBox
																			id='notify-inbound-tradeinpro'
																			className='switch'
																			name={'notifyInboundleadTIP'}
																			checked={
																				userData.notifyInboundleadTIP
																					? userData.notifyInboundleadTIP
																					: false
																			}
																			onChange={handleCheckChange}
																		/>
																		<label htmlFor='notify-inbound-tradeinpro'></label>
																	</span>
																	<Translate text={'Enable Tradein Pro inbound lead notification'} />
																</li>
															) : (<></>)
														}
													</>) : (<></>)}
													{(() => {
														return (
															<li>
																<span className='switch switch-sm mr-2'>
																	<InputCheckBox
																		id='dealer-notification'
																		className='switch'
																		name={'currentDealerNorification'}
																		checked={userData.currentDealerNorification ? userData.currentDealerNorification : false}
																		onChange={handleCheckChange}
																	/>
																	<label htmlFor='dealer-notification'></label>
																</span>
																<Translate text={'Show only current dealer notifications'} />
															</li>
														)
													})()}
												</ul>
											</div>
										</div>
									</div>
								</>
							)
						}

					</div>
					<div className='settings-footer mt-3'>
						{/* <button type="button" className="btn btn-default float-left">Clear</button> */}
						<button
							type='button'
							className={`btn btn-primary float-left ${imageLoader === true ? 'btn-disable' : ''}`}
							onClick={handleSubmit}
						// disabled={isButtonDisable}
						>
							{btnLoader && (
								<span
									className='spinner-border spinner-button mr-1'
									role='status'
									aria-hidden='true'
								/>
							)}
							<Translate text={'save'} />
						</button>
					</div>
				</>
			)}
			<ImageCropHook
				cropShow={cropShow}
				handleClose={handleCropClose}
				handleSelect={handleCropSelect}
			></ImageCropHook>
		</>
	);
};

export default PersonalInfo;
