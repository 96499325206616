import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Translate from '../../constants/translate';
import axios from 'axios';
import { allrecipientFields } from './viewModel';
import Report from './campaignReport';
import CampaignDetails from './campaignDetails'
import _ from 'lodash';
import moment from 'moment';

let bodyHTML = `<div style="border: 1px solid #e5e5e5; padding: 15px 20px; max-width: 600px; margin: auto;">
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Hello ,</span><br><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">You got a new message from Tenderdash:</span></p>
<p style="padding: 12px; border-left: 6px solid #eee; font-style: italic;">&nbsp;</p>
<p><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">&nbsp;</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Best wishes,</span><br><span style="font-family: helvetica, arial, sans-serif; font-size: 12pt;">Tenderdash team</span></p>
</div>`

const AddTemplate = props => {
	const { API_KEY, SERVER_PREFIX } = props
	const [campaign, setCampaign] = useState(null);
	const [activeTab, setActiveTab] = useState('detail');
	const [recipients, setRecipients] = useState([]);
	const [recipientLoader, setRecipientLoader] = useState(true);
	const [loader, setLoader] = useState(true);
	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth - 440,
		windowHeight: window.innerHeight - 273,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth - 440,
				windowHeight: window.innerHeight - 273,
			});
		}
		window.addEventListener('resize', handleResize);
		document.getElementsByTagName('body')[0].classList.add('inner-bg');
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unSubCampaigns && window.unSubCampaigns();
		};
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setRecipientLoader(true)
			const response = await axios.post(`${props.apiUrl}mailchimp-api/api/mailchimp/campaign-info`, {
				campaign_id: props.campaign_id,
				API_KEY: API_KEY,
				SERVER_PREFIX: SERVER_PREFIX,
			})
			// console.log(response);
			if (response.data && response.data.response) {
				setCampaign(response.data.response);
				let campaignData = response.data.response
				// console.log('campaignData', response.data.response)

				if (campaignData.settings.template_id) {
					const resp = await axios.post(`${props.apiUrl}mailchimp-api/api/mailchimp/get-template-source`, {
						campaign_id: campaignData.id,
						template_id: campaignData.settings.template_id,
						API_KEY: API_KEY,
						SERVER_PREFIX: SERVER_PREFIX,
					})
						// console.log('resp of template in preview mode ', resp)
					if (resp.data.response && resp.data.response.html) {
						let templateData =  {
							bodyHTML : resp.data.response.html,
							id: campaignData.settings.template_id,
							documentID: campaignData.settings.template_id,
							type: 'fromMailchimp'
						}
						// console.log('templateData',templateData)
						setCampaign({...campaignData, templateData});
					}
				}

				const querySnapshot = await window.firebase.firestore().collection('campaignRecipients').where('campaignID', '==', response.data.response.id).get()
				// console.log(querySnapshot)
				let documentID  
				if (!querySnapshot.empty) {
					querySnapshot.forEach((doc, i) => {
						documentID = doc.id
					});
					// console.log('abc',documentID)

					const snapshots = await window.firebase.firestore().collection('campaignRecipients').doc(documentID).collection('recipients').limit(100).get()
					// console.log('123',snapshots)
					if (!snapshots.empty) {
						const data = []
						snapshots.forEach((doc) => {
							// doc.data() is never undefined for query doc snapshots
							// console.log(doc.id, " => ", doc.data());
							if (doc?.data()?.emails?.length > 0) {
								doc.data().emails.forEach(email => {
									data.push({
										email: email,
										sendTime: campaignData?.send_time ? moment(campaignData?.send_time).format('DD/MM/YYYY hh:mm A') : ''
									})
								})
							}
							else {
								data.push({
									email: doc.data().email,
									sendTime: campaignData?.send_time ? moment(campaignData?.send_time).format('DD/MM/YYYY hh:mm A') : ''
								})
							}

						});
						setRecipients(data)
					}
				}
			}
			setRecipientLoader(false)

		}
		fetchData()
		// axios
		// 	.post('http://localhost:5000/campaign-info', {
		// 		campaign_id: props.campaign_id,
		// 	})
		// 	.then(function (response) {
		// 		console.log(response);
		// 		if (response.data && response.data.response) {
		// 			setCampaign(response.data.response);
		// 			let campaignData = response.data.response

		// 			if (campaignData.settings.template_id) {
		// 				axios.post('http://localhost:5000/get-template-source', {
		// 					campaign_id: campaignData.id,
		// 					template_id: campaignData.settings.template_id,
		// 				}).then(response => {
		// 					// console.log('resp of template in edit mode ', response)
		// 					if (response.data.response && response.data.response.html) {
		// 						let templateData =  {
		// 							bodyHTML : response.data.response.html,
		// 							title: response.data.tempRes.name ? response.data.tempRes.name : '',
		// 							id: campaignData.settings.template_id,
		// 							documentID: campaignData.settings.template_id,
		// 							type: 'fromMailchimp'
		// 						}
		// 						setCampaign({...campaign, templateData});
		// 					}
		// 				})
		// 			}	

		// 			window.firebase.firestore().collection('campaignRecipients').where('campaignID', '==', response.data.response.id).get().then(querySnapshot => {
		// 				console.log(querySnapshot)
		// 				let documentID  
		// 				if (!querySnapshot.empty) {
		// 					querySnapshot.forEach((doc, i) => {
		// 						documentID = doc.id
		// 					});
		// 					console.log('abc',documentID)

		// 					window.firebase.firestore().collection('campaignRecipients').doc(documentID).collection('recipients').get().then(snapshots => {
		// 						console.log('123',snapshots)
		// 							if (!snapshots.empty) {
		// 								const data = []
		// 								snapshots.forEach((doc) => {
		// 									// doc.data() is never undefined for query doc snapshots
		// 									console.log(doc.id, " => ", doc.data());
		// 									data.push(doc.data())
		// 							});
		// 							setRecipients(data)
		// 						}
		// 					})
		// 				}
		// 			})
		// 		}
		// 		setRecipientLoader(false)
		// 	})
		// 	.catch(function (error) {
		// 		setRecipientLoader(false)
		// 		console.log(error);
		// 	});
	}, []);

	const handleActionClick = () => {

	}
	const handleRowSingleClick = () => {

	}

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
              {campaign && (

							<div className='container-fluid pl-0 '>
								{recipientLoader ? (
									<div className='loader-center-screen'>
										<div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
									</div>
								) : activeTab === 'detail' ? (
									<CampaignDetails
									setActiveTab={setActiveTab}
									campaign={campaign}
									bodyHTML={bodyHTML}
									recipients={recipients}
									windowSize={windowSize}
									allrecipientFields={allrecipientFields}
									handleActionClick={handleActionClick}
									handleRowSingleClick={handleRowSingleClick}
									activeTab={activeTab}
									/>
								) : (
									<Report 
									setActiveTab={setActiveTab}
									 campaign={campaign}
									 activeTab={activeTab}

									/>
								)}
							</div>
              )}
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</>
	);
};

export default AddTemplate;
