/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import toast from 'toasted-notes'
import _ from 'lodash';
/** COMPONENTS */
import { InputTextArea, ReactSelect, ReactTimePicker } from '../../../components';
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import { firestoreDB } from '../../../services/helper';
import { eventAttendeeStatus } from '../../../services/enum';
import { eventProMapVM } from '../viewModel';
//#region  ASSIGN VARIABLES
let titles = [];
//#endregion
export default class ConfirmAttendee extends Component {
    constructor(props) {
        super(props);
        this.isLoadingA = false;
        this.isLoadingR = false;
        this.state = {
            dietary_preferences: [],
            event_slots: [],
            _dealers: [],
            eventDates: [],
            fields: {
                eventDate: null,
                dietaryPreference: null,
                eventSlot: null,
                rejectReason: null
            },
            errors: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            this.loadSettings();
    }

    loadSettings = async () => {
        const { dealersettings, contact, eventsPro, eventsProID } = this.props;

        let objData = Object.assign({}, this.state.fields);
        for (let [key, value] of Object.entries(contact)) {
            if (objData.hasOwnProperty(key)) {
                objData[key] = value;
            }
        }

        let eventSettings = null;
        if (eventsProID) {
            const refSettingsData = await firestoreDB(dealersettings).firestore().doc(`eventsPro/${eventsProID}/settings/${eventsProID}`).get();
            if (refSettingsData.exists)
                eventSettings = Object.assign({}, refSettingsData.data());
        }


        let dietary_preferences = [];
        eventSettings?.dietary && eventSettings.dietary.forEach((doc) => {
            dietary_preferences.push({
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        let event_slots = [];
        eventSettings?.slots && eventSettings.slots.forEach((doc) => {
            event_slots.push({
                ...doc,
                value: doc.value,
                active: _.isBoolean(doc.active) ? doc.active : true,
                label: doc.name
            });
        });

        let _dealers = [];
        dealersettings.clients.forEach(rec => {
            _dealers.push({
                label: rec.name,
                value: rec.id,
                data: rec,
                active: true,
                projectId: rec.projectId
            })
        })

        let _dates = []
        for (var m = moment.unix(eventsPro.startDate.seconds).startOf('day'); m.isSameOrBefore(moment.unix(eventsPro.endDate.seconds).startOf('day')); m.add(1, 'days')) {
            _dates.push({
                value: m.format('YYYY-MM-DD'),
                label: m.format(eventSettings?.dateFormat || 'DD/MM/YYYY'),
            })
        }

        if (!_.isEmpty(event_slots) && objData.eventSlot && _.find(event_slots, { value: objData.eventSlot })?.date)
            objData.eventDate = _.find(event_slots, { value: objData.eventSlot })?.date;

        this.setState({
            fields: objData,
            _dealers: _dealers,
            event_slots: event_slots,
            dietary_preferences: dietary_preferences,
            eventDates: _dates,
            eventSettings: eventSettings
        });


    }

    handleReactSelectChange = (e, data) => {
        let state = Object.assign({}, this.state.fields);
        if (e) {
            state[data.name] = e.value;
        }
        else {
            state[data.name] = '';
        }

        if (data.name === 'eventDate') {
            state['eventSlot'] = null;
        }
        this.setState({ fields: state }, () => { this.errorChange(data.name) });
    }

    handleSlotSelect = (value) => {
        let state = Object.assign({}, this.state.fields);
        state['eventSlot'] = value;
        this.setState({ fields: state }, () => { this.errorChange('eventSlot') });
    }

    handleOnAreaChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
        else
            fields[name] = ''
        this.setState({ fields: fields }, () => { this.errorChange(name) });
        //this.errorChange(name);
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (_.isObject(this.props.mandatoryFields) && !_.isEmpty(this.props.mandatoryFields))
            _mandatoryFields = _.union(_mandatoryFields, this.props.mandatoryFields);

        if (!_.isEmpty(errors)) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }
        //console.log('errorChange', isScroll, document.getElementById(key), errors, key)
        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }

    handleAccept = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (_.isEmpty(fields[key]) && key !== 'dietaryPreference' && key !== 'rejectReason') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        this.setState({ errors: errors });

        if (formIsValid === false) return;
        this.isLoadingA = true;
        this.handleSave(eventAttendeeStatus.EOIConfirmed);
    };

    handleReject = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        for (let [key] of Object.entries(fields)) {
            if (_.isEmpty(fields[key]) && key === 'rejectReason') {
                formIsValid = false;
                errors[key] = errorClass;
            }
        }
        this.setState({ errors: errors });

        if (formIsValid === false) return;
        this.isLoadingR = true;
        this.handleSave(eventAttendeeStatus.EOIUnqualified);
    };

    handleSave = (_status) => {

        const { contact, dealersettings, eventsPro } = this.props;
        const { fields } = this.state;
        let userID = dealersettings ? dealersettings.id : '';
        let currenDate = window.firebase.firestore.Timestamp.now();

        let objData = Object.assign({});
        objData.modifiedBy = userID;
        objData.modifiedDate = currenDate;
        objData.modifiedFrom = 'web';
        objData.status = _status;
        objData.actionedBy = userID;
        objData.actionedByName = dealersettings?.name;
        objData.actionedDate = currenDate;

        if (_status === eventAttendeeStatus.EOIConfirmed) {
            if (fields.dietaryPreference) objData.dietaryPreference = fields.dietaryPreference;
            if (fields.eventSlot) objData.eventSlot = fields.eventSlot;

            if (!_.isEmpty(eventsPro)) {
                let eventData = Object.assign({});
                for (let [key, value] of Object.entries(eventsPro)) {
                    if (eventProMapVM.hasOwnProperty(key) && value !== undefined) eventData[key] = value;
                }
                objData.eventsPro = eventData;
                objData.eventsProID = eventData.documentID;
            }
            else {
                objData.eventsProID = contact.eventsProID.slice(0, -4);
            }
        }

        if (contact.clientID && _.find(this.state._dealers, { value: contact.clientID }))
            objData.clientName = _.find(this.state._dealers, { value: contact.clientID })?.label

        if (fields.rejectReason) {
            let _notes = Object.assign([], contact?.notes)
            _notes.push(
                {
                    documentID: firestoreDB(dealersettings).firestore().collection('inboundLeads').doc().id,
                    notes: fields.rejectReason,
                    //mentions: Object.assign([], convertedIDs),
                    addedBy: localStorage.uid,
                    addedByName: dealersettings.name,
                    addedDate: window.firebase.firestore.Timestamp.now(),
                    addedFrom: 'web',
                    modifiedBy: localStorage.uid,
                    modifiedDate: window.firebase.firestore.Timestamp.now(),
                    modifiedFrom: 'web'
                });
            objData.notes = _notes
        }
        //Remove empty value from object
        for (var propName in objData) {
            if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
                delete objData[propName];
            }
        }

        const updateRef = firestoreDB(dealersettings).firestore().doc(`eventsProAttendees/${contact.documentID}`);
        updateRef
            .update(objData)
            .then(docRef => {
                this.isLoadingA = false;
                this.isLoadingR = false;
                toast.notify('Attendee confirmed successfully', {
                    duration: 2000
                })
                this.props.handleClose(contact.documentID);
            })
            .catch(error => {
                this.isLoadingA = false;
                this.isLoadingR = false;
                console.error('Error updating enquiries: ', error);
            });

    }


    render() {
        const { dealersettings, clientUsers, groupUsers, contact, eventsPro } = this.props;
        const { eventDates, dietary_preferences, event_slots, fields, errors, eventSettings } = this.state;
        let _allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={this.props.show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-events-confirm-userinfo">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate text={'Expression Of Interest'} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="events-confirm-userinfo-body">
                            <h3> <Translate text={'contactDetails'} /></h3>
                            <div className="events-confirm-userinfo-box">

                                <div className="events-confirm-userinfo-thumb ">
                                    <img src={CommonHelper.showUserAvatar(contact?.userImageURL, CommonHelper.getFullName([], contact, '', true))} alt="" className="rounded-circle img-object-fit" />
                                </div>
                                <div className="events-confirm-userinfo-details ">

                                    <div className="events-confirm-userinfo-title"> {CommonHelper.displayFullContact(titles, contact, '--')}</div>
                                    <div className="events-confirm-userinfo-subtitle">
                                        <ul className="events-confirm-userinfo-list">
                                            <li> <i className="ico icon-call"></i> {contact && contact.phone ? ((contact.phoneCode ? contact.phoneCode : '') + contact.phone) : '--'}</li>
                                            <li> <i className="ico icon-email"></i> {contact && contact.email ? contact.email : '--'}</li>
                                        </ul>
                                    </div>
                                    <div className="events-confirm-userinfo-subtitle">
                                        <ul className="events-confirm-userinfo-list">
                                            <li> <i className="ico icon-location"></i> {contact && contact.address ? contact.address : '--'} </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h3>Preferences</h3>
                            <div className="form-style w-100">
                                <div className='form-row'>
                                    <div className="form-group col-md-6">
                                        <label ><Translate text={'dietaryPreference'} /></label>
                                        <ReactSelect
                                            options={dietary_preferences}
                                            name={"dietaryPreference"}
                                            placeholder={'select dietary preference'}
                                            onChange={this.handleReactSelectChange}
                                            value={fields.dietaryPreference}
                                            classNamePrefix={`${errors["dietaryPreference"]} basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label ><Translate text={'eventSlot'} /></label>
                                        <ReactSelect
                                            options={eventDates}
                                            name={"eventDate"}
                                            placeholder={'select date'}
                                            onChange={this.handleReactSelectChange}
                                            value={fields.eventDate}
                                            classNamePrefix={`${errors["eventSlot"]} basic-select`}
                                        >
                                        </ReactSelect>
                                    </div>
                                </div>


                                {
                                    fields.eventDate && event_slots.filter(a => a.date === fields.eventDate).length ? (
                                        <div className="form-group">
                                            <div className={`custom-slot-listwrap mt-2`}>
                                                <div className="custom-slot-availablity">Available timeslots on <span className="custom-slot-bluelink">{moment(fields.eventDate).format('dddd,Do MMM YYYY')}</span> </div>
                                                <div className={`custom-slot-list ${errors["eventSlot"] ? 'border-error' : ''}`}>
                                                    {
                                                        _.orderBy(event_slots.filter(a => a.date === fields.eventDate), 'startTime', 'asc').map(rec => {
                                                            return <div id={rec.value} key={rec.value} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.handleSlotSelect(rec.value)
                                                            }} className={`custom-slot-loopbox ${rec.value === fields.eventSlot ? 'active' : rec.total && rec.booked === rec.total ? 'inactive form-disable' : ''}`}>{moment.unix(rec.startTime.seconds).tz(eventsPro.timezone).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)} - {moment.unix(rec.endTime.seconds).tz(eventsPro.timezone).format(`${eventSettings?.timeFormat || 'hh:mm A'}`)}
                                                                {
                                                                    rec.total && (_.isEmpty(rec.booked) || rec.total !== rec.booked) ? (
                                                                        <div className="custom-slot-counterwrap"><div className="custom-slot-counter">{rec.booked ? rec.booked : 0} / {rec.total}</div></div>
                                                                    ) : (<></>)
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div></div>
                                    ) : (<> {fields.eventDate && (<div className="alert alert-danger" role="alert">
                                        There are no open time slots available on <span className="custom-slot-bluelink">{moment(fields.eventDate).format('dddd,Do MMM YYYY')}</span>
                                    </div>)}</>)
                                }
                                <h3>Notes</h3>
                                <div className="form-group">
                                    <InputTextArea
                                        className={`engage-request-notewrap ${errors["rejectReason"]}`}
                                        name="rejectReason"
                                        onChange={this.handleOnAreaChange}
                                        value={fields.rejectReason}
                                        maxLength="500"
                                        rows="3">
                                    </InputTextArea>
                                </div>

                                {!_.isEmpty(contact?.notes)
                                    ?
                                    <>
                                        <h3>Notes History</h3>
                                        {
                                            _.orderBy(contact.notes, ['addedDate'], ['desc']).map((noteData, index) => {
                                                return <div key={index} className="events-confirm-userinfo-notes text-area-space ">
                                                    <div>{noteData.notes}</div>
                                                    <div className="events-confirm-userinfo-notes-sub">

                                                        <span className="pl-0">{noteData.addedDate ? moment.unix(noteData.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</span>
                                                        &nbsp;{' - '}&nbsp;{CommonHelper.getUserNamebyId(_allUsers, noteData.addedBy)}

                                                        {/* {noteData.addedFrom ?
                                                                <>
                                                                    &nbsp;{` - `}&nbsp;
                                                                    <i className={`ico icon-${noteData.addedFrom === 'web' ? 'monitor' : noteData.addedFrom} mr-1`}></i>
                                                                    {noteData.addedFrom} </> : <></>} */}
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </>
                                    :
                                    <>
                                    </>}

                            </div>


                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoadingA || this.isLoadingR ? 'form-disable' : ''}`}>

                        <button type="button"
                            className={`btn btn-red float-left ${this.isLoadingR ? 'form-disable' : ''}`}
                            onClick={(e) => { e.preventDefault(); this.handleReject(e); }}
                        >
                            {this.isLoadingR ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'reject'} />
                        </button>
                        <button type="button"
                            className={`btn btn-primary float-right ${this.isLoadingA ? 'form-disable' : ''}`}
                            onClick={(e) => { e.preventDefault(); this.handleAccept(e); }}
                        >
                            {this.isLoadingA ? <span className="spinner-border spinner-border-sm mb-1 mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'accept'} />
                        </button>

                    </Modal.Footer>
                </Modal >

            </>
        );
    }
}
