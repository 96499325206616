import React, { Component, Fragment } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";

import ActivitiesCount from '../dashboard/activitiesCount'
import InboundActivitiesCount from '../dashboard/inboundactivitiesCount'
import SalesFunnel from './salesFunnel'
import StatusCounts from './statusCounts'
import ShowNoShowCounts from './showNoshowCounts'
import LeadsBucket from './leadsBucket'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class DragDropLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      layouts: { lg: props.initialLayout }
    };

    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialLayout !== this.state.layouts) {
      this.setState({ layouts: { lg: nextProps.initialLayout } });
    }
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onLayoutChange(layout, layouts) {
    //console.log('onLayoutChange layout, layouts', layout, layouts)
    this.props.onLayoutChange(layout, this.props.sortedWidgets, this.props.hiddenWidgets);
  }

  calculateHeight(sectionID, layoutArr) {
    let selectedSection = layoutArr.filter(obj => obj.i === sectionID)
    if (selectedSection.length === 0)
      return 30
    let columnHeight = Number(selectedSection[0].h)
    if (columnHeight === 1) {
      return 30
    }
    columnHeight = columnHeight - 1
    columnHeight = columnHeight * 45
    columnHeight = columnHeight + 30

    return columnHeight
  }

  filterLayouts = (layouts) => {

    if (!this.props.pipeline.some(item => item.value === 'LeadsBucket') || (this.props.clientLostApproval && this.props.managerLostApproval)) {
      if (layouts.lg[0].i === '0') {
        layouts.lg.shift()
      }
    }
    //console.log('layout passed to the grid', layouts)

    // settings height and width to 0 of hidden widgets
    // let layoutWithDashboardSettingsApplied = {...layouts}
    let layoutWithDashboardSettingsApplied = layouts.lg.filter(a => a.i !== 'null').map((rec, index) => {
      return this.props.hiddenWidgets.includes(rec.i) ? {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        w: 0,
        h: 0
      } : {
        ..._.pickBy(rec, v => v !== null && v !== undefined),
        i: rec.i === 'null' ? `${index}` : rec.i,
        maxH: 16,
        maxW: 12,
        minH: rec.i === 'LeadsBucket' ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
        minW: 4,
        h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? (rec.i === 'LeadsBucket' ? 4 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
        w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
        isResizable: rec.i === 'LeadsBucket' ? false : true,
        isBounded: false,
        isDraggable: true,
        resizeHandles: false
      }
    })

    return { lg: layoutWithDashboardSettingsApplied }
  }

  render() {
    const {
      handleShowEnquiryList,
      inboundleadEnquiries,
      selectedPipeline,
      selectedUser,
      clientLostApproval,
      managerLostApproval,
      dateRange,
      applydateRange,
      loader,
      allActivities,
      testdrives,
      tradeins,
      contacts,
      dealersettings,
      pipeline,
      hiddenWidgets,
      sortedWidgets,
      ownerDashboard,
      removeUnAllowedWidgets,
    } = this.props;

    const showInboundActivities = ((dealersettings?.client?.clientSettings?.separateInbdUserAct) ? true : false);

    return (clientLostApproval && managerLostApproval) ? (
      <div>
        <ResponsiveReactGridLayout
          {...this.props}
          layouts={this.filterLayouts(this.state.layouts)}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
          margin={[15, 15]}
          isResizable={true}
          draggableHandle='.draggable-section'
          draggableCancel='.dashboard-box-body'
        >
          {(clientLostApproval && managerLostApproval) &&
            sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsBucket') ? (
            <div key={'LeadsBucket'} id={'LeadsBucket'}>
              <LeadsBucket {...this.props}
                handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                clientLostApproval={clientLostApproval}
                managerLostApproval={managerLostApproval}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('LeadsBucket', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('LeadsBucket')}
              />
            </div>
          ) : (<Fragment key={'LeadsBucket'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
            <div key={'Activities'} id={'Activities'}>
              {
                (showInboundActivities && dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true))
                  ?
                  <InboundActivitiesCount {...this.props}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    height={this.calculateHeight('Activities', this.props.initialLayout)}
                    reloadData={this.props.reloadData}
                    ownerDashboard={ownerDashboard}
                    refreshData={this.props.refreshData}
                    removeWidget={() => this.props.removeWidget('Activities')}
                    roleIDs={dealersettings.client.roles
                      .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                      .map(r => { return r.documentID })
                      .join(',')}
                    title={'Activities'}
                  />
                  :
                  <InboundActivitiesCount {...this.props}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    height={this.calculateHeight('Activities', this.props.initialLayout)}
                    reloadData={this.props.reloadData}
                    ownerDashboard={ownerDashboard}
                    refreshData={this.props.refreshData}
                    removeWidget={() => this.props.removeWidget('Activities')}
                    roleIDs={dealersettings.client.roles
                      .filter(e => e.pipeline && e.pipeline.some(m => m === 'LeadsBucket'))
                      .map(r => { return r.documentID })
                      .join(',')}
                    title={'Activities'}
                  />
              }
            </div>
          ) : (<Fragment key={'Activities'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesFunnel') ? (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight('SalesFunnel', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
              />
            </div>
          ) : (<Fragment key={'SalesFunnel'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
            <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
              <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('LeadsByStatus')}
              />
            </div>
          ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByShow') ? (
            <div key={'LeadsByShow'} id={'LeadsByShow'}>
              <ShowNoShowCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('LeadsByShow', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('LeadsByShow')}
              />
            </div>
          ) : (<Fragment key={'LeadsByShow'}></Fragment>)}
        </ResponsiveReactGridLayout>
      </div>
    ) : (
      <div>
        <ResponsiveReactGridLayout
          {...this.props}
          layouts={this.filterLayouts(this.state.layouts)}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          measureBeforeMount={false}
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
          margin={[15, 15]}
          isResizable={true}
          draggableHandle='.draggable-section'
          draggableCancel='.dashboard-box-body'
        >
          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('Activities') ? (
            <div key={'Activities'} id={'Activities'}>
              {
                (showInboundActivities && dealersettings.client.roles.some(e => e.permissions && e.permissions.separateInbdUserAct === true))
                  ?
                  <InboundActivitiesCount {...this.props}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    height={this.calculateHeight('Activities', this.props.initialLayout)}
                    reloadData={this.props.reloadData}
                    ownerDashboard={ownerDashboard}
                    refreshData={this.props.refreshData}
                    removeWidget={() => this.props.removeWidget('Activities')}
                    roleIDs={dealersettings.client.roles
                      .filter(e => e.permissions && Boolean(e.permissions.separateInbdUserAct) === true)
                      .map(r => { return r.documentID })
                      .join(',')}
                    title={'Activities'}
                  />
                  :
                  <InboundActivitiesCount {...this.props}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    height={this.calculateHeight('Activities', this.props.initialLayout)}
                    reloadData={this.props.reloadData}
                    ownerDashboard={ownerDashboard}
                    refreshData={this.props.refreshData}
                    removeWidget={() => this.props.removeWidget('Activities')}
                    roleIDs={dealersettings.client.roles
                      .filter(e => e.pipeline && e.pipeline.some(m => m === 'LeadsBucket'))
                      .map(r => { return r.documentID })
                      .join(',')}
                    title={'Activities'}
                  />
              }
            </div>
          ) : (<Fragment key={'Activities'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('SalesFunnel') ? (
            <div key={'SalesFunnel'} id={'SalesFunnel'}>
              <SalesFunnel {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                height={this.calculateHeight('SalesFunnel', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('SalesFunnel')}
              />
            </div>
          ) : (<Fragment key={'SalesFunnel'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByStatus') ? (
            <div key={'LeadsByStatus'} id={'LeadsByStatus'}>
              <StatusCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('LeadsByStatus', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('LeadsByStatus')}
              />
            </div>
          ) : (<Fragment key={'LeadsByStatus'}></Fragment>)}

          {sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes('LeadsByShow') ? (
            <div key={'LeadsByShow'} id={'LeadsByShow'}>
              <ShowNoShowCounts {...this.props} handleShowEnquiryList={handleShowEnquiryList}
                pipelineEnquiries={inboundleadEnquiries}
                selectedPipeline={selectedPipeline}
                selectedUser={selectedUser}
                loader={loader}
                dateRange={dateRange}
                applydateRange={applydateRange}
                height={this.calculateHeight('LeadsByShow', this.props.initialLayout)}
                removeWidget={() => this.props.removeWidget('LeadsByShow')}
              />
            </div>
          ) : (<Fragment key={'LeadsByShow'}></Fragment>)}
        </ResponsiveReactGridLayout>
      </div>

    );
  }
}

DragDropLayout.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: function () { },
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }
};

