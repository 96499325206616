
export const initialLayoutArr = [

    { x: 0, y: 0, w: 4, h: 11, i: 'LeadsByAppointmentType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 0, w: 4, h: 11, i: 'JobCounts', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 8, y: 0, w: 4, h: 11, i: 'LeadsByStatus', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'LeadsByServiceType', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 4, y: 11, w: 8, h: 11, i: 'RevenueCounts', minW: 8, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },
    { x: 0, y: 12, w: 4, h: 11, i: 'Activities', minW: 4, maxW: 12, minH: 11, maxH: 16, isDraggable: true, isResizable: true, resizeHandles: false, isBounded: false, },

]

export const pipelineCountVMNEW = {
    appointmentsMonth: 0,
    appointmentsToday: 0,
    cancelledJobsMonth: 0,
    cancelledJobsToday: 0,
    checkedInMonth: 0,
    checkedInToday: 0,
    completedJobsMonth: 0,
    completedJobsToday: 0,
    ownerApprovedMonth: 0,
    ownerApprovedToday: 0,
    ownerDeclinedMonth: 0,
    ownerDeclinedToday: 0,
    pendingJobsMonth: 0,
    pendingJobsToday: 0,
    workInProgressMonth: 0,
    workInProgressToday: 0,
    partsRequestedToday: 0,
    partsRequestedMonth: 0,
}

export const pipelinestatus = [
    {
        name: "Scheduled",
        value: "scheduled",
        color: "#875A5A",
        sortOrder: 1
    },
    {
        name: "Cancelled",
        value: "cancelled",
        color: "#DE252A",
        sortOrder: 2
    },
    {
        name: "CheckIn",
        value: "checkIn",
        color: "#607D8D",
        sortOrder: 3
    },
    {
        name: "Technician Requested",
        value: "technicianRequested",
        color: "#d99d8c",
        sortOrder: 4
    },
    {
        name: "Parts Requested",
        value: "partsRequested",
        color: "#16a89a",
        sortOrder: 5
    },
    {
        name: "Workshop Requested",
        value: "workshopRequested",
        color: "#9b5082",
        sortOrder: 6
    },
    {
        name: "Advisor Requested",
        value: "advisorRequested",
        color: "#6f62a5",
        sortOrder: 7
    },
    {
        name: "Owner Approved",
        value: "ownerApproved",
        color: "#64a000",
        sortOrder: 8
    },
    {
        name: "Owner Declined",
        value: "ownerDeclined",
        color: "#e25b3d",
        sortOrder: 9
    },
    {
        name: "Work In Progress",
        value: "workInProgress",
        color: "#ff9d00",
        sortOrder: 10
    },
    {
        name: "Completed",
        value: "completed",
        color: "#4CAF50",
        sortOrder: 11
    },

]