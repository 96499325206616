export const objStock = {
  documentID: null,
  clientID: null,
  enquiryID: null,
  //keywords: [],
  stockNo: '',
  make: '',
  model: '',
  modelDescription: '',
  saleType: 'New',
  type: '',
  year: '',
  regNo: '',
  vinNo: '',
  extColor: '',
  intColor: '',
  trim: '',
  miles: '',
  price: null,
  purchasePrice: null,
  location: '',
  regDate: null,
  warrantyStartDate: null,
  warrantyExp: null,
  inStockDate: null,
  stockInDate: null,
  status: '', //stockStatus
  isLoan: false,
  isTestDrive: false,
  isSale: true,
  images: [],
  testDriveDetail: null, //objTestDriveDetail
  fleetDetail: null, //objFleetDetail
  statusModifiedBy: '',
  addedBy: '',
  addedDate: null,
  modifiedBy: '',
  modifiedFrom: 'web',
  modifiedDate: null,
  isDeleted: false
};