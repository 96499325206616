import React from 'react';
import { default as _images } from "../images";
import Translate from '../constants/translate';

const Upgrade = () => {
    return (<div className="upgrade-subscription-overlay">
        <div className="spinner-loader h-100">
            <div className="upgrade-subscription-flex-wrap h-100 justify-content-center align-items-center text-primary">
                <div className="upgrade-subscription-img-wrap"><img src={_images.upgrade} width="200" alt="" /></div>
                <div className="upgrade-subscription-txt-wrap">
                    <div className="upgrade-subscription-content-head"><Translate text={'Upgrade your subscription plan'} /></div>
                    <div className="upgrade-subscription-content"><Translate text={"Your current subscription doesnt include this features"} /></div>
                    <div className="upgrade-subscription-content-btn"><button type="button" className="btn btn-primary"
                        onClick={e => {
                            e.preventDefault()
                            window.Intercom('show')
                        }}> <Translate text={'Contact Support'} /></button></div>
                </div>
            </div>
        </div>
    </div>)
}

export default Upgrade;