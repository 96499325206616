import React, { useState } from 'react';
import { Modal, Tab, Nav } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import Translate from '../../constants/translate';
import { PopUpModal } from '../../components';
import TemplateEditor from '../settings/workflow/templates/templateEditor';
import CommonHelper from '../../services/common';

const AddTemplate = props => {
	const [showEdiorModel, setShowEdiorModel] = useState(false);
	const [popHeader, setPopHeader] = useState('');
	const [template, setTemplate] = useState(null);
	const [searchText, setSearchText] = useState();
	const [activeTab, setActiveTab] = useState(
		!_.isEmpty(props.selectedTemplate)
			? {
				key: props.selectedTemplate.documentID,
				email: props.selectedTemplate,
			}
			: !_.isEmpty(props.emailTemplateList) ? {
				key: props.emailTemplateList[0].documentID,
				email: props.emailTemplateList[0],
			} : '',
	);

	const handleModelClose = () => {
		setShowEdiorModel(false);
	};

	const onSelect = key => {
		let email = props.emailTemplateList.filter(email => {
			let val = false;
			if (email.documentID === key) {
				val = true;
			}
			return val;
		})[0];

		setActiveTab({
			key,
			email,
		});
	};

	return (
		<>
			<Modal
				show={props.show}
				backdrop='static'
				backdropClassName={'overlay-modal active'}
				dialogClassName='modal-dialog fullscreenmodal-lg add-triggers-fullscreen'
			>
				<div className='modal-content modal-content-fullscreen'>
					<Modal.Header className='modal-header-fullscreen'>
						<div className='modal-title'>
							{' '}
							<Translate text={props.title} />
						</div>
						<div className='campaign-add-buttonwrap campaign-add-buttonwrap-custom'>
							<button
								type='button'
								className='close close-fullscreen float-right'
								onClick={() => {
									props.handleClose();
								}}
							>
								×
							</button>
						</div>
					</Modal.Header>
					<Modal.Body className='modal-body-fullscreen'>
						<div className='popup-wrap-fullscreen h-100'>
							<div className='container-fluid '>
								<div
									className='choose-template-wrapper'
									style={{ height: '90vh' }}
								>
									<div className='choose-template-column-wraper'>
										<div className='choose-template-columns'>
											<Tab.Container
												defaultActiveKey={activeTab.key}
												mountOnEnter={true}
												onSelect={key => onSelect(key)}
											>
												<div className='choose-template-column-left'>
													<div className='choose-template-column-title'>
														<h3>Templates</h3>
													</div>
													<div className='email-list-search'>
														<div className='input-group template-search'>
															<input type='text' className='form-control' placeholder='Search' value={searchText} onChange={e => setSearchText(e.target.value)} style={{ width: '100%' }} />
															<div className='input-group-append input-search-clear'>
																{searchText && searchText.length > 0 && (
																	<a href='#' className='input-search-clear-icon' onClick={() => setSearchText('')}>
																		<i className='ico icon-remove'></i>
																	</a>
																)}
															</div>
														</div>
													</div>
													<div className='choose-template-column-nav'>
														<Nav as='ul' bsPrefix='ul-nav'>
															{props.emailTemplateList.filter(({ title }) =>
																!_.isEmpty(searchText)
																	? (!_.isEmpty(title)
																		? title.toLowerCase().includes(searchText.toLowerCase())
																		: false)
																	: true,
															).map(template => (
																<Nav.Item as='li' bsPrefix='li-nav'>
																	<Nav.Link
																		eventKey={template.documentID}
																		bsPrefix='li-a-nav'
																	>
																		<a
																			href='#'
																			className={`column-list-left ${template.documentID === activeTab.key
																				? 'active-custom'
																				: ''
																				}`}
																		>
																			<div className='choose-template-list-head'>
																				{template.documentID ===
																					activeTab.key && (
																						<div className='choose-template-active-check'>
																							<i className='ico icon-check'> </i>
																						</div>
																					)}{' '}
																				{template.title}
																			</div>
																			<span>
																				{template.modifiedDate
																					? moment
																						.unix(
																							template.modifiedDate.seconds,
																						)
																						.format('DD/MM/YYYY hh:mm A')
																					: '--'}
																				&nbsp;
																				{CommonHelper.getUserNamebyId(
																					props.clientUsers,
																					template.addedBy,
																				)}
																			</span>
																		</a>{' '}
																	</Nav.Link>
																</Nav.Item>
															))}
														</Nav>
													</div>
												</div>
												<div className='choose-template-column-right'>
													{activeTab.email && (
														<>
															<div className='choose-template-column-title'>
																<h3>Template Preview</h3>
																<div className='choose-template-common-button'>
																	<button
																		type='button'
																		className='btn btn-primary float-right ml-2'
																		onClick={() => {
																			setTemplate(activeTab.key);
																			props.handleClose(activeTab.email);
																		}}
																	>
																		Select Template
																	</button>
																	{/* <button
																		type='button'
																		className='btn btn-default icon-font-fix float-right ml-2'
																	>
																		<i className='ico icon-rearrange'></i>Clone
																	</button> */}
																	{/* <button
																		type='button'
																		className='btn btn-default icon-font-fix float-right ml-2'
																	>
																		<i className='ico icon-edit'></i> Edit
																	</button> */}
																</div>
															</div>
															<div className='choose-template-column-preview'>
																<div className=''>
																	{/* <img
																		src='images/christmas-edm.jpg'
																		alt=''
																		width='720'
																		height='1018'
																	/> */}
																	<div
																		dangerouslySetInnerHTML={{
																			__html: activeTab.email.bodyHTML,
																		}}
																	></div>
																</div>

																{/* <div className="h-100  w-100"><div className="spinner-loader h-100"><div className="d-flex h-100 justify-content-center align-items-center text-primary" style="flex-direction: column;"><div className="no-data-img"> <img src="images/template.svg" alt="" width="60" height="60"/></div><div className="no-data-txt mt-2">   Please Select Template</div></div></div></div> */}
															</div>
														</>
													)}
												</div>
											</Tab.Container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</div>
				<PopUpModal show={showEdiorModel}>
					<TemplateEditor
						{...props}
						show={showEdiorModel}
						title={popHeader}
						handleClose={handleModelClose}
						template={template}
						dealersettings={props.dealersettings}
					/>
				</PopUpModal>
			</Modal>
		</>
	);
};

export default AddTemplate;
