/** LIBRARIES */
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment';
import _ from 'lodash'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import "react-datepicker/dist/react-datepicker.css";
/** COMPONENTS */
import {
    PopoverPanel, ReactSelect
} from '../../../components';
import PipelineSwitch from '../pipelineSwitch'
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';
import EnquiryInputs from '../quickview/enquiryInputs'
/** VIEW-MODELS */
import { enquiryStatus, stockStatus } from '../../../services/enum';
import { firestoreDB } from '../../../services/helper';
import { objEnquiry, objModules } from '../viewModel'

// const enquiryTypes = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Phone', label: 'Phone' },
//     { value: 'Email', label: 'Email' }
// ]
// const origins = [
//     { value: 'Dubizzle', label: 'Dubizzle' },
//     { value: 'Dealer Website', label: "Dealer Website" },
//     { value: 'Google', label: "Google" },
//     { value: 'Dealer Telephone Inquiry', label: "Dealer Telephone Inquiry" }
// ]
// const campaigns = [
//     { value: 'Walk In', label: 'Walk In' },
//     { value: 'Test Campaign', label: 'Test Campaign' },
//     { value: 'Service Leads', label: 'Service Leads' }
// ]
let origins = [];
let enquiryTypes = [];
let serviceTypes = [];
let leadSources = [];
let campaigns = [];
let enquiryOptions = [];
let enquiryOptionsDF = [];
let enquiryOptionsStatic = []
let financeStatuses = [];
let labels = [];
// const labels = [
//     { active: true, value: 'hot', label: <div className={`badge badge-pill badge-hot`}><Translate text={'hot'} upperCase={true} /></div> },
//     { active: true, value: 'warm', label: <div className={`badge badge-pill badge-warm`}><Translate text={'warm'} upperCase={true} /></div> },
//     { active: true, value: 'cold', label: <div className={`badge badge-pill badge-cold`}><Translate text={'cold'} upperCase={true} /></div> }
// ]
const __financestatus = [
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]
let enquirySortOptions = [
    "enquiryType", "origin", "campaign", "label", "purchaseIntention", "deliveryDate",
    "chassisNo", "regNo", "stockNo", "warrantyStartDate", "warrantyExpiry", "lostReason",
    "lostSubReason", "isFinanceRequired", "financeStatus", "depositAmount", "depositReciptNumber", "grossAmount",
    "contractNumber", "leadSource"
];
class QuickActivityTab extends Component {
    constructor(props) {
        super(props);
        //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
        this.mandatoryFields = [];
        this.unsubscribeEnquiryAT = null;
        this.state = {
            loading: false,
            stageChange: false,
            fields: Object.assign({}, objEnquiry),
            contact: {},
            enquiry: Object.assign({}, objEnquiry),
            errors: {},
            pipelines: [],
            lostReasons: [],
            lostSubReasons: [],
            defaulStages: [],
            statusSettings: [],
            allFinanceUsers: [],
            allAfterMarketUsers: [],
            activeStageIndex: 0,
            sectionLoading: false,
            pipelinePopOver: { showpopover: false, targetpopover: null }
        }
    }

    loadSettings = (enquiry) => {
        const { dealersettings, contact, clientUsers, groupUsers } = this.props;
        let setttings = dealersettings.client && dealersettings.client.settings;
        const accessToSelectAllKPIs = dealersettings?.rolePermissions?.permissions?.accessToSelectAllKPIs;

        if (enquiry && enquiry.clientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
            && !_.isEmpty(dealersettings.group.clients[enquiry.clientID]) && !_.isEmpty(dealersettings.group.clients[enquiry.clientID].settings))
            setttings = dealersettings.group.clients[enquiry.clientID].settings;

        if (setttings) {

            const pipelines = [];
            const lostReasons = [];
            const lostSubReasons = [];


            if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
                this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

            let defaultstages = null;
            let statusSettings = null;
            let stages = null;
            let _allPipelines = !_.isEmpty(setttings?.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;
            if (enquiry.pipeline) {
                let _selectedPipeline = _allPipelines.filter(e => e.value === enquiry.pipeline)[0];
                defaultstages = _selectedPipeline && _selectedPipeline.stages.filter(m => m.value !== 'InboundLead' && m.value !== 'Converted');
                statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;
                stages = !_.isEmpty(defaultstages) && Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');
            }
            
            origins = [];
            let excludeOrigins = Object.assign([], dealersettings?.rolePermissions?.excludeOrigins)
            setttings.origins && setttings.origins.forEach(doc => {
                origins.push({
                    value: doc.value,
                    active: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? false : _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: (!_.isEmpty(excludeOrigins) && excludeOrigins.includes(doc.value)) ? true : accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            enquiryTypes = [];
            setttings.enquiryTypes && setttings.enquiryTypes.forEach(doc => {
                enquiryTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            labels = [];
            setttings.enquiryLabels && setttings.enquiryLabels.forEach(doc => {
                labels.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill`} style={{
                        color: `${doc.color ? doc.color : '#f47815 '}`,
                        backgroundColor: `${doc.color ? doc.color + '1f' : '#f478151f'}`,
                        boxShadow: `inset 0 0 0 1px ${doc.color ? doc.color : '#000'},0 1px 2px rgba(38,41,44,.08)`
                    }}>{doc.name.toUpperCase()}</div>,
                });
            });

            serviceTypes = [];
            setttings.serviceTypes && setttings.serviceTypes.forEach(doc => {
                serviceTypes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            leadSources = [];
            setttings.leadSource && setttings.leadSource.forEach(doc => {
                leadSources.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            enquiryOptions = [];
            setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
                enquiryOptions.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            enquiryOptionsDF = [];
            if (setttings.enquiryOptionsDF)
                enquiryOptionsDF = setttings.enquiryOptionsDF;

            enquiryOptionsStatic = [];
            if (setttings.enquiryOptionsStatic)
                enquiryOptionsStatic = setttings.enquiryOptionsStatic;

            financeStatuses = [];
            setttings.financeStatus && setttings.financeStatus.forEach(doc => {
                financeStatuses.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
                });
            });

            campaigns = [];
            setttings.campaigns && setttings.campaigns.forEach(doc => {
                campaigns.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    isDisabled: accessToSelectAllKPIs ? false : Boolean(doc.autoSelect)
                });
            });

            _allPipelines.forEach((doc) => {
                pipelines.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setttings.lostReasons.forEach(doc => {
                lostReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                    subList: !_.isEmpty(doc.subList) ? doc.subList : []
                });
            });

            if (enquiry.lostReason && !_.isEmpty(setttings.lostReasons) && !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0])) {
                let _subList = !_.isEmpty(setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList) ?
                    setttings.lostReasons.filter(e => e.value === enquiry.lostReason)[0].subList : null;

                !_.isEmpty(_subList) && _subList.forEach(doc => {
                    lostSubReasons.push({
                        value: doc.value,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });
            }

            let optionsFM = [];
            let optionsAMM = [];
            if (!_.isEmpty(dealersettings.client.roles) && enquiry.clientID) {

                let roleFMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
                    return obj.documentID;
                })];
                let roleAMMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
                    return obj.documentID;
                })];

                let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
                let _selectedFMUsers = [];
                if (!_.isEmpty(roleFMIds))
                    _selectedFMUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleFMIds, v.roleID));

                _selectedFMUsers && _selectedFMUsers.map(rec => {
                    return optionsFM.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })

                let _selectedAMMUsers = [];
                if (!_.isEmpty(roleAMMIds))
                    _selectedAMMUsers = _.filter(allDealerUsers, (v) => _.includes(roleAMMIds, v.roleID));

                _selectedAMMUsers && _selectedAMMUsers.map(rec => {
                    return optionsAMM.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
                })
            }

            this.setState({
                pipelines: pipelines,
                defaulStages: defaultstages,
                statusSettings: statusSettings,
                lostReasons: lostReasons,
                allFinanceUsers: _.uniqBy(optionsFM, 'value'),
                allAfterMarketUsers: _.uniqBy(optionsAMM, 'value'),
                lostSubReasons: lostSubReasons,
                errors: this.props.errors,
                activeStageIndex: stages && stages.indexOf(enquiry['stage']),
                fields: Object.assign({}, enquiry),
                enquiry: Object.assign({}, enquiry),
                contact: Object.assign({}, contact),
            });
        }
        else {
            this.setState({
                fields: Object.assign({}, enquiry),
                enquiry: Object.assign({}, enquiry),
                contact: Object.assign({}, contact),
            });
        }
    }

    loadEnquiry = () => {
        //console.log('loadEnquiry')
        const { enquiry } = this.props;

        if (enquiry && enquiry.documentID) {
            this.unsubscribeEnquiryAT = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
                .doc(enquiry.documentID)
                .onSnapshot(this.onEnquiryCollectionUpdate);
        }

        this.loadSettings(enquiry);
    }

    onEnquiryCollectionUpdate = (docSnapshot) => {

        if (!docSnapshot.exists) {
            return;
        }
        const enquiry = Object.assign({}, objEnquiry);
        const _objEnquiry = Object.assign({}, docSnapshot.data());
        for (let [key, value] of Object.entries(_objEnquiry)) {
            enquiry[key] = value;
        }

        let _isEmptyPipeline = false;
        if (_.isEmpty(enquiry.pipeline)) {
            enquiry.pipeline = 'LeadsBucket';
            enquiry.isEmptyPipeline = true;
            _isEmptyPipeline = true;
        }

        enquiry.documentID = docSnapshot.id;

        this.loadSettings(enquiry);
    };

    componentDidMount() {
        if (!this.props.enquiry.documentID) {
            this.setState({
                errors: {},
                defaulStages: []
            });
        }
        else {
            this.loadEnquiry();
        }

    }

    componentWillUnmount() {
        this.unsubscribeEnquiryAT && this.unsubscribeEnquiryAT();
    }
    // componentDidUpdate() {
    //     if (this.state.loading) {
    //         this.loadEnquiry();
    //     }

    //     if (this.state.stageChange && this.props.newStage) {
    //         var indexVal = this.state.defaulStages.findIndex(x => x.value === this.props.newStage);
    //         if (indexVal >= 0) {
    //             this.handleStageChange(indexVal, this.props.newStage);
    //             this.props.handleStageUpdate();
    //         }
    //     }
    // }


    // static getDerivedStateFromProps(props, state) {
    //     if (props.enquiry && state.enquiry && JSON.stringify(props.enquiry) !== JSON.stringify(state.enquiry)) {
    //         console.log()
    //         return {
    //             loading: true,
    //             enquiry: Object.assign({}, objEnquiry),
    //             errors: {},
    //             defaulStages: []
    //         };
    //     }
    //     else if (JSON.stringify(state.errors) !== JSON.stringify(props.errors)) {
    //         return {
    //             loading: false,
    //             errors: Object.assign({}, props.errors)
    //         };
    //     }
    //     else if (!_.isEmpty(props.newStage)) {
    //         return {
    //             stageChange: true,
    //         };

    //     }
    //     else {
    //         return {
    //             loading: false,
    //             stageChange: false,
    //         };
    //     }
    //     return null;
    // }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (!nextProps.enquiry.documentID) {
    //         this.setState({
    //             fields: Object.assign({}, objEnquiry),
    //             errors: {},
    //             defaulStages: []
    //         });
    //     }
    //     else {
    //         if (JSON.stringify(this.state.errors) !== JSON.stringify(nextProps.errors))
    //             this.setState({ errors: Object.assign({}, nextProps.errors) });
    //         /*if (nextProps.dealersettings && nextProps.dealersettings.client && nextProps.dealersettings.client.settings) {

    //             const setttings = nextProps.dealersettings.client.settings;
    //             let defaultstages = dealersettings.allPipelines.filter(e => e.default)[0].stages;

    //             const stages = [];
    //             defaultstages.forEach((doc) => {
    //                 stages.push({
    //                     value: doc.value,
    //                     label: doc.name
    //                 });
    //             });
    //             this.setState({ defaulStages: stages });
    //         }*/

    //     }

    // }

    handlePipelineClose = async (data) => {
        let state = Object.assign({}, this.state.fields);
        if (!_.isEmpty(data) && data.pipeline === 'LeadsBucket' && data.stage === 'Converted') {
            this.setState({
                pipelinePopOver: { showpopover: false, targetpopover: '' }
            });
            this.props.handleLeadConvertShow(this.props.enquiry.documentID, this.props.enquiry)
            return
        }
        if (!_.isEmpty(data)) {
            //const _newStage = data.pipeline + '-' + data.stage;
            if (state.isNewEnquiry || await this.props.isValidForStageUpdate(data.pipeline, data.stage, 'PipelineClose', data)) {

                const { dealersettings } = this.props

                let _settings = state.clientID && !_.isEmpty(dealersettings) && dealersettings.group &&
                    dealersettings.group.clients && dealersettings.group.clients[state.clientID] && dealersettings.group.clients[state.clientID].settings;

                if (_.isEmpty(_settings))
                    _settings = dealersettings && dealersettings.client && dealersettings.client.settings;

                let defaultstages = null;
                let statusSettings = null;
                let stages = null;
                if (data.pipeline) {
                    let _allPipelines = !_.isEmpty(_settings?.allPipelines) ? _settings.allPipelines : dealersettings.allPipelines;
                    let _selectedPipeline = _allPipelines.filter(e => e.value === data.pipeline)[0];
                    defaultstages = _selectedPipeline && _selectedPipeline.stages.filter(m => m.value !== 'InboundLead' && m.value !== 'Converted');
                    statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;
                    stages = !_.isEmpty(defaultstages) && Array.prototype.map.call(defaultstages, function (item) { return item.value; }).join(",").split(',');
                }

                if (state.stage !== data.stage || state.pipeline !== data.pipeline) {
                    if (data.pipeline === 'LeadsBucket') {
                        //state.status = null;
                        state.isConverted = false;
                        state.unactionedNotify = '';
                        state.convertedBy = null;
                        state.convertedDate = null;
                    }

                    let stageHistory = Object.assign([], state.stageHistory);

                    let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
                    if (!_.isEmpty(_stageData)) {
                        stageHistory.push(_stageData);
                        state.stageHistory = Object.assign([], stageHistory);
                        state.pipeline = data.pipeline;
                        state.stage = data.stage;
                        state.stageDate = window.firebase.firestore.Timestamp.now();
                    }
                }

                state.owner = data.owner ? data.owner : state.owner;
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiry.documentID);
                updateRef.update({
                    pipeline: state.pipeline,
                    stage: state.stage,
                    owner: state.owner,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    isConverted: state.isConverted,
                    unactionedNotify: state.unactionedNotify ? state.unactionedNotify : null,
                    convertedBy: localStorage.uid,
                    convertedDate: state.convertedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({
                        fields: state,
                        defaulStages: defaultstages,
                        statusSettings: statusSettings,
                        activeStageIndex: stages && stages.indexOf(data.stage)
                    }, () => { this.errorChange(data.name); });

                    if (this.props.isFilterApplied) {
                        if (this.props.updateEnquiry) {
                            this.props.updateEnquiry({
                                ...state,
                                id: state.documentID
                            }, true);
                        }
                        if (this.props.updateListEnquiry) {
                            this.props.updateListEnquiry(state);
                        }
                    }

                    //this.props.saveEnquiryInfo(this.state.fields);

                    toast.notify('Enquiry updated successfully.', {
                        duration: 2000,
                        position: 'top-right'
                    })
                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }
        this.setState({
            pipelinePopOver: { showpopover: false, targetpopover: '' }
        });

    }

    handleStageChange = async (id, name) => {
        //e.preventDefault();
        //const { id, name } = e.target;
        const { pipeline, status } = this.state.fields;

        if (pipeline === 'LeadsBucket' && name === 'Converted') {
            this.props.handleLeadConvertShow(this.props.enquiry.documentID, this.props.enquiry)
            return
        }

        const { statusSettings } = this.state;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.pipeline === pipeline && e.stage === name)[0];
        if (_statusMndry && !_statusMndry.isModule && status !== _statusMndry.status) {
            let _status = CommonHelper.getNameByValue(this.props.enquiryAllStatus, _statusMndry.status)

            Swal.fire({
                title: CommonHelper.showLocale(this.props, 'Are you sure?'),
                text: `${CommonHelper.showLocale(this.props, 'You want to change enquiry status to')} ${_status.toUpperCase()}`,
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.value) {
                    this.props.handleStatusOptions(_statusMndry.status);
                }
            });
            return;
        }

        //const _newStage = pipeline + '-' + name;
        if (await this.props.isValidForStageUpdate(pipeline, name, 'stage')) {

            this.setState({
                activeStageIndex: id
            })
            let state = Object.assign({}, this.state.fields);

            let stageHistory = Object.assign([], state.stageHistory);
            let _stageData = CommonHelper.handleStageHistory(stageHistory, state.pipeline, name);
            if (!_.isEmpty(_stageData)) {
                stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], stageHistory);
                state.stage = name;
                state.stageDate = window.firebase.firestore.Timestamp.now();
                state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
                state.modifiedDate = window.firebase.firestore.Timestamp.now();

                const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiry.documentID);
                updateRef.update({
                    stage: state.stage,
                    stageDate: state.stageDate,
                    stageHistory: state.stageHistory,
                    modifiedBy: state.modifiedBy,
                    modifiedDate: state.modifiedDate,
                    modifiedFrom: 'web'
                }).then((docRef) => {
                    this.setState({ fields: state });

                    if (this.props.isFilterApplied) {
                        if (this.props.updateEnquiry) {
                            this.props.updateEnquiry({
                                ...state,
                                id: state.documentID
                            }, true);
                        }
                        if (this.props.updateListEnquiry) {
                            this.props.updateListEnquiry(state);
                        }
                    }

                    //this.props.saveEnquiryInfo(this.state.fields);

                    // toast.notify('Enquiry updated successfully...', {
                    //     duration: 2000,
                    //     position: 'top-right'
                    // })
                }).catch((error) => {
                    console.error("Error updating enquiries: ", error);
                });
            }
        }
    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        if (!_.isEmpty(errors)) {
            if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = value;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }
    handleOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = _.trim(value.toUpperCase());
        else
            fields[name] = null;
        this.setState({ fields: fields }, () => { this.errorChange(name); });
    }

    onCurrencyChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856} 
        let state = Object.assign({}, this.state.fields);
        state[name] = e.floatValue;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleCheckChange = (e) => {
        const { name, checked } = e.target;
        this.saveEnquiryField(name, checked);
    }

    handleOptionsChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _reqOtherInfo = state.options ? state.options : [];
        if (checked)
            _reqOtherInfo.push(name)
        else
            _reqOtherInfo = _reqOtherInfo.filter(m => m !== name);
        this.saveEnquiryField('options', _reqOtherInfo);
    }

    cancelEnquiryField = (name, val) => {
        let state = Object.assign({}, this.state.fields);
        state[name] = val;
        this.setState({ fields: state }, () => { this.errorChange(name); });

    }

    saveEnquiryField = (name, val) => {

        if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
            this.errorChange(name);
        }
        else {
            let objData = {};
            let state = Object.assign({}, this.state.fields);
            const { dealersettings, handleFinanceInfoOpen } = this.props;
            // if (name === 'financeBM' && !state.isFinanceRequired) {
            //     objData.isFinanceRequired = true;
            //     if (val) {
            //         const { documentID, clientID, displayID, contact } = this.state.fields;
            //         let _reqObject = {
            //             "userID": val,
            //             "notification": {
            //                 title: 'Finance Enquiry',
            //                 body: displayID + (contact ? ' - ' + CommonHelper.displayContactName([], contact) : ''),
            //                 type: 'finance',
            //                 recordID: documentID,
            //                 clientID: clientID
            //             }
            //         }
            //         const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
            //         genericNotify(_reqObject)
            //     }
            // }

            // if (name === 'afterMarketBM' && val) {
            //     if (val) {
            //         const { documentID, clientID, displayID, contact } = this.state.fields;
            //         let _reqObject = {
            //             "userID": val,
            //             "notification": {
            //                 title:  'After Market Enquiry',
            //                 body: displayID + (contact ? ' - ' + CommonHelper.displayContactName([], contact) : ''),
            //                 type: 'afterMarket',
            //                 recordID: documentID,
            //                 clientID: clientID
            //             }
            //         }
            //         const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
            //         genericNotify(_reqObject)
            //     }
            // }

            state[name] = val;
            objData[name] = val;
            state.modifiedBy = (dealersettings?.id || '');
            state.modifiedDate = window.firebase.firestore.Timestamp.now();

            objData.modifiedBy = state.modifiedBy;
            objData.modifiedDate = state.modifiedDate;
            objData.modifiedFrom = 'web';

            this.setState({ fields: state });
            const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiry.documentID);
            updateRef.set(objData, { merge: true }).then((docRef) => {
                //this.props.saveEnquiryInfo(this.state.fields);

                if (name === 'isFinanceRequired' && val && dealersettings?.rolePermissions?.permissions?.accessToFinanceManager === true && handleFinanceInfoOpen) {
                    handleFinanceInfoOpen();
                }

                if (name === 'campaign' || name === 'origin') {
                    this.saveContactOriginAndCampaign(state);
                }

                if (name === 'status') {
                    if (state.contactID && (val === enquiryStatus.WON || val === enquiryStatus.OPEN)) {
                        const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
                        updateRepeatCustomer({ 'contactID': state.contactID, 'projectId': state?.projectId ? state.projectId : null })
                    }
                    toast.notify('Enquiry updated successfully.', {
                        duration: 2000,
                        position: 'top-right'
                    })
                }

                if (this.props.isFilterApplied) {
                    if (this.props.updateEnquiry) {
                        this.props.updateEnquiry({
                            ...state,
                            id: state.documentID
                        }, true);
                    }
                    if (this.props.updateListEnquiry) {
                        this.props.updateListEnquiry(state);
                    }
                }

            }).catch((error) => {
                console.error("Error updating enquiries: ", error);

            });
        }
    }

    saveContactOriginAndCampaign = (objData) => {
        const { fields, contact } = this.state;
        const { campaign, origin } = objData;

        let _contact = !_.isEmpty(contact) ? contact : fields.contact;
        let _objData = {};

        if (campaign && _contact && !_contact.campaign) _objData.campaign = campaign;
        if (origin && _contact && !_contact.origin) _objData.origin = origin;

        let _contactID = fields.contactID ? fields.contactID : (fields.contact ? fields.contact.documentID : (!_.isEmpty(contact) ? contact : contact.documentID));

        CommonHelper.saveContactOriginAndCampaign(_contactID, _objData.campaign, _objData.origin, this.props);
    }

    handleDeliveryDateChange = (val, name) => {
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        this.saveEnquiryField(name, val);

        if (name === 'deliveryDate') {
            const { requirements, contact } = this.props;
            if (contact.documentID && !_.isEmpty(requirements)) {
                requirements.filter(e => e.isSold === true).forEach(_requirement => {
                    if (!_.isEmpty(_requirement.stock))
                        this.handleChangeStockStatus(_requirement.stock.documentID, null, val);
                });
            }
        }
        //setTimeout(() => this.errorChange(name), 500);
    };

    handleTimeChange = (val, name) => {
        //console.log(val)
        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(val)._d)

        this.saveEnquiryField(name, val);
    };

    handleDateChange = (val, name) => {
        if (val) val = moment(val).format('YYYY-MM-DD');

        this.saveEnquiryField(name, val);
    };

    handlePIDateSave = (val, name) => {
        if (val)
            val = moment(val).format('MMM YYYY');

        this.saveEnquiryField(name, val);
    };

    handleSelectSave = (e, data) => {
        if (data.name === 'financeStatus') {
            this.handleFinanceStatus((e?.value || ''))
        }
        else {
            this.saveEnquiryField(data.name, (e?.value || ''));
        }
    }

    handleFinanceStatus = async (_status) => {
        if (_status && await this.props.isValidForFinanceStatusUpdate(_status)) {
            this.saveEnquiryField('financeStatus', _status);
        }
        else if (_.isEmpty(_status)) {
            this.saveEnquiryField('financeStatus', null);
        }
    }

    handleLostReasonSave = (e, data) => {
        const { lostSubReason } = this.state.fields;
        const { lostReasons } = this.state;
        const lostSubReasons = [];
        if (e?.value && !_.isEmpty(lostReasons.filter(m => m.value === e.value)[0].subList)) {
            lostReasons.filter(m => m.value === e.value)[0].subList.forEach(doc => {
                lostSubReasons.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });
        }
        this.setState({ lostSubReasons: lostSubReasons });
        this.saveEnquiryField(data.name, (e?.value || ''));
        if (lostSubReason)
            this.saveEnquiryField('lostSubReason', null);

        //setTimeout(() => this.errorChange(data.name), 500);
    };

    isPIValid = (date) => {
        let _date = moment(date, "MMM YYYY")
        if (moment().isBefore(_date) || moment().isSame(_date, 'month'))
            return ''
        else
            return 'form-alert '
    }

    handleSittingDays = _stage => {
        const { stageHistory, pipeline } = this.state.fields;
        //console.log('handleSittingDays', _stage, stageHistory)
        let diff = '0 day';
        if (
            !_.isEmpty(stageHistory) &&
            stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]
        ) {
            let _stageDays = stageHistory.filter(
                e => e.stage === _stage && e.pipeline === pipeline,
            )[0];

            if (_stageDays) {
                const date = moment.unix(_stageDays.date.seconds); //.fromNow()
                diff = moment().startOf('day').diff(date.startOf('day'), 'days');
                diff =
                    diff +
                    (_stageDays.sittingDays ? parseInt(_stageDays.sittingDays) : 0);

                if (diff === 1) {
                    diff = diff + 'day';
                } else if (diff > 0) {
                    diff = diff + 'days';
                }
            }
        }

        return diff;
    };

    handleStageComplete = _stage => {
        const { stageHistory, pipeline } = this.state.fields;
        //console.log('handleStageComplete', _stage, stageHistory)
        let response = 'active-disable';
        if (!_.isEmpty(stageHistory) &&
            stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0]) {
            let _stageDays = stageHistory.filter(e => e.stage === _stage && e.pipeline === pipeline)[0];
            if (!_.isEmpty(_stageDays)) {
                response = 'active';
            }
        }
        return response;
    };

    handleChangeStockStatus = (stockID, status, deliveryDate) => {
        let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();
        const { fields } = this.state;

        let objData = {
            modifiedBy: modifiedBy,
            modifiedDate: modifiedDate,
            modifiedFrom: 'web'
        }

        if (status) {
            objData.status = status;
            objData.statusModifiedBy = modifiedBy;
            objData.statusModifiedFrom = 'web';
        }
        if (deliveryDate) {
            objData.deliveryDate = deliveryDate;
        }

        if (status === stockStatus.SOLD || status === stockStatus.DELIVERED) {
            objData.enquiryID = this.state.enquiryID ? this.state.enquiryID : null;

            if (status === stockStatus.SOLD)
                objData.soldDate = modifiedDate;

            if (status === stockStatus.DELIVERED) {
                objData.deliveredDate = modifiedDate;
                objData.warrantyStartDate = modifiedDate;
            }

            if (fields.deliveryDate && !deliveryDate)
                objData.deliveryDate = fields.deliveryDate;
        }

        if (status && status !== stockStatus.SOLD && status !== stockStatus.DELIVERED) {
            objData.soldDate = null;
            objData.deliveredDate = null;
        }

        const refStockData = window.firebase.firestore().collection('stock').doc(stockID);
        refStockData.set(objData, { merge: true }).then((docRef) => {
            //console.log(docRef);
        }).catch((error) => {
            console.error("Error updating handleChangeStockStatus: ", error);
        });
    }
    //#region DYNAMIC FIELDS

    handleDynamicCheckChange = (e) => {
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = checked;
        this.saveEnquiryField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    }

    handleDynamicOnChange = (e) => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    handleDynamicOnCapChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        if (value)
            _dynamicFields[name] = _.trim(value.toUpperCase());
        else
            _dynamicFields[name] = null;

        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }

    onDynamicNumberChange = (name, e) => {
        //{formattedValue: "$9,856", value: "9856", floatValue: 9856}

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);

        _dynamicFields[name] = e.floatValue;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name) });
    }

    handleDynamicSelectSave = (e, data) => {

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[data.name] = (e?.value || '');
        this.saveEnquiryField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(data.name), 500);
    };

    handleDynamicMultiSelectChange = (selectedOptions, _data) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[_data.name] = value;
        this.saveEnquiryField('dynamicFields', _dynamicFields);
        this.setState({ fields: state });
    }

    handleDynamicDateChange = (val, name) => {

        if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = val;

        this.saveEnquiryField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    };

    saveDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        this.saveEnquiryField('dynamicFields', _dynamicFields);
        setTimeout(() => this.errorChange(name), 500);
    }

    cancelDynamicField = (name, value) => {
        let state = Object.assign({}, this.state.fields);
        let _dynamicFields = Object.assign({}, state.dynamicFields);
        _dynamicFields[name] = value;
        state.dynamicFields = _dynamicFields;
        this.setState({ fields: state }, () => { this.errorChange(name); });
    }
    //#endregion


    render() {

        const { pipeline, stage, status, enquiryType, clientID,
            label, purchaseIntention, contractNumber, isAfterSales,
            depositAmount, depositReciptNumber, grossAmount, origin, campaign,
            lostReason, owner, financeBM, afterMarketBM, stageHistory,
            lostSubReason, isNewEnquiry,
            deliveryDate, lostDate, wonDate, deliveredDate,
            chassisNo, regNo, stockNo, warrantyExpiry, warrantyStartDate,
            isFinanceRequired, financeStatus, options, dynamicFields,
            verifiedBy, verifiedDate, receivedDate, modifiedFrom, addedFrom,
            modifiedBy, modifiedDate, addedBy, addedDate,
            convertedBy, convertedDate,
            oemConvertedBy, oemConvertedDate,
            ownerInbound, tags,
            //convertedByGroup, convertedByOEM, convertedByRegion,
            //convertedDateGroup, convertedDateOEM, convertedDateRegion
        } = this.state.fields;

        const { lostReasons, lostSubReasons, allFinanceUsers, allAfterMarketUsers } = this.state;

        const { dealersettings, canModifyEnquiry, groupUsers, clientUsers, isEmptyPipeline, enquiryAllStatus, accessToFinanceTab, accessToAfterMarketTab } = this.props;

        const _hasNoAccess = (pipeline !== 'LeadsBucket' && dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.pipelines.length > 0 &&
            dealersettings.client.settings.pipelines.filter(item => item.value !== 'LeadsBucket').length === 0) ? true : false;

        const isDeivered = _hasNoAccess ? true : ((status === enquiryStatus.DELIEVERD || status === enquiryStatus.LOST || status === enquiryStatus.PROSPECTLOST || status === enquiryStatus.PENDINGLOST) ? true : false);

        const _permissions = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.rolePermissions) &&
            !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

        const _client = clientID && !_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.group) &&
            !_.isEmpty(dealersettings.group.clients) &&
            !_.isEmpty(dealersettings.group.clients[clientID]) ? dealersettings.group.clients[clientID] : dealersettings.client;

        const _moduleSettings = ((_client &&
            !_.isEmpty(_client.moduleSettings)) ? _client.moduleSettings : null);

        const movePipeline = ((!_.isEmpty(_permissions) && _permissions.movePipeline) ? true : false);

        const modifyStageAfterDeliveredLost = ((!_.isEmpty(_permissions) && _permissions.modifyStageAfterDeliveredLost) ? true : false);

        const modifyStatusDate = ((!_.isEmpty(_permissions) && _permissions.modifyStatusDate) ? true : false);
        const modifyEnquiryOwner = ((!_.isEmpty(_permissions) && _permissions.modifyEnquiryOwner) ? true : false);

        let _allPipelines = !_.isEmpty(_client?.settings?.allPipelines) ? _client.settings.allPipelines : dealersettings.allPipelines;
        let _modules = pipeline && !_.isEmpty(_allPipelines.filter(e => e.value === pipeline)[0]) ? _allPipelines.filter(e => e.value === pipeline)[0].modules : objModules;
        let modules = !_.isEmpty(_modules) ? _modules : objModules;

        const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
            !_.isEmpty(_moduleSettings.finance) &&
            _moduleSettings.finance.enabled) ? (!_.isEmpty(modules) && modules.finance ? true : false) : false);

        const currencySymbol = ((_client &&
            !_.isEmpty(_client.currency)) ? _client.currency.symbol + ' ' : '$ ');

        let _canModifyEnquiry = _hasNoAccess ? false : canModifyEnquiry;

        let dynamicEnquiryDetailList = (!_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.enquiryOptions)) ?
            dealersettings.client.enquiryOptions :
            Object.assign([], _.uniq(_.union(enquirySortOptions, Object.assign([], (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(enquiryOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));

        dynamicEnquiryDetailList = isAfterSales ? _.uniq(_.union(['serviceType'], dynamicEnquiryDetailList)) : dynamicEnquiryDetailList;

        const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)
        let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        const ismodifyStatusDate = (modifyStatusDate && (isStatusSold || status === enquiryStatus.LOST || status === enquiryStatus.PROSPECTLOST || status === enquiryStatus.WON || status === enquiryStatus.DELIEVERD))
        const isModifyInboundOwner = (!_.isEmpty(ownerInbound) && pipeline !== 'LeadsBucket' && modifyEnquiryOwner)

        const drivaEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
        ) ? (!_.isEmpty(modules) && modules.finance ? true : false) : false);

        return (
            <>
                <div className="quickview-activity-item pb-5">
                    <div className="row">
                        <div className={`col-md-12 form-style ${_canModifyEnquiry ? '' : 'form-disable'}`}>
                            <div className="form-row">
                                {
                                    (financeBM || accessToFinanceTab) && !_.isEmpty(allFinanceUsers)
                                        ?
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'financeBM'} /></label>
                                            <ReactSelect
                                                options={allFinanceUsers}
                                                name={"financeBM"}
                                                placeholder={'select business manager'}
                                                onChange={this.handleSelectSave}
                                                value={financeBM}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                isSearchable={true}
                                                isDisabled={accessToFinanceTab ? false : true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    (afterMarketBM || accessToAfterMarketTab) && !_.isEmpty(allAfterMarketUsers)
                                        ?
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'afterMarketBM'} /></label>
                                            <ReactSelect
                                                options={allAfterMarketUsers}
                                                name={"afterMarketBM"}
                                                placeholder={'select aftermarket manager'}
                                                onChange={this.handleSelectSave}
                                                value={afterMarketBM}
                                                classNamePrefix={`cursor-pointer basic-select`}
                                                //removeClearable={true}
                                                isSearchable={true}
                                                isDisabled={accessToAfterMarketTab ? false : true}
                                            >
                                            </ReactSelect>
                                        </div>
                                        :
                                        <></>
                                }


                                {
                                    dynamicEnquiryDetailList && dynamicEnquiryDetailList.map((info, index) => (
                                        <EnquiryInputs
                                            key={index}
                                            type={info}
                                            fields={this.state.fields}
                                            errors={this.state.errors}
                                            currencySymbol={currencySymbol}
                                            dealersettings={this.props.dealersettings}
                                            canModifyEnquiry={_canModifyEnquiry}
                                            enquiryTypes={enquiryTypes}
                                            enquiryAllStatus={enquiryAllStatus}
                                            serviceTypes={serviceTypes}
                                            leadSources={leadSources}
                                            origins={origins}
                                            campaigns={campaigns}
                                            labels={labels}
                                            lostReasons={lostReasons}
                                            lostSubReasons={lostSubReasons}
                                            financeStatuses={financeStatuses}
                                            __financestatus={__financestatus}
                                            enquiryOptionsDF={enquiryOptionsDF}
                                            enquiryOptionsStatic={enquiryOptionsStatic}
                                            financeEnabled={financeEnabled}
                                            drivaEnabled={drivaEnabled}
                                            handleSelectSave={this.handleSelectSave}
                                            handlePIDateSave={this.handlePIDateSave}
                                            isPIValid={this.isPIValid}
                                            handleDeliveryDateChange={this.handleDeliveryDateChange}
                                            handleTimeChange={this.handleTimeChange}
                                            handleOnCapChange={this.handleOnCapChange}
                                            saveEnquiryField={this.saveEnquiryField}
                                            cancelEnquiryField={this.cancelEnquiryField}
                                            handleDateChange={this.handleDateChange}
                                            handleLostReasonSave={this.handleLostReasonSave}
                                            handleCheckChange={this.handleCheckChange}
                                            onCurrencyChange={this.onCurrencyChange}
                                            handleOnChange={this.handleOnChange}
                                            handleDynamicCheckChange={this.handleDynamicCheckChange}
                                            handleDynamicOnChange={this.handleDynamicOnChange}
                                            saveDynamicField={this.saveDynamicField}
                                            cancelDynamicField={this.cancelDynamicField}
                                            onDynamicNumberChange={this.onDynamicNumberChange}
                                            handleDynamicDateChange={this.handleDynamicDateChange}
                                            handleDynamicSelectSave={this.handleDynamicSelectSave}
                                            handleDynamicMultiSelectChange={this.handleDynamicMultiSelectChange}

                                        ></EnquiryInputs>
                                    ))

                                }

                                {
                                    isEmptyPipeline === true
                                        ?
                                        <></>
                                        :
                                        <>
                                            <div className={`form-group col-md-6 ${(movePipeline && _canModifyEnquiry) ? '' : 'form-disable'}`}>
                                                <label><Translate text={'pipeline'} /></label>
                                                <div className="form-control cursor-pointer"
                                                    title={CommonHelper.showPSAge(stageHistory, pipeline, null, 'pipeline')}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!isAfterSales && pipeline !== 'LeadsBucket' && movePipeline && _canModifyEnquiry) {
                                                            this.setState({
                                                                pipelinePopOver: { showpopover: true, targetpopover: e.target }
                                                            });
                                                        }
                                                    }}>
                                                    {
                                                        _allPipelines && _allPipelines.find(e => e.value === pipeline)
                                                            ?
                                                            _allPipelines.find(e => e.value === pipeline).name
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>

                                            <div className="form-group col-md-12">
                                                <label><Translate text={'stage'} /></label>
                                                <div className={`${this.state.errors["stage"] ? 'custom_error' : ''}`} id="stage">
                                                    <ul className="pipeline-step">
                                                        {
                                                            this.state.defaulStages && this.state.defaulStages.map((_stage, index) => {
                                                                let _sittingDays = this.state.activeStageIndex >= index ? CommonHelper.handleSittingDays(stageHistory, pipeline, _stage) : '';
                                                                return (
                                                                    <OverlayTrigger
                                                                        key={index}
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip><>{_stage.name} {_sittingDays ? <><br />{_sittingDays}</> : ''}</></Tooltip>
                                                                        }
                                                                    >
                                                                        <li
                                                                            key={index}
                                                                            name={_stage}
                                                                            className={this.state.activeStageIndex >= index ? (this.handleStageComplete(_stage.value)) : ''}
                                                                        >
                                                                            <a
                                                                                href="#"
                                                                                className={`stage-span ${(_canModifyEnquiry && (!isDeivered || (isDeivered && modifyStageAfterDeliveredLost))) ? '' : 'form-disable'}`}
                                                                                id={index}
                                                                                name={_stage.value}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (_canModifyEnquiry && (!isDeivered || (isDeivered && modifyStageAfterDeliveredLost)))
                                                                                        this.handleStageChange(index, _stage.value)
                                                                                }}
                                                                            >
                                                                                {/* {_stage.name} */}
                                                                                {<>&nbsp;</>}
                                                                            </a>
                                                                        </li>
                                                                    </OverlayTrigger>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                }




                            </div>

                            <div className="enquiry-detailed-info">

                                {
                                    ismodifyStatusDate || isModifyInboundOwner
                                        ?
                                        <>
                                            <div className="edit-enquiry-detailes-btn">
                                                <a href="#" className="mini-button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.props.handleStatusDateOpen(ismodifyStatusDate, isModifyInboundOwner);
                                                    }}>
                                                    <i className="ico icon-edit"></i>
                                                </a>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                <ul>
                                    <li> <span><Translate text={'modifiedBy'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), modifiedBy)}</li>
                                    <li> <span><Translate text={'modifiedDate'} />:</span> {modifiedDate ? moment.unix(modifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                    {
                                        !_.isEmpty(modifiedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'modifiedFrom'} />:</span> {modifiedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (status === enquiryStatus.LOST || status === enquiryStatus.PROSPECTLOST) && !_.isEmpty(lostDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'lost', 'Lost') + ' Date'} />:</span> {lostDate ? moment.unix(lostDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (isStatusSold || status === enquiryStatus.WON || status === enquiryStatus.CLOSED || status === enquiryStatus.DELIEVERD) && !_.isEmpty(wonDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={isAfterSales ? 'closeWonDate' : (CommonHelper.getNameByValue(enquiryAllStatus, 'won', 'Won') + ' Date')} />:</span> {wonDate ? moment.unix(wonDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (status === enquiryStatus.DELIEVERD) && !_.isEmpty(deliveredDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'delivered', 'Delivered') + ' Date'} />:</span> {deliveredDate ? moment.unix(deliveredDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        verifiedBy && !_.isEmpty(verifiedDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'verifiedBy'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), verifiedBy)}</li>
                                                <li> <span><Translate text={'verifiedDate'} />:</span> {verifiedDate ? moment.unix(verifiedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        oemConvertedBy && !_.isEmpty(oemConvertedDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'oemConvertedBy'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), oemConvertedBy)}</li>
                                                <li> <span><Translate text={'oemConvertedDate'} />:</span> {oemConvertedDate ? moment.unix(oemConvertedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {/* {
                                        convertedByRegion && !_.isEmpty(convertedDateRegion)
                                            ?
                                            <>
                                                <li> <span><Translate text={'convertedByRegion'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), convertedByRegion)}</li>
                                                <li> <span><Translate text={'convertedDateRegion'} />:</span> {convertedDateRegion ? moment.unix(convertedDateRegion.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        convertedByGroup && !_.isEmpty(convertedDateGroup)
                                            ?
                                            <>
                                                <li> <span><Translate text={'convertedByGroup'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), convertedByGroup)}</li>
                                                <li> <span><Translate text={'convertedDateGroup'} />:</span> {convertedDateGroup ? moment.unix(convertedDateGroup.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    } */}
                                    {
                                        !_.isEmpty(receivedDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'receivedDate'} />:</span> {receivedDate ? moment.unix(receivedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }

                                    {
                                        !_.isEmpty(convertedBy)
                                            ?
                                            <>
                                                <li> <span><Translate text={'convertedBy'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), convertedBy)}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        !_.isEmpty(convertedDate)
                                            ?
                                            <>
                                                <li> <span><Translate text={'convertedDate'} />:</span> {convertedDate ? moment.unix(convertedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        (!_.isEmpty(ownerInbound) && pipeline !== 'LeadsBucket')
                                            ?
                                            <li> <span><Translate text={'ownerInbound'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), ownerInbound)}</li>
                                            :
                                            <></>
                                    }

                                    <li> <span><Translate text={'addedBy'} />:</span> {CommonHelper.getUserNamebyId((allDealerUsers), addedBy)}</li>
                                    <li> <span><Translate text={'addedDate'} />:</span> {addedDate ? moment.unix(addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'}</li>
                                    {
                                        !_.isEmpty(addedFrom)
                                            ?
                                            <>
                                                <li> <span><Translate text={'addedFrom'} />:</span> {addedFrom}</li>
                                            </>
                                            :
                                            <></>
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <PopoverPanel
                    showpopover={this.state.pipelinePopOver.showpopover}
                    targetpopover={this.state.pipelinePopOver.targetpopover}
                    title='Pipeline'
                    position="top"
                    id="pipeline-switch"
                    className="popover-pipeline-switch"
                    closepopover={() => {
                        this.setState({
                            pipelinePopOver: { showpopover: false, targetpopover: '' }
                        });
                    }}>
                    <PipelineSwitch
                        pipeline={pipeline}
                        stage={stage}
                        clientID={clientID}
                        dealersettings={this.props.dealersettings}
                        handleClose={this.handlePipelineClose}
                        clientUsers={this.props.clientUsers}
                        groupUsers={this.props.groupUsers}
                        owner={owner}
                        status={status}
                        isNewEnquiry={isNewEnquiry}
                    />
                </PopoverPanel>

            </>
        );
    }
}
export default QuickActivityTab;