/** LIBRARIES */
import React, {PureComponent } from "react";
import AsyncSelect from 'react-select/async';


class ReactSelectAsyncSearch extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        //this.handleCancel = this.handleCancel.bind(this);
    }

    filterColors = inputValue => {
        return this.props.options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

  
 
    promiseOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        } else {

            callback(this.filterColors(inputValue));
            this.setState({ loading: false });

        }
    };
   

    render() {

        const singleStyles = {
            control: (base, state) => ({
                ...base,
                minHeight: "33px"
            }),
            dropdownIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            clearIndicator: base => ({
                ...base,
                paddingTop: 0,
                paddingBottom: 0
            }),
            option: (base, { data, isCreatable }) => ({
                ...base,
                // color: data.label.indexOf("Create") >= 0 ? "#4466f2" : "",
                // cursor: data.label.indexOf("Create") >= 0 ? "pointer" : "",
                // borderTop: data.label.indexOf("Create") >= 0 ? "1px solid #d5dee5" : "",
            })
        };

        const value = [];

        if (this.props.value) {
            var index = this.props.options.findIndex(x => x.value === this.props.value);
            value.push(this.props.options[index])
        }

        const { loading } = this.state;
        return (
            <AsyncSelect
                isClearable
                isLoading={loading}
                placeholder={this.props.placeholder}
                className={`basic-single`}
                classNamePrefix={this.props.classNamePrefix}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null
                }}
                styles={singleStyles}
                name={this.props.name}
                onChange={this.props.onChange}
                defaultValue={value}
                value={value} 
                loadOptions={this.promiseOptions} 
            />
        );
    }
}


export default ReactSelectAsyncSearch;