import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import moment from 'moment'
import ReactExport from 'react-export-excel-xlsx-fix';
import _ from 'lodash'
import { allEventsProFields } from '../eventsPro/viewModel'
import CommonHelper from '../../services/common';
import { TableView, PopUpModal } from '../../components'
import toast from 'toasted-notes'
import _images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import { firestoreDB } from '../../services/helper';
import Translate from '../../constants/translate';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const EventsList = (props) => {
    const csvBtn = useRef();
    const csvDirectBtn = useRef();
    const [events, setEvents] = useState([])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 190), windowHeight: (window.innerHeight - 190) })
    const [eventsproFields, setEventsProFields] = useState(props.dealersettings.eventsProFields)
    const [searchText, setSearchText] = useState('')
    const [csvHeader, setHeader] = useState([])
    const [showReAuthentication, setReAuthentication] = useState(false);
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [loader, setLoader] = useState();

    const fetchDirectExcel = () => {
        // console.log('fetchDirectExcel');
        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
    }

    useEffect(() => {
        if (_.isEmpty(props.events))
            return

        setEvents(props.events.map(rec => {
            return {
                ...CommonHelper.convertEventsProVM(rec, props)
            }
        }))

    }, [props.events])

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                if (props.isFromDashboard) {
                    setTimeout(() => { csvDirectBtn.current && csvDirectBtn.current.handleDownload && csvDirectBtn.current.handleDownload(); }, 1000)
                    Swal.fire({
                        icon: 'success',
                        title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                        showConfirmButton: false,
                        timer: 1500
                    })
                    toast.closeAll();
                }
                else
                    fetchDirectExcel();

            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion

    useEffect(() => {
        let headerFields = eventsproFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allEventsProFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allEventsProFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allEventsProFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: !_.isEmpty(rec.elementsubName) ? rec.elementsubName : rec.subText })
            }
        })
        setHeader(_headers);

    }, [eventsproFields])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 190),
                windowHeight: (window.innerHeight - 190)
            })
            document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        }
        window.addEventListener('resize', handleResize);
        document.documentElement.style.setProperty('--dashboardpopWidth', ((window.innerWidth - 190) + 40) + 'px');
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleSavesettings = (fields, showToast) => {
        if (!showToast)
            setLoader(true)
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ eventsProFields: fields }, { merge: true })
            .then(() => {
                setEventsProFields(fields)
                setSettingsLoader(false)
                setLoader(false)
                props.updateDealerSettings('eventsProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                setLoader(true)
                console.error(error)
            });

    }

    const handleActionClick = (id, data) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" data-id={id} onClick={(e) => {

            props.history.push("/eventspro/details/" + id);


        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id, data) => {

        props.history.push("/eventspro/details/" + id);


    }


    const _permissions = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.eventsProPermissions)) ? props.dealersettings.rolePermissions.eventsProPermissions : null);


    const exportData = ((!_.isEmpty(_permissions) && _permissions.exportData) ? true : false);

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                enforceFocus={false}
                dialogClassName="modal-dialog-centered modal-dashboard-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={props.title} /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="settings-head">
                                <div className="float-left">

                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                    {/* <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div> */}
                                </div>
                                <div className="float-right">

                                    {
                                        (exportData)
                                            ?
                                            <>
                                                {!props.isFromDashboard ? (
                                                    <div className="filter-item-btn">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchDirectExcel();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvDirectBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`events_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(events, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                ) : (
                                                    <div className="filter-item-btn ">
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setTimeout(() => { csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload(); }, 1000)
                                                            Swal.fire({
                                                                icon: 'success',
                                                                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                                                                showConfirmButton: false,
                                                                timer: 1500
                                                            })
                                                            toast.closeAll();
                                                        }}>
                                                            <i className="ico icon-excel-download"></i>
                                                        </a>
                                                        <ExcelFile
                                                            ref={csvBtn}
                                                            element={<a href="#" style={{ display: 'none' }} ><i className="ico icon-excel-download"></i></a>}
                                                            filename={`events_${moment().format('DD_MM_YYYY_HH_mm_ss')}`}>
                                                            <ExcelSheet dataSet={CommonHelper.generateExcelData(events, csvHeader, props)} name={`Sheet1`} />
                                                        </ExcelFile>
                                                    </div>
                                                )}
                                            </>
                                            :
                                            <>
                                            </>
                                    }


                                </div>
                            </div>
                            {
                                props.isFromDashboard && loader ? (
                                    <>
                                        <div className='loader-center-screen' style={{ height: windowSize.windowHeight, width: windowSize.windowWidth }}>
                                            <div className="spinner-loader h-100 undefined "><div className="d-flex h-100 justify-content-center align-items-center text-primary"><div role="status" className="spinner-border text-primary"></div><p className="text-muted mt-3"></p></div></div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="common-table">
                                        <TableView
                                            isReorderable={true}
                                            datalist={_.orderBy(events.filter(
                                                ({ title, location }) => (!_.isEmpty(searchText) ?
                                                    (title.toLowerCase().includes(searchText.toLowerCase()) ||
                                                        location.toLowerCase().includes(searchText.toLowerCase()))
                                                    : true)), ['startDate.seconds'], ['desc'])}
                                            height={windowSize.windowHeight}
                                            width={windowSize.windowWidth}
                                            columns={allEventsProFields}
                                            handleSavesettings={handleSavesettings}
                                            dynamicFields={(eventsproFields && eventsproFields.length > 0) ? eventsproFields : allEventsProFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                            settingsLoader={settingsLoader}
                                            handleActionClick={handleActionClick}
                                            handleRowSingleClick={enquiryDetailclick}
                                            isSettings={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            <PopUpModal show={showReAuthentication}>
                <ReAuthenticate
                    show={showReAuthentication}
                    handleClose={handleReAuthOpenClose}
                    dealersettings={props.dealersettings}
                    privacy='file'
                />
            </PopUpModal>

        </>
    );
}

export default EventsList;