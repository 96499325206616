
/** LIBRARIES */
import React, { PureComponent } from "react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Modal } from "react-bootstrap";
import Translate from '../constants/translate';

class ImageCropHook extends PureComponent {
    constructor(props) {
        super(props);
        this.state =
        {
            croppedImageUrl: null
        };

    }

    handleCancel(e) {
        if (e)
            e.preventDefault();
        this.props.handleClose(this.props.cropShow.id);
    }

    handleSubmit(e) {

        e.preventDefault();

        if (typeof this.cropper?.cropper?.getCroppedCanvas() === 'undefined') {
            return;
        }
        else {
            this.props.cropShow.src = this.cropper.cropper.getCroppedCanvas({ fillColor: '#FFFFFF' }).toDataURL('image/jpeg');
        }
        //console.log( this.props.cropShow.src) ;      

        this.props.handleSelect(this.props.cropShow)
    }

    render() {
        const { show, src, title } = this.props.cropShow;

        return (
            <>

                <Modal
                    show={show}
                    onHide={this.handleCancel}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog modal-crop modal-dialog-centered"
                    style={this.props.fromChat === true ? { zIndex: 1053 } : {}}
                >

                    <Modal.Header /*closeButton*/>
                        <Modal.Title> <h4 className="modal-title"><Translate text={'Crop'} /> - {title}</h4>	</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className="text-center p-3">
                            <Cropper
                                style={{ height: 400, width: '100%' }}
                                //aspectRatio={16 / 9}
                                preview=".img-preview"
                                guides={false}
                                src={src}
                                ref={cropper => { this.cropper = cropper; }}
                                background={false}
                                viewMode={1}
                                autoCrop={true}
                                autoCropArea={1}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default float-left"
                            onClick={(e) => this.handleCancel(e)}
                        > <Translate text={'cancel'} /> </button>
                        <button type="button" className="btn btn-primary ml-2" onClick={(e) => this.handleSubmit(e)} ><Translate text={'confirm'} /></button>

                    </Modal.Footer>
                </Modal>
            </>


        );
    }
}

export default ImageCropHook;