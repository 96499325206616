import React, { useEffect, useRef } from 'react';
import _ from 'lodash'

import CafeSearch from './sections/cafeSearch';
import FrontDeskSearch from './sections/frontDeskSearch';
import LoanVehicleSearch from './sections/loanVehicleSearch';
import OEMSearch from './sections/oemSearch';
import SalesSearch from './sections/salesSearch';
import StockSearch from './sections/stockSearch';
import ServiceSearch from './sections/serviceSearch';
import WholesalerSearch from './sections/wholesalerSearch';
import TradeinProSearch from './sections/tradeinproSearch';
import EventsProSearch from './sections/eventsproSearch';
import FleetProSearch from './sections/fleetproSearch';

const SearchContainer = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleSearchClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleOpenStock,
	handleAttendeeOpen,
	handleOpenCafeOrder,
	handleTestDriveOpen,
	handleDealerOpen,
	handleLoanVehicleOpen,
	handleInBoundOpen,
	history,
	pipeline,
	modules,
	handleOpenOffers
}) => {
	const wrapperRef = useRef(null);

	const enableSharedContact = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.group) &&
		dealersettings.group.enableSharedContact) ? true : false);

	const enableSharedStock = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.group) &&
		dealersettings.group.enableSharedStock) ? true : false);

	const getDealerName = (obj) => {
		let dealerName = ''
		if (dealersettings.clients.filter(e => e.id === obj.clientID).length) {
			let _dealer = dealersettings.clients.filter(e => e.id === obj.clientID)[0];
			dealerName = _dealer && _dealer.name ? _dealer.name : '';
		}
		return dealerName
	}



	function useOutsideAlerter(ref) {
		useEffect(() => {
			const handleClickOutside = (event) => {
				if (ref.current && !ref.current.contains(event.target)) {
					handleSearchClose()
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	useOutsideAlerter(wrapperRef)

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }} ref={wrapperRef}>
			{localStorage.defaultModule === 'enquiry' ? (
				<SalesSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					handleInBoundOpen={handleInBoundOpen}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					enableSharedStock={enableSharedStock}
					getDealerName={getDealerName}
					modules={modules}
				/>
			) : localStorage.defaultModule === 'frontdesk' || localStorage.defaultModule === 'reports' ? (
				<FrontDeskSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'kitchen' ? (
				<CafeSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					handleOpenCafeOrder={handleOpenCafeOrder}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'loanVehicle' ? (
				<LoanVehicleSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					handleTestDriveOpen={handleTestDriveOpen}
					handleLoanVehicleOpen={handleLoanVehicleOpen}
					pipeline={pipeline}
					enableSharedStock={enableSharedStock}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'oem' ? (
				<OEMSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					handleDealerOpen={handleDealerOpen}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'stock' ? (
				<StockSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					enableSharedStock={enableSharedStock}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'service' ? (
				<ServiceSearch
					history={history}
					handleOpenStock={handleOpenStock}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'wholesaler' ? (
				<WholesalerSearch
					history={history}
					handleOpenOffers={handleOpenOffers}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					enableSharedContact={enableSharedContact}
					getDealerName={getDealerName}
				/>
			) : localStorage.defaultModule === 'tradeinPro' ? (
				<TradeinProSearch
					history={history}
					handleOpenEditActivity={handleOpenEditActivity}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					getDealerName={getDealerName}
					modules={modules}
					enableSharedContact={enableSharedContact}
					handleInBoundOpen={handleInBoundOpen}
				/>
			) : localStorage.defaultModule?.includes('eventsPro') ? (
				<EventsProSearch
					history={history}
					handleOpenEditActivity={handleOpenEditActivity}
					handleAttendeeOpen={handleAttendeeOpen}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					getDealerName={getDealerName}
					modules={modules}
					enableSharedContact={enableSharedContact}
					handleInBoundOpen={handleInBoundOpen}
				/>
			) : localStorage.defaultModule?.includes('fleetPro') ? (
				<FleetProSearch
					history={history}
					handleOpenEditActivity={handleOpenEditActivity}
					handleAttendeeOpen={handleAttendeeOpen}
					handleCompanyView={handleCompanyView}
					showSearchPanel={showSearchPanel}
					dealersettings={dealersettings}
					searchText={searchText}
					handleClose={handleClose}
					pipeline={pipeline}
					getDealerName={getDealerName}
					modules={modules}
					enableSharedContact={enableSharedContact}
					handleInBoundOpen={handleInBoundOpen}
				/>
			) : (
				<></>
			)}
		</div>
	);
};

export default SearchContainer;
