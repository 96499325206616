import React, { useState } from 'react';
import toast from 'toasted-notes';
import moment from 'moment';
import _, { truncate } from 'lodash';

import CommonHelper from '../../services/common';
import { convertMilisecondsTimeStampToDate } from '../../services/helper';

import { contactBasicDetailsVM } from '../contacts/viewModel';
import { enquiryBasicTDVM } from '../pipeline/viewModel';
import { objService } from '../service/viewModel'
import { tipBasicDetailsVM } from '../tradeinplus/viewModel'

import ChatContainer from './chat';
import './chat.scss';

export const ChatUserListItem = ({
	clientUser,
	dealersettings,
	sidepanelClose,
	sharePanel,
	sendAs,
	contact,
	enquiry,
	service,
	tradeinPro,
	sentToList,
	handleAddSentToList,
	handleEnquirySwitchDealer,
	clientUsers,
	unreadCounts,
	setUnreadCounts,
	contactChat
}) => {
	const nameFrom = dealersettings.name;
	const [showChat, setShowChat] = useState(localStorage.showChat === 'true');

	const handleOpenShowChat = clientUser => {
		if (!sharePanel) {
			setShowChat(true);
			localStorage.setItem('showChat', 'true');
			markAsRead(clientUser);
		} else {
			if (sendAs === 'contact') {
				checkMessageAddress(clientUser);
			} else if (sendAs === 'service') {
				checkMessageAddress(clientUser);
			} else {
				checkMessageAddress(clientUser);
			}
		}
	};

	const handleCloseShowChat = () => {
		setShowChat(false);
		localStorage.removeItem('showChat');
	};

	const markAsRead = async clientUser => {
		if (sharePanel) {
			return
		}

		if (!clientUser.isRead && clientUser.dbAddress && clientUser.conversationType !== 'group') {
			if (Object.keys(unreadCounts).length < 3) {
				setUnreadCounts({ total: 0 })
			}
			try {
				const ref = window.firebase
					.firestore()
					.collection('messages')
					.doc(clientUser.dbAddress);
				ref.update({
					isRead: true,
				});
				const messagesRef = window.firebase
					.firestore()
					.collection('messages')
					.doc(clientUser.dbAddress)
					.collection(clientUser.dbAddress)
					.where('isRead', '==', false)
					.where('idTo', '==', localStorage.uid)
					.where('idFrom', '==', clientUser.id);

				messagesRef.get().then(querySnapshot => {
					// console.log(querySnapshot);
					let batch = window.firebase.firestore().batch();
					querySnapshot.forEach(doc => {
						const docRef = window.firebase
							.firestore()
							.collection('messages')
							.doc(clientUser.dbAddress)
							.collection(clientUser.dbAddress)
							.doc(doc.id);
						batch.update(docRef, { isRead: true });
					});
					batch.commit().then(() => {
						// console.log(`updated all documents inside ${'messages'}`);
					});
				});

				const notificationsRef = window.firebase
					.firestore()
					.collection('notifications')
					.where('isDeleted', '==', false)
					.where('isRead', '==', false)
					.where('toUserID', '==', localStorage.uid)
					.where('fromUserID', '==', clientUser.id)
					.where('type', '==', 'chat')

				notificationsRef.get().then(querySnapshot => {
					// console.log(querySnapshot);
					let batch = window.firebase.firestore().batch();
					querySnapshot.forEach(doc => {
						// console.log('ASASASAS', doc);
						const docRef = window.firebase
							.firestore()
							.collection('notifications')
							.doc(doc.id);
						batch.update(docRef, { isRead: true });
					});
					batch.commit().then(() => {
						// console.log(`updated all documents inside ${'messages'}`);
					});
				});

				// console.log('this is messagesRef', messagesRef);
				// console.log('marked as read', clientUser.isRead, clientUser.dbAddress);
			} catch (error) {
				console.error(error);
			}
		}

		if (clientUser.conversationType === 'group') {
			// console.log('called at all')
			if (Object.keys(unreadCounts).length < 3) {
				setUnreadCounts({ total: 0 })
			}
		}

		if (clientUser.conversationType === 'group' && clientUser.groupPendingReads && clientUser.groupPendingReads.includes(localStorage.uid)) {
			// console.log('called at all')

			if (Object.keys(unreadCounts).length < 3) {
				setUnreadCounts({ total: 0 })
			}
			try {
				const convRef = window.firebase
					.firestore()
					.collection('messages')
					.doc(clientUser.dbAddress);
				await convRef.update({
					groupPendingReads: clientUser.groupPendingReads.filter(participant => participant !== localStorage.uid),
				});

				const messagesRef = window.firebase
					.firestore()
					.collection('messages')
					.doc(clientUser.dbAddress)
					.collection(clientUser.dbAddress)
					.where('groupPendingReads', 'array-contains', localStorage.uid)

				messagesRef.get().then(querySnapshot => {
					// console.log(querySnapshot);
					let batch = window.firebase.firestore().batch();
					querySnapshot.forEach(doc => {
						const docRef = window.firebase
							.firestore()
							.collection('messages')
							.doc(clientUser.dbAddress)
							.collection(clientUser.dbAddress)
							.doc(doc.id);
						batch.update(docRef, { groupPendingReads: doc.data().groupPendingReads.filter(participant => participant !== localStorage.uid) });
					});
					batch.commit().then(() => {
						// console.log(`updated all documents inside ${'messages'}`);
						if (Object.keys(unreadCounts).length < 3) {
							setUnreadCounts({ total: 0 })
						}
					});
				});
			} catch (error) {
				console.error(error);
			}
		}

	};

	const checkMessageAddress = async (clientUser) => {
		// Add check to restrict sending same enquiry again and again
		if (sentToList.includes(clientUser.id) || sentToList.includes(clientUser.dbAddress)) {
			// toast.notify('Message already sent to this user', {
			// 	duration: 2000,
			// });
			setShowChat(true);
			localStorage.setItem('showChat', 'true');
			return;
		}

		// Check the address of the messages
		let alternateAddress = `${localStorage.uid}-${clientUser.id}`;
		let address = `${clientUser.id}-${localStorage.uid}`;

		let arrToSort = []
		arrToSort.push(localStorage.uid)
		arrToSort.push(clientUser.id)
		let sortedArr = arrToSort.sort()
		let idForMessage = sortedArr.join('-')
		address = idForMessage
		alternateAddress = idForMessage
		// console.log(`address, alternateAddress`,address, alternateAddress)

		if (clientUser.conversationType === 'group') {
			address = clientUser.dbAddress
			alternateAddress = clientUser.dbAddress
		}

		let firstRef = window.firebase
			.firestore()
			.collection('messages')
			.doc(address)
			.collection(address);

		let secondRef = window.firebase
			.firestore()
			.collection('messages')
			.doc(alternateAddress)
			.collection(alternateAddress);

		try {
			firstRef
				.orderBy('timestamp', 'desc')
				.limit(1)
				.get()
				.then(firstSnap => {
					if (firstSnap.size > 0) {
						handleMessageSend(address, clientUser);
					} else {
						secondRef
							.orderBy('timestamp', 'desc')
							.limit(1)
							.get()
							.then(secondSnap => {
								if (secondSnap.size > 0) {
									handleMessageSend(alternateAddress, clientUser);
								} else {
									handleMessageSend(alternateAddress, clientUser);
								}
							});
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const handleMessageSend = async (address, clientUser) => {
		try {
			if (address === null) {
				return;
			}

			let _contactBasicVM;
			let messageModel;
			let notifyText;
			const timestamp = moment()._d.getTime();

			const messageRef = window.firebase
				.firestore()
				.collection('messages')
				.doc(address)
				.collection(address)
				.doc(timestamp.toString());

			messageModel = {
				idFrom: localStorage.uid,
				idTo: clientUser.id,
				nameFrom,
				timestamp,
				oemID: dealersettings.client.settingsID,
				clientID: dealersettings.client.id,
				isRead: false,
				idToAMSMe: false,
				documentID: null,
				avatarFrom: CommonHelper.getUserImagebyId(clientUsers, localStorage.uid),
				addedFrom: 'web',
			};

			if (clientUser.conversationType === 'group') {
				messageModel.idTo = clientUser.dbAddress
				messageModel.groupPendingReads = []
				clientUser.participants.forEach(participant => {
					if (participant !== localStorage.uid) {
						messageModel.groupPendingReads.push(participant)
					}
				})
			}

			// if contact is passed then assign a basic contact detail vm
			// and set it as content value to be send to database
			if (sendAs === 'contact') {
				_contactBasicVM = Object.assign({}, contactBasicDetailsVM);
				for (let [key, value] of Object.entries(contact)) {
					if (_contactBasicVM.hasOwnProperty(key)) _contactBasicVM[key] = value;
				}
				messageModel.content = _contactBasicVM;
				messageModel.content.clientID = dealersettings.client.documentID;
				if (messageModel.content.interests && messageModel.content.interests === '') {
					messageModel.content.interests = []
				}
				messageModel.type = 'contact';
				notifyText = 'Contact shared';
			} else if (sendAs === 'enquiry') {
				let _enquiry = Object.assign({}, enquiryBasicTDVM);
				for (let [key, value] of Object.entries(enquiry)) {
					if (_enquiry.hasOwnProperty(key)) _enquiry[key] = value;
				}
				_enquiry.contact = enquiry.contact;

				messageModel.content = _enquiry;
				messageModel.content.clientID = dealersettings.client.documentID;
				messageModel.type = 'enquiry';
				notifyText = 'Enquiry shared';
			} else if (sendAs === 'service') {
				let _service = Object.assign({}, objService);
				for (let [key, value] of Object.entries(service)) {
					if (_service.hasOwnProperty(key)) _service[key] = value;
				}
				_service.contact = service.contact;

				messageModel.content = _service;
				messageModel.content.clientID = dealersettings.client.documentID;
				messageModel.type = 'service';
				notifyText = 'Service shared';
			} else if (sendAs === 'tradeinPro') {
				let _tradeinPro = Object.assign({}, tipBasicDetailsVM);
				for (let [key, value] of Object.entries(tradeinPro)) {
					if (_tradeinPro.hasOwnProperty(key)) _tradeinPro[key] = value;
				}
				_tradeinPro.contact = tradeinPro.contact;

				messageModel.content = _tradeinPro;
				messageModel.content.clientID = dealersettings.client.documentID;
				messageModel.type = 'tradeinPro';
				notifyText = 'Appraisal shared';
			}

			await messageRef.set(messageModel, { merge: true });

			setShowChat(true);
			localStorage.setItem('showChat', 'true');
			toast.notify(notifyText, {
				duration: 2000,
			});

			if (clientUser.conversationType === 'group') {
				handleAddSentToList(clientUser.dbAddress);
			} else {
				handleAddSentToList(clientUser.id);
			}

			// updating the latest message
			const ref = window.firebase
				.firestore()
				.collection('messages')
				.doc(address);
			await ref.set({
				lastMessage: { ...messageModel },
				timestamp: messageModel.timestamp,
				isRead: false,
			}, { merge: true });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			{(clientUser.id || clientUser.dbAddress) ? (
				<>
					<div
						className={`chat-item-wraper`}
						id={`chat-list-item-${clientUser.id ? clientUser.id : clientUser.dbAddress ? clientUser.dbAddress : ''}`}
						onClick={() => handleOpenShowChat(clientUser)}
					>
						<a
							className={`chat-item d-flex ${!clientUser.isRead && !clientUser.conversationType && !sharePanel
									? 'unread'
									: clientUser.conversationType &&
										clientUser.groupPendingReads &&
										clientUser.groupPendingReads.includes(localStorage.uid)
										? 'unread'
										: ''
								}`}
							href='#'
							onClick={e => {
								e.preventDefault();
							}}
						>
							<div className='mr-3'>
								<div className='chat-avatar'>
									{!clientUser.conversationType && !contactChat ?
										clientUser.tokkens && clientUser.tokkens.length > 0 ? (
											<div className='chat-online-badge'></div>
										) : (
											<div className='chat-offline-badge'></div>
										) : <></>}
									<img
										src={
											!clientUser.conversationType && !contactChat
												? CommonHelper.getUserImagebyId(clientUsers, clientUser.id)
												: clientUser.groupImageURL
													? clientUser.groupImageURL
													: clientUser.name
														? CommonHelper.showNameAvatar(clientUser.name)
														: contactChat &&
															!_.isEmpty(clientUser.participantInfo) &&
															clientUser.participantInfo.filter(
																participant => participant.isAMSME === true,
															).length
															? clientUser.participantInfo.filter(
																participant => participant.isAMSME === true,
															)[0].avatar
															: ''
										}
										alt=''
										className='img-object-fit'
									/>
								</div>
							</div>
							<div style={{ flex: '1' }}>
								{' '}
								<span className='chat-header' style={{ width: '100%' }}>
									{' '}
									{clientUser.name
										? clientUser.name
										: !contactChat && clientUser.email
											? clientUser.email
											: contactChat &&
												!_.isEmpty(clientUser.participantInfo) &&
												clientUser.participantInfo.filter(
													participant => participant.isAMSME === true,
												).length
												? clientUser.participantInfo.filter(
													participant => participant.isAMSME === true,
												)[0].name
												: ''}
								</span>{' '}
								{!clientUser?.lastMessage?.type && (
									<span className='chat-info'>
										{clientUser
											? clientUser.email
												? clientUser.email
												: clientUser.phone
											: ''}
									</span>
								)}
								{clientUser?.lastMessage?.type && (
									<span className='chat-info'>
										{`${clientUser?.lastMessage?.idFrom === localStorage.uid
												? 'You: '
												: clientUser.groupPendingReads
													? `${clientUser?.lastMessage?.nameFrom}: `
													: ''
											} ${clientUser?.lastMessage?.type === 'text' ? (
												truncate(clientUser?.lastMessage?.content, { length: 40 })
											) : clientUser?.lastMessage?.type === 'enquiry' ? (
												'Sent an enquiry'
											) : clientUser?.lastMessage?.type === 'contact' ? (
												'Sent a contact'
											) : clientUser?.lastMessage?.type === 'image' ? (
												'Sent a image'
											) : clientUser?.lastMessage?.type === 'service' ? (
												'Sent a service'
											) : clientUser?.lastMessage?.type === 'tradeinPro' ? (
												'Sent a appraisal'
											) : clientUser?.lastMessage?.type === 'file' ? (
												'Sent a file'
											) : (
												<></>
											)
											}`}
									</span>
								)}
								{clientUser.timestamp && clientUser?.lastMessage?.idFrom && (
									<div className='chat-date'>
										{convertMilisecondsTimeStampToDate(clientUser.timestamp)}
									</div>
								)}
							</div>
							{!clientUser.conversationType &&
								!clientUser.isRead &&
								!sharePanel &&
								unreadCounts[contactChat ? clientUser.dbAddress : clientUser.id] && (
									<div
										className='chat-count'
										style={{
											minHeight: '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<span
											className='badge badge-pill cursor-pointer'
											style={{
												color: 'white',
												background: '#4466f2',
												fontSize: '12px',
											}}
										>
											{unreadCounts[contactChat ? clientUser.dbAddress : clientUser.id]}
										</span>
									</div>
								)}
							{clientUser.conversationType === 'group' &&
								clientUser.groupPendingReads &&
								clientUser.groupPendingReads.includes(localStorage.uid) &&
								!sharePanel &&
								unreadCounts[clientUser.dbAddress] && (
									<div
										className='chat-count'
										style={{
											minHeight: '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
										}}
									>
										<span
											className='badge badge-pill cursor-pointer'
											style={{
												color: 'white',
												background: '#4466f2',
												fontSize: '12px',
											}}
										>
											{unreadCounts[clientUser.dbAddress]}
										</span>
									</div>
								)}
						</a>
					</div>
				</>) : (<></>)}
			{showChat && (
				<ChatContainer
					clientUsers={clientUsers}
					clientUser={clientUser}
					handleCloseShowChat={handleCloseShowChat}
					dealersettings={dealersettings}
					sidepanelClose={sidepanelClose}
					markAsRead={markAsRead}
					contactChat={contactChat}
				/>
			)}
		</>
	)
};

export default ChatUserListItem