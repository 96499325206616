import React, { useEffect, useState, useReducer } from 'react';
import _ from 'lodash';
import moment from 'moment';
import CustomToolbar from './toolbar'
import CustomEvent from './eventView';
import CommonHelper from '../../../../services/common';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { schedulerVM } from './viewModel';
import { Carousel } from 'react-bootstrap';
import { defaultActivityTypes } from '../../../../services/enum';
import SchedulerLink from './tvSchedulerLink';

const localizer = momentLocalizer(moment);

const ScheduleListReducer = (state, action) => {
	function updateEnquiry() {
		return state.map((item) => {
			if (item.documentID === action.data.documentID) {
				return action.data;
			}
			return item;
		});
	}
	switch (action.type) {
		case "SUCCESS": {
			return action.data
		}
		case "APPEND_LIST": {
			return [...state, ..._.differenceBy(action.data, state, 'documentID')]
		}
		case "ADD_LOG_LIST": {
			return [action.data, ...state, action.data]
		}
		case "REMOVE_LOG_LIST": {
			return state.filter(item => item.documentID !== action.data.documentID)
		}
		case "REMOVE_LOG": {
			return state.filter(item => item.documentID !== action.documentID)
		}
		case "UPDATE_LOG_LIST": {
			return updateEnquiry()
		}
		default:
			return state;
	}
};
const tvSchedulerActivity = {
	label: (<><i className={`ico icon-monitor mr-1`}></i>TV Scheduler</>),
	name: 'TV Scheduler',
	value: 'tvScheduler',
	active: true,
};
const ActivityLink = props => {
	const [schedulerList, dispatch] = useReducer(ScheduleListReducer, [])
	const [clients, setClients] = useState();
	const [loader, setLoader] = useState(true);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const [viewstate, setViewState] = useState('day')
	const [monthData, setMonthData] = useState(moment()._d.getMonth())
	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf('day'),
		endDate: moment().endOf('day'),
		date: moment().format('DD-MM-YYYY')
	})
	const [checkDataLoad, setDataload] = useState(true)
	const [allUsers, setAllUsers] = useState([])
	const [workingStartHour, setworkingStartHour] = useState()
	const [workingEndHour, setworkingEndHour] = useState()
	const [calendarHeight, setCaleandarHeight] = useState(window.innerHeight - 70)
	const [clientSettings, setClientSettings] = useState()
	const [clientIDs, setClientIDs] = useState([])
	//const [refreshTime, setRefreshTime] = useState(moment());
	const inIframe = (window === window.parent) ? false : true;

	useEffect(() => {
		if (props?.slides?.length > 0) {
			let _clientIDs = []
			props.slides.forEach(rec => {
				_clientIDs = [
					..._clientIDs,
					...rec.clientIDs
				]
			})
			setClientIDs(_.uniq(_clientIDs));
			loadClients(_.uniq(_clientIDs));
		}

	}, [])

	const loadClients = async (_clientIDs) => {
		let settingsDoc = {};
		const snapClients = await window.firebase.firestore().collection(`clients`)
			.where('documentID', 'in', CommonHelper.arrLimit(_clientIDs, 9))
			.get();
		let _clients = [];
		let _users = [];
		const clientpromises = []
		snapClients.docs.forEach(function (doc) {
			_clients.push(doc.data())
			clientpromises.push(window.firebase.firestore().doc(`clients/${doc.id}/currentSettings/${doc.id}`).get())
		});
		const clientsnapshots = await Promise.all(clientpromises);
		clientsnapshots.forEach(snap => {
			if (snap.exists) {
				var dataDoc = snap.data()?.settings;
				const objList = !_.isEmpty(snap.data()?.settings) && Object.keys(snap.data()?.settings).filter(function (e) { return _.isObject(snap.data()?.settings[e]) })
				objList.forEach((key) => {
					if (_.isArray(dataDoc[key])) {
						dataDoc[key].forEach((lookups) => {
							settingsDoc[key] = settingsDoc[key] ? [
								...settingsDoc[key],
								lookups
							] : [
								lookups
							]
						})
					}
				})
				_users = [
					..._users,
					...snap.data()?.users
				];
			}
		})
		setAllUsers(_users)
		setClientSettings(settingsDoc)
		// let _activityTypes = [];
		// if (_.uniqBy(settingsDoc.activityTypes, 'value')) {
		// 	_activityTypes = _.uniqBy(settingsDoc.activityTypes, 'value')?.filter(a => props?.activityTypes?.includes(a.value));
		// }
		// else {
		// 	_activityTypes = defaultActivityTypes.filter(a => props?.activityTypes?.includes(a.value));
		// }
		// if (_activityTypes.length > 0) {
		// 	setActivityTypes(_activityTypes);
		// 	setActitiveTab(_activityTypes[0].value)
		// }
		moment.tz.setDefault(_clients[0]?.timezone ? _clients[0]?.timezone : moment.tz.guess())
		setworkingStartHour(_clients[0]?.workingHours?.split(';')[0] ? moment(`${moment().format('YYYY-MM-DD')} ${_clients[0]?.workingHours.split(';')[0]}`).hours() : 6)
		setworkingEndHour(_clients[0]?.workingHours?.split(';')[1] ? moment(`${moment().format('YYYY-MM-DD')} ${_clients[0]?.workingHours.split(';')[1]}`).hours() : 22)
		setClients(_clients);
		setLoader(false)
	}

	useEffect(() => {
		function handleResize() {
			setCaleandarHeight(window.innerHeight - 70)
		}
		window.addEventListener('resize', handleResize);
		// if (window.reminderTimeout) {
		// 	clearInterval(window.reminderTimeout);
		// }
		// window.reminderTimeout = setInterval(() => {
		// 	if (dateRange.date !== moment().format('DD-MM-YYYY')) {
		// 		setDateRange({
		// 			startDate: moment().startOf('day'),
		// 			endDate: moment().endOf('day'),
		// 			date: moment().format('DD-MM-YYYY')
		// 		});
		// 		window.location.reload();
		// 	}
		// 	else {
		// 		if (moment().diff(refreshTime, 'minutes') === 1) {
		// 			window.location.reload();
		// 		}
		// 	}
		// }, 60000);
		return () => {
			window.removeEventListener('resize', handleResize);
			window.unrefSchedulatData && window.unrefSchedulatData();
		}
	}, []);

	useEffect(() => {
		if (loader)
			return;

		let refActivitiesData = window.firebase.firestore().collection('activities')
			.where('startDate', '>=', window.firebase.firestore.Timestamp.fromDate(dateRange.startDate.toDate()))
			.where('startDate', '<=', window.firebase.firestore.Timestamp.fromDate(dateRange.endDate.toDate()))
			.where('clientID', 'in', CommonHelper.arrLimit(clientIDs, 9))
			.orderBy('startDate', 'desc')
		window.unrefSchedulatData = refActivitiesData
			.onSnapshot(onCollectionUpdate);

		//}

	}, [dateRange, loader])

	const onCollectionUpdate = (querySnapshot) => {
		let actionType;
		const _activities = [];
		if (!actionType) {
			querySnapshot.forEach(function (doc) {
				var logVM = convertLogVM(doc.data())
				_activities.push(logVM)
			});
		}
		dispatch({
			type: "SUCCESS",
			data: _activities.filter(a => a.isDeleted === false)
		});
		setDataload(false)
	}

	const convertLogVM = (doc) => {

		doc.startDate = !_.isObject(doc.startDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.startDate)._d) : doc.startDate;
		doc.endDate = !_.isObject(doc.endDate) ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.endDate)._d) : doc.endDate;

		const objlogData = Object.assign({}, doc);
		const logVM = Object.assign({}, schedulerVM);
		for (let [key, value] of Object.entries(objlogData)) {
			if (logVM.hasOwnProperty(key))
				logVM[key] = value;
		}
		logVM.start = moment.unix(doc.startDate.seconds).toDate()
		logVM.end = moment.unix(doc.endDate.seconds).toDate()
		logVM.contactName = !_.isEmpty(doc.contact) ? CommonHelper.displayContactName([], doc.contact) : ''
		logVM.vehiclemodel = doc.enquiry && doc.enquiry.requirement ? !_.isEmpty(doc.enquiry.requirement) ? (!_.isEmpty(doc.enquiry.requirement.stock) && !_.isEmpty(doc.enquiry.requirement.stock.make)) ? (!_.isEmpty(doc.enquiry.requirement.stock.make) ? (doc.enquiry.requirement.stock.make + ' ' + doc.enquiry.requirement.stock.model) : '') : (!_.isEmpty(doc.enquiry.requirement.make) ? (doc.enquiry.requirement.make + ' ' + doc.enquiry.requirement.model) : '') : '' : '';
		logVM.regNo = doc?.enquiry?.requirement?.stock?.regNo ? doc.enquiry.requirement.stock.regNo : doc?.enquiry?.requirement?.regNo ? doc.enquiry.requirement.regNo : doc?.enquiry?.regNo ? doc.enquiry.regNo : '';
		logVM.stockNo = doc?.enquiry?.requirement?.stock?.stockNo ? doc.enquiry.requirement.stock.stockNo : doc?.enquiry?.requirement?.stockNo ? doc.enquiry.requirement.stockNo : doc?.enquiry?.stockNo ? doc.enquiry.stockNo : '';
		logVM.enqOwner = doc?.enquiry?.owner ? doc.enquiry.owner : '';
		// logVM.enqOwner = doc?.enquiry?.owner ? common.getUserNamebyId(allUsers, doc.enquiry.owner) : '';
		// logVM.enqOwnerImg = doc?.enquiry?.owner ? common.getUserImagebyId(allUsers, doc.enquiry.owner) : '';
		let saleTypeColor = null;
		if (doc?.enquiry?.requirement?.saleType) {
			const settings = clientSettings?.salesType?.find(a => a.value === doc?.enquiry?.requirement?.saleType)
			const make = doc?.enquiry?.requirement?.stock?.make ? doc?.enquiry?.requirement?.stock?.make.toLowerCase().trim() : doc?.enquiry?.requirement?.make ? doc?.enquiry?.requirement?.make.toLowerCase().trim() : '';
			if (settings?.colors?.find(a => a.make.toLowerCase().trim() === make)) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === make).color;
			}
			else if (settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others')) {
				saleTypeColor = settings?.colors?.find(a => a.make.toLowerCase().trim() === 'others').color;
			}
		}
		logVM.clientID = doc.clientID;
		logVM.title = doc.title;
		logVM.subType = doc.subType;
		logVM.isDone = doc.isDone;
		logVM.owner = doc.owner;
		logVM.resourceId = doc.owner;
		logVM.resourceTitle = _.find(allUsers, { id: doc.owner }) ? _.find(allUsers, { id: doc.owner }).name : doc.owner;
		logVM.dealerName = CommonHelper.getOtherDealerName({ clients }, doc.clientID);
		return logVM
	}

	const getResource = (activityTypes, salesType, clientIDs) => {
		return viewstate === 'day' && schedulerList.filter(a =>
			activityTypes.includes(a.subType)
			&& clientIDs.includes(a.clientID)
			&& (salesType?.length > 0 ? salesType.includes(a.enquiry?.requirement?.saleType) : true)).length > 0 ? {
			resources: _.uniqBy(allUsers, 'id').filter(a => _.uniqBy(schedulerList.filter(a =>
				activityTypes.includes(a.subType)
				&& clientIDs.includes(a.clientID)
				&& (salesType?.length > 0 ? salesType.includes(a.enquiry?.requirement?.saleType) : true)), 'owner').map(r => { return r.owner }).includes(a.id)).map(rec => {
					return {
						resourceId: rec.id,
						resourceTitle: <div className="user-calendar-day-cell">
							<div className="user-calendar-avatar">
								<img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" />
							</div>
							{rec.name}
						</div>,
					}
				}),
			resourceIdAccessor: "resourceId",
			resourceTitleAccessor: "resourceTitle"
		} : {}
	}


	const handleSelectSlot = () => {
		return;
	}

	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div role='status' className='spinner-border' style={{ color: '#4466F2' }}></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className='popup-wrap-fullscreen h-100'>
			<div className='container-fluid' id='tv-activity-lnk' style={props.inIframe ? {} : { zoom: '75%' }}>
				{
					props?.slides?.length > 0 ? (
						<Carousel controls={false} indicators={false} interval={props?.slides?.length === 1 ? null : props.slideInterval > 0 ? props.slideInterval * 1000 : null} >
							{
								props?.slides.map(rec => {
									return <Carousel.Item key={rec.id} bsPrefix={'carousel-item h-100'}>
										{
											rec.activityTypes.includes('tvScheduler') ? (<>
												{
													rec.title ? (
														<div className="nav-tab-tv">
															<div className="carousel-indicators tv-tab-nav-wrap">
																<a href="#" className={`tv-nav-link active`} > {rec.title}</a>

															</div>
														</div>
													) : (<></>)
												}
												<SchedulerLink oemID={props.oemID} inIframe={inIframe} clientIDs={rec.clientIDs} salesType={rec.salesType} height={window.innerHeight + (window.innerHeight * 0.2)} activityLink={true} />
											</>
											) : (<>
												{
													rec.title ? (
														<div className="nav-tab-tv">
															<div className="carousel-indicators tv-tab-nav-wrap">
																<a href="#" className={`tv-nav-link active`} > {rec.title}</a>

															</div>
														</div>
													) : (<></>)
												}
												<Calendar
													className='tv-scheduler'
													popup={false}
													localizer={localizer}
													events={schedulerList.filter(a =>
														rec.activityTypes.includes(a.subType)
														&& rec.clientIDs.includes(a.clientID)
														&& (rec.salesType?.length > 0 ? rec.salesType.includes(a.enquiry?.requirement?.saleType) : true)
													)}
													style={props.inIframe ? { height: calendarHeight } : { height: `${window.innerHeight + (window.innerHeight * 0.2)}px` }}
													defaultView="day"
													views={['day', 'week']}
													min={moment().set('hour', workingStartHour).set('minute', 0).toDate()}
													max={moment().set('hour', workingEndHour).set('minute', 0).toDate()}
													components={{
														//toolbar: CustomToolbar,
														toolbar: propstb => (<CustomToolbar {...propstb}
															popupModel={true}
															viewstate={viewstate}
															handleChangeviewstate={(view) => {
																setViewState(view);
															}}
															handlechangemonth={(month) => setMonthData(month)}
															monthData={monthData}
															setDateRange={(dateRange) => {
																setDateRange(dateRange)
																setDataload(true)
															}}
															device={'device'}
															hideSearch={true}
															hideAddSchedule={true}
														/>),
														eventWrapper: propsevt => (<CustomEvent {...propsevt}
															onSelectEvent={handleSelectSlot}
															allUsers={allUsers}
															viewstate={viewstate} />)
													}}
													selectable
													onSelectSlot={handleSelectSlot}
													dayPropGetter={(date) => {
														return {
															className: 'special-day',
														}
													}}
													{...getResource(rec.activityTypes, rec.salesType, rec.clientIDs)}
												/>
											</>

											)
										}
									</Carousel.Item>
								})
							}
						</Carousel>
					) : (<></>)
				}
			</div>
		</div>
	);
};

export default ActivityLink;
