import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import _ from 'lodash'
import AddEmailAccount from './addEmailAccount'
import { PopUpModal, ContentOverlay, InputText } from '../../../../components'
import Translate from '../../../../constants/translate';
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import axios from 'axios';
import CommonHelper from '../../../../services/common';

const EmailSettings = (props) => {
    const [loader, setLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [awsLoader, setAWSLoader] = useState(false)
    const [btnloader, setBtnLoader] = useState(false)
    const [showModel, setShowModel] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [mailgunLoader, setMailgunLoader] = useState(false)

    const [checkawsLoader, setCheckAWSLoader] = useState(false)
    const [resetSMTPLoader, setResetSMTPLoader] = useState(false)
    const [resetawssesLoader, setResetawssesLoader] = useState(false)

    const handleModelClose = () => {
        setShowModel(false)
    }


    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if (_.isEmpty(code) || !_.isEmpty(props.dealer.nylasAccessToken))
            return

        authnulasEmail(code)
    }, [])

    async function authnulasEmail(code) {
        try {
            setLoader(true)
            const nylasExchangeCodeForToken = window.firebase
                .functions()
                .httpsCallable('nylas-exchangeCodeForToken');
            const resp = await nylasExchangeCodeForToken({
                code: code,
            });

            const nylasGetAccountInfo = window.firebase
                .functions()
                .httpsCallable('nylas-getAccountInfo');
            const accInfo = await nylasGetAccountInfo({
                token: resp.data,
            });
            window.firebase.firestore().doc(`clients/${props.dealer.documentID}`).set({
                nylasAccountInfo: accInfo.data,
                nylasAccessToken: resp.data
            }, { merge: true })
                .then(snapshot => {
                    toast.notify('Email linked successfully.', {
                        duration: 2000,
                    });
                    setLoader(false)
                })
                .catch(error => {
                    console.log(error)
                })
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }

    async function unAuthenticateDealerEmail() {
        try {
            setBtnLoader(true)
            const nylasCancelAccount = window.firebase
                .functions()
                .httpsCallable('nylas-cancelAccount');
            const resp = await nylasCancelAccount({
                token: props.dealer.nylasAccessToken,
            });
            if (resp.data.success) {
                window.firebase.firestore().doc(`clients/${props.dealer.documentID}`).set({
                    nylasAccountInfo: null,
                    nylasAccessToken: null,
                    nylasFolders: null,
                    nylasLabels: null,
                }, { merge: true })
                    .then(snapshot => {
                        toast.notify('Email disabled successfully.', {
                            duration: 2000,
                        });
                        setBtnLoader(false)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        } catch (error) {
            toast.notify('Something went wrong', {
                duration: 2000,
            });
            console.log(error);
        }
    }

    const disableEmail = () => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to disable email account.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                unAuthenticateDealerEmail()
            }
        });
    };



    const resetAWSSES = async () => {
        try {
            const result = await Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to switch to reset Amazon SES.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            })

            if (!result.value) {
                return
            }

            setResetawssesLoader(true)
            props.saveDealer(null, null, null, true)

            setResetawssesLoader(false)
        } catch (error) {
            setResetawssesLoader(false)
            console.log(error)
        }
    }

    const resetSTMP = async () => {
        try {
            const result = await Swal.fire({
                title: CommonHelper.showLocale(props, 'Are you sure?'),
                text: CommonHelper.showLocale(props, 'Do you want to switch to reset SMPT Settings.'),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                cancelButtonText: CommonHelper.showLocale(props, 'No'),
            })

            if (!result.value) {
                return
            }

            setResetSMTPLoader(true)
            props.saveDealer(null, null, true)

            setResetSMTPLoader(false)
        } catch (error) {
            setResetSMTPLoader(false)
            console.log(error)
        }
    }

    const verifySTMP = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if ((!_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.smtpFrom)) && !props.validateEmail(props.dealer.clientSettings.smtpFrom)) {
            formIsValid = false;
            errors['smtpFrom'] = errorClass;
        }
        if (_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.smtpUri)) {
            formIsValid = false;
            errors['smtpUri'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        const apiURL = props.dealersettings.apiURL
        try {
            setVerifyLoader(true)
            const response = await axios.post(`${apiURL}mailer-api/api/mailer/verify`, {
                smtpUri: props.dealer.clientSettings.smtpUri,
                from: props.dealer.clientSettings.smtpFrom,
            })
            // console.log('repsonse smtpverify', response)
            if (response && response.data && response.data.success) {
                props.saveDealer(true)
            } else {
                Swal.fire(CommonHelper.showLocale(props, 'Unable to verify SMTP Settings.'), response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setVerifyLoader(false)
        } catch (error) {
            console.log(error)
            setVerifyLoader(false)
            Swal.fire(CommonHelper.showLocale(props, 'Unable to verify SMTP Settings.'), '', 'error')
        }
    }

    const verifyAWSSES = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.awsses) ||
            _.isEmpty(props.dealer.clientSettings.awsses.email)) {
            formIsValid = false;
            errors['awsemail'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        const apiURL = props.dealersettings.apiURL
        try {
            setAWSLoader(true)
            const response = await axios.post(`${apiURL}awsses-api/api/awsses/verifyEmail`, {
                email: props.dealer.clientSettings.awsses.email
            })
            // console.log('repsonse awsses/verifyEmail', response)
            if (response && response.data && response.data.success) {
                Swal.fire(CommonHelper.showLocale(props, 'Verification Email Sent.'), response.data.message, 'success')
                props.saveDealer(null, true)
            } else {
                Swal.fire(CommonHelper.showLocale(props, 'Unable to verify email.'), response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setAWSLoader(false)
        } catch (error) {
            console.log(error)
            setAWSLoader(false)
            Swal.fire(CommonHelper.showLocale(props, 'Unable to verify email.'), '', 'error')
        }
    }

    const checkVerifyAWSSES = async () => {
        const apiURL = props.dealersettings.apiURL
        try {
            setCheckAWSLoader(true)
            const response = await axios.post(`${apiURL}awsses-api/api/awsses/checkVerification`, {
                email: props.dealer.clientSettings.awsses.email
            })
            // console.log('repsonse awsses/checkVerification', response)
            if (response &&
                response.data &&
                response.data.success &&
                !_.isEmpty(response.data.data) &&
                !_.isEmpty(response.data.data.VerificationAttributes) &&
                !_.isEmpty(response.data.data.VerificationAttributes) &&
                !_.isEmpty(response.data.data.VerificationAttributes[props.dealer.clientSettings.awsses.email]) &&
                response.data.data.VerificationAttributes[props.dealer.clientSettings.awsses.email].VerificationStatus) {

                // console.log('status resp', response.data.data.VerificationAttributes[props.dealer.clientSettings.awsses.email])

                if (response.data.data.VerificationAttributes[props.dealer.clientSettings.awsses.email].VerificationStatus === 'Pending') {
                    Swal.fire(CommonHelper.showLocale(props, 'Email Verification is still pending'), '', 'warning')
                } else {
                    Swal.fire(CommonHelper.showLocale(props, 'Email Verified'), '', 'success')
                    window.firebase.firestore().doc(`clients/${props.dealer.documentID}`).set({
                        ...props.dealer,
                        clientSettings: {
                            ...props.dealer.clientSettings,
                            'awsses': {
                                ...props.dealer.clientSettings.awsses,
                                verified: true
                            }
                        }
                    }, { merge: true })
                }
            } else {
                Swal.fire(CommonHelper.showLocale(props, 'Unable to verify email.'), response && response.data && response.data.data && response.data.data.response ? response.data.data.response : '', 'error')
            }
            setCheckAWSLoader(false)
        } catch (error) {
            console.log(error)
            setCheckAWSLoader(false)
            Swal.fire(CommonHelper.showLocale(props, 'Unable to verify email.'), '', 'error')
        }
    }

    const verifyMailgun = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.mailgunconfig) ||
            _.isEmpty(props.dealer.clientSettings.mailgunconfig.email)) {
            formIsValid = false;
            errors['email'] = errorClass;
        }
        if (_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.mailgunconfig) ||
            _.isEmpty(props.dealer.clientSettings.mailgunconfig.key)) {
            formIsValid = false;
            errors['key'] = errorClass;
        }
        if (_.isEmpty(props.dealer.clientSettings)
            || _.isEmpty(props.dealer.clientSettings.mailgunconfig) ||
            _.isEmpty(props.dealer.clientSettings.mailgunconfig.domain)) {
            formIsValid = false;
            errors['domain'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setMailgunLoader(true)
        setTimeout(() => {
            setMailgunLoader(false)
        }, 1000);
        props.saveDealer()
    }


    return (<>
        {
            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                <>
                    <Tabs defaultActiveKey="Custom" className="nav-fill">
                        <Tab eventKey="Custom" title="Custom SMTP">
                            <div className="email-settings-inner form-style">
                                <h3><Translate text={'Custom SMTP Settings'} /></h3>
                                <div className="settings-inner form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'From Email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'from email'}
                                                className={`form-control ${errorFields["smtpFrom"]}`}
                                                name="smtpFrom"
                                                onChange={props.handleSMTPOnChange}
                                                value={!_.isEmpty(props.dealer.clientSettings) ? props.dealer.clientSettings.smtpFrom : false}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label ><Translate text={'SMTP connection URI'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'smtp://username:password@smtp.hostname.com:465'}
                                                className={`form-control  ${errorFields["smtpUri"]}`}
                                                name="smtpUri"
                                                onChange={props.handleSMTPOnChange}
                                                value={!_.isEmpty(props.dealer.clientSettings) ? props.dealer.clientSettings.smtpUri : false}
                                                maxLength={500}
                                            />
                                        </div>
                                    </div>
                                    {!_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.smtpVerified ? (<div className="mfa-green mt-2">
                                        <div className="float-left mt-1"><i className="ico icon-settings-security"></i> <Translate text={'SMTP Settings are verified'} /></div>
                                        <div className="float-right ">
                                            <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetSTMP() }}>
                                                {
                                                    resetSMTPLoader ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                <Translate text={'Disable SMTP Settings'} />
                                            </button>
                                        </div>
                                    </div>) : (<div className="mfa-red mt-2">
                                        <div className="float-left mt-1"><i className="ico icon-settings-security"></i><Translate text={'SMTP Settings are unverified'} /> </div>
                                        <div className="float-right ">
                                            <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { verifySTMP() }}>
                                                {
                                                    verifyLoader ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                <Translate text={'Verify SMTP Settings'} />
                                            </button>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Amazon" title="Amazon SES">
                            <div className="email-settings-inner form-style">
                                <h3><Translate text={'Simple Email Service (Amazon SES)'} /></h3>
                                <div className="settings-inner form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'enter email'}
                                                className={`form-control ${errorFields["awsemail"]}`}
                                                name="email"
                                                onChange={props.handleAWSSESOnChange}
                                                value={(!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.awsses)) ? props.dealer.clientSettings.awsses.email : ''}
                                            />
                                        </div>
                                    </div>
                                    {!_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.awsses && props.dealer.clientSettings.awsses.verified ? (<div className="mfa-green mt-2">
                                        <div className="float-left mt-1"><i className="ico icon-settings-security"></i> <Translate text={'Email Verified'} /></div>
                                        <div className="float-right ">
                                            <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetAWSSES() }}>
                                                {
                                                    resetawssesLoader ?
                                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                        : (<></>)
                                                }
                                                <Translate text={'disable'} />
                                            </button>
                                        </div>
                                    </div>) :
                                        !_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.awsses && props.dealer.clientSettings.awsses.pending ? (
                                            (
                                                <div className="mfa-yellow mt-2">
                                                    <div className="float-left mt-1"><i className="ico icon-settings-security"></i> <Translate text={'Pending verification'} /></div>
                                                    <div className="float-right ">
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { verifyAWSSES() }}>
                                                            {
                                                                awsLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            <Translate text={'Resend Verification Email'} />
                                                        </button>
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { resetAWSSES() }}>
                                                            {
                                                                resetawssesLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            <Translate text={'disable'} />
                                                        </button>
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { checkVerifyAWSSES() }}>
                                                            {
                                                                checkawsLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            <Translate text={'Check Verification'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <>
                                                {/* <div className="mfa-red mt-2">
                                                    <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Email unverified</div>
                                                    <div className="float-right ">
                                                        <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => { verifyAWSSES() }}>
                                                            {
                                                                awsLoader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            Verify
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </>
                                        )}
                                </div>
                                {!_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.awsses && props.dealer.clientSettings.awsses.verified ? (<></>)
                                    : !_.isEmpty(props.dealer.clientSettings) && props.dealer.clientSettings.awsses && props.dealer.clientSettings.awsses.pending ? (<></>)
                                        : (
                                            <div className="settings-footer mt-3">
                                                <button type="button" className="btn btn-primary float-right" onClick={() => verifyAWSSES()}>
                                                    {
                                                        awsLoader ?
                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                            : (<></>)
                                                    }
                                                    <Translate text={'save'} />
                                                </button>
                                            </div>
                                        )}
                            </div>
                        </Tab>
                        <Tab eventKey="Nylas" title="Nylas">
                            <div className="email-settings-inner form-style">
                                <h3><Translate text={'Outbound Emails (SMTP)'} /></h3>
                                <p><Translate text={'Link your email account to send automatically'} /></p>
                                {
                                    (!_.isEmpty(props.dealer.nylasAccessToken) && !_.isEmpty(props.dealer.nylasAccountInfo)) ? (
                                        <div className="email-active-sync">
                                            <div className="email-active-sync-item active-tab">
                                                <div className="email-sync-status">
                                                    <button type="button" className="btn btn-common float-right" onClick={() => { disableEmail() }}>
                                                        {
                                                            btnloader ?
                                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                : (<></>)
                                                        }
                                                        <Translate text={'disable'} /> </button>
                                                </div>
                                                <h4>{props.dealer.nylasAccountInfo.email_address}</h4>
                                            </div>
                                        </div>
                                    ) : (<div className="w-100 mt-2 d-inline-block">
                                        <button type="button" className="btn btn-primary float-left" onClick={() => { setShowModel(true) }}><i className="ico icon-add mr-2"></i> <Translate text={'Add Email Account'} /> </button>
                                    </div>)
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="mailgun" title="Mailgun">
                            <div className="email-settings-inner form-style">
                                <h3><Translate text={'Mailgun Settings'} /></h3>
                                <div className="settings-inner form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'Domain'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'enter domain'}
                                                className={`form-control ${errorFields["domain"]}`}
                                                name="domain"
                                                onChange={props.handleMailgunOnChange}
                                                value={(!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.mailgunconfig)) ? props.dealer.clientSettings.mailgunconfig.domain : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'enter email'}
                                                className={`form-control ${errorFields["email"]}`}
                                                name="email"
                                                onChange={props.handleMailgunOnChange}
                                                value={(!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.mailgunconfig)) ? props.dealer.clientSettings.mailgunconfig.email : ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label><Translate text={'Key'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'enter key'}
                                                className={`form-control ${errorFields["key"]}`}
                                                name="key"
                                                onChange={props.handleMailgunOnChange}
                                                value={(!_.isEmpty(props.dealer.clientSettings) && !_.isEmpty(props.dealer.clientSettings.mailgunconfig)) ? props.dealer.clientSettings.mailgunconfig.key : ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-footer mt-3">
                                    <button type="button" className="btn btn-primary float-right" onClick={() => verifyMailgun()}>
                                        {
                                            mailgunLoader ?
                                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                : (<></>)
                                        }
                                        <Translate text={'save'} />
                                    </button>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </>
            )
        }
        <PopUpModal show={showModel}>
            <AddEmailAccount
                {...props}
                show={showModel}
                title={'Connect Dealer Account'}
                handleClose={handleModelClose}
            />
        </PopUpModal>
    </>);
}

export default EmailSettings;