/** LIBRARIES */
import React from 'react'
import moment from 'moment';
import { Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import _ from 'lodash'
import NumberFormat from 'react-number-format';
/** PROVIDERS */
import { StageConsumer } from './provider'
/** COMPONENTS */
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { tipOfferStatus } from '../../services/enum'

const EnquiryCell = React.memo(({ index, task, sidepanelOpen, clientUsers, groupUsers, handleEnquiryFav, dealersettings }) => {

    function getStyle(style, snapshot) {
        if (!snapshot.isDragging) return {};
        if (!snapshot.isDropAnimating) {
            return style;
        }

        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`
        };
    }

    const allUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
    const _enquiryFields = !_.isEmpty(task.enqFields) ? task.enqFields : task;

    // const currencySymbol = ((!_.isEmpty(dealersettings) &&
    //     !_.isEmpty(dealersettings.client) &&
    //     !_.isEmpty(dealersettings.client.currency)) ? dealersettings.client.currency.symbol + ' ' : '$ ');

    const currencySymbol = !_.isEmpty(_enquiryFields?.currency) ? _enquiryFields?.currency.symbol + ' ' : '$ ';

    const _jobStatus = _enquiryFields.status;
    let _badgeColor = _jobStatus === tipOfferStatus.LOST ? 'orange' : 'green'
    return (
        // <StageConsumer>
        //     {({ handleEnquiryFav, handleShowInboundLead, dealersettings }) => (
        // <Draggable
        //     draggableId={task.id}
        //     index={index}
        //     key={task.id}
        // >
        //     {(provided1, snapshot1) => (
        <>
            <div
                //ref={provided1.innerRef}
                //{...provided1.draggableProps}
                //{...provided1.dragHandleProps}
                //style={getStyle(provided1.draggableProps.style, snapshot1)}
                className={`pipeline-item cursor-pointer ${task.isNewTradein ? 'newlead' : (task.status ? task.status.toLowerCase() : '')}-cell `}//${snapshot1.isDraggingOver ? "pipeline-item-dragging-Over" : ""}
                id={`tradeinpro-${task.id}`}
                onClick={(e) => {
                    e.preventDefault();
                    sidepanelOpen(task.id)

                }}
            >

                {task.displayID ? <div className="tradein-valuation-num text-uppercase"><Translate text={'Valuation No'} />:&nbsp; {task.displayID.substring(0, 8)}</div> : <></>}
                {task.clientName ? <div className="dealer-group-title blue-color">{task.clientName}</div> : <></>}

                {
                    task.vehiclemodel ? <>
                        <div className="pipeline-item-vehicle tradein-tile-vehicle"><strong>{task.vehiclemodel}</strong></div>

                        {
                            task.rego
                                ?
                                <><div className="pipeline-item-source"><Translate text={'rego'} />: {task.rego}</div></>
                                :
                                <>
                                    {
                                        task.regNo
                                            ?
                                            <><div className="pipeline-item-source"><Translate text={'regNo'} />: {task.regNo}</div></>
                                            :
                                            <></>
                                    }
                                </>
                        }

                        {
                            task.vinNo
                                ?
                                <><div className="pipeline-item-source"><Translate text={'vinNo'} />: {task.vinNo}</div></>
                                :
                                <></>
                        }

                    </> : (<div className="no-data-red"><Translate text={CommonHelper.showVehicleText(dealersettings?.client?.category, 'vehiclemissing', 'reqmissing')} /></div>)
                }





                <div className="pipeline-item-div w-100">
                    {/* {
                        _enquiryFields && _enquiryFields.owner ? (<OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip>{CommonHelper.getUserNamebyId(allUsers, _enquiryFields.owner)}</Tooltip>
                            }
                        >
                            <div className="float-right">
                                <div className="pipeline-users pl-0">
                                    <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                        <img src={CommonHelper.getUserImagebyId(allUsers, _enquiryFields.owner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                    </a>
                                </div>
                            </div>
                        </OverlayTrigger>) : (<></>)
                    } */}
                    {
                        _enquiryFields && _enquiryFields.isReRequested
                            ?
                            <><div className="float-right"><div className="badge badge-pill badge-white ml-1"><Translate text={'reRequested'} /></div></div></>
                            :
                            <></>
                    }
                    <div className="float-left">

                        {
                            _enquiryFields && _enquiryFields.addedDate
                                ?
                                <div className="pipeline-item-div w-100">
                                    <i className="ico icon-timer"></i>
                                    {moment.unix(_enquiryFields.addedDate.seconds).format('DD MMM YYYY h:mm A')}
                                </div>
                                :
                                <></>
                        }

                    </div>


                </div>

                <div className="pipeline-item-div badge-slot-container">
                    <div className="float-left">
                        {
                            (_jobStatus)
                                ?
                                <>
                                    <div className='pipeline-button'>
                                        <span className={`status-${_jobStatus.toLowerCase()} border-bottom-0`}>
                                            {_jobStatus.split(/(?=[A-Z])/).join(' ').toUpperCase()}
                                        </span>
                                    </div>

                                </>
                                :
                                <></>

                        }
                    </div>
                    {
                        _enquiryFields && _enquiryFields.evaluationPrice
                            ?
                            <>
                                <div className="float-right">
                                    <div className={`badge-slot-tradein badge-slot-tradein-${_badgeColor}`}>
                                        <i className="ico icon-sent"></i>
                                        <NumberFormat value={_enquiryFields.evaluationPrice} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} />
                                    </div>
                                </div>
                            </>
                            :
                            <>


                            </>
                    }

                </div>




            </div>
        </>
        //     )
        //     }
        // </Draggable >
        //     )}
        // </StageConsumer>
    )
})

export default EnquiryCell