import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { InputCheckBox, InputText, ReactMultiSelect, ReactSelect } from '../../../../components'
import Translate from '../../../../constants/translate';
import { deviceVM, visibilities } from './viewModel'
import { TemplateContext } from '../templateContext';
import moment from 'moment';
import { storageBucket } from '../../../../services/helper';
import CommonHelper from '../../../../services/common';
import { activityLogOptions } from '../../../pipeline/viewModel';

const tvSchedulerActivity = {
    label: (<><i className={`ico icon-monitor mr-1`}></i>TV Scheduler</>),
    name: 'TV Scheduler',
    value: 'tvScheduler',
    active: true,
};

const AddDevice = (props) => {

    const [device, setDevice] = useState(props.device)
    const [errorFields, setErrorFields] = useState({});
    const [loader, setLoader] = useState(false)
    const newDevice = !_.isEmpty(props.device) ? false : true;
    const { clients } = useContext(TemplateContext);
    const [videoLoader, setVideoLoader] = useState(false)
    const [activityTypes, setActivityTypes] = useState([...activityLogOptions, tvSchedulerActivity]);
    const [salesTypes, setSalesTypes] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [addSubList, setAddSubList] = useState({ clientIDs: '', activityTypes: '', id: uuid() })
    const [textEditor, setTexteditor] = useState({})
    const [editSubList, setEditSubList] = useState()
    const [subList, setSubList] = useState(!_.isEmpty(props.device) && !_.isEmpty(props.device.slides) ? props.device.slides.map(r => { return { ...r, id: r.id ? r.id : uuid() } }) : []);

    useEffect(() => {
        if (_.isEmpty(props.device)) {
            const docID = window.firebase.firestore().collection(`tvDevices`).doc().id;
            const newdeviceVM = Object.assign({}, deviceVM);
            newdeviceVM.documentID = docID;
            newdeviceVM.clientID = props.dealersettings.client.id;
            newdeviceVM.oemID = props.dealersettings.oemID;
            newdeviceVM.addedBy = localStorage.uid;
            newdeviceVM.modifiedBy = localStorage.uid;
            newdeviceVM.addedDate = window.firebase.firestore.Timestamp.now();
            newdeviceVM.modifiedDate = window.firebase.firestore.Timestamp.now();
            newdeviceVM.visibility = 'private';
            newdeviceVM.url = `customer/delivery/${docID}?cid=${props.dealersettings.oemID}`;
            setDevice(newdeviceVM)
        }
    }, [])

    useEffect(() => {
        const { dealersettings } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const _activityTypes = [];
            const _salesTypes = [];
            dealersettings.client.settings.activityTypes && dealersettings.client.settings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push(
                    {
                        label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                        name: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });
            dealersettings.client.settings.salesType && dealersettings.client.settings.salesType.filter(a => a.active === true).forEach(doc => {
                _salesTypes.push(
                    {
                        label: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });
            if (!_.isEmpty(_activityTypes))
                setActivityTypes([..._activityTypes, tvSchedulerActivity]);
            if (!_.isEmpty(_salesTypes))
                setSalesTypes(_salesTypes);

        }

    }, [])

    useEffect(() => {
        if (!_.isEmpty(clients)) {
            let _clients = [];
            _.filter(clients, (v) => _.indexOf(props.dealersettings.clients.map(v => _.pick(v, ['id']).id), v.documentID) >= 0).forEach(_client => {
                _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true })
            });
            setClientOptions(_clients)
        }
    }, [clients])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;
        if (name === 'value') {
            setDevice({
                ...device,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setDevice({
                    ...device,
                    [name]: newarray1.join(' '),
                    ['value']: newDevice ? uuid() : device.value
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setDevice({
                    ...device,
                    [name]: str,
                    ['value']: newDevice ? uuid() : device.value
                });
            }
        }
        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }
    const handleTextChange = (e) => {
        setDevice({
            ...device,
            [e.target.name]: YouTubeGetID(e.target.value)
        });
        setErrorFields({
            ...errorFields,
            [e.target.name]: null,
        });
    };


    const handleReactSelectChange = (e, data) => {
        setDevice({
            ...device,
            [data.name]: e ? e.value : null,
        });
        setErrorFields({
            ...errorFields,
            [data.name]: null,
        });
    };

    // CAN UPDATE DIFFIRENT LEVEL
    const saveDevice = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(device['name'])) {
            formIsValid = false;
            errors['name'] = errorClass;
        }

        setErrorFields(errors);
        if (!formIsValid) {
            return;
        }
        setLoader(true);
        device.modifiedDate = window.firebase.firestore.Timestamp.now();
        device.modifiedBy = localStorage.uid
        window.firebase
            .firestore()
            .doc(
                `tvDevices/${device.documentID}`,
            )
            .set({ ..._.pick(device, _.keys(deviceVM)) }, { merge: true })
            .then(snapshot => {
                setLoader(false);
                toast.notify(
                    newDevice === true
                        ? 'Device added successfully'
                        : 'Device updated successfully',
                    {
                        duration: 2000,
                    },
                );
                props.handleClose(device);
            })
            .catch(error => {
                console.error(error);
                Swal.fire(CommonHelper.showLocale(props, 'Something went wrong'), '', 'error');
            });
    }

    const onSelectFile = (e, type, id, title, rec) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(mpeg|mp4|avi|mov|wmv|flv|3gpp)$/i)) {
                Swal.fire(CommonHelper.showLocale(props, 'Please select video file'), '', 'info')
                return;
            }

            var _size = e.target.files[0].size;
            var filesize = _size / 1024 / 1024;
            if (filesize > 200) {
                Swal.fire(CommonHelper.showLocale(props, 'Maximum file size 200MB.'), '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];
            reader.onloadend = () => {
                saveImageToStorage(reader.result, id, type, file.name.substr((file.name.lastIndexOf('.') + 1)), file.name, file.size)
            }
            reader.readAsDataURL(file)
        }
    }

    const saveImageToStorage = (url, id, type, ext, name, size) => {
        let uid = moment()._d.getTime().toString() + '.' + ext;
        setVideoLoader(true)
        var storageRef = storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/tvDevices/${uid}`).putString(url, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            //console.log(snapshot);
        },
            (error) => {
                //console.log(error);
            },
            () => {
                storageBucket(props.dealersettings).ref(`${props.dealersettings.client.id}/tvDevices`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setDevice({
                            ...device,
                            [id]: dataURL,
                            ['fileName']: name,
                            ['fileSize']: size
                        })
                        setVideoLoader(false)
                    })
            })
    };

    const YouTubeGetID = (url) => {
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    const handleCheckedChange = (e) => {
        if (e.target.checked) {
            setDevice({
                ...device,
                [e.target.name]: e.target.checked
            })
        }
        else {
            setDevice({
                ...device,
                [e.target.name]: e.target.checked,
                ['clientIDs']: null,
                ['activityTypes']: null
            })
        }
    }

    const handleNumberChange = e => {
        var regexp = /^[0-9+ \b]+$/;
        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || regexp.test(number)) {
            setDevice({
                ...device,
                [e.target.name]: Number(e.target.value)
            });
        }
    };

    const handleOnSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setAddSubList({
                ...addSubList,
                [name]: newarray1.join(' '),
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setAddSubList({
                ...addSubList,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }

    const handleOnEditSubChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            setEditSubList({
                ...editSubList,
                [name]: newarray1.join(' ')
            });
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            setEditSubList({
                ...editSubList,
                [name]: str
            });
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }

    const handleSubMultiSelectChange = (selectedOptions, name) => {
        let value = [];
        if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions[selectedOptions.length - 1].value === 'tvScheduler') {
            value.push('tvScheduler')
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.filter(a => a.value !== 'tvScheduler').map((data) => {
                value.push(data.value);
            })
        }

        setAddSubList({
            ...addSubList,
            [name]: value,
        });
        setErrorFields({
            ...errorFields,
            ['sublist' + name]: ''
        });
    }


    const handleEditSubMultiSelectChange = (selectedOptions, name) => {

        let value = [];
        if (!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && selectedOptions[selectedOptions.length - 1].value === 'tvScheduler') {
            value.push('tvScheduler')
        }
        else {
            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                value.push(data.value);
            })
        }

        setEditSubList({
            ...editSubList,
            [name]: value
        })
        setErrorFields({
            ...errorFields,
            ['editsublist' + name]: ''
        });
    }


    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }
    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.id !== rec.id)];
                setSubList(deleteList)
                setDevice({
                    ...device,
                    ['slides']: deleteList
                });
            }
        })
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['clientIDs'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistclientIDs'] = errorClass;
            else
                errors['editsublistclientIDs'] = errorClass;
        }
        if (_.isEmpty(rec['activityTypes'])) {
            formIsValid = false;
            if (newlist)
                errors['sublistactivityTypes'] = errorClass;
            else
                errors['editsublistactivityTypes'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];
        setSubList(addList)
        setDevice({
            ...device,
            ['slides']: addList
        });
        setAddSubList({ clientIDs: '', activityTypes: '', id: uuid() })
        setEditSubList(null)
        setTexteditor({})
    }




    return _.isEmpty(device) ? (<></>) : (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            backdropClassName={props.show ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered modal-add-tv-device"
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title> <Translate text={props.title} /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-style w-100">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'name'}
                                        className={`form-control ${errorFields["name"]}`}
                                        name="name"
                                        onChange={handleOnChange}
                                        value={device.name}
                                        maxLength={150}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label > <Translate text={'Display Name'} /></label>
                                    <InputText
                                        autoComplete="off"
                                        placeholder={'displayName'}
                                        className={`form-control ${errorFields["displayName"]}`}
                                        name="displayName"
                                        onChange={handleOnChange}
                                        value={device.displayName}
                                        maxLength={150}
                                    />
                                </div>
                                <div className='form-group col-md-12'>
                                    <label>
                                        <Translate text={'visibility'} />
                                    </label>
                                    <ReactSelect
                                        options={visibilities}
                                        name={'visibility'}
                                        placeholder={'select visibility'}
                                        onChange={handleReactSelectChange}
                                        value={device.visibility}
                                        classNamePrefix={`basic-select`}
                                        removeClearable={true}
                                        isSearchable={false}
                                    ></ReactSelect>
                                </div>
                                <div className='form-group col-md-12'>
                                    <span className={`switch switch-sm float-left mt-2 `}>
                                        <InputCheckBox
                                            className={`switch`}
                                            id={`show-scheduler`}
                                            name={'showScheduler'}
                                            checked={device.showScheduler ? device.showScheduler : false}
                                            onChange={(e) => { handleCheckedChange(e) }} />
                                        <label htmlFor={`show-scheduler`}><Translate text={'Show Appointments / Delivery'} /></label>
                                    </span>
                                </div>
                                {
                                    device.showScheduler === true ? (<>
                                        <div className="common-table w-100 mt-2">
                                            <label>
                                                <Translate text={'TV Slides'} />
                                            </label>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="head-light" width="20%"> <Translate text={'Title'} /></th>
                                                        <th scope="col" className="head-light" width="20%"> <Translate text={'Activity Types'} /></th>
                                                        <th scope="col" className="head-light" width="20%"> <Translate text={'Sales Type'} /></th>
                                                        <th scope="col" className="head-light" width="20%"> <Translate text={'Dealers'} /></th>
                                                        <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><div className="">
                                                            <InputText
                                                                id="sublisttitle"
                                                                autoComplete="off"
                                                                placeholder={'title'}
                                                                className={`form-control ${errorFields["sublisttitle"]}`}
                                                                name="title"
                                                                onChange={handleOnSubChange}
                                                                value={addSubList.title}
                                                            />
                                                        </div></td>
                                                        <td><div className="">
                                                            <ReactMultiSelect
                                                                options={activityTypes}
                                                                name={"activityTypes"}
                                                                placeholder={'select type'}
                                                                onChange={(data) => handleSubMultiSelectChange(data, `activityTypes`)}
                                                                value={addSubList.activityTypes ? addSubList.activityTypes : []}
                                                                classNamePrefix={` ${errorFields["sublistactivityTypes"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div></td>
                                                        <td><div className="">
                                                            <ReactMultiSelect
                                                                options={salesTypes}
                                                                name={"salesType"}
                                                                placeholder={'select sales type'}
                                                                onChange={(data) => handleSubMultiSelectChange(data, `salesType`)}
                                                                value={addSubList.salesType ? addSubList.salesType : []}
                                                                classNamePrefix={`basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div></td>
                                                        <td><div className="">
                                                            <ReactMultiSelect
                                                                options={clientOptions}
                                                                name={"clientIDs"}
                                                                placeholder={'select client'}
                                                                onChange={(data) => handleSubMultiSelectChange(data, `clientIDs`)}
                                                                value={addSubList.clientIDs ? addSubList.clientIDs : []}
                                                                classNamePrefix={` ${errorFields["sublistclientIDs"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div></td>
                                                        <td className="border-right-0">
                                                            <div className="button-height-fix">
                                                                <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}> <Translate text={'add'} /></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        subList.length > 0 ?
                                                            subList.map((rec, index) => {
                                                                return <tr key={index} onDoubleClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}>
                                                                    <td>
                                                                        {
                                                                            textEditor[rec.id] ? (<>  <InputText
                                                                                id="editsublisttitle"
                                                                                autoComplete="off"
                                                                                placeholder={'title'}
                                                                                className={`form-control ${errorFields["editsublisttitle"]}`}
                                                                                name="title"
                                                                                onChange={handleOnEditSubChange}
                                                                                value={editSubList.title}
                                                                            /></>) : (<>{rec.title ? rec.title : ''}</>)
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            textEditor[rec.id] ? (<> <ReactMultiSelect
                                                                                options={activityTypes}
                                                                                name={"activityTypes"}
                                                                                placeholder={'select type'}
                                                                                onChange={(data) => handleEditSubMultiSelectChange(data, `activityTypes`)}
                                                                                value={editSubList.activityTypes ? editSubList.activityTypes : []}
                                                                                classNamePrefix={` ${errorFields["editsublistactivityTypes"]} basic-select`}
                                                                                isMulti={true}
                                                                                isSearchable={true}
                                                                            >
                                                                            </ReactMultiSelect></>) : (<div className={'Vehicle-availability'}>{
                                                                                activityTypes.filter(e => rec.activityTypes.includes(e.value)).map((rec, index) => {
                                                                                    return <div key={index}><span>{rec.label}</span></div>;
                                                                                })
                                                                            }</div>)
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            textEditor[rec.id] ? (<> <ReactMultiSelect
                                                                                options={salesTypes}
                                                                                name={"salesType"}
                                                                                placeholder={'select sales type'}
                                                                                onChange={(data) => handleEditSubMultiSelectChange(data, `salesType`)}
                                                                                value={editSubList.salesType ? editSubList.salesType : []}
                                                                                classNamePrefix={`basic-select`}
                                                                                isMulti={true}
                                                                                isSearchable={true}
                                                                            >
                                                                            </ReactMultiSelect></>) : rec.salesType ? (<div className={'Vehicle-availability'}>{
                                                                                salesTypes.filter(e => rec.salesType.includes(e.value)).map((rec, index) => {
                                                                                    return <div key={index}><span>{rec.label}</span></div>;
                                                                                })
                                                                            }</div>) : (<></>)
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            textEditor[rec.id] ? (<> <ReactMultiSelect
                                                                                options={clientOptions}
                                                                                name={"clientIDs"}
                                                                                placeholder={'select client'}
                                                                                onChange={(data) => handleEditSubMultiSelectChange(data, `clientIDs`)}
                                                                                value={editSubList.clientIDs ? editSubList.clientIDs : []}
                                                                                classNamePrefix={` ${errorFields["editsublistclientIDs"]} basic-select`}
                                                                                isMulti={true}
                                                                                isSearchable={true}
                                                                            >
                                                                            </ReactMultiSelect></>) : (<div className={'Vehicle-availability'}>{
                                                                                clientOptions.filter(e => rec.clientIDs.includes(e.value)).map((rec, index) => {
                                                                                    return <div key={index}><span>{rec.label}</span></div>;
                                                                                })
                                                                            }</div>)
                                                                        }
                                                                    </td>
                                                                    <td className="border-right-0">
                                                                        <div className="action-fix">
                                                                            {
                                                                                textEditor[rec.id] ?
                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleSublistSave(editSubList, false) }}> <i className="ico icon-check"></i></a></div>
                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCancelSubList(rec) }}> <i className="ico icon-remove"></i></a></div>
                                                                                    </>) :
                                                                                    (<><div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleEditSubList(rec) }}> <i className="ico icon-edit"></i></a></div>
                                                                                        <div className="table-edit float-left mr-2"> <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a></div>
                                                                                    </>)
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            })
                                                            : (<></>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='form-group col-md-12'>
                                            <label>
                                                <Translate text={'Data Interval (seconds)'} />
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                placeholder={'interval in seconds'}
                                                pattern="[0-9]*"
                                                className={`form-control `}
                                                name="slideInterval"
                                                onChange={handleNumberChange}
                                                value={device.slideInterval ? device.slideInterval : 0}
                                            />
                                        </div>
                                    </>) : (
                                        <>
                                            {
                                                _.isEmpty(device.videoURL) && _.isEmpty(device.embedURL) ? (
                                                    <div className="tv-device-upload-file-box">
                                                        <label htmlFor=''><Translate text={'Upload Video'} /> </label>
                                                        <div className="tv-device-upload-box"> <a href="#">
                                                            <label htmlFor='videoUpload' className="tv-device-upload-iconwrap" style={{ margin: 0, cursor: 'pointer' }}>
                                                                {
                                                                    videoLoader ? (
                                                                        <span className="spinner-border text-primary mt-1 float-none" role="status" aria-hidden="true"></span>
                                                                    ) : (<> <i className="ico icon-files-upload"></i> <span className="upload-text"><Translate text={'Upload File'} /></span></>)
                                                                }
                                                                <input className="fileInput"
                                                                    type="file"
                                                                    id="videoUpload"
                                                                    accept="video/mp4,video/x-m4v,video/*"
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => onSelectFile(e, 'profile', 'videoURL', 'Add Video')} />
                                                            </label>
                                                        </a>
                                                        </div>
                                                        <div className="form-style w-100 mt-2">
                                                            <div className="form-row ">
                                                                <div className="form-group col-md-12">
                                                                    <label htmlFor=""><Translate text={'Embed Youtube Video'} /> </label>
                                                                    <InputText
                                                                        autoComplete="off"
                                                                        placeholder={'paste youtube video link'}
                                                                        className={`form-control ${errorFields["embedURL"]}`}
                                                                        name="embedURL"
                                                                        onChange={handleTextChange}
                                                                        value={device.embedURL}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : !_.isEmpty(device.videoURL) ? (<>  <label htmlFor=''><Translate text={'Custom Video'} /> </label>
                                                    <div className="tv-device-uploaded-list">

                                                        <div className="tv-device-flex-upload">
                                                            <div className="tv-device-upload-clear"><a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                Swal.fire({
                                                                    title: CommonHelper.showLocale(props, 'Are you sure?'),
                                                                    text: CommonHelper.showLocale(props, 'Do you want to delete'),
                                                                    icon: 'info',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                                                                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                                                                }).then(result => {
                                                                    if (result.value) {
                                                                        setDevice({
                                                                            ...device,
                                                                            'videoURL': null
                                                                        })
                                                                    }
                                                                })

                                                            }}><i
                                                                className="fa fa-times" aria-hidden="true"></i></a></div>
                                                            <div className="tv-device-file-upload-icon"> <i className="ico icon-files-video"></i> </div>
                                                            <div className="tv-device-upload-data">
                                                                <a data-fancybox="file_data" href={device.videoURL} style={{ color: '#4466f2' }}>
                                                                    {device.fileName}
                                                                </a></div>
                                                        </div>
                                                    </div>
                                                </>) : !_.isEmpty(device.embedURL) ? (<><label htmlFor=''><Translate text={'Youtube Video'} /> </label>
                                                    <div className="tv-device-uploaded-list">

                                                        <div className="tv-device-flex-upload">
                                                            <div className="tv-device-upload-clear"><a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                Swal.fire({
                                                                    title: CommonHelper.showLocale(props, 'Are you sure?'),
                                                                    text: CommonHelper.showLocale(props, 'Do you want to delete'),
                                                                    icon: 'info',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
                                                                    cancelButtonText: CommonHelper.showLocale(props, 'No'),
                                                                }).then(result => {
                                                                    if (result.value) {
                                                                        setDevice({
                                                                            ...device,
                                                                            'embedURL': null
                                                                        })
                                                                    }
                                                                })
                                                            }}><i
                                                                className="fa fa-times" aria-hidden="true"></i></a></div>
                                                            <div className="tv-device-file-upload-thumb"> <img src={`https://img.youtube.com/vi/${device.embedURL}/1.jpg`} /></div>
                                                            <div className="tv-device-upload-data">
                                                                <a data-fancybox="file_data" href={`https://www.youtube.com/watch?v=${device.embedURL}`} style={{ color: '#4466f2' }}>
                                                                    {`https://www.youtube.com/watch?v=${device.embedURL}`}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>) : (<></>)
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div >
                </div >
            </Modal.Body >
            <Modal.Footer className="modal-footer">
                <button type="button" className={`btn btn-primary float-right ml-2 ${videoLoader ? 'btn-disable' : ''}`} onClick={(e) => saveDevice(e)}>
                    {
                        loader ?
                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                            : (<></>)
                    }
                    <Translate text={'save'} />
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} > <Translate text={'cancel'} /></button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddDevice;