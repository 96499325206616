/** LIBRARIES */
import React, { Component } from "react";
//import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import moment from 'moment';
import _ from 'lodash'
//import { dateFnsLocalizer } from "react-big-calendar";
// import NumberFormat from 'react-number-format';
const { RangePicker } = DatePicker;

class AntDateRangePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.value !== this.props.value;
    // }
    //onDatepickerRef = (el) => { if (el && el.input) { el.input.readOnly = true; } }
    render() {
        const { name, onChange, value, readOnly, disabledDate, onCalendarChange, className, onOpenChange } = this.props;
        const _dates = [];
        if (!_.isEmpty(value) && _.isObject(value)) {
            value.forEach(date => {
                _dates.push(moment(date));
            })
        }
        //console.log(_dates)
        return (
            <RangePicker
                //ref={el => this.onDatepickerRef(el)}
                id={name}
                //value={value ? moment(new Date(value)) : null}
                value={!_.isEmpty(_dates) ? _dates : null}
                onChange={onChange}
                className={`w-100 ${className ? className : ''}`}
                //format={format}
                format='DD/MM/YYYY'
                size={'default'}
                inputReadOnly={readOnly}
                disabledDate={disabledDate}
                onCalendarChange={onCalendarChange}
                onOpenChange={onOpenChange}
            //dropdownClassName={'form-control'}
            />
        );
    }
}

export default AntDateRangePicker;