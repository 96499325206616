import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap'
import MentionEditor from '../../../../components/mentionEditor/mentionEditor';
import { InputCheckBox, InputText, PopUpModal, ReactMultiSelect, ReactSelect, WorkingHours } from '../../../../components'
import ReactSummernote from 'react-summernote';
import SummerEditor from '../../../mail/summereditor/summereditor';
import _ from 'lodash';
import { mentions, timeSlots } from './viewmodel';
import CommonHelper from '../../../../services/common';
import Translate from '../../../../constants/translate';
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import toast from 'toasted-notes'
import AddRules from './addRules';

const LeadAlloction = (props) => {
    const [email, setEmail] = useState(props.email)
    const [addSubList, setAddSubList] = useState({ id: uuid() })
    const [editSubList, setEditSubList] = useState()
    const [errorFields, setErrorFields] = useState({});
    const [textEditor, setTexteditor] = useState({})
    const [dynSalesTypes, setDynSaalesTypes] = useState([])
    const [dynOrigins, setDynOrigins] = useState([])
    const [dynIntegrations, setDynIntegrations] = useState([])
    const [makes, setMakes] = useState([])
    const [showModel, setShowModel] = useState({})
    const [whModel, setWHModel] = useState({})
    const [btnLoader, setBtnLoader] = useState(false)

    const roleIds = [..._.map(props.dealersettings.client.roles.filter(e => e.modules && e.modules.tradeinPro === true), function (obj) {
        return obj.documentID;
    })];

    const subList = props.data?.rules?.length > 0 ? props.data.rules : []
    useEffect(() => {
        var node = document.createElement('div');
        node.innerHTML = `${props.email}`;
        ReactSummernote.insertNode(node);
        setEmail(`${props.email}`)
    }, [])

    const convertTemplateJSON = (templateJSON) => {
        // const _templateJSON = JSON.stringify(obj.rawData)
        // const textMessage = obj.value.replace(/<[^>]*>?/gm, '')
        let data = JSON.parse(templateJSON);
        let text = '';
        if (!_.isEmpty(data.blocks)) {
            data.blocks.forEach(ele => {
                text += ele.text + ' ';
            });
        }
        if (text) {
            mentions.filter(a => a.dynamic === true).forEach(rec => {
                if (text.includes(`${rec.name}`)) {
                    text = text.replace(new RegExp(`${rec.name}`, 'g'), rec.id);
                }
            })
        }
        return text
    }

    useEffect(() => {
        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.tradeinPro)) {
            const setttings = props.dealersettings.client.tradeinPro;
            let _salesTypes = [];
            let _origins = [];
            let _integrations = [];
            setttings.salesType && setttings.salesType.filter(a => a.active === true).forEach((saleType) => {
                _salesTypes.push({
                    value: saleType.value,
                    label: saleType.name,
                    name: saleType.name
                })
            });
            //_salesTypes.push({ value: 'others', label: 'Others', name: 'Others' })

            setttings.origins && setttings.origins.filter(a => a.active === true).forEach((rec) => {
                _origins.push({
                    value: rec.value,
                    label: rec.name,
                    name: rec.name
                })
            });
            props?.dealersettings?.client?.integrations && props.dealersettings.client.integrations.filter(e => e.inbound === true && e.appraisal === true && e.enabled === true && e.active === true && e.value).forEach((rec) => {
                _integrations.push({
                    value: rec.value,
                    label: rec.name,
                    name: rec.name
                })
            });
            setDynIntegrations(_integrations)
            setDynOrigins(_origins)
            setDynSaalesTypes(_salesTypes)
        }
    }, [])

    useEffect(() => {
        let brands = props.dealersettings.allMakeModels;
        let makes = [];
        brands.forEach((doc) => {
            makes.push({
            value: doc.value,
                label: doc.name,
                name: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });
        setMakes(makes)
    }, [])

    const handleSubMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setAddSubList({
            ...addSubList,
            [name]: value,
        })
    }

    const handleEditMultiSelectChange = (selectedOptions, name) => {
        const value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        setEditSubList({
            ...editSubList,
            [name]: value,
        })
    }

    const getsubTypeName = (type, subType, pipeline) => {
        var result = [];
        if (!_.isEmpty(type)) {
            let subtypeOptions = !_.isEmpty(props.dealersettings.client.tradeinPro[type]) ? props.dealersettings.client.tradeinPro[type] : [];
            if (type === 'users') {
                subtypeOptions = props.clientUsers.filter(v => Boolean(v.superAdmin) === false && (roleIds ? _.includes(roleIds, v.roleID) : true)).map(rec => {
                    return {
                        ...rec,
                        value: rec.id,
                        name: (
                            <div className='drop-image'>
                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                {rec.name}
                            </div>
                        )
                    }
                })
            } else if (type === 'saleType') {
                subtypeOptions = dynSalesTypes
            } else if (type === 'make') {
                subtypeOptions = makes
            } else if (type === 'origin') {
                subtypeOptions = dynOrigins
            } else if (type === 'systemType') {
                subtypeOptions = dynIntegrations
            } else if (type === 'time') {
                subtypeOptions = timeSlots
            }
            else if (type === 'stage') {
                subtypeOptions = generateStageOptions(pipeline ? pipeline : '')
            }
            if (_.isArray(subType)) {
                subType.forEach(rec => {
                    result.push(_.find(subtypeOptions, { 'value': rec }) ? _.find(subtypeOptions, { 'value': rec }).name : '')
                });
                return type === 'users' ? (
                    <div className={'user-avatar-tag-listing'}>{
                        result.filter(e => !_.isEmpty(e)).map((rec, index) => {
                            return <div className='user-avatar-tag-loop' key={index}><span>{rec}</span></div>;
                        })
                    }</div>
                ) : (<div className={'Vehicle-availability'} style={{ minWidth: 'auto' }}>{
                    result.filter(e => !_.isEmpty(e)).map((rec, index) => {
                        return <div key={index}><span>{rec}</span></div>;
                    })
                }</div>);
            }
            else {
                return _.find(subtypeOptions, { 'value': subType }) ? _.find(subtypeOptions, { 'value': subType }).name : ''
            }
        }

    }

    const handleonSubSelectChange = (e, data) => {
        if (data.name === 'pipeline') {
            setAddSubList({
                ...addSubList,
                [data.name]: e ? e.value : '',
                stage: '',
            })
        } else {
            setAddSubList({
                ...addSubList,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['sublist' + data.name]: ''
        });
    }
    const handleonEditSubSelectChange = (e, data) => {
        if (data.name === 'pipeline') {
            setEditSubList({
                ...editSubList,
                [data.name]: e ? e.value : '',
                stage: '',
            })
        } else {
            setEditSubList({
                ...editSubList,
                [data.name]: e ? e.value : ''
            })
        }
        setErrorFields({
            ...errorFields,
            ['editsublist' + data.name]: ''
        });
    }

    const handleEditSubList = (rec) => {
        setEditSubList(rec)
        setTexteditor({
            [rec.id]: true
        })
    }

    const handleCancelSubList = () => {
        setEditSubList(null)
        setTexteditor({})
    }

    const handleDeleteSubList = (rec) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                let deleteList = [...subList.filter(item => item.id !== rec.id)];
                //props.setRulesLoader({ [rec.id]: true })
                props.handleRulesChange(deleteList);
            }
        })
    }

    const handleCloneRule = (rec) => {
        setShowModel({
            show: true,
            title: 'Add Rule',
            state: {
                ...rec,
                id: uuid()
            },
            newRule: true
        })
    }

    const handleSublistSave = (rec, newlist) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (_.isEmpty(rec['stage']) || _.isEmpty(rec['stage'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['subliststage'] = errorClass;
            else
                errors['editsubliststage'] = errorClass;
        }
        if (_.isEmpty(rec['pipeline']) || _.isEmpty(rec['pipeline'].trim())) {
            formIsValid = false;
            if (newlist)
                errors['sublistpipeline'] = errorClass;
            else
                errors['editsublistpipeline'] = errorClass;
        }
        if (rec.default === true
            && props.data?.rules?.length
            && props.data.rules.some(a => a.id !== rec.id && a.time === rec.time && a.default === true)) {
            Swal.fire('Cannot set more then one default rule.', '', 'error')
            formIsValid = false;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        let addList = newlist ? [...subList, rec] : [
            ..._.map(subList, function (obj) {
                return obj.id === rec.id ? { ...rec } : { ...obj };
            })];
        props.setRulesLoader(true)
        props.handleRulesChange(addList);
        setAddSubList({ pipeline: '', stage: '', status: '', id: uuid() })
        setEditSubList(null)
        setTexteditor({})
    }

    useEffect(() => {
        if (props.rulesLoader === false) {
            setShowModel({})
            setWHModel({})
            setBtnLoader(false)
        }
    }, [props.rulesLoader])

    const generateStageOptions = _pipeline => {
        const selectedPipeline = props.dealersettings.client.tradeinPro.allPipelines.filter(rec => rec.value === _pipeline)[0]
        let stagesToReturn = !_.isEmpty(selectedPipeline) ? selectedPipeline.stages.map(stage => {
            return {
                active: true,
                label: stage.name ? stage.name : '',
                value: stage.value,
                name: stage.name ? stage.name : '',
            }
        }) : []

        if (!_.isEmpty(selectedPipeline) && selectedPipeline.value === 'LeadsBucket') {
            stagesToReturn = stagesToReturn.filter(stage => stage.value !== 'Converted' && stage.value !== 'InboundLead')
        }

        return stagesToReturn
    }

    const handleCheckedChange = (e) => {
        props.handleRulesChange(props.data?.rules, { [e.target.name]: e.target.checked });
    }

    const handleModelClose = (data) => {
        if (!_.isEmpty(data?.workingHours)) {
            let wh = {};
            Object.keys(data.workingHours).map(r => { return wh = { ...wh, [r]: [data.workingHours[r].from, data.workingHours[r].to] } })
            setBtnLoader(true)
            props.setRulesLoader(true)
            props.handleRulesChange(props.data?.rules, { workingHours: wh });
        }
        else {
            setWHModel({})
        }
    }

    return (
        <>
            <div className="settings-tab-wrap tabs">
                <div className="tabs-wraper">
                    <nav>
                        <Tabs defaultActiveKey={"unactionedLeadsTIP"} transition={false} className="nav-fill" onSelect={(val) => {
                            props.setAllocationTabKey(val);
                        }}>
                            <Tab eventKey={"unactionedLeadsTIP"} title={"Notify Unactioned Leads"}>
                                <div className="settings-terms-wrap form-style">
                                    <div className='form-row'>
                                        <div className='form-group mb-0  col-md-12'>
                                            <div className='w-100 d-block font-14'>
                                                <span className="switch switch-sm float-left">
                                                    <InputCheckBox
                                                        className="switch"
                                                        name={'enabled'}
                                                        checked={props.data.enabled === true ? true : false}
                                                        onChange={(e) => { props.handleCheckedChange(e) }}
                                                        id={'template-Enabled'} />
                                                    <label htmlFor={'template-Enabled'}>Enabled</label>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='form-group mb-0  col-md-12 mt-2 '>
                                            <div className='w-100 d-block font-14 ml-2 mt-2 mb-2'>
                                                <div className='form-row'>
                                                    <h5 className='settings-subhead'><Translate text={'After'} /></h5>
                                                    <div className='form-group col-md-1'>
                                                        <input
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder={''}
                                                            className={`form-control`}
                                                            name="time"
                                                            onChange={props.handleNumberChange}
                                                            value={props.data.time ? props.data.time : ''}
                                                            maxLength={2}
                                                        />
                                                    </div>
                                                    <h5 className='settings-subhead'><Translate text={'Minutes'} /></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group col-md-1 mt-2'>
                                            <label className='mt-1'><b> Recipients :</b> </label>
                                        </div>
                                        <div className="form-group  col-md-11 mt-2">
                                            <ReactMultiSelect
                                                options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false && (roleIds ? _.includes(roleIds, e.roleID) : true))
                                                    .map(rec => {
                                                        return {
                                                            value: rec.id,
                                                            searchlabel: rec.name,
                                                            active: _.isBoolean(rec.active) ? rec.active : true,
                                                            label: (
                                                                <div className='drop-image'>
                                                                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                    {rec.name}
                                                                </div>
                                                            ),
                                                        };
                                                    })}
                                                name={"recipients"}
                                                placeholder={`select recipients`}
                                                onChange={(option) => props.handleReactMultiSelectChange(option, 'recipients')}
                                                value={props.data.recipients}
                                                classNamePrefix={`basic-select`}
                                                isMulti={true}
                                                isSearchable={true}
                                            >
                                            </ReactMultiSelect>
                                        </div>
                                    </div>
                                    <div className="divider-line"></div>
                                    <div className="settings-tab-wrap tabs">
                                        <div className="tabs-wraper">
                                            <nav>
                                                <Tabs defaultActiveKey={"email"} transition={false} className="nav-fill">
                                                    <Tab eventKey={"email"} title={"Email Template"}>
                                                        <div className='form-row'>
                                                            <div className='form-group col-md-1'>
                                                                <label className='mt-1'><b> Subject :</b> </label>
                                                            </div>
                                                            <div className='form-group  col-md-11'>
                                                                <InputText
                                                                    autoComplete='off'
                                                                    placeholder={'subject'}
                                                                    className={`form-control`}
                                                                    name='subject'
                                                                    onChange={props.handleOnChange}
                                                                    value={props.data.subject}
                                                                    maxLength={250}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-12 mt-2">
                                                                <SummerEditor
                                                                    name={props.key}
                                                                    value={email}
                                                                    height={'350px'}
                                                                    handleChange={props.handleChange}
                                                                    emailType={'compose'}
                                                                    templateMentionsEnabled={true}
                                                                    otherMentions={mentions}
                                                                    customFonts={props?.dealersettings?.customFonts || []}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab eventKey={"sms"} title={"SMS Template"}>
                                                        <div className="form-group">
                                                            <MentionEditor
                                                                mentionsData={mentions.map(mention => {
                                                                    return {
                                                                        name: `${mention.value}`,
                                                                        id: mention.value,
                                                                        title: `${mention.name}`,
                                                                    };
                                                                })}
                                                                onMentionChange={props.onMentionChange}
                                                                onMentionSelect={props.onMentionSelect}
                                                                notes={props.data.smsTemplate && _.isEmpty(props.data.templateJSON) ? props.data.smsTemplate : !_.isEmpty(props.data.templateJSON) ? convertTemplateJSON(props.data.templateJSON) : ''}
                                                                focusOnMount={false}
                                                                isNoteEmpty={false}
                                                                teamMentions={null}
                                                                clearTeamMentions={() => { }}
                                                                clientUsers={[]}
                                                                ownerID={''}
                                                                showCustomSuggestions={true}
                                                                height={'400px'}
                                                            />
                                                        </div>
                                                    </Tab>
                                                </Tabs>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey={"allocationRules"} title={"Auto Allocation Rules"}>
                                {/* <div className='autoallocation-alert'>
                                    <div className="alert alert-info" role="alert">
                                        <div className="service-alert-flex">
                                            <div className="service-alert-flex-data pl-1">
                                                <h4>Note: </h4>
                                                <p>- The selected 'Enquiry ID' will be a primary enquiry.</p>
                                                <p>- The merge process cannot be reversed. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="settings-terms-wrap form-style">
                                    <div className="form-group mb-0">
                                        <div className="float-left font-14 ml-2 mt-1">
                                            <span className="switch switch-sm float-left">
                                                <InputCheckBox
                                                    className="switch"
                                                    name={'autoAllocate'}
                                                    checked={props?.data?.autoAllocate === true ? true : false}
                                                    onChange={(e) => { handleCheckedChange(e) }}
                                                    id={'template-autoAllocate'} />
                                                <label htmlFor={'template-autoAllocate'}>Enable Auto Allocate</label>
                                            </span>
                                        </div>
                                        <div className="float-right button-height-fix text-right" style={{ width: '250px' }}>

                                            <button type="button" className="btn btn-green mr-2" onClick={() => {
                                                let _workingHours = {};
                                                if (props?.data?.workingHours) {
                                                    Object.keys(props?.data?.workingHours).map(r => {
                                                        return _workingHours =
                                                        {
                                                            ..._workingHours,
                                                            [r]: {
                                                                from: props?.data?.workingHours[r][0],
                                                                to: props?.data?.workingHours[r][1]
                                                            }
                                                        }
                                                    })
                                                }
                                                setWHModel({
                                                    show: true,
                                                    title: 'Working Hours',
                                                    state: _workingHours
                                                })
                                            }}>
                                                <i className="bg-icons ico icon-time mr-2 mt-1"></i>Working Hours
                                            </button>
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                setShowModel({
                                                    show: true,
                                                    title: 'Add Rule',
                                                    state: null
                                                })
                                            }}>
                                                <Translate text={'add'} /></button>

                                        </div>
                                    </div>
                                    {/* <div className='form-group mb-0'>
                                        <div className='w-100 d-block font-14 ml-2 mt-3'>
                                            <span className="switch switch-sm float-left">
                                                <InputCheckBox
                                                    className="switch"
                                                    name={'afterWorkingHours'}
                                                    checked={props?.data?.afterWorkingHours === true ? true : false}
                                                    onChange={(e) => { handleCheckedChange(e) }}
                                                    id={'template-afterWorkingHours'} />
                                                <label htmlFor={'template-afterWorkingHours'}>After Working Hours Only</label>
                                            </span>
                                        </div>
                                    </div> */}
                                    {/* <div className="settings-head">Rules</div> */}

                                    <table className="table table-bordered mt-4">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'inboundSource'} /></th>
                                                {/* <th scope="col" className="head-light" width="10%"> <Translate text={'make'} /></th>
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'vehicleSaletype'} /></th> */}
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'origin'} /></th>
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'Pipeline'} /></th>
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'Stage'} /></th>
                                                <th scope="col" className="head-light" width="20%"> <Translate text={'Sales Persons'} /></th>
                                                <th scope="col" className="head-light" width="20%"> <Translate text={'Managers'} /></th>
                                                <th scope="col" className="head-light" width="10%"> <Translate text={'Time'} /></th>
                                                <th scope="col" className="head-light border-right-0" width="10%"> <Translate text={'Actions'} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <tr>
                                                <td><div className="">
                                                    <ReactMultiSelect
                                                        options={makes}
                                                        name={"make"}
                                                        placeholder={'select make'}
                                                        onChange={(option) => handleSubMultiSelectChange(option, 'make')}
                                                        value={addSubList.make ? addSubList.make : []}
                                                        classNamePrefix={`basic-select`}
                                                        id="sublistmake"
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactSelect
                                                        options={dynSalesTypes}
                                                        name={"saleType"}
                                                        placeholder={'select saleType'}
                                                        onChange={handleonSubSelectChange}
                                                        value={addSubList.saleType ? addSubList.saleType : ''}
                                                        classNamePrefix={`${errorFields["sublistsaleType"]} cursor-pointer basic-select`}
                                                        id="sublistsaleType"
                                                    >
                                                    </ReactSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactMultiSelect
                                                        options={dynOrigins}
                                                        name={"origin"}
                                                        placeholder={'select origin'}
                                                        onChange={(option) => handleSubMultiSelectChange(option, 'origin')}
                                                        value={addSubList.origin ? addSubList.origin : []}
                                                        classNamePrefix={`basic-select`}
                                                        id="sublistorigin"
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactSelect
                                                        options={props.dealersettings?.client?.settings?.allPipelines ? props.dealersettings?.client?.settings?.allPipelines.filter(a => a.value !== 'LeadsBucket').map(rec => {
                                                            return {
                                                                label: rec.name,
                                                                value: rec.value
                                                            }
                                                        }) : []}
                                                        name={"pipeline"}
                                                        placeholder={'select pipeline'}
                                                        onChange={handleonSubSelectChange}
                                                        value={addSubList.pipeline ? addSubList.pipeline : ''}
                                                        classNamePrefix={`${errorFields["sublistpipeline"]} cursor-pointer basic-select`}
                                                        id="sublistpipeline"
                                                    >
                                                    </ReactSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactSelect
                                                        options={generateStageOptions(addSubList.pipeline ? addSubList.pipeline : '')}
                                                        name={"stage"}
                                                        placeholder={'select stage'}
                                                        onChange={handleonSubSelectChange}
                                                        value={addSubList.stage}
                                                        classNamePrefix={`${errorFields["subliststage"]} cursor-pointer basic-select`}
                                                        id="subliststage"
                                                    >
                                                    </ReactSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactMultiSelect
                                                        options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false)
                                                            .map(rec => {
                                                                return {
                                                                    value: rec.id,
                                                                    searchlabel: rec.name,
                                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                                    label: (
                                                                        <div className='drop-image'>
                                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                            {rec.name}
                                                                        </div>
                                                                    ),
                                                                };
                                                            })}
                                                        name={"salesPersonIDs"}
                                                        placeholder={`select sales persons`}
                                                        onChange={(option) => handleSubMultiSelectChange(option, 'salesPersonIDs')}
                                                        value={addSubList.salesPersonIDs ? addSubList.salesPersonIDs : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div></td>
                                                <td><div className="">
                                                    <ReactMultiSelect
                                                        options={props.clientUsers.filter(e => Boolean(e.superAdmin) === false)
                                                            .map(rec => {
                                                                return {
                                                                    value: rec.id,
                                                                    searchlabel: rec.name,
                                                                    active: _.isBoolean(rec.active) ? rec.active : true,
                                                                    label: (
                                                                        <div className='drop-image'>
                                                                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                                                                            {rec.name}
                                                                        </div>
                                                                    ),
                                                                };
                                                            })}
                                                        name={"managerIDs"}
                                                        placeholder={`select managers`}
                                                        onChange={(option) => handleSubMultiSelectChange(option, 'managerIDs')}
                                                        value={addSubList.managerIDs ? addSubList.managerIDs : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div></td>
                                                <td className="border-right-0">
                                                    <div className="button-height-fix">
                                                        <button type="button" className="btn btn-primary" onClick={() => { handleSublistSave(addSubList, true) }}>
                                                            {
                                                                props.rulesLoader[addSubList.id] ? (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<Translate text={'add'} />)
                                                            }</button>
                                                    </div>
                                                </td>
                                            </tr> */}
                                            {
                                                props.data?.rules?.length > 0 ?
                                                    props.data.rules.map((rec, index) => {
                                                        return <tr key={index}>
                                                            <td>{getsubTypeName('systemType', rec.systemType)} </td>
                                                            {/* <td>{getsubTypeName('make', rec.make)} </td>
                                                            <td>{getsubTypeName('saleType', rec.saleType)} </td> */}
                                                            <td>{getsubTypeName('origin', rec.origin)} </td>
                                                            <td>{getsubTypeName('allPipelines', rec.pipeline)} </td>
                                                            <td>{getsubTypeName('stage', rec.stage, rec.pipeline)} </td>
                                                            <td>{getsubTypeName('users', rec.salesPersonIDs)} </td>
                                                            <td>{getsubTypeName('users', rec.managerIDs)} </td>
                                                            <td>{getsubTypeName('time', rec.time)} </td>
                                                            <td className="border-right-0">
                                                                <div className="action-fix-allocation">
                                                                    <div className="table-edit float-left mr-2" title='Add Rule'>
                                                                        <a href="#" className="mini-button" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowModel({
                                                                                show: true,
                                                                                title: 'Edit Rule',
                                                                                state: rec
                                                                            })
                                                                        }}><i className="ico icon-edit"></i></a></div>
                                                                    <div className="table-edit float-left mr-2" title='Delete Rule'>
                                                                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleDeleteSubList(rec) }}> <i className="ico icon-delete"></i></a>
                                                                    </div>
                                                                    <div className="table-edit float-left mr-2" title='Clone Rule'>
                                                                        <a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); handleCloneRule(rec) }}> <i className="ico icon-rearrange"></i></a>
                                                                    </div>
                                                                    {
                                                                        rec.default === true ? (
                                                                            <div className="badge badge-pill badge-active mt-1">Default</div>
                                                                        ) : (<></>)
                                                                    }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                    : (
                                                        <tr>
                                                            <td colSpan={11}>
                                                                <div className="text-center p-5">
                                                                    <p>{`no record found.`}</p>
                                                                    <button className="btn btn-sm btn-default" onClick={() => {
                                                                        setShowModel({
                                                                            show: true,
                                                                            title: 'Add Rule'
                                                                        })
                                                                    }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                                        {`Add Rule`}
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                        </Tabs>
                    </nav>
                </div>
            </div >
            <PopUpModal show={showModel.show}>
                <AddRules
                    {...props}
                    {...showModel}
                    handleClose={() => setShowModel({})}
                    makes={makes}
                    origins={dynOrigins}
                    integrations={dynIntegrations}
                    salesTypes={dynSalesTypes}
                    handleSublistSave={handleSublistSave}
                    errorFields={errorFields}
                />
            </PopUpModal>
            <PopUpModal show={whModel.show}>
                <WorkingHours
                    {...props}
                    {...whModel}
                    handleClose={handleModelClose}
                    btnLoader={btnLoader}
                    allMandatory={true}
                />
            </PopUpModal>
        </>
    );
}

export default LeadAlloction;