
import React from 'react'
import _ from 'lodash'
import moment from 'moment';

class ReportHelper {



    leadsCounts = (data, dates, year) => {
        let result = [];
        dates.forEach(rec => {
            result.push({
                ...rec,
                ...this.checkDataFilter(data.filter((a) => a.col === 'enq' && a.stgv !== 'Archived'), rec.startDate, rec.endDate)
            })
        })
        for (let i = 1; i <= result.length; i++) {
            const weekData = result[i - 1]
            result = result.map(rec => {
                return rec.name === weekData.name ? {
                    ...weekData,
                    average: i === 1 ? weekData.actual : parseFloat((_.sumBy(result.filter(a => a.weekNo <= i), 'actual') / i).toFixed(2))
                } : { ...rec }
            })
        }
        result = result.map(rec => {
            return {
                ...rec,
                actual: rec.weekNo <= (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) ? rec.actual : null,
                average: rec.weekNo <= (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) ? rec.average : null,
            }
        })
        return result;
    }

    testdriveCounts = (data, dates, year) => {
        let result = [];
        dates.forEach(rec => {
            result.push({
                ...rec,
                ...this.checkDataFilter(data.filter((a) => a.col === 'td' && a.stgv !== 'Archived'), rec.startDate, rec.endDate)
            })
        })
        for (let i = 1; i <= result.length; i++) {
            const weekData = result[i - 1]
            result = result.map(rec => {
                return rec.name === weekData.name ? {
                    ...weekData,
                    average: i === 1 ? weekData.actual : parseFloat((_.sumBy(result.filter(a => a.weekNo <= i), 'actual') / i).toFixed(2))
                } : { ...rec }
            })
        }
        result = result.map(rec => {
            return {
                ...rec,
                actual: rec.weekNo <= (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) ? rec.actual : null,
                average: rec.weekNo <= (year < moment().year() ? moment().year(year).isoWeeksInYear() : moment().week()) ? rec.average : null,
            }
        })
        return result;
    }

    wonCounts = (data, dates, year, objFilter, dealers, clientTarget) => {
        let result = [];
        dates.forEach(rec => {
            result.push({
                ...rec,
                ...this.checkDataFilter(data.filter((a) => a.col === 'enq' && a.stgv !== 'Archived'), rec.startDate, rec.endDate, 'sold', 'wd')
            })
        })
        result = result.map(rec => {
            const targetFilter = clientTarget.filter(a => objFilter?.clients?.length > 0 ? objFilter.clients.some(b => b === a.clientID) : dealers.some(b => b.value === a.clientID));
            const _clientForecast = _.sumBy(targetFilter, `${year}.${rec.name}.wonForecast`)
            const _clientTarget = _.sum(targetFilter.filter(a => a.type === 'dealer').map(r => { return _.sumBy(r?.[year]?.[rec.name]?.filter(a => a.calculationType === "count" && a.type === 'status' && a.subType.includes('won')), 'target') }))
            return {
                ...rec,
                actual: rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? rec.actual : null,
                forecast: _clientForecast,//rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? null : _clientForecast,
                wonTarget: _clientTarget,//rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? _clientTarget : null,
            }
        })
        return result;
    }

    deliveredCounts = (data, dates, year, objFilter, dealers, clientTarget) => {
        let result = [];
        dates.forEach(rec => {
            result.push({
                ...rec,
                ...this.checkDataFilter(data.filter((a) => a.col === 'enq' && a.stgv !== 'Archived'), rec.startDate, rec.endDate, 'delivered', 'dd')
            })
        })
        result = result.map(rec => {
            const targetFilter = clientTarget.filter(a => objFilter?.clients?.length > 0 ? objFilter.clients.some(b => b === a.clientID) : dealers.some(b => b.value === a.clientID));
            const _clientForecast = _.sumBy(targetFilter, `${year}.${rec.name}.deliveredForecast`);
            //const _clientTarget = _.sumBy(targetFilter, `${year}.${rec.name}.deliveredTarget`);
            const _clientTarget = _.sum(targetFilter.filter(a => a.type === 'dealer').map(r => { return _.sumBy(r?.[year]?.[rec.name]?.filter(a => a.calculationType === "count" && a.type === 'status' && a.subType.includes('delivered')), 'target') }))
            return {
                ...rec,
                retailedActual: rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? rec.actual : null,
                forecast: _clientForecast,//rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? null : _clientForecast,
                target: _clientTarget,
            }
        })
        result = result.map((rec, i) => {
            return {
                ...rec,
                cummulativeTarget: _.sumBy(result.filter(a => a.monthNo <= i), 'target'),
                cummulativeActual: rec.monthNo <= (year < moment().year() ? 11 : moment().month()) ? _.sumBy(result.filter(a => a.monthNo <= i), 'retailedActual') : null,
            }
        })
        return result;
    }

    getAllWeeks = (props, year) => {
        var dates = [];
        moment.updateLocale('en', {
            week: {
                dow: moment().day(props?.dealersettings?.client?.weekStart ? props.dealersettings.client.weekStart : 1).day(),
            },
        })
        for (let i = 1; i <= moment().year(year).isoWeeksInYear(); i++) {
            dates.push({
                weekNo: i,
                name: `WK ${i}`,
                startDate: moment().week(i).startOf('week').startOf('day').unix(),
                endDate: moment().week(i).endOf('week').endOf('day').unix()
            })
        }
        return dates;
    }

    getAllMonths = () => {
        var dates = [];
        for (let i = 0; i < 12; i++) {
            dates.push({
                monthNo: i,
                name: moment().month(i).format('MMM'),
                startDate: moment().month(i).startOf('month').startOf('day').unix(),
                endDate: moment().month(i).endOf('month').endOf('day').unix()
            })
        }
        return dates;
    }

    checkDataFilter = (_data, startDt, endDt, status, dateType, newlead, saleType) => {
        const filterData = status === 'sold' || status === 'delivered' ? _.map(_data.filter((a) =>
            ((status === 'sold') ? (a.sts && (a.sts.toLowerCase() === 'won')) : true)
            && ((status === 'delivered') ? (a.sts && a.sts.toLowerCase() === 'delivered') : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        )) : _.map(_data.filter((a) =>
            ((status === 'lost') ? (a.sts && (a.sts.toLowerCase() === 'lost' || a.sts.toLowerCase() === 'prospectlost' || a.sts.toLowerCase() === 'pendinglost')) : true)
            && ((saleType === 'new') ? (a.st && a.st.toLowerCase() !== 'preowned') : true)
            && ((saleType === 'used') ? (a.st && a.st.toLowerCase() === 'preowned') : true)
            && ((dateType && newlead) ?
                (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt && a.dt >= startDt && a.dt <= endDt)
                : dateType ? (a[dateType] && a[dateType] >= startDt && a[dateType] <= endDt)
                    : a.dt >= startDt && a.dt <= endDt)
        ));
        return {
            arractual: _.uniqBy(filterData, 'eid').map(r => { return r.eid }),
            actual: status === 'sold' || status === 'delivered' ? _.sumBy(_.uniqBy(filterData, 'eid'), 'wc') : _.uniqBy(filterData, 'eid').length
        }
    }
}

export default new ReportHelper;