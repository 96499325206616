export const objTradeIP = {
    documentID: null,
    clientID: null,
    projectId: null,
    contactID: null,
    displayID: null,
    tradeinID: null,

    pipeline: '',
    stage: '',
    status: '',

    offer: null,

    contact: null,
    tradein: null,
    stageDate: null,

    isContact: false,
    isTradein: false,
    isFiles: false,
    isFav: false,
    isNewTradein: false,

    completedDate: null,
    wonDate: null,
    lostDate: null,
    origin: '',
    lostReason: '',
    lostSubReason: '',
    lostNotes: '',
    finalPrice: 0,
    valuerPrice: 0,

    finalizedBy: '',
    finalizedDate: null,

    dynamicFields: {},
    stageHistory: [],
    tags: [],
    ownerSales: '',
    owner: '',
    addedBy: '',
    modifiedBy: '',
    addedDate: null,
    modifiedDate: null,
    modifiedFrom: 'web',
    addedFrom: 'web',
    isDeleted: false,
};

export const objBasicTradeInVM = {
    documentID: null,
    clientID: null,
    projectId: null,
    stockID: null,
    evalexpertID: null,
    tradeinProID: null,
    autograbID: null,
    enquiryID: null,
    enquiry: null,
    tradeinPro: null,
    make: '',
    model: '',
    year: '',
    modelDescription: '',
    saleType: '',
    notes: '',
    regNo: '',
    regDate: null,
    chassisNo: '',
    doors: '',
    exteriorColor: '',
    interiorColor: '',
    mileage: '',
    financed: false,
    status: '',
    sent: false,
    linkedAppraisal: false,
    offerPrice: 0,
    valuerPrice: 0,
    linkStatus: '',
    linkURL: '',
    images: [],
    marks: [],
    addedBy: '',
    addedDate: null,
    isDeleted: false
};

export const tipBasicDetailsVM = {
    documentID: null,
    displayID: null,
    clientID: null,
    projectId: null,
    contactID: null,
    tradeinID: null,
    pipeline: '',
    stage: '',
    status: '',
    tradein: {},
    contact: {},
    stageDate: null,
    owner: '',
    addedBy: '',
    addedDate: null,
};

export const objTradeIPOffers = {
    documentID: null,
    clientID: null,
    projectId: null,
    wholeSellerId: null,
    tradeinProID: null,
    tradeinPro: null,

    status: '', //'pending,completed'
    evaluationPrice: 0,
    dealerPrice: 0,
    previousWSPrice: null,

    isSelfEvaluation: false,
    isSelected: false,
    isApproved: false,
    isReRequested: false,

    addedBy: '',
    modifiedBy: '',
    evaluatedBy: '',
    selectedBy: '',
    approvedBy: '',
    onBehalfUserID: '',

    addedDate: null,
    modifiedDate: null,
    evaluationDate: null,
    selectedDate: null,
    approvedDate: null,

    currency: null,

    modifiedFrom: 'web',
    addedFrom: 'web',
    evaluationFrom: 'web',
    selectedFrom: '',
    approvedFrom: '',

    isDeleted: false,
};

export const objOfferNotesVM = {
    documentID: null,
    tradeinProID: null,
    offerID: null,
    notes: '',
    isInternalNotes: false,
    wholeSalerNotes: false,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};

export const objSRVehicle = {
    make: '',
    type: '',
    group: '',
    model: '',
    year: '',
    odometer: '',
    vinNo: '',
    regNo: '',
    rego: '',
    extColor: '',
    warrantyStartDate: null,
    warrantyExp: null,
}
export const mandatoryVehicleFields = [
    'make',
    'model'
];


export const objModules = {
    contact: true,
    files: true,
    vehicle: true,
    packages: true,

};

export const objMark = {
    dx: 0,
    dy: 0,
    markType: '',
    notes: '',
    price: 0,
    currency: '',
    images: []
};

export const mandatoryMark = [
    'dx',
    'dy',
    'markType'
];


export const objVideos = {
    documentID: null,
    clientID: null,
    projectId: null,
    recordID: null,
    name: '',
    url: '',
    type: '',
    size: 0,
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false
};


export const mandatoryVideo = ['url']


export const allTIPFields = [
    // {
    //     name: 'Checkbox',
    //     value: 'checkbox',
    //     subText: 'documentID',
    //     width: 40,
    //     default: true,
    //     type: 'checkbox',
    //     flex: 0,
    //     fixed: true
    // },
    {
        name: 'Favourite',
        value: 'documentID',
        subText: 'isFav',
        width: 80,
        default: true,
        type: 'favorite',
        flex: 0,
        align: 'center'        
    },
    {
        name: 'Valuation Number',
        value: 'displayID',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
        sortValue: 'displayID'
    },
    {
        name: 'Name',
        value: 'contactName',
        flex: 2,
        default: true,
        subText: 'contactNumber',
        subTextTitle: 'Contact ID',
        sortValue: 'contact."firstName"'
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true,
        sortValue: 'contact."email"'
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone',
        sortValue: 'contact."phone"'
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleYear',
        subTextTitle: 'Vehicle Year',
        sortValue: 'vehicleModel'

    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName',
        sortValue: 'status.name'
    },
    {
        name: 'Owner',
        value: 'owner',
        flex: 1,
        default: true,
        sortValue: 'owner.name'
    },
    {
        name: 'Valuer Price',
        value: 'valuerPrice',
        flex: 1,
        default: true,
        sortValue: 'valuerPrice'
    },
    {
        name: 'Final Price',
        value: 'offerPrice',
        flex: 1,
        default: true,
        sortValue: 'finalPrice'
    },
    {
        name: 'Stage Date',
        value: 'stageDate',
        flex: 1,
        sortValue: 'stageDate'
    },
    {
        name: 'Created On',
        value: 'createdOn',
        flex: 1,
        subText: 'addedBy',
        subTextTitle: 'Created By',
        sortValue: 'addedDate'
    },
    {
        name: 'Last Updated On',
        value: 'updatedOn',
        flex: 1,
        subText: 'modifiedBy',
        subTextTitle: 'Last Updated By',
        sortValue: 'modifiedDate'
    },

    {
        name: 'Pipeline',
        value: 'pipeline',
        flex: 1,
        sortValue: 'pipeline.name'
    },
    {
        name: 'Stage',
        value: 'stage',
        flex: 1,
        sortValue: 'stage.name'
    },
    {
        name: 'Completed Date',
        value: 'completedDate',
        flex: 1,
        sortValue: 'completedDate'
    },
    {
        name: 'Won Date',
        value: 'wonDate',
        flex: 1,
        sortValue: 'wonDate'
    },
    {
        name: 'Lost Date',
        value: 'lostDate',
        flex: 1,
        sortValue: 'lostDate'
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1,
        sortValue: 'origin.name'
    },
    {
        name: 'Lost Reason',
        value: 'lostReason',
        flex: 1,
        sortValue: 'lostReason.name'
    },
    {
        name: 'Lost Notes',
        value: 'lostNotes',
        flex: 1,
        sortValue: 'lostNotes'
    },
    {
        name: 'Lost Sub Reason',
        value: 'lostSubReason',
        flex: 1,
        sortValue: 'lostSubReason.name'
    },
    {
        name: 'Vehicle VIN No.',
        value: 'vehicleVinNo',
        flex: 1,
        sortValue: 'chassisNo'
    },
    {
        name: 'Vehicle Reg No.',
        value: 'vehicleRegNo',
        flex: 1,
        sortValue: 'regNo'
    },
    {
        name: 'License Expiry',
        value: 'licenseExpiry',
        flex: 1,
        sortValue: 'contact."licenseExpiry"'
    },
    {
        name: 'License State',
        value: 'licenseState',
        flex: 1,
    },
    {
        name: 'License Type',
        value: 'licenseType',
        flex: 1,
    },
    {
        name: 'Address',
        value: 'address',
        flex: 2,
        sortValue: 'contact."address"'
    },
    {
        name: 'Area Code',
        value: 'areaCode',
        flex: 1,
        sortValue: 'contact."areaCode"'
    },
    {
        name: 'Company',
        value: 'companyName',
        flex: 1,
    },
    {
        name: 'Designation',
        value: 'designation',
        flex: 1,
        sortValue: 'contact."designation"'
    },
    {
        name: 'Birthday',
        value: 'dob',
        flex: 1,
        sortValue: 'contact."dob"'
    },
    {
        name: 'Gender',
        value: 'gender',
        flex: 1,
        sortValue: 'contact."gender"'
    },
    {
        name: 'Contact Campaign',
        value: 'contactcampaign',
        flex: 1
    },
    {
        name: 'Contact Origin',
        value: 'contactorigin',
        flex: 1
    },
    {
        name: 'Marital Status',
        value: 'maritalStatus',
        flex: 1
    },
    {
        name: 'Nationality',
        value: 'nationality',
        flex: 1
    },
    {
        name: 'Language',
        value: 'language',
        flex: 1
    },
    {
        name: 'Contact Type',
        value: 'contactType',
        flex: 1
    },
    {
        name: 'Contact Method',
        value: 'contactMethod',
        flex: 1
    },
    {
        name: 'Hobbies',
        value: 'interests',
        flex: 1
    },
    {
        name: 'Dependents',
        value: 'dependents',
        flex: 1
    },
    {
        name: 'Driver Name',
        value: 'driverName',
        flex: 1
    },
    {
        name: 'Phone Opt In',
        value: 'optinPhone',
        flex: 1
    },
    {
        name: 'SMS Opt In',
        value: 'optinSMS',
        flex: 1
    },
    {
        name: 'Email Opt In',
        value: 'optinEmail',
        flex: 1
    },
    {
        name: 'Post Opt In',
        value: 'optinPost',
        flex: 1
    },
    {
        name: 'Marketing Email Opt In',
        value: 'marketingOptinEmail',
        flex: 1,
    },
    {
        name: 'Marketing SMS Opt In',
        value: 'marketingOptinSMS',
        flex: 1,
    },
    {
        name: 'Secondary Name',
        value: 'secondaryName',
        flex: 1
    },
    {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        flex: 1
    },
    {
        name: 'Secondary Email',
        value: 'secondaryEmail',
        flex: 1
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allCancelledFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        group: 'Contact',
        default: true,
        subText: 'displayID',
        subTextTitle: 'Contact ID',
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Contact Email',
        value: 'contactEmail',
        flex: 1,
        default: true
    },
    {
        name: 'Vehicle Model',
        value: 'vehicleModel',
        flex: 1,
        default: true,
        subText: 'vehicleYear',
        subTextTitle: 'Vehicle Year'

    },
    {
        name: 'Appointment Date',
        value: 'appointmentDate',
        flex: 1,
        default: true
    },
    {
        name: 'Created By',
        value: 'addedByName',
        flex: 1,
        default: true,
        subText: 'createdOn'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 80,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]




