/** LIBRARIES */
import React from 'react';
import moment from 'moment';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../components/customdropdown';
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common'

const ActivityView = React.memo(({ title, activityDate, id, type, addedBy, handleEditactivity, handleComplete, completed, activitytag, handleDeleteactivity, activitylog, dealersettings, readOnlyView, originType }) => {
    // const [taskDone,setTaskdone] = useState(false) 
    // useEffect(()=>{
    //    setTaskdone(activitylog.isDone)
    // },[id])
    return (
        (!_.isEmpty(dealersettings))
            ?
            <>
                <div className="quickview-activity-loop" id={`activity-${id}`}>
                    {
                        (!readOnlyView && activitylog.clientID === dealersettings.client.id) ? (
                            <Dropdown className="quickview-activity-more" alignRight>
                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                    <i className="ico icon-more"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">

                                    {
                                        ((!_.isEmpty(activitylog.searchUsers) && activitylog.searchUsers.indexOf(dealersettings.id) >= 0) || (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                            ?
                                            <Dropdown.Item eventKey="1" onClick={(e) => { handleEditactivity(e, activitylog) }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
                                            :
                                            <Dropdown.Item eventKey="1" onClick={(e) => { handleEditactivity(e, activitylog) }}><i className="ico icon-zoom"></i>  <Translate text={'view'} /></Dropdown.Item>
                                    }
                                    {
                                        (!_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.deleteActivity)
                                            ?
                                            <Dropdown.Item eventKey="2" onClick={(e) => { handleDeleteactivity(e, activitylog) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                            :
                                            <></>
                                    }

                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (<></>)
                    }
                    <div className="quickview-activity-checkbox">
                        {
                            !readOnlyView && !activitylog.isDone ? (<div className="checkbox icheck-success">
                                {
                                    ((activitylog.clientID === dealersettings.client.id && !_.isEmpty(activitylog.searchUsers) && activitylog.searchUsers.indexOf(dealersettings.id) >= 0) ||
                                        (activitylog.clientID === dealersettings.client.id && !_.isEmpty(dealersettings.rolePermissions) && !_.isEmpty(dealersettings.rolePermissions.permissions) && dealersettings.rolePermissions.permissions.modifyOtherActivity))
                                        ?
                                        <input type="checkbox"
                                            id={`success-${id}`}
                                            className="uncheck-activity"
                                            title={completed ? 'mark as not done' : 'mark as done'}
                                            checked={activitylog.isDone}
                                            onChange={(e) => {
                                                // setTaskdone(e.target.checked)
                                                handleComplete(e, activitylog, activitytag)
                                                // if (e.target.checked) {
                                                //     handleComplete(e, activitylog)
                                                // }
                                            }} />
                                        :
                                        <input type="checkbox"
                                            id={`success-${id}`}
                                            disabled={true}
                                        />

                                }

                                <label htmlFor={`success-${id}`}></label>
                            </div>) : (<></>)
                        }


                    </div>
                    <div className="quickview-activity-list">
                        <div className="quickview-activity-title">
                            <div className="quickview-activity-icon">
                                <i className={`ico ${CommonHelper.activityIconByValue(dealersettings, type)}`}></i>
                            </div>
                            {
                                readOnlyView
                                    ?
                                    <>{title}</>
                                    :
                                    <><a href="#" onClick={(e) => { e.preventDefault(); handleEditactivity(e, activitylog) }}>{title}</a></>
                            }

                        </div>
                        {
                            (!_.isEmpty(activitylog) &&
                                activitylog.type === CommonHelper.getActivityType('activity', originType) &&
                                activitylog.isDone === true &&
                                activitylog.subType && activitylog.subType === 'call' &&
                                activitylog.activityOutcome &&
                                dealersettings &&
                                dealersettings.client &&
                                dealersettings.client.settings &&
                                !_.isEmpty(dealersettings.client.settings.activityOutcome) &&
                                dealersettings.client.settings.activityOutcome.filter(a => a.value === activitylog.activityOutcome).length
                            ) ? (
                                <span className="activity-outcome"><strong>Activity Outcome: </strong>{dealersettings.client.settings.activityOutcome.filter(a => a.value === activitylog.activityOutcome)[0].name}</span>
                            ) : (<></>)
                        }
                        <div className="quickview-activity-date">

                            <span className={`${activitytag === 'Overdue' ? 'activity-due' : activitytag === 'Today' ? 'activity-upcoming' : ''}`}>
                                {
                                    activitytag === 'Overdue'
                                        ? (moment().diff(moment.unix(activityDate.seconds), 'days') === 1 ? '1 day ago' : moment().to(moment.unix(activityDate.seconds)))
                                        : moment.unix(activityDate.seconds).format('DD/MM/YYYY h:mm A')
                                }
                            </span> - {addedBy}</div>
                        {
                            activitylog.clientID && (activitylog.clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem') && dealersettings.group ?
                                (<span className="tbl-sub-dealer">{CommonHelper.getOtherDealerName(dealersettings, activitylog.clientID)}</span>)
                                : (<></>)
                        }
                        {
                            (activitylog.type === CommonHelper.getActivityType('activity', originType) && !_.isEmpty(activitylog.notes) && activitylog.notes.trim().length > 0) ?
                                (<div className="quickview-activity-notes-inner text-area-space">
                                    <div dangerouslySetInnerHTML={{
                                        __html: CommonHelper.stringtoURL(activitylog.notes),
                                    }}
                                    ></div>
                                </div>) :
                                (<></>)
                        }
                    </div>
                </div>
            </>
            :
            <></>

    )
})
export default ActivityView;

