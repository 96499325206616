import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';

import { default as _images } from '../../images';

import { convertMilisecondsTimeStampToDate } from '../../services/helper';
import CommonHelper from '../../services/common';
import { MainContext } from '../layout/provider';
import Translate from '../../constants/translate';

const ChatMessageItem = ({ contactChat, clientUser, messageItem, sidepanelClose, clientUsers }) => {
	// determine message position based on current user
	let messagePosition =
		messageItem.idFrom === localStorage.uid
			? 'chatApp__convMessageItem--right'
			: 'chatApp__convMessageItem--left';

	const type = messageItem.type;

	let contactAvatar =
		contactChat &&
			!isEmpty(clientUser.participantInfo) &&
			clientUser.participantInfo.filter(
				participant => participant.isAMSME === true && participant.id === messageItem.idFrom
			).length
			? clientUser.participantInfo.filter(
				participant => participant.isAMSME === true && participant.id === messageItem.idFrom
			)[0].avatar
			: '';

	return type === 'text' ? (
		<TextMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			clientUsers={clientUsers}
			contactChat={contactChat}
			contactAvatar={contactAvatar}
		/>
	) : type === 'enquiry' ? (
		<EnquiryMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			sidepanelClose={sidepanelClose}
			clientUsers={clientUsers}
			contactChat={contactChat}
			contactAvatar={contactAvatar}
		/>
	) : type === 'service' ? (
		<ServiceMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			sidepanelClose={sidepanelClose}
			clientUsers={clientUsers}
		/>
	) : type === 'tradeinPro' ? (
		<TradeinProMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			sidepanelClose={sidepanelClose}
			clientUsers={clientUsers}
		/>
	) : type === 'contact' ? (
		<ContactMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			sidepanelClose={sidepanelClose}
			clientUsers={clientUsers}
			contactChat={contactChat}
			contactAvatar={contactAvatar}
		/>
	) : type === 'image' ? (
		<ImageMessage messagePosition={messagePosition} messageItem={messageItem} clientUsers={clientUsers} contactAvatar={contactAvatar} />
	) : type === 'file' ? (
		<DocumentMessage
			messagePosition={messagePosition}
			messageItem={messageItem}
			clientUsers={clientUsers}
			contactChat={contactChat}
			contactAvatar={contactAvatar}
		/>
	) : null;
};

const TextMessage = ({ contactChat, contactAvatar, messagePosition, messageItem, clientUsers }) => {
	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={contactChat && contactAvatar ? contactAvatar : messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				// src={__images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>
			<div
				className='chatApp__convMessageValue'
			// dangerouslySetInnerHTML={{ __html: message }}
			>
				{messageItem.content}
			</div>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const EnquiryMessage = ({ contactChat, contactAvatar, messagePosition, messageItem, sidepanelClose, clientUsers }) => {
	const { content } = messageItem;
	const history = useHistory()
	const { currentClient, handleEnquirySwitchDealer, dealersettings } = useContext(MainContext);

	const data = {
		make: content.requirement?.make,
		model: content.requirement?.model,
		firstName: content.contact?.firstName,
		middleName: content.contact?.middleName || '',
		lastName: content.contact?.lastName,
		enquiryDocumentID: content.documentID,
		contactDocumentID: content.contact.documentID,
	};

	const checkEnquiryClient = async (e) => {
		e.preventDefault();
		// console.log('checkEnquiryClient', currentClient)

		let messageClientID;

		if (content.clientID) {
			messageClientID = content.clientID
		} else {
			messageClientID = await getClientID(content.documentID, 'enquiries')
		}

		if (currentClient === messageClientID) {
			history.push(`/enquiry/details/${content.documentID}`);
			sidepanelClose();
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to switch ' + dealersettings.clients.filter(client => client.id === messageClientID)[0].name + '?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					sidepanelClose();
					handleEnquirySwitchDealer(content.documentID, 'enquiry', messageClientID);
				}
			})
		}
	}

	const getClientID = async (docID, path) => {
		const ref = window.firebase
			.firestore()
			.collection(path)
			.doc(docID)

		const snap = await ref.get()
		const { clientID } = snap.data()
		return clientID
	}

	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={contactChat && contactAvatar ? contactAvatar : messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>
			<a
				className='chatApp__convMessageValue custom-share'
				onClick={e => checkEnquiryClient(e)}
			>
				<div className='custom-share-panel'>
					<div className='custom-share-panel-header'>
						<div className='float-left'><Translate text={'Enquiry'} /></div>
						<div className=' float-right'>
							<i className='ico icon-enquiry'></i>
						</div>
					</div>
					<div className='custom-share-panel-middle'>
						{data.make && data.model && (
							<div className='custom-share-panel-text'>
								{' '}
								{`${data.make} ${data.model}`}{' '}
							</div>
						)}
						{data.firstName && data.lastName && (
							<div className='custom-share-panel-subtext'>
								{' '}
								{CommonHelper.displayContactIcon(data, 'mr-1')}
								{CommonHelper.displayContact([], data, '--')}
							</div>
						)}
					</div>
				</div>
			</a>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const ServiceMessage = ({ messagePosition, messageItem, sidepanelClose, clientUsers }) => {
	const { content } = messageItem;
	const history = useHistory()
	const { currentClient, handleServiceSwitchDealer, dealersettings } = useContext(MainContext);

	const data = {
		make: !isEmpty(content.vehicle) && !isEmpty(content.vehicle.make) ? content.vehicle.make : '',
		model: !isEmpty(content.vehicle) && !isEmpty(content.vehicle.model) ? content.vehicle.model : '',
		firstName: content.contact?.firstName,
		middleName: content.contact?.middleName || '',
		lastName: content.contact?.lastName,
		serviceDocumentID: content.documentID,
		contactDocumentID: !isEmpty(content.contact) && !isEmpty(content.contact.documentID) ? content.contact.documentID : '',
	};

	const checkServiceClient = async (e) => {
		e.preventDefault();
		// console.log('checkServiceClient', currentClient)

		let messageClientID;

		if (content.clientID) {
			messageClientID = content.clientID
		} else {
			messageClientID = await getClientID(content.documentID, 'serviceJobs')
		}

		if (currentClient === messageClientID) {
			history.push(`/service/details/${content.documentID}`);
			sidepanelClose();
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to switch ' + dealersettings.clients.filter(client => client.id === messageClientID)[0].name + '?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					sidepanelClose();
					handleServiceSwitchDealer(content.documentID, 'service', messageClientID);
				}
			})
		}
	}

	const getClientID = async (docID, path) => {
		const ref = window.firebase
			.firestore()
			.collection(path)
			.doc(docID)

		const snap = await ref.get()
		const { clientID } = snap.data()
		return clientID
	}

	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>
			<a
				className='chatApp__convMessageValue custom-share'
				onClick={e => checkServiceClient(e)}
			>
				<div className='custom-share-panel'>
					<div className='custom-share-panel-header'>
						<div className='float-left'><Translate text={'Service'} /></div>
						<div className=' float-right'>
							<i className='ico icon-service-settings'></i>
						</div>
					</div>
					<div className='custom-share-panel-middle'>
						{data.make && data.model && (
							<div className='custom-share-panel-text'>
								{' '}
								{`${data.make} ${data.model}`}{' '}
							</div>
						)}
						{data.firstName && data.lastName && (
							<div className='custom-share-panel-subtext'>
								{' '}
								{CommonHelper.displayContactIcon(data, 'mr-1')}
								{CommonHelper.displayContact([], data, '--')}
							</div>
						)}
					</div>
				</div>
			</a>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const TradeinProMessage = ({ messagePosition, messageItem, sidepanelClose, clientUsers }) => {
	const { content } = messageItem;
	const history = useHistory()
	const { currentClient, handleTradeinProSwitchDealer, dealersettings } = useContext(MainContext);

	const data = {
		make: !isEmpty(content.tradein) && !isEmpty(content.tradein.make) ? content.tradein.make : '',
		model: !isEmpty(content.tradein) && !isEmpty(content.tradein.model) ? content.tradein.model : '',
		firstName: content.contact?.firstName,
		middleName: content.contact?.middleName || '',
		lastName: content.contact?.lastName,
		serviceDocumentID: content.documentID,
		contactDocumentID: !isEmpty(content.contact) && !isEmpty(content.contact.documentID) ? content.contact.documentID : '',
	};

	const checkTradeinProClient = async (e) => {
		e.preventDefault();
		// console.log('checkTradeinProClient', currentClient)

		let messageClientID;

		if (content.clientID) {
			messageClientID = content.clientID
		} else {
			messageClientID = await getClientID(content.documentID, 'tradeinPro')
		}

		if (currentClient === messageClientID) {
			history.push(`/tradeinpro/details/${content.documentID}`);
			sidepanelClose();
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to switch ' + dealersettings.clients.filter(client => client.id === messageClientID)[0].name + '?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					sidepanelClose();
					handleTradeinProSwitchDealer(content.documentID, 'tradeinPro', messageClientID);
				}
			})
		}
	}

	const getClientID = async (docID, path) => {
		const ref = window.firebase
			.firestore()
			.collection(path)
			.doc(docID)

		const snap = await ref.get()
		const { clientID } = snap.data()
		return clientID
	}

	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>
			<a
				className='chatApp__convMessageValue custom-share'
				onClick={e => checkTradeinProClient(e)}
			>
				<div className='custom-share-panel'>
					<div className='custom-share-panel-header'>
						<div className='float-left'><Translate text={'appraisal'} /></div>
						<div className=' float-right'>
							<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-tradein-module-icon')}></i>
						</div>
					</div>
					<div className='custom-share-panel-middle'>
						{data.make && data.model && (
							<div className='custom-share-panel-text'>
								{' '}
								{`${data.make} ${data.model}`}{' '}
							</div>
						)}
						{data.firstName && data.lastName && (
							<div className='custom-share-panel-subtext'>
								{' '}
								{CommonHelper.displayContactIcon(data, 'mr-1')}
								{CommonHelper.displayContact([], data, '--')}
							</div>
						)}
					</div>
				</div>
			</a>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const ContactMessage = ({ contactChat, contactAvatar, messagePosition, messageItem, sidepanelClose, clientUsers }) => {
	const { content } = messageItem;
	const history = useHistory()
	const { currentClient, handleEnquirySwitchDealer, dealersettings } = useContext(MainContext);

	const data = {
		firstName: content.firstName,
		middleName: content.middleName || '',
		lastName: content.lastName,
		phone: content.phone ? content.phone : content.email,
		contactDocumentID: content.documentID,
	};

	const checkContactClient = async (e) => {
		e.preventDefault();
		// console.log('checkContactClient', currentClient)

		let messageClientID;

		if (content.clientID) {
			messageClientID = content.clientID
		} else {
			messageClientID = await getClientID(content.documentID, 'contactDetails')
		}

		if (currentClient === messageClientID) {
			history.push(`/contacts/details/${content.documentID}`);
			sidepanelClose();
		} else {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to switch ' + dealersettings.clients.filter(client => client.id === messageClientID)[0].name + '?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.value) {
					sidepanelClose();
					handleEnquirySwitchDealer(content.documentID, 'contact', messageClientID);
				}
			})
		}
	}

	const getClientID = async (docID, path) => {
		const ref = window.firebase
			.firestore()
			.collection(path)
			.doc(docID)

		const snap = await ref.get()
		// console.log('data of contact', snap.data())
		const { clientID } = snap.data()
		return clientID
	}

	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={contactChat && contactAvatar ? contactAvatar : messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>

			<a
				to={`/contacts/details/${data.contactDocumentID}`}
				className='chatApp__convMessageValue custom-share'
				onClick={e => checkContactClient(e)}
			>
				<div className='custom-share-panel'>
					<div className='custom-share-panel-header'>
						<div className='float-left'><Translate text={'contact'} /></div>
						<div className=' float-right'>
							<i className='ico icon-Contacts'></i>
						</div>
					</div>
					<div className='custom-share-panel-middle'>
						<div className='custom-share-panel-text'>
							{CommonHelper.displayContactIcon(data, 'mr-1')}
							{CommonHelper.displayContact([], data, '--')}
						</div>
						<div className='custom-share-panel-subtext'>
							{data.phone && data.phone}
						</div>
					</div>
				</div>
			</a>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const ImageMessage = ({ contactChat, contactAvatar, messagePosition, messageItem, clientUsers }) => {
	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={contactChat && contactAvatar ? contactAvatar : messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>


			<div className='chatApp__convMessageValue  custom-attachment'>
				<div className='chat-image-attach'>
					<a data-fancybox={`messageImage-${messageItem.timestamp}`} href={messageItem.content} onClick={(e) => { e.preventDefault(); }}>
						<img src={messageItem.content} alt='message' loading='lazy' />
					</a>
				</div>
			</div>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

const DocumentMessage = ({ contactChat, contactAvatar, messagePosition, messageItem, clientUsers }) => {
	const getIconClass = fileExt =>
		fileExt === 'docx' || fileExt === 'doc'
			? 'icon-files-word'
			: fileExt === 'xlsx' || fileExt === 'xls'
				? 'icon-files-excel'
				: fileExt === 'pdf'
					? 'icon-files-pdf'
					: 'icon-files-document';

	return (
		<div
			className={'chatApp__convMessageItem ' + messagePosition + ' clearfix'}
		>
			<img

				src={contactChat && contactAvatar ? contactAvatar : messageItem.idFrom ? CommonHelper.getUserImagebyId(clientUsers, messageItem.idFrom) : _images.nouser}
				alt={messageItem.nameFrom}
				className='chatApp__convMessageAvatar img-object-fit'
			/>

			<a
				href={messageItem.content.documentURL}
				target='_blank'
				rel='noopener noreferrer'
			>
				<div className='chatApp__convMessageValue custom-attachment'>
					<div className='custom-attachment-panel'>
						<div className='custom-attachment-panel-header'>
							<div className='custom-attachment-panel-text'>
								<i
									className={`ico ${getIconClass(
										messageItem.content.documentExt,
									)}`}
								></i>{' '}
								<span className='file-name'>
									{messageItem.content.documentName}
								</span>
							</div>
						</div>
						<div className='custom-attachment-panel-footer'>
							<div className='float-left'>
								{messageItem.content.documentSizeKB} KB &nbsp;&bull;&nbsp;{' '}
								{messageItem.content.documentExt.toUpperCase()}
							</div>
						</div>
					</div>
				</div>
			</a>
			<span className='chatApp_convMessageDate'>
				{messageItem.groupPendingReads ? (
					<span className="chat-name-show">{messageItem.nameFrom}</span>
				) : (<></>)}
				{convertMilisecondsTimeStampToDate(messageItem.timestamp)}
			</span>
		</div>
	);
};

export default ChatMessageItem;
