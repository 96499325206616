import React, { useState, useEffect, useContext } from 'react'
import { Modal, Tabs, Tab, Dropdown } from 'react-bootstrap'
import { InputText, ImageCropHook, PopUpModal, ReactMultiSelect } from '../../../../components'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { mandatoryFields, wholesalerVM } from '../users/viewModel'
import _ from 'lodash'
import moment from 'moment';
import { default as _images } from "../../../../images";
import UpdatePassword from '../users/updatePassword'
import { validateEmail } from "../../../../services/helper";
import { ClientContext } from '../../../layout/clientContext'
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';


const AddWholesaler = (props) => {
    const [wholesaler, setWholesaler] = useState(props.wholesaler);
    const [loader, setLoader] = useState(false)
    const [verifyLoader, setVerifyLoader] = useState(false)
    const [errorFields, setErrorFields] = useState({});
    const [cropShow, setCropShow] = useState({ show: false, clsActive: '', src: null, id: '', type: '', ext: '', title: '' })
    const [imageLoader, setImageLoader] = useState(false)
    const [showPasswordModel, setShowPasswordModel] = useState(false)
    const newWholesaler = !_.isEmpty(props.wholesaler) ? false : true
    const [mandFields, setMandFields] = useState(mandatoryFields)
    const { countryLookups, oemSettings, clients } = useContext(ClientContext);
    const oldEmailaddress = !_.isEmpty(props.wholesaler) ? props.wholesaler.email : true
    const [showMFAEnableModel, setShowMFAEnableModel] = useState(false)
    const [phoneCodes, setphoneCodes] = useState([])
    const [mfaLoader, setmfaLoader] = useState(false);

    useEffect(() => {
        if (_.isEmpty(props.wholesaler)) {
            const newuserVM = Object.assign({}, wholesalerVM);
            newuserVM.addedDate = window.firebase.firestore.Timestamp.now();
            newuserVM.addedBy = localStorage.uid;
            newuserVM.isWholesaler = true;
            setWholesaler(newuserVM)
        }
    }, [])

    useEffect(() => {
        let _phoneCodes = [];
        countryLookups.forEach((doc) => {
            if (doc.phone_code) {
                _phoneCodes.push({
                    country_code: doc.code,
                    value: doc.phone_code,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name + ' (' + (doc.phone_code ? doc.phone_code : '--') + ')'
                });
            }
        });
        setphoneCodes(_phoneCodes);
    }, [])

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (name === 'email' || name === 'password' || name === 'cpassword') {
            setWholesaler({
                ...wholesaler,
                [name]: value
            });
        }
        else {
            if (array1.length > 0) {
                var newarray1 = [];
                for (var x = 0; x < array1.length; x++) {
                    newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
                }
                setWholesaler({
                    ...wholesaler,
                    [name]: newarray1.join(' ')
                });
            } else {
                if (str && str.length >= 1) {
                    var firstChar = str.charAt(0);
                    var remainingStr = str.slice(1);
                    str = firstChar.toUpperCase() + remainingStr;
                }
                setWholesaler({
                    ...wholesaler,
                    [name]: str
                });
            }
        }

        errorChange(name);

    }

    const errorChange = (key) => {
        let errorClass = 'input_error';

        if (errorFields !== {}) {
            if (!wholesaler[key] && mandatoryFields.indexOf(key) >= 0) {
                setErrorFields({
                    ...errorFields,
                    [key]: errorClass
                });
            }
            else {
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            setErrorFields({
                                ...errorFields,
                                [key]: errorClass
                            });
                        }
                        else
                            setErrorFields({
                                ...errorFields,
                                [key]: ''
                            });
                    }
                }
                else
                    setErrorFields({
                        ...errorFields,
                        [key]: ''
                    });
            }


        }

    }

    const savewholesaler = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        for (let [key] of Object.entries(wholesaler)) {
            if (mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(wholesaler[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        if (newWholesaler) {
            if (_.isEmpty(wholesaler['password'])) {
                formIsValid = false;
                errors['password'] = errorClass;
            }
            if (wholesaler['password'] !== wholesaler['cpassword']) {
                formIsValid = false;
                errors['cpassword'] = errorClass;
            }
        }
        if (_.isEmpty(wholesaler['wholesalerOEMID'])) {
            formIsValid = false;
            errors['wholesalerOEMID'] = errorClass;
        }
        if (_.isEmpty(wholesaler['wholesalerClientID'])) {
            formIsValid = false;
            errors['wholesalerClientID'] = errorClass;
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setLoader(true)
        if (newWholesaler === true) {
            const addwholesaler = window.firebase.functions().httpsCallable('amsadmin-createUser');
            addwholesaler({
                "email": wholesaler.email,
                "password": wholesaler.password
            }).then(async (data) => {
                //console.log(data)
                if (data.data.success === true) {
                    wholesaler.documentID = data.data.data;
                    updatewholesaler(wholesaler)
                }
                else {
                    const existingWholesaler = await window.firebase.firestore().collection('users')
                        .where('email', '==', wholesaler.email)
                        //.where('isWholesaler', '==', true)
                        .limit(1)
                        .get();
                    if (existingWholesaler.docs && existingWholesaler.docs.length > 0) {
                        updatewholesaler({
                            ...existingWholesaler.docs[0].data(),
                            wholesalerOEMID: _.uniq(wholesaler.wholesalerOEMID),
                            wholesalerClientID: _.uniq(wholesaler.wholesalerClientID),
                            isWholesaler: true
                        }, true);
                    }
                    else {
                        setLoader(false)
                        Swal.fire(data.data.message, '', 'error')
                    }
                }
            });
        }
        else if (wholesaler.email !== oldEmailaddress) {
            const addwholesaler = window.firebase.functions().httpsCallable('amsadmin-updateUserEmail');
            addwholesaler({
                "email": wholesaler.email,
                "uid": wholesaler.documentID
            }).then((data) => {
                //console.log(data)
                if (data.data.success === true) {
                    //user.documentID = data.data.data;
                    updatewholesaler(wholesaler)
                }
                else {
                    setLoader(false)
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        else {
            updatewholesaler(wholesaler)
        }
    }

    const updatewholesaler = async (wholesaler) => {
        if (_.isEmpty(wholesaler) || _.isEmpty(wholesaler.documentID)) {
            Swal.fire('Invalid user.', '', 'info')
            return
        }
        wholesaler.modifiedDate = window.firebase.firestore.Timestamp.now();
        wholesaler.modifiedBy = localStorage.uid;
        delete wholesaler.password
        delete wholesaler.cpassword
        window.firebase.firestore().doc(`users/${wholesaler.documentID}`).set(wholesaler, { merge: true })
            .then(snapshot => {
                setLoader(false)
                toast.notify((newWholesaler === true ? 'Wholesaler added successfully' : 'Wholesaler updated successfully'), {
                    duration: 2000
                })
                props.handleClose(wholesaler)
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000
                })
            });
    }


    const onSelectFile = (e, type, id, title) => {

        if (e.target.files && e.target.files.length > 0) {
            if (!e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
                Swal.fire('File format not supported. Please select image file.', '', 'info')
                return;
            }
            var img = e.target.files[0].size;
            var imgsize = img / 1024 / 1024;
            if (imgsize > 10) {
                Swal.fire('Maximum file size exceeded.', '', 'info')
                return;
            }

            let reader = new FileReader();
            let file = e.target.files[0];

            reader.onloadend = () => {

                setCropShow({
                    show: true,
                    clsActive: 'overlay-modal active',
                    src: reader.result,
                    id: id,
                    type: type,
                    ext: file.name.substr((file.name.lastIndexOf('.') + 1)),
                    title: title
                })
            }
            reader.readAsDataURL(file)
        }
    }

    const handleCropClose = (id) => {
        if (id && document.getElementById(id))
            document.getElementById(id).value = "";
        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const handleCropSelect = (cropShow) => {

        const { src, id, type, ext } = cropShow;
        setWholesaler({
            ...wholesaler,
            [id]: src
        });
        saveImageToStorage(src, id, type, ext);


        setCropShow({
            show: false,
            clsActive: '',
            src: null,
            id: '',
            type: '',
            ext: '',
            title: ''
        })
    }

    const saveImageToStorage = (imageBase64, id, type, ext) => {

        let uid = moment()._d.getTime().toString() + '.' + ext;
        setImageLoader(true)
        var storageRef = window.firebase.storage().ref(`users/${uid}`).putString(imageBase64, 'data_url');
        storageRef.on('state_changed', (snapshot) => {
            var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;

        },
            (error) => {
                //console.log(error);
            },
            () => {
                window.firebase.storage().ref(`users`).child(uid).getDownloadURL()
                    .then(dataURL => {
                        setImageLoader(false)
                        setWholesaler({
                            ...wholesaler,
                            [id]: dataURL
                        });
                    })
            })
    };

    const handlePasswordModelClose = () => {
        setShowPasswordModel(false)
    }


    const handleReactMultiSelectChange = (selectedOptions, name) => {

        const value = [];

        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value);
        })

        setWholesaler({
            ...wholesaler,
            [name]: _.uniq(value)
        });
    }

    const emailVerification = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        let _mandFields = ["name", "email"];
        for (let [key] of Object.entries(wholesaler)) {
            if (_mandFields.indexOf(key) >= 0) {
                if (_.isEmpty(wholesaler[key])) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
                if (key === 'email') {
                    if (typeof wholesaler[key] !== "undefined") {
                        if (!validateEmail(wholesaler[key])) {
                            formIsValid = false;
                            errors[key] = errorClass;
                        }
                    }
                }
            }
        }
        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }
        setVerifyLoader(true);
        try {
            const emailVerificationLink = window.firebase.functions().httpsCallable('amsadmin-sendNewUserEmail');
            emailVerificationLink({
                "email": wholesaler.email,
                "name": wholesaler.name
            }).then((data) => {
                setVerifyLoader(false);
                if (data.data.success === true) {
                    toast.notify('Email verification link sent successfully.', {
                        duration: 2000
                    })
                }
                else {
                    Swal.fire(data.data.message, '', 'error')
                }
            });
        }
        catch (error) {
            setVerifyLoader(false);
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }
    }

    const handleMFAChange = (cheked) => {
        if (cheked) {
            setShowMFAEnableModel(true);
        }
        else {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to disable multi-factor authentication?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    handleEnableMFA(cheked)
                }
            })

        }
    }

    const handleMFAModelClose = (wholesaler) => {
        setShowMFAEnableModel(false);
        if (wholesaler)
            setWholesaler({
                ...wholesaler,
                phone: wholesaler.phone,
                phoneCode: wholesaler.phoneCode
            })
    }

    const handlePhoneChange = e => {
        const { name, value } = e.target;
        const phoneReg = /^[0-9]+$/;
        if (phoneReg.test(value) || value === '') {
            setWholesaler({ ...wholesaler, [name]: value });
        }
    };

    const handleAddPhoneCode = (name, value) => {
        let val = value ? value : '';
        setWholesaler({ ...wholesaler, [name]: val });
    };

    const handleEnableMFA = (checked) => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};
        if (checked) {
            if (_.isEmpty(wholesaler['phone']) || _.isEmpty(wholesaler['phoneCode'])) {
                formIsValid = false;
                errors['phone'] = errorClass;
            }
            setErrorFields(errors)
            if (!formIsValid) {
                return;
            }
        }
        setmfaLoader(true);
        try {
            const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
            enrollMFA({
                "uid": wholesaler.documentID,
                "phone": `+${wholesaler.phoneCode.replace('+', '')}${wholesaler.phone}`,
                "enroll": checked
            }).then((data) => {
                setmfaLoader(false);
                if (data.data.success === true) {
                    let updatewholesaler = {
                        phoneCode: wholesaler.phoneCode,
                        phone: wholesaler.phone,
                        enableMFA: checked
                    }
                    setWholesaler({
                        ...wholesaler,
                        ...updatewholesaler
                    })
                    setShowMFAEnableModel(false);
                    window.firebase.firestore().doc(`users/${wholesaler.documentID}`).set(updatewholesaler, { merge: true });
                    toast.notify(`Multi-factor authentication ${checked ? 'enabled' : 'disabled'} successfully.`, {
                        duration: 2000
                    })
                }
                else {
                    let msg = data.data.message;
                    if (data.data.data.errorInfo && data.data.data.errorInfo.code === 'auth/invalid-phone-number') {
                        msg = 'Invalid phone number.'
                    }
                    Swal.fire(msg, '', 'error');
                }
            });
        }
        catch (error) {
            setmfaLoader(false);
            Swal.fire('Something went wrong. Please contact support.', '', 'error')
        }
    }

    return _.isEmpty(wholesaler) ? (<></>) : (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-user"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="form-style w-100">
                                <div className="form-row settings-profile pt-2">

                                    <div className="settings-profileimage">
                                        {
                                            (imageLoader)
                                                ?
                                                <div className="img-loader pl-2">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-secondary">
                                                        <div className="spinner-border" role="status"> <span className="sr-only">Loading...</span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            (!_.isEmpty(wholesaler.profileImage))
                                                ?
                                                <a data-fancybox={`profileImage`} href={wholesaler.profileImage} onClick={(e) => { e.preventDefault(); }}>
                                                    <img src={wholesaler.profileImage} alt="" className="rounded-circle img-object-fit" />
                                                </a>
                                                :
                                                <img src={_images.nouser} alt="" className="rounded-circle img-object-fit" />
                                        }

                                    </div>
                                    <div className="settings-profile-info">
                                        <div className="settings-profile-upload">
                                            <label htmlFor="profileImage" className={`btn-common float-left mr-2 font-600`}>Choose Picture
                                                <input className="fileInput"
                                                    type="file"
                                                    name="profileImage"
                                                    id="profileImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => onSelectFile(e, 'wholesaler', 'profileImage', 'Profile Image')} />
                                            </label>
                                            <div className="settings-delete-button"> <a href="#" className="mini-button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setWholesaler({
                                                        ...wholesaler,
                                                        ['profileImage']: ''
                                                    });
                                                }}> <i className="ico icon-delete"></i></a></div>

                                        </div>
                                        <div className=" settings-limit">Max size 2 MB. Formats: JPG, GIF, PNG.</div>
                                    </div>

                                </div>
                                <div className="settings-tab-wrap tabs">
                                    <div className="tabs-wraper">
                                        <nav>
                                            <Tabs defaultActiveKey="login" className="nav-fill" mountOnEnter={true}>
                                                <Tab eventKey="login" title="Login Details">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label >Name</label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'name'}
                                                                className={`form-control ${errorFields["name"]}`}
                                                                name="name"
                                                                onChange={handleOnChange}
                                                                value={wholesaler.name}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label >Email</label>
                                                            <InputText
                                                                autoComplete="off"
                                                                placeholder={'email'}
                                                                className={`form-control ${errorFields["email"]}`}
                                                                name="email"
                                                                onChange={handleOnChange}
                                                                value={wholesaler.email}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label >OEM</label>
                                                            <ReactMultiSelect
                                                                options={oemSettings.map(rec => {
                                                                    return {
                                                                        label: rec.name,
                                                                        value: rec.id
                                                                    }
                                                                })}
                                                                name={'wholesalerOEMID'}
                                                                placeholder={'select oem'}
                                                                onChange={(e) => handleReactMultiSelectChange(e, 'wholesalerOEMID')}
                                                                value={wholesaler.wholesalerOEMID}
                                                                classNamePrefix={`${errorFields["wholesalerOEMID"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label >Client</label>
                                                            <ReactMultiSelect
                                                                options={clients.filter(a => wholesaler.wholesalerOEMID.includes(a.settingsID)
                                                                    && a.moduleSettings
                                                                    && a.moduleSettings.tradeinPro
                                                                    && a.moduleSettings.tradeinPro.enabled === true
                                                                    && a.moduleSettings.tradeinPro.active === true
                                                                ).map(rec => {
                                                                    return {
                                                                        label: rec.name,
                                                                        value: rec.documentID
                                                                    }
                                                                })}
                                                                name={'wholesalerClientID'}
                                                                placeholder={'select client'}
                                                                onChange={(e) => handleReactMultiSelectChange(e, 'wholesalerClientID')}
                                                                value={wholesaler.wholesalerClientID}
                                                                classNamePrefix={`${errorFields["wholesalerClientID"]} basic-select`}
                                                                isMulti={true}
                                                                isSearchable={true}
                                                            >
                                                            </ReactMultiSelect>
                                                        </div>
                                                    </div>
                                                    {
                                                        newWholesaler ? (<div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label >Password</label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="off"
                                                                    placeholder={'password'}
                                                                    className={`form-control ${errorFields["password"]}`}
                                                                    name="password"
                                                                    onChange={handleOnChange}
                                                                    value={wholesaler.password}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label >Confirm Password</label>
                                                                <InputText
                                                                    type={'password'}
                                                                    autoComplete="off"
                                                                    placeholder={'confirm password'}
                                                                    className={`form-control ${errorFields["cpassword"]}`}
                                                                    name="cpassword"
                                                                    onChange={handleOnChange}
                                                                    value={wholesaler.cpassword}
                                                                />
                                                            </div>
                                                        </div>
                                                        ) : (<></>)
                                                    }
                                                    {
                                                        !newWholesaler ? (
                                                            <div className="form-row">
                                                                <div className="user-mfa-wrapper">
                                                                    <h4>Two Factor Authentication</h4>
                                                                    {
                                                                        wholesaler.enableMFA ? (<div className="mfa-green">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> You have enabled Multi-factor Authentication </div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-red float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(false)}>
                                                                                    {
                                                                                        mfaLoader ?
                                                                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                                            : (<></>)
                                                                                    }
                                                                                    Disable</button>
                                                                            </div>
                                                                        </div>) : (<div className="mfa-blue">
                                                                            <div className="float-left mt-1"><i className="ico icon-settings-security"></i> Enable Multi-factor Authentication</div>
                                                                            <div className="float-right ">
                                                                                <button type="button" className="btn btn-default float-left ml-2 mfa-enable-btn" onClick={() => handleMFAChange(true)}> Enable</button>
                                                                            </div>
                                                                        </div>)
                                                                    }


                                                                </div>
                                                            </div>
                                                        ) : (<></>)
                                                    }
                                                </Tab>
                                                <Tab eventKey="other" title="Other Info">
                                                    <div>
                                                        Other info
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    {
                        newWholesaler === false ?
                            (<>
                                <button type="button" className="btn btn-secondary float-left" onClick={() => { setShowPasswordModel(true) }}>Update Password</button>
                                <button type="button" className="btn btn-secondary float-left  ml-2" onClick={() => emailVerification()} >

                                    {
                                        verifyLoader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<><i className="ico icon-email"></i></>)
                                    }
                                    Send Email Invitation</button>
                            </>)
                            : (<></>)
                    }
                    <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => savewholesaler(e)}>
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        Save
                    </button>
                    <button type="button" className="btn btn-default float-right" onClick={() => props.handleClose()} >Cancel</button>
                </Modal.Footer>
            </Modal>
            {
                showMFAEnableModel ? (
                    <Modal
                        show={showMFAEnableModel}
                        onHide={handleMFAModelClose}
                        backdrop="static"
                        backdropClassName={props.show ? 'overlay-modal active' : ''}
                        dialogClassName="modal-dialog-centered modal-update-password"
                        enforceFocus={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Enable Multi-factor authentication </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="form-style w-100">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label >Phone</label>
                                                <div className={`input-group country-code ${errorFields["phone"] ? 'phone_error' : ''}`}>
                                                    <div className='input-group-prepend'>
                                                        <Dropdown className={`btn btn-outline-secondary`}>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                <span id='spn-code-name' data-name={wholesaler.phoneCode}>
                                                                    {wholesaler.phoneCode &&
                                                                        phoneCodes.find(item => item.value === wholesaler.phoneCode)
                                                                        ? phoneCodes.find(
                                                                            item => item.value === wholesaler.phoneCode,
                                                                        ).value + ' '
                                                                        : 'Code'}
                                                                </span>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu
                                                                as={CustomSearchMenu}
                                                                className='rm-pointers dropdown-menu-lg dropdown-menu-right'
                                                                ChildClass='pipeline-dropdown'
                                                                xplacement='bottom-end'
                                                            >
                                                                {!_.isEmpty(phoneCodes) && phoneCodes.length > 0 ? (
                                                                    phoneCodes.map((rec, index) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={index}
                                                                                className={`current-pipeline ${wholesaler.phoneCode && rec.value === wholesaler.phoneCode
                                                                                    ? 'active'
                                                                                    : ''
                                                                                    }`}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    handleAddPhoneCode(
                                                                                        'phoneCode',
                                                                                        rec.value,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {rec.label}
                                                                            </Dropdown.Item>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'phone'}
                                                        className={`form-control`}
                                                        name="phone"
                                                        onChange={handlePhoneChange}
                                                        value={wholesaler.phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <button type="button" className="btn btn-primary float-right ml-2" onClick={(e) => handleEnableMFA(true)}>
                                {
                                    mfaLoader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                Enable
                            </button>
                            <button type="button" className="btn btn-default float-right" onClick={() => handleMFAModelClose()} >Cancel</button>
                        </Modal.Footer>
                    </Modal>
                ) : (<></>)
            }
            <ImageCropHook
                cropShow={cropShow}
                handleClose={handleCropClose}
                handleSelect={handleCropSelect}
            ></ImageCropHook>
            <PopUpModal show={showPasswordModel}>
                <UpdatePassword
                    show={showPasswordModel}
                    handleClose={handlePasswordModelClose}
                    uid={!_.isEmpty(props.wholesaler) ? props.wholesaler.documentID : ''}
                />
            </PopUpModal>
        </>
    )
}

export default AddWholesaler