import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import PipelineCounts from './pipelineCounts'
import { PopUpModal } from '../../components'
import EnquiryList from '../dashboard/enquiryList'
import ActivityList from '../dashboard/activitiesList'
import { initialLayoutArr, defaultWidgetSettings, liteVersionWidgetSettings } from './viewModel';
import TestDriveList from '../dashboard/testdriveList'
import TradeInList from '../dashboard/tradeinList'
import DragDropLayout from './dragDropLayout';
import { default as _images } from '../../images';
import Swal from 'sweetalert2';
import CommonHelper from '../../services/common';

const defaulModelState = {
    enquiry: false,
    activity: false,
    testdrive: false,
    tradein: false
}

const Dashboard = (props) => {
    const [loader, setLoader] = useState(false)
    const [modelShow, setModelShow] = useState(defaulModelState)
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [pipelineEnquiries, setEnquiries] = useState([]);
    const [allActivities, setActivities] = useState([]);
    const [mtdTestdrives, setTestdrives] = useState([]);
    const [mtdTradeins, setTradeins] = useState([]);
    const [mtdContacts, setContacts] = useState([]);
    const [selectedPipeline, setPipeline] = useState();
    const [selectedUser, setUser] = useState(props.dealersettings.ownDashboard || props?.dealersettings?.rolePermissions?.permissions?.ownerDashboard ? { value: props.dealersettings.id } : null);
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [applydateRange, setApplyDateRange] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [refreshActivity, setRefreshActivity] = useState(false)
    const [layout, setLayout] = useState([])
    const [layoutLoader, setLayoutLoader] = useState(true)
    const [widgetSettingsLoader, setWidgetSettingsLoader] = useState(true)
    const [sortedWidgets, setSortedWidgets] = useState([])
    const [hiddenWidgets, setHiddenWidgets] = useState([])
    const [sortedStats, setSortedStats] = useState([])
    const [hiddenStats, setHiddenStats] = useState([])
    const [showWidgetSettings, setShowWidgetSettings] = useState(false);
    const [dashboardFilter, setDashboardFilter] = useState(props.dealersettings.ownDashboard && !props?.dealersettings?.rolePermissions?.permissions?.ownerDashboard ? { user: [props.dealersettings.id] } : {})

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const managerLostApproval = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.lostApproval) ? true : false

    const enableLoanBooking = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.enableLoanBooking) ? true : false);

    const showInboundActivities = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.separateInbdUserAct) ? true : false);

    const performanceReviewEnabled = ((props.dealersettings && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.moduleSettings) &&
        !_.isEmpty(props.dealersettings.client.moduleSettings.performanceReview) &&
        props.dealersettings.client.moduleSettings.performanceReview.enabled) ? true : false);

    const clientLostApproval = !_.isEmpty(props.dealersettings.client.clientSettings) ? Boolean(props.dealersettings.client.clientSettings.lostApproval) : false;
    const userRef = window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`);
    const enquiryStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.settings) &&
        props.dealersettings.client.settings.enquiryStatus) ? props.dealersettings.client.settings.enquiryStatus.filter(a => Boolean(a.active)) : []);
    const liteVersion = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.liteVersion) ? true : false);

    const staticDashboard = false;
    useEffect(() => {
        if (ownerDashboard)
            setUser({ value: props.dealersettings.id })
    }, [])

    useEffect(() => {
        document.getElementsByTagName('body')[0].classList.remove('inner-bg');
        document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('enquiry-bg');
        }
    }, [])


    useEffect(() => {
        if (staticDashboard)
            return;
        const widgetSettingsSnapShot = window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`)
            .onSnapshot(querySnapshot => {
                //console.log('querySnapshot', querySnapshot)
                let _defaultWidgetSettings = [...defaultWidgetSettings]
                if (liteVersion) {
                    setSortedWidgets(liteVersionWidgetSettings)
                    setHiddenWidgets(defaultWidgetSettings.filter(a => !liteVersionWidgetSettings.some(b => b === a)))
                    setWidgetSettingsLoader(false)
                } else if (querySnapshot.exists) {
                    const widgetsData = querySnapshot.data()
                    let hiddenWidgets = widgetsData.hiddenWidgets ? widgetsData.hiddenWidgets : [];
                    if (!_.isEmpty(widgetsData.sortedWidgets)) {
                        if (showInboundActivities
                            && !widgetsData.sortedWidgets.some(e => e === 'InboundActivitiesWidget')
                            && !hiddenWidgets.some(e => e === 'InboundActivitiesWidget')) {
                            hiddenWidgets = [...hiddenWidgets, 'InboundActivitiesWidget']
                        }

                        if (showInboundActivities
                            && !widgetsData.sortedWidgets.some(e => e === 'InboundActivities')
                            && !hiddenWidgets.some(e => e === 'InboundActivities')) {
                            hiddenWidgets = [...hiddenWidgets, 'InboundActivities']
                        }
                        if (performanceReviewEnabled
                            && !widgetsData.sortedWidgets.some(e => e === 'PerformanceReviewWidget')
                            && !hiddenWidgets.some(e => e === 'PerformanceReviewWidget')) {
                            hiddenWidgets = [...hiddenWidgets, 'PerformanceReviewWidget']
                        }
                        if (!ownerDashboard
                            && !widgetsData.sortedWidgets.some(e => e === 'CampaignPerformance')
                            && !hiddenWidgets.some(e => e === 'CampaignPerformance')) {
                            hiddenWidgets = [...hiddenWidgets, 'CampaignPerformance']
                        }
                        if (!ownerDashboard
                            && !widgetsData.sortedWidgets.some(e => e === 'OriginPerformance')
                            && !hiddenWidgets.some(e => e === 'OriginPerformance')) {
                            hiddenWidgets = [...hiddenWidgets, 'OriginPerformance']
                        }
                        if (ownerDashboard) {
                            widgetsData.sortedWidgets = widgetsData.sortedWidgets.filter(a => !a.includes('SalesFunnel-'))
                            hiddenWidgets = hiddenWidgets.filter(a => !a.includes('SalesFunnel-'))
                            if (!hiddenWidgets.some(a => a === 'SalesFunnel'))
                                widgetsData.sortedWidgets.push(`SalesFunnel`)
                        }
                    }
                    // console.log('sortedWidgets', widgetsData.sortedWidgets ? widgetsData.sortedWidgets : _defaultWidgetSettings)
                    if (_.isEmpty(widgetsData.sortedWidgets) && _.isEmpty(widgetsData.hiddenWidgets)) {
                        setSortedWidgets(_defaultWidgetSettings)
                        setHiddenWidgets([])
                    }
                    else {
                        setSortedWidgets(widgetsData.sortedWidgets ? _.uniq(widgetsData.sortedWidgets) : _defaultWidgetSettings)
                        setHiddenWidgets(_.uniq(hiddenWidgets))
                    }
                    setWidgetSettingsLoader(false)
                } else {
                    setSortedWidgets(_defaultWidgetSettings)
                    setHiddenWidgets([])
                    setWidgetSettingsLoader(false)
                }
            })
        return () => {
            widgetSettingsSnapShot && widgetSettingsSnapShot();
        }
    }, [])

    useEffect(() => {
        const statsSettingsSnapShot = window.firebase.firestore().doc(`users/${localStorage.uid}/userClients/${props.dealersettings.client.id}`)
            .onSnapshot(querySnapshot => {
                let _dynStatus = enquiryStatus.filter(a => a.value !== 'prospectLost').map(r => { return r.value });
                const allStats = ['new', ..._dynStatus, 'delivery', 'pendingdelivery', 'testdrive', 'tradein', 'unattended', 'scheduledAppointments'];
                if (querySnapshot.exists) {
                    const statsData = querySnapshot.data();
                    let sortedStats = statsData.sortedStats ? _.filter(statsData.sortedStats, (v) => _.indexOf(allStats, v) >= 0) : allStats;
                    let _dynStats = _.filter(_dynStatus, (v) => _.indexOf(sortedStats, v) < 0)
                    let _hiddenStats = statsData.hiddenStats ? _.filter(statsData.hiddenStats, (v) => _.indexOf(allStats, v) >= 0) : [];
                    let hiddenStats = statsData.hiddenStats ? _.uniq([..._hiddenStats, ..._dynStats]) : _dynStats;
                    if (!sortedStats.some(e => e === 'delivery')
                        && !hiddenStats.some(e => e === 'delivery')) {
                        hiddenStats = [...hiddenStats, 'delivery']
                    }
                    if (!sortedStats.some(e => e === 'pendingdelivery')
                        && !hiddenStats.some(e => e === 'pendingdelivery')) {
                        hiddenStats = [...hiddenStats, 'pendingdelivery']
                    }
                    if (!sortedStats.some(e => e === 'scheduledAppointments')
                        && !hiddenStats.some(e => e === 'scheduledAppointments')) {
                        hiddenStats = [...hiddenStats, 'scheduledAppointments']
                    }
                    setSortedStats(sortedStats)
                    setHiddenStats(hiddenStats)
                } else {
                    setSortedStats(_.uniq(allStats))
                    setHiddenStats([])
                }
                if (liteVersion) {
                    const allStats = ['new', ..._dynStatus, 'testdrive'];
                    setSortedStats(_.uniq(allStats))
                    setHiddenStats([])
                }
            })
        return () => {
            statsSettingsSnapShot && statsSettingsSnapShot();
        }
    }, [])

    const removeUnAllowedWidgets = widgetName => {
        let valToReturn = true

        if (widgetName === 'LoanBookingsWidget' && !enableLoanBooking) {
            valToReturn = false
        }

        if (widgetName === 'PerformanceReviewWidget' && !performanceReviewEnabled) {
            valToReturn = false
        }

        if ((widgetName === 'InboundActivitiesWidget' || widgetName === 'InboundActivities') && !showInboundActivities) {
            valToReturn = false
        }

        if (widgetName === 'DealerTarget' && !(!_.isEmpty(props.dealersettings.rolePermissions) && !_.isEmpty(props.dealersettings.rolePermissions.permissions) && props.dealersettings.rolePermissions.permissions.accessDealertarget)) {
            valToReturn = false
        }

        if (widgetName === 'LeadsBucket' && !(props.pipeline.some(item => item.value === 'LeadsBucket') || (clientLostApproval && managerLostApproval))) {
            valToReturn = false
        }

        if (widgetName === 'Allusertarget')
            valToReturn = false

        if ((widgetName === 'CampaignPerformance' || widgetName === 'OriginPerformance') && ownerDashboard)
            valToReturn = false

        return valToReturn
    }


    useEffect(() => {
        const getUserData = async () => {
            const userSnapshot = await userRef.get();
            const data = userSnapshot.data();
            if (data && !_.isEmpty(data.dashboardLayout)) {
                setLayout(data.dashboardLayout.filter(a => a.i !== 'null').map((rec) => {
                    return rec.i === "ActivitiesWidget" ? {
                        ...rec,
                        h: 4
                    } : {
                        ...rec
                    }
                }))
                setLayoutLoader(false)
            } else {
                setLayout(initialLayoutArr)
                setLayoutLoader(false)
            }
        }
        getUserData()
    }, [])

    const handleModelClose = () => {
        setFilter(null)
        setModelShow(defaulModelState)
    }

    const handleShowEnquiryList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Enquiry List')
        setModelShow({
            ...defaulModelState,
            enquiry: true,
            counts
        })
    }

    const handleShowActivityList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Activities List')
        setModelShow({
            ...defaulModelState,
            activity: true,
            counts
        })
    }

    const handleShowTestDriveList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Test Drive List')
        setModelShow({
            ...defaulModelState,
            testdrive: true,
            counts
        })
    }

    const handleShowTradeinList = (filterValue, title, counts) => {
        setFilter(filterValue)
        setPopHeader(title ? title : 'Trade-In List')
        setModelShow({
            ...defaulModelState,
            tradein: true,
            counts
        })
    }

    const onLayoutChange = async (_layout, sortedWidgets, hiddenWidgets) => {
        if (_.isEmpty(_layout)) {
            return
        }
        let _dLayout = _layout.filter(a => a.i !== 'null').map((rec, index) => {
            return sortedWidgets.filter(a => removeUnAllowedWidgets(a)).includes(rec.i) && sortedWidgets.filter(a => removeUnAllowedWidgets(a)).length === 1 ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                x: 0,
                y: 0
            } : hiddenWidgets.filter(a => removeUnAllowedWidgets(a)).includes(rec.i) ? {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                w: 0,
                h: 0
            } : {
                ..._.pickBy(rec, v => v !== null && v !== undefined),
                i: rec.i === 'null' ? `${index}` : rec.i,
                maxH: 16,
                maxW: 12,
                minH: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11,
                minW: 4,
                h: (rec.h === 0 || rec.h === 1 || rec.h === undefined) ? ((rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget') ? 4 : (rec.i === 'InboundActivitiesWidget') ? 3 : rec.i === 'LeadsByLabels' ? 7 : 11) : rec.h,
                w: (rec.w === 0 || rec.w === 1 || rec.w === undefined) ? 4 : rec.w,
                isResizable: (rec.i === 'LeadsBucket' || rec.i === 'ActivitiesWidget' || rec.i === 'InboundActivitiesWidget') ? false : true,
                isBounded: false,
                isDraggable: true,
                resizeHandles: false
            }
        })
        setLayout(_dLayout)
        try {
            await userRef.set({
                dashboardLayout: _dLayout,
            }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const reloadData = () => {
        // console.log('isDone true, refresh data start')
        setTimeout(() => {
            setRefreshData(!refreshData)
        }, 1000);
    }

    const reloadActivity = () => {
        // console.log('isDone true, refresh data start')
        setTimeout(() => {
            setRefreshActivity(!refreshActivity)
        }, 1000);
    }
    const handleCloseWidgetSettings = () => {
        setShowWidgetSettings(false)
    }

    const handleOpenWidgetSettings = e => {
        e.preventDefault()
        setShowWidgetSettings(true)
    }

    const removeWidget = async (val) => {
        const result = await Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to hide this widget.'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
            allowOutsideClick: false,
            allowEscapeKey: false
        })

        if (!result.value) {
            return
        }
        const localHiddenWidgets = [val, ...hiddenWidgets];
        const localSortedWidgets = sortedWidgets.filter(a => a !== val);
        const dashboardSettingsRef = window.firebase.firestore().doc(`users/${localStorage.uid}/salesDashboardSettings/${localStorage.uid}`);
        await dashboardSettingsRef.set({
            hiddenWidgets: localHiddenWidgets,
            sortedWidgets: localSortedWidgets,
        }, { merge: true })
        onLayoutChange(layout, localSortedWidgets, localHiddenWidgets);
    }

    return (
        <div className="middle-wrapper">
            <div className="dashboard-panel mt-3">
                {
                    <PipelineCounts {...props}
                        ownerDashboard={ownerDashboard}
                        handleShowEnquiryList={handleShowEnquiryList}
                        handleShowActivityList={handleShowActivityList}
                        handleShowTestDriveList={handleShowTestDriveList}
                        handleShowTradeinList={handleShowTradeinList}
                        pipelineEnquiries={[]}
                        allActivities={allActivities}
                        testdrives={mtdTestdrives}
                        tradeins={mtdTradeins}
                        selectedPipeline={selectedPipeline}
                        selectedUser={selectedUser}
                        dateRange={dateRange}
                        applydateRange={applydateRange}
                        setPipeline={(e) => {
                            setPipeline(e)
                        }}
                        setUser={(e) => {
                            setUser(e)
                        }}
                        handleDateRangeChange={(val) => {
                            if (val) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                        refreshData={refreshData}
                        sortedWidgets={sortedWidgets}
                        hiddenWidgets={hiddenWidgets}
                        handleCloseWidgetSettings={handleCloseWidgetSettings}
                        handleOpenWidgetSettings={handleOpenWidgetSettings}
                        showWidgetSettings={showWidgetSettings}
                        setShowWidgetSettings={setShowWidgetSettings}
                        removeUnAllowedWidgets={removeUnAllowedWidgets}
                        onLayoutChange={onLayoutChange}
                        layout={layout}
                        setLayout={setLayout}
                        sortedStats={sortedStats}
                        hiddenStats={hiddenStats}
                        dashboardFilter={dashboardFilter}
                        setDashboardFilter={(val) => {
                            setDashboardFilter(val)
                            if (val?.daterange) {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.startDate.toDate()).startOf('day')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment(val.daterange.endDate.toDate()).endOf('day')._d)
                                })
                                setApplyDateRange(true)
                            } else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                    endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                })
                                setApplyDateRange(false)
                            }
                        }}
                        hideSettings={staticDashboard}
                        setRefreshData={() => {
                            setRefreshData(!refreshData);
                        }}
                    />
                }
            </div>
            <div className="row">
                <div className="col-md-12 p-0 m-0">
                    {(layoutLoader || widgetSettingsLoader) ?
                        (
                            <div className='mt-5  w-100'>
                                <div className='spinner-loader pt-5 h-100'>
                                    <div className='d-flex h-100 justify-content-center align-items-center text-primary'>
                                        <div
                                            role='status'
                                            className='spinner-border text-primary'
                                        ></div>
                                        <p className='text-muted mt-3'></p>
                                    </div>
                                </div>
                            </div>
                        ) : sortedWidgets.filter(a => removeUnAllowedWidgets(a)).length !== 0 ? (
                            <DragDropLayout
                                onLayoutChange={onLayoutChange}
                                {...props}
                                handleShowEnquiryList={handleShowEnquiryList}
                                handleShowActivityList={handleShowActivityList}
                                handleShowTestDriveList={handleShowTestDriveList}
                                handleShowTradeinList={handleShowTradeinList}
                                pipelineEnquiries={pipelineEnquiries}
                                selectedPipeline={selectedPipeline}
                                selectedUser={selectedUser}
                                clientLostApproval={clientLostApproval}
                                managerLostApproval={managerLostApproval}
                                dateRange={dateRange}
                                applydateRange={applydateRange}
                                loader={loader}
                                allActivities={allActivities}
                                testdrives={mtdTestdrives}
                                tradeins={mtdTradeins}
                                contacts={mtdContacts}
                                initialLayout={layout}
                                ownerDashboard={ownerDashboard}
                                reloadData={reloadData}
                                refreshData={refreshData}
                                reloadActivity={reloadActivity}
                                refreshActivity={refreshActivity}
                                sortedWidgets={sortedWidgets}
                                hiddenWidgets={hiddenWidgets}
                                removeUnAllowedWidgets={removeUnAllowedWidgets}
                                removeWidget={removeWidget}
                                enquiryStatus={enquiryStatus}
                                dashboardFilter={dashboardFilter}
                                staticDashboard={staticDashboard}
                            />
                        ) : (
                            <div className="common-table pt-5">
                                <div className="text-center p-5">
                                    <div className='no-data-img mb-3'>
                                        {' '}
                                        <img src={_images.nodata} width='60' height='60' alt='' />
                                    </div>
                                    <p>No Visible Widgets.</p>
                                    <button className="btn btn-sm btn-default" onClick={handleOpenWidgetSettings}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        Add a widget
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={pipelineEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    reloadData={reloadData}
                    dashboardFilter={dashboardFilter}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={allActivities}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    reloadData={reloadActivity}
                    dashboardFilter={dashboardFilter}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTestdrives}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.tradein}>
                <TradeInList
                    {...props}
                    show={modelShow.tradein}
                    handleClose={handleModelClose}
                    counts={modelShow.counts}
                    title={popHeader}
                    dataList={mtdTradeins}
                    filter={filter}
                    selectedPipeline={selectedPipeline}
                    selectedUser={selectedUser}
                    dateRange={dateRange}
                    isFromDashboard={true}
                    dashboardFilter={dashboardFilter}
                ></TradeInList>
            </PopUpModal>
        </div>
    )
}

export default Dashboard