export const inboundCountsVM = {
  todayNew: [],
  weekNew: [],
  monthNew: [],
  todayPending: [],
  weekPending: [],
  monthPending: [],
  todayConverted: [],
  weekConverted: [],
  monthConverted: [],
  todayShow: [],
  weekShow: [],
  monthShow: [],
  todayNoShow: [],
  weekNoShow: [],
  monthNoShow: [],
  todayWon: [],
  weekWon: [],
  monthWon: [],
  todayLost: [],
  weekLost: [],
  monthLost: [],
}