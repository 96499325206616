/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment'
/** COMPONENTS */
import Translate from '../../constants/translate';
import {
    PopUpModal, InputCheckBox,
    ReactSelect, InputText, ReactMultiSelect,
    AntDateRangePicker
} from '../../components';
import { default as _images } from '../../images';
import CommonHelper from '../../services/common';
import SaveFilter from '../filters/saveFilter'
import { activityLogOptions } from '../pipeline/viewModel'

//#region  ASSIGN VARIABLES
let outcomes = [];

const dateOptions = [
    { active: true, value: 'today', label: <Translate text={'today'} /> },
    { active: true, value: 'yesterday', label: <Translate text={'Yesterday'} /> },
    { active: true, value: 'thisWeek', label: <Translate text={'This Week'} /> },
    { active: true, value: 'lastWeek', label: <Translate text={'Last Week'} /> },
    { active: true, value: 'thisMonth', label: <Translate text={'This Month'} /> },
    { active: true, value: 'lastMonth', label: <Translate text={'Last Month'} /> },
    { active: true, value: 'custom', label: <Translate text={'Custom'} /> }
]

const dateTypes = [
    { active: true, value: 'addedDate', label: <Translate text={'Created Date'} /> },
    { active: true, value: 'modifiedDate', label: <Translate text={'Modified Date'} /> },
    { active: true, value: 'startDate', label: <Translate text={'startDate'} /> },
    { active: true, value: 'endDate', label: <Translate text={'endDate'} /> },
    { active: true, value: 'dueDate', label: <Translate text={'Due Date'} /> }
]

const objDateField = {
    name: null,
    type: null,
    range: null
}
const boolTypes = ['isDone', 'excludeTrigger']
//#endregion
const CalendarAdvanceFilters = (props) => {
    const [filterFields, setFilterFields] = useState({})
    const [clientUsers, setClientUsers] = useState([])
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [dealers, setDealers] = useState([]);
    const [showSaveFilter, setShowSaveFilter] = useState(false)
    const [activityTypes, setActivityTypes] = useState(activityLogOptions);

    const { columnFields } = props;

    useEffect(() => {
        const { activityFilter } = props;

        if (!_.isEmpty(activityFilter))
            setFilterFields(activityFilter.value);
        else
            setFilterFields({});

    }, [props.activityFilter])

    useEffect(() => {
        const { dealersettings, clientUsers, activityFilter } = props;

        if (dealersettings && dealersettings.client && dealersettings.client.settings) {
            const setttings = dealersettings.client.settings;
            const brands = dealersettings.allMakeModels;
            let _fields = !_.isEmpty(activityFilter) ? activityFilter.value : {};
            const _years = [];
            const _makes = [];

            outcomes = [];
            setttings.activityOutcome && setttings.activityOutcome.forEach(doc => {
                outcomes.push({
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name,
                });
            });

            for (var i = moment()._d.getFullYear() + 1; i > moment()._d.getFullYear() - 20; i--) {
                _years.push({
                    value: i.toString(),
                    label: i.toString()
                });
            }

            brands.forEach((doc) => {
                _makes.push({
                    value: doc.value,
                    label: doc.name,
                    models: doc.models
                });
            });


            if ((!_.isEmpty(_fields) && _fields.hasOwnProperty('make') && _fields.make)) {
                const models = [];
                let _models = !_.isEmpty(_makes.filter(m => m.value === _fields.make)[0]) ? _makes.filter(m => m.value === _fields.make)[0].models : [];

                _models && _models.forEach((data, index) => {
                    models.push({
                        value: data.value,
                        label: CommonHelper.displayModelName(data),
                    });
                });
                setModels(models);
            }

            const viewOtherActivity = ((!_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.rolePermissions) &&
                !_.isEmpty(dealersettings.rolePermissions.permissions) &&
                dealersettings.rolePermissions.permissions.viewOtherActivity) ? true : false);

            const _clientUsers = [];
            if (clientUsers) {
                let ___clientUsers = clientUsers;
                if (!viewOtherActivity)
                    ___clientUsers = ___clientUsers.filter(m => m.id === localStorage.uid);

                ___clientUsers.forEach((doc) => {
                    _clientUsers.push({
                        value: doc.id,
                        searchlabel: doc.name,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(doc.profileImage, doc.name)} alt="" width="50" className="img-object-fit" />
                            {doc.name}
                        </div>
                    });
                });
            }

            const _activityTypes = [];
            setttings.activityTypes && setttings.activityTypes.filter(a => a.active === true).forEach(doc => {
                _activityTypes.push(
                    {
                        label: (<><i className={`ico icon-${doc.icon} mr-1`}></i>{doc.name}</>),
                        name: doc.name,
                        value: doc.value,
                        active: true,
                    });
            });

            if (!_.isEmpty(_activityTypes))
                setActivityTypes(_activityTypes);

            let _dealers = [];
            if (localStorage.defaultModule === 'tradeinPro') {
                _dealers = CommonHelper.tradeinProClients(dealersettings);
            }

            setDealers(_dealers);
            setClientUsers(_clientUsers);
            setMakes(_makes);
            //setYears(_years);
        }

    }, [props])

    //#region CHANGE EVENTS

    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, filterFields);
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            fields[name] = newarray1.join(' ');
            setFilterFields(fields);
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
            fields[name] = str;
            setFilterFields(fields);
        }

    }

    const handleMultiSelectChange = (selectedOptions, data) => {
        let value = '';
        if (!_.isEmpty(selectedOptions)) {
            selectedOptions.map((data) => {
                value = (value ? value + ',' : '') + (data.value)
            })
        }

        let state = Object.assign({}, filterFields);
        state[data.name] = value;
        setFilterFields(state);
    }

    const handleSwitchChange = (e, name) => {
        const { checked } = e.target;
        let state = Object.assign({}, filterFields);
        state[name] = checked;
        setFilterFields(state);
    }

    const handleSelectChange = (e, data) => {
        let state = Object.assign({}, filterFields);

        if (data.name === 'make' && e) {
            const models = [];
            let _models = !_.isEmpty(makes.filter(m => m.value === e.value)[0]) ? makes.filter(m => m.value === e.value)[0].models : [];

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                });
            });
            setModels(models);
            state[data.name] = e.value;
            if (state.hasOwnProperty('model'))
                state['model'] = null;
            setFilterFields(state)
        }
        else {
            state[data.name] = e.value;
            setFilterFields(state)
        }
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let state = Object.assign({}, filterFields);
        if (checked) {

            if (boolTypes.includes(name)) {
                state[name] = false;
                //state['model'] = null;
            }
            else if (name === 'owner') {
                let userID = props.dealersettings ? props.dealersettings.id : null;
                state[name] = userID;
            }
            else
                state[name] = null;

        }
        else {
            delete state[name];

            if (name === 'make') {
                delete state['model'];
            }
        }
        setFilterFields(state);
    }

    //#endregion

    //#region EVENTS

    const handleClear = () => {
        if (!_.isEmpty(props.activityFilter)) {
            props.handleClearFilter();
        }
        else {
            setFilterFields({});
        }
    }

    const handleApply = () => {

        if (!_.isEmpty(filterFields)) {
            if (!_.isEmpty(props.activityFilter) && !_.isEmpty(props.activityFilter.value) && JSON.stringify(props.activityFilter.value) === JSON.stringify(filterFields)) {
                props.handleApplyFilter(props.activityFilter);
            }
            else {
                let _filter = Object.assign({}, { name: 'Adv Filter', value: filterFields, type: 'filter' })
                props.handleApplyFilter(_filter);
            }
            props.handleFilterClose();
        }
        else {
            props.handleClearFilter();
            props.handleFilterClose();
        }

    }

    const handleSave = () => {
        if (!_.isEmpty(filterFields))
            setShowSaveFilter(true);
    }

    const handleClose = (applyFilter) => {
        if (!_.isEmpty(applyFilter)) {
            props.handleApplyFilter(applyFilter);
            props.handleFilterClose();
        }
        setShowSaveFilter(false);

    }
    //#endregion

    //#region DATE RANGE FILTERS
    const handleDateTypeChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.type = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.type = e.value;
            }
            if (e.value !== 'custom')
                objData.range = null;

            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateNameChange = (e, data) => {
        let state = Object.assign({}, filterFields);
        if (e) {
            var objData = Object.assign({}, state[data.name])
            if (!_.isEmpty(objData)) {
                objData.name = e.value;
            }
            else {
                objData = Object.assign({}, objDateField)
                objData.name = e.value;
            }
            state[data.name] = objData;
            setFilterFields(state);
        }
    }

    const handleDateRangeChange = (val, name) => {
        let state = Object.assign({}, filterFields);
        state[name].range = val;
        setFilterFields(state);

    };
    //#endregion
    return (
        <>

            <div className="quick-filter-body">
                <div className="advanced-filter-subheader"> <Translate text={'activities'} /></div>

                <div className="advanced-filter-list">

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('keywords') >= 0)
                            ?
                            <>
                                {/* KEYWORDS */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'keywords'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'keywords'}><Translate text={'keywords'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('keywords')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <InputText
                                                        autoComplete="off"
                                                        placeholder={'search..'}
                                                        className={`form-control`}
                                                        name="keywords"
                                                        onChange={handleOnChange}
                                                        value={filterFields.hasOwnProperty('keywords') ? filterFields.keywords : ''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (props.showDateFilter && !_.isEmpty(columnFields) && columnFields.indexOf('date') >= 0)
                            ?
                            <>
                                {/* DATE RANGE */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'date'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'date'}><Translate text={'date'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('date')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateTypes}
                                                        id={"date-name"}
                                                        name={"date"}
                                                        placeholder={'select date type'}
                                                        onChange={handleDateNameChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.name))
                                                                ? filterFields.date.name :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={dateOptions}
                                                        id={"date-type"}
                                                        name={"date"}
                                                        placeholder={'select date option'}
                                                        onChange={handleDateTypeChange}
                                                        value={
                                                            (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type))
                                                                ? filterFields.date.type :
                                                                null
                                                        }
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            {
                                                (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.type) && filterFields.date.type === 'custom')
                                                    ?
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <AntDateRangePicker
                                                                value={
                                                                    (!_.isEmpty(filterFields.date) && !_.isEmpty(filterFields.date.range))
                                                                        ? filterFields.date.range :
                                                                        null
                                                                }
                                                                id={"date-range"}
                                                                name={'date'}
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => { handleDateRangeChange(e, 'date') }}
                                                                placeholder='DD/MM/YYYY'
                                                            />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(dealers) && !_.isEmpty(columnFields) && columnFields.indexOf('clientID') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'clientID'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'clientID'}><Translate text={'dealerships'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('clientID')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactMultiSelect
                                                        options={dealers}
                                                        name={"clientID"}
                                                        placeholder={'select dealer'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('clientID') && !_.isEmpty(filterFields.clientID) ? filterFields.clientID.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('owner') >= 0)
                            ?
                            <>
                                {/* OWNER */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'owner'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'owner'}><Translate text={'owner'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('owner')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={clientUsers}
                                                        name={"owner"}
                                                        placeholder={'select ownership'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('owner') ? filterFields.owner.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('subType') >= 0)
                            ?
                            <>
                                {/* subType */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'subType'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('subType')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'subType'}><Translate text={'type'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('subType')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    {/* <ReactSelect
                                                        options={sub_type}
                                                        name={"subType"}
                                                        placeholder={'select type'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('subType') ? filterFields.subType : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect> */}
                                                    <ReactMultiSelect
                                                        options={activityTypes}
                                                        name={"subType"}
                                                        placeholder={'select type'}
                                                        onChange={handleMultiSelectChange}
                                                        value={filterFields.hasOwnProperty('subType') && !_.isEmpty(filterFields['subType']) ? filterFields.subType.split(',') : []}
                                                        classNamePrefix={`basic-select`}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    >
                                                    </ReactMultiSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }



                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('isDone') >= 0)
                            ?
                            <>
                                {/* isDone */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'isDone'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('isDone')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'isDone'}><Translate text={'completed'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('isDone')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <div className="text-left" >
                                                        <Translate text={'completed'} />
                                                        <span className="switch switch-sm float-right">
                                                            <InputCheckBox
                                                                className={`switch`}
                                                                name="_isDone"
                                                                checked={filterFields.hasOwnProperty('isDone') ? filterFields.isDone : false}
                                                                onChange={(e) => { handleSwitchChange(e, 'isDone') }} />
                                                            <label htmlFor="_isDone"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('activityOutcome') >= 0)
                            ?
                            <>
                                {/* activityOutcome */}
                                <div className="check-list">
                                    <div className="checkbox icheck-success w-100">
                                        <InputCheckBox
                                            className="switch"
                                            name={'activityOutcome'}
                                            checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityOutcome')) ? true : false}
                                            onChange={(e) => { handleCheckChange(e) }} />
                                        <label htmlFor={'activityOutcome'}><Translate text={'Activity Outcome'} /></label>
                                    </div>
                                </div>
                                {
                                    !_.isEmpty(filterFields) && filterFields.hasOwnProperty('activityOutcome')
                                        ?
                                        <div className="advanced-filter-box form-style">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <ReactSelect
                                                        options={outcomes}
                                                        name={"activityOutcome"}
                                                        placeholder={'select activity outcome'}
                                                        onChange={handleSelectChange}
                                                        value={filterFields.hasOwnProperty('activityOutcome') ? filterFields.activityOutcome : null}
                                                        classNamePrefix={`cursor-pointer basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </>

                            :
                            <></>
                    }

                    {
                        (!_.isEmpty(columnFields) && columnFields.indexOf('excludeTrigger') >= 0) && (<>
                            {/* excludeTrigger */}
                            <div className="check-list">
                                <div className="checkbox icheck-success w-100">
                                    <InputCheckBox
                                        className="switch"
                                        name={'excludeTrigger'}
                                        checked={(!_.isEmpty(filterFields) && filterFields.hasOwnProperty('excludeTrigger')) ? true : false}
                                        onChange={(e) => { handleCheckChange(e) }} />
                                    <label htmlFor={'excludeTrigger'}><Translate text={'Exclude Trigger Activities'} /></label>
                                </div>
                            </div>
                            {
                                !_.isEmpty(filterFields) && filterFields.hasOwnProperty('excludeTrigger') && (
                                    <div className="advanced-filter-box form-style">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="text-left" >
                                                    <Translate text={'Exclude Trigger Activities'} />
                                                    <span className="switch switch-sm float-right">
                                                        <InputCheckBox
                                                            className={`switch`}
                                                            name="_excludeTrigger"
                                                            checked={filterFields.hasOwnProperty('excludeTrigger') ? filterFields.excludeTrigger : false}
                                                            onChange={(e) => { handleSwitchChange(e, 'excludeTrigger') }} />
                                                        <label htmlFor="_excludeTrigger"></label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                        </>)
                    }



                </div>

            </div>

            {
                !_.isEmpty(filterFields)
                    ?
                    <>
                        <div className="quick-filter-footer">
                            {props.noSaveAccess ? <></> : <><button type="button" className="btn btn-primary float-left" onClick={(e) => { e.preventDefault(); handleSave(); }}> <Translate text={'saveAsFilter'} /></button></>}
                            <button type="button" className="btn btn-default float-left ml-2" onClick={(e) => { e.preventDefault(); handleClear(); }}><Translate text={'clear'} /></button>
                            <button type="button" className="btn btn-primary float-right" onClick={(e) => { e.preventDefault(); handleApply(); }}><Translate text={'apply'} /></button>
                        </div>
                    </>
                    :
                    <>
                    </>
            }



            <PopUpModal show={showSaveFilter}>
                <SaveFilter
                    show={showSaveFilter}
                    handleClose={handleClose}
                    filterFields={filterFields}
                    filtersList={props.customFilters}
                    activeFilter={props.activityFilter}
                    dealersettings={props.dealersettings}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    module={'activities'}
                >
                </SaveFilter>
            </PopUpModal>



        </>
    )
}

export default CalendarAdvanceFilters;