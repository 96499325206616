/** LIBRARIES */
import React, { useState, useEffect } from 'react'
import "../../../styles/tradein-eval.scss";
import _ from 'lodash'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { collectionData, docData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'

/** COMPONENTS */
import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';
import { PopUpModal, ContentOverlay } from '../../../components'
import Translate from '../../../constants/translate';
import { tipOfferStatus, tipStatus } from '../../../services/enum';
import AddNotes from '../offerNotes/addWholesalerNote';

import { objTradeIn, tradeinSortOptions } from '../../tradeIn/viewModel'
import { objTradeIPOffers, objOfferNotesVM } from '../viewModel'


const TradeInDetails = (props) => {
    const [tradeInOffer, setTradeInOffer] = useState(objTradeIPOffers)
    const [tradeIn, setTradeIn] = useState(objTradeIn)
    const [showNotes, setShowNotes] = useState(false)

    const [errorFields, setErrorFields] = useState({});
    const [wholesalerPrice, setPrice] = useState(null);

    const [loader, setLoader] = useState(false)
    const [pagenotFound, setpagenotFound] = useState(false)

    const [tradeinOptionsDF, settradeinOptionsDF] = useState([])
    const [dealersettings, setdealerSettings] = useState({})

    const { client, currentSettings, users, offerNotes, tradeinPro, evaluationPrice, currency, isReRequested, previousWSPrice } = tradeInOffer;
    const {
        images, marks, inspectionSnapshot, sidePhotos, specifications,
        tradeinProID,
    } = tradeIn;

    const currencySymbol = !_.isEmpty(currency) ? currency.symbol + ' ' : ((!_.isEmpty(tradeInOffer) &&
        !_.isEmpty(tradeInOffer.client) &&
        !_.isEmpty(tradeInOffer.client.currency)) ? tradeInOffer.client.currency.symbol + ' ' : '$ ');

    const _settings = ((!_.isEmpty(tradeInOffer) &&
        !_.isEmpty(tradeInOffer.currentSettings) &&
        !_.isEmpty(tradeInOffer.currentSettings.tradeinPro)) ? tradeInOffer.currentSettings.tradeinPro : null);


    let _selectedPipeline = tradeinPro && tradeinPro.pipeline && !_.isEmpty(_settings) ? _settings.allPipelines.filter(item => item.value === tradeinPro.pipeline)[0] : null;
    let statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;

    const toDataURL = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    const [firestoreDB, setFirestoreDB] = useState()
    useEffect(() => {
        window.firebase.firestore().collectionGroup(`offers`).where('documentID', '==', props.offerid)
            .limit(1).get()
            .then(snapshot => {
                if (snapshot.size > 0) {
                    setFirestoreDB(window.firebase)
                }
                else {
                    setFirestoreDB(window.firebase2)
                }
            });
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return
        //console.log('TradeInDetails', props.offerid);
        if (props.offerid) {
            loadTradeIn();
        }
        return () => {
            window.refCollectionData && window.refCollectionData.unsubscribe()
        }
    }, [firestoreDB])

    const loadTradeIn = async () => {

        try {


            window.refCollectionData = collectionData(firestoreDB.firestore().collectionGroup(`offers`).where('documentID', '==', props.offerid).limit(1))
                .pipe(
                    switchMap(_tradeIns => {
                        //console.log(_tradeIns);
                        let tradeIns = _tradeIns[0] ? _tradeIns[0] : {}

                        const enqIds = tradeIns.tradeinProID ? [tradeIns.tradeinProID] :
                            (tradeIns?.tradeinPro?.documentID ? [tradeIns.tradeinPro.documentID] : []);
                        const tradeIds = tradeIns?.tradeinPro?.tradeinID ? [tradeIns.tradeinPro.tradeinID] :
                            (tradeIns?.tradeinPro?.tradein?.documentID ? [tradeIns.tradeinPro.tradein.documentID] : []);
                        const clientIds = tradeIns.clientID ? [tradeIns.clientID] :
                            (tradeIns?.tradeinPro?.clientID ? [tradeIns.tradeinPro.clientID] : []);
                        const wholeSellerIds = [tradeIns.wholeSellerId];
                        return combineLatest(
                            of(tradeIns),
                            combineLatest(
                                enqIds.length > 0 ? enqIds.map(enquiryID =>
                                    collectionData(firestoreDB.firestore().collection('tradeinPro').where('documentID', '==', enquiryID)).pipe(
                                        map(proSnapshot => proSnapshot[0] ? proSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                tradeIds.length > 0 ? tradeIds.map(tradeID =>
                                    collectionData(firestoreDB.firestore().collection('tradeins').where('documentID', '==', tradeID)).pipe(
                                        map(tradeinSnapshot => tradeinSnapshot[0] ? tradeinSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                enqIds.length > 0 ? enqIds.map(enquiryID =>
                                    collectionData(firestoreDB.firestore().collection(`tradeinPro/${enquiryID}/offerNotes`).where('offerID', '==', props.offerid)).pipe(
                                        map(notesSnapshot => notesSnapshot)
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('clients').where('documentID', '==', clientID)).pipe(
                                        map(clientSnapshot => clientSnapshot[0] ? clientSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    docData(window.firebase.firestore().doc(`clients/${clientID}/currentSettings/${clientID}`)).pipe(
                                        map(clientSettingsSnapshot => clientSettingsSnapshot)
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                clientIds.length > 0 ? clientIds.map(clientID =>
                                    collectionData(window.firebase.firestore().collection('users').where('arrayClientID', 'array-contains', clientID)).pipe(
                                        map(usersSnapshot => usersSnapshot)
                                    )
                                ) : of([])
                            ),
                            combineLatest(
                                wholeSellerIds.length > 0 ? wholeSellerIds.map(wholeSellerId =>
                                    collectionData(window.firebase.firestore().collection('users').where('documentID', '==', wholeSellerId)).pipe(
                                        map(userSnapshot => userSnapshot[0] ? userSnapshot[0] : {})
                                    )
                                ) : of([])
                            ),
                        )
                    }),
                    map(([tradeIns, proSnapshot, tradeinSnapshot, notesSnapshot, clientSnapshot, clientSettingsSnapshot, usersSnapshot, userSnapshot]) => {

                        const offerNotes = [];
                        notesSnapshot[0] && notesSnapshot[0].forEach((doc) => {
                            const _notes = Object.assign({}, objOfferNotesVM);
                            for (let [key, value] of Object.entries(doc)) {
                                _notes[key] = value;
                            }

                            if ((_notes.isInternalNotes === false && _notes.wholeSalerNotes === false) || // isDealerPublicNotes 
                                (_notes.isInternalNotes === true && _notes.wholeSalerNotes === true) || // isWholesalerInternalNotes 
                                (_notes.isInternalNotes === false && _notes.wholeSalerNotes === true) // isWholesalerPublicNotes 
                            ) offerNotes.push(_notes);
                        });

                        return {
                            ...tradeIns,
                            tradeinPro: proSnapshot[0] ? proSnapshot[0] : tradeIns.tradeinPro,
                            tradein: tradeinSnapshot[0] ? tradeinSnapshot[0] : (tradeIns.tradeinPro && tradeIns.tradeinPro.tradein ? tradeIns.tradeinPro.tradein : {}),
                            client: clientSnapshot.find(a => a.documentID === tradeIns.clientID),
                            currentSettings: clientSettingsSnapshot[0],
                            users: _.union(usersSnapshot[0], [userSnapshot[0]]),
                            offerNotes: offerNotes,
                            wholeSaler: userSnapshot[0]
                        }

                    })
                )
                .subscribe(_tradeIns => {
                    // let search = window.location.search;
                    // let params = new URLSearchParams(search);
                    // let backURL = params.get('isView');

                    // setPDF(backURL ? false : true);               
                    //console.log('_tradeIns', _tradeIns)
                    if (!_.isEmpty(_tradeIns)) {
                        setTradeInOffer(_tradeIns)
                        //setTradeIn(_tradeIns.tradein);

                        let _dealerSettings = {};
                        let _client = Object.assign({}, _tradeIns.client);
                        let _clientSettings = Object.assign({}, _tradeIns.currentSettings);
                        _dealerSettings.client = Object.assign({
                            ..._client,
                            id: _tradeIns.clientID,
                            name: _clientSettings.name,
                            timezone: _clientSettings.timezone,
                            phone: _clientSettings.phone,
                            logoURL: _clientSettings.logoURL,
                            address: _clientSettings.address,
                            moduleSettings: _clientSettings.moduleSettings,
                            currency: _clientSettings.settings && _clientSettings.settings.currency,
                            integrations: _clientSettings.integrations,
                            settings: _clientSettings.settings
                        });

                        let _tradeinOptionsDF = [];
                        if (_dealerSettings?.client?.settings?.tradeinOptionsDF)
                            _tradeinOptionsDF = _dealerSettings?.client?.settings?.tradeinOptionsDF;
                        settradeinOptionsDF(_tradeinOptionsDF);
                        setdealerSettings(_dealerSettings);

                        saveTradein(_tradeIns.tradein, _tradeinOptionsDF);
                    }
                    else {
                        setpagenotFound(true);
                    }

                });
        }
        catch (error) {
            setpagenotFound(true);
            console.error(error)
        }

    }

    const saveTradein = (doc, _tradeinOptionsDF) => {
        let _fields = { ...doc };
        let __tradeinOptionsDF = _tradeinOptionsDF ? _tradeinOptionsDF : tradeinOptionsDF
        if (!_.isEmpty(doc.dynamicFields) && __tradeinOptionsDF) {

            __tradeinOptionsDF.filter(e => e.active === true).forEach(rec => {
                if (rec.type === 'toggle') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                // else if (rec.type === 'price') {
                //     _fields[rec.value] = doc.dynamicFields[rec.value] ? `${!_.isEmpty(dealersettings?.client?.currency) ? dealersettings.client.currency.symbol : ''}${doc.dynamicFields[rec.value]}` : ''
                // }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format('DD/MM/YYYY') : '';
                }
                else if (rec.type === 'multiselect') {
                    _fields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value].join(',') : ''
                    // _fields[rec.value] = doc.dynamicFields[rec.value] ? (<div className="Vehicle-availability">
                    //     {
                    //         doc.dynamicFields[rec.value].map((rec, index) => {
                    //             return <div key={index}><span>{rec}</span></div>
                    //         })
                    //     }
                    // </div>) : (<></>)
                }
                else {
                    _fields[rec.value] = doc.dynamicFields[rec.value]
                }
            })
        }

        setTradeIn(_fields);
    }

    const getInspection = (id, _marks) => {
        const canvas = document.createElement("canvas");
        canvas.width = 395;
        canvas.height = 600;
        // var canvas = document.getElementById("canvas");
        var context = canvas.getContext('2d');

        var img = new Image();
        if (client?.category === 'Motorcycle')
            img.src = require("../../../images/inspection-motorcycle.jpg");
        else
            img.src = require("../../../images/inspection.jpg");
        //img.src = require(`../../../images/${CommonHelper.getVehicleIcons(client?.category, 'inspection')}.jpg`);
        img.onload = async function (e) {
            context.drawImage(img, 0, 0, 395, 600);

            if (!_.isEmpty(_marks)) {
                await asyncForEach(_marks, async (mark) => {
                    let imgURL = require(`../../../images/${mark.markType}.png`);
                    await loadImage(imgURL).then(img2 => {
                        context.drawImage(img2, mark.dx, mark.dy, 25, 25);
                    });
                });
            }

            var dataURL = canvas.toDataURL();
            document.getElementById(id).src = dataURL;
            //console.log('imageToBase64:', dataURL)
            return dataURL;
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    // It's better to use async image loading.
    const loadImage = url => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`load ${url} fail`));
            img.src = url;
        });
    };

    const handleMoveStageForStatusChange = (_status, state) => {

        let data = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _status)[0];
        if (!_.isEmpty(data) && data.pipeline && data.stage) {
            let _stageHistory = Object.assign([], tradeinPro.stageHistory);
          
            let _stageData = CommonHelper.handleStageHistory(_stageHistory, data.pipeline, data.stage);
            if (!_.isEmpty(_stageData)) {
                _stageHistory.push(_stageData);
                state.stageHistory = Object.assign([], _stageHistory);
                state.pipeline = data.pipeline;
                state.stage = data.stage;
                state.stageDate = window.firebase.firestore.Timestamp.now();
            }
            return state;
        }
        else
            return state;
    }

    const onCurrencyChange = (e, name) => {

        setPrice(e.floatValue);

        setErrorFields({
            ...errorFields,
            [name]: ''
        });
    }

    const handleSubmit = async () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let errors = {};


        if (!wholesalerPrice) {
            formIsValid = false;
            errors['wholesalerPrice'] = errorClass;
        }

        setErrorFields(errors)
        if (!formIsValid) {
            return;
        }

        handleSave();


    }

    const handleSave = () => {
        setLoader(true);

        let objDataOffer = Object.assign({})

        let _currentUser = tradeInOffer.wholeSellerId;
        let _currentDate = window.firebase.firestore.Timestamp.now();

        objDataOffer.modifiedBy = _currentUser;
        objDataOffer.modifiedDate = _currentDate;
        objDataOffer.evaluatedBy = _currentUser;
        objDataOffer.evaluationDate = _currentDate;
        objDataOffer.isSelfEvaluation = false;
        objDataOffer.evaluationPrice = wholesalerPrice;
        //objDataOffer.dealerPrice = wholesalerPrice;
        objDataOffer.status = tipOfferStatus.COMPLETED;
        objDataOffer.projectId = tradeIn?.projectId ? tradeIn?.projectId : null

        //Remove empty value from object
        for (var propName in objDataOffer) {
            if (objDataOffer[propName] === null || objDataOffer[propName] === undefined || objDataOffer[propName] === '' || objDataOffer[propName] === [] || (_.isObject(objDataOffer[propName]) && _.isEmpty(objDataOffer[propName]))) {
                delete objDataOffer[propName];
            }
        }

        let _reqObject = {
            "documentID": props.offerid,
            "collection": `tradeinPro/${tradeInOffer.tradeinProID}/offers`,
            "data": objDataOffer
        }

        //console.log('_reqObject', _reqObject)
        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                saveTradeInProLog();
                if (tradeinPro.status === tipStatus.NEW || tradeinPro.status === tipStatus.INPROGRESS) {
                    saveTradeinPro(tipStatus.PENDING);
                    saveTradeins(tipStatus.PENDING);
                }
                else
                    setLoader(false)
            }
            else {
                setLoader(false)

            }
        });


    }

    const saveTradeinPro = (_status) => {
        var objData = {
            status: _status,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            projectId: tradeIn?.projectId ? tradeIn?.projectId : null

        }
        objData = handleMoveStageForStatusChange(objData.status, objData);

        let _reqObject = {
            "documentID": tradeinProID,
            "collection": `tradeinPro`,
            "data": objData
        }
        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                setLoader(false)
            }
            else {
                setLoader(false)

            }
        });
    }

    const saveTradeins = (_status) => {
        var objData = {
            status: _status,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            projectId: tradeIn?.projectId ? tradeIn?.projectId : null
        }

        let _reqObject = {
            "documentID": tradeIn?.documentID,
            "collection": `tradeins`,
            "data": objData
        }
        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject).then((response) => {
            //console.log(response);
            if (response.data.success) {
                setLoader(false)
            }
            else {
                setLoader(false)

            }
        });
    }

    const saveTradeInProLog = () => {
        var objData = {
            documentID: window.firebase.firestore().collection('tradeins').doc().id,
            addedBy: tradeInOffer.wholeSellerId,
            projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedFrom: 'web',
            offerID: props.offerid,
            tradeinProID,
            isDeleted: false,
            message: `️An offer of ${CommonHelper.formatCurrency(currencySymbol, wholesalerPrice)} has been received from ${tradeInOffer.wholeSaler ? tradeInOffer.wholeSaler.name : ''}.`
        }


        let _reqObject = {
            "documentID": objData.documentID,
            "collection": `tradeinPro/${tradeinProID}/offerLogs`,
            "data": objData
        }
        const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
        genericSaveData(_reqObject).then((response) => {
        });
    }

    const handleNotesClose = (noteTxt, _mentions, _isInternal) => {

        if (noteTxt) {
            const { offerid } = props;
            let objData =
            {
                documentID: window.firebase.firestore().collection('tradeins').doc().id,
                notes: noteTxt,
                addedBy: tradeInOffer.wholeSellerId,
                addedDate: window.firebase.firestore.Timestamp.now(),
                addedFrom: 'web',
                offerID: offerid,
                tradeinProID,
                wholeSalerNotes: true,
                projectId: tradeInOffer?.projectId ? tradeInOffer?.projectId : (tradeIn?.projectId ? tradeIn?.projectId : null),
                isDeleted: false
            }

            if (_isInternal)
                objData.isInternalNotes = true;

            let _reqObject = {
                "documentID": objData.documentID,
                "collection": `tradeinPro/${tradeinProID}/offerNotes`,
                "data": objData
            }
            const genericSaveData = window.firebase.functions().httpsCallable('generic-saveData');
            genericSaveData(_reqObject).then((response) => {
                //console.log(response);
                if (response.data.success) {
                    // toast.notify('notes added successfully', {
                    //     duration: 2000
                    // })
                }
            });

        }
        setShowNotes(false)

    }

    let dynamicDetailList = (!_.isEmpty(dealersettings?.client?.settings?.sortTradeinOptions))
        ? dealersettings.client.settings.sortTradeinOptions :
        Object.assign([], _.uniq(_.union(tradeinSortOptions, Object.assign([], (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true)) ? Array.prototype.map.call(tradeinOptionsDF.filter(m => m.active === true), function (item) { return item.value; }).join(",").split(',') : [])))));


    return (
        <>

            {
                !_.isEmpty(tradeIn) && !_.isEmpty(tradeinProID)
                    ?
                    <>
                        <div className="middle-wrapper tradein-custom-middle">
                            <div className="customer-rec-header">
                                <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                                    <tbody>
                                        <tr>
                                            <td width="100%"><div className="customer-rec-header-left">
                                                {
                                                    !_.isEmpty(client) && client.logoURL
                                                        ?
                                                        <><div className="customer-rec-logo"> <img src={client.logoURL} height="60" /></div></>
                                                        :
                                                        <><div className="customer-rec-dealer">{!_.isEmpty(client) ? client.name : '--'}</div></>
                                                }


                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="customer-rec-title"><Translate text={'Evaluation Request'} /></div>

                            <div className="detailhead">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="detailtbl01">
                                    <tbody>
                                        {
                                            _.chunk(dynamicDetailList.filter(e => !['notes', 'specifications', 'status'].includes(e)), 4).map((stats, i) => {
                                                return <tr key={i}>
                                                    {
                                                        stats.map((rec, index) => {

                                                            return <td key={index} className="grid-two ">


                                                                {(() => {
                                                                    if (rec === 'regDate' || rec === 'complianceDate' || rec === 'buildDate' || rec === 'serviceContFrom' || rec === 'serviceContTo' || rec === 'lastService' || rec === 'warrantyExpiry') {
                                                                        return <>
                                                                            <span><Translate text={rec} /></span><div>{tradeIn[rec] ? moment(tradeIn[rec], 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                                                        </>
                                                                    }
                                                                    else if (rec === 'massageVentilated' || rec === 'contrastStiching' || rec === 'financed' || rec === 'outstandingPayments' || rec === 'sunroof' || rec === 'camera' || rec === 'woodSteering' || rec === 'warranty' || rec === 'reconditioning') {
                                                                        return <>
                                                                            <span><Translate text={rec} /></span><div>{tradeIn[rec] ? 'YES' : 'NO'}</div>
                                                                        </>
                                                                    }
                                                                    else if (rec === 'reconditioningCost') {
                                                                        return <>
                                                                            <span><Translate text={rec} /></span><div>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</div>
                                                                        </>
                                                                    }
                                                                    else if (objTradeIn.hasOwnProperty(rec)) {
                                                                        return <><span><Translate text={rec} /></span><div>{tradeIn[rec] ? tradeIn[rec] : '--'}</div></>
                                                                    }
                                                                    else if (!_.isEmpty(tradeinOptionsDF) && !_.isEmpty(tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0])) {
                                                                        let info = tradeinOptionsDF.filter(m => m.active === true && m.value === rec)[0];
                                                                        if (info.type === 'price')
                                                                            return <><span><Translate text={info.name} /></span><div>{tradeIn[rec] ? <NumberFormat style={{ fontSize: 12 }} value={tradeIn[rec]} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={info.type === 'price' ? currencySymbol : ''} /> : '--'}</div></>
                                                                        else
                                                                            return <><span><Translate text={info.name} /></span><div>{tradeIn[rec] ? tradeIn[rec] : '--'}</div></>
                                                                    }
                                                                })()}
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="clear"></div>
                            </div>
                            {/* <div className="detailhead">
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="detailtbl01">
                                    <tbody>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'make'} /></span>
                                                <div>{make ? make : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'model'} /></span>
                                                <div>{model ? model : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'group'} /></span>
                                                <div>{group ? group : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'modelDescription'} /></span>
                                                <div>{modelDescription ? modelDescription : '--'}</div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'year'} /></span>
                                                <div>{year ? year : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'registrationNumber'} /></span>
                                                <div>{regNo ? regNo : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'registrationExpiry'} /></span>
                                                <div>{regDate ? moment(regDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'mileage'} /></span>
                                                <div>{mileage ? mileage : '--'}</div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'chassisNo'} /></span>
                                                <div>{chassisNo ? chassisNo : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'engineNo'} /></span>
                                                <div>{engineNo ? engineNo : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'transmission'} /></span>
                                                <div>{transmission ? transmission : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'type'} /></span>
                                                <div>{type ? type : '--'}</div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'complianceDate'} /></span>
                                                <div>{complianceDate ? moment(complianceDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'buildDate'} /></span>
                                                <div>{buildDate ? moment(buildDate, 'YYYY-MM-DD').format('MMM YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'engineType'} /></span>
                                                <div>{engineType ? engineType : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'colour'} /></span>
                                                <div>{exteriorColor ? exteriorColor : '--'}</div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'vehicleCondition'} /></span>
                                                <div>{vehicleCondition ? vehicleCondition : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'interiorColor'} /></span>
                                                <div>{interiorColor ? interiorColor : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'trim'} /></span>
                                                <div>{trim ? trim : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'doors'} /></span>
                                                <div>{doors ? doors : '--'}</div>
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'engineSize'} /></span>
                                                <div>{engineSize ? engineSize : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'owners'} /></span>
                                                <div>{owners ? owners : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'financeCo'} /></span>
                                                <div>{financeCo ? financeCo : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'spareKey'} /></span>
                                                <div>{spareKey ? spareKey : '--'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'serviceHistory'} /></span>
                                                <div>{serviceHistory ? serviceHistory : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'cargoBlind'} /></span>
                                                <div>{cargoBlind ? cargoBlind : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'logBooks'} /></span>
                                                <div>{logBooks ? logBooks : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'headRests'} /></span>
                                                <div>{headRests ? headRests : '--'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'frontRightWheel'} /></span>
                                                <div>{frontRightWheel ? frontRightWheel : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'frontLeftWheel'} /></span>
                                                <div>{frontLeftWheel ? frontLeftWheel : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'rearRightWheel'} /></span>
                                                <div>{rearRightWheel ? rearRightWheel : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'rearLeftWheel'} /></span>
                                                <div>{rearLeftWheel ? rearLeftWheel : '--'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'serviceContFrom'} /></span>
                                                <div>{serviceContFrom ? moment(serviceContFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'serviceContTo'} /></span>
                                                <div>{serviceContTo ? moment(serviceContTo, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'lastService'} /></span>
                                                <div>{lastService ? moment(lastService, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'warrantyExpiry'} /></span>
                                                <div>{warrantyExpiry ? moment(warrantyExpiry, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'spareWheel'} /></span>
                                                <div>{spareTyre ? spareTyre : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'sdCard'} /></span>
                                                <div>{sdCard ? sdCard : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'tyreAge'} /></span>
                                                <div>{tyreAge ? tyreAge : '--'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'reconditioningCost'} /></span>
                                                <div>{reconditioningCost ? <NumberFormat style={{ fontSize: 12 }} value={reconditioningCost} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={currencySymbol} /> : '--'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'massageVentilated'} /></span>
                                                <div>{massageVentilated ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'contrastStiching'} /></span>
                                                <div>{contrastStiching ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'sunroof'} /></span>
                                                <div>{sunroof ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'camera'} /></span>
                                                <div>{camera ? 'YES' : 'NO'}</div>
                                            </div></td>
                                        </tr>

                                        <tr>
                                            <td className="grid-two "><div> <span><Translate text={'woodSteering'} /></span>
                                                <div>{woodSteering ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'outstandingPayments'} /></span>
                                                <div>{outstandingPayments ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'reconditioning'} /></span>
                                                <div>{reconditioning ? 'YES' : 'NO'}</div>
                                            </div></td>
                                            <td className="grid-two "><div> <span><Translate text={'financed'} /></span>
                                                <div>{financed ? 'YES' : 'NO'}</div>
                                            </div></td>
                                        </tr>


                                    </tbody>
                                </table>
                                <div className="clear"></div>
                            </div> */}

                            {
                                !_.isEmpty(specifications) && !_.isEmpty(dynamicDetailList.filter(e => ['specifications'].includes(e))[0])
                                    ?
                                    <>
                                        <div className="table-wraper brd-none page-break mt-3">
                                            <div className="table-head"><Translate text={'Specifications'} /></div>
                                            <div className="terms text-area-space mt-2"
                                                dangerouslySetInnerHTML={{ __html: (specifications ? '&middot; ' + specifications.replace(/\n/g, "<br/> &middot; ") : '--') }} />

                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }

                            <div className="imggal-pic" >
                                <div className="float-left"> <span><Translate text={'vehicleImages'} /></span>
                                    <>
                                        {
                                            (!_.isEmpty(images) || !_.isEmpty(sidePhotos) || (!_.isEmpty(marks) && marks.find(item => !_.isEmpty(item.images))))
                                                ?
                                                <>
                                                    <ul>
                                                        {
                                                            !_.isEmpty(images) && images.map((data, index) => (
                                                                <li key={index}>
                                                                    <a className="venobox vbox-item" data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                        <img id={`tradein-before-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </li>
                                                            ))
                                                        }

                                                        {!_.isEmpty(sidePhotos) && Object.keys(sidePhotos).map((key, index) => {
                                                            if (sidePhotos[key]) {
                                                                return (<li key={index}>
                                                                    <a className="venobox vbox-item" data-fancybox={`tradein_img`} href={sidePhotos[key]} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                        <img id={`tradein-side-img-${index}`} src={sidePhotos[key]} width="35" height="35" alt="" className="img-object-fit" />
                                                                    </a>
                                                                </li>)
                                                            }

                                                        })}

                                                        {
                                                            !_.isEmpty(marks) && marks.map((mark, index) => {
                                                                return <div key={index}>
                                                                    {!_.isEmpty(mark.images) && mark.images.map((data, index) => (
                                                                        <li key={index}>
                                                                            <a className="venobox vbox-item" data-fancybox={`tradein_img`} href={data} onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                                <img id={`tradein-mark-img-${index}`} src={data} width="35" height="35" alt="" className="img-object-fit" />
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </div>
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                    <ul>
                                                        <li>
                                                            <a href="#" onClick={(e) => { e.preventDefault(); }} width="35" height="35">
                                                                <img src={_images[CommonHelper.getNoVehicleIcons(client?.category, 'novehicle')]} width="35" height="35" alt="" className="img-object-fit" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </>
                                        }

                                    </>

                                </div>
                                <div className="float-right service-rec-inspection">
                                    <a data-fancybox={`tradein_inspection_img`} href={inspectionSnapshot ? inspectionSnapshot : _images[CommonHelper.getNoVehicleIcons(client?.category, 'inspection')]} onClick={(e) => { e.preventDefault(); }} width="80" height="80">
                                        <button type="button" className="btn btn-inspection-multi float-right"><i className={CommonHelper.getVehicleIcons(client?.category, 'ico icon-inspection')}></i> <Translate text={'vehicleInspection'} />
                                            <div className="float-right ml-2"><i className="fa fa-angle-right" aria-hidden="true"></i></div>
                                            <div className="float-right ml-2">
                                                <span className="badge badge-inspection">{!_.isEmpty(marks) ? marks.length : 0}</span>
                                            </div>

                                        </button>
                                    </a>

                                </div>
                            </div>

                            <div className="clear"></div>
                            <div className="right-notes">
                                <h4> <Translate text={'Notes'} />
                                    <button className="btn btn-sm btn-default" onClick={(e) => { e.preventDefault(); setShowNotes(true) }}><i className="ico icon-add"></i> <Translate text={'addNote'} /></button>
                                </h4>

                                {
                                    offerNotes
                                        ?
                                        <>
                                            {
                                                _.orderBy(offerNotes, ['addedDate'], ['desc']).map((_data, index) => {
                                                    return <div className="notes text-area-space" key={index}>
                                                        {_data.notes}
                                                        <div className="notes-sub-div">

                                                            <span>{_data.addedDate ? moment.unix(_data.addedDate.seconds).format('DD MMM YYYY h:mm A') : '--'} </span>
                                                            &nbsp;{' - '}&nbsp;
                                                            {CommonHelper.getUserNamebyId(users, _data.addedBy)}

                                                            {
                                                                (!_data.wholeSalerNotes) ? (
                                                                    <div className="badge badge-pill badge-input-white ml-2 text-uppercase">{'dealer'}</div>
                                                                ) : (<></>)
                                                            }

                                                            {
                                                                (_data.isInternalNotes) ? (
                                                                    <div className="badge badge-pill badge-input-white ml-1 text-uppercase">{'Internal'}</div>
                                                                ) : (<></>)
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="no-date-notes"><Translate text={'No notes created'} /></div>
                                        </>
                                }



                            </div>


                            <div className="tradein-evaluation-offer-wrapper">
                                {
                                    evaluationPrice
                                        ?
                                        <>
                                            <div className="inputwrap">

                                                <p className="top-fixmob pb-1"><Translate text={'You have quoted the price'} /> </p>
                                                <div className="top-fixmob">

                                                    <NumberFormat
                                                        style={{ fontSize: 25, fontWeight: 700 }}
                                                        value={evaluationPrice}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol} />


                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="inputwrap">
                                                {/* <a href="#"><div className="log"> <i className="fa fa-file-text" aria-hidden="true"></i> Offers Log </div></a> */}
                                                {
                                                    isReRequested
                                                        ?
                                                        <p className="top-fixmob"><b><Translate text={'The manager has re-requested for the price'} /></b> </p>
                                                        :
                                                        <p className="top-fixmob"><b><Translate text={'Please insert your offer and click Send button'} /></b> </p>
                                                }

                                                <div className="form">

                                                    <NumberFormat
                                                        id={'wholesalerPrice'}
                                                        decimalScale={2}
                                                        allowNegative={true}
                                                        thousandSeparator={true}
                                                        prefix={currencySymbol}
                                                        placeholder={currencySymbol}
                                                        className={`form-control input-price ${errorFields["wholesalerPrice"]}`}
                                                        onValueChange={(e) => {
                                                            onCurrencyChange(e, 'wholesalerPrice')
                                                        }}
                                                        value={wholesalerPrice ? wholesalerPrice : null}
                                                    />


                                                    <div className="btn-wrap">
                                                        {/* <input type="submit" className="green-btn pull-right" value="Send" /> */}
                                                        <button type="button" className="btn btn-primary green-btn float-right ml-2" onClick={(e) => handleSubmit(e)}>
                                                            {
                                                                loader ?
                                                                    (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                                                    : (<></>)
                                                            }
                                                            <Translate text={'send'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }



                            </div>

                            {
                                previousWSPrice
                                    ?
                                    <div className="wholesaler-last-offer-alert alert-user mt-2">
                                        <Translate text={'Previous Offer'} /> : <b className="alert-link"><NumberFormat
                                            value={previousWSPrice}
                                            displayType={'text'}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={currencySymbol} /></b>

                                    </div>
                                    :
                                    <></>
                            }
                            <div className="clear"></div>












                            <footer>

                                <div className="footer-cpy">
                                    <div className="footer-bottom">

                                        <div className="cpy-wrap"> Powered by <a href="http://fusionsd.com.au/" target="_blank">Fusion SD </a></div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </>
                    :
                    <>
                        {
                            pagenotFound
                                ?
                                <>
                                    <div
                                        className='middle-wrapper h-100 mt-0'
                                        style={{
                                            minHeight: '90vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: 'floralwhite'
                                        }}
                                    >
                                        <div className='spinner-loader h-100'>
                                            <div className='no-data-flex-wrap h-100 justify-content-center align-items-center text-primary'>
                                                <div className='no-data-img-wrap'>
                                                    <img src={_images.pagenotFound} height='190' alt='' />
                                                </div>
                                                <div className='no-data-txt-wrap'>
                                                    <div className='no-data-content-head'>
                                                        Page Not Found.
                                                    </div>
                                                    <div className='no-data-content'>
                                                        The page you requested could not be found or may be deleted.
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <><div className="mt-5"><ContentOverlay active={true} /></div></>
                        }


                    </>

            }


            <PopUpModal show={showNotes}>
                <AddNotes
                    show={showNotes}
                    handleClose={handleNotesClose}
                />
            </PopUpModal>
        </>



    )
};

export default TradeInDetails;
