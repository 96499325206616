import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment';
import { oemTabs, NoDataDiv, LoadingDiv } from '../constants';
import CommonHelper from '../../../../services/common';
import { enquiryStatus } from '../../../../services/enum'
import { firestoreDB } from '../../../../services/helper';

const StockSearch = ({
	showSearchPanel,
	searchText,
	dealersettings,
	handleClose,
	handleCompanyView,
	handleOpenEditActivity,
	handleInBoundOpen,
	handleOpenStock,
	handleDealerOpen,
	history,
	pipeline,
	getDealerName
}) => {
	const [activeTab, setActivetab] = useState('all');
	const [stock, setStock] = useState([]);
	const [dealers, setDealers] = useState([]);
	const [loader, setLoader] = useState([]);
	const [all, setAll] = useState([]);
	const [brands, setBrands] = useState([]);
	const [contactDetails, setContactDetails] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [activities, setActivities] = useState([]);
	const [enquiries, setEnquiries] = useState([]);

	const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

	const viewOtherContact = ((!_.isEmpty(dealersettings) &&
		!_.isEmpty(dealersettings.rolePermissions) &&
		!_.isEmpty(dealersettings.rolePermissions.permissions) &&
		dealersettings.rolePermissions.permissions.viewOtherContact) ? true : false);

	const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])

	useEffect(() => {
		const brandCollection = window.firebase
			.firestore()
			.collection(`brands`)
			.where('category', '==', 'Automotive')
			.where('isDeleted', '==', false)
			//.orderBy('addedDate', 'desc')
			.onSnapshot(docSnapshot => {
				let _brand = [];
				docSnapshot.docs.forEach(doc => {
					const branddoc = {
						...doc.data(),
						documentID: doc.id,
					};
					_brand.push({
						label: branddoc.name,
						value: doc.id,
						data: branddoc,
						active: _.isBoolean(doc.active) ? doc.active : true,
					});
				});
				setBrands(_.orderBy(_brand, ["addedDate"], ["desc"]));
			});
		return () => {
			brandCollection && brandCollection();
		};
	}, []);

	useEffect(() => {
		if (searchText.length > 0) {
			setLoader(true);
			let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchText)


			let companiesInContacts = []
			let stocksInEnquiries = []


			let refEnquiries = firestoreDB(dealersettings)
				.firestore()
				.collection('enquiries')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)

			if (dealersettings.level === 'oem') {
				refEnquiries = refEnquiries.where('oemID', '==', dealersettings.oemID)
			} else if (dealersettings.level === 'region') {
				refEnquiries = refEnquiries.where('regionID', '==', dealersettings.regionID)
			} else if (dealersettings.level === 'group') {
				refEnquiries = refEnquiries.where('groupID', '==', dealersettings.groupID)
			}

			if (!viewOtherEnquiry)
				refEnquiries = refEnquiries.where('owner', '==', dealersettings.id)

			refEnquiries = refEnquiries
				.orderBy('stageDate', 'desc')
				.limit(10);

			let refOEMConvertedEnquiries = firestoreDB(dealersettings)
				.firestore()
				.collection('enquiries')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
			if (!viewOtherEnquiry)
				refOEMConvertedEnquiries = refOEMConvertedEnquiries.where('owner', '==', dealersettings.id)

			if (dealersettings.level === 'oem') {
				refOEMConvertedEnquiries = refOEMConvertedEnquiries
					.where('oemID', '==', dealersettings.oemID)
					.where('oemStatus', '==', 'converted')
					.orderBy('stageDate', 'desc')
			} else if (dealersettings.level === 'region') {
				refOEMConvertedEnquiries = refOEMConvertedEnquiries
					.where('regionID', '==', dealersettings.regionID)
					.where('oemStatus', '==', 'converted')
					.orderBy('oemConvertedDate', 'desc')
			} else if (dealersettings.level === 'group') {
				refOEMConvertedEnquiries = refOEMConvertedEnquiries
				.where('groupID', '==', dealersettings.groupID)
				.where('oemStatus', '==', 'converted')
				.orderBy('oemConvertedDate ', 'desc')
			}

			refOEMConvertedEnquiries = refOEMConvertedEnquiries
				.limit(10);


			let refInboundLeads = '';
			if (pipeline.filter(item => item.value === 'LeadsBucket').length > 0) {
				refInboundLeads = firestoreDB(dealersettings)
					.firestore()
					.collection('inboundLeads')
					.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
					.where('status', '==', 0)
					.where('isDeleted', '==', false)

				if (dealersettings.level === 'oem') {
					refInboundLeads = refInboundLeads.where('oemID', '==', dealersettings.oemID)
				} else if (dealersettings.level === 'region') {
					refInboundLeads = refInboundLeads.where('regionID', '==', dealersettings.regionID)
				} else if (dealersettings.level === 'group') {
					refInboundLeads = refInboundLeads.where('groupID', '==', dealersettings.groupID)
				}

				refInboundLeads = refInboundLeads
					.orderBy('addedDate', 'desc')
					.limit(10);
			}

			let refStock = window.firebase
				.firestore()
				.collection('stock')
				.where('settingsID', '==', dealersettings.oemID)
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)
				.orderBy('modifiedDate', 'desc')
				.limit(10);

			let refDealers = window.firebase
				.firestore()
				.collection('clients')
				.where('settingsID', '==', dealersettings.oemID)
				// .orderBy('addedDate', 'desc')

			let refContactDetails;
			refContactDetails = firestoreDB(dealersettings)
				.firestore()
				.collection('contactDetails')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)

			if (dealersettings.level === 'oem') {
				refContactDetails = refContactDetails.where('oemID', '==', dealersettings.oemID)
			} else if (dealersettings.level === 'region') {
				refContactDetails = refContactDetails.where('regionID', '==', dealersettings.regionID)
			} else if (dealersettings.level === 'group') {
				refContactDetails = refContactDetails.where('groupID', '==', dealersettings.groupID)
			}

			refContactDetails = refContactDetails
				.orderBy('firstName', 'asc')
				.limit(10);

			let refCompanies;
			refCompanies = firestoreDB(dealersettings)
				.firestore()
				.collection('companies')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)


			if (dealersettings.level === 'oem') {
				refCompanies = refCompanies.where('oemID', '==', dealersettings.oemID)
			} else if (dealersettings.level === 'region') {
				refCompanies = refCompanies.where('regionID', '==', dealersettings.regionID)
			} else if (dealersettings.level === 'group') {
				refCompanies = refCompanies.where('groupID', '==', dealersettings.groupID)
			}

			refCompanies = refCompanies
				.orderBy('name', 'asc')
				.limit(10);

			let refActivities = firestoreDB(dealersettings).firestore().collection('activities')
				.where('type', '==', 'activity')
				.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
				.where('isDeleted', '==', false)

			if (dealersettings.level === 'oem') {
				refActivities = refActivities.where('oemID', '==', dealersettings.oemID)
			} else if (dealersettings.level === 'region') {
				refActivities = refActivities.where('regionID', '==', dealersettings.regionID)
			} else if (dealersettings.level === 'group') {
				refActivities = refActivities.where('groupID', '==', dealersettings.groupID)
			}

			refActivities = refActivities
				.orderBy('startDate', 'desc')
				.limit(5);

			var dashboardPipelines = combineLatest(
				collection(refStock),
				collection(refDealers),
				collection(refContactDetails),
				collection(refCompanies),
				collection(refEnquiries),
				refInboundLeads ? collection(refInboundLeads) : of([]),
				collection(refActivities),
				collection(refOEMConvertedEnquiries)
			)
				.pipe(
					map(([stock, dealers, contactDetails, companies, enquiries, inboundLeads, activities, OEMenquiries]) => {
						return [
							_.map(stock, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'stock',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(dealers, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'dealers',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(contactDetails, function (obj) {
								let contactObj = obj.data()
								// if (!_.isEmpty(contactObj.company) && contactObj.company.documentID) {
								// 	companiesInContacts.push({...contactObj.company, renderType: 'company',})
								// }
								return {
									...contactObj,
									documentID: obj.id,
									renderType: 'contactDetail',
									sortDate: contactObj.addedDate,
								};
							}),
							_.map(companies, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'company',
									sortDate: obj.data().addedDate,
								};
							}),
							_.map(enquiries, function (obj) {
								let enquiryObj = obj.data()
								if (!_.isEmpty(enquiryObj.requirement)) {
									if (!_.isEmpty(enquiryObj.requirement.stock) && enquiryObj.requirement.stock.documentID) {
										stocksInEnquiries.push({ ...enquiryObj.requirement.stock, renderType: 'stock', })
									}
								}
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'enquiry',
									sortDate: obj.data().stageDate,
									isInbound: false,
								};
							}),
							_.map(inboundLeads, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'enquiry',
									sortDate: obj.data().addedDate,
									isInbound: true,
								};
							}),
							_.map(activities, function (obj) {
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'activity',
									sortDate: obj.data().startDate,
								};
							}),
							_.map(OEMenquiries, function (obj) {
								let enquiryObj = obj.data()
								if (!_.isEmpty(enquiryObj.requirement)) {
									if (!_.isEmpty(enquiryObj.requirement.stock) && enquiryObj.requirement.stock.documentID) {
										stocksInEnquiries.push({ ...enquiryObj.requirement.stock, renderType: 'stock', })
									}
								}
								return {
									...obj.data(),
									documentID: obj.id,
									renderType: 'enquiry',
									sortDate: obj.data().stageDate,
									isInbound: false,
								};
							}),
						];
					}),
				)
				.subscribe(allDocs => {
					let filterActivities = allDocs[6]
					let _inboundLeads = [...allDocs[5]].filter(lead => !_.isEmpty(lead.oemStatus))
					let allEnquiries = [..._inboundLeads, ...allDocs[4], ...allDocs[7]]
					let allStocks = _.uniqBy([...allDocs[0], ...stocksInEnquiries], 'documentID')
					if (dealersettings.level && (dealersettings.level === 'group' || dealersettings.level === 'region')) {
						allStocks = []
					}
					let allCompanies = _.uniqBy([...allDocs[3], ...companiesInContacts], 'documentID')
					let FilteredDealers = allDocs[1].filter(dealer => dealersettings.clients.some(client => client.id === dealer.documentID))
					FilteredDealers = FilteredDealers.filter(dealer => dealer.name.toLowerCase().includes(formattedSearchText.toLowerCase().trim()))
					setStock(allStocks);
					setDealers(FilteredDealers);
					setActivities(filterActivities)
					setEnquiries(allEnquiries)
					setAll([...allStocks, ...FilteredDealers, ...allEnquiries, ...filterActivities, ...allCompanies, ...allDocs[2]]);
					setContactDetails(allDocs[2]);
					setCompanies(allCompanies);
					setLoader(false);
					dashboardPipelines && dashboardPipelines.unsubscribe();
				});
		}
		return () => {
			dashboardPipelines && dashboardPipelines.unsubscribe();
		};
	}, [searchText, dealersettings.client.documentID]);

	return (
		<div className='dropdown' style={{ float: 'left', position: 'inherit' }}>
			<div
				className='dropdown-menu dropdown-menu-left'
				aria-labelledby='dropdownMenuButton'
				style={{ display: showSearchPanel ? 'block' : '', left: '120px' }}
			>
				<div className='form-style globalsearch-wrap'>
					<div className='globalsearch-nav-wraper'>
						<div className='globalsearch-nav pl-0'>
							<Tab.Container defaultActiveKey={activeTab}>
								<Nav as='ul' bsPrefix='ul-nav' onSelect={k => setActivetab(k)}>
									{oemTabs
										// filter for removing stock on group and region levels
										.filter(tab => {
											if (dealersettings.level && (dealersettings.level === 'group' || dealersettings.level === 'region') && tab.value === 'stock') {
												return false
											} else {
												return true
											}
										})
										.map((rec, index) => {
											return (
												<OverlayTrigger
													key={index}
													placement='bottom'
													overlay={
														<Tooltip><span>{rec.title}</span></Tooltip>
													}
												>
													<Nav.Item
														as='li'
														bsPrefix='li-nav'
														key={index}
													>
														<Nav.Link eventKey={rec.value} bsPrefix='li-a-nav'>
															<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, rec.ico, true, rec.icoVehicle)}></i>
															{rec.name}
														</Nav.Link>
													</Nav.Item>
												</OverlayTrigger>
											);
										})}
								</Nav>
							</Tab.Container>
						</div>
					</div>
					<div className='globalsearch-body'>
						{loader ? (
							<LoadingDiv />
						) : (
							<div className='global-item-wraper'>
								{activeTab === 'all' ? (
									<>
										{all
											// _.orderBy(all, ['sortDate.seconds'], ['desc'])
											.map(obj =>
												obj.renderType === 'stock' ? (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleOpenStock(obj.documentID);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.extColor ? obj.extColor + ',' : ''} ${obj.year
																	}`}
																{obj.stockNo ? ` - ${obj.stockNo}` : ''}
															</span>
															{obj.saleType && (
																<div className='global-status'>
																	<div className='badge badge-pill badge-white'>
																		{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
																			obj.saleType, '')}
																	</div>
																</div>
															)}
														</div>
													</a>
												) : obj.renderType === 'dealers' ? (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleDealerOpen(obj, brands);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-dealers'></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.name}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.phone ? obj.phone : obj.email}`}
															</span>
														</div>
													</a>
												) : obj.renderType === 'enquiry' ? (
													<>
														{obj.isInbound ? (
															<a
																className='global-item d-flex'
																onClick={(e) => {
																	e.preventDefault();
																	handleInBoundOpen(obj)
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className='ico icon-enquiry'></i>
																	</div>
																</div>
																<div>
																	{!_.isEmpty(obj.requirement) ? (
																		<>
																			{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.stock.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.stock.model
																							: ''
																						}`}{' '}
																				</span>
																			) : (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.model
																							: ''
																						}`}{' '}
																				</span>
																			)}
																		</>
																	) : (
																		<></>
																	)}
																	<span className='global-info'>
																		{' '}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{obj.displayID ? ` - ${obj.displayID}` : ''}
																	</span>
																	<div className='global-status'>
																		<span
																			className={`status-pending`}
																		>
																			{' '}
																			Pending{' '}
																		</span>
																	</div>
																</div>
															</a>
														) : (
															<a
																className='global-item d-flex'
																onClick={(e) => {
																	e.preventDefault();
																	history.push(`/`);
																	history.push(`enquiry/details/${obj.documentID}`);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className='ico icon-enquiry'></i>
																	</div>
																</div>
																<div>
																	{!_.isEmpty(obj.requirement) ? (
																		<>
																			{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.stock.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.stock.model
																							: ''
																						}`}{' '}
																				</span>
																			) : (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.model
																							: ''
																						}`}{' '}
																				</span>
																			)}
																		</>
																	) : (
																		<></>
																	)}
																	<span className='global-info'>
																		{' '}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{obj.displayID ? ` - ${obj.displayID}` : ''}
																	</span>
																	{!_.isEmpty(obj) && obj.status && (
																		<>
																			{(() => {
																				let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																				let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																				return <div className='global-status'>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<span
																									className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									{_objCurrentStatus.name}
																								</span>
																							</>
																							:
																							<></>
																					}
																				</div>
																			})()}

																		</>
																	)}
																</div>
															</a>
														)}
													</>
												) : obj.renderType === 'contactDetail' ? (
													<a
														className='global-item d-flex'
														onClick={e => {
															e.preventDefault();
															history.push(`/`);
															history.push(
																`contacts/details/${obj.documentID}`,
															);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-Contacts'></i>
															</div>
														</div>
														<div>
															{(obj.firstName || obj.lastName) && (
																<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
															)}
															{obj.phone ? (
																<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
															) : obj.email ? (
																<span className='global-info'>{`${obj.email}`}</span>
															) : (
																<></>
															)}
															{/* {enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
															(<>
															<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>} */}
														</div>
													</a>
												) : obj.renderType === 'company' ? (
													<>
														<a
															className='global-item d-flex'
															href='#'
															onClick={() => {
																history.push(`/`)
																history.push(`/company/details/${obj.documentID}`)
																handleClose();
															}}
															key={obj.documentID}
														>
															<div className='global-space'>
																<div className='global-icon-circle'>
																	<i className='ico icon-company'></i>
																</div>
															</div>
															<div>
																{obj.name && (
																	<span className='global-header'>{obj.name}</span>
																)}
																{obj.address && (
																	<span className='global-info'>
																		{`${obj.address} ${obj.areaCode}`}
																	</span>
																)}
																{/* {enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
																	(<>
																	<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
																	</>) : <></>} */}
															</div>
														</a>
													</>
												) : obj.renderType === 'activity' ? (
													<>
														{obj.type === 'activity' ? (
															<a
																className='global-item d-flex'
																href='#'
																onClick={e => {
																	e.preventDefault();
																	handleOpenEditActivity(obj);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																		<i className='ico icon-date'></i>
																	</div>
																</div>
																<div>
																	{obj.title && (
																		<span className='global-header'>
																			{obj.title}
																		</span>
																	)}
																	{!_.isEmpty(obj.contact) && (
																		<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																	)}
																	{!_.isEmpty(obj.addedDate) && (
																		<div className='global-date'>
																			{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																		</div>
																	)}
																</div>
															</a>
														) : (
															<Link
																className='global-item d-flex'
																to={
																	obj.enquiryID
																		? `enquiry/details/${obj.enquiryID}?comments=${obj.documentID}`
																		: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
																}
																key={obj.documentID}
																onClick={handleClose}
															>
																<div className='global-space'>
																	<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																		<i className='ico icon-date'></i>
																	</div>
																</div>
																<div>
																	{obj.title && (
																		<span className='global-header'>
																			{obj.title}
																		</span>
																	)}
																	{!_.isEmpty(obj.contact) && (
																		<span className='global-info'>{CommonHelper.displayContactName([], obj.contact, '--')}</span>
																	)}
																	{!_.isEmpty(obj.addedDate) && (
																		<div className='global-date'>
																			{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																		</div>
																	)}
																</div>
															</Link>
														)}
													</>
												) : (
													<></>
												),
											)}
									</>
								) : activeTab === 'dealers' ? (
									<>
										{dealers.length > 0 ? (
											<>
												{dealers.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleDealerOpen(obj, brands);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-dealers'></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.name}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.phone ? obj.phone : obj.email}`}
															</span>
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'stock' ? (
									<>
										{stock.length > 0 ? (
											<>
												{stock.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={e => {
															e.preventDefault();
															handleOpenStock(obj.documentID);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className={CommonHelper.getVehicleIcons(dealersettings?.client?.category, 'ico icon-Stock')}></i>
															</div>
														</div>
														<div>
															<span className='global-header'>
																{`${obj.make} ${obj.model}`}
															</span>
															<span className='global-info'>
																{' '}
																{`${obj.extColor ? obj.extColor + ',' : ''} ${obj.year
																	}`}
																{obj.stockNo ? ` - ${obj.stockNo}` : ''}
															</span>
															{obj.saleType && (
																<div className='global-status'>
																	<div className='badge badge-pill badge-white'>
																		{CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.salesType,
																			obj.saleType, '')}
																	</div>
																</div>
															)}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'enquiry' ? (
									<>
										{enquiries.length > 0 ? (
											<>
												{enquiries.map(obj => (
													<>
														{obj.isInbound ? (
															<a
																className='global-item d-flex'
																onClick={(e) => {
																	e.preventDefault();
																	handleInBoundOpen(obj)
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className='ico icon-enquiry'></i>
																	</div>
																</div>
																<div>
																	{!_.isEmpty(obj.requirement) ? (
																		<>
																			{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.stock.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.stock.model
																							: ''
																						}`}{' '}
																				</span>
																			) : (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.model
																							: ''
																						}`}{' '}
																				</span>
																			)}
																		</>
																	) : (
																		<></>
																	)}
																	<span className='global-info'>
																		{' '}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{obj.displayID ? ` - ${obj.displayID}` : ''}
																	</span>
																	<div className='global-status'>
																		<span
																			className={`status-pending`}
																		>
																			{' '}
																			Pending{' '}
																		</span>
																	</div>
																</div>
															</a>
														) : (
															<a
																className='global-item d-flex'
																onClick={(e) => {
																	e.preventDefault();
																	history.push(`/`);
																	history.push(`enquiry/details/${obj.documentID}`);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className='global-icon-circle'>
																		<i className='ico icon-enquiry'></i>
																	</div>
																</div>
																<div>
																	{!_.isEmpty(obj.requirement) ? (
																		<>
																			{!_.isEmpty(obj.requirement.stock) && obj.requirement.stock.make ? (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.stock.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.stock.model
																							: ''
																						}`}{' '}
																				</span>
																			) : (
																				<span className='global-header'>
																					{' '}
																					{`${obj.requirement
																						? obj.requirement.make
																						: ''
																						} ${obj.requirement
																							? obj.requirement.model
																							: ''
																						}`}{' '}
																				</span>
																			)}
																		</>
																	) : (
																		<></>
																	)}
																	<span className='global-info'>
																		{' '}
																		{!_.isEmpty(obj.contact) && (CommonHelper.displayContactName([], obj.contact, '--'))}
																		{obj.displayID ? ` - ${obj.displayID}` : ''}
																	</span>
																	{!_.isEmpty(obj) && obj.status && (
																		<>
																			{(() => {
																				let __status = (obj.status && obj.status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : obj.status;
																				let _objCurrentStatus = __status && enquiryAllStatus.filter(e => e.value === __status)[0]

																				return <div className='global-status'>
																					{
																						_objCurrentStatus
																							?
																							<>
																								<span
																									className={`status-${obj.status === 'open' ? 'open' : 'empty'} mr-2`}
																									style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
																								>
																									{_objCurrentStatus.name}
																								</span>
																							</>
																							:
																							<></>
																					}
																				</div>
																			})()}

																		</>
																	)}
																</div>
															</a>
														)}
													</>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'contacts' ? (
									<>
										{contactDetails.length > 0 ? (
											<>
												{contactDetails.map(obj => (
													<a
														className='global-item d-flex'
														onClick={(e) => {
															e.preventDefault();
															history.push(`/`);
															history.push(`contacts/details/${obj.documentID}`);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-Contacts'></i>
															</div>
														</div>
														<div>
															{(obj.firstName || obj.lastName) && (
																<span className='global-header'>{`${CommonHelper.displayContactName([], obj, '--')}`} {obj.preferredName ? <span className="global-header-pref-name"> ({obj.preferredName})</span> : (<></>)}</span>
															)}
															{obj.phone ? (
																<span className='global-info'>{CommonHelper.getFullPhone(obj.phoneCode, obj.phone)}</span>
															) : obj.email ? (
																<span className='global-info'>{obj.email}</span>
															) : (
																<></>
															)}
															{/* {enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
															(<>
															<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>} */}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'company' ? (
									<>
										{companies.length > 0 ? (
											<>
												{companies.map(obj => (
													<a
														className='global-item d-flex'
														href='#'
														onClick={() => {
															history.push(`/`)
															history.push(`/company/details/${obj.documentID}`)
															// handleCompanyView(obj.documentID);
															handleClose();
														}}
														key={obj.documentID}
													>
														<div className='global-space'>
															<div className='global-icon-circle'>
																<i className='ico icon-company'></i>
															</div>
														</div>
														<div>
															{obj.name && (
																<span className='global-header'>{obj.name}</span>
															)}
															{obj.address && (
																<span className='global-info'>
																	{`${obj.address} ${obj.areaCode}`}
																</span>
															)}
															{/* {enableSharedContact && dealersettings.group && obj.clientID !== dealersettings.client.id ? 
															(<>
															<span className="tbl-sub-dealer">{getDealerName(obj)}</span>
															</>) : <></>} */}
														</div>
													</a>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : activeTab === 'activity' ? (
									<>
										{activities.length > 0 ? (
											<>
												{_.orderBy(
													activities,
													['sortDate.seconds'],
													['desc'],
												).map(obj => (
													<>
														{obj.type === 'activity' ? (
															<a
																className='global-item d-flex'
																href='#'
																onClick={e => {
																	e.preventDefault();
																	handleOpenEditActivity(obj);
																	handleClose();
																}}
																key={obj.documentID}
															>
																<div className='global-space'>
																	<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																		<i className='ico icon-date'></i>
																	</div>
																</div>
																<div>
																	{obj.title && (
																		<span className='global-header'>{obj.title}</span>
																	)}
																	{!_.isEmpty(obj.contact) && (
																		<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																	)}
																	{!_.isEmpty(obj.addedDate) && (
																		<div className='global-date'>
																			{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																		</div>
																	)}
																</div>
															</a>
														) : (
															<Link
																className='global-item d-flex'
																to={
																	obj.enquiryID
																		? `enquiry/details/${obj.enquiryID}?comments=${obj.documentID}`
																		: `contacts/details/${obj.contactID}?comments=${obj.documentID}`
																}
																onClick={handleClose}
															>
																<div className='global-space'>
																	<div className={`global-icon-circle ${obj.isDone ? 'global-completed-circle' : !obj.isDone && moment().startOf('day').diff(moment(obj.startDate.toDate())) > 0 ? 'global-overdue-circle' : ''}`}>
																		<i className='ico icon-date'></i>
																	</div>
																</div>
																<div>
																	{obj.title && (
																		<span className='global-header'>{obj.title}</span>
																	)}
																	{!_.isEmpty(obj.contact) && (
																		<span className='global-info'>{`${CommonHelper.displayContactName([], obj.contact, '--')}`}</span>
																	)}
																	{!_.isEmpty(obj.addedDate) && (
																		<div className='global-date'>
																			{moment(obj.sortDate.toDate()).fromNow()}{obj.isDone && obj.completedDate ? ` - Completed Date: ${moment(obj.completedDate.toDate()).format(`DD/MM/YYYY hh:mm A`)}` : ''}
																		</div>
																	)}
																</div>
															</Link>
														)}
													</>
												))}
											</>
										) : (
											<NoDataDiv />
										)}
									</>
								) : (
									<></>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StockSearch;
