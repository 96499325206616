/** LIBRARIES */
import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash'
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap'
/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../components/customdropdown';
import Translate from '../../constants/translate';
import Filters from './filters'

const FilterPanel = (props) => {
    const [searchKeywords, setSearchKeywords] = useState('')
    const [eventsProPipelineFilter, setPipelineFilter] = useState({})
    const [counts, setCounts] = useState({ Draft: 0, Published: 0, Live: 0, Closed: 0, total: 0 });
    const [searchText, setSearchText] = useState(props.searchText)

    const eventsProAllStatus = Object.assign([], props.dealersettings?.client?.eventsPro?.eventsProStatus)

    useEffect(() => {
        setSearchText(props.searchText);
    }, [props.searchText])

    useEffect(() => {
        var dpl = props.pipeline.filter(e => e.default)[0] ? props.pipeline.filter(e => e.default)[0].value : '';
        var dataArray = [];
        if (props.stageCounts) {
            for (var i in props.stageCounts) {
                if (i !== 'Converted')
                    dataArray.push(props.stageCounts[i]);
            }
        }
        //console.log('dataArray', dataArray)
        let _counts = {
            Draft: _.sumBy(dataArray, "Draft"),
            Published: _.sumBy(dataArray, "Published"),
            Live: _.sumBy(dataArray, "Live"),
            Closed: _.sumBy(dataArray, "Closed"),
            total: _.sumBy(dataArray, "total")
        };
        eventsProAllStatus.forEach(sts => {
            _counts = {
                ..._counts,
                [sts.value]: _.sumBy(dataArray, sts.value)
            }
        });
        setCounts(_counts)

    }, [props.stageCounts])

    useEffect(() => {
        if (!_.isEmpty(localStorage.eventsProPipelineFilter) && Object.keys(JSON.parse(localStorage.eventsProPipelineFilter)).length > 0) {
            let _pipelineFilter = JSON.parse(localStorage.eventsProPipelineFilter);
            let _filterValue = _pipelineFilter.value;

             if (!_.isEmpty(_filterValue) && _filterValue.hasOwnProperty('keywords'))
                 setSearchKeywords(_filterValue['keywords']);
             else
                 setSearchKeywords('');

            setPipelineFilter(_pipelineFilter);
        }
        else {
            setPipelineFilter({});
            setSearchKeywords('');
        }
    }, [localStorage.eventsProPipelineFilter])

    
    const addEventAccess = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.eventsProPermissions) &&
        props.dealersettings.rolePermissions.eventsProPermissions.addEvent) ? true : false);

    const exportData = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.eventsProPermissions) &&
        props.dealersettings.rolePermissions.eventsProPermissions.exportData) ? true : false);

    return (
        <div className="filter-panel">
            {/* <div className="filter-switch">
                <NavLink to="/eventspro" exact activeClassName={"switch-active"}>
                    <i className="ico icon-pipeline-1"></i>
                </NavLink>
                <NavLink to="/eventspro/list" className="ml-1" exact activeClassName={"switch-active"}>
                    <i className="ico icon-list"></i>
                </NavLink>
            </div>  */}

            {/**** KEYWORDS SERACH ****/}
            <div className="filter-search">
                <div className="input-group ">
                    <input
                        className="form-control"
                        type="text"
                        id="isearch"
                        placeholder="search..."
                        value={searchText ? searchText : ''}
                        onChange={(e) => {
                            let _val = e.target.value;

                            setSearchText(_val);
                            localStorage.eventsSearchText = _val;

                            if (!_val)
                                props.handlesearchText('')

                            if (_val && _val.length > 2)
                                props.handlesearchText(_val)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                props.handlesearchText(searchText)
                            }
                        }}
                        autoComplete="off"
                    />
                    <div className="input-group-append input-search-clear">
                        {
                            searchText && searchText.length > 0
                                ?
                                <a href="#"
                                    className="input-search-clear-icon"
                                    title="Clear"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchText('');
                                        localStorage.eventsSearchText = '';
                                        props.handlesearchText('')
                                    }}
                                >
                                    <i className="ico icon-remove"></i>
                                </a>
                                :
                                <>
                                </>
                        }

                        <button
                            className="btn btn-default"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                props.handlesearchText(searchText)
                            }}>
                            <i className="ico icon-search-fill"></i>
                        </button>
                    </div>
                </div>
            </div>


            {
                (!_.isEmpty(props.selectedIds) && props.showMore) ?
                    (<>
                        <div className="list-count-btn ml-3" style={{ float: 'left' }}>
                            <span><Translate text={Object.keys(props.selectedIds).length > 1 ? 'Selected Events' : 'Selected Event'} /></span>
                            <span className="list-count"> {Object.keys(props.selectedIds).length} </span>
                        </div>
                    </>)
                    : (<></>)
            }

            <div className="float-right">
                {
                    addEventAccess
                        ?
                        <div className="filter-add-button ml-2">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.handleEventOpen();
                                }}
                            > <i className="ico icon-add"></i> <Translate text={'addEvent'} /></button>
                        </div>
                        :
                        <></>
                }

                {
                    props.showMore
                        ?
                        <>
                            {/* {
                                (transferEnquiry || movePipeline)
                                    ?
                                    <>
                                        <Dropdown className='filter-view-more  ml-2'>
                                            <Dropdown.Toggle
                                                as={CustomToggle}
                                                className='common-button'
                                            >
                                                <i className='ico icon-more'></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                as={CustomMenu}
                                                ChildClass="more-dropdown"
                                                xplacement="bottom-end"
                                            >
                                                {
                                                    transferEnquiry ? (
                                                        <Dropdown.Item eventKey="1" onClick={(e) => { props.handleTransfer(e) }}><i className="ico icon-email"></i>  <Translate text={'transfer'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }

                                                {
                                                    movePipeline ? (
                                                        <Dropdown.Item eventKey="2" onClick={(e) => { props.handleMove(e) }}><i className="fa fa-file-import"></i>  <Translate text={'move'} /></Dropdown.Item>
                                                    ) : (<></>)
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                    </>
                            } */}

                            {/* {
                                (exportData)// && counts.total > 0)
                                    ?
                                    <>
                                        <div className="filter-item-btn ml-2">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                props.excelDownload(counts.total)
                                            }} title={'Excel Export'}>
                                                <i className="ico icon-excel-download"></i>
                                            </a>
                                            <CSVLink
                                                ref={props.csvBtn}
                                                uFEFF={false}
                                                data={props.csvData}
                                                headers={props.csvHeader}
                                                filename={`eventsPro_${moment().format('DD_MM_YYYY_HH_mm')}.csv`}
                                                style={{ display: 'none' }}>
                                            </CSVLink>
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            } */}


                        </>
                        :
                        <>
                        </>
                }

            </div>

            <Dropdown className="filter-pipeline-btn float-right dropdown-toggle" alignRight>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <i className="ico icon-pipeline-1"></i> <span>
                        {props.pipeline.length > 0 ? props.pipeline.filter(e => e.default)[0].name : ''}
                    </span>
                    <i className="ico icon-dropdown"></i>
                </Dropdown.Toggle>
                {
                    !_.isEmpty(props.dealersettings)
                        && !_.isEmpty(props.dealersettings.rolePermissions)
                        && !_.isEmpty(props.dealersettings.rolePermissions.admin)
                        && Object.keys(props.dealersettings.rolePermissions.admin).filter(function (id) {
                            return props.dealersettings.rolePermissions.admin[id]
                        }).some(e => e === 'eventsProworkflowSetup') ? (<Dropdown.Item className="edit" bsPrefix="edit" as={Link} to='/settings/eventsProworkflow?key=pipelines'>
                            <i className="ico icon-edit"></i>
                        </Dropdown.Item>) : (<></>)
                }

                <Dropdown.Menu as={CustomMenu} className="rm-pointers dropdown-menu-lg dropdown-menu-right" ChildClass="pipeline-dropdown" xplacement="bottom-end">
                    {
                        props.pipeline && props.pipeline.length > 0 ?
                            props.pipeline.map((rec, index) => {
                                return <Dropdown.Item key={index} className="current-pipeline" onClick={(e) => {
                                    e.preventDefault();
                                    props.handlePipelineChange(rec.value)
                                }}>
                                    {rec.default ? (<div className="pipeline-check">  <i className="ico icon-check"> </i></div>) : (<></>)}
                                    {rec.name}</Dropdown.Item>
                            })
                            : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown>

            {/**** FILTERS ****/}
             <Filters
                searchKeywords={searchKeywords}
                pipelineFilter={eventsProPipelineFilter}
                customFilters={props.customFilters}
                dealersettings={props.dealersettings}
                handleApplyFilter={props.handleApplyFilter}
                handleClearFilter={props.handleClearFilter}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                showPipeline={props.showMore}
                pipeline={props.pipeline}
                noSaveAccess={true}
            >
            </Filters> 


            {
                ((searchText && searchText.length > 2)) ? (<></>) : (
                    <div className="pipeline-counter mr-3">
                        <div className="counter-nav">
                            <ul>
                                {
                                    counts.total > 0 ? (
                                        <li><a href="#" className="counter-total" onClick={(e) => {
                                            e.preventDefault();
                                            //props.handleStatusFilter('', 'status');
                                        }}>Total <span className="badge badge-pill badge-counter ml-1">{counts.total}</span></a></li>
                                    ) : (<></>)
                                }
                                {
                                    eventsProAllStatus.map((sts, index) => {
                                        return counts[sts.value] > 0 ? (
                                            <li key={index}><a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                //props.handleStatusFilter(sts.value, 'status');
                                            }}> {sts.name} <span className="badge badge-pill badge-counter ml-1" style={{ background: `${sts.value === 'New' ? '#6b717f' : sts.color}` }}>{counts[sts.value]}</span></a></li>
                                        ) : (<Fragment key={index}></Fragment>)
                                    })
                                }

                            </ul>
                        </div>
                    </div>
                )
            }

            {
                (!_.isEmpty(props.pipelineCount) || props.pipelineCount > 0) ?
                    (<>
                        <div className="list-count-btn ml-3" >
                            <span>  <Translate text={props.pipelineCount > 1 ? 'Events' : 'Event'} /></span>
                            <span className="list-count"> {props.pipelineCount} </span>
                        </div>
                    </>)
                    : (<></>)
            }

        </div >)
}

export default FilterPanel