/** LIBRARIES */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash'
/** COMPONENTS */
import { ReactTimePicker, ReactMultiSelect, AntDatePicker } from '../../../components';
import CommonHelper from '../../../services/common'
import { default as _images } from '../../../images';
import Translate from '../../../constants/translate';

const ActivityPanel = ({ objActivity, placeholder, activitypanelClose, id, activeTab, title, clientUsers, handleSaveactivity, editable, oldsubtype, sectionLoading }) => {
  const [changelog, setChangelog] = useState(objActivity)
  const [userOptions, setUseroptions] = useState([])
  const inputRef = useRef()
  //console.log(changelog)
  useEffect(() => {
    inputRef.current && inputRef.current.classList.remove('input_error')
    setChangelog({
      ...changelog,
      'subType': activeTab,
      'title': editable && oldsubtype === activeTab ? placeholder : title
    });
  }, [activeTab])

  useEffect(() => {
    let options = [];
    clientUsers.filter(e => e.id !== localStorage.uid)
      .map(rec => {
        return options.push({
          value: rec.id,
          active: _.isBoolean(rec.active) ? rec.active : true,
          searchlabel: rec.name,
          label: <div className="drop-image">
            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit"/>
            {rec.name}
          </div>
        })
      })
    setUseroptions(options)
  }, [])

  const handleNotesChanges = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (value)
      value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

    setChangelog({
      ...changelog,
      [name]: value
    });
  }

  const handleChangelog = (e) => {
    e.preventDefault();
    if (e.target.name === 'title' && e.target.value.length === 0) {
      inputRef.current && inputRef.current.classList.add('input_error')
    }
    else {
      inputRef.current && inputRef.current.classList.remove('input_error')
    }
    setChangelog({
      ...changelog,
      [e.target.name]: e.target.name === 'isDone' ? e.target.checked : e.target.value
    });
  }

  const handleDateChange = (val, name) => {
    if (val) {
      val = moment(moment(val).format('YYYY-MM-DD') + ' ' + moment.unix(changelog.startDate.seconds).format('HH:mm'))._d;
      setChangelog({
        ...changelog,
        [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(['call'].includes(changelog.subType) ? moment(val).add(15, 'minutes')._d : moment(val).add(1, 'hours')._d),
      });
    }
  }
  const handleStartTimeChange = (val, name) => {
    //console.log(val)
    setChangelog({
      ...changelog,
      [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
      endDate: window.firebase.firestore.Timestamp.fromDate(['call'].includes(changelog.subType) ? moment(val).add(15, 'minutes')._d : moment(val).add(1, 'hours')._d),
    });
  }

  const handleEndTimeChange = (val, name) => {

    setChangelog({
      ...changelog,
      [name]: window.firebase.firestore.Timestamp.fromDate(moment(val)._d),
    });
  }

  const dateFormat = (val) => {
    return CommonHelper.getdateFormat(val)
  }

  const handleUserChange = (selectedOptions) => {

    const value = [];

    !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
      value.push(data.value)
    })

    setChangelog({
      ...changelog,
      'teammates': value
    });



  }
  return <div className="activity-form form-style" id="activity-form">
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="title"
        value={changelog.title}
        onChange={(e) => handleChangelog(e)}
        placeholder={placeholder}
        autoComplete="off"
        ref={inputRef}
      />
    </div>
    <div className="form-row">
      <div className="form-group col-md-6">
        <label ><Translate text={'date'} /></label>
        <div className="inner-addon right-addon">
          <i className="bg-icons ico icon-date"></i>
          {/* <ReactDatePicker
            value={moment.unix(changelog.startDate.seconds).format('YYYY-MM-DD')}
            name={'startDate'}
            onChange={handleDateChange}
            format={dateFormat}
            placeholder='DD/MM/YYYY'
            className={`form-control`}
            readOnly={true}
          /> */}
          <AntDatePicker
            value={moment.unix(changelog.startDate.seconds).format('YYYY-MM-DD')}
            name={'startDate'}
            onChange={(e) => { handleDateChange(e, 'startDate') }}
            format='DD/MM/YYYY'
            placeholder='DD/MM/YYYY'
            className={`form-control`}
          />
        </div>
      </div>
      <div className="form-group col-md-3">
        <label ><Translate text={'from'} /></label>
        <div className="inner-addon right-addon">
          <i className="bg-icons ico icon-time"></i>

          <ReactTimePicker
            value={moment.unix(changelog.startDate.seconds)._d}
            name={'startDate'}
            onChange={handleStartTimeChange}
            timeIntervals={15}
            placeholder='h:mm aa'
            className={`form-control`}
          />
        </div>
      </div>
      <div className="form-group col-md-3">
        <label ><Translate text={'to'} /></label>
        <div className="inner-addon right-addon">
          <i className="bg-icons ico icon-time"></i>

          <ReactTimePicker
            value={moment.unix(changelog.endDate.seconds)._d}
            name={'endDate'}
            onChange={handleEndTimeChange}
            timeIntervals={15}
            placeholder='h:mm aa'
            className={`form-control`}
            minTime={moment.unix(changelog.startDate.seconds)._d}
            maxTime={moment.unix(CommonHelper.getEndDate(changelog.startDate).seconds)._d}
          />
        </div>
      </div>
    </div>
    <div className="form-group">
      <label ><Translate text={'teammate'} /></label>
      <ReactMultiSelect
        options={userOptions}
        name={"teammates"}
        placeholder={'select teammate'}
        onChange={handleUserChange}
        value={changelog.teammates}
        classNamePrefix={`basic-select`}
        isMulti={true}
        isSearchable={true}
      >
      </ReactMultiSelect>
    </div>
    <div className="form-group">
      <label ><Translate text={'notes'} /></label>
      <textarea className="form-control notes" name="notes" onChange={(e) => handleNotesChanges(e)} value={changelog.notes} placeholder="notes" cols="80" rows="3"></textarea>
    </div>
    <div className="editmode-footer">

      <div className="checkbox icheck-success  float-left">
        <input type="checkbox" id={`success-edit-${id}`} name="isDone" onChange={(e) => {
          setChangelog({
            ...changelog,
            'isDone': e.target.checked
          });
        }} checked={changelog.isDone} />
        <label htmlFor={`success-edit-${id}`}><Translate text={'markasdone'} /></label>
      </div>
      <button type="button" className="btn btn-primary float-right ml-2"
        onClick={(e) => {
          if (changelog.title.length === 0 && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.classList.add('input_error')
            return
          }
          handleSaveactivity(e, changelog)
        }}>
        {sectionLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
        <Translate text={'save'} /></button>
      <button type="button" className="btn btn-default float-right" onClick={(e) => activitypanelClose(e)} ><Translate text={'cancel'} /></button>
    </div>
  </div >

}


export default ActivityPanel;