import React from 'react';
export const triggerVM = {
    documentID: null,
    clientID: '',
    category: '',
    title: '',
    subject: '',
    status: '',
    triggerType: '',
    todoType: '',
    templateID: null,
    make: '',
    model: '',
    bodyType: '',
    saleType: '',
    recipientType: '',
    replyType: '',
    ccType: '',
    recipientTo: '',
    mailBcc: '',
    replyTo: '',
    period: '',
    periodValue: 0,
    periodEvery: 0,
    periodEveryType: '',
    startAfter: 0,
    startAfterType: '',
    addedBy: '',
    addedDate: null,
    modifiedBy: '',
    modifiedDate: null,
    isDeleted: false,
    isActive: true,
    notes: '',
    triggerFor: '',
    pipeline: '',
    stage: '',
    level: '',
    levelID: '',
    subLevelIDs: '',
    regionIDs: [],
    groupIDs: [],
    clientIDs: [],
    teammates: [],
    oemID: null,
    pipelines: [],
    stages: [],
    lostReasons: [],
    settingsID: null,
    mailCc: '',
    ccType: '',
    module: '',
    tradeinProStatus: '',
    triggerFeedback: '',
    senderName: null
}


export const triggerCategory = [
    {
        name: "Activity",
        value: "activity",
        class: "ico icon-trigger-activity"
    },
    {
        name: "Contact",
        value: "contact",
        class: "ico icon-trigger-contact"
    },
    {
        name: "Company",
        value: "company",
        class: "ico icon-trigger-company"
    },
    {
        name: "Appraisal",
        value: "appraisal",
        class: "ico icon-service-settings"
    },
    {
        name: "Inbound Appraisal",
        value: "tradeinProInbound",
        class: "ico icon-inbound"
    },
];

export const tradeinProStatuses = [
    { active: true, value: 'new', label: <div className={`badge badge-pill status-new`}>NEW</div> },
    { active: true, value: 'Pending', label: <div className={`badge badge-pill status-pending`}>PENDING</div> },
    { active: true, value: 'InProgress', label: <div className={`badge badge-pill status-inprogress`}>IN PROGRESS</div> },
    { active: true, value: 'Won', label: <div className={`badge badge-pill status-won`}>WON</div> },
    { active: true, value: 'Lost', label: <div className={`badge badge-pill status-lost`}>LOST</div> }

]

export const eventTypes = [
    {
        active: true,
        name: "Contact Created",
        status: "contactCreated",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS once a contact is created.",
        label: "Contact Created Date"
    },
    {
        active: true,
        name: "Contact Birthday",
        status: "contactBirthday",
        value: "contact",
        desc: "Schedule an Activity, Email or SMS to be triggered for the contact’s birthday.",
        label: "Contact Birthday",
    },
    {
        active: true,
        name: "Company Created",
        status: "companyCreated",
        value: "company",
        desc: "Schedule an Activity, Email or SMS once a company is created",
        label: "Company Created Date",
    },
    {
        active: true,
        name: "Appraisal Created",
        status: "tradeinProCreated",
        value: "appraisal",
        desc: "Schedule an Activity, Email or SMS once an appraisal is created.",
        label: "Appraisal Created Date",
    },
    {
        active: true,
        name: "Appraisal Status Update",
        status: "tradeinProStatus",
        value: "appraisal",
        desc: "Schedule an Activity, Email or SMS once an appraisal status update.",
        label: "Appraisal Created Date",
    },
    {
        active: true,
        name: "Pipeline/Stage Update",
        status: "tradeinProUpdate",
        value: "appraisal",
        desc: "Schedule an Activity, Email or SMS on pipeline/stage update.",
        label: "Appraisal Modified Date",
    },
    {
        active: true,
        name: "Appointment Scheduled",
        label: "Appointment Scheduled Date",
        status: "appointmentScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is scheduled."
    },
    {
        active: true,
        name: "Appointment Completed",
        label: "Appointment Completed Date",
        status: "appointmentCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an appointment is completed."
    },
    {
        active: true,
        name: "Call Scheduled",
        label: "Call Scheduled Date",
        status: "callScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is scheduled."
    },
    {
        active: true,
        name: "Call Completed",
        label: "Call Completed Date",
        status: "callCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a call is completed."
    },
    {
        active: true,
        name: "Email Scheduled",
        label: "Email Scheduled Date",
        status: "emailScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is scheduled."
    },
    {
        active: true,
        name: "Email Completed",
        label: "Email Completed Date",
        status: "emailCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once an email is completed."
    },
    {
        active: true,
        name: "Delivery Scheduled",
        label: "Delivery Scheduled Date",
        status: "deliveryScheduled",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is scheduled."
    },
    {
        active: true,
        name: "Delivery Completed",
        label: "Delivery Completed Date",
        status: "deliveryCompleted",
        value: "activity",
        desc: "Add an Email or SMS trigger once a Delivery is completed."
    },
    {
        active: true,
        name: "Inbound Lead Received",
        status: "tradeinProInboundCreated",
        value: "tradeinProInbound",
        desc: "Schedule an Email or SMS once an inbound lead is received.",
        label: "Inbound Received Date",
    },
];

export const saleTypes = [
    { active: true, value: 'New', label: 'New' },
    { active: true, value: 'Demo', label: 'Demo' },
    { active: true, value: 'Preowned', label: 'Preowned' }
]

export const mailtotypes = [
    { active: true, value: 'contact', label: 'Contact' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'user', label: 'Users' },
    { active: true, value: 'owner', label: 'Valuation Owner' },
    { active: true, value: 'salesOwner', label: 'Sales Owner' }
]

export const tiggerFrequency = [
    { active: true, value: 'instant', label: 'Instant', name: 'Instant' },
    { active: true, value: 'afterMins', label: 'Minutes (No.in Minutes)', name: 'Minutes' },
    { active: true, value: 'afterHours', label: 'Hours (No.in Hours)', name: 'Hours' },
    { active: true, value: 'afterDays', label: 'Days (No.in Days)', name: 'Days' },
    { active: true, value: 'iteration', label: 'Iteration', name: 'Iteration' },
    { active: true, value: 'birthday', label: 'Birthday', name: 'Birthday' }
]

export const frequencyOptions = [
    { active: true, value: 'before', label: 'Before' },
    { active: true, value: 'after', label: 'After' }
]

export const priodEveryTypes = [
    { active: true, value: 'day', label: 'Day' },
    { active: true, value: 'month', label: 'Month' },
    { active: true, value: 'year', label: 'Year' }
]

export const triggerTypes = [
    { active: true, value: 'activity', label: 'Activity' },
    { active: true, value: 'email', label: 'Email' },
    { active: true, value: 'sms', label: 'SMS' }
]


export const triggerlistFields = [
    {
        name: 'Title',
        value: 'title',
        sortValue: 'title',
        flex: 1,
        default: true
    },
    {
        name: 'Event Type',
        value: 'strStatus',
        sortValue: 'strStatus',
        flex: 1,
        default: true
    },
    // {
    //     name: 'Trigger Type',
    //     value: 'strtriggerType',
    //     flex: 1,
    //     default: true
    // },
    {
        name: 'Triggered On',
        value: 'strtriggerOn',
        sortValue: 'strtriggerOn',
        flex: 1,
        default: true,
        subText: 'strtriggerType'
    },
    {
        name: 'OEM/Region/Group/Client',
        value: 'levelsDOM',
        sortValue: 'strlevels',
        flex: 1,
        default: true
    },
    {
        name: 'Level',
        value: 'levelValue',
        sortValue: 'levelValue',
        flex: 0,
        width: 120,
        default: true
    },
    {
        name: 'Active',
        value: 'isActive',
        sortValue: 'isActive',
        flex: 0,
        width: 120,
        default: true,
        type: 'switch'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 120,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]


export const replyTypes = [
    { active: true, value: 'owner', label: 'Valuation Owner' },
    { active: true, value: 'custom', label: 'Custom' },
    { active: true, value: 'ownerandcustom', label: 'Valuation Owner & Custom' }
]