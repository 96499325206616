/** LIBRARIES */
import React, { Component } from "react";
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import moment from 'moment';

class DateMonthPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value;
    }
    render() {
        const { onChange, placeholder, value, className } = this.props;
        //console.log('RENDER - Date Month Picker', name, value)
        return (
            <DatetimePickerTrigger
                minPanel={'month'}
                showTimePicker={false}
                closeOnSelectMonth={true}
                moment={value ? moment('01 ' + value) : null}
                onChange={onChange}>
                <input
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    readOnly
                    className={className}
                />
            </DatetimePickerTrigger>

        );
    }
}

export default DateMonthPicker;