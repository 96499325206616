import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import { ReactSelect, PopUpModal, ContentOverlay } from '../../../../components'
import Translate from '../../../../constants/translate';
import AddTeam from './addTeam'
import CommonHelper from '../../../../services/common'

/** COMPONENTS */
import { CustomMenu, CustomToggle } from '../../../../components/customdropdown';
import { ClientContext } from '../clientContext'

const TeamsList = (props) => {
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamLoader, setTeamloader] = useState(false)
    const [showAddTeamModal, setShowAddTeamModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [editTeam, setEditTeam] = useState(null)
    const [oemTeams, setOEMTeams] = useState([])

    const { groups, teams } = useContext(ClientContext);

    useEffect(() => {
        if (props.activeKey === 'clients') {
            function handleResize() {
                //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
                document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            }
            window.addEventListener('resize', handleResize);
            //document.documentElement.style.setProperty('--rolescontainerHeight', (window.innerHeight - 204) + 'px');
            document.documentElement.style.setProperty('--rolesuseritemHeight', (window.innerHeight - 344) + 'px');
            return () => {
                window.removeEventListener('resize', handleResize);
            }
        }
    }, [props.activeKey])

    useEffect(() => {
        if (!_.isEmpty(teams)) {
            var _teams = [];
            teams.forEach(rec => {
                _teams.push({
                    ...rec,
                    'active': (_.find(oemTeams, { 'documentID': rec.documentID }) ? _.find(oemTeams, { 'documentID': rec.documentID }).active : false)
                });
            })
            if (_teams.length > 0 && !_teams.some(item => item.active === true)) {
                _.find(_teams, { 'documentID': _teams[0].documentID }).active = true;

            }
            setSelectedTeam(_teams.find(item => item.active === true))
            setOEMTeams(_teams)
        }
        else {
            setSelectedTeam(null)
            setOEMTeams([])
        }
    }, [teams])

    const handleTeamChange = (team) => {
        if (!_.isEmpty(team)) {
            setOEMTeams([..._.forEach(oemTeams, function (obj) {
                if (obj.documentID === team.documentID)
                    _.set(obj, 'active', true);
                else
                    _.set(obj, 'active', false);
            })]);
            setSelectedTeam(team)
        }
    }

    const handleModelClose = () => {
        setShowAddTeamModel(false);
    }

    const handleeditTeam = (team) => {
        setPopHeader('Edit Team')
        setEditTeam(team)
        setShowAddTeamModel(true)
    }

    const handledeleteGroup = (team) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
			cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${team.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Team deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                            .set({
                                settingsUpdate: window.firebase.firestore.Timestamp.now(),
                                settingsUpdatePriority: 'normal',
                                settingsUpdateBy: props?.dealersettings?.name || null,
                                settingsUpdateText: 'Teams Settings has been updated',
                            }, { merge: true })
                    })
                    .catch(error => {
                        console.error(error);
                        //setSectionLoading(false)
                        toast.notify('Something went wrong', {
                            duration: 2000,
                            position: 'top-right'
                        })
                    });
            }
        })
    }

    const handleaddClient = async (e, data) => {
        if (e) {
            try {
                let team = { ...selectedTeam }
                team.user_ids = [...team.user_ids, e.value]
                await window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${team.documentID}`).set(team, { merge: true })
                await window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                    .set({
                        settingsUpdate: window.firebase.firestore.Timestamp.now(),
                        settingsUpdatePriority: 'normal',
                        settingsUpdateBy: props?.dealersettings?.name || null,
                        settingsUpdateText: 'User Team Settings has been updated',
                    }, { merge: true })

            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleswitchGroup = async (newTeam, client) => {
        try {
            if (newTeam.user_ids.includes(client.documentID)) {
                toast.notify(`${client.name} already exists in ${newTeam.name}`, {
                    duration: 2000,
                    position: 'top-right'
                })
                return
            }
            let team = { ...selectedTeam }
            let updatedUserIds = team.user_ids.filter(userId => userId !== client.documentID)
            team.user_ids = [...updatedUserIds]
            await window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${team.documentID}`).set(team, { merge: true })

            newTeam.user_ids = [...newTeam.user_ids, client.documentID]
            await window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${newTeam.documentID}`).set(newTeam, { merge: true })
            await window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                .set({
                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                    settingsUpdatePriority: 'normal',
                    settingsUpdateBy: props?.dealersettings?.name || null,
                    settingsUpdateText: 'User Team Settings has been updated',
                }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    const handledeleteClientGroup = async (client) => {
        try {
            let team = { ...selectedTeam }
            let updatedUserIds = team.user_ids.filter(userId => userId !== client.documentID)
            team.user_ids = [...updatedUserIds]
            await window.firebase.firestore().doc(`clientSettings/${props.dealersettings.client.settingsID}/teams/${team.documentID}`).set(team, { merge: true })
            await window.firebase.firestore().doc(`clients/${props.dealersettings.client.id}`)
                .set({
                    settingsUpdate: window.firebase.firestore.Timestamp.now(),
                    settingsUpdatePriority: 'normal',
                    settingsUpdateBy: props?.dealersettings?.name || null,
                    settingsUpdateText: 'User Team Settings has been updated',
                }, { merge: true })
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="settings-column-wraper">
                <div className="settings-columns">
                    <div className="settings-column-left">
                        <div className="settings-column-title">
                            <div className="column-add-button"><a href="#" className="mini-button" onClick={(e) => { e.preventDefault(); setEditTeam(null); setShowAddTeamModel(true); setPopHeader('Add Team') }}> <i className="ico icon-add"></i></a></div>
                            <h3><Translate text={'Teams'} /></h3>
                        </div>
                        <div className="settings-column-nav">
                            {
                                teamLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<ul>
                                    {
                                        oemTeams.map((rec, index) => {
                                            return <li key={index} className={`${rec.active === true ? 'active' : ''}`}>
                                                <Dropdown className="role-list-items-more" alignRight>
                                                    <Dropdown.Toggle as={CustomToggle} className="dropdown role-list-items-more-fix">
                                                        <i className="ico icon-more"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handleeditTeam(rec) }}><i className="ico icon-edit"></i>  <Translate text={'edit'} /></Dropdown.Item>
                                                        <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteGroup(rec) }}><i className="ico icon-delete"></i>  <Translate text={'delete'} /></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <a href="#" className="column-list-left"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleTeamChange(rec);
                                                    }}
                                                >{rec.name}
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>)
                            }
                        </div>
                    </div>
                    <div className="settings-column-right-large">
                        <div className="settings-column-title">
                            <h3><Translate text={'Members'} /></h3>
                        </div>
                        {
                            !_.isEmpty(selectedTeam) ? (
                                <>
                                    <div className="user-list">
                                        <p><Translate text={'Add users to'} /> '{selectedTeam.name}'</p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ReactSelect
                                                    options={_.map(props.clientUsers.filter(item => !Boolean(item.superAdmin) && !selectedTeam.user_ids.some(id => item.documentID === id)), function (c) {
                                                        return {
                                                            value: c.documentID,
                                                            doc: c,
                                                            searchlabel: c.name,
                                                            label: (
                                                                <div className='drop-image'>
                                                                    <img src={CommonHelper.showUserAvatar(c.profileImage, c.name)} alt="" width="50" className="img-object-fit" />
                                                                    {c.name}
                                                                </div>
                                                            ),
                                                            active: _.isBoolean(c.active) ? c.active : true,
                                                        };
                                                    })}
                                                    name={"clientID"}
                                                    placeholder={'select user'}
                                                    onChange={handleaddClient}
                                                    value={''}
                                                    classNamePrefix={`cursor-pointer basic-select`}
                                                    removeClearable={true}
                                                >
                                                </ReactSelect>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !_.isEmpty(selectedTeam) ? selectedTeam.user_ids.length === 0 ? (<><div className="common-table"><div className="text-center p-5"><p><Translate text={'select team'} /></p></div></div></>) : (<div className="user-list-items">
                                            <ul>
                                                {
                                                    props.clientUsers.filter(clientUser => selectedTeam.user_ids.includes(clientUser.documentID)).map((rec, index) => {
                                                        return <li key={index} className="user-list-box">
                                                            <Dropdown className="user-list-items-more" drop={'left'}>
                                                                <Dropdown.Toggle as={CustomToggle} className="dropdown">
                                                                    <i className="ico icon-more"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu as={CustomMenu} className="dropdown-menu dropdown-menu-right" ChildClass="more-dropdown" xplacement="bottom-end">
                                                                    {
                                                                        oemTeams.filter(item => item.active === false).length > 0 ? (
                                                                            <Dropdown.Header className='nav-item-header nav-item text-left'>
                                                                                <Translate text={'Switch Team'} />
                                                                            </Dropdown.Header>
                                                                        ) : (<></>)
                                                                    }
                                                                    {
                                                                        oemTeams.filter(item => item.active === false).map((_team, index) => {
                                                                            return <Dropdown.Item key={index} eventKey={index} onClick={(e) => { handleswitchGroup(_team, rec) }}> {_team.name} </Dropdown.Item>
                                                                        })
                                                                    }
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item eventKey="delete" onClick={(e) => { handledeleteClientGroup(rec) }}><i className="ico icon-delete"></i> <Translate text={'Remove'} /> </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <div className="user-list-items-image">
                                                                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" className="rounded-circle img-object-fit" />
                                                            </div>
                                                            <div className="user-list-items-info">
                                                                <h4>{rec.name}</h4>
                                                                <h5>{rec.email ? rec.email : rec.phone}</h5>
                                                            </div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </div>) : (<></>)
                                    }
                                </>) : (<></>)
                        }
                    </div>
                </div>
            </div>
            <PopUpModal show={showAddTeamModal}>
                <AddTeam
                    {...props}
                    show={showAddTeamModal}
                    title={popHeader}
                    handleClose={handleModelClose}
                    team={editTeam}
                    allTeams={teams}
                    dealersettings={props.dealersettings}
                />
            </PopUpModal>
        </>
    );
}

export default TeamsList;