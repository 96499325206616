import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'
import { ContentOverlay } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import { reportDynColumns } from '../reports/viewModel'
import Translate from '../../constants/translate';
import NumberFormat from 'react-number-format';
import { checkoutFields } from './viewmodel';
import { ProgressBar } from 'react-bootstrap'


const ScheculePerformanceReport = (props) => {
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [dynReport, setDynReport] = useState()
    const [dealersettings, setDealersettings] = useState()
    const [clientUsers, setClientUsers] = useState()
    const [propsSettings, setPropsSettings] = useState()
    const [inclInboundLeadsinRpt, setInclInboundLeadsinRpt] = useState(false);
    const [currencySymbol, setcurrencySymbol] = useState('');
    const [calcCurrentStatus, setCalcCurrentStatus] = useState('false');
    const [tableFields, setTableFields] = useState()
    const [checkoutData, setCheckoutData] = useState([])
    const [performance, setPerformance] = useState([])
    const [teams, setTeams] = useState([])
    const [monthData, setMonthData] = useState([])
    const [multiTDperLead, setMultiTDperLead] = useState('false');
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let _date = params.get('date');

    useEffect(() => {
        window.firebase.firestore().doc(`schedular/${props.clientid}-performance`)
            .get()
            .then(doc => {
                if (doc.exists) {
                    setDynReport({
                        ...doc.data(),
                        'name': 'Sales Performance Review',
                        'documentID': doc.id,
                        'columns': _.isArray(doc.data().columns) ? doc.data().columns : [doc.data().columns]
                    })
                }
            })
    }, [])

    useEffect(() => {
        if (_.isEmpty(dynReport) || _.isEmpty(dynReport.clientID))
            return

        window.firebase.firestore().doc(`clients/${dynReport.clientID}`)
            .get()
            .then(async doc => {
                if (doc.exists) {
                    setInclInboundLeadsinRpt((!_.isEmpty(doc.data().clientSettings) &&
                        doc.data().clientSettings.inclInboundLeadsinRpt) ? true : false);
                    setcurrencySymbol(!_.isEmpty(doc.data().currency) ? doc.data().currency.symbol + ' ' : '$ ');
                    if (!_.isEmpty(doc.data().clientSettings) && doc.data().clientSettings.calcCurrentStatus) {
                        setCalcCurrentStatus('true');
                    }
                    if (!_.isEmpty(doc.data().clientSettings) && doc.data().clientSettings.multiTDperLead) {
                        setMultiTDperLead('true');
                    }
                    const clinetTeams = await window.firebase.firestore().collection(`clientSettings/${doc.data().settingsID}/teams`).get();
                    let _teams = [];
                    if (clinetTeams.docs.length > 0) {
                        clinetTeams.docs.forEach(rec => {
                            if (rec.data() && rec.data().user_ids)
                                _teams.push({
                                    ...rec.data(),
                                    id: rec.id
                                })
                        })
                    }
                    setTeams(_teams)
                }
                window.firebase.firestore().doc(`clients/${dynReport.clientID}/currentSettings/${dynReport.clientID}`)
                    .get()
                    .then(doc => {
                        if (doc.exists) {
                            let timezone = !_.isEmpty(doc.data().timezone) ? doc.data().timezone : moment.tz.guess();
                            if (_date === 'today') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('day').valueOf()),
                                    endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('day').valueOf()),
                                    strstartDate: moment().tz(timezone).startOf('day').format('YYYY-MM-DD'),
                                    strendDate: moment().tz(timezone).endOf('day').format('YYYY-MM-DD'),
                                    monthstartDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('month').valueOf()),
                                    monthendDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('month').valueOf()),
                                });
                            }
                            else if (_date === 'wtd') {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).add('day', -6).startOf('day').valueOf()),
                                    endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('day').valueOf()),
                                    strstartDate: moment().tz(timezone).add('day', -6).startOf('day').format('YYYY-MM-DD'),
                                    strendDate: moment().tz(timezone).endOf('day').format('YYYY-MM-DD'),
                                    monthstartDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('month').valueOf()),
                                    monthendDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('month').valueOf()),
                                });
                            }
                            else {
                                setDateRange({
                                    startDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('month').valueOf()),
                                    endDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('month').valueOf()),
                                    strstartDate: moment().tz(timezone).startOf('month').format('YYYY-MM-DD'),
                                    strendDate: moment().tz(timezone).endOf('month').format('YYYY-MM-DD'),
                                    monthstartDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).startOf('month').valueOf()),
                                    monthendDate: window.firebase.firestore.Timestamp.fromMillis(moment().tz(timezone).endOf('month').valueOf()),
                                });
                            }
                            setClientUsers(doc.data().users.filter(a =>
                                !Boolean(a.superAdmin) &&
                                !Boolean(a.isDisabled)))
                            setPropsSettings({
                                dealersettings: {
                                    ...doc.data(),
                                    client: {
                                        id: doc.id,
                                        settings: doc.data().settings,
                                        integrations: doc.data().integrations,
                                        workingHours: doc.data().workingHours,
                                    }
                                },
                                pipeline: doc.data().settings.allPipelines,
                                clientUsers: doc.data().users.filter(a => !Boolean(a.superAdmin) && !Boolean(a.isDisabled))

                            })
                            setDealersettings({
                                ...doc.data(),
                                'clientID': doc.id
                            })
                        }
                    })
            })

    }, [dynReport])

    useEffect(() => {
        if (_.isEmpty(propsSettings))
            return
        let _performance = []
        propsSettings.dealersettings &&
            propsSettings.dealersettings.client &&
            propsSettings.dealersettings.client.settings &&
            propsSettings.dealersettings.client.settings.checkoutPerformance &&
            propsSettings.dealersettings.client.settings.checkoutPerformance.filter(a => a.active === true)
                .forEach(rec => {
                    _performance.push({
                        name: <div style={{ color: rec.color }}>{rec.name}</div>,
                        value: rec.value,
                        color: rec.color,
                    })
                })
        setPerformance(_performance)
        setTableFields([
            ...checkoutFields.filter(a => a.value !== 'performance'),
            ..._performance.map(rec => {
                return {
                    name: <div style={{ color: rec.color }}>{rec.name}</div>,
                    label: rec.name,
                    value: rec.value,
                }
            }),
            ...dynReport.columns.map(rec => {
                return {
                    ..._.find(reportDynColumns, { value: rec })
                }
            })
        ]);
    }, [propsSettings])

    useEffect(() => {
        if (_.isEmpty(tableFields))
            return
        setLoader(true)
        async function getReportData() {
            let _reportData = [];
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            const resp = await reportData({
                type: dealersettings.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: props.clientid,
                    reportJSON: JSON.stringify({
                        clientID: props.clientid,
                        groupBy: 'user',
                        groupSubtype: clientUsers.filter(a => Boolean(a.superAdmin) === false).map(rec => { return rec.id }),
                        columns: dynReport.columns,
                        pipeline: propsSettings.dealersettings &&
                            propsSettings.dealersettings.client &&
                            propsSettings.dealersettings.client.settings &&
                            propsSettings.dealersettings.client.settings.allPipelines &&
                            _.map(propsSettings.dealersettings.client.settings.allPipelines.filter(item => (inclInboundLeadsinRpt ? true : item.value !== 'LeadsBucket') && !Boolean(item.disableReport)), function (e) { return e.value; })

                    }),
                    startDate: dateRange.strstartDate,
                    endDate: dateRange.strendDate,
                    timezone: !_.isEmpty(dealersettings.timezone) ? dealersettings.timezone : moment.tz.guess(),
                    calcCurrentStatus,
                    multiTDperLead
                })
            });
            if (resp.data.success) {
                _reportData = convertReportData(resp.data.data)
            }
            if (_date !== 'mtd') {
                const refcheckoutSnapshot = await window.firebase.firestore().collection('checkout')
                    .where('clientID', '==', props.clientid)
                    .where('logDate', '>=', dateRange.monthstartDate)
                    .where('logDate', '<=', dateRange.monthendDate)
                    .get();
                let _snapshotData = [];
                refcheckoutSnapshot.forEach((rec) => {
                    _snapshotData.push({ ...rec.data(), 'id': rec.id })
                })

                let __data = []
                clientUsers.forEach(rec => {
                    __data.push({
                        documentID: rec.id,
                        ...getPerformanceData(rec.id, performance, _snapshotData),
                    })
                })
                setMonthData(__data)
            }
            window.firebase.firestore().collection('checkout')
                .where('clientID', '==', props.clientid)
                .where('logDate', '>=', dateRange.startDate)
                .where('logDate', '<=', dateRange.endDate)
                .get()
                .then(querySnapshot => {

                    let snapshotData = [];
                    querySnapshot.forEach((rec) => {
                        snapshotData.push({ ...rec.data(), 'id': rec.id })
                    })

                    let _data = []
                    _.orderBy(clientUsers, ['name'], ['asc']).filter(a => Boolean(a.superAdmin) === false && !_.isEmpty(a.roleID)).forEach(rec => {
                        _data.push({
                            documentID: rec.id,
                            name: rec.name,
                            profileImage: rec.profileImage,
                            userName: rec.name,
                            department: rec.department,
                            ...getPerformanceData(rec.id, performance, snapshotData),
                            ..._.find(_reportData, { id: rec.id })
                        })
                    })
                    if (teams.length > 0) {
                        let _results = [];
                        teams.forEach(rec => {
                            _results.push({
                                name: rec.name,
                                data: _data.filter(a => rec.user_ids.includes(a.documentID))
                            })
                        })
                        setCheckoutData(_results)
                    }
                    else {
                        setCheckoutData([{
                            data: _data
                        }])
                    }
                    if (_date === 'mtd')
                        setMonthData(_data)
                    setLoader(false)
                })
        }
        getReportData()

    }, [tableFields])

    const convertReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.clientid] : []
        let reports = [];
        _report.forEach(rec => {
            reports.push({
                ...rec,
                arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                show: rec.arrshow ? rec.arrshow.length : 0,
                showCount: rec.arrshow ? rec.arrshow.length : 0,
                noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                //avg response time
                arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, propsSettings.dealersettings.client, ''),
                //max reposnse time
                arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), propsSettings.dealersettings.client, ''),
                //min response time
                arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), propsSettings.dealersettings.client, ''),
                open: rec.arropen ? rec.arropen.length : 0,
                statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                //won: rec.arrwon ? rec.arrwon.length : 0,
                arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,
                arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                lost: rec.arrlost ? rec.arrlost.length : 0,
                prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                total: rec.arrtotal ? rec.arrtotal.length : 0,
                tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                arrwonAmount: rec.arrwonAmount ? _.uniq(rec.arrwonAmount.map(r => { return r.documentID })) : [],
                arrquoteAmount: rec.arrquoteAmount ? _.uniq(rec.arrquoteAmount.map(r => { return r.documentID })) : [],
                wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
            })
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: rec.total > 0 ? Math.round((100 / rec.total) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: rec.total > 0 ? Math.round((100 / rec.total) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })

        return reports;
    }

    const getPerformanceData = (id, _performance, _snapshotData) => {
        let _data = {};
        let sum = 0;
        _performance.forEach(rec => {
            _data = {
                ..._data,
                [rec.value]: <div style={{ color: rec.color, fontSize: '14px', fontWeight: 800 }}>{_snapshotData.filter(a => a[id] === rec.value).length}</div>,
                [`sort-${rec.value}`]: _snapshotData.filter(a => a[id] === rec.value).length
            }
            sum += _data[`sort-${rec.value}`];
        })
        _performance.forEach(rec => {
            _data = {
                ..._data,
                [`percentage-${rec.value}`]: sum > 0 ? Math.round((100 / sum) * _data[`sort-${rec.value}`]) : 0,
            }
        })
        return _data;
    }


    return (_.isEmpty(dealersettings) || loader) ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (<>
        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        dealersettings && dealersettings.logoURL
                                            ?
                                            <div className="logo-union"> <img src={dealersettings.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{dealersettings.name}</div>
                                            </>
                                    }
                                </div></td>
                                <td width="40%"><div className="header-right"> {dynReport.name} <br></br>
                                    {
                                        _date === 'wtd' ? (
                                            <span> {`${moment.unix(dateRange.startDate.seconds).format('DD MMM')}-${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`}</span>
                                        ) : !_.isEmpty(dealersettings.timezone) ? (
                                            <span> {moment().tz(dealersettings.timezone).format('DD MMMM YYYY')}</span>
                                        ) : (
                                            <span> {moment().format('DD MMMM YYYY')}</span>
                                        )
                                    }
                                </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : checkoutData && checkoutData.length === 0 ? (
                        <div className="common-table">
                            <div className="text-center p-5">
                                <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                <div className="no-data-txt mt-2"> <Translate text={'No record found'} /></div>
                            </div>
                        </div>
                    ) : (<div className="table-wraper">
                        {
                            checkoutData.map((team, _index) => {
                                return <div key={_index}>
                                    {
                                        team.name ? (<div className="summary-table-wraper-head">{team.name}</div>) : (<></>)
                                    }
                                    <table className="table-loop">
                                        <thead>
                                            <tr>
                                                {
                                                    tableFields.map((rec, index) => {
                                                        return <th key={index} className="head-light text-center" width={`${rec.width ? `20%` : `${Math.round(80 / (tableFields.length - 1))}%`}`}>
                                                            {rec.name}
                                                        </th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                team.data.length > 0 ?
                                                    team.data.map((rec, index) => {
                                                        return <tr key={index}>
                                                            {
                                                                tableFields.map((val, index) => {
                                                                    return <td key={index}>
                                                                        {
                                                                            reportDynColumns.find(item => item.value === val.value) ?
                                                                                (<>
                                                                                    {
                                                                                        reportDynColumns.find(item => item.value === val.value).currency ? (
                                                                                            <NumberFormat
                                                                                                thousandSeparator={true}
                                                                                                prefix={currencySymbol}
                                                                                                displayType={'text'}
                                                                                                value={rec[val.value]}
                                                                                            />
                                                                                        ) : `${rec[val.value]}${reportDynColumns.find(item => item.value === val.value).percentage ? '%' : ''}`

                                                                                    }
                                                                                </>)
                                                                                : val.value === 'user' ?
                                                                                    (
                                                                                        <div className="user-td-bar-wrap user-td-bar-flex">
                                                                                            <div className="user-td-bar-avatar">
                                                                                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} />
                                                                                            </div>
                                                                                            <div className="user-td-bar-detail">
                                                                                                <h3>{rec.name}</h3>
                                                                                                <ProgressBar>
                                                                                                    {
                                                                                                        performance.map((r, i) => {
                                                                                                            return <ProgressBar key={i}
                                                                                                                style={{ backgroundColor: r.color }}
                                                                                                                now={_.find(monthData, { documentID: rec.documentID }) ? _.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`] : 0}
                                                                                                                label={_.find(monthData, { documentID: rec.documentID })
                                                                                                                    && _.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`] > 19 ? `${_.find(monthData, { documentID: rec.documentID })[`percentage-${r.value}`]}%` : ''}
                                                                                                            />
                                                                                                        })
                                                                                                    }
                                                                                                </ProgressBar>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                    : (<>{rec[val.value]}</>)

                                                                        }
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                    }) : (
                                                        <tr>
                                                            <td colSpan={tableFields.length}>
                                                                <div className="spinner-loader h-100 p-5">
                                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                                        <div className="no-data-txt mt-2"> <Translate text={'No Data'} /></div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            })
                        }

                    </div>
                    )
                }
                {
                    dealersettings ? (<div className="footer">
                        <div className="footer-bottom">
                            <p>
                                <span style={{ paddingLeft: 0 }} >
                                    {dealersettings.address ? <>{dealersettings.address} &nbsp; | &nbsp;</> : <></>}
                                </span>
                                <span style={{ paddingLeft: 0 }}>
                                    {dealersettings.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{dealersettings.phone}</span></> : <></>}
                                    {dealersettings.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >&nbsp; | &nbsp;{dealersettings.website}</span></> : <></>}
                                </span>
                            </p>
                        </div>
                        <div className="footer-cpy">
                            <div className="footer-left">{'Powered by FusionSD.'}</div>

                            <div className="footer-right"></div>

                        </div>
                        <div className="clear"></div>
                    </div>) : (<></>)
                }

            </section>
        </div>
    </>)
}

export default ScheculePerformanceReport;