/** LIBRARIES */
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CalendarSettingsTab from './calendarSettingsTab'
import GoogleCalendarSettingsTab from './googleCalendarSettingsTab'
import { UpgradePlan } from '../../../../components';
import _ from 'lodash';
import Translate from '../../../../constants/translate';
import CommonHelper from '../../../../services/common';

const CalendarSettings = props => {

	const liteVersion = ((!_.isEmpty(props.dealersettings) &&
		!_.isEmpty(props.dealersettings.client) &&
		!_.isEmpty(props.dealersettings.client.clientSettings) &&
		props.dealersettings.client.clientSettings.liteVersion) ? true : false);

	const getQueryparam = () => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let key = 'outlookcalendarsettings'
		let state = params.get('state');
		if (state === 'googlecalendar') {
			key = 'googlecalendarsettings'
		}
		return key
	}

	return (
		<>
			{
				liteVersion ? (<UpgradePlan />) : (<></>)
			}
			<h2><Translate text={'Calendar Settings'} /></h2>
			<div className={`settings-tab-wrap tabs ${liteVersion ? 'form-lite' : ''}`}>
				<div className='tabs-wraper'>
					<nav>
						<Tabs
							defaultActiveKey={getQueryparam()}
							transition={false}
							className='nav-fill'
						>
							<Tab eventKey='outlookcalendarsettings' title={CommonHelper.showLocale(props, 'Outlook Calendar')}>
								<div>
									<CalendarSettingsTab {...props} />
								</div>
							</Tab>
							<Tab eventKey='googlecalendarsettings' title={CommonHelper.showLocale(props, 'Google Calendar')}>
								<div>
									<GoogleCalendarSettingsTab {...props} />
								</div>
							</Tab>
						</Tabs>
					</nav>
				</div>
			</div>
		</>
	)
};

export default CalendarSettings;
