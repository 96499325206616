import React, { useState, useEffect, useReducer, useContext } from 'react';
import _ from 'lodash'
import moment from 'moment'
import { ContentOverlay, TableView, PopUpModal } from '../../../../components'
import { wholesalerVM, wholesalerlistFields } from '../users/viewModel'
import { default as _images } from "../../../../images";
import AddWholesaler from './addWholesaler'
import CommonHelper from '../../../../services/common';

import { ClientContext } from '../../../layout/clientContext'

const WholesalerReducer = (state, action) => {
    function updateWholesaler() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_LIST": {
            return updateWholesaler()
        }
        default:
            return state;
    }
};

const WholesalerList = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 315) })
    const [wholesalers, dispatch] = useReducer(WholesalerReducer, [])
    const [checkDataLoad, setDataload] = useState(true)
    const [searchText, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [isPaging, setPaging] = useState(false)
    const [hasMore, setHasMoreData] = useState([])
    const [showWholesalerModel, setShowWholesalerModel] = useState(false)
    const [popHeader, setPopHeader] = useState('')
    const [selectedWholesaler, setSelectedWholesaler] = useState(null)
    const pageLimit = 1000;

    const { clients } = useContext(ClientContext);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 315)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubAdminWholesalerList && window.unSubAdminWholesalerList();
        }
    }, [])


    useEffect(() => {
        if (!checkDataLoad) {
            return;
        }

        let WholesalerDataRef = window.firebase.firestore().collection('users')
            .where('isWholesaler', '==', true);

        // if (searchText) {
        //     userDataRef = userDataRef
        //         .where('keywords', 'array-contains', searchText)
        // }
        // userDataRef = userDataRef
        //     .orderBy('modifiedDate', 'desc')

        if (hasMore.length > 0) {
            WholesalerDataRef = WholesalerDataRef
                .startAfter(hasMore[0])
                .limit(pageLimit)
        }
        else {
            WholesalerDataRef = WholesalerDataRef
                .limit(pageLimit)
        }

        window.unSubAdminWholesalerList = WholesalerDataRef
            .onSnapshot(onCollectionUpdate);

    }, [checkDataLoad])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                if (change.doc.data().email !== 'admin@fusionsd.com') {
                    snapshotDoc = convertVM({
                        ...change.doc.data(),
                        documentID: change.doc.id
                    });
                    if (change.type === 'added') {
                        actionType = "ADD_LIST";
                    }
                    else if (change.type === 'modified') {
                        actionType = "UPDATE_LIST"
                    }
                    else if (change.type === 'removed') {
                        actionType = "REMOVE_LIST"
                    }
                }
            }
        })
        const contacts = [];
        if (!actionType) {
            if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
                setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            }
            else {
                setHasMoreData([]);
            }
            querySnapshot.forEach((doc) => {
                if (doc.data().email !== 'admin@fusionsd.com') {
                    contacts.push(convertVM({
                        ...doc.data(),
                        documentID: doc.id
                    }));
                }
            });
        }

        dispatch({
            type: isPaging ? "APPEND_LIST" : actionType ? actionType : "SUCCESS",
            data: actionType ? snapshotDoc : contacts
        });


        setDataload(false)
        setLoader(false)
        setPaging(false)
    }

    const convertVM = (doc) => {
        const objWholesalerData = Object.assign({}, doc);
        const listVM = Object.assign({}, wholesalerVM);
        for (let [key, value] of Object.entries(objWholesalerData)) {
            if (listVM.hasOwnProperty(key))
                listVM[key] = value;
        }

        listVM.userName = <><div className="table-list-users"><img src={CommonHelper.showUserAvatar(doc.profileImage, listVM.name)} alt="" className="img-object-fit" /></div> {listVM.name}</>
        listVM.lastlogin = doc.lastLoginTime ? moment.unix(doc.lastLoginTime.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.dealerName = (<div className="Vehicle-availability">
            {
                _.filter(clients, (v) => _.indexOf(listVM.wholesalerClientID, v.documentID) >= 0).map((rec, index) => {
                    return <div key={index}><span>{rec.name}</span></div>
                })
            }
        </div>)
        listVM.status = doc.tokkens && doc.tokkens.length > 0 ?
            (<div className="badge badge-pill badge-online ml-1">Online </div>)
            : (<div className="badge badge-pill badge-offline ml-1">Offline </div>)
        return listVM
    }

    const hadleEditWholesaler = (id) => {
        const editWholesaler = wholesalers.find(item => item.documentID === id);
        const wholesaler = Object.assign({}, wholesalerVM);
        for (let [key, value] of Object.entries(editWholesaler)) {
            if (wholesaler[key] !== undefined)
                wholesaler[key] = value;
        }
        setSelectedWholesaler(wholesaler)
        setPopHeader('Edit Wholesaler')
        setShowWholesalerModel(true)
    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            hadleEditWholesaler(id)
        }}>
            <i className="ico icon-edit"></i>
        </div>)
    }

    const handleWholesalerdetailsClick = (e, id) => {
        e.preventDefault();
        hadleEditWholesaler(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }


    const handleModelClose = () => {
        setSelectedWholesaler(null)
        setShowWholesalerModel(false)
    }

    const handleAddWholesaler = () => {
        setSelectedWholesaler(null)
        setPopHeader('Add Wholesaler')
        setShowWholesalerModel(true)
    }

    return (
        <>
            <div className="user-management">
                <div className="settings-head form-style">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="form-row">
                                <div className="col-3">
                                    <div className="filter-search search-icon">
                                        <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="float-right">
                                <button type="button" className="btn btn-primary float-right" onClick={() => { handleAddWholesaler(); }}><i className="ico icon-add mr-2"></i> Add Wholesaler</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                        wholesalers.length > 0 ? (
                            <div className="common-table">
                                <TableView
                                    datalist={_.orderBy(wholesalers.filter(({ name, phone, email }) =>
                                    (!_.isEmpty(searchText) ? (
                                        (!_.isEmpty(name) ? name.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(phone) ? phone.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                        (!_.isEmpty(email) ? email.toLowerCase().includes(searchText.toLowerCase()) : false)
                                    ) : true)
                                    ), ['addedDate.seconds'], ['desc'])}
                                    height={windowSize.windowHeight}
                                    width={windowSize.windowWidth}
                                    columns={wholesalerlistFields}
                                    dynamicFields={wholesalerlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                    handleActionClick={handleActionClick}
                                    handleRowSingleClick={handleWholesalerdetailsClick}
                                    isSettings={false}
                                    hasMore={hasMore}
                                    handlePagination={handlePagination}
                                    isPaging={true}
                                    isReorderable={false}
                                />
                            </div>
                        ) : (
                            <div className="common-table">
                                <div className="text-center p-5">
                                    <p>No wholesalers found.</p>
                                    <button className="btn btn-sm btn-default" onClick={() => { handleAddWholesaler(); }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                        Add Wholesaler
                                    </button>
                                </div>
                            </div>
                        )
                }

            </div>
            <PopUpModal show={showWholesalerModel}>
                <AddWholesaler
                    show={showWholesalerModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    wholesaler={selectedWholesaler}
                />
            </PopUpModal>
        </>
    );
}

export default WholesalerList;