import React, { useState, useEffect } from 'react';
// import moment from 'moment';
// import _ from 'lodash';
import toast from 'toasted-notes';
import StarRatings from 'react-star-ratings'

import { default as _images } from '../../../images';
import CommonHelper from '../../../services/common';

import '../../../styles/customer-feedbackform.scss';
import { InputCheckBox, InputRadio, InputTextArea } from '../../../components';
import _ from 'lodash';

const CustomerReview = props => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const _preview = params.get('preview');

	const [customerReview, setCustomerReview] = useState({
		satisfaction: '',
		responseSatifaction: '',
		ratingDigitalCommunication: '',
		ratingKnowledge: '',
		ratingPoliteness: '',
		issuesFaced: [],
		remarks: [],
	});
	const [view, setView] = useState('landing'); // 'landing', 'end'
	const [loader, setLoader] = useState(true);
	const [enquiry, setEnquiry] = useState({});
	const [contact, setContact] = useState({});
	const [error, setError] = useState(false);
	const [submitLoader, setSubmitLoader] = useState(false);
	const [firestoreDB, setFirestoreDB] = useState()
	const [projectId, setProjectId] = useState()

	useEffect(() => {
		if (props.enquiryID) {
			window.firebase.firestore().doc(`enquiries/${props.enquiryID}`).get()
				.then(doc => {
					if (doc.exists) {
						setFirestoreDB(window.firebase)
					}
					else {
						setFirestoreDB(window.firebase2)
						setProjectId(window.secondaryProjectId);
					}
				})
		}
		else {
			setFirestoreDB(window.firebase)
		}
	}, [])

	useEffect(() => {
		if (_.isEmpty(firestoreDB))
			return
		const getData = async () => {
			let enquiryData = {};
			let contactData = {};
			let userRatings = [];
			let userRatingData = {};

			if (props.enquiryID) {

				const ref = firestoreDB
					.firestore()
					.collection(`enquiries`)
					.doc(props.enquiryID);
				const snapshots = await ref.get();
				console.log('snapshots.exists', snapshots.exists);

				if (snapshots.exists) {
					enquiryData = snapshots.data();
					console.log('enquiry', enquiry);

					contactData = enquiryData.contact;

					const userRatingRef = firestoreDB
						.firestore()
						.collection(`usersRating`)
						.where('enquiryID', '==', props.enquiryID)

					const ratingsnapshots = await userRatingRef.get()
					console.log('ratingsnapshots.size', ratingsnapshots.size)
					console.log('_preview', _preview)

					if (ratingsnapshots.size) {
						ratingsnapshots.docs.forEach((doc) => {
							userRatings.push(doc.data())
						});
						userRatingData = userRatings[0]
						setCustomerReview(userRatingData.customerReview)
					}

					if (!_.isEmpty(userRatingData)) {
						if (_preview) {
							setView('form')
						} else {
							setView('end')
						}
					}
					console.log('userRatingData', userRatingData)
					setContact(contactData);
					setEnquiry(enquiryData);
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		};

		getData();
	}, [firestoreDB]);

	const changeRating = (newRating, name) => {
		if (_preview) {
			return
		}
		console.log('newRating, name', newRating, name)
		setCustomerReview({
			...customerReview,
			[name]: newRating,
		})
	}

	const handleTypeChange = (e) => {
		if (_preview) {
			return
		}
		const { name, value } = e.target;
		// console.log('handleTypeChange', name, value)
		setCustomerReview({
			...customerReview,
			[name]: value
		})
	}

	const handleCheckChange = (e) => {
		if (_preview) {
			return
		}
		const { name, checked, id } = e.target;
		// console.log('handleCheckChange', name, checked, id)
		let arrayToSet = [...customerReview.issuesFaced]
		if (checked === true) {
			arrayToSet.push(id)
		} else {
			arrayToSet = arrayToSet.filter(a => a !== id)
		}
		// console.log('arrayToSet', arrayToSet)
		setCustomerReview({
			...customerReview,
			[name]: arrayToSet
		})
	}

	const handleOnChange = (e) => {
		if (_preview) {
			return
		}
		e.preventDefault()
		const { name, value } = e.target;
		console.log('handleOnChange', name, value)
		let valueToSet = ''
		if (value)
			valueToSet = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
		else
			valueToSet = ''

		setCustomerReview({
			...customerReview,
			remarks: valueToSet
		})
	}

	const handleSubmit = async (e) => {
		if (_preview) {
			return
		}
		e.preventDefault();
		setSubmitLoader(true)
		const objData = {
			contactID: enquiry.contactID,
			enquiryID: enquiry.documentID,
			clientID: enquiry.clientID,
			userID: enquiry.owner,
			customerReview: customerReview,
			addedDate: window.firebase.firestore.Timestamp.now(),
			projectId: projectId ? projectId : null
		}

		console.log('objData', objData)

		const usersSaveRating = window.firebase
			.functions()
			.httpsCallable('users-saveRating');
		setTimeout(() => {
			setSubmitLoader(false)
			setView('end')
		}, 5000);
		const resp = await usersSaveRating(objData);

		//console.log('resp', resp)



		if (resp.data.success) {
			console.log('saved', resp.data)
			// linkEnqruiy(resp.data)
			// setSendLoader(false);
			setSubmitLoader(false)
			setView('end')
		} else {
			toast.closeAll();
			toast.notify('There was an error in saving.', {
				duration: 2000,
			});
			setSubmitLoader(false)
		}
	}

	return loader ? (
		<>
			<div className='loader-center-screen'>
				<div className='spinner-loader h-100 '>
					<div className='d-flex h-100 justify-content-center align-items-center'>
						<div role='status' className='spinner-border' style={{ color: '#fff' }}></div>
						<p className='text-muted mt-3'></p>
					</div>
				</div>
			</div>
		</>
	) : (
		<main role='main' className="container-fluid pl-0 pr-0">
			<div className='feedbackform-header'>
				<div className='container'>
					<div className='feedbackform-logo-wrap'>
						<span className='feedbackform-logo-img mr-3'>
							<img
								style={{ maxHeight: '50px' }}
								src={_images.porscheLogo}
								alt='logo'
							/>
						</span>
					</div>
				</div>
			</div>

			{view === 'form' ? (
				<>
					<div className='container'>
						<div className='feedbackform-title'>
							<h1>Owner Experience Survey</h1>
						</div>
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap'>
								<div className='feedbackform-question-loop'>
									<div className='feedbackform-question-container'>
										1. Overall, how satisfied are you with the service
										experience?
									</div>
									<div className='feedbackform-answer-container'>
										<div className='feedbackform-input-wrap'>
											<div className='radio icheck-success'>
												<InputRadio
													id="5"
													type="radio"
													value="5"
													name="satisfaction"
													checked={customerReview.satisfaction === '5' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='5'> Very satisfied</label>
											</div>
											<div className='radio icheck-success'>
												<InputRadio
													id="4"
													type="radio"
													value="4"
													name="satisfaction"
													checked={customerReview.satisfaction === '4' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='4'> Satisfied </label>
											</div>
											<div className='radio icheck-success'>
												<InputRadio
													id="3"
													type="radio"
													value="3"
													name="satisfaction"
													checked={customerReview.satisfaction === '3' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='3'> Neutral </label>
											</div>
											<div className='radio icheck-success'>
												<InputRadio
													id="2"
													type="radio"
													value="2"
													name="satisfaction"
													checked={customerReview.satisfaction === '2' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='2'> Dissatisfied </label>
											</div>
											<div className='radio icheck-success'>
												<InputRadio
													id="1"
													type="radio"
													value="1"
													name="satisfaction"
													checked={customerReview.satisfaction === '1' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='1'> Very Dissatisfied </label>
											</div>
										</div>
									</div>
								</div>

								<div className='feedbackform-question-loop'>
									<div className='feedbackform-question-container'>
										2. Please rate below based on your experience.
									</div>
									<div className='feedbackform-answer-container'>
										<div className='feedbackform-answer-rating-loop'>
											<div className='feedbackform-answer-subhead'>
												Digital communication
											</div>
											<div className='feedbackform-answer-starrating'>
												<StarRatings
													rating={customerReview.ratingDigitalCommunication ? customerReview.ratingDigitalCommunication : 0}
													isAggregateRating={true}
													changeRating={changeRating}
													numberOfStars={5}
													starRatedColor={'#ffc107'}
													starHoverColor={'#ffc107'}
													starDimension="20px"
													starSpacing="2px"
													name='ratingDigitalCommunication'
												/>
											</div>
										</div>
										<div className='feedbackform-answer-rating-loop'>
											<div className='feedbackform-answer-subhead'>
												Knowledge of staff
											</div>
											<div className='feedbackform-answer-starrating'>
												<StarRatings
													rating={customerReview.ratingKnowledge ? customerReview.ratingKnowledge : 0}
													isAggregateRating={true}
													changeRating={changeRating}
													numberOfStars={5}
													starRatedColor={'#ffc107'}
													starHoverColor={'#ffc107'}
													starDimension="20px"
													starSpacing="2px"
													name='ratingKnowledge'
												/>
											</div>
										</div>
										<div className='feedbackform-answer-rating-loop'>
											<div className='feedbackform-answer-subhead'>
												Politeness of staff
											</div>
											<div className='feedbackform-answer-starrating'>
												<StarRatings
													rating={customerReview.ratingPoliteness ? customerReview.ratingPoliteness : 0}
													isAggregateRating={true}
													changeRating={changeRating}
													numberOfStars={5}
													starRatedColor={'#ffc107'}
													starHoverColor={'#ffc107'}
													starDimension="20px"
													starSpacing="2px"
													name='ratingPoliteness'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='feedbackform-question-loop'>
									<div className='feedbackform-question-container'>
										3. Did we respond quickly to your initial query, and was it
										to your satisfaction?
									</div>
									<div className='feedbackform-answer-container'>
										<div className='feedbackform-input-wrap'>
											<div className='radio icheck-success'>
												<InputRadio
													id="responseSatifactionYes"
													type="radio"
													value="responseSatifactionYes"
													name="responseSatifaction"
													checked={customerReview.responseSatifaction === 'responseSatifactionYes' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='responseSatifactionYes'> Yes</label>
											</div>
											<div className='radio icheck-success'>
												<InputRadio
													id="responseSatifactionNo"
													type="radio"
													value="responseSatifactionNo"
													name="responseSatifaction"
													checked={customerReview.responseSatifaction === 'responseSatifactionNo' ? true : false}
													onChange={handleTypeChange}
												/>
												<label htmlFor='responseSatifactionNo'> No </label>
											</div>
										</div>
									</div>
								</div>

								<div className='feedbackform-question-loop'>
									<div className='feedbackform-question-container'>
										4. Have you faced any of the below issues?
									</div>
									<div className='feedbackform-answer-container'>
										<div className='feedbackform-input-wrap'>
											<div className='checkbox icheck-success'>
												<InputCheckBox
													id="locationOfDealership"
													className="switch"
													name={'issuesFaced'}
													checked={customerReview.issuesFaced.includes('locationOfDealership')}
													onChange={(e) => { handleCheckChange(e) }}
												/>
												<label htmlFor='locationOfDealership'>Location of dealership </label>
											</div>
											<div className='checkbox icheck-success'>
												<InputCheckBox
													id="qualityOfServices"
													className="switch"
													name={'issuesFaced'}
													checked={customerReview.issuesFaced.includes('qualityOfServices')}
													onChange={(e) => { handleCheckChange(e) }}
												/>
												<label htmlFor='qualityOfServices'>Quality of service </label>
											</div>
											<div className='checkbox icheck-success'>
												<InputCheckBox
													id="price"
													className="switch"
													name={'issuesFaced'}
													checked={customerReview.issuesFaced.includes('price')}
													onChange={(e) => { handleCheckChange(e) }}
												/>
												<label htmlFor='price'>Price </label>
											</div>
											<div className='checkbox icheck-success'>
												<InputCheckBox
													id="accessoriesNotAvailable"
													className="switch"
													name={'issuesFaced'}
													checked={customerReview.issuesFaced.includes('accessoriesNotAvailable')}
													onChange={(e) => { handleCheckChange(e) }}
												/>
												<label htmlFor='accessoriesNotAvailable'>Accessories/Parts not available </label>
											</div>
											<div className='checkbox icheck-success'>
												<InputCheckBox
													id="treatmentByDealershipPersonnel"
													className="switch"
													name={'issuesFaced'}
													checked={customerReview.issuesFaced.includes('treatmentByDealershipPersonnel')}
													onChange={(e) => { handleCheckChange(e) }}
												/>
												<label htmlFor='treatmentByDealershipPersonnel'>
													Treatment by dealership personnel{' '}
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className='feedbackform-question-loop'>
									<div className='feedbackform-remarks'>
										<div className='form-row'>
											<div className='form-group col-md-12'>
												<label htmlFor=''>Remarks</label>
												<InputTextArea
													className={`form-control notes`}
													name="remarks"
													onChange={handleOnChange}
													value={customerReview.remarks}
													maxLength="280"
													rows="3">
												</InputTextArea>
											</div>
										</div>
									</div>

									<div className='clear'></div>
									{_preview ? (<></>) : (
										<div className='accordion-bottom approve-btn'>
											{' '}
											<a
												href='#'
												className='btn btn-common button-black float-right mt-3'
												onClick={handleSubmit}
											>
												{submitLoader && (<span
													className='spinner-border spinner-button mr-1 mt-0'
													role='status'
													aria-hidden='true'
												></span>)}
												Submit
											</a>
											<div className='clear'> </div>
										</div>
									)}

									<footer>
										<div className='footer-cpy mt-5'>
											<div className='footer-bottom'>
												<div className='cpy-wrap'>
													{' '}
													Powered by{' '}
													<a href='http://fusionsd.com.au/' target='_blank' rel="noopener noreferrer">
														Fusion SD{' '}
													</a>
												</div>
												<div className='clear'></div>
											</div>
										</div>
									</footer>
								</div>
							</div>
						</div>
					</div>
				</>
			) : view === 'landing' ? (
				<>
					<div className='container'>
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap inner-position-fix'>
								<div className='feedbackform-question-loop mt-4'>
									<div className='feedbackform-landing-wrap'>
										<div className='feedbackform-landing-wrap-head'>
											<div className='feedbackform-landing-image'>
												<img src={_images.surveyImg} alt='' height='200' />
											</div>
											<h2>Got a minute?</h2>
											<p className='feedbackform-landing-owner'>
												Hello {contact.firstName || contact.lastName ? CommonHelper.displayContactName([], contact) : 'customer'}!
											</p>
											<p>
												We'd appreciate if you take just a few minutes of your
												time to share your thoughts, so we can improve our
												services. Thank you for taking our quick survey!
											</p>
										</div>

										<div className='clear'></div>
										<div className='accordion-bottom approve-btn text-center'>
											<a href='#' className='btn btn-common button-black mt-3' onClick={e => { e.preventDefault(); setView('form'); }}>
												Start Now
											</a>
											<div className='clear'></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='container'>
						<div className='feedbackform-wrap-bg'>
							<div className='feedbackform-wrap inner-position-fix'>
								<div className='feedbackform-question-loop mt-4'>
									<div className='feedbackform-landing-wrap'>
										<div className='feedbackform-landing-wrap-head'>
											<div className='feedbackform-landing-image'>
												<img src={_images.surveyTick} alt='' height='150' />
											</div>
											<h2>We appreciate your feedback!</h2>

											<p>
												Thank you for giving us feedback on your experience with
												our service.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</main>
	);
};

export default CustomerReview;
