import React, { useState, useEffect, useReducer, useRef } from 'react';
import moment from 'moment'
import toast from 'toasted-notes'
import _ from 'lodash'
import Swal from 'sweetalert2';
import { combineLatest, of } from 'rxjs';
import { collection, docData } from 'rxfire/firestore';

import { LayoutConsumer } from '../layout/provider';
import Translate from '../../constants/translate';
import { ContentOverlay, SidePanel, TableView, PopUpModal } from '../../components'
//import QuickView from "./quickview";
import FilterPanel from './filterPanel'
import CommonHelper from '../../services/common';
import { allTIPFields } from './viewModel'
import images from '../../images'
import ReAuthenticate from '../common/reAuthenticate';
import { firestoreDB } from '../../services/helper';
import QuickView from "./quickview";

const EnquiryListReducer = (state, action) => {
    function updateTradeinPro() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_ENQUIRY_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_ENQUIRY_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_ENQUIRY_LIST": {
            return updateTradeinPro()
        }
        default:
            return state;
    }
};



const PipelineListView = (props) => {
    const [enquiries, dispatch] = useReducer(EnquiryListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), windowHeight: (window.innerHeight - 123) })
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [tradeinProFields, setTradeinProFields] = useState(props.dealersettings.tradeinProFields)
    const pageLimit = 50
    const [hasMore, setHasMoreData] = useState([])
    const [checkDataLoad, setDataload] = useState(false)
    const [isPaging, setPaging] = useState(false)
    const [searchText, setSearchText] = useState(localStorage.tipSearchText ? localStorage.tipSearchText : '')
    const [enquiryLoader, setEnquiryLoader] = useState(true)
    const [showpanel, setShowPanel] = useState({ clsActive: '', clsName: 'sidebar-tradeinpro-quickview' })
    const [customFilters, setCustomFilters] = useState([])

    const [isFilterApplied, setFilterApplied] = useState(false)
    const [pageNum, setPageNum] = useState(0);
    const [selectedIds, setSelectedIds] = useState({})
    const [modalTransferShow, setModalTransferShow] = useState(false)
    const [modalMoveShow, setModalMoveShow] = useState(false)
    const [pipelineCount, setPipelineCount] = useState(0)

    const [titles, setTitles] = useState([])
    const [languages, setLanguages] = useState([])
    const [nationalities, setNationalities] = useState([])
    const [enquiryTypes, setEnquiryTypes] = useState([])
    const [enquiryOptions, setEnquiryOptions] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [origins, setOrigins] = useState([])


    const [csvHeader, setHeader] = useState([])
    const [csvData, setCSVData] = useState([])
    const csvBtn = useRef();

    const [showReAuthentication, setReAuthentication] = useState(false);

    const [sortConfig, setSortConfig] = useState();
    const [tableLoader, setTableLoader] = useState(false)

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const _tradeinProPipeline = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.tradeinPro) &&
        !_.isEmpty(props.dealersettings.client.tradeinPro.pipelines)) ? props.dealersettings.client.tradeinPro.pipelines : [];


    const [defaultPipeline, setDefaultPipeline] = useState(_tradeinProPipeline.find(item => item.default === true) ? _tradeinProPipeline.find(item => item.default === true).value : '')

    // const _moduleSettings = ((!_.isEmpty(props.dealersettings) &&
    //     !_.isEmpty(props.dealersettings.client) &&
    //     !_.isEmpty(props.dealersettings.client.moduleSettings)) ? props.dealersettings.client.moduleSettings : null);

    // const financeEnabled = ((!_.isEmpty(_moduleSettings) && !_.isEmpty(_moduleSettings.finance) &&
    //     _moduleSettings.finance.enabled) ? true : false);

    const allUsers = !_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers

    useEffect(() => {
        let headerFields = tradeinProFields;
        let allHeaderFields = allTIPFields;
        if (_.isEmpty(headerFields) || headerFields.length === 0) {
            headerFields = allHeaderFields.filter(e =>
                e.default === true
                && e.fixed !== true
                && e.fixedRight !== true
            ).map(v => _.pick(v, ['value']).value)
        }
        headerFields = headerFields.filter(e => e !== 'checkbox' && e !== 'favorite' && e !== 'settings' && e !== 'documentID')
        var _headers = _.map(_.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0), function (e) {
            return {
                label: e.name,
                key: !_.isEmpty(e.elementName) ? e.elementName : e.value
            }
        });
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.subText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index > 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.subTextTitle ? rec.subTextTitle : rec.subTextTitle, key: rec.subText })
            }
        })
        _.filter(allHeaderFields, (v) => _.indexOf(headerFields, v.value) >= 0 && !_.isEmpty(v.nestedText)).forEach(rec => {
            var index = _.findIndex(_headers, (e) => {
                return e.key == rec.value;
            }, 0)
            if (index >= 0) {
                _headers = [..._headers.slice(0, (index + 1)), { label: rec.nestedTextTitle, key: rec.nestedText }, ..._headers.slice(index + 1)]
            }
            else {
                _headers.push({ label: rec.nestedTextTitle, key: rec.nestedText })
            }
        })
        setHeader(CommonHelper.bindContactName(_headers, 'contactName'));
        //setHeader(_headers);

    }, [tradeinProFields?.length])

    useEffect(() => {
        if (_.isEmpty(enquiryOptions))
            return
        enquiryOptions.forEach(rec => {
            if (!allTIPFields.some(e => e.value === rec.value))
                allTIPFields.push({
                    name: rec.label,
                    value: rec.value,
                    flex: 1,
                    elementName: rec.type === 'multiselect' ? `${rec.value}Value` : rec.value
                })
        })
        var deleteids = []
        tradeinProFields && tradeinProFields.forEach(rec => {
            if (!allTIPFields.some(e => e.value === rec))
                deleteids.push(rec)
        })
        setTradeinProFields(_.differenceBy(tradeinProFields, deleteids))
    }, [enquiryOptions.length, tradeinProFields?.length])

    useEffect(() => {

        if (!_.isEmpty(props.dealersettings) && !_.isEmpty(props.dealersettings.client) && !_.isEmpty(props.dealersettings.client.settings)) {

            const setttings = props.dealersettings.client.settings;
            const tipSettings = Object.assign({}, props.dealersettings.client.tradeinPro);

            let _enquiryOptions = [];
            tipSettings.enquiryOptionsDF && tipSettings.enquiryOptionsDF.forEach(doc => {
                _enquiryOptions.push({
                    ...doc,
                    value: doc.value,
                    active: _.isBoolean(doc.active) ? doc.active : true,
                    label: doc.name
                });
            });

            setDataload(true);
            setEnquiryOptions(_enquiryOptions.filter(e => e.active === true));
        }
    }, [])

    useEffect(() => {
        if (isFilterApplied || _tradeinProPipeline.length === 0)
            return

        var querySet = [];
        const _pipeline = _tradeinProPipeline.find(item => item.default === true).value
        CommonHelper.getTradeinProDealers(props.dealersettings, true, true).forEach(rec => {
            querySet.push(docData(window.firebase.firestore().doc(`clients/${rec}/counts/${rec}`)))
        })
        window.unSubtradeinProPipelineLstCount = combineLatest(querySet)
            .subscribe(allDocs => {
                var stageCount = {};
                allDocs.forEach(doc => {
                    if (doc.tradeinPro && doc.tradeinPro[_pipeline] && doc.tradeinPro[_pipeline].stages) {
                        let _selectedPipeline = _tradeinProPipeline.find(item => item.default === true);
                        _selectedPipeline && _selectedPipeline.stages.forEach(stage => {
                            stageCount = {
                                ...stageCount,
                                [stage.value]: {
                                    ...sumStages(doc.tradeinPro[_pipeline].stages[stage.value], stageCount[stage.value]),
                                    ['total']: sumObj(doc.tradeinPro[_pipeline].stages[stage.value], stageCount?.[stage.value]?.['total'] ? stageCount[stage.value]['total'] : 0)
                                }
                            }
                        })
                    }
                })
                //console.log('stageCount', stageCount)
                setPipelineCount(stageCount)
            });
    }, [_tradeinProPipeline, isFilterApplied])

    const sumObj = (obj, _stageCount) => {
        var sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum += parseFloat(obj[el]);
            }
        }
        return sum + _stageCount;
    }

    const sumStages = (obj, _stageCount) => {
        var sum = _stageCount ? _stageCount : {};
        for (var el in obj) {
            if (obj.hasOwnProperty(el)) {
                sum = {
                    ...sum,
                    [el]: sum.hasOwnProperty(el) ? sum[el] += parseFloat(obj[el]) : parseFloat(obj[el])
                }
            }
        }
        return sum;
    }

    const handleStatusFilter = (_status) => {
        if (_status) {
            if (!_.isEmpty(localStorage.tipPipelineFilter)) {
                let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
                if (!_.isEmpty(_pipelineFilter.value)) {
                    // update with the existing keyword
                    _pipelineFilter.value['status'] = _status
                }
                else {
                    // update with the new keyword
                    _pipelineFilter.value = { 'status': _status }
                }
                handleApplyFilter(_pipelineFilter);
            }
            else {
                // create new filter with keyword
                let _pipelineFilter = Object.assign({}, { name: 'Adv Filter', value: { 'status': _status }, type: 'filter' })
                handleApplyFilter(_pipelineFilter);
            }
        }
        else if (!_.isEmpty(localStorage.tipPipelineFilter)) {
            let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);
            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('status'))
                handleClearFilter();
            else {
                delete _pipelineFilter.value['status'];
                handleApplyFilter(_pipelineFilter);
            }
        }
    }

    useEffect(() => {
        let settingsid = props.dealersettings.client.settings.id;
        let userID = props.dealersettings.id;
        const userRefDoc = window.firebase.firestore().collection(`clientSettings/${settingsid}/filters`)
            .where('module', '==', 'tradeinPro')
            .onSnapshot((querySnapshot) => {
                let _customFilters = [];
                querySnapshot.forEach(doc => {
                    const _filt = {
                        ...doc.data(),
                        documentID: doc.id,
                        clientIDs: !_.isEmpty(doc.data().clientIDs) ? doc.data().clientIDs : [props.dealersettings.client.id]
                    }
                    _customFilters.push(_filt);
                });
                _customFilters = _customFilters.filter(m => (m.addedBy === userID && m.visibility === 'private') ||
                    (m.visibility === 'shared' && (m.level === 'oem' || m.clientIDs.some(b => b === props.dealersettings.client.id))));
                setCustomFilters(_customFilters)

            });
        return () => {
            userRefDoc && userRefDoc();
        }
    }, [])

    const sidepanelClose = () => {
        setShowPanel({
            ...showpanel,
            clsActive: '',
            tradeinproid: 0
        })
    }

    const sidepanelOpen = (documentID) => {
        setShowPanel({
            ...showpanel,
            clsActive: 'active',
            tradeinproid: documentID
        })

    }
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 123)
            })
        }
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        document.getElementById('top-nav-bar') && document.getElementById('top-nav-bar').classList.add('fixed-top')
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubservicelist && window.unsubservicelist();
            window.unSubtradeinProPipelineLstCount && window.unSubtradeinProPipelineLstCount.unsubscribe();
        }
    }, [])

    useEffect(() => {
        //console.log('localStorage.tipPipelineFilter', isFilterApplied, localStorage.tipPipelineFilter)
        if (localStorage.tipPipelineFilter && !isFilterApplied)
            setFilterApplied(true);

    }, [localStorage.tipPipelineFilter])



    useEffect(() => {
        const { dealersettings } = props;

        if (_.isEmpty(dealersettings)) {
            return;
        }

        if (_tradeinProPipeline.length === 0) {
            return
        }
        if (!checkDataLoad && defaultPipeline === _tradeinProPipeline.find(item => item.default === true).value) {
            return;
        }
        if (defaultPipeline !== _tradeinProPipeline.find(item => item.default === true).value) {
            setEnquiryLoader(true)
        }

        if (isFilterApplied && pageNum === 0 && _.isEmpty(sortConfig))
            setEnquiryLoader(true)

        const viewOtherEnquiry = true;
        const managerWorkflow = dealersettings?.client?.clientSettings?.managerWorkflow ? true : false;
        // const viewOtherEnquiry = ((!_.isEmpty(dealersettings) &&
        //     !_.isEmpty(dealersettings.rolePermissions) &&
        //     !_.isEmpty(dealersettings.rolePermissions.permissions) &&
        //     dealersettings.rolePermissions.permissions.viewOtherEnquiry) ? true : false);

        if (((isFilterApplied || localStorage.tipPipelineFilter) && isValidFilter()) || isValidSortConfig()) {
            let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
            let _pipelineFilter = localStorage.tipPipelineFilter ? JSON.parse(localStorage.tipPipelineFilter) : {};
            let _filter = Object.assign({}, _pipelineFilter.value);

            if (_.isEmpty(_filter.pipeline))
                _filter.pipeline = _tradeinProPipeline.find(item => item.default === true).value;

            if (!viewOtherEnquiry)
                _filter.owner = dealersettings.id;

            /* DATE RANGE FILTER */
            if (!_.isEmpty(_filter.date)) {
                _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
                delete _filter['date'];
            }

            if (_filter.rottenDays &&
                !_.isEmpty(dealersettings) &&
                !_.isEmpty(dealersettings.client) &&
                !_.isEmpty(dealersettings.client.tradeinPro) &&
                !_.isEmpty(dealersettings.client.tradeinPro.pipelines)) {
                _filter.pipelineSettings = JSON.stringify(dealersettings.client.tradeinPro.pipelines)
            }

            if (!_filter.clientID)
                _filter.clientID = CommonHelper.getTradeinProDealers(dealersettings, false, true)

            _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();
            let _searchObject = {
                "type": "searchTradeinpro",
                "filters": JSON.stringify(_filter),
                "sortOrder": !_.isEmpty(sortConfig) ? `${(sortConfig.key === 'finalPrice' && !managerWorkflow) ? 'dealerPrice' : sortConfig.key} ${sortConfig.sort}` : "addedDate desc",
                "pageNum": pageNum,
                "pageLimit": pageLimit
            }

            _searchObject.clientID = _filter.clientID;

            //console.log('filter-searchTradeinpro', _searchObject, clientID, pageNum, _filter);
            const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
            searchEnquiries(_searchObject).then((response) => {
                //console.log('generic-searchData', _rec, response);

                if (response.data.success && ((localStorage.tipPipelineFilter && isValidFilter()) || isValidSortConfig())) {
                    onCollectionTIP(response.data);
                    setPipelineCount({
                        advance: {
                            total: response.data.total
                        }
                    })
                }
                else {
                    setEnquiryLoader(false)
                    setDataload(false)
                    setPaging(false)
                    setTableLoader(false)
                }
            });

            window.unsubservicelist && window.unsubservicelist();
            window.unSubtradeinProPipelineLstCount && window.unSubtradeinProPipelineLstCount.unsubscribe();
            window.unsubTIPlist && window.unsubTIPlist.unsubscribe();

        }
        else {

            let searchKeywords = searchText;
            var collectionPromise = [];

            if (localStorage.tipPipelineFilter) {
                let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
                let _filter = Object.assign({}, _pipelineFilter.value);

                searchKeywords = _filter['keywords'] ? _filter['keywords'] : searchText;

                setSearchText(searchKeywords);
                localStorage.tipSearchText = searchKeywords;
                //console.log('search---Keywords', searchKeywords, searchOwners, searchText)
            }

            let allTIProDealers = CommonHelper.getTradeinProDealers(dealersettings, true, true);
            for (let k = 0; k < Math.ceil(allTIProDealers.length / 10); k++) {
                let refEnquiryData = firestoreDB(dealersettings).firestore().collection('tradeinPro')
                    .where('clientID', 'in', CommonHelper.arrPaginate(allTIProDealers, 10, k + 1))
                    .where('pipeline', '==', _tradeinProPipeline.find(item => item.default === true).value)
                    .where('isDeleted', '==', false)

                if (searchKeywords) {
                    let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
                    if (formattedSearchText.trim())
                        refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
                }

                refEnquiryData = refEnquiryData.orderBy('addedDate', 'desc')

                if (hasMore[k] && defaultPipeline === _tradeinProPipeline.find(item => item.default === true).value) {
                    refEnquiryData = refEnquiryData
                        .startAfter(hasMore[k])
                        .limit(pageLimit)
                }
                else {
                    if (isPaging) {
                        refEnquiryData = '';
                    } else {
                        refEnquiryData = refEnquiryData
                            .limit(pageLimit)
                    }
                }
                collectionPromise.push(refEnquiryData ? collection(refEnquiryData) : of([]));
            }

            if (collectionPromise.length > 0) {
                window.unsubTIPlist = combineLatest(collectionPromise)
                    .subscribe(allDocs => {
                        var querySnapshot = [];
                        let i = 0;
                        let _hasMore = {};
                        allDocs.forEach(doc => {
                            if (doc.length > 0 && doc.length === pageLimit) {
                                _hasMore = {
                                    ..._hasMore,
                                    [i]: doc[doc.length - 1]
                                };
                            }
                            else {
                                _hasMore = {
                                    ..._hasMore,
                                    [i]: null
                                };
                            }
                            querySnapshot.push(...doc);
                            i++;
                        });
                        setHasMoreData(_hasMore);
                        onCollectionUpdate(querySnapshot);
                    });
            }
            else {
                setEnquiryLoader(false)
                setDataload(false)
                setPaging(false)
            }

            // let refEnquiryData = firestoreDB(dealersettings).firestore().collection('tradeinPro')
            //     .where('clientID', 'in', CommonHelper.getTradeinProDealers(dealersettings, true))
            //     .where('pipeline', '==', _tradeinProPipeline.find(item => item.default === true).value)
            //     .where('isDeleted', '==', false)

            // if (searchKeywords) {
            //     let formattedSearchText = CommonHelper.parseSearchText(dealersettings, searchKeywords)
            //     if (formattedSearchText.trim())
            //         refEnquiryData = refEnquiryData.where('keywords', 'array-contains', formattedSearchText.toLowerCase().trim())
            // }

            // // if (!viewOtherEnquiry)
            // //     refEnquiryData = refEnquiryData.where('owner', 'in', [localStorage.uid])

            // refEnquiryData = refEnquiryData.orderBy('addedDate', 'desc')

            // if (hasMore.length > 0 && defaultPipeline === _tradeinProPipeline.find(item => item.default === true).value) {
            //     refEnquiryData = refEnquiryData
            //         .startAfter(hasMore[0])
            //         .limit(pageLimit)
            // }
            // else {
            //     refEnquiryData = refEnquiryData
            //         .limit(pageLimit)
            // }

            // window.unsubservicelist = refEnquiryData
            //     .onSnapshot(onCollectionUpdate);
        }


    }, [checkDataLoad, _tradeinProPipeline, props.dealersettings])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        // querySnapshot.docChanges().forEach(change => {
        //     if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
        //     {
        //         snapshotDoc = CommonHelper.convertTradeinProVM({
        //             ...change.doc.data(),
        //             documentID: change.doc.id
        //         }, props);
        //         if (change.type === 'added') {
        //             actionType = "ADD_ENQUIRY_LIST";
        //         }
        //         else if (change.type === 'modified') {
        //             actionType = "UPDATE_ENQUIRY_LIST"
        //         }
        //         else if (change.type === 'removed') {
        //             actionType = "REMOVE_ENQUIRY_LIST"
        //         }
        //     }
        // })
        const _enquiries = [];
        if (!actionType) {
            // if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
            //     setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
            // }
            // else {
            //     setHasMoreData([]);
            // }
            querySnapshot.forEach(function (doc) {
                var logVM = CommonHelper.convertTradeinProVM({
                    ...doc.data(),
                    documentID: doc.id
                }, props);
                _enquiries.push(logVM)
            });
        }
        dispatch({
            type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
            data: actionType ? snapshotDoc : _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_tradeinProPipeline.find(item => item.default === true).value)
        setTableLoader(false)
    }

    const onCollectionTIP = (querySnapshot) => {

        const _enquiries = [];

        if (querySnapshot.data.length > 0 && querySnapshot.data.length === pageLimit) {
            setHasMoreData([querySnapshot.data[querySnapshot.data.length - 1]])
            let _pageNum = pageNum + 1;
            setPageNum(_pageNum)
        }
        else {
            setHasMoreData([]);
        }
        querySnapshot.data.forEach(function (doc) {
            var logVM = CommonHelper.convertTradeinProVM(doc, props);
            _enquiries.push(logVM)
        });

        dispatch({
            type: isPaging ? "APPEND_LIST" : "SUCCESS",
            data: _enquiries,
        });
        setEnquiryLoader(false)
        setDataload(false)
        setPaging(false)
        setDefaultPipeline(_tradeinProPipeline.find(item => item.default === true).value)
        setTableLoader(false)
    }

    const isValidFilter = () => {
        if (localStorage.tipPipelineFilter) {
            let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            // if (Object.keys(_filter).length === 2 && (_filter.hasOwnProperty('keywords') && (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            // if (Object.keys(_filter).length === 1 && (_filter.hasOwnProperty('keywords') || (_filter.hasOwnProperty('owner') && _filter['owner'] && ((_filter['owner']).split(',')).length <= 8)))
            //     return false;

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else {
            return false;
        }
    }

    const isValidSortConfig = () => {
        if (searchText && sortConfig)
            return false;
        else if (localStorage.tipPipelineFilter && sortConfig) {
            let _pipelineFilter = JSON.parse(localStorage.tipPipelineFilter);
            let _filter = Object.assign({}, _pipelineFilter.value);

            if (Object.keys(_filter).length === 1 && _filter.hasOwnProperty('keywords'))
                return false;

            return true;
        }
        else if (sortConfig) return true; else return false;
    }

    const handleSavesettings = (fields, showToast) => {
        setSettingsLoader(true)
        window.firebase.firestore().doc(`/users/${localStorage.uid}/columnSettings/${localStorage.uid}`)
            .set({ tradeinProFields: fields }, { merge: true })
            .then(() => {
                setTradeinProFields(fields)
                setSettingsLoader(false)
                props.updateDealerSettings('tradeinProFields', fields)
                if (showToast)
                    toast.notify('Settings updated successfully', {
                        duration: 2000
                    })

            }).catch(error => {
                setSettingsLoader(false)
                console.error(error)
            });

    }

    const handleActionClick = (id) => {
        return (<div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
            sidepanelOpen(id)
        }}>
            <i className="ico icon-more"></i>
        </div>)
    }

    const enquiryDetailclick = (e, id) => {
        sidepanelOpen(id)
    }

    const handlePagination = () => {
        setPaging(true)
        setDataload(true)
    }

    const handleFavorite = (id, val) => {
        const enq = enquiries.find(item => item.documentID === id);
        if (!_.isEmpty(enq)) {
            enq.isFav = val
            dispatch({
                type: "UPDATE_ENQUIRY_LIST",
                data: enq
            });
            firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${id}`)
                .set({
                    isFav: val
                }, { merge: true })
                .then(snapshot => {
                    // toast.notify('service updated successfully', {
                    //     duration: 2000
                    // })

                })
                .catch(error => {
                    console.error(error);
                    toast.notify('Something went wrong', {
                        duration: 2000
                    })
                });
        }
    }

    const updateListEnquiry = (objEnquiry) => {
        if (!_.isEmpty(objEnquiry)) {
            let enq = CommonHelper.convertTradeinProVM(objEnquiry, props);

            if (!_.isEmpty(enq)) {
                dispatch({
                    type: "UPDATE_ENQUIRY_LIST",
                    data: enq
                });
            }
        }

    }

    const handleApplyFilter = (_filter) => {
        if (!_.isEmpty(_filter) && !_.isEmpty(_filter.value)) {
            localStorage.setItem('tipPipelineFilter', JSON.stringify(_filter));
            localStorage.tipSearchText = '';
            setFilterApplied(true);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
            setSortConfig(null)
        }
        else {
            handleClearFilter();
        }
    }

    const handleClearFilter = () => {
        if (!_.isEmpty(localStorage.tipPipelineFilter)) {
            localStorage.removeItem('tipPipelineFilter');
            localStorage.tipSearchText = '';
            setFilterApplied(false);
            setDataload(true);
            setPaging(false);
            setHasMoreData([])
            setPageNum(0);
            setSearchText('');
            setSortConfig(null)
        }
    }

    const handleAllChecked = (event) => {
        let _selectedIds = {};
        if (event.target.checked) {
            !_.isEmpty(enquiries) && enquiries.map(file => {
                let record = Object.assign({}, enquiries.filter(e => e.documentID === file.documentID)[0].objDoc);
                _selectedIds[file.documentID] = record;
            })
        }
        setSelectedIds(_selectedIds);
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;

        let _selectedIds = Object.assign({}, selectedIds);
        if (checked) {
            let record = Object.assign({}, enquiries.filter(e => e.documentID === name)[0]);
            if (!_.isEmpty(record))
                _selectedIds[name] = record.objDoc;
        }
        else {
            delete _selectedIds[name];
        }
        setSelectedIds(_selectedIds);
    }

    // const handleShowTransferEnquiries = () => {
    //     let _selectedIds = Object.assign({}, selectedIds);

    //     if (_.isEmpty(_selectedIds)) {
    //         Swal.fire('Please select service to transfer.');
    //         return;
    //     }
    //     else {
    //         setModalTransferShow(true)
    //     }
    // }


    // const handleCloseTransferEnquiries = async (owner) => {
    //     if (owner) {
    //         let ObjData = {
    //             owner: owner,
    //             modifiedBy: props.dealersettings ? props.dealersettings.id : '',
    //             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //             modifiedFrom: 'web',
    //         }

    //         const batch = window.firebase.firestore().batch();

    //         let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
    //             result[key] = selectedIds[key];
    //             return result;
    //         }, {});
    //         for (let [key, value] of Object.entries(_selectedIds)) {
    //             let _selectedEnq = Object.assign({}, value);
    //             await window.firebase.firestore().collection('enquiries')
    //                 .doc(key)
    //                 .update(ObjData).then((docRef) => {
    //                     for (let [key, value] of Object.entries(ObjData)) {
    //                         _selectedEnq[key] = value;
    //                     }
    //                     updateListEnquiry(_selectedEnq);
    //                 }).catch((error) => {
    //                     console.error("Error updating enquiries: ", error);
    //                 });

    //             await firestoreDB(props.dealersettings).firestore().collection('activities')
    //                 .where('enquiryID', '==', key)
    //                 .where('isDeleted', '==', false)
    //                 .where('isDone', '==', false)
    //                 .get().then(response => {
    //                     response.docs.forEach((doc) => {
    //                         batch.update(doc.ref, ObjData);
    //                     });
    //                 });
    //         }

    //         batch.commit()
    //             .then(() => {
    //                 setSelectedIds({});
    //                 setModalTransferShow(false)

    //                 let _leadsSize = Object.keys(_selectedIds).length;
    //                 toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Service(s) transferred successfully`, {
    //                     duration: 2000
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setModalTransferShow(false)
    //                 toast.notify('Something went wrong', {
    //                     duration: 2000
    //                 })
    //             });
    //     }
    //     else {
    //         setModalTransferShow(false);
    //     }

    // }

    // const handleShowMoveEnquiries = () => {
    //     let _selectedIds = Object.assign({}, selectedIds);

    //     if (_.isEmpty(_selectedIds)) {
    //         Swal.fire('Please select service to move.');
    //         return;
    //     }
    //     else {
    //         setModalMoveShow(true)
    //     }
    // }

    // const handleCloseMoveEnquiries = async (fields) => {
    //     //    console.log('handleCloseMoveEnquiries',handleCloseMoveEnquiries)
    //     if (!_.isEmpty(fields)) {

    //         let ObjData = {
    //             modifiedBy: props.dealersettings ? props.dealersettings.id : '',
    //             modifiedDate: window.firebase.firestore.Timestamp.now(),
    //             stageDate: window.firebase.firestore.Timestamp.now(),
    //             modifiedFrom: 'web',
    //         }

    //         if (fields.clientID)
    //             ObjData.clientID = fields.clientID;

    //         if (fields.pipeline)
    //             ObjData.pipeline = fields.pipeline;

    //         if (fields.stage)
    //             ObjData.stage = fields.stage;

    //         if (fields.owner)
    //             ObjData.owner = fields.owner;

    //         const batch = window.firebase.firestore().batch();

    //         let _selectedIds = Object.keys(Object.assign({}, selectedIds)).slice(0, 50).reduce((result, key) => {
    //             result[key] = selectedIds[key];
    //             return result;
    //         }, {});
    //         for (let [key, value] of Object.entries(_selectedIds)) {
    //             let _selectedEnq = Object.assign({}, value);
    //             let _objEnq = Object.assign({}, ObjData);

    //             let newstagehistory = {
    //                 pipeline: fields.pipeline,
    //                 stage: fields.stage,
    //                 date: window.firebase.firestore.Timestamp.now(),
    //                 sittingDays: 0
    //             };

    //             if (fields.pipeline === 'LeadsBucket') {
    //                 //_objEnq.status = null;
    //                 _objEnq.isConverted = false;
    //                 _objEnq.convertedBy = null;
    //                 _objEnq.convertedDate = null;
    //             }

    //             let arrstagehistory = _selectedEnq.stageHistory ? _selectedEnq.stageHistory : []
    //             if (arrstagehistory.some(e => e.stage === fields.stage && e.pipeline === fields.pipeline)) {
    //                 newstagehistory = _.filter(arrstagehistory, { stage: fields.stage, pipeline: fields.pipeline })[0]
    //                 newstagehistory.date = window.firebase.firestore.Timestamp.now()
    //             }
    //             arrstagehistory = [...arrstagehistory.filter(e => e.stage !== fields.stage), newstagehistory]
    //             _objEnq.stageHistory = arrstagehistory;

    //             //console.log('_objEnq', _objEnq)
    //             await window.firebase.firestore().collection('enquiries')
    //                 .doc(key)
    //                 .update(_objEnq).then((docRef) => {
    //                     for (let [key, value] of Object.entries(_objEnq)) {
    //                         _selectedEnq[key] = value;
    //                     }
    //                     updateListEnquiry(_selectedEnq);

    //                 }).catch((error) => {
    //                     console.error("Error updating enquiries: ", error);
    //                 });

    //             if (_selectedEnq.owner !== ObjData.owner || (ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
    //                 await firestoreDB(props.dealersettings).firestore().collection('activities')
    //                     .where('enquiryID', '==', key)
    //                     .where('isDeleted', '==', false)
    //                     .where('isDone', '==', false)
    //                     .get().then(response => {
    //                         response.docs.forEach((doc) => {
    //                             batch.update(doc.ref, {
    //                                 clientID: ObjData.clientID,
    //                                 owner: ObjData.owner ? ObjData.owner : localStorage.uid
    //                             });
    //                         });
    //                     });
    //             }

    //             if ((ObjData.clientID && ObjData.clientID !== props.dealersettings.client.id)) {
    //                 await window.firebase.firestore().collection('tradeins')
    //                     .where('enquiryID', '==', key)
    //                     .where('isDeleted', '==', false)
    //                     .get().then(response => {
    //                         response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
    //                     });

    //                 await window.firebase.firestore().collection('testdrives')
    //                     .where('enquiryID', '==', key)
    //                     .where('isDeleted', '==', false)
    //                     .get().then(response => {
    //                         response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
    //                     });

    //                 await window.firebase.firestore().collection('cafeOrders')
    //                     .where('enquiryID', '==', key)
    //                     .where('isDeleted', '==', false)
    //                     .get().then(response => {
    //                         response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
    //                     });

    //                 await window.firebase.firestore().collection(`enquiries/${key}/walkins`)
    //                     .get().then(response => {
    //                         response.docs.forEach((doc) => { batch.update(doc.ref, { clientID: ObjData.clientID }); });
    //                     });
    //             }
    //         }

    //         batch.commit()
    //             .then(() => {
    //                 setSelectedIds({});
    //                 setModalMoveShow(false)

    //                 let _leadsSize = Object.keys(_selectedIds).length;
    //                 toast.notify(`${_leadsSize > 0 ? _leadsSize : ''} Service(s) allocated successfully`, {
    //                     duration: 2000
    //                 })
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 setModalMoveShow(false)
    //                 toast.notify('Something went wrong', {
    //                     duration: 2000
    //                 })
    //             });
    //     }
    //     else {
    //         setModalMoveShow(false);
    //     }
    // }

    const excelDownload = async (total) => {

        const isPrivacyFileEnabled = CommonHelper.isPrivacyFileEnabled(props.dealersettings);
        if (isPrivacyFileEnabled) {
            handleReAuthOpen();
            return;
        }

        let clientID = props.dealersettings.client ? props.dealersettings.client.id : '';
        let _pipelineFilter = localStorage.tipPipelineFilter ? JSON.parse(localStorage.tipPipelineFilter) : {};
        let _filter = Object.assign({}, _pipelineFilter.value);
        _filter.pipeline = _tradeinProPipeline.find(item => item.default === true).value;
        // const viewOtherEnquiry = ((!_.isEmpty(props.dealersettings) &&
        //     !_.isEmpty(props.dealersettings.rolePermissions) &&
        //     !_.isEmpty(props.dealersettings.rolePermissions.tradeinProPermissions) &&
        //     props.dealersettings.rolePermissions.tradeinProPermissions.viewOtherEnquiry) ? true : false);

        // if (!viewOtherEnquiry)
        //     _filter.owner = props.dealersettings.id;

        /* DATE RANGE FILTER */
        if (!_.isEmpty(_filter.date)) {
            _filter = CommonHelper.handleDateRangeFilter(_filter, props.dealersettings);
            delete _filter['date'];
        }

        if (_filter.rottenDays &&
            !_.isEmpty(props.dealersettings) &&
            !_.isEmpty(props.dealersettings.client) &&
            !_.isEmpty(props.dealersettings.client.tradeinPro) &&
            !_.isEmpty(props.dealersettings.client.tradeinPro.pipelines)) {
            _filter.pipelineSettings = JSON.stringify(props.dealersettings.client.tradeinPro.pipelines)
        }
        toast.notify((<div style={{ fontSize: `14px` }}><span className="spinner-border spinner-button  mr-2 spinner-border text-primary" role="status" aria-hidden="true"></span> downloading...</div>),
            {
                position: 'top',
                duration: null
            },
        )

        if (!_filter.clientID)
            _filter.clientID = CommonHelper.getTradeinProDealers(props.dealersettings, false, true)
        _filter.timezone = !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess();

        let _searchObject = {
            "type": "searchTradeinpro",
            "filters": JSON.stringify(_filter),
            "sortOrder": !_.isEmpty(sortConfig) ? `${sortConfig.key} ${sortConfig.sort}` : "addedDate desc",
            "pageNum": 0,
            "pageLimit": total > 999 ? 5000 : (total ? total : 5000)
        }

        _searchObject.clientID = _filter.clientID;



        const searchEnquiries = window.firebase.functions().httpsCallable('generic-searchData');
        const resp = await searchEnquiries(_searchObject);
        //console.log('generic-searchData', _rec, response);
        if (resp.data.success) {
            let _enquiries = [];
            resp.data.data.forEach(function (doc) {
                var logVM = CommonHelper.convertTradeinProVM(doc, props);
                _enquiries.push(logVM)
            });            
            setCSVData(CommonHelper.generateExcelData(_enquiries, csvHeader, props))
            setTimeout(() => {csvBtn.current && csvBtn.current.handleDownload && csvBtn.current.handleDownload();}, 1000)
            toast.closeAll();
            Swal.fire({
                icon: 'success',
                title: CommonHelper.showLocale(props, 'Data downloaded successfully.'),
                showConfirmButton: false,
                timer: 1500
            })

            let _objLogData = {
                notes: 'Excel export from pipeline',
                type: 'excelExport',
                subType: 'serviceJobs',
                params: JSON.stringify(_filter),
            }
            _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, props.dealersettings);
            CommonHelper.saveAuditLog(_objLogData);
        }
        else {
            toast.closeAll();
            Swal.fire('Something went wrong.');
        }
    }

    //#region RE-AUTHENTICATION
    const handleReAuthOpen = () => {
        setReAuthentication(true);
    }

    const handleReAuthOpenClose = (data) => {
        if (!_.isEmpty(data)) {
            setTimeout(() => {
                var dataArray = [];
                for (var i in pipelineCount) {
                    if (i !== 'Converted')
                        dataArray.push(pipelineCount[i]);
                }
                let count = _.sumBy(dataArray, "total")
                excelDownload(count);
            }, 1000);
        }
        setReAuthentication(false);

    }
    //#endregion
    const handleDynamicSort = (sortConfig) => {
        //console.log('handleDynamicSort', sortConfig)
        setSortConfig(sortConfig);
        setPageNum(0);
        setTableLoader(true)
        setPaging(false);
        setHasMoreData([]);
        setDataload(true);
    }

    return (
        <LayoutConsumer>
            {({ dealersettings, clientUsers, groupUsers, updateDealerSettings }) => dealersettings ? (


                <div className={`middle-wrapper ${_tradeinProPipeline.length === 0 ? 'h-100' : ''}`}>

                    {
                        _tradeinProPipeline.length === 0 ? (<></>) : (<FilterPanel
                            pipeline={_tradeinProPipeline}
                            customFilters={customFilters}
                            handlePipelineChange={(val) => {
                                updateDealerSettings('tradeinProPipeline', val);
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.tipPipelineFilter))
                                    localStorage.removeItem('tipPipelineFilter');
                            }}
                            dealersettings={dealersettings}
                            clientUsers={clientUsers}
                            groupUsers={groupUsers}
                            handleApplyFilter={handleApplyFilter}
                            handleClearFilter={handleClearFilter}
                            handleStatusFilter={handleStatusFilter}
                            //handleTransfer={handleShowTransferEnquiries}
                            //handleMove={handleShowMoveEnquiries}
                            showMore={true}
                            stageCounts={pipelineCount}
                            csvHeader={csvHeader}
                            csvData={csvData}
                            datalist={enquiries}
                            view="list"
                            isFilterApplied={isFilterApplied}
                            searchText={searchText}
                            handlesearchText={(val) => {
                                setSearchText(val);
                                setFilterApplied(false);
                                setDataload(true);
                                setPaging(false);
                                setHasMoreData([])
                                setPageNum(0);
                                if (!_.isEmpty(localStorage.tipPipelineFilter))
                                    localStorage.removeItem('tipPipelineFilter');

                            }}
                            selectedIds={selectedIds}
                            excelDownload={excelDownload}
                            csvBtn={csvBtn}
                        />)
                    }
                    <div className={`${_tradeinProPipeline.length === 0 ? 'h-100' : 'mt-2'}`}>
                        {
                            _tradeinProPipeline.length === 0 ? (
                                <div className="spinner-loader h-100">
                                    <div className="no-data-flex-wrap h-100 justify-content-center align-items-center text-primary">
                                        <div className="no-data-img-wrap"><img src={images.nopipeline} width="350" alt="" /></div>
                                        <div className="no-data-txt-wrap">
                                            <div className="no-data-content-head"><Translate text={'Access denied.'} /></div>
                                            <div className="no-data-content"><Translate text={'You dont have any pipeline access. Please contact administrator.'} /></div>
                                        </div>
                                    </div>
                                </div>
                            ) : enquiryLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                (<div className="common-table">
                                    {
                                        tableLoader
                                            ?
                                            <>
                                                <div className="dashboard-widget-loader h-100">
                                                    <div className="d-flex h-100 justify-content-center align-items-center text-primary">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                            </>
                                    }
                                    <TableView
                                        isReorderable={true}
                                        datalist={enquiries}
                                        height={windowSize.windowHeight}
                                        width={windowSize.windowWidth}
                                        columns={allTIPFields}
                                        handleSavesettings={handleSavesettings}
                                        dynamicFields={(tradeinProFields && tradeinProFields.length > 0) ? tradeinProFields.filter(item => item !== 'checkbox') : allTIPFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                        settingsLoader={settingsLoader}
                                        handleActionClick={handleActionClick}
                                        handleRowSingleClick={enquiryDetailclick}
                                        isSettings={true}
                                        hasMore={hasMore}
                                        handlePagination={handlePagination}
                                        isPaging={true}
                                        handleFavorite={handleFavorite}
                                        selectedIds={selectedIds}
                                        handleAllChecked={handleAllChecked}
                                        handleCheckChange={handleCheckChange}
                                        isSorting={true}
                                        dynamicSort={true}
                                        handleDynamicSort={handleDynamicSort}
                                        sortConfig={sortConfig}
                                    />
                                </div>
                                )
                        }


                    </div>

                    {/* <PopUpModal show={modalTransferShow}>
                        <TransferOwner
                            show={modalTransferShow}
                            handleClose={handleCloseTransferEnquiries}
                            title={'Transfer Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        >
                        </TransferOwner>
                    </PopUpModal>

                    <PopUpModal show={modalMoveShow}>
                        <LeadTransfer
                            show={modalMoveShow}
                            handleModalClose={handleCloseMoveEnquiries}
                            title={'Move Enquiries'}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                            isList={true}
                        >
                        </LeadTransfer>
                    </PopUpModal> */}

                    <PopUpModal show={showReAuthentication}>
                        <ReAuthenticate
                            show={showReAuthentication}
                            handleClose={handleReAuthOpenClose}
                            dealersettings={props.dealersettings}
                            privacy='file'
                        />
                    </PopUpModal>

                    <SidePanel
                        clsActive={showpanel.clsActive}
                        clsName={showpanel.clsName}
                        sidepanelClose={sidepanelClose}
                        title=''
                    >
                        <QuickView {...props}
                            tradeinproid={showpanel.tradeinproid}
                            isFilterApplied={isFilterApplied}
                            updateListEnquiry={updateListEnquiry}
                            sidepanelClose={sidepanelClose}
                            dealersettings={props.dealersettings}
                            clientUsers={props.clientUsers}
                            groupUsers={props.groupUsers}
                        />
                    </SidePanel>

                </div>

            ) : (<></>)}
        </LayoutConsumer>
    )
}

export default PipelineListView;