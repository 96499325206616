/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import moment from 'moment';
import Translate from '../../../constants/translate';
import "../../../styles/dailyActivity.scss";
import CommonHelper from '../../../services/common';
import images from '../../../images';

const AttendeeReport = props => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const _date = params.get('date');
    const [loader, setLoader] = useState(true);
    const [client, setClient] = useState({})
    const [firestoreDB, setFirestoreDB] = useState()
    const [event, setEvent] = useState({})
    const [settings, setSettings] = useState()
    const [timezone, setTimezone] = useState(moment.tz.guess())
    const [clientSettings, setClientSettings] = useState()
    const [attendees, setAttendees] = useState([])
    const [dateRange, setDateRange] = useState({})
    const [source, setSource] = useState([])
    const [dealerSettings, setDealerSettings] = useState({})

    useEffect(() => {
        if (props.eventid) {
            window.firebase.firestore().doc(`eventsPro/${props.eventid}`).get()
                .then(doc => {
                    if (doc.exists) {
                        setFirestoreDB(window.firebase)
                    }
                    else {
                        setFirestoreDB(window.firebase2)
                    }
                })
        }
        else {
            setFirestoreDB(window.firebase)
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(firestoreDB))
            return

        firestoreDB.firestore().collection(`eventsPro`).doc(props.eventid)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setTimezone(doc.data()?.timezone)
                    setEvent({
                        ...doc.data(),
                        id: doc.id
                    })
                }
                else {
                    setLoader(false)
                }
            })

    }, [firestoreDB])

    useEffect(() => {
        if (_.isEmpty(event))
            return

        let _currentDate = _date !== 'today' ? _date : moment.tz(event.timezone).format('YYYY-MM-DD');
        let _promise = [];
        _promise.push(firestoreDB.firestore().collection(`eventsPro`).doc(props.eventid).collection('settings').doc(props.eventid).get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`)
            .where('isDeleted', '==', false)
            .where('eventsProID', '==', props.eventid)
            .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).startOf('day').toDate()))
            .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).endOf('day').toDate()))
            .orderBy('addedDate', 'desc')
            .get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`)
            .where('isDeleted', '==', false)
            .where('eventsProID', '==', `${props.eventid}-EOI`)
            .where('addedDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).startOf('day').toDate()))
            .where('addedDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).endOf('day').toDate()))
            .orderBy('addedDate', 'desc')
            .get());
        _promise.push(firestoreDB.firestore().collection(`eventsProAttendees`)
            .where('isDeleted', '==', false)
            .where('eventsProID', '==', `${props.eventid}`)
            .where('checkinDate', '>=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).startOf('day').toDate()))
            .where('checkinDate', '<=', window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).endOf('day').toDate()))
            .orderBy('checkinDate', 'desc')
            .get());
        _promise.push(window.firebase.firestore().collection(`eventsProTPSource`)
            .where('active', '==', true)
            .where('isDeleted', '==', false)
            .where('eventsProIDs', 'array-contains', props.eventid)
            .get()
        );
        Promise.all(_promise)
            .then(async docs => {
                const settingsPromise = [];
                let _settings = {};
                let _dealerSettings = {};
                let _clientSettings = {}
                let _source = {}
                if (event.clientID) {
                    settingsPromise.push(window.firebase.firestore().collection(`clients`).doc(event.clientID).get());
                    settingsPromise.push(window.firebase.firestore().doc(`clients/${event.clientID}/currentSettings/${event.clientID}/eventsPro/${event.clientID}`).get());
                    settingsPromise.push(window.firebase.firestore().doc(`clients/${event.clientID}/currentSettings/${event.clientID}`).get());
                }
                else if (event.oemID) {
                    settingsPromise.push(window.firebase.firestore().collection(`clientSettings`).doc(event.oemID).get());
                    settingsPromise.push(window.firebase.firestore().doc(`clientSettings/${event.oemID}/currentSettings/${event.oemID}/eventsPro/${event.oemID}`).get());
                    settingsPromise.push(window.firebase.firestore().doc(`clientSettings/${event.oemID}/currentSettings/${event.oemID}`).get());
                }

                const settingsSnap = await Promise.all(settingsPromise);
                if (settingsSnap[0].exists) {
                    setClient(settingsSnap[0].data());
                }
                if (settingsSnap[1].exists) {
                    _clientSettings = settingsSnap[1].data();
                    setClientSettings(_clientSettings);
                }
                if (settingsSnap[2].exists) {
                    _dealerSettings = settingsSnap[2].data();
                    setDealerSettings(_dealerSettings);
                }
                if (docs[0]?.exists) {
                    _settings = docs[0].data();
                    setSettings(_settings)
                }
                if (docs[4]?.size) {
                    _source = docs[4].docs.map((doc) => {
                        return {
                            ...doc.data(),
                            value: doc.id
                        }
                    })
                    setSource(_source)
                }
                const _attendees = [];
                if (docs[1]?.size) {
                    _attendees.push(...docs[1].docs.map((doc) => {
                        return convertAttendeeVM({
                            ...doc.data(),
                            id: doc.id
                        }, _settings, _dealerSettings, _source)
                    }))
                }
                if (docs[2]?.size) {
                    _attendees.push(...docs[2].docs.map((doc) => {
                        return convertAttendeeVM({
                            ...doc.data(),
                            id: doc.id
                        }, _settings, _dealerSettings, _source)
                    }))
                }
                if (docs[3]?.size) {
                    _attendees.push(...docs[3].docs.map((doc) => {
                        return convertAttendeeVM({
                            ...doc.data(),
                            id: doc.id
                        }, _settings, _dealerSettings, _source)
                    }))
                }
                setAttendees(_.uniqBy(_attendees, 'id'))
                setDateRange({
                    date: _currentDate,
                    startDate: window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).startOf('day').toDate()),
                    endDate: window.firebase.firestore.Timestamp.fromDate(moment.tz(_currentDate, timezone).endOf('day').toDate())
                })
                setLoader(false)
            })
    }, [event]);

    const convertAttendeeVM = (doc, _settings, _dealerSettings, _source) => {
        const objcontactData = Object.assign({}, doc);
        const contact = Object.assign({});
        for (let [key, value] of Object.entries(objcontactData)) {
            contact[key] = value;
        }

        contact.objDoc = doc;
        contact.eoi = doc.url?.includes('type=eoi');
        contact.registration = doc.url?.includes('type=registration');
        contact.fullName = CommonHelper.getFullName(_dealerSettings?.settings?.titles, contact, '', true);
        contact.phone = CommonHelper.getFullPhone(doc.phoneCode, doc.phone);
        contact.displayPhone = doc.phone ? (doc.phoneCode ? doc.phoneCode : '') + doc.phone : '';
        contact.displayID = contact.displayID ? contact.displayID : '--';
        contact.csvPhone = contact.displayPhone ? `${contact.displayPhone}` : ''

        contact.licenseExpiry = contact.licenseExpiry ? moment(contact.licenseExpiry).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'}`) : '';
        contact.createdOn = contact.addedDate ? moment.unix(contact.addedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : '';
        contact.updatedOn = contact.modifiedDate ? moment.unix(contact.modifiedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : '';
        contact.checkedOn = contact.checkinDate ? moment.unix(contact.checkinDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`) : '';
        if (contact.actionedDate) {
            contact.actionDate = moment.unix(contact.actionedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`);
        } else if (contact.acceptedDate) {
            contact.actionDate = moment.unix(contact.acceptedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`);
        } else if (contact.declinedDate) {
            contact.actionDate = moment.unix(contact.declinedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`);
        } else if (contact.addedDate) {
            contact.actionDate = moment.unix(contact.addedDate.seconds).tz(timezone).format(`${event?.dateFormat || 'DD/MM/YYYY'} ${event?.timeFormat || 'hh:mm A'}`);
        }

        contact.source = CommonHelper.getNameByValue(_source, contact.source, '');
        contact.dietaryPreference = CommonHelper.getNameByValue(_settings?.dietary, contact.dietaryPreference, '');
        contact.eventSlot = CommonHelper.getNameByValue(_settings?.slots, contact.eventSlot, '');
        contact.dataLocation = CommonHelper.getNameByValue(_settings?.dataLocation, contact.dataLocation, '');
        if (!_.isEmpty(contact.notes)) contact.lastNote = _.orderBy(contact.notes, ["addedDate"], ["desc"])[0]?.notes;

        contact.addedBy = CommonHelper.getUserNamebyId(_dealerSettings?.users, contact.addedBy);
        contact.modifiedBy = CommonHelper.getUserNamebyId(_dealerSettings?.users, contact.modifiedBy);

        contact.optinPhone = doc.optinPhone === null ? '' : (doc.optinPhone ? 'Yes' : 'No');
        contact.optinEmail = doc.optinEmail === null ? '' : (doc.optinEmail ? 'Yes' : 'No');
        contact.optinPost = doc.optinPost === null ? '' : (doc.optinPost ? 'Yes' : 'No');
        contact.optinSMS = doc.optinSMS === null ? '' : (doc.optinSMS ? 'Yes' : 'No');

        contact.marketingOptinEmail = doc.marketingOptinEmail === null ? '' : (doc.marketingOptinEmail ? 'Yes' : 'No');
        contact.marketingOptinSMS = doc.marketingOptinSMS === null ? '' : (doc.marketingOptinSMS ? 'Yes' : 'No');

        const enquiryAllStatus = Object.assign([], _dealerSettings?.settings?.enquiryStatus);
        contact.leadStatusName = CommonHelper.getNameByValue(enquiryAllStatus, doc.leadStatus, '');
        let _objCurrentStatus = doc.leadStatus && enquiryAllStatus.filter(e => e.value === doc.leadStatus)[0];
        contact.leadStatus = !_.isEmpty(doc.leadStatus) ?
            (
                _objCurrentStatus
                    ?
                    <>
                        <span key={doc.leadStatus}
                            className={`status-${doc.leadStatus === 'open' ? 'open' : 'empty'}`}
                            style={{ background: (_objCurrentStatus.color ? _objCurrentStatus.color : '#333') }}
                        >
                            {_objCurrentStatus.name}
                        </span>
                    </>
                    :
                    <>
                    </>
            ) : ('');
        contact.statusName = doc.status;
        contact.status = (contact.status
            ?
            <div key={contact.status} className={`badge badge-pill badge-events-${contact.status}`}>
                {contact.status}
            </div>
            :
            <></>);
        contact.checkedStatusName = contact.checkinDate ? 'CHECKED-IN' : '';
        contact.checkedStatus = contact.checkinDate ? <><div className="badge badge-pill badge-available-fill ">CHECKED-IN</div></> : <></>;
        contact.guestTag = contact.guestOf ? <><div className="badge badge-pill badge-white badge-mini">{contact.guestOf}</div></> : <></>;
        contact.strGuestTag = contact.guestOf || '';


        contact.requirements = CommonHelper.getAttendeReq(_settings, doc);
        if (contact?.requirements?.length > 0) {
            contact.vehicleModel = (contact.requirements[0].make ? contact.requirements[0].make + ' ' : '') + contact.requirements[0].model;
            contact.vehicleType = contact.requirements[0].saleType;
        }
        if (contact?.ownedVehicle) {
            contact.ownedVehicleModel = (contact.ownedVehicle.make ? contact.ownedVehicle.make + ' ' : '') + (contact?.ownedVehicle?.model || '');
            contact.ownedVehicleYear = contact?.ownedVehicle?.year || '';
        }
        if (contact?.testDrives?.length > 0) {
            const _vehicle = contact.testDrives[0].stock ? contact.testDrives[0].stock : contact.testDrives[0];
            contact.testdriveModel = `${_vehicle.make || ''} ${_vehicle.model || ''} ${_vehicle.year || ''} ${_vehicle.extColor || ''}`;
            contact.testdriveVin = _vehicle.vinNo || '';
        }
        const currencySymbol = !_.isEmpty(_dealerSettings?.settings?.currency) ? _dealerSettings.settings.currency.symbol + ' ' : '$ ';
        if (!_.isEmpty(doc.dynamicFields)) {

            _settings?.attendeesDF && _settings.attendeesDF.forEach(rec => {
                if (rec.type === 'toggle') {
                    contact[rec.value] = doc.dynamicFields[rec.value] === true ? 'Yes' : 'No';
                }
                else if (rec.type === 'price') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? currencySymbol + doc.dynamicFields[rec.value] : ''
                }
                else if (rec.type === 'date') {
                    doc.dynamicFields[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value]._seconds ? window.firebase.firestore.Timestamp.fromDate(moment.unix(doc.dynamicFields[rec.value]._seconds)._d) : doc.dynamicFields[rec.value] : ''
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment.unix(doc.dynamicFields[rec.value].seconds).format(`${event?.dateFormat || 'DD/MM/YYYY'}`) : '';
                }
                else if (rec.type === 'monthYear') {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? moment(doc.dynamicFields[rec.value]).format('MMM YYYY') : '';
                }
                else if (rec.type === 'multiselect' && doc.dynamicFields[rec.value]) {
                    contact[`${rec.value}Value`] = _.isArray(doc.dynamicFields[rec.value]) ? doc.dynamicFields[rec.value].join(',') : doc.dynamicFields[rec.value]
                    contact[rec.value] = _.isArray(doc.dynamicFields[rec.value]) ? (<div className="Vehicle-availability">
                        {
                            doc.dynamicFields[rec.value].map((rec, index) => {
                                return <div key={index}><span>{rec}</span></div>
                            })
                        }
                    </div>) : (<div className="Vehicle-availability">{doc.dynamicFields[rec.value]}</div>)
                }
                else {
                    contact[rec.value] = doc.dynamicFields[rec.value] ? doc.dynamicFields[rec.value] : ''
                }
            })
        }
        return contact
    }

    return loader ? (
        <>
            <div className='loader-center-screen'>
                <div className='spinner-loader h-100 '>
                    <div className='d-flex h-100 justify-content-center align-items-center'>
                        <div role='status' className='spinner-border'></div>
                        <p className='text-muted mt-3'></p>
                    </div>
                </div>
            </div>
        </>
    ) : (<>

        <div className="A4 landscape report-data-loaded report-data-load">
            <section className="sheet padding-10mm" style={{ margin: '0px auto 0px auto' }}>
                <div className="header">
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td width="60%"><div className="header-left">
                                    {
                                        client && client.logoURL
                                            ?
                                            <div className="logo-union"> <img src={client.logoURL} height="60" /></div>
                                            :
                                            <>
                                                <div className="dealer">{!_.isEmpty(client) ? client.name : '--'}</div>
                                            </>
                                    }
                                </div></td>

                                <td width="40%"><div className="header-right"> {event?.title} <br></br>
                                    <span> {moment(dateRange.date).format(`${event?.dateFormat || 'DD MMMM YYYY'}`)}</span> </div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-wraper">
                    {
                        attendees.length === 0 ? (
                            <div className="common-table" style={{ height: `500px` }}>
                                <div className='h-100  w-100'>
                                    <div className='spinner-loader h-100'>
                                        <div className='d-flex h-100 justify-content-center align-items-center text-primary' style={{ flexDirection: 'column' }}>
                                            <div className="no-data-img"> <img src={images.nodata} width="60" height="60" alt="" /></div>
                                            <div className="no-data-txt mt-2">
                                                <div className="report-ui-nodata-custom">
                                                    <h2>No Attendees Found</h2>
                                                    <p>Please close the event to stop the daily report for this event.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<></>)
                    }
                    {
                        attendees.some(a => a.eoi && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) ? (
                            <>
                                <div className="table-head" style={{ fontSize: '16px', marginBottom: '1rem', }}>EXPRESSION OF INTEREST
                                    <span className="badge-report-custom-blue">
                                        {_.orderBy(attendees, 'addedDate.seconds', 'asc').filter(a => a.eoi && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds).length}
                                    </span>
                                </div>
                                <table className="table-loop table-sub-fix">
                                    <thead>
                                        <tr>
                                            <th width="15%">CONTACT DETAILS</th>
                                            <th width="15%">PREFERRED RETAILER</th>
                                            <th width="15%">SOURCE</th>
                                            <th width="15%">OWNED VEHICLE</th>
                                            <th width="15%">RSVP STATUS</th>
                                            <th width="15%">SLOT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.orderBy(attendees, 'addedDate.seconds', 'asc').filter(a => a.eoi && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds).map((data, index) => {
                                                return (<tr key={index}>
                                                    <td valign="top" align="left">
                                                        <span><strong>{data.fullName ? data.fullName : '--'} </strong></span>
                                                        {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                        {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                    </td>
                                                    <td>{data.preferredRetailer || '--'} </td>
                                                    <td>{data.source || '--'} </td>
                                                    <td> {data.ownedVehicleModel || ''} </td>
                                                    <td> {data.status || ''}
                                                        {data.actionDate ? <span className="sub-txt">{data.actionDate}</span> : <></>}
                                                    </td>
                                                    <td> {data.eventSlot || ''} </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>
                        ) : (<></>)
                    }
                    {
                        attendees.some(a => a.registration && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds) ? (
                            <>
                                <div className="table-head" style={{ fontSize: '16px', marginBottom: '1rem', }}>REGISTRATION
                                    <span className="badge-report-custom-blue">
                                        {_.orderBy(attendees, 'addedDate.seconds', 'asc').filter(a => a.registration && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds).length}
                                    </span>
                                </div>
                                <table className="table-loop table-sub-fix">
                                    <thead>
                                        <tr>
                                            <th width="15%">CONTACT DETAILS</th>
                                            <th width="15%">PREFERRED RETAILER</th>
                                            <th width="15%">SOURCE</th>
                                            <th width="15%">OWNED VEHICLE</th>
                                            <th width="15%">RSVP STATUS</th>
                                            <th width="15%">SLOT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.orderBy(attendees, 'addedDate.seconds', 'asc').filter(a => a.registration && a.addedDate.seconds >= dateRange.startDate.seconds && a.addedDate.seconds <= dateRange.endDate.seconds)
                                                .map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td valign="top" align="left">
                                                            <span><strong>{data.fullName ? data.fullName : '--'} </strong></span>
                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                        </td>
                                                        <td>{data.preferredRetailer || '--'} </td>
                                                        <td>{data.source || '--'} </td>
                                                        <td> {data.ownedVehicleModel || ''} </td>
                                                        <td> {data.status || ''}
                                                            {data.actionDate ? <span className="sub-txt">{data.actionDate}</span> : <></>}
                                                        </td>
                                                        <td> {data.eventSlot || ''} </td>
                                                    </tr>)
                                                })
                                        }
                                    </tbody>
                                </table>
                            </>
                        ) : (<></>)
                    }
                    {
                        attendees.some(a => a?.checkinDate?.seconds >= dateRange.startDate.seconds && a?.checkinDate?.seconds <= dateRange.endDate.seconds) ? (
                            <>
                                <div className="table-head" style={{ fontSize: '16px', marginBottom: '1rem', }}>CHECKED IN ATTENDEES
                                    <span className="badge-report-custom-blue">
                                        {_.orderBy(attendees, 'checkinDate.seconds', 'asc').filter(a => a?.checkinDate?.seconds >= dateRange.startDate.seconds && a?.checkinDate?.seconds <= dateRange.endDate.seconds).length}
                                    </span>
                                </div>
                                <table className="table-loop table-sub-fix">
                                    <thead>
                                        <tr>
                                            <th width="15%">CONTACT DETAILS</th>
                                            <th width="15%">PREFERRED RETAILER</th>
                                            <th width="15%">SOURCE</th>
                                            <th width="15%">TEST DRIVE</th>
                                            <th width="15%">CHEKED IN</th>
                                            <th width="15%">SLOT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.orderBy(attendees, 'checkinDate.seconds', 'asc').filter(a => a?.checkinDate?.seconds >= dateRange.startDate.seconds && a?.checkinDate?.seconds <= dateRange.endDate.seconds)
                                                .map((data, index) => {
                                                    return (<tr key={index}>
                                                        <td valign="top" align="left">
                                                            <span><strong>{data.fullName ? data.fullName : '--'} </strong></span>
                                                            {data.phone ? <span className="sub-txt">{data.phone}</span> : <></>}
                                                            {data.email ? <span className="sub-txt">{data.email}</span> : <></>}

                                                        </td>
                                                        <td>{data.preferredRetailer || '--'} </td>
                                                        <td>{data.source || '--'} </td>
                                                        <td valign="top" align="left">
                                                            <span><strong>{data.testdriveModel ? data.testdriveModel : '--'} </strong></span>
                                                            {data.testdriveVin ? <span className="sub-txt">{data.testdriveVin}</span> : <></>}
                                                        </td>
                                                        <td>{data.checkedStatus || ''}
                                                            {data.checkedOn ? <span className="sub-txt">{data.checkedOn}</span> : <></>}
                                                        </td>
                                                        <td> {data.eventSlot || ''} </td>
                                                    </tr>)
                                                })
                                        }
                                    </tbody>
                                </table>
                            </>
                        ) : (<></>)
                    }
                </div>
                <div className="footer">
                    {/* <div className="footer-bottom">

                        <p>
                            <span style={{ paddingLeft: 0 }} >
                                {client.address ? <>{client.address} &nbsp; | &nbsp;</> : <></>}
                            </span>
                            <span style={{ paddingLeft: 0 }}>
                                {client.phone ? <><Translate text={'Tel'} />: <span style={{ paddingLeft: 0 }} >{client.phone}</span></> : <></>}
                                {client.website ? <><Translate text={'Web'} />: <span style={{ paddingLeft: 0 }} >{client.website}</span></> : <></>}
                            </span>
                        </p>
                    </div> */}
                    <div className="footer-cpy">
                        <div className="footer-left">{'Powered by FusionSD.'}</div>

                        <div className="footer-right"></div>

                    </div>
                    <div className="clear"></div>
                </div>
            </section>

        </div>
    </>
    );
};

export default AttendeeReport;
