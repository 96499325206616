import React from 'react';
import _ from 'lodash'
import CommonHelper from '../../../../services/common';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

const CustomEvent = React.memo((props) => {
    const { event, children, onSelectEvent, label, allUsers } = props;
    const enqOwner = allUsers && event.enqOwner ? CommonHelper.getUserNamebyId(allUsers, event.enqOwner) : ''
    var eventtype = '';
    var teammates = false;
    if (event.subType && event.isDone === false && event.start < moment().startOf('day')._d) {
        eventtype = 'overdue';
    }
    else if (event.subType && event.isDone === true) {
        eventtype = 'completed';
    }
    else if (event.subType) {
        eventtype = 'todo';
    }
    return <div title={`${label}${!_.isEmpty(event.contactName) ? ' - ' + event.contactName : ''} ${enqOwner ? `(${enqOwner})` : ''} ${event.deviceName ? `(${event.deviceName})` : ''}`} className={`${children.props.className} calendar-event-cell ${eventtype ? `day-${eventtype}` : 'day-completed'}`} style={eventtype ? { ...children.props.style } : { ...children.props.style, backgroundColor: event.wrapper ? event.wrapper : '#cdf3ed' }} onClick={() => onSelectEvent(event)}>
        <div style={{ width: '100%', paddingRight: '0px' }} className={`${children.props.children[0].props.className}`}>{label}
            {
                enqOwner ? (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip>{enqOwner}</Tooltip>
                        }
                    >
                        <div className="float-right">
                            <div className="pipeline-users pl-0">
                                <a href="#" onClick={(e) => { e.preventDefault(); }}>
                                    <img src={CommonHelper.getUserImagebyId(allUsers, event.enqOwner)} width="300" height="300" alt="" className="rounded-circle img-object-fit" />
                                </a>
                            </div>
                        </div>
                    </OverlayTrigger>
                ) : (<></>)
            }
        </div>
        <div className={`${children.props.children[1].props.className}`}>
            {
                event.subType ? (
                    <div className="calendar-week-icon"><i className={`ico ${CommonHelper.activityIconByValue(props.dealersettings, event.subType)}`}></i> {event.title}</div>
                ) : (<></>)
            }
            {
                event.contactName ? (
                    <div className="calendar-week-icon"><i className="ico icon-Contacts"></i>{event.contactName}</div>
                ) : (<></>)
            }
            {
                event.dealerName ? (
                    <div className="calendar-week-icon"><i className="ico icon-group"></i><div className="blue-color">{event.dealerName}</div> </div>
                ) : (<></>)
            }
            {
                event.vehiclemodel ? (
                    <div className="calendar-week-icon"><i className={CommonHelper.getVehicleIcons(props.dealersettings?.client?.category, 'ico icon-Stock')}></i>{event.vehiclemodel}</div>
                ) : (<></>)
            }
            {
                event.regNo || event.stockNo ? (
                    <div className="calendar-week-icon" style={{ paddingLeft: '17px' }}>
                        {
                            event.regNo ? (<>Reg #:{event.regNo}</>) : (<></>)
                        }
                        
                        {/* {
                            event.stockNo ? (<>{event.regNo ? ' , ' : ''}Stock #:{event.stockNo}</>) : (<></>)
                        } */}
                    </div>
                ) : (<></>)
            }
            {
                event.regNo || event.stockNo ? (
                    <div className="calendar-week-icon" style={{ paddingLeft: '17px' }}>
                        {
                            event.stockNo ? (<> Stock #:{event.stockNo}</>) : (<></>)
                        }
                    </div>
                ) : (<></>)
            }
            {/* {
                event.deviceName && event.viewstate !== 'day' ? (
                    <div className="calendar-week-icon"><i className="ico icon-monitor"></i>{event.deviceName}</div>
                ) : (<></>)
            } */}
        </div>
    </div>
})

export default CustomEvent