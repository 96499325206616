/** LIBRARIES */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default class ReactDateTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value;
    }
    render() {
        const { name, onChange, placeholder, value, className, minDate, maxDate } = this.props;
        //console.log('RENDER - React Date Picker', name, value)
        return (
            <DatePicker
                showPopperArrow={false}
                placeholderText={placeholder}
                selected={value ? moment(value)._d : null}
                onChange={val => onChange(val, name)}
                showTimeSelect
                timeIntervals={15}
                timeCaption="time"
                dateFormat="dd/MM/yyyy h:mm aa"
                showMonthDropdown
                showYearDropdown
                minDate={minDate}
                maxDate={maxDate}
                className={className}
            />
        );
    }
}