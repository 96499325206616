/** LIBRARIES */
import React, { useState, useEffect } from 'react';
import Translate from '../constants/translate';
import CommonHelper from '../services/common'

export const CustomToggle = React.forwardRef(({ children, onClick, className }, ref) => (
  <a
    href="#"
    className={className}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}

  </a>
));

export const CustomMenu = React.forwardRef(
  ({ children, style, className, ChildClass, xplacement }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        x-placement={xplacement}
      >

        <ul className={ChildClass}>

          {
            React.Children.toArray(children).map(
              (child, index) =>
                <li key={index}>{child}</li>
            )}

        </ul>
      </div>
    );
  },
);

export const CustomSearchMenu = React.forwardRef(
  ({ children, style, className, ChildClass, xplacement }, ref) => {
    const [searchKeywords, setSearchKeywords] = useState('')

    const handleTextchange = (e) => {
      setSearchKeywords(e.target.value.toLowerCase().trim());
    }
    const handleTextClear = () => {
      setSearchKeywords('');
    }

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        x-placement={xplacement}
      >

        <div className="form-style table-field-drop table-field-drop-wdt">
          <div className="table-field-drop-search mt-2">

            <div className="input-group ">
              <input placeholder="search..." aria-label="Search" value={searchKeywords}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextchange(e)
                }} />
              <div className="input-group-append input-search-clear">
                {
                  searchKeywords && searchKeywords.length > 0
                    ?
                    <a
                      href="#"
                      className="input-search-clear-icon"
                      title="Clear"
                      onClick={(e) => {
                        e.preventDefault();
                        handleTextClear();
                      }}
                    >
                      <i className="ico icon-remove"></i>
                    </a>
                    :
                    <>
                    </>
                }
              </div>
            </div>
          </div>
          <div className="table-field-drop-body mt-2">
            <ul className={ChildClass}>
              {
                React.Children.toArray(children).filter(
                  child =>
                    (!searchKeywords || child.props.children.toLowerCase().indexOf(searchKeywords) >= 0),
                ).map(
                  (child, index) =>
                    <li key={index}>{child}</li>
                )}

            </ul>
          </div>
        </div>
      </div>
    );
  },
);

export const CustomTableFields = React.forwardRef(
  ({ children, style, className, ChildClass, xplacement, handleCancel, id, handleSave, settingsLoader, searchField, handleTextchange }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        x-placement={xplacement}
        id={id}
      >

        <div className="form-style table-field-drop">
          <div className="table-field-drop-header"><Translate text={'Select Columns'} /></div>
          <div className="table-field-drop-search mt-2">
            <input placeholder="search..." aria-label="Search" value={searchField}
              onChange={(e) => {
                handleTextchange(e)
              }} />
          </div>
          <div className="table-field-drop-body mt-2">
            <ul className={ChildClass}>
              {
                React.Children.toArray(children).map(
                  (child, index) =>
                    <li key={index}>{child}</li>
                )}

            </ul>
          </div>
          <div className="table-field-drop-footer mt-2 d-inline-block">
            <button type="button" className="btn btn-primary float-right ml-2" onClick={() => { handleSave() }}>
              {
                settingsLoader ?
                  (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                  : (<></>)
              }
              <Translate text={'save'} />
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => { handleCancel() }}><Translate text={'cancel'} /></button>
          </div>
        </div>
      </div>
    );
  },
);

export const CustomAdvFilters = React.forwardRef(
  ({ children, style, className, xplacement, id, title, searchField, handleTextchange, handleTextClear, activeTab, setActiveTab, noSaveAccess, dealersettings }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        x-placement={xplacement}
        id={id}
      >

        <div className="form-style quick-filter-wrap">
          <div className="quick-filter-header">
            <Translate text={title ? title : 'Filters'} />
          </div>
          <div className="quick-filter-search">
            <div className="input-group ">
              <input placeholder="search filters..." aria-label="Search" value={searchField}
                onChange={(e) => {
                  handleTextchange(e)
                }} />
              <div className="input-group-append input-search-clear">
                {
                  searchField && searchField.length > 0
                    ?
                    <a
                      href="#"
                      className="input-search-clear-icon"
                      title="Clear"
                      onClick={(e) => {
                        e.preventDefault();
                        handleTextClear();
                      }}
                    >
                      <i className="ico icon-remove"></i>
                    </a>
                    :
                    <>
                    </>
                }
              </div>
            </div>
          </div>
          {
            noSaveAccess
              ?
              <></>
              :
              <>
                <div className="quick-filter-nav-wraper">
                  <div className="quick-filter-nav pl-0">
                    <ul>
                      <li><a href="#" className={`nav-activity-tabs ${activeTab === 'All' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab('All');
                        }}><Translate text={'all'} /></a></li>
                      <li><a href="#" className={`nav-activity-tabs ${activeTab === 'Custom' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab('Custom');
                        }}>{CommonHelper.showLimitedChar(CommonHelper.showLocale({ dealersettings }, 'Custom'), 6)}</a></li>
                      <li><a href="#" className={`nav-activity-tabs ${activeTab === 'Favorites' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab('Favorites');
                        }}>{CommonHelper.showLimitedChar(CommonHelper.showLocale({ dealersettings }, 'favorites'), 9)} </a></li>
                      <li><a href="#" className={`nav-activity-tabs ${activeTab === 'Filters' ? 'active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab('Filters');
                        }}>{CommonHelper.showLimitedChar(CommonHelper.showLocale({ dealersettings }, 'advancedFilters'), 16)}</a></li>
                    </ul>
                  </div>
                </div>
              </>
          }

          <>
            {
              children
            }
          </>
        </div>
      </div>
    );
  },
);

export const CustomFilter = React.forwardRef(
  ({ children, style, className, ChildClass, xplacement }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        x-placement={xplacement}
      >
        <div className={ChildClass}>
          <div className="quick-filter-header"> Filters </div>
          {children}
        </div>
      </div>
    );
  },
);