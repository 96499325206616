import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import toast from 'toasted-notes'
import Swal from 'sweetalert2'
import moment from 'moment'
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, PopUpModal, BarChart, PieChart, LineChart, NoDataPieChart, ReactSelect, PopoverPanel } from "../../components";
import CommonHelper from '../../services/common'
import _images from '../../images'
import { reportGroupBy, reportDynColumns, defaultFilters, filterOptions, labels, reportFilters } from './viewModel'
import { CustomMenu, CustomToggle, CustomFilter } from '../../components/customdropdown';
import AddReport from './addDynReport'
import EnquiryList from '../dashboard/enquiryList'
import TestDriveList from '../dashboard/testdriveList'
import ActivityList from '../dashboard/activitiesList'
import TradeInList from '../dashboard/tradeinList'
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import ReactExport from 'react-export-excel-xlsx-fix';
import DynamicFilters from "./dynFilters";
import LogListModel from "./logsList";
import ScheduleReport from "./scheduleReport";
import { allactivityFields } from '../calendar/viewModel';
import { customerLogFields } from './customerLogReport/viewModel';
import { allTradeinFields } from '../tradeIn/viewModel';
import { allenquiryFields } from '../pipeline/viewModel';
import CustomFilters from './customFilters';
import uuid from 'react-uuid'
import cronstrue from 'cronstrue';
import NumberFormat from 'react-number-format';
import QuoteList from '../dashboard/quotesList'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import StockList from './stockList';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const styles = StyleSheet.create({
    page: {
        padding: '10mm'
    },
    header: {
        width: '100%',
        borderBottomColor: '#4466f2',
        borderBottomWidth: 3,
        display: 'inline-block',
        paddingBottom: 10,
        float: 'left',
        paddingTop: 30
    },
    headerwrapLeft: {
        width: '60%',
        float: 'left',
        position: 'absolute',
        left: 0,
        paddingTop: 15
    },
    headerwrapRight: {
        width: '40%',
        position: 'absolute',
        right: 0,
        textAlign: 'right'
    },
    reportImage: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 600
    },
    image: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        maxHeight: 500,
        marginTop: 10
    },
    reporHeader: {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 10,
    },
    footer: {
        width: '100%',
        paddingTop: 8,
        marginTop: 0,
        display: 'inline-block'
    },
    footerCpy: {
        width: '100%',
        fontSize: 8,
        borderTopColor: '#d3d9e6',
        borderTopWidth: 1,
        paddingTop: 8,
        marginTop: 8
    }
});

const ViewDynamicReport = (props) => {
    const [windowSize, setWindowSize] = useState({ windowWidth: (window.innerWidth - 30), tblWidth: (window.innerWidth - 695), windowHeight: (window.innerHeight - 75), tblHeight: (window.innerHeight - 200) })
    const [loader, setLoader] = useState(true)
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const [report, setReport] = useState(props.report);
    const [progressReport, setProgressReport] = useState([])
    const [sortprogressReport, setSortProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [refreshData, setRefreshData] = useState(false)
    const [sortConfig, setSortConfig] = useState();

    const [showModel, setShowmodel] = useState(false)
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false,
        logs: false,
        quotes: false,
        sheduleReport: false,
        scheduleActivity: false,
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)

    const [csvData, setCSVData] = useState([])
    const [sheetData, setSheetData] = useState([])

    const [chartData, setChartData] = useState()
    const [chartImg, setChartImg] = useState()
    const [filterShow, setShow] = useState(true)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [objFilter, setObjFilter] = useState(defaultFilters)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [counts, setCounts] = useState([])
    const [errorFilters, setErrorFilter] = useState({});
    const [schedueReport, setSchedeReport] = useState({});
    const [dynReportGroupBy, setReportGroupBy] = useState(reportGroupBy);
    const [popover, setPopover] = useState({ show: false, target: null },)
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [enqDynamicOptions, setEnqDynamicOptions] = useState([]);

    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false

    const isInbound = (props.report.pipeline
        && props.report.pipeline.some(b => b === 'LeadsBucket')
        && props.report.pipeline.length === 1) ? true : false;
    const enqStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];

    const currencySymbol = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.currency)) ? props.dealersettings.client.currency.symbol + ' ' : '$ ');

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    const multiTDperLead = props?.dealersettings?.client?.clientSettings?.multiTDperLead === true;

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: (window.innerWidth - 30),
                windowHeight: (window.innerHeight - 75),
                tblHeight: (window.innerHeight - 200),
                tblWidth: (window.innerWidth - 695)
            })
        }
        //document.addEventListener("mousedown", handleClick);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.persistencyChart && window.persistencyChart.dispose()
            //document.removeEventListener("mousedown", handleClick);
        }
    }, [])
    useEffect(() => {
        setReport(props.report);
    }, [props.report])
    // useEffect(() => {
    //     if (_.isEmpty(report) || _.isEmpty(report.objFilters))
    //         return;

    //     if (report.objFilters.length >= 3) {
    //         setShow(false);
    //         const reportWrapper = window.jQuery(`#${report.documentID}-report-collapse`);
    //         if (reportWrapper) {
    //             reportWrapper.fadeToggle();
    //         }
    //     }
    // }, [])

    useEffect(() => {
        let _leadSource = []
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `enqtype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Enquiry Type',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `origin-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Origin',
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `leadSource-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Lead Source',
                    })
                })
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.pipelines
            && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
            props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    _leadSource.push({
                        label: `${rec.name} (Stage)`,
                        searchlabel: `${rec.name} Stage`,
                        value: `stage-${rec.value}`,
                        name: `${rec.name} (Stage)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Stage',
                    })
                }
            })
        }
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `outcome-${rec.value}`,
                        name: `${rec.name} (Activity Outcome)`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Activity Outcome',
                        type: 'activity'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `callstatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Call Status',
                        type: 'log'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `enqstatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                    _leadSource.push({
                        label: `${rec.name} Conv. Ratio`,
                        value: `enqstatus-${rec.value}Conv`,
                        name: `${rec.name} Conv. Ratio`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['percentage'],
                        percentage: true,
                        color: rec.color,
                        sourceType: 'Enquiry Status'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `financestatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Finance Status'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.afterMarketStatus &&
            props.dealersettings.client.settings.afterMarketStatus.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: rec.name,
                        value: `afterMarketStatus-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'After Market Status'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        _leadSource.push({
                            label: rec,
                            value: `${item.type};${item.value};${rec}`,
                            name: item.name,
                            view: ['table', 'column', 'pie', 'target'],
                            calculationType: ['count'],
                            color: '#4967CC',
                            sourceType: item.name
                        })
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Activity Type`,
                        value: `acttype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                    _leadSource.push({
                        label: <>{rec.name} Done<span className="user-role-span">Activity Type</span></>,
                        searchlabel: `${rec.name} Done Activity Type`,
                        value: `acttypedone-${rec.value}`,
                        name: `${rec.name} Done`,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: rec.color,
                        sourceType: 'Activity Type',
                        type: 'activity'
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.contactTypes &&
            props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
                .forEach(rec => {
                    _leadSource.push({
                        label: <>{rec.name}<span className="user-role-span">Contact Type</span></>,
                        searchlabel: `${rec.name} Contact Type`,
                        value: `conttype-${rec.value}`,
                        name: rec.name,
                        view: ['table', 'column', 'pie', 'target'],
                        calculationType: ['count'],
                        color: '#4967CC',
                        sourceType: 'Contact Type'
                    })
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            _leadSource.push({
                searchlabel: rec,
                value: rec,
                name: rec === '15' ? '15+' : rec,
                label: rec === '15' ? '15+' : rec,
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#4967CC',
                sourceType: 'Persistency'
            })
            if (rec === '4' || rec === '15') {
                _leadSource.push({
                    searchlabel: rec === '4' ? '0-4 Total' : '5-15+ Total',
                    value: `${rec}plus`,
                    name: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    label: rec === '4' ? (<>0-4 <br /> Total</>) : (<>5-15+ <br /> Total</>),
                    view: ['table', 'column', 'pie', 'target'],
                    calculationType: ['count'],
                    color: '#4967CC',
                    sourceType: 'Persistency',
                    cls: 'table-grey-sub-total'
                })
            }
        })
        if (props.dealersettings.isPorsche === true) {
            _leadSource.push({
                label: 'Leads Created in C@P',
                searchlabel: 'Leads Created in C@P',
                value: 'newCAP',
                name: 'Leads Created in C@P',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Lost From Enquiries',
                searchlabel: 'Lost From Enquiries',
                value: 'lostEnq',
                name: 'Lost From Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Allocated Enquiries',
                searchlabel: 'Allocated Enquiries',
                value: 'allocated',
                name: 'Allocated Enquiries',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
            _leadSource.push({
                label: 'Outdated',
                searchlabel: 'Outdated',
                value: 'outdated',
                name: 'Outdated',
                view: ['table', 'column', 'pie', 'target'],
                calculationType: ['count'],
                color: '#049b8c',
            })
        }
        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)).map(rec => {
            return rec.status === true && _.find(enqStatus, { value: rec.value }) ? {
                ...rec,
                name: rec.value === 'won' && props.report.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: rec.value === 'won' && props.report.columnType === 'persistency' ? 'Total' : `${_.find(enqStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                cls: rec.value === 'won' && props.report.columnType === 'persistency' ? 'table-sub-total' : null
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.label} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : (rec.value === 'preApproved' || rec.value === 'notpreApproved') && props?.dealersettings?.client?.clientSettings?.creditScore?.approved ?
            {
                ...rec,
                name: `${rec.name} ${rec.value === 'preApproved' ? `( >= ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})` : `( < ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})`}`,
                label: `${rec.label} ${rec.value === 'preApproved' ? `( >= ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})` : `( < ${props?.dealersettings?.client?.clientSettings?.creditScore?.approved})`}`,
            }
            : { ...rec }
        }), ..._leadSource]);

        const enquiryOptions = [];
        const enquiryDynFilters = [];
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && (a.type === "select" || a.type === "multiselect" || a.type === "text" || a.type === "number"))
                .forEach(rec => {
                    enquiryOptions.push({
                        label: rec.name,
                        value: `${rec.type}-${rec.value}`,
                        displayName: rec.name,
                        displayValue: rec.name.toLowerCase(),
                        tableHeader: rec.name,
                    })
                    enquiryDynFilters.push({ label: rec.name, value: `${rec.type}-${rec.value}`, displayValue: rec.name.toLowerCase(), id: rec.value })
                })
        if (report.columnType === 'stock') {
            enquiryDynFilters.push({ label: 'Date Type', value: 'dateType', displayValue: 'date type', type: 'stock' })
            enquiryDynFilters.push({ label: 'Stock Status', value: 'stockStatus', displayValue: 'status', type: 'stock' })
            enquiryDynFilters.push({ label: 'Enquiries', value: 'stockEnquiries', displayValue: 'enquiries', type: 'stock' })
            enquiryDynFilters.push({ label: 'Stock Age', value: 'stockAge', displayValue: 'Stock Age', type: 'stock' })
        }
        if (enquiryOptions.length > 0) {
            setReportGroupBy([...reportGroupBy, ...enquiryOptions]);
            setEnqDynamicOptions(enquiryDynFilters)
        }

        setRefreshData(true)
    }, [])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (props.activeKey === props.report.documentID)
            setRefreshData(true)
    }, [props.report.columns])


    // useEffect(() => {
    //     if (props.activeKey === props.report.documentID)
    //         setRefreshData(true)
    // }, [props.report.objFilters])

    useEffect(() => {
        if (_.isEmpty(report))
            return;

        const refScheduleReport = window.firebase.firestore().doc(`schedular/${report.documentID}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [])


    useEffect(() => {
        if (_.isEmpty(sortprogressReport) || props.report.reportType !== 'table')
            return

        let _headers = [];
        let fontFamily = 'Noto Sans'
        let _borderStyle = {
            border: {
                top: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                bottom: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                left: {
                    style: "thin",
                    color: { rgb: "000000" }
                },
                right: {
                    style: "thin",
                    color: { rgb: "000000" }
                }
            }
        }
        let _headerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    color: { rgb: "FFFFFF" },
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "000000" } },
                ..._borderStyle

            }
        }
        let _headerStyleCenter = {
            style: {
                ..._headerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        }
        let _cellStyle = {
            style: {
                font: {
                    sz: 10,
                    name: fontFamily
                },
                ..._borderStyle
            }
        };
        let _cellStyleCenter = {
            style: {
                ..._cellStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        let _numStyle = { style: { font: { sz: 11 } }, numFmt: "0" };
        let _footerStyle = {
            style:
            {
                font: {
                    sz: 10,
                    bold: false,
                    name: fontFamily
                },
                fill: { patternType: "solid", fgColor: { rgb: "ffeede" } },
                ..._borderStyle
            }
        };
        let _footerStyleCenter = {
            style: {
                ..._footerStyle.style,
                alignment: {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        };
        _headers.push(
            {
                title: CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value'),
                dynValue: 'displayName',
                dynWidth: 10,
                ..._headerStyle
            })

        props.report.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name, dynValue: val, dynWidth: cols.name.length, ..._headerStyleCenter })
            }
            else {
                _headers.push({ title: val, dynValue: val, dynWidth: val.toString().length, ..._headerStyleCenter })
            }
            if (props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length) {
                props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                    return _headers.push({ title: dynCol.name, dynValue: dynCol.id, dynWidth: dynCol.name.toString().length, ..._headerStyleCenter })
                })
            }
        })

        if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
            props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((val) => {
                _headers.push({ title: val.name, dynValue: val.id, dynWidth: val.name.toString().length, ..._headerStyleCenter })
            })
        }


        var exportData = []
        var totals = {
            inboundCall: _.sumBy(sortprogressReport, "inboundCall"),
            outboundCall: _.sumBy(sortprogressReport, "outboundCall"),
            totalCall: _.sumBy(sortprogressReport, "totalCall"),
            messageLog: _.sumBy(sortprogressReport, "messageLog"),
            emailLog: _.sumBy(sortprogressReport, "emailLog"),
            noteLog: _.sumBy(sortprogressReport, "noteLog"),
            appointmentScheduled: _.sumBy(sortprogressReport, "appointmentScheduled"),
            testdriveScheduled: _.sumBy(sortprogressReport, "testdriveScheduled"),
            callScheduled: _.sumBy(sortprogressReport, "callScheduled"),
            mailScheduled: _.sumBy(sortprogressReport, "mailScheduled"),
            deliveryScheduled: _.sumBy(sortprogressReport, "deliveryScheduled"),
            appointment: _.sumBy(sortprogressReport, "appointment"),
            testdriveActivity: _.sumBy(sortprogressReport, "testdriveActivity"),
            callActivity: _.sumBy(sortprogressReport, "callActivity"),
            mailActivity: _.sumBy(sortprogressReport, "mailActivity"),
            deliveryActivity: _.sumBy(sortprogressReport, "deliveryActivity"),
            appointmentsold: `${(_.sumBy(sortprogressReport, "apptsold") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "apptsold")) * _.sumBy(sortprogressReport, "won")) : 0)}%`,
            appointmenttestdrive: `${(_.sumBy(sortprogressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "appointment")) * _.sumBy(sortprogressReport, "tdappt")) : 0)}%`,
            testdrivetotestdrive: `${(_.sumBy(sortprogressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "testdriveActivity")) * _.sumBy(sortprogressReport, "tdtestdrive")) : 0)}%`,
            apptsold: _.sumBy(sortprogressReport, "apptsold"),
            delivered: _.sumBy(sortprogressReport, "delivered"),
            displayName: "Totals",
            exisitngLeads: _.sumBy(sortprogressReport, "exisitngLeads"),
            id: "totals",
            lost: _.sumBy(sortprogressReport, "lost"),
            prospectLost: _.sumBy(sortprogressReport, "prospectLost"),
            name: "Totals",
            totalInbound: _.sumBy(sortprogressReport, "totalInbound"),
            overallPending: _.sumBy(sortprogressReport, "overallPending"),
            pendingLeads: _.sumBy(sortprogressReport, "pendingLeads"),
            convertedLeads: _.sumBy(sortprogressReport, "convertedLeads"),
            show: _.sumBy(sortprogressReport, "show"),
            noshow: _.sumBy(sortprogressReport, "noshow"),
            responseTime: (_.sumBy(sortprogressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00'),
            maxresponseTime: (_.sumBy(sortprogressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00'),
            minresponseTime: (_.sumBy(sortprogressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00'),
            allocatedTime: (_.sumBy(sortprogressReport, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalallocatedTime), props.dealersettings.client) : '00:00:00'),
            maxallocatedTime: (_.sumBy(sortprogressReport, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmaxallocatedTime), props.dealersettings.client) : '00:00:00'),
            minallocatedTime: (_.sumBy(sortprogressReport, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalminallocatedTime), props.dealersettings.client) : '00:00:00'),
            contactedTime: (_.sumBy(sortprogressReport, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalcontactedTime), props.dealersettings.client) : '00:00:00'),
            maxcontactedTime: (_.sumBy(sortprogressReport, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmaxcontactedTime), props.dealersettings.client) : '00:00:00'),
            mincontactedTime: (_.sumBy(sortprogressReport, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(sortprogressReport, (p) => p.totalmincontactedTime), props.dealersettings.client) : '00:00:00'),
            newLeads: _.sumBy(sortprogressReport, "newLeads"),
            open: _.sumBy(sortprogressReport, "open"),
            tdappt: _.sumBy(sortprogressReport, "tdappt"),
            tdtestdrive: _.sumBy(sortprogressReport, "tdtestdrive"),
            testdrive: _.sumBy(sortprogressReport, "testdrive"),
            testdriveConv: `${(_.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(sortprogressReport, "testdrive")) : 0)}%`,
            total: _.sumBy(sortprogressReport, "total"),
            tradein: _.sumBy(sortprogressReport, "tradein"),
            tradeincompleted: _.sumBy(sortprogressReport, "tradeincompleted"),
            tradeininprogress: _.sumBy(sortprogressReport, "tradeininprogress"),
            tradeinlost: _.sumBy(sortprogressReport, "tradeinlost"),
            tradeinwon: _.sumBy(sortprogressReport, "tradeinwon"),
            tradeinwonConv: `${(_.sumBy(sortprogressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "tradein")) * _.sumBy(sortprogressReport, "tradeinwon")) : 0)}%`,
            walkin: _.sumBy(sortprogressReport, "walkin"),
            newwalkin: _.sumBy(sortprogressReport, "newwalkin"),
            repeatwalkin: _.sumBy(sortprogressReport, "repeatwalkin"),
            carryover: _.sumBy(progressReport, "carryover"),
            doneActivity: _.sumBy(progressReport, "doneActivity"),
            allocated: _.sumBy(progressReport, "allocated"),
            newCAP: _.sumBy(progressReport, "newCAP"),
            lostEnq: _.sumBy(progressReport, "lostEnq"),
            outdated: _.sumBy(progressReport, "outdated"),
            noActivity: _.sumBy(progressReport, "noActivity"),
            noNotes: _.sumBy(progressReport, "noNotes"),
            noCalls: _.sumBy(progressReport, "noCalls"),
            noMessage: _.sumBy(progressReport, "noMessage"),
            unactionedLeads: _.sumBy(progressReport, "unactionedLeads"),
            totalnewLeads: _.sumBy(progressReport, "totalnewLeads"),
            totalexisitngLeads: _.sumBy(progressReport, "totalexisitngLeads"),
            totalLeads: _.sumBy(progressReport, "totalLeads"),
            totalStock: _.sumBy(progressReport, "totalStock"),
            stockwithEnq: _.sumBy(progressReport, "stockwithEnq"),
            stockwithoutEnq: _.sumBy(progressReport, "stockwithoutEnq"),
            statusModified: _.sumBy(progressReport, "statusModified"),
            walkintestdrive: `${(_.sumBy(sortprogressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "walkin")) * _.sumBy(sortprogressReport, "testdrive")) : 0)}%`,
            won: _.sumBy(sortprogressReport, "won"),
            wonTD: _.sumBy(sortprogressReport, "wonTD"),
            wonConv: `${(_.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(sortprogressReport, "won")) : 0)}%`,
            quotes: _.sumBy(sortprogressReport, "quotes"),
            quoteAmount: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "quoteAmount") + Number.EPSILON) * 100) / 100}`,
            wonAmount: `${currencySymbol}${Math.round((_.sumBy(sortprogressReport, "wonAmount") + Number.EPSILON) * 100) / 100}`,

            scheduledConv: `${(_.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(sortprogressReport, "appointmentScheduled")) : 0)}%`,
            showConv: `${(_.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(sortprogressReport, "show")) : 0)}%`,
            noshowConv: `${(_.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(sortprogressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(sortprogressReport, "noshow")) : 0)}%`,
            convertedConv: `${(_.sumBy(sortprogressReport, "totalInbound") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "totalInbound")) * _.sumBy(sortprogressReport, "convertedLeads")) : 0)}%`,
            newLeadsConv: `${(_.sumBy(sortprogressReport, "totalnewLeads") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "totalnewLeads")) * _.sumBy(sortprogressReport, "newLeads")) : 0)}%`,
            exisitngLeadsConv: `${(_.sumBy(sortprogressReport, "totalexisitngLeads") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "totalexisitngLeads")) * _.sumBy(sortprogressReport, "exisitngLeads")) : 0)}%`,
            totalConv: `${(_.sumBy(sortprogressReport, "totalLeads") > 0 ? Math.round((100 / _.sumBy(sortprogressReport, "totalLeads")) * _.sumBy(sortprogressReport, "total")) : 0)}%`,
            creditScore: _.sumBy(progressReport, "creditScore"),
            preApproved: _.sumBy(progressReport, "preApproved"),
            notpreApproved: _.sumBy(progressReport, "notpreApproved"),
            ...getLeadsByEnquiryTypeTotal(sortprogressReport)
        };

        sortprogressReport.forEach(rec => {
            let obj = rec;
            let arrCols = [{ value: obj.displayName, ..._cellStyle, dynValue: 'displayName', dynWidth: obj.displayName.length }]
            props.report.columns.forEach(col => {
                var cols = reportColumns.find(item => item.value === col)
                arrCols.push({ value: cols?.currency === true || cols?.percentage === true || cols?.average === true ? `${cols && cols.currency === true ? currencySymbol : ''}${obj[col]}${cols && cols.percentage === true ? '%' : ''}` : obj[col], ..._cellStyleCenter, dynValue: col, dynWidth: obj[col] ? obj[col].toString().length : 0 })
                if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                    props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                        arrCols.push({ value: dyncol.action === 'percentage' ? `${obj[dyncol.id]}%` : obj[dyncol.id], ..._cellStyleCenter, dynValue: dyncol.id, dynWidth: obj[dyncol.id] ? obj[dyncol.id].toString().length : 0 })
                    })
                }
            })
            if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                    arrCols.push({ value: col.action === 'percentage' ? `${obj[col.id]}%` : obj[col.id], ..._cellStyleCenter, dynValue: col.id, dynWidth: obj[col.id] ? obj[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = [{ value: 'Totals', ..._footerStyle, dynValue: 'displayName', dynWidth: 6 }]
            props.report.columns.forEach(col => {
                arrCols.push({ value: totals[col], ..._footerStyleCenter, dynValue: col, dynWidth: totals[col] ? totals[col].toString().length : 0 })
                if (props?.report?.dynColumns?.filter(a => a.moveAfter === col)?.length) {
                    props.report.dynColumns.filter(a => a.moveAfter === col).map((dyncol) => {
                        arrCols.push({ value: dyncol.action === 'percentage' ? `${totals[dyncol.id]}%` : totals[dyncol.id], ..._footerStyleCenter, dynValue: dyncol.id, dynWidth: totals[dyncol.id] ? totals[dyncol.id].toString().length : 0 })
                    })
                }
            })
            if (props?.report?.dynColumns?.filter(a => _.isEmpty(a.moveAfter))?.length > 0) {
                props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).forEach((col) => {
                    arrCols.push({ value: col.action === 'percentage' ? `${totals[col.id]}%` : totals[col.id], ..._footerStyleCenter, dynValue: col.id, dynWidth: totals[col.id] ? totals[col.id].toString().length : 0 })
                })
            }
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData.map(r => { return r.map(a => { return { ...a, value: a?.value?.toString() } }) })]
        }])

    }, [sortprogressReport])

    useEffect(() => {
        if (_.isEmpty(sortprogressReport) || props.report.reportType !== 'table')
            return

        const _score = _.sumBy(progressReport, '4plus') > 0 ? Math.round((_.sumBy(progressReport, '15plus') / _.sumBy(progressReport, '4plus')) * 100) : 0;
        var chart = am4core.create(`progressive-${props.report.documentID}`, am4charts.PieChart);
        chart.logo.disabled = true;
        chart.data = [{
            "category": "",
            "value": _score,
            "color": "#4466f2"

        }, {
            "category": "",
            "value": 100 - _score,
            "color": '#d3d9e6'
        }];

        chart.innerRadius = am4core.percent(90)
        //chart.radius = am4core.percent(90)

        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.tooltip.disabled = true;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.ticks.template.disabled = true;
        pieSeries.labels.template.disabled = true;
        var label = pieSeries.createChild(am4core.Label)
        label.text = `${_score}%`;
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = "15px";
        //chart.validateNow();

        pieSeries.slices.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && target.dataItem.dataContext.color)
                return am4core.color(target.dataItem.dataContext.color);
            return color
        });

        window.persistencyChart = chart;
    }, [sortprogressReport])


    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    useEffect(() => {
        if (!refreshData)
            return
        setLoader(true)
        let _filters = {};
        if (!_.isEmpty(props.report.objFilters) && props.report.objFilters.length > 0) {
            props.report.objFilters.forEach(rec => {
                if (rec.name === 'date') {
                    setDateRange({
                        startDate: window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.value.startDate.seconds)._d),
                        endDate: window.firebase.firestore.Timestamp.fromDate(moment.unix(rec.value.endDate.seconds)._d)
                    })
                }
                _filters = rec.name === 'date' ? {
                    ..._filters,
                    startDate: moment.unix(rec.value.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(rec.value.endDate.seconds).format('YYYY-MM-DD')
                } : rec?.name?.includes('select-') || rec?.name?.includes('multiselect-') || rec?.name?.includes('text-') || rec?.name?.includes('number-') ? {
                    ..._filters,
                    dynamicFilter: _filters?.dynamicFilter ? [
                        ..._filters?.dynamicFilter, {
                            name: rec.name,
                            value: rec?.value?.[rec.name] || null,
                            values: rec?.value?.['values'] || null
                        }
                    ] : [{
                        name: rec.name,
                        value: rec?.value?.[rec.name] || null,
                        values: rec?.value?.['values'] || null
                    }]
                } : {
                    ..._filters,
                    ...rec.value,
                    ...(rec.subList ? rec.subList : {})
                }
            })
        }
        else {
            _filters = {
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d).format('YYYY-MM-DD'),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d).format('YYYY-MM-DD')
            }
        }
        if (_filters.dynamicFilter) {
            _filters = {
                ..._filters,
                dynamicFilter: JSON.stringify(_filters.dynamicFilter)
            }
        }
        if (props.dealersettings.isPorsche === true) {
            _filters.isPorsche = true;
        }
        if (report.columnType === 'stock' && props?.dealersettings?.group?.enableSharedStock) {
            _filters.groupID = props.dealersettings.client.group;
        }
        if (props?.dealersettings?.client?.clientSettings?.creditScore?.approved) {
            _filters.creditScore = props?.dealersettings?.client?.clientSettings?.creditScore?.approved;
        }
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: props?.dealersettings?.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify(props.report),
                    calcCurrentStatus,
                    multiTDperLead,
                    ..._filters
                    // startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    // endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    // ...objFilter
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }

    }, [refreshData])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.dealersettings.client.id] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => Boolean(props.report.includeZero) ? true : _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                    profileImage: (props.report.groupBy === 'user' || props.report.groupBy === 'financeBM' || props.report.groupBy === 'afterMarketBM' || props.report.groupBy === 'enqAddedBy') && _.find(props.clientUsers, { id: rec.id }) ? _.find(props.clientUsers, { id: rec.id }).profileImage : '',
                    newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                    exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                    pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                    totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                    overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                    convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                    show: rec.arrshow ? rec.arrshow.length : 0,
                    showCount: rec.arrshow ? rec.arrshow.length : 0,
                    noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                    //avg response time
                    arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                    totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                    responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),

                    //avg allocated time
                    arrallocatedLeads: rec.arrallocatedTime ? _.uniq(rec.arrallocatedTime.map(r => { return r.documentID })) : [],
                    totalallocatedTime: _.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0,
                    allocatedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrallocatedTime, 'allocatedTime') > 0 ? _.meanBy(rec.arrallocatedTime, "allocatedTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //max allocated time
                    arrmaxallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                    arrmaxallocatedLeads: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? [_.maxBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                    totalmaxallocatedTime: _.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                    maxallocatedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrallocatedTime, 'allocatedTime') ? _.maxBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //min allocated time
                    arrminallocatedTime: rec.arrallocatedTime ? rec.arrallocatedTime : [],
                    arrminallocatedLeads: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? [_.minBy(rec.arrallocatedTime, 'allocatedTime').documentID] : [],
                    totalminallocatedTime: _.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0,
                    minallocatedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrallocatedTime, 'allocatedTime') ? _.minBy(rec.arrallocatedTime, 'allocatedTime').allocatedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),


                    //avg contacted time
                    arrcontactedLeads: rec.arrcontactedTime ? _.uniq(rec.arrcontactedTime.map(r => { return r.documentID })) : [],
                    totalcontactedTime: _.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0,
                    contactedTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrcontactedTime, 'contactedTime') > 0 ? _.meanBy(rec.arrcontactedTime, "contactedTime") : 0, props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //max contacted time
                    arrmaxcontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                    arrmaxcontactedLeads: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? [_.maxBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                    totalmaxcontactedTime: _.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                    maxcontactedTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrcontactedTime, 'contactedTime') ? _.maxBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),
                    //min contacted time
                    arrmincontactedTime: rec.arrcontactedTime ? rec.arrcontactedTime : [],
                    arrmincontactedLeads: _.minBy(rec.arrcontactedTime, 'contactedTime') ? [_.minBy(rec.arrcontactedTime, 'contactedTime').documentID] : [],
                    totalmincontactedTime: _.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0,
                    mincontactedTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrcontactedTime, 'contactedTime') ? _.minBy(rec.arrcontactedTime, 'contactedTime').contactedTime : 0), props.dealersettings.client, (props.report.reportType === 'table' ? '' : 'seconds')),


                    open: rec.arropen ? rec.arropen.length : 0,
                    statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                    //won: rec.arrwon ? rec.arrwon.length : 0,
                    arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                    won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,

                    arrwonTD: rec.arrwonTD ? _.uniq(rec.arrwonTD.map(r => { return r.documentID })) : [],
                    wonTD: rec.arrwonTD ? _.sumBy(rec.arrwonTD, "soldCount") : 0,

                    arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                    delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                    lost: rec.arrlost ? rec.arrlost.length : 0,
                    prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                    leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                    totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                    //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                    walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                    newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                    repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                    testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                    tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                    tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                    tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                    tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                    tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                    inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                    outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                    totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                    messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                    emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                    noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                    appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                    testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                    callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                    mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                    deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                    appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                    testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                    callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                    mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                    deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                    total: rec.arrtotal ? rec.arrtotal.length : 0,
                    tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                    tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                    apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                    target: props.report.target ? props.report.target : 0,
                    totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                    totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                    totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                    doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                    allocated: rec.arrallocated ? rec.arrallocated.length : 0,
                    newCAP: rec.arrnewCAP ? rec.arrnewCAP.length : 0,
                    lostEnq: rec.arrlostEnq ? rec.arrlostEnq.length : 0,
                    outdated: rec.arroutdated ? rec.arroutdated.length : 0,
                    noActivity: rec.arrnoActivity ? rec.arrnoActivity.length : 0,
                    unactionedLeads: rec.arrunactionedLeads ? rec.arrunactionedLeads.length : 0,
                    noNotes: rec.arrnoNotes ? rec.arrnoNotes.length : 0,
                    noCalls: rec.arrnoCalls ? rec.arrnoCalls.length : 0,
                    noMessage: rec.arrnoMessage ? rec.arrnoMessage.length : 0,
                    totalStock: rec.arrtotalStock ? rec.arrtotalStock.length : 0,
                    stockwithEnq: rec.arrstockwithEnq ? rec.arrstockwithEnq.length : 0,
                    stockwithoutEnq: rec.arrstockwithoutEnq ? rec.arrstockwithoutEnq.length : 0,
                    overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                    quotes: rec.arrquotes ? rec.arrquotes.length : 0,
                    arrquotes: rec.arrquotes ? _.uniq(rec.arrquotes.map(r => { return r.documentID })) : [],
                    arrwonAmount: rec.arrwonAmount ? _.uniq(rec.arrwonAmount.map(r => { return r.documentID })) : [],
                    arrquoteAmount: rec.arrquoteAmount ? _.uniq(rec.arrquoteAmount.map(r => { return r.documentID })) : [],
                    wonAmount: rec.arrwonAmount ? Math.round((_.sumBy(rec.arrwonAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                    quoteAmount: rec.arrquoteAmount ? Math.round((_.sumBy(rec.arrquoteAmount, item => Number(item.payableOnDelivery)) + Number.EPSILON) * 100) / 100 : 0,
                    creditScore: rec.arrcreditScore ? rec.arrcreditScore.length : 0,
                    preApproved: rec.arrpreApproved ? rec.arrpreApproved.length : 0,
                    notpreApproved: rec.arrnotpreApproved ? rec.arrnotpreApproved.length : 0,
                    ...getDynamicTotalLength(rec)
                })
            }
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.appointmentScheduled) : 0,
                showConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.show) : 0,
                noshowConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })
        props.report.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setProgressReport(reports)
        setSortProgressReport(reports)
    }

    const getDynamicTotalLength = (data) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: data[`arrenqtype-${rec.value}`] ? data[`arrenqtype-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: data[`arrorigin-${rec.value}`] ? data[`arrorigin-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: data[`arrleadSource-${rec.value}`] ? data[`arrleadSource-${rec.value}`].length : 0
                    }
                })
        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.pipelines
            && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: data[`arrstage-${rec.value}`] ? data[`arrstage-${rec.value}`].length : 0
                    }
                }
            })
        }
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: data[`arrlostReason-${rec.value}`] ? data[`arrlostReason-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: data[`arroutcome-${rec.value}`] ? data[`arroutcome-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: data[`arrcallstatus-${rec.value}`] ? data[`arrcallstatus-${rec.value}`].length : 0
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = data[`arrenqstatus-${rec.value}`] ? _.sumBy(data[`arrenqstatus-${rec.value}`], "soldCount") : 0;
                    let _totalCount = data[`arrtotal`] ? data[`arrtotal`].length : 0;
                    let _convertedCount = data[`arrconvertedLeads`] ? data[`arrconvertedLeads`].length : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrenqstatus-${rec.value}`]: data[`arrenqstatus-${rec.value}`] ? _.uniq(data[`arrenqstatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: (isInbound ? _convertedCount : _totalCount) > 0 ? Math.round((100 / (isInbound ? _convertedCount : _totalCount)) * _stsCount) : 0,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrfinancestatus-${rec.value}`] ? _.sumBy(data[`arrfinancestatus-${rec.value}`], "soldCount") : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrfinancestatus-${rec.value}`]: data[`arrfinancestatus-${rec.value}`] ? _.uniq(data[`arrfinancestatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.afterMarketStatus &&
            props.dealersettings.client.settings.afterMarketStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = data[`arrafterMarketStatus-${rec.value}`] ? _.sumBy(data[`arrafterMarketStatus-${rec.value}`], "soldCount") : 0;
                    leadSourceData = {
                        ...leadSourceData,
                        [`arrafterMarketStatus-${rec.value}`]: data[`arrafterMarketStatus-${rec.value}`] ? _.uniq(data[`arrafterMarketStatus-${rec.value}`].map(r => { return r.documentID })) : [],
                        [`afterMarketStatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: data[`arr${item.type};${item.value};${rec}`] ? data[`arr${item.type};${item.value};${rec}`].length : 0
                        }
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && !Boolean(a.default))
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`acttype-${rec.value}`]: data[`arracttype-${rec.value}`] ? data[`arracttype-${rec.value}`].length : 0,
                        [`acttypedone-${rec.value}`]: data[`arracttypedone-${rec.value}`] ? data[`arracttypedone-${rec.value}`].length : 0,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.contactTypes &&
            props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`conttype-${rec.value}`]: data[`arrconttype-${rec.value}`] ? data[`arrconttype-${rec.value}`].length : 0
                    }
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = data[`arr${rec}`] ? _.sumBy(data[`arr${rec}`], "soldCount") : 0;
            leadSourceData = {
                ...leadSourceData,
                [`arr${rec}`]: data[`arr${rec}`] ? _.uniq(data[`arr${rec}`].map(r => { return r.documentID })) : [],
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = data[`arr${rec}plus`] ? _.sumBy(data[`arr${rec}plus`], "soldCount") : 0;
                leadSourceData = {
                    ...leadSourceData,
                    [`arr${rec}plus`]: data[`arr${rec}plus`] ? _.uniq(data[`arr${rec}plus`].map(r => { return r.documentID })) : [],
                    [`${rec}plus`]: __stsCount,
                }
            }
        })
        // if (data) {
        //     let cols = _.isArray(props.report.columns) ? props.report.columns : [props.report.columns];
        //     data.forEach(rec => {
        //         let arr = [];
        //         cols.forEach(col => {
        //             if (rec[`arr${col}`])
        //                 arr = [...arr, ...rec[`arr${col}`]]
        //         })
        //         leadSourceData = {
        //             ...leadSourceData,
        //             [`${props.report.groupBy}-${rec.id}`]: arr.length,
        //             [`arr${props.report.groupBy}-${rec.id}`]: arr,
        //         }
        //     })

        // }

        if (report?.dynColumns?.length > 0) {
            report.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let total = _.sumBy(rec.columns1.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`arr${rec.id}`]: [],
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    var _arrData = [];
                    rec.columns.forEach(r => {
                        _arrData = [
                            ..._arrData,
                            ...(r === 'won' || r === 'delivered' ? _.uniq(data[`arr${r}`].map(r => { return r.documentID })) : data[`arr${r}`])
                        ]
                    })
                    leadSourceData = {
                        ...leadSourceData,
                        [`arr${rec.id}`]: _arrData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return data[`arr${r}`] ? r === 'won' || r === 'delivered' ? _.sumBy(data[`arr${r}`], "soldCount") : data[`arr${r}`].length : 0 }))
                    }
                }
            })
        }

        return leadSourceData;
    }
    const getLeadsByEnquiryTypeTotal = (_progressReport) => {
        let leadSourceData = {};
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqtype-${rec.value}`]: _.sumBy(_progressReport, `enqtype-${rec.value}`)
                    }
                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.origins.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`origin-${rec.value}`]: _.sumBy(_progressReport, `origin-${rec.value}`)
                    }
                })

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.leadSource &&
            props.dealersettings.client.settings.leadSource.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`leadSource-${rec.value}`]: _.sumBy(_progressReport, `leadSource-${rec.value}`)
                    }
                })

        if (props.report.pipeline
            && props.report.pipeline.length === 1
            && props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.pipelines
            && props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0])) {
            let _leadSource = []
            let _sourceFields = []
            props.dealersettings.client.settings.pipelines.find(a => a.value === props.report.pipeline[0]).stages.forEach(rec => {
                if (rec.value !== 'InboundLead') {
                    leadSourceData = {
                        ...leadSourceData,
                        [`stage-${rec.value}`]: _.sumBy(_progressReport, `stage-${rec.value}`)
                    }
                }
            })
        }

        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.lostReasons.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`lostReason-${rec.value}`]: _.sumBy(_progressReport, `lostReason-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityOutcome &&
            props.dealersettings.client.settings.activityOutcome.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`outcome-${rec.value}`]: _.sumBy(_progressReport, `outcome-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.callStatus.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`callstatus-${rec.value}`]: _.sumBy(_progressReport, `callstatus-${rec.value}`)
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.enquiryStatus.filter(a => a.active === true && a.dynamic === true && a.value !== 'open')
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `enqstatus-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`enqstatus-${rec.value}`]: _stsCount,
                        [`enqstatus-${rec.value}Conv`]: `${(isInbound ? _.sumBy(_progressReport, `convertedLeads`) : _.sumBy(_progressReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(_progressReport, `convertedLeads`) : _.sumBy(_progressReport, `total`))) * _stsCount) : 0}%`,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.financeStatus &&
            props.dealersettings.client.settings.financeStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `financestatus-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`financestatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings?.afterMarketStatus &&
            props.dealersettings.client.settings.afterMarketStatus.filter(a => a.active === true)
                .forEach(rec => {
                    let _stsCount = _.sumBy(_progressReport, `afterMarketStatus-${rec.value}`);
                    leadSourceData = {
                        ...leadSourceData,
                        [`afterMarketStatus-${rec.value}`]: _stsCount,
                    }
                })
        props.dealersettings.client &&
            props.dealersettings.client.settings?.enquiryOptionsDF &&
            props.dealersettings.client.settings.enquiryOptionsDF.filter(a => a.active === true && a?.subList?.length && (a.type === "select" || a.type === "multiselect"))
                .forEach(item => {
                    item.subList.forEach(rec => {
                        let _stsCount = _.sumBy(_progressReport, `${item.type};${item.value};${rec}`);
                        leadSourceData = {
                            ...leadSourceData,
                            [`${item.type};${item.value};${rec}`]: _stsCount,
                        }
                    })
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.activityTypes &&
            props.dealersettings.client.settings.activityTypes.filter(a => a.active === true && Boolean(a.default) === false)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`acttype-${rec.value}`]: _.sumBy(_progressReport, `acttype-${rec.value}`),
                        [`acttypedone-${rec.value}`]: _.sumBy(_progressReport, `acttypedone-${rec.value}`),
                    }
                })
        props.dealersettings &&
            props.dealersettings.client &&
            props.dealersettings.client.settings &&
            props.dealersettings.client.settings.contactTypes &&
            props.dealersettings.client.settings.contactTypes.filter(a => a.active === true)
                .forEach(rec => {
                    leadSourceData = {
                        ...leadSourceData,
                        [`conttype-${rec.value}`]: _.sumBy(_progressReport, `conttype-${rec.value}`)
                    }
                })
        Array.from(Array(16).keys()).map(r => { return r.toString() }).forEach(rec => {
            let _stsCount = _.sumBy(_progressReport, `${rec}`);
            leadSourceData = {
                ...leadSourceData,
                [rec]: _stsCount,
            }
            if (rec === '4' || rec === '15') {
                let __stsCount = _.sumBy(_progressReport, `${rec}plus`);
                leadSourceData = {
                    ...leadSourceData,
                    [`${rec}plus`]: __stsCount,
                }
            }
        })

        if (report?.dynColumns?.length > 0) {
            report.dynColumns.forEach(rec => {
                if (rec.action === 'percentage') {
                    let ptg = _.sumBy(rec.columns.map(r => { return _.sumBy(_progressReport, `${r}`) }));
                    let total = _.sumBy(rec.columns1.map(r => { return _.sumBy(_progressReport, `${r}`) }));
                    let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: ptgValue > 100 ? 100 : ptgValue
                    }
                }
                else {
                    leadSourceData = {
                        ...leadSourceData,
                        [`${rec.id}`]: _.sumBy(rec.columns.map(r => { return _.sumBy(_progressReport, `${r}`) }))
                    }
                }
            })
        }

        return leadSourceData;
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        setSortProgressReport(sortableItems)
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleModelClose = (rec, isNew) => {
        if (rec)
            setReport(rec);
        setCounts(0)
        setShowmodel(false)
        setFilter(null)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false,
            logs: false,
            sheduleReport: false,
            scheduleActivity: false,
            stock: false
        })
        if (isNew) {
            props.setKey(rec.documentID)
        }
    }

    const deleteReport = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`reports/${props.report.documentID}`).delete()
                    .then(snapshot => {
                        toast.notify('Report deleted successfully', {
                            duration: 2000
                        })
                        window.firebase.firestore().doc(`schedular/${props.report.documentID}`).delete();
                        props.setKey('salesprogress')
                    })
                    .catch(error => {
                        console.error(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    });
            }
        })
    }

    const cloneReport = () => {
        setReport({
            ...props.report,
            documentID: window.firebase.firestore().collection(`reports`).doc().id,
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedBy: localStorage.uid,
            owner: localStorage.uid,
            name: `${props.report.name} - Copy`,
            schedule: false
        })
        setShowmodel(true)
    }

    useEffect(() => {
        if (_.isEmpty(chartData))
            return
        try {
            chartData.exporting && chartData.exporting.getImage("png").then(rec => {
                setChartImg(rec)
            })
        }
        catch (error) {
            console.log(error)
        }
    }, [chartData])

    const ReportPdf = () => (
        <Document>
            <Page style={styles.page} size="A4" orientation="lanscape">
                <View style={styles.header}>
                    <View style={styles.headerwrapLeft}>
                        <Text style={{ fontSize: 13 }}>{props.dealersettings.client.name}</Text>
                    </View>
                    <View style={styles.headerwrapRight}>
                        <Text style={{ fontSize: 13 }}>{props.report.name}</Text>
                        <Text style={{ fontSize: 10, color: '#6b717f', paddingTop: 5 }}>{moment().format('DD/MM/YYYY hh:mm A')}</Text>
                    </View>
                </View>
                <View style={styles.reportImage}>
                    {
                        chartImg ? (<Image style={styles.image} src={chartImg} />) : (<></>)
                    }

                </View>
                <View style={styles.footer}>
                    <View style={styles.footerCpy}>
                        <View style={styles.footerLeft}>
                            <Text>Powered by <Link src="http://fusionsd.com.au/" style={{ color: '#666', textDecoration: 'none' }}>Fusion SD</Link></Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
    const DownloadPdf = () => {
        return useMemo(
            () => (
                <PDFDownloadLink document={<ReportPdf />} fileName={`${props.report.name.toLowerCase().replace(/ /g, '-')}.pdf`}>
                    {({ loading }) => ((loading || _.isEmpty(chartImg)) ? (<></>) : (<> <i className="ico icon-files-pdf"></i> Export PDF </>))}
                </PDFDownloadLink>
            ),
            [chartImg],
        )
    }

    const handleShowEnquiryList = (val, rec) => {
        var cols = reportColumns.find(item => item.value === val)
        if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
            setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
            if (cols.type === 'activity') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Activity List')
                setModelShow({
                    ...modelShow,
                    activity: true
                })
            }
            else if (cols.type === 'scheduleActivity') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Activity List')
                setModelShow({
                    ...modelShow,
                    scheduleActivity: true
                })
            }
            else if (cols.type === 'log') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`],
                    columnValue: cols.value
                })
                setPopHeader('Customer logs')
                setModelShow({
                    ...modelShow,
                    logs: true
                })
            }
            else if (cols.type === 'tradein') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Trade In List')
                setModelShow({
                    ...modelShow,
                    tradein: true
                })
            }
            else if (cols.type === 'testdrive' && multiTDperLead) {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Test Drives List')
                setModelShow({
                    ...modelShow,
                    testdrive: true
                })
            }
            else if (cols.value === 'responseTime') {
                setFilter({
                    documentIDs: rec[`arrresponseLeads`]
                })
                setCounts(rec[`arrresponseLeads`] ? rec[`arrresponseLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxresponseTime') {
                setFilter({
                    documentIDs: rec[`arrmaxresponseLeads`]
                })
                setCounts(rec[`arrmaxresponseLeads`] ? rec[`arrmaxresponseLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'minresponseTime') {
                setFilter({
                    documentIDs: rec[`arrminresponseLeads`]
                })
                setCounts(rec[`arrminresponseLeads`] ? rec[`arrminresponseLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'allocatedTime') {
                setFilter({
                    documentIDs: rec[`arrallocatedLeads`]
                })
                setCounts(rec[`arrallocatedLeads`] ? rec[`arrallocatedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxallocatedTime') {
                setFilter({
                    documentIDs: rec[`arrmaxallocatedLeads`]
                })
                setCounts(rec[`arrmaxallocatedLeads`] ? rec[`arrmaxallocatedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'minallocatedTime') {
                setFilter({
                    documentIDs: rec[`arrminallocatedLeads`]
                })
                setCounts(rec[`arrminallocatedLeads`] ? rec[`arrminallocatedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'contactedTime') {
                setFilter({
                    documentIDs: rec[`arrcontactedLeads`]
                })
                setCounts(rec[`arrcontactedLeads`] ? rec[`arrcontactedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'maxcontactedTime') {
                setFilter({
                    documentIDs: rec[`arrmaxcontactedLeads`]
                })
                setCounts(rec[`arrmaxcontactedLeads`] ? rec[`arrmaxcontactedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.value === 'mincontactedTime') {
                setFilter({
                    documentIDs: rec[`arrmincontactedLeads`]
                })
                setCounts(rec[`arrmincontactedLeads`] ? rec[`arrmincontactedLeads`].length : 0)
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }
            else if (cols.columnType === 'service') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Quotes')
                setModelShow({
                    ...modelShow,
                    quotes: true
                })
            }
            else if (cols.type === 'stock') {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Stock List')
                setModelShow({
                    ...modelShow,
                    stock: true
                })
            }
            else {
                setFilter({
                    documentIDs: rec[`arr${cols.value}`]
                })
                setPopHeader('Enquiry List')
                setModelShow({
                    ...modelShow,
                    enquiry: true
                })
            }

        }
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const isValidFilters = (isScroll) => {
        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';


        let _filterLists = Object.assign([], report.objFilters);

        !_.isEmpty(_filterLists) && _filterLists.forEach(_data => {
            if (_.isEmpty(_data.name)) {
                formIsValid = false;
                errors['name_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;

            }
            else if (_.isEmpty(_data.value)) {
                formIsValid = false;
                errors['value_' + _data.id] = errorClass;
                errors['schd_' + _data.id] = errorClass;
            }

        });

        setErrorFilter(errors);
        console.log('isValidFilters,', errors)
        return formIsValid;
    }

    const addFilter = () => {
        let _lists = Object.assign([], report.objFilters);
        let _isValidFilters = isValidFilters();
        if (!_isValidFilters)
            return;

        _lists.push({ name: null, value: null, id: uuid() })
        setReport({
            ...report,
            ['objFilters']: _lists
        });

    }

    const updateFilter = (_data) => {
        if (!_.isEmpty(_data)) {
            let _reLists = Object.assign([], report.objFilters);

            _reLists.filter(function (obj) {
                //Update old data
                if (obj.id === _data.id) {
                    for (let [key, value] of Object.entries(_data)) {
                        obj[key] = value;
                    }
                }
            });
            // console.log('updateRule', _data, _reLists)
            if (report.columnType === 'stock' && _data.name === 'date') {
                if (_data.type === 'overall') {
                    _reLists = _reLists.filter(a => a.name !== 'dateType')
                } else if (!_reLists.some(a => a.name === 'dateType')) {
                    _reLists.push({
                        id: uuid(),
                        name: "dateType",
                        value: {
                            dateType: "addedDate"
                        }
                    })
                }
            }
            setReport({
                ...report,
                ['objFilters']: _reLists
            });
        }
    }

    const deleteFilter = (_id) => {
        if (!_.isEmpty(_id)) {
            const _reLists = Object.assign([], report.objFilters);
            setReport({
                ...report,
                ['objFilters']: _reLists.filter(m => m.id !== _id)
            });
        }
    }

    const saveFilter = () => {
        setLoader(true)
        window.firebase.firestore().doc(`reports/${report.documentID}`).set(report, { merge: true })
            .then(snapshot => {
                setTimeout(() => {
                    setRefreshData(true)
                }, 1000);
                // toast.notify('Report updated successfully', {
                //     duration: 2000
                // });
            })
            .catch(error => {
                setLoader(false)
                console.error(error);
                Swal.fire('Something went wrong', '', 'error')
            });
    }

    const toggleFilter = (e, show) => {
        e.preventDefault();
        setShow(show);
        const reportWrapper = window.jQuery(`#${report.documentID}-report-collapse`);
        if (reportWrapper) {
            if (show)
                reportWrapper.fadeIn();
            else
                reportWrapper.fadeOut();
        }
    }

    const getFilterDates = () => {
        let dates = report?.objFilters?.filter(a => a.date)?.[0]?.value;
        let datesObj = {};
        if (dates?.startDate) {
            datesObj.startDate = moment.unix(dates?.startDate?.seconds).format('YYYY-MM-DD');
            datesObj.endDate = moment.unix(dates?.endDate?.seconds).format('YYYY-MM-DD');
        }
        return datesObj;
    }

    return (<>
        <div className="report-page-head">
            <h2>{props.report.name}  </h2>


            <Dropdown className='enquiry-view-more float-right mt-0'>
                <Dropdown.Toggle as={CustomToggle} className='common-button'>
                    <i className='ico icon-more'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    as={CustomMenu}
                    ChildClass="more-dropdown dropsub-fix"
                    xplacement="bottom-end"
                >
                    {
                        !Boolean(props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly)
                            && (props.report.addedBy === localStorage.uid || props.report.ownerIDs?.some(a => a === localStorage.uid) || props.dealersettings.roleName === 'Administrator') ? (
                            <Dropdown.Item eventKey="1" onClick={(e) => { setReport(props.report); setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                        ) : (<></>)
                    }
                    {
                        props.report.reportType === 'table' ? (<Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                            <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`${props.report.name.toLowerCase().replace(/ /g, '-')}`}>
                                <ExcelSheet dataSet={csvData} name={`${moment.unix(dateRange.startDate.seconds).format('DD MMM YYYY')} - ${moment.unix(dateRange.endDate.seconds).format('DD MMM YYYY')}`} />
                                {
                                    Object.keys(sheetData).map((rec, index) => {
                                        return <ExcelSheet key={index} dataSet={sheetData[rec]} name={`${_.find(reportColumns, { value: rec }) ? _.find(reportColumns, { value: rec }).name : rec}`} />
                                    })
                                }
                            </ExcelFile>
                        </Dropdown.Item>) : (<Dropdown.Item eventKey="3" as={'div'} bsPrefix={'li-'}>
                            <DownloadPdf />
                        </Dropdown.Item>)
                    }
                    {
                        !Boolean(props?.dealersettings?.rolePermissions?.permissions?.accessToReportsOnly)
                            && (props.report.addedBy === localStorage.uid || props.report.ownerIDs?.some(a => a === localStorage.uid) || props.dealersettings.roleName === 'Administrator') ? (<>
                                <Dropdown.Item eventKey="3" onClick={(e) => { deleteReport() }}><i className="ico icon-delete"></i> Delete </Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={(e) => {
                                    setModelShow({
                                        ...modelShow,
                                        sheduleReport: true
                                    })
                                }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={(e) => { cloneReport() }}><i className="ico icon-rearrange"></i> Clone Report </Dropdown.Item>
                            </>) : (<></>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            {
                !_.isEmpty(schedueReport) ? (
                    <div className="reportsuccess-item-btn mr-2 float-right cursor-pointer" onClick={(e) => {
                        if (schedueReport?.multiSchedule?.length > 0) {
                            setPopover({ show: true, target: e.target })
                        }
                    }}>
                        <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                        <div className="reportsuccess-inner-text">
                            <div className="reportsuccess-inner-text-top"> Scheduled </div>
                            {
                                schedueReport?.multiSchedule?.length > 0 ? (
                                    <div className="reportsuccess-inner-text-sub">{`${schedueReport.multiSchedule.length + 1} Scheduler is Active`}</div>
                                ) : (
                                    <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                )
                            }
                        </div>
                        <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                    </div>
                ) : (<></>)
            }

        </div>
        <div className="divider-line"></div>
        <div className="report-content-head pl-3 pr-3">
            <div className="row">
                {/* <div className="col-md-9">
                    <div className="row">
                        <div className="form-group col-md-4 pr-0 daterange-fix">
                            <AntDateRangePicker
                                value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                id={"dashboard-date-range"}
                                name={`${props.report.documentID}-date`}
                                format='DD/MM/YYYY'
                                onChange={(e) => { handleDateRangeChange(e, `${props.report.documentID}-date`) }}
                                placeholder='DD/MM/YYYY'
                                onCalendarChange={(value) => {
                                    if (value) {
                                        const [start, end] = value;
                                        setDates([start, end]);
                                    }
                                    else {
                                        setDates([])
                                    }
                                }}
                            //disabledDate={disabledDate}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-primary" onClick={() => { setRefreshData(true) }}> <i className="ico icon-search"></i> Search</button>
                        </div>
                    </div>
                </div> */}
                <div className="rule-filter-head pl-3 pr-3">
                    {
                        !_.isEmpty(report.objFilters)
                            ?
                            <div className="float-left rule-filter-info cursor-pointer" onClick={(e) => { toggleFilter(e, !filterShow) }}> <i className="ico icon-filter"></i>
                                {Object.keys(report.objFilters).length} {Object.keys(report.objFilters).length > 1 ? ' filters' : ' filter'} applied
                            </div>
                            :
                            <></>
                    }
                    {
                        (report.visibility !== 'sharedView' || props.report.addedBy === localStorage.uid || props.report.ownerIDs?.some(a => a === localStorage.uid) || props.dealersettings.roleName === 'Administrator') ? (<div className="float-right rule-add-btn">
                            <div className="float-right ml-2 filter-collapse-btn">
                                <div className="filter-collapse-report"> <a href="#" onClick={(e) => { toggleFilter(e, !filterShow) }} className={`mini-button ${!filterShow ? 'collapsed' : ''}`}><i className="arrow-collapse"></i></a> </div>
                            </div>
                            <button type="button" className="btn btn-primary float-right ml-2"
                                onClick={(e) => { toggleFilter(e, true); addFilter(); }}>
                                <i className="ico icon-add mr-2"></i> Add Filter
                            </button>
                        </div>) : (<></>)
                    }
                </div>
                <div className={`rules-group-wrapper query-builder`} id={`${report.documentID}-report-collapse`}>
                    <div className="rules-group-body">
                        <div className="rules-list">
                            {
                                !_.isEmpty(report.objFilters) && report.objFilters.map((_rec, index) => {
                                    return <CustomFilters
                                        {...props}
                                        key={index}
                                        docID={_rec ? _rec.id : ''}
                                        fieldData={_rec}
                                        errors={errorFilters}
                                        filterFields={report.objFilters}
                                        updateFilter={updateFilter}
                                        deleteFilter={deleteFilter}
                                        columns={report.columns}
                                        report={report}
                                        filterOptions={[...filterOptions, ...enqDynamicOptions]}
                                        reportFilters={[...reportFilters, ...enqDynamicOptions]}
                                        labels={labels}
                                    ></CustomFilters>
                                })
                            }

                        </div>
                    </div>
                    <div className="filter-collapse-report-footer">
                        <button type="button" className="btn btn-green float-left ml-3" onClick={() => saveFilter()}>Apply</button>
                    </div>
                </div>
                {/* {
                    !_.isEmpty(props.report.filters) ? (
                        <div className="col-md-3">
                            <div className="float-right">
                                <Dropdown ref={node} alignRight show={filterShow}>
                                    <div className="advanced-filter-btn mr-2 float-right">
                                        <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                            setShow(!filterShow)
                                        }}>
                                            {filterCounts()}
                                            <i className="ico icon-filter"></i>Filters
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu as={CustomFilter}
                                        id="table-reports-cols-settings-pipeline"
                                        className="dropdown-menu dropdown-menu-left"
                                        ChildClass="form-style quick-filter-wrap"
                                        xplacement="bottom-end"
                                    >
                                        <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                            <div className={`report-filter-wraper form-style ${props.report.filters.length > 5 ? 'report-filter-height-fix' : ''}`}>
                                                {
                                                    props.report.filters.map((rec, i) => {
                                                        return <DynamicFilters
                                                            key={i}
                                                            {...props}
                                                            filter={rec}
                                                            objFilter={objFilter}
                                                            columns={props.report.columns}
                                                            setObjFilter={(e, name) => {
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: e ? e.value : ''
                                                                })
                                                            }}
                                                            setMultibjFilter={(selectedOptions, name) => {
                                                                let value = [];
                                                                !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                    value.push(data.value)
                                                                })
                                                                setObjFilter({
                                                                    ...objFilter,
                                                                    [name]: value
                                                                });
                                                            }}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="quick-filter-footer">
                                            <button type="button" className="btn btn-default float-left" onClick={() => {
                                                // setDateRange({
                                                //     startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                //     endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                                // })
                                                // setOldDateRange({
                                                //     startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                //     endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                                // })
                                                setRefreshData(true)
                                                setObjFilter(defaultFilters)
                                                setShow(false)
                                                setShowCount(false)
                                            }}>Clear</button>
                                            <button type="button" className="btn btn-primary float-right" onClick={() => {
                                                setRefreshData(true)
                                                setShow(false)
                                                setShowCount(true)
                                            }}>Apply</button>

                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    ) : (<></>)
                } */}
            </div>
        </div>
        {
            loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                <div className="common-table">
                    <div className="text-center p-5">
                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                        <div className="no-data-txt mt-2"> No records found</div>
                    </div>
                </div>
            ) : props.report.reportType === 'table' ? (<div className="common-table" style={{ overflowX: 'auto' }}>
                {
                    props.report.columnType === 'persistency' ? (

                        <div className="table-progressive-wrapper mb-2">
                            <div className="table-progressive-graph">
                                <div className="table-progressive-graph-image">
                                    <div id={`progressive-${props.report.documentID}`} style={{ width: "60px", height: '55px', marginLeft: '5px', marginTop: '2px' }}></div>
                                </div>
                                <div className="table-progressive-graph-title">Progressive Sales <br /> ({_.sumBy(progressReport, '15plus')}/{_.sumBy(progressReport, '4plus')}) x 100</div>

                            </div>
                            <div className="table-progressive-table">

                                <div className="common-table">
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="head-light" width="25%">Sales at first contact</th>
                                                <th scope="col" className="head-light" width="25%">Sales within 4 contacts</th>
                                                <th scope="col" className="head-light" width="25%">Sales after 5 contacts</th>
                                                <th scope="col" className="head-light table-sub-total" width="25%">Total</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{_.sumBy(progressReport, '0')}</td>
                                                <td>{_.sumBy(progressReport, '4plus')}</td>
                                                <td>{_.sumBy(progressReport, '15plus')}</td>
                                                <td className='table-sub-total'>{_.sumBy(progressReport, 'won')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)
                }
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <td className="head-light" width="10%">
                                <div className="sort-cell cursor-pointer" onClick={(e) => {
                                    e.preventDefault()
                                    requestSort('name')
                                }}>
                                    <div className="table-sort"><a href="#">
                                        {
                                            sortConfig && sortConfig.key === 'name' ?
                                                (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                        }
                                    </a>
                                    </div>
                                    {CommonHelper.getCustomValue(dynReportGroupBy, props.report.groupBy, 'tableHeader', 'value')}
                                </div>
                            </td>
                            {
                                props.report.columns.map((rec, index) => {
                                    return <Fragment key={index}><td className={`head-light text-center ${reportColumns.find(item => item.value === rec) && reportColumns.find(item => item.value === rec).cls ? reportColumns.find(item => item.value === rec).cls : ''}`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {
                                                reportColumns.find(item => item.value === rec) ?
                                                    (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                    (<span className="pipeline-inactive-span">(In Active)</span>)
                                            }
                                            {
                                                rec.includes('enqtype-') ? (
                                                    <span className="th-subhead">(Enquiry Type)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('origin-') ? (
                                                    <span className="th-subhead">(Origin)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('leadSource-') ? (
                                                    <span className="th-subhead">(Lead Source)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('lostReason-') ? (
                                                    <span className="th-subhead">(Lost Reason)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('outcome-') ? (
                                                    <span className="th-subhead">(Activity Outcome)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('callstatus-') ? (
                                                    <span className="th-subhead">(Call Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('enqstatus-') ? (
                                                    <span className="th-subhead">(Enquiry Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('financestatus-') ? (
                                                    <span className="th-subhead">(Finance Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('afterMarketStatus-') ? (
                                                    <span className="th-subhead">(After Market Status)</span>
                                                ) : (<></>)
                                            }
                                            {
                                                rec.includes('select;') ? (
                                                    <span className="th-subhead">({reportColumns.find(item => item.value === rec)?.name || 'Dynamic Field'})</span>
                                                ) : (<></>)
                                            }
                                        </div>
                                    </td>
                                        {
                                            props?.report?.dynColumns?.filter(a => a.moveAfter === rec)?.length ? props.report.dynColumns.filter(a => a.moveAfter === rec).map((dynCol) => {
                                                return <td key={dynCol.id} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                        e.preventDefault()
                                                        requestSort(dynCol.id)
                                                    }}>
                                                        <div className="table-sort"><a href="#">
                                                            {
                                                                sortConfig && sortConfig.key === dynCol.id ?
                                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                            }
                                                        </a>
                                                        </div>
                                                        {dynCol.name}
                                                    </div>
                                                </td>
                                            }) : (<></>)
                                        }
                                    </Fragment>
                                })
                            }
                            {
                                props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((rec, index) => {
                                    return <td key={index} className={`head-light text-center`} width={`${(90 / (props.report.columns.length + (props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.length : 0))).toFixed(2)}%`}>
                                        <div className="sort-cell cursor-pointer" onClick={(e) => {
                                            e.preventDefault()
                                            requestSort(rec.id)
                                        }}>
                                            <div className="table-sort"><a href="#">
                                                {
                                                    sortConfig && sortConfig.key === rec.id ?
                                                        (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                }
                                            </a>
                                            </div>
                                            {rec.name}
                                        </div>
                                    </td>
                                }) : (<></>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedItems.length > 0 ?
                                sortedItems.map((rec, index) => {
                                    return <tr key={index}>
                                        <td>
                                            {
                                                (props.report.groupBy === 'user' || props.report.groupBy === 'financeBM' || props.report.groupBy === 'afterMarketBM' || props.report.groupBy === 'enqAddedBy') ? (<div className="table-user-list">
                                                    <a href="#">
                                                        <div className="table-user-avatar">
                                                            <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                        {rec.displayName}
                                                    </a>
                                                </div>) : (
                                                    <div className="dealership-tbl">
                                                        {rec.displayName}
                                                    </div>
                                                )
                                            }

                                        </td>
                                        {
                                            props.report.columns.map((val, index) => {
                                                return <Fragment key={index}>
                                                    <td className={`text-center cursor-pointer ${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).cls ? reportColumns.find(item => item.value === val).cls : ''}`}
                                                        onClick={() => {
                                                            var cols = reportColumns.find(item => item.value === val)
                                                            if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
                                                                setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
                                                                if (cols.type === 'activity') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Activity List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        activity: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'scheduleActivity') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Activity List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        scheduleActivity: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'log') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`],
                                                                        columnValue: cols.value
                                                                    })
                                                                    setPopHeader('Customer Logs')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        logs: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'tradein') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Trade In List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        tradein: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'testdrive' && multiTDperLead) {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Test Drive List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        testdrive: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'responseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrresponseLeads`] ? rec[`arrresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'maxresponseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrmaxresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrmaxresponseLeads`] ? rec[`arrmaxresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'minresponseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrminresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrminresponseLeads`] ? rec[`arrminresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'allocatedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrallocatedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrallocatedLeads`] ? rec[`arrallocatedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'maxallocatedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrmaxallocatedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrmaxallocatedLeads`] ? rec[`arrmaxallocatedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'minallocatedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrminallocatedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrminallocatedLeads`] ? rec[`arrminallocatedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'contactedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrcontactedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrcontactedLeads`] ? rec[`arrcontactedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'maxcontactedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrmaxcontactedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrmaxcontactedLeads`] ? rec[`arrmaxcontactedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'mincontactedTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrmincontactedLeads`]
                                                                    })
                                                                    setCounts(rec[`arrmincontactedLeads`] ? rec[`arrmincontactedLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.columnType === 'service') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Quotes')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        quotes: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'stock') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Stock List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        stock: true
                                                                    })
                                                                }
                                                                else {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }

                                                            }
                                                        }}>
                                                        {
                                                            reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).currency ? (
                                                                <NumberFormat
                                                                    thousandSeparator={true}
                                                                    prefix={currencySymbol}
                                                                    displayType={'text'}
                                                                    value={rec[val]}
                                                                />
                                                            )
                                                                : `${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`
                                                        }
                                                    </td>
                                                    {
                                                        props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                            return <td key={dynCol.id} className={`text-center cursor-pointer`}
                                                                onClick={() => {
                                                                    if (rec[`arr${dynCol.id}`] && rec[`arr${dynCol.id}`].length > 0) {
                                                                        setCounts(rec[`arr${dynCol.id}`] ? rec[`arr${dynCol.id}`].length : 0)
                                                                        setFilter({
                                                                            documentIDs: rec[`arr${dynCol.id}`]
                                                                        })
                                                                        setPopHeader('Enquiry List')
                                                                        setModelShow({
                                                                            ...modelShow,
                                                                            enquiry: true
                                                                        })

                                                                    }
                                                                }}>
                                                                {rec[dynCol.id]}{dynCol.action === 'percentage' ? '%' : ''}
                                                            </td>
                                                        }) : (<></>)
                                                    }
                                                </Fragment>
                                            })
                                        }
                                        {
                                            props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                                return <td key={index} className={`text-center cursor-pointer`}
                                                    onClick={() => {
                                                        if (rec[`arr${val.id}`] && rec[`arr${val.id}`].length > 0) {
                                                            setCounts(rec[`arr${val.id}`] ? rec[`arr${val.id}`].length : 0)
                                                            setFilter({
                                                                documentIDs: rec[`arr${val.id}`]
                                                            })
                                                            setPopHeader('Enquiry List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                enquiry: true
                                                            })

                                                        }
                                                    }}>
                                                    {rec[val.id]}{val.action === 'percentage' ? '%' : ''}
                                                </td>
                                            }) : (<></>)
                                        }
                                    </tr>
                                }) : (
                                    <tr>
                                        <td colSpan={props.report.columns.length + 1}>
                                            <div className="spinner-loader h-100 p-5">
                                                <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                    <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                    <div className="no-data-txt mt-2"> No data</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                    {
                        sortedItems.length > 0 ?
                            (<tfoot>
                                <tr>
                                    <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                    {
                                        props.report.columns.map((val, index) => {
                                            return <Fragment key={index}><td className="text-center table-sub-total cursor-pointer"
                                                onClick={() => {
                                                    var cols = reportColumns.find(item => item.value === val)
                                                    if (cols && cols.percentage !== true) {
                                                        var IDs = [];
                                                        if (cols.value === 'responseTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrresponseLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'maxresponseTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxresponseLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'minresponseTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrminresponseLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'allocatedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrallocatedLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'maxallocatedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxallocatedLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'minallocatedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrminallocatedLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'contactedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrcontactedLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'maxcontactedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmaxcontactedLeads`]]
                                                            })
                                                        }
                                                        else if (cols.value === 'mincontactedTime') {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arrmincontactedLeads`]]
                                                            })
                                                        }
                                                        else {
                                                            progressReport.forEach(rec => {
                                                                IDs = [...IDs, ...rec[`arr${cols.value}`]]
                                                            })
                                                        }
                                                        setCounts(IDs.length)
                                                        if (cols.type === 'activity') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Activity List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                activity: true
                                                            })
                                                        }
                                                        else if (cols.type === 'scheduleActivity') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Activity List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                scheduleActivity: true
                                                            })
                                                        }
                                                        else if (cols.type === 'log') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs),
                                                                columnValue: cols.value
                                                            })
                                                            setPopHeader('Customer Logs')
                                                            setModelShow({
                                                                ...modelShow,
                                                                logs: true
                                                            })
                                                        }
                                                        else if (cols.type === 'tradein') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Trade In List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                tradein: true
                                                            })
                                                        }
                                                        else if (cols.type === 'testdrive' && multiTDperLead) {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Test Drives List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                testdrive: true
                                                            })
                                                        }
                                                        else if (cols.columnType === 'service') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Quotes')
                                                            setModelShow({
                                                                ...modelShow,
                                                                quotes: true
                                                            })
                                                        }
                                                        else if (cols.type === 'stock') {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Stock List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                stock: true
                                                            })
                                                        }
                                                        else {
                                                            setFilter({
                                                                documentIDs: _.uniq(IDs)
                                                            })
                                                            setPopHeader('Enquiry List')
                                                            setModelShow({
                                                                ...modelShow,
                                                                enquiry: true
                                                            })
                                                        }

                                                    }
                                                }}>
                                                {
                                                    (val === 'walkintestdrive' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                        val === 'walkinsold' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0) :
                                                            val === 'appointmenttestdrive' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0) :
                                                                val === 'testdrivetotestdrive' ? (_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0) :
                                                                    val === 'appointmentsold' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "apptsold")) : 0) :
                                                                        val === 'testdriveConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                            val === 'scheduledConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) : 0) :
                                                                                val === 'showConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) : 0) :
                                                                                    val === 'noshowConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) : 0) :
                                                                                        val === 'convertedConv' ? (_.sumBy(progressReport, "totalInbound") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) : 0) :
                                                                                            val === 'newLeadsConv' ? (_.sumBy(progressReport, "totalnewLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) : 0) :
                                                                                                val === 'exisitngLeadsConv' ? (_.sumBy(progressReport, "totalexisitngLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) : 0) :
                                                                                                    val === 'totalConv' ? (_.sumBy(progressReport, "totalLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) : 0) :
                                                                                                        val === 'tradeinwonConv' ? (_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0) :
                                                                                                            val === 'wonConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) : 0) :
                                                                                                                val === 'responseTime' ? (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                    val === 'maxresponseTime' ? (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                        val === 'minresponseTime' ? (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                            val === 'allocatedTime' ? (_.sumBy(progressReport, `totalallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                val === 'maxallocatedTime' ? (_.sumBy(progressReport, `totalmaxallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                    val === 'minallocatedTime' ? (_.sumBy(progressReport, `totalminallocatedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminallocatedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                        val === 'contactedTime' ? (_.sumBy(progressReport, `totalcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalcontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                            val === 'maxcontactedTime' ? (_.sumBy(progressReport, `totalmaxcontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxcontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                                val === 'mincontactedTime' ? (_.sumBy(progressReport, `totalmincontactedTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmincontactedTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                                    val === 'wonAmount' || val === 'quoteAmount' ? <NumberFormat
                                                                                                                                                        thousandSeparator={true}
                                                                                                                                                        prefix={currencySymbol}
                                                                                                                                                        displayType={'text'}
                                                                                                                                                        value={Math.round((_.sumBy(progressReport, val) + Number.EPSILON) * 100) / 100}
                                                                                                                                                    /> : reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                                        reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                                            return col.value === val ?
                                                                                                                                                                (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`))) * _.sumBy(progressReport, `${val.replace('Conv', '')}`)) : 0
                                                                                                                                                                : 0
                                                                                                                                                        }) : _.sumBy(progressReport, val))
                                                }
                                                {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                            </td>
                                                {
                                                    props?.report?.dynColumns?.filter(a => a.moveAfter === val)?.length ? props.report.dynColumns.filter(a => a.moveAfter === val).map((dynCol) => {
                                                        let ptg = dynCol.columns ? _.sumBy(dynCol.columns.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                        let total = dynCol.columns1 ? _.sumBy(dynCol.columns1.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                                        let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                                        return <td key={dynCol.id} className="text-center table-sub-total cursor-pointer"
                                                            onClick={() => {
                                                                var IDs = [];
                                                                progressReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arr${dynCol.id}`]]
                                                                })
                                                                if (IDs.length > 0) {
                                                                    setCounts(IDs.length)
                                                                    setFilter({
                                                                        documentIDs: _.uniq(IDs)
                                                                    })
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }

                                                            }}>
                                                            {
                                                                dynCol.action === 'percentage' ? (
                                                                    <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                                ) : (<>{_.sumBy(progressReport, dynCol.id)}</>)
                                                            }

                                                        </td>
                                                    }) : (<></>)
                                                }
                                            </Fragment>
                                        })
                                    }
                                    {
                                        props?.report?.dynColumns?.length > 0 ? props.report.dynColumns.filter(a => _.isEmpty(a.moveAfter)).map((val, index) => {
                                            let ptg = val.columns ? _.sumBy(val.columns.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                            let total = val.columns1 ? _.sumBy(val.columns1.map(r => { return _.sumBy(progressReport, `${r}`) })) : 0;
                                            let ptgValue = total > 0 ? Math.round((100 / total) * ptg) : 0
                                            return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                onClick={() => {
                                                    var IDs = [];
                                                    progressReport.forEach(rec => {
                                                        IDs = [...IDs, ...rec[`arr${val.id}`]]
                                                    })
                                                    if (IDs.length > 0) {
                                                        setCounts(IDs.length)
                                                        setFilter({
                                                            documentIDs: _.uniq(IDs)
                                                        })
                                                        setPopHeader('Enquiry List')
                                                        setModelShow({
                                                            ...modelShow,
                                                            enquiry: true
                                                        })
                                                    }

                                                }}>
                                                {
                                                    val.action === 'percentage' ? (
                                                        <>{ptgValue > 100 ? 100 : ptgValue}%</>
                                                    ) : (<>{_.sumBy(progressReport, val.id)}</>)
                                                }

                                            </td>
                                        }) : (<></>)
                                    }
                                </tr>
                            </tfoot>
                            ) : (<></>)
                    }
                </table>
            </div >
            ) : props.report.reportType === 'column' ?
                (
                    <div className="text-center">
                        <BarChart
                            id={`view-report-bar-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            columns={props.report.columns ? _.isArray(props.report.columns) ? props.report.columns : [props.report.columns] : []}
                            dynamicColumns={reportColumns}
                            xCategory={'displayName'}
                            setChartData={(data) => { setChartData(data) }}
                            calculationType={props.report.calculationType}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />
                    </div>
                ) : props.report.reportType === 'pie' ? (<>
                    {
                        progressReport.some(item => item[props.report.columns] > 0) ? (<PieChart
                            id={`view-report-pie-chart-${props.report.documentID}`}
                            height={`${windowSize.tblHeight}px`}
                            data={progressReport}
                            dynamicReport={true}
                            columns={_.isArray(props.report.columns) ? props.report.columns[0] : props.report.columns}
                            setChartData={(data) => { setChartData(data) }}
                            handleShowEnquiryList={(val, rec) => {
                                handleShowEnquiryList(val, rec)
                            }}
                        />) : (
                            <NoDataPieChart
                                id={'no-data-pie-chart'}
                                height={`${windowSize.tblHeight}px`}
                            />)
                    }
                </>) : props.report.reportType === 'target' ? (
                    <LineChart
                        id={`view-report-line-chart-${props.report.documentID}`}
                        height={`${windowSize.tblHeight}px`}
                        data={progressReport.map(rec => { return { ...rec, 'name': rec.displayName } })}
                        dynamicColumns={reportColumns}
                        xCategory={'displayName'}
                        //columns={props.report.target > 0 ? [props.report.columns, 'target'] : [props.report.columns]}
                        columns={props.report.target > 0 ? (_.isArray(props.report.columns) ? [...props.report.columns, 'target'] : [props.report.columns, 'target']) : (_.isArray(props.report.columns) ? props.report.columns : [props.report.columns])}
                        setChartData={(data) => { setChartData(data) }}
                        setChartImg={(img) => { setChartImg(img) }}
                        calculationType={props.report.calculationType}
                        handleShowEnquiryList={(val, rec) => {
                            handleShowEnquiryList(val, rec)
                        }}
                    />
                ) : (<></>)
        }
        <PopUpModal show={showModel}>
            <AddReport
                {...props}
                show={showModel}
                title={'Edit Report'}
                handleClose={handleModelClose}
                report={report}
            />
        </PopUpModal>
        <PopUpModal show={modelShow.enquiry}>
            <EnquiryList
                {...props}
                show={modelShow.enquiry}
                handleClose={handleModelClose}
                title={popHeader}
                history={props.history}
                clientUsers={props.clientUsers}
                groupUsers={props.groupUsers}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></EnquiryList>
        </PopUpModal>
        <PopUpModal show={modelShow.testdrive}>
            <TestDriveList
                {...props}
                show={modelShow.testdrive}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></TestDriveList>
        </PopUpModal>
        <PopUpModal show={modelShow.activity}>
            <ActivityList
                {...props}
                show={modelShow.activity}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.scheduleActivity}>
            <ActivityList
                {...props}
                show={modelShow.scheduleActivity}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></ActivityList>
        </PopUpModal>
        <PopUpModal show={modelShow.tradein}>
            <TradeInList
                {...props}
                show={modelShow.tradein}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></TradeInList>
        </PopUpModal>
        <PopUpModal show={modelShow.logs}>
            <LogListModel
                {...props}
                show={modelShow.logs}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></LogListModel>
        </PopUpModal>
        <PopUpModal show={modelShow.quotes}>
            <QuoteList
                {...props}
                show={modelShow.quotes}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></QuoteList>
        </PopUpModal>
        <PopUpModal show={modelShow.stock}>
            <StockList
                {...props}
                show={modelShow.stock}
                handleClose={handleModelClose}
                title={popHeader}
                filter={filter}
                isFromDashboard={true}
                counts={counts}
            ></StockList>
        </PopUpModal>
        <PopUpModal show={modelShow.sheduleReport}>
            <ScheduleReport
                {...props}
                show={modelShow.sheduleReport}
                handleClose={handleModelClose}
                title={popHeader}
                {...getFilterDates()}
            ></ScheduleReport>
        </PopUpModal>
        <PopoverPanel
            showpopover={popover.show}
            targetpopover={popover.target}
            title='Scheduler'
            position='left'
            id='popover-schdule-report'
            className='popover-schdule-report'
            closepopover={() => {
                setPopover({ show: false })
            }}>
            {
                multiSchedule.map((rec, index) => {
                    return <div key={index} className="reportsuccess-multi-btn mb-2">
                        <div className="reportsuccess-multi-logo">
                            <i className="ico icon-files-document"></i>
                        </div>
                        <div className="reportsuccess-multi-inner-text">
                            <div className="reportsuccess-multi-inner-text-top"> Scheduled </div>
                            <div className="reportsuccess-multi-inner-text-sub"> {`${rec.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(rec.cron)}`}</div>
                        </div>
                    </div>
                })
            }
        </PopoverPanel>
    </>)
}

export default ViewDynamicReport;