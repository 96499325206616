/** LIBRARIES */
import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import {
    DropDownMenu, InputText
} from '../../../components';
import CommonHelper from '../../../services/common';
import { default as _images } from '../../../images';

const emailList = [
    { active: true, value: 'Secondary', label: 'Secondary' },
    { active: true, value: 'Other', label: 'Other' },
]

export const objCustomer = {
    fullName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    phoneCode: '',
    phone: '',
    email: '',
    emails: [],
    sendSMS: false,
}

export default class CustomerApproval extends Component {
    constructor(props) {
        super(props);

        this.isLoading = false;
        this.mandatoryFields = [];
        this.messageMediaEnabled = false;
        this.ringcentralEnabled = false;
        this.smsHubEnabled = false;
        this.podiumEnabled = false;
        this.justcallEnabled = false;
        this.smsEnabled = false;
        this.state = {
            fields: Object.assign({}, objCustomer),
            errors: {},
            showParts: false,
            searchText: '',
        }
    }

    //#region PAGE LOAD
    loadRecomm(props) {
        const { show, contact, dealersettings } = props;
        const fields = Object.assign({}, objCustomer);

        const _messagemedia = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "messagemedia")[0]);

        const _smshub = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "smshub")[0]);

        const _podium = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "podium")[0]);

        const _ringcentral = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "ringcentral")[0]);

        const _justcall = Object.assign({}, dealersettings?.client?.integrations &&
            dealersettings.client.integrations.filter(e => e.type === "justcall")[0]);

        this.ringcentralEnabled = _ringcentral && _ringcentral.active && _ringcentral.enabled ? true : false;
        this.messageMediaEnabled = _messagemedia && _messagemedia.active && _messagemedia.enabled ? true : false;
        this.smsHubEnabled = _smshub && _smshub.active && _smshub.enabled ? true : false;
        this.podiumEnabled = _podium && _podium.active && _podium.enabled ? true : false;
        this.justcallEnabled = _justcall && _justcall.active && _justcall.enabled ? true : false;

        this.smsEnabled = (this.messageMediaEnabled || this.justcallEnabled) ? true : false;
        if (show === true && contact) {
            const objData = Object.assign({}, contact);

            for (let [key, value] of Object.entries(objData)) {
                if (fields.hasOwnProperty(key))
                    fields[key] = value;
            }
            fields.fullName = CommonHelper.getFullName(null, objData)

            if (fields.phone && this.smsEnabled) fields['sendSMS'] = true;
            this.setState({ fields: fields });
        }
        else if (show === true) {
            this.setState({ fields: fields });
        }
    }

    componentDidMount() {
        this.loadRecomm(this.props);
    }
    //#endregion

    //#region CHANGE EVENT

    handleEmailChange = idx => evt => {
        let state = Object.assign({}, this.state.fields);
        const newEmail = !_.isEmpty(state.emails) && state.emails.map((data, sidx) => {
            if (idx !== sidx) return data;
            return { ...data, value: evt.target.value };
        });
        state['emails'] = newEmail
        this.setState({ fields: state });
    };
    handleAddEmail = (type) => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.concat([{ value: "", name: type }])
        this.setState({ fields: state });
    };
    handleRemoveEmail = idx => {
        let state = Object.assign({}, this.state.fields);
        state['emails'] = state.emails.filter((s, sidx) => idx !== sidx)
        this.setState({ fields: state });
    };

    onHandleTelephoneChange = e => {
        const { name, value } = e.target;
        let state = Object.assign({}, this.state.fields);
        var regexp = /^[0-9+ \b]+$/;
        let telephone = _.trim(value);

        // if value is not blank, then test the regex
        if (telephone === '' || regexp.test(telephone)) {
            state[name] = _.trim(value);
            state['sendSMS'] = value ? true : false;
            this.setState({ fields: state });
        }
    };

    handleCheckChange = (e) => {
        //console.log('handleCheckChange', e, name, e.target.checked, e.target.name);
        const { name, checked } = e.target;
        let state = Object.assign({}, this.state.fields);
        state[name] = checked;
        this.setState({ fields: state });
    }

    errorChange = (key, isScroll) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        let _mandatoryFields = this.mandatoryFields;

        if (errors !== {}) {
            if ((!fields[key] || (_.isObject(fields[key]) && _.isEmpty(fields[key]))) && _mandatoryFields && _mandatoryFields.indexOf(key) >= 0) {
                errors[key] = errorClass;
            }
            else {
                if (key === 'email' || (key === 'secondaryEmail' && fields[key].length > 1)) {
                    if (typeof fields[key] !== "undefined") {
                        let lastAtPos = fields[key].lastIndexOf('@');
                        let lastDotPos = fields[key].lastIndexOf('.');

                        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields[key].indexOf('@@') === -1 && lastDotPos > 2 && (fields[key].length - lastDotPos) > 2)) {

                            errors[key] = errorClass;
                        }
                        else
                            errors[key] = '';
                    }
                }
                else
                    errors[key] = '';
            }

            this.setState({ errors: errors });
        }

        if (isScroll && document.getElementById(key) && !_.isEmpty(errors[key])) {
            document.getElementById(key).scrollIntoView({
                behavior: "smooth",
                block: "center"
            })
        }
    }
    //#endregion

    handleSubmit = (e) => {
        e.preventDefault();

        // let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);


        // if (!fields.sendEmail && !fields.sendSMS) {
        //     if (this.smsEnabled)
        //         Swal.fire(CommonHelper.showLocale(this.props, 'Please select Mobile or Email to send'), '', 'info');
        //     else
        //         Swal.fire(CommonHelper.showLocale(this.props, 'Please enter Email to send'), '', 'info');
        //     return false;
        // }

        // let errorClass = 'input_error';

        // for (let [key, value] of Object.entries(fields)) {
        //     if (this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0) {
        //         if (!fields[key] && key !== 'totalCost') {
        //             formIsValid = false;
        //             errors[key] = errorClass;
        //         }
        //     }
        // }

        // this.setState({ errors: errors });

        // if (formIsValid === true) {

        // }
        // else
        //     
        this.props.handleClose(fields);
        return formIsValid;
    };

    handleModalClose = (ID) => {
        this.setState({ fields: Object.assign({}) });
        this.setState({ errors: {} });
        this.props.handleClose(ID);
    };


    render() {
        const { fullName, firstName, lastName, phone, email, emails, sendSMS } = this.state.fields;

        const { show, dealersettings } = this.props;

        return (
            <>
                <Modal
                    id="modal-add-service-part"
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName={`modal-dialog modal-dialog-centered modal-confirm-quotation`}>

                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title" >
                                <Translate text={'Send To Customer'} upperCase={true} />
                            </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" pop-layout form-style">

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label ><Translate text={'Customer Name'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'full name'}
                                                className={`form-control ${this.state.errors["fullName"]}`}
                                                name="fullName"
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={fullName}
                                                readOnly={true}
                                            />
                                        </div>
                                        {
                                            this.smsEnabled && (<div className="form-group col-md-12">
                                                <label ><Translate text={'mobile'} /></label>
                                                <input
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder={'phone'}
                                                    pattern="[0-9]*"
                                                    className={`form-control `}
                                                    name="phone"
                                                    onChange={this.onHandleTelephoneChange}
                                                    value={phone}
                                                />
                                            </div>)}

                                        <div className="form-group col-md-12">
                                            <DropDownMenu
                                                text='+ add CC Email'
                                                keyVal="1002"
                                                tIndex="-1"
                                                className={`inputlink-addmore ${this.state.errors["emails"] ? 'label-error' : ''}`}
                                                menu={emailList}
                                                handleChange={this.handleAddEmail}
                                            >
                                            </DropDownMenu>
                                            <label ><Translate text={'email'} /></label>
                                            <InputText
                                                autoComplete="off"
                                                placeholder={'email'}
                                                className={`form-control ${this.state.errors["email"]}`}
                                                name="email"
                                                onChange={(e) => { e.preventDefault(); }}
                                                value={email}
                                                readOnly={true}
                                            />
                                        </div>

                                        {!_.isEmpty(emails) && emails.map((email, idx) => (

                                            <div className="form-group col-md-12" key={idx}>
                                                <a href="#" className="inputlink-remove" onClick={(e) => { e.preventDefault(); this.handleRemoveEmail(idx)}}>- <Translate text={'remove'} /></a>
                                                <label >{email.name} <Translate text={'email'} /> </label>
                                                <InputText
                                                    type="text"
                                                    className="form-control"
                                                    id={idx}
                                                    placeholder={`${email.name.toLowerCase()} email `}
                                                    value={email.value}
                                                    onChange={this.handleEmailChange(idx)}
                                                />
                                            </div>
                                        ))}

                                        {
                                            this.smsEnabled && (<div className="form-group col-md-12">
                                                <div className="checkbox icheck-success">
                                                    <input
                                                        type="checkbox"
                                                        id="sendSMS"
                                                        name="sendSMS"
                                                        className="uncheck-activity"
                                                        checked={sendSMS ? true : false}
                                                        disabled={phone ? false : true}
                                                        onChange={(e) => {
                                                            this.handleCheckChange(e, 'sendSMS')
                                                        }} />
                                                    <label htmlFor="sendSMS"><Translate text={'Send SMS To Customer'} /> </label>
                                                </div>
                                            </div>)}

                                    </div>

                                </div>

                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer className="modal-footer">

                        <button type="button" className="btn btn-default float-left" onClick={(e) => this.handleModalClose(null)}>
                            <Translate text={'cancel'} />
                        </button>

                        <button type="button"
                            className={`btn btn-primary float-right `}
                            onClick={(e) => this.handleSubmit(e)} >
                            {this.isLoading ? <span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'send'} />
                        </button>

                    </Modal.Footer>
                </Modal>



            </>
        );
    }
}
