/** LIBRARIES */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash'
import moment from 'moment'
import toast from 'toasted-notes'
/** COMPONENTS */
import Translate from '../../constants/translate';
import { notificationVM } from './viewModel.js'
import CommonHelper from '../../services/common';
import { ContentOverlay } from '../../components'
import ListView from './listView'
import { MainContext } from '../layout/provider';

const NotificationReducer = (state, action) => {
    function updateNotification() {
        return state.map((item) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPEND_LIST": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADD_NOTIFICATION_LIST": {
            return [action.data, ...state, action.data]
        }
        case "REMOVE_NOTIFICATION_LIST": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATE_NOTIFICATION_LIST": {
            return updateNotification()
        }
        default:
            return state;
    }
};

const Notification = (props) => {
    const [notifications, setNotifications] = useState([])
    // const [checkDataLoad, setDataload] = useState(true)
    // const pageLimit = 50
    // const [hasMore, setHasMoreData] = useState([])
    // const [isPaging, setPaging] = useState(false)
    const [notifyLoader, setNotifyLoader] = useState(false)
    const groupbyDate = ["Today", "This Week", "Earlier"];
    const [limitReached, setLimitReached] = useState(false);
    const tradeinNotify = ['tradein', 'tradeinPro', 'tradeinProOffer', 'tradeinProRequest', 'tradeinProValuer', 'tradeinProFinal', 'tradeinProactivity', 'tradeinProlog', 'inboundTIP']
    const {
        userNotifications,
        smsNotifications,
        hasMoreSalesNotifications,
        hasMoreServiceNotifications,
        hasMoreTradeinProNotifications,
        loadMoreNotifications,
    } = useContext(MainContext);

    useEffect(() => {
        if (props.notifyClose === true) {
            props.sidepanelClose()
            props.handlenotifyClose(false)
        }

    }, [props.notifyClose])

    useEffect(() => {
        function handleResize() {
            document.documentElement.style.setProperty('--notifyheight', (window.innerHeight - 60) + 'px');
        }
        document.documentElement.style.setProperty('--notifyheight', (window.innerHeight - 60) + 'px');
        window.addEventListener('resize', handleResize);
        document.getElementsByTagName('body')[0].classList.add('inner-bg');
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unsubnotificationlist && window.unsubnotificationlist();
        }
    }, [])

    useEffect(() => {
        let _notification = [];
        if (!props.isSMSNotify) {
            userNotifications.forEach(function (doc) {
                var logVM = converttoVM(doc);
                _notification.push(logVM)
            });
            setNotifications(_notification)
        }
    }, [userNotifications])

    useEffect(() => {
        let _notification = [];
        if (props.isSMSNotify) {
            smsNotifications.forEach(function (doc) {
                var logVM = converttoVM(doc);
                _notification.push(logVM)
            });
            setNotifications(_notification)
        }
    }, [smsNotifications])

    // useEffect(() => {
    //     if (!checkDataLoad) {
    //         return;
    //     }
    //     let refNotificationData = window.firebase.firestore().collection('notifications')
    //         .where('isDeleted', '==', false)
    //         .where('type', 'in', ['engageRequest','activity'])
    //         .where('toUserID', '==', localStorage.uid)
    //         .orderBy('addedDate', 'desc')

    //     if (hasMore.length > 0) {
    //         refNotificationData = refNotificationData
    //             .startAfter(hasMore[0])
    //             .limit(pageLimit)
    //     }
    //     else {
    //         refNotificationData = refNotificationData
    //             .limit(pageLimit)
    //     }

    //     window.unsubnotificationlist = refNotificationData
    //         .onSnapshot(onCollectionUpdate);

    // }, [checkDataLoad])

    // const onCollectionUpdate = (querySnapshot) => {
    //     let actionType;
    //     let snapshotDoc;
    //     querySnapshot.docChanges().forEach(change => {
    //         if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
    //         {
    //             snapshotDoc = converttoVM({
    //                 ...change.doc.data(),
    //                 documentID : change.doc.id
    //             });
    //             if (change.type === 'added') {
    //                 actionType = "ADD_NOTIFICATION_LIST";
    //             }
    //             else if (change.type === 'modified') {
    //                 actionType = "UPDATE_NOTIFICATION_LIST"
    //             }
    //             else if (change.type === 'removed') {
    //                 actionType = "REMOVE_NOTIFICATION_LIST"
    //             }
    //         }
    //     })
    //     const _notification = [];
    //     if (!actionType) {
    //         if (querySnapshot.docs.length > 0 && querySnapshot.docs.length === pageLimit) {
    //             setHasMoreData([querySnapshot.docs[querySnapshot.docs.length - 1]])
    //         }
    //         else {
    //             setHasMoreData([]);
    //         }
    //         querySnapshot.forEach(function (doc) {
    //             var logVM = converttoVM({
    //                 ...doc.data(),
    //                 documentID : doc.id
    //             });
    //             _notification.push(logVM)
    //         });
    //     }
    //     dispatch({
    //         type: actionType ? actionType : isPaging ? "APPEND_LIST" : "SUCCESS",
    //         data: actionType ? snapshotDoc : _notification,
    //     });
    //     setDataload(false)
    //     setNotifyLoader(false)
    //     setPaging(false)
    // }

    const converttoVM = (doc) => {
        const objnotifyData = Object.assign({}, doc);
        const listVM = Object.assign({}, notificationVM);
        for (let [key, value] of Object.entries(objnotifyData)) {
            //if (listVM.hasOwnProperty(key))
            listVM[key] = value;
        }
        listVM.addedDate = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        listVM.toUser = CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.toUserID);
        listVM.fromUser = !_.isEmpty(doc.fromUserID) ? CommonHelper.getUserNamebyId((!_.isEmpty(props.groupUsers) ? props.groupUsers : props.clientUsers), doc.fromUserID) : '';
        listVM.dateAge = doc.addedDate ? moment.unix(doc.addedDate.seconds).fromNow().replace('a month', '1 month').replace('a day', '1 day') : '';
        listVM.dateGroup = moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? 'Today' :
            moment.unix(doc.addedDate.seconds).week() === moment().week() ? 'This Week' : 'Earlier';
        listVM.client = (doc.clientID !== props.currentClient || localStorage.defaultModule === 'oem' || tradeinNotify.includes(doc.type)) ?
            (CommonHelper.getDealerNamebyId(localStorage.defaultModule === 'wholesaler' ?
                props.dealersettings.wholesalerclients : props.dealersettings.clients, doc.clientID)) : ''
        if (!_.isEmpty(doc.subType) && doc.type === 'log') {
            listVM.title = CommonHelper.autoCaps(doc.subType);
        }
        return listVM
    }
    const handleNotifyremove = useCallback((e, id) => {
        e.preventDefault();
        window.firebase.firestore().doc(`notifications/${id}`)
            .set({ isDeleted: true }, { merge: true })
            .then(snapshot => {
                toast.notify('Notification deleted successfully', {
                    duration: 2000,
                    position: 'top-right'
                })
            })
            .catch(error => {
                console.error(error);
                toast.notify('Something went wrong', {
                    duration: 2000,
                    position: 'top-right'
                })
            });
    }, [])

    const handlesidepanelClose = useCallback(() => {
        props.sidepanelClose()
    }, [])

    const handleScroll = e => {
        if (limitReached) {
            return;
        }

        let difference = e.target.scrollHeight - e.target.offsetHeight;
        const scrollTop = e.target.scrollTop * 1;
        difference = difference - 2;

        if (scrollTop > difference && (hasMoreSalesNotifications.length || hasMoreServiceNotifications.length || hasMoreTradeinProNotifications.length)) {
            loadMoreNotifications();
            //console.log('loadMore');
        }
    };

    return (
        <>
            {
                notifyLoader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) : (
                    notifications.length > 0 ? (
                        <div className="notify-scroll" onScroll={handleScroll}>
                            {
                                groupbyDate.map((date, index) => {
                                    return <div key={index}>
                                        {
                                            notifications.filter(item => item.dateGroup === date).length > 0 ? (<>
                                                <div className="notify-category">{date}</div>
                                                <div className="notify-list-wraper">
                                                    {
                                                        notifications.filter(item => item.dateGroup === date).map((rec, index) => {
                                                            return <ListView
                                                                key={index}
                                                                id={rec.documentID}
                                                                rec={rec}
                                                                handleNotifyremove={handleNotifyremove}
                                                                handleNotifyClick={props.handleNotifyClick}
                                                                handleNotifySwitchDealer={props.handleNotifySwitchDealer}
                                                                handleEnquirySwitchDealer={props.handleEnquirySwitchDealer}
                                                                sidepanelClose={handlesidepanelClose}
                                                                dealersettings={props.dealersettings}
                                                            />
                                                        })
                                                    }
                                                </div></>) : (<></>)
                                        }
                                    </div>
                                })

                            }
                            {/* {
                            isPaging ? (<div className="pull-left mt-4 w-100"><ContentOverlay active={true} /></div>) :
                                (notifications.length > 0 && hasMore.length > 0 ?
                                    (<div className="load-more-button"> <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setPaging(true)
                                        setDataload(true)
                                    }}><Translate text={'Load More'} /> <i className="fa fa-angle-down" aria-hidden="true"></i> </a></div>)
                                    : (<></>))
                        } */}

                        </div>

                    ) : (
                        <div className="no-notify">
                            <img src={require("../../images/no-notification.png")} width="113" height="104" alt="" />
                            <span> <Translate text={'noNotificationsRightNow'} /></span>
                        </div>
                    )
                )
            }
        </>
    );
}

export default Notification;