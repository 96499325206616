/** LIBRARIES */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'
import { Tabs } from 'antd';
import _ from 'lodash'
/** PROVIDERS */
/** VIEW-MODELS */
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, InputText } from '../../../components';
import { default as _images } from '../../../images';
import AddRecommendation from '../details/addRecommendation';
import { firestoreDB } from '../../../services/helper';
import { recommendationOption } from '../../../services/enum';

const AddBBTCheckList = (props) => {
    const { TabPane } = Tabs;
    const [errorFields, setErrorFields] = useState({})
    const [recommendations, setRecommendations] = useState(props.recommendations ? props.recommendations : [])
    const [active, setActive] = useState('0')
    const [loader, setLoader] = useState(false);
    const [recommendationModal, setrecommendationModal] = useState({
        show: false,
        docID: '',
        title: '',
        data: null
    });


    const checklists = _.chain(_.orderBy(recommendations.filter(item => item.type === 'bbt'), ['category'], ['asc']))
        .groupBy("category")
        .map((value, key) => ({ category: key, lists: value }))
        .value()

    const checklistsMandatory = _.orderBy(recommendations.filter(item => item.type === 'bbt' && item?.subList?.length), ['category'], ['asc'])?.flatMap(({ subList }) => subList.flatMap(x => x.value))

    //const checklistsMandatory = Array.prototype.map.call(_.orderBy(recommendations.filter(item => item.type === 'bbt'), ['category'], ['asc']), function (item) { return item.documentID; })//.join(",").split(',')

    const divStyle = {
        height: '400px',
        overflow: 'auto',
        position: "relative",
        width: '100%'
    };

    //#region RECOMMENDATION CHANGES
    const handleRecommendationClose = (_data) => {
        if (!_.isEmpty(_data)) {
            const _recommendations = Object.assign([], recommendations);

            _recommendations.filter(function (obj) {
                //Update old data
                if (obj.documentID === _data.documentID) {
                    obj.detail = _data.detail;
                }
            });
            //console.log('handleRecommendationClose', _data, _recommendations)
            setRecommendations(_recommendations)
        }
        setrecommendationModal({
            show: false,
            title: '',
            docID: '',
            data: null
        });
    }

    const handleEditRecommendation = (req) => {
        setrecommendationModal({
            show: true,
            title: 'Edit Recommendation',
            docID: req ? req.documentID : '',
            data: req
        });
    }
    //#endregion

    const handleOnChange = (e, id) => {
        e.preventDefault()
        const { name, value } = e.target;
        var array1 = value.split(' ');
        var str = value;

        if (array1.length > 0) {
            var newarray1 = [];
            for (var x = 0; x < array1.length; x++) {
                newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
            }
            str = newarray1.join(' ')
        } else {
            if (str && str.length >= 1) {
                var firstChar = str.charAt(0);
                var remainingStr = str.slice(1);
                str = firstChar.toUpperCase() + remainingStr;
            }
        }
        let _newData = [..._.map(recommendations, function (obj) {
            return obj.documentID === id ? {
                ...obj,
                subList: [..._.map(obj.subList, function (item) {
                    return item.value === name ? {
                        ...item,
                        data: str
                    } : { ...item };
                })]
            } : { ...obj };
        })]
        setRecommendations(_newData)
        setErrorFields({
            ...errorFields,
            [name]: ''
        });



        setErrorFields({
            ...errorFields,
            [name]: ''
        });

    }

    const handleCheckChange = (e) => {

        const { name, checked } = e.target;

        const _recommendations = Object.assign([], recommendations);

        _recommendations.filter(function (obj) {
            //Update old data
            if (obj.documentID === name) {
                obj.choice = checked ? 'Required' : null;
                if (checked) {
                    let recommendationDetail = Object.assign({}, obj.detail);
                    recommendationDetail.option = recommendationOption.customer;
                    recommendationDetail.status = 'urgent';
                    recommendationDetail.oKToDo = true;
                    obj.detail = recommendationDetail;

                    obj.addedBy = props.dealersettings ? props.dealersettings.id : '';
                    obj.addedDate = window.firebase.firestore.Timestamp.now();
                    obj.addedFrom = 'web';
                    obj.modifiedBy = props.dealersettings ? props.dealersettings.id : '';
                    obj.modifiedDate = window.firebase.firestore.Timestamp.now();
                    obj.modifiedFrom = 'web'
                    handleEditRecommendation(obj);
                }
            }
        });

        setRecommendations(_recommendations)
        // setErrorFields({
        //     ...errorFields,
        //     [name]: ''
        // });        
    }

    const handleSubmit = () => {
        let formIsValid = true;
        let errorClass = 'input_error';
        let _errorFields = {};
        let _errorFieldTab = null;
        let recommendationsData = recommendations.filter(item => item.type === 'bbt');

        recommendationsData && recommendationsData.forEach((list) => {
            list.subList && list.subList.forEach((item) => {
                if (checklistsMandatory.indexOf(item.value) >= 0 && !item.data) {
                    if (!_.isNumber(_errorFieldTab)) {
                        let activeStageIndex = _.indexOf(checklists.map(v => v.category), list.category)
                        _errorFieldTab = activeStageIndex;
                    }
                    formIsValid = false;
                    _errorFields[item.value] = errorClass;
                }
            });
        })
        setErrorFields(_errorFields);
        if (formIsValid === true) {
            setLoader(true);
            const batch = firestoreDB(props.dealersettings).firestore().batch();
            recommendationsData && recommendationsData.forEach(data => {
                if (!data.projectId) data.projectId = (props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null);
                batch.set(firestoreDB(props.dealersettings).firestore().doc(`serviceJobs/${data.serviceID}/recommendations/${data.documentID}`), data, { merge: true });
            })
            batch.commit().then(snapshot => {
                setLoader(false);

                props.handleClose(formIsValid);

                if (props.handleStatusReScheduled)
                    props.handleStatusReScheduled();


            }).catch((error) => {
                console.error("Error updating document: ", error);
            });

        }
        else {
            setActive(_errorFieldTab)
        }
    }

    return (
        <>

            <Modal
                id="modal-add-bbtchecklist"
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-scrollable modal-dialog-centered modal-add-bbtchecklist">

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title" ><Translate text={props.title ? props.title : 'Brake Battery Tyre Checklist'} /></h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <>

                        <div className="container-fluid">
                            <div className="row">

                                <div className="bbtchecklist-popup-container tabs">

                                    <div className="tabs-wraper">


                                        <Tabs tabPosition={'top'} activeKey={`${active}`} onTabClick={(key) => { setActive(key) }}>
                                            {
                                                !_.isEmpty(checklists)
                                                    ?
                                                    <>
                                                        {
                                                            checklists && checklists.map((info, index) => {
                                                                return <TabPane tab={info.category} key={index}>
                                                                    <div className="bbtchecklist-popup-wrapper">
                                                                        {
                                                                            info.lists && _.orderBy(info.lists, ['name'], ['asc']).map((list, index1) => {
                                                                                return <div key={index1} className="bbtchecklist-loop">

                                                                                    <div className="bbtchecklist-loop-title">
                                                                                        <div className="float-left">
                                                                                            <div className="bbtchecklist-loop-name">{list.name}</div>
                                                                                        </div>
                                                                                        <div className="float-right">
                                                                                            <div className={`checkbox icheck-success float-left mr-3  ${props.hasAccess ? '' : 'form-disable'}`}>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={list.documentID}
                                                                                                    name={list.documentID}
                                                                                                    className="uncheck-activity"
                                                                                                    checked={list.choice ? true : false}
                                                                                                    onChange={(e) => {
                                                                                                        if (props.hasAccess)
                                                                                                            handleCheckChange(e)
                                                                                                    }} />
                                                                                                <label htmlFor={list.documentID}><Translate text={'Recommended'} /></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`bbtchecklist-loop-list ${props.hasAccess ? '' : 'form-disable'}`}>
                                                                                        <div className=" pop-layout form-style">
                                                                                            <div className="form-row">
                                                                                                {
                                                                                                    list.subList && _.orderBy(list.subList, ['name'], ['asc']).map((item, index2) => {
                                                                                                        return <div key={index2} className="form-group col-md-6">
                                                                                                            <label>{item.name} </label>
                                                                                                            <InputText
                                                                                                                autoComplete="off"
                                                                                                                placeholder={item.name}
                                                                                                                className={`form-control ${errorFields[item.value]}`}
                                                                                                                name={item.value}
                                                                                                                onChange={(e) => { handleOnChange(e, list.documentID) }}
                                                                                                                value={item.data ? item.data : ''}
                                                                                                            />
                                                                                                        </div>

                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            })
                                                                        }

                                                                    </div>

                                                                </TabPane>
                                                            })
                                                        }

                                                    </>
                                                    :
                                                    <>
                                                        <div className="spinner-loader h-100 p-5 w-100">
                                                            <div className="no-cafe-flex h-100 justify-content-center align-items-center ">
                                                                <div className="no-cafe-img">
                                                                    <i className="ico icon-service-checklist"></i> </div>
                                                                <div className="no-data-txt mt-2"> <Translate text={'No checklist available.'} /></div>
                                                            </div>

                                                        </div>
                                                    </>
                                            }

                                        </Tabs>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </>
                </Modal.Body>
                {
                    !_.isEmpty(checklists) && props.hasAccess
                        ?
                        <>
                            <Modal.Footer>

                                <button
                                    type="button"
                                    className="btn btn-default float-left"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleClose(null);

                                    }}
                                ><Translate text={'cancel'} />
                                </button>

                                <button
                                    type="button"
                                    className={`btn btn-primary float-right ${loader ? 'form-disable' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    {
                                        loader ?
                                            (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                            : (<></>)
                                    }
                                    <Translate text={'save'} />
                                </button>

                            </Modal.Footer>
                        </>
                        :
                        <>
                        </>
                }


            </Modal>

            <PopUpModal show={recommendationModal.show}>
                <AddRecommendation
                    show={recommendationModal.show}
                    docID={recommendationModal.docID}
                    title={recommendationModal.title}
                    recomm={recommendationModal.data}
                    serviceID={props.serviceID}
                    mandatoryFields={props.mandatoryRecommFields}
                    handleClose={handleRecommendationClose}
                    dealersettings={props.dealersettings}
                    isDontSave={true}
                    serviceVideos={props.serviceVideos}
                    handleVideoOpen={props.handleVideoOpen}
                    deleteVideo={props.deleteVideo}
                >
                </AddRecommendation>
            </PopUpModal>
        </>
    )
}

export default AddBBTCheckList