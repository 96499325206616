import React, { useReducer, useState, useEffect } from 'react'
import moment from 'moment';
import _ from 'lodash'
import Translate from '../../../../constants/translate';
import { targetlistFields, targetVM } from './viewModel'
import TargetKPIs from './targetKPIs'
import CommonHelper from '../../../../services/common';
import Swal from 'sweetalert2';
import toast from 'toasted-notes';
import { ContentOverlay, TableView, PopUpModal, ReactSelect } from '../../../../components';
import { useContext } from 'react';
import { ClientContext } from '../clientContext';
import { Tab, Tabs } from 'react-bootstrap';
import UserKPITargets from './kpitargets';
// import CustomTargets from './customTargets';

const TargetListReducer = (state, action) => {
    function updateTargets() {
        return state.map((item, index) => {
            if (item.documentID === action.data.documentID) {
                return action.data;
            }
            return item;
        });
    }
    switch (action.type) {
        case "SUCCESS": {
            return action.data
        }
        case "APPENDTARGETS": {
            return [...state, ..._.differenceBy(action.data, state, 'documentID')]
        }
        case "ADDTARGETS": {
            return [action.data, ...state, action.data]
        }
        case "REMOVETARGETS": {
            return state.filter(item => item.documentID !== action.data.documentID)
        }
        case "UPDATETARGETS": {
            return updateTargets();
        }
        default:
            return state;
    }
};

const OEMTarget = (props) => {
    const [targets, dispatch] = useReducer(TargetListReducer, [])
    const [windowSize, setWindowSize] = useState({ windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500), windowHeight: (window.innerHeight - 259) })
    const [loader, setLoader] = useState(true)
    const [showModel, setShowModel] = useState(false);
    const [popHeader, setPopHeader] = useState('')
    const [searchText, setSearchText] = useState('')
    const [selectedTarget, setTarget] = useState(null)
    const [clientOptions, setClientOptions] = useState([]);
    const { groups, regions } = useContext(ClientContext);
    const [regionGroupOptions, setRegionGroupOptions] = useState([])
    const [selectedregionGroup, setSelectedRegionGroup] = useState()
    const [showKPITarget, setShowKPITarget] = useState(false)//KPI Target when inter emirates static report is enable

    useEffect(() => {
        let _clients = [];
        props.dealersettings.allClients.forEach(_client => {
            _clients.push({ label: _client.name, value: _client.documentID, data: _client, active: _.isBoolean(_client.active) ? _client.active : true, })
        });
        setClientOptions(_clients)
    }, [])

    useEffect(() => {
        let _options = [{
            lable: props.dealersettings.settingName,
            value: props.dealersettings.oemID
        }];
        if (!_.isEmpty(groups) && props.dealersettings.level === 'group') {
            _options = _.filter(groups, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'group')), v.documentID) >= 0)
                .map(rec => {
                    return {
                        label: rec.name,
                        value: rec.documentID
                    }
                });
        }
        if (!_.isEmpty(regions) && props.dealersettings.level === 'region') {
            _options = _.filter(regions, (v) => _.indexOf(_.uniq(_.map(clientOptions.map(rec => { return rec.data }), 'region')), v.code) >= 0)
                .map(rec => {
                    return {
                        label: rec.name,
                        value: rec.documentID
                    }
                });
        }
        setRegionGroupOptions(_options);
        if (_options.length > 0)
            setSelectedRegionGroup(_options[0])

    }, [groups, regions, clientOptions])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                windowWidth: window.innerWidth < 1450 ? (window.innerWidth - 410) : (window.innerWidth - 500),
                windowHeight: (window.innerHeight - 259)
            })
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.unSubTargets && window.unSubTargets();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(selectedregionGroup))
            return
        let targetsDataRef = window.firebase.firestore().collection('targets')
            .where('type', '==', 'client')
            .where('isDeleted', '==', false)

        if (props.dealersettings.level === 'group')
            targetsDataRef = targetsDataRef.where('groupID', '==', selectedregionGroup.value);
        else if (props.dealersettings.level === 'region')
            targetsDataRef = targetsDataRef.where('regionID', '==', selectedregionGroup.value);
        else
            targetsDataRef = targetsDataRef.where('oemID', '==', selectedregionGroup.value);

        window.unSubTargets = targetsDataRef
            .onSnapshot(onCollectionUpdate);


    }, [selectedregionGroup])

    useEffect(() => {
        window.firebase.firestore().collection('schedular')
            .where('static', '==', true)
            .where('oemID', '==', props.dealersettings.oemID)
            .where('value', '==', 'InterEmiratesExcelReport')
            .limit(1)
            .get()
            .then(docs => {
                if (docs.size > 0) {
                    setShowKPITarget(true)
                }
            });

    }, [])

    const onCollectionUpdate = (querySnapshot) => {
        let actionType;
        let snapshotDoc;
        querySnapshot.docChanges().forEach(change => {
            if (change.oldIndex >= 0) //if snapshot updated oldindex will be 0 if first load or page load oldindex will be -1
            {
                snapshotDoc = convertTargetVM({
                    ...change.doc.data(),
                    documentID: change.doc.id
                });
                if (change.type === 'added') {
                    actionType = "ADDTARGETS";
                }
                else if (change.type === 'modified') {
                    actionType = "UPDATETARGETS"
                }
                else if (change.type === 'removed') {
                    actionType = "REMOVETARGETS"
                }
            }
        })
        const _targets = [];
        if (!actionType) {
            querySnapshot.forEach((doc) => {
                _targets.push(convertTargetVM({
                    ...doc.data(),
                    documentID: doc.id
                }));
            });
        }
        if (actionType) {
            dispatch({
                type: actionType,
                data: snapshotDoc
            });
        }
        else {
            dispatch({
                type: "SUCCESS",
                data: _targets
            });
        }


        setLoader(false)
    }

    const convertTargetVM = (doc) => {
        const objtargetData = Object.assign({}, doc);
        const _target = Object.assign({}, targetVM);
        for (let [key, value] of Object.entries(objtargetData)) {
            _target[key] = value;
        }
        _target.clientsName = (<div className="Vehicle-availability">
            {
                _.filter(props.dealersettings.allClients, (v) => _.indexOf(doc.clientIDs, v.documentID) >= 0).map((rec, index) => {
                    return <div key={index}><span>{rec.name}</span></div>
                })
            }
        </div>);
        _target.clientNames = _.filter(props.dealersettings.allClients, (v) => _.indexOf(doc.clientIDs, v.documentID) >= 0).map((rec, index) => {
            return rec.name
        });
        _target.createdBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.addedBy);
        _target.updatedBy = CommonHelper.getUserNamebyId(props.clientUsers, doc.modifiedBy);
        _target.createdOn = doc.addedDate ? moment.unix(doc.addedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        _target.updatedOn = doc.modifiedDate ? moment.unix(doc.modifiedDate.seconds).format('DD/MM/YYYY hh:mm A') : '';
        return _target
    }



    const handleAddClientTarget = () => {
        setPopHeader('Add Target')
        setTarget(null)
        setShowModel(true)
    }

    const handleModelClose = () => {
        setTarget(null)
        setShowModel(false)
    }

    const handleActionClick = (id, data) => {
        return (
            <div className="action-fix">
                <div style={{ cursor: 'pointer' }} className="mini-button float-left mr-2" onClick={(e) => {
                    hadleEditTarget(data)
                }}>
                    <i className="ico icon-edit"></i>
                </div>
                <div style={{ cursor: 'pointer' }} className="mini-button" onClick={(e) => {
                    hadleDeleteTarget(data)
                }}>
                    <i className="ico icon-delete"></i>
                </div>
            </div>
        )
    }

    const hadleEditTarget = (data) => {
        const targetData = Object.assign({}, data);
        delete targetData.clientsName;
        delete targetData.clientNames;
        delete targetData.createdBy;
        delete targetData.updatedBy;
        delete targetData.createdOn;
        delete targetData.updatedOn;
        setPopHeader('Edit Target')
        setTarget(targetData)
        setShowModel(true)
    }

    const hadleDeleteTarget = (data) => {
        Swal.fire({
            title: CommonHelper.showLocale(props, 'Are you sure?'),
            text: CommonHelper.showLocale(props, 'Do you want to delete'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: CommonHelper.showLocale(props, 'Yes'),
            cancelButtonText: CommonHelper.showLocale(props, 'No'),
        }).then((result) => {
            if (result.value) {
                window.firebase.firestore().doc(`targets/${data.documentID}`)
                    .set({
                        modifiedDate: window.firebase.firestore.Timestamp.now(),
                        modifiedBy: localStorage.uid,
                        isDeleted: true
                    }, { merge: true })
                    .then(() => {
                        setLoader(false);
                        toast.notify(`Dealer Target deleted successfully`, {
                            duration: 2000
                        })
                    })
                    .catch(error => {
                        setLoader(false);
                        console.log(error);
                        toast.notify('Something went wrong', {
                            duration: 2000
                        })
                    })
            }
        })
    }

    const handleClientdetailsClick = (e, id, data) => {
        hadleEditTarget(data)
    }

    return (
        <>

            <h2><Translate text={'Targets'} /> </h2>
            <div className="divider-line"></div>
            <div className={`settings-tab-wrap tabs`}>
                <div className='tabs-wraper'>
                    <nav>
                        <Tabs
                            defaultActiveKey='dealertargets'
                            className='nav-fill'
                            mountOnEnter={true}
                        >
                            <Tab eventKey='dealertargets' title={CommonHelper.showLocale(props, 'Client Targets')}>
                                <div className={`settings-tab-wrap tabs`}>
                                    <div className="mt-2">
                                        <div className="settings-head form-style">
                                            <div className="row">

                                                <div className="col-md-9">
                                                    <div className="row">
                                                        {
                                                            props.dealersettings.level !== 'oem' ? (
                                                                <div className="col-md-3 pr-0">
                                                                    <ReactSelect
                                                                        options={regionGroupOptions}
                                                                        name={"regionID"}
                                                                        placeholder={'please select'}
                                                                        onChange={(e) => {
                                                                            if (!_.isEmpty(selectedregionGroup) && e.value !== selectedregionGroup.value)
                                                                                setLoader(true)
                                                                            setSelectedRegionGroup(e)
                                                                        }}
                                                                        value={!_.isEmpty(selectedregionGroup) ? selectedregionGroup.value : ''}
                                                                        classNamePrefix={`basic-select`}
                                                                        removeClearable={true}
                                                                    >
                                                                    </ReactSelect>
                                                                </div>
                                                            ) : (<></>)
                                                        }
                                                        <div className="filter-search search-icon col-md-3">
                                                            <input placeholder="Search" aria-label="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="float-right">
                                                        <button type="button" className="btn btn-primary float-right"
                                                            onClick={() => {
                                                                handleAddClientTarget()
                                                            }}
                                                        ><i className="ico icon-add mr-2"></i>   <Translate text={'Add Target'} /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            loader ? (<div className="mt-5"><ContentOverlay active={true} /></div>) :
                                                targets.length > 0 ? (
                                                    <div className="common-table">
                                                        <TableView
                                                            datalist={targets.filter(({ title, clientNames }) =>
                                                            (!_.isEmpty(searchText) ? (
                                                                (!_.isEmpty(title) ? title.toLowerCase().includes(searchText.toLowerCase()) : false) ||
                                                                (!_.isEmpty(clientNames) ? clientNames.some(a => a.toLowerCase().includes(searchText.toLowerCase())) : false)
                                                            ) : true)
                                                            )}
                                                            height={windowSize.windowHeight}
                                                            width={windowSize.windowWidth}
                                                            columns={targetlistFields}
                                                            dynamicFields={targetlistFields.filter(e => e.default === true).map(v => _.pick(v, ['value']).value)}
                                                            handleActionClick={handleActionClick}
                                                            handleRowSingleClick={handleClientdetailsClick}
                                                            isSettings={false}
                                                            isReorderable={false}
                                                            isPaging={false}
                                                            isSorting={true}
                                                            isTranslate={true}
                                                            dealersettings={props.dealersettings}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="common-table">
                                                        <div className="text-center p-5">
                                                            <p><Translate text={'No targets found'} /></p>
                                                            <button className="btn btn-sm btn-default" onClick={() => { handleAddClientTarget() }}><i className="ico icon-add mr-2 pt-1 float-left"></i>
                                                                <Translate text={'Add Target'} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </Tab>
                            {
                                showKPITarget ? (<Tab eventKey='KPItargets' title={CommonHelper.showLocale(props, 'KPI Targets')}>
                                    <UserKPITargets
                                        {...props}
                                    />
                                </Tab>) : (<></>)
                            }
                            {/* <Tab eventKey='oemtargets' title={CommonHelper.showLocale(props, `${props.dealersettings.level === 'oem' ? 'OEM' : props.dealersettings.level === 'region' ? 'Region' : props.dealersettings.level === 'group' ? 'Group' : 'Dealer'} Target`)}>
                                <CustomTargets
                                    {...props}
                                />
                            </Tab> */}
                        </Tabs>
                    </nav>
                </div>
            </div>
            <PopUpModal show={showModel}>
                <TargetKPIs
                    {...props}
                    show={showModel}
                    title={popHeader}
                    handleClose={handleModelClose}
                    target={selectedTarget}
                    allTargets={targets}
                    selectedregionGroup={selectedregionGroup}
                />
            </PopUpModal>
        </>
    )
}

export default OEMTarget