import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash'
import _images from '../../images'
import { Dropdown } from 'react-bootstrap'
import { CustomToggle, CustomFilter } from '../../components/customdropdown';
import DynamicFilters from '../reports/dynFilters';

const CalendarFilter = (props) => {
    const node = useRef();
    const [filterShow, setShow] = useState(false)
    const [objFilter, setObjFilter] = useState({});
    const [showCount, setShowCount] = useState(false)
    const statisFilters = [
        'region',
        'subregion',
        'group',
        'dealer'
    ];

    const setMultibjFilter = (selectedOptions, name) => {
        let value = [];
        !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
            value.push(data.value)
        })
        let _objFilter = objFilter;
        if (name === 'region') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['subregion']: [],
                ['group']: [],
                ['dealer']: [],
            };
        } else if (name === 'subregion') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['group']: [],
                ['dealer']: [],
            };
        } else if (name === 'group') {
            _objFilter = {
                ..._objFilter,
                [name]: value,
                ['dealer']: [],
            };
        } else {
            _objFilter = {
                ..._objFilter,
                [name]: value,
            };
        }
        setObjFilter(_objFilter);
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }

    const clearFilter = () => {
        setShow(false)
        setShowCount(false)
        setObjFilter({})
        props.calendarFilter({})
    }
    const applyFilter = () => {
        setShow(false)
        setShowCount(true)
        //console.log('calendarFilter', objFilter)
        props.calendarFilter(objFilter)
    }
    return (
        <>


            <Dropdown ref={node} alignRight show={filterShow}>
                <div className="advanced-filter-btn mr-2 float-right">
                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                        setShow(!filterShow)
                    }}>
                        {filterCounts()}
                        <i className="ico icon-filter"></i>Filters
                    </Dropdown.Toggle>
                    {
                        !_.isEmpty(objFilter) && showCount
                            ?
                            <>
                                <a href="#" className="clear-btn" onClick={(e) => {
                                    e.preventDefault();
                                    clearFilter();
                                }}> <i className="ico icon-remove"></i> </a>
                            </>
                            :
                            <></>
                    }
                </div>
                <Dropdown.Menu as={CustomFilter}
                    id="table-reports-cols-settings-pipeline"
                    className="dropdown-menu dropdown-menu-left"
                    ChildClass="form-style quick-filter-wrap"
                    xplacement="bottom-end"
                >
                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                        <div className="report-filter-wraper form-style">
                            {
                                statisFilters.map((rec, i) => {
                                    return <DynamicFilters
                                        key={i}
                                        {...props}
                                        filter={rec}
                                        objFilter={objFilter}
                                        columns={[]}
                                        setMultibjFilter={setMultibjFilter}
                                    />
                                })
                            }
                        </div>
                    </div>
                    <div className="quick-filter-footer">
                        <button type="button" className="btn btn-default float-left" onClick={() => {
                            clearFilter();
                        }}>Clear</button>
                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                            applyFilter();
                        }}>Apply</button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default CalendarFilter;