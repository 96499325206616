/** LIBRARIES */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import _ from 'lodash';
/** PROVIDERS */
import { PipelineProvider, PipelineConsumer } from './PipelineProvider';
/** COMPONENTS */
import { ContentOverlay } from '../../../components'
import { enquiryStatus } from '../../../services/enum'
import Translate from '../../../constants/translate';
import CommonHelper from '../../../services/common';

class StatusUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { dealersettings, clientUsers, groupUsers, history, enquiryid, handleStatusUpdateDone } = this.props;
        //console.log('S T A T U S - U P D A T E')
        return (

            <PipelineProvider
                enquiryid={enquiryid}
                dealersettings={dealersettings}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
                history={history}
                handleStatusUpdateDone={handleStatusUpdateDone}
            >
                <PipelineConsumer>
                    {({ enquiry, updateStatus, loading, canModifyEnquiry, reopenEnquiry, enquiryAllStatus, isStatusOpen, isStatusSold }) => loading ?
                        (<ContentOverlay active={true} />) : (
                            <>
                                {
                                    canModifyEnquiry
                                        ?
                                        <>
                                            {
                                                enquiry.pipeline === 'LeadsBucket' ? (<></>)
                                                    : (
                                                        <>
                                                            {
                                                                (enquiry && enquiry.pipeline !== 'LeadsBucket' && dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.pipelines.length > 0 &&
                                                                    dealersettings.client.settings.pipelines.filter(item => item.value !== 'LeadsBucket').length === 0)
                                                                    ?
                                                                    <></>
                                                                    :
                                                                    <>

                                                                        {(() => {
                                                                            if (isStatusOpen || enquiry.status === 'open' || enquiry.status === 'reopen') {
                                                                                return (
                                                                                    <>
                                                                                        <div className="link-enq-buttons">
                                                                                            <div className="quickview-btn-wrap">
                                                                                                {
                                                                                                    enquiry.isAfterSales
                                                                                                        ?
                                                                                                        <>
                                                                                                            <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                updateStatus('status', 'won')
                                                                                                            }}>  <Translate text={'closeWon'} upperCase={true} /></a>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <a href="#" className="btn btn-common button-green" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                updateStatus('status', 'won')
                                                                                                            }}>  <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'won')} upperCase={true} /></a>
                                                                                                        </>
                                                                                                }


                                                                                                <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    updateStatus('status', 'lost')
                                                                                                }}>  <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'lost')} upperCase={true} /></a>
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if (isStatusSold || enquiry.status === enquiryStatus.WON || enquiry.status === enquiryStatus.CLOSED) {
                                                                                return (
                                                                                    <>
                                                                                        <div className="link-enq-buttons">
                                                                                            <div className="quickview-btn-wrap">
                                                                                                {
                                                                                                    enquiry.isAfterSales
                                                                                                        ?
                                                                                                        <>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <a href="#" className="btn btn-common button-delivered" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                updateStatus('status', 'delivered')
                                                                                                            }}>  <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'delivered')} upperCase={true} /></a>
                                                                                                            
                                                                                                            <a href="#" className="btn btn-common button-red" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                updateStatus('status', 'lost')
                                                                                                            }}>  <Translate text={CommonHelper.getNameByValue(enquiryAllStatus, 'lost')} upperCase={true} /></a>
                                                                                                        </>
                                                                                                }

                                                                                                {
                                                                                                    reopenEnquiry
                                                                                                        ?
                                                                                                        <>
                                                                                                            <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                updateStatus('status', 'open')
                                                                                                            }}> <Translate text={'reopen'} upperCase={true} /></a>
                                                                                                        </>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            else if ((enquiry.status === enquiryStatus.LOST || enquiry.status === enquiryStatus.DELIEVERD) && reopenEnquiry && !enquiry.lastStatus) {
                                                                                return (
                                                                                    <>
                                                                                        <div className="link-enq-buttons">
                                                                                            <div className="quickview-btn-wrap">
                                                                                                <a href="#" className="btn btn-common button-reopen" onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    updateStatus('status', 'open')
                                                                                                }}> <Translate text={'reopen'} upperCase={true} /></a>
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })()}


                                                                    </>
                                                            }
                                                        </>
                                                    )
                                            }
                                        </>
                                        :
                                        <>
                                        </>
                                }

                            </>
                        )}
                </PipelineConsumer>
            </PipelineProvider>
        );
    }
}

export default withRouter(StatusUpdate);