/** LIBRARIES */
import React, { useEffect, Suspense } from 'react';
import _ from 'lodash'
/** PROVIDERS */
/** COMPONENTS */

import MainContainer from './container';


const TradeInLayout = (props) => {



    useEffect(() => {
        if (window.location.pathname.indexOf('tradeinpro/eval') >= 0)
            document.getElementsByTagName('body')[0].classList.add('tradein-eval-page-landing');
        else if (window.location.pathname.indexOf('tradeinpro/internal') >= 0)
            document.getElementsByTagName('body')[0].classList.add('tradein-page-landing-internal');
        else if (window.location.pathname.indexOf('tradeinpro/intake') >= 0)
            document.getElementsByTagName('body')[0].classList.add('tradein-page-landing-intake');
        else if (window.location.pathname.indexOf('tradein/details') >= 0 || window.location.pathname.indexOf('tradein/images') >= 0)
            document.getElementsByTagName('body')[0].classList.add('tradein-page-landing-new');
        else
            document.getElementsByTagName('body')[0].classList.add('tradein-page-landing');

        document.getElementById('root') && document.getElementById('root').classList.add('h-100');
        return () => {
            document.getElementById('root') && document.getElementById('root').classList.remove('h-100');
        }
    }, [])





    return (
        <Suspense fallback={<></>}>
            <>
                <MainContainer history={props.history} />
            </>
        </Suspense>
    );
}

export default TradeInLayout;