/** LIBRARIES */
import * as React from 'react'
import moment from 'moment';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import Swal from 'sweetalert2'
import _ from 'lodash'
import { collectionData } from 'rxfire/firestore';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators'
import { Modal } from "react-bootstrap";
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { PopUpModal, PopoverPanel } from '../../../components';
import LostApprovalNotes from '../../common/lostApprovalNotes';
import CommonHelper from '../../../services/common';
import enquiryHelper from '../../../services/enquiryHelper'
import AddRequirement from '../../requirement/add'
import MultiRequirementSelect from '../requirements/multiRequirementSelect'
import LostReasons from '../lostReasons';
import DeliveredOptions from '../deliverdOption'
import EnquiryFields from '../enquiryFields'
import PipelineSwitch from '../pipelineSwitch';
import MergeList from '../merge/enquiryList'
import LeadConvert from '../leadConvert';
import AddContact from '../../contacts/add';
import TradeinCompletedList from '../../tradeinplus/list/completedList'
import SubLeadsOptions from '../subLeads'
import Files from "../../files";
import AddFile from "../../files/add";
/** VIEW-MODELS */
import { enquiryStatus, enqUnActionStatus, stockStatus, tipStatus } from '../../../services/enum'
import { objEnquiry, objStageEnquiry, objActivitylog, enquiryBasicDetailsVM, objModules, objWalkIn } from '../viewModel';
import { objOwnedVehicle, objContact, contactBasicDetailsVM } from '../../contacts/viewModel';
import { objRequirementStock } from '../../stock/viewModel'
import { objRequirement } from '../../requirement/viewModel'
import { firestoreDB } from '../../../services/helper';

const MyPipelineContext = React.createContext()

const fieldsData = {
  contact: '',
  businessContact: '',
  requirement: '',
  enquiry: '',
  company: ''
}
let titles = [];
let enquiryOptions = [];
let enquiryOptionsDF = [];
let enquiryAllStatus = [];
let financeStatuses = [];
let afterMarketStatuses = [];

const __financestatus = [
  { active: true, value: 'InProgress', label: <div className={`badge badge-pill badge-status-inprogress`}><Translate text={'InProgress'} upperCase={true} /></div> },
  { active: true, value: 'Pending', label: <div className={`badge badge-pill badge-status-pending`}><Translate text={'Pending'} upperCase={true} /></div> },
  { active: true, value: 'Won', label: <div className={`badge badge-pill badge-status-won`}><Translate text={'won'} upperCase={true} /></div> },
  { active: true, value: 'Lost', label: <div className={`badge badge-pill badge-status-lost`}><Translate text={'lost'} upperCase={true} /></div> }
]
export const PipelineConsumer = MyPipelineContext.Consumer

export class PipelineProvider extends React.Component {


  constructor(props) {
    super(props);
    //this.ref = firestoreDB(this.props.dealersettings).firestore().collection('enquiries');
    this.refContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails');
    this._isMounted = false;
    this.unsubscribeRequirements = null;
    this.unsubscribeEnquiryQV = null;
    this.unsubscribeContact = null;
    this.unsubscribeAmsMe = null;
    this.unsubscribeLinkEnquiry = null;
    this.unsubscribeFiles = null;
    this.mandatoryFields = [];
    this.state = {
      enquiry: objEnquiry,
      contact: Object.assign({}, objContact),
      enquiryBasicVM: {},
      amsmeUser: {},
      errors: {},
      requirements: Object.assign([]),
      ownedVehicles: Object.assign([]),
      linkEnquiries: Object.assign([]),
      files: Object.assign([]),
      loading: true,
      activitiesList: [],
      customerlogList: [],
      allFinanceUsers: [],
      allAfterMarketUsers: [],
      modules: Object.assign({}, objModules),
      lostReasons: [],
      lostApprovalPopOver: { showpopover: false, targetpopover: null },
      requirementModal:
      {
        show: false,
        Id: '',
        title: '',
        clsActive: '',
        requirement: null,
        clone: null,
        mandatoryFields: null
      },
      requirementMultiModal:
      {
        show: false,
        Id: '',
        title: '',
        clsActive: '',
        nxtStatus: null
      },
      deliveryModal: {
        show: false,
      },
      lostModal: {
        show: false,
      },
      enquiryModal: {
        show: false,
        errors: null,
        mandatoryFields: null,
        newStatus: null,
        newStatusValue: null,
        ismodifyStatusDate: false,
        isModifyInboundOwner: false
      },
      contactModal:
      {
        show: false,
        showSearch: false,
        title: '',
        clsActive: '',
        mandatoryFields: null
      },
      newStage: null,
      isEmptyPipeline: false,
      showNewLeadModal: false,
      showPipelineOnOpen: false,
      statusSettings: [],
      defaultStatus: [],
      defaultMakes: [],
      isAccessToReOpen: false,
      allClientUsers: [],
      showMergeEnqList: false,
      showLeadConvert: false,
      convertLeadLoader: false,
      dealersettings: props.dealersettings,
      completedTradeinsList: {
        show: false,
        isMandatory: false,
        isReopen: false
      },
      subLeadModal: {
        show: false,
        enquiries: null,
        mandatoryFields: null,
        soldIDs: null,
        status: null,
        nxtStatus: null,
        checkPrimary: null
      },
      fileModal: {
        show: false,
        category: null,
        type: null,
        categories: null
      },
      filesPopOver: {
        show: false,
        category: null,
        type: null,
        categories: null,
        categoryName: null,
        targetpopover: null
      },
      loaderCreditScore: false
    }
  }

  //#region PAGE LOAD

  componentDidMount() {
    //console.log('dealersettings,', this.state.dealersettings)
    this.getEnquirydetails()
  }

  async getEnquirydetails() {
    this._isMounted = true;
    try {

      let dealersettings = this.props.dealersettings;
      this.setState({ loading: true })

      if (localStorage.defaultModule === 'oem' && this.props.updateDealerSettings) {

        const doc = await window.firebase.firestore().collection('enquiries').doc(this.props.enquiryid).get();
        if (doc.exists) {
          this.props.updateDealerSettings('projectId', null)
        }
        else {
          this.props.updateDealerSettings('projectId', window.secondaryProjectId)
        }
      }

      const refData = await firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid).get();

      if (refData.exists) {

        // this.unsubscribeEnquiryQV = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
        //   .doc(this.props.enquiryid)
        //   .onSnapshot(this.onEnquiryCollectionUpdate);

        firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.props.enquiryid).set({ lastViewed: moment.utc().format("YYYY-MM-DDTHH:mmZ") }, { merge: true })

        const enquiry = Object.assign({}, refData.data());
        enquiry.documentID = refData.id;

        firestoreDB(this.props.dealersettings).firestore().collection("enquiries").doc(this.props.enquiryid).set({ lastViewed: moment().utc().toISOString() }, { merge: true })
        if (enquiry?.linkID || enquiry?.displayID) {
          this.unsubscribeLinkEnquiry = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
            .where('clientID', '==', enquiry.clientID ? enquiry.clientID : dealersettings.client.id)
            .where('linkID', '==', enquiry?.linkID ? enquiry.linkID : (enquiry?.displayID ? enquiry.displayID : ''))
            .where('isDeleted', '==', false)
            .onSnapshot(this.onLinkEnquiryCollectionUpdate);
        }

        if (enquiry.clientID && (localStorage.defaultModule === 'oem' || dealersettings?.client?.id !== enquiry.clientID)) {
          dealersettings = await CommonHelper.updateClientSettings(enquiry.clientID, Object.assign({}, this.props.dealersettings));
          //console.log('_dealersettings,', dealersettings)
        }

        if (enquiry.pipeline === 'LeadsBucket' && _.isEmpty(enquiry.ownerInbound) && !_.isEmpty(enquiry.owner))
          firestoreDB(dealersettings).firestore().doc(`enquiries/${this.props.enquiryid}`).set({ ownerInbound: enquiry.owner }, { merge: true });

        let _client = enquiry.clientID && !_.isEmpty(dealersettings) && !_.isEmpty(dealersettings.group) && !_.isEmpty(dealersettings.group.clients)
          && !_.isEmpty(dealersettings.group.clients[enquiry.clientID]) ? dealersettings.group.clients[enquiry.clientID] : dealersettings.client;

        if (_client) {
          let modules = [];
          let _userOptions = [];
          let lostReasons = [];
          let statusSettings;
          const { clientUsers, groupUsers } = this.props;
          const setttings = _client.settings;
          let _allPipelines = !_.isEmpty(setttings.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;

          if (!_.isEmpty(setttings.mandatoryFields) && !_.isEmpty(setttings.mandatoryFields.enquiry))
            this.mandatoryFields = setttings.mandatoryFields.enquiry.split(',');

          let _modules = !_.isEmpty(_allPipelines.filter(e => e.value === enquiry.pipeline)[0]) ? _allPipelines.filter(e => e.value === enquiry.pipeline)[0].modules : objModules;
          modules = !_.isEmpty(_modules) ? _modules : objModules;


          setttings.lostReasons.forEach(doc => {
            lostReasons.push({
              value: doc.value,
              active: _.isBoolean(doc.active) ? doc.active : true,
              label: doc.name,
              //subList: !_.isEmpty(doc.subList) ? doc.subList : []
            });
          });

          titles = [];
          setttings.titles && setttings.titles.forEach((doc) => {
            titles.push({
              value: doc.value,
              active: _.isBoolean(doc.active) ? doc.active : true,
              label: doc.name
            });
          });

          enquiryAllStatus = [];
          setttings.enquiryStatus && setttings.enquiryStatus.forEach((doc) => {
            enquiryAllStatus.push({
              ...doc,
              label: <div className={`badge badge-pill badge-status-${doc.value === 'open' ? 'open' : 'empty'}`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
            });
          });

          enquiryOptions = [];
          setttings.enquiryOptions && setttings.enquiryOptions.forEach(doc => {
            enquiryOptions.push({
              value: doc.value,
              label: doc.name,
            });
          });

          financeStatuses = [];
          setttings.financeStatus && setttings.financeStatus.forEach(doc => {
            financeStatuses.push({
              value: doc.value,
              active: _.isBoolean(doc.active) ? doc.active : true,
              label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
            });
          });

          afterMarketStatuses = [];
          setttings.afterMarketStatus && setttings.afterMarketStatus.forEach(doc => {
            afterMarketStatuses.push({
              value: doc.value,
              active: _.isBoolean(doc.active) ? doc.active : true,
              label: <div className={`badge badge-pill badge-status-empty`} style={{ background: (doc.color ? doc.color : '#6b717f') }}>{doc.name}</div>,
            });
          });

          enquiryOptionsDF = [];
          if (setttings.enquiryOptionsDF)
            enquiryOptionsDF = setttings.enquiryOptionsDF;

          let _selPipeline = _allPipelines.filter(e => e.value === enquiry.pipeline)[0];
          statusSettings = _selPipeline?.statusSettings;


          clientUsers && clientUsers.map(rec => {
            return _userOptions.push({
              value: rec.id,
              active: _.isBoolean(rec.active) ? rec.active : true,
              searchlabel: rec.name,
              label: <div className="drop-image">
                <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                {rec.name}
              </div>
            })
          })

          let optionsFM = [];
          let optionsAMM = [];
          if (!_.isEmpty(dealersettings.client.roles) && enquiry.clientID) {
            let roleFMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToBusinessManager === true), function (obj) {
              return obj.documentID;
            })];
            let roleAMMIds = [..._.map(dealersettings.client.roles.filter(e => e.permissions && e.permissions.accessToAfterMarketBM === true), function (obj) {
              return obj.documentID;
            })];

            let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            let _selectedFMUsers = [];
            if (!_.isEmpty(roleFMIds))
              _selectedFMUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleFMIds, v.roleID));

            _selectedFMUsers && _selectedFMUsers.map(rec => {
              return optionsFM.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                  <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                  {rec.name}
                </div>
              })
            })

            let _selectedAMMUsers = [];
            if (!_.isEmpty(roleAMMIds))
              _selectedAMMUsers = _.filter(allDealerUsers.filter(v => v.clientID === enquiry.clientID), (v) => _.includes(roleAMMIds, v.roleID));

            _selectedAMMUsers && _selectedAMMUsers.map(rec => {
              return optionsAMM.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                  <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                  {rec.name}
                </div>
              })
            })

          }


          this.setState({
            //enquiry: enquiry,
            modules: modules,
            allClientUsers: _.uniqBy(_userOptions, 'value'),
            allFinanceUsers: _.uniqBy(optionsFM, 'value'),
            allAfterMarketUsers: _.uniqBy(optionsAMM, 'value'),
            lostReasons: lostReasons,
            statusSettings: statusSettings,
            defaultStatus: _selPipeline?.status ? _selPipeline.status : null,
            defaultMakes: _selPipeline?.makes ? _selPipeline.makes : null,
            dealersettings: dealersettings
          }, () => {
            this.unsubscribeEnquiryQV = firestoreDB(this.props.dealersettings).firestore().collection('enquiries')
              .doc(this.props.enquiryid)
              .onSnapshot(this.onEnquiryCollectionUpdate);

            const _permissions = this.props.dealersettings?.rolePermissions?.permissions;
            const accessToBusinessManager = ((!_.isEmpty(_permissions) && _permissions.accessToBusinessManager) ? true : false);
            const accessToProduct = ((!_.isEmpty(_permissions) && _permissions.accessToProduct) ? true : false);
            const accessToAfterMarketTab = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketTab) ? true : false);
            const showSalesDoc = this.props.dealersettings?.client?.clientSettings?.hideSalesDoc ? false : true;

            if (showSalesDoc || accessToBusinessManager || accessToProduct || accessToAfterMarketTab)
              this.unsubscribeFiles = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${refData.id}/files`)
                .onSnapshot(this.onFilesCollectionUpdate);
          });

        }

        const logsSnapshot = await firestoreDB(this.props.dealersettings).firestore().collection('activities')
          .where('enquiryID', '==', this.props.enquiryid)
          .where('isDeleted', '==', false)
          .where('type', '==', 'log')
          //.orderBy('startDate', 'desc')
          .get()
        let logs = [];
        logsSnapshot.forEach(_log => {
          const objlogData = Object.assign({}, _log.data());
          objlogData.documentID = _log.id;
          const logVM = Object.assign({}, objActivitylog);
          for (let [key, value] of Object.entries(objlogData)) {
            logVM[key] = value;
          }
          logs.push(logVM)
        });

        let refRequirementsData = firestoreDB(dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/requirements`).where('isDeleted', '==', false)//.orderBy('addedDate', 'desc')
        this.unsubscribeRequirements = collectionData(refRequirementsData)
          .pipe(
            switchMap(requirementSnapshot => {
              const stockIds = _.uniq(requirementSnapshot.filter(item => (!_.isEmpty(item.stock))).map(item => item.stock.documentID))
              return combineLatest(
                of(requirementSnapshot),
                combineLatest(
                  stockIds.length > 0 ? stockIds.map(stockID =>
                    collectionData(window.firebase.firestore().collection('stock').where('documentID', '==', stockID)).pipe(
                      map(stockSnapshot => stockSnapshot[0] ? stockSnapshot[0] : {})
                    )
                  ) : of([])
                ),
              )
            }),
            map(([requirementSnapshot, stockSnapshot]) => {
              return requirementSnapshot.map(requirementDoc => {
                return {
                  ...requirementDoc,
                  stock: (!_.isEmpty(requirementDoc?.stock?.documentID)) ? stockSnapshot.find(a => a.documentID === requirementDoc.stock.documentID) : null,
                }
              })
            })
          )
          .subscribe(requirements => {
            this.onReqCollectionUpdate(requirements)
          });

        this._isMounted && this.setState({
          loading: false,
          //activitiesList: logs.filter(a => a.type === 'activity'),
          customerlogList: logs.filter(a => a.type === 'log'),
        })
      }
    }
    catch (error) {
      console.error(error)
      this._isMounted && this.setState({ loading: false });
    }
  }

  onLinkEnquiryCollectionUpdate = (querySnapshot) => {
    document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
    let linkEnquiries = [];
    querySnapshot.forEach((docSnapshot) => {
      const enquiry = Object.assign({}, objEnquiry);
      const _objEnquiry = Object.assign({}, docSnapshot.data());
      for (let [key, value] of Object.entries(_objEnquiry)) {
        enquiry[key] = value;
      }
      enquiry.documentID = docSnapshot.id;
      linkEnquiries.push(enquiry);
    });
    const { enquiryID } = this.state;
    linkEnquiries = _.orderBy(linkEnquiries.filter(e => e.documentID !== enquiryID), ['addedDate'], ['desc'])
    this.setState({ linkEnquiries: linkEnquiries });
  }

  onEnquiryCollectionUpdate = (docSnapshot) => {

    const enquiry = Object.assign({}, objEnquiry);
    const _objEnquiry = Object.assign({}, docSnapshot.data());
    for (let [key, value] of Object.entries(_objEnquiry)) {
      enquiry[key] = value;
    }
    enquiry.documentID = docSnapshot.id;
    let _isEmptyPipeline = false;
    if (_.isEmpty(enquiry.pipeline)) {
      enquiry.pipeline = 'LeadsBucket';
      enquiry.isEmptyPipeline = true;
      _isEmptyPipeline = true;
    }

    // if (!enquiry.counts || enquiry.counts.todayDate !== moment().format('YYYY-MM-DD')) {
    //   let objcounts = enquiry.counts ? enquiry.counts : {};
    //   objcounts.todayDate = moment().format('YYYY-MM-DD');

    //   firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(docSnapshot.id).set({ counts: objcounts }, { merge: true });
    // }

    let _enqbasicVM = Object.assign({}, enquiryBasicDetailsVM);
    _enqbasicVM.documentID = docSnapshot.id;
    for (let [key, value] of Object.entries(docSnapshot.data())) {
      if (_enqbasicVM.hasOwnProperty(key))
        _enqbasicVM[key] = value;
    }

    let _contactID = (!_.isEmpty(enquiry['contact']) && !_.isEmpty(enquiry['contact'].documentID)) ? enquiry['contact'].documentID : enquiry['contactID'];
    if (_contactID) {

      this.unsubscribeContact = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails')
        .doc(_contactID)
        .onSnapshot(this.onContactCollectionUpdate);

      this.unsubscribeOwned = firestoreDB(this.props.dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`)
        .onSnapshot(this.onOwnedCollectionUpdate);
    }

    const { dealersettings } = this.state;

    let _client = enquiry.clientID && !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.group) &&
      !_.isEmpty(dealersettings.group.clients) &&
      dealersettings.group.clients[enquiry.clientID] ? dealersettings.group.clients[enquiry.clientID] : dealersettings.client;

    const setttings = _client?.settings;
    let _allPipelines = !_.isEmpty(setttings?.allPipelines) ? setttings.allPipelines : dealersettings.allPipelines;

    let defaultStatus;
    let statusSettings;
    let defaultMakes;
    if (enquiry.pipeline) {
      let _selectedPipeline = _allPipelines.filter(e => e.value === enquiry.pipeline)[0];
      statusSettings = _selectedPipeline && _selectedPipeline.statusSettings;
      defaultStatus = _selectedPipeline?.status;
      defaultMakes = _selectedPipeline.makes;
    }


    this.setState({
      enquiry: enquiry,
      enquiryBasicVM: _enqbasicVM,
      defaultStatus: defaultStatus,
      statusSettings: statusSettings,
      defaultMakes: defaultMakes,
      isEmptyPipeline: _isEmptyPipeline
    });
  }

  onContactCollectionUpdate = (docSnapshot) => {

    const _contact = Object.assign({}, objContact);
    const objcontactData = Object.assign({}, docSnapshot.data());
    for (let [key, value] of Object.entries(objcontactData)) {
      if (_contact.hasOwnProperty(key))
        _contact[key] = value;
    }
    _contact.documentID = docSnapshot.id;

    if (!_.isEmpty(_contact.amsMe) && !_.isEmpty(_contact.amsMe.meID)) {
      this.unsubscribeAmsMe = window.firebase.firestore()
        .collection(`amsmeUsers`)
        .doc(_contact.amsMe.meID)
        .onSnapshot(this.onAmsMeCollectionUpdate);
    }

    //	console.log('onContactCollectionUpdate', contact, objcontactData)
    this.setState({
      contact: _contact
    });
  }

  onAmsMeCollectionUpdate = docSnapshot => {
    if (!docSnapshot.exists) {
      return;
    }

    let amsmeUser = Object.assign({}, docSnapshot.data());
    amsmeUser.documentID = docSnapshot.id;

    this.setState({ amsmeUser: amsmeUser });
  };

  onFilesCollectionUpdate = (querySnapshot) => {
    let _list = [];
    querySnapshot.forEach((data) => {
      const objData = Object.assign({}, data.data());
      objData.isDeleted = objData.isDeleted ? true : false;
      objData.documentID = data.id;
      _list.push(objData);
    });

    _list = _.orderBy(_list, ['addedDate'], ['desc'])
    this.setState({ files: _list });
  }

  onReqCollectionUpdate = (querySnapshot) => {
    //console.log('onCollectionUpdate', querySnapshot)
    const requirements = [];
    querySnapshot.forEach((_requirement) => {
      const _stock = Object.assign({}, objRequirementStock);
      const requirement = Object.assign({}, objRequirement);
      const objData = Object.assign({}, _requirement);
      for (let [key, value] of Object.entries(objData)) {
        requirement[key] = value;
      }
      requirements.push(requirement);
    });

    this.setState({ requirements: _.orderBy(requirements, ["addedDate"], ["desc"]) }, () => { this.saveReqCounts(requirements); });
  }

  saveReqCounts = (requirements) => {
    const { enquiry } = this.state;
    let _reCounts = requirements.length;
    let _soldCounts = requirements.filter(m => m.isSold).length;

    if (enquiry && enquiry.documentID && (enquiry.requirementCount !== _reCounts || enquiry.soldCount !== _soldCounts)) {
      const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiry.documentID);
      updateRef.update({
        requirementCount: _reCounts,
        soldCount: _soldCounts,
        modifiedFrom: 'web'
      })
    }
  }

  onOwnedCollectionUpdate = (querySnapshot) => {
    document.getElementsByTagName('body')[0].classList.add('enquiry-bg');
    const ownedVehicles = [];
    querySnapshot.forEach((_owned) => {
      const owned = Object.assign({}, objOwnedVehicle);
      const objOwnedData = Object.assign({}, _owned.data());
      for (let [key, value] of Object.entries(objOwnedData)) {
        owned[key] = value;
      }
      owned.documentID = _owned.id;
      ownedVehicles.push(owned);
    });

    this.setState({
      ownedVehicles: _.orderBy(ownedVehicles, ["modifiedDate"], ["desc"])
    });
  }


  componentWillUnmount() {
    this._isMounted = false;
    this.unsubscribeRequirements && this.unsubscribeRequirements.unsubscribe();
    this.unsubscribeEnquiryQV && this.unsubscribeEnquiryQV();
    this.unsubscribeOwned && this.unsubscribeOwned();
    this.unsubscribeContact && this.unsubscribeContact();
    this.unsubscribeAmsMe && this.unsubscribeAmsMe();
    this.unsubscribeLinkEnquiry && this.unsubscribeLinkEnquiry();
    this.unsubscribeFiles && this.unsubscribeFiles();
    // this.setState({
    //   enquiry: objEnquiry, activitiesList :[],
    //   customerlogList :[], loading: false
    // });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('quick view receive props', nextProps)
    /*if (nextProps.enquiryid) {

      const refData = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(nextProps.enquiryid);
      refData.get().then((doc) => {
        if (doc.exists) {
          const objData = Object.assign({}, doc.data());
          objData.documentID = doc.id;
          //objData.dob = objData.dob !== null ? moment(new Date(objData.dob + 'Z')) : null;           

          const fields = Object.assign({}, objEnquiry);
          for (let [key, value] of Object.entries(objData)) {
            fields[key] = value;
          }

          this.setState({ enquiry: fields });

        } else {
          console.error("No such document!");
        }
      });
    }
    else {
      this.setState({ enquiry: objEnquiry });
    }*/
  }

  //#endregion

  //#region ENQUIRY CHANGES
  errorChange = (key) => {
    let errors = Object.assign({}, this.state.errors);
    let fields = Object.assign({}, this.state.enquiry);
    let errorClass = 'input_error';
    if (errors !== {}) {
      if (!fields[key] && this.mandatoryFields && this.mandatoryFields.indexOf(key) >= 0)
        errors[key] = errorClass;
      else
        errors[key] = '';
      this.setState({ errors: errors });
    }
  }

  updateFilter = filter => {
    this.setState({ filter })
  }

  updateStageEnquiry = () => {
    const objData = Object.assign({}, this.state.enquiry);
    const objStage = Object.assign({}, objStageEnquiry);

    //Remove empty value from object
    for (var propName in objData) {
      if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {

      }
      else {
        if (propName === 'addedBy') {
          objStage.addedby = objData[propName] && this.props.clientUsers.filter(e => e.id === objData[propName]).length > 0 ? this.props.clientUsers.filter(m => m.id === objData[propName])[0].name : '--';
        }
        if (propName === 'modifiedBy') {
          objStage.modifiedby = objData[propName] && this.props.clientUsers.filter(e => e.id === objData[propName]).length > 0 ? this.props.clientUsers.filter(m => m.id === objData[propName])[0].name : '--';
        }
        if (propName === 'contact') {
          objStage.email = objData[propName].email;
          objStage.phone = objData[propName].phone ? ((objData[propName].phoneCode ? objData[propName].phoneCode : '') + objData[propName].phone) : '';
          objStage.name = CommonHelper.displayContactName([], objData[propName])
        }
        if (propName === 'requirement') {
          objStage.saletype = objData[propName].saleType;
          objStage.vehiclemodel = objData[propName] ? objData[propName].stock ? (objData[propName].stock.make + ' ' + objData[propName].stock.model) : (objData[propName].make + ' ' + objData[propName].model) : '';

        }
        if (propName === 'isFav' || propName === 'origin' ||
          propName === 'pipeline' || propName === 'stage' || propName === 'status' ||
          propName === 'addedDate' || propName === 'modifiedDate' ||
          propName === 'label' || propName === 'stageDate' ||
          propName === 'verifiedBy' || propName === 'verifiedDate' || propName === 'displayID' ||
          propName === 'isDeleted' || propName === 'isNewEnquiry')
          objStage[propName] = objData[propName];
      }
    }
    objStage.id = this.props.enquiryid;

    //update dashboard counts
    this.props.reloadData && this.props.reloadData();
    this.updateEnq({
      ...objData,
      id: objData.documentID
    });

  }

  handleSelectChange = (e, data) => {
    let state = Object.assign({}, this.state.enquiry);
    state[data.name] = data.value;
    this.setState({ enquiry: state });
  }

  handleReactSelectChange = (e, data) => {
    if (['financeStatus', 'afterMarketStatus'].includes(data.name)) {
      this.handleFinanceStatus((e?.value || ''), data.name)
    }
    else {
      this.updateEnquiryInfo(data.name, (e ? e.value : ''))
    }
  }

  handleDateChange = (moment) => {
    let state = Object.assign({}, this.state.enquiry);
    state.purchaseIntention = moment.format('MMM YYYY');
    this.setState({ enquiry: state });
  }

  handleFirebaseDateChange = (val, name) => {
    if (val) val = window.firebase.firestore.Timestamp.fromDate(moment(moment(val).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm'))._d);

    this.updateEnquiryInfo(name, val);
  };

  onCurrencyChange = (name, e) => {
    //{formattedValue: "$9,856", value: "9856", floatValue: 9856}
    let state = Object.assign({}, this.state.enquiry);
    state[name] = e.floatValue;
    this.setState({ enquiry: state }, () => { this.errorChange(name) });

  }

  cancelEnquiryField = (name, val) => {
    let state = Object.assign({}, this.state.enquiry);
    state[name] = val;
    this.setState({ enquiry: state }, () => { this.errorChange(name); });
  }

  saveEnquiryInfo = (data) => {
    this.setState({ enquiry: data }, () => { this.updateStageEnquiry(); });
  }

  updateEnquiryInfo = async (name, val, _isReopen) => {
    let state = Object.assign({}, this.state.enquiry);
    const { dealersettings, requirements } = this.state;
    if (name !== 'isFav') {
      state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
      state.modifiedDate = window.firebase.firestore.Timestamp.now();
    }

    let objData = {};


    if (name === 'status') {

      if (state.isNewEnquiry)
        objData.isNewEnquiry = false;

      if (val === enquiryStatus.WON || val === enquiryStatus.CLOSED)
        objData.wonDate = state.modifiedDate;

      if (val === enquiryStatus.DELIEVERD)
        objData.deliveredDate = state.modifiedDate;

      if (val === enquiryStatus.LOST) {
        objData.lostDate = state.modifiedDate;


        const lostApprovalEnabled = ((!_.isEmpty(dealersettings) &&
          !_.isEmpty(dealersettings.client) &&
          !_.isEmpty(dealersettings.client.clientSettings) &&
          dealersettings.client.clientSettings.lostApproval) ? true : false);

        const _permissions = ((!_.isEmpty(dealersettings) &&
          !_.isEmpty(dealersettings.rolePermissions) &&
          !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);
        const lostApproval = ((!_.isEmpty(_permissions) && _permissions.lostApproval) ? true : false);

        if (!state.isAfterSales && lostApprovalEnabled && !lostApproval) {
          val = enquiryStatus.PENDINGLOST;
          objData.lastStatus = state.status;
        }
      }

      if (val === enquiryStatus.PROSPECTLOST) {
        objData.lostDate = state.modifiedDate;
      }

      if (val === enquiryStatus.OPEN) {
        objData.wonDate = null;
        objData.lostDate = null;
        objData.lostReason = null;
        objData.lostSubReason = null;
        objData.lostNotes = '';
        objData.deliveryDate = null;
      }
      let __status = val === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : val;
      objData = this.handleMoveStageForStatusChange(__status, objData);
      state = this.handleMoveStageForStatusChange(__status, state);

      if (val) {
        let _dynamicStatus = Object.assign({}, state.dynamicStatus)
        _dynamicStatus[val] = window.firebase.firestore.Timestamp.now();
        objData['dynamicStatus'] = _dynamicStatus;
      }

      if ((val === enquiryStatus.WON || val === enquiryStatus.DELIEVERD) && state?.requirement?.documentID) {
        const _requirement = Object.assign({}, requirements.filter((e) => e.documentID === state.requirement.documentID)[0]);
        if (!_.isEmpty(_requirement)) objData.requirement = _requirement;
      }

    }

    // if (name === 'financeBM' && !state.isFinanceRequired) {
    //   objData.isFinanceRequired = true;
    //   if (val) {
    //     const { documentID, clientID, displayID, contact } = this.state.enquiry;
    //     let _reqObject = {
    //       "userID": val,
    //       "notification": {
    //         title: 'Finance Enquiry',
    //         body: displayID + (contact ? ' - ' + CommonHelper.displayContactName([], contact) : ''),
    //         type: 'finance',
    //         recordID: documentID,
    //         clientID: clientID
    //       }
    //     }
    //     const genericNotify = window.firebase.functions().httpsCallable('generic-notify');
    //     genericNotify(_reqObject)
    //   }
    // }

    if (name === 'status' && val === enquiryStatus.WON && state.isAfterSales) {
      state[name] = enquiryStatus.CLOSED;
      objData[name] = enquiryStatus.CLOSED;
    }
    else {
      state[name] = val;
      objData[name] = val;
    }

    objData.modifiedBy = state.modifiedBy;
    objData.modifiedDate = state.modifiedDate;
    objData.modifiedFrom = 'web';

    this.setState({ enquiry: state }, () => { this.updateStageEnquiry(); });
    const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
    await updateRef.set(objData, { merge: true }).then((docRef) => {

      //this.updateStageEnquiry();
      //update dashboard counts
      this.props.reloadData && this.props.reloadData();

      if (name === 'enquiryType' && val && (val.toLowerCase().indexOf('walk') >= 0 || val.toLowerCase().indexOf('drive') >= 0)) {
        this.handleSaveWalkin(this.props.enquiryid, state.clientID)
      }

      if (name === 'status') {

        if (this.props.updatenavbar) {
          const { contact } = this.state.enquiry;
          this.props.updatenavbar(
            {
              id: this.props.enquiryid,
              status: val,
              name: (!_.isEmpty(contact)) ? (CommonHelper.displayContact([], contact, '--')) : 'No Name'
            });
        }

        if (this.props.handleStatusUpdateDone) {
          this.props.handleStatusUpdateDone(this.state.enquiry);
        }

        if (val === enquiryStatus.WON || val === enquiryStatus.CLOSED || val === enquiryStatus.DELIEVERD) {
          CommonHelper.savePrimaryFieldsToEnquiry(state, this.props.enquiryid, null, this.props.dealersettings);
        }

        if (state.contactID && (val === enquiryStatus.WON || val === enquiryStatus.CLOSED || val === enquiryStatus.OPEN)) {
          const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
          updateRepeatCustomer({ 'contactID': state.contactID, 'projectId': state?.projectId ? state.projectId : null })
        }

        if (name === 'status' && (_isReopen || val === enquiryStatus.WON || val === enquiryStatus.LOST || val === enquiryStatus.DELIEVERD)) {
          this.checkTradeinCompleted(val, _isReopen);
        }

        if (name === 'status' && (val === enquiryStatus.PROSPECTLOST || val === enquiryStatus.LOST)) {
          CommonHelper.removeBatchNumberFromEnquiry(state, enquiryOptionsDF, this.props.dealersettings);
        }

      }
    }).catch((error) => {
      console.error("Error updating enquiries: ", error);
    });

  }

  checkTradeinCompleted = async (val, _isReopen) => {
    const { dealersettings } = this.props;
    if (_isReopen) {
      enquiryHelper.reopenAllCompletedTradeinPro(dealersettings, this.props.enquiryid);
    } else {
      const modifyTradeInProStatusAccess = (dealersettings?.rolePermissions?.permissions?.modifyTradeInProStatusAccess ? true : false);
      if (modifyTradeInProStatusAccess) {
        let tradeIns = [];
        let _status = (_isReopen ? [tipStatus.WON, tipStatus.LOST] : [tipStatus.COMPLETED])
        const snapshots = await firestoreDB(this.props.dealersettings).firestore().collection('tradeins')
          .where('enquiryID', '==', this.props.enquiryid)
          .where('status', 'in', _status)
          .where('isDeleted', '==', false)
          .get();

        if (snapshots.size > 0) {
          snapshots.docs.forEach((doc) => {
            tradeIns.push(doc.data())
          });
        }

        if (!_.isEmpty(tradeIns.filter(e => !_.isEmpty(e.tradeinProID))[0])) {
          const mandTradeinStatusonWon = dealersettings?.client?.clientSettings?.mandTradeinStatusonWon ? true : false;
          this.setState({
            completedTradeinsList: {
              show: true,
              isMandatory: (mandTradeinStatusonWon || (val === enquiryStatus.WON || _isReopen ? false : true)),
              isReopen: _isReopen
            }
          })
        }
      }
    }

  }

  handleMoveStageForStatusChange = (_status, state) => {
    const { statusSettings } = this.state;
    const { stageHistory, requirement } = this.state.enquiry;

    let _make = requirement?.stock?.make ? requirement?.stock?.make : requirement?.make;
    let _saleType = requirement?.saleType;
    let _statusArray = !_.isEmpty(statusSettings) ? statusSettings.filter(e => e.status === _status) : []
    let data = null;

    if (!_.isEmpty(_statusArray)) {
      if (!_.isEmpty(_make) && !_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0])
        data = _statusArray.filter(e => !_.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.salesType.includes(_saleType) && e.make.includes(_make))[0]
      else if (!_.isEmpty(_make) && _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0])
        data = _statusArray.filter(e => _.isEmpty(e.salesType) && !_.isEmpty(e.make) && e.make.includes(_make))[0]
      else if (!_.isEmpty(_saleType) && _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0])
        data = _statusArray.filter(e => !_.isEmpty(e.salesType) && _.isEmpty(e.make) && e.salesType.includes(_saleType))[0];
      else if (_statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0])
        data = _statusArray.filter(e => _.isEmpty(e._make) && _.isEmpty(e.salesType))[0];
    }
    if (!_.isEmpty(data) && data.pipeline && data.stage) {
      let _stageHistory = Object.assign([], stageHistory);
      let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
      if (!_.isEmpty(_stageData)) {
        _stageHistory.push(_stageData);
        state.stageHistory = Object.assign([], _stageHistory);
        state.pipeline = data.pipeline;
        state.stage = data.stage;
        state.stageDate = window.firebase.firestore.Timestamp.now();
      }

    }
    state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
      {
        date: window.firebase.firestore.Timestamp.now(),
        status: _status,
        user: localStorage.uid
      }
    )
    return state;
  }

  handleRejectLostStatusChange = (state) => {
    const { stageHistory, pipeline, stage } = this.state.enquiry;

    let statusSettings = this.state.statusSettings;

    if (_.isEmpty(statusSettings)) {
      let _previousStage = !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null
      let _selPipeline = !_.isEmpty(_previousStage) && this.props.dealersettings.allPipelines.filter(e => e.value === _previousStage.pipeline)[0];
      statusSettings = _selPipeline && _selPipeline.statusSettings;
    }

    let _statusSettingData = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === 'lost' && e.pipeline === pipeline && e.stage === stage)[0];

    let data = !_.isEmpty(_statusSettingData) && !_.isEmpty(stageHistory) ? _.orderBy(stageHistory, ["date"], ["desc"])[1] : null;

    if (!_.isEmpty(data) && data.pipeline && data.stage) {
      let _stageHistory = Object.assign([], stageHistory);
      let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
      if (!_.isEmpty(_stageData)) {
        stageHistory.push(_stageData);
        state.stageHistory = Object.assign([], _stageHistory);
        state.pipeline = data.pipeline;
        state.stage = data.stage;
        state.stageDate = window.firebase.firestore.Timestamp.now();
      }

      return state;
    }
    else
      return state;
  }

  handleStatusOptions = (status) => {
    if (status === enquiryStatus.OPEN)
      this.handleEnquiryOpen();
    else if (status === enquiryStatus.WON)
      this.handleEnquiryWon();
    else if (status === enquiryStatus.LOST)
      this.handleEnquiryLost();
    else if (status === enquiryStatus.DELIEVERD)
      this.handleEnquiryDelivered();
    else if (status === enquiryStatus.PROSPECTLOST)
      this.handleEnquiryProspectLost();
    else if (status)
      this.handleDynamicStatus(status)
  }

  updateStatus = (name, val, _isReopen) => {
    if (name === 'status') {
      if (val === enquiryStatus.WON)
        this.handleEnquiryWon()
      else if (val === enquiryStatus.LOST)
        this.handleEnquiryLost()
      else if (val === enquiryStatus.DELIEVERD)
        this.handleEnquiryDelivered()
      else if (val === enquiryStatus.PROSPECTLOST)
        this.handleEnquiryProspectLost();
      else if (val === enquiryStatus.OPEN)
        this.handleEnquiryOpen()
      else if (val)
        this.handleDynamicStatus(val)
    }
    else {
      this.updateEnquiryInfo(name, val);
    }
  }

  handleLostReasonsClose = data => {
    if (!_.isEmpty(data)) {
      this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
        this.handleSoldRequirements(requirement.documentID, false, false);
      });
      this.saveEnquiryField('status', enquiryStatus.LOST)
    }
    this.setState({
      lostModal: { show: false },
    });
  };

  handleLostReasonsOpen = () => {
    this.setState({
      lostModal: {
        show: true
      }
    });
  }

  handleDeliveryOptionClose = data => {
    if (!_.isEmpty(data)) {
      this.saveEnquiryField('status', enquiryStatus.DELIEVERD);
    }
    this.setState({
      deliveryModal: { show: false },
    });
  };

  handleDeliveryOptionOpen = () => {
    this.setState({
      deliveryModal: {
        show: true
      },
    });
  }

  handleEnquiryOptionClose = (status) => {
    if (!_.isEmpty(status)) {
      let _enquiryModal = Object.assign({}, this.state.enquiryModal)

      if (status === enquiryStatus.OPEN)
        this.handleEnquiryOpen();
      else if (status === enquiryStatus.WON)
        this.handleEnquiryWon();
      else if (status === enquiryStatus.LOST)
        this.handleEnquiryLost();
      else if (status === enquiryStatus.DELIEVERD)
        this.handleEnquiryDelivered();
      else if (status === enquiryStatus.PROSPECTLOST)
        this.handleEnquiryProspectLost();
      else if (status === 'addContact')
        this.handleMandatoryContactOpen(status)
      else if (status === 'requirement')
        this.handleRequirementOpen();
      else if (status === 'leadConvert')
        this.handleLeadConvertShow();
      else if (status === 'PipelineClose' && _enquiryModal.newStatus == 'PipelineClose' && _enquiryModal.newStatusValue) {
        this.handlePipelineClose(_enquiryModal.newStatusValue)
      }
      else if (['financeStatus', 'afterMarketStatus'].includes(status) && ['financeStatus', 'afterMarketStatus'].includes(_enquiryModal.newStatus) && _enquiryModal.newStatusValue)
        this.handleFinanceStatus(_enquiryModal.newStatusValue, status)
      else if (status === 'stage')
        this.setState({ newStage: status })
      else if (status === 'activity') {
        //this.handleDynamicStatus(status)
      }
      else {
        this.handleDynamicStatus(status)
      }

    }
    this.setState({
      enquiryModal: {
        show: false,
        errors: null,
        mandatoryFields: null,
        newStatus: null,
        newStatusValue: null,
        ismodifyStatusDate: false,
        isModifyInboundOwner: false
      }
    });
  };

  handleStageUpdate = () => {
    this.setState({ newStage: null })
  }

  handleSaveWalkin = (enquiryID, _clientID) => {
    this.isLoading = true;
    const { dealersettings } = this.props;

    let userID = dealersettings ? dealersettings.id : '';
    let clientID = dealersettings ? dealersettings.client.id : '';
    let currenDate = window.firebase.firestore.Timestamp.now();

    const objData = Object.assign({}, objWalkIn);
    objData.documentID = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc().id;
    objData.addedBy = userID;
    objData.addedDate = currenDate;
    objData.modifiedBy = userID;
    objData.modifiedDate = currenDate;
    objData.clientID = _clientID ? _clientID : clientID;
    objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;
    objData.enquiryID = enquiryID;
    objData.walkinDate = currenDate;

    const refOnjData = firestoreDB(dealersettings).firestore().collection(`enquiries/${enquiryID}/walkins`).doc(objData.documentID);
    refOnjData.set(objData).then((docRef) => {
      if (this.state.enquiry && (this.state.enquiry.bdcStatus === 'allocated' || this.props.enquiry.bdcStatus === 'noshow')) {
        firestoreDB(dealersettings).firestore().doc(`enquiries/${enquiryID}`).set({
          bdcStatus: 'show'
        }, { merge: true })
      }
      CommonHelper.logFirstWalkin(enquiryID, currenDate, dealersettings);
    }).catch((error) => {
      console.error("Error updating walkin_details: ", error);
    });

  }

  getMandatoryFieldsByType = (type, _newStatus) => {
    const { dealersettings } = this.state;
    const { status, isAfterSales } = this.state.enquiry;
    let _mandEnquiryStatus;
    let _response = [];
    if (!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.settings) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields.status))
      _mandEnquiryStatus = dealersettings.client.settings.mandatoryFields.status;

    if (!_.isEmpty(_mandEnquiryStatus) && !isAfterSales && (status || _newStatus)) {
      let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === (_newStatus ? _newStatus : status))[0];

      _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

      if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type]) {
        _response = _.uniq(_newStatusMandatoryFields[type].split(','));
      }
    }
    else {
      let _newStatusMandatoryFields = [];
      _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields));

      if (!_.isEmpty(_newStatusMandatoryFields) && _newStatusMandatoryFields[type])
        _response = _.uniq(_newStatusMandatoryFields[type].split(','))

    }
    return _response;
  }

  isValidForFinanceStatusUpdate = async (_newStatus, name, origin) => {
    const { dealersettings } = this.state;

    let _mandEnquiryStatus;
    let _isValid = true;
    if (!_.isEmpty(dealersettings?.client?.settings?.mandatoryFields?.[name]))
      _mandEnquiryStatus = dealersettings.client.settings.mandatoryFields[name];

    if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
      let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

      if (!_.isEmpty(_newStatusMandatoryFields)) {
        _isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : name), _newStatus)
      }
    }

    return _isValid;
  }

  isValidForStatusUpdate = async (_newStatus, origin, _previousStatus) => {
    const { dealersettings } = this.state;
    const { isAfterSales } = this.state.enquiry;

    if (isAfterSales && _newStatus === enquiryStatus.WON)
      return true;

    let _mandEnquiryStatus;
    let _isValid = true;
    if (!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.settings) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields.status))
      _mandEnquiryStatus = dealersettings.client.settings.mandatoryFields.status;

    if (!_.isEmpty(_mandEnquiryStatus) && _newStatus) {
      let _newStatusMandatoryFields = _mandEnquiryStatus.filter(e => e.name === _newStatus)[0];

      if (_previousStatus)
        _newStatusMandatoryFields = this.mergeNewAndPreviousStatus(_mandEnquiryStatus, Object.assign({}, _newStatusMandatoryFields), _previousStatus);

      _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);

      if (!_.isEmpty(_newStatusMandatoryFields)) {
        _isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))
      }
    }
    else {
      let _newStatusMandatoryFields = [];
      _newStatusMandatoryFields = this.mergeStatusAndStage(Object.assign({}, _newStatusMandatoryFields), _newStatus);

      _isValid = await this.handleMandatoryPermission(_newStatusMandatoryFields, (origin ? origin : _newStatus))

    }

    const isStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, _newStatus)
    if ((isStatusOpen || _newStatus === enquiryStatus.OPEN) && !_isValid)
      this.setState({ isAccessToReOpen: true });

    return _isValid;
  }

  isValidForStageUpdate = async (newPipeline, newStage, origin, originValue) => {
    const _newStage = newPipeline + '-' + newStage;
    const { pipeline, stage } = this.state.enquiry;
    const _oldStage = pipeline + '-' + stage;
    const { dealersettings } = this.state;

    let _mandPiplines;
    let _isValid = true;
    if (!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.settings) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields.pipeline))
      _mandPiplines = dealersettings.client.settings.mandatoryFields.pipeline;

    if (!_.isEmpty(_mandPiplines)) {

      let __newPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];
      let __oldPipeline = _mandPiplines.filter(e => e.name === _oldStage)[0];

      if (!_.isEmpty(__newPipeline)) {

        if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
          for (let [key] of Object.entries(fieldsData)) {
            __newPipeline[key] = ((__newPipeline[key] ? __newPipeline[key] : "") + (__oldPipeline[key] ? (__newPipeline[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
          }
        }
        //  console.log('isValidForStageUpdate', '==>', _newStage, '==>', _oldStage, '==>', __newPipeline, '==>', __oldPipeline);
        _isValid = await this.handleMandatoryPermission(__newPipeline, (origin ? origin : newStage), originValue)
        //  console.log('isValidForStageUpdate-Response', _isValid);
      }
      else if (!_.isEmpty(__oldPipeline) && _oldStage !== _newStage) {
        for (let [key] of Object.entries(fieldsData)) {
          __oldPipeline[key] = (__oldPipeline[key] ? __oldPipeline[key] : "");
        }
        //  console.log('isValidForStageUpdate', '==>', _oldStage, '==>', __oldPipeline);
        _isValid = await this.handleMandatoryPermission(__oldPipeline, (origin ? origin : newStage), originValue)
        //  console.log('isValidForStageUpdate-Response', _isValid);
      }
    }
    return _isValid;
  }

  mergeNewAndPreviousStatus = (_mandEnquiryStatus, _newMandatoryStatus, _prevStatus) => {
    if (!_.isEmpty(_mandEnquiryStatus)) {
      let __oldPipeline = _mandEnquiryStatus.filter(e => e.name === _prevStatus)[0];

      if (!_.isEmpty(__oldPipeline)) {
        for (let [key] of Object.entries(fieldsData)) {
          _newMandatoryStatus[key] = ((_newMandatoryStatus[key] ? _newMandatoryStatus[key] : "") + (__oldPipeline[key] ? (_newMandatoryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
        }
      }
    }
    return _newMandatoryStatus;
  }

  mergeStatusAndStage = (_mandEnquiryStatus, _newStatus) => {

    const { pipeline, stage } = this.state.enquiry;
    const _newStage = pipeline + '-' + stage;
    const { dealersettings } = this.state;

    let _mandPiplines;
    let _isValid = true;
    if (!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.settings) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields) &&
      !_.isEmpty(dealersettings.client.settings.mandatoryFields.pipeline))
      _mandPiplines = dealersettings.client.settings.mandatoryFields.pipeline;

    if (!_.isEmpty(_mandPiplines)) {
      let __oldPipeline = _mandPiplines.filter(e => e.name === _newStage)[0];

      if (!_.isEmpty(__oldPipeline)) {
        for (let [key] of Object.entries(fieldsData)) {
          _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__oldPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __oldPipeline[key] : __oldPipeline[key]) : ""));
        }
      }

      if (_newStatus) {
        const { statusSettings } = this.state;
        let _statusMndry = !_.isEmpty(statusSettings) && statusSettings.filter(e => e.status === _newStatus)[0];
        const _newStatusPipelineName = !_.isEmpty(_statusMndry) ? _statusMndry.pipeline + '-' + _statusMndry.stage : null;
        let __newStatusPipeline = !_.isEmpty(_newStatusPipelineName) && _mandPiplines.filter(e => e.name === _newStatusPipelineName)[0];
        if (!_.isEmpty(__newStatusPipeline)) {
          for (let [key] of Object.entries(fieldsData)) {
            _mandEnquiryStatus[key] = ((_mandEnquiryStatus[key] ? _mandEnquiryStatus[key] : "") + (__newStatusPipeline[key] ? (_mandEnquiryStatus[key] ? ',' + __newStatusPipeline[key] : __newStatusPipeline[key]) : ""));
          }
        }
      }

    }

    return _mandEnquiryStatus;
  }

  //#endregion

  //#region CONTACT CHANGES

  handleMandatoryContactOpen = async (title) => {
    if (title === 'addContact' ? await this.isValidEnquiryForAddContact() : true) {
      let _manFields = this.getMandatoryFieldsByType('contact');
      //console.log('handleMandatoryContactOpen', _manFields)
      this.setState({
        contactModal: {
          show: true,
          showSearch: true,
          title: title,
          clsActive: 'overlay-modal active',
          mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
        },
      });
    }

  }

  isValidEnquiryForAddContact = async () => {

    let _manFields = this.getMandatoryFieldsByType('enquiry');
    //console.log('_manFields,', _manFields)
    let _requiredFields = !_.isEmpty(_manFields) ?
      _.filter(_manFields, (v) => (!v.startsWith('is') || v.startsWith('isFinanceRequired') || v.startsWith('isFav'))) :
      null;
    if (!_.isEmpty(_requiredFields)) {
      let _objFields = Object.assign({}, {
        enquiry: _requiredFields.join(",")
      });
      return await this.handleMandatoryPermission(_objFields, 'addContact')

    }
    else
      return true;

  }

  handleContactOpen = async (title, _manFields) => {
    //	console.log('handleContactOpen', _manFields)
    if (title === 'addContact' ? await this.isValidEnquiryForAddContact() : true) {
      setTimeout(() => {
        this.setState({
          contactModal: {
            show: true,
            showSearch: true,
            title: title,
            clsActive: 'overlay-modal active',
            mandatoryFields: !_.isEmpty(_manFields) ? _manFields : [],
          },
        });
      }, 200);
    }

  }

  handleContactClose = (ID, data, isNew) => {
    const { enquiryid } = this.props;
    if (data === 'delete') {


      const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiryid);
      updateRef.update(
        {
          isDeleted: true,
          modifiedBy: localStorage.uid,
          modifiedDate: window.firebase.firestore.Timestamp.now(),
          modifiedFrom: 'web'
        }
      );

    } else if (ID) {
      //const refData = firestoreDB(this.props.dealersettings).firestore().collection('contactDetails').doc(ID);
      //refData.get().then((doc) => {
      if (data) {
        const objcontactData = Object.assign({}, data);
        const contact = Object.assign({}, objContact);
        const _contact = Object.assign({}, contactBasicDetailsVM);
        for (let [key, value] of Object.entries(objcontactData)) {
          contact[key] = value;
          if (_contact.hasOwnProperty(key))
            _contact[key] = value;
        }
        contact.documentID = ID;

        let state = Object.assign({}, this.state.enquiry);
        state['isContact'] = true;
        state['contact'] = _contact;
        state.contactID = ID;

        if (state.isNewEnquiry)
          state.isNewEnquiry = false;

        state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();
        state.modifiedFrom = 'web';

        this.setState({ contact: contact, enquiry: state });
        let _isContact = state.isContact;
        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(enquiryid);
        updateRef.update(
          {
            isContact: true,
            contact: state.contact,
            contactID: state.contactID,
            isNewEnquiry: state.isNewEnquiry,
            modifiedBy: state.modifiedBy,
            modifiedDate: state.modifiedDate,
            modifiedFrom: 'web',
            areaCode: objcontactData?.areaCode ? objcontactData.areaCode : null,
            prevPurchased: objcontactData?.prevPurchased ? objcontactData.prevPurchased : null
          }
        ).then((docRef) => {

          this.updateEnq(state);

        }).catch((error) => {
          console.error("Error updating enquiries: ", error);
        });

      } else {
        console.error("No such document!");
      }
      //});
    }

    this.setState({
      contactModal:
      {
        show: false,
        showSearch: false,
        title: '',
        clsActive: ''
      }
    });
  }
  //#endregion

  //#region MANDATORY PERMISSION
  handleMandatoryPermission = async (objFields, _newStatus, _newStatusValue) => {
    let isBreak = false;
    if (!_.isEmpty(objFields)) {
      const _fieldsData = Object.assign({}, fieldsData);
      for (let [key, value] of Object.entries(objFields)) {
        _fieldsData[key] = value;
      }
      const { contact } = this.state;
      for (let [key, value] of Object.entries(_fieldsData)) {

        if (key === 'contact' && !contact?.businessContact && !_.isEmpty(value)) {
          if (!this.checkMandatoryContact(value)) {
            isBreak = true;
            break;
          }
        }

        if (key === 'businessContact' && contact?.businessContact && !_.isEmpty(value)) {
          if (!this.checkMandatoryContact(value)) {
            isBreak = true;
            break;
          }
        }

        if (key === 'enquiry' && !_.isEmpty(value)) {
          let _response = await this.checkMandatoryEnquiry(value, _newStatus, _newStatusValue)
          if (_response === false) {
            isBreak = true;
            break;
          }
        }

        // if (key === 'company') {
        // 	Swal.fire('Please add company')
        // 	isBreak = true;
        // 	break;
        // }
      }
    }
    if (isBreak)
      return false;
    else
      return true;
  }

  checkMandatoryContact = value => {
    const { isContact } = this.state.enquiry;
    const { modules } = this.state;
    let _mandatoryFields = value ? _.uniq(value.split(',')) : [];

    if (!isContact && !_.isEmpty(modules) && modules.contact) {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, 'Please add contact'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
        cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
      }).then(result => {
        if (result.value) {
          //this.handleEnquiryExpand();
          this.handleContactOpen('addContact', _mandatoryFields);
        }
      });

      return false;

    }
    else if (!_.isEmpty(_mandatoryFields)) {
      const ObjCustomer = Object.assign({}, this.state.contact);
      let msg;
      _mandatoryFields.some((el, index) => {
        if (el.trim() === 'licenseFrontURL') {
          if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
            msg = 'Please upload license front image';
            return true;
          }
        } else if (el.trim() === 'licenseBackURL') {
          if (!ObjCustomer[el] && !ObjCustomer.isDLScan) {
            msg = 'Please upload license back image';
            return true;
          }
        } else if (el.trim() === 'phoneORemail') {
          if (!ObjCustomer['phone'] && !ObjCustomer['email']) {
            msg = 'Please enter Phone or Email';
            return true;
          }
        } else if (el.trim() === 'phoneORemailORlicenseNo') {
          if (!ObjCustomer['phone'] && !ObjCustomer['email'] && !ObjCustomer['licenseNo']) {
            msg = 'Please enter Phone or Email or License No.';
            return true;
          }
        } else if (!ObjCustomer[el] && !_.isBoolean(ObjCustomer[el])) {
          msg = 'Please enter ' + el;
          return true;
        }
      });
      //console.log('checkMandatoryContact', ObjCustomer, _mandatoryFields)
      if (msg) {
        Swal.fire({
          title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
          cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
        }).then(result => {
          if (result.value) {
            //this.handleEnquiryExpand();
            this.handleContactOpen('addContact', _mandatoryFields);
          }
        });
        return false;
      } else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  checkMandatoryEnquiry = async (value, _newStatus, _newStatusValue) => {
    //console.log('checkMandatoryEnquiry', _newStatus, _newStatusValue)
    let _mandatoryFields = !_.isEmpty(value) ? _.uniq(value.split(',')) : [];

    if (!_.isEmpty(this.mandatoryFields))
      _mandatoryFields = _.union(_mandatoryFields, this.mandatoryFields);

    if (!_.isEmpty(_mandatoryFields)) {
      const ObjEnquiry = Object.assign({}, objEnquiry);
      for (let [key, value] of Object.entries(this.state.enquiry)) {
        ObjEnquiry[key] = value;
      }
      let msg;

      // if (!_.isEmpty(ignoreFields))
      //   _mandatoryFields = _.difference(_mandatoryFields, ignoreFields);      

      const { modules, requirements } = this.state;
      let _soldRequirements = requirements.filter(e => e.isSold === true)
      let _isbreak = false;

      const { dealersettings } = this.state;

      let _client = ObjEnquiry.clientID && !_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.group) &&
        !_.isEmpty(dealersettings.group.clients) &&
        dealersettings.group.clients[ObjEnquiry.clientID] ? dealersettings.group.clients[ObjEnquiry.clientID] : dealersettings.client;

      const _moduleSettings = ((_client &&
        !_.isEmpty(_client.moduleSettings)) ? _client.moduleSettings : null);

      let _requiredObject = _.filter(_mandatoryFields, (v) => ((v.startsWith('is') || v.startsWith('files_') || v.endsWith('_note')) && !['isFinanceRequired', 'isFav'].includes(v)));
      let _requiredFields = _.filter(_mandatoryFields, (v) => (!_requiredObject.includes(v)));

      let _requiredForDeliverd = [];
      if (_newStatus === enquiryStatus.WON || _newStatus === enquiryStatus.DELIEVERD) {
        var _excluded = ['chassisNo', 'regNo', 'stockNo', 'warrantyStartDate', 'warrantyExpiry']

        if (_newStatus === enquiryStatus.DELIEVERD || (_newStatus === enquiryStatus.WON && !_.isEmpty(requirements) && requirements.length === 1))
          _requiredForDeliverd = _.filter(_requiredFields, (v) => (_excluded.indexOf(v) >= 0));

        _requiredFields = _.filter(_requiredFields, (v) => (_excluded.indexOf(v) < 0));
      }

      //console.log('checkMandatory-Enquiry', value, _mandatoryFields, this.mandatoryFields, _requiredObject, _requiredFields)
      for (const el of _requiredObject) {
        if (_.trim(el) === 'isContact') {
          if (!ObjEnquiry[el] && _.isEmpty(ObjEnquiry['contact'])) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add contact'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                //this.handleEnquiryExpand();
                this.handleContactOpen('addContact');
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isVehicle' && _newStatus !== 'requirement') {
          if ((!ObjEnquiry[el] && (_.isEmpty(ObjEnquiry['requirement']) || (!_.isEmpty(ObjEnquiry['requirement']) && _.isEmpty(ObjEnquiry['requirement'].make) && _.isEmpty(ObjEnquiry['requirement'].stock)))) &&
            !_.isEmpty(modules) && modules.requirement) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add requirement'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  this.setState({
                    requirementModal: {
                      show: true,
                      Id: '',
                      title: 'requirement',
                      clsActive: 'overlay-modal active',
                      requirement: null,
                      clone: null
                    }
                  });
                }, 200);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isTestDrive') {
          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.testDrive) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add testdrive'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isTradeIn') {
          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.tradeIn) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add tradein'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isCafe') {
          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.cafe) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add cafe'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isFiles') {
          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.files) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add file'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el).includes('files_')) {
          let _data = await enquiryHelper.isFileCategoryExist(_.trim(el), this.props.enquiryid, dealersettings)
          if (_data === false) {

            let filesCategory = Object.assign([], dealersettings?.client?.settings?.filesCategory)
            let _info = _.find(filesCategory, { value: el.split('_')[2] })
            if (!_.isEmpty(_info)) {
              Swal.fire({
                title: CommonHelper.showLocale(this.props, `Please add file in ${CommonHelper.autoCaps(_info.module)} (${_info.name}) Folder.`),
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
                cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
              }).then(result => {
                if (result.value) {
                  this.handleEnquiryExpand(_newStatus, _newStatusValue);
                }
              });
              _isbreak = true;
              return false;
            }
          }
        } else if (_.trim(el).includes('_note')) {
          let _data = await enquiryHelper.isActivityExist(_.trim(el), this.props.enquiryid, dealersettings)
          if (_data === false) {
            let _type = _.trim(el).split('_')[0];
            Swal.fire({
              title: CommonHelper.showLocale(this.props, `Please add ${_type.replace('log', ' note').toLowerCase()}.`),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus, _newStatusValue);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isVideo') {
          const amsVideoEnabled = ((!_.isEmpty(_moduleSettings) &&
            !_.isEmpty(_moduleSettings.amsVideo) &&
            _moduleSettings.amsVideo.enabled) ? true : false);

          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.amsVideo && amsVideoEnabled) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add video'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        } else if (_.trim(el) === 'isFinance') {
          const drivaEnabled = ((!_.isEmpty(dealersettings) &&
            !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0] &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].active === true &&
            dealersettings.client.integrations.filter(e => e.type === "driva")[0].enabled === true
          ) ? true : false);

          const financeEnabled = ((!_.isEmpty(_moduleSettings) &&
            !_.isEmpty(_moduleSettings.finance) &&
            _moduleSettings.finance.enabled) ? true : false);

          if (!ObjEnquiry[el] && !_.isEmpty(modules) && modules.finance && (financeEnabled || drivaEnabled)) {
            Swal.fire({
              title: CommonHelper.showLocale(this.props, 'Please add finance'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
              cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
            }).then(result => {
              if (result.value) {
                this.handleEnquiryExpand(_newStatus);
              }
            });
            _isbreak = true;
            return false;
          }
        }
      };

      if (_isbreak)
        return false;

      !_.isEmpty(_requiredFields) && _requiredFields.some((el, index) => {
        if (!ObjEnquiry[el]) {
          if (ObjEnquiry.hasOwnProperty(el)) {
            msg = 'Please enter ' + el;
            return true;
          }
          else if (
            (!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === el && m.active === true)[0])) &&
            (_.isEmpty(ObjEnquiry.dynamicFields) ||
              (!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[el]) && !_.isBoolean(ObjEnquiry.dynamicFields[el]) && !_.isNumber(ObjEnquiry.dynamicFields[el]))
            )
          ) {
            msg = 'Please enter ' + el;
            return true;
          }

          // else {
          //   return true;
          // }
        }
      });

      if (msg) {

        let errors = {};
        let formIsValid = true;
        let errorClass = 'input_error';

        _requiredFields.forEach((key, index) => {
          if (
            !ObjEnquiry[key] &&
            _requiredFields.indexOf(key) >= 0
          ) {
            if (ObjEnquiry.hasOwnProperty(key)) {
              formIsValid = false;
              errors[key] = errorClass;
            }
            else if ((!_.isEmpty(enquiryOptionsDF) && !_.isEmpty(enquiryOptionsDF.filter(m => m.value === key && m.active === true)[0])) &&
              (_.isEmpty(ObjEnquiry.dynamicFields) ||
                (!_.isEmpty(ObjEnquiry.dynamicFields) && _.isEmpty(ObjEnquiry.dynamicFields[key]) && !_.isBoolean(ObjEnquiry.dynamicFields[key]) && !_.isNumber(ObjEnquiry.dynamicFields[key]))
              )) {
              formIsValid = false;
              errors[key] = errorClass;
            }
            // else if ((!_.isEmpty(enquiryOptions) && !_.isEmpty(enquiryOptions.filter(m => m.value === key && m.active === true)[0])) &&
            //   (_.isEmpty(ObjEnquiry.options) || (!_.isEmpty(ObjEnquiry.options) && ObjEnquiry.options.indexOf(key) < 0))) {
            //   formIsValid = false;
            //   errors[key] = errorClass;
            // }
          }
        });

        if (formIsValid === true) {
          return true;
        } else {
          this.setState({
            enquiryModal: {
              show: true,
              errors: errors,
              newStatus: _newStatus,
              newStatusValue: _newStatusValue,
              ismodifyStatusDate: false,
              isModifyInboundOwner: false,
              mandatoryFields: _requiredForDeliverd
            },
          });
          return formIsValid;
        }
      } if (!_.isEmpty(_requiredForDeliverd) && !_.isEmpty(_soldRequirements) && !_.isEmpty(modules) && modules.requirement) {
        if (CommonHelper.isValidSoldRequirements(_soldRequirements, _requiredForDeliverd) === false) {
          this.setState({
            enquiryModal: {
              show: true,
              mandatoryFields: _requiredForDeliverd,
              newStatus: _newStatus,
              newStatusValue: _newStatusValue,
              ismodifyStatusDate: false,
              isModifyInboundOwner: false
            },
          });
          return false;
        }
        else {
          return true;
        }
      } else {
        return true;
      }
    }
    else {
      return true;
    }
  }
  //#endregion 

  //#region REQUIREMENT

  saveEnquiryField = async (name, val, _isReopen) => {
    if (!val && this.mandatoryFields && this.mandatoryFields.indexOf(name) >= 0) {
      //this.errorChange(name);
    }
    else {
      await this.updateEnquiryInfo(name, val, _isReopen);

      if (name === 'status' && val === enquiryStatus.DELIEVERD) {

        const { dealersettings } = this.props;
        const { requirements, contact } = this.state;
        const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
        if (!enableServicePackages && contact.documentID && !_.isEmpty(requirements)) {
          requirements.filter(e => e.isSold === true).forEach(_requirement => {
            this.handleSaveOwnedVehicle(_requirement, contact.documentID, _requirement.documentID)

            if (!_.isEmpty(_requirement.stock))
              this.handleChangeStockStatus(_requirement.stock.documentID, stockStatus.DELIVERED);
          });
        }
      } else if (name === 'status' && val === enquiryStatus.OPEN) {

        const { dealersettings } = this.props;
        const { requirements, contact } = this.state;
        const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);
        if (!enableServicePackages && contact.documentID && !_.isEmpty(requirements)) {
          requirements.forEach(_requirement => {
            this.handleDeleteOwnedVehicle(_requirement.documentID)
          });
        }
      }

    }
  }

  handleEnquiryWon = async () => {
    const { isVehicle, requirement } = this.state.enquiry;
    const { modules, requirements } = this.state;
    if (!_.isEmpty(requirements) && _.isEmpty(requirement)) {
      Swal.fire(CommonHelper.showLocale(this.props, "Mark a vehicle as primary to continue."), "", "info");
      return false;
    }
    else if (await this.isValidForStatusUpdate(enquiryStatus.WON)) {
      if (isVehicle && !_.isEmpty(modules) && modules.requirement)
        this.handleRequirentsForWon('won');
      else
        this.saveEnquiryField('status', enquiryStatus.WON)
    }
  }

  handleEnquiryExpand = (_newStatus, _newStatusValue) => {
    let _origin = _newStatusValue ? `${_newStatus}_${_newStatusValue}` : _newStatus
    //const { enquiry } = this.state;
    // if (this.props.handleRouteChange) {
    //   this.props.handleRouteChange({
    //     name: (enquiry.isContact && !_.isEmpty(enquiry.contact) && (!_.isEmpty(enquiry.contact.firstName) || !_.isEmpty(enquiry.contact.lastName)))
    //       ? CommonHelper.displayContact([], enquiry.contact, '--')
    //       : (!_.isEmpty(enquiry.engageNewContact) ? CommonHelper.displayContact([], enquiry.engageNewContact, '--') : (enquiry.displayID ? enquiry.displayID : this.props.enquiryid)),
    //     id: this.props.enquiryid,
    //     status: enquiry.status && enquiry.status === enquiryStatus.PENDINGLOST ? enquiryStatus.LOST : enquiry.status,
    //     active: true,
    //     clientid: enquiry.clientID,
    //     module: enquiry.isAfterSales ? 'afterSales' : 'enquiry'
    //   })
    // }
    if (_newStatus && _newStatus !== 'PipelineClose')
      this.props.history.push("/enquiry/details/" + this.props.enquiryid + '?origin=' + _origin);
    else
      this.props.history.push("/enquiry/details/" + this.props.enquiryid);
    this.props.sidepanelClose();
  }

  handleEnquiryDelivered = async () => {
    const { status, isVehicle } = this.state.enquiry;
    const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)
    if (isStatusSold || status == enquiryStatus.WON) {
      if (await this.isValidForStatusUpdate(enquiryStatus.DELIEVERD)) {
        this.saveEnquiryField('status', enquiryStatus.DELIEVERD);
      }
    }
    else { // when status delivered before enquiry won
      if (await this.isValidForStatusUpdate(enquiryStatus.DELIEVERD, null, enquiryStatus.WON)) {

        const { modules } = this.state;
        if (isVehicle && !_.isEmpty(modules) && modules.requirement)
          this.handleRequirentsForWon(enquiryStatus.WON, enquiryStatus.DELIEVERD);
        else {
          await this.saveEnquiryField('status', enquiryStatus.WON)
          this.saveEnquiryField('status', enquiryStatus.DELIEVERD)
        }

      }
    }
  }

  handleEnquiryLost = async () => {
    if (await this.isValidForStatusUpdate(enquiryStatus.LOST)) {
      !_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
        this.handleSoldRequirements(requirement.documentID, false, false);
      })
      this.saveEnquiryField('status', enquiryStatus.LOST)
    }
  }

  handleEnquiryProspectLost = async () => {
    if (await this.isValidForStatusUpdate(enquiryStatus.PROSPECTLOST)) {
      !_.isEmpty(this.state.requirements) && this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
        this.handleSoldRequirements(requirement.documentID, false, false);
      })
      this.saveEnquiryField('status', enquiryStatus.PROSPECTLOST)
    }
  }

  handleEnquiryOpen = async () => {
    if (await this.isValidForStatusUpdate(enquiryStatus.OPEN)) {
      this.setState({ showPipelineOnOpen: true });

      // this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
      //   this.handleSoldRequirements(requirement.documentID, false, false);
      // })
      // this.saveEnquiryField('status', enquiryStatus.OPEN)
    }
  }

  handleDynamicStatus = async (_status) => {
    //const { dealersettings } = this.state;
    const { status } = this.state.enquiry;
    //const enquiryAllStatus = (dealersettings && dealersettings.client && dealersettings.client.settings ? dealersettings.client.settings.enquiryStatus : [])
    let _objCurrentStatus = _status && enquiryAllStatus.filter(e => e.value === _status)[0];

    if (status === 'prospectLost' && _status === enquiryStatus.OPEN)
      this.saveEnquiryField('status', _status);
    else if (_status === enquiryStatus.WON)
      this.handleEnquiryWon();
    else if (_status === enquiryStatus.LOST)
      this.handleEnquiryLost();
    else if (_status === enquiryStatus.DELIEVERD)
      this.handleEnquiryDelivered();
    else if (_status === enquiryStatus.PROSPECTLOST)
      this.handleEnquiryProspectLost();
    else if (!_.isEmpty(_objCurrentStatus) && await this.isValidForStatusUpdate(_status)) {
      this.saveEnquiryField('status', _status);
    }

    // else if (_status === enquiryStatus.OPEN)
    //   this.handleEnquiryOpen();
  }

  handleFinanceStatus = async (_status, name) => {
    if (_status && await this.isValidForFinanceStatusUpdate(_status, name)) {
      this.saveEnquiryField(name, _status);
      //setTimeout(() => this.errorChange('financeStatus'), 500);
    }
    else if (_.isEmpty(_status)) {
      this.saveEnquiryField(name, null);
    }
    return true;
  }

  handleOpenLeadClose = async (data) => {
    if (!_.isEmpty(data)) {
      await this.handlePipelineClose(data, 'fromOpenLead');
      this.state.requirements.filter(e => e.isSold === true).forEach(requirement => {
        this.handleSoldRequirements(requirement.documentID, false, false);
      })
      if (!data.status)
        this.saveEnquiryField('status', enquiryStatus.OPEN, true)

    }
    this.setState({ showPipelineOnOpen: false, isAccessToReOpen: false });
  }

  handlePipelineClose = async (data, origin) => {
    let state = Object.assign({}, this.state.enquiry);

    if (!_.isEmpty(data)) {

      if (state.stage !== data.stage || state.pipeline !== data.pipeline) {
        if (data.pipeline === 'LeadsBucket') {
          //state.status = null;
          state.isConverted = false;
          state.unactionedNotify = '';
          state.convertedBy = null;
          state.convertedDate = null;
        }

        let stageHistory = Object.assign([], state.stageHistory);

        let _stageData = CommonHelper.handleStageHistory(stageHistory, data.pipeline, data.stage);
        if (!_.isEmpty(_stageData)) {
          stageHistory.push(_stageData);
          state.stageHistory = Object.assign([], stageHistory);
          state.pipeline = data.pipeline;
          state.stage = data.stage;
          state.stageDate = window.firebase.firestore.Timestamp.now();
        };
      }

      state.owner = data.owner ? data.owner : state.owner;
      state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
      state.modifiedDate = window.firebase.firestore.Timestamp.now();

      let updateObj = {
        pipeline: state.pipeline,
        stage: state.stage,
        owner: state.owner,
        stageDate: state.stageDate,
        stageHistory: state.stageHistory,
        modifiedBy: state.modifiedBy,
        modifiedDate: state.modifiedDate,
        isConverted: state.isConverted,
        unactionedNotify: state.unactionedNotify ? state.unactionedNotify : null,
        convertedBy: localStorage.uid,
        convertedDate: state.convertedDate,
        modifiedFrom: 'web'
      }
      if (origin === 'fromOpenLead') {
        updateObj.wonDate = null;
        updateObj.lostDate = null;
        updateObj.lostReason = null;
        updateObj.lostSubReason = null;
        updateObj.lostNotes = '';
        updateObj.deliveryDate = null;

        state.wonDate = null;
        state.lostDate = null;
        state.lostReason = null;
        state.lostSubReason = null;
        state.lostNotes = '';
        state.deliveryDate = null;

        if (data.status) {
          updateObj.status = data.status;
          state.status = data.status;

          if (state.status)
            updateObj.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
              {
                date: window.firebase.firestore.Timestamp.now(),
                status: state.status,
                user: localStorage.uid
              }
            )
        };
      }

      const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(state.documentID);
      await updateRef.update(updateObj).then((docRef) => {
        this.setState({
          enquiry: state
        });
        this.updateEnq(state);
      }).catch((error) => {
        console.error("Error updating enquiries: ", error);
      });

    }

  }

  handleChangeStockStatus = (stockID, status) => {
    let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
    let modifiedDate = window.firebase.firestore.Timestamp.now();

    let objData = {
      status: status,
      statusModifiedBy: modifiedBy,
      statusModifiedFrom: 'web',
      modifiedBy: modifiedBy,
      modifiedDate: modifiedDate,
      modifiedFrom: 'web'
    }
    if (status === stockStatus.SOLD || status === stockStatus.DELIVERED) {
      objData.enquiryID = this.props.enquiryid ? this.props.enquiryid : null;

      if (status === stockStatus.SOLD)
        objData.soldDate = modifiedDate;

      if (status === stockStatus.DELIVERED) {
        objData.deliveredDate = modifiedDate;
        objData.warrantyStartDate = modifiedDate;
      }
    }

    if (status !== stockStatus.SOLD && status !== stockStatus.DELIVERED) {
      objData.soldDate = null;
      objData.deliveredDate = null;
    }

    const refStockData = window.firebase.firestore().collection('stock').doc(stockID);
    refStockData.set(objData, { merge: true }).then((docRef) => {
      //console.log(docRef);

    }).catch((error) => {
      console.error("Error updating handleChangeStockStatus: ", error);
    });
  }

  handleSoldRequirements = (requirementID, isSold, setAsPrimary, saveFields) => {
    const { dealersettings } = this.props;
    const { enquiry, requirements } = this.state;
    const _requirement = Object.assign({}, requirements.filter(e => e.documentID === requirementID)[0]);

    _requirement.isSold = isSold;
    _requirement.modifiedBy = dealersettings ? dealersettings.id : '';
    _requirement.modifiedDate = window.firebase.firestore.Timestamp.now();

    const refReqData = firestoreDB(dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/requirements`).doc(requirementID);
    refReqData.update(
      {
        isSold: _requirement.isSold,
        modifiedBy: _requirement.modifiedBy,
        modifiedDate: _requirement.modifiedDate,
        modifiedFrom: 'web'
      }).then((docRef) => {
        //console.log(docRef);

        if (!_.isEmpty(_requirement.stock))
          this.handleChangeStockStatus(_requirement.stock.documentID, (isSold ? stockStatus.SOLD : stockStatus.AVAILABLE));

        if (setAsPrimary && setAsPrimary === true)
          this.handleReqMarkAsPrimary(_requirement, saveFields);
        // else if (enquiry?.requirement?.documentID === requirementID)
        //   this.handleReqMarkAsPrimary(_requirement, saveFields, true);

      }).catch((error) => {
        console.error("Error updating handleSoldRequirements: ", error);
      });
  }

  handleCheckSubLeads = async (soldIDs) => {

    Swal.fire({
      title: CommonHelper.showLocale(this.props, 'Are you sure?'),
      text: CommonHelper.showLocale(this.props, `Do you want to create sub Leads/Enquiries for each sold vehicle`),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(result => {
      if (result.value)
        this.handleCreateSubLeads(soldIDs, null, null, true)

    });
  }

  handleCheckRequirementSubLead = async (requirementID, isSold) => {
    const { linkID } = this.state.enquiry;
    const enableSubLeadsonWon = (this.props.dealersettings?.client?.clientSettings?.enableSubLeadsonWon) ? true : false;
    if (enableSubLeadsonWon && isSold && linkID) {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, 'Are you sure?'),
        text: CommonHelper.showLocale(this.props, `Do you want to create sub Lead/Enquiry for this vehicle`),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(result => {
        if (result.value)
          this.handleCreateSubLeads([requirementID])
        else {
          this.handleSoldRequirements(requirementID, isSold, false);
          toast.notify('Requirement updated successfully.', {
            duration: 2000
          })
        }


      });
    }
    else {
      this.handleSoldRequirements(requirementID, isSold, false);
      toast.notify('Requirement updated successfully.', {
        duration: 2000
      })
    }

  }

  handleCreateSubLeads = async (soldIDs, _status, nxtStatus, checkPrimary) => {
    const { enquiry, requirements, statusSettings, linkEnquiries } = this.state;
    var _excluded = ['chassisNo', 'regNo', 'stockNo', 'warrantyStartDate', 'warrantyExpiry']
    let _mandatoryFields = this.getMandatoryFieldsByType('enquiry', enquiryStatus.WON);
    let _requiredFields = _.filter(_mandatoryFields, (v) => (_excluded.indexOf(v) < 0));

    if (!_.isEmpty(_requiredFields)) {
      // GENERATING TEMPERORY ENQUIRIES DATA 
      let _enquiries = await enquiryHelper.generateSubLeadsData(this.props.dealersettings, enquiry, requirements, soldIDs, checkPrimary, _status, nxtStatus, statusSettings, linkEnquiries.length)
      // CHECKING IF ENQUIRIES DATA HAS ANY MANADATORY (EMPTY FIELDS)
      let errors = enquiryHelper.getMandatoryErrors(_enquiries.filter(e => !e.isPrimary), _requiredFields, enquiryOptionsDF);

      // IF HAS MANDATORY, FILLING THE MANDATORY (EMPTY FIELDS)
      if (!_.isEmpty(errors)) {
        this.setState({
          subLeadModal: {
            show: true,
            enquiries: _enquiries,
            mandatoryFields: _requiredFields,
            soldIDs: soldIDs,
            status: _status,
            nxtStatus: nxtStatus,
            checkPrimary: checkPrimary
          }
        });

      }
      else { // DIRECTLY SAVING SUB-LEADS
        this.handleSaveSubLeads(soldIDs, _status, nxtStatus, checkPrimary);
      }


      //console.log('_enquiries,-', _enquiries)
    }
    else {// DIRECTLY SAVING SUB-LEADS, IF HAS NO MANDATORY,
      this.handleSaveSubLeads(soldIDs, _status, nxtStatus, checkPrimary);
    }
  }

  handleSaveSubLeads = (soldIDs, _status, nxtStatus, checkPrimary) => {
    const { enquiry, requirements, statusSettings, linkEnquiries } = this.state;

    enquiryHelper.handleCreateSubLeads(this.props.dealersettings, enquiry, requirements, soldIDs, checkPrimary, _status, nxtStatus, statusSettings, linkEnquiries.length).then(() => {
      if (_status)
        this.checkTradeinCompleted(_status);
    });


  }

  handleCreateSubLeadsClose = (enquiries) => {

    const subLeadModal = Object.assign({}, this.state.subLeadModal)
    if (subLeadModal && !_.isEmpty(enquiries)) {

      if (subLeadModal.status)
        this.checkTradeinCompleted(subLeadModal.status);

    }
    this.setState({
      subLeadModal: {
        show: false,
        enquiries: null,
        mandatoryFields: null,
        soldIDs: null,
        status: null,
        nxtStatus: null,
        checkPrimary: null
      }
    });
  }

  handleMultiRequirementClose = async (soldIDs) => {
    //console.log('handleMultiRequirementClose', soldIDs);
    let nxtStatus = this.state.requirementMultiModal.nxtStatus;
    this.setState({
      requirementMultiModal:
      {
        show: false,
        Id: '',
        title: '',
        clsActive: '',
        nxtStatus: null
      }
    });
    const { isAfterSales } = this.state.enquiry;
    const enableSubLeadsonWon = (this.props.dealersettings?.client?.clientSettings?.enableSubLeadsonWon) ? true : false;
    if (enableSubLeadsonWon && !isAfterSales && !_.isEmpty(soldIDs) && Object.keys(soldIDs).length > 1) {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, 'Do you want to create sub Leads/Enquiries for each selected vehicle?'),
        //text: `You want to split enquiry.`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
        cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(result => {
        if (result.value)
          this.handleCreateSubLeads(soldIDs, enquiryStatus.WON, nxtStatus, true)
        else
          this.saveSoldRequirements(soldIDs, nxtStatus)
      });
    }
    else {
      this.saveSoldRequirements(soldIDs, nxtStatus)
    }
  }

  saveSoldRequirements = async (soldIDs, nxtStatus) => {
    if (!_.isEmpty(soldIDs)) {
      const { requirement } = this.state.enquiry;
      const primaryReqID = (!_.isEmpty(requirement)) ? requirement.documentID : '';
      let primaryID = ''

      //IF NO PRIMARY VEHICLE SELECTED, MARK FIRST ONE AS PRIMARY
      if (soldIDs.indexOf(primaryReqID) < 0) {
        primaryID = soldIDs[0];
      }

      soldIDs.forEach((requirementID) => {
        this.handleSoldRequirements(requirementID, true, (primaryID && primaryID === requirementID ? true : false));
      });
      await this.saveEnquiryField('status', enquiryStatus.WON)
      if (nxtStatus) this.saveEnquiryField('status', nxtStatus);
    }

  }

  handleRequirentsForWon = async (status, nextStatus) => {
    const { requirements } = this.state;
    const { isVehicle } = this.state.enquiry;

    //FOR SINGLE REQUIREMENT
    if (isVehicle && (requirements && _.isObject(requirements) && requirements.length === 1)) {
      const requirement = Object.assign({}, this.state.requirements[0]);
      if (!_.isEmpty(requirement.stock)) {
        if (requirement.stock.status === stockStatus.AVAILABLE || requirement.stock.status === stockStatus.MCRETAILED || requirement.stock.status === stockStatus.INPRODUCTION || requirement.stock.status === stockStatus.UNAVAILABLE) {
          if (requirement.stock.isServiceLoan) {
            Swal.fire(CommonHelper.showLocale(this.props, 'This vehicle has been reserved for service loan only'), '', 'info')
            return false;
          }
          this.handleSoldRequirements(requirement.documentID, true, false);
          await this.saveEnquiryField('status', enquiryStatus.WON)
          if (nextStatus) this.saveEnquiryField('status', nextStatus);
        }
        else {
          Swal.fire(CommonHelper.showLocale(this.props, 'Stock is not available'), '', 'info')
          return false;
        }
      }
      else {
        this.handleSoldRequirements(requirement.documentID, true, false);
        await this.saveEnquiryField('status', enquiryStatus.WON)
        if (nextStatus) this.saveEnquiryField('status', nextStatus);
      }
    } //FOR MULTIPLE REQUIREMENTS, SHOW MULTI SELECT OPTIONS TO WON
    else if (isVehicle && (requirements && _.isObject(requirements) && requirements.length > 1)) {
      this.setState({
        requirementMultiModal:
        {
          show: true,
          title: 'chooseVehicles',
          clsActive: 'overlay-modal active',
          nxtStatus: nextStatus
        }
      })
    }

  }

  handleReqMarkAsPrimary = (req, saveFields, noToast) => {
    if (!_.isEmpty(req)) {
      let state = Object.assign({}, this.state.enquiry);
      state.requirement = req;
      state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
      state.modifiedDate = window.firebase.firestore.Timestamp.now();

      const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
      updateRef.update(
        {
          requirement: state.requirement,
          modifiedBy: state.modifiedBy,
          modifiedDate: state.modifiedDate,
          modifiedFrom: 'web'
        }
      ).then((docRef) => {
        if (!noToast)
          toast.notify('Requirement updated successfully.', { duration: 2000 });

        this.setState({ enquiry: state });
        this.updateEnq(state);
      }).catch((error) => {
        console.error("Error updating enquiries: ", error);
      });
    }
  }

  decrementQuantity = (requirementID) => {
    let requirements = Object.assign([], this.state.requirements);

    let requirement = Object.assign({}, requirements.filter(e => e.documentID === requirementID)[0]);
    let quantity = 1;
    if (requirement.quantity && requirement.quantity > 1)
      quantity = parseInt(requirement.quantity) - 1;

    let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
    let modifiedDate = window.firebase.firestore.Timestamp.now();

    requirements.filter(function (obj) {
      if (obj.documentID === requirementID) {
        obj.quantity = quantity
        return true;
      }
      return false;
    });

    this.setState({ requirements: requirements });

    const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/requirements`).doc(requirementID);
    refReqData.update(
      {
        quantity: quantity,
        modifiedBy: modifiedBy,
        modifiedDate: modifiedDate,
        modifiedFrom: 'web'
      }).then((docRef) => {
        //console.log(docRef);
      }).catch((error) => {
        console.error("Error updating decrementQuantity: ", error);
      });
  }

  incrementQuantity = (requirementID) => {
    let requirements = Object.assign([], this.state.requirements);

    let requirement = Object.assign({}, requirements.filter(e => e.documentID === requirementID)[0]);
    let quantity = (requirement.quantity ? parseInt(requirement.quantity) : 0) + 1;
    let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
    let modifiedDate = window.firebase.firestore.Timestamp.now();

    requirements.filter(function (obj) {
      if (obj.documentID === requirementID) {
        obj.quantity = quantity
        return true;
      }
      return false;
    });

    this.setState({ requirements: requirements });
    const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/requirements`).doc(requirementID);
    refReqData.update(
      {
        quantity: quantity,
        modifiedBy: modifiedBy,
        modifiedDate: modifiedDate,
        modifiedFrom: 'web'
      }).then((docRef) => {
        //console.log(docRef);
      }).catch((error) => {
        console.error("Error updating incrementQuantity: ", error);
      });
  }

  requirementSoldMark = (requirement, isSold) => {
    if (!_.isEmpty(requirement) && !_.isEmpty(requirement.stock)) {
      if (requirement.stock.status !== stockStatus.AVAILABLE && requirement.stock.status !== stockStatus.MCRETAILED && requirement.stock.status !== stockStatus.INPRODUCTION && requirement.stock.status !== stockStatus.UNAVAILABLE && isSold) {
        Swal.fire(CommonHelper.showLocale(this.props, 'Stock is not available'), '', 'info')
        return false;
      }
      else {
        if (requirement.stock.isServiceLoan) {
          Swal.fire(CommonHelper.showLocale(this.props, 'This vehicle has been reserved for service loan only'), '', 'info')
          return false;
        }
        this.handleCheckRequirementSubLead(requirement.documentID, isSold)
      }
    }
    else if (!_.isEmpty(requirement)) {
      this.handleCheckRequirementSubLead(requirement.documentID, isSold)
    }
  }

  deleteRequirement = (objData, requirementID) => {
    Swal.fire({
      title: CommonHelper.showLocale(this.props, 'Are you sure?'),
      text: CommonHelper.showLocale(this.props, `You want to delete the requirement.`),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
    }).then(result => {
      if (result.value) {

        let modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        let modifiedDate = window.firebase.firestore.Timestamp.now();
        const refReqData = firestoreDB(this.props.dealersettings).firestore().collection(`enquiries/${this.props.enquiryid}/requirements`).doc(requirementID);
        refReqData.update(
          {
            isDeleted: true,
            modifiedBy: modifiedBy,
            modifiedDate: modifiedDate,
            modifiedFrom: 'web'
          }).then((docRef) => {
            //console.log(docRef);

            let _makeModel = objData ? (objData.make ? objData.make + ' ' : '') + (objData.model ? objData.model + ' ' : '') + (objData.year ? objData.year : '') : '';
            let _logNote = 'Requirement deleted ' + (_makeModel ? ' (' + _makeModel + ')' : '');
            CommonHelper.saveChangeLog(this.props.dealersettings.client.id, objData.groupID, objData.enquiryID, _logNote, 'enquiry', 'updated');

            this.handleDeleteOwnedVehicle(requirementID);

          }).catch((error) => {
            console.error("Error updating deleteRequirement: ", error);
          });
      }

    });

  }

  handleSaveOwnedVehicle = (requirement, _contactID, requirementID) => {
    const { ownedVehicles } = this.state;
    const { dealersettings } = this.props;
    let _requirement = !_.isEmpty(requirement.stock) ? requirement.stock : requirement;

    let isExists = ownedVehicles && ownedVehicles.filter(m =>
      (_requirement.vinNo ? m.vinNo === _requirement.vinNo : false) ||
      (_requirement.regNo ? m.regNo === _requirement.regNo : false))[0];

    if (_.isEmpty(isExists)) {

      const objData = Object.assign({}, objOwnedVehicle);
      for (let [key, value] of Object.entries(_requirement)) {
        if (objData.hasOwnProperty(key))
          objData[key] = value;
      }
      objData.addedBy = dealersettings ? dealersettings.id : '';
      objData.addedDate = window.firebase.firestore.Timestamp.now();
      objData.addedFrom = 'web';
      objData.modifiedBy = dealersettings ? dealersettings.id : '';
      objData.modifiedDate = window.firebase.firestore.Timestamp.now();
      objData.modifiedFrom = 'web';
      objData.documentID = firestoreDB(dealersettings).firestore().collection('contactDetails').doc().id;
      objData.currentlyOwned = true;
      objData.exteriorColor = _requirement.extColor ? _requirement.extColor : '';
      objData.requirementID = requirementID;
      objData.projectId = dealersettings?.client?.projectId ? dealersettings?.client?.projectId : null;

      const _dmsSettings = Object.assign({}, dealersettings?.client?.integrations?.filter(e => e.type === "cap")[0]);
      if (_dmsSettings?.active && _dmsSettings?.enabled) objData.owned = true;

      //Remove empty value from object
      for (var propName in objData) {
        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
          delete objData[propName];
        }
      }

      const refStockData = firestoreDB(dealersettings).firestore().collection(`contactDetails/${_contactID}/ownedVehicles`).doc(objData.documentID);
      refStockData.set(objData).then((docRef) => {
        //console.log(docRef);				
      }).catch((error) => {
        console.error("Error updating contact_details: ", error);
      });
    }

  }

  handleDeleteOwnedVehicle = (requirementID) => {
    const { ownedVehicles, enquiry, contact } = this.state;

    let _contactID = enquiry.contactID ? enquiry.contactID : (!_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : (!_.isEmpty(contact) ? contact.documentID : null));
    let objData = ownedVehicles && ownedVehicles.filter(m => m.requirementID === requirementID)[0];

    if (!_.isEmpty(objData) && objData.documentID && _contactID)
      firestoreDB(this.props.dealersettings).firestore().doc(`contactDetails/${_contactID}/ownedVehicles/${objData.documentID}`).delete()

  }

  handleReqEdit = (req, isClone, mandatoryFields) => {
    this.setState({
      requirementModal:
      {
        show: true,
        title: isClone ? 'cloneRequirement' : 'requirement',
        clsActive: 'overlay-modal active',
        Id: '',
        requirement: isClone ? null : req,
        clone: isClone ? req : null,
        mandatoryFields: mandatoryFields ? mandatoryFields : null
      }
    })
  }

  handleRequirementOpen = async () => {
    const { dealersettings } = this.props;
    const enableServicePackages = ((!_.isEmpty(dealersettings) && dealersettings.enableServicePackages) ? true : false);

    if (!this.state.enquiry.isContact && enableServicePackages) {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, 'Please add contact'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, 'Add Now'),
        cancelButtonText: CommonHelper.showLocale(this.props, 'Later'),
      }).then(result => {
        if (result.value) {
          //this.handleEnquiryExpand();
          this.handleContactOpen('addContact');
        }
      });
      return false;
    }
    else {
      const { pipeline, stage } = this.state.enquiry;
      if (await this.isValidForStageUpdate(pipeline, stage, 'requirement')) {
        this.setState({
          requirementModal: {
            show: true,
            Id: '',
            title: 'requirement',
            clsActive: 'overlay-modal active',
            requirement: null,
            clone: null
          }
        });
      }

    }


  }

  updateEnq = (_data) => {
    const { isFilterApplied, updateEnquiry, updateListEnquiry } = this.props;
    const { enquiry } = this.state;
    if (isFilterApplied) {
      if (updateEnquiry) {
        //console.log('updateEnq-enquiry', enquiry)
        updateEnquiry(_data ? _data : enquiry, true);
      }
      if (updateListEnquiry) {
        updateListEnquiry(_data ? _data : enquiry);
      }
    }
  }

  handleRequirementClose = (_data) => {
    if (!_.isEmpty(_data)) {
      var self = this;
      setTimeout(function () { self.updateEnq(); }, 2000);
    }

    this.setState({
      requirementModal:
      {
        show: false,
        Id: '',
        title: '',
        clsActive: '',
        requirement: null,
        clone: null,
        mandatoryFields: null
      }
    })
  }

  handleDeleteEnquiry = (e) => {
    e.preventDefault();

    Swal.fire({
      title: CommonHelper.showLocale(this.props, 'Are you sure?'),
      text: CommonHelper.showLocale(this.props, 'You want to delete enquiry.'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
    }).then(result => {
      if (result.value) {
        let state = Object.assign({}, this.state.enquiry);

        state.isDeleted = true;
        state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
        state.modifiedDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
        updateRef.update({
          isDeleted: state.isDeleted,
          modifiedBy: state.modifiedBy,
          modifiedDate: state.modifiedDate,
          modifiedFrom: 'web'
        }).then((docRef) => {
          var objRecord = {
            id: this.props.enquiryid,
            clientid: state.clientID
          }
          //this.props.handleRouteRemove(objRecord, state.isAfterSales ? 'afterSales' : 'enquiry');

          if (state.contactID) {
            const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
            updateRepeatCustomer({ 'contactID': state.contactID, 'projectId': state?.projectId ? state.projectId : null })
          }

          this.setState({ enquiry: state });
          toast.notify('Enquiry deleted successfully.', {
            duration: 2000
          })

          let logNotes = 'Enquiry deleted';
          if (!_.isEmpty(state) && state.displayID)
            logNotes = 'Enquiry: ' + state.displayID + ' deleted';

          let _objLogData = {
            notes: logNotes,
            type: 'enquiries',
            subType: 'delete',
            recordId: this.props.enquiryid,
          }
          _objLogData = CommonHelper.setClientOrGroupOrRegionOrOemIDForContacts(_objLogData, this.state.dealersettings);
          CommonHelper.saveAuditLog(_objLogData)

          this.updateEnq(state);

          this.props.sidepanelClose();
        }).catch((error) => {
          console.error("Error updating enquiries: ", error);
        });

      }
    });
  }

  handleVerifyEnqiry = () => {
    Swal.fire({
      title: CommonHelper.showLocale(this.props, 'Are you sure?'),
      text: CommonHelper.showLocale(this.props, `You want to verify enquiry.`),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
    }).then(result => {
      if (result.value) {
        let state = Object.assign({}, this.state.enquiry);
        if (!state.verifiedBy) {
          state.verifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
          state.verifiedDate = window.firebase.firestore.Timestamp.now();
          state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : '';
          state.modifiedDate = window.firebase.firestore.Timestamp.now();

          const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
          updateRef.update({
            verifiedBy: state.verifiedBy,
            verifiedDate: state.verifiedDate,
            modifiedBy: state.modifiedBy,
            modifiedDate: state.modifiedDate,
            modifiedFrom: 'web'
          }).then((docRef) => {
            this.setState({ enquiry: state });
            this.updateEnq(state);
          }).catch((error) => {
            console.error("Error updating enquiries: ", error);
          });
        }
      }
    });


  }

  handleLostApprovalOpen = (e) => {
    this.setState({
      lostApprovalPopOver: { showpopover: true, targetpopover: e.target }
    });
  }

  handleLostApprovalClose = (approvalStatus, notes, mentions) => {
    if (approvalStatus) {
      let state = Object.assign({}, this.state.enquiry);
      let _userID = this.props.dealersettings ? this.props.dealersettings.id : '';
      let _date = window.firebase.firestore.Timestamp.now();
      let objData = {};
      if (approvalStatus === 'approved') {
        objData.status = enquiryStatus.LOST;
        //objData = this.handleMoveStageForStatusChange(enquiryStatus.LOST, objData);
        objData.lastStatus = '';
        objData.lostDate = _date;
        objData = CommonHelper.removeBatchNumberOnLost(objData, state, enquiryOptionsDF);
      }

      if (approvalStatus === 'rejected') {
        objData.status = state.lastStatus;
        objData.lastStatus = '';
        objData.lostReason = '';
        objData.lostSubReason = '';
        objData.lostDate = null;
        objData.lostNotes = '';
        objData = this.handleRejectLostStatusChange(objData);

      }

      if (objData.status)
        objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
          {
            date: window.firebase.firestore.Timestamp.now(),
            status: objData.status,
            user: localStorage.uid
          }
        )

      if (!_.isEmpty(mentions)) {
        if (mentions.length > 0) {
          let convertedIDs = []
          let rawIDs = mentions.map(mentionObject => mentionObject.id)
          rawIDs.forEach(id => {
            if (id.includes(',')) {
              let allIDs = id.split(',')
              allIDs.forEach(a => {
                convertedIDs.push(a)
              })
            } else {
              convertedIDs.push(id)
            }
          })

          if (objData.mentions && objData.mentions.length > 0) {
            objData.mentions = _.uniq([...objData.mentions, ...convertedIDs])
          } else {
            objData.mentions = _.uniq(convertedIDs)
          }
        }
      }

      objData.modifiedBy = _userID;
      objData.modifiedDate = _date;
      objData.modifiedFrom = 'web';

      const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
      updateRef.set(objData, { merge: true }).then((docRef) => {
        if (approvalStatus === 'approved') this.checkTradeinCompleted(enquiryStatus.LOST);
        if (notes) {
          this.saveCustomerlog(notes, objData.mentions);
        }
      }).catch((error) => {
        console.error("Error updating enquiries: ", error);
      });
    }
    this.setState({
      lostApprovalPopOver: { showpopover: false, targetpopover: '' }
    });
  }

  saveCustomerlog = (logNotes, mentions, enquiryID, clientID) => {
    let newlogVM = Object.assign({}, objActivitylog);
    newlogVM.type = 'log';
    newlogVM.subType = 'note';
    newlogVM.isDone = true;
    newlogVM.owner = localStorage.uid;
    newlogVM.addedBy = localStorage.uid;
    newlogVM.modifiedBy = localStorage.uid;
    newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.enquiryID = enquiryID ? enquiryID : this.props.enquiryid;
    newlogVM.contactID = this.state?.contact?.documentID ? this.state.contact.documentID : null;
    newlogVM['enquiry'] = CommonHelper.getMinifiedData(this.state.enquiry, 'enquiry');
    newlogVM['contact'] = CommonHelper.getMinifiedData(this.state.contact, 'contact');
    //newlogVM.clientID = this.props.dealersettings ? this.props.dealersettings.client.id : null;
    newlogVM.documentID = firestoreDB(this.props.dealersettings).firestore().collection('activities').doc().id;
    newlogVM.startDate = window.firebase.firestore.Timestamp.now();
    newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(this.props.groupUsers) ? this.props.groupUsers : this.props.clientUsers), localStorage.uid);
    newlogVM.modifiedByName = newlogVM.addedByName;
    newlogVM.notes = logNotes ? logNotes : '';
    newlogVM = CommonHelper.setLevelIDs(newlogVM, this.state.dealersettings, null, (!_.isEmpty(this.state.enquiry)
      ? this.state.enquiry.clientID
      : null));
    if (mentions) {
      newlogVM.mentions = mentions
    }
    Object.keys(newlogVM).forEach(key => ([undefined, null, ''].includes(newlogVM[key])) ? delete newlogVM[key] : {});
    newlogVM.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;

    firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`)
      .set(newlogVM, { merge: true })
      .catch(error => {
        console.error(error);
      });
  };
  //#endregion

  //#region NEW LEAD

  handleNewLeadOpen = () => {
    Swal.fire({
      title: CommonHelper.showLocale(this.props, 'Are you sure?'),
      text: CommonHelper.showLocale(this.props, `You want to create new enquiry.`),
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: CommonHelper.showLocale(this.props, 'Yes'),
      cancelButtonText: CommonHelper.showLocale(this.props, 'No'),
    }).then(result => {
      if (result.value) {
        this.setState({ showNewLeadModal: true });
      }
    });

  }

  handleNewLeadClose = (data) => {
    if (!_.isEmpty(data)) {
      let _existingEnquiry = Object.assign({}, this.state.enquiry);

      let state = Object.assign({}, objEnquiry);
      const { dealersettings, clientUsers } = this.props;
      state.addedBy = dealersettings ? dealersettings.id : '';
      state.addedDate = window.firebase.firestore.Timestamp.now();

      state.modifiedBy = dealersettings ? dealersettings.id : '';
      state.modifiedDate = window.firebase.firestore.Timestamp.now();
      state = CommonHelper.setClientOrGroupOrRegionOrOemIDForEnquiry(state, this.state.dealersettings, null, _existingEnquiry.clientID);

      state.pipeline = data.pipeline;
      state.stage = data.stage;
      state.owner = data.owner ? data.owner : '';
      state.status = data.status ? data.status : 'open';

      if (state.status)
        state.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
          {
            date: window.firebase.firestore.Timestamp.now(),
            status: state.status,
            user: localStorage.uid
          }
        )

      state.stageDate = window.firebase.firestore.Timestamp.now();
      state.stageHistory = Object.assign([], [{ date: state.stageDate, stage: state.stage, pipeline: state.pipeline }]);
      state.isDeleted = false;
      state.isNewEnquiry = true;

      if (!_.isEmpty(_existingEnquiry) && !_.isEmpty(_existingEnquiry.contact)) {
        const contact = Object.assign({}, _existingEnquiry.contact);
        state.contact = contact;
        state.isContact = true;
        state.contactID = contact.documentID;
        state.isNewEnquiry = false;
      }

      if (_.isEmpty(state.requirement)) {
        const roleID = _.find(clientUsers, { id: data.owner }) ? _.find(clientUsers, { id: data.owner }).roleID : '';
        const role = _.find(dealersettings.client.roles, { id: roleID });
        if (role && _.isArray(role.salesType) && role.salesType.length > 0) {
          state.requirement = {
            saleType: role.salesType[0]
          }
        }
      }

      const objData = Object.assign({}, state);
      //Remove empty value from object
      for (var propName in objData) {
        if (objData[propName] === null || objData[propName] === undefined || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
          delete objData[propName];
        }
      }

      objData.documentID = firestoreDB(dealersettings).firestore().collection('enquiries').doc().id;

      let _comments = 'lost enquiry reference : ' + (_existingEnquiry.displayID ? _existingEnquiry.displayID : '');


      const updateRef = firestoreDB(dealersettings).firestore().collection('enquiries').doc(objData.documentID);
      updateRef.set(objData, { merge: true }).then((docRef) => {
        let documentID = objData.documentID;
        toast.notify('Enquiry created sucessfully', { duration: 2000 });

        if (!_.isEmpty(_comments)) {
          this.saveCustomerlog(_comments, null, objData.documentID);
        }

        if (objData.contactID) {
          const updateRepeatCustomer = window.firebase.functions().httpsCallable(`contact-updateRepeatCustomer`);
          updateRepeatCustomer({ 'contactID': objData.contactID, 'projectId': objData?.projectId ? objData.projectId : null })
        }

        // lead reference ID Updated...
        const updateRef = firestoreDB(this.props.dealersettings).firestore().collection('enquiries').doc(this.props.enquiryid);
        updateRef.update({
          lostRefID: documentID,
          modifiedBy: dealersettings ? dealersettings.id : '',
          modifiedDate: window.firebase.firestore.Timestamp.now(),
          modifiedFrom: 'web'
        }).then((docRef) => {
          this.setState({ showNewLeadModal: false });
          this.props.history.push('/enquiry/details/' + objData.documentID);
          this.props.sidepanelClose();
        }).catch((error) => {
          console.error("Error updating enquiries: ", error);
        });

      }).catch((error) => {
        console.error("Error updating contact_details: ", error);
      });
      //console.log('handleNewLeadClose', objData, _comments);

    }
    else {
      this.setState({ showNewLeadModal: false });
    }

  }

  //#endregion

  handleStatusDateOpen = (ismodifyStatusDate, isModifyInboundOwner) => {
    this.setState({
      enquiryModal: {
        show: true,
        errors: {},
        newStatus: null,
        newStatusValue: null,
        ismodifyStatusDate: ismodifyStatusDate,
        isModifyInboundOwner: isModifyInboundOwner,
        mandatoryFields: null,
      }
    })
  }

  handleFinanceInfoOpen = () => {
    this.setState({
      enquiryModal: {
        show: true,
        financeRequired: true,
      }
    })
  }

  //#region MERGE ENQUIRY
  handleOpenMerge = (e) => {
    e.preventDefault();
    this.setState({ showMergeEnqList: true });
  }

  handleCloseMerge = () => {
    this.setState({ showMergeEnqList: false });
  }
  //#endregion

  //#region LEAD Convert
  handleLeadConvertShow = async () => {
    const { pipeline, stage } = this.state.enquiry;
    if (await this.isValidForStageUpdate(pipeline, 'Converted', 'leadConvert')) {
      this.setState({ showLeadConvert: true });
    }
  }

  handleModalClose = objFields => {
    if (_.isEmpty(objFields)) {
      this.setState({ showLeadConvert: false, convertLeadLoader: false });
    } else {
      var self = this;
      self.setState({ convertLeadLoader: true });
      let state = Object.assign({}, this.state.enquiry);
      let _contact = Object.assign({}, this.state.contact);
      const { dealersettings } = this.props;
      let _objData = {
        isConverted: true,
        unactionedNotify: enqUnActionStatus.PENDING,
        convertedDate: window.firebase.firestore.Timestamp.now(),
        stageDate: window.firebase.firestore.Timestamp.now(),
        convertedBy: localStorage.uid,
        modifiedBy: localStorage.uid,
        modifiedDate: window.firebase.firestore.Timestamp.now(),
        clientID: objFields.clientID ? objFields.clientID : state.clientID,
        projectId: objFields.projectId ? objFields.projectId : (state.projectId ? state.projectId : null),
        //groupID: (dealersettings && dealersettings.client && dealersettings.client.group) ? dealersettings.client.group : null,
        owner: objFields.owner,
        pipeline: objFields.pipeline,
        stage: objFields.stage,
        status: enquiryHelper.bindDefaultStatus(objFields.status, state.status),
        stageHistory: objFields.stageHistory,
        modifiedFrom: 'web'
      }

      if (objFields.status)
        _objData.statusHistory = window.firebase.firestore.FieldValue.arrayUnion(
          {
            date: window.firebase.firestore.Timestamp.now(),
            status: objFields.status,
            user: localStorage.uid
          }
        )

      if (objFields.pipeline === 'LeadsBucket') {
        //_objData.status = null;
        _objData.isConverted = false;
        _objData.unactionedNotify = '';
        _objData.convertedBy = null;
        _objData.convertedDate = null;
        _objData.ownerInbound = objFields.owner;
      }
      else {
        _objData.lastStatus = '';
        _objData.lostReason = '';
        _objData.lostSubReason = '';
        _objData.lostNotes = '';
        _objData.lostDate = null;
        _objData.wonDate = null;

        if (_.isEmpty(state.tags) && state.pipeline === 'LeadsBucket') {
          _objData.tags = [{ type: 'inbound', refID: objFields.enquiryID, subType: '' }]
        }
      }

      let _contactChange = false;
      if (state.isContact && !_.isEmpty(_contact?.documentID) && objFields.clientID && state.clientID !== objFields.clientID) {
        if (!(dealersettings?.group?.enableSharedContact && !_.isEmpty(dealersettings.group?.client_ids) && dealersettings.group?.client_ids.includes(objFields.clientID))) {
          let _tags = Object.assign([], state.tags);
          _tags.push({
            type: 'Dealer Transfer',
            refID: (_contact?.documentID || null),
            clientID: (_contact?.clientID || null),
            origin: 'quick_convert'
          })
          _objData.engageNewContact = Object.assign({}, {
            ..._contact,
            documentID: null,
            displayID: null,
            clientID: null,
            projectId: null,
            groupID: null,
            regionID: null,
            oemID: null,
          });
          _objData.contact = {};
          _objData.contactID = null;
          _objData.isContact = false;
          _objData.tags = _tags;
          _objData.tags = _objData.tags ? _.union(_tags, _objData.tags) : _tags;
          _contactChange = true;
        }
      }
      const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
        !_.isEmpty(dealersettings.client) &&
        !_.isEmpty(dealersettings.client.clientSettings) &&
        dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

      if (enableCustShowNoShow && objFields.activityType === 'appointment' && !_.isEmpty(objFields.activityDate)) {
        _objData.bdcStatus = 'allocated';
        _objData.appointmentDate = objFields.activityDate;
      }
      //Remove empty value from object
      Object.keys(_objData).forEach(key => _objData[key] === undefined ? delete _objData[key] : {});
      firestoreDB(dealersettings).firestore().doc(`enquiries/${objFields.enquiryID}`)
        .set(_objData, { merge: true })
        .then(snapshot => {
          //console.log('success');

          let _contactID = state.contactID ? state.contactID : state?.contact?.documentID
          if (objFields.owner && state.owner !== objFields.owner && _contactID)
            firestoreDB(dealersettings).firestore().doc(`contactDetails/${_contactID}`).set({ 'owner': objFields.owner }, { merge: true })

          if (state.owner !== objFields.owner || state.clientID !== objFields.clientID) {
            this.updateActivity(state.owner, objFields, _contactChange);
          }
          else
            this.setState({ showLeadConvert: false, convertLeadLoader: false });

          let logNotes = (_.isEmpty(objFields.notes) || _.isEmpty(objFields.notes.trim())) ? `${dealersettings.isPorsche === true ? 'Enquiry has been moved' : 'Lead has been converted'} to ${_.find(this.props.dealersettings.allPipelines, { 'value': objFields.pipeline }) ? _.find(this.props.dealersettings.allPipelines, { 'value': objFields.pipeline }).name : objFields.pipeline}` : objFields.notes;
          this.saveCustomerlog(logNotes, objFields.mentions, null, _objData.clientID);
          if (objFields.activityDate)
            this.createAppoitment(objFields);

          toast.notify('Enquiry converted successfully.', {
            duration: 2000,
          });

          CommonHelper.refreshStageCount(objFields.pipeline, dealersettings);
          var self = this;
          setTimeout(() => {
            this.props.reloadData && this.props.reloadData();
            setTimeout(function () { self.updateEnq(); }, 2000);
          }, 3000);
        })
        .catch(error => {
          console.error(error);
          setTimeout(function () {
            self.setState({ convertLeadLoader: false });
          }, 1000);
          toast.notify('Something went wrong', {
            duration: 2000,
          });
        });
    }
  };

  createAppoitment = (objFields) => {
    let newlogVM = Object.assign({}, objActivitylog);
    const { clientUsers, groupUsers } = this.props;
    const { dealersettings } = this.state;

    const { enquiry, contact } = this.state;

    const objData = Object.assign({}, enquiry);
    //Remove empty value from object
    for (var propName in objData) {
      if (objData[propName] === null || objData[propName] === undefined || objData[propName] === '' || objData[propName] === [] || (_.isObject(objData[propName]) && _.isEmpty(objData[propName]))) {
        delete objData[propName];
      }
    }

    const objContData = Object.assign({}, contact);
    //Remove empty value from object
    for (var propName in objContData) {
      if (objContData[propName] === null || objContData[propName] === undefined || objContData[propName] === '' || objContData[propName] === [] || (_.isObject(objContData[propName]) && _.isEmpty(objContData[propName]))) {
        delete objContData[propName];
      }
    }

    newlogVM.type = 'activity';
    newlogVM.subType = objFields.activityType ? objFields.activityType : 'appointment';
    newlogVM.title = objFields.activityType ? CommonHelper.getNameByValue(dealersettings && dealersettings.client && dealersettings.client.settings && dealersettings.client.settings.activityTypes, objFields.activityType) : 'Appointment';
    newlogVM.owner = objFields.owner;
    newlogVM.addedBy = localStorage.uid;
    newlogVM.modifiedBy = localStorage.uid;
    newlogVM.addedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.modifiedDate = window.firebase.firestore.Timestamp.now();
    newlogVM.modifiedFrom = 'web';
    newlogVM.enquiryID = this.props.enquiryid;
    newlogVM.contactID = contact?.documentID ? contact.documentID : null;
    newlogVM.enquiry = !_.isEmpty(objData) ? objData : null;
    newlogVM.contact = !_.isEmpty(objContData) ? objContData : null;
    //newlogVM.clientID = dealersettings ? dealersettings.client.id : null;
    newlogVM.documentID = firestoreDB(this.props.dealersettings).firestore().collection('activities').doc().id;
    newlogVM.startDate = objFields.activityDate;
    newlogVM.endDate = window.firebase.firestore.Timestamp.fromDate(moment.unix(objFields.activityDate.seconds).add('hour', 1).toDate());
    newlogVM.addedByName = CommonHelper.getUserNamebyId((!_.isEmpty(groupUsers) ? groupUsers : clientUsers), localStorage.uid);
    newlogVM.projectId = this.props.dealersettings?.client?.projectId ? this.props.dealersettings?.client?.projectId : null;

    const enableCustShowNoShow = ((!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.clientSettings) &&
      dealersettings.client.clientSettings.enableCustShowNoShow) ? true : false);

    if (enableCustShowNoShow)//objFields.pipeline !== 'LeadsBucket' && 
      newlogVM = CommonHelper.setCustShowNoShow(newlogVM, dealersettings, true);

    newlogVM = CommonHelper.setLevelIDs(newlogVM, dealersettings, null, objFields.clientID);
    firestoreDB(this.props.dealersettings).firestore().doc(`activities/${newlogVM.documentID}`).set(newlogVM, { merge: true })
      .catch(error => {
        console.error(error);
      });;
  }

  updateActivity = async (currentOwner, objFields, _contactChange) => {
    if (!_.isEmpty(objFields) && (objFields.clientID || objFields.owner)) {
      try {
        let _objAct = {}
        if (objFields.owner) _objAct.owner = objFields.owner;
        if (objFields.clientID) _objAct.clientID = objFields.clientID;
        if (_contactChange) {
          _objAct.contactID = null;
          _objAct.contact = {};
        }

        const batch = firestoreDB(this.props.dealersettings).firestore().batch();
        await firestoreDB(this.props.dealersettings).firestore().collection('activities')
          .where('enquiryID', '==', objFields.enquiryID)
          .where('owner', '==', currentOwner)
          .where('isDeleted', '==', false)
          //.where('isDone', '==', false)
          .get().then(response => {
            response.docs.forEach((doc) => {
              batch.update(doc.ref, _objAct);
            });
          });
        batch.commit()
          .then(() => {
            this.setState({ showLeadConvert: false, convertLeadLoader: false });
          })
          .catch((error) => {
            console.log(error);
            toast.notify('Something went wrong', {
              duration: 2000
            })
          });

      }
      catch (error) {
        console.error('updateActivity', error)

      }
    }

  }
  //#endregion


  //#region FILE CHANGES

  handleFileClose = (ID) => {
    if (ID) {
      let state = Object.assign({}, this.state.enquiry);
      if (!state.isFiles) {
        state.isFiles = true;
        state.modifiedBy = this.props.dealersettings ? this.props.dealersettings.id : "";
        state.modifiedDate = window.firebase.firestore.Timestamp.now();

        const updateRef = firestoreDB(this.props.dealersettings)
          .firestore()
          .collection("enquiries")
          .doc(this.props.enquiryid);
        updateRef
          .update({
            isFiles: state.isFiles,
            modifiedBy: state.modifiedBy,
            modifiedDate: state.modifiedDate,
            modifiedFrom: "web",
          })
          .then((docRef) => {
            this.setState({ enquiry: state });
          })
          .catch((error) => {
            console.error("Error updating enquiries: ", error);
          });
      }
    }

    this.setState({
      fileModal: {
        show: false,
        category: null,
        type: null,
        categories: null
      },
      filesPopOver: {
        show: false,
        category: null,
        categoryName: null,
        type: null,
        categories: null,
        targetpopover: null
      }
    });
  };

  handleFileOpen = (e, category, type, categories, categoryName) => {
    if (this.state.enquiry.isContact) {
      if (e)
        this.setState({ filesPopOver: { show: true, category, type, categories, categoryName, targetpopover: e.target } });
      else
        this.setState({ fileModal: { show: true, category, type, categories } });

      return true;
    } else {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, "Please add contact"),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
        cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
      }).then((result) => {
        if (result.value) {
          this.handleContactOpen("addContact");
        }
      });
      return false;
    }
  };

  isValidForRequestCreditScore = () => {
    const { dealersettings, fields } = this.state;
    let _mandatoryFields = ['firstName', 'state', 'dob']

    if (this.state.enquiry.isContact) {
      const ObjCustomer = Object.assign({}, this.state.contact);
      var msg = "";

      let _creditScoreTC = CommonHelper.getTermsByField(dealersettings.client, 'creditScoreTC', ObjCustomer.language);
      if (!_.isEmpty(_creditScoreTC)) _mandatoryFields.push('creditScoreTerms')

      _mandatoryFields.some((el, index) => {
        if (_.isEmpty(ObjCustomer[el]) && !_.isBoolean(ObjCustomer[el])) {
          msg = `${CommonHelper.showLocale(this.props, "Please enter")} ${CommonHelper.showLocale(this.props, el)}`;
          return true;
        }
      });

      if (msg) {
        Swal.fire({
          title: CommonHelper.showLocale(this.props, 'Please enter mandatory fields in contact'),
          icon: "info",
          showCancelButton: true,
          confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
          cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
        }).then((result) => {
          if (result.value) {
            this.handleContactOpen("editContact", _mandatoryFields);
          }
        });

        return false;

      } else {
        this.handleRequestCreditScore();
      }
      return true;
    } else {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, "Please add contact"),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
        cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
      }).then((result) => {
        if (result.value) {
          this.handleContactOpen("addContact", _mandatoryFields);
        }
      });
      return false;
    }
  }

  handleRequestCreditScore = () => {
    if (this.state.enquiry.isContact) {
      this.setState({ loaderCreditScore: true })
      try {
        const getCreditScore = window.firebase.functions().httpsCallable(`contact-getCreditScore`);
        getCreditScore({
          "contact": this.state.contact,
          "enquiryID": this.props.enquiryid,
          "userID": localStorage.uid
        }).then((res) => {
          console.log('getCreditScore', res)
          this.setState({ loaderCreditScore: false });
          if (res.data.success) {
            toast.notify('Requested successfully.', {
              duration: 2000,
            });
          }
          else {
            Swal.fire(res.data.message, '', 'error')
          }
        }).catch((error) => {
          this.setState({ loaderCreditScore: false });
          console.log(error);
          Swal.fire('Something went wrong. Please contact support.', '', 'error')
        })
      }
      catch (error) {
        Swal.fire('Something went wrong. Please contact support.', '', 'error')
      }
      return true;
    } else {
      Swal.fire({
        title: CommonHelper.showLocale(this.props, "Please add contact"),
        icon: "info",
        showCancelButton: true,
        confirmButtonText: CommonHelper.showLocale(this.props, "Add Now"),
        cancelButtonText: CommonHelper.showLocale(this.props, "Later"),
      }).then((result) => {
        if (result.value) {
          this.handleContactOpen("addContact");
        }
      });
      return false;
    }

  }

  //#endregion
  render() {

    const { children, clientUsers, groupUsers, isReadOnlyView } = this.props
    const {
      enquiry, contact, requirements, loading, customerlogList, activitiesList,
      requirementMultiModal, requirementModal, enquiryModal,
      deliveryModal, lostModal, isEmptyPipeline, allClientUsers,
      enquiryBasicVM, modules, newStage, lostApprovalPopOver,
      showNewLeadModal, showPipelineOnOpen, isAccessToReOpen,
      showMergeEnqList, showLeadConvert, convertLeadLoader, amsmeUser,
      dealersettings, contactModal, completedTradeinsList, allFinanceUsers,
      linkEnquiries, subLeadModal, defaultStatus, defaultMakes, allAfterMarketUsers,
      files, filesPopOver, fileModal, loaderCreditScore
    } = this.state;

    const { clientID, status, requirement, isVehicle, isConverted, isConvertedOEM, isConvertedRegion, isConvertedGroup, isAfterSales } = this.state.enquiry;
    const primaryReqID = (!_.isEmpty(requirement)) ? requirement.documentID : '';

    const isGroupDealer = localStorage.defaultModule !== 'oem' && enquiry && enquiry.clientID && !_.isEmpty(dealersettings) && enquiry.clientID !== dealersettings.client.id ? true : false;

    let dealerName = '';
    if (clientID && (clientID !== dealersettings.client.id || localStorage.defaultModule === 'oem'))
      dealerName = CommonHelper.getOtherDealerName(dealersettings, clientID);

    let _client = enquiry.clientID && !_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.group) &&
      !_.isEmpty(dealersettings.group.clients) &&
      dealersettings.group.clients[clientID] ? dealersettings.group.clients[enquiry.clientID] : dealersettings.client;

    const currencySymbol = ((!_.isEmpty(_client) &&
      !_.isEmpty(_client.currency)) ? _client.currency.symbol + ' ' : '$ ');

    const _hasNoPipelineAccess = !enquiry.isAfterSales && _client && _client.settings && !_client.settings.pipelines.some(item => item.value === enquiry.pipeline) ? true : false;

    const _isOEMConverted = localStorage.defaultModule === 'oem' && enquiry.clientID ? true : false;
    const isDeivered = (status === enquiryStatus.DELIEVERD || status === enquiryStatus.LOST || status === enquiryStatus.PENDINGLOST || status === enquiryStatus.PROSPECTLOST) ? true : false;

    const _permissions = ((!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.rolePermissions) &&
      !_.isEmpty(dealersettings.rolePermissions.permissions)) ? dealersettings.rolePermissions.permissions : null);

    const readOnlyEnquiryView = ((!_.isEmpty(_permissions) && _permissions.readOnlyEnquiryView) ? true : false);

    let _clientSettings = clientID && !_.isEmpty(dealersettings) && dealersettings.group &&
      dealersettings.group.clients && dealersettings.group.clients[clientID] && dealersettings.group.clients[clientID].clientSettings;

    if (_.isEmpty(_clientSettings))
      _clientSettings = dealersettings && dealersettings.client && dealersettings.client.settings;

    const isDymStatusOpen = CommonHelper.isEnqStatusOpen(enquiryAllStatus, status)

    const reopenEnquiry = ((!_.isEmpty(_permissions) && _permissions.reopenEnquiry) ? (isGroupDealer ? false : true) : false);
    const lostApproval = ((!_.isEmpty(_permissions) && _permissions.lostApproval) ? (isGroupDealer ? false : true) : false);
    const verifyEnquiry = ((!_.isEmpty(_permissions) && _permissions.verifyEnquiry) ? (isGroupDealer ? false : true) : false);
    const leadVerifyEnabled = ((!_.isEmpty(_clientSettings) && _clientSettings.leadVerification) ? (isGroupDealer ? false : true) : false);
    const lostApprovalEnabled = ((!_.isEmpty(_clientSettings) && _clientSettings.lostApproval) ? (isGroupDealer ? false : true) : false);
    const createLeadOnLost = ((!_.isEmpty(_permissions) && _permissions.createLeadOnLost) ? (isGroupDealer ? false : true) : false);
    const modifyRequirements = ((!_.isEmpty(_permissions) && _permissions.modifyRequirements) ? (isGroupDealer ? false : true) : false);
    const liteVersion = (!_.isEmpty(_clientSettings) && _clientSettings.liteVersion && localStorage.defaultModule !== 'oem') ? true : false;

    const canModifyEnquiry = (readOnlyEnquiryView || _hasNoPipelineAccess || (enquiry && enquiry.isDeleted) || _isOEMConverted || isReadOnlyView || contact?.gdprDeleted) ? false : (((!_.isEmpty(enquiry) && enquiry.owner === dealersettings.id) ||
      (!_.isEmpty(_permissions) &&
        _permissions.modifyOtherEnquiry)) ? (isGroupDealer ? false : true) : false);

    const accessToCreateSubLeads = ((!_.isEmpty(_permissions) && _permissions.accessToCreateSubLeads) ? true : false);
    const soldIDs = Array.prototype.map.call(requirements.filter(m => m.isSold), function (item) { return item.documentID; }).join(",").split(',');
    const enableSubLeadsonWon = (dealersettings?.client?.clientSettings?.enableSubLeadsonWon) ? true : false;

    const requirementAccess = (isDeivered === false && (modifyRequirements || (isDymStatusOpen || (enquiry && enquiry.status === enquiryStatus.OPEN) || !enquiry.status)) && canModifyEnquiry) ? true : false

    const enableProspectLost = ((!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) &&
      !_.isEmpty(dealersettings.client.clientSettings) && dealersettings.client.clientSettings.enableProspectLost) ? true : false);

    const amsMeEnabled = ((!_.isEmpty(dealersettings) &&
      !_.isEmpty(dealersettings.client) && !_.isEmpty(dealersettings.client.integrations) &&
      dealersettings.client.integrations.filter(e => e.type === "amsme")[0] &&
      dealersettings.client.integrations.filter(e => e.type === "amsme")[0].active === true &&
      dealersettings.client.integrations.filter(e => e.type === "amsme")[0].enabled === true
    ) ? true : false);

    const isAmsMeContact = amsMeEnabled && contact && contact.clientID && contact.documentID && !_.isEmpty(amsmeUser) && !_.isEmpty(amsmeUser.amsProLink) &&
      amsmeUser.amsProLink.filter(e => e.clientID === contact.clientID && e.contactID === contact.documentID)[0] ? true : false;

    const amsMeID = !_.isEmpty(contact) && !_.isEmpty(contact.amsMe) && !_.isEmpty(contact.amsMe.meID) ? contact.amsMe.meID : null;

    // FOR DYNAMIC STATUS 		
    let __status = (status && status === enquiryStatus.PENDINGLOST) ? enquiryStatus.LOST : status;
    let objCurrentStatus = status && enquiryAllStatus.filter(e => e.value === __status)[0];
    const isStatusOpen = (_.isEmpty(status) || isDymStatusOpen || status === enquiryStatus.PENDING) ? true : false;
    const isStatusSold = CommonHelper.isEnqStatusSold(enquiryAllStatus, status)

    let _dynamicStatusMenu = CommonHelper.bindEnquiryStatusMenu(enquiryAllStatus, __status, enquiry, (canModifyEnquiry ? true : false), defaultStatus);

    let leadOwner = enquiry?.pipeline === 'LeadsBucket' && enquiry?.ownerInbound ? enquiry?.ownerInbound : enquiry?.owner;
    const accessToFinanceTab = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceManager) ? true : false);
    const accessToFinanceActivity = ((!_.isEmpty(_permissions) && _permissions.accessToFinanceActivity) ? true : false);
    const accessToBusinessManager = ((!_.isEmpty(_permissions) && _permissions.accessToBusinessManager) ? true : false);

    const accessToAfterMarketTab = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketTab) ? true : false);
    const accessToAfterMarketActivity = ((!_.isEmpty(_permissions) && _permissions.accessToAfterMarketActivity) ? true : false);

    const financeEnabled = ((!_.isEmpty(dealersettings?.client?.moduleSettings?.finance) &&
      dealersettings.client.moduleSettings.finance.enabled) ? true : false);

    const financeAllStatues = financeEnabled ? __financestatus : financeStatuses;
    const subLeadAccess = (accessToCreateSubLeads && enableSubLeadsonWon && isStatusSold && canModifyEnquiry && isDeivered === false && soldIDs && soldIDs.length > 1) ? true : false;

    const filesCategory = Object.assign([], _client?.settings?.filesCategory?.filter(a => a.active))
    const financeProviders = Object.assign([], _client?.settings?.financeProviders?.filter(a => a.active))
    const accessToProduct = ((!_.isEmpty(_permissions) && _permissions.accessToProduct) ? true : false);

    const accessToViewFinanceCreditScore = (!_.isEmpty(dealersettings?.client?.csID) && (!_.isEmpty(_permissions) && _permissions.accessToViewFinanceCreditScore) ? true : false);
    const accessToRequestFinanceCreditScore = ((!_.isEmpty(_permissions) && _permissions.accessToRequestFinanceCreditScore) ? true : false);
    return (
      <>
        <MyPipelineContext.Provider
          value={{
            enquiry: enquiry,
            contact: contact,
            isEmptyPipeline: isEmptyPipeline,
            enquiryBasicVM: enquiryBasicVM,
            errors: this.state.errors,
            updateStatus: this.updateStatus,
            handleSelectChange: this.handleSelectChange,
            handleDateChange: this.handleDateChange,
            saveEnquiryInfo: this.saveEnquiryInfo,
            updateEnquiryInfo: this.updateEnquiryInfo,
            handleDynamicStatus: this.handleDynamicStatus,
            handleEnquiryProspectLost: this.handleEnquiryProspectLost,
            dynaicStatusMenu: _dynamicStatusMenu,
            enquiryAllStatus,
            objCurrentStatus,
            isStatusOpen,
            isStatusSold,
            loading,
            liteVersion,
            leadOwner,
            clientsettings: dealersettings,
            accessToFinanceTab,
            accessToFinanceActivity,
            accessToBusinessManager,
            allFinanceUsers,
            financeAllStatues,
            accessToAfterMarketTab,
            accessToAfterMarketActivity,
            allAfterMarketUsers,
            afterMarketStatuses,
            handleReactSelectChange: this.handleReactSelectChange,
            customerlogList,
            activitiesList,
            updateStageEnquiry: this.updateStageEnquiry,
            handleDeleteEnquiry: this.handleDeleteEnquiry,
            canModifyEnquiry: canModifyEnquiry,
            reopenEnquiry: reopenEnquiry,
            isDeivered: isDeivered,
            modules: modules,
            isValidForStageUpdate: this.isValidForStageUpdate,
            isValidForStatusUpdate: this.isValidForStatusUpdate,
            titles: titles,
            newStage: newStage,
            handleStageUpdate: this.handleStageUpdate,
            lostApproval: lostApproval,
            verifyEnquiry: verifyEnquiry,
            leadVerifyEnabled: leadVerifyEnabled,
            lostApprovalEnabled: lostApprovalEnabled,
            handleLostApprovalOpen: this.handleLostApprovalOpen,
            handleVerifyEnqiry: this.handleVerifyEnqiry,
            isGroupDealer: isGroupDealer,
            dealerName: dealerName,
            handleNewLeadOpen: this.handleNewLeadOpen,
            createLeadOnLost: createLeadOnLost,
            requirements: requirements,
            handleRequirementOpen: this.handleRequirementOpen,
            decrementQuantity: this.decrementQuantity,
            incrementQuantity: this.incrementQuantity,
            deleteRequirement: this.deleteRequirement,
            handleReqEdit: this.handleReqEdit,
            handleReqMarkAsPrimary: this.handleReqMarkAsPrimary,
            requirementSoldMark: this.requirementSoldMark,
            modifyRequirements: modifyRequirements,
            primaryReqID: primaryReqID,
            requirementAccess: requirementAccess,
            enableProspectLost: enableProspectLost,
            handleStatusDateOpen: this.handleStatusDateOpen,
            handleStatusOptions: this.handleStatusOptions,
            handleOpenMerge: this.handleOpenMerge,
            handleLeadConvertShow: this.handleLeadConvertShow,
            amsMeEnabled: amsMeEnabled,
            isAmsMeContact: isAmsMeContact,
            amsMeID: amsMeID,
            linkEnquiries,
            subLeadAccess,
            soldIDs,
            handleCheckSubLeads: this.handleCheckSubLeads,
            handleFileClose: this.handleFileClose,
            handleFileOpen: this.handleFileOpen,
            filesCategory,
            accessToProduct,
            files,
            currencySymbol,
            financeProviders,
            handleFirebaseDateChange: this.handleFirebaseDateChange,
            saveEnquiryField: this.saveEnquiryField,
            cancelEnquiryField: this.cancelEnquiryField,
            onCurrencyChange: this.onCurrencyChange,
            isValidForFinanceStatusUpdate: this.isValidForFinanceStatusUpdate,
            handleFinanceInfoOpen: this.handleFinanceInfoOpen,
            loaderCreditScore,
            handleRequestCreditScore: this.isValidForRequestCreditScore,
            accessToRequestFinanceCreditScore,
            accessToViewFinanceCreditScore
          }}
        >
          {children}
        </MyPipelineContext.Provider>

        <PopUpModal show={requirementMultiModal.show}>
          <MultiRequirementSelect
            show={requirementMultiModal.show}
            clsActive={requirementMultiModal.clsActive}
            title={requirementMultiModal.title}
            handleClose={this.handleMultiRequirementClose}
            isVehicle={isVehicle}
            requirements={this.state.requirements}
            handleEdit={this.handleReqEdit}
            getMandatoryFieldsByType={this.getMandatoryFieldsByType}
            setPrimary={this.handleReqMarkAsPrimary}
            setSold={this.requirementSoldMark}
            decrementQuantity={this.decrementQuantity}
            incrementQuantity={this.incrementQuantity}
            deleteRequirement={this.deleteRequirement}
            primaryID={primaryReqID}
            enquiryID={this.props.enquiryid}
            status={enquiry && enquiry.status}
            isDeivered={isDeivered}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
          >
          </MultiRequirementSelect>
        </PopUpModal>

        <PopUpModal show={requirementModal.show}>
          <AddRequirement
            show={requirementModal.show}
            clsActive={requirementModal.clsActive}
            title={requirementModal.title}
            handleClose={this.handleRequirementClose}
            isVehicle={isVehicle}
            isAfterSales={isAfterSales}
            enquiryID={this.props.enquiryid}
            primaryID={primaryReqID}
            isDeivered={(isAccessToReOpen || !isVehicle || _.isEmpty(this.state.requirements)) ? false : (requirementAccess ? false : true)}
            requirement={requirementModal.requirement}
            clone={requirementModal.clone}
            mandatoryFields={requirementModal.mandatoryFields}
            enqStatus={enquiry && enquiry.status}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            requirements={this.state.requirements}
            contactID={!_.isEmpty(enquiry) && !_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : enquiry.contactID}
            ownedVehicles={this.state.ownedVehicles}
            defaultMakes={this.state.defaultMakes}
          >
          </AddRequirement>
        </PopUpModal>

        <PopUpModal show={contactModal.show}>
          <AddContact
            docID={this.state.contact.documentID}
            contactName={enquiry.engageContactName ? enquiry.engageContactName : ''}
            engageNewContact={enquiry.engageNewContact}
            show={contactModal.show}
            clsActive={contactModal.clsActive}
            handleClose={this.handleContactClose}
            title={contactModal.title}
            showSearch={isStatusOpen}
            mandatoryFields={contactModal.mandatoryFields}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            enquiryID={this.props.enquiryid}
            enquiry={this.state.enquiry}
            enquiryPipeline={enquiry?.pipeline}
            //clientModule={clientModule}
            campaign={enquiry.campaign}
            origin={enquiry.origin}
            history={this.props.history}
          >
          </AddContact>
        </PopUpModal>

        <PopUpModal show={enquiryModal.show}>
          <EnquiryFields
            title='enquiryInfo'
            show={enquiryModal.show}
            errors={enquiryModal.errors}
            newStatus={enquiryModal.newStatus}
            ismodifyStatusDate={enquiryModal.ismodifyStatusDate}
            isModifyInboundOwner={enquiryModal.isModifyInboundOwner}
            mandatoryFields={enquiryModal.mandatoryFields}
            financeRequired={enquiryModal?.financeRequired}
            enquiry={this.state.enquiry}
            canModifyEnquiry={canModifyEnquiry}
            enquiryID={this.props.enquiryid}
            requirement={this.state.enquiry.requirement}
            requirements={this.state.requirements}
            enquiryAllStatus={enquiryAllStatus}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            handleClose={this.handleEnquiryOptionClose}
            handleEditRequirement={this.handleReqEdit}
          />
        </PopUpModal>

        <PopUpModal show={subLeadModal.show}>
          <SubLeadsOptions
            title='subLeadsInfo'
            show={subLeadModal.show}
            enquiries={subLeadModal.enquiries}
            newStatus={subLeadModal.status}
            mandatoryFields={subLeadModal.mandatoryFields}
            enquiry={this.state.enquiry}
            contact={this.state.contact}
            enquiryOptionsDF={enquiryOptionsDF}
            primaryID={this.props.enquiryid}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            handleClose={this.handleCreateSubLeadsClose}
            enquiryAllStatus={enquiryAllStatus}
          />
        </PopUpModal>

        <PopUpModal show={showLeadConvert}>
          <LeadConvert
            show={showLeadConvert}
            handleModalClose={this.handleModalClose}
            pipeline={enquiry.pipeline}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            enquiryID={this.props.enquiryid}
            loader={this.state.convertLeadLoader}
            enquiry={this.state.enquiry}
          />
        </PopUpModal>

        <PopUpModal show={showMergeEnqList}>
          <MergeList
            show={showMergeEnqList}
            title={'Select Enquiry To Merge'}
            dealersettings={dealersettings}
            handleClose={this.handleCloseMerge}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
            contactID={!_.isEmpty(enquiry) && !_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : enquiry.contactID}
            enquiryID={this.props.enquiryid}
            primaryEnquiry={this.state.enquiry}
          />
        </PopUpModal>

        <PopUpModal show={deliveryModal.show}>
          <DeliveredOptions
            title='deliveryOptions'
            show={deliveryModal.show}
            enquiryID={this.props.enquiryid}
            requirement={this.state.enquiry.requirement}
            dealersettings={dealersettings}
            handleClose={this.handleDeliveryOptionClose}
          />
        </PopUpModal>

        <PopUpModal show={lostModal.show}>
          <LostReasons
            title='lostReasons'
            show={lostModal.show}
            enquiryID={this.props.enquiryid}
            dealersettings={dealersettings}
            handleClose={this.handleLostReasonsClose}
          />
        </PopUpModal>
        <PopoverPanel
          showpopover={lostApprovalPopOver.showpopover}
          targetpopover={lostApprovalPopOver.targetpopover}
          title="Lost Approval"
          position="bottom"
          id="notes-lost-approval-quickview"
          className="popover-pipeline-switch"
          closepopover={() => {
            this.setState({
              lostApprovalPopOver: { showpopover: false, targetpopover: '' }
            });
          }}>
          <LostApprovalNotes
            handleClose={this.handleLostApprovalClose}
            clientUsers={clientUsers}
            lostReason={this.state.enquiry.lostReason ? this.state.enquiry.lostReason : ''}
            lostSubReason={this.state.enquiry.lostSubReason ? this.state.enquiry.lostSubReason : ''}
            dealersettings={dealersettings}
            clientID={this.state.enquiry.clientID}
          // atat={this.state}
          // usedWithList={true}
          />
        </PopoverPanel>

        <PopUpModal show={showNewLeadModal}>
          <Modal
            show={showNewLeadModal}
            onHide={this.handleNewLeadClose}
            backdrop="static"
            backdropClassName={showNewLeadModal ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
              <Modal.Title>{'Add New Enquiry'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0 pt-0">

              <PipelineSwitch
                clientID={enquiry.clientID}
                dealersettings={dealersettings}
                handleClose={this.handleNewLeadClose}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
                pipeline={enquiry.pipeline}
                owner={leadOwner}
                status={enquiry.status}
                isNewEnq={true}
              />

            </Modal.Body>
          </Modal>

        </PopUpModal>

        <PopUpModal show={showPipelineOnOpen}>
          <Modal
            show={showPipelineOnOpen}
            onHide={this.handleOpenLeadClose}
            backdrop="static"
            backdropClassName={showPipelineOnOpen ? 'overlay-modal active' : ''}
            dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
              <Modal.Title>{'Select Pipeline'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-0 pt-0">

              <PipelineSwitch
                clientID={enquiry.clientID}
                dealersettings={dealersettings}
                handleClose={this.handleOpenLeadClose}
                clientUsers={clientUsers}
                groupUsers={groupUsers}
                allClientUsers={enquiry.isAfterSales ? allClientUsers : []}
                pipeline={enquiry.pipeline}
                stage={enquiry.stage}
                owner={leadOwner}
                status={enquiry.status}
                isNewEnq={true}
              />

            </Modal.Body>
          </Modal>

        </PopUpModal>

        <PopUpModal show={completedTradeinsList.show}>
          <TradeinCompletedList
            show={completedTradeinsList.show}
            isMandatory={completedTradeinsList.isMandatory}
            isReopen={completedTradeinsList.isReopen}
            enquiryID={this.props.enquiryid}
            handleClose={() => {
              this.setState({
                completedTradeinsList: {
                  show: false,
                  isMandatory: false,
                  isReopen: false
                }
              })
            }}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
          />
        </PopUpModal>

        <PopUpModal show={fileModal.show}>
          <AddFile
            show={fileModal.show}
            activeTab={fileModal.category}
            module={fileModal.type}
            dynamicCategories={fileModal.categories}
            contactID={!_.isEmpty(enquiry) && !_.isEmpty(enquiry.contact) ? enquiry.contact.documentID : enquiry.contactID}
            enquiryID={this.props.enquiryid}
            handleClose={this.handleFileClose}
            dealersettings={dealersettings}
            clientUsers={clientUsers}
            groupUsers={groupUsers}
          ></AddFile>
        </PopUpModal>

        <PopUpModal show={filesPopOver.show}>
          {
            filesPopOver.show && (
              <Modal
                show={filesPopOver.show}
                onHide={this.handleFileClose}
                backdrop={true}//"static"
                backdropClassName={
                  filesPopOver.show ? "overlay-modal active" : ""
                }
                dialogClassName={`modal-dialog-centered modal-add-requirement`}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{"Documents"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0 pt-0">

                  <div className="quickview-files-wraper">
                    <div className="add-files-button">
                      <a href="#" className="mini-button" onClick={(e) => {
                        e.preventDefault();
                        this.handleFileOpen(null, filesPopOver.category, filesPopOver.type, filesPopOver.categories, filesPopOver.categoryName);
                      }}>
                        <i className="ico icon-add"></i>
                      </a>
                    </div>
                    <div className="file-manager-nav pl-1 file-manager-tooltipfix">
                      <div className="file-manager-accordion-icon"> <i className="ico icon-folder"></i> </div>
                      <div className="file-manager-accordion-head">{filesPopOver.categoryName ? filesPopOver.categoryName : 'Files'} </div>
                    </div>
                    < div style={{
                      minHeight: "100px",
                      //overflow: "auto",
                      //position: "relative"
                    }}>
                      <Files
                        enquiryID={this.props.enquiryid}
                        contactID={this.state.contact?.documentID}
                        contact={this.state.contact}
                        dealersettings={dealersettings}
                        clientUsers={clientUsers}
                        groupUsers={groupUsers}
                        isDeivered={canModifyEnquiry ? isDeivered : true}
                        dynamicCategories={filesPopOver.categories}
                        customTab={filesPopOver.category}
                        module={filesPopOver.type}
                      ></Files>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )
          }
        </PopUpModal>
      </>
    )
  }
}
