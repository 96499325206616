/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../../constants/translate';
import { ReactSelect } from '../../../components';
export default class Slots extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            fields: {
                slot: ''
            },
            errors: {},
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {

    }

    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        //let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        // let errorClass = 'input_error';

        // for (let [key, value] of Object.entries(fields)) {
        //     if (!fields[key]) {
        //         formIsValid = false;
        //         errors[key] = errorClass;

        //     }
        // }
        // this.setState({ errors: errors });

        if (formIsValid === true) {
            this.isLoading = true;

            this.props.handleClose(fields.slot, true);

            return true;
        }
        else
            return formIsValid;
    };


    render() {
        const { slot } = this.state.fields;
        const { show, isGenericLayout, title, eventSlots, buttonTitle } = this.props;
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton>
                        <Modal.Title> <h5 className="modal-title"><Translate text={title} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">
                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''}`}>
                            <div className={`row ${isGenericLayout ? 'w-100 d-block border-0' : ''}`}>
                                <div className="search-pop form-style">
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label > <Translate text={'eventSlotOpt'} /></label>
                                            <ReactSelect
                                                options={eventSlots}
                                                name={"slot"}
                                                placeholder={'select slot'}
                                                onChange={this.handleReactSelectChange}
                                                value={slot}
                                                classNamePrefix={`${this.state.errors["slot"]} basic-select`}
                                            >
                                            </ReactSelect>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {this.isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={buttonTitle ? buttonTitle : 'submit'} />
                        </button>
                    </Modal.Footer>
                </Modal>

            </>


        );
    }
}

