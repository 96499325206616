import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap'
import moment from 'moment'
// import { combineLatest, of } from 'rxjs';
// import { map } from 'rxjs/operators';
// import { collection } from 'rxfire/firestore';
import { ContentOverlay, AntDateRangePicker, ReactSelect, PopUpModal, ReactMultiSelect, PopoverPanel } from "../../../components";
import CommonHelper from '../../../services/common'
import _images from '../../../images'
import EnquiryList from '../../dashboard/enquiryList'
import TestDriveList from '../../dashboard/testdriveList'
import { CustomToggle, CustomFilter, CustomMenu } from '../../../components/customdropdown';
import ReactExport from 'react-export-excel-xlsx-fix';
import ActivityList from '../../dashboard/activitiesList'
import { reportVM, reportDynColumns, defaultFilters, reportGroupBy } from '../viewModel'
import Swal from 'sweetalert2'
import DynamicFilters from "../dynFilters";
import AddReport from '../addDynReport'
import ScheduleReport from '../scheduleReport';
import cronstrue from 'cronstrue';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const leadTypes = [
    { value: 'all', label: 'All Leads' },
    { value: 'new', label: 'New Leads' },
    { value: 'existing', label: 'Existing Leads' },
    { value: 'existingCustomer', label: 'Existing Customers Leads' },
    { value: 'newExistingSold', label: 'New Leads with Existing Sold / Test Drives' }
]

const statisFilters = [
    'user',
    'inboundUser',
    'enqAddedBy',
    'makeModels',
    'salesType',
    'enquiryTypes',
    'campaigns',
    'origins',
    'leadSource'
];

const LeadProgressKPI = (props) => {
    const [loader, setLoader] = useState(true)
    const [allEnquiries, setEnquiries] = useState([]);
    const [allTestdrives, setTestdrives] = useState([]);
    const [userOptions, setUserOptions] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
        endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
    })
    const todayStart = window.firebase.firestore.Timestamp.fromDate(moment().startOf('day')._d)
    const todayEnd = window.firebase.firestore.Timestamp.fromDate(moment().endOf('day')._d)
    const [progressReport, setProgressReport] = useState([])
    const [dates, setDates] = useState([]);
    const [modelShow, setModelShow] = useState({
        enquiry: false,
        testdrive: false,
        activity: false
    })
    const [popHeader, setPopHeader] = useState()
    const [filter, setFilter] = useState(null)
    const [selectedUser, setUser] = useState();
    const [refreshData, setRefreshData] = useState(true)
    const [refreshClientData, setRefreshClientData] = useState(false)
    const [sortConfig, setSortConfig] = useState();
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])
    const [salesTypes, setSalesTypes] = useState([])
    const [selectedMake, setSelectedMake] = useState()
    const [selectedModel, setSelectedModel] = useState()
    const [selectedDMSType, setSelectedDMSType] = useState()
    const [selectedEnqType, setSelectedEnqType] = useState()
    const [selectedOrigin, setSelectedOrigin] = useState()
    const [selectedLeadSource, setSelectedLeadSource] = useState()
    const [selectedCampaign, setSelectedCampaign] = useState()
    const [selectedSaleType, setSelectedSaleType] = useState()
    const [tempData, setTempData] = useState({
        enquiries: [],
        walkins: [],
        requirements: []
    })
    const [filterShow, setShow] = useState(false)
    const [showCount, setShowCount] = useState(false)
    const node = useRef();
    const [kpis, setKpis] = useState([]);
    const [selectedKpi, setSelectedKpi] = useState({})
    const [csvData, setCSVData] = useState([])
    const [selectedLeadType, setLeadType] = useState()
    const [doneActivities, setDoneActivities] = useState([]);
    const [isInbound, setInbound] = useState();
    const [reportJSON, setReportJSON] = useState({})
    const [showModel, setShowmodel] = useState(false)
    const [reportColumns, setReportColumns] = useState(reportDynColumns);
    const [counts, setCounts] = useState([])
    const ownerDashboard = (!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.rolePermissions) &&
        !_.isEmpty(props.dealersettings.rolePermissions.permissions) &&
        props.dealersettings.rolePermissions.permissions.ownerDashboard) ? true : false
    const inclInboundLeadsinRpt = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.inclInboundLeadsinRpt) ? true : false);
    const [objFilter, setObjFilter] = useState(defaultFilters)
    const enquiryStatus = props.dealersettings &&
        props.dealersettings.client &&
        props.dealersettings.client.settings &&
        props.dealersettings.client.settings.enquiryStatus ? props.dealersettings.client.settings.enquiryStatus : [];

    const calcCurrentStatus = ((!_.isEmpty(props.dealersettings) &&
        !_.isEmpty(props.dealersettings.client) &&
        !_.isEmpty(props.dealersettings.client.clientSettings) &&
        props.dealersettings.client.clientSettings.calcCurrentStatus) ? 'true' : 'false');

    const multiTDperLead = props?.dealersettings?.client?.clientSettings?.multiTDperLead === true;
    const [schedueReport, setSchedeReport] = useState({});
    const [multiSchedule, setMultiSchedule] = useState([]);
    const [popover, setPopover] = useState({ show: false, target: null },)

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        if (_.isEmpty(selectedKpi))
            return
        const refScheduleReport = window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-${selectedKpi.value}`)
            .onSnapshot(doc => {
                if (doc.exists && doc.data().enabled === true && !_.isEmpty(doc.data().cron)) {
                    setSchedeReport(doc.data())
                    if (doc.data().multiSchedule?.length > 0) {
                        let refPromise = [];
                        doc.data().multiSchedule.forEach(rpt => {
                            refPromise.push(window.firebase.firestore().doc(`schedular/${rpt}`).get())
                        })
                        Promise.all(refPromise)
                            .then(docs => {
                                let rpts = [];
                                docs.forEach(doc => {
                                    if (doc.exists) {
                                        let arrCron = [];
                                        if (doc.data().cron && doc.data().cron !== '* * * * *') {
                                            arrCron = doc.data().cron.split(' ');
                                        }
                                        rpts.push({
                                            ...doc.data(),
                                            schedulartime: arrCron.length > 0 ?
                                                window.firebase.firestore.Timestamp.fromDate(new Date(`${moment().format('YYYY-MM-DD')} ${arrCron[1]}:${arrCron[0]}`)) : null
                                        })
                                    }
                                })
                                setMultiSchedule([
                                    doc.data(),
                                    ...rpts
                                ]);
                            })
                    }
                }
                else {
                    setSchedeReport(null)
                }
            })
        return () => {
            refScheduleReport && refScheduleReport();
        }

    }, [selectedKpi])

    const handleClick = e => {
        if ((node && node.current && node.current.contains(e.target)) || e.target.classList.contains('ant-picker-cell-inner') || e.target.tagName === 'path' || e.target.tagName === 'svg' || e.target.classList.contains('basic-select__indicator')) {
            return;
        }
        setShow(false);
    };

    useEffect(() => {
        if (_.isEmpty(progressReport))
            return

        let _headers = [];
        let csvStyle = CommonHelper.getCSVStyle();
        _headers.push(
            {
                title: CommonHelper.getCustomValue(reportGroupBy, reportJSON.groupBy, 'tableHeader', 'value'),
                dynValue: 'displayName',
                dynWidth: 10,
                ...csvStyle.header
            })

        reportJSON.columns.forEach((val) => {
            var cols = reportColumns.find(item => item.value === val)
            if (cols) {
                _headers.push({ title: cols.name, dynValue: val, dynWidth: cols.name.length, ...csvStyle.header })
            }
            else {
                _headers.push({ title: val, dynValue: val, dynWidth: val.toString().length, ...csvStyle.header })
            }
        })

        var exportData = []
        var totals = {
            inboundCall: _.sumBy(progressReport, "inboundCall"),
            outboundCall: _.sumBy(progressReport, "outboundCall"),
            totalCall: _.sumBy(progressReport, "totalCall"),
            messageLog: _.sumBy(progressReport, "messageLog"),
            emailLog: _.sumBy(progressReport, "emailLog"),
            noteLog: _.sumBy(progressReport, "noteLog"),
            appointmentScheduled: _.sumBy(progressReport, "appointmentScheduled"),
            testdriveScheduled: _.sumBy(progressReport, "testdriveScheduled"),
            callScheduled: _.sumBy(progressReport, "callScheduled"),
            mailScheduled: _.sumBy(progressReport, "mailScheduled"),
            deliveryScheduled: _.sumBy(progressReport, "deliveryScheduled"),
            appointment: _.sumBy(progressReport, "appointment"),
            testdriveActivity: _.sumBy(progressReport, "testdriveActivity"),
            callActivity: _.sumBy(progressReport, "callActivity"),
            mailActivity: _.sumBy(progressReport, "mailActivity"),
            deliveryActivity: _.sumBy(progressReport, "deliveryActivity"),
            appointmentsold: `${(_.sumBy(progressReport, "apptsold") > 0 ? Math.round((100 / _.sumBy(progressReport, "apptsold")) * _.sumBy(progressReport, "won")) : 0)}%`,
            appointmenttestdrive: `${(_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0)}%`,
            testdrivetotestdrive: `${(_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0)}%`,
            apptsold: _.sumBy(progressReport, "apptsold"),
            delivered: _.sumBy(progressReport, "delivered"),
            displayName: "Totals",
            exisitngLeads: _.sumBy(progressReport, "exisitngLeads"),
            id: "totals",
            lost: _.sumBy(progressReport, "lost"),
            name: "Totals",
            totalInbound: _.sumBy(progressReport, "totalInbound"),
            overallPending: _.sumBy(progressReport, "overallPending"),
            pendingLeads: _.sumBy(progressReport, "pendingLeads"),
            convertedLeads: _.sumBy(progressReport, "convertedLeads"),
            show: _.sumBy(progressReport, "show"),
            noshow: _.sumBy(progressReport, "noshow"),
            responseTime: (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00'),
            maxresponseTime: (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00'),
            minresponseTime: (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00'),
            newLeads: _.sumBy(progressReport, "newLeads"),
            open: _.sumBy(progressReport, "open"),
            tdappt: _.sumBy(progressReport, "tdappt"),
            tdtestdrive: _.sumBy(progressReport, "tdtestdrive"),
            testdrive: _.sumBy(progressReport, "testdrive"),
            testdriveConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) : 0)}%`,
            total: _.sumBy(progressReport, "total"),
            tradein: _.sumBy(progressReport, "tradein"),
            tradeincompleted: _.sumBy(progressReport, "tradeincompleted"),
            tradeininprogress: _.sumBy(progressReport, "tradeininprogress"),
            tradeinlost: _.sumBy(progressReport, "tradeinlost"),
            tradeinwon: _.sumBy(progressReport, "tradeinwon"),
            tradeinwonConv: `${(_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0)}%`,
            walkin: _.sumBy(progressReport, "walkin"),
            newwalkin: _.sumBy(progressReport, "newwalkin"),
            repeatwalkin: _.sumBy(progressReport, "repeatwalkin"),
            carryover: _.sumBy(progressReport, "carryover"),
            doneActivity: _.sumBy(progressReport, "doneActivity"),
            overdueActivity: _.sumBy(progressReport, "overdueActivity"),
            walkinsold: `${(_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0)}%`,
            walkintestdrive: `${(_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0)}%`,
            won: _.sumBy(progressReport, "won"),
            wonConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) : 0)}%`,
            scheduledConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) : 0)}%`,
            showConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) : 0)}%`,
            noshowConv: `${(_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) : 0)}%`,
            convertedConv: `${(_.sumBy(progressReport, "totalInbound") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) : 0)}%`,
            newLeadsConv: `${(_.sumBy(progressReport, "totalnewLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) : 0)}%`,
            exisitngLeadsConv: `${(_.sumBy(progressReport, "totalexisitngLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) : 0)}%`,
            totalConv: `${(_.sumBy(progressReport, "totalLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) : 0)}%`,
            ...CommonHelper.getLeadsByEnquiryTypeTotal({ ...props, report: reportJSON }, progressReport, isInbound)
        };

        progressReport.forEach(rec => {
            let obj = rec;
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${obj[col.dynValue]}${col.percentage ? '%' : ''}`, ...csvStyle.cell, dynValue: col.dynValue, dynWidth: obj[col.dynValue] ? obj[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        })
        if (exportData.length > 0) {
            let arrCols = []
            _headers.forEach(col => {
                arrCols.push({ value: `${totals[col.dynValue]}`, ...csvStyle.footer, dynValue: col.dynValue, dynWidth: totals[col.dynValue] ? totals[col.dynValue].toString().length : 0 })
            })
            exportData.push(arrCols)
        }
        _headers.forEach(rec => {
            var w = _.maxBy(exportData.map(arrrec => { return { ...arrrec.find(r => r.dynValue === rec.dynValue) } }), 'dynWidth')
            _headers = _headers.map(h => {
                return h.dynValue === rec.dynValue && w && w.dynWidth > h.dynWidth ? {
                    ...h,
                    'width': { wch: w.dynWidth + 5 }
                } : {
                    ...h,
                    'width': { wch: h.dynWidth + 5 }
                }
            })
        })
        setCSVData([{
            columns: [..._headers.map(r => { return { ...r, value: r.title, widthPx: (r?.width?.wch || 10) * 7 } })],
            data: [...exportData]
        }])
    }, [progressReport])

    useEffect(() => {
        let brands = props.dealersettings.client.brands;
        let makes = [];
        let types = [];
        let models = [];
        brands.forEach((doc) => {
            makes.push({
                value: doc.value,
                label: doc.name,
                models: doc.models,
                active: _.isBoolean(doc.active) ? doc.active : true,
            });
        });

        if (selectedMake) {
            let _models = !_.isEmpty(makes.filter(m => m.value === selectedMake.value)[0]) ? makes.filter(m => m.value === selectedMake.value)[0].models : [];
            _models.filter((model, index) => {
                if (!_.isEmpty(model.type) && index === _models.findIndex(obj => { return obj.type === model.type; })) {
                    types.push({
                        value: model.type,
                        label: model.type,
                        active: _.isBoolean(model.active) ? model.active : true,
                    });
                }
            })

            _models && _models.forEach((data, index) => {
                models.push({
                    value: data.value,
                    label: CommonHelper.displayModelName(data),
                    active: _.isBoolean(data.active) ? data.active : true,
                });
            });
        }
        setMakes(makes)
        setModels(models)
    }, [selectedMake])

    useEffect(() => {
        if (_.isEmpty(props.clientUsers))
            return
        let _users = [];
        props.clientUsers.filter(a => Boolean(a.superAdmin) === false).forEach(rec => {
            _users.push({
                value: rec.id,
                active: _.isBoolean(rec.active) ? rec.active : true,
                searchlabel: rec.name,
                label: <div className="drop-image">
                    <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                    {rec.name}
                </div>,
                data: rec,
                name: rec.name
            })
        })
        setUserOptions(_users)
    }, [props.clientUsers])

    useEffect(() => {
        if (_.isEmpty(props.dealersettings) || _.isEmpty(props.dealersettings.client))
            return

        if (!_.isEmpty(props.dealersettings.client.settings['salesType'])) {
            let _saleTypes = [];
            props.dealersettings.client.settings['salesType'].filter(a => a.active === true).map(rec => {
                return _saleTypes.push({
                    value: rec.value,
                    label: rec.name
                });
            })
            setSalesTypes(_saleTypes)
        }
    }, [props.dealersettings])

    useEffect(() => {

        let _kpis = [];
        _kpis.push({ label: 'Enquiry Types', name: 'Enquiry Types', value: 'enquiryTypes', fieldValue: 'enquiryTypeValue', fieldName: 'enquiryType' })
        _kpis.push({ label: 'Campaigns', name: 'Campaigns', value: 'campaigns', fieldValue: 'campaignValue', fieldName: 'campaign' })
        _kpis.push({ label: 'Lead Origins', name: 'Lead Origins', value: 'origins', fieldValue: 'originValue', fieldName: 'origin' })
        setKpis(_kpis)
        setSelectedKpi(_kpis[0])

        setReportColumns([...reportColumns.filter(a => _.isEmpty(a.sourceType)).map(rec => {
            return rec.status === true && _.find(enquiryStatus, { value: rec.value }) ? {
                ...rec,
                name: `${_.find(enquiryStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
                label: `${_.find(enquiryStatus, { value: rec.value }).name} ${props.dealersettings.isPorsche === true ? ` ${rec.value === 'open' ? 'in' : 'from'} C@P` : ''}`,
            } : rec.status === true && props.dealersettings.isPorsche === true ? {
                ...rec,
                name: `${rec.name} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
                label: `${rec.label} ${rec.value === 'open' ? 'in' : 'from'} C@P`,
            } : { ...rec }
        }), ...CommonHelper.getReportColumns({ ...props, report: reportJSON })]);

        window.firebase.firestore().doc(`reports/kpi-${props.dealersettings.client.id}`)
            .onSnapshot(snap => {
                if (snap.exists) {
                    setReportJSON({
                        ...snap.data(),
                        documentID: snap.id,
                        columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'overallPending', 'convertedLeads', ...snap.data().columns]),
                        pipeline: _.map(props.pipeline.filter(item => !Boolean(item.disableReport)), function (e) { return e.value; })
                    });
                    setRefreshData(true);
                    setInbound((snap.data().pipeline
                        && snap.data().pipeline.some(b => b === 'LeadsBucket')
                        && snap.data().pipeline.length === 1) ? true : false)
                }
                else {
                    const newReportVM = Object.assign({}, reportVM);
                    newReportVM.documentID = `kpi-${props.dealersettings.client.id}`;
                    newReportVM.name = 'Lead KPIs';
                    newReportVM.clientID = props.dealersettings.client.id;
                    newReportVM.reportType = 'table';
                    newReportVM.groupBy = selectedKpi?.value || _kpis[0].value;
                    newReportVM.groupSubtype = ['all'];
                    newReportVM.columns = _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...reportDynColumns.filter(a => a.reportType && a.reportType.indexOf('leadKpi') >= 0).map(rec => {
                        return rec.value
                    }), 'doneActivity']);
                    newReportVM.pipeline = _.map(props.pipeline.filter(item => !Boolean(item.disableReport)), function (e) { return e.value; });
                    newReportVM.static = true;
                    newReportVM.visibility = 'shared';
                    setReportJSON(newReportVM)
                    setInbound((props.pipeline
                        && props.pipeline.some(b => b === 'LeadsBucket')
                        && props.pipeline.length === 1) ? true : false)
                }
            })


    }, [])

    useEffect(() => {
        if (!refreshData || _.isEmpty(reportJSON))
            return

        setLoader(true)
        try {
            const reportData = window.firebase.functions().httpsCallable('generic-getData');
            reportData({
                type: props?.dealersettings?.mvwReport ? "getDynamicReportV2" : "getDynamicReport",
                params: JSON.stringify({
                    clientID: props.dealersettings.client.id,
                    reportJSON: JSON.stringify({
                        ...reportJSON,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all']
                    }),
                    startDate: moment.unix(dateRange.startDate.seconds).format('YYYY-MM-DD'),
                    endDate: moment.unix(dateRange.endDate.seconds).format('YYYY-MM-DD'),
                    timezone: !_.isEmpty(props.dealersettings.client.timezone) ? props.dealersettings.client.timezone : moment.tz.guess(),
                    leadType: selectedLeadType ? selectedLeadType.value : '',
                    calcCurrentStatus,
                    multiTDperLead,
                    ...{
                        ...objFilter,
                        salesType: (_.isEmpty(objFilter.salesType) && _.isArray(props.dealersettings.accessSalesType) && props.dealersettings.accessSalesType.length > 0) ?
                            props.dealersettings.accessSalesType : objFilter.salesType,
                        user: ownerDashboard ? [localStorage.uid] : objFilter.user,
                        isPorsche: props.dealersettings.isPorsche
                    }
                })
            }).then((response) => {
                setLoader(false)
                setRefreshData(false)
                if (response.data.success) {
                    loadReportData(response.data.data)
                }
                else {
                    Swal.fire('Something went wrong', '', 'error')
                }
            });
        }
        catch (error) {
            setLoader(false)
            Swal.fire('Something went wrong', '', 'error')
        }
    }, [refreshData, reportJSON])

    const loadReportData = (result) => {
        let _report = result[0] ? result[0]['data_json'][0][props.dealersettings.client.id] : []
        let reports = [];
        _report.forEach(rec => {
            if (Object.keys(rec).filter(r => _.isArray(rec[r])).some(r => rec[r].length > 0)) {
                reports.push({
                    ...rec,
                    arrcarryover: rec.arrcarryover ? rec.arrcarryover.filter(e => rec.arrexisitngLeads ? !rec.arrexisitngLeads.some(a => a === e) : true) : 0,
                    profileImage: reportJSON.groupBy === 'user' && _.find(props.clientUsers, { id: rec.id }) ? _.find(props.clientUsers, { id: rec.id }).profileImage : '',
                    newLeads: rec.arrnewLeads ? rec.arrnewLeads.length : 0,
                    exisitngLeads: rec.arrexisitngLeads ? rec.arrexisitngLeads.length : 0,
                    pendingLeads: rec.arrpendingLeads ? rec.arrpendingLeads.length : 0,
                    totalInbound: rec.arrtotalInbound ? rec.arrtotalInbound.length : 0,
                    overallPending: rec.arroverallPending ? rec.arroverallPending.length : 0,
                    convertedLeads: rec.arrconvertedLeads ? rec.arrconvertedLeads.length : 0,
                    show: rec.arrshow ? rec.arrshow.length : 0,
                    showCount: rec.arrshow ? rec.arrshow.length : 0,
                    noshow: rec.arrnoshow ? rec.arrnoshow.length : 0,
                    //avg response time
                    arrresponseLeads: rec.arrresponseTime ? _.uniq(rec.arrresponseTime.map(r => { return r.documentID })) : [],
                    totalresponseTime: _.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0,
                    responseTime: CommonHelper.timeformatfromSeconds(_.sumBy(rec.arrresponseTime, 'responseTime') > 0 ? _.meanBy(rec.arrresponseTime, "responseTime") : 0, props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    //max reposnse time
                    arrmaxresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrmaxresponseLeads: _.maxBy(rec.arrresponseTime, 'responseTime') ? [_.maxBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalmaxresponseTime: _.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    maxresponseTime: CommonHelper.timeformatfromSeconds((_.maxBy(rec.arrresponseTime, 'responseTime') ? _.maxBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    //min response time
                    arrminresponseTime: rec.arrresponseTime ? rec.arrresponseTime : [],
                    arrminresponseLeads: _.minBy(rec.arrresponseTime, 'responseTime') ? [_.minBy(rec.arrresponseTime, 'responseTime').documentID] : [],
                    totalminresponseTime: _.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0,
                    minresponseTime: CommonHelper.timeformatfromSeconds((_.minBy(rec.arrresponseTime, 'responseTime') ? _.minBy(rec.arrresponseTime, 'responseTime').responseTime : 0), props.dealersettings.client, (reportJSON.reportType === 'table' ? '' : 'seconds')),
                    open: rec.arropen ? rec.arropen.length : 0,
                    statusModified: rec.arrstatusModified ? rec.arrstatusModified.length : 0,
                    //won: rec.arrwon ? rec.arrwon.length : 0,
                    arrwon: rec.arrwon ? _.uniq(rec.arrwon.map(r => { return r.documentID })) : [],
                    won: rec.arrwon ? _.sumBy(rec.arrwon, "soldCount") : 0,
                    arrdelivered: rec.arrdelivered ? _.uniq(rec.arrdelivered.map(r => { return r.documentID })) : [],
                    delivered: rec.arrdelivered ? _.sumBy(rec.arrdelivered, "soldCount") : 0,
                    lost: rec.arrlost ? rec.arrlost.length : 0,
                    prospectLost: rec.arrprospectLost ? rec.arrprospectLost.length : 0,
                    leadLost: rec.arrleadLost ? rec.arrleadLost.length : 0,
                    totalLost: rec.arrtotalLost ? rec.arrtotalLost.length : 0,
                    //delivered: rec.arrdelivered ? rec.arrdelivered.length : 0,
                    walkin: rec.arrwalkin ? rec.arrwalkin.length : 0,
                    newwalkin: rec.arrnewwalkin ? rec.arrnewwalkin.length : 0,
                    repeatwalkin: rec.arrrepeatwalkin ? rec.arrrepeatwalkin.length : 0,
                    testdrive: rec.arrtestdrive ? rec.arrtestdrive.length : 0,
                    tradein: rec.arrtradein ? rec.arrtradein.length : 0,
                    tradeinwon: rec.arrtradeinwon ? rec.arrtradeinwon.length : 0,
                    tradeininprogress: rec.arrtradeininprogress ? rec.arrtradeininprogress.length : 0,
                    tradeincompleted: rec.arrtradeincompleted ? rec.arrtradeincompleted.length : 0,
                    tradeinlost: rec.arrtradeinlost ? rec.arrtradeinlost.length : 0,
                    inboundCall: rec.arrinboundCall ? rec.arrinboundCall.length : 0,
                    outboundCall: rec.arroutboundCall ? rec.arroutboundCall.length : 0,
                    totalCall: rec.arrtotalCall ? rec.arrtotalCall.length : 0,
                    messageLog: rec.arrmessageLog ? rec.arrmessageLog.length : 0,
                    emailLog: rec.arremailLog ? rec.arremailLog.length : 0,
                    noteLog: rec.arrnoteLog ? rec.arrnoteLog.length : 0,
                    appointmentScheduled: rec.arrappointmentScheduled ? rec.arrappointmentScheduled.length : 0,
                    testdriveScheduled: rec.arrtestdriveScheduled ? rec.arrtestdriveScheduled.length : 0,
                    callScheduled: rec.arrcallScheduled ? rec.arrcallScheduled.length : 0,
                    mailScheduled: rec.arrmailScheduled ? rec.arrmailScheduled.length : 0,
                    deliveryScheduled: rec.arrdeliveryScheduled ? rec.arrdeliveryScheduled.length : 0,
                    appointment: rec.arrappointment ? rec.arrappointment.length : 0,
                    testdriveActivity: rec.arrtestdriveActivity ? rec.arrtestdriveActivity.length : 0,
                    callActivity: rec.arrcallActivity ? rec.arrcallActivity.length : 0,
                    mailActivity: rec.arrmailActivity ? rec.arrmailActivity.length : 0,
                    deliveryActivity: rec.arrdeliveryActivity ? rec.arrdeliveryActivity.length : 0,
                    total: rec.arrtotal ? rec.arrtotal.length : 0,
                    tdappt: rec.arrtdappt ? rec.arrtdappt.length : 0,
                    tdtestdrive: rec.arrtdtestdrive ? rec.arrtdtestdrive.length : 0,
                    apptsold: rec.arrapptsold ? rec.arrapptsold.length : 0,
                    target: reportJSON.target ? reportJSON.target : 0,
                    totalnewLeads: rec.arrdataQualitynewLeads ? rec.arrdataQualitynewLeads.length : 0,
                    totalexisitngLeads: rec.arrdataQualityexisitngLeads ? rec.arrdataQualityexisitngLeads.length : 0,
                    totalLeads: rec.arrdataQualitytotal ? rec.arrdataQualitytotal.length : 0,
                    doneActivity: rec.arrdoneActivity ? rec.arrdoneActivity.length : 0,
                    overdueActivity: rec.arroverdueActivity ? rec.arroverdueActivity.length : 0,
                    ...CommonHelper.getDynamicTotalLength({ ...props, report: reportJSON }, rec, isInbound)
                })
            }
        });

        reports = reports.map(rec => {
            return {
                ...rec,
                carryover: rec.arrcarryover.length,
                walkintestdrive: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.testdrive) : 0,
                walkinsold: rec.walkin > 0 ? Math.round((100 / rec.walkin) * rec.won) : 0,
                appointmenttestdrive: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.tdappt) : 0,
                testdrivetotestdrive: rec.testdriveActivity > 0 ? Math.round((100 / rec.testdriveActivity) * rec.tdtestdrive) : 0,
                appointmentsold: rec.appointment > 0 ? Math.round((100 / rec.appointment) * rec.apptsold) : 0,
                testdriveConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.testdrive) : 0,
                tradeinwonConv: rec.tradein > 0 ? Math.round((100 / rec.tradein) * rec.tradeinwon) : 0,
                wonConv: (isInbound ? rec.convertedLeads : rec.total) > 0 ? Math.round((100 / (isInbound ? rec.convertedLeads : rec.total)) * rec.won) : 0,
                scheduledConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.appointmentScheduled) : 0,
                showConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.show) : 0,
                noshowConv: rec.convertedLeads > 0 ? Math.round((100 / rec.convertedLeads) * rec.noshow) : 0,
                convertedConv: rec.totalInbound > 0 ? Math.round((100 / rec.totalInbound) * rec.convertedLeads) : 0,
                newLeadsConv: rec.totalnewLeads > 0 ? Math.round((100 / rec.totalnewLeads) * rec.newLeads) : 0,
                exisitngLeadsConv: rec.totalexisitngLeads > 0 ? Math.round((100 / rec.totalexisitngLeads) * rec.exisitngLeads) : 0,
                totalConv: rec.totalLeads > 0 ? Math.round((100 / rec.totalLeads) * rec.total) : 0,
            }
        })

        reports = reports.map(rec => {
            return {
                ...rec,
                walkintestdrive: rec.walkintestdrive > 100 ? 100 : rec.walkintestdrive,
                walkinsold: rec.walkinsold > 100 ? 100 : rec.walkinsold,
                wonConv: rec.wonConv > 100 ? 100 : rec.wonConv,
                testdriveConv: rec.testdriveConv > 100 ? 100 : rec.testdriveConv
            }
        })
        reportJSON.columns.forEach(col => {
            if (!reportColumns.find(val => val.value === col)) {
                reports = reports.map(rec => {
                    return {
                        ...rec,
                        [col]: 0
                    }
                });
            }
        })
        setProgressReport(reports)
    }

    const sortedItems = useMemo(() => {
        let sortableItems = [...progressReport];
        if (!_.isEmpty(sortConfig)) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [progressReport, sortConfig]);

    const handleDateRangeChange = (val) => {
        if (val) {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(val[0].startOf('day')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(val[1].endOf('day')._d)
            })
        }
        else {
            setDateRange({
                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
            })
        }
    }
    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 183;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 183;
        return tooEarly || tooLate;
    };

    const handleModelClose = async (report) => {
        setUser(null)
        setFilter(null)
        setShowmodel(false)
        setModelShow({
            enquiry: false,
            testdrive: false,
            activity: false
        })
        if (report) {
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-campaigns`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-origins`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
            await window.firebase.firestore().doc(`schedular/kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-enquiryTypes`)
                .get()
                .then(doc => {
                    if (doc.exists) {
                        doc.ref.update({
                            reportJSON: {
                                ...report,
                                columns: _.uniq(['totalInbound', 'overallPending', 'pendingLeads', 'convertedLeads', ...report.columns])
                            }
                        })
                    }
                })
        }
    }

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const totaltestdrives = (testdrives) => {
        var _enq = [];
        testdrives.forEach(e => {
            if (e.arrtestdrive) {
                _enq = _enq.concat(e.arrtestdrive)
            }
        })
        return _.uniq(_enq).length
    }

    const filterCounts = () => {

        var FilterCount = Object.keys(_.pickBy(objFilter, function (value, key) {
            return !_.isEmpty(value);
        }));

        return (FilterCount.length > 0 && showCount) ? (<span className="badge badge-pill badge-advanced-filter">{FilterCount.length}</span>) : (<></>)
    }
    return (
        <>
            <div className="report-page-head">
                <h2>{reportJSON.name} </h2>
                <Dropdown className='enquiry-view-more float-right mt-0'>
                    <Dropdown.Toggle as={CustomToggle} className='common-button'>
                        <i className='ico icon-more'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        as={CustomMenu}
                        ChildClass="more-dropdown dropsub-fix"
                        xplacement="bottom-end"
                    >
                        {
                            !ownerDashboard || props.dealersettings.roleName === 'Administrator' ? (
                                <Dropdown.Item eventKey="1" onClick={(e) => { setShowmodel(true) }}><i className="ico icon-edit"></i> Edit </Dropdown.Item>
                            ) : (<></>)
                        }
                        {
                            <Dropdown.Item eventKey="2" as={'div'} bsPrefix={'li-excel'}>
                                <ExcelFile element={<a href="#"><i className="ico icon-excel-download"></i> Export Excel</a>} filename={`lead-progress-kpi`}>
                                    <ExcelSheet dataSet={csvData} name="Sheet1" />
                                </ExcelFile>
                            </Dropdown.Item>
                        }
                        <Dropdown.Item eventKey="4" onClick={(e) => {
                            setModelShow({
                                ...modelShow,
                                sheduleReport: true
                            })
                        }}><i className="ico icon-email"></i> Schedule Report </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {
                    !_.isEmpty(schedueReport) ? (
                        <div className="reportsuccess-item-btn mr-2 float-right cursor-pointer" onClick={(e) => {
                            if (schedueReport?.multiSchedule?.length > 0) {
                                setPopover({ show: true, target: e.target })
                            }
                        }}>
                            <div className="reportsuccess-logo"><i className="ico icon-files-document"></i></div>
                            <div className="reportsuccess-inner-text">
                                <div className="reportsuccess-inner-text-top"> Scheduled </div>
                                {
                                    schedueReport?.multiSchedule?.length > 0 ? (
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.multiSchedule.length + 1} Scheduler is Active`}</div>
                                    ) : (
                                        <div className="reportsuccess-inner-text-sub">{`${schedueReport.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(schedueReport.cron)}`}</div>
                                    )
                                }
                            </div>
                            <div className="reportsuccess-sent-right"><img src={_images.meSent} alt="" width="22" /></div>
                        </div>
                    ) : (<></>)
                }
            </div>
            <div className="divider-line"></div>
            <div className="report-content-head">

                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <div className="form-style d-block">
                            <div className="form-row">
                                <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={kpis}
                                        name={"kpis"}
                                        placeholder={'select kpi'}
                                        onChange={(e, data) => {
                                            setSelectedKpi(e)
                                            setRefreshData(true)
                                        }}
                                        value={!_.isEmpty(selectedKpi) ? selectedKpi.value : ''}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3">
                                    <ReactSelect
                                        options={leadTypes}
                                        name={"leadType"}
                                        placeholder={'select lead type'}
                                        onChange={(e, data) => {
                                            setLeadType(e)
                                            setRefreshData(true)
                                        }}
                                        value={!_.isEmpty(selectedLeadType) ? selectedLeadType.value : 'all'}
                                        classNamePrefix={`cursor-pointer basic-select`}
                                        removeClearable={true}
                                    >
                                    </ReactSelect>
                                </div>
                                <div className="form-group col-md-3 daterange-fix">
                                    <AntDateRangePicker
                                        value={dateRange ? [moment.unix(dateRange.startDate.seconds), moment.unix(dateRange.endDate.seconds)] : null}
                                        id={"dashboard-date-range"}
                                        name={'leadkpi-date'}
                                        format='DD/MM/YYYY'
                                        onChange={(e) => { handleDateRangeChange(e, 'leadkpi-date') }}
                                        placeholder='DD/MM/YYYY'
                                        onCalendarChange={(value) => {
                                            if (value) {
                                                const [start, end] = value;
                                                setDates([start, end]);
                                            }
                                            else {
                                                setDates([])
                                            }
                                        }}
                                    //disabledDate={disabledDate}
                                    />
                                </div>
                                <div className="form-group col-md-3">
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            setRefreshData(true)
                                        }}> <i className="ico icon-search"></i> Search</button>
                                        <button type="button" className="btn btn-default ml-3" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedEnqType(null)
                                            setSelectedOrigin(null)
                                            setSelectedLeadSource(null)
                                            setSelectedCampaign(null)
                                            setLeadType(null)
                                            setRefreshData(true)
                                            setShowCount(false)
                                            setObjFilter(defaultFilters)
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="float-right">
                            <Dropdown ref={node} alignRight show={filterShow}>
                                <div className="advanced-filter-btn mr-2 float-right">
                                    <Dropdown.Toggle as={CustomToggle} className="dropdown" onClick={() => {
                                        setShow(!filterShow)
                                    }}>
                                        {filterCounts()}
                                        <i className="ico icon-filter"></i>Filters
                                    </Dropdown.Toggle>
                                </div>
                                <Dropdown.Menu as={CustomFilter}
                                    id="table-reports-cols-settings-pipeline"
                                    className="dropdown-menu dropdown-menu-left"
                                    ChildClass="form-style quick-filter-wrap"
                                    xplacement="bottom-end"
                                >
                                    <div className="quick-filter-body mt-1 h-100 overflow-inherit">
                                        <div className="report-filter-wraper form-style report-filter-height-fix">
                                            {
                                                statisFilters.filter(a => ownerDashboard ? a !== 'user' : true).map((rec, i) => {
                                                    return <DynamicFilters
                                                        key={i}
                                                        {...props}
                                                        filter={rec}
                                                        objFilter={objFilter}
                                                        columns={['testdrive']}
                                                        setObjFilter={(e, name) => {
                                                            setObjFilter({
                                                                ...objFilter,
                                                                [name]: e ? e.value : ''
                                                            })
                                                        }}
                                                        setMultibjFilter={(selectedOptions, name) => {
                                                            let value = [];
                                                            !_.isEmpty(selectedOptions) && selectedOptions.map((data) => {
                                                                value.push(data.value)
                                                            })
                                                            setObjFilter({
                                                                ...objFilter,
                                                                [name]: value
                                                            });
                                                        }}
                                                    />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="quick-filter-footer">
                                        <button type="button" className="btn btn-default float-left" onClick={() => {
                                            setDateRange({
                                                startDate: window.firebase.firestore.Timestamp.fromDate(moment().startOf('month')._d),
                                                endDate: window.firebase.firestore.Timestamp.fromDate(moment().endOf('month')._d)
                                            })
                                            setUser(null)
                                            setSelectedMake(null)
                                            setSelectedModel(null)
                                            setSelectedEnqType(null)
                                            setSelectedOrigin(null)
                                            setSelectedLeadSource(null)
                                            setSelectedCampaign(null)
                                            setSelectedSaleType(null)
                                            setLeadType(null)
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(false)
                                            setObjFilter(defaultFilters)
                                        }}>Clear</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => {
                                            setRefreshData(true)
                                            setShow(false)
                                            setShowCount(true)
                                        }}>Apply</button>

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ? (<div className="mt-5" style={{ height: `${window.innerHeight - 340}px` }}><ContentOverlay active={true} /></div>) : progressReport && progressReport.length === 0 ? (
                    <div className="common-table">
                        <div className="text-center p-5">
                            <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                            <div className="no-data-txt mt-2"> No records found</div>
                        </div>
                    </div>
                ) : (<div className="common-table" style={{ overflowX: 'auto' }}>

                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {/* <button onClick={() => {
                                    requestSort('name')
                                }}>User</button> */}
                                <td className="head-light" width="16%">
                                    <div className="sort-cell cursor-pointer" onClick={(e) => {
                                        e.preventDefault()
                                        requestSort('name')
                                    }}>
                                        <div className="table-sort"><a href="#">
                                            {
                                                sortConfig && sortConfig.key === 'name' ?
                                                    (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                            }
                                        </a>
                                        </div>
                                        Name
                                    </div>
                                </td>
                                {
                                    reportJSON.columns.map((rec, index) => {
                                        return <td key={index} className="head-light text-center" width="7%">
                                            <div className="sort-cell cursor-pointer" onClick={(e) => {
                                                e.preventDefault()
                                                requestSort(rec)
                                            }}>
                                                <div className="table-sort"><a href="#">
                                                    {
                                                        sortConfig && sortConfig.key === rec ?
                                                            (<i className={`ico icon-sort-${sortConfig.direction === 'ascending' ? 'asc' : 'desc'}`}></i>) : (<></>)
                                                    }
                                                </a>
                                                </div>
                                                {
                                                    reportColumns.find(item => item.value === rec) ?
                                                        (<>{CommonHelper.getLabelByValue(reportColumns, rec)}</>) :
                                                        (<span className="pipeline-inactive-span">(In Active)</span>)
                                                }
                                                {
                                                    rec.includes('enqtype-') ? (
                                                        <span className="th-subhead">(Enquiry Type)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('lostReason-') ? (
                                                        <span className="th-subhead">(Lost Reason)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('outcome-') ? (
                                                        <span className="th-subhead">(Activity Outcome)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('callstatus-') ? (
                                                        <span className="th-subhead">(Call Status)</span>
                                                    ) : (<></>)
                                                }
                                                {
                                                    rec.includes('enqstatus-') ? (
                                                        <span className="th-subhead">(Enquiry Status)</span>
                                                    ) : (<></>)
                                                }
                                            </div>
                                        </td>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedItems.length > 0 ?
                                    sortedItems.map((rec, index) => {
                                        return <tr key={index}>
                                            <td>
                                                {
                                                    reportJSON.groupBy === 'user' ? (<div className="table-user-list">
                                                        <a href="#">
                                                            <div className="table-user-avatar">
                                                                <img className="rounded-circle img-object-fit" alt="" src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} /></div>
                                                            {rec.displayName}
                                                        </a>
                                                    </div>) : (
                                                        <div className="dealership-tbl">
                                                            {rec.displayName}
                                                        </div>
                                                    )
                                                }

                                            </td>
                                            {
                                                reportJSON.columns.map((val, index) => {
                                                    return <td key={index} className="text-center cursor-pointer"
                                                        onClick={() => {
                                                            var cols = reportColumns.find(item => item.value === val)
                                                            if (cols && rec[`arr${cols.value}`] && rec[`arr${cols.value}`].length > 0 && cols.percentage !== true) {
                                                                setCounts(rec[`arr${cols.value}`] ? rec[`arr${cols.value}`].length : 0)
                                                                if (cols.type === 'activity') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Activity List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        activity: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'scheduleActivity') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Activity List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        scheduleActivity: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'log') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Customer Logs')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        logs: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'tradein') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Trade In List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        tradein: true
                                                                    })
                                                                }
                                                                else if (cols.type === 'testdrive' && multiTDperLead) {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Test Drives List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        testdrive: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'responseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrresponseLeads`] ? rec[`arrresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'maxresponseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrmaxresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrmaxresponseLeads`] ? rec[`arrmaxresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else if (cols.value === 'minresponseTime') {
                                                                    setFilter({
                                                                        documentIDs: rec[`arrminresponseLeads`]
                                                                    })
                                                                    setCounts(rec[`arrminresponseLeads`] ? rec[`arrminresponseLeads`].length : 0)
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }
                                                                else {
                                                                    setFilter({
                                                                        documentIDs: rec[`arr${cols.value}`]
                                                                    })
                                                                    setPopHeader('Enquiry List')
                                                                    setModelShow({
                                                                        ...modelShow,
                                                                        enquiry: true
                                                                    })
                                                                }

                                                            }
                                                        }}>{`${rec[val]}${reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}`}</td>
                                                })
                                            }
                                        </tr>
                                    }) : (
                                        <tr>
                                            <td colSpan={reportJSON.columns.length + 1}>
                                                <div className="spinner-loader h-100 p-5">
                                                    <div className="no-data-flex h-100 justify-content-center align-items-center ">
                                                        <div className="no-data-img"> <img src={_images.nodata} width="60" height="60" alt="" /></div>
                                                        <div className="no-data-txt mt-2"> No data</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                            }
                        </tbody>
                        {
                            sortedItems.length > 0 ?
                                (<tfoot>
                                    <tr>
                                        <td className="table-sub-total"><div className="dealership-tbl cursor-pointer float-left">Totals</div></td>
                                        {
                                            reportJSON.columns.map((val, index) => {
                                                return <td key={index} className="text-center table-sub-total cursor-pointer"
                                                    onClick={() => {
                                                        var cols = reportColumns.find(item => item.value === val)
                                                        if (cols && cols.percentage !== true) {
                                                            var IDs = [];
                                                            if (cols.value === 'responseTime') {
                                                                progressReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arrresponseLeads`]]
                                                                })
                                                            }
                                                            else if (cols.value === 'maxresponseTime') {
                                                                progressReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arrmaxresponseLeads`]]
                                                                })
                                                            }
                                                            else if (cols.value === 'minresponseTime') {
                                                                progressReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arrminresponseLeads`]]
                                                                })
                                                            }
                                                            else {
                                                                progressReport.forEach(rec => {
                                                                    IDs = [...IDs, ...rec[`arr${cols.value}`]]
                                                                })
                                                            }
                                                            setCounts(IDs.length)
                                                            if (cols.type === 'activity') {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Activity List')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    activity: true
                                                                })
                                                            }
                                                            else if (cols.type === 'scheduleActivity') {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Activity List')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    scheduleActivity: true
                                                                })
                                                            }
                                                            else if (cols.type === 'log') {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Customer Logs')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    logs: true
                                                                })
                                                            }
                                                            else if (cols.type === 'tradein') {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Trade In List')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    tradein: true
                                                                })
                                                            }
                                                            else if (cols.type === 'testdrive' && multiTDperLead) {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Test Drives List')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    testdrive: true
                                                                })
                                                            }
                                                            else {
                                                                setFilter({
                                                                    documentIDs: _.uniq(IDs)
                                                                })
                                                                setPopHeader('Enquiry List')
                                                                setModelShow({
                                                                    ...modelShow,
                                                                    enquiry: true
                                                                })
                                                            }

                                                        }
                                                    }}>
                                                    {
                                                        (val === 'walkintestdrive' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                            val === 'walkinsold' ? (_.sumBy(progressReport, "walkin") > 0 ? Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "walkin")) * _.sumBy(progressReport, "won")) : 0) :
                                                                val === 'appointmenttestdrive' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "tdappt")) : 0) :
                                                                    val === 'testdrivetotestdrive' ? (_.sumBy(progressReport, "testdriveActivity") > 0 ? Math.round((100 / _.sumBy(progressReport, "testdriveActivity")) * _.sumBy(progressReport, "tdtestdrive")) : 0) :
                                                                        val === 'appointmentsold' ? (_.sumBy(progressReport, "appointment") > 0 ? Math.round((100 / _.sumBy(progressReport, "appointment")) * _.sumBy(progressReport, "apptsold")) : 0) :
                                                                            val === 'testdriveConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "testdrive")) : 0) :
                                                                                val === 'scheduledConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "appointmentScheduled")) : 0) :
                                                                                    val === 'showConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "show")) : 0) :
                                                                                        val === 'noshowConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "noshow")) : 0) :
                                                                                            val === 'convertedConv' ? (_.sumBy(progressReport, "totalInbound") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalInbound")) * _.sumBy(progressReport, "convertedLeads")) : 0) :
                                                                                                val === 'newLeadsConv' ? (_.sumBy(progressReport, "totalnewLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalnewLeads")) * _.sumBy(progressReport, "newLeads")) : 0) :
                                                                                                    val === 'exisitngLeadsConv' ? (_.sumBy(progressReport, "totalexisitngLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalexisitngLeads")) * _.sumBy(progressReport, "exisitngLeads")) : 0) :
                                                                                                        val === 'totalConv' ? (_.sumBy(progressReport, "totalLeads") > 0 ? Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, "totalLeads")) * _.sumBy(progressReport, "total")) : 0) :
                                                                                                            val === 'tradeinwonConv' ? (_.sumBy(progressReport, "tradein") > 0 ? Math.round((100 / _.sumBy(progressReport, "tradein")) * _.sumBy(progressReport, "tradeinwon")) : 0) :
                                                                                                                val === 'wonConv' ? (_.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`) > 0 ? Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) > 100 ? 100 : Math.round((100 / _.sumBy(progressReport, `${isInbound ? 'convertedLeads' : 'total'}`)) * _.sumBy(progressReport, "won")) : 0) :
                                                                                                                    val === 'responseTime' ? (_.sumBy(progressReport, `totalresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                        val === 'maxresponseTime' ? (_.sumBy(progressReport, `totalmaxresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalmaxresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                            val === 'minresponseTime' ? (_.sumBy(progressReport, `totalminresponseTime`) > 0 ? CommonHelper.timeformatfromSeconds(_.meanBy(progressReport, (p) => p.totalminresponseTime), props.dealersettings.client) : '00:00:00') :
                                                                                                                                reportColumns.some(item => item.value === val && val.includes('enqstatus-') && item.percentage === true) ?
                                                                                                                                    reportColumns.filter(item => item.value === val && val.includes('enqstatus-') && item.percentage === true).map(col => {
                                                                                                                                        return col.value === val ?
                                                                                                                                            (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`)) > 0 ? Math.round((100 / (isInbound ? _.sumBy(progressReport, `convertedLeads`) : _.sumBy(progressReport, `total`))) * _.sumBy(progressReport, `${val.replace('Conv', '')}`)) : 0
                                                                                                                                            : 0
                                                                                                                                    }) : _.sumBy(progressReport, val))
                                                    }
                                                    {reportColumns.find(item => item.value === val) && reportColumns.find(item => item.value === val).percentage ? '%' : ''}
                                                </td>
                                            })
                                        }
                                    </tr>
                                </tfoot>
                                ) : (<></>)
                        }

                    </table>
                </div>
                )
            }
            <PopUpModal show={modelShow.enquiry}>
                <EnquiryList
                    {...props}
                    show={modelShow.enquiry}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allEnquiries}
                    history={props.history}
                    clientUsers={props.clientUsers}
                    groupUsers={props.groupUsers}
                    filter={filter}
                    isFromDashboard={true}
                ></EnquiryList>
            </PopUpModal>
            <PopUpModal show={modelShow.testdrive}>
                <TestDriveList
                    {...props}
                    show={modelShow.testdrive}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={allTestdrives}
                    filter={filter}
                    isFromDashboard={true}
                ></TestDriveList>
            </PopUpModal>
            <PopUpModal show={modelShow.activity}>
                <ActivityList
                    {...props}
                    show={modelShow.activity}
                    counts={counts}
                    handleClose={handleModelClose}
                    title={popHeader}
                    dataList={doneActivities}
                    filter={filter}
                    isFromDashboard={true}
                ></ActivityList>
            </PopUpModal>
            <PopUpModal show={showModel}>
                <AddReport
                    {...props}
                    show={showModel}
                    title={'Edit Report'}
                    handleClose={handleModelClose}
                    report={reportJSON}
                />
            </PopUpModal>
            <PopUpModal show={modelShow.sheduleReport}>
                <ScheduleReport
                    {...props}
                    show={modelShow.sheduleReport}
                    handleClose={handleModelClose}
                    title={popHeader}
                    reportJSON={{
                        ...reportJSON,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all']
                    }}
                    report={{
                        ...reportJSON,
                        static: false,
                        documentID: `kpi-${ownerDashboard ? localStorage.uid : props.dealersettings.client.id}-${selectedKpi.value}`,
                        groupBy: selectedKpi.value,
                        groupSubtype: ['all']
                    }}
                ></ScheduleReport>
            </PopUpModal >
            <PopoverPanel
                showpopover={popover.show}
                targetpopover={popover.target}
                title='Scheduler'
                position='left'
                id='popover-schdule-report'
                className='popover-schdule-report'
                closepopover={() => {
                    setPopover({ show: false })
                }}>
                {
                    multiSchedule.map((rec, index) => {
                        return <div key={index} className="reportsuccess-multi-btn mb-2">
                            <div className="reportsuccess-multi-logo">
                                <i className="ico icon-files-document"></i>
                            </div>
                            <div className="reportsuccess-multi-inner-text">
                                <div className="reportsuccess-multi-inner-text-top"> Scheduled </div>
                                <div className="reportsuccess-multi-inner-text-sub"> {`${rec.frequency === 'daily' ? 'Daily' : ''} ${cronstrue.toString(rec.cron)}`}</div>
                            </div>
                        </div>
                    })
                }
            </PopoverPanel>
        </>
    )
}

export default LeadProgressKPI;