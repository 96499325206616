import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import { ReactSelect } from '../../components';
import { default as _images } from "../../images";
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import { firestoreDB } from '../../services/helper';

const OEMLeadAllocation = (props) => {

    const [fields, setFields] = useState({ clientID: '', groupID: '', regionID: '', notes: '', owner: '', enquiryID: props.enquiryID });
    const [regions, setRegions] = useState([]);
    const [groups, setGroups] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [errors, setErrors] = useState({});
    const [activeTab, setActiveTab] = useState('clientID');
    const [loader, setLoader] = useState(false);
    const [contact, setContact] = useState({});


    const [defaulStages, setDefaulStages] = useState([]);
    //const [pipelines, setPipelines] = useState([]);
    const [activeStageIndex, setActiveStageIndex] = useState(0);
    const [allUsers, setAllusers] = useState([])
    const [clientSettings, setClientSettings] = useState({});

    //const [showContactInfo, setshowContactInfo] = useState(false)
    //const [existingContactInfo, setexistingContactInfo] = useState({})
    //const [isContactMerged, setContactMerged] = useState(false);



    useEffect(() => {
        const { dealersettings, groupUsers, clientUsers, enquiry } = props;
        if (dealersettings) {

            let _dealers = [];
            let _allClients = dealersettings.clients;
            if (_allClients) {
                _dealers = Object.keys(_allClients).map(cid => {
                    return {
                        value: _allClients[cid].id ? _allClients[cid].id : _allClients[cid].documentID,
                        label: _allClients[cid].name,
                        group: _allClients[cid].group,
                        region: _allClients[cid].region
                    }
                })
            }


            let _regions = [];
            dealersettings.regions && dealersettings.regions.forEach((doc) => {
                if (_dealers && _dealers.some(client => client.region === doc.id)) {
                    _regions.push({
                        value: doc.id,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                }
            });

            let _groups = [];
            if (dealersettings.groups)
                dealersettings.groups && dealersettings.groups.forEach((doc) => {
                    if (_dealers && _dealers.some(client => client.group === doc.id)) {
                        _groups.push({
                            value: doc.id,
                            active: _.isBoolean(doc.active) ? doc.active : true,
                            label: doc.name
                        });
                    }
                });

            // let options = [];
            // let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
            // allDealerUsers && allDealerUsers.map(rec => {
            //     if (!options.some(m => m.value === rec.id))
            //         return options.push({
            //             value: rec.id,
            //             active: _.isBoolean(rec.active) ? rec.active : true,
            //             searchlabel: rec.name,
            //             label: <div className="drop-image">
            //                 <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
            //                 {rec.name}
            //             </div>
            //         })
            // })
            // setAllusers(options)

            //let _activeTab = dealersettings.level === 'oem' ? 'regionID' : 'clientID';
            setDealers(_dealers);
            setGroups(_groups);
            setRegions(_regions);
            // //setActiveTab(_activeTab)
            // console.log('_dealers', _dealers);
            //console.log('groupUsers', groupUsers);
            //console.log('clientUsers', clientUsers);
            //console.log('enquiry', props.enquiry);
        }
    }, [])

    useEffect(() => {
        async function getContactDetails(contactID) {
            firestoreDB(props.dealersettings).firestore().doc(`contactDetails/${contactID}`).get().then(doc => {
                //console.log('contactDetails', doc.data());
                if (!_.isEmpty(doc.data())) {
                    setContact({
                        ...doc.data(),
                        'documentID': doc.id
                    });
                }
                else {
                    setContact(Object.assign({}, props.enquiry.contact));
                }

            });
        }

        if (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact) && !_.isEmpty(props.enquiry.contact.documentID)) {
            getContactDetails(props.enquiry.contact.documentID)
        }
        else if (!_.isEmpty(props.enquiry) && !_.isEmpty(props.enquiry.contact) && _.isEmpty(props.enquiry.contact.documentID)) {
            setContact(Object.assign({}, props.enquiry.contact));
        }




    }, [props.enquiry])

    const handleDealerChange = (e, data) => {
        const { groupUsers, clientUsers } = props;
        let objFilds = {
            ...fields,
            'clientID': e.value
        }

        let options = [];
        let allDealerUsers = !_.isEmpty(groupUsers) ? groupUsers : clientUsers;
        allDealerUsers && allDealerUsers.filter(item =>
            (item.arrayClientID && item.arrayClientID.some(e => e === objFilds.clientID)) ||
            (item.clientIDs && item.clientIDs.some(e => e === objFilds.clientID))).map(rec => {
                if (!options.some(m => m.value === rec.id))
                    return options.push({
                        value: rec.id,
                        active: _.isBoolean(rec.active) ? rec.active : true,
                        searchlabel: rec.name,
                        label: <div className="drop-image">
                            <img src={CommonHelper.showUserAvatar(rec.profileImage, rec.name)} alt="" width="50" className="img-object-fit" />
                            {rec.name}
                        </div>
                    })
            })

        let _currentUser = fields.owner ? options.filter(v => v.value === fields.owner)[0] : {};
        if (!_currentUser) {
            objFilds.owner = '';
        }

        setAllusers(options)       

        setFields(objFilds);
    }
    
    const handleReactSelectChange = (e, data) => {
        if (e) {
            setFields({
                ...fields,
                [data.name]: e.value
            });
        }
    }

    const handleNotesChanges = (e) => {
        e.preventDefault();

        let { name, value } = e.target;
        if (value)
            value = value.replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });

        if (value.length > 0) {
            setErrors({ ...errors, 'notes': '' });
        }
        setFields({
            ...fields,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
        let objfields = Object.assign({}, fields);
        const { enquiry, convertLeadStage } = props;

        let errorClass = 'input_error';
        for (let [key] of Object.entries(objfields)) {
            if (!fields[activeTab]) {
                formIsValid = false;
                errors[activeTab] = errorClass;
            }
        }
        //console.log('input_error', errors, objfields, mandatoryFields)
        setErrors(errors);

        if (formIsValid === true) {
            try {
                setLoader(true);
                const batch = firestoreDB(props.dealersettings).firestore().batch();

                if (!_.isEmpty(props.enquiries)) {
                    for (let [key, value] of Object.entries(props.enquiries)) {
                        let _enqID = (value.enquiryID ? value.enquiryID : (value.stage !== 'InboundLead' ? key : null))

                        //console.log('transferLead', _enqID, value)
                        transferLead(batch, value, _enqID);
                    }
                }
                else {
                    const { enquiry } = props;
                    transferLead(batch, enquiry);
                }


                batch.commit().then((docRef) => {
                    setLoader(false);

                    toast.notify('Lead allocated successfully.', {
                        duration: 2000
                    })
                    props.handleModalClose(props.returnEnq ? enquiry : props.enquiries);
                }).catch((error) => {
                    setLoader(false);
                    console.error("Error updating document: ", error);
                });
            } catch (error) {
                errorLog(error, 'transferLead');
            }

        }
        else
            return formIsValid;

    };

    const handleCancel = (e) => {
        e.preventDefault();
        props.handleModalClose({});
    }

    const transferLead = (batch, enquiry, enquiryID) => {
        let objFields = Object.assign({}, fields);
        let __enquiryID = enquiryID ? enquiryID : objFields.enquiryID;
        const { dealersettings, clientUsers } = props;

        let _enquiry = Object.assign({}, enquiry);
        let transferHistory = Object.assign([], enquiry.transferHistory);

        let objEnqData = {
            modifiedBy: localStorage.uid,
            modifiedDate: window.firebase.firestore.Timestamp.now(),
            modifiedFrom: 'web',
        }
        let objInboundData = {
            //documentID: window.firebase.firestore().collection('inboundLeads').doc().id,
            settingsID: dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null,
            oemID: dealersettings.client && dealersettings.client.settingsID ? dealersettings.client.settingsID : null
        };

        let _newTransferHistory = {
            by: localStorage.uid,
            date: window.firebase.firestore.Timestamp.now(),
        }
        let _transferredTo = 'Dealer';
        let isLeadExistinInbound = false;
        if (dealersettings.level === 'oem') {
            // objEnqData.isConvertedOEM = true;
            // objEnqData.convertedByOEM = localStorage.uid;
            // objEnqData.convertedDateOEM = window.firebase.firestore.Timestamp.now();
            // objEnqData.convertedOEMID = dealersettings.oemID ? dealersettings.oemID : null;
            _newTransferHistory.fromOEM = dealersettings.oemID ? dealersettings.oemID : null;
        }
        if (dealersettings.level === 'region') {
            // objEnqData.isConvertedRegion = true;
            // objEnqData.convertedByRegion = localStorage.uid;
            // objEnqData.convertedDateRegion = window.firebase.firestore.Timestamp.now();
            // objEnqData.convertedRegionID = dealersettings.regionID ? dealersettings.regionID : null;
            _newTransferHistory.fromRegion = dealersettings.regionID ? dealersettings.regionID : null;
        }
        if (dealersettings.level === 'group') {
            // objEnqData.isConvertedGroup = true;
            // objEnqData.convertedByGroup = localStorage.uid;
            // objEnqData.convertedDateGroup = window.firebase.firestore.Timestamp.now();
            // objEnqData.convertedGroupID = dealersettings.groupID ? dealersettings.groupID : null;
            _newTransferHistory.fromGroup = dealersettings.groupID ? dealersettings.groupID : null;
        }

        if (objFields.regionID) {
            objEnqData.regionID = objFields.regionID;
            objInboundData.regionID = objFields.regionID;

            _newTransferHistory.toRegion = objFields.regionID;
            _transferredTo = _.find(dealersettings.regions, { id: objFields.regionID }) ? _.find(dealersettings.regions, { id: objFields.regionID }).name : 'Region';
            if (objFields.regionID === enquiry.regionID) isLeadExistinInbound = true;
        }
        else if (objFields.groupID) {
            objEnqData.groupID = objFields.groupID;
            objInboundData.groupID = objFields.groupID;

            _newTransferHistory.toGroup = objFields.groupID;
            _transferredTo = _.find(dealersettings.groups, { id: objFields.groupID }) ? _.find(dealersettings.groups, { id: objFields.groupID }).name : 'Group';
            if (objFields.groupID === enquiry.groupID) isLeadExistinInbound = true;
        }
        else if (objFields.clientID) {
            objEnqData.clientID = objFields.clientID;
            objInboundData.clientID = objFields.clientID;

            _newTransferHistory.toClient = objFields.clientID;
            _transferredTo = _.find(dealersettings.clients, { id: objFields.clientID }) ? _.find(dealersettings.clients, { id: objFields.clientID }).name : 'Dealer';
            if (objFields.clientID === enquiry.clientID) isLeadExistinInbound = true;
        }

        transferHistory.push(_newTransferHistory);
        objEnqData.transferHistory = transferHistory;
        objInboundData.transferHistory = transferHistory;
        objInboundData.oemStatus = 'converted';
        objInboundData.oemConvertedBy = localStorage.uid;
        objInboundData.oemConvertedDate = window.firebase.firestore.Timestamp.now();

        if (fields.owner) {
            objEnqData.owner = fields.owner;
            let _userEmail = clientUsers && clientUsers.filter(m => m.id === fields.owner)[0] ? props.clientUsers.filter(m => m.id === fields.owner)[0].email : null;
            if (_userEmail) objInboundData.userEmail = _userEmail
        }

        //const isClientTransfer = (activeTab === 'clientID' && fields.pipeline && fields.pipeline !== 'LeadsBucket') ? true : false;
        //const isFromInbound = (_enquiry.stage === 'InboundLead' || _enquiry.oldStage === 'InboundLead') ? true : false;
        const isNewEnquiryTransfer = (_.isEmpty(__enquiryID) && ((_enquiry.stage === 'InboundLead') || _enquiry.oldStage === 'InboundLead')) ? true : false;

        if (isNewEnquiryTransfer) {

            if (objFields.notes)
                objInboundData.notes = _enquiry.notes ? `${_enquiry.notes} \n${objFields.notes}` : objFields.notes;
            updateInBoundData(batch, objInboundData, _enquiry.documentID)
        }
        else if (__enquiryID) {

            objInboundData.documentID = firestoreDB(props.dealersettings).firestore().collection('inboundLeads').doc().id;
            objEnqData.convertedLeadRefID = objInboundData.documentID;
            objEnqData.status = null;
            objEnqData.oemStatus = null;
            objEnqData.stage = null;
            objEnqData.clientID = null;
            objEnqData.isDeleted = null; //SaMeeR For OEM 
            objEnqData.projectId = null;
            objInboundData.enquiryID = __enquiryID;
            objInboundData.refID = __enquiryID;

            batch.set(firestoreDB(props.dealersettings).firestore().doc(`enquiries/${__enquiryID}`), objEnqData, { merge: true });

            if (objFields.notes) {
                objInboundData.notes = _enquiry.notes ? `${_enquiry.notes} \n${objFields.notes}` : objFields.notes;
            }

            if (_.isEmpty(objEnqData.pipeline))
                saveOEMInboundLead(batch, objInboundData, _enquiry);

        }
    }

    const saveOEMInboundLead = (batch, _objOEMInbound, _objEnq) => {
        //const { dealersettings } = props;
        const _newLead = Object.assign({}, _objOEMInbound);
        let _objEnquiry = Object.assign({}, _objEnq);
        for (let [key, value] of Object.entries(_objEnquiry)) {
            if (_newLead.hasOwnProperty(key) && _.isEmpty(_newLead[key]) && !_.isEmpty(_objEnquiry[key]))
                _newLead[key] = value;
        }

        //_newLead.documentID = window.firebase.firestore().collection('inboundLeads').doc().id;
        _newLead.pipeline = 'LeadsBucket';
        _newLead.stage = 'InboundLead';
        _newLead.isDeleted = false;
        _newLead.status = 0;
        _newLead.addedBy = localStorage.uid;
        _newLead.modifiedBy = localStorage.uid;
        _newLead.addedDate = window.firebase.firestore.Timestamp.now();
        _newLead.modifiedDate = window.firebase.firestore.Timestamp.now();
        _newLead.leadCreated = window.firebase.firestore.Timestamp.now();
        _newLead.leadModified = window.firebase.firestore.Timestamp.now();
        _newLead.addedFrom = 'web';
        _newLead.modifiedFrom = 'web';
        _newLead.origin = _objEnquiry.origin ? _objEnquiry.origin : null;
        _newLead.campaign = _objEnquiry.campaign ? _objEnquiry.campaign : null;
        _newLead.projectId = props.dealersettings?.client?.projectId ? props.dealersettings?.client?.projectId : null;

        //_newLead.systemType = dealersettings.level ? dealersettings.level : null;
        //_newLead.browserName = CommonHelper.checkBrowser();

        if (!_.isEmpty(_objEnquiry.requirement)) {
            let _requirement = Object.assign({}, _objEnquiry.requirement);
            let _newReq = {};
            if (!_.isEmpty(_requirement.stock)) {
                let _stockReq = Object.assign({}, _requirement.stock);
                _newReq.make = _stockReq.make ? _stockReq.make : '';
                _newReq.model = _stockReq.model ? _stockReq.model : '';
                _newReq.type = _stockReq.type ? _stockReq.type : '';
                _newReq.year = _stockReq.year ? _stockReq.year : '';
                _newReq.extColor = _stockReq.extColor ? _stockReq.extColor : '';
                _newReq.intColor = _stockReq.intColor ? _stockReq.intColor : '';
                _newReq.saleType = _stockReq.saleType ? _stockReq.saleType : '';
                _newReq.price = _stockReq.price ? _stockReq.price.toString() : null;
                _newReq.stockNo = _stockReq.stockNo ? _stockReq.stockNo : '';
                _newReq.regNo = _stockReq.regNo ? _stockReq.regNo : '';
                _newReq.vinNo = _stockReq.vinNo ? _stockReq.vinNo : '';
            }
            else {
                _newReq.make = _requirement.make ? _requirement.make : '';
                _newReq.model = _requirement.model ? _requirement.model : '';
                _newReq.type = _requirement.type ? _requirement.type : '';
                _newReq.year = _requirement.year ? _requirement.year : '';
                _newReq.extColor = _requirement.extColor ? _requirement.extColor : '';
                _newReq.intColor = _requirement.intColor ? _requirement.intColor : '';
                _newReq.saleType = _requirement.saleType ? _requirement.saleType : '';
                _newReq.stockNo = _requirement.stockNo ? _requirement.stockNo : '';
                _newReq.regNo = _requirement.regNo ? _requirement.regNo : '';
                _newReq.vinNo = _requirement.vinNo ? _requirement.vinNo : '';
                _newReq.price = _requirement.price ? _requirement.price.toString() : null;
            }
            _newLead.requirement = _newReq;
        }

        if (!_.isEmpty(_objEnquiry.contact)) {
            let objContactData = Object.assign({}, _objEnquiry.contact);

            //Remove empty value from object
            for (var propName in objContactData) {
                if (objContactData[propName] === null || objContactData[propName] === undefined || objContactData[propName] === '' || objContactData[propName] === [] || (_.isObject(objContactData[propName]) && _.isEmpty(objContactData[propName]))) {
                    delete objContactData[propName];
                }
            }
            delete objContactData['documentID'];
            delete objContactData['displayID'];
            delete objContactData['clientID'];
            delete objContactData['groupID'];
            delete objContactData['regionID'];
            delete objContactData['oemID'];
            delete objContactData['keywords'];
            delete objContactData['owner'];
            delete objContactData['terms'];
            delete objContactData['addedBy'];
            delete objContactData['addedDate'];
            delete objContactData['modifiedBy'];
            delete objContactData['modifiedFrom'];
            delete objContactData['modifiedDate'];
            if (!_.isEmpty(objContactData['company'])) {
                let _company = Object.assign({}, objContactData['company']);
                objContactData.company = _company.name ? _company.name : null;
            }

            _newLead.contact = objContactData;
        }

        //console.log('saveOEMInboundLead', _objOEMInbound, _newLead);

        batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${_newLead.documentID}`), _newLead, { merge: true });

    }

    const updateInBoundData = (batch, _objReq, documentID) => {
        const { dealersettings } = props;
        _objReq.modifiedBy = dealersettings ? dealersettings.id : '';
        _objReq.modifiedDate = window.firebase.firestore.Timestamp.now();
        _objReq.modifiedFrom = 'web';
        _objReq.addedBy = dealersettings ? dealersettings.id : '';
        _objReq.addedDate = window.firebase.firestore.Timestamp.now();

        batch.set(firestoreDB(props.dealersettings).firestore().doc(`inboundLeads/${documentID}`), _objReq, { merge: true });
    }


    const errorLog = (error, origin) => {
        const documentID = window.firebase.firestore().collection('errorLogs').doc().id;
        const ref = window.firebase.firestore().collection('errorLogs').doc(documentID);
        ref.set({
            addedDate: window.firebase.firestore.Timestamp.now(),
            addedBy: localStorage.uid,
            clientID: props.dealersettings.client.id,
            oemID: props.dealersettings.client.settingsID,
            error: error.message ? error.message : '',
            description: (origin ? origin + ':- ' : '') + (error.message ? error.message : ''),
            currentPath: window.location.pathname,
        }).then(() => {
            console.log('Error has been uploaded')
        }).catch((error) => {
            console.error("Error adding log: ", error);
        });
    }


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleModalClose}
                backdrop="static"
                backdropClassName={props.show ? 'overlay-modal active' : ''}
                dialogClassName="modal-dialog-centered modal-add-company"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title><Translate text={'Allocate Leads'} /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                  
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" pop-layout form-style">

                                {
                                    activeTab === 'regionID'
                                        ?
                                        <>
                                            <div className="form-row ">
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'Regions'} /></label>
                                                    <ReactSelect
                                                        options={regions}
                                                        name={"regionID"}
                                                        placeholder={'select region'}
                                                        onChange={handleReactSelectChange}
                                                        value={fields.regionID}
                                                        classNamePrefix={`${errors["regionID"]} basic-select`}
                                                        removeClearable={true}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            {
                                                activeTab === 'groupID'
                                                    ?
                                                    <>
                                                        <div className="form-row ">
                                                            <div className="form-group col-md-12">
                                                                <label > <Translate text={'Groups'} /></label>
                                                                <ReactSelect
                                                                    options={groups}
                                                                    name={"groupID"}
                                                                    placeholder={'select group'}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.groupID}
                                                                    classNamePrefix={`${errors["groupID"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-row ">
                                                            <div className="form-group col-md-12">
                                                                <label > <Translate text={'Dealers'} /></label>
                                                                <ReactSelect
                                                                    options={dealers}
                                                                    name={"clientID"}
                                                                    placeholder={'select dealer'}
                                                                    onChange={handleDealerChange}
                                                                    value={fields.clientID}
                                                                    classNamePrefix={`${errors["clientID"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>

                                                            <div className="form-group col-md-12">
                                                                <label ><Translate text={'owner'} /></label>
                                                                <ReactSelect
                                                                    options={allUsers}
                                                                    name={"owner"}
                                                                    onChange={handleReactSelectChange}
                                                                    value={fields.owner ? fields.owner : ''}
                                                                    classNamePrefix={`${errors["owner"]} basic-select`}
                                                                    removeClearable={true}
                                                                >
                                                                </ReactSelect>
                                                            </div>
                                                        </div>
                                                      
                                                    </>
                                            }
                                        </>
                                }

                                <div className="form-group">
                                    <label ><Translate text={'notes'} /></label>
                                    <textarea
                                        className={`${errors["notes"]} form-control notes`}
                                        name="notes" onChange={(e) => handleNotesChanges(e)}
                                        value={fields.notes ? fields.notes : ''}
                                        placeholder="notes"
                                        cols="80"
                                        rows="3"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className={`modal-footer ${loader ? 'form-disable' : ''}`}>

                    <button
                        type="button"
                        className={`btn btn-primary float-right ml-2 ${loader ? 'form-disable' : ''}`}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {
                            loader ?
                                (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                : (<></>)
                        }
                        <Translate text={'done'} />
                    </button>
                    <button
                        type="button"
                        className="btn btn-default float-right"
                        onClick={(e) => handleCancel(e)}
                    ><Translate text={'cancel'} />
                    </button>


                </Modal.Footer>
            </Modal>
            
        </>
    )
}

export default OEMLeadAllocation;