
export const objCallLogs = {
    documentID: null,
    clientID: null,
    projectId: null,
    user: null,
    contact: {},
    enquiry: {},
    contactID: null,
    enquiryID: null,
    activityID: null,
    callType: '',
    callStatus: '',
    callDuration: 0,
    campaign: '',
    origin: '',
    enquiryType: '',
    notes: '',
    teammates: [],
    rejectReason: '',
    status: '',
    keywords: [],
    make: '',
    model: '',
    type: '',
    group: '',
    year: '',
    saleType: '',
    addedBy: '',
    addedFrom: 'web',
    addedDate: null,
    modifiedBy: '',
    modifiedFrom: 'web',
    modifiedDate: null,
    isDeleted: false,
    logType: ''
};

export const mandatoryFields = [
    'user',
    'callType',
    'notes'
];

export const allCallLogsFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Phone',
        value: 'phone',
        flex: 1,
        default: true,
        elementName: 'csvPhone'
    },
    {
        name: 'Email',
        value: 'email',
        flex: 1,
        default: true
    },
    {
        name: 'Reason',
        value: 'rejectReason',
        flex: 1
    },
    {
        name: 'Log Type',
        value: 'logTypeName',
        flex: 1,
        default: true
    },
    {
        name: 'Call Type',
        value: 'callType',
        flex: 1,
        default: true
    },
    {
        name: 'Call Duration',
        value: 'callDuration',
        flex: 1,
        default: true
    },
    {
        name: 'Campaign',
        value: 'campaign',
        flex: 1
    },
    {
        name: 'Origin',
        value: 'origin',
        flex: 1
    },
    {
        name: 'Enquiry Type',
        value: 'enquiryType',
        flex: 1
    },
    {
        name: 'Call Summary',
        value: 'notes',
        flex: 1,
        limit : 190
    },
    {
        name: 'Make',
        value: 'make',
        flex: 1
    },
    {
        name: 'Model',
        value: 'model',
        flex: 1
    },
    {
        name: 'Body Type',
        value: 'type',
        flex: 1
    },
    {
        name: 'Year',
        value: 'year',
        flex: 1
    },
    {
        name: 'Call Status',
        value: 'callStatus',
        flex: 1
    },
    {
        name: 'Logged By',
        value: 'addedBy',
        flex: 1,
        default: true,
        subText: 'createdOn',
        subTextTitle: 'Created On'
    },
    {
        name: 'Transferred To',
        value: 'userName',
        flex: 1,
        default: true,
        subText: 'updatedOn',
        subTextTitle: 'Transferred On'
    },
    {
        name: 'Status',
        value: 'status',
        flex: 1,
        default: true,
        elementName: 'statusName'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 40,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]

export const allRejectFieldsVM = [
    {
        name: 'Contact Name',
        value: 'contactName',
        flex: 1,
        default: true,
        nestedText: 'dealerName',
        nestedTextTitle: 'Dealer Name',
    },
    {
        name: 'Rejected By',
        value: 'userName',
        flex: 1,
        default: true,
        subText: 'updatedOn'
    },
    {
        name: 'Reason',
        value: 'rejectReason',
        flex: 1,
        default: true
    },
    // {
    //     name: 'Comments',
    //     value: 'notes',
    //     flex: 1,
    //     default: true
    // },
    {
        name: 'Created By',
        value: 'addedByName',
        flex: 1,
        default: true,
        subText: 'createdOn'
    },
    {
        name: 'Settings',
        value: 'settings',
        subText: 'documentID',
        width: 280,
        default: true,
        type: 'settings',
        flex: 0,
        fixedRight: true
    }
]