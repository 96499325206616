/** LIBRARIES */
import React, { Component } from 'react';
import { Tab, Col, Nav, Row } from 'react-bootstrap'
/** COMPONENTS */
import PasswordSecurity from './passwordsecurity'

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <Tab.Container defaultActiveKey="personel">
                    <div className="settings-submenu">
                        <h4>General Settings</h4>
                        <Nav as="ul" bsPrefix="ul-nav">
                            <Nav.Item as="li" bsPrefix="li-nav">
                                <Nav.Link eventKey="personel" bsPrefix="li-a-nav">Personal Info</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" bsPrefix="li-nav">
                                <Nav.Link eventKey="security" bsPrefix="li-a-nav">Security/Password</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" bsPrefix="li-nav">
                                <Nav.Link eventKey="companies" bsPrefix="li-a-nav">Companies / Sites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" bsPrefix="li-nav">
                                <Nav.Link eventKey="notifications" bsPrefix="li-a-nav">Notifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" bsPrefix="li-nav">
                                <Nav.Link eventKey="devices" bsPrefix="li-a-nav">Active Devices</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="settings-container mb-0">
                        <div className="settings-container-sub" >
                            <Tab.Pane eventKey="personel">
                                <>
                                    <h2>Personal Info</h2>
                                    <div className="divider-line"></div>
                                </>
                            </Tab.Pane>
                            <Tab.Pane eventKey="security">
                                <PasswordSecurity />
                            </Tab.Pane>
                        </div>
                    </div>

                </Tab.Container>

            </>
        );
    }
}

export default GeneralSettings;