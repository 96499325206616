/** LIBRARIES */
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Swal from 'sweetalert2'
import _ from 'lodash'
/** COMPONENTS */
import Translate from '../../constants/translate';
import CommonHelper from '../../services/common';
import { ReactSelect, InputText, EvalSearch, PopUpModal, EvalSelect } from '../../components';


let australian_state = [
    { value: 'ACT', active: true, label: 'Australian Capital Territory' },
    { value: 'NSW', active: true, label: 'New South Wales' },
    { value: 'NT', active: true, label: 'Northern Territory' },
    { value: 'QLD', active: true, label: 'Queensland' },
    { value: 'SA', active: true, label: 'South Australia' },
    { value: 'TAS', active: true, label: 'Tasmania' },
    { value: 'VIC', active: true, label: 'Victoria' },
    { value: 'WA', active: true, label: 'Western Australia' }
]

export default class VehicleScan extends Component {
    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            fields: {
                regNum: (this.props.regNo ? this.props.regNo.toUpperCase() : ''),
                vinNum: (this.props.chassisNo ? this.props.chassisNo.toUpperCase() : ''),
                _state: (this.props.regState ? this.props.regState : ''),
            },
            activeTab: 'reg',
            errors: {},
            license_state: [],
            showEvalScan: false,
            evalSelectModal: {
                show: false,
                data: null
            }
        }
        //this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount = () => {
        const { dealersettings, type } = this.props;
        let license_state = [];
        if (!_.isEmpty(dealersettings?.client)) {
            const client = dealersettings.client;
            let defaultCountry = client.countryCode ? client.countryCode.toUpperCase() : 'AU';

            dealersettings.states &&
                dealersettings.states.filter(e => e.country_code === (defaultCountry)).forEach((doc) => {
                    license_state.push({
                        value: doc.code,
                        active: _.isBoolean(doc.active) ? doc.active : true,
                        label: doc.name
                    });
                });

        }
        this.setState({ license_state: license_state, activeTab: type ? type : 'reg' })
    }

    handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        let fields = Object.assign({}, this.state.fields);
        if (value)
            fields[name] = value.toUpperCase();
        else
            fields[name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(name) });

    }


    handleReactSelectChange = (e, data) => {
        let fields = Object.assign({}, this.state.fields);

        if (e)
            fields[data.name] = e.value;
        else
            fields[data.name] = '';

        this.setState({ fields: fields }, () => { this.errorChange(data.name) });

    }

    errorChange = (key) => {
        let errors = Object.assign({}, this.state.errors);
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';

        if (errors !== {}) {
            if (!fields[key])
                errors[key] = errorClass;
            else
                errors[key] = '';
            this.setState({ errors: errors });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        let fields = Object.assign({}, this.state.fields);
        let errorClass = 'input_error';
        const { activeTab } = this.state;
        let mandatoryFields = activeTab === 'reg' ? ['regNum', '_state'] : ['vinNum']

        for (let [key, value] of Object.entries(fields)) {

            if (mandatoryFields.indexOf(key) >= 0) {

                if (!fields[key]) {
                    formIsValid = false;
                    errors[key] = errorClass;

                }
            }
        }
        this.setState({ errors: errors });

        if (formIsValid === true) {
            if (this.props.isEvalExpert)
                this.searchEvalExpert(this);
            else
                this.searchScan(this);
        }
        else
            return formIsValid;
    };

    searchEvalExpert = () => {
        const { dealersettings } = this.props;
        const { activeTab } = this.state;
        let clientID = dealersettings ? dealersettings.client.id : '';
        const objData = Object.assign({}, this.state.fields);
        this.isLoading = true;

        let _param = {
            clientID: clientID
        }
        if (activeTab === 'reg') {
            _param.vinNo = objData.regNum;
            _param.state = objData._state;
        }
        else {
            _param.vinNo = objData.vinNum;
        }
        const evalXpertSearch = window.firebase.functions().httpsCallable(`evalexpert-search`);
        evalXpertSearch(_param).then((_response) => {
            this.isLoading = false;
            //console.log('evalexpert-search', _response);
            //check for success response
            if (_response && _response.data && _response.data.success) {
                //console.log('evalexpert-search--', _response.data.data[0]);
                this.props.handleClose(_response.data.data && _response.data.data[0]);
            }
            if (_response?.data?.success === false && !_.isEmpty(_response?.data?.data) && !_.isEmpty(_response.data.data[0]?.VechielDescriptions)) {
                //console.log('evalexpert-search--false', _response.data.data[0]);
                this.setState({
                    evalSelectModal: {
                        show: true,
                        data: _response.data.data[0]
                    }
                })

            }
            else {
                this.setState({ showEvalScan: true })
                //Swal.fire('No record found!', '', 'info')
                //this.forceUpdate()
            }
        });

    }

    searchScan = (e) => {
        const objData = Object.assign({}, this.state.fields);
        const { dealersettings } = this.props;
        const { activeTab } = this.state;
        this.isLoading = true;
        let _param = {}
        if (activeTab === 'reg') {
            _param.Rego = objData.regNum;
            _param.State = objData._state;
        }
        else {
            _param.Vin = objData.vinNum;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(_param)
        };

        let tradeinplusURL = dealersettings && dealersettings.tradeinplusURL ? dealersettings.tradeinplusURL : 'https://demo.fusionsd.net/tradeinplusqa';
        let url = tradeinplusURL + '/api/TradeInApp/Action/GetNevdisVehicle'
        fetch(url, requestOptions)
            .then(async response => {
                const data = await response.json();
                this.isLoading = false;
                //check for success response
                if (response.ok && data && data.success) {
                    this.props.handleClose(data.data);
                }
                else {
                    Swal.fire(CommonHelper.showLocale(this.props, 'No record found!'), '', 'info')
                    this.forceUpdate()
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

    };

    handleEvalScanClose = (data) => {
        if (!_.isEmpty(data)) {
            const { activeTab } = this.state;
            let _param = {
                ...data,
                EvalID: data.EvalID ? data.EvalID.toString() : null
            }
            if (activeTab === 'reg')
                _param.Registration = this.state.fields.regNum;
            this.props.handleClose(_param);
        }
        this.setState({
            showEvalScan: false,
            evalSelectModal: {
                show: false,
                data: null
            }
        })
    }

    render() {
        const { regNum, _state, vinNum } = this.state.fields;
        const { show, isGenericLayout, hideClose } = this.props;
        const { license_state, showEvalScan, activeTab, evalSelectModal } = this.state;
        return (
            <>
                <Modal
                    show={show}
                    onHide={this.props.handleClose}
                    backdrop="static"
                    backdropClassName={show ? 'overlay-modal active' : ''}
                    dialogClassName="modal-dialog-centered modal-add-regno">
                    <Modal.Header closeButton={!Boolean(hideClose)}>
                        <Modal.Title> <h5 className="modal-title"><Translate text={'searchVehicle'} /></h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-modal-body">

                        <div className="file-manager-nav pl-0">
                            <ul>
                                <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'reg' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ activeTab: 'reg' })

                                    }}><Translate text={'regNo'} /> </a></li>

                                <li><a href="#" className={`nav-activity-tabs  ${activeTab === 'vin' ? 'active' : ''}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ activeTab: 'vin' })
                                    }}
                                > <Translate text={'chassisNo'} /> </a></li>
                            </ul>
                        </div>

                        <div className={`container-fluid ${isGenericLayout ? 'pl-0 pr-0' : ''}`}>
                            <div className={`${isGenericLayout ? 'w-100 d-block border-0' : 'row'}`}>
                                <div className="search-pop form-style mt-2">
                                    {
                                        activeTab === 'reg'
                                            ?
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label ><Translate text={'vehicleRegNo'} /></label>
                                                    <InputText
                                                        name="regNum"
                                                        value={regNum}
                                                        onChange={this.handleOnChange}
                                                        autoComplete="off"
                                                        placeholder={'registration number'}
                                                        className={`form-control ${this.state.errors["regNum"]}`}
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'state'} /></label>
                                                    <ReactSelect
                                                        options={!_.isEmpty(license_state) ? license_state : (isGenericLayout ? australian_state : [])}
                                                        name={"_state"}
                                                        placeholder={'select state'}
                                                        onChange={this.handleReactSelectChange}
                                                        value={_state}
                                                        classNamePrefix={`${this.state.errors["_state"]} basic-select`}
                                                    >
                                                    </ReactSelect>
                                                </div>
                                            </div>
                                            :
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label > <Translate text={'chassisNo'} /></label>
                                                    <InputText
                                                        type="text"
                                                        name="vinNum"
                                                        value={vinNum}
                                                        onChange={this.handleOnChange}
                                                        autoComplete="off"
                                                        placeholder={'chassis/vin number'}
                                                        className={`form-control ${this.state.errors["vinNum"]}`}
                                                    />
                                                </div>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={`modal-footer ${this.isLoading ? 'form-disable' : ''}`}>
                        <button type="button" className="btn btn-primary float-right" onClick={(e) => this.handleSubmit(e)}>
                            {this.isLoading ? <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> : <></>}
                            <Translate text={'search'} />
                        </button>
                    </Modal.Footer>
                </Modal>

                <PopUpModal show={showEvalScan}>
                    <EvalSearch
                        show={showEvalScan}
                        clsActive='overlay-modal active'
                        regNum={activeTab === 'reg' ? regNum : null}
                        vinNum={activeTab === 'vin' ? vinNum : null}
                        dealersettings={this.props.dealersettings}
                        handleClose={this.handleEvalScanClose}
                        isGenericLayout={isGenericLayout}
                    >
                    </EvalSearch>
                </PopUpModal>

                <PopUpModal show={evalSelectModal.show}>
                    <EvalSelect
                        show={evalSelectModal.show}
                        data={evalSelectModal.data}
                        vinNum={vinNum}
                        clsActive='overlay-modal active'
                        dealersettings={this.props.dealersettings}
                        handleClose={this.handleEvalScanClose}
                        isGenericLayout={isGenericLayout}
                    >
                    </EvalSelect>
                </PopUpModal>
            </>


        );
    }
}

