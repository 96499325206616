import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import _ from 'lodash'
import toast from 'toasted-notes'
import { Modal, Dropdown } from 'react-bootstrap'

import { InputText, InputCheckBox, PopUpModal } from '../../../../components';
import { CustomToggle, CustomSearchMenu } from '../../../../components/customdropdown';
import Translate from '../../../../constants/translate'
import CommonHelper from '../../../../services/common';
import images from '../../../../images'
import moment from 'moment'
import TwoFactorAuthModel from './twoFactorAuthModel'
import ReAuthenticate from '../../../common/reAuthenticate'
import { getAuth, getMultiFactorResolver } from 'firebase/auth'

const TwoFactorAuth = props => {
    const [loader, setLoader] = useState(true)
    const [mfaLoader, setmfaLoader] = useState(false)
    const [showMFAEnableModel, setShowMFAEnableModel] = useState({ show: false })
    const [showReauthenticate, setshowReauthenticate] = useState({ show: false })
    const [errorFields, setErrorFields] = useState({});
    const [phoneCodes, setphoneCodes] = useState([])
    const [state, setState] = useState({})

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userRefDoc = window.firebase
                    .firestore()
                    .doc(`users/${localStorage.uid}`);

                const userSnapShot = await userRefDoc.get();
                const data = userSnapShot.data();
                setState(data);
                setLoader(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUser();
    }, []);

    const handleEnable = async () => {
        if (state.enableMFA) {
            setmfaLoader(true)
            const enrollMFA = window.firebase.functions().httpsCallable('amsadmin-enrollMFA');
            enrollMFA({
                "uid": state.documentID,
                "phone": `+${state.phoneCode.replace('+', '')}${state.phone}`,
                "enroll": false
            }).then((data) => {
                setmfaLoader(false);
                if (data.data.success === true) {
                    window.firebase.firestore().doc(`users/${state.documentID}`).set({ enableMFA: false }, { merge: true });
                    setState({ ...state, enableMFA: false })
                    setshowReauthenticate({
                        show: true,
                        title: 'Reauthenticate'
                    })
                }
                else {
                    let msg = data.data.message;
                    if (data.data.data.errorInfo && data.data.data.errorInfo.code === 'auth/invalid-phone-number') {
                        msg = 'Invalid phone number.'
                    }
                    Swal.fire(msg, '', 'error');
                }
            });
        } else {
            setshowReauthenticate({
                show: true,
                title: 'Reauthenticate'
            })
        }

    }

    return (
        <>
            <div className='security-settings-inner form-style'>
                {/* <h3 style={{ paddingBottom: '10px' }}>Two-factor authentication</h3> */}
                <p><Translate text={'Multi-factor authentication adds'} /></p>
                {/* <button onClick={() => handleEnableMFA()}>DISBALE ALI MFA</button> */}
                {
                    state.enableTOTP ? (<div className="authenticator-active-sync-item active-tab">

                        <div className="authenticator-label">
                            <span className="authenticator-active-logo"><img src={images.qrcodescanner} alt="" height="30px" /></span>
                            <div>
                                Authenticated
                                {
                                    state?.enableTOTPOn?.seconds ? (
                                        <div className="authenticator-added">{moment.unix(state.enableTOTPOn.seconds).format('DD/MM/YYYY hh:mm A')}</div>
                                    ) : (<></>)
                                }
                            </div>
                        </div>
                    </div>) : (<div className="authenticator-active-sync-item ">

                        <div className="authenticator-label">
                            <span className="authenticator-active-logo"><img src={images.qrcodescanner} alt="" height="30px" /></span>
                            <div>
                                Authenticator

                            </div>
                        </div>
                        <div className="authenticator-btn">
                            <button type="button" className="btn btn-common float-right" onClick={handleEnable}>
                                {
                                    mfaLoader ?
                                        (<span className="spinner-border spinner-button mr-1" role="status" aria-hidden="true"></span>)
                                        : (<></>)
                                }
                                <Translate text={'Enable'} /></button>
                        </div>
                    </div>)
                }
            </div>
            <PopUpModal show={showReauthenticate.show}>
                <ReAuthenticate
                    {...props}
                    {...showReauthenticate}
                    hideHeader={true}
                    handleClose={(type, user) => {
                        setshowReauthenticate({
                            show: false,
                        })
                        if (user) {
                            setShowMFAEnableModel({
                                show: true,
                                title: 'Enable Multi-factor authentication',
                                currentUser: user
                            })
                        } else if (type) {
                            Swal.fire('You have already enrolled in multi-factor authentication.', '', 'info');
                        }

                    }}
                >
                </ReAuthenticate>
            </PopUpModal>
            <PopUpModal show={showMFAEnableModel.show}>
                <TwoFactorAuthModel
                    {...props}
                    {...showMFAEnableModel}
                    handleClose={() => {
                        setShowMFAEnableModel({
                            show: false,
                        })
                    }}
                >
                </TwoFactorAuthModel>
            </PopUpModal>
        </>
    )
}

export default TwoFactorAuth
